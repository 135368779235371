import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace LoanEnqueryResponse {
  export const Loan = t.type({
    body: t.type({
      action: Maybe(t.string),
      contractType: Maybe(t.string),
      subfileOccurs: Maybe(
        t.array(
          t.type({
            accuredInterest: Maybe(t.number),
            pendingInterest: Maybe(t.number),
            currentBalance: Maybe(t.number),
            loanNumber: Maybe(t.number),
            loanStartDate: Maybe(t.string),
            loanType: Maybe(t.string),
            principalAmount: Maybe(t.number),
            policyLoanTotal: Maybe(t.number)
          })
        )
      )
    })
  })
  export type Loan = t.TypeOf<typeof Loan>
}
