import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { NumberFromString } from 'io-ts-types'
import { POApi } from '../POApi'

export const QCDataC = t.type({
  data: t.type({
    errorId: Maybe(t.string),
    lastUser: Maybe(t.string),
    remark: Maybe(t.string),
    createdDate: Maybe(t.string),
    createdBy: Maybe(t.string),
    totalScore: NumberFromString,
    detailList: t.array(
      t.type({
        errorId: t.string,
        qcCheck: t.boolean,
        minusScore: t.number,
        desc_en: t.string,
        desc_vi: t.string
      })
    )
  })
})

export interface QCDataSubmit {
  processInstanceId: string
  lastUser: string
  totalScore: string
  remark: string
  userId: string
  detailList: {
    errorId: string
    qcCheck: boolean
    minusScore: number
  }[]
  activityBasket?: string
}
export namespace QCService {
  export const getQCSave = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/qc-score/get-lasted/${processInstanceId}`)(QCDataC),
      ZIO.map((policy) => policy.data)
    )

  export const getQCUWSave = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/qc-score/get-lasted/${processInstanceId}?activityBasket=UW-QC`)(QCDataC),
      ZIO.map((policy) => policy.data)
    )

  export const saveQC = (data: QCDataSubmit) => pipe(POApi.post(`pulseops/api/v1/qc-score/save-history`)(QCDataC)(data))
}
