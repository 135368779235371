import React, { useRef, useState } from 'react'
import { View, ScrollView, SafeAreaView, StyleSheet, TouchableOpacity, Animated } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { AppContext, ErrorHandling, RBAC, RoundedButton, Form, assets, PulseOpsFormat, Permission } from '@pulseops/common'
import { InfoHeader, DaAddLackService, AddLackInterface } from './common'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import {
  TableContainer,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core'
import { StackScreenProps } from '@react-navigation/stack'
import { UtilitiesStackParamList } from '../utilites-stack-params-list'
import { Input } from '@pulseops/submission/common'
import { useForm } from 'react-hook-form'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'

type addLackForm = {
  agentCode: string
}

const useStyles = makeStyles({
  tableHeader: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#F4F4F4'
  },
  headerCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
})

interface headerInfo {
  agentCode?: string
  agentName?: string
  idNumber?: string
  eRef?: string
  caseId?: string
}

const MAX_TRANS_Y = 200
const MIN_TRANS_Y = 0
const DURATION = 400

type Props = StackScreenProps<UtilitiesStackParamList, 'DSScreen'>
export const DSScreen = (props: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ht = useTranslation('menu').t
  const [loading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const [isHide, setHide] = useState<boolean>(false)
  const [isDisplayInfo, setIsDisplayInfo] = useState<boolean>(false)
  const transY = useRef(new Animated.Value(200)).current
  const { changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const { navigate } = useNavigation()
  const [headerInfo, setHeaderInfo] = useState<headerInfo>({})
  const [list, setList] = useState<AddLackInterface.TableList[]>([])

  const form = useForm<addLackForm>()

  const { watch, trigger, formState } = form

  const getError = Form.getError(formState)
  const { agentCode } = watch()

  const register = Form.register(form)
  const errors = form.formState.errors

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('Utilities')
          }
        },
        {
          title: ht('DistributionServices'),
          navigate: () => {
            props.navigation.navigate('DistributionServicesScreen')
          }
        },
        {
          title: t('DistributionService:AddLackDoc'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getAgent = () => {
    return pipe(
      DaAddLackService.getAddLackData(agentCode),
      ZIO.tap((res) => {
        if (res && res?.detailList && res?.detailList?.length > 0) {
          const fullName:string = `${res?.candidateInfo?.lastName ?? ''} ${res?.candidateInfo?.firstName ?? ''}`
          setHeaderInfo({
            agentCode: res?.agentCode ?? agentCode,
            agentName: fullName ?? '-',
            idNumber: res?.candidateInfo?.idNo ?? '-',
            eRef: res?.eref ?? '-',
            caseId: res.businessKey ?? '-'
          })
          const newList: AddLackInterface.TableList[] | null =
            res?.detailList && res?.detailList.filter((item) => item.status === 'lackdoc')
          setList(newList ?? [])
          setIsDisplayInfo(true)
        } else {
          showToast(t('message:MS030029'), 'info')
          setHeaderInfo({})
          setList([])
          setIsDisplayInfo(false)
        }
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onSearchAgent = () => {
    if (!!agentCode && !loading && !getError('agentCode')) {
      getAgent()
    }
  }

  React.useEffect(() => {
    trigger().then()
  }, [agentCode])

  const menus = [
    { label: 'Agent code', value: headerInfo?.agentCode ?? '-' },
    { label: 'Agent name', value: headerInfo?.agentName ?? '-' },
    { label: 'ID Number', value: headerInfo?.idNumber ?? '-' },
    { label: 'eREF', value: headerInfo?.eRef ?? '-' },
    { label: 'Case ID', value: headerInfo?.caseId ?? '-' }
  ]

  const opacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  })

  const imageOpacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  const toggleView = () => {
    if (isHide) {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MAX_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(false))
    } else {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MIN_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(true))
    }
  }

  const formatStringToDateString = (value: string) => {
    return value ? moment(value).format('DD/MM/YYYY') : ''
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={styles.container}>
          <Animated.View
            style={{
              alignSelf: 'center',
              height: transY,
              // width: transY
              opacity: imageOpacity
            }}
          >
            <assets.PsImage />
          </Animated.View>
          <View style={{ flexDirection: 'row', paddingVertical: 10, alignItems: 'flex-start', width: '70%' }}>
            <Input
              {...register('agentCode', {
                required: `${t('message:MS020001', { field: t('Agent') })}`,
                validate: {
                  minlength8: (agentCode) => agentCode?.length > 7 || `${t('common:MS090002')}`
                }
              })}
              containerStyle={{ flex: 2, backgroundColor: '#fff' }}
              suffixIcon={() => (
                <TouchableOpacity
                  style={{ marginHorizontal: 15 }}
                  onPress={onSearchAgent}
                  disabled={!agentCode || agentCode.length <= 7}
                >
                  {loading ? (
                    <CircularProgress color={'secondary'} size={25} />
                  ) : agentCode && agentCode.length > 7 ? (
                    <assets.Search20Icon />
                  ) : (
                    <assets.SearchDisableIcon />
                  )}
                </TouchableOpacity>
              )}
              numberOnly={true}
              placeholder={t('common:AgentSearch')}
              errorMessage={getError('agentCode')}
              maxLength={8}
              value={agentCode ?? ''}
              onIconPressed={() => {
                agentCode && agentCode.length > 7 && onSearchAgent()
              }}
            />
            {/* <TouchableOpacity
              onPress={toggleView}
              style={{
                backgroundColor: 'white',getAddLackData
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
                width: 45,
                height: '100%',
                marginLeft: 20
              }}
              activeOpacity={0.4}
            >
              {!isHide ? <assets.OpenMenu /> : <assets.CloseMenu />}
            </TouchableOpacity> */}
          </View>
        </View>
        {isDisplayInfo && (
          <View style={{ width: '70%', justifyContent: 'center', marginHorizontal: 'auto', marginBottom: 50 }}>
            <InfoHeader menus={menus} />

            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableCell}>
                    <TableCell className={classes.headerCell}>{t('Reports:DocumentName').toUpperCase()}</TableCell>
                    <TableCell className={classes.headerCell}>{t('common:Status').toUpperCase()}</TableCell>
                    <TableCell className={classes.headerCell}>
                      {t('SupplementaryInfo:UpdatedDate').toUpperCase()}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list &&
                    list.map((cell, index) => (
                      <TableRow key={index}>
                        <TableCell>{cell.documentsEn ?? ''}</TableCell>
                        <TableCell>{cell.status ?? ''}</TableCell>
                        <TableCell>{formatStringToDateString(cell.lastedDate ?? '')}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <View style={{ marginTop: 20 }}>
              <RoundedButton
                text={'Supplement'}
                textStyle={{ fontSize: 18 }}
                activeOpacity={0.7}
                style={[{ width: '100%', maxWidth: 200, backgroundColor: '#ED1B2E', height: 46, borderRadius: 8 }]}
                showBorder={true}
                textColorEnable="#fff"
                bgColorDisable="#ED1B2E"
                textColorDisable="gray"
                borderColorEnable="#ED1B2E"
                borderColorDisable="gray"
                disabled={roles && !roles.includes(Permission.EditAddLackDoc)}
                onPress={() =>
                  props.navigation.navigate('AddLackScreen', {
                    agentCode: headerInfo.agentCode,
                    eRef: headerInfo.eRef,
                    idNumber: headerInfo.idNumber,
                    caseId: headerInfo.caseId
                  })
                }
              />
            </View>
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 40
  },
  body: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '70%'
  },
  row: {
    width: '100%',
    flexDirection: 'column'
  }
})
