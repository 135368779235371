import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const ContractInfoC = t.type({
  masterContractNo: t.string,
  submitDate: DateFromISOString,
  policyHolder: t.type({
    policyHolder: t.string,
    taxCode: t.string
  }),
  policyYear: t.number
})
export type ContractInfo = t.TypeOf<typeof ContractInfoC>
