import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

export const PayLoadCommonRequest = t.type({
  transactionType: t.union([
    t.literal(TransactionType.COMMON_REQUEST_FORM),
    t.literal(TransactionType.MAJOR_CHANGE_IN_FREE_LOOK),
    t.literal(TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK),
    t.literal(TransactionType.OTHER_MAJOR_ALTERATION),
    t.literal(TransactionType.OTHER_MINOR_ALTERATION),
    t.literal(TransactionType.OTHER_PAYOUT)
  ]),
  payload: t.type({
    body: t.type({
      request: t.string,
      transWf: Maybe(t.string)
    })
  }),
  source: Maybe(t.string)
})

export type PayLoadCommonRequest = t.TypeOf<typeof PayLoadCommonRequest>
