import { ZIO } from '@mxt/zio'
import {
  AddLackOfDocumentModel,
  AddLackOfDocumentService,
  assets,
  ErrorHandling,
  ImgUploadMutiple,
  // Locale,
  Panel,
  Select,
  StorageBlob,
  // T0Data,
  useMobile
} from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import { useFocusEffect } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import React, { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { AddLackForm } from './add-lack-of-documents-form'
interface Props {
  form: Omit<UseFormReturn<AddLackForm.Raw>, 'handleSubmit'>
  policyNum: string
  index: number
  remove: (index?: number | number[] | undefined) => void
  officeCode?: string
}

type Option = {
  label: string
  value: string
  claimNumber?: string | null
}

export const SupplementaryInfomation: React.FC<Props> = ({ form, policyNum, index, remove, officeCode }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()

  const [caseIds, setCaseIds] = useState<Option[]>([])
  const [suspends, setSuspends] = useState<Option[]>([])
  // const [docType, setDocType] = useState<string>('')
  const [suspendData, setSuspendData] = useState<AddLackOfDocumentModel.SuppendType[]>([])
  const [claimNumber, setClaimNumber] = useState<string>('')

  const { control, setValue } = form

  // Fecth API

  useFocusEffect(
    React.useCallback(() => {
      pipe(
        AddLackOfDocumentService.getListCaseId(policyNum),
        ZIO.map((data) => {
          let options: Option[] = []
          options = data.body.map(({ caseId, claimNumber }) => ({
            label: caseId,
            value: caseId,
            claimNumber: claimNumber
          }))
          setCaseIds(options)
        }),
        ErrorHandling.run()
      )
    }, [])
  )

  // const translate = (en: string, vi: string) => {
  //   return i18n.language === Locale.en ? en : vi
  // }

  const onChangeCaseId = (e: Option | null) => {
    if (!!e) {
      onGetSuspendByCaseId(e.value)
      setClaimNumber(e.claimNumber || '')
    }
  }

  const onGetSuspendByCaseId = (caseId: string) => {
    pipe(
      AddLackOfDocumentService.getSuspendType(caseId),
      ZIO.map((data) => {
        // const transactionTypeItem = T0Data.getValue(data.body[0].transactionType)
        setSuspendData(data.body)
        setValue(`addLackOfDocuments.${index}.transactionType`, data.body[0].transactionType)

        // const doc = transactionTypeItem
        //   ? translate(transactionTypeItem.transactionType, transactionTypeItem.transactionTypeVn)
        //   : ''

        // setDocType(doc)
        let options: Option[] = []
        options = data.body.map((suspendItem) => ({
          ...suspendItem,
          value: suspendItem.code,
          label: i18n.language === 'en' ? suspendItem.typeEn : suspendItem.typeVn
        }))
        setSuspends(options)
      }),
      ErrorHandling.run()
    )
  }

  const onChangeSubType = (e: Option | null) => {
    let metaData: StorageBlob.MetaDataUpload | undefined
    const sType = suspendData.find(({ code }) => code === e?.value)

    if (sType) {
      if (sType.metadata) {
        const { transactionType, docTypeEn, classFile, docId, mainDoc, subDoc, type: typeCode } = sType.metadata
        // const officeCode = localStorage.getItem('officeCode')
        const currentDateFormat = moment().format('DD/MM/YYYY')
        const customedBatchNo = !!officeCode
          ? currentDateFormat + '-' + typeCode + '-' + officeCode
          : currentDateFormat + '-' + typeCode
        metaData = {
          type: transactionType,
          doctype: docTypeEn,
          class: classFile,
          docid: docId,
          maindoc: mainDoc,
          subdoc: subDoc,
          polnum: policyNum,
          batchno: customedBatchNo
        }
      }
      setValue(`addLackOfDocuments.${index}.metaData`, metaData)
    }
  }

  const onRemoveItem = (index: number) => {
    remove(index)
  }

  return (
    <Panel title="">
      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={isMobile ? styles.colFull : styles.col}>
          <Controller
            name={`addLackOfDocuments.${index}.caseId` as 'addLackOfDocuments.0.caseId'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                required
                label={t('submission:CaseId')}
                onChange={(e) => {
                  onChange(e)
                  onChangeCaseId(e)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                placeholder={t('common:Select')}
                options={caseIds}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.colFull : styles.col}>
          <Controller
            name={`addLackOfDocuments.${index}.suspendCode` as 'addLackOfDocuments.0.suspendCode'}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                required
                label={t('submission:SuspendType')}
                onChange={(e) => {
                  onChange(e)
                  onChangeSubType(e)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                placeholder={t('common:Select')}
                options={suspends}
              />
            )}
          />
        </View>
        {/* {docType && (
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('submission:AddDocumentForRequest')}</Text>
            </View>
            <Text style={styles.text}>{docType}</Text>
          </View>
        )} */}
        {claimNumber && (
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('submission:ClaimNumber')}</Text>
            </View>
            <Text style={styles.text}>{claimNumber}</Text>
          </View>
        )}
      </View>
      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={styles.colFull}>
          <View style={[styles.row, styles.mB5]}>
            <Text style={[styles.mR5, styles.label]}>{t('IFQ:FileAttachment')}</Text>
            <Text style={styles.required}>*</Text>
          </View>
          <Controller
            control={control}
            name={`addLackOfDocuments.${index}.attachments` as 'addLackOfDocuments.0.attachments'}
            render={({ field, fieldState: { error } }) => (
              <Column>
                <ImgUploadMutiple {...field} value={field.value as any[]} errorMessage={error?.message || ''} />
                {error?.message && <Error message={error.message} />}​​​
              </Column>
            )}
          />
        </View>
      </View>

      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={isMobile ? styles.colFull : styles.col}>
          <TouchableOpacity onPress={() => onRemoveItem(index)} style={[styles.row, { alignItems: 'center' }]}>
            <assets.DeleteBin />
            <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Delete')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Panel>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  text: {
    fontSize: 15
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
