import { View, Text, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import {
  ErrorHandling,
  form2,
  PulseOpsFormat,
  useMobile,
  assets,
  SelectOption,
  Alert,
  FileUploadData,
  AppContext
} from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { LandingPageCCCDStackParamList } from './LandingPageCCCDStackParamList'
import { BorderFilledDatePicker, BorderFilledInput, SC, Services, Button, ClientQuery } from './core'
import { Header } from './Header'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import { withMinStringLength, withMaxStringLength, withMaxDate } from './validators'
import * as t from 'io-ts'
import _ from 'lodash'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { withMessage } from 'io-ts-types'
type Props = StackScreenProps<LandingPageCCCDStackParamList, 'ChangeNationalIdCardScreen'>

const FormC = t.type({
  id: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:id') })),
    withMinStringLength(8, 'message:MS090008'),
    withMaxStringLength(24, 'message:MS090008')
  ),
  issueDate: pipe(
    withMessage(form2.date.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('landingPage:IssueDate') })
    ),
    withMaxDate(new Date(), 'message:MS050013', 'landingPage:IssueDate')
  ),
  issueBy: form2.selectOption.requiredM(() =>
    i18next.t('message:MS020001', { field: i18next.t('landingPage:IssueBy') })
  ),
  selectClient: form2.selectOption.requiredM(() =>
    i18next.t('message:MS020001', { field: i18next.t('landingPage:selectClient') })
  )
})

export const ChangeNationalIDCard = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation('landingPage')
  const { t: m } = useTranslation('message')
  const { params } = props.route
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  // const YESTERDAY = PulseOpsFormat.getYesterday()
  const [lstCustomer, setLstCustomer] = React.useState<ClientQuery[]>([])

  const getRoles = (role: string) => {
    return role === 'PO'
      ? t('roles:PolicyOwner')
      : role === 'BEN'
      ? t('roles:Beneficiary')
      : role === 'LA'
      ? t('roles:LifeAssured')
      : '-'
  }

  const ownerInfo = pipe(
    Services.getOwnerInfo(params.policyNumber),
    ZIO.map((data) => {
      return data
    }),
    ErrorHandling.runDidMount()
  )

  const clients = pipe(
    Services.getClientsByPolicy(params.policyNumber),
    ZIO.map((data) => {
      if ((data?.laList || data?.benList) && ownerInfo) {
        const clientsLA = data?.laList.map((item) => ({
            clientId: item.clientNumberOfLA,
            role: 'LA'
          })),
          clientsBen = data?.benList.map((item) => ({ clientId: item.clientNumberOfBen, role: 'BEN' }))
        const clients = _.uniqBy([...clientsBen, ...clientsLA], 'clientId').map((item) => ({
          clientId: item.clientId,
          role: item.clientId === ownerInfo?.clientId ? 'PO' : item.role
        }))
        clients.some((item) => item.clientId === ownerInfo?.clientId)
          ? clients
          : clients.push({ clientId: ownerInfo?.clientId || '', role: 'PO' })
        return clients
      }
      return []
    }),
    ErrorHandling.runDidUpdate([ownerInfo])
  )

  const customer: SelectOption[] =
    pipe(
      Services.getClients(clients || []),
      ZIO.map((data) => {
        setLstCustomer(data.body)

        return data.body.map((item) => ({
          label: `${item.name} - ${getRoles(clients?.find((x) => x.clientId === item.clientId)?.role || '')}` || '',
          value: item.clientId || ''
        }))
      }),
      ErrorHandling.runDidUpdate([clients])
    ) || []

  const listIssueBy: SelectOption[] =
    pipe(
      Services.getIssuedPlaceList(),
      ZIO.map((data) => {
        return (data || [])
          .filter((item) => item.code === '')
          .map((item) => ({ label: item.issueBy || '', value: item.issueBy || '' }))
      }),
      ErrorHandling.runDidMount()
    ) || []

  const {
    base: {
      control,
      formState: { errors },
      setValue,
      watch
    },
    handleSubmit
  } = form2.useForm(FormC)

  const onSumit = handleSubmit((data) => {
    if ((params.files?.length as number) > 0) {
      return pipe(
        Services.compareIdCard(data.selectClient.value, {
          clientName: data.selectClient.label.split(' - ')[0],
          clientNumber: data.selectClient.value,
          identifyNumberUserInput: data.id,
          identifyNumberOCR: params.identifyNumberOCR,
          idExprieDateOCR: moment(params.idExpreiDateOCR, 'DD/MM/YYYY', true).isValid() ? params.idExpreiDateOCR : null,
          fullNameOCR: params.fullNameOCR,
          dob: moment(params.dobOCR, 'DD/MM/YYYY').format('YYYYMMDD'),
          gender: params.sexOCR === 'NỮ' ? 'F' : 'M'
        }),
        ZIO.flatMap((identify) => {
          const navigateOTPRequestScreen = () => {
            const sendDate = moment().format('yyMMDDhhmmss').substr(2, 12)
            const transactionCode = `PS-${params.policyNumber}-${sendDate}`
            pipe(
              Services.otpRequest(
                'Dieu chinh can cuoc cong dan',
                transactionCode,
                params.phoneNumber,
                params.policyNumber
              ),
              ZIO.tap(() =>
                ZIO.effect(() => {
                  props.navigation.navigate('OTPRequestScreen', {
                    policyNumber: params.policyNumber,
                    files: params.files as FileUploadData[],
                    clientId: data.selectClient.value,
                    ownerName: ownerInfo?.name || '',
                    client: {
                      name: data.selectClient.label.split(' - ')[0],
                      dob: lstCustomer.find((item) => item.clientId === data.selectClient.value)?.dob || '',
                      sex: lstCustomer.find((item) => item.clientId === data.selectClient.value)?.sex || '',
                      clientId: data.selectClient.value,
                      firstName: '',
                      surName: '',
                      nationality: 'VN',
                      attributesExt: {
                        clientNum: data.selectClient.value,
                        clientType: '',
                        idType: 'ID',
                        issuedBy: data.issueBy.label,
                        idNumber: data.id,
                        issuedDate: moment(data.issueDate).format('YYYY-MM-DD'),
                        validOCR: identify.body.oErrorResult.errorDescription
                      },
                      externalIds: {
                        NATIONAL_ID: data.id
                      }
                    },
                    attributesExt: {
                      idNumber: data.id,
                      validOCR: identify.body.oErrorResult.errorDescription,
                      issuedBy: data.issueBy.label,
                      issuedDate: moment(data.issueDate).format('DD/MM/YYYY')
                    },
                    transactionCode,
                    phoneNumber: params.phoneNumber
                  })
                })
              ),
              ZIO.catchAll((error) => {
                const path = `source.response.data.header.responseStatus.errors.0.code`
                const code = _.get(error, path)
                return ZIO.effect(() => {
                  showToast(code ? m(code) : m('MS030029'), 'error', {
                    horizontal: 'center',
                    vertical: 'top'
                  })
                })
              }),
              ZIO.unsafeRun({})
            )
          }

          if (identify.body.oErrorResult.errorDescription === '1') {
            navigateOTPRequestScreen()
            return ZIO.unit
          } else {
            return Alert.modalM({
              title: t('warning'),
              content:
                'Nội dung yêu cầu chưa trùng khớp với chừng từ đính kèm. Quý khách có muốn tiếp tục nộp yêu cầu.',
              size: {
                width: isMobile ? '90%' : '50%'
              },
              onOK: () => {
                navigateOTPRequestScreen()
              },
              onClose: () => {
                console.log(data)
              }
            })
          }
        }),
        ErrorHandling.run()
      )
    } else {
      showToast(t('WarningPleaseFill'), 'error', {
        horizontal: 'center',
        vertical: 'top'
      })
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#FFFFFF' }}>
      <Header isWide={isWide} t={t} />
      <ScrollView>
        <SC.ContainerPolicyList maxWidth="566" isWide={isWide}>
          <View style={styles.form}>
            <SC.Form.Group marginBottom="0">
              <SC.Form.Row marginBottom="18" isMobile={isMobile} alignSelf="center">
                <SC.MessageTitle isWide={isWide}>{m('ChangeNationalIdCard')}</SC.MessageTitle>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <Controller
                  control={control}
                  name="selectClient"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <View style={{ width: '100%' }}>
                      <SC.SelectContainer>
                        <SC.SelectSearch
                          options={customer || []}
                          label={t('selectClient')}
                          value={value ?? null}
                          onChange={(value) => {
                            onChange(value)
                          }}
                          required
                          onBlur={onBlur}
                          placeholder={t('common:Select')}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                        />
                      </SC.SelectContainer>
                      {errors?.selectClient?.message ? (
                        <Text style={{ color: 'red', fontSize: 12, marginTop: 5, fontFamily: 'Noto Sans' }}>
                          {errors?.selectClient?.message}
                        </Text>
                      ) : (
                        ''
                      )}
                    </View>
                  )}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <Controller
                  control={control}
                  name="id"
                  render={({ field }) => (
                    <BorderFilledInput
                      controlStyle={{ width: '100%' }}
                      label={`${t('id')}*`}
                      value={field.value || ''}
                      onChange={(val) => {
                        field.onChange(val == null ? val : val.replace(/\D/g, ''))
                      }}
                      onBlur={field.onBlur}
                      maxLength={24}
                      errorMessage={errors?.id?.message}
                    />
                  )}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <Controller
                  control={control}
                  name="issueDate"
                  render={({ field }) => (
                    <BorderFilledDatePicker
                      label={`${t('IssueDate')} (dd/mm/yyyy)*`}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      // maxDate={YESTERDAY}
                      // maxDateMessage={m('MS050022')}
                      // minDateMessage={m('MS070024')}
                      errorMessage={errors?.issueDate?.message}
                    />
                  )}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <Controller
                  control={control}
                  name="issueBy"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <View style={{ width: '100%' }}>
                      <SC.SelectContainer>
                        <SC.SelectSearch
                          options={listIssueBy || []}
                          label={t('IssueBy')}
                          value={value ?? null}
                          onChange={(value) => {
                            onChange(value)
                          }}
                          required
                          onBlur={onBlur}
                          placeholder={t('common:Select')}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                        />
                      </SC.SelectContainer>
                      {errors?.selectClient?.message ? (
                        <Text style={{ color: 'red', fontSize: 12, marginTop: 5, fontFamily: 'Noto Sans' }}>
                          {errors?.issueBy?.message}
                        </Text>
                      ) : (
                        ''
                      )}
                    </View>
                  )}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="7" isMobile={isMobile}>
                <SC.MessageTitle isWide={isWide}>Chứng từ đính kèm *</SC.MessageTitle>
                {/* <Controller
                  control={control}
                  name={`attachments`}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS150004')}`
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <ImgUploadMutiple value={value} onChange={onChange} timeFormat={'DD/MM/YYYY HH:mm'} />
                  )}
                /> */}
              </SC.Form.Row>
              <SC.Form.Row marginBottom="7" isMobile={isMobile}>
                <SC.NoteText>{t('ImageFormat')}</SC.NoteText>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <TouchableOpacity
                  onPress={() => props.navigation.navigate('UploadCCCD', props.route.params)}
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: 10,
                    borderWidth: 1,
                    borderColor: '#EE1A2D'
                  }}
                >
                  <Text style={{ color: '#EE1A2D', fontFamily: 'Noto Sans' }}>{t('uploadCCCD')}</Text>
                </TouchableOpacity>
              </SC.Form.Row>
              {params.files?.length || 0 > 0 ? (
                <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                  <SC.MessageTitle isWide={isWide}>Chứng từ CCCD</SC.MessageTitle>
                </SC.Form.Row>
              ) : (
                <></>
              )}
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <View style={{ flex: 1 }}>
                  {params.files?.map((file, i) => (
                    <View style={styles.resultItem} key={`resultItem-${i}`}>
                      <Image source={{ uri: file.base64 }} style={{ height: 70, width: 70 }}></Image>
                      <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                        <Text style={{ fontSize: 15 }}>
                          <Text>
                            {file.fileName.length < 10 ? file.fileName : `${file.file.name.substr(0, 5)}...`}{' '}
                          </Text>
                          .{file.fileExtension}
                        </Text>
                        <Text style={{ fontSize: 15, fontStyle: 'italic', marginTop: 20 }}>
                          {PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD/MM/YYYY')}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <Button title={t('continue')} onPress={onSumit} />
              </SC.Form.Row>
            </SC.Form.Group>
          </View>
        </SC.ContainerPolicyList>
      </ScrollView>
      <View style={styles.footer}>
        <SC.FooterText isWide={isWide}>{t('copy_right')}</SC.FooterText>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#000000'
  },
  resultItem: {
    height: 70,
    width: '100%',
    flexDirection: 'row',
    borderBottomColor: '#92B0B3',
    borderBottomWidth: 2,
    borderStyle: 'dashed',
    marginBottom: 10,
    marginHorizontal: 10
  }
})
