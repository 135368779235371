import { ZIO } from '@mxt/zio'
import {
  Alert,
  AppContext,
  capFisrtLetterEachWord,
  // ErrorHandling,
  FieldList,
  form2,
  formatNumberWithComma,
  GeneralService,
  Input,
  Panel,
  PartialWithdrawal,
  PartialWithdrawalService,
  PayoutPopup,
  StorageBlob,
  TaskType,
  Title,
  TransactionType
} from '@pulseops/common'
import { Column } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../../../payout-method'
import { PolicyServiceProps, UploadedFilesInfo } from '../../../policy-service-props'
import _ from 'lodash'
import { RequestAuthenticateData } from '../../../../request-authen'
import { useLoading } from '@mxt/zio-react'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'

type withdrawalAmountBrand = {
  readonly MS050242: unique symbol
  readonly MS050243: unique symbol
  readonly MS050244: unique symbol
  readonly MS050245: unique symbol
}

type withdrawalAmount = t.Branded<NonEmptyString, withdrawalAmountBrand>
export namespace ULPForm {
  const ULP = t.type({
    withdrawalAmount: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:WithdrawAmount') })
      ),
      form2.refine(
        (l): l is withdrawalAmount => Number(l) >= withdrawFormData.minimumPartialWithdrawal,
        () => 'MS050242',
        'MS050242'
      ),
      form2.refine(
        (l): l is withdrawalAmount => Number(l) <= withdrawFormData.maximumPartialWithdrawal,
        () => 'MS050243',
        'MS050243'
      ),
      form2.refine(
        (value): value is withdrawalAmount => Number(value) <= withdrawFormData.estimatedValue,
        () => 'MS050244',
        'MS050244'
      ),
      form2.refine(
        (value): value is withdrawalAmount => Number(value) !== withdrawFormData.estimatedValue,
        () => 'MS050245',
        'MS050245'
      )
    ),
    // newSumAssuredError: form2.string.optional,
    isValid: t.boolean,
    minSA: form2.number.optional,
    newSumAssured: form2.number.optional,
    partialWithdrawFee: form2.number.optional,
    payout: pipe(
      t.array(PayoutPopup.SummaryCodec),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray => arr && arr.length > 0,
        () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
        'EmptyArray'
      ),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray =>
          arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0,
        () => i18next.t('message:MS050241'),
        'TotalAmountLessThanZero'
      )
    )
  })

  export const codec = ULP

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

let withdrawFormData: ULPForm.Raw & {
  minimumPartialWithdrawal: number
  maximumPartialWithdrawal: number
  estimatedValue: number
} = {
  withdrawalAmount: '',
  // newSumAssuredError: '',
  minSA: '',
  isValid: false,
  newSumAssured: '',
  partialWithdrawFee: '',
  minimumPartialWithdrawal: 0,
  maximumPartialWithdrawal: 0,
  estimatedValue: 0,
  payout: []
}

interface Props extends PolicyServiceProps<PartialWithdrawal.SubmitData> {
  policyNumber: string
  detail: any
}

export const ULP = ({ detail, policyNumber, isConfirmed, initSubmission, officeCode }: Props) => {
  const { t } = useTranslation()

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  const { base } = form2.useForm(ULPForm.codec, {
    defaultValues: {
      payout: []
    }
  })

  withdrawFormData = Object.assign(withdrawFormData, {
    ...base.watch(),
    minimumPartialWithdrawal: detail.ulp.minimumPartialWithdrawal,
    maximumPartialWithdrawal: detail.ulp.maximumPartialWithdrawal,
    estimatedValue: detail.ulp.estimatedValue
  })

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (detail && detail?.ulp) {
      base.setValue('withdrawalAmount', null)
      base.clearErrors('withdrawalAmount')
      base.setValue('newSumAssured', String(detail?.ulp?.newSumAssured))
      base.setValue('partialWithdrawFee', String(detail?.ulp?.partialWithdrawFee))
    }
  }, [policyNumber])

  const validateWithdrawalAmount = (value: number, isUpdatedWithdrawAmount = false) => {
    const A = detail?.ulp?.minimumPartialWithdrawal || 0
    const B = detail?.ulp?.maximumPartialWithdrawal || 0
    const C = detail?.ulp?.estimatedValue || 0
    const P = value
    if (P) {
      if (P < A) {
        return
      } else if (P > B) {
        return
      } else if (P > C) {
        return
      } else if (P === C) {
        // Alert.alert(t('message:MS050246'))
        showToast(t('message:MS050246'), 'warning')
        return
      } else {
        updateNSAAndPWF(P)
        base.clearErrors('withdrawalAmount')
      }
    }
  }

  const getMessageByCode = (code: string | undefined) => {
    let message = ''
    const A = detail?.ulp?.minimumPartialWithdrawal || 0
    const B = detail?.ulp?.maximumPartialWithdrawal || 0
    switch (code) {
      case 'MS050242':
        message = t('message:MS050242', { Min: `${formatNumberWithComma(A)} VND` })
        break
      case 'MS050243':
        message = t('message:MS050243', { Max: `${formatNumberWithComma(B)} VND` })
        break
      case 'MS050244':
        message = t('message:MS050244')
        break
      case 'MS050245':
        message = t('message:MS050245')
        break
      default:
        message = code ?? ''
    }
    return message
  }

  const setNewSumAssuredValidation = (newSumAssured: number) => {
    return pipe(
      PartialWithdrawalService.getNewSumAssuredValidation(policyNumber, newSumAssured),
      ZIO.map((newSAMData) => {
        if (!!newSAMData) {
          base.setValue('isValid', newSAMData.isValid)
          base.setValue('minSA', String(newSAMData.minSA))
          !newSAMData.isValid &&
            showToast(t('message:MS050056', { min: `${formatNumberWithComma(newSAMData.minSA)} VND` }), 'error')
        }
      })
    )
  }

  const updateNSAAndPWF = (withdrawAmount: number) => {
    pipe(
      detail.ulp?.existInT5538
        ? pipe(
            ZIO.effect(() => {
              let topupFundAmount = 0,
                targetFundAmount = 0
              let fundEPA = '',
                fundTPA = ''
              if (detail.ulp?.fundList && detail.ulp?.fundList.length > 0) {
                fundEPA = detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => x.isEPA)?.fundCode ?? ''
                fundTPA = detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => !x.isEPA)?.fundCode ?? ''
                topupFundAmount = Number(
                  detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => x.isEPA)?.estimatedValue ?? 0
                )
                targetFundAmount = Number(
                  detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => !x.isEPA)?.estimatedValue ?? 0
                )
              }
              return {
                fundEPA,
                fundTPA,
                topupFundAmount,
                targetFundAmount
              }
            }),
            ZIO.flatMap((topUpFundInfo) => {
              return withdrawAmount > topUpFundInfo.topupFundAmount
                ? PartialWithdrawalService.getUlpValue2(
                    policyNumber,
                    topUpFundInfo.topupFundAmount,
                    withdrawAmount,
                    topUpFundInfo.fundEPA,
                    topUpFundInfo.fundTPA
                  )
                : PartialWithdrawalService.getUlpValue1(policyNumber, withdrawAmount, topUpFundInfo.fundEPA)
            }),
            ZIO.map(({ newSumAssured, partialWithdrawFee }) => {
              base.setValue('newSumAssured', String(newSumAssured))
              base.setValue('partialWithdrawFee', String(partialWithdrawFee))
              return { newSumAssured, partialWithdrawFee }
            })
          )
        : pipe(
            ZIO.effect(() => {
              const fundTPACode =
                detail.ulp?.fundList && detail.ulp?.fundList.length > 0
                  ? detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => !x.isEPA)?.fundCode ?? ''
                  : ''
              return fundTPACode
            }),
            ZIO.flatMap((fundCode) => PartialWithdrawalService.getIlpValue3(policyNumber, withdrawAmount, fundCode)),
            ZIO.map(({ newSumAssured, partialWithdrawFee }) => {
              base.setValue('newSumAssured', String(newSumAssured))
              base.setValue('partialWithdrawFee', String(partialWithdrawFee))
              return { newSumAssured: newSumAssured, partialWithdrawFee }
            })
          ),
      ZIO.flatMap((newSumData) => {
        return setNewSumAssuredValidation(newSumData.newSumAssured)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const checkValidPolicyFromLAS = (withdrawAmount: number) => {
    const validatedPolicyNum = policyNumber + 'VL'
    return pipe(
      detail.ulp?.existInT5538
        ? pipe(
            ZIO.effect(() => {
              let topupFundAmount = 0,
                targetFundAmount = 0
              let fundEPA = '',
                fundTPA = ''
              if (detail.ulp?.fundList && detail.ulp?.fundList.length > 0) {
                fundEPA = detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => x.isEPA)?.fundCode ?? ''
                fundTPA = detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => !x.isEPA)?.fundCode ?? ''
                topupFundAmount = Number(
                  detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => x.isEPA)?.estimatedValue ?? 0
                )
                targetFundAmount = Number(
                  detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => !x.isEPA)?.estimatedValue ?? 0
                )
              }
              return {
                fundEPA,
                fundTPA,
                topupFundAmount,
                targetFundAmount
              }
            }),
            ZIO.flatMap((topUpFundInfo) => {
              return withdrawAmount > topUpFundInfo.topupFundAmount
                ? PartialWithdrawalService.validateUlpValue2(
                    validatedPolicyNum,
                    topUpFundInfo.topupFundAmount,
                    withdrawAmount,
                    topUpFundInfo.fundEPA,
                    topUpFundInfo.fundTPA
                  )
                : PartialWithdrawalService.validateUlpValue1(validatedPolicyNum, withdrawAmount, topUpFundInfo.fundEPA)
            })
          )
        : pipe(
            ZIO.effect(() => {
              const fundTPACode =
                detail.ulp?.fundList && detail.ulp?.fundList.length > 0
                  ? detail.ulp?.fundList.find((x: PartialWithdrawal.fundULPList) => !x.isEPA)?.fundCode ?? ''
                  : ''
              return fundTPACode
            }),
            ZIO.flatMap((fundCode) =>
              PartialWithdrawalService.validateIlpValue3(validatedPolicyNum, withdrawAmount, fundCode)
            )
          ),
      ZIO.foldM(
        (error) => {
          // console.log('error', error)
          // console.log('error', error.source.message)
          showToast(t('message:MS050034') + error.source.message, 'error')
          return ZIO.fail(false)
        },
        (success) => {
          if (success.code === 1 && success.message === 'Success') {
            return ZIO.succeed(true)
          } else {
            showToast(t('message:MS050034') + success.message, 'error')
            return ZIO.succeed(false)
          }
        }
      )
    )
  }

  const totalPayoutAmount = React.useMemo(() => {
    return base.getValues().payout.reduce((total, item) => total + item.amount, 0)
  }, [base.watch('payout')])

  const validateNextButton = () => {
    return pipe(
      ZIO.effect(() => {
        let isFormValid = false
        const coverageCode = detail?.coverageCode
        const withdrawalAmount = base.watch('withdrawalAmount')
        if (base.watch('payout').length === 0) {
          base.setError('payout', {
            message: t('message:MS020001', { field: capFisrtLetterEachWord(t('submission:PAYOUT_INFO')) })
          })
        } else if (totalPayoutAmount === 0) {
          base.setError('payout', { message: t('message:MS050241') })
        } else if (totalPayoutAmount != Number(withdrawalAmount)) {
          base.setError('payout', { message: t('message:MS050237') })
        } else if (!base.watch('isValid')) {
          showToast(t('message:MS050056', { min: `${formatNumberWithComma(base.watch('minSA'))} VND` }), 'error')
        } else {
          if (
            ['VLR6', 'VLR7', 'VLR8'].includes(coverageCode) &&
            Number(withdrawalAmount) === detail?.ulp.maximumPartialWithdrawal
          ) {
            // Alert.alert(t('message:MS050245'))
            showToast(t('message:MS050245'), 'warning')
          }
          isFormValid = true
          base.clearErrors('payout')
        }
        return isFormValid
      }),
      ZIO.flatMap((isFormValid) => {
        const withdrawVal = Number(base.watch('withdrawalAmount') || 0)
        return isFormValid ? checkValidPolicyFromLAS(withdrawVal) : ZIO.succeed(isFormValid)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getUploadedFilesInfo = (formData: ULPForm.Raw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(formData.payout),
      transactionType: TransactionType.PARTIAL_WITHDRAWAL,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async () => {
      const isFormValid = (await base.trigger()) && (await validateNextButton())
      if (isFormValid) {
        const formValue = base.getValues()
        const withdrawalAmount = Number(base.watch('withdrawalAmount'))
        const newSumAssured = Number(base.watch('newSumAssured'))
        const partialWithdrawFee = Number(base.watch('partialWithdrawFee'))
        // if (!isContinue) {
        //   uploadedFiles = await pipe(
        //     GeneralService.getAzureStorageFiles(
        //       GeneralService.getPayoutDocuments(formValue.payout),
        //       TransactionType.PARTIAL_WITHDRAWAL,
        //       'DPS01',
        //       TaskType.PolicyService,
        //       policyNumber,
        //       officeCode ?? ''
        //     ),
        //     bindLoader,
        //     ZIO.unsafeRun({})
        //   )
        // }
        return {
          url: (policyNum) => `wf-api/policy/${policyNum}/partial-withdrawal`,
          collerationId: policyNumber,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PARTIAL_WITHDRAWAL),
          transaction: TransactionType.PARTIAL_WITHDRAWAL,
          body: {
            cashOutOptions: mapCashOutOption(formValue.payout),
            policy: {
              policyNo: policyNumber
            },
            attributesExt: {
              CURRENT_SUM_ASSURED: detail.ulp.currentSumAssured,
              NEW_SUM_ASSURED: newSumAssured,
              WITHDRAW_AMOUNT: withdrawalAmount,
              // common
              TOTAL_PAYOUT_AMOUNT: totalPayoutAmount,
              ESTIMATED_VALUE: detail.ulp.estimatedValue,
              MINIMUM_PARTIAL_WITHDRAWAL: detail.ulp.minimumPartialWithdrawal,
              MAXIMUM_PARTIAL_WITHDRAWAL: detail.ulp.maximumPartialWithdrawal,
              WITHDRAW_FEE: partialWithdrawFee
            }
          },
          uploadedFilesInfo: getUploadedFilesInfo(formValue)
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset({
        withdrawalAmount: '',
        newSumAssured: String(detail?.ulp?.newSumAssured),
        partialWithdrawFee: String(detail?.ulp?.partialWithdrawFee),
        payout: []
      })
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('requestInfo:PARTIAL_WITHDRAWAL_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <FieldList
          dataSource={[
            {
              label: t('requestInfo:EstimatedValue'),
              value: formatNumberWithComma(detail.ulp.estimatedValue),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Minimumpartialwithdrawal'),
              value: formatNumberWithComma(detail.ulp.minimumPartialWithdrawal),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:Maximumpartialwithdrawal'),
              value: formatNumberWithComma(detail.ulp.maximumPartialWithdrawal),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:CurrentSumAssured'),
              value: formatNumberWithComma(detail.ulp.currentSumAssured),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:NewSumAssured'),
              // value: formatNumberWithComma(detail?.ulp?.newSumAssured || ''),
              value: formatNumberWithComma(base.watch('newSumAssured')),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:PartialWithdrawfee'),
              // value: formatNumberWithComma(detail.ulp.partialWithdrawFee),
              value: formatNumberWithComma(base.watch('partialWithdrawFee')),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:WithdrawAmount'),
              suffix: 'VND',
              required: true,
              render: () => {
                return (
                  <Controller
                    control={base.control}
                    name="withdrawalAmount"
                    render={({ field, formState: { errors } }) => (
                      <Input
                        value={field.value || ''}
                        inputType="money"
                        disabled={isConfirmed}
                        containerStyle={{ marginEnd: 30 }}
                        inputStyle={{ color: '#ED1B2E', fontSize: 18, fontWeight: 'bold' }}
                        errorMessage={getMessageByCode(_.get(errors, 'withdrawalAmount')?.message)}
                        onBlur={() => {
                          const withdrawVal = Number(base.watch('withdrawalAmount') || 0)
                          field.onBlur()
                          validateWithdrawalAmount(withdrawVal)
                        }}
                        onChange={(val) => {
                          field.onChange(val)
                        }}
                        maxLength={12}
                      />
                    )}
                  />
                )
              }
            }
          ]}
        />
      </Panel>

      <Controller
        control={base.control}
        name="payout"
        render={({ field, fieldState: { error } }) => (
          <PayoutMethod
            {...field}
            ref={payoutRef}
            policyNum={policyNumber}
            transactionType={TransactionType.PARTIAL_WITHDRAWAL}
            otherPremiumTitle={t('submission:OtherFee_Alteration')}
            errorMessage={error?.message}
            editable={!isConfirmed}
            maxAmount={Number(base.watch('withdrawalAmount'))}
            methods={[
              PayoutPopup.PayoutMethods.PAYPREMIUM,
              PayoutPopup.PayoutMethods.PAYLOAN,
              PayoutPopup.PayoutMethods.CASHLESS,
              PayoutPopup.PayoutMethods.OTHER,
              PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
              PayoutPopup.PayoutMethods.PAID_AT_BANK,
              PayoutPopup.PayoutMethods.BANKTRANSFER,
              PayoutPopup.PayoutMethods.MOMO
            ]}
            officeCode={officeCode}
            isSeaBankPolicy={!!detail.warningMessage}
          />
        )}
      />
    </Column>
  )
}
