import { CashOut } from './CashOutOption'

export namespace MaturityAdvanceGroup {
  export interface Detail {
    estimatedTotalMaturity: number
    preMaturityAdvance: number
    totalMaturityAmount: number
  }

  export interface DataSubmit {
    attributesExt: {
      TOTAL_MATURITY_AMOUNT: number
    }
    cashOutOptions: Array<CashOut.Option>
  }

  export interface AccessDataCheck {
    /**
     * item MATUR, NOMAADV, MATURVAL --> call api get group CoverageCode
     * k months = 6 months
     */

    contractStatus: string
    maturityAdvValid: boolean
  }
}
