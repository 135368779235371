import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle, ScrollView } from 'react-native'
import { Checkbox, Label, Row } from '@pulseops/submission/common'

export interface RowSelectedProps {
  index: number
  isSelected: boolean
}

export type Columns = {
  key: string | number
  title: string | JSX.Element
  name: string
  styles?: TextStyle
  flex?: number
  require?: boolean
  color?: (val: string) => string
  render?: (props: RowSelectedProps) => JSX.Element
}

export type DataSource = {
  [key: string]: string | number | JSX.Element
}
const TableHeader = ({
  columns,
  isHighlight,
  containerStyle,
  titleStyle,
  selectable = false,
  selectBoxContainerStyle,
  unit,
  isAllSelected = false,
  isDisabled = false,
  onCheckAllSelectedChange
}: {
  columns: Columns[]
  isHighlight?: boolean
  containerStyle?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  selectable?: boolean
  selectBoxContainerStyle?: StyleProp<ViewStyle>
  unit?: string
  isAllSelected?: boolean
  isDisabled?: boolean
  onCheckAllSelectedChange?: (isChecked: boolean) => void
}) => {
  const { t } = useTranslation()

  return (
    <View style={[containerStyle]}>
      {unit && (
        <Row justifyContent="flex-end" alignItems="center" marginTop={16}>
          <Label title={`${t('Payout:Currency')}: ${unit}`} fontStyle="italic" />
        </Row>
      )}
      <View style={[styles.tableRow, { borderTopWidth: 0 }]}>
        {selectable && (
          <Checkbox
            style={[{ marginEnd: 16 }, selectBoxContainerStyle]}
            value={isAllSelected}
            enable={!isDisabled}
            onChange={onCheckAllSelectedChange}
          />
        )}
        {columns.map((column) => (
          <View key={column.key} style={[styles.tableCol, { flex: column.flex ? column.flex : 1 / columns.length }]}>
            <Text
              style={[styles.tableColHeaderText, column.styles, isHighlight && styles.tableHeaderHighlight, titleStyle]}
            >
              {column.title}
              {column.require === true && (
                <Text style={[styles.tableColHeaderText, { color: 'red', marginStart: 4 }]}>*</Text>
              )}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}

const TableRow = ({
  index,
  cols,
  columns,
  containerStyle,
  selectable = false,
  isSelectAll = false,
  isDisabled = false,
  selectBoxContainerStyle,
  onRowSelected,
  onRowUnSelected
}: {
  index: number
  cols: DataSource
  columns: Columns[]
  containerStyle?: StyleProp<ViewStyle>
  selectable?: boolean
  isSelectAll?: boolean
  isDisabled?: boolean
  selectBoxContainerStyle?: StyleProp<ViewStyle>
  onRowSelected?: (index: number, data: DataSource) => void
  onRowUnSelected?: (index: number, data: DataSource) => void
}) => {
  const [isSelected, setIsSelected] = React.useState<boolean>(false)

  React.useEffect(() => {
    setIsSelected(isSelectAll)
  }, [isSelectAll])

  return (
    <View style={[styles.tableRow, containerStyle]}>
      {selectable && (
        <Checkbox
          style={[{ marginEnd: 16 }, selectBoxContainerStyle]}
          value={isSelected}
          enable={!isDisabled}
          onChange={(isChecked) => {
            setIsSelected(isChecked)
            if (isChecked) {
              onRowSelected && onRowSelected(index, cols)
            } else {
              onRowUnSelected && onRowUnSelected(index, cols)
            }
          }}
        />
      )}
      {columns.map((col, i) => {
        const value = cols[col.name] as string

        return (
          <View key={i} style={[styles.tableCol, { flex: col.flex ? col.flex : 1 / columns.length }]}>
            {col.render ? (
              col.render({
                index,
                isSelected
              })
            ) : (
              <Text style={[styles.tableColText, col.styles, { color: col.color ? col.color(value) : '#000' }]}>
                {cols[col.name]}
              </Text>
            )}
          </View>
        )
      })}
    </View>
  )
}

const TableBody = ({
  columns,
  dataSource,
  rowStyle,
  selectable = false,
  isSelectAll,
  isDisabled = false,
  selectBoxContainerStyle,
  rowsSelected,
  onRowSelected,
  onRowUnSelected
}: {
  columns: Columns[]
  dataSource: DataSource[]
  rowStyle?: StyleProp<ViewStyle>
  selectable?: boolean
  selectBoxContainerStyle?: StyleProp<ViewStyle>
  rowsSelected: Map<number, DataSource>
  isSelectAll?: boolean
  isDisabled?: boolean
  onRowSelected?: (index: number, data: DataSource) => void
  onRowUnSelected?: (index: number, data: DataSource) => void
}) => {
  const { t } = useTranslation()
  let xhtml: JSX.Element[] = [
    <View key={1} style={styles.tableRow}>
      <View style={[styles.tableCol, { flex: 1 }]}>
        <Text style={[styles.tableColText, { textAlign: 'center' }]}>{t('common:Nodata')}</Text>
      </View>
    </View>
  ]
  if (dataSource.length > 0) {
    xhtml = dataSource.map((data, i) => (
      <TableRow
        key={i}
        index={i}
        isSelectAll={rowsSelected.has(i) || isSelectAll}
        isDisabled={isDisabled}
        selectable={selectable}
        cols={data}
        columns={columns}
        containerStyle={rowStyle}
        selectBoxContainerStyle={selectBoxContainerStyle}
        onRowSelected={onRowSelected}
        onRowUnSelected={onRowUnSelected}
      />
    ))
  }
  return <>{xhtml}</>
}

export interface TableProps {
  rowsSelected?: Map<number, any>
  columns: Columns[]
  dataSource: DataSource[]
  isHeaderHighlight?: boolean
  headerContainerStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
  headerTitleStyle?: StyleProp<TextStyle>
  noneBorderTable?: StyleProp<ViewStyle>
  isChooseAll?: boolean
  selectable?: boolean
  isDisabled?: boolean
  selectBoxContainerStyle?: StyleProp<ViewStyle>
  unit?: string
  scrollable?: boolean
  contentHeight?: number | string
  onRowSelected?: (index: number, data: DataSource) => void
  onRowUnSelected?: (index: number, data: DataSource) => void
  onAllRowSelected?: (data: DataSource[]) => void
  onAllRowUnSelected?: () => void
}

export const Table = ({
  columns,
  dataSource,
  isHeaderHighlight = false,
  headerContainerStyle,
  containerStyle,
  rowStyle,
  headerTitleStyle,
  selectable = false,
  isChooseAll,
  isDisabled = false,
  selectBoxContainerStyle,
  unit,
  rowsSelected = new Map(),
  contentHeight = 'auto',
  scrollable = false, // muốn dùng scrollable nên kèm theo contentHeight
  onRowSelected,
  onRowUnSelected,
  onAllRowSelected,
  onAllRowUnSelected
}: TableProps) => {
  const [isSelectAll, setIsSelectAll] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (isChooseAll != undefined) {
      setIsSelectAll(isChooseAll)
    }
  }, [isChooseAll])

  return (
    <View style={[styles.table, containerStyle]}>
      <TableHeader
        unit={unit}
        selectable={selectable}
        selectBoxContainerStyle={selectBoxContainerStyle}
        columns={columns}
        isHighlight={isHeaderHighlight}
        containerStyle={headerContainerStyle}
        titleStyle={headerTitleStyle}
        isAllSelected={isSelectAll}
        isDisabled={isDisabled}
        onCheckAllSelectedChange={(isChecked) => {
          setIsSelectAll(isChecked)
          if (isChecked) {
            onAllRowSelected && onAllRowSelected(dataSource)
          } else {
            onAllRowUnSelected && onAllRowUnSelected()
          }
        }}
      />
      <View style={{ maxHeight: contentHeight }}>
        <ScrollView scrollEnabled={scrollable}>
          <TableBody
            rowsSelected={rowsSelected}
            columns={columns}
            dataSource={dataSource}
            selectable={selectable}
            rowStyle={rowStyle}
            isSelectAll={isSelectAll}
            isDisabled={isDisabled}
            selectBoxContainerStyle={selectBoxContainerStyle}
            onRowSelected={(index, data) => {
              if (rowsSelected.size + 1 === dataSource.length) {
                setIsSelectAll(true)
              }
              onRowSelected && onRowSelected(index, data)
            }}
            onRowUnSelected={(index, data) => {
              if (isSelectAll && isChooseAll === undefined) {
                setIsSelectAll(false)
              }
              onRowUnSelected && onRowUnSelected(index, data)
            }}
          />
        </ScrollView>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  tableHeaderHighlight: {
    backgroundColor: '#F4F4F4'
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    borderTopColor: '#EEEEEE',
    borderTopWidth: 2,
    paddingVertical: 10
  },
  tableCol: {
    justifyContent: 'center',
    alignContent: 'center'
  },
  tableColHeaderText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700'
  },
  tableColText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '300'
  }
})
