import { COLORS, TextCustomI } from './change-occupation.const'
import { View, Text } from 'react-native'
import styled from 'styled-components/native'

export const TextCustom = styled(Text)<TextCustomI>`
  color: ${(props) => props?.textColor ?? 'black'};
  font-size: ${(props) => props?.fontSize ?? '16px'};
  font-weight: ${(props) => props?.fontWeight ?? 'normal'};
  font-style: ${(props) => props?.fontStyle ?? 'normal'}; ;
`
export const CN = {
  Container: styled(View)`
    width: 100%;
    background-color: ${COLORS.WHITE};
    padding: 24px 0;
  `,

  Row: styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  `,

  Col_3: styled(View)`
    width: 100%;
    max-width: 33.3333333333%;
    padding: 15px;
  `,

  GROUP: styled(View)`
    padding: 24px 0;
  `
}
