
export const REFRESH_TASKMANAGEMENT = 'REFRESH_TASKMANAGEMENT'

export enum OBRefreshTaskManagementCode {
  REFRESH_ALL_BASKET = 'REFRESH_ALL_BASKET'
}

export const requestRefreshAllBasket = () => {
  localStorage.setItem(REFRESH_TASKMANAGEMENT,OBRefreshTaskManagementCode.REFRESH_ALL_BASKET)
}

export const isRefreshAllBasket = () => {
  const option = localStorage.getItem(REFRESH_TASKMANAGEMENT)
  return option === OBRefreshTaskManagementCode.REFRESH_ALL_BASKET
}

export const clearRefreshTaskManager = () => {
  localStorage.removeItem(REFRESH_TASKMANAGEMENT)
}