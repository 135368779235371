import { AddressForm } from '@pulseops/business/core'
import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace EmployeeDetailForm {
  export const codec = t.type({
    employeeName: form2.string.requiredMaxLength(100),
    employeeNo: form2.string.requiredMaxLength(50),
    employeeId: form2.string.requiredMaxLength(25),
    dateOfIssue: form2.date.requiredPast,
    placeOfIssue: form2.string.requiredMaxLength(100),
    email: form2.email.required,
    employeePhoneNumber: form2.phone.required,
    gender: form2.string.required,
    dateOfBirth: form2.date.requiredPast,
    maritalStatus: form2.string.requiredMaxLength(20),
    nationality: form2.string.required,
    otherNationality: form2.string.optional,
    visa: form2.string.optionalMaxLength(100),
    permanentAddress: form2.string.requiredMaxLength(500),
    permanentAddressCountry: form2.string.required,
    permanent: AddressForm.codec,
    residentialAddress: form2.string.requiredMaxLength(500),
    residentialAddressCountry: form2.string.required,
    residential: AddressForm.codec,
    overseasAddress: form2.string.optionalMaxLength(500),
    overseasAddressCountry: form2.string.optional,
    overseas: AddressForm.codec,
    occupation: form2.string.requiredMaxLength(50),
    occupationCode: form2.string.requiredMaxLength(10),
    benefitClass: form2.string.requiredMaxLength(50),
    // memberType: form2.string.optionalMaxLength(50),
    bankName: form2.string.optionalMaxLength(100),
    bankBranch: form2.string.optionalMaxLength(100),
    bankAccountNo: form2.string.optionalMaxLength(50),
    accountName: form2.string.optionalMaxLength(100),
    // masterContractNo: form2.string.requiredMaxLength(20),
    // policyYear: form2.number.required,
    // lifeCertificateNo: form2.string.optionalMaxLength(0),
    lifeCertificateEffectiveDate: form2.date.required,
    // benefitExpiryDate: form2.date.required,
    basicPlan: form2.string.requiredMaxLength(50),
    basicSa: form2.number.requiredMin(0),
    // lifeCertificateEndDate: form2.date.required,
    addRiderSa: form2.number.requiredMin(0),
    healthcarePlan: form2.string.requiredMaxLength(2),
    overFcl: form2.string.required,
    overFclAmount: form2.number.requiredMin(0),
    exclusion: form2.string.optionalMaxLength(100),
    // waitingPeriod: form2.string.optional,
    // preExisting: form2.string.optional,
    // preExistingDate: form2.date.optional,
    // holdClaimsBenefitsPayout: form2.string.optional,
    // vip: form2.string.optional,
    standardPremium: form2.number.requiredMin(0),
    loadingPremium: form2.number.optionalMin(0),
    totalPremium: form2.number.requiredMin(0)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
