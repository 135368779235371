export namespace ContractMapping {
  export const mapContract = new Map<string, string>([
    ['CF', `CFI`], //CFI:
    ['DC', `Declined`], //Declined:
    ['DE', `DeclineCl`], //DeclineCl:
    ['DH', `DeathClm`], //:: Đã giải quyết Quyền lợi bảo hiểm
    ['IF', `InForce`], //InForce:
    ['INFORCE', `InForce`], //InForce:
    ['LA', `Lapsed`], //Lapsed:
    ['MA', `Matured`], //Matured:
    ['PS', `Proposal`], //Proposal:
    ['PU', `PaidUp`], //PaidUp:
    ['SU', `Surrender`], //Surrender:
    ['WD', `Withdrawn`], //Withdrawn:
    ['PO', `POSTPONE`], //POSTPONE:
    ['RD', `REGDEATH`], //REGDEATH:
    ['TD', `TPDCLAIM`], //TPDCLAIM:
    ['FZ', `FROZEN`], //FROZEN:
    ['PP', `PRMPAYING`], //PRMPAYING:
    ['FP', `FULLYPAID`], //FULLYPAID:
    ['EX', `Expired`], //Expired:
    ['TM', `Terminated`],
    ['AP', `AddPrpsl`],
    ['TR', `Terminated`],
    ['DECLINE_CLAIM', `DECLINE_CLAIM`],
    ['DEATH_CLAIM', 'DEATH_CLAIM'],
    ['TERMINATED', 'TERMINATED'],
    ['Cancelled', 'Cancelled'],
    ['REGDEATH', 'REGDEATH'],
    ['SUSPENDED', 'SUSPENDED'],
    ['TPDCLAIM', 'TPDCLAIM']
  ])

  export const get = (translator: (val: string) => string) => (key?: string) =>
    key ? translator(mapContract.get(key) || '') ?? '-' : '-'
}
