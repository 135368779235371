import { createStackNavigator, StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { TaskDetailScreen } from './TaskDetailScreen'
import { TaskDetailStackParamList } from './TaskDetailStackParamList'
import {
  PolicyValueScreen,
  ProductScreen,
  CustomerScreen,
  InvestmentScreen,
  AgentScreen,
  TransactionHistoryScreen,
  PolicyInfoScreen,
  TrackingStatusClaimScreen
} from './container/generalInfo/index'
import { EvaluationScreen } from './container/adjudication-info/index'

const Stack = createStackNavigator<TaskDetailStackParamList>()

type Props = StackScreenProps<TaskDetailStackParamList, 'TaskDetailScreen'>

export const TaskDetailStack = ({ route }: Props) => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="TaskDetailScreen">
      <Stack.Screen name="TaskDetailScreen" component={TaskDetailScreen} />
      <Stack.Screen name="PolicyScreen" component={PolicyInfoScreen} />
      <Stack.Screen name="CustomerScreen" component={CustomerScreen} />
      <Stack.Screen name="ProductScreen" component={ProductScreen} />
      <Stack.Screen name="PolicyValueScreen" component={PolicyValueScreen} />
      <Stack.Screen name="InvestmentScreen" component={InvestmentScreen} />
      <Stack.Screen name="AgentScreen" component={AgentScreen} />
      <Stack.Screen name="TransactionHistoryScreen" component={TransactionHistoryScreen} />
      <Stack.Screen name="EvaluationScreen" component={EvaluationScreen} />
      <Stack.Screen name="TrackingStatusClaimScreen" component={TrackingStatusClaimScreen} />
    </Stack.Navigator>
  )
}
