import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

  const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.BILLING_CHANGE),
        t.literal(TransactionType.BILLING_CHANGE_CORPORATE)
      ])
    }),
    codec
  ])

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload: t.type({
      body: t.type({
        category: Maybe(t.string),
        curBillingFrequency: Maybe(t.string),
        newBillingFrequency: Maybe(t.string),
        newInstallmentPremium: Maybe(t.string),
        poClientNum: Maybe(t.string),
        poNationalId: Maybe(t.string),
        policyNo: Maybe(t.string),
        userId: Maybe(t.string)
      })
    })
  })
)

const baseC = t.type({
  body: t.type({
    billToDate: Maybe(t.string),
    curBillingFrequency: Maybe(t.string),
    curRenewalPremium: Maybe(t.string),
    newBillingFrequency: Maybe(t.string),
    paidToDate: Maybe(t.string),
    premiumAdv: Maybe(t.string),
    suspendAmount: Maybe(t.string)
  })
})

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: t.type({
      body: t.type({
        advancePremium: Maybe(t.string),
        billToDate: Maybe(t.string),
        curBillingFrequency: Maybe(t.string),
        curInstallmentPremium: Maybe(t.string),
        curRenewalPremium: Maybe(t.string),
        effectiveDateNewFrequency: Maybe(t.string),
        newBillingFrequency: Maybe(t.string),
        newInstallmentPremium: Maybe(t.string),
        officeAddress: Maybe(t.string),
        ownerName: Maybe(t.string),
        paidToDate: Maybe(t.string),
        requirePayinAmount: Maybe(t.string),
        suspenseAmount: Maybe(t.string)
      })
    })
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        advancePremium: Maybe(t.string),
        billToDate: Maybe(t.string),
        curBillingFrequency: Maybe(t.string),
        curInstallmentPremium: Maybe(t.string),
        curRenewalPremium: Maybe(t.string),
        effectiveDateNewFrequency: Maybe(t.string),
        newBillingFrequency: Maybe(t.string),
        newInstallmentPremium: Maybe(t.string),
        officeAddress: Maybe(t.string),
        ownerName: Maybe(t.string),
        paidToDate: Maybe(t.string),
        requirePayinAmount: Maybe(t.string),
        suspenseAmount: Maybe(t.string)
      })
    })
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: t.type({
      body: t.type({
        advancePremium: Maybe(t.string),
        billToDate: Maybe(t.string),
        curBillingFrequency: Maybe(t.string),
        curInstallmentPremium: Maybe(t.string),
        curRenewalPremium: Maybe(t.string),
        effectiveDateNewFrequency: Maybe(t.string),
        newBillingFrequency: Maybe(t.string),
        newInstallmentPremium: Maybe(t.string),
        officeAddress: Maybe(t.string),
        ownerName: Maybe(t.string),
        paidToDate: Maybe(t.string),
        requirePayinAmount: Maybe(t.string),
        suspenseAmount: Maybe(t.string)
      })
    })
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload: baseC
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload: baseC
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    // payload: t.type({
    //   body: t.type({
    //     category: Maybe(t.string),
    //     curBillingFreq: Maybe(t.string),
    //     // curInstallmentAmount: Maybe(t.string),
    //     curInstallmentPremium: Maybe(t.string),
    //     effectiveDate: Maybe(t.string),
    //     newBillingFreq: Maybe(t.string),
    //     // newInstallmentAmount: Maybe(t.string),
    //     newInstallmentPremium: Maybe(t.string),
    //     poClientNum: Maybe(t.string),
    //     poNationalId: Maybe(t.string),
    //     policyNo: Maybe(t.string),
    //     serviceType: Maybe(t.string),
    //     source: Maybe(t.string),
    //     transactionType: Maybe(t.string),
    //     userId: Maybe(t.string)
    //   })
    // })

    // new payload
    payload: t.type({
      body: t.type({
        advancePremium: Maybe(t.string),
        billToDate: Maybe(t.string),
        curBillingFrequency: Maybe(t.string),
        curInstallmentPremium: Maybe(t.string),
        curRenewalPremium: Maybe(t.string),
        effectiveDateNewFrequency: Maybe(t.string),
        newBillingFrequency: Maybe(t.string),
        newInstallmentPremium: Maybe(t.string),
        officeAddress: Maybe(t.string),
        ownerName: Maybe(t.string),
        paidToDate: Maybe(t.string),
        requirePayinAmount: Maybe(t.string),
        suspenseAmount: Maybe(t.string)
      })
    })
  })
)

export const BillingChange = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, Ekiosk, PruGreat, PruOnline])
export type BillingChange = t.TypeOf<typeof BillingChange>
