import { StyleSheet, Text, TouchableHighlight, View, ScrollView, TouchableOpacity } from 'react-native'
import { assets, BuildConfig, PulseOpsFormat, replaceSpecialCharactersInFileName, useMobile } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FileUploadData, generalStyles } from '../claim'

const MB = 1048576
interface Props {
  importFile: (file: File) => void
}

export const ImportDoc: React.FC<Props> = ({ importFile }: Props) => {
  const { t, i18n } = useTranslation()
  const [documentList, setDocumentList] = React.useState<FileUploadData[]>([])
  const all = useTranslation().t

  const maxSize = 10 * MB
  const maxTotal = 50 * MB
  // const maxFile = 5
  const validExtensions = ['XLSX', 'CSV']
  const [errorMess, setErrorMess] = React.useState<string>('')
  const [currSize, setCurrSize] = React.useState<number>(0)

  const [errorDocType, setErrorDocType] = React.useState<string>('')
  // React.useEffect(() => {

  // }, [])

  const onUploaded = (files: FileUploadData[]) => {
    const file = files[0]
    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      setErrorMess(all('message:MS040003', { files: validExtensions.join('/') }))
    } else if (file.size >= maxSize) {
      setErrorMess(all('message:MS040004'))
    } else if (currSize + file.size >= maxTotal) {
      setErrorMess(all('message:MS040005'))
    } else if (files.length > 1 || documentList.length > 0) {
      setErrorMess(all('message:MS040014'))
    } else {
      // if (tabIndex === undefined) return
      // const cloneSelectedDoc = [...selectedDocumentType]
      // cloneSelectedDoc[tabIndex].files = [...cloneSelectedDoc[tabIndex].files, file]
      // setSelectedDocumentType(cloneSelectedDoc)
      setDocumentList([...documentList, file])
      setCurrSize(currSize + file.size)
      setErrorMess('')
    }
  }

  const deleteAttachment = (attachmentIdx: number) => {
    if (currSize - documentList[attachmentIdx].size < maxTotal) setErrorMess('')
    setCurrSize(currSize - documentList[attachmentIdx].size)
    const currDoc = documentList
    currDoc.splice(attachmentIdx, 1)
    setDocumentList(currDoc)
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 15,
        paddingTop: 10,
        width: '50%',
        minWidth: 600
      }}
    >
      <View style={[styles.sectionContainer, { backgroundColor: '#fafafa', borderRadius: 5 }]}>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <ImportFile onUploaded={onUploaded} documentList={documentList} importFile={importFile} resetDoc={() => deleteAttachment(0)} />
        </View>
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            display: documentList.length === 0 ? "none" : "flex"
          }}
        >
          <ScrollView>
            <View
              style={{
                flex: 1,
                flexWrap: 'wrap',
                marginTop: 10,
                flexDirection: 'row'
              }}
            >
              {documentList?.map((file: FileUploadData, attachmentIdx) => (
                <View
                  key={`File: ${file.fileName}-${attachmentIdx}`}
                  style={{
                    width: '40%',
                    paddingBottom: 10,
                    flexDirection: 'row'
                  }}
                >
                  <View style={{ flexDirection: 'column', paddingRight: 5, maxWidth: '100%' }}>
                    <Text>{file.fileName}</Text>
                    <Text>{PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD-MM-YYYY HH:mm')}</Text>
                  </View>
                  <TouchableHighlight style={{ height: 15 }} onPress={() => deleteAttachment(attachmentIdx)}>
                    <assets.RemoveIcon />
                  </TouchableHighlight>
                </View>
              ))}
            </View>
          </ScrollView>
        </View>
        <Text style={{ color: '#ed1b2c' }}>{errorDocType}</Text>
        <Text style={{ color: '#ed1b2c', marginTop: 10 }}>{errorMess}</Text>
        <Text style={{ fontStyle: 'italic', color: '#70777E', marginTop: 10 }}>{t('ReleasePolicy:FileFormat')}</Text>
        <Text style={{ color: '#70777E', marginTop: 10 }}>
          {t('ReleasePolicy:DownloadFile')}
          <a href={`${BuildConfig.contextPath}/static/Template_for_Release_code_by_Excel(ver 1).xlsx`} download style={{ color: 'red', textDecoration: 'none' }}>
            {t('submission:Here')}
          </a>
        </Text>
      </View>
    </View>
  )
}

export const ImportFile = ({
  onUploaded,
  documentList,
  importFile,
  resetDoc
}: {
  onUploaded: (file: FileUploadData[]) => void
  documentList: FileUploadData[]
  importFile: (file: File) => void
  resetDoc: () => void
}) => {
  const { t } = useTranslation()
  const [fileSelect, setFileSelect] = React.useState<File>()
  const inputRef = React.createRef<HTMLInputElement>()
  const upload = () => inputRef.current?.click()
  const handleUpload = (files: FileList) => {
    const fileList: FileUploadData[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      // const fileExt = file.name.split('.').pop() ?? ''
      const reader = new FileReader()

      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''
      setFileSelect(newFile)
      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && onUploaded(fileList)
      }
    }
  }

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row'
      }}
    >
      <View style={{ flexDirection: 'column', marginBottom: 10, marginTop: 10, width: "40%" }}>
        <TouchableOpacity
          style={
            {
              backgroundColor: '#FFFFFF',
              borderRadius: 8,
              paddingTop: 15,
              paddingBottom: 15,
              borderWidth: 2,
              borderColor: '#D3DCE6',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            }
          }
          onPress={upload}
        >
          <assets.UploadIcon />
          <Text style={{ color: '#ED1B2E', fontWeight: 'bold', marginLeft: 10 }}>{t('Import files')}</Text>
          <input
            ref={inputRef}
            hidden
            type="file"
            multiple={true}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.files) {
                handleUpload(event.target.files)
                event.target.files = null
                event.target.value = ''
              }
            }}
          />
        </TouchableOpacity>
      </View>
      <TouchableHighlight style={[styles.releaseBtn, { display: documentList.length === 0 ? "none" : "flex" }]}
        onPress={() => {
          resetDoc()
          if (fileSelect) {
            importFile(fileSelect)
          }
        }}>
        <Text style={[styles.submitTextWhite]}>{t('Release')}</Text>
      </TouchableHighlight>
    </View>
  )
}

const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    label: {
      color: '#70777E',
      fontWeight: 'bold',
      fontSize: 15,
      marginBottom: 2
    },
    releaseBtn: {
      marginLeft: "15%",
      marginTop: 20,
      justifyContent: 'center',
      backgroundColor: '#ED1B2E',
      borderRadius: 100,
      borderWidth: 1,
      borderColor: '#ED1B2E',
      maxWidth: 200,
      height: 40,
      paddingLeft: 29,
      paddingRight: 29
    }
  })
}
