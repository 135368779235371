import React from 'react'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View, StyleSheet, TextInput } from 'react-native'
import { assets, DigitalInquiryService, ErrorHandling, exportCSV, PulseOpsFormat, Digital } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

type ExportList = Digital.VoucherInquiry[]

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF'
  },
  dialogContent: {},
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export type VoucherInquiryExportPopupProps = {
  onClose: () => void
  open: boolean
  selectedValue: {
    voucherCode: string
    fromDate: string
    toDate: string
    roles: string[]
  }
}

export const VoucherInquiryExportPopup = (props: VoucherInquiryExportPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, selectedValue, open } = props

  const { t } = useTranslation()

  const [reason, setReason] = React.useState<string>('')
  const [errMess, setErrMess] = React.useState<string>('')

  const [loading, bindLoader] = useLoading(false)

  const mapping =
    (exportData: string[][]) =>
    (data: ExportList): string[][] => {
      exportData.push([
        t('VoucherInquiryTable:VoucherCode'),
        t('VoucherInquiryTable:OrderID'),
        t('VoucherInquiryTable:Batch'),
        t('VoucherInquiryTable:VouchersSoldNumber'),
        t('VoucherInquiryTable:OrderIncome'),
        t('VoucherInquiryTable:PurchasedOrderDate'),
        t('VoucherInquiryTable:VouchersExpiredDate'),
        t('VoucherInquiryTable:TransactionID')
      ])
      return [
        ...exportData,
        ...data.map((voucher) => [
          voucher.chaVoucherCode != null ? voucher.chaVoucherCode.toString() : '',
          voucher.orderId != null ? voucher.orderId.toString() : '',
          voucher.batchId != null ? voucher.batchId.toString() : '',
          voucher.quantity != null ? voucher.quantity.toString() : '',
          voucher.basePrice != null && voucher.quantity != null
            ? PulseOpsFormat.thousandSepartorStr((voucher.basePrice * voucher.quantity).toString())
            : '',
          voucher.purchasedDate != null ? voucher.purchasedDate.toString() : '',
          voucher.expiryDate != null ? voucher.expiryDate.toString() : '',
          voucher.paymentsTxRef != null ? voucher.paymentsTxRef.toString() : ''
        ])
      ]
    }

  const onExport = () => {
    if (reason.length > 50) {
      pipe(
        DigitalInquiryService.exportVoucherData({
          reason: reason,
          voucherCode: selectedValue.voucherCode,
          fromDate: selectedValue.fromDate,
          toDate: selectedValue.toDate
        }),
        ZIO.map((res) => {
          const inputData: ExportList = res.map((voucher) => ({
            ...voucher
          }))
          const fromDateToDate =
            selectedValue.fromDate && selectedValue.toDate
              ? `${PulseOpsFormat.dateStrWF(selectedValue.fromDate, 'DD/MM/yyyy')} - ${PulseOpsFormat.dateStrWF(
                  selectedValue.toDate,
                  'DD/MM/yyyy'
                )}`
              : ''
          const commonData = [
            ['PULSE DPAS VOUCHER'],
            [`Reporting period: ${fromDateToDate}`],
            [`Reporting date: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`]
          ]
          const data: string[][] = mapping(commonData)(inputData)
          return data
        }),
        ZIO.tap((data) => {
          if (data !== null) exportCSV('voucher-info', data)
          onClose()
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
    } else {
      setErrMess(
        reason.length < 50 && reason.length > 0
          ? t('message:MS990018')
          : t('message:MS020001', { field: t('common:ReasonExport') })
      )
    }
  }

  return (
    <Dialog maxWidth={'md'} className={dialog} onClose={onClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>{t('common:Export')}</Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose()
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View>
          <Text style={{ margin: 10, marginBottom: 5 }}>
            {t('common:ReasonExport')} <Text style={{ color: '#ED1B2C' }}>*</Text>{' '}
          </Text>
          <TextInput
            style={{
              borderColor: '#BABABA',
              borderRadius: 8,
              borderWidth: 1,
              padding: 10,
              marginHorizontal: 10,
              width: 600
            }}
            multiline={true}
            numberOfLines={10}
            maxLength={500}
            onChangeText={(text) => setReason(text)}
            placeholder={'...'}
            value={reason}
          />
          <View style={{ flexDirection: 'row', margin: 10, marginTop: 5 }}>
            <Text style={{ color: '#ED1B2C', width: '80%' }}> {errMess} </Text>
            <Text style={{ width: '80%', textAlign: 'right' }}> {reason.length} / 500 </Text>
          </View>
        </View>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose()}>
          <Text style={{ color: '#ED1B2C' }}>{t('common:Cancel')}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.actionBtn, { backgroundColor: '#ED1B2C', flexDirection: 'row' }]}
          onPress={() => onExport()}
        >
          <Text style={{ color: '#FFF' }}>
            {t('common:Export')} {loading && <CircularProgress size={14} color={'inherit'} style={{ marginTop: 10 }} />}
          </Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  actionBtn: {
    borderRadius: 100,
    paddingVertical: 10,
    paddingHorizontal: 20
  }
})
