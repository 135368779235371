import { InquiryComplaintData, Panel, TableField } from '@pulseops/common'
import {
  CancelRiderData,
  VeriCancelRiderInfo,
  VeriLARiderInfo
} from 'libs/common/src/service/model/task-detail/CancelRider'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Title } from '../common'

type DataSource = {
  riderName: string
  riskCommDate: string
  riskCessDate: string
  sumAssured: string
  installmentPremium: string
}

export const CancelRider = ({ detail }: { detail?: CancelRiderData }) => {
  const { t, i18n } = useTranslation('requestInfo')

  const lifeAssureds: VeriLARiderInfo[] = detail?.lifeAssureds || []
  const riderNameList: InquiryComplaintData.IACSources[] = detail?.riderNameList || []

  const getCoreRiders = (coreRiders: VeriCancelRiderInfo[]): DataSource[] => {
    return coreRiders.map((coreRider) => ({
      riderName: getRiderName(coreRider.productCode),
      riskCommDate: coreRider.riskCommDate,
      riskCessDate: coreRider.riskCessDate,
      sumAssured: coreRider.sumAssured,
      installmentPremium: coreRider.installmentPremium
    }))
  }

  const getRiderName = (riderCode: string) => {
    const item = riderNameList.find((x) => x.code === riderCode)
    return !!item ? riderCode + ' - ' + (i18n.language === 'en' ? item.name : item.nameVi) : ''
  }
  const columns = [
    {
      key: '1',
      title: t('RiderName'),
      name: 'riderName'
    },
    {
      key: '2',
      title: t('RiskCommencementDate'),
      name: 'riskCommDate'
    },
    {
      key: '3',
      title: t('RiskCessationDate'),
      name: 'riskCessDate'
    },
    {
      key: '4',
      title: t('SumAssured'),
      name: 'sumAssured'
    },
    {
      key: '5',
      title: t('InstallmentPremium'),
      name: 'installmentPremium'
    }
  ]

  return (
    <View>
      <Title title={t('CancelRider')} />
      {lifeAssureds.length > 0 &&
        lifeAssureds.map((lifeAssureds, index) => (
          <Panel title={lifeAssureds.clientName.toUpperCase()} key={index}>
            <TableField columns={columns} dataSource={getCoreRiders(lifeAssureds.coreRiders)} />
          </Panel>
        ))}
      <View>
        <Text style={styles.text}>{t('EstimatedNewInstallmentPremium')}</Text>
        <Text style={styles.isHightLight}>{detail?.totalPremium} VND</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginBottom: 10
  },
  isHightLight: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#eD1B2E'
  }
})
