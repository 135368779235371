export namespace EServiceModel {
  export interface Detail {
    ownerName?: string
    nationalId?: string
    email?: string
    birthDate?: string
    gender?: string
    clientPhoneNumber?: string
    clientPhoneCode: string
  }

  export interface EServiceSubmitData {
    idNumber: string
    fullName: string
    dob: string
    gender: string
    telCode: string
    mobileNumber: string
    email: string
    otpConsent: string
    poClientNum: string
  }
}
