import { Task, ZIO } from '@mxt/zio'
import { POApi, SubmissionService } from '@pulseops/common'
import { ChangeContactInfo, StaffSubmissionService } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { differenceInMonths } from 'date-fns'

export namespace ChangeContactInfoService {
  export const getDetail = (policyNum: string, isDigital?: boolean): Task<ChangeContactInfo.Detail> => {
    return pipe(
      StaffSubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) => {
        // return pipe(
        //   ZIO.zipPar(
        //     isDigital ? SubmissionService.getPolicyInquiry(policyNum) : StaffSubmissionService.getCustomer(policy.clientDespatchAddress ?? ''),
        //     StaffSubmissionService.getOwnerInfo(policyNum),
        //     getPolicyList(policyNum, policy.owners.id ?? '')
        //   ),
        //   ZIO.map(([customerInfo, owner, ClientPolicyList]) => {
        //     let street = '', nationality = '', province = '', district = '', ward = ''
        //     if (isDigital) {
        //       street = customerInfo?.customerRoles[0]?.customer.addressDetails.Current.line1
        //       nationality = customerInfo?.customerRoles[0]?.customer.addressDetails.Current.country
        //       province = customerInfo?.customerRoles[0]?.customer.addressDetails.Current?.line2 || ''
        //       district = customerInfo?.customerRoles[0]?.customer.addressDetails.Current?.line3 || ''
        //       ward = customerInfo?.customerRoles[0]?.customer.addressDetails.Current?.line4 || ''
        //     } else {
        //       const customer = customerInfo as unknown as Customer
        //       street = customer?.body ? customer?.body?.addressDetails?.PRIMARY?.line1 ?? '' : ''
        //       nationality = customer?.body.nationality ?? ''
        //       province = customer?.body ? customer?.body.location.slice(0, 2) ?? '' : ''
        //       district = customer?.body ? customer?.body.location.slice(2, 4) ?? '' : ''
        //       ward = customer?.body ? customer?.body.location ?? '' : ''
        //     }
        //     return {
        //       customerId: policy?.owners.id ?? '',
        //       policyNum: policyNum,
        //       street: street,
        //       nationality: nationality,
        //       province: province,
        //       district: district,
        //       ward: ward,
        //       clientPolicyList: ClientPolicyList,
        //       mobilePhoneCode: owner?.body?.attributesExt?.MOBILE_CODE ?? '',
        //       mobilePhoneNumber: owner?.body?.mobilePhone ?? '',
        //       phoneRelationship: '',
        //       officePhoneCode: owner?.body?.attributesExt?.COMPANY_CODE,
        //       officePhoneNumber: owner?.body?.attributesExt?.COMPANY_PHONE_NUMBER,
        //       smsIndicator: owner?.body?.customerSmsInd ?? '',
        //       email: owner?.body?.email ?? '',
        //       emailIndicator: owner?.body?.customerEmailInd ?? '',
        //       emailRelationship: '',
        //       poName: owner?.body?.name
        //     }
        //   })
        // )
        return pipe(
          isDigital
            ? pipe(
                ZIO.zipPar(
                  SubmissionService.getPolicyInquiry(policyNum),
                  StaffSubmissionService.getOwnerInfo(policyNum),
                  getPolicyList(policyNum, policy.owners.id ?? '')
                ),
                ZIO.map(([customerInfo, owner, ClientPolicyList]) => {
                  let street = '',
                    nationality = '',
                    province = '',
                    district = '',
                    ward = ''
                  street = customerInfo?.customerRoles[0]?.customer.addressDetails.Current.line1
                  nationality = customerInfo?.customerRoles[0]?.customer.addressDetails.Current.country
                  province = customerInfo?.customerRoles[0]?.customer.addressDetails.Current?.line2 || ''
                  district = customerInfo?.customerRoles[0]?.customer.addressDetails.Current?.line3 || ''
                  ward = customerInfo?.customerRoles[0]?.customer.addressDetails.Current?.line4 || ''
                  return {
                    customerId: policy?.owners.id ?? '',
                    policyNum: policyNum,
                    street: street,
                    nationality: nationality,
                    province: province,
                    district: district,
                    ward: ward,
                    clientPolicyList: ClientPolicyList,
                    mobilePhoneCode: owner?.body?.attributesExt?.MOBILE_CODE ?? '',
                    mobilePhoneNumber: owner?.body?.mobilePhone ?? '',
                    phoneRelationship: '',
                    officePhoneCode: owner?.body?.attributesExt?.COMPANY_CODE,
                    officePhoneNumber: owner?.body?.attributesExt?.COMPANY_PHONE_NUMBER,
                    smsIndicator: owner?.body?.customerSmsInd ?? '',
                    email: owner?.body?.email ?? '',
                    emailIndicator: owner?.body?.customerEmailInd ?? '',
                    emailRelationship: '',
                    poName: owner?.body?.name
                  }
                })
              )
            : pipe(
                ZIO.zipPar(
                  StaffSubmissionService.getCustomer(policy.clientDespatchAddress ?? ''),
                  StaffSubmissionService.getOwnerInfo(policyNum),
                  getPolicyList(policyNum, policy.owners.id ?? '')
                ),
                ZIO.map(([customerInfo, owner, ClientPolicyList]) => {
                  let street = '',
                    nationality = '',
                    province = '',
                    district = '',
                    ward = ''
                  street = customerInfo?.body ? customerInfo?.body?.addressDetails?.PRIMARY?.line1 ?? '' : ''
                  nationality = customerInfo?.body.nationality ?? ''
                  province = customerInfo?.body ? customerInfo?.body.location.slice(0, 2) ?? '' : ''
                  district = customerInfo?.body ? customerInfo?.body.location.slice(2, 4) ?? '' : ''
                  ward = customerInfo?.body ? customerInfo?.body.location ?? '' : ''
                  return {
                    customerId: policy?.owners.id ?? '',
                    policyNum: policyNum,
                    street: street,
                    nationality: nationality,
                    province: province,
                    district: district,
                    ward: ward,
                    clientPolicyList: ClientPolicyList,
                    mobilePhoneCode: owner?.body?.attributesExt?.MOBILE_CODE ?? '',
                    mobilePhoneNumber: owner?.body?.mobilePhone ?? '',
                    phoneRelationship: '',
                    officePhoneCode: owner?.body?.attributesExt?.COMPANY_CODE,
                    officePhoneNumber: owner?.body?.attributesExt?.COMPANY_PHONE_NUMBER,
                    smsIndicator: owner?.body?.customerSmsInd ?? '',
                    email: owner?.body?.email ?? '',
                    emailIndicator: owner?.body?.customerEmailInd ?? '',
                    emailRelationship: '',
                    poName: owner?.body?.name
                  }
                })
              )
        )
      })
    )
  }

  export const getDispatchAddress = (policyIds: Array<string>) =>
    pipe(
      StaffSubmissionService.getPolicies(policyIds.map((e) => ({ policyNo: e }))),
      ZIO.flatMap((policies) =>
        pipe(
          StaffSubmissionService.getClients(policies.map((e) => ({ clientId: e.clientDespatchAddress }))),
          ZIO.map((clients) => {
            return policies
              .filter(
                (p) =>
                  p.status === 'IF' ||
                  (p.status === 'LA' && differenceInMonths(new Date(), new Date(p.paidToDateAdvance)) < 24)
              )
              .map((policy) => {
                const client = clients.body.find((client) => client.clientId === policy.clientDespatchAddress)
                if (!client) return null
                return {
                  ...client,
                  policyNum: policy.policyNo
                }
              })
          })
        )
      )
    )

  export const getPolicyList = (policyNum: string, ownerId: string): Task<ChangeContactInfo.ClientPolicy[]> =>
    pipe(
      POApi.post(`wf-api/customer/policies`)(
        t.type({
          body: t.array(
            t.type({
              clientNum: t.string,
              policyNo: t.string,
              role: t.string
            })
          )
        })
      )({
        body: {
          securityNo: `C${ownerId}`
        }
      }),
      ZIO.flatMap(
        (clientPolicies): Task<ChangeContactInfo.ClientPolicy[]> =>
          pipe(
            getDispatchAddress(clientPolicies.body.filter((e) => e.policyNo !== policyNum).map((e) => e.policyNo)),
            ZIO.map((clients) => {
              return clients.map((client) => {
                return {
                  policyNum: client?.policyNum ?? '',
                  name: client?.name ?? '',
                  address: client?.addressDetails
                    ? `${client.addressDetails?.PRIMARY?.line1 ?? ''}, ${
                        client.addressDetails?.PRIMARY?.line2 ?? ''
                      }, ${client.addressDetails?.PRIMARY?.line3 ?? ''}, ${
                        client.addressDetails?.PRIMARY?.line4 ?? ''
                      }`.replace(/(, ,)+/g, ',')
                    : ''
                }
              })
            })
          )
      )
    )
}
