import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { InboundParamList } from './InboundParamList'
import { IBSearchScreen } from './ib-search'
import { IBClientScreenStack } from './ib-client'
import { IBDraftTransaction, IBSubmitTransaction } from './ib-draft-trans'
import { IBCallbackAppointment } from './ib-callback-appointment'
import { IBEmailManagement } from './ib-email-management'
const InStack = createStackNavigator<InboundParamList>()
export const InboundStack = () => {
  return (
    <InStack.Navigator initialRouteName="IBSearchScreen" screenOptions={{ headerShown: false }}>
      <InStack.Screen name="IBSearchScreen" component={IBSearchScreen}></InStack.Screen>
      <InStack.Screen name="IBClientScreenStack" component={IBClientScreenStack}></InStack.Screen>
      <InStack.Screen name="IBDraftTransaction" component={IBDraftTransaction}></InStack.Screen>
      <InStack.Screen name="IBSubmitTransaction" component={IBSubmitTransaction}></InStack.Screen>
      <InStack.Screen name="IBCallbackAppointment" component={IBCallbackAppointment}></InStack.Screen>
      <InStack.Screen name="IBEmailManagement" component={IBEmailManagement}></InStack.Screen>
    </InStack.Navigator>
  )
}
