import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.SIGNATURE_REGISTRATION)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        optionFlag: t.string,
        documents: t.array(
          t.type({
            name: t.string,
            url: t.string
          })
        )
      })
    })
  })
)

export const SignatureRegistration = Pulse4Ops
export type SignatureRegistration = t.TypeOf<typeof SignatureRegistration>
