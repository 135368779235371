import {
  assets,
  Checkbox,
  DatePicker,
  GeneralService,
  ICDType,
  SelectSearch,
  useMobile,
  VerClaimType,
  Input
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { Panel } from '../../claim-common'
import { ClaimInfoProps } from '../ClaimInfoProps'
import { FontAwesome5 } from '@expo/vector-icons'
import { Controller } from 'react-hook-form'
import { compareOnlyDate } from '@pulseops/submission/common'

export const TotalPermanentDisability = (props: ClaimInfoProps) => {
  const { isMobile } = useMobile()
  const { form, icdName, accidents, setIsCanChangeTab } = props
  const { t, i18n } = useTranslation(['claim'])
  const { watch, control, clearErrors, setValue } = form

  const searchOptions = (inputValue: string) => pipe(GeneralService.tpdSearchOptions(inputValue))

  const icdDeleteHandler = (idx: number) => {
    const list = [...watch('disability.cause')]
    list.splice(idx, 1)
    form.setValue('disability.cause', list)
  }

  const tpdDeleteHandler = (idx: number) => {
    const list = [...watch('disability.tpdName')]
    list.splice(idx, 1)
    form.setValue('disability.tpdName', list)
  }

  const isRelatedToAccident = !!watch('disability.isRelatedToAccident')

  const onChangeRelatedToAccident = (e: boolean) => {
    if (!e) {
      clearErrors(['disability.occurrenceDate', 'disability.accidentName'])
      setValue('disability.occurrenceDate', null)
      setValue('disability.accidentName', null)
    }
  }

  return (
    <Panel
      title=""
      breadcrumb={
        <View style={[styles.contentText, styles.row]}>
          <Text style={styles.titleSectionText}>{t('Disability')}</Text>
          <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
          <Text style={styles.titleSectionText}>{t('Disability')}</Text>
        </View>
      }
      isExand={true}
      isHiddenTitle={false}
      styling={styles.sectionInfo}
    >
      <View style={styles.row}>
        <View style={[isMobile ? styles.colFull : styles.colOneThird, { marginRight: 50 }]}>
          <Controller
            name="disability.isRelatedToAccident"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                title={t('RelatedToAccident')}
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e)
                  onChangeRelatedToAccident(e)
                  setIsCanChangeTab(false)
                }}
              />
            )}
          />
        </View>
        {isRelatedToAccident && (
          <>
            <View style={[isMobile ? styles.colFull : styles.colOneThird, { marginRight: 50 }]}>
              <Controller
                name={'disability.occurrenceDate'}
                control={control}
                rules={{
                  required: {
                    value: isRelatedToAccident,
                    message: t('message:MS020009', { field: t('OccurrenceDate') })
                  },
                  validate: (occurrenceDate) => {
                    const currentDate = new Date()
                    const compareDate = !!occurrenceDate && !compareOnlyDate(occurrenceDate, currentDate)
                    const message = t('message:MS070021')
                    if (compareDate) return message
                    return true
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('OccurrenceDate')}
                    required={isRelatedToAccident}
                    onChange={(e) => {
                      onChange(e)
                      setIsCanChangeTab(false)
                    }}
                    alwaysShow={!isRelatedToAccident}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={[styles.colOneThird]}>
              <Controller
                name="disability.accidentName"
                control={control}
                rules={{
                  required: {
                    value: isRelatedToAccident,
                    message: t('message:MS020009', { field: t('AccidentName') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('AccidentName')}
                    required
                    options={
                      accidents?.map((option: VerClaimType) => ({
                        value: option.code,
                        label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
                      })) || []
                    }
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChange(e)
                      setIsCanChangeTab(false)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </>
        )}
      </View>
      <View style={styles.row}>
        <View style={[isMobile ? styles.colFull : styles.colOneThird, { marginRight: 50 }]}>
          <Controller
            name={'disability.claimEventDate'}
            control={control}
            defaultValue={new Date()}
            rules={{
              required: {
                value: isRelatedToAccident,
                message: t('message:MS020009', { field: t('ClaimEventDate') })
              },
              validate: (claimEventDate) => {
                const currentDate = new Date()
                const compareDate = !!claimEventDate && !compareOnlyDate(claimEventDate, currentDate)
                const message = t('message:MS070008')
                if (compareDate) return message
                return true
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                label={t('ClaimEventDate')}
                required
                onChange={(e) => {
                  onChange(e)
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
      </View>
      <View style={styles.row}>
        <View style={isMobile ? styles.colFull : styles.colThreeFourths}>
          <Controller
            name="disability.cause"
            defaultValue={[]}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020009', { field: t('Cause') })
              },
              pattern: {
                value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                message: t('message:MS050223')
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                label={t('Cause')}
                required
                options={
                  icdName?.map((option: ICDType) => ({
                    value: option.icdcode,
                    label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
                  })) || []
                }
                placeholder={t('common:Select')}
                onChange={(e) => {
                  onChange(e)
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                multiple
                key={watch('disability.cause').length}
              />
            )}
          />
        </View>
      </View>

      <View style={styles.row}>
        <View style={[isMobile ? styles.colFull : styles.colThreeFourths]}>
          <View style={styles.tableDiagnosis}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={styles.label}>{t('Cause')}</Text>
              </View>
            </View>

            {watch('disability.cause')?.map((addition, index) => (
              <View style={[styles.row, styles.line]} key={index}>
                <View style={styles.col}>
                  <Text>
                    {addition.value.split(':').length > 1 ? addition.value.split(':')[1] : addition.value.split(':')[0]}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text>
                    {addition.label.split(':').length > 1 ? addition.label.split(':')[1] : addition.label.split(':')[0]}
                  </Text>
                </View>
                <View style={[styles.col, { alignItems: 'flex-end' }]}>
                  <TouchableOpacity onPress={() => icdDeleteHandler(index)}>
                    <assets.DeleteIcon />
                  </TouchableOpacity>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
      <View style={styles.row}>
        <View style={isMobile ? styles.colFull : styles.colThreeFourths}>
          <Controller
            name="disability.tpdName"
            defaultValue={[]}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020009', { field: t('TPDName') })
              },
              pattern: {
                value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                message: t('message:MS050223')
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                label={t('TPDName')}
                required
                searchOptions={searchOptions}
                placeholder={t('common:Select')}
                onChange={(e) => {
                  onChange(e)
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                multiple
                key={watch('disability.tpdName').length}
              />
            )}
          />
        </View>
      </View>

      <View style={styles.row}>
        <View style={[isMobile ? styles.colFull : styles.colThreeFourths]}>
          <View style={styles.tableDiagnosis}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={styles.label}>{t('TPDName')}</Text>
              </View>
            </View>

            {watch('disability.tpdName')?.map((addition, index) => (
              <View style={[styles.row, styles.line]} key={index}>
                <View style={styles.col}>
                  <Text>{addition.value}</Text>
                </View>
                <View style={styles.col}>
                  <Text>{addition.label.split(' - ')[1]}</Text>
                </View>
                <View style={[styles.col, { alignItems: 'flex-end' }]}>
                  <TouchableOpacity onPress={() => tpdDeleteHandler(index)}>
                    <assets.DeleteIcon />
                  </TouchableOpacity>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      <View style={styles.row}>
        <View style={[styles.colFull, { paddingHorizontal: 0 }]}>
          <Controller
            name="disability.remark"
            control={control}
            rules={{
              maxLength: {
                value: 500,
                message: `${t('message:MaxLength', { field: '500' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('Remark')}
                onChange={(e) => {
                  onChange(e)
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                maxLength={500}
                multiline
                numberOfLines={5}
                inputStyle={{ backgroundColor: '#FFFFFF' }}
              />
            )}
          />
        </View>
      </View>
    </Panel>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    // marginTop: 20,
    padding: 30,
    width: '100%',
    backgroundColor: '#FAFAFA',
    borderStartWidth: 1,
    borderEndWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 8,
    marginBottom: 16
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  col: {
    flex: 1 / 3,
    marginBottom: 12
  },
  colOneThird: {
    flex: 1 / 3,
    marginBottom: 32
  },
  colThreeFourths: {
    flex: 2 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  contentText: {
    padding: 5,
    borderRadius: 20,
    width: 'fit-content',
    backgroundColor: '#E5EAEF',
    marginBottom: 26
  },
  titleSectionText: {
    fontSize: 13,
    lineHeight: 22,
    fontWeight: '600',
    paddingStart: 10,
    paddingEnd: 10,
    color: '#4F4F4F'
  },
  tableDiagnosis: {
    // marginTop: 20,
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  line: {
    width: '100%',
    paddingBottom: 10,
    marginBottom: 22,
    borderBottomWidth: 1,
    borderBottomColor: '#D3DCE6'
  }
})
