import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ClientName } from './CepCommonModels'
import { TransactionTypeC } from '@pulseops/common'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'

export const PolicyInfoC = t.type({
  data: t.type({
    policy: t.type({
      policyStatus: Maybe(t.string),
      premiumStatus: Maybe(t.string),
      sumAssured: Maybe(t.number),
      billingFrequency: Maybe(t.string),
      policyOwnerCode: Maybe(t.string),
      policyOwnerName: Maybe(ClientName),
      mainLifeAssuredCode: Maybe(t.string),
      mainLifeAssuredName: Maybe(ClientName),
      productCode: Maybe(t.string),
      productNameVN: Maybe(t.string),
      productNameEN: Maybe(t.string),
      installmentPremium: Maybe(t.number),
      riskCommencementDate: Maybe(t.string),
      aplDate: Maybe(t.string),
      policyIssueDate: Maybe(t.string),
      paidToDateBasic: Maybe(t.string),
      premiumCessationDate: Maybe(t.string),
      riskCessationDate: Maybe(t.string),
      customerReceiveDate: Maybe(t.string),
      customerReceiveMethod: Maybe(t.string),
      firstIssueDate: Maybe(t.string)
    }),
    beneficiaries: Maybe(
      t.array(
        t.type({
          beneficiaryCode: Maybe(t.string),
          beneficiaryPercentage: Maybe(t.number),
          beneficiaryRelationship: Maybe(t.string),
          beneficiaryName: Maybe(ClientName)
        })
      )
    ),
    transactionHistories: Maybe(
      t.array(
        t.type({
          id: Maybe(t.string),
          name: Maybe(t.string),
          businessKey: Maybe(t.string),
          source: Maybe(t.string),
          category: Maybe(t.string),
          transactionType: TransactionTypeC,
          policyNumber: Maybe(t.string),
          clientNumber: Maybe(t.string),
          poName: Maybe(t.string),
          lastUpdateDate: Maybe(t.string),
          subTransactionType: Maybe(t.string),
          agingDate: Maybe(t.number),
          assignee: Maybe(t.string),
          assigneeName: Maybe(t.string),
          activity: Maybe(t.string),
          createdDate: Maybe(t.string),
          request: Maybe(t.string),
          status: Maybe(t.string),
          createdBy: Maybe(t.string),
          lastUpdateByVer: Maybe(t.string),
          lastUpdateByAdj: Maybe(t.string),
          poClientNumber: Maybe(t.string),
          poPhoneNumber: Maybe(t.string),
          serviceType: Maybe(t.string),
          group: Maybe(t.string),
          subServiceType: Maybe(t.string),
          comment: Maybe(t.string),
          closeInfo: Maybe(t.string),
          note: Maybe(t.string),
          action: Maybe(t.string),
          endTime: Maybe(t.string),
          createTime: Maybe(t.string),
          lastUpdateDateAdj: Maybe(t.string),
          lastUpdateDateVer: Maybe(t.string),
          lifeAssuredName: Maybe(t.string),
          startTime: Maybe(t.string),
          completed: Maybe(t.boolean),
          suspended: Maybe(t.boolean)
        })
      )
    )
  })
})

export type PolicyInfoC = t.TypeOf<typeof PolicyInfoC>

export const TransactionHistoryC = t.type({
  start: t.number,
  size: t.number,
  data: Maybe(
    t.type({
      fromDate: Maybe(DateFromISOString),
      toDate: Maybe(DateFromISOString),
      category: t.string,
      transactionType: Maybe(t.array(t.string)),
      subServiceType: t.array(t.string),
      subTransactionType: t.array(t.string),
      source: t.array(t.string)
    })
  )
})

export type TransactionHistoryC = t.TypeOf<typeof TransactionHistoryC>

export const ItemTransactionHistory = t.type({
  id: Maybe(t.string),
  name: Maybe(t.string),
  businessKey: Maybe(t.string),
  source: Maybe(t.string),
  category: Maybe(t.string),
  transactionType: TransactionTypeC,
  policyNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  poName: Maybe(t.string),
  lastUpdateDate: Maybe(t.string),
  subTransactionType: Maybe(t.string),
  agingDate: Maybe(t.number),
  assignee: Maybe(t.string),
  assigneeName: Maybe(t.string),
  activity: Maybe(t.string),
  createdDate: Maybe(t.string),
  request: Maybe(t.string),
  status: Maybe(t.string),
  createdBy: Maybe(t.string),
  lastUpdateByVer: Maybe(t.string),
  lastUpdateByAdj: Maybe(t.string),
  poClientNumber: Maybe(t.string),
  poPhoneNumber: Maybe(t.string),
  serviceType: Maybe(t.string),
  group: Maybe(t.string),
  subServiceType: Maybe(t.string),
  comment: Maybe(t.string),
  closeInfo: Maybe(t.string),
  note: Maybe(t.string),
  action: Maybe(t.string),
  endTime: Maybe(t.string),
  createTime: Maybe(t.string),
  lastUpdateDateAdj: Maybe(t.string),
  lastUpdateDateVer: Maybe(t.string),
  lifeAssuredName: Maybe(t.string),
  startTime: Maybe(t.string),
  completed: Maybe(t.boolean),
  suspended: Maybe(t.boolean)
})

export type ItemTransactionHistory = t.TypeOf<typeof ItemTransactionHistory>

export const TransactionHistoryRes = t.type({
  start: Maybe(t.number),
  size: Maybe(t.number),
  total: Maybe(t.number),
  data: Maybe(t.array(ItemTransactionHistory))
})
export type TransactionHistoryRes = t.TypeOf<typeof TransactionHistoryRes>
