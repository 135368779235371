import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { BOStackParamList } from './BOStackParamList'
import { BODashboardScreen } from './backoffice'
import { BODetailScreen } from './BackofficeDetail'

const Stack = createStackNavigator<BOStackParamList>()

export const BOStack = () => {
  return (
    <Stack.Navigator initialRouteName="BODashboardScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="BODashboardScreen" component={BODashboardScreen} />
      <Stack.Screen name="BODetailScreen" component={BODetailScreen} />
    </Stack.Navigator>
  )
}
