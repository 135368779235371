import { Task } from '@mxt/zio'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import moment from 'moment'
import { POApi } from '../POApi'
import { ChangeContactInfo, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export namespace ChangeContactInfoService {
  export const getDetail = (policyNum: string): Task<ChangeContactInfo.Detail> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getCustomer(policy.body.clientDespatchAddress ?? ''),
            SubmissionService.getOwnerInfo(policyNum),
            getPolicyList(policyNum, policy.body.owners.id ?? '')
          ),
          ZIO.map(([customerInfo, owner, ClientPolicyList]) => ({
            customerId: policy.body?.owners.id ?? '',
            policyNum: policyNum,
            street: customerInfo.body ? customerInfo.body.addressDetails?.PRIMARY?.line1 ?? '' : '',
            nationality: customerInfo.body.nationality ?? '',
            province: customerInfo.body ? customerInfo.body.location.slice(0, 2) ?? '' : '',
            district: customerInfo.body ? customerInfo.body.location.slice(2, 4) ?? '' : '',
            ward: customerInfo.body ? customerInfo.body.location ?? '' : '',
            clientPolicyList: ClientPolicyList,
            mobilePhoneCode: owner.body.attributesExt.MOBILE_CODE ?? '',
            mobilePhoneNumber: owner.body.mobilePhone ?? '',
            phoneRelationship: '',
            officePhoneCode: owner.body.attributesExt.COMPANY_CODE,
            officePhoneNumber: owner.body.attributesExt.COMPANY_PHONE_NUMBER,
            smsIndicator: owner.body.customerSmsInd ?? '',
            email: owner.body.email ?? '',
            emailIndicator: owner.body.customerEmailInd ?? '',
            emailRelationship: '',
            poName: owner.body.name,
            secuityNo: customerInfo.body.externalIds.SOE_VALUE ?? '',
            dob: customerInfo.body.dob ?? '',
            gender: customerInfo.body.sex ?? ''
          }))
        )
      )
    )

  export const getDataAccess = (policyNum: string): Task<ChangeContactInfo.AccessDataCheck> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            paidToDateAdvance: Maybe(t.string),
            status: Maybe(t.string)
          })
        })
      ),
      ZIO.map(
        (policy): ChangeContactInfo.AccessDataCheck => ({
          contractStatus: policy.body.status ?? '',
          paidToDateAdvance: policy.body.paidToDateAdvance ? new Date(policy.body.paidToDateAdvance) : null
        })
      )
    )

  export const submit =
    (policyNum: string, customerId: string, data: ChangeContactInfo.DataSubmit) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/customer/${customerId}/contact/address`,
        { body: data },
        policyNum,
        requestauth,
        [],
        customerId
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/customer/${customerId}/contact/address`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )

  export const getDispatchAddress = (policyIds: Array<string>) =>
    pipe(
      SubmissionService.getPolicies(policyIds.map((e) => ({ policyNo: e }))),
      ZIO.flatMap((policies) =>
        pipe(
          SubmissionService.getClients(policies.map((e) => ({ clientId: e.clientDespatchAddress }))),
          ZIO.map((clients) => {
            return policies
              .filter(
                (p) =>
                  p.status === 'IF' || (p.status === 'LA' && moment().diff(moment(p.paidToDateAdvance), 'months') < 24)
              )
              .map((policy) => {
                const client = clients.body.find((client) => client.clientId === policy.clientDespatchAddress)
                if (!client) return null
                return {
                  ...client,
                  policyNum: policy.policyNo
                }
              })
          })
        )
      )
    )

  export const getDuplicatePhoneInfo = (
    phone: string,
    clientNum: string
  ): Task<Array<ChangeContactInfo.DuplicateInfo>> =>
    pipe(
      SubmissionService.checkDuplicatePhone(phone),
      ZIO.flatMap((clientPolicies) =>
        pipe(
          clientPolicies,
          O.fromNullable,
          O.filter((e) => e.body.length > 0),
          O.map((e) => e.body.filter((x) => x.clientNum !== clientNum) ?? []),
          O.fold(
            () => ZIO.succeed([]),
            (clients) =>
              pipe(
                SubmissionService.getClients(
                  clients.map((e) => ({
                    clientId: e.clientNum ?? ''
                  }))
                ),
                ZIO.map((res): Array<ChangeContactInfo.DuplicateInfo> => {
                  return res.body.map(
                    (e, i): ChangeContactInfo.DuplicateInfo => ({
                      clientId: e.clientId ?? '',
                      clientName: e.name ?? '',
                      role: clients[i].role ?? '',
                      secuityNo: res.body && e ? String(e.externalIds?.SOE_VALUE) : '',
                      dob: res.body && e ? String(e.dob) : '',
                      gender: res.body && e ? String(e.sex) : ''
                    })
                  )
                })
              )
          )
        )
      )
    )

  export const getDuplicateEmailInfo = (
    email: string,
    clientNum: string
  ): Task<Array<ChangeContactInfo.DuplicateInfo>> =>
    pipe(
      SubmissionService.checkDuplicateEmail(email),
      ZIO.flatMap((clientPolicies) =>
        pipe(
          clientPolicies,
          O.fromNullable,
          O.filter((e) => e.body.length > 0),
          O.map((e) => e.body.filter((x) => x.clientNum !== clientNum) ?? []),
          O.fold(
            () => ZIO.succeed([]),
            (clients) =>
              pipe(
                SubmissionService.getClients(
                  clients.map((e) => ({
                    clientId: e.clientNum ?? ''
                  }))
                ),
                ZIO.map((res): Array<ChangeContactInfo.DuplicateInfo> => {
                  const clientList =
                    res.body && res.body.length > 0
                      ? res.body.map(
                          (e, i): ChangeContactInfo.DuplicateInfo => ({
                            clientId: res.body && e ? String(e.clientId) : '',
                            clientName: res.body && e ? String(e.name) : '',
                            role: clients[i].role ?? '',
                            secuityNo: res.body && e ? String(e.externalIds?.SOE_VALUE) : '',
                            dob: res.body && e ? String(e.dob) : '',
                            gender: res.body && e ? String(e.sex) : ''
                          })
                        )
                      : []

                  return clientList
                })
              )
          )
        )
      )
    )

  export const getPolicyList = (policyNum: string, ownerId: string): Task<ChangeContactInfo.ClientPolicy[]> =>
    pipe(
      POApi.post(`wf-api/customer/policies`)(
        t.type({
          body: t.array(
            t.type({
              clientNum: t.string,
              policyNo: t.string,
              role: t.string
            })
          )
        })
      )({
        body: {
          securityNo: `C${ownerId}`
        }
      }),
      ZIO.flatMap(
        (clientPolicies): Task<ChangeContactInfo.ClientPolicy[]> =>
          pipe(
            getDispatchAddress(clientPolicies.body.filter((e) => e.policyNo !== policyNum).map((e) => e.policyNo)),
            ZIO.map((clients) => {
              return clients.map((client) => {
                return {
                  policyNum: client?.policyNum ?? '',
                  name: client?.name ?? '',
                  address: client?.addressDetails
                    ? `${client.addressDetails?.PRIMARY?.line1 ?? ''}, ${
                        client.addressDetails?.PRIMARY?.line2 ?? ''
                      }, ${client.addressDetails?.PRIMARY?.line3 ?? ''}, ${
                        client.addressDetails?.PRIMARY?.line4 ?? ''
                      }`.replace(/(, ,)+/g, ',')
                    : ''
                }
              })
            })
          )
      )
    )
}
