import { PayoutPopup, form2, capFisrtLetterEachWord } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'

export namespace ExcellentStudentAwardForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  const ExcellentStudentAward = t.type({
    files: pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l && l.length > 0,
        () => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
        'FileList'
      )
    ),

    payout: pipe(
      t.array(PayoutPopup.SummaryCodec),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray => arr && arr.length > 0,
        () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
        'EmptyArray'
      ),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray =>
          arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0,
        () => i18next.t('message:MS050241'),
        'TotalAmountLessThanZero'
      )
    )
  })

  export type ExcellentStudentAward = t.TypeOf<typeof ExcellentStudentAward>

  export const codec = ExcellentStudentAward

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
