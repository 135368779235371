import React from 'react'
import { OBModalAction, OBModalPopup, OBSharedStyles } from '../../../ob-common'
import { useTranslation } from 'react-i18next'
import {StyleSheet, Text} from 'react-native'

type OBCallIndicatorPopupProp = {
  isVisible: boolean
  onCloseEvent?: () => void
  onYesEvent?: () => void
}

export const OBCallIndicatorPopup = (props: OBCallIndicatorPopupProp) => {
  const {t} = useTranslation()
  const modalButtons: OBModalAction[] = [
    {
      text: t('common:No'),
      onClickEvent: () => props.onCloseEvent && props.onCloseEvent(),
      actionStyle: [OBSharedStyles.actionContent, acceptStyles.actionLine],
      textStyle: OBSharedStyles.actionContentText
    },
    {
      text: t('common:Yes'),
      onClickEvent: () => props.onYesEvent && props.onYesEvent(),
      actionStyle: [OBSharedStyles.actionContent, OBSharedStyles.actionHightLight],
      textStyle: OBSharedStyles.actionHightLightText
    }
  ]
  
  return (
    <OBModalPopup
      isVisible={props.isVisible}
      modalTitle={t('Outbound:OBCallOutPopup:ConfirmSavingCallResult')}
      modalBody={<Text> {t("message:OBMSDoNotCall")}</Text>}
      onCloseEvent={()=> props.onCloseEvent && props.onCloseEvent()}
      modalActions={modalButtons}
      modalContainerStyle={acceptStyles.modalContainerStyle}
      modalContentStyle={acceptStyles.modalContentStyle}
    >
    </OBModalPopup>
  )
}

const acceptStyles = StyleSheet.create({
  actionLine: {
    marginRight: 15
  },
  modalContainerStyle: {
    width: 550
  },
  modalContentStyle: {
    marginVertical: 25
  }
})