import { LasStatus } from './LasStatus'
import * as t from 'io-ts'

export interface PaymentMethod {
  method: string
  detail: string
  amount: number
  status?: LasStatus | null
  note?: string
  action?: string
  bankCode?: string
}

export interface AccountBalanceRequest {
  policyNum: string
  function: string
  accountGroups: Array<{
    sacsCode: string
    sacsType: string
  }>
}

export interface AccountBalance {
  amount: number
  sacsCode: string
  sacsType: string
}

export const AccountBalanceC = t.type({
  body: t.type({
    accountGroups: t.array(
      t.type({
        amount: t.number,
        sacsCode: t.string,
        sacsType: t.string
      })
    )
  })
})

export type AccountBalanceC = t.TypeOf<typeof AccountBalanceC>
