import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  ChangePremiumService,
  ErrorHandling,
  form2,
  Input,
  PulseOpsFormat,
  sharedStyle,
  SubmissionService,
  TransactionType
} from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString } from 'io-ts-types'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps } from './policy-service-props'

type NewInstallmentPremiumBrand = {
  readonly MS050053: unique symbol
  readonly MS050054: unique symbol
  readonly MS050034: unique symbol
}
type NewInstallmentPremium = t.Branded<NonEmptyString, NewInstallmentPremiumBrand>

const FormData = t.type({
  currentSumAssured: form2.string.optional,
  currentInstallmentPremium: form2.string.optional,
  newInstallmentPremium: pipe(
    form2.string.requiredM(() =>
      i18next.t('message:MS020001', { field: i18next.t('submission:NewInstallmentPremium') })
    ),
    form2.refine(
      (value): value is NewInstallmentPremium => value !== currentFormData.currentInstallmentPremium,
      () => i18next.t('message:MS050053'),
      'MS050053'
    ),
    form2.refine(
      (value): value is NewInstallmentPremium => Number(value) >= currentFormData.minAPI,
      (): string => i18next.t('message:MS050054', { min: currentFormData.minAPI.toString() }),
      'MS050054'
    )
    // ,form2.refine(
    //   (value): value is NewInstallmentPremium => {
    //     const newSam = Number(value) / Number(currentFormData.currentSumAssured)
    //     return newSam >= currentFormData.minSAM && newSam <= currentFormData.maxSAM
    //   },
    //   () => i18next.t('message:MS050034'),
    //   'MS050034'
    // )
  ),
  estimatedRequirePayinAmount: form2.string.optional
})
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

type SubmitData = {
  currentSumAssured: number
  currentInstallmentPremium: number
  newInstallmentPremium: number
  requirePayInAmount: number
}

let currentFormData: FormDataRaw & { minAPI: number; minSAM: number; maxSAM: number } = {
  currentSumAssured: '',
  currentInstallmentPremium: '',
  newInstallmentPremium: '',
  estimatedRequirePayinAmount: '',
  minAPI: 0,
  minSAM: 0,
  maxSAM: 0
}

export const ChangePremium = (props: PolicyServiceProps<SubmitData>) => {
  const { t } = useTranslation()

  const policyNumber = props.policyNumber!
  const defaultValues: FormDataRaw = {
    currentSumAssured: '0',
    currentInstallmentPremium: '0',
    newInstallmentPremium: '',
    estimatedRequirePayinAmount: '0'
  }

  const [isLoading, bindLoader] = useLoading(false)
  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  currentFormData = Object.assign(currentFormData, base.watch())

  const data = pipe(
    ZIO.zipPar(SubmissionService.getPolicy(policyNumber), ChangePremiumService.getDetail(policyNumber)),
    ZIO.map(
      ([
        {
          body: { paidToDateAdvance, attributesExt, billFreq }
        },
        { curSumAssured, curRenewalPremium, minAPI, minSAM, maxSAM, suspenseAmount }
      ]) => {
        base.setValue('currentSumAssured', curSumAssured.toString())
        base.setValue('currentInstallmentPremium', curRenewalPremium.toString())

        currentFormData = Object.assign(currentFormData, { minAPI, minSAM, maxSAM })

        return {
          curSumAssured,
          curRenewalPremium,
          paidToDateAdvance,
          billFreq,
          basicPremium: attributesExt?.basicPremium,
          riderPremium: attributesExt?.riderPremium,
          suspenseAmount
        }
      }
    ),
    ErrorHandling.runDidUpdate([policyNumber])
  )

  React.useEffect(() => {
    return () => {
      base.reset()
    }
  }, [])

  // const newInstallmentPremium = base.watch('newInstallmentPremium')

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  // const onBlurNewInstallmentPremium = () => {
  //   if (data && newInstallmentPremium && !base.formState.errors.newInstallmentPremium) {
  //     let result = 0
  //     if (data.paidToDateAdvance && new Date(data.paidToDateAdvance).getTime() < new Date().getTime()) {
  //       const basicPremium = data.basicPremium ? Number(data.basicPremium) : 0
  //       const riderPremium = data.riderPremium ? Number(data.riderPremium) : 0

  //       const C: 1 | 2 = data.billFreq === '12' ? 2 : 1

  //       result = Math.floor(C * (basicPremium + riderPremium))
  //     }
  //     updateEstimatedRequirePayinAmount(result)
  //   }
  // }

  const updateEstimatedRequirePayinAmount = (newInstallmentPremiumVal: string) => {
    if (data && newInstallmentPremiumVal) {
      // const newInstallmentPremium = Number(newInstallmentPremiumVal ?? 0)
      const estimatedRequirePayinAmount = Math.max(Number(newInstallmentPremiumVal) - (data?.suspenseAmount ?? 0), 0)
      base.setValue('estimatedRequirePayinAmount', estimatedRequirePayinAmount.toString())
    }
  }

  const checkNextStep = () => {
    const newInstallmentPremium = Number(base.watch('newInstallmentPremium') ?? '0')
    const currentInstallmentPremium = Number(base.watch('currentInstallmentPremium') ?? '0')
    const inputData: ChangePremiumService.InputPolicyForLAS = {
      policyNum: props.policyNumber ?? '',
      newInstallmentPremium,
      currentInstallmentPremium
    }
    return pipe(
      ChangePremiumService.checkValidPolicyFromLAS(inputData),
      ZIO.foldM(
        (error) =>
          ZIO.effect(() => {
            if (!!error && !!error.source) {
              showToast(t('message:MS050034'), 'error')
            }
            return false
          }),
        (_) =>
          ZIO.effect(() => {
            return true
          })
      ),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  props.initSubmission({
    validate: async () => {
      const _form = await handleSubmit(() => undefined)()
      if (E.isRight(_form) && data && (await checkNextStep())) {
        const form = _form.right

        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/change-premium`,
          body: {
            currentSumAssured: data.curSumAssured,
            currentInstallmentPremium: data.curRenewalPremium,
            newInstallmentPremium: Number(form.newInstallmentPremium),
            requirePayInAmount: Number(form.estimatedRequirePayinAmount)
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_PREMIUM),
          collerationId: policyNumber,
          transaction: TransactionType.CHANGE_PREMIUM
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset({
        ...defaultValues,
        currentSumAssured: base.watch('currentSumAssured'),
        currentInstallmentPremium: base.watch('currentInstallmentPremium')
      })
    }
  })

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:ChangePremium')}</Text>
        <View style={[sharedStyle.sectionContent, { paddingHorizontal: 5 }]}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:CurrentSumAssured')}
                value={toCurrency(base.watch('currentSumAssured'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:CurrentInstallmentPremium')}
                value={toCurrency(base.watch('currentInstallmentPremium'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="newInstallmentPremium"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required
                    title={t('submission:NewInstallmentPremium')}
                    value={value ?? undefined}
                    maxLength={15}
                    onChange={(val)=> {
                      onChange(val)
                      updateEstimatedRequirePayinAmount(val)
                    }}
                    onBlur={onBlur}
                    inputType="money"
                    disabled={props.isConfirmed}
                    suffix="VND"
                    containerStyle={{ maxWidth: 250 }}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:EstimatedRequirePayinAmount')}
                value={toCurrency(base.watch('estimatedRequirePayinAmount'))}
                disabled={true}
              />
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 15
  }
} as const)

function toCurrency(value: number | string | null | undefined) {
  return PulseOpsFormat.thousandSepartor(value ? Number(value) : 0) + ' VND'
}
