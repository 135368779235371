import { CancelRider } from './CancelRider'
import { ReinstatementModel } from './Reinstatement'
import { RiderAlterationData } from './RiderAlteration'
import { RiderReinstatementModel } from './RiderReinstatement'
import { VeriLARiderInfo } from './task-detail/RiderAlteration'

export namespace MajorCombined {
  export interface ChangeContractType {
    basicProductCode: string
    riskCommDate: string
    age: number
    sumAssured: number
    policyYear: string
    riskCessDate: string
    premiumYear: string
    installmentPremium: number
  }

  export interface BillingChange {
    curBillingFrequency: string
    curInstallmentPremium: string
    newBillingFrequency: string
    newInstallmentPremium: string
    effectiveDateNewFrequency: string
    requirePayinAmount: string
  }

  export interface ProductOptionSwitching {
    benefits: string
    oldBenefit: string
    currentSumAssured: number
    newSumAssured: number
    riskSumAssured: number
    minimumSumAssured: number
  }
  export interface SubmitLARider {
    clientName?: string
    lifeAssured: string
    riders: CancelRider.SubmitedRiderInfo[]
  }
  export interface CancelRider {
    policyNo: string
    lifeAssureds: SubmitLARider[]
  }

  export interface RiderAlteration {
    isRiderAlteration: boolean
    isAddLA: boolean
    lifeAssuredList: Array<RiderAlterationData.LifeAssuredList>
    lifeAssuredAdditions: Array<RiderAlterationData.LifeAssuredAdditions>
  }

  export interface VeRiderAlteration {
    isRiderAlteration: boolean
    isAddLA: boolean
    lifeAssuredList: Array<VeriLARiderInfo>
    lifeAssuredAdditions: Array<RiderAlterationData.LifeAssuredAdditions>
  }

  export interface SubmitMajorCombined {
    majorType: string
    changeContract?: ChangeContractType
    billingChange?: BillingChange
    productOptionSwitching?: ProductOptionSwitching
    riderCancellation?: CancelRider
    riderAlteration?: RiderAlteration
    reinstatement?: ReinstatementModel.ReinstatementSubmitData
    riderReinstatement?: RiderReinstatementModel.RiderReinstatementSubmitData
    requirePayinAmount?: string
  }
}
