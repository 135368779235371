import { Alert, assets } from '@pulseops/common'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './styled'

export type ImportButtonProps = {
  onImport: (file: File) => void
  loading?: boolean
  disabled?: boolean
}

export const ImportButton = ({ onImport, loading, disabled }: ImportButtonProps) => {
  const { t } = useTranslation()

  const inputRef = React.createRef<HTMLInputElement>()

  return (
    <>
      <Button
        title={t('common:import')}
        onPress={() => {
          inputRef.current?.click()
        }}
        icon={<assets.IconImport />}
        loading={loading}
        disabled={disabled}
      />
      <input
        ref={inputRef}
        hidden
        type="file"
        multiple={false}
        accept=".xlsx"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target.files?.item(0)
          if (file && file.name.split('.'))
            if (file) {
              pipe(
                file.name.split('.'),
                A.last,
                O.filter((ext) => ext === 'xlsx'),
                E.fromOption(() => 'business:MS000013'),
                E.chain(() =>
                  pipe(
                    file,
                    E.fromPredicate(
                      (f) => f.size / 1024 / 1024 < 10,
                      () => 'business:MS000014'
                    )
                  )
                ),
                E.fold((message) => {
                  Alert.alert(t(message))
                }, onImport)
              )
              event.target.files = null
              event.target.value = ''
            }
        }}
      />
    </>
  )
}
