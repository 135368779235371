import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Tooltip, withStyles } from '@material-ui/core'

interface IBItemInfo {
  icon: JSX.Element
  data: {
    label: string
    value: string
  }
}
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgb(112, 119, 126)',
    fontSize: 15
  }
}))(Tooltip)

const IBItemInfo = (props: IBItemInfo) => {
  const { icon, data } = props
  const viewRef = React.useRef<View | null>(null)
  const textRef = React.useRef<Text | null>(null)
  const [visibleText, setVisibleText] = React.useState(data.value)
  React.useEffect(() => {
    if (textRef.current && viewRef.current && data.value !== '-') {
      textRef.current.measure((x, y, textWidth) => {
        viewRef.current?.measure((_, __, viewWidth) => {
          if (textWidth > viewWidth - 55) {
            const maxCharacters = Math.floor((viewWidth - 55) / (textWidth / data.value.length)) - 3
            setVisibleText(data.value.slice(0, maxCharacters) + '...')
          } else {
            setVisibleText(data.value)
          }
        })
      })
    }
  }, [data.value])

  return (
    <View
      ref={viewRef}
      style={{ flexDirection: 'row', alignSelf: 'center', alignItems: 'center', width: `${100 / 4}%` }}
    >
      <View style={{ marginRight: 5 }}>{icon}</View>
      <View>
        <Text style={{ color: '#58647A', fontSize: 12, fontWeight: 'bold' }}>{data.label}</Text>
        <LightTooltip
          title={data.value}
          disableHoverListener={data.value.length <= visibleText.length}
          placement="bottom-start"
        >
          <Text style={{ color: '#ED1B2E', fontSize: 15 }}>{visibleText}</Text>
        </LightTooltip>
        <Text ref={textRef} style={{ color: '#ED1B2E', fontSize: 15, opacity: 0, height: 0 }}>
          {data.value}
        </Text>
      </View>
    </View>
  )
}

interface Props {
  data: IBItemInfo[]
  behaviorContent: string
}

export const IBClientGeneralInfo = (props: Props) => {
  const { data, behaviorContent } = props
  return (
    <View>
      <View style={styles.headerBar}>
        <View style={{ flexDirection: 'row', marginHorizontal: 25 }}>
          {data.map((item) => (
            <IBItemInfo icon={item.icon} data={item.data} />
          ))}
        </View>
      </View>
      <View style={styles.notiContent}>
        <Text style={styles.notiContent2}>{behaviorContent || ''}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  headerBar: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    height: 65,
    marginBottom: 10,
    justifyContent: 'center'
  },
  notiContent: {
    margin: 5
  },
  notiContent1: {
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 23,
    color: '#09A4D8',
    textAlign: 'center'
  },
  notiContent2: {
    color: '#58647A',
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: '400',
    lineHeight: 21,
    textAlign: 'center'
  }
})
