import React from 'react'
import {
  form2,
  ErrorHandling,
  AppContext,
  ReinstatementModel,
  ReinstatementService,
  GeneralService,
  PulseOpsFormat,
  SelectOption,
  TransactionType,
  StorageBlob,
  RiderReinstatementModel,
  CancelRiderService,
  CancelRider,
  sharedStyle,
  Panel,
  useTranslator,
  TableField,
  getDiffMonths,
  ProductEnum,
  RiderReinstatementService,
  convertToAmount,
  TaskType,
  SelectSearch,
  assets,
  Input,
  BuildConfig,
  ImgUploadMutiple
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import moment from 'moment'
import { ScrollView, TouchableOpacity, View, Text, StyleSheet, Platform } from 'react-native'
import { MajorCombinedForm } from '../major-combined-form'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { UploadedFilesInfo } from '../../policy-service-props'
import { FilePresent, SC, ValueShow } from '../../../common'
import { ChooseView } from '../../rider-reinstatement/common'

interface Props {
  policyNum: string
  isConfirmed: boolean
  validateBeforeContinue: ({
    validateRiderReinstatement
  }: {
    validateRiderReinstatement: () => Promise<boolean>
  }) => void
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}
export const RiderReinstatementComponent: React.FC<Props> = ({
  policyNum,
  isConfirmed,
  form,
  validateBeforeContinue
}) => {
  const [loading, bindLoader] = useLoading(false)
  const { t, i18n } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [data, setData] = React.useState<CancelRider.LARiderInfo[]>([])
  const [detailLapsedDate, setLapsedDate] = React.useState<string>('')
  const [reinsFee, setReinsFee] = React.useState<number>()
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const isFocused = useIsFocused()

  const st = useTranslator('submission').t
  const { getValues, watch } = form

  const columns = [
    {
      key: '1',
      title: st('Rider'),
      name: 'productName'
    },
    {
      key: '2',
      title: st('RiskCommDate'),
      name: 'riskCommDate'
    },
    {
      key: '3',
      title: st('LapsedDate'),
      name: 'lapsedDate'
    },
    {
      key: '4',
      title: st('SumAssured'),
      name: 'sumAssured'
    },
    {
      key: '5',
      title: st('InstallmentPremium'),
      name: 'installmentPrem'
    }
  ]

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    setErrorMessage('')
    pipe(
      ZIO.zipPar(
        RiderReinstatementService.getLapseDate(policyNum!),
        CancelRiderService.getDetail(policyNum!, ProductEnum.Status.LA)
      ),
      ZIO.map(([lapseDateData, detail]) => {
        // const day = moment().startOf('days').diff(moment(detailGetLapsedDate.reinData.attributesExt.LAPSED_DATE).startOf('days'), 'days')
        setLapsedDate(lapseDateData)
        // if (day <= 730) {
        const riderInfoList = detail.laRiderInfo
        riderInfoList.map((item, index) => {
          const riderList = detail.riderNameList
          item.riders.map((itemRider, indexRider) => {
            const riderFind = riderList.filter((i) => i.code === itemRider.productCode)
            riderInfoList[index].riders[indexRider].riskCommDate = PulseOpsFormat.dateStringtoFormat(
              riderInfoList[index].riders[indexRider].riskCommDate,
              'DD/MM/YYYY'
            )
            riderInfoList[index].riders[indexRider].lapsedDate = PulseOpsFormat.dateStringtoFormat(
              lapseDateData,
              'DD/MM/YYYY'
            )
            if (riderFind[0])
              riderInfoList[index].riders[indexRider].productName =
                itemRider.productCode + ' - ' + (i18n.language === 'vi' ? riderFind[0].nameVi : riderFind[0].name)
          })
        })
        setData(riderInfoList)
        // }
        // else
        //   setData([])
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }, [i18n.language])

  React.useEffect(() => {
    if (isFocused) {
      form.setValue('riderReinstatement', {
        reinFee: 0,
        lifeAssuredList: [],
        attachmentFiles: []
      })
    }
  }, [isFocused])

  const { detail, occupationsOptions, dataRiders } = pipe(
    ZIO.zipPar(
      ReinstatementService.getDetail(policyNum!),
      GeneralService.getOccupations,
      CancelRiderService.getDetail(policyNum!, ProductEnum.Status.LA)
    ),
    ZIO.map(([detail, occupations, data]) => {
      const occupationsOptions = occupations.map((occupation) => {
        return {
          value: occupation.code,
          label: occupation.name
        }
      })
      const dataRiders = data
      //return ZIO.unit
      initData(detail, occupationsOptions, dataRiders)
      return {
        detail,
        occupationsOptions,
        dataRiders
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  validateBeforeContinue({
    validateRiderReinstatement: async () => {
      if (checkAttachFile() && data.length >= 1) return true
      else return false
    }
  })

  // const resetForm = () => {
  //     setErrorMessage('')
  //     initData(detail, occupationsOptions, dataRiders)
  // }

  const initData = (
    detail: ReinstatementModel.Detail,
    occupations: SelectOption[],
    dataRiders: CancelRider.DetailCancelRiderInfo
  ) => {
    // const day = moment().startOf('days').diff(moment(detail.reinData.attributesExt.LAPSED_DATE).startOf('days'), 'days')
    // const checkDay = detail && detail.reinData && day <= 730
    let reinData: MajorCombinedForm.LifeAssured[] = []
    detail.reinData.lifeAssured.map((client, index) => {
      const riders: MajorCombinedForm.RiderDetail[] = []
      const riderFindIndex = dataRiders.laRiderInfo.findIndex((item) => item.lifeAssured === client.clientId)
      if (riderFindIndex !== -1) {
        dataRiders.laRiderInfo[riderFindIndex].riders.map((item, itemIndex) => {
          riders[itemIndex] = {
            coverageCode: item.productCode,
            riskCommDate: item.riskCommDate,
            lapsedDate: detail.reinData.attributesExt.LAPSED_DATE,
            sumAssured: item.sumAssured,
            riderPremium: item.installmentPrem
          }
        })
        const currentOccupation = occupations.find((o) => o.value === client.attributesExt.CUR_OCCUPATION)
        const reinDetail: MajorCombinedForm.LifeAssured = {
          name: client.name,
          clientNum: client.clientId,
          healthAnswer: false,
          occupationAnswer: false,
          activitiesAnswer: false,
          companyAnswer: false,
          curOccupation: currentOccupation ?? { label: '', value: '' },
          agentCode: detail.reinData.attributesExt.AGENT_CODE,
          riderList: riders
        }
        reinData.push(reinDetail)
      }
    })

    form.setValue('riderReinstatement', {
      reinFee: reinsFee ?? 0,
      lifeAssuredList: reinData ?? [],
      attachmentFiles: []
    })
  }

  const getFee = () => {
    if (policyNum)
      pipe(
        RiderReinstatementService.getReinStateFee(policyNum),
        ZIO.tap((value) => {
          form.setValue('riderReinstatement.reinFee', value)
          setReinsFee(value)
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
    else setReinsFee(0)
  }

  const checkAttachFile = () => {
    const lapseDate = moment(detailLapsedDate)
    const endDate = moment(new Date())
    const diffM = getDiffMonths(lapseDate, endDate)
    const attachmentFiles = watch('riderReinstatement.attachmentFiles')
    const lifeAssuredList = watch('riderReinstatement.lifeAssuredList')
    if (diffM >= 12 || lifeAssuredList.some((c) => c.healthAnswer)) {
      return !!attachmentFiles && attachmentFiles.length >= 1
    }
    return true
  }

  const checkRequireAttachFile = () => {
    const lapseDate = moment(detailLapsedDate)
    const endDate = moment(new Date())
    const diffM = getDiffMonths(lapseDate, endDate)
    const lifeAssuredList = watch('riderReinstatement.lifeAssuredList')
    if (diffM >= 12 || (lifeAssuredList?.length > 0 && lifeAssuredList.some((c) => c.healthAnswer))) {
      return true
    }
    return false
  }

  return (
    <ScrollView>
      <SC.Container>
        <SC.Padding vertical={10}>
          <Text style={sharedStyle.sectionTitle}>{t('submission:RidersList').toUpperCase()}</Text>
        </SC.Padding>
        <View>
          {data.map((item, index) => {
            return (
              <Panel isExand key={index} title={<Text style={{ marginTop: 16 }}>{item.clientName}</Text>}>
                <View>
                  <TableField
                    columns={columns}
                    dataSource={item.riders}
                    currency="VND"
                    styleBody={styles.bodyTable}
                    styleHeader={styles.headerTable}
                  />
                </View>
              </Panel>
            )
          })}
        </View>
        {!isConfirmed && (
          <View>
            <TouchableOpacity
              style={[
                sharedStyle.button,
                { borderColor: '#ED1B2E', backgroundColor: '#EE1A2D', width: 300, marginLeft: 10 }
              ]}
              onPress={getFee}
            >
              <Text style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                {t('submission:CalculateReinstateFee')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        {(reinsFee || reinsFee === 0) && (
          <View style={{ marginVertical: 10, marginLeft: 10 }}>
            <SC.TitleText>{t('submission:EstimatedReinstateFee')}</SC.TitleText>
            <SC.BoldText color={'#EE1A2D'}>{convertToAmount(reinsFee) + ' VND'}</SC.BoldText>
          </View>
        )}

        <SC.Container>
          <View style={{ marginVertical: 10 }}>
            <Text style={sharedStyle.sectionTitle}>{t('submission:StatementOfInsurability')}</Text>
          </View>
          {watch('riderReinstatement.lifeAssuredList') &&
            watch('riderReinstatement.lifeAssuredList').map((value, index) => {
              return value ? (
                <Controller
                  key={`list_rein_riderReins_${index}`}
                  control={form.control}
                  name={`riderReinstatement.lifeAssuredList.${index}`}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <Panel title={value?.name || ''} containerStyle={{ backgroundColor: '#fafafa' }}>
                        <ChooseView
                          disable={isConfirmed}
                          title={t('submission:AnyChangesInStateOfHealth')}
                          selected={value?.healthAnswer}
                          onChange={(select) => onChange({ ...value, healthAnswer: select })}
                        />

                        <ChooseView
                          disable={isConfirmed}
                          title={t('submission:AnyChangesInOccupation')}
                          selected={value?.occupationAnswer}
                          onChange={(select) => onChange({ ...value, occupationAnswer: select })}
                        />
                        {value?.occupationAnswer && (
                          <SC.Row>
                            <SC.InputContainer maxWidth={300}>
                              <SC.Padding vertical={10}>
                                <ValueShow
                                  title={t('submission:CurrentOccupation')}
                                  value={value.curOccupation?.label || ''}
                                />
                              </SC.Padding>
                            </SC.InputContainer>

                            <SC.Divider width={20} />

                            <SC.InputContainer maxWidth={300}>
                              <SC.Padding vertical={10}>
                                <Controller
                                  key={`newOccupation_riderReins_${index}`}
                                  control={form.control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: t('message:MS020001', {
                                        field: t('submission:NewOccupation').toLowerCase()
                                      })
                                    }
                                  }}
                                  name={`riderReinstatement.lifeAssuredList.${index}.newOccupation`}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <SelectSearch
                                      disabled={isConfirmed}
                                      options={occupationsOptions || []}
                                      value={value}
                                      label={t('submission:NewOccupation')}
                                      required
                                      onChange={onChange}
                                      placeholder={t('common:Select')}
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      errorMessage={error?.message}
                                    />
                                  )}
                                />
                              </SC.Padding>
                            </SC.InputContainer>
                          </SC.Row>
                        )}

                        <ChooseView
                          disable={isConfirmed}
                          title={t('submission:AnyChangeInActivities')}
                          selected={value?.activitiesAnswer}
                          onChange={(select) => onChange({ ...value, activitiesAnswer: select })}
                        />

                        {value?.activitiesAnswer && (
                          <SC.Row>
                            <SC.InputContainer maxWidth={300}>
                              <SC.Padding vertical={10}>
                                <Controller
                                  key={`newActivity_riderReins_${index}`}
                                  control={form.control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: t('message:MS020001', {
                                        field: t('submission:NewActivity').toLowerCase()
                                      })
                                    }
                                  }}
                                  name={`riderReinstatement.lifeAssuredList.${index}.newActivity`}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input
                                      disabled={isConfirmed}
                                      title={t('submission:NewActivity')}
                                      value={value}
                                      onChange={onChange}
                                      required
                                      onBlur={onBlur}
                                      maxLength={100}
                                      // placeholder={'Input'}
                                      errorMessage={error?.message}
                                    />
                                  )}
                                />
                              </SC.Padding>
                            </SC.InputContainer>
                          </SC.Row>
                        )}
                        <ChooseView
                          title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                          selected={value?.companyAnswer}
                          onChange={(select) => onChange({ ...value, companyAnswer: select })}
                        />

                        {value?.companyAnswer && (
                          <SC.Row>
                            <SC.InputContainer>
                              <SC.Padding vertical={5}>
                                <Controller
                                  key={`newCompany_riderReins_${index}`}
                                  control={form.control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: t('message:MS020001', {
                                        field: t('submission:CompanyName').toLowerCase()
                                      })
                                    }
                                  }}
                                  name={`riderReinstatement.lifeAssuredList.${index}.newCompany`}
                                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <Input
                                      disabled={isConfirmed}
                                      title={t('submission:CompanyName')}
                                      value={value}
                                      onChange={onChange}
                                      required
                                      onBlur={onBlur}
                                      maxLength={100}
                                      // placeholder={'Input'}
                                      errorMessage={error?.message}
                                    />
                                  )}
                                />
                              </SC.Padding>
                            </SC.InputContainer>
                          </SC.Row>
                        )}
                      </Panel>
                    )
                  }}
                />
              ) : (
                <></>
              )
            })}

          {isConfirmed ? (
            form.watch('riderReinstatement.attachmentFiles') &&
            form.watch('riderReinstatement.attachmentFiles').length > 0 && (
              <SC.Padding>
                <SC.Padding vertical={10} horizontal={10}>
                  <SC.TitleText>{t('submission:HealthDeclaration')}</SC.TitleText>
                </SC.Padding>
                <SC.Row>
                  {form.watch('riderReinstatement.attachmentFiles').map((value, index) => {
                    const wrapValue = { ...value, uploadedDate: value.uploadedDate!, file: value.file! }
                    return (
                      <SC.InputContainer key={`file_health_declaration_${index}`}>
                        <SC.Padding horizontal={10}>
                          <FilePresent file={wrapValue} />
                        </SC.Padding>
                      </SC.InputContainer>
                    )
                  })}
                </SC.Row>
              </SC.Padding>
            )
          ) : (
            <SC.Padding>
              {Platform.OS === 'web' && (
                <SC.BoldText>
                  {t('submission:HealthNote')}
                  <a href={`${assets.UpdatedRedatingHealthyTemplate}`} download style={{ color: 'red' }}>
                    {t('submission:Here')}
                  </a>
                </SC.BoldText>
              )}
              <Controller
                control={form.control}
                name="riderReinstatement.attachmentFiles"
                render={({ field: { value, onChange } }) => {
                  const wrapFile =
                    value?.map((file) => ({ ...file, uploadedDate: file.uploadedDate!, file: file.file! })) || []
                  return (
                    <SC.Padding>
                      <SC.Padding top={8}>
                        <SC.BoldText>
                          {t('submission:HealthDeclaration')}
                          {checkRequireAttachFile() ? <SC.ErrorText> * </SC.ErrorText> : <></>}
                        </SC.BoldText>
                      </SC.Padding>
                      <ImgUploadMutiple value={wrapFile} onChange={onChange} />
                      <SC.ErrorText>{errorMessage}</SC.ErrorText>
                    </SC.Padding>
                  )
                }}
              />
            </SC.Padding>
          )}
        </SC.Container>
      </SC.Container>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  bodyTable: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16
  },
  headerTable: {
    paddingVertical: 16,
    paddingHorizontal: 16
  }
})
