import { CustomerDataAML } from './models';
export namespace ChangeOtherInfomationPersonalService {
  export const replaceTwoArray = <A extends object>(array1: Array<A>, array2: Array<A>, typeKey: keyof A) => {
    return array1.map((elm: A) => array2.find((obj: A) => elm[typeKey] === obj[typeKey]) ?? elm)
  }

  export const replaceArraysofObject = <A extends object>(
    array: Array<A>,
    index: number,
    { key, value }: { key: keyof A; value: unknown }
  ) => {
    return array.fill(
      {
        ...array[index],
        [key]: value
      },
      index,
      index + 1
    )
  }

  export const getClientDetail = (clients: CustomerDataAML[], customerId: string) => {
    return [...clients].find((client) => client.customerId === customerId)
  }
}
