import { Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { DocIdC } from './doc-id'

export const VerificationDecisionC = t.type({
  id: t.string,
  masterContractNo: t.string,
  policyYear: t.number,
  checklistName: DocIdC,
  decision: Nullable(t.boolean),
  comment: Nullable(t.string),
  status: t.string,
  requestDate: Nullable(DateFromISOString),
  completeDate: Nullable(DateFromISOString),
  createdDate: DateFromISOString,
  createdBy: t.string
})
export type VerificationDecision = t.TypeOf<typeof VerificationDecisionC>
