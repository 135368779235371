import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { ClientName } from './CepCommonModels'

export const CustomerNotesC = t.type({
  noteId: Maybe(t.number),
  noteCreatedBy: Maybe(t.string),
  noteCreatedDate: Maybe(t.string),
  noteContent: Maybe(t.string)
})

export type CustomerNotesC = t.TypeOf<typeof CustomerNotesC>

export const TierHistory = t.type({
  customerName: Maybe(t.string),
  activePoint: Maybe(t.number),
  oldAPE: Maybe(t.number),
  newAPE: Maybe(t.number),
  oldTier: Maybe(t.string),
  newTier: Maybe(t.string),
  createdDate: Maybe(t.string)
})

export type TierHistory = t.TypeOf<typeof TierHistory>

export const CustomerTierHistoryC = t.type({
  tierHistory: Maybe(t.array(TierHistory)),
  clientName: Maybe(ClientName)
})

export type CustomerTierHistoryC = t.TypeOf<typeof CustomerTierHistoryC>

export const GiftRedemptionC = t.type({
  clientId: Maybe(t.string),
  transactionId: Maybe(t.string),
  promotionId: Maybe(t.string),
  promotionName: Maybe(t.string),
  providerName: Maybe(t.string),
  promotionCreateDate: Maybe(t.string),
  promotionReceivedDate: Maybe(t.string),
  deliveryStatus: Maybe(t.string),
  deliveryDate: Maybe(t.string),
  deliveryNote: Maybe(t.string)
})

export type GiftRedemptionC = t.TypeOf<typeof GiftRedemptionC>

export const CodeRedemptionC = t.type({
  code: Maybe(t.string),
  providerName: Maybe(t.string),
  dealName: Maybe(t.string),
  codeUsingStatus: Maybe(t.string),
  redeemCodeDate: Maybe(t.string),
  expiredDate: Maybe(t.string),
  channel: Maybe(t.string)
})
export type CodeRedemptionC = t.TypeOf<typeof CodeRedemptionC>

export const RewardPointC = t.type({
  issuer: Maybe(t.string),
  status: Maybe(t.string),
  pointType: Maybe(t.string),
  pointValue: Maybe(t.number),
  pointComment: Maybe(t.string),
  pointRemark: Maybe(t.string),
  pointTransferCreatedAt: Maybe(t.string),
  pointTransferExpiredAt: Maybe(t.string)
})

export type RewardPointC = t.TypeOf<typeof RewardPointC>

export const PruRewardDataC = t.type({
  data: t.type({
    customerNotes: t.array(CustomerNotesC),
    customerTierHistories: CustomerTierHistoryC,
    giftRedemptions: t.array(GiftRedemptionC),
    rewardPoints: t.array(RewardPointC)
  })
})

export type PruRewardDataC = t.TypeOf<typeof PruRewardDataC>

export const RewardPointSearchC = t.type({
  start: t.number,
  size: t.number,
  data: Maybe(t.type({
    pointType: Maybe(t.string),
    status: Maybe(t.string),
  }))
})

export type RewardPointSearchC = t.TypeOf<typeof RewardPointSearchC>

export const GiftRedemptionSearchC = t.type({
  start: t.number,
  size: t.number,
  data: Maybe(t.type({
    deliveryStatus: Maybe(t.string)
  }))
})

export type GiftRedemptionSearchC = t.TypeOf<typeof GiftRedemptionSearchC>

export const CodeRedemptionSearchC = t.type({
  start: t.number,
  size: t.number,
  data: Maybe(t.type({
    codeUsingStatus: Maybe(t.string)
  }))
})

export type CodeRedemptionSearchC = t.TypeOf<typeof CodeRedemptionSearchC>

export const PruRewardDataGiftC = t.type({
  size:  Maybe(t.number),
  start:  Maybe(t.number),
  total:  Maybe(t.number),
  data: t.array(GiftRedemptionC)
})

export type PruRewardDataGiftC = t.TypeOf<typeof PruRewardDataGiftC>

export const PruRewardDataCodeC = t.type({
  size: Maybe(t.number),
  start: Maybe(t.number),
  total: Maybe(t.number),
  data: t.array(CodeRedemptionC),
})

export type PruRewardDataCodeC = t.TypeOf<typeof PruRewardDataCodeC>

export const PruRewardDataPointC = t.type({
  size: Maybe(t.number),
  start: Maybe(t.number),
  total: Maybe(t.number),
  data: t.array(RewardPointC),
})

export type PruRewardDataPointC = t.TypeOf<typeof PruRewardDataPointC>

export const PruRewardDataCusNoteC = t.type({
  data: t.array(CustomerNotesC),
})
export type PruRewardDataCusNoteC = t.TypeOf<typeof PruRewardDataCusNoteC>

export const PruRewardDataTierHistoryC = t.type({
  data: CustomerTierHistoryC
})
export type PruRewardDataTierHistoryC = t.TypeOf<typeof PruRewardDataTierHistoryC>

export const PruRewardDataAgentC = t.type({
  data: t.type({
    agentCode: t.string,
    agentName: Maybe(t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })),
    agentPhone: Maybe(t.string),
    agentStatus: Maybe(t.string)
  })
})

export type PruRewardDataAgentC = t.TypeOf<typeof PruRewardDataAgentC>


