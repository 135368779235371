import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { OBSectionCol, OBSectionContent, OBFieldTitle, OBSectionRow, OBSharedStyles, OBTitle, OBFieldDescription } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { CallOutDetailContext, OBGeneralService, OBPolicyOwnerData } from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { Container, ErrorHandling, GeneralService, PulseOpsFormat } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { TaskConst } from '../../ob-task-management'
import { useIsFocused } from '@react-navigation/native'

export const OBPolicyOwner = () => {
  const { t, i18n } = useTranslation()
  const [dataPolicyOwner, setDataPolicyOwner] = React.useState<OBPolicyOwnerData | null>(null)
  const [loading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { policyNumber, isReloadedPolicyOwnerTab, setIsReloadedPolicyOwnerTab, caseId } = React.useContext(CallOutDetailContext)

  React.useEffect(() => {
      pipe(
        OBGeneralService.getPolicyOwner(policyNumber, caseId),
        ZIO.map((data) => {
          setDataPolicyOwner(data)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
  }, [policyNumber, caseId])

  React.useEffect(()=> {
    return () => {
      setDataPolicyOwner(null)
    }
  },[isFocused])

  React.useEffect(() => {
    if (isReloadedPolicyOwnerTab && !!policyNumber && !!caseId) {
      setTimeout(() => getPolicyOwnerInfo(), 1200)
    }
  }, [isReloadedPolicyOwnerTab])

  const occupations = pipe(
    GeneralService.getOccupations,
    ZIO.map(((res) => res)),
    ErrorHandling.runDidMount()
  )

  const countriesList = pipe(
    GeneralService.getCountries,
    ZIO.map((r) => r),
    ErrorHandling.runDidMount()
  )

  const getPolicyOwnerInfo = () => {
    pipe(
      OBGeneralService.getPolicyOwner(policyNumber, caseId),
      ZIO.map((data) => {
        setDataPolicyOwner(data)
        if (setIsReloadedPolicyOwnerTab) {
          setIsReloadedPolicyOwnerTab(false)
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getOccupationName = (occupationCode: string): string => {
    return occupations?.find((item) => item.code === occupationCode)?.name ?? '-'
  }

  const getGenderName = (gender?: string | null): string => {
    switch (gender) {
      case 'M':
        return t('common:Male')
      case 'F':
        return t('common:Female')
      default:
        return '-'
    }
  }

  const getLabelVipRank = (vipcode: string) => {
    const vipFind = TaskConst.VIPList.find((i) => i.code === vipcode)
    return i18n.language === 'en' ? vipFind?.descriptionEN : vipFind?.descriptionVN
  }

  const getCountryName = (countryCode: string) => {
    if (countriesList && countriesList.length > 0)
      return countriesList.find((item) => item.code === countryCode)?.name ?? ''
    return ''
  }

  const checkHighlight = (phoneLatest: string | undefined | null, phoneTrigger: string | undefined | null) => {
    return !!phoneLatest && phoneLatest !== phoneTrigger
  }

  const getTitlePhoneTrigger = (mobilePhone: string | undefined | null, homePhone: string | undefined | null) => {
    return !!mobilePhone ? t('Outbound:OBPolicyOwnerInfo:MobilePhoneTrigger')
     : !!homePhone ? t('Outbound:OBPolicyOwnerInfo:HomePhoneTrigger') 
     : t('Outbound:OBPolicyOwnerInfo:CompanyPhoneTrigger')
  }

  const getDataPhoneTrigger = (mobilePhone: string | undefined | null, homePhone: string | undefined | null, companyPhone: string | undefined | null) => {
    return !!mobilePhone ? mobilePhone : !!homePhone ? homePhone : (companyPhone ?? '-')
  }

  return (
    <Container loading={loading}>
      <View style={OBSharedStyles.policyInfoContainer}>
        <OBSectionContent style={policyStyle.container}>
          <Text style={policyStyle.header}>{t('Outbound:OBPolicyOwnerInfo:PolicyOwner')}</Text>
          <OBSectionRow>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:IDNumber')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.idNumber || '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Email')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.email || '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:VipTier')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.vipCode ? getLabelVipRank(dataPolicyOwner?.vipCode) ?? '-' : getLabelVipRank('C')}></OBFieldDescription>
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={policyStyle.secondLine}>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Gender')}></OBFieldTitle>
              <OBFieldDescription text={getGenderName(dataPolicyOwner?.genderCode) || '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Occupation')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.occupationCode ? getOccupationName(dataPolicyOwner?.occupationCode) : '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Income')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.incomeAmt ? PulseOpsFormat.thousandSepartor(dataPolicyOwner?.incomeAmt) : '-'}></OBFieldDescription>
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={policyStyle.secondLine}>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Dob')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.dob ? PulseOpsFormat.dateStringtoFormat(dataPolicyOwner?.dob, 'DD/MM/YYYY') : '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Address')}></OBFieldTitle>
              <OBFieldDescription
                text={(dataPolicyOwner?.address && dataPolicyOwner?.countryCode) ?
                  (dataPolicyOwner?.address + ' ' + (dataPolicyOwner?.countryCode ? getCountryName(dataPolicyOwner?.countryCode) : '')) :
                  dataPolicyOwner?.address || '-'}
              ></OBFieldDescription>
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={policyStyle.secondLine}>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:Homephone')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.homePhone || '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:MobilePhoneLatest')}></OBFieldTitle>
              <OBFieldDescription
                textStyle={checkHighlight(dataPolicyOwner?.mobilePhoneLatest, dataPolicyOwner?.mobilePhoneAtTriggerTime) ? { color: 'red' } : {}} text={dataPolicyOwner?.mobilePhoneLatest || '-'} />
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:AccountPruOnline')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.accountPruOnline || '-'}></OBFieldDescription>
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={policyStyle.secondLine}>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:CompanyPhone')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.companyPhone || '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={getTitlePhoneTrigger(dataPolicyOwner?.mobilePhoneAtTriggerTime, dataPolicyOwner?.homePhoneAtTriggerTime)}></OBFieldTitle>
              <OBFieldDescription text={getDataPhoneTrigger(dataPolicyOwner?.mobilePhoneAtTriggerTime, dataPolicyOwner?.homePhoneAtTriggerTime, dataPolicyOwner?.companyPhoneAtTriggerTime)}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBPolicyOwnerInfo:AccountStatus')}></OBFieldTitle>
              <OBFieldDescription text={dataPolicyOwner?.accountStatusCode || '-'}></OBFieldDescription>
            </OBSectionCol>
          </OBSectionRow>
        </OBSectionContent>
      </View>
    </Container>
  )
}
const policyStyle = StyleSheet.create({
  secondLine: {
    marginTop: 16
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  header: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16,
    marginBottom: 16
  }
})