import { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import {
  AppContext,
  assets,
  AuthService,
  CashOutService,
  downloadURI,
  ErrorHandling,
  GeneralService,
  HistoricTask,
  Input,
  Permission,
  PulseOpsFormat,
  RadioButton,
  RBAC,
  SelectOption,
  StorageBlobApi,
  TaskAccountingOP,
  TaskDetailService,
  TaskService,
  TransactionLabel
} from '@pulseops/common'
import { AccountingStackParamList } from './AccountingStackParamList'
import React from 'react'
import { NoteListSearch } from './note-list-form'
import { Controller, useForm } from 'react-hook-form'
import { Status } from '../cash-out-request/cashout-history-list'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { FileDocs, listDocId, showLabelDoc } from './note-list-screen'

type Props = StackScreenProps<AccountingStackParamList, 'AccountingUpdateNoteScreen'>

export const NoteDetailScreen = (props: Props) => {
  const { t, i18n } = useTranslation()
  const pvNumSearchForm = useForm<NoteListSearch.PvNumberSearchForm>({ defaultValues: { pvNumber: '' } })
  const paymentNumSearchForm = useForm<NoteListSearch.PaymentNumberSearchForm>({ defaultValues: { paymentNumber: '' } })
  const noteUpdateForm = useForm<NoteListSearch.NoteDetailUpdateForm>()
  const [loading, bindLoading] = useLoading(false)
  const [statusSelected, setStatusSelected] = React.useState<string>(Status.VALID)
  const [dataAccounting, setDataAccounting] = React.useState<TaskAccountingOP | null>(null)
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [createdByName, setCreatedByName] = React.useState<string>('')
  const [officeCodes, setOfficeCodes] = React.useState<SelectOption[]>([])
  const isFocused = useIsFocused()
  const [docList, setDocList] = React.useState<FileDocs[]>([])
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  
  const dataStatus = [
    {
      label: t('Accounting:ValidDoc'),
      id: Status.VALID
    },
    {
      label: t('Accounting:NotCompleteDoc'),
      id: Status.INCOMPLETE
    },
    {
      label: t('Accounting:NotUpdateDoc'),
      id: Status.NOTUPDATE
    },
    {
      label: t('Accounting:InvalidDoc'),
      id: Status.INVALID
    }
  ]

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Accounting'),
          navigate: () => {
            props.navigation.navigate('AccountingList')
          }
        },
        {
          title: t('Accounting:UpdateNote'),
          navigate: null
        }
      ])

      pipe(
        AuthService.userInfo,
        ZIO.map((userInfo) => {
          setCreatedByName(userInfo.isGaLogin ? userInfo.name : userInfo.email)
        }),
        ZIO.unsafeRun({})
      )

      pipe(
        GeneralService.getOfficeCashOut(),
        ZIO.map((codes) => {
          setOfficeCodes(
            codes.map((code) => ({
              value: code.code ?? '',
              label: i18n.language === 'vi' ? code.nameVi : code.nameEn
            }))
          )
        }),
        ZIO.unsafeRun({})
      )
      clearData()
      if (props.route.params.pvNumber) getCashoutAccounting({ paymentNumber: props.route.params.pvNumber }, 1, 0)
    }
  }, [isFocused])

  const getCashoutAccounting = (submittedData: HistoricTask.Filter, pageSize: number, pageNum: number) => {
    pipe(
      TaskService.getHistoryTasksAccountingCashout({
        pagination: {
          pageIndex: pageNum,
          pageSize: pageSize
        },
        order: 'desc',
        filter: submittedData
      }),
      ZIO.tap((res) => {
        setDataAccounting(res.items.length === 0 ? null : res.items[0])
        noteUpdateForm.setValue('notes', res.items.length === 0 ? '' : res.items[0].notes ?? '')
        pvNumSearchForm.setValue('pvNumber', res.items.length === 0 ? '' : res.items[0].paymentNumber ?? '')
        paymentNumSearchForm.setValue('paymentNumber', res.items.length === 0 ? '' : res.items[0].pvNumber ?? '')
        setStatusSelected(res.items.length === 0 ? Status.VALID : res.items[0].statusNote ?? Status.VALID)
        if(res.items.length > 0 && res.items[0].processInstanceId && res.items[0].createdDate) {
          getDocList(res.items[0].processInstanceId, PulseOpsFormat.dateStringtoFormat(res.items[0].createdDate.toString(), 'DD/MM/YYYY'))
        } 
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getLabel = (code: string) => {
    const filterLabel = officeCodes.find((item) => item.value === code)
    return filterLabel?.label ?? '-'
  }

  const pvNumberSearch = async () => {
    const isValidate = await pvNumSearchForm.trigger()
    if (isValidate) {
      const data = pvNumSearchForm.watch()
      const getSubmitData = (): HistoricTask.Filter => {
        return {
          paymentNumber: data.pvNumber || undefined
        }
      }
      const submitData: HistoricTask.Filter = getSubmitData()
      getCashoutAccounting(submitData, 1, 0)
    }
  }

  const paymentNumSearch = async () => {
    const isValidate = await paymentNumSearchForm.trigger()
    if (isValidate) {
      const data = paymentNumSearchForm.watch()
      const getSubmitData = (): HistoricTask.Filter => {
        return {
          pvNumber: data.paymentNumber || undefined
        }
      }
      const submitData: HistoricTask.Filter = getSubmitData()
      getCashoutAccounting(submitData, 1, 0)
    }
  }

  const cancelButton = () => {
    if(permissions.includes(Permission['ViewCreatenote']))
      props.navigation.navigate('AccountingCreateNoteScreen')
  }

  const clearData = () => {
    pvNumSearchForm.reset()
    paymentNumSearchForm.reset()
    noteUpdateForm.reset()
    setDataAccounting(null)
    setStatusSelected(Status.VALID)
    setDocList([])
  }

  const saveStatus = async () => {
    const isValidate = await noteUpdateForm.trigger()
    if (dataAccounting && isValidate) {
      const dataStatusChange: CashOutService.CashoutStatusInfo = {
        caseId: dataAccounting.caseId ?? '',
        notes: statusSelected !== Status.VALID ? noteUpdateForm.watch('notes') : '',
        status: statusSelected,
        createdBy: dataAccounting.createdBy ?? '',
        updatedBy: createdByName
      }
      pipe(
        CashOutService.updateCashoutStatus(dataStatusChange),
        ZIO.tap(() => {
          clearData()
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
    }
  }

  const getDocList = (processId: string, createDate: string) => {
    pipe(
      TaskDetailService.GetRelatedDoc(processId),
      ZIO.map((x) => ({
        azureDocuments: x.azureDocuments || [],
        archivedDocuments: x.archivedDocuments || []
      })),
      ZIO.map((res) => [
        ...res.azureDocuments.map((x) => {
          const docType = !!x.metaData.Doctype ? x.metaData.Doctype : x.metaData.doctype
          const docid = !!x.metaData.Docid ? x.metaData.Docid : x.metaData.docid
          const maindoc = !!x.metaData.Maindoc ? x.metaData.Maindoc : x.metaData.maindoc
          const subdoc = !!x.metaData.Subdoc ? x.metaData.Subdoc : x.metaData.subdoc
          const batchno = !!x.metaData.Batchno ? x.metaData.Batchno : x.metaData.batchno
          return {
            url: x.url,
            metaData: {
              doctype: docType,
              docid: docid,
              maindoc: maindoc,
              subdoc: subdoc,
              batchno: batchno
            },
            source: 'Azure'
          }
        }),
        ...res.archivedDocuments.map((x) => ({ ...x, source: 'Filenet' }))
      ]),
      ZIO.map((res) => {
        const dataCashout = res.filter((item) => 
          (item.metaData.docid && listDocId.includes(item.metaData.docid)) &&
          (item.metaData.batchno && item.metaData.batchno.split('-').length > 0 && 
          item.metaData.batchno.split('-')[0] === createDate)
        )
        setDocList(dataCashout)
        return ZIO.succeed(res)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const getLink = (url: string, source: string) => {
    source !== 'Azure'
      ? pipe(
          ZIO.zipPar(AuthService.token, AuthService.getLoginType),
          ZIO.flatMap(([token, loginType]) => {
            return ZIO.fromPromise(() =>
              fetch(url, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'X-Authen-Vendor': loginType
                }
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.map(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
              downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
          }),
          ErrorHandling.run()
        )
      : pipe(
          ZIO.zipPar(
            StorageBlobApi.getRemoteConfig(dataAccounting?.category ?? '', dataAccounting?.policyNumber ?? ''),
            AuthService.token,
            AuthService.getLoginType
          ),
          ZIO.flatMap(([storageConfig, token, loginType]) => {
            return pipe(
              ZIO.fromPromise(() => {
                const apiUrl = url.split('?')
                const storageUrl = apiUrl[0]
                const paramInfo = apiUrl[1]
                const paramList = paramInfo.split('&')
                const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
                const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
                return fetch(storageUrl, {
                  method: 'GET',
                  headers: {
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                    Container: containerName,
                    Token: storageConfig.sas,
                    Blob: blobName,
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              })
            )
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.tap(([res, blob]) => {
            const type = res.headers.get('content-type') || ''
            downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
            return ZIO.unit
          }),
          bindLoading,
          ErrorHandling.run()
        )
  }

  if(permissions.includes(Permission['ViewUpdatenote'])) {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={styles.container}>
              <View style={[styles.row, { paddingBottom: 30 }]}>
                <Text style={[styles.label, { fontSize: 18 }]}>{t('Accounting:Info')}</Text>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="pvNumber"
                    control={pvNumSearchForm.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: t('Accounting:PvNumber') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <View style={{ flexDirection: 'row' }}>
                        <Input
                          containerStyle={{ width: '90%' }}
                          title={t('Accounting:PvNumber')}
                          value={value ?? undefined}
                          maxLength={8}
                          inputType="number"
                          onChange={onChange}
                          errorMessage={error?.message}
                        />
                        <TouchableOpacity
                          style={{ marginBottom: error ? 23 : 8, alignSelf: 'flex-end' }}
                          onPress={() => pvNumberSearch()}
                        >
                          <assets.Search20Icon />
                        </TouchableOpacity>
                      </View>
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="paymentNumber"
                    control={paymentNumSearchForm.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: t('Accounting:PaymentNumber') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <View style={{ flexDirection: 'row' }}>
                        <Input
                          containerStyle={{ width: '90%' }}
                          title={t('Accounting:PaymentNumber')}
                          value={value ?? undefined}
                          maxLength={16}
                          inputType="text"
                          onChange={onChange}
                          errorMessage={error?.message}
                        />
                        <TouchableOpacity
                          style={{ marginBottom: error ? 23 : 8, alignSelf: 'flex-end' }}
                          onPress={() => paymentNumSearch()}
                        >
                          <assets.Search20Icon />
                        </TouchableOpacity>
                      </View>
                    )}
                  />
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('ServiceInquiry:PolicyNumber')}</Text>
                  <Text style={styles.text}>{dataAccounting ? dataAccounting.policyNumber : '-'}</Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('common:PolicyOwner')}</Text>
                  <Text style={styles.text}>{dataAccounting ? dataAccounting.poName ?? '-' : '-'}</Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:Amount')}</Text>
                  <Text style={styles.text}>
                    {dataAccounting ? PulseOpsFormat.thousandSepartor(Number(dataAccounting.amount ?? '0')) : '-'}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:Payee')}</Text>
                  <Text style={styles.text}>{dataAccounting ? dataAccounting.payee ?? '-' : '-'}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:PaymentService')}</Text>
                  <Text style={styles.text}>
                    {dataAccounting ? t(TransactionLabel(dataAccounting.transactionType)) : '-'}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:IdNumber')}</Text>
                  <Text style={styles.text}>{dataAccounting ? dataAccounting.idNumber ?? '-' : '-'}</Text>
                </View>
                <View style={styles.col2}>
                  <Text style={styles.label}>{t('Accounting:PaymentOffice')}</Text>
                  <Text style={styles.text}>{dataAccounting && dataAccounting.officeCode ? getLabel(dataAccounting.officeCode) : '-'}</Text>
                </View>
                <View style={styles.col3}>
                  <Text style={styles.label}>{t('Accounting:Bankcode')}</Text>
                  <Text style={styles.text}>{dataAccounting ? dataAccounting.bankCode ?? '-' : '-'}</Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:VerifiedDate')}</Text>
                  <Text style={styles.text}>
                    {dataAccounting
                        ? PulseOpsFormat.dateStringtoFormat(dataAccounting.paymentDate?.toString(), 'DD/MM/YYYY')
                        : '-'}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:PaymentDate')}</Text>
                  <Text style={styles.text}>
                    {dataAccounting
                      ? PulseOpsFormat.dateStringtoFormat(dataAccounting.paymentDate?.toString(), 'DD/MM/YYYY')
                      : '-'}
                  </Text>
                </View>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('Accounting:LastUpdateDate')}</Text>
                  <Text style={styles.text}>
                    {dataAccounting
                      ? PulseOpsFormat.dateStringtoFormat(dataAccounting.lastUpdateDate?.toString(), 'DD/MM/YYYY')
                      : '-'}
                  </Text>
                </View>
                <View style={styles.lastCol}>
                  <Text style={styles.label}>{t('Accounting:LastUpdateBy')}</Text>
                  <Text style={styles.text}>{dataAccounting ? dataAccounting.lastUpdatedBy ?? '-' : '-'}</Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>{t('Accounting:RelatedDoc')}</Text>
              </View>
              <View style={styles.relatedDocContainer}>
                <View style={{ paddingVertical: 20, flexDirection: 'row', flexWrap: 'wrap' }}>
                  {docList.map((doc, index) => {
                    return (
                      <View key={`doc_${index}`} style={{ width: '33%', paddingBottom: 20, paddingLeft: 20 }}>
                        <TouchableOpacity onPress={() => getLink(doc.url, doc.source)}>
                          <Text style={{ color: '#1EA5FC', textDecorationLine: 'underline', paddingLeft: 6 }}>
                            {showLabelDoc(doc.metaData.docid ?? '', i18n.language)}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )
                  })}
                </View>
              </View>

              <View style={styles.containerStatus}>
                {dataStatus.map((r, i) => (
                  <View style={{ paddingBottom: 20, flexDirection: 'row', height: 50 }}>
                    <View style={styles.colStatusNote}>
                      <RadioButton
                        key={i}
                        disable={!permissions.includes(Permission['EditableUpdatenote'])}
                        label={r.label}
                        value={r.id}
                        selected={r.id === statusSelected}
                        onChange={() => setStatusSelected(r.id)}
                      />
                    </View>
                    {r.id !== Status.VALID && r.id === statusSelected && (
                      <View style={{ width: '40%' }}>
                        <Controller
                          name="notes"
                          control={noteUpdateForm.control}
                          rules={{
                            required: {
                              value: true,
                              message: `${t('message:MS020001', { field: t('common:Note') })}`
                            }
                          }}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => 
                            permissions.includes(Permission['EditableUpdatenote']) ? (
                              <Input
                                containerStyle={{ width: '100%' }}
                                value={value}
                                placeholder={t('common:Note')}
                                maxLength={100}
                                inputType="text"
                                onChange={onChange}
                                errorMessage={error?.message ?? ''}
                              />
                            ) : (
                              <Text style={{ paddingTop: 5 }}>{value}</Text>
                            )}
                        />
                      </View>
                    )}
                  </View>
                ))}
              </View>

              <View style={[styles.row, { paddingTop: 15 }]}>
                <View style={{ paddingRight: 25 }}>
                  <TouchableOpacity style={styles.btnWhite} onPress={() => cancelButton()}>
                    <Text style={{ textAlign: 'center', color: '#ed1b2e' }}>{t('Accounting:Cancel')}</Text>
                  </TouchableOpacity>
                </View>

                <View style={{ paddingRight: 25 }}>
                  <TouchableOpacity style={[styles.btnRed, { width: 140 }]} onPress={() => clearData()}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('Accounting:OtherPolicy')}</Text>
                  </TouchableOpacity>
                </View>

                {permissions.includes(Permission['EditableUpdatenote']) && (
                  <TouchableOpacity 
                    style={[styles.btnRed, { width: 80 }]} 
                    onPress={() => saveStatus()}
                  >
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Save')}</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
  else {
    return (<SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}></SafeAreaView>)
  }
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16,
    paddingBottom: 15
  },

  btnRed: {
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnWhite: {
    width: 80,
    height: 40,
    backgroundColor: '#fff',
    border: '1px solid #ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  containerStatus: {
    paddingHorizontal: 16,
    paddingTop: 30
  },

  col: {
    width: '20%',
    marginBottom: 16,
    marginRight: '5%'
  },

  col2: {
    width: '11%',
    marginBottom: 16,
  },

  col3: {
    width: '14%',
    marginBottom: 16,
  },

  colStatusNote: {
    paddingTop: 6,
    width: '22%'
  },

  lastCol: {
    width: '50%',
    marginBottom: 16
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  },

  text: {
    marginTop: 10
  },

  relatedDocContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 20,
    paddingBottom: 16,
    flexDirection: 'column',
    minHeight: 40
  },

  container: {
    marginHorizontal: 30,
    marginVertical: 20,
    paddingBottom: 16,
    flexDirection: 'column'
  }
})
