import { Task, Throwable, ZIO } from '@mxt/zio'
import * as R from '@mxt/zio-react'
import { Ref } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import { DependencyList } from 'react'
import { RootNavigation } from './RootNavigation'

export namespace ErrorHandling {
  export const errorsRef = Ref.make<Throwable[]>([])

  export const clearErrors = pipe(errorsRef.set([]), ZIO.asVoid)

  export const catchAll = <A>(fallbackValue: A) =>
    ZIO.catchAll((err: Throwable) =>
      pipe(
        errorsRef.update((errors) => errors.concat(err)),
        ZIO.flatMap((errors) =>
          errors.length === 1 ? pipe(RootNavigation.navigate('ErrorScreen'), ZIO.orDie) : ZIO.unit
        ),
        ZIO.map(() => fallbackValue)
      )
    )

  export function runDidMount<S0>(initialState: S0): <A>(effect: Task<A>) => A extends S0 ? A : S0 | A
  export function runDidMount(): <A>(effect: Task<A>) => A | null
  export function runDidMount<S0>(initialState?: S0) {
    return <A>(effect: Task<A>) => pipe(effect, catchAll(initialState), R.runDidMount(initialState))
  }

  export function run<S0>(fallbackValue: S0): <A>(effect: Task<A>) => Promise<A extends S0 ? A : S0 | A>
  export function run(): <A>(effect: Task<A>) => Promise<A | null>
  export function run<S0>(fallbackValue: S0 | null = null) {
    return <A>(effect: Task<A>) => pipe(effect, catchAll(fallbackValue), ZIO.run({}))
  }

  export function runDidUpdate<S0>(
    initialState: S0,
    deps: DependencyList
  ): <A>(effect: Task<A>) => A extends S0 ? A : S0 | A
  export function runDidUpdate(deps: DependencyList): <A>(effect: Task<A>) => A | null
  export function runDidUpdate<S0>(...args: any[]) {
    const { initialState, deps } =
      args.length === 2 ? { initialState: args[0], deps: args[1] } : { initialState: null, deps: args[0] }
    return <A>(effect: Task<A>) =>
      pipe(effect, catchAll(initialState), (zio) => R.useEffectZ(zio, undefined, deps, initialState))
  }
}
