import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import {
  AppContext,
  assets,
  // ErrorHandling,
  ImgUploadMutiple2,
  InquiryComplaintService,
  Panel,
  SelectOption,
  SelectSearch,
  Title
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Column, Error } from '@pulseops/submission/common'
import { VeriInquiryComplaintData, VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { AdditionalDocumentForm, InquiryComplaintForm } from './InquiryComplaintForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'
import moment from 'moment'

export interface AdditionalDocumentProps {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  category?: string
  updatedPayloadData: VeriPayloadData
  disabled: boolean
}

export const AdditionalDocument: React.FC<AdditionalDocumentProps> = ({
  detail,
  form,
  category,
  updatedPayloadData,
  disabled
}) => {
  const { t, i18n } = useTranslation()
  const { control, setValue, watch } = form
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'additionalDocument'
  })
  const policyNum = detail.veriPayloadData.primaryPolicyNo
  const documentTypeList = detail.documentTypeList
  // const [transactionTypeList, setTransactionTypeList] = React.useState<InquiryComplaintService.DocTypeListWithMeta>([])
  const [isLoading, bindLoader] = useLoading(false)

  React.useEffect(() => {
    if (!!updatedPayloadData) {
      fields.length > 0 && remove()
      pipe(
        ZIO.succeed(documentTypeList),
        // ZIO.flatMap((categoryList) =>
        //   pipe(InquiryComplaintService.getDocTypeListIncludeMetaData(categoryList, detail.veriPayloadData.officeCode))
        // ),
        ZIO.map((responseData) => {
          // setTransactionTypeList(responseData)
          if (
            responseData &&
            responseData.length > 0 &&
            updatedPayloadData.additionalDocuments &&
            updatedPayloadData.additionalDocuments.length > 0
          ) {
            updatedPayloadData.additionalDocuments.forEach((additionItem, index) => {
              addNewAdditionalDocument()
              const transactionOptionList = convertArrayToOptionList(responseData)
              const transactionType = convertFromUnderToEmptyItem(
                transactionOptionList.find((x) => x.value === additionItem.transactionType)
              )
              const transactionItem = responseData.find((x) => x.docTypeCode === transactionType.value)
              const metaData = {
                type: transactionItem?.transactionType ?? '',
                doctype: transactionItem?.doctypeEn ?? '',
                class: transactionItem?.classFilenet ?? '',
                docid: transactionItem?.docID ?? '',
                maindoc: transactionItem?.mainDoc ?? '',
                subdoc: transactionItem?.subDoc ?? '',
                polnum: policyNum,
                batchno: transactionItem?.batchNo ?? ''
              }
              // const payLoadAdditionalDocuments: AdditionalDocumentForm = {
              //   transactionType: transactionType,
              //   attachment: additionItem.attachmentList,
              //   metaData: metaData,
              //   isAzuredUpdated: additionItem.attachmentList && additionItem.attachmentList.length > 0 ? true : false
              // }
              // setValue(`additionalDocument.${index}`, payLoadAdditionalDocuments)
              setValue(`additionalDocument.${index}.transactionType`, transactionType)
              setValue(`additionalDocument.${index}.attachment`, additionItem.attachmentList)
              setValue(`additionalDocument.${index}.metaData`, metaData)
              setValue(
                `additionalDocument.${index}.isAzuredUpdated`,
                additionItem.attachmentList && additionItem.attachmentList.length > 0 ? true : false
              )
            })
          } else {
            addNewAdditionalDocument()
          }
        }),
        ZIO.catchAll((error) => {
          console.log('get DocType List error')
          return ZIO.fail(error)
        }),
        bindLoader,
        // ErrorHandling.runDidUpdate([detail])
        ZIO.unsafeRun({})
      )
    }
    return () => {
      remove()
    }
  }, [updatedPayloadData])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const addNewAdditionalDocument = (payLoadAdditionalDocuments?: AdditionalDocumentForm) => {
    if (!!payLoadAdditionalDocuments) {
      append(payLoadAdditionalDocuments)
    } else {
      append({
        transactionType: null,
        attachment: [],
        metaData: undefined,
        isAzuredUpdated: false
      })
    }
  }

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const deleteAdditionalDocumentItem = (index: number) => {
    remove(index)
  }

  const convertFromUnderToEmptyItem = (item: SelectOption | undefined) => {
    return pipe(
      item,
      O.fromNullable,
      O.map((data) => ({
        label: data.label,
        value: data.value
      })),
      O.getOrElse(() => ({
        label: '',
        value: ''
      }))
    )
  }

  const convertArrayToOptionList = (tranList: InquiryComplaintService.DocTypeListWithMeta) => {
    const optionList = tranList.map((tranItem) => ({
      value: tranItem.docTypeCode,
      label: i18n.language === 'en' ? tranItem.doctypeEn : tranItem.doctypeVn
    }))
    return optionList
  }

  const onChangeTransactionTypeEvent = (value: SelectOption | null, index: number) => {
    const transactionItem = documentTypeList.find((x) => x.docTypeCode === value?.value)
    const currentDateFormat = moment().format('DD/MM/YYYY')
    const batchNo =
      !!detail.veriPayloadData.officeCode && detail.veriPayloadData.officeCode !== '-'
        ? currentDateFormat + '-' + transactionItem?.type + '-' + detail.veriPayloadData.officeCode
        : currentDateFormat + '-' + transactionItem?.type
    const metaData = {
      type: transactionItem?.transactionType ?? '',
      doctype: transactionItem?.doctypeEn ?? '',
      class: transactionItem?.classFilenet ?? '',
      docid: transactionItem?.docID ?? '',
      maindoc: transactionItem?.mainDoc ?? '',
      subdoc: transactionItem?.subDoc ?? '',
      polnum: policyNum,
      batchno: batchNo
    }
    setValue(`additionalDocument.${index}.metaData`, metaData)
  }

  return (
    <View>
      <Title title={t('IACVerification:ADDITIONALDOCUMENT')} />
      {fields &&
        fields.length > 0 &&
        fields.map((fieldItem, index) => (
          <Panel key={index}>
            <View style={additionalStyles.sectionRow}>
              <View style={additionalStyles.col_4}>
                <Controller
                  control={control}
                  name={`additionalDocument.${index}.transactionType`}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020009', { field: t('IACVerification:TransactionType') })}`
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      required={true}
                      label={t('IACVerification:TransactionType')}
                      options={convertArrayToOptionList(documentTypeList)}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      placeholder={showOptionLanguage()}
                      disabled={watch(`additionalDocument.${index}.isAzuredUpdated`) || disabled}
                      onChange={(val) => {
                        onChange(val)
                        onChangeTransactionTypeEvent(val, index)
                      }}
                      onBlur={onBlur}
                      value={value}
                      key={value?.value}
                      errorMessage={error?.message}
                      isShowFullText={true}
                    />
                  )}
                />
              </View>
            </View>
            <View style={[additionalStyles.sectionRow, additionalStyles.secondLine]}>
              <View style={additionalStyles.col_12}>
                <Text style={additionalStyles.field_title}>{t('requestInfo:Attachment')}</Text>
                <Controller
                  control={control}
                  name={`additionalDocument.${index}.attachment`}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: `${t('message:MS150004')}`
                  //   }
                  // }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Column>
                      <ImgUploadMutiple2
                        value={value}
                        onChange={onChange}
                        category={category}
                        policyNum={policyNum}
                        timeFormat={'DD/MM/YYYY HH:mm'}
                        disabled={disabled}
                      />
                      {error?.message && <Error message={!!value && value.length > 0 ? '' : error?.message} />}
                    </Column>
                  )}
                />
              </View>
            </View>
            {!disabled && (
              <View style={[additionalStyles.sectionRow, additionalStyles.secondLine]}>
                <View style={additionalStyles.col_4}>
                  <TouchableOpacity onPress={() => deleteAdditionalDocumentItem(index)}>
                    <View style={additionalStyles.btnContent}>
                      <assets.DeleteBin />
                      <Text style={[additionalStyles.btn_text, additionalStyles.field_title]}>
                        {t('common:Delete')}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </Panel>
        ))}
      {!disabled && (
        <View style={[additionalStyles.sectionRow, additionalStyles.secondLine]}>
          <View style={additionalStyles.col_4}>
            <TouchableOpacity onPress={() => addNewAdditionalDocument()}>
              <View style={additionalStyles.btnContent}>
                <assets.PlusCircleIcon />
                <Text style={[additionalStyles.btn_text, additionalStyles.field_title]}>{t('common:Add')}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  )
}
const additionalStyles = StyleSheet.create({
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  btnContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btn_text: {
    marginLeft: 10,
    marginTop: 4
  }
})
