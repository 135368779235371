export namespace DAagentEvaluationData {
  export interface DAagentEvaluationDataRule {
    fullName: string | null | undefined
    birthdayStr: string | null | undefined
    identifyPassport: string | null | undefined
  }
  export interface QsCore {
    sumAge: number
    Educations: any
    workExperiences: string
    hasAListOfPotentialCustomers: string
    total: number
  }
  export interface Qualified {
    nameSystem: string
    nameApplication: string
    DateOfbirthSys: string
    DateOfbirthApp: string
    MOFnumber: string
    MOFdate: string
    ExamCode: string
  }
  export interface Candidate {
    sumAge: number
  }
}
