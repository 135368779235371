import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ClientName, Error } from './CepCommonModels'

export const CustomerInfoC = t.type({
  data: Maybe(
    t.type({
      contact: Maybe(
        t.type({
          companyPhone: Maybe(t.string),
          vip: Maybe(t.string),
          country: Maybe(t.string),
          city: Maybe(t.string),
          district: Maybe(t.string),
          ward: Maybe(t.string),
          address: Maybe(t.string),
          ape: Maybe(t.string),
          longName: Maybe(t.string),
          point: Maybe(t.number),
          expiredPoint: Maybe(t.number),
          usedPoint: Maybe(t.number),
          pruOnlineUserName: Maybe(t.string),
          accountStatus: Maybe(t.string),
          expiredPointInNextMonth: Maybe(t.number),
          expiredPointIn03Month: Maybe(t.number),
          expiredPointIn06Month: Maybe(t.number),
          contactChannels: Maybe(
            t.array(
              t.type({
                channel: Maybe(t.string),
                contactValue: Maybe(t.string),
                indicator: Maybe(t.string),
                zaloId: Maybe(t.string),
                zaloName: Maybe(t.string)
              })
            )
          ),
          riskCessDate: Maybe(t.string),
          enrolledDate: Maybe(t.string),
          otpConsent: Maybe(t.string),
          signature: Maybe(t.string)
        })
      ),
      policyOwners: Maybe(
        t.array(
          t.type({
            clientNumber: Maybe(t.string),
            role: Maybe(t.string),
            clientName: Maybe(ClientName),
            nationalID: Maybe(t.string),
            gender: Maybe(t.string),
            dateOfBirth: Maybe(t.string),
            ageLatBirth: Maybe(t.number),
            mortalityClass: Maybe(t.string),
            poOwner: Maybe(t.boolean),
            occupationCode: Maybe(t.string),
            occupationName: Maybe(t.string),
            currentAge: Maybe(t.number),
            lifeNo: Maybe(t.string),
            usedToBe: Maybe(t.string)
          })
        )
      ),
      fao: Maybe(
        t.type({
          clientNumber: Maybe(t.string),
          clientName: Maybe(t.string),
          title: Maybe(t.string),
          nationalId: Maybe(t.string),
          gender: Maybe(t.string),
          dateOfBirth: Maybe(t.string)
        })
      )
    })
  ),
  error: Error
})

export type CustomerInfoC = t.TypeOf<typeof CustomerInfoC>

export const DuplicationPhoneC = t.type({
  start: t.number,
  size: t.number,
  data: t.type({
    phoneNumber: t.string,
    clientNumber: t.string
  })
})

export type DuplicationPhoneC = t.TypeOf<typeof DuplicationPhoneC>

export const DuplicationPhoneItemC = t.type({
  phoneNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  clientSurName: Maybe(t.string),
  clientGivenName: Maybe(t.string),
  agentCode: Maybe(t.string),
  agentSurname: Maybe(t.string),
  agentGivenName: Maybe(t.string)
})

export type DuplicationPhoneItemC = t.TypeOf<typeof DuplicationPhoneItemC>

export const DuplicationPhoneResponseC = t.type({
  // start: t.number,
  // size: t.number,
  // total: t.number,
  data: t.array(DuplicationPhoneItemC),
  error: Error
})
