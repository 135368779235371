import moment from 'moment'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface AgentOnboardNew {
  tag: TransactionType.AGENT_ONBOARD_NEW | TransactionType.AGENT_ONBOARD_REINS
  eREF: string | null | undefined
  subChanel: string | null | undefined
  salesType: string | null | undefined
  ref: string | null | undefined
  createdDate: string | null | undefined
  agCAT: string | null | undefined
  agCategory: string | null | undefined
  agentCode: string | null | undefined
  agentName: string | null | undefined
  appointedDate: string | null | undefined
  terminateDate: string | null | undefined
  agentType: string | null | undefined
  title: string | null | undefined
  unitDescription: string | null | undefined
  branchDescription: string | null | undefined
  officeDescription: string | null | undefined
  subzoneDescription: string | null | undefined
  zoneDescription: string | null | undefined
  debt: string | null | undefined
  ocr: string | null | undefined
  saleZone: string | null | undefined
  asessmentDate: string | null | undefined
  assessByDesc: string | null | undefined
  reAssess: string | null | undefined
  reAssessDate: string | null | undefined
  role: string | null | undefined
  status: boolean | null | undefined
  leaderCode: string | null | undefined
  leaderCodeDescription: string | null | undefined
  regionCode: string | null | undefined
  regionDescription: string | null | undefined
  oldUnit: string | null | undefined
  terdate: string | null | undefined
  idNo: string | null | undefined
  oldIdNo: string | null | undefined
  idDate: Date | null | undefined
  idPlace: string | null | undefined
  idExpiredDate: Date | null | undefined
  dob: Date | null | undefined
  placeOfBirth: string | null | undefined
  nationality: string | null | undefined
  lastName: string | null | undefined
  firstName: string | null | undefined
  genderCode: string | null | undefined
  ethNic: string | null | undefined
  agentCodeRefer: string | null | undefined
  agentNameRefer: string | null | undefined
  phoneNumber: string | null | undefined
  companyPhone: string | null | undefined
  homePhone: string | null | undefined
  email: string | null | undefined
  zalo: string | null | undefined
  address: string | null | undefined
  district: string | null | undefined
  province: string | null | undefined
  ward: string | null | undefined
  locationCode: string | null | undefined
  AllocatedlocationCode: string | null | undefined
  Allocatedaddress: string | null | undefined
  Allocateddistrict: string | null | undefined
  Allocatedprovince: string | null | undefined
  Allocatedward: string | null | undefined
  contactlocationCode: string | null | undefined
  contactaddress: string | null | undefined
  contactdistrict: string | null | undefined
  contactprovince: string | null | undefined
  contactward: string | null | undefined
  bankName: string | null | undefined
  bankBranch: string | null | undefined
  bankAccount: string | null | undefined
  bankCode: string | null | undefined
  otherBank: string | null | undefined
  otherBankBranch: string | null | undefined
  taxCode: string | null | undefined
  educationCode: string | null | undefined
  specialization: string | null | undefined
  income: string | null | undefined
  numberOfChildren: string | null | undefined
  marriedCode: string | null | undefined
  officeCode: string | null | undefined
  occupationCode: string | null | undefined
  officeCodes: string | null | undefined
  hasAListOfPotentialCustomers: string | null | undefined
  processInstanceId: string | null | undefined
  structure: any | null | undefined
  source: string
  transactionType: string | null | undefined
  newUnit: string | null | undefined
  businessKey: string | null | undefined
  id: string | null | undefined
  taskId: string | null | undefined
  galleries: string | null | undefined
  clientId: string | null | undefined
  newLeaderName: string | null | undefined
  newLeaderCode: string | null | undefined
  leaderName: string | null | undefined
  oldLeaderCode: string | null | undefined
  oldLeaderName: string | null | undefined
  access_token: string | null | undefined
  action: string | null | undefined
  additionalProperties: any | null | undefined
  authenOption: string | null | undefined
  authenRequestForm: string | null | undefined
  correlationId: string | null | undefined
  createdBy: string | null | undefined
  documents: string | null | undefined
  errorMsg: string | null | undefined
  exchangeId: string | null | undefined
  functionUI: string | null | undefined
  idNumber: string | null | undefined
  isCCE: string | null | undefined
  lasStatus: string | null | undefined
  location: string | null | undefined
  msgId: string | null | undefined
  otpTransaction: any | null | undefined
  primaryPolicyNo: string | null | undefined
  refresh_token: string | null | undefined
  systemName: string | null | undefined
  timestamp: string | null | undefined
  transType: string | null | undefined
  agentCodes: string | null | undefined
  suspendReason: string | null | undefined
  unsuspendReason: string | null | undefined
}

export const AgentOnboardNew = (detail: api.AgentOnboardNew & api.TaskDetailBase): AgentOnboardNew => {
  const payload = detail.payload.body
  const CreatedDate = detail.payload.createdDate
  const structure = detail
  return {
    tag: TransactionType.AGENT_ONBOARD_NEW || TransactionType.AGENT_ONBOARD_REINS,
    eREF: payload.eREF,
    subChanel: payload.subChanel,
    salesType: payload.salesType,
    ref: payload.ref,
    createdDate: CreatedDate,
    agCAT: payload.agCAT,
    agCategory: payload.agent?.agCategory,
    agentCode: payload.agent?.agentCode,
    agentName: payload.agent?.agentName,
    appointedDate: payload.agent?.appointedDate,
    terminateDate: payload.agent?.terminateDate,
    agentType: payload.agent?.agentType,
    title: payload.agent?.title,
    unitDescription: payload.agent?.unitDescription,
    branchDescription: payload.agent?.branchDescription,
    officeDescription: payload.agent?.officeDescription,
    subzoneDescription: payload.agent?.subzoneDescription,
    zoneDescription: payload.agent?.zoneDescription,
    debt: payload.agent?.debt,
    ocr: payload.agent?.ocr,
    saleZone: payload.agent?.saleZone,
    asessmentDate: payload.assessment?.assessmentDate,
    assessByDesc: payload.assessment?.assessByDesc,
    reAssess: payload.assessment?.reAssess,
    reAssessDate: payload.assessment?.reAssessDate,
    role: payload.assessment?.role,
    status: payload.assessment?.status,
    leaderCode: payload.group?.leaderCode,
    leaderCodeDescription: payload.group?.leaderCodeDescription,
    leaderName: payload.group?.leaderName,
    newLeaderName: payload.group?.newLeaderName,
    newLeaderCode: payload.group?.newLeaderCode,
    oldLeaderCode: payload.group?.oldLeaderCode,
    oldLeaderName: payload.group?.oldLeaderName,
    regionCode: payload.agent?.regionCode,
    regionDescription: payload.agent?.regionDescription,
    oldUnit: payload.oldUnit,
    terdate: payload.terDate,
    idNo: payload.candidate?.idNo,
    oldIdNo: payload.candidate?.oldIdNo,
    idDate: payload.candidate?.idDate ? moment(payload.candidate?.idDate, 'DD/MM/yyyy').toDate() : null,
    idPlace: payload.candidate?.idPlace,
    idExpiredDate: payload.candidate?.idExpiredDate
      ? moment(payload.candidate.idExpiredDate, 'DD/MM/YYYY').toDate()
      : null,
    dob: payload.candidate?.dob ? moment(payload.candidate?.dob, 'DD/MM/yyyy').toDate() : null,
    placeOfBirth: payload.candidate?.placeOfBirth,
    nationality: payload.candidate?.nationality,
    lastName: payload.candidate?.lastName,
    firstName: payload.candidate?.firstName,
    genderCode: payload.agent?.genderCode,
    ethNic: payload.agent?.ethnic,
    agentCodeRefer: payload.agent?.agentCodeRefer,
    agentNameRefer: payload.agent?.agentNameRefer,
    phoneNumber: payload.agent?.phoneNumber,
    companyPhone: payload.agent?.companyPhone,
    homePhone: payload.agent?.homePhone,
    email: payload.agent?.email,
    zalo: payload.agent?.zalo,
    address: payload.residential?.address,
    district: payload.residential?.district,
    province: payload.residential?.province,
    ward: payload.residential?.ward,
    locationCode: payload.agent?.locationCode,
    AllocatedlocationCode: payload.allocate?.locationCode,
    Allocatedaddress: payload.allocate?.address,
    Allocateddistrict: payload.allocate?.district,
    Allocatedprovince: payload.allocate?.province,
    Allocatedward: payload.allocate?.ward,
    contactlocationCode: payload.contact?.locationCode,
    contactaddress: payload.contact?.address,
    contactdistrict: payload.contact?.district,
    contactprovince: payload.contact?.province,
    contactward: payload.contact?.ward,
    bankName: payload.bank?.bankName,
    bankBranch: payload.bank?.bankBranch,
    bankAccount: payload.bank?.bankAccount,
    bankCode: payload.bank?.bankCode,
    otherBank: payload.bank?.otherBank,
    otherBankBranch: payload.bank?.otherBankBranch,
    taxCode: payload.bank?.taxCode,
    educationCode: payload.agent?.educationCode,
    specialization: payload.agent?.specialization,
    income: payload.agent?.income,
    numberOfChildren: payload.agent?.numberOfChildren,
    marriedCode: payload.agent?.marriedCode,
    officeCode: payload.officeCode,
    occupationCode: payload.agent?.occupationCode,
    officeCodes: detail.payload.officeCode,
    hasAListOfPotentialCustomers: payload.agent?.hasAListOfPotentialCustomers,
    structure: structure,
    processInstanceId: detail.processInstanceId,
    source: detail.payload.source,
    transactionType: detail.transactionType,
    newUnit: payload.newUnit,
    businessKey: detail.businessKey,
    id: detail.id,
    taskId: detail.taskId,
    galleries: detail.galleries,
    clientId: detail.payload.clientId,
    access_token: detail.payload.access_token,
    action: detail.payload.action,
    additionalProperties: detail.payload.additionalProperties,
    authenOption: detail.payload.authenOption,
    authenRequestForm: detail.payload.authenRequestForm,
    correlationId: detail.payload.correlationId,
    createdBy: detail.payload.createdBy,
    documents: detail.payload.documents,
    errorMsg: detail.payload.errorMsg,
    exchangeId: detail.payload.exchangeId,
    functionUI: detail.payload.functionUI,
    idNumber: detail.payload.idNumber,
    isCCE: detail.payload.isCCE,
    lasStatus: detail.payload.lasStatus,
    location: detail.payload.location,
    msgId: detail.payload.msgId,
    otpTransaction: detail.payload.otpTransaction,
    primaryPolicyNo: detail.payload.primaryPolicyNo,
    refresh_token: detail.payload.refresh_token,
    systemName: detail.payload.systemName,
    timestamp: detail.payload.timestamp,
    transType: detail.payload.transType,
    agentCodes: detail.payload.agentCode,
    suspendReason: detail.suspendReason,
    unsuspendReason: detail.unsuspendReason
  }
}
