import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import {
  TaskDetail,
  Panel,
  formatNumberWithComma,
  Checkbox,
  PayoutPopup,
  PayoutChecker,
  PulseOpsFormat,
  TransactionType,
  TaskDetailApi,
  getPayeeNotPOData
} from '@pulseops/common'
import { Title, InfoViewList } from '../common'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { PayoutMethod } from './common'
import _ from 'lodash'
// import { PaymentData } from 'libs/common/src/service/model/task-detail-api'
interface Props {
  detail: TaskDetail.CashOutMaturity
}

export const Maturity = ({ detail }: Props) => {
  const { t } = useTranslation('requestInfo')

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.totalAmount),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  const formatPODate = (dateStr: string) => {
    if (!dateStr) {
      return '-'
    }

    if (detail.tag === TransactionType.MATURITY) {
      return dateStr
    }

    return format(new Date(dateStr), 'dd/MM/yyyy')
  }

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('MATURITY_INFORMATION')} />
      <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('TotalMaturityBenefit'),
              value: formatNumberWithComma(detail.totalMaturityBenefits),
              suffix: 'VND'
            },
            {
              label: t('TotalPrucashBenefit'),
              value: formatNumberWithComma(detail.totalCashBenefit),
              suffix: 'VND'
            },
            {
              label: t('SuspenseAmount'),
              value: formatNumberWithComma(detail.suspendAmount),
              suffix: 'VND'
            },

            {
              label: t('PaidMaturityAdvance'),
              value: formatNumberWithComma(detail.paidMaturity),
              suffix: 'VND'
            },
            {
              label: t('PrincipalAPL-OPL'),
              value: formatNumberWithComma(detail.principalAplOpl),
              suffix: 'VND'
            },
            {
              label: t('InterestAPL-OPL'),
              value: formatNumberWithComma(detail.interestAplOpl),
              suffix: 'VND'
            },

            {
              label: t('NotPaidPremium'),
              value: formatNumberWithComma(detail.notPaidPremium),
              suffix: 'VND'
            },
            {
              label: t('DeductOfClaims'),
              value: formatNumberWithComma(detail.deductOfClaims),
              suffix: 'VND'
            },
            {
              label: t('NetTotalMaturityBenefits'),
              value: formatNumberWithComma(detail.netTotalMaturityBenefit),
              suffix: 'VND',
              isHighlight: true
            }
          ]}
        />
      </Panel>

      <View style={{ marginBottom: 40, marginTop: 20 }}>
        <Checkbox
          title={t('POLICY_OWNER_PASSED_AWAY')}
          textStyle={styles.sectionInfoHeaderTitle}
          value={detail.inheritanceFlag}
          disabled
        />
        {detail.inheritanceFlag && (
          <>
            <Title title={t('INHERITANCE_INFOMATION')} />
            <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
              <InfoViewList
                dataSource={[
                  {
                    label: t('PODeathDate'),
                    value: formatPODate(detail.deathDate)
                  }
                ]}
              />
            </Panel>
          </>
        )}
      </View>
      <PayoutMethod
        payoutMethodData={payoutData}
        payeeData={viewPayeeData}
        panelContainerStyle={{ backgroundColor: '#FAFAFA' }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfoHeaderTitle: {
    color: '#000',
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  }
})
