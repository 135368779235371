export enum Code {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  BB = 'BB',
  BN = 'BN',
  BR = 'BR',
  BU = 'BU',
  BW = 'BW',
  CH = 'CH',
  DA = 'DA',
  DS = 'DS',
  DT = 'DT',
  EX = 'EX',
  FA = 'FA',
  FC = 'FC',
  FH = 'FH',
  FR = 'FR',
  FW = 'FW',
  GF = 'GF',
  GM = 'GM',
  HS = 'HS',
  HU = 'HU',
  JL = 'JL',
  LA = 'LA',
  MO = 'MO',
  NE = 'NE',
  O = 'O',
  OT = 'OT',
  PA = 'PA',
  PO = 'PO',
  SB = 'SB',
  SO = 'SO',
  SP = 'SP',
  ST = 'ST',
  SW = 'SW',
  TK = 'TK',
  TR = 'TR',
  TT = 'TT',
  U1 = 'U1',
  U2 = 'U2',
  U3 = 'U3',
  U4 = 'U4',
  WB = 'WB',
  WI = 'WI',
  N = 'N'
}

export const EmailIndicator: Array<{
  value: string
  labelEN: string
  labelVI: string
}> = [
  {
    value: '1',
    labelVI: 'Thông tin hợp đồng',
    labelEN: 'Policy info'
  },
  {
    value: '3',
    labelVI: 'Thông tin hợp đồng & Prudential',
    labelEN: 'Policy & Prudential info'
  },
  {
    value: '4',
    labelVI: 'Từ chối nhận email',
    labelEN: 'Reject'
  }
]

export const SMSIndicator: Array<{
  value: string
  labelEN: string
  labelVI: string
}> = [
  {
    value: '1',
    labelVI: 'Thông tin hợp đồng',
    labelEN: 'Policy info'
  },
  {
    value: '3',
    labelVI: 'Thông tin hợp đồng & Prudential',
    labelEN: 'Policy & Prudential info'
  },
  {
    value: '4',
    labelVI: 'Từ chối nhận SMS',
    labelEN: 'Reject'
  }
]

export const IndicatorRelations: Array<{
  code: string
  desEn: string
  desVi: string
}> = [
  { code: Code.PA, desEn: 'Father/mother', desVi: 'Cha/mẹ' },
  { code: Code.SP, desEn: 'Wife/Husband', desVi: 'Vợ/Chồng' },
  { code: Code.CH, desEn: 'Children', desVi: 'Con' }
]
