import { ZIO } from '@mxt/zio'
import { AmlHistory, Employee, Form, FormService, Input } from '@pulseops/business/core'
import { Format } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

export const EmployeeDetailView = ({ detail }: { detail: Employee }) => {
  const { t } = useTranslation('business')

  const {
    getCountryOptions,
    getProvinceOptions,
    getGenderName,
    getCountryName,
    getMaritalStatusName,
    getYesNoName,
    getProvinceName,
    getDistrictOptionsFull,
    getDistrictName,
    getWardOptionsFull,
    getWardName
  } = FormService

  const [viewData, setViewData] = React.useState({
    gender: '',
    maritalStatus: '',
    nationality: '',
    otherNationality: '',

    permanentCountry: '',
    permanentCity: '',
    permanentDistrict: '',
    permanentWard: '',

    residentCountry: '',
    residentCity: '',
    residentDistrict: '',
    residentWard: '',

    overseaCountry: '',
    overseaCity: '',
    overseaDistrict: '',
    overseaWard: '',

    overFcl: ''
  })

  const updateViewData = pipe(
    ZIO.zipPar(getCountryOptions, getProvinceOptions, getDistrictOptionsFull, getWardOptionsFull),
    ZIO.tap(([countryOptions, provinceOptions, districtOptionsFull, wardOptionsFull]) => {
      setViewData({
        gender: getGenderName(detail.gender),
        maritalStatus: getMaritalStatusName(detail.maritalStatus),
        nationality: getCountryName(detail.nationality, countryOptions),
        otherNationality: getCountryName(detail.otherNationality, countryOptions),

        permanentCountry: getCountryName(detail.permanentAddressCountry, countryOptions),
        permanentCity: getProvinceName(detail.permanentCity, provinceOptions),
        permanentDistrict: getDistrictName(detail.permanentDistrict, detail.permanentCity, districtOptionsFull),
        permanentWard: getWardName(detail.permanentWard, wardOptionsFull),

        residentCountry: getCountryName(detail.residentialAddressCountry, countryOptions),
        residentCity: getProvinceName(detail.residentialCity, provinceOptions),
        residentDistrict: getDistrictName(detail.residentialDistrict, detail.residentialCity, districtOptionsFull),
        residentWard: getWardName(detail.residentialWard, wardOptionsFull),

        overseaCountry: getCountryName(detail.overseasAddressCountry, countryOptions),
        overseaCity: getProvinceName(detail.overseaCity, provinceOptions),
        overseaDistrict: getDistrictName(detail.overseaDistrict, detail.overseaCity, districtOptionsFull),
        overseaWard: getWardName(detail.overseaWard, wardOptionsFull),

        overFcl: getYesNoName(detail.overFcl)
      })
      return ZIO.unit
    })
  )

  React.useEffect(() => {
    pipe(updateViewData, ZIO.unsafeRun({}))
  }, [detail])

  return (
    <>
      <Form.Group>
        <Form.GroupTitle>{t('employee_info_detail')}</Form.GroupTitle>
        <Form.Row>
          <Form.Col>
            <Input label={t('full_name_employee')} value={detail.employeeName} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('employee_code')} value={detail.employeeNo} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('id_card_no')} value={detail.employeeId} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('issue_date')} value={Format.date(detail.dateOfIssue)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('issue_place')} value={detail.placeOfIssue} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('dob')} value={Format.date(detail.dateOfBirth)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('gender')} value={viewData.gender} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('marital_status')} value={viewData.maritalStatus} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('phone_number')} value={detail.employeePhoneNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('email')} value={detail.email} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('nationality')} value={viewData.nationality} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('other_nationality')} value={viewData.otherNationality || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('visa')} value={detail.visa || '-'} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('perm_address')} value={detail.permanentAddress} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('perm_address_country')} value={viewData.permanentCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.permanentCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.permanentDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.permanentWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('curr_address')} value={detail.residentialAddress} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('curr_address_country')} value={viewData.residentCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.residentCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.residentDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.residentWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('oversea_address')} value={detail.overseasAddress || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('oversea_address_country')} value={viewData.overseaCountry || '-'} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.overseaCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.overseaDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.overseaWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('occupation')} value={detail.occupation} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('occupation_code')} value={detail.occupationCode} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('benefit_class')} value={detail.benefitClass} readonly />
          </Form.Col>
          <Form.Col></Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('bank_name')} value={detail.bankName || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('branch_name')} value={detail.bankBranch || '-'} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('account_no')} value={detail.bankAccountNo || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('account_holder')} value={detail.accountName || '-'} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('group_policy_no')} value={detail.masterContractNo} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('policy_year')} value={detail.policyYear.toString()} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('cert_no')} value={detail.lifeCertificateNo || ''} readonly />
          </Form.Col>
          <Form.Col>
            <Input
              label={t('policy_if_date_la')}
              value={detail.lifeCertificateEffectiveDate ? Format.date(detail.lifeCertificateEffectiveDate) : ''}
              readonly
            />
          </Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('basic_plan')} value={detail.basicPlan || ''} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('basic_sa')} value={Format.currencyForBusiness(detail.basicSa)} readonly />
          </Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('add_rider_sa')} value={Format.currencyForBusiness(detail.addRiderSa)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('hc_plan')} value={detail.healthcarePlan || ''} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('over_fcl')} value={viewData.overFcl} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('over_fcl_amount')} value={Format.currencyForBusiness(detail.overFclAmount)} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('exclusion')} value={detail.exclusion || '-'} readonly />
          </Form.Col>
          <Form.Col></Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col></Form.Col>
          <Form.Col></Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input
              label={t('total_standard_premium')}
              value={Format.currencyForBusiness(detail.standardPremium)}
              readonly
            />
          </Form.Col>
          <Form.Col>
            <Input
              label={t('total_loading_premium')}
              value={detail.loadingPremium ? Format.currencyForBusiness(detail.loadingPremium) : '-'}
              readonly
            />
          </Form.Col>
          <Form.Col>
            <Input label={t('total_premium')} value={Format.currencyForBusiness(detail.totalPremium)} readonly />
          </Form.Col>
        </Form.Row>
        {detail.status === 'inactive' && (
          <Form.Row>
            <Form.Col>
              <Input
                label={t('member_removal_effective_date')}
                value={detail.memberRemovalEffectiveDate ? Format.date(detail.memberRemovalEffectiveDate) : '-'}
                readonly
              />
            </Form.Col>
            <Form.Col>
              <Input
                label={t('refunded_premium')}
                value={detail.refundedPremium ? Format.currencyForBusiness(detail.refundedPremium) : '-'}
                readonly
              />
            </Form.Col>
            <Form.Col></Form.Col>
          </Form.Row>
        )}
      </Form.Group>

      <AmlHistory history={detail.historyAmlResult} />
    </>
  )
}
