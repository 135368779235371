import { useLoading } from '@mxt/zio-react'
import { AppContext } from './AppContext'
import { useContext, useEffect } from 'react'
export const useGlobalLoading = (initialLoading?: boolean) => {
  const [isLoading, bindLoader] = useLoading(initialLoading)
  const { showGlobalLoading } = useContext(AppContext.AppContextInstance)

  useEffect(() => {
    showGlobalLoading(isLoading)

    return () => {
      showGlobalLoading(false)
    }
  }, [isLoading])

  return { isLoading, bindLoader }
}
