import React from 'react'
import { DatePickerProps } from './DatePicker'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StyleSheet, Text, View } from 'react-native'
import { createTheme, ThemeProvider } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { assets } from '../../assets'
import i18next from 'i18next'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

export const CustomedDateTimePicker = (props: DatePickerProps) => {
  // const [selectedDate, handleDateChange] = React.useState(new Date());
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.required && (
          <Text style={styles.label}>
            {props.label}
            <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>{' '}
          </Text>
        )}
        {props.alwaysShow && <Text style={styles.label}>{props.label}</Text>}
        <View style={{ flex: 1, minWidth: props?.minWidth ?? 'auto' }}>
          <KeyboardDateTimePicker
            InputProps={{ disableUnderline: true }}
            autoOk
            label=""
            variant="inline"
            ampm={false}
            disabled={props.disabled}
            value={props.value}
            keyboardIcon={<assets.InputCalendar />}
            onKeyPress={(e) => {
              if(props.disableKeyInput)
                e.preventDefault()                                     
            }}
            onChange={(date) => {
              if (props.onChange) {
                props.onChange(date)
              }
            }}
            onBlur={props.onBlur}
            minDate={props.minDate}
            maxDate={props.maxDate}
            minDateMessage={''}
            maxDateMessage={props.errorMessage ? '' : props.maxDateMessage}
            invalidDateMessage={props.errorMessage ? '' : props.invalidDateMessage || i18next.t('form:error_invalid')}
            format="dd/MM/yyyy HH:mm"
            placeholder="dd/mm/yyyy HH:mm"
          />
          {props.hideUnderline ? <View style={{ height: 1, backgroundColor: '#D3DCE6' }} /> : null}
          {props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
        </View>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 8
  },

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  }
})
