import { ZIO, Throwable } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import _ from 'lodash'
import { POApi } from '@pulseops/common'

export namespace DAdsAdminService {
  export const GetDsAminList = t.type({
    totalPage: t.number,
    totalElement: t.number,
    currentPage: t.number,
    pageSize: t.number,
    elements: Nullable(
      t.array(
        t.type({
          id: Maybe(t.string),
          locationCode: Maybe(t.string),
          officeCode: Maybe(t.string),
          leaderOfficeCode: Maybe(t.string),
          regionCode: Maybe(t.string),
          regionType: Maybe(t.string),
          note: Maybe(t.string),
          leaderCode: Maybe(t.string),
          version: Maybe(t.string)
        })
      )
    )
  })

  export const ResponseDSAdmin = t.type({
    body: Maybe(t.boolean),
    responseStatus: t.type({
      clientError: Nullable(t.string),
      code: t.number,
      message: t.string,
      errors: Maybe(
        t.array(
          Maybe(
            t.type({
              code: t.string,
              message: t.string,
              type: t.string
            })
          )
        )
      )
    })
  })

  export type InputBase = {
    pageNum: number
    pageSize: number
  }

  export type InputOfficeRule = InputBase & {
    locationCode?: string | null
    officeCode?: string | null
    leaderOfficeCode?: string | null
    note?: string | null
  }

  export type InputGADRule4 = InputBase & {
    locationCode?: string | null
    officeCode?: string | null
    leaderCode?: string | null
    note?: string | null
  }

  export type InputLocationRule3 = InputBase & {
    locationCode?: string | null
    officeCode?: string | null
    note?: string | null
  }

  export type InputLocationRegion = InputBase & {
    locationCode?: string | null
    officeCode?: string | null
    regionCode?: string | null
    regionType?: string | null
    note?: string | null
  }

  export type GetDsAminList = t.TypeOf<typeof GetDsAminList>
  export type ResponseDSAdmin = t.TypeOf<typeof ResponseDSAdmin>

  export const getOfficeRuleList = (params: InputOfficeRule) =>
    pipe(
      POApi.getConfig({ params })('distribution-agents-service/office-rule/get-by')(GetDsAminList),
      ZIO.map((res) => res)
    )

  export const deleteOfficeRule = (id: string | null | undefined) =>
    pipe(
      POApi.post('distribution-agents-service/office-rule/delete')(ResponseDSAdmin)({ id }),
      ZIO.map((res) => res)
    )

  export const saveOfficeRule = (
    id: string | null | undefined,
    locationCode: string | null,
    officeCode: string,
    leaderOfficeCode: string,
    note: string | null
  ) =>
    pipe(
      POApi.post('distribution-agents-service/office-rule/save')(ResponseDSAdmin)({
        id,
        locationCode,
        officeCode,
        leaderOfficeCode,
        note
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      }),
      ZIO.flatMap((res) => {
        return ZIO.succeed(res)
      })
    )

  export const getGADRuleList = (params: InputGADRule4) =>
    pipe(
      POApi.getConfig({ params })('distribution-agents-service/gad-rule4/get-by')(GetDsAminList),
      ZIO.map((res) => res)
    )

  export const deleteGADRule4 = (id: string | null | undefined) =>
    pipe(
      POApi.post('distribution-agents-service/gad-rule4/delete')(ResponseDSAdmin)({ id }),
      ZIO.map((res) => res)
    )

  export const saveGADRule4 = (
    id: string | null | undefined,
    locationCode: string | null,
    officeCode: string,
    leaderCode: string,
    note: string | null
  ) =>
    pipe(
      POApi.post('distribution-agents-service/gad-rule4/save')(ResponseDSAdmin)({
        id,
        locationCode,
        officeCode,
        leaderCode,
        note
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      }),
      ZIO.flatMap((res) => {
        return ZIO.succeed(res)
      })
    )

  export const getLocationRuleList = (params: InputLocationRule3) =>
    pipe(
      POApi.getConfig({ params })('distribution-agents-service/location-rule3/get-by')(GetDsAminList),
      ZIO.map((res) => res)
    )

  export const deleteLocationRule3 = (id: string | null | undefined) =>
    pipe(
      POApi.post('distribution-agents-service/location-rule3/delete')(ResponseDSAdmin)({ id }),
      ZIO.map((res) => res)
    )

  export const saveLocationRule3 = (
    id: string | null | undefined,
    locationCode: string | null,
    officeCode: string,
    note: string | null
  ) =>
    pipe(
      POApi.post('distribution-agents-service/location-rule3/save')(ResponseDSAdmin)({
        id,
        locationCode,
        officeCode,
        note
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      }),
      ZIO.flatMap((res) => {
        return ZIO.succeed(res)
      })
    )

  export const getLocationRegionList = (params: InputLocationRegion) =>
    pipe(
      POApi.getConfig({ params })('distribution-agents-service/location-region/get-by')(GetDsAminList),
      ZIO.map((res) => res)
    )

  export const deleteLocationRegion = (id: string | null | undefined) =>
    pipe(
      POApi.post('distribution-agents-service/location-region/delete')(ResponseDSAdmin)({ id }),
      ZIO.map((res) => res)
    )

  export const saveLocationRegion = (
    id: string | null | undefined,
    locationCode: string | null,
    officeCode: string,
    regionCode: string,
    regionType: string,
    note: string | null
  ) =>
    pipe(
      POApi.post('distribution-agents-service/location-region/save')(ResponseDSAdmin)({
        id,
        locationCode,
        officeCode,
        regionCode,
        regionType,
        note
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      }),
      ZIO.flatMap((res) => {
        return ZIO.succeed(res)
      })
    )
}
