import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace ProductOptionSwitching {
  const PolicyExtraInfoDetails = t.type({
    productCode: t.string,
    lifeNo: t.string,
    coverageNo: t.string,
    riderNo: t.string,
    benefitOption: t.string,
    mortClass: t.string,
    sumAssured: t.number,
    installmentPremium: t.number,
    totalPaidBasicYearly: t.number,
    riskCommDate: t.string,
    premCessDate: t.string,
    riskCessDate: t.string,
    dealingDate: t.string,
    rerateDate: t.string,
    debtAmount: t.number,
    targetPremium: t.number,
    minTopUp: t.number,
    maxTopUp: t.number,
    policyNum: t.string,
    riderStatus: t.string,
    riderPremiumStatus: t.string
  })

  const Input = t.type({
    exchangeId: t.string,
    action: t.string,
    policies: t.array(t.string)
  })

  const Output = t.type({
    exchangeId: t.string,
    action: t.string,
    status: t.string,
    policyExtraInfoDetails: t.array(PolicyExtraInfoDetails),
    policyExtraInfoLoadings: t.Array,
    policyExtraInfoFunds: t.Array
  })

  const EduBenefit = t.type({
    coverageCode: t.string,
    option1: t.string,
    description1: t.string,
    description1Vi: t.string,
    option2: t.string,
    description2: t.string,
    description2Vi: t.string
  })

  export type EduBenefit = t.TypeOf<typeof EduBenefit>

  const TpdBenefit = t.type({
    tpdBenefit: t.string,
    description: t.string,
    descriptionVi: t.string
  })

  export type TpdBenefit = t.TypeOf<typeof TpdBenefit>

  const HistoryActions = t.type({
    actionName: t.string,
    input: Input,
    output: Output
  })

  const ActionDetails = t.type({
    status: t.string,
    methodName: t.string,
    historyActions: t.array(HistoryActions)
  })

  const Body = t.type({
    coverageCode: t.string,
    benefitOption: t.string,
    currentSumAssured: t.number,
    curRenewalPremium: t.number,
    eduBenefitList: Maybe(t.array(EduBenefit)),
    tpdBenefitList: Maybe(t.array(TpdBenefit))
  })

  const ResponseStatus = t.type({
    code: t.number,
    message: t.string,
    errors: t.Array
  })

  export const Detail = t.type({
    exchangeId: t.string,
    body: Body,
    actionDetails: t.array(ActionDetails),
    responseStatus: ResponseStatus
  })

  const SubmitData = t.type({
    benefits: t.string,
    oldBenefit: t.string,
    currentSumAssured: t.number,
    newSumAssured: t.number,
    riskSumAssured: t.number,
    minimumSumAssured: t.number
  })

  export type SubmitData = t.TypeOf<typeof SubmitData>
}
