import { format } from "date-fns"
import moment from "moment"
import UTIF from "utif"

export namespace OBFormat {
  export const formatDateToDateString = (date: Date) => {
    return format(date, 'dd/MM/yyyy - HH:mm:ss')
  }

  export const isValidDate = (value: string, format: string) => {
    const isValidDate = moment(value, format, true).isValid()
    return isValidDate
  }

  export const isEqualWithCurrentDate = (value: string) => {
    const isEqualDate = value === moment(new Date()).format('YYYYMMDD')
    return isEqualDate
  }

  export const isRightFormatWithYYYYMMDD = (value: string) => {
    const regexFormat = /^\d{4}\d{2}\d{2}$/
    const isValidFormat = regexFormat.test(value)
    return isValidFormat
  }

  export const isRightFormatWithXXX = (value: string) => {
    const regexFormat = /^\d{3}$/
    const isValidFormat = regexFormat.test(value)
    return isValidFormat
  }

  export const diffTwoDateAsMonth = (d1: Date, d2: Date) =>
    moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'months')

  export const viewTiffFileByNewTab = (blobUrl: string) => {
    let htmlStr: string = ''
    const xhr = new XMLHttpRequest()
    xhr.open('GET', blobUrl)
    xhr.responseType = 'arraybuffer'
    xhr.onload = function (this, e) {
      var buffer = this.response
      const ifds = UTIF.decode(buffer)
      for (let i = 0; i < ifds.length; i++) {
        const firstPageOfTif = ifds[i]
        UTIF.decodeImage(buffer, firstPageOfTif)
        const rgba = UTIF.toRGBA8(firstPageOfTif)

        const imageWidth = firstPageOfTif.width
        const imageHeight = firstPageOfTif.height

        const cnv = document.createElement('canvas')
        cnv.width = imageWidth
        cnv.height = imageHeight

        const ctx = cnv.getContext('2d')
        const imageData = ctx?.createImageData(imageWidth, imageHeight)
        if (imageData) {
          for (let i = 0; i < rgba.length; i++) {
            imageData.data[i] = rgba[i]
          }
          ctx?.putImageData(imageData, 0, 0)
        }

        const dataUrl = cnv.toDataURL('image/png', 1)
        htmlStr += `<img src="${dataUrl}"><br/>`
      }
      var w = window.open('')
      w?.document.write(htmlStr)
    }
    xhr.send()
  }

}