import React, { useState } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import {
  OBButton,
  OBGeneralTable,
  OBGeneralTableColum,
  OBSectionCol,
  OBSectionContent,
  OBSectionRow,
  OBSharedStyles
} from '../ob-common'
import {
  Container,
  DatePicker,
  ErrorHandling,
  Input,
  SelectOption,
  SelectSearch,
  assets,
  useGlobalLoading,
  ModalComponent,
  OBImportFilePermission,
  PulseOpsFormat
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { OBUploadedHistoryConst } from './OBUploadedHistoryConst'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/lib/function'
import { OBImportFileService, PayloadSearchDetailDataImport } from '../ob-service'
import { ZIO } from '@mxt/zio'
import { isArray, isEmpty } from 'lodash'
import { OBImportFileContext } from './OBImportFileContext'
import * as O from 'fp-ts/lib/Option'

type OBFileUploadedDetailHistoryData = {
  fromDate: Date | null
  toDate: Date | null
  department: SelectOption | undefined
  policyNumber: string | undefined
  fileName: string | undefined
  adhocName: SelectOption | undefined
  status: SelectOption | undefined
}

type UploadHistoryDetail = {
  STT: number
  policyNumber: string
  adhocCode: string
  adhocName: string
  fileName: string
  status: string
  paramData: string
  historyDetailID: string
  departmentCode: string
}

type Pagination = {
  pageNum: number
  pageSize: number
  total: number
}

const obImportFileTranslate = 'Outbound:OBImportFile.UploadDetailHistory'

type Props = {
  permissions: string[]
}

export const OBFileUploadedDetailHistory = (props: Props) => {
  const { permissions } = props
  const { t, i18n } = useTranslation()

  const [loading, bindLoading] = useLoading(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  // const [adhocNameOption, setAdhocNameOption] = useState<SelectOption[]>([])
  const [historyDetails, setHistoryDetails] = useState<UploadHistoryDetail[] | null>(null)
  const [rowIndex, setRowIndex] = useState<number>(0)
  const { AdhocNameList, AdhocImportTemplates } = React.useContext(OBImportFileContext)
  const { bindLoader } = useGlobalLoading(false)

  const [{ pageNum, pageSize, total }, setPagination] = useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })

  const { control, watch, trigger } = useForm<OBFileUploadedDetailHistoryData>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      adhocName: undefined
    },
    mode: 'onChange'
  })

  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t(`${obImportFileTranslate}.PolicyNumber`),
      fieldName: 'policyNumber',
      // disabled: true,
      onRender: (rowItem) => {
        // const item = historyDetails?.[rowItem.index]
        const policyNumber = rowItem.selectItem["policyNumber"] as string
        // const historyDetailID = rowItem.selectItem["historyDetailID"] as string
        const selectedIndex = historyDetails?.indexOf(rowItem.selectItem as UploadHistoryDetail) ?? -1
        return (
          <Pressable
            onPress={() => {
              setIsShowModal(true)
              // setRowIndex(rowItem.index)
              setRowIndex(selectedIndex)
            }}
          >
            <Text style={historyStyles.hightLightText}>{policyNumber || '-'}</Text>
          </Pressable>
        )
      }
    },
    {
      title: t(`${obImportFileTranslate}.AdhocCode`),
      fieldName: 'adhocCode',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.AdhocName`),
      fieldName: 'adhocName',
      // disabled: true
    },
    {
      title: t('Outbound:OBUploadFile:Department'),
      fieldName: 'departmentCode',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.FileName`),
      fieldName: 'fileName',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.Status`),
      fieldName: 'status',
      // disabled: true,
      onRender: (rowItem) => {
        // const item = historyDetails?.[rowItem.index]
        const status = rowItem.selectItem["status"] as string
        const currentStatus = OBUploadedHistoryConst.StatusTriggerConst.find((elm) => elm.code === status)
        return <Text>{i18n.language === 'en' ? currentStatus?.nameEn : currentStatus?.nameVi || '-'}</Text>
      }
    }
  ]

  /**
   * Fetch API
   */


  const callAPISearchDetailDataImport = (payload: PayloadSearchDetailDataImport) => {
    return pipe(
      OBImportFileService.searchDetailDataImport({ ...payload }),
      ZIO.map((res) => {
        const { records, paging } = res ?? {}
        const { total, pageIndex, itemsPerPage } = { ...paging } ?? {}

        const _records = records.map(({ policyNumber, adhocCode, adhocName, fileName, status, paramData, id, departmentCode }, index) => ({
          STT: index + 1,
          policyNumber,
          adhocCode,
          adhocName,
          fileName,
          status,
          paramData: Object.entries(paramData ?? {})
            .map(([key, value]) => {
              const number = key.split(/(\d+)/)?.[1] || 0
              return `Parameter ${number}:${value}`
            })
            .join(','),
          historyDetailID: id,
          departmentCode: departmentCode
        }))

        setHistoryDetails(_records)
        setPagination({
          total,
          pageNum: pageIndex,
          pageSize: itemsPerPage
        })
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  /**
   * Functions
   */

  const getOptions = (list: OBUploadedHistoryConst.DepartmentInfoConst[]) => {
    return list.map((item) => ({ value: item.code, label: i18n.language === 'en' ? item.nameEn : item.nameVi }))
  }

  const mappingPayload = (pagination: { pageNum: number; pageSize: number }): PayloadSearchDetailDataImport => {
    const { fromDate, toDate, department, fileName = '', adhocName, policyNumber = '', status } = watch()

    return {
      fromDate: moment(fromDate).format('DD-MM-YYYY'),
      toDate: moment(toDate).format('DD-MM-YYYY'),
      adhocName: adhocName?.label ?? '',
      departmentCode: department?.value ?? '',
      fileName,
      status: status?.value ?? '',
      policyNumber,
      paging: {
        pageIndex: pagination?.pageNum || 0,
        itemsPerPage: pagination?.pageSize || 10
      }
    }
  }

  const mappingDataBeforeOpenModal = () => {
    const info: UploadHistoryDetail = [...(historyDetails ?? [])][rowIndex] ?? {}

    const { adhocName, adhocCode, policyNumber, paramData = '' } = info ?? {}

    const paramDataFormat = paramData?.split(',').reduce((acc, curr, index) => {
      const [key, value] =  [curr.slice(0, curr.indexOf(':')), curr.slice(curr.indexOf(':') + 1)]
      console.log('key:' + key)
      console.log('value:' + value)
      const paramValue = getParamValue(index + 1, adhocCode, value)
      const paramHeaderName = getParamHeaderName(index + 1, adhocCode)
      return {
        ...acc,
        [paramHeaderName]: paramValue || '-'
      }
    }, {})
    return {
      'Detail information': adhocName,
      'Adhoc code': adhocCode,
      'Adhoc name': adhocName,
      'Policy number': policyNumber,
      ...paramDataFormat
    }
  }

  const getParamValue = (paramIndex: number, adhocCode: string, paramValue: string) => {
    const templateItem = AdhocImportTemplates.find((x) => x.adhocCode === adhocCode)

    switch (paramIndex) {
      case 1:
        const paramValue1 = pipe(
          templateItem?.headerParamType?.PARAM01 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue1
      case 2:
        const paramValue2 = pipe(
          templateItem?.headerParamType?.PARAM02 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue2
      case 3:
        const paramValue3 = pipe(
          templateItem?.headerParamType?.PARAM03 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue3

      case 4:
        const paramValue4 = pipe(
          templateItem?.headerParamType?.PARAM04 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue4
      case 5:
        const paramValue5 = pipe(
          templateItem?.headerParamType?.PARAM05 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue5
      case 6:
        const paramValue6 = pipe(
          templateItem?.headerParamType?.PARAM06 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue6
      case 7:
        const paramValue7 = pipe(
          templateItem?.headerParamType?.PARAM07 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue7
      case 8:
        const paramValue8 = pipe(
          templateItem?.headerParamType?.PARAM08 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue8
      case 9:
        const paramValue9 = pipe(
          templateItem?.headerParamType?.PARAM09 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue9
      case 10:
        const paramValue10 = pipe(
          templateItem?.headerParamType?.PARAM10 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue10

      case 11:
        const paramValue11 = pipe(
          templateItem?.headerParamType?.PARAM11 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue11
      case 12:
        const paramValue12 = pipe(
          templateItem?.headerParamType?.PARAM12 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue12
      case 13:
        const paramValue13 = pipe(
          templateItem?.headerParamType?.PARAM13 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue13
      case 14:
        const paramValue14 = pipe(
          templateItem?.headerParamType?.PARAM01 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue14
      case 15:
        const paramValue15 = pipe(
          templateItem?.headerParamType?.PARAM15 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue15
      case 16:
        const paramValue16 = pipe(
          templateItem?.headerParamType?.PARAM16 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue16
      case 17:
        const paramValue17 = pipe(
          templateItem?.headerParamType?.PARAM17 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue17
      case 18:
        const paramValue18 = pipe(
          templateItem?.headerParamType?.PARAM18 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue18
      case 19:
        const paramValue19 = pipe(
          templateItem?.headerParamType?.PARAM19 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue19
      case 20:
        const paramValue20 = pipe(
          templateItem?.headerParamType?.PARAM20 === 'currency',
          O.fromNullable,
          O.fold(
            () => paramValue || '',
            (isCurrencyItem) => pipe(
              paramValue,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue20
      default:
        return ''
    }
  }

  const getParamHeaderName = (paramIndex: number, adhocCode: string) => {
    const isEnLanguage = i18n.language === 'en'
    const headerTemplateData = AdhocImportTemplates.find((x) => x.adhocCode === adhocCode)
    switch (paramIndex) {
      case 1:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM01 || '' : headerTemplateData?.headerTemplateVn.PARAM01 || ''
      case 2:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM02 || '' : headerTemplateData?.headerTemplateVn.PARAM02 || ''
      case 3:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM03 || '' : headerTemplateData?.headerTemplateVn.PARAM03 || ''
      case 4:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM04 || '' : headerTemplateData?.headerTemplateVn.PARAM04 || ''
      case 5:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM05 || '' : headerTemplateData?.headerTemplateVn.PARAM05 || ''
      case 6:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM06 || '' : headerTemplateData?.headerTemplateVn.PARAM06 || ''
      case 7:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM07 || '' : headerTemplateData?.headerTemplateVn.PARAM07 || ''
      case 8:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM08 || '' : headerTemplateData?.headerTemplateVn.PARAM08 || ''
      case 9:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM09 || '' : headerTemplateData?.headerTemplateVn.PARAM09 || ''
      case 10:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM10 || '' : headerTemplateData?.headerTemplateVn.PARAM10 || ''
      case 11:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM11 || '' : headerTemplateData?.headerTemplateVn.PARAM11 || ''
      case 12:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM12 || '' : headerTemplateData?.headerTemplateVn.PARAM12 || ''
      case 13:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM13 || '' : headerTemplateData?.headerTemplateVn.PARAM13 || ''
      case 14:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM14 || '' : headerTemplateData?.headerTemplateVn.PARAM14 || ''
      case 15:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM15 || '' : headerTemplateData?.headerTemplateVn.PARAM15 || ''
      case 16:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM16 || '' : headerTemplateData?.headerTemplateVn.PARAM16 || ''
      case 17:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM17 || '' : headerTemplateData?.headerTemplateVn.PARAM17 || ''
      case 18:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM18 || '' : headerTemplateData?.headerTemplateVn.PARAM18 || ''
      case 19:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM19 || '' : headerTemplateData?.headerTemplateVn.PARAM19 || ''
      case 20:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM20 || '' : headerTemplateData?.headerTemplateVn.PARAM20 || ''
      default:
        return ''
    }
  }

  /**
   * Actions
   */

  const onSearchEvent = async () => {
    const isValidForm = await trigger()
    if (!isValidForm) return

    const payload = mappingPayload({ pageNum: 0, pageSize: 10 })
    return pipe(payload, callAPISearchDetailDataImport)
  }

  const onPageChange = ({ pageNum, pageSize }: { pageNum: number; pageSize: number }) => {
    const payload = mappingPayload({ pageNum, pageSize })
    return pipe(payload, callAPISearchDetailDataImport)
  }

  const onRowsPerPageChange = ({ pageNum, pageSize }: { pageNum: number; pageSize: number }) => {
    const payload = mappingPayload({ pageNum, pageSize })
    return pipe(payload, callAPISearchDetailDataImport)
  }

  /**
   * Views
   */

  const renderTable = () => {
    if (!historyDetails) return <></>

    if (isArray(historyDetails) && isEmpty(historyDetails)) {
      return <Text style={{ textAlign: 'center', marginTop: 12 }}> {t('common:noRecord')}</Text>
    }

    return (
      <OBGeneralTable
        isPagination
        stickyHeader
        rowsPerPageOptions={[10, 20, 50, 100]}
        displayedColumns={displayedColumns}
        dataSource={historyDetails}
        totalRecord={total}
        pageNum={pageNum}
        pageSize={pageSize}
        onPageChange={(pageNum, pageSize) => onPageChange({ pageNum, pageSize })}
        onRowsPerPageChange={(pageNum, pageSize) => onRowsPerPageChange({ pageNum, pageSize })}
        setPageNum={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
        setPageSize={(pageSize) => setPagination((state) => ({ ...state, pageSize }))}
        setTotalRecord={(total) => setPagination((state) => ({ ...state, total }))}
      />
    )
  }

  const renderContentModal = () => {
    return (
      <>
        <OBSectionRow>
          {Object.entries(mappingDataBeforeOpenModal())?.map(([key, value]) => (
            <OBSectionCol style={{ width: '25%', marginBottom: 24 }}>
              <Text style={{ fontSize: 14, fontWeight: 'bold', color: 'rgb(112, 119, 126)' }}>{key}</Text>
              <Text style={{ fontSize: 16, fontWeight: '400', color: 'rgb(79, 79, 79)', marginTop: 4 }}>{value}</Text>
            </OBSectionCol>
          ))}
        </OBSectionRow>
      </>
    )
  }

  return (
    <>
      <ScrollView style={historyStyles.container}>
        <OBSectionContent>
          <OBSectionRow>
            <OBSectionCol>
              <Controller
                control={control}
                name={'fromDate'}
                rules={{
                  validate: (value) => {
                    const toDate = watch('toDate')
                    const monthDistance =
                      moment(toDate).isValid() && moment(value).isValid()
                        ? moment(toDate).startOf('days').diff(moment(value).startOf('days'), 'months')
                        : 0
                    if (!value) return true
                    else if (!moment(value).isValid()) return `${t('error_invalid_date', { ns: 'form' })}`
                    else if (moment(value).valueOf() > moment().valueOf()) return `${t('message:MS990032')}`
                    else if (monthDistance > 3) return `${t('message:MS080004', { k: 3 })}`
                    else return true
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('ServiceInquiry:FromDate')}
                    alwaysShow
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxDate={new Date()}
                    maxDateMessage={t('message:MS990032')}
                    minDate={undefined}
                    minDateMessage={''}
                    errorMessage={error?.message}
                  />
                )}
              />
            </OBSectionCol>
            <OBSectionCol>
              <Controller
                control={control}
                name={'toDate'}
                rules={{
                  validate: (value) => {
                    const fromDate = watch('fromDate')
                    const monthDistance =
                      moment(value).isValid() && moment(value).isValid()
                        ? moment(value).startOf('days').diff(moment(fromDate).startOf('days'), 'months')
                        : 0

                    if (!value) return true
                    else if (!moment(value).isValid()) return `${t('error_invalid_date', { ns: 'form' })}`
                    else if (moment(value).valueOf() > moment().valueOf()) return `${t('message:MS990032')}`
                    else if (
                      moment(moment(fromDate).format('YYYY/MM/DD')).valueOf() >
                      moment(moment(value).format('YYYY/MM/DD')).valueOf()
                    ) {
                      return `${t('message:MS030044')}`
                    } else if (monthDistance > 3) return `${t('message:MS080004', { k: 3 })}`
                    else return true
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('ServiceInquiry:ToDate')}
                    alwaysShow
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxDate={new Date()}
                    maxDateMessage={t('message:MS990032')}
                    minDate={undefined}
                    minDateMessage={''}
                    errorMessage={error?.message}
                  />
                )}
              />
            </OBSectionCol>
            <OBSectionCol>
              <Controller
                control={control}
                name={'department'}
                render={({ field: { value, onChange, onBlur } }) => (
                  <SelectSearch
                    label={t('Outbound:OBUploadFile:Department')}
                    options={getOptions(OBUploadedHistoryConst.DepartmentListConst)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={historyStyles.secondLine}>
            <OBSectionCol>
              <Controller
                control={control}
                name={'fileName'}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    title={t('Outbound:OBUploadFile:FileName')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </OBSectionCol>
            <OBSectionCol>
              <Controller
                control={control}
                name={'adhocName'}
                render={({ field: { value, onChange, onBlur } }) => (
                  <SelectSearch
                    label={t('Outbound:OBUploadFile:AdhocName')}
                    options={AdhocNameList ?? []}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </OBSectionCol>
            <OBSectionCol>
              <Controller
                control={control}
                name={'policyNumber'}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    title={t('Outbound:OBImportFile:UploadDetailHistory:PolicyNumber')}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={''}
                  />
                )}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={historyStyles.secondLine}>
            <OBSectionCol>
              <Controller
                control={control}
                name={'status'}
                render={({ field: { value, onChange, onBlur } }) => (
                  <SelectSearch
                    label={t('Reports:Status')}
                    options={getOptions(OBUploadedHistoryConst.StatusTriggerConst)}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </OBSectionCol>
          </OBSectionRow>

          <View style={[historyStyles.buttonContainer, historyStyles.secondLine]}>
            {permissions.includes(OBImportFilePermission.SearchDetailedFileImportHistoryAdhoc) ? (
              <OBButton
                text={t(`${obImportFileTranslate}.Search`)}
                onHandleClickEvent={() => onSearchEvent()}
                isHightLight={true}
              ></OBButton>
            ) : null}
          </View>
        </OBSectionContent>

        <OBSectionRow>
          <OBSectionCol style={OBSharedStyles.sectionCol12}>
            <Container loading={loading}>{renderTable()}</Container>
          </OBSectionCol>
        </OBSectionRow>
      </ScrollView>

      {isShowModal ? (
        <ModalComponent
          title={'Detail Information'}
          visible={isShowModal}
          onClose={() => setIsShowModal(false)}
          modalWidth={900}
          modalHeight={550}
          actions={[]}
        >
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={{ padding: 24 }}>{renderContentModal()}</View>
          </ScrollView>
        </ModalComponent>
      ) : null}
    </>
  )
}
const historyStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  secondLine: {
    marginTop: 25
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  secondBtn: {
    marginLeft: 15
  },
  hightLightText: {
    textDecorationLine: 'underline',
    color: 'rgb(30, 165, 252)'
  }
})
