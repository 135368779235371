import { Animated, TouchableOpacity, View, StyleSheet, Text } from 'react-native'
//import styled from 'styled-components/native'
import { Autocomplete } from '@material-ui/lab'
import { assets, useMobile } from '@pulseops/common'
import { TextField } from '@material-ui/core'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export enum PolicyServiceGroup {
  CashAdvance = 'CashAdvance',
  AddLackOfDocuments = 'AddLackOfDocuments',
  Reinstatement = 'Reinstatement',
  Payout = 'Payout',
  ChangePolicyInfo = 'ChangePolicyInfo',
  ChangeGeneralInfo = 'ChangeGeneralInfo',
  LetterRequest = 'LetterRequest',
  TransferJournal = 'TransferJournal'
}

export type PanelItem = {
  id: string
  name: string
  navigator: () => void
  group?: PolicyServiceGroup
}

export type PanelProp = {
  itemsSearch: Array<PanelItem>

  itemsGroup: Array<PanelGroupProps>
}

export type PanelGroupProps = {
  id: string
  name: string
  icon: () => JSX.Element
  children: Array<PanelItem>
}

// export const SeachPanel: React.FC<{data: PanelProp}> = ({data}) => {
//     return(
//         <SC.Container>
//             <SC.Scroll>
//                 <SC.Autocomplete
//                     id="combo-box-demo"
//                     // className={useStyles().root}
//                     options={data.itemsSearch}
//                     style={{flex: 1, backgroundColor: 'white', borderRadius: 8}}
//                     freeSolo
//                     disableClearable
//                     renderInput={(params) => {
//                         return(
//                             <View style={{flexDirection: 'row', flex: 1, alignSelf:'center', justifyContent:'center'}}>
//                                  <View style={{alignSelf:'center', paddingHorizontal: 10}}>
//                                     <assets.Search20Icon />
//                                  </View>
//                                  <TextField
//                                     {...params}
//                                     placeholder={"Tell me what you need"}
//                                     style={{fontStyle:'italic'}}
//                                     // variant="standard"
//                                     InputProps={{
//                                         ...params.InputProps,
//                                         // endAdornment: (
//                                         // <React.Fragment>
//                                         //     {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
//                                         //     {params.InputProps.endAdornment}
//                                         // </React.Fragment>
//                                         // ),
//                                         disableUnderline: true
//                                     }}
//                                     // InputLabelProps={{ shrink: true }}
//                                     fullWidth
//                                     />
//                                 {/* <View style={{alignSelf:'center', paddingHorizontal: 10}}>
//                                     <assets.ArrowDownDropdownIcon />
//                                  </View> */}
//                             </View>
//                         );
//                     }}
//                     // getOptionLabel={(option) => option.itemsSearch}
//                     // onChange={(_event, value) => {
//                     //     if(!(typeof value === 'string')) {
//                     //         navigationPage(value)
//                     //     }
//                     // }}
//                     // onClick={(event) => {
//                     //     console.log(event);
//                     // }}
//                 />

//                 {

//                     data.itemsGroup.map((group, index) => {
//                         return <PanelView
//                             group={group}

//                         />
//                     })
//                 }
//             </SC.Scroll>
//         </SC.Container>
//     )
// }

// const PanelView: React.FC<{group: PanelGroupProps}> = ({group}) => {
//     const [ isOpen, setOpen ] = React.useState<boolean>(false)
//     // const { t }  = useTranslation();
//     return(
//        <SC.PanelContainer>
//             {/* Title when close */}
//             <View style={{
//                    // aspectRatio: isOpen ? 1 : undefined,
//                     width: 150,
//                     backgroundColor: 'grey',
//                     alignSelf:'flex-start',
//                     height: isOpen ? "100%" : "100%",
//                 }}
//             >

//             </View>
//         </SC.PanelContainer>
//     );
// }

// const SC = {
//     Container: styled(View)`
//       padding-top: 16px;
//     `,

//     Scroll: styled(ScrollView)`

//     `,
//     Autocomplete: styled(Autocomplete)`
//         flex: 1;
//         background-color: white;
//         border-radius: 8px;
//     `,

//     PanelContainer: styled(View)`
//         width: 90%;
//         flex-direction: row;
//         margin-top: 20px;
//         background-color: #fff;
//         border-radius: 8px;
//         overflow: hidden;
//     `
//   }

const MAX_TRANS_Y = 200
const MIN_TRANS_Y = 0
const DURATION = 400

export const SearchPanel: React.FC<{ data: PanelProp }> = ({ data }) => {
  const [isHide, setHide] = useState<boolean>(false)
  const transY = useRef(new Animated.Value(200)).current
  const [isFocused, setFocused] = useState<boolean>(false)
  const { t } = useTranslation()
  const ref = React.useRef()
  // const [selected, setSelected] = useState<PanelItem>()

  const opacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  })

  const imageOpacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  const toggleView = () => {
    if (isHide) {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MAX_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(false))
    } else {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MIN_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(true))
    }
  }

  return (
    <View style={styles.container}>
      <Animated.View
        style={{
          alignSelf: 'center',
          height: transY,
          // width: transY
          opacity: imageOpacity
        }}
      >
        <assets.PsImage />
      </Animated.View>

      <View style={{ width: '70%', flexDirection: 'row' }}>
        <Autocomplete
          ref={ref}
          id="combo-box-demo"
          options={data.itemsSearch}
          style={{ flex: 1 }}
          freeSolo
          disableClearable
          renderInput={(params) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: 'white',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: isFocused ? 'red' : 'transparent',
                  alignSelf: 'center',
                  paddingVertical: 5
                }}
              >
                <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>
                  <assets.Search20Icon />
                </View>
                <TextField
                  {...params}
                  placeholder={t('utilities:searchPlaceHolder')}
                  style={{ fontStyle: 'italic' }}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    // endAdornment: (
                    // <React.Fragment>
                    //     {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                    //     {params.InputProps.endAdornment}
                    // </React.Fragment>
                    // ),
                    disableUnderline: true
                  }}
                  // InputLabelProps={{ shrink: true }}
                  fullWidth
                  onBlur={() => setFocused(false)}
                  onFocus={() => setFocused(true)}
                />
                <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>
                  <assets.MicroPhone />
                </View>
              </View>
            )
          }}
          getOptionLabel={(option) => option.name}
          onChange={(_event, value) => {
            if (!(typeof value === 'string')) {
              // navigationPage(value)
              value.navigator()
            }
          }}
        />

        <View style={{ width: 20 }} />

        <TouchableOpacity
          onPress={toggleView}
          style={{
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 8,
            width: 45
            // height: 45
          }}
          activeOpacity={0.4}
        >
          {!isHide ? <assets.OpenMenu /> : <assets.CloseMenu />}
        </TouchableOpacity>
      </View>

      <Animated.View
        style={{
          transform: [{ translateY: transY }],
          opacity: opacity,
          marginTop: 40,
          width: '100%',
          alignItems: 'center'
        }}
      >
        {data.itemsGroup.map((group, index) => {
          return <PanelView group={group} isHide={isHide} />
        })}
      </Animated.View>
    </View>
  )
}

// const MIN_HEIGHT = 80
// const MAX_HEIGHT = 200

// const PannelView: React.FC<{suggestion: Suggest, onPress: (value:string) => void}> = ({suggestion, onPress}) => {
//     const { navigate } = useNavigation()
//     const opacity = useRef(new Animated.Value(0)).current
//     const [isHide, setHide] = useState<boolean>(false)
//     const height = opacity.interpolate({
//         inputRange: [0, 1],
//         outputRange: [MIN_HEIGHT, MAX_HEIGHT],
//         extrapolate: 'clamp'
//     })

//     const rotate = opacity.interpolate({
//         inputRange: [0, 1],
//         outputRange: ["0deg", "180deg"],
//     })

//     useEffect(() => {
//         const animListener = opacity.addListener((state) => {
//             console.log(state)
//         })

//         return () => {
//             opacity.removeListener(animListener);
//         }
//     }, [])

//     const toggleView = () => {
//         if(isHide) {
//             opacity.stopAnimation();
//             Animated.timing(
//                 opacity,
//                 {
//                     toValue: 0,
//                     duration: DURATION,
//                     useNativeDriver: false
//                 }
//             ).start(() => setHide(false))
//         } else {
//             opacity.stopAnimation();
//             Animated.timing(
//                 opacity, {
//                     toValue: 1,
//                     duration: DURATION,
//                     useNativeDriver: false
//                 }
//             ).start(() => setHide(true))
//         }
//     }

//     return(
//         <Animated.View
//             style={{height: height, width: "100%", backgroundColor: 'red', marginVertical: 20 }}
//         >
//             <Animated.View
//                 style={{transform: [{ rotate}], position:'absolute', top: 10, right: 10}}
//             >
//                 <TouchableOpacity
//                     onPress={toggleView}
//                     style={{alignSelf:'flex-end'}}
//                 >
//                     <assets.ArrowDownDropdownIcon />
//                 </TouchableOpacity>
//             </Animated.View>

//             <View style={{flexDirection:'row'}}>
//                 <View
//                     style={{aspectRatio:1, height: "100%", backgroundColor:'cyan'}}
//                 >

//                 </View>
//                 <Animated.View style={{opacity: opacity, alignSelf:'center'}}>
//                     <TouchableOpacity
//                         onPress={() => onPress(suggestion.value)}
//                     >
//                         <Text>{suggestion.label}</Text>
//                     </TouchableOpacity>
//                 </Animated.View>
//             </View>
//         </Animated.View>
//     );
// }

const PanelView: React.FC<{ group: PanelGroupProps; isHide: boolean }> = ({ group, isHide }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const { isMobile } = useMobile()
  return (
    <View style={styles.pannel}>
      {/* Title when close */}
      <View
        style={{
          width: 250,
          backgroundColor: '#E5EAEF',
          alignSelf: 'flex-start',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 16
        }}
      >
        {group.icon()}
        {/* {isOpen && <View style={{ height: 20 }} />}
        {isOpen && <Text style={{ fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>{group.name}</Text>} */}
      </View>

      <View style={styles.pannelBody}>
        {
          <TouchableOpacity
            style={{ height: isOpen ? undefined : '100%', width: '100%' }}
            disabled={isOpen || !isHide}
            onPress={() => setOpen(!isOpen)}
          >
            <Text style={[styles.pannelTitle, { paddingVertical: isOpen ? 20 : 40 }]}>{group.name}</Text>
          </TouchableOpacity>
        }
        {isOpen && (
          <View
            style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', paddingHorizontal: 30, paddingBottom: 20 }}
          >
            {group.children.map((child) => {
              return (
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 20,
                    paddingVertical: 20,
                    margin: 10,
                    backgroundColor: '#E5EAEF',
                    borderRadius: 8,
                    width: isMobile ? '100%' : '45%',
                    minWidth: 250,
                    shadowColor: 'grey',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.22
                  }}
                  disabled={!isHide}
                  onPress={() => child.navigator()}
                >
                  <View style={{ justifyContent: 'center' }}>
                    {/* <View style={styles.dot} /> */}
                    <Text style={{ fontSize: 20 }}>{child.name}</Text>
                  </View>
                </TouchableOpacity>
              )
            })}
          </View>
        )}
      </View>

      {/* toggle button */}
      <Animated.View style={[styles.xBtn, { transform: [{ rotate: isOpen ? '0deg' : '-180deg' }] }]}>
        <TouchableOpacity onPress={() => setOpen(!isOpen)} hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}>
          <assets.ExpandArrow />
        </TouchableOpacity>
      </Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20
  },
  pannel: {
    width: '90%',
    flexDirection: 'row',
    backgroundColor: 'white',
    shadowColor: 'white',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 30,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 20
  },
  pannelBody: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    minWidth: 500
  },
  pannelTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    justifyContent: 'center',
    padding: 40
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    margin: 10
  },
  xBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#E5EAEF',
    alignItems: 'center',
    justifyContent: 'center'
  }
})
