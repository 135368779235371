import { Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const EcardC = t.type({
  id: t.string,
  employeeName: t.string,
  certificateNum: t.string,
  effectiveDate: DateFromISOString,
  hcPlan: Nullable(t.string)
})

export type Ecard = t.TypeOf<typeof EcardC>
