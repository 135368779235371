import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { FundSwitchingModel, RequestAuthFormDataSubmit, TopUp } from './model'
import { SubmissionService } from './SubmissionService'
import { TopUpService } from './TopUpService'

export namespace FundSwitchingService {
  export const getDetail = (policyNum: string): Task<FundSwitchingModel.Detail> =>
    pipe(
      TopUpService.GetPolicyExtraInfo([policyNum], ''),
      ZIO.map((policyExt) => {
        const filter = (fund: TopUp.PolicyExtraInfoFund) => (end: string) =>
          fund.fundCode.endsWith(end) && fund.estimateValue > 0
        const fundToFixed0 = (fund: TopUp.PolicyExtraInfoFund) => ({
          ...fund,
          estimateValue: Number(fund.estimateValue.toFixed(0))
        })
        return {
          productCode:
            policyExt.policyExtraInfoDetail.find((p) => p.lifeNo === '01' && p.riderNo === '00')?.productCode ?? '',
          targetPremiumAccount: policyExt.policyExtraInfoFund.filter((p) => filter(p)('1')).map(fundToFixed0),
          topUpAccount: policyExt.policyExtraInfoFund.filter((p) => filter(p)('2')).map(fundToFixed0)
        }
      })
    )

  export const accessData = (policyNum: string): Task<FundSwitchingModel.AccessData> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policyInfo) => {
        return {
          coverageCode: String(policyInfo.body.basicCode),
          contractStatus: String(policyInfo.body.status)
        }
      })
    )

  export const submit =
    (policyNum: string, data: FundSwitchingModel.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(`wf-api/fund/fund-switching`, { body: data }, policyNum, requestauth, [])
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/fund/fund-switching`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )
}
