import { View, Text, StyleSheet, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native'
import React from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import {
  AppContext,
  // PolicyInfoHeader,
  Panel,
  form2,
  useMobile,
  Select,
  Input,
  ImgUploadMutiple,
  RoundedButton,
  SelectOption,
  TransactionType,
  GeneralService,
  StorageBlob,
  CashLessService
} from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import { InfoViewList } from '@pulseops/task'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { RequestRelatedCashlessCollectionStackParamList } from '../RequestRelatedCashlessCollectionStackParamList'
import { WriteOffSubmissionForm } from './WriteOffSubmissionForm'
import { Controller, UseFormReturn } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyInfoHeader } from '../../../ps-submission/transactionScreen/screens'
type Props = StackScreenProps<RequestRelatedCashlessCollectionStackParamList, 'WriteOffSubmissionCommonScreen'>

const paymentReasons: SelectOption[] = [
  { label: 'Thanh toán phí bảo hiểm định kỳ', value: '1' },
  { label: 'Hoàn trả khoản giảm thu nhập đầu tư', value: '2' },
  { label: 'Hoàn trả tạm ứng từ giá trị hoàn lại', value: '3' },
  { label: 'Hoàn trả phí tự động', value: '4' },
  { label: 'Lý do khác - Phí điều chỉnh hợp đồng', value: '5' },
  { label: 'Lý do khác - Phí khôi phục hợp đồng', value: '6' },
  { label: 'Lý do khác - Phí hồ sơ mới', value: '7' },
  { label: 'Lý do khác - Topup', value: '8' },
  { label: 'Lý do khác', value: '9' }
]

export const WriteOffSubmissionCommon = (props: Props) => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const { isMobile } = useMobile()

  const { base, handleSubmit } = form2.useForm(WriteOffSubmissionForm.codec, {
    defaultValues: {
      attachments: [],
      transactionType: props.route.params.transactionType
    }
  })

  const { control, reset } = base

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.navigate('HomeScreen')
        },
        {
          title: t('menu:PCRequest'),
          navigate: () => navigation.navigate('PCRequestStack', { screen: 'PCRequestScreen' })
        },
        {
          title: t('common:RequestRelatedCashlessCollection'),
          navigate: () =>
            navigation.navigate('RequestRelatedCashlessCollectionScreen', {
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeCode: props.route.params.officeCode,
              officeName: props.route.params.officeName,
              proposalNum: props.route.params.proposalNum
            })
        },
        {
          title: t(`TransactionType:${props.route.params?.transactionType}`),
          navigate: () => null
        }
      ])
    }
  }, [isFocused])

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: props.route.params?.policyNum ?? ''
    },
    {
      label: t('common:PolicyOwner'),
      value: props.route.params?.policyOwner
    },
    {
      label: t('common:Office'),
      value: `${props.route.params?.officeCode ?? ''} - ${props.route.params?.officeName ?? ''}`
    }
  ]

  const requestTypeData = [
    {
      label: t('submission:category'),
      value: t('common:RequestRelatedCashlessCollection')
    },
    {
      label: t('submission:serviceType'),
      value: t('submission:Payin')
    },
    {
      label: t('submission:transactionType'),
      value: t(`TransactionType:${props.route.params?.transactionType}`)
    }
  ]

  const getDocsType = () => {
    switch (props.route.params.transactionType) {
      case TransactionType.CUSTOMERS_ADD_PAYMENT_INFORMATION:
        return 'DPC01'
      case TransactionType.REFUND_ACCORDING_TO_CUSTOMER_REQUEST:
        return 'DPC02'
      case TransactionType.REQUEST_A_CARD_REFUND:
        return 'DPC03'
      case TransactionType.REQUEST_TO_ADJUST_PAYMENT_CONTENT:
        return 'DPC04'
      default:
        return ''
    }
  }

  const onReset = () => {
    reset()
  }

  const onSubmit = handleSubmit((value) => {
    const params = props.route.params
    showGlobalLoading(true)
    pipe(
      GeneralService.getAzureStorageFiles(
        value?.attachments,
        params.transactionType,
        getDocsType(),
        'PC',
        params.policyNum,
        params.officeCode,
        'WRITEOFF'
      ),
      ZIO.flatMap((documents) =>
        CashLessService.submissionWriteOff(
          null,
          params.proposalNum,
          documents.map((item) => ({ filename: item.name, url: item.url })),
          params.officeCode,
          value.paymentReason.label,
          params.policyNum,
          value.remark,
          value.transactionType
        )
      ),
      ZIO.map((item) => {
        showGlobalLoading(false)
        showToast(t('message:MS040011'), 'success')
        navigation.navigate('RequestRelatedCashlessCollectionStack', {
          screen: 'RequestRelatedCashlessCollectionScreen',
          params: {
            policyNum: props.route.params.policyNum,
            policyOwner: props.route.params.policyOwner,
            officeCode: props.route.params.officeCode,
            officeName: props.route.params.officeName,
            proposalNum: props.route.params.proposalNum
          }
        })
      }),
      ZIO.catchAll((error) => {
        console.log(error)
        return ZIO.succeed(null)
      }),
      ZIO.unsafeRun({})
    )
  })

  return (
    <SafeAreaView style={{ flex: 1, width: '100%', backgroundColor: 'white' }}>
      <ScrollView>
        <View style={{ flex: 1, width: '100%' }}>
          <View style={{ flex: 1, width: '100%', backgroundColor: '#F2F2F2' }}>
            <PolicyInfoHeader menus={headers} />
          </View>
          <View style={[{ marginHorizontal: 'auto', width: '100%', paddingHorizontal: 15, paddingTop: 15 }]}>
            <Panel title={t('submission:requestType')} isExand={true} containerStyle={{ backgroundColor: '#F9F9F9' }}>
              <InfoViewList dataSource={requestTypeData} containerStyle={{ marginBottom: 0 }} />
            </Panel>
            <Panel title={t('AddLackOfDocuments:SUPPLEMENTARYINFORMATION')} isExand={true}>
              <View style={isMobile ? styles.rowMobile : styles.row}>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Controller
                    name={`paymentReason`}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Select
                        required
                        label={t('submission:PaymentReason')}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                        placeholder={t('common:Select')}
                        options={paymentReasons}
                      />
                    )}
                  />
                </View>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Controller
                    name={'remark'}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        required
                        title={t('submission:Remark')}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        errorMessage={error?.message}
                        maxLength={255}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={isMobile ? styles.rowMobile : styles.row}>
                <View style={styles.colFull}>
                  <View style={[styles.row, styles.mB5]}>
                    <Text style={[styles.mR5, styles.label]}>{t('submission:FileAttachmentPC')}</Text>
                    <Text style={styles.required}>*</Text>
                  </View>
                  <Controller
                    control={control}
                    name={'attachments'}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <Column>
                          <ImgUploadMutiple {...field} value={field.value as any[]} />
                          {error?.message && <Error message={error.message} />}​​​
                        </Column>
                      )
                    }}
                  />
                </View>
              </View>
            </Panel>
          </View>
        </View>
      </ScrollView>
      <View style={[styles.footer]}>
        <TouchableOpacity style={styles.button} onPress={() => onReset()}>
          <Text style={styles.textButton}>{t('submission:ClearAll')}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.btnRed} onPress={onSubmit}>
          <Text style={styles.textButtonRed}>{t('submission:SendRequest')}</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  rowBtn: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  btnRed: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
