import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import { UseFormReturn } from 'react-hook-form'
import { PulseOpsFormat, TaskDetail, form2, SelectOption } from '@pulseops/common'
import { generateUUID } from './helper'
import * as _ from 'lodash'
import { PCReason } from '../mock'

export const defaultSelect: SelectOption = {
  value: '',
  label: '-'
}

export const defaultReceipt: CashLessForm.RawReceipt = {
  about: defaultSelect,
  pendingCode: defaultSelect,
  receiptNo: null,
  transactionCode: null,
  info: [{ sacsType: null, sacsCode: null, amount: null, sacsTypeSelection: [], allowEdit: true, allowDetele: true }]
}

export const defaultJournal: CashLessForm.RawReceipt = {
  about: defaultSelect,
  pendingCode: null,
  receiptNo: null,
  transactionCode: null,
  info: []
}

export const defaultNonCash: CashLessForm.RawReceipt = {
  about: defaultSelect,
  pendingCode: null,
  receiptNo: null,
  transactionCode: null,
  info: []
}

export namespace CashLessForm {
  export const Transaction = t.type({
    sacsType: form2.selectOption.optional,
    sacsCode: form2.selectOption.optional,
    amount: form2.string.optional,
    allowEdit: form2.optional(t.boolean), // condition to disable amount
    allowDetele: form2.optional(t.boolean), // condition to disable delete action
    sacsTypeSelection: t.array(t.type({ value: t.string, label: t.string }))
  })

  export type Transaction = t.TypeOf<typeof Transaction>

  export type RawTransaction = t.OutputOf<typeof Transaction>

  const Receipt = t.type({
    about: form2.selectOption.optional,
    // pendingCode: withMessage(form2.selectOption.required, () =>
    //   i18next.t('message:MS020009', { field: 'pending code' })
    // ),
    pendingCode: form2.selectOption.optional,
    transactionCode: form2.string.optional,
    receiptNo: form2.string.optional,
    info: t.array(Transaction)
  })

  export type RawReceipt = t.OutputOf<typeof Receipt>

  export type Receipt = t.TypeOf<typeof Receipt>

  type ReceiptDetailBrand = {
    readonly ReceiptDetailCheckTrancode: unique symbol
  }

  const ReceiptDetailCheck = pipe(
    Receipt,
    form2.refine(
      (l): l is t.Branded<Receipt, ReceiptDetailBrand> => {
        if (!!l.receiptNo) {
          // không check mã hoãn khi đã có reciept NO vì đã vào core
          return true
        }
        if (!!l.about?.value && (l.about.value === PCReason.ActionEnum.N || l.about.value === PCReason.ActionEnum.R)) {
          return !!l.pendingCode?.value
        }
        return true
      },
      (l) => i18next.t('message:MS020009', { field: i18next.t('Cashless:trancode') }),
      'ReceiptDetailCheckTrancode'
    )
  )

  type ReceiptDetailCheck = t.TypeOf<typeof ReceiptDetailCheck>

  type ListReceiptBrand = {
    readonly ListReceiptCheck: unique symbol
  }

  const ListReceiptCheck = pipe(
    t.array(ReceiptDetailCheck),
    form2.refine(
      (l): l is t.Branded<ReceiptDetailCheck[], ListReceiptBrand> => {
        const _checkReceipt = l.some((r) => !!r.about?.value && r.info.some((i) => !!i.sacsCode && !!i.sacsType))
        return _checkReceipt
      },
      (l) => i18next.t('Cashless:MS990063'),
      'ListReceiptCheck'
    )
  )

  // const JournalFrom = t.intersection([
  //   Transaction,
  //   t.type({
  //     action: t.string,
  //     lpsAmount: t.number,
  //     lpuAmount: t.number,
  //     lnvpAmount: t.number,
  //     lpe1Amount: t.number
  //   })
  // ])

  // export type JournalFrom = t.TypeOf<typeof JournalFrom>

  // export type RawJournalFrom = t.TypeOf<typeof JournalFrom>

  // type JournalFromBrand = {
  //   readonly JournalFromCheck: unique symbol
  // }

  // const JournalFromCheck = pipe(
  //   JournalFrom,
  //   form2.refine(
  //     (l): l is t.Branded<JournalFrom, JournalFromBrand> => {
  //       if (l.sacsCode?.value === 'LP' && l.sacsType?.value === 'S') {
  //         return Math.abs(PulseOpsFormat.thousandSepartorReverse(l.amount ?? '0')) <= l.lpsAmount
  //       }
  //       return true
  //     },
  //     (l) => 'LPS không đủ',
  //     'JournalFromCheck'
  //   ),
  //   form2.refine(
  //     (l): l is t.Branded<JournalFrom, JournalFromBrand> => {
  //       if (l.sacsCode?.value === 'LP' && l.sacsType?.value === 'U') {
  //         return Math.abs(PulseOpsFormat.thousandSepartorReverse(l.amount ?? '0')) <= l.lpuAmount
  //       }

  //       return true
  //     },
  //     (l) => 'LPU không đủ',
  //     'JournalFromCheck'
  //   ),
  //   form2.refine(
  //     (l): l is t.Branded<JournalFrom, JournalFromBrand> => {
  //       if (l.sacsCode?.value === 'LN' && l.sacsType?.value === 'VP') {
  //         return Math.abs(PulseOpsFormat.thousandSepartorReverse(l.amount ?? '0')) <= l.lnvpAmount
  //       }

  //       return true
  //     },
  //     (l) => 'LNVP không đủ',
  //     'JournalFromCheck'
  //   ),
  //   form2.refine(
  //     (l): l is t.Branded<JournalFrom, JournalFromBrand> => {
  //       if (l.sacsCode?.value === 'LP' && l.sacsType?.value === 'E1') {
  //         return Math.abs(PulseOpsFormat.thousandSepartorReverse(l.amount ?? '0')) <= l.lpe1Amount
  //       }

  //       return true
  //     },
  //     (l) => 'LPE1 không đủ',
  //     'JournalFromCheck'
  //   )
  // )

  // const Journal = t.intersection([
  //   Receipt,
  //   t.type({
  //     from: form2.optional(JournalFromCheck)
  //   })
  // ])
  const Journal = t.intersection([
    Receipt,
    t.type({
      lpsAmount: t.number,
      lpuAmount: t.number,
      lnvpAmount: t.number,
      lpe1Amount: t.number
    })
  ])

  export type Journal = t.TypeOf<typeof Journal>

  export type RawJournal = t.OutputOf<typeof Journal>

  type JournalBrand = {
    readonly JournalCheckSum: unique symbol
  }

  type JournalCheckSum = t.Branded<Journal, JournalBrand>

  const JournalCheckSum = pipe(
    Journal,
    form2.refine(
      (l): l is JournalCheckSum => {
        const filterInfo = l.info.filter((i) => !!i.sacsCode?.value && !!i.sacsType?.value)
        const sum = _.sumBy(filterInfo, (t) => Number(PulseOpsFormat.thousandSepartorReverse(t.amount ?? '0')))
        // if (filterInfo.length > 0) {
        //   return sum + PulseOpsFormat.thousandSepartorReverse(l.from?.amount ?? '0') === 0
        // } else {
        //   return true
        // }
        return sum === 0
      },
      (l) => 'Tổng tiền journal phải bằng 0',
      'JournalCheckSum'
    ),
    form2.refine(
      (l): l is JournalCheckSum => {
        const checkLPS = l.info.find(
          (i) =>
            i.sacsCode?.value === 'LP' &&
            i.sacsType?.value === 'S' &&
            PulseOpsFormat.thousandSepartorReverse(i.amount ?? '0') < 0
        )
        if (checkLPS) {
          return PulseOpsFormat.thousandSepartorReverse(checkLPS.amount ?? '0') + l.lpsAmount >= 0
        }

        return true
      },
      (l) => i18next.t('Cashless:journalError'),
      'JournalCheckSum'
    ),
    form2.refine(
      (l): l is JournalCheckSum => {
        const checkLPU = l.info.find(
          (i) =>
            i.sacsCode?.value === 'LP' &&
            i.sacsType?.value === 'U' &&
            PulseOpsFormat.thousandSepartorReverse(i.amount ?? '0') < 0
        )
        if (checkLPU) {
          return PulseOpsFormat.thousandSepartorReverse(checkLPU.amount ?? '0') + l.lpuAmount >= 0
        }
        return true
      },
      (l) => i18next.t('Cashless:journalError'),
      'JournalCheckSum'
    ),
    form2.refine(
      (l): l is JournalCheckSum => {
        const checkLNVP = l.info.find(
          (i) =>
            i.sacsCode?.value === 'LN' &&
            i.sacsType?.value === 'VP' &&
            PulseOpsFormat.thousandSepartorReverse(i.amount ?? '0') < 0
        )

        if (checkLNVP) {
          return PulseOpsFormat.thousandSepartorReverse(checkLNVP.amount ?? '0') + l.lnvpAmount >= 0
        }
        return true
      },
      (l) => i18next.t('Cashless:journalError'),
      'JournalCheckSum'
    ),
    form2.refine(
      (l): l is JournalCheckSum => {
        const checkLPE1 = l.info.find(
          (i) =>
            i.sacsCode?.value === 'LP' &&
            i.sacsType?.value === 'E1' &&
            PulseOpsFormat.thousandSepartorReverse(i.amount ?? '0') < 0
        )
        if (checkLPE1) {
          return PulseOpsFormat.thousandSepartorReverse(checkLPE1.amount ?? '0') + l.lpe1Amount >= 0
        }
        return true
      },
      (l) => i18next.t('Cashless:journalError'),
      'JournalCheckSum'
    )
  )

  const NonCashTransaction = t.intersection([
    Transaction,
    t.type({
      action: withMessage(form2.string.required, () => 'required action')
    })
  ])

  export type NonCashTransaction = t.TypeOf<typeof NonCashTransaction>

  type NonCashTransBrand = {
    readonly NonCashTransCondition: unique symbol
  }

  const NonCashTransCondition = pipe(
    NonCashTransaction,
    // form2.refine(
    //   (l): l is t.Branded<NonCashTransaction, NonCashTransBrand> => {
    //     if (l.action === 'MSP_R_RPJ' || l.action === 'MSP_R_RPN') {
    //       const wrapAmount = PulseOpsFormat.thousandSepartorReverse(l.amount ?? '0')
    //       return wrapAmount <= 30000
    //     }
    //     return true
    //   },
    //   (l) => i18next.t('Cashless:noncashRenError'),
    //   'NonCashTransCondition'
    // ),
    form2.refine(
      (l): l is t.Branded<NonCashTransaction, NonCashTransBrand> => {
        if (l.action === PCReason.ActionEnum.L) {
          const wrapAmount = PulseOpsFormat.thousandSepartorReverse(l.amount ?? '0')
          return wrapAmount >= 0
        }
        return true
      },
      (l) => i18next.t('Cashless:noncashOPLError1'),
      'NonCashTransCondition'
    )
  )

  const Noncash = t.intersection([
    Receipt,
    t.type({
      info: t.array(NonCashTransCondition),
      previousTotalAmount: t.number
    })
  ])

  export type RawNonCash = t.OutputOf<typeof Noncash>

  export type Noncash = t.TypeOf<typeof Noncash>

  type NonCashCheckBrand = {
    readonly NonCashCheck: unique symbol
  }

  const NonCashCheck = pipe(
    Noncash,
    form2.refine(
      (l): l is t.Branded<Noncash, NonCashCheckBrand> => {
        if (l.about?.value === PCReason.ActionEnum.J || l.about?.value === PCReason.ActionEnum.N) {
          const total = (l.info ?? []).reduce<number>(
            (pre, cur) => pre + PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'),
            0
          )
          return l.info.length > 0
            ? !l.receiptNo
              ? total + l.previousTotalAmount <= 30000
              : l.previousTotalAmount <= 30000
            : true
        }
        return true
      },
      (l) => i18next.t('Cashless:noncashRenError'),
      'NonCashCheck'
    )
  )

  export type PolicyNumberBrand = {
    readonly PolicyNumber: unique symbol
  }

  export const validatePolicyNumber = (val: string) => /^[0-9]*$/.test(val) && (val.length === 8 || val.length === 9)

  export const PolicyNumber = pipe(
    NonEmptyString,
    form2.refine(
      (val): val is t.Branded<NonEmptyString, PolicyNumberBrand> => validatePolicyNumber(val),
      () => i18next.t('Số HĐ/HSYCBH gồm 8 hoặc 9 chữ số'),
      'PolicyNumber'
    )
  )

  const RawForm = t.type({
    amount: t.number,
    adjustPolicy: form2.optional(PolicyNumber),
    receips: ListReceiptCheck,
    nonCash: NonCashCheck,
    journal: t.array(JournalCheckSum)
  })

  type RawForm = t.TypeOf<typeof RawForm>

  export const codec = RawForm

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

export type CashLessFormBase = Omit<UseFormReturn<CashLessForm.Raw>, 'handleSubmit'>

export const mapReciepsUpdatePayload = (validated: CashLessForm.Validated, detail: TaskDetail.VeriCashLess) => {
  const filterRecieps =
    validated.receips
      .filter((r) => !!r.about?.value && r.info?.length > 0)
      .map((r) => ({
        ...r,
        info: r.info.filter((i) => !!i.sacsCode?.value && !!i.sacsType?.value) ?? []
      })) ?? []

  const filterReciepsJournal =
    validated.journal.filter(
      (j) => !!j.about?.value && j.info?.some((i) => !!i.sacsType?.value && !!i.sacsCode?.value)
      // !!j.from?.sacsType?.value &&
      // !!j.from?.sacsType?.value
    ) ?? []
  // .map((j) => ({
  //   ...j,
  //   info: [j.from as CashLessForm.Transaction].concat(j.info)
  // })) ?? []

  const filterInfo = validated.nonCash?.info?.filter((i) => !!i.sacsCode?.value && !!i.sacsType?.value) ?? []

  const wrapNoncash = filterInfo.length > 0 ? { ...validated.nonCash, info: filterInfo } : undefined

  const toRawData = (r: CashLessForm.Receipt) => {
    const newDescription = r.pendingCode?.value ?? (r.about?.value === PCReason.ActionEnum.N ? 'NC2' : '')
    return {
      action: r.about?.value ?? '',
      newDescription: newDescription,
      transactionCode: !!r.transactionCode ? r.transactionCode : `P${generateUUID()}`,
      receiptNo: r.receiptNo,
      transactions: r.info
        .map((i) => {
          return {
            amount: Number(PulseOpsFormat.thousandSepartorReverse(i.amount ?? '0')),
            newDescription: newDescription,
            subAccountCode: i.sacsCode?.value ?? '',
            subAccountType: i.sacsType?.value ?? ''
          }
        })
        .filter((r) => !!r.subAccountType)
    }
  }

  const mapData = {
    pcStpType: '0',
    receipts: filterRecieps.map(toRawData),
    journalReceipts: filterReciepsJournal.map(toRawData),
    nonCashReceipts: wrapNoncash
      ? [
          ...detail.rawCoreData.coreNonCash
            .filter((c) => c.receiptNo)
            .map((item) => ({
              action: item.action,
              newDescription: item.action === PCReason.ActionEnum.N ? 'NC2' : '',
              transactionCode: item.transactionCode,
              receiptNo: item.receiptNo,
              transactions: item.transactions
            })),
          toRawData(wrapNoncash)
        ]
      : null,
    discountReceipts: detail.discountReceipts
  }
  return mapData
}
