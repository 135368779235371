import { makeStyles, Tabs, Tab } from '@material-ui/core'
import React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { CategoryTabInfo, OBCategoryTabProps } from './OBCategoryTab'

const useStyles = makeStyles({
  root: {
    '& .MuiTabs-fixed': {
      // borderBottom: '1px solid #C4C4C4'
    },
    '& .MuiTab-root': {
      // set style for child component
      lineHeight: 1,
      minHeight: 35,
      minWidth: 80,
      textTransform: 'capitalize',
      // margin: '0px 10px',
      marginRight: 10,
      padding: '6px 1px'
    },
    '&.MuiTabs-root': {
      // set style for root component
      minHeight: 35
    }
  }
})

export interface BasketCount {
  basketType: string
  activityBasket?: string
}

export type OBBasketTabProps = {
  dataSource: Array<CategoryTabInfo>
  permisison?: string[]
  onChangeTab: (val: number) => void
  activeTabIndex: number
  tabValue: string
}

export const OBBasketTab = (props: OBBasketTabProps) => {
  const { activeTabIndex, dataSource, onChangeTab, tabValue } = props
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    onChangeTab(value)
  }
  const getTabLabel = (isActive: boolean, label: string, count?: number) => {
    // return <Text style={[isActive && tabStyles.activeText]}>{label}</Text>
    console.log('count:' + count)
    return (
      <View style={tabStyles.tabContent}>
        <View><Text style={[isActive && tabStyles.activeText]}>{label}</Text></View>
        {!count && count !== 0 ? <></> :
          (<View style={tabStyles.tabCircleContent}>
            <Text style={tabStyles.tabCircleText}>{count}</Text>
          </View>)}
      </View>
    )
  }

  // console.log('dataSource: ' + JSON.stringify(dataSource))
  return (
    <Tabs
      value={activeTabIndex}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="simple tabs example"
      classes={{
        root: classes.root
      }}
      TabIndicatorProps={{
        style: {
          transition: 'none'
        }
      }}
    >
      {/* <Tab label="Kiểm tra thông tin" disableRipple></Tab>
      <Tab label="Kiểm Soát chất lượng" disableRipple></Tab>
      <Tab label="Thẩm định" disableRipple></Tab> */}
      {dataSource &&
        dataSource.map((dataItem, index) => {
          return (
            <Tab
              key={`CSTabItem_${index}`}
              label={getTabLabel(activeTabIndex === index, dataItem.label, dataItem.count)}
              disableRipple
            // value={dataItem.value}
            ></Tab>
          )
        })}
    </Tabs>
  )
}
const tabStyles = StyleSheet.create({
  activeText: {
    // color: '#ED1B2E'
    color: '#1C1D1F',
    fontSize: 14,
    fontFamily: 'NotoSans_400Regular',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 21
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  tabCircleContent: {
    marginLeft: 4,
    minWidth: 24,
    minHeight: 24,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 3
  },
  tabCircleText: {
    color: '#ED1B2E',
    margin: 2,
    textAlign: 'center',
    fontFamily: 'NotoSans_400Regular',
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: '600'
  }
})
