import React, { useEffect, useState } from 'react'
import { AppContext, assets, ErrorHandling, InputTable, OfficeCode, useMobile, Permission } from '@pulseops/common'
import { TouchableOpacity, useWindowDimensions, View, StyleSheet, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { RuleItem, TypeTab } from '../common/da-rule-item'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableFooter
} from '@material-ui/core'
import { AdminFormModal, AdminUpdateModal, BodyTable, DAdsAdminService, headerItem, HeaderTable } from '../common'
import { Controller, useForm } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useIsFocused } from '@react-navigation/native'

const headerList: headerItem[] = [
  { title: 'Location code', name: 'locationCode', maxL: 4 },
  { title: 'Office code', name: 'officeCode', maxL: 3 },
  { title: 'Note', name: 'note', maxL: 256 }
]

export interface queryOption {
  locationCode?: string | null
  officeCode?: string | null
  note?: string | null
  pageNum: number
  pageSize: number
}

export interface updateFormData {
  id?: string
  locationCode: string
  officeCode: string
  note: string | null
}
interface Props {
  active?: boolean
  permissions: string[]
}

export const LocationRule3Tab = ({ active, permissions }: Props) => {
  const { height } = useWindowDimensions()
  const { t } = useTranslation()
  const [list, setList] = useState<RuleItem[]>([])
  const [obSearch, setObSearch] = useState<RuleItem>({})
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false)
  const [idItem, setIdItem] = useState<string>('')
  const [pageNum, setPageNum] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalItem, setTotalItem] = useState<number>(0)
  const [itemEdit, setItemEdit] = useState<RuleItem>({})
  const [isLoading, bindLoading] = useLoading(false)
  const isFocused = useIsFocused()
  const { isWide } = useMobile()
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 240
    },
    table: { position: 'relative' },
    tableCell: {
      whiteSpace: 'nowrap'
    },
    cellWidth200: {
      minWidth: 200
    },
    tableCellInput: {
      paddingTop: 8,
      paddingBottom: 8
    },
    input: {
      height: 40,
      width: 149
    },
    body: {
      maxHeight: 200
    },
    btnEdit: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    tableFT: {
      position: 'sticky',
      bottom: 0,
      background: 'white',
      zIndex: 10
    },
    stickyRight: {
      position: 'sticky',
      right: 0,
      backgroundColor: '#fff',
      whiteSpace: 'nowrap',
      maxWidth: 150
    },
    hidden: {
      display: 'none'
    }
  })

  const classes = useStyles()

  const updateForm = useForm<updateFormData>({
    defaultValues: {
      locationCode: '',
      officeCode: '',
      note: null
    }
  })

  const { control, setValue, handleSubmit, reset, trigger } = updateForm

  const resetFields = () => {
    setIsEdit(false)
    setOpenModalUpdate(false)
    setIdItem('')
    reset()
  }

  const setFields = (item: RuleItem) => {
    setValue('officeCode', String(item.officeCode))
    setValue('locationCode', String(item.locationCode))
    setValue('note', item.note ? String(item.note) : null)
  }

  const handleRemove = (id: string | null | undefined) => {
    pipe(
      DAdsAdminService.deleteLocationRule3(id),
      ZIO.map((res) => {
        return res.responseStatus.code
      }),
      ZIO.tap((status) => {
        if (status === 1) {
          reLoadData(pageNum, pageSize)
        }
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const handleSave = handleSubmit((values) => {
    if (
      values.locationCode === itemEdit.locationCode &&
      values.officeCode === itemEdit.officeCode &&
      values?.note === itemEdit?.note
    ) {
      showToast(t('RequestInfo:MS030030'), 'error')
      setOpenModalUpdate(false)
    } else {
      pipe(
        DAdsAdminService.saveLocationRule3(idItem, values.locationCode, values.officeCode, values.note ?? null),
        ZIO.map((res) => {
          return res.responseStatus.code
        }),
        ZIO.tap((status) => {
          if (status === 1) {
            reLoadData(pageNum, pageSize)
          }
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
      resetFields()
    }
  })

  const handleUpdate = async (item: RuleItem, index: number) => {
    setIsEdit(true)
    setIdItem(String(item?.id))
    setFields(item)
    setItemEdit(item)
  }

  const onOpenModalUpdate = () => {
    trigger(['locationCode', 'officeCode']).then((isValid) => {
      if (isValid) {
        setOpenModalUpdate(true)
      }
    })
  }

  const onSubmitCreate = (data: RuleItem) => {
    pipe(
      DAdsAdminService.saveLocationRule3(undefined, data?.locationCode ?? '', data?.officeCode ?? '', data?.note ?? ''),
      ZIO.fold(
        (error) => {
          const msg = _.get(error, 'source.cause.source.response.data.responseStatus.errors[0].code')
          showToast(t(`RequestInfo:${msg}`), 'error', {
            horizontal: 'center',
            vertical: 'top'
          })
        },
        () => {
          reLoadData(pageNum, pageSize)
        }
      ),
      ZIO.unsafeRun({})
    )
  }

  const loadData = (pageNum: number, pageSize: number) => {
    const locationCode = obSearch?.locationCode || undefined
    const officeCode = obSearch?.officeCode || undefined
    const note = obSearch?.note || undefined
    return pipe(
      DAdsAdminService.getLocationRuleList({ locationCode, officeCode, note, pageNum, pageSize }),
      ZIO.map((response) => {
        const { elements, totalElement, currentPage, pageSize } = response
        setTotalItem(totalElement)
        setPageNum(currentPage)
        setPageSize(pageSize)
        if (elements && elements.length > 0) {
          return elements
        } else {
          return []
        }
      }),
      ZIO.tap((res) => {
        setList(res)
        return ZIO.unit
      }),
      bindLoading
    )
  }

  const reLoadData = (page: number, size: number) => {
    pipe(loadData(page, size), ErrorHandling.run())
  }
  const onFilter = (value: RuleItem) => {
    setObSearch(value)
  }

  useEffect(() => {
    if (isFocused) {
      pipe(loadData(pageNum, pageSize), ErrorHandling.run())
    }
  }, [obSearch])

  const checkPermission = () => {
    return permissions && !permissions.includes(Permission.EditDSAdmin)
  }

  return (
    <View style={{ paddingHorizontal: 15, paddingTop: 15 }}>
      <TableContainer component={Paper} className={classes.container} elevation={1}>
        <Table className={classes.table} stickyHeader>
          {/* header */}
          <HeaderTable headerList={headerList} onFilter={onFilter} />

          {/* body */}
          <BodyTable
            typeTab={TypeTab.rule3}
            isUpdate={isEdit}
            list={list}
            handleRemove={handleRemove}
            handleUpdate={handleUpdate}
            isLoading={isLoading}
            permissions={permissions}
            tableName="Location rule 3"
          />

          {/* footer */}
          <TableFooter className={classes.tableFT}>
            {isEdit ? (
              <TableRow>
                <TableCell className={classes.tableCellInput}></TableCell>
                <TableCell>
                  <Controller
                    name={'locationCode'}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: 'location code' })}`
                      },
                      validate: (value) => {
                        if (value.length !== 4) {
                          return `${t('RequestInfo:InvalidField', { field: 'Location code' })}`
                        }
                        return true
                      }
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <InputTable
                        value={value}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        errorMessage={error?.message}
                        inputStyle={{ borderColor: '#D3DCE6' }}
                        maxLength={4}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={'officeCode'}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: 'office code' })}`
                      },
                      validate: (value) => {
                        if (value.length !== 3) {
                          return `${t('RequestInfo:InvalidField', { field: 'Office code' })}`
                        }
                        return true
                      }
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <InputTable
                        value={value}
                        onChange={onChange}
                        errorMessage={error?.message}
                        inputStyle={{ borderColor: '#D3DCE6' }}
                        maxLength={3}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    name={'note'}
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <InputTable
                        value={value ?? ''}
                        onChange={onChange}
                        errorMessage={error?.message}
                        inputStyle={{ borderColor: '#D3DCE6' }}
                        maxLength={256}
                      />
                    )}
                  />
                </TableCell>
                <TableCell className={classes.btnEdit}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: isWide ? 200 : 112,
                      height: 53.02,
                      backgroundColor: '#fff',
                      marginLeft: 'auto',
                      marginRight: -6,
                      paddingVertical: 10,
                      paddingLeft: 16,
                      paddingRight: 0
                    }}
                  >
                    <TouchableOpacity onPress={onOpenModalUpdate}>
                      <assets.IconSave />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => resetFields()}>
                      <assets.DeleteBin />
                    </TouchableOpacity>
                  </View>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell style={{ minWidth: 80 }} className={classes.stickyRight}>
                  <TouchableOpacity
                    style={[styles.buttonAdd, { opacity: checkPermission() ? 0.7 : 1 }]}
                    onPress={() => setIsOpen(true)}
                    disabled={checkPermission()}
                  >
                    <Text style={{ fontSize: 12, fontWeight: '700', color: '#ED1B2E' }}>{t('claim:AddNew')}</Text>
                  </TouchableOpacity>
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <TablePagination
        page={pageNum}
        rowsPerPage={pageSize}
        count={totalItem}
        onPageChange={(e, page) => {
          setPageNum(page), reLoadData(page, pageSize)
        }}
        onRowsPerPageChange={(e) => {
          const newPageSize = Number(e.target.value)
          setPageSize(newPageSize)
          reLoadData(pageNum, newPageSize)
        }}
        labelRowsPerPage={t('common:PaginationSize')}
        labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
        component={View}
      />

      <AdminFormModal
        selectedTab={2}
        groupName="DS Admin"
        categoryName="Location Rule 3"
        visible={isOpen}
        onPressClose={() => setIsOpen(false)}
        onPressSubmit={onSubmitCreate}
      />
      <AdminUpdateModal
        selectedTab={2}
        groupName="DS Admin"
        categoryName={'Location Rule 3'}
        visible={openModalUpdate}
        onPressClose={() => setOpenModalUpdate(false)}
        onPressSubmit={handleSave}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  buttonAdd: {
    color: '#ED1B2E',
    borderColor: '#ED1B2E',
    borderRadius: 12,
    border: '1px solid #ED1B2E',
    textAlign: 'center',
    paddingVertical: 6,
    maxWidth: 200,
    marginLeft: 'auto'
  }
})
