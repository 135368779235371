// import { Frequency } from './../model/BillingChange'
// import { ConfirmOfPremium, FrequencyC } from '../model'
import { ZIO, Task } from '@mxt/zio'
// import { PulseOpsApi } from '@pulseops/api'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
// import { PulseOpsAuth } from '@pulseops/auth'
// import { SubmissionService } from '../SubmissionService'
// import { RequestAuthFormDataSubmit } from 'packages/pulse-ops/core/src/entity'
import moment from 'moment'
import { POApi } from '..'
import { ConfirmOfPremium } from './model/ConfirmOfPremium'
import { Frequency, FrequencyC } from './model/Frequency'
import { SubmissionService } from './SubmissionService'

export namespace ConfirmOfPremiumService {
  export const getDetail = (policyNum: string): Task<ConfirmOfPremium.Detail> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policy): ConfirmOfPremium.Detail => {
        const riskCommenceDate = policy.body.riskCommenceDate ?? ''
        const billingFrequency = policy.body.billFreq ?? ''
        const customedRiskComDate = moment(riskCommenceDate).format('YYYY/MM/DD')
        return {
          rcd: new Date(customedRiskComDate),
          billingFrequency: billingFrequency
        }
      })
    )

  // export const submit = (policyNum: string, data: ConfirmOfPremium.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>   SubmissionService.submit(t.unknown)(
  //   `wf-api/policy/${policyNum}/premium-receipt`,
  //   {body: data}, policyNum, requestauth, [])

  export const getDataCheckAccess = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicies([{ policyNo: policyNum }]),
      ZIO.map(
        (policies): ConfirmOfPremium.AccessDataCheck => ({
          contractStatus: policies[0].contractStatus,
          lapseDate: new Date(policies[0].paidToDateBasic)
        })
      )
    )

  export const getCurBillingFreqency = (policyNum: string): Task<{ curBillingFreq: Frequency }> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/billing-change`)(
        t.type({
          body: t.type({
            curBillingFrequency: FrequencyC
          })
        })
      ),
      ZIO.map((billing): { curBillingFreq: Frequency } => ({
        curBillingFreq: billing.body.curBillingFrequency
      }))
    )
}
