import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
  Pressable
} from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  form2,
  DatePicker,
  SelectSearch,
  assets,
  FailListInfo,
  CashLessService,
  Banks,
  SelectOption,
  downloadURI,
  formatNumberWithComma,
  AuthService,
  ErrorHandling,
  AppContext
} from '@pulseops/common'
import { FailListForm } from './fail-list-form'
import { Controller } from 'react-hook-form'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { FailListDetailScreen } from './fail-list-detail-screen'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { IconSwitch } from '../../../../task/src/task-dashboard/IconSwitch'
import _ from 'lodash'
interface Props {
  setCanChangeTab: (canChangeTab: boolean) => void
}

export const FailListScreen = (props: Props) => {
  const { t } = useTranslation()
  const { setCanChangeTab } = props
  // const [isLoading, setLoading] = React.useState(false)
  const [failList, setFailList] = React.useState<FailListInfo[]>([])
  const { base, handleSubmit } = form2.useForm(FailListForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      bankCode: { label: 'All', value: '' },
      status: { label: 'All', value: '' }
    }
  })
  const [isLoading, bindLoading] = useLoading(false)
  const [openModalViewDetail, setOpenModalViewDetail] = React.useState<boolean>(false)
  const { height } = useWindowDimensions()
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [totalTask, setTotalTask] = React.useState<number>(0)
  const [objectIdDetail, setObjectIdDetail] = React.useState<string | null>(null)
  const [rowSelected, setRowSelected] = React.useState<FailListInfo[]>([])
  const [selectedAll, setSelectedAll] = React.useState<boolean>(false)
  const [enableCheckbox, setEnableCheckbox] = React.useState<boolean>(false)
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false)
  const [loadingUploadData, setLoadingUploadDate] = React.useState<boolean>(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const Status = [
    { label: 'All', value: '' },
    { label: 'Fail', value: 'fail' },
    { label: 'Time out', value: 'timeout' },
    { label: 'Duplicate', value: 'duplicate' }
  ]
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const checkStatus = (status: string) => ['fail', 'duplicate'].includes(status)

  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const onChangePageSize = (size: number) => {
    setPageSize(Number(size))
    setPage(0)
  }

  const classes = useStyles()

  const onSearch = async (page: number) => {
    const validDate = moment(base.watch().toDate).diff(base.watch().fromDate, 'days') <= 30
    const formValid = await base.trigger()
    if (formValid && validDate) {
      const data = base.watch()
      pipe(
        CashLessService.searchFailList(
          page,
          pageSize,
          'createdDate',
          'desc',
          moment(data.fromDate).format('DD-MM-YYYY'),
          moment(data.toDate).format('DD-MM-YYYY'),
          data.bankCode?.value || '',
          data.status?.value || ''
        ),
        ZIO.map((res) => {
          setFailList(res.data)
          handleChangePageSelectRow(res.data)
          setTotalTask(Number(res.total))
          setEnableCheckbox(res.data.some((item) => ['timeout'].includes(item.status || '')))
        }),
        ZIO.catchAll((e) => {
          console.log(e)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const onExport = handleSubmit((data) => {
    setLoadingExport(true)
    setCanChangeTab(false)
    pipe(
      CashLessService.exportFailList(
        moment(data.fromDate).startOf('day').toISOString(),
        moment(data.toDate).endOf('day').toISOString(),
        data.bankCode?.value || '',
        data.status?.value || ''
      ),
      ZIO.flatMap((res) => {
        setLoadingExport(false)
        setCanChangeTab(true)
        return ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      }),
      ZIO.tap(([res, blob]) => {
        downloadURI(
          window.URL.createObjectURL(blob),
          `FailList_${data.bankCode?.value ? data.bankCode?.value : 'All'}.xlsx`
        )
        return ZIO.unit
      }),

      ZIO.unsafeRun({})
    )
  })
  React.useEffect(() => {
    onSearch(page)
  }, [page, pageSize])

  const handleViewDetail = (objectId: string) => {
    setObjectIdDetail(objectId)
    setOpenModalViewDetail(true)
  }

  const onSelectedCase = (active: boolean, item: FailListInfo) => {
    if (active) {
      setRowSelected([...rowSelected, item])
      handleChangePageSelectRow(failList, [...rowSelected, item])
    } else {
      setSelectedAll(false)
      setRowSelected(rowSelected.filter((x) => x.objectId !== item.objectId))
    }
  }

  const onUploadDataToSys = () => {
    setLoadingUploadDate(true)
    setCanChangeTab(false)
    pipe(
      CashLessService.submitCaseDetail(
        rowSelected.map((item) => ({
          objectId: item?.objectId || '',
          receivedDate: moment(item.receivedDate).format('YYYY-MM-DD'),
          creditDate: moment(item.creditDate).format('YYYY-MM-DD'),
          amount: (item.amount || '').toString() || '',
          refNo: item.refNo || '',
          paymentReason: item.paymentReason || '',
          primaryPolicyNo: item.primaryPolicyNo || '',
          bankCode: item.bankCode || '',
          updatedBy: userInfo?.email || ''
        }))
      ),
      ZIO.map((data) => {
        setLoadingUploadDate(false)
        setCanChangeTab(true)
        setRowSelected([])
        setSelectedAll(false)
        showToast(t('message:MS990072'), 'success')
        onSearch(page)
      }),
      ZIO.unsafeRun({})
    )
  }

  const handleChangePageSelectRow = (data: FailListInfo[], row: FailListInfo[] = rowSelected) => {
    const dataTimeout = data.filter((item) => item.status === 'timeout')
    const checkSelectAll =
      dataTimeout.filter((item) => row.some((x) => x.objectId === item.objectId)).length === dataTimeout.length
    setSelectedAll(checkSelectAll)
  }

  const formatGetNameByEmail = (email: string) => {
    return email ? email.split('@')[0] : email
  }

  return (
    <View style={{ margin: 15 }}>
      <Text style={styles.sessonText}>{t('TaskManagement:SearchTransaction')}</Text>
      <View style={styles.searchContainer}>
        <View style={styles.row}>
          <Controller
            control={base.control}
            name={'fromDate'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <View style={styles.inputStyle}>
                  <DatePicker
                    label={t('ServiceInquiry:FromDate')}
                    onChange={onChange}
                    value={value}
                    alwaysShow
                    maxDate={new Date()}
                    minDate={moment(base.watch().toDate).subtract(30, 'days')}
                    errorMessage={error?.message}
                  />
                </View>
              )
            }}
          />
          <Controller
            control={base.control}
            name={'toDate'}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <View style={styles.inputStyle}>
                  <DatePicker
                    label={t('ServiceInquiry:ToDate')}
                    onChange={onChange}
                    value={value}
                    alwaysShow
                    maxDate={moment(base.watch().fromDate).add(30, 'days')}
                    minDate={base.watch().fromDate}
                    errorMessage={error?.message}
                  />
                </View>
              )
            }}
          />
          <Controller
            name="bankCode"
            control={base.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <View style={styles.inputStyle}>
                  <SelectSearch
                    label={t('TaskManagement:BankCode')}
                    options={Banks.RawBanks.map((item) => ({
                      value: item.bankCode,
                      label: item.bankCode ? item.bankCode : 'All'
                    }))}
                    placeholder={t('common:Select')}
                    onChange={(val) => {
                      onChange(val)
                    }}
                    onBlur={() => {
                      onChange((!value || !value.value) && { label: 'All', value: '' })
                    }}
                    value={value}
                    labelStyle={{ marginBottom: 8 }}
                    errorMessage={error?.message}
                  />
                </View>
              )
            }}
          />
          <Controller
            name="status"
            control={base.control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <View style={styles.inputStyle}>
                <SelectSearch
                  label={t('TaskManagement:StatusPC')}
                  options={Status}
                  placeholder={t('common:Select')}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  onBlur={() => {
                    onChange((!value || !value.value) && { label: 'All', value: '' })
                  }}
                  value={value}
                  labelStyle={{ marginBottom: 8 }}
                  errorMessage={error?.message}
                />
              </View>
            )}
          />
        </View>
        <View style={[styles.row, { alignItems: 'center', marginTop: 20 }]}>
          <TouchableOpacity
            style={{ height: 40 }}
            onPress={() => {
              setPage(0)
              onSearch(0)
            }}
            disabled={loadingExport || loadingUploadData}
          >
            <View style={[styles.btn, { backgroundColor: loadingExport || loadingUploadData ? '#B0B0B0' : '#ed1b2e' }]}>
              <Text style={styles.btnText}>{t('common:Search')}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ height: 40, marginLeft: 30 }}
            onPress={onExport}
            disabled={loadingExport || loadingUploadData}
          >
            <View
              style={[
                styles.btn,
                { flexDirection: 'row', backgroundColor: loadingExport || loadingUploadData ? '#B0B0B0' : '#ed1b2e' }
              ]}
            >
              {loadingExport && <ActivityIndicator size="small" color="white" />}
              <Text style={styles.btnText}>{t('DistributionService:Export')}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      <Text style={styles.sessonText}>{t('TaskManagement:DetailInfo')}</Text>
      <View style={{ marginTop: 10 }}>
        <TableContainer component={Paper} className={classes.container} elevation={1}>
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>
                  {enableCheckbox ? (
                    <IconSwitch
                      size={24}
                      active={selectedAll}
                      ActiveIcon={<assets.TaskIcon_Checked />}
                      InactiveIcon={<assets.TaskIcon_UnChecked />}
                      onChanged={(event) => {
                        setSelectedAll(event)
                        if (event) {
                          setRowSelected([...rowSelected, ...failList.filter((x) => x.status === 'timeout')])
                        } else {
                          setRowSelected([])
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:BankCode')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:PolicyNo/ProposalNo')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:Amount')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:RefNo')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:ReceivedDatePC')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:CreditDate')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:ImportDate')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:StatusPC')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:UserImplementation')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {isLoading ? (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <ActivityIndicator size="large" color="red" />
                    </View>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {failList.length > 0 ? (
                    <>
                      {failList.map((item: FailListInfo, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className={classes.tableCell}>
                              {['timeout'].includes(item?.status || '') ? (
                                <IconSwitch
                                  size={24}
                                  active={rowSelected.some((itemSelected) => itemSelected.objectId === item.objectId)}
                                  ActiveIcon={<assets.TaskIcon_Checked />}
                                  InactiveIcon={<assets.TaskIcon_UnChecked />}
                                  onChanged={(event) => onSelectedCase(event, item)}
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            <TableCell className={classes.tableCell}>{item.bankCode}</TableCell>
                            <TableCell className={classes.tableCell}>
                              <Pressable
                                onPress={() => handleViewDetail(item?.objectId || '')}
                                disabled={!checkStatus(item?.status || '')}
                              >
                                <Text
                                  style={{
                                    fontWeight: '600',
                                    fontSize: 15,
                                    color: checkStatus(item?.status || '') ? '#1ea5fc' : '#000000',
                                    textDecorationLine: checkStatus(item?.status || '') ? 'underline' : 'none'
                                  }}
                                >
                                  {item.primaryPolicyNo || '-'}
                                </Text>
                              </Pressable>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {formatNumberWithComma((item?.amount || '').toString())}
                            </TableCell>
                            <TableCell className={classes.tableCell}>{item.refNo}</TableCell>
                            <TableCell className={classes.tableCell}>
                              {moment(item.receivedDate).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {moment(item.creditDate).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {moment(item.createdDate).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {Status.find((x) => x.value === item.status)?.label}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {formatGetNameByEmail(item.updatedBy) || formatGetNameByEmail(item.createdBy)}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                        {t('common:Nodata')}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          rowsPerPage={pageSize}
          count={totalTask}
          onPageChange={(e, page) => {
            setPage(page)
          }}
          onRowsPerPageChange={(e) => {
            onChangePageSize(Number(e.target.value))
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
        <View style={styles.rowBtn}>
          <TouchableOpacity
            style={{ height: 40, marginLeft: 30 }}
            onPress={onUploadDataToSys}
            disabled={loadingUploadData || _.isEmpty(rowSelected) || loadingExport}
          >
            <View
              style={[
                styles.btnUpload,
                {
                  flexDirection: 'row',
                  backgroundColor: loadingUploadData || _.isEmpty(rowSelected) || loadingExport ? '#B0B0B0' : '#ed1b2e'
                }
              ]}
            >
              {loadingUploadData && <ActivityIndicator size="small" color="white" />}
              <Text style={styles.btnText}>{t('TaskManagement:UploadDataToSys')}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
      {objectIdDetail && (
        <FailListDetailScreen
          visible={openModalViewDetail}
          onClose={(visible) => {
            setOpenModalViewDetail(visible)
            setObjectIdDetail(null)
            onSearch(page)
          }}
          objectId={objectIdDetail || ''}
        />
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  sessonText: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 15
  },
  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginVertical: 15,
    padding: 25,
    flex: 1,
    flexDirection: 'column'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15,
    width: '22%'
  },
  btn: {
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  btnUpload: {
    height: 40,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  rowBtn: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15
  },
  btnText: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'NotoSans_700Bold'
  }
})
