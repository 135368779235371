import { AppContext, ErrorHandling, TopUp, TopUpConst, TopUpService, TransactionType } from '@pulseops/common'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { TopUpILPScreen } from './top-up-ilp/top-up-ilp-screen'
import { TopUpFormData } from './top-up.form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { PolicyServiceProps } from '../policy-service-props'
import { TopUpULPScreen } from './top-up-ulp/top-up-ulp-screen'
import { RequestAuthenticateData } from '../../request-authen'

export const TopUpScreen: React.FC<PolicyServiceProps<TopUp.SubmitData>> = ({
  initSubmission,
  policyNumber,
  isConfirmed
}) => {
  const { t, i18n } = useTranslation()
  const policyNum = policyNumber ?? ''

  const formILP = useForm<TopUpFormData.RequestFormValue>({
    defaultValues: {
      topUpAmount: 0,
      suspenseAmount: 0,
      requirePayinAmount: 0,
      topUpOption: TopUpConst.TopUpOption.ByPercentage,
      fundList: [],
      agentCode: ''
    }
  })

  const formULP = useForm<TopUpFormData.RequestULPForm>({
    defaultValues: {
      topUpAmount: 0,
      suspenseAmount: 0,
      requirePayinAmount: 0,
      agentCode: ''
    }
    // mode: 'onChange'
  })

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [detailData, setDetailData] = React.useState<TopUp.Detail>()
  const [topUpType, setTopUpType] = React.useState<string>('')
  const [nextStepMsg, setNextStepMsg] = React.useState<string>('')
  const [isLoading, bindLoader] = useLoading(false)

  React.useEffect(() => {
    pipe(
      TopUpService.getDetail(policyNum),
      ZIO.map((responseData) => {
        if (responseData) {
          setDetailData(responseData)
          if (responseData.coverageCode.startsWith('U')) {
            setTopUpType('ILP')
          } else {
            setTopUpType('ULP')
          }
        }
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }, [i18n.language])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const totalTopupPercentage = () => {
    const fundTotal = formILP.watch('fundList').reduce(function (total, item) {
      return !isNaN(Number(item.percentage)) ? total + Number(item.percentage) : total
    }, 0)
    return fundTotal
  }

  const totalTopupAmount = () => {
    const fundTotal = formILP.watch('fundList').reduce(function (total, next) {
      return !isNaN(Number(next.amount)) ? total + Number(next.amount) : total
    }, 0)
    return fundTotal
  }

  const IsValidILPData = (amount: number) => {
    if (amount <= 0) {
      const ILPMessage = formILP.formState.errors.topUpAmount?.message
      !ILPMessage &&
        formILP.setError('topUpAmount', {
          message: `${t('message:MS020001', { field: t('requestInfo:TopUpAmount') })}`
        })
      return false
    } else if (amount > Number(detailData?.maxTopUp ?? 0)) {
      return false
    } else if (amount < Number(detailData?.minTopUp)) {
      // setError('topUpAmount', {
      //   message: `${t('message:MS050266', { min: formatNumberWithComma(topUpDetail.minTopUp), max: formatNumberWithComma(topUpDetail.maxTopUp) })}`
      // })
      return false
    } else {
      return true
    }
  }

  const isValidULPData = (amount: number) => {
    if (amount <= 0) {
      const ULPMessage = formULP.formState.errors.topUpAmount?.message
      !ULPMessage &&
        formULP.setError('topUpAmount', {
          message: `${t('message:MS020001', { field: t('requestInfo:TopUpAmount') })}`
        })
      return false
    } else if (detailData?.coverageCode === 'VLR1' && amount > detailData?.totalPremium) {
      return false
    } else if (detailData?.coverageCode === 'VLS1' && amount > 0.5 * Number(detailData?.ULPsuspendAmount ?? 0)) {
      return false
    } else if (amount < Number(detailData?.minTopUp ?? 0)) {
      return false
    } else {
      return true
    }
  }

  const validateNextButtonClick = () => {
    switch (topUpType) {
      case 'ILP': {
        const topUpOPT = formILP.watch('topUpOption')
        // const isValid = await formILP.trigger()
        setNextStepMsg('')
        if (!IsValidILPData(formILP.watch('topUpAmount'))) {
          return false
        } else if (topUpOPT === TopUpConst.TopUpOption.ByPercentage && totalTopupPercentage() !== 100) {
          // this.nextStepMsg = PulseOpsMessage.MS050040
          setNextStepMsg(t('message:MS050040'))
          return false
        } else if (
          topUpOPT === TopUpConst.TopUpOption.ByAmount &&
          totalTopupAmount() !== Number(formILP.watch('topUpAmount'))
        ) {
          // this.nextStepMsg = PulseOpsMessage.MS050230
          setNextStepMsg(t('message:MS050230'))
          return false
        } else {
          return true
        }
      }
      case 'ULP': {
        if (!isValidULPData(formULP.watch('topUpAmount'))) {
          return false
        } else {
          return true
        }
      }
      default:
        return false
    }
  }

  const mappingILPData = () => {
    const suspenseAmount = formILP.watch('suspenseAmount')
    const totalTopUpAmount = formILP.watch('topUpAmount')
    const topUpOPT = formILP.watch('topUpOption')
    const option = topUpOPT === TopUpConst.TopUpOption.ByPercentage ? 'P' : 'A'
    const topupFunds: TopUp.TopupFunds[] = formILP
      .watch('fundList')
      .filter(
        (fundItem) =>
          (option === 'P' && Number(fundItem.percentage) > 0) || (option === 'A' && Number(fundItem.amount) > 0)
      )
      .map((fundInfo) => {
        const ration = topUpOPT === TopUpConst.TopUpOption.ByPercentage ? String(fundInfo.percentage) : ''
        const topupAmount = topUpOPT === TopUpConst.TopUpOption.ByAmount ? String(fundInfo.amount) : ''
        return {
          fundCode: String(fundInfo.fundCode),
          ratio: ration,
          topupAmount: topupAmount
        }
      })
    const data: TopUp.SubmitData = {
      totalTopupAmount: String(totalTopUpAmount),
      topupFunds: topupFunds,
      option: option,
      suspenseAmount: suspenseAmount
    }
    return data
  }

  const mappingULPData = () => {
    const suspenseAmount = formULP.watch('suspenseAmount')
    const totalTopUpAmount = formULP.watch('topUpAmount')
    const option = 'A'
    const topupFunds: TopUp.TopupFunds[] = [
      {
        fundCode: detailData?.policyExtraInfoFund?.fundCode ?? '',
        ratio: '',
        topupAmount: String(totalTopUpAmount)
      }
    ]
    const data: TopUp.SubmitData = {
      totalTopupAmount: String(totalTopUpAmount),
      topupFunds: topupFunds,
      option: option,
      suspenseAmount: suspenseAmount
    }
    return data
  }

  const getTopUpSubmitedData = () => {
    let topUpSubmitData!: TopUp.SubmitData
    switch (topUpType) {
      case 'ILP': {
        topUpSubmitData = mappingILPData()
        // this.eformAuthService.submitData(TopUpService.submit(this.topUp.policyNum, ILPSubmitData))(this.authenticationForm.value)
        break
      }
      case 'ULP': {
        topUpSubmitData = mappingULPData()
        // this.eformAuthService.submitData(TopUpService.submit(this.topUp.policyNum, ULPSubmitData))(this.authenticationForm.value)
        break
      }
    }
    return topUpSubmitData
  }

  initSubmission({
    validate: async () => {
      if (validateNextButtonClick()) {
        const submitedData = getTopUpSubmitedData()
        return {
          url: (policyNumber: string) => `wf-api/policy/${policyNumber}/top-up`,
          body: submitedData,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.TOP_UP),
          collerationId: policyNum,
          transaction: TransactionType.TOP_UP
        }
      } else {
        return false
      }
    },
    clear: () => {
      switch (topUpType) {
        case 'ILP': {
          formILP.setValue('topUpAmount', 0)
          formILP.setValue('requirePayinAmount', 0)
          const topUpOPT = formILP.watch('topUpOption')
          formILP.watch('fundList').forEach((fundItem, index) => {
            if (topUpOPT === TopUpConst.TopUpOption.ByPercentage) {
              formILP.setValue(`fundList.${index}.percentage`, 0)
              formILP.setValue(`fundList.${index}.amountByPercent`, 0)
            } else {
              formILP.setValue(`fundList.${index}.amount`, 0)
            }
          })
          break
        }
        case 'ULP': {
          formULP.setValue('topUpAmount', 0)
          formULP.setValue('requirePayinAmount', 0)
          break
        }
        default:
          break
      }
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      {topUpType === 'ILP' && !!detailData && (
        <TopUpILPScreen
          formILP={formILP}
          topUpDetail={detailData as TopUp.Detail}
          isDisable={isConfirmed}
        ></TopUpILPScreen>
      )}
      {topUpType === 'ULP' && !!detailData && (
        <TopUpULPScreen
          formULP={formULP}
          topUpDetail={detailData as TopUp.Detail}
          isDisable={isConfirmed}
        ></TopUpULPScreen>
      )}
      {!!nextStepMsg && (
        <View style={{ marginTop: 10 }}>
          <Text style={topUpStyles.error_message}>{nextStepMsg}</Text>
        </View>
      )}
    </SafeAreaView>
  )
}
const topUpStyles = StyleSheet.create({
  error_message: {
    color: '#ed1c2e'
  }
})
