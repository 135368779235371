import { CircularProgress, createTheme, makeStyles, TextField, ThemeProvider } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { UIO, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { identity, pipe } from 'fp-ts/function'
import React from 'react'
import { ControlProps, SelectOption, assets } from '@pulseops/common'
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import * as R from 'rxjs'
import * as Rx from 'rxjs/operators'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from '@material-ui/styles'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

const useStyles = makeStyles({
  root: {
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none'
    }
  },
  textField: {
    borderColor: '#D3DCE6'
  }
})

type SelectProps = {
  label?: string
  options?: SelectOption[]
  searchOptions?: (inputValue: string) => UIO<SelectOption[]>
  disabled?: boolean
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  popupIcon?: JSX.Element
  showPopupIcon?: boolean
  required?: boolean
  readOnly?: boolean
  labelStyle?: TextStyle
  placeholder?: string
  disableUnderline?: boolean
  prefixIcon?: JSX.Element
  inputStyle?: StyleProp<ViewStyle>
  buildLabel?: (selectOption: SelectOption) => string
  inputWhenFocus?: boolean
  textInputStyle?: CSSProperties
}

type Props = ControlProps<SelectOption | null> & SelectProps

type MultipleProps = ControlProps<SelectOption[]> &
  SelectProps & {
    multiple: true
  }

export function SelectSearch(props: MultipleProps): JSX.Element
export function SelectSearch(props: Props): JSX.Element
export function SelectSearch(_props: any): JSX.Element {
  const props: ControlProps<SelectOption | SelectOption[] | null> &
    SelectProps & {
      multiple?: boolean
    } = _props
  const [options, setOptions] = React.useState<SelectOption[]>([])
  // const [inputValue, setInputValue] = React.useState<string>('')
  // const [selected, setSelected] = React.useState<SelectOption | SelectOption[] | null>(null)
  const [loading, bindLoading] = useLoading(false)
  const { t } = useTranslation()

  const inputChange = new R.Subject<string>()

  React.useEffect(() => {
    // Update the document title using the browser API
    inputChange.pipe(Rx.debounceTime(1000)).subscribe((res) => {
      pipe(
        props.searchOptions !== undefined ? props.searchOptions(res) : ZIO.succeed(new Array<SelectOption>()),
        ZIO.tap((options) =>
          ZIO.effectTotal(() => {
            setOptions(options)
          })
        ),
        bindLoading,
        ZIO.run({})
      )
    })
  })

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        className={useStyles().root}
        multiple={props.multiple}
        options={props.searchOptions ? options : props.options || []}
        popupIcon={props.showPopupIcon === false ? null : props?.popupIcon || <assets.Search20Icon />}
        style={{}}
        renderInput={(params) => (
          <View>
            <Text style={[styles.label, _props.labelStyle]}>
              {props.label}
              {!!props.required && <Text>*</Text>}{' '}
            </Text>
            <View style={[{ flexDirection: 'row' }, props.inputStyle]}>
              {props.prefixIcon && (
                <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>{props.prefixIcon}</View>
              )}
              <TextField
                {...params}
                placeholder={props.placeholder}
                // className={useStyles().textField}
                // label={props.label}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  readOnly: props.readOnly,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  disableUnderline: props.disableUnderline
                }}
                InputLabelProps={{ shrink: props.disableUnderline }}
                onFocus={() => {
                  props?.inputWhenFocus && inputChange.next('')
                }}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: props.textInputStyle || undefined
                }}
              />
            </View>
          </View>
        )}
        getOptionLabel={(o: SelectOption) => (props.buildLabel ? props.buildLabel(o) : o.label)}
        filterOptions={props.searchOptions ? identity : undefined}
        // value={props.value || selected || null}
        value={props.value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
          setOptions(newValue ? [...(Array.isArray(newValue) ? newValue : [newValue]), ...options] : options)
          // setSelected(newValue)
          if (props.onChange) {
            props.onChange(newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          // const newValue = newInputValue.replace(/[^a-z0-9]/gi, '')
          // setInputValue(newValue)
          inputChange.next(newInputValue)
        }}
        //inputValue={inputValue}
        loading={loading}
        onBlur={props.onBlur}
        noOptionsText={t('common:NoOptions')}
      />
      {props.errorMessage && <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text>}
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  }
})
