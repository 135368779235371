import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'

export const ContactUsTab = () => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{t('Setting:ContactUs')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30
  },

  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  }
})
