import React, { useState } from 'react'
import { TableCell, TableRow, makeStyles, TableBody } from '@material-ui/core'
import { RuleItem, TypeTab } from './da-rule-item'
import { ActivityIndicator, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { assets, useMobile, Permission } from '@pulseops/common'
import { AdminDeleteModal } from './AdminDeleteModal'

interface Props {
  list: RuleItem[] | null
  handleRemove: (id: string | null | undefined) => void
  handleUpdate: (item: RuleItem, index: number) => void
  isUpdate?: boolean
  typeTab?: TypeTab
  isLoading?: boolean
  tableName?: string
  permissions?: string[]
}

export const BodyTable = ({
  list,
  handleRemove,
  handleUpdate,
  isUpdate,
  typeTab,
  isLoading,
  tableName,
  permissions
}: Props) => {
  const { height } = useWindowDimensions()
  const { isWide } = useMobile()
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [item, setItem] = useState<string | null | undefined>('')
  const [index, setIndex] = useState<number>(-1)

  const useStyles = makeStyles({
    tableBody: {
      backgroundColor: '#FFFFFF',
      maxHeight: height - 340
    },
    tableCell: {
      whiteSpace: 'nowrap'
    },
    stickyRight: {
      position: 'sticky',
      right: 0,
      whiteSpace: 'nowrap',
      padding: 0
    },
    cellNote: {
      width: isWide ? 300 : 200,
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()
  const handleDelete = (item: string | null | undefined) => {
    setIsDelete(true)
    setItem(item)
    // setIndex(index)
    // handleRemove(id)
  }

  const onSubmitDelete = () => {
    handleRemove(item)
    setIsDelete(false)
  }

  const checkPermission = () => {
    return permissions && !permissions.includes(Permission.EditDSAdmin)
  }

  return (
    <>
      <TableBody className={classes.tableBody}>
        {isLoading ? (
          <TableRow>
            <TableCell style={{ textAlign: 'center' }} colSpan={12}>
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="red" />
              </View>
            </TableCell>
          </TableRow>
        ) : (
          <>
            {list && list.length > 0 ? (
              <>
                {list &&
                  list.map((item, index) => {
                    return (
                      <TableRow key={index} style={{ backgroundColor: '#FAFAFA' }}>
                        <TableCell className={classes.tableCell}></TableCell>
                        {typeTab === TypeTab.rule4 && (
                          <TableCell className={classes.tableCell}>{item.leaderCode}</TableCell>
                        )}
                        {typeTab !== TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.locationCode}</TableCell>
                        )}
                        {typeTab !== TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.officeCode}</TableCell>
                        )}
                        {typeTab === TypeTab.region && (
                          <TableCell className={classes.tableCell}>{item.regionCode}</TableCell>
                        )}
                        {typeTab === TypeTab.region && (
                          <TableCell className={classes.tableCell}>{item.regionType}</TableCell>
                        )}
                        {typeTab === TypeTab.rule8 && (
                          <TableCell className={classes.tableCell}>{item.leaderOfficeCode}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.docId}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.class}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.mainDoc}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.subDoc}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>
                            {item?.channelsMapping &&
                              item.channelsMapping.map((i, index) => (
                                <>
                                  <Text key={index}>{i.label}</Text>
                                </>
                              ))}
                          </TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.required}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.documentEn}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.documentVn}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.xCondition}</TableCell>
                        )}
                        {typeTab === TypeTab.channel && (
                          <TableCell className={classes.tableCell}>{item.suspendCode}</TableCell>
                        )}
                        <TableCell className={classes.cellNote}>{item.note}</TableCell>
                        <TableCell className={classes.stickyRight}>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                              width: isWide ? 200 : 112,
                              height: 53.02,
                              backgroundColor: '#fff',
                              marginLeft: 'auto',
                              padding: 10
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => handleUpdate(item, index)}
                              disabled={isUpdate || checkPermission()}
                              style={{ opacity: isUpdate || checkPermission() ? 0.5 : 1 }}
                            >
                              <assets.IconEdit />
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={() => handleDelete(item.id)}
                              disabled={isUpdate || checkPermission()}
                              style={{ opacity: isUpdate || checkPermission() ? 0.5 : 1 }}
                            >
                              <assets.DeleteBin />
                            </TouchableOpacity>
                          </View>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  No data
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </TableBody>
      <AdminDeleteModal
        selectedTab={1}
        groupName="ds admin"
        categoryName={tableName || ''}
        visible={isDelete}
        onPressClose={() => setIsDelete(false)}
        onPressSubmit={onSubmitDelete}
      />
    </>
  )
}
