import _ from 'lodash'
import React from 'react'
import { SC } from './styles'
import { ZIO } from '@mxt/zio'
import { PaidUpForm } from './common'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import { Input } from '@pulseops/business/core'
import { View, StyleSheet, Text } from 'react-native'
import { PolicyServiceProps } from '../policy-service-props'
import { UseFormReturn, Controller } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { PaidUp, form2, TransactionType, AppContext, PaidUpService, PulseOpsFormat } from '@pulseops/common'

type Props = PolicyServiceProps<PaidUp.SubmitData> & {
  policyNumber?: string
}

export const PaidUpScreen: React.FC<Props> = ({ policyNumber, initSubmission, isConfirmed }) => {
  const { base: form } = form2.useForm(PaidUpForm.codec, {
    defaultValues: {
      reason: '',
      requestPaidUpPeriod: ''
    }
  })
  const { showGlobalLoading, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { t } = useTranslation()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigation.navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:PAID_UP'), navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    if (policyNumber) {
      showGlobalLoading(true)
      pipe(
        PaidUpService.checkAccess(policyNumber),
        ZIO.map(({ paidToDateAdvance }) => {
          if (!!paidToDateAdvance) {
            form.setValue('requestPaidUpPeriod', paidToDateAdvance)
          }
          return form
        }),
        ZIO.tap((_) => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.tapError((_) => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [policyNumber])

  initSubmission({
    validate: async () => {
      await form.trigger()
      if (form.formState.isValid) {
        const { requestPaidUpPeriod, reason } = form.getValues()
        const data = {
          reason,
          requestPaidUpPeriod:
            requestPaidUpPeriod!! && PulseOpsFormat.dateWF(requestPaidUpPeriod, 'YYYY-MM-DD', 'YYYYMMDD')
        }
        return {
          url: () => `wf-api/policy/${policyNumber}/paid-up`,
          body: data,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PAID_UP),
          collerationId: policyNumber ?? '',
          transaction: TransactionType.PAID_UP
        }
      }
      return false
    },
    clear: () => {
      form.reset({ ...form.getValues(), reason: '' })
    }
  })

  if (isConfirmed) {
    return <ConfirmTab form={form.getValues()} />
  }
  return <RequestTab form={form} />
}

const RequestTab: React.FC<{
  form: Omit<UseFormReturn<PaidUpForm.Raw>, 'handleSubmit'>
}> = ({ form }) => {
  const {
    control,
    formState: { errors }
  } = form

  const { t } = useTranslation()
  const { requestPaidUpPeriod } = form.getValues()

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:PaidUpInformation')}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <View style={paidUpStyles.sectionRow}>
          <View style={paidUpStyles.col_4}>
            <Text style={paidUpStyles.field_title}>{t('requestInfo:RequestPaidUpPeriod')}</Text>
            <Text style={paidUpStyles.field_description}>
              {requestPaidUpPeriod!! && PulseOpsFormat.dateWF(requestPaidUpPeriod, 'YYYY-MM-DD', 'DD/MM/YYYY')}
            </Text>
          </View>
          <View style={[paidUpStyles.col_8, paidUpStyles.col8_padding]}>
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <Input
                  label={t('common:Reason')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.reason?.message}
                  placeholder={t('submission:ReasonPlaceHolder')}
                  maxLength={200}
                />
              )}
            />
          </View>
        </View>
      </SC.PanelContainer>
    </SC.Container>
  )
}

const ConfirmTab: React.FC<{ form: PaidUpForm.Raw }> = ({ form }) => {
  const { t } = useTranslation()
  const { requestPaidUpPeriod, reason } = form
  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:PaidUpInformation')}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <View style={paidUpStyles.sectionRow}>
          <View style={paidUpStyles.col_4}>
            <Text style={paidUpStyles.field_title}>{t('requestInfo:RequestPaidUpPeriod')}</Text>
            <Text style={paidUpStyles.field_description}>
              {requestPaidUpPeriod!! && PulseOpsFormat.dateWF(requestPaidUpPeriod, 'YYYY-MM-DD', 'DD/MM/YYYY')}
            </Text>
          </View>
          <View style={paidUpStyles.col_8}>
            <Text style={paidUpStyles.field_title}>{t('common:Reason')}</Text>
            <Text style={paidUpStyles.field_description}>{reason}</Text>
          </View>
        </View>
      </SC.PanelContainer>
    </SC.Container>
  )
}

const paidUpStyles = StyleSheet.create({
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_8: {
    width: '100%',
    maxWidth: '66.6666666667%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col8_padding: {
    paddingRight: 5,
    paddingLeft: 5
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  red_line: {
    color: '#ed1c2e'
  }
})
