import { ZIO } from '@mxt/zio'
import { AmlHistory, Form, FormService, Input, Shareholder } from '@pulseops/business/core'
import { Format } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const ShareholderDetailView = ({ detail }: { detail: Shareholder }) => {
  const { t } = useTranslation('business')

  const {
    getCountryOptions,
    getProvinceOptions,
    getGenderName,
    getCountryName,
    getProvinceName,
    getDistrictOptionsFull,
    getDistrictName,
    getWardOptionsFull,
    getWardName
  } = FormService

  const [viewData, setViewData] = React.useState({
    gender: '',
    nationality: '',
    otherNationality: '',

    permanentCountry: '',
    permanentCity: '',
    permanentDistrict: '',
    permanentWard: '',

    residentCountry: '',
    residentCity: '',
    residentDistrict: '',
    residentWard: '',

    overseaCountry: '',
    overseaCity: '',
    overseaDistrict: '',
    overseaWard: ''
  })

  const updateViewData = pipe(
    ZIO.zipPar(getCountryOptions, getProvinceOptions, getDistrictOptionsFull, getWardOptionsFull),
    ZIO.tap(([countryOptions, provinceOptions, districtOptionsFull, wardOptionsFull]) => {
      setViewData({
        gender: getGenderName(detail.gender),
        nationality: getCountryName(detail.nationality, countryOptions),
        otherNationality: getCountryName(detail.otherNationality, countryOptions),

        permanentCountry: getCountryName(detail.permanentAddressCountry, countryOptions),
        permanentCity: getProvinceName(detail.permanentCity, provinceOptions),
        permanentDistrict: getDistrictName(detail.permanentDistrict, detail.permanentCity, districtOptionsFull),
        permanentWard: getWardName(detail.permanentWard, wardOptionsFull),

        residentCountry: getCountryName(detail.residentialAddressCountry, countryOptions),
        residentCity: getProvinceName(detail.residentialCity, provinceOptions),
        residentDistrict: getDistrictName(detail.residentialDistrict, detail.residentialCity, districtOptionsFull),
        residentWard: getWardName(detail.residentialWard, wardOptionsFull),

        overseaCountry: getCountryName(detail.overseasAddressCountry, countryOptions),
        overseaCity: getProvinceName(detail.overseaCity, provinceOptions),
        overseaDistrict: getDistrictName(detail.overseaDistrict, detail.overseaCity, districtOptionsFull),
        overseaWard: getWardName(detail.overseaWard, wardOptionsFull)
      })
      return ZIO.unit
    })
  )

  React.useEffect(() => {
    pipe(updateViewData, ZIO.unsafeRun({}))
  }, [detail])

  return (
    <>
      <Form.Group>
        <Form.GroupTitle>{t('shareholder_info_detail')}</Form.GroupTitle>
        <Form.Row>
          <Form.Col>
            <Input label={t('full_name')} value={detail.fullName} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('dob')} value={Format.date(detail.dateOfBirth)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('gender')} value={viewData.gender} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('position')} value={detail.position} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('phone_number')} value={detail.phoneNumber} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('id_card_no')} value={detail.idNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('issue_date')} value={Format.date(detail.dateOfIssue)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('issue_place')} value={detail.placeOfIssue} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('nationality')} value={viewData.nationality} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('other_nationality')} value={viewData.otherNationality} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('visa')} value={detail.visa || '-'} readonly />
          </Form.Col>
        </Form.Row>

        <Form.Row>
          <Form.Col>
            <Input label={t('perm_address')} value={detail.permanentAddress} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('perm_address_country')} value={viewData.permanentCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.permanentCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.permanentDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.permanentWard} readonly />
          </Form.Col>
        </Form.Row>

        <Form.Row>
          <Form.Col>
            <Input label={t('curr_address')} value={detail.residentAddress} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('curr_address_country')} value={viewData.residentCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.residentCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.residentDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.residentWard} readonly />
          </Form.Col>
        </Form.Row>

        <Form.Row>
          <Form.Col>
            <Input label={t('oversea_address')} value={detail.overseaAddress || ''} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('oversea_address_country')} value={viewData.overseaCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.overseaCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.overseaDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.overseaWard} readonly />
          </Form.Col>
        </Form.Row>

        <Form.Row>
          <Form.Col>
            <Input label={t('contrib_ratio')} value={detail.capitalContributionRatio} readonly />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
      </Form.Group>

      <AmlHistory history={detail.historyAmlResult} />
    </>
  )
}
