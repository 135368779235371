import { FileUploadData, SelectOption } from '@pulseops/common'

export namespace CommonRequestFormData {
  export interface CommonRequestData {
    request: SelectOption | undefined
    documentType: DocumentType[]
  }

  export interface DocumentType {
    transactionTypeName: string
    requestName: string
    docTypeCode: string
    docTypeName: string
    mandatory: string
    attachmentFiles: FileUploadData[]
  }
}
