import { useMobile, assets } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import styled from 'styled-components/native'
import { Button } from 'react-native-elements'
import { useNavigation } from '@react-navigation/native'

export const SuccessScreen = () => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()
  const navigation = useNavigation()

  return (
    <SC.Container>
      <SC.Container style={{ width: isMobile ? '100%' : 768 }}>
        <SC.IconContainer>
          <assets.IconSuccessCheck />
        </SC.IconContainer>
        <SC.NormalText>{t('landingPage:success_message')}</SC.NormalText>
      </SC.Container>
      <SC.Footer>
        <SC.Button
          onPress={() => {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: 'HomeScreen'
                }
              ]
            })
          }}
          title={t('landingPage:confirm')}
        />
      </SC.Footer>
    </SC.Container>
  )
}

const SC = {
  Container: styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
    align-self: center;
  `,
  NormalText: styled(Text)`
    font-family: 'NotoSans_400Regular';
    font-size: 14px;
    text-align: center;
    margin-bottom: 40px;
    margin-left: 26px;
    margin-right: 26px;
  `,
  Button: styled(Button).attrs({
    buttonStyle: {
      backgroundColor: 'red',
      borderRadius: 100
    },
    titleStyle: {
      fontFamily: 'NotoSans_700Bold',
      fontSize: 15
    }
  })``,
  IconContainer: styled(View)`
    margin-bottom: 33px;
  `,
  Footer: styled(View)`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 18.5px;
    padding-left: 18.5px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
    width: 100%;
  `
}
