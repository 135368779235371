// import { form2 } from '@pulseops/common'
// import { pipe } from 'fp-ts/function'
// import i18next from 'i18next'
import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'

export type InquiryForm = {
  policyNum: string | undefined
  clientNum: string | undefined
}

export namespace InquiryNameForm {
  // const Test = t.type({
  //   policy: form2.string.required,
  //   clientNumber: form2.string.required
  // })

  const Test = t.union([
    t.type({
      policy: form2.string.required
    }),
    t.type({
      clientNumber: form2.string.required
    })
  ])

  type Test = t.TypeOf<typeof Test>

  type TCheckBrand = {
    readonly TCheck: unique symbol
  }

  export type TCheck = t.Branded<Test, TCheckBrand>

  const TCheck = pipe(
    Test,
    form2.refine(
      (l): l is TCheck => {
        // l.policy !== null || l.clientNumber !== null
        return true
      },
      (l) => i18next.t('common:dssd'),
      'TCheck'
    )
  )

  export const codec = t.type({
    form: TCheck
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
