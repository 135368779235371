export enum Category {
  ALL = 'ALL',
  COMPLAINT = 'CH',
  INQUIRY = 'CS',
  POLICY_SERVICE = 'PS',
  GENERAL = 'GI',
  CLAIM_SERVICES = 'CL',
  PREMIUM_COLLECTION = 'PC',
  DISTRIBUTION_SERVICES = 'DS',
  INDEPENDENCE_CHECK = 'IDC'
}

interface SelectOption {
  label: string
  value: string
}

export const CategoryList: SelectOption[] = [
  {
    label: 'All',
    value: Category.ALL
  },
  {
    label: 'Complaint',
    value: Category.COMPLAINT
  },
  {
    label: 'Inquiry',
    value: Category.INQUIRY
  },
  {
    label: 'PolicyServices',
    value: Category.POLICY_SERVICE
  },
  {
    label: 'General',
    value: Category.GENERAL
  }
]

export const ServiceInquiryCategories: SelectOption[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Complaint',
    value: Category.COMPLAINT
  },
  {
    label: 'Inquiry',
    value: Category.INQUIRY
  },
  {
    label: 'PolicyServices',
    value: Category.POLICY_SERVICE
  },
  {
    label: 'General',
    value: Category.GENERAL
  },
  {
    label: 'PremiumCollection',
    value: Category.PREMIUM_COLLECTION
  },
  {
    label: 'Distribution Services',
    value: Category.DISTRIBUTION_SERVICES
  }
]

export const CategoryToLabel = (category: string): string => {
  switch (category) {
    case Category.COMPLAINT:
      return 'Complaint'
    case Category.INQUIRY:
      return 'Inquiry'
    case Category.POLICY_SERVICE:
      return 'PolicyServices'
    case Category.GENERAL:
      return 'General'
    case Category.CLAIM_SERVICES:
      return 'ClaimServices'
    case Category.PREMIUM_COLLECTION:
      return 'PremiumCollection'
    case Category.DISTRIBUTION_SERVICES:
      return 'DistributionServices'
    case Category.INDEPENDENCE_CHECK:
      return 'IDC'
    default:
      return ''
  }
}
