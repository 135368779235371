import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import i18next from 'i18next'

export namespace SignatureRegistrationForm {
  const FileMeta = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type FileMeta = t.TypeOf<typeof FileMeta>

  type ListFileBrand = {
    readonly ListFile: unique symbol
  }

  type ListFile = t.Branded<FileMeta[], ListFileBrand>

  const ListFile = pipe(
    t.array(FileMeta),
    form2.refine(
      (l): l is ListFile => l.length >= 1,
      (l) => i18next.t('message:MS020001', { field: i18next.t('utilities:FileAttachment').toLowerCase() }),
      'ListFile'
    )
  )

  export const codec = t.type({
    listFile: ListFile
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
