import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { IBService, InvestmentDataInfo } from '../../../../../../ib-service'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { IBGeneralTable } from '../../../../../../common'
import { isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { PulseOpsFormat } from '@pulseops/common'
interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
}
export const InvestmentInterest = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo } = props
  const [loading, bindLoading] = useLoading()

  React.useEffect(() => {
    if (isNil(investmentInfo?.investmentInterest)) {
      const accountingCodes = [{ sacsCode: 'LE', sacsType: 'FI' }]
      pipe(
        IBService.ACMVQuery(policyNumber, 'GET_ACCOUNTING_HISTORY_BY_CODE', undefined, accountingCodes, true),
        ZIO.map((res) => {
          setInvestmentInfo({
            ...(investmentInfo as InvestmentDataInfo),
            investmentInterest: res.data?.map((item) => ({
              ...item,
              accountingAmount: item.moneySign === '-' ? (item.accountingAmount || 0) * -1 : item.accountingAmount
            }))
          })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataTable = [
    {
      label: t('EffectiveDate'),
      field: 'effectiveDate',
      format: 'date'
    },
    { label: t('TransNo'), field: 'transNo' },
    { label: t('TransactionDate'), field: 'transDate', format: 'date' },
    {
      label: t('Code'),
      field: 'transCode'
    },
    {
      label: t('AccountingAmount'),
      field: 'accountingAmount',
      format: 'money'
    }
  ]

  return (
    <View>
      <View style={{ marginVertical: 10, flexDirection: 'row' }}>
        <Text style={{ fontSize: 15, color: '#58647A', marginRight: 5, fontWeight: 'bold' }}>{t('Total')}: </Text>
        <Text style={{ fontSize: 15 }}>
          {PulseOpsFormat.thousandSepartor(
            investmentInfo?.investmentInterest?.reduce((pre, cur) => (pre = pre + (cur.accountingAmount as number)), 0)
          )}
        </Text>
      </View>
      <IBGeneralTable
        data={
          orderBy(
            investmentInfo?.investmentInterest?.map((item) => ({
              ...item,
              effectiveDate: moment(item.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              transDate: moment(item.transDate, 'DD/MM/YYYY').format('YYYYMMDD')
            })),
            ['transDate', 'transNo'],
            ['desc', 'desc']
          ) || []
        }
        dataTable={dataTable}
        loading={loading}
        autoPaging
      />
    </View>
  )
}

const styles = StyleSheet.create({})
