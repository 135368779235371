import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useForm } from 'react-hook-form'
import { claimFormDefault, IBClaimSearchForm, listClaimType, listProcessStatus } from './IBClaimSearchForm'
import { IBButton, IBGeneralField, IBGeneralTable, IBPanel, IBStyles, TypeInputComponent } from '../../../common'
import { useTranslation } from 'react-i18next'
import { AppContext, assets } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { IBClaimCaseInfo } from './IBClaimCaseInfo'
import { IBMedicalInfo } from './IBMedicalInfo'
import { IBPolicyRider } from './IBPolicyRider'
import { IBBenefitItem } from './IBBenefitItem'
import { IBReceipts } from './IBReceipts'
import { IBClaimDocuments } from './IBClaimDocuments'
import { IBPayMode } from './IBPayMode'
import { IBPaymentAllocation } from './IBPaymentAllocation'
import { IBPendingNoteList } from './IBPendingNoteList'
import { BenefitItemData, ClaimCaseDetail, MedicalInfoData, PendingNoticeData, PolicyRiderData, SearchClaimData, SearchClaimInquiry, DocumentsClaimData, PayModeData, PaymentAllocationData, ReceiptsDataList } from '../../../ib-service/IBModels'
import { pipe } from 'fp-ts/lib/function'
import { IBService } from '../../../ib-service'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { isNull } from 'lodash'

type ClaimDataShow = {
  claimNumber: string | undefined | null,
  processStatus: string | undefined | null,
  claimTypes: string | undefined | null,
  eventDates: string,
  registeredDate: string | undefined | null,
  approvalDate: string | undefined | null,
  caseCategory: string | undefined | null,
  faceAmount: number | undefined | null,
  cancelReason: string | undefined | null
}
interface Props {
  clientNumber: string
  listClaimNumbers: string[]
  setCanChangeTab: (canChangeTab: boolean) => void
  scrollRef: React.MutableRefObject<ScrollView | null>
  dataClaimInquiry: ClaimDataShow[] | null
  setDataClaimInquiry: (data: ClaimDataShow[] | null) => void
}

export const IBClientPolicyRequired = (props: Props) => {
  const { t } = useTranslation('Inbound')
  const [searchData, setSearchData] = React.useState<SearchClaimInquiry | null>(null)

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPage] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [formError, setFormError] = React.useState<string>('')

  const [claimNumberSelected, setClaimNumberSelected] = React.useState<string>('')
  const [claimCaseInfo, setClaimCaseInfo] = React.useState<ClaimCaseDetail | null>(null)
  const [medicalInfo, setMedicalInfo] = React.useState<MedicalInfoData[]>([])
  const [pendingNotelist, setPendingNotelist] = React.useState<PendingNoticeData[]>([])
  const [benefitlist, setBenefitlist] = React.useState<BenefitItemData>([])
  const [dataRiderList, setDataRiderList] = React.useState<PolicyRiderData[]>([])
  const [receiptsList, setReceiptsList] = React.useState<ReceiptsDataList[]>([])
  const [claimDocList, setClaimDocList] = React.useState<DocumentsClaimData>([])
  const [paymodeData, setPaymodeData] = React.useState<PayModeData | null>(null)
  const [paymentAllocationData, setPaymentAllocationData] = React.useState<PaymentAllocationData>([])
  const [deathDate, setDeathDate] = React.useState<string>('')
  const [firstLoad, setFirstLoad] = React.useState<boolean>(isNull(props.dataClaimInquiry) ? true : false)
  const [totalActualExpense, setTotalActualExpense] = React.useState<number>(0)

  const [isAllClose, setAllClose] = React.useState<boolean>(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const claimSearchForm = useForm<IBClaimSearchForm>({
    defaultValues: claimFormDefault
  })

  const dataTable = [
    {
      label: t('ClaimNumber'),
      field: 'claimNumber',
      render: (value:string) => {
        return (
          <Pressable onPress={() => {
            if(value !== claimNumberSelected) {
              setClaimCaseInfo(null)
              setAllClose(true)
              loadClaimCaseInfo(value)
            }
            setClaimNumberSelected(value)
          }}>
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('ProcessStatus'),
      field: 'processStatus'
    },
    {
      label: t('ClaimType'),
      field: 'claimTypes'
    },
    {
      label: t('EventDateClaim'),
      field: 'eventDates',
      disabled: true
    },
    {
      label: t('RegisterDate'),
      field: 'registeredDate',
      format: 'date'
    },
    {
      label: t('ApprovalDate'),
      field: 'approvalDate',
      format: 'date'
    },
    {
      label: t('CaseCategory'),
      field: 'caseCategory'
    },
    {
      label: t('FaceAmount'),
      field: 'faceAmount',
      format: 'money'
    },
    {
      label: t('CancelReason'),
      field: 'cancelReason'
    }
  ]

  React.useEffect(() => {
    if(searchData) {
      searchClaimInquiryData(searchData)
    }
  }, [pageNum, pageSize])

  React.useEffect(() => {
    if(!deathDate && props.clientNumber && isNull(props.dataClaimInquiry)) {
      pipe(
        IBService.getCustomerProfile(props.clientNumber),
        ZIO.map((customerData) => {
          customerData.data?.dateOfDeath && setDeathDate(customerData.data?.dateOfDeath)
          onSearch()
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [])

  React.useEffect(() => {
    medicalInfo.length > 0 && setMedicalInfo([])
    pendingNotelist.length > 0 && setPendingNotelist([])
    benefitlist.length > 0 && setBenefitlist([])
    dataRiderList.length > 0 && setDataRiderList([])
    receiptsList.length > 0 && setReceiptsList([])
    claimDocList.length > 0 && setClaimDocList([])
    paymodeData && setPaymodeData(null)
    setAllClose(false)
  }, [claimNumberSelected])

  const getClaimTypeLabel = (type: string) => {
    return listClaimType.find((i) => i.value === type)?.label ?? ''
  }

  const onSearch = claimSearchForm.handleSubmit((data) => {
    setAllClose(true)
    setClaimNumberSelected('')
    const dataSearch = {
      clientNumber: props.clientNumber ?? '',
      claimNo: data.claimNo?.value ?? '',
      claimType: data.claimType?.value ?? '',
      processStatus: data.processStatus?.value ?? '',
      pageIndex: pageNum,
      itemsPerPage: pageSize,
      orders: ['registeredDate'],
      sort: 'DESC'
    } as SearchClaimInquiry
    setSearchData(dataSearch)
    searchClaimInquiryData(dataSearch)
    firstLoad && setFirstLoad(false)
  })

  const getEventDate = (data: SearchClaimData) => {
    const getDate = (claimType: string) => {
      switch (claimType) {
        case 'ACC':
          return data.accidentEventDate ?? ''
        case 'CIL':
          return data.criticalIllnessEventDate ?? ''
        case 'DTH':
          return data.lifeEventDate ?? ''
        case 'TPD':
          return data.disabilityEventDate ?? ''
        case 'MED':
          return data.medicalEventDate ?? ''
        default: 
          return ''
      }
    }
    let eventDates = ''
    data.claimTypes && data.claimTypes.map((claim) => {
      eventDates = eventDates ? (eventDates + '; ' + getDate(claim)) : getDate(claim)
    })
    return eventDates
  }

  const getClaimLabel = (claims: string[]) => {
    let label = ''
    claims.map((claim) => {
      label = label ? (label + '; ' + getClaimTypeLabel(claim)) : getClaimTypeLabel(claim)
    })
    return label
  }

  const searchClaimInquiryData = (data: SearchClaimInquiry) => {
    data.pageIndex = pageNum
    data.itemsPerPage = pageSize
    showGlobalLoading(true)
    pipe(
      IBService.searchClaim(data),
      ZIO.map((res) => {
        if(res.records) {
          if(res.records.length > 0) {
            props.setDataClaimInquiry(res.records?.map((record) => ({
              ...record,
              registeredDate: record.registeredDate ? moment(record.registeredDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
              approvalDate: record.approvalDate ? moment(record.approvalDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
              eventDates: getEventDate(record),
              claimTypes: record.claimTypes && getClaimLabel(record.claimTypes)
            })))
          }
          else props.setDataClaimInquiry([])
          
          res.paging?.total && setTotalItem(res.paging?.total)
          if(res.records.length === 0) setFormError(t('message:MS030029'))
          else setFormError('')
        }
        else props.setDataClaimInquiry([])
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showGlobalLoading(false)
        setFormError(t('message:MS030029'))
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const loadClaimCaseInfo = (claimNumber: string) => {
    if(claimNumber) {
      showGlobalLoading(true)
      pipe(
        IBService.getClaimCaseDetail(claimNumber),
        ZIO.map((res) => {
          res && setClaimCaseInfo({
            ...res,
            claimEvents: res.claimEvents?.map((i) => ({
              ...i,
              cancelReason: (i.cancelReasonCode ?? '') + ' ' + (i.cancelReason ?? ''),
              confirmedDate: 
                i.confirmedDate ? moment(i.confirmedDate, 'DD/MM/YYYY').format('YYYYMMDD') :
                i.occurenceDate ? moment(i.occurenceDate, 'DD/MM/YYYY').format('YYYYMMDD') : ''
            }))
          })
          res && setTotalActualExpense(res.medicalClaimAmount ?? 0)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.catchAll((error) => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false} ref={props.scrollRef}>
      {deathDate && <Text style={{ color: 'red', fontWeight: 'bold', margin: 15, marginBottom: 0, fontSize: 15 }}>{`${t('DeathDate')}: ${deathDate}`}</Text>}
      <View style={styles.searchContainer}>
        <IBGeneralField
          FieldForm={claimSearchForm}
          col={3}
          typeInput={[
            {
              type: TypeInputComponent.SELECT,
              formName: 'claimNo',
              title: t('ClaimNumber'),
              option: props.listClaimNumbers.map((i) => ({
                label: i,
                value: i
              }))
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'claimType',
              title: t('ClaimType'),
              option: listClaimType
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'processStatus',
              title: t('ProcessStatus'),
              option: listProcessStatus
            }
          ]}
        />
        <View style={styles.buttonContainer}>
          <IBButton
            onPress={onSearch}
            title={t('Search')}
            suffixIcon={<assets.IBSearchIcon />}
            backgroundFill
            disabled={firstLoad}
          />
        </View>
      </View>
      {props.dataClaimInquiry && 
        <View style={[{ marginLeft: 15 }, IBStyles.container]}>
          <Text style={[IBStyles.header, { paddingBottom: 15 }]}>{t('Result')}</Text>
          <IBGeneralTable
            dataTable={dataTable}
            data={props.dataClaimInquiry}
            paginationStyle={{ marginRight: 60 }}
            paging={{
              page: pageNum,
              pageSize: pageSize,
              taskCount: totalItem,
              setPage: setPage,
              setPageSize: setPageSize
            }}
          />
        </View>
      }
      
      {claimNumberSelected &&
        <View style={[{ marginLeft: 15 }, IBStyles.container]}>
          <Text style={{ color: 'red', fontWeight: 'bold', fontSize: 15 }}>{`Claim#: ${claimNumberSelected}`}</Text>
          <IBPanel
            title={t('ClaimCaseInfo')}
            intialToggle={true}
            children={
              <IBClaimCaseInfo 
                claimCaseInfo={claimCaseInfo}
                setClaimCaseInfo={setClaimCaseInfo}
                claimNumber={claimNumberSelected}
              />
            }
          />
          <IBPanel
            title={t('MedicalInfo')}
            close={isAllClose}
            children={
              <IBMedicalInfo
                medicalInfo={medicalInfo}
                setMedicalInfo={setMedicalInfo}
                claimNumber={claimNumberSelected}
                totalActualExpense={totalActualExpense}
              />
            }
          />
          <IBPanel
            title={t('PendingNoticeList')}
            close={isAllClose}
            children={
              <IBPendingNoteList
                pendingNotelist={pendingNotelist}
                setPendingNotelist={setPendingNotelist}
                claimNumber={claimNumberSelected}
              />
            }
          />
          <IBPanel
            title={t('PolicyRider')}
            close={isAllClose}
            children={
              <IBPolicyRider
                dataRiderList={dataRiderList}
                setDataRiderList={setDataRiderList}
                claimNumber={claimNumberSelected}
              />
            }
          />
          <IBPanel
            title={t('BenefitItem')}
            close={isAllClose}
            children={
              <IBBenefitItem
                benefitlist={benefitlist}
                setBenefitlist={setBenefitlist}
                claimNumber={claimNumberSelected}
              />
            }
          />
          <IBPanel
            title={t('Receipts')}
            close={isAllClose}
            children={
              <IBReceipts
                receiptsList={receiptsList}
                setReceiptsList={setReceiptsList}
                claimNumber={claimNumberSelected}
              />
            }
          />
          <IBPanel
            title={t('ClaimDocuments')}
            close={isAllClose}
            children={
              <IBClaimDocuments
                claimDocList={claimDocList}
                setClaimDocList={setClaimDocList}
                claimNumber={claimNumberSelected}
              />
            }
          />
          <IBPanel
            title={t('PayMode')}
            close={isAllClose}
            children={
              <IBPayMode
                paymodeData={paymodeData}
                setPaymodeData={setPaymodeData}
                claimNumber={claimNumberSelected}
              />
            }
          />
          {/* <IBPanel
            title={t('PaymentAllocation')}
            close={isAllClose}
            children={
              <IBPaymentAllocation
                paymentAllocationData={paymentAllocationData ?? []}
                setPaymentAllocationData={setPaymentAllocationData}
                claimNumber={claimNumberSelected}
              />
            }
          /> */}
        </View>
      }
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    width: '100%'
  }
})