import { Color } from '@material-ui/lab'
import { ZIO } from '@mxt/zio'
import {
  AuthService,
  ClaimUpdateService,
  ErrorHandling,
  GeneralService,
  PulseOpsService,
  RequestAuthOption,
  SubTasksResponse,
  TaskDetail
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as T from 'io-ts'
import { PolicyRider } from '../container/claim-info/claim-type/ClaimInfoForm'
import moment from 'moment'
export type ShowToastFunc = (message: string, type?: Color | undefined) => void
type Translate = (val: string) => string
export type SetCompleteOpenFunc = (val: boolean) => void
export type ShowLoadingFunc = (isLoading: boolean) => void

enum ClaimType {
  CRITICAL_ILLNESS = 'CT0100',
  DEATH = 'CT0200',
  MEDICAL = 'CT0300',
  DISABILITY = 'CT0400'
}
export const SaveClaimReceiptInfo =
  (showToast: ShowToastFunc, showLoading: ShowLoadingFunc, t: Translate) =>
  (detailData: TaskDetail.Type, receiptInformation: any) => {
    // RECEIPT INFO SUBMIT
    const detail: TaskDetail.VerificationClaim = detailData.payload as TaskDetail.VerificationClaim
    if (detail.claimType === ClaimType.MEDICAL) {
      // const receiptSubmited = receiptInformation.map((receipt) => {
      //   const { isToggle, ...rest } = receipt
      //   return {
      //     ...rest,
      //     receiptItemCode: receipt.receiptItemCode?.value || '',
      //     surgeryCode: receipt.surgeryCode?.value || ''
      //   }
      // })
      showLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.flatMap(({ email }) =>
          ClaimUpdateService.saveReceiptInfo(T.unknown)({
            data: receiptInformation,
            processInstanceId: detailData.processId,
            user: email
          })
        ),
        ZIO.catchAll((e) => {
          showLoading(false)
          showToast(`${t('message:MS070007')}`, 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          showLoading(false)
          showToast(`${t('message:MS990017')}`, 'success')
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }
export const SaveClaimPolicyRider =
  (showToast: ShowToastFunc, showLoading: ShowLoadingFunc, t: Translate) =>
  (detailData: TaskDetail.Type, policyRiders: PolicyRider[], setSaveData: (val: boolean) => void) => {
    let parSubmited: PolicyRider[] = []
    parSubmited =
      policyRiders && policyRiders.filter((policyRider) => policyRider.eligible && policyRider.claimAmount !== '')
    if (parSubmited.length > 0) {
      showLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.flatMap(({ email }) =>
          ClaimUpdateService.savePolicyRider(T.unknown)({
            data: parSubmited,
            processInstanceId: detailData.processId,
            user: email
          })
        ),
        ZIO.tap(() => {
          setSaveData(true)
          showToast(`${t('message:MS990017')}`, 'success')
          showLoading(false)
          return ZIO.unit
        }),
        ZIO.catchAll((e) =>
          ZIO.effectTotal(() => {
            setSaveData(false)
            showLoading(false)
          })
        ),
        ZIO.unsafeRun({})
      )
    } else {
      setSaveData(false)
      showToast(`${t('message:MS020001') + t('ClaimAmount')}`, 'error')
    }
  }

export const SaveClaimPaymentMode =
  (showToast: ShowToastFunc, showLoading: ShowLoadingFunc, t: Translate) =>
  (detailData: TaskDetail.Type, payeeIsNotValid: boolean, setSaveData: (val: boolean) => void) => {
    const detail: TaskDetail.VerificationClaim = detailData.payload as TaskDetail.VerificationClaim
    let payment = detail?.PAYMENT ? JSON.parse(JSON.stringify(detail?.PAYMENT)) : {}
    let validDate = moment(payment.dob, 'DD/MM/YYYY', true).isValid()

    showLoading(true)
    pipe(
      AuthService.userInfo,
      ZIO.flatMap(({ email }) =>
        ClaimUpdateService.savePaymentMethod(T.unknown)({
          data: [
            {
              paymentModeCode: detail.PAYMENT.paymentMethod,
              payeeIsNotValid,
              payment: {
                ...payment,
                dob: payment.dob
                  ? validDate
                    ? moment(payment.dob, 'DD/MM/YYYY').format('YYYY/MM/DD')
                    : payment.dob
                  : moment().format('YYYY/MM/DD')
              }
            }
          ],
          processInstanceId: detailData.processId,
          user: email
        })
      ),
      ZIO.tap(() => {
        setSaveData(true)
        showToast(`${t('message:MS990017')}`, 'success')
        showLoading(false)
        return ZIO.unit
      }),
      ZIO.catchAll((e) =>
        ZIO.effectTotal(() => {
          setSaveData(false)
          showLoading(false)
        })
      ),
      ZIO.unsafeRun({})
    )
  }

export const CompleteClaim =
  (showToast: ShowToastFunc, showLoading: ShowLoadingFunc, t: Translate) =>
  (
    detail: TaskDetail.Type,
    totalFaceAmount: number,
    claimType: string,
    subClaimType: string,
    newPaymentRef?: string,
    pvType?: string
  ) =>
  (onSuccess: () => void) => {
    let subTaskCode = ''
    let conGoCompleteAction = true
    const isNotAuthPaper =
      subTaskCode !== SubTasksResponse.CL_VER ? true : detail?.authenOption === RequestAuthOption.PAPER ? false : true
    showLoading(true)
    pipe(
      isNotAuthPaper ? ZIO.succeed(true) : GeneralService.authFormUserCheckFlag(detail?.processId || ''),
      ZIO.flatMap((res) => {
        if (conGoCompleteAction) {
          if (detail.id) {
            return pipe(
              AuthService.userInfo,
              ZIO.flatMap((res) =>
                PulseOpsService.completeTask({
                  taskId: detail.id,
                  data: {
                    author: res.email,
                    message: ''
                  },
                  updateToCore: detail.updateToCore,
                  totalFaceAmount: totalFaceAmount,
                  claimType: claimType,
                  subClaimType: subClaimType,
                  newPaymentRef: newPaymentRef,
                  pvType: pvType
                })
              )
            )
          }
        }
        return ZIO.unit
      }),
      ZIO.catchAll((_error) => {
        showLoading(false)
        showToast('Network error, please try again', 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap((value) => {
        pipe(GeneralService.triggerActionClaim(detail.caseId), ZIO.unsafeRun({}))
        showLoading(false)
        showToast(t('TaskManagement:CompleteSuccessful'), 'success')
        onSuccess()
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

export const onCompleteAdjudication =
  (setToast: (val: { open: boolean; message: string; type?: Color }) => void, t: (val: string) => string) =>
  (subtask: SubTasksResponse, subTaskCode: string, detail: TaskDetail.Type) =>
  (setCompleteOpen: (val: boolean) => void) =>
  () => {
    let canGoCompleteAction = true
    const { authenOption, processId } = detail
    const isNotAuthPaper = subTaskCode !== 'CL_ADJ' ? true : authenOption === RequestAuthOption.PAPER ? false : true

    // const { payload } = detail
    pipe(
      isNotAuthPaper ? ZIO.succeed(true) : GeneralService.authFormUserCheckFlag(processId),
      ZIO.flatMap((x) => {
        if (!x) {
          setToast({
            open: true,
            message: t('message:MS050261'),
            type: 'error'
          })
        }
        if (canGoCompleteAction) {
          setCompleteOpen(true)
        }
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }
