import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import React from 'react'
import { View, StyleSheet, TouchableOpacity, Text } from "react-native"
import { OBInternalSuspendForm } from "./OBInternalSuspendForm"
import { AppContext, CustomedDateTimePicker, ErrorHandling, Input, InternalConst, InternalFeedbackService, Select, SelectOption, SelectSearch, Title, assets, sharedStyle } from "@pulseops/common"
import { FieldText, SectionCol, SectionContent, SectionRow } from "@pulseops/submission/common"
import { InternalSuspendInformationConst, InternalUserInfo, Status } from "@pulseops/task"
import moment from "moment"
import { pipe } from 'fp-ts/lib/function'
import { useIsFocused } from "@react-navigation/native"
import { ZIO } from "@mxt/zio"
import { useLoading } from "@mxt/zio-react"
import { CallOutDetailContext, DataSuspend, DataSuspendSave, OBInternalRulesListData, OBInternalSuspendService, OBSharedStyles, OBSuspendCodeData, T6S2EscalationRuleResponse } from "@pulseops/outbound"
import * as O from 'fp-ts/lib/Option'

type Props = {
  disabled: boolean
  processInstanceId: string
  caseId: string
  userInfo: InternalUserInfo
  suspendTypeList: OBSuspendCodeData[]
  workingTimeList: InternalFeedbackService.WorkingTimeInfo[]
  listDataEmailRules: OBInternalRulesListData[]
}

export const OBInternalSuspendInfoScreen = ({
  disabled,
  processInstanceId,
  caseId,
  userInfo,
  suspendTypeList,
  workingTimeList,
  listDataEmailRules
}: Props) => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  // const [dataRuleInfo, setDataRuleInfo] = React.useState<OBInternalRulesListData>()
  const [dataRuleInfo, setDataRuleInfo] = React.useState<T6S2EscalationRuleResponse>()
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { setSuspendEnable, isSuspendEnable } = React.useContext(CallOutDetailContext)
  const [SLATimeout, setSLATimeout] = React.useState<string>('')
  const internalSuspendForm = useForm<OBInternalSuspendForm.InternalSuspendList>({
    defaultValues: {
      suspendList: []
    },
    mode: 'onChange'
  })
  const { control, watch, setValue, trigger, clearErrors } = internalSuspendForm
  const { fields, append, remove } = useFieldArray<OBInternalSuspendForm.InternalSuspendList>({
    control: control,
    name: 'suspendList'
  })

  const validateMail = (mail:string) => {
    if (/^\w+([\.-]?\w)*@\w+([\.-]?\w)*(\.\w{2,3})$/.test(mail)) {
      return true
    }
    return false
  }

  const validateMailPru = (mail: string) => {
    if (/^\w+([\.-]?\w)*@prudential.com.vn$/.test(mail)) {
      return true
    }
    return false
  }

  const ruleList = {
    suspendType: {
      required: {
        value: true,
        message: t('message:MS020009', { field: t('InternalSuspend:SuspendType') })
      },
    },
    effectiveTime: {
      validate: (value: Date) => {
        if (!moment(value).isValid()) return t('form:error_invalid_date')
        if (moment(value).valueOf() < moment().valueOf()) return t('message:MS100011')
        return true
      }
    },
    to: {
      required: {
        value: true,
        message: t('message:MS020001', { field: t('To') })
      },
      validate: (value: string) => {
        if (value.endsWith(';') || value.endsWith('.') || value.endsWith(' ') || value.includes(' ')) return t('message:OB0028')
        const listMail = value.split(';')
        if (listMail.length > 0) {
          let checkError = listMail.find((item) => !validateMail(item.trim()))
          let checkErrorNotMailPru = listMail.find((item) => !validateMailPru(item.trim()))
          if (checkError) return t('message:OB0028')
          if (checkErrorNotMailPru) return t('message:OB0052')
        }
        return true
      }
    },
    cc: {
      required: {
        value: true,
        message: t('message:MS020001', { field: t('CC') })
      },
      validate: (value: string) => {
        if (value.endsWith(';') || value.endsWith('.') || value.endsWith(' ') || value.includes(' ')) return t('message:OB0028')
        const listMail = value.split(';')
        if (listMail.length > 0) {
          let checkError = listMail.find((item) => !validateMail(item.trim()))
          let checkErrorNotMailPru = listMail.find((item) => !validateMailPru(item.trim()))
          if (checkError) return t('message:OB0028')
          if (checkErrorNotMailPru) return t('message:OB0052')
        }
        return true
      }
    },
    content: {
      required: {
        value: true,
        message: t('message:MS020001', { field: t('InternalSuspend:Content') })
      },
    },
    level: {
      required: {
        value: true,
        message: t('message:MS020001', { field: t('InternalSuspend:Level') })
      }
    }
  }

  React.useEffect(() => {
    if (isFocused) {
      addNewSuspendItem()
    }
    return () => {
      remove()
      setSLATimeout('')
    }
  }, [])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const findDescription = (code: string) =>
    InternalSuspendInformationConst.levelList.find((item) => item.code === code)?.description ?? ''

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const convertArrayToOption = (dataList: InternalSuspendInformationConst.LevelInfoData[]) => {
    return dataList.map((level) => ({
      value: level.code,
      label: i18n.language === 'en' ? level.description : level.descriptionVi
    }))
  }

  const addNewSuspendItem = () => {
    const defaultLevelItem = convertArrayToOption(InternalSuspendInformationConst.levelList).find(
      (x) => x.value === 'LV01'
    )
    const defaultStatus = InternalSuspendInformationConst.statusList().find((x) => x.value === Status.ACTIVE)
    userInfo && setValue('userEmail', userInfo.email)
    const effectiveTime = moment(new Date()).add(15, 'minutes').toDate()
    const dueDate = effectiveTime && moment(effectiveTime).format('DD/MM/YYYY HH:mm')
    append({
      suspendType: undefined,
      departmentCode: '',
      departmentName: '',
      level: defaultLevelItem,
      status: defaultStatus,
      SLA: '',
      effectiveTime: effectiveTime,
      dueDateText: dueDate,
      dueDateVal: effectiveTime.toISOString(),
      to: '',
      cc: '',
      content: ''
    })
  }

  const mappingNewInternalSuspendData = () => {
    const timeZone = Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).resolvedOptions().timeZone
    const internalSuspendList = watch('suspendList').map((suspendItem) => {
      const effectiveTime = suspendItem.effectiveTime?.toISOString()
      const newInternalSuspend: DataSuspend = {
        status: suspendItem.status?.value ?? '',
        suspendCode: suspendItem.suspendType?.value ?? '',
        user: userInfo.email,
        slaHour: suspendItem.SLA,
        effectiveDate: effectiveTime ?? '',
        dueDate: suspendItem.dueDateVal ?? '',
        department: suspendItem.departmentShortName,
        level: suspendItem.level?.value ?? '',
        content: suspendItem.content ?? '',
        emailTo: suspendItem.to ?? '',
        emailCc: suspendItem.cc ?? '',
        timeZone: timeZone
      }
      return newInternalSuspend
    })
    const submitedInternalSuspendData: DataSuspendSave = {
      data: internalSuspendList,
      processInstanceId: processInstanceId
    }
    return submitedInternalSuspendData
  }

  const editSLAByLevel = (index: number, slaData: T6S2EscalationRuleResponse) => {
    let SLAValue = ''
    const levelItem = watch(`suspendList.${index}.level`)?.value
    switch (levelItem) {
      case 'LV01':
        SLAValue = slaData?.slaHourNormal ?? ''
        setValue(`suspendList.${index}.SLA`, SLAValue)
        break
      case 'LV02':
        SLAValue = slaData?.slaHourUrgent ?? ''
        setValue(`suspendList.${index}.SLA`, SLAValue)
        break
      case 'LV03':
        SLAValue = slaData?.slaHourCritical ?? ''
        setValue(`suspendList.${index}.SLA`, SLAValue)
        break
      default:
        break
    }
    return SLAValue
  }

  const getEmailsTOString = (emails: string[]) => {
    let temp = ''
    emails.map((item, index) => {
      temp += index !== 0 ? (';' + item) : item
    })
    return temp
  }

  const getToEmailList = (departmentShortName: string, index: number, isToEmail: boolean) => {
    const levelCode = watch(`suspendList.${index}.level`)?.value ?? ''
    const departmentCode = watch(`suspendList.${index}.departmentCode`) ?? ''
    const levelDescription = findDescription(levelCode)
    const mailGroupCheck = levelDescription + (isToEmail ? '-TO' : '-CC')
    if (departmentShortName && levelDescription) {
      pipe(
        OBInternalSuspendService.getMailList(departmentCode),
        ZIO.map((emailList) => {
          if (!!emailList && emailList.length > 0) {
            const emails = emailList.find((x) => x?.departmentCode === departmentCode && x.mailGroup?.includes(mailGroupCheck))?.emails ?? []
            // console.log(emailList.find((x) => x?.departmentCode === departmentCode && x.mailGroup?.includes(mailGroupCheck)), mailGroupCheck, 'mailGroupCheck')
            if (isToEmail) {
              setValue(`suspendList.${index}.to`, getEmailsTOString(emails))
              clearErrors(`suspendList.${index}.to`)
            }
            else {
              setValue(`suspendList.${index}.cc`, getEmailsTOString(emails))
              clearErrors(`suspendList.${index}.cc`)
            }
          } else {
            isToEmail && setValue(`suspendList.${index}.to`, '')
            !isToEmail && setValue(`suspendList.${index}.cc`, '')
          }
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  const validateInternalSuspendDataForSave = () => {
    let isDuplicatedDepartment = false
    const internalSuspendList = watch('suspendList')
    for (let i = 0; i < internalSuspendList.length - 1; i++) {
      const historyItem = internalSuspendList[i]
      const duplicationFlag = internalSuspendList.some(
        (oldItem, index) =>
          i !== index &&
          historyItem.departmentCode === oldItem.departmentCode &&
          historyItem.status?.value === Status.ACTIVE &&
          oldItem.status?.value === Status.ACTIVE
      )
      if (duplicationFlag) {
        isDuplicatedDepartment = true
        break
      }
    }
    return isDuplicatedDepartment
  }

  const setDueDate = (SLAValue: string, index: number, effectiveTime: Date | null, isSLAMinuteUnit?: boolean) => {
    // calculate dueDate base on effectiveTime,SLA,working time list
    let nextDay = ''
    let estimatedTime: moment.Moment = moment()
    let originSLAMinutes = isSLAMinuteUnit ? Number(SLAValue) : Number(SLAValue) * 60
    let originEffectiveTime = moment(effectiveTime)
    for (let i = 0; i < workingTimeList.length && i + 1 < workingTimeList.length; i++) {
      // calculate in working time
      if (!!workingTimeList[i].startTime && !!workingTimeList[i].endTime) {
        const startDate = workingTimeList[i].date + ' ' + workingTimeList[i].startTime
        const endDate = workingTimeList[i].date + ' ' + workingTimeList[i].endTime
        // if startDate > originEffectiveTime, We set value variable originEffectiveTime to equal with startdate
        if (moment(startDate).valueOf() >= originEffectiveTime.valueOf()) {
          const newEffectiveValue = moment(startDate).valueOf() - originEffectiveTime.valueOf()
          originEffectiveTime = moment(originEffectiveTime.valueOf() + newEffectiveValue)
        }
        if (moment(endDate).valueOf() > originEffectiveTime.valueOf()) {
          const SLAHour = Math.floor(originSLAMinutes / 60)
          const SLAMinutes = Math.round((originSLAMinutes / 60 - SLAHour) * 60)
          estimatedTime = moment(originEffectiveTime).add(SLAHour, 'hours').add(SLAMinutes, 'minutes')
          // if estimatedTime less than endDate, we get valid estimatedTime and  break loop
          if (moment(endDate).valueOf() >= estimatedTime.valueOf()) {
            break
          }
          // else we get distanceMinutes and set originEffectiveTime = nextDay
          const distanceMinutes = moment(endDate).diff(originEffectiveTime, 'minutes')
          nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
          originEffectiveTime = moment(nextDay)
          originSLAMinutes = originSLAMinutes - distanceMinutes
        } else {
          // if originEffectiveTime > endDate, we will set originEffectiveTime = next working day
          if (workingTimeList[i + 1].startTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          } else if (workingTimeList[i + 1].endTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].endTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          }
        }
      }
    }
    const dueDate = !!effectiveTime ? estimatedTime.format('DD/MM/YYYY HH:mm') : ''
    setValue(`suspendList.${index}.dueDateText`, dueDate)
    setValue(`suspendList.${index}.dueDateVal`, estimatedTime.toISOString())
  }

  const onChangeLevelEvent = (index: number) => {
    const departmentShortName = watch(`suspendList.${index}.departmentShortName`)
    let SLAValue = dataRuleInfo ? editSLAByLevel(index, dataRuleInfo) : ''
    getToEmailList(departmentShortName, index, true)
    getToEmailList(departmentShortName, index, false)
    const isSLAMinuteUnit = !!SLATimeout && !SLAValue ? true : false
    SLAValue = !!SLAValue ? SLAValue : SLATimeout
    setDueDate(SLAValue, index, watch(`suspendList.${index}.effectiveTime`), isSLAMinuteUnit)
  }

  const onChangeEffectiveTimeEvent = (timeValue: Date | null, index: number) => {
    if (moment(timeValue).isValid()) {
      let SLAValue = !!watch(`suspendList.${index}.SLA`) ? watch(`suspendList.${index}.SLA`) : SLATimeout
      const isSLAMinuteUnit = !!SLATimeout ? true : false
      setDueDate(SLAValue, index, timeValue, isSLAMinuteUnit)
    }
  }

  const onChangeSuspendTypeEvent = (suspendCode: string, index: number) => {
    pipe(
      OBInternalSuspendService.getT6S2EscalationRule(suspendCode),
      ZIO.map((responseData) => {
        const findSuspendData = suspendTypeList.find((item) => item.code === suspendCode)
        const timeout = findSuspendData?.timeout
        if (suspendTypeList.length && findSuspendData) {
          // const data = listDataEmailRules.find(item => item.departmentShortName === findSuspendData.departmentShortName)
          const departmentName = i18n.language === 'vi' ? findSuspendData.departmentVN : findSuspendData.department
          const fullDepartmentName = findSuspendData.departmentShortName + '-' + departmentName
          setValue(`suspendList.${index}.departmentShortName`, findSuspendData.departmentShortName ?? '')
          setValue(`suspendList.${index}.departmentCode`, findSuspendData.departmentCode ?? '')
          setValue(`suspendList.${index}.departmentName`, fullDepartmentName)
          !watch(`suspendList.${index}.level`) && setValue(`suspendList.${index}.level`, pipe(
            InternalSuspendInformationConst.levelList.find((x) => x.code === 'LV01'),
            O.fromNullable,
            O.map((levelItem) => ({ value: levelItem.code, label: i18n.language === 'en' ? levelItem.description : levelItem.descriptionVi })),
            O.getOrElse(() => ({ value: '', label: '' }))
          ))
          getToEmailList(findSuspendData.departmentShortName ?? '', index, true)
          getToEmailList(findSuspendData.departmentShortName ?? '', index, false)
          if (responseData && responseData.length > 0) {
            const SLAValue = editSLAByLevel(index, responseData[0])
            setDataRuleInfo(responseData[0])
            setDueDate(SLAValue, index, watch(`suspendList.${index}.effectiveTime`))
            setSLATimeout('')
          }
          else {
            setValue(`suspendList.${index}.SLA`, '')
            setValue(`suspendList.${index}.level`, null)
            setDataRuleInfo(undefined)
            if (timeout) {
              setSLATimeout(timeout)
              setDueDate(timeout, index, watch(`suspendList.${index}.effectiveTime`), true)
            } else {
              setSLATimeout('')
              setDueDate('', index, watch(`suspendList.${index}.effectiveTime`))
            }

          }
        }
        return responseData
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const isDisableLevelField = (index: number) => {
    return !watch(`suspendList.${index}.level`) && !watch(`suspendList.${index}.SLA`)
  }

  const deleteSuspendItem = (index: number) => {
    remove(index)
  }

  const cancelButtonEvent = () => {
    const defaultLevelItem = convertArrayToOption(InternalSuspendInformationConst.levelList).find(
      (x) => x.value === 'LV01'
    ) ?? { label: '', value: '' }
    const defaultStatus = InternalSuspendInformationConst.statusList().find((x) => x.value === Status.ACTIVE) ?? {
      label: '',
      value: ''
    }
    const effectiveTime = moment(new Date()).add(15, 'minutes').toDate()
    const dueDate = moment(new Date()).add(15, 'minutes').format('DD/MM/YYYY HH:mm')
    const suspendItem = {
      suspendType: null,
      departmentCode: '',
      departmentName: '',
      departmentShortName: '',
      level: null,
      status: defaultStatus,
      SLA: '',
      effectiveTime: effectiveTime,
      dueDateText: dueDate,
      dueDateVal: effectiveTime.toISOString(),
      to: '',
      cc: '',
      content: ''
    }
    let i = watch('suspendList').length - 1
    while (i > 0) {
      remove(i)
      i--
    }
    setValue(`suspendList.${i}`, suspendItem)
    internalSuspendForm.clearErrors()
  }

  const setStateShowSuspend = () => {
    if (caseId && !isSuspendEnable) {
      pipe(
        ZIO.zipPar(
          InternalFeedbackService.getCaseStatusByCaseID(caseId),
          OBInternalSuspendService.getInternalSuspendHistoryListOB(caseId),
        ),
        ZIO.map(([caseStatus, historyList]) => {
          const status = historyList && historyList?.find(item => item.status === InternalConst.Status.ACTIVE)
          setSuspendEnable(!!status && caseStatus !== 'Pending')
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  const saveInternalSuspend = async () => {
    const isValidForm = await trigger()
    const isDuplicatedDepartment = validateInternalSuspendDataForSave()
    const internalSuspendList = watch('suspendList')
    if (isValidForm && !isDuplicatedDepartment && internalSuspendList.length > 0) {
      const newInternalSuspendData = mappingNewInternalSuspendData()
      pipe(
        OBInternalSuspendService.saveInternalSuspend(newInternalSuspendData),
        ZIO.map((responseData) => {
          if (!!responseData) {
            if (responseData.responseError.status === 'Success') {
              // setStateShowSuspend()
              const status = internalSuspendList && internalSuspendList?.find(item => item.status.value === InternalConst.Status.ACTIVE)
              setSuspendEnable(!!status)
              cancelButtonEvent()
              showToast(t('message:MS990017'), 'success')
            } else {
              showToast(t(`message:${responseData.responseError.message}`), 'error')
            }
          }
          // return responseData
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    } else if (isDuplicatedDepartment) {
      showToast(t(`message:MS050270`), 'error')
    }
  }

  return (
    <SectionContent>
      <Text style={internalInfoStyles.header}>{t('InternalSuspend:AddInternalSuspend')}</Text>
      {fields &&
        fields.length > 0 &&
        fields.map((fieldItem, index) => (
          <SectionContent key={fieldItem.id}>
            <SectionRow>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.suspendType`}
                  rules={ruleList.suspendType}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      required={true}
                      label={t('InternalSuspend:SuspendType')}
                      options={suspendTypeList && suspendTypeList.map((item) => ({
                        value: item.code,
                        label: i18n.language === 'en' ? item.typeEn : item.typeVn
                      }))}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      onChange={(val) => {
                        onChange(val)
                        onChangeSuspendTypeEvent(val?.value ?? '', index)
                      }}
                      placeholder={showOptionLanguage()}
                      disabled={disabled}
                      onBlur={onBlur}
                      value={value}
                      key={value?.value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol>
                <FieldText text={t('InternalSuspend:Department')}></FieldText>
                <FieldText
                  fieldStyles={internalInfoStyles.field_description}
                  text={watch(`suspendList.${index}.departmentName`)}
                ></FieldText>
              </SectionCol>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.level`}
                  // rules={ruleList.level}
                  rules={{
                    validate: (optionItem: SelectOption | null) => {
                      if (!optionItem && !watch(`suspendList.${index}.SLA`)) {
                        return true
                      } else if (!optionItem || !optionItem.value) {
                        return `${t('message:MS020001', { field: t('InternalSuspend:Level') })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <SelectSearch
                      label={t('InternalSuspend:Level')}
                      options={convertArrayToOption(InternalSuspendInformationConst.levelList)}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      disabled={disabled || isDisableLevelField(index)}
                      onChange={(val) => {
                        onChange(val)
                        onChangeLevelEvent(index)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={!!value?.value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.status`}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Select
                      label={t('common:Status')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={disabled}
                      options={InternalSuspendInformationConst.statusList().filter((p) => p.value === Status.ACTIVE)}
                      errorMessage={!!value?.value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
              <SectionCol>
                <FieldText text={t('InternalSuspend:ServiceLevelAgreement')}></FieldText>
                <FieldText
                  fieldStyles={internalInfoStyles.field_description}
                  text={watch(`suspendList.${index}.SLA`)}
                ></FieldText>
              </SectionCol>
              <SectionCol>
                <Controller
                  control={control}
                  name={`suspendList.${index}.effectiveTime`}
                  rules={ruleList.effectiveTime}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <CustomedDateTimePicker
                      label={t('InternalSuspend:EffectiveTime')}
                      alwaysShow={true}
                      disabled={disabled}
                      onChange={(val) => {
                        onChange(val)
                        onChangeEffectiveTimeEvent(val, index)
                      }}
                      onBlur={onBlur}
                      value={value}
                      minDate={new Date()}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol>
                <FieldText text={t('InternalSuspend:DueDate')}></FieldText>
                <FieldText
                  fieldStyles={internalInfoStyles.field_description}
                  text={watch(`suspendList.${index}.dueDateText`)}
                ></FieldText>
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol>
                <Title title={t('InternalSuspend:EmailGroup')}></Title>
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                <Controller
                  control={control}
                  name={`suspendList.${index}.to`}
                  rules={ruleList.to}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('To')}
                      value={value ?? ''}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      required={true}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                <Controller
                  control={control}
                  name={`suspendList.${index}.cc`}
                  rules={ruleList.cc}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('CC')}
                      value={value ?? ''}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      required={true}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                <Controller
                  control={control}
                  name={`suspendList.${index}.content`}
                  rules={ruleList.content}
                  render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Input
                      title={t('InternalSuspend:Content')}
                      required
                      maxLength={5000}
                      multiline
                      numberOfLines={5}
                      disabled={disabled}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      errorMessage={!!value ? '' : error?.message}
                    />
                  )}
                />
              </SectionCol>
            </SectionRow>
            <SectionRow sectionStyles={internalInfoStyles.secondLine}>
              <SectionCol sectionStyles={internalInfoStyles.col_12}>
                {!disabled && (
                  <View style={internalInfoStyles.btnContent}>
                    <TouchableOpacity onPress={() => deleteSuspendItem(index)}>
                      <View style={[OBSharedStyles.actionContent, internalInfoStyles.btnDelete]}>
                        <Text style={[OBSharedStyles.actionContentText]}>{t('common:Delete')}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
              </SectionCol>
            </SectionRow>
          </SectionContent>
        ))}
      <SectionRow sectionStyles={internalInfoStyles.secondLine}>
        <SectionCol sectionStyles={internalInfoStyles.col_12}>
          {disabled ? (
            <></>
          ) : (
            <View style={[internalInfoStyles.buttonContainer, { width: '100%' }]}>
              <TouchableOpacity
                style={[sharedStyle.button, { marginLeft: 0, borderColor: '#ED1B2E' }]}
                onPress={cancelButtonEvent}
              >
                <Text style={[OBSharedStyles.actionContentText, { textAlign: 'center' }]}>{t('common:Cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={saveInternalSuspend}>
                <Text style={[OBSharedStyles.actionHightLightText, { textAlign: 'center' }]}>{t('common:Save')}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnRed]} onPress={() => addNewSuspendItem()}>
                <Text style={[OBSharedStyles.actionHightLightText, { textAlign: 'center' }]}>{t('InternalSuspend:AddSuspend')}</Text>
              </TouchableOpacity>
            </View>
          )
          }
        </SectionCol>
      </SectionRow>
    </SectionContent>
  )
}

export const internalInfoStyles = StyleSheet.create({
  container: {
    marginVertical: 30
  },
  noMarginBottom: {
    marginBottom: 0
  },
  secondLine: {
    marginTop: 20
  },
  rightAlign: {
    textAlign: 'right'
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
  },
  actionBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  cancelBtn: {
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  btnContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btnAdd: {
    minHeight: 40,
    width: '100%',
    borderRadius: 100,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderColor: '#ED1B2E',
    borderWidth: 1
  },
  btnDelete: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: '#FEEDEE',
    borderColor: '#FEEDEE'
  },
  btn_text: {
    // marginLeft: 10,
    // marginTop: 4
    fontSize: 15,
    lineHeight: 20,
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    height: 70
  },
  header: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16,
    marginBottom: 16
  }
})