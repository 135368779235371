import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, ActivityIndicator } from 'react-native'
import { IBGeneralTable, IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { ClaimCaseDetail, IBService } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { listClaimType } from './IBClaimSearchForm'
import { PulseOpsFormat } from '@pulseops/common'
import { isNull } from 'lodash'
import moment from 'moment'

interface Props {
  claimCaseInfo: ClaimCaseDetail | null
  setClaimCaseInfo: (data: ClaimCaseDetail) => void
  claimNumber: string
}

export const IBClaimCaseInfo = ({ claimCaseInfo, setClaimCaseInfo, claimNumber }: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  // React.useEffect(() => {
  //   if(claimNumber && isNull(claimCaseInfo)) {
  //     pipe(
  //       IBService.getClaimCaseDetail(claimNumber),
  //       ZIO.map((res) => {
  //         res && setClaimCaseInfo({
  //           ...res,
  //           claimEvents: res.claimEvents?.map((i) => ({
  //             ...i,
  //             cancelReason: (i.cancelReasonCode ?? '') + ' ' + (i.cancelReason ?? ''),
  //             confirmedDate: 
  //               i.confirmedDate ? moment(i.confirmedDate, 'DD/MM/YYYY').format('YYYYMMDD') :
  //               i.occurenceDate ? moment(i.occurenceDate, 'DD/MM/YYYY').format('YYYYMMDD') : ''
  //           }))
  //         })
  //       }),
  //       bindLoading,
  //       ZIO.unsafeRun({})
  //     )
  //   }
  // }, [claimNumber])

  const getCaseCategoryLabel = (category: string) => {
    return category === 'J' ? 'Jet Case' : category === 'N' ? 'Normal Case' : ''
  }

  const getClaimTypeLabel = (type: string) => {
    return listClaimType.find((i) => i.value === type)?.label ?? ''
  }

  const dataClaimCaseInfo = (data: ClaimCaseDetail) => [
    {
      title: t('ClaimNumber'),
      value: data?.claimNumber
    },
    {
      title: t('ReceivedDate'),
      value: data?.receivedDate
    },
    {
      title: t('ConfirmDate'),
      value: data?.confirmedDate
    },
    {
      title: t('RegisterUser'),
      value: data?.registeredUser
    },
    {
      title: t('CaseCategory'),
      value: data?.category ? getCaseCategoryLabel(data?.category) : ''
    },
    {
      title: t('RegisterDate'),
      value: data?.registeredDate
    },
    {
      title: t('DocCompleteDate'),
      value: data?.docCompletedDate
    },
    {
      title: t('ConfirmUser'),
      value: data?.confirmedUser
    },
    {
      title: t('ProcessStatus'),
      value: data?.processStatus
    },
    {
      title: t('FaceAmount'),
      value: data?.faceAmount ? PulseOpsFormat.currency(data?.faceAmount) : ''
    },
    {
      title: t('EvaluationDate'),
      value: data?.evaluationDate
    },
    {
      title: t('EvaluationUser'),
      value: data?.evaluationUser
    },
    {
      title: t('Reason'),
      value: data?.cancelReason
    },
    {
      title: t('GaRank'),
      value: data?.gaRank
    },
    {
      title: t('ApprovalDate'),
      value: data?.approvalDate
    },
    {
      title: t('ApprovalUser'),
      value: data?.approvalUser
    }
  ]

  const dataTable = [
    {
      label: t('ClaimType'),
      field: 'claimType',
      render: (value: string) => (value ? getClaimTypeLabel(value) : '-')
    },
    {
      label: t('ConfirmFirstDate'),
      field: 'confirmedDate',
      format: 'date'
    },
    {
      label: t('Reason'),
      field: 'cancelReason'
    },
    {
      label: t('RemarkClaim'),
      field: 'remark'
    }
  ]

  return (
    <View>
      {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <View>
          <View style={[IBStyles.row, { paddingBottom: 15 }]}>
            {claimCaseInfo && dataClaimCaseInfo(claimCaseInfo).map((field) => (
              <View style={[IBStyles.sectionCol3, { paddingBottom: 24 }]}>
                <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                  <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{field.title}</Text>
                </View>
                <Text style={{ fontSize: 15, marginTop: 4 }}>{field.value ?? '-'}</Text>
              </View>
            ))}
          </View>
          <IBGeneralTable
            dataTable={dataTable}
            data={claimCaseInfo?.claimEvents ?? []}
            loading={loading}
          />
        </View>
      )}
    </View>
  )
}