import React from 'react'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

export const tableUseStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#EEEEEE'
  },
  stickyLeftHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: '#EEEEEE',
    fontWeight: 'bold'
  },
  stickyLeftCell: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: '#FFF',
    borderWidth: 1
  },
  none: {}
})

export type TableSetup = {
  title: string
  key: string
  colSpan?: number
  stickyLeft?: boolean
  minWidth?: number
}

export interface RTableProps {
  rows: any[]
  setup: TableSetup[]
  keyTitle: string
  actions?: (i: number, pageNum: number, pageSize: number) => void
  noHeader?: boolean
  noPagination?: boolean
}

export const RequestTable = ({ rows, setup, keyTitle, actions, noHeader, noPagination }: RTableProps) => {
  const classes = tableUseStyles()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const totalItem = rows.length
  const { t } = useTranslation()

  return (
    <View style={{ marginTop: 10 }}>
      <TableContainer component={Paper}>
        <Table>
          {!noHeader && (
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {setup.map((x, i) => (
                  <TableCell
                    style={!!x.minWidth ? { minWidth: x.minWidth } : {}}
                    className={x.stickyLeft ? classes.stickyLeftHeader : classes.none}
                    key={`header-${keyTitle}-${i}`}
                  >
                    {x.title}
                  </TableCell>
                ))}
                {actions !== undefined && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={`row-${keyTitle}-${i}`}>
                {setup.map((x, i) => (
                  <TableCell
                    style={!!x.minWidth ? { minWidth: x.minWidth } : {}}
                    className={x.stickyLeft ? classes.stickyLeftCell : classes.none}
                    key={`header-${keyTitle}-${i}`}
                    colSpan={row.colSpan}
                  >
                    {row[x.key]}
                  </TableCell>
                ))}
                {actions !== undefined && (
                  <TableCell>
                    <Text
                      style={{ color: 'blue' }}
                      onPress={() => {
                        actions(i, pageNum, pageSize)
                      }}
                    >
                      Xem thêm
                    </Text>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={setup.length} style={{ textAlign: 'center' }}>
                  {t('common:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (
        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={totalItem}
          onPageChange={(e, page) => {
            setPageNum(page)
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      )}
    </View>
  )
}
