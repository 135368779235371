/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, TextStyle, Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import { PulseOpsFormat, TaskDetail, assets } from '@pulseops/common'
import { ImageDialog } from '../../../container'
import { GeneralInformationSelect } from '../mock'
import i18next from 'i18next'
import { SectionCol, SectionRow } from '@pulseops/submission/common'

type Props = {
  detail: TaskDetail.AgentVerificationNew
  imageList: string[]
  openPopup: boolean
  viewPendingList: (index: number) => void
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DANewOfficeScreen = (props: Props) => {
  const { t } = useTranslation('RequestInfo')
  const officeMovementReason = GeneralInformationSelect.OfficeReasonList.find(
    (item) => item.value === props.detail.officeMovementReason
  )
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.bodyInfo}>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('OfficeCode')}</Text> <Text>{props.detail?.officeCodeagent ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('OfficeName')}</Text> <Text>{props.detail?.officeName ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('GACode')}</Text> <Text>{props.detail?.officeGaCode ?? '-'}</Text>
          </View>
        </View>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('NewOfficeAddress')}</Text>{' '}
            <Text>{props.detail?.officeAddress ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('ReasonRequesting')}</Text>{' '}
            <Text>
              {!!props.detail?.officeMovementReason
                ? i18next.language === 'en'
                  ? officeMovementReason?.nameEn
                  : officeMovementReason?.nameVn
                : '-'}
            </Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}></Text> <Text></Text>
          </View>
        </View>
        <View>
          <Text style={styles.bodyPolicy}>{t('FileAttachment')}</Text>
          {!!props.detail.officeCodeagent ? (
            <SectionRow>
              {props.detail.documents?.map((item, index) => {
                const nameFiles = '...' + item?.name?.substring(item?.name?.length - 10)
                const nameFile = item?.name?.split('/').splice(-1, 1).join('/')
                const checkNameFiles = item?.name?.substring(item?.name?.length - 3)
                // `${file.fileName.substr(0, 5)}...`}
                return (
                  <SectionCol sectionStyles={styles.col1}>
                    <TouchableOpacity
                      style={
                        {
                          // width: 40,
                          // height: 40,
                          // borderRadius: 2,
                          // marginEnd: 6
                        }
                      }
                      onPress={() => {
                        props.viewPendingList(index)
                      }}
                    >
                      {checkNameFiles?.toLocaleLowerCase() === 'pdf' ? <assets.pdf /> : <assets.imageIcon />}
                    </TouchableOpacity>
                    <View>
                      <Text style={[{ fontSize: 14, fontWeight: 'bold' }, styles.cellNoWrap]}>
                        {/* {`${item?.name?.substr(0, 6)}...`} */}
                        {/* {nameFiles?.toLocaleLowerCase()} */}
                        {item.name && item?.name?.length < 10 ? nameFile : nameFiles}
                      </Text>
                      <Text style={{ fontSize: 13, marginTop: 10, fontWeight: 'normal' }}>
                        {props.detail.createdDate
                          ? PulseOpsFormat.datetoFormat(new Date(props.detail.createdDate), 'DD/MM/yyyy HH:mm:ss')
                          : '-'}
                      </Text>
                    </View>
                  </SectionCol>
                )
              })}
            </SectionRow>
          ) : (
            '-'
          )}
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <View style={styles.bodyInfoOne}></View>
      </View>
      <ImageDialog selectedValueArray={props.imageList} open={props.openPopup} onClose={() => props.setOpen(false)} />
    </View>
  )
}

const styles = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  bodyInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  bodyInfoOne: {
    width: '100%',
    padding: 80,
    backgroundColor: '#FFF'
  },
  bodyInfoHeader: {
    flexDirection: 'row',
    paddingVertical: 20
  },
  bodyPolyView: {
    flex: 1
  },
  bodyPolicy: {
    fontSize: 15,
    paddingVertical: 6,
    color: '#70777E',
    fontWeight: 'bold',
  },
  col1: {
    display: 'flex',
    width: '100%',
    maxWith: '8.3333334%',
    flexDirection: 'row',
    paddingVertical: 10
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle
})
