/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as t from 'io-ts'
import { form2 } from '@pulseops/common'
export namespace StructureForm {
  export const codec = t.type({
    saleZone: form2.string.required,
    gaType: form2.string.required,
    agentType: form2.string.required,
    officeCode1: form2.string.required,
    gaCode1: form2.string.optional,
    regionCode: form2.string.required,
    officeCode2: form2.string.optional,
    gaCode2: form2.string.optional,
    serviceBranch: form2.string.required,
    galleries: form2.string.optional,
    agentCategory: form2.string.optional,
    ref: form2.string.optional,
    isSaveStructure: t.boolean
  })
  export type Raw = t.OutputOf<typeof codec>
  export type Validated = t.TypeOf<typeof codec>
}
