import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.FUND_SWITCHING)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    attributesExt: t.type({
      PRIMARY_POLICY: t.string,
      PO_CLIENT_NUMBER: t.string,
      GA_OFFICE_CODE: t.string,
      OPTION_FLAG: t.boolean,
      FUND_TYPE: t.string,
      SOURCE_FUND_LIST: t.array(
        t.type({
          sourceFundCode: t.string,
          unitValue: t.number,
          sourcePercentage: t.number,
          sourceAmount: t.number
        })
      ),
      TARGET_FUND_LIST: t.array(
        t.type({
          targetFundCode: t.string,
          targetPercentage: t.number,
          targetAmount: t.number
        })
      )
    })
  })
})

const payloadPruonline = t.type({
  body: t.type({
    attributesExt: t.type({
      OPTION_FLAG: Maybe(t.boolean),
      FUND_TYPE: Maybe(t.string),
      PRIMARY_POLICY: Maybe(t.string),
      SOURCE_FUND_LIST: t.array(
        t.type({
          sourceFundCode: Maybe(t.string),
          unitValue: Maybe(t.number),
          sourcePercentage: Maybe(t.number),
          sourceAmount: Maybe(t.number)
        })
      ),
      TARGET_FUND_LIST: t.array(
        t.type({
          targetFundCode: Maybe(t.string),
          targetPercentage: Maybe(t.number),
          targetAmount: Maybe(t.number)
        })
      )
    })
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPruonline
  })
)

export const FundSwitching = t.union([Pulse4Ops, PruOnline])
export type FundSwitching = t.TypeOf<typeof FundSwitching>
