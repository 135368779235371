import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { NumberFromString } from 'io-ts-types'
import { SourceType } from '../SourceType'
// import { SourceType } from '../SourceType'
// import { SourceType } from '..'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.CASHLESS_O1),
        t.literal(TransactionType.CASHLESS_O3),
        t.literal(TransactionType.CASHLESS_O2),
        t.literal(TransactionType.CASHLESS_DK),
        t.literal(TransactionType.CASHLESS_C9),
        t.literal(TransactionType.CASHLESS_P2),
        t.literal(TransactionType.CASHLESS_M3),
        t.literal(TransactionType.CASHLESS_V4),
        t.literal(TransactionType.CASHLESS_A0),
        t.literal(TransactionType.CASHLESS_A7),
        t.literal(TransactionType.CASHLESS_S1),
        t.literal(TransactionType.CASHLESS_P3),
        t.literal(TransactionType.CASHLESS_S2),
        t.literal(TransactionType.CASHLESS_B3),
        t.literal(TransactionType.CASHLESS_S3),
        t.literal(TransactionType.CASHLESS_C3),
        t.literal(TransactionType.CASHLESS_U3),
        t.literal(TransactionType.CASHLESS_V3),
        t.literal(TransactionType.CASHLESS_I3),
        t.literal(TransactionType.CASHLESS_C7),
        t.literal(TransactionType.CASHLESS_X1),
        t.literal(TransactionType.CASHLESS_T3),
        t.literal(TransactionType.CASHLESS_V2),
        t.literal(TransactionType.CASHLESS_M2),
        t.literal(TransactionType.CASHLESS_A9),
        t.literal(TransactionType.CASHLESS_S6),
        t.literal(TransactionType.CASHLESS_L3),
        t.literal(TransactionType.CASHLESS_D7),
        t.literal(TransactionType.CASHLESS_I4),
        t.literal(TransactionType.CASHLESS_P9),
        t.literal(TransactionType.CASHLESS_I2),
        t.literal(TransactionType.CASHLESS_M9),
        t.literal(TransactionType.CASHLESS_D6),
        t.literal(TransactionType.CASHLESS_D8),
        t.literal(TransactionType.CASHLESS_D9),
        t.literal(TransactionType.CASHLESS_V5)
      ]),
      source: t.union([
        t.literal(SourceType.ONLINE_PAYMENT),
        t.literal(SourceType.PRUONLINE),
        t.literal(SourceType.PULSE),
        t.literal(SourceType.ShopeePay),
        t.literal(SourceType.Ebanking_seabank),
        t.literal(SourceType.MSB_BANK),
        t.literal(SourceType.MOMO),
        t.literal(SourceType.MOMO_FOR_AGENT),
        t.literal(SourceType.MY_VIB),
        t.literal(SourceType.Shopee),
        t.literal(SourceType.PAYOO),
        t.literal(SourceType.POS_SeABank),
        t.literal(SourceType.VIB_AUTODEBIT),
        t.literal(SourceType.Agribank_VBA),
        t.literal(SourceType.Viettel),
        t.literal(SourceType.VNPOST),
        t.literal(SourceType.VIETTEL_TELCO),
        t.literal(SourceType.MPOS_EXIMBANK),
        t.literal(SourceType.Offline),
        t.literal(SourceType.SACOMBANK),
        t.literal(SourceType.CREDITLIFEPORTAL)
      ])
    }),
    codec
  ])

const ReceiptPayload = t.type({
  action: t.string,
  newDescription: Maybe(t.string),
  transactionCode: Maybe(t.string),
  receiptNo: Maybe(t.string),
  transactions: t.array(
    t.type({
      amount: t.number,
      newDescription: t.string,
      subAccountCode: t.string,
      subAccountType: t.string
    })
  )
})

const PCWeb = Base(
  // PCWeb(dynamic)
  t.type({
    payload: t.type({
      body: t.type({
        advanceAmount: Maybe(t.string),
        advanceInd: Maybe(t.string),
        agentCode: Maybe(t.string),
        agentPhone: Maybe(t.string),
        amount: Maybe(t.union([NumberFromString, t.number, t.string])),
        bankCode: Maybe(t.string),
        channel: Maybe(t.string),
        email: Maybe(t.string),
        etrNo: Maybe(t.string),
        id: Maybe(t.string),
        idNumber: Maybe(t.string),
        payerName: Maybe(t.string),
        paymentMethod: Maybe(t.string),
        paymentReason: Maybe(t.string),
        phoneNumber: Maybe(t.string),
        policyNumber: Maybe(t.string),
        policyNumberOld: Maybe(t.string),
        policyOwner: Maybe(t.string),
        proposalNumber: Maybe(t.string),
        proposalNumberOld: Maybe(t.string),
        priorProposal: Maybe(t.boolean), /// ưu tiên show field proposal trên màn hình
        receiptDate: Maybe(t.string),
        referenceNumber: Maybe(t.string),
        remark: Maybe(t.string),
        transactionDate: Maybe(t.string),
        cardType: Maybe(t.string),
        receipts: Maybe(t.array(ReceiptPayload)),
        discountReceipts: Maybe(t.array(ReceiptPayload)),
        journalReceipts: Maybe(t.array(ReceiptPayload)),
        nonCashReceipts: Maybe(t.array(ReceiptPayload)),
        hasVoucher: Maybe(t.boolean),
        vouchers: Maybe(
          t.array(
            t.type({
              code: t.string,
              amount: t.string
            })
          )
        )
      })
    }),
    policyNumber: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    stpFunction: Maybe(t.string),
    writeOffAction: Maybe(t.string),
    suspendReason: Maybe(t.string)
  })
)

export const CashLess = PCWeb

export const UpdatePayloadCashless = t.intersection([
  CashLess,
  t.type({
    transactionStatus: t.string, // 1-3, ---param change payload (1:AutoLAS, 2: verify, 3: AutoLAS2d1 (pendding))
    policyNumberNew: t.string,
    pcStpType: t.string, //  ---param change payload(99: verify, 1-18:autoSTP)
    subPendCode: t.string,
    receips: t.array(
      t.type({
        action: t.string,
        transactions: t.array(
          t.type({
            amout: t.string,
            newDescription: t.string, // Ma hoan
            subAccountCode: t.string,
            subAccountType: t.string
          })
        ),
        transactionCode: t.string,
        receiptStatus: t.number
      })
    )
  })
])

export type CashLess = t.TypeOf<typeof CashLess>

export type UpdatePayloadCashless = t.TypeOf<typeof UpdatePayloadCashless>
