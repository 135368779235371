import * as t from 'io-ts'
import { PremiumChargeC } from './premium-charge'

export const PremiumChargeTotalC = t.type({
  id: t.string,
  masterContractNo: t.string,
  policyYear: t.number,
  premiumCharges: t.array(PremiumChargeC),
  total: t.number,
  premiumReceived: t.number,
  premiumPending: t.number
})
export type PremiumChargeTotal = t.TypeOf<typeof PremiumChargeTotalC>
