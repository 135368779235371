import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { HomeScreen } from './HomeScreen'
import { LandingPageIDCInfoStackParamList } from './LandingPageIDCStackParamList'
const Stack = createStackNavigator<LandingPageIDCInfoStackParamList>()

export const LandingPageIDCStack = () => {
  return (
    <Stack.Navigator initialRouteName="HomeScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
    </Stack.Navigator>
  )
}
