export namespace PremiumStatusMapping {
  export const mapPremiumStatus = new Map<string, string>([
    ['AP', `AnnuityInPayment`],
    ['CE', `Ceased`],
    ['CF', `CancelledFromInception`],
    ['DC', `Declined`],
    ['DE', `DeclineClaim`],
    ['DH', `Deceased`],
    ['ET', `ExtendedTermInsurance`],
    ['EX', `Expired`],
    ['FP', `FullyPaid`],
    ['FZ', `Frozen`],
    ['HA', `PremHolly`],
    ['HP', `PHReinstatementPending`],
    ['LA', `Lapsed`],
    ['MA', `Matured`],
    ['NF', `NFSurr`],
    ['PP', `PremiumPaying`],
    ['PS', `Proposal`],
    ['PU', `MadePaidUp`],
    ['S', `SuspendedPendingTransaction`],
    ['SP', `SinglePrm`],
    ['SU', `FullySurrender`],
    ['TM', `TerminateByClaim`],
    ['VO', `VoiderRider`],
    ['VR', `VestReg`],
    ['WD', `Withdrawn`],
    ['WV', `WaiverOfPremium`]
  ])

  export const get = (translator: (val: string) => string) => (key?: string) =>
    key ? translator(mapPremiumStatus.get(key) ?? '') ?? '-' : '-'
}
