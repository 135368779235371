import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { PSSubmissionStackParamList } from './PSSubmissionStackParamList'

import { PSSubmissionListScreen } from './PSSubmissionListScreen'
import { PSSubmissionScreen } from './PSSubmissionScreen'

const Stack = createStackNavigator<PSSubmissionStackParamList>()

export const PSSubmissionStack = () => {
  return (
    <Stack.Navigator initialRouteName="PSSubmissionListScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="PSSubmissionListScreen" component={PSSubmissionListScreen} />
      <Stack.Screen name="PSSubmissionScreen" component={PSSubmissionScreen} />
    </Stack.Navigator>
  )
}
