import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  Checkbox,
  DatePicker,
  ErrorHandling,
  form2,
  Input,
  Permission,
  RBAC,
  Format
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import { ReportsStackParamList } from './ReportsStackParamList'
import { PolicyDataXMLService } from './Service/PolicyDataXMLService'

const formC = t.union([
  t.type({
    tag: t.literal('POLICY'),
    policyNumber: form2.string.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('Reports:PolicyNumber')
      })
    )
  }),
  t.type({
    tag: t.literal('CLIENTNUMBER'),
    clientNumber: form2.string.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('Reports:ClientNumber')
      })
    )
  }),
  t.type({
    tag: t.literal('RELEASE'),
    releaseDate: form2.date.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('Reports:ReleaseDate')
      })
    )
  })
])

const currentDate = new Date()

type props = StackScreenProps<ReportsStackParamList, 'PolicyDataXMLScreen'>

export const PolicyDataXMLScreen = ({ navigation }: props) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:PolicyDataXML'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const {
    base: {
      control,
      setValue,
      formState: { errors },
      watch,
      clearErrors
    },
    handleSubmit
  } = form2.useForm(formC, {
    defaultValues: {
      tag: 'POLICY'
    }
  })

  const extract = handleSubmit((validated) => {
    const data = {
      masterContractNo: validated.tag === 'POLICY' ? validated.policyNumber : undefined,
      clientNumber: validated.tag === 'CLIENTNUMBER' ? validated.clientNumber : undefined,
      releaseDate: validated.tag === 'RELEASE' ? Format.date(validated.releaseDate) : undefined
    }
    showGlobalLoading(true)

    return pipe(
      PolicyDataXMLService.checkExtractData(data),
      ZIO.tap((res) => {
        if (res.result === 'SUCCESS') {
          return PolicyDataXMLService.extractData(data)
        } else {
          showToast(t(`business:${res.errorCode}`), 'error')
          return ZIO.unit
        }
      }),
      ZIO.tap((res) => {
        res.result === 'SUCCESS' && showToast(t('business:MS000066'), 'success')
        return ZIO.unit
      }),
      ZIO.catchAll(() => {
        showToast(t('common:SomeThingWentWrong'), 'error')
        return ZIO.unit
      }),
      ZIO.tapBoth(
        () => ZIO.succeed(showGlobalLoading(false)),
        () => ZIO.succeed(showGlobalLoading(false))
      ),
      ErrorHandling.run()
    )
  })

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Checkbox
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  value={watch('tag') === 'POLICY'}
                  onChange={() => {
                    setValue('tag', 'POLICY')
                    clearErrors()
                  }}
                />
                <View style={{ flex: 1 }}>
                  <Controller
                    name="policyNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        title={t('Reports:PolicyNumber')}
                        labelStyle={{ marginBottom: 9 }}
                        value={value ?? undefined}
                        maxLength={8}
                        inputType="number"
                        onChange={onChange}
                        errorMessage={errors.policyNumber?.message}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={styles.col}>
                <Checkbox
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  value={watch('tag') === 'CLIENTNUMBER'}
                  onChange={() => {
                    setValue('tag', 'CLIENTNUMBER')
                    clearErrors()
                  }}
                />
                <View style={{ flex: 1 }}>
                  <Controller
                    name="clientNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        title={t('Reports:ClientNumber')}
                        labelStyle={{ marginBottom: 9 }}
                        value={value ?? undefined}
                        maxLength={8}
                        inputType="number"
                        onChange={onChange}
                        errorMessage={errors.clientNumber?.message}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={styles.col}>
                <Checkbox
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  value={watch('tag') === 'RELEASE'}
                  onChange={() => {
                    setValue('tag', 'RELEASE')
                    clearErrors()
                  }}
                />
                <View style={{ flex: 1 }}>
                  <Controller
                    name="releaseDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        alwaysShow={true}
                        label={t('Reports:ReleaseDate')}
                        onChange={onChange}
                        value={value}
                        maxDate={currentDate}
                        maxDateMessage={t('message:MS990032')}
                        errorMessage={errors.releaseDate?.message}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={[styles.row, { justifyContent: 'flex-end' }]}>
              {roles.includes(Permission['BusinessPulseReports']) && (
                <TouchableOpacity onPress={extract}>
                  <View style={styles.btn}>
                    <assets.DownloadWhite />
                    <Text style={styles.btnText}>{t('Reports:Export')}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 16,
    marginVertical: 16,
    paddingVertical: 16,
    paddingHorizontal: 16
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 16
  },

  col: {
    width: '33.33%',
    minWidth: 300,
    flexDirection: 'row',
    padding: 16
  },

  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    boxShadow: 'none',
    paddingVertical: 10,
    paddingHorizontal: 32
  },

  btnText: {
    textAlign: 'center',
    color: '#fff',
    marginLeft: 6
  }
} as const)
