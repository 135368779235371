import React, { ChangeEvent } from 'react'
import { Text, View, StyleSheet, TextStyle, ViewStyle, TouchableOpacity, StyleProp } from 'react-native'
import { SelectProps } from './Select'
import { Select as MaterialSelect, MenuItem, InputBase, createTheme, ThemeProvider } from '@material-ui/core'
import { assets } from '../../assets'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

type Props = SelectProps & {
  labelStyle?: TextStyle
  containerStyles?: ViewStyle
  showUnderline?: boolean
  numberOfLine?: number
  iconStyle?: StyleProp<ViewStyle>
  filled?: boolean
  popupIcon?: JSX.Element
  selectStyle?: React.CSSProperties
  menuStyle?: React.CSSProperties
}

export const Select = ({ showUnderline = true, ...props }: Props) => {
  const {
    label,
    options,
    disabled,
    required,
    errorMessage,
    labelStyle,
    placeholder,
    containerStyles,
    numberOfLine,
    iconStyle,
    filled = false,
    popupIcon
  } = props

  const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (props.onChange) {
      const value = event.target.value as string
      const option = options.find((option) => option.value === value) || null
      props.onChange(option)
    }
  }

  const [open, setOpen] = React.useState<boolean>(false)
  return (
    <ThemeProvider theme={theme}>
      {label && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.label, labelStyle]}>{label}</Text>
          {required && <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginLeft: 5 }}>*</Text>}
        </View>
      )}
      <View style={[filled && styles.filledContainer, containerStyles]}>
        <MaterialSelect
          value={props.value?.value}
          labelId={label}
          key={props.value?.value || 'nullValue'}
          displayEmpty
          IconComponent={() =>
            !disabled ? (
              <TouchableOpacity
                style={[styles.dropdownIcon, filled && { paddingEnd: 8 }, iconStyle]}
                onPress={() => setOpen(true)}
              >
                {popupIcon ? popupIcon : <assets.ArrowDownDropdownIcon />}
              </TouchableOpacity>
            ) : (
              <></>
            )
          }
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          //onClick={() => setOpen(true)}
          input={<InputBase />}
          onChange={onChange}
          disabled={disabled}
          renderValue={(value) => {
            const option = options.find((option) => option.value === value)
            const label = option?.label
            if (!label) {
              return (
                <Text style={[styles.placeholder, filled && { paddingStart: 8 }]} numberOfLines={numberOfLine}>
                  {placeholder}
                </Text>
              )
            }
            return (
              <Text style={[styles.value, filled && { paddingStart: 8 }]} numberOfLines={numberOfLine}>
                {label}
              </Text>
            )
          }}
          style={{ minHeight: 33 }}
          placeholder={placeholder}
          onBlur={props.onBlur}
          MenuProps={{style: props.menuStyle}}
        >
          {placeholder ? (
            <MenuItem key={'1'} value={''} disabled>
              {placeholder}
            </MenuItem>
          ) : null}

          {options.map((item, _index) => (
            <MenuItem value={item.value} key={`${item.value}-${_index}`} style={props.selectStyle}>
              {item.label}
            </MenuItem>
          ))}
        </MaterialSelect>
      </View>
      {showUnderline && !filled && <View style={styles.underline} />}
      {!!errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errorMessage}</Text> : null}
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  },

  dropdownIcon: {
    width: 16,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  placeholder: {
    color: '#70777E',
    fontSize: 15
  },

  value: {
    color: '#000000',
    fontSize: 15,
    fontWeight: 'normal'
  },

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  },

  filledContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#D3DCE6'
  }
})
