import { Nothing } from '@mxt/zio'
import { Stream, ZStream } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { DependencyList } from 'react'
import { defer, merge, of, Subject } from 'rxjs'

export function didUpdate<A>(deps: [A]): Stream<Nothing, [A]>
export function didUpdate<A, B>(deps: [A, B]): Stream<Nothing, [A, B]>
export function didUpdate<A, B, C>(deps: [A, B, C]): Stream<Nothing, [A, B, C]>
export function didUpdate<A, B, C, D>(deps: [A, B, C, D]): Stream<Nothing, [A, B, C, D]>
export function didUpdate<A, B, C, D, E>(deps: [A, B, C, D, E]): Stream<Nothing, [A, B, C, D, E]>
export function didUpdate(deps: DependencyList): Stream<Nothing, DependencyList> {
  const subject = React.useRef(new Subject<DependencyList>())

  React.useEffect(() => {
    subject.current.next(deps)
  }, deps)

  return pipe(
    merge(
      subject.current,
      defer(() => of(deps))
    ),
    ZStream.fromObservable
  )
}
