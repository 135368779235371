import { Color } from '@material-ui/lab'
import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  ErrorHandling,
  GeneralService,
  Input,
  InquiryComplaintService,
  ModalComponent,
  SelectOption,
  SelectSearch,
  Toast,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React, { useContext, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { EvaluationForm } from './AdjudicationForm'

export type EvaluationModalProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {}
  form: UseFormReturn<EvaluationForm>
  diaryTypeList: InquiryComplaintService.VeriIACDiaryType[]
  onSubmit: () => void
}

export const EvaluationModal = (props: EvaluationModalProps) => {
  const { onClose, open } = props
  const { isWide } = useMobile()
  const { isGlobalLoadingVisible } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const [evaluations, setEvaluations] = useState<SelectOption[]>([])

  const { t, i18n } = useTranslation()

  const { control } = props.form

  const handleClose = () => {
    onClose()
    props.form.reset()
  }

  pipe(
    GeneralService.getEvaluationData(),
    ZIO.map((evaluavationList) => {
      setEvaluations(
        evaluavationList?.map(
          (evaluation) =>
            ({
              value: evaluation.evaluationCode,
              label: i18n.language === 'vi' ? evaluation?.evaluationVN : evaluation?.evaluationEN
            } || [])
        )
      )
    }),
    ErrorHandling.runDidMount()
  )

  return (
    <ModalComponent
      title={t('claim:Evaluation')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('common:Save'),
          type: 'filled',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39 },
          action: () => props.onSubmit()
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 15 }}>
          <Controller
            name="evaluationType"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('claim:EvaluationType') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('claim:EvaluationType')}
                placeholder={t('common:Select')}
                required
                options={evaluations}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                errorMessage={error?.message}
                key={value?.value}
              />
            )}
          />
        </View>
        <View style={{ marginBottom: 15 }}>
          <Controller
            name="subject"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('claim:Subject') })}`
              },
              maxLength: {
                value: 100,
                message: `${t('message:MaxLength', { field: '100' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('claim:Subject')}
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                errorMessage={error?.message}
                maxLength={100}
              />
            )}
          />
        </View>
        <View>
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020001', { field: t('claim:Description') })}`
              },
              maxLength: {
                value: 10000,
                message: `${t('message:MaxLength', { field: '10000' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('claim:Description')}
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                errorMessage={error?.message}
                multiline
                maxLength={10000}
                numberOfLines={4}
              />
            )}
          />
        </View>
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
