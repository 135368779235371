import { TaskDetail, SubPremiumRedirectionData, TableField } from '@pulseops/common'
// import { PremiumRedirectionConst } from 'libs/common/src/service/model/OpEformDetail'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Title } from '../common'

export const PremiumRedirection = ({ detail }: { detail: TaskDetail.VeriPremiumRedirectionData }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'TaskManagement', 'Payout'])
  let subFundList: SubPremiumRedirectionData.SUBFundInfo[] = []
  const fundList = [
    { code: 'UBL1', name: 'PRUlink Balanced', nameVi: 'Quỹ PRUlink Cân Bằng' },
    { code: 'UEQ1', name: 'PRUlink VN Equity', nameVi: 'Quỹ PRUlink Cổ Phiếu Việt Nam' },
    { code: 'UFI1', name: 'PRUlink VN FixedInc', nameVi: 'Quỹ PRUlink Trái Phiếu Việt Nam' },
    { code: 'UGR1', name: 'PRUlink Growth', nameVi: 'Quỹ PRUlink Tăng Trưởng' },
    { code: 'UPS1', name: 'PRUlink Preserver', nameVi: 'Quỹ PRUlink Bảo Toàn' },
    { code: 'USB1', name: 'PRUlink Stable', nameVi: 'Quỹ PRUlink Bền Vững' },
    { code: 'USE1', name: 'Prulink Vietnam Sustainable Development Equity Fund', nameVi: 'Quỹ PRUlink Tương lai xanh' }
  ]

  subFundList = detail.fundList

  subFundList.forEach((fundInfo) => {
    const fundItem = fundList.find((x) => x.code === fundInfo.fundCode)
    fundInfo.fundName = fundItem ? (i18n.language === 'en' ? fundItem?.name : fundItem?.nameVi) : '-'
  })

  const dataSource = subFundList.map((item) => ({
    fundName: item.fundName,
    currentPercentage: item.currentPercentage,
    redirectPercentage: item.redirectPercentage
  }))

  const columnsSource = [
    {
      key: '1',
      title: t('TaskManagement:FundName'),
      name: 'fundName'
    },
    {
      key: '2',
      title: t('requestInfo:CurrentPercentage'),
      name: 'currentPercentage'
    },
    {
      key: '3',
      title: t('requestInfo:RedirectPercentage'),
      name: 'redirectPercentage'
    }
  ]
  return (
    <View style={{ paddingBottom: 50 }}>
      <Title title={t('requestInfo:PremiumRedirection')} />
      <View>
        <TableField
          styleHeader={styles.headerTable}
          styleBody={styles.bodyTable}
          columns={columnsSource}
          dataSource={dataSource}
        />
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  headerTable: {
    backgroundColor: '#e2e7ea',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  bodyTable: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#f4f4f4'
  }
})
