import * as React from 'react'
import { View } from 'react-native'
import { TaskDetail, Panel, formatNumberWithComma, PayoutPopup, PayoutChecker, PulseOpsFormat } from '@pulseops/common'
import { Title, InfoViewList } from '../common'
import { PayoutMethod } from './common'
import { useTranslation } from 'react-i18next'
interface Props {
  detail: TaskDetail.MaturityAdvance
}

export const MaturityAdvance = ({ detail }: Props) => {
  const { t } = useTranslation('requestInfo')

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.totalAmount),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('MATURITY_ADVANCE_INFORMATION')} />
      <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('EstimatedTotalMaturityBenefit'),
              value: formatNumberWithComma(detail.estimatedTotalBenefit),
              suffix: 'VND'
            },
            {
              label: t('PaidMaturityAdvance'),
              value: formatNumberWithComma(detail.paidAdvance),
              suffix: 'VND'
            },
            {
              label: t('NetMaturityAdvance'),
              value: formatNumberWithComma(detail.netAdvance),
              suffix: 'VND'
            }
          ]}
        />
      </Panel>
      <PayoutMethod
        payoutMethodData={payoutData}
        containerStyle={{ marginTop: 16 }}
        panelContainerStyle={{ backgroundColor: '#FAFAFA' }}
      />
    </View>
  )
}
