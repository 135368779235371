import { Nullable } from '@mxt/zio/codec'
import { refCache, refCacheWithCondition, RefSessionStorage } from '@pulseops/common'
import { flow, pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { Lens } from 'monocle-ts'
import { ZIO } from '@mxt/zio'
import { PaginationState } from '../service/pagination-state'
import { BenefitC } from './model/benefit'
import { ContractDetail, ContractDetailC } from './model/contract-detail'
import { ContractItemC } from './model/contract-item'
import { GroupProduct } from './model/group-product'
import { ShareholderC } from './model/shareholder'
import { EmployeeC } from './model/employee'
import { HistoryItemC } from './model/history-item'
import { ContractId } from './model/contract-id'

const ps = {
  ContractItem: PaginationState.itemC(ContractItemC, (item) => item.masterContractNo),
  Shareholder: PaginationState.itemC(ShareholderC, (item) => item.shareHolderKey),
  Employee: PaginationState.itemC(EmployeeC, (item) => item.employeeKey),
  History: PaginationState.itemC(HistoryItemC, (item) => item.version.toString())
}

export namespace GeneralInfoState {
  const AllInfoC = t.type({
    detail: Nullable(ContractDetailC),
    shareholder: ps.Shareholder.codec,
    employee: ps.Employee.codec,
    benefits: Nullable(t.array(BenefitC)),

    history: ps.History.codec
  })
  type AllInfo = t.TypeOf<typeof AllInfoC>

  const AllInfoRecordC = t.record(t.string, Nullable(AllInfoC))
  type AllInfoRecord = t.TypeOf<typeof AllInfoRecordC>

  export const codec = t.type(
    {
      contractItem: ps.ContractItem.codec,
      allInfo: AllInfoRecordC
    },
    'ContractService'
  )

  const ref = RefSessionStorage.make(codec, {
    contractItem: ps.ContractItem.initialState,
    allInfo: {}
  })

  export const cache = refCache(ref)
  export const cacheWithRule = refCacheWithCondition(ref)

  export const contractItem = ps.ContractItem.getService(ref, Lens.fromProp<GeneralInfoState>()('contractItem'))

  export const forContract = (id: ContractId) => {
    const lens = Lens.fromProp<GeneralInfoState>()('allInfo').compose(
      Lens.fromNullableProp<AllInfoRecord>()(ContractId.toString(id), {
        detail: null,
        shareholder: ps.Shareholder.initialState,
        employee: ps.Employee.initialState,
        benefits: null,

        history: ps.History.initialState
      })
    )

    const detailLens = lens.compose(Lens.fromProp<AllInfo>()('detail'))

    const benefitsLens = lens.compose(Lens.fromProp<AllInfo>()('benefits'))

    return {
      detail: {
        lens: detailLens,
        get: pipe(detailLens.get, ref.select),
        watch: pipe(detailLens.get, ref.watch),
        clear: pipe(detailLens.set(null), ref.update, ZIO.asVoid),
        set: (c: ContractDetail) => pipe(detailLens.set(c), ref.update, ZIO.asVoid)
      },
      shareholder: ps.Shareholder.getService(ref, lens.compose(Lens.fromProp<AllInfo>()('shareholder'))),
      employee: ps.Employee.getService(ref, lens.compose(Lens.fromProp<AllInfo>()('employee'))),
      benefit: {
        lens: benefitsLens,
        get: pipe(benefitsLens.get, ref.select),
        set: flow(benefitsLens.set, ref.update),
        clear: pipe(benefitsLens.set(null), ref.update, ZIO.asVoid),
        watchGroupProduct: pipe(
          flow(benefitsLens.get, (benefits) => benefits && GroupProduct(benefits)),
          ref.watch
        )
      },
      history: ps.History.getService(ref, lens.compose(Lens.fromProp<AllInfo>()('history')))
    }
  }
}

export type GeneralInfoState = t.TypeOf<typeof GeneralInfoState.codec>
