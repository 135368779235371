import { StyleSheet } from 'react-native'
export const sharedStyle = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  sectionTitle: {
    fontSize: 15,
    textTransform: 'uppercase',
    fontWeight: '800'
  },
  sectionContent: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#d3dce6',
    padding: 20,
    marginTop: 15
  },
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnRed: {
    backgroundColor: '#ED1B2E',
    borderColor: '#ED1B2E'
  },
  btnDisabled: {
    backgroundColor: '#FFF',
    borderColor: '#B0B0B0'
  },
  tableContainer: {
    marginTop: 16,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF'
  },

  headerContainer: {
    backgroundColor: '#e2e7ea',
    padding: 24
  },

  rowContainer: {
    paddingHorizontal: 24
  },

  headerTitle: {
    color: '#000',
    fontSize: 15,
    fontWeight: '700'
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_6: {
    width: '100%',
    maxWidth: '50%',
    paddingRight: 15,
    paddingLeft: 15
  },
})
