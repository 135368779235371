import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  Toast,
  AppContext,
  DatePicker,
  Label,
  AuthService,
  PulseOpsService,
  PulseOpsFormat,
  RadioButton,
  TaskType,
  GeneralService,
  SupplementaryInfo
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { Color } from '@material-ui/lab'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'
import moment from 'moment'
import { VoiceDataGridForm, VoiceRecordingModels, VoiceRecordingServices } from '../../container/voice-recording'

export type SuspendPopupProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {
    taskId: string
    updateToCore: boolean
    processId: string
    planSuspendDate: Date
    category?: string
    caseId?: string
    isCancelPayment?: boolean
    historyList?: SupplementaryInfo.HistoryList
    firstSuspendDate?: string | null
    dynamicKeywordData?: Array<VoiceDataGridForm.DynamicKeywordData>
    isSavedDynamicKeyword?: boolean
  }
  category: string
}

export const SuspendPopup = (props: SuspendPopupProps) => {
  const MIN_DATE = new Date(new Date(Date.now() + 86400000).setHours(0, 0, 0, 0))
  const MAX_DATE = new Date(new Date(Date.now() + 86400000 * 60).setHours(0, 0, 0, 0))
  // const MAX_DATE = props.category === TaskType.IDC ?
  //   pipe(
  //     props.selectedValue.firstSuspendDate,
  //     O.fromNullable,
  //     O.fold(
  //       () => new Date(new Date(Date.now() + 86400000 * 20).setHours(0, 0, 0, 0)),
  //       (itemData) => {
  //         const customedMaxDay = new Date(moment(itemData).add(20, 'days').toDate().setHours(0, 0, 0, 0))
  //         return customedMaxDay
  //       }
  //     )
  //   )
  //   : new Date(new Date(Date.now() + 86400000 * 60).setHours(0, 0, 0, 0))


  const { t } = useTranslation()
  const { onClose, onSuccess, open, selectedValue, category } = props
  const { isWide } = useMobile()

  const [note, setNote] = useState<string>('')
  const [communication, setCommunication] = useState<string>(category === TaskType.Claim ? 'Y' : 'N')
  const [loading, bindLoader] = useLoading(false)
  const [planSuspendDate, setPlanSuspendDate] = React.useState<Date | null>(null)
  const [dateError, setDateError] = React.useState<string>()
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)

  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const radioGroups = [
    {
      code: 'Y',
      label: t('common:Yes')
    },
    {
      code: 'N',
      label: t('common:No')
    }
  ]

  const taskTypeHidePlanDate = [TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService, TaskType.IDC]

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const handleClose = () => {
    setNote('')
    setPlanSuspendDate(null)
    onClose()
  }

  const getUserResultDataForVoiceRecording = () => {
    const submitedData = selectedValue.dynamicKeywordData?.map((item) => ({
      scriptId: item.scriptId || '',
      keywordId: item.keywordId || '',
      result: item.result?.value || '',
      reason: item.reason || '',
      updateKeywordType: item.isGroup ? 'UPDATE_KEYWORD_TYPE_GROUP_EVALUATION' : 'UPDATE_KEYWORD_TYPE_DYNAMIC'
    }))
    const data = {
      resultList: submitedData,
      processInstanceId: selectedValue.processId
    }
    return data
  }

  const handleSuspendAction = () => {
    if (planSuspendDate && validateForPlanDate()) {
      showGlobalLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.flatMap((x) =>
          pipe(
            selectedValue.category === TaskType.IDC &&
              selectedValue.dynamicKeywordData &&
              selectedValue.dynamicKeywordData.length > 0 &&
              !selectedValue.isSavedDynamicKeyword ?
              VoiceRecordingServices.submitAIreasonUser(getUserResultDataForVoiceRecording())
              : ZIO.succeed({} as VoiceRecordingModels.ReasonUserC),
            ZIO.flatMap((_) =>
              PulseOpsService.suspendTask(
                selectedValue.taskId,
                {
                  author: x.email,
                  message: note,
                  expiryDate: PulseOpsFormat.datetoFormat(planSuspendDate, 'DD/MM/yyyy HH:mm:ss'),
                  communication: communication === 'Y'
                },
                selectedValue.updateToCore,
                category,
                selectedValue.isCancelPayment ? false : undefined
              )
            )
          )
        ),
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastInternal(t('requestInfo:SuspendFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          if (selectedValue.category === TaskType.Claim) {
            pipe(GeneralService.triggerActionClaim(selectedValue.caseId || ''), ZIO.unsafeRun({}))
          }
          showGlobalLoading(false)
          handleClose()
          showToast(t('requestInfo:SuspendSuccess'), 'success')
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  React.useEffect(() => {
    if (!taskTypeHidePlanDate.includes(props.category as TaskType)) validateDate(selectedValue.planSuspendDate)
    setPlanSuspendDate(selectedValue.planSuspendDate)
  }, [selectedValue.planSuspendDate])

  const validateDate = (date: Date | null) => {
    date?.setHours(0, 0, 0, 0)
    if (!Number(date?.getTime())) {
      setDateError(t('common:InvalidDateFormat'))
    } else if (Number(date?.getTime()) < MIN_DATE.getTime()) {
      setDateError(t('message:MS990006'))
    } else if (Number(date?.getTime()) > MAX_DATE.getTime()) {
      setDateError(t('message:MS990007', { day: props.category === TaskType.IDC ? '20' : '60' }))
    } else {
      setDateError(undefined)
    }
  }

  const validateForPlanDate = () => {
    if (taskTypeHidePlanDate.includes(props.category as TaskType) && planSuspendDate) {
      if (!Number(planSuspendDate?.getTime())) {
        setToast({ open: true, message: t('common:InvalidDateFormat'), type: 'error' })
        return false
      }
      if (Number(planSuspendDate?.getTime()) < MIN_DATE.getTime()) {
        setToast({ open: true, message: t('message:MS990006'), type: 'error' })
        return false
      }
    }
    return true
  }

  return (
    <ModalComponent
      title={selectedValue.isCancelPayment ? t('CashOut:RejectRemove') : t('requestInfo:SuspendCase')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: selectedValue.isCancelPayment ? t('common:Confirm') : t('claim:Suspend'),
          type: 'filled',
          disabled: selectedValue.isCancelPayment ? false : !!dateError && !taskTypeHidePlanDate.includes(props.category as TaskType),
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: handleSuspendAction
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ flex: 1, flexDirection: 'row' }}>
          {!taskTypeHidePlanDate.includes(category as TaskType) && (
            <View style={{ marginBottom: 16, width: isWide ? '50%' : '100%', marginEnd: 10 }}>
              <Label title={t('requestInfo:PlanUnsuspendDate')} />
              <DatePicker
                label=""
                value={planSuspendDate}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                onChange={(val) => {
                  validateDate(val)
                  setPlanSuspendDate(val)
                }}
                errorMessage={dateError}
                invalidDateMessage={null}
                maxDateMessage={null}
                minDateMessage={null}
              />
            </View>
          )}
          {category === TaskType.Claim && (
            <View style={{ marginBottom: 16, width: isWide ? '50%' : '100%' }}>
              <Label title={t('requestInfo:SendCommunication')} required />
              <View style={{ flexDirection: 'row', marginTop: 15 }}>
                {radioGroups.map((r, i) => (
                  <RadioButton
                    key={i}
                    label={r.label}
                    value={r.code}
                    selected={communication === r.code}
                    onChange={(e) => {
                      setCommunication(e)
                    }}
                  />
                ))}
              </View>
            </View>
          )}
        </View>
        <TextInput
          title={t('TaskManagement:Comment')}
          value={note}
          onChange={setNote}
          placeholder={t('TaskManagement:WriteComment')}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
