import React, { useMemo } from 'react'
import { DataSource, ModalComponent, HeaderTable, PulseOpsFormat } from '@pulseops/common'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'

export interface IWarningData extends DataSource {
  detailDesc: string
  policyNum: string
  shortDesc: string
  userId: string
  warningDate: string
}

interface IWarningPopup {
  dataSource?: IWarningData[]
  visible?: boolean
  onClose?: () => void
}

export const WarningPopup: React.FC<IWarningPopup> = (props) => {
  const { visible, onClose, dataSource = [] } = props
  const { t } = useTranslation()

  const newDataSource = useMemo(
    () => dataSource.map((item) => ({ ...item, warningDate: PulseOpsFormat.dateStr(item.warningDate + '') })),
    [dataSource]
  )

  const columns = [
    {
      key: 1,
      title: t('common:FromDate'),
      name: 'warningDate'
    },
    {
      key: 2,
      title: t('TaskManagement:User'),
      name: 'userId'
    },
    {
      key: 3,
      title: t('common:ShortDescription'),
      name: 'shortDesc'
    },
    {
      key: 4,
      title: t('common:description'),
      name: 'detailDesc'
    }
  ]

  return (
    <ModalComponent
      titleStyle={styles.modalTitle}
      title={t('RequestInfo:WarningMessage')}
      visible={visible ?? false}
      modalWidth={900}
      onClose={() => onClose?.()}
      actions={[]}
    >
      <View style={styles.container}>
        <HeaderTable columns={columns} dataSource={newDataSource} />
      </View>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },
  container: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    flex: 2,
    marginHorizontal: 'auto',
    backgroundColor: '#fafafa'
  }
})
