import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  capFisrtLetterEachWord,
  ErrorHandling,
  form2,
  Input,
  PayoutPopup,
  sharedStyle,
  FullSurrenderService,
  TransactionType,
  PulseOpsFormat,
  Divider,
  // StorageBlob,
  AppContext,
  GeneralService,
  TaskType,
  BeneficiaryDesignationService
} from '@pulseops/common'
import { Checkbox } from '@pulseops/submission/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import moment from 'moment'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps, UploadedFilesInfo } from './policy-service-props'
import { PayoutMethod, PayoutMethodRef, mapCashOutOption } from '../payout-method'
import { DateOfDeath, DateOfDeathForm } from '../date-of-death'

const isCYR1Product = (coverageCode: string | undefined, totalSurrenderAmount: string | null | undefined) => {
  return coverageCode === 'CYR1' && Number(totalSurrenderAmount ?? '0') === 0
}

const FormData = t.type({
  cashDeposit: form2.string.required,
  suspenseAmount: form2.string.required,
  policyLoans: form2.string.required,
  policyDebt: form2.string.required,
  surrenderCharge: form2.string.required,
  coverageCode: t.string,
  totalSurrenderAmount: form2.string.required,
  payment: t.intersection([
    t.type({
      isPoPassAway: t.boolean,
      payout: pipe(
        t.array(PayoutPopup.SummaryCodec),
        form2.refine(
          (arr): arr is PayoutPopup.PayoutSummaryArray =>
            isCYR1Product(currentFormData?.coverageCode, currentFormData?.totalSurrenderAmount) ||
            (arr && arr.length > 0),
          () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
          'EmptyArray'
        ),
        form2.refine(
          (arr): arr is PayoutPopup.PayoutSummaryArray =>
            isCYR1Product(currentFormData?.coverageCode, currentFormData?.totalSurrenderAmount) ||
            (arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0),
          () => i18next.t('message:MS050241'),
          'TotalAmountLessThanZero'
        ),
        form2.refine(
          (arr): arr is PayoutPopup.PayoutSummaryArray =>
            isCYR1Product(currentFormData?.coverageCode, currentFormData?.totalSurrenderAmount) ||
            (arr &&
              arr.length > 0 &&
              arr.reduce((sum, item) => sum + item.amount, 0) ===
                Math.floor(Number(currentFormData?.totalSurrenderAmount))),
          () => i18next.t('message:MS050170'),
          'TotalAmountNotEqualValue'
        )
      )
    }),
    t.union([
      t.intersection([t.type({ isPoPassAway: t.literal(true) }), DateOfDeathForm.codec]),
      t.type({ isPoPassAway: t.literal(false) })
    ])
  ])
})
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

type SubmitData = FullSurrenderService.SubmitData

let currentFormData: {
  totalSurrenderAmount: string | null
  coverageCode: string
} | null = null

export const FullSurrender = (props: PolicyServiceProps<SubmitData>) => {
  const { t } = useTranslation()
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)
  const policyNumber = props.policyNumber!
  const officeCode = props.officeCode
  const defaultValues: FormDataRaw = {
    cashDeposit: '0',
    suspenseAmount: '0',
    policyLoans: '0',
    policyDebt: '0',
    surrenderCharge: '0',
    totalSurrenderAmount: '0',
    coverageCode: '',
    payment: {
      isPoPassAway: false,
      payout: []
    }
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })
  currentFormData = Object.assign(currentFormData ?? {}, {
    totalSurrenderAmount: base.watch('totalSurrenderAmount'),
    coverageCode: base.watch('coverageCode')
  })

  const data = pipe(
    ZIO.zipPar(
      FullSurrenderService.getDetail(policyNumber),
      BeneficiaryDesignationService.getSeaBankMessgeList(policyNumber ?? '', props.clientNumber ?? '')
    ),
    ZIO.map(
      ([
        {
          cashDeposit,
          suspenseAmount,
          policyLoans,
          policyDebt,
          surrenderCharge,
          totalSurrenderAmount,
          sumAssured,
          coverageCode
        },
        warningMessage
      ]) => {
        base.setValue('cashDeposit', cashDeposit.toString())
        base.setValue('suspenseAmount', suspenseAmount.toString())
        base.setValue('policyLoans', policyLoans.toString())
        base.setValue('policyDebt', policyDebt.toString())
        base.setValue('surrenderCharge', surrenderCharge.toString())
        base.setValue('totalSurrenderAmount', totalSurrenderAmount.toString())
        base.setValue('coverageCode', coverageCode)
        if (!!warningMessage) {
          showToast(warningMessage, 'warning', undefined, 30000)
        }
        return {
          cashDeposit,
          suspenseAmount,
          policyLoans,
          policyDebt,
          surrenderCharge,
          totalSurrenderAmount,
          sumAssured,
          coverageCode,
          warningMessage
        }
      }
    ),
    ErrorHandling.runDidUpdate([policyNumber])
  )

  React.useEffect(() => {
    return () => {
      // console.log('run unmount component')
      base.reset(defaultValues)
    }
  }, [])

  const getUploadedFilesInfo = (formData: FormDataRaw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    if (formData.payment.isPoPassAway && formData.payment.files && formData.payment.files.length > 0) {
      const passAwayFiles = formData.payment.files as DateOfDeathForm.FileList
      uploadedFileList.push({
        uploadFiles: passAwayFiles,
        transactionType: TransactionType.FULL_SURRENDER,
        docTypeCode: 'DPS11',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    uploadedFileList.push({
      uploadFiles: GeneralService.getPayoutDocuments(formData.payment.payout),
      transactionType: TransactionType.FULL_SURRENDER,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  props.initSubmission({
    validate: async (isContinue) => {
      const _form = await handleSubmit(() => undefined)()
      if (E.isRight(_form) && data) {
        const form = _form.right

        // let fileMeta: StorageBlob.FileContentSubmit[] = []

        // if (!isContinue && form.payment.isPoPassAway && form.payment.files && form.payment.files.length > 0) {
        //   const payment = form.payment

        //   showGlobalLoading(true)
        //   fileMeta = await pipe(
        //     GeneralService.getMetaData(TransactionType.FULL_SURRENDER || '', 'DPS11'),
        //     ZIO.flatMap((metaDataRes) => {
        //       const metaDataRaw: StorageBlob.MetaDataUpload = {
        //         type: metaDataRes.data.transactionType,
        //         doctype: metaDataRes.data.doctypeEn,
        //         class: metaDataRes.data.classFilenet,
        //         docid: metaDataRes.data.docID,
        //         maindoc: metaDataRes.data.mainDoc,
        //         subdoc: metaDataRes.data.subDoc,
        //         polnum: policyNumber,
        //         batchno: metaDataRes.data.batchNo
        //       }
        //       return StorageBlob.uploadToSubmit(
        //         'PS',
        //         policyNumber
        //       )(
        //         payment.files.map((x) => ({
        //           file: x.file!,
        //           metaData: metaDataRaw,
        //           id: x.uploadedDate.getTime()
        //         }))
        //       )
        //     }),
        //     ErrorHandling.run({})
        //   )
        //   showGlobalLoading(false)
        // }

        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/full-surrender`,
          body: {
            policy: {
              policyNo: policyNumber,
              owners: {
                dateOfDeath: form.payment.isPoPassAway ? moment(form.payment.deathDate).format('DD/MM/yyyy') : '',
                attributesExt: {
                  HAS_INHERITANCE: form.payment.isPoPassAway
                }
              }
            },
            attributesExt: {
              TOTAL_SURRENDER_AMOUNT: data.totalSurrenderAmount,
              SUSPENSE_AMOUNT: data.suspenseAmount,
              POLICY_LOAN: data.policyLoans,
              CASH_DEPOSIT: data.cashDeposit,
              POLICY_DEBT: data.policyDebt,
              SURRENDER_CHARGE: data.surrenderCharge
            },
            cashOutOptions: mapCashOutOption(form.payment.payout)
            // documents: fileMeta.map((file) => ({
            //   ...file,
            //   id: file.id || ''
            // }))
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.FULL_SURRENDER),
          collerationId: policyNumber,
          transaction: TransactionType.FULL_SURRENDER,
          uploadedFilesInfo: getUploadedFilesInfo(form)
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.setValue('payment.isPoPassAway', false)
      base.setValue('payment.deathDate', null)
      base.setValue('payment.files', [])
      base.setValue('payment.payout', [])
      payoutRef.current?.clearData()
      base.clearErrors()
    }
  })

  const isConfirmed = props.isConfirmed
  const totalSurrenderAmount = base.watch('totalSurrenderAmount')
  const isPoPassAway = base.watch('payment.isPoPassAway')

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:FullSurrender')}</Text>
        <View style={[sharedStyle.sectionContent, { paddingHorizontal: 5 }]}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:CashDeposit')}
                value={toCurrency(base.watch('cashDeposit'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:SuspenseAmount')}
                value={toCurrency(base.watch('suspenseAmount'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:PolicyLoans')}
                value={toCurrency(base.watch('policyLoans'))}
                disabled={true}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input title={t('submission:PolicyDebt')} value={toCurrency(base.watch('policyDebt'))} disabled={true} />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:SurrenderCharge')}
                value={toCurrency(base.watch('surrenderCharge'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:TotalSurrenderAmount')}
                value={toCurrency(base.watch('totalSurrenderAmount'))}
                disabled={true}
              />
            </View>
          </View>
        </View>
      </View>
      {(!isConfirmed || (isConfirmed && isPoPassAway)) && (
        <>
          <Divider height={30} />
          <Checkbox
            enable={!isConfirmed}
            title={t('submission:PODeath')}
            fontWeight="bold"
            value={isPoPassAway}
            onChange={(val) => {
              if (val) {
                base.setValue('payment.deathDate', null)
                base.setValue('payment.files', [])
              }
              base.setValue('payment.isPoPassAway', val)
            }}
          />
        </>
      )}
      {isPoPassAway && (
        <DateOfDeath
          sumAssured={data?.sumAssured}
          editable={!isConfirmed}
          deathDateControl={{ control: base.control, name: 'payment.deathDate' }}
          fileControl={{ control: base.control, name: 'payment.files' }}
        />
      )}

      <Controller
        control={base.control}
        name="payment.payout"
        render={({ field, fieldState: { error } }) => (
          <PayoutMethod
            {...field}
            ref={payoutRef}
            editable={!isConfirmed}
            policyNum={policyNumber}
            transactionType={TransactionType.MATURITY}
            inheritanceFlag={isPoPassAway}
            maxAmount={Math.floor(Number(totalSurrenderAmount))}
            errorMessage={error?.message}
            methods={[
              PayoutPopup.PayoutMethods.PAYPREMIUM,
              PayoutPopup.PayoutMethods.PAYLOAN,
              PayoutPopup.PayoutMethods.CASHLESS,
              PayoutPopup.PayoutMethods.OTHER,
              PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
              PayoutPopup.PayoutMethods.PAID_AT_BANK,
              PayoutPopup.PayoutMethods.BANKTRANSFER,
              // PayoutPopup.PayoutMethods.MOMO
            ]}
            officeCode={props.officeCode}
            isSeaBankPolicy={!!data.warningMessage}
          />
        )}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 15
  }
} as const)

function toCurrency(value: number | string | null | undefined) {
  return PulseOpsFormat.thousandSepartor(value ? Number(value) : 0) + ' VND'
}
