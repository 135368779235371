import React from 'react'
import { FlatList, View, Text, StyleSheet, TextStyle, StyleProp, useWindowDimensions } from 'react-native'

type DataSource = {
  label?: string | undefined
  value?: string | React.ReactNode | undefined
  child?: React.ReactNode | undefined
  labelStyle?: StyleProp<TextStyle> | undefined
  valueStyle?: StyleProp<TextStyle> | undefined
}

type Props = {
  numRow?: number
  data: DataSource[]
}

export const GridView = ({ data, numRow = 3 }: Props) => {
  const mod = data.length % numRow
  const { width } = useWindowDimensions()
  const isMobile = width < 768
  const wrapData: DataSource[] = React.useMemo(
    () => [
      ...data,
      ...[...Array<DataSource>(numRow - mod < numRow ? numRow - mod : 0).keys()].map((i) => {
        return {
          child: <View />,
          label: undefined,
          value: undefined,
          labelStyle: undefined,
          valueStyle: undefined
        }
      })
    ],
    []
  )
  return (
    <FlatList
      data={wrapData}
      extraData={data}
      keyExtractor={(_, index) => `grid${index}`}
      numColumns={isMobile ? 1 : numRow}
      renderItem={({ item, index }) => {
        return (
          <View style={{ flex: 1, marginTop: isMobile ? 28 : index < numRow ? 0 : 28, marginRight: 20 }}>
            {item.child ?? (
              <View style={{ flex: 1 }}>
                <Text style={[styles.defaultTitle, item.labelStyle]}>{item.label ?? ''}</Text>
                {typeof item.value === 'string' ? (
                  <Text style={[styles.defaultValue, item.valueStyle]}>{item.value ?? ''}</Text>
                ) : typeof item.value === 'undefined' ? (
                  <View />
                ) : (
                  item.value
                )}
              </View>
            )}
          </View>
        )
      }}
    />
  )
}

const styles = StyleSheet.create({
  defaultTitle: {
    fontFamily: 'NotoSans_700Bold',
    fontSize: 15,
    color: '#70777E',
    marginBottom: 10
  },
  defaultValue: {
    fontFamily: 'NotoSans_400Regular',
    fontSize: 15
  }
})
