import { enumC } from '@mxt/zio/codec'

export enum OBStatus {
  IN_PROGRESS = 'In progress',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  ENDED = 'Ended'
}

export const OBStatusC = enumC(OBStatus)

const mapOBStatus = new Map<OBStatus, OBStatusLabel>([
  [
    OBStatus.IN_PROGRESS,
    { label: 'In progress', color: '#1EA5FC', backgroundColor: 'rgba(86, 204, 242, 0.3)' }
  ],
  [OBStatus.PENDING, { label: 'Pending', color: '#FF6F00', backgroundColor: 'rgba(242, 201, 76, 0.2)' }],
  [OBStatus.COMPLETED, { label: 'Completed', color: '#4CAF50', backgroundColor: 'rgba(111, 207, 151, 0.2)' }],
  [OBStatus.ENDED, { label: 'Ended', color: '#ED1B2E', backgroundColor: '#FFFFFF' }],
])

export type OBStatusLabel = {
  label: string
  color: string
  backgroundColor: string
}

export const OBStatusLabel = (status: OBStatus): OBStatusLabel =>
    mapOBStatus.get(status) ?? {
    label: '-',
    color: 'black',
    backgroundColor: 'white'
  }
