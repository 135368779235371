import React from 'react'
import { StyleSheet, View, Text, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { PulseOpsFormat } from '@pulseops/common'
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { SpecialList } from '../OBCallOutDetailScreen'

const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#E5EAEF'
    },
    tableCell: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      color: '#70777E',
      backgroundColor: '#F4F4F4'
    },
    cellNoWrap: {
      whiteSpace: 'nowrap'
    }
  })

type Prop = {
  dataSource: SpecialList[]
}

export const OBSpecialNotePopup = (props: Prop) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const columnSpecialNote = [
    t('Outbound:OBNotification:FromDate'),
    t('Outbound:OBNotification:User'),
    t('Outbound:OBNotification:ShortDescription'),
    t('Outbound:OBNotification:Description'),
  ]

  const TableTitleComlumn = (columns: string[]) => {
    return (
      <TableRow>
        {columns.map((item) => 
          <TableCell className={classes.tableCell}>{item}</TableCell>
        )}
      </TableRow>
    )
  }

  const TableRowData = (row: SpecialList[]) => {
    return row.length > 0 ? 
      row.map((item) => (
      <TableRow>
        <TableCell className={classes.cellNoWrap}>{PulseOpsFormat.dateStringtoFormat(item.warningDate, 'DD/MM/YYYY')}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.userId}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.shortDesc}</TableCell>
        <TableCell className={classes.cellNoWrap}>{item.detailDesc}</TableCell>
      </TableRow>
      ))
      : 
      <TableRow>
        <TableCell style={{ textAlign: 'center' }} colSpan={12}>
            {t('common:Nodata')}
        </TableCell>
      </TableRow>
  }

  return (
    <View>
      <ScrollView style={specialNoteStyle.scrollView}>
        <TableContainer component={Paper} style={{ maxHeight: 500, width: 'auto', padding: 20 }} elevation={1}>
            <Table stickyHeader style={{ border: '1px solid #D3DCE6', borderRadius: 6 }}>
              <TableHead className={classes.tableHeader}>
                {TableTitleComlumn(columnSpecialNote)}
              </TableHead>
              {TableRowData(props.dataSource)}
            </Table>
        </TableContainer>
      </ScrollView>
    </View>
  )
}
const specialNoteStyle = StyleSheet.create({
  secondLine: {
    marginTop: 16
  },
  scrollView: {
    width: '100%'
  },
  header: {
    marginLeft: 32,
    fontWeight: '700',
    paddingBottom: 20,
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16
  },
  sectionContainer: {
    marginVertical: 5,
    paddingVertical: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20
  }
})