import { CircularProgress, createTheme, makeStyles, TextField, ThemeProvider, Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { UIO, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { identity, pipe } from 'fp-ts/function'
import React from 'react'
import { ControlProps } from '../FormProps'
import { SelectOption } from '../select'
import { assets } from '../../assets'
import { StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import * as R from 'rxjs'
import * as Rx from 'rxjs/operators'
import { useTranslation } from 'react-i18next'
import { AssignmentUserInfo } from '../../service'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

const useStyles = makeStyles({
  popupIndicatorOpen: {
    transform: 'none'
  },
  root: {
    '& input::placeholder': {
      color: '#70777E',
      opacity: 1
    }
  },
  textField: {
    borderColor: '#D3DCE6'
  }
})

type SelectProps = {
  label?: string
  options?: SelectOption[]
  searchOptions?: (inputValue: string) => UIO<SelectOption[]>
  disabled?: boolean
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  popupIcon?: JSX.Element
  showPopupIcon?: boolean
  required?: boolean
  readOnly?: boolean
  labelStyle?: TextStyle
  placeholder?: string
  disableUnderline?: boolean
  prefixIcon?: JSX.Element
  inputStyle?: StyleProp<ViewStyle>
  buildLabel?: (selectOption: SelectOption) => string
  inputWhenFocus?: boolean
  hideLabel?: boolean
  maxLength?: number
  isShowFullText?: boolean
  hightlightList?: AssignmentUserInfo[]
}

type Props = ControlProps<SelectOption | null> & SelectProps

type MultipleProps = ControlProps<SelectOption[]> &
  SelectProps & {
    multiple: true
  }

export function HighLightSelectSearch(props: MultipleProps): JSX.Element
export function HighLightSelectSearch(props: Props): JSX.Element
export function HighLightSelectSearch(_props: any): JSX.Element {
  const props: ControlProps<SelectOption | SelectOption[] | null> &
    SelectProps & {
      multiple?: boolean
    } = _props
  const [options, setOptions] = React.useState<SelectOption[]>([])
  const [loading, bindLoading] = useLoading(false)
  const { t } = useTranslation()

  const inputChange = new R.Subject<string>()
  const classes = useStyles()

  React.useEffect(() => {
    // Update the document title using the browser API
    inputChange.pipe(Rx.debounceTime(1000)).subscribe((res) => {
      pipe(
        props.searchOptions !== undefined ? props.searchOptions(res) : ZIO.succeed(new Array<SelectOption>()),
        ZIO.tap((options) =>
          ZIO.effectTotal(() => {
            setOptions(options)
          })
        ),
        bindLoading,
        ZIO.run({})
      )
    })
  })

  return (
    <ThemeProvider theme={theme} key={JSON.stringify(props.value) || 'nullValue'}>
      <Autocomplete
        classes={
          props?.popupIcon
            ? undefined
            : {
                popupIndicatorOpen: classes.popupIndicatorOpen
              }
        }
        multiple={props.multiple}
        options={props.searchOptions ? options : props.options || []}
        popupIcon={props.showPopupIcon === false ? null : props?.popupIcon || <assets.Search20Icon />}
        style={{}}
        closeIcon={props.readOnly ? null : undefined}
        PopperComponent={(props) => {
          return <Popper {...props} style={{ ...(props.style || {}), zIndex: 9999 }} />
        }}
        open={props.readOnly === true ? false : undefined}
        renderInput={(params) => {
          // console.log('select params : ',params)
          return (
            <View>
              {!!props.label && !props.hideLabel && (
                <Text style={[styles.label, _props.labelStyle]}>
                  {props.label} {!!props.required && <Text style={{ color: '#ed1b2c', fontWeight: 'bold' }}>*</Text>}
                </Text>
              )}
              <View style={[{ flexDirection: 'row' }, props.inputStyle]}>
                {props.prefixIcon && (
                  <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>{props.prefixIcon}</View>
                )}
                <TextField
                  {...params}
                  placeholder={props.placeholder}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    readOnly: props.readOnly,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    disableUnderline: true //props.disableUnderline
                  }}
                  style={{ minHeight: 33 }}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: props.maxLength
                  }}
                  InputLabelProps={{ shrink: props.disableUnderline }}
                  onFocus={() => {
                    props?.inputWhenFocus && inputChange.next('')
                  }}
                  fullWidth
                  multiline={props.isShowFullText}
                />
              </View>
            </View>
          )
        }}
        getOptionLabel={(o: SelectOption) => (props.buildLabel ? props.buildLabel(o) : o.label)}
        renderOption={(optionItem, optionState) => {
          const isHightLight = props.hightlightList?.some((x) => x.email === optionItem.value && !x.status)
          return (
            <View>
              <Text style={[styles.normailText, isHightLight && styles.hightLightText]}>{optionItem.label}</Text>
            </View>
          )
        }}
        filterOptions={props.searchOptions ? identity : undefined}
        value={props.value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
          setOptions(newValue ? [...(Array.isArray(newValue) ? newValue : [newValue]), ...options] : options)
          // setSelected(newValue)
          if (props.onChange) {
            props.onChange(newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          inputChange.next(newInputValue)
        }}
        loading={loading}
        onBlur={props.onBlur}
        noOptionsText={t('common:NoOptions')}
      />
      {!props.disableUnderline && <View style={{ width: '100%', height: 1, backgroundColor: '#D3DCE6' }} />}
      {props.errorMessage && <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text>}
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  },
  hightLightText: {
    color: '#ED1B2E',
    fontSize: 16,
    fontWeight: '400'
  },
  normailText: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '400'
  }
})
