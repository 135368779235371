export namespace Address {
  export interface AddressOption {
    code: string
    desVi: string
  }

  export interface ProvinceOption {
    code: string
    desVi: string
    birthCode: string
  }

  export const EmailIndicator: Array<{
    value: string
    labelEN: string
    labelVI: string
  }> = [
    {
      value: '1',
      labelVI: 'Thông tin hợp đồng',
      labelEN: 'Policy info'
    },
    {
      value: '3',
      labelVI: 'Thông tin hợp đồng & Prudential',
      labelEN: 'Policy & Prudential info'
    },
    {
      value: '4',
      labelVI: 'Từ chối nhận email',
      labelEN: 'Reject'
    }
  ]

  export const SMSIndicator: Array<{
    value: string
    labelEN: string
    labelVI: string
  }> = [
    {
      value: '1',
      labelVI: 'Thông tin hợp đồng',
      labelEN: 'Policy info'
    },
    {
      value: '3',
      labelVI: 'Thông tin hợp đồng & Prudential',
      labelEN: 'Policy & Prudential info'
    },
    {
      value: '4',
      labelVI: 'Từ chối nhận SMS',
      labelEN: 'Reject'
    }
  ]

  export const mapNational = new Map<string | null | undefined, string>()
}
