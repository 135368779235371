import { Format } from '@pulseops/common'
import { flow, pipe } from 'fp-ts/function'
import * as Ord from 'fp-ts/Ord'
import * as Eq from 'fp-ts/Eq'
import * as A from 'fp-ts/Array'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as R from 'fp-ts/Record'
import * as D from 'fp-ts/Date'
import * as O from 'fp-ts/Option'
import * as S from 'fp-ts/string'
import { DocId } from './doc-id'
import { VerificationDecision } from './verification-decision'
import { FormService } from '../../general-info/form-service'

export type VerificationDecisionChange = {
  docId: DocId
  oldValues: string[]
  newValues: string[]
  createdBy: string
  createdDate: Date
}

const byCreatedDate = pipe(
  D.Ord,
  Ord.contramap((p: VerificationDecision) => p.createdDate),
  Ord.reverse
)

const eqDate = pipe(
  S.Eq,
  Eq.contramap((date: Date | null) => (date ? Format.date(date) : ''))
)

const byDecision = pipe(
  Eq.eqStrict,
  Eq.contramap((p: VerificationDecision) => p.decision)
)

const byComment = pipe(
  S.Eq,
  Eq.contramap((p: VerificationDecision) => p.comment || '')
)

const byStatus = pipe(
  S.Eq,
  Eq.contramap((p: VerificationDecision) => p.status || '')
)

const byRequestDate = pipe(
  eqDate,
  Eq.contramap((p: VerificationDecision) => p.requestDate)
)

const byCompleteDate = pipe(
  eqDate,
  Eq.contramap((p: VerificationDecision) => p.completeDate)
)

export const LatestVerificationDecision = (decisions: VerificationDecision[]) =>
  pipe(
    decisions,
    NEA.groupBy((d) => d.checklistName),
    R.map(flow(NEA.sort(byCreatedDate), NEA.head))
  )

export const VerificationDecisionHistory = (decisions: VerificationDecision[]): VerificationDecisionChange[] =>
  pipe(decisions, A.sort(byCreatedDate), (decisions) =>
    decisions.map((decision, i) => {
      const nearestDecision = decisions.find((_, j) => _.checklistName === decision.checklistName && j > i)
      const newChange = decision

      return pipe(
        O.fromNullable(nearestDecision),
        O.getOrElse(
          (): VerificationDecision => ({
            ...newChange,
            decision: null,
            comment: null,
            status: '',
            requestDate: null,
            completeDate: null
          })
        ),
        (oldChange): VerificationDecisionChange => {
          const oldValues: string[] = []
          const newValues: string[] = []

          const isChanged = (eq: Eq.Eq<VerificationDecision>) => !eq.equals(newChange, oldChange)
          const add = (f: (c: VerificationDecision) => string) => {
            newValues.push(f(newChange))
            oldValues.push(f(oldChange))
          }

          if (isChanged(byDecision)) {
            add((c) => `Đã nộp: ${FormService.getYesNoName(c.decision)}`)
          }
          if (isChanged(byComment)) {
            add((c) => `Ghi chú: ${c.comment || '-'}`)
          }
          if (isChanged(byStatus)) {
            add((c) => `Trạng thái: ${FormService.getStatusName(c.status)}`)
          }
          if (isChanged(byRequestDate)) {
            add((c) => `Ngày yêu cầu: ${c.requestDate ? Format.date(c.requestDate) : '-'}`)
          }
          if (isChanged(byCompleteDate)) {
            add((c) => `Ngày hoàn tất: ${c.completeDate ? Format.date(c.completeDate) : '-'}`)
          }

          return {
            docId: newChange.checklistName,
            oldValues,
            newValues,
            createdBy: newChange.createdBy,
            createdDate: newChange.createdDate
          }
        }
      )
    })
  )
