/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { FontAwesome5 } from '@expo/vector-icons'
import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle, ActivityIndicator } from 'react-native'
import { assets } from '@pulseops/common'

interface Props {
  title?: string | JSX.Element
  titleRight?: string | JSX.Element
  titleOutline?: string
  allowToggle?: boolean
  isExand?: boolean
  children?: React.ReactNode
  containerStyle?: ViewStyle
  isLoading?: boolean
  isNoPadding?: boolean
  isTransactionToggleExpand?: boolean
  checkApprove?: boolean
  // form?: Omit<UseFormReturn<AgentEvaluationForm.Raw>, 'handleSubmit'>
  isFormQs?: boolean
  isFormCan?: boolean
  isFormQua?: boolean
}

export const Panel: React.FC<Props> = ({
  title,
  titleRight,
  isExand = true,
  isLoading = false,
  children,
  titleOutline,
  allowToggle,
  containerStyle,
  isNoPadding = false,
  isTransactionToggleExpand = false
}) => {
  const [toggle, setToggle] = useState(true)

  const onToggle = () => {
    allowToggle !== false && setToggle(!toggle)
  }

  return (
    <View>
      {!!titleOutline && <Text style={[styles.sectionInfoHeaderTitle, styles.titleOutline]}>{titleOutline}</Text>}
      <View style={[styles.sectionInfo, containerStyle, isNoPadding && styles.noPadding]}>
        <View
          style={[
            styles.sectionInfoHeader,
            !title && styles.sectionInfoHeaderInnerRight,
            isNoPadding && styles.touchableBtn,
            !toggle && styles.bottomPadding
          ]}
        >
          <View style={{ flexDirection: 'row' }}>
            {/* {!!title && !isTransactionToggleExpand && <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>}  */}
            {!!title &&
              !isTransactionToggleExpand &&
              (typeof title === 'string' ? (
                <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
              ) : (
                <View>{title}</View>
              ))}
          </View>
          {!!titleRight && <Text style={styles.sectionInfoHeaderTitle}>{titleRight}</Text>}
          {isExand && !isTransactionToggleExpand && (
            <TouchableOpacity style={{ width: 26, height: 26 }} onPress={onToggle}>
              {toggle ? <assets.iconOpen /> : <assets.iconclose />}
            </TouchableOpacity>
          )}
          {isTransactionToggleExpand && (
            <TouchableOpacity onPress={onToggle} style={styles.transactionContent}>
              {!!title && <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>}
              <FontAwesome5 name={toggle ? 'minus-circle' : 'plus-circle'} color="#1B365D" size={25} />
            </TouchableOpacity>
          )}
        </View>
        {toggle && (
          <View style={styles.sectionInfoContent}>
            {isLoading ? <ActivityIndicator size="large" color="#ED1B2E" /> : children}
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    paddingLeft: 8
  },
  sectionInfoHeaderInnerLeft: {
    justifyContent: 'flex-start'
  },
  sectionInfoHeaderInnerRight: {
    justifyContent: 'flex-end'
  },
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase',
    color: '#4F4F4F'
  },
  sectionInfoContent: {
    marginTop: 16
  },
  noPadding: {
    padding: 0,
    backgroundColor: '#FAFAFA'
  },
  transactionContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  touchableBtn: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  bottomPadding: {
    paddingBottom: 20
  },
  check: {
    width: 20,
    height: 20,
    backgroundColor: '#b7dfb9',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginLeft: 40
  },
  checkAssets: {
    width: 10,
    height: 8,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  }
})
