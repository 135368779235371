import * as t from 'io-ts'

export namespace LBUModal {
  export interface SubmitData {
    active: boolean
    lbu: string
    source: string[]
    updatedBy: string
  }

  export const Item = t.type({
    id: t.string,
    lbu: t.string,
    active: t.boolean,
    source: t.array(t.string)
  })
  export type Item = t.TypeOf<typeof Item>

  export const Items = t.type({
    data: t.array(Item)
  })
  export type Items = t.TypeOf<typeof Items>
}
