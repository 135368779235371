import React from 'react'
import { FileUploadData, PulseOpsFormat } from '@pulseops/common'
import { SC } from './styles'

export const FilePresent: React.FC<{ file: FileUploadData }> = ({ file }) => {
  return (
    <SC.DashBottomContainer>
      <SC.Row>
        <SC.ImageThumb source={{ uri: file.base64 }} />
        <SC.Divider />
        <SC.Colum>
          <SC.NormalText>{file.fileName}</SC.NormalText>
          <SC.ItalicText>{PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD/MM/YYYY HH:mm')}</SC.ItalicText>
        </SC.Colum>
        <SC.Divider />
      </SC.Row>
    </SC.DashBottomContainer>
  )
}
