import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  colors: {
    main: '#ED1B2E'
  },
  fonts: {
    main: 'Open Sans'
  }
}
