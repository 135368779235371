import React from 'react'
import { View, StyleSheet, useWindowDimensions, TouchableOpacity, Text, FlatList } from 'react-native'
import { AppContext, assets, BackOfficePermission, ErrorHandling, useMobile } from '@pulseops/common'
import { PolicyHeader } from './common/PolicyInfoHeader'
import { BOGeneralInfoComponent } from './tabs'
import { BOInquiryComponent } from './tabs/inquiry'
import { BOInfoCheckComponent } from './tabs/Info-check'
import { BOFeeCheckComponent } from './tabs/fee-check'
import { BOStackParamList } from './BOStackParamList'
import { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'

import { ZIO, Throwable } from '@mxt/zio'
import { BOService } from './services'
import { pipe } from 'fp-ts/lib/function'
import { useIsFocused } from '@react-navigation/native'

type Props = StackScreenProps<BOStackParamList, 'BODetailScreen'>
export const BODetailScreen = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width } = useWindowDimensions()

  const { t } = useTranslation()

  const { tabItems } = pipe(
    BOService.getPermission,
    ZIO.absolveOption(() => Throwable('RolesMissing')),
    ZIO.map((role) => {
      let items: {
        id: number
        title: string
        component: JSX.Element
      }[] = []
      role?.includes(BackOfficePermission.Detail.Tab.General) &&
        items.push({
          id: items.length + 1,
          title: t('Tab:GeneralInfo'),
          component: <BOGeneralInfoComponent />
        })

      role?.includes(BackOfficePermission.Detail.Tab.UW) &&
        items.push({
          id: items.length + 1,
          title: 'Thẩm định',
          component: <BOInquiryComponent />
        })

      role?.includes(BackOfficePermission.Detail.Tab.Verify) &&
        items.push({
          id: items.length + 1,
          title: 'Kiểm tra thông tin',
          component: <BOInfoCheckComponent />
        })

      role?.includes(BackOfficePermission.Detail.Tab.PremiumCheck) &&
        items.push({
          id: items.length + 1,
          title: 'Kiểm tra phí',
          component: <BOFeeCheckComponent />
        })
      return {
        roles: role,
        tabItems: items
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    roles: [],
    tabItems: []
  }

  const menus = [
    {
      label: t('BO:QuotationID'),
      icon: <assets.DocumentIcon />,
      value: props.route.params.policyNum
    }
  ]

  const [tabIndex, setTabIndex] = React.useState(1)

  const flatListRef = React.useRef<FlatList>(null)

  const onTabChange = (index: number) => {
    setTabIndex(index)
    flatListRef.current?.scrollToOffset({ offset: (index - 1) * width, animated: true })
  }

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        {
          title: 'Mẫu đơn yêu cầu',
          navigate: () => {
            props.navigation.goBack()
          }
        },
        {
          title: 'Danh sách',
          navigate: () => {
            props.navigation.goBack()
          }
        },
        { title: 'Chi tiết', navigate: null }
      ])
    }
  }, [])

  return (
    <SafeAreaView>
      {/* <BreadCrumb navigation={[
        { title: "", navigate: () => {props.navigation.replace('HomeScreen')} },
        { title: "Mẫu đơn yêu cầu", navigate: () => {props.navigation.goBack()}},
        { title: "Danh sách", navigate: () => {props.navigation.goBack()}},
        { title: "Chi tiết", navigate: null},
      ]}></BreadCrumb> */}
      <PolicyHeader menus={menus} />
      <View style={{ flexDirection: 'row', paddingLeft: isMobile ? 10 : 15, paddingRight: 15, marginTop: 15 }}>
        {tabItems.map(({ title, id }) => (
          <TouchableOpacity
            key={id}
            style={[tabIndex === id ? styles.tabActive : styles.tab, { minWidth: isWide ? 260 : (width - 20) / 3 }]}
            onPress={() => onTabChange(id)}
          >
            <Text style={[styles.tabLabel, tabIndex === id && styles.tabLabelActive, { fontSize: isMobile ? 11 : 14 }]}>
              {title}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <FlatList
        scrollEnabled={false}
        keyExtractor={(_item, index) => index.toString()}
        ref={flatListRef}
        horizontal={true}
        pagingEnabled={true}
        data={tabItems}
        renderItem={({ item }) => {
          return (
            <View
              style={{ width: width, backgroundColor: '#FFF' }}
              onMoveShouldSetResponder={(e) => {
                e.stopPropagation()
                return false
              }}
            >
              {item.component}
            </View>
          )
        }}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9'
  },

  // Tab ++
  tabLabel: {
    paddingTop: 8,
    paddingBottom: 8
  },
  tabLabelActive: {
    color: 'red',
    fontWeight: 'bold'
  },
  tab: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E7',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  tabActive: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  }
})
