import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { markupString } from './Util'
import moment from 'moment'
import { Customer } from '../Client'
import { PulseOpsFormat } from '../../../Formatter'

export interface ConfirmationPremiumReceipt {
  tag: TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT
  source: SourceType
  clientName: string
  documentType: string
  fromDate: string
  toDate: string
  reason: string
}

export const ConfirmationPremiumReceipt = (
  detail: api.ConfirmationPremiumReceipt,
  customer: Customer
): ConfirmationPremiumReceipt => {
  const data = getData(detail)
  return {
    tag: TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT,
    source: detail.source,
    clientName: `${customer.body.surName} ${customer.body.firstName}`,
    documentType: 'Confirmation of premium receipt',
    fromDate: data.fromDate ?? '',
    toDate: data.toDate ?? '',
    reason: data.reason ?? ''
  }
}

const getData = (detail: api.ConfirmationPremiumReceipt) => {
  switch (detail.source) {
    case SourceType.PRUONLINE:
      const poBody = detail.payload.body
      const formatDate = (date: string = '') => moment(date, 'DD/MM/YYYY', true).isValid() ? date : PulseOpsFormat.dateStr(date)

      return {
        fromDate : formatDate(poBody?.fromDate ?? '') || '-',
        toDate : formatDate(poBody?.toDate ?? '') || '-',
        reason: markupString(poBody.reason) || '-'
      }
    case SourceType.ZALO:
    case SourceType.EKIOSK:
      const body = detail.payload.body
      return {
        fromDate: body.fromDate ? moment(new Date(body.fromDate)).format('DD/MM/YYYY') : '-',
        toDate: body.toDate ? moment(new Date(body.toDate)).format('DD/MM/YYYY') : '-',
        reason: markupString(body.reason) || '-'
      }
    case SourceType.PULSE:
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
      return {
        fromDate: '-',
        toDate: '-',
        reason: '-'
      }
    case SourceType.PULSE4OPS:
      return {
        fromDate: detail.payload.body?.fromDate ?? '',
        toDate: detail.payload.body?.toDate ?? '',
        reason: detail.payload.body?.reason ?? ''
      }
  }
}
