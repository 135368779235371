import { ZIO } from '@mxt/zio'
import {
  assets,
  GeneralService,
  PulseOpsFormat,
  RadioButton,
  replaceSpecialCharactersInFileName,
  SelectSearch,
  TransactionType,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  useWindowDimensions,
  View
} from 'react-native'
import { DocumentOption, FileUploadData, generalStyles } from '../../../common'
import * as AddLackOfPayeeForm from '../../add-lack-of-payee-form'

const MB = 1048576
interface Props {
  selectedClaimType?: string
  form: UseFormReturn<AddLackOfPayeeForm.Form>
  setSelectedDocumentType: (documents: DocumentOption[]) => void
  selectedDocumentType: DocumentOption[]
}

export const DocumentInfo = ({ selectedClaimType, form, setSelectedDocumentType, selectedDocumentType }: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t, i18n } = useTranslation('claim')
  const { width } = useWindowDimensions()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [documentTypes, setDocumentTypes] = React.useState<DocumentOption[]>([])
  const [documentCode, setDocumentCode] = React.useState<string>('')
  const flatListRef = React.useRef<FlatList>(null)

  const all = useTranslation().t

  const payeeIsNotPolicyOwner = form.watch('payeeIsNotPolicyOwner')

  const maxSize = 10 * MB
  const maxTotal = 50 * MB
  // const maxFile = 5
  const validExtensions = ['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']

  const [errorMess, setErrorMess] = React.useState<string>('')
  const [currSize, setCurrSize] = React.useState<number>(0)
  const [errorDocType, setErrorDocType] = React.useState<string>('')

  const { watch } = form
  const { documentType } = watch()

  const translator = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)

  // triggerOnNext(() => {
  //   if (selectedDocumentType.filter((s) => s.required).every((s) => s.files.length) && errorMess === '') {
  //     onSubmit(selectedDocumentType)
  //   } else {
  //     if (errorMess === '') setErrorMess(`${all('message:MS020001', { field: t('DocumentInfo') })}`)
  //   }
  // })

  // triggerOnReset(() => {
  //   if (selectedClaimType) {
  //     pipe(
  //       ZIO.zipPar(
  //         GeneralService.getListDocs(selectedClaimType),
  //         GeneralService.getListOptional(TransactionType.NEW_CLAIM_REGISTER)
  //       ),
  //       ZIO.map(([listDocs, listOptionals]) => {
  //         const docs: DocumentOption[] = listDocs
  //           .filter((d) => d.claimTypeCode === selectedClaimType)
  //           .map((d) => ({
  //             value: d.docTypeCode,
  //             label: translator(d.docTypeEN, d.docTypeVN),
  //             required: d.required === 'Y',
  //             files: [],
  //             docTypeCode: d.docTypeCode
  //           }))
  //         const dataSelected = docs.filter((d) => d.required).map((item) => item.value)
  //         const listOfficial = listOptionals.filter((item) => dataSelected.indexOf(item.docTypeCode) === -1)
  //         const list: DocumentOption[] = listOfficial.map((d) => ({
  //           value: d.docTypeCode,
  //           label: translator(d.doctypeEn, d.doctypeVn),
  //           files: [],
  //           required: false,
  //           docTypeCode: d.docTypeCode
  //         }))
  //         const { newSelect, newDocs } = handleData(docs, list)
  //         setDocumentTypes(newDocs)
  //         setSelectedDocumentType(newSelect.filter((d) => d.required))
  //         setDocumentCode('')
  //         setCurrSize(0)
  //         setTabIndex(0)
  //         return ZIO.unit
  //       }),
  //       ZIO.catchAll(() => {
  //         return ZIO.unit
  //       }),
  //       ZIO.unsafeRun({})
  //     )
  //   }
  // })

  React.useEffect(() => {
    pipe(
      // ZIO.zipPar(
      // GeneralService.getListDocs(selectedClaimType),
      //   GeneralService.getListOptional(TransactionType.ADD_LACK_OF_PAYEE)
      // ),
      GeneralService.getListOptional(TransactionType.ADD_LACK_OF_PAYEE_INFO),
      ZIO.map((listOptionals) => {
        // const docs: DocumentOption[] = listDocs
        //   .filter((d) => d.claimTypeCode === selectedClaimType)
        //   .map((d) => ({
        //     value: d.docTypeCode,
        //     label: translator(d.docTypeEN, d.docTypeVN),
        //     required: d.required === 'Y',
        //     files: [],
        //     docTypeCode: d.docTypeCode
        //   }))
        // const dataSelected = docs.filter((d) => d.required).map((item) => item.value)
        // const listOfficial = listOptionals.filter((item) => dataSelected.indexOf(item.docTypeCode) === -1)
        const list: DocumentOption[] = listOptionals.map((d) => ({
          value: d.docTypeCode,
          label: translator(d.doctypeEn, d.doctypeVn),
          files: [],
          required: false,
          docTypeCode: d.docTypeCode
        }))
        setDocumentTypes(list)
        // setSelectedDocumentType(list)
        return ZIO.unit
      }),
      ZIO.catchAll(() => {
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }, [])

  React.useEffect(() => {
    const { newDocs } = handleData(selectedDocumentType, documentTypes)
    // setSelectedDocumentType(newSelect)
    setDocumentTypes(newDocs)
  }, [payeeIsNotPolicyOwner])

  const handleData = (requiredList: DocumentOption[], optionalList: DocumentOption[]) => {
    const thirdPartyDocument = {
      value: 'DPS01',
      label: translator('3th party relative document', 'Chứng từ nhân thân bên thứ 3'),
      required: !!payeeIsNotPolicyOwner,
      files: [],
      docTypeCode: 'DPS01'
    }
    let newSelect = [...requiredList]
    let newDocs = [...optionalList]
    if (payeeIsNotPolicyOwner) {
      newDocs = optionalList.filter((el) => el.docTypeCode !== 'DPS01')
      newSelect = [...requiredList.filter((el) => el.docTypeCode !== 'DPS01'), thirdPartyDocument]
    } else {
      newSelect = requiredList.filter((el) => el.docTypeCode !== 'DPS01')
      if (!optionalList.some((item) => item.docTypeCode === 'DPS01')) {
        newDocs = [...optionalList, thirdPartyDocument]
      }
    }
    return { newSelect, newDocs }
  }

  const setIndexAndScroll = (index: number): void => {
    setTabIndex(index)
    flatListRef.current?.scrollToOffset({ offset: index * width, animated: true })
  }

  const addDoc = () => {
    if (documentType && !selectedDocumentType.find((f) => f.value === documentType.value)) {
      const newSelectedDocs = [...selectedDocumentType]
      const oldDoc = documentTypes.find((f) => f.value === documentType.value)
      oldDoc && newSelectedDocs.push(oldDoc)
      setSelectedDocumentType(newSelectedDocs)
      const cloneDocumentTypes = [...documentTypes]
      const idx = cloneDocumentTypes.findIndex((d) => d.value === documentType.value)
      cloneDocumentTypes.splice(idx, 1)
      setDocumentTypes(cloneDocumentTypes)
      form.setValue('documentType', null)
      setErrorDocType('')
    } else {
      setErrorDocType(`${all('message:MS020009', { field: t('DocumentTypeOptional') })}`)
    }
  }

  const deleteDoc = (doc: DocumentOption) => {
    const newSelectedDocs = [...selectedDocumentType]
    const idx = newSelectedDocs.findIndex((d) => d.value === doc.value)
    if (idx > -1) {
      newSelectedDocs[idx].files = []
      newSelectedDocs.splice(idx, 1)
      setSelectedDocumentType(newSelectedDocs)
      setTabIndex(0)
    }
    const cloneDocumentTypes = [...documentTypes]
    cloneDocumentTypes.push(doc)
    setDocumentTypes(cloneDocumentTypes)
  }

  const onUploaded = (files: FileUploadData[]) => {
    const file = files[0]

    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      setErrorMess(all('message:MS040003', { files: validExtensions.join('/') }))
    } else if (file.size >= maxSize) {
      setErrorMess(all('message:MS040004'))
    } else if (currSize + file.size >= maxTotal) {
      setErrorMess(all('message:MS040005'))
    }
    // else if (selectedDocumentType.length >= maxFile) {
    //   setErrorMess(all('message:MS050279',{size: maxFile}))
    // }
    else {
      if (tabIndex === undefined) return
      const cloneSelectedDoc = [...selectedDocumentType]
      cloneSelectedDoc[tabIndex].files = [...cloneSelectedDoc[tabIndex].files, file]
      setSelectedDocumentType(cloneSelectedDoc)
      setCurrSize(currSize + file.size)
      setErrorMess('')
    }
  }

  const deleteAttachment = (tabIdx: number, attachmentIdx: number) => {
    const currSelectedDocs = [...selectedDocumentType]
    const currDoc = currSelectedDocs[tabIdx]
    if (currSize - currDoc.files[attachmentIdx].size < maxTotal) setErrorMess('')
    setCurrSize(currSize - currDoc.files[attachmentIdx].size)
    currDoc.files.splice(attachmentIdx, 1)
    setDocumentTypes(currSelectedDocs)
  }

  return (
    <View style={{ flexDirection: 'column' }}>
      <View style={{ width: '100%', marginTop: 20, flexDirection: 'row' }}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="documentType"
            control={form.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectSearch
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('DocumentTypeOptional')}
                options={documentTypes.map((d) => ({ value: d.value, label: d.label }))}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                key={value?.value}
              />
            )}
          />
        </View>
        <TouchableHighlight style={styles.addBtn} onPress={() => addDoc()}>
          <Text style={[styles.submitTextWhite]}>{t('Add')}</Text>
        </TouchableHighlight>
      </View>
      <Text style={{ color: '#ed1b2c' }}>{errorDocType}</Text>
      <Text style={[styles.sectionTitle, { paddingBottom: 10 }]}>{t('DocumentInfo')}</Text>
      <Text style={{ fontStyle: 'italic', color: '#70777E' }}>{t('ImageFormat')}</Text>
      <View style={[styles.sectionContainer]}>
        <ScrollView horizontal={true}>
          {selectedDocumentType.map((doc, index) => (
            <TouchableOpacity
              key={`doc.value-${doc.value}-${index}`}
              style={[
                tabIndex === index
                  ? {
                      borderRadius: 15,
                      backgroundColor: '#E5EAEF'
                    }
                  : {},
                {
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 23,
                  paddingRight: 23,
                  flexDirection: 'row'
                }
              ]}
              onPress={() => setIndexAndScroll(index)}
            >
              <Text style={tabIndex === index ? { fontWeight: 'bold' } : { color: '#70777E' }}>{doc.label}</Text>
              {doc.required && <Text style={{ color: 'red' }}> *</Text>}
              {!doc.required && (
                <TouchableOpacity style={{ marginLeft: 4 }} onPress={() => deleteDoc(doc)}>
                  <assets.RemoveIcon />
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          ))}
        </ScrollView>

        <View style={{ flexDirection: 'row', width: '100%' }}>
          {selectedDocumentType.map((document, index) => (
            <View
              style={{
                width: '100%',
                marginTop: 30,
                flexDirection: isMobile ? 'column' : 'row',
                display: index === tabIndex ? 'flex' : 'none'
              }}
              key={`selected-doc-${index}`}
            >
              <View style={{ flexDirection: 'column', marginBottom: 23 }}>
                <View style={{ flexDirection: 'row', marginBottom: 3 }}>
                  <Text style={{ fontWeight: 'bold', color: '#70777E' }}>{t('ValidDocument')}</Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: 5 }}>
                  <RadioButton
                    label={t('common:Original')}
                    selected={documentCode === 'DF01'}
                    style={{ marginRight: 28, marginBottom: 5 }}
                    onChange={() => setDocumentCode('DF01')}
                  />
                  <RadioButton
                    label={t('common:OriginalCopy')}
                    selected={documentCode === 'DF02'}
                    style={{ marginRight: 28, marginBottom: 5 }}
                    onChange={() => setDocumentCode('DF02')}
                  />
                </View>

                {isWide && <AddMore onUploaded={onUploaded} />}
              </View>
              {isMobile && (
                <View
                  style={{
                    borderBottomColor: '#C4C4C4',
                    borderBottomWidth: 1,
                    marginBottom: 30
                  }}
                />
              )}
              <ScrollView style={{ height: 170 }}>
                <View
                  style={{
                    flex: 1,
                    flexWrap: 'wrap',
                    marginTop: 8,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}
                >
                  {document.files?.map((file: FileUploadData, attachmentIdx) => (
                    <View
                      key={`File: ${file.fileName}-${attachmentIdx}`}
                      style={{
                        width: isWide ? '50%' : '100%',
                        paddingLeft: isWide ? 15 : 0,
                        paddingBottom: 10,
                        flexDirection: 'row'
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <img src={file.base64} width={38} height={41} />
                      </View>
                      <View style={{ flexDirection: 'column', flex: 3 }}>
                        <Text>{file.fileName}</Text>
                        <Text>{PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD-MM-YYYY hh:mm')}</Text>
                      </View>
                      <TouchableHighlight style={{ flex: 1 }} onPress={() => deleteAttachment(index, attachmentIdx)}>
                        <assets.RemoveIcon />
                      </TouchableHighlight>
                    </View>
                  ))}
                </View>
              </ScrollView>

              {isMobile && <AddMore onUploaded={onUploaded} />}
            </View>
          ))}
        </View>
      </View>

      <Text style={{ color: '#ed1b2c' }}>{errorMess}</Text>
    </View>
  )
}

export const AddMore = ({ onUploaded }: { onUploaded: (file: FileUploadData[]) => void }) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()

  const inputRef = React.createRef<HTMLInputElement>()
  const upload = () => inputRef.current?.click()
  const handleUpload = (files: FileList) => {
    const fileList: FileUploadData[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      // const fileExt = file.name.split('.').pop() ?? ''
      const reader = new FileReader()

      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''

      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && onUploaded(fileList)
      }
    }
  }

  return (
    <TouchableOpacity
      style={
        isMobile
          ? {
              flexDirection: 'row'
            }
          : {
              backgroundColor: '#FFFFFF',
              borderRadius: 8,
              marginTop: 31,
              paddingTop: 18,
              paddingBottom: 18,
              borderWidth: 2,
              borderColor: '#D3DCE6',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            }
      }
      onPress={upload}
    >
      <assets.UploadIcon />
      <Text style={{ color: '#ED1B2E', fontWeight: 'bold', marginLeft: 10 }}>{t('claim:AMF')}</Text>
      <input
        ref={inputRef}
        hidden
        type="file"
        multiple={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.files) {
            handleUpload(event.target.files)
            event.target.files = null
            event.target.value = ''
          }
        }}
      />
    </TouchableOpacity>
  )
}

export const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    addBtn: {
      marginLeft: 26,
      marginRight: 40,
      marginTop: 10,
      justifyContent: 'center',
      backgroundColor: '#ED1B2E',
      borderRadius: 100,
      borderWidth: 1,
      borderColor: '#ED1B2E',
      minWidth: 34,
      height: 40,
      paddingLeft: 29,
      paddingRight: 29
    }
  })
}
