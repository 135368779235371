import { useMobile } from '@pulseops/common'
import * as React from 'react'
import { ViewStyle, FlatList, View, StyleSheet, useWindowDimensions, Dimensions } from 'react-native'
import { Tab } from 'react-native-elements'

interface Scene {
  id: number
  title: string
  Component: JSX.Element
}

interface Props {
  initTabIndex?: number
  scenes: Scene[]
  onTabChange?: (tabIdx: number) => void
  containerStyle?: ViewStyle
}

export const TabView = ({ initTabIndex = 0, scenes = [], containerStyle, onTabChange }: Props) => {
  const { isWide, isMobile } = useMobile()
  const { width } = useWindowDimensions()

  const [currentTabIndex, setCurrentTabIndex] = React.useState(initTabIndex)

  const flatlistRef = React.useRef<FlatList>(null)
  const onTabChanged = (index: number) => {
    if (index !== null) {
      onTabChange && onTabChange(index)
    }
    if (isMobile) {
      flatlistRef.current?.scrollToOffset({ offset: index * width, animated: false })
    } else {
      setCurrentTabIndex(index)
    }
  }

  return (
    <View style={[styles.container, containerStyle, { width: '100%' }]}>
      {/* Tab Title */}
      <View style={{ marginHorizontal: isWide ? 24 : 10 }}>
        <Tab value={currentTabIndex} onChange={onTabChanged} disableIndicator={true}>
          {scenes.map((scene, index) => {
            const isActive = index == currentTabIndex
            return (
              <Tab.Item
                key={index}
                title={scene.title}
                style={{ minWidth: isWide ? 260 : (Dimensions.get('window').width - 30) / 2 }}
                buttonStyle={{ padding: 0 }}
                titleStyle={[
                  styles.tabTitle,
                  isActive ? styles.tabTitle_Active : styles.tabTitle_Deactive,
                  { fontSize: isWide ? 14 : 13 }
                ]}
                containerStyle={[
                  styles.tabTitleContainer,
                  isActive ? styles.tabTitleContainer_Active : styles.tabTitleContainer_Deactive,
                  {
                    maxWidth: isWide ? 260 : '100%',
                    maxHeight: isWide ? 30 : 40
                  }
                ]}
              />
            )
          })}
        </Tab>
      </View>
      {/* Tab Content */}
      {isWide && (
        <View>
          {scenes
            .filter((res) => res.id - 1 === currentTabIndex)
            .map((screen, index) => (
              <View key={index}>{screen.Component}</View>
            ))}
        </View>
      )}
      {isMobile && (
        <FlatList
          scrollEnabled={isMobile}
          keyExtractor={(_item, index) => index.toString()}
          ref={flatlistRef}
          horizontal={true}
          pagingEnabled={true}
          data={scenes}
          onScroll={(e) => {
            if (isMobile) {
              const itemWidth = e.nativeEvent.contentSize.width / scenes.length
              const index = Math.trunc(e.nativeEvent.contentOffset.x / itemWidth)
              if (index !== currentTabIndex) {
                setCurrentTabIndex(index)
              }
            }
          }}
          renderItem={({ item }) => {
            return (
              <View
                style={{ width: width }}
                onMoveShouldSetResponder={(e) => {
                  e.stopPropagation()
                  return false
                }}
              >
                {item.Component}
              </View>
            )
          }}
        />
      )}

      {/* */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // overflow: 'hidden'
  },

  tabTitleContainer: {
    borderTopStartRadius: 14,
    borderTopEndRadius: 14,
    alignSelf: 'baseline',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },

  tabTitleContainer_Active: {
    opacity: 1,
    backgroundColor: '#FFFFFF'
  },

  tabTitleContainer_Deactive: {
    opacity: 0.5,
    backgroundColor: '#E7E7E7'
  },

  tabTitle: {
    textTransform: 'none',
    paddingHorizontal: 0
  },

  tabTitle_Active: {
    color: '#ED1B2E',
    fontWeight: 'bold'
  },

  tabTitle_Deactive: {
    color: '#828282',
    fontWeight: 'normal'
  }
})
