import * as t from 'io-ts'
import { form2 } from '@pulseops/common'
import i18next from 'i18next'
export namespace ChangeOccupationForm {
  export const codec = t.type({
    selectClient: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020001', { field: i18next.t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.selectClient') })
    ),
    occupation: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020001', { field: i18next.t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.occupation') })
    ),
    jobDescription: t.string,
    jobTitle: t.string,
    occupationClass: t.string,
    companyName: t.string,
    companyAddress: t.string,
    salary: form2.selectOption.optional
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
