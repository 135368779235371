import { ZIO } from '@mxt/zio'
import { diffYear } from '@pulseops/submission/common'
import { Console } from 'console'
import { pipe } from 'fp-ts/function'
import moment from 'moment'
import { PulseOpsFormat } from '../Formatter'
import { SubmissionService } from './SubmissionService'
import { TopUpService } from './TopUpService'

export namespace ChangeContractService {
  export const getDetail = (policyNum: string, clientId: string, basicCode: string) =>
    pipe(
      ZIO.zipPar(TopUpService.GetPolicyExtraInfo([policyNum], ''), SubmissionService.getCustomer(clientId ?? '')),
      ZIO.map(([policyExtra, customerInfo]) => {
        const filterProductInfo = policyExtra.policyExtraInfoDetail.filter(
          (x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00' && x.productCode === basicCode
        )
        const dob = PulseOpsFormat.dateStringtoFormat(customerInfo?.body.dob, 'YYYY-MM-DD')
        const riskCessDate = PulseOpsFormat.dateStringtoFormat(filterProductInfo[0]?.riskCessDate, 'YYYY-MM-DD')
        const riskCommDate = PulseOpsFormat.dateStringtoFormat(filterProductInfo[0]?.riskCommDate, 'YYYY-MM-DD')
        const rerateDate = PulseOpsFormat.dateStringtoFormat(filterProductInfo[0]?.rerateDate, 'YYYY-MM-DD')
        return {
          age: diffYear(new Date(), new Date(dob)),
          riskCommDate: filterProductInfo[0]?.riskCommDate,
          currentSumAssured: filterProductInfo[0]?.sumAssured,
          currentPolicyYear: diffYear(new Date(riskCessDate), new Date(riskCommDate)),
          riskCessDate: filterProductInfo[0]?.riskCessDate,
          currentPremiumYear: diffYear(new Date(rerateDate), new Date(riskCommDate))
        }
      })
    )
}
