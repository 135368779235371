import React from 'react'
// import { View } from 'react-native'
import {
  ErrorHandling,
  FieldList,
  AppContext,
  ReinstatementModel,
  ReinstatementService,
  GeneralService,
  PulseOpsFormat,
  SelectOption,
  // StorageBlob,
  FrequencyMapping,
  Panel,
  SelectSearch,
  assets,
  Input,
  ImgUploadMutiple,
  BuildConfig,
  getDiffMonths,
  SubmissionService,
  Frequency,
  Title
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
// import { Subject, debounceTime } from 'rxjs'

import moment from 'moment'
import { MajorCombinedForm } from '../major-combined-form'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { ChooseView } from '../../reinstatement/common'
import { FilePresent, SC, ValueShow } from '../../../common'
import { Platform, View } from 'react-native'
interface Props {
  policyNum: string
  isConfirmed: boolean
  validateBeforeContinue: ({ validateReinstatement }: { validateReinstatement: () => Promise<boolean> }) => void
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}

export const ReinstatementComponent: React.FC<Props> = ({ isConfirmed, policyNum, validateBeforeContinue, form }) => {
  const [loading, bindLoader] = useLoading(false)
  const { t } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const mapFrequency = FrequencyMapping.mapFrequency
  const [afterFirstLoad, setAfterFirstLoad] = React.useState<boolean>(false)
  const [errorAgentCode, setErrorAgentCode] = React.useState<boolean>(false)
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const { getValues, watch } = form

  const formReinstatement = form.watch('reinstatement')

  const checkRequiredHealthDeclare = () => {
    const lapseDate = moment(form.watch('reinstatement.lapsedDate') ?? new Date())
    const endDate = moment(new Date())
    const diffM = getDiffMonths(lapseDate, endDate)
    console.log('diff mounnn', diffM)
    return diffM >= 12 || (diffM < 12 && form.watch('reinstatement.reinClients')?.some((c) => c.healthAnswer))
  }

  const fieldList: FieldList.FieldType[] = [
    {
      label: t('submission:RiskCommencementDate'),
      value: formReinstatement?.riskCommonDate ? moment(formReinstatement?.riskCommonDate).format('DD/MM/YYYY') : '-'
    },
    {
      label: t('submission:LapsedDate'),
      value: formReinstatement?.lapsedDate ? moment(formReinstatement?.lapsedDate).format('DD/MM/YYYY') : '-'
    },
    {
      label: t('submission:BillingFrequency'),
      value: t((mapFrequency.get(formReinstatement?.frequency || '') as Frequency) || '-').toString()
    },
    {
      label: t('submission:InstallmentPremium'),
      value:
        formReinstatement?.installmentPrem || formReinstatement?.installmentPrem === 0
          ? PulseOpsFormat.thousandSepartor(formReinstatement?.installmentPrem) + ' VND'
          : '-'
    },
    {
      label: t('submission:AutomaticPolicyLoan'),
      value:
        formReinstatement?.apl || formReinstatement?.apl === 0
          ? PulseOpsFormat.thousandSepartor(formReinstatement?.apl) + ' VND'
          : '-'
    },
    {
      label: t('submission:OriginalLoan'),
      value:
        formReinstatement?.opl || formReinstatement?.opl === 0
          ? PulseOpsFormat.thousandSepartor(formReinstatement?.opl) + ' VND'
          : '-'
    },
    {
      label: t('submission:ReinstatementFee'),
      value:
        formReinstatement?.reinFee || formReinstatement?.reinFee === 0
          ? PulseOpsFormat.thousandSepartor(formReinstatement?.reinFee) + ' VND'
          : '-'
    },
    {
      label: t('submission:EstimatedTotalReinstatementAmount'),
      value: (
        <SC.SessionText color={'red'}>
          {formReinstatement?.totalReinAmount || formReinstatement?.totalReinAmount === 0
            ? PulseOpsFormat.thousandSepartor(formReinstatement?.totalReinAmount) + ' VND'
            : '-'}
        </SC.SessionText>
      )
    }
  ]

  const { detail, occupationsOptions } = pipe(
    ZIO.zipPar(ReinstatementService.getDetail(policyNum!), GeneralService.getOccupations),
    ZIO.map(([detail, occupations]) => {
      console.log(detail)
      const occupationsOptions = occupations.map((occupation) => {
        return {
          value: occupation.code,
          label: occupation.name
        }
      })
      //return ZIO.unit
      initData(detail, occupationsOptions)
      return {
        detail,
        occupationsOptions
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  const getAgentNameByCode = (agentCode: string) => {
    return pipe(
      ZIO.zipPar(
        ZIO.fromPromise(() => form.trigger('reinstatement.agentCode')),
        ZIO.succeed(agentCode)
      ),
      ZIO.flatMap(([isValid, value]) => {
        if (isValid) {
          return pipe(
            SubmissionService.getAgents([value]),
            ZIO.map((c) => c[0]),
            ZIO.foldM(
              (_) => ZIO.unit,
              (client) => {
                if (client && client.clientId === 'ERROR') {
                  form.setError('reinstatement.agentCode', { message: t('message:MS020019') })
                  setErrorAgentCode(true)
                } else {
                  setErrorAgentCode(false)
                }
                form.setValue('reinstatement.agentName', client?.name || '')
                return ZIO.unit
              }
            )
          )
        }
        form.setValue('reinstatement.agentName', '')
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  validateBeforeContinue({
    validateReinstatement: async () => {
      if (errorAgentCode) {
        form.setError('reinstatement.agentCode', { message: t('message:MS020019') })
        return false
      }

      if (detail) return true
      else return false
    }
  })

  const initData = (detail: ReinstatementModel.Detail, occupations: SelectOption[]) => {
    if (detail && detail.reinData) {
      const {
        reinData: { attributesExt }
      } = detail

      const reinDetails = detail.reinData.lifeAssured.map((client, index) => {
        const currentOccupation = occupations.find((o) => o.value === client.attributesExt.CUR_OCCUPATION)
        const reinDetail: MajorCombinedForm.ReinDetail = {
          name: client.name,
          clientNum: client.clientId,
          healthAnswer: false,
          occupationAnswer: false,
          activitiesAnswer: false,
          companyAnswer: false,
          curOccupation: currentOccupation ?? { label: '', value: '' }
        }
        return reinDetail
      })

      form.setValue('reinstatement', {
        reinFee: attributesExt.REINS_FEE ?? 0,
        riskCommonDate: attributesExt.RISK_COMM_DATE,
        lapsedDate: attributesExt.LAPSED_DATE,
        frequency: attributesExt.FREQUENCY ?? '',
        installmentPrem: attributesExt.INSTALLMENT_PREM ?? 0,
        agentCode: attributesExt.AGENT_CODE,
        agentName: attributesExt.AGENT_NAME,
        transCode: attributesExt.TRANS_CODE,
        opl: attributesExt.OPL,
        apl: attributesExt.APL,
        clientId: detail.owner.body.clientId,
        reinClients: reinDetails,
        totalReinAmount: attributesExt.TOTAL_REINS_AMOUNT ?? 0,
        attachmentFiles: []
      })
      setAfterFirstLoad(true)
    }
  }

  //   if (isConfirmed) {
  //     return <ConfirmTab form={form.getValues()} headerFields={fieldList} />
  //   }

  //   return <RequestTab form={form} occupations={occupationsOptions || []} headerFields={fieldList} />
  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <Title wrapperStyle={{ marginTop: 5 }} title={t('submission:ReinstatementInfomation').toUpperCase()}></Title>
      </SC.Padding>
      <SC.PanelContainer>
        <FieldList dataSource={fieldList} />
      </SC.PanelContainer>
      <SC.Padding vertical={10}>
        <Title wrapperStyle={{ marginTop: 20 }} title={t('submission:StatementOfInsurability').toUpperCase()}></Title>
      </SC.Padding>
      {watch('reinstatement.reinClients') &&
        watch('reinstatement.reinClients').map((value, index) => {
          return (
            <Controller
              key={`list_rein_${index}`}
              control={form.control}
              name={`reinstatement.reinClients.${index}`}
              render={({ field: { value, onChange, onBlur } }) => {
                return value ? (
                  <Panel title={value?.name || ''} containerStyle={{ backgroundColor: '#fafafa' }}>
                    <ChooseView
                      disable={isConfirmed}
                      title={t('submission:AnyChangesInStateOfHealth')}
                      selected={value.healthAnswer}
                      onChange={(select) => onChange({ ...value, healthAnswer: select })}
                    />

                    <ChooseView
                      disable={isConfirmed}
                      title={t('submission:AnyChangesInOccupation')}
                      selected={value.occupationAnswer}
                      onChange={(select) => onChange({ ...value, occupationAnswer: select })}
                    />
                    {value.occupationAnswer && (
                      <SC.Row>
                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <ValueShow
                              title={t('submission:CurrentOccupation')}
                              value={value.curOccupation?.label || ''}
                            />
                          </SC.Padding>
                        </SC.InputContainer>

                        <SC.Divider width={20} />

                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <Controller
                              key={`newOccupation_${index}`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: t('message:MS020001', { field: t('submission:NewOccupation').toLowerCase() })
                                }
                              }}
                              name={`reinstatement.reinClients.${index}.newOccupation`}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <SelectSearch
                                  disabled={isConfirmed}
                                  options={occupationsOptions || []}
                                  value={value}
                                  label={t('submission:NewOccupation')}
                                  required
                                  onChange={onChange}
                                  placeholder={t('common:Select')}
                                  popupIcon={<assets.ArrowDownDropdownIcon />}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}

                    <ChooseView
                      disable={isConfirmed}
                      title={t('submission:AnyChangeInActivities')}
                      selected={value.activitiesAnswer}
                      onChange={(select) => onChange({ ...value, activitiesAnswer: select })}
                    />

                    {value.activitiesAnswer && (
                      <SC.Row>
                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <Controller
                              key={`newActivity_${index}`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: t('message:MS020001', { field: t('submission:NewActivity').toLowerCase() })
                                }
                              }}
                              name={`reinstatement.reinClients.${index}.newActivity`}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Input
                                  disabled={isConfirmed}
                                  title={t('submission:NewActivity')}
                                  value={value}
                                  onChange={onChange}
                                  required
                                  onBlur={onBlur}
                                  maxLength={100}
                                  // placeholder={'Input'}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}
                    <ChooseView
                      disable={isConfirmed}
                      title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                      selected={value.companyAnswer}
                      onChange={(select) => onChange({ ...value, companyAnswer: select })}
                    />

                    {value.companyAnswer && (
                      <SC.Row>
                        <SC.InputContainer>
                          <SC.Padding vertical={5}>
                            <Controller
                              key={`newCompany_${index}`}
                              control={form.control}
                              rules={{
                                required: {
                                  value: true,
                                  message: t('message:MS020001', { field: t('submission:CompanyName').toLowerCase() })
                                }
                              }}
                              name={`reinstatement.reinClients.${index}.newCompany`}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Input
                                  disabled={isConfirmed}
                                  title={t('submission:CompanyName')}
                                  value={value}
                                  onChange={onChange}
                                  required
                                  onBlur={onBlur}
                                  // placeholder={'Input'}
                                  maxLength={100}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}
                  </Panel>
                ) : (
                  <></>
                )
              }}
            />
          )
        })}

      {isConfirmed
        ? form.watch('reinstatement.attachmentFiles') &&
          form.watch('reinstatement.attachmentFiles').length > 0 && (
            <SC.Padding>
              <SC.Padding vertical={10} horizontal={10}>
                <SC.TitleText>{t('submission:HealthDeclaration')}</SC.TitleText>
              </SC.Padding>
              <SC.Row>
                {form.watch('reinstatement.attachmentFiles').map((value, index) => {
                  const wrapValue = { ...value, uploadedDate: value.uploadedDate!, file: value.file! }
                  return (
                    <SC.InputContainer key={`file_health_declaration_${index}`}>
                      <SC.Padding horizontal={10}>
                        <FilePresent file={wrapValue} />
                      </SC.Padding>
                    </SC.InputContainer>
                  )
                })}
              </SC.Row>
            </SC.Padding>
          )
        : checkRequiredHealthDeclare() && (
            <SC.Padding>
              {Platform.OS === 'web' && (
                <SC.BoldText>
                  {t('submission:HealthNote')}
                  <a href={`${assets.UpdatedRedatingHealthyTemplate}`} download style={{ color: 'red' }}>
                    {t('submission:Here')}
                  </a>
                </SC.BoldText>
              )}
              <Controller
                control={form.control}
                name="reinstatement.attachmentFiles"
                rules={{
                  validate: (val) => {
                    if (!!!val || val?.length === 0) {
                      return `${t('message:MS020001', { field: t('submission:HealthDeclaration') })}`
                    } 
                    return true
                  }
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  const wrapFile =
                    value?.map((file) => ({ ...file, uploadedDate: file.uploadedDate!, file: file.file! })) || []
                  return (
                    <SC.Padding>
                      <SC.Padding top={8}>
                        <SC.BoldText>
                          {t('submission:HealthDeclaration')} <SC.ErrorText>*</SC.ErrorText>
                        </SC.BoldText>
                      </SC.Padding>
                      <ImgUploadMutiple value={wrapFile} onChange={onChange} />
                      <SC.ErrorText>{error?.message ?? ''}</SC.ErrorText>
                    </SC.Padding>
                  )
                }}
              />
            </SC.Padding>
          )}

      <SC.Padding vertical={10}>
        <Title wrapperStyle={{ marginTop: 20 }} title={t('submission:ReinstatementAgent').toUpperCase()}></Title>
      </SC.Padding>
      {afterFirstLoad && (
        <SC.PanelContainer>
          <SC.Row>
            <Controller
              control={form.control}
              name={'reinstatement.agentCode'}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020001', { field: t('utilities:AgentCode') })
                },
                validate: (value) => (value && !/\b\d{8}\b/.test(value) ? `${t('message:MS100005')}` : true)
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <SC.InputContainer minWidth={300}>
                    <Input
                      disabled={isConfirmed}
                      title={t('submission:Agent')}
                      value={value && form.watch('reinstatement.agentCode')}
                      onBlur={() => {
                        console.log(value, 'agentCode')
                        onBlur()
                        if (!isConfirmed) getAgentNameByCode(value)
                      }}
                      maxLength={8}
                      required
                      onChange={(value) => {
                        onChange(value)
                        //const a = await form.trigger('agentCode')
                      }}
                      errorMessage={error?.message ?? ''}
                    />
                  </SC.InputContainer>
                )
              }}
            />
            <SC.Divider />
            <SC.CenterSelf>
              <Controller
                control={form.control}
                name={'reinstatement.agentName'}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <View style={{ alignSelf: 'flex-end', flex: 1 }}>
                      <Input value={value} disabled />
                    </View>
                  )
                }}
              />
            </SC.CenterSelf>
          </SC.Row>
        </SC.PanelContainer>
      )}
    </SC.Container>
  )
}
