import { CashOut } from './CashOutOption'
export namespace PrucashPayment {
  export interface Detail {
    policyNum: string
    clientName: string
    clientId: string
    totalCashBenefit: number
    notPaidPremium: number
    loanExceedSurrenderValue: number
    netTotalCashBenefit: number
    coverageCode: string
  }
  export interface AccessData {
    coverageCode: string
    contractStatus: string
    totalCashBenefit: number
  }
  export interface SubmitData {
    policy: {
      owners: {
        clientId: string
        dateOfDeath: string
      }
      attributesExt: {
        inheritanceFlag: 'N' | 'Y'
      }
    }
    cashOutOptions: Array<CashOut.Option>
  }
}
