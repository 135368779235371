import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { IDCSharedStyles } from '../IDCSharedStyles'
import { OBSectionCol, OBSectionContent, OBSectionRow, OBSharedStyles, OBCallingResultDetailFrom, OBContainer, OBCallProgramTypeCode, OBFieldTitle, OBFieldDescription, OBCustomerAnswerConst } from '@pulseops/outbound'
import { Controller } from 'react-hook-form'
import { Container, ErrorHandling, IDCDetailService, Input, PulseOpsFormat, SelectOption, SelectSearch, assets } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { IDCDetailContext } from '../IDCDetailContext'
import * as O from 'fp-ts/lib/Option'
import { useLoading } from '@mxt/zio-react'
import { IDCContainer } from '../independence-components'


export const IDCCallingResultDetail = () => {
  const { t, i18n } = useTranslation()
  const { transactionType, processInstanceID, setIsActiveTabIndex, setOBSelectedCaseID } = React.useContext(IDCDetailContext)
  const [isLoading, bindLoader] = useLoading(false)
  // const processInstanceId = '211b5add-db88-11ee-ab42-2e54100ee1f4'

  const { callingResultDetailList, questionAnswerList } = pipe(
    ZIO.zipPar(
      IDCDetailService.getCallingResultDetail(processInstanceID),
      IDCDetailService.getQuestionAnswerList(transactionType)
    ),
    ZIO.map(([callingResultDetails, questionAnswerList]) => {
      return {
        callingResultDetailList: callingResultDetails,
        questionAnswerList
      }
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([processInstanceID])
  ) || {
    callingResultDetailList: [],
    questionAnswerList: {}
  }

  const getOptions = () => {
    return questionAnswerList && questionAnswerList?.a2s3AnswerList ? questionAnswerList?.a2s3AnswerList?.map((item) => ({
      label: i18n.language === 'en' ? item.answerEN : item.answerVN,
      value: item.answerCode
    })) : []
  }

  const optionItem = (idcType: string) => {
    const IDCTypeName = pipe(
      OBCustomerAnswerConst.IDCTypeConst.find((p) => p.code === idcType),
      O.fromNullable,
      O.fold(
        () => '-',
        (typeItem) => i18n.language === 'en' ? typeItem.nameEn : typeItem.nameVi
      )
    )
    return IDCTypeName
  }

  const isShowNoteBlock = (index: number, answerItem: SelectOption | undefined, questionCode: string) => {
    if (transactionType === OBCallProgramTypeCode.IDC && questionCode && answerItem) {
      return true
    } else {
      return false
    }
  }

  const onViewCaseOBActionHistory = (caseID: string) => {
    setIsActiveTabIndex(2)
    setOBSelectedCaseID && setOBSelectedCaseID(caseID)
  }

  return (
    <Container loading={isLoading}>
      <View style={IDCSharedStyles.container}>
        {callingResultDetailList && callingResultDetailList.length > 0 && callingResultDetailList.map((detailItem, index) => {
          return (
            <>
              <IDCContainer containerStyle={index > 0 && callStyles.secondLine} containerTitle={(isContainerOpen) => (
                <>
                  <View style={IDCSharedStyles.answerHeader}>
                    <View>
                      <OBFieldTitle text={t('Outbound:OBCustomerAnwser:IDCType')}></OBFieldTitle>
                      <OBFieldDescription text={optionItem(detailItem.idcInfo?.idcType || '')}></OBFieldDescription>
                    </View>
                    <View>
                      <OBFieldTitle text={t('IDCDetail:CreateBy')}></OBFieldTitle>
                      <OBFieldDescription text={detailItem.callingResultInfo?.createdBy || ''}></OBFieldDescription>
                    </View>
                    <View>
                      <OBFieldTitle text={t('IDCDetail:CreateDate')}></OBFieldTitle>
                      <OBFieldDescription text={detailItem.callingResultInfo?.createdDate ? PulseOpsFormat.dateStringtoFormat(detailItem.callingResultInfo?.createdDate, 'DD/MM/YYYY - HH:mm:ss') : ''}></OBFieldDescription>
                    </View>
                  </View>
                  {isContainerOpen && (
                    <>
                      <View style={callStyles.openContainer}>
                        <View style={callStyles.openBlock1Container}>
                          <OBFieldTitle text={t('Business key')}></OBFieldTitle>
                          <OBFieldDescription text={detailItem.obBusinessKey || ''}></OBFieldDescription>
                        </View>
                        <View style={callStyles.openBlock2Container}>
                          <TouchableOpacity onPress={() => onViewCaseOBActionHistory(detailItem.obBusinessKey || '')}>
                            <Text style={callStyles.openBlock2ContainerHightLight}>{t('IDCDetail:ViewActionHistoryTab')}</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </>
                  )}
                </>
              )} isOpenContainer={false}>
                <OBSectionContent>
                  <Text style={callStyles.header}>{t('Outbound:OBCalloutDetail:CallingResult')}</Text>
                  <OBSectionRow style={{}}>
                    {detailItem && detailItem.callingResultInfo && detailItem.callingResultInfo.details &&
                      detailItem.callingResultInfo.details.map((item, index) => {
                        const questionName = pipe(
                          questionAnswerList?.a2s1QuestionList?.find((p) => p.questionCode === item.questionCode),
                          O.fromNullable,
                          O.fold(
                            () => '',
                            (questionItem) => {
                              const questionName = `${index + 1}. ${i18n.language === 'en' ? questionItem.questionDescEn : questionItem.questionDescVN}`
                              return questionName
                            }
                          )
                        )
                        const answerItem = pipe(
                          questionAnswerList.a2s3AnswerList?.find((p) => p.answerCode === item.answerCode),
                          O.fromNullable,
                          O.fold(
                            () => ({ label: '', value: '' }),
                            (answerItem) => {
                              // const questionName = `${index + 1}. ${i18n.language === 'en' ? questionItem.questionDescEn : questionItem.questionDescVN}`
                              return { label: i18n.language === 'en' ? answerItem.answerEN : answerItem.answerVN, value: answerItem.answerCode }
                            }
                          )
                        )
                        return (
                          <OBSectionCol style={[OBSharedStyles.sectionCol12, callStyles.secondLine]}>
                            <SelectSearch
                              label={questionName}
                              showPopupIcon={true}
                              value={answerItem}
                              disabled={true}

                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              options={getOptions()}

                            />

                            {isShowNoteBlock(index, answerItem, item.questionCode || '') && (
                              <View style={callStyles.noteContent}>
                                <Input
                                  title={t('Outbound:OBCustomerAnwser:Opinion')}
                                  value={item.answerNote || ''}
                                  required={false}
                                  disabled={true}
                                  multiline={true}
                                  numberOfLines={2}
                                  maxLength={1000}
                                />

                              </View>
                            )}
                          </OBSectionCol>
                        )
                      })}
                  </OBSectionRow>
                  <OBSectionRow style={{ marginTop: 16 }}>
                    <OBSectionCol style={OBSharedStyles.sectionCol12}>
                      <Input
                        title={t('Outbound:OBCalloutDetail:DetailsNote')}
                        multiline={true}
                        numberOfLines={5}
                        labelStyle={{ marginBottom: 8 }}
                        value={detailItem.callingResultInfo?.note || ''}
                        maxLength={5000}
                        disabled={true}
                      ></Input>
                    </OBSectionCol>
                  </OBSectionRow>
                </OBSectionContent>
              </IDCContainer>
            </>
          )
        })}
      </View>
    </Container>

  )
}

const callStyles = StyleSheet.create({
  container: {
    display: 'flex',
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingVertical: 16
  },
  panelContainer: {
    paddingLeft: 24,
    marginBottom: 24
  },
  header: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16,
    marginBottom: 16
  },
  secondLine: {
    marginTop: 24
  },
  noteContent: {
    backgroundColor: '#F9F9F9',
    padding: 20,
    marginTop: 24
  },
  openContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  openBlock1Container: {
    display: 'flex'
  },
  openBlock2Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingLeft: 24,
    paddingBottom: 5
  },
  openBlock2ContainerHightLight: {
    color: 'rgb(30, 165, 252)',
    textDecorationLine: 'underline',
    textDecorationColor: 'rgb(30, 165, 252)',
    textAlign: 'center'
  }
})