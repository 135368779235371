import { makeStyles } from '@material-ui/core'
import { StyleSheet, View, Text } from 'react-native'
import styled from 'styled-components'
export const OBSharedStyles = StyleSheet.create({
  policyInfoContainer: {
    marginVertical: 24
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  actionContent: {
    minHeight: 30,
    maxHeight: 37,
    paddingHorizontal: 35,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#ED1B2E'
  },
  actionContentText: {
    color: '#ED1B2E'
  },
  actionHightLight: {
    backgroundColor: '#ED1B2E'
  },
  actionHightLightText: {
    color: '#fff'
  },
  actionDisable: {
    backgroundColor: '#B0B0B0',
    borderColor: '#B0B0B0'
  },
  actionNonBackgroundDisable: {
    borderColor: '#B0B0B0'
  },
  actionDisableText: {
    color: '#FFFFFF'
  },
  actionNonBackgroundDisableText: {
    color: '#B0B0B0'
  },
  generalSecondLine: {
    marginTop: 16
  },
  generalHeaderTitle: {
    marginBottom: 19
  },
  sectionCol1: {
    width: '100%',
    maxWidth: '8.333333%',
    paddingHorizontal: 15
  },
  sectionCol2: {
    width: '100%',
    maxWidth: '16.666667%',
    paddingHorizontal: 15
  },
  sectionCol4: {
    width: '100%',
    maxWidth: '33.333333%',
    paddingHorizontal: 15
  },
  sectionCol8: {
    width: '100%',
    maxWidth: '66.666667%',
    paddingHorizontal: 15
  },
  sectionCol6: {
    width: '100%',
    maxWidth: '50%',
    paddingHorizontal: 15
  },
  sectionCol7: {
    width: '100%',
    maxWidth: '58.333333%',
    paddingHorizontal: 15
  },
  sectionCol10: {
    width: '100%',
    maxWidth: '83.333333%',
    paddingHorizontal: 15
  },
  sectionCol11: {
    width: '100%',
    maxWidth: '91.666667%',
    paddingHorizontal: 15
  },
  sectionCol12: {
    width: '100%',
    maxWidth: '100%',
    paddingHorizontal: 15
  },
  containerPadding: {
    // paddingVertical: 16,
    paddingHorizontal: 24,
    borderColor: '#D3DCE6',
    backgroundColor: '#fff'
  },
})

export const OBMuiTableStyles = makeStyles({
  root: {
    "& .MuiTableHead-root": {
      backgroundColor: '#F4F4F4'
    },
    "& .MuiTableCell-head": {
      color: '#58647A',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    "& .MuiTableCell-body": {
      color: '#1C1D1F',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
    }
  }
})

export const SC = {
  Container: styled(View)`
    width: 100%;
    align-self: center;
  `,
  CenterSelf: styled(View)`
    align-self: center;
  `,
  PanelContainer: styled(View) <{ backgroundColor?: string }>`
    border: 1px solid #d3dce6;
    border-radius: 8px;
    background-color: ${(p) => p.backgroundColor ?? '#fafafa'};
    padding: 16px;
    :hover {
      background-color: red;
    }
  `,
  Row: styled(View)`
    flex-direction: row;
    justify-content: space-beetween;
    //align-items: flex-end;
    flex-wrap: wrap;
  `,
  Input: styled(View).attrs<{ flex?: number }>((p) => ({
    style: {
      flex: p.flex
    }
  }))``,
  SessionText: styled(Text)`
    font-weight: bold;
  `,
  TitleText: styled(Text) <{ fontSize?: number }>`
    font-weight: bold;
    color: #70777e;
    font-size: ${(p) => (p.fontSize ?? 15) + 'px'};
  `,
  RequiredMark: styled(Text)`
    color: #ed1b2c;
  `,
  NormalText: styled(Text)``,
  Divider: styled(View) <{ height?: number; width?: number; spacer?: boolean }>`
    width: ${(p) => (p.width ?? 10) + 'px'};
    height: ${(p) => (p.height ?? 10) + 'px'};
  `,
  Colum: styled(View) <{ flex?: number }>`
    flex: ${(p) => p.flex || 1};
  `,
  ErrorText: styled(Text)`
    color: red;
  `,
  InfoText: styled(Text) <{ color?: string }>`
    color: ${(props) => props.color || 'red'};
    padding: 20px 0px 20px 0px;
  `,
  InputContainer: styled(View) <{ flex?: number; maxWidth?: number }>`
    flex: ${(p) => p.flex || 1};
    max-width: ${(p) => (p.maxWidth || 250) + 'px'};
  `,
  Padding: styled(View) <{
    top?: number
    bottom?: number
    left?: number
    right?: number
    vertical?: number
    horizontal?: number
  }>`
    padding-top: ${(p) => p.top || p.vertical || 0};
    padding-bottom: ${(p) => p.bottom || p.vertical || 0};
    padding-right: ${(p) => p.right || p.horizontal || 0};
    padding-left: ${(p) => p.left || p.horizontal || 0};
  `,
  BorderView: styled(View) <{}>`
    border: 1px solid #e5eaef;
  `
}
