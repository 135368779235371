import { FontAwesome5 } from '@expo/vector-icons'
import { assets } from '@pulseops/common'
import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'

interface Props {
  title: string
  isExand?: boolean
  children?: React.ReactNode
  isHiddenTitle: boolean
  styling?: ViewStyle
  isArrowExpand?: boolean
  isHaveComment?: boolean
  comment?: String
  isAllowToggle?: boolean
  breadcrumb?: React.ReactNode
}

export const Panel: React.FC<Props> = ({
  title,
  isExand,
  children,
  isHiddenTitle,
  styling = styles.sectionInfo,
  isArrowExpand = false,
  isHaveComment = false,
  comment = '',
  isAllowToggle = true,
  breadcrumb
}) => {
  const [toggle, setToggle] = React.useState(true)

  const onToggle = () => {
    setToggle((prevState: boolean) => !prevState)
  }

  const handleChangeTitle = (): JSX.Element | undefined => {
    if (isHiddenTitle === true) {
      if (toggle === true) {
        return <Text style={styles.sectionInfoHeaderTitle}></Text>
      } else {
        return <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
      }
    } else {
      return <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
    }
  }

  return (
    <View style={styling}>
      <View style={styles.sectionInfoHeader}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {handleChangeTitle()}
          {isHaveComment === true && (
            <Text style={{ color: '#70777E', marginStart: 25, fontSize: 13 }}>added a comment - {comment}</Text>
          )}
          {breadcrumb}
        </View>

        {isExand ? (
          <TouchableOpacity onPress={onToggle} disabled={!isAllowToggle}>
            {isArrowExpand === true ? (
              <assets.ArrowDownDropdownIcon />
            ) : (
              <FontAwesome5 name={toggle ? 'minus-circle' : 'plus-circle'} color="#1B365D" size={25} />
            )}
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
      {toggle && <View style={styles.sectionInfoContent}>{children}</View>}
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  sectionInfoHeaderTitle: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfoContent: {
    marginTop: 16
  },
  currencyText: {
    fontStyle: 'italic'
  }
})
