import { POApi } from "@pulseops/common"
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { Maybe } from "@mxt/zio/codec"

export namespace OBCustomerAnswerService {

  export enum IDCType {
    SAMPLING = 'SAMPLING',
    NEGATIVE = 'NEGATIVE',
    NORESPONSE = 'NORESPONSE'
  }

  export const AnswerResultInfo = t.type({
    questionCode: t.string,
    questionContent: t.string,
    commentUser: t.string,
    decisionResult: t.string,
    answerUser: Maybe(t.string),
    passCheck: Maybe(t.string)
  })

  const CustomerAnswerResponse = t.type({
    totalResult: Maybe(t.string),
    answerResults: Maybe(t.array(AnswerResultInfo)),
    createdDate: Maybe(t.string),
    timeRetry: Maybe(t.number),
    completeTimeQuestion: Maybe(t.string),
    smsSenderDate: Maybe(t.string)
  })

  export type AnswerResultInfo = t.OutputOf<typeof AnswerResultInfo>
  export type CustomerAnswerResponse = t.OutputOf<typeof CustomerAnswerResponse>

  export const getCustomerAnswer = (businessKey: string) => {
    return pipe(
      POApi.get(`independence-rest/api/v1/idc/answer-result/${businessKey}`)(t.type({
        body: CustomerAnswerResponse
      })),
      ZIO.foldM(
        (e) => ZIO.fail(e),
        (success) => ZIO.succeed(success.body)
      )
    )
  }
}