import i18n, { Resource } from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources: Record<string, Resource> = {
  en: require('./trans/en.json'),
  vi: require('./trans/vi.json')
}

export const initI18n = () =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: localStorage.getItem('locale') === 'en-US' ? 'en' : 'vi', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    })

type TransKey = typeof import('./trans/en.json')
export const useTranslator = <T1 extends keyof TransKey>(ns: T1) => {
  const { t: translate } = useTranslation(ns)
  const t = (key: string) => translate(key as any) ?? '-'
  return { t }
}

export const getLanguage = () => {
  return i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en'
}

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang)
}
