import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, Pressable, TouchableOpacity, TextInput } from 'react-native'
import {
  assets,
  Input,
  PulseOpsFormat,
  ReactableView,
  Select,
  SelectOption,
  // SelectTable,
  TaskDetail,
  Alert,
  AppContext,
  SelectSearch
} from '@pulseops/common'
import { PCReason, SacsData } from '../mock'
import { CashLessFormBase, defaultReceipt, defaultSelect } from './cash-less-form'
import { calAmountAction } from './helper'
import { ContainerView, styles, useStyles } from './styles'
import { SelectTable } from '../common'
import _ from 'lodash'

export const Reciepts = (props: {
  form: CashLessFormBase
  detail: TaskDetail.VeriCashLess
  canAction: boolean
  remainingAmount: number
  mapTablePendingCode: Map<PCReason.ActionEnum, SelectOption[]>
}) => {
  const { form, detail, canAction, mapTablePendingCode, remainingAmount } = props

  const { control } = form
  const aboutSelect = React.useMemo(() => [defaultSelect, ...PCReason.getSelectOptions()], [])
  const [ruleLA, setRuleLA] = React.useState<boolean>(false)
  const formState = useFormState({ control })
  const { t: ca } = useTranslation('Cashless')
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const { fields } = useFieldArray({
    control,
    name: 'receips'
  })

  const showTitle = React.useMemo(() => {
    return canAction || fields.filter((f) => !f.receiptNo).length > 0 // ẩn title nếu ko phải là màn hình ver hoặc ở màn hình khác ver nhưng không tồn tại receipt
  }, [fields])

  const { errors } = useFormState({ control: form.control })

  //console.log('123123123123123123', remainingAmount)
  const amountReceipt = (index: number) =>
    useWatch({ control: form.control, name: `receips.${index}.info` }).reduce<number>((pre, cur) => {
      return pre + Number(PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'))
    }, 0) ?? 0
  return (
    <View>
      {showTitle && <Text style={styles.sessonText}>{ca('receiptDetail')}</Text>}

      {(fields ?? []).map((v, index) => {
        const canTouch = canAction && !v.receiptNo

        if (!!v.receiptNo) {
          // ẩn view này nếu có receiptNo, đã show ở receipt list bên dưới
          return <View />
        }

        return (
          <View>
            <Controller
              key={v.id}
              control={control}
              name={`receips.${index}`}
              render={({ field }) => {
                const payinMethod = field.value.about?.value
                // const sacCode = getListSacCodeByPayinCode(payinMethod ? (payinMethod as PCReason.ReasonEnum) : undefined)
                return (
                  <ReactableView disable={!canTouch}>
                    <ContainerView>
                      <View style={styles.row}>
                        <View style={styles.selectContainer}>
                          <Controller
                            control={control}
                            name={`receips.${index}.about`}
                            defaultValue={defaultSelect}
                            render={({ field: { value, onChange } }) => {
                              return (
                                <Select
                                  value={value}
                                  onChange={(v) => {
                                    onChange(v)
                                    const validLARule =
                                      detail.policyStatus === 'LA' &&
                                      ['J', 'G', 'R', 'L'].includes(v?.value.charAt(v?.value.length - 1) || '-')

                                    setRuleLA(validLARule)

                                    if (validLARule) {
                                      showToast(ca('receiptContractStatusErr'), 'error')
                                    }

                                    form.setValue(`receips.${index}.pendingCode`, defaultSelect)

                                    const currentRecipts = form.getValues(`receips.${index}`)
                                    form.setValue(`receips.${index}`, {
                                      ...currentRecipts,
                                      about: v,
                                      info: [
                                        {
                                          sacsCode: null,
                                          amount: '',
                                          sacsTypeSelection: [],
                                          sacsType: null,
                                          allowEdit: !validLARule,
                                          allowDetele: true
                                        }
                                      ]
                                    })
                                  }}
                                  options={aboutSelect}
                                  label={ca('chooseAction')}
                                />
                              )
                            }}
                          />
                        </View>
                        <View style={styles.selectContainer}>
                          <Controller
                            control={control}
                            name={`receips.${index}.pendingCode`}
                            defaultValue={defaultSelect}
                            render={({ field, fieldState }) => {
                              const action = useWatch({ control: control, name: `receips.${index}.about` })
                              const listPendings = mapTablePendingCode.get(action?.value as PCReason.ActionEnum) ?? []
                              const pendingCode = form.watch(`receips.${index}.pendingCode`)
                              const findPending = listPendings.find(
                                (x) => x.value === pendingCode?.value && x.label !== pendingCode?.label
                              )
                              if (findPending) {
                                form.setValue(`receips.${index}.pendingCode`, findPending)
                              }
                              return (
                                <SelectSearch
                                  {...field}
                                  options={listPendings}
                                  label={ca('trancode')}
                                  onChange={(v) => {
                                    field.onChange(v)
                                    form.setValue(`receips.${index}`, {
                                      ...form.getValues(`receips.${index}`),
                                      pendingCode: v
                                    })
                                  }}
                                  errorMessage={_.get(formState.errors, `receips.${index}.message`)}
                                />
                              )
                            }}
                          />
                        </View>
                        <View style={styles.selectContainer}>
                          <View style={{ borderBottomWidth: 1, borderBottomColor: '#D3DCE6' }}>
                            <Input
                              title={ca('amount')}
                              value={PulseOpsFormat.thousandSepartor(amountReceipt(index)) + ' VND'}
                              maxLength={15}
                              disabled={true}
                              inputStyle={{
                                fontWeight: amountReceipt(index) === 0 ? 'normal' : 'bold'
                              }}
                            />
                          </View>
                        </View>
                      </View>
                      <ReciptTable
                        form={form}
                        tableIndex={index}
                        detail={detail}
                        key={`table_r${index}${payinMethod}`}
                        remainingAmount={remainingAmount}
                        canAction={canAction}
                        ruleLA={ruleLA}
                      />
                    </ContainerView>
                  </ReactableView>
                )
              }}
            />

            {canTouch && ( // chỉ cho xoá khi đang trong màn hình Ver hoặc không tồn tại ReceipNo trong Receip (có receiptNo nghĩa là đã tồn tại trong core)
              <View style={{ position: 'absolute', top: 7, right: 7 }}>
                <Pressable
                  onPress={() =>
                    Alert.modal({
                      title: ca('warning'),
                      content: ca('warningDeleteReceipt'),
                      size: {
                        width: '30%'
                      },
                      onOK: () => {
                        const receipts = form.getValues(`receips`)
                        form.setValue(`receips`, [...receipts.slice(0, index), ...receipts.slice(index + 1)])
                      }
                    })
                  }
                >
                  <assets.IconXRed />
                </Pressable>
              </View>
            )}
          </View>
        )
      })}

      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={[styles.helperText, { paddingBottom: 16 }]}>{_.get(errors, 'receips.message')}</Text>
        {canAction && (
          <TouchableOpacity
            style={[styles.Button, { alignSelf: 'flex-end', marginEnd: 40 }]}
            onPress={() => {
              //append(defaultReceipt) // dùng hàm này đôi khi bị crash app :))
              form.setValue('receips', [
                ...(form.getValues('receips') ?? []),
                { ...defaultReceipt, about: defaultSelect }
              ])
            }}
          >
            <Text style={[styles.boldText, { fontSize: 12, color: '#fff' }]}>{ca('addReceipt')}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

const ReciptTable = (params: {
  form: CashLessFormBase
  tableIndex: number
  detail: TaskDetail.VeriCashLess
  canAction: boolean
  remainingAmount: number
  ruleLA: boolean
}) => {
  const { form, tableIndex, detail, canAction, remainingAmount, ruleLA } = params
  // const { fields } = useFieldArray({ control: form.control, name: `receips.${tableIndex}.info` })
  const classes = useStyles()
  const {
    t: ca,
    i18n: { language }
  } = useTranslation('Cashless')
  const { fields, append } = useFieldArray({
    control: form.control,
    name: `receips.${tableIndex}.info`
  })

  const payinMethod = useWatch({ control: form.control, name: `receips.${tableIndex}.about.value` })

  const receipNo = form.getValues(`receips.${tableIndex}.receiptNo`)

  // only show with verification, hide with QC screen and inquiry, hide with receipt have receiptNo
  const showButton = canAction && !receipNo

  //   const { info } = useWatch({
  //     control: form.control,
  //     name: `receips.${tableIndex}`
  //   })

  //   const _allSacType = React.useMemo(() => {
  //     return (info ?? []).map((i) => i.sacsType?.value).filter((i): i is string => !!i)
  //   }, [info])

  const sacCodes = React.useMemo(() => {
    return SacsData.getOptionsCode(language)
  }, [language])

  const sacTypes = React.useMemo(() => {
    return SacsData.getOptionsTypes(language)
  }, [language])

  const handleCalAmount = (sacCode: string, sacType: string) => {
    const currentRecipts = form.getValues(`receips.${tableIndex}`)
    const _previousAmount = (currentRecipts?.info ?? []).reduce<number>(
      (pre, cur) => pre + PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'),
      0
    )

    const _totalReceiptAmount = (form.getValues(`receips`) ?? []).reduce<number>(
      (pre, cur) =>
        pre + (cur.info ?? []).reduce<number>((p, c) => p + PulseOpsFormat.thousandSepartorReverse(c.amount ?? '0'), 0),
      0
    )
    const about = form.getValues(`receips.${tableIndex}.about`)
    return calAmountAction({
      detail,
      sacCode: sacCode ?? '',
      sacType: sacType ?? '',
      action: (about?.value ?? '') as PCReason.ActionEnum,
      remainingAmount: PulseOpsFormat.thousandSepartor(
        Math.min(
          remainingAmount + _previousAmount,
          Math.max(detail.amount - (_totalReceiptAmount - _previousAmount), 0)
        )
      )
    })
  }

  React.useEffect(() => {
    const validActionJ = form.watch(`receips.${tableIndex}.about.value`) === 'MSP_R_RPJ'
    if (detail.discount > 0 && validActionJ) {
      form.setValue(`receips.${tableIndex}.info`, [
        {
          sacsCode: { label: 'LN - Policy Loan and APL', value: 'LN' },
          amount: handleCalAmount('LN', 'VP').amount,
          sacsTypeSelection: [],
          sacsType: { label: 'VP - Premium Deposit - Principal', value: 'VP' },
          allowEdit: false,
          allowDetele: false
        },
        {
          sacsCode: { label: 'LP - Life Payer Ledger (Contract)', value: 'LP' },
          amount: handleCalAmount('LP', 'E1').amount,
          sacsTypeSelection: [],
          sacsType: { label: 'E1 - Contract Premium Discount', value: 'E1' },
          allowEdit: false,
          allowDetele: false
        }
      ])
    }
  }, [form.watch(`receips.${tableIndex}.about`)])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableCell className={classes.stickyLeft} width={'33%'}>
            <Text style={styles.titleColumn}>{ca('sacCode')}</Text>
          </TableCell>
          <TableCell className={classes.stickyLeft} width={'33%'}>
            <Text style={styles.titleColumn}>{ca('sacType')}</Text>
          </TableCell>
          <TableCell className={classes.stickyLeft} width={'33%'}>
            <Text style={styles.titleColumn}>{ca('amount')}</Text>
          </TableCell>
        </TableHead>
        <TableBody>
          {fields.map((receip, index) => {
            return (
              <TableRow key={receip.id}>
                <Controller
                  control={form.control}
                  name={`receips.${tableIndex}.info.${index}.sacsCode`}
                  render={({ field: { value, onChange } }) => {
                    // const sacCode = getListSacCodeByActionCode(
                    //   payinMethod ? (payinMethod as PCReason.ActionEnum) : undefined
                    // )
                    return (
                      <TableCell className={classes.cell}>
                        <SelectTable
                          id={`${tableIndex}${index}`}
                          options={sacCodes}
                          value={value ?? { value: '', label: '' }}
                          onChange={(v) => {
                            // const sacs = getSacTypeByActionCode(about?.value as PCReason.ActionEnum, v?.value ?? '')
                            const currentInfo = form.getValues(`receips.${tableIndex}.info.${index}`)
                            // if (sacs.defaultValue && !_allSacType.includes(sacs.defaultValue.value)) {
                            //   const cal = calAmountAction({
                            //     detail,
                            //     sacCode: v?.value ?? '',
                            //     sacType: sacs.defaultValue.value,
                            //     action: (about?.value ?? '') as PCReason.ActionEnum
                            //   })
                            //   form.setValue(`receips.${tableIndex}.info.${index}`, {
                            //     ...currentInfo,
                            //     sacsCode: v,
                            //     sacsTypeSelection: sacs.sacsType,
                            //     sacsType: sacs.defaultValue,
                            //     amount: cal.amount,
                            //     allowEdit: cal.allowEdit,
                            //     allowDetele: true
                            //   })
                            // } else {
                            //   form.setValue(`receips.${tableIndex}.info.${index}`, {
                            //     ...currentInfo,
                            //     sacsCode: v,
                            //     sacsType: null,
                            //     amount: '',
                            //     sacsTypeSelection: sacs.sacsType.filter((s) => !_allSacType.includes(s.value)),
                            //     allowEdit: true,
                            //     allowDetele: true
                            //   })
                            // }
                            onChange(v)
                            form.setValue(`receips.${tableIndex}.info.${index}`, {
                              ...currentInfo,
                              sacsCode: v,
                              sacsType: null,
                              amount: '',
                              //sacsTypeSelection: sacs.sacsType.filter((s) => !_allSacType.includes(s.value)),
                              sacsTypeSelection: [],
                              allowEdit: true,
                              allowDetele: true
                            })
                          }}
                          disabled={!payinMethod || (!receip.allowEdit as boolean)}
                        />
                      </TableCell>
                    )
                  }}
                />
                <Controller
                  control={form.control}
                  name={`receips.${tableIndex}.info.${index}.sacsType`}
                  render={({ field: { value, onChange } }) => {
                    // const typeSelection =
                    //   useWatch({
                    //     control: form.control,
                    //     name: `receips.${tableIndex}.info.${index}.sacsTypeSelection`
                    //   }) ?? []
                    const sacsCodeCur = form.getValues(`receips.${tableIndex}.info.${index}.sacsCode`)
                    const optionsSelected = form
                      .getValues(`receips.${tableIndex}.info`)
                      .filter((_) => _.sacsCode?.value === sacsCodeCur?.value)
                      .map((_) => _.sacsType)
                      .filter((item) => !_.isNull(item))
                    return (
                      <TableCell className={classes.cell}>
                        <SelectTable
                          id={`${tableIndex}${index}${value?.value}`}
                          key={`${tableIndex}${index}${value?.value}`}
                          options={sacTypes.filter((_) =>
                            (sacsCodeCur?.value === 'LN'
                              ? ['VP', 'AO', 'AI', 'LI', 'LO']
                              : sacsCodeCur?.value === 'LP'
                              ? ['S', 'E1', 'U', 'PT']
                              : sacsCodeCur?.value === 'SC'
                              ? ['I']
                              : []
                            ).includes(_.value)
                          )}
                          optionsSelected={optionsSelected as SelectOption[]}
                          value={value}
                          onChange={(v) => {
                            // const _allSacType = (form.getValues(`receips.${tableIndex}.info`) ?? []).reduce<string[]>(
                            //   (pre, cur) => [...pre, cur?.sacsType?.value ?? ''],
                            //   []
                            // )

                            // if (_allSacType.includes(v?.value ?? '')) {
                            //   onChange(null)
                            // } else {
                            //   const currentInfo = form.getValues(`receips.${tableIndex}.info.${index}`)
                            //   const about = form.getValues(`receips.${tableIndex}.about`)
                            //   const cal = calAmountAction({
                            //     detail,
                            //     sacCode: currentInfo?.sacsCode?.value ?? '',
                            //     sacType: v?.value ?? '',
                            //     action: (about?.value ?? '') as PCReason.ActionEnum
                            //   })
                            //   form.setValue(`receips.${tableIndex}.info.${index}`, {
                            //     ...currentInfo,
                            //     sacsType: v,
                            //     amount: cal.amount,
                            //     allowEdit: cal.allowEdit,
                            //     allowDetele: true
                            //   })
                            // }
                            onChange(v)

                            const currentInfo = form.getValues(`receips.${tableIndex}.info.${index}`)
                            const cal = handleCalAmount(currentInfo.sacsCode?.value || '', v?.value || '')
                            form.setValue(`receips.${tableIndex}.info.${index}.amount`, cal.amount)
                            form.setValue(`receips.${tableIndex}.info.${index}`, {
                              ...currentInfo,
                              sacsType: v,
                              allowEdit: cal.allowEdit,
                              allowDetele: true
                            })
                          }}
                        />
                      </TableCell>
                    )
                  }}
                />
                <Controller
                  control={form.control}
                  name={`receips.${tableIndex}.info.${index}.amount`}
                  defaultValue={''}
                  render={({ field: { value, onBlur } }) => {
                    const allowEdit = form.watch(`receips.${tableIndex}.info.${index}.allowEdit`)
                    const allowDetele = form.watch(`receips.${tableIndex}.info.${index}.allowDetele`)
                    return (
                      <TableCell className={classes.cell}>
                        <View style={styles.inputContainer}>
                          <TextInput
                            key={`key_state${index}`}
                            onChangeText={(value) => {
                              form.setValue(
                                `receips.${tableIndex}.info.${index}.amount`,
                                PulseOpsFormat.addThousandSeparator(value)
                              )
                            }}
                            value={value ?? ''}
                            style={styles.input}
                            maxLength={15}
                            onBlur={onBlur}
                            editable={allowEdit ?? undefined}
                          />

                          {
                            // hide this button with inquiery and QC
                            showButton && (
                              <Pressable
                                onPress={() => {
                                  Alert.modal({
                                    title: ca('warning'),
                                    content: ca('warningDeleteLine'),
                                    size: {
                                      width: '30%'
                                    },
                                    onOK: () => {
                                      const infos = form.getValues(`receips.${tableIndex}.info`)
                                      form.setValue(`receips.${tableIndex}.info`, [
                                        ...infos.slice(0, index),
                                        ...infos.slice(index + 1)
                                      ])
                                    }
                                  })
                                }}
                                disabled={!allowDetele}
                              >
                                <View style={{ opacity: allowDetele ? 1.0 : 0.4 }}>
                                  <assets.DeleteIcon />
                                </View>
                              </Pressable>
                            )
                          }
                        </View>
                      </TableCell>
                    )
                  }}
                />
              </TableRow>
            )
          })}

          {showButton && (
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <TouchableOpacity
                    onPress={() =>
                      append({
                        sacsType: null,
                        sacsCode: null,
                        amount: null,
                        sacsTypeSelection: [],
                        allowDetele: true,
                        allowEdit: true
                      })
                    }
                    style={[
                      styles.Button,
                      { alignSelf: 'flex-end', backgroundColor: '#fff', width: undefined, marginHorizontal: 0 }
                    ]}
                    disabled={ruleLA}
                  >
                    <Text style={[styles.boldText, { color: '#ED1B2E', fontSize: 12 }]}>{ca('addRow')}</Text>
                  </TouchableOpacity>
                </View>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
