import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface PartialWithdrawalAutoDebit {
  tag: TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST | TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL
  sourceType: string
  policyNumber: string
  attributesExt: any
  FUND_LIST: any
}

export const PartialWithdrawalAutoDebit = (
  detail: api.PartialWithdrawalAutoDebit & api.TaskDetailBase
): PartialWithdrawalAutoDebit => {
  return {
    tag: detail.transactionType,
    sourceType: detail?.source ?? '',
    policyNumber: detail?.policyNumber ?? '',
    attributesExt: detail.payload.body.attributesExt,
    FUND_LIST: detail.payload.body.attributesExt.FUND_LIST
  }
}
