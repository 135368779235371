import { FontAwesome5 } from '@expo/vector-icons'
import {
  assets,
  Checkbox,
  DatePicker,
  HospitalType,
  ICDType,
  SelectOption,
  SelectSearch,
  VerClaimType
} from '@pulseops/common'
import { ClaimService } from '@pulseops/submission'
import { compareOnlyDate } from '@pulseops/submission/common'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Panel } from '../../claim-common'
import { ClaimInfoProps } from '../ClaimInfoProps'
import { InPatient } from './InPatient'
import { ReceiptInformation } from './ReceiptInformation'

export const HospitalCash = (props: ClaimInfoProps) => {
  const { form, icdName, hospital, isSurery, detail, setIsCanChangeTab, accidents } = props
  const { watch, control, clearErrors, setValue } = form
  const hospitalForm = watch('hospital')
  const { t, i18n } = useTranslation(['claim'])
  const [subBenefit, setSubBenefit] = React.useState<SelectOption>({ label: '', value: '' })

  const translator = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)

  const additionalDeleteHandler = (idx: number) => {
    const list = [...hospitalForm.additionalDiagnosisType]
    list.splice(idx, 1)
    form.setValue('hospital.additionalDiagnosisType', list)
  }

  const onChangeRelatedToAccident = (e: boolean) => {
    if (!e) {
      clearErrors(['hospital.occurrenceDate', 'hospital.accidentName'])
      setValue('hospital.occurrenceDate', null)
      setValue('hospital.accidentName', null)
    }
  }

  const isRelatedToAccident = !!watch('hospital.isRelatedToAccident')

  return (
    <View>
      <Panel
        title=""
        breadcrumb={
          <View style={[styles.contentText, styles.row]}>
            <Text style={styles.titleSectionText}>{t('MedicalClaim')}</Text>
            <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
            <Text style={styles.titleSectionText}>{t('HospitalCashSection')}</Text>
          </View>
        }
        isExand={true}
        isHiddenTitle={true}
        styling={styles.sectionInfo}
      >
        <View style={[styles.row, { marginBottom: 20 }]}>
          <View style={{ flex: 1 / 3, flexDirection: 'row', alignItems: 'center' }}>
            <Controller
              name="hospital.isRelatedToAccident"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={t('RelatedToAccident')}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onChangeRelatedToAccident(e)
                    setIsCanChangeTab(false)
                  }}
                />
              )}
            />
          </View>
          {isRelatedToAccident && (
            <View style={{ flex: 2 / 3, flexDirection: 'row' }}>
              <View style={[{ marginRight: 10, flex: 1 / 2 }]}>
                <Controller
                  name={'hospital.occurrenceDate'}
                  control={control}
                  rules={{
                    required: {
                      value: isRelatedToAccident,
                      message: t('message:MS020009', { field: t('OccurrenceDate') })
                    },
                    validate: (occurrenceDate) => {
                      const currentDate = new Date()
                      const compareDate = !!occurrenceDate && !compareOnlyDate(occurrenceDate, currentDate)
                      const message = t('message:MS070021')
                      if (compareDate) return message
                      return true
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <DatePicker
                      label={t('OccurrenceDate')}
                      required={isRelatedToAccident}
                      onChange={(e) => {
                        onChange(e)
                        setIsCanChangeTab(false)
                      }}
                      alwaysShow={!isRelatedToAccident}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
              <View style={[{ paddingLeft: 25, marginTop: 5, flex: 1 / 2 }]}>
                <Controller
                  name="hospital.accidentName"
                  control={control}
                  rules={{
                    required: {
                      value: isRelatedToAccident,
                      message: t('message:MS020009', { field: t('AccidentName') })
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('AccidentName')}
                      required
                      options={
                        accidents?.map((option: VerClaimType) => ({
                          value: option.code,
                          label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
                        })) || []
                      }
                      placeholder={t('common:Select')}
                      onChange={(e) => {
                        onChange(e)
                        setIsCanChangeTab(false)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
          )}
        </View>
        <View style={styles.row}>
          <View style={{ flex: 1 / 3, marginBottom: 32, marginEnd: 100 }}>
            <Controller
              name="hospital.hospitalType"
              control={control}
              rules={{
                required: {
                  value: isRelatedToAccident,
                  message: t('message:MS020009', { field: t('Hospital') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('Hospital')}
                  required
                  options={
                    hospital?.map((option: HospitalType) => ({
                      value: option.code,
                      label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVn}`
                    })) || []
                  }
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                    setIsCanChangeTab(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>

        <View style={styles.row}>
          <View style={{ flex: 4 / 6, marginBottom: 32, marginEnd: 100 }}>
            <Controller
              name="hospital.mainDiagnosisType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('MainDiagnosis') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('MainDiagnosis')}
                  options={
                    icdName?.map((option: ICDType) => ({
                      value: option.icdcode,
                      label: `${option.icdcode} - ${
                        i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn
                      }`
                    })) || []
                  }
                  required
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                    setIsCanChangeTab(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={{ flex: 2 / 6, marginBottom: 32 }}>
            <Text style={styles.label}>{t('ScheduleLOS')}</Text>
            <Text style={{ fontSize: 15, lineHeight: 22 }}>0</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ flex: 4 / 6, marginBottom: 32, marginEnd: 100 }}>
            <Controller
              name="hospital.additionalDiagnosisType"
              defaultValue={[]}
              control={control}
              rules={{
                pattern: {
                  value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                  message: t('message:MS050223')
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('AdditionalDiagnosis')}
                  searchOptions={ClaimService.searchDiagnostic(translator)}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                    setIsCanChangeTab(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  multiple
                  key={watch('hospital.additionalDiagnosisType').length}
                />
              )}
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ flex: 4 / 6, marginBottom: 32, marginEnd: 100 }}>
            <View style={styles.tableDiagnosis}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text style={styles.label}>{t('ICDName')}</Text>
                </View>
              </View>

              {hospitalForm?.additionalDiagnosisType?.map((addition, index) => (
                <View style={[styles.row, styles.line]} key={index}>
                  <View style={styles.col}>
                    <Text>
                      {addition.value.split(':').length > 1
                        ? addition.value.split(':')[1]
                        : addition.value.split(':')[0]}
                    </Text>
                  </View>
                  <View style={styles.col}>
                    <Text>
                      {addition.label.split(':').length > 1
                        ? addition.label.split(':')[1]
                        : addition.label.split(':')[0]}
                    </Text>
                  </View>
                  <View style={[styles.col, { alignItems: 'flex-end' }]}>
                    <TouchableOpacity onPress={() => additionalDeleteHandler(index)}>
                      <assets.DeleteIcon />
                    </TouchableOpacity>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>
        <Text style={styles.label}>{t('claim:Hospitalbenefit')}</Text>
        <View>
          <Text>{t('claim:IN_PATIENT_TREATMENT_BENEFIT')}</Text>
        </View>
      </Panel>
      {/* In patient */}
      <Panel
        title=""
        breadcrumb={
          <View style={[styles.contentText, styles.row]}>
            <Text style={styles.titleSectionText}>{t('MedicalClaim')}</Text>
            <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
            <Text style={styles.titleSectionText}>{t('HospitalCashSection')}</Text>
            <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
            <Text style={styles.titleSectionText}>{t('claim:InPatientSection')}</Text>
          </View>
        }
        isExand={true}
        isHiddenTitle={true}
        styling={styles.sectionInfo}
      >
        <InPatient
          form={form}
          cb={(data) => {
            setSubBenefit(data)
          }}
          setIsCanChangeTab={setIsCanChangeTab}
        />
      </Panel>

      <ReceiptInformation
        form={form}
        isSurery={isSurery}
        detail={detail}
        subBenefit={subBenefit}
        setIsCanChangeTab={setIsCanChangeTab}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    // marginTop: 20,
    padding: 30,
    width: '100%',
    backgroundColor: '#FAFAFA',
    borderStartWidth: 1,
    borderEndWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderBottomColor: '#E5EAEF',
    borderBottomWidth: 3
  },
  tableDiagnosis: {
    // marginTop: 20,
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 12
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  titleSectionText: {
    fontSize: 13,
    lineHeight: 22,
    fontWeight: '600',
    paddingStart: 10,
    paddingEnd: 10,
    color: '#4F4F4F'
  },
  contentText: {
    padding: 5,
    borderRadius: 20,
    width: 'fit-content',
    backgroundColor: 'white',
    marginBottom: 26,
    display: 'flex'
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  line: {
    width: '100%',
    paddingBottom: 10,
    marginBottom: 22,
    borderBottomWidth: 1,
    borderBottomColor: '#D3DCE6'
  }
})
