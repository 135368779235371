import { Panel, PulseOpsFormat, TaskDetail } from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList, Title } from '../common'

type DataSource = {
  label: string | number | boolean | JSX.Element | TFunctionResult
  value: string | number | boolean | JSX.Element | TFunctionResult
  suffix?: string | number | boolean | JSX.Element
  isHighlight?: boolean
}

export const ScheduledPaymentStatement = ({ detail }: { detail?: TaskDetail.ScheduledPaymentStatement }) => {
  const { t } = useTranslation('requestInfo')

  let data: DataSource[] = []
  data = [
    {
      label: t('DocumentType'),
      value: t('ScheduledPaymentStatement')
    },
    {
      label: t('FromDate'),
      value: detail?.fromDate ? PulseOpsFormat.dateWF(detail.fromDate, 'YYYYMMDD', 'DD/MM/YYYY') : '-'
    },
    {
      label: t('ToDate'),
      value: detail?.toDate ? PulseOpsFormat.dateWF(detail.toDate, 'YYYYMMDD', 'DD/MM/YYYY') : '-'
    }
  ]

  return (
    <View>
      <Title title={t('LetterRequest')} />
      <Panel title="" isExand={false}>
        <InfoViewList dataSource={data} />
      </Panel>
    </View>
  )
}
