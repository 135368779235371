import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CHANGE_NATIONAL_ID_CARD)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    dob: Maybe(t.string),
    name: Maybe(t.string),
    nationality: Maybe(t.string),
    sex: Maybe(t.string),
    attributes: Maybe(
      t.type({
        LOCATION_CODE: Maybe(t.string),
        LOCATION_DESC: Maybe(t.string),
        NATIONAL_ID_ISSUE_DATE: Maybe(t.string)
      })
    ),
    externalIds: Maybe(
      t.type({
        NATIONAL_ID: Maybe(t.string)
      })
    ),
    attributesExt: Maybe(
      t.type({
        issuedDate: Maybe(t.string),
        issuedBy: Maybe(t.string),
        idType: Maybe(t.string),
        idNumber: Maybe(t.string)
      })
    ),
    createdDate: Maybe(t.string)
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadPulse4Ops
  })
)

const Pruonline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPulse4Ops
  })
)

export const ChangeNationalIDCard = t.union([Pulse4Ops, Zalo, Pruonline])
export type ChangeNationalIDCard = t.TypeOf<typeof ChangeNationalIDCard>
