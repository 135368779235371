import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ErrorHandling, PulseOpsFormat, TaskDetailService, TaskType } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    borderColor: '#D3DCE6'
  }
})

interface ErrLogModel {
  transactionDate: string
  system: string
  errorCode: string
  desp: string
}

export const ErrorLogTab = (props: { processId: string, category?: string, p40ProcessInstanceId?: string }) => {
  const classes = useStyles()

  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)

  const [orderBy, setOrderBy] = React.useState<string>('id')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')

  const [total, setTotal] = React.useState<number>(0)

  const [rows, setRows] = React.useState<ErrLogModel[]>([])
  const [loading, bindLoading] = useLoading()
  const { t } = useTranslation()

  const setOrderTable = () => {
    setOrderBy('startTime')
    setOrder('desc')
  }

  const loadData = () =>
    pipe(
      TaskDetailService.GetWFComment(
        props.category === TaskType.IDC ? (props.p40ProcessInstanceId || '') : props.processId,
        {
          pageSize: pageSize,
          pageNum: pageNum
        },
        {
          order: order,
          field: orderBy
        }
      ),
      ZIO.map((res) => {
        setTotal(res.total)
        return res.data
          ? res.data.map((x) => ({
            transactionDate: PulseOpsFormat.dateStrWF(x.createDate, 'DD/MM/YYYY'),
            system: x.systemName ?? '',
            errorCode: x.errorCode ?? '',
            desp: x.description ?? ''
          }))
          : []
      }),
      ZIO.tap((res) => {
        setRows(res)
        return ZIO.unit
      }),
      bindLoading
    )

  pipe(loadData(), ErrorHandling.runDidMount())

  const update = () => {
    pipe(loadData(), ErrorHandling.run())
  }
  return (
    <View style={{ margin: 10 }}>
      <View>
        {loading && <LinearProgress color="secondary" />}
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>{t('ErrorLog:transDate')}</TableCell>
                <TableCell>{t('common:system')}</TableCell>
                <TableCell>{t('common:errorCode')}</TableCell>
                <TableCell>{t('common:description')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={`errLog-${i}`}>
                  <TableCell component="th" scope="row">
                    {row.transactionDate}
                  </TableCell>

                  <TableCell>{row.system}</TableCell>
                  <TableCell>{row.errorCode}</TableCell>
                  <TableCell>{row.desp}</TableCell>
                </TableRow>
              ))}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    {t('common:noRecord')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={total}
          onPageChange={(e, page) => {
            setPageNum(page)
            update()
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value))
            setOrderTable()
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      </View>
    </View>
  )
}
