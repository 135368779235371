import React from 'react'
import { CustomerBehaviorData } from '../ib-service'

type ClientScreenContextInfo = {
  listClientNumber: { label: string; value: string }[]
  vipLevel: string
  permissions: string[]
  lastestCustomerBehavior: CustomerBehaviorData | undefined
  setLastestCustomerBehavior: ((data: CustomerBehaviorData) => void) | undefined
  masterClientName: string | undefined
}

const defaultDetailContext: ClientScreenContextInfo = {
  listClientNumber: [],
  vipLevel: '-',
  permissions: [],
  lastestCustomerBehavior: undefined,
  setLastestCustomerBehavior: undefined,
  masterClientName: undefined
}

export const ClientScreenContext = React.createContext<ClientScreenContextInfo>(defaultDetailContext)
