import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

export const SignatureRegistration = () => {
  const { t } = useTranslation()

  return (
    <View>
      <Text>{t('message:MS050272')}</Text>
    </View>
  )
}
