import { View, StyleSheet, useWindowDimensions, ScrollView, ActivityIndicator } from 'react-native'
import React from 'react'
import { ModalComponent, Input, ErrorHandling } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import _ from 'lodash'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'
import { SEAWithdrawChangeService } from './SEAWithdrawChangeService'
interface Props {
  visible: boolean
  onClose: (visible: boolean) => void
  idDetial: string
}

export const SEAWithdrawFailListDetailModal = (props: Props) => {
  const { width } = useWindowDimensions()
  const { visible, onClose, idDetial } = props
  const { t, i18n } = useTranslation()
  const detail: SEABillingChangeModal.DetailWithdrawData | null = pipe(
    SEAWithdrawChangeService.getDetailCase(idDetial),
    ErrorHandling.runDidMount()
  )
  const funLisst: string =
    detail?.fundPriorityFirst +
    '; ' +
    detail?.fundPrioritySecond +
    '; ' +
    detail?.fundPriorityThird +
    '; ' +
    detail?.fundPriorityFourth +
    '; ' +
    detail?.fundPriorityFifth +
    '; ' +
    detail?.fundPrioritySixth +
    '; ' +
    detail?.fundPrioritySeventh
  return (
    <ModalComponent
      title={t('BillingChange:FailDetail')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.6, 750)}
      visible={true}
      onClose={() => onClose(!visible)}
      actions={[]}
    >
      <ScrollView style={styles.container}>
        {!_.isUndefined(detail?.policyNumber) ? (
          <>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <View style={styles.inputStyle}>
                  <Input value={detail.policyNumber ?? ''} title={t('BillingChange:Policy')} disabled={true} />
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.inputStyle}>
                  <Input value={detail.paymentMethod ?? ''} title={t('BillingChange:Payoutmethod')} disabled={true} />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <View style={styles.inputStyle}>
                  <Input value={funLisst ?? ''} title={t('BillingChange:Fundlist')} disabled={true} />
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={styles.inputStyle}>
                  <Input
                    value={
                      detail.functionType === 'N' || detail.functionType === 'D'
                        ? SEABillingChangeModal.getActionName(detail.functionType ?? '')(i18n.language)
                        : detail.functionType ?? ''
                    }
                    title={t('BillingChange:Action')}
                    disabled={true}
                  />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <View style={styles.inputStyle}>
                  <Input value={detail.fromDate ?? ''} title={t('BillingChange:FromDate')} disabled={true} />
                </View>
              </View>
              <View style={{ flex: 1, marginTop: 3 }}>
                <View style={styles.inputStyle}>
                  <Input value={detail.toDate ?? ''} title={t('BillingChange:ToDate')} disabled={true} />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <View style={styles.inputStyle}>
                  <Input
                    value={SEABillingChangeModal.getFailReasonWithdrawName(detail?.reason ?? '')(i18n.language) ?? ''}
                    title={t('BillingChange:Failreason')}
                    disabled={true}
                  />
                </View>
              </View>
              <View style={{ flex: 1, paddingRight: 30 }}></View>
            </View>
          </>
        ) : (
          <ActivityIndicator size="large" color="#ED1B2C" />
        )}
      </ScrollView>
    </ModalComponent>
  )
}
const styles = StyleSheet.create({
  modalTitle: {},

  modalContent: {},

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39,
    marginHorizontal: 5
  },
  btnConfirm1: {
    height: 40,
    marginHorizontal: 5,
    marginRight: 50
  },
  btnConfirm2: {
    height: 40,
    marginHorizontal: 5,
    marginRight: 50
  },
  btnConfirm3: {
    height: 40,
    marginHorizontal: 5
  },
  container: {
    paddingHorizontal: 23,
    paddingTop: 13
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  inputStyle: {
    marginBottom: 20,
    width: '100%'
  },
  textWarning: {
    paddingHorizontal: 35,
    fontSize: 18
  }
})
