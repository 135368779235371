import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const lAClient = t.type({
  clientNumberOfLA: t.string,
  life: t.string
})

export type lAClient = t.TypeOf<typeof lAClient>

export const benClient = t.type({
  clientNumberOfBen: t.string,
  percent: t.number,
  relationShipCode: t.string
})

export type benClient = t.TypeOf<typeof benClient>

export const GetClientsByPolicyResponse = t.type({
  body: t.type({
    laList: t.array(lAClient),
    benList: t.array(benClient)
  })
})

export type GetClientsByPolicyResponseType = t.TypeOf<typeof GetClientsByPolicyResponse>

export const ScanIdentifyCardResponse = t.type({
  data: t.array(
    t.type({
      id: Maybe(t.string),
      id_prob: Maybe(t.string),
      name: Maybe(t.string),
      name_prob: Maybe(t.string),
      dob: Maybe(t.string),
      dob_prob: Maybe(t.string),
      sex: Maybe(t.string),
      sex_prob: Maybe(t.string),
      nationality: Maybe(t.string),
      nationality_prob: Maybe(t.string),
      home: Maybe(t.string),
      home_prob: Maybe(t.string),
      address: Maybe(t.string),
      address_prob: Maybe(t.string),
      doe: Maybe(t.string),
      doe_prob: Maybe(t.string),
      overall_score: Maybe(t.string),
      type_new: Maybe(t.string),
      type: Maybe(t.string),
      QRcode: Maybe(t.string)
    })
  ),
  errorCode: t.string
})

export type ScanIdentifyCardResponseType = t.TypeOf<typeof ScanIdentifyCardResponse>

export const CompareOCResponse = t.type({
  body: t.type({
    status: t.string,
    oErrorResult: t.type({
      errorCode: t.string,
      errorDescription: t.string
    })
  })
})

export type CompareOCResponseType = t.TypeOf<typeof CompareOCResponse>

export const GetOwnerInfoResponse = t.type({
  clientId: Maybe(t.string),
  email: Maybe(t.string),
  name: Maybe(t.string),
  mobilePhone: Maybe(t.string),
  externalIds: Maybe(
    t.type({
      SOE_VALUE: Maybe(t.string)
    })
  ),
  attributesExt: Maybe(
    t.type({
      MOBILE_CODE: Maybe(t.string),
      BASIC_PRODUCT_CODE: Maybe(t.string)
    })
  ),
  firstName: Maybe(t.string),
  surName: Maybe(t.string),
  dob: Maybe(t.string),
  sex: Maybe(t.string),
  nationality: Maybe(t.string),
  dod: Maybe(t.string)
})

export type GetOwnerInfoResponseType = t.TypeOf<typeof GetOwnerInfoResponse>
