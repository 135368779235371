import { SelectOption } from '@pulseops/common'
export namespace PCReason {
  export enum ActionEnum {
    // NEW = 'NEW',
    // REN = 'REN',
    // OPL = 'OPL',
    // APL = 'APL',
    // OTH_REINS = 'OTH-REINS',
    // OTH_TOPUP = 'OTH-TOPUP',
    // OTH_CHANGE_POLICY = 'OTH_CHANGE_POLICY'
    J = 'MSP_R_RPJ',
    N = 'MSP_R_RPN',
    L = 'MSP_R_RPL',
    R = 'MSP_R_RPR',
    G = 'MSP_R_RPG',
    A = 'MSP_C_RTA'
  }

  const ReceiptAction = [ActionEnum.J, ActionEnum.N, ActionEnum.L, ActionEnum.R, ActionEnum.G]

  const NoncashAction = [ActionEnum.J, ActionEnum.L, ActionEnum.N]

  const JournalAction = [ActionEnum.J, ActionEnum.A, ActionEnum.L]

  const PCActionRaw: {
    code: string
    des: string
    about: string
  }[] = [
    {
      code: 'MSP_R_RPJ',
      des: 'Action J',
      about: 'Action J'
    },
    {
      code: 'MSP_R_RPN',
      des: 'Action N',
      about: 'Action N'
    },
    {
      code: 'MSP_R_RPL',
      des: 'Action L',
      about: 'Action L'
    },
    {
      code: 'MSP_R_RPR',
      des: 'Action R',
      about: 'Action R'
    },
    {
      code: 'MSP_R_RPG',
      des: 'Action G',
      about: 'Action G'
    },
    {
      code: 'MSP_C_RTA',
      des: 'Action A',
      about: 'Action A'
    }
    // {
    //   code: 'REN',
    //   des: 'Thanh toán phí bảo hiểm cho hợp đồng mới',
    //   about: 'Phí định kỳ'
    // },
    // {
    //   code: 'OPL',
    //   des: 'Thanh toán phí bảo hiểm cho hợp đồng mới',
    //   about: 'Hoàn trả tạm ứng'
    // },
    // {
    //   code: 'APL',
    //   des: 'Thanh toán phí bảo hiểm cho hợp đồng mới',
    //   about: 'Hoàn trả phí tự động'
    // },
    // {
    //   code: 'NEW',
    //   des: 'Thanh toán phí bảo hiểm cho hợp đồng mới',
    //   about: 'Phí khác-Phí hồ sơ mới'
    // },
    // {
    //   code: 'OTH-REINS',
    //   des: '',
    //   about: 'Phí khác-Phí Khôi phục hợp đồng'
    // },
    // {
    //   code: 'OTH-TOPUP',
    //   des: '',
    //   about: 'Phí khác-Phí đầu tư thêm'
    // },
    // {
    //   code: 'OTH_CHANGE_POLICY',
    //   des: '',
    //   about: 'Phí khác-Phí Điều chỉnh hợp đồng'
    // }
  ]

  const PCMapActionData = new Map<string, Map<string, string>>(
    PCActionRaw.map((item) => [
      item.code,
      new Map<string, string>([
        ['des', item.des],
        ['about', item.about]
      ])
    ])
  )

  export const getDescriptionActionByCode = (code: string) => PCMapActionData.get(code)?.get('des') ?? code

  export const getAboutActionByCode = (code: string) => PCMapActionData.get(code)?.get('about') ?? code

  export const getSelectOptions = (): SelectOption[] => // get action for receipt
    ReceiptAction.map((item) => ({
      value: item as string,
      label: getDescriptionActionByCode(item as string)
    }))

  export const getActionNonCash = (): SelectOption[] => // get action for Noncash
    NoncashAction.map((item) => ({
      value: item as string,
      label: getDescriptionActionByCode(item as string)
    }))

  export const getActionJournal = (): SelectOption[] =>
    JournalAction.map((item) => ({
      value: item as string,
      label: getDescriptionActionByCode(item as string)
    }))

  export enum ReasonEnum {
    NEW = 'NEW',
    REN = 'REN',
    OPL = 'OPL',
    APL = 'APL'
  }

  const PCReasonRaw: {
    code: string
    des: string
    about: string
  }[] = [
    {
      code: 'REN',
      des: 'Thanh toán phí bảo hiểm định kỳ',
      about: 'Phí định kỳ'
    },
    {
      code: 'OPL',
      des: 'Hoàn trả tạm ứng từ giá trị hoàn lại',
      about: 'Hoàn trả tạm ứng'
    },
    {
      code: 'APL',
      des: 'Hoàn trả phí tự động',
      about: 'Hoàn trả phí tự động'
    },
    {
      code: 'NEW',
      des: 'Thanh toán phí bảo hiểm cho hợp đồng mới',
      about: 'Phí khác-Phí hồ sơ mới'
    },
    {
      code: 'OTH',
      des: 'Lý do khác',
      about: 'Lý do khác'
    },
    {
      code: 'LOA',
      des: 'Hoàn trả khoản giảm thu nhập đầu tư',
      about: 'Hoàn trả khoản giảm thu nhập đầu tư'
    }
  ]

  const PCMapReasonData = new Map<string, Map<string, string>>(
    PCReasonRaw.map((item) => [
      item.code,
      new Map<string, string>([
        ['des', item.des],
        ['about', item.about]
      ])
    ])
  )

  export const getDescriptionReasonByCode = (code: string) => PCMapReasonData.get(code)?.get('des') ?? code

  export const getAboutReasonByCode = (code: string) => PCMapReasonData.get(code)?.get('about') ?? code

  export const getSelecReasonOptions = (): SelectOption[] =>
    PCReasonRaw.map((item) => ({ value: item.code, label: item.about }))
}

export namespace SacsData {
  export const SacsCodeRaw: { code: string; vi: string; en: string }[] = [
    {
      code: 'LN',
      vi: 'Policy Loan and APL',
      en: 'Policy Loan and APL'
    },
    {
      code: 'LP',
      vi: 'Life Payer Ledger (Contract)',
      en: 'Life Payer Ledger (Contract)'
    },
    {
      code: 'SC',
      vi: 'Company Suspense',
      en: 'Company Suspense'
    }
  ]

  export const SacsCodeMapData = new Map<string, Map<string, string>>(
    SacsCodeRaw.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getSacsCodeByCode = (code: string) => (languageCode: string) => ({
    label: `${code} - ${SacsCodeMapData.get(code)?.get(languageCode) ?? code}`,
    value: code
  })

  export const getOptionsCodeByCode =
    (...codes: string[]) =>
    (languageCode: string): SelectOption[] => {
      return codes.map((c) => getSacsCodeByCode(c)(languageCode))
    }

  export const getOptionsCode = (languageCode: string) =>
    SacsCodeRaw.map((s) => getSacsCodeByCode(s.code)(languageCode))

  export const SacsTypeRaw: { code: string; vi: string; en: string }[] = [
    {
      code: 'VP',
      vi: 'Premium Deposit - Principal',
      en: 'Premium Deposit - Principal'
    },
    {
      code: 'PT',
      vi: 'Contract Premium Tolerance',
      en: 'Contract Premium Tolerance'
    },
    {
      code: 'S',
      vi: 'Contract Suspense',
      en: 'Contract Suspense'
    },
    {
      code: 'U',
      vi: 'Receipt for new ILP',
      en: 'Receipt for new ILP'
    },
    {
      code: 'AO',
      vi: 'APL Principal',
      en: 'APL Principal'
    },
    {
      code: 'AI',
      vi: 'APL Interest',
      en: 'APL Interest'
    },
    {
      code: 'LO',
      vi: 'Policy Loan Principal',
      en: 'Policy Loan Principal'
    },
    {
      code: 'LI',
      vi: 'Policy Loan Interest',
      en: 'Policy Loan Interest'
    },
    {
      code: 'E1',
      vi: 'Contract Premium Discount',
      en: 'Contract Premium Discount'
    },
    {
      code: 'I',
      vi: 'Suspense In',
      en: 'Suspense In'
    }
  ]

  export const SacsTypeMapData = new Map<string, Map<string, string>>(
    SacsTypeRaw.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getSacsTypeByCode = (code: string) => (languageCode: string) => ({
    label: `${code} - ${SacsTypeMapData.get(code)?.get(languageCode) ?? code}`,
    value: code
  })

  export const getOptionsTypeByCode =
    (...codes: string[]) =>
    (languageCode: string): SelectOption[] => {
      return codes.map((c) => getSacsTypeByCode(c)('vi'))
    }

  export const getOptionsTypes = (languageCode: string) =>
    SacsTypeRaw.map((sac) => getSacsTypeByCode(sac.code)(languageCode))
}

export namespace MappingJournal {
  export const RawTableCode: {
    action: PCReason.ActionEnum
    tables: {
      from: string
      to: string[]
    }[]
  }[] = [
    {
      action: PCReason.ActionEnum.J,
      tables: [
        {
          from: 'LP_S',
          to: ['LN_VP']
        },
        {
          from: 'LN_VP',
          to: ['LP_S']
        },
        {
          from: 'LP_E1',
          to: ['LN_VP']
        }
      ]
    },
    {
      action: PCReason.ActionEnum.A,
      tables: [
        {
          from: 'LP_S',
          to: ['LP_U']
        },
        {
          from: 'LP_U',
          to: ['LP_S']
        },
        {
          from: 'LP_E1',
          to: ['LP_S']
        }
      ]
    },
    {
      action: PCReason.ActionEnum.L,
      tables: [
        {
          from: 'LP_S',
          to: ['LN_AO', 'LN_AI', 'LN_LO', 'LN_LI']
        }
      ]
    }
  ]

  export const TableMapData = new Map<PCReason.ActionEnum, Map<string, string[]>>(
    RawTableCode.map((item) => [
      item.action,
      new Map<string, string[]>(item.tables.map((table) => [table.from, table.to]))
    ])
  )

  export const getJournalToPair = (action: PCReason.ActionEnum) => (code: string) =>
    TableMapData.get(action)?.get(code) ?? []

  export const getAvailableSacCodeFrom = (action: PCReason.ActionEnum) => {
    const a = TableMapData.get(action)
    const keys = Array.from(new Set(Array.from(a?.keys() ?? []).map((k) => k.split('_')[0])))
    const options = keys.map((k) => SacsData.getSacsCodeByCode(k)('vi'))
    return options
  }

  export const getAvailableSacTypeFrom = (action: PCReason.ActionEnum) => (sacCode: string) => {
    const a = TableMapData.get(action)
    const keys = Array.from(new Set(a?.keys() ?? []))
      .filter((k) => k.includes(sacCode ?? 'Null'))
      .map((k) => k.split('_')[1])

    const options = keys.map((k) => SacsData.getSacsTypeByCode(k)('vi'))
    return options
  }

  export const getAvailableSacCodeTo = (action: PCReason.ActionEnum, sacCode: string, sacType: string) => {
    const a = getJournalToPair(action)(`${sacCode}_${sacType}`)

    const sacCodeKeys = Array.from(new Set(a.map((k) => k.split('_')[0])))
    const sacTypeKeys = Array.from(new Set(a.map((k) => k.split('_')[1])))
    const sacCodeOptions = sacCodeKeys.map((code) => SacsData.getSacsCodeByCode(code)('vi'))
    const sacTypeOptions = sacTypeKeys.map((code) => SacsData.getSacsTypeByCode(code)('vi'))
    return {
      sacCodeOptions,
      sacTypeOptions
    }
  }
}

// Temporary Harcode, waiting API
// export namespace InterestRateTable {
//   const dateFormat = 'MM/DD/yyyy'
//   const RawInterestRateTable = [
//     {
//       fromDate: '01/01/2013',
//       toDate: '30/09/2013',
//       rate: 11.0
//     },
//     {
//       fromDate: '01/10/2013',
//       toDate: '31/12/2014',
//       rate: 11.25
//     },
//     {
//       fromDate: '01/01/2015',
//       toDate: '30/11/2018',
//       rate: 11.0
//     },
//     {
//       fromDate: '01/12/2018',
//       toDate: '31/03/2020',
//       rate: 10.5
//     },
//     {
//       fromDate: '01/04/2020',
//       toDate: '30/09/2020',
//       rate: 10.0
//     },
//     {
//       fromDate: '01/10/2020',
//       toDate: '31/03/2022',
//       rate: 9.0
//     },
//     {
//       fromDate: '01/04/2022',
//       toDate: null,
//       rate: 8.5
//     }
//   ]

//   export const InterestRateTable = RawInterestRateTable.map((i) => ({
//     fromDate: moment(i.fromDate, dateFormat).toDate(),
//     toDate: i.toDate ? moment(i.toDate).toDate() : Date(),
//     rate: i.rate
//   }))

//   export const getRateByDate = (transactionDate: Date) => {
//     for (let i = 0; i < InterestRateTable.length; i++) {
//       const data = InterestRateTable[i]
//       const isBetween = moment(transactionDate).isBetween(data.fromDate, data.toDate)
//       if (isBetween) {
//         return data.rate
//       }
//     }

//     return 0
//   }
// }

export namespace PartnerChannel {
  export const RawParnerChannel: { bankCode: string; name: string; nameEn?: string }[] = [
    {
      bankCode: 'V2',
      name: 'MyVIB'
    },
    {
      bankCode: 'M2',
      name: 'Momo'
    },
    {
      bankCode: 'A9',
      name: 'VNPost'
    },
    {
      bankCode: 'S6',
      name: 'VBA'
    },
    {
      bankCode: 'L3',
      name: 'Viettel'
    },
    {
      bankCode: 'D7',
      name: 'POS SeaBank'
    },
    {
      bankCode: 'I2',
      name: 'Ebanking SeaBank'
    },
    {
      bankCode: 'O1',
      name: 'PruOnline - Thẻ quốc tế',
      nameEn: 'PruOnline - International Card (Visa/Mastercard)'
    },
    {
      bankCode: 'O2',
      name: 'PruOnline - Thẻ ATM',
      nameEn: 'PruOnline - Domestic Card (ATM)'
    },
    {
      bankCode: 'O3',
      name: 'Recurring'
    },
    {
      bankCode: 'DK',
      name: 'VNPay'
    },
    {
      bankCode: 'C9',
      name: 'Zalo'
    },
    // {
    //   bankCode: 'P2',
    //   name: 'Shopee'
    // },
    // {
    //   bankCode: 'M7',
    //   name: 'Mpos - Sacombank'
    // },
    // {
    //   bankCode: 'W1',
    //   name: 'Vietel-Telco'
    // },
    {
      bankCode: 'T3',
      name: 'TCB'
    },
    {
      bankCode: 'A7',
      name: 'Citi'
    },
    {
      bankCode: 'S1',
      name: 'Maritime'
    },
    {
      bankCode: 'P3',
      name: 'PVCombank'
    },
    {
      bankCode: 'S2',
      name: 'VCB'
    },
    {
      bankCode: 'B3',
      name: 'Seabank'
    },
    {
      bankCode: 'S3',
      name: 'Shinhan'
    },
    {
      bankCode: 'C3',
      name: 'SCB'
    },
    {
      bankCode: 'U3',
      name: 'UOBank'
    },
    {
      bankCode: 'V3',
      name: 'VIB'
    },
    {
      bankCode: 'I3',
      name: 'VietBank'
    },
    {
      bankCode: 'C7',
      name: 'POS Eximbank'
    },
    {
      bankCode: 'X1',
      name: 'Auto debit VCB'
    },
    // {
    //   bankCode: 'A0',
    //   name: 'ShopeePay'
    // },
    {
      bankCode: 'I4',
      name: 'MSB BANK'
    },
    // {
    //   bankCode: 'M3',
    //   name: 'MOMO FOR AGENT'
    // },
    {
      bankCode: 'P9',
      name: 'Payoo'
    },
    {
      bankCode: 'V4',
      name: 'VIB AUTODEBIT'
    },
    {
      bankCode: 'M9',
      name: 'Momo PruOnline'
    },
    {
      bankCode: 'D6',
      name: 'Sacombank App'
    },
    {
      bankCode: 'D8',
      name: 'Sacombank Counter'
    },
    {
      bankCode: 'D9',
      name: 'Sacombank Banktransfer'
    },
    {
      bankCode: 'V5',
      name: 'Credit Life Portal'
    }
    // {
    //   bankCode: 'V4',
    //   name: 'VIB AUTODEBIT'
    // }
  ]

  const MapPartnerChannel: Map<string, Map<string, string>> = new Map(
    RawParnerChannel.map((item) => [
      item.bankCode,
      new Map([
        ['en', item.nameEn ?? item.name],
        ['vi', item.name]
      ])
    ])
  )

  export const getPartnerNameByCode = (code: string) => (languageCode: string) =>
    MapPartnerChannel.get(code)?.get(languageCode) ?? code
}
