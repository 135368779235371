import {
  Checkbox,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  Select,
  SelectOption
} from '@pulseops/common'
import { VeriInquiryComplaintData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'
import { Title } from '../../common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Controller, UseFormReturn } from 'react-hook-form'
import { InquiryComplaintForm } from './InquiryComplaintForm'

interface ConservationPropData {
  detailData: VeriInquiryComplaintData
  dataForm: UseFormReturn<InquiryComplaintForm>
}

export const Conservation: React.FC<ConservationPropData> = ({ detailData, dataForm }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])

  const [conservationData, setConservationData] = React.useState<InquiryComplaintData.CONSERVATIONINFO>()
  const [isConservationGAStaff, setIsConservationGAStaff] = React.useState<boolean>(false)
  const [fullSurrenderReasonList, setFullSurrenderReasonList] = React.useState<
    InquiryComplaintData.FullSurrenderReason[]
  >([])
  const [resolutionList, setResolutionList] = React.useState<InquiryComplaintData.Resolution[]>([])
  const { watch, setValue, control } = dataForm

  const conservationResultList: InquiryComplaintData.FullSurrenderReason[] = [
    {
      reasonCode: 'SUCCESS',
      reasonEN: 'Success',
      reasonVN: 'Thành công'
    },
    {
      reasonCode: 'FAIL',
      reasonEN: 'Fail',
      reasonVN: 'Không thành công'
    }
  ]

  const conservationBonusList: InquiryComplaintData.FullSurrenderReason[] = [
    {
      reasonCode: 'BO_01',
      reasonEN: '+/- 2%',
      reasonVN: 'Cộng/trừ 2%'
    },
    {
      reasonCode: 'BO_02',
      reasonEN: 'Not +/- 2%',
      reasonVN: 'Không cộng/trừ 2%'
    }
  ]

  React.useEffect(() => {
    if (!!detailData && !conservationData) {
      const veriCONSERVATION = detailData.submissionData.CONSERVATION_INFO as InquiryComplaintData.CONSERVATIONINFO
      setConservationData(veriCONSERVATION)
      setIsConservationGAStaff(!!veriCONSERVATION?.conservationGAStaff)
      setValue(
        'conservation.conservationResult',
        convertToOptionItem(veriCONSERVATION?.conservationResult, conservationResultList)
      )
      setValue(
        'conservation.conservationBonus',
        convertToOptionItem(veriCONSERVATION?.conservationBonus, conservationBonusList)
      )
      setValue('conservation.conservationNote', veriCONSERVATION?.conservationNote)
      initalConservationList(veriCONSERVATION)
    }
  }, [detailData])

  const initalConservationList = (conservationData: InquiryComplaintData.CONSERVATIONINFO) => {
    pipe(
      InquiryComplaintService.getFullSurrenderReasonList(),
      ZIO.map((Itemlist) => {
        if (Itemlist && Itemlist.length > 0) {
          setFullSurrenderReasonList(Itemlist)
          setValue(
            'conservation.reasonFullSurrender',
            convertToOptionItem(conservationData?.reasonFullSurrender, Itemlist)
          )
        }
      }),
      ZIO.unsafeRun({})
    )
    if (conservationData?.conservationResult !== 'FAIL') {
      pipe(
        InquiryComplaintService.getResolutionList(),
        ZIO.map((Itemlist) => {
          if (Itemlist && Itemlist.length > 0) {
            setResolutionList(Itemlist)
            setValue(
              'conservation.conservationSolution',
              convertResolutionItemToOptionItem(conservationData?.conservationSolution, Itemlist)
            )
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const convertArrayToOptionList = (surrenderList: InquiryComplaintData.FullSurrenderReason[]) => {
    return surrenderList.map((surrenderItem) => ({
      value: surrenderItem.reasonCode,
      label: i18n.language === 'en' ? surrenderItem.reasonEN : surrenderItem.reasonVN
    }))
  }

  const convertResolutionListToOptionList = (solutionList: InquiryComplaintData.Resolution[]) => {
    return solutionList.map((solutionItem) => ({
      value: solutionItem.resolutionCode,
      label: i18n.language === 'en' ? solutionItem.resolutionEN : solutionItem.resolutionVN
    }))
  }

  const convertToOptionItem = (itemVal: string, itemList: InquiryComplaintData.FullSurrenderReason[]) => {
    const reasonItem = itemList.find((x) => x.reasonCode === itemVal)
    const optionItem: SelectOption = {
      value: reasonItem?.reasonCode ?? '',
      label: i18n.language === 'en' ? reasonItem?.reasonEN ?? '' : reasonItem?.reasonVN ?? ''
    }
    return optionItem
  }

  const convertResolutionItemToOptionItem = (itemVal: string, itemList: InquiryComplaintData.Resolution[]) => {
    const resolutionItem = itemList.find((x) => x.resolutionCode === itemVal)
    const optionItem: SelectOption = {
      value: resolutionItem?.resolutionCode ?? '',
      label: i18n.language === 'en' ? resolutionItem?.resolutionEN ?? '' : resolutionItem?.resolutionVN ?? ''
    }
    return optionItem
  }

  const getSolutionNameByValue = (itemVal: string | undefined) => {
    return itemVal === 'FULL_SURRENDER' ? (i18n.language === 'en' ? 'Full surrender' : 'Hủy hợp đồng') : ''
  }

  return (
    <View>
      <Title title={t('ConservationInfomation')} />
      <Panel title="">
        <View style={conservationStyle.row}>
          <View style={conservationStyle.col_4}>
            <Text style={conservationStyle.field_title}>{t('Product')}</Text>
            <Text style={conservationStyle.field_description}>{conservationData?.product}</Text>
          </View>
          <View style={conservationStyle.col_4}>
            <Text style={conservationStyle.field_title}>{t('ServicingAgent')}</Text>
            <Text style={conservationStyle.field_description}>
              {conservationData?.servicingAgentCode + ' ' + conservationData?.servicingAgentName}
            </Text>
          </View>
        </View>
        <View style={[conservationStyle.row, conservationStyle.secondLine]}>
          <View style={conservationStyle.col_4}>
            {/* <Text style={conservationStyle.field_title}>{t('ConservationGAStaff')}</Text> */}
            <Checkbox
              value={isConservationGAStaff}
              disabled={true}
              title={isConservationGAStaff ? conservationData?.conservationGAStaff : t('ConservationGAStaff')}
            />
          </View>
          <View style={conservationStyle.col_4}>
            <Text style={conservationStyle.field_title}>{t('ConservationAgent')}</Text>
            <Text style={conservationStyle.field_description}>
              {conservationData?.conservationAgentCode + ' ' + conservationData?.conservationAgentName}
            </Text>
          </View>
          <View style={conservationStyle.col_4}>
            <Controller
              control={control}
              name="conservation.reasonFullSurrender"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('ReasonForFullSurrender')}
                  showUnderline={false}
                  disabled={true}
                  options={convertArrayToOptionList(fullSurrenderReasonList)}
                />
              )}
            />
          </View>
        </View>
        <View style={[conservationStyle.row, conservationStyle.secondLine]}>
          <View style={conservationStyle.col_4}>
            <Controller
              control={control}
              name="conservation.conservationResult"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('Result')}
                  showUnderline={false}
                  disabled={true}
                  options={convertArrayToOptionList(conservationResultList)}
                />
              )}
            />
          </View>
          <View style={conservationStyle.col_4}>
            {watch('conservation.conservationResult')?.value === 'FAIL' && (
              <View>
                <Text style={conservationStyle.field_title}>{t('Solution')}</Text>
                <Text style={conservationStyle.field_description}>
                  {getSolutionNameByValue(conservationData?.conservationSolution)}
                </Text>
              </View>
            )}
            {watch('conservation.conservationResult')?.value !== 'FAIL' && (
              <Controller
                control={control}
                name="conservation.conservationSolution"
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t('Solution')}
                    showUnderline={false}
                    disabled={true}
                    options={convertResolutionListToOptionList(resolutionList)}
                  />
                )}
              />
            )}
          </View>
          <View style={conservationStyle.col_4}>
            <Controller
              control={control}
              name="conservation.conservationBonus"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('Bonus')}
                  showUnderline={false}
                  disabled={true}
                  options={convertArrayToOptionList(conservationBonusList)}
                />
              )}
            />
          </View>
        </View>
        <View style={[conservationStyle.secondLine]}>
          <View>
            {/* <Text style={conservationStyle.field_title}>{t('Note')}</Text>
            <Text style={conservationStyle.field_description}>{conservationData?.conservationNote}</Text> */}
            <Controller
              control={control}
              name="conservation.conservationNote"
              render={({ field }) => (
                <Input title={t('Note')} {...field} maxLength={1000} multiline disabled={true} numberOfLines={5} />
              )}
            />
          </View>
        </View>
      </Panel>
    </View>
  )
}
const conservationStyle = StyleSheet.create({
  row: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  }
})
