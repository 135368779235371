import {
  AppContext,
  AuthService,
  ErrorHandling,
  InternalFeedbackService,
  Tab,
  TabList,
  TaskType
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { InternalSuspendHistory } from './internal-suspend-history'
import { pipe } from 'fp-ts/lib/function'
import { InternalSuspendInformation } from './internal-suspend-information'
import { useLoading } from '@mxt/zio-react'
import { ZIO } from '@mxt/zio'
import moment from 'moment'

interface InternalFeedbackProps {
  category: string
  processInstanceId: string
  businessKey: string
  disabled: boolean
}

interface InternalSuspendContext {
  disabled: boolean
}
export interface InternalUserInfo {
  email: string
  name: string
  officeCode: string | null
  isGaLogin: boolean
}

const defaultContext: InternalSuspendContext = {
  disabled: false
}

export const InternalContext = React.createContext<InternalSuspendContext>(defaultContext)

export const InternalSuspendScreen: React.FC<InternalFeedbackProps> = ({
  category,
  processInstanceId,
  businessKey,
  disabled
}) => {
  const { t } = useTranslation()
  const [subTabIndex, onTabChange] = React.useState(0)
  const { getFooter, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  const [suspendTypeList, setsuspendTypeList] = React.useState<InternalFeedbackService.SuspendTypeArray>([])
  const [workingTimeList, setWorkingTimeList] = React.useState<InternalFeedbackService.WorkingTimeInfo[]>([])

  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }

  // init data in Didmount event
  const departmentList =
    pipe(InternalFeedbackService.getDepartmentList(), bindLoader, ErrorHandling.runDidMount()) ?? []

  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount()) ?? {
    email: '',
    name: '',
    officeCode: '',
    isGaLogin: false
  }

  if (!disabled) {
    pipe(
      ZIO.effect(() => {
        const groupCode = getSuspendGroupCode()
        const currentDate = moment().format('YYYY/MM/DD')
        return {
          groupCode,
          currentDate
        }
      }),
      ZIO.flatMap((inputData) =>
        ZIO.zipPar(
          InternalFeedbackService.getSuspendTypeList(processInstanceId, inputData.groupCode),
          InternalFeedbackService.getWorkingTimeList(inputData.currentDate)
        )
      ),
      ZIO.map(([responseData, workingList]) => {
        responseData && responseData.length > 0 && setsuspendTypeList(responseData)
        !!workingList && workingList.length > 0 && setWorkingTimeList(workingList)
      }),
      bindLoader,
      ErrorHandling.runDidUpdate([processInstanceId])
    )
  }

  const internalFeedbackTabs = [
    {
      id: 0,
      title: t('Tab:InternalSuspendInformation'),
      permission: ''
    },
    {
      id: 1,
      title: t('Tab:InternalSuspendHistory'),
      permission: ''
    }
  ]

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getSuspendGroupCode = () => {
    switch (category) {
      case TaskType.Complaint:
        return 'INT1'
      case TaskType.CustomerService:
        return 'INT2'
      case TaskType.Claim:
        return 'INT3'
      default:
        return ''
    }
  }

  const renderTab = (): JSX.Element | undefined => {
    switch (subTabIndex) {
      case 0: {
        return hasTab(0, internalFeedbackTabs) && departmentList.length > 0 ? (
          <InternalSuspendInformation
            businessKey={businessKey}
            category={category}
            processInstanceId={processInstanceId}
            T31DepartmentList={departmentList}
            userInfo={userInfo}
            suspendTypeList={suspendTypeList}
            workingTimeList={workingTimeList}
          ></InternalSuspendInformation>
        ) : undefined
      }
      case 1: {
        return hasTab(1, internalFeedbackTabs) && departmentList.length > 0 ? (
          <InternalSuspendHistory
            businessKey={businessKey}
            processInstanceId={processInstanceId}
            T31DepartmentList={departmentList}
            userInfo={userInfo}
            category={category}
          ></InternalSuspendHistory>
        ) : undefined
      }
      default:
        return <></>
    }
  }
  return (
    <SafeAreaView style={internalFeedbackStyles.container}>
      <TabList
        gutterBottom
        isSubTab={true}
        useOriginalId={true}
        menus={internalFeedbackTabs}
        tabIndex={subTabIndex}
        onChangeTab={(index) => {
          getFooter(<></>)
          onTabChange(index)
        }}
      />
      <View>
        <InternalContext.Provider value={{ disabled }}>{renderTab()}</InternalContext.Provider>
      </View>
    </SafeAreaView>
  )
}

export const internalFeedbackStyles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
    marginBottom: 15,
    flex: 1
  }
})
