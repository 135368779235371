import { useMobile, Checkbox, TransactionType, RadioButton } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View, Text, StyleSheet, ActivityIndicator, ViewStyle } from 'react-native'
import { TaskType } from '@pulseops/common'
import { isNil } from 'lodash'
interface Props {
  UWDecision?: {
    UWAcceptVal: boolean
    disabledAcceptUW: boolean
    setAcceptUWVal: (val: boolean) => void
    UWTransferVal: boolean
    setTransferUWVal: (val: boolean) => void
    isShowedTransferButton: boolean
    disabledTransferUW: boolean
  }
  listAction: {
    name: string
    action: () => void
    isLoading?: boolean
    isVisible?: boolean
    isSubmissionDisabled?: boolean
    isHighLightBtn?: boolean
  }[]
  updateToCore?: {
    val: boolean
    setVal: (val: boolean) => void
  }
  disableUTC?: boolean
  containerStyle?: ViewStyle
  caterogy?: TaskType
  transactionType?: TransactionType
  isUWTransaction?: boolean
  isUWScreen?: boolean
  amlResult?: string
  redFlagResult?: boolean | string
}
export const TaskDetailAction = ({
  listAction,
  updateToCore,
  disableUTC = false,
  containerStyle,
  caterogy,
  isUWTransaction,
  UWDecision,
  isUWScreen,
  redFlagResult,
  amlResult
}: Props) => {
  const { isWide } = useMobile()
  const { t } = useTranslation()

  const renderFlagAML = () => {
    if (isNil(amlResult) || isNil(redFlagResult)) return

    const detectStyleText = (condition: boolean) => {
      return condition ? styles.textAMLSpecial : styles.textAMLNormal
    }

    return (
      // <View style={styles.amlArea}>
      //   <View>
      //     <Text style={[styles.textAML, detectStyleText(redFlagResult === 'Y')]}>AML Redflag: {redFlagResult}</Text>
      //   </View>
      //   <View>
      //     <Text style={[styles.textAML, detectStyleText(['HIT'].includes(amlResult))]}>AML Screening: {amlResult}</Text>
      //   </View>
      // </View>
      <View style={styles.amlArea}>
        <Text style={[styles.textAML, detectStyleText(redFlagResult === 'Y')]}>AML Redflag: {redFlagResult}</Text>
        <Text style={[styles.textAML, detectStyleText(['HIT'].includes(amlResult))]}>AML Screening: {amlResult}</Text>
      </View>
    )
  }

  return (
    <>
      {isWide && (
        <View style={[styles.footer, containerStyle]}>
          {listAction?.map((res, i) =>
            res.isVisible === false ? null : (
              <View key={`action-${i}`}>
                <TouchableOpacity
                  style={[
                    styles.button,
                    res.isSubmissionDisabled && styles.btnDisabled,
                    res.isHighLightBtn && styles.highLightBtn
                  ]}
                  onPress={res.action}
                  disabled={res.isLoading || res.isSubmissionDisabled}
                >
                  <View style={styles.loadingWrapper}>
                    {res.isLoading && <ActivityIndicator style={{ marginEnd: 13 }} color="#ED1B2E" />}
                    <Text
                      style={[
                        styles.textButton,
                        res.isSubmissionDisabled && styles.btnTextDisabled,
                        res.isHighLightBtn && styles.highLightText
                      ]}
                    >
                      {res.name}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )
          )}

          {caterogy && ['PS', 'PC'].includes(caterogy) && renderFlagAML()}
          <View style={{ position: 'absolute', right: 10 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              {isUWTransaction && UWDecision && (
                <View
                  style={[
                    { flex: 1, flexWrap: 'wrap', flexDirection: UWDecision.isShowedTransferButton ? 'column' : 'row' },
                    !UWDecision.isShowedTransferButton && { alignItems: 'center' }
                  ]}
                >
                  <Checkbox
                    title={t('common:AcceptUW')}
                    checkBoxStyle={[styles.UWCheckBox, { marginTop: 4 }]}
                    disabled={isUWScreen || UWDecision.disabledAcceptUW}
                    value={UWDecision.UWAcceptVal}
                    onChange={(value) => {
                      UWDecision.setAcceptUWVal(value)
                    }}
                  />
                  {UWDecision.isShowedTransferButton && (
                    <Checkbox
                      title={t('common:Transfer To UW')}
                      checkBoxStyle={styles.UWCheckBox}
                      disabled={isUWScreen || UWDecision.disabledTransferUW || UWDecision.UWAcceptVal}
                      value={UWDecision.UWTransferVal}
                      onChange={(value) => {
                        UWDecision.setTransferUWVal(value)
                      }}
                    />
                  )}
                </View>
              )}

              {updateToCore !== undefined &&
                ![TaskType.DistributionService, TaskType.PremiumCollection].includes(caterogy as TaskType) && (
                  <Checkbox
                    disabled={disableUTC}
                    title={t('common:UpdateToCore')}
                    value={updateToCore.val}
                    onChange={updateToCore.setVal}
                  />
                )}
            </View>
          </View>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flex: 1
  },
  loadingWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 60,
    width: '100%',
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    marginRight: 0
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  highLightBtn: {
    backgroundColor: '#ed1b2e',
    color: '#fff'
  },
  highLightText: {
    color: '#fff',
    fontWeight: 'normal'
  },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  amlArea: {
    position: 'absolute',
    right: '25%',
    display: 'flex',
    alignItems: 'flex-start'
  },
  textAML: {
    fontSize: 15,
    fontWeight: '700'
  },
  textAMLSpecial: {
    color: '#ED1B2E'
  },
  textAMLNormal: {
    color: '#70777E'
  },
  UWCheckBox: {
    padding: 0,
    margin: 0
  }
})
