import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { RequestAuthFormDataSubmit, ProductEnum } from './model'
import { SubmissionService } from './SubmissionService'

export namespace PaidUpService {
  export interface SubmitData {
    requestPaidUpPeriod: string
    reason: string
  }

  export interface PaidUpResolverData {
    breadcrumb: string
    detail: PaidUpDetail
  }

  export interface PaidUpDetail {
    paidToDateAdvance: string
  }

  export const checkAccess = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policy) => {
        const { status, basicCode, premiumStatus, paidToDateAdvance } = policy.body
        const notValid =
          (basicCode && (['V', 'U'].some((code) => basicCode.startsWith(code)) || basicCode === 'IPD1')) ||
          status !== ProductEnum.Status.IF ||
          premiumStatus !== ProductEnum.PremiumStatus.PP
        return {
          isValid: !notValid,
          paidToDateAdvance
        }
      })
    )

  export const submit = (policyNum: string, body: SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
    SubmissionService.submit(t.unknown)(`wf-api/policy/${policyNum}/paid-up`, { body }, policyNum, requestauth, [])
}
