import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { OutboundParamList } from './OutboundParamList'
import { OBTaskManagementScreen } from './ob-task-management'
import { OBCallOutDetailScreen } from './ob-call-out-detail'
// import { OBServiceInquiryScreen } from './ob-service-inquiry'
import { OBReportScreen } from './ob-report'

const OutStack = createStackNavigator<OutboundParamList>()
export const OutboundStack = () => {
  return (
    <OutStack.Navigator initialRouteName="OBCallOutDetailScreen" screenOptions={{ headerShown: false }}>
      {/* <OutStack.Screen name="OBTaskManagementScreen" component={OBTaskManagementScreen}></OutStack.Screen> */}
      <OutStack.Screen name="OBCallOutDetailScreen" component={OBCallOutDetailScreen}></OutStack.Screen>
      {/* <OutStack.Screen name="OBServiceInquiryScreen" component={OBServiceInquiryScreen}></OutStack.Screen> */}
      <OutStack.Screen name="OBReportScreen" component={OBReportScreen}></OutStack.Screen>
    </OutStack.Navigator>
  )
}
