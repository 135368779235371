import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { OBCategoryTab, CategoryTabInfo } from '../../ob-task-management'
import { useTranslation } from 'react-i18next'
import { OBComment } from './OBComment'
import { OBRelatedDocument } from './OBRelatedDocument'
import { OBOnGoingRequest } from './OBOnGoingRequest'
import { CallOutDetailContext, OBHistory } from '@pulseops/outbound'
import { Permission } from '@pulseops/common'

export const OBRelatedInformation = () => {
  const { t } = useTranslation()
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const { permissions } = React.useContext(CallOutDetailContext)
  const [categoryTabList, setCategoryTabList] = React.useState<CategoryTabInfo[]>([])
  const [tabIndex, setTabIndex] = React.useState<CategoryTabInfo | null>(null)

  React.useEffect(() => {
    checkPermissionTabView()
  }, [permissions])

  const checkPermissionTabView = () => {
    const listTabPermission : CategoryTabInfo[] = []

    permissions.includes(Permission['ViewRelatedInfoCommentOBTaskDetail']) &&
    listTabPermission.push({
      label: t('Tab:Comment'),
      key: '1'
    })

    permissions.includes(Permission['ViewRelatedInfoHistoryOBTaskDetail']) &&
    listTabPermission.push({
      label: t('Tab:History'),
      key: '2'
    })

    permissions.includes(Permission['ViewRelatedInfoDocumentOBTaskDetail']) &&
    listTabPermission.push({
      label: t('Tab:Document'),
      key: '3'
    })

    permissions.includes(Permission['ViewRelatedInfoGoingRequestOBTaskDetail']) &&
    listTabPermission.push({
      label: t('Tab:OGR'),
      key: '4'
    })

    listTabPermission.length > 0 && setCategoryTabIndex(0) 
    listTabPermission.length > 0 && setTabIndex(listTabPermission[0])
    setCategoryTabList([...listTabPermission])
  }

  const getViewByTabIndex = (tab: CategoryTabInfo) => {
    switch (tab.key) {
      case '1':
        return <OBComment />
      case '2':
        return <OBHistory/>
      case '3':
        return <OBRelatedDocument/>
      case '4':
        return <OBOnGoingRequest/>
      default:
        return <></>
    }
  }
  
  return (
    <View style={relatedStyles.container}>
      <OBCategoryTab
        dataSource={categoryTabList}
        onChangeTab={(index) => { setCategoryTabIndex(index), setTabIndex(categoryTabList[index]) }}
        activeTabIndex={categoryTabIndex}
      ></OBCategoryTab>
      {tabIndex && getViewByTabIndex(tabIndex)}
    </View>
  )
}
const relatedStyles = StyleSheet.create({
  container: {
    flex: 1,
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  }
})