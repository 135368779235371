import { ZIO } from '@mxt/zio'
import { Nullable } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'

export namespace ChangeSumAssuredService {
  export const getDetail = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/sum-assured/get-data`)(
        t.type({
          body: t.type({
            coverageCode: t.string,
            curRenewalPremium: t.number,
            curSumAssured: t.number,
            suspenseAmount: t.number,
            advancePremium: t.number,
            minSAM: Nullable(t.number),
            maxSAM: Nullable(t.number),
            minSA: Nullable(t.number),
            maxSA: Nullable(t.number),
            minPremium: Nullable(t.number),
            maxPremium: Nullable(t.number)
          })
        })
      ),
      ZIO.map((_) => ({
        ..._.body,
        minSAM: _.body.minSAM || 0,
        maxSAM: _.body.maxSAM || 0,
        minSA: _.body.minSA || 0,
        maxSA: _.body.maxSA || 0,
        minPremium: _.body.minPremium ?? 0,
        maxPremium: _.body.maxPremium ?? 0
      }))
    )

  export const getNewInstallmentPremium = (policyNum: string, newSumAssured: number) =>
    pipe(
      POApi.post(`wf-api/policy/${policyNum}/sum-assured/comp-modify/action-f`)(
        t.type({
          body: t.type({
            // newPremium: t.number
            newTotalInstalmentPremium: t.number,
            // riderList: t.array(
            //   t.type({
            //     newPremium: t.number
            //   })
            // )
          })
        })
      )({
        body: {
          // newSumAssured: newSumAssured
          occurList: [{ sumAssured: newSumAssured }]
        }
      }),
      // ZIO.map((_) => _.body)
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) =>
          ZIO.effect(() => {
            // const riderList = success.body.riderList
            // return riderList && riderList.length > 0 ? riderList[0] : { newPremium: 0 }
            return success.body
          })
      )
    )
}
