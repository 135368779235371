import React, { forwardRef, LegacyRef, Ref, useImperativeHandle, useRef, useState } from 'react'
import { isNil } from 'lodash'
import { GroupCitizenI } from '../change-national-id-card.const'
import { FileUploadData, replaceSpecialCharactersInFileName } from '@pulseops/common'

export type EventType = React.ChangeEvent<HTMLInputElement>
export type DocumentType = {
  fileUpload: FileUploadData
  url: string | ArrayBuffer
  groupCitizen: GroupCitizenI | undefined
}

export interface ModalUploadCitizenRef {
  openUpload: (item: GroupCitizenI) => void
}

interface Props {
  ref: Ref<HTMLInputElement | ModalUploadCitizenRef>
  children: React.ReactNode | string
  onUpload: (e: EventType) => void
}
const UploadFileCitizen = ({ children, onUpload }: Props, ref: Props['ref']) => {
  const inputRef = useRef<LegacyRef<HTMLInputElement> | any>()
  const [group, setGroup] = useState<GroupCitizenI | undefined>()

  const handleChange = (e: EventType, callBack: ({ fileUpload, url, groupCitizen }: DocumentType) => {}) => {
    const { files } = e.target as HTMLInputElement
    if (isNil(files)) return

    const fileItem = files[0]
    const reader = new FileReader()
    const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
    const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''

    // filter file name and replace special characters
    const filterNewName = replaceSpecialCharactersInFileName(fileName)
    const fullFilterName = filterNewName + oldExtension
    const newFile = new File([fileItem], fullFilterName, { type: fileItem?.type })
    const fileExt = newFile?.name.split('.').pop() ?? ''

    reader.readAsDataURL(files[0]) // read file as data url

    reader.onloadend = (event) => {
      callBack({
        url: event?.target?.result ?? '',
        groupCitizen: group,
        fileUpload: {
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        }
      })
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      openUpload: (item: GroupCitizenI) => {
        inputRef.current?.click()
        setGroup({ ...item })
      },
      onChange: handleChange
    }),
    [group]
  )

  return (
    <>
      <input type="file" name="file" onChange={onUpload} accept="image/*" ref={inputRef} style={{ display: 'none' }} />
      {children}
    </>
  )
}

export default forwardRef(UploadFileCitizen)
