import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace RepayLoanForm {
  const RepayLoanform = t.type({
    formId: form2.string.required,
    repayFor: form2.selectOption.required,
    repayAmount: form2.string.required
  })

  export const codec = t.type({
    list: t.array(RepayLoanform)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
