import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { PaymentData } from './PaymentData'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.PARTIAL_SURRENDER)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    payload: t.type({
      body: t.type({
        policy: t.type({
          policyNo: t.string
        }),
        attributesExt: t.type({
          CURRENT_SUM_ASSURED: t.string,
          NEW_SUM_ASSURED: t.string,
          ADVANCE_PREMIUM: t.string,
          SUSPENSE_AMOUNT: t.string,
          LOAN_OVER_LIMIT: t.string,
          ESTIMATED_PAID_SURRENDER: t.string
        }),
        cashOutOptions: t.array(
          t.union([
            PaymentData.BankTransfer,
            PaymentData.EWalletMomo,
            PaymentData.PayPremium,
            PaymentData.Premium,
            PaymentData.PayLoan,
            PaymentData.TopUp,
            PaymentData.Reinstatement,
            PaymentData.NoType,
            PaymentData.RepayOpl,
            PaymentData.RepayApl,
            PaymentData.Other,
            PaymentData.Reins,
            PaymentData.PaidAtBank,
            PaymentData.CashAtCounter
          ])
        )
      })
    })
  })
)

export const PartialSurrender = Pulse4Ops
export type PartialSurrender = t.TypeOf<typeof PartialSurrender>
