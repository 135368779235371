import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { FundCodeC } from './FundCode'
import { DateFromNumber } from './task-detail-api/DateFromNumber'

export namespace MoreData {
  //CFI
  export const CFI = t.type({
    body: Maybe(
      t.type({
        customerReceivedDate: t.union([DateFromNumber, t.literal(99999999)]),
        effectiveDate: t.union([DateFromNumber, t.literal(99999999)]),
        advancePremium: t.number
      })
    )
  })
  export type CFI = t.TypeOf<typeof CFI>

  export const CFISuspendAmount = t.type({
    body: Maybe(
      t.type({
        suspendAmount: t.number
      })
    )
  })
  export type CFISuspendAmount = t.TypeOf<typeof CFISuspendAmount>

  //BILLING CHANGE
  export const BillingChangeSuspendAmount = t.type({
    body: Maybe(
      t.type({
        suspenseAmount: t.number
      })
    )
  })
  export type BillingChangeSuspendAmount = t.TypeOf<typeof BillingChangeSuspendAmount>

  export const BillingChangeAdvancePremium = t.type({
    body: Maybe(
      t.type({
        advancePremium: t.number
      })
    )
  })
  export type BillingChangeAdvancePremium = t.TypeOf<typeof BillingChangeAdvancePremium>

  export const BillingChangeBillToDate = t.type({
    body: Maybe(
      t.type({
        billToDate: t.union([DateFromNumber, t.literal(99999999)])
      })
    )
  })
  export type BillingChangeBillToDate = t.TypeOf<typeof BillingChangeBillToDate>

  export const BillingChangeReversalDateC = t.type({
    body: Maybe(
      t.array(
        t.type({
          translate: t.number,
          date: t.number
        })
      )
    )
  })
  export type BillingChangeReversalDate = t.TypeOf<typeof BillingChangeReversalDateC>

  //MATURITY
  export const MaturityNetTotalBenefit = t.type({
    body: Maybe(
      t.type({
        totalMaturityBenefit: t.number
      })
    )
  })
  export type MaturityNetTotalBenefit = t.TypeOf<typeof MaturityNetTotalBenefit>

  export const MaturitySuspendAmount = t.type({
    body: Maybe(
      t.type({
        suspendAmount: t.number
      })
    )
  })
  export type MaturitySuspendAmount = t.TypeOf<typeof MaturitySuspendAmount>

  //EXCESS PREMIUM
  export const ExcessPremium = t.type({
    body: Maybe(
      t.type({
        excessPremium: t.number
      })
    )
  })
  export type ExcessPremium = t.TypeOf<typeof ExcessPremium>

  //TOPUP
  export const TopupSuspendAmount = t.type({
    body: Maybe(
      t.type({
        suspendAmount: t.number
      })
    )
  })
  export type TopupSuspendAmount = t.TypeOf<typeof TopupSuspendAmount>

  //APL
  export const APL = t.type({
    body: Maybe(
      t.type({
        billingFrequency: t.string,
        renewalPremium: t.number
      })
    )
  })
  export type APL = t.TypeOf<typeof APL>

  export const APLTransactionDate = t.type({
    transactionDate: t.union([DateFromNumber, t.literal(99999999)])
  })

  export const APLTransactionDateBody = t.type({
    body: Maybe(t.union([APLTransactionDate, t.type({})]))
  })
  export type APLTransactionDateBody = t.TypeOf<typeof APLTransactionDateBody>

  //Reinstatement
  export const ReinsFunds = t.type({
    body: Maybe(
      t.array(
        t.type({
          lifcnum: t.string,
          laName: t.string
        })
      )
    )
  })
  export type ReinsFunds = t.TypeOf<typeof ReinsFunds>

  export const ReinsAgent = t.type({
    body: Maybe(
      t.type({
        agentNumber: t.string,
        servicingAgent: t.string
      })
    )
  })
  export type ReinsAgent = t.TypeOf<typeof ReinsAgent>

  //PW
  export const PwFunds = t.type({
    body: Maybe(
      t.array(
        t.type({
          fundAmount: t.number,
          fundValue: FundCodeC
        })
      )
    )
  })
  export type PwFunds = t.TypeOf<typeof PwFunds>

  export const ChangeContactInfoLocation = t.type({
    body: Maybe(
      t.type({
        location: Maybe(t.string)
      })
    )
  })
  export type ChangeContactInfoLocation = t.TypeOf<typeof ChangeContactInfoLocation>

  export const PoNameFromSql = t.type({
    body: Maybe(
      t.type({
        poName: Maybe(t.string)
      })
    )
  })
  export type PoNameFromSql = t.TypeOf<typeof PoNameFromSql>

  export const AddressFromSql = t.type({
    body: Maybe(
      t.type({
        address: Maybe(t.string)
      })
    )
  })
  export type AddressFromSql = t.TypeOf<typeof AddressFromSql>
}
