import { SelectOption } from '@pulseops/common'

export namespace UploadDocumentReportFormData {
  export interface UploadDocumentReport {
    fromDate: Date
    toDate: Date
    office: SelectOption[]
    department: SelectOption[]
    policyNumber: string
    proposalNumber: string
    agentCode: string
    template: SelectOption
  }
}
