import * as React from 'react'
import {
  assets,
  mapTransactionType,
  SubTaskCodeFromString,
  SubTaskCount,
  TaskOp,
  SourceType,
  TaskType,
  getAMLResultLabel,
  TransactionType,
  AuthService,
  ErrorHandling,
  totalAgingDays,
  SelectOption
} from '@pulseops/common'
import { UserDrawerParamList } from '@pulseops/main'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { BIG_SCREEN_SIZE, getSourceLabel, GroupType, useBigScreenThreshold, GroupTypeLastActivity } from './common'
import { IconSwitch } from './IconSwitch'
import { TaskStatus } from './TaskStatus'
import { CanView } from '../../common/src'
import { pipe } from 'fp-ts/function'

export type TaskCardType = 'MyTask' | 'TeamTask'

const formatAssigneeName = (name: string, email: string, defaultValue = '') => {
  if (name && email && name !== '-' && email !== '-') {
    return `${name}\n(${email.split('@')[0]})`
  }
  return defaultValue
}

const validateEmail = (mail: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }
  return false
}

interface Props {
  cardType?: TaskCardType
  data: TaskOp
  index: number
  basket: SubTaskCount
  selected: boolean
  canComment?: boolean
  disableSelect?: boolean
  containerStyle?: ViewStyle
  isShowLastActivity?: boolean
  onSelected?: (item: TaskOp, index: number) => void
  onUnSelected?: (item: TaskOp, index: number) => void
  onPressChatIcon?: (item: TaskOp, index: number) => void
  subServiceTypeList: SelectOption[]
  subTransactionTypeList: (SelectOption & { slaStandard: string })[]
}

export const TaskCard = ({
  cardType = 'MyTask',
  data,
  index = -1,
  selected,
  canComment = true,
  disableSelect = false,
  containerStyle = {},
  basket,
  isShowLastActivity,
  onSelected,
  onUnSelected,
  onPressChatIcon,
  subServiceTypeList,
  subTransactionTypeList
}: Props) => {
  const { navigate } = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()
  const { t, i18n } = useTranslation(['TaskManagement', 'DistributionService'])
  const { isBigScreen } = useBigScreenThreshold(BIG_SCREEN_SIZE)
  // const [subServiceTypeList, setSubServiceTypeList] = React.useState<SelectOption[]>([])
  // const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<
  //   (SelectOption & { slaStandard: string })[]
  // >([])
  const [isShowMore, setShowMore] = React.useState(false)

  const isHighlightAMLScreening = data.amlResult === 'HIT'
  const isHighlightAMLRedFlag = !!data.redFlagResult
  // React.useEffect(() => {
  //   // load Sub Service Type
  //   pipe(
  //     InquiryComplaintService.getTypes(),
  //     ZIO.map((typeList) => {
  //       if (typeList && typeList.length > 0) {
  //         setSubServiceTypeList(
  //           typeList.slice().map((x) => ({
  //             label: i18n.language === 'en' ? x.name : x.nameVi,
  //             value: x.code
  //           }))
  //         )
  //       } else {
  //         setSubServiceTypeList([])
  //       }
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  //   // load sub transaction type
  //   pipe(
  //     InquiryComplaintService.getSubType(),
  //     ZIO.map((typeList) => {
  //       if (typeList && typeList.length > 0) {
  //         setSubTransactionTypeList(
  //           typeList.slice().map((x) => ({
  //             label: i18n.language === 'en' ? x.name : x.nameVi,
  //             value: x.code,
  //             slaStandard: x.slaStandard ?? ''
  //           }))
  //         )
  //       } else {
  //         setSubTransactionTypeList([])
  //       }
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  // }, [i18n.language])

  const onTaskSelected = (active: boolean) => {
    if (active) {
      onSelected && onSelected(data, index)
    } else {
      onUnSelected && onUnSelected(data, index)
    }
  }

  const isEnableNavigateToDetail = () => {
    // tạm thời cho view detail khi task source không phải là PULSE4OPS

    if (
      data.source === SourceType.PULSE4OPS ||
      data.source === SourceType.PRUONLINE ||
      (data.source === SourceType.PULSE &&
        (data.transactionType !== TransactionType.CHANGE_CONTACT_INFO_ADDRESS || basket.code.task === TaskType.Claim))
    ) {
      return true
    }

    if (data.source === SourceType.BANCA_SEA && !!data.policyNumber && data.policyNumber !== 'null') {
      return true
    }

    // tạm thời cho view detail task có source từ LANDING_PAGE
    if (data.source === SourceType.LANDING_PAGE && !!data.policyNumber && data.policyNumber !== 'null') {
      return true
    }

    if (data.source === SourceType.ZALO && basket.code.task === TaskType.CustomerService) {
      return true
    }
    // tạm thời cho view detail khi source là complaint và task source là ZALO và EKIOSK
    if (
      basket.code.task === TaskType.Complaint &&
      ([SourceType.ZALO, SourceType.EKIOSK] as string[]).includes(data.source)
    ) {
      return true
    }

    // condition can view detail source OB/IB for change contact info trans
    if (
      !!data.policyNumber &&
      data.policyNumber !== 'null' &&
      (data.source === SourceType.OUTBOUND || data.source === SourceType.INBOUND) &&
      [
        TransactionType.CHANGE_CONTACT_INFO,
        TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL,
        TransactionType.CHANGE_CONTACT_INFO_PHONE,
        TransactionType.CHANGE_CONTACT_INFO_EMAIL,
        TransactionType.CHANGE_CONTACT_INFO_ADDRESS
      ].includes(data.transactionType as TransactionType)
    ) {
      return true
    }

    if (
      !!data.policyNumber &&
      data.policyNumber !== 'null' &&
      data.source === SourceType.INBOUND &&
      [
        TransactionType.INQUIRY,
        TransactionType.COMPLAINT,
        TransactionType.COMPLAINT_AND_INQUIRY
      ].includes(data.transactionType as TransactionType)
    ) {
      return true
    }

    if (data.source === SourceType.PRUDAILY && data.proposalNumber) {
      return true
    }

    if(data.source === SourceType.ZALO && [TransactionType.CANCEL_RIDER, TransactionType.EXCESS_PREMIUM].includes(data.transactionType as TransactionType)){
      return true
    }

    return false
  }
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const isSuspended = basket.code.subTask === 'SUSPENDED'
  const PS_DIGITAL = data?.transactionType ? data?.transactionType.includes('DIGITAL') : false
  const agentNumber =
    data.transactionType === TransactionType.AGENT_OFFICE_MOVING ||
    data.transactionType === TransactionType.AGENT_TERMINATION_AG ||
    data.transactionType === TransactionType.AGENT_UNIT_TRANSFER
  const policyNumber = data.transactionType === TransactionType.AGENT_TRANSFER_POLICY
  const eRefIdnumber =
    data.transactionType === TransactionType.AGENT_ONBOARD_NEW ||
    data.transactionType === TransactionType.AGENT_ONBOARD_REINS
  const checkAvicadStatus = () => {
    if (
      (data.transactionType === TransactionType.AGENT_ONBOARD_NEW &&
        data.suspendReason === 'AVICAD_SUSPEND' &&
        data.unsuspendReason !== 'AVICAD_SUSPEND') ||
      (data.transactionType === TransactionType.AGENT_ONBOARD_REINS &&
        data.suspendReason !== 'AVICAD_SUSPEND' &&
        data.unsuspendReason === 'AVICAD_SUSPEND')
    ) {
      return 'Not Pass'
    } else if (agentNumber || policyNumber) {
      return '-'
    } else {
      return 'Pass'
    }
  }
  const checkAgentKey = () => {
    if (policyNumber) {
      return data?.policyNumber ?? '-'
    } else if (agentNumber) {
      return data.agentCode ?? '-'
    } else {
      return data.clientNumber === 'null' ? '-' : data?.eref + '-' + data?.clientNumber || '-'
    }
  }
  const checkItemWriteOff =
    data.writeOffAction === 'ENABLE_WRITE_OFF' && isSuspended && basket.code.task === TaskType.PremiumCollection

  const showLabelType = (type: string) => {
    return subServiceTypeList.find((item) => item.value === type)?.label ?? ''
  }

  const showLabelSubType = (type: string) => {
    return subTransactionTypeList.find((item) => item.value === type)?.label ?? ''
  }

  return (
    <View>
      {data.category == 'DS' ? (
        <View style={[styles.container, isBigScreen ? wideStyles.container : mobileStyles.container, containerStyle]}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <IconSwitch
              size={24}
              disable={disableSelect}
              active={selected}
              ActiveIcon={<assets.TaskIcon_Checked />}
              InactiveIcon={disableSelect ? <assets.TaskIcon_UnChecked_Disable /> : <assets.TaskIcon_UnChecked />}
              onChanged={onTaskSelected}
            />
            <View style={{ flex: 1, marginStart: 8, flexDirection: 'row' }}>
              <Text style={styles.caseIdLabel}>{t('DistributionService:TransactionID')}</Text>
              <Pressable
                // tạm ko cho navigate qua trang detail khi source ko phải là PULSE4OPS
                // disabled={!eRefIdnumber}
                onPress={() =>
                  navigate('TaskDetailStack', {
                    screen: 'TaskDetailScreen',
                    params: {
                      id: data.id,
                      caterogy: basket.code.task,
                      basket: basket.code.subTask,
                      basketName: SubTaskCodeFromString.encode(basket.code),
                      isInquiry: '',
                      isHistory: 'false',
                      isPSDigital: PS_DIGITAL.toString(),
                      isBreadcrumb: '0'
                    }
                  })
                }
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 15,
                    color: eRefIdnumber || policyNumber || agentNumber ? '#1ea5fc' : '#333333',
                    textDecorationLine: eRefIdnumber || policyNumber || agentNumber ? 'underline' : 'none'
                  }}
                >
                  {checkAgentKey()}
                </Text>
              </Pressable>

              <CanView condition={false}>
                <View style={styles.overdueContainer}>
                  <assets.OverdueIcon />
                  <Text style={styles.overdue}>{t('TaskManagement:Overdue')}</Text>
                </View>
              </CanView>
            </View>

            <View style={{ marginStart: 8 }}>
              <CanView condition={canComment}>
                <TouchableOpacity
                  style={[styles.size_28, { marginTop: 0 }]}
                  activeOpacity={0.7}
                  onPress={() => onPressChatIcon && onPressChatIcon(data, index)}
                >
                  {/* <assets.ChatIcon /> */}
                  <assets.IconComment />
                </TouchableOpacity>
              </CanView>
            </View>
          </View>
          <View style={styles.infoContainer}>
            <CanView condition={!!data.status}>
              <TaskStatus type={data.status} containerStyle={{ marginTop: 6 }} />
            </CanView>
            <View style={{ flex: 1, marginTop: 20 }}>
              <View style={styles.rowInfo}>
                <Text style={styles.infoLabel}>{t('DistributionService:eREF')}: </Text>
                <Text style={styles.infoValue} numberOfLines={1}>
                  {data.eref || '-'}
                </Text>
              </View>
              <View style={styles.rowInfo}>
                <Text style={styles.infoLabel}>{t('DistributionService:AMLScreening')}: </Text>
                <Text style={styles.infoValue} numberOfLines={1}>
                  {data.amlResult ? getAMLResultLabel(data.amlResult) : '-'}
                </Text>
              </View>
              {/* <View style={styles.rowInfo}>
                <Text style={styles.infoLabel}>{t('DistributionService:AvicadStatus')}: </Text>
                <Text style={styles.infoValue} numberOfLines={1}>
                  {checkAvicadStatus()}
                </Text>
              </View> */}

              <CanView condition={isShowMore}>
                <CanView condition={cardType === 'TeamTask'}>
                  <View style={styles.rowInfo}>
                    <Text style={styles.infoLabel}>{t('DistributionService:Assignee')}: </Text>
                    <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                      <Text style={styles.infoValue} numberOfLines={1}>
                        {formatAssigneeName(data.assigneeName, data.assignee, '-')}
                      </Text>
                    </View>
                  </View>
                </CanView>

                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:Agingdays')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.aging || '-'}
                  </Text>
                </View>

                {/* {data.transactionType === TransactionType.NEW_CLAIM_REGISTER && ( */}
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:Totalagingdays')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.agingTotal || '-'}
                  </Text>
                </View>
                {/* )} */}

                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:TransactionType')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.transactionType ? t(mapTransactionType.get(data.transactionType) ?? '-') : '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:CaseID')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.caseId || '-'}
                  </Text>
                </View>

                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:SubmissionDate')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.createdDate ? format(data.createdDate, 'dd/MM/yyyy - HH:mm:ss') : '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:SourceSubmission')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.source || '-'}
                  </Text>
                </View>
                {/* <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('DistributionService:SaleType')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {'-'}
                  </Text>
                </View> */}
              </CanView>
            </View>
            <TouchableOpacity style={styles.btnMoreInfo} onPress={() => setShowMore(!isShowMore)} activeOpacity={0.7}>
              <Text style={styles.btnMoreInfoTitle}>
                {isShowMore ? t('TaskManagement:SeeLess') : t('TaskManagement:Moreinfo')}
              </Text>
              {isShowMore ? <assets.SeeLessTaskIcon /> : <assets.ShowMoreTaskIcon />}
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <View
          style={[
            styles.container,
            isBigScreen ? wideStyles.container : mobileStyles.container,
            containerStyle,
            { backgroundColor: checkItemWriteOff ? '#ed1b2e26' : '#FFFFFF' }
          ]}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <IconSwitch
              size={24}
              disable={disableSelect}
              active={selected}
              ActiveIcon={<assets.TaskIcon_Checked />}
              InactiveIcon={disableSelect ? <assets.TaskIcon_UnChecked_Disable /> : <assets.TaskIcon_UnChecked />}
              onChanged={onTaskSelected}
            />
            <View style={{ flex: 1, marginStart: 8, flexDirection: 'row' }}>
              <Text style={styles.caseIdLabel}>{t('TaskManagement:policy')}</Text>
              <Pressable
                // tạm ko cho navigate qua trang detail khi source ko phải là PULSE4OPS
                disabled={!isEnableNavigateToDetail()}
                onPress={() =>
                  navigate('TaskDetailStack', {
                    screen: 'TaskDetailScreen',
                    params: {
                      id:
                        userInfo?.email !== data.assignee && !isSuspended
                          ? (data.processInstanceId as string)
                          : data.id,
                      caterogy: basket.code.task,
                      basket: basket.code.subTask,
                      basketName: SubTaskCodeFromString.encode(basket.code),
                      isInquiry: userInfo?.email !== data.assignee && !isSuspended ? '1' : '',
                      isHistory: 'false',
                      isPSDigital: PS_DIGITAL.toString(),
                      isBreadcrumb: '0',
                      isCLPayout: data?.transactionType === 'PAYOUT' ? 'CL-PV' : undefined
                    }
                  })
                }
              >
                <Text style={styles.caseIdValue}>{data.policyNumber || data.proposalNumber || '-'}</Text>
              </Pressable>

              <CanView condition={false}>
                <View style={styles.overdueContainer}>
                  <assets.OverdueIcon />
                  <Text style={styles.overdue}>{t('TaskManagement:Overdue')}</Text>
                </View>
              </CanView>
            </View>

            <View style={{ marginStart: 8 }}>
              {/* <CanView condition={cardType === 'MyTask'}>
            <IconSwitch
              size={20}
              active={false}
              containerStyle={styles.size_20}
              ActiveIcon={<assets.PriorityStarFilledIcon />}
              InactiveIcon={<assets.PriorityStarNoFilledIcon />}
            />
          </CanView> */}
              {/* <TouchableOpacity style={[styles.size_20, { marginTop: 6 }]}>
                <assets.TimeIcon />
              </TouchableOpacity> */}
              <CanView condition={canComment}>
                <TouchableOpacity
                  style={[styles.size_28, { marginTop: 0 }]}
                  activeOpacity={0.7}
                  onPress={() => onPressChatIcon && onPressChatIcon(data, index)}
                >
                  {/* <assets.ChatIcon /> */}
                  <assets.IconComment />
                </TouchableOpacity>
              </CanView>
            </View>
          </View>

          <View style={styles.infoContainer}>
            <CanView condition={!!data.status}>
              <TaskStatus type={data.status} containerStyle={{ marginTop: 6 }} />
            </CanView>
            <View style={{ flex: 1, marginTop: 22 }}>
              <CanView condition={![TaskType.Complaint, TaskType.IDC].includes(data.category as TaskType)}>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:AMLScreening')}: </Text>
                  <Text
                    style={[styles.infoValue, isHighlightAMLScreening ? styles.textHighlight : {}]}
                    numberOfLines={1}
                  >
                    {data.amlResult ? getAMLResultLabel(data.amlResult) : '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{`${t('TaskManagement:AMLRedflag')}: `}</Text>
                  <Text style={[styles.infoValue, isHighlightAMLRedFlag ? styles.textHighlight : {}]} numberOfLines={1}>
                    {data.redFlagResult === null ? '-' : data.redFlagResult ? 'Y' : 'N'}
                  </Text>
                </View>
              </CanView>
              <CanView condition={data.category === TaskType.Complaint}>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:PoName')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.poName ?? '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{`${t('TaskManagement:Type')}: `}</Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.subServiceTypeCode ? showLabelType(data.subServiceTypeCode) : '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{`${t('TaskManagement:SubType')}: `}</Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.subTransactionCode ? showLabelSubType(data.subTransactionCode) : '-'}
                  </Text>
                </View>
              </CanView>
              <CanView condition={cardType === 'TeamTask'}>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:assignee')}: </Text>
                  <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                    {/* <Image
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 24,
                    marginEnd: 6
                  }}
                  source={{
                    uri: 'https://www.teahub.io/photos/full/35-352710_beautiful-nature-wallpaper-desktop-background.jpg'
                  }}
                /> */}
                    <Text style={styles.infoValue} numberOfLines={1}>
                      {formatAssigneeName(data.assigneeName, data.assignee, '-')}
                    </Text>
                  </View>
                </View>
              </CanView>

              <View style={styles.rowInfo}>
                <Text style={styles.infoLabel}>{t('TaskManagement:aging')}: </Text>
                <Text style={styles.infoValue} numberOfLines={1}>
                  {data.aging || '-'}
                </Text>
              </View>

              {/* {data.transactionType === TransactionType.NEW_CLAIM_REGISTER && (
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:totalAging')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.agingTotal || '-'}
                  </Text>
                </View>
              )} */}

              {GroupType.includes(basket.code.task) ? (
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:totalAging')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.createdDate ? totalAgingDays(data.createdDate) : ''}
                  </Text>
                </View>
              ) : (
                data.transactionType === TransactionType.NEW_CLAIM_REGISTER && (
                  <View style={styles.rowInfo}>
                    <Text style={styles.infoLabel}>{t('TaskManagement:totalAging')}: </Text>
                    <Text style={styles.infoValue} numberOfLines={1}>
                      {data.agingTotal || '-'}
                    </Text>
                  </View>
                )
              )}

              <CanView condition={isShowMore}>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:transactiontype')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.transactionType ? t(mapTransactionType.get(data.transactionType) ?? '-') : '-'}
                  </Text>
                </View>
                {basket.code.task !== TaskType.IDC && (
                  <View style={styles.rowInfo}>
                    <Text style={styles.infoLabel}>{t('TaskManagement:Request')}: </Text>
                    <Text style={styles.infoValue} numberOfLines={1}>
                      {data.transactionType === TransactionType.COMMON_REQUEST_FORM && data.request ? data.request : '-'}
                    </Text>
                  </View>
                )}
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:CaseID')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.caseId || '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:source')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.source ? getSourceLabel(data.source) : '-'}
                  </Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:createddate')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.startDate ? format(data.startDate, 'dd/MM/yyyy - HH:mm:ss') : '-'}
                  </Text>
                </View>
                {basket.code.task !== TaskType.IDC && (
                  <View style={styles.rowInfo}>
                    <Text style={styles.infoLabel}>{t('TaskManagement:SubmittedBy')}: </Text>
                    <Text style={styles.infoValue} numberOfLines={1}>
                      {data.createdBy && validateEmail(data.createdBy) ? data.createdBy.split('@')[0] : data.createdBy}
                    </Text>
                  </View>
                )}
                <View style={styles.rowInfo}>
                  <Text style={styles.infoLabel}>{t('TaskManagement:client')}: </Text>
                  <Text style={styles.infoValue} numberOfLines={1}>
                    {data.clientNumber || '-'}
                  </Text>
                </View>
                {GroupType.includes(data.category) && (
                  <View style={styles.rowInfo}>
                    <Text style={styles.infoLabel}>{t('common:OfficeCode')}: </Text>
                    <Text style={styles.infoValue} numberOfLines={1}>
                      {data.officeCode || '-'}
                    </Text>
                  </View>
                )}
                {GroupTypeLastActivity.includes(data.category) && (
                  <View style={styles.rowInfo}>
                    <Text style={styles.infoLabel}>{t('TaskManagement:LastActivity')}: </Text>
                    <Text style={styles.infoValue} numberOfLines={1}>
                      {data?.lastActivity ?? '-'}
                    </Text>
                  </View>
                )}
              </CanView>
            </View>
            <TouchableOpacity style={styles.btnMoreInfo} onPress={() => setShowMore(!isShowMore)} activeOpacity={0.7}>
              <Text style={styles.btnMoreInfoTitle}>
                {isShowMore ? t('TaskManagement:SeeLess') : t('TaskManagement:Moreinfo')}
              </Text>
              {isShowMore ? <assets.SeeLessTaskIcon /> : <assets.ShowMoreTaskIcon />}
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    padding: 10,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3
  },

  infoContainer: {
    flex: 1,
    marginTop: 12,
    marginHorizontal: 4
  },

  checkBox_uncheck: {
    width: 24,
    height: 24,
    borderRadius: 6,
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderWidth: 2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3
  },

  rowInfo: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'flex-start'
  },

  infoLabel: {
    minWidth: 130,
    fontWeight: '400',
    fontSize: 14,
    color: '#4F4F4F'
  },

  infoValue: {
    fontWeight: '600',
    fontSize: 14,
    color: '#000000',
    flexGrow: 1
  },

  caseIdLabel: {
    marginEnd: 6,
    fontWeight: 'normal',
    fontSize: 15,
    color: '#828282'
  },

  caseIdValue: {
    // marginTop: 2,
    fontWeight: '600',
    fontSize: 15,
    color: '#333333',
    textDecorationLine: 'underline'
  },

  size_20: {
    width: 20,
    height: 20
  },

  size_28: {
    width: 28,
    height: 28
  },

  btnMoreInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    marginEnd: 2,
    alignSelf: 'baseline'
  },

  btnMoreInfoTitle: {
    marginEnd: 4,
    color: '#333333',
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 17
  },

  overdueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4
  },

  overdue: {
    marginStart: 7,
    fontSize: 15,
    fontWeight: '700',
    color: '#ED1B2E'
  },

  textHighlight: {
    color: '#ED1B2E'
  }
})

const wideStyles = StyleSheet.create({
  container: {
    alignSelf: 'auto'
  }
})

const mobileStyles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    width: 310,
    minHeight: 200
  }
})
