import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { GeneralInfo, WfDate } from './model'

export namespace WfApi {
  const endpoint = `wf-api`

  export const Policy = t.type(
    {
      body: Maybe(
        t.type({
          salaryIncome: Maybe(t.number),
          lastIssueDate: Maybe(WfDate),
          billToDate: Maybe(WfDate),
          billFreq: Maybe(t.string),
          mainLifeAssuredNumber: Maybe(t.string),
          productCode: Maybe(t.string),
          productType: Maybe(t.string),
          clientDespatchAddress: Maybe(t.string),
          policyNo: Maybe(t.string),
          totalPremium: Maybe(t.string),
          contractDate: Maybe(WfDate),
          firstIssueDate: Maybe(WfDate),
          premiumStatus: Maybe(t.string),
          proposal: Maybe(
            t.type({
              proposalNo: Maybe(t.string),
              proposalReceivedDate: Maybe(WfDate)
            })
          ),
          owners: Maybe(
            t.type({
              id: Maybe(t.string)
            })
          ),
          servingAgents: Maybe(
            t.type({
              agent: t.type({
                code: Maybe(t.string)
              })
            })
          )
        })
      )
    },
    'Policy'
  )

  export type Policy = t.TypeOf<typeof Policy>

  export const getPolicy = (policyNum: string) => pipe(POApi.get(`${endpoint}/policy/${policyNum}`)(Policy))

  export const Client = t.type(
    {
      clientName: t.string,
      clientCode: t.string,
      firstName: t.string,
      lastName: t.string,
      clientPhoneNumber: t.string,
      companyPhoneNumber: t.string,
      homePhoneNumber: t.string,
      gender: t.string,
      birthDate: t.string,
      nationality: t.string,
      email: t.string,
      addressCode: t.string,
      addressStreet: t.string,
      addressWard: t.string,
      addressDistrict: t.string,
      addressProvince: t.string
    },
    'Client'
  )

  export type Client = t.TypeOf<typeof Client>

  export const Customer = t.type(
    {
      body: t.type({
        dob: t.string,
        name: t.string,
        nationality: t.string,
        sex: t.string
      })
    },
    'Customer'
  )

  export type Customer = t.TypeOf<typeof Customer>

  export const getCustomer = (policyNum: string) => pipe(POApi.get(`${endpoint}/customer/${policyNum}`)(Customer))

  namespace Mock {
    export const date = new Date()
    export const phoneNUmber: GeneralInfo.PhoneNumber = {
      zone: '-',
      value: '-'
    }
    export const codeName: GeneralInfo.CodeName = {
      code: '-',
      name: '-'
    }
    export const agent: GeneralInfo.ServicingAgent = {
      person: codeName,
      homePhone: phoneNUmber,
      mobilePhone: phoneNUmber
    }
  }

  export const createGeneralInfo = ({ policy, client }: { policy: Policy; client: Customer }): GeneralInfo => {
    return {
      policyOwner: Mock.codeName,
      lifeAssuredList: [Mock.codeName],
      aplDate: Mock.date,
      contract: { type: '-', status: '-' },
      premiumStatus: '-',
      sumAssured: -1,
      installmentPremium: -1,
      billingFrequency: '-',
      firstIssueDate: Mock.date,
      policyIssueDate: Mock.date,
      riskCommencementDate: Mock.date,
      paidToDateAdvanceBasic: Mock.date,
      paidToDateBasic: Mock.date,
      riskCessDate: Mock.date,
      paidToDateAdvanceRider: Mock.date,
      paidToDateRider: Mock.date,
      surrenderValue: -1,
      prucashBenefit: -1,
      maturityBenefit: -1,
      specialBonus: -1,
      suspenseAmount: -1,
      prucashDetail: [
        { interest: -1, principal: -1, receivedAmount: -1, receivedDate: Mock.date, startDate: Mock.date, total: -1 },
        { interest: -1, principal: -1, receivedAmount: -1, receivedDate: Mock.date, startDate: Mock.date, total: -1 }
      ],

      // investment
      estimateValue: -1,
      fundDetail: [
        { fund: '-', pricingDate: Mock.date, unitBalance: -1, unitPrice: -1, unitValue: -1 },
        { fund: '-', pricingDate: Mock.date, unitBalance: -1, unitPrice: -1, unitValue: -1 }
      ],

      agent: Mock.codeName,
      sharedAgent: Mock.codeName,
      unitManager: Mock.codeName,
      saleUnit: Mock.codeName,
      saleBranch: Mock.codeName,
      gaCode: Mock.codeName,
      region: Mock.codeName,
      zone: Mock.codeName,
      office: Mock.codeName,

      contactInfo: {
        street: '-',
        country: '-',
        location: '-',
        city: '-',
        district: '-',
        ward: '-',
        email: '-',
        mobilePhone: Mock.phoneNUmber,
        homePhone: Mock.phoneNUmber
      }
    }
  }
}
