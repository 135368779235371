import React from 'react'
import { View } from 'react-native'
import { OBSectionContent, OBSharedStyles } from '../../../ob-common'
import { useTranslation } from 'react-i18next'
import { CallOutDetailContext } from '../../ob-common'
import { OBFieldList } from '../OBFieldList'
import { pipe } from 'fp-ts/lib/function'
import { OBImportFileService } from '../../../ob-service'
import { ZIO } from '@mxt/zio'
import { Container, ErrorHandling, PulseOpsFormat } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import * as O from 'fp-ts/lib/Option'
import { useLoading } from '@mxt/zio-react'

export const OBADDHOCSpecialInformation = () => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { ADDHOCImportedParamData } = React.useContext(CallOutDetailContext)
  const [isLoading, bindLoader] = useLoading(false)

  const headerTemplateData = pipe(
    isFocused ? OBImportFileService.getListTemplateImportFile() : ZIO.succeed([]),
    ZIO.map((templateArr) => {
      const headerNameData = templateArr.find((x) => x.adhocCode === ADDHOCImportedParamData?.adhocCode)
      return headerNameData
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([ADDHOCImportedParamData])
  )

  const getParamName = (paramIndex: number) => React.useMemo(() => {
    const isEnLanguage = i18n.language === 'en'
    switch (paramIndex) {
      case 1:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM01 || '' : headerTemplateData?.headerTemplateVn.PARAM01 || ''
      case 2:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM02 || '' : headerTemplateData?.headerTemplateVn.PARAM02 || ''
      case 3:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM03 || '' : headerTemplateData?.headerTemplateVn.PARAM03 || ''
      case 4:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM04 || '' : headerTemplateData?.headerTemplateVn.PARAM04 || ''
      case 5:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM05 || '' : headerTemplateData?.headerTemplateVn.PARAM05 || ''
      case 6:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM06 || '' : headerTemplateData?.headerTemplateVn.PARAM06 || ''
      case 7:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM07 || '' : headerTemplateData?.headerTemplateVn.PARAM07 || ''
      case 8:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM08 || '' : headerTemplateData?.headerTemplateVn.PARAM08 || ''
      case 9:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM09 || '' : headerTemplateData?.headerTemplateVn.PARAM09 || ''
      case 10:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM10 || '' : headerTemplateData?.headerTemplateVn.PARAM10 || ''
      case 11:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM11 || '' : headerTemplateData?.headerTemplateVn.PARAM11 || ''
      case 12:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM12 || '' : headerTemplateData?.headerTemplateVn.PARAM12 || ''
      case 13:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM13 || '' : headerTemplateData?.headerTemplateVn.PARAM13 || ''
      case 14:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM14 || '' : headerTemplateData?.headerTemplateVn.PARAM14 || ''
      case 15:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM15 || '' : headerTemplateData?.headerTemplateVn.PARAM15 || ''
      case 16:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM16 || '' : headerTemplateData?.headerTemplateVn.PARAM16 || ''
      case 17:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM17 || '' : headerTemplateData?.headerTemplateVn.PARAM17 || ''
      case 18:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM18 || '' : headerTemplateData?.headerTemplateVn.PARAM18 || ''
      case 19:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM19 || '' : headerTemplateData?.headerTemplateVn.PARAM19 || ''
      case 20:
        return isEnLanguage ? headerTemplateData?.headerTemplateEn.PARAM20 || '' : headerTemplateData?.headerTemplateVn.PARAM20 || ''
      default:
        return ''
    }
  }, [headerTemplateData, i18n.language])

  const getParamValue = (paramIndex: number) => {
    switch (paramIndex) {
      case 1:
        const paramValue1 = pipe(
          headerTemplateData?.headerParamType?.PARAM01 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM01 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM01,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue1
      case 2:
        const paramValue2 = pipe(
          headerTemplateData?.headerParamType?.PARAM02 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM02 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM02,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue2
      case 3:
        const paramValue3 = pipe(
          headerTemplateData?.headerParamType?.PARAM03 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM03 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM03,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue3

      case 4:
        const paramValue4 = pipe(
          headerTemplateData?.headerParamType?.PARAM04 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM04 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM04,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue4
      case 5:
        const paramValue5 = pipe(
          headerTemplateData?.headerParamType?.PARAM05 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM05 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM05,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue5
      case 6:
        const paramValue6 = pipe(
          headerTemplateData?.headerParamType?.PARAM06 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM06 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM06,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue6
      case 7:
        const paramValue7 = pipe(
          headerTemplateData?.headerParamType?.PARAM07 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM07 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM07,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue7
      case 8:
        const paramValue8 = pipe(
          headerTemplateData?.headerParamType?.PARAM08 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM08 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM08,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue8
      case 9:
        const paramValue9 = pipe(
          headerTemplateData?.headerParamType?.PARAM09 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM09 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM09,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue9
      case 10:
        const paramValue10 = pipe(
          headerTemplateData?.headerParamType?.PARAM10 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM10 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM10,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue10

      case 11:
        const paramValue11 = pipe(
          headerTemplateData?.headerParamType?.PARAM11 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM11 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM11,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue11
      case 12:
        const paramValue12 = pipe(
          headerTemplateData?.headerParamType?.PARAM12 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM12 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM12,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue12
      case 13:
        const paramValue13 = pipe(
          headerTemplateData?.headerParamType?.PARAM13 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM13 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM13,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue13
      case 14:
        const paramValue14 = pipe(
          headerTemplateData?.headerParamType?.PARAM01 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM14 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM14,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue14
      case 15:
        const paramValue15 = pipe(
          headerTemplateData?.headerParamType?.PARAM15 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM15 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM15,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue15
      case 16:
        const paramValue16 = pipe(
          headerTemplateData?.headerParamType?.PARAM16 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM16 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM16,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue16
      case 17:
        const paramValue17 = pipe(
          headerTemplateData?.headerParamType?.PARAM17 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM17 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM17,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue17
      case 18:
        const paramValue18 = pipe(
          headerTemplateData?.headerParamType?.PARAM18 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM18 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM18,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue18
      case 19:
        const paramValue19 = pipe(
          headerTemplateData?.headerParamType?.PARAM19 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM19 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM19,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue19
      case 20:
        const paramValue20 = pipe(
          headerTemplateData?.headerParamType?.PARAM20 === 'currency',
          O.fromNullable,
          O.fold(
            () => ADDHOCImportedParamData?.paramData.PARAM20 || '',
            (isCurrencyItem) => pipe(
              ADDHOCImportedParamData?.paramData.PARAM20,
              O.fromNullable,
              O.map((paramVal) => isCurrencyItem ? PulseOpsFormat.thousandSepartor(Number(paramVal)) : paramVal),
              O.getOrElse(() => '')
            )
          )
        )
        return paramValue20
      default:
        return ''
    }
  }

  const fieldList = [
    {
      title: t('Adhoc code'),
      value: ADDHOCImportedParamData?.adhocCode || ''
    },
    {
      title: t('Adhoc name'),
      value: ADDHOCImportedParamData?.adhocName || ''
    },
    {
      title: getParamName(1),
      value: getParamValue(1)
    },
    {
      title: getParamName(2),
      value: getParamValue(2)
    },
    {
      title: getParamName(3),
      value: getParamValue(3)
    },
    {
      title: getParamName(4),
      value: getParamValue(4)
    },
    {
      title: getParamName(5),
      value: getParamValue(5)
    },
    {
      title: getParamName(6),
      value: getParamValue(6)
    },
    {
      title: getParamName(7),
      value: getParamValue(7)
    },
    {
      title: getParamName(8),
      value: getParamValue(8)
    },
    {
      title: getParamName(9),
      value: getParamValue(9)
    },
    {
      title: getParamName(10),
      value: getParamValue(10)
    },
    {
      title: getParamName(11),
      value: getParamValue(11)
    },
    {
      title: getParamName(12),
      value: getParamValue(12)
    },
    {
      title: getParamName(13),
      value: getParamValue(13)
    },
    {
      title: getParamName(14),
      value: getParamValue(14)
    },
    {
      title: getParamName(15),
      value: getParamValue(15)
    },
    {
      title: getParamName(16),
      value: getParamValue(16)
    },
    {
      title: getParamName(17),
      value: getParamValue(17)
    },
    {
      title: getParamName(18),
      value: getParamValue(18)
    },
    {
      title: getParamName(19),
      value: getParamValue(19)
    },
    {
      title: getParamName(20),
      value: getParamValue(20)
    }

  ]
  return (
    <Container loading={isLoading} >
      <View style={OBSharedStyles.policyInfoContainer}>
        <OBSectionContent>
          <OBFieldList dataSource={fieldList} ></OBFieldList>
        </OBSectionContent>
      </View>
    </Container>

  )
}