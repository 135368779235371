import * as React from 'react'
import {
  ModalComponent,
  HeaderTable,
  Title,
  PayoutPopup,
  formatNumberWithComma,
  FieldList,
  assets,
  form2,
  Input,
  Divider,
  PayoutService,
  ModalComponentRef,
  Select,
  InputTable,
  ModalAction,
  RowSelectedProps,
  Columns,
  DataSource
} from '@pulseops/common'
import {
  useWindowDimensions,
  View,
  ScrollView,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  StyleSheet
} from 'react-native'
import { RepayLoanForm } from './reapay-loan.form'
import { PolicyOfOrtherClientForm } from './policy-of-orther-client.form'
import { getRepayForOption, getPayoutMethod } from './repay-loan.helper'
import { Controller, useFieldArray } from 'react-hook-form'
import { Label, Error, Column } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { RepayLoanConst } from './repay-loan.constant'
interface IconButtonProps {
  title: string
  style?: StyleProp<ViewStyle>
  icon: () => JSX.Element
  onPress: () => void
}

const IconButton = ({ title, icon, style, onPress }: IconButtonProps) => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        {
          height: 32,
          alignItems: 'center',
          flexDirection: 'row',
          alignSelf: 'baseline'
        },
        style
      ]}
      onPress={onPress}
    >
      {icon()}
      <Divider width={12} />
      <Label title={title} fontWeight="600" color="#000000" />
    </TouchableOpacity>
  )
}

interface Props {
  primaryPolicy: string
  details: PayoutPopup.ReloanPayData[]
  visible: boolean
  maxNetAmount: number
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData[]) => void
}

export const RepayLoanPopup = ({ visible, details, primaryPolicy, maxNetAmount, onClose, onConfirm }: Props) => {
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const [rowsSelected, setRowsSelected] = React.useState<Map<number, PayoutPopup.ReloanPayData>>(new Map())
  const [loadingMap, setLoadingMap] = React.useState<Map<number, boolean>>(new Map())

  const repayLoanForm = form2.useForm(RepayLoanForm.codec, { defaultValues: { list: [] } })

  const repayLoanFormList = useFieldArray<RepayLoanForm.Raw>({
    name: 'list',
    control: repayLoanForm.base.control
  })

  const POCForm = form2.useForm(PolicyOfOrtherClientForm.codec, { defaultValues: { list: [] } })

  const POCFormList = useFieldArray<PolicyOfOrtherClientForm.Raw>({
    control: POCForm.base.control,
    name: 'list'
  })

  const showSearchLoadingAtIndex = (index: number, isShow: boolean) => {
    const map = new Map(loadingMap)
    map.set(index, isShow)
    setLoadingMap(map)
  }

  const searchPolicy = (policyNum: string, formIndex: number) => {
    POCFormList.update(formIndex, { policyNumber: policyNum })

    if (policyNum === primaryPolicy) {
      modalRef?.current?.showToast(t('message:MS050110'), 'error')
      return
    }

    showSearchLoadingAtIndex(formIndex, true)
    return pipe(
      PayoutService.searchPolicyRepayLoan(policyNum),
      ZIO.catchAll((error) => {
        showSearchLoadingAtIndex(formIndex, false)
        modalRef?.current?.showToast(error.source.message, 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap((response) => {
        showSearchLoadingAtIndex(formIndex, false)
        let data: any = { policyNumber: policyNum }
        if (response.isNotFound) {
          modalRef?.current?.showToast(t('message:MS030029'), 'error')
          data = { policyNumber: policyNum }
          // POCFormList.update(formIndex, { policyNumber: policyNum })
        } else {
          if (response && response.isRepayLoanvalid) {
            data = {
              policyNumber: policyNum,
              poName: response.poName,
              oplAmount: `${response.opl}`,
              aplAmount: `${response.apl}`,
              totalPremium: `${response.totalPremium}`
            }
            // POCFormList.update(formIndex, data)
          } else {
            modalRef?.current?.showToast(t('message:MS050259'), 'error')
            data = { policyNumber: policyNum }
            // POCFormList.update(formIndex, { policyNumber: policyNum })
          }
        }
        POCFormList.update(formIndex, data)
        POCFormList.update(formIndex, data)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const resetForm = () => {
    rowsSelected.clear()
    repayLoanFormList.remove()
    POCFormList.remove()
  }

  const onPressClose = () => {
    resetForm()
    onClose()
  }

  const onPressConfirm = async () => {
    const isRepayLoanFormValid = await repayLoanForm.base.trigger()
    const isPOCFormValid = await POCForm.base.trigger()

    let payoutData: PayoutPopup.PayoutData[] = []

    if (isRepayLoanFormValid) {
      const repayLoanData: PayoutPopup.PayoutData[] = repayLoanForm.base.getValues('list').map((item) => {
        const row = rowsSelected.get(Number(item.formId))
        return {
          method: getPayoutMethod(item.repayFor?.value || ''),
          amount: Number(item.repayAmount),
          policyNum: row?.policyNum,
          poName: row?.poName,
          totalPremium: row?.totalPremium,
          payee: undefined,
          officeBankCode: undefined,
          officeType: undefined
        }
      })
      payoutData = repayLoanData
    }

    if (isPOCFormValid) {
      const otherPolicyArr: PayoutPopup.PayoutData[] = POCForm.base
        .getValues('list')
        .filter((e) => Number(e.repayAmount) > 0)
        .map(
          (e): PayoutPopup.PayoutData => ({
            method: getPayoutMethod(e.repayFor?.value || ''),
            amount: Number(e.repayAmount),
            policyNum: e.policyNumber,
            poName: e.poName || '-',
            totalPremium: Number(e.totalPremium),
            payee: undefined,
            officeBankCode: undefined,
            officeType: undefined
          })
        )

      payoutData = [...payoutData, ...otherPolicyArr]
    }
    onConfirm(payoutData)
    resetForm()
  }

  const { isRepayLoanFormValid, isShowErrorMS050196, isShowErrorMS050159 } = React.useMemo(() => {
    const list = repayLoanForm.base.getValues('list')
    const formValidated = list.map((form) => {
      const result = { isFormValid: true, isShowErrorMS050196: false, isShowErrorMS050159: false }
      if (!form.formId || !form.repayAmount || !form.repayFor) {
        result.isFormValid = false
        result.isShowErrorMS050196 = false
      } else {
        const repayForValue = form.repayFor.value
        const amount = Number(form.repayAmount)
        const { apl, opl } = details[Number(form.formId)]
        const isAmountValid =
          (repayForValue === RepayLoanConst.RepayFor.REPAYAPL && amount <= apl) ||
          (repayForValue === RepayLoanConst.RepayFor.REPAYOPL && amount <= opl) ||
          (repayForValue === RepayLoanConst.RepayFor.REPAY_OPL_APL && amount <= apl + opl)

        if (isAmountValid) {
          result.isFormValid = true
          result.isShowErrorMS050196 = false
        } else {
          result.isFormValid = false
          result.isShowErrorMS050196 = true
        }
      }

      if (Number(form.repayAmount) > maxNetAmount) {
        result.isShowErrorMS050196 = false
        result.isShowErrorMS050159 = true
      }

      return result
    })
    return {
      isRepayLoanFormValid: list.length > 0 && formValidated.find((item) => !item.isFormValid) === undefined,
      isShowErrorMS050196: formValidated.find((item) => item.isShowErrorMS050196) !== undefined,
      isShowErrorMS050159: formValidated.find((item) => item.isShowErrorMS050159) !== undefined
    }
  }, [repayLoanForm.base.watch()])

  const { isPOCFormValid, pocFormValidated } = React.useMemo(() => {
    const list = POCForm.base.getValues('list')
    const formValidated = list.map((form) => {
      const result = { isFormValid: true, error: '' }
      if (!form.repayFor || !form.repayAmount) {
        result.isFormValid = false
      } else {
        const repayForValue = form.repayFor.value
        const amount = Number(form.repayAmount)
        const { aplAmount, oplAmount } = form
        const apl = Number(aplAmount)
        const opl = Number(oplAmount)
        const isAmountValid =
          (repayForValue === RepayLoanConst.RepayFor.REPAYAPL && amount <= apl) ||
          (repayForValue === RepayLoanConst.RepayFor.REPAYOPL && amount <= opl) ||
          (repayForValue === RepayLoanConst.RepayFor.REPAY_OPL_APL && amount <= apl + opl)
        if (isAmountValid) {
          result.isFormValid = true
        } else {
          result.isFormValid = false
          result.error = 'MS050196'
        }
      }

      if (Number(form.repayAmount) > maxNetAmount) {
        result.isFormValid = false
        result.error = 'MS050159'
      }
      return result
    })
    return {
      isPOCFormValid: list.length > 0 && formValidated.find((item) => !item.isFormValid) === undefined,
      pocFormValidated: formValidated
    }
  }, [POCForm.base.watch()])
  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled:
        (POCFormList.fields.length === 0 && !isRepayLoanFormValid) ||
        (repayLoanFormList.fields.length === 0 && !isPOCFormValid) ||
        (POCFormList.fields.length > 0 &&
          repayLoanFormList.fields.length > 0 &&
          (!isRepayLoanFormValid || !isPOCFormValid)),
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]

  const renderRepayForController = ({ index, isSelected }: RowSelectedProps) => {
    if (isSelected) {
      const formIndex = repayLoanFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
      if (formIndex >= 0) {
        const detail = details[index]
        const options = getRepayForOption(detail.apl, detail.opl)
        return (
          <View style={{ marginHorizontal: 8 }}>
            <Controller
              control={repayLoanForm.base.control}
              name={`list.${formIndex}.repayFor`}
              render={({ field }) => <Select {...field} options={options} filled={true} numberOfLine={1} />}
            />
          </View>
        )
      }
    }
    return <View />
  }

  const renderRepayAmountController = ({ index, isSelected }: RowSelectedProps) => {
    if (isSelected) {
      const formIndex = repayLoanFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
      if (formIndex >= 0) {
        return (
          <View style={{ marginHorizontal: 8 }}>
            <Controller
              control={repayLoanForm.base.control}
              name={`list.${formIndex}.repayAmount`}
              render={({ field, fieldState: { error } }) => (
                <InputTable
                  {...field}
                  value={field.value || ''}
                  inputType={'money'}
                  textAlign="right"
                  onChange={(val) => field.onChange(Number(val) > 0 ? val : '')}
                />
              )}
            />
          </View>
        )
      }
    }
    return <View />
  }

  const repayLoanTableColumns: Columns[] = [
    {
      key: '0',
      name: 'policyNum',
      title: t('submission:PolicyNumber'),
      styles: {
        textAlign: 'center'
      }
    },
    {
      key: '1',
      name: 'OPLAmount',
      title: t('submission:OPLamount'),
      styles: {
        textAlign: 'center'
      }
    },
    {
      key: '2',
      name: 'APLAmount',
      title: t('submission:APLamount'),
      styles: {
        textAlign: 'center'
      }
    },
    {
      key: '3',
      name: 'repayFor',
      require: true,
      title: t('submission:RepayFor'),
      render: renderRepayForController,
      styles: {
        textAlign: 'center'
      }
    },
    {
      key: '4',
      name: 'repayAmount',
      require: true,
      title: t('submission:RepayAmount'),
      render: renderRepayAmountController,
      styles: {
        textAlign: 'center'
      }
    }
  ]

  const onTableRowSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.set(index, details[index])
    setRowsSelected(rows)
    repayLoanFormList.append({
      formId: `${index}`,
      repayFor: null,
      repayAmount: ''
    })
  }

  const onTableRowUnSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.delete(index)
    setRowsSelected(rows)
    const targetIndex = repayLoanFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
    repayLoanFormList.remove(targetIndex)
  }

  const onSelectedAllTableRow = () => {
    const rows = details.reduce((result, item, index) => {
      result.set(index, item)
      const isFormExists = repayLoanFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`) > -1
      if (!isFormExists) {
        repayLoanFormList.append({
          formId: `${index}`,
          repayFor: null,
          repayAmount: ''
        })
      }
      return result
    }, new Map<number, PayoutPopup.ReloanPayData>())
    setRowsSelected(rows)
  }

  const onUnSelectedAllTableRow = () => {
    setRowsSelected(new Map())
    repayLoanFormList.remove()
  }

  const renderSearchIcon = (isEnable: boolean, isLoading: boolean) => {
    return isLoading ? (
      <ActivityIndicator color="red" size={24} />
    ) : isEnable ? (
      <assets.Search20Icon />
    ) : (
      <assets.SearchDisableIcon />
    )
  }

  const renderPOCPolicySearchInputController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.policyNumber`}
        render={({ field, fieldState: { error, invalid } }) => {
          const isValid = field.value && !invalid
          const isSearching = loadingMap.get(index) || false
          return (
            <View style={{ marginBottom: 22 }}>
              <View style={styles.searchPolicyContainer}>
                <Input
                  {...field}
                  title={t('submission:PolicyNumber')}
                  required={true}
                  value={field.value || ''}
                  maxLength={8}
                  disabled={isSearching}
                  alwayShowUnderline={true}
                  onKeyEnter={() => field.value && searchPolicy(field.value, index)}
                />
                <TouchableOpacity
                  disabled={!isValid || isSearching}
                  onPress={() => field.value && searchPolicy(field.value, index)}
                  style={styles.btnSearch}
                >
                  {renderSearchIcon(isValid, isSearching)}
                </TouchableOpacity>
              </View>
              {error?.message && <Error message={error.message} />}
            </View>
          )
        }}
      />
    )
  }

  const renderPOCRepayInputController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.repayAmount`}
        render={({ field }) => (
          <Input
            {...field}
            value={field.value || ''}
            inputType={'money'}
            errorMessage={pocFormValidated[index]?.error ? t(`message:${pocFormValidated[index].error}`) : ''}
          />
        )}
      />
    )
  }

  const renderPOCRepayForSelectController = (index: number) => {
    const data = POCForm.base.getValues('list')[index]
    const apl = Number(data?.aplAmount) || 0
    const opl = Number(data?.oplAmount) || 0
    const options = getRepayForOption(apl, opl)
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.repayFor`}
        render={({ field }) => (
          <View style={{ marginEnd: 60 }}>
            <Select {...field} options={options} numberOfLine={1} />
          </View>
        )}
      />
    )
  }

  const renderPOCFieldList = (index: number) => {
    const data = POCForm.base.getValues('list')[index]
    return (
      <FieldList
        dataSource={[
          {
            label: t('submission:POname'),
            value: data?.poName?.trim() || '-'
          },
          {
            label: t('submission:OPLamount'),
            value: `${formatNumberWithComma(data?.oplAmount)} VND` || '-'
          },
          {
            label: t('submission:APLamount'),
            value: `${formatNumberWithComma(data?.aplAmount)} VND` || '-'
          },
          {
            label: t('submission:RepayFor'),
            required: true,
            value: '',
            render: ({}) => renderPOCRepayForSelectController(index)
          },
          {
            label: t('submission:RepayAmount'),
            required: true,
            value: '',
            render: ({}) => renderPOCRepayInputController(index)
          }
        ]}
      />
    )
  }

  const renderPOCForm = (item: Record<'id', string>, index: number) => {
    return (
      <View key={item.id} style={styles.ortherPolicyContainer}>
        {renderPOCPolicySearchInputController(index)}
        {renderPOCFieldList(index)}
        <IconButton
          title={t('common:Delete')}
          icon={() => <assets.DeleteBin />}
          onPress={() => POCFormList.remove(index)}
        />
      </View>
    )
  }

  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:RepayLoan')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 932)}
      modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <Title title={t('submission:RepayLoan')} wrapperStyle={styles.bigLabel} />
        <HeaderTable
          selectable={true}
          rowsSelected={rowsSelected}
          unit={'VND'}
          headerContainerStyle={{ backgroundColor: '#FAFAFA' }}
          rowStyle={{ backgroundColor: '#FAFAFA' }}
          selectBoxContainerStyle={{ justifyContent: 'center', alignItems: 'center' }}
          columns={repayLoanTableColumns}
          dataSource={details.map((item) => ({
            policyNum: item.policyNum,
            OPLAmount: formatNumberWithComma(item.opl),
            APLAmount: formatNumberWithComma(item.apl)
          }))}
          onRowSelected={onTableRowSelected}
          onRowUnSelected={onTableRowUnSelected}
          onAllRowSelected={onSelectedAllTableRow}
          onAllRowUnSelected={onUnSelectedAllTableRow}
        />

        <Column>
          {isShowErrorMS050196 && <Error message={t('message:MS050196')} style={{ marginTop: 8 }} />}
          {isShowErrorMS050159 && <Error message={t('message:MS050159')} style={{ marginTop: 8 }} />}
        </Column>

        <Title title={t('submission:POLICY_OF_OTHER_CLIENT')} wrapperStyle={styles.bigLabel} />
        {POCFormList.fields.map(renderPOCForm)}
        <IconButton
          style={styles.btnAdd}
          title={t('common:Add')}
          icon={() => <assets.PlusCircleIcon />}
          onPress={() => POCFormList.append({ policyNumber: '' }, { shouldFocus: true })}
        />
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },

  bigLabel: {
    textTransform: 'uppercase',
    marginTop: 20,
    marginBottom: 10
  },

  ortherPolicyContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 5,
    paddingHorizontal: 30,
    paddingVertical: 24
  },

  searchPolicyContainer: {
    width: 275,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  btnSearch: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnAdd: {
    marginStart: 20,
    marginTop: 16
  }
})
