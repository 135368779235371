import { Paper } from '@material-ui/core'
import {
  DatePicker,
  Form,
  NbuwService,
  SectionHeader,
  Table,
  TableFooterPagination,
  TableStatus,
  Button,
  assets
} from '@pulseops/business/core'
import { ErrorHandling, form2, jsFileDownload, PulseOpsFormat } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../contract-detail-context'
import { SC } from '../sc'
import { ReportForm } from './report-form'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { ZIO } from '@mxt/zio'
import { ReportECardC } from 'libs/business/core/src/lib/nbuw/model/report-ecard'
import * as ExcelJS from 'exceljs'
import _ from 'lodash'
import moment from 'moment'

const excelColumns: { name: string; value: string; width?: number }[] = [
  {
    name: 'Người đại diện liên hệ',
    value: 'policyAdminName',
    width: 20
  },
  {
    name: 'Điện thoại (của người đại diện liên hệ)',
    value: 'phoneNumber'
  },
  {
    name: 'Địa chỉ liên lạc (của Công ty/Tổ chức)',
    value: 'contractAddress'
  },
  {
    name: 'Chương trình BH',
    value: 'program'
  },
  {
    name: 'Tên Công ty/Tổ chức',
    value: 'policyHolder'
  },
  {
    name: 'Họ tên Người lao động',
    value: 'employeeName'
  },
  {
    name: 'Số GCNBH',
    value: 'certificateNum'
  },
  {
    name: 'Số hợp đồng',
    value: 'masterContractNo'
  },
  {
    name: 'Năm hợp đồng',
    value: 'policyYear'
  },
  {
    name: 'Ngày hiệu lực HĐ của NĐBH',
    value: 'effectiveDate'
  }
]

export const Report = () => {
  const { t } = useTranslation('business')
  const { contractId } = React.useContext(ContractDetailContext)
  const service = NbuwService.forContract(contractId)
  const {
    base: {
      control,
      getValues,
      formState: { errors },
      watch
    }
  } = form2.useForm(ReportForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  })

  const [size, setSize] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [total, setTotal] = React.useState(0)
  const [reportList, setListReport] = React.useState<ReportECardC[]>([])
  const [loadingExport, setLoadingExport] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isToggleSearch, setIsToggleSearch] = React.useState(false)

  const formatDate = (date: Date | null) => {
    return date && moment(date).format('YYYY-MM-DD').replace(/\-/g, '')
  }

  const fromDate = watch('fromDate') ? formatDate(watch('fromDate')) : formatDate(new Date())
  const toDate = watch('toDate') ? formatDate(watch('toDate')) : formatDate(new Date())

  const hdSearch = () => {
    setLoading(true)
    setIsToggleSearch((prevState) => !prevState)
  }

  pipe(
    service.hdSearchECard({
      page: page,
      size: size,
      fromDate: fromDate,
      toDate: toDate,
      masterContractNo: contractId?.masterContractNo,
      policyYear: contractId?.policyYear
    }),
    ZIO.tap((res) => {
      setListReport(res?.data)
      setTotal(res.total)
      return ZIO.unit
    }),
    ZIO.tapBoth(
      () => ZIO.succeed(setLoading(false)),
      () => ZIO.succeed(setLoading(false))
    ),
    ErrorHandling.runDidUpdate([page, size, isToggleSearch])
  )

  const handleDownload = () => {
    setLoadingExport(true)
    return pipe(
      service.exportEcard({
        fromDate: formatDate(watch('fromDate')),
        toDate: formatDate(watch('toDate')),
        masterContractNo: contractId?.masterContractNo,
        policyYear: contractId?.policyYear
      }),
      ZIO.flatMap((list) =>
        ZIO.fromPromise(async () => {
          const wb = new ExcelJS.Workbook()
          const ws = wb.addWorksheet('REPORT ECARD')

          excelColumns.forEach((column, index) => {
            const headerCell = ws.getCell(1, index + 1)
            headerCell.value = column.name
            headerCell.alignment = {
              wrapText: true,
              horizontal: 'left',
              vertical: 'middle'
            }

            headerCell.worksheet.columns.forEach(function (column, i) {
              let maxLength = 0
              column['eachCell'] &&
                column['eachCell']({ includeEmpty: true }, function (cell) {
                  const columnLength = cell.value ? cell.value.toString().length : 10
                  if (columnLength > maxLength) {
                    maxLength = columnLength
                  }
                })
              column.width = maxLength < 10 ? 10 : maxLength
            })
          })
          const getValue = (key: string, value: any) => {
            switch (key) {
              case 'program':
                return 'PRU-GẮN KẾT'
              case 'effectiveDate':
                return getTime(value)
              default:
                if (!value) return ''
                return value
            }
          }

          list.forEach((report, i) => {
            excelColumns.forEach((column, j) => {
              const cell = ws.getCell(2 + i, j + 1)
              cell.value = getValue(column.value, _.get(report, column.value))
            })
          })

          const buffer = await wb.xlsx.writeBuffer({
            useStyles: true
          })

          jsFileDownload(buffer, `ReportEcard${moment(new Date()).format('DDMMyyyy_HHmm')}.xlsx`)
        })
      ),
      ZIO.tapBoth(
        () => ZIO.succeed(setLoadingExport(false)),
        () => ZIO.succeed(setLoadingExport(false))
      ),
      ErrorHandling.run()
    )
  }

  const getTime = (value: Date) => {
    return PulseOpsFormat.datetoFormat(value, 'DD/MM/yyyy')
  }

  return (
    <SC.Container>
      <SC.BorderContainer>
        <Form.Group>
          <SectionHeader>{t('ecard_report')}</SectionHeader>
          <Form.Row>
            <Form.Col>
              <Controller
                control={control}
                name="fromDate"
                render={({ field }) => (
                  <DatePicker
                    label={t('from_date')}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    maxDate={getValues('toDate')}
                    errorMessage={errors?.fromDate?.message}
                  />
                )}
              />
            </Form.Col>
            <Form.Col>
              <Controller
                control={control}
                name="toDate"
                render={({ field }) => (
                  <DatePicker
                    label={t('to_date')}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    minDate={getValues('fromDate')}
                    errorMessage={errors?.toDate?.message}
                  />
                )}
              />
            </Form.Col>
            <Form.Col>
              <WR.BottomRow>
                <Button
                  title={t('to_search')}
                  icon={
                    <assets.IconSearch
                      style={{
                        color: 'white'
                      }}
                    />
                  }
                  loading={loading}
                  onPress={hdSearch}
                />

                <Button
                  title={t('to_download')}
                  icon={<assets.IconDownloadWhite />}
                  onPress={handleDownload}
                  loading={loadingExport}
                />
              </WR.BottomRow>
            </Form.Col>
          </Form.Row>
        </Form.Group>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead align="center">STT</Table.CellHead>
                <Table.CellHead>{t('worker_fullname')}</Table.CellHead>
                <Table.CellHead>{t('cgnbh_no')}</Table.CellHead>
                <Table.CellHead>{t('policy_if_date_la')}</Table.CellHead>
                <Table.CellHead>{t('hc_plan')}</Table.CellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={5} rows={reportList} loading={loading} />
              {reportList?.map((row, i) => (
                <Table.Row>
                  <Table.Cell align="center">{i + 1}</Table.Cell>
                  <Table.Cell>{row.employeeName}</Table.Cell>
                  <Table.Cell>{row.certificateNum}</Table.Cell>
                  <Table.Cell>{PulseOpsFormat.datetoFormat(new Date(row.effectiveDate), 'DD/MM/yyyy')}</Table.Cell>
                  <Table.Cell>{row.hcPlan || '-'}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooterPagination
              total={total}
              size={size}
              page={page}
              onPageChange={setPage}
              onSizeChange={setSize}
              colspan={5}
            />
          </Table.Main>
        </Table.Container>
      </SC.BorderContainer>
    </SC.Container>
  )
}

const WR = {
  BottomRow: styled(View)`
    z-index: 1;
    position: sticky;
    bottom: 0;
    height: 68px;
    left: 0;
    right: 0;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 1rem;
  `
}
