import { useMobile } from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

export type DataSource = {
  label: string | number | boolean | JSX.Element | TFunctionResult
  value: string | number | boolean | JSX.Element | TFunctionResult
  suffix?: string | number | boolean | JSX.Element
  isHighlight?: boolean
  isHighlightWarning?: boolean
  isBold?: boolean
  viewStyle?: StyleProp<ViewStyle>
  style?: { colorText?: string }
  isRequired?: boolean
}

const InfoViewItem: React.FC<DataSource> = (props) => {
  const { label, value, suffix, isHighlight, isBold, viewStyle, isHighlightWarning, style, isRequired } = props
  const { isMobile } = useMobile()

  return (
    <View style={[isMobile ? styles.colFull : styles.col, viewStyle]}>
      <Text style={styles.label}>
        {label} {isRequired && <Text style={{ marginLeft: 6, color: 'rgb(237, 27, 46)' }}>*</Text>}
      </Text>
      <Text
        style={[
          styles.value,
          isHighlight && styles.highLight,
          isHighlightWarning && styles.highLightWarning,
          isBold && styles.bold,
          !isEmpty(style) && { color: style?.colorText }
        ]}
      >
        {value} {suffix}
      </Text>
    </View>
  )
}

export const InfoViewList = ({
  dataSource,
  containerStyle,
  itemStyle
}: {
  dataSource: DataSource[]
  containerStyle?: StyleProp<ViewStyle>
  itemStyle?: StyleProp<ViewStyle>
}) => {
  const { t } = useTranslation()
  let xhtml: JSX.Element[] = [
    <Text key={1} style={styles.noData}>
      {t('common:Nodata')}
    </Text>
  ]
  if (dataSource.length > 0) {
    xhtml = dataSource.map((data, i) => (
      <InfoViewItem
        key={i}
        label={data.label}
        value={data.value}
        suffix={data.suffix}
        isHighlight={data.isHighlight}
        isHighlightWarning={data.isHighlightWarning}
        isBold={data.isBold}
        viewStyle={itemStyle}
        style={data?.style ?? {}}
        isRequired={data?.isRequired}
      />
    ))
  }

  return <View style={[styles.row, containerStyle]}>{xhtml}</View>
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  colFull: {
    width: '100%',
    marginBottom: 16
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  noData: { textAlign: 'center', width: '100%' },
  highLight: { color: '#eD1B2E' },
  highLightWarning: { color: '#FF6F00' },
  bold: { fontWeight: 'bold' }
})
