import React from 'react'
import { View } from 'react-native'
import {
  OBContainer,
  OBFieldDescription,
  OBFieldTitle,
  OBSectionCol,
  OBSectionContent,
  OBSectionRow,
  OBSharedStyles,
  OBTitle
} from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { CallOutDetailContext, OBAgentTable } from '../ob-common'
import { pipe } from 'fp-ts/lib/function'
import { OBGeneralService, OBAgentData } from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { Container } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'

export const OBAgent = () => {
  const { t } = useTranslation()
  const displayedColums = [
    t('Outbound:OBAgent:Agent'),
    t('Outbound:OBAgent:MobilePhone'),
    t('Outbound:OBAgent:From'),
    t('Outbound:OBAgent:To')
  ]
  const [loading, bindLoader] = useLoading(false)
  const [dataAgent, setDataAgent] = React.useState<OBAgentData | null>(null)
  const [agentCode, setAgentCode] = React.useState<string>('')
  const { policyNumber } = React.useContext(CallOutDetailContext)
  const space = ' '
  React.useEffect(() => {
    pipe(
      OBGeneralService.getAgent(policyNumber),
      ZIO.map((data) => {
        setDataAgent(data)
        if (data.staffs) {
          const findCode = data.staffs.find((item) => item.role === 'SERVICING_AGENT')?.code ?? ''
          setAgentCode(findCode)
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [policyNumber])

  const getTilteOfRole = (role: string, code?: string) => {
    switch (role) {
      case 'SERVICING_AGENT':
        return t('Outbound:OBAgent:ServicingAgent')
      case 'SHARING_AGENT':
        return t('Outbound:OBAgent:SharingAgent')
      case 'UNIT_MANAGER':
        return t('Outbound:OBAgent:UnitManager')
      case 'ADMIN_PERSON': {
        if (code && code.startsWith('60')) return t('Outbound:OBAgent:AdminPerson_60')
        else if (code && code.startsWith('69')) return t('Outbound:OBAgent:AdminPerson_69')
        return ''
      }
      case 'BRANCH_MANAGER':
        return t('Outbound:OBAgent:BranchManager')
      case 'HEAD_TEAM': {
        if (code && code.startsWith('60')) return t('Outbound:OBAgent:HeadTeam_60')
        else if (code && code.startsWith('69')) return t('Outbound:OBAgent:HeadTeam_69')
        return ''
      }
      case 'BUSINESS_DEVELOPMENT_MANAGER':
        return t('Outbound:OBAgent:BusinessDevelopmentManager')
      case 'SUB_ZONE_HEAD': {
        if (code && code.startsWith('60')) return t('Outbound:OBAgent:SubZoneHead_60')
        else if (code && code.startsWith('69')) return t('Outbound:OBAgent:SubZoneHead_69')
        return ''
      }
      default:
        return ''
    }
  }

  const checkRowWidthByRole = (role: string) => {
    return role === 'ADMIN_PERSON' || role === 'HEAD_TEAM' || role === 'SUB_ZONE_HEAD'
  }

  return (
    <Container loading={loading}>
      <View style={OBSharedStyles.policyInfoContainer}>
        <OBContainer containerTitle={t('Outbound:OBAgent:ServicingAgentInfo')}>
          <View style={OBSharedStyles.containerPadding}>
            <OBSectionRow>
              {dataAgent?.staffs &&
                dataAgent?.staffs.map((staff) => {
                  return staff.role === 'SERVICING_AGENT' || staff.role === 'SHARING_AGENT' ? (
                    <>
                      <OBSectionCol style={{ marginBottom: 16 }}>
                        <OBFieldTitle text={staff.role ? getTilteOfRole(staff.role) : '-'}></OBFieldTitle>
                        <OBFieldDescription
                          text={(staff?.code ? staff.code + space : '') + staff?.fullName || '-'}
                        ></OBFieldDescription>
                      </OBSectionCol>
                      <OBSectionCol style={{ marginBottom: 16 }}>
                        <OBFieldTitle text={t('Outbound:OBAgent:MobilePhone')}></OBFieldTitle>
                        <OBFieldDescription text={staff?.mobilePhone || '-'}></OBFieldDescription>
                      </OBSectionCol>
                      {staff.role === 'SERVICING_AGENT' && (
                        <OBSectionCol style={{ marginBottom: 16 }}>
                          <OBFieldTitle text={t('Outbound:OBAgent:DateTerminateServicingAgent')}></OBFieldTitle>
                          <OBFieldDescription
                            text={
                              moment(staff.terminateDate, 'YYYY-MM-DD').isValid()
                                ? moment(staff.terminateDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                : '-'
                            }
                          ></OBFieldDescription>
                        </OBSectionCol>
                      )}
                      {staff.role === 'SHARING_AGENT' && (
                        <OBSectionCol style={{ marginBottom: 16 }}>
                          <OBFieldTitle text={t('Outbound:OBAgent:DateTerminateSharingAgent')}></OBFieldTitle>
                          <OBFieldDescription
                            text={
                              moment(staff.terminateDate, 'YYYY-MM-DD').isValid()
                                ? moment(staff.terminateDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                : '-'
                            }
                          ></OBFieldDescription>
                        </OBSectionCol>
                      )}
                    </>
                  ) : (
                    <>
                      <OBSectionCol
                        style={{
                          marginBottom: 16,
                          maxWidth: staff.role && checkRowWidthByRole(staff.role) ? '33.33%' : '33.33%'
                        }}
                      >
                        {staff.role && getTilteOfRole(staff.role, agentCode) ? (
                          <View>
                            <OBFieldTitle text={getTilteOfRole(staff.role, agentCode)}></OBFieldTitle>
                            <OBFieldDescription
                              text={(staff?.code ? staff.code + space : '') + staff?.fullName || '-'}
                            ></OBFieldDescription>
                          </View>
                        ) : (
                          <View></View>
                        )}
                      </OBSectionCol>
                      {staff.role === 'ADMIN_PERSON' && (
                        <OBSectionCol style={{ marginBottom: 16 }}>
                          <OBFieldTitle text={t('Outbound:OBAgent:OfficeCode')}></OBFieldTitle>
                          <OBFieldDescription
                            text={(dataAgent.officeCode ?? '-') + space + (dataAgent.officeName ?? '-')}
                          ></OBFieldDescription>
                        </OBSectionCol>
                      )}
                      {staff.role === 'HEAD_TEAM' && (
                        <OBSectionCol style={{ marginBottom: 16 }}>
                          <OBFieldTitle text={t('Outbound:OBAgent:GAOffice')}></OBFieldTitle>
                          <OBFieldDescription
                            text={(dataAgent.gaCode ?? '-') + space + (dataAgent.gaName ?? '-')}
                          ></OBFieldDescription>
                        </OBSectionCol>
                      )}
                    </>
                  )
                })}
            </OBSectionRow>
          </View>
        </OBContainer>
        <OBContainer containerTitle={t('Outbound:OBAgent:OldAgent')}>
          <View style={OBSharedStyles.containerPadding}>
            <OBAgentTable displayedColums={displayedColums} dataSource={dataAgent?.oldAgents ?? []}></OBAgentTable>
          </View>
        </OBContainer>
      </View>
    </Container>
  )
}
