import React from 'react'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Input, TransactionType } from '@pulseops/common'
import { Controller, useForm } from 'react-hook-form'
import { PolicyServiceProps } from './policy-service-props'
import { ConfirmationLetterData } from '@pulseops/common'
import { RequestAuthenticateData } from '../request-authen'

export interface ConfirmationLetterFormData {
  reason: string
}

export const ConfirmationLetterOfPolicy = (props: PolicyServiceProps<ConfirmationLetterData.SubmitData>) => {
  const { t } = useTranslation()
  const ConfirmationLetterForm = useForm<ConfirmationLetterFormData>({ defaultValues: { reason: '' } })
  const { control, watch } = ConfirmationLetterForm

  props.initSubmission({
    validate: async () => {
      const isValidForm = await ConfirmationLetterForm.trigger()
      if (isValidForm) {
        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/confirmation-letter/account-and-surrender-value`,
          body: {
            reason: watch('reason') ?? ''
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(
            TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE
          ),
          collerationId: props.policyNumber ?? '',
          transaction: TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE
        }
      } else {
        return false
      }
    },
    clear: () => {
      // setValue('reason', '')
      ConfirmationLetterForm.reset()
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Text style={confirmStyle.headerField}>{t('requestInfo:LetterRequest')}</Text>
      <View style={confirmStyle.sectionContent}>
        <View style={confirmStyle.sectionRow}>
          <View style={confirmStyle.col_8}>
            <Controller
              name="reason"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('common:Reason') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('common:Reason')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={value ? '' : error?.message}
                  disabled={props.isConfirmed}
                  maxLength={500}
                />
              )}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}
const confirmStyle = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  col_8: {
    width: '100%',
    maxWidth: '66.6666666667%',
    paddingRight: 15,
    paddingLeft: 15
  }
})
