/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { DaInforPlicyModal } from '../component'
import { TaskDetail } from '@pulseops/common'
type Props = {
  detail: TaskDetail.AgentVerificationNew
}
export interface serviceAgents {
  agentCode: string | null | undefined
  agentGivName: string | null | undefined
  agentName: string | null | undefined
  agentSurName: string | null | undefined
  appointedDate: string | null | undefined
  clientNum: string | null | undefined
  regionCode: string | null | undefined
  regionDescription: string | null | undefined
  salezone: string | null | undefined
}

export const DAPolicyInformationScreen = (props: Props) => {
  const { t } = useTranslation('RequestInfo')
  const [inforPolicy, setInforPolicy] = React.useState<boolean>(false)
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.bodyInfo}>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('Policy')}</Text> <Text>{props.detail.transferPolicy.policyNum}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('PolicyOwner')}</Text>{' '}
            <Text>{props.detail.transferPolicy.cownName}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('Product')}</Text> <Text>{props.detail.transferPolicy.cnttype}</Text>
          </View>
        </View>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('Status')}</Text> <Text>{props.detail.transferPolicy.statcode}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('IssueDate')}</Text> <Text>{props.detail.transferPolicy.issueDate}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('PolicyYear')}</Text>{' '}
            <Text>{props.detail.transferPolicy.policyYear}</Text>
          </View>
        </View>
        {!!props.detail.serviceAgents && props.detail.serviceAgents.length > 0 && (
          <View style={styles.bodyInfoHeader}>
            <View style={styles.bodyPolyView}>
              <Text style={styles.bodyPolicy}>{t('AgentNumber1')}</Text>{' '}
              <Text>{!!props.detail.serviceAgents[0].agentCode && props.detail.serviceAgents[0].agentCode}</Text>
            </View>
            <View style={styles.bodyPolyView}>
              <Text style={styles.bodyPolicy}>{t('AgentName1')}</Text>{' '}
              <Text>{!!props.detail.serviceAgents[0].agentName && props.detail.serviceAgents[0].agentName}</Text>
            </View>
            <View style={styles.bodyPolyView}>
              <Text style={styles.bodyPolicy}>{t('TerminateDate1')}</Text>{' '}
              <Text>
                {!!props.detail.serviceAgents[0].appointedDate && props.detail.serviceAgents[0].appointedDate}
              </Text>
            </View>
          </View>
        )}

        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('BDMName1')}</Text> <Text>{}</Text>
          </View>
        </View>
        {!!props.detail.serviceAgents && props.detail.serviceAgents.length > 0 && (
          <View style={styles.bodyInfoHeader}>
            <View style={styles.bodyPolyView}>
              <Text style={styles.bodyPolicy}>{t('AgentNumber2')}</Text>{' '}
              <Text>{props.detail.serviceAgents[1]?.agentCode ?? '-'}</Text>
            </View>
            <View style={styles.bodyPolyView}>
              <Text style={styles.bodyPolicy}>{t('AgentName2')}</Text>{' '}
              <Text>{props.detail.serviceAgents[1]?.agentName ?? '-'}</Text>
            </View>
            <View style={styles.bodyPolyView}>
              <Text style={styles.bodyPolicy}>{t('TerminateDate2')}</Text>{' '}
              <Text>{props.detail.serviceAgents[1]?.appointedDate ?? '-'}</Text>
            </View>
          </View>
        )}
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('BDMName2')}</Text> <Text>{}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('Salezone')}</Text> <Text>{'-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('TransferHistory')}</Text>
            <Text onPress={() => setInforPolicy(true)} style={{ color: 'red', textDecorationLine: 'underline' }}>
              {t('ViewInfo')}
            </Text>
          </View>
        </View>
      </View>
      <DaInforPlicyModal
        detail={props.detail}
        visible={inforPolicy}
        onPressClose={() => setInforPolicy(false)}
        onPressSubmit={() => {
          setInforPolicy(false)
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  bodyInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  bodyInfoHeader: {
    flexDirection: 'row',
    paddingVertical: 20
  },
  bodyPolyView: {
    flex: 1
  },
  bodyPolicy: {
    fontSize: 15,
    paddingVertical: 6,
    color: '#70777E',
    fontWeight: 'bold'
  }
})
