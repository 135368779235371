import { pipe } from 'fp-ts/lib/function'
import { ReinstatementService } from './ReinstatementService'
import { ZIO, Task } from '@mxt/zio'
import { GeneralService } from './GeneralService'
import { LoanService } from './LoanService'
import { SubmissionService } from './SubmissionService'
import { SUFullSurrenderReversalData } from './model'

export namespace FullSurrenderReversalService {
  export const getDetail = (policyNum: string): Task<SUFullSurrenderReversalData.DetailData> =>
    pipe(
      ZIO.zipPar(
        LoanService.getPolicyLoanEnquiry(policyNum),
        SubmissionService.getAccountBalance(policyNum, 'T512', [{ sacsCode: 'LP', sacsType: 'PS' }]),
        SubmissionService.getAccountBalance(policyNum, 'TV9U', [
          { sacsCode: 'LP', sacsType: 'EO' },
          { sacsCode: 'LN', sacsType: 'EI' }
        ]),
        SubmissionService.getPolicy(policyNum),
        ReinstatementService.getReinData(policyNum),
        GeneralService.getOccupations
      ),
      ZIO.map(([loanEnquiry, accountBalance1, accountBalance2, policyInfo, reinData, occupationList]) => {
        const balanceAPLOPL = Number(loanEnquiry.balanceAPL) + Number(loanEnquiry.balanceOPL)
        const interestAPLOPL = Number(loanEnquiry.interestAPL) + Number(loanEnquiry.interestOPL)
        const policyLoans = balanceAPLOPL + interestAPLOPL
        const surrenderValue = accountBalance1.body.accountGroups?.reduce((total, next) => {
          return next.sacsType === 'PS' && next.amount ? total + Number(next.amount) : total
        }, 0)
        const totalPaidCashBenefits = accountBalance2.body.accountGroups?.reduce((total, next) => {
          return ['EO', 'EI'].includes(next.sacsType) ? total + Number(next.amount) : total
        }, 0)
        const filterOccupationList = occupationList.map((item) => ({ ...item, id: item.id ?? '' }))
        return {
          policyLoans: policyLoans,
          surrenderValue: surrenderValue ?? 0,
          ptdateAdvance: policyInfo.body.paidToDateAdvance ?? '',
          billFreq: policyInfo.body.billFreq ?? '',
          basicPrem: policyInfo.body.attributesExt?.basicPremium ?? 0,
          riderPrem: policyInfo.body.attributesExt?.riderPremium ?? 0,
          totalPaidCashBenefits: totalPaidCashBenefits ?? 0,
          reinData,
          occupationList: filterOccupationList
        }
      })
    )

  export const getAccessData = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        SubmissionService.getAccountBalance(policyNum, 'T512TOTAL', [{ sacsCode: 'LP', sacsType: 'PS' }])
      ),
      ZIO.map(([policyInfo, accountBalanceInfo]) => {
        const accountBalance = accountBalanceInfo.body.accountGroups?.reduce((total, next) => {
          return next.sacsType === 'PS' && !!next.amount ? total + Number(next.amount) : total
        }, 0)
        return {
          contractStatus: policyInfo.body.status ?? '',
          surrenderDate: policyInfo.body.surrenderDate,
          accountBalance: accountBalance ?? 0
        }
      })
    )
}
