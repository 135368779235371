import { TablePagination } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  assets,
  ControlProps,
  Divider,
  ErrorHandling,
  IHeatDataService,
  IHeatData,
  ModalComponent,
  IHeatDataDetail,
  AppContext
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React, { useState, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  StyleProp,
  ViewStyle,
  ScrollView,
  useWindowDimensions
} from 'react-native'
import styled from 'styled-components/native'
import _ from 'lodash'
import * as O from 'fp-ts/Option'
import { TableIheat } from './table-iheat'
import { TableIHeatDetail, Columns as DetailColumn } from './table-iheat-detail'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import { StackScreenProps } from '@react-navigation/stack'
import { UtilitiesStackParamList } from '../utilites-stack-params-list'
type IheatForm = {
  policy: string
  clientNumber: string
}

type InputProps = ControlProps<string> & {
  containerStyle?: StyleProp<ViewStyle>
  inputContainer?: StyleProp<ViewStyle>
  suffixIcon?: () => JSX.Element
  prefixIcon?: () => JSX.Element
  label?: string
  errorString?: string
  disabled?: boolean
  disabledSearch?: boolean
  required?: boolean
  errorMessage?: string
  numberOnly?: boolean
  onIconPressed?: () => void
  placeholder?: string
  maxLength?: number
}

type keyType = string | number | JSX.Element

export type DataSource<T = keyType> = {
  [key: string]: T
}

type RowProps = {
  title: string
  name: string
  builder?: (data: IHeatData) => JSX.Element
  cellStyle?: StyleProp<ViewStyle>
  headerCellStyle?: StyleProp<ViewStyle>
}

const Input: React.FC<InputProps> = ({
  label,
  errorMessage,
  onChange,
  onBlur,
  value,
  placeholder,
  numberOnly,
  disabled,
  maxLength,
  suffixIcon
}) => {
  return (
    <View style={{ flex: 1, alignSelf: 'flex-start' }}>
      {label && <Text style={{ fontWeight: 'bold', color: '#70777E' }}>{label}</Text>}
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          borderBottomWidth: 1,
          paddingVertical: 5,
          borderBottomColor: errorMessage ? 'red' : undefined
        }}
      >
        <TextInput
          onChangeText={(value) => onChange?.call(null, value)}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          style={{ flex: 1 }}
          maxLength={maxLength}
        />
        {suffixIcon && suffixIcon()}
      </View>
      {errorMessage && (
        <View>
          <Text style={styles.errorText}>{errorMessage}</Text>
        </View>
      )}
    </View>
  )
}

const TextButton: React.FC<{ title: string; onPress: () => void }> = ({ title, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <SC.TextUnderline>{title}</SC.TextUnderline>
    </TouchableOpacity>
  )
}

type Props = StackScreenProps<UtilitiesStackParamList, 'InquiryIheatScreen'>

export const InquiryIHeatScreen: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { showGlobalLoading, showToast, changeBreadcrumb } = useContext(AppContext.AppContextInstance)
  const [loading, bindLoader] = useLoading(false)
  const [dataSource, setDataSource] = useState<IHeatData[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [dataDetailSource, setIheatDetail] = useState<IHeatDataDetail[]>([])
  const [detailVisible, setDetailVisible] = useState<boolean>(false)
  const [infoVisible, setInfoVisible] = useState<boolean>(false)
  const [info, setInfo] = useState<{ title: string; content: string }>({ title: '', content: '' })
  const { height } = useWindowDimensions()
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors
    //reset
  } = useForm<IheatForm>({
    defaultValues: {
      policy: '',
      clientNumber: props.route?.params?.clientNumber ? props.route.params.clientNumber : ''
    }
  })

  const { policy, clientNumber } = watch()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Utilities'),
          navigate: () => {
            navigate('UtilitiesStack', { screen: 'Utilities' })
          }
        },
        { title: t('utilities:iHeatdata'), navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    if (props.route?.params?.clientNumber) {
      setPage(0)
      searchByClient(rowsPerPage, 0)
    }
  }, [props.route?.params?.clientNumber])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const columns: RowProps[] = [
    {
      title: t('utilities:callId'),
      name: 'callId',
      builder: (data) => {
        const callId = _.get(data, 'callId') || ''
        return <TextButton title={callId} onPress={() => showDataDetail(callId)} />
      }
    },
    {
      title: t('utilities:policyNo'),
      name: 'policyNo'
    },
    {
      title: t('utilities:clientNum'),
      name: 'clientNum'
    },
    {
      title: t('utilities:policyOwner'),
      name: 'policyOwner'
    },
    {
      title: t('utilities:callCategory'),
      name: 'callCategory'
    },
    {
      title: t('utilities:callType'),
      name: 'callType'
    },
    {
      title: t('utilities:subType'),
      name: 'subType'
    },
    {
      title: t('utilities:callSource'),
      name: 'callSource'
    },
    {
      title: t('utilities:ref'),
      name: 'ref'
    },
    {
      title: t('utilities:csCenter'),
      name: 'csCenter'
    },
    {
      title: t('utilities:receivedFrom'),
      name: 'receivedFrom'
    },
    {
      title: t('utilities:location'),
      name: 'location'
    },
    {
      title: t('utilities:callDesc'),
      name: 'callDesc',
      builder: (data) => {
        const content = _.get(data, 'callDesc') || ''
        return (
          <TextButton
            title={t('utilities:view')}
            onPress={() => {
              setInfo({ title: t('utilities:callDesc'), content })
              setInfoVisible(true)
            }}
          />
        )
      }
    },
    {
      title: t('utilities:closeDesc'),
      name: 'closeDesc',
      builder: (data) => {
        const content = _.get(data, 'closeDesc') || ''
        return (
          <TextButton
            title={t('utilities:view')}
            onPress={() => {
              setInfo({ title: t('utilities:closeDesc'), content })
              setInfoVisible(true)
            }}
          />
        )
      }
    },
    {
      title: t('utilities:subject'),
      name: 'subject',
      builder: (data) => {
        const content = _.get(data, 'subject') || ''
        return (
          <TextButton
            title={t('utilities:view')}
            onPress={() => {
              setInfo({ title: t('utilities:subject'), content })
              setInfoVisible(true)
            }}
          />
        )
      }
    },
    {
      title: t('utilities:recvdDate'),
      name: 'recvdDate'
    },
    {
      title: t('utilities:closedDate'),
      name: 'closedDate'
    },
    {
      title: t('utilities:closedBy'),
      name: 'closedBy'
    },
    {
      title: t('utilities:closureType'),
      name: 'closureType'
    },
    {
      title: t('utilities:complaintsAgainst'),
      name: 'complaintsAgainst'
    },
    {
      title: t('utilities:personComplaint'),
      name: 'personComplaint'
    },
    {
      title: t('utilities:fullName'),
      name: 'fullName'
    },
    {
      title: t('utilities:receipt'),
      name: 'receipt'
    },
    {
      title: t('utilities:correctiveAction'),
      name: 'correctiveAction'
    },
    {
      title: t('utilities:complaintAmount'),
      name: 'complaintAmount'
    },
    {
      title: t('utilities:verifyAmount'),
      name: 'verifyAmount'
    },
    {
      title: t('utilities:deductedAmountUM'),
      name: 'deductedAmountUM'
    },
    {
      title: t('utilities:costSerRecovery'),
      name: 'costSerRecovery'
    },
    {
      title: t('utilities:recoveredAmount'),
      name: 'recoveredAmount'
    },
    {
      title: t('utilities:deductedAmount'),
      name: 'deductedAmount'
    },
    {
      title: t('utilities:refNo'),
      name: 'refNo'
    },
    {
      title: t('utilities:response'),
      name: 'response'
    },
    {
      title: t('utilities:reimbursement'),
      name: 'reimbursement'
    },
    {
      title: t('utilities:corrective'),
      name: 'corrective'
    },
    {
      title: t('utilities:achangePYear'),
      name: 'achangePYear'
    }
  ]

  const detailColums: DetailColumn[] = [
    {
      title: t('utilities:callId'),
      name: 'callId'
    },
    {
      title: t('utilities:entryDate'),
      name: 'entryDate',
      builder: (data) => {
        const entryDate = _.get(data, 'entryDate') || ''
        const entryTime = _.get(data, 'entryTime') || ''

        return <Text>{`${entryDate} ${entryTime}`}</Text>
      }
    },
    {
      title: t('utilities:journalType'),
      name: 'journalType'
    },
    {
      title: t('utilities:tracker'),
      name: 'tracker'
    },
    {
      title: t('utilities:entryText'),
      name: 'entryText',
      builder: (data) => {
        const content = _.get(data, 'entryText') || ''
        return (
          <TextButton
            title={t('utilities:view')}
            onPress={() => {
              setInfo({ title: t('utilities:entryText'), content })
              setInfoVisible(true)
            }}
          />
        )
      }
    }
  ]
  const getIHeadData = (pageSize: number, pageNum: number, policy?: string, clientNum?: string) =>
    pipe(
      IHeatDataService.getIHeatData({ policyNo: policy, clientNum: clientNum, page: pageNum, size: pageSize }),
      ZIO.flatMap((res) => {
        return pipe(
          O.fromNullable(res.data),
          O.filter((data) => data.length > 0),
          O.fold(
            () => {
              showToast(t('message:MS030029'), 'error')
              setTotalPage(0)
              setDataSource([])
              return ZIO.unit
            },
            (data) => {
              if (data.length > 0) {
                setTotalPage(res.total)
                setDataSource(data)
              }
              return ZIO.unit
            }
          )
        )
      }),
      ZIO.tapError((_) => {
        showToast(t('message:MS030029'), 'error')
        setTotalPage(0)
        setDataSource([])
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run({})
    )

  const searchByPolicy = (pageSize: number, pageNum: number = 0) => {
    if (/\b\d{8}\b/.test(policy)) {
      clearErrors()
      setValue('clientNumber', '')
      getIHeadData(pageSize, pageNum, policy)
    } else {
      setError('policy', { message: 'error' })
    }
  }

  const searchByClient = (pageSize: number, pageNum: number = 0) => {
    if (/\b\d{8}\b/.test(clientNumber)) {
      setValue('policy', '')
      getIHeadData(pageSize, pageNum, undefined, clientNumber)
    } else {
      setError('clientNumber', { message: 'error' })
    }
  }

  const update = (pageSize: number, pageNum: number) => {
    if (!!policy) {
      searchByPolicy(pageSize, pageNum)
    } else if (!!clientNumber) {
      searchByClient(pageSize, pageNum)
    }
  }

  const showDataDetail = (callId: string) =>
    pipe(
      IHeatDataService.getIHeatDataDetail({ callId }),
      ZIO.map((res) => {
        setIheatDetail(res.data)
        setDetailVisible(true)
      }),
      bindLoader,
      ErrorHandling.run({})
    )

  return (
    <ScrollView>
      <SC.Container>
        <SC.Header>
          <Controller
            control={control}
            name="policy"
            render={({ field }) => {
              return (
                <View style={{ flexDirection: 'row', flex: 1, alignSelf: 'flex-start' }}>
                  <Input
                    {...field}
                    label={t('common:PolicyNumber')}
                    placeholder={t('common:PolicyNumber')}
                    maxLength={8}
                    suffixIcon={() => (
                      <TouchableOpacity
                        onPress={handleSubmit((validated) => {
                          setPage(0)
                          searchByPolicy(rowsPerPage, 0)
                        })}
                      >
                        <assets.Search20Icon />
                      </TouchableOpacity>
                    )}
                    errorMessage={errors.policy ? t('message:MS990016') : undefined}
                  />
                </View>
              )
            }}
            rules={{ pattern: /\b\d{8}\b/ }}
          />

          <Divider width={30} />
          <Controller
            control={control}
            name="clientNumber"
            render={({ field }) => {
              return (
                <View style={{ flexDirection: 'row', flex: 1 }}>
                  <Input
                    {...field}
                    label={t('common:ClientNumber')}
                    placeholder={t('common:ClientNumber')}
                    maxLength={8}
                    suffixIcon={() => (
                      <TouchableOpacity
                        onPress={handleSubmit((validated) => {
                          setPage(0)
                          searchByClient(rowsPerPage, 0)
                        })}
                      >
                        <assets.Search20Icon />
                      </TouchableOpacity>
                    )}
                    errorMessage={errors.clientNumber ? t('message:MS990016') : undefined}
                  />
                </View>
              )
            }}
            rules={{ pattern: /\b\d{8}\b/ }}
          />
        </SC.Header>
        <Divider height={40} />
        <SC.Body>
          <TableIheat columns={columns} dataSource={dataSource} />
          <TablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            count={totalPage}
            rowsPerPageOptions={[5, 10, 20]}
            onPageChange={(e, page) => {
              setPage(page)
              update(rowsPerPage, page)
            }}
            onRowsPerPageChange={(e) => {
              // console.log('Row per page', e.target.value)
              setRowsPerPage(Number(e.target.value))
              update(Number(e.target.value), 0)
              setPage(0)
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </SC.Body>
        <ModalComponent
          title={t('utilities:iHeatdata')}
          visible={detailVisible}
          onClose={() => setDetailVisible(false)}
          actions={[
            {
              text: t('claim:Cancel'),
              type: 'outline',
              disabled: false,
              loading,
              onlyWide: false,
              style: { height: 39 },
              action: () => setDetailVisible(false)
            }
          ]}
        >
          <SC.ModalContainer>
            <TableIHeatDetail dataSource={dataDetailSource} columns={detailColums} />
          </SC.ModalContainer>
        </ModalComponent>
        <ModalComponent
          title={info.title}
          visible={infoVisible}
          onClose={() => setInfoVisible(false)}
          actions={[
            {
              text: t('claim:Cancel'),
              type: 'filled',
              disabled: false,
              loading,
              onlyWide: false,
              style: { height: 39 },
              action: () => setInfoVisible(false)
            }
          ]}
        >
          <View style={{ marginHorizontal: 20, maxHeight: height - 200 }}>
            <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('utilities:detail')}</Text>
            <View
              style={{
                minHeight: 80,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: 'grey',
                backgroundColor: '#fff',
                padding: 15,
                maxHeight: height / 2
              }}
            >
              <ScrollView>
                <Text>{info.content}</Text>
              </ScrollView>
            </View>
          </View>
        </ModalComponent>
      </SC.Container>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 20
  },
  header: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#FAFAFA',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingVertical: 24,
    paddingHorizontal: 30
  },
  errorText: {
    color: 'red'
  }
})

const SC = {
  Container: styled(View)`
    width: 100%;
    height: 100%;
    /* padding-right: 20;
    padding-left: 20;  */
    padding: 20px 20px 10px 20px;
  `,
  TextUnderline: styled(Text)`
    text-decoration: underline;
    font-weight: bold;
    color: #1ea5fc;
    text-decoration-color: #1ea5fc;
  `,
  ModalContainer: styled(View)`
    width: 100%;
    padding-top: 20;
    padding-right: 20;
    padding-left: 20;
  `,
  Header: styled(View)`
    border-radius: 8px;
    border-width: 1px;
    border-color: #d3dce6;
    background-color: #fafafa;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    align-self: center;
    padding: 24px 30px 24px 30px;
    justify-content: flex-start;
  `,
  Body: styled(View)`
    width: 70%;
    align-self: center;
  `,
  InputContainer: styled(View)`
    flex: 1;
    flex-direction: row;
  `
}
