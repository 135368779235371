import * as React from 'react'
import {
  ModalComponent,
  HeaderTable,
  Title,
  PayoutPopup,
  formatNumberWithComma,
  FieldList,
  assets,
  form2,
  Input,
  Divider,
  PayoutService,
  ModalComponentRef,
  ModalAction,
  Columns,
  DataSource,
  FrequencyMapping,
  RowSelectedProps,
  InputTable,
  SelectSearch,
  Relationship,
  getLanguage
} from '@pulseops/common'
import {
  useWindowDimensions,
  View,
  ScrollView,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  StyleSheet
} from 'react-native'
import { PolicyOfOrtherClientForm } from './policy-of-orther-client.form'
import { Controller, useFieldArray } from 'react-hook-form'
import { Label, Error } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { PayPremiumForm } from './pay-premium.form'

const MIN_AMOUNT = 10000000

interface IconButtonProps {
  title: string
  style?: StyleProp<ViewStyle>
  icon: () => JSX.Element
  onPress: () => void
}

const IconButton = ({ title, icon, style, onPress }: IconButtonProps) => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        {
          height: 32,
          alignItems: 'center',
          flexDirection: 'row',
          alignSelf: 'baseline'
        },
        style
      ]}
      onPress={onPress}
    >
      {icon()}
      <Divider width={12} />
      <Label title={title} fontWeight="600" color="#000000" />
    </TouchableOpacity>
  )
}

interface Props {
  primaryPolicy: string
  details: PayoutPopup.PayPremiumData[]
  visible: boolean
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData[]) => void
}

export const PayPremiumPopup = ({ visible, details, primaryPolicy, onClose, onConfirm }: Props) => {
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const [rowsSelected, setRowsSelected] = React.useState<Map<number, PayoutPopup.PayPremiumData>>(new Map())
  const [loadingMap, setLoadingMap] = React.useState<Map<number, boolean>>(new Map())

  const payPremiumForm = form2.useForm(PayPremiumForm.codec)

  const payPremiumFormList = useFieldArray<PayPremiumForm.Raw>({
    control: payPremiumForm.base.control,
    name: 'list'
  })

  const POCForm = form2.useForm(PolicyOfOrtherClientForm.codec, { defaultValues: { list: [] } })

  const POCFormList = useFieldArray<PolicyOfOrtherClientForm.Raw>({
    control: POCForm.base.control,
    name: 'list'
  })

  const { list } = POCForm.base.watch()

  const showSearchLoadingAtIndex = (index: number, isShow: boolean) => {
    const map = new Map(loadingMap)
    map.set(index, isShow)
    setLoadingMap(map)
  }

  const searchPolicy = (policyNum: string, formIndex: number) => {
    if (policyNum === primaryPolicy) {
      modalRef?.current?.showToast(t('message:MS050110'), 'error')
      POCFormList.update(formIndex, { policyNumber: policyNum })
      return
    }

    showSearchLoadingAtIndex(formIndex, true)
    return pipe(
      PayoutService.searchPolicy(policyNum),
      ZIO.catchAll((error) => {
        showSearchLoadingAtIndex(formIndex, false)
        modalRef?.current?.showToast(error.source.message, 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap((response) => {
        showSearchLoadingAtIndex(formIndex, false)

        const isNotFound =
          response.paidToDate === '-' && response.totalPremium === 0 && response.installmentPremium === 0

        if (isNotFound) {
          modalRef?.current?.showToast(t('message:MS030029'), 'error')
          POCFormList.update(formIndex, { policyNumber: policyNum })
        } else {
          if (response.isMaturityAdvValid) {
            POCFormList.update(formIndex, {
              policyNumber: policyNum,
              poName: response.poName,
              finalPolicyNum: response.policyNum,
              totalPremium: `${response.totalPremium || ''}`,
              installmentPremium: `${response.installmentPremium || ''}`,
              billingFrequency: response.billingFrequency
            })
          } else {
            modalRef?.current?.showToast(t('message:MS050259'), 'error')
            POCFormList.update(formIndex, { policyNumber: policyNum })
          }
        }
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const resetForm = () => {
    rowsSelected.clear()
    payPremiumFormList.remove()
    POCFormList.remove()
  }

  const onPressClose = () => {
    resetForm()
    onClose()
  }

  const onPressConfirm = POCForm.handleSubmit((data) => {
    const payPremiumData: PayoutPopup.PayoutData[] = payPremiumForm.base.getValues('list').map((item) => {
      const index = Number(item.formId)
      const e = rowsSelected.get(index)
      return {
        method: PayoutPopup.PayoutMethods.PAYPREMIUM,
        amount: Number(item.amount),
        policyNum: e?.policyNum,
        poName: e?.poName,
        totalPremium: e?.totalPremium,
        payee: undefined,
        officeBankCode: undefined,
        officeType: undefined
      }
    })

    const otherPolicyArr: PayoutPopup.PayoutData[] = list
      .filter((e) => Number(e.amount) > 0)
      .map(
        (e): PayoutPopup.PayoutData => ({
          method: PayoutPopup.PayoutMethods.PAYPREMIUM,
          poName: e.poName || '-',
          policyNum: e.finalPolicyNum || '-',
          amount: Number(e.amount),
          totalPremium: Number(e.totalPremium),
          payee: undefined,
          officeBankCode: undefined,
          officeType: undefined
        })
      )

    onConfirm([...payPremiumData, ...otherPolicyArr])
    resetForm()
    otherPolicyArr.length = 0
  })

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled: (() => {
        const lA = payPremiumFormList.fields.length
        const lB = POCFormList.fields.length

        const isAValid =
          payPremiumForm.base.formState.isValid &&
          !payPremiumForm.base.getValues('list').find((item) => Number(item.amount) > Number(item.installmentPremium))

        const isBValid =
          POCForm.base.formState.isValid &&
          !POCForm.base.getValues('list').find((item) => Number(item.amount) > Number(item.installmentPremium))

        const isDisable = (lA === 0 && lB === 0) || isAValid === false || isBValid === false
        return isDisable
      })(),
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]

  const renderTableInputAmountController = ({ index, isSelected }: RowSelectedProps) => {
    if (isSelected) {
      const formIndex = payPremiumFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
      if (formIndex > -1) {
        return (
          <Controller
            control={payPremiumForm.base.control}
            name={`list.${formIndex}.amount`}
            render={({ field, fieldState: { error } }) => (
              <InputTable
                {...field}
                value={field.value || ''}
                inputType={'money'}
                onChange={(v) => {
                  field.onChange(v)
                  payPremiumForm.base.trigger()
                }}
                errorMessage={
                  Number(field.value || '') > details[index].installmentPremium ? t('message:MS050159') : error?.message
                }
              />
            )}
          />
        )
      }
    }
    return <View />
  }

  const payPremiumTableColumns: Columns[] = [
    {
      key: '0',
      name: 'policyNum',
      title: t('common:PolicyNumber')
    },
    {
      key: '1',
      name: 'billingFrequency',
      title: t('submission:BillingFrequency')
    },
    {
      key: '2',
      name: 'installmentPremium',
      title: t('Payout:InstallmentPremium')
    },
    {
      key: '3',
      name: 'amount',
      title: t('submission:AmountToBePaid'),
      require: true,
      render: renderTableInputAmountController
    }
  ]

  const onTableRowSelected = (index: number, _data: DataSource) => {
    const data = details[index]
    if (data.installmentPremium * Number(data.billingFrequency || 0) > MIN_AMOUNT) {
      const rows = new Map(rowsSelected)
      rows.set(index, data)
      setRowsSelected(rows)

      payPremiumFormList.append({
        formId: `${index}`,
        amount: '',
        installmentPremium: `${data.installmentPremium}`
      })
    }
  }

  const onTableRowUnSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.delete(index)
    setRowsSelected(rows)

    const targetIndex = payPremiumFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
    if (targetIndex > -1) {
      payPremiumFormList.remove(targetIndex)
    }
  }

  const onAllTableRowSelected = () => {
    const rows = details.reduce((result, item, index) => {
      if (item.installmentPremium * Number(item.billingFrequency || 0) > MIN_AMOUNT) {
        result.set(index, item)
        const isFormExists =
          payPremiumFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`) > -1
        if (!isFormExists) {
          payPremiumFormList.append({
            formId: `${index}`,
            amount: '',
            installmentPremium: `${item.installmentPremium}`
          })
        }
      }
      return result
    }, new Map<number, PayoutPopup.PayPremiumData>())
    setRowsSelected(rows)
  }

  const onAllTableRowUnSelected = () => {
    setRowsSelected(new Map())
    payPremiumFormList.remove()
  }

  const renderSearchIcon = (isEnable: boolean, isLoading: boolean) => {
    return isLoading ? (
      <ActivityIndicator color="red" size={24} />
    ) : isEnable ? (
      <assets.Search20Icon />
    ) : (
      <assets.SearchDisableIcon />
    )
  }

  const renderPOCPolicySearchInputController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.policyNumber`}
        render={({ field, fieldState: { error, invalid } }) => {
          const isValid = field.value && !invalid
          const isSearching = loadingMap.get(index) || false
          return (
            <View style={{ marginBottom: 22 }}>
              <View style={styles.searchPolicyContainer}>
                <Input
                  {...field}
                  title={t('common:PolicyNumber')}
                  required={true}
                  maxLength={8}
                  value={field.value || ''}
                  disabled={isSearching}
                  alwayShowUnderline={true}
                  onKeyEnter={() => field.value && searchPolicy(field.value, index)}
                />
                <TouchableOpacity
                  disabled={!isValid || isSearching}
                  onPress={() => field.value && searchPolicy(field.value, index)}
                  style={styles.btnSearch}
                >
                  {renderSearchIcon(isValid, isSearching)}
                </TouchableOpacity>
              </View>
              {error?.message && <Error message={error.message} />}
            </View>
          )
        }}
      />
    )
  }

  const renderRelationShipSelectSearchController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.relationship`}
        render={({ field, fieldState: { error } }) => (
          <View style={{ marginEnd: 30 }}>
            <SelectSearch
              {...field}
              hideLabel={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={Relationship.WithMainLifeAssured.map((item) => ({
                label: getLanguage() === 'en' ? item.desEn : item.desVi,
                value: item.code
              }))}
              errorMessage={error?.message}
            />
          </View>
        )}
      />
    )
  }

  const renderInputAmountController = (index: number) => {
    const data = list[index]
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.amount`}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            containerStyle={{ marginEnd: 30 }}
            value={field.value || ''}
            inputType="money"
            onChange={(v) => {
              field.onChange(v)
              POCForm.base.trigger()
            }}
            errorMessage={
              Number(field.value || 0) > Number(data.installmentPremium || 0) ? t('message:MS050159') : error?.message
            }
          />
        )}
      />
    )
  }

  const renderPOCFieldList = (index: number) => {
    const data = list[index]
    return (
      <FieldList
        dataSource={[
          {
            label: t('utilities:PolicyOwner'),
            value: data?.poName?.trim() || '-'
          },
          {
            label: t('requestInfo:RelationshipWithMainifeAssured'),
            required: true,
            render: () => renderRelationShipSelectSearchController(index)
          },
          {
            label: t('submission:BillingFrequency'),
            value: FrequencyMapping.get(t)(data.billingFrequency || undefined)
          },
          {
            label: t('Payout:InstallmentPremium'),
            value: `${formatNumberWithComma(data?.installmentPremium)} VND` || '-'
          },
          {
            label: t('submission:AmountToBePaid'),
            required: true,
            render: () => renderInputAmountController(index)
          }
        ]}
      />
    )
  }

  const renderPOCForm = (item: any, index: number) => {
    return (
      <View style={styles.ortherPolicyContainer} key={index}>
        {renderPOCPolicySearchInputController(index)}
        {renderPOCFieldList(index)}
        <IconButton
          title={t('common:Delete')}
          icon={() => <assets.DeleteBin />}
          onPress={() => POCFormList.remove(index)}
        />
      </View>
    )
  }

  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:payPremiumForProposal')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={width * 0.7}
      modalHeight={height * 0.9}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <Title title={t('submission:payPremiumForProposal')} wrapperStyle={styles.bigLabel} />
        <HeaderTable
          selectable={true}
          rowsSelected={rowsSelected}
          unit={'VND'}
          headerContainerStyle={{ backgroundColor: '#FAFAFA' }}
          rowStyle={{ backgroundColor: '#FAFAFA' }}
          selectBoxContainerStyle={{ marginEnd: 100, justifyContent: 'center', alignItems: 'center' }}
          columns={payPremiumTableColumns}
          dataSource={details.map((item) => ({
            policyNum: item.policyNum,
            billingFrequency: FrequencyMapping.get(t)(item.billingFrequency),
            installmentPremium: formatNumberWithComma(item.installmentPremium)
          }))}
          onAllRowSelected={onAllTableRowSelected}
          onAllRowUnSelected={onAllTableRowUnSelected}
          onRowSelected={onTableRowSelected}
          onRowUnSelected={onTableRowUnSelected}
        />
        <Title title={t('submission:POLICY_OF_OTHER_CLIENT')} wrapperStyle={styles.bigLabel} />
        {(list || []).map(renderPOCForm)}
        <IconButton
          style={styles.btnAdd}
          title={t('common:Add')}
          icon={() => <assets.PlusCircleIcon />}
          onPress={() => POCFormList.append({ policyNumber: '' }, { shouldFocus: true })}
        />
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },

  bigLabel: {
    textTransform: 'uppercase',
    marginTop: 20,
    marginBottom: 10
  },

  ortherPolicyContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 5,
    paddingHorizontal: 30,
    paddingVertical: 24
  },

  searchPolicyContainer: {
    width: 275,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  btnSearch: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnAdd: {
    marginStart: 20,
    marginTop: 16
  }
})
