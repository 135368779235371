import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
    CancelRiderService,
    convertToAmount,
    formatTextFromLasToVN,
    GeneralService,
    Panel,
    PulseOpsFormat,
    RadioButton,
    TableField,
    TaskDetail,
    useTranslator,
    RiderReinstatementService
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import { Title } from '../common'

type RiderInfo = {
    coverageCode: string,
    riskCommDate: string,
    lapsedDate: string,
    sumAssured: number,
    riderPremium: number,
    productName: string
}

type StatementOfInsurability = {
    laName: string
    newHeathState: boolean
    newOccupationState: boolean
    curOccupation: string
    newOccupation: string
    newActivityState: boolean
    newActivity: string
    newCompanyState: boolean
    newCompany: string
    riderList: RiderInfo[]
}

export const RiderReinstatement = ({ detail, policyNum }: { detail?: TaskDetail.VERIRiderRein, policyNum?: string }) => {
    const { t } = useTranslation(['requestInfo', 'frequency'])
    const [dataStatementOfInsurability, setDataStatementOfInsurability] = React.useState<StatementOfInsurability[]>([])
    const [reinsFee, setReinsFee] = React.useState<number>(0)
    const [loading, bindLoader] = useLoading(false)
    const { i18n } = useTranslation()
    const st = useTranslator('submission').t
    const columns = [
        {
            key: '1',
            title: st('Rider'),
            name: 'productName'
        },
        {
            key: '2',
            title: st('RiskCommDate'),
            name: 'riskCommDate'
        },
        {
            key: '3',
            title: st('LapsedDate'),
            name: 'lapsedDate'
        },
        {
            key: '4',
            title: st('SumAssured'),
            name: 'sumAssured'
        },
        {
            key: '5',
            title: st('InstallmentPremium'),
            name: 'riderPremium'
        }
    ]
    React.useEffect(() => {
        console.log(detail, "detailData")
        pipe(
            ZIO.zipPar(GeneralService.getOccupations, CancelRiderService.getRiderInfoList(), RiderReinstatementService.getReinStateFee(policyNum!)),
            ZIO.map(([occupations, riderInfoList, reinsFeeRider]) => {
                setReinsFee(reinsFeeRider ?? 0)
                const dataStatementOfInsurability = detail?.submissionData.lifeAssuredList.map((l) => ({
                    ...l,
                    curOccupation: occupations.find((o) => o.code === l.curOccupation)?.name ?? l.curOccupation ?? '-',
                    newOccupation: occupations.find((o) => o.code === l.newOccupation)?.name ?? l.newOccupation
                }))

                const newDataStatementOfInsurability = dataStatementOfInsurability?.map((item) => {
                    const riderListShow: RiderInfo[] = item.riderList.map((itemRider) => {
                        const riderFind = riderInfoList.filter((riderInfo) => itemRider.coverageCode === riderInfo.code)
                        let riderName = ""
                        if (riderFind[0])
                            riderName = i18n.language === "vi" ? riderFind[0].nameVi : riderFind[0].name
                        return {
                            ...itemRider,
                            riskCommDate: PulseOpsFormat.dateStringtoFormat(itemRider.riskCommDate, 'DD/MM/YYYY'),
                            lapsedDate: PulseOpsFormat.dateStringtoFormat(itemRider.lapsedDate, 'DD/MM/YYYY'),
                            productName: riderName
                        }
                    })
                    return (
                        {
                            laName: item.clientName,
                            newHeathState: item.healthStateChange,
                            newOccupationState: item.occupationChange,
                            curOccupation: item.curOccupation,
                            newOccupation: item.newOccupation || '',
                            newActivityState: item.activityChange,
                            newActivity: item.newActivity || '',
                            newCompanyState: item.companyChange,
                            newCompany: item.newCompany || '',
                            riderList: riderListShow || []
                        })
                }
                )

                setDataStatementOfInsurability(newDataStatementOfInsurability || [])
                return ZIO.unit
            }),
            bindLoader,
            ZIO.unsafeRun({})
        )
    }, [detail && i18n])

    const formatText = (text: string): string => {
        return formatTextFromLasToVN(text)
    }

    const notEmpty = (value: string): boolean => {
        return !!(value && value !== '' && value !== '-')
    }

    const renderLoading = () => {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="red" />
            </View>
        )
    }

    return loading ? (
        renderLoading()
    ) : (
        <View>
            <Title title={t('submission:RidersList').toUpperCase()} />
            <View>
                {dataStatementOfInsurability.map((item, index) => {
                    return (
                        <Panel isExand key={index} title={<Text style={{ marginTop: 16 }}>{item.laName}</Text>}>
                            <View>
                                <TableField columns={columns} dataSource={item.riderList} currency="VND" />
                            </View>
                        </Panel>)
                })}
            </View>
            <View style={{ marginVertical: 15, marginLeft: 10, marginBottom: 30 }}>
                <Text style={{ color: '#70777e', fontWeight: "bold", fontSize: 15 }}>{t('submission:EstimatedReinstateFee')}</Text>
                <Text style={{ color: '#EE1A2D', fontWeight: "bold", fontSize: 15 }}>{convertToAmount(reinsFee) + " VND"}</Text>
            </View>
            {dataStatementOfInsurability.length > 0 && (
                <>
                    <Title title={t('StatementOfInsurability')}/>
                    {dataStatementOfInsurability.map((item: StatementOfInsurability, index: number) => (
                        <Panel title={item.laName} key={index}>
                            <View style={styles.col}>
                                <Text style={styles.label}>{t('AnyChangesInStateOfHealth')}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <RadioButton label={t('Change')} selected={item.newHeathState} />
                                    <RadioButton label={t('NotChange')} selected={!item.newHeathState} />
                                </View>
                            </View>
                            <View style={styles.col}>
                                <Text style={styles.label}>{t('AnyChangesInOccupation')}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <RadioButton label={t('Change')} selected={item.newOccupationState} />
                                    <RadioButton label={t('NotChange')} selected={!item.newOccupationState} />
                                </View>
                            </View>
                            {item.newOccupationState &&
                                <View style={styles.col}>
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                                        <View style={{ marginRight: 30 }}>
                                            <Text style={styles.label}>{t('CurrentOccupation')}</Text>
                                            <Text style={styles.value}>{item.curOccupation}</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.label}>{t('NewOccupation')}</Text>
                                            <Text style={styles.value}>{item.newOccupation}</Text>
                                        </View>
                                    </View>
                                </View>
                            }
                            <View style={styles.col}>
                                <Text style={styles.label}>{t('AnyChangeInActivities')}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <RadioButton label={t('Change')} selected={item.newActivityState} />
                                    <RadioButton label={t('NotChange')} selected={!item.newActivityState} />
                                </View>
                            </View>
                            {item.newActivityState &&
                                <View style={styles.col}>
                                    <Text style={styles.label}>{t('NewActivity')}</Text>
                                    <Text style={styles.value}>{item.newActivity}</Text>
                                </View>
                            }
                            <View style={styles.col}>
                                <Text style={styles.label}>{t('submission:AnyChangesInInsurancePolicy/Requesting')}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <RadioButton label={t('Change')} selected={item.newCompanyState} />
                                    <RadioButton label={t('NotChange')} selected={!item.newCompanyState} />
                                </View>
                            </View>
                            {item.newCompanyState &&
                                <View style={styles.col}>
                                    <Text style={styles.label}>{t('CompanyName')}</Text>
                                    <Text style={styles.value}>{item.newCompany}</Text>
                                </View>
                            }
                        </Panel>
                    ))}
                </>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    label: {
        color: '#70777E',
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 20,
        marginBottom: 8
    },

    value: {
        color: '#000000',
        fontSize: 15,
        lineHeight: 22
    },

    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center'
    },
    col: {
        width: '100%',
        marginBottom: 15
    }
})
