import { enumC } from '@mxt/zio/codec'
import { SelectOption } from '@pulseops/common'

export enum ProposalStatusCode {
  Proposal = 'P',
  InForce = 'IF',
  Issuing = 'IS',
  Decline = 'DC',
  Postpone = 'PP',
  Withdraw = 'WD',
  AfterIssuing = 'PIS',
  AfterInForce = 'PIF'
}

export const ProposalStatusCodeC = enumC(ProposalStatusCode)

export const ProposalStatusLabel = (status: ProposalStatusCode): string => {
  switch (status) {
    case ProposalStatusCode.Proposal:
      return 'HSYCBH mới'
    case ProposalStatusCode.InForce:
      return 'Phát hành'
    case ProposalStatusCode.Issuing:
      return 'Đang phát hành'
    case ProposalStatusCode.Decline:
      return 'Từ chối'
    case ProposalStatusCode.Postpone:
      return 'Tạm hoãn'
    case ProposalStatusCode.Withdraw:
      return 'Hủy HSYCBH'
    case ProposalStatusCode.AfterInForce:
      return 'Cập nhật sau phát hành'
    case ProposalStatusCode.AfterIssuing:
      return 'Đang cập nhật sau phát hành'
  }
}

export const ProposalStatusOptions: SelectOption[] = [
  {
    label: ProposalStatusLabel(ProposalStatusCode.Proposal),
    value: ProposalStatusCode.Proposal
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.InForce),
    value: ProposalStatusCode.InForce
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.Decline),
    value: ProposalStatusCode.Decline
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.Postpone),
    value: ProposalStatusCode.Postpone
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.Withdraw),
    value: ProposalStatusCode.Withdraw
  }
]

export const ProposalStatusInput: SelectOption[] = [
  {
    label: ProposalStatusLabel(ProposalStatusCode.InForce),
    value: ProposalStatusCode.InForce
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.Issuing),
    value: ProposalStatusCode.Issuing
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.AfterInForce),
    value: ProposalStatusCode.AfterInForce
  },
  {
    label: ProposalStatusLabel(ProposalStatusCode.AfterIssuing),
    value: ProposalStatusCode.AfterIssuing
  }
]
