import { StyleSheet, Text, View, ActivityIndicator, StyleProp, ViewStyle } from 'react-native'
import React from 'react'

interface Props {
  data: {
    label: string
    labelSuffixIcon?: JSX.Element
    value: string | JSX.Element
    color?: string
    colorLabel?: string
    onClick?: () => void
    hidden?: boolean
    widthItem?: string
  }[]
  col: number
  loading?: boolean
  containerStyle?: StyleProp<ViewStyle>
}

export const IBGridDataView = (props: Props) => {
  const { data, col, loading, containerStyle } = props
  return (
    <View style={[styles.container, containerStyle]}>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 15 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        data
          .filter((x) => !x.hidden)
          .map((item, index) => {
            return (
              <View
                style={{
                  width: item.widthItem ? item.widthItem : `${Math.floor(95 / col)}%`,
                  marginBottom: 30,
                  marginRight: 15
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text style={[styles.labelText, { color: item.colorLabel ? item.colorLabel : '#58647A' }]}>
                    {item.label}
                  </Text>
                  <View style={{ marginLeft: 5 }}>{item.labelSuffixIcon}</View>
                </View>
                {typeof item.value === 'string' ? (
                  <Text style={[styles.valueText, { color: item.color ? item.color : '#1C1D1F' }]}>{item.value}</Text>
                ) : (
                  <>{item.value}</>
                )}
              </View>
            )
          })
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingTop: 30,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#D3DCE6',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  labelText: {
    fontWeight: '700',
    color: '#58647A',
    marginBottom: 10,
    fontSize: 15
  },
  valueText: {
    fontWeight: '400',
    fontSize: 15
  }
})
