import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, ActivityIndicator, ScrollView, Pressable } from 'react-native'
import { IBGeneralTable, IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { IBService, ReceiptsDataList } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { ModalComponent, PulseOpsFormat } from '@pulseops/common'
import moment from 'moment'

interface Props {
  receiptsList: ReceiptsDataList[]
  setReceiptsList: (data: ReceiptsDataList[]) => void
  claimNumber: string
}

export type DataReceiptsTable = {
  receiptNumber: string | undefined | null,
  category: string | undefined | null,
  type: string | undefined | null,
  hospital: string | undefined | null,
  startDate: string | undefined | null,
  endDate: string | undefined | null,
  receiptExpense: number | undefined | null,
  receiptPayable: number | undefined | null
}

export type DataTableReceiptsDetail = {
  duration: number | undefined | null,
  occurence: number | undefined | null,
  diagnosis: string | undefined | null,
  procedureCode: string | undefined | null,
  expenseAmount: number | undefined | null,
  thirdPartyAmount: number | undefined | null,
  siAmount: number | undefined | null,
  claimAmount: number | undefined | null,
  excludedAmount: number | undefined | null,
  payableAmount: number | undefined | null
}

type ReceiptsInfoData = {
  numberOfReceipts: number,
  totalExpenseAmount: number,
  totalPayableAmount: number
}

export const IBReceipts = ({ receiptsList, setReceiptsList, claimNumber }: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)
  const [dataReceiptsTable, setDataReceiptsTable] = React.useState<DataReceiptsTable[]>([])
  const [dataTableDetail, setTableDetail] = React.useState<DataTableReceiptsDetail[]>([])
  const [isShowDetailPopup, setShowDetailPopup] = React.useState<boolean>(false)
  const [receiptsInfo, setReceiptsInfo] = React.useState<ReceiptsInfoData | null>(null)

  React.useEffect(() => {
    if(claimNumber && receiptsList.length === 0) {
      pipe(
        IBService.getReceiptsInfo(claimNumber),
        ZIO.map((res) => {
          res && setReceiptsList(res)
          res && setDataReceiptsTable(res.map((i) => ({
            receiptNumber: i.receiptNumber ?? '-',
            category: i.category ?? '-',
            type: i.type ?? '-',
            receiptExpense: i.receiptExpense ?? 0,
            receiptPayable: i.receiptPayable ?? 0,
            hospital: (i.hospital?.code ?? '') + ' ' + (i.hospital?.name ?? ''),
            startDate: i.startDate ? moment(i.startDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
            endDate: i.endDate ? moment(i.endDate, 'DD/MM/YYYY').format('YYYYMMDD') : ''
          })))
          let receipt = {
            numberOfReceipts: res?.length ?? 0,
            totalExpenseAmount: 0,
            totalPayableAmount: 0
          }
          res && res.map((i) => {
            receipt.totalExpenseAmount += (i.receiptExpense ?? 0)
            receipt.totalPayableAmount += (i.receiptPayable ?? 0)
          })
          setReceiptsInfo(receipt)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
    else {
      receiptsList && setDataReceiptsTable(receiptsList.map((i) => ({
        receiptNumber: i.receiptNumber ?? '-',
        category: i.category ?? '-',
        type: i.type ?? '-',
        receiptExpense: i.receiptExpense ?? 0,
        receiptPayable: i.receiptPayable ?? 0,
        hospital: (i.hospital?.code ?? '-') + ' ' + (i.hospital?.name ?? ''),
        startDate: i.startDate ? moment(i.startDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
        endDate: i.endDate ? moment(i.endDate, 'DD/MM/YYYY').format('YYYYMMDD') : ''
      })))
      let receipt = {
        numberOfReceipts: receiptsList?.length ?? 0,
        totalExpenseAmount: 0,
        totalPayableAmount: 0
      }
      receiptsList && receiptsList.map((i) => {
        receipt.totalExpenseAmount += (i.receiptExpense ?? 0)
        receipt.totalPayableAmount += (i.receiptPayable ?? 0)
      })
      setReceiptsInfo(receipt)
    }
  }, [claimNumber])

  const dataTable = [
    {
      label: t('CategoryReceipts'),
      field: 'category'
    },
    {
      label: t('Type'),
      field: 'type'
    },
    {
      label: t('Hospital'),
      field: 'hospital'
    },
    {
      label: t('StartDate'),
      field: 'startDate',
      format: 'date'
    },
    {
      label: t('EndDate'),
      field: 'endDate',
      format: 'date'
    },
    {
      label: t('ReceiptExpense'),
      field: 'receiptExpense',
      format: 'money'
    },
    {
      label: t('ReceiptPayable'),
      field: 'receiptPayable',
      format: 'money'
    },
    {
      label: t('ViewDetail'),
      field: 'receiptNumber',
      disabled: true,
      render: (value: string, index: number) => {
        return (
          <Pressable onPress={() => {
            receiptsList.length > 0 && setTableDetail(receiptsList[index].detailInfos?.map((i) => ({
              duration: i.duration ?? 0,
              occurence: i.occurence ?? 0,
              procedureCode: i.procedureCode ?? '',
              expenseAmount: i.expenseAmount ?? 0,
              thirdPartyAmount: i.thirdPartyAmount ?? 0,
              siAmount: i.siAmount ?? 0,
              claimAmount: i.claimAmount ?? 0,
              excludedAmount: i.excludedAmount ?? 0,
              payableAmount: i.payableAmount ?? 0,
              diagnosis: (i.diagnosis?.code ?? '-') + ' ' + (i.diagnosis?.description ?? '')
            })) || [])
            setShowDetailPopup(true)
          }}>
            <Text style={{ fontSize: 15, color: '#09A4D8', textDecorationLine: 'underline' }}>{t('ViewDetail')}</Text>
          </Pressable>
        )
      }
    }
  ]

  const tableDetail = [
    {
      label: t('Duration'),
      field: 'duration'
    },
    {
      label: t('Occurence'),
      field: 'occurence'
    },
    {
      label: t('Diagnosis'),
      field: 'diagnosis'
    },
    {
      label: t('Surgery'),
      field: 'procedureCode'
    },
    {
      label: t('ExpenseAmt'),
      field: 'expenseAmount',
      format: 'money'
    },
    {
      label: t('ThirdPartyAmt'),
      field: 'thirdPartyAmount',
      format: 'money'
    },
    {
      label: t('SIAmt'),
      field: 'siAmount',
      format: 'money'
    },
    {
      label: t('ClaimAmt'),
      field: 'claimAmount',
      format: 'money'
    },
    {
      label: t('ExcludedAmt'),
      field: 'excludedAmount',
      format: 'money'
    },
    {
      label: t('PayableAmt'),
      field: 'payableAmount',
      format: 'money'
    }
  ]
  
  const receiptsFieldsInfo = (data: ReceiptsInfoData) => [
    {
      title: t('NumberReceipts'),
      value: data?.numberOfReceipts ? PulseOpsFormat.currency(data?.numberOfReceipts) : '-'
    },
    {
      title: t('TotalExpenseAmt'),
      value: data?.totalExpenseAmount ? PulseOpsFormat.currency(data?.totalExpenseAmount) : '-'
    },
    {
      title: t('TotalPayableAmt'),
      value: data?.totalPayableAmount ? PulseOpsFormat.currency(data?.totalPayableAmount) : '-'
    }
  ]

  const ViewReceiptsInfo = (data: ReceiptsInfoData | null) => {
    return (
      <View style={[IBStyles.row]}>
        {data && receiptsFieldsInfo(data).map((field) => (
          <View style={[IBStyles.sectionCol4, { paddingBottom: 24, paddingRight: 8 }]}>
            <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
              <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{field.title}</Text>
            </View>
            <Text style={{ fontSize: 15, marginTop: 4 }}>{field.value ?? '-'}</Text>
          </View>
        ))}
        {receiptsList && ReceiptsTable(dataReceiptsTable)}
      </View>
    )
  }

  const DetailReceipts = () => {
    return (
      <ScrollView>
        <View style={{ paddingHorizontal: 20 }}>
          <IBGeneralTable
            dataTable={tableDetail}
            data={dataTableDetail ?? []}
            loading={loading}
          >
          </IBGeneralTable>
        </View>
      </ScrollView>
    )
  }

  const ReceiptsTable = (data: DataReceiptsTable[]) => {
    return (
      <View style={{ width: '100%' }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={data ?? []}
          loading={loading}
        >
        </IBGeneralTable>
        <ModalComponent
          children={DetailReceipts()}
          titleStyle={{ fontWeight: 'bold' }}
          visible={isShowDetailPopup}
          onClose={() => setShowDetailPopup(false)}
          title={t('ReceiptDetail')}
          modalWidth={1100}
          actions={[]}
        >
        </ModalComponent>
      </View>
    )
  }

  return (
    <View>
       {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        receiptsList ? 
          ViewReceiptsInfo(receiptsInfo) :
          <Text style={{ textAlign: 'center', fontSize: 15 }}>{t('common:Nodata')}</Text>
        )
      }
    </View>
  )
}