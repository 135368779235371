import { PayoutPopup, form2, capFisrtLetterEachWord } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'

export namespace LoanPaymentForm {
  const LoanPayment = t.type({
    payout: pipe(
      t.array(PayoutPopup.SummaryCodec),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray =>
          arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0,
        () => i18next.t('message:MS050241'),
        'TotalAmountLessThanZero'
      ),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray => arr && arr.length > 0,
        () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
        'EmptyArray'
      ),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray =>
          arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) >= 500000,
        () => i18next.t('message:MS050157', { k: 500000 }),
        'TotalAmountLessThanZero'
      )
    )
  })

  export type LoanPayment = t.TypeOf<typeof LoanPayment>

  export const codec = LoanPayment

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
