export namespace CancelFromInceptionConst {
  export const reasons = [
    {
      value: 'AGDV',
      label: 'Cty cham dut hop dong voi dai ly'
    },
    {
      value: 'AGNV',
      label: 'Dai ly nghi viec'
    },
    {
      value: 'CALL',
      label: 'Khong lien lac duoc khach hang'
    },
    {
      value: 'CASS',
      label: 'KH y/cau thay doi dai ly'
    },
    {
      value: 'CFIF',
      label: 'KH huy HD, ly do tai chinh'
    }
  ]
  export const mapCFIReason = new Map<string | undefined, string>()
  reasons.forEach((o) => mapCFIReason.set(o.value, o.label))
}
