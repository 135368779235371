import React from 'react'
import { View, Text } from 'react-native'
import { CustomCheckBox, SC, ConfirmView } from '../../common'
import { PolicyServiceProps } from '../policy-service-props'
import { pipe } from 'fp-ts/function'
import {
  ErrorHandling,
  form2,
  GeneralService,
  GoAbroadService,
  Select,
  useMobile,
  GoAbroadModel,
  AppContext,
  SelectOption,
  AbroadClientMapping,
  laAbroadClient,
  Panel,
  SelectSearch,
  assets,
  DatePicker,
  RadioButton,
  Input,
  Duration,
  TransactionType
} from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { GoAboardForm } from './go-aboard-form'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import _ from 'lodash'
import { RequestAuthenticateData } from '../../request-authen'
import moment from 'moment'

// type Props = PolicyServiceProps<GoAbroadModel.GoAbroadSubmitData> & {}
const defaultClient = {
  clientName: undefined,
  destinationCountry: undefined,
  purpose: undefined,
  departureDate: undefined,
  duration: undefined,
  fatca: false,
  aboardAddress: undefined,
  addressCountry: undefined,
  addressCity: undefined,
  addressDistrict: undefined,
  addressWard: undefined,
  clientPhoneCode: { value: '1', label: '+1' },
  clientPhoneNumber: undefined
}
const defaultValues = {
  goAbroadClients: [defaultClient],
  isPremiumPayer: false,
  premiumPayer: {
    mobileCode: { value: '1', label: '+1' }
  }
}
type Props = PolicyServiceProps<{}> & {}
export const GoAboard: React.FC<Props> = ({ policyNumber, isConfirmed, initSubmission }) => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [, bindLoader] = useLoading(false)
  const { isMobile } = useMobile()
  const [provinceOptions, setProvinces] = React.useState<SelectOption[]>([])
  const [districtOptions, setDistricts] = React.useState<SelectOption[]>([])
  const [wardOptions, setWards] = React.useState<SelectOption[]>([])
  const {
    base: {
      control,
      formState: { errors, isValid },
      getValues,
      trigger,
      reset,
      watch,
      setValue
    }
  } = form2.useForm(GoAboardForm.codec, {
    defaultValues
  })

  const payerProvinceCode = watch('premiumPayer.city.value')

  initSubmission({
    validate: async () => {
      await trigger()
      //console.log(errors)
      if (isValid) {
        return {
          url: () => `wf-api/policy/${policyNumber!}/go-abroad`,
          body: mapData(),
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.GO_ABROAD),
          collerationId: policyNumber ?? '',
          transaction: TransactionType.GO_ABROAD
        }
      }
      return false
    },
    clear: () => {
      clearForm()
    }
  })

  const mapData = () => {
    const goAbroadClients = getValues('goAbroadClients')
    const clients: GoAbroadModel.AbroadDetail[] = goAbroadClients.map((value) => {
      return {
        clientName: value.clientName.label!,
        aboardAddress: value.aboardAddress!,
        addressWard: value.addressWard ?? '',
        addressDistrict: value.addressDistrict ?? '',
        addressCity: value.addressCity ?? '',
        addressCountry: value.addressCountry.value!,
        clientPhoneCode: value.clientPhoneCode?.value ?? '',
        clientPhoneNumber: value.clientPhoneNumber ?? '',
        destinationCountry: value.destinationCountry.value!,
        purpose: value.purpose.value!,
        departureDate: moment(value.departureDate).toISOString(),
        duration: value.duration.value!,
        fatca: value.fatca,
        clientCode: value.clientName.value!
      }
    })

    let datasubmit: { goAbroadClients: GoAbroadModel.AbroadDetail[]; premiumPayer?: GoAbroadModel.PremiumPayer } = {
      goAbroadClients: clients
    }
    if (getValues('isPremiumPayer')) {
      const { payer, address, country, city, district, ward, mobileCode, mobileNumber } = getValues('premiumPayer')
      const premiumPayer: GoAbroadModel.PremiumPayer = {
        clientName: payer!,
        aboardAddress: address!,
        addressWard: ward.value!,
        addressDistrict: district.value!,
        addressCity: city.value!,
        addressCountry: country.value!,
        clientPhoneCode: mobileCode?.value ?? '',
        clientPhoneNumber: mobileNumber ?? ''
      }

      datasubmit.premiumPayer = premiumPayer
    }

    return datasubmit
  }

  const clearForm = () => {
    reset(defaultValues)
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:GO_ABROAD'), navigate: null }
      ])
    }

    return () => {
      clearForm()
    }
  }, [isFocused])

  const { fields, append, remove } = useFieldArray<GoAboardForm.Raw>({
    control,
    name: 'goAbroadClients'
  })

  const detail = pipe(
    GoAbroadService.getAbroadClients(policyNumber!),
    ZIO.map((detail) => {
      return detail
    }),
    ErrorHandling.runDidUpdate([policyNumber])
  )

  //   const getRole = React.useMemo(() => { }, [])

  const { countryOptions, mobileCodeOptions, purposeOptions, desCountryOptions } = pipe(
    ZIO.zipPar(
      GeneralService.getCountries,
      GeneralService.getMobileCodes,
      GeneralService.getGoAbroadPurposes,
      GeneralService.getCountriesTV536
    ),
    ZIO.map(([countries, mobileCodes, purposes, desCountries]) => {
      const countryOptions = countries
        .filter((c) => c.code !== 'VN')
        .map((country) => ({
          value: country.code,
          label: country.name
        }))

      const mobileCodeOptions = mobileCodes
        .filter((m) => m.code !== '84')
        .map((mobileCode) => ({
          value: mobileCode.code,
          label: `+${mobileCode.code}`
        }))

      const purposeOptions = purposes.map((purpose) => ({
        value: purpose.code,
        label: purpose.name
      }))

      const desCountryOptions = desCountries.map((country) => ({
        value: country.code,
        label: country.name
      }))

      return {
        countryOptions,
        desCountryOptions,
        mobileCodeOptions,
        purposeOptions,
        mobileCodes
      }

      // return ZIO.unit
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  // const provinceOptions = React.useMemo(() => {
  //   return pipe(
  //     GeneralService.getProvinces,
  //     ZIO.map(provinces => {

  //       return ZIO.unit
  //     }),
  //     bindLoader,
  //     ErrorHandling.run()
  //   )
  // }, [])

  const getProvice = React.useCallback(() => {
    return pipe(
      GeneralService.getProvinces,
      ZIO.map((provinces) => {
        const provinceOptions = provinces.map((p) => ({ label: p.name, value: p.code }))
        setProvinces(provinceOptions)
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }, [])

  const getRole = (client: AbroadClientMapping) => {
    let role = ''
    const clientName = client.info.clientName
    switch (client.detail.role) {
      case 'LA':
        role = t('roles:LifeAssured')
        const clientDetail = client.detail as laAbroadClient
        return `${client.info.clientName} (${role} ${clientDetail.life})`
      case 'BEN':
        role = t('roles:Beneficiary')
        return `${clientName} (${role})`
      case 'OWNER':
        role = t('roles:PolicyOwner')
        return `${clientName} (${role})`
      default:
        return ''
    }
  }

  const mapClientOptions = React.useMemo(() => {
    if (detail && Array.isArray(detail)) {
      return detail.map((c) => ({
        value: c.detail.clientCode,
        label: getRole(c)
      }))
    }
    return []
  }, [detail])

  const getDistrict = (provinceCode: string) =>
    pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => ({
          value: district.code,
          label: district.name
        }))
        setDistricts(districtOptions)
        return ZIO.unit
      }),
      ErrorHandling.run([i18n.language])
    )

  const getWard = (provinceCode: string, districtCode: string) =>
    pipe(
      GeneralService.getWards({ provinceCode, districtCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => ({
          value: ward.code,
          label: ward.name
        }))
        setWards(wardOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  // Calculate width if width screen isMobile mode - composewith use percent number [ 1 -> 100]
  const composeWidth = isMobile ? 100 : 33.3
  //const composeFlex = isMobile ? 1 : undefined

  const mapDuration = React.useMemo(() => {
    return new Map<Duration.Duration, string>([
      [Duration.Duration.From3To6Months, t('requestInfo:From3To6months') ?? '3 den 6 thang'],
      [Duration.Duration.From6To1Year, t('requestInfo:From6To1Year') ?? '3 den 6 thang'],
      [Duration.Duration.From1To5Years, t('requestInfo:From1To5Years')],
      [Duration.Duration.From5To10Years, t('requestInfo:From5To10Years')],
      [Duration.Duration.From10To20Years, t('requestInfo:From10To20Years')],
      [Duration.Duration.From20Years, t('requestInfo:From20Years')]
    ])
  }, [])

  const MockDuration: SelectOption[] = React.useMemo(() => {
    return [
      {
        value: Duration.Duration.From3To6Months,
        label: mapDuration.get(Duration.Duration.From3To6Months) ?? ''
      },
      {
        value: Duration.Duration.From6To1Year,
        label: mapDuration.get(Duration.Duration.From6To1Year) ?? ''
      },
      {
        value: Duration.Duration.From1To5Years,
        label: mapDuration.get(Duration.Duration.From1To5Years) ?? ''
      },
      {
        value: Duration.Duration.From5To10Years,
        label: mapDuration.get(Duration.Duration.From5To10Years) ?? ''
      },
      {
        value: Duration.Duration.From10To20Years,
        label: mapDuration.get(Duration.Duration.From10To20Years) ?? ''
      },
      {
        value: Duration.Duration.From20Years,
        label: mapDuration.get(Duration.Duration.From20Years) ?? ''
      }
    ]
  }, [])

  // const getPhoneCodeByCountry = (countryName: string): SelectOption => {
  //   const phoneCode = mobileCodes?.find((m) => m.countryName.trim() === countryName.trim())
  //   return {
  //     value: phoneCode?.code ?? '',
  //     label: !!phoneCode?.code ? `+${phoneCode.code}` : ''
  //   }
  // }

  const isPremiumPayer = watch('isPremiumPayer')

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:GoAboardDetailRequest').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      {fields.map((form, index) => {
        return (
          <Controller
            key={`form____aboard_${index}`}
            control={control}
            name={`goAbroadClients.${index}`}
            render={({ field: {} }) => {
              return (
                <SC.Padding bottom={10}>
                  <Panel containerStyle={{ backgroundColor: '#fafafa' }}>
                    <SC.Padding vertical={10}>
                      <SC.RowWrap>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.clientName`}
                              render={({ field: { value, onChange } }) => {
                                const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                                return (
                                  <ConfirmView
                                    isConfirmed={isConfirmed}
                                    value={wrapValue.label}
                                    title={t('submission:SelectClient')}
                                    required
                                  >
                                    <Select
                                      label={t('submission:SelectClient')}
                                      options={mapClientOptions}
                                      required
                                      placeholder={t('common:Select')}
                                      //onChange={(value) => }
                                      value={wrapValue}
                                      onChange={onChange}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.clientName.message`)}
                                      // disabled={isConfirmed}
                                      // showUnderline={!isConfirmed}
                                      numberOfLine={1}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.destinationCountry`}
                              render={({ field: { value, onChange } }) => {
                                const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                                return (
                                  <ConfirmView
                                    value={wrapValue.label}
                                    title={t('submission:DestinationCountry')}
                                    required
                                    isConfirmed={isConfirmed}
                                  >
                                    <SelectSearch
                                      label={t('submission:DestinationCountry')}
                                      required
                                      value={wrapValue}
                                      onChange={(valueChange) => {
                                        //console.log(valueChange)
                                        onChange(valueChange)
                                        if (valueChange) {
                                          setValue(`goAbroadClients.${index}.addressCountry`, valueChange)
                                        }
                                        if (valueChange?.value === 'USA') {
                                          setValue(`goAbroadClients.${index}.fatca`, true)
                                        }

                                        // if (!!valueChange?.label) {
                                        //   const phoneCode = getPhoneCodeByCountry(valueChange.label)
                                        //   setValue(
                                        //     `goAbroadClients.${index}.clientPhoneCode`,
                                        //     getPhoneCodeByCountry(valueChange.label)
                                        //   )
                                        // }
                                      }}
                                      options={desCountryOptions || []}
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      placeholder={t('common:Select')}
                                      errorMessage={_.get(
                                        errors,
                                        `goAbroadClients.${index}.destinationCountry.message`
                                      )}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.purpose`}
                              render={({ field: { value, onChange } }) => {
                                const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                                return (
                                  <ConfirmView
                                    value={wrapValue.label}
                                    title={t('submission:Purpose')}
                                    isConfirmed={isConfirmed}
                                    required
                                  >
                                    <SelectSearch
                                      options={purposeOptions || []}
                                      label={t('submission:Purpose')}
                                      required
                                      value={wrapValue}
                                      onChange={onChange}
                                      placeholder={t('common:Select')}
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.purpose.message`)}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                      </SC.RowWrap>
                    </SC.Padding>

                    <SC.Padding vertical={10}>
                      <SC.RowWrap>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5} top={4}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.departureDate`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <ConfirmView
                                    title={t('submission:DepartureDate')}
                                    value={moment(value).format('DD/MM/yyyy')}
                                    required
                                    isConfirmed={isConfirmed}
                                  >
                                    <DatePicker
                                      label={t('submission:DepartureDate')}
                                      required
                                      value={value}
                                      onChange={(value) => onChange(value)}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.departureDate.message`)}
                                      // disabled={props.isConfirmed}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5} top={4}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.duration`}
                              render={({ field: { value, onChange } }) => {
                                const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                                return (
                                  <ConfirmView
                                    title={t('submission:Duration')}
                                    value={wrapValue.label}
                                    required
                                    isConfirmed={isConfirmed}
                                  >
                                    <Select
                                      label={t('submission:Duration')}
                                      required
                                      options={MockDuration}
                                      onChange={onChange}
                                      value={wrapValue}
                                      placeholder={t('common:Select')}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.duration.message`)}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={4}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.fatca`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <ChooseFatca
                                    title={t('submission:CurrentlyFatca')}
                                    selected={value}
                                    onChange={onChange}
                                    disable={isConfirmed}
                                  />
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                      </SC.RowWrap>
                    </SC.Padding>

                    <SC.Padding>
                      <SC.RowWrap>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.addressCountry`}
                              render={({ field: { value, onChange } }) => {
                                const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                                return (
                                  <ConfirmView
                                    title={t('submission:Country')}
                                    value={wrapValue.label}
                                    required
                                    isConfirmed={isConfirmed}
                                  >
                                    <SelectSearch
                                      label={t('submission:Country')}
                                      options={countryOptions || []}
                                      onChange={onChange}
                                      value={wrapValue}
                                      placeholder={t('common:Select')}
                                      errorMessage={
                                        _.get(errors, `goAbroadClients.${index}.addressCountry.message`) ||
                                        (_.get(errors, `goAbroadClients.${index}.type`) === 'AboardFormCountry' &&
                                          _.get(errors, `goAbroadClients.${index}.message`))
                                      }
                                      popupIcon={<assets.ArrowDownDropdownIcon />}
                                      required
                                      maxLength={100}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5} vertical={6}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.addressCity`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <ConfirmView
                                    title={t('submission:CityProvince')}
                                    value={value ?? ''}
                                    required
                                    isConfirmed={isConfirmed}
                                  >
                                    <Input
                                      title={t('submission:CityProvince')}
                                      placeholder={t('submission:InputCity')}
                                      value={value ?? ''}
                                      onChange={onChange}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.addressCity.message`)}
                                      required
                                      maxLength={50}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5} vertical={6}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.addressDistrict`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <ConfirmView
                                    title={t('submission:District')}
                                    value={value ?? '-'}
                                    required={false}
                                    isConfirmed={isConfirmed}
                                  >
                                    <Input
                                      title={t('submission:District')}
                                      value={value ?? ''}
                                      onChange={onChange}
                                      placeholder={t('submission:InputDistrict')}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.addressDistrict.message`)}
                                      maxLength={30}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                      </SC.RowWrap>
                    </SC.Padding>

                    <SC.Padding vertical={10}>
                      <SC.RowWrap>
                        <View style={{ width: composeWidth + '%' }}>
                          <SC.Padding horizontal={5} vertical={5}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.addressWard`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <ConfirmView
                                    title={t('submission:Ward')}
                                    value={value ?? '-'}
                                    isConfirmed={isConfirmed}
                                    required={false}
                                  >
                                    <Input
                                      title={t('submission:Ward')}
                                      value={value ?? ''}
                                      onChange={onChange}
                                      placeholder={t('submission:InputWard')}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.addressWard.message`)}
                                      maxLength={30}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                        <View style={{ width: (isMobile ? 100 : 66.6) + '%' }}>
                          <SC.Padding horizontal={5} top={5}>
                            <Controller
                              control={control}
                              name={`goAbroadClients.${index}.aboardAddress`}
                              render={({ field: { value, onChange } }) => {
                                //const wrapValue = { value: value?.label ?? '', label: value?.label ?? ''}
                                return (
                                  <ConfirmView
                                    title={t('requestInfo:HouseNumberBuildingNameStreet')}
                                    value={value ?? ''}
                                    required
                                    isConfirmed={isConfirmed}
                                  >
                                    <Input
                                      title={t('requestInfo:HouseNumberBuildingNameStreet')}
                                      onChange={onChange}
                                      placeholder={t('submission:InputAddres')}
                                      value={value ?? ''}
                                      errorMessage={_.get(errors, `goAbroadClients.${index}.aboardAddress.message`)}
                                      required
                                      maxLength={30}
                                    />
                                  </ConfirmView>
                                )
                              }}
                            />
                          </SC.Padding>
                        </View>
                      </SC.RowWrap>
                    </SC.Padding>

                    <SC.Padding horizontal={5}>
                      <SC.RowWrap>
                        <View style={{ width: composeWidth + '%' }}>
                          <ConfirmView
                            isConfirmed={isConfirmed}
                            title={t('submission:AboardMobilePhone')}
                            value={
                              getValues(`goAbroadClients.${index}.clientPhoneNumber`)
                                ? `${getValues(`goAbroadClients.${index}.clientPhoneCode.label`) ?? ''} ${
                                    getValues(`goAbroadClients.${index}.clientPhoneNumber`) ?? '-'
                                  }`
                                : '-'
                            }
                            required={false}
                          >
                            <View style={{ flex: 1, paddingTop: 4 }}>
                              <SC.TitleText>{t('submission:AboardMobilePhone')}</SC.TitleText>
                              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                <Controller
                                  key={`client_phone_key_${getValues(`goAbroadClients.${index}.clientPhoneCode`)}`}
                                  control={control}
                                  name={`goAbroadClients.${index}.clientPhoneCode`}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                                    return (
                                      <View style={{ width: 110 }}>
                                        <SelectSearch
                                          options={mobileCodeOptions}
                                          onChange={onChange}
                                          value={wrapValue}
                                          popupIcon={<assets.ArrowDownDropdownIcon />}
                                          hideLabel
                                          placeholder={'+1'}
                                          disabled={isConfirmed}
                                          maxLength={6}
                                        />
                                      </View>
                                    )
                                  }}
                                />
                                <SC.Divider />
                                <Controller
                                  control={control}
                                  name={`goAbroadClients.${index}.clientPhoneNumber`}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <View style={{ flex: 1 }}>
                                        <Input
                                          onChange={(value) => {
                                            onChange(value)
                                            // if(/^[0-9]*$/.test(value)) {

                                            // }
                                            // if (/\b\d{10}\b/.test(value)) {
                                            //   getDuplicatePhone(value)
                                            // } else {
                                            //   setValue('phone.relationships', [])
                                            // }
                                          }}
                                          value={value ?? ''}
                                          maxLength={20}
                                          placeholder={t('submission:InputPhone')}
                                          disabled={isConfirmed}
                                        />
                                      </View>
                                    )
                                  }}
                                />
                              </View>
                              {(_.get(errors, `goAbroadClients.${index}.clientPhoneCode`)?.message ||
                                _.get(errors, `goAbroadClients.${index}.clientPhoneNumber`)?.message) && (
                                <View>
                                  <SC.ErrorText fontSize={12}>
                                    {_.get(errors, `goAbroadClients.${index}.clientPhoneCode`)?.message ||
                                      _.get(errors, `goAbroadClients.${index}.clientPhoneNumber`)?.message}
                                  </SC.ErrorText>
                                </View>
                              )}
                            </View>
                          </ConfirmView>
                        </View>
                      </SC.RowWrap>
                    </SC.Padding>

                    {!isConfirmed && (
                      <SC.FlexStartSelf>
                        <SC.Padding vertical={10}>
                          <IconButton
                            title={t('common:Delete')}
                            onPress={() => {
                              remove(index)
                            }}
                            icon={<assets.DeleteBin />}
                          />
                        </SC.Padding>
                      </SC.FlexStartSelf>
                    )}
                  </Panel>
                </SC.Padding>
              )
            }}
          />
        )
      })}

      {!isConfirmed && (
        <SC.FlexStartSelf>
          <SC.Padding horizontal={20}>
            <IconButton
              title={t('common:Add')}
              onPress={() => {
                append(defaultClient)
              }}
              icon={<assets.PlusCircleIcon />}
            />
          </SC.Padding>
        </SC.FlexStartSelf>
      )}

      {/* Premium Payer */}

      <SC.Padding vertical={10}>
        <Controller
          control={control}
          name="isPremiumPayer"
          render={({ field: { value, onChange } }) => {
            return (
              <CustomCheckBox
                checked={value}
                onPress={(_) => {
                  onChange(!value)
                  getProvice()
                  if (!value) {
                    setValue('premiumPayer.country', { label: 'Viet Nam', value: 'VN' })
                  } else {
                    setValue('premiumPayer.country', { label: '', value: '' })
                  }
                }}
                title={t('submission:PremiumPayerAndContact').toUpperCase()}
                titleProps={{ style: { fontWeight: 'bold' } }}
                containerStyle={{
                  paddingVertical: 5,
                  alignSelf: 'flex-start',
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  paddingHorizontal: 0
                }}
                disabled={isConfirmed}
              />
            )
          }}
        />
      </SC.Padding>

      {isPremiumPayer && (
        <SC.PanelContainer>
          <SC.RowWrap>
            <View style={{ width: (isMobile ? 100 : 66.6) + '%' }}>
              <SC.Padding horizontal={5}>
                <Controller
                  control={control}
                  name={'premiumPayer.payer'}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <ConfirmView title={t('submission:Payer')} value={value ?? ''} required isConfirmed={isConfirmed}>
                        <Input
                          title={t('submission:Payer')}
                          value={value ?? ''}
                          onChange={onChange}
                          errorMessage={_.get(errors, 'premiumPayer.payer.message')}
                          required
                          placeholder={t('submission:InputPayer')}
                          maxLength={120}
                        />
                      </ConfirmView>
                    )
                  }}
                />
              </SC.Padding>
            </View>
          </SC.RowWrap>

          <SC.Padding>
            <SC.RowWrap>
              <View style={{ width: (isMobile ? 100 : 66.6) + '%' }}>
                <SC.Padding horizontal={5} top={5}>
                  <Controller
                    control={control}
                    name={'premiumPayer.address'}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:HouseNumber')}
                          value={value ?? ''}
                          required
                          isConfirmed={isConfirmed}
                        >
                          <Input
                            title={t('submission:HouseNumber')}
                            value={value ?? ''}
                            onChange={onChange}
                            required
                            errorMessage={_.get(errors, 'premiumPayer.address.message')}
                            placeholder={t('submission:InputAddres')}
                            maxLength={50}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </SC.Padding>
              </View>
              <View style={{ width: composeWidth + '%' }}>
                <SC.Padding horizontal={5}>
                  <Controller
                    control={control}
                    name={'premiumPayer.country'}
                    render={({ field: { value, onChange } }) => {
                      const wrapValue = { value: value?.value ?? '', label: value?.label ?? '' }
                      return (
                        <ConfirmView
                          isConfirmed={isConfirmed}
                          title={t('submission:Country')}
                          required
                          value={wrapValue.label}
                        >
                          <SelectSearch
                            label={t('submission:Country')}
                            value={wrapValue}
                            onChange={onChange}
                            required
                            disabled
                            options={countryOptions || []}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            maxLength={100}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </SC.Padding>
              </View>
            </SC.RowWrap>
          </SC.Padding>

          <SC.Padding vertical={10}>
            <SC.RowWrap>
              <View style={{ width: composeWidth + '%' }}>
                <SC.Padding horizontal={5}>
                  <Controller
                    control={control}
                    name={'premiumPayer.city'}
                    render={({ field: { value, onChange } }) => {
                      const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                      return (
                        <ConfirmView
                          title={t('submission:CityProvince')}
                          value={wrapValue.label}
                          isConfirmed={isConfirmed}
                          required
                        >
                          <SelectSearch
                            label={t('submission:CityProvince')}
                            value={wrapValue}
                            onChange={(value) => {
                              onChange(value)
                              setValue('premiumPayer.district', { label: '', value: '' })
                              setValue('premiumPayer.ward', { label: '', value: '' })
                              if (value?.value) {
                                getDistrict(value.value)
                              }
                            }}
                            options={provinceOptions}
                            errorMessage={_.get(errors, 'premiumPayer.city.message')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            required
                            placeholder={t('common:Select')}
                            maxLength={100}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </SC.Padding>
              </View>
              <View style={{ width: composeWidth + '%' }}>
                <SC.Padding horizontal={5}>
                  <Controller
                    control={control}
                    name={'premiumPayer.district'}
                    render={({ field: { value, onChange } }) => {
                      const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                      return (
                        <ConfirmView
                          title={t('submission:District')}
                          value={wrapValue.label}
                          isConfirmed={isConfirmed}
                          required
                        >
                          <SelectSearch
                            label={t('submission:District')}
                            value={wrapValue}
                            onChange={(value) => {
                              onChange(value)
                              setValue('premiumPayer.ward', { label: '', value: '' })
                              if (payerProvinceCode && value?.value) {
                                getWard(payerProvinceCode, value.value)
                              }
                            }}
                            options={districtOptions || []}
                            errorMessage={_.get(errors, 'premiumPayer.district.message')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            required
                            placeholder={t('common:Select')}
                            //disableUnderline={true}
                            maxLength={100}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </SC.Padding>
              </View>
              <View style={{ width: composeWidth + '%' }}>
                <SC.Padding horizontal={5}>
                  <Controller
                    control={control}
                    name={'premiumPayer.ward'}
                    render={({ field: { value, onChange } }) => {
                      const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                      return (
                        <ConfirmView
                          title={t('submission:Ward')}
                          value={wrapValue.label}
                          isConfirmed={isConfirmed}
                          required
                        >
                          <SelectSearch
                            label={t('submission:Ward')}
                            value={wrapValue}
                            onChange={onChange}
                            options={wardOptions || []}
                            errorMessage={_.get(errors, 'premiumPayer.ward.message')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            required
                            placeholder={t('common:Select')}
                            maxLength={100}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </SC.Padding>
              </View>
            </SC.RowWrap>
          </SC.Padding>
          <SC.Padding horizontal={5}>
            <SC.RowWrap>
              <View style={{ width: composeWidth + '%' }}>
                <ConfirmView
                  isConfirmed={isConfirmed}
                  title={t('submission:AboardMobilePhone')}
                  value={
                    getValues('premiumPayer.mobileNumber')
                      ? `${getValues('premiumPayer.mobileCode.label') ?? ''} ${getValues('premiumPayer.mobileNumber')}`
                      : '-'
                  }
                  required={false}
                >
                  <View style={{ flex: 1, paddingTop: 4 }}>
                    <SC.TitleText>{t('submission:AboardMobilePhone')}</SC.TitleText>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                      <Controller
                        control={control}
                        name={`premiumPayer.mobileCode`}
                        render={({ field: { value, onChange, onBlur } }) => {
                          const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                          return (
                            <View style={{ width: 110 }}>
                              <SelectSearch
                                options={mobileCodeOptions}
                                onChange={onChange}
                                value={wrapValue}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                hideLabel
                                placeholder={'+1'}
                                disabled={isConfirmed}
                                maxLength={6}
                              />
                            </View>
                          )
                        }}
                      />
                      <SC.Divider />
                      <Controller
                        control={control}
                        name={`premiumPayer.mobileNumber`}
                        render={({ field: { value, onChange, onBlur } }) => {
                          return (
                            <View style={{ flex: 1 }}>
                              <Input
                                onChange={(value) => {
                                  onChange(value)
                                  // if (/\b\d{10}\b/.test(value)) {
                                  //   getDuplicatePhone(value)
                                  // } else {
                                  //   setValue('phone.relationships', [])
                                  // }
                                }}
                                value={value ?? ''}
                                maxLength={10}
                                placeholder={t('submission:InputPhone')}
                                disabled={isConfirmed}
                              />
                            </View>
                          )
                        }}
                      />
                    </View>
                    {(_.get(errors, `premiumPayer.mobileCode`)?.message ||
                      _.get(errors, `premiumPayer.mobileNumber`)?.message) && (
                      <View>
                        <SC.ErrorText fontSize={12}>
                          {_.get(errors, `premiumPayer.mobileCode`)?.message ||
                            _.get(errors, `premiumPayer.mobileNumber`)?.message}
                        </SC.ErrorText>
                      </View>
                    )}
                  </View>
                </ConfirmView>
              </View>
            </SC.RowWrap>
          </SC.Padding>
        </SC.PanelContainer>
      )}
    </SC.Container>
  )
}

const IconButton: React.FC<{ icon: JSX.Element; title: string; onPress: () => void }> = ({ title, onPress, icon }) => {
  return (
    <SC.ButtonContainer onPress={onPress}>
      {icon}
      <SC.Divider />
      <Text style={{ color: 'black', fontSize: 15, fontWeight: '600' }}>{title}</Text>
    </SC.ButtonContainer>
  )
}

export const ChooseFatca: React.FC<{
  selected: boolean
  title: string
  onChange?: (value: boolean) => void
  disable?: boolean
}> = ({ selected, title, onChange, disable = false }) => {
  const { t } = useTranslation()
  return (
    <SC.Padding vertical={8}>
      <SC.BoldText>
        {title} <SC.ErrorText>*</SC.ErrorText>
      </SC.BoldText>
      <SC.Divider />
      <SC.Row>
        <RadioButton
          label={t('common:Yes')}
          selected={selected}
          onChange={() => onChange?.call(null, true)}
          disable={disable}
        />
        <RadioButton
          label={t('common:No')}
          selected={!selected}
          onChange={() => onChange?.call(null, false)}
          disable={disable}
        />
      </SC.Row>
    </SC.Padding>
  )
}
