import { PremiumDecisionCode } from '@pulseops/business/core'
import * as t from 'io-ts'
import { form2 } from '@pulseops/common'

export namespace PremiumCheckForm {
  const FeeDecisionC = t.type({
    premiumDebitNote: form2.string.required,
    invoiceAmount: form2.number.required,
    releaseDate: form2.date.required,
    comment: form2.string.optional,
    status: form2.stringEnum(PremiumDecisionCode).required,
    requestDate: form2.date.optional,
    completeDate: form2.date.optional,
    decision: form2.string.required
  })
  export type FeeDecision = t.TypeOf<typeof FeeDecisionC>

  export const PremiumCaseC = t.type({
    adjustment: form2.string.required,
    standardPremium: form2.number.required,
    loadingPremium: form2.number.required,
    receivedDate: form2.date.required
  })
  export type PremiumCase = t.OutputOf<typeof PremiumCaseC>

  export const codec = t.type({
    feeDecisions: t.array(FeeDecisionC),
    cases: t.array(PremiumCaseC),
    premiumReceived: form2.number.required
  })
}
