import { Link } from '@material-ui/core'
import {
  assets,
  ErrorHandling,
  formatNumberWithComma,
  FullSurrenderReversalService,
  GeneralData,
  ImgUploadMutiple,
  Input,
  Panel,
  ReinstatementModel,
  SelectSearch,
  Title,
  SUFullSurrenderReversalData,
  TransactionType,
  GeneralService,
  StorageBlob,
  TaskType
} from '@pulseops/common'
import {
  Column,
  Error,
  FieldDescription,
  FieldText,
  SectionCol,
  SectionContent,
  SectionRow
} from '@pulseops/submission/common'
import React from 'react'
import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text } from 'react-native'
import { FullSurrenderReversalForm } from './full-surrender-reversal.form'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import * as O from 'fp-ts/lib/Option'
import { RedatingFormData } from '../redating'
import { ChooseView } from '../reinstatement/common'
import moment from 'moment'
import { RequestAuthenticateData } from '../../request-authen'

export const FullSurrenderReversal = ({
  policyNumber,
  isConfirmed,
  initSubmission,
  officeCode
}: PolicyServiceProps<SUFullSurrenderReversalData.SubmitData>) => {
  const { t } = useTranslation()

  const policyNum = policyNumber ?? ''

  const { control, trigger, reset, watch, getValues } = useForm<FullSurrenderReversalForm.FullSurrenderReversalData>({
    defaultValues: {
      reinstatementReason: '',
      healthDeclaration: [],
      LAList: []
    }
  })

  const { fields, append } = useFieldArray({
    control: control,
    name: 'LAList'
  })

  const currentLAList = useWatch({ control, name: 'LAList' })

  const { occupationList, detailData } = pipe(
    FullSurrenderReversalService.getDetail(policyNum),
    ZIO.map((responseData) => {
      const notPaidPremium = getNotPaidPremium(responseData)
      const requirePayinAmount = responseData.surrenderValue + notPaidPremium
      const surrenderData = {
        policyLoans: responseData.policyLoans,
        surrenderValue: responseData.surrenderValue,
        ptdateAdvance: responseData.ptdateAdvance,
        billFreq: responseData.billFreq,
        basicPrem: responseData.basicPrem,
        riderPrem: responseData.riderPrem,
        totalPaidCashBenefits: responseData.totalPaidCashBenefits,
        notPaidPremium,
        requirePayinAmount,
        agentCode: responseData.reinData.attributesExt.AGENT_CODE,
        reinData: responseData.reinData,
        originOccupationArr: responseData.occupationList
      }
      responseData.reinData &&
        responseData.reinData?.lifeAssured.forEach((lifeItem) => {
          const LAItem = getLADetail(lifeItem, responseData.occupationList)
          append(LAItem)
        })
      return {
        occupationList: responseData.occupationList.map((item) => ({ label: item.name, value: item.code })),
        detailData: surrenderData
      }
    }),
    ErrorHandling.runDidUpdate([policyNum])
  ) ?? {
    occupationList: [],
    detailData: null
  }

  const getResetData = () => {
    !!detailData?.reinData &&
      detailData?.reinData?.lifeAssured.forEach((lifeItem) => {
        const LAItem = getLADetail(lifeItem, detailData.originOccupationArr)
        append(LAItem)
      })
  }

  function getNotPaidPremium(surrenderData: SUFullSurrenderReversalData.DetailData) {
    const paidToDateAdvance = moment(surrenderData.ptdateAdvance)
    const requestDate = moment()
    let result = 0
    if (paidToDateAdvance < requestDate) {
      result = getNotPaidPremiumPeriod(surrenderData) * (surrenderData.basicPrem + surrenderData.riderPrem)
    }
    return result
  }

  function getNotPaidPremiumPeriod(surrenderData: SUFullSurrenderReversalData.DetailData) {
    const paidToDateAdvance = moment(surrenderData.ptdateAdvance)
    const requestDate = moment()
    const distanceDays = requestDate.diff(paidToDateAdvance, 'days')
    const customedMonth = Math.floor(distanceDays / 30) //divide by integer
    const frequency = converBillFrequencyToMonth(surrenderData.billFreq)
    const notPaidPremium = Math.floor(customedMonth / frequency) + 1
    return notPaidPremium
  }

  function converBillFrequencyToMonth(billFreq: string) {
    switch (billFreq) {
      case '01':
        return 12
      case '02':
        return 6
      case '04':
        return 3
      case '12':
        return 1
      default:
        return 0
    }
  }

  const getLADetail = (LAItem: ReinstatementModel.laClient, occupationList: GeneralData[]) => {
    const curOccupationName = pipe(
      occupationList.find((x) => x.code === LAItem.attributesExt.CUR_OCCUPATION),
      O.fromNullable,
      O.map((item) => item && item.name),
      O.getOrElse(() => '')
    )
    const lifeItem: RedatingFormData.LifeAssuredInfo = {
      idNumber: LAItem.clientId,
      clientName: LAItem.name,
      hAnswer: false,
      oAnswer: false,
      aAnswer: false,
      cAnswer: false,
      curOccupation: curOccupationName,
      curOccupationID: LAItem.attributesExt.CUR_OCCUPATION
    }
    return lifeItem
  }

  const isRequiredHealth = React.useMemo(() => {
    const isChangeHealth = currentLAList.some((item) => item.hAnswer)
    return isChangeHealth
  }, [currentLAList])

  const getSurrenderSubmitedData = () => {
    const fullSurrenderReversalInfo = {
      PAID_CASH_BENEFITS: (detailData?.totalPaidCashBenefits ?? 0).toString(),
      NOT_PAID_PREMIUM: (detailData?.notPaidPremium ?? 0).toString(),
      POLICY_LOANS: (detailData?.policyLoans ?? 0).toString(),
      REINSTATEMENT_FEE: (detailData?.requirePayinAmount ?? 0).toString(),
      REASON: watch('reinstatementReason')
    }
    const lifeAssureds = watch('LAList').map((LAItem) => {
      let curOccupationID = '',
        NEW_OCCUPATION = ''
      let NEW_ACTIVITY = '',
        COMPANY_NAME = ''
      const clientNo = LAItem.idNumber
      const H_ANSWER = LAItem.hAnswer ? 'Y' : 'N'
      const O_ANSWER = LAItem.oAnswer ? 'Y' : 'N'
      const A_ANSWER = LAItem.aAnswer ? 'Y' : 'N'
      const C_ANSWER = LAItem.cAnswer ? 'Y' : 'N'
      if (O_ANSWER) {
        curOccupationID = LAItem.curOccupationID ?? ''
        NEW_OCCUPATION = LAItem.newOccupation?.value ?? ''
      }

      if (A_ANSWER) {
        NEW_ACTIVITY = LAItem.newActivity ?? ''
      }
      if (C_ANSWER) {
        COMPANY_NAME = LAItem.newCompany ?? ''
      }
      const lifeItem = {
        attributesExt: {
          CLIENT_NO: clientNo,
          H_ANSWER: H_ANSWER,
          O_ANSWER: O_ANSWER,
          CURRENT_OCCUPATION: curOccupationID,
          NEW_OCCUPATION: NEW_OCCUPATION,
          A_ANSWER: A_ANSWER,
          NEW_ACTIVITY: NEW_ACTIVITY,
          C_ANSWER: C_ANSWER,
          COMPANY_NAME: COMPANY_NAME,
          AGENT_CODE: detailData?.agentCode ?? ''
        }
      }
      return lifeItem
    })
    const submitData: SUFullSurrenderReversalData.SubmitData = {
      surrenderValue: (detailData?.surrenderValue ?? 0).toString(),
      attributesExt: fullSurrenderReversalInfo,
      lifeAssureds: lifeAssureds
    }
    return submitData
  }

  // const getFilesWitMetaData = (isContinue: boolean) => {
  //   return isContinue
  //     ? ZIO.succeed([])
  //     : watch('healthDeclaration') && watch('healthDeclaration').length > 0
  //     ? pipe(
  //         GeneralService.getMetaData(TransactionType.FULL_SURRENDER_REVERSAL || '', 'DPS09'),
  //         ZIO.map((metaDataResult) => {
  //           const metaDataRaw: StorageBlob.MetaDataUpload = {
  //             type: metaDataResult.data.transactionType,
  //             doctype: metaDataResult.data.doctypeEn,
  //             class: metaDataResult.data.classFilenet,
  //             docid: metaDataResult.data.docID,
  //             maindoc: metaDataResult.data.mainDoc,
  //             subdoc: metaDataResult.data.subDoc,
  //             polnum: policyNum,
  //             batchno: metaDataResult.data.batchNo
  //           }
  //           const files: StorageBlob.FileContent[] = watch('healthDeclaration').map((x) => {
  //             return {
  //               file: x.file,
  //               metaData: metaDataRaw
  //             }
  //           })
  //           return files
  //         }),
  //         ZIO.flatMap((metaDataFile) => StorageBlob.uploadToSubmit('PS', policyNum)(metaDataFile)),
  //         ZIO.tapError((_) => {
  //           return ZIO.unit
  //         })
  //       )
  //     : ZIO.succeed([])
  // }

  const getUploadedFilesInfo = (formData: FullSurrenderReversalForm.FullSurrenderReversalData) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    if (formData.healthDeclaration && formData.healthDeclaration.length > 0) {
      uploadedFileList.push({
        uploadFiles: formData.healthDeclaration,
        transactionType: TransactionType.FULL_SURRENDER_REVERSAL,
        docTypeCode: 'DPS09',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isValid = await trigger()
      if (isValid) {
        // const metaFiles = await pipe(getFilesWitMetaData(!!isContinue), ZIO.unsafeRun({}))
        return {
          url: (policyNum: string) => `wf-api/policy/${policyNum}/surrender-reversal`,
          body: getSurrenderSubmitedData(),
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.FULL_SURRENDER_REVERSAL),
          collerationId: policyNum,
          transaction: TransactionType.FULL_SURRENDER_REVERSAL,
          uploadedFilesInfo: getUploadedFilesInfo(getValues())
        }
      } else {
        return false
      }
    },
    clear: () => {
      reset({
        reinstatementReason: '',
        healthDeclaration: [],
        LAList: []
      })
      getResetData()
    }
  })

  return (
    <SafeAreaView style={surrenderStyles.displayStyle}>
      <Title title={t('FSR:Info')} wrapperStyle={surrenderStyles.secondLine} />
      <SectionContent>
        <SectionRow>
          <SectionCol>
            <Controller
              control={control}
              name="reinstatementReason"
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  title={t('Payout:ReinstatementReason')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={200}
                  disabled={isConfirmed}
                  alwayShowUnderline={true}
                />
              )}
            />
          </SectionCol>
          <SectionCol>
            <FieldText text={t('FSR:SurrenderValue')}></FieldText>
            <FieldDescription
              text={formatNumberWithComma(detailData?.surrenderValue ?? 0)}
              isCurrencyUnit={true}
            ></FieldDescription>
          </SectionCol>
          <SectionCol>
            <FieldText text={t('FSR:TotalPaidCashBenefits')}></FieldText>
            <FieldDescription
              text={formatNumberWithComma(detailData?.totalPaidCashBenefits ?? 0)}
              isCurrencyUnit={true}
            ></FieldDescription>
          </SectionCol>
        </SectionRow>
        <SectionRow isSecondLine={true}>
          <SectionCol>
            <FieldText text={t('FSR:PolicyLoans')}></FieldText>
            <FieldDescription
              text={formatNumberWithComma(detailData?.policyLoans ?? 0)}
              isCurrencyUnit={true}
            ></FieldDescription>
          </SectionCol>
          <SectionCol>
            <FieldText text={t('FSR:NotPaidPremium')}></FieldText>
            <FieldDescription
              text={formatNumberWithComma(detailData?.notPaidPremium ?? 0)}
              isCurrencyUnit={true}
            ></FieldDescription>
          </SectionCol>
          <SectionCol>
            <FieldText text={t('requestInfo:RequirePayinAmount')}></FieldText>
            <FieldDescription
              text={formatNumberWithComma(detailData?.requirePayinAmount ?? 0)}
              isCurrencyUnit={true}
            ></FieldDescription>
          </SectionCol>
        </SectionRow>
      </SectionContent>
      <Title title={t('FSR:STATEMENTOFINSURABILITY')} wrapperStyle={surrenderStyles.secondLine} />
      {fields.map((fieldItem, index) => {
        return (
          <Controller
            key={`LifeAssured_${index}`}
            control={control}
            name={`LAList.${index}`}
            render={({ field: { value, onChange } }) => {
              return !value ? (
                <></>
              ) : (
                <Panel title={fieldItem.clientName} key={index} isTransactionToggleExpand={true}>
                  <SectionRow>
                    <SectionCol sectionStyles={surrenderStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangesInStateOfHealth')}
                        selected={value.hAnswer}
                        disable={isConfirmed}
                        onChange={(select) => {
                          onChange({ ...value, hAnswer: select })
                          // isRequiredHealthDeclaration(detailData)
                        }}
                      />
                    </SectionCol>
                  </SectionRow>
                  <SectionRow isSecondLine={true}>
                    <SectionCol sectionStyles={surrenderStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangesInOccupation')}
                        selected={value.oAnswer}
                        disable={isConfirmed}
                        onChange={(select) => onChange({ ...value, oAnswer: select })}
                      />
                    </SectionCol>
                  </SectionRow>
                  {value && value.oAnswer && (
                    <SectionRow isSecondLine={true}>
                      <SectionCol>
                        <Controller
                          control={control}
                          name={`LAList.${index}.curOccupation`}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Input
                              title={t('submission:CurrentOccupation')}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={true}
                              alwayShowUnderline={true}
                            />
                          )}
                        />
                      </SectionCol>
                      <SectionCol>
                        <Controller
                          control={control}
                          name={`LAList.${index}.newOccupation`}
                          rules={{
                            required: {
                              value: true,
                              message: t('message:MS020001', { field: t('submission:NewOccupation') })
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <SelectSearch
                              options={occupationList}
                              value={value}
                              label={t('submission:NewOccupation')}
                              required
                              onChange={onChange}
                              disabled={isConfirmed}
                              placeholder={t('common:Select')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              errorMessage={!!value ? '' : error?.message}
                            />
                          )}
                        />
                      </SectionCol>
                    </SectionRow>
                  )}
                  <SectionRow isSecondLine={true}>
                    <SectionCol sectionStyles={surrenderStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangeInActivities')}
                        selected={value.aAnswer}
                        disable={isConfirmed}
                        onChange={(select) => onChange({ ...value, aAnswer: select })}
                      />
                    </SectionCol>
                  </SectionRow>
                  {value && value.aAnswer && (
                    <SectionRow isSecondLine={true}>
                      <SectionCol>
                        <Controller
                          control={control}
                          name={`LAList.${index}.newActivity`}
                          rules={{
                            required: {
                              value: true,
                              message: t('message:MS020001', { field: t('submission:NewActivity') })
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <Input
                              required
                              title={t('submission:NewActivity')}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={isConfirmed}
                              maxLength={100}
                              alwayShowUnderline={true}
                              errorMessage={!!value ? '' : error?.message}
                            />
                          )}
                        />
                      </SectionCol>
                    </SectionRow>
                  )}

                  <SectionRow isSecondLine={true}>
                    <SectionCol sectionStyles={surrenderStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                        selected={value.cAnswer}
                        disable={isConfirmed}
                        onChange={(select) => onChange({ ...value, cAnswer: select })}
                      />
                    </SectionCol>
                  </SectionRow>
                  {value && value.cAnswer && (
                    <SectionRow isSecondLine={true}>
                      <SectionCol>
                        <Controller
                          control={control}
                          name={`LAList.${index}.newCompany`}
                          rules={{
                            required: {
                              value: true,
                              message: t('message:MS020001', { field: t('submission:CompanyName') })
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <Input
                              required
                              title={t('submission:CompanyName')}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={isConfirmed}
                              maxLength={100}
                              alwayShowUnderline={true}
                              errorMessage={!!value ? '' : error?.message}
                            />
                          )}
                        />
                      </SectionCol>
                    </SectionRow>
                  )}
                </Panel>
              )
            }}
          />
        )
      })}
      <SectionRow>
        <SectionCol sectionStyles={surrenderStyles.col_12}>
          <Text style={surrenderStyles.field_title}>
            {t('submission:HealthNote')}
            <Link href={`${assets.UpdatedRedatingHealthyTemplate}`} target="_parent" download>
              <Text style={surrenderStyles.underlineLink}>{t('submission:Here')}</Text>
            </Link>
          </Text>
        </SectionCol>
      </SectionRow>
      <SectionRow>
        <SectionCol sectionStyles={surrenderStyles.col_12}>
          <FieldText text={t('submission:HealthDeclaration')} isRequired={isRequiredHealth}></FieldText>
          <Controller
            control={control}
            name="healthDeclaration"
            rules={{
              required: {
                value: isRequiredHealth,
                message: t('message:MS050232')
              }
            }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <Column>
                <ImgUploadMutiple
                  value={value}
                  onChange={onChange}
                  timeFormat={'DD/MM/YYYY HH:mm'}
                  disabled={isConfirmed}
                />
                {error?.message && isRequiredHealth && (
                  <Error message={value && value.length > 0 ? '' : error.message} />
                )}
              </Column>
            )}
          />
        </SectionCol>
      </SectionRow>
    </SafeAreaView>
  )
}
const surrenderStyles = StyleSheet.create({
  displayStyle: {
    flex: 1
  },
  secondLine: {
    marginTop: 20
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  underlineLink: {
    textDecorationLine: 'underline'
  }
})
