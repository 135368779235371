import React from 'react'
import { SC } from '../../../common'
import { RadioButton } from '@pulseops/common'
import { useTranslation } from 'react-i18next'

export const ChooseView: React.FC<{
  selected: boolean
  title: string
  onChange?: (value: boolean) => void
  disable?: boolean
}> = ({ selected, title, onChange, disable = false }) => {
  const { t } = useTranslation()
  return (
    <SC.Padding vertical={8}>
      <SC.BoldText>
        {title} <SC.ErrorText>*</SC.ErrorText>
      </SC.BoldText>
      <SC.Divider />
      <SC.Row>
        <RadioButton
          label={t('submission:Change')}
          selected={selected}
          onChange={() => onChange?.call(null, true)}
          disable={disable}
        />
        <RadioButton
          label={t('submission:NoChange')}
          selected={!selected}
          onChange={() => onChange?.call(null, false)}
          disable={disable}
        />
      </SC.Row>
    </SC.Padding>
  )
}
