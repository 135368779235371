import {
  ACMVQueryData,
  APLValueData,
  BonusValueData,
  ContractValueData,
  IBPanel,
  IBService,
  IPremiumHistory,
  IProductDetail,
  LasTransactionHistoryData,
  MainListProduct,
  OPLValueData,
  PaidUpInquiryData,
  PolicyInfoData,
  PolicyValueInfoData,
  PremiumFrequencyDetailData,
  PremiumInfoData,
  PremiumPaymentHistoryData,
  ProductTypeC,
  PrucashBenefits,
  ReinData,
  SurrenderValueData
} from '@pulseops/inbound'
import { CallOutDetailContext } from '@pulseops/outbound'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { ErrorHandling, PulseOpsFormat } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { isNil } from 'lodash'
import { useIsFocused } from '@react-navigation/native'

const PremiumHistory = React.lazy(() => import('../ob-policy-tab-childrens/OBPremiumHistory'))
const OBPremiumAllocationHistory = React.lazy(() => import('../ob-policy-tab-childrens/OBPremiumAllocationHistory'))
const PremiumFrequencyDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBPremiumFrequencyDetail'))
const APLDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBAPLDetail'))
const OPLDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBOPLDetail'))
const OBLoanRepayInquiry = React.lazy(() => import('../ob-policy-tab-childrens/OBLoanRepayInquiry'))
const BonusValueDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBBonusValueDetail'))
const PrucashBenefitDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBPrucashBenefitDetail'))
const SurrenderValueDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBSurrenderValueDetail'))
const OBSuspendAmountDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBSuspendAmountDetail'))
const OBSuspendPremiumDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBSuspendPremiumDetail'))
const OBDiscountedPremiumHistory = React.lazy(() => import('../ob-policy-tab-childrens/OBDiscountedPremiumHistory'))
const WriteOffAmountDetail = React.lazy(() => import('../ob-policy-tab-childrens/OBWriteOffAmountDetail'))
const OBReinstatement = React.lazy(() => import('../ob-policy-tab-childrens/OBReinstatement'))
const PaidUpInquiry = React.lazy(() => import('../ob-policy-tab-childrens/OBPaidUpInquiry'))
const PremiumPaymentHistoryPruonline = React.lazy(
  () => import('../ob-policy-tab-childrens/OBPremiumPaymentHistoryPruonline')
)
const PolicyStatusHistory = React.lazy(() => import('../ob-policy-tab-childrens/OBPolicyStatusHistory'))
interface Props {
  setProductInfo: (data: ProductTypeC) => void
  hasLoanRepayDate?: boolean
}

export const OBPolicyTab = (props: Props) => {
  const { t, i18n } = useTranslation()
  const [loading, bindLoading] = useLoading(false)
  const { policyNumber, clientNumber } = React.useContext(CallOutDetailContext)
  const [dataPremiumHistory, setDataPremiumHistory] = React.useState<{
    listData: IPremiumHistory[]
    dataHistory: PremiumInfoData[]
  } | null>(null)

  const [dataPremiumAllocationHistory, setDataPremiumAllocationHistory] = React.useState<{
    ACMVData: ACMVQueryData[]
    listProduct: MainListProduct[]
  } | null>(null)
  const [data, setData] = React.useState<
    | (PolicyInfoData &
        PolicyValueInfoData & {
          aplValue: APLValueData
          oplValue: OPLValueData
          prucashBenefits: PrucashBenefits
          contractValue: ContractValueData
        })
    | null
  >(null)
  const [autoToggleKey, setAutoToggleKey] = React.useState<string | undefined | null>(null)
  const [layoutY, setLayoutY] = React.useState<number | null>(null)
  const [dataPremiumFrequency, setDataPremiumFrequency] = React.useState<PremiumFrequencyDetailData | null>(null)
  const [productDetail, setProductDetail] = React.useState<IProductDetail | null>(null)
  const [dataBonusValue, setDataBonusValue] = React.useState<BonusValueData | null>(null)
  const [dataSurrenderValue, setDataSurrenderValue] = React.useState<SurrenderValueData | null>(null)
  const [dataSuspendAmountDetail, setDataSuspendAmountDetail] = React.useState<ACMVQueryData[] | null>(null)
  const [dataSuspendPremiumDetail, setDataSuspendPremiumDetail] = React.useState<ACMVQueryData[] | null>(null)
  const [dataDiscountedPremiumHistory, setDataDiscountedPremiumHistory] = React.useState<ACMVQueryData[] | null>(null)
  const [dataWriteOffAmountDetail, setDataWriteOffAmountDetail] = React.useState<ACMVQueryData[] | null>(null)
  const [dataReinstatement, setDataReinstatement] = React.useState<ReinData | null>(null)
  const [dataPaidUpInquiry, setDataPaidUpInquiry] = React.useState<PaidUpInquiryData | null>()
  const [dataPremiumPaymentHistory, setDataPremiumPaymentHistory] = React.useState<PremiumPaymentHistoryData[] | null>(
    null
  )
  const [dataPolicyStatusHistory, setDataPolicyStatusHistory] = React.useState<LasTransactionHistoryData[] | null>(null)

  const getName = (item: { firstName?: string; surName?: string; middleName?: string } | null) => {
    if (item) {
      return item.surName + ' ' + item.middleName + ' ' + item.firstName
    }
    return ''
  }

  const getData = (): ZIO<
    unknown,
    Throwable,
    | (PolicyInfoData &
        PolicyValueInfoData & {
          aplValue: APLValueData
          oplValue: OPLValueData
          prucashBenefits: PrucashBenefits
          contractValue: ContractValueData
        })
    | null
  > =>
    pipe(
      IBService.getProductType(policyNumber),
      ZIO.flatMap((productInfo) => {
        return pipe(
          ZIO.zipPar(
            IBService.getPolicyInfo(policyNumber),
            IBService.getContractValue({
              contractNumber: policyNumber,
              effectiveDate: undefined,
              contractStatus: productInfo?.body?.status,
              productType: productInfo?.body?.productType,
              coverageId: productInfo?.body?.basicCode
            }),
            ZIO.succeed(productInfo),
            IBService.getInvestmentInfo({ data: { function: '', policies: [`${policyNumber}`] } }),
            IBService.getAPLValue(policyNumber, props.hasLoanRepayDate ? PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/YYYY') : undefined),
            IBService.getOPLValue(policyNumber, props.hasLoanRepayDate ? PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/YYYY') : undefined),
            IBService.getPrucashBenefitDetail(policyNumber)
          ),
          ZIO.flatMap(([_policyInfo, _contractValue, product, checker, _aplValue, _oplValue, _prucashValue]) =>
            ZIO.effect(() => {
              props.setProductInfo(product)
              const { policy, beneficiaries } = _policyInfo.data
              // policyInfo calc
              const productName = policy ? (i18n.language === 'en' ? policy.productNameEN : policy.productNameVN) : '-'
              const {
                policyStatus,
                premiumStatus,
                sumAssured,
                billingFrequency,
                policyOwnerCode,
                mainLifeAssuredCode,
                productCode,
                installmentPremium,
                riskCommencementDate,
                policyIssueDate,
                paidToDateBasic,
                premiumCessationDate,
                riskCessationDate,
                customerReceiveDate,
                customerReceiveMethod,
                firstIssueDate,
                uwDecisionDate,
                proposalDate,
                polCessationDate,
                destOffice,
                destOfficeName,
                paidToDateRider,
                premiumRiderAmount,
                writeOffAmount,
                waitingApplyPremiumAmount,
                discountAmount,
                premiumPaidInAdvanceAmount,
                suspendPremiumAmount,
                suspendAmount,
                paidUpNewSumAssured
              } = policy
              const POname = policy.policyOwnerName ? policy.policyOwnerName.name : ''
              const mainLA = getName(policy.mainLifeAssuredName ?? null)
              // check notify anniversary
              // if (['IF', 'PU'].includes(policyStatus as string)) {
              //   if (policyStatus === 'IF') notifyAnniversary(riskCommencementDate ?? '')
              //   // check notify fully paid
              //   notifyFullyPaid(paidToDateBasic ?? '', premiumCessationDate ?? '', premiumStatus ?? '')
              // }

              setProductDetail(product)

              const aplData = _aplValue.data.sort(function (a, b) {
                const timeB = b?.loanStartDate?.split('/').reverse().join('/') ?? ''
                const timeA = a?.loanStartDate?.split('/').reverse().join('/') ?? ''

                const newB = new Date(timeB)

                const newA = new Date(timeA)

                return newB.valueOf() - newA.valueOf()
              })

              const oplData = _oplValue.data.sort(function (a, b) {
                const timeB = b?.loanStartDate?.split('/').reverse().join('/') ?? ''
                const timeA = a?.loanStartDate?.split('/').reverse().join('/') ?? ''

                const newB = new Date(timeB)

                const newA = new Date(timeA)

                return newB.valueOf() - newA.valueOf()
              })

              const premiumBasic = pipe(
                checker.data.policyExtraInfoDetails.find(
                  (x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00'
                ),
                O.fromNullable,
                O.map((p) => p.installmentPremium),
                O.getOrElse(() => 0)
              )
              return {
                policyInfo: {
                  policyStatus: policyStatus ?? '-',
                  premiumStatus: premiumStatus ?? '-',
                  sumAssured: sumAssured ?? 0,
                  billingFrequency: billingFrequency ?? '-',
                  policyOwnerCode: policyOwnerCode ?? '-',
                  policyOwnerName: POname ?? '-',
                  mainLifeAssuredCode: mainLifeAssuredCode ?? '-',
                  mainLifeAssuredName: mainLA ?? '-',
                  productCode: productCode ?? '-',
                  productName: productName ?? '-',
                  installmentPremium: installmentPremium ?? 0,
                  riskCommencementDate: riskCommencementDate ?? '',
                  aplDate: policy && policy.aplDate ? policy.aplDate : '-',
                  policyIssueDate: policyIssueDate ?? '-',
                  paidToDateBasic: paidToDateBasic ?? '-',
                  premiumCessationDate: premiumCessationDate ?? '-',
                  riskCessationDate: riskCessationDate ?? '-',
                  customerReceiveDate: customerReceiveDate ?? '',
                  customerReceiveMethod: customerReceiveMethod ?? '-',
                  firstIssueDate: firstIssueDate ?? '-',
                  premiumBasic: premiumBasic,
                  uwDecisionDate: uwDecisionDate ?? '-',
                  proposalDate: proposalDate ?? '-',
                  polCessationDate: polCessationDate ?? '-',
                  destOffice: destOffice ?? '-',
                  destOfficeName: destOfficeName ?? '',
                  paidToDateRider: paidToDateRider ?? '-',
                  premiumRiderAmount:
                    (installmentPremium as number) >= premiumBasic
                      ? (installmentPremium as number) - premiumBasic
                      : (installmentPremium as number),
                  writeOffAmount: writeOffAmount ?? 0,
                  waitingApplyPremiumAmount: waitingApplyPremiumAmount ?? 0,
                  discountAmount: discountAmount ?? 0,
                  premiumPaidInAdvanceAmount: premiumPaidInAdvanceAmount ?? 0,
                  suspendPremiumAmount: suspendPremiumAmount ?? 0,
                  suspendAmount: suspendAmount ?? 0,
                  paidUpNewSumAssured: paidUpNewSumAssured ?? 0,
                  dispatchAddress: policy.dispatchAddress ?? '-'
                },
                beneficiaries: beneficiaries
                  ? beneficiaries.map((x, i) => ({
                      stt: (i + 1).toString(),
                      clientId: x.beneficiaryCode ?? '-',
                      percentage: x.beneficiaryPercentage + '%' ?? '0%',
                      relationshipType: x.beneficiaryRelationship ?? '',
                      clientName: getName(x.beneficiaryName ?? null) ?? '-'
                    }))
                  : [],
                contractValue: _contractValue.data,
                aplValue: aplData,
                oplValue: oplData,
                productInfo: product?.body,
                validProduct: !checker.data.policyExtraInfoDetails.some(
                  (x) =>
                    ['UHS2', 'HSR4', 'VHS1'].includes(x.productCode) &&
                    x.riderStatus === 'IF' &&
                    x.sumAssured === 400000
                ),
                prucashBenefits: _prucashValue.data
              }
            })
          )
        )
      })
    )

  const { policyInfo, beneficiaries, contractValue, aplValue, oplValue, productInfo, validProduct, prucashBenefits } =
    pipe(
      data !== null ? ZIO.succeed(data) : getData(),
      ZIO.tap((x) => {
        if (x !== null && data === null) setData(x)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.runDidUpdate([policyNumber])
    ) || {
      policyInfo: null,
      beneficiaries: [],
      contractValue: null,
      fullSurrender: null,
      aplValue: [],
      oplValue: [],
      productInfo: null,
      validProduct: false,
      prucashBenefits: []
    }

  // React.useEffect(() => {
  //   if (isNil(dataReinstatement)) {
  //     if (policyNumber && policyInfo && policyInfo.policyStatus === 'LA') {
  //       pipe(
  //         IBService.getReinData(policyNumber),
  //         ZIO.map((res) => {
  //           setDataReinstatement(res)
  //         }),
  //         ZIO.unsafeRun({})
  //       )
  //     }
  //   } else {
  //     pipe(ZIO.succeed(dataReinstatement), bindLoading, ZIO.unsafeRun({}))
  //   }
  // }, [policyNumber, policyInfo])

  React.useEffect(() => {
    if (!dataBonusValue && policyNumber && productInfo) {
      pipe(
        IBService.getBonusValue({
          contractNumber: policyNumber,
          effectiveDate: undefined,
          contractStatus: productInfo?.status,
          productType: productInfo?.productType,
          coverageId: productInfo?.basicCode
        }),
        ZIO.map((res) => {
          const bonusData =
            res && res?.data && (res.data.bonusHistories as []).length > 0
              ? res?.data?.bonusHistories?.sort(function (a, b) {
                  const timeB = b?.transactionDate?.split('/').reverse().join('/') ?? ''
                  const timeA = a?.transactionDate?.split('/').reverse().join('/') ?? ''
                  const newB = new Date(timeB)
                  const newA = new Date(timeA)
                  return newB.valueOf() - newA.valueOf()
                })
              : []
          setDataBonusValue({ ...res.data, bonusHistories: bonusData })
        }),
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(dataBonusValue), ZIO.unsafeRun({}))
    }

    if (!dataSurrenderValue && policyNumber && productInfo) {
      pipe(
        IBService.getSurrenderValue({
          contractNumber: policyNumber,
          effectiveDate: undefined,
          contractStatus: productInfo?.status,
          productType: productInfo?.productType,
          coverageId: productInfo?.basicCode
        }),
        ZIO.map((res) => {
          setDataSurrenderValue(res.data)
        }),
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(data), ZIO.unsafeRun({}))
    }
  }, [policyNumber, productInfo])

  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <View style={policyTabStyles.container}>
          <IBPanel
            title={t('Inbound:PremiumHistory')}
            children={
              <PremiumHistory
                policyNumber={policyNumber}
                clientNumber={clientNumber}
                data={dataPremiumHistory}
                setData={setDataPremiumHistory}
              />
            }
          />
          <IBPanel
              title={t('Inbound:PremiumPaymentHistoryVIAPruonline')}
              children={
                <PremiumPaymentHistoryPruonline
                  policyNumber={policyNumber}
                  data={dataPremiumPaymentHistory}
                  setData={setDataPremiumPaymentHistory}
                />
              }
            />
          <IBPanel
            title={t('Inbound:PremiumAllocationHistory')}
            children={
              <OBPremiumAllocationHistory
                policyNumber={policyNumber}
                data={dataPremiumAllocationHistory}
                setData={setDataPremiumAllocationHistory}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:PremiumFrequencyDetail')}
            children={
              <PremiumFrequencyDetail
                policyNumber={policyNumber}
                data={dataPremiumFrequency}
                productTypeInfo={productDetail}
                validProduct={validProduct}
                paidToDate={policyInfo?.paidToDateBasic}
                riskCommDate={policyInfo?.riskCommencementDate}
                setData={setDataPremiumFrequency}
                policyStatus={policyInfo?.policyStatus}
                premiumStatus={policyInfo?.premiumStatus}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:APLDetail')}
            children={<APLDetail aplValue={aplValue} policyNumber={policyNumber} hasloanRepayDate={props.hasLoanRepayDate}/>}
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:OPLDetail')}
            children={<OPLDetail oplValue={oplValue} policyNumber={policyNumber} hasloanRepayDate={props.hasLoanRepayDate}/>}
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          {(contractValue?.originalPolicyLoan as number) + (contractValue?.automaticPremiumLoan as number) > 0 ? (
            <IBPanel
              title={t('Inbound:LoanRepayInquiry')}
              children={<OBLoanRepayInquiry policyNumber={policyNumber} />}
            />
          ) : (
            <></>
          )}
          <IBPanel
            title={t('Inbound:BonusValueDetail')}
            children={
              <BonusValueDetail
                policyNumber={policyNumber}
                productInfo={productInfo}
                dataBonusValue={dataBonusValue}
                setDataBonusValue={setDataBonusValue}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:PrucashBenefitDetail')}
            children={<PrucashBenefitDetail prucashBenefits={prucashBenefits} policyNumber={policyNumber} />}
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:SurrenderValueDetail')}
            children={
              <SurrenderValueDetail
                data={dataSurrenderValue}
                setData={setDataSurrenderValue}
                policyNumber={policyNumber}
                productInfo={productInfo}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:SuspendAmountDetail')}
            children={
              <OBSuspendAmountDetail
                policyNumber={policyNumber}
                data={dataSuspendAmountDetail}
                setData={setDataSuspendAmountDetail}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:SuspendPremiumDetail')}
            children={
              <OBSuspendPremiumDetail
                policyNumber={policyNumber}
                data={dataSuspendPremiumDetail}
                setData={setDataSuspendPremiumDetail}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:DiscountedPremiumHistory')}
            children={
              <OBDiscountedPremiumHistory
                policyNumber={policyNumber}
                data={dataDiscountedPremiumHistory}
                setData={setDataDiscountedPremiumHistory}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:WriteOffAmountDetail')}
            children={
              <WriteOffAmountDetail
                policyNumber={policyNumber}
                data={dataWriteOffAmountDetail}
                setData={setDataWriteOffAmountDetail}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          <IBPanel
            title={t('Inbound:TransactionHistory')}
            children={
              <PolicyStatusHistory
                policyNumber={policyNumber}
                data={dataPolicyStatusHistory}
                setData={setDataPolicyStatusHistory}
              />
            }
            autoToggleKey={autoToggleKey}
            setLayoutY={setLayoutY}
          />
          {policyInfo?.policyStatus !== 'LA' ? (
            <></>
          ) : (
            <IBPanel
              title={`${t('Inbound:Reinstatement')}`}
              children={<OBReinstatement policyNumber={policyNumber} data={dataReinstatement} setData={setDataReinstatement} />}
            />
          )}
           {['U', 'V'].includes(productInfo?.basicCode?.charAt(0) || '') || productInfo?.basicCode === 'IPD1' ? (
              <></>
            ) : (
              <IBPanel
                title={t('Inbound:PaidUpInquiry')}
                children={
                  <PaidUpInquiry policyNumber={policyNumber} data={dataPaidUpInquiry} setData={setDataPaidUpInquiry} />
                }
              />
            )}
        </View>
      )}
    </>
  )
}

const policyTabStyles = StyleSheet.create({
  container: {
    marginTop: 20
  }
})
