import { ZIO } from '@mxt/zio'
import { assets, DatePicker, ErrorHandling, GeneralService, Input, SelectSearch, useMobile } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import * as AddLackOfPayeeForm from '../add-lack-of-payee/add-lack-of-payee-form'
import { generalStyles as styles } from './styles'
import _ from 'lodash'
interface Props {
  form: UseFormReturn<AddLackOfPayeeForm.Form>
  editableForm?: boolean
}

export const PaymentGeneralInfo: React.FC<Props> = (props) => {
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation('claim')
  const { form, editableForm } = props

  const isRequired = !!form.watch('payeeIsNotPolicyOwner')

  const gender = React.useMemo(
    () => [
      {
        value: 'F',
        label: t('common:Female')
      },
      {
        value: 'M',
        label: t('common:Male')
      }
    ],
    [i18n.languages]
  )

  const relationships =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          // label: i18n.language === 'vi' ? item.nameVn : item.nameEn
          label: i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN,
          labelVn: item.relationshipVN
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const countries =
    pipe(
      GeneralService.getCountries,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const occupations =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  return (
    <>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="relationshipWithPO"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020009', { field: t('requestInfo:RelationshipWithPolicyOwner') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              const valueRelationships = value ? relationships.find((x) => x.value === value.value) : value
              return (
                <SelectSearch
                  required
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:RelationshipWithPolicyOwner')}
                  options={relationships}
                  value={valueRelationships}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('common:Select')}
                  errorMessage={error?.message}
                  key={value?.value}
                />
              )
            }}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="dob"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020009', { field: t('contact:DOB') })}`
              },
              validate: (date) =>
                (date && date.getTime() < new Date().getTime()) ||
                `${t('message:MS050013', { field: t('contact:DOB') })}`
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                required
                label={t('contact:DOB')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                disabled={editableForm}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="gender"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020009', { field: t('contact:Gender') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              const valueGender = value ? gender.find((x) => x.value === value.value) : value
              return (
                <SelectSearch
                  required
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('contact:Gender')}
                  options={gender}
                  value={valueGender}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('common:Select')}
                  errorMessage={error?.message}
                  key={value?.value}
                />
              )
            }}
          />
        </View>
      </View>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="occupation"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020009', { field: t('contact:Occupation') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('contact:Occupation')}
                options={occupations}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={editableForm}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="residenceCountry"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020009', { field: t('contact:ResidenceCountry') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('contact:ResidenceCountry')}
                options={countries}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={editableForm}
              />
            )}
          />
        </View>
      </View>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="nationality"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020009', { field: t('contact:Nationality') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('contact:Nationality')}
                options={countries}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={editableForm}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.twoThirdField}>
          <Controller
            name="address"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020001', { field: t('contact:Address') })}`
              },
              pattern: {
                // value: /^[^*&$#\\<>|~`!@%^()';.:\[\]"+=?]*$/g,
                value: /^[^*&$#\\<>|]*$/g,
                message: t('message:MS050223')
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                required
                title={t('contact:Address')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                maxLength={250}
                disabled={editableForm}
              />
            )}
          />
        </View>
      </View>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="nationality2"
            control={form.control}
            rules={{
              required: {
                value: isRequired,
                message: `${t('message:MS020001', { field: t('contact:Nationality') + ' 2' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('contact:Nationality') + ' 2'}
                options={countries}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={editableForm}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.twoThirdField}>
          <Controller
            name="foreignAddress"
            control={form.control}
            rules={{
              required: {
                value:
                  isRequired &&
                  (form.watch('nationality')?.value !== 'VN' || form.watch('nationality2')?.value !== 'VN'),
                message: `${t('message:MS020001', { field: t('contact:ForeignAddress') })}`
              },
              pattern: {
                // value: /^[^*&$#\\<>|~`!@%^()';.:\[\]"+=?]*$/g,
                value: /^[^*&$#\\<>|]*$/g,
                message: t('message:MS050223')
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                required={form.watch('nationality')?.value !== 'VN' || form.watch('nationality2')?.value !== 'VN'}
                title={t('contact:ForeignAddress')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                maxLength={250}
                disabled={editableForm}
              />
            )}
          />
        </View>
      </View>
    </>
  )
}
