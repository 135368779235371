import { enumC } from '@mxt/zio/codec'

export enum CashOutType {
  PRUCASHBENEFIT = 'PRUCASHBENEFIT',
  MATURITYBENEFIT = 'MATURITYBENEFIT',
  SUSPENDAMOUNT = 'SUSPENDAMOUNT'
}

export const CashOutTypeC = enumC(CashOutType)

export enum CashOutPrucashType {
  LOAN = 'LOAN',
  PAYPREMIUM = 'PAYPREMIUM',
  TOPUP = 'TOPUP'
}

export const CashOutPrucashTypeC = enumC(CashOutPrucashType)
