import { PaymentType } from '../PaymentType'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const InternalPayments = [
  PaymentType.PAYPREMIUM,
  PaymentType.PREMIUM,
  PaymentType.PAYLOAN,
  PaymentType.REPAY_APL,
  PaymentType.REPAY_OPL,
  PaymentType.OTHER,
  PaymentType.REINSTATEMENT,
  PaymentType.TOPUP,
  PaymentType.PAID_AT_BANK,
  PaymentType.CASH_AT_COUNTER
]

export const PaymentOption = t.type({
  paymentOption: t.type({
    amount: Maybe(t.number),
    currency: Maybe(t.string)
  })
})
export type PaymentOption = t.TypeOf<typeof PaymentOption>

export namespace PaymentData {
  export const BankTransfer = t.intersection([
    t.type({
      bankAccount: Maybe(
        t.type({
          accountName: Maybe(t.string),
          accountNo: Maybe(t.string),
          bankName: Maybe(t.string),
          bankCode: Maybe(t.string),
          branchCode: Maybe(t.string)
        })
      ),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      ),
      type: t.literal(PaymentType.BANKTRANSFER),
      attributesExt: Maybe(
        t.type({
          PAYEE_INFO: Maybe(
            t.type({
              paymentMethod: Maybe(t.string),
              isPayeeNotPO: Maybe(t.boolean),
              isNewClient: Maybe(t.boolean),
              dob: Maybe(t.string),
              gender: Maybe(t.string),
              phone: Maybe(t.string),
              email: Maybe(t.string),
              payeeName: Maybe(t.string),
              issuedDate: Maybe(t.string),
              issuedBy: Maybe(t.string),
              salutation: Maybe(t.string),
              married: Maybe(t.string),
              occupation: Maybe(t.string),
              relationshipWithPO: Maybe(t.string),
              nationality: Maybe(t.string),
              address: Maybe(t.string),
              nationality2: Maybe(t.string),
              foreignAddress: Maybe(t.string),
              foreignCountry: Maybe(t.string),
              residenceCountry: Maybe(t.string),
              name: Maybe(t.string),
              idNumber: Maybe(t.string),
              dod: Maybe(t.string),
              surName: Maybe(t.string),
              giveName: Maybe(t.string),
              city: Maybe(t.string),
              district: Maybe(t.string),
              ward: Maybe(t.string),
              street: Maybe(t.string)
            })
          )
        })
      )
    }),
    PaymentOption
  ])
  export const PaidAtBank = t.intersection([
    t.type({
      paymentOption: Maybe(
        t.type({
          currency: Maybe(t.string),
          amount: Maybe(t.number)
        })
      ),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string),
          status: Maybe(t.string),
          totalPremium: Maybe(t.number)
        })
      ),
      attributesExt: Maybe(
        t.type({
          PAYEE_INFO: Maybe(
            t.type({
              paymentMethod: Maybe(t.string),
              isPayeeNotPO: Maybe(t.boolean),
              dob: Maybe(t.string),
              gender: Maybe(t.string),
              phone: Maybe(t.string),
              email: Maybe(t.string),
              issuedDate: Maybe(t.string),
              issuedBy: Maybe(t.string),
              issueCode: Maybe(t.string),
              salutation: Maybe(t.string),
              married: Maybe(t.string),
              occupation: Maybe(t.string),
              relationshipWithPO: Maybe(t.string),
              nationality: Maybe(t.string),
              address: Maybe(t.string),
              nationality2: Maybe(t.string),
              foreignAddress: Maybe(t.string),
              foreignCountry: Maybe(t.string),
              isNewClient: Maybe(t.boolean),
              residenceCountry: Maybe(t.string),
              payeeName: Maybe(t.string),
              name: Maybe(t.string),
              idNumber: Maybe(t.string),
              dod: Maybe(t.string),
              surName: Maybe(t.string),
              giveName: Maybe(t.string),
              city: Maybe(t.string),
              district: Maybe(t.string),
              ward: Maybe(t.string),
              street: Maybe(t.string)
            })
          )
        })
      ),
      type: t.literal(PaymentType.PAID_AT_BANK),
      bankAccount: Maybe(
        t.type({
          bankCode: Maybe(t.string),
          bankName: Maybe(t.string),
          branchCode: Maybe(t.string),
          branchName: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const CashAtCounter = t.intersection([
    t.type({
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string),
          status: Maybe(t.string),
          totalPremium: Maybe(t.number)
        })
      ),
      attributesExt: Maybe(
        t.type({
          PVA_CASH_OUT_OPTION_INFO: t.type({
            officeCode: Maybe(t.string),
            officeType: Maybe(t.string)
          }),
          PAYEE_INFO: Maybe(
            t.type({
              paymentMethod: Maybe(t.string),
              isPayeeNotPO: Maybe(t.boolean),
              dob: Maybe(t.string),
              gender: Maybe(t.string),
              phone: Maybe(t.string),
              email: Maybe(t.string),
              salutation: Maybe(t.string),
              married: Maybe(t.string),
              occupation: Maybe(t.string),
              relationshipWithPO: Maybe(t.string),
              nationality: Maybe(t.string),
              address: Maybe(t.string),
              nationality2: Maybe(t.string),
              foreignAddress: Maybe(t.string),
              foreignCountry: Maybe(t.string),
              isNewClient: Maybe(t.boolean),
              residenceCountry: Maybe(t.string),
              payeeName: Maybe(t.string),
              name: Maybe(t.string),
              idNumber: Maybe(t.string),
              dod: Maybe(t.string),
              surName: Maybe(t.string),
              giveName: Maybe(t.string),
              city: Maybe(t.string),
              district: Maybe(t.string),
              ward: Maybe(t.string),
              street: Maybe(t.string)
            })
          )
        })
      ),
      type: t.literal(PaymentType.CASH_AT_COUNTER),
      bankAccount: Maybe(
        t.type({
          bankCode: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  // export const EWallet = t.intersection([
  //   t.type({
  //     bankAccount: t.type({
  //       bankName: t.string,
  //       branchCode: t.string
  //     }),
  //     policy: Maybe(
  //       t.type({
  //         policyNo: Maybe(t.string)
  //       })
  //     ),
  //     type: t.literal(PaymentType.EWALLET)
  //   }),
  //   PaymentOption
  // ])
  export const EWalletMomo = t.intersection([
    t.type({
      type: t.literal(PaymentType.MOMO),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string),
          status: Maybe(t.string),
          totalPremium: Maybe(t.number)
        })
      ),
      attributesExt: Maybe(
        t.type({
          PAYEE_INFO: Maybe(
            t.type({
              paymentMethod: Maybe(t.string),
              isPayeeNotPO: Maybe(t.boolean),
              dob: Maybe(t.string),
              gender: Maybe(t.string),
              phone: Maybe(t.string),
              email: Maybe(t.string),
              salutation: Maybe(t.string),
              married: Maybe(t.string),
              occupation: Maybe(t.string),
              relationshipWithPO: Maybe(t.string),
              nationality: Maybe(t.string),
              address: Maybe(t.string),
              nationality2: Maybe(t.string),
              foreignAddress: Maybe(t.string),
              foreignCountry: Maybe(t.string),
              isNewClient: Maybe(t.boolean),
              residenceCountry: Maybe(t.string),
              payeeName: Maybe(t.string),
              name: Maybe(t.string),
              idNumber: Maybe(t.string),
              dod: Maybe(t.string),
              surName: Maybe(t.string),
              giveName: Maybe(t.string),
              city: Maybe(t.string),
              district: Maybe(t.string),
              ward: Maybe(t.string),
              street: Maybe(t.string)
            })
          )
        })
      )
    }),
    PaymentOption
  ])
  export const PayPremium = t.intersection([
    t.type({
      type: t.literal(PaymentType.PAYPREMIUM),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const Premium = t.intersection([
    t.type({
      type: t.literal(PaymentType.PREMIUM),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const PayLoan = t.intersection([
    t.type({
      type: t.union([t.literal(PaymentType.PAYLOAN), t.literal(PaymentType.LOAN)]),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const TopUp = t.intersection([
    t.type({
      type: t.literal(PaymentType.TOPUP),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const Reinstatement = t.intersection([
    t.type({
      type: t.literal(PaymentType.REINSTATEMENT),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])

  export const RepayOpl = t.intersection([
    t.type({
      type: t.literal(PaymentType.REPAY_OPL),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const RepayApl = t.intersection([
    t.type({
      type: t.literal(PaymentType.REPAY_APL),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const Other = t.intersection([
    t.type({
      type: t.literal(PaymentType.OTHER),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])

  export const NoType = t.intersection([
    t.type({
      type: t.union([t.undefined, t.null]),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])
  export const Reins = t.intersection([
    t.type({
      type: t.literal(PaymentType.REINSTATEMENT),
      policy: Maybe(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    }),
    PaymentOption
  ])

  export const CashOutList = t.array(
    t.union([
      PaymentData.BankTransfer,
      PaymentData.EWalletMomo,
      PaymentData.PayPremium,
      PaymentData.Premium,
      PaymentData.PayLoan,
      PaymentData.TopUp,
      PaymentData.Reinstatement,
      PaymentData.NoType,
      PaymentData.RepayOpl,
      PaymentData.RepayApl,
      PaymentData.Other,
      PaymentData.Reins,
      PaymentData.PaidAtBank,
      PaymentData.CashAtCounter
    ])
  )

  export type CashOutList = t.TypeOf<typeof CashOutList>
}
