import { OptionField } from './OptionField'

export namespace Phone {
  export const TelCode: Array<OptionField> = [
    {
      value: '84',
      label: '+84'
    },
    {
      value: '850',
      label: '+850'
    },
    {
      value: '852',
      label: '+852'
    }
  ]
}
