import { CallOutDetailContext, CategoryTabInfo, OBCategoryTab, OBPolicyData } from '@pulseops/outbound'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { ProductTypeC } from '@pulseops/inbound'
import { OBPolicyTab } from '../ob-rmd-special-info/OBPolicyTab'
import { OBOthersMaturityTab } from './OBOthersMaturityTab'

export const OBMaturitySpecialInformation = () => {
  const { t, i18n } = useTranslation()
  const [categoryTabIndex, setCategoryTabIndex] = React.useState(0)
  const [categoryTabList, setCategoryTabList] = React.useState<CategoryTabInfo[]>([])
  const [productInfo, setProductInfo] = React.useState<ProductTypeC>()
  const { permissions, policyNumber, clientNumber, caseId } = React.useContext(CallOutDetailContext)
  const isFocused = useIsFocused()

  React.useEffect(() => {
    if (isFocused) {
      setCategoryTabIndex(0)
    } else {
      setProductInfo(undefined)
    }
  }, [isFocused])

  const checkTabViewPermission = () => {
    const listTabPermission: CategoryTabInfo[] = []
    listTabPermission.push({
      label: t('Outbound:OBSpecialInfo:Policy'),
      key: '1'
    })
    listTabPermission.push({
      label: t('Outbound:OBSpecialInfo:OtherTab'),
      key: '2'
    })

    setCategoryTabList(listTabPermission)
  }

  React.useEffect(() => {
    checkTabViewPermission()
  }, [permissions, i18n.language, productInfo])

  const getTabKey = (tabIndex: number) => {
    const tabShow = categoryTabList[tabIndex]
    return tabShow?.key ?? ''
  }

  const getComponentByTabIndex = () => {
    switch (getTabKey(categoryTabIndex)) {
      case '1':
        return <OBPolicyTab setProductInfo={setProductInfo}></OBPolicyTab>
      case '2':
        return <OBOthersMaturityTab policyNumber={policyNumber} clientNumber={clientNumber} caseId={caseId}/>
      default:
        return <></>
    }
  }

  return (
    <View style={specialStyles.container}>
      <OBCategoryTab
        dataSource={categoryTabList}
        onChangeTab={(index) => {
          setCategoryTabIndex(index)
        }}
        activeTabIndex={categoryTabIndex}
      ></OBCategoryTab>
      {isFocused ? getComponentByTabIndex() : <></>}
    </View>
  )
}
const specialStyles = StyleSheet.create({
  container: {
    marginTop: 24
  }
})
