import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import { Alert } from '@pulseops/common'
import { assets, Button } from '@pulseops/business/core'

export const UploadButton = ({ onUpload, loading }: { onUpload: (file: File) => void; loading?: boolean }) => {
  const { t } = useTranslation()

  const inputRef = React.createRef<HTMLInputElement>()

  return (
    <>
      <Button
        title={t('business:insurance_handbook')}
        type="outline"
        icon={<assets.IconFolder />}
        onPress={() => {
          inputRef.current?.click()
        }}
        loading={loading}
      />
      <input
        ref={inputRef}
        hidden
        type="file"
        multiple={false}
        accept=".pdf"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target.files?.item(0)
          if (file && file.name.split('.'))
            if (file) {
              pipe(
                file.name.split('.'),
                A.last,
                O.filter((ext) => ext === 'pdf'),
                E.fromOption(() => 'business:MS000028'),
                E.chain(() =>
                  pipe(
                    file,
                    E.fromPredicate(
                      (f) => f.size / 1024 / 1024 < 10,
                      () => 'business:MS000014'
                    )
                  )
                ),
                E.fold((message) => {
                  Alert.alert(t(message))
                }, onUpload)
              )
              event.target.files = null
              event.target.value = ''
            }
        }}
      />
    </>
  )
}
