import { Nullable } from '@mxt/zio/codec'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import * as t from 'io-ts'
import { Benefit } from './benefit'

export const HealthCareBenefitC = t.type({
  benefitKey: t.string,
  name: t.string,
  hcPlan: t.string
})
export type HealthCareBenefit = t.TypeOf<typeof HealthCareBenefitC>

export const AddBenefitC = t.type({
  benefitKey: t.string,
  name: t.string,
  geoCoverage: t.string,
  sumAssured: t.number
})
export type AddBenefit = t.TypeOf<typeof AddBenefitC>

export const BasicBenefitC = t.type({
  benefitKey: t.string,
  name: t.string,
  geoCoverage: t.string,
  sumAssured: t.number
})
export type BasicBenefit = t.TypeOf<typeof BasicBenefitC>

const productC = <C extends t.Mixed>(benefitC: C) =>
  t.type({
    name: t.string,
    code: t.string,
    benefits: t.array(benefitC)
  })

const BasicProductC = productC(BasicBenefitC)

export type BasicProduct = t.TypeOf<typeof BasicProductC>

export const AddProductC = productC(AddBenefitC)

export type AddProduct = t.TypeOf<typeof AddProductC>

export const HealthCareProductC = productC(HealthCareBenefitC)

export type HealthCareProduct = t.TypeOf<typeof HealthCareProductC>

export const GroupProductC = t.type({
  basic: BasicProductC,
  add: Nullable(AddProductC),
  healthCare: Nullable(HealthCareProductC)
})
export type GroupProduct = t.TypeOf<typeof GroupProductC>

export const GroupProduct = (benefits: Benefit[]): GroupProduct | null => {
  const basic = pipe(
    benefits,
    A.filter((b) => !!b.ddProductCodeB),
    NEA.groupBy((b) => b.ddProductCodeB),
    R.toArray,
    A.head,
    O.map(([code, benefits]): BasicProduct => {
      const benefit = NEA.head(benefits)
      return {
        name: benefit.ddProductNameB,
        code,
        benefits: pipe(
          benefits,
          NEA.map(
            (p): BasicBenefit => ({
              benefitKey: p.benefitKey,
              name: p.clazz,
              geoCoverage: p.geographicCoverageB,
              sumAssured: p.sumAssuredB
            })
          )
        )
      }
    }),
    O.toNullable
  )

  const add: AddProduct | null = pipe(
    benefits,
    A.filter((b) => !!b.ddProductCodeR),
    NEA.groupBy((b) => b.ddProductCodeR || '-'),
    R.toArray,
    A.head,
    O.map(([code, benefits]): AddProduct => {
      const benefit = NEA.head(benefits)
      return {
        name: benefit.ddProductNameR || '-',
        code,
        benefits: pipe(
          benefits,
          NEA.map(
            (p): AddBenefit => ({
              benefitKey: p.benefitKey,
              name: p.clazz,
              geoCoverage: p.geographicCoverageR || '-',
              sumAssured: p.sumAssuredR || 0
            })
          )
        )
      }
    }),
    O.toNullable
  )

  const healthCare: HealthCareProduct | null = pipe(
    benefits,
    A.filter((b) => !!b.productCodeHCR),
    NEA.groupBy((b) => b.productCodeHCR || ''),
    R.toArray,
    A.head,
    O.map(([code, benefits]): HealthCareProduct => {
      const benefit = NEA.head(benefits)
      return {
        name: benefit.productNameHCR || '-',
        code,
        benefits: pipe(
          benefits,
          NEA.map(
            (p): HealthCareBenefit => ({
              benefitKey: p.benefitKey,
              name: p.clazz,
              hcPlan: p.healthcarePlan || '-'
            })
          )
        )
      }
    }),
    O.toNullable
  )

  return basic && { basic, add, healthCare }
}
