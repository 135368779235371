import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { ta } from 'date-fns/locale'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export namespace OBCallOutPopupService {
  export const CallReceiverInfo = t.type({
    nameVi: t.string,
    nameEn: t.string,
    code: t.string
  })
  export const CallScriptInfo = t.type({
    code: t.string,
    docNo: t.string,
    docId: t.string,
    description: t.string,
    saleChannelCode: t.string,
    isDefaultOfCallScript: t.boolean,
    transactionTypeWF: Maybe(t.string)
  })

  export const CallingResultInfo = t.type({
    nameVi: t.string,
    nameEn: t.string,
    code: t.string,
    hasCallBackTime: Maybe(t.boolean),
    isManualSuspend: Maybe(t.boolean),
    isNACallReceiverAccepted: Maybe(t.boolean),
    isNonPOCallReceiverAccepted: Maybe(t.boolean),
    isPOCallReceiverAccepted: Maybe(t.boolean),
    isRelativeCallReceiverAccepted: Maybe(t.boolean)
  })

  export const CallOutDetailInput = t.type({
    businessKey: t.string,
    processInstanceId: t.string,
    policyNumber: t.string,
    assignee: t.string,
    teamLeader: t.string,
    transactionType: t.string,
    calledDate: t.string,
    savedDate: t.string,
    createdBy: t.string,
    createdDate: t.string,
    updatedBy: t.string,
    updatedDate: t.string,
    clientNumber: t.string,
    isChangeContactInfo: t.boolean,
    callingInfoDTO: t.type({
      callReceiver: t.string,
      callingMethod: t.string,
      callbackTime: t.string,
      // doNotContacts: t.string,
      callIndicator: t.string,
      callingResult: t.string,
      quickNote: t.string,
      callScript: t.string,
      hint: t.string,
      phoneNumber: t.string,
      callingDuration: t.string,
      connectionStatus: t.string,
      callId: t.string
    }),
    sendingFileInfoDTO: t.type({
      sendingType: t.string,
      guidelineDocument: t.string,
      communicationHistory: t.string
    })
  })

  export const GuideDocumentInfo = t.type({
    categoryCode: t.string,
    transactionType: t.string,
    processDecision: t.string,
    pushCode: t.string,
    pushCodeName: t.string
  })

  export const CallOtherDocumentInfo = t.type({
    url: t.string,
    metaData: t.type({
      docid: t.string,
      maindoc: Maybe(t.string),
      subdoc: Maybe(t.string)
    })
  })

  export const SendCommunicationInfo = t.type({
    processInstanceId: t.string,
    policyNumber: t.string,
    pushCode: t.string,
    channel: t.string,
    requestTime: t.string,
    callId: t.string,
    feature: t.string
  })

  export const DoNotCallInfo = t.type({
    id: Maybe(t.string),
    code: t.string,
    nameVi: t.string,
    nameEn: t.string,
    codeLas: Maybe(t.string)
  })

  export const CallingMethodInfo = t.type({
    code: t.string,
    nameVi: t.string,
    nameEn: t.string
  })

  export const MakeCallResponse = t.type({
    data: Maybe(
      t.type({
        callId: Maybe(t.string),
        context: Maybe(t.string),
        faultCode: Maybe(t.string)
      })
    ),
    code: Maybe(t.string),
    message: Maybe(t.string)
  })

  export const callingSearchInfo = t.type({
    startDate: t.string,
    duration: Maybe(t.number),
    parties: Maybe(t.array(t.string)),
    callId: Maybe(t.string),
    direction: Maybe(t.string),
    interactionId: t.string,
    resultCode: Maybe(t.string),
    resultMessage: Maybe(t.string)
  })

  export const SearchCallResponse = t.type({
    data: Maybe(
      t.type({
        callId: Maybe(t.string),
        context: Maybe(t.string),
        faultCode: Maybe(t.string),
        callingSearchList: Maybe(t.array(callingSearchInfo))
      })
    ),
    code: Maybe(t.string),
    message: Maybe(t.string)
  })

  export const ReleaseCallResponse = t.type({
    data: Maybe(
      t.type({
        callId: Maybe(t.string),
        context: Maybe(t.string),
        faultCode: Maybe(t.string)
      })
    ),
    code: Maybe(t.string),
    message: Maybe(t.string)
  })

  export const ReplayCallResponse = t.type({
    data: Maybe(
      t.type({
        callId: Maybe(t.string),
        context: Maybe(t.string),
        faultCode: Maybe(t.string),
        interactionId: Maybe(t.string),
        recordLink: Maybe(t.string)
      })
    ),
    code: Maybe(t.string),
    message: Maybe(t.string)
  })

  export const ExtensionNumberReponse = t.type({
    staffEmail: t.string,
    blackList: t.string,
    extensionCode: t.string
  })

  export const A7s7Config = t.type({
    transactionTypeWF: t.string,
    // callOutCode: Maybe(t.string),
    callReceiver: Maybe(t.string)
  })

  export const A7s8Config = t.type({
    transactionTypeWF: t.string,
    callReceiver: t.string,
    callOutCode: t.string
  })

  export const A7s3Config = t.type({
    functionCode: t.string,
    functionName: t.string,
    functionDescription: t.string
  })

  export const A7s9Config = t.type({
    callOutCode: t.string,
    functionCode: t.string
  })

  export const A7s2Config = t.type({
    callReceiverCode: t.string,
    haveCallResultDetails: t.boolean
  })

  export const A7s10RequestInfo = t.type({
    transactionTypeWF: t.string,
    idcType: t.string,
    callReceiver: t.string,
    callOutCode: t.string,
    callOutCount: t.number,
    isCallBack: t.string
  })

  export type CallReceiverInfo = t.TypeOf<typeof CallReceiverInfo>
  export type CallScriptInfo = t.TypeOf<typeof CallScriptInfo>
  export type CallOutDetailInput = t.TypeOf<typeof CallOutDetailInput>
  export type GuideDocumentInfo = t.TypeOf<typeof GuideDocumentInfo>
  export type CallOtherDocumentInfo = t.TypeOf<typeof CallOtherDocumentInfo>
  export type SendCommunicationInfo = t.TypeOf<typeof SendCommunicationInfo>
  export type DoNotCallInfo = t.TypeOf<typeof DoNotCallInfo>
  export type CallingMethodInfo = t.TypeOf<typeof CallingMethodInfo>
  export type CallingResultInfo = t.TypeOf<typeof CallingResultInfo>
  export type A7s7Config = t.TypeOf<typeof A7s7Config>
  export type A7s8Config = t.TypeOf<typeof A7s8Config>
  export type A7s3Config = t.TypeOf<typeof A7s3Config>
  export type A7s9Config = t.TypeOf<typeof A7s9Config>
  export type A7s10RequestInfo = t.OutputOf<typeof A7s10RequestInfo>

  export const getCallReceiverList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s4-call-receiver`)(
        t.type({
          data: Maybe(t.array(CallReceiverInfo)),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseInfo) => (responseInfo.data && responseInfo.data.length > 0 ? responseInfo.data : []))
    )

  export const getCallScriptList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/t17s4-call-script`)(
        t.type({
          data: Maybe(t.array(CallScriptInfo)),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseInfo) => (responseInfo.data && responseInfo.data.length > 0 ? responseInfo.data : []))
    )

  export const getCallingResultList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s0-calling-result`)(
        t.type({
          data: Maybe(t.array(CallingResultInfo)),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseInfo) => (responseInfo.data && responseInfo.data.length > 0 ? responseInfo.data : []))
    )

  export const getCallingResultList1 = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s0-calling-result`)(
        t.type({
          data: Maybe(
            t.array(
              t.type({
                nameVi: t.string,
                nameEn: t.string,
                code: t.string
              })
            )
          ),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseInfo) => (responseInfo.data && responseInfo.data.length > 0 ? responseInfo.data : []))
    )

  export const getGuidelineDocumentList = (transactionType: string) =>
    pipe(
      POApi.get(`cs-api/outbound/table/t10-communication?processDecision=Send&transactionType=${transactionType}`)(
        t.type({
          data: t.array(GuideDocumentInfo),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseInfo) => (responseInfo.data && responseInfo.data.length > 0 ? responseInfo.data : []))
    )

  export const getDoNotCallList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s6-do-not-contact`)(
        t.type({
          data: t.array(DoNotCallInfo),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.data)
      )
    )

  export const getCallingMethodList = (): Task<CallingMethodInfo[]> =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s5-calling-method`)(
        t.type({
          data: t.array(CallingMethodInfo),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.data)
      )
    )

  export const saveCallOutPopupDetail = (inputData: CallOutDetailInput) =>
    pipe(
      POApi.post(`pulseops/api/v1/outbound/call-out/save-call`)(t.type({ message: t.string }))(inputData),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.message)
      )
    )

  export const getDocumentByDocID = (policyNumber: string, docId: string) =>
    pipe(
      POApi.get(`cs-api/outbound/document/${docId}`)(
        t.type({
          data: Maybe(t.array(CallOtherDocumentInfo)),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const sendCommunication = (inputData: SendCommunicationInfo) =>
    pipe(
      POApi.post(`pulseops/api/v1/outbound/send-communication`)(t.string)(inputData),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )

  export const makeAvayaCall = (extensionNumber: string, phoneNumber: string, processIntanceID: string) =>
    pipe(
      POApi.post(`cs-api/avaya-gateway/make-call?extensionNumber=${extensionNumber}&phoneNumber=${phoneNumber}`, {
        headers: { traceid: processIntanceID }
      })(MakeCallResponse)(undefined),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (responseData) => {
          return ZIO.succeed(responseData.data?.callId)
        }
      )
    )
  export const searchAvayaCall = (callID: string, startTime: string, endTime: string, processIntanceID: string) =>
    pipe(
      POApi.get(`cs-api/avaya-gateway/search-call?ucid=${callID}&startTime=${startTime}&endTime=${endTime}`, {
        headers: { traceid: processIntanceID }
      })(SearchCallResponse),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (responseData) => {
          const callingSearchList =
            responseData.data && responseData.data.callingSearchList && responseData.data.callingSearchList.length > 0
              ? responseData.data.callingSearchList
              : []
          return ZIO.succeed(callingSearchList)
        }
      )
    )

  export const releaseAvayaCall = (extensionNumber: string, processIntanceID: string) =>
    pipe(
      POApi.post(`cs-api/avaya-gateway/release-call?extensionNumber=${extensionNumber}`, {
        headers: { traceid: processIntanceID }
      })(ReleaseCallResponse)(undefined),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (responseData) => {
          return ZIO.succeed(responseData.data?.context)
        }
      )
    )

  export const replayAvayaCall = (interactionId: string, processIntanceID: string) =>
    pipe(
      POApi.post(`cs-api/avaya-gateway/replay-call?interactionId=${interactionId}`, {
        headers: { traceid: processIntanceID }
      })(ReplayCallResponse)(undefined),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (responseData) => {
          return ZIO.succeed(responseData.data)
        }
      )
    )

  export const getExtensionNumberList = () =>
    pipe(
      POApi.get(`cs-api/outbound/config-data/t4s15config`)(
        t.type({
          data: t.array(ExtensionNumberReponse)
        })
      ),
      ZIO.map((responseData) => {
        const extensionNumber =
          responseData && responseData.data && responseData.data.length > 0 ? responseData.data[0].extensionCode : ''
        return extensionNumber
      })
    )

  export const getA7s7ConfigList = (transactionType: string) =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s7-config?transactionTypeWF=${transactionType}`)(
        t.type({
          data: t.array(A7s7Config),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )
  export const getA7s8ConfigList = (callReceiverCode: string) =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s8-config?callReceiver=${callReceiverCode}`)(
        t.type({
          data: t.array(A7s8Config),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const getA7s3ConfigList = (funcitionCode: string) =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s3-config?functionCode=${funcitionCode}`)(
        t.type({
          data: t.array(A7s3Config),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const getA7s9ConfigList = (callOutCode: string) =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s9-config?callOutCode=${callOutCode}`)(
        t.type({
          data: t.array(A7s9Config),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const getA7S2ConfigList = (callReceiverCode: string) =>
    pipe(
      POApi.get(`cs-api/outbound/table/a7s2-config?callReceiverCode=${callReceiverCode}`)(
        t.type({
          data: t.array(A7s2Config)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const getA7s10ConfigList = (inputData: A7s10RequestInfo) =>
    pipe(
      POApi.post(`cs-api/outbound/config-data/a7s10-config`)(
        t.type({
          data: t.array(t.string),
          message: Maybe(t.string)
        })
      )({
        transactionTypeWF: inputData.transactionTypeWF,
        idcType: inputData.idcType,
        callReceiver: inputData.callReceiver,
        callOutCode: inputData.callOutCode,
        callOutCount: inputData.callOutCount,
        isCallBack: inputData.isCallBack
      }),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (responseData) => ZIO.succeed(responseData.data)
      )
    )

  export const sendGuideline = (inputData: SendCommunicationInfo) =>
    pipe(
      POApi.post(`communication-rest/guideline/send`)(t.type({ message: t.string }))(inputData),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.message)
      )
    )
}
