import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Text, TouchableOpacity, StyleSheet, Image, View } from 'react-native'
import {
  assets,
  AuthService,
  ErrorHandling,
  GeneralService,
  useTranslator,
  Checkbox,
  Container
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { FontAwesome5 } from '@expo/vector-icons'
import { useIsFocused } from '@react-navigation/native'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableHeader: {
    backgroundColor: '#E5EAEF',
    borderColor: '#D3DCE6'
  }
})

export type SignCheckDialogProps = {
  onClose: (value: any) => void
  onDocReviewed: (value: boolean) => void
  open: boolean
  isRequestForm: boolean
  docReviewed: boolean
  policyNum: string
  processInstanceId: string
  isGICase?: boolean
}

export const SignCheckDialog = (props: SignCheckDialogProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions, tableHeader } = useStyles()
  const { onClose, open } = props
  const [noFileCheck, setNoFileCheck] = React.useState<boolean>(false)
  const [isView, setIsView] = React.useState<boolean>(false)
  const [alreadyCheck, setAlreadyCheck] = React.useState<boolean>(false)
  const [files, setFiles] = React.useState<
    {
      policyNum: string
      mainDoc: string
      subDoc: string
      signture: string
    }[]
  >([])

  const [img, setImg] = React.useState<string>('')
  const [loading, bindLoader] = useLoading()
  const isFocused = useIsFocused()
  const { t } = useTranslation()
  const emptyMess = t('message:MS050079', { policyNum: props.policyNum })
  const [warnErr, setWarnErr] = React.useState<string>('')

  const handleClose = () => {
    onClose(null)
  }

  const registerCheck = (): Promise<any> => {
    return props.isRequestForm
      ? pipe(
        ZIO.succeed(props.onDocReviewed(true)),
        ZIO.tap((_) => {
          setIsView(true)
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
      : pipe(
        alreadyCheck === false ? GeneralService.verAddUserCheckFlag(props.processInstanceId) : ZIO.succeed(true),
        ZIO.tap((_) => {
          setIsView(true)
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
  }

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement('a')
    link.download = name
    link.target = '_blank'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getLink = (url: string) => {
    registerCheck()
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const type = res.headers.get('content-type') || ''
        if (['image/png', '	image/jpg', 'image/jpeg'].includes(type)) {
          setImg(url)
        } else {
          downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
        }
      }),
      ErrorHandling.run()
    )
  }

  const onOk = () => {
    if (files.length === 0) {
      noFileCheck === true
        ? (() => {
          registerCheck().finally(() => {
            handleClose()
          })
        })()
        : setWarnErr(t('message:MS020009', { field: t('SignCheck:SignatureChecked') }))
    } else {
      isView ? handleClose() : setWarnErr(t('message:MS050213'))
    }
  }

  React.useEffect(() => {
    if (isFocused && !props.isGICase) {
      pipe(
        ZIO.zipPar(
          props.isRequestForm
            ? ZIO.succeed(!!props.docReviewed)
            : GeneralService.authFormUserCheckFlag(props.processInstanceId),
          GeneralService.getRequestFormSign(props.policyNum)
        ),
        ZIO.tap(([x, files]) => {
          setNoFileCheck(x)
          setAlreadyCheck(x)
          if (files?.data.length > 0) {
            setFiles(
              files.data[0].signFiles.map((y) => ({
                policyNum: props.policyNum,
                mainDoc: y.metaData.maindoc || '-',
                subDoc: y.metaData.subdoc || '-',
                signture: y.url
              }))
            )
          }
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [props.processInstanceId])



  const popup = useTranslator('SignCheck').t

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <View style={styles.headerContent}>
          <View style={styles.headerFlexCol1}>
            <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>{popup('popupTitle')}</Text>
          </View>
          <View style={styles.headerFlexCol2}>
            <TouchableOpacity
              // style={{ position: 'absolute', right: 20, top: 20 }}
              onPress={() => {
                onClose(null)
              }}
            >
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
        </View>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <Container loading={loading} isSignCheck={true}>
          {files.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={tableHeader}>
                  <TableRow>
                    <TableCell>{t('common:PolicyNumber')}</TableCell>
                    <TableCell>{t('SignCheck:mainDoc')}</TableCell>
                    <TableCell>{t('SignCheck:subDoc')}</TableCell>
                    <TableCell>{t('SignCheck:column')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((row, i) => (
                    <TableRow key={`doc-row-${i}`}>
                      <TableCell>{row.policyNum}</TableCell>
                      <TableCell>{row.mainDoc}</TableCell>
                      <TableCell>{row.subDoc}</TableCell>
                      <TableCell>
                        <Text
                          style={{ color: '#ED1B2E', flexDirection: 'row', alignContent: 'center', padding: 10 }}
                          onPress={() => {
                            getLink(row.signture)
                          }}
                        >
                          <FontAwesome5 name="eye" size="20" color="#ed1b2e"></FontAwesome5>
                          <Text style={{ color: '#ed1b2e', marginLeft: 10 }}>{t('SignCheck:btn')}</Text>
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <View>
              <Text>{emptyMess}</Text>
              <Checkbox
                title={t('SignCheck:SignatureChecked')}
                value={noFileCheck}
                onChange={(val) => setNoFileCheck(val)}
              ></Checkbox>
            </View>
          )}
          {!!img && <Image style={{ width: 800, height: 800 }} source={{ uri: img }}></Image>}
          {((files.length > 0 && !isView) || (files.length <= 0 && !noFileCheck)) && <Text>{warnErr}</Text>}
        </Container>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onOk()}>
          <Text style={{ color: '#FFF' }}>OK</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}

export const styles = StyleSheet.create({
  actionBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    paddingHorizontal: 29,
    paddingVertical: 5.5
  },
  headerContent: {
    flex: 1,
    flexDirection: 'row'
  },
  headerFlexCol1: {
    flex: 82
  },
  headerFlexCol2: {
    flex: 8,
    alignItems: 'flex-end'
  }
})
