import * as React from 'react'
import { useWindowDimensions } from 'react-native'

export const BIG_SCREEN_SIZE = 1194

export const useBigScreenThreshold = (bigScreenThresholdSize: number) => {
  const { width } = useWindowDimensions()
  const [isBigScreen, setIsBigScreen] = React.useState(width >= bigScreenThresholdSize)
  React.useEffect(() => setIsBigScreen(width >= bigScreenThresholdSize), [width])
  return {
    isBigScreen
  }
}
