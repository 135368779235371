import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  ErrorHandling,
  form2,
  Input,
  sharedStyle,
  TransactionType,
  ChangeSumAssuredService,
  ImgUploadMutiple,
  PulseOpsFormat,
  SubmissionService,
  AppContext,
  StorageBlob,
  // GeneralService,
  TaskType
} from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import _ from 'lodash'
import { SC } from '../common'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps, UploadedFilesInfo } from './policy-service-props'
import { NonEmptyString } from 'io-ts-types'
import { useLoading } from '@mxt/zio-react'

const FileMeta = t.type({
  fileName: t.string,
  fileExtension: t.string,
  size: t.number,
  base64: t.string,
  uploadedDate: form2.date.required,
  file: form2.file.required
})
type FileMeta = t.TypeOf<typeof FileMeta>
type FileAttachments = t.Branded<FileMeta[], { readonly FileAttachments: unique symbol }>

type NewSumAssuredBrand = {
  readonly MS050055: unique symbol
  readonly MS050305: unique symbol
  readonly MS050304: unique symbol
  readonly MS050308: unique symbol
  readonly MS050310: unique symbol
}
type NewSumAssured = t.Branded<NonEmptyString, NewSumAssuredBrand>

const FormData = t.type({
  currentSumAssured: form2.string.optional,
  newSumAssured: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('submission:NewSumAssured') })),
    form2.refine(
      (l): l is NewSumAssured => Number(l) !== Number(currentFormData?.currentSumAssured),
      (): string => i18next.t('message:MS050304'),
      'MS050304'
    ),
    form2.refine(
      (l): l is NewSumAssured =>
        currentFormData?.coverageCode.startsWith('U') ||
        currentFormData?.coverageCode.startsWith('V') ||
        Number(l) >= (currentFormData?.minPremium ?? 0),
      (): string => i18next.t('message:MS050308'),
      'MS050308'
    ),
    form2.refine(
      (l): l is NewSumAssured =>
        currentFormData?.coverageCode.startsWith('U') ||
        currentFormData?.coverageCode.startsWith('V') ||
        Number(l) <= (currentFormData?.maxPremium ?? 0),
      (): string => i18next.t('message:MS050308'),
      'MS050308'
    ),
    form2.refine(
      (value): value is NewSumAssured =>
        currentFormData?.coverageCode.startsWith('U') ||
        currentFormData?.coverageCode.startsWith('V') ||
        Number(value) < Number(currentFormData?.currentSumAssured ?? '0'),
      (): string => i18next.t('message:MS050310'),
      'MS050310'
    ),
    // form2.refine(
    //   (value): value is NewSumAssured =>
    //     (!currentFormData?.coverageCode.startsWith('U') && !currentFormData?.coverageCode.startsWith('V')) ||
    //     (!!currentFormData?.minSumAssured && Number(value) >= Number(currentFormData.minSumAssured)),
    //   (): string =>
    //     i18next.t('message:MS050055', {
    //       min: currentFormData?.minSumAssured ?? ''
    //     }),
    //   'MS050055'
    // ),
    // form2.refine(
    //   (value): value is NewSumAssured =>
    //     (!currentFormData?.coverageCode.startsWith('U') && !currentFormData?.coverageCode.startsWith('V')) ||
    //     (!!currentFormData?.minSumAssured && Number(value) <= Number(currentFormData.maxSumAssured)),
    //   (): string =>
    //     i18next.t('message:MS050305', {
    //       max: currentFormData?.maxSumAssured ?? ''
    //     }),
    //   'MS050305'
    // )
  ),
  // minSumAssured: form2.string.optional,
  // maxSumAssured: form2.string.optional,
  currentInstallPremium: form2.string.optional,
  newInstallPremium: form2.string.optional,
  requirePayInAmount: form2.string.optional,
  minPremium: t.number,
  maxPremium: t.number,
  coverageCode: t.string,
  isLAMessage: t.boolean,
  documents: pipe(
    t.array(
      t.type({
        fileName: t.string,
        fileExtension: t.string,
        size: t.number,
        base64: t.string,
        uploadedDate: form2.date.required,
        file: form2.file.required
      })
    ),
    form2.refine(
      (files): files is FileAttachments =>
        currentFormData &&
          currentFormData.newSumAssured &&
          currentFormData.currentSumAssured &&
          Number(currentFormData.newSumAssured) > Number(currentFormData.currentSumAssured)
          ? files.length > 0
          : true,
      () => i18next.t('message:MS020001', { field: i18next.t('submission:StatementOfInsurability') }),
      'FileAttachments'
    )
  )
})
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

type SubmitData = {
  currentSumAssured: number
  newSumAssured: number
  // minimumSumAssured: number
  // maximumSumAssured: number
  currentInstallmentPremium: number
  newInstallmentPremium: number
  requirePayInAmount: number
  documents: {
    name: string
    url: string
    id: string
  }[]
}

let currentFormData: (FormDataRaw & { coverageCode: string; minPremium: number; maxPremium: number }) | null = null

export const ChangeSumAssured = (props: PolicyServiceProps<SubmitData>) => {
  const { t } = useTranslation()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [showFileBlock, setShowFileBlock] = React.useState(false)
  const [isLoading, bindLoader] = useLoading(false)

  const policyNumber = props.policyNumber!
  const defaultValues: FormDataRaw = {
    currentSumAssured: '0', // set when init
    newSumAssured: '', // input
    // minSumAssured: '0', // set when init
    // maxSumAssured: '0', // set when init
    currentInstallPremium: '0', // set when init
    newInstallPremium: '0', // set when input newSumAssured
    requirePayInAmount: '0', // newInstallPremium - suspenseAmount
    minPremium: 0,
    maxPremium: 0,
    coverageCode: '',
    isLAMessage: false,
    documents: []
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })
  currentFormData = Object.assign(currentFormData ?? {}, base.watch())

  const data = pipe(
    ZIO.zipPar(SubmissionService.getPolicy(policyNumber), ChangeSumAssuredService.getDetail(policyNumber)),
    ZIO.map(
      ([
        policyInfo,
        {
          coverageCode,
          curSumAssured,
          minSA,
          maxSA,
          minSAM,
          maxSAM,
          curRenewalPremium,
          suspenseAmount,
          minPremium,
          maxPremium
        }
      ]) => {
        // const billFreq = policyInfo.body.billFreq ? Number(policyInfo.body.billFreq) : 0
        // const basicPrem = Number(policyInfo.body.attributesExt?.basicPremium ?? 0)
        // if (['VLR1', 'VLR4', 'VLR5'].includes(coverageCode)) {
        //   // base.setValue('minSumAssured', Math.floor(billFreq * curRenewalPremium * minSAM).toString())
        //   // base.setValue('maxSumAssured', Math.floor(billFreq * curRenewalPremium * maxSAM).toString())
        //   base.setValue('minSumAssured', Math.floor(billFreq * basicPrem * minSAM).toString())
        //   base.setValue('maxSumAssured', Math.floor(billFreq * basicPrem * maxSAM).toString())
        // } else if (coverageCode.startsWith('U') || coverageCode.startsWith('V')) {
        //   ['ULR4', 'ULR2'].includes(coverageCode) ? base.setValue('minSumAssured', '0') :
        //     base.setValue('minSumAssured', Math.floor(minSA).toString())
        //   base.setValue('maxSumAssured', Math.floor(maxSA).toString())
        // } else {
        //   base.setValue('minSumAssured', '0')
        //   base.setValue('maxSumAssured', '0')
        // }
        base.setValue('currentSumAssured', curSumAssured.toString())
        base.setValue('currentInstallPremium', curRenewalPremium.toString())
        base.setValue('minPremium', minPremium)
        base.setValue('maxPremium', maxPremium)
        base.setValue('coverageCode', coverageCode)

        if (['VLR6', 'VLR7', 'VLR8'].includes(coverageCode)) {
          base.setValue('newInstallPremium', '0')
          base.setValue('requirePayInAmount', (0 - suspenseAmount).toString())
        } else if (coverageCode.startsWith('U') || coverageCode.startsWith('V')) {
          base.setValue('newInstallPremium', curRenewalPremium.toString())
          base.setValue('requirePayInAmount', (curRenewalPremium - suspenseAmount).toString())
        }

        return {
          policyInfo,
          coverageCode,
          curSumAssured,
          minSA,
          maxSA,
          minSAM,
          maxSAM,
          curRenewalPremium,
          suspenseAmount,
          minPremium,
          maxPremium
        }
      }
    ),
    ErrorHandling.runDidUpdate([props.policyNumber])
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    return () => {
      base.reset(defaultValues)
    }
  }, [])

  const newSumAssured = base.watch('newSumAssured')

  const onBlurNewSumAssured = () => {
    if (
      data &&
      newSumAssured &&
      !base.formState.errors.newSumAssured
      // !data.coverageCode.startsWith('U') &&
      // !data.coverageCode.startsWith('V')
    ) {
      pipe(
        // showGlobalLoading(true),
        // () => ChangeSumAssuredService.getQuotationAlteration(policyNumber, Number(newSumAssured)),
        // ZIO.map(({ newPremium }) => {
        //   base.setValue('newInstallPremium', newPremium.toString())
        //   base.setValue('requirePayInAmount', (newPremium - data.suspenseAmount).toString())
        // }),
        // ZIO.tapBoth(
        //   () => ZIO.succeed(showGlobalLoading(false)),
        //   () => ZIO.succeed(showGlobalLoading(false))
        // ),
        // ErrorHandling.run()
        ChangeSumAssuredService.getNewInstallmentPremium(policyNumber, Number(newSumAssured)),
        ZIO.foldM(
          (error) =>
            ZIO.effect(() => {
              if (!!error && !!error.source) {
                base.setValue('isLAMessage', true)
                isShowedLAMessage(true)
                base.setValue('newInstallPremium', '0')
                base.setValue('requirePayInAmount', '0')
              }
            }),
          (success) =>
            ZIO.effect(() => {
              base.setValue('newInstallPremium', success.newTotalInstalmentPremium.toString())
              base.setValue('requirePayInAmount', (success.newTotalInstalmentPremium - data.suspenseAmount).toString())
              base.setValue('isLAMessage', false)
            })
        ),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
    setShowFileBlock(!!data && !!newSumAssured && Number(newSumAssured) > data.curSumAssured)
  }

  const isShowedLAMessage = (flag: boolean) => {
    flag && showToast(t('message:MS050034'), 'error')
    return flag
  }

  const getUploadedFilesInfo = (formData: FormDataRaw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    if (data && Number(formData.newSumAssured) > data.curSumAssured) {
      uploadedFileList.push({
        uploadFiles: formData.documents as FileMeta[],
        transactionType: TransactionType.CHANGE_SUM_ASSURED,
        docTypeCode: 'DPS09',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: props.officeCode ?? ''
      })
    }
    return uploadedFileList
  }

  props.initSubmission({
    validate: async (isContinue) => {
      const _form = await handleSubmit(() => undefined)()
      if (E.isRight(_form) && data && !isShowedLAMessage(base.watch('isLAMessage'))) {
        const form = _form.right

        let fileMeta: StorageBlob.FileContentSubmit[] = []

        // if (!isContinue && Number(form.newSumAssured) > data.curSumAssured) {
        //   showGlobalLoading(true)
        //   fileMeta = await pipe(
        //     GeneralService.getMetaData(TransactionType.CHANGE_SUM_ASSURED || '', 'DPS09'),
        //     ZIO.flatMap((metaDataRes) => {
        //       const metaDataRaw: StorageBlob.MetaDataUpload = {
        //         type: metaDataRes.data.transactionType,
        //         doctype: metaDataRes.data.doctypeEn,
        //         class: metaDataRes.data.classFilenet,
        //         docid: metaDataRes.data.docID,
        //         maindoc: metaDataRes.data.mainDoc,
        //         subdoc: metaDataRes.data.subDoc,
        //         polnum: policyNumber,
        //         batchno: metaDataRes.data.batchNo
        //       }
        //       return StorageBlob.uploadToSubmit(
        //         'PS',
        //         policyNumber
        //       )(
        //         form.documents.map((x) => ({
        //           file: x.file!,
        //           metaData: metaDataRaw,
        //           id: x.uploadedDate.getTime()
        //         }))
        //       )
        //     }),
        //     ErrorHandling.run({})
        //   )
        //   showGlobalLoading(false)
        // }

        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/sum-assured`,
          body: {
            currentSumAssured: data.curSumAssured,
            newSumAssured: Number(form.newSumAssured),
            // minimumSumAssured: Number(form.minSumAssured),
            // maximumSumAssured: Number(form.maxSumAssured),
            currentInstallmentPremium: data.curRenewalPremium,
            newInstallmentPremium: Number(form.newInstallPremium),
            requirePayInAmount: Number(form.requirePayInAmount),
            documents: fileMeta.map((file) => ({
              ...file,
              id: file.id || ''
            }))
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_SUM_ASSURED),
          collerationId: policyNumber,
          transaction: TransactionType.CHANGE_SUM_ASSURED,
          uploadedFilesInfo: getUploadedFilesInfo(form)
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset({
        ...defaultValues,
        currentSumAssured: base.watch('currentSumAssured'),
        // minSumAssured: base.watch('minSumAssured'),
        // maxSumAssured: base.watch('maxSumAssured'),
        currentInstallPremium: base.watch('currentInstallPremium'),
        newInstallPremium: base.watch('newInstallPremium'),
        requirePayInAmount: base.watch('requirePayInAmount')
      })
    }
  })

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:ChangeSumAssured')}</Text>
        <View style={[sharedStyle.sectionContent, { paddingHorizontal: 5 }]}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:CurrentSumAssured')}
                value={toCurrency(base.watch('currentSumAssured'))}
                disabled={true}
              />
            </View>
            {/* <View style={styles.col}>
              <Input
                title={t('submission:MinSumAssured')}
                value={toCurrency(base.watch('minSumAssured'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:MaxSumAssured')}
                value={toCurrency(base.watch('maxSumAssured'))}
                disabled={true}
              />
            </View> */}
            <View style={styles.col}>
              <Controller
                name="newSumAssured"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required
                    title={t('submission:NewSumAssured')}
                    value={value ?? undefined}
                    maxLength={15}
                    onChange={onChange}
                    onBlur={() => {
                      onBlurNewSumAssured()
                      return onBlur()
                    }}
                    inputType="money"
                    disabled={props.isConfirmed}
                    suffix="VND"
                    containerStyle={{ maxWidth: 200 }}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:CurrentInstallPremium')}
                value={toCurrency(base.watch('currentInstallPremium'))}
                disabled={true}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:NewInstallPremium')}
                value={toCurrency(base.watch('newInstallPremium'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:RequirePayInAmount')}
                value={toCurrency(base.watch('requirePayInAmount'))}
                disabled={true}
                inputStyle={{ color: 'rgb(237, 27, 46)', fontWeight: 'bold' }}
              />
            </View>
          </View>
          <View style={styles.row}>
            {showFileBlock && (
              <SC.Container style={{ paddingHorizontal: 16 }}>
                <SC.Padding vertical={10}>
                  <SC.BoldText>
                    {t('submission:StatementOfInsurability')} <SC.ErrorText>*</SC.ErrorText>
                  </SC.BoldText>
                </SC.Padding>
                <Controller
                  name={'documents'}
                  control={base.control}
                  render={({ field: { value, onChange } }) => (
                    <SC.PanelContainer>
                      <ImgUploadMutiple
                        value={
                          value?.map((file) => ({
                            ...file,
                            uploadedDate: file.uploadedDate!,
                            file: file.file!
                          })) || []
                        }
                        onChange={onChange}
                        errorMessage={_.get(base.formState.errors, 'documents.message')}
                      />
                      {base.formState.errors.documents && (
                        <SC.ErrorText>{_.get(base.formState.errors, 'documents.message')}</SC.ErrorText>
                      )}
                    </SC.PanelContainer>
                  )}
                />
              </SC.Container>
            )}
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 15
  }
} as const)

function toCurrency(value: number | string | null | undefined) {
  return PulseOpsFormat.thousandSepartor(value ? Number(value) : 0) + ' VND'
}
