import React from 'react'
import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { TouchableOpacity, View, Text } from 'react-native'
import { assets, ControlProps } from '@pulseops/common'
import { useTranslation } from 'react-i18next'

type Props = ControlProps<string | null> & {
  label?: string
  options?: string[]
  disabled?: boolean
  required?: boolean
  errorMessage?: string
}
const useStylesTextField = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;'
    }
  }
}))

export const SelectBox: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const { t } = useTranslation()
  const classesTextField = useStylesTextField()
  return (
    <View>
      <Select
        IconComponent={() =>
          !props.disabled ? (
            <TouchableOpacity
              // style={[styles.dropdownIcon, filled && { paddingEnd: 8 }, iconStyle]}
              onPress={() => setOpen(true)}
            >
              <assets.ArrowDownDropdownIcon />
            </TouchableOpacity>
          ) : (
            <></>
          )
        }
        //className={sc.root}
        style={styleView.tableCellSelect}
        classes={classesTextField}
        variant={'outlined'}
        placeholder={t('common:Select')}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value as string)
        }}
        onBlur={props.onBlur}
        value={props.value}
      >
        {(props.options || []).map((o, index) => (
          <MenuItem key={index} value={o}>
            {o}
          </MenuItem>
        ))}
      </Select>
      {!!props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
    </View>
  )
}

const styleView = {
  tableCellSelect: {
    width: '100%',
    borderRadius: '6px',
    height: 40,
    paddingRight: 20
  },
  tableCellInput: {
    width: '100%',
    borderRadius: '6px',
    height: 40,
    border: '1px solid #C8C8C8',
    paddingLeft: 15,
    paddingRight: 15
  }
}
