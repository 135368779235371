import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  Select,
  SelectOption,
  Toast,
  AppContext,
  AuthService,
  ErrorHandling
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Color } from '@material-ui/lab'
import { OBTaskService } from '@pulseops/outbound'

export type EndPopupProps = {
  onClose: () => void
  onSuccess: () => void
  open: boolean
  taskId: string
}

export const OBEndPopup = (props: EndPopupProps) => {
  const { onClose, onSuccess, open, taskId } = props
  const { isWide } = useMobile()
  const { t, i18n } = useTranslation()
  const [note, setNote] = useState<string>('')
  const [reason, setReason] = useState<SelectOption | null>(null)
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)

  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const endReasonList = pipe(
    open ? OBTaskService.getEndReasonList() : ZIO.succeed([]),
    ErrorHandling.runDidUpdate([open, i18n.language])
  ) || []

  const handleClose = () => {
    setNote('')
    setReason(null)
    onClose()
  }

  const handleEndAction = () => {
    if (reason) {
      showGlobalLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.flatMap(({ email }) => {
          return OBTaskService.endTaskOB(
            taskId,
            {
              author: email,
              message: note,
              reason: reason.value
            }
          )
        }),
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastInternal(t('requestInfo:EndFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          handleClose()
          showToast(t('requestInfo:EndSuccess'), 'success')
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const reasonOptions = [
    { value: 'R001', en: 'Change status', vi: 'Hợp đồng thay đổi trạng thái' },
    { value: 'R002', en: 'Expired date', vi: 'Hết hạn' },
    { value: 'R003', en: 'Wrong process', vi: 'Lỗi quy trình' },
    { value: 'R004', en: 'System error', vi: 'Lỗi hệ thống' },
    { value: 'R005', en: 'Function not available', vi: 'Chức năng chưa sẵn sàng' },
    { value: 'R006', en: 'Data Wrong', vi: 'Lỗi dữ liệu'  },
    { value: 'R007', en: 'Duplicated case', vi: 'Trùng nghiệp vụ' }
  ]

  return (
    <ModalComponent
      title={t('Outbound:OBTaskManagement:EndCase')}
      centerTitle
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('requestInfo:End'),
          type: 'filled',
          disabled: !reason,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: () => {
            handleEndAction()
          }
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16, width: isWide ? '50%' : '100%' }}>
          <Select
            value={reason}
            onChange={setReason}
            label={t('claim:Reason')}
            required={true}
            options={endReasonList.map((item) => ({
              label: i18n.language === 'vi' ? item.reasonDescVN : item.reasonDesc,
              value: item.reasonCode
            }))}
            placeholder={t('common:Select')}
          />
        </View>
        <TextInput
          title={t('TaskManagement:Comment')}
          value={note}
          onChange={setNote}
          placeholder={t('TaskManagement:WriteComment')}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
