import { assets } from '@pulseops/common'
import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

type OBTickBoxProps = {
  text?: string
  onHandlePressText?: () => void
  isHightLight?: boolean
  checked?: boolean
}

export const OBTickBox = (props: OBTickBoxProps) => {
  return (
    <View style={tickStyles.container}>
      <View style={{ marginRight: 8 }}>
         {props.checked ? <assets.OBTickBox /> : <assets.OBNoTick />}
      </View>
      <View>
        {props.isHightLight && props.onHandlePressText ? (
          <Pressable onPress={() => props.onHandlePressText && props.onHandlePressText()}>
            <Text style={[tickStyles.tickBoxText, props.isHightLight && tickStyles.hightLightText]}>{props.text}</Text>
          </Pressable>
        ) : (
          <Text style={[tickStyles.tickBoxText, props.isHightLight && tickStyles.hightLightText]}>{props.text}</Text>
        )}
      </View>
    </View>
  )
}

const tickStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  tickBoxText: {
    color: '#27272A',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 21
  },
  hightLightText: {
    color: '#5959FF',
    fontWeight: '500',
    textDecorationLine: 'underline'
  }
})