import { SelectOption } from '@pulseops/common'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'

export namespace SEABillingChangeConst {
  export enum ImportType {
    ImportBillingChange = 1,
    ImportPartialWithdramw = 2
  }
  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }

  export enum Report {
    PM = 'PM',
    SM = 'SM'
  }

  export const Status: SelectOption[] = [
    { label: 'All', value: SEABillingChangeModal.Status.ALL },
    { label: 'Duplicate', value: SEABillingChangeModal.Status.DUPLICATE },
    { label: 'Time_out', value: SEABillingChangeModal.Status.TIMEOUT },
    { label: 'Fail', value: SEABillingChangeModal.Status.FAIL },
    { label: 'Success', value: SEABillingChangeModal.Status.SUCCESS }
  ]
  export const StatusVi: SelectOption[] = [
    { label: 'Tất cả', value: SEABillingChangeModal.Status.ALL },
    { label: 'Trùng', value: SEABillingChangeModal.Status.DUPLICATE },
    { label: 'Quá hạn', value: SEABillingChangeModal.Status.TIMEOUT },
    { label: 'Thất bại', value: SEABillingChangeModal.Status.FAIL },
    { label: 'Thành công', value: SEABillingChangeModal.Status.SUCCESS }
  ]

  export const TemplateList = [
    {
      label: 'Excel',
      value: Extension.EXCEL
    },
    {
      label: 'CSV',
      value: Extension.CSV
    }
  ]

  export const displayedColumns = [
    {
      id: 'id',
      name: `BillingChange:No`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'policyNumber',
      name: `BillingChange:Policy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'currentBillingFrequency',
      name: `BillingChange:CurrentBillingFrequency`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 28
    },
    {
      id: 'currentInstallmentPremium',
      name: `BillingChange:CurentInstallmentPremium`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 25
    },
    {
      id: 'newDateEffect',
      name: `BillingChange:EffectiveDateOfNewFrequency`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 25
    },
    {
      id: 'newBillingFrequency',
      name: `BillingChange:NewBillingFrequency`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 25
    },
    {
      id: 'newInstallmentPremium',
      name: `BillingChange:NewInstallmentPremium`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 23
    },
    {
      id: 'createdDate',
      name: `BillingChange:Importdate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 20
    },
    {
      id: 'status',
      name: `BillingChange:Status`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 16
    },
    {
      id: 'reason',
      name: `BillingChange:Failreason`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'importBy',
      name: `BillingChange:UserImplement`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    }
  ]
}
