import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'
const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.JOURNAL_ALTERATION),
        t.literal(TransactionType.JOURNAL_PAYOUT)
      ])
    }),
    codec
  ])

const PolicyDetailsTranfer = t.type({
  code: t.string,
  type: t.string,
  balance: t.number,
  amount: t.number
})

const PolicyDetailsReceive = t.type({
  code: t.string,
  type: t.string,
  amount: t.number
})

const PayableAccount = t.type({
  type: t.string,
  policyTransfer: t.type({
    releaseNumber: t.union([t.string, t.undefined]),
    type: t.string,
    total: t.number,
    policyDetails: t.array(PolicyDetailsTranfer)
  }),
  receivingPolicy: t.type({
    policy: t.string,
    total: t.number,
    policyDetails: t.array(PolicyDetailsReceive)
  })
})

const UnitJournal = t.type({
  type: t.string,
  policyTransfer: t.type({
    policyDetails: t.array(PolicyDetailsTranfer)
  })
})

const PolicyAccountJournal = t.type({
  type: t.string,
  policyTransfer: t.type({
    policyDetails: t.array(PolicyDetailsTranfer)
  })
})

const BonusJournal = t.type({
  type: t.string,
  policyTransfer: t.type({
    effectiveDate: t.string,
    bonus: t.number,
    amount: t.number
  })
})

const RemoveJournal = t.type({
  type: t.string,
  policyTransfer: t.type({
    journalNumber: t.string
  })
})
const OptionalRequest = t.union([BonusJournal, RemoveJournal, PayableAccount, UnitJournal, PolicyAccountJournal])

const payloadPulse4Ops = t.type({
  body: t.type({
    policyNo: t.string,
    category: t.string,
    action: t.string,
    caseID: t.union([t.string, t.undefined]),
    transaction: t.union([t.string, t.undefined]),
    remark: t.union([t.string, t.undefined]),
    optionalRequest: OptionalRequest,
    attributesExt: t.union([
        t.type({
          TOTAL_PAYOUT_AMOUNT: t.number
        }),
        t.undefined
      ])
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

export const TransferJournal = Pulse4Ops
export type TransferJournal = t.TypeOf<typeof TransferJournal>
