import { TransactionType } from '../TransactionType'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([t.literal(TransactionType.UPLOAD_DOCUMENT), t.literal(TransactionType.IMAGING_UPLOAD)])
    }),
    codec
  ])

export const UploadDocument = Base(
  t.type({
    //payload: t.unknown
    //transactionType: t.literal(TransactionType.UPLOAD_DOCUMENT),
    payload: t.type({
      authenOption: Maybe(t.string),
      body: t.type({
        type: t.string,
        batchName: Maybe(t.string),
        requestDetails: t.array(
          t.type({
            agentCode: Maybe(t.string),
            agentName: Maybe(t.string),
            department: t.string,
            documentType: t.string,
            poClientId: t.string,
            poName: Maybe(t.string),
            policyNo: Maybe(t.string),
            proposalNo: Maybe(t.string),
            requestDocuments: t.array(t.string),
            poIdNumber: Maybe(t.string)
          })
        )
      }),
      clientId: t.string,
      createdBy: t.string,
      createdDate: t.string,
      documents: t.array(
        t.type({
          id: t.string,
          name: t.string,
          url: t.string
        })
      ),
      officeCode: t.string,
      primaryPolicyNo: t.string,
      source: t.string
    })
  })
)
export type UploadDocument = t.TypeOf<typeof UploadDocument>
