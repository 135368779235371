import { Customer } from '../Client'
import { Relationship } from '../mock'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { markPhone84 } from './Util'
import { SourceType } from '../SourceType'
import { DuplicateValue } from './ChangeContactInfo'

export interface P4OpsData {
  source: SourceType.PULSE4OPS | SourceType.PRUONLINE | SourceType.OUTBOUND | SourceType.INBOUND
  phoneNumber: string
  officePhone: string
  sms_Ind: string
}

export interface DefaultData {
  source: SourceType.BANCA | SourceType.EKIOSK | SourceType.PRUGREAT | SourceType.PULSE | SourceType.ZALO
  phoneNumber: string
}

export interface ChangePhoneInfo {
  tag: TransactionType.CHANGE_CONTACT_INFO_PHONE
  detail: P4OpsData | DefaultData
  clientName: string
  clientCode: string
  attributesExt?: {
    PHONES_DUP: Array<DuplicateValue>,
    mainPhoneNumber: boolean
  },
  otpTransaction?: {
    otp: string,
    receiverPhone: string
  }
}
export const ChangePhoneInfo = (
  detail: api.ChangePhoneInfo,
  client: Customer,
  clientData: Array<{ clientId: string; clientName: string }>
): ChangePhoneInfo => {
  const common: {
    tag: TransactionType.CHANGE_CONTACT_INFO_PHONE
    clientName: string
    clientCode: string
  } = {
    tag: TransactionType.CHANGE_CONTACT_INFO_PHONE,
    clientName: client.body.name,
    clientCode: client.body.clientId
  }
  switch (detail.source) {
    case SourceType.PRUONLINE:
    case SourceType.BANCA_SEA:
    case SourceType.OUTBOUND:
    case SourceType.INBOUND:      
    case SourceType.PULSE4OPS:
      return {
        ...common,
        detail: {
          source: SourceType.PULSE4OPS,
          phoneNumber: `+${detail.payload.body.contactDetails.PHONE.countryCode} ${detail.payload.body.contactDetails.PHONE.value}`,
          officePhone: `+${detail.payload.body.contactDetails.OFFICE_PHONE.countryCode} ${detail.payload.body.contactDetails.OFFICE_PHONE.value}`,
          sms_Ind: detail.payload.body.contactDetails?.SMS_IND?.value || '-'
        },
        attributesExt: {
          PHONES_DUP:
            detail.payload.body.attributesExt?.PHONES_DUP?.filter((e) => !!e.clientId && !!e.relationshipType).map(
              (e) => ({
                clientId: e.clientId ?? '',
                clientName:
                  clientData && clientData.length > 0
                    ? clientData.find((client) => client.clientId === e.clientId)?.clientName ?? ''
                    : '',
                relationship: e.relationshipType
                  ? Relationship.IndicatorRelations.find((relation) => relation.code === e.relationshipType)?.desEn ??
                    ''
                  : '',
                veriRelationship: e.relationshipType
                  ? Relationship.IndicatorRelations.find((relation) => relation.code === e.relationshipType) ?? {
                      code: e.relationshipType,
                      desEn: '',
                      desVi: ''
                    }
                  : { code: '', desEn: '', desVi: '' }
              })
            ) ?? [],
            mainPhoneNumber: detail.payload.body.attributesExt?.mainPhoneNumber as boolean
        },
        otpTransaction: {
          otp: detail.payload.otpTransaction?.otp ?? '',
          receiverPhone: detail.payload.otpTransaction?.receiverPhone ?? '',
        }
      }
    case SourceType.PULSE:
      return {
        ...common,
        detail: {
          source: detail.source,
          phoneNumber:
            detail.payload.body.countryCode && detail.payload.body.value
              ? `+${detail.payload.body.countryCode} ${detail.payload.body.value}`
              : ''
        }
      }
    default:
      return {
        ...common,
        detail: {
          source: detail.source,
          phoneNumber: detail.payload.body.value ? markPhone84(detail.payload.body.value) : '-'
        }
      }
  }
}
