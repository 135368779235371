import React from 'react'
import styled from 'styled-components/native'
import { ErrorHandling, form2, PulseOpsFormat, assets, useMobile } from '@pulseops/common'
import { BankTransfer } from './bank-transfer-form'
import { Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { Services, Input, PayoutPopup, SelectSearch, Button } from '../core'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
import { theme } from '../theme'

export interface OwnerInfo {
  ownerName: string
  nationalId: string
}
type Props = {
  primaryPolicy: string
  //visible: boolean
  maxAmount: number
  onPress: () => void | undefined
}

export interface PayoutMethodRef {
  //   clearData: () => void
  submit: (onSuccess: (payout: PayoutPopup.PayoutData) => void) => void
}

const _BankTransferView = (props: Props, ref: React.Ref<PayoutMethodRef>) => {
  const { t } = useTranslation(['landingPage', 'common', 'message'])
  const { isWide } = useMobile()
  const [branches, setBranches] = React.useState<{ value: string; label: string }[]>([])
  const [__, bindLoader] = useLoading(false)
  const {
    base: {
      formState: { errors },
      setValue,
      control
    },
    handleSubmit
  } = form2.useForm(BankTransfer.codec, {
    defaultValues: {
      payeeNotPO: false
    }
  })

  React.useEffect(() => {
    setValue('amount', props.maxAmount)
  }, [props.maxAmount])

  const { banks, owner } = pipe(
    ZIO.zipPar(Services.getBank(), Services.getOwnerInfo(props.primaryPolicy)),
    ZIO.map(([banks, owner]) => {
      //console.log(banks)
      const banksOptions = banks.map((bank) => ({
        value: bank.code,
        label: bank.name
      }))
      setValue('idNumber', owner.nationalId)
      setValue('accountName', owner.ownerName)
      return {
        banks: banksOptions,
        owner
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  ) || { banks: [], owner: null }

  const getBankBranch = (bankCode: string) =>
    pipe(
      Services.getBankBranch(bankCode),
      ZIO.tap((branches) => {
        const branchesOptions = branches.map((branch) => ({
          value: branch.code,
          label: branch.name
        }))
        setBranches(branchesOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  React.useImperativeHandle(ref, () => ({
    submit: (cb) => {
      handleSubmit((data) => {
        // const bankName = banks.find((b) => b.value === data.bank)?.label
        // const bankBranchName = branches.find((b) => b.value === data.branch)?.label
        const payout: PayoutPopup.PayoutData = {
          method: PayoutPopup.PayoutMethods.BANKTRANSFER,
          amount: Number(data.amount),
          policyNum: props.primaryPolicy,
          poName: data.accountName,
          bankCode: data.bank?.value ?? '',
          bankName: data.bank?.label ?? '',
          bankAccountNum: data.accountNumber,
          bankAccountName: data.accountName,
          bankBranchCode: data.branch?.value ?? '',
          bankBranchName: data.branch?.label ?? '',
          nationalId: data.idNumber
        }
        cb(payout)
      })()
    }
  }))
  //const { payeeNotPO, amount } = watch()

  const payoutMethod = [
    { field: t('payment_method'), value: t('bank_transfer') },
    { field: t('amount'), value: `${PulseOpsFormat.thousandSepartor(props.maxAmount ?? 0)} VND` },
    { field: t('account_name'), value: owner?.ownerName ?? '' },
    { field: t('id_number'), value: owner?.nationalId ?? '' }
  ]

  return (
    <View>
      <SC.SessionText isWide={isWide}>{t('payout_method')}</SC.SessionText>
      <SC.Container isWide={isWide}>
        <SC.PayOutMethodContainer>
          {payoutMethod.map((item) => (
            <SC.ItemInfoContainer>
              <SC.TitleText isWide={isWide}>{item.field}</SC.TitleText>
              <SC.NormalText isWide={isWide}>{item.value}</SC.NormalText>
            </SC.ItemInfoContainer>
          ))}
        </SC.PayOutMethodContainer>
        <SC.HelpText isWide={isWide}>{t('message:MS050299')}</SC.HelpText>
        <Controller
          control={control}
          name="bank"
          render={({ field: { value, onChange, onBlur } }) => (
            <SC.SelectContainer>
              <SC.SelectSearch
                options={banks || []}
                label={t('bank')}
                value={value ?? null}
                onChange={(value) => {
                  onChange(value)
                  setValue('branch', null)
                  if (value) {
                    getBankBranch(value.value)
                  }
                }}
                required
                onBlur={onBlur}
                errorMessage={_.get(errors, 'bank.message')}
                placeholder={t('common:Select')}
                popupIcon={<assets.ArrowDownDropdownIcon />}
              />
            </SC.SelectContainer>
          )}
        />
        <Controller
          control={control}
          name="branch"
          render={({ field: { value, onChange, onBlur } }) => (
            <SC.SelectContainer>
              <SC.SelectSearch
                options={branches || []}
                label={t('branch')}
                value={value ?? null}
                onChange={(value) => {
                  onChange(value)
                }}
                required
                onBlur={onBlur}
                errorMessage={_.get(errors, 'branch.message')}
                placeholder={t('common:Select')}
                popupIcon={<assets.ArrowDownDropdownIcon />}
              />
            </SC.SelectContainer>
          )}
        />
        <Controller
          control={control}
          name="accountNumber"
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <SC.Input
                label={
                  <SC.TitleTextInput>
                    {t('account_number')}
                    <SC.NormalText isWide={isWide}>*</SC.NormalText>
                  </SC.TitleTextInput>
                }
                onChangeText={(val: any) => {
                  onChange(val.replace(/(\s|\*|&|\$|#|\\|<|>|\|)/g, ''))
                }}
                value={value ?? ''}
                errorMessage={errors?.accountNumber?.message}
                errorStyle={{
                  marginLeft: 0,
                  marginRight: 0
                }}
                placeholder="Nhập"
                onBlur={onBlur}
              />
            )
          }}
        />
        <SC.Footer>
          <Button title={t('confirm')} onPress={props.onPress} />
        </SC.Footer>
      </SC.Container>
    </View>
  )
}

export const BankTransferView = React.forwardRef(_BankTransferView)

const SC = {
  Container: styled(View)<{ isWide?: boolean }>`
    border: 1px solid #d3dce6;
    background: #fff;
    padding: ${(p) => (p.isWide ? '30px 40px' : '20px')};
    margin: ${(p) => (p.isWide ? '10' : '0')}px;
    border-radius: 8px;
    min-width: ${(p) => (p.isWide ? '580px' : '100%')};
    box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
  `,
  PayOutMethodContainer: styled(View)`
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #d3dce6;
    padding-bottom: 10px;
  `,
  SessionText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '32' : '24')}px;
    margin-bottom: 30px;
    margin-left: 10px;
  `,
  TitleText: styled(Text)<{ color?: string; isWide?: boolean }>`
    font-family: ${theme.fonts.main};
    font-weight: 500;
    margin-bottom: 12px;
    font-size: ${(p) => (p.isWide ? '16' : '14')}px;
    color: ${(p) => p.color ?? '#000000'};
  `,
  NormalText: styled(Text)<{ color?: string; isWide?: boolean }>`
    font-family: ${theme.fonts.main};
    font-weight: 700;
    max-width: ${(p) => (p.isWide ? 'auto' : '149px')};
    font-size: ${(p) => (p.isWide ? '16' : '14')}px;
    color: ${(p) => p.color ?? '#000'};
    text-align: right;
  `,
  ItemInfoContainer: styled(View)`
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  HelpText: styled(Text)<{ isWide?: boolean }>`
    color: ${theme.colors.main};
    font-family: ${theme.fonts.main};
    font-weight: 400;
    font-size: 16px;
    margin: ${(p) => (p.isWide ? '30px 0' : '24px 0')};
  `,
  SelectContainer: styled(View)`
    margin-bottom: 20px;
  `,
  SelectSearch: styled(SelectSearch).attrs(() => ({
    labelStyle: {
      fontFamily: theme.fonts.main,
      fontWeight: '700',
      fontSize: 14,
      color: '#000000'
    },
    textInputStyle: {
      fontFamily: theme.fonts.main,
      fontWeight: 400,
      fontSize: 16,
      color: '#000000'
    }
  }))``,
  Input: styled(Input).attrs(() => ({
    containerStyle: { margin: 0, paddingLeft: '0 0 0 0' },
    inputStyle: { color: ' #000000', fontSize: 16, fontWeight: 400 }
  }))``,
  Footer: styled(View)`
    margin-top: 50px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
  `,
  TitleTextInput: styled(Text)<{ color?: string }>`
    font-family: ${theme.fonts.main};
    font-weight: 700;
    font-size: 14px;
    color: ${(p) => p.color ?? '#000000'};
  `
}
