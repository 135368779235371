import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import {
  AppContext,
  DatePicker,
  SelectOption,
  ReportPermission,
  RBAC,
  ErrorHandling,
  SelectSearch,
  assets,
  Input,
  form2,
  AuthService,
  downloadURI,
  DistributionServices
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { UtilitiesStackParamList } from '../utilites-stack-params-list'
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import _ from 'lodash'
import { SaleStructureReportForm } from './SaleStructureReportForm'
import { SaleStructureReportConst } from './SaleStructureReportConst'
import { SaleStructureReportService } from './SaleStructureReportServices'
import { useLoading } from '@mxt/zio-react'

export interface ReportItem {
  no: string
  policyNum: string
  category: string
  transactionType: string
  subServiceType: string
  subTransactionType: string
  createdDate: string
  closeDate: string
  submissionUser: string
  lastUpdateBy: string
  assignee: string
  userGroup: string
  status: string
  source: string
  office: string
  request: string
  newType: string
  newSubType: string
}

export interface SubmissionReportFormData {
  fromDate: Date | null
  toDate: Date | null
  template: SelectOption
  category: SelectOption
  source: SelectOption[]
}

type props = StackScreenProps<UtilitiesStackParamList, 'SaleStructureReportScreen'>

export const SaleStructureReportScreen = ({ navigation }: props) => {
  const { t, i18n } = useTranslation()
  const ht = useTranslation('menu').t
  const formSaleStructure = form2.useForm(SaleStructureReportForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      template: {
        value: SaleStructureReportConst.Extension.EXCEL,
        label: 'Excel'
      }
    }
  })
  const { base, handleSubmit } = formSaleStructure
  const [maxDate] = React.useState<Date>(new Date())
  const [dataSource, setDataSource] = React.useState<SaleStructureReportService.DataList[]>([])
  const isFocused = useIsFocused()
  // const [loading, bindLoader] = useLoading(false)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [submittedData, setSubmittedData] = React.useState<any>({})
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [loading, bindLoading] = useLoading(false)
  const formError = _.get(base.formState.errors, '.message')

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('Utilities')
          }
        },
        {
          title: ht('DistributionServices'),
          navigate: () => {
            navigation.navigate('DistributionServicesScreen')
          }
        },
        {
          title: t('Reports:SaleStructure'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const onViewReportClick = handleSubmit((validated) => {
    setSubmittedData(validated)
    handleData(validated, pageNum, pageSize)
  })

  const transTypeDAOptions = React.useMemo(
    () => SaleStructureReportConst.TransactionList.map((item) => ({ ...item, label: t(item.label) })),
    []
  )

  const handleData = (submitData: any, page: number, size: number) => {
    let data = {
      fromDate: submitData.fromDate ? moment(submitData.fromDate).format('DD/MM/yyyy') : '',
      toDate: submitData.toDate ? moment(submitData.toDate).format('DD/MM/yyyy') : '',
      saleType: submitData.saleType?.value ?? 'All',
      template: submitData.template?.value ?? 'All',
      source: submitData.source?.value ?? 'All',
      email: !!submitData.email ? submitData.email : 'All',
      transactionType: submitData.transactionType?.value ?? 'All',
      status: submitData.status?.value ?? 'All',
      category: 'All',
      pageSize: size,
      pageNum: page
    }
    pipe(
      SaleStructureReportService.getReport(data),
      ZIO.tap((res) => {
        setTotalItem(res.body?.totalElement ?? 0)
        setMessageResponse(res.body?.elements?.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        setDataSource(res.body?.elements ?? [])
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  // export data to exceljs

  const onExcelExport = handleSubmit((validated) => {
    let data = {
      fromDate: validated.fromDate ? moment(validated.fromDate).format('DD/MM/yyyy') : '',
      toDate: validated.toDate ? moment(validated.toDate).format('DD/MM/yyyy') : '',
      saleType: validated.saleType?.value ?? 'All',
      template: validated.template?.value ?? 'All',
      source: validated.source?.value ?? 'All',
      email: validated.email ?? 'All',
      transactionType: validated.transactionType?.value ?? 'All',
      status: validated.status?.value ?? 'All',
      category: 'All'
    }
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, SaleStructureReportService.getReportData(data)),
      ZIO.flatMap(([token, loginType, res]) => {
        return !!res && !!res.body
          ? pipe(
              ZIO.fromPromise(() =>
                fetch('data:application/vnd.openxmlformats-officedocument.speadsheetml.sheet;base64,' + res.body, {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              ),
              ZIO.flatMap((res) =>
                ZIO.zipPar(
                  ZIO.succeed(res),
                  ZIO.fromPromise(() => res.blob())
                )
              ),
              ZIO.map(([res, blob]) => {
                return downloadURI(window.URL.createObjectURL(blob), `Report_Sale_Structure_result_export.xlsx`)
              })
            )
          : ZIO.succeed(showToast(t(`message:MS030029`), 'error'))
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  return (
    <View style={SUREStyle.pageContent}>
      <ScrollView style={[SUREStyle.container]}>
        <View style={SUREStyle.sectionContent}>
          <View style={SUREStyle.sectionRow}>
            <View style={SUREStyle.col_4}>
              <Controller
                name="fromDate"
                control={base.control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('common:SubmissionDateFrom') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:SubmissionDateFrom')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    maxDateMessage={'Date should not be future'}
                    required
                  />
                )}
              />
            </View>
            <View style={SUREStyle.col_4}>
              <Controller
                name="toDate"
                control={base.control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('common:SubmissionDateTo') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:SubmissionDateTo')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    maxDateMessage={'Date should not be future'}
                    required
                  />
                )}
              />
            </View>
            <View style={[SUREStyle.col_4, { marginTop: 6 }]}>
              <Controller
                name="email"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('Reports:Email')}
                    value={value ?? undefined}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={[SUREStyle.col_4, { marginTop: 6 }]}>
              <Controller
                name="saleType"
                control={base.control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:SaleType')}
                    options={SaleStructureReportConst.SaleTypeList}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
          </View>

          <View style={[SUREStyle.sectionRow, SUREStyle.secondLine]}>
            {/* <View style={SUREStyle.col_4}>
              <Controller
                name="status"
                control={base.control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Status')}
                    options={SaleStructureReportConst.StatusList.map((item) => ({
                      label: t(`Reports:${item.label}`),
                      value: item.value
                    }))}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View> */}

            <View style={SUREStyle.col_4}>
              <Controller
                name="template"
                control={base.control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Template')}
                    options={SaleStructureReportConst.TemplateList}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
            <View style={SUREStyle.col_4}>
              <Controller
                name="source"
                control={base.control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Source')}
                    options={SaleStructureReportConst.sourceDAOptions}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
            <View style={SUREStyle.col_4}>
              <Controller
                name="transactionType"
                control={base.control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectSearch
                    label={t('ServiceInquiry:TransactionType')}
                    options={transTypeDAOptions}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value ?? undefined}
                  />
                )}
              />
            </View>
          </View>

          <View style={[SUREStyle.sectionRow, SUREStyle.secondLine]}>
            <View style={[SUREStyle.col_4, { flex: 1, flexDirection: 'row', alignItems: 'center' }]}>
              {permissions.includes(DistributionServices.ViewSalesStructureDistributionServices) && (
                <TouchableOpacity style={{ width: 118, height: 40 }} onPress={onViewReportClick}>
                  <View style={SUREStyle.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('Reports:View')}</Text>
                  </View>
                </TouchableOpacity>
              )}
              {permissions.includes(DistributionServices.ExportSalesStructureDistributionServices) && (
                <TouchableOpacity style={{ width: 118, height: 40, marginLeft: 30 }} onPress={onExcelExport}>
                  <View style={SUREStyle.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('Reports:Export')}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
          {formError && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{formError}</Text>}
        </View>
        {loading && <LinearProgress color="secondary" />}
        {messageResponse ? (
          <View style={{ marginHorizontal: 30 }}>
            <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
          </View>
        ) : (
          dataSource &&
          dataSource.length > 0 && (
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <TableContainer component={Paper} style={{ maxHeight: 350 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#e2e7ea' }}>
                      <TableCell align="center" style={{ fontWeight: 'bold', fontSize: 15 }}>
                        #
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 110, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:IdNo')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 180, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:AgentCode')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:OfficeCode1')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:GAcode1')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:GAtype')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:ServiceBranch')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:RegionCode')}
                      </TableCell>

                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:SaleZone')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:GAcode2')}
                      </TableCell>

                      <TableCell align="left" style={{ minWidth: 120, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:OfficeCode2')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 130, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:Gallerie')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:AgentCategory')}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                        {t('Reports:Ref')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSource.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell align="center">{++i}</TableCell>
                        <TableCell align="left">{row.clientId ?? '-'}</TableCell>
                        <TableCell align="left">{row.agentCode ?? '-'}</TableCell>
                        <TableCell align="left">{row.officeCode1 ?? '-'}</TableCell>
                        <TableCell align="left">{row.gaCode1 ?? '-'}</TableCell>
                        <TableCell align="left">{row.gaType ?? '-'}</TableCell>
                        <TableCell align="left">{row.serviceBranch ?? '-'}</TableCell>
                        <TableCell align="left">{row.regionCode ?? '-'}</TableCell>
                        <TableCell align="left">{row.saleZone ?? '-'}</TableCell>
                        <TableCell align="left">{row.gaCode2 ?? '-'}</TableCell>
                        <TableCell align="left">{row.officeCode2 ?? '-'}</TableCell>
                        <TableCell align="left">{!!row.galleries ? row.galleries : '-'}</TableCell>
                        <TableCell align="left">{!!row.agentCategory ? row.agentCategory : '-'}</TableCell>
                        <TableCell align="left">{!!row.ref ? row.ref : '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNum}
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={pageSize}
                count={totalItem}
                onPageChange={(e, page) => {
                  setPageNum(page)
                  handleData(submittedData, page, pageSize)
                }}
                onRowsPerPageChange={(e) => {
                  const rowsPerPage = Number(e.target.value)
                  setPageSize(rowsPerPage)
                  if (pageNum * rowsPerPage >= totalItem) {
                    setPageNum(0)
                    handleData(submittedData, 0, rowsPerPage)
                  } else {
                    handleData(submittedData, pageNum, rowsPerPage)
                  }
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                component={View}
              ></TablePagination>
            </View>
          )
        )}
      </ScrollView>
    </View>
  )
}

const SUREStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 30
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '22%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  cellWidth200: {
    minWidth: '200px'
  }
})
