import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ErrorHandling, TaskDetailService, History, TaskType } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, View } from 'react-native'

export const HistoryTab = (props: { processId: string, category?: string, p40ProcessInstanceId?: string}) => {
  const [rows, setRows] = React.useState<History[]>([])

  const [loading, bindLoading] = useLoading()

  const { t } = useTranslation()

  pipe(
    TaskDetailService.GetRelatedHistory(props.category === TaskType.IDC ? (props.p40ProcessInstanceId || '') : props.processId),
    ZIO.tap((result) => {
      setRows(result)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  return (
    <ScrollView>
      {loading && <LinearProgress color="secondary" />}
      <View style={{ borderColor: '#D3DCE6', backgroundColor: '#fafafa', borderRadius: 8, borderWidth: 1 }}>
        <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 24 }}>
          <View style={{ width: '33.3333333333%' }}>
            <Text style={[styles.titleTable, styles.font16]}>{t('HistoryTab:Field')}</Text>
          </View>
          <View style={{ width: '33.3333333333%' }}>
            <Text style={[styles.titleTable, styles.font16]}>{t('HistoryTab:OriginalValue')}</Text>
          </View>
          <View style={{ width: '33.3333333333%' }}>
            <Text style={[styles.titleTable, styles.font16]}>{t('HistoryTab:NewValue')}</Text>
          </View>
        </View>
        {rows.map((row, i) => (
          <View
            key={`history-row-${i}`}
            style={{ borderTopColor: '#eee', borderTopWidth: 2, paddingHorizontal: 24, paddingVertical: 8 }}
          >
            <View style={{ width: '100%' }}>
              <Text style={[styles.font16]}>
                {row.name} - (<Text style={{ color: '#ef1c2e' }}>{row.email}</Text>){' '}
                {i === rows.length - 1 ? 'created case' : 'made changes'} - {row.createdDate} - {row.workFlowStage}
              </Text>
            </View>
            {row.fields.map((item, j) => (
              <View style={{ width: '100%', flexDirection: 'row', marginVertical: 2.5 }} key={`history-row-data-${j}`}>
                <View style={{ width: '33.3333333333%' }}>
                  <Text style={[styles.font16]}>{item.name}</Text>
                </View>
                <View style={{ width: '33.3333333333%' }}>
                  <Text style={[styles.font16]}>{item.oldValue}</Text>
                </View>
                <View style={{ width: '33.3333333333%' }}>
                  <Text style={[styles.font16]}>{item.newValue}</Text>
                </View>
              </View>
            ))}
          </View>
        ))}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  titleSectionText: {
    fontSize: 16,
    lineHeight: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  titleTable: {
    color: '#70777e',
    fontWeight: '700'
  },
  font16: {
    fontSize: 16
  }
})
