import { mapTransactionType, SelectOption, SubTaskCount, TaskSource, TransactionType } from '@pulseops/common'
import { CepFilterForm } from '../CEPFormSearch'
export interface QueryTaskOptions {
  subTask?: SubTaskCount
  pageIndex: number
  pageSize?: number
  searchPolicyNum?: string
  filter?: CepFilterForm.Validated
  isTeam?: boolean
  category?: string
  tabIndex?: number
}

export const sourceOptions: SelectOption[] = [
  { label: 'Pulse', value: TaskSource.PULSE },
  { label: 'Banca', value: TaskSource.BANCA },
  { label: 'Banca Sea', value: TaskSource.BANCA_SEA },
  { label: 'Banca Seabank', value: TaskSource.BANCA_SEABANK },
  { label: 'PRUonline', value: TaskSource.PRUONLINE },
  { label: 'Zalo', value: TaskSource.ZALO },
  { label: 'Ekiosk', value: TaskSource.EKIOSK },
  { label: 'Pulse4Ops', value: TaskSource.PULSE4OPS },
  { label: 'Landing page', value: TaskSource.LANDING_PAGE }
]

const mapSourceOptions = new Map<string, string>([
  [TaskSource.PULSE, 'Pulse'],
  [TaskSource.BANCA, 'Banca'],
  [TaskSource.BANCA_SEA, 'Banca Sea'],
  [TaskSource.PRUONLINE, 'Pruonline'],
  [TaskSource.ZALO, 'Zalo'],
  [TaskSource.EKIOSK, 'Ekiosk'],
  [TaskSource.PULSE4OPS, 'Pulse4Ops'],
  [TaskSource.LANDING_PAGE, 'Landing page']
])

export const getSourceLabel = (source: string) => mapSourceOptions.get(source) || source

const TransactionLabel = (type: TransactionType | null): string => (type ? mapTransactionType.get(type) || '-' : '-')

const transactions = (type: TransactionType) => ({
  label: TransactionLabel(type),
  value: type
})

export const transactionTypeOption = [
  transactions(TransactionType.BENEFICIARY_DESIGNATION),
  transactions(TransactionType.CHANGE_CONTACT_INFO_EMAIL),
  transactions(TransactionType.CHANGE_CONTACT_INFO_PHONE),
  transactions(TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL),
  transactions(TransactionType.CHANGE_CLIENT_INFO),
  transactions(TransactionType.CHANGE_CONTACT_INFO_ADDRESS),
  transactions(TransactionType.CHANGE_CONTACT_INFO),
  transactions(TransactionType.CHANGE_SUM_ASSURED),
  transactions(TransactionType.CHANGE_PREMIUM),
  transactions(TransactionType.LOAN),
  transactions(TransactionType.LOAN_STATEMENTS),
  transactions(TransactionType.PRUCASH_PAYMENT),
  transactions(TransactionType.MATURITY),
  transactions(TransactionType.MATURITY_ADVANCE),
  transactions(TransactionType.BILLING_CHANGE),
  transactions(TransactionType.BILLING_FREQUENCY_REVERSAL),
  transactions(TransactionType.REINSTATEMENT),
  transactions(TransactionType.FULL_SURRENDER_REVERSAL),
  transactions(TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT),
  transactions(TransactionType.TOP_UP),
  transactions(TransactionType.EXCESS_PREMIUM),
  transactions(TransactionType.CANCEL_FROM_INCEPTION),
  transactions(TransactionType.CANCEL_RIDER),
  transactions(TransactionType.PARTIAL_WITHDRAWAL),
  transactions(TransactionType.PARTIAL_SURRENDER),
  transactions(TransactionType.FULL_SURRENDER),
  transactions(TransactionType.POLICY_CERTIFICATE),
  transactions(TransactionType.COMPLAINT_AND_INQUIRY),
  transactions(TransactionType.COMPLAINT),
  transactions(TransactionType.INQUIRY),
  transactions(TransactionType.PRODUCT_OPTION_SWITCHING),

  transactions(TransactionType.ZALO_OTHERS),
  transactions(TransactionType.ZALO_CLAIM_DECISION),
  transactions(TransactionType.ZALO_PREMIUM_COLLECTION),
  transactions(TransactionType.ZALO_RECOMMENDATION),
  transactions(TransactionType.ZALO_REQUEST_PROCESS),
  transactions(TransactionType.E_SERVICE_REGISTRATION),
  transactions(TransactionType.GO_ABROAD),
  transactions(TransactionType.SPECIAL_BONUS),
  transactions(TransactionType.SPECIAL_BONUS_CUSTOMER),
  transactions(TransactionType.PREMIUM_REDIRECTION),
  transactions(TransactionType.SIGNATURE_REGISTRATION),
  transactions(TransactionType.EXCELLENT_STUDENT_AWARD),
  transactions(TransactionType.BONUS_SURRENDER),
  transactions(TransactionType.FUND_SWITCHING),
  transactions(TransactionType.STOP_APL),
  transactions(TransactionType.ASSIGNMENT),
  transactions(TransactionType.FATCA_DECLARATION),
  transactions(TransactionType.INHERITANCE),
  transactions(TransactionType.PAID_UP),
  transactions(TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE),
  transactions(TransactionType.PRUKID369),
  transactions(TransactionType.HEALTH_DECLARATION),
  transactions(TransactionType.SCHEDULED_PAYMENT_STATEMENT),

  transactions(TransactionType.COMMON_REQUEST_FORM),
  transactions(TransactionType.RIDER_ALTERATION),
  transactions(TransactionType.MAJOR_CHANGE_IN_FREE_LOOK),
  transactions(TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK),
  transactions(TransactionType.REDATING),
  transactions(TransactionType.PRODUCT_OPTION_SWITCHING)
]
