import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { JournalModel } from './../Journal'

export interface TransferJournal {
  tag: TransactionType.JOURNAL_ALTERATION | TransactionType.JOURNAL_PAYOUT
  clientName: string
  submissionData: JournalModel.JournalSubmitData
}

export const TransferJournal = (detail: api.TransferJournal, poName: string): TransferJournal => {
  const trans = detail.transactionType

  switch (detail.source) {
    case SourceType.PULSE4OPS: {
      return {
        tag: trans,
        clientName: poName,
        submissionData: detail.payload.body
      }
    }
  }
}
