import { useMobile } from '@pulseops/common'
import * as React from 'react'
import { ViewStyle, FlatList, View, StyleSheet, useWindowDimensions, LayoutChangeEvent } from 'react-native'
import { Tab } from 'react-native-elements'

interface Scene {
  title: string
  Component: JSX.Element
}

interface Props {
  initTabIndex?: number
  scenes: Scene[]
  containerStyle?: ViewStyle
  onTabChanged?: (tabIndex: number) => void
}

export const TabView = ({ initTabIndex = 0, scenes = [], containerStyle, onTabChanged }: Props) => {
  const { isWide } = useMobile()
  const { width } = useWindowDimensions()

  const [currentTabIndex, setCurrentTabIndex] = React.useState(initTabIndex)
  const [contentSize, setContentSize] = React.useState<{ width: number; height: number }>({ width: 0, height: 0 })

  const flatlistRef = React.useRef<FlatList>(null)

  React.useEffect(() => onTabChanged && onTabChanged(currentTabIndex), [currentTabIndex])

  const renderTabItem = (scene: Scene, index: number) => {
    const isActive = index == currentTabIndex
    return (
      <Tab.Item
        key={index}
        title={scene.title}
        style={{ width: '100%' }}
        buttonStyle={{ padding: 0 }}
        titleStyle={[
          styles.tabTitle,
          isActive ? styles.tabTitle_Active : styles.tabTitle_Deactive,
          { fontSize: isWide ? 14 : 13 }
        ]}
        containerStyle={[
          styles.tabTitleContainer,
          isActive ? styles.tabTitleContainer_Active : styles.tabTitleContainer_Deactive,
          {
            maxWidth: isWide ? 200 : '100%',
            maxHeight: isWide ? 30 : 40
          }
        ]}
      />
    )
  }

  const renderTab = (item: any) => {
    return (
      <View
        style={{
          width: contentSize.width,
          minHeight: contentSize.height
        }}
        onMoveShouldSetResponder={(e) => {
          e.stopPropagation()
          return false
        }}
      >
        {contentSize.width > 0 && item.Component}
      </View>
    )
  }

  const onTabContentLayout = (e: LayoutChangeEvent) => {
    if (e.nativeEvent.layout.width !== 0) {
      setContentSize({
        width: e.nativeEvent.layout.width,
        height: e.nativeEvent.layout.height
      })
    }
    onTabChange(currentTabIndex)
  }

  const onTabChange = (index: number) => {
    setCurrentTabIndex(index)
    flatlistRef.current?.scrollToOffset({ offset: index * width, animated: false })
  }

  return (
    <View style={[styles.container, containerStyle]}>
      {/* Tab Title */}
      <View style={{ paddingHorizontal: isWide ? 16 : 10, backgroundColor: '#F9F9F9' }}>
        <Tab value={currentTabIndex} onChange={onTabChange} disableIndicator={true}>
          {scenes.map(renderTabItem)}
        </Tab>
      </View>
      {/* Tab Content */}
      <FlatList
        nestedScrollEnabled={true}
        scrollEnabled={false}
        keyExtractor={(_item, index) => index.toString()}
        ref={flatlistRef}
        horizontal={true}
        pagingEnabled={true}
        data={scenes}
        onLayout={onTabContentLayout}
        // onScroll={onTabContentScroll}
        renderItem={({ item }) => renderTab(item)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },

  tabTitleContainer: {
    borderTopStartRadius: 14,
    borderTopEndRadius: 14,
    alignSelf: 'baseline',
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },

  tabTitleContainer_Active: {
    opacity: 1,
    backgroundColor: '#FFFFFF'
  },

  tabTitleContainer_Deactive: {
    opacity: 0.5,
    backgroundColor: '#E7E7E7'
  },

  tabTitle: {
    textTransform: 'none',
    paddingHorizontal: 0,
    lineHeight: 24
  },

  tabTitle_Active: {
    color: '#ED1B2E',
    fontWeight: 'bold'
  },

  tabTitle_Deactive: {
    color: '#828282',
    fontWeight: 'normal'
  }
})
