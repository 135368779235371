import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import moment from 'moment'
import { CancelRider, ProductEnum, RiderReinstatementModel } from './model'
import { ReinstatementService } from './ReinstatementService'
import { SubmissionService } from "./SubmissionService"
import { TopUpService } from './TopUpService'
import * as t from 'io-ts'
import { POApi } from '../POApi'
export namespace RiderReinstatementService {

    export const getDataAccess = (policyNum: string): Task<RiderReinstatementModel.AccessData> =>
        pipe(
            ZIO.zipPar(TopUpService.GetPolicyExtraInfo([policyNum], ''), SubmissionService.getPolicy(policyNum), RiderReinstatementService.getLapseDate(policyNum)),
            ZIO.map(([extraInfo, policyInfo, lapsedDate]) => {
                const contractStatus = policyInfo.body.status
                const coverageCode = policyInfo.body.basicCode
                // filter ra cac san pham rider(khong xet san pham chinh)
                const existedItem = extraInfo.policyExtraInfoDetail
                    .filter(
                        (x) =>
                            (x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00') &&
                            !CancelRider.UnCancelRiderList.includes(x.productCode)
                    )
                    .find((x) => x.riderStatus === ProductEnum.Status.LA)

                const day = moment().startOf('days').diff(moment(lapsedDate).startOf('days'), 'days')
                const checkDay = day <= 730
                return {
                    coverageCode: String(coverageCode),
                    contractStatus: String(contractStatus),
                    notExistAnyRider: existedItem ? false : true,
                    checkDate: checkDay
                }
            })
        )

    export const getReinStateFee = (policyNum: string) =>
        pipe(
            POApi.get(`wf-api/policy/${policyNum}/rider-reinstatement/get-reins-fee`)(
                t.type({
                    body: t.type({
                        reinsFee: t.number,
                        lapsedDate: t.string
                    })
                })
            ),
            ZIO.map((res) => res.body.reinsFee)
        )

    export const getLapseDate = (policyNum: string) =>
        pipe(
            POApi.get(`wf-api/policy/${policyNum}/rider-reinstatement/get-reins-fee`)(
                t.type({
                    body: t.type({
                        reinsFee: t.number,
                        lapsedDate: t.string
                    })
                })
            ),
            ZIO.map((res) => res.body.lapsedDate)
        )

}
