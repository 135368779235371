import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { InquiryStackParamList } from './InquiryStackParamList'
import { CEPSearchScreen } from './cep-services-inquiry/cep-services-inquiry-screen'
import { CEPPolicyDetailScreen } from './cep-policy-detail/CEPPolicyDetailScreen'

const Stack = createStackNavigator<InquiryStackParamList>()

export const InquiryStack = () => {
  return (
    <Stack.Navigator initialRouteName="InquiryScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="CEPSearchScreen" component={CEPSearchScreen} />
      <Stack.Screen name="CEPPolicyDetailScreen" component={CEPPolicyDetailScreen} />
    </Stack.Navigator>
  )
}
