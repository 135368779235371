import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { IDCDetailParamList } from './IDCDetailParamList'
import { IDCDetailScreen } from './IDCDetailScreen'
const Stack = createStackNavigator<IDCDetailParamList>()

export const IDCDetailStack = () => {
  return (
    <Stack.Navigator initialRouteName='IDCDetailScreen' screenOptions={{ headerShown: false }}>
      <Stack.Screen name="IDCDetailScreen" component={IDCDetailScreen} />
    </Stack.Navigator>
  )
}