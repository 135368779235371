import { Paper } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  assets,
  BorderDatePicker,
  BorderInput,
  BorderSelect,
  ContractDetail,
  DetailService,
  EditFooterService,
  Form,
  FormService,
  Input,
  NbuwService,
  PremiumCharge,
  PremiumChargeTotal,
  PremiumDecision,
  PremiumDecisionCode,
  PremiumDecisionLabel,
  ProposalStatusOptions,
  SectionHeader,
  SendEmail,
  Table,
  TableFooterPagination,
  TableStatus,
  BorderInputCurrency
} from '@pulseops/business/core'
import { didUpdate, ErrorHandling, form2, Format, RBAC, Permission, subscribe } from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { Option } from 'fp-ts/Option'
import * as tt from 'io-ts'
import * as _ from 'lodash'
import * as React from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import { ContractDetailContext } from '../contract-detail-context'
import { SC, StrongText } from '../sc'
import { PremiumCheckForm } from './premium-check-form'

export const PremiumCheck = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)
  const service = NbuwService.forContract(contractId)
  const detailService = DetailService.contract(contractId)

  const detail: ContractDetail | null = pipe(detailService.getInfo, ErrorHandling.runDidMount())

  const [sizeHistory, setSizeHistory] = React.useState(10)
  const [pageHistory, setPageHistory] = React.useState(0)
  const [loadingHistory, bindLoadingHistory] = useLoading(false)

  pipe(
    didUpdate([sizeHistory, pageHistory]),
    ZStream.chainEffect(([size, page]) => pipe(service.getPremiumDecision({ size, page }), bindLoadingHistory)),
    subscribe()
  )

  const history: PremiumDecision[] | null = pipe(
    didUpdate([sizeHistory, pageHistory]),
    ZStream.switchMap(([size, page]) => service.state.premiumDecision.size(size).watchPage(page)),
    subscribe([])
  )

  const totalHistory = pipe(
    didUpdate([sizeHistory]),
    ZStream.switchMap(([size]) => service.state.premiumDecision.size(size).watchTotal),
    subscribe(0)
  )

  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue
    },
    handleSubmit
  } = form2.useForm(PremiumCheckForm.codec, {
    defaultValues: {
      feeDecisions: [],
      cases: [],
      premiumReceived: ''
    }
  })

  const feeDecisionsHelpers = useFieldArray({
    control,
    name: 'feeDecisions'
  })

  const casesHelpers = useFieldArray({
    control,
    name: 'cases'
  })
  const cases = watch('cases')

  const charges = cases.map((c) => {
    const { loadingPremium, standardPremium } = PremiumCheckForm.PremiumCaseC.props
    const a = pipe(
      tt.type({ loadingPremium }).decode(c),
      E.fold(
        () => 0,
        ({ loadingPremium }) => loadingPremium
      )
    )
    const b = pipe(
      tt.type({ standardPremium }).decode(c),
      E.fold(
        () => 0,
        ({ standardPremium }) => standardPremium
      )
    )
    return a + b
  })

  const totalCharge = React.useMemo(() => charges.reduce((sum, c) => sum + c, 0), [charges])

  const premiumReceived = watch('premiumReceived')

  const premiumPending = React.useMemo(
    () =>
      pipe(
        premiumReceived,
        PremiumCheckForm.codec.props.premiumReceived.decode,
        E.fold(
          () => 0,
          (premiumReceived) => totalCharge - premiumReceived
        )
      ),
    [totalCharge, premiumReceived]
  )

  const [loadingTotal, bindLoadingTotal] = useLoading(false)
  const premiumTotal: Option<PremiumChargeTotal> | null = pipe(
    service.getPremiumChargeTotal,
    bindLoadingTotal,
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (premiumTotal) {
      pipe(
        premiumTotal,
        O.map(({ premiumCharges, premiumReceived }) => {
          setValue(
            'cases',
            premiumCharges.map(
              (c): PremiumCheckForm.PremiumCase => ({
                adjustment: c.adjustment,
                standardPremium: c.standardPremium.toString(),
                loadingPremium: c.loadingPremium.toString(),
                receivedDate: c.receivedDate
              })
            )
          )

          setValue('premiumReceived', premiumReceived.toString())
        })
      )
    }
  }, [premiumTotal])

  const isEdit = roles.includes(Permission['EditPremiumInfoGroupDetailPremium'])
  const [registerOnSave] = EditFooterService.useFooter({ hideCancel: true, hideSave: !isEdit })

  pipe(
    handleSubmit((validated) =>
      pipe(
        service.savePremiumCheck({
          premiumDecisions: validated.feeDecisions.map(
            (d): PremiumDecision => ({
              id: undefined,
              masterContractNo: contractId.masterContractNo,
              policyYear: contractId.policyYear,
              premiumDebitNote: d.premiumDebitNote,
              decision: d.decision === FormService.decisionYes,
              comment: d.comment || '',
              status: d.status,
              invoiceAmount: d.invoiceAmount,
              releaseDate: d.releaseDate,
              requestDate: d.requestDate,
              completeDate: d.completeDate,
              createdDate: undefined,
              createdBy: undefined
            })
          ),
          reloadDecision: ZIO.effect(() => {
            if (pageHistory === 0) {
              pipe(
                service.getPremiumDecision({ size: sizeHistory, page: pageHistory }),
                bindLoadingHistory,
                ErrorHandling.run()
              )
            } else {
              setPageHistory(0)
            }
          }),
          totalPremiumCharge: {
            masterContractNo: contractId.masterContractNo,
            policyYear: contractId.policyYear,
            premiumCharges: validated.cases.map(
              (c): PremiumCharge => ({
                adjustment: c.adjustment,
                standardPremium: c.standardPremium,
                loadingPremium: c.loadingPremium,
                receivedDate: c.receivedDate
              })
            ),
            total: totalCharge,
            premiumReceived: validated.premiumReceived,
            premiumPending
          }
        }),
        ErrorHandling.run()
      )
    ),
    registerOnSave
  )

  return (
    <SC.Container>
      <SC.BorderContainer>
        <Form.Group>
          <SectionHeader>{t('contract_info')}</SectionHeader>
          <Form.Row>
            <Form.Col>
              <Input label={t('proposal_no')} value={contractId.masterContractNo} readonly />
            </Form.Col>
            <Form.Col>
              <Input label={t('policy_no')} value={contractId.masterContractNo} readonly />
            </Form.Col>
            <Form.Col>
              <Input
                label={t('proposal_status')}
                value={ProposalStatusOptions.find((_) => _.value === detail?.status)?.label || t('new_HSYCH')}
                readonly
              />
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <SC.SubtitleContainer>
              <SC.Subtitle>{t('send_request')}</SC.Subtitle>
              <SendEmail email={detail?.policyHolder.contactAddress.email} />
            </SC.SubtitleContainer>
          </Form.Row>
        </Form.Group>
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader> {t('fee_decision')}</SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>
                  {t('letter_no')}
                  <SC.StarDot>*</SC.StarDot>
                </Table.CellHead>
                <SC.TableCellHead>
                  {t('fee')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead>
                  {t('notice_letter_issue_date')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead>{t('comment')}</SC.TableCellHead>
                <SC.TableCellHead>
                  {t('status')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead>{t('request_date')}</SC.TableCellHead>
                <SC.TableCellHead>{t('fee_collection_date')}</SC.TableCellHead>
                <SC.TableCellHead>
                  {t('postpone_claim')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead></SC.TableCellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={7} rows={feeDecisionsHelpers.fields} />
              {feeDecisionsHelpers.fields.map((f, i) => (
                <Table.Row key={f.id}>
                  <Table.Cell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.premiumDebitNote`}
                      render={({ field }) => (
                        <BorderInput
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          maxLength={255}
                          errorMessage={_.get(errors, `feeDecisions.${i}.premiumDebitNote`)?.message}
                        />
                      )}
                    />
                  </Table.Cell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.invoiceAmount`}
                      render={({ field }) => (
                        <BorderInputCurrency
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `feeDecisions.${i}.invoiceAmount`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.releaseDate`}
                      render={({ field }) => (
                        <BorderDatePicker
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `feeDecisions.${i}.releaseDate`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.comment`}
                      render={({ field }) => (
                        <BorderInput
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          maxLength={255}
                          errorMessage={_.get(errors, `feeDecisions.${i}.comment`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.status`}
                      render={({ field }) => (
                        <BorderSelect
                          value={field.value}
                          options={[
                            {
                              label: PremiumDecisionLabel(PremiumDecisionCode.InForce),
                              value: PremiumDecisionCode.InForce
                            },
                            {
                              label: PremiumDecisionLabel(PremiumDecisionCode.Lapse),
                              value: PremiumDecisionCode.Lapse
                            },
                            {
                              label: PremiumDecisionLabel(PremiumDecisionCode.Complete),
                              value: PremiumDecisionCode.Complete
                            }
                          ]}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `feeDecisions.${i}.status`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.requestDate`}
                      render={({ field }) => (
                        <BorderDatePicker
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `feeDecisions.${i}.requestDate`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.completeDate`}
                      render={({ field }) => (
                        <BorderDatePicker
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `feeDecisions.${i}.completeDate`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`feeDecisions.${i}.decision`}
                      render={({ field }) => (
                        <BorderSelect
                          value={field.value}
                          options={FormService.decisionOptions}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `feeDecisions.${i}.decision`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Pressable onPress={() => feeDecisionsHelpers.remove(i)}>
                      <assets.IconDeleteAddNew />
                    </Pressable>
                  </SC.TableCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Main>
        </Table.Container>

        {isEdit && (
          <SC.SubtitleContainer>
            <Pressable
              onPress={() => {
                feeDecisionsHelpers.append({
                  premiumDebitNote: '',
                  invoiceAmount: '',
                  releaseDate: null,
                  comment: '',
                  status: '',
                  requestDate: null,
                  completeDate: null,
                  decision: ''
                })
              }}
            >
              <assets.IconAddNew />
            </Pressable>
          </SC.SubtitleContainer>
        )}
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader> {t('premium_info')}</SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>
                  {t('case_id')}
                  <SC.StarDot>*</SC.StarDot>
                </Table.CellHead>
                <SC.TableCellHead>
                  {t('standard_premium')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead>
                  {t('loading_premium')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead>{t('total_premium_fee')}</SC.TableCellHead>
                <SC.TableCellHead align="center">
                  {t('date')}
                  <SC.StarDot>*</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead></SC.TableCellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={6} rows={casesHelpers.fields} loading={loadingTotal} />
              {casesHelpers.fields.map((f, i) => (
                <Table.Row key={f.id}>
                  <Table.Cell>
                    <Controller
                      control={control}
                      name={`cases.${i}.adjustment`}
                      render={({ field }) => (
                        <BorderInput
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          maxLength={255}
                          disabled={!isEdit}
                          errorMessage={_.get(errors, `cases.${i}.adjustment`)?.message}
                        />
                      )}
                    />
                  </Table.Cell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`cases.${i}.standardPremium`}
                      render={({ field }) => (
                        <BorderInputCurrency
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={!isEdit}
                          errorMessage={_.get(errors, `cases.${i}.standardPremium`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`cases.${i}.loadingPremium`}
                      render={({ field }) => (
                        <BorderInputCurrency
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={!isEdit}
                          errorMessage={_.get(errors, `cases.${i}.loadingPremium`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCellText>
                    <StrongText>{Format.currencyForBusiness(charges[i])}</StrongText>
                  </SC.TableCellText>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`cases.${i}.receivedDate`}
                      render={({ field }) => (
                        <BorderDatePicker
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={!isEdit}
                          errorMessage={_.get(errors, `cases.${i}.receivedDate`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    {isEdit && (
                      <Pressable onPress={() => casesHelpers.remove(i)}>
                        <assets.IconDeleteAddNew />
                      </Pressable>
                    )}
                  </SC.TableCell>
                </Table.Row>
              ))}

              <Table.Row>
                <Table.NoBorderTableCell />
                <Table.NoBorderTableCell />
                <Table.Cell>
                  <SC.BlueLabel>{t('total_premium_fee')}</SC.BlueLabel>
                </Table.Cell>
                <SC.TableCell>
                  <StrongText>{Format.currencyForBusiness(totalCharge)}</StrongText>
                </SC.TableCell>
                <Table.NoBorderTableCell />
                <Table.NoBorderTableCell />
              </Table.Row>
              <Table.Row>
                <Table.NoBorderTableCell />
                <Table.NoBorderTableCell />
                <Table.Cell>
                  <SC.BlueLabel>{t('recieved_fee')}</SC.BlueLabel>
                  <SC.StarDot>*</SC.StarDot>
                </Table.Cell>
                <SC.TableCell>
                  <Controller
                    control={control}
                    name={`premiumReceived`}
                    render={({ field }) => (
                      <BorderInputCurrency
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        disabled={!isEdit}
                        errorMessage={errors?.premiumReceived?.message}
                      />
                    )}
                  />
                </SC.TableCell>
                <Table.NoBorderTableCell />
                <Table.NoBorderTableCell />
              </Table.Row>
              <Table.Row>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell>
                  <SC.BlueLabel>{t('remaining_fee')}</SC.BlueLabel>
                </Table.Cell>
                <SC.TableCell>
                  <StrongText>{Format.currencyForBusiness(premiumPending)}</StrongText>
                </SC.TableCell>
                <Table.Cell />
                <Table.Cell />
              </Table.Row>
            </Table.Body>
          </Table.Main>
        </Table.Container>

        {isEdit && (
          <SC.SubtitleContainer>
            <Pressable
              onPress={() => {
                casesHelpers.append({
                  adjustment: '',
                  standardPremium: '',
                  loadingPremium: '',
                  receivedDate: null
                })
              }}
            >
              <assets.IconAddNew />
            </Pressable>
          </SC.SubtitleContainer>
        )}
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader> {t('history_premium_check')}</SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>{t('letter_no')}</Table.CellHead>
                <SC.TableCellHead>{t('fee')}</SC.TableCellHead>
                <SC.TableCellHead>{t('notice_letter_issue_date')}</SC.TableCellHead>
                <SC.TableCellHead>{t('comment')}</SC.TableCellHead>
                <SC.TableCellHead>{t('status')}</SC.TableCellHead>
                <SC.TableCellHead>{t('request_date')}</SC.TableCellHead>
                <SC.TableCellHead>{t('fee_collection_date')}</SC.TableCellHead>
                <SC.TableCellHead>{t('postpone_claim')}</SC.TableCellHead>
                <SC.TableCellHead>{t('datetime')}</SC.TableCellHead>
                <SC.TableCellHead>{t('change_by')}</SC.TableCellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={7} rows={history || []} loading={loadingHistory} />
              {history?.map((row, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{row.premiumDebitNote}</Table.Cell>
                  <SC.TableCell>{Format.currencyForBusiness(row.invoiceAmount)}</SC.TableCell>
                  <SC.TableCell>{row.releaseDate ? Format.date(row.releaseDate) : ''}</SC.TableCell>
                  <SC.TableCell>{row.comment}</SC.TableCell>
                  <SC.TableCell>{PremiumDecisionLabel(row.status)}</SC.TableCell>
                  <SC.TableCell>{row.requestDate ? Format.date(row.requestDate) : ''}</SC.TableCell>
                  <SC.TableCell>{row.completeDate ? Format.date(row.completeDate) : ''}</SC.TableCell>
                  <SC.TableCell>{FormService.getDecisionName(row.decision)}</SC.TableCell>
                  <SC.TableCell>{row.createdDate ? Format.datetime(row.createdDate) : ''}</SC.TableCell>
                  <SC.TableCell>{row.createdBy}</SC.TableCell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooterPagination
              total={totalHistory}
              size={sizeHistory}
              page={pageHistory}
              onPageChange={setPageHistory}
              onSizeChange={setSizeHistory}
              colspan={7}
            />
          </Table.Main>
        </Table.Container>
      </SC.BorderContainer>
    </SC.Container>
  )
}
