import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { PCRequestStackParamList } from './PCRequestStackParamList'
import { PCRequest } from './PCRequest'
import { RequestRelatedcashlessCollectionStack } from './RequestRelatedCashlessCollection'
const Stack = createStackNavigator<PCRequestStackParamList>()

export const PCRequestStack = () => {
  return (
    <Stack.Navigator initialRouteName="PCRequestScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="PCRequestScreen" component={PCRequest} />
      <Stack.Screen name="RequestRelatedCashlessCollectionStack" component={RequestRelatedcashlessCollectionStack} />
    </Stack.Navigator>
  )
}
