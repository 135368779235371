import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { TransactionType } from '../TransactionType'
import { CashoutOptions } from './CashoutOptions'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.PARTIAL_WITHDRAWAL)
    }),
    codec
  ])

export const PartialWithdrawal = Base(
  t.type({
    source: t.string,
    payload: t.type({
      body: t.type({
        policy: t.type({
          policyNo: t.string
        }),
        attributesExt: t.type({
          TOTAL_PAYOUT_AMOUNT: t.number,
          ESTIMATED_VALUE: t.number,
          MINIMUM_PARTIAL_WITHDRAWAL: t.number,
          MAXIMUM_PARTIAL_WITHDRAWAL: t.number,
          WITHDRAW_FEE: t.number,

          //ulp
          CURRENT_SUM_ASSURED: Maybe(t.number),
          NEW_SUM_ASSURED: Maybe(t.number),
          WITHDRAW_AMOUNT: Maybe(t.number),

          //ilp
          TOTAL_WITHDRAW_AMOUNT: Maybe(t.number),
          FUND_LIST: Maybe(
            t.array(
              t.type({
                FUND_CODE: t.string,
                FUND_VALUE: t.number,
                'MIN_P/W_REMAIN': t.number,
                WITHDRAW_AMOUNT: t.number
              })
            )
          )
        }),
        cashOutOptions: Maybe(CashoutOptions)
      })
    })
  })
)

export type PartialWithdrawal = t.TypeOf<typeof PartialWithdrawal>
