import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { ClientPolicy, Customer } from '../Client'
import { PaymentType } from '../PaymentType'
import { getCoreDetail, getPayOutDetail } from './common/payout'
import { SourceType } from '../SourceType'
import moment from 'moment'
import { MaturityPayment } from '../MaturityPayment'

export interface CashOutMaturity {
  tag: TransactionType.MATURITY

  clientName: string
  totalMaturityBenefits: number | null
  totalCashBenefit: number | null
  netTotalMaturityBenefit: number | null
  totalAmount: number
  methods: PaymentMethod[]
  inheritanceFlag: boolean
  deathDate: string

  principalAplOpl: number
  interestAplOpl: number
  suspendAmount: number
  paidMaturity: number
  notPaidPremium: number
  deductOfClaims: number
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
  source?: string
}

export const CashOutMaturity = (
  detail: api.CashOutMaturity & api.TaskDetailBase,
  client: Customer,
  poName: string,
  maturityData: MaturityPayment.Detail,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string
): CashOutMaturity => {
  const p = detail.payload

  const suspendAmount = maturityData.suspendAmount
  const paidMaturity = maturityData.paidMaturity
  const notPaidPremium = maturityData.notPaidPremium
  const deductOfClaims = maturityData.deductOfClaims
  const coreStatus = getCoreDetail(detail.coreResponseDetail, p.body.cashOutOptions)
  const cashOutList = p.body
    ? p.body.cashOutOptions?.filter(
        (x) =>
          PaymentType.BANKTRANSFER === x.type ||
          PaymentType.PAID_AT_BANK === x.type ||
          PaymentType.CASH_AT_COUNTER === x.type
      )
    : []
  switch (detail.source) {
    case SourceType.EKIOSK:
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
    case SourceType.PULSE:
      return {
        tag: TransactionType.MATURITY,
        clientName: poName || client.body.name,

        totalMaturityBenefits: maturityData.maturityBenefit,
        totalCashBenefit: maturityData.prucashBenefit,
        suspendAmount,
        netTotalMaturityBenefit: maturityData.totalCashBenefit,
        inheritanceFlag:
          p.additionalProperties?.inheritanceFlag && p.additionalProperties?.inheritanceFlag === 'Y' ? true : false,
        deathDate: p.additionalProperties?.deathDate ?? '',

        principalAplOpl: maturityData.principalAplOpl,
        interestAplOpl: maturityData.interestAplOpl,
        paidMaturity,
        notPaidPremium,
        deductOfClaims,

        totalAmount: p.body?.cashOutOptions?.reduce((total, next) => total + (next.paymentOption.amount ?? 0), 0) ?? 0, // missing
        cashOutList: cashOutList,
        branchName: branchName,
        methods: p.body
          ? p.body.cashOutOptions?.map((v, i) => ({
              method: v.type || '-',
              detail: getPayOutDetail(
                {
                  type: v.type as PaymentType,
                  officeCode:
                    (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) ||
                    '',

                  bankAccount: {
                    accountName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                      ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                      ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                      '-',
                    accountNo:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                      ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                      '-',
                    bankName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                      '-',
                    branchName:
                      (v.type === PaymentType.BANKTRANSFER && branchName) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.branchName) ||
                      undefined,
                    bankCode: '-',
                    branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
                  },
                  phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
                  issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
                  issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
                  policy: {
                    policyNo: v.policy?.policyNo || '-'
                  }
                },
                clientPolicy
              ),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
            })) || []
          : []
      }
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS:
      return {
        tag: TransactionType.MATURITY,
        clientName: poName || client.body.name,
        source: detail?.source ?? '',
        totalMaturityBenefits: maturityData.maturityBenefit,
        totalCashBenefit: maturityData.prucashBenefit,
        suspendAmount,
        netTotalMaturityBenefit: maturityData.totalCashBenefit,
        inheritanceFlag: detail.payload.body?.policy.attributesExt.inheritanceFlag === 'Y',
        deathDate: detail.payload.body?.policy.owners.dateOfDeath
          ? moment(detail.payload.body?.policy.owners.dateOfDeath).format('DD/MM/yyyy')
          : '',

        principalAplOpl: maturityData.principalAplOpl,
        interestAplOpl: maturityData.interestAplOpl,
        paidMaturity,
        notPaidPremium,
        deductOfClaims,

        totalAmount: p.body?.cashOutOptions?.reduce((total, next) => total + (next.paymentOption.amount ?? 0), 0) ?? 0, // missing
        cashOutList: cashOutList,
        branchName: branchName,
        methods: p.body
          ? p.body.cashOutOptions?.map((v, i) => ({
              method: v.type || '-',
              detail: getPayOutDetail(
                {
                  type: v.type as PaymentType,
                  officeCode:
                    (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) ||
                    '',

                  bankAccount: {
                    accountName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                      (v.type === PaymentType.PAID_AT_BANK &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      (v.type === PaymentType.CASH_AT_COUNTER &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      (v.type === PaymentType.MOMO &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      '-',
                    accountNo:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ||
                      (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ||
                      (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ||
                      '-',
                    bankName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                      '-',
                    branchName:
                      (v.type === PaymentType.BANKTRANSFER && branchName) ||
                      (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                      undefined,
                    bankCode: '-',
                    branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
                  },
                  phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
                  issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
                  issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
                  policy: {
                    policyNo: v.policy?.policyNo || '-'
                  }
                },
                clientPolicy
              ),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
            })) || []
          : []
      }
  }
}
