import * as React from 'react'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { Column, Label, Error, Row } from '@pulseops/submission/common'
import {
  FieldList,
  Panel,
  Title,
  ExcellentStudentAwardService,
  ErrorHandling,
  PulseOpsFormat,
  ImgUploadMutiple,
  form2,
  TransactionType,
  PayoutPopup,
  ExcellentStudentAwardServiceModel,
  StorageBlob,
  AppContext,
  GeneralService,
  TaskType
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { useLoading } from '@mxt/zio-react'
import { Controller } from 'react-hook-form'
import { FilePresent } from '../../common'
import { ExcellentStudentAwardForm } from './excellent-student-award.form'
import { View } from 'react-native'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../payout-method'
import { RequestAuthenticateData } from '../../request-authen'
// import { ZIO } from '@mxt/zio'

interface Props extends PolicyServiceProps<ExcellentStudentAwardServiceModel.SubmitData> {
  policyNumber: string
}

export const ExcellentStudentAward = ({ policyNumber, isConfirmed, initSubmission, officeCode }: Props) => {
  const { t } = useTranslation()

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [isLoading, bindLoading] = useLoading(false)
  // const [loading, bindLoader] = useLoading(false)

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const form = form2.useForm(ExcellentStudentAwardForm.codec, {
    defaultValues: {
      files: [],
      payout: []
    }
  })

  const { control, watch, getValues } = form.base

  const { payout } = watch()

  const detail = pipe(
    ExcellentStudentAwardService.getDetail(policyNumber),
    bindLoading,
    ErrorHandling.runDidUpdate([policyNumber])
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const renderNoteContent = (text: string) => {
    return (
      <Row alignItems="center" marginStart={10}>
        <View
          style={{
            width: 5,
            height: 5,
            borderRadius: 4,
            overflow: 'hidden',
            backgroundColor: '#70777E',
            marginEnd: 10
          }}
        />
        <Label title={text} />
      </Row>
    )
  }

  const renderNote = () => {
    return (
      <Column marginBottom={14}>
        <Label title={t('submission:FileAttachmentNote')} />
        {renderNoteContent(t('submission:FileAttachmentNote_1'))}
        {renderNoteContent(t('submission:FileAttachmentNote_2'))}
        {renderNoteContent(t('submission:FileAttachmentNote_3'))}
        {renderNoteContent(t('submission:FileAttachmentNote_4'))}
      </Column>
    )
  }

  const totalAmount = React.useMemo(() => payout.reduce((sum, item) => sum + item.amount, 0), [payout])

  // const getMetaDataFromAttachmentFiles = () => {
  //   let files: StorageBlob.FileContent[] = []
  //   return pipe(
  //     GeneralService.getMetaData(TransactionType.EXCELLENT_STUDENT_AWARD || '', 'DPS17'),
  //     ZIO.map((metaDataRes) => {
  //       const metaDataRaw: StorageBlob.MetaDataUpload = {
  //         type: metaDataRes.data.transactionType,
  //         doctype: metaDataRes.data.doctypeEn,
  //         class: metaDataRes.data.classFilenet,
  //         docid: metaDataRes.data.docID,
  //         maindoc: metaDataRes.data.mainDoc,
  //         subdoc: metaDataRes.data.subDoc,
  //         polnum: policyNumber,
  //         batchno: metaDataRes.data.batchNo
  //       }
  //       const documents = getValues('files') as ExcellentStudentAwardForm.File[]
  //       files = documents.map((x) => {
  //         return {
  //           file: x.file,
  //           metaData: metaDataRaw
  //         }
  //       })
  //       return files
  //     })
  //   )
  // }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const documents = getValues('files') as ExcellentStudentAwardForm.File[]
    uploadedFileList.push({
      uploadFiles: documents,
      transactionType: TransactionType.EXCELLENT_STUDENT_AWARD,
      docTypeCode: 'DPS17',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    uploadedFileList.push({
      uploadFiles: GeneralService.getPayoutDocuments(getValues('payout')),
      transactionType: TransactionType.EXCELLENT_STUDENT_AWARD,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isValid = await form.base.trigger()
      if (!isValid || !detail || totalAmount > detail.reqPayinAmount) {
        return false
      }

      let filesUploaded: StorageBlob.FileContentSubmit[] = []
      // if (!isContinue) {
      //   showGlobalLoading(true)
      //   await pipe(
      //     getMetaDataFromAttachmentFiles(),
      //     ZIO.flatMap((metaDataFiles) => StorageBlob.uploadToSubmit('PS', policyNumber)(metaDataFiles)),
      //     ZIO.tapError((_) => {
      //       showGlobalLoading(false)
      //       return ZIO.unit
      //     }),
      //     ZIO.map((files) => {
      //       showGlobalLoading(false)
      //       filesUploaded = files
      //       return files
      //     }),
      //     ZIO.runPromise({})
      //   )
      //   if (getValues('files').length > 0 && filesUploaded.length === 0) {
      //     showToast(t('message:MS050001'), 'error')
      //     return false
      //   }
      // }

      return {
        url: (policyNum) => `wf-api/policy/${policyNum}/excellent-student-award`,
        body: {
          policy: {
            policyNo: policyNumber
          },
          attributesExt: {
            TOTAL_PAYOUT_AMOUNT: totalAmount,
            EXCELLENT_AWARD_AMOUNT: detail.benefit,
            PRINCIPAL_AMOUNT: detail.principalAmount,
            INTEREST_AMOUNT: detail.investmentAmount,
            NET_TOTAL_CASH_BENEFIT: detail.reqPayinAmount,
            LOAN_EXCEED_80_SURRENDER_VALUE: detail.overMaturity
          },
          cashOutOptions: mapCashOutOption(payout)
        },
        collerationId: policyNumber,
        transaction: TransactionType.EXCELLENT_STUDENT_AWARD,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.EXCELLENT_STUDENT_AWARD),
        documents: filesUploaded,
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      form.base.reset()
      payoutRef.current?.clearData()
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('submission:EXCELLENT_STUDENT_AWARD_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <FieldList
          dataSource={[
            {
              label: t('submission:ExcellentStudentAwardAmount'),
              value: PulseOpsFormat.thousandSepartor(detail?.benefit),
              suffix: 'VND'
            },
            {
              label: t('submission:PrincipalAPL-OPL'),
              value: PulseOpsFormat.thousandSepartor(detail?.principalAmount),
              suffix: 'VND'
            },
            {
              label: t('submission:InterestAPL-OPL'),
              value: PulseOpsFormat.thousandSepartor(detail?.investmentAmount),
              suffix: 'VND'
            },
            {
              label: t('submission:LoanOverLimit'),
              value: PulseOpsFormat.thousandSepartor(detail?.overMaturity),
              suffix: 'VND'
            },
            {
              label: t('submission:NetAmountAvailable2'),
              value: PulseOpsFormat.thousandSepartor(detail?.reqPayinAmount),
              suffix: 'VND',
              isHighlight: true
            }
          ]}
        />

        <Column marginTop={12} marginBottom={14}>
          <Label title={t('submission:FileAttachment')} required={!isConfirmed} fontWeight="bold" />
        </Column>
        {!isConfirmed && renderNote()}
        <Controller
          control={control}
          name={'files'}
          render={({ field, fieldState: { error } }) =>
            !isConfirmed ? (
              <Column>
                <ImgUploadMutiple {...field} value={field.value as any[]} />
                {error?.message && <Error message={error.message} />}
              </Column>
            ) : (
              <Row flexWrap={'wrap'} flexGrow={1}>
                {(field.value as any[]).map((item) => (
                  <Column marginEnd={20}>
                    <FilePresent file={item} />
                  </Column>
                ))}
              </Row>
            )
          }
        />
      </Panel>

      {detail && (
        <Controller
          control={control}
          name="payout"
          render={({ field, fieldState: { error } }) => (
            <PayoutMethod
              {...field}
              ref={payoutRef}
              editable={!isConfirmed}
              policyNum={policyNumber}
              transactionType={TransactionType.EXCELLENT_STUDENT_AWARD}
              maxAmount={detail.reqPayinAmount}
              errorMessage={error?.message || (totalAmount > detail.reqPayinAmount ? t('message:MS050237') : '')}
              methods={[
                PayoutPopup.PayoutMethods.PAYPREMIUM,
                PayoutPopup.PayoutMethods.PAYLOAN,
                PayoutPopup.PayoutMethods.CASHLESS,
                PayoutPopup.PayoutMethods.OTHER,
                PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
                PayoutPopup.PayoutMethods.PAID_AT_BANK,
                PayoutPopup.PayoutMethods.BANKTRANSFER,
                // PayoutPopup.PayoutMethods.MOMO
              ]}
              officeCode={officeCode}
            />
          )}
        />
      )}
    </Column>
  )
}
