import { ZIO } from '@mxt/zio'
import {
  AppContext,
  FundSwitchingModel,
  FundSwitchingService,
  RadioButton,
  TransactionType,
  useMobile,
  TopUp,
  FundMapping,
  SelectSearch,
  assets,
  PulseOpsFormat,
  Input,
  HeaderTable,
  InputTable,
  RowSelectedProps,
  Columns
} from '@pulseops/common'
// import { Error } from '@pulseops/submission/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { RequestAuthenticateData } from '../../request-authen'
import { PolicyServiceProps } from '../policy-service-props'

type Option = {
  value: string
  label: string
}

export interface TargetFund {
  code: string
  targetPercentage: string
  amount: string
}

export interface SourceFund {
  code: string
  unitValue: string
  targetPercentage: string
  amount: string
}

export interface FundSwichingForm {
  oneToMany: {
    sourceFund: Option | null
    unitValue: string
    sourcePercentage: string
    sourceAmount: string
    funds: TargetFund[]
  }
  manyToOne: {
    targetFund: Option | null
    percentage: string
    amount: string
    funds: SourceFund[]
  }
}

const x = 1000000
const y = 1000000
const k = 1000000
const min = '1,000,000'

export const FundSwitchingScreen: React.FC<PolicyServiceProps<FundSwitchingModel.SubmitData>> = ({
  initSubmission,
  isConfirmed,
  policyNumber
}) => {
  const policyNum = policyNumber ?? ''
  const { t } = useTranslation()
  const { isMobile } = useMobile()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()

  const fundMap = FundMapping.mapFunds

  const [is1toN, setIs1toN] = React.useState<boolean>(true)
  const [isTargetPremium, setIsTargetPremium] = React.useState<boolean>(true)
  const [totalFund, setTotalFund] = React.useState<TopUp.PolicyExtraInfoFund[]>([])

  const [detailData, setDetailData] = React.useState<FundSwitchingModel.Detail>({
    productCode: '',
    targetPremiumAccount: [],
    topUpAccount: []
  })
  const form = useForm<FundSwichingForm>({
    mode: 'all',
    defaultValues: {
      oneToMany: {
        funds: []
      }
    }
  })
  const { control, setValue, watch, reset, formState } = form

  const oneToManyFunds = useFieldArray<FundSwichingForm>({
    name: 'oneToMany.funds',
    control: control
  })

  const manyToOneFunds = useFieldArray<FundSwichingForm>({
    name: 'manyToOne.funds',
    control: control
  })

  const totalPercentFundOneToMany = React.useMemo(() => {
    return watch('oneToMany.funds').reduce((total, item) => total + Number(item.targetPercentage || 0), 0)
  }, [formState.isValidating])

  React.useEffect(() => {
    setIs1toN(true)
    setIsTargetPremium(true)
    form.clearErrors()
    form.reset({
      oneToMany: {
        sourceFund: null,
        unitValue: '',
        sourcePercentage: '',
        sourceAmount: '',
        funds: []
      },
      manyToOne: {
        targetFund: null,
        percentage: '',
        amount: '',
        funds: []
      }
    })
    loadingDetailData()
  }, [isFocused])

  const isFormValidated = (): boolean => {
    let isFormValid: boolean = false
    const calFund = (funds: TargetFund[]): number =>
      funds.reduce((acc, curr) => acc + (Number(curr.targetPercentage) ?? 0), 0) ?? 0
    let percent = 0

    switch (is1toN) {
      case true:
        percent = calFund(watch('oneToMany.funds') ?? [])

        // const amountValue = PulseOpsFormat.thousandSepartorReverse(watch('oneToMany.sourceAmount') ?? '0')
        // const unitValue = PulseOpsFormat.thousandSepartorReverse(watch('oneToMany.unitValue') ?? '0')
        if (percent > 100) {
          showToast(t('message:MS050175'), 'error')
          return false
        } else if (percent !== 100) {
          showToast(t('message:MS050040'), 'error')
          return false
        }
        // else if (isULR() && unitValue >= x + y) {
        //   if (amountValue < x) {
        //     showToast(t('message:MS050201', { k: PulseOpsFormat.thousandSepartor(k) }), 'error')
        //     return false
        //   } else if (unitValue - amountValue !== 0 && unitValue - amountValue < y) {
        //     showToast(t('message:MS050202', { min: min }), 'error')
        //     return false
        //   }
        // }
        return true
      case false:
        const fundList = watch('manyToOne.funds') ?? []
        if (fundList.every((s) => Number(s.targetPercentage) === 0)) return false

        if (!fundList.length || fundList.some((s) => Number(s.targetPercentage) > 100)) {
          showToast(t('message:MS050175'), 'error')
          return false
        } else if (fundList.length === 0) {
          return false
        } else if (isULR()) {
          const fund = fundList.find(
            (s) => Number(s.targetPercentage) && PulseOpsFormat.thousandSepartorReverse(s.unitValue) >= 2 * k
          )
          if (fund) {
            const unitValue = PulseOpsFormat.thousandSepartorReverse(fund.unitValue)
            const amountValue = PulseOpsFormat.thousandSepartorReverse(fund.amount)
            if (amountValue < k) {
              showToast(t('message:MS050201', { k: PulseOpsFormat.thousandSepartor(k) }), 'error')
              return false
            } else if (unitValue - amountValue < k && unitValue - amountValue !== 0) {
              showToast(t('message:MS050202', { min: min }), 'error')
              return false
            }
          }
        }
        return true
      case null:
      case undefined:
        showToast('message:MS050236', 'error')
        isFormValid = false
        break
    }
    return isFormValid
  }

  const mapData = (): FundSwitchingModel.SubmitData => {
    const formValue = form.getValues()
    const oneToManyFrm = formValue.oneToMany
    const manyToOneFrm = formValue.manyToOne
    let sourceFunds: FundSwitchingModel.SourceFundData[] = []
    let targetFunds: FundSwitchingModel.TargetFundData[] = []
    let isFWA: boolean
    if (is1toN) {
      sourceFunds = [
        {
          sourceFundCode: oneToManyFrm?.sourceFund?.value ?? '-',
          unitValue: PulseOpsFormat.thousandSepartorReverse(oneToManyFrm?.unitValue ?? '0'),
          sourcePercentage: Number(oneToManyFrm?.sourcePercentage),
          sourceAmount: PulseOpsFormat.thousandSepartorReverse(oneToManyFrm?.sourceAmount ?? '0')
        }
      ]
      oneToManyFrm?.funds.forEach((f) => {
        if (f.targetPercentage) {
          targetFunds.push({
            targetFundCode: f.code,
            targetPercentage: Number(f.targetPercentage ?? '0'),
            targetAmount: PulseOpsFormat.thousandSepartorReverse(f.amount)
          })
        }
      })
      isFWA = !!oneToManyFrm?.sourceFund?.value.endsWith('1')
    } else {
      manyToOneFrm?.funds.forEach((f) => {
        if (f.targetPercentage) {
          sourceFunds.push({
            sourceFundCode: f.code,
            unitValue: PulseOpsFormat.thousandSepartorReverse(f.unitValue),
            sourcePercentage: Number(f?.targetPercentage),
            sourceAmount: PulseOpsFormat.thousandSepartorReverse(f?.amount)
          })
        }
      })
      targetFunds = [
        {
          targetFundCode: manyToOneFrm?.targetFund?.value ? manyToOneFrm.targetFund.value.split(' ')[0] : '-',
          targetPercentage: Number(manyToOneFrm?.percentage),
          targetAmount: PulseOpsFormat.thousandSepartorReverse(manyToOneFrm?.amount ?? '0')
        }
      ]
      isFWA = !!manyToOneFrm?.funds.some((f) => f.code.endsWith('1'))
    }
    return {
      attributesExt: {
        PRIMARY_POLICY: policyNum,
        PO_CLIENT_NUMBER: '',
        GA_OFFICE_CODE: '',
        OPTION_FLAG: is1toN,
        FUND_TYPE: isFWA ? 'MSP_C_FWA' : 'MSP_C_FWD', //1 = MSP_C_FWA , 2 = MSP_C_FWD
        SOURCE_FUND_LIST: sourceFunds,
        TARGET_FUND_LIST: targetFunds
      }
    }
  }

  initSubmission({
    validate: async () => {
      if (!is1toN && watch('manyToOne.funds').length === 0) {
        if (isTargetPremium) {
          showToast(t('message:MS050298'), 'error')
        } else {
          showToast(t('message:MS050297'), 'error')
        }
        return false
      } else if (
        ((await form.trigger('oneToMany')) && isFormValidated() && is1toN) ||
        ((await form.trigger('manyToOne')) && isFormValidated() && !is1toN)
      ) {
        return {
          url: () => `wf-api/fund/fund-switching`,
          body: {
            ...mapData()
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.FUND_SWITCHING),
          collerationId: policyNum,
          transaction: TransactionType.FUND_SWITCHING
        }
      } else {
        return false
      }
    },
    clear: () => {
      setIs1toN(true)
      setIsTargetPremium(true)
      form.clearErrors()
      form.reset({
        oneToMany: {
          sourceFund: null,
          unitValue: '',
          sourcePercentage: '',
          sourceAmount: '',
          funds: []
        },
        manyToOne: {
          targetFund: null,
          percentage: '',
          amount: '',
          funds: []
        }
      })
      loadingDetailData()
    }
  })

  const loadingDetailData = () => {
    showGlobalLoading(true)
    pipe(
      FundSwitchingService.getDetail(policyNum),
      ZIO.map((responseData) => {
        setTotalFund([...responseData.targetPremiumAccount].concat(responseData.topUpAccount))
        setDetailData(responseData)
        return responseData
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const onChangeFund = (is1ToMany: boolean) => {
    setIs1toN(is1ToMany)
    reset({
      oneToMany: {
        funds: [],
        unitValue: '',
        sourcePercentage: '',
        sourceAmount: '',
        sourceFund: null
      },
      manyToOne: {
        funds: [],
        targetFund: null,
        amount: '',
        percentage: '100'
      }
    })
    if (!is1ToMany) {
      setIsTargetPremium(true)
    }
  }

  const onChangeTargetPremium = (isTarget: boolean) => {
    setIsTargetPremium(isTarget)
    reset({
      oneToMany: {
        funds: [],
        unitValue: '',
        sourcePercentage: '',
        sourceAmount: '',
        sourceFund: null
      },
      manyToOne: {
        funds: [],
        targetFund: null,
        amount: '',
        percentage: '100'
      }
    })
  }

  const onChangeSourceFund = (fund: Option | null) => {
    if (fund?.value) {
      const fWData = totalFund.find((f) => f.fundCode === fund.value)
      const percent = Number(watch('oneToMany.sourcePercentage'))
      if (fWData) {
        setValue('oneToMany.unitValue', PulseOpsFormat.thousandSepartor(fWData.estimateValue))
        if (percent) {
          const unitValue = watch('oneToMany.unitValue') ?? '0'
          const amount = (PulseOpsFormat.thousandSepartorReverse(unitValue) * (percent / 100)).toFixed(2)
          setValue('oneToMany.sourceAmount', PulseOpsFormat.thousandSepartor(parseInt(amount)))
        }
      }

      const fundMapping = fund.value.endsWith('1')
        ? FundMapping.TargetPremium.filter((t) => t.code !== fund.value)
        : fund.value.endsWith('2')
        ? FundMapping.TopUpAcc.filter((t) => t.code !== fund.value)
        : []
      const fundList: TargetFund[] = []
      setValue('oneToMany.funds', [])
      if (fundMapping.length) {
        fundMapping.forEach((c) => {
          const fund: TargetFund = {
            code: c.code,
            targetPercentage: '',
            amount: ''
          }
          oneToManyFunds.append(fund)
          fundList.push(fund)
        })
      }
    } else {
      reset({
        oneToMany: {
          funds: [],
          unitValue: '',
          sourcePercentage: '',
          sourceAmount: '',
          sourceFund: null
        },
        manyToOne: {
          funds: [],
          targetFund: null,
          amount: '',
          percentage: '100'
        }
      })
    }
  }

  const onChangeTargetFund = (fund: Option | null) => {
    if (fund?.value) {
      const fundMapping = isTargetPremium ? detailData.targetPremiumAccount : detailData.topUpAccount
      form.setValue('manyToOne.funds', [])
      fundMapping.forEach((f) => {
        const fund: SourceFund = {
          code: f.fundCode,
          targetPercentage: '',
          amount: '',
          unitValue: PulseOpsFormat.thousandSepartor(f.estimateValue)
        }
        manyToOneFunds.append(fund)
      })
    } else {
      reset({
        oneToMany: {
          funds: [],
          unitValue: '',
          sourcePercentage: '',
          sourceAmount: '',
          sourceFund: null
        },
        manyToOne: {
          funds: [],
          targetFund: null,
          amount: '',
          percentage: '100'
        }
      })
    }
  }

  const isULR = () => {
    return ['ULR1', 'ULR4', 'ULR5'].includes(detailData.productCode)
  }

  const isULR6 = (): boolean =>
    detailData && detailData.productCode && detailData.productCode === 'ULR6' ? true : false

  const calculateSourceFund = (i: number) => {
    const currAmount = PulseOpsFormat.thousandSepartorReverse(watch('oneToMany.sourceAmount') ?? '0') ?? 0
    const newValue = watch(`oneToMany.funds.${i}.targetPercentage`)
    const amount = ((parseInt(newValue) * currAmount) / 100).toFixed(2)
    setValue(`oneToMany.funds.${i}.amount`, PulseOpsFormat.thousandSepartor(Number(amount)))
  }

  const calculateSourcePercent = (i: number) => {
    const percent = Number(watch(`manyToOne.funds.${i}.targetPercentage`))
    if (percent >= 0 && percent <= 100) {
      const amount = Number(
        (percent * (PulseOpsFormat.thousandSepartorReverse(watch(`manyToOne.funds.${i}.unitValue`)) / 100)).toFixed(2)
      )
      // tFund.controls.amount?.setValue(PulseOpsFormat.thousandSepartor(amount))
      setValue(`manyToOne.funds.${i}.amount`, PulseOpsFormat.thousandSepartor(Number(amount)))
      let totalAmount = 0
      watch('manyToOne.funds').forEach((c) => (totalAmount += PulseOpsFormat.thousandSepartorReverse(c.amount)))
      setValue('manyToOne.amount', PulseOpsFormat.thousandSepartor(Number(totalAmount.toFixed(2))))

      // if (
      //   this.isULR() &&
      //   PulseOpsFormat.thousandSepartorReverse(tFund.controls.unitValue.value) < 2 * k &&
      //   percent &&
      //   percent !== 100
      // ) {
      //   tFund.controls.errorMessage.setValue(PulseOpsMessage.MS050235, { emitEvent: false })
      // } else {
      //   tFund.controls.errorMessage.setValue('', { emitEvent: false })
      // }
    }
  }

  const onChangeSourcePercentage = (e: string) => {
    const percent = e.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    setValue('oneToMany.sourcePercentage', percent)

    const unitValue = PulseOpsFormat.thousandSepartorReverse(watch('oneToMany.unitValue') ?? '0')

    const amount = (unitValue * (parseInt(percent) / 100)).toFixed(2)

    setValue('oneToMany.sourceAmount', PulseOpsFormat.thousandSepartor(Number(amount)))

    const funds = watch('oneToMany.funds')
    if (funds.length) {
      const fundValues = funds
      fundValues.forEach((_, i) => {
        calculateSourceFund(i)
      })
    }

    if (isULR() && unitValue < 2 * k && parseInt(percent) !== 100) {
      showToast(t('message:MS050235'), 'error')
    }
  }

  const optionTargetFund = () => {
    return isULR6()
      ? isTargetPremium
        ? FundMapping.TargetPremium
        : FundMapping.TopUpAcc
      : detailData.targetPremiumAccount
      ? FundMapping.TargetPremium
      : FundMapping.TopUpAcc
  }

  const renderFundController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`oneToMany.funds.${index}.code` as 'oneToMany.funds.0.code'}
        render={({ field }) => (
          <Input
            {...field}
            value={`${field.value} - ${t(`${FundMapping.mapFunds.get(field.value)}`) ?? '-'}`}
            disabled
          />
        )}
      />
    )
  }

  const renderPercentController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        rules={{
          // required: {
          //   value: true,
          //   message: `${t('message:MS020001', { field: t('requestInfo:TargetPercentage') })}`
          // },
          max: {
            value: 100,
            message: `${t('message:MS050175')}`
          },
          min: {
            value: 1,
            message: `${t('message:MS050175')}`
          }
        }}
        name={`oneToMany.funds.${index}.targetPercentage` as 'oneToMany.funds.0.targetPercentage'}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
          const isDisable = isConfirmed || (totalPercentFundOneToMany >= 100 && Number(value || 0) === 0)
          return (
            <InputTable
              onChange={(e) => {
                onChange(e)
                calculateSourceFund(index)
              }}
              onBlur={onBlur}
              value={value?.toString()}
              errorMessage={error?.message}
              inputType="number"
              maxLength={3}
              disabled={isDisable}
              containerStyle={{ marginHorizontal: 16 }}
              inputStyle={{
                backgroundColor: isDisable ? '#FAFAFA' : '#FFFFFF',
                borderColor: '#D3DCE6'
              }}
            />
          )
        }}
      />
    )
  }

  const renderAmountController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`oneToMany.funds.${index}.amount` as 'oneToMany.funds.0.amount'}
        render={({ field }) => (
          <Input {...field} value={field.value} disabled containerStyle={{ marginHorizontal: 16 }} />
        )}
      />
    )
  }

  // Target columns

  const renderSourceFundController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`manyToOne.funds.${index}.code` as 'manyToOne.funds.0.code'}
        render={({ field }) => (
          <Input
            {...field}
            value={`${field.value} - ${t(`${FundMapping.mapFunds.get(field.value)}`) ?? '-'}`}
            disabled
          />
        )}
      />
    )
  }

  const renderUnitValueController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`manyToOne.funds.${index}.unitValue` as 'manyToOne.funds.0.unitValue'}
        render={({ field }) => <Input {...field} value={field.value} disabled />}
      />
    )
  }

  const renderSourcePercentController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        rules={{
          required: {
            value: true,
            message: `${t('message:MS020001', { field: t('requestInfo:TargetPercentage') })}`
          },
          max: {
            value: 100,
            message: `${t('message:MS050175')}`
          },
          min: {
            value: 0,
            message: `${t('message:MS050175')}`
          }
        }}
        name={`manyToOne.funds.${index}.targetPercentage` as 'manyToOne.funds.0.targetPercentage'}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <InputTable
            onChange={(e) => {
              onChange(e)
              calculateSourcePercent(index)
            }}
            onBlur={onBlur}
            value={value?.toString()}
            errorMessage={error?.message}
            inputType="number"
            maxLength={3}
            disabled={isConfirmed}
            containerStyle={{ marginHorizontal: 16 }}
          />
        )}
      />
    )
  }

  const renderSourceAmountController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`manyToOne.funds.${index}.amount` as 'manyToOne.funds.0.amount'}
        render={({ field }) => (
          <Input {...field} value={field.value} disabled containerStyle={{ marginHorizontal: 16 }} />
        )}
      />
    )
  }

  const columnsOneToMany: Columns[] = [
    {
      key: '0',
      name: 'fundName',
      title: t('requestInfo:TargetFund'),
      render: renderFundController
    },
    {
      key: '1',
      name: 'targetPercentage',
      require: true,
      styles: { marginHorizontal: 16 },
      title: t('requestInfo:TargetPercentage') + ' (%)',
      render: renderPercentController
    },
    {
      key: '2',
      name: 'amount',
      styles: { marginHorizontal: 16 },
      title: t('requestInfo:TargetAmount'),
      render: renderAmountController
    }
  ]

  const columnsManyToOne: Columns[] = [
    {
      key: '0',
      name: 'code',
      title: t('requestInfo:SourceFund'),
      render: renderSourceFundController
    },
    {
      key: '1',
      name: 'unitValue',
      styles: { marginHorizontal: 16 },
      title: t('requestInfo:UnitValue'),
      render: renderUnitValueController
    },
    {
      key: '2',
      name: 'targetPercentage',
      require: true,
      styles: { marginHorizontal: 16 },
      title: t('requestInfo:SourcePercentage') + ' (%)',
      render: renderSourcePercentController
    },
    {
      key: '3',
      name: 'amount',
      styles: { marginHorizontal: 16 },
      title: t('requestInfo:SourceAmount'),
      render: renderSourceAmountController
    }
  ]

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Text style={styles.headerField}>{t('submission:FundSwitchingInformation')}</Text>
      <View style={styles.sectionContent}>
        <View style={styles.sectionRow}>
          <View style={isMobile ? styles.colMobile : styles.col}>
            <RadioButton
              label={t('requestInfo:From1FundToManyFunds')}
              selected={is1toN}
              onChange={() => onChangeFund(true)}
              disable={isConfirmed}
            />
          </View>
          <View style={isMobile ? styles.colMobile : styles.col}>
            <View style={styles.text}>
              <RadioButton
                label={t('requestInfo:FromManyFundsTo1Fund')}
                selected={!is1toN}
                onChange={() => onChangeFund(false)}
                disable={isConfirmed}
              />
            </View>

            {!is1toN && isULR6() && (
              <View style={styles.subSelect}>
                <View style={styles.text}>
                  <RadioButton
                    label={t('requestInfo:TargetPremiumAccount')}
                    selected={isTargetPremium}
                    onChange={() => onChangeTargetPremium(true)}
                    disable={isConfirmed}
                  />
                </View>
                <View style={styles.text}>
                  <RadioButton
                    label={t('requestInfo:TopupAccount')}
                    selected={!isTargetPremium}
                    onChange={() => onChangeTargetPremium(false)}
                    disable={isConfirmed}
                  />
                </View>
              </View>
            )}
          </View>
        </View>

        {is1toN && (
          <>
            <View style={[styles.sectionRow, styles.secondLine]}>
              <View style={styles.col_4}>
                <Controller
                  name="oneToMany.sourceFund"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020009', { field: t('requestInfo:SourceFund') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      required
                      popupIcon={isConfirmed ? <></> : <assets.ArrowDownDropdownIcon />}
                      onChange={(val) => {
                        onChange(val)
                        onChangeSourceFund(val)
                      }}
                      onBlur={onBlur}
                      value={value}
                      label={t('requestInfo:SourceFund')}
                      placeholder={t('common:Select')}
                      disabled={isConfirmed}
                      disableUnderline={isConfirmed}
                      errorMessage={error?.message}
                      options={totalFund.map((fund) => ({
                        label: fund.fundCode + ' - ' + t(`${fundMap.get(fund.fundCode)}`),
                        value: fund.fundCode
                      }))}
                      key={value?.value}
                    />
                  )}
                />
              </View>
              <View style={styles.col_4}>
                <Controller
                  name="oneToMany.unitValue"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:UnitValue')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      alwayShowUnderline={false}
                      disabled
                    />
                  )}
                />
              </View>
              <View style={styles.col_4}>
                <Controller
                  name="oneToMany.sourcePercentage"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020001', { field: t('requestInfo:TargetPercentage') })}`
                    },
                    max: {
                      value: 100,
                      message: `${t('message:MS050175')}`
                    },
                    min: {
                      value: 1,
                      message: `${t('message:MS050175')}`
                    },
                    // validate: {
                    //   positive: (v) =>
                    //     !(
                    //       isULR() &&
                    //       PulseOpsFormat.thousandSepartorReverse(watch('oneToMany.unitValue') ?? '0') < 2 * k &&
                    //       parseInt(v) !== 100
                    //     ) || `${t('message:MS050235')}`
                    // }
                    validate: {
                      positive: (v) => {
                        const unitValue = PulseOpsFormat.thousandSepartorReverse(watch('oneToMany.unitValue') ?? '0')
                        const amountValue = PulseOpsFormat.thousandSepartorReverse(
                          watch('oneToMany.sourceAmount') ?? '0'
                        )
                        if (isULR() && unitValue >= x + y) {
                          if (amountValue < x) {
                            return `${t('message:MS050201', { k: PulseOpsFormat.thousandSepartor(k) })}`
                          } else if (unitValue - amountValue !== 0 && unitValue - amountValue < y) {
                            return `${t('message:MS050202', { min: min })}`
                          }
                        }
                        return true
                      }
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:SourcePercentage') + ' (%)'}
                      onChange={(e) => {
                        onChangeSourcePercentage(e)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      maxLength={3}
                      required
                      alwayShowUnderline={!isConfirmed}
                      disabled={isConfirmed}
                    />
                  )}
                />
              </View>
            </View>
            <View style={[styles.sectionRow, styles.secondLine]}>
              <View style={styles.col_4}>
                <Controller
                  name="oneToMany.sourceAmount"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:SourceAmount')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      maxLength={24}
                      alwayShowUnderline={false}
                      disabled
                    />
                  )}
                />
              </View>
            </View>
          </>
        )}

        {is1toN && watch('oneToMany.funds').length > 0 && (
          <View style={{ marginTop: 16 }}>
            <HeaderTable
              selectable={false}
              headerContainerStyle={{ backgroundColor: '#D3DCE6' }}
              rowStyle={{ backgroundColor: '#FAFAFA', paddingHorizontal: 10 }}
              selectBoxContainerStyle={{ marginEnd: 100 }}
              columns={columnsOneToMany}
              dataSource={watch('oneToMany.funds').map((fundItem: TargetFund) => ({
                code: fundItem.code,
                targetPercentage: fundItem.targetPercentage,
                amount: fundItem.amount
              }))}
            />
          </View>
        )}

        {!is1toN && (
          <View style={{ marginTop: 16 }}>
            <HeaderTable
              selectable={false}
              headerContainerStyle={{ backgroundColor: '#D3DCE6' }}
              rowStyle={{ backgroundColor: '#FAFAFA' }}
              selectBoxContainerStyle={{ marginEnd: 100 }}
              columns={columnsManyToOne}
              dataSource={
                watch('manyToOne.funds').map((fundItem: SourceFund) => ({
                  code: fundItem.code,
                  unitValue: fundItem.unitValue,
                  targetPercentage: fundItem.targetPercentage,
                  amount: fundItem.amount
                })) || []
              }
            />
          </View>
        )}
        {!is1toN && (
          <>
            <View style={[styles.sectionRow, styles.secondLine]}>
              <View style={styles.col_4}>
                <Controller
                  name="manyToOne.targetFund"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020009', { field: t('requestInfo:TargetFund') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      popupIcon={isConfirmed ? <></> : <assets.ArrowDownDropdownIcon />}
                      onChange={(val) => {
                        onChange(val)
                        onChangeTargetFund(val)
                      }}
                      onBlur={onBlur}
                      // value={value}
                      value={value}
                      label={t('requestInfo:TargetFund')}
                      placeholder={t('common:Select')}
                      disabled={isConfirmed}
                      disableUnderline={isConfirmed}
                      errorMessage={error?.message}
                      options={optionTargetFund().map((fund) => ({
                        label: `${fund.code} - ${t(`${fund.name}`)}`,
                        value: fund.code
                      }))}
                      key={value?.value}
                    />
                  )}
                />
              </View>
              <View style={styles.col_4}>
                <Controller
                  name="manyToOne.percentage"
                  control={control}
                  render={({ field: { onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:ReceivedPercentage') + ' (%)'}
                      onChange={(e) => {
                        onChangeSourcePercentage(e)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      maxLength={3}
                      required
                      alwayShowUnderline={false}
                      disabled
                    />
                  )}
                />
              </View>
              <View style={styles.col_4}>
                <Controller
                  name="manyToOne.amount"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('requestInfo:TargetAmount')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      alwayShowUnderline={false}
                      disabled
                    />
                  )}
                />
              </View>
            </View>
          </>
        )}
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col: {
    width: '33.333333334%'
  },
  colMobile: {
    width: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  subSelect: {
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    marginBottom: 10
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  red_line: {
    color: '#ed1c2e'
  }
})
