/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import { View, TouchableOpacity, Text, ScrollView, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppContext, ErrorHandling, GroupPolicyPermission, RBAC } from '@pulseops/common'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { UserDrawerParamList } from 'libs/main/src/user/drawer/UserDrawerParamList'
import { SEABillingChangeConst } from './billing-change/SEABillingChangeConst'
import { pipe } from 'fp-ts/lib/function'

export function SEAPolicyGroupScreen() {
  const props = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()
  const { t, i18n } = useTranslation('BillingChange')
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { width } = useWindowDimensions()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('GroupPolicy'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  return (
    <View style={{ backgroundColor: '#fff', height: '100%' }}>
      <ScrollView
        style={{
          width: width <= 1440 ? width : 1440,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <View style={{ flexDirection: 'row', padding: 10 }}>
          <TouchableOpacity
            disabled={
              permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu)
                ? false
                : true
            }
            style={{
              marginTop: 100,
              flex: 1
            }}
            onPress={() => {
              props.navigate('PolicyGroupListStack', {
                screen: 'SEABillingChangeScreen',
                params: { type: SEABillingChangeConst.ImportType.ImportBillingChange }
              })
            }}
          >
            <View
              style={{
                width: i18n.language === 'en' ? '40%' : '70%',
                height: 50,
                backgroundColor:
                  permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu)
                    ? '#FFFFFF'
                    : '#ccc',
                borderRadius: 100,
                padding: 10,
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-end',
                borderWidth: 1,
                borderColor:
                  permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu)
                    ? 'red'
                    : '#ccc'
              }}
            >
              <Text
                style={{
                  textAlign: 'center',
                  color:
                    permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu)
                      ? 'red'
                      : 'white',
                  fontWeight: '800'
                }}
              >
                {t('ImportFileForBillingChange')}
              </Text>
            </View>
          </TouchableOpacity>
          <View style={{ width: 100 }} />
          <TouchableOpacity
            disabled={
              permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu)
                ? false
                : true
            }
            style={{
              marginTop: 100,
              flex: 1
            }}
            onPress={() => {
              props.navigate('PolicyGroupListStack', {
                screen: 'SEAWithdrawScreen',
                params: { type: SEABillingChangeConst.ImportType.ImportPartialWithdramw }
              })
            }}
          >
            <View
              style={{
                width: i18n.language === 'en' ? '40%' : '80%',
                height: 50,
                backgroundColor:
                  permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu)
                    ? '#FFFFFF'
                    : '#ccc',
                borderRadius: 100,
                padding: 10,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor:
                  permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu)
                    ? 'red'
                    : '#ccc'
              }}
            >
              <Text
                style={{
                  textAlign: 'center',
                  color:
                    permissions && permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu)
                      ? 'red'
                      : 'white',
                  fontWeight: '800'
                }}
              >
                {t('ImportFileForPartialWithdraw')}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  )
}
