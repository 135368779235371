/* eslint-disable no-useless-escape */
// React Lib
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { Controller, ControllerFieldState, ControllerRenderProps, UseFormStateReturn } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ZIO } from '@mxt/zio'
import moment from 'moment'

// Common
import {
  Checkbox,
  SelectOption,
  TaskType,
  TransactionType,
  assets,
  DatePicker,
  SelectSearch,
  Input,
  RadioButtonGroup,
  ChangeClientInfoService,
  ErrorHandling,
  GeneralService,
  PulseOpsFormat,
  AppContext,
  ImgUploadMutiple,
  FileUploadData,
  useGlobalLoading
} from '@pulseops/common'
import { PSSubmissionStackParamList } from '../../../PSSubmissionStackParamList'
import { RequestAuthenticateData } from '../../request-authen'
import { UploadedFilesInfo } from '../policy-service-props'
import { FormData } from './change-other-infomation-personal-form'
import {
  BenIDCardOption,
  COLORS,
  FieldsType,
  groupsViewsConst,
  GroupsViewType,
  HiddenType,
  issuedByAdult,
  typeField,
  TypeFieldEnum,
  typeGroupName,
  typeNameField,
  valuesFormType
} from './change-other-infomation-personal-const'
import { CN, TextCustom } from './change-other-infomation-personal-style'
import { ChangeOtherInfomationPersonalService } from './change-other-infomation-personal-service'
import { useForm } from 'react-hook-form'
import { BenIDCard } from '../..'
import { CustomerDataAML } from './models'

const { Container, Row, Col_3, GROUP, TabText } = CN

interface Props {
  isConfirmed: boolean
  initSubmission: ({ validate, clear }: { validate: unknown; clear: () => void }) => void
}

const defaultValues: FormData = {
  selectClient: null,
  idType: null,
  idNumber: '',
  issuedBy: null,
  issuedDate: null,
  surName: '',
  givenName: '',
  nationality: null,
  usTaxDeclarationFrom: '',

  isCheckChangeCardGroup: true,
  isCheckChangeFullNameGroup: true,
  isCheckChangeNationalityGroup: true,

  // AML check

  // taxResidencyCountry: null,
  foreignAddress: '',
  countryOfForeignAddress: null,
  nationality2: null
}

const ChangeOtherInfomationPersonalScreen: React.FC<Props> = ({ initSubmission, isConfirmed }) => {
  const { t, i18n } = useTranslation()
  const { params } = useRoute<RouteProp<PSSubmissionStackParamList, 'PSSubmissionScreen'>>()
  const { showToast } = useContext(AppContext.AppContextInstance)
  const { bindLoader } = useGlobalLoading(false)
  const [groupsViews, setGroupsViews] = useState<GroupsViewType<typeGroupName, typeNameField>[] | []>(
    () => groupsViewsConst
  )
  const [files, setFiles] = useState<FileUploadData[] | null>(null)
  const [{ customers, provinces }, setData] = useState<{
    customers: CustomerDataAML[]
    provinces: SelectOption[]
  }>({
    customers: [],
    provinces: []
  })

  const getRoles = useCallback(
    (role: string) => {
      return role === 'PolicyOwner'
        ? t('roles:PolicyOwner')
        : role === 'Beneficiary'
        ? t('roles:Beneficiary')
        : role === 'LifeAssured'
        ? t('roles:LifeAssured')
        : '-'
    },
    [t]
  )

  /**
   *  Global
   */
  const idTypesOptions = useMemo(() => {
    return BenIDCardOption((value) => t(`submission:${value}`))
  }, [i18n.language])

  const { control, getValues, reset, trigger, setValue, watch, clearErrors } = useForm({
    defaultValues,
    mode: 'all'
  })

  const { nationality, nationality2 } = watch()

  const setValues = (values: valuesFormType[]) => values.map(({ key, value }) => setValue(key, value))

  const replaceArrayByGroupName = (newArray: GroupsViewType<typeGroupName, typeNameField>[]) => {
    return ChangeOtherInfomationPersonalService.replaceTwoArray<GroupsViewType<typeGroupName, typeNameField>>(
      [...groupsViews],
      newArray,
      'groupName'
    )
  }

  const mappingWithGroupFields = (groupName: typeGroupName, options: Array<{ key: typeNameField; value: unknown }>) => {
    const groupsViewsClone = [...groupsViews]
    const infoGroup = groupsViewsClone.find((elm) => elm.groupName === groupName)

    const fields =
      infoGroup?.fields.map((elm) => {
        return {
          ...elm,
          options: options.find((_e) => _e.key === elm.formName)?.value ?? elm.options ?? []
        }
      }) ?? []

    return {
      ...infoGroup,
      fields
    }
  }

  const setIDTypes = (idTypes: Array<unknown>) => {
    const changeCardGroup = mappingWithGroupFields('change_card_group', [{ key: 'idType', value: idTypes }])

    const newArray = [changeCardGroup] as GroupsViewType<typeGroupName, typeNameField>[]

    const groupsViewsFuture = replaceArrayByGroupName(newArray)
    return setGroupsViews([...groupsViewsFuture])
  }

  const detectIDTypesByDateOfBirth = (currentSelectClient: CustomerDataAML | undefined) => {
    const age = moment().diff(moment(currentSelectClient?.dob ?? '', 'YYYYMMDD'), 'years')
    const isHaveBirthDateCertificate = age <= 14

    const idTypesBirthDateCertificate = idTypesOptions.filter((elm) => elm.value === BenIDCard.BIRTH_CERTIFICATE)
    const idTypesWithoutBirthDateCertificate = idTypesOptions.filter((elm) => elm.value !== BenIDCard.BIRTH_CERTIFICATE)
    return setIDTypes(isHaveBirthDateCertificate ? idTypesBirthDateCertificate : idTypesWithoutBirthDateCertificate)
  }

  /**
   * Fetch API
   */
  pipe(
    ZIO.zipPar(
      ChangeClientInfoService.getDetail(params.policyNum),
      GeneralService.getProvinces,
      GeneralService.getCountries
    ),
    ZIO.map(([detail, provinces, countries]) => {
      const clients = detail.customerData.map((dc) => ({
        value: dc.customerId,
        label: `${dc.surName ?? ''} ${dc.name} - ${getRoles(dc.role)}`
      }))

      const provincesOptions = provinces.map((p) => ({
        value: p.code,
        label: p.name
      }))

      const countriesOptions = countries.map((c) => ({
        value: c.code,
        label: c.name
      }))

      const infoChangeClientGroup = mappingWithGroupFields('info_change_client_group', [
        { key: 'selectClient', value: clients }
      ])

      const changeCardGroup = mappingWithGroupFields('change_card_group', [{ key: 'idType', value: idTypesOptions }])

      const changeNationalityGroup = mappingWithGroupFields('change_nationality_group', [
        { key: 'nationality', value: countriesOptions },
        { key: 'nationality2', value: countriesOptions },
        // { key: 'taxResidencyCountry', value: countriesOptions },
        { key: 'countryOfForeignAddress', value: countriesOptions }
      ])

      const newArray = [infoChangeClientGroup, changeCardGroup, changeNationalityGroup] as GroupsViewType<
        typeGroupName,
        typeNameField
      >[]

      const groupsViewsFuture = replaceArrayByGroupName(newArray)

      setGroupsViews([...groupsViewsFuture])
      setData((prev) => ({
        ...prev,
        customers: detail.customerData ?? [],
        provinces: [...provincesOptions],
        countries: [...countriesOptions]
      }))
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([i18n.language])
  )

  useEffect(() => {
    clearErrors(['countryOfForeignAddress', 'foreignAddress'])
  }, [nationality, nationality2])

  /**
   * Function
   */
  const handleChangeSelectClient = async (selectClientCode: string) => {
    const customtersClone = [...customers]
    const currentSelectClient = ChangeOtherInfomationPersonalService.getClientDetail(customtersClone, selectClientCode)
    const { name = '', surName = '', idNum = '', fatca = '' } = currentSelectClient ?? {}

    detectIDTypesByDateOfBirth(currentSelectClient)

    const values: valuesFormType[] = [
      { key: 'givenName', value: name },
      { key: 'surName', value: surName },
      { key: 'idNumber', value: idNum },
      { key: 'usTaxDeclarationFrom', value: fatca ? 'Y' : 'N' }
    ]
    setValues(values)
  }

  const handleChangeIDType = (idTypeCode: string) => {
    const customtersClone = [...customers]
    const { selectClient } = getValues()
    const currentSelectClient = ChangeOtherInfomationPersonalService.getClientDetail(
      customtersClone,
      selectClient?.value ?? ''
    )
    const age = moment().diff(moment(currentSelectClient?.dob, 'YYYYMMDD'), 'years')
    const isHaveBirthDateCertificate = age <= 14

    const changeCardGroup = mappingWithGroupFields('change_card_group', [
      {
        key: 'issuedBy',
        value: idTypeCode === 'BC' ? (isHaveBirthDateCertificate ? provinces : issuedByAdult) : issuedByAdult
      }
    ])
    const newArray = [changeCardGroup] as GroupsViewType<typeGroupName, typeNameField>[]
    const groupsViewsFuture = replaceArrayByGroupName(newArray)

    setGroupsViews([...groupsViewsFuture])
  }

  const handleChangeFieldForm = (formName: typeNameField, payload: SelectOption | null) => {
    const { value = '' } = payload ?? {}
    const changeHandler = new Map<typeNameField | 'DEFAULT', () => void>([
      ['selectClient', () => handleChangeSelectClient(value)],
      ['idType', () => handleChangeIDType(value)]
    ])
    return changeHandler.get(formName)?.() ?? changeHandler.get('DEFAULT')?.()
  }

  /**
   * VIEWS
   */

  const conditionRequiredAddress = () => {
    const { nationality, nationality2 } = watch()
    return {
      isRequiredAddressByNationality: nationality ? nationality.value !== 'VN' : false,
      isRequiredAddressByNationality2: nationality2 ? nationality2.value !== 'VN' : false
    }
  }

  const detectTextRequired = (formName: typeNameField) => {
    if (
      ['selectClient', 'idType', 'idNumber', 'issuedDate', 'issuedBy', 'surName', 'givenName', 'nationality'].includes(
        formName
      )
    ) {
      return true
    }

    if (['foreignAddress', 'countryOfForeignAddress'].includes(formName)) {
      const { isRequiredAddressByNationality, isRequiredAddressByNationality2 } = conditionRequiredAddress()

      if (isRequiredAddressByNationality || isRequiredAddressByNationality2) return true
      return false
    }

    return false
  }

  const renderTypeField = ({
    field,
    controller
  }: {
    field: FieldsType<typeNameField>
    controller: {
      field: ControllerRenderProps<FormData, typeNameField>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<FormData>
    }
  }) => {
    const { type, placeholder, required, options, editable, maxLength = 100 } = field
    const {
      field: { onChange, onBlur, value },
      fieldState: { error }
    } = controller

    const label = t(`CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.${field.formName}`)
    const disabled = isConfirmed || !editable
    const isRequired = detectTextRequired(field.formName)

    const fieldTypeMap = new Map<typeField | 'DEFAULT', () => void>([
      [
        TypeFieldEnum['TEXTBOX'],
        () => (
          <Input
            title={label}
            value={value as string}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            required={isRequired}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            maxLength={maxLength}
          />
        )
      ],
      [
        TypeFieldEnum['DATE_PICKER'],
        () => (
          <DatePicker
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value as Date}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxDate={new Date()}
            alwaysShow={false}
            disabled={disabled}
            required={isRequired}
            maxDateMessage=""
            minDateMessage=""
          />
        )
      ],
      [
        TypeFieldEnum['DROPDOWN_LIST'],
        () => (
          <SelectSearch
            label={label}
            options={options}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e)
              handleChangeFieldForm(field.formName, e)
            }}
            onBlur={onBlur}
            value={value as SelectOption}
            disabled={disabled}
            required={isRequired}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxLength={maxLength}
          />
        )
      ],
      [
        TypeFieldEnum['RADIO'],
        () => (
          <RadioButtonGroup
            disabled={disabled}
            title={label}
            style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: 0 }}
            titleStyle={{ display: 'flex', marginRight: 24 }}
            options={[
              { id: 'Y', label: t('common:Yes') },
              { id: 'N', label: t('common:No') }
            ]}
            value={value as string}
            required={required}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            onChange={onChange}
            onBlur={onBlur}
          />
        )
      ],
      ['DEFAULT', () => <></>]
    ])

    return (fieldTypeMap.get(type) ?? fieldTypeMap.get('DEFAULT'))?.()
  }

  const handleMappingRules = (formName: typeNameField) => {
    const { isCheckChangeCardGroup, isCheckChangeFullNameGroup, isCheckChangeNationalityGroup } = getValues()
    const required = (isRequired: boolean, formName: typeNameField) => {
      return {
        value: isRequired,
        message: t('message:MS020001', {
          field: t(`CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.${formName}`)
        })
      }
    }

    const detectIsRequired = (): boolean => {
      if (formName === 'selectClient') return true
      if (['idType', 'idNumber', 'issuedDate', 'issuedBy'].includes(formName)) return isCheckChangeCardGroup
      if (['givenName', 'surName'].includes(formName)) return isCheckChangeFullNameGroup
      if (['usTaxDeclarationFrom', 'nationality'].includes(formName)) return isCheckChangeNationalityGroup
      if (['countryOfForeignAddress', 'foreignAddress'].includes(formName)) {
        const { isRequiredAddressByNationality, isRequiredAddressByNationality2 } = conditionRequiredAddress()
        if (!isCheckChangeNationalityGroup) return false
        if (isRequiredAddressByNationality || isRequiredAddressByNationality2) return true
        return false
      }
      return false
    }

    return {
      required: required(detectIsRequired(), formName),
      ...(formName === 'idNumber' && {
        validate: (l: unknown) => {
          if (typeof l !== 'string') return
          if (l.length <= 1 || l.length > 24) return t('message:MS050216')
          return true
        }
      }),
      ...(['street', 'foreignAddress'].includes(formName) && {
        validate: (l: unknown) => {
          if (typeof l !== 'string') return
          const pattern = /^[^*&$#\\<>|]*$/g
          const isMatchPattern = pattern.test(l)
          if (!isMatchPattern) return t('message:MS050223')
          return true
        }
      })
    }
  }

  const renderFields = (fields: FieldsType<typeNameField>[]) => {
    return fields.map((_field, index) => {
      const { formName } = _field
      return (
        <Col_3 key={index} style={{ marginBottom: 32 }}>
          <Controller
            name={formName}
            control={control}
            render={(controller) => <>{renderTypeField({ field: _field, controller })}</>}
            rules={handleMappingRules(_field.formName)}
          />
        </Col_3>
      )
    })
  }

  const renderCheckBox = (typeHidden: HiddenType = 'NONE', groupName: typeGroupName) => {
    const setValuesCheckBoxToGrouViews = (value: boolean, _groupName: typeGroupName) => {
      const groupsViewsClone = [...groupsViews]
      const index = groupsViewsClone.findIndex((elm) => elm.groupName === _groupName)
      const groupsViewsFuture = ChangeOtherInfomationPersonalService.replaceArraysofObject<
        GroupsViewType<typeGroupName, typeNameField>
      >(groupsViewsClone, index, { key: 'isVisible', value })

      setGroupsViews([...groupsViewsFuture])
    }

    const setValuesCheckBoxToForm = (value: boolean, _groupName: typeGroupName) => {
      const valuesCheckBox = [
        ...(_groupName === 'change_card_group' ? [{ key: 'isCheckChangeCardGroup', value }] : ([] as valuesFormType[])),
        ...(_groupName === 'change_full_name_group' ? [{ key: 'isCheckChangeFullNameGroup', value }] : []),
        ...(_groupName === 'change_nationality_group' ? [{ key: 'isCheckChangeNationalityGroup', value }] : [])
      ] as valuesFormType[]

      setValues(valuesCheckBox)
    }

    const handleChangeCheckBox = (value: boolean, _groupName: typeGroupName) => {
      setValuesCheckBoxToGrouViews(value, _groupName)
      setValuesCheckBoxToForm(value, _groupName)
    }

    const hiddenTypeMap = new Map<HiddenType, () => void>([
      [
        'CHECK_BOX',
        () => (
          <Checkbox
            value={groupsViews.find((elm) => elm.groupName === groupName)?.isVisible ?? false}
            disabled={isConfirmed}
            onChange={(value) => handleChangeCheckBox(value, groupName)}
          />
        )
      ],
      ['NONE', () => <></>]
    ])

    return (hiddenTypeMap.get(typeHidden) ?? hiddenTypeMap.get('NONE'))?.()
  }

  const renderGroupsViews = () => {
    return groupsViews?.map(({ fields, isVisible, labelGroup, isBorder, groupName, typeHidden }, index) => {
      return (
        <GROUP key={`${labelGroup}_${index}`}>
          <TextCustom fontSize="15px" fontWeight="800" textColor="black">
            {renderCheckBox(typeHidden, groupName)}
            {t(`CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.LABEL_GROUP.${groupName}`)}
          </TextCustom>

          {isVisible ? (
            <Row
              style={[
                { marginTop: 12 },
                isBorder
                  ? {
                      borderRadius: 6,
                      paddingHorizontal: 12,
                      paddingVertical: 30,
                      borderStyle: 'solid',
                      borderColor: COLORS.HOWKES_BLUE,
                      borderWidth: 1
                    }
                  : {}
              ]}
            >
              {renderFields(fields)}
            </Row>
          ) : null}
        </GROUP>
      )
    })
  }

  const renderUploadFiles = () => {
    return (
      <>
        <TextCustom textColor={COLORS.ROLLING_STONE} fontWeight="bold" fontSize="15px">
          {t('IFQ:FileAttachment')} <TabText>*</TabText>
        </TextCustom>
        <ImgUploadMutiple
          value={files ?? []}
          onChange={(files) => setFiles([...files])}
          timeFormat={'DD/MM/YYYY HH:mm'}
          maxNumFile={100}
        />

        {files && isEmpty(files) && (
          <TextCustom textColor={COLORS.ALIZARIN} fontStyle="italic" fontSize="13px">
            {t('message:MS020001', { field: t('utilities:FileAttachment') })}
          </TextCustom>
        )}
      </>
    )
  }

  /**
   * Submission
   */
  const mappingBodySubmisson = () => {
    const {
      selectClient,
      givenName,
      idNumber,
      usTaxDeclarationFrom,
      issuedBy,
      issuedDate,
      nationality,
      surName,
      idType,
      isCheckChangeFullNameGroup,
      isCheckChangeCardGroup,
      isCheckChangeNationalityGroup,

      // AML Check
      foreignAddress,
      countryOfForeignAddress,
      nationality2
      // taxResidencyCountry
    } = getValues()

    return {
      clientId: selectClient?.value ?? '',
      contactDetails: {},
      ...(isCheckChangeFullNameGroup && {
        surName: surName ?? '',
        firstName: givenName ?? ''
      }),

      ...(isCheckChangeNationalityGroup && {
        nationality: nationality?.value ?? '',
        nationalityName: nationality?.label ?? '',
        addressDetails: {
          // ADDRESS: {
          //   line1: street ?? '',
          //   city: city?.label ?? '',
          //   district: district?.label ?? '',
          //   subDistrict: ward?.label ?? '',
          //   countryCode: country?.value ?? '',
          //   location: ward?.value ?? ''
          // },
          FOREIGN_ADDRESS: {
            line1: foreignAddress ?? '',
            countryCode: countryOfForeignAddress?.value ?? '',
            country: countryOfForeignAddress?.label ?? ''
          }
        }
      }),

      attributesExt: {
        clientNum: selectClient?.value ?? '',
        isCheckChangeFullNameGroup,
        isCheckChangeCardGroup,
        isCheckChangeNationalityGroup,

        ...(isCheckChangeCardGroup && {
          idType: idType?.value ?? '',
          idNumber: idNumber ?? '',
          issuedBy: issuedBy?.value ?? '',
          issuedDate: PulseOpsFormat.datetoFormat(issuedDate, 'YYYY-MM-DD') ?? ''
        }),

        ...(isCheckChangeNationalityGroup && {
          usTaxDeclarationFrom,
          NATIONALITY_2: nationality2?.value ?? '',
          nationality2Name: nationality2?.label ?? ''
          // countryName: country?.label ?? '',
          // districtName: district?.label ?? '',
          // wardName: ward?.label ?? '',
          // taxResidencyCountry: taxResidencyCountry?.value ?? '',
          // taxResidencyCountryName: taxResidencyCountry?.label ?? ''
        }),

        transactionType: TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL
      }
    }
  }

  const getUploadedFilesInfo = () => {
    if (!files || isEmpty(files)) return
    const uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: [...files],
      transactionType: TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL,
      docTypeCode: 'DPS10',
      category: TaskType.PolicyService,
      policyNumber: params.policyNum ?? '',
      officeCode: params.officeCode ?? ''
    }
    return [...uploadedFileList, uploadedItem]
  }

  initSubmission({
    validate: async () => {
      const isValid = await trigger()

      if (!isValid) return false

      const { isCheckChangeCardGroup, isCheckChangeFullNameGroup, isCheckChangeNationalityGroup } = getValues()

      const isDontCheckAnyGroup =
        !isCheckChangeCardGroup && !isCheckChangeFullNameGroup && !isCheckChangeNationalityGroup

      if (isDontCheckAnyGroup) {
        showToast(t('message:MS020051'), 'error')
        return false
      }

      if (isEmpty(files)) {
        showToast(t('message:MS020001', { field: t('utilities:FileAttachment') }), 'error')
        return false
      }

      const selectClient = getValues('selectClient')

      return {
        url: () => `wf-api/customer/${selectClient?.value ?? ''}`,
        body: mappingBodySubmisson(),
        transactionName:
          RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL) || '',
        collerationId: params.policyNum || '',
        transaction: TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL,
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      reset({ ...defaultValues })
    }
  })

  return (
    <Container>
      {renderGroupsViews()}
      {renderUploadFiles()}
    </Container>
  )
}

export default ChangeOtherInfomationPersonalScreen
