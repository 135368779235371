import { Input } from '@pulseops/common'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Text, View, ViewStyle, StyleProp, TextStyle, StyleSheet } from 'react-native'

export interface SectionProps {
  sectionStyles?: StyleProp<ViewStyle>
  isSecondLine?: boolean
}

export interface FieldTextProps {
  text?: string
  isCurrencyUnit?: boolean
  fieldStyles?: StyleProp<TextStyle>
  isRequired?: boolean
}

export type AgentControlProps = {
  control?: Control<any, object> | undefined
  nameControl: string
  errorMessage?: string
  isErrorMessage: boolean
  disabled: boolean
  maxLength?: number
  containerStyles?: StyleProp<ViewStyle>
  onGlobalChange: (value: string) => void
}

export const SectionContent: React.FC<SectionProps> = ({ children, ...props }) => {
  return <View style={[sectionStyles.sectionContent, props.sectionStyles]}>{children}</View>
}

export const SectionRow: React.FC<SectionProps> = ({ children, ...props }) => {
  return (
    <View style={[sectionStyles.sectionRow, props.isSecondLine && sectionStyles.secondLine, props.sectionStyles]}>
      {children}
    </View>
  )
}

export const SectionCol: React.FC<SectionProps> = ({ children, ...props }) => {
  return <View style={[sectionStyles.col_4, props.sectionStyles]}>{children}</View>
}

export const FieldText: React.FC<FieldTextProps> = ({ text, fieldStyles, isCurrencyUnit, isRequired }) => {
  return (
    <Text style={[sectionStyles.field_title, fieldStyles]}>
      {text} {!!isCurrencyUnit && 'VND'}
      {!!isRequired && <Text style={sectionStyles.redLine}>*</Text>}
    </Text>
  )
}

export const FieldDescription: React.FC<FieldTextProps> = ({ text, fieldStyles, isCurrencyUnit }) => {
  return (
    <Text style={[sectionStyles.field_description, fieldStyles]}>
      {text} {isCurrencyUnit && 'VND'}
    </Text>
  )
}

export const AgentRow: React.FC<SectionProps> = ({ children, ...props }) => {
  return <View style={{ display: 'flex', flexDirection: 'row' }}>{children}</View>
}

export const AgentControl: React.FC<AgentControlProps> = ({
  control,
  nameControl,
  errorMessage,
  disabled,
  containerStyles,
  isErrorMessage,
  onGlobalChange,
  maxLength
}) => {
  return isErrorMessage ? (
    <Controller
      control={control}
      name={nameControl}
      rules={{
        required: {
          value: true,
          message: errorMessage ?? ''
        }
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Input
          value={value}
          onChange={(val) => {
            onChange(val)
            onGlobalChange(val)
          }}
          disabled={disabled}
          maxLength={maxLength}
          containerStyle={containerStyles}
          errorMessage={!!value ? '' : error?.message}
          alwayShowUnderline={true}
        />
      )}
    />
  ) : (
    <Controller
      control={control}
      name={nameControl}
      render={({ field: { value, onChange } }) => (
        <Input
          value={value}
          onChange={(val) => {
            onChange(val)
          }}
          containerStyle={containerStyles}
          disabled={disabled}
          alwayShowUnderline={true}
        />
      )}
    />
  )
}

const sectionStyles = StyleSheet.create({
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  redLine: {
    color: '#ed1b2c',
    fontSize: 15,
    fontWeight: 'bold'
  }
})
