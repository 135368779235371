import { ZIO } from '@mxt/zio'
import {
  DatePicker,
  ErrorHandling,
  Form,
  GeneralService,
  Input,
  Select,
  TaskDetail,
  SelectOption
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { ClaimInfoForm } from '../claim-type/ClaimInfoForm'
import moment from 'moment'
interface Props {
  props: {
    detail: TaskDetail.VerificationClaim
    form: UseFormReturn<ClaimInfoForm>
  }
}

export const PayAtCounter: React.FC<Props> = ({ props }) => {
  const { form, detail } = props
  const { PAYMENT } = detail
  const register = Form.register(form)
  const { watch } = form
  const { isNotPolicyOwner } = watch()
  const { t, i18n } = useTranslation('claim')

  const officeCodes =
    pipe(
      GeneralService.getOfficeCodes('CSC'),
      ZIO.map((codes) => {
        const gaList: SelectOption[] =
          codes.map((item) => ({
            value: item.code,
            label: `${item.code} - ${i18n.language === 'vi' ? item.nameVi : item.nameEn}`
          })) || []
        const gaOffice = gaList.find((cond) => cond.value === PAYMENT?.payAtCounter?.gaOffice) || null
        form.setValue('payAtTheCounter.gaOffice', gaOffice)
        return gaList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const relationships =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) => {
        const relaList: SelectOption[] =
          r.map((item) => ({
            value: item.code,
            // label: `${item.code} - ${i18n.language === 'vi' ? item.nameVn : item.nameEn}`
            label: `${item.code} - ${i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN}`
          })) || []
        const rela = relaList.find((cond) => cond.value === PAYMENT?.relationshipWithPO) || null
        form.setValue('payAtTheCounter.relationshipWithPO', rela)
        return relaList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const countries =
    pipe(
      GeneralService.getCountries,
      ZIO.map((c) => {
        const countryList: SelectOption[] =
          c.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        const nationality = countryList.find((cond) => cond.value === PAYMENT?.nationality) || null
        const nationality2 = countryList.find((cond) => cond.value === PAYMENT?.nationality2) || null
        const residenceCountry = countryList.find((cond) => cond.value === PAYMENT?.residenceCountry) || null
        form.setValue('payAtTheCounter.nationality', nationality)
        form.setValue('payAtTheCounter.nationality2', nationality2)
        form.setValue('payAtTheCounter.residenceCountry', residenceCountry)
        return countryList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const occupations =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((oc) => {
        const ocupationList: SelectOption[] =
          oc.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        const occupation = ocupationList.find((cond) => cond.value === PAYMENT?.occupation) || null

        form.setValue('payAtTheCounter.occupation', occupation)
        return ocupationList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  React.useEffect(() => {
    const genderGen = (value: string) =>
      value === 'F' ? `${t('common:Female')}` : value === 'M' ? `${t('common:Male')}` : value

    const genderObj = PAYMENT?.gender
      ? {
          value: PAYMENT?.gender || '-',
          label: PAYMENT?.gender ? genderGen(PAYMENT.gender) : '-'
        }
      : null
    form.setValue('payAtTheCounter.gender', genderObj)
    if (isNotPolicyOwner) {
      form.setValue('payAtTheCounter.foreignAddress', PAYMENT?.foreignAddress || '-')
      form.setValue('payAtTheCounter.address', PAYMENT?.address || '-')
      form.setValue('payAtTheCounter.dateOfBirth', moment(PAYMENT?.dob).toDate() || '-')
      form.setValue('payAtTheCounter.payeeName', PAYMENT?.payAtCounter?.payeeName || '-')
      form.setValue('payAtTheCounter.idNumber', PAYMENT?.payAtCounter?.idNumber || '-')
    }
  }, [i18n.language, detail])

  return (
    <SafeAreaView>
      <View style={styles.row}>
        <View style={styles.col}>
          <Select
            {...register('payAtTheCounter.gaOffice')}
            disabled
            options={officeCodes}
            label={t('GAOffice')}
            showUnderline={false}
          />
        </View>
        <View style={styles.col}>
          <Input {...register('payAtTheCounter.payeeName')} disabled title={t('PayeeName')} />
        </View>
        <View style={[styles.col, { paddingEnd: 100 }]}>
          <Text style={styles.label}>{t('IDNumber')}</Text>
          <Input disabled {...register('payAtTheCounter.idNumber')} />
        </View>
        {!isNotPolicyOwner && (
          <View style={[styles.col, { paddingEnd: 100 }]}>
            <Text style={styles.label}>{t('DateOfBirth')}</Text>
            <DatePicker {...register('payAtTheCounter.dateOfBirth')} disabled label={''} />
          </View>
        )}
      </View>
      {isNotPolicyOwner && (
        <>
          <View style={styles.row}>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Select
                {...register('payAtTheCounter.relationshipWithPO')}
                disabled
                options={relationships}
                label={t('RelationWithPolicyOwner')}
                showUnderline={false}
              />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('DateOfBirth')}</Text>
              <DatePicker {...register('payAtTheCounter.dateOfBirth')} disabled label={''} />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Gender')}</Text>
              <Select
                {...register('payAtTheCounter.gender')}
                disabled
                options={[
                  {
                    value: 'F',
                    label: t('common:Female')
                  },
                  {
                    value: 'M',
                    label: t('common:Male')
                  }
                ]}
                showUnderline={false}
              />
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>{t('ResidenceCountry')}</Text>
              <Select
                {...register('payAtTheCounter.residenceCountry')}
                disabled
                options={countries}
                showUnderline={false}
              />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Occupation')}</Text>
              <Select
                {...register('payAtTheCounter.occupation')}
                options={occupations}
                disabled
                showUnderline={false}
              />
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Nationality')}</Text>
              <Select {...register('payAtTheCounter.nationality')} disabled options={countries} showUnderline={false} />
            </View>
            <View style={[styles.col]}>
              <Text style={styles.label}>{t('Address')}</Text>
              <Input {...register('payAtTheCounter.address')} disabled title={''} multiline numberOfLines={4} />
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.col]}>
              <Text style={styles.label}>{t('Nationality2')}</Text>
              <Select
                {...register('payAtTheCounter.nationality2')}
                disabled
                options={countries}
                showUnderline={false}
              />
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>{t('ForeignAddress')}</Text>
              <Input {...register('payAtTheCounter.foreignAddress')} disabled title={''} multiline numberOfLines={4} />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
