import { useLoading } from '@mxt/zio-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'
import { IBGeneralTable, IBPanel, IBStyles } from '../../../../../common'
import { ErrorHandling, GeneralService, assets } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { IBService, AgentInfoResponse, ServicingAgentInfo } from '../../../../../ib-service'
import { orderBy } from 'lodash'
import moment from 'moment'
type AgentHistory = {
  agentCode: string,
  agentName: string,
  fromDate: string,
  toDate: string
}

export const IBAgentInfoTab = (props: { policyNumber?: string }) => {
  const { t, i18n } = useTranslation()
  const [loading, bindLoading] = useLoading()
  const [agentInfo, setAgentInfo] = React.useState<AgentInfoResponse | null>(null)
  const [agentHistories, setAgentHistories] = React.useState<AgentHistory[]>([])

  const officeCodes = pipe(
    GeneralService.getALLOfficeList(),
    ZIO.map((codes) => {
      return (
        codes.map((code) => ({
          gaCode: code.gaCode,
          code: code.code,
          label: `${i18n.language === 'vi' ? code.nameVi : code.nameEn}`
        }))
      )
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (props.policyNumber) {
      pipe(
        IBService.getAgentInfo(props.policyNumber),
        ZIO.map((res) => {
          res && setAgentInfo(res)
          res.data.agentHistory && setAgentHistories(res.data.agentHistory.map((i) => ({
            agentCode: i.code ?? '',
            agentName: i.name?.name ?? '',
            fromDate: i.fromDate ? moment(i.fromDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
            toDate: i.toDate ? moment(i.toDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [props.policyNumber])

  const getBranchRegionManager = (code?: string | null, agentCode?: string | null, name?: string | null) => {
    return (code ? code + ' - ' : '') + (agentCode ? agentCode + ' - ' : '') + (name ?? '')
  }

  const showOfficeLabel = (code: string) => {
    const findData = officeCodes?.find((i) => i.code === code)?.label ?? ''
    return code + ' - ' + findData
  }

  const dataTableAgentHistory = [
    { label: t('Inbound:AgentCodes'), field: 'agentCode' },
    { label: t('Inbound:AgentName'), field: 'agentName' },
    { label: t('Inbound:FromDate'), field: 'fromDate', format: 'date' },
    { label: t('Inbound:ToDate'), field: 'toDate', format: 'date' }
  ]

  const dataCommonField = (data: ServicingAgentInfo | undefined) => [
    {
      title: t('Inbound:Dob'),
      value: data?.dob
    },
    {
      title: t('Inbound:ClientNo'),
      value: data?.clientId
    },
    {
      title: t('Inbound:UnitManager'),
      value: data?.unitManager ? getBranchRegionManager(data.unitManager.unitCode, data.unitManager.agentCode, data.unitManager.name?.name) : '-'
    },
    {
      title: t('Inbound:AdminPerson'),
      value: data?.adminManager
    },
    {
      title: t('Inbound:ID'),
      value: data?.nationalId
    },
    {
      title: t('Inbound:OfficeCode'),
      value: data?.officeCode ? (data?.officeCode + ' - ' + (data?.officeName ?? '')) : ''
    },
    {
      title: t('Inbound:MobilePhone'),
      value: data?.unitManager?.mobilePhone
    },
    {
      title: t('Inbound:RegionManager'),
      value: data?.regionManager
    },
    {
      title: t('Inbound:TerminateDate'),
      value: data?.terminateDate
    },
    {
      title: t('Inbound:GACode'),
      value: (data?.gaCode ? (data?.gaCode + ' - ') : '') + (data?.gaName ? ('GAD: ' + data?.gaName) : '')
    },
    {
      title: t('Inbound:BranchManager'),
      value: data?.branchManager ? getBranchRegionManager(data.branchManager.branchCode, data.branchManager.agentCode, data.branchManager.name?.name) : '-'
    },
    {
      title: t('Inbound:HeadTeam'),
      value: data?.headTeam
    },
    {
      title: t('Inbound:TerminationReason'),
      value: data?.terminationReason
    },
    {
      title: t('Inbound:Region'),
      value: data?.region
    },
    {
      title: t('Inbound:MobilePhone'),
      value: data?.branchManager?.mobilePhone
    },
    {
      title: t('Inbound:SubZoneHead'),
      value: data?.subZoneHead
    }
  ]

  const agentList = [
    {
      title: t('Inbound:ServicingAgent'),
      agentCode: agentInfo?.data.servicingAgent?.code,
      agentName: agentInfo?.data.servicingAgent?.name?.name,
      gender: agentInfo?.data.servicingAgent?.gender,
      phone: agentInfo?.data.servicingAgent?.mobilePhone,
      dataCommonField: dataCommonField(agentInfo?.data.servicingAgent)
    },
    {
      title: t('Inbound:ShareServicingAgent'),
      agentCode: agentInfo?.data.sharingServicingAgent?.code,
      agentName: agentInfo?.data.sharingServicingAgent?.name?.name,
      gender: agentInfo?.data.sharingServicingAgent?.gender,
      phone: agentInfo?.data.sharingServicingAgent?.mobilePhone,
      dataCommonField: dataCommonField(agentInfo?.data.sharingServicingAgent)
    }
  ]

  const AgentInfo = () => {
    return agentList.map((i) =>
      <View style={[IBStyles.container, { padding: 0 }]}>
        <View style={[IBStyles.row, { backgroundColor: '#EBEDF0', padding: 20 }]}>
          <View style={IBStyles.sectionCol24}>
            <Text style={IBStyles.header}>{i.title}</Text>
          </View>
          <View style={IBStyles.sectionCol24}>
            <Text style={IBStyles.header}>{i.agentCode}</Text>
          </View>
          <View style={{ width: '22%' }}>
            <Text style={[IBStyles.header, { textTransform: 'none' }]}>{i.agentName}</Text>
          </View>
          <View style={{ width: '18%' }}>
            {i.gender === 'M' ? <assets.IBMaleIcon /> : i.gender === 'F' ? <assets.IBFemaleIcon /> : <></>}
          </View>
          <View style={IBStyles.sectionCol24}>
            <Text style={IBStyles.header}>{i.phone}</Text>
          </View>
        </View>
        <View style={[IBStyles.row, { padding: 20 }]}>
          {i.agentCode ?
            i.dataCommonField.map((field) => (
              <View style={[IBStyles.sectionCol3, { paddingBottom: 24 }]}>
                <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                  <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{field.title}</Text>
                </View>
                <Text style={{ fontSize: 15, marginTop: 4 }}>{field.value ?? '-'}</Text>
              </View>
            )) : <Text style={{ fontSize: 15, marginTop: 4, width: '100%', textAlign: 'center' }}>{t('common:noData')}</Text>
          }
        </View>
      </View>
    )
  }

  const AgentHistory = () => {
    return (
      <View style={[IBStyles.row]}>
        <IBPanel
          title={t('Inbound:AgentHistory')}
          styleContainer={{ width: '100%', marginTop: 0 }}
          children={
            <IBGeneralTable
              dataTable={dataTableAgentHistory}
              data={orderBy(agentHistories, ['fromDate'], ['desc'])}
              autoPaging={true}
            />
          }
        />
      </View>
    )
  }

  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <View style={styles.container}>
          {AgentInfo()}
          {AgentHistory()}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20
  }
})