import { Frequency } from './Frequency'

export namespace BillingChange {
  export interface Detail {
    curBillingFrequency: string // Định kỳ nộp phí bảo hiểm hiện tại
    curInstallmentPremium: string // Phí bảo hiểm định kỳ hiện tại
    newBillingFrequency: Frequency | null // Định kỳ nộp phí bảo hiểm mới
    newInstallmentPremium: string // Phí bảo hiểm định kỳ mới
    suspenseAmount: string // Phí dư
    advancePremium: string // Phí bảo hiểm nộp trước
    effectiveDateNewFrequency: string // Ngày hiệu lực của định kỳ mới
    requirePayinAmount: string // Số tiền cần nộp
    billToDate: string
    paidToDate: string
    curRenewalPremium: string
    validNewInstallmentPremium: boolean
    validProduct: boolean
  }

  export interface NextInstallmentPre {
    action: string
    minimumContribution: string
    nextInstallmentPremium: string
  }
  export interface SubmitData {
    curRenewalPremium: string
    suspenseAmount: string
    advancePremium: string
    billToDate: string
    paidToDate: string
    newBillingFrequency: Frequency | null
    curBillingFrequency: string
    curInstallmentPremium: string
    newInstallmentPremium: string
    effectiveDateNewFrequency: string
    requirePayinAmount: string
  }
}
