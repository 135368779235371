import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBStyles, IBPanel, IBGeneralTable, viewTiffFileByNewTab } from '../../../../../common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import {
  ClaimPaymentInfo,
  ClientBenInfo,
  ClientInsuranceInfo,
  ClientSpecialBenInfo,
  IBService,
  JointLife,
  LifeAssuredInfo,
  MasterProductsData,
  ProductInfo,
  RoleInsuranceBenefit
} from '../../../../../ib-service'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  AuthService,
  ContractMapping,
  ErrorHandling,
  GeneralService,
  ModalComponent,
  PremiumStatusMapping,
  PulseOpsFormat,
  useTranslator
} from '@pulseops/common'
import { IBClaimPaymentDetail } from './IBClaimPaymentDetail'
import { isEmpty, isNil } from 'lodash'
import { isNull } from 'lodash'

type Props = {
  policyNumber?: string
  laList: ClientInsuranceInfo[] | null
  setLaList: (data: ClientInsuranceInfo[]) => void
  benList: ClientBenInfo[] | null
  setBenList: (data: ClientBenInfo[]) => void
  specialBenList: ClientSpecialBenInfo[] | null
  setSpecialBenList: (data: ClientSpecialBenInfo[]) => void
  listProduct: LifeAssuredInfo[] | null
  setListProduct: (data: LifeAssuredInfo[]) => void
  listPaymentClaim: ClaimPaymentInfo[] | null
  setListPaymentClaim: (data: ClaimPaymentInfo[]) => void
  productJoinLife: LifeAssuredInfo | null
  setProductJoinLife: (data: LifeAssuredInfo) => void
}

export const IBIsuranceBenefitTab = ({
  policyNumber,
  laList,
  setLaList,
  benList,
  setBenList,
  specialBenList,
  setSpecialBenList,
  listProduct,
  setListProduct,
  listPaymentClaim,
  setListPaymentClaim,
  productJoinLife,
  setProductJoinLife
}: Props) => {
  const { t, i18n } = useTranslation()
  const ct = useTranslator('contract').t
  const pst = useTranslator('premiumStatus').t

  const [loading, bindLoading] = useLoading(false)
  const [loadingScreen, bindLoadingScreen] = useLoading(false)
  const [showModalEAL, setShowModalEAL] = React.useState<{ isShow: boolean, lifeAssuredCode: string } | null>(null)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [listShowDetail, setListShowDetail] = React.useState<boolean[]>([])
  const [productListName, setProductListName] = React.useState<MasterProductsData[]>([])

  const statusMap = [
    {
      status: 'ACTIVE',
      enText: 'New',
      viText: 'Hiệu lực'
    },
    {
      status: 'INACTIVE',
      enText: 'Old',
      viText: 'Đã hủy'
    }
  ]

  const getStatusLabel = (status: string) => {
    const findStatus = statusMap.find((i) => status === i.status)
    return i18n.language === 'en' ? findStatus?.enText : findStatus?.viText
  }

  const listPaymentClaimLabel = [
    {
      sacsCode: 'LP',
      sacsType: 'HS',
      enLabel: 'Hospital care benefits (LP_HS)',
      viLabel: 'Quyền lợi bảo hiểm chăm sóc sức khỏe (LP_HS)'
    },
    {
      sacsCode: 'LP',
      sacsType: 'HM',
      enLabel: 'Hospital care with surgery benefits (LP_HM)',
      viLabel: 'Quyền lợi bảo hiểm chăm sóc sức khỏe kèm phẩu thuật (LP_HM)'
    },
    {
      sacsCode: 'LP',
      sacsType: 'HI',
      enLabel: 'Health care benefits (LP_HI)',
      viLabel: 'Quyền lợi bảo hiểm PRU - Hành Trang Vui Khỏe (LP_HI)'
    },
    {
      sacsCode: 'LP',
      sacsType: 'WV',
      enLabel: 'Critical illness benefits (LP_WV)',
      viLabel: 'Quyền lợi bảo hiểm Bệnh Hiểm Nghèo (LP_WV)'
    },
    {
      sacsCode: 'LP',
      sacsType: 'TD',
      enLabel: 'Total and permanent dismemberment benefits (LP_TD)',
      viLabel: 'Quyền lợi bảo hiểm Thương Tật (LP_TD)'
    },
    {
      sacsCode: 'LP',
      sacsType: 'DX',
      enLabel: 'Death claim benefits (LP_DX)',
      viLabel: 'Quyền lợi bảo hiểm Tử Vong (LP_DX)'
    },
    {
      sacsCode: 'LP',
      sacsType: 'DE',
      enLabel: 'Premium refund benefits (LP_DE)',
      viLabel: 'Quyền lợi hoàn phí (LP_DE)'
    },
    {
      sacsCode: 'GL',
      sacsType: 'WV',
      enLabel: 'Premium waiver benefits (GL_WV)',
      viLabel: 'Quyền lợi Miễn đóng phí (GL_WV)'
    }
  ]

  const listClaimPaymentParams = listPaymentClaimLabel.map((item) => ({
    sacsCode: item.sacsCode,
    sacsType: item.sacsType
  }))

  React.useEffect(() => {
    showGlobalLoading(loadingScreen)
  }, [loadingScreen])

  const getData = () => {
    if (policyNumber) {
      pipe(
        ZIO.zipPar(
          IBService.getListClientInsuranBenefit(policyNumber, RoleInsuranceBenefit.LA),
          IBService.getListClientInsuranBenefit(policyNumber, RoleInsuranceBenefit.BEN),
          IBService.getListClientInsuranBenefit(policyNumber, RoleInsuranceBenefit.SPEC_BEN),
          IBService.getClaimPaymentList(policyNumber, listClaimPaymentParams),
          IBService.getProductPolicy(policyNumber),
          GeneralService.getRelationship()
        ),
        ZIO.map(([la, ben, specBen, list, products, relationships]) => {
          la &&
            setLaList(
              la.data.sort((p1, p2) => {
                if (Number(p1.lifeNo) > Number(p2.lifeNo)) return 1
                return -1
              })
            )

          ben &&
            setBenList(
              ben.data.map((x, i) => ({
                stt: i + 1,
                name: x.name?.name ?? '',
                idNumber: x.idNumber ?? '',
                clientNumber: x.clientNumber ?? '',
                dob: x.dob ?? '',
                gender: getGenderLabel(x.gender),
                relationship: x.relationship ? getRelationship(x.relationship, relationships) : '',
                beneficiaryPercentage: x.beneficiaryPercentage ?? 0,
                effectiveDate: x.effectiveDate ?? '',
                status: x.status ? getStatusLabel(x.status) ?? '' : '',
                beneficiaryCode: x.beneficiaryCode ?? '',
                usedTobe: x.usedTobe ?? ''
              }))
            )

          specBen &&
            setSpecialBenList(
              specBen.data.map((x, i) => ({
                name: x.name?.name ?? '',
                idNumber: x.idNumber ?? '',
                clientNumber: x.clientNumber ?? '',
                dob: x.dob ?? '',
                gender: getGenderLabel(x.gender),
                status: x.status ? getStatusLabel(x.status) ?? '' : '',
                usedTobe: x.usedTobe ?? ''
              }))
            )

          if (list.accountGroups) {
            setListPaymentClaim(list.accountGroups)
            let listShow = []
            for (let i = 0; i < list.accountGroups.length; i++) {
              listShow.push(false)
            }
            setListShowDetail(listShow)
          }

          products.data &&
            setListProduct(
              products.data.lifeAssureds?.map((x, i) => ({
                ...x,
                products:
                  x.products?.map((item) => ({
                    ...item,
                    componentName: item.productCode,
                    coverageStatus: item.coverageStatus ? ContractMapping.get(ct)(item.coverageStatus) : '',
                    premiumStatus: item.premiumStatus ? PremiumStatusMapping.get(pst)(item.premiumStatus) : ''
                  })) ?? []
              })) ?? []
            )
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  React.useEffect(() => {
    if(isNull(laList) || isNull(benList) || isNull(specialBenList) || isNull(listProduct) || isNull(listPaymentClaim)) {
      getData()
    }
  }, [])

  React.useEffect(() => {
    if(productListName.length === 0) {
      pipe(
        IBService.getListMasterDataProduct(),
        ZIO.map((productList) => {
          productList?.data && setProductListName(productList.data)
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [])

  const getContractName = (basicCode: string, products: MasterProductsData[]) => {
    const checkNull = (name: string | null | undefined) => (name === 'null' || name === 'N/A') ? '' : name
    const contract = products.find((r) => r.code === basicCode)
    return contract ? (i18n.language === 'en' ? checkNull(contract.nameEn) : checkNull(contract.nameVn)) : ''
  }

  const getProductPolicyByClientId = (clientId: string) => {
    if(policyNumber && isNull(productJoinLife)) {
      pipe(
        IBService.getProductPolicyByClientId(policyNumber, clientId),
        ZIO.map((res) => {
          res.data &&
            res.data?.lifeAssureds &&
            res.data?.lifeAssureds[0] &&
            setProductJoinLife({
              ...res.data.lifeAssureds[0],
              products:
                res.data.lifeAssureds[0].products?.map((item) => ({
                  ...item,
                  componentName: item.productCode + '-' + item.componentName,
                  coverageStatus: item.coverageStatus ? ContractMapping.get(ct)(item.coverageStatus) : '',
                  premiumStatus: item.premiumStatus ? PremiumStatusMapping.get(pst)(item.premiumStatus) : ''
                })) ?? []
            })
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const dataUserInsuranceBenefit = (data: ClientInsuranceInfo | undefined) => [
    {
      title: t('Inbound:ClientNumber'),
      value: data?.clientNumber ?? '-',
      link: data?.clientNumber ? (
        <Pressable
          onPress={() => {
            const linkName = window.location.pathname.split('ib-client-policy')[0]
            window.open(`${linkName}ib-dashboard?clientNumber=${data?.clientNumber}`)
          }}
        >
          <Text
            style={{
              fontWeight: '500',
              marginTop: 4,
              fontSize: 15,
              textDecorationLine: 'underline',
              color: '#1ea5fc'
            }}
          >
            {data?.clientNumber}
          </Text>
        </Pressable>
      ) : (
        <Text>{'-'}</Text>
      )
    },
    {
      title: t('Inbound:ID'),
      value: data?.idNumber ?? '-'
    },
    {
      title: t('Inbound:DayOfBirth'),
      value: data?.dob ?? '-'
    },
    {
      title: t('Inbound:AgeOfParticipation'),
      value: data?.ageOfParticipation ?? '-'
    },
    {
      title: t('Inbound:CurrentAge'),
      value: data?.currentAge ?? '-'
    },
    {
      title: t('Inbound:Gender'),
      value: getGenderLabel(data?.gender)
    }
  ]

  const dataTableDetailProduct = (
    visiblePolicyCessDate: boolean
  ): {
    label: string
    field: string
    isRequired?: boolean
    format?: string
    disabled?: boolean
    visible?: boolean
    render?: (val: string, index: number) => JSX.Element | string
  }[] => {
    return [
      {
        label: t('Inbound:ComponentName'),
        field: 'componentName',
        render: (value: string) => {
          return (
            <Pressable onPress={() => onViewFile(value)}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 15,
                  color: '#1ea5fc',
                  textDecorationLine: 'underline'
                }}
              >
                {value ? (value + '-' + getContractName(value, productListName)) : ''}
              </Text>
            </Pressable>
          )
        }
      },
      { label: t('Inbound:ProductStatus'), field: 'coverageStatus' },
      { label: t('Inbound:PremiumStatus'), field: 'premiumStatus' },
      { label: t('Inbound:SumAssured'), field: 'sumAssured', format: 'money' },
      { label: t('Inbound:InstallmentPremiumInsurance'), field: 'installmentPremium', format: 'money' },
      { label: t('Inbound:LoadedInstPrem'), field: 'loadInstallmentPremium', format: 'money' },
      { label: t('Inbound:PaidToDate'), field: 'paidToDate' },
      { label: t('Inbound:CoverageRCD'), field: 'covRiskCessDate' },
      { label: t('Inbound:RerateFromDate'), field: 'rerateDate' },
      { label: t('Inbound:PremCessD'), field: 'premCessDate' },
      { label: t('Inbound:RiskCessD'), field: 'riskCessDate' },
      { label: t('Inbound:PolCessDate'), field: 'policyCessDate', visible: visiblePolicyCessDate },
      { label: t('Inbound:BenefitOption'), field: 'benefitOption' },
      { label: t('Inbound:MortalityClass'), field: 'mortalityClass' }
    ]
  }

  const dataTableBeneficiary = [
    { label: t('Inbound:No'), field: 'stt' },
    { label: t('Inbound:FullName'), field: 'name' },
    {
      label: t('Inbound:ClientNumber'),
      field: 'clientNumber',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              const linkName = window.location.pathname.split('ib-client-policy')[0]
              window.open(`${linkName}ib-dashboard?clientNumber=${value}`)
            }}
          >
            <Text style={{ fontWeight: '500', color: '#1ea5fc', fontSize: 15, textDecorationLine: 'underline' }}>
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    { label: t('Inbound:IDNum'), field: 'idNumber' },
    { label: t('Inbound:DayOfBirth'), field: 'dob' },
    { label: t('Inbound:Gender'), field: 'gender' },
    { label: t('Inbound:EffectiveDate'), field: 'effectiveDate' },
    { label: t('Inbound:BeneficiaryPercen'), field: 'beneficiaryPercentage' },
    { label: t('Inbound:Relationship'), field: 'relationship' },
    { label: t('Inbound:Status'), field: 'status' }
  ]

  const dataTableSpecialBeneficiary = [
    { label: t('Inbound:FullName'), field: 'name' },
    { label: t('Inbound:ClientNumber'), field: 'clientNumber' },
    { label: t('Inbound:IDNum'), field: 'idNumber' },
    { label: t('Inbound:DayOfBirth'), field: 'dob' },
    { label: t('Inbound:Gender'), field: 'gender' },
    { label: t('Inbound:Status'), field: 'status' }
  ]

  const getGenderLabel = (sex?: string | null) => {
    switch (sex) {
      case 'F':
        return t('common:Female')
      case 'M':
        return t('common:Male')
      case 'U':
        return t('submission:Unknown')
      default:
        return ''
    }
  }

  const getTitleClaimPayment = (sacsCode: string, sacsType: string, amount: number) => {
    const findData = listPaymentClaimLabel.find((i) => i.sacsCode === sacsCode && i.sacsType === sacsType)
    const amountLabel = PulseOpsFormat.currency(amount)
    return findData
      ? i18n.language === 'en'
        ? `${findData.enLabel}: ${amountLabel}`
        : `${findData.viLabel}: ${amountLabel}`
      : undefined
  }

  const getRelationship = (code: string, relationships: any[]): string => {
    const r = relationships.find((r) => r.code === code)
    return r ? (i18n.language === 'en' ? r.nameEn : r.nameVn) : code
  }

  const getFileByUrl = (url: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const fileType = res.headers.get('content-type') || ''
        const blobUrl = window.URL.createObjectURL(blob)
        if (fileType === 'image/tiff') {
          viewTiffFileByNewTab(blobUrl)
        } else {
          window.open(window.URL.createObjectURL(blob), '_blank')
        }
      }),
      ErrorHandling.run()
    )
  }

  const onViewFile = (componentCode: string) => {
    if (componentCode) {
      pipe(
        IBService.getLinkDocByComponent(componentCode),
        ZIO.flatMap((responseData) => {
          const url = responseData && responseData.length > 0 ? responseData[0].url : ''
          if (!!url) {
            return ZIO.fromPromise(() => getFileByUrl(url))
          } else {
            showToast(t('message:IB0012'), 'error')
            return ZIO.unit
          }
        }),
        ZIO.mapError(() => showToast(t('message:IB0012'), 'error')),
        bindLoadingScreen,
        ZIO.unsafeRun({})
      )
    }
  }

  const getContentFromArray = (data?: ClientInsuranceInfo) => {
    let content = ''
    if (!!data?.underWritings) {
      data?.underWritings.map((item, i) => {
        content += (i !== 0 ? '\n' : '') + item.conditionAcceptContent
      })
    }
    return content
  }

  const showFullNameLabel = (
    lifeNo: string,
    name: string,
    clientNumber: string,
    productCode?: string | null,
    jointLifeClientNumber?: string | null
  ) => {
    const lifeNoLabel = lifeNo.split('0')[1] ? t('Inbound:LANum') + lifeNo.split('0')[1] : ''
    const defaultLabel = lifeNoLabel + ' - ' + name
    const jointLifeLabel = t('Inbound:LegalHusband') + ' - ' + defaultLabel
    return jointLifeClientNumber &&
      jointLifeClientNumber === clientNumber &&
      (productCode === 'PER3' || productCode === 'PE3')
      ? jointLifeLabel
      : defaultLabel
  }

  const CommonClientInfo = (data?: ClientInsuranceInfo, joinLifeInfo?: boolean) => {
    return (
      <View style={IBStyles.container}>
        <View style={IBStyles.row}>
          {dataUserInsuranceBenefit(data).map((i) => (
            <View style={[IBStyles.sectionCol2, { paddingBottom: 24 }]}>
              <Text style={{ fontWeight: '700', color: '#58647A', marginTop: 5, marginBottom: 4, fontSize: 15 }}>
                {i.title}
              </Text>
              {i.link ? i.link : <Text style={{ fontSize: 15, marginTop: 4 }}>{i.value}</Text>}
            </View>
          ))}
        </View>
        <View style={[IBStyles.row, { paddingBottom: 24 }]}>
          {data?.underWritings && (
            <View style={IBStyles.sectionCol2}>
              <Text style={{ fontWeight: '700', color: '#58647A', marginTop: 5, marginBottom: 4, fontSize: 15 }}>
                {t('Inbound:EAL')}
              </Text>
              <Pressable onPress={() => setShowModalEAL({ isShow: true, lifeAssuredCode: data?.clientNumber ?? '' })}>
                <Text style={{ fontWeight: '500', color: '#1ea5fc', fontSize: 15, textDecorationLine: 'underline', marginTop: 4 }}>
                  {t('Inbound:Detail')}
                </Text>
              </Pressable>
            </View>
          )}
          <View style={IBStyles.sectionCol2}>
            <Text style={{ fontWeight: '700', color: '#58647A', marginTop: 5, marginBottom: 4, fontSize: 15 }}>
              {t('Inbound:Occupation')}
            </Text>
            <Text style={{ fontSize: 15, marginTop: 4 }}>{data?.occupationName ?? '-'}</Text>
          </View>
        </View>
        {joinLifeInfo ? (
          productJoinLife?.products && productJoinLife.products?.length > 0 ? (
            DetailProduct(productJoinLife.products)
          ) : (
            <></>
          )
        ) : (
          !!listProduct && listProduct.map((product) =>
            product.lifeAssuredCode === data?.clientNumber && product.products && product.products?.length > 0 ? (
              DetailProduct(product.products)
            ) : (
              <></>
            )
          )
        )}
        {data?.clientNumber && ModalEalInfo(getContentFromArray(data), data?.clientNumber)}
      </View>
    )
  }

  const ClientInfo = (data?: ClientInsuranceInfo, clientNumberJoinLife?: string) => {
    return (
      <View style={IBStyles.container}>
        <Text style={[IBStyles.header, { paddingBottom: 10 }]}>
          {showFullNameLabel(
            data?.lifeNo ?? '',
            data?.name?.name ?? '',
            data?.clientNumber ?? '',
            data?.productCode,
            clientNumberJoinLife
          )}
        </Text>
        {CommonClientInfo(data)}
      </View>
    )
  }

  const JoinLifeInfo = (data?: JointLife) => {
    data?.clientNumber && !productJoinLife && getProductPolicyByClientId(data?.clientNumber)
    const dataInput =
      data &&
      ({
        ...data,
        role: null,
        relationship: null,
        beneficiaryPercentage: null,
        effectiveDate: null,
        status: null,
        beneficiaryCode: null,
        lifeNo: null,
        productCode: null,
        husband: null
      } as ClientInsuranceInfo)
    return (
      <View style={IBStyles.container}>
        <Text style={[IBStyles.header, { paddingBottom: 10 }]}>
          {t('Inbound:LegalHusband') + ' - ' + (data?.name?.name ?? '')}
        </Text>
        {CommonClientInfo(dataInput, true)}
      </View>
    )
  }

  const ModalEalInfo = (content: string, lifeAssuredCode: string) => {
    return (
      <ModalComponent
        title={t('Inbound:ContentEAL')}
        modalWidth={700}
        onClose={() => setShowModalEAL(null)}
        visible={!!showModalEAL ? showModalEAL?.lifeAssuredCode === lifeAssuredCode && showModalEAL?.isShow : false}
        actions={[]}
      >
        <ScrollView style={{ marginHorizontal: 20 }}>
          {
            content ?
              <Text>{content}</Text> :
              <Text style={{ textAlign: 'center', fontSize: 15 }}>{t('common:Nodata')}</Text>
          }
        </ScrollView>
      </ModalComponent>
    )
  }

  const DetailProduct = (products: ProductInfo[]) => {
    return (
      <View style={[IBStyles.row, { paddingTop: 15 }]}>
        <IBPanel
          title={t('Inbound:ProductInfo')}
          noContainer
          noUpperTitle
          styleContainer={{ width: '100%' }}
          intialToggle={true}
          children={
            <IBGeneralTable
              dataTable={dataTableDetailProduct(
                products.some((x) => !isEmpty(x.policyCessDate) || !isNil(x.policyCessDate))
              )}
              data={products}
            />
          }
        />
      </View>
    )
  }

  const LifeAssuredList = () => {
    return (
      <View style={{ paddingTop: 10 }}>
        <Text style={[IBStyles.header, { paddingBottom: 20 }]}>{t('Inbound:LifeAssured')}</Text>
        {!!laList && laList.map((client) => {
          const clientNumberJoinLife = laList.find((item) => item.lifeNo === '01' && item.joinLifeFlag === '00')
          const checkClientNumber = client.husband?.clientNumber && !laList.find((item) => item.clientNumber === client.husband?.clientNumber && item.lifeNo !== '01')
          const checkExistSubLA = laList.find((item) => item.lifeNo !== '01')
          const isShowJoinLife =
            client?.lifeNo === '01' &&
            (client?.productCode === 'PER3' || client?.productCode === 'PE3') &&
            (checkClientNumber || !checkExistSubLA)
          if (!(client?.lifeNo === '01' && client?.joinLifeFlag === '01') && client?.lifeNo)
            return (
              <>
                {ClientInfo(client, clientNumberJoinLife?.husband?.clientNumber ?? '')}
                {isShowJoinLife && client?.husband && JoinLifeInfo(client?.husband)}
              </>
            )
        })}
      </View>
    )
  }

  const Beneficiary = () => {
    return (
      <View>
        <Text style={[IBStyles.header, { paddingBottom: 20 }]}>{t('Inbound:Beneficiary')}</Text>
        <View style={[IBStyles.container, { paddingTop: 15 }]}>
          <IBGeneralTable
            dataTable={dataTableBeneficiary}
            data={benList || []}
          />
        </View>
      </View>
    )
  }

  const SpecialBeneficiary = () => {
    return (
      <View>
        <Text style={[IBStyles.header, { paddingBottom: 20 }]}>{t('Inbound:SpecialBeneficiary')}</Text>
        <View style={[IBStyles.container, { paddingTop: 15 }]}>
          <IBGeneralTable
            dataTable={dataTableSpecialBeneficiary}
            data={specialBenList || []}
          />
        </View>
      </View>
    )
  }

  const ClaimDetail = (sacsCode: string, sacsType: string, num: number, amount: number) => {
    return (
      <IBClaimPaymentDetail
        title={getTitleClaimPayment(sacsCode, sacsType, amount) ?? ''}
        isShowDetail={listShowDetail[num]}
        setShowDetail={(value) => {
          const temp = listShowDetail
          temp[num] = value
          setListShowDetail([...temp])
        }}
        sacsCode={sacsCode} 
        sacsType={sacsType} 
        policyNumber={policyNumber ?? ''}
      />
    )
  }

  const ClaimPaymentHistory = () => {
    return (
      <IBPanel
        title={t('Inbound:ClaimPaymentHistory')}
        children={
          !!listPaymentClaim && listPaymentClaim.length > 0 ? (
            <>
              {listPaymentClaim.map((i, num) => {
                return getTitleClaimPayment(i.sacsCode, i.sacsType, Math.abs(i.amount)) ? (
                  ClaimDetail(i.sacsCode, i.sacsType, num, Math.abs(i.amount))
                ) : (
                  <></>
                )
              })}
            </>
          ) : (
            <Text style={{ textAlign: 'center', fontSize: 15 }}>{t('common:Nodata')} </Text>
          )
        }
      />
    )
  }

  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <View style={styles.container}>
          {LifeAssuredList()}
          {Beneficiary()}
          {SpecialBeneficiary()}
          {ClaimPaymentHistory()}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10
  },
  titleText: {
    fontSize: 15,
    fontWeight: '700',
    marginVertical: 10
  },
  containerBox: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#D3DCE6',
    padding: 20,
    marginVertical: 10
  }
})
