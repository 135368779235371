export namespace SubPremiumRedirectionData {
  export interface AccessData {
    coverageCode: string
    contractStatus: string
    proposalReceivedDate: string
  }

  export interface detail {
    fundList: SUBFundInfo[]
    POClientId: string
  }

  export interface SubmitData {
    policyNo: string
    poClientNum: string
    correlationId: string
    transactionType: string
    fundList: SUBFundInfo[]
    office: string
  }

  export interface SUBFundInfo {
    fundCode: string
    fundName: string
    currentPercentage: number
    redirectPercentage: number
  }
}
