
import {StyleSheet} from 'react-native'

export const IDCSharedStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingVertical: 16
  },
  panelContainer: {
    paddingLeft: 24,
    marginBottom: 24
  },
  answerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
  },
})