import {
  Checkbox,
  Occupation,
  Panel,
  RadioButton,
  SourceType,
  TaskDetail,
  translator,
  GeneralService,
  ErrorHandling,
  Label,
  TransactionType
} from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList, Title } from '../common'
import { DuplicateInfoTable, DuplicateInfoItem } from './common'
import { pipe } from 'fp-ts/function'

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
  style?: { colorText?: string }
}

export const ChangeClientInfo = ({ detail }: { detail?: TaskDetail.ChangeClientInfo }) => {
  const { t } = useTranslation(['requestInfo', 'common', 'contact'])
  const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []

  const getRelationshipFromT16s1 = (code: string) => {
    const found = relationshipT16s1.find((item) => item.code === code)
    return {
      en: found?.relationshipEN || '',
      vi: found?.relationshipVN || ''
    }
  }

  let dataRequest: Output[] = []
  let dataOccupation: Output[] = []
  const occupation = detail?.occupation
  const changeOccupation = occupation?.isChangeOccupation || false

  const isChangeNationalIDCard = detail?.tag === TransactionType.CHANGE_NATIONAL_ID_CARD

  if (detail) {
    dataRequest = [
      {
        label: t('Client'),
        value: detail.targetName || '-',
        ...(isChangeNationalIDCard && { style: { colorText: '#eD1B2E' } })
      },
      { label: '', value: '' },
      { label: '', value: '' },
      {
        label: t('IDType'),
        value: detail.idType ? t(translator(detail.idType)) : '-',
        ...(isChangeNationalIDCard && { style: { colorText: '#eD1B2E' } })
      },
      {
        label: t('IDNumber'),
        value: detail.idNumber || '-',
        ...(isChangeNationalIDCard && { style: { colorText: '#eD1B2E' } })
      },
      {
        label: t('IssueBy'),
        value: detail.issueBy || '-',
        ...(isChangeNationalIDCard && { style: { colorText: '#eD1B2E' } })
      },

      {
        label: t('submission:issuedDate'),
        value: detail.issuedDate || '-',
        ...(isChangeNationalIDCard && { style: { colorText: '#eD1B2E' } })
      },

      { label: t('Surname'), value: detail.surname || '-' },
      { label: t('GivenName'), value: detail.givenName || '-' },
      {
        label: t('CurrentlyHaveToFileATaxReturnInTheUS'),
        value: (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <RadioButton label={t('Yes')} selected={detail.fatca === 'Y'} />
            <RadioButton label={t('No')} selected={detail.fatca === 'N'} />
          </View>
        )
      },
      {
        label: t('DateOfBirth'),
        value: detail.dob ? detail.dob : '-'
      },
      {
        label: t('Gender'),
        value: detail.gender === 'M' ? t('common:Male') : t('common:Female')
      },
      { label: t('Nationality'), value: detail.nationality || '-' },
      { label: t('MobilePhone1'), value: detail.mobilePhone || '-' },
      { label: t('Email'), value: detail.email || '-' }
    ]

    if (!!detail.city && !!detail.district && !!detail.ward) {
      dataRequest = [...dataRequest,
      { label: t('submission:Country'), value: detail.countryName },
      { label: t('submission:CityProvince'), value: detail.city || '-' },
      { label: t('submission:District'), value: detail.districtName || '-' },
      { label: t('submission:WardSubDistrict'), value: detail.wardName || '-' },
      { label: t('submission:Street'), value: detail.address || '-' },
      { label: t('submission:TaxResidencyCountry'), value: detail.taxResidencyCountry || '-' },
      { label: t('submission:Nationality2'), value: detail.nationality2 || '-' },
      { label: t('submission:CountryOfForeignAddress'), value: detail.foreignCountry || '-' },
      { label: t('submission:ForeignAddress'), value: detail.foreignAddress || '-' },
      ]
    }

    dataOccupation = [
      { label: t('Ocupation'), value: occupation?.occupation || '-' },
      { label: t('JobDescription'), value: occupation?.jobDescription || '-' },
      { label: t('JobTitle'), value: occupation?.jobTitle || '-' },
      { label: t('CompanyName'), value: occupation?.companyName || '-' },
      { label: t('CompanyAddress'), value: occupation?.companyAddress || '-' },
      { label: t('Salary'), value: t(translator(Occupation.getSalaryTitle(occupation?.salaryAvg || '-'))) }
    ]
  }

  const phoneDupData: DuplicateInfoItem[] =
    detail?.dupPhone.map((item) => {
      return {
        clientNumber: item.clientNum,
        clientName: item.clientName,
        relationship: getRelationshipFromT16s1(item.relationship || '')
      }
    }) || []

  const emailDupData: DuplicateInfoItem[] =
    detail?.dupEmail.map((item) => {
      return {
        clientNumber: item.clientNum,
        clientName: item.clientName,
        relationship: getRelationshipFromT16s1(item.relationship || '')
      }
    }) || []

  return (
    <View>
      <Title title={t('RequestDetail')} />
      <Panel title="" isExand={false}>
        <InfoViewList dataSource={dataRequest} />
        {phoneDupData.length > 0 && (
          <View style={{ marginBottom: 20 }}>
            <Label title={t('requestInfo:dupPhone')} />
            <View style={{ marginTop: 6 }}>
              <DuplicateInfoTable data={phoneDupData} />
            </View>
          </View>
        )}
        {emailDupData.length > 0 && (
          <View style={{ marginBottom: 20 }}>
            <Label title={t('requestInfo:dupEmail')} />
            <View style={{ marginTop: 6 }}>
              <DuplicateInfoTable data={emailDupData} />
            </View>
          </View>
        )}
      </Panel>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Checkbox title="" disabled value={changeOccupation} />
        <Title title={t('ChangeOccupation')} wrapperStyle={{ marginBottom: 0 }} />
      </View>
      {detail?.source === SourceType.PULSE4OPS && changeOccupation && (
        <Panel title="" isExand={false}>
          <InfoViewList dataSource={dataOccupation} />
        </Panel>
      )}
    </View>
  )
}
