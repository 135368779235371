import { View, StyleSheet, useWindowDimensions, ScrollView, ActivityIndicator } from 'react-native'
import React from 'react'
import {
  ModalComponent,
  ModalAction,
  form2,
  Input,
  ErrorHandling,
  DatePicker,
  AuthService,
  Toast,
  SourceType,
  SelectSearch,
  assets
} from '@pulseops/common'
import { Color } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import moment from 'moment'
import { SEABillingFailForm } from '../common/SEABillingFailForm'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'
import { GenerateUUID } from './SEAuuid'
import { SEABillingChangeService } from '../billing-change/SEABillingChangeService'
interface Props {
  visible: boolean
  onClose: (visible: boolean) => void
  idDetial: string
  statusDetial: string
}

export const SEAFailListDetailModal = (props: Props) => {
  const { width } = useWindowDimensions()
  const { visible, onClose, idDetial, statusDetial } = props
  const { t, i18n } = useTranslation()
  const [enableEdit, setEnableEdit] = React.useState<boolean>(false)
  const [enableSave, setEnableSave] = React.useState<boolean>(true)
  const [enableUploadFile, setEnableUploadFile] = React.useState<boolean>(true)
  const [disableFields, setDisabledFields] = React.useState<boolean>(true)
  const detail: SEABillingChangeModal.DetailData | null = pipe(
    SEABillingChangeService.getDetailCase(idDetial),
    ErrorHandling.runDidMount()
  )
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const { base, handleSubmit } = form2.useForm(SEABillingFailForm.codec)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const checkStatus = (status: string) => ['Success', 'Time_out', 'Duplicate'].includes(status)
  React.useEffect(() => {
    if (detail)
      base.reset({
        policyNo: detail?.policyNumber || '',
        newBillingFrequency:
          SEABillingChangeModal.getGenderOption(detail?.newBillingFrequency ?? '')(i18n.language) ?? '',
        currentBillingFrequency:
          SEABillingChangeModal.getGenderOption(detail?.currentBillingFrequency ?? '')(i18n.language) ?? '',
        newInstallmentPremium: detail?.newInstallmentPremium || '',
        curentInstallmentPremium: detail?.currentInstallmentPremium || '',
        effectiveDateOfNewFrequency: moment(detail?.newDateEffect, 'DD/MM/YYYY').toDate()
      })
  }, [detail])
  const handleSave = handleSubmit((data) => {
    pipe(
      SEABillingChangeService.submitCaseDetail(detail?.id ?? '', {
        body: {
          id: detail?.id ?? '',
          policyNumber: base.watch('policyNo') ?? '',
          currentBillingFrequency: base.watch('currentBillingFrequency')?.value ?? '',
          currentInstallmentPremium: base.watch('curentInstallmentPremium') ?? '',
          newDateEffect: moment(base.watch('effectiveDateOfNewFrequency')).format('DD/MM/YYYY') ?? '',
          newBillingFrequency: base.watch('newBillingFrequency')?.value ?? '',
          newInstallmentPremium: base.watch('newInstallmentPremium') ?? '',
          requirePayInAmount: detail?.requirePayInAmount ?? '',
          lifeAssuredFulName: detail?.lifeAssuredFulName ?? ''
        }
      }),
      ZIO.map((data) => {
        setEnableEdit(false)
        setDisabledFields(true)
        setEnableUploadFile(false)
      }),
      ZIO.catchAll((e) => {
        setEnableSave(false)
        showToastInternal(t('message:MS990073'), 'error')
        return ZIO.unit
      }),
      ErrorHandling.run({})
    )
  })

  const uploadFile = (data: SEABillingFailForm.Raw) => {
    pipe(
      SEABillingChangeService.uploadCaseDetail({
        authenOption: 'ATTACH_REQUEST_FORM',
        authenRequestForm: {
          authFlag: true,
          signatureDocuments: []
        },
        body: {
          id: detail?.id ?? '',
          policyNumber: base.watch('policyNo') ?? '',
          currentBillingFrequency: base.watch('currentBillingFrequency')?.value ?? '',
          currentInstallmentPremium: base.watch('curentInstallmentPremium') ?? '',
          newDateEffect: moment(base.watch('effectiveDateOfNewFrequency')).format('DD/MM/YYYY') ?? '',
          newBillingFrequency: base.watch('newBillingFrequency')?.value ?? '',
          newInstallmentPremium: base.watch('newInstallmentPremium') ?? '',
          requirePayInAmount: detail?.requirePayInAmount ?? '',
          lifeAssuredFulName: detail?.lifeAssuredFulName ?? ''
        },
        clientId: '12522298',
        createdBy: userInfo?.email ?? '',
        createdDate: new Date().toISOString(),
        documents: [],
        isCCE: false,
        officeCode: 'VCO',
        primaryPolicyNo: base.watch('policyNo') ?? '',
        source: SourceType.BANCA_SEA,
        exchangeId: GenerateUUID.uuid()
      }),
      ZIO.map((data) => {
        showToastInternal(t('message:MS990072'), 'success')
        setEnableUploadFile(true)
        setEnableSave(true)
        setEnableEdit(true)
        setDisabledFields(true)
      }),
      ZIO.unsafeRun({})
    )
  }

  const handleUploadFile = handleSubmit((data: any) => {
    pipe(
      ZIO.succeed(data),
      ZIO.flatMap((res) => {
        uploadFile(res)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  })
  const modalActions: ModalAction[] = [
    {
      text: t('TaskManagement:Edit'),
      type: 'outline',
      action: () => {
        setEnableEdit(true)
        setEnableSave(false)
        setDisabledFields(false)
        setEnableUploadFile(true)
      },
      disabled: (checkStatus(statusDetial) && true) || enableEdit,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm1
    },
    {
      text: t('TaskManagement:Save'),
      type: 'outline',
      action: handleSave,
      disabled: enableSave,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm2
    },
    {
      text: t('TaskManagement:UploadDataToSys'),
      type: 'outline',
      action: handleUploadFile,
      disabled: enableUploadFile,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm3
    }
  ]

  return (
    <ModalComponent
      title={t('BillingChange:FailDetail')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.6, 750)}
      visible={true}
      onClose={() => onClose(!visible)}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        {!_.isUndefined(base.watch().policyNo) ? (
          <>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <Controller
                  control={base.control}
                  name="policyNo"
                  render={({ field, fieldState: { error } }) => {
                    const firstLoading = _.isNaN(Number(field.value)) && !_.isEmpty(field.value)
                    return (
                      <View style={styles.inputStyle}>
                        <Input
                          {...field}
                          value={field.value ?? ''}
                          title={t('BillingChange:Policy')}
                          maxLength={9}
                          onChange={(val) => {
                            if (firstLoading && field.value) {
                              field.value?.length > val.length && field.onChange(val)
                            } else {
                              field.onChange(val)
                            }
                          }}
                          disabled={disableFields}
                          // alwayShowUnderline={!disableFields}
                          errorMessage={error?.message}
                          inputType={firstLoading ? 'text' : 'number'}
                          required
                        />
                      </View>
                    )
                  }}
                />
              </View>
              <View style={{ flex: 1 }}>
                <Controller
                  name="newBillingFrequency"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      disabled={disableFields}
                      required={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('BillingChange:NewBillingFrequency')}
                      options={SEABillingChangeModal.getGenderOptions(i18n.language)}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('common:Select')}
                      errorMessage={error?.message}
                      // key={value}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <Controller
                  name="currentBillingFrequency"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      disabled={disableFields}
                      required={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('BillingChange:CurrentBillingFrequency')}
                      options={SEABillingChangeModal.getGenderOptions(i18n.language)}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('common:Select')}
                      errorMessage={error?.message}
                      // key={value}
                    />
                  )}
                />
              </View>
              <View style={{ flex: 1 }}>
                <Controller
                  control={base.control}
                  name="newInstallmentPremium"
                  render={({ field, fieldState: { error } }) => (
                    <View style={styles.inputStyle}>
                      <Input
                        {...field}
                        maxLength={20}
                        value={field.value ?? ''}
                        title={t('BillingChange:NewInstallmentPremium')}
                        disabled={disableFields}
                        alwayShowUnderline={!disableFields}
                        errorMessage={error?.message}
                        inputType={'money'}
                      />
                    </View>
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, paddingRight: 30 }}>
                <Controller
                  control={base.control}
                  name="curentInstallmentPremium"
                  render={({ field, fieldState: { error } }) => (
                    <View style={styles.inputStyle}>
                      <Input
                        {...field}
                        maxLength={20}
                        value={field.value ?? ''}
                        title={t('BillingChange:CurentInstallmentPremium')}
                        disabled={disableFields}
                        alwayShowUnderline={!disableFields}
                        errorMessage={error?.message}
                        required
                        inputType={'money'}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ flex: 1, marginTop: 3 }}>
                <Controller
                  control={base.control}
                  name="effectiveDateOfNewFrequency"
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <View style={styles.inputStyle}>
                      <DatePicker
                        label={t('BillingChange:EffectiveDateOfNewFrequency')}
                        onChange={onChange}
                        value={value}
                        labelStyle={{ marginBottom: 0 }}
                        disabled={disableFields}
                        hideUnderline={disableFields}
                        errorMessage={error?.message}
                        required
                      />
                    </View>
                  )}
                />
              </View>
            </View>
          </>
        ) : (
          <ActivityIndicator size="large" color="#ED1B2C" />
        )}
      </ScrollView>

      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
const styles = StyleSheet.create({
  modalTitle: {},

  modalContent: {},

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39,
    marginHorizontal: 5
  },
  btnConfirm1: {
    height: 40,
    marginHorizontal: 5,
    marginRight: 50
  },
  btnConfirm2: {
    height: 40,
    marginHorizontal: 5,
    marginRight: 50
  },
  btnConfirm3: {
    height: 40,
    marginHorizontal: 5
  },
  container: {
    paddingHorizontal: 23,
    paddingTop: 13
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  inputStyle: {
    marginBottom: 20,
    width: '100%'
  },
  textWarning: {
    paddingHorizontal: 35,
    fontSize: 18
  }
})
