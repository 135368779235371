/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { DaGeneralData } from './da-general-data'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { contactInfo } from './constants'
export namespace DaGeneralInfoService {
  export const DaGeneralInfoTypeC = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createdDate: Maybe(t.number),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    ),
    data: Maybe(t.string),
    eref: Maybe(t.string)
  })
  export type DaGeneralInfoType = t.TypeOf<typeof DaGeneralInfoTypeC>
  export const GeneralInfoLogRqDataType = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    source: Maybe(t.string),
    id: Maybe(t.string),
    qsCoreInfo: Maybe(t.string),
    applicationInfo: Maybe(t.string),
    assessment: Nullable(
      t.type({
        status: t.boolean,
        assessmentDate: Nullable(t.string),
        role: Nullable(t.string),
        assessByDesc: Nullable(t.string),
        reAssess: Nullable(t.string),
        reAssessDate: Nullable(t.string)
      })
    ),
    candidateInfo: Maybe(t.string),
    otherInfo: Maybe(t.string),
    contactInfo: Maybe(t.string),
    bankAccountInfo: Maybe(t.string),
    educationInfo: Maybe(t.string),
    maritalStatus: Maybe(t.string),
    eref: Maybe(t.string),
    email: Maybe(t.string),
    saleType: Maybe(t.string),
    status: Maybe(t.string),
    submissionDate: Maybe(t.union([t.string, t.number])),
    transactionType: Maybe(t.string)
  })
  export type GeneralInfoLogRqData = t.TypeOf<typeof GeneralInfoLogRqDataType>

  export type GeneralInfoLogRqDataModel = {
    contactInfo: contactInfo
    processInstanceId: string
  }

  export const GeneralInfoLogType = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createdDate: Maybe(t.string),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    ),
    data: Maybe(GeneralInfoLogRqDataType),
    eref: Maybe(t.string),
    id: Maybe(t.string)
  })

  export type GeneralInfoLog = t.TypeOf<typeof GeneralInfoLogType>

  export const ProvinceType = t.type({
    provinceCode: t.string,
    provinceCombine: t.string,
    provinceDesc: t.string,
    provinceTitle: t.string
  })

  export type ProvinceType = t.TypeOf<typeof ProvinceType>

  export const DistrictType = t.type({
    districtCode: t.string,
    districtCombine: t.string,
    districtDesc: t.string,
    districtTitle: t.string,
    provinceCode: t.string
  })

  export type DistrictType = t.TypeOf<typeof ProvinceType>

  export const WardType = t.type({
    districtCode: t.string,
    provinceCode: t.string,
    wardCode: t.string,
    wardCombine: t.string,
    wardDesc: t.string,
    wardTitle: t.string
  })

  export type WardType = t.TypeOf<typeof ProvinceType>

  export const GeneralData = t.type({
    id: Maybe(t.string),
    code: t.string,
    name: t.string
  })

  export type GeneralData = t.TypeOf<typeof GeneralData>

  export const LeaderCodeT = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agenCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createDate: Maybe(t.union([t.string, t.number])),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    ),
    data: Maybe(
      t.union([
        Maybe(
          t.type({
            leaderCodeDescription: Maybe(t.string),
            saleZone: Maybe(t.string),
            officeCode: Maybe(t.string),
            regionCode: Maybe(t.string),
            regionDescription: Maybe(t.string)
          })
        ),
        t.string
      ])
    ),
    eref: Maybe(t.string)
  })
  export const AgentCodeT = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agenCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createDate: Maybe(t.union([t.string, t.number])),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    ),
    data: Maybe(t.string),
    eref: Maybe(t.string)
  })

  export type leaderCodeT = t.TypeOf<typeof LeaderCodeT>
  export type agentCodeT = t.TypeOf<typeof AgentCodeT>
  export const getcheckDuplicateActive = (data: DaGeneralData.DaGeneralRuleDataRule): Task<DaGeneralInfoType> => {
    return pipe(
      POApi.post('distribution-agents-service/agent/check-active')(DaGeneralInfoTypeC)(data),
      ZIO.map((res) => res)
    )
  }
  export const getcheckDuplicateFullNameAndGenderAndDob = (
    data: DaGeneralData.DaGeneralRuleDataRule
  ): Task<DaGeneralInfoType> => {
    return pipe(
      POApi.post('distribution-agents-service/validate-rules')(DaGeneralInfoTypeC)(data),
      ZIO.map((res) => res)
    )
  }
  export const saveGeneralInfoLog = (data: GeneralInfoLogRqData): Task<GeneralInfoLog> => {
    return pipe(POApi.post('distribution-agents-service/general-info/save')(GeneralInfoLogType)(data))
  }
  export const getGeneralInfoLog = (businessKey: string): Task<GeneralInfoLog> => {
    return pipe(
      POApi.getConfig({ params: { businessKey } })('distribution-agents-service/general-info/get-by')(
        GeneralInfoLogType
      ),
      ZIO.map((res) => res)
    )
  }

  export const getTerminarReason = (agentNum: string, terDate: string) => {
    return pipe(
      POApi.get(`distribution-agents-service/table/get-termination-reason?agentNum=${agentNum}&dteTrm=${terDate}`)(
        t.type({
          data: t.array(t.string)
        })
      ),
      ZIO.map((res) => res.data)
    )
  }
  export const getProvinces = pipe(
    POApi.get(`distribution-agents-service/general/get-provinces`)(
      t.type({
        body: t.array(ProvinceType)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getDistricts = (provinceCode?: string) =>
    pipe(
      POApi.getConfig({ params: { province: provinceCode } })(
        `distribution-agents-service/general/get-districts-by-province`
      )(
        t.type({
          body: t.array(DistrictType)
        })
      ),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? [])
    )

  export const getWards = (data: { provinceCode?: string; districtCode?: string }) =>
    pipe(
      POApi.getConfig({
        params: {
          province: data.provinceCode,
          district: data.districtCode
        }
      })(`distribution-agents-service/general/get-wards-by-province-and-district`)(
        t.type({
          body: t.array(WardType)
        })
      ),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? [])
    )
  const bankRes = t.array(
    t.type({
      bankCode: t.string,
      bankName: t.string
    })
  )
  export const getBanks = () =>
    pipe(
      POApi.getConfig({ params: { pageNum: 0, pageSize: 120 } })(`distribution-agents-service/bank/get-all`)(bankRes),
      ZIO.map((banks) =>
        banks.map((x) => ({
          code: x.bankCode,
          name: x.bankName
        }))
      )
    )

  const branchRes = t.array(
    t.type({
      branchKey: t.string,
      branchName: t.string
    })
  )

  export const bankBranch = (code: string) =>
    pipe(
      POApi.getConfig({ params: { bankCode: code, pageNum: 0, pageSize: 120 } })(
        `distribution-agents-service/bank/get-branch-by`
      )(branchRes),
      ZIO.map((branches) =>
        branches.map((x) => ({
          code: x.branchKey,
          name: x.branchName
        }))
      )
    )

  export const getLeaderCode = (leaderCode: string): Task<leaderCodeT> => {
    return pipe(
      POApi.getConfig({ params: { leaderCode } })('distribution-agents-service/onboard-rules/get-info-by')(LeaderCodeT),
      ZIO.map((res) => res)
    )
  }
  export const getAgentCode = (agentCodeRefer: string): Task<agentCodeT> => {
    return pipe(
      POApi.getConfig({ params: { agentCodeRefer } })('distribution-agents-service/onboard-rules/get-agent-by')(
        AgentCodeT
      ),
      ZIO.map((res) => res)
    )
  }
}
