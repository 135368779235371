import React from 'react'
import { StyleSheet, View } from 'react-native'
import { OBContactList } from './OBContactList'
import { OBCallingRecord } from './OBCallingRecord'
import { useIsFocused } from '@react-navigation/native'

type OBContactListProps = {
  onCloseCallOutPopup?: () => void
}

export type CalloutPopupContext = {
  phoneNumber: string
  setPhoneNumber: (phoneNum: string) => void
}
const defaultValues: CalloutPopupContext = {
  phoneNumber: '',
  setPhoneNumber: () => { }
}

export const calloutPopupContext = React.createContext<CalloutPopupContext>(defaultValues)

export const OBCallOutPopup = (props: OBContactListProps) => {
  const isFocused = useIsFocused()
  const [phoneNumber, setPhoneNumber] = React.useState<string>('')

  return (
    <calloutPopupContext.Provider value={{
      phoneNumber: phoneNumber,
      setPhoneNumber: setPhoneNumber,
    }}>
      <View style={popupStyle.container}>
        <View style={popupStyle.callingRecord}>
          <OBCallingRecord></OBCallingRecord>
        </View>
        <View style={popupStyle.contactList}>
          <OBContactList onCloseCallOutPopup={props.onCloseCallOutPopup}></OBContactList>
        </View>
      </View>
    </calloutPopupContext.Provider>
  )
}
const popupStyle = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 720,
    height: 530,
    zIndex: 100,
    position: 'absolute',
    // backgroundColor: '#0f0',
    bottom: 0,
    right: 24,
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderLeftWidth: 2,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderTopColor: '#AAA',
    borderLeftColor: '#AAA',
    borderRightColor: '#AAA',
    boxShadow: '0px 0px 40px 0px rgba(0, 0, 0, 0.20)'
  },
  callingRecord: {
    width: 400,
    height: 530,
    backgroundColor: '#fff',
    borderTopLeftRadius: 16,
  },
  contactList: {
    width: 320,
    height: 530,
    backgroundColor: '#fff',
    borderTopRightRadius: 16,
  }
})