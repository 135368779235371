export namespace Purpose {
  export interface PurposeOption {
    value: string
    label: string
  }

  export const MockPurpose: PurposeOption[] = [
    {
      value: 'CB',
      label: 'Chữa bệnh'
    },
    {
      value: 'CT',
      label: 'Công tác'
    },
    {
      value: 'DC',
      label: 'Định cư'
    },
    {
      value: 'DH',
      label: 'Du học'
    },
    {
      value: 'DL',
      label: 'Du lịch'
    },
    {
      value: 'OTH',
      label: 'Khác'
    }
  ]
}
