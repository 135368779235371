import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { SEABillingChangeStackParamList } from './SEABillingChangeParamList'
import { SEABillingChanceUploadFileScreen } from './billing-change/SEAUploadFileScreen'
import { SEABillingChangeRequestScreen } from './billing-change/SEARequestHistoryScreen'
import { SEAPolicyGroupScreen } from './SEAPolicyGroupScreen'
import { SEAWithdrawUploadFileScreen } from './auto-partial-withdraw/SEAWithdrawUploadFileScreen'
import { SEAWithdrawImportHistoryScreen } from './auto-partial-withdraw/SEAWithdrawImportHistoryScreen'
import { SEAWithdrawImportHistoryDetailScreen } from './auto-partial-withdraw/SEAWithdrawImportHistoryDetailScreen'
import { SEAWithdrawRequestScreen } from './auto-partial-withdraw/SEAWithdrawRequestScreen'
import { SEAWithdrawScreen } from './auto-partial-withdraw/SEAWithdrawScreen'
import { SEABillingChanceScreen } from './billing-change/SEABillingChangeScreen'
import { SEABillingChangeImportHistoryScreen } from './billing-change/SEAImportHistoryScreen'

const Stack = createStackNavigator<SEABillingChangeStackParamList>()

export const PolicyGroupListStack = () => {
  return (
    <Stack.Navigator initialRouteName="SEAPolicyGroupScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="SEAPolicyGroupScreen" component={SEAPolicyGroupScreen} />
      <Stack.Screen name="SEABillingChangeScreen" component={SEABillingChanceScreen} />
      <Stack.Screen name="SEABillingChangeUploadScreen" component={SEABillingChanceUploadFileScreen} />
      <Stack.Screen name="SEABillingChangeImportHistoryScreen" component={SEABillingChangeImportHistoryScreen} />
      <Stack.Screen name="SEABillingChangeRequestScreen" component={SEABillingChangeRequestScreen} />
      <Stack.Screen name="SEAWithdrawScreen" component={SEAWithdrawScreen} />
      <Stack.Screen name="SEAWithdrawUploadFileScreen" component={SEAWithdrawUploadFileScreen} />
      <Stack.Screen name="SEAWithdrawImportHistoryScreen" component={SEAWithdrawImportHistoryScreen} />
      <Stack.Screen name="SEAWithdrawImportHistoryDetailScreen" component={SEAWithdrawImportHistoryDetailScreen} />
      <Stack.Screen name="SEAWithdrawRequestScreen" component={SEAWithdrawRequestScreen} />
    </Stack.Navigator>
  )
}
