import { StyleSheet, Text, View } from 'react-native'
import { IBDividerProps } from './index'
import React from 'react'

export const IBDivider = ({ borderStyle = 'dashed', marginHorizontal = 0, hidden = false }: IBDividerProps) => {
  return hidden ? <></> : <View style={[styles.divider, { borderStyle, marginHorizontal }]}></View>
}

const styles = StyleSheet.create({
  divider: {
    borderTopWidth: 1,
    borderColor: '#BCC5D6',
    marginVertical: 15
  }
})
