import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'

export namespace SubmitFileForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  const Base = t.type({
    attachments: pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l && l.length > 0,
        () => '',
        'FileList'
        // () => {i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') })},
      )
    ),
    evidences: pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l && l.length > 0,
        () => '',
        'FileList'
        // () => {i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') })},
      )
    )
  })
  export const codec = Base

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
