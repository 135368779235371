import { SafeAreaView, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { CategoryTabInfo, IBCategoryTab } from '../../../../../common'
import { useTranslation } from 'react-i18next'
import { IBILPFundInfo } from './IBILPFundInfo'
import { IBILPFundValueUnit } from './IBILPFundValueUnit'
import { InvestmentDataInfo, InvestmentFund } from '../../../../../ib-service'

interface Props {
  scrollRef: React.MutableRefObject<ScrollView | null>
  policyNumber: string
  productCode: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  investmentInfo?: InvestmentDataInfo
  status: string
  productType: string
  basicCode: string
  listFundT25?: InvestmentFund[]
}

export const IBILP = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const {
    investmentInfo,
    policyNumber,
    productCode,
    setInvestmentInfo,
    status,
    productType,
    basicCode,
    scrollRef,
    listFundT25
  } = props
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const categoryTabList: Array<CategoryTabInfo> = [
    {
      label: t('FundInfo'),
      key: '1'
    },
    {
      label: t('FundValueUnit'),
      key: '2'
    }
  ]
  return (
    <View>
      <View style={{ marginBottom: 10 }}>
        <IBCategoryTab
          dataSource={categoryTabList}
          onChangeTab={setCategoryTabIndex}
          activeTabIndex={categoryTabIndex}
        />
      </View>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          {categoryTabIndex === 0 && (
            <IBILPFundInfo
              scrollRef={scrollRef}
              policyNumber={policyNumber}
              productCode={productCode as string}
              setInvestmentInfo={setInvestmentInfo}
              investmentInfo={investmentInfo}
              status={status}
              productType={productType as string}
              basicCode={basicCode}
              listFundT25={listFundT25}
            />
          )}
          {categoryTabIndex === 1 && <IBILPFundValueUnit listFundT25={listFundT25} />}
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}

const styles = StyleSheet.create({})
