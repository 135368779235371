import { formatNumberWithComma, FrequencyMapping, Panel, TaskDetail } from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

export const BillingChangeReversal = ({ detail }: { detail?: TaskDetail.BillingChangeReversal }) => {
  const { t } = useTranslation('requestInfo')

  const currentFrequency: string | TFunctionResult = detail?.currentFrequency
    ? FrequencyMapping.mapFrequency.get(detail?.currentFrequency)
    : '-'
  const oldFrequency: string | TFunctionResult = detail?.oldFrequency
    ? FrequencyMapping.mapFrequency.get(detail.oldFrequency)
    : '-'

  let data: Output[] = []
  data = [
    {
      label: t('CurrentBillingFrequency'),
      value: t(`${currentFrequency}`)
    },
    {
      label: t('CurentInstallmentPremium'),
      value: detail?.currentRenewalPremium ? formatNumberWithComma(detail?.currentRenewalPremium) : '0',
      suffix: 'VND'
    },
    {
      label: t('DateOfTransaction'),
      value: detail?.transactionDate || '-'
    },
    {
      label: t('OldBillingFrequency'),
      value: t(`${oldFrequency}`)
    },
    {
      label: t('OldInstallmentPremium'),
      value: detail?.oldRenewalPremium ? formatNumberWithComma(detail?.oldRenewalPremium) : '0',
      suffix: 'VND'
    },
    {
      label: t('AutomaticPolicyLoanInterest'),
      value: detail?.loanInterest ? formatNumberWithComma(detail.loanInterest) : '0'
    },
    {
      label: t('RequirePayinAmount'),
      value: detail?.requirePayinAmount ? formatNumberWithComma(detail?.requirePayinAmount) : '0',
      suffix: 'VND',
      isHighlight: true
    }
  ]

  return (
    <Panel title={t('Billing')} isExand={false}>
      <InfoViewList dataSource={data} />
    </Panel>
  )
}
