import { TransactionStatus, TransactionStatusLabel } from '@pulseops/common'
import React from 'react'
import { StyleSheet,Text, View } from 'react-native'

type OBTaskStatusProps = {
  statusText: string
}

export const OBTaskStatus = (props: OBTaskStatusProps) => {
  return (
    <View style={[statusStyles.container, { backgroundColor: TransactionStatusLabel(props.statusText as TransactionStatus)?.backgroundColor } ]}>
      <Text style={{ color: TransactionStatusLabel(props.statusText as TransactionStatus)?.color }}>
        {props.statusText ? TransactionStatusLabel(props.statusText as TransactionStatus)?.label : ''}
      </Text>
    </View>
  )
}
const statusStyles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(86, 204, 242, 0.3)', 
    paddingVertical: 5, 
    paddingHorizontal: 15, 
    borderRadius: 16
  }
})