import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Input, TaskDetail } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { GeneralForm } from './da-genneral-form'
// import { ZIO } from '@mxt/zio'
// import { pipe } from 'fp-ts/lib/function'
// import { Bank, BankBranch } from './constants'
type Props = { detail: TaskDetail.AgentOnboardNew; form: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'> }
export const BankAccountOther = (props: Props) => {
  const { t } = useTranslation('RequestInfo')
  // const [banks, setBanks] = React.useState<Bank[]>([])
  // const [bankBranchs, setBankBranchs] = React.useState<BankBranch[]>([])
  const { control, setValue } = props.form

  // const bankOptions = React.useMemo(
  //   () => banks.map((item): SelectOption => ({ label: item.name, value: item.code })),
  //   [banks]
  // )

  // const bankBranchOptions = React.useMemo(
  //   () => bankBranchs.map((item): SelectOption => ({ label: item.name, value: item.code })),
  //   [bankBranchs]
  // )

  // const getBanks = () => {
  //   pipe(
  //     PayoutService.getBanks(),
  //     ZIO.tap((banks) => {
  //       setBanks(banks.length ? banks : [])
  //       return ZIO.unit
  //     }),
  //     ErrorHandling.run()
  //   )
  // }

  // const getBankBranch = (code: string) =>
  //   pipe(
  //     PayoutService.bankBranch(code ?? 'AGRI'),
  //     ZIO.tap((branchs) => {
  //       setBankBranchs(branchs.length ? branchs : [])
  //       return ZIO.unit
  //     }),
  //     ErrorHandling.run()
  //   )
  // React.useEffect(() => {
  //   getBanks()
  // }, [])

  React.useEffect(() => {
    // if (props.detail.bankCode === 'OTHER') {
    setValue('BanknameOther', props.detail.otherBank ?? '')
    setValue('BankbranchnameOther', props.detail.otherBankBranch ?? '')
    // }
  }, [props.detail])

  return (
    <View style={{ flex: 1, paddingVertical: 24 }}>
      <View style={styles.row}>
        <View style={styles.col}>
          <Controller
            name="Bankaccount"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('Bankaccount')}
                disabled={false}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                errorMessage={error?.message}
                maxLength={50}
                required={true}
                inputType="number"
              />
            )}
          />
        </View>
        <View style={styles.cols}>
          <Controller
            name="BanknameOther"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('BanknameOther') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              // <SelectSearch
              //   required={false}
              //   popupIcon={<assets.ArrowDownDropdownIcon />}
              //   label={t('BanknameOther')}
              //   options={bankOptions}
              //   value={value}
              //   onBlur={onBlur}
              //   onChange={(option) => {
              //     setBankBranchs([])
              //     props.form.setValue('generalForm.BanknameOther', null)
              //     if (option) {
              //       getBankBranch(option.value)
              //     }
              //     onChange(option)
              //   }}
              //   placeholder={t('common:Select')}
              //   // errorMessage={error?.message}
              //   // key={value}
              // />
              <Input
                title={t('BanknameOther')}
                disabled={false}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                errorMessage={error?.message}
                // maxLength={24}
                // required={true}
              />
            )}
          />
        </View>
        <View style={styles.col}>
          <Controller
            control={control}
            name="BankbranchnameOther"
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <View style={{ flex: 1 }}>
                {/* <SelectSearch
                  // key={props.form.getValues('generalForm.BankName')}
                  {...field}
                  label={t('BankbranchnameOther')}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  options={bankBranchOptions}
                  placeholder={t('common:Select')}
                  // errorMessage={error?.message}
                /> */}
                <Input
                  title={t('BankbranchnameOther')}
                  disabled={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value == null ? '' : value}
                  errorMessage={error?.message}
                  // maxLength={24}
                  // required={true}
                />
              </View>
            )}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    marginBottom: 16
  },
  containers: {
    width: '100%',
    padding: 30,
    borderRadius: 8,
    marginBottom: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6'
  },
  col: {
    width: '33.33333333333%'
  },
  cols: {
    width: '33.33333333333%',
    paddingHorizontal: 30
  },
  row: {
    flexDirection: 'row'
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' }
})
