import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CHANGE_SUM_ASSURED)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    payload: t.type({
      body: t.type({
        currentInstallmentPremium: t.number,
        currentSumAssured: t.number,
        maximumSumAssured: Maybe(t.number),
        minimumSumAssured: Maybe(t.number),
        newInstallmentPremium: t.number,
        newSumAssured: t.number,
        requirePayInAmount: t.number
      })
    })
  })
)

export const ChangeSumAssured = Pulse4Ops
export type ChangeSumAssured = t.TypeOf<typeof ChangeSumAssured>
