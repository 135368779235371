export namespace UploadDocumentConst {
  export interface TypeData {
    code: string
    name: string
    nameVi: string
  }

  export const TypeList: Array<TypeData> = [
    {
      code: 'TP01',
      name: 'Policy',
      nameVi: 'Hợp đồng'
    },
    {
      code: 'TP02',
      name: 'Proposal',
      nameVi: 'Hồ sơ yêu cầu bảo hiểm'
    }
  ]
}
