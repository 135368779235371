import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  ScrollView,
  Pressable,
  useWindowDimensions,
  ActivityIndicator
} from 'react-native'
import { IBCategoryTab, CategoryTabInfo, IBGeneralTable } from '../../../../common'
import IBPolicyInfoTab from './IBPolicyInfoTab/IBPolicyInfoTab'
import { IBIsuranceBenefitTab } from './IBIsuranceBenefitTab'
import {
  assets,
  RBAC,
  ErrorHandling,
  IBPolicyPermission,
  ContractMapping,
  useTranslator,
  PremiumStatusMapping,
  CancelRiderService,
  ModalComponent
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import {
  PolicyDetail,
  PolicyInfoData,
  PolicyValueInfoData,
  APLValueData,
  OPLValueData,
  PrucashBenefits,
  ContractValueData,
  InvestmentDataInfo,
  IBService,
  ProductTypeC,
  InvestmentFund,
  ClientInsuranceInfo,
  ClientBenInfo,
  ClientSpecialBenInfo,
  ClaimPaymentInfo,
  ChangePOHistoryData,
  CheckNoteInformationData,
  PolicyNoteDetailData,
  PolicyNoteWarningData,
  LifeAssuredInfo,
  NBUWInfoResponse,
  DocumentsTabsData
} from '../../../../ib-service'
import i18next from 'i18next'
import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _, { isEmpty, isNil, orderBy } from 'lodash'
import { IBPushToTop } from '../../../../common'
import { IBNBUWTab } from './IBNBUWTab'
import { IBAgentInfoTab } from './IBAgentInfo'
import { IBInvesmentInfo } from './IBInvesmentInfoTab'
import { IBPaymentInfo } from './IBPaymentInfo'
import { IBDocumentInfoTab } from './IBDocumentInfoTab'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'

const ModalChangeHistoryInfo = (props: {
  visible: boolean
  onClose: (visible: boolean) => void
  policyNumber?: string
}) => {
  const { width } = useWindowDimensions()
  const { visible, onClose, policyNumber } = props
  const [data, setData] = React.useState<ChangePOHistoryData>()
  const [loading, bindLoading] = useLoading(false)
  const { t } = useTranslation('Inbound')

  React.useEffect(() => {
    pipe(
      IBService.getChangePOHistory(policyNumber as string),
      ZIO.map((res) => {
        setData(res.data)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [])

  return (
    <ModalComponent
      title={t('ChangeContractOwnerHistory')}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20, flexDirection: 'row' }}>
        {loading ? (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
            <ActivityIndicator size="large" color="red" />
          </View>
        ) : (
          <>
            <View style={{ width: '50%' }}>
              <Text style={{ marginBottom: 10, fontSize: 15, color: '#58647A', fontWeight: '700' }}>
                {t('LastedEffectiveDate')}
              </Text>
              {data?.effectiveDate ? <Text style={{ fontSize: 15 }}>{data?.effectiveDate}</Text> : <Text>-</Text>}
            </View>
            <View style={{ width: '50%' }}>
              <Text style={{ marginBottom: 10, fontSize: 15, color: '#58647A', fontWeight: '700' }}>
                {t('ListOldPO')}
              </Text>
              {!isEmpty(data?.ownerHistory) && !isNil(data?.ownerHistory) ? (
                data?.ownerHistory?.map((item) => {
                  return <Text style={{ marginBottom: 5, fontSize: 15 }}>{item.clientName.name}</Text>
                })
              ) : (
                <Text>-</Text>
              )}
            </View>
          </>
        )}
      </View>
    </ModalComponent>
  )
}
interface TaskDetailHeaderProps {
  data: PolicyDetail
  scrollToKey?: (key: string) => void
  canPressScroll: boolean
}

const IBTasksDetailHeader = ({ data, scrollToKey, canPressScroll }: TaskDetailHeaderProps) => {
  const { t, i18n } = useTranslation()
  const ct = useTranslator('contract').t
  const ps = useTranslator('premiumStatus').t
  const [openModalChangePOHistory, setOpenModalChangePOHistory] = React.useState<boolean>(false)
  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerRowPolicy}>
        <View>
          <Text style={{ fontSize: 15 }}>{t('common:PolicyNumber')}</Text>
          <Text style={styles.headerRowPolicyContent}>{data.policyNumber}</Text>
        </View>
        <View>
          <Text style={{ fontSize: 15 }}>{t('Inbound:MainProduct')}</Text>
          <Text style={styles.headerRowPolicyContent}>
            {(data.mainProductCode ?? '') +
              ' ' +
              (i18n.language === 'en' ? data.mainProductNameEn : data.mainProductNameVi)}
          </Text>
        </View>
        <View>
          <Text style={{ fontSize: 15 }}>{t('Inbound:PolicyStatus')}</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.headerRowPolicyContent}>{ContractMapping.get(ct)(data.policyStatus)}</Text>
            {data.policyStatus === 'LA' ? (
              <Pressable
                onPress={() => (scrollToKey ? scrollToKey(t('Inbound:Reinstatement')) : '')}
                style={{ marginLeft: 5 }}
                disabled={!canPressScroll}
              >
                <assets.IBChevronRightCircle />
              </Pressable>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View>
          <Text style={{ fontSize: 15 }}>{t('Inbound:PremiumStatus')}</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.headerRowPolicyContent}>{PremiumStatusMapping.get(ps)(data.premiumStatus)}</Text>
            <Pressable
              onPress={() => (scrollToKey ? scrollToKey(t('Inbound:TransactionHistory')) : '')}
              style={{ marginLeft: 5 }}
            >
              <assets.IBChevronRightCircle />
            </Pressable>
          </View>
        </View>
      </View>
      <View style={styles.headerRowPO}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ marginRight: 8, fontSize: 15 }}>{t('Inbound:OwnerName')}</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.headerRowPolicyContent}>{data.policyOwnerName ?? '-'}</Text>
            <View style={{ marginLeft: 5 }}>
              <Pressable onPress={() => setOpenModalChangePOHistory(true)}>
                <assets.IBChevronRightCircle />
              </Pressable>
            </View>
          </View>
        </View>
        <View></View>
        <View></View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ marginRight: 8, fontSize: 15 }}>{t('Inbound:MainLifeAssured')}</Text>
          <Text style={styles.headerRowPolicyContent}>
            {_.isNil(data.mainLAName) || data.mainLAName === 'undefined' ? '-' : data.mainLAName}
          </Text>
        </View>
      </View>
      {openModalChangePOHistory ? (
        <ModalChangeHistoryInfo
          visible={openModalChangePOHistory}
          onClose={setOpenModalChangePOHistory}
          policyNumber={data.policyNumber}
        />
      ) : (
        <></>
      )}
    </View>
  )
}

const ModalPolicyNote = (props: { visible: boolean; onClose: (visible: boolean) => void; policyNumber?: string }) => {
  const { t, i18n } = useTranslation('Inbound')
  const { width } = useWindowDimensions()
  const [loading, bindLoading] = useLoading(false)
  const { visible, onClose, policyNumber } = props
  const [data, setData] = React.useState<PolicyNoteDetailData[]>([])

  React.useEffect(() => {
    if (policyNumber)
      pipe(
        IBService.getPolicyNoteDetail(policyNumber),
        ZIO.map((res) => {
          setData(res.data)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
  }, [])

  const dataTable = [
    {
      label: t('Date'),
      field: 'notedDate',
      format: 'date'
    },
    {
      label: t('Time'),
      field: 'notedTime',
      format: 'time'
    },
    {
      label: t('Dep'),
      field: 'departmentCode'
    },
    {
      label: t('UserPolicyNote'),
      field: 'lasUserId'
    },
    {
      label: t('Note'),
      field: 'notedContent',
      render: (value: string) => {
        return (
          <View style={{ width: 400 }}>
            <Text>{value}</Text>
          </View>
        )
      },
      disabled: true
    }
  ]
  return (
    <ModalComponent
      title={t('PolicyNoteDetail')}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={orderBy(
            data.map((item) => ({
              ...item,
              notedDate: moment(item.notedDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              notedTime: moment(item.notedTime, 'HH:mm:ss').format('HHmmss'),
              departmentCode: item.departmentCode || '-',
              lasUserId: item.lasUserId || '-',
              notedContent: item.notedContent || '-'
            })),
            ['notedDate', 'notedTime'],
            ['desc', 'desc']
          )}
          loading={loading}
        />
      </View>
    </ModalComponent>
  )
}

const ModalPolicyWarning = (props: {
  visible: boolean
  onClose: (visible: boolean) => void
  policyNumber?: string
}) => {
  const { t, i18n } = useTranslation('Inbound')
  const { width } = useWindowDimensions()
  const [loading, bindLoading] = useLoading(false)
  const { visible, onClose, policyNumber } = props
  const [data, setData] = React.useState<PolicyNoteWarningData[]>([])

  React.useEffect(() => {
    if (policyNumber)
      pipe(
        IBService.getPolicyNoteWarning(policyNumber),
        ZIO.map((res) => {
          setData(res.data)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
  }, [])

  const dataTable = [
    {
      label: t('Code'),
      field: 'code'
    },
    {
      label: t('Status'),
      field: 'status'
    },
    {
      label: t('FromDate'),
      field: 'fromDate',
      format: 'date'
    },
    {
      label: t('ToDate'),
      field: 'toDate',
      format: 'date'
    },
    {
      label: t('ShortDescription'),
      field: 'shortDesc'
    },
    {
      label: t('Desciption'),
      field: 'longDesc',
      render: (value: string) => {
        return (
          <View style={{ width: 400 }}>
            <Text>{value}</Text>
          </View>
        )
      },
      disabled: true
    },
    {
      label: t('OPID'),
      field: 'lasCreatedUserId'
    },
    {
      label: t('RIsID'),
      field: 'lasUpdatedUserId'
    },
    {
      label: t('Solution'),
      field: 'solution',
      render: (value: string) => {
        return (
          <View style={{ width: 400 }}>
            <Text>{value}</Text>
          </View>
        )
      },
      disabled: true
    }
  ]
  return (
    <ModalComponent
      title={t('WarningMessageDetail')}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={orderBy(
            data.map((item) => ({
              ...item,
              longDesc:
                !isEmpty(item.longDesc) && !isNil(item.longDesc)
                  ? item.longDesc.reduce((pre, cur) => (pre += ` ${cur}`), '')
                  : '-',
              solution:
                !isEmpty(item.solutions) && !isNil(item.solutions)
                  ? item.solutions.reduce((pre, cur) => (pre += ` ${cur}`), '')
                  : '-',
              fromDate: moment(item.fromDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              toDate: moment(item.toDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              code: item.code || '-',
              status: item.status || '-',
              shortDesc: item.shortDesc || '-',
              lasCreatedUserId: item.lasCreatedUserId || '-',
              lasUpdatedUserId: item.lasUpdatedUserId || '-'
            })),
            ['fromDate', 'toDate'],
            ['desc', 'desc']
          )}
          loading={loading}
        />
      </View>
    </ModalComponent>
  )
}

interface DetailNotificationProps {
  messageData: { Anniversary: string; FullyPaid: string } & CheckNoteInformationData
  policyNumber?: string
}

const IBTasksDetailNotification = ({ messageData, policyNumber }: DetailNotificationProps) => {
  const { t, i18n } = useTranslation('Inbound')
  const [modalPolicyNote, setModalPolicyNote] = React.useState<boolean>(false)
  const [modalPolicyWarning, setModalPolicyWarning] = React.useState<boolean>(false)
  return (
    <View style={{ flexDirection: 'row' }}>
      {messageData.Anniversary || messageData.isHasPolicyNote || messageData.isHasWarning || messageData.FullyPaid ? (
        <View style={styles.notificationContainer}>
          <View style={{ flexDirection: 'row' }}>
            {messageData.Anniversary ? (
              <View style={{ backgroundColor: '#EBF8F7', marginBottom: 10, padding: 5 }}>
                <Text style={[styles.notificationText, { color: '#09A4D8' }]}>{messageData.Anniversary}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: messageData.isHasPolicyNote || messageData.isHasWarning ? 10 : 0
            }}
          >
            {messageData.isHasPolicyNote ? (
              <View style={{ backgroundColor: '#FEEDEE', padding: 5, marginRight: 10 }}>
                <Pressable onPress={() => setModalPolicyNote(true)}>
                  <Text style={[styles.notificationText, { color: '#ED1B2D', textDecorationLine: 'underline' }]}>
                    {t('PolicyNote')}
                  </Text>
                </Pressable>
              </View>
            ) : (
              <></>
            )}
            {messageData.isHasWarning ? (
              <View style={{ backgroundColor: '#FEEDEE', padding: 5 }}>
                <Pressable onPress={() => setModalPolicyWarning(true)}>
                  <Text style={[styles.notificationText, { color: '#ED1B2D', textDecorationLine: 'underline' }]}>
                    {t('WarningMessage')}
                  </Text>
                </Pressable>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            {messageData.FullyPaid ? (
              <View style={{ backgroundColor: '#EDFAF4', padding: 5 }}>
                <Text style={[styles.notificationText, { color: '#1DC06F' }]}>{messageData.FullyPaid}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
      {messageData.isUpdatedCustomerInfo || messageData.isUpdatedPO || messageData.hasAmlMessage ? (
        <View style={styles.notificationContainer}>
          <View style={{ flexDirection: 'row' }}>
            {messageData.isUpdatedCustomerInfo ? (
              <View style={{ backgroundColor: '#EDFAF4', padding: 5, marginBottom: 10 }}>
                <Text style={[styles.notificationText, { color: '#1DC06F' }]}>{'Customer Information update'}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            {messageData.isUpdatedPO ? (
              <View style={{ backgroundColor: '#EDFAF4', padding: 5, marginBottom: 10 }}>
                <Text style={[styles.notificationText, { color: '#1DC06F' }]}>{'Update POs Tel-Email-Add'}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            {messageData.hasAmlMessage ? (
              <View style={{ backgroundColor: '#EDFAF4', padding: 5 }}>
                <Text style={[styles.notificationText, { color: '#1DC06F' }]}>{'Report to AML Team'}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
      {messageData.hasEmailActuaryForPolAcc || messageData.isHCRWillNotBeRenewed ? (
        <View style={styles.notificationContainer}>
          <View style={{ flexDirection: 'row' }}>
            {messageData.hasEmailActuaryForPolAcc ? (
              <View style={{ backgroundColor: '#EDFAF4', padding: 5, marginBottom: 10 }}>
                <Text style={[styles.notificationText, { color: '#1DC06F' }]}>{t('EmailActuaryForPolAcc')}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            {messageData.isHCRWillNotBeRenewed ? (
              <View style={{ backgroundColor: '#EDFAF4', padding: 5, marginBottom: 10 }}>
                <Text style={[styles.notificationText, { color: '#1DC06F' }]}>{t('HCRWillNotBeRenewed')}</Text>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
      {modalPolicyNote ? (
        <ModalPolicyNote visible={modalPolicyNote} onClose={setModalPolicyNote} policyNumber={policyNumber} />
      ) : (
        <></>
      )}
      {modalPolicyWarning ? (
        <ModalPolicyWarning visible={modalPolicyWarning} onClose={setModalPolicyWarning} policyNumber={policyNumber} />
      ) : (
        <></>
      )}
    </View>
  )
}

interface PromotionProps {
  policyNumber: string
}

const IBTasksDetailPromotionInfo = (props: PromotionProps) => {
  const { t, i18n } = useTranslation()
  const { policyNumber } = props
  const [promotionInfos, setPromotionInfo] = React.useState<string[]>()
  React.useEffect(() => {
    if (policyNumber) {
      pipe(
        IBService.getPromotionInfo(policyNumber),
        ZIO.map((res) => {
          setPromotionInfo(
            res?.data?.havePromotion
              ? res?.data.promotions?.map(
                  (pro) =>
                    `• ${pro.name} - ${pro.description} - ${t('Outbound:OBNotification:From')}: ${moment(
                      pro.fromDate
                    ).format('DD/MM/YYYY')} - ${t('Outbound:OBNotification:To')}: ${moment(pro.toDate).format(
                      'DD/MM/YYYY'
                    )}`
                )
              : undefined
          )
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [policyNumber])
  if (!promotionInfos) return <></>
  return (
    <View style={{ marginVertical: 5, height: promotionInfos.length >= 3 ? 60 : undefined }}>
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View style={{ flexDirection: 'row' }}>
          <View style={[styles.iconContainer, { marginRight: 5 }]}>
            <assets.OBPromotionIcon />
          </View>
          <View>
            <Text style={{ fontSize: 15, fontWeight: 'bold', marginRight: 5 }}>
              {t('Outbound:OBCalloutDetail:Promotion')}:
            </Text>
          </View>
          <View style={{ width: '100%' }}>
            {promotionInfos?.map((x) => (
              <Text style={{ fontSize: 15, fontWeight: '600', width: '90%' }}>{x}</Text>
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
interface Props {
  data: PolicyDetail
  clientNumber: string
}

export interface PolicyInfoRef {
  scrollToKey: (key: string) => void
}

export const IBClientPolicyTasksDetail = ({ data, clientNumber }: Props) => {
  const { t, i18n } = useTranslation()
  const [listTabHasPermission, setListTabHasPermission] = React.useState<CategoryTabInfo[]>([])
  const policyInfoTabRef = React.useRef<PolicyInfoRef>(null)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const productInfo: ProductTypeC = pipe(
    IBService.getProductType(data.policyNumber as string),
    ErrorHandling.runDidMount({})
  )
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const [policyInfo, setPolicyInfo] = React.useState<
    | (PolicyInfoData &
        PolicyValueInfoData & {
          aplValue: APLValueData
          oplValue: OPLValueData
          prucashBenefits: PrucashBenefits
          contractValue: ContractValueData
        })
    | null
  >(null)

  const [investmentInfo, setInvestmentInfo] = React.useState<InvestmentDataInfo>()
  const [listFundT25, setListFundT25] = React.useState<InvestmentFund[]>([])
  const [laList, setLaList] = React.useState<ClientInsuranceInfo[] | null>(null)
  const [benList, setBenList] = React.useState<ClientBenInfo[] | null>(null)
  const [specialBenList, setSpecialBenList] = React.useState<ClientSpecialBenInfo[] | null>(null)
  const [listPaymentClaim, setListPaymentClaim] = React.useState<ClaimPaymentInfo[] | null>(null)
  const [listProduct, setListProduct] = React.useState<LifeAssuredInfo[] | null>(null)
  const [productJoinLife, setProductJoinLife] = React.useState<LifeAssuredInfo | null>(null)
  const [nbuwInfo, setNbuwInfo] = React.useState<NBUWInfoResponse | null>(null)
  const [documentsInfo, setDocumentsInfo] = React.useState<DocumentsTabsData>({
    ClaimAdvanceDocs: [],
    ClaimInfomationDocs: [],
    EInvoiceDocs: [],
    PolicyInfomationDocs: []
  })

  const scrollRef = React.useRef<ScrollView | null>(null)
  const [messageData, setMessageData] = React.useState<
    { Anniversary: string; FullyPaid: string } & CheckNoteInformationData
  >({
    Anniversary: '',
    FullyPaid: '',
    hasEmailActuaryForPolAcc: false,
    hasAmlMessage: false,
    isUpdatedCustomerInfo: false,
    isUpdatedPO: false,
    isHasWarning: false,
    isHasPolicyNote: false,
    isHCRWillNotBeRenewed: false
  })

  React.useEffect(() => {
    if (data.policyNumber) {
      pipe(
        IBService.CheckNoteInformation(data.policyNumber),
        ZIO.map((res) => {
          setMessageData((prevState) => ({
            ...prevState,
            ...res.data
          }))
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [data.policyNumber])

  const YearAnniversary = [5, 10, 15, 20]

  const notifyAnniversary = (riskCommenDate: string) => {
    const today = new Date()

    const riskDate = riskCommenDate?.split('/').reverse().join('/')

    const RCD = new Date(riskDate)

    const currentYear = today.getFullYear()

    const yearAnni = currentYear - RCD.getFullYear()

    if (YearAnniversary.includes(yearAnni)) {
      if (today.getMonth() === RCD.getMonth()) {
        setMessageData((prevState) => ({
          ...prevState,
          Anniversary: i18next.t('message:MSnotifyAnniversary', { year: yearAnni })
        }))
      }
      return
    }
  }

  const notifyFullyPaid = (PTDate: string, PCDate: string, premiumStatus: string) => {
    if (premiumStatus === 'FP' || premiumStatus === 'PU') {
      setMessageData((prevState) => ({
        ...prevState,
        FullyPaid: i18next.t('message:MSFullyPaid')
      }))
    } else if (premiumStatus === 'PP') {
      const paidTD = PTDate?.split('/').reverse().join('/')
      const premCD = PCDate?.split('/').reverse().join('/')

      const PTD = new Date(paidTD + ' 00:00:00')
      const PCD = new Date(premCD + ' 00:00:00')

      if (PTD.valueOf() === PCD.valueOf()) {
        setMessageData((prevState) => ({
          ...prevState,
          FullyPaid: i18next.t('message:MSFullyPaid')
        }))
      }
    }
  }

  const categoryTabList: Array<CategoryTabInfo> = [
    {
      label: t('Inbound:PolicyInfo'),
      key: '1',
      permission: IBPolicyPermission.ViewIBPolicyInfoPolicyDetail
    },
    {
      label: t('Inbound:InsuranceBenefits'),
      key: '2',
      permission: IBPolicyPermission.ViewIBInsuranceBenefitInfoPolicyDetail
    },
    {
      label: t('Inbound:PaymentInfo'),
      key: '3',
      permission: IBPolicyPermission.ViewIBPaymentInfoPolicyDetail
    },
    {
      label: t('Inbound:InvestmentInfo'),
      key: '4',
      permission:
        !_.isEmpty(productInfo) && ['ULP', 'ILP'].includes(productInfo.body.productType)
          ? IBPolicyPermission.ViewIBInvestmentInfoPolicyDetail
          : 'notPermissionShow'
    },
    {
      label: t('Inbound:NBUWInfo'),
      key: '5',
      permission: IBPolicyPermission.ViewIBNBUWInfoPolicyDetail
    },
    {
      label: t('Inbound:AgentInfo'),
      key: '6',
      permission: IBPolicyPermission.ViewIBAgentInfoPolicyDetail
    },
    {
      label: t('Inbound:DocumentInfomation'),
      key: '7',
      permission: IBPolicyPermission.ViewIBDocumentPolicyDetail
    }
    // {
    //   label: t('Inbound:PromotionInfo'),
    //   key: '7',
    //   permission: IBPolicyPermission.ViewIBPromotionInfoPolicyDetail
    // }
  ]

  React.useEffect(() => {
    const list: Array<CategoryTabInfo> = []
    categoryTabList.map((i) => {
      if (i.permission && permissions.includes(i.permission)) {
        list.push(i)
      }
    })
    setListTabHasPermission(list)
  }, [permissions, productInfo, i18n.language])

  const handlePushToTop = () => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true })
  }

  const getTabKey = (tabIndex: number) => {
    const tabShow = listTabHasPermission[tabIndex]
    return tabShow?.key ?? ''
  }

  return (
    <>
      <View style={styles.container}>
        <IBTasksDetailHeader
          data={data}
          scrollToKey={policyInfoTabRef.current?.scrollToKey}
          canPressScroll={!!policyInfo}
        />
        <IBTasksDetailNotification messageData={messageData} policyNumber={data.policyNumber} />
        <IBTasksDetailPromotionInfo policyNumber={data.policyNumber || ''} />
        <IBCategoryTab
          dataSource={listTabHasPermission}
          onChangeTab={setCategoryTabIndex}
          activeTabIndex={categoryTabIndex}
          permission={permissions}
        />
        <SafeAreaView style={{ flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false} ref={scrollRef}>
            {getTabKey(categoryTabIndex) === '1' && (
              <IBPolicyInfoTab
                ref={policyInfoTabRef}
                scrollRef={scrollRef}
                policyNumber={data.policyNumber || '-'}
                data={policyInfo}
                setData={setPolicyInfo}
                clientNumber={clientNumber || '-'}
                notifyAnniversary={notifyAnniversary}
                notifyFullyPaid={notifyFullyPaid}
                messageData={messageData}
              />
            )}
            {getTabKey(categoryTabIndex) === '2' && (
              <IBIsuranceBenefitTab
                policyNumber={data.policyNumber}
                laList={laList}
                setLaList={setLaList}
                benList={benList}
                setBenList={setBenList}
                specialBenList={specialBenList}
                setSpecialBenList={setSpecialBenList}
                listProduct={listProduct}
                setListProduct={setListProduct}
                listPaymentClaim={listPaymentClaim}
                setListPaymentClaim={setListPaymentClaim}
                productJoinLife={productJoinLife}
                setProductJoinLife={setProductJoinLife}
              />
            )}
            {getTabKey(categoryTabIndex) === '3' && <IBPaymentInfo policyNumber={data.policyNumber} />}
            {getTabKey(categoryTabIndex) === '4' && (
              <IBInvesmentInfo
                scrollRef={scrollRef}
                investmentInfo={investmentInfo}
                setInvestmentInfo={setInvestmentInfo}
                policyNumber={data.policyNumber || '-'}
                status={data.policyStatus as string}
                productInfo={productInfo}
                setListFundT25={setListFundT25}
                listFundT25={listFundT25}
              />
            )}
            {getTabKey(categoryTabIndex) === '5' && (
              <IBNBUWTab policyNumber={data.policyNumber ?? ''} nbuwInfo={nbuwInfo} setNbuwInfo={setNbuwInfo} />
            )}
            {getTabKey(categoryTabIndex) === '6' && <IBAgentInfoTab policyNumber={data.policyNumber} />}
            {getTabKey(categoryTabIndex) === '7' && (
              <IBDocumentInfoTab policyNumber={data.policyNumber} data={documentsInfo} setData={setDocumentsInfo} />
            )}
          </ScrollView>
        </SafeAreaView>
        <IBPushToTop onClick={handlePushToTop} />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  headerContainer: {
    backgroundColor: '#FFF',
    borderRadius: 10
  },
  headerRowPolicy: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: '#F9F9F9',
    flexWrap: 'wrap'
  },
  headerRowPolicyContent: {
    color: '#ED1B2E',
    fontWeight: '700',
    fontSize: 15
  },
  headerRowPO: {
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-around'
  },
  notificationContainer: {
    marginTop: 15,
    width: '33%'
  },
  notificationText: {
    fontWeight: '700',
    fontSize: 15
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#F05A67'
  }
})
