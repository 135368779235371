import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import moment from 'moment'
import { POApi } from '../POApi'
import { LoanModel, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export namespace LoanService {
  export const getDetail = (policyNum: string): Task<LoanModel.Detail> =>
    pipe(
      ZIO.zipPar(registerLoanEnquiry(policyNum), getPolicyLoanEnquiry(policyNum)),
      ZIO.map(([registerLoan, policyLoan]): LoanModel.Detail => {
        const principalAplOpl = Number(policyLoan.balanceAPL) + Number(policyLoan.balanceOPL)
        const interestAplOpl = Number(policyLoan.interestAPL) + Number(policyLoan.interestOPL)
        const contractSurrenderValue1 = registerLoan ? registerLoan?.contractSurrenderValue1 : 0
        const loanAmountAvailable1 = registerLoan ? registerLoan?.loanAmountAvailable1 : 0
        return {
          policyNum: policyNum,
          principalAplOpl: principalAplOpl,
          interestAplOpl: interestAplOpl,
          contractSurrenderValue: contractSurrenderValue1,
          loanAmountAvailable: loanAmountAvailable1
        }
      })
    )
  export const registerLoanEnquiry = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/bo/register-loan-enquiry`)(
        t.type({
          body: t.type({
            exchangeId: Maybe(t.string),
            action: Maybe(t.string),
            loanAmountAvailableEffectiveDate: Maybe(t.number),
            loanAmountAvailableAfterNextDueDate: Maybe(t.number),
            currentAmountAvailableEffectiveDate: Maybe(t.number),
            currentAmountAvailableAfterNextDueDate: Maybe(t.number),
            netLoanAmountPaid: Maybe(t.number),
            maturityAdvanceAmount: Maybe(t.number),
            contractSurrenderValueEffectiveDate: Maybe(t.number),
            contractSurrenderValueAfterNextDueDate: Maybe(t.number),
            loanDutyStamp: Maybe(t.number),
            contractCashDepositsEffectiveDate: Maybe(t.number),
            contractCashDepositsAfterNextDueDate: Maybe(t.number)
          })
        })
      )({
        body: {
          contractNumber: policyNum
        }
      }),
      ZIO.map((item) => {
        const contractSurrenderValue1 = item.body.contractSurrenderValueEffectiveDate
        const loanAmountAvailable1 = item.body.loanAmountAvailableEffectiveDate
        return {
          loanAmountAvailable1: loanAmountAvailable1,
          contractSurrenderValue1: contractSurrenderValue1
        }
      })
    )
  export const submitLoan =
    (policyNum: string, data: LoanModel.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(`wf-api/policy/${policyNum}/loan`, { body: data }, policyNum, requestauth, [])
  // pipe(
  //     PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/loan`)(t.unknown)({
  //         body: data,
  //         otpTransaction: requestauth,
  //         source: SourceType.PULSE4OPS
  //     }),
  //     ZIO.asVoid
  // )
  export const getDataAccess = (policyNum: string): Task<LoanModel.AccessDataCheck> =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNum), registerLoanEnquiry(policyNum)),
      ZIO.map(([policyInfo, registerLoan]) => ({
        coverageCode: String(policyInfo.body?.basicCode),
        contractStatus: String(policyInfo?.body?.status),
        loanAmountAvailable: Number(registerLoan.loanAmountAvailable1)
      }))
    )

  export const getPolicyLoanEnquiry = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/billing-change-apl/loan-enquiry`)(
        t.type({
          body: t.type({
            action: Maybe(t.string),
            contractType: Maybe(t.string),
            subfileOccurs: Maybe(
              t.array(
                t.type({
                  accuredInterest: Maybe(t.number),
                  pendingInterest: Maybe(t.number),
                  currentBalance: Maybe(t.number),
                  loanNumber: Maybe(t.number),
                  loanStartDate: Maybe(t.string),
                  loanType: Maybe(t.string),
                  principalAmount: Maybe(t.number),
                  policyLoanTotal: Maybe(t.number),
                  numCon: Maybe(t.number)
                })
              )
            )
          })
        })
      )({
        body: {
          contractNumber: policyNum,
          effectiveDate: moment(new Date()).format('yyyyMMDD')
        }
      }),
      ZIO.catchAll(() => ZIO.succeed(null)),
      ZIO.map((LoanEnquery) => {
        const interestAPL = !!LoanEnquery
          ? LoanEnquery.body.subfileOccurs?.reduce(function (total, item) {
              return item.loanType === 'A'
                ? total + (Number(item.pendingInterest) + Number(item.accuredInterest))
                : total
            }, 0)
          : 0
        const interestOPL = !!LoanEnquery
          ? LoanEnquery.body.subfileOccurs?.reduce(function (total, item) {
              return item.loanType === 'P'
                ? total + (Number(item.pendingInterest) + Number(item.accuredInterest))
                : total
            }, 0)
          : 0
        const balanceAPL = !!LoanEnquery
          ? LoanEnquery.body.subfileOccurs?.reduce(function (total, item) {
              return item.loanType === 'A' ? total + Number(item.currentBalance) : total
            }, 0)
          : 0
        const balanceOPL = !!LoanEnquery
          ? LoanEnquery.body.subfileOccurs?.reduce(function (total, item) {
              return item.loanType === 'P' ? total + Number(item.currentBalance) : total
            }, 0)
          : 0
        return {
          interestAPL: interestAPL,
          interestOPL: interestOPL,
          balanceAPL: balanceAPL,
          balanceOPL: balanceOPL
        }
      })
    )
}
