import { useMobile } from '@pulseops/common'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  menus: {
    label: string
    value: string
  }[]
  isNoti?: boolean
}

export const PolicyInfoHeader: React.FC<Props> = ({ menus, isNoti = false }) => {
  const { isMobile, isWide } = useMobile()

  return (
    <View
      style={[
        !isMobile && (isNoti ? styles.header1 : styles.header),
        isWide ? styles.headerWide : styles.headerWideMobile
      ]}
    >
      <View style={[styles.container, { flexWrap: isMobile ? 'wrap' : 'nowrap' }]}>
        {menus.map((m, i) => (
          <View
            key={i}
            style={
              isMobile
                ? styles.infoWrapper
                : [styles.infoWrapper, styles.infoWrapperWide, i === menus.length - 1 && { flex: 1 / 2 }]
            }
          >
            <Text style={[styles.label, isMobile ? { flex: 1 } : {}]}>{m.label}</Text>
            <Text style={[styles.bold, styles.label, styles.labelActive, isMobile ? { flex: 2 } : {}]}>{m.value}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    height: 68,
    marginTop: 10,
    marginBottom: 24
  },
  headerWide: {
    flexDirection: 'row',
    marginHorizontal: 15,
    // backgroundColor: '#E5EAEF',
    backgroundColor: '#FFF',
    borderRadius: 8
  },
  headerWideMobile: {
    flexDirection: 'row',
    marginHorizontal: 15,
    marginTop: 16,
    // backgroundColor: '#E5EAEF',
    backgroundColor: '#FFF',
    borderRadius: 8
  },
  image: {
    width: '100%',
    height: '100%'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 10
  },
  label: {
    fontSize: 15,
    lineHeight: 22,
    color: '#70777E'
  },
  labelActive: {
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  // Tab --
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 10
  },
  infoWrapper: {
    // flexDirection: 'row',
    // alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: 10
  },
  infoWrapperWide: {
    flex: 1 / 3,
    width: 'auto',
    paddingLeft: 33
  },
  bold: {
    fontWeight: 'bold'
  },
  header1: {
    height: 68,
    marginTop: 10,
    marginBottom: 10
  }
})
