import { ZIO } from '@mxt/zio'
import { assets, DatePicker, Form, Input, SelectOption, SelectSearch, useMobile } from '@pulseops/common'
import { diffYear } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { ClaimService } from '../../../../ClaimService'
import { generalStyles as styles, PaymentGeneralInfo } from '../../../../common'
import { DocumentInfo } from '../../add-more'
import { PaymentMethodProps } from '../PaymentMethodProps'

export const PaidAtBank = (props: PaymentMethodProps) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation('claim')
  const ct = useTranslation().t
  const [bankBranch, setBankBranch] = React.useState<SelectOption[]>([])
  const { form, banks, selectedDocumentType, setSelectedDocumentType } = props
  const register = Form.register(form)
  const {
    formState: { errors }
  } = form

  const watchPO = form.watch('payeeIsNotPolicyOwner')

  React.useEffect(() => {
    if (form.watch('bank')?.value) {
      pipe(
        ClaimService.getBankBranch(form.watch('bank')?.value || ''),
        ZIO.tap((bankBranch) => {
          setBankBranch(bankBranch.map((branch) => ({ value: branch.code, label: branch.name })))
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [form.watch('bank')])

  const onChangeBank = (e: SelectOption | null) => {
    if (!e) {
      setBankBranch([])
      form.setValue('bankBranch', null)
    }
  }

  return (
    <View style={{ width: '100%' }}>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('payeeName', {
              required: `${ct('message:MS020001', { field: t('PayeeName') })}`,
              maxLength: { value: 120, message: 'Max 120' },
              pattern: {
                value: /^[^<>'\"/;`%@*!(){}\[\],.:#+-=$^&\\|?~_-]*$/,
                message: `${t('message:MS050013', { field: t('PayeeName') })}`
              }
            })}
            disabled={!watchPO}
            errorMessage={errors.payeeName?.message || ''}
            title={t('PayeeName')}
            required={watchPO}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('payeeId', {
              required: `${ct('message:MS020001', { field: t('IDNumber') })}`,
              maxLength: { value: 24, message: 'Max 24' },
              pattern: {
                value: /^((?!501|504)[0-9]*)$/,
                message: `${t('message:MS050013', { field: t('IDNumber') })}`
              }
            })}
            disabled={!watchPO}
            errorMessage={errors.payeeId?.message}
            title={t('IDNumber')}
            required={watchPO}
          />
        </View>
        {!watchPO && (
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Controller
              name="dob"
              control={form.control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <DatePicker
                  disabled
                  label={t('contact:DOB')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        )}
      </View>
      <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <DatePicker
            {...register('issuedDate', {
              required: `${ct('message:MS020001', { field: t('IssuedDate') })}`,
              validate: (value) => {
                const years = (value && diffYear(new Date(), value)) || 0
                const message = ct('message:MS070014')
                return years >= 15 ? message : true
              }
            })}
            required
            errorMessage={errors.issuedDate?.message || ''}
            label={t('IssuedDate')}
          />
          {/* <Text style={{color: 'red'}}>{ !!issuedDate && errors.issuedDate?.message}</Text> */}
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="issuedBy"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020001', { field: t('IssuedBy') })}`
              },
              maxLength: { value: 250, message: `${ct('message:MaxLength', { field: 250 })}` },
              pattern: {
                // value: /^[^*&$#\\<>|~`!@%^()';.:\[\]"+=?]*$/g,
                value: /^[^*&$#\\<>|]*$/g,
                message: t('message:MS050223')
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                required
                title={t('IssuedBy')}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="bank"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020009', { field: t('Bank') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('Bank')}
                options={banks?.map((v) => ({ value: v.code, label: v.name })) || []}
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e)
                  onChangeBank(e)
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
              />
            )}
          />
        </View>
      </View>
      <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="bankBranch"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020001', { field: t('Branch') })}`
              },
              validate: (opt) => {
                return opt?.value === '' ? `${ct('message:MS020001', { field: t('Branch') })}` : true
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('Branch')}
                options={bankBranch || []}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
              />
            )}
          />
        </View>
      </View>
      {watchPO && <PaymentGeneralInfo form={form} />}
      <DocumentInfo
        form={form}
        setSelectedDocumentType={setSelectedDocumentType}
        selectedDocumentType={selectedDocumentType}
      />
    </View>
  )
}
