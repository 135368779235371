import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Button } from '@pulseops/business/core'
import { ErrorHandling, AppContext, AuthService } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import _ from 'lodash'
import { EnableCallPrograms, OBAssignmentService, OBCallProgram, OBCallProgramTypeCode, defaultDataAssignmentOB, mapCallProgram, originAssignmentOBList } from '@pulseops/outbound'
import { ScrollView } from 'react-native-gesture-handler'
import * as O from 'fp-ts/lib/Option'
import { useIsFocused } from '@react-navigation/native'

type AssignmentData = {
  callProgram: string,
  status: string
}

export const OBAssignmentTab = () => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [listAssignmentStatus, setListAssignmentStatus] = React.useState<AssignmentData[]>(defaultDataAssignmentOB)
  const [authorizedTransactionCodeList, setAuthorizedTransactionCodeList] = React.useState<string[]>([])

  const theme = createMuiTheme({
    overrides: {
      MuiSwitch: {
        root: {
          width: '85px',
          height: '48px'
        },
        switchBase: {
          // transform: 'translateX(2px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(35px)'
          }
        },
        track: {
          opacity: 1,
          backgroundColor: '#BDBDBD',
          borderRadius: 22 / 2,
          '&:before, &:after': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 23,
            height: 23
          },
          '&:before': {
            right: '50%',
            content: `${t('common:ON')}`,
            fontSize: 9,
            textAlign: 'center',
            lineHeight: 2.5,
            color: 'white',
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: 400
          },
          '&:after': {
            left: '50%',
            content: `${t('common:OFF')}`,
            fontSize: 9,
            textAlign: 'center',
            lineHeight: 2.5,
            color: 'white',
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: 400
          },
          '$checked$checked + &': {
            opacity: 1,
            backgroundColor: 'red'
          }
        },
        thumb: {
          boxShadow: 'none',
          width: 32,
          height: 32,
          color: 'white'
        }
      }
    }
  })

  React.useEffect(() => {
    if (isFocused) {
      pipe(
        AuthService.userInfo,
        ZIO.flatMap((userData) =>
          ZIO.zipPar(
            OBAssignmentService.getAssigneeState(userData.email),
            OBAssignmentService.getAuthorizationCallTypeListForUser(userData.email)
          )
        ),
        ZIO.map(([previousAssignmentList, transactionList]) => {
          const authorizationCallTypeCodes = transactionList.map((item) => pipe(
            mapCallProgram.get(item as OBCallProgramTypeCode),
            O.fromNullable,
            O.map((p) => p),
            O.getOrElse(() => '')
          ))
          setAuthorizedTransactionCodeList(authorizationCallTypeCodes)
          if (previousAssignmentList && previousAssignmentList.length > 0) {
            setListAssignmentStatus([...previousAssignmentList.filter((p) => authorizationCallTypeCodes.some((x) => x === p.callProgram)), ...defaultDataAssignmentOB.filter((x) => !previousAssignmentList.some((p) => p.callProgram === x.callProgram) && authorizationCallTypeCodes.some((k) => k === x.callProgram))])
          } else {
            setListAssignmentStatus(defaultDataAssignmentOB.filter((p) => authorizationCallTypeCodes.some((x) => x === p.callProgram)))
          }
        }),
        ErrorHandling.run()
      )
    }
    return () => {
      setListAssignmentStatus(defaultDataAssignmentOB)
      setAuthorizedTransactionCodeList([])
    }
  }, [isFocused])


  const updateStatus = (arr: AssignmentData[], callProgram: OBCallProgram, status: Partial<AssignmentData>): AssignmentData[] => {
    return arr.map((item) => (item.callProgram === callProgram ? { ...item, ...status } : item))
  }

  const handleSubmit = React.useCallback(() => {
    pipe(
      OBAssignmentService.changeAssignee(listAssignmentStatus),
      ZIO.map((res) => {
        showToast(t('common:SaveSuccess'), 'success')
      }),
      ErrorHandling.run()
    )
  }, [listAssignmentStatus])

  const handleChange = (status: boolean, callProgram: OBCallProgram) => {
    const newList = updateStatus(listAssignmentStatus, callProgram, { status: status === true ? "ON" : "OFF" })
    setListAssignmentStatus([...newList])
  }

  const ListAssignment = () => {
    return originAssignmentOBList.filter((p) => authorizedTransactionCodeList.some((x) => x === p.callProgram)).map((item) => {
      const itemCheckedVal = listAssignmentStatus.some((p) => p.callProgram === item.callProgram && p.status === 'ON')
      return EnableCallPrograms.includes(item.callProgram) ? (
        <View style={styles.wrapper}>
          <Text style={{ marginRight: '20px', minWidth: 180 }}>{item.title(t)}</Text>
          <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={itemCheckedVal} onChange={(e) => handleChange(e.target.checked, item.callProgram)} />} label="" />
            </FormGroup>
          </ThemeProvider>
        </View>
      ) : <></>
    })
  }

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.header}>{t('Setting:OBAssignmentON_OFF')}</Text>
      {ListAssignment()}
      {listAssignmentStatus && listAssignmentStatus.length > 0 && (
        <View style={styles.btnSave}>
          <Button title={t('common:Save')} onPress={handleSubmit} />
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30,
    paddingLeft: '5%'
  },

  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30
  },

  wrapper: {
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },

  btnSave: {
    width: 100,
    marginTop: 30,
    marginRight: 'auto'
  }
})