import React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  ConfirmOfPremiumService,
  DatePicker,
  ConfirmOfPremium,
  TransactionType,
  Frequency,
  Title
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Controller, useForm } from 'react-hook-form'
import { PolicyServiceProps } from './policy-service-props'
import moment from 'moment'
import { RequestAuthenticateData } from '../request-authen'
import { Column, Label } from '@pulseops/submission/common'

interface ConfirmOfPremiumReceiptForm {
  fromDate: Date
  toDate: Date
}

export const ConfirmOfPremiumReceipt = (props: PolicyServiceProps<ConfirmOfPremium.SubmitData>) => {
  const toDateMax: Date = new Date()
  const policyNum = props.policyNumber ?? ''
  const { t } = useTranslation()
  const confirmOfPremiumForm = useForm<ConfirmOfPremiumReceiptForm>({
    defaultValues: {
      fromDate: undefined,
      toDate: new Date()
    }
  })
  const [detailData, setDetailData] = React.useState<ConfirmOfPremium.Detail | null>()
  const [nextStepMsg, setNextStepMsg] = React.useState<string>('')
  const {
    control,
    setValue,
    setError,
    watch
    // formState: { errors }
  } = confirmOfPremiumForm
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  // const submitData = (data: ConfirmOfPremiumReceiptForm) => {
  // }

  React.useEffect(() => {
    if (!watch('fromDate')) {
      showGlobalLoading(true)
      pipe(
        ConfirmOfPremiumService.getDetail(policyNum),
        ZIO.map((responseData) => {
          if (responseData) {
            setDetailData(responseData)
            setValue('fromDate', responseData.rcd)
          }
          showGlobalLoading(false)
        }),
        ZIO.catchAll(() => {
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [detailData])

  props.initSubmission({
    validate: async () => {
      // await confirmOfPremiumForm.trigger()
      if (validationNextTab()) {
        const fromDateVal = moment(watch('fromDate')).format('DD/MM/yyyy')
        const toDateVal = moment(watch('toDate')).format('DD/MM/yyyy')
        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/premium-receipt`,
          body: {
            transactionType: TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT,
            fromDate: fromDateVal,
            toDate: toDateVal,
            reason: ''
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(
            TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT
          ),
          collerationId: props.policyNumber ?? '',
          transaction: TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT
        }
      } else {
        return false
      }
    },
    clear: () => {
      // todo
      confirmOfPremiumForm.reset({
        fromDate: detailData?.rcd as Date,
        toDate: toDateMax
      })
    }
  })

  const validationNextTab = () => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')
    const riskCommencementDate = detailData?.rcd as Date
    const currentMoment = moment()
    const billingFrequency = detailData?.billingFrequency ?? ''
    const distanceYear = moment(toDate).diff(moment(fromDate), 'years')
    if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
      return false
    }
    if (moment(fromDate) < moment(riskCommencementDate)) {
      return false
    } else if (moment(fromDate) > moment(toDate)) {
      return false
    } else if (moment(toDate) > currentMoment) {
      return false
    } else if (distanceYear > 2 && billingFrequency === Frequency.Monthly) {
      setNextStepMsg(t('message:MS030049', { k: 2 }))
      return false
    } else {
      setNextStepMsg('')
      return true
    }
  }

  const onChangeFromDateEvent = (fromDate: Date | null) => {
    const billingFrequency = detailData?.billingFrequency ?? ''
    const distanceYear = moment(watch('toDate')).diff(moment(fromDate), 'years')
    const riskCommencementDate = detailData?.rcd as Date
    setError('fromDate', { message: '' })
    setNextStepMsg('')
    if (fromDate === null) {
      setError('fromDate', { message: `${t('message:MS020001', { field: t('common:FromDate') })}` })
    } else if (!moment(fromDate).isValid()) {
      setError('fromDate', { message: '' })
    } else if (moment(fromDate) < moment(riskCommencementDate)) {
      setError('fromDate', {
        message: t('message:MS030046', { RCD: moment(riskCommencementDate).format('DD/MM/YYYY') })
      })
    } else if (moment(fromDate) > moment(watch('toDate'))) {
      setError('fromDate', {
        message: t('message:MS030042')
      })
    } else if (distanceYear > 2 && billingFrequency === Frequency.Monthly) {
      setNextStepMsg(t('message:MS030049', { k: 2 }))
    }
  }

  const onChangeToDateEvent = (toDate: Date | null) => {
    const billingFrequency = detailData?.billingFrequency ?? ''
    const distanceYear = moment(toDate).diff(moment(watch('fromDate')), 'years')
    const currentMoment = moment()
    setError('toDate', { message: '' })
    setNextStepMsg('')
    if (toDate === null) {
      setError('toDate', { message: `${t('message:MS020001', { field: t('common:ToDate') })}` })
    } else if (!moment(toDate).isValid()) {
      setError('toDate', { message: '' })
    } else if (moment(watch('fromDate')) > moment(toDate)) {
      setError('toDate', {
        message: t('message:MS030042')
      })
    } else if (moment(toDate) > currentMoment) {
      setError('toDate', {
        message: t('message:MS030043')
      })
    } else if (distanceYear > 2 && billingFrequency === Frequency.Monthly) {
      setNextStepMsg(t('message:MS030049', { k: 2 }))
    }
  }

  const renderReadOnlyValue = (title: string, value: string | null | undefined, required: boolean = false) => {
    return (
      <Column>
        <Label title={title} fontWeight="bold" required={required} />
        <Column marginTop={10}>
          <Label title={value || '-'} color={'#000000'} />
        </Column>
      </Column>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Title title={t('requestInfo:LetterRequest')} />
      <View style={receiptStyles.sectionContent}>
        <View style={receiptStyles.sectionRow}>
          <View style={receiptStyles.col_4}>
            <Controller
              name="fromDate"
              control={control}
              // defaultValue={fromDate}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('common:FromDate') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                const isReadOnly = props.isConfirmed

                if (isReadOnly) {
                  return renderReadOnlyValue(t('common:FromDate'), moment(value).format('DD/MM/yyyy'))
                }

                return (
                  <DatePicker
                    required={true}
                    label={t('common:FromDate')}
                    onBlur={onBlur}
                    onChange={(val) => {
                      onChange(val)
                      // onChangeFromDateEvent(val, watch('toDate'), 1)
                      onChangeFromDateEvent(val)
                    }}
                    value={value}
                    minDate={detailData?.rcd as Date}
                    maxDate={watch('toDate') ? watch('toDate') : toDateMax}
                    errorMessage={error?.message}
                    minDateMessage={''}
                    maxDateMessage={''}
                    alwaysShow={false}
                    disabled={props.isConfirmed}
                  />
                )
              }}
            />
          </View>
          <View style={receiptStyles.col_4}>
            <Controller
              name="toDate"
              control={control}
              defaultValue={new Date()}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('common:ToDate') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                const isReadOnly = props.isConfirmed

                if (isReadOnly) {
                  return renderReadOnlyValue(t('common:ToDate'), moment(value).format('DD/MM/yyyy'))
                }

                return (
                  <DatePicker
                    required={true}
                    label={t('common:ToDate')}
                    value={value}
                    onChange={(val) => {
                      onChange(val)
                      // onChangeFromDateEvent(watch('fromDate'), val, 2)
                      onChangeToDateEvent(val)
                    }}
                    onBlur={onBlur}
                    minDate={watch('fromDate') ? watch('fromDate') : (detailData?.rcd as Date)}
                    maxDate={toDateMax}
                    errorMessage={error?.message}
                    minDateMessage={''}
                    maxDateMessage={''}
                    alwaysShow={false}
                    disabled={props.isConfirmed}
                  ></DatePicker>
                )
              }}
            />
          </View>
        </View>
      </View>

      <View style={[receiptStyles.sectionRow, receiptStyles.secondLine]}>
        <View style={receiptStyles.col_12}>
          {!!nextStepMsg && <Text style={{ color: '#ed1c2e' }}>{nextStepMsg}</Text>}
        </View>
      </View>
    </SafeAreaView>
  )
}

const receiptStyles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  secondLine: {
    marginTop: 20
  },
  buttonContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  basicBtn: {
    // outline: 'none',
    width: 118,
    height: 40,
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 100,
    color: '#ED1B2E',
    border: 'solid 1px #ED1B2E',
    boxShadow: 'none'
  }
})
