import moment from 'moment'

export const getAgingDayFilterParams = (aging: number) => {
  const fromDate = moment().startOf('days').subtract(aging, 'days').toDate()
  fromDate.setHours(0)
  fromDate.setMinutes(0)
  fromDate.setSeconds(0)

  const toDate = moment().startOf('days').subtract(aging, 'days').toDate()
  toDate.setHours(23)
  toDate.setMinutes(59)
  toDate.setSeconds(59)

  return { fromDate, toDate }
}

export const getCreatedDateFilterParams = (startDate: Date) => {
  const fromDate = new Date(startDate)
  fromDate.setHours(0)
  fromDate.setMinutes(0)
  fromDate.setSeconds(0)

  const toDate = new Date(startDate)
  toDate.setHours(23)
  toDate.setMinutes(59)
  toDate.setSeconds(59)

  return { fromDate, toDate }
}

export const formatPeriodTime = (fromDate: Date, toDate: Date) => {
  fromDate.setHours(0)
  fromDate.setMinutes(0)
  fromDate.setSeconds(0)

  toDate.setHours(23)
  toDate.setMinutes(59)
  toDate.setSeconds(59)

  return { fromDate, toDate }
}

export const parseAging = (agingFrom: number, agingTo: number) => {
  const fromDate = moment().startOf('days').subtract(agingTo, 'days').toDate()
  fromDate.setHours(0)
  fromDate.setMinutes(0)
  fromDate.setSeconds(0)

  const toDate = moment().startOf('days').subtract(agingFrom, 'days').toDate()
  toDate.setHours(23)
  toDate.setMinutes(59)
  toDate.setSeconds(59)

  return { fromDate, toDate }
}
