import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export const CommunicationTrackingData = t.type({
  businessKey: Maybe(t.string),
  policyNumber: Maybe(t.string),
  transactionType: Maybe(t.string),
  temporaryFile: Maybe(t.string),
  status: Maybe(t.string),
  createdDate: Maybe(t.string),
  userComplete: Maybe(t.string)
})

export const ReportCommunicationResponse = t.type({
  body: t.type({
    data: t.array(CommunicationTrackingData),
    size: t.number,
    start: t.number,
    total: t.number,
  })
})

export type CommunicationTrackingDataType = t.TypeOf<typeof CommunicationTrackingData>
export namespace CommunicationTrackingReportService {

  export interface PayloadReport {
    fromDate?: string
    toDate?: string
    transactionType?: string
    policyNumber?: string
    status?: string
    userComplete?: string
  }

  export const viewReportCommunicationTracking= ({
      fromDate,
      toDate,
      transactionType,
      policyNumber,
      status,
      userComplete
    }: PayloadReport, size: number, start: number ) => {
      return pipe(
        POApi.post('wf-api/policy/paper-letter/get-data')(ReportCommunicationResponse)({
          order: "ASC",
          sort: "createdDate",
          transactionType,
          policyNumber,
          status,
          fromDate,
          toDate,
          userComplete,
          size,
          start
        }),
        ZIO.map((res) => {
          const { data = [], size = 10, start = 0, total = 0 } = res.body ?? {}

          return {
            data: data,
            start,
            size,
            total
          }
        })
      )
    }
}
