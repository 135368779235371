import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { IDCSharedStyles } from '../IDCSharedStyles'
import { OBCustomerAnswerConst, OBFieldDescription, OBFieldTitle, OBSectionCol, OBSectionContent, OBSectionRow, OBSharedStyles, OBTitle } from '@pulseops/outbound'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Container, ErrorHandling, IDCDetailService, Input, PulseOpsFormat } from '@pulseops/common'
import * as O from 'fp-ts/lib/Option'
import { IDCDetailContext } from '../IDCDetailContext'
import { IDCContainer } from '../independence-components'

export const IDCCustomerAnswer = () => {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { caseID } = React.useContext(IDCDetailContext)

  const customerAnswersList = pipe(
    IDCDetailService.getCustomerAnswersList(caseID),
    ZIO.map((responseData) => {
      return responseData
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([caseID])
  )

  const optionItem = (idcType: string) => {
    const IDCTypeName = pipe(
      OBCustomerAnswerConst.IDCTypeConst.find((p) => p.code === idcType),
      O.fromNullable,
      O.fold(
        () => '-',
        (typeItem) => i18n.language === 'en' ? typeItem.nameEn : typeItem.nameVi
      )
    )
    return IDCTypeName
  }

  return (
    <Container loading={isLoading}>
      <View style={IDCSharedStyles.container}>
        {customerAnswersList && customerAnswersList.length > 0 && customerAnswersList.map((answerItem, index) => {
          return (
            <IDCContainer containerStyle={index > 0 && customerStyle.secondLine} containerTitle={(isContainerOpen) => (
              <>
                <View style={customerStyle.answerHeader}>
                  <View>
                    <OBFieldTitle text={t('Outbound:OBCustomerAnwser:IDCType')}></OBFieldTitle>
                    <OBFieldDescription text={optionItem(answerItem.idcType || '')}></OBFieldDescription>
                  </View>
                  <View>
                    <OBFieldTitle text={t('Outbound:OBCustomerAnwser:SendSMSTime')}></OBFieldTitle>
                    <OBFieldDescription text={answerItem.smsSenderDate ? PulseOpsFormat.dateStringtoFormat(answerItem?.smsSenderDate, 'DD/MM/YYYY - HH:mm:ss') : '-'}></OBFieldDescription>
                  </View>
                  <View>
                    <OBFieldTitle text={t('Outbound:OBCustomerAnwser:FeedbackTime')}></OBFieldTitle>
                    <OBFieldDescription text={answerItem.completeTimeQuestion ? PulseOpsFormat.dateStringtoFormat(answerItem.completeTimeQuestion, 'DD/MM/YYYY - HH:mm:ss') : '-'}></OBFieldDescription>
                  </View>
                  <View>
                    <OBFieldTitle text={t('Outbound:OBCustomerAnwser:TimesOfSendingSMSToCustomers')}></OBFieldTitle>
                    <OBFieldDescription text={answerItem.timeRetry ? answerItem.timeRetry.toString() : '-'}></OBFieldDescription>
                  </View>
                </View>
                {isContainerOpen && (
                  <View style={{ display: 'flex' }}>
                    <OBFieldTitle text={t('IDCDetail:ProcessInstanceID')}></OBFieldTitle>
                    <OBFieldDescription text={answerItem.processInstanceId || ''}></OBFieldDescription>
                  </View>
                )}
              </>
            )} isOpenContainer={false}>
              {answerItem && answerItem.answerResults && answerItem.answerResults.length > 0 && (
                <OBSectionContent style={customerStyle.answerBody}>
                  <OBTitle text={t('Outbound:OBCustomerAnwser:CustomerAnwser')}></OBTitle>
                  {answerItem.answerResults.map((customerItem, index) => {
                    const questionItem = `${index + 1}. ${customerItem.questionContent}`
                    const answerItem = customerItem.answerUser === 'Y' ? 'Yes' : 'No'
                    return (
                      <OBSectionRow style={customerStyle.secondLine}>
                        <OBSectionCol style={OBSharedStyles.sectionCol12}>
                          <OBFieldTitle text={questionItem}></OBFieldTitle>
                          <OBFieldDescription text={answerItem}></OBFieldDescription>
                          {customerItem.decisionResult === 'FAIL' && (
                            <View style={customerStyle.inputContainer}>
                              <Input title={t('Outbound:OBCustomerAnwser:Opinion')} value={customerItem.commentUser} multiline={true} disabled />
                            </View>
                          )}
                        </OBSectionCol>
                      </OBSectionRow>
                    )
                  })}

                </OBSectionContent>
              )}

            </IDCContainer>
          )
        })}
      </View>
    </Container>

  )
}
const customerStyle = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  panelContainer: {
    paddingLeft: 24,
    marginBottom: 24
  },
  answerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
  },
  answerBody: {
    // marginTop: 24,
  },
  secondLine: {
    marginTop: 20,
  },
  inputContainer: {
    backgroundColor: '#F9F9F9',
    padding: 20,
    marginTop: 24
  }
})