import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.AGENT_ONBOARD_NEW),
        t.literal(TransactionType.AGENT_ONBOARD_REINS)
      ])
    }),
    codec
  ])

const DsServices = Base(
  t.type({
    transactionType: Maybe(t.string),
    businessKey: Maybe(t.string),
    id: Maybe(t.string),
    taskId: Maybe(t.string),
    galleries: Maybe(t.string),
    payload: t.type({
      access_token: Maybe(t.string),
      action: Maybe(t.string),
      additionalProperties: Maybe(t.type({})),
      agentCode: Maybe(t.string),
      authenOption: Maybe(t.string),
      authenRequestForm: Maybe(t.string),
      body: t.type({
        officeCode: Maybe(t.string),
        terDate: Maybe(t.string),
        oldUnit: Maybe(t.string),
        newUnit: Maybe(t.string),
        eREF: Maybe(t.string),
        subChanel: Maybe(t.string),
        salesType: Maybe(t.string),
        /// SubmissionDate
        ref: Maybe(t.string),
        agCAT: Maybe(t.string),
        agent: Maybe(
          t.type({
            agCategory: Maybe(t.string),
            agentCode: Maybe(t.string),
            agentName: Maybe(t.string),
            appointedDate: Maybe(t.string),
            terminateDate: Maybe(t.string),
            agentType: Maybe(t.string),
            title: Maybe(t.string),
            unitDescription: Maybe(t.string),
            branchDescription: Maybe(t.string),
            officeDescription: Maybe(t.string),
            subzoneDescription: Maybe(t.string),
            zoneDescription: Maybe(t.string),
            debt: Maybe(t.string),
            agentCodeRefer: Maybe(t.string),
            agentNameRefer: Maybe(t.string),
            companyPhone: Maybe(t.string),
            educationCode: Maybe(t.string),
            email: Maybe(t.string),
            ethnic: Maybe(t.string),
            genderCode: Maybe(t.string),
            hasAListOfPotentialCustomers: Maybe(t.string),
            homePhone: Maybe(t.string),
            income: Maybe(t.string),
            locationCode: Maybe(t.string),
            marriedCode: Maybe(t.string),
            numberOfChildren: Maybe(t.string),
            occupationCode: Maybe(t.string),
            ocr: Maybe(t.string),
            phoneNumber: Maybe(t.string),
            regionCode: Maybe(t.string),
            regionDescription: Maybe(t.string),
            saleZone: Maybe(t.string),
            specialization: Maybe(t.string),
            zalo: Maybe(t.string)
          })
        ),
        assessment: Maybe(
          t.type({
            assessmentDate: Maybe(t.string),
            assessByDesc: Maybe(t.string),
            reAssess: Maybe(t.string),
            reAssessDate: Maybe(t.string),
            role: Maybe(t.string),
            status: Maybe(t.boolean)
          })
        ),
        group: Maybe(
          t.type({
            leaderCode: Maybe(t.string),
            leaderCodeDescription: Maybe(t.string),
            leaderName: Maybe(t.string),
            newLeaderCode: Maybe(t.string),
            newLeaderName: Maybe(t.string),
            oldLeaderCode: Maybe(t.string),
            oldLeaderName: Maybe(t.string)
          })
        ),
        candidate: Maybe(
          t.type({
            idNo: Maybe(t.string),
            oldIdNo: Maybe(t.string),
            idDate: Maybe(t.string),
            idPlace: Maybe(t.string),
            idExpiredDate: Maybe(t.string),
            dob: Maybe(t.string),
            placeOfBirth: Maybe(t.string),
            nationality: Maybe(t.string),
            lastName: Maybe(t.string),
            firstName: Maybe(t.string)
          })
        ),
        residential: Maybe(
          t.type({
            address: Maybe(t.string),
            district: Maybe(t.string),
            province: Maybe(t.string),
            ward: Maybe(t.string)
          })
        ),
        allocate: Maybe(
          t.type({
            locationCode: Maybe(t.string),
            address: Maybe(t.string),
            district: Maybe(t.string),
            province: Maybe(t.string),
            ward: Maybe(t.string)
          })
        ),
        contact: Maybe(
          t.type({
            locationCode: Maybe(t.string),
            address: Maybe(t.string),
            district: Maybe(t.string),
            province: Maybe(t.string),
            ward: Maybe(t.string)
          })
        ),
        bank: Maybe(
          t.type({
            bankName: Maybe(t.string),
            bankBranch: Maybe(t.string),
            bankAccount: Maybe(t.string),
            bankCode: Maybe(t.string),
            otherBank: Maybe(t.string),
            otherBankBranch: Maybe(t.string),
            taxCode: Maybe(t.string)
          })
        )
      }),
      clientId: Maybe(t.string),
      correlationId: Maybe(t.string),
      createdBy: Maybe(t.string),
      createdDate: Maybe(t.string),
      documents: Maybe(t.string),
      errorMsg: Maybe(t.string),
      exchangeId: Maybe(t.string),
      functionUI: Maybe(t.string),
      idNumber: Maybe(t.string),
      isCCE: Maybe(t.string),
      lasStatus: Maybe(t.string),
      location: Maybe(t.string),
      msgId: Maybe(t.string),
      officeCode: Maybe(t.string),
      otpTransaction: Maybe(
        t.type({
          udit: Maybe(
            t.type({
              createTime: Maybe(t.string),
              updateTime: Maybe(t.string),
              createdBy: Maybe(t.string),
              updatedBy: Maybe(t.string)
            })
          ),
          id: Maybe(t.string),
          otp: Maybe(t.string),
          transactionCode: Maybe(t.string),
          transactionName: Maybe(t.string),
          message: Maybe(t.string),
          receiverPhone: Maybe(t.string),
          wrongOtpInputCount: Maybe(t.union([t.string, t.number])),
          status: Maybe(t.string),
          payload: Maybe(t.string),
          createdTimeLong: Maybe(t.union([t.string, t.number])),
          updatedTimeLong: Maybe(t.union([t.string, t.number])),
          duration: Maybe(t.union([t.string, t.number]))
        })
      ),
      primaryPolicyNo: Maybe(t.string),
      refresh_token: Maybe(t.string),
      source: t.string,
      systemName: Maybe(t.string),
      timestamp: Maybe(t.string),
      transType: Maybe(t.string)
    }),
    suspendReason: Maybe(t.string),
    unsuspendReason: Maybe(t.string)
  })
)

export const AgentOnboardNew = DsServices
export type AgentOnboardNew = t.TypeOf<typeof AgentOnboardNew>
