import * as t from 'io-ts'
import { form2 } from '@pulseops/common'
import { date } from 'io-ts-types'

const SelectOption = t.type({
  value: t.string,
  label: t.string
})
export const FileUpload = t.type({
  fileName: t.string,
  fileExtension: t.string,
  size: t.number,
  base64: t.string,
  uploadedDate: date,
  file: form2.file.required
})

export type FileUpload = t.TypeOf<typeof FileUpload>

const ClickToSendEmailForm = t.type({
  policyNumber: SelectOption,
  policyOwner: t.string,
  group: SelectOption,
  type: SelectOption,
  note: t.string,
  sendTo: t.string,
  BCC: t.string,
  subject: t.string,
  content: t.string,
  attachmentFiles: t.array(FileUpload),
  contentOriginal: t.string
})

export type ClickToSendEmailForm = t.TypeOf<typeof ClickToSendEmailForm>
