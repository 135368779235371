import {
  AppContext,
  assets,
  PanelGroupProps,
  PanelItem,
  PanelProp,
  SearchPanel,
  TransactionType
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { AmlRequestStackParamList } from './AmlRequestStackParamList'
import { PolicyInfoHeader } from '../ps-submission/transactionScreen/screens'

type Props = StackScreenProps<AmlRequestStackParamList, 'AmlRequestListScreen'>

export const AmlRequestListScreen = (props: Props) => {
  const { t } = useTranslation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const isFocused = useIsFocused()

  const navigationParams = {
    policyNum: props.route.params.policyNum,
    policyOwner: props.route.params.policyOwner,
    officeCode: props.route.params.officeCode,
    officeName: props.route.params.officeName
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.navigate('HomeScreen')
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('menu:AmlRequest'),
          navigate: () => null
        }
      ])
    }
  }, [isFocused])

  const itemsGroup: PanelGroupProps[] = [
    {
      id: 'AmlRequest',
      name: t('TaskManagement:AmlRequest'),
      icon: () => <assets.ClaimService />,
      children: [
        {
          id: 'AmlRedFlagAccept',
          name: t('submission:AmlRedFlagAccept'),
          navigator: () =>
            props.navigation.reset({
              routes: [
                {
                  name: 'AmlRequestSubmissionCommonScreen',
                  params: {
                    ...navigationParams,
                    transactionType: TransactionType.AML_RED_FLAG_ACCEPT
                  }
                }
              ]
            })
          // props.navigation.navigate('AmlRequestSubmissionCommonScreen', {
          //   ...navigationParams,
          //   transactionType: TransactionType.AML_RED_FLAG_ACCEPT
          // })
        },
        {
          id: 'AmlRedFlagDecline',
          name: t('submission:AmlRedFlagDecline'),
          navigator: () =>
            props.navigation.reset({
              routes: [
                {
                  name: 'AmlRequestSubmissionCommonScreen',
                  params: {
                    ...navigationParams,
                    transactionType: TransactionType.AML_RED_FLAG_DECLINE
                  }
                }
              ]
            })
          // props.navigation.navigate('AmlRequestSubmissionCommonScreen', {
          //   ...navigationParams,
          //   transactionType: TransactionType.AML_RED_FLAG_DECLINE
          // })
        }
      ]
    }
  ]

  const data: PanelProp | null = {
    itemsGroup: itemsGroup,
    itemsSearch: ([] as PanelItem[]).concat(...itemsGroup.map((group) => group.children))
  }

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: navigationParams?.policyNum ?? ''
    },
    {
      label: t('common:PolicyOwner'),
      value: navigationParams.policyOwner
    },
    {
      label: t('common:Office'),
      value: `${props.route.params?.officeCode ?? ''} - ${props.route.params?.officeName ?? ''}`
    }
  ]
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <PolicyInfoHeader menus={headers} />
        {data && <SearchPanel data={data} />}
      </ScrollView>
    </SafeAreaView>
  )
}
