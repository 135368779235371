import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { withMessage } from 'io-ts-types'
import i18next from 'i18next'

export namespace PayPremiumForm {
  const PayPremiumForm = t.type({
    formId: form2.string.required,
    amount: withMessage(form2.string.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:AmountToBePaid') })
    ),
    installmentPremium: form2.string.required
  })

  export const codec = t.type({
    list: t.array(PayPremiumForm)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
