import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Customer } from '../Client'
import { MoreData } from '../MoreData'

export interface ZaloRecommendation {
  tag: TransactionType.ZALO_RECOMMENDATION
  clientName: string

  note: string
}

export const ZaloRecommendation = (
  detail: api.ZaloRecommendation,
  client: Customer,
  sql: MoreData.PoNameFromSql
): ZaloRecommendation => {
  return {
    tag: TransactionType.ZALO_RECOMMENDATION,
    clientName: sql.body?.poName || client.body.name,

    note: detail.payload.body.message || ''
  }
}
