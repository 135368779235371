import * as React from 'react'
import * as core from '@material-ui/core'
import { assets } from '../assets'
import { ControlProps, SelectOption } from '@pulseops/common'
import clsx from 'clsx'

type Props = ControlProps<string | null> & {
  label?: string
  options?: SelectOption[]
  disabled?: boolean
  required?: boolean
}

export const BorderSelect = (props: Props) => {
  const sc = useStyles(props)

  return (
    <core.FormControl error={!!props.errorMessage} className={sc.fc}>
      <core.Select
        input={<core.InputBase />}
        value={props.value}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value as string)
        }}
        onBlur={props.onBlur}
        className={sc.root}
        IconComponent={({ className, classes, ...rest }) => (
          <assets.IconArrowDown {...rest} className={clsx(className, sc.select)} />
        )}
        disabled={props.disabled}
        displayEmpty
      >
        <core.MenuItem value="" style={{ display: 'none' }}>
          Chọn
        </core.MenuItem>
        {(props.options || []).map((o) => (
          <core.MenuItem key={o.value} value={o.value}>
            {o.label}
          </core.MenuItem>
        ))}
      </core.Select>
      {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
    </core.FormControl>
  )
}

const useStyles = core.makeStyles<core.Theme, Props>(() => ({
  root: (props) => ({
    borderRadius: 8,
    height: 40,
    border: '1px solid #D3DCE6',
    padding: '5px 10px',
    background: 'white',
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: 13
    },
    '&:hover': !props.disabled
      ? {
          borderColor: 'rgba(0, 0, 0, 0.87)'
        }
      : undefined
  }),
  select: {
    position: 'absolute',
    right: 10,
    top: 'auto'
  },
  fc: {
    width: '100%',
    minWidth: '100px'
  }
}))
