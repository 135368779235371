import { Types } from './setup'

export const initVoiceRecordingState: () => Types.VoiceRecordContextState = () => ({
  audioTrack: [],
  trackIndex: 0,
  currentTrack: null,
  timeProgress: 0,
  duration: 0,
  configTrainscript: null,
  voiceDetail: null,
  isPlaying: false,
  dynamicKeyword: null,
  applicationInfo: null,
  dataGird: null,
  transcripts: null,
  loadingSrc: false
})

export const VoiceRecordingReducer = (
  state: Types.VoiceRecordContextState,
  action: Types.ActionReducer
): Types.VoiceRecordContextState => {
  switch (action.type) {
    case 'SET_TRACK_INDEX_STATE':
      state.trackIndex = action.payload

      return {
        ...state
      }
    case 'SET_CURRENT_TRACK_STATE':
      state.currentTrack = action.payload

      return {
        ...state
      }
    case 'SET_DURATION_STATE':
      state.duration = action.payload

      return {
        ...state
      }
    case 'SET_CONFIG_TRAINSCRIPT_STATE':
      state.configTrainscript = action.payload

      return {
        ...state
      }
    case 'SET_VOICE_DETAIL_STATE':
      state.voiceDetail = action.payload

      return {
        ...state
      }
    case 'SET_PLAYING_VOICE_STATE':
      state.isPlaying = action.payload

      return {
        ...state
      }
    case 'SET_DYNAMIC_KEYWORD_STATE':
      state.dynamicKeyword = action.payload

      return {
        ...state
      }
    case 'SET_APPLICATION_INFO_STATE':
      state.applicationInfo = action.payload

      return {
        ...state
      }
    case 'SET_DATA_GIRD_STATE':
      state.dataGird = action.payload

      return {
        ...state
      }
    case 'SET_DATA_GIRD_STATE':
      state.dataGird = action.payload

      return {
        ...state
      }
    case 'SET_TRANSCRIPT_STATE':
      state.transcripts = action.payload

      return {
        ...state
      }
    case 'SET_LOADING_SRC_STATE':
      state.loadingSrc = action.payload

      return {
        ...state
      }
    default:
      return state
  }
}
