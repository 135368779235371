import { ZIO } from '@mxt/zio'
import {
  ErrorHandling,
  GeneralService,
  PulseOpsFormat,
  RadioButton,
  TaskDetail,
  Panel,
  Checkbox,
  getLanguage,
  Label
} from '@pulseops/common'
import { Title } from '@pulseops/task'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View } from 'react-native'
import { InfoViewList } from '../common'
import { DuplicateInfoTable, DuplicateInfoItem } from './common'

type Field = {
  label: string
  value: string | number | JSX.Element
  suffix?: string
  isHighlight?: boolean
}

interface Dup {
  clientId: string
  clientName: string
  relationship: string
}
export const Inheritance = ({ detail }: { detail: TaskDetail.Inheritance }) => {
  const { t } = useTranslation(['requestInfo', 'common', 'message', 'contact', 'claim'])

  const smsIndicators = [
    { label: t('submission:SMSIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:SMSIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:SMSIndicatorOptions.RefuseSMS'), value: '4' }
  ]

  const emailIndicators = [
    { label: t('submission:EmailIndicatorOptions.PolInfo'), value: '1' },
    { label: t('submission:EmailIndicatorOptions.Pol&PruInfo'), value: '3' },
    { label: t('submission:EmailIndicatorOptions.RefuseEmail'), value: '4' }
  ]

  const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []

  const { emailDup, phoneDup, nationalityOptions, occupations } = pipe(
    ZIO.zipPar(GeneralService.getCountries, GeneralService.getOccupations, GeneralService.getIARelationship()),
    ZIO.map(([countries, occupations, iaRela]) => {
      const nationalityOptions = countries
      const phoneDup: Dup[] = detail?.attributesExt?.PHONES_DUP || []
      const emailDup: Dup[] = detail?.attributesExt?.EMAILS_DUP || []
      return {
        iaRela,
        nationalityOptions,
        occupations,
        phoneDup,
        emailDup
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    iaRela: [],
    nationalityOptions: [],
    occupations: [],
    emailDup: [] as Dup[],
    phoneDup: [] as Dup[]
  }

  const getRelationshipFromT16s1 = (code: string) => {
    const found = relationshipT16s1.find((item) => item.code === code)
    return {
      relationshipEN: found?.relationshipEN || '',
      relationshipVI: found?.relationshipVN || ''
    }
  }

  const appliedTo = (): { appliedPolicy: string }[] => {
    return detail?.detail.payload.body.appliedPolicys || []
  }

  const getOtherRelationship = (clientName: string, relationship: string, defaultValue: string = '') => {
    return {
      label: t('contact:RelationshipWithLAShort') + ' ' + clientName,
      value: pipe(
        getRelationshipFromT16s1(relationship),
        ({ relationshipEN, relationshipVI }) =>
          (getLanguage() === 'en' ? relationshipEN : relationshipVI) || defaultValue
      )
    }
  }

  const dataDetail = detail?.detail.payload.body

  const requestFields: Field[] = [
    {
      label: t('requestInfo:NewPolicyOwner'),
      value: dataDetail.clientNo ? detail?.selectedClient : `${t('requestInfo:Other')}`
    },
    { label: t('requestInfo:IDNumber'), value: dataDetail?.idNumber ?? '-' },
    {
      label: t('requestInfo:RelationshipWithMainifeAssured'),
      value: pipe(getRelationshipFromT16s1(dataDetail?.relationship1 ?? ''), ({ relationshipEN, relationshipVI }) =>
        getLanguage() === 'en' ? relationshipEN : relationshipVI
      )
    },
    { label: t('requestInfo:Surname'), value: dataDetail?.surname ?? '-' },
    { label: t('requestInfo:GivenName'), value: dataDetail?.givenName ?? '-' },
    {
      label: t('contact:usTax'),
      value: (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <RadioButton
            disable={true}
            label={t('common:Yes')}
            selected={dataDetail?.isFatca ? dataDetail.isFatca : false}
          />
          <RadioButton
            disable={true}
            label={t('common:No')}
            selected={!(dataDetail?.isFatca ? dataDetail.isFatca : false)}
          />
        </View>
      )
    },
    { label: t('requestInfo:DOB'), value: PulseOpsFormat.dateStrWF(dataDetail?.birthDate, 'DD/MM/YYYY') ?? '-' },
    {
      label: t('requestInfo:Gender'),
      value: dataDetail?.sex === 'M' ? `${t('common:Male')}` : `${t('common:Female')}`
    },
    {
      label: t('requestInfo:Nationality'),
      value: nationalityOptions.find((n) => n.code === dataDetail?.nationality)?.name ?? '-'
    },
    {
      label: t('contact:MobilePhone'),
      value: dataDetail?.mobilePhone ? `+${dataDetail?.telCode} ${dataDetail?.mobilePhone}` : '-'
    },
    { label: t('requestInfo:Email'), value: dataDetail?.email ?? '-' },
    {
      label: t('contact:Occupation'),
      value: occupations.find((n) => n.code === dataDetail?.occupation)?.name ?? dataDetail?.occupation ?? '-'
    },
    {
      label: t('requestInfo:SMSIndicator'),
      value: smsIndicators.find((n) => n.value === dataDetail.smsIndicator)?.label ?? '_'
    },
    {
      label: t('requestInfo:EmailIndicator'),
      value: emailIndicators.find((n) => n.value === dataDetail.emailIndicator)?.label ?? '_'
    }
  ]

  const foreignFields: Field[] = [
    {
      label: t('requestInfo:Country'),
      value: nationalityOptions.find((n) => n.code === dataDetail?.country)?.name ?? '-' ?? '-'
    },
    { label: t('requestInfo:City'), value: dataDetail?.city ?? '-' },
    { label: t('requestInfo:District'), value: dataDetail?.district ?? '-' },
    { label: t('requestInfo:Ward'), value: dataDetail?.ward ?? '-' },
    { label: t('requestInfo:Street'), value: dataDetail?.street ?? '-' },
    { label: '', value: '' },
    { label: t('contact:ForeignAddress'), value: dataDetail?.foreignAdd ?? '-' },

    { label: t('submission:CountryOfForeignAddress'), value: nationalityOptions.find((n) => n.code === dataDetail?.foreignCountry)?.name ?? '-' },
    { label: '', value: '' },
    { label: t('submission:TaxResidencyCountry'), value: nationalityOptions.find((n) => n.code === dataDetail?.attributesExt?.taxResidencyCountry)?.name ?? '-' },
    {
      label: t('claim:Nationality2'),
      value: nationalityOptions.find((n) => n.code === dataDetail?.nationality2)?.name ?? '-'
    },
    { label: '', value: '' }
  ]

  if (dataDetail && dataDetail.clientNo2 && dataDetail.relationship2) {
    foreignFields.push(getOtherRelationship(dataDetail.clientNo2, dataDetail.relationship2, '-'))
  }

  if (dataDetail && dataDetail.clientNo3 && dataDetail.relationship3) {
    foreignFields.push(getOtherRelationship(dataDetail.clientNo3, dataDetail.relationship3, '-'))
  }

  if (dataDetail && dataDetail.clientNo4 && dataDetail.relationship4) {
    foreignFields.push(getOtherRelationship(dataDetail.clientNo4, dataDetail.relationship4, '-'))
  }

  const infoFields: Field[] = [
    { label: t('requestInfo:PODeathDate'), value: PulseOpsFormat.dateStrWF(dataDetail?.deathDate, 'DD/MM/YYYY') }
  ]

  const phoneDups: DuplicateInfoItem[] = phoneDup.map((item) => ({
    clientNumber: item.clientId,
    clientName: item.clientName,
    relationship: pipe(getRelationshipFromT16s1(item.relationship), ({ relationshipEN, relationshipVI }) => ({
      en: relationshipEN,
      vi: relationshipVI
    }))
  }))

  const emailDups: DuplicateInfoItem[] = emailDup.map((item) => ({
    clientNumber: item.clientId,
    clientName: item.clientName,
    relationship: pipe(getRelationshipFromT16s1(item.relationship), ({ relationshipEN, relationshipVI }) => ({
      en: relationshipEN,
      vi: relationshipVI
    }))
  }))

  return (
    <View>
      <ScrollView>
        <Title title={t('requestInfo:InheritanceRequest')} />
        <Panel isExand={false}>
          <InfoViewList dataSource={requestFields} />
          {phoneDups.length > 0 && (
            <View style={{ marginBottom: 20 }}>
              <Label title={t('requestInfo:dupPhone')} />
              <View style={{ marginTop: 6 }}>
                <DuplicateInfoTable data={phoneDups} />
              </View>
            </View>
          )}
          {emailDups.length > 0 && (
            <View style={{ marginBottom: 20 }}>
              <Label title={t('requestInfo:dupEmail')} />
              <View style={{ marginTop: 6 }}>
                <DuplicateInfoTable data={emailDups} />
              </View>
            </View>
          )}
          <InfoViewList dataSource={foreignFields} />

          {appliedTo().length > 0 && (
            <View>
              <Label title={t('requestInfo:UpdatedForOtherPolicy')} />
              {detail?.policyList.map((item, index) => (
                <View key={index}>
                  <Checkbox title={`${item.policyNum} ${item.name}`} value={true} />
                </View>
              ))}
            </View>
          )}
        </Panel>

        <Title title={t('requestInfo:InheritanceInformation')} />
        <Panel isExand={false}>
          <InfoViewList dataSource={infoFields} />
        </Panel>
      </ScrollView>
    </View>
  )
}
