import {
  formatNumberWithComma,
  Panel,
  PulseOpsFormat,
  TaskDetail,
  PayoutPopup,
  PayoutChecker,
  getPayeeNotPOData,
  TaskDetailApi
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { InfoViewList, Title } from '../common'
import { PayoutMethod } from './common'

type Output = {
  label: string
  value: string | number | JSX.Element
  suffix?: string
  isHighlight?: boolean
}

export const SpecialBonus = ({ detail }: { detail: TaskDetail.CashOutSpecialBonus }) => {
  const { t } = useTranslation(['requestInfo', 'TaskManagement', 'Payout'])

  let data: Output[] = []

  data = [
    {
      label: t('TaskManagement:SpecialBonus'),
      value: detail.specialBonus ? formatNumberWithComma(detail.specialBonus) : '0',
      suffix: 'VND'
    }
  ]

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method
  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail.totalPayoutAmount),
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    payouts: detail.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  // const columnsSource = [
  //   {
  //     key: '1',
  //     title: t('claim:PaymentMethodSelect'),
  //     name: 'payoutMethod'
  //   },
  //   {
  //     key: '2',
  //     title: t('requestInfo:Detail'),
  //     name: 'detail'
  //   },
  //   {
  //     key: '3',
  //     title: t('requestInfo:Amount'),
  //     name: 'amount'
  //   },
  //   {
  //     key: '4',
  //     title: t('Status'),
  //     name: 'status'
  //   },
  //   {
  //     key: '4',
  //     title: t('Note'),
  //     name: 'note'
  //   }
  // ]
  return (
    <>
      <Title title={t('requestInfo:SpecialBonusInfo')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FFF' }}>
        <InfoViewList dataSource={data} />
      </Panel>

      <Title title={t('Payout:PAYMENTINFORMATION')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FFF' }}>
        <View style={styles.row}>
          <Text style={styles.value}>{t('requestInfo:TotalPayoutAmount')} : </Text>
          <Text style={{ fontSize: 15, fontWeight: 'bold', lineHeight: 20, marginBottom: 8, color: '#ed1b2e' }}>
            {payoutData.totalPayoutAmount} VND
          </Text>
        </View>
        <View style={[styles.row, { justifyContent: 'space-between' }]}>
          <Text style={styles.value}>{t('Payout:PayoutDetails')}</Text>
          <Text style={{ fontSize: 15, lineHeight: 20, marginBottom: 8, color: '#000000', fontStyle: 'italic' }}>
            {t('Payout:Currency')}: VND
          </Text>

          {/* <TableField
            styleHeader={styles.headerTable}
            styleBody={styles.bodyTable}
            currency={''}
            columns={columnsSource}
            dataSource={payoutData.payouts}
          /> */}
        </View>
        <PayoutMethod
          payoutMethodData={payoutData}
          payeeData={viewPayeeData}
          panelContainerStyle={{ backgroundColor: '#FAFAFA' }}
        />
      </Panel>
    </>
  )
}
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  headerTable: {
    backgroundColor: '#e2e7ea',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  bodyTable: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  }
})
