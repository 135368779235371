import React from 'react'
import { View } from 'react-native'

interface DividerProps {
  height?: string | number
  width?: string | number
  isExpanded?: boolean
}

export const Divider: React.FC<DividerProps> = ({ height = 16, width = 16, isExpanded = false }) => {
  return <View style={{ height: height, width: width, flex: isExpanded ? 1 : undefined }} />
}
