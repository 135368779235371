import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { ModalComponent, useMobile } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

type InternalHistoryModal = {
  title: string
  content: string
  onClose: () => void
  onSuccess?: () => void
  open: boolean
}

export const InternalHistoryContentModal = (props: InternalHistoryModal) => {
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const actionButtons = [
    {
      text: t('QC:Cancel'),
      type: 'outline' as 'outline',
      disabled: false,
      loading: false,
      onlyWide: false,
      style: { height: 39, marginEnd: 15 },
      action: props.onClose
    }
  ]
  return (
    <ModalComponent
      title={props.title}
      modalWidth={700}
      onClose={props.onClose}
      visible={props.open}
      actions={actionButtons}
    >
      <ScrollView style={[suspendHistoryStyles.containerModal, { marginTop: isWide ? 20 : 16 }]}>
        <TextField
          value={props.content}
          fullWidth
          multiline
          InputProps={{
            readOnly: true,
            disableUnderline: true
          }}
        />
      </ScrollView>
    </ModalComponent>
  )
}

const suspendHistoryStyles = StyleSheet.create({
  containerModal: {
    marginHorizontal: 20
    // maxHeight: 500
  }
})
