import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TaskDetail } from '@pulseops/common'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DataSource, InfoViewList, Title } from '../common'

interface Props {
  detail: TaskDetail.ChangeDOBGender
}

interface mappingDataI {
  isBorder: boolean
  title: string
  dataSource: DataSource[]
}

const ChangeDOBGenderScreen = ({ detail }: Props) => {
  const { t } = useTranslation()

  const {
    clientName,
    data: { dob, gender, isCheckChangeDOBGroup, isCheckChangeGenderGroup }
  } = detail

  const mappingData: mappingDataI[] = [
    {
      isBorder: true,
      title: '',
      dataSource: [
        { label: t('submission:category'), value: t('Reports:PolicyService') },
        { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
        {
          label: t('submission:transactionType'),
          value: t(`TransactionType:${detail.tag}`)
        }
      ]
    },
    {
      isBorder: false,
      title: '',
      dataSource: [{ label: t('CHANGE_DOB_GENDER:GROUPS_VIEWS.FIELDS.selectClient'), value: clientName }]
    },

    ...(isCheckChangeDOBGroup
      ? [
          {
            isBorder: true,
            title: t('CHANGE_DOB_GENDER:GROUPS_VIEWS.LABEL_GROUP.change_dob_group'),
            dataSource: [
              {
                label: t('CHANGE_DOB_GENDER:GROUPS_VIEWS.FIELDS.dob'),
                value: dob ?? '-',
                isRequired: true
              }
            ]
          }
        ]
      : []),

    ...(isCheckChangeGenderGroup
      ? [
          {
            isBorder: true,
            title: t('CHANGE_DOB_GENDER:GROUPS_VIEWS.LABEL_GROUP.change_gender_group'),
            dataSource: [
              {
                label: t('CHANGE_DOB_GENDER:GROUPS_VIEWS.FIELDS.gender'),
                value: gender ? `${gender === 'M' ? t('common:Male') : t('common:Female')}` : '-',
                isRequired: true
              }
            ]
          }
        ]
      : [])
  ]

  const renderViews = () => {
    if (isEmpty(detail)) return

    return mappingData.map((group, index) => {
      const { isBorder, title, dataSource } = group
      return (
        <View key={index} style={{ paddingVertical: 12 }}>
          {!!title ? <Title title={title} /> : null}

          <View style={[isBorder && styles.borderGroup]}>
            {!isEmpty(dataSource) ? <InfoViewList dataSource={dataSource} /> : null}
          </View>
        </View>
      )
    })
  }

  return <View>{renderViews()}</View>
}

export default ChangeDOBGenderScreen

const styles = StyleSheet.create({
  borderGroup: {
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingVertical: 30,
    borderStyle: 'solid',
    borderColor: '#d3dce6',
    borderWidth: 1
  }
})
