export const excelColumnsSheet1: excelType[] = [
  {
    name: 'STT',
    value: 'stt',
    width: 10
  },
  {
    name: 'HỌ VÀ TÊN NGƯỜI ĐƯỢC BẢO HIỂM',
    value: 'employeeName'
  },
  {
    name: 'MÃ SỐ NHÂN VIÊN',
    value: 'employeeNo'
  },
  {
    name: 'SỐ CMND/CCCD/HỘ CHIẾU',
    value: 'employeeId'
  },
  {
    name: 'NGÀY CẤP',
    value: 'dateOfIssue'
  },
  {
    name: 'NƠI CẤP',
    value: 'placeOfIssue'
  },
  {
    name: 'SỐ ĐIỆN THOẠI',
    value: 'employeePhoneNumber'
  },
  {
    name: 'EMAIL',
    value: 'email'
  },
  {
    name: 'GIỚI TÍNH',
    value: 'gender'
  },
  {
    name: 'NGÀY SINH',
    value: 'dateOfBirth'
  },
  {
    name: 'TÌNH TRẠNG HÔN NHÂN',
    value: 'maritalStatus'
  },
  {
    name: 'QUỐC TỊCH',
    value: 'nationality'
  },
  {
    name: 'QUỐC TỊCH KHÁC',
    value: 'otherNationality'
  },
  {
    name: 'THỊ THỰC NHẬP CẢNH',
    value: 'visa'
  },
  {
    name: 'ĐỊA CHỈ THƯỜNG TRÚ',
    value: 'permanentAddress'
  },
  {
    name: 'QUỐC GIA ĐỊA CHỈ THƯỜNG TRÚ',
    value: 'permanentAddressCountry'
  },
  {
    name: 'ĐỊA CHỈ NƠI Ở HIỆN TẠI',
    value: 'residentialAddress'
  },
  {
    name: 'QUỐC GIA ĐỊA CHỈ NƠI Ở HIỆN TẠI',
    value: 'residentialAddressCountry'
  },
  {
    name: 'ĐỊA CHỈ NƯỚC NGOÀI',
    value: 'overseasAddress'
  },
  {
    name: 'QUỐC GIA ĐỊA CHỈ NƯỚC NGOÀI',
    value: 'overseasAddressCountry'
  },
  {
    name: 'NGHỀ NGHIỆP',
    value: 'occupation'
  },
  {
    name: 'MÃ NGHỀ NGHIỆP',
    value: 'occupationCode'
  },
  {
    name: 'NHÓM QUYỀN LỢI BẢO HIỂM',
    value: 'benefitClass'
  },
  {
    name: 'SỐ GCNBH',
    value: 'certificateNum'
  },
  {
    name: 'NGÀY HIỆU LỰC HĐ CỦA NĐBH',
    value: 'effectiveDate'
  },
  {
    name: 'TÊN DOANH NGHIỆP',
    value: 'policyHolder'
  },
  {
    name: 'TÌNH TRẠNG NĐBH',
    value: 'status'
  },
  {
    name: 'CẬP NHẬT YÊU CẦU ĐIỀU CHỈNH',
    value: ''
  },
  {
    name: 'NGÀY CẬP NHẬT',
    value: ''
  },
  {
    name: 'NGÀY HIỆU LỰC YÊU CẦU ĐIỀU CHỈNH',
    value: ''
  },
  {
    name: 'SỐ HĐ BH TRUYỀN THỐNG MUA VỚI ISSUE DATE >= NGÀY MUA BH NHÓM',
    value: 'numberContractIndividualAfter'
  },
  {
    name: 'SỐ HĐ BH TRUYỀN THỐNG MUA VỚI ISSUE DATE < NGÀY MUA BH NHÓM',
    value: 'numberContractIndividualBefore'
  },
  {
    name: 'TỔNG APE MUA SP TRUYỀN THỐNG',
    value: 'totalOfApe'
  }
]

export const excelColumnsSheet2: excelType[] = [
  {
    name: 'STT',
    value: 'stt',
    width: 10
  },
  {
    name: 'Tên Doanh nghiệp',
    value: 'policyHolder.policyHolder'
  },
  {
    name: 'Mã số thuế',
    value: 'policyHolder.taxCode'
  },
  {
    name: 'Ngày thành lập',
    value: 'policyHolder.dateOfRegistration'
  },
  {
    name: 'Địa chỉ',
    value: 'policyHolder.businessAddress.address'
  },
  {
    name: 'Tên người liên hệ',
    value: 'policyAdmin.fullName'
  },
  {
    name: 'Số điện thoại người liên hệ',
    value: 'policyAdmin.phoneNumber'
  },
  {
    name: 'Số lượng nhân viên',
    value: 'totalEmployees'
  },
  {
    name: 'Số Hợp đồng Master contract  ',
    value: 'masterContractNo'
  },
  {
    name: 'Số lượng nhân viên được BH',
    value: 'totalEmployeesInsured'
  },
  {
    name: 'APE',
    value: 'annualizedPremiumEquivalent'
  },
  {
    name: 'IP',
    value: 'initialPremium'
  },
  {
    name: 'Năm đóng phí của HĐ',
    value: 'yearOfReceivedDate'
  },
  {
    name: 'Ngày đóng phí',
    value: 'dayOfReceivedDate'
  },
  {
    name: 'Ngày cập nhật',
    value: 'updateDate'
  },
  {
    name: 'Ngày ghi nhận cập nhật',
    value: 'requestUpdateDate'
  },
  {
    name: 'Số nhân viên thêm trong tháng',
    value: 'addEmployee'
  },
  {
    name: 'Số nhân viên bớt trong tháng',
    value: 'removalEmployee'
  },
  {
    name: 'Ngày hiệu lực của Master contract',
    value: 'groupPolicyEffectiveDate'
  },
  {
    name: 'Total Sum Assured',
    value: 'sumBasicSa'
  },
  {
    name: 'Case count',
    value: 'caseCount'
  },
  {
    name: 'Định kỳ đóng phí',
    value: 'modeOfPayment'
  },
  {
    name: 'Mã số đại lý',
    value: 'agentNumber1'
  },
  {
    name: 'Tên Đại lý',
    value: 'agentName1'
  },
  {
    name: 'Bankcode',
    value: 'bankCode  '
  },
  {
    name: 'Sold unit của HĐ',
    value: 'soldUnit'
  },
  {
    name: 'Sale unit của đại lý bán',
    value: 'saleUnit  '
  },
  {
    name: 'Unit name của Sold Unit',
    value: 'soldUnitName'
  },
  {
    name: 'Mã vùng Sold unit',
    value: 'soldUnitCode'
  },
  {
    name: 'Theo structure PD',
    value: 'regnen'
  },
  {
    name: 'Theo structure PD',
    value: 'sazoneen'
  },
  {
    name: 'Theo structure PD',
    value: 'zoneen'
  },
  {
    name: 'Theo structure PD',
    value: 'suzoneen'
  },

  {
    name: 'Tên BSM',
    value: 'bsmnme'
  },
  {
    name: 'Tên RSM',
    value: 'rsmnme'
  },
  {
    name: 'Theo structure PD',
    value: 'provcity'
  },

  {
    name: 'Mã số Đại lý nhận chuyển giao HĐ',
    value: 'contractTransferAgentNumber'
  },
  {
    name: 'Mã nhân viên',
    value: 'staffCode'
  },

  {
    name: 'Mã tư vấn viên (Mã refer)',
    value: 'referredAgentCode'
  },
  {
    name: 'Tên',
    value: 'referredBy'
  },

  {
    name: 'Chi nhánh',
    value: 'refferedSoldUnit'
  },
  {
    name: 'Bank',
    value: 'refferedBankCode'
  },

  {
    name: 'Transaction code (status of HĐBHN)',
    value: 'transctionCode'
  },

  {
    name: 'Phí đóng thực tế của HĐ chưa tính HĐ share',
    value: 'paidPremiumWithoutSplit'
  },
  {
    name: 'Thời hạn đóng phí của HĐ',
    value: 'policyTerm'
  },
  {
    name: 'Tháng phát sinh transaction',
    value: 'monthOfReceivedDate'
  },

  {
    name: 'Năm phát sinh transaction',
    value: 'yearOfReceivedDate'
  },
  {
    name: 'Phí đóng thực tế của HĐ đã chia tỷ lệ HĐ share',
    value: 'paidPremiumWithSplit'
  },
  {
    name: 'Transaction date (ngày đóng phí)',
    value: 'dayOfReceivedDate'
  },
  {
    name: 'Tỷ lệ HĐ share',
    value: 'splitCommission'
  },
  {
    name: 'G/L Code ( Ex: 51311I-10-HCR1 )',
    value: ''
  },
  {
    name: 'OFFICE',
    value: 'office'
  },
  {
    name: 'NGÀY NỘP HĐ',
    value: 'proposalDate'
  },
  {
    name: 'AGCAT',
    value: 'agcat'
  },
  {
    name: 'APP.DATE',
    value: 'appdate'
  },
  {
    name: 'TER.DATE',
    value: 'termdate'
  },
  {
    name: 'Ngày cập nhật',
    value: 'updateDate'
  },
  {
    name: 'Ngày hiệu lực yêu cầu điều chỉnh',
    value: 'requestUpdateDate'
  }
]

export type excelType = {
  name: string
  value: string
  width?: number
}
