import { Maybe } from '@mxt/zio/codec'
import { FileUploadData, StorageBlob } from '@pulseops/common'

import * as t from 'io-ts'
export namespace UploadDocumentData {
  export interface DetailData {
    DepartmentList: Department[]
  }

  export interface Department {
    departmentCode: string
    departmentEN: string
    departmentVN: string
    departmentShortName: string
  }

  export interface DepartmentFormInfo {
    department: string
    documentType: string
    officeCode: string
    attachmentFiles: FileUploadData[]
  }

  export const DepartmentT31 = t.type({
    id: t.string,
    departmentCode: t.string,
    departmentEN: t.string,
    departmentShortName: t.string,
    departmentVN: t.string
  })

  export interface ResponseFileInfo {
    blobFiles: StorageBlob.FileContentSubmit[]
    category: string
    documentType: string
    metaType: string
  }

  // export const SUrequestDocuments = t.type({
  //   name: t.string,
  //   url: t.string
  // })

  export const SURequestDetails = t.type({
    policyNo: t.string,
    poClientId: t.string,
    poIdNumber: t.string,
    proposalNo: t.string,
    poName: t.string,
    agentCode: t.string,
    agentName: t.string,
    department: t.string,
    documentType: t.string,
    requestDocuments: t.array(t.string),
    type2: t.string
  })

  export const SubmitData = t.type({
    type: t.string,
    requestDetails: t.array(SURequestDetails)
  })

  export const DepartmentT17 = t.type({
    department: t.string,
    departmentShortName: t.string
  })

  export const DocumentType = t.type({
    doctypeVn: t.string,
    doctypeEn: t.string,
    docTypeCode: t.string
  })

  export const ImagingDocumentType = t.type({
    department: Maybe(t.string),
    departmentShortName: t.string,
    transactionType: Maybe(t.string),
    docTypeVN: Maybe(t.string),
    docTypeEN: Maybe(t.string),
    docTypeCode: Maybe(t.string),
    docID: Maybe(t.string),
    mainDoc: Maybe(t.string),
    subDoc: Maybe(t.string),
    uploadFile: t.string
    // "classFilenet": "POLICYINFOTMP",
    // "trigger": "7",
    // "type": "UWR",
    // "batchNo": "08/11/2022 BANCA_SEA",
    // "refNum": null,
    // "optionalDocument": "-",
    // "addLackOfDocument": null,
    // "eform": "N"
  })

  export type DepartmentT31 = t.TypeOf<typeof DepartmentT31>
  export type DepartmentT17 = t.TypeOf<typeof DepartmentT17>
  export type DocumentType = t.TypeOf<typeof DocumentType>
  export type SubmitData = t.TypeOf<typeof SubmitData>
  export type ImagingDocumentType = t.TypeOf<typeof ImagingDocumentType>
}
