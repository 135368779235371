import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

import {
  AppContext,
  Columns,
  Container,
  ContractMapping,
  ErrorHandling,
  Panel,
  TableField,
  useTranslator,
  formatNumberWithComma,
  CancelRiderService,
  PremiumStatusMapping
} from '@pulseops/common'
import { CepTableField, CepPanelToggle } from '../cep-components'
import { StyleSheet } from 'react-native'
import { useIsFocused, useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { CepPolicyDetailServices } from '../CEPPolicyDetailServices'
import { useTranslation } from 'react-i18next'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import _, { isEmpty } from 'lodash'
import { getProductInfo, PI_detail, ProductInfo } from '@pulseops/inquiry'
export interface ProductExtInfo {
  owner: ProductInfo | null
  laClient: ProductInfo[]
}
export interface ProductInfoData {
  productInfo: ProductExtInfo | null
  mainLAD: TableField.DataSources
}

export const CepProductScreen = (props: {
  id: string
  data: ProductInfoData | null
  setData: (val: ProductInfoData | null) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const { t: tPolicy } = useTranslator('TaskManagement')
  const ct = useTranslator('contract').t
  const commont = useTranslator('common').t
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()
  const { i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const pst = useTranslator('premiumStatus').t

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('Tab:ProductInfo'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getData = (): ZIO<unknown, Throwable, ProductInfoData | null> =>
    pipe(
      ZIO.zip(getProductInfo(props.id), CancelRiderService.getRiderInfoList()),
      ZIO.flatMap(([productInfo, rider]) =>
        ZIO.effect(() => {
          let MLAS: ProductInfo = {
            name: '',
            life: '',
            extInfos: [],
            code: ''
          }

          const laClient: ProductInfo[] = []
          const getContractName = (basicCode: string) => {
            const contract = rider.find((r) => r.code === basicCode)
            return contract ? (i18n.language === 'en' ? contract.name : contract.nameVi) : basicCode
          }
          if (productInfo?.data?.lifeAssureds) {
            productInfo?.data?.lifeAssureds.map((x) => {
              if (x.life === '01') {
                MLAS = {
                  name:
                    (x.lifeAssuredName?.surName ?? '') +
                    ' ' +
                    (x.lifeAssuredName?.middleName ?? '') +
                    ' ' +
                    (x.lifeAssuredName?.firstName ?? ''),
                  life: x.life,
                  code: x.lifeAssuredCode ?? '',
                  extInfos: x.products
                    ? x.products.map((it) => ({
                        ...it,
                        componentName: it.productCode ? getContractName(it.productCode) : '-',
                        coverageStatus: it.coverageStatus ? it.coverageStatus : '-',
                        premiumStatus: it.premiumStatus ? it.premiumStatus : '-',
                        rerateDate: it.rerateDate ? it.rerateDate : '-',
                        sumAssured: it.sumAssured ? formatNumberWithComma(it.sumAssured) : '-',
                        installmentPremium: it.installmentPremium ? formatNumberWithComma(it.installmentPremium) : '-',
                        paidToDate: it.paidToDate ? it.paidToDate : '-',
                        premCessDate: it.premCessDate ? it.premCessDate : '-',
                        covRiskCessDate: it.covRiskCessDate ? it.covRiskCessDate : '-',
                        riskCessDate: it.riskCessDate ? it.riskCessDate : '-',
                        productCode: it.productCode ? it.productCode : '-',
                        rider: it.rider ? it.rider : '',
                        coverage: it.coverage ? it.coverage : '',
                        priorityCVG: it.coverage && it.coverage === '01' ? 0 : 1,
                        priorityRider: it.rider === '00' ? 0 : 1,
                        benefitOption: it.benefitOption ? it?.benefitOption : '-',
                        planCode: it.planCode ? it?.planCode : '-',
                        mortalityClass: it.mortalityClass ? it.mortalityClass : '-'
                      }))
                    : []
                }
              } else {
                const LAS = {
                  name:
                    (x.lifeAssuredName?.surName ?? '') +
                    ' ' +
                    (x.lifeAssuredName?.middleName ?? '') +
                    ' ' +
                    (x.lifeAssuredName?.firstName ?? ''),
                  life: x.life,
                  code: x.lifeAssuredCode ?? '',
                  extInfos: x.products
                    ? x.products.map((it) => ({
                        ...it,
                        componentName: it.productCode ? getContractName(it.productCode) : '-',
                        coverageStatus: it.coverageStatus ? it.coverageStatus : '-',
                        premiumStatus: it.premiumStatus ? it.premiumStatus : '-',
                        rerateDate: it.rerateDate ? it.rerateDate : '-',
                        sumAssured: it.sumAssured ? formatNumberWithComma(it.sumAssured) : '-',
                        installmentPremium: it.installmentPremium ? formatNumberWithComma(it.installmentPremium) : '-',
                        paidToDate: it.paidToDate ? it.paidToDate : '-',
                        premCessDate: it.premCessDate ? it.premCessDate : '-',
                        covRiskCessDate: it.covRiskCessDate ? it.covRiskCessDate : '-',
                        riskCessDate: it.riskCessDate ? it.riskCessDate : '-',
                        productCode: it.productCode ? it.productCode : '-',
                        rider: it.rider ? it.rider : '',
                        coverage: it.coverage ? it.coverage : '',
                        priorityCVG: it.coverage && it.coverage === '01' ? 0 : 1,
                        priorityRider: it.rider === '00' ? 0 : 1,
                        benefitOption: it.benefitOption ? it?.benefitOption : '-',
                        planCode: it.planCode ? it?.planCode : '-',
                        mortalityClass: it.mortalityClass ? it.mortalityClass : '-'
                      }))
                    : []
                }

                laClient.push(LAS)
              }
            })
          }
          const productExtInfo = {
            owner: MLAS,
            laClient: laClient
          }

          return {
            productInfo: {
              ...productExtInfo,
              laClient: productExtInfo.laClient.map((x) => ({
                ...x,
                extInfos: x.extInfos ? mappingData(x.extInfos) : []
              }))
            },
            mainLAD: productExtInfo.owner
              ? productExtInfo && productExtInfo.owner.extInfos
                ? mappingData(productExtInfo.owner?.extInfos)
                : []
              : []
          }
        })
      )
    )

  const { productInfo, mainLAD } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.map((x) => {
      if (x !== null && props.data === null) props.setData(x)
      const data = _.sortBy(x?.mainLAD, ['coverage', 'rider'])
      return {
        productInfo: x?.productInfo,
        mainLAD: data
      }
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    productInfo: null,
    mainLAD: []
  }

  const columns = [
    {
      key: '1',
      title: t('ComponentName'),
      name: 'componentName',
      flex: 3,
      styles: styles.columTextLeft
    },
    {
      key: '2',
      title: commont('Status'),
      name: 'coverageStatus',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '3',
      title: cep('PremiumStatus'),
      name: 'premiumStatus',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '4',
      title: `${t('SumAssured')}`,
      name: 'sumAssured',
      styles: styles.columTextRight,
      flex: 2
    },
    {
      key: '5',
      title: `${t('InstalmentPremium')}`,
      name: 'installmentPremium',
      styles: styles.columTextRight,
      flex: 2
    },
    {
      key: '6',
      title: `${t('PaidToDate')}`,
      name: 'paidToDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '7',
      title: `${cep('CoverageRCD')}`,
      name: 'covRiskCessDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '8',
      title: `${cep('PremCessDate')}`,
      name: 'premCessDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '9',
      title: `${cep('RiskCessDate')}`,
      name: 'riskCessDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '10',
      title: `${cep('RerateFromDate')}`,
      name: 'rerateDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '11',
      title: `${cep('BenifitOption')}`,
      name: 'benefitOption',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '12',
      title: `${t('contact:MortalityClass')}`,
      name: 'mortalityClass',
      flex: 2,
      styles: styles.columTextLeft
    }
  ]

  const lifeAssuredColumns: Columns[] = [
    {
      key: '1',
      title: t('ComponentName'),
      name: 'componentName',
      flex: 3,
      styles: styles.columTextLeft
    },
    {
      key: '2',
      title: commont('Status'),
      name: 'coverageStatus',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '3',
      title: cep('PremiumStatus'),
      name: 'premiumStatus',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '4',
      title: `${t('SumAssured')}`,
      name: 'sumAssured',
      styles: styles.columTextRight,
      flex: 2
    },
    {
      key: '5',
      title: `${t('InstalmentPremium')}`,
      name: 'installmentPremium',
      styles: styles.columTextRight,
      flex: 2
    },
    {
      key: '6',
      title: `${cep('PaidToDate')}`,
      name: 'paidToDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '7',
      title: `${cep('CoverageRCD')}`,
      name: 'covRiskCessDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '8',
      title: `${cep('PremCessDate')}`,
      name: 'premCessDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '9',
      title: `${cep('RiskCessDate')}`,
      name: 'riskCessDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '10',
      title: `${cep('RerateFromDate')}`,
      name: 'rerateDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '11',
      title: `${cep('PlanCode')}`,
      name: 'planCode',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '12',
      title: `${t('contact:MortalityClass')}`,
      name: 'mortalityClass',
      flex: 2,
      styles: styles.columTextLeft
    }
  ]

  function getLaTableFields(la: ProductInfo) {
    const list =
      la && la.extInfos
        ? la.extInfos.map((e, i) => ({
            ...e,
            componentName: e.componentName,
            coverageStatus: e.coverageStatus,
            premiumStatus: e.premiumStatus,
            rerateDate: e.rerateDate,
            sumAssured: e.sumAssured,
            installmentPremium: e.installmentPremium,
            paidToDate: e.paidToDate,
            premCessDate: e.premCessDate,
            covRiskCessDate: e.covRiskCessDate,
            riskCessDate: e.riskCessDate,
            rider: e.rider,
            benefitOption: e.benefitOption ?? '-',
            planCode: e.planCode ?? '-',
            mortalityClass: e.mortalityClass ?? '-'
          }))
        : []

    return _.sortBy(list, ['coverage', 'rider'])
  }
  // console.log('productInfo', productInfo);
  const mappingData = (data: PI_detail[]) => {
    return data?.map((item) => ({
      ...item,
      componentName: item.productCode + '-' + item.componentName,
      coverageStatus: ContractMapping.get(ct)(item.coverageStatus) ?? '-',
      premiumStatus: PremiumStatusMapping.get(pst)(item.premiumStatus) ?? '-',
      rerateDate: item.rerateDate,
      sumAssured: item.sumAssured,
      installmentPremium: item.installmentPremium,
      paidToDate: item.paidToDate,
      premCessDate: item.premCessDate,
      covRiskCessDate: item.covRiskCessDate,
      riskCessDate: item.riskCessDate,
      rider: item.rider,
      benefitOption: item.benefitOption ?? '-',
      planCode: item.planCode ?? '-',
      mortalityClass: item.mortalityClass ?? '-'
    }))
  }

  return (
    <Container loading={loading} display={!!productInfo}>
      <CepPanelToggle title={tPolicy('MainLifeAssured')}>
        <Panel title={productInfo?.owner?.code + '-' + productInfo?.owner?.name ?? ''} isExand={false}>
          <CepTableField columns={columns} dataSource={mainLAD} />
        </Panel>
      </CepPanelToggle>
      {productInfo && productInfo?.laClient.length > 0 && (
        <CepPanelToggle title={cep('LifeAssuredProduct')}>
          {productInfo?.laClient.map((item, i) => (
            <Panel
              title={item.code + (!isEmpty(item?.name) && !!item.name ? '-' + item?.name : '')}
              key={`ProductScreenMAIN-${i}`}
              isExand={false}
            >
              <CepTableField columns={lifeAssuredColumns} dataSource={getLaTableFields(item)} />
            </Panel>
          ))}
          {/* {productInfo?.laClient.slice(1).map((item, i) => (
            <Panel title={item?.name || '-'} key={`ProductScreenSUB-${i}`}>
              <CepTableField columns={lifeAssuredColumns} dataSource={getLaTableFields(item)} />
            </Panel>
          ))} */}
        </CepPanelToggle>
      )}
    </Container>
  )
}

const styles = StyleSheet.create({
  columTextRight: {
    textAlign: 'right',
    paddingHorizontal: 15
  },
  columTextLeft: {
    textAlign: 'left',
    paddingHorizontal: 15
  }
})
