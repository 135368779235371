import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '../POApi'
import { BillingChange, Frequency, FrequencyC, RequestAuthFormDataSubmit } from './model'
import { Task } from '@mxt/zio'
import { SubmissionService } from './SubmissionService'

export interface DataAccessCheck {
  freequency: string | null
  contractStatus: string | null
  productCode: string
  productType: string
  requestDate: Date
  paidToDateAdvance: Date | null
  paidToDateBasic: Date | null
  rcd: Date | null
}

export namespace BillingChangeService {
  export const getBill = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/billing-change`)(
        t.type({
          body: t.type({
            billToDate: Maybe(t.string),
            curBillingFrequency: Maybe(FrequencyC),
            curRenewalPremium: Maybe(t.string),
            premiumAdv: Maybe(t.string),
            suspendAmount: Maybe(t.string)
          })
        })
      ),
      ZIO.map((bill) => {
        return bill
      })
    )

  export const getDetail = (policyNum: string): Task<BillingChange.Detail> =>
    pipe(
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNum}/billing-change`)(
          t.type({
            body: t.type({
              billToDate: Maybe(t.string),
              paidToDate: Maybe(t.string),
              curBillingFrequency: Maybe(FrequencyC),
              curRenewalPremium: Maybe(t.string),
              premiumAdv: Maybe(t.string),
              suspendAmount: Maybe(t.string)
            })
          })
        ),
        POApi.get(`wf-api/policy/${policyNum}`)(
          t.type(
            {
              body: t.type({
                policyNo: t.string,
                billToDate: Maybe(t.string),
                clientDespatchAddress: Maybe(t.string),
                contractDate: Maybe(t.string),
                premiumStatus: Maybe(t.string),
                productCode: Maybe(t.string),
                totalPremium: Maybe(t.number),
                owners: t.type({
                  id: t.string
                })
              })
            },
            'Policy'
          )
        )
      ),
      ZIO.map(([detail, policy]): BillingChange.Detail => {
        const newInstallmentPremium = 0
        const contractDay = policy.body.contractDate?.split('-')[2] ?? ''
        const contractMonth = policy.body.contractDate?.split('-')[1] ?? ''
        const effectiveDateNewFrequency = `${contractDay}/${contractMonth}/${new Date().getFullYear()}`

        return {
          curBillingFrequency: detail.body.curBillingFrequency ? detail.body.curBillingFrequency : '',
          curInstallmentPremium: detail.body.curRenewalPremium ?? '0',
          newBillingFrequency: null,
          newInstallmentPremium: newInstallmentPremium.toString(), //ContractDate --> policy/id --> BE chờ Las (a Hoàng)
          suspenseAmount: detail.body.suspendAmount ?? '0',
          advancePremium: detail.body.premiumAdv ?? '0',
          effectiveDateNewFrequency: effectiveDateNewFrequency,
          requirePayinAmount: (
            newInstallmentPremium -
            (parseInt(detail?.body?.suspendAmount ?? '0') + parseInt(detail.body.premiumAdv ?? '0'))
          ).toString(),
          billToDate: detail.body.billToDate ?? '',
          paidToDate: detail.body.paidToDate ?? '',
          validNewInstallmentPremium: true,
          validProduct: true,
          curRenewalPremium: detail.body.curRenewalPremium ?? ''
        }
      })
    )

  export const getDetailWithNextInstall = (policyNum: string, newBillingFrequency: string) =>
    pipe(
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNum}/billing-change`)(
          t.type({
            body: t.type({
              billToDate: Maybe(t.string),
              paidToDate: Maybe(t.string),
              curBillingFrequency: Maybe(FrequencyC),
              curRenewalPremium: Maybe(t.string),
              premiumAdv: Maybe(t.string),
              suspendAmount: Maybe(t.string)
            })
          })
        ),
        POApi.get(`wf-api/policy/${policyNum}`)(
          t.type(
            {
              body: t.type({
                policyNo: t.string,
                billToDate: Maybe(t.string),
                clientDespatchAddress: Maybe(t.string),
                contractDate: Maybe(t.string),
                premiumStatus: Maybe(t.string),
                productCode: Maybe(t.string),
                totalPremium: Maybe(t.number),
                owners: t.type({
                  id: t.string
                })
              })
            },
            'Policy'
          )
        ),
        getNextInstallmentPremium(policyNum, newBillingFrequency),
        POApi.post(`wf-api/bo/policy-extra-info`)(
          t.type({
            body: t.type({
              policyExtraInfoDetails: t.array(
                t.type({
                  productCode: t.string,
                  riderStatus: t.string,
                  sumAssured: t.number
                })
              )
            })
          })
        )({
          body: {
            policies: [policyNum]
          }
        })
      ),
      ZIO.map(([detail, policy, nextInstallment, checker]): BillingChange.Detail => {
        const nextInstallmentPremium = Number(nextInstallment.nextInstallmentPremium)
        const contractDay = policy.body.contractDate?.split('-')[2] ?? ''
        const contractMonth = policy.body.contractDate?.split('-')[1] ?? ''
        const effectiveDateNewFrequency = `${contractDay}/${contractMonth}/${new Date().getFullYear()}`
        return {
          curBillingFrequency: detail.body.curBillingFrequency ? detail.body.curBillingFrequency : '',
          curInstallmentPremium: detail.body.curRenewalPremium ?? '0',
          newBillingFrequency: null,
          newInstallmentPremium: nextInstallmentPremium.toString(), //ContractDate --> policy/id --> BE chờ Las (a Hoàng)
          suspenseAmount: detail.body.suspendAmount ?? '0',
          advancePremium: detail.body.premiumAdv ?? '0',
          effectiveDateNewFrequency: effectiveDateNewFrequency,
          requirePayinAmount: (
            nextInstallmentPremium -
            (parseInt(detail?.body?.suspendAmount ?? '0') + parseInt(detail.body.premiumAdv ?? '0'))
          ).toString(),
          billToDate: detail.body.billToDate ?? '',
          paidToDate: detail.body.paidToDate ?? '',
          validNewInstallmentPremium:
            Number(nextInstallment.nextInstallmentPremium) >= Number(nextInstallment.minimumContribution),
          curRenewalPremium: detail.body.curRenewalPremium ?? '',
          validProduct: !checker.body.policyExtraInfoDetails.some(
            (x) => ['UHS2', 'HSR4', 'VHS1'].includes(x.productCode) && x.riderStatus === 'IF' && x.sumAssured === 400000
          )
        }
      })
    )

  export const submit =
    (policyNum: string, newBillingFreq: Frequency, detail: BillingChange.Detail) =>
    (requestauth: RequestAuthFormDataSubmit): Task<void> =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/billing-change`,
        {
          body: {
            curRenewalPremium: detail.curRenewalPremium,
            suspenseAmount: detail.suspenseAmount,
            advancePremium: detail.advancePremium,
            billToDate: detail.billToDate,
            paidToDate: detail.paidToDate,
            newBillingFrequency: newBillingFreq,
            curBillingFrequency: detail.curBillingFrequency,
            curInstallmentPremium: detail.curInstallmentPremium,
            newInstallmentPremium: detail.newInstallmentPremium,
            effectiveDateNewFrequency: detail.effectiveDateNewFrequency,
            requirePayinAmount: detail.requirePayinAmount,
            ownerName: '',
            officeAddress: ''
          }
        },
        policyNum,
        requestauth,
        []
      )
  // pipe(
  //   // policy -> policyNo api moi
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/billing-change`)(t.unknown)({
  //     body: {
  //       curRenewalPremium: detail.curRenewalPremium,
  //       suspenseAmount: detail.suspenseAmount,
  //       advancePremium: detail.advancePremium,
  //       billToDate: detail.billToDate,
  //       paidToDate: detail.paidToDate,
  //       newBillingFrequency: newBillingFreq,
  //       curBillingFrequency: detail.curBillingFrequency,
  //       curInstallmentPremium: detail.curInstallmentPremium,
  //       newInstallmentPremium: detail.newInstallmentPremium,
  //       effectiveDateNewFrequency: detail.effectiveDateNewFrequency,
  //       requirePayinAmount: detail.requirePayinAmount,
  //       ownerName: "",
  //       officeAddress: ""
  //     },
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )

  export const getDataCheckAccess = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNum}/billing-change`)(
          t.type({
            body: t.type({
              billToDate: Maybe(t.string),
              curBillingFrequency: Maybe(t.union([FrequencyC, t.literal('00')])),
              curRenewalPremium: Maybe(t.string),
              premiumAdv: Maybe(t.string),
              suspendAmount: Maybe(t.string)
            })
          })
        ),
        SubmissionService.getPolicies([
          {
            policyNo: policyNum
          }
        ])
      ),
      ZIO.map(([detail, policies]): DataAccessCheck => {
        return {
          freequency: detail.body.curBillingFrequency ?? '00',
          contractStatus: policies[0].contractStatus,
          productCode: policies[0].productCode ?? null,
          requestDate: new Date(),
          paidToDateAdvance: policies[0].paidToDateAdvance ? new Date(policies[0].paidToDateAdvance) : null,
          paidToDateBasic: policies[0].paidToDateBasic ? new Date(policies[0].paidToDateBasic) : null,
          rcd: policies[0].contractDate ? new Date(policies[0].contractDate) : null,
          productType: policies[0].productType
        }
      })
    )

  export const getNextInstallmentPremium = (
    policyNum: string,
    newBillingFrequency: string
  ): Task<BillingChange.NextInstallmentPre> =>
    pipe(
      POApi.post(`wf-api/policy/billing-change/freq-change-quotation`)(
        t.type({
          body: Maybe(
            t.type({
              action: Maybe(t.string),
              minimumContribution: t.string,
              nextInstallmentPremium: t.string
            })
          )
        })
      )({
        body: {
          contractNumber: policyNum,
          billingFrequency: newBillingFrequency
        }
      }),
      ZIO.map(
        (detail): BillingChange.NextInstallmentPre => ({
          action: detail.body && detail.body.action ? detail.body.action : '',
          minimumContribution: detail.body && detail.body.minimumContribution ? detail.body.minimumContribution : '0',
          nextInstallmentPremium:
            detail.body && detail.body.nextInstallmentPremium ? detail.body.nextInstallmentPremium : '0'
        })
      )
    )
}
