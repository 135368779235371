import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Checkbox,
  ErrorHandling,
  PaymentType,
  Select,
  SelectOption,
  SubmissionService,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { ClaimService } from '../../../ClaimService'
import { DocumentOption, generalStyles as styles } from '../../../common'
import * as AddLackOfPayeeForm from '../../add-lack-of-payee-form'
import { BankTransfer } from './bank-transfer'
import { PaidAtBank } from './paid-at-bank'
import { PayAtTheCounter } from './pay-at-the-counter'
import moment from 'moment'
export const PaymentMethod = ({
  // onSubmit,
  form,
  policyNum,
  officeCode,
  setSelectedDocumentType,
  selectedDocumentType,
  defaultPayeeIsNotPO
}: {
  policyNum: string
  form: UseFormReturn<AddLackOfPayeeForm.Form>
  setSelectedDocumentType: (documents: DocumentOption[]) => void
  selectedDocumentType: DocumentOption[]
  officeCode: string
  defaultPayeeIsNotPO: {
    payeeNotPO: boolean
    bankAccountName: string
    bankIdNumber: string
    dob: Date | null
  }
}) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation(['claim', 'requestInfo', 'common'])

  const { watch, control } = form
  const { paymentMode, payeeIsNotPolicyOwner } = watch()

  const [loading, bindLoading] = useLoading()

  const banks = pipe(ClaimService.getBanks('2'), ErrorHandling.runDidMount())

  const paymentMethods = [
    {
      label: t('BankTransfer'),
      value: PaymentType.BANKTRANSFER
    }
    // {
    //   label: t('CashAtTheCounter'),
    //   value: PaymentType.CASH_AT_COUNTER
    // },
    // {
    //   label: t('PayAtBank'),
    //   value: PaymentType.PAID_AT_BANK
    // }
  ]

  const getPaymentComponent = () => {
    switch (paymentMode?.value) {
      case PaymentType.BANKTRANSFER:
        return (
          <BankTransfer
            form={form}
            banks={banks}
            policyNum={policyNum}
            setSelectedDocumentType={setSelectedDocumentType}
            selectedDocumentType={selectedDocumentType}
          />
        )
      case PaymentType.CASH_AT_COUNTER:
        return (
          <PayAtTheCounter
            formInfo={{
              form: form,
              policyNum: policyNum,
              setSelectedDocumentType: setSelectedDocumentType,
              selectedDocumentType: selectedDocumentType
            }}
            officeCode={officeCode}
          />
        )
      case PaymentType.PAID_AT_BANK:
        return (
          <PaidAtBank
            form={form}
            banks={banks}
            policyNum={policyNum}
            setSelectedDocumentType={setSelectedDocumentType}
            selectedDocumentType={selectedDocumentType}
          />
        )
      default:
        return <></>
    }
  }

  const ownerInfo = pipe(
    SubmissionService.getOwnerInfoDigital(policyNum),
    ZIO.tap((res) => {
      form.setValue('bankAccountName', res.fullname)
      form.setValue('bankIdNumber', res.nationalID)
      form.setValue('payeeName', res.fullname)
      form.setValue('payeeId', res.nationalID)
      form.setValue('dob', moment(res.dob).toDate() ?? moment(res.dob, 'DD/MM/YYYY').toDate())
      return ZIO.unit
    }),
    ZIO.map((res) => ({
      fullname: res.fullname,
      nationalID: res.nationalID,
      dob: res.dob
    })),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  const onCheckClaimant = (e: boolean) => {
    // form.setValue('payeeIsNotPolicyOwner', e)
    if (e === false && ownerInfo && paymentMode?.value) {
      form.clearErrors()
      form.setValue('dob', moment(ownerInfo.dob).toDate() ?? moment(ownerInfo.dob, 'DD/MM/YYYY').toDate())
      switch (paymentMode?.value) {
        case PaymentType.BANKTRANSFER:
          form.setValue('bankAccountName', ownerInfo.fullname || '-')
          form.setValue('bankIdNumber', ownerInfo.nationalID || '-')
          break
        case PaymentType.CASH_AT_COUNTER:
          form.setValue('payeeName', ownerInfo.fullname || '-')
          form.setValue('payeeId', ownerInfo.nationalID || '-')
          break
        case PaymentType.PAID_AT_BANK:
          form.setValue('payeeName', ownerInfo.fullname || '-')
          form.setValue('payeeId', ownerInfo.nationalID || '-')
          break
      }
    } else {
      if (defaultPayeeIsNotPO.payeeNotPO) {
        form.setValue('bankAccountName', defaultPayeeIsNotPO.bankAccountName)
        form.setValue('bankIdNumber', defaultPayeeIsNotPO.bankIdNumber)
        form.setValue('dob', defaultPayeeIsNotPO.dob)
      } else {
        form.setValue('dob', null)
        switch (paymentMode?.value) {
          case PaymentType.BANKTRANSFER:
            form.setValue('bankAccountName', '')
            form.setValue('bankIdNumber', '')
            break
          case PaymentType.CASH_AT_COUNTER:
            form.setValue('payeeName', '')
            form.setValue('payeeId', '')
            break
          case PaymentType.PAID_AT_BANK:
            form.setValue('payeeName', '')
            form.setValue('payeeId', '')
            break
        }
      }
    }
  }
  const onPaymentChange = (e: SelectOption | null) => {
    form.clearErrors()
    form.setValue('documentType', null)
    if (e?.value && ownerInfo) {
      form.setValue(
        'dob',
        payeeIsNotPolicyOwner ? null : moment(ownerInfo.dob).toDate() && moment(ownerInfo.dob, 'DD/MM/YYYY').toDate()
      )
      switch (e?.value) {
        case PaymentType.BANKTRANSFER:
          form.setValue('bankAccountName', payeeIsNotPolicyOwner ? '' : ownerInfo.fullname)
          form.setValue('bankIdNumber', payeeIsNotPolicyOwner ? '' : ownerInfo.nationalID)
          break
        case PaymentType.CASH_AT_COUNTER:
          form.setValue('payeeName', payeeIsNotPolicyOwner ? '' : ownerInfo.fullname)
          form.setValue('payeeId', payeeIsNotPolicyOwner ? '' : ownerInfo.nationalID)
          break
        case PaymentType.PAID_AT_BANK:
          form.setValue('payeeName', payeeIsNotPolicyOwner ? '' : ownerInfo.fullname)
          form.setValue('payeeId', payeeIsNotPolicyOwner ? '' : ownerInfo.nationalID)
          break
      }
    }
  }

  return (
    <View style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Text style={[styles.sectionTitle, { paddingBottom: 10 }]}>{t('PaymentMethod')}</Text>
      <View style={styles.sectionContainer}>
        <View style={{ flexDirection: 'row' }}>
          <View style={[isMobile ? styles.mobileField : styles.tabletField, { paddingBottom: 15 }]}>
            <Controller
              name="paymentMode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('PaymentMethod') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  required
                  label={t('PaymentMethod')}
                  onChange={(e) => {
                    onChange(e)
                    onPaymentChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  placeholder={t('common:Select')}
                  options={paymentMethods}
                />
              )}
            />
          </View>
        </View>
        <Controller
          name="payeeIsNotPolicyOwner"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              textStyle={{ fontWeight: 'bold' }}
              title={t('PayeeIsNotPolicyOwner')}
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e)
                onCheckClaimant(e)
              }}
              disabled={watch('claimType') === 'CT0200'}
            />
          )}
        />
        {loading && <LinearProgress color="secondary" />}
        {getPaymentComponent()}
      </View>
    </View>
  )
}
