import { FieldList, SelectOption, TableField, TransactionStatus } from '@pulseops/common'
import { CodeRedemptionC, CustomerNotesC, GiftRedemptionC, NbuwRequestInfos, RewardPointC, TierHistory, ReinsuranceRequestDetails } from '../cep-api-services'

export enum RolesMLA {
  OW = 'OW',
  LF = 'LF',
  BN = 'BN'
}

export enum StatusPO {
  Current = ' ',
  Old = 'U'
}

export enum FundType {
  TargetPremium = 'Target Premium',
  TopUp = 'Top-up'
}

export const codesPremium = ['T551']

export enum RolesCode {
  PO = 'PO',
  LA = 'LA',
  AG = 'AG'
}

export enum ETradProduct {
  TRAD = 'TRAD'
}

export enum EInvesProduct {
  ULP = 'ULP',
  ILP = 'ILP'
}

export const TransCode = ['T2A4', 'T2A6']

export const codesBillingChangeHistory = ['BR74', 'T522']

export enum LoanType {
  EO = 'EO',
  EI = 'EI',
  AO = 'AO',
  AI = 'AI',
  LO = 'LO',
  LI = 'LI'
}

export enum CepPremiumStatus {
  PP = 'PP',
  PU = 'PU',
  FP = 'FP'
}

export enum CepPolicyStatus {
  IF = 'IF'
}

export interface IReinSuranceInfo {
  isRole: string
  clientNumber: string
  clientName: string
  isDescription: string
  meDescription: string
  reinDescription: string
  lifeNo: string
  uwDecisionBasic: string
  letterSummaryContent: string
  uwDecision: string
}

export interface IReqDescription {
  type: string
  reqDes: string
}

export interface SearchFormData {
  policyNo: string | null
  poIDNumber: string | null
  poMobilePhone: string | null
  poClientNumber: string | null
}
export interface ISortDataType {
  key: string
  sort: string
}

export interface IProductDetail {
  data: {
    productType: string
    productCode: string
    basicCode: string
    status: string
    salaryIncome: number
    lastIssueDate: string
    paidToDateAdvance: string
    anniDate: string
    paidToDateBasic: string
    paidToDateRider: string
    billToDate: string
    billFreq: string
    mainLifeAssuredNumber: string
    clientDespatchAddress: string
    riskCommenceDate: string
    surrenderDate: string
    lapsedDate: string
    policyNo: string
    totalPremium: number
    contractDate: string
    firstIssueDate: string
    premiumStatus: string
  }
}

export interface IDuplicationPhone {
  phoneNumber: string
  clientNumber: string
  clientName: string
  agentCode: string
  agentName: string
}

export interface PropsPartial {
  policyNumber: string
}

export interface PolicyInfo {
  policyStatus: string
  premiumStatus: string
  sumAssured: number
  billingFrequency: string
  policyOwnerCode: string
  policyOwnerName: string
  mainLifeAssuredCode: string
  mainLifeAssuredName: string
  productCode: string
  productName: string
  installmentPremium: number
  riskCommencementDate: string
  aplDate: string
  policyIssueDate: string
  paidToDateBasic: string
  premiumCessationDate: string
  riskCessationDate: string
  customerReceiveDate: string
  customerReceiveMethod: string
  firstIssueDate: string
}

export interface Beneficiary {
  stt: string
  clientId: string
  percentage: string
  relationshipType: string
  clientName: string
}

export interface TransactionHistory {
  id: string
  name: string
  businessKey: string
  source: string
  category: string
  transactionType: string
  policyNumber: string
  clientNumber: string
  poName: string
  lastUpdateDate: string
  subTransactionType: string
  agingDate: number
  assignee: string
  assigneeName: string
  activity: string
  createdDate: string
  request: string
  status: TransactionStatus
  createdBy: string
  lastUpdateByVer: string
  lastUpdateByAdj: string
  poClientNumber: string
  poPhoneNumber: string
  serviceType: string
  group: string
  subServiceType: string
  comment: string
  closeInfo: string
  note: string
  action: string
}

export interface ParamsList {
  policyNum: string
  policyOwner: string
  officeCode: string | null
  officeName: string | null
  primaryId: string
  clientNumber: string
  isGAstaff: string
  isSubmission?: string
}
export interface PolicyInfoData {
  policyInfo: PolicyInfo | null
  beneficiaries: Beneficiary[]
}

export interface IBonusSurrender {
  bonusValue: number | null | undefined
  currentBalance: number | null | undefined
  interest: number | null | undefined
  bonusReserveRemainValue: number | null | undefined
}
export interface IProductInfo {
  productType: string | null | undefined
  productCode: string | null | undefined
  basicCode: string | null | undefined
  status: string | null | undefined
}
export type FormDataBillingChange = {
  newBillingChange: SelectOption | null
}

export interface IPremiumHistory {
  transactionId: string | undefined | null
  paymentType: string | undefined | null
  bankCode: string | undefined | null
  transDate: string | undefined | null
  amount: number | string | undefined | null
  rcpDate: string | undefined | null
  orNo: string | undefined | null
  agtCol: string | undefined | null
  user: string | undefined | null
  channel: string | undefined | null
  sacsCode: string | undefined | null
  sacsType: string | undefined | null
  gtAmount: number | string | undefined | null
  content: string | undefined | null
  transactionCode: string | undefined | null
  paymentDesc: string | undefined | null
  isFromACMV: boolean | undefined | null,
  glCode: string | undefined | null
}

export interface IPremiumDetail {
  transactionId: string | null | undefined,
  sacsCode: string | null | undefined
  sacsType: string | null | undefined
  gtAmount: string | number | null | undefined
  content: string | null | undefined
}

export interface PremiumInfoData {
  transactionId: string
  data: IPremiumHistory[]
}

export interface PI_detail {
  benefitOption: string
  componentName: string
  coverageStatus: string
  premiumStatus: string
  rerateDate: string
  sumAssured: string
  installmentPremium: string
  paidToDate: string
  premCessDate: string
  covRiskCessDate: string
  riskCessDate: string
  productCode: string
  rider: string
  coverage: string
  planCode: string
  mortalityClass: string
}

export interface ProductInfo {
  name?: string | null
  life: string
  extInfos: PI_detail[] | null | undefined
  code: string
}
export interface PruReWardInfo {
  customerNotes: TableField.DataSources
  customerTierHistories: TableField.DataSources
  giftRedemptions: TableField.DataSources
  rewardPoints: TableField.DataSources
}

export interface ICustomerInfo {
  ape: string | null | undefined
  point: number | null | undefined
  expiredPoint: number | null | undefined
  usedPoint: number | null | undefined
  expiredPointInNextMonth: number | null | undefined
  expiredPointIn03Month: number | null | undefined
  expiredPointIn06Month: number | null | undefined
  enrolledDate: string | null | undefined
  vip: string | null | undefined
}

export interface NbuwInfoData {
  contractInfo: FieldList.DataSources
  NbRequest: Array<NbuwRequestInfos>
  UwRequest: FieldList.DataSources
  MeIsReinsuranceRequest: FieldList.DataSources
  ConditionAccept: Array<{
    laNo: string,
    clientNo: string,
    clientName:string,
    decision: string,
    letterSummaryContent?: string
  }>
  ConditionAcceptContent: string
  reinsurancePOInfo: Array<IReinSuranceInfo>
  reinsuranceLAInfo: Array<IReinSuranceInfo>
}

export interface LoadDetailInfo {
  loanNo: string
  loanType: string
  effectiveDate: string
  transNo: string
  glNo: string
  transDate: string
  transCode: string
  accountingAmount: number
}

export interface FundList {
  fundId: string
  fundName: string
  percentage: string
}
export interface SelectOptionModel {
  code: string
  nameEn: string
  nameVn: string
}
export interface PruReWardInformation {
  rewardPoints: RewardPointC[]
  customerTierHistories: TierHistory[]
  codeRedeems: CodeRedemptionC[]
  giftRedemptions: GiftRedemptionC[]
  customerNotes: CustomerNotesC[]
}
