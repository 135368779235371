import { StyleSheet } from 'react-native'
export const IBStyles = StyleSheet.create({
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  actionContent: {
    minHeight: 30,
    maxHeight: 37,
    paddingHorizontal: 35,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#ED1B2E'
  },
  actionContentText: {
    color: '#ED1B2E'
  },
  actionHightLight: {
    backgroundColor: '#ED1B2E'
  },
  actionHightLightText: {
    color: '#fff'
  },
  actionDisable: {
    backgroundColor: '#B0B0B0',
    borderColor: '#B0B0B0'
  },
  actionNonBackgroundDisable: {
    borderColor: '#B0B0B0'
  },
  actionDisableText: {
    color: '#FFFFFF'
  },
  actionNonBackgroundDisableText: {
    color: '#B0B0B0'
  },
  sectionCol4: {
    width: '100%',
    maxWidth: '33.333333%'
  },
  sectionCol8: {
    width: '100%',
    maxWidth: '66.666667%'
  },
  sectionCol6: {
    width: '100%',
    maxWidth: '50%'
  },
  sectionCol12: {
    width: '100%',
    maxWidth: '100%'
  },
  sectionCol3: {
    width: '100%',
    maxWidth: '25%'
  },
  sectionCol2: {
    width: '100%',
    maxWidth: '16.666667%'
  },
  sectionCol24: {
    width: '100%',
    maxWidth: '20%'
  },
  containerPadding: {
    // paddingVertical: 16,
    paddingHorizontal: 24,
    borderColor: '#D3DCE6',
    backgroundColor: '#fff'
  },
  header: {
    fontSize: 15, 
    fontWeight: '700',
    textTransform: "uppercase"
  },
  container: {
    display: 'flex',
    padding: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',    
    marginBottom: 20,
    backgroundColor: '#ffffff'
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})