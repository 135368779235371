import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import moment from 'moment'
import { Task } from '@mxt/zio'
import { SubmissionService } from './SubmissionService'
import { POApi } from '../POApi'
import { BeneficiaryDesignationData, ReinstatementModel, RequestAuthFormDataSubmit } from './model'

const lAClient = t.type({
  clientNumberOfLA: t.string,
  life: t.string
})

type lAClient = t.TypeOf<typeof lAClient>

export namespace ReinstatementService {
  export const ReinData = t.type({
    attributesExt: t.type({
      AGENT_CODE: t.string,
      AGENT_NAME: t.string,
      APL: t.number,
      FREQUENCY: t.union([t.string, t.undefined]),
      INSTALLMENT_PREM: t.union([t.number, t.undefined]),
      LAPSED_DATE: t.string,
      OPL: t.number,
      REINS_FEE: t.union([t.number, t.undefined]),
      RISK_COMM_DATE: t.string,
      TOTAL_REINS_AMOUNT: t.union([t.number, t.undefined]),
      TRANS_CODE: t.string
    }),
    lifeAssured: t.array(
      t.type({
        attributesExt: t.type({
          CUR_OCCUPATION: t.string
        }),
        clientId: t.string,
        name: t.string
      })
    )
  })

  export type ReinData = t.TypeOf<typeof ReinData>

  export const getClientsByPolicyNum = (policyNum: string): Task<SubmissionService.ClientQuery[]> =>
    pipe(
      POApi.get(`wf-api/bo/${policyNum}/clients`)(
        t.type({
          body: Maybe(
            t.type({
              laList: t.array(lAClient)
            })
          )
        })
      ),
      ZIO.flatMap((client) =>
        client.body && client.body.laList.length
          ? pipe(
              SubmissionService.getClients(client.body?.laList.map((l) => ({ clientId: l.clientNumberOfLA }))),
              ZIO.map((c) => c.body)
            )
          : ZIO.succeed([])
      )
    )

  export const getReinData = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/reinstatement`)(
        t.type({
          body: ReinData
        })
      ),
      ZIO.map((c) => c.body)
    )

  export const getDetail = (policyNum: string): Task<ReinstatementModel.Detail> =>
    pipe(
      ZIO.zipPar(SubmissionService.getOwnerInfo(policyNum), getReinData(policyNum)),
      ZIO.map(([owner, reinData]): ReinstatementModel.Detail => {
        return {
          owner,
          reinData
        }
      })
    )

  export const submit =
    (
      policyNum: string,
      data: ReinstatementModel.ReinstatementSubmitData,
      documents: BeneficiaryDesignationData.BeneficiaryDocumentFile[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/reinstatement`,
        { body: data },
        policyNum,
        requestauth,
        documents
      )

  export const getDataAccess = (policyNum: string): Task<ReinstatementModel.AccessData> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getCustomer(String(policy.body.owners.id)),
            getClientsByPolicyNum(policyNum),
            getReinData(policyNum)
          ),
          ZIO.map(([owner, laClients, reinData]) => {
            const mainLa = laClients.find((f) => f.clientId === policy.body.mainLifeAssuredNumber)
            return {
              policy: policy.body,
              isPoDeath: !!owner && moment(owner.body.dod, 'YYYY/MM/DD').isBefore(new Date()),
              isMainLADeath: !!mainLa && moment(mainLa.dod, 'YYYY/MM/DD').isBefore(new Date()),
              reinData
            }
          })
        )
      )
    )
}
