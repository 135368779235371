import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import { IBGeneralTable } from '../../../../../../common'
import { useTranslation } from 'react-i18next'
import {
  ACMVQueryData,
  IBService,
  InvestmentDataInfo,
  InvestmentFund,
  PartialWithdrawHistoryData
} from '../../../../../../ib-service'
import { isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ModalComponent, PulseOpsFormat, TopUpConst, formatNumberWithComma } from '@pulseops/common'
import moment from 'moment'

interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  productType: string
  listFundT25?: InvestmentFund[]
}

const ModalPartialWithdrawlHistory = (props: {
  visible: boolean
  onClose: (visible: boolean) => void
  data: { policyNumber: string; transNo: string }
  listFundT25?: InvestmentFund[]
}) => {
  const { width } = useWindowDimensions()
  const { visible, onClose, data, listFundT25 } = props
  const [loading, bindLoading] = useLoading(false)
  const [partialWithdrawlHistoryDetail, setPartialWithdrawlHistoryDetail] = React.useState<
    PartialWithdrawHistoryData[]
  >([])

  const { t, i18n } = useTranslation('Inbound')
  const getPartialWithdrawlHistoryDetail = (data: { policyNumber: string; transNo: string }) =>
    pipe(
      IBService.getPartialWithdrawHistoryDetail({
        policyNo: data.policyNumber,
        transNo: data.transNo,
        transCode: 'T510'
      }),
      ZIO.map((res) => {
        setPartialWithdrawlHistoryDetail(res.data)
      }),
      bindLoading,
      ZIO.unsafeRun([])
    )

  React.useEffect(() => {
    if (data) getPartialWithdrawlHistoryDetail(data)
    return () => {
      setPartialWithdrawlHistoryDetail([])
    }
  }, [data])

  const dataTable = [
    {
      label: t('FundPWH'),
      field: 'fundCode'
    },
    {
      label: t('TotalAmountPW'),
      field: 'fundAmount',
      format: 'money'
    },
    {
      label: t('RealUnits'),
      field: 'numberOfUnits'
    },
    {
      label: t('EffectivePrice'),
      field: 'effectivePrice',
      format: 'money'
    }
  ]

  return (
    <ModalComponent
      title={`${t('PartialWithdrawDetail')}: ${data.transNo}`}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={partialWithdrawlHistoryDetail.map((x) => ({
            ...x,
            fundCode: `${x.fundCode} ${
              i18n.language === 'vi'
                ? listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.inBoundNameVN
                : listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.fundNameEN
            }`
          }))}
          loading={loading}
          autoPaging
        />
      </View>
    </ModalComponent>
  )
}

export const PartialWithdrawalHistory = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo, productType, listFundT25 } = props
  const [convertPWHData, setConvertPWHData] =
    React.useState<{ transNo: string; transDate: string; totalAmountPW: string; amountPw: string; feePW: string }[]>()
  const [loading, bindLoading] = useLoading()
  const [openModalDetail, setOpenModalDetail] = React.useState<boolean>(false)
  const [dataOpenModal, setDataOpenModal] = React.useState<{
    policyNumber: string
    transNo: string
  }>()

  const convertData = (data: ACMVQueryData[]) => {
    setConvertPWHData(
      orderBy(
        data.map((item) => ({
          transNo: item.transNo || '-',
          transDate: moment(item.transDate, 'DD/MM/YYYY').format('YYYYMMDD') || '-',
          totalAmountPW: PulseOpsFormat.thousandSepartor(
            item.additionalProperties?.partialWithDrawMoney?.withDrawAmount || 0
          ),
          amountPw: PulseOpsFormat.thousandSepartor(
            item.additionalProperties?.partialWithDrawMoney?.purChaseAmount || 0
          ),
          feePW: PulseOpsFormat.thousandSepartor(item.additionalProperties?.partialWithDrawMoney?.fee || 0)
        })),
        ['transDate', 'transNo'],
        ['desc', 'desc']
      )
    )
  }

  React.useEffect(() => {
    if (isNil(investmentInfo?.partialWithdrawHistory)) {
      const transCode = productType === 'ILP' ? 'T510' : 'T619'
      pipe(
        IBService.ACMVQuery(policyNumber, 'PARTIAL_WITHDRAWAL_HISTORY', transCode, undefined, true),
        ZIO.map((res) => {
          if (res.data) {
            setInvestmentInfo({ ...(investmentInfo as InvestmentDataInfo), partialWithdrawHistory: res.data })
            convertData(res.data)
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
      convertData(investmentInfo.partialWithdrawHistory)
    }
  }, [])

  React.useEffect(() => {
    if (isNil(investmentInfo?.extraInfo && productType === 'ILP')) {
      pipe(
        IBService.getInvestmentInfo({ data: { function: 'PARTIAL', policies: [`${policyNumber}`] } }),
        ZIO.map((extraInfo) => {
          if (extraInfo) {
            setInvestmentInfo({ ...(investmentInfo as InvestmentDataInfo), extraInfo: extraInfo })
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataTable = [
    {
      label: t('TransNo'),
      field: 'transNo',
      render: (value: string) => {
        if (productType === 'ILP') {
          return (
            <Pressable
              onPress={() => {
                setDataOpenModal({
                  policyNumber: policyNumber,
                  transNo: value
                })
                setOpenModalDetail(true)
              }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 15,
                  color: '#1ea5fc'
                }}
              >
                {value}
              </Text>
            </Pressable>
          )
        }
        return (
          <Text
            style={{
              fontWeight: '600',
              fontSize: 15
            }}
          >
            {value}
          </Text>
        )
      }
    },
    {
      label: t('TransDatePW'),
      field: 'transDate',
      format: 'date'
    },
    {
      label: t('TotalAmountPW'),
      field: 'totalAmountPW'
    },
    {
      label: t('AmountPW'),
      field: 'amountPw'
    },
    {
      label: t('FeePW'),
      field: 'feePW'
    }
  ]

  const dataTable2 = [
    {
      label: t('FundName'),
      field: 'fundName'
    },
    {
      label: t('PartialWithdrawAmount'),
      field: 'partialWithdrawAmount',
      format: 'money'
    }
  ]

  return (
    <View>
      {productType === 'ULP' ? (
        <IBGeneralTable data={convertPWHData || []} dataTable={dataTable} loading={loading} autoPaging />
      ) : (
        <>
          <View style={{ marginBottom: 10, flexDirection: 'row' }}>
            <Text style={styles.textStyle}>{`${t('TotalWithdrawnAmountFromEachFund')}: `}</Text>
            <Text style={{ fontSize: 15 }}>
              {formatNumberWithComma(
                Math.round(
                  investmentInfo?.extraInfo?.data.policyExtraInfoFunds.reduce(
                    (pre, cur) => (pre = pre + (cur.estimateValue as number)),
                    0
                  ) as number
                )
              )}
            </Text>
          </View>
          <IBGeneralTable
            data={
              investmentInfo?.extraInfo?.data.policyExtraInfoFunds
                .filter((x) => x.estimateValue !== 0)
                .map((x) => ({
                  fundName: `${x.fundCode} ${
                    i18n.language === 'vi'
                      ? listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.inBoundNameVN
                      : listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.fundNameEN
                  }`,
                  partialWithdrawAmount: Math.round(x.estimateValue)
                })) || []
            }
            dataTable={dataTable2}
            loading={loading}
            autoPaging
          />
          <View style={{ marginVertical: 10 }}>
            <Text style={styles.textStyle}>{t('PartialWithdrawalHistory')}</Text>
          </View>
          <IBGeneralTable data={convertPWHData || []} dataTable={dataTable} loading={loading} autoPaging />
          {dataOpenModal ? (
            <ModalPartialWithdrawlHistory
              visible={openModalDetail}
              onClose={setOpenModalDetail}
              data={dataOpenModal}
              listFundT25={listFundT25}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  textStyle: {
    color: '#58647A',
    fontWeight: '700',
    fontSize: 15,
    marginRight: 5,
    marginBottom: 10
  }
})
