import { SelectOption } from '@pulseops/common'
import { SeaAutoDebitRegisterReportModel } from './SEAAutoDebitRegisterReportModel'

export namespace SEAAutoDebitRegisterReportConst {
  export const Status: SelectOption[] = [
    { label: 'All', value: SeaAutoDebitRegisterReportModel.Status.ALL },
    { label: 'Register', value: SeaAutoDebitRegisterReportModel.Status.REGISTER },
    { label: 'Cancel', value: SeaAutoDebitRegisterReportModel.Status.CANCEL }
  ]
  export const StatusVi: SelectOption[] = [
    { label: 'Tất cả', value: SeaAutoDebitRegisterReportModel.Status.ALL },
    { label: 'Đăng ký', value: SeaAutoDebitRegisterReportModel.Status.REGISTER },
    { label: 'Hủy', value: SeaAutoDebitRegisterReportModel.Status.CANCEL }
  ]
  export const displayedColumns = [
    {
      id: 'policyNumber',
      name: `BillingChange:Policy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 20
    },
    {
      id: 'fromDate',
      name: `BillingChange:RegisterAutoDebitFromDate`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 38
    },
    {
      id: 'toDate',
      name: `BillingChange:RegisterAutoDebitToDate`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 38
    },
    {
      id: 'fundTopup1',
      name: `BillingChange:PriorityFundTopup1`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'fundTopup2',
      name: `BillingChange:PriorityFundTopup2`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'fundTopup3',
      name: `BillingChange:PriorityFundTopup3`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'fundTopup4',
      name: `BillingChange:PriorityFundTopup4`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'fundTopup5',
      name: `BillingChange:PriorityFundTopup5`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'fundTopup6',
      name: `BillingChange:PriorityFundTopup6`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'fundTopup7',
      name: `BillingChange:PriorityFundTopup7`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'paymentStatus',
      name: `BillingChange:RegisterStatus`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },

    {
      id: 'chargeDate',
      name: `BillingChange:RegisterDate`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    }
  ]
  export enum ImportType {
    ImportBillingChange = 1,
    ImportPartialWithdramw = 2
  }
}
