import DateFnsUtils from '@date-io/date-fns'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import i18next from 'i18next'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { assets } from '../../assets'
import { DatePickerProps } from './DatePicker'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

const useStyles = makeStyles({
  root: {
    '& input::placeholder': {
      color: '#70777E',
      opacity: 1
    }
  }
})

export const DatePickerCircel = (props: DatePickerProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.required && (
          <Text style={styles.label}>
            {props.label}
            <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>{' '}
          </Text>
        )}
        {props.alwaysShow && <Text style={styles.label}>{props.label}</Text>}
        <View style={[{ flex: 1 }, styles.inputStyle, props.containerStyle]}>
          <KeyboardDatePicker
            className={useStyles().root}
            clearable
            label={props.required || props.alwaysShow ? '' : props.label}
            disabled={props.disabled}
            value={props.value || null}
            keyboardIcon={<assets.InputCalendar />}
            onChange={(date) => {
              if (props.onChange) {
                props.onChange(date)
              }
            }}
            onBlur={props.onBlur}
            placeholder="dd/mm/yyyy"
            format="dd/MM/yyyy"
            minDate={props.minDate}
            maxDate={props.maxDate}
            maxDateMessage={props.errorMessage ? '' : props.maxDateMessage}
            minDateMessage={props.errorMessage ? '' : props.minDateMessage}
            invalidDateMessage={props.errorMessage ? '' : props.invalidDateMessage || i18next.t('form:error_invalid')}
            InputProps={{
              disableUnderline: true,
              style: {
                minHeight: 33
              }
            }}
          />
        </View>
        {props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 8
  },
  inputStyle: {
    borderColor: '#D3DCE6',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 3
  }
})
