import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Alert, assets, AuthService, form2, useMobile } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, Text, View } from 'react-native'
import styled from 'styled-components/native'
import { Input } from './Input'
import { LoginInfoForm } from './login-info-form'
// import EmployeeIcon from './employee.svg'

// type Props = StackScreenProps<MainStackParamList, 'LoginScreen'>
export const LoginScreen = () => {
  const { t } = useTranslation('login')

  const { isWide } = useMobile()

  const [loading, bindLoading] = useLoading(false)

  const {
    base: {
      control,
      formState: { errors }
    },
    handleSubmit
  } = form2.useForm(LoginInfoForm.codec)

  React.useEffect(() => {
    const listenerEvent = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        onLoginPress()
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listenerEvent)
    return () => {
      document.removeEventListener('keydown', listenerEvent)
    }
  }, [])

  const onLoginPress = handleSubmit((validated) => {
    console.log('onLoginPress')

    pipe(
      AuthService.loginGA({
        username: validated.userName,
        password: validated.password
      }),
      bindLoading,
      ZIO.catchAll(() => Alert.alertM('login failed')),
      ZIO.run({})
    )
  })

  return (
    <SC.Container isWide={isWide}>
      <SC.ImageBackground isWide={isWide}>
        <SC.ScrollView>
          <SC.WrapperContent isWide={isWide}>
            <SC.WrapperLogo>
              {/* <assets.LoginLogo width={320} height={103} /> */}
              <assets.AppLogo />
              <SC.TextLogin>{t('TransformingCustomerFulfillment')}</SC.TextLogin>
            </SC.WrapperLogo>
            <SC.WrapperForm>
              <Input control={control} name="userName" errorMessage={errors.userName?.message} />
              <Input control={control} name="password" errorMessage={errors.password?.message} />
              <SC.TouchLogin onPress={onLoginPress} disabled={loading}>
                <SC.TextTouchLogin>{t('Login')}</SC.TextTouchLogin>
              </SC.TouchLogin>
              <SC.OrRow>
                <SC.OrDivider />
                <SC.OrText>{t('Or')}</SC.OrText>
                <SC.OrDivider />
              </SC.OrRow>
              <Pressable
                onPress={() => {
                  pipe(AuthService.loginStaff, ZIO.unsafeRun({}))
                }}
              >
                <SC.LoginStaffButton>
                  {/* <EmployeeIcon /> */}
                  <SC.LoginStaffText>{t('LoginAsStaff')}</SC.LoginStaffText>
                </SC.LoginStaffButton>
              </Pressable>
              {/* <SC.TextVersion>{t('Version')} 1.0</SC.TextVersion> */}
            </SC.WrapperForm>
          </SC.WrapperContent>
        </SC.ScrollView>
      </SC.ImageBackground>
    </SC.Container>
  )
}

type WideProps = { isWide: boolean }

const SC = {
  Container: styled(View)<WideProps>`
    flex: 1;
    background-color: ${(props) => (props.isWide ? 'rgba(0, 0, 0, 0.03)' : '#fff')};
  `,
  ImageBackground: styled.ImageBackground.attrs((props: WideProps) => ({
    source: props.isWide ? assets.LoginBackground : assets.LoginBackgroundMobile
  }))<WideProps>`
    height: 100%;
    width: 100%;
  `,
  WrapperLogo: styled(View)`
    display: flex;
    align-items: center;
    margin: auto;
    padding: 36px 27px 0px;
  `,
  ScrollView: styled.ScrollView.attrs({
    contentContainerStyle: {
      paddingVertical: 40
    }
  })``,
  WrapperContent: styled(View)<WideProps>`
    margin: ${(props) => (props.isWide ? `auto` : 0)};
    background-color: ${(props) => (props.isWide ? '#ffffff' : `transparent`)};
    border-radius: ${(props) => (props.isWide ? `8px` : 0)};
    overflow: hidden;
    box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
  `,
  WrapperForm: styled(View)`
    margin-top: 50px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 43px 30px 20px;
    background-color: #f5f5f5;
  `,
  TouchLogin: styled(Pressable)`
    background-color: #ed1b2e;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 30px;
    border-radius: 20px;
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    margin-bottom: 30px;
  `,
  TextLogin: styled(Text)`
    font-family: ${(props) => props.theme.fonts.main};
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
    margin-top: 22px;
  `,
  TextTouchLogin: styled(Text)`
    font-family: ${(props) => props.theme.fonts.main};
    font-size: 15px;
    color: #fff;
  `,
  TextVersion: styled(Text)`
    font-family: ${(props) => props.theme.fonts.main};
    font-size: 15px;
    color: #000;
    margin-top: 150px;
    text-align: center;
  `,
  OrRow: styled(View)`
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  `,
  OrDivider: styled(View)`
    height: 0;
    flex: 1;
    border: 1px solid #d3dce6;
  `,
  OrText: styled(Text)`
    font-family: 'NotoSans_700Bold';
    font-size: 15px;
    margin-left: 20px;
    margin-right: 20px;
    color: #b0b0b0;
  `,
  LoginStaffButton: styled(View)`
    background-color: #ed1b2e;
    border: 1px solid #d3dce6;
    box-sizing: border-box;
    border-radius: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 40px;
  `,
  LoginStaffText: styled(Text)`
    font-family: ${(props) => props.theme.fonts.main};
    font-size: 15px;
    color: #ffffff;
  `
}
