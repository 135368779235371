import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View } from 'react-native'
import { assets } from '@pulseops/common'
import { infoGen, styles } from '../../../common'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export type BOGeneralAgentBenPopupProps = {
  onClose: (value: any) => void
  open: boolean
  selectedValue: any
}

export const BOGeneralAgentBenPopup = (props: BOGeneralAgentBenPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, selectedValue, open } = props

  const handleClose = (props: BOGeneralAgentBenPopupProps) => {
    onClose(selectedValue)
  }
  const infoMapper = infoGen(false)

  const agentBenInfo = [
    [
      { id: 'agentBenName', title: 'Họ và tên', value: '-' },
      { id: 'agentBenDOB', title: 'Ngày sinh', value: '-' }
    ],
    [
      { id: 'agentBenGender', title: 'Giới tính', value: '-' },
      { id: 'agentBenOccupation', title: 'Nghề nghiệp', value: '-' }
    ],
    [{ id: 'agentBenNationID', title: 'Số CMND / CCCD / Hộ chiếu', value: '-' }],
    [
      { id: 'agentBenNationIDCreatedDate', title: 'Ngày cấp', value: '-' },
      { id: 'agentBenNationIDCreatedLocation', title: 'Nơi cấp', value: '-' }
    ],
    [
      { id: 'agentBenNation', title: 'Quốc tịch', value: '-' },
      { id: 'agentBenOtherNation', title: 'Quốc tịch khác', value: '-' }
    ],
    [{ id: 'agentBenVISA', title: 'Thị thực nhập cảnh', value: '-' }],
    [
      { id: 'agentBenAddress', title: 'Địa chỉ thường trú', value: '-' },
      { id: 'agentBenAddressCurr', title: 'Nơi ở hiện tại', value: '-' }
    ],
    [{ id: 'agentBenAddress1', title: 'Địa chỉ tại nước ngoài', value: '-' }],
    [{ id: 'agentBenRelationship', title: 'MQH với người được bảo hiểm', value: '-' }],
    [
      { id: 'agentBenPercent', title: 'Tỷ lệ % thụ hưởng', value: '-' },
      { id: 'agentAML', title: 'AML', value: '-' }
    ]
  ]

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center', marginLeft: 385 }}>Người thụ hưởng</Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose(null)
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin chi tiết</Text>
          {infoMapper(agentBenInfo, 'memberInfo')}
        </View>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose(null)}>
          <Text style={{ color: '#FFF' }}>OK</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}
