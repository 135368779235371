export namespace BuildConfig {
  export const baseUrl = `https://${window.location.host}`

  export const contextPath = '/1-0-0/rn'
  export const KeyCloakConfig = {
    realm: 'CLAIM',
    url: 'https://huutri.prudential.com.vn/auth',
    clientId: 'pulseforops'
  }
}
