import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DataSource, InfoViewList, Title } from '../common'
import { TaskDetail } from '../../../../common/src'

interface Props {
  detail: TaskDetail.ChangeNationalIDCard
}

interface mappingDataI {
  isBorder: boolean
  title: string
  dataSource: DataSource[]
}

const ChangeNationalIDCardScreen = ({ detail }: Props) => {
  const { t } = useTranslation()
  const { clientName, data } = detail
  const { idNumber, issuedDate, issuedBy, createDate } = data

  const mappingData: mappingDataI[] = [
    {
      isBorder: true,
      title: '',
      dataSource: [
        { label: t('submission:category'), value: t('Reports:PolicyService') },
        { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
        {
          label: t('submission:transactionType'),
          value: t(`TransactionType:${detail.tag}`)
        }
      ]
    },
    {
      isBorder: false,
      title: t('submission:ChangeClientDetail').toUpperCase(),
      dataSource: [{ label: t('CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.FIELDS.selectClient'), value: clientName }]
    },

    {
      isBorder: true,
      title: t('CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.LABEL_GROUP.change_citizen_number_group'),
      dataSource: [
        {
          label: t('CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.FIELDS.idCitizenNumber'),
          value: idNumber,
          isRequired: true
        },
        {
          label: t('CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.FIELDS.issuedDate'),
          value: issuedDate,
          isRequired: true
        },
        {
          label: t('CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.FIELDS.issuedBy'),
          value: issuedBy,
          isRequired: true
        }
      ]
    }
  ]

  const renderViews = () => {
    if (isEmpty(detail)) return

    return mappingData.map((group, index) => {
      const { isBorder, title, dataSource } = group
      return (
        <View key={index} style={{ paddingVertical: 12 }}>
          {title ? <Title title={title} /> : null}
          <View style={[isBorder && styles.borderGroup]}>
            {!isEmpty(dataSource) ? <InfoViewList dataSource={dataSource} /> : null}
          </View>
        </View>
      )
    })
  }

  const renderFiles = () => {
    return (
      <View style={{ marginTop: 32 }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Title title={t('CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.LABEL_GROUP.UPLOAD_FILES_CITIZEN')} />
          <Text style={{ marginLeft: 6, color: 'rgb(237, 27, 46)' }}>*</Text>
        </View>

        {/* <Text style={styles.label}>Chứng từ CCCD</Text> */}

        <View style={styles.box}>
          <View>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png"
              alt="img_pdf"
              width={56}
              height={60}
            />
          </View>

          <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 12 }}>
            <Text style={styles.fileName}>CCCD.pdf</Text>
            <Text style={styles.createDate}>{createDate}</Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <View>
      {renderViews()}
      {renderFiles()}
    </View>
  )
}

export default ChangeNationalIDCardScreen
const styles = StyleSheet.create({
  borderGroup: {
    paddingHorizontal: 12,
    paddingVertical: 30,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: '#d3dce6',
    borderWidth: 1
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  fileName: {
    color: 'black',
    fontWeight: '800',
    fontSize: 15,
    marginBottom: 10
  },

  createDate: {
    color: '#70777e',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: 15
  }
})
