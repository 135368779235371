import { Task } from '@mxt/zio'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { AuthService, AuthState } from '../auth'
import { POApi } from '../POApi'
import { UserRoles /*,Permission*/ } from './Permission'

import { Alert } from '@pulseops/common'
export namespace RBAC {
  // const email = 'ho.quang.dung@prudential.com.vn'
  export const getPermission = (email: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/permission/groups/email?email=${email}`)(
        t.type({
          groups: t.array(t.string)
        })
      )
      // ZIO.catchAll(() =>
      // ZIO.succeed({
      //   groups: Object.values(Permission)
      // })
      // )
    )

  const checkUserRoleCasualLabor = (permissions: string[]) => {
    return pipe(
      ZIO.succeed(permissions),
      ZIO.flatMap((data) => {
        return data && data.length > 0
          ? ZIO.effectTotal(() => {
              const isCasualLabor = permissions.includes(UserRoles.CasualLabor)
              localStorage.setItem('isCasualLabor', `${isCasualLabor}`)
            })
          : pipe(
              ZIO.zipPar(AuthService.logout, ZIO.succeed(sessionStorage.clear())),
              ZIO.flatMap((_) => {
                return Alert.modalM({
                  title: 'Thông báo',
                  content: 'You do not have permission to login this system',
                  hideCancle: true,
                  hideCloseIcon: true,
                  onClose: () => {},
                  onOK: () => {}
                })
              })
            )
      })
    )
  }

  export const permissions: Task<string[]> = pipe(
    AuthService.userInfo,
    ZIO.flatMap((u) => getPermission(u.email)), //ptk.kieu@prudential.com.vn
    ZIO.map((g) => g.groups),
    ZIO.tap(checkUserRoleCasualLabor),
    // ZIO.map((g) => ['View-FunctionList-PS-Submission', 'View-BusinessBackOffice-Homepage', 'View-General-AFDetail', 'Search-AF-AFList', 'View-AFDetail-AFList', 'View-UW-AFDetail', 'View-Verify-AFDetail', 'View-PremiumCheck-AFDetail', 'View-Product-AFDetail-General','View-Employee-AFDetail-General','Edit-UWInfo-AFDetail-UW', 'Edit-VerifyInfo-AFDetail-Verify', 'Edit-PremiumCheckInfo-AFDetail-PremiumCheck' ]),
    ZIO.cached()
  )

  export const permissionWithNoCache: Task<string[]> = pipe(
    AuthService.userInfo,
    ZIO.flatMap((u) => getPermission(u.email)), //ptk.kieu@prudential.com.vn
    ZIO.map((g) => g.groups),
    ZIO.tap(checkUserRoleCasualLabor)
  )
}
