import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { Option } from 'fp-ts/Option'
import { pipe } from 'fp-ts/pipeable'
import * as O from 'fp-ts/Option'

export const Client = t.type(
  {
    clientName: t.string,
    clientCode: t.string,
    firstName: t.string,
    lastName: t.string,
    clientPhoneNumber: t.string,
    companyPhoneNumber: t.string,
    homePhoneNumber: t.string,
    gender: t.string,
    birthDate: t.string,
    nationality: t.string,
    email: t.string,
    addressCode: t.string,
    addressStreet: t.string,
    addressWard: t.string,
    addressDistrict: t.string,
    addressProvince: t.string
  },
  'Client'
)

export const Customer = t.type(
  {
    body: t.type({
      email: t.string,
      mobilePhone: t.string,
      countryCode: t.string,
      location: t.string,
      otpConsentStatus: t.string,
      customerSmsInd: t.string,
      customerEmailInd: t.string,
      agentSmsInd: t.string,
      updateNewPruInfo: t.string,
      name: t.string,
      clientId: t.string,
      externalIds: t.type({
        SOE: Maybe(t.string),
        SOE_VALUE: Maybe(t.string)
      }),
      addressDetails: t.type({
        PRIMARY: Maybe(
          t.type({
            line1: t.string,
            line2: t.string,
            line3: t.string,
            line4: t.string
          })
        )
      }),
      classCode: t.string,
      firstName: t.string,
      surName: t.string,
      dob: t.string,
      dateOfDeath: t.string,
      sex: t.string,
      maritalStatus: t.string,
      salutation: t.string,
      nationality: t.string,
      occupation: t.string,
      attributesExt: t.type({
        MOBILE_CODE: t.string,
        COMPANY_PHONE_NUMBER: t.string,
        COMPANY_CODE: t.string,
        HOME_PHONE_NUMBER: t.string,
        HOME_CODE: t.string,
        BIRTH_PLACE: t.string,
        BIRTH_COUNTRY: t.string,
        CLIENT_TYPE: t.string,
        ID_DATE: t.string,
        CATEGORY: t.string,
        NATIONALITY_2: t.string,
        FORMAT_NAME: t.string,
        EMPLOYEE_ID: t.string,
        FATCA_TAX_NUMBER: t.string,
        FATCA_TAX_DECLARE: t.string,
        FATCA_SIGNED_DATE: t.string,
        FATCA_EXPIRY_DATE: t.string,
        FAX: t.string,
        FOREIGN_COUNTRY: t.string,
        FOREIGN_STREET: t.string,
        FOREIGN_WARD: t.string,
        FOREIGN_DISTRICT: t.string,
        FOREIGN_CITY: t.string,
        COMPANY_NAME: t.string,
        COMPANY_STREET: t.string,
        COMPANY_WARD: t.string,
        COMPANY_DISTRICT: t.string,
        COMPANY_CITY: t.string,
        OCCUPATION_GROUP: t.string,
        OCCUPATION_DESC: t.string
      }),
      dod: t.string
    })
  },
  'Customer'
)

export const NewOwnerInfo = t.type({
  otpConsentStatus: t.string,
  mobilePhone: t.string,
  clientId: t.string,
  name: t.string,
  externalIds: t.type({
    SOE_VALUE: t.string
  }),
  // contactDetails: t.type({
  //   clientPhoneNumber: t.type({
  //     channel: t.string,
  //     value: t.string
  //   })
  // }),
  attributesExt: t.type({
    MOBILE_CODE: t.string,
    EMAIL: Maybe(t.string)
  }),
  surName: t.string,
  dob: t.string,
  sex: t.string,
  nationality: t.string,
  contactDetails: t.type({
    DISPATCH_ADDRESS_PHONE: Maybe(
      t.type({
        value: Maybe(t.string)
      })
    )
  })
})

export const NewOwnerInfoResponseData = t.type({
  body: NewOwnerInfo,
  correlationId: t.string,
  responseStatus: Maybe(
    t.type({
      message: Maybe(t.string)
    })
  )
})

export type NewOwnerInfo = t.TypeOf<typeof NewOwnerInfo>

export type Customer = t.TypeOf<typeof Customer>

export type Client = t.TypeOf<typeof Client>

export const Address = t.type(
  {
    line1: t.string,
    line2: t.string,
    line3: t.string,
    line4: t.string,
    subDistrict: t.string,
    district: t.string,
    city: t.string
  },
  'Address'
)
export type Address = t.TypeOf<typeof Address>

export const Policy = t.type(
  {
    body: t.type({
      policyNo: t.string,
      billToDate: Maybe(t.string),
      clientDespatchAddress: Maybe(t.string),
      contractDate: Maybe(t.string),
      premiumStatus: Maybe(t.string),
      productCode: Maybe(t.string),
      totalPremium: Maybe(t.number),
      owners: t.type({
        id: t.string
      }),
      attributesExt: Maybe(
        t.type({
          basicPremium: Maybe(t.number),
          riderPremium: Maybe(t.number)
        })
      )
    })
  },
  'Policy'
)
export type Policy = t.TypeOf<typeof Policy>

export const Beneficiary = t.type(
  {
    body: t.type({})
  },
  'Beneficiary'
)
export type Beneficiary = t.TypeOf<typeof Beneficiary>

export interface ClientPolicy {
  name: string
  policy: string
}

export const getCustomerInfo = (customer: Option<Customer>) => (f: (customer: Customer) => string) =>
  pipe(
    customer,
    O.map(f),
    O.getOrElse(() => '-')
  )

export const OccupationDetail = Maybe(
  t.type({
    id: t.string,
    code: t.string,
    classOccupation: t.string,
    nameEn: t.string,
    nameVn: t.string,
    isEnableCompanyInfo: t.string,
    positionCode: t.string,
    positionName: t.string
  })
)

export type OccupationDetailType = t.TypeOf<typeof OccupationDetail>
