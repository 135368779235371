import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { InboundParamList } from '../InboundParamList'
import { View, StyleSheet, TouchableOpacity, Text, TextStyle, ScrollView, useWindowDimensions, SafeAreaView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { AppContext, ErrorHandling, FromToDateRangeMonth, InquiryComplaintService, ModalComponent, PulseOpsFormat, PulseOpsService, ServiceInquiryNote, TaskComment, TaskOp, TaskService, TaskType, TransactionStatus, TransactionStatusLabel, assets, totalAgingDays } from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useForm } from 'react-hook-form'
import { IBCallbackSearchForm } from './IBCallbackSearchForm'
import _ from 'lodash'
import { CallbackAppointmentSearch, CallbackInfo, IBService } from '../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { Panel } from '@pulseops/task'
import { IBButton, IBGeneralField, IBGeneralTable, TypeInputComponent } from '../common'
import * as O from 'fp-ts/lib/Option'

type Props = StackScreenProps<InboundParamList, 'IBCallbackAppointment'>

type CallbackAppointmentData = {
  businessKey: string | null | undefined,
  policyNumber: string | null | undefined,
  poClientNumber: string | null | undefined,
  poName: string | null | undefined,
  callbackAppointmentTime: string | null | undefined,
  group: string | null | undefined,
  subServiceTypeCode: string | null | undefined,
  subTransactionTypeCode: string | null | undefined,
  createdBy: string | null | undefined,
  createdDate: string | null | undefined,
  assignee: string | null | undefined,
  standardSLA: string | boolean,
  totalAgings: string | null | undefined,
  lastUpdateDateVer: string | null | undefined,
  lastUpdateByVer: string | null | undefined,
  cancelPaymentStatus: string | null | undefined,
  status: string | null | undefined,
  activity: string | null | undefined
}

export const IBCallbackAppointment = (props: Props) => {
  const { i18n } = useTranslation()
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPage] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [dataSearch, setDataSearch] = React.useState<IBCallbackSearchForm | null>(null)
  const [callbackList, setCallbackList] = React.useState<(CallbackAppointmentData & { isHighlight?: boolean, color?: string })[]>([])
  const [infoVisible, setInfoVisible] = React.useState<boolean>(false)
  const [PSComment, setPSComment] = React.useState<boolean>(false)
  const [PSCommentData, setPSCommentData] = React.useState<TaskComment[]>([])
  const [noteDetail, setNoteDetail] = React.useState<ServiceInquiryNote>({
    customerComment: '',
    closedInfo: '',
    responseToCustomer: ''
  })
  const [formError, setFormError] = React.useState<string>('')
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const { height } = useWindowDimensions()
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const defaultValues: IBCallbackSearchForm = {
    policyNumber: '',
    clientNumber: '',
    fromDate: new Date(),
    toDate: new Date(),
    lastAssignee: ''
  }

  const CSSLAStandardArr = 
    pipe(
      TaskService.getCustomerServiceStandardSLAList(),
      ZIO.map((CSSLAStandardArr) => {
        return CSSLAStandardArr
      }),
      ErrorHandling.runDidMount()
    ) || []

  const { subServiceTypeList, subTransactionTypeList, groupCodeList } = pipe(
    ZIO.zipPar(InquiryComplaintService.getTypes(), InquiryComplaintService.getSubType(), InquiryComplaintService.getGroups()),
    ZIO.map(([typeList, subTypeArr, groups]) => {
      const subServiceTypeList = typeList.slice().map((x) => ({
        name: x.name,
        nameVi: x.nameVi,
        value: x.code
      }))
      const subTransactionTypeList = subTypeArr.slice().map((x) => ({
        name: x.name,
        nameVi: x.nameVi,        
        value: x.code,
        slaStandard: x.slaStandard ?? ''
      }))
      const groupCodeList = groups.slice().map((x) => ({
        name: x.name,
        nameVi: x.nameVi,
        value: x.code,
      }))
      return { subServiceTypeList, subTransactionTypeList, groupCodeList }
    }),
    ErrorHandling.runDidMount()
  ) || {
    subServiceTypeList: [],
    subTransactionTypeList: [],
    groupCodeList: []
  }

  const searchForm = useForm<IBCallbackSearchForm>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })
  const columnTable = [
    {
      label: t('Inbound:table:CaseID'),
      field: 'businessKey'
    },
    {
      label: t('Inbound:table:PolicyNumber'),
      field: 'policyNumber'
    },
    {
      label: t('Inbound:table:POClient'),
      field: 'poClientNumber'
    },
    {
      label: t('Inbound:table:POName'),
      field: 'poName'
    },
    {
      label: t('Inbound:table:CallbackAppointmentTime'),
      field: 'callbackAppointmentTime',
      format: 'dateTime'
    },
    {
      label: t('Inbound:table:Group'),
      field: 'group',
    },
    {
      label: t('Inbound:table:SubService'),
      field: 'subServiceTypeCode'
    },
    {
      label: t('Inbound:table:SubTransaction'),
      field: 'subTransactionTypeCode'
    },
    {
      label: t('Inbound:table:UserSubmission'),
      field: 'createdBy'
    },
    {
      label: t('Inbound:table:CreatedDate'),
      field: 'createdDate',
      format: 'dateTime'
    },
    {
      label: t('Inbound:table:LastAssignee'),
      field: 'assignee'
    },
    {
      label: t('Inbound:table:StandardSLA'),
      field: 'standardSLA',
      render: (value: string, index: number) => {
        return(
          <Text
            style={
              isStandardSLAExceed(index)
                ? { color: '#ed1b2e' }
                : {}
            }
          >
            {value}
          </Text>
        )
      }
    },
    {
      label: t('Inbound:table:TotalAgingdays'),
      field: 'totalAgings'
    },
    {
      label: t('Inbound:table:LastUpdatedDate'),
      field: 'lastUpdateDateVer',
      format: 'dateTime'
    },
    {
      label: t('Inbound:table:LastUpdatedBy'),
      field: 'lastUpdateByVer'
    },
    {
      label: t('Inbound:table:CancelPaymentStatus'),
      field: 'cancelPaymentStatus',
    },
    {
      label: t('Inbound:table:Status'),
      field: 'status',
      render: (value: string) => {
        return (
          value ?
            <Text
              style={{ color: TransactionStatusLabel(getStatus(value)).color, fontSize: 15, whiteSpace: 'nowrap' }}
            >
              {TransactionStatusLabel(getStatus(value)).label}
            </Text> : ''
        )
      }
    },
    {
      label: t('Inbound:table:CurrentActivity'),
      field: 'activity'
    },
    {
      label: t('Inbound:table:Note'),
      field: 'processInstanceId',
      disabled: true,
      render: (value: string) => {
        return (
          <TouchableOpacity onPress={() => getServiceInquiryNote(value)}>
            <View style={styles.btnDetail}>
              <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
            </View>
          </TouchableOpacity>
        )
      }
    },
    {
      label: t('Inbound:table:Comment'),
      field: 'processInstanceId',
      disabled: true,
      render: (value: string) => {
        return (
          <TouchableOpacity
            onPress={() => {
              getCommentById(value)
            }}
          >
            <assets.IconComment />
          </TouchableOpacity>
        )
      }
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:CallbackAppointment'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if(dataSearch) {
      searchCallbackAppointment(dataSearch)
    }
  }, [pageSize, pageNum])

  React.useEffect(() => {
    if(dataSearch && callbackList.length > 0) {
        setCallbackList(callbackList.map((i) => {
          return ({
            ...i,
            subTransactionTypeCode: i.subTransactionTypeCode ? showLabelSubType(i.subTransactionTypeCode, true) : '',
            subServiceTypeCode: i.subServiceTypeCode ? showLabelType(i.subServiceTypeCode, true) : '',
            group: i.group ? showGroup(i.group, true) : ''
          })}
        )
      )
    }
  }, [i18n.language])

  const getStatus = (status: string): TransactionStatus => {
    switch (status) {
      case 'Completed':
        return TransactionStatus.COMPLETED
      case 'Pending':
        return TransactionStatus.PENDING
      case 'Rejected':
        return TransactionStatus.REJECTED
      case 'End':
        return TransactionStatus.END
      default:
        return TransactionStatus.IN_PROGRESS
    }
  }

  const isStandardSLAExceed = (index: number) => {
    const dataIndex = callbackList[index]
    if(dataIndex && dataIndex.businessKey?.startsWith(TaskType.CustomerService))
      return pipe(
        O.fromNullable(CSSLAStandardArr.length > 0 && CSSLAStandardArr.find((x) => x.subTransactionCode === dataIndex.subTransactionTypeCode)),
        O.fold(
          () => false,
          (standardItem) => {
            return dataIndex.createdDate && standardItem && totalAgingDays(new Date(dataIndex.createdDate)) >= Number(standardItem?.slaDays)
          }
        )
      )
    if(dataIndex && dataIndex.businessKey?.startsWith(TaskType.Complaint))
      return pipe(
        O.fromNullable(subTransactionTypeList.find((x) => x.value === dataIndex.subTransactionTypeCode)),
        O.fold(
          () => false,
          (standardItem) =>
            dataIndex.createdDate && totalAgingDays(new Date(dataIndex.createdDate)) >= Number(standardItem.slaStandard)
        )
      )
    return false
  }

  const getStandardSLAExceed = (dataIndex: CallbackInfo) => {
    if(dataIndex && dataIndex.businessKey?.startsWith(TaskType.CustomerService))
      return pipe(
        O.fromNullable(CSSLAStandardArr?.find((x) => x.subTransactionCode === dataIndex.subTransactionTypeCode)),
        O.fold(
          () => '',
          (standardItem) => {
            return standardItem.slaDays
          }
        )
      )
    if(dataIndex && dataIndex.businessKey?.startsWith(TaskType.Complaint))
      return pipe(
        O.fromNullable(subTransactionTypeList.find((x) => x.value === dataIndex.subTransactionTypeCode)),
        O.fold(
          () => '',
          (standardItem) => standardItem.slaStandard
        )
      )
    return false
  }

  const showLabelType = (type: string, changeLanguage?: boolean) => {
    const findItem = subServiceTypeList.find((item) => changeLanguage ? (i18n.language === 'vi' ? item.name === type : item.nameVi === type) : (item.value === type))
    return i18n.language === 'en' ? findItem?.name ?? '' : findItem?.nameVi ?? ''
  }

  const showLabelSubType = (type: string, changeLanguage?: boolean) => {
    const findItem = subTransactionTypeList.find((item) => changeLanguage ? (i18n.language === 'vi' ? item.name === type : item.nameVi === type) : (item.value === type))
    return i18n.language === 'en' ? findItem?.name ?? '' : findItem?.nameVi ?? ''
  }

  const showGroup = (type: string, changeLanguage?: boolean) => {
    const findItem = groupCodeList.find((item) => changeLanguage ? (i18n.language === 'vi' ? item.name === type : item.nameVi === type) : (item.value === type))
    return i18n.language === 'en' ? findItem?.name ?? '' : findItem?.nameVi ?? ''
  }

  const getCommentById = (processId: string) => {
    return pipe(
      PulseOpsService.getCommentList(processId),
      ZIO.tap((comments) => {
        setPSComment(true), 
        setPSCommentData(comments ?? [])
        return ZIO.unit
      }),
      ZIO.mapError((e) => {
        return ZIO.fail(e)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const getServiceInquiryNote = (taskId: string) => {
    pipe(
      TaskService.getServiceInquiryNote(taskId),
      ZIO.tap((note) => {
        setNoteDetail({
          customerComment: note.customerComment,
          closedInfo: note.closedInfo,
          responseToCustomer: note.responseToCustomer
        })
        setInfoVisible(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const showCancelPaymentText = (value: string) => {
    const enum CancelPaymentStatus {
      Accept = 'ACCEPT_CANCEL',
      Reject = 'REJECT_CANCEL'
    }
    const cancelPaymentLabel = [
      {
        value: CancelPaymentStatus.Accept,
        label: 'Accept cancel'
      },
      {
        value: CancelPaymentStatus.Reject,
        label: 'Reject cancel'
      }
    ]
    return cancelPaymentLabel.find((item) => item.value === value)?.label ?? '-'
  }
  
  const searchCallbackAppointment = (valueSearch: IBCallbackSearchForm) => {
    if(valueSearch) {
      const dataSearch: CallbackAppointmentSearch = {
        pageIndex: pageNum,
        itemsPerPage: pageSize,
        policyNumber: valueSearch.policyNumber,
        clientNumber: valueSearch.clientNumber,
        latestAssignee: valueSearch.lastAssignee,
        createdBefore: PulseOpsFormat.serviceInquiry_endOfDate(valueSearch.toDate ?? new Date(), true),
        createdAfter: PulseOpsFormat.serviceInquiry_StartOfDate(valueSearch.fromDate ?? new Date(), true),
      }
      pipe(
        IBService.searchCallbackAppointment(dataSearch),
        ZIO.map((res) => {
          res.records && setCallbackList(res.records.map((i, index) => {
            const minuteDiff = i.callbackAppointmentTime ? moment(i.callbackAppointmentTime).diff(moment(new Date()), 'minutes') : undefined
            return ({
              ...i,
              callbackAppointmentTime: moment(i.callbackAppointmentTime).format('YYYYMMDDHHmmss'),
              isHighlight: minuteDiff ? minuteDiff <= 45 : false,
              color: (minuteDiff && minuteDiff <= 45) ? minuteDiff > 0 ? '#FFF380' : '#FF7F7F' : undefined,
              standardSLA: getStandardSLAExceed(i),
              totalAgings: (i.createdDate ? moment().startOf('days').diff(moment(i.createdDate).startOf('days'), 'days') : 0).toString(),
              subTransactionTypeCode: i.subTransactionTypeCode ? showLabelSubType(i.subTransactionTypeCode) : '',
              subServiceTypeCode: i.subServiceTypeCode ? showLabelType(i.subServiceTypeCode) : '',
              createdDate: moment(i.createdDate).format('YYYYMMDDHHmmss'),
              lastUpdateDateVer: moment(i.lastUpdateDateVer).format('YYYYMMDDHHmmss'),
              activity: (i.status === 'In process' || i.status === 'Pending') ? i.activity : '',
              cancelPaymentStatus: i.cancelPaymentStatus ? showCancelPaymentText(i.cancelPaymentStatus) : '',
              group: i.groupCode ? showGroup(i.groupCode) : ''
            })}
          ).sort((p1,p2) => p1.callbackAppointmentTime && p2.callbackAppointmentTime && moment(p1.callbackAppointmentTime).diff(moment(p2.callbackAppointmentTime), 'minutes') ? 1 : -1))
          setTotalItem(res.paging.total)
          if (res.records.length === 0) setFormError(t('message:MS030029'))
          else setFormError('')
          return ZIO.unit
        }),
        ZIO.catchAll((error) => {
          setFormError(t('message:MS030029'))
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const onSearch = searchForm.handleSubmit((value) => {
    searchForm.clearErrors()
    setDataSearch(value)
    searchCallbackAppointment(value)
  })

  const ModalNote = () => {
    return (
      <ModalComponent
        title={t('ServiceInquiry:DetailNote')}
        visible={infoVisible}
        onClose={() => setInfoVisible(false)}
        actions={[
          {
            text: t('common:Close'),
            type: 'filled',
            disabled: false,
            loading,
            onlyWide: false,
            style: { height: 40 },
            action: () => setInfoVisible(false)
          }
        ]}
      >
        <View style={{ marginHorizontal: 20, marginTop: 20, maxHeight: height - 200 }}>
          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:CustomerComment')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#4f4f4f',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.customerComment}</Text>
            </ScrollView>
          </View>
          {/* Response to Customer */}
          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ResponseToCustomer')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#4f4f4f',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.responseToCustomer}</Text>
            </ScrollView>
          </View>

          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ClosedInfo')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#4f4f4f',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.closedInfo}</Text>
            </ScrollView>
          </View>
        </View>
      </ModalComponent>
    )
  }

  const ModalComment = () => {
    return (
      <ModalComponent
        title={t('ServiceInquiry:CommentDetail')}
        visible={PSComment}
        onClose={() => {
          setPSComment(false), setPSCommentData([])
        }}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'filled',
            disabled: false,
            loading,
            onlyWide: false,
            style: { height: 40 },
            action: () => {
              setPSComment(false), setPSCommentData([])
            }
          }
        ]}
        modalWidth={864}
      >
        {PSCommentData.length > 0 && (
          <View
            style={{
              marginHorizontal: 20,
              marginTop: 20,
              maxHeight: height - 200,
              borderWidth: 1,
              borderRadius: 8,
              borderColor: '#dcdcdc',
              paddingTop: 16,
              paddingHorizontal: 16
            }}
          >
            {PSCommentData.map((user, index) => (
              <Panel
                key={index}
                title={user.username}
                comment={PulseOpsFormat.datetoFormat(user.time, 'DD/MM/YYYY HH:mm:ss')}
                isHiddenTitle={false}
                isExand={true}
                isHaveComment
              >
                <Text>{user.message}</Text>
              </Panel>
            ))}
          </View>
        )}
        {PSCommentData.length === 0 && (
          <View
            style={{
              marginHorizontal: 20,
              marginTop: 20,
              maxHeight: height - 200,
              borderWidth: 1,
              borderRadius: 8,
              borderColor: '#dcdcdc',
              paddingHorizontal: 16,
              minHeight: 80
            }}
          >
            <Text style={{ marginVertical: 'auto' }}>{t('ServiceInquiry:CommentNotFound')}</Text>
          </View>
        )}
      </ModalComponent>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <IBGeneralField
            FieldForm={searchForm}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.INPUT,
                formName: 'policyNumber',
                title: t('common:PolicyNumber'),
                inputType: 'number',
                maxLength: 9,
                rules: {
                  minLength: {
                    value: 8,
                    message: t('message:MS160014')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'fromDate',
                required: true,
                title: t('FromDate'),
                maxDate: new Date(),
                maxDateMessage: '',
                rules: {
                  validate: async () => {
                    const currentDate = new Date()
                    const toDate = searchForm.watch('toDate')
                    const fromDate = searchForm.watch('fromDate')
                    return !_.isNull(fromDate) &&
                      (fromDate?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:MS990032')}`
                      : !_.isNull(fromDate) && !_.isNull(toDate) &&
                        fromDate?.getTime() > toDate?.getTime()
                        ? `${t('message:MS030044')}`
                        : FromToDateRangeMonth(1)({ fromDate: fromDate, toDate: toDate })
                          ? `${t('message:MS080004', { k: '1' })}`
                          : _.isNull(fromDate) ?
                            `${t('message:MS020009', { field: t('common:FromDate') })}` : true
                  }
                },
                placeHolder: 'dd/mm/yyyy',
                disabled: loading
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'toDate',
                required: true,
                maxDate: new Date(),
                maxDateMessage: '',
                title: t('ToDate'),
                rules: {
                  validate: () => {
                    const currentDate = new Date()
                    const toDate = searchForm.watch('toDate')
                    const fromDate = searchForm.watch('fromDate')
                    return !_.isNull(toDate) &&
                      (toDate?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:MS990032')}`
                      : !_.isNull(fromDate) && !_.isNull(toDate) &&
                        (fromDate?.getTime() as number) > (toDate?.getTime() as number)
                        ? `${t('message:MS030044')}`
                        : FromToDateRangeMonth(1)({ fromDate: fromDate, toDate: toDate })
                          ? `${t('message:MS080004', { k: '1' })}`
                          : _.isNull(toDate) ?
                            `${t('message:MS020009', { field: t('common:ToDate') })}` : true
                  }
                },
                placeHolder: 'dd/mm/yyyy',
                disabled: loading
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'clientNumber',
                title: t('ClientNumber'),
                inputType: 'number',
                maxLength: 8,
                rules: {
                  minLength: {
                    value: 8,
                    message: t('message:IB0003')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'lastAssignee',
                title: t('Inbound:table:LastAssignee'),
                inputType: 'text',
                maxLength: 100,
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              }
            ]}
          />
          <View style={{ width: '7%', minWidth: 140 }}>
            <IBButton
              onPress={() => {
                onSearch()
              }}
              title={t('Search')}
              backgroundFill
              disabled={loading}
            />
          </View>
        </View>
        <View style={{ marginLeft: 15 }}>
          {formError ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{formError}</Text> : null}
        </View>
        <View style={{ padding: 15 }}>
          <IBGeneralTable 
            dataTable={columnTable} 
            data={callbackList} 
            paging={{
              setPage: setPage,
              setPageSize: setPageSize,
              page: pageNum,
              pageSize: pageSize,
              taskCount: totalItem
            }} />
          {ModalNote()}
          {ModalComment()}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    height: '100%'
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,
  label: {
    fontSize: 13,
    fontWeight: '500',
    marginHorizontal: 16,
    marginVertical: 2,
    lineHeight: 24,
    whiteSpace: 'nowrap'
  },
  containerButton: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'baseline',
    borderRadius: 16
    // maxHeight: 24
  }
})