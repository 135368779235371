import { AccountBalance } from './../PaymentMethod'
import { Frequency, mapFrequency } from './../Frequency'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Policy } from '../Client'
import { SourceType } from '../SourceType'
import { EFormBillingChange } from '../OpEformDetailApi'
import moment from 'moment'
import { BillingChange as BillingChangeType } from '../BillingChange'
export interface BillingChange {
  tag: TransactionType.BILLING_CHANGE | TransactionType.BILLING_CHANGE_CORPORATE
  source: SourceType
  clientName: string
  currentFrequency: string
  currentRenewalPremium: number | null
  paidToDate: string | null
  newFrequency: string
  newRenewalPremium: number | null
  suspendAmount: number | null
  effectiveDate: string | null
  advancePremium: number | null
  billToDate: string | null
  requirePayinAmount: number | null
}

export const BillingChange = (
  detail: api.BillingChange,
  policy: Policy | null,
  billingData: EFormBillingChange,
  poName: string,
  accountBalanceLP: Array<AccountBalance>,
  accountBalanceVP: Array<AccountBalance>,
  billingChangeData: BillingChangeType.Detail
): BillingChange => {
  // const _suspendAmount = accountBalanceLP.length ? accountBalanceLP.reduce((total, e) => total + e.amount, 0) : 0
  // const _advancePremium = accountBalanceVP.length ? accountBalanceVP.reduce((total, e) => total + e.amount, 0) : 0
  const _advancePremium = Number(billingData.body.premiumAdv || '0')
  const contractDay = policy && policy.body.contractDate?.split('-')[2] ? policy.body.contractDate?.split('-')[2] : ''
  const contractMonth = policy && policy.body.contractDate?.split('-')[1] ? policy.body.contractDate?.split('-')[1] : ''
  const effectiveDateNewFrequency = `${contractDay}/${contractMonth}/${new Date().getFullYear()}`
  const clientName = poName
  const currentFrequency = billingData.body.curBillingFrequency ?? '' // from core
  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
      const _data = detail.payload
      const _currentRenewalPremium = _data && _data.body.curRenewalPremium ? Number(_data.body.curRenewalPremium) : 0
      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: clientName,
        currentFrequency,
        currentRenewalPremium: _currentRenewalPremium,
        paidToDate: _data.body.paidToDate || '-',
        newFrequency: _data.body.newBillingFrequency
          ? Number(_data.body.newBillingFrequency)
            ? mapFrequency.get(_data.body.newBillingFrequency as Frequency) ?? ''
            : _data.body.newBillingFrequency
          : '-',
        newRenewalPremium: Number(billingChangeData.newInstallmentPremium),
        suspendAmount: Number(billingChangeData.suspenseAmount) ?? 0,
        effectiveDate: effectiveDateNewFrequency ? effectiveDateNewFrequency : null,
        advancePremium: _advancePremium,
        billToDate: _data.body.billToDate || '-',
        requirePayinAmount:
          Number(billingChangeData.newInstallmentPremium) -
          (Number(billingChangeData.suspenseAmount) ?? 0 + _advancePremium)
      }
    // case SourceType.ZALO:
    //   const zaloData = detail.payload
    //   const newInstallmentPremium = Number(zaloData.body.newInstallmentPremium)
    //   const advancePremium = _advancePremium
    //   const suspendAmount = Number(billingChangeData.suspenseAmount) ?? 0
    //   return {
    //     tag: TransactionType.BILLING_CHANGE,
    //     source: detail.source,
    //     clientName: clientName,
    //     currentFrequency,
    //     currentRenewalPremium: Number(billingChangeData.curInstallmentPremium),
    //     paidToDate: '',
    //     newFrequency: zaloData.body.newBillingFrequency
    //       ? Number(zaloData.body.newBillingFrequency)
    //         ? mapFrequency.get(zaloData.body.newBillingFrequency as Frequency) ?? ''
    //         : zaloData.body.newBillingFrequency
    //       : '-',
    //     newRenewalPremium: newInstallmentPremium,
    //     suspendAmount,
    //     effectiveDate: effectiveDateNewFrequency ? effectiveDateNewFrequency : null,
    //     advancePremium,
    //     billToDate: '',
    //     requirePayinAmount: newInstallmentPremium - (suspendAmount + advancePremium)
    //   }
    case SourceType.EKIOSK:
      const data = detail.payload.body
      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: clientName,
        currentFrequency,
        currentRenewalPremium: Number(billingChangeData.curInstallmentPremium),
        paidToDate: '-',
        newFrequency: data.newBillingFrequency ?? '-',
        newRenewalPremium: Number(data.newInstallmentPremium),
        suspendAmount: Number(billingChangeData.suspenseAmount) ?? 0,
        effectiveDate: effectiveDateNewFrequency ? effectiveDateNewFrequency : null,
        advancePremium: _advancePremium,
        billToDate: null,
        requirePayinAmount:
          Number(data.newInstallmentPremium) -
          ((Number(billingChangeData.suspenseAmount) || 0) + (_advancePremium || 0))
      }

    case SourceType.ZALO:
    case SourceType.PULSE4OPS:
    case SourceType.PULSE:
    case SourceType.PRUONLINE:
      const body = detail.payload.body
      const newRenewalPremium4ops = body.newInstallmentPremium ? Number(body.newInstallmentPremium) : 0
      const suspendAmount4ops = Number(billingChangeData.suspenseAmount) ?? 0
      const detectFormatDDMMYYYY = (date: string = '') =>
        moment(body?.effectiveDateNewFrequency, 'DD/MM/YYYY', true).isValid()
          ? date
          : moment(date ?? '', 'YYYYMMDD').format('DD/MM/YYYY')

      let effectiveDate: string | null = ''
      if (detail.source === SourceType.PULSE4OPS) {
        effectiveDate = body?.effectiveDateNewFrequency ? body?.effectiveDateNewFrequency : null
      } else {
        effectiveDate = body?.effectiveDateNewFrequency ? detectFormatDDMMYYYY(body?.effectiveDateNewFrequency) : null
      }

      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: clientName,
        currentFrequency:
          [SourceType.PULSE, SourceType.PRUONLINE].includes(detail.source) || TransactionType.BILLING_CHANGE_CORPORATE
            ? detail.payload.body.curBillingFrequency || ''
            : currentFrequency,
        currentRenewalPremium: [SourceType.PULSE, SourceType.PRUONLINE].includes(detail.source)
          ? body?.curInstallmentPremium
            ? Number(body?.curInstallmentPremium)
            : 0
          : body?.curRenewalPremium
          ? Number(body.curRenewalPremium)
          : 0,
        paidToDate: body?.paidToDate ?? '',
        newFrequency: body?.newBillingFrequency ?? '-',
        newRenewalPremium: newRenewalPremium4ops,
        suspendAmount: suspendAmount4ops,
        effectiveDate: effectiveDate,
        advancePremium: _advancePremium,
        billToDate: body?.billToDate ? body.billToDate : '',
        requirePayinAmount: newRenewalPremium4ops - (suspendAmount4ops + _advancePremium)
      }
    // case SourceType.PRUONLINE:
    //   const bd = detail.payload.body
    //   const newRenewalPremium = bd.newInstallmentPremium ? Number(bd.newInstallmentPremium) : 0
    //   const susAmount = Number(billingChangeData.suspenseAmount) ?? 0
    //   return {
    //     tag: TransactionType.BILLING_CHANGE,
    //     source: detail.source,
    //     clientName: clientName,
    //     currentFrequency,
    //     currentRenewalPremium: bd?.curInstallmentPremium ? Number(bd?.curInstallmentPremium) : 0,
    //     paidToDate: '-',
    //     newFrequency: bd.newBillingFreq ?? '',
    //     newRenewalPremium,
    //     suspendAmount: susAmount,
    //     effectiveDate: bd.effectiveDate ? moment(bd.effectiveDate).format('DD/MM/yyyy') : null,
    //     advancePremium: _advancePremium,
    //     billToDate: billingData.body.billToDate ? billingData.body.billToDate : '',
    //     requirePayinAmount: newRenewalPremium - (susAmount + _advancePremium)
    //   }
  }
}
