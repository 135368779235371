export enum AMLResult {
  HIT = 'HIT',
  NO_HIT = 'NO_HIT',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  HIT_ACCEPT = 'HIT_ACCEPT',
  HIT_DECLINE = 'HIT_DECLINE',
  NOTCONNECT = 'NOTCONNECT',
  INSUFFICIENT_INFORMATION = 'INSUFFICIENT_INFORMATION'
}

export const getAMLResultLabel = (aml: AMLResult): string => {
  switch (aml) {
    case AMLResult.HIT:
      return 'HIT'
    case AMLResult.NO_HIT:
      return 'NO HIT'
    case AMLResult.ERROR:
      return 'ERROR'
    case AMLResult.WARNING:
      return 'WARNING'
    case AMLResult.HIT_ACCEPT:
      return 'HIT ACCEPT'
    case AMLResult.HIT_DECLINE:
      return 'HIT DECLINE'
    case AMLResult.NOTCONNECT:
      return 'NOT CONNECT'
    case AMLResult.INSUFFICIENT_INFORMATION:
      return 'INSUFFICIENT INFORMATION'
    default:
      return '-'
  }
}
