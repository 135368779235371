import React from 'react'
import { CheckBox, CheckBoxProps } from 'react-native-elements'

type Props = CheckBoxProps & {}

export const CustomCheckBox: React.FC<Props> = (props) => {
  return (
    // @ts-ignore
    <CheckBox
      checkedColor={'#ED1B2E'}
      //   checkedIcon={'check-square'}
      //   uncheckedIcon={'square'}
      textStyle={{ fontSize: 15, fontWeight: 'normal' }}
      containerStyle={{ padding: 5, alignSelf: 'flex-start', backgroundColor: 'transparent', borderWidth: 0 }}
      {...props}
    />
  )
}
