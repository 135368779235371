import * as React from 'react'
import { Modal, ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import {
  assets,
  Input,
  Select,
  DatePicker,
  useMobile,
  RoundedButton,
  form2,
  SelectSearch,
  CanView,
  TaskCount,
  TASK_ACTIVITY,
  AMLResult,
  getAMLResultLabel,
  TaskType
} from '@pulseops/common'
import * as D from 'date-fns'
import { Controller } from 'react-hook-form'
import { TaskFilterForm } from './TaskFilterForm'
import {
  sourceOptions,
  sourceDAOptions,
  transactionTypeOption,
  transactionTypeDAOption,
  sourceIDCOptions,
  transactionTypeIDCOption
} from './TaskFilterFormTypes'
import { useTranslation } from 'react-i18next'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
interface Props {
  visible: boolean
  groupName: string
  categroryName: string
  onPressClose?: () => void
  onPressSubmit: (data: TaskFilterForm.Validated) => void
  onPressClean: () => void
  taskGroupSelected: TaskCount | undefined
}

export const TaskFilterFormModal = ({
  groupName,
  categroryName,
  visible,
  onPressClose,
  onPressSubmit,
  onPressClean,
  taskGroupSelected
}: Props) => {
  const { isWide, isMobile } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  const paymentMethods = [
    {
      label: t('claim:BankTransfer'),
      value: 'BANKTRANSFER'
    },
    {
      label: t('claim:CashAtTheCounter'),
      value: 'CASH_AT_COUNTER'
    },
    {
      label: t('claim:PayAtBank'),
      value: 'PAID_AT_BANK'
    }
  ]

  const transTypeOptions = React.useMemo(
    () => transactionTypeOption.map((item) => ({ ...item, label: t(item.label) })),
    []
  )
  const transTypeDAOptions = React.useMemo(
    () => transactionTypeDAOption.map((item) => ({ ...item, label: t(item.label) })),
    []
  )

  const transTypeIDCOptions = React.useMemo(
    () => transactionTypeIDCOption.map((item) => ({ ...item, label: t(item.label) })),
    []
  )

  const lastActivityOptions = React.useMemo(() => {
    const activityArr = Object.entries(TASK_ACTIVITY)
    return activityArr.map(([key, value]) => {
      return {
        label: value,
        value: key
      }
    })
  }, [])

  const AMLScreeningOptions = React.useMemo(() => {
    const screenArr = Object.entries(AMLResult)
    return screenArr
      .filter(([key, value]) => key !== 'INSUFFICIENT_INFORMATION')
      .map(([key, value]) => {
        return {
          label: getAMLResultLabel(value),
          value: key
        }
      })
  }, [])

  const AMLRedflagOptions = React.useMemo(() => {
    const redFlagList = [
      { label: 'N', value: 'N' },
      { label: 'Y', value: 'Y' }
    ]
    return redFlagList
  }, [])

  const defaultPaymentMethod = {
    value: '',
    label: ''
  }

  const { base, handleSubmit } = form2.useForm(TaskFilterForm.codec, {
    defaultValues: {
      transactionType: [],
      source: [],
      paymentMethod: defaultPaymentMethod
    }
  })

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset
  } = base

  const { agingTo, agingFrom, createdFromDate, createdToDate } = watch()

  React.useEffect(() => {
    if (agingFrom || agingTo) {
      setValue('createdFromDate', null)
      setValue('createdToDate', null)
      if (agingFrom && agingTo && agingFrom > agingTo) {
        setError('agingFrom', { message: t('TaskManagement:ValidateFilterAging') }, { shouldFocus: true })
        setError('agingTo', { message: t('TaskManagement:ValidateFilterAging') }, { shouldFocus: true })
      } else {
        clearErrors('agingFrom')
        clearErrors('agingTo')
      }
    }
  }, [agingTo, agingFrom])

  React.useEffect(() => {
    if (createdFromDate || createdToDate) {
      setValue('agingFrom', null)
      setValue('agingTo', null)

      if (createdFromDate && createdToDate && D.isAfter(createdFromDate, createdToDate)) {
        setError('createdFromDate', { message: t('TaskManagement:ValidateFilterAging') }, { shouldFocus: true })
        setError('createdToDate', { message: t('TaskManagement:ValidateFilterAging') }, { shouldFocus: true })
      } else {
        clearErrors('createdFromDate')
        clearErrors('createdToDate')
      }
    }
  }, [createdFromDate, createdToDate])

  const onSubmit = () => {
    if ((agingFrom && !agingTo) || (!agingFrom && agingTo)) {
      setError('agingFrom', { message: t('form:error_required_field') })
      setError('agingTo', { message: t('form:error_required_field') })
    } else if ((createdFromDate && !createdToDate) || (!createdFromDate && createdToDate)) {
      setError('createdFromDate', { message: t('form:error_required_field') })
      setError('createdToDate', { message: t('form:error_required_field') })
    } else {
      handleSubmit((value) => {
        onPressSubmit && onPressSubmit(value)
      })()
    }
  }

  const onReset = () => {
    reset({
      createdFromDate: null,
      createdToDate: null,
      assignee: '',
      caseId: '',
      transactionType: [],
      source: [],
      agingFrom: '',
      agingTo: '',
      client: '',
      paymentMethod: defaultPaymentMethod,
      agentCode: '',
      lastActivity: {
        value: '',
        label: ''
      },
      submittedBy: '',
      AMLScreening: {
        value: '',
        label: ''
      },
      AMLRedflag: {
        value: '',
        label: ''
      }
    })
    // reset()
    console.log(`filter form: ${JSON.stringify(base.getValues())}`)
    onPressClean && onPressClean()
  }

  const getSourceOptionsByCategory = () => {
    switch (taskGroupSelected?.type) {
      case TaskType.DistributionService:
        return sourceDAOptions
      case TaskType.IDC:
        return sourceIDCOptions
      default:
        return sourceOptions
    }
  }

  const getTransactionTypeListByCategory = () => {
    switch (taskGroupSelected?.type) {
      case TaskType.DistributionService:
        return transTypeDAOptions
      case TaskType.IDC:
        return transTypeIDCOptions
      default:
        return transTypeOptions
    }
  }

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          justifyContent: isWide ? 'center' : 'flex-end',
          alignItems: 'center'
        }}
      >
        {/* Backdrop handle touch outsidem modal event*/}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onPressClose}
            style={{
              width: width,
              height: height,
              position: 'absolute'
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              maxHeight: height * 0.85,
              width: isWide ? 720 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('TaskManagement:Filter')}</Text>
              <View style={styles.breadcumbCotnainer}>
                <Text style={styles.breadcumbItem}>{groupName}</Text>
                <View style={styles.arrowIcon}>
                  <assets.ArrowRightTaskFilterModalIcon />
                </View>
                <Text style={styles.breadcumbItem}>{categroryName}</Text>
              </View>
            </View>
            <TouchableOpacity onPress={onPressClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          {/* Filter Inputs */}
          <View style={{ marginTop: 20, flex: 1, paddingHorizontal: 20 }}>
            <ScrollView style={{ paddingBottom: isWide ? 0 : 16 }}>
              <View style={[styles.row, { flexDirection: isWide ? 'row' : 'column' }]}>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginEnd: isWide ? 30 : 0
                  }}
                >
                  <Controller
                    name={'category'}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={t('TaskManagement:Catergories')}
                        disabled
                        options={[]}
                        labelStyle={{
                          ...{ ...styles.label, fontSize: isMobile ? 13 : 15 },
                          fontSize: isMobile ? 13 : 15
                        }}
                      />
                    )}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginTop: isWide ? 0 : 20
                  }}
                >
                  <Text style={[styles.label, { fontSize: isMobile ? 13 : 15 }]}>
                    {t('TaskManagement:Createddate')}
                  </Text>
                  <View style={{ justifyContent: 'flex-start' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Controller
                        name={'createdFromDate'}
                        control={control}
                        render={({ field }) => (
                          <DatePicker onChange={field.onChange} onBlur={field.onBlur} value={field.value ?? null} />
                        )}
                      />
                      <Text style={{ color: '#000000', fontSize: 15, marginHorizontal: 10 }}>~</Text>
                      <Controller
                        name={'createdToDate'}
                        control={control}
                        render={({ field }) => (
                          <DatePicker onChange={field.onChange} onBlur={field.onBlur} value={field.value ?? null} />
                        )}
                      />
                    </View>
                    <Text style={styles.error}>{errors.createdFromDate?.message || errors.createdToDate?.message}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.row, { flexDirection: isWide ? 'row' : 'column', marginTop: 20 }]}>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginEnd: isWide ? 30 : 0
                  }}
                >
                  <Controller
                    name={'status'}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={t('TaskManagement:Status')}
                        options={[]}
                        disabled
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                      />
                    )}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginTop: isWide ? 0 : 20
                  }}
                >
                  <Controller
                    name={'assignee'}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        value={field.value ?? ''}
                        title={t('TaskManagement:Assignee')}
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={[styles.row, { flexDirection: isWide ? 'row' : 'column', marginTop: 20 }]}>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginEnd: isWide ? 30 : 0
                  }}
                >
                  <Controller
                    name={'transactionType'}
                    control={control}
                    render={({ field }) => (
                      <SelectSearch
                        {...field}
                        label={t('TaskManagement:TransactionType')}
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                        options={getTransactionTypeListByCategory()}
                        multiple={true}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                      />
                    )}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginTop: isWide ? 0 : 20
                  }}
                >
                  <Controller
                    name={'caseId'}
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        title={t('TaskManagement:CaseId')}
                        value={field.value ?? ''}
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={[styles.row, { flexDirection: isWide ? 'row' : 'column', marginTop: 20 }]}>
                <View
                  style={{
                    flex: 1,
                    width: '100%',
                    marginEnd: isWide ? 30 : 0
                  }}
                >
                  <Controller
                    name={'source'}
                    control={control}
                    render={({ field }) => (
                      <SelectSearch
                        {...field}
                        label={t('TaskManagement:Source')}
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                        options={getSourceOptionsByCategory()}
                        multiple={true}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                      />
                    )}
                  />
                </View>

                {taskGroupSelected?.type == 'DS' ? (
                  <View
                    style={{
                      flex: 1,
                      width: '100%',
                      marginTop: isWide ? 0 : 20
                    }}
                  >
                    <Controller
                      name={'agentCode'}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          title={t('DistributionService:agentCode')}
                          value={field.value ?? ''}
                          labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                        />
                      )}
                    />
                  </View>
                ) : null}
                {taskGroupSelected?.type == 'DS' ? null : (
                  <View
                    style={{
                      flex: 1,
                      width: '100%',
                      marginTop: isWide ? 0 : 20
                    }}
                  >
                    <Text style={[styles.label, { fontSize: isMobile ? 13 : 15 }]}>{t('TaskManagement:Aging')}</Text>
                    <View style={{ justifyContent: 'flex-start' }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ flex: 1 }}>
                          <Controller
                            name={'agingFrom'}
                            control={control}
                            render={({ field }) => <Input {...field} value={field.value ?? ''} inputType="number" />}
                          />
                        </View>
                        <Text style={{ color: '#000000', fontSize: 15, marginHorizontal: 10 }}>~</Text>
                        <View style={{ flex: 1 }}>
                          <Controller
                            name={'agingTo'}
                            control={control}
                            render={({ field }) => <Input {...field} value={field.value ?? ''} inputType="number" />}
                          />
                        </View>
                      </View>
                      <Text style={styles.error}>{errors.agingFrom?.message || errors.agingTo?.message}</Text>
                    </View>
                  </View>
                )}
              </View>
              {taskGroupSelected?.type == 'DS' ? null : (
                <SectionRow sectionStyles={{ marginTop: 20 }}>
                  {![TaskType.IDC].includes(taskGroupSelected?.type as TaskType) && (
                    <SectionCol sectionStyles={styles.col_6}>
                      <Controller
                        name={'paymentMethod'}
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <SelectSearch
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            label={t('TaskManagement:PaymentMethod')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={paymentMethods}
                          />
                        )}
                      />
                    </SectionCol>
                  )}
                  <SectionCol sectionStyles={styles.col_6}>
                    <Controller
                      name={'client'}
                      control={control}
                      render={({ field }) => (
                        <Input
                          title={t('TaskManagement:Client')}
                          {...field}
                          value={field.value ?? ''}
                          labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                        />
                      )}
                    />
                  </SectionCol>
                </SectionRow>
              )}

              {[TaskType.DistributionService, TaskType.PremiumCollection, TaskType.IDC].includes(
                taskGroupSelected?.type as TaskType
              ) ? null : (
                <View style={[{ flexDirection: isWide ? 'row' : 'column', marginTop: 20 }]}>
                  <View
                    style={{
                      flex: 1,
                      width: '100%',
                      marginEnd: isWide ? 30 : 0
                    }}
                  >
                    <Controller
                      name={'submittedBy'}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <Input
                          title={t('TaskManagement:SubmittedBy')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                        />
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      width: '100%'
                    }}
                  >
                    <Controller
                      name={'lastActivity'}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SelectSearch
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label={t('TaskManagement:LastActivity')}
                          options={lastActivityOptions}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                        />
                      )}
                    />
                  </View>
                </View>
              )}
              {[TaskType.DistributionService, TaskType.PremiumCollection, TaskType.IDC].includes(
                taskGroupSelected?.type as TaskType
              ) ? null : (
                <View style={[styles.row, { flexDirection: isWide ? 'row' : 'column', marginTop: 20 }]}>
                  <View
                    style={{
                      flex: 1,
                      width: '100%',
                      marginEnd: isWide ? 30 : 0
                    }}
                  >
                    <Controller
                      name={'AMLScreening'}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SelectSearch
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label={t('TaskManagement:AMLScreening')}
                          options={AMLScreeningOptions}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                        />
                      )}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      width: '100%'
                    }}
                  >
                    <Controller
                      name={'AMLRedflag'}
                      control={control}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <SelectSearch
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label={t('TaskManagement:AMLRedflag')}
                          options={AMLRedflagOptions}
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                        />
                      )}
                    />
                  </View>
                </View>
              )}
              <View style={[styles.row, { flexDirection: isWide ? 'row' : 'column', marginTop: 20 }]}>
                {taskGroupSelected?.type === 'CL' ? (
                  <View
                    style={{
                      flex: 1,
                      width: '50%',
                      marginEnd: isWide ? 30 : 0
                    }}
                  >
                    <Controller
                      name={'claimNumber'}
                      control={control}
                      render={({ field }) => (
                        <Input
                          title={t('TaskManagement:claimNumber')}
                          {...field}
                          value={field.value ?? ''}
                          labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
                        />
                      )}
                    />
                  </View>
                ) : (
                  <View style={{ flex: 1 }} />
                )}
                <View
                  style={{
                    flex: 1,
                    width: '50%',
                    marginTop: isWide ? 0 : 20
                  }}
                ></View>
              </View>
            </ScrollView>
          </View>
          {/* Apply Button */}

          <View
            style={[
              styles.btnGroup,
              { paddingTop: isWide ? 40 : 24 },
              isMobile
                ? {
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,

                    elevation: 5
                  }
                : {}
            ]}
          >
            <RoundedButton
              showBorder
              activeOpacity={0.7}
              text={t('TaskManagement:Clear')}
              textStyle={styles.btnTitle}
              style={{ height: 39, marginEnd: 15 }}
              textColorDisable={'#B0B0B0'}
              textColorEnable={'#ED1B2E'}
              borderColorDisable={'#B0B0B0'}
              borderColorEnable={'#ED1B2E'}
              onPress={onReset}
            />
            <RoundedButton
              filled
              activeOpacity={0.7}
              text={t('TaskManagement:Apply')}
              textStyle={styles.btnTitle}
              style={{ height: 39 }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={onSubmit}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#4F4F4F',
    fontWeight: '600',
    fontSize: 20,
    marginEnd: 16
  },

  breadcumbCotnainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },

  breadcumbItem: {
    fontSize: 14,
    color: '#828282'
  },

  arrowIcon: {
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 25
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },

  row: {
    alignItems: 'flex-end'
  },

  startItem: {
    width: 250
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 2
  },

  error: {
    color: 'red',
    fontSize: 12
  },
  col_6: {
    width: '100%',
    maxWidth: '50%',
    paddingRight: 15,
    paddingLeft: 15
  }
})
