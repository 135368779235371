/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NotificationScreen } from '@pulseops/task'
import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { UserDrawer } from './drawer'
import { HomeScreen } from './HomeScreen'
import { UserStackParamList } from './UserStackParamList'

const Stack = createStackNavigator<UserStackParamList>()

export const UserStack = () => {
  return (
    <Stack.Navigator initialRouteName="HomeScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="UserDrawer" component={UserDrawer} />
      <Stack.Screen name="NotificationScreen" component={NotificationScreen} />
    </Stack.Navigator>
  )
}
