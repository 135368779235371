import React from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { Text, View } from 'react-native'

interface CurrencyProp {
  text: string
  textStyle?: StyleProp<TextStyle>
}

export const CurrencyContainer: React.FC<CurrencyProp> = (props: CurrencyProp) => {
  return (
    <View style={currencyStyles.content}>
      <Text style={[!!props.textStyle && props.textStyle, { fontStyle: 'italic' }]}>{props.text} : VND</Text>
    </View>
  )
}
export const currencyStyles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})
