import { TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Text } from 'react-native-elements'

export const HealthRedeclareration = ({ detail }: { detail?: TaskDetail.HealthRedeclaration }) => {
  const { t } = useTranslation(['requestInfo', 'message'])

  return <Text style={styles.text}>{t('message:MS050272')}</Text>
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 16
  }
})
