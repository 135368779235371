import { PayoutPopup } from './Payout'

export namespace CashOut {
  export interface PaymentOption {
    currency: string
    amount: number
  }

  export interface PolicyOption {
    policyNo: string
    totalPremium: number
    status: string
  }

  export interface BankAccount {
    accountName?: string
    accountNo?: string
    bankName?: string
    branchCode?: string
  }

  export interface Option {
    type: PayoutPopup.PayoutMethods
    paymentOption: PaymentOption
    policy: PolicyOption
    bankAccount?: BankAccount
  }
}
