import { assets } from '@pulseops/common'
import React from 'react'
import { StyleSheet, TouchableOpacity, Text, View } from "react-native"

type OBCallingCustomerBlockProps = {
  isOpenedCallingBlock: boolean
  callTypeName?: string
  onEndCallEvent?: () => void
  onRecheckEndCallEvent?: () => void
  setIsShowCallOutPopup?: (visiable: boolean) => void
}

export const OBCallingAvayaBlock = (props: OBCallingCustomerBlockProps) => {

  return props.isOpenedCallingBlock ? (
    <View style={callingStyles.wrapper}>
      <TouchableOpacity onPress={() => props.setIsShowCallOutPopup && props.setIsShowCallOutPopup(true)}>
        <View style={callingStyles.callingCustomerContainer}>
          <View style={callingStyles.callingCustomerContent}>
            <Text style={callingStyles.callingCustomerTitle}>{props.callTypeName}</Text>
            <Text style={callingStyles.callingCustomerDescription}>Calling</Text>
          </View>
          <View style={callingStyles.callingIconContent}>
            {/* <TouchableOpacity style={callingStyles.callingFirstIcon} onPress={()=> props.onRecheckEndCallEvent && props.onRecheckEndCallEvent()}>
              <assets.OBRecheckEndCall />
            </TouchableOpacity> */}
            <TouchableOpacity onPress={() => props.onEndCallEvent && props.onEndCallEvent()}>
              <assets.OBEndCall />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </View >
  ) : <></>
}
const callingStyles = StyleSheet.create({
  wrapper: {
    position: 'absolute', width: 320, right: 24, bottom: 0, zIndex: 200
  },
  callingCustomerContainer: {
    display: 'flex',
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'center',
    backgroundColor: '#333'
  },
  callingCustomerTitle: {
    color: '#fff',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '600'
  },
  callingCustomerDescription: {
    color: '#fff',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400'
  },
  callingCustomerContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  callingIconContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  callingFirstIcon: {
    marginRight: 12
  }
})