import { FormService, UwDecisionCodeC, UwDecisionCode } from '@pulseops/business/core'
import * as t from 'io-ts'
import { form2 } from '@pulseops/common'
import i18next from 'i18next'
import { pipe } from 'fp-ts/function'
import { Maybe } from '@mxt/zio/codec'

export namespace UnderwritingForm {
  type FLCAmountBrand = {
    readonly FLCAmount: unique symbol
  }
  export type FLCAmount = t.Branded<string, FLCAmountBrand>

  export const compareGreaterThanZero = () =>
    pipe(
      t.string,
      form2.refine(
        (l): l is FLCAmount => Number(l.replace(/,/g, '')) > 0,
        (l) => i18next.t('business:MS000068', { field: i18next.t('business:over_amount_fcl') }),
        'FLCAmount'
      )
    )

  export const compareEqualZero = () =>
    pipe(
      t.string,
      form2.refine(
        (l): l is FLCAmount => Number(l.replace(/,/g, '')) === 0,
        (l) => i18next.t('business:MS000030', { field: i18next.t('business:over_amount_fcl') }),
        'FLCAmount'
      )
    )

  const FCLEmployeeC = t.intersection([
    t.type({
      decision: form2.stringEnum(UwDecisionCode).required,
      comment: form2.string.optional,
      overFclAmount: form2.number.optional
    }),
    t.union([
      t.type({
        overFcl: t.literal(FormService.decisionNo),
        overFclAmount: compareEqualZero()
      }),
      t.type({
        overFcl: t.literal(FormService.decisionYes),
        overFclAmount: compareGreaterThanZero()
      })
    ])
  ])

  export const codec = t.type({
    finalUwDecision: form2.stringEnum(UwDecisionCode).required,
    employees: t.array(FCLEmployeeC)
  })

  export const UwData = t.type({
    decision: UwDecisionCodeC,
    comment: Maybe(t.string),
    overFclAmount: t.number,
    overFcl: t.string
  })

  export type UwData = t.TypeOf<typeof UwData>

  export const DumpData = t.type({
    decision: UwDecisionCodeC,
    comment: Maybe(t.string),
    overFclAmount: t.number,
    overFcl: t.string,
    uuid: t.string
  })

  export type DumpData = t.TypeOf<typeof DumpData>
}
