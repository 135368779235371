import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { isNil, isString, orderBy } from 'lodash'
import { PulseOpsFormat, assets } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { IBGeneralTable } from '../../../../../../common'
import { DetailFundValueData, IBService, InvestmentDataInfo, InvestmentFund } from '../../../../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
interface PanelCProps {
  title: string | React.ReactNode
  children?: React.ReactNode
  fundCode: string
  handleToggle: (fundCode: string) => void
}

const PanelC = (props: PanelCProps) => {
  const [toggle, setToggle] = React.useState<boolean>(false)
  const { title, children, fundCode } = props

  return (
    <View>
      <View style={styles.title}>
        {isString(title) ? (
          <Text style={{ color: '#70777e', fontWeight: 'bold', fontSize: 15 }}>{title}</Text>
        ) : (
          <View style={{ width: '100%' }}>{title}</View>
        )}
        <TouchableOpacity
          onPress={() => {
            props.handleToggle(fundCode)
            setToggle(!toggle)
          }}
        >
          {toggle ? <assets.IBCircleCollapseIcon /> : <assets.IBCircleExpandIcon />}
        </TouchableOpacity>
      </View>
      {toggle ? <View style={{ marginTop: 15 }}>{children}</View> : <></>}
    </View>
  )
}

interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  listFundT25?: InvestmentFund[]
}

export const FundValueInfo = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo, listFundT25 } = props
  const [fundValueDetail, setFundValueDetail] =
    React.useState<{ fundCode: string; listDetail: DetailFundValueData[] }[]>()
  const [loadingByFundCode, setLoadingByFundCode] = React.useState<string[]>([])
  const [loading, bindLoading] = useLoading()

  React.useEffect(() => {
    if (isNil(investmentInfo?.fundValueInfo)) {
      pipe(
        IBService.getInvestmentInfo({ data: { function: '', policies: [`${policyNumber}`] } }),
        ZIO.map((res) => {
          setInvestmentInfo({ ...(investmentInfo as InvestmentDataInfo), fundValueInfo: res })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const getFundValueDetail = (fundCode: string) => {
    if (!fundValueDetail?.some((x) => x.fundCode === fundCode)) {
      setLoadingByFundCode([...loadingByFundCode, fundCode])
      pipe(
        IBService.getDetailFundValue(policyNumber, fundCode),
        ZIO.map((res) => {
          setFundValueDetail([...(fundValueDetail || []), { fundCode: fundCode, listDetail: res.data }])
          setLoadingByFundCode(loadingByFundCode.filter((x) => x !== fundCode))
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const dataTable = [
    {
      label: t('RealUnitsFund'),
      field: 'realUnits'
    },
    { label: t('CutOffDate'), field: 'cutOffDate', format: 'date' },
    { label: t('MoniesDate'), field: 'moniesDate', format: 'date' },
    {
      label: t('FundAmountILP'),
      field: 'fundAmount'
    },
    {
      label: t('CodeInvestment'),
      field: 'code'
    }
  ]

  return (
    <View>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        investmentInfo?.fundValueInfo?.data.policyExtraInfoFunds.map((item) => {
          return (
            <PanelC
              title={
                <View style={styles.title}>
                  <Text style={[styles.textStyle, { color: '#70777e', width: '40%' }]}>{`${item.fundCode} ${
                    i18n.language === 'vi'
                      ? listFundT25?.find((x) => x.fundCode === item.fundCode)?.inBoundNameVN
                      : listFundT25?.find((x) => x.fundCode === item.fundCode)?.fundNameEN
                  }`}</Text>
                  <View style={{ flexDirection: 'row', width: '30%' }}>
                    <Text style={styles.textStyle}>{t('UnitBalanceFund')}:</Text>
                    <Text style={[styles.textStyle, { color: '#70777e' }]}>{item.unitBalance}</Text>
                  </View>
                  <View style={{ flexDirection: 'row', width: '30%' }}>
                    <Text style={styles.textStyle}>{t('EstimateFundValue')}:</Text>
                    <Text style={[styles.textStyle, { color: '#70777e' }]}>
                      {PulseOpsFormat.thousandSepartor(item.estimateValue)}
                    </Text>
                  </View>
                </View>
              }
              children={
                <View style={{ marginBottom: 15 }}>
                  <IBGeneralTable
                    data={
                      orderBy(
                        fundValueDetail
                          ?.find((x) => x.fundCode === item.fundCode)
                          ?.listDetail.map((x) => ({
                            ...x,
                            cutOffDate: moment(x.cutOffDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                            moniesDate: moment(x.moniesDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                            realUnits: x.realUnits === 0 ? '-' : x.realUnits,
                            fundAmount: x.fundAmount === 0 ? '-' : PulseOpsFormat.thousandSepartor(x.fundAmount)
                          })),
                        'moniesDate',
                        'desc'
                      ) || []
                    }
                    dataTable={dataTable}
                    loading={loadingByFundCode.includes(item.fundCode)}
                    autoPaging
                  />
                </View>
              }
              fundCode={item.fundCode}
              handleToggle={getFundValueDetail}
            />
          )
        })
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    flexDirection: 'row',
    marginRight: 10
    // justifyContent: 'space-between'
  },
  textStyle: {
    color: '#58647A',
    fontWeight: '700',
    fontSize: 15,
    marginRight: 5,
    marginBottom: 10
  },
  errorMsgStyle: {
    fontSize: 14,
    color: '#ED1B2E',
    fontStyle: 'italic',
    fontWeight: '400',
    marginBottom: 10
  }
})
