import { Animated, View, StyleSheet } from 'react-native'
import { assets } from '@pulseops/common'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export type PanelItem = {
  id: string
  name: string
  navigator: () => void
}

export type PanelGroupProps = {
  id: string
  name: string
  icon: () => JSX.Element
  children: Array<PanelItem>
}

export type CepPanelProps = {
  AnimatedY?: boolean
}

const MAX_TRANS_Y = 200
const MIN_TRANS_Y = 0
const DURATION = 400

export const CEPSearchPanel = ({ AnimatedY }: CepPanelProps) => {
  const [isHide, setHide] = useState<boolean>(false)
  const transY = useRef(new Animated.Value(200)).current
  const { t } = useTranslation()
  const ref = React.useRef()
  // const [selected, setSelected] = useState<PanelItem>()
  const opacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  })

  const imageOpacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  const toggleView = () => {
    if (!AnimatedY) {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MAX_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(false))
    } else {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MIN_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(true))
    }
  }

  React.useEffect(() => {
    toggleView()
  }, [AnimatedY])

  return (
    <View style={styles.container}>
      <Animated.View
        style={{
          alignSelf: 'center',
          height: transY,
          opacity: imageOpacity
        }}
      >
        <assets.CEPImage />
      </Animated.View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20
  },
  pannel: {
    width: '90%',
    flexDirection: 'row',
    backgroundColor: 'white',
    shadowColor: 'white',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 30,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 20
  },
  pannelBody: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    minWidth: 500
  },
  pannelTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    justifyContent: 'center',
    padding: 40
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    margin: 10
  },
  xBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#E5EAEF',
    alignItems: 'center',
    justifyContent: 'center'
  }
})
