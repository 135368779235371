import { AppContext, assets, changeLanguage, getLanguage } from '@pulseops/common'
import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/core'

export const LanguageTab = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const [language, setLanguage] = React.useState<string>(getLanguage())

  const languageOptions = [
    {
      name: 'Setting:Vietnamese',
      icon: <assets.VietnameseFlagIcon />,
      value: 'vi',
      locale: 'vi-VN'
    },
    {
      name: 'Setting:English',
      icon: <assets.EnglandFlagIcon />,
      value: 'en',
      locale: 'en-US'
    }
  ]

  React.useEffect(() => {
    changeBreadcrumb([
      {
        title: '',
        navigate: () => navigate('HomeScreen')
      },
      { title: t('menu:Settings'), navigate: null },
      { title: t('Setting:Language'), navigate: null }
    ])
  }, [language])

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{t('Setting:Language')}</Text>
      <View style={{ marginTop: 30 }}>
        {languageOptions.map((item, index) => {
          const isActive = getLanguage() === item.value
          return (
            <TouchableOpacity
              activeOpacity={0.7}
              key={index}
              style={styles.option}
              onPress={() => {
                setLanguage(item.value)
                changeLanguage(item.value)
                localStorage.setItem('locale', item.locale)
              }}
            >
              <View style={styles.icon}>{item.icon}</View>
              <Text style={styles.title}>{t(item.name)}</Text>
              {isActive && <assets.CheckedIcon />}
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30
  },

  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },

  option: {
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    overflow: 'hidden',
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9
  },

  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 24,
    marginEnd: 15
  },

  title: {
    color: '#000000',
    fontSize: 15,
    flex: 1
  }
})
