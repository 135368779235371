import { ZIO } from '@mxt/zio'
import { Nullable } from '@mxt/zio/codec'
import { refCache, RefSessionStorage } from '@pulseops/common'
import { flow, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { option } from 'io-ts-types'
import { Lens } from 'monocle-ts'
import { ContractId } from '../general-info/model/contract-id'
import { EmployeeC } from '../general-info/model/employee'
import { PaginationState } from '../service/pagination-state'
import { EcardC } from './model/ecard'
import { EmployeeUwC } from './model/employee-uw'
import { PremiumChargeTotalC } from './model/premium-charge-total'
import { PremiumDecisionC } from './model/premium-decision'
import { StatusContractC } from './model/status-contract'
import { UwDecisionC } from './model/uw-decision'
import { UwEmployeeDecisionC } from './model/uw-employee-decision'
import { VerificationDecisionC } from './model/verification-decision'
import { VerificationDecisionHistory } from './model/verification-decision-history'

const ps = {
  UwDecision: PaginationState.itemC(UwDecisionC, (item) => item.id || ''),
  Employee: PaginationState.itemC(EmployeeC, (item) => item.employeeKey),
  EmployeeUw: PaginationState.itemC(EmployeeUwC, (item) => item.employeeKey),
  EmployeeDecision: PaginationState.itemC(UwEmployeeDecisionC, (item) => item._id || ''),
  PremiumDecision: PaginationState.itemC(PremiumDecisionC, (item) => item.id || ''),
  StatusContract: PaginationState.itemC(StatusContractC, (item) => item.uuid || ''),
  Ecard: PaginationState.itemC(EcardC, (item) => item.id || '')
}

export namespace NbuwState {
  const NbuwContractC = t.type({
    verificationDecision: Nullable(t.array(VerificationDecisionC)),
    uwDecision: ps.UwDecision.codec,
    employee: ps.Employee.codec,
    employeeUw: ps.EmployeeUw.codec,
    employeeDecision: ps.EmployeeDecision.codec,
    premiumDecision: ps.PremiumDecision.codec,
    premiumTotal: Nullable(option(PremiumChargeTotalC)),
    statusContract: ps.StatusContract.codec,
    ecard: ps.Ecard.codec
  })
  type NbuwContract = t.TypeOf<typeof NbuwContractC>

  export const codec = t.record(t.string, Nullable(NbuwContractC), 'BusinessNbuwState')

  const ref = RefSessionStorage.make(codec, {})

  export const cache = refCache(ref)

  export const forContract = (id: ContractId) => {
    const lens = Lens.fromNullableProp<NbuwState>()(ContractId.toString(id), {
      verificationDecision: null,
      uwDecision: ps.UwDecision.initialState,
      employee: ps.Employee.initialState,
      employeeUw: ps.EmployeeUw.initialState,
      employeeDecision: ps.EmployeeDecision.initialState,
      premiumDecision: ps.PremiumDecision.initialState,
      premiumTotal: null,
      statusContract: ps.StatusContract.initialState,
      ecard: ps.Ecard.initialState
    })

    const verificationDecisionLens = lens.compose(Lens.fromProp<NbuwContract>()('verificationDecision'))
    const premiumTotalLens = lens.compose(Lens.fromProp<NbuwContract>()('premiumTotal'))

    return {
      verificationDecision: {
        lens: verificationDecisionLens,
        get: ref.select(verificationDecisionLens.get),
        set: flow(verificationDecisionLens.set, ref.update),
        watchHistory: ref.watch(flow(verificationDecisionLens.get, (l) => VerificationDecisionHistory(l || [])))
      },
      uwDecision: ps.UwDecision.getService(ref, lens.compose(Lens.fromProp<NbuwContract>()('uwDecision'))),
      employee: ps.Employee.getService(ref, lens.compose(Lens.fromProp<NbuwContract>()('employee'))),
      employeeUw: ps.EmployeeUw.getService(ref, lens.compose(Lens.fromProp<NbuwContract>()('employeeUw'))),
      employeeDecision: ps.EmployeeDecision.getService(
        ref,
        lens.compose(Lens.fromProp<NbuwContract>()('employeeDecision'))
      ),
      premiumDecision: ps.PremiumDecision.getService(
        ref,
        lens.compose(Lens.fromProp<NbuwContract>()('premiumDecision'))
      ),
      premiumTotal: {
        lens: premiumTotalLens,
        clear: pipe(premiumTotalLens.set(null), ref.update),
        set: flow(premiumTotalLens.set, ref.update),
        getId: pipe(
          ref.select(premiumTotalLens.get),
          ZIO.map(
            flow(
              O.fromNullable,
              O.flatten,
              O.map((a) => a.id),
              O.toUndefined
            )
          )
        )
      },
      statusContract: ps.StatusContract.getService(ref, lens.compose(Lens.fromProp<NbuwContract>()('statusContract'))),
      ecard: ps.Ecard.getService(ref, lens.compose(Lens.fromProp<NbuwContract>()('ecard')))
    }
  }
}

export type NbuwState = t.TypeOf<typeof NbuwState.codec>
