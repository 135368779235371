import { assets } from '@pulseops/common'
import React from 'react'
import { Modal, StyleSheet, TouchableOpacity, Text, View, StyleProp, ViewStyle, TextStyle } from 'react-native'

export type OBModalAction = {
  text: string
  onClickEvent?: () => void
  disabled?: boolean
  actionStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}


type OBAcceptPopupProp = {
  isVisible: boolean
  modalTitle?: string
  // messageContent?: string
  onCloseEvent?: () => void
  // onSaveEvent?: () => void
  modalContainerStyle?: StyleProp<ViewStyle>
  modalBody?: JSX.Element
  modalActions?: OBModalAction[]
  modalContentStyle?: StyleProp<ViewStyle>
  toastComponent?: JSX.Element
}

export const OBModalPopup = (props: OBAcceptPopupProp) => {
  return (
    <Modal visible={props.isVisible} transparent={true} animationType={'fade'}>
      <View style={acceptStyles.pageContainer}>
        <View style={[acceptStyles.modalContainer, props.modalContainerStyle]}>
          <View style={acceptStyles.modalHeader}>
            <View style={acceptStyles.modalHeaderBlock1}>
              {!!props.modalTitle && <Text style={acceptStyles.modalHeaderText}>{props.modalTitle}</Text>}
            </View>
            <View style={acceptStyles.modalHeaderBlock2}>
              <TouchableOpacity onPress={() => props.onCloseEvent && props.onCloseEvent()}>
                <assets.CloseTaskModalIcon />
              </TouchableOpacity>
            </View>
          </View>
          <View style={[acceptStyles.modalContent, props.modalContentStyle]}>
            {props.modalBody}
          </View>
          <View style={acceptStyles.modalFooter}>
            {props.modalActions && props.modalActions.length > 0 && props.modalActions.map((modalItem) => {
              return (
                <TouchableOpacity disabled={modalItem.disabled} onPress={() => modalItem.onClickEvent && modalItem.onClickEvent()}>
                  <View style={modalItem.actionStyle}>
                    <Text style={modalItem.textStyle}>{modalItem.text}</Text>
                  </View>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
      </View>
      {props.toastComponent}
    </Modal>
  )
}

const acceptStyles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    minWidth: 400,
    minHeight: 150,
    paddingHorizontal: 24,
    paddingVertical: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.08)'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'flex-end'
  },
  modalHeaderBlock1: {
    flex: 90,
    flexDirection: 'row',
    justifyContent: "center"
  },
  modalHeaderBlock2: {
    flex: 10,
    flexDirection: 'row',
    justifyContent: "flex-end"
  },
  modalHeaderText: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#4F4F4F'
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  actionLine: {
    marginRight: 15
  }
})