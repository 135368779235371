import { FundCode } from './FundCode'
import { Locale } from './Locale'
// import i18next from 'i18next';

export namespace FundMapping {
  export interface Fund {
    code: string
    name: string
  }

  export const TargetPremium: Fund[] = [
    {
      code: FundCode.UBL1,
      name: 'fund:PRUlinkBalanced'
    },
    {
      code: FundCode.UEQ1,
      name: 'fund:PRUlinkVNEquity'
    },
    {
      code: FundCode.UFI1,
      name: 'fund:PRUlinkVNFixedInc'
    },
    {
      code: FundCode.UGR1,
      name: 'fund:PRUlinkGrowth'
    },
    {
      code: FundCode.UPS1,
      name: 'fund:PRUlinkPreserver'
    },
    {
      code: FundCode.USB1,
      name: 'fund:PRUlinkStable'
    },
    {
      code: FundCode.USE1,
      name: 'fund:PrulinkVietnamSustainableDevelopmentEquityFund'
    }
  ]

  export const TopUpAcc: Fund[] = [
    {
      code: FundCode.UBL2,
      name: 'fund:PRUlinkBalancedTopup'
    },
    {
      code: FundCode.UEQ2,
      name: 'fund:PRUlinkVNEquityTopup'
    },
    {
      code: FundCode.UFI2,
      name: 'fund:PRUlinkVNFixedIncTopup'
    },
    {
      code: FundCode.UGR2,
      name: 'fund:PRUlinkGrowthTopup'
    },
    {
      code: FundCode.UPS2,
      name: 'fund:PRUlinkPreserverTopup'
    },
    {
      code: FundCode.USB2,
      name: 'fund:PRUlinkStableTopup'
    },
    {
      code: FundCode.USE2,
      name: 'fund:PrulinkVietnamSustainableDevelopmentEquityFundTopup'
    }
  ]

  // const PayoutMethodsMap = new Map([
  //   [PayoutMethods.PAYPREMIUM, `:@@PayPremium:Pay premium`],
  //   [PayoutMethods.PREMIUM, `:@@PayPremium:Pay premium`],
  //   [PayoutMethods.PAYLOAN, `:@@RepayLoan:Repay loan`],
  //   [PayoutMethods.OTHER, `:@@OtherPremium:Other premium (Reinstate, Top-up, Alteration)`],
  //   [PayoutMethods.CASHLESS, `:@@Cashless:Cashless`],
  //   [PayoutMethods.BANKTRANSFER, `:@@BankTransfer:Bank transfer`],
  //   [PayoutMethods.PAID_AT_BANK, `:@@PaidAtBank:Paid at bank`],
  //   [PayoutMethods.REPAY_OPL, `:@@RepayOPL:Repay OPL`],
  //   [PayoutMethods.REPAY_APL, `:@@RepayAPL:Repay APL`],
  //   [PayoutMethods.REPAY_OPL_APL, `:@@RepayOplApl:Repay APL & OPL`],
  //   [PayoutMethods.MOMO, `:@@EWalletMomo:eWallet Momo`],
  //   [PayoutMethods.TOPUP, `:@@TopUp:Top up`],
  //   [PayoutMethods.REINSTATEMENT, `:@@Reinstatement:Reinstatement`]
  // ])
  // export const mapPayoutMethods = (method: string) => PayoutMethodsMap.get(method as PayoutMethods) ?? '-'

  export const getPayoudMethod =
    (
      payoutMethods: {
        typeEnum: string
        nameEn: string
        nameVi: string
      }[],
      locale: string
    ) =>
    (method: string) => {
      const found = payoutMethods.find(({ typeEnum }) => typeEnum === method)
      if (found) {
        return locale === Locale.en ? found.nameEn : found.nameVi
      }
      return '-'
    }

  export const TotalFunds = TargetPremium.concat(TopUpAcc)

  export const mapFunds = new Map<FundCode | string, string>(TotalFunds.map((t) => [t.code, t.name]))

  export type CheckAccountFn = (code: string) => boolean

  export const isValidAcc: CheckAccountFn = (code: string) => !!mapFunds.get(code)

  export const getFunds = (key?: string) => (key ? mapFunds.get(key) ?? '-' : '-')

  export const isTopUpAcc: CheckAccountFn = (code: string) =>
    !!new Map<FundCode | string, string>(TopUpAcc.map((t) => [t.code, t.name])).get(code)

  export const isPremiumAcc: CheckAccountFn = (code: string) =>
    !!new Map<FundCode | string, string>(TargetPremium.map((t) => [t.code, t.name])).get(code)
  // !!new Map<FundCode | string, string>(TargetPremium.slice(0, 1).map((t) => [t.code, t.name])).get(code)
}
