import { SelectOption } from '@pulseops/common'

export const COLORS = {
  SNOW: '#fafafa',
  HOWKES_BLUE: '#d3dce6',
  ALIZARIN: '#ED1B2E',
  WHITE: '#ffff',
  ROLLING_STONE: '#70777e',
  BLACK: '#000000',
  MYSTIC: '#E5EAEF',
  BISCAY: '#1B365D'
}

export interface TextCustomI {
  textColor?: string
  alignText?: string
  fontSize?: string
  fontWeight?: string
  fontStyle?: 'italic' | 'normal' | 'oblique'
}

export enum TypeFieldEnum {
  TEXTBOX = 'TEXTBOX',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  DATE_PICKER = 'DATE_PICKER'
}
export type typeField = `${TypeFieldEnum}`

export enum HiddenEnum {
  CHECK_BOX = 'CHECK_BOX',
  RADIO = 'RADIO'
}
export type typeHiddenEnum = `${HiddenEnum}`

enum TypeGroupNameEnum {
  INFO_CHANGE_CLIENT = 'info_change_client',
  INFO_CHANGE_OCCUPATION = 'info_change_occupation'
}
export type typeGroupName = `${TypeGroupNameEnum}_group`

export type typeNameField =
  | 'selectClient'
  | 'occupation'
  | 'jobDescription'
  | 'jobTitle'
  | 'occupationClass'
  | 'companyName'
  | 'companyAddress'
  | 'salary'

export type FieldsType<TN> = {
  type: typeField
  formName: TN
  required: boolean
  label: string
  options?: SelectOption[]
  placeholder: string
  popupIcon?: string
  editable: boolean
  rules?: object
}

export type GroupsViewType<GN, TN> = {
  groupName: GN
  isVisible: boolean
  fields: FieldsType<TN>[]
  typeHidden?: typeHiddenEnum
  labelGroup?: string
  isBorder?: boolean
}

export enum STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type StatusType = `${STATUS}`

export const groupsViewsConst = (t: any): GroupsViewType<typeGroupName, typeNameField>[] => [
  {
    groupName: 'info_change_client_group',
    labelGroup: t('GROUPS_VIEWS.LABEL_GROUP.INFO_CHANGE_CLIENT'),
    isVisible: true,
    typeHidden: HiddenEnum['CHECK_BOX'],
    fields: [
      {
        formName: 'selectClient',
        type: TypeFieldEnum['DROPDOWN_LIST'],
        placeholder: '-',
        label: t('GROUPS_VIEWS.FIELDS.SELECT_CLIENT'),
        required: true,
        editable: true,
        options: [],
        rules: {
          required: {
            value: true,
            message: `${t('message:MS020009', { field: t('GROUPS_VIEWS.FIELDS.SELECT_CLIENT') })}`
          }
        }
      }
    ]
  },
  {
    groupName: 'info_change_occupation_group',
    isVisible: true,
    typeHidden: HiddenEnum['CHECK_BOX'],
    labelGroup: t('GROUPS_VIEWS.LABEL_GROUP.CHANGE_OCCUPATION'),
    isBorder: true,
    fields: [
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'occupation',
        label: t('GROUPS_VIEWS.FIELDS.OCCUPATION'),
        required: true,
        editable: true,
        placeholder: '-',
        rules: {
          required: {
            value: true,
            message: `${t('message:MS020009', { field: t('GROUPS_VIEWS.FIELDS.OCCUPATION') })}`
          },

          validate: (value: string) => {
            if (value.length > 100) return 'Không đúng format'
            return true
          }
        }
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'jobDescription',
        label: t('GROUPS_VIEWS.FIELDS.JOB_DESCRIPTION'),
        required: false,
        editable: true,
        placeholder: '-',
        rules: {}
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'jobTitle',
        label: t('GROUPS_VIEWS.FIELDS.JOB_TITLE'),
        required: false,
        placeholder: '-',
        editable: true,
        rules: {}
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'occupationClass',
        label: t('GROUPS_VIEWS.FIELDS.OCCUPATION_CLASS'),
        required: false,
        editable: false,
        placeholder: '-',
        rules: {}
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'companyName',
        label: t('GROUPS_VIEWS.FIELDS.COMPANY_NAME'),
        required: false,
        editable: false,
        placeholder: '-',
        rules: {}
      },
      {
        type: TypeFieldEnum['TEXTBOX'],
        formName: 'companyAddress',
        label: t('GROUPS_VIEWS.FIELDS.COMPANY_ADDRESS'),
        required: false,
        editable: false,
        placeholder: '-',
        rules: {}
      },
      {
        type: TypeFieldEnum['DROPDOWN_LIST'],
        formName: 'salary',
        label: t('GROUPS_VIEWS.FIELDS.SALARY'),
        required: false,
        editable: true,
        placeholder: '-',
        options: [],
        rules: {}
      }
    ]
  }
]

export type valuesFormType = {
  key: typeNameField
  value: string
}
