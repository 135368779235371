import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.FATCA_DECLARATION)
    }),
    codec
  ])

export const FatcaDeclaration = Base(
  t.type({
    source: t.string
  })
)
export type FatcaDeclaration = t.TypeOf<typeof FatcaDeclaration>
