export namespace OBCallOutPopupConst {
  export interface CallReceiverInfo {
    nameVi: string
    nameEn: string
    code: string
  }
  export const CallReceiverList: CallReceiverInfo[] = [
    { nameVi: 'N/A', nameEn: 'N/A', code: 'N/A' },
    { nameVi: 'Chủ Hợp Đồng', nameEn: 'Policy Owner', code: 'PO' },
    { nameVi: 'Non-Policy owner', nameEn: 'Non-Policy owner', code: 'Non-PO' },
    { nameVi: 'Người Thân', nameEn: 'Relatives', code: 'Relatives' }
  ]

  export const DoNotContactList: CallReceiverInfo[] = [
    { nameVi: 'ALL', nameEn: 'ALL', code: '' },
    { nameVi: 'WCC', nameEn: 'WCC', code: 'WCC' },
    { nameVi: 'SQCB', nameEn: 'SQCB', code: 'SQCB' },
    { nameVi: 'Premium Reminder', nameEn: 'Premium Reminder', code: 'RMD' },
    { nameVi: 'Auto Premium Loan', nameEn: 'Auto Premium Loan', code: 'APL' },
    { nameVi: 'Outstanding Premium Loan', nameEn: 'Outstanding Premium Loan', code: 'OPL' },
    { nameVi: 'Premium Overdue', nameEn: 'Premium Overdue', code: 'PO' },
    { nameVi: 'Care', nameEn: 'Care', code: 'CARE' },
    { nameVi: 'Maturity', nameEn: 'Maturity', code: 'MA' },
    { nameVi: 'Campaign', nameEn: 'Campaign', code: 'CMB' }
  ]
  export const HintList: CallReceiverInfo[] = [
    { nameVi: 'N/A', nameEn: 'N/A', code: 'H1' },
    { nameVi: 'Nghi ngờ', nameEn: 'Suspicious', code: 'H2' },
    { nameVi: 'Ép mua', nameEn: 'Forced purchase', code: 'H3' }
  ]
  export const SendingTypeList: CallReceiverInfo[] = [
    { nameVi: 'ZNS', nameEn: 'ZNS', code: 'ZNS' },
    { nameVi: 'Email', nameEn: 'Email', code: 'EMAIL' }
    // { nameVi: 'SMS', nameEn: 'SMS', code: 'SMS' },
    // { nameVi: 'NULL', nameEn: 'NULL', code: 'NONE' },
  ]
  export const CallingMethodConst: CallReceiverInfo[] = [
    { nameVi: 'None', nameEn: 'None', code: 'CM01' },
    { nameVi: 'Điện Thoại Di Động', nameEn: 'Mobile phone', code: 'CM02' },
    { nameVi: 'Điện Thoại Nhà', nameEn: 'Home phone', code: 'CM03' },
    { nameVi: 'Điện Thoại Cơ Quan', nameEn: 'Company phone', code: 'CM04' },
    { nameVi: 'Số Điện Thoại khác', nameEn: 'Call other', code: 'CM05' }
  ]

  export const A7S2List = [
    { code: 'PO', flag: 'Y' },
    { code: 'RELATIVES', flag: 'N' },
    { code: 'NON_PO', flag: 'N' },
    { code: 'NA', flag: 'N' }
  ]
}
