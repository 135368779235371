import { Task, ZIO } from '@mxt/zio'
import { HttpClient } from '@mxt/zio-http-client'
import { Ref } from '@mxt/zio/stream'
import { BackOfficePermission, POApi, PulseOpsFormat, SubmissionService } from '@pulseops/common'
import { Either } from 'fp-ts/Either'
import { constVoid } from 'fp-ts/function'
import { pipe } from 'fp-ts/lib/function'
import {
  BOBenefitsRiders,
  BOEmployee,
  BOGetAllC,
  BOModelMapping,
  BOPremiumDecisionModel,
  BOPremiumDecisionModelC,
  BOUWModel,
  BOUWModelC,
  BOUWModelEmployee,
  BOUWModelEmployeeC,
  BOVerifyModel,
  BOVerifyModelC,
  Dependent,
  UWFCLData
} from './model'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import * as E from 'fp-ts/Either'

const tempRoles = [BackOfficePermission.List.View]

export namespace BOService {
  const ref = Ref.make<O.Option<BOModelMapping>>(O.none)

  export const setDetail = (data: BOModelMapping) => ref.set(O.some(data))
  export const getDetail = ref.get

  const permission = Ref.make<O.Option<string[]>>(O.none)
  export const setPermission = (data: string[]) => permission.set(O.some(data.concat(tempRoles)))
  export const getPermission = permission.get

  export const getBOList = (
    size: number,
    page: number,
    sort?: string,
    order?: 'asc' | 'desc',
    quotationNo?: string,
    status?: string
  ): Task<BOModelMapping[]> =>
    pipe(
      POApi.getConfig({ params: { size, page, sort, order, quotationNo, status } })('business-pulse/api/v1/quotations')(
        BOGetAllC
      ),
      ZIO.flatMap((res) =>
        pipe(
          SubmissionService.getAgents(res.data.map((x) => x.agentCode)),
          ZIO.map((agent) => {
            return {
              BOdata: res,
              AgentData: agent
            }
          })
        )
      ),
      ZIO.map(({ BOdata, AgentData }) =>
        BOdata.data.map((x, index) => {
          const companyHeadquaterAddress = x.company.addresses.find((x) => x.addressType === 'BUSINESS')
          const companyContactAddress = x.company.addresses.find((x) => x.addressType === 'CONTACT')
          const companyRep = x.companyPeople.find((x) => x.companyRole === 'LEGAL_REPRESENTATIVE')
          const companyRepAddressPermanent = companyRep?.addresses.find((x) => x.addressType === 'PERMANENT')
          const companyRepAddressCurr = companyRep?.addresses.find((x) => x.addressType === 'RESIDENTIAL')
          const companyRepAddressOversee = companyRep?.addresses.find((x) => x.addressType === 'OVERSEA')
          const companyBenInfo = x.companyPeople.filter((x) => x.companyRole === 'BENEFICIAL_OWNER')
          const overFCLEmployee = (x.employees || []).filter(
            (y) => y.warnings.findIndex((z) => z.type === 'FCL_EXCEED') >= 0
          )
          const employeeMapper = (list: BOEmployee[]) =>
            list.map((y) => ({
              policyNum: y.quotationId,
              fullName: `${y.surname} ${y.firstName}`,
              employeeCode: y.employeeNumber,
              occupation: y.occupationClassCode,
              gender: y.gender,
              mobile: y.phoneNo,
              email: y.emailAddress,
              nationID: y.nationalId,
              nationIDCreatedDate: '-',
              nationIDCreatedLocation: '-',
              dob: y.dob,
              maritalStatus: '-',
              nationality: y.nationality,
              otherNationality: '-',
              visa: '-',
              addressPerm: y.addresses.find((z) => z.addressType === 'PERMANENT')?.address || '-',
              addressCurr: y.addresses.find((z) => z.addressType === 'RESIDENTIAL')?.address || '-',
              addressOversea: y.addresses.find((z) => z.addressType === 'OVERSEA')?.address || '-',
              benefitGroup: y.benefitClassId,
              bankAccont: '-',
              bankName: '-',
              bankBranch: '-',
              AML: '-',
              overFCL: PulseOpsFormat.thousandSepartor(
                Number(y.warnings.find((z) => z.type === 'FCL_EXCEED')?.remark.substring(1))
              )
            }))

          return {
            policyNum: x.quotationNo,
            proposalNum: '-',
            effectiveDate: PulseOpsFormat.dateStrWF(x.effectiveDate, 'DD/MM/YYYY'),
            createdDate: PulseOpsFormat.dateStrWF(x.createdAt, 'DD/MM/YYYY'),
            duePremiumDate: '-', // Kỳ hạn đóng phí
            status: x.status,
            company: {
              companyName: x.company.companyName,
              companyEmail: x.company.contactEmail,
              headquaterAddress: companyHeadquaterAddress?.address || '-',
              headquaterCountry: companyHeadquaterAddress?.country || '-',
              contactAddress: companyContactAddress?.address || '-',
              contactCountry: companyContactAddress?.country || '-',
              mobile: x.company.contactNo,
              email: x.company.contactEmail,
              companyBrNo: x.companyBrNo || '-',
              registrationDate: x.company.registrationDate
                ? PulseOpsFormat.dateStrWF(x.company.registrationDate, 'DD/MM/YYYY')
                : '-',
              registrationPlace: x.company.registrationPlace,
              businessNature: x.company.businessNature,
              legalRepresentative: {
                repName: companyRep?.fullName || '-',
                repTitle: '-',
                nationID: companyRep?.idNo || '-',
                nationIDCreatedDate: '-',
                nationIDCreatedLocation: '-',
                repDOB: PulseOpsFormat.dateStrWF(companyRep?.dob || '-', 'DD/MM/YYYY'),
                repGender: companyRep?.gender || '-',
                repNation: companyRep?.nationality || '-',
                repOtherNation: companyRep?.otherNationality || '-',
                repVISA: '-',
                repPhone: '-',
                repEmail: '-',
                repAddress: companyRepAddressPermanent?.address || '-',
                repAddressNation: companyRepAddressPermanent?.country || '-',
                repAddressCurr: companyRepAddressCurr?.address || '-',
                repAddressCurrNation: companyRepAddressCurr?.country || '-',
                repAddress1: companyRepAddressOversee?.address || '-',
                repAddress1Nation: companyRepAddressOversee?.country || '-'
              },
              USrelated: '-',
              RegulatedStockSxchange: '-',
              contactPerson: {
                benRepName: x.company.contactPerson,
                benRepTitle: x.company.position,
                benRepNationID: x.company.contactIdNo,
                benRepPhone: x.company.contactNo,
                benRepEmail: x.company.contactEmail
              },
              beneficalOwner: companyBenInfo.map((y) => ({
                fullName: y.fullName,
                dob: PulseOpsFormat.dateStrWF(y.dob || '-', 'DD/MM/YYYY'),
                gender: y.gender,
                nationalId: y.idNo,
                nationalIdIssueDate: '-',
                nationalIdIssuePlace: '-',
                nationality: y.nationality,
                otherNationality: y.otherNationality,
                visa: '-',
                addressPerm: y.addresses.find((z) => z.addressType === 'PERMANENT')?.address || '-',
                addressCurr: y.addresses.find((z) => z.addressType === 'RESIDENTIAL')?.address || '-',
                addressOversea: y.addresses.find((z) => z.addressType === 'OVERSEA')?.address || '-',
                position: y.occupationClass || '',
                mobile: '-',
                capitalContribute: '-'
              }))
            },
            benefits: {
              groupBasic: x.benefits.map((y) => ({
                classId: y.classId,
                name: y.basicPlan?.benefitType || '-',
                region: y.basicPlan?.geographicalCover || '-',
                amount: y.basicPlan ? PulseOpsFormat.thousandSepartor(y.basicPlan.sumOfAssured) : '-'
              })),
              riders: x.benefits.reduce(
                (ans, y) => [
                  ...ans,
                  ...(y.riders
                    ? y.riders.map((z) => ({
                        classId: y.classId,
                        name: z.riderId,
                        region: z.geographicalCover,
                        benefit: '-',
                        program: z.benefitAttributes.planName || '-',
                        hospitalized: z.benefitAttributes.inPatient || '-',
                        unHospitalized: z.benefitAttributes.outPatient || '-',
                        pregnancy: z.benefitAttributes.maternity || '-'
                      }))
                    : new Array<BOBenefitsRiders>())
                ],
                new Array<BOBenefitsRiders>()
              )
            },
            employees: employeeMapper(x.employees || []),
            employeesOverFCL: employeeMapper(overFCLEmployee),
            dependents: (x.employees || []).reduce(
              (ans, y) => [
                ...ans,
                ...y.dependents.map((z: any) => ({
                  fullName: `${z.surname} ${z.firstName}`,
                  agentCode: x.agentCode,
                  employeeName: `${y.surname} ${y.firstName}`,
                  employeeCode: y.employeeNumber,
                  occupation: y.occupationClassCode,
                  mobile: y.phoneNo,
                  email: y.emailAddress,
                  nationID: y.nationalId,
                  nationIDCreatedDate: '-',
                  nationIDCreatedLocation: '-',
                  dob: PulseOpsFormat.dateStrWF(y.dob || '-', 'DD/MM/YYYY'),
                  maritalStatus: '-',
                  nationality: y.nationality,
                  otherNationality: '-',
                  visa: '-',
                  addressPerm: y.addresses.find((z) => z.addressType === 'PERMANENT')?.address || '-',
                  addressCurr: y.addresses.find((z) => z.addressType === 'RESIDENTIAL')?.address || '-',
                  addressOversea: y.addresses.find((z) => z.addressType === 'OVERSEA')?.address || '-',
                  benefitGroup: y.benefitClassId,
                  relationship: z.relationship,
                  AML: '-'
                }))
              ],
              new Array<Dependent>()
            ),
            agent: {
              agentCode: x.agentCode,
              agentname: `${AgentData[index].surName} ${AgentData[index].firstName}`,
              agentEmail: AgentData[index].email || '-',
              agentMobile: AgentData[index].mobilePhone || '-'
            }
          }
        })
      )
    )

  export const getUWDecisionList = (
    quotationNo: string,
    page: number,
    size: number,
    sort?: string,
    order?: string
  ): Task<BOUWModel> =>
    pipe(
      POApi.getConfig({ params: { page, size, sort, order, quotationNo } })('business-pulse/api/v1/uw-decision')(
        BOUWModelC
      )
    )
  export const getUWEmployeeList = (
    quotationNo: string,
    page: number,
    size: number,
    sort?: string,
    order?: string
  ): Task<BOUWModelEmployee> =>
    pipe(
      POApi.getConfig({ params: { page, size, sort, order, quotationNo } })(
        'business-pulse//api/v1/uw-decision-employees'
      )(BOUWModelEmployeeC)
    )

  export const addUWList = (
    quotationNo: string,
    finalUwDecision: string,
    createdBy: string,
    employeeeUWList: UWFCLData[]
  ): Task<void> =>
    pipe(
      ZIO.zipPar(
        POApi.post('business-pulse/api/v1/add-uw-decision')(t.unknown)({ quotationNo, finalUwDecision, createdBy }),
        POApi.post('business-pulse/api/v1/add-uw-decision-employees')(t.unknown)({
          employeeeUWList: employeeeUWList.map((x) => ({
            firstName: x.firstName,
            surname: x.surname,
            nationalId: x.nationalId,
            nationality: x.nationality,
            overFLCAmount: PulseOpsFormat.thousandSepartorReverse(x.overFCL),
            occupationClassCode: x.occupationClassCode,
            dob: x.dob,
            gender: x.gender,
            phoneNo: x.phoneNo,
            emailAddress: x.emailAddress,
            benefitClassId: x.benefitClassId,
            employeeNumber: x.employeeNumber,
            quotationId: x.quotationId,
            quotationNo,
            uwDecision: x.uwDecision?.value || '',
            createdBy,
            comment: x.comment
          }))
        })
      ),
      ZIO.asVoid
    )

  export const getVerifyList = (
    quotationNo: string,
    page: number,
    size: number,
    sort?: string,
    order?: string
  ): Task<BOVerifyModel> =>
    pipe(
      POApi.getConfig({ params: { page, size, sort, order, quotationNo } })(
        'business-pulse/api/v1/verification-decision'
      )(BOVerifyModelC)
    )
  interface CheckListData {
    checklistName: string
    decision: boolean | null
    note: string
    status: string
    requestDate: string
    completeDate: string
  }

  export const addVerifyList = (quotationNo: string, createdBy: string, checkList: CheckListData[]): Task<void> =>
    pipe(
      POApi.post('business-pulse/api/v1/add-verification-decision')(t.unknown)({
        checkList: checkList.map((x) => ({ ...x, createdBy, quotationNo }))
      }),
      ZIO.asVoid
    )
  export const getPremiumDecisionList = (
    quotationNo: string,
    page: number,
    size: number,
    sort?: string,
    order?: string
  ): Task<BOPremiumDecisionModel> =>
    pipe(
      POApi.getConfig({ params: { page, size, sort, order, quotationNo } })('business-pulse/api/v1/premium-decision')(
        BOPremiumDecisionModelC
      )
    )

  export const addPremiumDecisionList = (
    quotationNo: string,
    createdBy: string,
    checkList: CheckListData[]
  ): Task<{ test: string }> =>
    pipe(
      POApi.post('business-pulse/api/v1/add-premium-decision')(t.unknown)({
        checkList: checkList.map((x) => ({ ...x, createdBy, quotationNo }))
      }),
      ZIO.map((_) => ({ test: 'test' }))
    )

  export type ImportError = { tag: 'Duplicated' } | { tag: 'Fail' }

  export const importContractInfo = (file: File): Task<Either<ImportError, void>> => {
    const formData = new FormData()
    formData.append('file', file)

    return pipe(
      POApi.post('business-pulse/api/v1/contract-info/upload', {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })(t.unknown)(formData),
      ZIO.foldM(
        (err) => {
          if (HttpClient.isAxiosError(err.source) && err.source.response?.status === 400) {
            return ZIO.succeed(
              E.left({
                tag: 'Fail'
              })
            )
          }
          return ZIO.fail(err)
        },
        () => ZIO.succeed(E.right(constVoid()))
      )
    )
  }
}
