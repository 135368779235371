import moment from 'moment'
import { PulseOpsFormat } from '../../../Formatter'
import { GeneralData } from '../../GeneralService'
import { BenIDCard } from '../ben.enum'
import { Customer } from '../Client'
import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { getGivenName, getSurname } from './../../format'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
export interface Duplication {
  clientName: string
  clientNum: string
  relationship: string | null | undefined
}
export interface ChangeClientInfo {
  tag: TransactionType.CHANGE_CLIENT_INFO | TransactionType.CHANGE_NATIONAL_ID_CARD
  source: SourceType
  clientName: string
  targetName: string
  idType: string
  idNumber: string
  nationality: string
  clientNumber: string
  surname: string
  givenName: string
  dob: string
  age: number | null
  gender: string
  email: string
  mobilePhone: string
  policyNo: string
  issueBy: string
  issuedDate: string
  fatca: string
  occupation?: {
    isChangeOccupation: boolean
    occupation: string
    occupationCode?: string
    jobDescription: string
    jobTitle: string
    companyName: string
    companyAddress: string
    salaryAvg: string // Table TV847 00->05
  }
  dupPhone: Duplication[]
  dupEmail: Duplication[]
  countryName?: string
  city?: string
  district?: string
  ward?: string
  address?: string
  foreignAddress?: string
  foreignCountry?: string
  nationality2?: string
  districtName?: string
  wardName?: string
  taxResidencyCountry?: string
}

export const ChangeClientInfo = (
  detail: api.ChangeClientInfo,
  customer: Customer,
  countries: GeneralData[],
  provinces: {
    code: string
    name: string
    bcCode: string
  }[],
  occupation?: GeneralData[],
  target?: Customer
): ChangeClientInfo => {
  const gender = customer.body.sex || '-'
  const surname = customer.body.surName
  const givenName = customer.body.firstName
  const nationality = countries.find((x) => x.code === customer.body.nationality)?.name || '-'
  const dob = customer.body.dob ? customer.body.dob : '-'
  const age = moment().diff(dob, 'years')
  const phone = customer.body.mobilePhone ? customer.body.mobilePhone : '-'
  const phoneFormat =
    (customer.body.nationality === 'VN' || customer.body.nationality === 'VIETNAM') && phone !== '-'
      ? `+84 ${phone}`
      : phone
  const fatca = customer.body.attributesExt.FATCA_TAX_DECLARE ?? ''
  const email = customer.body.email

  switch (detail.source) {
    case SourceType.ZALO:
    case SourceType.LANDING_PAGE:
    case SourceType.EKIOSK:
      const zeData = detail.payload.body
      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: customer.body.name,
        targetName: customer.body.name,
        idType: zeData.externalIds?.NATIONAL_ID ? 'NationalID' : '-',
        idNumber: zeData.externalIds && zeData.externalIds.NATIONAL_ID ? zeData.externalIds.NATIONAL_ID : '',
        nationality: detail.payload.body.nationality ?? nationality,
        clientNumber: customer.body.clientId,
        surname: detail.payload.body.name ? getSurname(detail.payload.body.name) : surname,
        givenName: detail.payload.body.name ? getGivenName(detail.payload.body.name) : givenName,
        dob: detail.payload.body.dob
          ? moment(detail.payload.body.dob, 'DD/MM/YYYY', true).isValid()
            ? detail.payload.body.dob
            : moment(detail.payload.body.dob).format('DD/MM/YYYY')
          : moment(dob).format('DD/MM/YYYY'),
        age: detail.payload.body.dob ? moment().diff(detail.payload.body.dob, 'years') : age,
        gender,
        email: customer.body.email,
        mobilePhone: phoneFormat,
        policyNo: '-',
        issueBy: detail.payload.body.attributes?.LOCATION_DESC ?? detail.payload.body.attributesExt?.issuedBy ?? '',
        issuedDate: moment(detail.payload.body.attributesExt?.issuedDate, 'DD/MM/YYYY', true).isValid()
          ? detail.payload.body.attributesExt?.issuedDate ?? '-'
          : moment(detail.payload.body.attributesExt?.issuedDate).format('DD/MM/YYYY') ?? '-',
        fatca,
        dupPhone: [],
        dupEmail: []
      }
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
    case SourceType.PULSE:
      const pulseData = detail.payload.body
      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: customer.body.name,
        targetName: customer.body.name,
        idType: (pulseData?.externalIds?.idType as BenIDCard) ?? '-',
        idNumber: pulseData?.externalIds?.idNumber ?? '-',
        nationality,
        clientNumber: customer.body.clientId, // policyNum
        surname: detail.payload.body.fullName ? getSurname(detail.payload.body.fullName) : surname,
        givenName: detail.payload.body.fullName ? getGivenName(detail.payload.body.fullName) : givenName,
        dob: !isNaN(Date.parse(pulseData?.dob || '-'))
          ? PulseOpsFormat.dateWF(pulseData?.dob || '-', 'YYYY-MM-DD', 'DD/MM/YYYY')
          : PulseOpsFormat.dateWF(dob, 'YYYY-MM-DD', 'DD/MM/YYYY'),
        age: detail.payload.body.dob ? moment().diff(detail.payload.body.dob, 'years') : age,
        gender,
        email,
        mobilePhone: phoneFormat,
        policyNo: detail.payload.body.policyNo || '-',
        issueBy: provinces.find((p) => p.code === pulseData?.externalIds?.issuedBy)?.name
          ? provinces.find((p) => p.code === pulseData?.externalIds?.issuedBy)?.name ?? '-'
          : pulseData?.externalIds?.issuedBy ?? '-',
        fatca,
        issuedDate: !!pulseData.externalIds?.issuedDate
          ? moment(pulseData.externalIds?.issuedDate).format('DD/MM/YYYY')
          : '-',
        dupPhone: [],
        dupEmail: []
      }

    case SourceType.PRUONLINE:
      const res = detail.payload.body
      const pruonlinePhone = ''
      const pruonlineEmail = ''
      const pruonlineGender = ''
      const pruonlineNationality = ''
      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: customer.body.name,
        targetName: customer.body.name,
        idType: res.externalIds?.BIRTH_CERTIFICATE
          ? 'Birth Certificate'
          : res.externalIds?.NATIONAL_ID
            ? 'National ID'
            : '',
        idNumber: res.externalIds
          ? res.externalIds.BIRTH_CERTIFICATE
            ? res.externalIds.BIRTH_CERTIFICATE
            : res.externalIds.NATIONAL_ID ?? ''
          : '',
        nationality: pruonlineNationality,
        clientNumber: customer.body.clientId, // policyNum
        surname: res.name ? getSurname(res.name) : surname,
        givenName: res.name ? getGivenName(res.name) : givenName,
        dob: res.dob ? PulseOpsFormat.dateWF(res.dob, 'YYYY-MM-DD', 'DD/MM/YYYY') : '-',
        age: res.dob ? PulseOpsFormat.getAgeFromDate(res.dob, 'YYYYMMDD') : age,
        gender: pruonlineGender,
        email: pruonlineEmail,
        mobilePhone: pruonlinePhone,
        policyNo: '-',
        issueBy: '',
        issuedDate: '',
        fatca,
        dupPhone: [],
        dupEmail: []
      }

    case SourceType.PULSE4OPS:
      const p4ops = detail.payload.body
      const isChangeNationalIDCard = detail.transactionType === TransactionType.CHANGE_NATIONAL_ID_CARD
      const dupMapper = (x: Duplication[] | undefined | null): Duplication[] =>
        x?.map((x) => ({
          clientNum: x.clientNum || '-',
          clientName: x.clientName || '-',
          relationship: x.relationship || '-'
        })) || []
      const targetName = (target && target.body.name) || '-'

      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: targetName,
        targetName,
        idType: (p4ops.attributesExt?.idType as BenIDCard) ?? '-',
        idNumber: p4ops.attributesExt?.idNumber ?? '-',
        nationality: countries.find((x) => x.code === p4ops.nationality)?.name || '-',
        clientNumber: p4ops.attributesExt?.clientNum ?? '-', //get clientId cua con
        surname: p4ops.surName ?? '-',
        givenName: p4ops.firstName ?? '-',
        dob: !isChangeNationalIDCard
          ? PulseOpsFormat.dateWF(p4ops.dob || '-', 'YYYYMMDD', 'DD/MM/YYYY')
          : p4ops?.dob
          ? moment(p4ops?.dob, 'DD/MM/YYYY', true).isValid()
            ? p4ops?.dob
            : moment(p4ops?.dob || '-').format('DD/MM/YYYY')
          : '-',

        age: PulseOpsFormat.getAgeFromDate(p4ops.dob, 'YYYYMMDD'),
        gender,
        email: !isChangeNationalIDCard
          ? (p4ops?.contactDetails?.email?.value ?? customer?.body?.email) || '-'
          : customer.body.email ?? '-',

        mobilePhone: !isChangeNationalIDCard
          ? PulseOpsFormat.phoneNumber(
              p4ops?.contactDetails?.phone?.value,
              p4ops?.contactDetails?.phone?.countryCode
            ) ?? '-'
          : phoneFormat ?? '-',

        policyNo: '-',
        issueBy:
          provinces.find((p) => p.code === p4ops.attributesExt?.issuedBy)?.name ??
          (p4ops?.attributesExt?.issuedBy || '-'),
        issuedDate: !!p4ops.attributesExt?.issuedDate
          ? moment(p4ops.attributesExt.issuedDate).format('DD/MM/YYYY')
          : '-',
        fatca: p4ops.attributesExt?.usTaxDeclarationFrom ?? '',
        occupation: {
          isChangeOccupation: p4ops.attributesExt?.occupationFlag === 'Y',
          occupation: occupation?.find((x) => x.code === p4ops.occupation)?.name || '-',
          occupationCode: p4ops.occupation ?? '',
          jobDescription: p4ops.attributesExt?.specificJob ?? '-',
          jobTitle: p4ops.attributesExt?.title ?? '-',
          companyName: p4ops.attributesExt?.workPlace ?? '-',
          companyAddress: p4ops.attributesExt?.workAddress ?? '-',
          salaryAvg: p4ops.attributesExt?.salary || '-'
        },
        dupPhone: dupMapper(p4ops.attributesExt?.duplicatePhones),
        dupEmail: dupMapper(p4ops.attributesExt?.duplicateEmails),
        countryName: p4ops.attributesExt?.countryName ?? '',
        // city: pipe(provinces.find(x => x.code === p4ops.addressDetails?.ADDRESS?.city), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => '')),
        city: p4ops.addressDetails?.ADDRESS?.city ?? '',
        district: p4ops.addressDetails?.ADDRESS?.district ?? '',
        ward: p4ops.addressDetails?.ADDRESS?.subDistrict ?? '',
        address: p4ops.addressDetails?.ADDRESS?.line1 ?? '',
        foreignAddress: p4ops.addressDetails?.FOREIGN_ADDRESS?.line1 ?? '',
        foreignCountry: pipe(countries.find(x => x.code === p4ops.addressDetails?.FOREIGN_ADDRESS?.countryCode), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => '')),
        nationality2: pipe(countries.find(x => x.code === p4ops.attributesExt?.NATIONALITY_2), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => '')),
        districtName: p4ops.attributesExt?.districtName ?? '',
        wardName: p4ops.attributesExt?.wardName ?? '',
        taxResidencyCountry: pipe(countries.find(x => x.code === p4ops.attributesExt?.taxResidencyCountry), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => ''))
      }
  }
}
