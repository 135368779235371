/* eslint-disable prefer-const */
/* eslint-disable no-extra-boolean-cast */
import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Input, SelectSearch, TaskDetail, ErrorHandling, SelectOption } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { GeneralForm } from './da-genneral-form'
import { assets } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { DaGeneralInfoService } from './da-genneral-info-service'

type Props = {
  detail: TaskDetail.AgentOnboardNew
  form: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'>
  provinceData: Array<DaGeneralInfoService.ProvinceType>
  agentGeneralData: DaGeneralInfoService.GeneralInfoLogRqData | null | undefined
}
export const ContactAddress = React.memo((props: Props) => {
  const { t, i18n } = useTranslation('RequestInfo')
  const [provinceContactOptions, setProvinceContactOptions] = React.useState<SelectOption[]>([])
  const [districContactOptions, setDistrictContactOptions] = React.useState<SelectOption[]>([])
  const [wardContactOptions, setWardContactOptions] = React.useState<SelectOption[]>([])
  const { control, setValue, getValues, watch } = props.form
  const { detail, provinceData, agentGeneralData } = props
  const provinceCode = watch('ProvinceContact.value')
  const districtCode = watch('DistrictContact.value')
  const [wardCode, setWardCode] = useState<string>('')
  const [locatedCode, setLocatedCode] = useState<string>('')
  React.useEffect(() => {
    provinceData &&
      provinceData.length &&
      pipe(
        ZIO.zipPar(ZIO.succeed(detail), ZIO.succeed(provinceData)),
        ZIO.flatMap(([detail, provinces]) => {
          const provinceContactOptions = provinces.map((province) => {
            return {
              label: province.provinceCombine,
              value: province.provinceCode
            }
          })

          setProvinceContactOptions(provinceContactOptions)
          const chooseProvinceContact =
            provinceContactOptions.find(
              (value) => value.value === getValues('ProvinceContact.value') || value.value === detail.contactprovince
            ) ?? null

          return pipe(
            agentGeneralData === null
              ? DaGeneralInfoService.getDistricts(detail.contactprovince ?? '')
              : DaGeneralInfoService.getDistricts(getValues('ProvinceContact.value')),
            ZIO.flatMap((districts) => {
              const districtContactOptions = districts.map((district) => {
                return {
                  label: district.districtCombine,
                  value: district.districtCode
                }
              })

              const chooseDistrictContact =
                districtContactOptions.find(
                  (value) =>
                    value.value === getValues('DistrictContact.value') || value.value === detail.contactdistrict
                ) ?? null
              setDistrictContactOptions(districtContactOptions)
              return pipe(
                agentGeneralData === null
                  ? DaGeneralInfoService.getWards({
                      provinceCode: detail.contactprovince ?? '',
                      districtCode: detail.contactdistrict ?? ''
                    })
                  : DaGeneralInfoService.getWards({
                      provinceCode: getValues('ProvinceContact.value'),
                      districtCode: getValues('DistrictContact.value')
                    }),
                ZIO.map((wards) => {
                  const wardOptions = wards.map((ward) => {
                    return {
                      label: ward.wardCombine,
                      value: ward.wardCode
                    }
                  })
                  const chooseWardsContact =
                    wardOptions.find(
                      (value) => value.value === getValues('WardContact.value') || value.value === detail.contactward
                    ) ?? null
                  setWardContactOptions(wardOptions)
                  setValue('StreetContact', getValues('StreetContact') ?? detail.contactaddress ?? '')
                  setValue('ProvinceContact', getValues('ProvinceContact') ?? chooseProvinceContact ?? null)
                  setValue('DistrictContact', getValues('DistrictContact') ?? chooseDistrictContact ?? null)
                  setValue('WardContact', getValues('WardContact') ?? chooseWardsContact ?? null)
                  return ZIO.unit
                })
              )
            })
          )
        }),
        ZIO.tapError((_) => {
          return ZIO.unit
        }),
        ErrorHandling.run({})
      )
  }, [provinceData, agentGeneralData])
  const getDistricts = (provinceCode: string) =>
    pipe(
      DaGeneralInfoService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.districtCode,
            label: i18n.language === 'vi' ? district.districtCombine : district.districtCombine
          }
        })
        setDistrictContactOptions(districtOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const getWards = (provinceCode: string, districtCode: string) =>
    pipe(
      DaGeneralInfoService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.wardCode,
            label: i18n.language === 'vi' ? ward.wardCombine : ward.wardCombine
          }
        })
        setWardContactOptions(wardOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  React.useEffect(() => {
    let code = provinceCode + districtCode + watch('WardContact.value')
    !!code && setLocatedCode(code)
  }, [provinceCode, districtCode, wardCode, getValues('locationCodeContact')])

  React.useEffect(() => {
    if (!!locatedCode) {
      setValue('locationCodeContact', locatedCode)
    } else {
      setLocatedCode(detail.contactlocationCode ?? '')
    }
  }, [detail, locatedCode])

  return (
    <View style={styles.containers}>
      <View style={styles.row}>
        <View style={styles.col}>
          <Text
            style={{
              fontSize: 15,
              fontWeight: '800',
              color: '#4F4F4F',
              paddingBottom: 22
            }}
          >
            {t('ContactAddress')}
          </Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col}>
          <Controller
            name="ProvinceContact"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('ProvinceContact') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required={true}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('ProvinceContact')}
                options={provinceContactOptions ?? []}
                value={value}
                onBlur={onBlur}
                onChange={(value) => {
                  if (value) {
                    onChange(value)
                    setValue('DistrictContact', { label: '', value: '' })
                    setValue('WardContact', { label: '', value: '' })
                    getDistricts(value.value)
                    setWardCode('')
                  }
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                // key={value}
              />
            )}
          />
        </View>

        <View style={styles.cols}>
          <Controller
            name="DistrictContact"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('DistrictContact') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required={true}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('DistrictContact')}
                options={districContactOptions ?? []}
                value={value}
                onBlur={onBlur}
                onChange={(value) => {
                  if (value) {
                    onChange(value)
                    setValue('WardContact', { label: '', value: '' })
                    getWards(provinceCode, value.value)
                    setWardCode('')
                  }
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                // key={value}
              />
            )}
          />
        </View>
        <View style={styles.col}>
          <Controller
            name="WardContact"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('WardContact') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required={true}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('WardContact')}
                options={wardContactOptions ?? []}
                value={value}
                onBlur={onBlur}
                onChange={(val) => {
                  onChange(val)
                  setValue('WardContact', val)
                  setWardCode(val?.value ?? '')
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                // key={value}
              />
            )}
          />
        </View>
      </View>
      <View style={{ flex: 1, paddingVertical: 24 }}>
        <View style={styles.row}>
          <View style={styles.col}>
            <Controller
              name="StreetContact"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('StreetContact')}
                  disabled={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value == null ? '' : value}
                  errorMessage={error?.message}
                  maxLength={100}
                  required={true}
                />
              )}
            />
          </View>

          <View style={styles.cols}>
            <Input title={t('LocationcodeContact')} required={true} value={locatedCode || '-'} disabled />
          </View>
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    marginBottom: 16
  },
  containers: {
    width: '100%',
    padding: 30,
    borderRadius: 8,
    marginBottom: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6'
  },
  col: {
    width: '33.33333333333%'
  },
  cols: {
    width: '33.33333333333%',
    paddingHorizontal: 30
  },
  row: {
    flexDirection: 'row'
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' }
})
