export namespace DaGeneralData {
  export interface DaGeneralRuleDataRule {
    idNumber: string | null | undefined
    oldIdNumber: string | null | undefined
    phone: string | null | undefined
    fullName: string | null | undefined
    sex: string | null | undefined
    dateOfBirth: string | null | undefined
    bankKey: string | null | undefined
    bankAccKey: string | null | undefined
  }
}
