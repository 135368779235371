import { Pressable } from 'react-native'
import { Text, View } from './styled'
import * as React from 'react'
import styled from 'styled-components/native'
import { assets } from '../assets'

type Props = {
  parentTitle: string
  childTitle: string
  onPressParent: () => void
}

export const DetailNav = (props: Props) => {
  return (
    <SC.Container>
      <assets.IconArrowLeft
        style={{
          marginRight: 6
        }}
      />
      <Pressable onPress={props.onPressParent}>
        <SC.ParentLink>{props.parentTitle}</SC.ParentLink>
      </Pressable>
      <assets.IconArrowLeft
        style={{
          marginRight: 6
        }}
      />
      <SC.ChildTitle>{props.childTitle}</SC.ChildTitle>
    </SC.Container>
  )
}

const SC = {
  Container: styled(View)`
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  `,
  ParentLink: styled(Text)`
    color: ${(p) => p.theme.colors.main};
    font-size: 13px;
    margin-right: 6px;
  `,
  ChildTitle: styled(Text)`
    color: #70777e;
    font-size: 13px;
  `
}
