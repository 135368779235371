import { Panel, PulseOpsFormat, TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList, Title } from '../common'

interface Props {
  detail: TaskDetail.PaidUpModal
}

export const PaidUp = ({ detail }: Props) => {
  const { t } = useTranslation(['requestInfo'])

  const { requestPaidUpPeriod, reason } = detail.detail.payload.body

  return (
    <>
      <Title title={t('PAID_UP')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('RequestPaidUpPeriod'),
              value: requestPaidUpPeriod ? PulseOpsFormat.dateWF(requestPaidUpPeriod, 'YYYYMMDD', 'DD/MM/YYYY') : '-'
            },
            {
              label: t('Reason'),
              value: reason ?? '-'
            }
          ]}
        />
      </Panel>
    </>
  )
}
