import { enumC } from '@mxt/zio/codec'

export enum FundCode {
  UBL1 = 'UBL1',
  UEQ1 = 'UEQ1',
  UFI1 = 'UFI1',
  UGR1 = 'UGR1',
  UPS1 = 'UPS1',
  USB1 = 'USB1',
  UBL2 = 'UBL2',
  UEQ2 = 'UEQ2',
  UFI2 = 'UFI2',
  UGR2 = 'UGR2',
  UPS2 = 'UPS2',
  USB2 = 'USB2',
  USE1 = 'USE1',
  USE2 = 'USE2'
}
export const FundCodeC = enumC(FundCode)
