import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { AdmintoolService, OBCallProgramTypeCode, OBFieldList, OBSharedStyles, mapCallProgram } from '@pulseops/outbound'
import { AppContext, ErrorHandling, ModalComponent, Permission, RBAC, assets } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useForm } from 'react-hook-form'
import { IBGeneralField, IBGeneralTable, TypeInputComponent } from '@pulseops/inbound'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
type MaxCaseForm = {
  maxCase: string
  id: string
  version: string
}

type DataTable = {
  no: number
  id: string
  callProgram: string
  activityBasket: string
  maxCase: string,
  version: string
}

interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }
export const ConfigT4S7MaxCaseScreen = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [isOpenEditPopup, setOpenEditPopup] = React.useState<boolean>(false)
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [dataTable, setDataTable] = React.useState<DataTable[]>([])
  const [isLoading, bindLoading] = useLoading(false)
  const [reload, setReload] = React.useState<boolean>(false)
  const filterColumnList = {
    maxCase: '',
    callProgram: '',
    activityBasket: ''
  }
  const { height, width } = useWindowDimensions()

  const formMaxCase = useForm<MaxCaseForm>({
    defaultValues: {
      id: '',
      maxCase: '',
      version: ''
    },
    mode: 'onChange'
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen' }) },
        { title: t('Setting:AdminTool'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen', params: { tabTitle: 'adminTool' } }) },
        { title: t('Outbound:AdminTool:T4S7'), navigate: null }
      ])
    }

  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    pipe(
      AdmintoolService.getT4S7Config(),
      ZIO.map((res) => {
        res && setDataTable(res.map((i, index) => ({
          no: index + 1,
          id: i.id ?? '',
          callProgram: i.transactionType ? mapCallProgram.get(i.transactionType as OBCallProgramTypeCode) ?? '' : '',
          activityBasket: i.activityBasket ?? '',
          maxCase: i.maxCase?.toString() ?? '',
          version: i.version ?? ''
        }))
        .sort((p1,p2) => p1.callProgram > p2.callProgram ? 1 : -1)
        .map((i, index) => ({
          ...i,
          no: index + 1,
        })))
        reload && setReload(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [reload])

  const columns = [
    {
      label: t('Outbound:AdminTool:No'),
      field: 'no',
      disabled: true
    },
    {
      label: t('Outbound:AdminTool:CallProgram'),
      field: 'callProgram'
    },
    {
      label: t('Outbound:AdminTool:ActivityBasket'),
      field: 'activityBasket'
    },
    {
      label: t('Outbound:AdminTool:MaximumCase'),
      field: 'maxCase'
    },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (value: string) => {
        return (
          roles.includes(Permission.UpdateT4S7MaxCaseAssignment) ?
            <TouchableOpacity
              onPress={() => {
                formMaxCase.reset()
                const findData = dataTable.find((i) => i.id === value)
                formMaxCase.setValue('id', findData?.id ?? '')
                formMaxCase.setValue('maxCase', findData?.maxCase ?? '')
                formMaxCase.setValue('version', findData?.version ?? '')
                setOpenEditPopup(true)
              }}
            >
              <assets.OBPencilGray />
            </TouchableOpacity> :
            <></>
        )
      }
    }
  ]

  const TableConfig = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <IBGeneralTable
          dataTable={columns}
          data={dataTable}
          maxWidthContainer
          maxHeight={height - height * 0.25}
          maxWidth={width - width * 0.025}
          autoPaging
          filterColumn={filterColumnList}
        >
        </IBGeneralTable>
      </View>
    )
  }

  const FieldEdit = () => {
    const findData = dataTable.find((i) => i.id === formMaxCase.watch('id'))
    const dataField = [
      {
        title: t('Outbound:AdminTool:CallProgram'),
        value: findData?.callProgram ?? '-',
      },
      {
        title: t('Outbound:AdminTool:ActivityBasket'),
        value: findData?.activityBasket ?? '-',
      }
    ]
    return (
      <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
        <View style={styles.row}>
          {dataField.map((i) => (
            <View style={[OBSharedStyles.sectionCol6, { paddingHorizontal: 0 }]}>
              <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginTop: 5, marginBottom: 4 }}>{i.title}</Text>
              <Text style={{ marginTop: 4, marginBottom: 5, fontSize: 15 }}>{i.value || i.title ? i.value : ''}</Text>
            </View>
          ))}
        </View>
        <IBGeneralField
          FieldForm={formMaxCase}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'maxCase',
              title: t('Outbound:AdminTool:MaximumCase'),
              inputType: 'number',
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:MaximumCase') }) },
                validate: (value: string) => {
                  return (value && Number(value) < 1000 && Number(value) > 0) ? true : t('message:OB0095', { max: '999', min: '1' })
                }
              }
            }
          ]}
        >
        </IBGeneralField>
      </View>
    )
  }

  const EditPopup = () => {
    return (
      <ModalComponent
        children={FieldEdit()}
        visible={isOpenEditPopup}
        onClose={() => setOpenEditPopup(false)}
        title={t('Outbound:AdminTool:Edit')}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => setOpenEditPopup(false),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35, marginRight: 10 }
          },
          {
            text: t('common:Save'),
            type: 'filled',
            action: () => onSave(),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35 }
          }
        ]}
        centerTitle
        modalWidth={800}
      />
    )
  }

  const onSave = formMaxCase.handleSubmit((value) => {
    pipe(
      AdmintoolService.updateT4S7Config({
        id: value.id ?? '',
        maxCase: value.maxCase ? Number(value.maxCase) : 0,
        version: value.version ?? ''
      }),
      ZIO.map((res) => {
        setReload(true)
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showToast(error.source.message, 'error')
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  return (
    <View style={styles.container}>
      {
        roles.includes(Permission.ViewT4S7MaxCaseAssignment) &&
        <>
          {TableConfig()}
          {EditPopup()}
        </>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
    paddingVertical: 10
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  addButton: {
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    flexDirection: 'row',
    borderRadius: 8,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 10
  },
})
