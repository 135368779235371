import { TFunctionResult } from 'i18next'
import React from 'react'
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native'

interface Props {
  title: string | TFunctionResult
  wrapperStyle?: StyleProp<TextStyle>
}

export const Title: React.FC<Props> = ({ title, wrapperStyle }) => {
  return <Text style={[styles.sectionInfoHeaderTitle, wrapperStyle]}>{title}</Text>
}

const styles = StyleSheet.create({
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginBottom: 15
  }
})
