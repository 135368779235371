// import { ZIO } from '@mxt/zio'
import {
  DatePicker,
  Panel,
  RadioButtonGroup,
  Select,
  useMobile
  // InquiryComplaintData,
  // InquiryComplaintService,
  // ErrorHandling
} from '@pulseops/common'
// import { pipe } from 'fp-ts/lib/function'
import { VeriInquiryComplaintData, VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text } from 'react-native'
import { Title } from '../../common'
import { InquiryComplaintForm } from './InquiryComplaintForm'
interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  validateReadOnly: () => boolean
  updatedPayloadData: VeriPayloadData
}

export const SalesPractice: React.FC<Props> = ({ detail, form, validateReadOnly, updatedPayloadData }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()

  const submissionData = updatedPayloadData.body.attributesExt
  // const [financialNeedAnalysisList, setFinancialNeedAnalysisList] = React.useState<
  //   InquiryComplaintData.IACReceiptType[]
  // >([])

  const { setValue, control } = form
  const financialNeedAnalysisList = detail.complaintedDataList.financialNeedAnalysisList
  const isDisabled = validateReadOnly()

  // Fecth API
  // pipe(
  //   InquiryComplaintService.getT22Data('fna'),
  //   ZIO.map((list) => {
  //     setFinancialNeedAnalysisList(list)
  //   }),
  //   ErrorHandling.runDidMount()
  // )

  useEffect(() => {
    const salesPracticeDetail = submissionData.COMPLAINT_SALE_DETAILS
    if (
      salesPracticeDetail &&
      salesPracticeDetail.length > 0 &&
      financialNeedAnalysisList &&
      financialNeedAnalysisList.length > 0
    ) {
      const financialNeedAnalysis =
        financialNeedAnalysisList
          .map((item) => ({
            label: i18n.language === 'en' ? item.nameEn : item.name,
            value: item.code
          }))
          .find((generalAgency) => generalAgency.value === salesPracticeDetail[0].financeNeedCode) || null

      const customerReceiveDate = moment(salesPracticeDetail[0].customerReceiveDate).format('YYYY/MM/DD')
      setValue('salesPractice.financialNeedAnalysis', financialNeedAnalysis)

      setValue('salesPractice.POSignedInProposal', salesPracticeDetail[0].isPoSignedInProposal ? 'yes' : 'no')
      setValue('salesPractice.customerReceivedPolicyKit', salesPracticeDetail[0].isCustomerReceiveKit ? 'yes' : 'no')
      if (salesPracticeDetail[0].evidence !== undefined) {
        setValue('salesPractice.evidence', salesPracticeDetail[0].evidence ? 'yes' : 'no')
      }
      !!customerReceiveDate &&
        customerReceiveDate !== 'Invalid date' &&
        setValue('salesPractice.customerReceivedDate', new Date(customerReceiveDate))
    }
  }, [submissionData, financialNeedAnalysisList])

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  return (
    <View>
      <Title title={t('ComplaintedSalesPracticeDetails')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="salesPractice.financialNeedAnalysis"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('FinancialNeedAnalysis') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  label={t('FinancialNeedAnalysis')}
                  disabled={isDisabled}
                  required
                  options={financialNeedAnalysisList.map((item) => ({
                    value: item.code,
                    label: i18n.language === 'en' ? item.nameEn : item.name
                  }))}
                  // showUnderline={false}
                  placeholder={showOptionLanguage()}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'salesPractice.POSignedInProposal'}
              control={control}
              // defaultValue="yes"
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('POSignedInProposal') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  title={t('POSignedInProposal')}
                  colLength={'100%'}
                  required
                  disabled={isDisabled}
                  value={value}
                  options={[
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'salesPractice.customerReceivedPolicyKit'}
              control={control}
              // defaultValue="yes"
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('CustomerReceivedPolicyKit/e-kit') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  title={t('CustomerReceivedPolicyKit/e-kit')}
                  colLength={'100%'}
                  required
                  disabled={isDisabled}
                  value={value}
                  options={[
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Text style={styles.field_title}>{t('CustomerReceivedDate')}</Text>
            <Controller
              name={'salesPractice.customerReceivedDate'}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <DatePicker
                  // label={t('CustomerReceivedDate')}
                  // required
                  disabled={isDisabled}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'salesPractice.evidence'}
              control={control}
              // defaultValue="yes"
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020009', { field: t('Evidence') })
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  title={t('Evidence')}
                  colLength={'100%'}
                  required
                  disabled={isDisabled}
                  value={value}
                  options={[
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 15
  },
  colFull: {
    width: '100%',
    marginBottom: 32,
    paddingHorizontal: 15
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  }
})
