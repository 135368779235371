import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
export const LoanStatements = t.type({
  transactionType: t.literal(TransactionType.LOAN_STATEMENTS),
  payload: t.type({
    body: t.type({
      office: Maybe(t.string),
      fromDate: Maybe(t.string),
      toDate: Maybe(t.string),
      status: Maybe(t.string)
    })
  }),
  source: Maybe(t.string)
})
export type LoanStatements = t.TypeOf<typeof LoanStatements>
