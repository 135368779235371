import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView, Platform } from 'react-native'
import { ControlProps } from '../FormProps'
import {
  AppContext,
  assets,
  AuthService,
  ErrorHandling,
  replaceSpecialCharactersInFileName,
  StorageBlobApi,
  useMobile
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { PulseOpsFormat } from '../../Formatter'
import * as Linking from 'expo-linking'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

export type LocalData = {
  tag: 'Local'
  fileName: string
  fileExtension: string
  size: number
  base64: string
  uploadedDate: Date
  file: File
}

export type CloudData = {
  tag: 'Cloud'
  fileName: string
  fileExtension: string
  size: number
  base64?: string
  uploadedDate: Date
  url: string
  blobInfo: string
  storageConfig: {
    sas: string
  }
}

export type FileUploadData2 = LocalData | CloudData

type ImageMultiProps = ControlProps<FileUploadData2[]> & {
  transactionType?: string
  maxNumFile?: number
  timeFormat?: string
  disabled?: boolean
  category?: string
  policyNum?: string
}

export const ImgUploadMutiple2 = (props: ImageMultiProps) => {
  const { value, onChange, disabled, category, policyNum } = props
  const { t } = useTranslation()

  const inputRef = React.createRef<HTMLInputElement>()
  //const upload = () => inputRef.current?.click()

  const MB = 1048576
  const maxSize = 10 * MB
  const maxTotal = 50 * MB
  // const maxFile = props.maxNumFile ? props.maxNumFile : 5
  const maxFile = props.maxNumFile
  const validExtensions = ['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']

  const [errorMess, setErrorMess] = React.useState<string>('')
  const [currSize, setCurrSize] = React.useState<number>(0)
  const { isMobile } = useMobile()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const checkUpload = (files: FileUploadData2[]) => {
    const file = files[0]

    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      setErrorMess(t('message:MS040003', { files: validExtensions.join('/') }))
    } else if (file.size >= maxSize) {
      setErrorMess(t('message:MS040004'))
    } else if (currSize + file.size >= maxTotal) {
      setErrorMess(t('message:MS040005'))
    }
    // else if (value && value.length >= maxFile) {
    else if (maxFile && value && value.length >= maxFile) {
      setErrorMess(t('message:MS050279', { size: maxFile }))
    } else {
      onChange && onChange(value ? [...value, file] : [file])
      setCurrSize(currSize + file.size)
      setErrorMess('')
    }
  }

  const deleteItem = (i: number) => {
    setErrorMess('')
    const temp = value || []
    temp.splice(i, 1)
    onChange && onChange(temp)
  }

  const handleUpload = (files: FileList) => {
    const fileList: FileUploadData2[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      const reader = new FileReader()

      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''
      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          tag: 'Local',
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && checkUpload(fileList)
      }
    }
  }

  const handleDownloadCloud = (fileUploadData: CloudData) => {
    // const { sas } = fileUploadData.storageConfig
    const url = fileUploadData.url
    return pipe(
      ZIO.zipPar(
        StorageBlobApi.getRemoteConfig(category ?? '', policyNum ?? ''),
        AuthService.token,
        AuthService.getLoginType
      ),
      ZIO.flatMap(([remoteConfig, token, loginType]) => {
        return ZIO.fromPromise(() => {
          const apiUrl = url.split('?')
          const storageUrl = apiUrl[0]
          const paramInfo = apiUrl[1]
          const paramList = paramInfo.split('&')
          const containerName = paramList[0] ? paramList[0].split('=')[1] : ''
          const blobName = paramList[1] ? paramList[1].split('=')[1] : ''
          return fetch(storageUrl, {
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Container: containerName,
              Token: remoteConfig.sas,
              Blob: blobName,
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        })
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.tap(([res, blob]) => {
        const type = res.headers.get('content-type') || ''
        downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const ProxyView = isMobile ? View : ScrollView
  const composeContainerWidth = isMobile ? '100%' : 368.5
  const composeProxyView = '100%'
  // const composeHeightPicture = isMobile ? undefined : '100%'

  return (
    <View>
      <View style={{ flexDirection: isMobile ? 'column' : 'row', marginTop: 10 }}>
        {!disabled && (
          <View style={{ width: composeContainerWidth }}>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                style={[styles.uploadContainer, { marginRight: 10 }]}
                // onPress={() => {
                //   upload()
                // }}
              >
                <assets.UploadCloud />
                <Text style={{ fontSize: 12 }}>{t('common:DropFileHere')}</Text>
                <Text style={{ fontSize: 12 }}>{t('common:or')}</Text>
                <Text style={{ fontSize: 12, color: '#ED1B1C' }}>{t('common:SelectFromComputer')}</Text>
                <input
                  ref={inputRef}
                  //hidden
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    fontSize: 20,
                    cursor: 'pointer',
                    opacity: 0,
                    borderWidth: 1
                  }}
                  type="file"
                  multiple={true}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.files) {
                      handleUpload(event.target.files)
                      event.target.files = null
                      event.target.value = ''
                    }
                  }}
                  onDrop={(event: React.DragEvent<HTMLInputElement>) => {
                    event.preventDefault()
                    if (event.dataTransfer.files) {
                      handleUpload(event.dataTransfer.files)
                    }
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity style={[styles.uploadContainer]} disabled>
                <assets.UploadCamera />
                <Text style={{ fontSize: 12 }}>{t('common:TakeAPicture')}</Text>
              </TouchableOpacity>
            </View>
            <Text style={{ fontSize: 15, opacity: 0.6, fontStyle: 'italic' }}>
              {t('common:ImageFormat', { fileExtension: validExtensions.join(', ') })}
            </Text>
          </View>
        )}
        <ProxyView
          style={{ width: composeProxyView, paddingVertical: isMobile ? 16 : 0, maxHeight: isMobile ? undefined : 250 }}
        >
          <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
            {value &&
              value.map((file, i) => (
                <View style={styles.resultItem} key={`resultItem-${i}`}>
                  {file.base64 ? (
                    <Image source={{ uri: file.base64 }} style={{ height: 70, width: 70 }}></Image>
                  ) : (
                    <View style={{ height: 70, width: 70 }}></View>
                  )}
                  <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                    <Text style={{ fontSize: 15 }}>
                      <Text> {file.fileName.length < 10 ? file.fileName : `${file.fileName.substr(0, 5)}...`} </Text>.
                      {file.fileExtension}
                    </Text>
                    <Text style={{ fontSize: 15, fontStyle: 'italic', marginTop: 20 }}>
                      {PulseOpsFormat.datetoFormat(file.uploadedDate, props.timeFormat || 'DD/MM/YYYY')}
                    </Text>
                  </View>
                  <View style={{ position: 'absolute', right: 0, paddingTop: 20, flexDirection: 'row' }}>
                    <View
                    //hitSlop={{right: 20, left: 20, top: 20, bottom: 20,}}
                    >
                      {Platform.OS === 'web' ? (
                        file.tag === 'Local' ? (
                          <View style={{ padding: 5 }}>
                            <a href={file.base64} download={`${file.fileName}.${file.fileExtension}`}>
                              <assets.Download />
                            </a>
                          </View>
                        ) : (
                          <TouchableOpacity
                            // hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                            onPress={() => {
                              handleDownloadCloud(file)
                            }}
                            style={{ padding: 5 }}
                          >
                            <assets.Download />
                          </TouchableOpacity>
                        )
                      ) : (
                        <TouchableOpacity
                          //style={{ position: 'absolute', right: 0, paddingTop: 20 }}
                          hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                          onPress={() => {
                            Linking.openURL(file.base64 || '')
                          }}
                          style={{ padding: 5 }}
                        >
                          <assets.DeleteIcon />
                        </TouchableOpacity>
                      )}
                    </View>

                    {!disabled && (
                      <TouchableOpacity
                        //style={{ position: 'absolute', right: 0, paddingTop: 20 }}
                        hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                        onPress={() => deleteItem(i)}
                        style={{ padding: 5 }}
                      >
                        <assets.DeleteIcon />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              ))}
          </View>
        </ProxyView>
      </View>
      <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errorMess}</Text>
    </View>
  )
}

function downloadURI(uri: string, name: string) {
  const link = document.createElement('a')
  link.download = name
  link.target = '_blank'
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const styles = StyleSheet.create({
  common: {
    height: 31,
    fontSize: 15,
    borderBottomWidth: 0
  },
  dashline: {
    borderBottomColor: '#D3DCE6',
    borderBottomWidth: 1
  },
  uploadContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    // font-size: 12px;
    // border: 1px dashed #D3DCE6,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    height: 156,
    width: '48%'
  },
  resultItem: {
    height: 70,
    width: 275,
    flexDirection: 'row',
    borderBottomColor: '#92B0B3',
    borderBottomWidth: 2,
    borderStyle: 'dashed',
    marginBottom: 10,
    marginHorizontal: 10
  }
})
