import { ZIO } from '@mxt/zio'
import { Checkbox, ErrorHandling, GeneralService, TaskDetail, SourceType } from '@pulseops/common'
import { Branch, ClaimService } from '@pulseops/submission'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import { Panel } from '../claim-common'
import { ClaimInfoForm } from '../claim-type/ClaimInfoForm'
import { LogHistoryData } from '../ClaimScreen'
import { BankTransfer } from './BankTransfer'
import { PaidAtBank } from './PaidAtBank'
import { PayAtCounter } from './PayAtCounter'

interface Props {
  innerProps: {
    detail: TaskDetail.VerificationClaim
    clients: {
      firstName: string
      surName: string
      dob: string
      sex: string
      nationality: string
      occupation: {
        desc: string
      }
      addressDetails: {
        Current: {
          line1: string
          country: string
        }
      }
    }
    form: UseFormReturn<ClaimInfoForm>
  }
  disabled?: boolean
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
}

export const PaymentMethodTab: React.FC<Props> = ({ innerProps, disabled, setIsCanChangeTab }) => {
  const { detail, form } = innerProps
  const { PAYMENT } = detail
  const [bankBranchs, setBankBranchs] = React.useState<Branch[]>([])
  const { historyPaymentMethodData } = React.useContext(LogHistoryData)
  const { t } = useTranslation('claim')
  const isPruonline = detail?.source === SourceType.PRUONLINE

  const { watch, control } = form
  const { paidAtBank } = watch()

  // Call get banks, get provinces, get bank branchs API
  const bankAPI = pipe(
    ClaimService.getBanks(),
    ZIO.tap((banks) => {
      if (PAYMENT.bankTransfer || PAYMENT.payAtBank) {
        if (PAYMENT.bankTransfer?.bank && PAYMENT.bankTransfer?.bankName) {
          form.setValue(
            'bankTransferForm.bank',
            PAYMENT.bankTransfer?.bankName && PAYMENT.bankTransfer?.bank
              ? {
                  label: PAYMENT.bankTransfer?.bankName,
                  value: PAYMENT.bankTransfer?.bank
                }
              : null
          )
        } else if (PAYMENT.bankTransfer?.bank && !PAYMENT.bankTransfer?.bankName) {
          const bank =
            banks
              .map((item) => ({
                value: item.code,
                label: item.name
              }))
              .find((item) => item.value === PAYMENT.bankTransfer?.bank) || null
          form.setValue('bankTransferForm.bank', bank)
        }

        if (PAYMENT.payAtBank?.bankName) {
          form.setValue(
            'paidAtBank.bankName',
            PAYMENT.payAtBank?.bankName && PAYMENT.payAtBank?.bank
              ? {
                  label: PAYMENT.payAtBank?.bankName,
                  value: PAYMENT.payAtBank?.bank
                }
              : null
          )
        } else if (PAYMENT.payAtBank?.bankName && !PAYMENT.payAtBank?.bank) {
          const bank =
            banks
              .map((item) => ({
                value: item.code,
                label: item.name
              }))
              .find((item) => item.value === PAYMENT.payAtBank?.bankName) || null
          form.setValue('paidAtBank.bankName', bank)
        }
      }

      return ZIO.succeed(banks)
    }),
    ErrorHandling.runDidMount()
  )

  const province = pipe(
    GeneralService.getProvinces,
    ZIO.tap((provinces) => {
      return ZIO.succeed(provinces)
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (historyPaymentMethodData == null) {
      form.setValue('payeeIsNotValid', false)
    } else {
      form.setValue('payeeIsNotValid', historyPaymentMethodData.payeeIsNotValid === 'true' ? true : false)
    }
  }, [historyPaymentMethodData])

  React.useEffect(() => {
    if (PAYMENT.bankTransfer || PAYMENT.payAtBank) {
      const branch = PAYMENT.bankTransfer
        ? PAYMENT.bankTransfer.bank
        : PAYMENT?.payAtBank
        ? PAYMENT?.payAtBank.bank
        : ''
      if (branch) {
        pipe(
          ClaimService.getBankBranch(branch),
          ZIO.catchAll((error) => {
            console.log('Error: ', error)
            return ZIO.fail(null)
          }),
          ZIO.tap((bankBranch) => {
            if (PAYMENT.payAtBank) {
              form.setValue(
                'paidAtBank.branch',
                PAYMENT.payAtBank?.branchName && PAYMENT.payAtBank?.branch
                  ? {
                      label: PAYMENT.payAtBank?.branchName,
                      value: PAYMENT.payAtBank?.branch
                    }
                  : null
              )
            } else if (PAYMENT.bankTransfer) {
              form.setValue(
                'bankTransferForm.branch',
                PAYMENT.bankTransfer?.branchName && PAYMENT.bankTransfer?.branch
                  ? {
                      label:
                        detail?.detail?.source === SourceType.PULSE
                          ? bankBranch.find((_) => _.code === PAYMENT.bankTransfer?.branch)?.name || ''
                          : PAYMENT.bankTransfer?.branchName,
                      value: PAYMENT.bankTransfer?.branch
                    }
                  : null
              )
            }
            setBankBranchs(bankBranch)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      }
    }
  }, [paidAtBank.bankName])

  const renderPaymentMode = () => {
    switch (PAYMENT?.paymentMethod) {
      case 'BANKTRANSFER':
        return (
          <View>
            <BankTransfer props={{ form, detail: detail, bank: bankAPI, bankBranchs }} />
          </View>
        )
      case 'CASH_AT_COUNTER':
        return (
          <View>
            <PayAtCounter props={{ form, detail: detail }} />
          </View>
        )
      case 'PAID_AT_BANK':
        return (
          <View>
            <PaidAtBank
              props={{ form, detail: detail, bank: bankAPI, provinces: province, bankBranchs: bankBranchs }}
            />
          </View>
        )
      default:
        return <></>
    }
  }

  const renderPaymentModeTitle = () => {
    switch (PAYMENT?.paymentMethod) {
      case 'BANKTRANSFER':
        return t('BankTransfer')
      case 'CASH_AT_COUNTER':
        return t('CashAtTheCounter')
      case 'PAID_AT_BANK':
        return t('PayAtBank')
      default:
        return isPruonline ? PAYMENT.paymentMethodLabel || '-' : '-'
    }
  }

  return (
    <ScrollView>
      <Panel title={''} isHiddenTitle={false} isExand={false} styling={styles.container}>
        <View style={{ marginBottom: 30 }}>
          <Text style={styles.label}>{t('PaymentMethodSelect')}</Text>
          <Text style={styles.value}>{renderPaymentModeTitle()}</Text>
        </View>
        <View style={[styles.row, { marginBottom: 16 }]}>
          <View style={{ flex: 1 / 3, flexDirection: 'row', alignItems: 'center' }}>
            <Controller
              name={`isNotPolicyOwner`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={''}
                  disabled
                  onBlur={onBlur}
                  value={PAYMENT.isPayeeNotPO || false}
                  onChange={onChange}
                />
              )}
            />

            <Text style={{ fontWeight: 'bold', alignSelf: 'center' }}>{t('PayeeIsNotPolicyOwner')}</Text>
          </View>
          <View style={{ flex: 1 / 3, flexDirection: 'row', alignItems: 'center' }}>
            <Controller
              name={`payeeIsNotValid`}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title=""
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                    setIsCanChangeTab(false)
                  }}
                  disabled={disabled}
                />
              )}
            />
            <Text style={{ fontWeight: 'bold', alignSelf: 'center' }}>{t('PayeeIsNotValid')}</Text>
          </View>
        </View>
        {renderPaymentMode()}
      </Panel>
    </ScrollView>
  )
}
const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
