import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View } from 'react-native'
import { assets, PulseOpsFormat } from '@pulseops/common'
import { infoGen, styles } from '../../common'
import { Employee } from '../../services'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export type BOInquiryFCLPopupProps = {
  onClose: (value: any) => void
  open: boolean
  selectedValue: Employee | null
}

export const BOInquiryFCLPopup = (props: BOInquiryFCLPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, selectedValue, open } = props

  const handleClose = (props: BOInquiryFCLPopupProps) => {
    onClose(selectedValue)
  }
  const infoMapper = infoGen(false)

  const memberInfo = [
    [
      { id: 'memberName', title: 'Họ và tên', value: props.selectedValue?.fullName || '-' },
      { id: 'memberID', title: 'Số ID', value: props.selectedValue?.nationID || '-' }
    ],
    [
      {
        id: 'memberAge',
        title: 'Tuổi',
        value: props.selectedValue?.dob ? PulseOpsFormat.getAge(new Date(props.selectedValue.dob)).toString() : '-'
      },
      { id: 'memberGender', title: 'Giới tính', value: props.selectedValue?.gender || '-' }
    ],
    [
      { id: 'memberPhone', title: 'Số điện thoại', value: props.selectedValue?.mobile || '-' },
      { id: 'memberEmail', title: 'Thư điện tử', value: props.selectedValue?.email || '-' }
    ],
    [{ id: 'memberGroup', title: 'Nhóm', value: props.selectedValue?.benefitGroup || '-' }],
    [
      { id: 'memberBenAmount', title: 'Số tiền bảo hiểm', value: '-' },
      { id: 'memberOverFCLAmount', title: 'Số tiền vượt quá FCL', value: props.selectedValue?.overFCL || '-' }
    ],
    [
      { id: 'memberDecision', title: 'Quyết định', value: props.selectedValue?.fullName || '-' },
      { id: 'memberTime', title: 'Thời gian', value: props.selectedValue?.fullName || '-' }
    ],
    [{ id: 'memberNote', title: 'Ghi chú', value: props.selectedValue?.fullName || '-' }],
    [{ id: 'memberUID', title: 'User ID', value: props.selectedValue?.fullName || '-' }]
  ]

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center', marginLeft: 300 }}>
          Thông tin thành viên vượt FCL
        </Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose(null)
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin chi tiết</Text>
          {infoMapper(memberInfo, 'memberInfo')}
        </View>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose(null)}>
          <Text style={{ color: '#FFF' }}>OK</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}
