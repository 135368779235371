import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { RadioButton, TaskDetail } from '@pulseops/common'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DataSource, InfoViewList, Title } from '../common'

interface Props {
  detail: TaskDetail.ChangeOtherInfomationPersonal
}

interface mappingDataI {
  isBorder: boolean
  title: string
  dataSource: DataSource[]
}

const ChangeOtherInfomationPersonalScreen = ({ detail }: Props) => {
  const { t } = useTranslation()
  const {
    clientName,
    data: {
      idType,
      idNumber,
      issuedBy,
      issuedDate,
      firstName,
      surName,
      nationality,
      usTaxDeclarationFrom,
      isCheckChangeCardGroup,
      isCheckChangeFullNameGroup,
      isCheckChangeNationalityGroup,
      countryOfForeignAddress,
      foreignAddress,
      nationality2,
      // taxResidencyCountry
    }
  } = detail

  const mappingData: mappingDataI[] = [
    {
      isBorder: true,
      title: '',
      dataSource: [
        { label: t('submission:category'), value: t('Reports:PolicyService') },
        { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
        {
          label: t('submission:transactionType'),
          value: t(`TransactionType:${detail.tag}`)
        }
      ]
    },
    {
      isBorder: false,
      title: '',
      dataSource: [
        { label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.selectClient'), value: clientName }
      ]
    },

    ...(isCheckChangeCardGroup
      ? [
          {
            isBorder: true,
            title: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.LABEL_GROUP.change_card_group'),
            dataSource: [
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.idType'),
                value: idType ? t(`submission:${idType}`) : '-',
                isRequired: true
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.idNumber'),
                value: idNumber,
                isRequired: true
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.issuedBy'),
                value: issuedBy,
                isRequired: true
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.issuedDate'),
                value: issuedDate,
                isRequired: true
              }
            ]
          }
        ]
      : []),

    ...(isCheckChangeFullNameGroup
      ? [
          {
            isBorder: true,
            title: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.LABEL_GROUP.change_full_name_group'),
            dataSource: [
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.surName'),
                value: surName,
                isRequired: true
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.givenName'),
                value: firstName,
                isRequired: true
              }
            ]
          }
        ]
      : []),

    ...(isCheckChangeNationalityGroup
      ? [
          {
            isBorder: true,
            title: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.LABEL_GROUP.change_nationality_group'),
            dataSource: [
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.nationality'),
                value: nationality,
                isRequired: true
              },
              // {
              //   label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.taxResidencyCountry'),
              //   value: taxResidencyCountry,
              //   isRequired: false
              // },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.countryOfForeignAddress'),
                value: countryOfForeignAddress,
                isRequired: false
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.foreignAddress'),
                value: foreignAddress,
                isRequired: false
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.nationality2'),
                value: nationality2,
                isRequired: false
              },
              {
                label: t('CHANGE_OTHER_INFORMATION_PERSONAL:GROUPS_VIEWS.FIELDS.usTaxDeclarationFrom'),
                value: (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <RadioButton label={t('common:Yes')} selected={usTaxDeclarationFrom === 'Y'} />
                    <RadioButton label={t('common:No')} selected={usTaxDeclarationFrom === 'N'} />
                  </View>
                ),
                isRequired: true
              }
            ]
          }
        ]
      : [])
  ]

  const renderViews = () => {
    if (isEmpty(detail)) return <Text>XXXX</Text>

    return mappingData.map((group, index) => {
      const { isBorder, title, dataSource } = group
      return (
        <View key={index} style={{ paddingVertical: 12 }}>
          {title ? <Title title={title} /> : null}
          <View style={[isBorder && styles.borderGroup]}>
            {!isEmpty(dataSource) ? <InfoViewList dataSource={dataSource} /> : null}
          </View>
        </View>
      )
    })
  }
  return <View>{renderViews()}</View>
}

export default ChangeOtherInfomationPersonalScreen
const styles = StyleSheet.create({
  borderGroup: {
    paddingHorizontal: 12,
    paddingVertical: 30,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: '#d3dce6',
    borderWidth: 1
  }
})
