import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface ChangeSumAssured {
  tag: TransactionType.CHANGE_SUM_ASSURED

  currentInstallmentPremium: number
  currentSumAssured: number
  // maximumSumAssured: number
  // minimumSumAssured: number
  newInstallmentPremium: number
  newSumAssured: number
  requirePayInAmount: number
}

export const ChangeSumAssured = (detail: api.ChangeSumAssured & api.TaskDetailBase): ChangeSumAssured => {
  const payload = detail.payload.body

  return {
    tag: TransactionType.CHANGE_SUM_ASSURED,

    currentInstallmentPremium: payload.currentInstallmentPremium,
    currentSumAssured: payload.currentSumAssured,
    // maximumSumAssured: payload.maximumSumAssured,
    // minimumSumAssured: payload.minimumSumAssured,
    newInstallmentPremium: payload.newInstallmentPremium,
    newSumAssured: payload.newSumAssured,
    requirePayInAmount: payload.requirePayInAmount
  }
}
