export namespace NotificationData {
  export interface notification {
    start?: number
    size?: number
    order?: string
    sort?: string
    policyNumber?: string
    transactionTypes?: string[]
    fromDate?: string
    toDate?: string
    createdBy?: string
  }
}
