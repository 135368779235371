export namespace AccountingCashout {
  export interface AccountingList {
    caseId: string
    pvNumber: string
    paymentNumber: string
    paymentDate: string
    payee: string
    amount: number
    transaction: string
    documentStatus: string
    note: string
  }

  export interface AccountingDetail {
    policyNumber: string
    pvNumber: string
    paymentNumber: string
    csStaff: string
    policyOwner: string
    bankCode: string
    verifyDate: string
    paymentDate: string
    lastUpdateDate: string
    lastUpdateBy: string
    paymentOffice: string
    payee: string
    amount: number
    idNumber: string
    transaction: string
    documentStatus: string
    note: string
  }
}
