import { assets } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Pressable, TextInput, TouchableOpacity, View } from 'react-native'

type OBFilterSearchInput = {
  inputValue: string
  isFilterApplied?: boolean
  onChangeInput: (val: string) => void
  onPressFilter?: () => void
  onSearchTextEvent?: () => void
}

export const OBFilterSearchInput = (props: OBFilterSearchInput) => {
  const {t} = useTranslation()
  const { inputValue, onChangeInput, onPressFilter, isFilterApplied, onSearchTextEvent } = props

  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      <View style={filterStyles.searchContent}>
        <TextInput value={inputValue} placeholder={t('common:ClientNumber')} onChangeText={onChangeInput} style={filterStyles.inputContent} />
        <TouchableOpacity onPress={() => onSearchTextEvent && onSearchTextEvent()}>
          <assets.OutboundSearch />
        </TouchableOpacity>
      </View>
      <Pressable>
        <View>
          <TouchableOpacity style={filterStyles.btnFilter} onPress={onPressFilter}>
            {isFilterApplied ? <assets.FilterAppliedIcon /> : <assets.FilterIcon />}
          </TouchableOpacity>
        </View>
      </Pressable>
    </View>
  )
}
const filterStyles = StyleSheet.create({
  inputContent: {
    padding: 2,
    lineHeight: 2,
    width: '100%',
    minHeight: 22,
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    paddingHorizontal: 16,
    paddingVeritical: 3,
    color: '#70777E'
  },
  searchContent: {
    display: 'flex',
    width: 300,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 16,
    borderWidth: 2,
    borderColor: '#D3DCE6'
  },
  btnFilter: {
    marginStart: 11,
    marginEnd: 5
  }
})
