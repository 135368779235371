import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { ProductInfo } from './general-info.model'

export interface GeneralInfoDigitalPolicyRes {
  clients?: {
    firstName: string
    surName: string
    dob: string
    sex: string
    nationality: string
    nationalID: string
    occupation: {
      desc: string
    }
    addressDetails: {
      Current: {
        line1: string
        country: string
      }
    }
  }
  policy: {
    policyOwner: string
    policyOwnerName: string
    mainLA: string
    contractName: string
    policyStatus: string
    sumAssured: string
    installmentPremium: string
    frequency: string
    firstIssuedDate: string
    riskCommenceDate: string
    premiumCessDate: string
    riskCessDate: string
  }
  customer: {
    street: string
    ward: string
    district: string
    city: string
    country: string
    email: string
    mobilePhone: string
    po_la_info: {
      role: string
      name: string
      nationalId: string
      dob: string
      gender: string
    }[]
  }
}

export const PolicyDigitalC = t.type({
  clients: t.array(
    t.type({
      firstName: t.string,
      surName: Maybe(t.string),
      dob: t.string,
      sex: t.string,
      nationality: t.string,
      occupation: Maybe(
        t.type({
          desc: t.string
        })
      ),
      addressDetails: t.type({
        Current: t.type({
          line1: t.string,
          line2: Maybe(t.string),
          line3: Maybe(t.string),
          line4: Maybe(t.string),
          country: t.string
        })
      }),
      externalIds: t.type({
        NATIONAL_ID: t.string
      })
    })
  ),
  productOptions: t.array(
    t.type({
      product: t.type({
        code: t.string,
        fullName: t.string
      }),
      totalPremium: t.number,
      totalSumAssured: t.number,
      commencementDate: t.string,
      riskCessDate: t.string,
      status: t.string,
      productComponentOptions: t.array(
        t.type({
          component: t.type({
            name: t.string,
            code: t.string
          }),
          riskCessDate: t.string,
          commencementDate: t.string,
          options: t.type({
            totalPremium: Maybe(t.string),
            totalSumAssured: Maybe(t.string),
            claimTemplateType: Maybe(t.string),
            claimantRole: Maybe(t.string)
          }),
          status: t.string
        })
      )
    })
  ),
  status: t.string,
  lifeAssured: Maybe(
    t.array(
      t.type({
        firstName: t.string,
        surName: t.string
        // policies: t.array(t.type({
        //   id: t.string
        // }))
      })
    )
  ),
  paymentOption: Maybe(
    t.type({
      currency: t.string,
      amount: t.number,
      frequency: t.string
    })
  ),
  endDate: t.string,
  inceptionDate: t.string,
  firstIssueDate: t.string,
  customerRoles: t.array(
    t.type({
      id: Maybe(t.string),
      role: t.string,
      customer: t.type({
        addressDetails: t.type({
          Current: t.type({
            line1: t.string,
            line2: Maybe(t.string),
            line3: Maybe(t.string),
            line4: Maybe(t.string),
            country: t.string
          })
        }),
        firstName: t.string,
        surName: t.string,
        contactDetails: t.type({
          email: t.type({
            value: t.string
          }),
          phone: t.type({
            value: Maybe(t.string),
            countryCode: Maybe(t.string)
          })
        })
      })
    })
  )
})

export interface GeneralInfoDigitalProductRes {
  owner: ProductInfo | null
  laClient: ProductInfo[]
}

export const ProductInquiryC = t.type({
  policyNo: t.string,
  proposalNo: Maybe(t.string),
  productName: Maybe(t.string),
  planName: Maybe(t.string),
  productCode: Maybe(t.string),
  source: Maybe(t.string),
  firstIssueDate: Maybe(t.string),
  inceptionDate: Maybe(t.string),
  expiryDate: Maybe(t.string),
  coverageTotalPremium: Maybe(t.string),
  coverageTotalSumAssured: Maybe(t.string),
  referralCode: Maybe(t.string),
  status: Maybe(t.string),
  transactionId: Maybe(t.string),
  customerInfo: t.type({
    fullName: Maybe(t.string)
  }),
  riderInfos: t.array(
    t.type({
      policyNo: Maybe(t.string),
      componentCode: Maybe(t.string),
      componentName: Maybe(t.string),
      riskCessDate: Maybe(t.string),
      riskCommencementDate: Maybe(t.string),
      status: Maybe(t.string),
      sumAssured: Maybe(t.string),
      riderFlag: Maybe(t.string)
    })
  )
})
