import { VoiceRecordingModels } from '../VoiceRecordingModels'
import { Types } from './setup'

export class VoiceRecordingController {
  dispatchReducer: Types.DispatchReducer

  constructor(dispath: Types.DispatchReducer) {
    this.dispatchReducer = dispath
  }

  setCurrentTrackVoice = (data: any) => {
    this.dispatchReducer({
      type: 'SET_CURRENT_TRACK_STATE',
      payload: data
    })
  }

  setTrackIndexVoice = (index: number) => {
    this.dispatchReducer({
      type: 'SET_TRACK_INDEX_STATE',
      payload: index
    })
  }

  setDurationVoice = (duration: number) => {
    this.dispatchReducer({
      type: 'SET_DURATION_STATE',
      payload: duration
    })
  }

  setDefaultConfigTrainscript = (config: Array<VoiceRecordingModels.ScriptsConfig>) => {
    this.dispatchReducer({
      type: 'SET_CONFIG_TRAINSCRIPT_STATE',
      payload: config
    })
  }

  setVoiceDetailData = (data: VoiceRecordingModels.VoiceRecordResponse) => {
    this.dispatchReducer({
      type: 'SET_VOICE_DETAIL_STATE',
      payload: data
    })
  }

  setIsPlaying = (isPlaying: boolean) => {
    this.dispatchReducer({
      type: 'SET_PLAYING_VOICE_STATE',
      payload: isPlaying
    })
  }

  setDynamicKeyword = (data: Array<VoiceRecordingModels.DynamicKeywordC>) => {
    this.dispatchReducer({
      type: 'SET_DYNAMIC_KEYWORD_STATE',
      payload: data
    })
  }

  setApplicationInfo = (data: VoiceRecordingModels.ApplicationInfoC) => {
    this.dispatchReducer({
      type: 'SET_APPLICATION_INFO_STATE',
      payload: data
    })
  }
  setDataGirdInfo = (data: {
    keywordGroupEvaluations: Array<VoiceRecordingModels.KeywordGroupEvaluations>
    dynamicKeyword: Array<VoiceRecordingModels.DynamicKeyword>
  }) => {
    this.dispatchReducer({
      type: 'SET_DATA_GIRD_STATE',
      payload: data
    })
  }

  setTranscriptData = (data: Array<VoiceRecordingModels.Segments>) => {
    this.dispatchReducer({
      type: 'SET_TRANSCRIPT_STATE',
      payload: data
    })
  }

  setLoadingSrc = (data: boolean) => {
    this.dispatchReducer({
      type: 'SET_LOADING_SRC_STATE',
      payload: data
    })
  }
  public formatTime = (time: number) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60)
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
      const seconds = Math.floor(time % 60)
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
      return `${formatMinutes}:${formatSeconds}`
    }
    return '00:00'
  }
}
