import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { CashoutOptions } from './CashoutOptions'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.BONUS_SURRENDER)
    }),
    codec
  ])

export const BonusSurrender = Base(
  t.type({
    source: t.string,
    payload: t.type({
      body: t.type({
        policy: t.type({
          policyNo: t.string
        }),
        attributesExt: t.type({
          BONUS_AMOUNT: t.number,
          TOTAL_PAYOUT_AMOUNT: t.number,
          // bonusReserveRemainValue: t.number,
          bonusValue: t.number,
          bonusValueAmountToSurrender: t.number,
          bonusReserveValue: t.number,
          interestAplOpl: t.number,
          principalAplOpl: t.number
        }),
        cashOutOptions: Maybe(CashoutOptions)
      })
    })
  })
)
export type BonusSurrender = t.TypeOf<typeof BonusSurrender>
