import * as React from 'react'
import { Modal, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { TypeTab } from './da-rule-item'
import { CanView, useMobile, assets, RoundedButton } from '@pulseops/common'

interface Props {
  selectedTab: TypeTab
  groupName: string
  categoryName: string
  visible: boolean
  onPressClose: () => void
  onPressSubmit: () => void
}

export const AdminDeleteModal = ({
  selectedTab,
  groupName,
  categoryName,
  visible,
  onPressClose,
  onPressSubmit
}: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          alignItems: 'center',
          justifyContent: isWide ? 'center' : 'flex-end'
        }}
      >
        {/* Backdrop handle touch outside modal event  */}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onPressClose}
            style={{ position: 'absolute', height: height, width: width }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              height: height * 0.28,
              width: isWide ? 400 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('common:Delete')}</Text>
            </View>
            <TouchableOpacity onPress={onPressClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>

          {/* content */}
          <View
            style={{
              marginTop: 20,
              flex: 1,
              paddingHorizontal: 20,
              paddingVertical: 10,
              alignItems: 'center'
            }}
          >
            <Text style={{ fontSize: 16, display: 'flex', alignItems: 'center', height: '100%' }}>
              {t('RequestInfo:ConfirmDelete')}
            </Text>
          </View>
          <View
            style={[
              styles.btnGroup,
              { marginBottom: 16, marginTop: isWide ? 30 : 28, marginHorizontal: isWide ? 0 : 16 }
            ]}
          >
            <RoundedButton
              filled
              activeOpacity={0.7}
              text={t('common:Delete')}
              textStyle={styles.btnTitle}
              style={{ height: 39, width: isWide ? 108 : '100%' }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={() => {
                onPressSubmit()
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#4F4F4F',
    fontWeight: '600',
    fontSize: 20,
    marginEnd: 16
  },

  input: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 4
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
