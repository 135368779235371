import moment from 'moment'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface Independence_Check {
  tag: TransactionType.INDEPENDENT_CHECK
  eREF: string | null | undefined
  subChanel: string | null | undefined
  salesType: string | null | undefined
  ref: string | null | undefined
  createdDate: string | null | undefined
  agCAT: string | null | undefined
  agCategory: string | null | undefined
  agentCode: string | null | undefined
  agentName: string | null | undefined
  appointedDate: string | null | undefined
  terminateDate: string | null | undefined
  agentType: string | null | undefined
  title: string | null | undefined
  unitDescription: string | null | undefined
  branchDescription: string | null | undefined
  officeDescription: string | null | undefined
  subzoneDescription: string | null | undefined
  zoneDescription: string | null | undefined
  debt: string | null | undefined
  ocr: string | null | undefined
  saleZone: string | null | undefined
  asessmentDate: string | null | undefined
  assessByDesc: string | null | undefined
  reAssess: string | null | undefined
  reAssessDate: string | null | undefined
  role: string | null | undefined
  status: boolean | null | undefined
  leaderCode: string | null | undefined
  leaderCodeDescription: string | null | undefined
  regionCode: string | null | undefined
  regionDescription: string | null | undefined
  oldUnit: string | null | undefined
  terdate: string | null | undefined
  idNo: string | null | undefined
  oldIdNo: string | null | undefined
  idDate: Date | null | undefined
  idPlace: string | null | undefined
  idExpiredDate: Date | null | undefined
  dob: Date | null | undefined
  placeOfBirth: string | null | undefined
  nationality: string | null | undefined
  lastName: string | null | undefined
  firstName: string | null | undefined
  genderCode: string | null | undefined
  ethNic: string | null | undefined
  agentCodeRefer: string | null | undefined
  agentNameRefer: string | null | undefined
  phoneNumber: string | null | undefined
  companyPhone: string | null | undefined
  homePhone: string | null | undefined
  email: string | null | undefined
  zalo: string | null | undefined
  address: string | null | undefined
  district: string | null | undefined
  province: string | null | undefined
  ward: string | null | undefined
  locationCode: string | null | undefined
  AllocatedlocationCode: string | null | undefined
  Allocatedaddress: string | null | undefined
  Allocateddistrict: string | null | undefined
  Allocatedprovince: string | null | undefined
  Allocatedward: string | null | undefined
  contactlocationCode: string | null | undefined
  contactaddress: string | null | undefined
  contactdistrict: string | null | undefined
  contactprovince: string | null | undefined
  contactward: string | null | undefined
  bankName: string | null | undefined
  bankBranch: string | null | undefined
  bankAccount: string | null | undefined
  bankCode: string | null | undefined
  otherBank: string | null | undefined
  otherBankBranch: string | null | undefined
  taxCode: string | null | undefined
  educationCode: string | null | undefined
  specialization: string | null | undefined
  income: string | null | undefined
  numberOfChildren: string | null | undefined
  marriedCode: string | null | undefined
  officeCode: string | null | undefined
  occupationCode: string | null | undefined
  officeCodes: string | null | undefined
  hasAListOfPotentialCustomers: string | null | undefined
  processInstanceId: string | null | undefined
  structure: any | null | undefined
  source: string
  transactionType: string | null | undefined
  newUnit: string | null | undefined
  businessKey: string | null | undefined
  id: string | null | undefined
  taskId: string | null | undefined
  galleries: string | null | undefined
  clientId: string | null | undefined
  newLeaderName: string | null | undefined
  newLeaderCode: string | null | undefined
  leaderName: string | null | undefined
  oldLeaderCode: string | null | undefined
  oldLeaderName: string | null | undefined
  access_token: string | null | undefined
  action: string | null | undefined
  additionalProperties: any | null | undefined
  authenOption: string | null | undefined
  authenRequestForm: string | null | undefined
  correlationId: string | null | undefined
  createdBy: string | null | undefined
  documents: string | null | undefined
  errorMsg: string | null | undefined
  exchangeId: string | null | undefined
  functionUI: string | null | undefined
  idNumber: string | null | undefined
  isCCE: string | null | undefined
  lasStatus: string | null | undefined
  location: string | null | undefined
  msgId: string | null | undefined
  otpTransaction: any | null | undefined
  primaryPolicyNo: string | null | undefined
  refresh_token: string | null | undefined
  systemName: string | null | undefined
  timestamp: string | null | undefined
  transType: string | null | undefined
  agentCodes: string | null | undefined
}

export const IndependenceCheck = (detail: api.IndependenceCheck): Independence_Check => {
  const payload = detail.payload.body
//   const CreatedDate = detail.payload.createdDate
//   const structure = detail
  return payload
}
