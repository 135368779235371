import * as React from 'react'
import { Modal, ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import {
  assets,
  Select,
  useMobile,
  RoundedButton,
  form2,
  SelectSearch,
  CanView,
  SelectOption,
  InquiryComplaintService,
  TransactionTypeSelectOptions,
  Category,
  ErrorHandling,
  DatePickerCircel,
  useTranslator
} from '@pulseops/common'
import { Controller } from 'react-hook-form'
import { CepFilterForm } from '../CEPFormSearch'
import { sourceOptions } from './CepFilterFormTypes'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { CepFormConst } from '../CEPFormConst'
interface Props {
  visible: boolean
  onPressClose?: () => void
  onPressSubmit: (data: CepFilterForm.Validated) => void
  onPressClean: () => void
}

export const CepFilterTransactionModal = ({ visible, onPressClose, onPressSubmit, onPressClean }: Props) => {
  const { isWide, isMobile } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t, i18n } = useTranslation()
  const cep = useTranslator('CustomerEngagement').t
  const [subServiceTypeList, setSubServiceTypeList] = React.useState<SelectOption[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<(SelectOption & { typeCode: string })[]>(
    []
  )
  const defaultValues: CepFilterForm.Raw = {
    transactionType: [],
    source: [],
    category: CepFormConst.ServiceInquiryCategories[0],
    subServiceType: [],
    subTransactionType: [],
    toDate: new Date(),
    fromDate: new Date()
  }
  const { base, handleSubmit } = form2.useForm(CepFilterForm.codec, {
    defaultValues: defaultValues
  })

  const { control } = base

  const currentDate = new Date()

  pipe(
    InquiryComplaintService.getTypes(),
    ZIO.map((typeList) => {
      if (typeList && typeList.length > 0) {
        setSubServiceTypeList(
          typeList.slice().map((x) => ({
            label: i18n.language === 'en' ? x.name : x.nameVi,
            value: x.code
          }))
        )
      } else {
        setSubServiceTypeList([])
      }
    }),
    ErrorHandling.runDidMount([])
  )
  // load sub transaction type
  pipe(
    InquiryComplaintService.getSubType(),
    ZIO.map((typeList) => {
      if (typeList && typeList.length > 0) {
        setSubTransactionTypeList(
          typeList.slice().map((x) => ({
            label: i18n.language === 'en' ? x.name : x.nameVi,
            value: x.code,
            typeCode: x.typeCode
          }))
        )
      } else {
        setSubTransactionTypeList([])
      }
    }),
    ErrorHandling.runDidMount([])
  )

  const onSubmit = () => {
    handleSubmit((value) => {
      onPressSubmit && onPressSubmit(value)
    })()
  }

  const transOption = React.useMemo(() => {
    return TransactionTypeSelectOptions.filter((x) => x.category === base.watch('category')?.value).map((item) => ({
      label: t(item.label),
      value: item.value
    }))
  }, [base.watch('category')?.value])

  const categoryOptions = React.useMemo(() => {
    return CepFormConst.ServiceInquiryCategories.map((item) => ({
      label: t(`Reports:${item.label}`),
      value: item.value
    }))
  }, [])

  const subTransactionTypeOpt = React.useMemo(() => {
    return subTransactionTypeList.filter((x) =>
      base
        .watch('subServiceType')
        .map((_) => _.value)
        .includes(x.typeCode)
    )
  }, [base.watch('subServiceType')])

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          justifyContent: isWide ? 'center' : 'flex-end',
          alignItems: 'center'
        }}
      >
        {/* Backdrop handle touch outsidem modal event*/}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onPressClose}
            style={{
              width: width,
              height: height,
              position: 'absolute'
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              height: height * 0.7,
              width: isWide ? 560 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('TaskManagement:Filter')}</Text>
            </View>
            <TouchableOpacity onPress={onPressClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          {/* Filter Inputs */}
          <View style={{ marginTop: 20, flex: 1, paddingHorizontal: 20 }}>
            <ScrollView style={{ paddingBottom: isWide ? 0 : 16 }}>
              <View style={[styles.sectionRow, { justifyContent: 'space-between' }]}>
                <View style={styles.col_45}>
                  <View>
                    <Controller
                      name="fromDate"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePickerCircel
                          required
                          label={t('ServiceInquiry:FromDate')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={styles.col_45}>
                  <View>
                    <Controller
                      name="toDate"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePickerCircel
                          required
                          label={t('ServiceInquiry:ToDate')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                </View>
              </View>

              <View style={[styles.sectionRow, { marginTop: 16 }]}>
                <View style={styles.col_1}>
                  <Controller
                    name={'category'}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={cep('Category')}
                        options={categoryOptions}
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15, marginBottom: 8 }}
                        containerStyles={styles.inputStyle}
                        showUnderline={false}
                        onChange={(e) => {
                          field.onChange(e)
                          base.setValue('transactionType', [])
                          base.setValue('subServiceType', [])
                          base.setValue('subTransactionType', [])
                        }}
                        onBlur={field.onBlur}
                        popupIcon={<assets.IconCepArrowDownRed />}
                      />
                    )}
                  />
                </View>
              </View>

              <View
                style={[
                  styles.sectionRow,
                  { marginTop: 16 },
                  base.watch('category')?.value === '' ? { display: 'none' } : {}
                ]}
              >
                <View style={styles.col_1}>
                  <Controller
                    name={'transactionType'}
                    control={control}
                    render={({ field }) => (
                      <SelectSearch
                        {...field}
                        label={t('TaskManagement:TransactionType')}
                        labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15, marginBottom: 8 }}
                        options={transOption}
                        multiple={true}
                        inputStyle={styles.inputStyle}
                        disableUnderline={true}
                        showPopupIcon={true}
                        popupIcon={<assets.IconCepArrowDownRed />}
                      />
                    )}
                  />
                </View>
              </View>

              <View
                style={[
                  styles.sectionRow,
                  { justifyContent: 'space-between', marginTop: 16 },
                  ![Category.INQUIRY, Category.COMPLAINT].includes(base.watch('category')?.value as Category) && {
                    display: 'none'
                  }
                ]}
              >
                <View style={styles.col_45}>
                  <Controller
                    name="subServiceType"
                    control={base.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectSearch
                        label={t('ServiceInquiry:SubServiceType')}
                        labelStyle={{ marginBottom: 8 }}
                        options={subServiceTypeList}
                        placeholder={t('common:Select')}
                        onChange={(e) => {
                          onChange(e)
                          base.setValue('subTransactionType', [])
                        }}
                        onBlur={onBlur}
                        value={value}
                        multiple={true}
                        inputStyle={styles.inputStyle}
                        disableUnderline={true}
                        popupIcon={<assets.IconCepArrowDownRed />}
                      />
                    )}
                  />
                </View>
                <View style={styles.col_45}>
                  <Controller
                    name="subTransactionType"
                    control={base.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectSearch
                        label={t('ServiceInquiry:SubTransactionType')}
                        labelStyle={{ marginBottom: 8 }}
                        options={subTransactionTypeOpt}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        multiple={true}
                        inputStyle={styles.inputStyle}
                        disableUnderline={true}
                        popupIcon={<assets.IconCepArrowDownRed />}
                      />
                    )}
                  />
                </View>
              </View>

              <View style={[styles.sectionRow, { marginTop: 16 }]}>
                <View style={styles.col_1}>
                  <Controller
                    name="source"
                    control={base.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SelectSearch
                        label={t('ServiceInquiry:Source')}
                        labelStyle={{ marginBottom: 8 }}
                        options={sourceOptions}
                        placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        multiple={true}
                        inputStyle={styles.inputStyle}
                        disableUnderline={true}
                        popupIcon={<assets.IconCepArrowDownRed />}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={[styles.sectionRow, { marginTop: 6 }]}>
                <Text style={{ color: '#ED1B2E' }}>{_.get(base.formState.errors, '.message')}</Text>
              </View>
            </ScrollView>
          </View>

          {/* Apply Button */}

          <View
            style={[
              styles.btnGroup,
              { paddingTop: isWide ? 40 : 24 },
              isMobile
                ? {
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,

                    elevation: 5
                  }
                : {}
            ]}
          >
            <RoundedButton
              filled
              activeOpacity={0.7}
              text={t('TaskManagement:Apply')}
              textStyle={styles.btnTitle}
              style={{ height: 39 }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={onSubmit}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#000000',
    fontWeight: '700',
    fontSize: 20,
    marginEnd: 16
  },

  breadcumbCotnainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },

  breadcumbItem: {
    fontSize: 14,
    color: '#828282'
  },

  arrowIcon: {
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 25
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },

  row: {
    alignItems: 'flex-end'
  },

  startItem: {
    width: 250
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 8
  },

  error: {
    color: 'red',
    fontSize: 12
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_2: {
    width: '100%',
    maxWidth: '50%'
  },
  col_1: {
    width: '100%',
    maxWidth: '100%'
  },
  inputStyle: {
    borderColor: '#D3DCE6',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 3
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  }
})
