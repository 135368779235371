export namespace InquiryComplaintConst {
  export interface Group {
    code: string
    parentCode: string
    nameVi: string
    name: string
  }

  export const VeriReceivedUserInfo = {
    receivedBy: '',
    receivedFrom: '',
    officeLocation: '',
    receivedDate: '',
    estimationClosingDate: '',
    TAT: ''
  }

  export const generalAgencyList: Array<Group> = [
    {
      code: 'B13',
      nameVi: 'ACB LE LOI ',
      name: 'ACB LE LOI',
      parentCode: ''
    },
    {
      code: 'B14',
      nameVi: 'ACB KY HOA',
      name: 'ACB KY HOA',
      parentCode: ''
    },
    {
      code: 'B15',
      nameVi: 'ACB HOAHUN',
      name: 'ACB HOAHUN',
      parentCode: ''
    },
    {
      code: 'B16',
      nameVi: 'ACB NTHOC',
      name: 'ACB NTHOC',
      parentCode: ''
    },
    {
      code: 'B17',
      nameVi: 'ACB BMT',
      name: 'ACB BMT',
      parentCode: ''
    }
  ]

  export const requestorRoleList = [
    {
      value: '1',
      label: 'PolicyOwner'
    },
    {
      value: '2',
      label: 'LifeAssured'
    },
    {
      value: '3',
      label: 'Beneficiary'
    },
    {
      value: '4',
      label: 'Others'
    }
  ]
  export const methodList = [
    {
      value: 'MT01',
      text: 'Walk-in'
    },
    {
      value: 'MT02',
      text: 'Call in'
    },
    {
      value: 'MT03',
      text: 'Call out'
    },
    {
      value: 'MT04',
      text: 'Digital Channels'
    },
    {
      value: 'MT05',
      text: 'Return Mail'
    },
    {
      value: 'MT06',
      text: 'Snail Mail'
    },
    {
      value: 'MT07',
      text: 'E-mail'
    },
    {
      value: 'MT08',
      text: 'Outsource'
    },
    {
      value: 'MT09',
      text: 'Survey'
    },
    {
      value: 'MT10',
      text: 'Partner'
    },
    {
      value: 'MT11',
      text: 'Social Media'
    }
  ]
  export const sourceList: Array<Group> = [
    {
      code: 'SO01',
      nameVi: 'Văn phòng Tổng Đại Lý',
      name: 'GA',
      parentCode: ''
    },
    {
      code: 'SO02',
      nameVi: 'Văn phòng TĐL Blue Diamond',
      name: 'GA Blue Diamond',
      parentCode: ''
    },
    {
      code: 'SO03',
      nameVi: 'PD/Banca',
      name: 'PD/Banca',
      parentCode: ''
    },
    {
      code: 'SO04',
      nameVi: 'Báo chí',
      name: 'Press/News',
      parentCode: ''
    },
    {
      code: 'SO05',
      nameVi: 'Bộ Tài Chính',
      name: 'MOF',
      parentCode: ''
    },
    {
      code: 'SO06',
      nameVi: 'Công An',
      name: 'Police',
      parentCode: ''
    },
    {
      code: 'S007',
      nameVi: 'Cơ quan/ Chính quyền địa phương',
      name: 'Local Government',
      parentCode: ''
    },
    {
      code: 'SO08',
      nameVi: 'Đài Phát Thanh',
      name: 'Radio stations',
      parentCode: ''
    },
    {
      code: 'SO09',
      nameVi: 'Đài Truyền Hình',
      name: 'Broadcaster',
      parentCode: ''
    },
    {
      code: 'SO10',
      nameVi: 'Hiệp hội bảo vệ Người Tiêu Dùng',
      name: 'Consumer Protection Association',
      parentCode: ''
    },
    {
      code: 'SO11',
      nameVi: 'Hiệp hội Bảo Hiểm',
      name: 'Insurance Association',
      parentCode: ''
    },
    {
      code: 'SO12',
      nameVi: 'Văn phòng luật sư',
      name: 'Law office',
      parentCode: ''
    },
    {
      code: 'SO13',
      nameVi: 'Tòa Án',
      name: 'Court',
      parentCode: ''
    },
    {
      code: 'SO14',
      nameVi: 'Tổng Đài',
      name: 'Hotline',
      parentCode: ''
    },
    {
      code: 'SO15',
      nameVi: 'BP Chăm sóc KH',
      name: 'Customer Care',
      parentCode: ''
    },
    {
      code: 'SO16',
      nameVi: 'BP Điều tra',
      name: 'Investigation',
      parentCode: ''
    },
    {
      code: 'SO17',
      nameVi: 'Nội bộ OP',
      name: 'Internal OP',
      parentCode: ''
    },
    {
      code: 'SO18',
      nameVi: 'Tư vấn/bán hàng qua điện thoại',
      name: 'Tele Marketing',
      parentCode: ''
    },
    {
      code: 'SO19',
      nameVi: 'Chương trình Retention',
      name: 'Retention Program',
      parentCode: ''
    },
    {
      code: 'SO20',
      nameVi: 'Pru-Online',
      name: 'Pru-Online',
      parentCode: ''
    },
    {
      code: 'SO21',
      nameVi: 'Ekiosk',
      name: 'Ekiosk',
      parentCode: ''
    },
    {
      code: 'SO22',
      nameVi: 'Website',
      name: 'Website',
      parentCode: ''
    },
    {
      code: 'SO23',
      nameVi: 'Zalo',
      name: 'Zalo',
      parentCode: ''
    },
    {
      code: 'SO24',
      nameVi: 'Pulse',
      name: 'Pulse',
      parentCode: ''
    },
    {
      code: 'SO25',
      nameVi: 'Bank',
      name: 'Bank',
      parentCode: ''
    },
    {
      code: 'SO26',
      nameVi: 'Momo',
      name: 'Momo',
      parentCode: ''
    },
    {
      code: 'SO27',
      nameVi: 'VNP',
      name: 'VNP',
      parentCode: ''
    },
    {
      code: 'SO28',
      nameVi: 'Insmart',
      name: 'Insmart',
      parentCode: ''
    },
    {
      code: 'SO29',
      nameVi: 'Facebook',
      name: 'Facebook',
      parentCode: ''
    },
    {
      code: 'SO30',
      nameVi: 'Youtube',
      name: 'Youtube',
      parentCode: ''
    },
    {
      code: 'SO31',
      nameVi: 'Welcome Call',
      name: 'Welcome Call',
      parentCode: ''
    },
    {
      code: 'SO32',
      nameVi: 'Customer Ring',
      name: 'Customer Ring',
      parentCode: ''
    },
    {
      code: 'SO33',
      nameVi: 'RoP',
      name: 'RoP',
      parentCode: ''
    }
  ]
  export const relationshipList: Array<Group> = [
    {
      code: 'R001',
      nameVi: 'Cha/ Mẹ',
      name: 'Parents',
      parentCode: ''
    },
    {
      code: 'R002',
      nameVi: 'Vợ/ Chồng',
      name: 'Spouse',
      parentCode: ''
    },
    {
      code: 'R003',
      nameVi: 'Con',
      name: 'Children',
      parentCode: ''
    },
    {
      code: 'R004',
      nameVi: 'Anh/Chị/Em',
      name: 'Siblings',
      parentCode: ''
    },
    {
      code: 'R005',
      nameVi: 'Anh/Chị/Em họ',
      name: 'Cousin',
      parentCode: ''
    },
    {
      code: 'R006',
      nameVi: 'Cô/Dì/Cậu/Chú/Bác',
      name: 'Aunt/ Uncle',
      parentCode: ''
    },
    {
      code: 'R007',
      nameVi: 'Ông/ Bà',
      name: 'Grand Parents',
      parentCode: ''
    },
    {
      code: 'R008',
      nameVi: 'Cháu',
      name: 'Grand Children/ Niece/ Nephew',
      parentCode: ''
    },
    {
      code: 'R009',
      nameVi: 'Người được ủy quyền',
      name: 'Trustee',
      parentCode: ''
    },
    {
      code: 'R010',
      nameVi: 'Quan hệ khác',
      name: 'Another Relationship',
      parentCode: ''
    }
  ]

  export const receiptTypeList: Array<Group> = [
    {
      code: 'RC01',
      nameVi: 'Phiếu thu phí tạm tính (Hồ sơ mới)',
      name: 'Temporary receipt',
      parentCode: ''
    },
    {
      code: 'RC02',
      nameVi: 'Phiếu thu phí định kỳ',
      name: 'Renewal bill',
      parentCode: ''
    },
    {
      code: 'RC03',
      nameVi: 'Xác nhận điện tử thu phí tạm tính (Hồ sơ mới)',
      name: 'E-Temporary receipt',
      parentCode: ''
    },
    {
      code: 'RC04',
      nameVi: 'Xác nhận điện tử thu phí định kỳ',
      name: 'E-Renewal bill',
      parentCode: ''
    },
    {
      code: 'RC05',
      nameVi: 'Biên nhận viết tay',
      name: 'Handwritten receipt',
      parentCode: ''
    },
    {
      code: 'RC06',
      nameVi: 'Các loại phiếu thu khác',
      name: 'Other receipt type',
      parentCode: ''
    }
  ]

  export const financialNeedAnalysisList: Array<Group> = [
    {
      code: 'FA01',
      nameVi: 'Bảo hiểm',
      name: 'Insured',
      parentCode: ''
    },
    {
      code: 'FA02',
      nameVi: 'Tiết kiệm/ tích lũy tài chính',
      name: 'Saving/ Accumulation',
      parentCode: ''
    },
    {
      code: 'FA03',
      nameVi: 'Đầu tư',
      name: 'Investment',
      parentCode: ''
    }
  ]

  export const VIPLevel = [
    {
      code: 'D',
      name: 'Diamond',
      nameVi: 'Kim cương'
    },
    {
      code: 'P',
      name: 'Platinum',
      nameVi: 'Bạch kim'
    },
    {
      code: 'G',
      name: 'Gold',
      nameVi: 'Vàng'
    },
    {
      code: '',
      name: 'Bacsic',
      nameVi: 'Cơ bản'
    }
  ]

  // export const groupList: Array<Group> = [
  //     {
  //         code: 'CHG4',
  //         parentCode: 'CO01',
  //         nameVi: 'Khiếu nại văn phòng Tổng Đại lý',
  //         name: "GA Operators",
  //     },
  //     {
  //         code: 'CHG1',
  //         parentCode: 'CO01',
  //         nameVi: 'Khiếu nại chung',
  //         name: "Regular",
  //     },
  //     {
  //         code: 'CHG2',
  //         parentCode: 'CO01',
  //         nameVi: 'Khiếu nại về chiếm dụng phí/ quyền lợi',
  //         name: 'Money misappropriation',
  //     },
  //     {
  //         code: 'CHG3',
  //         parentCode: 'CO01',
  //         nameVi: 'Khiếu nại về hoạt động bán hàng',
  //         name: 'Sales practice',
  //     },
  //     {
  //         code: 'CSG1',
  //         parentCode: 'IN01',
  //         nameVi: 'Giải quyết Quyền lợi Bảo hiểm và Chi trả',
  //         name: 'Claims and Payments',
  //     },
  //     {
  //         code: 'CSG2',
  //         parentCode: 'IN01',
  //         nameVi: 'Nộp phí và hóa đơn nộp phí',
  //         name: 'Premium Collection and Receipts ',
  //     },
  //     {
  //         code: 'CSG3',
  //         parentCode: 'IN01',
  //         nameVi: 'Tư vấn và Dịch vụ khách hàng',
  //         name: 'Consultancy and Customer Services',
  //     },
  //     {
  //         code: 'CSG4',
  //         parentCode: 'IN01',
  //         nameVi: 'Chăm sóc khách hàng và chương trình PRURewards',
  //         name: 'Customer Care & PRURewards',
  //     },
  //     {
  //         code: 'CSG5',
  //         parentCode: 'IN01',
  //         nameVi: 'Thẩm định Sức khỏe và Hồ sơ',
  //         name: 'Medical exam and Underwriting',
  //     },
  //     {
  //         code: 'CSG6',
  //         parentCode: 'IN01',
  //         nameVi: 'Cập nhật thông tin Hợp đồng và Thư xác nhận',
  //         name: 'Policy Update and Confirmation Letter',
  //     },
  //     {
  //         code: 'CSG7',
  //         parentCode: 'IN01',
  //         nameVi: 'Sản phẩm và Điều khoản Hợp đồng',
  //         name: 'Product, Terms and Conditions',
  //     },
  //     {
  //         code: 'CSG8',
  //         parentCode: 'IN01',
  //         nameVi: 'Giao dịch trên các ứng dụng',
  //         name: 'Services on PVA platforms',
  //     },
  //     {
  //         code: 'CSG9',
  //         parentCode: 'IN01',
  //         nameVi: 'Khác (không phải các nội dung trên)',
  //         name: 'Others (not list as above)',
  //     }
  // ]
  // export const subServiceTypeList: Array<Group> = [
  //     {
  //         code: 'CH01', parentCode: 'CHG1', nameVi: 'Thông báo định kỳ', name: 'Billing',
  //     },
  //     {
  //         code: 'CH02', parentCode: 'CHG1', nameVi: 'Tổng đài chăm sóc khách hàng', name: 'Call center',
  //     },
  //     {
  //         code: 'CH03', parentCode: 'CHG1', nameVi: 'Kênh giao dịch trực tuyến', name: 'Digital Channels',
  //     },
  //     {
  //         code: 'CH04', parentCode: 'CHG1', nameVi: 'Giải quyết quyền lợi bảo hiểm', name: 'Claims related',
  //     },
  //     {
  //         code: 'CH05', parentCode: 'CHG1', nameVi: 'Trung tâm Dịch vụ', name: 'CSC',
  //     },
  //     {
  //         code: 'CH06', parentCode: 'CHG1', nameVi: 'Bảo mật thông tin', name: 'Customer data privacy',
  //     },
  //     {
  //         code: 'CH07', parentCode: 'CHG1', nameVi: 'Hoạt động hỗ trợ kinh doanh (DS)', name: 'Distribution support',
  //     },
  //     {
  //         code: 'CH08', parentCode: 'CHG1', nameVi: 'Chậm trễ', name: 'Failure to Deliver',
  //     },
  //     {
  //         code: 'CH11', parentCode: 'CHG1', nameVi: 'Hoạt động tiếp thị, bán hàng', name: 'Marketing related',
  //     },
  //     {
  //         code: 'CH15', parentCode: 'CHG1', nameVi: 'Thẩm định', name: 'NBUW',
  //     },
  //     {
  //         code: 'CH16', parentCode: 'CHG1', nameVi: 'Dịch vụ thu/ chi', name: 'Payment Services'
  //     },
  //     {
  //         code: 'CH17', parentCode: 'CHG1', nameVi: 'Điều chỉnh hợp đồng bảo hiểm', name: 'Policy Alteration',
  //     },
  //     {
  //         code: 'CH18', parentCode: 'CHG1', nameVi: 'Giá trị hợp đồng bảo hiểm', name: 'Policy Valuation',
  //     },
  //     {
  //         code: 'CH19', parentCode: 'CHG1', nameVi: 'Sản phẩm', name: 'Product related',
  //     },
  //     {
  //         code: 'CH21', parentCode: 'CHG1', nameVi: 'Cổng thông tin khách hàng PruOnline', name: 'Pruonline',
  //     },
  //     {
  //         code: 'CH22', parentCode: 'CHG1', nameVi: 'Chương trình chăm sóc khách hàng PRUrewards', name: 'PruReward',
  //     },
  //     {
  //         code: 'CH23', parentCode: 'CHG1', nameVi: 'Chương trình chăm sóc/ gìn giữ khách hàng', name: 'Retention',
  //     },
  //     {
  //         code: 'CH24', parentCode: 'CHG1', nameVi: 'Thiếu sót của TVV trong phục vụ khách hàng', name: 'Sales service performance',
  //     },
  //     {
  //         code: 'CH25', parentCode: 'CHG1', nameVi: 'Khiếu nại về SMS', name: 'SMS related',
  //     },
  //     {
  //         code: 'CH09', parentCode: 'CHG4', nameVi: 'Hoạt động của Văn phòng Tổng Đại lý', name: 'GA operation',
  //     },
  //     {
  //         code: 'CH14', parentCode: 'CHG2', nameVi: 'Chiếm dụng tiền của khách hàng', name: 'Money misappropriation',
  //     },
  //     {
  //         code: 'CH10', parentCode: 'CHG3', nameVi: 'Sử dụng các phương thức bán hàng sai trái', name: 'Improper Sale Solicitation',
  //     },
  //     {
  //         code: 'CH12', parentCode: 'CHG3', nameVi: 'Hành vi gian lận', name: 'Misconduct',
  //     },
  //     {
  //         code: 'CH13', parentCode: 'CHG3', nameVi: 'Cung cấp thông tin sai lệch', name: 'Misrepresentation',
  //     },
  //     {
  //         code: 'CH20', parentCode: 'CHG3', nameVi: 'Sản phẩm/ tư vấn không phù hợp', name: 'Product/Advise unsuitability',
  //     },
  //     {//Claims and Payments
  //         code: 'CS01', parentCode: 'CSG1', nameVi: 'Chi trả', name: 'Payments',
  //     },
  //     {
  //         code: 'CS02', parentCode: 'CSG1', nameVi: 'Giải quyết Quyền lợi Bảo hiểm', name: 'Claims',
  //     },
  //     {//Premium Collection and Receipts
  //         code: 'CS03', parentCode: 'CSG2', nameVi: 'Nộp phí', name: 'Premium Collection',
  //     },
  //     {
  //         code: 'CS04', parentCode: 'CSG2', nameVi: 'Hóa đơn/Biên lai nộp phí', name: 'Receipts',
  //     },
  //     {
  //         code: 'CS05', parentCode: 'CSG3', nameVi: 'Tư vấn viên phục vụ', name: 'Servicing Agent',
  //     },
  //     {
  //         code: 'CS06', parentCode: 'CSG3', nameVi: 'Chương trình khuyến mãi', name: 'Promotion campaigns',
  //     },
  //     {
  //         code: 'CS07', parentCode: 'CSG3', nameVi: 'Dịch vụ khách hàng tại quầy', name: 'CS Center or GA',
  //     },
  //     {//Consultancy and Customer Services
  //         code: 'CS08', parentCode: 'CSG3', nameVi: 'Dịch vụ khách hàng tại Tổng đài', name: 'Contact Center',
  //     },
  //     {//Customer Care & PRURewards
  //         code: 'CS09', parentCode: 'CSG4', nameVi: 'Chăm sóc khách hàng', name: 'Customer Care'
  //     },
  //     {
  //         code: 'CS10', parentCode: 'CSG4', nameVi: 'Chương trình PRURewards', name: 'PRURewards'
  //     },
  //     {
  //         code: 'CS11', parentCode: 'CSG5', nameVi: 'Thẩm định sức khỏe', name: 'Medical exam'
  //     },
  //     {
  //         code: 'CS12', parentCode: 'CSG5', nameVi: 'Thẩm định hồ sơ', name: 'Underwriting'
  //     },
  //     {
  //         code: 'CS13', parentCode: 'CSG6', nameVi: 'Cập nhật thông tin Hợp đồng', name: 'Policy Update'
  //     },
  //     {
  //         code: 'CS14', parentCode: 'CSG6', nameVi: 'Thư xác nhận', name: 'Confirmation Letter'
  //     },
  //     {
  //         code: 'CS23', parentCode: 'CSG9', nameVi: 'Ý kiến đóng góp', name: 'Other feedback'
  //     },
  //     {
  //         code: 'CS24', parentCode: 'CSG9', nameVi: 'Đề xuất', name: 'Recommendation'
  //     },
  //     {
  //         code: 'CS25', parentCode: 'CSG9', nameVi: 'Thắc mắc khác', name: 'Other queries '
  //     },
  //     {
  //         code: 'CS15', parentCode: 'CSG7', nameVi: 'Điều khoản hợp đồng', name: 'Terms and Conditions'
  //     },
  //     {
  //         code: 'CS16', parentCode: 'CSG7', nameVi: 'Sản phẩm ', name: 'Product'
  //     },
  //     {
  //         code: 'CS17', parentCode: 'CSG8', nameVi: 'Ứng dụng Pulse', name: 'Pulse'
  //     },
  //     {
  //         code: 'CS18', parentCode: 'CSG8', nameVi: 'Zalo', name: 'Zalo'
  //     },
  //     {
  //         code: 'CS19', parentCode: 'CSG8', nameVi: 'PRUBot & Livechat', name: 'PRUBot & Livechat'
  //     },
  //     {
  //         code: 'CS20', parentCode: 'CSG8', nameVi: 'Tin nhắn từ Prudential', name: 'SMS'
  //     },
  //     {
  //         code: 'CS21', parentCode: 'CSG8', nameVi: 'PRUDaily', name: 'PRUDaily'
  //     },
  //     {
  //         code: 'CS22', parentCode: 'CSG8', nameVi: 'PRUSmart', name: 'PRUSmart'
  //     }
  // ]
  // export const subTransactionType: Array<Group> = [
  //     {
  //         code: 'CH0101', parentCode: 'CH01', nameVi: 'Sai sót trên Thư thường niên', name: 'Error on APS letters'
  //     },
  //     {
  //         code: 'CH0102', parentCode: 'CH01', nameVi: 'Sai sót trên Thư báo Quyền lợi HĐBH (Tiền mặt)', name: "Error on Policy's benefit letters"
  //     },
  //     {
  //         code: 'CH0103', parentCode: 'CH01', nameVi: 'Thông tin sai sót trên Thư báo tình trạng HĐBH', name: 'Error on Premium/APL/Lapsed letters'
  //     },
  //     {
  //         code: 'CH0104', parentCode: 'CH01', nameVi: 'Không nhận được Thư thường niên', name: 'No receive of APS letters'
  //     },
  //     {
  //         code: 'CH0105', parentCode: 'CH01', nameVi: 'Không nhận được Thư báo tình trạng HĐBH', name: 'No receive of Premium/APL/Lapsed letters'
  //     },
  //     {
  //         code: 'CH0106', parentCode: 'CH01', nameVi: 'Không nhận được Thư báo Quyền lợi HĐ(Tiền mặt)', name: "No receive of Policy's benefit letters"
  //     },
  //     {
  //         code: 'CH0107', parentCode: 'CH01', nameVi: 'Các vấn đề khác', name: 'Other billing issues'
  //     },
  //     {
  //         code: 'CH0201', parentCode: 'CH02', nameVi: 'Khó kết nối với Tổng đài viên', name: "Difficult in geting through the hotline"
  //     },
  //     {
  //         code: 'CH0202', parentCode: 'CH02', nameVi: 'Thiếu kiến thức chuyên môn', name: "Lack of knowledge/expertise"
  //     },
  //     {
  //         code: 'CH0203', parentCode: 'CH02', nameVi: 'Thời gian chờ cuộc gọi lâu', name: "Long waiting "
  //     },
  //     {
  //         code: 'CH0204', parentCode: 'CH02', nameVi: 'Không/Chậm trả lời', name: "No/Delay response"
  //     },
  //     {
  //         code: 'CH0205', parentCode: 'CH02', nameVi: 'Các vấn đề khác', name: "Other contact center issues"
  //     },
  //     {
  //         code: 'CH0206', parentCode: 'CH02', nameVi: 'Cung cấp thông tin không chính xác', name: "Provide wrong information"
  //     },
  //     {
  //         code: 'CH0207', parentCode: 'CH02', nameVi: 'Thái độ/Giao tiếp thiếu chuyên nghiệp', name: "Unprofessional attitute/communication of staff"
  //     },
  //     {
  //         code: 'CH0401', parentCode: 'CH04', nameVi: 'Chậm trễ xử lý y/c giải quyết quyền lợi BH', name: "Delay in claim processing"
  //     },
  //     {
  //         code: 'CH0402', parentCode: 'CH04', nameVi: 'Ko đồng ý với quyết định giải quyết QLBH', name: "Disagreement on the basis for decision"
  //     },
  //     {
  //         code: 'CH0403', parentCode: 'CH04', nameVi: 'Ko đồng tình với cơ sở từ chối giải quyết QLBH', name: "Disagreement on the basis for declination"
  //     },
  //     {
  //         code: 'CH0404', parentCode: 'CH04', nameVi: 'Tranh chấp của những người thụ hưởng', name: "Dispute on payee of claim benefit"
  //     },
  //     {
  //         code: 'CH0405', parentCode: 'CH04', nameVi: 'Tính sai số tiền chi trả quyền lợi bảo hiểm', name: "Error in calculation of claim amount"
  //     },
  //     {
  //         code: 'CH0406', parentCode: 'CH04', nameVi: 'Thông báo không rõ ràng với khách hàng', name: "No/unclear communication with customer"
  //     },
  //     {
  //         code: 'CH0407', parentCode: 'CH04', nameVi: 'Các vấn đề khác', name: "Other Claims issues"
  //     },
  //     {
  //         code: 'CH0408', parentCode: 'CH04', nameVi: 'Tư cách/Thái độ thiếu chuyên nghiệp của NV', name: "Unprofessional attitute/behavior"
  //     },
  //     {
  //         code: 'CH0409', parentCode: 'CH04', nameVi: 'Thủ tục giải quyết QLBH không hợp lý', name: "Unreasonable claims procedures"
  //     },
  //     {
  //         code: 'CH0501', parentCode: 'CH05', nameVi: 'Thiếu kiến thức chuyên môn', name: "Lack of knowledge/expertise - CSC"
  //     },
  //     {
  //         code: 'CH0502', parentCode: 'CH05', nameVi: 'Thời gian chờ đến lượt giao dịch lâu', name: "Long queues"
  //     },
  //     {
  //         code: 'CH0503', parentCode: 'CH05', nameVi: 'Các vấn đề khác', name: "Other issues of CSC"
  //     },
  //     {
  //         code: 'CH0504', parentCode: 'CH05', nameVi: 'Thái độ/Giao tiếp thiếu chuyên nghiệp', name: "Unprofessional attitude/communication of staff"
  //     },
  //     {
  //         code: 'CH0505', parentCode: 'CH05', nameVi: 'Cung cấp thông tin không chính xác', name: "Provide wrong information - CSC"
  //     },
  //     {
  //         code: 'CH0301', parentCode: 'CH03', nameVi: 'Góp ý về hệ thống Chatbot', name: "Chatbot Recommendation"
  //     },
  //     {
  //         code: 'CH0302', parentCode: 'CH03', nameVi: 'Góp ý về hệ thống Zalo', name: "Zalo Recommendation"
  //     },
  //     {
  //         code: 'CH0303', parentCode: 'CH03', nameVi: 'Góp ý về hệ thống Ekiosk', name: "Ekiosk Recommendation"
  //     },
  //     {
  //         code: 'CH0304', parentCode: 'CH03', nameVi: 'Zalo-Y/cầu điều chỉnh của KH ko được cập nhật', name: "Zalo-Request not update after Customer modify"
  //     },
  //     {
  //         code: 'CH0305', parentCode: 'CH03', nameVi: 'Ekiosk-Ycầu điều chỉnh của KH ko được cập nhật', name: "Ekiosk-ReQ. not update after Customer modify"
  //     },
  //     {
  //         code: 'CH0306', parentCode: 'CH03', nameVi: 'Các vấn đề khác', name: "Others Digital Channels issues"
  //     },
  //     {
  //         code: 'CH0307', parentCode: 'CH03', nameVi: 'Pulse-Y/cầu điều chỉnh của KH ko được cập nhật', name: "Pulse-Request not update after Customer modify"
  //     },
  //     {
  //         code: 'CH0308', parentCode: 'CH03', nameVi: 'Góp ý về hệ thống Pulse', name: "Pulse Recommendation"
  //     },
  //     {
  //         code: 'CH0601', parentCode: 'CH06', nameVi: 'Các vi phạm về quy định bảo mật thông tin KH', name: "Customer data privacy violation"
  //     },
  //     {
  //         code: 'CH0701', parentCode: 'CH07', nameVi: 'Ko phân công TVV mới sau khi TVV cũ nghỉ việc', name: "No assignment after the servicing AG ter."
  //     },
  //     {
  //         code: 'CH0702', parentCode: 'CH07', nameVi: 'Các vấn đề khác', name: "Other distribution issues"
  //     },
  //     {
  //         code: 'CH0703', parentCode: 'CH07', nameVi: 'Chuyển giao nhầm Tư vấn viên', name: "Wrong assignment"
  //     },
  //     {
  //         code: 'CH0801', parentCode: 'CH08', nameVi: 'Không/Chậm giao bộ Quà của Cty cho KH', name: "No/Late delivery of Company's Gift to customer"
  //     },
  //     {
  //         code: 'CH0802', parentCode: 'CH08', nameVi: 'Không/Chậm giao HĐBH cho KH', name: "No/Late delivery of Policy kit"
  //     },
  //     {
  //         code: 'CH0803', parentCode: 'CH08', nameVi: 'Không/Chậm giao thư báo điều chỉnh HĐ đến KH', name: "No/Late delivery of policy letters to customer"
  //     },
  //     {
  //         code: 'CH0804', parentCode: 'CH08', nameVi: 'Chậm trễ khác của TVV trong phục vụ KH', name: "Other failure to deliver by Agent"
  //     },
  //     {
  //         code: 'CH0901', parentCode: 'CH09', nameVi: 'GA thu Phí nhưng không phát hành Phiếu thu', name: "Collect premium without issuing TR/RB - GA"
  //     },
  //     {
  //         code: 'CH0902', parentCode: 'CH09', nameVi: 'Phục vụ chậm trễ', name: "Delay in processing"
  //     },
  //     {
  //         code: 'CH0903', parentCode: 'CH09', nameVi: 'Giả mạo chữ ký/chứng từ', name: "Forgery/ fake documents"
  //     },
  //     {
  //         code: 'CH0904', parentCode: 'CH09', nameVi: 'Thiếu kiến thức chuyên môn', name: "Lack of knowledge/expertise - GA"
  //     },
  //     {
  //         code: 'CH0905', parentCode: 'CH09', nameVi: 'Các vấn đề khác', name: "Other GA issues"
  //     },
  //     {
  //         code: 'CH0906', parentCode: 'CH09', nameVi: 'Chiếm dụng quyền lợi hợp đồng bảo hiểm', name: "Policy benefit embezzlement"
  //     },
  //     {
  //         code: 'CH0907', parentCode: 'CH09', nameVi: 'Chiếm dụng phí bảo hiểm của khách hàng', name: "Premium embezzlement"
  //     },
  //     {
  //         code: 'CH0908', parentCode: 'CH09', nameVi: 'Thái độ/Giao tiếp thiếu chuyên nghiệp', name: "Unprofessional Attitude/behavior "
  //     },
  //     {
  //         code: 'CH0909', parentCode: 'CH09', nameVi: 'Sử dụng Phiếu thu giả', name: "Using fake receipt "
  //     },
  //     {
  //         code: 'CH1001', parentCode: 'CH10', nameVi: 'Gây áp lực để khách hàng mua bảo hiểm', name: "Force customer to buy the policy"
  //     },
  //     {
  //         code: 'CH1002', parentCode: 'CH10', nameVi: 'Sử dụng các phương thức bán SP ko chính thống', name: "Using unpermitted selling solicitations"
  //     },
  //     {
  //         code: 'CH1003', parentCode: 'CH10', nameVi: 'Các vấn đề khác', name: "Other Improper Sale Solicitation issues"
  //     },
  //     {
  //         code: 'CH1004', parentCode: 'CH10', nameVi: 'Sử dụng tài liệu bán SP ko chính thống của Cty', name: "Use unauthorised sales materials"
  //     },
  //     {
  //         code: 'CH1101', parentCode: 'CH11', nameVi: 'Các vấn đề khác', name: "Others marketing related issues"
  //     },
  //     {
  //         code: 'CH1102', parentCode: 'CH11', nameVi: 'Chất lượng của tài liệu tiếp thị', name: "Quality of Sales materials "
  //     },
  //     {
  //         code: 'CH1103', parentCode: 'CH11', nameVi: 'Tài liệu tiếp thị không rõ ràng', name: "Unclear/cheating materials"
  //     },
  //     {
  //         code: 'CH1104', parentCode: 'CH11', nameVi: 'Điều kiện khuyến mãi ko rõ ràng/công bằng', name: "Unclear/unfair conditions of promotion program"
  //     },
  //     {
  //         code: 'CH1201', parentCode: 'CH12', nameVi: 'TVV tự ý điều chỉnh thông tin HĐBH', name: "AG made pol alteration w/o customer's consent "
  //     },
  //     {
  //         code: 'CH1202', parentCode: 'CH12', nameVi: 'TVV tự ý nộp HSYCBH điện tử', name: "AG sub E-Application w/o customer's consent "
  //     },
  //     {
  //         code: 'CH1203', parentCode: 'CH12', nameVi: 'TVV tự ý sử dụng thông tin cá nhân của KH', name: "AG use personal data w/o customer's consent"
  //     },
  //     {
  //         code: 'CH1204', parentCode: 'CH12', nameVi: 'Các vấn đề khác', name: "Other Agent's misconduct"
  //     },
  //     {
  //         code: 'CH1205', parentCode: 'CH12', nameVi: 'Giả mạo chữ ký/chứng từ', name: "Signature/Documents forgery"
  //     },
  //     {
  //         code: 'CH1206', parentCode: 'CH12', nameVi: 'SDung TVV bên thứ 3/ko được cấp phép để bán SP', name: "Use 3rd party/unlicensed/unqualify AG to sale"
  //     },
  //     {
  //         code: 'CH1301', parentCode: 'CH13', nameVi: 'TVV bỏ qua ttin sức khỏe của Người được BH', name: "AG left out health info of the life assured"
  //     },
  //     {
  //         code: 'CH1302', parentCode: 'CH13', nameVi: 'Hứa với KH quyền lợi nằm ngoài phạm vi HĐBH', name: "Give promises beyond the Term and Coditions"
  //     },
  //     {
  //         code: 'CH1303', parentCode: 'CH13', nameVi: 'Các thông tin sai lệch khác', name: "Other misrepresentation"
  //     },
  //     {
  //         code: 'CH1304', parentCode: 'CH13', nameVi: 'Giải thích không đúng về các Quỹ đầu tư', name: "Wrongful explanation of Investment funds"
  //     },
  //     {
  //         code: 'CH1305', parentCode: 'CH13', nameVi: 'Giải thích không đúng về Pru-Quote', name: "Wrongful explanation of Pru-Quote"
  //     },
  //     {
  //         code: 'CH1306', parentCode: 'CH13', nameVi: 'Giải thích không đúng về Điều khoản Hợp đồng', name: "Wrongful explanation of T&Cs"
  //     },
  //     {
  //         code: 'CH1307', parentCode: 'CH13', nameVi: 'Ko cung cấp/cung cấp ttin sai về đặc tính SP', name: "Wrongful info of Product features"
  //     },
  //     {
  //         code: 'CH1308', parentCode: 'CH13', nameVi: 'Ko cung cấp/cung cấp ttin sai về phí/chi phí', name: "Wrongful info of Fees & Charges"
  //     },
  //     {
  //         code: 'CH1309', parentCode: 'CH13', nameVi: 'Giải thích không đầy đủ/sai về rủi ro đầu tư', name: "Wrongful/Inadequate info of investment risk "
  //     },
  //     {
  //         code: 'CH1310', parentCode: 'CH13', nameVi: 'Ko cung cấp/sai về Phí tăng thêm/ ĐK loại trừ', name: "Wrongful of Extra loading premium/exclusion"
  //     },
  //     {
  //         code: 'CH1401', parentCode: 'CH14', nameVi: 'Thu phí nhưng ko phát hành PThu hợp lệ cho KH', name: "Collect premium without issuing TR/RB"
  //     },
  //     {
  //         code: 'CH1402', parentCode: 'CH14', nameVi: 'Chiếm dụng Quyền lợi HĐ/khoản thanh toán khác', name: "Misappropriation of Policy Benf/cash payment…"
  //     },
  //     {
  //         code: 'CH1403', parentCode: 'CH14', nameVi: 'Chiếm dụng phí bảo hiểm', name: "Misapropriation of premium"
  //     },
  //     {
  //         code: 'CH1404', parentCode: 'CH14', nameVi: 'Các vấn đề khác', name: "Other money misapropriation by agent"
  //     },
  //     {
  //         code: 'CH1501', parentCode: 'CH15', nameVi: 'Dịch vụ/thủ tục của P.Khám/Bác sỹ liên kết Pru', name: "Services/procedures at Clinics/Panel doctors"
  //     },
  //     {
  //         code: 'CH1502', parentCode: 'CH15', nameVi: 'Ko nhận được Thư mời khám/Ycầu bổ sung ttin', name: "No receipt of invitation for ME/ReQ. for docs"
  //     },
  //     {
  //         code: 'CH1503', parentCode: 'CH15', nameVi: 'Các vấn đề khác', name: "Other NBUW issuses"
  //     },
  //     {
  //         code: 'CH1504', parentCode: 'CH15', nameVi: 'HĐBh được phát hành với thông tin sai', name: "Policy issued with wrong information"
  //     },
  //     {
  //         code: 'CH1505', parentCode: 'CH15', nameVi: 'Đã nộp Hồ sơ YCBH nhưng chưa nhận được HĐBH', name: "Proposal submitted but not yet received policy"
  //     },
  //     {
  //         code: 'CH1506', parentCode: 'CH15', nameVi: 'Thái độ/Giao tiếp của NV Pkhám/Bác sỹ liên kết', name: "Attitude/communication of Clinic staff/doctor"
  //     },
  //     {
  //         code: 'CH1507', parentCode: 'CH15', nameVi: 'Thái độ/Giao tiếp của nhân viên Thẩm định', name: "Attitude/communication of NBUW staff"
  //     },
  //     {
  //         code: 'CH1508', parentCode: 'CH15', nameVi: 'Quyết định Thẩm định không hợp lý', name: "Unreasonable UW decision"
  //     },
  //     {
  //         code: 'CH1509', parentCode: 'CH15', nameVi: 'Thủ tục thẩm định phức tạp/không hợp lý', name: "Unreasonable UW procedures"
  //     },
  //     {
  //         code: 'CH1510', parentCode: 'CH15', nameVi: 'Kết quả khám không chính xác', name: "Wrong medical exam results"
  //     },
  //     {
  //         code: 'CH1601', parentCode: 'CH16', nameVi: 'Chậm chuyển tiền từ tài khoản KH tới Pru', name: "Delay transfer from customer's account to Pru"
  //     },
  //     {
  //         code: 'CH1602', parentCode: 'CH16', nameVi: 'Chuyển tiền chậm trễ (do nhà cung cấp dịch vụ)', name: "Slow processing (by partner)"
  //     },
  //     {
  //         code: 'CH1603', parentCode: 'CH16', nameVi: 'Không thu phí đúng hạn', name: "Fail to collect premium on time"
  //     },
  //     {
  //         code: 'CH1604', parentCode: 'CH16', nameVi: 'Từ chối thu phí', name: "Reject collection"
  //     },
  //     {
  //         code: 'CH1605', parentCode: 'CH16', nameVi: 'Yêu cầu khách hàng nộp thêm khoản phí dịch vụ', name: "Require transfer charges"
  //     },
  //     {
  //         code: 'CH1606', parentCode: 'CH16', nameVi: 'Nhân viên thiếu kiến thức nghiệp vụ và kỹ năng', name: "Staff is lack of expertise and skill"
  //     },
  //     {
  //         code: 'CH1607', parentCode: 'CH16', nameVi: 'Ghi sai số hợp đồng (do nhà cung cấp dịch vụ)', name: "Debit to wrong policy No. (by partner)"
  //     },
  //     {
  //         code: 'CH1608', parentCode: 'CH16', nameVi: 'NVien có thái độ/Giao tiếp thiếu chuyên nghiệp', name: "Unprofessional attitude/communication of staff"
  //     },
  //     {
  //         code: 'CH1609', parentCode: 'CH16', nameVi: 'Các vấn đề khác', name: "Other Collection issues"
  //     },
  //     {
  //         code: 'CH1610', parentCode: 'CH16', nameVi: 'Chuyển tiền sai số tài khoản (do đối tác Pru)', name: "Debit to wrong Account No. (by partner)"
  //     },
  //     {
  //         code: 'CH1611', parentCode: 'CH16', nameVi: 'Sai số tiền', name: "Wrong collection amount"
  //     },
  //     {
  //         code: 'CH1612', parentCode: 'CH16', nameVi: 'Chậm chuyển tiền quyền lợi HĐ từ Pru đến KH', name: "Delay transfer Pol. Benf from Pru to Customer"
  //     },
  //     {
  //         code: 'CH1701', parentCode: 'CH17', nameVi: 'Sai sót trong giao dịch điều chỉnh t.tin HĐ', name: "Alteration errors"
  //     },
  //     {
  //         code: 'CH1702', parentCode: 'CH17', nameVi: 'Chậm trễ thực hiện yêu cầu của khách hàng', name: "Delay in processing customer's request - PA"
  //     },
  //     {
  //         code: 'CH1703', parentCode: 'CH17', nameVi: 'Không nhận được yêu cầu bổ sung thông tin', name: "No receipt of req. for suplementary documents"
  //     },
  //     {
  //         code: 'CH1704', parentCode: 'CH17', nameVi: 'Các vấn đề khác', name: "Other complaint regarding Policty Alteration"
  //     },
  //     {
  //         code: 'CH1705', parentCode: 'CH17', nameVi: 'Thái độ/Giao tiếp thiếu chuyên nghiệp của NV', name: "Attitude/communication of staffs - PA"
  //     },
  //     {
  //         code: 'CH1706', parentCode: 'CH17', nameVi: 'Thủ tục Điều chỉnh hợp đồng không hợp lý', name: "Unreasonable alteration procedures"
  //     },
  //     {
  //         code: 'CH1801', parentCode: 'CH18', nameVi: 'Chậm trễ thực hiện yêu cầu của khách hàng', name: "Delay in processing customer's request - PV"
  //     },
  //     {
  //         code: 'CH1802', parentCode: 'CH18', nameVi: 'Không nhận được yêu cầu bổ sung thông tin', name: "No receipt of ReQ. for supplementary docs"
  //     },
  //     {
  //         code: 'CH1803', parentCode: 'CH18', nameVi: 'Thái độ/Giao tiếp thiếu chuyên nghiệp của NV', name: "Attitude/communication of staffs - PV"
  //     },
  //     {
  //         code: 'CH1804', parentCode: 'CH18', nameVi: 'Thủ tục Điều chỉnh hợp đồng không hợp lý', name: "Unreasonable valuation procedures"
  //     },
  //     {
  //         code: 'CH1805', parentCode: 'CH18', nameVi: 'Các vấn đề khác', name: "Other Policy Valuation issues"
  //     },
  //     {
  //         code: 'CH1806', parentCode: 'CH18', nameVi: 'Sai sót trong giao dịch/tính toán Giá trị HĐ', name: "Valuation errors"
  //     },
  //     {
  //         code: 'CH1901', parentCode: 'CH19', nameVi: 'Bảo tức thấp hơn minh họa trên Pru-Quote', name: "Bonus is less than illustrated in Pru-Quote"
  //     },
  //     {
  //         code: 'CH1902', parentCode: 'CH19', nameVi: 'Lãi suất áp dụng cho các khoản vay của HD cao', name: "High loan interest"
  //     },
  //     {
  //         code: 'CH1903', parentCode: 'CH19', nameVi: 'Mức phí bảo hiểm/ tỉ lệ tăng phí quá cao', name: "High premium rate"
  //     },
  //     {
  //         code: 'CH1904', parentCode: 'CH19', nameVi: 'Giá trị hoàn lại/Giá trị tài khoản thấp', name: "Low surrender value/PAV"
  //     },
  //     {
  //         code: 'CH1905', parentCode: 'CH19', nameVi: 'Giá trị đáo hạn thấp hơn minh họa ở Pru-Quote', name: "Maturity value is less than value in Pru-Quote"
  //     },
  //     {
  //         code: 'CH1906', parentCode: 'CH19', nameVi: 'Giá trị đáo hạn thấp hơn Tổng phí đóng', name: "Maturity value is less than the paid premium"
  //     },
  //     {
  //         code: 'CH1907', parentCode: 'CH19', nameVi: 'Khiếu nại khác về Sản phẩm', name: "Other product related issues"
  //     },
  //     {
  //         code: 'CH1908', parentCode: 'CH19', nameVi: 'Điều khoản HĐg không rõ ràng/gây hiểu nhầm', name: "T&Cs wording is unclear/confused"
  //     },
  //     {
  //         code: 'CH1909', parentCode: 'CH19', nameVi: 'Nhiều Đkiện loại trừ/Đkiện loại trừ ko hợp lý', name: "Too many Exclusions/ Unreasonable Exclusions"
  //     },
  //     {
  //         code: 'CH2001', parentCode: 'CH20', nameVi: 'Những tư vấn không phù hợp khác về sản phẩm', name: "Other unsuitability of product or plan"
  //     },
  //     {
  //         code: 'CH2002', parentCode: 'CH20', nameVi: 'TVV tư vấn hủy HĐ để thay thế bằng HĐ mới', name: "Policy Replacement/Twisting/Churning"
  //     },
  //     {
  //         code: 'CH2003', parentCode: 'CH20', nameVi: 'Mức phí BH ko phù hợp với KH', name: "Premium is not suitable for customer"
  //     },
  //     {
  //         code: 'CH2004', parentCode: 'CH20', nameVi: 'Sản phẩm không phù hợp với nhu cầu của KH', name: "Product is not suitable to customer's need"
  //     },
  //     {
  //         code: 'CH2101', parentCode: 'CH21', nameVi: 'Lỗi hệ thống PRUonline', name: "Errors on PruOnline"
  //     },
  //     {
  //         code: 'CH2102', parentCode: 'CH21', nameVi: 'Ko nhận đc Mật khẩu sau khi đăng ký trực tuyến', name: "Not receive new password after register online"
  //     },
  //     {
  //         code: 'CH2103', parentCode: 'CH21', nameVi: 'Ko nhận được ID & Mật Khẩu sau khi đăng ký PYC', name: "Not receive ID & Password after register form"
  //     },
  //     {
  //         code: 'CH2104', parentCode: 'CH21', nameVi: 'Y/cầu điều chỉnh của KH ko được cập nhật', name: "Request not update after Customer modify"
  //     },
  //     {
  //         code: 'CH2105', parentCode: 'CH21', nameVi: 'Các vấn đề khác', name: "Other PruOnline issues"
  //     },
  //     {
  //         code: 'CH2201', parentCode: 'CH22', nameVi: 'Không thể đổi điểm PRUrewards', name: "Can’t get deal code on PRUrewards"
  //     },
  //     {
  //         code: 'CH2202', parentCode: 'CH22', nameVi: 'Không/Chậm giao quà/lịch', name: "No/late delivery of Gift/Calendar"
  //     },
  //     {
  //         code: 'CH2203', parentCode: 'CH22', nameVi: 'Không/Chậm giao Phiếu khám sức khỏe', name: "No/Late delivery of Health check letter"
  //     },
  //     {
  //         code: 'CH2204', parentCode: 'CH22', nameVi: 'Hạng VIP của khách hàng không chính xác', name: "Wrongly ranking of VIP levels"
  //     },
  //     {
  //         code: 'CH2205', parentCode: 'CH22', nameVi: 'Các vấn đề khác', name: "Other issue of PruRewards scheme"
  //     },
  //     {
  //         code: 'CH2301', parentCode: 'CH23', nameVi: 'Tư cách/Thái độ của TVV chỉ định tiếp nhận HĐ', name: "Conduct/Attitute of agent assigned by CC"
  //     },
  //     {
  //         code: 'CH2302', parentCode: 'CH23', nameVi: 'Tranh chấp về CTrình cứu hủy HĐ(Conservation)', name: "Disputes in conservation program"
  //     },
  //     {
  //         code: 'CH2303', parentCode: 'CH23', nameVi: 'Không/Chậm giao quà/lịch', name: "No/late delivery of Gift/Calendar - Retention"
  //     },
  //     {
  //         code: 'CH2304', parentCode: 'CH23', nameVi: 'Các vấn đề khác', name: "Other retention issues"
  //     },
  //     {
  //         code: 'CH2305', parentCode: 'CH23', nameVi: 'Chất lượng dịch vụ sau khi TVV nghỉ việc', name: "Quality of service for orphan policy"
  //     },
  //     {
  //         code: 'CH2401', parentCode: 'CH24', nameVi: 'TVV ko hỗ trợ KH thực hiện các G.dịch kịp thời', name: "Failure to facilitate customer's ReQ. on time"
  //     },
  //     {
  //         code: 'CH2402', parentCode: 'CH24', nameVi: 'TVV có Thái độ/hành vi/Ngôn từ không lịch sự…', name: "Impolite behaviour, language, attitude…"
  //     },
  //     {
  //         code: 'CH2403', parentCode: 'CH24', nameVi: 'TVV chuyển giao ko liên lạc hỗ trợ HĐ của KH', name: "No service for assigned orphan policy"
  //     },
  //     {
  //         code: 'CH2404', parentCode: 'CH24', nameVi: 'Các vấn đề khác', name: "Other Sales Service Performance issues"
  //     },
  //     {
  //         code: 'CH2501', parentCode: 'CH25', nameVi: 'KH nhận được SMS có thông tin không phù hợp', name: "Customer received message with irrelevant info"
  //     },
  //     {
  //         code: 'CH2502', parentCode: 'CH25', nameVi: 'Ko nhận được SMS sau khi đăng ký qua số 8099', name: "No received info after send message to 8099"
  //     },
  //     {
  //         code: 'CH2503', parentCode: 'CH25', nameVi: 'Các vấn đề khác', name: "Other SMS issues"
  //     },
  //     {
  //         code: 'CH2504', parentCode: 'CH25', nameVi: 'Không nhận được SMS', name: "No received SMS"
  //     },
  //     {
  //         code: 'CS0201', parentCode: 'CS02', nameVi: 'Quyết định giải quyết Quyền lợi Bảo hiểm', name: "Claims Decision"
  //     },
  //     {
  //         code: 'CS0202', parentCode: 'CS02', nameVi: 'Chứng từ giải quyết Quyền lợi Bảo hiểm', name: "Claims Documents"
  //     },
  //     {
  //         code: 'CS0203', parentCode: 'CS02', nameVi: 'Đang thẩm định', name: "Assessment in progress"
  //     },
  //     {
  //         code: 'CS0204', parentCode: 'CS02', nameVi: 'Đang thu thập hồ sơ ', name: "Investigation in progress"
  //     },
  //     {
  //         code: 'CS0205', parentCode: 'CS02', nameVi: 'Chưa thẩm định xong', name: "Not yet Assessment"
  //     },
  //     {
  //         code: 'CS0206', parentCode: 'CS02', nameVi: 'Chờ thông tin từ bộ phận Giải quyết Quyền lợi Bảo hiểm', name: "Wating for doc from Clmt"
  //     },
  //     {
  //         code: 'CS0701', parentCode: 'CS07', nameVi: 'Nhân viên cung cấp thông tin sai với thông tin từ Tư vấn viên', name: "Provide customers wrong info which is different from Agent"
  //     },
  //     {
  //         code: 'CS0801', parentCode: 'CS08', nameVi: 'Nhân viên cung cấp thông tin sai với thông tin từ Tư vấn viên', name: "Provide customers wrong info which is different from Agent"
  //     },
  //     {
  //         code: 'CS1401', parentCode: 'CS14', nameVi: 'Thư xác nhận điều chỉnh thông tin Hợp đồng', name: "Policy Change"
  //     },
  //     {
  //         code: 'CS1402', parentCode: 'CS14', nameVi: 'Thư công bố bảo tức tích lũy hay Bảng Thông tin thường niên', name: "Bonus Declaration"
  //     },
  //     {
  //         code: 'CS1403', parentCode: 'CS14', nameVi: 'Thư xác nhận đóng phí', name: "Confirmation letter of premium "
  //     },
  //     {
  //         code: 'CS1404', parentCode: 'CS14', nameVi: 'Thư xác nhận Lùi ngày hiệu lực Hợp đồng', name: "Confirmation letter of Backdating"
  //     },
  //     {
  //         code: 'CS1405', parentCode: 'CS14', nameVi: 'Khôi phục hiệu lực Hợp đồng', name: "Confirmation letter of Policy reinstatement"
  //     },
  //     {
  //         code: 'CS1406', parentCode: 'CS14', nameVi: 'Thư xác nhận điều chỉnh thông tin liên lạc (địa chỉ, điện thoại, email,…)', name: "Confirmation letter of contact update "
  //     },
  //     {
  //         code: 'CS0901', parentCode: 'CS09', nameVi: 'Chậm giao quà cho khách hàng VIP', name: "Delay Delivery Gift for Vip Customer"
  //     },
  //     {
  //         code: 'CS0902', parentCode: 'CS09', nameVi: 'Chậm giao Hợp đồng cho khách hàng', name: "Delay delivery of policy kit-WCP"
  //     },
  //     {
  //         code: 'CS0903', parentCode: 'CS09', nameVi: 'Chậm giao Coupon khám sức khỏe cho khách hàng', name: "Late delivery of Health Check Coupon"
  //     },
  //     {
  //         code: 'CS0904', parentCode: 'CS09', nameVi: 'VP Tổng đại lý chậm chuyển quà đến khách hàng', name: "GA late transfer gift to Agent"
  //     },
  //     {
  //         code: 'CS1101', parentCode: 'CS11', nameVi: 'Địa chỉ phòng khám', name: "Location of Medical exams"
  //     },
  //     {
  //         code: 'CS1102', parentCode: 'CS11', nameVi: 'Phí khám sức khỏe', name: "Medical fee"
  //     },
  //     {
  //         code: 'CS1103', parentCode: 'CS11', nameVi: 'Thời gian và địa điểm khám sức khỏe', name: "Time and venue for ME check up"
  //     },
  //     {
  //         code: 'CS2301', parentCode: 'CS23', nameVi: 'Ý kiến đóng góp', name: "Other Feedback"
  //     },
  //     {
  //         code: 'CS2501', parentCode: 'CS25', nameVi: 'Thắc mắc khác', name: "Request process"
  //     },
  //     {
  //         code: 'CS0101', parentCode: 'CS01', nameVi: 'Bảo tức', name: "Bonus"
  //     },
  //     {
  //         code: 'CS0102', parentCode: 'CS01', nameVi: 'Tạm ứng từ Giá trị tài khoản Hợp đồng', name: "Loans"
  //     },
  //     {
  //         code: 'CS0103', parentCode: 'CS01', nameVi: 'Quyền lợi tiền mặt', name: "PruCash"
  //     },
  //     {
  //         code: 'CS0104', parentCode: 'CS01', nameVi: 'Hoàn phí hủy hợp đồng sau thời gian cân nhắc', name: "Refund After Free Look"
  //     },
  //     {
  //         code: 'CS0105', parentCode: 'CS01', nameVi: 'Ứng trước chiết khấu phí bảo hiểm', name: "Small policy premium discount advance"
  //     },
  //     {
  //         code: 'CS0106', parentCode: 'CS01', nameVi: 'Hoàn phí hủy hợp đồng trong thời gian cân nhắc', name: "Refund Free Look"
  //     },
  //     {
  //         code: 'CS0107', parentCode: 'CS01', nameVi: 'Chi trả Quyền lợi Bảo hiểm nhanh trong 30 phút', name: "Claims payment within 30 minutes"
  //     },
  //     {
  //         code: 'CS0108', parentCode: 'CS01', nameVi: 'Chi trả Quyền lợi Bảo hiểm từ Prudential ', name: "Claim benefit payment from company"
  //     },
  //     {
  //         code: 'CS0301', parentCode: 'CS03', nameVi: 'Thu phí tại nhà', name: "Home Collection"
  //     },
  //     {
  //         code: 'CS0302', parentCode: 'CS03', nameVi: 'Chưa nhận được Thư báo/Tin nhắn nhắc đóng phí đến hạn', name: "Premium/reminder notice not received"
  //     },
  //     {
  //         code: 'CS0303', parentCode: 'CS03', nameVi: 'Chậm nhận được Thư báo/Tin nhắn nhắc đóng phí đến hạn', name: "Late receipt of premium/reminder notice"
  //     },
  //     {
  //         code: 'CS0304', parentCode: 'CS03', nameVi: 'Các kênh đóng phí', name: "Premium Collection Channels"
  //     },
  //     {
  //         code: 'CS0305', parentCode: 'CS03', nameVi: 'Thông tin phí đến hạn', name: "Premium due detail"
  //     },
  //     {
  //         code: 'CS0306', parentCode: 'CS03', nameVi: 'Chưa nhận được Thư/Tin nhắn xác nhận đã đóng phí', name: "Confirmation letter or SMS of premium not received"
  //     },
  //     {
  //         code: 'CS1301', parentCode: 'CS13', nameVi: 'Điều chỉnh thông tin Hợp đồng', name: "Policy Change"
  //     },
  //     {
  //         code: 'CS1302', parentCode: 'CS13', nameVi: 'Công bố bảo tức tích lũy hay Bảng Thông tin thường niên', name: "Bonus Declaration or APS"
  //     },
  //     {
  //         code: 'CS1303', parentCode: 'CS13', nameVi: 'Hướng dẫn thủ tục điều chỉnh sản phẩm bổ trợ', name: "Component change procedure"
  //     },
  //     {
  //         code: 'CS1304', parentCode: 'CS13', nameVi: 'Dừng nộp phí, duy trì hiệu lực hợp đồng với số tiền bảo hiểm giảm', name: "Paid-up"
  //     },
  //     {
  //         code: 'CS1305', parentCode: 'CS13', nameVi: 'Tình trạng đóng phí', name: "Premium Status"
  //     },
  //     {
  //         code: 'CS1306', parentCode: 'CS13', nameVi: 'Tình trạng Hợp đồng', name: "Policy status"
  //     },
  //     {
  //         code: 'CS1307', parentCode: 'CS13', nameVi: 'Lùi ngày hiệu lực', name: "Backdating"
  //     },
  //     {
  //         code: 'CS1308', parentCode: 'CS13', nameVi: 'Thủ tục tham gia bảo hiểm', name: "Application formalities"
  //     },
  //     {
  //         code: 'CS1309', parentCode: 'CS13', nameVi: 'Tình trạng phát hành hợp đồng', name: "Proposal status"
  //     },
  //     {
  //         code: 'CS1310', parentCode: 'CS13', nameVi: 'Khôi phục hiệu lực Hợp đồng', name: "Policy reinstatement"
  //     },
  //     {
  //         code: 'CS1311', parentCode: 'CS13', nameVi: 'Điều chỉnh thông tin liên lạc (địa chỉ, điện thoại, email,…)', name: "Customer update contact info"
  //     },
  //     {
  //         code: 'CS1601', parentCode: 'CS16', nameVi: 'Thắc mắc về sản phẩm', name: "Product Query"
  //     },
  //     {
  //         code: 'CS0601', parentCode: 'CS06', nameVi: 'Thông tin chương trình khuyến mãi', name: "Contest/Promotion details"
  //     },
  //     {
  //         code: 'CS1001', parentCode: 'CS10', nameVi: 'Không đổi được mã ưu đãi trên PRURewards', name: "Can’t get deal code on PRUrewards - CS"
  //     },
  //     {
  //         code: 'CS1002', parentCode: 'CS10', nameVi: 'Có ý kiến về chương trình PRURewards', name: "Comment on PRUrewards"
  //     },
  //     {
  //         code: 'CS1003', parentCode: 'CS10', nameVi: 'Làm sao sử dụng mã ưu đãi tại nhà cung cấp dịch vụ', name: "How to use deal code at vendor"
  //     },
  //     {
  //         code: 'CS1004', parentCode: 'CS10', nameVi: 'Làm sao sử dụng Coupon khám sức khỏe tại nhà cung cấp dịch vụ', name: "How to use Health Check Coupon Letter at vendor"
  //     },
  //     {
  //         code: 'CS1005', parentCode: 'CS10', nameVi: 'Bị thiếu điểm trong tài khoản PRURewards', name: "Missing Point on PRUreward account"
  //     },
  //     {
  //         code: 'CS1006', parentCode: 'CS10', nameVi: 'Chưa nhận được quà/bánh dành cho khách hàng PRURewards', name: "Not received cake/gift for PRUrewards Customer"
  //     },
  //     {
  //         code: 'CS1007', parentCode: 'CS10', nameVi: 'Chưa nhận được quà/bánh dành cho khách hàng PRURewards từ Tư vấn viên', name: "Not received cake/gift for PRUrewards Customer via Agent"
  //     },
  //     {
  //         code: 'CS1008', parentCode: 'CS10', nameVi: 'Không nhận được mã ưu đãi trên PRURewards', name: "Not received Deal code on PRUrewards"
  //     },
  //     {
  //         code: 'CS1009', parentCode: 'CS10', nameVi: 'Không nhận được Coupon khách sức khỏe', name: "Not received Health Check Coupon Letter"
  //     },
  //     {
  //         code: 'CS1010', parentCode: 'CS10', nameVi: 'Yêu cầu đổi bánh/quà cho khách hàng PRURewards', name: "Request cake/gift for PRUrewards Customer"
  //     },
  //     {
  //         code: 'CS1011', parentCode: 'CS10', nameVi: 'Yêu cầu đổi mã ưu đãi trên PRURewards', name: "Request for Deal code on PRUrewards"
  //     },
  //     {
  //         code: 'CS1012', parentCode: 'CS10', nameVi: 'Yêu cầu đổi mã Coupon khám sức khỏe', name: "Request Health Check Coupon Letter"
  //     },
  // ]
}
