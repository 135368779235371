import { Text } from '@pulseops/business/core'
import styled from 'styled-components/native'

export const SC = {
  TextTable: styled(Text)<{ isDisable?: boolean }>`
    color: ${(p) => (p.isDisable ? '#999999' : '#1C1D1F')};
    whitespace: nowrap
  `,
  TextColor: styled(Text)<{ isPass?: boolean | string }>`
    color: ${(p) => (p.isPass === '' ? '#000000' : Boolean(p.isPass) ? '#1DC06F' : '#ED1B2D')};
    whitespace: nowrap
  `
}
