import * as React from 'react'
import { PolicyServiceProps } from '../policy-service-props'
import {
  Title,
  Panel,
  DatePicker,
  LoanStatementsService,
  ErrorHandling,
  AppContext,
  form2,
  LoanStatementsData,
  TransactionType
} from '@pulseops/common'
import { Column, Row, Label } from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { useLoading } from '@mxt/zio-react'
import { LoanStatementsForm } from './loan-statements.form'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { RequestAuthenticateData } from '../../request-authen'

interface Props extends PolicyServiceProps<LoanStatementsData.SubmitData> {
  policyNumber: string
}

export const LoanStatementes = ({ policyNumber, isConfirmed, initSubmission }: Props) => {
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const { t } = useTranslation()

  const [isLoading, bindLoading] = useLoading()

  const detail = pipe(LoanStatementsService.getDetail(policyNumber), bindLoading, ErrorHandling.runDidMount())

  React.useEffect(() => showGlobalLoading(isLoading), [isLoading])

  const form = form2.useForm(LoanStatementsForm.codec)

  const formValues = form.base.watch()

  React.useEffect(() => {
    if (detail) {
      const filterLoanList = detail.loanEnquiry.filter(
        (x) =>
          (x.loanType === 'A' && (x.loanNumber === 0 || x.loanNumber === 99)) ||
          (x.loanType === 'P' && (x.loanNumber === 0 || x.loanNumber === 99))
      )
      const warningMesssage = filterLoanList && filterLoanList.length > 0 ? t('message:MS090004') : ''
      warningMesssage && showToast(warningMesssage, 'warning')
      if (detail.loanStartDate) {
        const loanStartDate = moment(detail.loanStartDate, 'DD/MM/YYYY').toDate()
        form.base.setValue('fromDate', loanStartDate)
      }
      form.base.setValue('toDate', new Date())
    }
  }, [detail])

  initSubmission({
    validate: async () => {
      const isFormValid = await form.base.trigger()
      const currentDate = new Date()
      const fromDate = formValues.fromDate
      const toDate = formValues.toDate
      const recommandDate = moment(detail?.recommandDate, 'YYYY-MM-DD')
      const customedRCD = new Date(recommandDate.valueOf())
      const customedToDate = moment(toDate)
      const distanceYear = customedToDate.diff(moment(fromDate), 'years')

      if (!isFormValid || !toDate || !fromDate) {
        return false
      }

      let errorMessage = ''

      if (fromDate < customedRCD) {
        errorMessage = t('message:MS030046', { RCD: recommandDate.format('DD/MM/YYYY') })
      } else if (fromDate > toDate) {
        errorMessage = t('message:MS030042')
      } else if (toDate > currentDate) {
        errorMessage = t('message:MS030043')
      } else if (distanceYear > 3) {
        errorMessage = t('message:MS030049', { k: '3' })
      }

      if (errorMessage) {
        showToast(errorMessage, 'error')
        return false
      }

      return {
        url: (policyNum: string) => `wf-api/policy/${policyNum}/loan-statement`,
        body: {
          clientId: detail?.clientId || '',
          office: '',
          fromDate: moment(fromDate).format('DD/MM/YYYY'),
          toDate: moment(toDate).format('DD/MM/YYYY')
        },
        collerationId: policyNumber,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.LOAN_STATEMENTS),
        transaction: TransactionType.LOAN_STATEMENTS
      }
    },
    clear: () => {
      form.base.reset()
    }
  })

  const renderDateValue = (title: string, value: Date | null) => {
    return (
      <Column>
        <Label title={title} fontWeight="bold" />
        <Label title={moment(value).format('DD/MM/YYYY')} color={'#000000'} />
      </Column>
    )
  }

  return (
    <Column flex={1}>
      <Title title={t('requestInfo:LetterRequest')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <Row>
          <Column minWidth={348} marginEnd={20}>
            <Controller
              control={form.base.control}
              name="fromDate"
              render={({ field, fieldState: { error } }) =>
                isConfirmed ? (
                  renderDateValue(t('common:FromDate'), field.value)
                ) : (
                  <DatePicker {...field} label={t('common:FromDate')} required={true} errorMessage={error?.message} />
                )
              }
            />
          </Column>
          <Column minWidth={348}>
            <Controller
              control={form.base.control}
              name="toDate"
              render={({ field, fieldState: { error } }) =>
                isConfirmed ? (
                  renderDateValue(t('common:ToDate'), field.value)
                ) : (
                  <DatePicker {...field} label={t('common:ToDate')} required={true} errorMessage={error?.message} />
                )
              }
            />
          </Column>
        </Row>
      </Panel>
    </Column>
  )
}
