import React from 'react'
import { StyleSheet, View, Text, ViewStyle, StyleProp } from 'react-native'
import { OBFieldDescription } from './OBSectionContent'
import { assets } from '@pulseops/common'
import { TouchableOpacity } from 'react-native-gesture-handler'

type OBContainerProps = {
  containerTitle?: string
  containerStyle?: StyleProp<ViewStyle>
  isOpenContainer?: boolean
}

export const OBContainer: React.FC<OBContainerProps> = (props) => {
  const { containerTitle, children, containerStyle, isOpenContainer = true } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(isOpenContainer)
  return (
    <View style={[containStyles.container, containerStyle]}>
      <View style={containStyles.headerContainer}>
        <View style={containStyles.headerTextContent}>
          {containerTitle && <Text style={containStyles.header} >{containerTitle}</Text>}
        </View>
        <TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
          <View>
            {isOpen ? <assets.OBDropUp /> : <assets.OBDropDown />}
          </View>
        </TouchableOpacity>
      </View>
      {isOpen && (
        <View style={containStyles.content}>
          {children}
        </View>
      )}
    </View>
  )
}
const containStyles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingVertical: 16,
    paddingRight: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginBottom: 26
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: 16
  },
  headerTextContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    marginLeft: 24,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16
  },
})