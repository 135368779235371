import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useMobile } from '@pulseops/common'

export const RView: React.FC<{
  mobileWidth?: string | number
  width?: string | number
  padding?: number
  style?: StyleProp<ViewStyle>
}> = ({ mobileWidth = '100%', width = '33%', padding = 5, children, style }) => {
  const { isMobile } = useMobile()
  const composeWidth = isMobile ? mobileWidth : width
  const composePadding = isMobile ? padding : 5

  return <View style={[style, { width: composeWidth, padding: composePadding }]}>{children}</View>
}
