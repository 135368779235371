import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { assets, DatePickerProps } from '@pulseops/common'
import i18next from 'i18next'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

const useStyles = makeStyles({
  root: {
    '& input::placeholder': {
      color: '#70777E',
      opacity: 1
    }
  }
})
export function OBDatePicker(props: DatePickerProps) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {props.label && (
        <Text style={styles.label}>
          {props.label}
          {props.required && <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>}
        </Text>
      )}
      <View style={styles.dateTimeContainer}>
        <KeyboardDatePicker
          className={useStyles().root}
          clearable
          disabled={props.disabled}
          value={props.value || null}
          keyboardIcon={<assets.InputCalendar />}
          onChange={(date) => {
            if (props.onChange) {
              props.onChange(date)
            }
          }}
          onBlur={props.onBlur}
          placeholder="dd/mm/yyyy"
          format="dd/MM/yyyy"
          minDate={props.minDate}
          maxDate={props.maxDate}
          maxDateMessage={props.errorMessage ? '' : props.maxDateMessage}
          minDateMessage={props.errorMessage ? '' : props.minDateMessage}
          invalidDateMessage={props.errorMessage ? '' : props.invalidDateMessage || i18next.t('form:error_invalid')}
          InputProps={{
            disableUnderline: true,
            style: {
              color: '#000',
              minHeight: 33
            }
          }}
        />
      </View>
    </MuiPickersUtilsProvider>
  )
}

const styles = StyleSheet.create({
  dateTimeContainer: {
    flex: 1,
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 16,
    backgroundColor: '#F6F7FA',
    borderColor: '#bdbec0',
    borderWidth: 1
  },
  label: {
    color: '#58647A',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 18,
    marginBottom: 4
  }
})
