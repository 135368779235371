import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { HeaderTable } from '../../common'
import {
  Checkbox,
  TextInput,
  RoundedButton,
  QCService,
  getLanguage,
  AppContext,
  Panel,
  TabList,
  Tab,
  GeneralService,
  ErrorHandling,
  AuthService,
  TASK_DETAIL_ROLES
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { QCTabAdju } from '../../data'

interface Props {
  data: QCTabAdju
  processId: string
  onDataChange: (newData: QCTabAdju) => void
  scoreDefault?: string
  subTask?: string
  detail?: {
    caseId: string
    policyNumber: string
    policyOwner: string
    clientNumber: string
  }
  roles: string[]
}

const QCAdmin = ({ processId, data, onDataChange, scoreDefault }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Panel
        isExand={false}
        key={0}
        title={
          <>
            <Text style={[styles.label, { marginTop: 22 }]}>
              {t('QC:LastUser')}:<Text style={styles.lastUser}>{data.lastUser}</Text>
            </Text>
            <Text style={[styles.label, { marginTop: 16 }]}>
              {t('QC:TotalScore')}:
              <Text style={styles.totalScore}>
                {data.totalScore} / {scoreDefault}
              </Text>
            </Text>
          </>
        }
      >
        <HeaderTable
          columns={[
            {
              key: 0,
              name: 'id',
              title: t('QC:No'),
              flex: 1,
              styles: styles.tableValue
            },
            {
              key: 1,
              name: 'type',
              title: t('QC:MistakeError'),
              flex: 4,
              styles: styles.tableValue
            },
            {
              key: 2,
              name: 'score',
              title: t('QC:MinusScore'),
              flex: 1,
              styles: styles.tableValue
            },
            {
              key: 3,
              name: 'selected',
              title: (
                <div style={{ textAlign: 'center' }}>
                  <h5 style={{ margin: 0 }}>{t('QC:Mistake')}</h5>
                  <h5 style={{ margin: 0 }}>(Yes = Check, No = Uncheck)</h5>
                </div>
              ),
              flex: 1,
              styles: styles.tableValueCenter
            }
          ]}
          dataSource={data.mistakes.map((item, index) => ({
            id: index + 1,
            type: getLanguage() === 'vi' ? item.desc_vi : item.desc_en,
            score: item.score,
            selected: (
              <Checkbox
                value={item.qcCheck}
                onChange={() => {
                  data.mistakes[index].qcCheck = !item.qcCheck
                  const totalScore = data.mistakes.reduce(
                    (total, item) => (item.qcCheck ? total : total + item.score),
                    0
                  )
                  onDataChange({
                    ...data,
                    totalScore: `${totalScore}`,
                    mistakes: [...data.mistakes]
                  })
                }}
              />
            )
          }))}
        />
        <Text style={[styles.label, { marginTop: 35 }]}>{t('QC:Remarks')}</Text>

        <TextInput
          value={data.remark}
          onChange={(value) => onDataChange({ ...data, remark: value })}
          textInputProps={{
            multiline: true,
            style: styles.remarkInput
          }}
        />
      </Panel>
    </>
  )
}
export const QCCheckClaim = ({ processId, data, onDataChange, subTask, detail, roles }: Props) => {
  // const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const { t } = useTranslation()
  const scoreDefault = React.useRef(data.mistakes.reduce((pre, cur) => pre + cur.score, 0).toString())
  const { showToast, getFooterClaim, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  React.useEffect(() => {
    const isSupsend = subTask?.split('-')[1] === 'SUSPENDED'
    getFooterClaim(
      isSupsend
        ? []
        : [
            {
              name: t('common:Save'),
              action: onPressSave,
              isVisible: roles.includes(TASK_DETAIL_ROLES.EDIT_QC_ADMIN_CL)
            },
            {
              name: t('common:Cancel'),
              action: onCancel,
              isVisible: roles.includes(TASK_DETAIL_ROLES.EDIT_QC_ADMIN_CL)
            }
          ]
    )
  }, [data])

  const onPressSave = () => {
    showGlobalLoading(true)
    pipe(
      GeneralService.saveQCScoreVerification({
        caseId: detail?.caseId || '',
        policyNumber: detail?.policyNumber || '',
        policyOwner: detail?.policyOwner || '',
        clientNumber: detail?.clientNumber || '',
        totalScore: data.totalScore,
        qcScoreDetailTopics: data.mistakes.map((_) => ({
          errorId: _.error_id,
          qcCheck: _.qcCheck,
          minusScore: _.score,
          topic: ''
        })),
        remark: data.remark,
        lastUser: data.lastUser || '',
        userGroup: 'Adjudicator'
      }),
      ZIO.map((data) => {
        showGlobalLoading(false)
        showToast(t('message:MS990017'), 'success')
        console.log(data)
      }),
      ErrorHandling.run()
    )
  }

  const onCancel = () => {
    onDataChange({
      ...data,
      totalScore: `${scoreDefault.current}`,
      mistakes: data.mistakes.map((item) => ({ ...item, qcCheck: false })),
      remark: ''
    })
  }

  return (
    <View style={{ flex: 1, padding: 10 }}>
      <QCAdmin data={data} onDataChange={onDataChange} processId={processId} scoreDefault={scoreDefault.current} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold'
  },

  tableValue: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '300'
  },
  tableValueCenter: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '300',
    alignSelf: 'center'
  },
  lastUser: {
    color: '#000000',
    fontSize: 17,
    marginStart: 16
  },

  totalScore: {
    color: '#ED1B2E',
    fontSize: 17,
    marginStart: 16,
    fontWeight: '800'
  },

  remarkInput: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginTop: 10,
    height: 150,
    paddingVertical: 10,
    paddingHorizontal: 20
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },

  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 45
  }
})
