import { Paper } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  assets,
  BorderInput,
  BorderSelect,
  ContractDetail,
  DetailService,
  EditFooterService,
  Employee,
  Form,
  Input,
  NbuwService,
  SectionHeader,
  Select,
  FormService,
  Table,
  TableFooterPagination,
  TableStatus,
  ProposalStatusOptions,
  UwDecision,
  UwDecisionCode,
  UwDecisionLabel,
  UwEmployeeDecision,
  InputCurrency
} from '@pulseops/business/core'
import { didUpdate, ErrorHandling, form2, Format, SelectOption, Permission, subscribe, RBAC } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import { ContractDetailContext } from '../contract-detail-context'
import { SC } from '../sc'
import { BusinessViewEmployeeOverFCLModalService } from './employee-over-fcl-modal-service'
import { UnderwritingForm } from './underwriting-form'
import { BusinessViewEmployeeModalService } from './employee-modal-service'
import * as _ from 'lodash'

export const Underwriting = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)
  const service = NbuwService.forContract(contractId)
  const detailService = DetailService.contract(contractId)

  const detail: ContractDetail | null = pipe(detailService.getInfo, ErrorHandling.runDidMount())

  type overFCLOptions = {
    [key: string]: string
  }

  const OVER_FCL: overFCLOptions = {
    Y: 'Có',
    N: 'Không'
  }

  const {
    base: {
      control,
      formState: { errors },
      watch,
      trigger,
      setValue
    },
    handleSubmit
  } = form2.useForm(UnderwritingForm.codec, {
    defaultValues: {
      finalUwDecision: '',
      employees: []
    }
  })

  const [sizeTable1, setSizeTable1] = React.useState(10)
  const [pageTable1, setPageTable1] = React.useState(0)
  const [loading1, bindLoading1] = useLoading(false)

  const [sizeTable2, setSizeTable2] = React.useState(10)
  const [pageTable2, setPageTable2] = React.useState(0)
  const [loading2, bindLoading2] = useLoading(false)

  const [sizeTable3, setSizeTable3] = React.useState(10)
  const [pageTable3, setPageTable3] = React.useState(0)
  const [loading3, bindLoading3] = useLoading(false)
  const [countsumit, setCountSumit] = React.useState(0)

  pipe(
    didUpdate([sizeTable1, pageTable1]),
    ZStream.chainEffect(([size, page]) => pipe(service.getUwDecision({ size, page }), bindLoading1)),
    subscribe()
  )

  const rows1: UwDecision[] | null = pipe(
    didUpdate([sizeTable1, pageTable1]),
    ZStream.switchMap(([size, page]) => service.state.uwDecision.size(size).watchPage(page)),
    subscribe([])
  )

  const total1 = pipe(
    didUpdate([sizeTable1]),
    ZStream.switchMap(([size]) => service.state.uwDecision.size(size).watchTotal),
    subscribe(0)
  )

  pipe(
    didUpdate([sizeTable2, pageTable2]),
    ZStream.chainEffect(([size, page]) => pipe(service.getOverFclEmployeeList({ size, page }), bindLoading2)),
    subscribe()
  )

  const rows2: Employee[] | null = pipe(
    didUpdate([sizeTable2, pageTable2]),
    ZStream.switchMap(([size, page]) => service.state.employee.size(size).watchPage(page)),
    subscribe([])
  )

  const total2 = pipe(
    didUpdate([sizeTable2]),
    ZStream.switchMap(([size]) => service.state.employee.size(size).watchTotal),
    subscribe(0)
  )

  pipe(
    didUpdate([sizeTable3, pageTable3]),
    ZStream.chainEffect(([size, page]) => pipe(service.getUwDecisionEmployees({ size, page }), bindLoading3)),
    subscribe()
  )

  const rows3: UwEmployeeDecision[] | null = pipe(
    didUpdate([sizeTable3, pageTable3]),
    ZStream.switchMap(([size, page]) => service.state.employeeDecision.size(size).watchPage(page)),
    subscribe([])
  )

  const total3 = pipe(
    didUpdate([sizeTable3]),
    ZStream.switchMap(([size]) => service.state.employeeDecision.size(size).watchTotal),
    subscribe(0)
  )
  pipe(
    didUpdate([total2, 0]),
    ZStream.chainEffect(([size, page]) => pipe(service.getOverFclEmployeeList({ size, page }), bindLoading2)),
    subscribe()
  )
  const allRow2 = pipe(
    didUpdate([total2, 0]),
    ZStream.switchMap(([size, page]) => service.state.employee.size(size).watchPage(page)),
    subscribe([])
  )
  console.log(allRow2, 'allRow2')
  console.log(total2, 'total2')
  React.useEffect(() => {
    if (allRow2 && allRow2.length > 0) {
      for (let i = 10; i < allRow2.length; i++) {
        setValue(`employees.${i}.overFcl`, getDefaultValue(allRow2[i].overFcl))
        // setValue(`employees.${i}.overFclAmount`, getDefaultValue(allRow2[i].overFclAmount))
        setValue(`employees.${i}.overFclAmount`, allRow2[i].overFclAmount.toString())
        const decision = allRow2[i].uwDecision
          ? allRow2[i].uwDecision
          : getDefaultValue(allRow2[i]?.overFcl) === FormService.decisionOptions[1].value
          ? options[1].value
          : ''
        setValue(`employees.${i}.decision`, decision ?? '')
        setValue(`employees.${i}.comment`, allRow2[i].comment ?? '')
      }
    }
  }, [allRow2])

  const isEditDecision = roles.includes(Permission['EditUWDecisionGroupDetailUnderwriting'])
  const isEditInfo = roles.includes(Permission['EditUWInfoGroupDetailUnderwriting'])
  const [registerOnSave] = EditFooterService.useFooter({ hideCancel: true, hideSave: !isEditDecision && !isEditInfo })

  pipe(
    handleSubmit((validated) => {
      if (rows2 && rows2.length) {
        const updatedList: UwEmployeeDecision[] = filterList(
          validated.employees,
          allRow2 && allRow2.length > 0 ? allRow2 : []
          // countsumit === 0 && rows3 && rows3.length <= 0 ? (allRow2 && allRow2.length > 0 ? allRow2 : []) : rows2
        )
        setCountSumit(1)
        return pipe(
          service.saveUwDecision({
            finalUwDecision: validated.finalUwDecision,
            employees: updatedList,
            reloadUwDecision: ZIO.effect(() => {
              if (pageTable1 === 0) {
                pipe(service.getUwDecision({ size: sizeTable1, page: pageTable1 }), bindLoading1, ErrorHandling.run())
              } else {
                setPageTable1(0)
              }
            }),
            reloadUwEmployees: ZIO.effect(() => {
              if (pageTable3 === 0) {
                console.log(sizeTable3, 'sizeTable3')
                console.log(pageTable3, 'hà')
                pipe(
                  service.getUwDecisionEmployees({ size: sizeTable3, page: pageTable3 }),
                  bindLoading3,
                  ErrorHandling.run()
                )
              } else {
                setPageTable3(0)
              }
            }),
            reloadListEmployee: ZIO.effect(() => {
              if (pageTable2 === 0) {
                pipe(
                  service.getOverFclEmployeeList({ size: sizeTable2, page: pageTable2 }),
                  bindLoading2,
                  ErrorHandling.run()
                )
              } else {
                setPageTable2(0)
              }
            })
          }),
          ErrorHandling.run()
        )
      }
    }),
    registerOnSave
  )

  const getDefaultValue = (value: string | null) => {
    switch (value) {
      case 'Có':
        return 'Y'
      case 'Không':
        return 'N'
      default:
        return 'N'
    }
  }

  const getDifference = (currentList: UwEmployeeDecision[], beforeList: Employee[]) => {
    return currentList.filter((curr) => {
      return !beforeList.some((bf) => {
        return (
          curr.comment === bf.comment &&
          curr.uwDecision === bf.uwDecision &&
          curr.overFcl === bf.overFcl &&
          curr.overFclAmount === bf.overFclAmount
        )
      })
    })
  }

  const filterList = (employees: UnderwritingForm.UwData[], list: Employee[]) => {
    const emp: UwEmployeeDecision[] = employees.map(
      (d, idx): UwEmployeeDecision =>
        pipe(
          (list || [])[idx],
          UwEmployeeDecision({
            decision: d.decision,
            comment: d?.comment,
            overFclAmount: d.overFclAmount,
            overFcl: d?.overFcl && OVER_FCL[d.overFcl]
          })
        )
    )
    return getDifference(emp, list)
  }

  pipe(
    ZIO.effectTotal(() => {
      if (rows1 && rows1.length) {
        const lastFinalUw: UwDecision[] | null = [...rows1]
        lastFinalUw && setValue('finalUwDecision', lastFinalUw[0].finalUwDecision)
      }
    }),
    ErrorHandling.runDidUpdate([rows1])
  )

  return (
    <SC.Container>
      <SC.BorderContainer>
        <Form.Group>
          <SectionHeader>{t('contract_info')}</SectionHeader>
          <Form.Row>
            <Form.Col>
              <Input label={t('proposal_no')} value={contractId.masterContractNo} readonly />
            </Form.Col>
            <Form.Col>
              <Input label={t('policy_no')} value={contractId.masterContractNo} readonly />
            </Form.Col>
            <Form.Col>
              <Input
                label={t('proposal_status')}
                value={ProposalStatusOptions.find((_) => _.value === detail?.status)?.label || t('new_HSYCH')}
                readonly
              />
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <Controller
              control={control}
              name="finalUwDecision"
              render={({ field }) => (
                <Select
                  required
                  options={options}
                  label={t('underwriting_contract_decision')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  disabled={!isEditDecision}
                  errorMessage={errors?.finalUwDecision?.message}
                />
              )}
            />
          </Form.Row>
        </Form.Group>
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader> {t('history_decision_for_contract')}</SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>{t('underwriting_decision')}</Table.CellHead>
                <Table.CellHead>{t('datetime')}</Table.CellHead>
                <Table.CellHead>{t('change_by')}</Table.CellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={3} rows={rows1} loading={loading1} />
              {rows1?.map((row, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{UwDecisionLabel(row.finalUwDecision)}</Table.Cell>
                  <Table.Cell>{Format.datetime(row.createdDate)}</Table.Cell>
                  <Table.Cell>{row.createdBy}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooterPagination
              total={total1}
              size={sizeTable1}
              page={pageTable1}
              onPageChange={setPageTable1}
              onSizeChange={setSizeTable1}
              colspan={3}
            />
          </Table.Main>
        </Table.Container>
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader> {t('underwriting_decision_for_employee')}</SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>{t('name')}</Table.CellHead>
                <SC.TableCellHead>{t('email')}</SC.TableCellHead>
                <SC.TableCellHead>{t('main_product')}</SC.TableCellHead>
                <SC.TableCellHead>{t('sum_assured')}</SC.TableCellHead>
                <SC.TableCellHead>{t('over_fcl')}</SC.TableCellHead>
                <SC.TableCellHead>{t('over_amount_fcl')}</SC.TableCellHead>
                <SC.TableCellHead>
                  {t('decision')}
                  <SC.StarDot> *</SC.StarDot>
                </SC.TableCellHead>
                <SC.TableCellHead align={'center'}>{t('comment')}</SC.TableCellHead>
                <Table.CellHead></Table.CellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={8} rows={rows2} loading={loading2} />
              {rows2?.map((row, i) => (
                <Table.Row key={i + pageTable2 * sizeTable2}>
                  <Table.Cell>{row.employeeName}</Table.Cell>
                  <SC.TableCell>{row.email}</SC.TableCell>
                  <SC.TableCell>{row.basicPlan}</SC.TableCell>
                  <SC.TableCell>{Format.currencyForBusiness(row.basicSa)}</SC.TableCell>
                  <SC.TableCell style={{ marginTop: '-18px' }}>
                    <Controller
                      control={control}
                      name={`employees.${i + pageTable2 * sizeTable2}.overFcl`}
                      defaultValue={getDefaultValue(row?.overFcl)}
                      render={({ field }) => (
                        <Select
                          options={FormService.decisionOptions}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e)
                            trigger(`employees.${i + pageTable2 * sizeTable2}.overFclAmount`)
                          }}
                          onBlur={field.onBlur}
                          errorMessage={_.get(errors, `employees.${i + pageTable2 * sizeTable2}.overFcl`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>

                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`employees.${i + pageTable2 * sizeTable2}.overFclAmount`}
                      defaultValue={row.overFclAmount.toString()}
                      render={({ field, fieldState: { error } }) => (
                        <InputCurrency
                          value={field.value}
                          onChangeText={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`employees.${i + pageTable2 * sizeTable2}.decision`}
                      defaultValue={
                        row.uwDecision
                          ? row.uwDecision
                          : getDefaultValue(row?.overFcl) === FormService.decisionOptions[1].value
                          ? options[1].value
                          : ''
                      }
                      rules={{
                        required: {
                          value: watch(`employees.${i + pageTable2 * sizeTable2}.overFcl`) === FormService.yesNoOptions[0].value,
                          message: `${t('message:MS000068', { field: t('over_amount_fcl') })}`
                        }
                      }}
                      render={({ field }) => (
                        <BorderSelect
                          required={watch(`employees.${i + pageTable2 * sizeTable2}.overFcl`) === FormService.yesNoOptions[0].value}
                          value={field.value}
                          options={options}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={!isEditInfo}
                          errorMessage={_.get(errors, `employees.${i + pageTable2 * sizeTable2}.decision`)?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  <SC.TableCell>
                    <Controller
                      control={control}
                      name={`employees.${i + pageTable2 * sizeTable2}.comment`}
                      defaultValue={row.comment}
                      render={({ field, fieldState: { error } }) => (
                        <BorderInput
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          maxLength={255}
                          disabled={!isEditInfo}
                          // errorMessage={_.get(errors, `employees.${i}.comment`)?.message}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </SC.TableCell>
                  {/* <SC.TableCell align="center">
                    <SendEmail email={detail?.policyHolder.contactAddress.email} />
                  </SC.TableCell> */}
                  <Table.Cell>
                    <Pressable
                      onPress={() => {
                        BusinessViewEmployeeOverFCLModalService.view({
                          masterContractNo: contractId.masterContractNo,
                          policyYear: contractId.policyYear.toString(),
                          employeeKey: rows2[i].employeeKey
                        })
                      }}
                    >
                      <assets.IconVerticalElipsis />
                    </Pressable>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooterPagination
              total={total2}
              size={sizeTable2}
              page={pageTable2}
              onPageChange={setPageTable2}
              onSizeChange={setSizeTable2}
              colspan={8}
            />
          </Table.Main>
        </Table.Container>
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader> {t('history_decision_for_employee')}</SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>{t('name')}</Table.CellHead>
                <SC.TableCellHead>{t('email')}</SC.TableCellHead>
                <SC.TableCellHead>{t('main_product')}</SC.TableCellHead>
                <SC.TableCellHead>{t('sum_assured')}</SC.TableCellHead>
                <SC.TableCellHead>{t('over_fcl')}</SC.TableCellHead>
                <SC.TableCellHead>{t('over_amount_fcl')}</SC.TableCellHead>
                <SC.TableCellHead>{t('decision')}</SC.TableCellHead>
                <SC.TableCellHead align={'center'}>{t('comment')}</SC.TableCellHead>
                <Table.CellHead></Table.CellHead>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <TableStatus colSpan={7} rows={rows3} loading={loading3} />
              {rows3?.map((row, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{row.employeeName}</Table.Cell>
                  <SC.TableCell>{row.email}</SC.TableCell>
                  <SC.TableCell>{row.basicPlan}</SC.TableCell>
                  <SC.TableCell>{Format.currencyForBusiness(row.basicSa)}</SC.TableCell>
                  <SC.TableCell>{row?.overFcl}</SC.TableCell>
                  <SC.TableCell>{Format.currencyForBusiness(row.overFclAmount)}</SC.TableCell>
                  <SC.TableCell>{UwDecisionLabel(row.uwDecision)}</SC.TableCell>
                  <SC.TableCell>{row.comment}</SC.TableCell>
                  <Table.Cell>
                    <Pressable
                      onPress={() => {
                        BusinessViewEmployeeModalService.view({
                          masterContractNo: contractId.masterContractNo,
                          policyYear: contractId.policyYear.toString(),
                          employeeKey: rows3[i]._id!
                        })
                      }}
                    >
                      <assets.IconVerticalElipsis />
                    </Pressable>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooterPagination
              total={total3}
              size={sizeTable3}
              page={pageTable3}
              onPageChange={setPageTable3}
              onSizeChange={setSizeTable3}
              colspan={7}
            />
          </Table.Main>
        </Table.Container>
      </SC.BorderContainer>
    </SC.Container>
  )
}

const options: SelectOption[] = [
  {
    label: UwDecisionLabel(UwDecisionCode.Medical),
    value: UwDecisionCode.Medical
  },
  {
    label: UwDecisionLabel(UwDecisionCode.Accept),
    value: UwDecisionCode.Accept
  },
  {
    label: UwDecisionLabel(UwDecisionCode.ConditionalAccept),
    value: UwDecisionCode.ConditionalAccept
  },
  {
    label: UwDecisionLabel(UwDecisionCode.Postpone),
    value: UwDecisionCode.Postpone
  },
  {
    label: UwDecisionLabel(UwDecisionCode.Decline),
    value: UwDecisionCode.Decline
  },
  {
    label: UwDecisionLabel(UwDecisionCode.NotTakenUp),
    value: UwDecisionCode.NotTakenUp
  },
  {
    label: UwDecisionLabel(UwDecisionCode.Withdraw),
    value: UwDecisionCode.Withdraw
  }
]
