import { DatePicker, Input, Select, SelectOption, SelectSearch, assets } from "@pulseops/common"
import { ControllerFieldState, ControllerRenderProps, UseFormStateReturn } from "react-hook-form"
import { OBAdvantageSearch, OBBasicSearch } from "./ob-search-form"
import React from 'react'
import { TFunction } from "react-i18next"
import { OBTaskFilterForm } from "../ob-task-management/ob-common"

export namespace SearchFields {
  export type TypeNameFieldBasic = 'policyNumber' | 'idNumber' | 'clientNumber' | 'phoneNumber' | 'fromDate' | 'toDate'
  export type TypeNameFieldAdvantage = 'fromDate' | 'toDate' | 'agency' | 'leader' | 'lastAssignee' | 'callProgram' | 'status' | 'currentActivity' | 'firstIssueFromDate' | 'firstIssueToDate'
  interface FieldSearchInput {
    formName: string,
    type: 'INPUT',
    title: string,
    inputType: string
    maxLength?: number
    rules?: Object
  }

  interface FieldSelect {
    formName: string,
    type: 'SELECT',
    title: string,
    option: SelectOption[],
    rules?: Object
  }

  interface FieldSearchSelect {
    formName: string,
    type: 'SEARCHSELECT',
    title: string,
    option: SelectOption[],
    rules?: Object
  }

  interface FieldSearchDate {
    formName: string,
    type: 'DATE',
    required?: boolean,
    title: string
    maxDate?: Date,
    maxDateMessage?: string
    minDate?: Date,
    minDateMessage?: string
    rules?: Object
  }

  export type FieldSearch = FieldSearchInput | FieldSelect | FieldSearchSelect | FieldSearchDate | OBTaskFilterForm.FieldSearchDateFromTo
  export type InputType = 'text' | 'number' | 'money'

  export const basicSearchFieldList: (t: TFunction<"translation">) => FieldSearch[] = (t) => [
    {
      formName: 'policyNumber',
      type: 'INPUT',
      title: t('ServiceInquiry:PolicyNumber'),
      inputType: 'number',
      maxLength: 8,
      rules: {
        minLength: { value: 8, message: t('message:OB0024', { field: t('ServiceInquiry:PolicyNumber'), k: 8 }) }
      }
    },
    {
      formName: 'clientNumber',
      type: 'INPUT',
      title: t('ServiceInquiry:ClientNumber'),
      inputType: 'number',
      maxLength: 8,
      rules: {
        minLength: { value: 8, message: t('message:OB0024', { field: t('ServiceInquiry:ClientNumber'), k: 8 }) }
      }
    },
    // {
    //   formName: 'idNumber',
    //   type: 'INPUT',
    //   title: t('ServiceInquiry:IDNumber'),
    //   inputType: 'text',
    //   maxLength: 24,
    //   rules: {
    //     minLength: { value: 2, message: t('message:OB0025') }
    //   }
    // },
    // {
    //   formName: 'phoneNumber',
    //   type: 'INPUT',
    //   title: t('ServiceInquiry:PhoneNumber'),
    //   inputType: 'number',
    //   maxLength: 10,
    //   rules: {
    //     minLength: { value: 10, message: t('message:OB0026', { field: t('ServiceInquiry:PhoneNumber') }) }
    //   }
    // },
    {
      formName: 'fromDate',
      type: 'DATE',
      title: t('ServiceInquiry:FromDate'),
      required: true,
      rules: {
        required: { value: true, message: t('message:MS020009', { field: t('ServiceInquiry:FromDate') }) }
      },
      maxDate: new Date()
    },
    {
      formName: 'toDate',
      type: 'DATE',
      title: t('ServiceInquiry:ToDate'),
      required: true,
      rules: {
        required: { value: true, message: t('message:MS020009', { field: t('ServiceInquiry:ToDate') }) }
      },
      maxDate: new Date()
    }
  ]

  export const advancedSearchFieldList: (t: TFunction<"translation">) => FieldSearch[] = (t) => [
    {
      formName: 'callType',
      type: 'SELECT',
      title: t('Outbound:OBServiceInquiry:CallType'),
      option: []
    },
    {
      formName: 'agency',
      type: 'SELECT',
      title: t('Outbound:OBServiceInquiry:Agency'),
      option: []
    },
    {
      formName: 'group',
      type: 'SELECT',
      title: t('Outbound:OBServiceInquiry:Group'),
      option: []
    },
    {
      formName: 'assignee',
      type: 'INPUT',
      inputType: 'text',
      title: t('Outbound:OBServiceInquiry:Assignee'),
    },
    {
      formName: 'status',
      type: 'SELECT',
      title: t('Outbound:OBServiceInquiry:Status'),
      option: []
    },
    {
      formName: 'fromDate',
      type: 'DATE',
      title: t('ServiceInquiry:FromDate'),
      required: true,
      rules: {
        required: { value: true, message: t('message:MS020009', { field: t('ServiceInquiry:FromDate') }) }
      },
      maxDate: new Date()
    },
    {
      formName: 'toDate',
      type: 'DATE',
      title: t('ServiceInquiry:ToDate'),
      required: true,
      rules: {
        required: { value: true, message: t('message:MS020009', { field: t('ServiceInquiry:ToDate') }) }
      },
      maxDate: new Date()
    }
  ]

  export const getFieldSearch = ({
    field,
    controller,
  }: {
    field: FieldSearch
    controller: {
      field: ControllerRenderProps<OBBasicSearch, TypeNameFieldBasic> | ControllerRenderProps<OBAdvantageSearch, TypeNameFieldAdvantage>
      fieldState: ControllerFieldState,
      formState: UseFormStateReturn<OBBasicSearch> | UseFormStateReturn<OBAdvantageSearch>
    },
  }) => {
    switch (field.type) {
      case 'INPUT':
        return (
          <Input
            title={field.title}
            value={controller.field.value as string | undefined}
            maxLength={field.maxLength}
            inputType={field.inputType as InputType}
            onChange={controller.field.onChange}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      case 'DATE':
        return (
          <DatePicker
            required={field.required}
            label={field.title}
            onChange={controller.field.onChange}
            value={controller.field.value as Date | undefined | null}
            maxDate={field.maxDate}
            maxDateMessage={field.maxDateMessage}
            minDate={field.minDate}
            minDateMessage={field.minDateMessage}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      case 'SELECT':
        return (
          <Select
            label={field.title}
            options={field.option}
            onChange={controller.field.onChange}
            value={controller.field.value as SelectOption | undefined | null}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      case 'SEARCHSELECT':
        return (
          <SelectSearch
            label={field.title}
            options={field.option}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            onChange={controller.field.onChange}
            value={controller.field.value as SelectOption | undefined | null}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      default:
        return <></>
    }
  }
}