import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { PulseOpsFormat } from '../Formatter'
import { ChangeContactInfoService } from './ChangeContactInfoService'
import { InquiryComplaintService } from './InquiryComplaintService'
import { Inheritance as InheritanceModel } from './model'
import { SubmissionService } from './SubmissionService'
import { TopUpService } from './TopUpService'

export namespace InheritanceService {
  export const getDetail = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policyInfo) =>
        ZIO.zipPar(
          SubmissionService.getClientsByPolicy(policyNum),
          ZIO.succeed(policyInfo),
          InquiryComplaintService.getIAC_ClientRelationShip(policyNum),
          TopUpService.GetPolicyExtraInfo([policyNum], ''),
          ChangeContactInfoService.getPolicyList(policyNum, policyInfo.body.owners.id ?? '')
        )
      ),
      ZIO.flatMap(([customerList, policyInfo, relationShipList, policyExt, clientPolicyList]) => {
        const benList = customerList.benList.filter((x) => x.clientNumberOfBen !== policyInfo.body.owners.id)
        const laList = customerList.laList.filter((x) => x.clientNumberOfLA !== policyInfo.body.owners.id)
        return pipe(
          SubmissionService.getClients(
            [...benList.map((x) => x.clientNumberOfBen), ...laList.map((x) => x.clientNumberOfLA)]
              .filter(PulseOpsFormat.onlyUniqueArrayFilter)
              .map((x) => ({ clientId: x }))
          ),
          ZIO.map((ClientPolicyList): InheritanceModel.Detail => {
            const getRole = (id: string) =>
              id === policyInfo.body.owners.id
                ? 'PolicyOwner'
                : customerList.laList.map((x) => x.clientNumberOfLA).includes(id)
                ? 'LifeAssured'
                : customerList.benList.map((x) => x.clientNumberOfBen).includes(id)
                ? 'Beneficiary'
                : '-'

            const getRelationShipWithLA = (LANumber: string) => {
              const { BORelationshipList, CBrelationshipList } = relationShipList
              const clientId = laList.find(({ life }) => life === LANumber)?.clientNumberOfLA
              const clientNumInfo = BORelationshipList.find((x) => x.relationship === clientId)
              const relationshipFilterItem = CBrelationshipList.find((p) => p.code === clientNumInfo?.clientNum)
              return relationshipFilterItem ? relationshipFilterItem.code : ''
            }

            const relationShipWithMainLA = (clientId: string | null) => {
              const { BORelationshipList, CBrelationshipList, BERelationshipList } = relationShipList
              const result = {
                relationShipWithMainLifeAssured: '',
                relationShipWithMainLifeAssuredLA2: '',
                relationShipWithMainLifeAssuredLA3: '',
                relationShipWithMainLifeAssuredLA4: ''
              }

              // LA
              const clientNumInfo = BORelationshipList.find((x) => x.relationship === clientId)
              const relationshipFilterItem = CBrelationshipList.find((p) => p.code === clientNumInfo?.clientNum)
              if (relationshipFilterItem) {
                result.relationShipWithMainLifeAssured = relationshipFilterItem.code
                result.relationShipWithMainLifeAssuredLA2 = getRelationShipWithLA('02')
                result.relationShipWithMainLifeAssuredLA3 = getRelationShipWithLA('03')
                result.relationShipWithMainLifeAssuredLA4 = getRelationShipWithLA('04')
                return result
              }

              // BEN
              const benItem = benList.find((x) => x.clientNumberOfBen === clientId)
              const relationshipFilterItemBEN = BERelationshipList.find((p) => p.code === benItem?.relationShipCode)
              if (relationshipFilterItemBEN) {
                result.relationShipWithMainLifeAssured = relationshipFilterItemBEN.code
              }

              return result
            }

            return {
              customerId: policyInfo.body.owners.id || '-',
              policyHasBen: benList.length > 0,
              laList: laList.map((la) => {
                const client = ClientPolicyList.body.find(({ clientId }) => clientId === la.clientNumberOfLA)
                return {
                  ...la,
                  name: client?.name ?? ''
                }
              }),
              customerData: ClientPolicyList.body
                .filter((x) => x.dod === '99999999')
                .map((x) => ({
                  customerId: x.clientId || '',
                  idType: x.externalIds?.SOE || '',
                  idNum: x.externalIds?.SOE_VALUE || '',
                  issuedAt: '',
                  name: x.firstName,
                  surName: x.surName || '',
                  dod: x.dod || '',
                  fatca: false,
                  dob: x.dob || '',
                  gender: x.sex || '',
                  nationality: x.nationality || '',
                  nationality2: x.attributesExt.NATIONALITY_2 || '',
                  mobilePhoneCode: x.attributesExt.MOBILE_CODE || '',
                  mobilePhoneNumber: x.mobilePhone || '',
                  email: x.email || '',
                  role: getRole(x.clientId || ''),
                  occupation: x.occupation || '',
                  address: x.addressDetails?.PRIMARY.line1 || '',
                  countryCode: x.countryCode || '',
                  city: x.location?.slice(0, 2) || '',
                  district: x.location?.slice(2, 4) || '',
                  ward: x.location || '',
                  foreignAddress: x.attributesExt.FOREIGN_STREET || '',
                  foreignCountry: x.attributesExt.FOREIGN_COUNTRY || '',
                  ...relationShipWithMainLA(x.clientId ?? '')
                })),
              sumAssured: policyExt.policyExtraInfoDetail.reduce((acc, curr) => {
                return (curr.lifeNo === '01' && curr.riderNo === '00') || curr.productCode.startsWith('TLR')
                  ? acc + curr.sumAssured
                  : acc
              }, 0),
              clientPolicyList
            }
          })
        )
      })
    )
}
