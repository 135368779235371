import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import {  ZIO } from '@mxt/zio'
import { POApi } from '@pulseops/common'
import _ from 'lodash'


export namespace DaPolicyInformationServices {
  
  export const transferDetail = Maybe(
    t.type({
      policyNum: Maybe(t.string),
      cownNum: Maybe(t.string),
      cownSurName: Maybe(t.string),
      cownGivName: Maybe(t.string),
      cownName: Maybe(t.string),
      cnttype: Maybe(t.string),
      statcode: Maybe(t.string),
      issueDate: Maybe(t.string),
      ptDate: Maybe(t.string),
      occDate: Maybe(t.string),
      policyYear: Maybe(t.number),
      serviceAgents: t.array(
        t.type({
          agentCode: Maybe(t.string),
          clientNum: Maybe(t.string),
          agentSurName: Maybe(t.string),
          agentGivName: Maybe(t.string),
          agentName: Maybe(t.string),
          appointedDate: Maybe(t.string),
          terminateDate: Nullable(t.string),
          agentType: Nullable(t.string),
          title: Nullable(t.string),
          unitCode: Nullable(t.string),
          unitDescription: Nullable(t.string),
          branchCode: Nullable(t.string),
          branchDescription: Nullable(t.string),
          officeCode: Nullable(t.string),
          officeDescription: Nullable(t.string),
          regionCode: Maybe(t.string),
          regionDescription: Maybe(t.string),
          subzoneCode: Nullable(t.string),
          subzoneDescription: Nullable(t.string),
          zoneCode: Nullable(t.string),
          zoneDescription: Nullable(t.string),
          debt: Nullable(t.string),
          workTime: Maybe(t.string),
          salezone: Maybe(t.string),
          rateInforce: Maybe(t.string),
          qualifiedProduct: Nullable(t.string)
        })
      ),
      transferAgent: Maybe(
        t.type({
          agentCode: Maybe(t.string),
          clientNum: Maybe(t.string),
          agentSurName: Maybe(t.string),
          agentGivName: Maybe(t.string),
          agentName: Maybe(t.string),
          appointedDate: Maybe(t.string),
          terminateDate: Maybe(t.string),
          agentType: Nullable(t.string),
          title: Nullable(t.string),
          unitCode: Nullable(t.string),
          unitDescription: Nullable(t.string),
          branchCode: Nullable(t.string),
          branchDescription: Nullable(t.string),
          officeCode: Nullable(t.string),
          officeDescription: Nullable(t.string),
          regionCode: Maybe(t.string),
          regionDescription: Maybe(t.string),
          subzoneCode: Nullable(t.string),
          subzoneDescription: Nullable(t.string),
          zoneCode: Nullable(t.string),
          zoneDescription: Nullable(t.string),
          debt: Nullable(t.string),
          workTime: Maybe(t.number),
          salezone: Maybe(t.string),
          rateInforce: Maybe(t.number),
          qualifiedProduct: Nullable(t.string)
        })
      ),
      transferReason: Maybe(t.string),
      transferHistory: t.array(
        t.type({
          fromAgent: Maybe(t.string),
          orphan: Maybe(t.string),
          reasonDesc: Maybe(t.string),
          toAgent: Maybe(t.string),
          transactionDate: Maybe(t.string),
          userId: Maybe(t.string)
        })
      )
    })
  )

  export type transferDetail = t.TypeOf<typeof transferDetail>

  export const getDetailTransfer = (policyNum: string, transferAgent?: string) =>
    pipe(
      POApi.getConfig({ params: { policyNum, transferAgent } })(
        'distribution-agents-service/policy/get-policy-transfer-info'
      )(transferDetail),
      ZIO.map((res) => res)
    )
}
