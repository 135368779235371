import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  COLORS,
  FieldsType,
  groupsViewsConst,
  GroupsViewType,
  typeField,
  TypeFieldEnum,
  typeGroupName,
  typeNameField,
  valuesFormType
} from './change-occupation.const'
import { CN, TextCustom } from './change-occupation.style'
import { Controller, ControllerRenderProps, UseFormStateReturn, ControllerFieldState } from 'react-hook-form'
import { ChangeOccupationForm } from './change-occupation.form'
import {
  SelectSearch,
  Input,
  assets,
  GeneralService,
  SelectOption,
  TransactionType,
  ErrorHandling,
  AppContext,
  TaskType,
  ChangeClientInfoService,
  form2,
  ChangeClientInfo
} from '@pulseops/common'
import { isEmpty, isNil } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { ChangeOccupationService, RequestAuthenticateData, UploadedFilesInfo } from '../..'
import { OccupationDetailType } from './models'
import { RouteProp, useRoute } from '@react-navigation/native'
import { PSSubmissionStackParamList } from '../../../PSSubmissionStackParamList'

const { Container, Row, Col_3, GROUP } = CN

interface Props {
  isConfirmed: boolean
  officeCode?: string
  initSubmission: ({ validate, clear }: { validate: any; clear: () => void }) => void
}

const defaultValues = {
  selectClient: null,
  occupation: null,
  jobDescription: '',
  jobTitle: '',
  occupationClass: '',
  companyName: '',
  companyAddress: '',
  salary: null
}

const defaultOccupationDetail = {
  id: '',
  code: '',
  classOccupation: '',
  nameEn: '',
  nameVn: '',
  isEnableCompanyInfo: '',
  positionCode: '',
  positionName: ''
}

const fieldsDefault: valuesFormType[] = [
  { key: 'companyAddress', value: '' },
  { key: 'companyName', value: '' },
  { key: 'jobDescription', value: '' },
  { key: 'jobTitle', value: '' },
  { key: 'occupationClass', value: '' }
]

const ChangeOccupationScreen: React.FC<Props> = ({ isConfirmed, initSubmission }) => {
  const { t } = useTranslation('landingPage')
  const { t: translateOccupation, i18n } = useTranslation('CHANGE_OCCUPATION')
  const { showToast } = useContext(AppContext.AppContextInstance)

  const [occupationDetail, setOccupationDetail] = useState<OccupationDetailType>(() => defaultOccupationDetail)
  const [groupsViews, setGroupsViews] = useState<GroupsViewType<typeGroupName, typeNameField>[] | []>(() =>
    groupsViewsConst(translateOccupation)
  )
  const [customers, setCustomers] = useState<ChangeClientInfo.CustomerData[]>([])

  const {
    base: { control, getValues, reset, trigger, setValue }
  } = form2.useForm(ChangeOccupationForm.codec, {
    defaultValues
  })

  const { params } = useRoute<RouteProp<PSSubmissionStackParamList, 'PSSubmissionScreen'>>()

  const getRole = useCallback(
    (role: string) => {
      return role === 'PolicyOwner'
        ? t('roles:PolicyOwner')
        : role === 'Beneficiary'
        ? t('roles:Beneficiary')
        : role === 'LifeAssured'
        ? t('roles:LifeAssured')
        : '-'
    },
    [t]
  )

  const setValues = (values: valuesFormType[]) => values.map(({ key, value }) => setValue(key, value ?? ''))

  const callAPIGetOccupaion = () => {
    return pipe(
      ChangeOccupationService.getOccupations,
      ZIO.map((occupations) => {
        return occupations.map((option) => ({
          value: option.code,
          label: i18n.language === 'vi' ? option.nameVn : option.nameEn
        }))
      }) || []
    )
  }

  const callAPIGetSalaries = () => {
    return pipe(
      GeneralService.getSalaries,
      ZIO.map((salaries) => {
        return salaries.map((salary) => ({
          value: salary.code,
          label: i18n.language === 'vi' ? salary.name : salary.nameEn
        }))
      })
    )
  }

  const callAPIgetOccupationDetailByCode = (code: string) => {
    return pipe(
      ChangeOccupationService.getOccupationDetailByCode(code),
      ZIO.foldM(
        (err) =>
          ZIO.effect(() => {
            showToast(translateOccupation('common:SomeThingWentWrong'), 'error')
            setValues(fieldsDefault)
            setOccupationDetail(defaultOccupationDetail)
            return ZIO.fail(Throwable(err))
          }),
        (success) => {
          if (isNil(success)) return ZIO.succeed({})

          setOccupationDetail(success)
          setValues([
            { key: 'jobTitle', value: success?.positionName ?? '' },
            { key: 'occupationClass', value: success?.classOccupation ?? '' }
          ])
          return ZIO.succeed(success)
        }
      ),
      ZIO.unsafeRun({})
    )
  }

  const mappingWithGroupFields = (groupName: typeGroupName, options: Array<{ key: typeNameField; value: unknown }>) => {
    const groupsViewsClone = [...groupsViews]
    const infoGroup = groupsViewsClone.find((elm) => elm.groupName === groupName)

    const fields =
      infoGroup?.fields.map((elm) => {
        return {
          ...elm,
          options: options.find((_e) => _e.key === elm.formName)?.value ?? elm.options ?? []
        }
      }) ?? []

    return {
      ...infoGroup,
      fields
    }
  }

  pipe(
    ZIO.zipPar(ChangeClientInfoService.getDetail(params.policyNum), callAPIGetOccupaion(), callAPIGetSalaries()),
    ZIO.map(([detail, occupations, salaries]) => {
      const clients = detail.customerData.map((dc) => ({
        value: dc.customerId,
        label: `${dc.surName ?? ''} ${dc.name} - ${getRole(dc.role)}`
      }))

      const infoChangeClientGroup = mappingWithGroupFields('info_change_client_group', [
        { key: 'selectClient', value: clients }
      ])

      const infoChangeOccupationGroup = mappingWithGroupFields('info_change_occupation_group', [
        { key: 'occupation', value: occupations },
        { key: 'salary', value: salaries }
      ])

      const newArray = [infoChangeClientGroup, infoChangeOccupationGroup] as GroupsViewType<
        typeGroupName,
        typeNameField
      >[]

      const groupsViewsFuture = ChangeOccupationService.replaceTwoArray<GroupsViewType<typeGroupName, typeNameField>>(
        [...groupsViews],
        newArray,
        'groupName'
      )

      setCustomers(detail.customerData)
      setGroupsViews([...groupsViewsFuture])
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  )

  /**
   * FUNCTIONS
   */
  const onChangeForm = (fieldName: typeNameField, payload: SelectOption | null) => {
    const isFieldOccupation = fieldName === 'occupation'
    const fieldsNeedClears = isNil(payload)
      ? fieldsDefault
      : fieldsDefault.filter((elm) => !['jobTitle', 'occupationClass'].includes(elm.key))

    isFieldOccupation && setValues(fieldsNeedClears)
    !isNil(payload) && isFieldOccupation && callAPIgetOccupationDetailByCode(payload?.value ?? '')
  }

  const mappingBodySubmisson = () => {
    const { selectClient, occupation, jobDescription, jobTitle, companyName, companyAddress, salary, occupationClass } =
      getValues()
    const { positionCode } = occupationDetail

    const getCustommerBySelectClient = () => [...customers]?.find((item) => item.customerId === selectClient?.value)

    return {
      clientId: selectClient?.value ?? '',
      occupation: occupation?.value ?? '',

      name: selectClient?.label?.split(new RegExp('-'))[0] ?? '',
      dob: getCustommerBySelectClient()?.dob || '',
      sex: getCustommerBySelectClient()?.gender || '',
      firstName: getCustommerBySelectClient()?.name || '',
      surName: getCustommerBySelectClient()?.surName || '',
      nationality: 'VN',

      attributesExt: {
        clientNum: selectClient?.value ?? '',
        occupationFlag: 'Y',
        specificJob: jobDescription ?? '',
        title: jobTitle ?? '',
        workPlace: companyName ?? '',
        workAddress: companyAddress ?? '',
        salary: salary?.value ?? '',
        class: occupationClass ?? '',
        NEW_OCCUPATION: occupation?.value ?? '',
        OCCUPATION_GROUP: positionCode ?? ''
      }
    }
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: [],
      transactionType: TransactionType.CHANGE_OCCUPATION_PERSONAL,
      docTypeCode: 'DPS17',
      category: TaskType.PolicyService,
      policyNumber: params.policyNum ?? '',
      officeCode: params.officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  const handleDisabled = (fieldName: typeNameField, defaultDisabled: boolean) => {
    const disableMap = new Map<typeNameField | 'default', boolean>([
      ['companyName', !!occupationDetail.isEnableCompanyInfo ? occupationDetail.isEnableCompanyInfo === 'N' : true],
      ['companyAddress', !!occupationDetail.isEnableCompanyInfo ? occupationDetail.isEnableCompanyInfo === 'N' : true],
      ['jobDescription', occupationDetail.positionCode !== 'OCG3'],
      ['occupationClass', true],
      ['default', defaultDisabled]
    ])

    return disableMap.get(fieldName) ?? disableMap.get('default')
  }

  initSubmission({
    validate: async () => {
      const isValidForm = await trigger()

      if (!isValidForm) return false

      const { selectClient } = getValues()

      return {
        url: () => `wf-api/customer/change-occupation/${selectClient?.value}`,
        body: mappingBodySubmisson(),
        transactionName:
          RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_OCCUPATION_PERSONAL) || '',
        collerationId: params.policyNum || '',
        transaction: TransactionType.CHANGE_OCCUPATION_PERSONAL,
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      reset({ ...defaultValues })
    }
  })

  /**
   * VIEWS
   */
  const renderTypeField = ({
    field,
    controller
  }: {
    field: FieldsType<typeNameField>
    controller: {
      field: ControllerRenderProps<ChangeOccupationForm.Raw, typeNameField>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<ChangeOccupationForm.Raw>
    }
  }) => {
    const { type, placeholder, required, options, editable } = field
    const {
      field: { onChange, onBlur, value },
      fieldState: { error }
    } = controller

    const disabled = !editable

    const label = translateOccupation(`GROUPS_VIEWS.FIELDS.${field.formName}`)

    const fieldTypeMap = new Map<typeField | 'DEFAULT', Function>([
      [
        TypeFieldEnum['TEXTBOX'],
        () => (
          <Input
            title={label}
            disabled={isConfirmed || handleDisabled(field.formName, disabled)}
            placeholder={placeholder}
            required={required}
            value={(value as string) || ''}
            onBlur={onBlur}
            onChange={onChange}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxLength={field.formName === 'occupationClass' ? 50 : 100}
          />
        )
      ],
      [
        TypeFieldEnum['DROPDOWN_LIST'],
        () => (
          <SelectSearch
            label={label}
            options={options}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e), onChangeForm(field.formName, e)
            }}
            onBlur={onBlur}
            value={value as any}
            disabled={isConfirmed || handleDisabled(field.formName, disabled)}
            required={required}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxLength={100}
          />
        )
      ],
      ['DEFAULT', () => <></>]
    ])

    return (fieldTypeMap.get(type) ?? fieldTypeMap.get('DEFAULT'))?.()
  }

  const renderFields = (fields: FieldsType<typeNameField>[]) => {
    return fields.map((_field, index) => {
      const { formName, rules } = _field
      return (
        <Col_3 key={index}>
          <Controller
            name={formName}
            control={control}
            render={(controller) => <>{renderTypeField({ field: _field, controller })}</>}
            rules={rules}
          />
        </Col_3>
      )
    })
  }

  const renderGroupsViews = () => {
    return groupsViews?.map(({ fields, isVisible, labelGroup, isBorder, groupName }, index) => {
      return (
        <GROUP key={`${labelGroup}_${index}`}>
          <TextCustom fontSize="15px" fontWeight="800" textColor="black">
            {translateOccupation(`GROUPS_VIEWS.LABEL_GROUP.${groupName}`).toUpperCase()}
          </TextCustom>

          {isVisible ? (
            <Row
              style={[
                { marginTop: 12 },
                isBorder
                  ? {
                      borderRadius: 6,
                      paddingHorizontal: 12,
                      paddingVertical: 30,
                      borderStyle: 'solid',
                      borderColor: COLORS.HOWKES_BLUE,
                      borderWidth: 1
                    }
                  : {}
              ]}
            >
              {renderFields(fields)}
            </Row>
          ) : null}
        </GROUP>
      )
    })
  }

  return <Container>{renderGroupsViews()}</Container>
}

export default ChangeOccupationScreen
