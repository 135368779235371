import React from 'react'
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { OBSharedStyles } from '../ob-shared'

export type OBButtonProps = {
  text: string
  buttonContainerStyle?: StyleProp<ViewStyle>
  onHandleClickEvent?: () => void
  isHightLight?: boolean
  disabled?: boolean
}

export const OBButton = (props: OBButtonProps) => {
  const { disabled, text, onHandleClickEvent, isHightLight, buttonContainerStyle } = props
  return (
    <TouchableOpacity disabled={disabled} onPress={() => onHandleClickEvent && onHandleClickEvent()}>
      <View
        style={[
          OBSharedStyles.actionContent,
          buttonStyles.actionLine,
          isHightLight && OBSharedStyles.actionHightLight,
          buttonContainerStyle,
          disabled && OBSharedStyles.actionNonBackgroundDisable
        ]}
      >
        <Text style={[OBSharedStyles.actionContentText, isHightLight && OBSharedStyles.actionHightLightText, disabled && OBSharedStyles.actionNonBackgroundDisableText]}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}
const buttonStyles = StyleSheet.create({
  actionLine: {
    marginLeft: 8
  }
})
