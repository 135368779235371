import React from 'react'
import { TableSortLabel, TableRow, TableCell, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import { View, Text } from 'react-native'
interface Props {
  data: any
  setData: any
  column: Array<{
    label: string | JSX.Element
    disabled?: boolean
    field?: string
    visible?: boolean
    isRequired?: boolean
    columnRender?: (list: any) => JSX.Element
    widthColumn?: string | number
  }>
  setSortItem: any
  sortItem: {
    field: string
    order: 'asc' | 'desc' | undefined
  }
  sortVNI?: boolean
  isFilterColumn?: boolean
}
const useStyles = makeStyles({
  tableCell: {
    // fontWeight: 'bold',
    // whiteSpace: 'nowrap'
    backgroundColor: '#F4F4F4',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E'
  },
  verticalTop: {
    verticalAlign: 'top'
  },
  tableCellNoBorder: {
    backgroundColor: '#F4F4F4',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E',
    borderBottom: 0
  }
})

export const TableHeaderSort = ({ data, setData, column, sortItem, setSortItem, sortVNI, isFilterColumn }: Props) => {
  const classes = useStyles()
  const dataSort = React.useRef([])

  React.useEffect(() => {
    const checkNewData = _.isEqual(handleSort(data, sortItem.order), handleSort(dataSort.current, sortItem.order))
    if (!_.isEmpty(sortItem.field) && !checkNewData) {
      dataSort.current = data
      setData(handleSort(data, sortItem.order, sortItem.field))
    }
  }, [data])

  const handleSort = (data: any, order: 'asc' | 'desc' | undefined, field: string | undefined = '') => {
    return sortVNI ? handleSortVNIChar(data, order, field) : handleSortData(data, order, field)
  }

  //if column is type Date then format the value date in data props to "YYYYMMDD"
  const handleSortData = (data: any, order: 'asc' | 'desc' | undefined, field: string | undefined = '') => {
    return _.orderBy(
      data,
      (o) => {
        if (field) {
          const isStringSortType = data.filter((x) => !!parseInt(x[field])).length < data.length
          const customedItem = !!o[field] && !moment(o[field], true).isValid()
            ? o[field].toString().replace(/([^\w]+|\s+)/g, '') : o[field]
          if (!isNumeric(customedItem) && moment(customedItem).isValid() && !isNaN(Date.parse(customedItem))) {
            return new Date(o[field])
          } else if (!!parseInt(o[field]) && !isStringSortType) {
            return parseInt(o[field])
          } else {
            return _.isString(o[field]) ? o[field].toUpperCase() : o[field]
          }
        }
        return o[field]
      },
      order
    )
  }

  const handleSortVNIChar = (data: any, order: 'asc' | 'desc' | undefined, field: string | undefined = '') => {
    return _.orderBy(
      data,
      (o) => {
        if (field) {
          const isStringSortType = data.filter((x) => !!parseInt(x[field])).length < data.length
          if (!!parseInt(o[field]) && !isStringSortType) {
            return parseInt(o[field])
          }
          return _.isString(o[field]) && o[field] ? o[field].toUpperCase().replaceAll(/Ă|Â|Ê|Đ|Ư|Ơ|Ô/gi, (x: string) => {
            return changeVNICharForOrder(x);
          }) : o[field]
        }
        return o[field]
      },
      order
    )
  }

  const changeVNICharForOrder = (value: string) => {
    switch (value) {
      case 'Ă':
        return 'B  '
      case 'Â':
        return 'B '
      case 'Ê':
        return 'F '
      case 'Ô':
        return 'P  '
      case 'Ơ':
        return 'P '
      case 'Đ':
        return 'E '
      case 'Ư':
        return 'V '
      default:
        return value
    }
  }

  const createSortHandler = (field: string) => (event: any) => {
    const order = sortItem.order === 'asc' ? 'desc' : 'asc'
    setData(handleSort(data, order, field))
    setSortItem({
      field,
      order
    })
  }

  const isNumeric = (num: any) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num as number)

  return (
    <TableRow>
      {column.map((item) => {
        const checkElementBelowLabel = column.some((x) => typeof x.label !== 'string')
        if (!item.visible && !_.isUndefined(item.visible)) return <></>
        return item.disabled ? (
          <TableCell
            className={`${isFilterColumn ? classes.tableCellNoBorder : classes.tableCell} ${checkElementBelowLabel ? classes.verticalTop : ''}`}
            style={{ fontSize: 15, ...item.widthColumn && { width: item.widthColumn } }}
          >
            {item.columnRender ? item.columnRender(data) : (
              item.isRequired ? (
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ marginRight: 5, fontWeight: 'bold', color: '#70777E', fontSize: 15 }}>{item.label}</Text>
                  <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                </View>
              ) : (
                item.label
              )
            )}
          </TableCell>
        ) : (
          <TableCell className={`${isFilterColumn ? classes.tableCellNoBorder : classes.tableCell} ${checkElementBelowLabel ? classes.verticalTop : ''}`} style={item.widthColumn ? { width: item.widthColumn } : {}}>
            <TableSortLabel
              direction={sortItem.order}
              onClick={createSortHandler(item.field || '')}
              active={sortItem.field === item.field}
              style={{
                fontSize: 15
              }}
            >
              {item.isRequired ? (
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ marginRight: 5, fontWeight: 'bold', color: '#70777E', fontSize: 15 }}>
                    {item.label}
                  </Text>
                  <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                </View>
              ) : (
                item.label
              )}
            </TableSortLabel>
          </TableCell>
        )
      })}
    </TableRow>
  )
}
