import * as React from 'react'
import { View } from 'react-native'
import { RoundedButton, useMobile } from '@pulseops/common'
interface Props {
  children: any
  disable?: boolean
}

export const TaskActionGroup = ({ children, disable = false }: Props) => {
  const items = React.Children.map(children, (child) => {
    return React.cloneElement(child, { disable: child.props?.disable !== undefined ? child.props?.disable : disable })
  })
  return <View style={{ flexDirection: 'row' }}>{items}</View>
}

interface ActionItemProps {
  label: string
  disable?: boolean
  onPress?: () => void
}

TaskActionGroup.Item = ({ label, disable = false, onPress }: ActionItemProps) => {
  const { isMobile } = useMobile()
  return (
    <RoundedButton
      filled
      activeOpacity={0.7}
      text={label}
      disabled={disable}
      style={{
        marginEnd: isMobile ? 8 : 11,
        height: isMobile ? 28 : 30
      }}
      textStyle={{
        fontSize: isMobile ? 11 : 14,
        marginHorizontal: isMobile ? 12 : 26,
        marginVertical: isMobile ? 5 : 6
      }}
      textColorDisable={'#B0B0B0'}
      textColorEnable={'#ED1B2E'}
      bgColorDisable={'#F4F4F4'}
      bgColorEnable={'rgba(237, 27, 46, 0.15)'}
      onPress={onPress}
    />
  )
}
