import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { LDApi, AppConfig } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import i18next from 'i18next'
import moment from 'moment'
import { StorageBlob } from '@pulseops/common'

const enum ENV {
  PRD = 'pva-prd-az1'
}

export const ClientC = t.type({
  // classCode: Maybe(t.string),
  // clientId: Maybe(t.string),
  // email: Maybe(t.string),
  // mobilePhone: Maybe(t.string),
  // countryCode: Maybe(t.string),
  // location: Maybe(t.string),
  // otpConsentStatus: Maybe(t.string),
  // customerSmsInd: Maybe(t.string),
  // customerEmailInd: Maybe(t.string),
  // agentSmsInd: Maybe(t.string),
  // updateNewPruInfo: Maybe(t.string),
  // name: Maybe(t.string),
  // firstName: t.string,
  // surName: t.string,
  // dob: Maybe(t.string),
  // dod: Maybe(t.string),
  // sex: Maybe(t.string),
  // nationality: Maybe(t.string),
  // occupation: Maybe(t.string),
  // externalIds: Maybe(
  //   t.type({
  //     SOE: Maybe(t.string),
  //     SOE_VALUE: Maybe(t.string)
  //   })
  // ),
  // addressDetails: Maybe(
  //   t.type({
  //     PRIMARY: t.type({
  //       line1: Maybe(t.string), // street
  //       line2: Maybe(t.string), // ward
  //       line3: Maybe(t.string), // district
  //       line4: Maybe(t.string) // city
  //     })
  //   })
  // ),
  // attributesExt: t.type({
  //   MOBILE_CODE: t.string,
  //   FATCA_TAX_DECLARE: t.string,
  //   COMPANY_CITY: Maybe(t.string),
  //   COMPANY_DISTRICT: Maybe(t.string),
  //   COMPANY_WARD: Maybe(t.string),
  //   FOREIGN_CITY: Maybe(t.string),
  //   FOREIGN_COUNTRY: Maybe(t.string),
  //   FOREIGN_DISTRICT: Maybe(t.string),
  //   FOREIGN_STREET: Maybe(t.string),
  //   FOREIGN_WARD: Maybe(t.string),
  //   NATIONALITY_2: Maybe(t.string)
  // })
  // otpConsentStatus: Maybe(t.string),
  clientId: Maybe(t.string),
  email: Maybe(t.string),
  name: Maybe(t.string),
  mobilePhone: Maybe(t.string),
  externalIds: Maybe(
    t.type({
      SOE_VALUE: Maybe(t.string)
    })
  ),
  attributesExt: Maybe(
    t.type({
      MOBILE_CODE: Maybe(t.string),
      BASIC_PRODUCT_CODE: Maybe(t.string)
    })
  ),
  firstName: Maybe(t.string),
  surName: Maybe(t.string),
  dob: Maybe(t.string),
  sex: Maybe(t.string),
  nationality: Maybe(t.string)
})
export type ClientQuery = t.TypeOf<typeof ClientC>

export namespace PayoutPopup {
  export enum CashlessMethod {
    BANK = 'Bank transfer', // (Chuyển khoản qua ngân hàng)
    PAB = 'Paid at bank', //(Nhận tiền mặt bằng CMND tại ngân hàng)
    PAC = 'Receive cash at counter', //(Nhận tiền mặt tại quầy)
    MOMO = 'eWallet Momo' //(Ví điện tử Momo)
  }

  export enum PayoutMethods {
    PAYPREMIUM = 'PAYPREMIUM',
    PAYLOAN = 'PAYLOAN',
    OTHER = 'OTHER',
    CASHLESS = 'CASHLESS',
    BANKTRANSFER = 'BANKTRANSFER',
    PAID_AT_BANK = 'PAID_AT_BANK',
    REPAY_APL = 'REPAY_APL',
    REPAY_OPL = 'REPAY_OPL',
    REPAY_OPL_APL = 'REPAY_OPL_APL',
    MOMO = 'EWALLET_MOMO'
  }

  export interface PayoutMethodTranslator {
    typeEnum: string
    nameEn: string
    nameVi: string
  }

  export const translateMethod = (
    method: PayoutPopup.PayoutMethods,
    data: {
      typeEnum: string
      nameEn: string
      nameVi: string
    }[]
  ): string => {
    const target = data.find((x) => x.typeEnum == method)
    // const isVi = window && window.localStorage && window.localStorage.getItem('locale') === 'vi'
    const isVi = i18next.language === 'vi'
    return target ? (isVi ? target.nameVi : target.nameEn) : '-'
  }

  export interface PayoutData {
    method: PayoutMethods
    amount: number

    policyNum?: string
    poName?: string
    totalPremium?: number

    bankCode?: string
    bankName?: string
    bankAccountNum?: string
    bankAccountName?: string
    bankBranchCode?: string
    bankBranchName?: string
    nationalId?: string
  }

  export const SummaryCodec = t.type({
    method: t.union([
      t.literal(PayoutMethods.BANKTRANSFER),
      t.literal(PayoutMethods.CASHLESS),
      t.literal(PayoutMethods.MOMO),
      t.literal(PayoutMethods.OTHER),
      t.literal(PayoutMethods.PAID_AT_BANK),
      t.literal(PayoutMethods.PAYLOAN),
      t.literal(PayoutMethods.PAYPREMIUM),
      t.literal(PayoutMethods.REPAY_APL),
      t.literal(PayoutMethods.REPAY_OPL),
      t.literal(PayoutMethods.REPAY_OPL_APL)
    ]),
    methodView: t.string,
    detail: t.string,
    amount: t.number,

    policyNum: Maybe(t.string),
    poName: Maybe(t.string),
    totalPremium: Maybe(t.number),

    bankCode: Maybe(t.string),
    bankName: Maybe(t.string),
    bankAccountNum: Maybe(t.string),
    bankAccountName: Maybe(t.string),
    bankBranchCode: Maybe(t.string),
    bankBranchName: Maybe(t.string),
    nationalId: Maybe(t.string)
  })

  export type Summary = t.TypeOf<typeof SummaryCodec>

  export type PayoutSummaryArrayBrands = {
    readonly EmptyArray: unique symbol
    readonly TotalAmountLessThanZero: unique symbol
    readonly TotalAmountLessThanMinValue: unique symbol
  }

  export type PayoutSummaryArray = t.Branded<Summary[], PayoutSummaryArrayBrands>

  /**PAY PREMIUM */
  export interface PayPremiumData {
    policyNum: string
    paidToDate: string
    installmentPremium: number
    poName: string
    totalPremium: number

    billingFrequency?: string
    relationship?: string
    isMaturityAdvValid?: boolean
  }

  export interface PayPremiumOtherData {
    policyNum: string
    poName: string
    paidToDate: string
    installmentPremium: number
    totalPremium: number
    billingFrequency: string
    isMaturityAdvValid?: boolean
    isPayPremiumValid?: boolean
  }

  /**REPAY LOAN */
  export interface ReloanPayData {
    policyNum: string
    opl: number
    apl: number
    poName: string
    totalPremium: number
  }

  export interface RepayLoanOtherData {
    policyNum: string
    poName: string
    opl: number
    apl: number
    totalPremium: number
    isRepayLoanvalid?: boolean
  }

  /**OTHER PAY */

  export interface OtherPayPremiumData {
    policyNum: string
    contractStatus: string
    poName: string
    totalPremium: number
    minTopUp?: number
    maxTopUp?: number
  }

  export interface OtherPayPremiumSearchData {
    policyNum: string
    poName: string
    contractStatus: string
    totalPremium: number
    isMaturityAdvValid?: boolean
    isOtherPayPremiumValid?: boolean
    minTopUp?: number
    maxTopUp?: number
  }

  export interface TopUpPolicy {
    policyNum: string
    minTopUp: number
    maxTopUp: number
    ogMaxTopup: number
    totalPremium: number
  }
}
export namespace CashOut {
  export interface PaymentOption {
    currency: string
    amount: number
  }

  export interface PolicyOption {
    policyNo: string
    totalPremium: number
    status: string
  }

  export interface BankAccount {
    accountName: string
    accountNo: string
    bankName: string
    branchCode: string
  }

  export interface Option {
    type: PayoutPopup.PayoutMethods
    paymentOption: PaymentOption
    policy?: PolicyOption
    bankAccount?: BankAccount
  }
}
export namespace SpecialBonusData {
  export interface SBAccountGroups {
    sacsCode: string
    sacsType: string
  }

  export interface DetailData {
    specialBonus: number
  }

  export interface SubmitData {
    policy: {
      policyNo: string
    }
    attributesExt: {
      TOTAL_PAYOUT_AMOUNT: number
      SPECIAL_BONUS: number
    }
    cashOutOptions: Array<CashOut.Option>
  }
}

export const lAClient = t.type({
  clientNumberOfLA: t.string,
  life: t.string
})

export type lAClient = t.TypeOf<typeof lAClient>

export const benClient = t.type({
  clientNumberOfBen: t.string,
  percent: t.number,
  relationShipCode: t.string
})

export type benClient = t.TypeOf<typeof benClient>

export const IssuedPlaceInfo = t.type({
  code: Maybe(t.string),
  name: Maybe(t.string),
  bcCode: Maybe(t.string),
  issueBy: Maybe(t.string)
})

export type IssuedPlaceInfo = t.TypeOf<typeof IssuedPlaceInfo>
export namespace Services {
  export const login = (data: { idNumber: string; dob: string; phoneNumber: string; captchaValue: string }) =>
    LDApi.login(`authentication/api/v1/customer/ocr-change-national-id/get-token`, data)

  export const getPolicyList = (data: { idNumber: string; dob: string; phoneNumber: string }) =>
    pipe(
      LDApi.get(
        `customer/get-list-policy-by-owner-info-no-constraint?idNumber=${data.idNumber}&dob=${data.dob}&phoneNumber=${data.phoneNumber}`
      )(
        t.type({
          body: t.array(t.string)
        })
      ),
      ZIO.map((_) => _.body)
    )

  const branchRes = t.type({
    body: t.array(
      t.type({
        code: t.string,
        name: t.string
      })
    )
  })
  const bankRes = t.type({
    body: t.array(
      t.type({
        code: t.string,
        name: t.string
      })
    )
  })
  export const PolicyTypeC = t.type({
    anniDate: Maybe(t.string),
    salaryIncome: Maybe(t.number),
    basicCode: Maybe(t.string), // Coverage Code
    lastIssueDate: Maybe(t.string),
    paidToDateAdvance: Maybe(t.string),
    paidToDateBasic: Maybe(t.string),
    paidToDateRider: Maybe(t.string),
    billToDate: Maybe(t.string),
    billFreq: Maybe(t.string),
    mainLifeAssuredNumber: Maybe(t.string), // Client Id
    productCode: Maybe(t.string), // Coverage Type
    productType: Maybe(t.string),
    clientDespatchAddress: Maybe(t.string),
    policyNo: Maybe(t.string),
    totalPremium: Maybe(t.number), // Installment Premium
    contractDate: Maybe(t.string), // Risk Command Date
    dispatchMethod: Maybe(t.string),
    firstIssueDate: Maybe(t.string),
    status: Maybe(t.string), // Contract Status
    premiumStatus: Maybe(t.string),
    riskCommenceDate: Maybe(t.string),
    proposal: Maybe(
      t.type({
        proposalNo: Maybe(t.string),
        proposalReceivedDate: Maybe(t.string)
      })
    ),
    owners: t.type({
      id: Nullable(t.string) // Owner Id
    }),
    servingAgents: Maybe(
      t.type({
        agent: Maybe(
          t.type({
            code: Maybe(t.string)
          })
        )
      })
    ),
    attributesExt: Maybe(
      t.type({
        basicPremium: Maybe(t.number),
        riderPremium: Maybe(t.number)
      })
    )
  })
  export const getBank = () =>
    pipe(
      LDApi.post(`wf-api/bank/search`)(bankRes)({
        body: {}
      }),
      ZIO.map((banks) => banks.body.filter((bank) => bank.code !== 'NULL' && bank.code !== 'VRB'))
    )

  export const getSalaries = () =>
    pipe(
      LDApi.get(`wf-api/general/data/get-salaries`)(
        t.type({
          body: t.array(
            t.type({
              id: Maybe(t.string),
              code: t.string,
              name: t.string,
              nameEn: t.string
            })
          )
        })
      )
    )

  export const getBankBranch = (bankCode: string) =>
    pipe(
      LDApi.get(`wf-api/bank/${bankCode}/branch/search`)(branchRes),
      ZIO.map((branches) => branches.body)
    )

  export const getPolicy = (policyNumber: string) =>
    pipe(
      LDApi.get(`wf-api/policy/${policyNumber}`)(
        t.type({
          body: PolicyTypeC
        })
      ),
      ZIO.map((policy) => policy)
    )

  //export const getCustomer = (clientId: string) => pipe(LDApi.get(`wf-api/customer/${clientId}`)(Customer))

  export const getClients = (clientIds: Array<{ clientId: string }>) =>
    pipe(
      LDApi.post(`wf-api/customer/customers`)(
        t.type({
          body: t.array(ClientC)
        })
      )({
        body: {
          clients: clientIds
        }
      }),
      ZIO.map((clients) => clients)
    )

  export const getOwnerInfo = (policyNumber: string) => {
    return pipe(
      LDApi.get(`wf-api/policy/${policyNumber}/owner`)(
        t.type({
          body: ClientC
        })
      ),
      ZIO.map((client) => {
        return client.body
        // return {
        //   ownerName: client.body.name ?? '',
        //   ownerId: client.body.clientId ?? '',
        //   phoneNumber: client.body.mobilePhone ?? '',
        //   nationalId: client.body.externalIds?.SOE_VALUE ?? '',
        //   email: client.body.email ?? '',
        //   basicProductCode: client.body.attributesExt?.BASIC_PRODUCT_CODE ?? ''
        // }
      })
    )
  }

  export const getSpecicalBonusAmount = (data: {
    policyNo: string
    idNumber: string
    dob: string
    phoneNumber: string
  }) =>
    pipe(
      LDApi.get(`customer/get-policy-special-bonus`, { params: data })(
        t.type({
          body: t.type({
            policyNo: t.string,
            specialBonusAmount: t.number
          })
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getRiderTable = pipe(
    LDApi.get(`wf-api/rider/get-list`)(
      t.type({
        body: t.array(
          t.type({
            id: t.string,
            code: t.string,
            name: t.string,
            nameVi: t.string
          })
        )
      })
    ),
    ZIO.map((riderInfoList) => {
      return riderInfoList.body
    })
  )

  export const otpRequest = (
    transactionName: string,
    collerationId: string,
    phoneNumber: string,
    policyNum: string,
    isCCE = false
  ) =>
    pipe(
      LDApi.post(`wf-api/transaction/otp/request`)(t.unknown)({
        isCCE: isCCE,
        body: {
          attributes: {
            PAYLOAD: '',
            TRANSACTION_NAME: transactionName,
            TRANSACTION_CODE: collerationId,
            POLICY_NUM: policyNum
          },
          channels: [
            {
              name: 'SMS',
              value: phoneNumber
            }
          ]
        }
      })
    )

  export const getClientsByPolicy = (policyNum: string) =>
    pipe(
      LDApi.get(`wf-api/bo/${policyNum}/clients`)(
        t.type({
          body: t.type({
            laList: t.array(lAClient),
            benList: t.array(benClient)
          })
        })
      ),
      ZIO.map((res) => res.body)
    )
  export const getIssuedPlaceList = () =>
    pipe(
      LDApi.get(`wf-api/general/data/get-place-of-issue`)(
        t.type({
          body: Maybe(t.array(IssuedPlaceInfo))
        })
      ),
      ZIO.map((responseData) => {
        return responseData.body
      })
    )

  export const compareIdCard = (clientId: string, body: any) =>
    pipe(
      LDApi.post(`wf-api/customer/national-id-card/compare`)(
        t.type({
          body: t.type({
            status: t.string,
            oErrorResult: t.type({
              errorCode: t.string,
              errorDescription: t.string
            })
          })
        })
      )({
        clientId,
        body
      })
    )

  export const identifycard = (file: File) => {
    const formData: FormData = new FormData()
    formData.append('image_base64', file)
    return pipe(
      AppConfig.get,
      ZIO.flatMap((config) =>
        LDApi.post(`ocr-rest/ocr/scan/identifycard`, {
          headers: {
            'Ocp-Apim-Subscription-Key':
              config.env === ENV.PRD ? 'd951c9f878cd4b99b28f2263e8df5ab1' : '1c6d8d2a9a7f474fa1e728a500b9c4f7'
          }
        })(
          t.type({
            data: t.array(
              t.type({
                id: Maybe(t.string),
                id_prob: Maybe(t.string),
                name: Maybe(t.string),
                name_prob: Maybe(t.string),
                dob: Maybe(t.string),
                dob_prob: Maybe(t.string),
                sex: Maybe(t.string),
                sex_prob: Maybe(t.string),
                nationality: Maybe(t.string),
                nationality_prob: Maybe(t.string),
                home: Maybe(t.string),
                home_prob: Maybe(t.string),
                address: Maybe(t.string),
                address_prob: Maybe(t.string),
                doe: Maybe(t.string),
                doe_prob: Maybe(t.string),
                overall_score: Maybe(t.string),
                type_new: Maybe(t.string),
                type: Maybe(t.string),
                QRcode: Maybe(t.string)
              })
            ),
            errorCode: t.string
          })
        )(formData)
      ),
      ZIO.map((body) => body.data)
    )
  }

  export const submissionChangeNationalIdCard = (
    policyNumber: string,
    body: any,
    clientId: string,
    clientName: string,
    attributesExt: any,
    otpTransaction: any,
    documents: StorageBlob.FileContentSubmit[],
    createdBy: string
  ) =>
    pipe(
      LDApi.post(`wf-api/customer/national-id-card/${clientId}`)(
        t.type({
          body: t.type({
            responseStatus: Maybe(
              t.type({
                errors: Maybe(
                  t.array(
                    t.type({
                      code: t.string,
                      message: t.string
                    })
                  )
                )
              })
            )
          })
        })
      )({
        policyNumber,
        clientId,
        clientName,
        idNumber: attributesExt.idNumber || '',
        primaryPolicyNo: policyNumber,
        receiveFrom: 'PULSEOPS',
        source: 'LANDING_PAGE',
        body,
        officeCode: '-',
        authenRequestForm: {},
        documents: documents,
        attributesExt,
        externalIds: {
          NATIONAL_ID: attributesExt.idNumber || ''
        },
        otpTransaction,
        createdDate: new Date().toISOString(),
        createdBy,
        authenOption: 'OTP',
        isCCE: false
      }),
      ZIO.map((data) => data.body)
    )
}
