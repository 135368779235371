import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { OBSharedStyles } from '../ob-shared'
import { OBButtonProps } from './OBButton'
import { assets } from '@pulseops/common'

type OBIconButtonProps = OBButtonProps & {
  btnIcon?: JSX.Element
}

export const OBIconButton = (props: OBIconButtonProps) => {
  const { disabled, text, onHandleClickEvent, isHightLight, buttonContainerStyle, btnIcon } = props
  return (
    <TouchableOpacity disabled={disabled} onPress={() => onHandleClickEvent && onHandleClickEvent()}>
      <View
        style={[
          OBSharedStyles.actionContent,
          buttonStyles.actionLine,
          isHightLight && OBSharedStyles.actionHightLight,
          buttonContainerStyle,
          disabled && OBSharedStyles.actionNonBackgroundDisable
        ]}
      >
        <Text style={[OBSharedStyles.actionContentText, buttonStyles.textMargin, isHightLight && OBSharedStyles.actionHightLightText, disabled && OBSharedStyles.actionNonBackgroundDisableText]}>{btnIcon && btnIcon}</Text>
        <Text style={[OBSharedStyles.actionContentText, isHightLight && OBSharedStyles.actionHightLightText, disabled && OBSharedStyles.actionNonBackgroundDisableText]}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  )
}
const buttonStyles = StyleSheet.create({
  actionLine: {
    marginLeft: 8,
    display: 'flex',
    flexDirection: 'row'
  },
  textMargin: {
    marginRight: 8
  }
})