import { Task } from '@mxt/zio'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { StorageBlob } from '../storage-blob'
import { ExcellentStudentAwardServiceModel, RequestAuthFormDataSubmit } from './model'
import { PruCashPaymentService } from './PrucashPaymentService'
import { SubmissionService } from './SubmissionService'

export namespace ExcellentStudentAwardService {
  export const getDetail = (policyNo: string): Task<ExcellentStudentAwardServiceModel.Detail> =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/bo/excellent-student-award`)(
          t.type({
            body: t.type({
              exchangeId: t.string,
              awardAmount: t.number,
              sumAssured: t.number
            })
          })
        )({
          body: {
            policyNo: `${policyNo}E`
          }
        }),
        PruCashPaymentService.getBenefit(policyNo),
        SubmissionService.getLoanEnquiry(policyNo)
      ),
      ZIO.map(([award, prucashBen, loanEnquiry]) => {
        const A = award.body.awardAmount
        const D = prucashBen.body.attributesExt?.LOAN_EXCEED_80_SURRENDER_VALUE || 0
        return {
          benefit: A,
          principalAmount: loanEnquiry.subfileOccurs.reduce((sum, x) => sum + x.currentBalance, 0),
          investmentAmount: loanEnquiry.subfileOccurs.reduce(
            (sum, x) => sum + x.accuredInterest + x.pendingInterest,
            0
          ),
          overMaturity: D,
          reqPayinAmount: A - D
        }
      })
    )

  export const getAccess = (policyNum: string): Task<boolean> =>
    pipe(
      POApi.post(`wf-api/other/client-policy-verify`)(
        t.type({
          body: t.type({
            policyRecOuts: t.array(
              t.type({
                errorCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        body: {
          function: 'CHECK_EXCELLENT',
          clientRecs: [{ clientNumber: '', securityNo: '' }],
          policyRecs: [{ policyNum }]
        }
      }),
      ZIO.map((verify) => !(verify.body.policyRecOuts.findIndex((x) => !!x.errorCode) > -1))
    )

  export const submit =
    (policyNum: string, data: ExcellentStudentAwardServiceModel.SubmitData, images: StorageBlob.FileContentSubmit[]) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/excellent-student-award`,
        { body: data },
        policyNum,
        requestauth,
        images
      )
  // pipe(
  //     PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/excellent-student-award`)(t.unknown)({
  //         body: data,
  //         otpTransaction: requestauth,
  //         source: SourceType.PULSE4OPS
  //     }),
  //     ZIO.asVoid
  // )
}
