import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

export const PremiumRedirectionPayLoad = t.type({
  policyNo: t.string,
  poClientNum: t.string,
  correlationId: t.string,
  transactionType: t.string,
  fundList: t.array(
    t.type({
      currentPercentage: t.string,
      fundCode: t.string,
      redirectPercentage: t.string
    })
  ),
  office: t.string,
  status: t.string
})

export const PremiumRedirection = t.type({
  transactionType: t.literal(TransactionType.PREMIUM_REDIRECTION),
  payload: t.type({
    body: PremiumRedirectionPayLoad
  }),
  source: Maybe(t.string)
})
export type PremiumRedirection = t.TypeOf<typeof PremiumRedirection>
