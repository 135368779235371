import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { LDApi } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import i18next from 'i18next'
import moment from 'moment'

const Body = t.type({
  phoneNumber: t.string,
  bankNumber: t.string,
  bankName: t.string
})

const OTPRequestC = t.type({
  body: Body
})

type OTPRequest = t.TypeOf<typeof OTPRequestC>

const SubmitBankInfoC = t.type({
  body: Body
})

type SubmitBankInfo = t.TypeOf<typeof SubmitBankInfoC>

export interface VerifyOTP {
  body: BodyOTP
  otpTransaction: OtpTransaction
}

export interface BodyOTP {
  phoneNumber: string
  policyNumber: string
  bankAccount: string
  bankName: string
}

export interface OtpTransaction {
  receiverPhone: string
  otp: string
}

export namespace Services {
  export const login = (phoneNumber: string, policyNum: string) =>
    LDApi.login(`authentication/api/v1/add-bank-account/get-token`, {
      phoneNumber: phoneNumber,
      policyNumber: policyNum
    })

  export const otpRequest = (data: {
    body: {
      phoneNumber: string
      bankName: string
      bankNumber: string
      policyNumber: string
      policies: Array<string>
    },
    captchaValue: string
  }) =>
    pipe(
      LDApi.post(`landing-page/api/v1/otp/request`)(
        t.type({
          body: t.type({
            phoneNumber: t.string,
            policyNumber: Maybe(t.string),
            bankName: t.string,
            policies: Maybe(t.array(t.string))
          }),
          responseStatus: t.type({
            code: Maybe(t.number),
            message: Maybe(t.string)
          })
        })
      )(data)
    )
  export const verifyOTP = (data: VerifyOTP) =>
    pipe(
      LDApi.post(`authentication/api/v1/add-bank-account/verify-otp`)(
        t.type({
          responseStatus: t.type({
            code: Maybe(t.number),
            message: Maybe(t.string)
          })
        })
      )({
        ...data,
        body: {
          ...data.body,
          status: 'COMPLETED'
        }
      }),
      ZIO.map((res) => {
        return res
      })
    )
  export const validate = (data: {
    body: {
      phoneNumber: string
      bankNumber: string
      bankName: string
      policies: Array<any>
    }
  }) =>
    pipe(
      LDApi.post(`landing-page/api/v1/validate/`)(t.unknown)({
        ...data,
        body: {
          ...data.body,
          status: 'COMPLETED'
        }
      })
    )

  export const savePayload = (data: {
    body: {
      phoneNumber: string
      bankNumber: string
      bankName: string
      policies: Array<string>
    }
  }) =>
    pipe(
      LDApi.post(`landing-page/api/v1/drawaccount/`)(
        t.type({
          body: t.type({
            phoneNumber: t.string,
            policies: t.array(t.string),
          })
          // responseStatus: t.type({
          //   code: Maybe(t.number),
          //   message: Maybe(t.string)
          // })
        })
      )({
        ...data,
        body: {
          ...data.body,
          status: 'COMPLETED'
        }
      }),
      ZIO.map((res) => res)
    )

  export const searchPolicies = (phone: string) =>
    pipe(
      LDApi.get(`landing-page/api/v1/drawaccount?phoneNumber=${phone}`)(
        t.type({
          body: Maybe(
            t.array(
              t.type({
                // phoneNumber: t.string,
                // policyNumber: t.string,
                // createdDate: t.string,
                // createdBy: t.string,
                // updatedBy: t.string,
                // updatedDate: t.string,
                // status: t.string
              })
            )
          ),
          responseStatus: t.type({
            code: Maybe(t.number),
            message: Maybe(t.string)
          })
        })
      )
    )

  export const searchBank = () =>
    pipe(LDApi.post(`wf-api/bank/search`)(t.type({
      body: t.array(t.type({
        name: t.string,
        code: t.string
      }))
    }))({ body: { status: '2' } }))
}
