import { ControlProps } from '@pulseops/common'
import * as React from 'react'
import { View, Text, TextInput, StyleSheet, TextStyle } from 'react-native'

type Props = ControlProps<string> & {
  placeholder?: string
  title?: string
  labelStyle?: TextStyle
  disabled?: boolean
  required?: boolean
  errorMessage?: string
  maxLength?: number
  numberOfLines?: number
  multiline?: boolean
  textAlign?: 'left' | 'right' | 'center' | 'auto' | 'justify'
  suffixIcon?: () => JSX.Element
  prefixIcon?: () => JSX.Element
  onSubmit?: () => void
}

export const Input = (props: Props) => {
  const {
    title,
    value,
    onChange,
    onBlur,
    required,
    errorMessage,
    disabled,
    labelStyle,
    maxLength,
    textAlign,
    numberOfLines,
    multiline,
    suffixIcon,
    prefixIcon
  } = props
  return (
    <View style={{ width: '100%' }}>
      {title && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, lineHeight: 20 }, labelStyle]}>
            {props.title}
          </Text>
          {required && <Text style={{ color: 'red', marginLeft: 5 }}>*</Text>}
        </View>
      )}
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {prefixIcon && prefixIcon()}
        <TextInput
          style={
            disabled
              ? [styles.common, { textAlign: textAlign }]
              : [styles.common, multiline ? styles.outLine : styles.dashline]
          }
          // textAlign = {textAlign}
          value={value}
          onChangeText={onChange}
          onBlur={onBlur}
          editable={!disabled}
          maxLength={maxLength}
          numberOfLines={numberOfLines}
          multiline={multiline}
          onSubmitEditing={() => props.onSubmit?.call(null)}
        />
        {suffixIcon && suffixIcon()}
      </View>
      {errorMessage ? (
        <View style={{ position: 'absolute', bottom: -15 }}>
          <Text style={{ color: 'red', fontSize: 12 }}>{errorMessage}</Text>
        </View>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  common: {
    minHeight: 31,
    fontSize: 15,
    lineHeight: 20,
    width: '100%'
  },
  outLine: {
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8
  },
  dashline: {
    borderBottomColor: 'darkgrey',
    borderBottomWidth: 1
  }
})
