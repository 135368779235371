/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Maybe } from '@mxt/zio/codec'
import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'

export namespace NotificationForm {
  const PLCNumber = pipe(
    form2.string.optional,
    form2.string.minLength(8, () => i18next.t('message:MS090001'))
  )

  const MultipleSelectOption = t.array(
    t.type({
      label: t.string,
      value: t.string
    })
  )

  const Base = t.type({
    policyNumber: form2.optional(PLCNumber),
    fromDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('Notification:FromDate') })),
    toDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('Notification:ToDate') })),
    transactionName: Maybe(MultipleSelectOption)
  })

  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(
    Base,
    form2.refine(
      (data): data is Form => !!data.policyNumber || !!data.transactionName,
      () => i18next.t('message:MS020036'),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}
