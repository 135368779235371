import { RadioButton } from '@pulseops/common'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

export interface TableData {
  inputType?: 'text' | 'radio' // text by default
  value?: number | string
}

export interface RowProps {
  data: TableData[]
  currentSelected?: string
  onSelectedChanged?: (data: TableData) => void
}

export interface TableProps {
  headers: string[]
  rows: TableData[][]
  currentSelected?: string
  onSelectedChanged?: (data: TableData) => void
}

export interface TableHeaderProps {
  fields: string[]
}

const TableHeader: React.FC<TableHeaderProps> = ({ fields }) => {
  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        minHeight: 74,
        backgroundColor: '#E5EAEF',
        paddingLeft: 15
      }}
    >
      {fields.map((d, i) => (
        <View
          key={i}
          style={{
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'center'
          }}
        >
          <Text style={{ fontWeight: 'bold', fontSize: 15, textTransform: 'capitalize' }}>{d}</Text>
        </View>
      ))}
    </View>
  )
}

const Row: React.FC<RowProps> = ({ data, currentSelected, onSelectedChanged }) => {
  return (
    <View style={styles.row}>
      {data.map((d, i) => (
        <View key={`cell_${i}`} style={{ flex: 1 }}>
          {(!d.inputType || d.inputType === 'text') && <Text style={styles.textStyle}>{d.value}</Text>}
          {d.inputType === 'radio' && (
            <RadioButton
              onChange={() => {
                console.log(currentSelected, d.value)
                onSelectedChanged?.call(this, d)
              }}
              selected={!!currentSelected && currentSelected === d.value}
            />
          )}
        </View>
      ))}
    </View>
  )
}

const Table: React.FC<TableProps> = ({ rows, headers, currentSelected, onSelectedChanged }) => {
  return (
    <View style={styles.container}>
      <TableHeader fields={headers} />
      {rows.map((row, index) => {
        return (
          <React.Fragment key={`row_${index}`}>
            <Row data={row} onSelectedChanged={onSelectedChanged} currentSelected={currentSelected} />
          </React.Fragment>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    overflow: 'hidden'
  },
  headerContainer: {
    borderWidth: 2,
    borderBottomColor: '#EEEEEE',
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    minHeight: 50,
    paddingLeft: 15,
    paddingVertical: 15
  },
  row: {
    borderBottomWidth: 2,
    borderBottomColor: '#EEEEEE',
    alignSelf: 'stretch',
    flexDirection: 'row',
    minHeight: 50,
    paddingLeft: 15,
    paddingVertical: 15
  },
  textStyle: {
    fontSize: 16
  }
})

export default Table
