import React from 'react'
import { View } from 'react-native'

interface DividerProps {
  height?: number
  width?: number
  isExpand?: boolean
}

export const Divider: React.FC<DividerProps> = ({ height = 16, width = 16, isExpand = false }) => {
  return <View style={{ flex: isExpand ? 1 : undefined, height, width }} />
}
