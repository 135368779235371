import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.ASSIGNMENT)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        clientId: t.string,
        surName: Maybe(t.string),
        firstName: Maybe(t.string),
        dob: Maybe(t.string),
        sex: Maybe(t.string),
        nationality: t.string,
        occupation: Maybe(t.string),
        dateOfDeath: Maybe(t.string),
        smsIndicator: Maybe(t.string),
        emailIndicator: Maybe(t.string),
        foreignCountry: Maybe(t.string),
        contactDetails: t.type({
          PHONE: t.type({
            countryCode: t.string,
            value: Maybe(t.string)
          }),
          EMAIL: t.type({
            value: Maybe(t.string)
          })
        }),
        addressDetails: t.type({
          ADDRESS: t.type({
            line1: Maybe(t.string),
            countryCode: Maybe(t.string),
            city: Maybe(t.string),
            district: Maybe(t.string),
            subDistrict: Maybe(t.string)
          }),
          FOREIGN_ADDRESS: t.type({
            line1: Maybe(t.string)
          })
        }),
        attributesExt: t.type({
          POLICY_NO: Maybe(t.string),
          CLIENT_NO: Maybe(t.string),
          ID_NUMBER: Maybe(t.string),
          RELATIONSHIP_1: t.string,
          IS_FATCA: Maybe(t.string),
          NATIONALITY_2: Maybe(t.string),
          CLIENT_NO_2: Maybe(t.string),
          CLIENT_NAME_2: Maybe(t.string),
          RELATIONSHIP_2: Maybe(t.string),
          CLIENT_NO_3: Maybe(t.string),
          CLIENT_NAME_3: Maybe(t.string),
          RELATIONSHIP_3: Maybe(t.string),
          CLIENT_NO_4: Maybe(t.string),
          CLIENT_NAME_4: Maybe(t.string),
          RELATIONSHIP_4: Maybe(t.string),
          DUPLICATE_PHONES: t.array(
            t.type({
              CLIENT_NUM: t.string,
              CLIENT_NAME: t.string,
              RELATIONSHIP: Maybe(t.string)
            })
          ),
          DUPLICATE_EMAILS: t.array(
            t.type({
              CLIENT_NUM: t.string,
              CLIENT_NAME: t.string,
              RELATIONSHIP: Maybe(t.string)
            })
          ),

          POLICY_HAS_BEN: t.string,
          LOCATION_CODE: Maybe(t.string),
          newPolicyOwner: t.string,
          nationality1Name: t.string,
          nationality2Name: t.string,
          occupationName: t.string,
          dobFormated: t.string,
          countryName: t.string,
          taxResidencyCountry: Maybe(t.string)
        })
      })
    })
  })
)

export const Assignment = Pulse4Ops
export type Assignment = t.TypeOf<typeof Assignment>
