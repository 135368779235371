import { Maybe } from '@mxt/zio/codec'
import { LasStatus } from '../LasStatus'
import { SourceType } from '../SourceType'
import * as t from 'io-ts'
import { PoliciesReceived } from '../PoliciesReceived'
import { TransactionType } from '../TransactionType'
import { PaymentData } from './PaymentData'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.MATURITY_ADVANCE)
    }),
    codec
  ])

const payload = t.type({
  body: Maybe(
    t.type({
      cashOutOptions: Maybe(
        t.array(
          t.union([
            PaymentData.BankTransfer,
            PaymentData.EWalletMomo,
            PaymentData.PayPremium,
            PaymentData.Premium,
            PaymentData.PayLoan,
            PaymentData.TopUp,
            PaymentData.Reinstatement,
            PaymentData.NoType,
            PaymentData.RepayOpl,
            PaymentData.RepayApl,
            PaymentData.Other,
            PaymentData.Reins
          ])
        )
      ),
      totalPayoutAmount: Maybe(t.string),
      policiesReceived: Maybe(t.array(PoliciesReceived))
    })
  ),
  lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined])
})

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: Maybe(
        t.type({
          cashOutOptions: Maybe(
            t.array(
              t.union([
                PaymentData.BankTransfer,
                PaymentData.EWalletMomo,
                PaymentData.PayPremium,
                PaymentData.Premium,
                PaymentData.PayLoan,
                PaymentData.TopUp,
                PaymentData.Reinstatement,
                PaymentData.NoType,
                PaymentData.RepayOpl,
                PaymentData.RepayApl,
                PaymentData.Other,
                PaymentData.Reins
              ])
            )
          ),
          attributesExt: t.type({
            TOTAL_MATURITY_AMOUNT: t.number
          })
        })
      ),
      lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined])
    })
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload
  })
)

export const MaturityAdvance = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, PruGreat, PruOnline])
export type MaturityAdvance = t.TypeOf<typeof MaturityAdvance>
