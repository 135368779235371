import { Paper } from '@material-ui/core'
import {
  assets,
  Button,
  DatePicker,
  Form,
  Input,
  SectionHeader,
  Select,
  Table,
  TableStatus,
  ClaimService,
  UtilRow
} from '@pulseops/business/core'
import { AppContext, ErrorHandling, form2, Format, jsFileDownload, BuildConfig } from '@pulseops/common'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { BusinessStackParamList } from '@pulseops/business'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { ClaimForm } from './claim-form'
import { SC } from './sc'
import { useIsFocused } from '@react-navigation/native'
import { Text } from 'react-native'
import * as ExcelJS from 'exceljs'
import _ from 'lodash'

const excelColumns: { name: string; value: string }[] = [
  {
    name: 'Số HS của PVA',
    value: 'pvaClaimId'
  },
  {
    name: 'Số HS của INS',
    value: 'insClaimId'
  },
  {
    name: 'Số hợp đồng',
    value: 'polNum'
  },
  {
    name: 'Mã định danh của NĐBH',
    value: 'cliNum'
  },
  {
    name: 'Code CSYT',
    value: 'hospCd'
  },
  {
    name: 'Loại hồ sơ',
    value: 'caseType'
  },
  {
    name: ' Loại quyền lợi',
    value: 'claimType'
  },
  {
    name: 'Case chi trả nhanh',
    value: 'instantCase'
  },
  {
    name: 'Số thẻ bảo hiểm y tế',
    value: 'healthCardNum'
  },
  {
    name: 'Ngày xảy ra tai nạn',
    value: 'accidentDt'
  },
  {
    name: 'Ngày xảy ra sự kiện bảo hiểm',
    value: 'eventDt'
  },
  {
    name: 'Ngày ra viện',
    value: 'dischargeDt'
  },
  {
    name: 'Ngày PVA nhận hồ sơ',
    value: 'pvaReceivedDt'
  },
  {
    name: 'Ngày INS nhận hồ sơ',
    value: 'insReceivedDt'
  },
  {
    name: 'Ngày INS gửi yêu cầu bổ sung chứng từ cuối cùng',
    value: 'insLastRequestDt'
  },
  {
    name: 'Ngày INS nhận đầy đủ chứng từ',
    value: 'insLastDocRcvDt'
  },
  {
    name: 'Ngày INS ra quyết định',
    value: 'insSubDt'
  },
  {
    name: 'Ngày thanh toán',
    value: 'insPayDt'
  },
  {
    name: 'Số tiền yêu cầu/phát sinh',
    value: 'claimProvAmnt'
  },
  {
    name: 'Số tiền chi trả',
    value: 'claimApprAmnt'
  },
  {
    name: 'Số tiền thực tế chi trả',
    value: 'claimActuAmnt'
  },
  {
    name: 'Số tiền cấn trừ ',
    value: 'claimDeduAmnt'
  },
  {
    name: 'Lý do cấn trừ',
    value: 'deductReason'
  },
  {
    name: 'Trạng thái hồ sơ',
    value: 'claimStatCd'
  },
  {
    name: 'Kênh nộp hồ sơ',
    value: 'regFrom'
  },
  {
    name: 'Đánh giá chi tiết',
    value: 'assessment'
  },
  {
    name: 'Nội dung yêu cầu bổ sung',
    value: 'description'
  },
  {
    name: 'Nội dung điều tra',
    value: 'investigate'
  },
  {
    name: 'Mã chẩn đoán',
    value: 'diagnosis.diag.diagCd'
  },
  {
    name: 'Chẩn đoán',
    value: 'diagnosis.diag.diagName'
  },
  {
    name: 'Mã định danh của chủ hợp đồng',
    value: 'payees.payee.cliNum'
  },
  {
    name: 'Tên chủ hợp đồng',
    value: 'payees.payee.cliName'
  },
  {
    name: 'Giấy tờ tùy thân của chủ hợp đồng',
    value: 'payees.payee.idNum'
  },
  {
    name: 'Giới tính',
    value: 'payees.payee.gender'
  },
  {
    name: 'Ngày sinh người nhận tiền',
    value: 'payees.payee.birthDt'
  },
  {
    name: ' Phương thức thanh toán',
    value: 'payees.payee.payType'
  },
  {
    name: 'Mã ngân hàng',
    value: 'payees.payee.bankCd'
  },
  {
    name: 'Tên ngân hàng',
    value: 'payees.payee.bankName'
  },
  {
    name: 'Số Tài khoản người nhận tiền',
    value: 'payees.payee.bankAccNum'
  },
  {
    name: ' Tên người nhận tiền',
    value: 'payees.payee.bankAccName'
  },
  {
    name: ' CMND / CCCD người nhận tiền',
    value: ''
  },
  {
    name: 'Nơi cấp CMND',
    value: ''
  },
  {
    name: 'Tên các loại chi phí',
    value: 'expenses.expense.expenseName'
  },
  {
    name: 'Chi phí',
    value: 'expenses.expense.billAmnt'
  },
  {
    name: 'Chi phí chấp thuận chi trả',
    value: 'expenses.expense.apprAmnt'
  },
  {
    name: 'Tên các loại chứng từ',
    value: 'documents'
  }
]

type Props = StackScreenProps<BusinessStackParamList, 'ClaimListScreen'>

export const ClaimListScreen = ({ navigation }: Props) => {
  const { t } = useTranslation('business')
  const { t: menu } = useTranslation('menu')
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const [info, setInfo] = React.useState<ClaimForm.SearchingResultInfo[]>([])
  const [loading, setLoading] = React.useState(false)
  const [loadingExport, setLoadingExport] = React.useState(false)
  const [latestInput, setLatestInput] = React.useState<ClaimService.ClaimInput | null>(null)

  const {
    base: {
      control,
      getValues,
      formState: { errors }
    },
    handleSubmit
  } = form2.useForm(ClaimForm.codec, {
    defaultValues: {
      pvaNo: '',
      insNo: '',
      fromDate: null,
      toDate: null,
      applicationStatus: '',
      policyNo: '',
      noInsured: '',
      nameInsured: ''
    }
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.replace('HomeScreen')
        },
        {
          title: menu('BackOffice'),
          navigate: () => navigation.navigate('BusinessScreen')
        },
        { title: t('ClaimList'), navigate: null }
      ])
    }
  }, [isFocused])

  const search = handleSubmit((validated) => {
    setLoading(true)
    const input: ClaimService.ClaimInput = {
      cliName: validated.nameInsured || undefined,
      cliNum: validated.noInsured || undefined,
      from: validated.fromDate ? Format.dateDigit(validated.fromDate) : undefined,
      to: validated.toDate ? Format.dateDigit(validated.toDate) : undefined,
      claimStatCd: validated.applicationStatus || undefined,
      insClaimId: validated.insNo || undefined,
      polNum: validated.policyNo || undefined,
      pvaClaimId: validated.pvaNo || undefined
    }

    return pipe(
      ClaimService.searchClaim(input),
      ZIO.flatMap((claimList) =>
        ZIO.effect(() => {
          setInfo(
            claimList.map((claim) => ({
              pvaNo: claim.pvaClaimId,
              insNo: claim.insClaimId,
              applicationDate: claim.pvaReceivedDt,
              payoutAmount: claim.claimApprAmnt,
              applicationStatus: claim.claimStatCd,
              ndbhId: claim.cliNum,
              ndbhName: claim.cliName || '',
              applicationType: claim.caseType,
              polNum: claim.polNum
            }))
          )
          setLatestInput(input)
        })
      ),
      ZIO.tapBoth(
        () => ZIO.succeed(setLoading(false)),
        () => ZIO.succeed(setLoading(false))
      ),
      ErrorHandling.run()
    )
  })

  const exportData = () => {
    if (!latestInput) {
      return
    }
    setLoadingExport(true)

    return pipe(
      ClaimService.exportClaim(latestInput),
      ZIO.flatMap((claimList) =>
        ZIO.fromPromise(async () => {
          const wb = new ExcelJS.Workbook()
          const ws = wb.addWorksheet('CLAIM DATA')

          excelColumns.forEach((column, index) => {
            const headerCell = ws.getCell(1, index + 1)
            headerCell.value = column.name
            headerCell.alignment = {
              wrapText: true,
              horizontal: 'left',
              vertical: 'middle'
            }
            headerCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFBFBFBF'
              },
              bgColor: {
                argb: 'FF000000'
              }
            }
            headerCell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            }
          })

          const getValue = (key: string, value: string): string => {
            if (!value) {
              return ''
            }
            switch (key) {
              case 'claimProvAmnt':
              case 'claimApprAmnt':
              case 'claimActuAmnt':
              case 'claimDeduAmnt':
                return Format.currencyForBusiness(value)
              case 'payees.payee.gender':
                return getGenderName(value)
              case 'payees.payee.payType':
                return getPayTypeName(value)
              case 'caseType':
                return ClaimService.caseTypeMap[value] || '-'
              case 'claimStatCd':
                return ClaimService.applicationStatusMap[value] || '-'
              default:
                return value
            }
          }

          claimList.forEach((claim, i) => {
            excelColumns.forEach((column, j) => {
              const cell = ws.getCell(2 + i, j + 1)
              cell.value = getValue(column.value, _.get(claim, column.value))
            })
          })

          const buffer = await wb.xlsx.writeBuffer({
            useStyles: true
          })

          jsFileDownload(buffer, `ClaimData-${new Date().getTime()}.xlsx`)
        })
      ),
      ZIO.tapBoth(
        () => ZIO.succeed(setLoadingExport(false)),
        () => ZIO.succeed(setLoadingExport(false))
      ),
      ErrorHandling.run()
    )
  }

  const getGenderName = (gender?: string | null): string => {
    switch (gender) {
      case 'M':
        return 'Nam'
      case 'F':
        return 'Nữ'
      default:
        return '-'
    }
  }

  const getPayTypeName = (payType?: string | null): string => {
    switch (payType) {
      case '1':
        return 'Tiền mặt'
      case '7':
        return 'Nhận tiền mặt tại ngân hàng'
      case '8':
        return 'Chuyển khoản'
      default:
        return '-'
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <SC.Container>
          <SC.BorderContainer>
            <Form.Group>
              <SectionHeader>{t('search')}</SectionHeader>
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name="pvaNo"
                    render={({ field }) => (
                      <Input
                        label={t('pva_no')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errors?.pvaNo?.message}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col>
                  <Controller
                    control={control}
                    name="insNo"
                    render={({ field }) => (
                      <Input
                        label={t('ins_no')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errors?.insNo?.message}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col>
                  <SC.FlexRow>
                    <Controller
                      control={control}
                      name="fromDate"
                      render={({ field }) => (
                        <DatePicker
                          label={t('pva_application_date')}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={errors?.fromDate?.message}
                          maxDate={getValues('toDate') || undefined}
                          placeholder="Từ ngày..."
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="toDate"
                      render={({ field }) => (
                        <DatePicker
                          label="&nbsp;"
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={errors?.toDate?.message}
                          minDate={getValues('fromDate') || undefined}
                          placeholder="Đến ngày..."
                        />
                      )}
                    />
                  </SC.FlexRow>
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name="applicationStatus"
                    render={({ field }) => (
                      <Select
                        options={ClaimService.applicationStatusOptions}
                        label={t('application_status')}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errors?.applicationStatus?.message}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col>
                  <Controller
                    control={control}
                    name="policyNo"
                    render={({ field }) => (
                      <Input
                        label={t('policy_no')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errors?.policyNo?.message}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col>
                  <Controller
                    control={control}
                    name="noInsured"
                    render={({ field }) => (
                      <Input
                        label={t('no_insured')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errors?.noInsured?.message}
                      />
                    )}
                  />
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name="nameInsured"
                    render={({ field }) => (
                      <Input
                        label={t('name_insured')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errors?.nameInsured?.message}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col></Form.Col>
                <Form.Col></Form.Col>
              </Form.Row>
              <UtilRow style={{ position: 'relative' }}>
                <UtilRow style={{ marginBottom: 0 }}>
                  <Button title={t('search')} icon={<assets.IconSearch />} onPress={search} loading={loading} />
                  <Button
                    title={t('export')}
                    type="outline"
                    icon={<assets.IconDownload />}
                    onPress={exportData}
                    loading={loadingExport}
                    disabled={!latestInput}
                  />
                </UtilRow>
                <UtilRow style={{ marginBottom: 0, marginTop: 8, position: 'absolute', top: '100%', right: 0 }}>
                  <Text style={{ color: '#ED2B1C' }}> {_.get(errors, '.message')}</Text>
                </UtilRow>
              </UtilRow>
            </Form.Group>
          </SC.BorderContainer>
          <SC.BorderContainer>
            <SectionHeader>{t('searching_result')}</SectionHeader>
            <Table.Container component={Paper}>
              <Table.Main>
                <Table.Head>
                  <Table.Row>
                    <Table.CellHead>{t('pva_no')}</Table.CellHead>
                    <Table.CellHead>{t('ins_no')}</Table.CellHead>
                    <Table.CellHead>{t('pva_application_date')}</Table.CellHead>
                    <Table.CellHead>{t('payout_amount')}</Table.CellHead>
                    <Table.CellHead>{t('application_status')}</Table.CellHead>
                    <Table.CellHead>{t('ndbh_id')}</Table.CellHead>
                    <Table.CellHead>{t('ndbh_name')}</Table.CellHead>
                    <Table.CellHead>{t('application_type')}</Table.CellHead>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  <TableStatus colSpan={8} rows={info} loading={loading} />
                  {info.map((row, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <SC.Link
                          to={`${BuildConfig.contextPath}/user/drawer/business/claim-detail?pvaClaimId=${row.pvaNo}&polNum=${row.polNum}&cliNum=${row.ndbhId}`}
                        >
                          {row.pvaNo || '-'}
                        </SC.Link>
                      </Table.Cell>
                      <Table.Cell>{row.insNo || '-'}</Table.Cell>
                      <Table.Cell>{row.applicationDate || '-'}</Table.Cell>
                      <Table.Cell>{Format.currencyForBusiness(row.payoutAmount)}</Table.Cell>
                      <Table.Cell>{ClaimService.applicationStatusMap[row.applicationStatus] || '-'}</Table.Cell>
                      <Table.Cell>{row.ndbhId || '-'}</Table.Cell>
                      <Table.Cell>{row.ndbhName || '-'}</Table.Cell>
                      <Table.Cell>{ClaimService.caseTypeMap[row.applicationType] || '-'}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Main>
            </Table.Container>
          </SC.BorderContainer>
        </SC.Container>
      </ScrollView>
    </SafeAreaView>
  )
}
