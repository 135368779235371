import { ZIO } from '@mxt/zio'
import { ErrorHandling, form2, AppContext, useMobile } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import * as _ from 'lodash'
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'
import { BorderInput, Button, CheckBox, SC, Services } from './core'
import { LandingPageCCCDStackParamList } from './LandingPageCCCDStackParamList'
import { Header } from './Header'

const FormC = t.type({
  policyList: t.array(
    t.union([
      t.type({
        selected: t.literal(false),
        policyRight: t.string,
        policyLeft: form2.string.optional
      }),
      t.type({
        selected: t.literal(true),
        policyRight: t.string,
        policyLeft: form2.string.requiredM(() =>
          i18next.t('message:MS020001', { field: i18next.t('landingPage:policyNumber') })
        )
      })
    ])
  )
})

type Props = StackScreenProps<LandingPageCCCDStackParamList, 'PolicyListScreen'>

export const PolicyListScreen = (props: Props) => {
  const { t } = useTranslation('landingPage')
  const { t: m } = useTranslation('message')
  const { isWide } = useMobile()

  const { idNumber, dob, phoneNumber } = props.route.params || {}

  React.useEffect(() => {
    if (!idNumber || !dob || !phoneNumber) {
      setTimeout(() => props.navigation.navigate('HomeScreen'), 0)
    }
  }, [])

  const [loading, setLoading] = React.useState(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    pipe(
      setLoading(true),
      () => Services.getPolicyList({ idNumber, dob, phoneNumber }),
      ZIO.tap((data) =>
        ZIO.effect(() => {
          setLoading(false)
          data.forEach((item, index) =>
            setValue(`policyList.${index}`, {
              selected: false,
              policyRight: item,
              policyLeft: ''
            })
          )
        })
      ),
      ZIO.catchAll(() =>
        ZIO.effect(() => {
          setLoading(false)
          showToast(i18next.t('common:SomeThingWentWrong'), 'error', {
            horizontal: 'center',
            vertical: 'top'
          })
        })
      ),
      ZIO.unsafeRun({})
    )
  }, [])

  const {
    base: {
      control,
      setValue,
      watch,
      formState: { errors },
      clearErrors
    },
    handleSubmit
  } = form2.useForm(FormC, {
    defaultValues: {
      policyList: []
    }
  })

  const policyList = watch('policyList')

  const onSumit = handleSubmit((validated) => {
    const policy = validated.policyList.find((_) => _.selected)
    if (!policy) {
      return showToast(i18next.t('Vui lòng chọn {{field}}', { field: t('policyNumber') }), 'error', {
        horizontal: 'center',
        vertical: 'top'
      })
    }

    return pipe(
      setLoading(true),
      () => Services.getOwnerInfo(policy.policyLeft + policy.policyRight),
      ZIO.map((data) => {
        setLoading(false)
        if (!data.externalIds || data.externalIds.SOE_VALUE !== idNumber) {
          return showToast(m('MS030029'), 'error', {
            horizontal: 'center',
            vertical: 'top'
          })
        }

        return props.navigation.navigate('ChangeNationalIdCardScreen', {
          idNumber,
          dob,
          phoneNumber,
          policyNumber: policy.policyLeft + policy.policyRight
        })
      }),
      ZIO.catchAll((e) => {
        const path = `source.response.data.responseStatus.errors.0.code`
        const code = _.get(e, path)

        return ZIO.effect(() => {
          setLoading(false)
          showToast(code ? m(code) : m('MS030029'), 'error', {
            horizontal: 'center',
            vertical: 'top'
          })
        })
      }),
      ErrorHandling.run()
    )
  })

  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#F7F7F7' }}>
      <Header t={t} isWide={isWide} />
      <SC.ContainerPolicyList maxWidth="920" isWide={isWide}>
        <SC.PageTitle isWide={isWide}>{t('policyList')}</SC.PageTitle>
        <View style={isWide ? styles.contentContainer : styles.contentContainerMobile}>
          {!loading && (
            <SC.PageTitleText isWide={isWide} hasError={!policyList?.length}>
              {policyList?.length ? m('MS050301') : m('MS030029')}
            </SC.PageTitleText>
          )}
          <ScrollView>
            <SC.ListItems isWide={isWide}>
              {policyList?.map((item, i) => (
                <SC.Item key={i} isWide={isWide}>
                  <CheckBox
                    containerStyle={{
                      borderRadius: 8,
                      height: 60,
                      justifyContent: 'center',
                      backgroundColor: '#FFFFFF',
                      marginLeft: 0,
                      marginRight: 0
                    }}
                    checked={item.selected}
                    onPress={() =>
                      policyList.forEach((_, j) => {
                        setValue(`policyList.${j}`, {
                          selected: i === j,
                          policyRight: _.policyRight,
                          policyLeft: _.policyLeft
                        })
                        clearErrors()
                      })
                    }
                    title={
                      item.selected === false ? (
                        <SC.NormalText style={{ marginLeft: 8 }}>****{item.policyRight}</SC.NormalText>
                      ) : (
                        <>
                          <Controller
                            control={control}
                            name={`policyList.${i}.policyLeft`}
                            render={({ field }) => (
                              <BorderInput
                                isDigit
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                placeholder=""
                                maxLength={4}
                                controlStyle={{ width: 84, marginLeft: 8, marginRight: 4 }}
                              />
                            )}
                          />
                          <SC.NormalText>{item.policyRight.replace(/\*/g, '')}</SC.NormalText>
                        </>
                      )
                    }
                  />
                  {_.get(errors, `policyList.${i}`) && (
                    <SC.TextError>{_.get(errors, `policyList.${i}.policyLeft.message`)}</SC.TextError>
                  )}
                </SC.Item>
              ))}
            </SC.ListItems>
          </ScrollView>
          <SC.Footer isWide={isWide}>
            <SC.FooterButton isWide={isWide}>
              <Button title={t('next')} onPress={onSumit} loading={loading} disabled={!policyList?.length} />
            </SC.FooterButton>
          </SC.Footer>
        </View>
      </SC.ContainerPolicyList>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    padding: 40,
    marginTop: 30,
    boxShadow: '0px 26px 30px rgba(0, 0, 0, 0.05)',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    //button: 39px, marginTop: 30px
    maxHeight: 'calc(100% - 69px)'
  },
  contentContainerMobile: {
    marginTop: 16,
    boxShadow: '0px 26px 30px rgba(0, 0, 0, 0.05)',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    padding: 20,
    //button: 39px, marginTop: 16px
    maxHeight: 'calc(100% - 55px)'
  }
})
