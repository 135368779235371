import { form2, getDiffMonths } from '@pulseops/common'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import { pipe } from 'fp-ts/function'
import moment from 'moment'
import i18next from 'i18next'

export namespace ReinstatementForm {
  const FileMeta = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type FileMeta = t.TypeOf<typeof FileMeta>

  const ReinDetail = t.intersection([
    t.type({
      name: form2.string.optional,
      clientNum: form2.string.optional,
      hAnswer: t.boolean,
      oAnswer: t.boolean,
      aAnswer: t.boolean,
      cAnswer: t.boolean,
      curOccupation: withMessage(form2.selectOption.required, () => 'This form required')
    }),
    t.union([
      t.type({
        oAnswer: t.literal(false)
      }),
      t.type({
        oAnswer: t.literal(true),
        newOccupation: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:NewOccupation').toLowerCase() })
        )
      })
    ]),
    t.union([
      t.type({
        aAnswer: t.literal(false)
      }),
      t.type({
        aAnswer: t.literal(true),
        newActivity: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:NewActivity').toLowerCase() })
        )
      })
    ]),
    t.union([
      t.type({
        cAnswer: t.literal(false)
      }),
      t.type({
        cAnswer: t.literal(true),
        newCompany: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CompanyName').toLowerCase() })
        )
      })
    ])
  ])

  export type ReinDetail = t.TypeOf<typeof ReinDetail>

  type AgentCodeBrand = {
    readonly AgentCode: unique symbol
  }

  type AgentCode = t.Branded<NonEmptyString, AgentCodeBrand>

  export const AgentCode = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('utilities:AgentCode') })),
    form2.refine(
      (l): l is AgentCode => /\b\d{8}\b/.test(l),
      (l) => i18next.t('message:MS100005'),
      'AgentCode'
    ),
    form2.refine(
      (l): l is AgentCode => l.startsWith('69') || l.startsWith('60'),
      (l) => i18next.t('message:MS050013', { field: i18next.t('utilities:AgentCode') }),
      'AgentCode'
    )
  )

  const FormRein = t.type({
    riskCommonDate: form2.string.optional,
    lapsedDate: form2.string.optional,
    frequency: form2.string.optional,
    installmentPrem: withMessage(form2.optional(t.number), () => 'form2.number.optional'),
    apl: withMessage(form2.optional(t.number), () => 'form2.number.optional'),
    opl: withMessage(form2.optional(t.number), () => 'form2.optional(t.number)'),
    reinFee: withMessage(form2.optional(t.number), () => 'form2.optional(t.number)'),
    totalReinAmount: withMessage(form2.optional(t.number), () => 'form2.number.optional'),
    agentCode: AgentCode,
    agentName: withMessage(NonEmptyString, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Agent').toLowerCase() })
    ),
    reinClients: t.array(ReinDetail),
    attachmentFiles: t.array(FileMeta)
  })
  export type FormRein = t.TypeOf<typeof FormRein>

  export const checkRequiredAttackment = (
    reinDetails: ReinDetail[],
    attachmentFiles: FileMeta[],
    lapsedDate?: string
  ) => {
    const lapseDate = moment(lapsedDate ?? new Date())
    const currentDate = moment(new Date())
    // const diffM = getDiffMonths(startDate, endDate)
    const diffM = moment(currentDate).diff(lapseDate, 'months')
    /* 
            Lapsed period < 12 months AND Any changes in state of health = "Change" 
            OR
            Lapsed period ≥ 12 months
        */
    if (diffM >= 12 || (diffM < 12 && reinDetails.some((c) => c.hAnswer))) {
      return !!attachmentFiles && attachmentFiles.length >= 1
    }
    return true
  }

  type AttactmentBrand = {
    readonly AttachmentsCheck: unique symbol
  }

  /* 
    check file declaration file upload
    */
  export type AttachmentsCheck = t.Branded<FormRein, AttactmentBrand>

  const AttachmentsCheck = pipe(
    FormRein,
    form2.refine(
      (l): l is AttachmentsCheck =>
        checkRequiredAttackment(l.reinClients, l.attachmentFiles, l.lapsedDate || undefined),
      (l) => i18next.t('message:MS050232'),
      'AttachmentsCheck'
    )
  )

  export const codec = t.type({
    formRein: AttachmentsCheck
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
