import { ImgUploadMutiple, Input, InquiryComplaintData, Panel, Title } from '@pulseops/common'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { ComplainInquiryForm } from '../../../../../submission/src/complain-inquiry/complain-inquiry-form'
import { IBGeneralField, TypeInputComponent } from '../../common'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail?: InquiryComplaintData.DetailData | null
  notShowTitle?: boolean
}
export const IBCommunicationInfo = ({ form, detail, notShowTitle }: Props) => {
  const { t } = useTranslation(['requestInfo', 'roles'])

  return (
    <View>
      {notShowTitle ? <></> : <Text style={{ fontWeight: '700', fontSize: 16, marginBottom: 10 }}>{t('CommunicationInfo').toUpperCase()}</Text>}
      <IBGeneralField
        FieldForm={form}
        col={1}
        typeInput={[
          {
            type: TypeInputComponent.INPUT,
            formName: 'communicationInfo.customerComments',
            title: t('CustomerComments'),
            inputType: 'input',
            multiline: true,
            numberOfLines: 5,
            maxLength: 1000,
            required: true
          },
          {
            type: TypeInputComponent.INPUT,
            formName: 'communicationInfo.responsesToCustomer',
            title: t('ResponseToCustomer'),
            inputType: 'input',
            multiline: true,
            numberOfLines: 5,
            maxLength: 1000,
            required: true
          },
          {
            type: TypeInputComponent.IMPORT,
            formName: 'communicationInfo.attachmentFiles',
            title: t('requestInfo:Attachment')
          }
        ]}
      />
    </View>
  )
}

const styles = StyleSheet.create({})
