import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const PremiumHisReq = t.type({
  start: t.number,
  size: t.number,
  data: t.type({
    policyNo: t.string,
    clientNo: t.string
  })
})

export type PremiumHisReq = t.TypeOf<typeof PremiumHisReq>

export const PremiumHistoryItem = t.type({
  transactionId: Maybe(t.string),
  paymentType: Maybe(t.string),
  bankCode: Maybe(t.string),
  transDate: Maybe(t.string),
  amount: Maybe(t.number),
  rcpDate: Maybe(t.string),
  orNo: Maybe(t.string),
  agtCol: Maybe(t.string),
  user: Maybe(t.string),
  channel: Maybe(t.string),
  sacsCode: Maybe(t.string),
  sacsType: Maybe(t.string),
  gtAmount: Maybe(t.number),
  content: Maybe(t.string),
  transactionCode: Maybe(t.string),
  paymentDesc: Maybe(t.string),
  isFromACMV: Maybe(t.boolean),
  glCode: Maybe(t.string)
})

export type PremiumHistoryItem = t.TypeOf<typeof PremiumHistoryItem>

export const PremiumHistoryInfo = t.type({
  total: Maybe(t.number),
  start: Maybe(t.number),
  size: Maybe(t.number),
  data: Maybe(t.type({}))
})
export type PremiumHistoryInfo = t.TypeOf<typeof PremiumHistoryInfo>

export const PremiumHistoryDetailItem = t.type({
  sacsCode: Maybe(t.string),
  sacsType: Maybe(t.string),
  gtAmount: Maybe(t.number),
  content: Maybe(t.string)
})

export type PremiumHistoryDetailItem = t.TypeOf<typeof PremiumHistoryDetailItem>

export const PremiumHistoryDetailC = t.type({
  data: t.array(PremiumHistoryDetailItem)
})

export type PremiumHistoryDetailC = t.TypeOf<typeof PremiumHistoryDetailC>

export const PremiumHisDetailReq = t.type({
  data: t.type({
    policyNo: t.string,
    transactionId: t.string
  })
})

export type PremiumHisDetailReq = t.TypeOf<typeof PremiumHisDetailReq>
