import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { withMessage } from 'io-ts-types'
import moment from 'moment'
export namespace InternalSuspendInformationForm {
  export type InternalSuspendInformationBrand = {
    readonly error_invalid_date: unique symbol
    readonly MS100011: unique symbol
  }
  export type InternalSuspendInformation = t.Branded<Date, InternalSuspendInformationBrand>
  const SuspendDetail = t.type({
    suspendType: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('InternalSuspend:SuspendType') })
    ),
    departmentCode: t.string,
    departmentShortName: t.string,
    departmentName: t.string,
    level: form2.selectOption.optional,
    status: form2.selectOption.optional,
    SLA: t.string,
    // effectiveTime: form2.date.optional,
    effectiveTime: pipe(
      form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('InternalSuspend:EffectiveTime') })),
      form2.refine(
        (l): l is InternalSuspendInformation => moment(l).isValid(),
        () => i18next.t('form:error_invalid_date'),
        'error_invalid_date'
      ),
      form2.refine(
        (l): l is InternalSuspendInformation => moment(l).valueOf() >= moment().valueOf(),
        () => i18next.t('message:MS100011'),
        'MS100011'
      )
    ),
    dueDateText: t.string,
    dueDateVal: t.string,
    to: withMessage(form2.string.required, () => i18next.t('message:MS020001', { field: i18next.t('To') })),
    cc: withMessage(form2.string.required, () => i18next.t('message:MS020001', { field: i18next.t('CC') })),
    content: form2.string.requiredM(() =>
      i18next.t('message:MS020001', { field: i18next.t('InternalSuspend:Content') })
    )
  })

  const InternalSuspendInformation = t.type({
    suspendList: t.array(SuspendDetail),
    userEmail: t.string
  })

  export type SuspendDetail = t.TypeOf<typeof SuspendDetail>
  export const codec = InternalSuspendInformation
  export type validated = t.TypeOf<typeof codec>
  export type raw = t.OutputOf<typeof codec>
}
