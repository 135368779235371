import { SubClaimTypeBenefit, TransactionType, SelectOption } from '@pulseops/common'
// import { TaskDetail } from '..'
import * as api from '../task-detail-api'
// import { adjudicationApi } from '../task-detail-api/AdjudicationModel'

export interface VerificationClaimPayout {
  tag: TransactionType.PAYOUT
  detail: api.VerificationClaimPayout & api.TaskDetailBase
  // claimInfo: {
  //   claimNo: string
  //   claimType: string
  // }
  // adjudicationInfo: {
  //   payableAmount: string
  // }
  // paymentInfo: {
  //   cashOutOptions: {
  //     type: string
  //     policy: {
  //       policyNo: string
  //       totalPremium: number
  //       status: string
  //     }
  //     paymentOption: {
  //       currency: string
  //       amount: number
  //     }
  //     bankAccount: {
  //       bankName: string
  //       bankCode: string
  //       branchCode: string
  //       accountNo: string
  //       accountName: string
  //     }
  //     attributesExt: {
  //       PAYEE_INFO: {
  //         paymentMethod: string
  //         isNewClient: boolean
  //         isPayeeNotPO: boolean
  //         changePayeeInfo: boolean
  //         payeeName: string
  //         idNumber: string
  //         dob: string
  //         gender: string
  //         occupation: string
  //         relationshipWithPolicyOwner: string
  //         nationality: string
  //         nationality2: string
  //         residenceCountry: string
  //       }
  //     }
  //   }
  // }
}
export const VerificationClaimPayout = (
  detail: api.VerificationClaimPayout & api.TaskDetailBase,
  poName: string
): VerificationClaimPayout => {
  return {
    tag: TransactionType.PAYOUT,
    detail: detail
  }
}
