import * as React from 'react'
import {
  ModalComponent,
  PayoutPopup,
  ModalComponentRef,
  ModalAction,
  Input,
  form2,
  SelectSearch,
  Title,
  Panel,
  assets,
  ImgUploadMutiple,
  GeneralService,
  ErrorHandling,
  GeneralData,
  DatePicker,
  PayoutService,
  PulseOpsFormat,
  AuthService,
  isCasualLabor,
  OfficeCode,
  RBAC,
  UserRoles,
  getLanguage,
  SelectOption,
  removeAbundantSpacesInText
} from '@pulseops/common'
import { Button } from '@pulseops/business/core'
import { useWindowDimensions, ScrollView, StyleSheet, View, Text } from 'react-native'
import {
  Checkbox,
  Error,
  withMaxDate,
  withMaxStringLength,
  withMinDate,
  withMinStringLength,
  withNumberGreater
} from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
// import { CashAtCounterForm } from './cash-at-counter.form'
import { Controller } from 'react-hook-form'
import { TableCustom } from '../../common/table'
import _ from 'lodash'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { OwnerInfo, Client, specialCharacters } from './constants'
import moment from 'moment'
import * as O from 'fp-ts/lib/Option'
import * as t from 'io-ts'
import i18next from 'i18next'
import { NonEmptyString, withMessage } from 'io-ts-types'
export namespace CashAtCounterForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })
  type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  type OfficeCodeBrand = {
    readonly MS990060: unique symbol
  }
  type ForeignAddressBrand = {
    readonly MS020001: unique symbol
    readonly MS050223: unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>
  type OfficeBrand = t.Branded<form2.selectOption.SelectOption<string> | null, OfficeCodeBrand>

  type ForeignAddress = t.Branded<string | null, ForeignAddressBrand>

  // type Email = t.Branded<NonEmptyString, { readonly MS050013: unique symbol }>

  const CashAtCounterform = t.intersection([
    t.union([
      t.type({
        payeeNotPO: t.literal(false),
        payeeName: pipe(form2.string.required),
        idNumber: pipe(form2.string.required)
      }),
      t.type({
        payeeNotPO: t.literal(true),
        payeeName: t.string,
        surname: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CASurname') })
        ),
        givenName: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:GivenName') })
        ),
        dob: pipe(
          withMessage(form2.date.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:DOB') })
          ),
          withMaxDate(new Date(), 'message:MS050013', 'submission:DateOfBirth'),
          withMinDate(new Date('1900-01-01'))
        ),
        relationshipWithPO: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:RelationshipWithPO') })
        ),
        mobileNumber: pipe(
          // withMessage(form2.string.required, () =>
          //   i18next.t('message:MS020001', { field: i18next.t('submission:PhoneNumber') })
          // )
          form2.string.optional
        ),
        email: pipe(
          // withMessage(form2.string.required, () =>
          //   i18next.t('message:MS020001', { field: i18next.t('requestInfo:Email') })
          // ),
          withMessage(form2.email.optional, () =>
            i18next.t('message:MS050013', { field: i18next.t('requestInfo:Email') })
          )
        ),
        nationality: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Nationality') })
        ),
        residenceCountry: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:ResidenceCountry') })
        ),
        occupation: pipe(form2.selectOption.optional),
        gender: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Gender') })
        ),
        salutation: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Salution') })
        ),
        married: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:SubMarried') })
        ),
        address: t.string,
        city: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CityProvince') })
        ),
        district: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:District') })
        ),
        ward: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Ward') })
        ),
        street: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:HouseNumber') })
        ),
        foreignAddress: pipe(
          form2.string.optional,
          form2.refine(
            (l): l is ForeignAddress =>
              !!l ||
              (CashAtCounterFormData.payeeNotPO &&
                (['VN'].includes(CashAtCounterFormData?.nationality?.value ?? '') ||
                  ['VN'].includes(CashAtCounterFormData?.nationality2?.value ?? ''))),
            () => i18next.t('message:MS020001', { field: i18next.t('submission:ForeignAddress') }),
            'MS020001'
          ),
          form2.refine(
            (l): l is ForeignAddress => !l || !specialCharacters.some((item) => l.includes(item)),
            () => i18next.t('message:MS050223'),
            'MS050223'
          )
        ),
        nationality2: pipe(
          // withMessage(form2.selectOption.required, () =>
          //   i18next.t('message:MS020001', { field: i18next.t('submission:Nationality2') })
          // )
          form2.selectOption.optional
        ),
        foreignCountry: pipe(form2.selectOption.optional),
        relativeDocument: pipe(
          t.array(File),
          form2.refine(
            (l): l is FileList => l && l.length > 0,
            () => i18next.t('message:MS020001', { field: i18next.t('submission:RelativeDocument') }),
            'FileList'
          )
        ),
        idNumber: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:IDNumber') })
          ),
          withMinStringLength(8),
          withMaxStringLength(24)
        ),
        idNumberSearch: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:IDNumber') })
          ),
          withMinStringLength(8),
          withMaxStringLength(24)
        )
      })
    ]),
    t.type({
      officeCode: pipe(
        withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('common:Office') })
        ),
        form2.refine(
          (l): l is OfficeBrand => {
            if (CashAtCounterFormData.isGA) return true
            return CashAtCounterFormData.submissionOfficeCode === l.value && ['PRU', 'VCO', 'DNG', 'HCM', 'NTG'].includes(l.value)
          },
          () => i18next.t('message:MS990060'),
          'MS990060'
        )
      ),
      amount: pipe(
        withMessage(form2.number.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('requestInfo:Amount') })
        ),
        withNumberGreater(0)
      )
    })
  ])

  export const codec = CashAtCounterform

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

let CashAtCounterFormData: CashAtCounterForm.Raw & { submissionOfficeCode: string; isGA: boolean } = {
  officeCode: null,
  amount: '',
  payeeNotPO: true,
  payeeName: '',
  idNumber: '',
  submissionOfficeCode: '',
  isGA: false,
  nationality: null,
  nationality2: null,
  dob: undefined,
  gender: null,
  mobileNumber: '',
  email: '',
  salutation: null,
  married: null,
  occupation: null,
  relationshipWithPO: null,
  address: '',
  foreignAddress: '',
  foreignCountry: null,
  residenceCountry: null,
  surname: '',
  givenName: '',
  city: null,
  district: null,
  ward: null,
  street: '',
  relativeDocument: [],
  idNumberSearch: ''
}

interface Props {
  primaryPolicy: string
  visible: boolean
  maxAmount: number
  ownerInfo: OwnerInfo
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData) => void
  isShowPayeeNotPoOption?: boolean
  isFractionFormat?: boolean
  submissionOfficeCode?: string
}
export type DataSource = {
  [key: string]: string | number | JSX.Element
}
export const CashAtCounterPopup = ({
  visible,
  primaryPolicy,
  maxAmount,
  ownerInfo,
  isShowPayeeNotPoOption = false,
  onClose,
  onConfirm,
  isFractionFormat,
  submissionOfficeCode
}: Props) => {
  const { width, height } = useWindowDimensions()
  const { t, i18n } = useTranslation()
  // const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { iacRela, nationalityOptions, occupations, salution, married, genders, provinceList } = pipe(
    ZIO.zipPar(
      GeneralService.getCountries,
      GeneralService.getOccupations,
      GeneralService.getIACRelationships,
      GeneralService.getProvinces
    ),
    ZIO.map(([countries, occupations, iacRela, provinceArr]) => {
      const nationalityOptions = countries
      const salution = [
        {
          value: 'BÀ',
          label: t('submission:Mrs')
        },
        {
          value: 'CHÁU',
          label: t('submission:Child')
        },
        {
          value: 'CÔ',
          label: t('submission:Ms')
        },
        {
          value: 'ÔNG',
          label: t('submission:Mr')
        },
        {
          value: 'QUÝ',
          label: t('submission:Quy')
        }
      ]
      const married = [
        {
          value: 'M',
          label: t('submission:Married')
        },
        {
          value: 'S',
          label: t('submission:Single')
        },
        {
          value: 'W',
          label: t('submission:Widowed')
        },
        {
          value: 'U',
          label: t('submission:Unknown')
        },
        {
          value: 'D',
          label: t('submission:Divorced')
        }
      ]
      const genders = [
        {
          value: 'F',
          label: t('common:Female')
        },
        {
          value: 'M',
          label: t('common:Male')
        }
      ]
      return {
        iacRela: iacRela?.map((item) => {
          return {
            value: item.code,
            // label: t(`relationship:${item.name}`),
            name: item.name,
            nameEn: item.nameEn
          }
        }),
        nationalityOptions: nationalityOptions.map((item: GeneralData) => {
          return {
            value: item.code,
            label: t(`submission:${item.name}`)
          }
        }),
        occupations: occupations.map((item: GeneralData) => {
          return {
            value: item.code,
            label: t(`submission:${item.name}`)
          }
        }),
        salution,
        married,
        genders,
        provinceList: provinceArr.map((item) => ({
          value: item.code,
          label: item.name
        }))
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    iaRela: [],
    nationalityOptions: [],
    occupations: [],
    salution: [],
    married: [],
    genders: [],
    provinceList: []
  }

  const modalRef = React.useRef<ModalComponentRef | null>(null)
  const [doubClients, setDoubClients] = React.useState<Client[] | null>([])
  const [copyInfo, setCopyInfo] = React.useState<boolean>(false)
  const [districtOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])
  const cashAtCounterForm = form2.useForm(
    CashAtCounterForm.codec,
    {
      defaultValues: { payeeNotPO: false }
    },
    'onChange'
  )
  const formData = cashAtCounterForm.base.watch()
  const { payeeNotPO, amount, officeCode } = formData
  const [isGA, setIsGA] = React.useState(false)
  const [searchMsg, setSearchMsg] = React.useState<string>('')
  // const [officeCodeSelected, setOfficeCodeSelected] = React.useState<OfficeCode>()
  const officeCodes: OfficeCode[] = pipe(GeneralService.getOfficeCodes('CSC'), ErrorHandling.runDidMount([]))
  const allOfficeList: OfficeCode[] = pipe(GeneralService.getALLOfficeList(), ErrorHandling.runDidMount([]))
  CashAtCounterFormData = Object.assign(CashAtCounterFormData, {
    ...formData,
    submissionOfficeCode: submissionOfficeCode,
    isGA: isGA
  })

  pipe(
    AuthService.userInfo,
    ZIO.flatMap((userData) => {
      const casualLaborInfo =
        userData.isGaLogin && !isCasualLabor()
          ? pipe(
            RBAC.getPermission(userData.email),
            ZIO.map((emailList) => {
              let isCasualLabor: boolean = false
              if (emailList && emailList.groups) {
                isCasualLabor = emailList.groups.includes(UserRoles.CasualLabor)
              }
              return {
                ...userData,
                isCasualLabor: isCasualLabor
              }
            })
          )
          : ZIO.effect(() => ({
            ...userData,
            isCasualLabor: isCasualLabor()
          }))
      return casualLaborInfo
    }),
    ZIO.tap((x) => {
      setIsGA(x.isGaLogin && !x.isCasualLabor)
      return ZIO.unit
    }),
    ZIO.flatMap((x) =>
      x.officeCode !== null
        ? pipe(
          GeneralService.getOfficeCode(x.officeCode),
          ZIO.tap((y) => {
            const officeCode = y.body[0]
            if (officeCode) {
              // setOfficeCodeSelected(officeCode)
              const option = {
                label: getLanguage() === 'en' ? officeCode.nameEn : officeCode.nameVi,
                value: officeCode.code
              }
              cashAtCounterForm.base.setValue('officeCode', option)
            }
            return ZIO.unit
          })
        )
        : ZIO.succeed(null)
    ),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    return () => {
      cashAtCounterForm.base.reset({ payeeNotPO: false })
    }
  }, [])

  React.useEffect(() => {
    cashAtCounterForm.base.setValue('amount', `${maxAmount}`)
  }, [maxAmount])

  React.useEffect(() => {
    // cashAtCounterForm.base.setValue('office', 'A70 - Quận 5')
    cashAtCounterForm.base.setValue('payeeName', ownerInfo.ownerName)
    cashAtCounterForm.base.setValue('idNumber', ownerInfo.nationalId)
  }, [ownerInfo])

  React.useEffect(() => {
    if (payeeNotPO) {
      cashAtCounterForm.base.setValue('idNumberSearch', '')
      cashAtCounterForm.base.setValue('relativeDocument', [])
    } else {
      setDoubClients(null)
      cashAtCounterForm.base.reset({
        amount: maxAmount.toString(),
        officeCode: formData.officeCode,
        payeeName: ownerInfo?.ownerName,
        idNumber: ownerInfo?.nationalId,
        payeeNotPO: false
      })
    }
  }, [payeeNotPO])

  const getDistricts = (provinceCode: string) => {
    cashAtCounterForm.base.setValue('district', null)
    cashAtCounterForm.base.setValue('ward', null)
    return pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.code,
            label: i18n.language === 'vi' ? district.name : district.name
          }
        })
        setDistrictOptions(districtOptions)
        return districtOptions
      }),
      ErrorHandling.run()
    )
  }

  const getWards = (provinceCode: string, districtCode: string) => {
    cashAtCounterForm.base.setValue('ward', null)
    return pipe(
      GeneralService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.code,
            label: i18n.language === 'vi' ? ward.name : ward.name
          }
        })
        setWardOptions(wardOptions)
        return wardOptions
      }),
      ErrorHandling.run()
    )
  }

  const getOfficeList = () => {
    const filteredOffices = officeCodes
      .filter((x) => ['PRU', 'VCO', 'DNG', 'HCM', 'NTG'].includes(x.code))
      .map((item) => ({
        label: getLanguage() === 'en' ? item.nameEn : item.nameVi,
        value: item.code
      }))
    return filteredOffices
  }

  const isForeignAddressRequired = () => {
    return (
      !['VN'].includes(cashAtCounterForm.base.watch('nationality.value') ?? '') &&
      !['VN'].includes(cashAtCounterForm.base.watch('nationality2.value') ?? '')
    )
  }

  const onChangeNationality = (nationality: SelectOption | null, nationality2: SelectOption | null) => {
    if (nationality?.value === 'VN' || nationality2?.value === 'VN') {
      cashAtCounterForm.base.clearErrors('foreignAddress')
    }
  }

  const getClientsInfo = () => {
    if (_.get(formData, 'idNumberSearch')) {
      pipe(
        PayoutService.getClients(_.get(formData, 'idNumberSearch')),
        ZIO.tap((clients) => {
          const checkDouble = clients.reduce((unique: Client[], o) => {
            if (
              !unique.some(
                (obj: Client) => obj?.sureName === o.sureName && obj?.gender === o.gender && obj?.dob === o.dob
              )
            ) {
              unique.push({
                ...o,
                sureName: o.sureName,
                giveName: o.giveName,
                fullName: o.sureName + ' ' + o.giveName,
                address01: o.address01 ?? '',
                address02: o.address02 ?? '',
                address03: o.address03 ?? '',
                address04: o.address04 ?? '',
                idDate: o.idDate ?? '',
                nationality2: o.nationality2 ?? '',
                email: o.email.trim(),
                foreignStreet: o.foreignStreet ?? '',
                foreignCountry: o.foreignCountry ?? ''
              })
            }
            return unique
          }, [])
          setDoubClients(checkDouble)
          if (_.isEmpty(clients)) {
            setCopyInfo(false)
            cashAtCounterForm.base.setValue('amount', maxAmount.toString())
            cashAtCounterForm.base.setValue('officeCode', formData.officeCode)
            cashAtCounterForm.base.setValue('payeeName', '')
            cashAtCounterForm.base.setValue('surname', '')
            cashAtCounterForm.base.setValue('givenName', '')
            cashAtCounterForm.base.setValue('idNumber', _.get(formData, 'idNumberSearch') ?? '')
            cashAtCounterForm.base.setValue('relativeDocument', [])
            setSearchMsg(t('message:MS030033'))
          } else {
            setSearchMsg('')
          }
          return ZIO.unit
        }),
        ErrorHandling.run()
      )
    }
  }

  const getItemByList = (list: SelectOption[] | null, oldItem: string) => {
    const newItem = pipe(
      list?.find((x) => oldItem.trimEnd().toLowerCase().includes(x.label.toLowerCase())),
      O.some,
      O.toUndefined
      // O.getOrElse(() => ({ value: '', label: '' }))
    )
    return newItem
  }

  const getAddressInfo = (proviceVal: string, districtVal: string, wardVal: string) => {
    return pipe(
      ZIO.effect(() => {
        const cityItem = getItemByList(provinceList, proviceVal)
        return cityItem
      }),
      ZIO.flatMap((cityItem) => {
        return !!cityItem
          ? pipe(
            ZIO.fromPromise(() => getDistricts(cityItem?.value ?? '')),
            ZIO.map((districtList) => {
              const districtItem = getItemByList(districtList, districtVal)
              return {
                cityItem,
                districtItem
              }
            })
          )
          : ZIO.succeed(cityItem)
      }),
      ZIO.flatMap((dataItem) =>
        !!dataItem
          ? pipe(
            ZIO.fromPromise(() => getWards(dataItem.cityItem.value, dataItem.districtItem?.value ?? '')),
            ZIO.map((wardList) => {
              const wardItem = getItemByList(wardList, wardVal)
              return {
                cityItem: dataItem.cityItem,
                districtItem: dataItem.districtItem,
                wardItem
              }
            })
          )
          : ZIO.succeed(dataItem)
      ),
      ZIO.unsafeRun({})
    )
  }

  const getForeignCountry = (foreignCode: string) => {
    return nationalityOptions.find((item) => item.value === foreignCode)
  }

  const handleCopyInfo = async (index: number, data: DataSource) => {
    const dataItem = await getAddressInfo(
      data?.address04 as string,
      data?.address03 as string,
      data?.address02 as string
    )
    cashAtCounterForm.base.reset({
      ...formData,
      ...{
        idNumber: data?.securityNo.toString(),
        payeeName: data?.sureName.toString(),
        surname: data?.sureName.toString(),
        givenName: data?.giveName.toString(),
        married: married.find((_) => _.value === data.married) || null,
        salutation: salution.find((_) => _.value === data.salutation) || null,
        occupation: occupations.find((_) => _.value === data.occupationCode) || null,
        nationality: nationalityOptions.find((_) => _.value === data.nationality) || null,
        nationality2: nationalityOptions.find((_) => _.value === data.nationality2) || null,
        gender: genders.find((_) => _.value === data.gender) || null,
        residenceCountry: nationalityOptions.find((_) => _.value === data.countryCode) || null,
        dob: PulseOpsFormat.getStringToDate(data.dob.toString(), 'YYYYMMDD'),
        // address: `${data?.address01} ${data?.address02} ${data?.address03} ${data?.address04}`,
        address: '',
        mobileNumber: data?.mobileNumber?.toString().trim(),
        email: data?.email?.toString(),
        city: dataItem?.cityItem,
        district: dataItem?.districtItem,
        ward: dataItem?.wardItem,
        street: `${data?.address01 ?? ''}`,
        foreignAddress: data?.foreignStreet.toString(),
        foreignCountry: getForeignCountry(data?.foreignCountry.toString())
      }
    })
    setCopyInfo(true)
    setDoubClients([])
  }

  const onPressClose = () => {
    onClose()
  }

  const onPressConfirm = cashAtCounterForm.handleSubmit(async (data) => {
    // const amount = typeof data.amount === 'string' ? Number(formData.amount) : formData.amount
    const amount = Number(formData.amount)
    const payeeName = payeeNotPO
      ? removeAbundantSpacesInText(formData.surname) + ' ' + removeAbundantSpacesInText(formData?.givenName)
      : formData.payeeName ?? ''
    const officeInfo = getOfficeInfo()
    const relativeDocument = payeeNotPO
      ? _.get(formData, 'relativeDocument').map((item) => ({
        ...item,
        uploadedDate: item.uploadedDate as Date,
        file: item.file as File
      }))
      : undefined
    const payout: PayoutPopup.PayoutData = {
      method: PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
      amount: amount,
      policyNum: primaryPolicy,
      poName: payeeName,
      officeCode: formData.officeCode?.value ?? '',
      bankCode: '',
      bankName: '',
      bankAccountNum: '',
      bankAccountName: '',
      bankBranchCode: '',
      bankBranchName: '',
      nationalId: _.get(formData, 'idNumber') ?? '',
      officeBankCode: officeInfo.bankCode,
      officeType: officeInfo.officeType,
      payee: payeeNotPO
        ? {
          paymentMethod: PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
          isPayeeNotPO: true,
          dob: moment(_.get(formData, 'dob')).format('YYYYMMDD') ?? '',
          gender: _.get(formData, 'gender.value') ?? '',
          phone: _.get(formData, 'mobileNumber') ?? '',
          email: _.get(formData, 'email') ?? '',
          salutation: _.get(formData, 'salutation.value') ?? '',
          married: _.get(formData, 'married.value') ?? '',
          occupation: _.get(formData, 'occupation.value') ?? '',
          relationshipWithPO: _.get(formData, 'relationshipWithPO.value') ?? '',
          nationality: _.get(formData, 'nationality.value') ?? '',
          address: _.get(formData, 'address') ?? '',
          nationality2: _.get(formData, 'nationality2.value') ?? '',
          foreignAddress: removeAbundantSpacesInText(_.get(formData, 'foreignAddress') ?? ''),
          foreignCountry: _.get(formData, 'foreignCountry.value') ?? '',
          isNewClient: !copyInfo,
          residenceCountry: _.get(formData, 'residenceCountry.value') ?? '',
          payeeName: payeeName,
          issuedDate: undefined,
          issuedBy: undefined,
          issueCode: undefined,
          name: payeeName,
          idNumber: _.get(formData, 'idNumber') ?? '',
          dod: '',
          surName: removeAbundantSpacesInText(_.get(formData, 'surname')),
          giveName: removeAbundantSpacesInText(_.get(formData, 'givenName')),
          city: _.get(formData, 'city.label') ?? '',
          district: _.get(formData, 'district.label') ?? '',
          ward: _.get(formData, 'ward.label') ?? '',
          street: removeAbundantSpacesInText(_.get(formData, 'street') ?? ''),
          relativeDocument: relativeDocument
        }
        : {
          paymentMethod: PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
          isPayeeNotPO: false,
          isNewClient: false,
          payeeName: payeeName,
          idNumber: _.get(formData, 'idNumber') ?? '',
          dob: undefined,
          gender: undefined,
          phone: undefined,
          email: undefined,
          salutation: undefined,
          married: undefined,
          occupation: undefined,
          relationshipWithPO: undefined,
          nationality: undefined,
          address: undefined,
          nationality2: undefined,
          foreignAddress: undefined,
          foreignCountry: undefined,
          residenceCountry: undefined,
          issuedDate: undefined,
          issuedBy: undefined,
          issueCode: undefined,
          name: undefined,
          dod: undefined,
          surName: undefined,
          giveName: undefined,
          city: undefined,
          district: undefined,
          ward: undefined,
          street: undefined,
          relativeDocument: relativeDocument
        }
    }
    onConfirm(payout)
  })

  const getOfficeInfo = () => {
    const officeCode = cashAtCounterForm.base.watch('officeCode.value')
    const officeItem = pipe(
      allOfficeList.find((x) => x.code === officeCode),
      O.fromNullable,
      O.map((item) => ({
        bankCode: item.bankCode ?? '',
        officeType: item.type
      })),
      O.getOrElse(() => ({
        bankCode: '',
        officeType: ''
      }))
    )
    return officeItem
  }

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled: !cashAtCounterForm.base.formState.isValid || Number(amount || 0) > maxAmount,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]
  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:CashAtCounter')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <View
          style={{
            flex: 1,
            backgroundColor: '#FAFAFA',
            borderWidth: 1,
            borderColor: '#D3DCE6',
            marginTop: 20,
            paddingHorizontal: 30,
            paddingVertical: 22
          }}
        >
          <View style={{ flexDirection: 'row', marginTop: 22, width: '50%' }}>
            <Controller
              key={`${officeCode?.label}-${officeCode?.value}`}
              control={cashAtCounterForm.base.control}
              name={'officeCode'}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <View style={{ flex: 1 }}>
                    <SelectSearch
                      value={value}
                      onChange={(value) => {
                        // cashAtCounterForm.base.clearErrors('officeCode')
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      label={t('common:Office')}
                      options={getOfficeList()}
                      disableUnderline={true}
                      placeholder={t('common:ChooseOffice')}
                      disabled={isGA}
                      errorMessage={error?.message}
                      buildLabel={(select) => `${select.value} - ${select.label}`}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required
                    />
                  </View>
                )
              }}
            />
          </View>
          {/* {isShowPayeeNotPoOption && (
            <Controller
              control={cashAtCounterForm.base.control}
              name="payeeNotPO"
              render={({ field }) => <Checkbox {...field} title={t('submission:PayeeIsNotPO')} fontWeight="bold" />}
            />
          )} */}
          <Controller
            control={cashAtCounterForm.base.control}
            name="payeeNotPO"
            render={({ field }) => <Checkbox {...field} title={t('submission:PayeeIsNotPO')} fontWeight="bold" />}
          />

          {payeeNotPO ? (
            <>
              <View style={{ flexDirection: 'row', marginTop: 22, width: '50%' }}>
                <Controller
                  control={cashAtCounterForm.base.control}
                  name="idNumberSearch"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1 }}>
                      <Input
                        {...field}
                        title={t('submission:IDNumber')}
                        containerStyle={{ flex: 1 }}
                        maxLength={24}
                        disabled={!payeeNotPO}
                        errorMessage={payeeNotPO ? error?.message : ''}
                        inputType={'number'}
                        required
                      />
                      {!error?.message && !!searchMsg && <Text style={styles.searchMessage}>{searchMsg}</Text>}
                    </View>
                  )}
                />
                <Button
                  title={t('common:Search')}
                  onPress={getClientsInfo}
                  disabled={_.get(formData, 'idNumberSearch.length') < 8}
                  style={{ marginTop: 20 }}
                />
              </View>
              {!_.isEmpty(doubClients) && (
                <View style={{ flexDirection: 'row', marginTop: 22 }}>
                  <TableCustom
                    columns={[
                      {
                        key: '0',
                        title: t('submission:FullName'),
                        name: 'fullName'
                      },
                      {
                        key: '1',
                        title: t('submission:DOB'),
                        name: 'dob',
                        render: ({ value }) => <Text>{moment(value).format('DD/MM/YYYY')}</Text>
                      },
                      {
                        key: '2',
                        title: t('submission:IDNumber'),
                        name: 'securityNo'
                      },
                      {
                        key: '3',
                        title: t('submission:Gender'),
                        name: 'gender',
                        render: ({ value }) => <Text>{t(`submission:${value === 'F' ? 'Female' : 'Male'}`)}</Text>
                      }
                    ]}
                    dataSource={doubClients as any[]}
                    onRowSelected={handleCopyInfo}
                  />
                </View>
              )}
              {!_.isNull(doubClients) && _.isEmpty(doubClients) && (
                <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA', marginTop: 22 }}>
                  <Title title={t('submission:PayeeInformation')} />
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    {/* <Controller
                      control={cashAtCounterForm.base.control}
                      name="payeeName"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:PayeeName')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={120}
                          required
                          // errorMessage={payeeNotPO ? error?.message : ''}
                          errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                        />
                      )}
                    /> */}
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="surname"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:CASurname')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={120}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="givenName"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:GivenName')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={120}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="idNumber"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:IDNumber')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          maxLength={24}
                          // disabled={true}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="amount"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required={true}
                          title={t('requestInfo:Amount')}
                          containerStyle={{ flex: 1 }}
                          inputType={'money'}
                          value={field.value || ''}
                          isFractionFormat={isFractionFormat}
                          errorMessage={Number(field.value || 0) > maxAmount ? t('message:MS050159') : error?.message}
                          disabled={!payeeNotPO || copyInfo}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <View style={{ flexDirection: 'column', width: '50%', marginEnd: 30 }}>
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>
                          {t('submission:DateOfBirth')}
                        </Text>
                        <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                      </View>
                      <Controller
                        control={cashAtCounterForm.base.control}
                        name="dob"
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <DatePicker
                            value={value}
                            onBlur={onBlur}
                            errorMessage={error?.message || ''}
                            maxDate={new Date()}
                            minDateMessage={''}
                            //maxDateMessage={t('message:MS050013', { field: t('submission:DateOfBirth') })}
                            onChange={(date) => {
                              if (!!date && moment(date).isValid()) {
                                onChange(date)
                              }
                            }}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="mobileNumber"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:PhoneNumber')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO}
                          // errorMessage={payeeNotPO ? error?.message : ''}
                          errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          value={field.value || ''}
                          maxLength={10}
                          // required
                          inputType={'number'}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="email"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:Email')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO}
                          errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          value={field.value || ''}
                        // required
                        />
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="relationshipWithPO"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('relationshipWithPO')?.value}
                            {...field}
                            required={true}
                            label={t('submission:RelationshipWithPO')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={iacRela?.map((x) => ({
                              value: x.value,
                              label: i18n.language === 'en' ? x.nameEn : x.name
                            }))}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="occupation"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('occupation')?.value}
                            {...field}
                            // required={true}
                            label={t('submission:Occupation')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={occupations}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="gender"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('gender')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Gender')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={genders}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="salutation"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('salutation')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Salutation')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={salution}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="married"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('married')?.value}
                            {...field}
                            required={true}
                            label={t('submission:SubMarried')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={married}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="residenceCountry"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('residenceCountry')?.value}
                            {...field}
                            required={true}
                            label={t('submission:ResidenceCountry')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="nationality"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('nationality')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Nationality')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            onChange={(val) => {
                              field.onChange(val)
                              onChangeNationality(val, cashAtCounterForm.base.watch('nationality2'))
                            }}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    {/* <Controller
                      control={cashAtCounterForm.base.control}
                      name="address"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          title={t('submission:Address')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO || copyInfo}
                          // errorMessage={payeeNotPO ? error?.message : ''}
                          errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          value={field.value || ''}
                          required
                        />
                      )}
                    /> */}
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="city"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('city')?.value}
                            {...field}
                            onChange={(provinceItem) => {
                              field.onChange(provinceItem)
                              getDistricts(provinceItem?.value ?? '')
                            }}
                            required={true}
                            label={t('submission:CityProvince')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={provinceList}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          // disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="district"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('district')?.value}
                            {...field}
                            onChange={(districtItem) => {
                              field.onChange(districtItem)
                              getWards(cashAtCounterForm.base.watch('city.value') ?? '', districtItem?.value ?? '')
                            }}
                            required={true}
                            label={t('submission:District')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={districtOptions}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          // disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="ward"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('ward')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Ward')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={wardOptions}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                          // disabled={!payeeNotPO || copyInfo}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="street"
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('submission:HouseNumber')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={50}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="foreignCountry"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1, marginEnd: 30 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('foreignCountry')?.value}
                            {...field}
                            label={t('submission:ForeignCountry')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            disabled={!payeeNotPO}
                          />
                        </View>
                      )}
                    />
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="nationality2"
                      render={({ field, fieldState: { error } }) => (
                        <View style={{ flex: 1 }}>
                          <SelectSearch
                            key={cashAtCounterForm.base.getValues('nationality2')?.value}
                            {...field}
                            label={t('submission:Nationality2')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            onChange={(val) => {
                              field.onChange(val)
                              onChangeNationality(cashAtCounterForm.base.watch('nationality'), val)
                            }}
                            options={nationalityOptions}
                            // errorMessage={error?.message}
                            errorMessage={payeeNotPO ? error?.message : ''}
                            disabled={!payeeNotPO}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="foreignAddress"
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('submission:ForeignAddress')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO}
                          required={isForeignAddressRequired()}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          maxLength={30}
                        />
                      )}
                    />
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={cashAtCounterForm.base.control}
                      name="relativeDocument"
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <View style={{ width: '100%' }}>
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>
                                {t('submission:RelativeDocument')}
                              </Text>
                              <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>
                                *
                              </Text>
                            </View>
                            <ImgUploadMutiple {...field} value={field.value as any[]} />
                            {error?.message && <Error message={error.message} />}
                          </View>
                        )
                      }}
                    />
                  </View>
                </Panel>
              )}
            </>
          ) : (
            <>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <Controller
                  control={cashAtCounterForm.base.control}
                  name="payeeName"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      value={field.value ?? ''}
                      title={t('submission:PayeeName')}
                      containerStyle={{ flex: 1, marginEnd: 30 }}
                      disabled={!payeeNotPO}
                      maxLength={120}
                      errorMessage={payeeNotPO ? error?.message : ''}
                    />
                  )}
                />

                <Controller
                  control={cashAtCounterForm.base.control}
                  name="idNumber"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      title={t('submission:IDNumber')}
                      containerStyle={{ flex: 1 }}
                      maxLength={24}
                      disabled={!payeeNotPO}
                      errorMessage={payeeNotPO ? error?.message : ''}
                    />
                  )}
                />
              </View>
              <View style={{ flexDirection: 'row', marginTop: 22, width: '50%' }}>
                <Controller
                  control={cashAtCounterForm.base.control}
                  name="amount"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      required={true}
                      title={t('requestInfo:Amount')}
                      containerStyle={{ flex: 1 }}
                      inputType={'money'}
                      value={field.value || ''}
                      isFractionFormat={isFractionFormat}
                      errorMessage={Number(field.value || 0) > maxAmount ? t('message:MS050159') : error?.message}
                    />
                  )}
                />
              </View>
            </>
          )}
        </View>
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
