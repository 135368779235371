import * as React from 'react'
import { View } from 'react-native'
import {
  TaskDetail,
  Panel,
  PayoutPopup,
  PayoutChecker,
  PulseOpsFormat,
  getPayeeNotPOData,
  TaskDetailApi
} from '@pulseops/common'
import { Title, InfoViewList } from '../common'
import { PayoutMethod } from './common'
import { useTranslation } from 'react-i18next'
interface Props {
  detail: TaskDetail.ExcellentStudentAwardModel
}

export const ExcellentStudentAward = ({ detail }: Props) => {
  const { t } = useTranslation('requestInfo')

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: detail.totalAmount,
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('EXCELLENT_STUDENT_AWARD_INFORMATION')} />
      <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('ExcellentStudentAwardAmount'),
              value: detail.benefit,
              suffix: 'VND'
            },
            {
              label: t('PrincipalAPL-OPL'),
              value: detail.principalAmount,
              suffix: 'VND'
            },
            {
              label: t('InterestAPL-OPL'),
              value: detail.investmentAmount,
              suffix: 'VND'
            },
            {
              label: t('LoanOverLimit'),
              value: detail.overMaturity,
              suffix: 'VND'
            },
            {
              label: t('NetAmountAvailable'),
              value: detail.reqPayinAmount,
              suffix: 'VND',
              isHighlight: true
            }
          ]}
        />
      </Panel>
      <PayoutMethod
        payoutMethodData={payoutData}
        payeeData={viewPayeeData}
        containerStyle={{ marginTop: 16 }}
        panelContainerStyle={{ backgroundColor: '#FAFAFA' }}
      />
    </View>
  )
}
