import * as core from '@material-ui/core'
import * as React from 'react'

export type TablePaginationProps = {
  total: number
  size: number
  page: number
  colspan: number
  onPageChange: (page: number) => void
  onSizeChange: (size: number) => void
}

export const TablePagination = ({ total, size, page, onPageChange, onSizeChange, colspan }: TablePaginationProps) => {
  const classes = useStyles()
  return (
    <core.TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      colSpan={colspan}
      count={total}
      rowsPerPage={size}
      page={page}
      labelRowsPerPage="Dòng mỗi trang"
      SelectProps={{
        native: true
      }}
      onPageChange={(event, newPage) => {
        onPageChange(newPage)
      }}
      onRowsPerPageChange={(event) => {
        onSizeChange(parseInt(event.target.value, 10))
        onPageChange(0)
      }}
      className={classes.pagination}
    />
  )
}

export const TableFooterPagination = (props: TablePaginationProps) => (
  <core.TableFooter>
    <core.TableRow>
      <TablePagination {...props} />
    </core.TableRow>
  </core.TableFooter>
)

const useStyles = core.makeStyles((theme) => ({
  pagination: {
    border: 'unset',
    color: '#70777E'
  }
}))
