import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { PaymentMethod } from '../PaymentMethod'
import { SourceType } from '../SourceType'
import { getCoreDetail } from './common/payout'
import { PaymentType } from '../PaymentType'
import { InternalPayments } from '../task-detail-api/PaymentData'
import { ClientPolicy } from '../Client'
import { LasStatus } from '../LasStatus'
import { MaturityAdvanceGroup } from '../MaturityAdvance'

export interface MaturityAdvance {
  tag: TransactionType.MATURITY_ADVANCE

  clientName: string
  estimatedTotalBenefit: number | null
  paidAdvance: number | null
  netAdvance: number | null
  totalAmount: number
  methods: PaymentMethod[]
}
const getDetail = (id: string | null | undefined, policyClients: Array<ClientPolicy>): string =>
  id ? `${id} - ${policyClients.find((e) => e.policy === id)?.name}` : ''
export const MaturityAdvance = (
  detail: api.MaturityAdvance & api.TaskDetailBase,
  poName: string,
  maturityAdvData: MaturityAdvanceGroup.Detail,
  clientPolicy: Array<ClientPolicy>
): MaturityAdvance => {
  const p = detail.payload

  const coreStatus = getCoreDetail(detail.coreResponseDetail, p?.body?.cashOutOptions ?? [])

  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.EKIOSK:
    case SourceType.PRUGREAT:
    case SourceType.PRUONLINE:
    case SourceType.PULSE:
    case SourceType.ZALO:
      return {
        tag: TransactionType.MATURITY_ADVANCE,
        clientName: poName,
        estimatedTotalBenefit: maturityAdvData.estimatedTotalMaturity,
        paidAdvance: maturityAdvData.preMaturityAdvance,
        netAdvance: maturityAdvData.totalMaturityAmount,
        totalAmount: p.body?.cashOutOptions?.reduce((total, next) => total + (next.paymentOption.amount ?? 0), 0) ?? 0, // missing
        methods: p.body
          ? p.body.cashOutOptions?.map((v, i) => ({
              method: v.type || '-',
              detail:
                v.type === PaymentType.BANKTRANSFER
                  ? `${v.bankAccount?.bankName ? v.bankAccount?.bankName + ' - ' : ''} ${
                      v.bankAccount?.accountName ? v.bankAccount.accountName + ' -' : ''
                    } ${v.bankAccount?.accountNo || ''}`
                  : // : v.type === PaymentType.EWALLET
                  // ? `${v.bankAccount?.branchCode}`
                  v.type === PaymentType.MOMO
                  ? ''
                  : v.type && InternalPayments.includes(v.type)
                  ? getDetail(v.policy?.policyNo, clientPolicy)
                  : getDetail(v.policy?.policyNo, clientPolicy),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
            })) || []
          : []
      }
    case SourceType.PULSE4OPS:
      return {
        tag: TransactionType.MATURITY_ADVANCE,
        clientName: poName,
        estimatedTotalBenefit: maturityAdvData.estimatedTotalMaturity,
        paidAdvance: maturityAdvData.preMaturityAdvance,
        netAdvance: maturityAdvData.totalMaturityAmount,
        totalAmount: p.body?.cashOutOptions?.reduce((total, next) => total + (next.paymentOption.amount ?? 0), 0) ?? 0, // missing
        methods: p.body
          ? p.body.cashOutOptions?.map((v, i) => ({
              method: v.type || '-',
              detail:
                v.type === PaymentType.BANKTRANSFER
                  ? `${v.bankAccount?.bankName ? v.bankAccount?.bankName + ' - ' : ''} ${
                      v.bankAccount?.accountName ? v.bankAccount.accountName + ' -' : ''
                    } ${v.bankAccount?.accountNo || ''}`
                  : // : v.type === PaymentType.EWALLET
                  // ? `${v.bankAccount?.branchCode}`
                  v.type === PaymentType.MOMO
                  ? ''
                  : v.type && InternalPayments.includes(v.type)
                  ? getDetail(v.policy?.policyNo, clientPolicy)
                  : getDetail(v.policy?.policyNo, clientPolicy),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i]?.status ?? LasStatus.FAILED,
              note: coreStatus[i]?.errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
            })) || []
          : []
      }
  }
}
