import { Panel, SourceType, TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element
  suffix?: string
  isHighlight?: boolean
}

export const ConfirmationPremiumReceipt = ({ detail }: { detail?: TaskDetail.ConfirmationPremiumReceipt }) => {
  const { t } = useTranslation('requestInfo')
  let data: Output[] = []

  const defaultData = [
    {
      label: t('DocumentType'),
      value: t('ConfirmationOfPremiumReceipt')
    },
    {
      label: t('FromDate'),
      value: detail?.fromDate ? detail?.fromDate : '-'
    },
    {
      label: t('ToDate'),
      value: detail?.toDate ? detail?.toDate : '-'
    }
  ]
  switch (detail?.source) {
    case SourceType.BANCA:
    case SourceType.EKIOSK:
    case SourceType.PRUGREAT:
    case SourceType.PRUONLINE:
    case SourceType.PULSE:
    case SourceType.ZALO:
      data = [
        ...defaultData,
        {
          label: t('Reason'),
          value: detail?.reason ? detail.reason : '-'
        }
      ]
      break
    case SourceType.PULSE4OPS:
      data = defaultData
      break
  }

  return (
    <Panel title={''} titleOutline={t('LetterRequest')} isExand={false}>
      <InfoViewList dataSource={data} />
    </Panel>
  )
}
