export namespace GoAbroadModel {
  interface AbroadGeneral {
    clientName: string
    aboardAddress?: string
    addressWard: string
    addressDistrict: string
    addressCity: string
    addressCountry?: string
    clientPhoneCode: string
    clientPhoneNumber: string
  }

  export interface AbroadDetail extends AbroadGeneral {
    destinationCountry: string
    purpose: string
    departureDate: string
    duration: string
    fatca: boolean | string
    clientCode: string
  }

  export interface PremiumPayer extends AbroadGeneral {}

  export interface GoAbroadSubmitData {
    goAbroadClients: AbroadDetail[]
    premiumPayer?: PremiumPayer
  }
}
