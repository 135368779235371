import * as t from 'io-ts'
import { PageParameter, PageResult } from '@pulseops/common'
import { Maybe } from '@mxt/zio/codec'
import { DateFromISOString } from 'io-ts-types'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/Array'

import { TaskSource } from '../../TaskSource'
import { TransactionType, TransactionTypeC } from '../../TransactionType'
import { SubTaskCode, SubTaskCodeFromString } from '../../TaskType'
import { QueryTask } from '../../QueryTask'
import { TransactionStatus } from '../../TransactionStatus'
import { ErrorActivity } from './ErrorActivity'
import { AMLResult } from '../../AMLResult'

export namespace HistoricProcessInstances {
  export const getAMLResult = (task: HistoricProcessInstances): AMLResult => {
    switch (task.amlResult) {
      case 'HIT':
        return AMLResult.HIT
      case 'NO_HIT':
        return AMLResult.NO_HIT
      case 'ERROR':
        return AMLResult.ERROR
      case 'WARNING':
        return AMLResult.WARNING
      case 'HIT_ACCEPT':
        return AMLResult.HIT_ACCEPT
      case 'HIT_DECLINE':
        return AMLResult.HIT_DECLINE
      default:
        return AMLResult.NOTCONNECT
    }
  }

  export type InputData = {
    pagination: PageParameter
    filter: QueryTask.Filter
    taskCategory?: SubTaskCode
    category?: string
    activities?: string[],
    businessKeyEmpty?: boolean
  }

  export type PostDataType = {
    sourceIn?: TaskSource[]
    transactionTypeIn?: TransactionType[]
    policyNumber?: string
    createdAfter?: Date
    createdBefore?: Date
    start: number
    size: number
    taskCategory?: string
    activities?: string[]
    offices?: string[]
    category?: string,
    businessKeyEmpty?: boolean
  }

  export const PostData = ({
    pagination,
    filter,
    taskCategory,
    category,
    activities,
    businessKeyEmpty
  }: InputData): PostDataType => ({
    size: pagination.pageSize,
    start: pagination.pageSize * pagination.pageIndex,
    sourceIn: filter.source,
    transactionTypeIn: filter.transactionType,
    policyNumber: filter.policyNum,
    createdAfter: filter.fromDate,
    createdBefore: filter.toDate,
    taskCategory: taskCategory && SubTaskCodeFromString.encode(taskCategory),
    offices: filter.offices,
    activities: activities,
    category: category,
    businessKeyEmpty
  })

  export const codec = t.type({
    id: Maybe(t.string),
    name: Maybe(t.string),
    businessKey: Maybe(t.string),
    correlationId: Maybe(t.string),
    source: Maybe(t.string),
    vip: Maybe(t.string),
    category: Maybe(t.string),
    transactionType: Maybe(t.string),
    payload: Maybe(t.string),
    coreResponseDetail: Maybe(t.string),
    updateToCoreConfig: Maybe(t.boolean),
    updateToCore: Maybe(t.boolean),
    processInstanceId: Maybe(t.string),
    parentProcessInstanceId: Maybe(t.string),
    attachmentURLs: Maybe(t.array(t.string)),
    policyNumber: Maybe(t.string),
    clientNumber: Maybe(t.string),
    claimNumber: Maybe(t.string),
    createdBy: Maybe(t.string),
    amlResult: Maybe(
      t.union([
        t.literal('HIT'),
        t.literal('NO_HIT'),
        t.literal('ERROR'),
        t.literal('WARNING'),
        t.literal('HIT_ACCEPT'),
        t.literal('HIT_DECLINE'),
        t.literal('NOTCONNECT')
      ])
    ),
    paymentNumber: Maybe(t.string),
    poName: Maybe(t.string),
    lastUpdateDate: Maybe(DateFromISOString),
    lastVerUpdate: Maybe(t.string),
    lastUpdateByVer: Maybe(t.string),
    subTransactionCode: Maybe(t.string),
    subTransactionType: Maybe(t.string),
    agingDate: Maybe(t.number),
    redFlagResult: Maybe(t.boolean),
    pendingNum: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    paymentMethod: Maybe(t.string),
    amount: Maybe(t.string),
    bankCode: Maybe(t.string),
    paymentReason: Maybe(t.string),
    remark: Maybe(t.string),
    uwDecision: Maybe(t.string),
    lastActivity: Maybe(t.string),
    startTime: Maybe(DateFromISOString),
    endTime: Maybe(DateFromISOString),
    suspended: Maybe(t.boolean),
    completed: Maybe(t.boolean),
    assignee: Maybe(t.string),
    assigneeName: Maybe(t.string),
    status: Maybe(t.string),
    activity: Maybe(t.string),
    createdDate: Maybe(DateFromISOString),
    lastUpdateDateVer: Maybe(t.string),
    lastUpdateByUW: Maybe(t.string),
    lastUpdateDateUW: Maybe(t.string),
    lastUpdateByAdj: Maybe(t.string),
    lastUpdateDateAdj: Maybe(t.string),
    subServiceType: Maybe(t.string),
    claimType: Maybe(t.string),
    lifeAssuredName: Maybe(t.string),
    poClientNumber: Maybe(t.string),
    poPhoneNumber: Maybe(t.string),
    newType: Maybe(t.string),
    newSubType: Maybe(t.string),
    request: Maybe(t.string),
    exchangeId: Maybe(t.string),
    agentCode: Maybe(t.string),
    agentPhoneNumber: Maybe(t.string),
    agentLastName: Maybe(t.string),
    agentFirstName: Maybe(t.string),
    receiptDate: Maybe(t.string),
    referenceNumber: Maybe(t.string),
    printCountCashAtCounter: Maybe(t.string),
    policyOwner: Maybe(t.string),
    pvStatus: Maybe(t.string),
    authorizedBy: Maybe(t.string),
    authorizedDate: Maybe(t.string),
    pvNumber: Maybe(t.string),
    payeeName: Maybe(t.string),
    paymentDate: Maybe(t.string),
    statusNote: Maybe(t.string),
    isLocked: Maybe(t.string),
    eref: Maybe(t.string),
    is_skip_uw_check: Maybe(t.boolean),
    is_pass_verification: Maybe(t.boolean),
    is_skip_authority: Maybe(t.boolean)
  })

  export const Response = t.type({
    data: t.union([t.array(HistoricProcessInstances.codec), t.null]),
    total: t.number
  })

  export type Response = t.TypeOf<typeof Response>

  export const MapToTaskOp = (task: HistoricProcessInstances, status: TransactionStatus | null): ErrorActivity.TaskOp => ({
    id: task.id || null,
    category: task.category || '-',
    caseId: task.businessKey || '-',
    policyNumber: task.policyNumber && task.policyNumber !== 'null' ? task.policyNumber : '-',
    clientNumber: task.clientNumber && task.clientNumber !== 'null' ? task.clientNumber : '-',
    source: task.source || '-',
    transactionType: TransactionTypeC.is(task.transactionType) ? task.transactionType : null,
    assignee: task.assignee || '-',
    assigneeName: task.assigneeName || '-',
    status: status || null,
    agingDate: task.agingDate?.toString() || null,
    processInstanceId: task.processInstanceId || '-',
    updateToCore: task.updateToCore || false,
    createdDate: task.createdDate || null,
    createdBy: task.createdBy || '-',
    lastUpdateDate: task.lastUpdateDate || null,
    subTransactionType: task.subTransactionType || '',
    poName: task.poName || '',
    claimNumber: task.claimNumber || '',
    request: task.transactionType === TransactionType.COMMON_REQUEST_FORM && task.request ? task.request : '',
    amlResult: task.amlResult ? getAMLResult(task) : null,
    redFlagResult: task.redFlagResult === null || task.redFlagResult === undefined ? null : task.redFlagResult,
    paymentMethod: task.paymentMethod || '',
    proposalNumber: task.proposalNumber ?? null,
    eref: task.eref || '',
    stuckTime: task.startTime ?? (task.startTime ? new Date(task.startTime) : new Date()),
    currentActivity: task?.activity || '-',
    categoryError: task?.category || '-',
    nameError: task?.name || '-'
  })

  export const toPageResult =
    (pagination: PageParameter, status: TransactionStatus | null) =>
      (result: Response): PageResult<ErrorActivity.TaskOp> =>
        PageResult({
          items: pipe(
            result.data || [],
            A.map((task) => MapToTaskOp(task, status))
          ),
          total: result.total,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize
        })
}

export type HistoricProcessInstances = t.TypeOf<typeof HistoricProcessInstances.codec>
