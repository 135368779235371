import { POApi } from '@pulseops/common'
// import { SubmissionReportModal } from '../Model/SubmissionReportModal'
import { SubmissionReportModal } from '../model/SubmissionReportModal'

export namespace ReportSubmissionService {
  // const postAuth = PulseOpsApi.postAuth

  export const getReportData = (query: SubmissionReportModal.QueryData) =>
    // postAuth()(`${PulseOpsApi.apiV1}/reporting/submission-report`)(SubmissionReportModal.ReportsData)(query)
    POApi.post(`pulseops/api/v1/reporting/submission-report`)(SubmissionReportModal.ReportsData)(query)

  export const getReport = (query: SubmissionReportModal.QueryData) =>
    // postAuth()(`${PulseOpsApi.apiV1}/reporting/submission-report`)(SubmissionReportModal.ReportsData)(query)
    POApi.post(`pulseops/api/v1/reporting/submission-report`)(SubmissionReportModal.ReportsData)(query)
    

  // export const getReportData = (query: ReportSubmissionModal.QueryData) => {
  //   const { size } = query
  //   if (size > MAX) {
  //     return pipe(
  //       ZIO.sequencePar(
  //         Array.from(Array(Math.ceil(size / MAX)).keys()).map((index) =>
  //           getReport({ ...query, size: MAX, start: index * MAX })
  //         )
  //       ),
  //       ZIO.map((reports) => {
  //         const data: ReportSubmissionModal.ReportData = []
  //         reports
  //           .map(({ data }) => data)
  //           .forEach((datas) => {
  //             !!datas && data.push(...datas.map((data) => data))
  //           })
  //         return { ...reports[0], data }
  //       })
  //     )
  //   }
  //   return getReport(query)
  // }
}
