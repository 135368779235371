import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Customer } from '../Client'
import { MoreData } from '../MoreData'

export interface ZaloRequestProcess {
  tag: TransactionType.ZALO_REQUEST_PROCESS
  clientName: string

  note: string
}

export const ZaloRequestProcess = (
  detail: api.ZaloRequestProcess,
  client: Customer,
  sql: MoreData.PoNameFromSql
): ZaloRequestProcess => {
  return {
    tag: TransactionType.ZALO_REQUEST_PROCESS,
    clientName: sql.body?.poName || client.body.name,

    note: detail.payload.body.message || ''
  }
}
