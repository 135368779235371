/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { TaskDetail } from '@pulseops/common'

type Props = {
  detail: TaskDetail.AgentVerificationNew
}

export const DAAgentInformationScreen = (props: Props) => {
  const { t } = useTranslation('RequestInfo')
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.bodyInfo}>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('AgentNumber')}</Text> <Text>{props.detail?.agentCode ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('AgentName')}</Text> <Text>{props.detail?.agentName ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('AppointedDate')}</Text>{' '}
            <Text>{props.detail?.appointedDate ?? '-'}</Text>
          </View>
        </View>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('TerminateDate')}</Text>{' '}
            <Text>{props.detail?.terminateDate ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('AgentType')}</Text> <Text>{props.detail?.agentType ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('Title')}</Text> <Text>{props.detail?.title ?? '-'}</Text>
          </View>
        </View>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('UnitDescription')}</Text>{' '}
            <Text>{props.detail?.unitDescription ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('BranchDescription')}</Text>{' '}
            <Text>{props.detail?.branchDescription ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('OfficeDescription')}</Text>{' '}
            <Text>{props.detail?.officeDescription ?? ''}</Text>
          </View>
        </View>
        <View style={styles.bodyInfoHeader}>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('RegionBDM')}</Text>{' '}
            <Text>{props.detail?.regionDescription ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('SubzoneBDD')}</Text>{' '}
            <Text>{props.detail?.subzoneDescription ?? '-'}</Text>
          </View>
          <View style={styles.bodyPolyView}>
            <Text style={styles.bodyPolicy}>{t('Zonecode')}</Text> <Text>{props.detail?.zoneDescription ?? '-'}</Text>
          </View>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <View style={styles.bodyInfoOne}>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  bodyInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  bodyInfoOne: {
    width: '100%',
    padding: 80,
    backgroundColor: '#FFF',
  },
  bodyInfoHeader: {
    flexDirection: 'row',
    paddingVertical: 20
  },
  bodyPolyView: {
    flex: 1
  },
  bodyPolicy: {
    fontSize: 15,
    paddingVertical: 6,
    color: '#70777E',
    fontWeight: 'bold',
  }
})
