import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

export const VeriCOMPLAINTSALEDETAILS = t.array(
  Maybe(
    t.type({
      agentCode: t.string,
      agentName: t.string,
      financeNeedCode: t.string,
      isPoSignedInProposal: t.boolean,
      isCustomerReceiveKit: t.boolean,
      customerReceiveDate: t.string,
      evidence: Maybe(t.boolean)
    })
  )
)

export const VeriCOMPLAINTGADETAILS = t.array(
  Maybe(
    t.type({
      generalAgency: t.string,
      personComplaint: t.string,
      role: Maybe(t.string),
      verifiedAmount: Maybe(t.number),
      evidenceStatus: Maybe(t.string)
    })
  )
)

export const VeriCOMPLAINTMISMONEYDETAILS = t.array(
  Maybe(
    t.type({
      hasReciept: t.string,
      recieptType: t.string,
      recieptNum: t.string,
      complaintAmount: t.number,
      misPremiumDate: t.string,
      verifiedAmount: Maybe(t.number),
      evidenceStatus: Maybe(t.string)
    })
  )
)

export const VeriCONSERVATIONINFO = Maybe(
  t.union([
    t.type({
      conservationGAStaff: t.string,
      conservationAgentCode: t.string,
      conservationAgentName: t.string,
      reasonFullSurrender: t.string,
      conservationResult: t.string,
      conservationSolution: t.string,
      conservationBonus: t.string,
      conservationNote: t.string
    }),
    t.type({})
  ])
)

export const AdditionalDocuments = Maybe(
  t.array(
    t.type({
      transactionType: Maybe(t.string),
      attachmentList: Maybe(
        t.array(
          t.type({
            tag: Maybe(t.string),
            fileName: Maybe(t.string),
            fileExtension: Maybe(t.string),
            size: Maybe(t.number),
            base64: Maybe(t.string),
            uploadedDate: Maybe(t.string),
            url: Maybe(t.string),
            storageConfig: Maybe(
              t.type({
                sas: t.string
              })
            )
          })
        )
      )
    })
  )
)

export const SubmissionData = t.type({
  CATEGORY_CODE: Maybe(t.string),
  GROUP_CODE: Maybe(t.string),
  SUB_SVC_TYPE_CODE: Maybe(t.string),
  SUB_TRANS_TYPE_CODE: Maybe(t.string),
  REQUEST_WITH_ROLE: Maybe(t.string),
  REQUESTER_FULL_NAME: Maybe(t.string),
  REQUESTER_NATIONAL_ID: Maybe(t.string),
  RLA_WITH_PO: Maybe(t.string),
  METHOD: Maybe(t.string),
  SOURCE: Maybe(t.string),
  HOT: Maybe(t.boolean),
  FCR: Maybe(t.boolean),
  CUSTOMER_CMT: Maybe(t.string),
  RES_TO_CUSTOMER: Maybe(t.string),
  TOTAL_MIS_MONEY_AMT: Maybe(t.number),
  TOTAL_VERIFIED_AMOUNT: Maybe(t.number),
  COMPLAINT_MIS_MONEY_DETAILS: Maybe(VeriCOMPLAINTMISMONEYDETAILS),
  COMPLAINT_SALE_DETAILS: Maybe(VeriCOMPLAINTSALEDETAILS),
  COMPLAINT_GA_DETAILS: Maybe(VeriCOMPLAINTGADETAILS),
  CONSERVATION_INFO: VeriCONSERVATIONINFO,
  CLOSED_INFO: Maybe(t.string),
  CALL_ID: Maybe(t.string)
})

export const IACPayloadInfo = t.type({
  source: Maybe(t.string),
  body: t.type({
    attributesExt: SubmissionData,
    auditDetail: Maybe(
      t.type({
        createTime: Maybe(t.string)
      })
    )
  }),
  createdBy: Maybe(t.string),
  documents: Maybe(
    t.array(
      t.type({
        name: Maybe(t.string),
        url: Maybe(t.string)
      })
    )
  ),
  primaryPolicyNo: Maybe(t.string),
  clientId: Maybe(t.string),
  officeCode: Maybe(t.string),
  createdDate: Maybe(t.string),
  additionalDocuments: AdditionalDocuments
})

export const InquiryComplaint = t.type({
  transactionType: t.union([
    t.literal(TransactionType.COMPLAINT_AND_INQUIRY),
    t.literal(TransactionType.COMPLAINT),
    t.literal(TransactionType.INQUIRY)
  ]),
  payload: IACPayloadInfo,
  source: Maybe(t.string)
})

// export const IACUpdatedPayloadData = t.type({
//   InquiryComplaint
// })

export type InquiryComplaint = t.TypeOf<typeof InquiryComplaint>
