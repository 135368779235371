import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ErrorHandling, CashLessService, SelectOption } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'
import moment from 'moment'
const useStyles = makeStyles({
  table: {},
  tableHeader: {
    backgroundColor: '#E7E7E7'
  },
  tableBody: {
    backgroundColor: '#FFFFFF'
  },
  tableCellHeader: {
    backgroundColor: '#F4F4F4',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
})
interface WriteOffInfo {
  id: string
  suspendGroupCode: string
  suspendGroupVN: string
  suspendGroupEN: string
  suspendCode: string
  suspendType: string
  suspendTypeVN: string
  pendingLetters?: string | null
  status: string
  createdBy: string
  createdDate: string
  modifiedBy?: string | null
  modifiedDate?: string | null
}

export const statusOptions = [
  { label: 'Hiệu lực', labelEn: 'Active', value: 'Active' },
  { label: 'Mất hiệu lực', labelEn: 'Inactive', value: 'Inactive' },
  { label: 'Hoàn tất', labelEn: 'Complete', value: 'Complete' }
]

export const SuspendWriteOffInfo = (props: { processId: string }) => {
  const classes = useStyles()

  const [rows, setRows] = React.useState<WriteOffInfo[]>([])
  const [loading, bindLoading] = useLoading()
  const { t, i18n } = useTranslation()

  const loadData = () =>
    pipe(
      CashLessService.getListWriteOffHistory(props.processId),
      ZIO.map((res) => {
        setRows(res)
      }),
      bindLoading
    )

  pipe(loadData(), ErrorHandling.runDidMount())

  return (
    <View style={{ margin: 10 }}>
      <View>
        {loading && <LinearProgress color="secondary" />}
        <Text style={styles.textTitle}>{t('common:SuspendWriteOffHistory')}</Text>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableCellHeader}>{t('SupplementaryInfo:SuspendGroup')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('SupplementaryInfo:SuspendType')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('common:Status')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('SupplementaryInfo:CreatedUser')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('SupplementaryInfo:CreatedDate')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('SupplementaryInfo:UpdatedUser')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('SupplementaryInfo:UpdatedDate')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {rows.map((row, i) => (
                <TableRow key={`writeoff-${i}`}>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    {i18n.language === 'en' ? row.suspendGroupEN : row.suspendGroupVN}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {i18n.language === 'en' ? row.suspendType : row.suspendTypeVN}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {i18n.language === 'en'
                      ? statusOptions.find((item) => item.value === row.status)?.labelEn
                      : statusOptions.find((item) => item.value === row.status)?.label}
                  </TableCell>
                  <TableCell className={classes.tableCell}>{row.createdBy}</TableCell>
                  <TableCell className={classes.tableCell}>{moment(row.createdDate).format('DD/MM/YYYY')}</TableCell>
                  <TableCell className={classes.tableCell}>{row.modifiedBy ?? '-'}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.modifiedDate ? moment(row.modifiedDate).format('DD/MM/YYYY') : '-'}
                  </TableCell>
                </TableRow>
              ))}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                    {t('common:noRecord')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={total}
          onPageChange={(e, page) => {
            setPageNum(page)
            update()
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value))
            setOrderTable()
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        /> */}
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  textTitle: {
    color: '#727272',
    fontWeight: 'bold',
    marginVertical: 10
  }
})
