import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Container, ErrorHandling, FieldList, Panel, useTranslator, TableField, AppContext } from '@pulseops/common'
import { CepTableField, CepFieldList } from '../cep-components'
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { getAgentInfo } from '@pulseops/inquiry'
export interface AgentInfoData {
  agentInfo: FieldList.DataSources
  oldAgentInfo: TableField.DataSources
}

export const CepAgentScreen = (props: {
  id: string
  data: AgentInfoData | null
  setData: (val: AgentInfoData | null) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()

  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('Tab:AgentInfo'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getDataAgent = (): ZIO<unknown, Throwable, AgentInfoData> =>
    pipe(
      getAgentInfo(props.id),
      ZIO.map((agent) => {
        const { policyAgent, servicingHistory } = agent.data

        const unitManagerCode = policyAgent && policyAgent.branchManager ? policyAgent.branchManager : ''
        const unitManagerName = policyAgent && policyAgent.unitManagerName ? policyAgent.unitManagerName : ''
        const branchManagerCode = policyAgent?.branchManager ? policyAgent.branchManager : ''
        const branchManagerName = policyAgent && policyAgent.branchManagerName ? policyAgent.branchManagerName : ''
        const GAcode = policyAgent && policyAgent.gaCode ? policyAgent.gaCode : ''
        const GAname = policyAgent && policyAgent.gaName ? policyAgent.gaName : ''
        const servicingAgCode = policyAgent && policyAgent?.servicingAgentCode ? policyAgent.servicingAgentCode : ''
        const servicingAgName =
          policyAgent && policyAgent?.servicingAgentName ? getName(policyAgent.servicingAgentName) : ''
        const sharingAgCode = policyAgent && policyAgent?.sharingAgentCode ? policyAgent?.sharingAgentCode : ' '
        const sharingAgName = policyAgent && policyAgent?.sharingAgentName ? getName(policyAgent.sharingAgentName) : ''
        const GAD = policyAgent && policyAgent.gad ? policyAgent.gad : ''

        return {
          agentInfo: [
            {
              label: t('Agent:ServicingAgent'),
              value: servicingAgCode + ' ' + servicingAgName,
              colLength: '25%'
            },
            {
              label: t('contact:MobilePhone'),
              value:
                !!policyAgent && policyAgent?.servicingAgentMobilePhone ? policyAgent?.servicingAgentMobilePhone : '',
              colLength: '25%'
            },
            {
              label: t('Agent:OfficeCode'),
              value: !!policyAgent && policyAgent?.officeName ? policyAgent.office + ' ' + policyAgent.officeName : '',
              colLength: '25%'
            },
            {
              label: cep('GAOffice'),
              value: GAcode + ' - GAD: ' + GAD,
              colLength: '25%'
            },
            {
              label: t('Agent:SharingAgent'),
              value: sharingAgCode + ' ' + sharingAgName,
              colLength: '25%'
            },
            {
              label: t('contact:MobilePhone'),
              value: !!policyAgent && policyAgent?.sharingAgentMobilePhone ? policyAgent?.sharingAgentMobilePhone : '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: t('Agent:UnitAgent'),
              value: unitManagerCode + ' ' + unitManagerName,
              colLength: '25%'
            },
            {
              label: t('contact:MobilePhone'),
              value: policyAgent?.unitManagerMobilePhone ? policyAgent?.unitManagerMobilePhone : '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: t('Agent:BranchManager'),
              value: branchManagerCode + ' ' + branchManagerName,
              colLength: '25%'
            },
            {
              label: t('contact:MobilePhone'),
              value:
                !!policyAgent && policyAgent?.branchManagerMobilePhone ? policyAgent?.branchManagerMobilePhone : '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: t('Agent:BDM'),
              value: !!policyAgent && policyAgent?.BDM ? policyAgent?.BDM : '',
              colLength: '25%'
            },
            {
              label: t('Agent:Region'),
              value: !!policyAgent && policyAgent?.BDD ? policyAgent?.BDD : '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            },
            {
              label: '',
              value: '',
              colLength: '25%'
            }
          ],
          oldAgentInfo: servicingHistory
            ? servicingHistory.map((x) => ({
                agentCode: !!x && x.agentCode ? x.agentCode : '-',
                agentName: !!x && x?.agentName ? getName(x.agentName) : '-',
                fromDate: !!x && x?.fromDate ? x.fromDate : '-',
                toDate: !!x && x?.toDate ? x.toDate : '-'
              }))
            : []
        }
      })
    )

  const getName = (item: { firstName?: string; surName?: string; middleName?: string } | null) => {
    if (!!item) {
      return item.surName + ' ' + item.middleName + ' ' + item.firstName
    }

    return ''
  }

  const { agentInfo, oldAgentInfo } = pipe(
    props.data ? ZIO.succeed(props.data) : getDataAgent(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    agentInfo: [],
    oldAgentInfo: []
  }

  const dataAgentServices = [
    {
      key: '1',
      title: cep('AgentCode'),
      name: 'agentCode'
    },
    {
      key: '2',
      title: cep('AgentName'),
      name: 'agentName'
    },
    {
      key: '3',
      title: cep('FromDate'),
      name: 'fromDate'
    },
    {
      key: '4',
      title: cep('ToDate'),
      name: 'toDate'
    }
  ]

  const existAgentInfo = agentInfo.length > 0

  return (
    <Container loading={loading}>
      {existAgentInfo && (
        <Panel title={t('Agent:agentInfo')}>
          <CepFieldList dataSource={agentInfo} />
        </Panel>
      )}
      <Panel title={cep('AgentServicingHistory')}>
        <CepTableField columns={dataAgentServices} dataSource={oldAgentInfo} name="AgentServicingHistory" />
      </Panel>
    </Container>
  )
}
