import React from 'react'
import {
  Modal,
  TouchableOpacity,
  useWindowDimensions,
  View,
  StyleSheet,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle
} from 'react-native'
import { assets } from '../../assets'
import { useMobile } from '../../useMobile'
import { RoundedButton } from '../rounded-button'
import { CanView } from './common/CanView'
import { Toast } from '../toast'
import { Color } from '@material-ui/lab'

interface ToastOption {
  open: boolean
  message: string
  type?: Color
}

export interface ModalComponentRef {
  showToast: (message: string, type?: Color) => void
}

export interface ModalAction {
  text: string
  type: 'filled' | 'outline'
  disabled: boolean
  loading: boolean
  onlyWide: boolean
  style: StyleProp<ViewStyle>
  action: () => void
  hideButton?: boolean
}

interface ModalComponentProps {
  children: any
  visible: boolean
  onClose: () => void
  title: string
  titleStyle?: StyleProp<TextStyle>
  contentStyle?: StyleProp<ViewStyle>
  modalWidth?: number | string
  modalHeight?: number | string
  actions: ModalAction[]
  showCloseIcon?: boolean
  centerTitle?: boolean
  buttonGroupStyle?: StyleProp<TextStyle>
}

const _ModalComponent = (
  {
    title,
    titleStyle,
    children,
    visible,
    onClose,
    actions,
    modalWidth = 600,
    modalHeight,
    contentStyle,
    showCloseIcon = true,
    centerTitle,
    buttonGroupStyle
  }: ModalComponentProps,
  ref: React.Ref<ModalComponentRef>
) => {
  const { isWide, isMobile } = useMobile()
  const { width, height } = useWindowDimensions()

  const [toast, setToast] = React.useState<ToastOption>({ open: false, message: '' })

  // showToastInternal: (message: string, type: Color | undefined = undefined) => {
  //   setToast({ open: true, message, type })
  // }

  React.useImperativeHandle(
    ref,
    () => ({
      showToast: (message: string, type?: Color) => setToast({ open: true, message, type })
    }),
    []
  )

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.25)',
          justifyContent: isWide ? 'center' : 'flex-end'
        }}
      >
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onClose}
            style={{
              position: 'absolute',
              width: width,
              height: height
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              borderRadius: 8,
              width: isWide ? modalWidth : '100%',
              height: modalHeight,
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0,
              overflow: 'hidden',
              maxHeight: '90%'
            }
          ]}
        >
          {showCloseIcon ? (
            <View style={[ centerTitle ? styles.headerCenterTitle : styles.headerContainer , { marginVertical: isWide ? 20 : 16 }]}>
              <Text style={[styles.headerTitle, titleStyle]}>{title}</Text>
              <CanView condition={isWide}>
                <TouchableOpacity style={styles.btnClose} activeOpacity={0.7} onPress={onClose}>
                  <assets.CloseTaskModalIcon />
                </TouchableOpacity>
              </CanView>
            </View>
          ) : (
            <></>
          )}

          <View style={[styles.contentStyle, contentStyle]}>
            {children}
            {/* Apply Button */}

            <View
              style={[
                styles.btnGroup,
                {
                  marginBottom: 30,
                  marginTop: isWide ? 30 : 28,
                  marginHorizontal: isWide ? 0 : 16
                },
                buttonGroupStyle
              ]}
            >
              {actions.map((x, i) => (
                <CanView condition={x.onlyWide ? isWide : true} key={`actions=${i}`}>
                  {!x.hideButton && (
                    <RoundedButton
                      showBorder={x.type === 'outline'}
                      filled={x.type === 'filled'}
                      activeOpacity={0.7}
                      text={x.text}
                      textStyle={styles.btnTitle}
                      style={x.style}
                      textColorDisable={x.type === 'filled' ? '#FFFFFF' : '#B0B0B0'}
                      textColorEnable={x.type === 'filled' ? '#FFFFFF' : '#ED1B2E'}
                      bgColorDisable={'#B0B0B0'}
                      bgColorEnable={'#ED1B2E'}
                      borderColorDisable={'#B0B0B0'}
                      borderColorEnable={'#ED1B2E'}
                      onPress={x.action}
                      disabled={x.disabled}
                    />
                  )}
                </CanView>
              ))}
            </View>
          </View>
        </View>
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </Modal>
  )
}

export const ModalComponent = React.forwardRef(_ModalComponent)

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF'
  },

  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20
  },

  headerCenterTitle: {
    alignItems: 'center',
    marginHorizontal: 20
  },

  headerTitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  headerTitle: {
    flex: 1,
    fontWeight: '600',
    color: '#4F4F4F',
    fontSize: 20,
    marginEnd: 16
  },

  contentStyle: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },

  taskId: {
    color: '#828282',
    fontSize: 14
  },

  btnClose: {
    position: 'absolute',
    end: 0,
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  noti: {
    color: '#B0B0B0',
    fontSize: 13,
    marginTop: 11,
    marginHorizontal: 20
  },

  noMessage: {
    color: '#B0B0B0',
    fontSize: 13,
    marginTop: 11,
    textAlign: 'center'
  },

  emptyList: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  mesageContainer: {
    backgroundColor: '#FAFAFA',
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 15,
    paddingBottom: 12,
    marginBottom: 16
  },

  messageInfo: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  message: {
    color: '#000000',
    fontSize: 13,
    marginTop: 12
  },

  sender: {
    color: '#333333',
    fontSize: 13,
    fontWeight: 'bold',
    marginEnd: 12
  },

  sentDate: {
    color: '#70777E',
    fontSize: 13
  },

  separateLine: {
    height: 1,
    backgroundColor: '#C4C4C4'
  },

  input: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
