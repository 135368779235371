import { SubmissionService } from './SubmissionService'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { ZIO } from '@mxt/zio'
import { EServiceModel } from './model'
export namespace EServiceService {
  export const getOwnerInfo = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<EServiceModel.Detail>{}),
            (clientId) =>
              pipe(
                SubmissionService.getCustomer(clientId),
                ZIO.tap((value) => {
                  console.log(value)
                  return ZIO.unit
                }),
                ZIO.map(
                  (client): EServiceModel.Detail => ({
                    ownerName: client.body.name ?? '',
                    nationalId: client.body.externalIds.SOE_VALUE ?? '',
                    email: client.body.email ?? '',
                    birthDate: client.body.dob ?? '',
                    gender: client.body.sex ?? '',
                    clientPhoneNumber: client.body.mobilePhone ?? '',
                    clientPhoneCode: client.body.attributesExt.MOBILE_CODE ?? ''
                  })
                )
              )
          )
        )
      )
    )
}
