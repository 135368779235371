import 'react-native-gesture-handler'

import { registerRootComponent } from 'expo'

import { initI18n, firebase } from '@pulseops/common'
import { App } from './App'

initI18n()

firebase.initApp()

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App)
