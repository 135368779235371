import { FileUploadData, SelectOption } from '@pulseops/common'

export namespace CancelFromInceptionFormData {
  export interface CancelFromInception {
    reason: SelectOption
    attachmentFiles: FileUploadData[]
    currency: string
    totalPayoutAmount: number
    installmentPremium: string
    advancePremium: string
    suspenseAmount: string
    medicalFee: string
    pwAmount: string
    netAmountAvailable: string
    topUpAmount: string
  }
}
