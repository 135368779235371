import { ZIO } from '@mxt/zio'
import { IconButton, makeStyles, Tab, Tabs } from '@material-ui/core'
import { BusinessStackParamList } from '@pulseops/business'
import { assets, EditFooter, EditFooterService, Text, View } from '@pulseops/business/core'
import { AppContext, subscribe, RBAC, Permission, ErrorHandling } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { animated, useSpring } from '@react-spring/native'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import { ContractDetailContext } from './contract-detail-context'
import { GeneralInfo } from './general-info/general-info'
import { Issue } from './issue/issue'
import { PremiumCheck } from './premium-check/premium-check'
import { Underwriting } from './underwriting/underwriting'
import { Verification } from './verification/verification'
import { Report } from './report/report'

type Props = StackScreenProps<BusinessStackParamList, 'ContractDetailScreen'>

enum TabId {
  GeneralInfo,
  Verification,
  Underwriting,
  PremiumCheck,
  Issue,
  Report,
  Claim
}

type Tab = {
  id: TabId
  title: string
  role: Permission
  element: () => JSX.Element
}

const tabs: Tab[] = [
  {
    id: TabId.GeneralInfo,
    title: 'general_info',
    role: Permission['ViewGeneralInfoGroupDetail'],
    element: () => <GeneralInfo />
  },
  {
    id: TabId.Verification,
    title: 'verification',
    role: Permission['ViewVerGroupDetail'],
    element: () => <Verification />
  },
  {
    id: TabId.Underwriting,
    title: 'underwriting',
    role: Permission['ViewUnderwritingGroupDetail'],
    element: () => <Underwriting />
  },
  {
    id: TabId.PremiumCheck,
    title: 'premium_check',
    role: Permission['ViewPremiumGroupDetail'],
    element: () => <PremiumCheck />
  },
  {
    id: TabId.Issue,
    title: 'issue',
    role: Permission['ViewIssuanceGroupDetail'],
    element: () => <Issue />
  },
  {
    id: TabId.Report,
    title: 'report',
    role: Permission['ViewReportGroupDetail'],
    element: () => <Report />
  }
]

export const ContractDetailScreen = (props: Props) => {
  const { t } = useTranslation('business')
  const { t: menu } = useTranslation('menu')

  pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      const activeTabs = tabs.filter((tab) => permissions.includes(tab.role))
      setActiveTabs(activeTabs)
      setSelected(activeTabs[0] || null)
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const { masterContractNo, policyYear } = props.route.params

  const [selected, setSelected] = React.useState<Tab | null>(null)
  const [activeTabs, setActiveTabs] = React.useState<Tab[]>([])

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const classes = useStyles(props)

  const animatedProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => props.navigation.replace('HomeScreen')
        },
        {
          title: menu('BackOffice'),
          navigate: () => props.navigation.navigate('BusinessScreen')
        },
        {
          title: t('ContractList'),
          navigate: () => props.navigation.navigate('ContractListScreen')
        },
        { title: t('ContractDetail'), navigate: null }
      ])
    }
  }, [isFocused])

  const editFooterShowing: boolean = pipe(
    EditFooterService.footerState.watch((s) => s.showing),
    subscribe(false)
  )

  if (!masterContractNo || !policyYear) {
    return null
  }

  return (
    <ContractDetailContext.Provider
      value={{
        contractId: {
          masterContractNo,
          policyYear: parseInt(policyYear)
        }
      }}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <SC.ScrollView>
          <SC.Header>
            <assets.IconDocument style={{ marginRight: 10 }} />
            <Text>{t('proposal_no')}: </Text>
            <SC.HighlightText>{masterContractNo}</SC.HighlightText>
          </SC.Header>
          <SC.TabRow>
            <Tabs
              value={selected?.id}
              variant="scrollable"
              scrollButtons="auto"
              ScrollButtonComponent={(props) =>
                props.direction === 'left' && props.disabled ? (
                  <IconButton {...props}>
                    <assets.IconArrowLeftCircleGray />
                  </IconButton>
                ) : props.direction === 'left' && !props.disabled ? (
                  <IconButton {...props}>
                    <assets.IconArrowLeftCircleRed />
                  </IconButton>
                ) : props.direction === 'right' && props.disabled ? (
                  <IconButton {...props}>
                    <assets.IconArrowRightCircleGray />
                  </IconButton>
                ) : props.direction === 'right' && !props.disabled ? (
                  <IconButton {...props}>
                    <assets.IconArrowRightCircleRed />
                  </IconButton>
                ) : null
              }
              TabIndicatorProps={{ style: { display: 'none' } }}
              style={{ minHeight: '30px' }}
            >
              {selected &&
                activeTabs.map(({ id, title }) => (
                  <Tab
                    label={<SC.TabText selected={selected.id === id}>{t(title)}</SC.TabText>}
                    key={id.toString()}
                    selected={selected.id === id}
                    disabled={selected.id === id}
                    onClick={() => {
                      setSelected(activeTabs.find((tab) => tab.id === id) || null)
                    }}
                    className={selected.id === id ? `${classes.root} ${classes.selected}` : `${classes.root}`}
                    disableRipple
                  />
                ))}
            </Tabs>
          </SC.TabRow>
          <SC.TabPanel style={animatedProps}>{selected?.element()}</SC.TabPanel>
        </SC.ScrollView>
        <EditFooter showing={editFooterShowing} />
      </SafeAreaView>
    </ContractDetailContext.Provider>
  )
}

export const SC = {
  ScrollView: styled(ScrollView).attrs({
    contentContainerStyle: {
      flex: 1
    }
  })``,
  Header: styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem;
    padding: 1rem 2rem;
    border-radius: 8px;
    background: #ffffff;
  `,
  HighlightText: styled(Text)`
    color: ${(props) => props.theme.colors.main};
  `,
  TabRow: styled(View)`
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    height: 30px;
  `,
  TabText: styled(Text)<{ selected?: boolean }>`
    color: ${(props) => (props.selected ? props.theme.colors.main : '#4F4F4F')};
    font-weight: ${(props) => (props.selected ? '700' : '400')};
    font-size: 14px;
    text-transform: none;
  `,
  TabPanel: styled(animated(View))`
    background: #ffffff;
    flex: 1;
    opacity: 0;
  `
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '180px',
    minHeight: '30px',
    background: '#D3DCE6',
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
    paddingTop: '6px',
    paddingBottom: '6px',
    textAlign: 'center',
    opacity: '1 !important'
  },
  selected: {
    background: '#FFFFFF'
  }
}))
