import { FieldList, Panel, PulseOpsFormat, sharedStyle, TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

export const FullSurrenderReversal = ({ detail }: { detail: TaskDetail.VeriFullSurrenderReversalData }) => {
  const { t } = useTranslation()
  const { submissionData } = detail

  const fields = [
    {
      label: t('Payout:ReinstatementReason'),
      value: submissionData.attributesExt.REASON
    },
    {
      label: t('FSR:SurrenderValue'),
      value: PulseOpsFormat.thousandSepartor(Number(submissionData.surrenderValue)),
      suffix: 'VND'
    },
    {
      label: t('FSR:TotalPaidCashBenefits'),
      value: PulseOpsFormat.thousandSepartor(Number(submissionData.attributesExt.PAID_CASH_BENEFITS)),
      suffix: 'VND'
    },
    {
      label: t('FSR:PolicyLoans'),
      value: PulseOpsFormat.thousandSepartor(Number(submissionData.attributesExt.POLICY_LOANS)),
      suffix: 'VND'
    },
    {
      label: t('FSR:NotPaidPremium'),
      value: PulseOpsFormat.thousandSepartor(Number(submissionData.attributesExt.NOT_PAID_PREMIUM)),
      suffix: 'VND'
    },
    {
      label: t('requestInfo:RequirePayinAmount'),
      value: PulseOpsFormat.thousandSepartor(Number(submissionData.attributesExt.REINSTATEMENT_FEE)),
      suffix: 'VND',
      isHighlight: true
    }
  ]

  const radioOptions = [
    { label: t('FSR:Change'), id: 'Y' },
    { label: t('FSR:NotChange'), id: 'N' }
  ]

  const LAList: {
    clientName: string
    fieldsets: FieldList.FieldType[]
  }[] = submissionData.lifeAssureds.map((x) => {
    const occupation: FieldList.FieldType[] =
      x.attributesExt.O_ANSWER === 'Y'
        ? [
            {
              label: t('FSR:CurrentOccupation'),
              value: x.attributesExt.oldOccupationName,
              colLength: '50%',
              required: true
            },
            {
              label: t('FSR:NewOccupation'),
              value: x.attributesExt.newOccupationName,
              colLength: '50%',
              required: true
            }
          ]
        : []
    const activity: FieldList.FieldType[] =
      x.attributesExt.A_ANSWER === 'Y'
        ? [
            {
              label: t('FSR:NewActivity'),
              value: x.attributesExt.NEW_ACTIVITY,
              colLength: '100%',
              required: true
            }
          ]
        : []
    const company: FieldList.FieldType[] =
      x.attributesExt.C_ANSWER === 'Y'
        ? [
            {
              label: t('requestInfo:CompanyName'),
              value: x.attributesExt.COMPANY_NAME,
              colLength: '100%',
              required: true
            }
          ]
        : []
    return {
      clientName: x.attributesExt.clientName,
      fieldsets: [
        {
          label: t('FSR:InStateOfHealth'),
          value: x.attributesExt.H_ANSWER ?? '-',
          fieldType: 'radio',
          radioOptions,
          colLength: '100%',
          required: true
        },
        {
          label: t('FSR:InOccupation'),
          value: x.attributesExt.O_ANSWER ?? '-',
          fieldType: 'radio',
          radioOptions,
          colLength: '100%',
          required: true
        },
        ...occupation,
        {
          label: t('FSR:InActivities'),
          value: x.attributesExt.A_ANSWER ?? '-',
          fieldType: 'radio',
          radioOptions,
          colLength: '100%',
          required: true
        },
        ...activity,
        {
          label: t('FSR:InCompanies'),
          value: x.attributesExt.C_ANSWER ?? '-',
          fieldType: 'radio',
          radioOptions,
          colLength: '100%',
          required: true
        },
        ...company
      ]
    }
  })

  return (
    <View>
      <Panel titleOutline={t('FSR:Info')} isExand={false} allowToggle={false}>
        <FieldList dataSource={fields} />
      </Panel>
      <Text style={[sharedStyle.sectionInfoHeaderTitle, sharedStyle.titleOutline]}>
        {t('FSR:STATEMENTOFINSURABILITY')}
      </Text>

      {LAList.length > 0 &&
        LAList.map((x, i) => (
          <Panel title={x.clientName} isExand={true} allowToggle={true} key={`panelFSR-${i}`}>
            <FieldList dataSource={x.fieldsets} />
          </Panel>
        ))}
    </View>
  )
}
