import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import {
  ModalComponent,
  useMobile,
  Select,
  Toast,
  ErrorHandling,
  SelectOption,
  RadioButtonGroup,
  AppContext
} from '@pulseops/common'
import { isEmpty } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ManualGetCase, OBGetCasesService } from '../../../ob-service'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Color } from '@material-ui/lab'

type OBGetCases = {
  visible: boolean
  onConfirm: (res: ManualGetCase) => void
  onCancel: () => void
}

type OBGetCasesForm = {
  transactionType: SelectOption | null
  adHocCode: SelectOption | null
  maxCaseType: string
}

const trans = 'Outbound:OBGetCasesPopup'

export const OBGetCasesPopup = (props: OBGetCases) => {
  const { visible, onConfirm, onCancel } = props
  const { t } = useTranslation()
  const { isWide } = useMobile()
  const [loading, bindLoading] = useLoading(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const { control, trigger, getValues, watch } = useForm<OBGetCasesForm>({
    defaultValues: {
      transactionType: null,
      maxCaseType: '0'
    }
  })

  const [adhocOption, setAdhocOption] = React.useState<{
    label: string,
    value: string
  }[]>([])
  const [isAdhocCS, setIsAdhocCS] = React.useState<boolean>(false)
  const [isDisableGetCase, setDisableGetCase] = React.useState<boolean>(true)

  const options = pipe(
    OBGetCasesService.getAuthorizedTransactions(),
    ZIO.map((res) => res.map((elm) => ({ label: elm, value: elm }))),
    ErrorHandling.runDidMount()
  )

  const getOptionsAdHocCode = () => {
    const trans = watch('transactionType')?.value
    const isCS = trans === 'ADHOC_CS'
    if(checkAdHoc(trans) && (isCS !== isAdhocCS || adhocOption.length === 0)) {
      pipe(
        OBGetCasesService.getAdHocCode(isCS),
        ZIO.map((res) => setAdhocOption(res.map((elm) => ({ label: (elm.name ?? '') + ' - ' + (elm.code ?? ''), value: elm.code ?? '' })))),
        ZIO.unsafeRun({})
      )
      setIsAdhocCS(isCS)
    }
  }

  const showToastInternal = (message: string, type: Color | undefined = undefined) =>
    setToast({ open: true, message, type })

  const checkAdHoc = (value: string | undefined) => {
    if (value === 'ADHOC_CS' || value === 'ADHOC_CCE') return true
    return false
  }

  const checkDisableGetCase = () => {
    const trans = watch('transactionType')?.value
    const subTrans = watch('adHocCode')
    if (checkAdHoc(trans)) {
      if (!!subTrans && trans) setDisableGetCase(false)
      else setDisableGetCase(true)
      return
    }
    if (trans) setDisableGetCase(false)
    else setDisableGetCase(true)
  }

  const renderModalBody = () => {
    return (
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View>
          <Controller
            name={'transactionType'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020001', {
                  field: t(`${trans}.CallProgram`)
                })
              }
            }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <Select
                required
                label={t(`${trans}.CallProgram`)}
                options={options ?? []}
                onChange={(value) => {
                  onChange(value)
                  checkDisableGetCase()
                  getOptionsAdHocCode()
                }}
                value={value as SelectOption}
                errorMessage={error && error.message}
                onBlur={onBlur}
              />
            )}
          />
          {checkAdHoc(watch('transactionType')?.value) &&
            <View style={{ paddingTop: 24 }}>
              <Controller
                name={'adHocCode'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', {
                      field: t(`${trans}.SubCallProgram`)
                    })
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t(`${trans}.SubCallProgram`)}
                    options={adhocOption ?? []}
                    onChange={(value) => {
                      onChange(value)
                      checkDisableGetCase()
                    }} 
                    value={value as SelectOption}
                    errorMessage={error && error.message}
                    onBlur={onBlur}
                    selectStyle={{ maxWidth: 550 }}
                    menuStyle={{ maxHeight: '70%' }}
                  />
                )}
              />
            </View>}
        </View>

        <View style={{ marginTop: 24 }}>
          <Controller
            name="maxCaseType"
            control={control}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <RadioButtonGroup
                required
                disabled={false}
                title={t(`${trans}.Volumn`)}
                style={{ flexDirection: 'column', width: '100%', padding: 0 }}
                titleStyle={{ display: 'flex', marginRight: 24 }}
                options={[
                  { id: '0', label: t(`${trans}.OnePolicy`) },
                  { id: '1', label: t(`${trans}.MaxPolicies`) }
                ]}
                value={value}
                errorMessage={!isEmpty(error) ? error?.message : ''}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </View>
      </View>
    )
  }

  const handleGet = async () => {
    const isValid = await trigger()
    if (!isValid) return

    const { transactionType, maxCaseType, adHocCode } = getValues()
    const payload = {
      transactionType: transactionType?.value ?? '',
      isGetMaxCase: maxCaseType === '1',
      adHocCode: checkAdHoc(transactionType?.value) ? (adHocCode?.value ?? '') : undefined
    }

    return pipe(
      OBGetCasesService.manualGetCase({ ...payload }),
      ZIO.map((res) => {
        const { status = 'FAILED', errorCode, errorMessage } = res
        if (status === 'FAILED') {
          if (errorCode === 'OB0084') {
            return showToastInternal(t('message:OB0084') || '', 'error')
          }
          if (errorCode === 'OB0083') {
            return showToastInternal(t('message:OB0083') || '', 'error')
          }
          return console.log('error:', errorMessage)
        } else {
          showToast(t('message:OB0088'), 'success')
        }
        return onConfirm?.(res)
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  return (
    <ModalComponent
      title={t('TaskManagement:GetCases')}
      visible={visible}
      onClose={onCancel}
      actions={[
        {
          text: t(`${trans}.Cancel`),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15, width: 150 },
          action: onCancel
        },
        {
          text: t(`${trans}.Get`),
          type: 'filled',
          disabled: loading || isDisableGetCase,
          loading: loading,
          onlyWide: false,
          style: { height: 39, width: 150 },
          action: handleGet
        }
      ]}
    >
      {renderModalBody()}
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
