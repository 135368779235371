import { withStyles } from '@material-ui/styles'
import { BorderContainer, Table, Text, View } from '@pulseops/business/core'
import { Link } from '@react-navigation/native'
import styled from 'styled-components/native'

export const StrongText = styled(Text)`
  font-size: 13px;
  font-weight: 700;
`

export const SC = {
  Container: styled(View)`
    padding: 1rem;
    background: white;
  `,
  BorderContainer: styled(BorderContainer)`
    background: #fafafa;
  `,
  SubtitleContainer: styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
  `,
  Subtitle: styled(StrongText)`
    color: #70777e;
    padding-right: 10px;
  `,
  RowLabel: styled(Text)`
    font-size: 13px;
    font-weight: 500;
  `,
  BlueLabel: styled(StrongText)`
    color: #3994f2;
  `,
  RedLabel: styled(Text)`
    color: #ed1b2e;
  `,
  Error: styled(Text)`
    color: #ed1b2e;
    font-size: 11px;
    font-weight: 400;
    font-style: italic;
    white-space: nowrap;
  `,
  StarDot: styled(StrongText)`
    color: #ed1b2e;
  `,
  TableCell: withStyles({
    root: {
      paddingLeft: 0
    }
  })(Table.Cell),
  TableCellText: withStyles({
    root: {
      paddingLeft: 0,
      paddingTop: 25
    }
  })(Table.Cell),
  TableCellNoBorderBottom: withStyles({
    root: {
      paddingLeft: 0,
      borderBottom: 'none'
    }
  })(Table.Cell),
  TableCellNoBorderTop: withStyles({
    root: {
      paddingLeft: 0,
      borderTop: 'none'
    }
  })(Table.Cell),
  TableCellHead: withStyles({
    root: {
      paddingLeft: 0
    }
  })(Table.CellHead),
  FlexRow: styled(View)`
    display: flex;
    flex-direction: row;
  `,
  Link: styled(Link)`
    text-decoration-line: underline;
    color: #1ea5fc;
  `,
  BoldText: styled(Text)<{ color?: string }>`
    font-weight: bold;
    color: ${(p) => p.color || 'grey'};
    font-size: 15px;
  `,
  TitleText: styled(Text)<{ fontSize?: number }>`
    font-weight: bold;
    color: #70777e;
    font-size: ${(p) => (p.fontSize ?? 15) + 'px'};
  `,
  Divider: styled(View)<{ height?: number; width?: number; spacer?: boolean }>`
    width: ${(p) => (p.width ?? 10) + 'px'};
    height: ${(p) => (p.height ?? 10) + 'px'};
  `,
  WarpperFixed: styled(View)`
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #f9f9f9;
    box-shadow: 0px -5px 24px rgba(0, 0, 0, 0.1);
  `
}
