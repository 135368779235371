import { assets, TaskCount, useMobile } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View, TouchableOpacity, StyleSheet, TextInput, ViewStyle } from 'react-native'
import { CanView } from '../../../common/src'

interface Props {
  value?: string
  hideInput?: boolean
  isFilterApplied?: boolean
  containerStyle?: ViewStyle
  inputContainerStyle?: ViewStyle
  onPressFilter?: () => void
  onChangeSearchText?: (text: string) => void
  onPressSearch: () => void
  taskGroupSelected: TaskCount | undefined
}

export const SearchFilterInput = ({
  containerStyle = {},
  inputContainerStyle = {},
  hideInput = false,
  isFilterApplied = false,
  value = '',
  onPressFilter,
  onChangeSearchText,
  onPressSearch,
  taskGroupSelected
}: Props) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation(['TaskManagement', 'DistributionService'])
  return (
    <View>
      {taskGroupSelected?.type == 'DS' ? (
        <View style={[styles.container, containerStyle]}>
          <CanView condition={!hideInput}>
            <View
              style={[
                styles.searchContainer,
                {
                  height: isMobile ? 28 : 30
                },
                inputContainerStyle
              ]}
            >
              <View style={styles.inputContainer}>
                <TextInput
                  value={value}
                  placeholder={t('eREF...')}
                  placeholderTextColor={'#70777E'}
                  style={[styles.input, { fontSize: isMobile ? 12 : 15 }]}
                  onChangeText={onChangeSearchText}
                  onSubmitEditing={onPressSearch}
                />
              </View>
              <TouchableOpacity
                onPress={onPressSearch}
                activeOpacity={0.7}
                style={[
                  styles.btnSearchContainer,
                  {
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: 18,
                    borderRadius: 20
                  }
                ]}
              >
                <assets.SearchTaskIcon />
              </TouchableOpacity>
            </View>
          </CanView>

          <CanView condition={hideInput}>
            <TouchableOpacity
              activeOpacity={0.7}
              style={[
                styles.btnSearchContainer,
                {
                  height: isMobile ? 28 : 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 18,
                  borderRadius: 20
                }
              ]}
            >
              <assets.SearchTaskIcon />
            </TouchableOpacity>
          </CanView>

          <TouchableOpacity style={styles.btnFilter} onPress={onPressFilter}>
            {isFilterApplied ? <assets.FilterAppliedIcon /> : <assets.FilterIcon />}
          </TouchableOpacity>
        </View>
      ) : (
        <View style={[styles.container, containerStyle]}>
          <CanView condition={!hideInput}>
            <View
              style={[
                styles.searchContainer,
                {
                  height: isMobile ? 28 : 30
                },
                inputContainerStyle
              ]}
            >
              <View style={styles.inputContainer}>
                <TextInput
                  value={value}
                  placeholder={t('Policy')}
                  placeholderTextColor={'#70777E'}
                  style={[styles.input, { fontSize: isMobile ? 12 : 15 }]}
                  onChangeText={onChangeSearchText}
                  onSubmitEditing={onPressSearch}
                />
              </View>

              {/* <CanView condition={isWide}>
              <RoundedButton
                filled
                activeOpacity={0.7}
                text={t('Search')}
                style={styles.btnSearchContainer}
                textStyle={styles.btnSearchTitle}
                onPress={onPressSearch}
              />
            </CanView> */}

              {/* <CanView condition={isMobile}> */}
              <TouchableOpacity
                onPress={onPressSearch}
                activeOpacity={0.7}
                style={[
                  styles.btnSearchContainer,
                  {
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: 18,
                    borderRadius: 20
                  }
                ]}
              >
                <assets.SearchTaskIcon />
              </TouchableOpacity>
              {/* </CanView> */}
            </View>
          </CanView>

          <CanView condition={hideInput}>
            <TouchableOpacity
              activeOpacity={0.7}
              style={[
                styles.btnSearchContainer,
                {
                  height: isMobile ? 28 : 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 18,
                  borderRadius: 20
                }
              ]}
            >
              <assets.SearchTaskIcon />
            </TouchableOpacity>
          </CanView>

          <TouchableOpacity style={styles.btnFilter} onPress={onPressFilter}>
            {isFilterApplied ? <assets.FilterAppliedIcon /> : <assets.FilterIcon />}
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  searchContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: '#D3DCE6',
    backgroundColor: '#F9F9F9'
  },

  inputContainer: {
    flex: 1,
    marginVertical: 4,
    marginStart: 15,
    marginEnd: 10
  },

  input: {
    flex: 1,
    height: '100%',
    fontWeight: '400',
    color: '#70777E'
  },

  btnSearchContainer: {
    backgroundColor: '#ED1B2E',
    height: '100%'
  },

  btnSearchTitle: {
    color: '#FFFFFF',
    fontSize: 10,
    fontWeight: '500',
    marginHorizontal: 9
  },

  btnFilter: {
    marginStart: 11,
    marginEnd: 5
  }
})
