import { AssignmentGroup } from '../AssignmentGroup'

export namespace WfMockData {
  interface Reason {
    code: string
    name: string
    nameVi?: string
  }
  export const reasonsEnd: Array<Reason> = [
    { code: 'Wrong process', name: 'Wrong process' },
    { code: 'System error', name: 'System error' },
    { code: 'Function not available', name: 'Function not available' }
  ]

  export const reasonPCEnd: Array<Reason> = [
    {
      code: 'Refund as Customer requirement',
      name: 'Refund as Customer requirement',
      nameVi: 'Hoàn phí theo yêu cầu khách hàng'
    },
    {
      code: 'Refund as wrongly transferred by Shihan',
      name: 'Refund as wrongly transferred by Shihan',
      nameVi: 'Hoàn phí Shihan chuyển nhầm'
    },
    {
      code: 'Refund as Partner requirement',
      name: 'Refund as Partner requirement',
      nameVi: 'Hoàn phí theo yêu cầu Partner'
    },
    {
      code: 'Refund on Card',
      name: 'Refund on Card',
      nameVi: 'Hoàn tiền thẻ'
    },
    {
      code: 'Other Reasons',
      name: 'Other Reasons',
      nameVi: 'Lý do khác'
    },
    {
      code: `Cancel as Accountant's Requirement`,
      name: `Cancel as Accountant's Requirement`,
      nameVi: 'Hủy theo yêu cầu kế toán'
    }
  ]

  const reasonEndRawMap = [
    {
      category: 'any',
      reasons: reasonsEnd
    },
    {
      category: 'PC',
      reasons: reasonPCEnd
    }
  ]

  const mapReasonEnd: Map<string, Reason[]> = new Map(reasonEndRawMap.map((item) => [item.category, item.reasons]))

  export const reasonEndWithCategory = (category: string) =>
    !!category ? mapReasonEnd.get(category) ?? reasonsEnd : reasonsEnd

  export const reasonOptions: Array<{
    suspendBy: string
    code: string
    name: string
  }> = [
    {
      suspendBy: 'Policy services',
      code: 'SPS02',
      name: 'Addtional document'
    },
    { suspendBy: 'Policy services', code: 'SPS01', name: 'Addtional premium' },
    { suspendBy: 'Policy services', code: 'SPS03', name: 'Await dealing/dayend' },
    { suspendBy: 'Complaint', code: 'SCH01', name: 'Await info and document from customer' },
    { suspendBy: 'Customer services', code: 'SCS01', name: 'Await info from OP function' },
    { suspendBy: 'Complaint', code: 'SCH08', name: 'Await info from OP_ Care' },
    { suspendBy: 'Complaint', code: 'SCH07', name: 'Await info from OP_ Claim' },
    { suspendBy: 'Complaint', code: 'SCH06', name: 'Await info from OP_ PC' },
    { suspendBy: 'Complaint', code: 'SCH04', name: 'Await info from OP_Alteration' },
    { suspendBy: 'Complaint', code: 'SCH10', name: 'Await info from OP_CallCenter' },
    { suspendBy: 'Complaint', code: 'SCH11', name: 'Await info from OP_IT' },
    { suspendBy: 'Complaint', code: 'SCH02', name: 'Await info from OP_NB' },
    { suspendBy: 'Complaint', code: 'SCH09', name: 'Await info from OP_OPL' },
    { suspendBy: 'Complaint', code: 'SCH05', name: 'Await info from OP_Payout' },
    { suspendBy: 'Complaint', code: 'SCH03', name: 'Await info from OP_UW' },
    { suspendBy: 'Customer services', code: 'SCS02', name: 'Await info from other department' },
    { suspendBy: 'Complaint', code: 'SCH15', name: 'Await info from Other_Actuarial' },
    { suspendBy: 'Complaint', code: 'SCH14', name: 'Await info from Other_Customer Experience' },
    { suspendBy: 'Complaint', code: 'SCH12', name: 'Await info from Other_Legal' },
    { suspendBy: 'Complaint', code: 'SCH13', name: 'Await info from Other_Media' },
    { suspendBy: 'AML', code: 'AML01', name: 'FISERV/Red-Flag' },
    { suspendBy: 'Underwriting', code: 'SUW01', name: 'ME/IS (Medical/Information Required)' }
  ]

  export const UserGroups = [
    {
      value: 'Tnt.hieu@prudential.com.vn',
      label: 'Trần Nguyên Trung Hiếu'
    },
    {
      value: 'Ngo.thi.loc@prudential.com.vn',
      label: 'Ngô Thị Lộc'
    },
    {
      value: 'Ly.Hoang.Long@prudential.com.vn',
      label: 'Lý Hoàng Long'
    },
    {
      value: 'Truong.Minh.Tung@prudential.com.vn',
      label: 'Trương Minh Tùng'
    },
    {
      value: 'Nguyen.Thi.Kim.Ngan.IT@prudential.com.vn',
      label: 'Nguyễn Thị Kim Ngân'
    },
    {
      value: 'Truong.Thi.Kim.Oanh@prudential.com.vn',
      label: 'Trương Thị Kim Oanh'
    },
    {
      value: 'nguyen.h.huong@prudential.com.vn',
      label: 'Nguyễn Hoàng Hưởng'
    },
    {
      value: 'dtl.huong@prudential.com.vn',
      label: 'Đỗ Thị Lan Hương'
    },
    {
      value: 'Ho.Quang.Dung@prudential.com.vn',
      label: 'Hồ Quang Dũng'
    }
  ]
  export const mapAssignmenGroup = new Map<AssignmentGroup, string>([
    [AssignmentGroup.PayoutMinor, 'Payout Minor'],
    [AssignmentGroup.PolicyManagementMinor, 'Policy Management Minor'],
    [AssignmentGroup.CustomerServices, 'Customer Services'],
    [AssignmentGroup.ComplaintHandling, 'Complaint Handling']
  ])

  export const AssignmentGroups = [
    {
      value: AssignmentGroup.PayoutMinor,
      label: mapAssignmenGroup.get(AssignmentGroup.PayoutMinor) ?? ''
    },
    {
      value: AssignmentGroup.PolicyManagementMinor,
      label: mapAssignmenGroup.get(AssignmentGroup.PolicyManagementMinor) ?? ''
    },
    {
      value: AssignmentGroup.CustomerServices,
      label: mapAssignmenGroup.get(AssignmentGroup.CustomerServices) ?? ''
    },
    {
      value: AssignmentGroup.ComplaintHandling,
      label: mapAssignmenGroup.get(AssignmentGroup.ComplaintHandling) ?? ''
    }
  ]
}
