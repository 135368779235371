import { ImgUploadMutiple, Input, InquiryComplaintData, Panel, Title } from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { ComplainInquiryForm } from './complain-inquiry-form'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail: InquiryComplaintData.DetailData | null
}

export const CommunicationInfo: React.FC<Props> = ({ detail, form }) => {
  const { t } = useTranslation(['requestInfo', 'roles'])

  const { control } = form

  return (
    <View>
      <Title title={t('CommunicationInfo')} />
      <Panel title="">
        <View style={styles.colFull}>
          <Controller
            name="communicationInfo.customerComments"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('requestInfo:CustomerComments')}
                required
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                maxLength={1000}
                multiline
                numberOfLines={5}
              />
            )}
          />
        </View>
        <View style={styles.colFull}>
          <Controller
            name="communicationInfo.responsesToCustomer"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('ResponseToCustomer')}
                required
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                maxLength={1000}
                multiline
                numberOfLines={5}
              />
            )}
          />
        </View>
        <View style={styles.colFull}>
          <View style={[styles.row, styles.mB5]}>
            <Text style={[styles.mR5, styles.label]}>{t('requestInfo:Attachment')}</Text>
          </View>
          <Controller
            control={control}
            defaultValue={[]}
            name="communicationInfo.attachmentFiles"
            render={({ field, fieldState: { error } }) => (
              <Column>
                <ImgUploadMutiple {...field} value={field.value as any[]} errorMessage={error?.message || ''} />
                {error?.message && <Error message={error.message} />}​​​
              </Column>
            )}
          />
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  required: {
    color: '#ed1b2e'
  },
  label: {
    fontSize: 15,
    lineHeight: 20,
    color: '#70777e',
    fontWeight: 'bold'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
