import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export const PolicyInquiry = t.type({
  product: Maybe(
    t.type({
      code: t.string
    })
  ),
  customerRoles: Maybe(
    t.array(
      t.type({
        role: Maybe(t.string),
        customer: t.type({
          firstName: t.string,
          surName: t.string,
          externalIds: t.type({
            NATIONAL_ID: t.string
          }),
          id: t.string,
          dob: t.string,
          addressDetails: t.type({
            Current: t.type({
              line1: t.string,
              line2: Maybe(t.string),
              line3: Maybe(t.string),
              line4: Maybe(t.string),
              country: t.string,
              countryCode: t.string
            })
          }),
          contactDetails: t.type({
            EMAIL: Maybe(
              t.type({
                value: t.string
              })
            ),
            PHONE: Maybe(
              t.type({
                value: Maybe(t.string),
                countryCode: Maybe(t.string)
              })
            ),
            email: Maybe(
              t.type({
                value: t.string
              })
            ),
            phone: Maybe(
              t.type({
                value: Maybe(t.string),
                countryCode: Maybe(t.string)
              })
            )
          })
        })
      })
    )
  ),
  lifeAssured: Maybe(
    t.array(
      t.type({
        surName: Maybe(t.string),
        firstName: Maybe(t.string)
      })
    )
  ),
  sourceOfBusiness: Maybe(t.string),
  status: Maybe(t.string)
})

export type PolicyInquiry = typeof PolicyInquiry
