import React from 'react'
import {
  Select,
  form2,
  ReportTypeList,
  AppContext,
  ErrorHandling,
  ReportType,
  RBAC,
  StorageBlobApi,
  AuthService,
  downloadURI,
  Permission,
  CustomedDateTimePicker,
  SelectOption,
  PulseOpsFormat
  // DatePicker
} from '@pulseops/common'
import * as _ from 'lodash'
import moment from 'moment'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReportForm } from './model/report-form'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ReportsStackParamList } from './ReportsStackParamList'
import { ReportManagementService } from './Service/ReportManagementService'
import { ScrollView, View, StyleSheet, TouchableOpacity, Text, Pressable } from 'react-native'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'
import { ReportService } from './Service'

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 700,
    fontSize: 15,
    width: 'calc(100%/3)',
    paddingRight: 30,
    paddingLeft: 50,
    color: '#70777E'
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontSize: 15,
    // width: 'calc(100%/5)',
    color: '#70777E'
  },
  tableHeaderCellbody: {
    paddingRight: 30,
    paddingLeft: 50
  },
  tableHeaderRow: {
    backgroundColor: 'white'
  },
  tableDelete: {
    fontSize: 15,
    color: '#70777E'
  },
  tableActive: {
    fontSize: 15,
    color: '#74c075'
  },
  tableWaiting: {
    fontSize: 15,
    color: '#FF6F00'
  },
  tableError: {
    fontSize: 15,
    color: 'red'
  }
})

export enum Extension {
  EXCEL = 'xlsx',
  CSV = 'csv'
}

export const TemplateList = [
  {
    label: 'CSV',
    value: Extension.CSV
  }
]

export const TemplateListAssignReport = [
  {
    label: 'CSV',
    value: Extension.CSV
  },
  {
    label: 'EXCEL',
    value: Extension.EXCEL
  }
]

type props = StackScreenProps<ReportsStackParamList, 'ReportManagementScreen'>

export const ReportManagementScreen = ({ navigation }: props) => {
  const { t } = useTranslation()
  const currentDate = new Date()
  // const result = moment(new Date()).subtract(3, 'months')
  const isFocused = useIsFocused()
  const { showGlobalLoading, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const classes = useStyles()
  const [toggleDate, setToggleDate] = React.useState(false)
  const [loading, bindLoader] = useLoading(false)
  const [errorMsg, setErrorMsg] = React.useState<string>('')

  const defaultValues: ReportForm.Raw = {
    reportType: null,
    fromDate: null,
    toDate: null,
    template: {
      value: Extension.CSV,
      label: 'CSV'
    }
  }

  const {
    base: {
      control,
      // formState: { errors },
      watch
    },
    handleSubmit
  } = form2.useForm(ReportForm.codec, {
    defaultValues: defaultValues
  })
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:ReportManagement'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => showGlobalLoading(loading), [loading])

  const handleExport = handleSubmit((data) => {
    const { reportType, fromDate, toDate, template } = data
    // const from = moment(fromDate).format('yyyy/MM/DD')
    // const to = moment(toDate).format('yyyy/MM/DD')
    switch (reportType?.value) {
      // case ReportType.COMPLAINT_HANDLING:
      //   return pipe(
      //     AuthService.userInfo,
      //     ZIO.flatMap((user) =>
      //       ReportManagementService.exportComplaintHandling({
      //         fromDate: from,
      //         toDate: to
      //       })
      //     ),
      //     bindLoader,
      //     ErrorHandling.run()
      //   )
      case ReportType.DATA_CONSERVATION:
        return pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) =>
            ReportManagementService.exportFileConservation({
              fromDate: moment(fromDate).format('DD/MM/yyyy HH:mm:ss'),
              toDate: moment(toDate).format('DD/MM/yyyy HH:mm:59'),
              author: user.email
            })
          ),
          bindLoader,
          ErrorHandling.run()
        )

      case ReportType.INCENTIVE_REPORT:
        return pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) =>
            ReportManagementService.exportIncentiveReport({
              fromDate: moment(fromDate).format('DD/MM/yyyy HH:mm:ss'),
              toDate: moment(toDate).format('DD/MM/yyyy HH:mm:59'),
              author: user.email
            })
          ),
          bindLoader,
          ErrorHandling.run()
        )
      // case ReportType.DATA_EXTRACTION_DAI:
      //   return pipe(
      //     AuthService.userInfo,
      //     ZIO.flatMap((user) =>
      //       ReportManagementService.exportFileManual({
      //         fromDate: from,
      //         toDate: to
      //       })
      //     ),
      //     bindLoader,
      //     ErrorHandling.run()
      //   )
      case ReportType.RAW_DATA: {
        return pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) =>
            ReportManagementService.exportRawData({
              fromDate: moment(fromDate).format('DD/MM/yyyy HH:mm:ss'),
              toDate: moment(toDate).format('DD/MM/yyyy HH:mm:59'),
              author: user.email
            })
          ),
          bindLoader,
          ErrorHandling.run()
        )
      }
      case ReportType.ASSIGNMENT_BY_ON_OFF:
        return pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) => {
            const timeZone = (currentDate.getTimezoneOffset() / 60) * -1
            return ReportManagementService.exportAssignment({
              fromDate: moment(fromDate).format('DD/MM/yyyy HH:mm:ss'),
              toDate: moment(toDate).format('DD/MM/yyyy HH:mm:59'),
              author: user.email,
              timezone: timeZone >= 0 ? "UTC+" + timeZone : "UTC" + timeZone
            })
          }
          ),
          ZIO.flatMap((response) => {
            const fromDateString = moment(fromDate).format('DDMMyyyy')
            const toDateString = moment(toDate).format('DDMMyyyy')
            const currentDate = moment(new Date).format('DDMMyyyy')
            const fileName = "cs_assignment_on_off_" + fromDateString + "_" + toDateString + "_" + currentDate
            const sheetName = "cs_assignment_on_off_" + fromDateString + "_" + toDateString
            const data = response.split('\n')
            let array = new Array()
            data.forEach((item, index) => {
              array[index] = item.split(',')
            })
            return ReportService.exportAssignmenCSV(array, fileName, sheetName, template.value)
          }),
          bindLoader,
          ErrorHandling.run()
        )
      default:
        return pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) =>
            ReportManagementService.exportRawData({
              fromDate: moment(fromDate).format('DD/MM/yyyy HH:mm:ss'),
              toDate: moment(toDate).format('DD/MM/yyyy HH:mm:59'),
              author: user.email
            })
          ),
          bindLoader,
          ErrorHandling.run()
        )
      // return pipe(
      //   AuthService.userInfo,
      //   ZIO.flatMap((user) =>
      //     ReportManagementService.exportComplaintHandling({
      //       fromDate: from,
      //       toDate: to
      //     })
      //   ),
      //   bindLoader,
      //   ErrorHandling.run()
      // )
    }
  })

  const checkMonthsAndExport = (month: number) => {
    const checkNullValue = control._getWatch('fromDate') === null || control._getWatch('toDate') === null
    const dateFrom = PulseOpsFormat.startOfDate(control._getWatch('fromDate'))
    const dateTo = PulseOpsFormat.startOfDate(control._getWatch('toDate'))
    const checkRuleMonth = moment(dateTo).diff(dateFrom, 'months', true) <= month
    if (checkNullValue || checkRuleMonth)
      handleExport()
    else
      showToast(t('message:MS080004', { k: month }), 'error')
  }

  const { listData, permissions } = pipe(
    RBAC.permissions,
    ZIO.flatMap((permissions) => {
      let role = ''
      if (permissions.includes(Permission['RawDataReportManagement'])) {
        role = '1'
      }
      if (permissions.includes(Permission['DataConservationReportManagement'])) {
        role = '2'
      }
      if (permissions.includes(Permission['IncentiveReportReportManagement'])) {
        role = '3'
      }
      // if (permissions.includes(Permission['ReportManagementReports'])) {
      //   role = '4'
      // }
      return ZIO.zipPar(ZIO.succeed(permissions), ReportManagementService.getList({ role: role, start: '0' }))
    }),
    ZIO.map(([permissions, data]) => {
      return { listData: data, permissions }
    }),
    ErrorHandling.runDidMount({ listData: [], permissions: [' '] })
  )

  const dowLoadDoccument = (url: string) =>
    pipe(
      ZIO.zipPar(StorageBlobApi.getRemoteConfig('', ''), AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([storageConfig, token, loginType]) => {
        return pipe(
          ZIO.fromPromise(() => {
            const apiUrl = url.split('?')
            const storageUrl = apiUrl[0]
            const paramInfo = apiUrl[1]
            const paramList = paramInfo.split('&')
            const containerName = paramList[0] ? paramList[0].split('=')[1] : ''
            const blobName = paramList[1] ? paramList[1].split('=')[1] : ''
            return fetch(storageUrl, {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Container: containerName,
                Token: storageConfig.sas,
                Blob: blobName,
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.tap(([res, blob]) => {
        // const type = res.headers.get('content-type') || ''
        const fileDowload = url.split('/')
        downloadURI(window.URL.createObjectURL(blob), `${fileDowload[fileDowload.length - 1]}`)
        return ZIO.unit
      }),
      // bindLoading,
      ErrorHandling.run()
    )

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const filterReportTypeList = React.useMemo(() => {
    let filterTypeList: SelectOption[] = []
    if (permissions.length === 0) return []
    else {
      if (permissions.includes(Permission['RawDataReportManagement'])) {
        // return ReportTypeList.filter((x) => x.value === ReportType.RAW_DATA)
        const reportItem = ReportTypeList.find((x) => x.value === ReportType.RAW_DATA) ?? { value: '', label: '' }
        filterTypeList.push(reportItem)
      }
      if (permissions.includes(Permission['DataConservationReportManagement'])) {
        // return ReportTypeList.filter((x) => x.value === ReportType.DATA_CONSERVATION)
        const reportItem = ReportTypeList.find((x) => x.value === ReportType.DATA_CONSERVATION) ?? {
          value: '',
          label: ''
        }
        filterTypeList.push(reportItem)
      }
      if (permissions.includes(Permission['IncentiveReportReportManagement'])) {
        // return ReportTypeList.filter((x) => x.value === ReportType.INCENTIVE_REPORT)
        const reportItem = ReportTypeList.find((x) => x.value === ReportType.INCENTIVE_REPORT) ?? {
          value: '',
          label: ''
        }
        filterTypeList.push(reportItem)
      }
      //  if (permissions.includes(Permission['ReportManagementReports'])) {
      //   return ReportTypeList
      // }
      if (permissions.includes(Permission['AssignmentONOFFReportReportManagement'])) {
        const reportItem = ReportTypeList.find((x) => x.value === ReportType.ASSIGNMENT_BY_ON_OFF) ?? {
          value: '',
          label: ''
        }
        filterTypeList.push(reportItem)
      }
      return filterTypeList
    }
  }, [permissions, toggleDate])

  const onChangeToDate = (val: Date | null) => {
    if (moment(val).isValid()) {
      if (moment(watch('fromDate')).valueOf() > moment(val).valueOf()) {
        /// set mesage show
        setErrorMsg(t('message:MS030044'))
      } else {
        setErrorMsg('')
      }
    }
  }
  const onChangeFromDate = (val: Date | null) => {
    if (moment(val).isValid()) {
      if (moment(watch('toDate')).valueOf() < moment(val).valueOf()) {
        /// set mesage show
        setErrorMsg(t('message:MS030044'))
      } else {
        setErrorMsg('')
      }
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            <View style={styles.row}>
              <View style={styles.col}>
                <View style={{ flex: 1 }}>
                  <Controller
                    name="reportType"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <Select
                        required
                        label={t('Reports:ReportType')}
                        options={filterReportTypeList}
                        placeholder={t('common:Select')}
                        onChange={(e) => {
                          onChange(e)
                          setToggleDate((prev) => !prev)
                        }}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="fromDate"
                  control={control}
                  render={
                    ({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      // [ReportType.COMPLAINT_HANDLING, ReportType.DATA_EXTRACTION_DAI].includes(
                      //   watch('reportType.value') as ReportType
                      // ) ? (
                      //   <DatePicker
                      //     label={t('Reports:FromDate')}
                      //     onChange={(val) => {
                      //       onChange(val)
                      //     }}
                      //     onBlur={onBlur}
                      //     value={value}
                      //     maxDate={currentDate}
                      //     maxDateMessage={t('message:MS990032')}
                      //     minDate={result}
                      //     errorMessage={error?.message}
                      //     required={true}
                      //   />
                      // ) : (
                      <CustomedDateTimePicker
                        required
                        label={t('Reports:FromDate')}
                        alwaysShow={false}
                        onChange={(val) => {
                          onChange(val)
                          onChangeFromDate(val)
                        }}
                        onBlur={onBlur}
                        value={value}
                        maxDate={currentDate}
                        // minDate={result}
                        hideUnderline={true}
                        errorMessage={error?.message}
                        maxDateMessage={t('message:MS990032')}
                      />
                    )
                    // )
                  }
                />
              </View>
              <View style={styles.col}>
                <Controller
                  name="toDate"
                  control={control}
                  render={
                    ({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      // [ReportType.COMPLAINT_HANDLING, ReportType.DATA_EXTRACTION_DAI].includes(
                      //   watch('reportType.value') as ReportType
                      // ) ? (
                      //   <DatePicker
                      //     label={t('Reports:ToDate')}
                      //     onChange={(val) => {
                      //       onChange(val)
                      //     }}
                      //     onBlur={onBlur}
                      //     value={value}
                      //     maxDate={currentDate}
                      //     maxDateMessage={t('message:MS990032')}
                      //     minDate={result}
                      //     errorMessage={error?.message}
                      //     required={true}
                      //   />
                      // ) : (
                      <CustomedDateTimePicker
                        required
                        label={t('Reports:ToDate')}
                        alwaysShow={false}
                        onChange={(val) => {
                          onChange(val)
                          onChangeToDate(val)
                        }}
                        onBlur={onBlur}
                        value={value}
                        maxDate={currentDate}
                        // minDate={result}
                        hideUnderline={true}
                        errorMessage={error?.message}
                        maxDateMessage={t('message:MS990032')}
                      />
                    )
                    // )
                  }
                />
              </View>
              <View style={styles.col}>
                <View style={{ flex: 1 }}>
                  <Controller
                    name="template"
                    control={control}
                    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                      <Select
                        label={t('Reports:Template')}
                        options={watch('reportType.value') === ReportType.ASSIGNMENT_BY_ON_OFF ? TemplateListAssignReport: TemplateList}
                        // placeholder={t('common:Select')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        required
                        errorMessage={error?.message}
                        labelStyle={{ marginBottom: '8px' }}
                      />
                    )}
                  />
                </View>
              </View>
            </View>

            <View style={styles.row}>
              <TouchableOpacity onPress={() => watch('reportType.value') === ReportType.ASSIGNMENT_BY_ON_OFF ? checkMonthsAndExport(12) : checkMonthsAndExport(3)}>
                <View style={styles.btn}>
                  <Text style={styles.btnText}>{t('Reports:ExportReports')}</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.row, { marginTop: 6 }]}>
              {!!errorMsg && <Text style={{ color: '#ED1B2E' }}>{errorMsg}</Text>}
              {/* <Text style={{ color: '#ED1B2E' }}>{_.get(errors, '.message')}</Text> */}
            </View>
          </View>
        </View>

        {listData.length === 0 || listData.filter((item) => item.status == 0).length == listData.length ? null : (
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <View
              style={{
                marginHorizontal: 16
              }}
            >
              <Text style={{ fontSize: 15, color: 'red' }}>{t('Reports:ReportFile')}</Text>
            </View>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  maxHeight: 350
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#FAFAFA' }}>
                      <TableCell className={classes.tableHeader}>{t('Reports:File')}</TableCell>
                      <TableCell className={classes.tableHeaderCell}>{t('Reports:Createddate')}</TableCell>
                      <TableCell className={classes.tableHeaderCell}>{t('Reports:FromDate')}</TableCell>
                      <TableCell className={classes.tableHeaderCell}>{t('Reports:ToDate')}</TableCell>
                      <TableCell className={classes.tableHeaderCell}>{t('Reports:AuThorized')}</TableCell>
                      <TableCell className={classes.tableHeaderCell}>{t('Reports:Status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { createdDate, filename, author, status, toDate, fromDate } = row
                      return (
                        Number(status) !== 0 && (
                          <TableRow className={classes.tableHeaderRow} key={`___index_table${index}`}>
                            <TableCell className={classes.tableHeaderCellbody}>
                              <View>
                                {filename.map((f, i) => {
                                  const fileShow = f.split('/')
                                  const showFile = fileShow[fileShow.length - 1]
                                  return (
                                    <Pressable
                                      disabled={status == 0 || status == 2 || status == 3 ? true : false}
                                      onPress={() => {
                                        dowLoadDoccument(f)
                                      }}
                                    >
                                      <Text
                                        style={{
                                          textDecorationLine: 'underline',
                                          color: '#72B9F7',
                                          padding: 10
                                        }}
                                      >
                                        {showFile}
                                      </Text>
                                    </Pressable>
                                  )
                                })}
                              </View>
                            </TableCell>
                            <TableCell>{moment(createdDate).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{fromDate.split(' ')[0]}</TableCell>
                            <TableCell>{toDate.split(' ')[0]}</TableCell>
                            <TableCell>{author}</TableCell>
                            {status == 0 && (
                              <TableCell className={classes.tableDelete}>{t('Reports:Delete')}</TableCell>
                            )}
                            {status == 1 && (
                              <TableCell className={classes.tableActive}>{t('Reports:Active')}</TableCell>
                            )}
                            {status == 2 && (
                              <TableCell className={classes.tableWaiting}>{t('Reports:Waiting')}</TableCell>
                            )}
                            {status == 3 && <TableCell className={classes.tableError}>{t('Reports:Error')}</TableCell>}
                          </TableRow>
                        )
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 16,
    marginVertical: 16,
    paddingVertical: 16,
    paddingHorizontal: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    boxShadow: 'none',
    paddingVertical: 10,
    paddingHorizontal: 32
  },

  btnText: {
    textAlign: 'center',
    color: '#fff',
    marginLeft: 6
  }
} as const)
