import { Maybe } from '@mxt/zio/codec'
import i18next from 'i18next'
import * as t from 'io-ts'
export namespace PayoutPopup {
  export enum CashlessMethod {
    BANK = 'Bank transfer', // (Chuyển khoản qua ngân hàng)
    PAB = 'Paid at bank', //(Nhận tiền mặt bằng CMND tại ngân hàng)
    PAC = 'Receive cash at counter', //(Nhận tiền mặt tại quầy)
    MOMO = 'eWallet Momo' //(Ví điện tử Momo)
  }

  export enum PayoutMethods {
    PAYPREMIUM = 'PAYPREMIUM',
    PAYLOAN = 'PAYLOAN',
    OTHER = 'OTHER',
    CASHLESS = 'CASHLESS',
    BANKTRANSFER = 'BANKTRANSFER',
    PAID_AT_BANK = 'PAID_AT_BANK',
    REPAY_APL = 'REPAY_APL',
    REPAY_OPL = 'REPAY_OPL',
    REPAY_OPL_APL = 'REPAY_OPL_APL',
    MOMO = 'EWALLET_MOMO'
  }

  export interface PayoutMethodTranslator {
    typeEnum: string
    nameEn: string
    nameVi: string
  }

  export const translateMethod = (
    method: PayoutPopup.PayoutMethods,
    data: {
      typeEnum: string
      nameEn: string
      nameVi: string
    }[]
  ): string => {
    const target = data.find((x) => x.typeEnum == method)
    const isVi = i18next.language === 'vi'
    return target ? (isVi ? target.nameVi : target.nameEn) : '-'
  }

  export interface PayoutData {
    method: PayoutMethods
    amount: number

    policyNum?: string
    poName?: string
    totalPremium?: number

    bankCode?: string
    bankName?: string
    bankAccountNum?: string
    bankAccountName?: string
    bankBranchCode?: string
    bankBranchName?: string
    nationalId?: string
  }

  export const SummaryCodec = t.type({
    method: t.union([
      t.literal(PayoutMethods.BANKTRANSFER),
      t.literal(PayoutMethods.CASHLESS),
      t.literal(PayoutMethods.MOMO),
      t.literal(PayoutMethods.OTHER),
      t.literal(PayoutMethods.PAID_AT_BANK),
      t.literal(PayoutMethods.PAYLOAN),
      t.literal(PayoutMethods.PAYPREMIUM),
      t.literal(PayoutMethods.REPAY_APL),
      t.literal(PayoutMethods.REPAY_OPL),
      t.literal(PayoutMethods.REPAY_OPL_APL)
    ]),
    methodView: t.string,
    detail: t.string,
    amount: t.number,

    policyNum: Maybe(t.string),
    poName: Maybe(t.string),
    totalPremium: Maybe(t.number),

    bankCode: Maybe(t.string),
    bankName: Maybe(t.string),
    bankAccountNum: Maybe(t.string),
    bankAccountName: Maybe(t.string),
    bankBranchCode: Maybe(t.string),
    bankBranchName: Maybe(t.string),
    nationalId: Maybe(t.string)
  })

  export type Summary = t.TypeOf<typeof SummaryCodec>

  export type PayoutSummaryArrayBrands = {
    readonly EmptyArray: unique symbol
    readonly TotalAmountLessThanZero: unique symbol
    readonly TotalAmountLessThanMinValue: unique symbol
  }

  export type PayoutSummaryArray = t.Branded<Summary[], PayoutSummaryArrayBrands>

  // export interface Summary {
  //   method: PayoutMethods
  //   methodView: string
  //   detail: string
  //   amount: number

  //   policyNum?: string
  //   poName?: string
  //   totalPremium?: number

  //   bankCode?: string
  //   bankName?: string
  //   bankAccountNum?: string
  //   bankAccountName?: string
  //   bankBranchCode?: string
  //   bankBranchName?: string
  //   nationalId?: string
  // }

  /**PAY PREMIUM */
  export interface PayPremiumData {
    policyNum: string
    paidToDate: string
    installmentPremium: number
    poName: string
    totalPremium: number

    billingFrequency?: string
    relationship?: string
    isMaturityAdvValid?: boolean
  }

  export interface PayPremiumOtherData {
    policyNum: string
    poName: string
    paidToDate: string
    installmentPremium: number
    totalPremium: number
    billingFrequency: string
    isMaturityAdvValid?: boolean
    isPayPremiumValid?: boolean
  }

  /**REPAY LOAN */
  export interface ReloanPayData {
    policyNum: string
    opl: number
    apl: number
    poName: string
    totalPremium: number
  }

  export interface RepayLoanOtherData {
    policyNum: string
    poName: string
    opl: number
    apl: number
    totalPremium: number
    isRepayLoanvalid?: boolean
  }

  /**OTHER PAY */

  export interface OtherPayPremiumData {
    policyNum: string
    contractStatus: string
    poName: string
    totalPremium: number
    minTopUp?: number
    maxTopUp?: number
  }

  export interface OtherPayPremiumSearchData {
    policyNum: string
    poName: string
    contractStatus: string
    totalPremium: number
    isMaturityAdvValid?: boolean
    isOtherPayPremiumValid?: boolean
    minTopUp?: number
    maxTopUp?: number
  }

  export interface TopUpPolicy {
    policyNum: string
    minTopUp: number
    maxTopUp: number
    ogMaxTopup: number
    totalPremium: number
  }
}
