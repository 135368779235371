export interface SelectOptionModel {
  code: string
  nameEn: string
  nameVn: string
}

export const StatusPointList: SelectOptionModel[] = [
  { code: '', nameEn: 'ALL', nameVn: 'Tất cả' },
  { code: 'ACTIVE', nameEn: 'ACTIVE', nameVn: 'Hoạt động' },
  { code: 'CANCELED', nameEn: 'CANCELED', nameVn: 'Đã hủy' },
  { code: 'EXPIRED', nameEn: 'EXPIRED', nameVn: 'Hết hạn' },
  { code: 'REDEEMED', nameEn: 'REDEEMED', nameVn: 'Đã đổi quà' },
  { code: 'REVERTED', nameEn: 'REVERTED', nameVn: 'Điểm đã reverted' }
]

export const PointTypeList: SelectOptionModel[] = [
  { code: '', nameEn: 'ALL', nameVn: 'Tất cả' },
  { code: 'POINT_PAYMENT', nameEn: 'POINT_PAYMENT', nameVn: 'POINT_PAYMENT' },
  { code: 'POINT_BIRTHDAY', nameEn: 'POINT_BIRTHDAY', nameVn: 'POINT_BIRTHDAY' },
  { code: 'POINT_REDEMPTION', nameEn: 'POINT_REDEMPTION', nameVn: 'POINT_REDEMPTION' },
  { code: 'POINT_ANNIVERSARY', nameEn: 'POINT_ANNIVERSARY', nameVn: 'POINT_ANNIVERSARY' },
  { code: 'POINT_BIRTHDAY_FIRST_LOGIN', nameEn: 'POINT_BIRTHDAY_FIRST_LOGIN', nameVn: 'POINT_BIRTHDAY_FIRST_LOGIN' },
  { code: 'POINT_NEW_YEAR', nameEn: 'POINT_NEW_YEAR', nameVn: 'POINT_NEW_YEAR' },
  { code: 'POINT_EXPIRED', nameEn: 'POINT_EXPIRED', nameVn: 'POINT_EXPIRED' },
  { code: 'POINT_CANCELLED', nameEn: 'POINT_CANCELLED', nameVn: 'POINT_CANCELLED' },
  { code: 'REFUND_POINT', nameEn: 'REFUND_POINT', nameVn: 'REFUND_POINT' },
  { code: 'PLUS_POINT', nameEn: 'PLUS_POINT', nameVn: 'PLUS_POINT' }
]

export const StatusRedeemList: SelectOptionModel[] = [
  { code: '', nameEn: 'ALL', nameVn: 'Tất cả' },
  { code: 'Issued', nameEn: 'Issued', nameVn: 'Mã đã xuất' },
  { code: 'NonIssued', nameEn: 'Non Issued', nameVn: 'Mã chưa xuất' },
  { code: 'Redeemed', nameEn: 'Redeemed', nameVn: 'Mã đã đối soát' }
]
export const StatusGiftList: SelectOptionModel[] = [
  { code: '', nameEn: 'ALL', nameVn: 'Tất cả' },
  { code: 'SUCCESS', nameEn: 'SUCCESS', nameVn: 'Thành công' },
  { code: 'FAIL', nameEn: 'FAIL', nameVn: 'Thất bại' },
  { code: 'RETURN', nameEn: 'RETURN', nameVn: 'Chuyển hoàn' },
  { code: 'PROCESSING', nameEn: 'PROCESSING', nameVn: 'Chờ xử lý' },
  { code: 'DELIVERING', nameEn: 'DELIVERING', nameVn: 'Đang vận chuyển' },
  { code: 'DELIVERED', nameEn: 'DELIVERED', nameVn: 'TVV xác nhận giao KH' },
  { code: 'NOT_DELIVERED', nameEn: 'NOT_DELIVERED', nameVn: 'TVV chưa xác nhận giao KH' }
]
