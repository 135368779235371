import { Throwable, ZIO } from '@mxt/zio'
import {
  DatePicker,
  DetailService,
  EditFooterService,
  Form,
  FormService,
  Input,
  Select,
  Shareholder,
  useAddressForm
} from '@pulseops/business/core'
import { ErrorHandling, form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../../contract-detail-context'
import { ShareholderDetailForm } from './shareholder-detail-form'

export const ShareholderDetailEdit = ({ shareHolderKey, onClose }: { shareHolderKey: string; onClose: () => void }) => {
  const [registerOnSave, registerOnClose] = EditFooterService.useFooter()

  registerOnClose(onClose)

  const { t } = useTranslation('business')

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const detail: Shareholder | null = pipe(
    service.state.shareholder.item(shareHolderKey).get,
    ZIO.tap((detail) => {
      if (detail) {
        setValue('permanent.city', detail.permanentCity)
        setValue('permanent.district', detail.permanentDistrict)
        setValue('permanent.ward', detail.permanentWard)
        setValue('residential.city', detail.residentialCity)
        setValue('residential.district', detail.residentialDistrict)
        setValue('residential.ward', detail.residentialWard)
        setValue('overseas.city', detail.overseaCity)
        setValue('overseas.district', detail.overseaDistrict)
        setValue('overseas.ward', detail.overseaWard)
      }
      return ZIO.unit
    }),
    ErrorHandling.runDidMount()
  )

  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue
    },
    handleSubmit
  } = form2.useForm(ShareholderDetailForm.codec)

  const countryOptions = pipe(FormService.getCountryOptions, ErrorHandling.runDidMount([]))
  const provinceOptions = pipe(FormService.getProvinceOptions, ErrorHandling.runDidMount([]))

  const permanentAddress = useAddressForm(watch, 'permanent')
  const residentialAddress = useAddressForm(watch, 'residential')
  const overseasAddress = useAddressForm(watch, 'overseas')

  pipe(
    handleSubmit((validated) =>
      pipe(
        detail,
        ZIO.fromPredicate(
          (a): a is Shareholder => a != null,
          () => Throwable('shareholder detail not found')
        ),
        ZIO.flatMap((detail) =>
          service.updateShareholder({
            ...detail,
            ...validated,
            permanentCity: validated.permanent.city,
            permanentDistrict: validated.permanent.district,
            permanentWard: validated.permanent.ward,
            residentialCity: validated.residential.city,
            residentialDistrict: validated.residential.district,
            residentialWard: validated.residential.ward,
            overseaCity: validated.overseas.city,
            overseaDistrict: validated.overseas.district,
            overseaWard: validated.overseas.ward
          })
        ),
        ZIO.tap(() => service.state.history.clear),
        ErrorHandling.run()
      )
    ),
    registerOnSave
  )

  if (!detail) {
    return null
  }

  return (
    <Form.Group>
      <Form.GroupTitle>{t('shareholder_info_detail')}</Form.GroupTitle>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="fullName"
            defaultValue={detail.fullName}
            render={({ field }) => (
              <Input
                label={t('full_name')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.fullName?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="dateOfBirth"
            defaultValue={detail.dateOfBirth}
            render={({ field }) => (
              <DatePicker
                label={t('dob')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.dateOfBirth?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="gender"
            defaultValue={detail.gender}
            render={({ field }) => (
              <Select
                options={FormService.genderOptions}
                label={t('gender')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.gender?.message}
              />
            )}
          />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="position"
            defaultValue={detail.position}
            render={({ field }) => (
              <Input
                label={t('position')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.position?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="phoneNumber"
            defaultValue={detail.phoneNumber}
            render={({ field }) => (
              <Input
                label={t('phone_number')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.phoneNumber?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col />
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="idNumber"
            defaultValue={detail.idNumber}
            render={({ field }) => (
              <Input
                label={t('id_card_no')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.idNumber?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="dateOfIssue"
            defaultValue={detail.dateOfIssue}
            render={({ field }) => (
              <DatePicker
                label={t('issue_date')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.dateOfIssue?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="placeOfIssue"
            defaultValue={detail.placeOfIssue}
            render={({ field }) => (
              <Input
                label={t('issue_place')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.placeOfIssue?.message}
              />
            )}
          />
        </Form.Col>
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="nationality"
            defaultValue={detail.nationality}
            render={({ field }) => (
              <Select
                options={countryOptions}
                label={t('nationality')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.nationality?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="otherNationality"
            defaultValue={detail.otherNationality}
            render={({ field }) => (
              <Select
                options={countryOptions}
                label={t('other_nationality')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.otherNationality?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="visa"
            defaultValue={detail.visa}
            render={({ field }) => (
              <Input
                label={t('visa')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.visa?.message}
              />
            )}
          />
        </Form.Col>
      </Form.Row>

      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="permanentAddress"
            defaultValue={detail.permanentAddress}
            render={({ field }) => (
              <Input
                label={t('perm_address')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.permanentAddress?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="permanentAddressCountry"
            defaultValue={detail.permanentAddressCountry}
            render={({ field }) => (
              <Select
                options={countryOptions}
                label={t('perm_address_country')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.permanentAddressCountry?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col />
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="permanent.city"
            render={({ field }) => (
              <Select
                options={provinceOptions}
                label={t('business_address_province')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  setValue('permanent.district', null)
                  setValue('permanent.ward', null)
                }}
                onBlur={field.onBlur}
                errorMessage={errors?.permanent?.city?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="permanent.district"
            render={({ field }) => (
              <Select
                disabled={permanentAddress.districtOptions.length === 0}
                options={permanentAddress.districtOptions}
                label={t('business_address_district')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  setValue('permanent.ward', null)
                }}
                onBlur={field.onBlur}
                errorMessage={errors?.permanent?.district?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="permanent.ward"
            render={({ field }) => (
              <Select
                disabled={permanentAddress.wardOptions.length === 0}
                options={permanentAddress.wardOptions}
                label={t('business_address_ward')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.permanent?.ward?.message}
              />
            )}
          />
        </Form.Col>
      </Form.Row>

      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="residentAddress"
            defaultValue={detail.residentAddress}
            render={({ field }) => (
              <Input
                label={t('curr_address')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.residentAddress?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="residentialAddressCountry"
            defaultValue={detail.residentialAddressCountry}
            render={({ field }) => (
              <Select
                options={countryOptions}
                label={t('curr_address_country')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.residentialAddressCountry?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col />
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="residential.city"
            render={({ field }) => (
              <Select
                options={provinceOptions}
                label={t('business_address_province')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  setValue('residential.district', null)
                  setValue('residential.ward', null)
                }}
                onBlur={field.onBlur}
                errorMessage={errors?.residential?.city?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="residential.district"
            render={({ field }) => (
              <Select
                disabled={residentialAddress.districtOptions.length === 0}
                options={residentialAddress.districtOptions}
                label={t('business_address_district')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  setValue('residential.ward', null)
                }}
                onBlur={field.onBlur}
                errorMessage={errors?.residential?.district?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="residential.ward"
            render={({ field }) => (
              <Select
                disabled={residentialAddress.wardOptions.length === 0}
                options={residentialAddress.wardOptions}
                label={t('business_address_ward')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.residential?.ward?.message}
              />
            )}
          />
        </Form.Col>
      </Form.Row>

      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="overseaAddress"
            defaultValue={detail.overseaAddress}
            render={({ field }) => (
              <Input
                label={t('oversea_address')}
                value={field.value || ''}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.overseaAddress?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="overseasAddressCountry"
            defaultValue={detail.overseasAddressCountry}
            render={({ field }) => (
              <Select
                options={countryOptions}
                label={t('oversea_address_country')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.overseasAddressCountry?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col />
      </Form.Row>
      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="overseas.city"
            render={({ field }) => (
              <Select
                options={provinceOptions}
                label={t('business_address_province')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  setValue('overseas.district', null)
                  setValue('overseas.ward', null)
                }}
                onBlur={field.onBlur}
                errorMessage={errors?.overseas?.city?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="overseas.district"
            render={({ field }) => (
              <Select
                disabled={overseasAddress.districtOptions.length === 0}
                options={overseasAddress.districtOptions}
                label={t('business_address_district')}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e)
                  setValue('overseas.ward', null)
                }}
                onBlur={field.onBlur}
                errorMessage={errors?.overseas?.district?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col>
          <Controller
            control={control}
            name="overseas.ward"
            render={({ field }) => (
              <Select
                disabled={overseasAddress.wardOptions.length === 0}
                options={overseasAddress.wardOptions}
                label={t('business_address_ward')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                errorMessage={errors?.overseas?.ward?.message}
              />
            )}
          />
        </Form.Col>
      </Form.Row>

      <Form.Row>
        <Form.Col>
          <Controller
            control={control}
            name="capitalContributionRatio"
            defaultValue={detail.capitalContributionRatio}
            render={({ field }) => (
              <Input
                label={t('contrib_ratio')}
                {...form2.registerPercent(field)}
                errorMessage={errors?.capitalContributionRatio?.message}
              />
            )}
          />
        </Form.Col>
        <Form.Col />
        <Form.Col />
      </Form.Row>
    </Form.Group>
  )
}
