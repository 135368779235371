import { BorderContainer, Text, View } from '@pulseops/business/core'
import styled from 'styled-components/native'
import { Pressable } from 'react-native'

export const SC = {
  Container: styled(View)`
    padding: 1rem;
    align-items: center;
  `,
  BorderContainer: styled(BorderContainer)`
    width: 100%;
  `,
  Header: styled(View)`
    align-items: center;
    justify-content: center;
    background: white;
    padding: 24px 30px;
  `,
  HeaderText: styled(Text)`
    font-size: 18px;
    font-weight: 700;
  `,
  CloseIcon: styled(Pressable)`
    position: absolute;
    right: 30px;
  `,
  ButtonContainer: styled(View)`
    width: 80px;
  `
}
