import { useMobile } from '@pulseops/common'
import * as React from 'react'
// import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'

interface Props {
  visible: boolean
  onPressClose: () => void
}

export const AlertModal = ({ visible, onPressClose }: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  // const { t } = useTranslation('claim')
  const onClose = () => {
    onPressClose && onPressClose()
  }

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View style={[styles.modal, { justifyContent: isWide ? 'center' : 'flex-end' }]}>
        {isMobile && (
          <TouchableOpacity
            activeOpacity={1}
            onPress={onClose}
            style={{ position: 'absolute', width: width, height: height }}
          />
        )}
        <View
          style={[
            styles.container,
            {
              borderRadius: 8,
              height: isWide ? 200 : '100%',
              width: isWide ? 500 : '100%',
              justifyContent: 'center',
              // maxHeight: height * (isWide ? 1 : 0.8),
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0
            }
          ]}
        >
          <Text
            style={{
              padding: 'auto',
              fontWeight: 'bold',
              fontSize: 20,
              textAlign: 'center',
              color: '#ED1B2E',
              marginVertical: 20
            }}
          >
            {' '}
            Something Wrong ! Please input valid field
          </Text>
          <View style={{ flexDirection: 'row', marginHorizontal: 50, justifyContent: 'center' }}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={onClose}
              style={{
                borderRadius: 8,
                backgroundColor: '#ED1B2E',
                justifyContent: 'center',
                width: '50%',
                height: 50
              }}
            >
              <Text style={{ fontWeight: '600', textAlign: 'center', color: '#fff' }}>OK</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)'
  },

  container: {
    backgroundColor: '#FFFFFF'
  }
})
