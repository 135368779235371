/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as t from 'io-ts'
import { form2 } from '@pulseops/common'

export namespace GeneralForm {
  export const codec = t.type({
    subChannel: form2.string.optional,
    channelLink: form2.string.optional,
    salesType: form2.string.optional,
    eREF: form2.string.optional,
    agCode: form2.string.optional,
    leadercodedescription: form2.string.optional,
    officecode: form2.string.optional,
    saleZone: form2.string.optional,
    regioncode: form2.string.optional,
    regiondescription: form2.string.optional,
    leaderCode: form2.string.required,
    terminatedDate: form2.string.optional,
    erminationReason: form2.string.optional,
    // fullName: form2.string.required,
    lastName: form2.string.required,
    firstName: form2.string.required,
    genDer: form2.selectOption.required,
    oldidNo: form2.string.optional,
    dateofBirth: form2.date.optional,
    idDate: form2.date.optional,
    idPlace: form2.string.required,
    idExpireddate: form2.date.optional,
    placeofBirth: form2.string.required,
    nationality: form2.selectOption.required,
    ethNic: form2.string.optional,
    agentcodeRefer: form2.string.optional,
    agentnameRefer: form2.string.optional,
    phonenumber: form2.string.required,
    companyphone: form2.string.optional,
    HomePhone: form2.string.optional,
    Email: form2.email.required,
    Zalo: form2.string.optional,
    StreetResidential: form2.string.required,
    locationCodeResidential: form2.string.required,
    ProvinceResidential: form2.selectOption.required,
    DistrictResidential: form2.selectOption.required,
    WardResidential: form2.selectOption.required,
    ProvinceAllocated: form2.selectOption.optional,
    DistrictAllocated: form2.selectOption.optional,
    WardAllocated: form2.selectOption.optional,
    StreetAllocated: form2.string.optional,
    AllocatedlocationCode: form2.string.optional,
    ProvinceContact: form2.selectOption.required,
    DistrictContact: form2.selectOption.required,
    WardContact: form2.selectOption.required,
    StreetContact: form2.string.required,
    locationCodeContact: form2.string.required,
    BankName: form2.selectOption.required,
    Bankbranchname: form2.selectOption.required,
    Bankcode: form2.string.required,
    Bankaccount: form2.string.required,
    BanknameOther: form2.string.optional,
    BankbranchnameOther: form2.string.optional,
    Education: form2.selectOption.required,
    Specialization: form2.string.optional,
    Occupation: form2.selectOption.required,
    // Occupation: withMessage(form2.selectOption.required,()=> i18next.t('message:MS020009', { field: i18next.t('RequestInfo:Occupation') })),
    Income: form2.selectOption.optional,
    Taxcode: form2.string.optional,
    Maritalstatus: form2.selectOption.required,
    Numberofchildren: form2.string.optional,
    checkDuplicate: t.boolean,
    qsCore: t.number,
    newLeaderName: form2.string.optional,
    newLeaderCode: form2.string.optional,
    checkPhone: t.boolean,
    checkBank: t.boolean,
    isSaveGeral: t.boolean,
    assessByDesc: form2.string.optional,
    assessmentDate: form2.string.optional,
    reAssess: form2.string.optional,
    reAssessDate: form2.string.optional,
    role: form2.string.optional,
    status: t.boolean,
    checkActiveAgent: t.boolean
  })
  export type Raw = t.OutputOf<typeof codec>
  export type Validated = t.TypeOf<typeof codec>
}
