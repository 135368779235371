import { Task, ZIO } from '@mxt/zio'
import {
  AppContext,
  BuildConfig,
  ChangeContactInfo,
  GeneralService,
  // assets,
  //PolicyInfoHeader,
  RequestAuthFormDataSubmit,
  StorageBlob,
  SubmissionService,
  TransactionType
} from '@pulseops/common'
import { TaskDetailAction } from '@pulseops/task'
import { useIsFocused, useLinkTo, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import * as tc from 'io-ts'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { PSSubmissionStackParamList } from './PSSubmissionStackParamList'
import { PolicyServicePayload, TransactionWrapper } from './transactionScreen'
import { RequestAuthenticateData } from './transactionScreen/request-authen'
import {
  AddLackofDocument,
  Assignment,
  BeneficiaryDesignation,
  BillingChangeReversalScreen,
  BonusSurrender,
  CancelFromInceptionScreen,
  CancelRiderScreen,
  ChangeClientInfo,
  ChangeContactInfoScreen,
  ConfirmationLetterOfPolicy,
  ConfirmOfPremiumReceipt,
  EServiceRegistration,
  ExcessPremium,
  FatcaDeclarationScreen,
  GoAboard,
  HealthRedeclaration,
  ChangeSumAssured,
  Inheritance,
  LoanPayment,
  LoanStatementes,
  MaturityAdvance,
  MaturityPayment,
  PolicyCertificate,
  PolicyInfoHeader,
  PolicyServiceClear,
  PolicyServiceSubmit,
  PrucashPayment,
  PruKid369,
  SignatureRegistrationScreen,
  ReinstatementScreen,
  ChangePremium,
  SchedulePaymentState,
  SpecialBonus,
  FundSwitchingScreen,
  BillingChangeScreen,
  StopAPL,
  TopUpScreen,
  ExcellentStudentAward,
  CommonRequestFormScreen,
  PaidUpScreen,
  FullSurrender,
  PartialSurrender,
  ProductOptionSwitching,
  PartialWithdrawal,
  RedatingScreen,
  RiderAlteration,
  FullSurrenderReversal,
  RiderReinstatementScreen,
  UploadedFilesInfo
} from './transactionScreen/screens'
import { PremiumRedirectionScreen } from './transactionScreen/screens/premium-redirection'
import * as A from 'fp-ts/lib/Array'
import { MajorCombinedScreen } from './transactionScreen/screens/major-combined'
import { TranfersJournalScreen } from './transactionScreen/screens/transfer-journal'
import ChangeNationalIDCardScreen from './transactionScreen/screens/change-national-id-card/change-national-id-card-screen'
import ChangeOccupationScreen from './transactionScreen/screens/change-occupation/change-occupation-screen'
import ChangeDOBAndGenderScreen from './transactionScreen/screens/change-dob-and-gender/change-dob-and-gender-screen'
import ChangeOtherInfomationPersonalScreen from './transactionScreen/screens/change-other-infomation-personal/change-other-infomation-personal-screen'

type Props = StackScreenProps<PSSubmissionStackParamList, 'PSSubmissionScreen'>

export type PolicyType = {
  policyNum: string
  clientId: string
  poName: string
  mobilePhone: string
  email: string
  idCCCD: string
  firstName: string
  surName: string
}
export const PSSubmissionScreen = (props: Props) => {
  const transactionTypeItem = props.route.params?.transactionName as TransactionType
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const linkTo = useLinkTo()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [policyInfo, setPolicyInfo] = React.useState<PolicyType>({
    policyNum: '',
    clientId: '',
    poName: '',
    email: '',
    mobilePhone: '',
    idCCCD: '',
    firstName: '',
    surName: ''
  })
  const [eformData, setEformData] = React.useState<RequestAuthenticateData.EformRequestAuthenticateData>(
    RequestAuthenticateData.DefaultRequestAuthenticateData
  )
  const requestAuthenForm = useForm<RequestAuthenticateData.RequestAuthFormValue>({
    defaultValues: {
      authOption: RequestAuthenticateData.RequestAuthOption.OTP,
      otp: {
        otpOption: RequestAuthenticateData.OTPOption.PHONE,
        otpInput: undefined,
        otpConsent: false
      },
      paper: {
        data: [],
        consent: false
      }
    }
  })
  const { watch, setValue } = requestAuthenForm

  const getPolicyOwnerHeaderName = () => {
    return !!policyInfo?.clientId && !!policyInfo?.poName ? policyInfo?.clientId + ' - ' + policyInfo?.poName : ''
  }

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: policyInfo?.policyNum ?? ''
      // icon: <assets.PolicyNumber />
    },
    {
      label: t('common:PolicyOwner'),
      // value: policyInfo?.poName ?? '',
      value: getPolicyOwnerHeaderName()
      // icon: <assets.MainBoyAvatar />
    },
    {
      label: t('common:Office'),
      value: `${props.route.params?.officeCode ?? ''} - ${props.route.params?.officeName ?? ''}`
      // icon: <assets.Building />
    }
  ]
  const transactionTypeByPassAuthPaper = [
    TransactionType.FATCA_DECLARATION,
    TransactionType.SIGNATURE_REGISTRATION,
    TransactionType.HEALTH_DECLARATION,
    TransactionType.COMMON_REQUEST_FORM
  ]

  React.useEffect(() => {
    if (isFocused) {
      if (props.route.params?.isCCE === '1') {
        changeBreadcrumb([
          {
            title: '',
            navigate: () => {
              navigate('HomeScreen')
            }
          },
          {
            title: t('Submission:ContactCenter'),
            navigate: () => navigate('StaffSubmissionStack', { screen: 'ContactCenterScreen' })
          },
          { title: t(`TransactionType:${props.route.params.transactionName}`), navigate: null }
        ])
      } else {
        changeBreadcrumb([
          {
            title: '',
            navigate: () => {
              navigate('HomeScreen')
            }
          },
          {
            title: t('Submission:EForm'),
            navigate: () => {
              navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
            }
          },
          {
            title: t('common:PolicyServices'),
            navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
          },
          { title: t(`TransactionType:${props.route.params.transactionName}`), navigate: null }
        ])
      }
      setTabIndex(0)
    }
    setTabIndex(0)
  }, [isFocused])

  React.useEffect(() => {
    if (props.route.params?.policyNum) {
      showGlobalLoading(true)
      pipe(
        SubmissionService.getOwnerInfo(props.route.params?.policyNum || ''),
        ZIO.map((responseItem) => {
          const {
            body: { clientId, surName, firstName, mobilePhone, email, externalIds: { SOE_VALUE } = {} }
          } = responseItem

          const policyItem: PolicyType = {
            policyNum: props.route.params?.policyNum || '',
            clientId,
            poName: `${surName} ${firstName}`,
            mobilePhone,
            email,
            idCCCD: SOE_VALUE ?? '-',
            firstName,
            surName
          }
          setPolicyInfo(policyItem)
          return policyItem
        }),
        ZIO.flatMap((clientItem) => {
          return pipe(
            // SubmissionService.getCustomer(clientItem.clientId || '-'),
            SubmissionService.getNewOwnerInfo(clientItem.policyNum),
            ZIO.map((customerInfo) => {
              setEformData({
                ...eformData,
                policyNum: clientItem.policyNum,
                // phoneData: {
                //   phoneCode: '84',
                //   phoneNum: customerInfo.body.mobilePhone ?? '-'
                // },
                // otpRegistered: customerInfo.body.otpConsentStatus === '1'
                phoneData: {
                  phoneCode: customerInfo.attributesExt.MOBILE_CODE,
                  phoneNum: customerInfo.mobilePhone ?? ''
                },
                otpRegistered: customerInfo.otpConsentStatus === '1',
                isCCE: props.route.params?.isCCE === '1'
              })
              showGlobalLoading(false)
              return customerInfo
            })
          )
        }),
        ZIO.mapError((_) => {
          showGlobalLoading(false)
        }),
        ZIO.unsafeRun({})
      )
    }
    // }, [eformData])
  }, [props.route.params?.policyNum])

  const transactionInfos = () => {
    switch (props.route.params.transactionName as TransactionType) {
      case TransactionType.POLICY_CERTIFICATE:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:LetterRequest') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.ASSIGNMENT:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.INHERITANCE:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.HEALTH_DECLARATION:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.STOP_APL:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:LetterRequest') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:LetterRequest') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.REINSTATEMENT:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.RIDER_REINSTATEMENT:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CHANGE_PREMIUM:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.FATCA_DECLARATION:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.BILLING_FREQUENCY_REVERSAL:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CHANGE_CONTACT_INFO:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.MATURITY:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`submission:${props.route.params.transactionName}`) }
        ]
      case TransactionType.SPECIAL_BONUS:
      case TransactionType.PRUKID369:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.GO_ABROAD:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CANCEL_FROM_INCEPTION:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.PREMIUM_REDIRECTION:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.BONUS_SURRENDER:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.PARTIAL_SURRENDER:
      case TransactionType.FULL_SURRENDER:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.BENEFICIARY_DESIGNATION:
      case TransactionType.BILLING_CHANGE_CORPORATE:
      case TransactionType.BILLING_CHANGE:
      case TransactionType.ADD_LACK_OF_DOCUMENT:
      case TransactionType.SIGNATURE_REGISTRATION:
      case TransactionType.PAID_UP:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CANCEL_RIDER:
        return [
          { label: t('submission:category'), value: t('submission:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.LOAN_STATEMENTS:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('submission:LetterRequest') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.TOP_UP:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.COMMON_REQUEST_FORM:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Common') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.EXCESS_PREMIUM:
      case TransactionType.PRUCASH_PAYMENT:
      case TransactionType.MATURITY_ADVANCE:
      case TransactionType.EXCELLENT_STUDENT_AWARD:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.LOAN:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`submission:${props.route.params.transactionName}`) }
        ]

      case TransactionType.E_SERVICE_REGISTRATION:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.FUND_SWITCHING:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyServices') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CHANGE_SUM_ASSURED:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.CHANGE_CLIENT_INFO:
        return [
          { label: t('submission:category'), value: t('Reports:General') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.SCHEDULED_PAYMENT_STATEMENT:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('submission:LetterRequest') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.REDATING:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.PRODUCT_OPTION_SWITCHING:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]

      case TransactionType.PARTIAL_WITHDRAWAL:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Payout') },
          { label: t('submission:transactionType'), value: t(`submission:${props.route.params.transactionName}`) }
        ]
      case TransactionType.RIDER_ALTERATION:
      case TransactionType.FULL_SURRENDER_REVERSAL:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.MAJOR_COMBINED_CHANGE:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      case TransactionType.CHANGE_DOB_GENDER:
      case TransactionType.CHANGE_NATIONAL_ID_CARD:
      case TransactionType.CHANGE_OCCUPATION_PERSONAL:
      case TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL:
        return [
          { label: t('submission:category'), value: t('Reports:PolicyService') },
          { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
          { label: t('submission:transactionType'), value: t(`TransactionType:${props.route.params.transactionName}`) }
        ]
      default:
        return [
          { label: t('submission:category'), value: '-' },
          { label: t('submission:serviceType'), value: '-' },
          { label: t('submission:transactionType'), value: '-' }
        ]
    }
  }
  //submit Data with request Authentication like OTP, Paper
  const onSubmit =
    (
      url: string,
      body: unknown,
      documents: StorageBlob.FileContentSubmit[],
      method?: 'POST' | 'PUT',
      uploadedFilesInfo?: UploadedFilesInfo[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) => {
      const requestAuthInfo = validateSubmitedButtonForAddLack() ? null : requestauth
      showGlobalLoading(true)
      return pipe(
        !!uploadedFilesInfo && uploadedFilesInfo.length > 0
          ? pipe(
              uploadedFilesInfo,
              A.map((uploadedItem) => {
                return GeneralService.getAzureStorageFiles(
                  uploadedItem?.uploadFiles,
                  uploadedItem.transactionType,
                  uploadedItem.docTypeCode,
                  uploadedItem.category,
                  uploadedItem.policyNumber,
                  uploadedItem.officeCode
                )
              }),
              ZIO.sequence,
              ZIO.map((uploadedList) => {
                let uploadFiles: StorageBlob.FileContentSubmit[] = []
                uploadedList.map((arrayItem) => {
                  uploadFiles = [...arrayItem, ...uploadFiles]
                })
                return uploadFiles
              })
            )
          : ZIO.succeed([]),
        ZIO.flatMap((azuredDocList) =>
          SubmissionService.submit(tc.unknown, method || 'POST')(
            url,
            body,
            policyInfo.policyNum,
            requestAuthInfo,
            [...documents, ...azuredDocList],
            policyInfo.clientId,
            props.route.params?.isCCE === '1',
            props.route.params?.officeCode
          )
        ),
        ZIO.foldM(
          (err) => {
            showGlobalLoading(false)
            return ZIO.fail(err)
          },
          (_) => {
            showGlobalLoading(false)
            return ZIO.unit
          }
        )
      )
    }

  const isByPassAuthPaper = (): boolean => {
    return eformData.transaction !== null && transactionTypeByPassAuthPaper.includes(eformData.transaction)
  }

  const onSubmitDataWithAuthen = (submitService: (_: RequestAuthFormDataSubmit) => Task<unknown>) => {
    return (verify: RequestAuthenticateData.RequestAuthFormValue) => {
      const isValidOTP: boolean =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP &&
        verify.otp.otpInput?.toString().length === 6
      const isValidAuthFlag =
        (verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER ||
          verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM ||
          verify.authOption ===
            RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM) &&
        eformData.docReviewed
      const isValidAuthDoc =
        (verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER ||
          verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM ||
          verify.authOption ===
            RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM) &&
        (verify.paper.data.length > 0 || isByPassAuthPaper())
      
      showGlobalLoading(true)
      const listFileUpload = [...verify.paper.data, ...(verify.otp.authentication_data || [])]

      return isValidOTP || (isValidAuthFlag && isValidAuthDoc)
        ? pipe(
            verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
              ? submitService({
                  type: RequestAuthenticateData.RequestAuthOption.OTP,
                  data: {
                    otp: verify.otp?.otpInput as number,
                    transactionName: eformData.transactionName,
                    transactionCode: `${eformData.transactionType}-${eformData.collerationId}-${eformData.sendDate}`,
                    receiverPhone: eformData.phoneData.phoneNum,
                    payload: ''
                  }
                })
              : pipe(
                  !!eformData.metaData
                    ? StorageBlob.uploadToSubmit(
                        'PS',
                        eformData.collerationId
                      )(
                        listFileUpload.map((x) => ({
                          file: x.file,
                          metaData: eformData.metaData
                        }))
                      )
                    : ZIO.succeed([]),
                  ZIO.flatMap((x) => {
                    return verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM
                      ? submitService({
                          type: RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM,
                          data: {
                            otp: verify.otp?.otpInput as number,
                            transactionName: eformData.transactionName,
                            transactionCode: `${eformData.transactionType}-${eformData.collerationId}-${eformData.sendDate}`,
                            receiverPhone: eformData.phoneData.phoneNum,
                            payload: '',
                            authFlag: eformData.docReviewed,
                            attachments: x
                          }
                        })
                      : verify.authOption ===
                        RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM
                      ? submitService({
                          type: RequestAuthenticateData.RequestAuthOption
                            .OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM,
                          data: {
                            authFlag: eformData.docReviewed,
                            attachments: x
                          }
                        })
                      : submitService({
                          type: RequestAuthenticateData.RequestAuthOption.PAPER,
                          data: {
                            authFlag: eformData.docReviewed,
                            attachments: x
                          }
                        })
                  })
                ),
            ZIO.tap((e) => {
              showToast(t('message:RequestSendSuccessfully'), 'success')
              showGlobalLoading(false)
              requestAuthenForm.reset({
                authOption: RequestAuthenticateData.RequestAuthOption.OTP,
                otp: {
                  otpOption: RequestAuthenticateData.OTPOption.PHONE,
                  otpInput: undefined,
                  otpConsent: false,
                  authentication_option: false,
                  authentication_data: []
                },
                paper: {
                  data: [],
                  consent: false
                }
              })
              if (props.route.params?.isCCE === '1') {
                linkTo(`${BuildConfig.contextPath}/user/drawer/staff-submission/contact-center`)
              } else {
                props.navigation.navigate('PSSubmissionListScreen', {
                  policyNum: policyInfo.policyNum,
                  officeCode: props.route.params.officeCode,
                  officeName: props.route.params.officeName,
                  policyOwner: props.route.params.policyOwner,
                  primaryId: policyInfo.clientId
                })
              }
              return ZIO.unit
            }),
            ZIO.mapError((err) => {
              let getErrorMess = ''
              if (err.source && err.source.message.includes(' - ')) {
                const code = err.source.message.split(' - ')[0]
                const time = err.source.message.split(' - ')[1].replace('t=', '')
                getErrorMess =
                  code === 'MS050252'
                    ? t('message:MS050252', { t: time })
                    : code === 'MS050254'
                    ? t('message:MS050254', { t: time })
                    : code === 'MS050253'
                    ? t('message:MS050253')
                    : code === 'MS050255'
                    ? t('message:MS050255')
                    : 'Unknown error'
              } else {
                getErrorMess = t('message:MS050001')
              }
              showToast(getErrorMess, 'error')
              showGlobalLoading(false)
              // return Throwable('Done')
              return getErrorMess
            }),
            ZIO.unsafeRun({})
          )
        : showOTPErrorMsg(verify, isValidAuthDoc, isValidAuthFlag)
    }
  }

  const showOTPErrorMsg = (
    verify: RequestAuthenticateData.RequestAuthFormValue,
    isValidAuthDoc: boolean,
    isValidAuthFlag: boolean
  ) => {
    showGlobalLoading(false)
    showToast(
      verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
        ? t('OTP:inputValid')
        : !isValidAuthDoc
        ? t('message:MS050262')
        : !isValidAuthFlag
        ? t('message:MS050261')
        : 'Unknown Error',
      'error'
    )
    return false
  }

  const updateSendDate = (sendDate: string) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      sendDate: sendDate
    }
    setEformData(eFormItem)
  }

  const updatedocReviewed = (docReview: boolean) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      docReviewed: docReview
    }
    setEformData(eFormItem)
  }

  let validateForm: PolicyServiceSubmit<unknown> = async () => {
    return false
  }
  let clearForm: PolicyServiceClear = () => {
    // do nothing
  }

  const initSubmission = ({
    validate,
    clear
  }: {
    validate: PolicyServiceSubmit<unknown>
    clear: PolicyServiceClear
  }) => {
    validateForm = validate
    clearForm = clear
  }

  const validateRequestAuthenForm = () => {
    if (validateSubmitedButtonForAddLack() || validateSubmitedButtonForJournal()) {
      return true
    } else if (watch('authOption') === RequestAuthenticateData.RequestAuthOption.PAPER) {
      return watch('paper.consent')
    } else if (watch('authOption') === RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM) {
      return !!watch('otp.otpInput') && watch('paper.consent')
    } else if (
      watch('authOption') ===
      RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM
    ) {
      const authentication_data =
        watch('otp.authentication_data') !== undefined && (watch('otp.authentication_data') || [])?.length > 0
      return authentication_data && watch('paper.consent')
    }
    {
      return !!watch('otp.otpInput') && watch('otp.otpConsent')
    }
  }

  const checkSignatureForPaperForm = (verify: RequestAuthenticateData.RequestAuthFormValue) => {
    if (validateSubmitedButtonForAddLack() || validateSubmitedButtonForJournal()) {
      return true
    } else {
      const isValidOTP: boolean =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP &&
        verify.otp.otpInput?.toString().length === 6
      const isValidAuthFlag =
        (verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER ||
          verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM ||
          verify.authOption ===
            RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM) &&
        eformData.docReviewed
      const isValidAuthDoc =
        (verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER ||
          verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM ||
          verify.authOption ===
            RequestAuthenticateData.RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM) &&
        (verify.paper.data.length > 0 || isByPassAuthPaper())

      const checkedFlag =
        !isValidOTP && (!isValidAuthFlag || !isValidAuthDoc)
          ? showOTPErrorMsg(verify, isValidAuthDoc, isValidAuthFlag)
          : true
      return checkedFlag
    }
  }

  const validateSubmitedButtonForAddLack = () => {
    switch (props.route.params?.transactionName as TransactionType) {
      case TransactionType.ADD_LACK_OF_DOCUMENT:
        return true
      default:
        return false
    }
  }

  const validateSubmitedButtonForJournal = () => {
    switch (props.route.params?.transactionName as TransactionType) {
      case TransactionType.JOURNAL_PAYOUT:
      case TransactionType.JOURNAL_ALTERATION:
        return true
      default:
        return false
    }
  }

  console.log('POClientID :' + JSON.stringify(props.route.params?.POClientID))

  const transactionRender = () => {
    switch (props.route.params?.transactionName as TransactionType) {
      case TransactionType.POLICY_CERTIFICATE:
        return <PolicyCertificate initSubmission={initSubmission} isConfirmed={tabIndex === 1} />

      case TransactionType.ASSIGNMENT:
        return (
          <Assignment
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )

      case TransactionType.INHERITANCE:
        return (
          <Inheritance
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )

      case TransactionType.HEALTH_DECLARATION:
        return (
          <HealthRedeclaration
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )

      case TransactionType.STOP_APL:
        return (
          <StopAPL
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )

      case TransactionType.CHANGE_SUM_ASSURED:
        return (
          <ChangeSumAssured
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )

      case TransactionType.MATURITY:
        return (
          <MaturityPayment
            officeCode={props.route.params?.officeCode}
            policyNumber={props.route.params?.policyNum}
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
          />
        )

      case TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT:
        return (
          <ConfirmOfPremiumReceipt
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          ></ConfirmOfPremiumReceipt>
        )
      case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE:
        return (
          <ConfirmationLetterOfPolicy
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          ></ConfirmationLetterOfPolicy>
        )

      case TransactionType.CHANGE_CONTACT_INFO:
        return (
          <ChangeContactInfoScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            isCCE={props.route.params?.isCCE === '1'}
            clientNumber={policyInfo.clientId}
            requestAuthenData={eformData as RequestAuthenticateData.EformRequestAuthenticateData}
          />
        )

      case TransactionType.FATCA_DECLARATION:
        return (
          <FatcaDeclarationScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )

      case TransactionType.REINSTATEMENT:
        return (
          <ReinstatementScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )
      case TransactionType.RIDER_REINSTATEMENT:
        return (
          <RiderReinstatementScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )
      case TransactionType.CHANGE_PREMIUM:
        return (
          <ChangePremium
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )

      case TransactionType.BILLING_FREQUENCY_REVERSAL:
        return (
          <BillingChangeReversalScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          ></BillingChangeReversalScreen>
        )
      case TransactionType.PRUKID369:
        return (
          <PruKid369
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          ></PruKid369>
        )
      case TransactionType.GO_ABROAD:
        return (
          <GoAboard
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.CANCEL_FROM_INCEPTION:
        return (
          <CancelFromInceptionScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          ></CancelFromInceptionScreen>
        )
      case TransactionType.BILLING_CHANGE_CORPORATE:
      case TransactionType.BILLING_CHANGE:
        return (
          <BillingChangeScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.SPECIAL_BONUS:
        return (
          <SpecialBonus
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params.officeCode}
          />
        )
      case TransactionType.BONUS_SURRENDER:
        return (
          <BonusSurrender
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )
      case TransactionType.BENEFICIARY_DESIGNATION:
        return (
          <BeneficiaryDesignation
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode ?? ''}
            clientNumber={props.route.params?.POClientID ?? ''}
          />
        )
      case TransactionType.ADD_LACK_OF_DOCUMENT:
        return (
          <AddLackofDocument
            policyServiceData={{
              initSubmission: initSubmission,
              isConfirmed: tabIndex === 1,
              policyNumber: props.route.params?.policyNum,
              officeCode: props.route.params?.officeCode
            }}
            requestAuthenData={eformData as RequestAuthenticateData.EformRequestAuthenticateData}
            updateRequestAuthenData={setEformData}
          ></AddLackofDocument>
        )
      case TransactionType.FUND_SWITCHING:
        return (
          <FundSwitchingScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.SIGNATURE_REGISTRATION:
        return (
          <SignatureRegistrationScreen
            officeCode={props.route.params?.officeCode}
            clientId={policyInfo.clientId}
            eformData={eformData}
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            optionFlag={RequestAuthenticateData.OptionFlag[requestAuthenForm.getValues()?.authOption]}
            policyNumber={props.route.params?.policyNum}
          ></SignatureRegistrationScreen>
        )
      case TransactionType.EXCESS_PREMIUM:
        return (
          <ExcessPremium
            policyNumber={props.route.params?.policyNum}
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            officeCode={props.route.params?.officeCode}
            clientNumber={props.route.params?.POClientID ?? ''}
          />
        )

      case TransactionType.E_SERVICE_REGISTRATION:
        return (
          <EServiceRegistration
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.LOAN_STATEMENTS:
        return (
          <LoanStatementes
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.PAID_UP:
        return (
          <PaidUpScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          ></PaidUpScreen>
        )
      case TransactionType.CHANGE_CLIENT_INFO:
        return (
          <ChangeClientInfo
            policyNumber={props.route.params?.policyNum}
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            officeCode={props.route.params?.officeCode}
          />
        )
      case TransactionType.PREMIUM_REDIRECTION:
        return (
          <PremiumRedirectionScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.CANCEL_RIDER:
        return (
          <CancelRiderScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )

      case TransactionType.PRUCASH_PAYMENT:
        return (
          <PrucashPayment
            officeCode={props.route.params?.officeCode}
            policyNumber={props.route.params?.policyNum}
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
          />
        )

      case TransactionType.LOAN:
        return (
          <LoanPayment
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )

      case TransactionType.MATURITY_ADVANCE:
        return (
          <MaturityAdvance
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )

      case TransactionType.TOP_UP:
        return (
          <TopUpScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )
      case TransactionType.COMMON_REQUEST_FORM:
        return (
          <CommonRequestFormScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          ></CommonRequestFormScreen>
        )
      case TransactionType.SCHEDULED_PAYMENT_STATEMENT:
        return (
          <SchedulePaymentState
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
          />
        )

      case TransactionType.EXCELLENT_STUDENT_AWARD:
        return (
          <ExcellentStudentAward
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )
      case TransactionType.REDATING:
        return (
          <RedatingScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )

      case TransactionType.PRODUCT_OPTION_SWITCHING:
        return (
          <ProductOptionSwitching
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )

      case TransactionType.FULL_SURRENDER:
        return (
          <FullSurrender
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
            clientNumber={props.route.params?.POClientID ?? ''}
          />
        )

      case TransactionType.PARTIAL_SURRENDER:
        return (
          <PartialSurrender
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )

      case TransactionType.PARTIAL_WITHDRAWAL:
        return (
          <PartialWithdrawal
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
            clientNumber={props.route.params?.POClientID ?? ''}
          />
        )
      case TransactionType.FULL_SURRENDER_REVERSAL:
        return (
          <FullSurrenderReversal
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )
      case TransactionType.RIDER_ALTERATION:
        return (
          <RiderAlteration
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            initSubmission={initSubmission}
            officeCode={props.route.params?.officeCode}
          ></RiderAlteration>
        )
      case TransactionType.MAJOR_COMBINED_CHANGE:
        return (
          <MajorCombinedScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            officeCode={props.route.params?.officeCode}
          />
        )

      case TransactionType.JOURNAL_ALTERATION:
        return (
          <TranfersJournalScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            groupName={'alteration'}
          />
        )

      case TransactionType.JOURNAL_PAYOUT:
        return (
          <TranfersJournalScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            policyNumber={props.route.params?.policyNum}
            groupName={'payout'}
          />
        )

      case TransactionType.CHANGE_NATIONAL_ID_CARD:
        return (
          <ChangeNationalIDCardScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            groupName={'changeNationalIDCard'}
            policyInfo={policyInfo}
            setTabIndex={setTabIndex}
            setEformData={setEformData}
            officeCode={props.route.params?.officeCode}
          />
        )

      case TransactionType.CHANGE_OCCUPATION_PERSONAL:
        return (
          <ChangeOccupationScreen
            initSubmission={initSubmission}
            isConfirmed={tabIndex === 1}
            officeCode={props.route.params?.officeCode}
          />
        )
      case TransactionType.CHANGE_DOB_GENDER:
        return <ChangeDOBAndGenderScreen initSubmission={initSubmission} isConfirmed={tabIndex === 1} />

      case TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL:
        return <ChangeOtherInfomationPersonalScreen initSubmission={initSubmission} isConfirmed={tabIndex === 1} />

      default:
        return <></>
    }
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <PolicyInfoHeader menus={headers} />
        <TransactionWrapper
          tab={{
            isShowed: !validateSubmitedButtonForAddLack(),
            current: tabIndex,
            isValidated: true,
            setTab: setTabIndex
          }}
          requestAuthenData={eformData as RequestAuthenticateData.EformRequestAuthenticateData}
          authenForm={requestAuthenForm}
          updateSendDate={updateSendDate}
          updatedocReviewed={updatedocReviewed}
          updateRequestAuthenData={setEformData}
          isByPassAuthPaper={isByPassAuthPaper()}
          transactionInfos={transactionInfos()}
          transactionType={props.route.params?.transactionName}
          officeCode={props.route.params.officeCode}
          noAuthen={validateSubmitedButtonForJournal()}
        >
          {transactionRender()}
        </TransactionWrapper>
      </ScrollView>
      <TaskDetailAction
        listAction={[
          {
            name: t('submission:ClearAll'),
            action: () => {
              clearForm()
            },
            isVisible: tabIndex === 0
          },
          {
            name: t('submission:Continue'),
            action: async () => {
              const policyPayLoadData = await validateForm(true)
              let isChangePhone = false
              if (props.route.params?.transactionName === TransactionType.CHANGE_CONTACT_INFO) {
                const changeSubmit = policyPayLoadData as PolicyServicePayload<ChangeContactInfo.DataSubmit>
                if (
                  changeSubmit.body &&
                  changeSubmit.body.contactDetails &&
                  changeSubmit.body.contactDetails.PHONE?.value &&
                  changeSubmit.body.contactDetails.OFFICE_PHONE
                ) {
                  isChangePhone = true
                } else {
                  isChangePhone = false
                }
              }

              if (policyPayLoadData) {
                setEformData({
                  ...eformData,
                  transactionName: policyPayLoadData.transactionName,
                  collerationId: policyPayLoadData.collerationId,
                  transaction: policyPayLoadData.transaction,
                  isHideRequestInfo: isChangePhone,
                  isHideOTP: isChangePhone
                })
                setTabIndex(1)
              }
            },
            isVisible: tabIndex === 0 && !validateSubmitedButtonForAddLack()
          },
          {
            name: t('submission:NewRequest'),
            action: () => {
              if (props.route.params?.isCCE === '1') {
                linkTo(`${BuildConfig.contextPath}/user/drawer/staff-submission/contact-center`)
              } else {
                props.navigation.navigate('PSSubmissionListScreen', {
                  policyNum: policyInfo.policyNum,
                  officeCode: props.route.params.officeCode,
                  officeName: props.route.params.officeName,
                  policyOwner: props.route.params.policyOwner,
                  primaryId: policyInfo.clientId
                })
              }
            },
            isVisible: tabIndex === 1
          },
          {
            name: t('submission:SendRequest'),
            action: async () => {
              const requestAuthenItem = requestAuthenForm.getValues()
              if (validateRequestAuthenForm() && checkSignatureForPaperForm(requestAuthenItem)) {
                const data = await validateForm()

                if (data) {
                  if (validateSubmitedButtonForAddLack() || validateSubmitedButtonForJournal()) {
                    setValue('otp.otpInput', 111111)
                  }
                  const bodyData =
                    transactionTypeItem === TransactionType.TOP_UP
                      ? { attributesExt: { TOP_UP: data.body } }
                      : data.body
                  onSubmitDataWithAuthen(
                    onSubmit(
                      data.url(policyInfo.policyNum),
                      { body: bodyData },
                      data.documents || [],
                      data.method,
                      data.uploadedFilesInfo
                    )
                  )(requestAuthenItem)
                }
              }
            },
            isSubmissionDisabled: !validateRequestAuthenForm(),
            // isHighLightBtn: validateSubmitedButtonForAddLack(),
            isHighLightBtn: false,
            isVisible: tabIndex === 1 || validateSubmitedButtonForAddLack()
          }
        ]}
      />
    </SafeAreaView>
  )
}
