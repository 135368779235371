import { Any, Task, ZIO } from '@mxt/zio'
import { POApi } from '@pulseops/common'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import * as R from 'fp-ts/Record'
import * as t from 'io-ts'
import { ContractDetail, ContractDetailC } from './model/contract-detail'
import { CountryC } from './model/country'
import { Benefit, BenefitC } from './model/benefit'
import { ContractInfoC } from './model/contract-info'
import { Employee, EmployeeC } from './model/employee'
import { HistoryItemC } from './model/history-item'
import { Shareholder, ShareholderC } from './model/shareholder'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { ProvinceType } from './model/province'
import { DistrictType } from './model/district'
import { DateFromISOString } from 'io-ts-types'
export namespace GeneralInfoApi {
  export const getAllContractInfo = (params: { page: number; size: number; masterContractNo?: string }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/contract-info/get-all')(
      t.type({
        data: t.array(ContractInfoC),
        total: t.number
      })
    )

  export const getContractInfo = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/contract-info/get')(
      t.type({
        dataContractInfo: ContractDetailC
      })
    )

  export const updateContractInfo = (body: ContractDetail) =>
    pipe(
      POApi.post('business-pulse/api/v1/contract-info/update')(
        t.type({
          contractInfo: ContractDetailC
        })
      )(mapUpdateBody(body)),
      ZIO.map((res) => res.contractInfo)
    )

  export const getContractInfoHistory = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/contract-info/get-history')(
      t.type({
        data: t.array(HistoryItemC),
        total: t.number
      })
    )

  export const getAllShareholder = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/shareholder/get-all')(
      t.type({
        data: t.array(ShareholderC),
        total: t.number
      })
    )

  export const getShareholder = (params: { masterContractNo: string; policyYear: number; shareHolderKey: string }) =>
    pipe(
      POApi.getConfig({ params })('business-pulse/api/v1/shareholder/get')(
        t.type({
          data: t.array(ShareholderC)
        })
      ),
      ZIO.map(({ data }) => A.head(data))
    )

  export const updateShareholder = (body: Shareholder) =>
    pipe(
      POApi.post('business-pulse/api/v1/shareholder/update')(
        t.type({
          shareHolder: ShareholderC
        })
      )(mapUpdateBody(body)),
      ZIO.map((res) => res.shareHolder)
    )

  export const getShareholderHistory = (params: {
    masterContractNo: string
    policyYear: number
    shareHolderKey: string
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/shareholder/get-history')(
      t.type({
        data: t.array(HistoryItemC),
        total: t.number
      })
    )

  export const getAllEmployee = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/employee/get-all')(
      t.type({
        data: t.array(EmployeeC),
        total: t.number
      })
    )

  export const updateStatus = (params: {
    masterContractNo: string
    policyYear: number
    memberRemovalEffectiveDate: string
    refundedPremium: number
    employeeKey: string
  }) =>
    pipe(
      POApi.post('business-pulse/api/v1/employee/update-status')(
        t.type({
          data: t.array(EmployeeC),
          employeeKey: Nullable(t.string),
          masterContractNo: Nullable(t.string),
          order: Nullable(t.string),
          page: t.number,
          policyYear: t.number,
          size: t.number,
          sort: Nullable(t.string),
          total: t.number,
          version: t.number
        })
      )({ ...params, status: 'inactive' }),
      ZIO.map((res) => res.data)
    )

  export const getEmployee = (params: { masterContractNo: string; policyYear: number; employeeKey: string }) =>
    pipe(
      POApi.getConfig({ params })('business-pulse/api/v1/employee/get')(
        t.type({
          data: t.array(EmployeeC)
        })
      ),
      ZIO.map(({ data }) => A.head(data))
    )

  export const updateEmployee = (body: Employee) =>
    pipe(
      POApi.post('business-pulse/api/v1/employee/update')(
        t.type({
          employee: EmployeeC
        })
      )(mapUpdateBody(body)),
      ZIO.map((res) => res.employee)
    )

  export const getEmployeeHistory = (params: {
    masterContractNo: string
    policyYear: number
    employeeKey: string
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/employee/get-history')(
      t.type({
        data: t.array(HistoryItemC),
        total: t.number
      })
    )

  export const getAllBenefit = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.getConfig({
      params: {
        ...params,
        size: 1000
      }
    })('business-pulse/api/v1/benefit/get-all')(
      t.type({
        data: t.array(BenefitC),
        total: t.number
      })
    )

  export const updateBenefit = (benefits: Benefit[]) =>
    pipe(
      POApi.post('business-pulse/api/v1/benefit/update')(
        t.type({
          benefits: t.array(BenefitC)
        })
      )({ benefits }),
      ZIO.map((res) => res.benefits)
    )

  export const getBenefitHistory = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/benefit/get-history')(
      t.type({
        data: t.array(HistoryItemC),
        total: t.number
      })
    )

  export const AmlResultC = t.type({
    scanDt: DateFromISOString,
    amlResult: t.string
  })
  export type AmlResult = t.TypeOf<typeof AmlResultC>

  export const ScanAmlContractBody = t.type({
    uuid: t.string,
    uniqueIdentifierAmlPolicyHolder: t.string,
    uniqueIdentifierAmlPolicyAdmin: t.string,
    uniqueIdentifierAmlLegal: t.string,
    amlResultPolicyHolder: t.string,
    amlResultPolicyAdmin: t.string,
    amlResultLegal: t.string,
    idNumberPolicyHolder: t.string,
    idNumberPolicyAdmin: t.string,
    idNumberLegal: t.string
    // uniqueIdentifierAml: t.string,
    // policyHolder: t.type({
    //   policyHolder: t.string,
    //   businessAddress: t.type({
    //     address: t.string,
    //     country: t.string,
    //     city: Nullable(t.string),
    //     district: Nullable(t.string),
    //     ward: Nullable(t.string),
    //     phoneNumber: t.string,
    //     email: t.string
    //   }),
    //   contactAddress: t.type({
    //     address: t.string,
    //     country: t.string,
    //     city: Nullable(t.string),
    //     district: Nullable(t.string),
    //     ward: Nullable(t.string),
    //     phoneNumber: t.string,
    //     email: t.string
    //   }),
    //   businessSector: t.string,
    //   businessLicense: t.string,
    //   dateOfRegistration: DateFromISOString,
    //   placeOfRegistration: t.string
    // }),
    // legalRepresentative: t.type({
    //   fullName: t.string,
    //   position: t.string,
    //   idNumber: t.string,
    //   dateOfBirth: DateFromISOString,
    //   gender: t.string,
    //   nationality: t.string,
    //   permanentAddress: t.string,
    //   country: t.string,
    //   residentAddress: t.string,
    //   country1: t.string
    // }),
    // policyAdmin: t.type({
    //   fullName: t.string,
    //   position: t.string,
    //   idNumber: t.string
    // })
  })
  export type ScanAmlContractBody = t.TypeOf<typeof ScanAmlContractBody>

  export const scanAmlContract = (body: ScanAmlContractBody): Task<void> => {
    return pipe(
      POApi.post('business-pulse/api/v1/contract-info/scan-aml')(
        t.type({
          result: t.literal('SUCCESS')
        })
      )(body),
      ZIO.asVoid
    )
  }

  export type ScanAmlShareholderBody = {
    uuid: string
    fullName: string
    dateOfBirth: Date
    gender: string
    idNumber: string
    nationality: string
    permanentAddress: string
    residentAddress: string
    position: string
    uniqueIdentifierAml: string
    amlResult: string
    clientId: string
  }

  export const scanAmlShareholder = (body: ScanAmlShareholderBody) => {
    return pipe(
      POApi.post('business-pulse/api/v1/shareholder/scan-aml')(
        t.type({
          result: t.literal('SUCCESS')
        })
      )(body),
      ZIO.asVoid
    )
  }

  export type ScanAmlEmployeeBody = {
    uuid: string
    dateOfBirth: number
    employeeId: string
    employeeName: string
    gender: string
    nationality: string
    occupation: string
    permanentAddress: string
    permanentAddressCountry: string
    residentialAddress: string
    residentialAddressCountry: string
    employeeNo: string
    uniqueIdentifierAml: string
    amlResult: string
    clientId: string
  }

  export const scanAmlEmployee = (body: ScanAmlEmployeeBody) => {
    return pipe(
      POApi.post('business-pulse/api/v1/employee/scan-aml')(
        t.type({
          result: t.literal('SUCCESS')
        })
      )(body),
      ZIO.asVoid
    )
  }

  export const getCountries = pipe(
    POApi.get('business-pulse/api/v1/get-countries')(
      t.type({
        body: t.array(CountryC)
      })
    ),
    ZIO.map((res) => res.body)
  )

  export const getProvinces = pipe(
    POApi.get(`business-pulse/api/v1/get-provinces`)(
      t.type({
        body: t.array(ProvinceType)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getDistricts = (provinceCode?: string) =>
    pipe(
      POApi.post(`business-pulse/api/v1/get-districts`)(
        t.type({
          body: t.array(DistrictType)
        })
      )({
        body: {
          provinceCode
        }
      }),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? [])
    )

  export const getWards = (data: { provinceCode?: string; districtCode?: string }) =>
    pipe(
      POApi.post(`business-pulse/api/v1/get-wards`)(
        t.type({
          body: t.array(DistrictType)
        })
      )({
        body: {
          provinceCode: data.provinceCode,
          districtCode: data.districtCode
        }
      }),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? [])
    )

  export const getAllLocation = pipe(
    POApi.get(`business-pulse/api/v1/get-all-location`)(
      t.type({
        body: t.array(
          t.type({
            code: t.string,
            ward: t.string,
            district: t.string,
            province: t.string,
            id: Maybe(t.string)
          })
        )
      })
    ),
    ZIO.map((res) =>
      res.body.reduce<{ wards: Record<string, string>; districts: Record<string, string> }>(
        (data, item) => {
          data.wards[item.code] = item.ward
          data.districts[item.code.substring(0, 4)] = item.district
          return data
        },
        { wards: {}, districts: {} }
      )
    ),
    ZIO.cached()
  )
  export const deleteEmployee = (params: { masterContractNo: string; policyYear: number; employeeKey: string }) =>
    pipe(
      POApi.post('business-pulse/api/v1/employee/delete')(
        t.type({
          errorCodes: Nullable(
            t.array(
              t.type({
                code: t.string
              })
            )
          )
        })
      )({ ...params }),
      ZIO.map((res) => res)
    )
}

export const mapUpdateBody = <A extends Record<string, Any>>(body: A): Any => {
  const mapper = <B extends Record<string, Any>>(o: Record<string, Any>): Any =>
    pipe(
      o,
      R.map((val) => {
        if (val instanceof Date) {
          return val.getTime()
        }
        if (t.record(t.string, t.unknown).is(val)) {
          return mapper(val)
        }
        return val
      })
    )
  return mapper(body)
}
