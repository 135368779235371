import { apply, option } from 'fp-ts'
import { flow, pipe } from 'fp-ts/function'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import moment from 'moment'
import { InquiryComplaintData } from '../InquiryComplaint'
// import { GeneralData } from '../../GeneralService'
import { CancelRider } from '../CancelRider'
import { CloudData } from 'libs/common/src/component/img-upload-multiple'
import * as O from 'fp-ts/Option'
import * as D from 'date-fns/fp'
import { InquiryComplaintService } from '../../InquiryComplaintService'

export interface VeriPayloadData {
  source: string
  createdBy: string
  documents:
  | {
    name: string
    url: string
  }[]
  | undefined
  primaryPolicyNo: string
  clientId: string
  officeCode: string
  createdDate: string
  body: {
    attributesExt: InquiryComplaintData.SubmitData
    auditDetail: {
      createTime: string
    }
  }
  additionalDocuments?: VeriAdditionalDocuments[]
}
export interface VeriAdditionalDocuments {
  transactionType: string
  attachmentList: CloudData[]
}

export interface VeriInquiryComplaintData {
  taskId: string
  processInstanceId: string
  tag: TransactionType.COMPLAINT_AND_INQUIRY | TransactionType.COMPLAINT | TransactionType.INQUIRY
  clientName: string
  submissionData: InquiryComplaintData.SubmitData
  veriPayloadData: VeriPayloadData
  groupList: InquiryComplaintData.IACGroup[]
  methodList: InquiryComplaintData.IACSources[]
  sourceList: InquiryComplaintData.CSSources[]
  laList: CancelRider.CanRiderClientInfo[]
  benList: InquiryComplaintData.ICbenInfo[]
  BORelationshipList: InquiryComplaintData.BORelationshipList[]
  CBrelationshipList: InquiryComplaintData.CBRelationshipList[]
  BERelationshipList: InquiryComplaintData.BERelationshipData[]
  createdDate: string
  createdBy: string
  subServiceTypeList: InquiryComplaintData.IACType[]
  subTransactionTypeList: InquiryComplaintData.IACSubtypes[]
  // evidenceStatusArr: InquiryComplaintData.IACReceiptType[]
  // financialNeedAnalysisList: InquiryComplaintData.IACReceiptType[]
  estimationClosingDate: string
  tat: string
  documentTypeList: InquiryComplaintService.DocTypeListWithMeta
  resolutionDataList: ResolutionDataList
  complaintedDataList: ComplaintedDataList
}

export interface VeriAgentInfo {
  agentCode: string
  agentName: string
  classCode: string
}

export interface ResolutionDataList {
  complaintAgainstList: InquiryComplaintService.VeriIACDiaryType[]
  resolutionList: InquiryComplaintService.VeriIACDiaryType[]
  responseMethodList: InquiryComplaintService.VeriIACDiaryType[]
  reasonForDealingList: InquiryComplaintService.VeriIACDiaryType[]
  correctiveSettlementList: InquiryComplaintService.VeriIACDiaryType[]
  reasonList: InquiryComplaintService.VeriIACDiaryType[]
  reasonForExceedingTAT: InquiryComplaintService.VeriIACDiaryType[]
  diaryTypeList: InquiryComplaintService.VeriIACDiaryType[]
}

export interface ComplaintedDataList {
  roleList: InquiryComplaintData.IACReceiptType[]
  generalAgencyList: InquiryComplaintData.IACSources[]
  evidenceStatusArr: InquiryComplaintData.IACReceiptType[]
  financialNeedAnalysisList: InquiryComplaintData.IACReceiptType[]
  receiptTypeList: InquiryComplaintData.IACReceiptType[]
}

export const InquiryComplaint = (
  detail: api.InquiryComplaint & api.TaskDetailBase,
  poName: string,
  coreDataInfo: InquiryComplaintData.DetailData,
  subServiceTypeList: InquiryComplaintData.IACType[],
  subTransactionTypeList: InquiryComplaintData.IACSubtypes[],
  // evidenceStatusArr: InquiryComplaintData.IACReceiptType[],
  // financialNeedAnalysisList: InquiryComplaintData.IACReceiptType[],
  complaintedDataList: ComplaintedDataList,
  resolutionDataList: ResolutionDataList,
  slaStandard: number | null,
  documentTypeArr: InquiryComplaintService.DocTypeListWithMeta
): VeriInquiryComplaintData => {
  const payLoadInfo = detail.payload.body.attributesExt
  const createTime = detail.payload.body.auditDetail?.createTime
  const transactionType = detail.transactionType

  console.log('payLoadInfo', payLoadInfo)
  // const createdBy = detail.payload.createdBy ?? ''
  // const createdByName = createdBy && createdBy.includes('@') ? createdBy.split('@')[0] : ''
  const COMPLAINT_MIS_MONEY_DETAILS =
    payLoadInfo.COMPLAINT_MIS_MONEY_DETAILS && payLoadInfo.COMPLAINT_MIS_MONEY_DETAILS.length > 0
      ? payLoadInfo.COMPLAINT_MIS_MONEY_DETAILS.map((item) => {
        return {
          hasReciept: item?.hasReciept ?? '',
          recieptType: item?.recieptType ?? '',
          recieptNum: item?.recieptNum ?? '',
          complaintAmount: item?.complaintAmount ?? 0,
          misPremiumDate: item?.misPremiumDate ?? '',
          evidenceStatus: item?.evidenceStatus ?? '',
          verifiedAmount: item?.verifiedAmount ?? 0
        }
      })
      : []
  const COMPLAINT_SALE_DETAILS =
    payLoadInfo.COMPLAINT_SALE_DETAILS && payLoadInfo.COMPLAINT_SALE_DETAILS.length > 0
      ? payLoadInfo.COMPLAINT_SALE_DETAILS.map((item) => ({
        agentCode: item?.agentCode ?? '',
        agentName: item?.agentName ?? '',
        financeNeedCode: item?.financeNeedCode ?? '',
        isPoSignedInProposal: item?.isPoSignedInProposal as boolean,
        isCustomerReceiveKit: item?.isCustomerReceiveKit as boolean,
        customerReceiveDate: item?.customerReceiveDate ?? '',
        evidence: item?.evidence as boolean
      }))
      : []
  const COMPLAINT_GA_DETAILS =
    payLoadInfo.COMPLAINT_GA_DETAILS && payLoadInfo.COMPLAINT_GA_DETAILS.length > 0
      ? payLoadInfo.COMPLAINT_GA_DETAILS.map((item) => ({
        generalAgency: item?.generalAgency ?? '',
        personComplaint: item?.personComplaint ?? '',
        role: item?.role ?? '',
        verifiedAmount: item?.verifiedAmount ?? 0,
        evidenceStatus: item?.evidenceStatus ?? ''
      }))
      : []
  const submissionData: InquiryComplaintData.SubmitData = {
    ...payLoadInfo,
    FCR: Boolean(payLoadInfo.FCR),
    HOT: Boolean(payLoadInfo.HOT),
    TOTAL_MIS_MONEY_AMT: payLoadInfo.TOTAL_MIS_MONEY_AMT ?? 0,
    SOURCE: payLoadInfo.SOURCE ?? '',
    METHOD: payLoadInfo.METHOD ?? '',
    RLA_WITH_PO: payLoadInfo.RLA_WITH_PO ?? '',
    GROUP_CODE: payLoadInfo.GROUP_CODE ?? '',
    REQUESTER_FULL_NAME: payLoadInfo.REQUESTER_FULL_NAME ?? '',
    REQUEST_WITH_ROLE: payLoadInfo.REQUEST_WITH_ROLE ?? '',
    REQUESTER_NATIONAL_ID: payLoadInfo.REQUESTER_NATIONAL_ID ?? '',
    CUSTOMER_CMT: payLoadInfo.CUSTOMER_CMT ?? '',
    RES_TO_CUSTOMER: payLoadInfo.RES_TO_CUSTOMER ?? '',
    TOUCH_POINT: '',
    CATEGORY_CODE: payLoadInfo.CATEGORY_CODE ?? '',
    SUB_SVC_TYPE_CODE: payLoadInfo.SUB_SVC_TYPE_CODE ?? '',
    SUB_TRANS_TYPE_CODE: payLoadInfo.SUB_TRANS_TYPE_CODE ?? '',
    COMPLAINT_MIS_MONEY_DETAILS: COMPLAINT_MIS_MONEY_DETAILS,
    COMPLAINT_SALE_DETAILS: COMPLAINT_SALE_DETAILS,
    COMPLAINT_GA_DETAILS: COMPLAINT_GA_DETAILS,
    CONSERVATION_INFO: payLoadInfo.CONSERVATION_INFO || undefined,
    CLOSED_INFO: payLoadInfo.CLOSED_INFO ?? '',
    TOTAL_VERIFIED_AMOUNT: payLoadInfo.TOTAL_VERIFIED_AMOUNT ?? 0,
    CALL_ID: payLoadInfo.CALL_ID ?? ''
  }

  const documents =
    !!detail.payload.documents && detail.payload.documents.length > 0
      ? detail.payload.documents.map((item) => ({
        name: item.name ?? '',
        url: item.url ?? ''
      }))
      : []

  const additionalDocuments = !!detail.payload.additionalDocuments
    ? detail.payload.additionalDocuments.map((item) => {
      const attachmentList = !!item.attachmentList
        ? item.attachmentList.map((subItem) => {
          const uploadedDate = !!subItem && !!subItem.uploadedDate ? new Date(subItem.uploadedDate) : new Date()
          // const tagItem = subItem.tag ? (subItem.tag === 'Cloud' ? 'Cloud' : 'Local' ) : 'Local'
          const attachmentItem: CloudData = {
            tag: 'Cloud',
            fileName: subItem.fileName ?? '',
            fileExtension: subItem.fileExtension ?? '',
            size: Number(subItem.size ?? 0),
            base64: subItem.base64 ?? '',
            uploadedDate: uploadedDate,
            url: subItem.url ?? '',
            blobInfo: subItem.url ?? '',
            storageConfig: {
              sas: subItem.storageConfig?.sas ?? ''
            }
          }
          return attachmentItem
        })
        : []
      return {
        transactionType: item.transactionType ?? '',
        attachmentList: attachmentList
      }
    })
    : undefined
  const veriPayloadData: VeriPayloadData = {
    source: detail.payload.source ?? '',
    createdBy: detail.payload.createdBy ?? '',
    documents: documents,
    primaryPolicyNo: detail.payload.primaryPolicyNo ?? '',
    clientId: detail.payload.clientId ?? '',
    officeCode: detail.payload.officeCode ?? '',
    createdDate: detail.payload.createdDate ?? '',
    body: {
      attributesExt: submissionData,
      auditDetail: {
        createTime: detail.payload.body.auditDetail?.createTime ?? ''
      }
    },
    additionalDocuments: additionalDocuments
  }

  console.log('sla standard', slaStandard)
  return {
    tag: transactionType,
    clientName: poName,
    submissionData: submissionData,
    taskId: detail.id,
    processInstanceId: detail.processInstanceId ?? '',
    groupList: coreDataInfo.groupList,
    methodList: coreDataInfo.methodList,
    sourceList: coreDataInfo.sourceList,
    laList: coreDataInfo.laList,
    benList: coreDataInfo.benList,
    BORelationshipList: coreDataInfo.BORelationshipList,
    CBrelationshipList: coreDataInfo.CBrelationshipList,
    BERelationshipList: coreDataInfo.BERelationshipList,
    createdDate: createTime ? moment(createTime).format('DD/MM/YYYY HH:mm') : '',
    createdBy: detail.payload.createdBy ?? '',
    subServiceTypeList,
    subTransactionTypeList,
    // evidenceStatusArr,
    // financialNeedAnalysisList,
    veriPayloadData: veriPayloadData,
    tat: slaStandard ? slaStandard.toString() : '-',
    documentTypeList: documentTypeArr,
    resolutionDataList: resolutionDataList,
    complaintedDataList: complaintedDataList,
    estimationClosingDate: pipe(
      apply.sequenceT(option.Apply)(
        O.fromNullable(slaStandard),
        pipe(
          O.fromNullable(createTime),
          O.map((createTime) => moment(createTime).toDate())
        )
      ),
      O.map(([slaStandard, createTime]) => {
        // const setWorkStart = flow(D.setHours(8), D.setMinutes(30))
        // const setWorkEnd = flow(D.setHours(17), D.setMinutes(30))
        // const hoursCount = 17 - 8

        // const startTime = pipe(
        //   D.isWithinInterval({
        //     start: setWorkStart(createTime),
        //     end: setWorkEnd(createTime)
        //   })
        //     ? createTime
        //     : pipe(createTime, D.addDays(1), setWorkStart),
        //   (time) => (D.isWeekend(time) ? pipe(time, D.nextMonday, setWorkStart) : time)
        // )

        // const fromDayStart = pipe(startTime, D.differenceInMinutes(setWorkStart(startTime)))

        // const totalMinutes = fromDayStart + slaStandard * 60
        // const addingDays = Math.floor(totalMinutes / (hoursCount * 60))
        // const addingMinutes = totalMinutes % (hoursCount * 60)

        // return pipe(startTime, setWorkStart, D.addBusinessDays(addingDays), D.addMinutes(addingMinutes))
        return pipe(createTime, D.addDays(slaStandard))
      }),
      O.fold(
        () => '-',
        (time) => moment(time).format('DD/MM/YYYY HH:mm')
      )
    )
  }
}
