import { SourceType } from '../SourceType'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CHANGE_OCCUPATION_PERSONAL)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    occupation: Maybe(t.string),
    attributesExt: Maybe(
      t.type({
        clientNum: Maybe(t.string),
        specificJob: Maybe(t.string),
        title: Maybe(t.string),
        workPlace: Maybe(t.string),
        workAddress: Maybe(t.string),
        salary: Maybe(t.string),
        class: Maybe(t.string)
      })
    )
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadPulse4Ops
  })
)

const Pruonline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPulse4Ops
  })
)

export const ChangeOccupationPersonal = t.union([Pulse4Ops, Zalo, Pruonline])
export type ChangeOccupationPersonal = t.TypeOf<typeof ChangeOccupationPersonal>
