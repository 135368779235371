import { form2, PulseOpsFormat } from '@pulseops/common'
import * as t from 'io-ts'
import i18next from 'i18next'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
export namespace CepFilterForm {
  const SelectOption = t.type({
    label: t.string,
    value: t.string
  })

  const MultipleSelectOption = t.array(SelectOption)

  const Base = t.type({
    toDate: form2.date.optional,
    fromDate: form2.date.optional,
    transactionType: MultipleSelectOption,
    source: MultipleSelectOption,
    category: form2.selectOption.optional,
    subServiceType: MultipleSelectOption,
    subTransactionType: MultipleSelectOption
  })
  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(
    Base,
    form2.refine(
      (l): l is Form => {
        return !!(l.fromDate && l.toDate && moment(l.fromDate).isSameOrBefore(l.toDate))
      },
      (l) => i18next.t('message:MS030044'),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
