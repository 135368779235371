import {
  FieldList,
  getPayeeNotPOData,
  Panel,
  PayoutChecker,
  PayoutPopup,
  PulseOpsFormat,
  TaskDetail,
  TaskDetailApi
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { PayoutMethod } from './common'

export const ExcessPremium = ({ detail }: { detail: TaskDetail.ExcessPremium }) => {
  const { t } = useTranslation()

  const fields = [
    {
      label: t('ExcessPremium:ExcessPremium'),
      value: PulseOpsFormat.thousandSepartor(detail.excessPremium),
      suffix: 'VND'
    }
  ]

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.totalAmount),

    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  return (
    <View>
      <Panel titleOutline={t('ExcessPremium:Info')} isExand={false} allowToggle={false}>
        <FieldList dataSource={fields} />
      </Panel>
      <PayoutMethod payeeData={viewPayeeData} payoutMethodData={payoutData}></PayoutMethod>
    </View>
  )
}
