import { TransactionStatus, TransactionStatusLabel } from '@pulseops/common'
import * as React from 'react'
import { StyleSheet, View, Text, ViewStyle } from 'react-native'

// const Status = {
//   IN_PROGRESS: {
//     label: 'In progress',
//     color: '#1EA5FC',
//     backgroundColor: 'rgba(86, 204, 242, 0.3)'
//   },
//   Pendding: {
//     label: 'Pendding',
//     color: '#FF6F00',
//     backgroundColor: 'rgba(242, 201, 76, 0.2)'
//   },
//   Error: {
//     label: 'Error',
//     color: '#ED1B2E',
//     backgroundColor: '#FFFFFF'
//   },
//   Done: {
//     label: 'Done',
//     color: '#4CAF50',
//     backgroundColor: 'rgba(111, 207, 151, 0.2)'
//   }
// }

interface Props {
  type: TransactionStatus
  containerStyle?: ViewStyle
}

export const TaskStatus = ({ type, containerStyle = {} }: Props) => {
  const { label, color, backgroundColor } = TransactionStatusLabel(type)
  return (
    <View style={[styles.container, containerStyle, { backgroundColor }]}>
      <Text style={[styles.lalel, { color }]}>{label}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'baseline',
    borderRadius: 16
    // maxHeight: 24
  },

  lalel: {
    fontSize: 13,
    fontWeight: '500',
    marginHorizontal: 16,
    marginVertical: 2,
    lineHeight: 24,
    whiteSpace: 'nowrap'
  }
})
