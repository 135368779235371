import { Color } from '@material-ui/lab'
import { AppContext, Input, InquiryComplaintService, ModalComponent, Select, Toast, useMobile } from '@pulseops/common'
import React, { useContext } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { DiaryForm } from './InquiryComplaintForm'

export type DiaryModalProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {}
  form: UseFormReturn<DiaryForm>
  diaryTypeList: InquiryComplaintService.VeriIACDiaryType[]
  onSubmit: () => void
}

export const DiaryModal = (props: DiaryModalProps) => {
  const { onClose, open } = props
  const { isWide } = useMobile()
  const { isGlobalLoadingVisible } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const { t, i18n } = useTranslation()

  const { control } = props.form

  const handleClose = () => {
    onClose()
    props.form.reset()
  }

  return (
    <ModalComponent
      title={t('requestInfo:AddNewDiary')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('QC:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('QC:Save'),
          type: 'filled',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39 },
          action: () => props.onSubmit()
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 15 }}>
          <Controller
            name="diaryType"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('requestInfo:DiaryType') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Select
                label={t('requestInfo:DiaryType')}
                required
                options={props.diaryTypeList.map((item) => ({
                  label: i18n.language === 'en' ? item.desc_en : item.desc_vi,
                  value: item.id
                }))}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View>
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020001', { field: t('requestInfo:Description') })}`
              },
              maxLength: {
                value: 10000,
                message: `${t('message:MaxLength', { field: '10000' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('requestInfo:Description')}
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                errorMessage={error?.message}
                multiline
                maxLength={10000}
                numberOfLines={4}
              />
            )}
          />
        </View>
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
