import { Throwable, ZIO } from '@mxt/zio'
import { ErrorHandling, Input, RequestTable, TableSetup, useMobile } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { View, StyleSheet, useWindowDimensions, Text, Platform } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { InfoGenInterface, infoGen, RadioBtnYN } from '../../common'
import { BOCompanyBenInfo, BOService } from '../../services'
import { BOGeneralInfoPolicyPopup } from './dialogs'
// import { useMobile } from '@pulseops/common'

export const BOGeneralInfoPolicyComponent = () => {
  const { isMobile, isWide } = useMobile()
  const { width } = useWindowDimensions()

  const detail = pipe(
    BOService.getDetail,
    ZIO.absolveOption(() => Throwable('DetailMissing')),
    ErrorHandling.runDidMount()
  )

  const agentInfo = [
    [
      { id: 'agentName', title: 'Họ và tên', value: detail?.agent.agentname || '-' },
      { id: 'agentCode', title: 'Mã số TVV', value: detail?.agent.agentCode || '-' }
    ],
    [
      { id: 'agentPhone', title: 'Số điện thoại', value: detail?.agent.agentMobile || '-' },
      { id: 'agentEmail', title: 'Email', value: detail?.agent.agentEmail || '-' }
    ]
  ]

  const companyInfo = [
    [{ id: 'companyName', title: 'Tên công ty / tổ chức', value: detail?.company.companyName || '-' }],
    [
      { id: 'companyMainAddress', title: 'Địa chỉ trụ sở chính', value: detail?.company.headquaterAddress || '-' },
      {
        id: 'companyMainAddressNation',
        title: 'Quốc gia - địa chỉ trụ sở chính',
        value: detail?.company.headquaterCountry || '-'
      }
    ],
    [
      { id: 'companyContactAddress', title: 'Địa chỉ liên lạc', value: detail?.company.contactAddress || '-' },
      {
        id: 'companyContactAddressNation',
        title: 'Quốc gia - địa chỉ liên lạc',
        value: detail?.company.contactCountry || '-'
      }
    ],
    [
      { id: 'companyPhone', title: 'Số điện thoại', value: detail?.company.mobile || '-' },
      { id: 'companyEmail', title: 'Thư điện tử', value: detail?.company.companyEmail || '-' }
    ],
    [{ id: 'businessField', title: 'Lĩnh vực kinh doanh', value: detail?.company.businessNature || '-' }],
    [
      { id: 'businessDoc', title: 'Giấy phép kinh doanh', value: detail?.company.companyBrNo || '-' },
      { id: 'businessRegisterDate', title: 'Ngày đăng ký kinh doanh', value: detail?.company.registrationDate || '-' },
      {
        id: 'businessRegisterLocation',
        title: 'Nơi đăng ký kinh doanh',
        value: detail?.company.registrationPlace || '-'
      }
    ]
  ]

  const representativeInfo = [
    [
      { id: 'repName', title: 'Họ và tên', value: detail?.company.legalRepresentative.repName || '-' },
      { id: 'repTitle', title: 'Chức vụ', value: detail?.company.legalRepresentative.repTitle || '-' }
    ],
    [
      {
        id: 'nationID',
        title: 'Số CMND / CCCD / Hộ chiếu',
        value: detail?.company.legalRepresentative.nationID || '-'
      },
      {
        id: 'nationIDCreatedDate',
        title: 'Ngày cấp',
        value: detail?.company.legalRepresentative.nationIDCreatedDate || '-'
      },
      {
        id: 'nationIDCreatedLocation',
        title: 'Nơi cấp',
        value: detail?.company.legalRepresentative.nationIDCreatedLocation || '-'
      }
    ],
    [
      { id: 'repDOB', title: 'Ngày sinh', value: detail?.company.legalRepresentative.repDOB || '-' },
      { id: 'repGender', title: 'Giới tính', value: detail?.company.legalRepresentative.repGender || '-' }
    ],
    [
      { id: 'repNation', title: 'Quốc tịch', value: detail?.company.legalRepresentative.repNation || '-' },
      {
        id: 'repOtherNation',
        title: 'Quốc tịch khác',
        value: detail?.company.legalRepresentative.repOtherNation || '-'
      },
      { id: 'repVISA', title: 'Thị thực nhập cảnh', value: detail?.company.legalRepresentative.repVISA || '-' }
    ],
    [
      { id: 'repPhone', title: 'Số điện thoại', value: detail?.company.legalRepresentative.repPhone || '-' },
      { id: 'repEmail', title: 'Thư điện tử', value: detail?.company.legalRepresentative.repEmail || '-' }
    ],
    [
      { id: 'repAddress', title: 'Địa chỉ thường trú', value: detail?.company.legalRepresentative.repAddress || '-' },
      {
        id: 'repAddressNation',
        title: 'Quốc gia - địa chỉ thường trú',
        value: detail?.company.legalRepresentative.repAddressNation || '-'
      }
    ],
    [
      {
        id: 'repAddressCurr',
        title: 'Nơi ở hiện tại',
        value: detail?.company.legalRepresentative.repAddressCurr || '-'
      },
      {
        id: 'repAddressCurrNation',
        title: 'Quốc gia - nơi ở hiện tại',
        value: detail?.company.legalRepresentative.repAddressCurrNation || '-'
      }
    ],
    [
      {
        id: 'repAddress1',
        title: 'Địa chỉ tại nước ngoài',
        value: detail?.company.legalRepresentative.repAddress1 || '-'
      },
      {
        id: 'repAddress1Nation',
        title: 'Quốc gia - địa chỉ tại nước ngoài',
        value: detail?.company.legalRepresentative.repAddress1Nation || '-'
      }
    ]
  ]
  const companyInfoExtra = {
    USrelated: detail?.company.USrelated,
    RegulatedStockSxchange: detail?.company.RegulatedStockSxchange
  }

  const [inputPart, setInputPart] = React.useState<InfoGenInterface[][]>([
    [
      { id: 'inputName', title: 'Họ và tên', value: '' },
      { id: 'inputTitle', title: 'Chức vụ', value: '' },
      { id: 'inputReprentative', title: 'Thay mặt cho', value: '' }
    ],
    [{ id: 'inputConfirmation', title: 'Xác nhận rằng', value: '', maxLength: 100, maxWidth: true }]
  ])

  const benRepresentativeInfo = [
    [
      { id: 'benRepName', title: 'Họ và tên', value: detail?.company.contactPerson.benRepName || '-' },
      { id: 'benRepTitle', title: 'Chức vụ', value: detail?.company.contactPerson.benRepTitle || '-' },
      {
        id: 'benRepNationID',
        title: 'Số CMND / CCCD / Hộ chiếu',
        value: detail?.company.contactPerson.benRepNationID || '-'
      }
    ],
    [
      { id: 'benRepPhone', title: 'Số điện thoại', value: detail?.company.contactPerson.benRepPhone || '-' },
      { id: 'benRepEmail', title: 'Thư điện tử', value: detail?.company.contactPerson.benRepEmail || '-' }
    ]
  ]

  const rows: {
    id: string
    name: string
    dob: string
    nationality: string
    fund: string
  }[] =
    detail?.company.beneficalOwner.map((x, i) => ({
      id: (i + 1).toString(),
      name: x.fullName,
      dob: x.dob,
      nationality: x.nationality,
      fund: x.capitalContribute
    })) || []
  const rowsSetup: TableSetup[] = [
    { title: 'STT', key: 'id' },
    { title: 'Họ và tên', key: 'name' },
    { title: 'Ngày sinh', key: 'dob' },
    { title: 'Quốc tịch', key: 'nationality' },
    { title: 'Tỷ lệ góp vốn', key: 'fund' }
  ]

  const infoMapper = infoGen(isMobile)
  const RadioBtnGroupYN = RadioBtnYN

  const [openPopup, setOpenPopup] = React.useState(false)

  const handleClickOpen = () => {
    setOpenPopup(true)
  }

  const handleClose = (value: any) => {
    setOpenPopup(false)
  }

  const [benOwnerData, setBenOwnerData] = React.useState<BOCompanyBenInfo | null>(null)

  return (
    <ScrollView
      style={{
        // width: width > 1099 ? width - 120 * 2 : "100%",
        width: width >= 1440 ? 1440 : '100%',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <View style={styles.sectionContainer}>
        <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input title={'Số HSYCBH'} value={detail?.policyNum || '-'} disabled={true} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input title={'Ngày lập HSYCBH'} value={detail?.createdDate || '-'} disabled={true} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input title={'Trạng thái HSYCBH'} value={detail?.status || '-'} disabled={true} />
          </View>
        </View>
        <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input title={'Ngày hiệu lực hợp đồng dự kiến'} value={detail?.effectiveDate || '-'} disabled={true} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input title={'Kỳ hạn đóng phí'} value={detail?.duePremiumDate || '-'} disabled={true} />
          </View>
        </View>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin TVV</Text>
          {infoMapper(agentInfo, 'agentKey')}
        </View>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin công ty/ tổ chức</Text>
          {infoMapper(companyInfo, 'companyKey')}
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Người đại hiện theo pháo luật</Text>
          {infoMapper(representativeInfo, 'repKey')}
          {RadioBtnGroupYN(
            'Công ty có cổ phiếu được niêm yết và giao dịch trên bất kỳ sàn cổ phiếu hợp pháp nào không?',
            companyInfoExtra.RegulatedStockSxchange
          )}
          {RadioBtnGroupYN(
            'Có cá nhân/tổ chức nào liên quan đến Hoa Kỳ, trực tiếp hoặc gián tiếp nắm giữ hơn 10% cổ phần không?',
            companyInfoExtra.USrelated
          )}
        </View>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Phần khai</Text>
          {infoMapper(inputPart, 'inputPart', true, (val: InfoGenInterface[][]) => {
            setInputPart(val)
          })}
        </View>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}>
            {' '}
            {'\u2022'} Người đại diện liên hệ của bên mua bảo hiểm
          </Text>
          {infoMapper(benRepresentativeInfo, 'benRepresentativeInfo')}
          <Text style={{ fontWeight: '900', marginBottom: 10 }}>
            {' '}
            {'\u2022'} Danh sách thành viên Hội đồng quản trị / Hội đồng thành viên, Ban giám đốc và các Cổ đông / Thành
            viên góp vốn chính *
          </Text>
          {isWide && Platform.OS === 'web' && rows.length > 0 && (
            <View style={{ marginTop: 10 }}>
              <RequestTable
                rows={rows}
                setup={rowsSetup}
                keyTitle={'benInfo'}
                actions={(i) => {
                  setBenOwnerData(detail?.company.beneficalOwner[i] || null)
                  handleClickOpen()
                }}
              ></RequestTable>
            </View>
          )}
        </View>
        <BOGeneralInfoPolicyPopup selectedValue={benOwnerData} open={openPopup} onClose={handleClose} />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9'
  },
  sectionContainer: {
    marginVertical: 5,
    marginHorizontal: 20,
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20
  },
  mobileField: {
    width: '100%',
    paddingBottom: 30
  },
  tabletField: {
    width: '33%',
    paddingBottom: 30,
    paddingRight: 30
  },
  infoContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: '#FFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20
  }
})
