import { enumC } from '@mxt/zio/codec'

export enum TaskSource {
  PULSE = 'PULSE',
  ZALO = 'ZALO',
  BANCA = 'BANCA',
  BANCA_SEA = 'BANCA_SEA',
  BANCA_SEABANK = 'BANCA_SEABANK',
  PRUONLINE = 'PRUONLINE',
  EKIOSK = 'EKIOSK',
  PULSE4OPS = 'PULSE4OPS',
  LANDING_PAGE = 'LANDING_PAGE',
  ONLINEPAYMENT = 'ONLINEPAYMENT',
  PRUDAILY = 'PRUDAILY',
  PRUFORCE = 'PRUFORCE',
  CREDITLIFEPORTAL = 'CREDITLIFEPORTAL',
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND'
}
export const TaskSourceC = enumC(TaskSource)
