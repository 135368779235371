import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Button } from '@pulseops/business/core'
import { AssignmentService, ErrorHandling, AppContext } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import _ from 'lodash'

export const AssignmentTab = () => {
  const { t } = useTranslation()
  const [checked, setChecked] = React.useState<boolean>(true)
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const theme = createMuiTheme({
    overrides: {
      MuiSwitch: {
        root: {
          width: '85px',
          height: '48px'
        },
        switchBase: {
          // transform: 'translateX(2px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(35px)'
          }
        },
        track: {
          opacity: 1,
          backgroundColor: '#BDBDBD',
          borderRadius: 22 / 2,
          '&:before, &:after': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 23,
            height: 23
          },
          '&:before': {
            right: '50%',
            content: `${t('common:ON')}`,
            fontSize: 9,
            textAlign: 'center',
            lineHeight: 2.5,
            color: 'white',
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: 400
          },
          '&:after': {
            left: '50%',
            content: `${t('common:OFF')}`,
            fontSize: 9,
            textAlign: 'center',
            lineHeight: 2.5,
            color: 'white',
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: 400
          },
          '$checked$checked + &': {
            opacity: 1,
            backgroundColor: 'red'
          }
        },
        thumb: {
          boxShadow: 'none',
          width: 32,
          height: 32,
          color: 'white'
        }
      }
    }
  })

  React.useEffect(() => {
    pipe(
      AssignmentService.getAssigneeState(),
      ZIO.map((res) => {
        setChecked(_.get(res, 'status', true))
      }),
      ErrorHandling.run()
    )
  }, [])

  const handleSubmit = React.useCallback(() => {
    pipe(
      AssignmentService.changeAssignee(checked),
      ZIO.map((res) => {
        setChecked(_.get(res, 'status', true))
        showToast(t('common:SaveSuccess'), 'success')
      }),
      ErrorHandling.run()
    )
  }, [checked])

  const handleChange = React.useCallback((event) => {
    setChecked(event.target.checked)
  }, [])

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{t('Setting:Assignment')}</Text>
      <View style={styles.wrapper}>
        <Text style={{ marginRight: '20px' }}>{t('Setting:AssignmentStatus')}</Text>
        <ThemeProvider theme={theme}>
          <FormGroup>
            <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="" />
          </FormGroup>
        </ThemeProvider>
      </View>
      <View style={styles.btnSave}>
        <Button title={t('common:Save')} onPress={handleSubmit} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30
  },

  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  wrapper: {
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 30
  },
  btnSave: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})
