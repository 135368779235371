import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'

export namespace DoNotCallService {
  export const DoNotCallDetailC = t.type({
    dncValueTel: Maybe(t.string),
    dncValueSms: Maybe(t.string),
    dncValueEmail: Maybe(t.string),
    sourceSystem: Maybe(t.string),
    dncIdPhone: Maybe(t.string),
    dncIdEmail: Maybe(t.string)
  })

  export type DoNotCallDetail = t.TypeOf<typeof DoNotCallDetailC>

  export const submitDoNotCallData = (data: DoNotCallDetail) =>
    pipe(POApi.post('wf-api/pulse/dnc/update-dnc')(t.unknown)(data))
}
