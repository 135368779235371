import * as React from 'react'
import { assets, ControlProps } from '@pulseops/common'
import { TouchableOpacity, Text, View, ColorValue, ViewStyle, StyleProp } from 'react-native'
import { Error } from './Error'

interface Props extends ControlProps<boolean> {
  title?: string
  enable?: boolean
  size?: number
  fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
  color?: ColorValue
  style?: StyleProp<ViewStyle>
  uppercase?: boolean
}

export const Checkbox = ({
  title,
  enable = true,
  size = 15,
  fontWeight = 'normal',
  color = '#000000',
  value,
  style,
  uppercase,
  onChange,
  onBlur,
  errorMessage
}: Props) => {
  return (
    <TouchableOpacity
      style={style}
      onBlur={onBlur}
      activeOpacity={1}
      disabled={!enable}
      onPress={() => onChange && onChange(!value)}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginEnd: title ? 12 : 0 }}>
          {enable ? (
            value ? (
              <assets.CheckBox_Checked_Active />
            ) : (
              <assets.CheckBox_Uncheck_Active />
            )
          ) : value ? (
            <assets.CheckBox_Checked_Disable />
          ) : (
            <assets.CheckBox_Uncheck_Disable />
          )}
        </View>
        {title && (
          <Text
            style={{
              fontSize: size,
              fontWeight: fontWeight,
              color: color,
              textTransform: uppercase ? 'uppercase' : 'none'
            }}
          >
            {title}
          </Text>
        )}
      </View>
      {errorMessage && <Error message={errorMessage} />}
    </TouchableOpacity>
  )
}
