import { StyleSheet } from 'react-native'
export const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '600',
    paddingTop: 13,
    textTransform: 'uppercase'
  },
  sectionContainer: {
    marginVertical: 5,
    marginHorizontal: 20,
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20
  },
  mobileField: {
    width: '100%',
    paddingBottom: 30
  },
  tabletField: {
    width: '33%',
    paddingBottom: 30,
    paddingRight: 30
  },
  tabletFieldBig: {
    width: '100%',
    paddingBottom: 30,
    paddingRight: 30
  },
  infoContainer: {
    marginVertical: 10,
    marginHorizontal: 10,
    paddingHorizontal: 20,
    paddingVertical: 20,
    backgroundColor: '#FFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20
  },
  actionBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    paddingHorizontal: 29,
    paddingVertical: 5.5
  }
})
