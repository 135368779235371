// import { Maybe } from '@mxt/zio/codec'
import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export const PolicyDigital = t.type({
  id: Maybe(t.string),
  policyNo: Maybe(t.string),
  inceptionDate: Maybe(t.string),
  status: Maybe(t.string),
  premiumStatus: Maybe(t.string),
  paymentOption: Maybe(
    t.type({
      currency: t.string
    })
  ),
  product: Maybe(
    t.type({
      code: t.string
    })
  ),
  productOptions: Maybe(
    t.array(
      t.type({
        id: Maybe(t.string),
        product: t.type({
          code: t.string,
          fullName: t.string
        }),
        totalPremium: t.number,
        riskCessDate: t.string,
        totalSumAssured: t.number,
        commencementDate: t.string,
        status: t.string,
        planName: Maybe(t.string),
        options: t.type({
          planName: Maybe(t.string)
        }),
        productName: Maybe(t.string),
        productComponentOptions: t.array(
          t.type({
            id: Maybe(t.string),
            component: t.type({
              id: Maybe(t.string),
              code: t.string,
              name: t.string
            }),
            options: t.type({
              totalSumAssured: t.string,
              totalPremium: Maybe(t.string),
              claimTemplateType: Maybe(t.string),
              claimantRole: Maybe(t.string)
            }),
            riskCessDate: t.string,
            status: t.string
          })
        )
      })
    )
  )
})

export type PolicyDigital = typeof PolicyDigital
