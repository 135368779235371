export namespace T18 {
  export const ProductTransaction = {
    cashBenefit: [
      'CIM2',
      'EAP1',
      'EAP2',
      'EAP4',
      'EAP5',
      'EAP6',
      'EMS1',
      'ENP2',
      'ENP5',
      'EUS1',
      'EUS3',
      'EUS4',
      'EUS5',
      'PRE1',
      'PRE2'
    ],
    changePremium: [],
    cancelRider: []
  }
}
