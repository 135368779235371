import React from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'

type WarningProps = {
  message: string
  style?: StyleProp<TextStyle>
}

export const Warning = ({ message, style }: WarningProps) => {
  return <Text style={[{ color: '#E6A90D', fontSize: 11.25 }, style]}>{message}</Text>
}
