import * as core from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { styled as styledM } from '@material-ui/core/styles'
import { TableContainerTypeMap } from '@material-ui/core/TableContainer'

export namespace Table {
  export const Container = styledM(core.TableContainer)({
    border: `1px solid #D3DCE6`,
    borderWidth: 1,
    borderRadius: 8
  }) as OverridableComponent<TableContainerTypeMap>

  export const Main = styledM(core.Table)({})

  export const Head = styledM(core.TableHead)({
    background: '#F4F4F4'
  })

  export const Body = styledM(core.TableBody)({})

  export const CellHead = styledM(core.TableCell)({
    fontWeight: 'bold',
    color: '#70777E',
    width: '100%'
  })

  export const Cell = styledM(core.TableCell)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  })

  export const Row = styledM(core.TableRow)({
    display: 'flex'
  })

  export const NoBorderTableCell = styledM(Cell)({
    border: 'unset'
  })
}
