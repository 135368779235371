import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { PulseOpsFormat } from '../../../Formatter'

export interface StopApl {
  tag: TransactionType.STOP_APL
  clientName: string
  aplDate: string
  reason: string
}

export const StopApl = (detail: api.StopApl, poName: string): StopApl => {
  const { aplDate, reason } = detail.payload.body
  return {
    tag: TransactionType.STOP_APL,
    clientName: poName ?? '',
    aplDate: PulseOpsFormat.dateStrWF(aplDate, 'DD/MM/YYYY'),
    reason
  }
}
