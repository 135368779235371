import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { SubmissionService } from './SubmissionService'

export namespace ChangePremiumService {

  export interface InputPolicyForLAS {
    policyNum: string
    newInstallmentPremium: number
    currentInstallmentPremium: number
  }

  export const getDetail = (policyNum: string) =>
    pipe(
     
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNum}/change-premium/get-data`)(
          t.type({
            body: t.type({
              coverageCode: t.string,
              curRenewalPremium: t.number,
              curSumAssured: t.number,
              minSAM: t.number,
              maxSAM: t.number,
              minAPI: t.number
            })
          })
        ),
        SubmissionService.getAccountBalance(policyNum, 'T542', [
          { sacsCode: 'LP', sacsType: 'S' },
          { sacsCode: 'LP', sacsType: 'U' }
        ])
      ),
      ZIO.map(([premiumData,accountBalance]) => {
        const suspenseAmount = accountBalance.body.accountGroups?.reduce(function (total, next) {
          return ['S', 'U'].includes(String(next.sacsType)) ? total + Number(next.amount) : total
        }, 0) ?? 0
        return {
          ...premiumData.body,
          suspenseAmount
        }
      })
    )

  export const checkValidPolicyFromLAS = (data: InputPolicyForLAS) => pipe(
    POApi.post(`wf-api/policy/${data.policyNum}/change-premium/continue`)(t.type({
      responseStatus: Maybe(
        t.type({
          code: t.number,
          message: t.string
        })
      )
    }))({
      body: {
        newInstallmentPremium: data.newInstallmentPremium,
        currentInstallmentPremium: data.currentInstallmentPremium
      }
    }),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (success) => ZIO.succeed(success?.responseStatus)
    )
  )
}
