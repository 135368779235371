import * as React from 'react'
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import { generalStyles as styles } from '../../common'
import { ClaimService } from '../../ClaimService'
import { DatePicker, Form, RadioButton, SelectSearch, useMobile, assets } from '@pulseops/common'
import { RequestInfoProps } from '../RequestInfoProps'
import { maxCurrentDate } from '@pulseops/submission/common'
import { FieldError } from 'react-hook-form'

export const ClaimCI = (props: RequestInfoProps) => {
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation('claim')
  const tcommon = useTranslation().t
  const { form } = props
  const register = Form.register(form)
  const { watch } = form
  const { isRelatedToAccident, diagnosis } = watch()
  const errors = form.formState.errors

  const radioGroups = [
    {
      code: 'Y',
      label: tcommon('common:Yes')
    },
    {
      code: 'N',
      label: tcommon('common:No')
    }
  ]

  const translator = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)

  const deleteDiagnosis = (idx: number) => {
    const list = [...diagnosis]
    list.splice(idx, 1)
    form.setValue('diagnosis', list)
  }

  return (
    <>
      <Text style={{ fontSize: 13, fontWeight: 'bold', margin: 18, marginBottom: 20, textTransform: 'uppercase' }}>
        {t('CriticalIllness')}
      </Text>
      <View style={{ flexDirection: 'column', flexWrap: 'wrap', padding: 18 }}>
        <View style={{ flexDirection: isMobile ? 'column' : 'row', flex: 1 }}>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Text style={{ fontWeight: 'bold', color: '#70777E', paddingLeft: 4, paddingBottom: 3, fontSize: 15 }}>
              {t('RelatedToAccident')}? <Text style={{ color: '#ed1b2c', fontSize: 15 }}>*</Text>
            </Text>
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              {radioGroups.map((r, i) => (
                <RadioButton
                  {...register('isRelatedToAccident', {
                    required: `${t('message:MS020001', { field: t('RelatedToAccident') })}`
                  })}
                  key={i}
                  label={r.label}
                  value={r.code}
                  selected={isRelatedToAccident === r.code}
                  onChange={(vl) => {
                    form.setValue('isRelatedToAccident', vl)
                  }}
                />
              ))}
            </View>
            {!isRelatedToAccident && errors.isRelatedToAccident?.message && (
              <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errors.isRelatedToAccident?.message}</Text>
            )}
          </View>
          {/* date */}
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <DatePicker
              {...register('claimEventDate', {
                required: `${t('message:MS020001', { field: t('ClaimEventDate') })}`,
                valueAsDate: true,
                validate: maxCurrentDate(tcommon('message:MS070008'))
              })}
              required
              label={t('ClaimEventDate')}
              errorMessage={errors.claimEventDate?.message || ''}
            />
          </View>
        </View>
        <View style={isMobile ? styles.mobileField : { width: '64%', paddingBottom: 30 }}>
          <SelectSearch
            required={true}
            {...register('diagnosis', {
              required: `${t('message:MS020001', { field: t('Diagnosis') })}`,
              pattern: {
                value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                message: t('message:MS050223')
              }
            })}
            multiple
            label={t('Diagnosis')}
            placeholder={t('common:EnterAdditionalDiagnosis')}
            errorMessage={(form.formState.errors.diagnosis as FieldError | undefined)?.message || ''}
            searchOptions={ClaimService.searchDiagnostic(translator)}
            key={diagnosis && diagnosis.length}
          />
        </View>
        <View style={[styles.sectionContainer, { width: '64%' }]}>
          <Text style={{ fontWeight: 'bold', color: '#70777E', marginBottom: 10 }}>{t('Diagnosis')}</Text>
          {diagnosis &&
            diagnosis.map((item, index) => (
              <View style={[style.cause, { flexDirection: 'row' }]}>
                <Text>
                  {item.value} - {item.label}
                </Text>
                <TouchableOpacity
                  hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                  onPress={() => deleteDiagnosis(index)}
                  style={{ padding: 5 }}
                >
                  <assets.DeleteIcon />
                </TouchableOpacity>
              </View>
            ))}
        </View>
      </View>
    </>
  )
}

const style = StyleSheet.create({
  cause: {
    borderBottomColor: '#D3DCE6',
    borderBottomWidth: 1,
    marginTop: 15,
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})
