import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { getCoreDetail, getPayOutDetail } from './common/payout'
import { PaymentType } from '../PaymentType'
import { ClientPolicy } from '../Client'
import { TransactionType } from '../TransactionType'

export interface FullSurrender {
  tag: TransactionType.FULL_SURRENDER

  cashDeposit: number
  suspenseAmount: number
  policyLoans: number
  policyDebt: number
  surrenderCharge: number
  totalSurrenderAmount: number

  totalAmount: number
  methods: PaymentMethod[]
  inheritanceFlag: boolean
  deathDate: string
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
  seaBankWarningMsg?: string
  sourceType: string
}

export const FullSurrender = (
  poName: string,
  detail: api.FullSurrender & api.TaskDetailBase,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string,
  seaBankWarningMsg?: string
): FullSurrender => {
  const p = detail.payload
  const coreStatus = getCoreDetail(detail.coreResponseDetail, p.body.cashOutOptions)
  const cashOutList =
    detail.payload && detail.payload.body
      ? detail.payload.body.cashOutOptions?.filter(
          (x) =>
            PaymentType.BANKTRANSFER === x.type ||
            PaymentType.PAID_AT_BANK === x.type ||
            PaymentType.CASH_AT_COUNTER === x.type
        )
      : []
  return {
    tag: TransactionType.FULL_SURRENDER,

    cashDeposit: p.body.attributesExt.CASH_DEPOSIT,
    suspenseAmount: p.body.attributesExt.SUSPENSE_AMOUNT,
    policyLoans: p.body.attributesExt.POLICY_LOAN,
    policyDebt: p.body.attributesExt.POLICY_DEBT,
    surrenderCharge: p.body.attributesExt.SURRENDER_CHARGE,
    totalSurrenderAmount: p.body.attributesExt.TOTAL_SURRENDER_AMOUNT,

    totalAmount: p.body?.cashOutOptions?.reduce((total, next) => total + (next.paymentOption.amount ?? 0), 0) ?? 0, // missing
    cashOutList: cashOutList,
    branchName: branchName,
    seaBankWarningMsg: seaBankWarningMsg,
    sourceType: detail.source ?? '',
    methods: p.body
      ? p.body.cashOutOptions?.map((v, i) => ({
          method: v.type || '-',
          detail: getPayOutDetail(
            {
              type: v.type as PaymentType,
              officeCode:
                (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) || '',

              bankAccount: {
                accountName:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                  (v.type === PaymentType.PAID_AT_BANK &&
                    (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                      ? poName
                      : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                  (v.type === PaymentType.CASH_AT_COUNTER &&
                    (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                      ? poName
                      : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                  ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                  '-',
                accountNo:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                  ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                    detail.clientNumber) ||
                  ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                    detail.clientNumber) ||
                  ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                  '-',
                bankName:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                  (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                  '-',
                branchName:
                  (v.type === PaymentType.BANKTRANSFER && branchName) ||
                  (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                  undefined,
                bankCode: '-',
                branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
              },
              phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
              issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
              issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
              policy: {
                policyNo: v.policy?.policyNo || '-'
              }
            },
            clientPolicy
          ),
          amount: v.paymentOption.amount || 0,
          status: coreStatus[i].status,
          note: coreStatus[i].errorMes,
          action: '-',
          bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
        })) || []
      : [],
    inheritanceFlag: p.body.policy.owners.attributesExt.HAS_INHERITANCE,
    deathDate: p.body.policy.owners.dateOfDeath
  }
}
