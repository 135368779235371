import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { RequestAuthFormDataSubmit, SubPremiumRedirectionData } from './model'
import { SubmissionService } from './SubmissionService'

export namespace PremiumRedirectionService {
  export const detail = (policyNum: string): Task<SubPremiumRedirectionData.detail> =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/premium-redirection-action-b`)(
          t.type({
            body: t.type({
              status: t.string,
              policyNo: t.string,
              fundSplitPlan: t.string,
              prc01: t.number,
              fund01: t.string,
              prc02: t.number,
              fund02: t.string,
              prc03: t.number,
              fund03: t.string,
              prc04: t.number,
              fund04: t.string,
              prc05: t.number,
              fund05: t.string,
              prc06: t.number,
              fund06: t.string,
              prc07: t.number,
              fund07: t.string,
              prc08: t.number,
              fund08: t.string,
              prc09: t.number,
              fund09: t.string,
              prc10: t.number,
              fund10: t.string
            })
          })
        )({
          body: {
            policyNo: policyNum
          }
        }),
        SubmissionService.getPolicy(policyNum)
      ),
      ZIO.map(([fundInfo, policyInfo]) => {
        const fundCodeList = Object.entries(fundInfo.body)
          .filter(([key, value]) => {
            return key.includes('fund0') || key.includes('fund1')
          })
          .map(([key, value]) => String(value))
        const percentList = Object.entries(fundInfo.body)
          .filter(([key, value]) => {
            return key.includes('prc')
          })
          .map(([key, value]) => Number(value))
        const fundList = fundCodeList
          .filter((x) => !!x)
          .map((item, index) => {
            return {
              fundCode: item,
              fundName: '',
              currentPercentage: Number(percentList[index] ?? 0),
              redirectPercentage: 0
            }
          })
        return {
          fundList: fundList,
          POClientId: policyInfo.body.owners.id ?? ''
        }
      })
    )
  export const accessData = (policyNum: string): Task<SubPremiumRedirectionData.AccessData> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policyInfo) => {
        const proposalReceivedDate = policyInfo.body.proposal?.proposalReceivedDate
        return {
          coverageCode: String(policyInfo.body.basicCode),
          contractStatus: String(policyInfo.body.status),
          proposalReceivedDate: String(proposalReceivedDate)
        }
      })
    )
  // export const submit = (policyNum: string, data: SubPremiumRedirectionData.SubmitData) => pipe(
  //   PulseOpsApi.postAuth()(`wf-api/policy/${policyNum}/premium-redirection`)(t.unknown)({
  //     body: data
  //   })
  // )

  export const submitData =
    (policyNum: string, data: SubPremiumRedirectionData.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/premium-redirection`,
        { body: data },
        policyNum,
        requestauth,
        []
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/premium-redirection`)(t.unknown)({
  //       body: data,
  //       otpTransaction: requestauth,
  //       source: SourceType.PULSE4OPS
  //   })
  // )
}
