import { SelectOption } from '@pulseops/common'
import { ReportAutoDebitModel } from './SEAReportAutoDebitModel'
export namespace ReportAutoDebitScreenConst {
  export const Status: SelectOption[] = [
    { label: 'All', value: ReportAutoDebitModel.Status.ALL },
    { label: 'Fail', value: ReportAutoDebitModel.Status.FAIL },
    { label: 'Success', value: ReportAutoDebitModel.Status.SUCCESS }
  ]
  export const StatusVi: SelectOption[] = [
    { label: 'Tất cả', value: ReportAutoDebitModel.Status.ALL },
    { label: 'Thất bại', value: ReportAutoDebitModel.Status.FAIL },
    { label: 'Thành công', value: ReportAutoDebitModel.Status.SUCCESS }
  ]
  export const displayedColumns = [
    {
      id: 'policyNumber',
      name: `BillingChange:Policy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'ptDate',
      name: `BillingChange:PTD`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 28
    },
    {
      id: 'tranDate',
      name: `BillingChange:ChargeDate`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 25
    },
    {
      id: 'status',
      name: `BillingChange:PaymentStatus`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 28
    },
    {
      id: 'reasonFail',
      name: `BillingChange:ReasonFail`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 25
    }
  ]
  export enum ImportType {
    ImportBillingChange = 1,
    ImportPartialWithdramw = 2
  }
}
