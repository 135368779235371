import { SelectOption } from '@pulseops/common'

export interface IBClaimSearchForm {
  claimNo: SelectOption | null
  claimType: SelectOption | null
  processStatus: SelectOption | null
}

export const claimFormDefault: IBClaimSearchForm = {
  claimNo: null,
  claimType: {
    label: 'All',
    value: ''
  },
  processStatus: {
    label: 'All',
    value: ''
  }
}

export const listClaimType: SelectOption[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Medical',
    value: 'MED'
  },
  {
    label: 'Critical illness',
    value: 'CIL'
  },
  {
    label: 'Accident',
    value: 'ACC'
  },
  {
    label: 'Total Disablity',
    value: 'TPD'
  },
  {
    label: 'Death',
    value: 'DTH'
  }
]

export const listProcessStatus: SelectOption[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Registration',
    value: 'Registration'
  },
  {
    label: 'Confirmation',
    value: 'Confirmation'
  },
  {
    label: 'Evaluation',
    value: 'Evaluation'
  },
  {
    label: 'PaymentCalculation',
    value: 'PaymentCalculation'
  },
  {
    label: 'PaymentAllocation',
    value: 'PaymentAllocation'
  },
  {
    label: 'Approval',
    value: 'Approval'
  },
  {
    label: 'ClaimCaseApproved',
    value: 'ClaimCaseApproved'
  },
  {
    label: 'ClaimHistoryUpdated',
    value: 'ClaimHistoryUpdated'
  },
  {
    label: 'PolicyUnlocked',
    value: 'PolicyUnlocked'
  },
  {
    label: 'PolicyAccountSettled',
    value: 'PolicyAccountSettled'
  },
  {
    label: 'PolicyStatusUpdated',
    value: 'PolicyStatusUpdated'
  },
  {
    label: 'PaymentReserved',
    value: 'PaymentReserved'
  },
  {
    label: 'PaymentRegistered',
    value: 'PaymentRegistered'
  },
  {
    label: 'PaymentPaid',
    value: 'PaymentPaid'
  },
  {
    label: 'End',
    value: 'End'
  },
  {
    label: 'PV pending',
    value: 'PV pending'
  },
  {
    label: 'Closed',
    value: 'Closed'
  }
]

export const listCommunicationType = [
  {
    label: 'Telephone',
    value: 'T'
  },
  {
    label: 'Email',
    value: 'E'
  },
  {
    label: 'Letter',
    value: 'L'
  }
]