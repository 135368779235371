import { ZIO } from '@mxt/zio'
import { Alert } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { GeneralInfoApi } from './general-info-api'

export namespace AmlService {
  const alertSuccess = Alert.alertM(
    'Yêu cầu cập nhật quét AML thành công. Vui lòng đợi để ghi nhận kết quả mới.',
    'Thông báo'
  )

  export const scanContract = (body: GeneralInfoApi.ScanAmlContractBody) =>
    pipe(
      GeneralInfoApi.scanAmlContract(body),
      ZIO.flatMap(() => alertSuccess)
    )

  export const scanShareholder = (body: GeneralInfoApi.ScanAmlShareholderBody) =>
    pipe(
      GeneralInfoApi.scanAmlShareholder(body),
      ZIO.flatMap(() => alertSuccess)
    )

  export const scanEmployee = (body: GeneralInfoApi.ScanAmlEmployeeBody) =>
    pipe(
      GeneralInfoApi.scanAmlEmployee(body),
      ZIO.flatMap(() => alertSuccess)
    )
}
