import { ZIO } from '@mxt/zio'
import { Ref } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import { Lens } from 'monocle-ts'
import * as O from 'fp-ts/Option'
import { TaskComment, TaskOp } from './model'

export interface PulseOpsState {
  tasks: {
    [id: string]: TaskOp | undefined
  }
  comments: {
    [taskId: string]: TaskComment[] | undefined
  }
}

export namespace PulseOpsState {
  export const ref = Ref.make<PulseOpsState>({
    tasks: {},
    comments: {}
  })

  export const lens = {
    task: (taskId: string) =>
      Lens.fromProp<PulseOpsState>()('tasks').compose(Lens.fromProp<{ [id: string]: TaskOp | undefined }>()(taskId)),
    comments: (taskId: string) =>
      Lens.fromProp<PulseOpsState>()('comments').compose(
        Lens.fromNullableProp<{
          [taskId: string]: TaskComment[] | undefined
        }>()(taskId, [])
      )
  }

  export const updateTasks = (tasks: TaskOp[]) =>
    pipe(
      tasks.map((task) => pipe(lens.task(task.id).set(task), ref.update)),
      ZIO.sequence
    )

  export const getTask = (id: string) => pipe(ref.get, ZIO.map(lens.task(id).get), ZIO.map(O.fromNullable))
}
