
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
export namespace OBREQCService {
  export const REQCCommonInfo = t.type({
    callResult: Maybe(t.string),
    manualInternalFeedback: Maybe(t.string),
    lastVerAssignee: Maybe(t.string),
    reQCTotalPassScore: Maybe(t.number),
    reQCTotalScore: Maybe(t.number),
    lastQCAssignee: Maybe(t.string),
    comment: Maybe(t.string),
    policyNumber: Maybe(t.string),
    assignee: Maybe(t.string),
    teamLeader: Maybe(t.string),
    transactionType: Maybe(t.string),
    updateContactInfoFlag: Maybe(t.string),
    reQCRating: Maybe(t.number),
    reQCOutcome: Maybe(t.string)
  })

  export const REQCList = t.array(t.type({
    a12s1Criteria: Maybe(t.type({
      id: Maybe(t.string),
      categoryENDesc: Maybe(t.string),
      categoryVNDesc: Maybe(t.string),
      criteriaCode: Maybe(t.string),
      criteriaENName: Maybe(t.string),
      criteriaVNName: Maybe(t.string),
      criteriaDescEN: Maybe(t.string),
      criteriaDescVN: Maybe(t.string),
      score: Maybe(t.number),
      topicCode: Maybe(t.string),
      topicENDesc: Maybe(t.string),
      topicVNDesc: Maybe(t.string),
      answerTypeCode: Maybe(t.string),
      failureMechanism:  Maybe(t.string),
    })),
    answerValueCode: Maybe(t.string)
  }))

  export const OBSaveDataQC = t.type({
    // businessKey: t.string,
    // processInstanceId: t.string,
    // policyNumber: t.string,
    // assignee: t.string,
    // teamLeader: t.string,
    // transactionType: t.string,
    // createdBy: t.string,
    // createdDate: t.string,
    // updatedBy: t.string,
    // updatedDate: t.string,
    // qcCommonInfo: QCCommonInfo,
    // qcCriteriaList: QCList
    businessKey: t.string,
    processInstanceId: t.string,
    reQCCommonInfo: t.type({
      lastVerAssignee: t.string,
      callResult: t.string,
      manualInternalFeedback: t.string,
      reQCTotalPassScore: t.number,
      reQCTotalScore: t.number,
      lastQCAssignee: t.string,
      comment: t.string,
      policyNumber: t.string,
      assignee: t.string,
      teamLeader: t.string,
      transactionType: t.string,
      updateContactInfoFlag: t.string, // value Y/N
      reQCRating: t.number,
      reQCOutcome: t.string
    }),
    reQCCriteriaList: REQCList
  })

  export const OBREQCHistory = t.type({
    businessKey: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    reQCCommonInfo: REQCCommonInfo,
    reQCCriteriaList: REQCList
  })

  export type OBSaveDataQC = t.TypeOf<typeof OBSaveDataQC>
  export type REQCCommonInfo = t.TypeOf<typeof REQCCommonInfo>
  export type OBREQCHistory = t.TypeOf<typeof OBREQCHistory>
  export type REQCList = t.TypeOf<typeof REQCList>

  export const getHistorySave = (processInstanceId: string) => pipe(
    POApi.get(`pulseops/api/v1/outbound/re-qc/${processInstanceId}`)(OBREQCHistory),
    ZIO.map((res) => res)
  )

  export const saveHistoryREQC = (dataSave: OBSaveDataQC) => pipe(
    POApi.post(`pulseops/api/v1/outbound/re-qc/save-history`)(t.unknown)(dataSave),
    ZIO.map((res) => res)
  )
}