import React from 'react'
// import { View } from 'react-native'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import {
  form2,
  ErrorHandling,
  FieldList,
  AppContext,
  ReinstatementModel,
  ReinstatementService,
  GeneralService,
  PulseOpsFormat,
  SelectOption,
  TransactionType,
  // StorageBlob,
  FrequencyMapping,
  TaskType
} from '@pulseops/common'
import { ReinstatementForm } from './common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { SC } from '../../common'
import _ from 'lodash'
// import { Subject, debounceTime } from 'rxjs'

import { RequestAuthenticateData } from '../../request-authen'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { ConfirmTab } from './confirm_tab'
import { RequestTab } from './request_tab'
import moment from 'moment'
type Props = PolicyServiceProps<ReinstatementModel.ReinstatementSubmitData> & {}

export enum Frequency {
  Annual = '01',
  HalfYearly = '02',
  Quarterly = '04',
  Monthly = '12'
}

export const mapFrequency = new Map<Frequency, string>([
  [Frequency.Annual, 'Annual'],
  [Frequency.HalfYearly, 'HalfYearly'],
  [Frequency.Quarterly, 'Quarterly'],
  [Frequency.Monthly, 'Monthly']
])

export const ReinstatementScreen: React.FC<Props> = ({ policyNumber, initSubmission, isConfirmed, officeCode }) => {
  const [loading, bindLoader] = useLoading(false)
  const { t } = useTranslation()
  const { showGlobalLoading, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const isFocused = useIsFocused()
  const mapFrequency = FrequencyMapping.mapFrequency
  const { base: form, handleSubmit } = form2.useForm(ReinstatementForm.codec)

  const { getValues, watch } = form
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionScreen' })
        },
        { title: t('TransactionType:REINSTATEMENT'), navigate: null }
      ])
    }
  }, [isFocused])

  const getUploadedFilesInfo = (formData: ReinstatementForm.Raw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadFiles = formData.formRein.attachmentFiles as ReinstatementForm.FileMeta[]
    if (uploadFiles && uploadFiles.length > 0) {
      uploadedFileList.push({
        uploadFiles: uploadFiles,
        transactionType: TransactionType.REINSTATEMENT,
        docTypeCode: 'DPS09',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      handleSubmit((validate) => {
        console.log('handlesubmit', validate)
      })()

      await form.trigger()

      console.log(form.formState.errors)
      console.log(form.getValues())
      //console.log(mapData())
      // if( form.getValue('formRein.attachmentFiles'))

      if (form.formState.isValid) {
        // return await pipe(
        //   ZIO.fromPromise(() => form.trigger()),
        //   ZIO.flatMap((isValid) => {
        //     if (!isContinue && isValid && !!form.getValues('formRein.attachmentFiles')?.length) {
        //       return pipe(
        //         GeneralService.getMetaData(TransactionType.REINSTATEMENT || '', 'DPS09'),
        //         ZIO.flatMap((metaRes) => {
        //           const metaDataRaw: StorageBlob.MetaDataUpload = {
        //             type: metaRes.data.transactionType,
        //             doctype: metaRes.data.doctypeEn,
        //             class: metaRes.data.classFilenet,
        //             docid: metaRes.data.docID,
        //             maindoc: metaRes.data.mainDoc,
        //             subdoc: metaRes.data.subDoc,
        //             polnum: policyNumber!,
        //             batchno: metaRes.data.batchNo
        //           }
        //           const files: StorageBlob.FileContent[] =
        //             form.getValues('formRein.attachmentFiles')?.map((x) => {
        //               return {
        //                 file: x.file!,
        //                 metaData: metaDataRaw
        //               }
        //             }) || []
        //           return pipe(StorageBlob.uploadToSubmit('PS', policyNumber!)(files))
        //         })
        //       )
        //     }
        //     return ZIO.succeed([])
        //   }),
        //   ZIO.map((documents) => {
        //     console.log('documents', documents)
        //     return {
        //       url: () => `wf-api/policy/${policyNumber!}/reinstatement`,
        //       body: mapData(),
        //       documents: documents,
        //       transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO) || '',
        //       collerationId: policyNumber || '',
        //       transaction: TransactionType.CHANGE_CONTACT_INFO
        //     }
        //   }),
        //   bindLoader,
        //   ErrorHandling.run({})
        // )
        return {
          url: () => `wf-api/policy/${policyNumber!}/reinstatement`,
          body: mapData(),
          // documents: documents,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.REINSTATEMENT) || '',
          collerationId: policyNumber || '',
          transaction: TransactionType.REINSTATEMENT,
          uploadedFilesInfo: getUploadedFilesInfo(form.getValues())
        }
      }
      return false
    },
    clear: () => resetForm()
  })

  const { detail, occupationsOptions } = pipe(
    ZIO.zipPar(ReinstatementService.getDetail(policyNumber!), GeneralService.getOccupations),
    ZIO.map(([detail, occupations]) => {
      console.log(detail)
      const occupationsOptions = occupations.map((occupation) => {
        return {
          value: occupation.code,
          label: occupation.name
        }
      })
      //return ZIO.unit
      initData(detail, occupationsOptions)
      return {
        detail,
        occupationsOptions
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  const resetForm = () => {
    initData(detail, occupationsOptions)
  }

  const initData = (detail: ReinstatementModel.Detail, occupations: SelectOption[]) => {
    if (detail && detail.reinData) {
      const {
        reinData: { attributesExt }
      } = detail

      const reinDetails = detail.reinData.lifeAssured.map((client, index) => {
        const currentOccupation = occupations.find((o) => o.value === client.attributesExt.CUR_OCCUPATION)
        const reinDetail: ReinstatementForm.ReinDetail = {
          name: client.name,
          clientNum: client.clientId,
          hAnswer: false,
          oAnswer: false,
          aAnswer: false,
          cAnswer: false,
          curOccupation: currentOccupation ?? { label: '', value: '' }
        }
        return reinDetail
      })

      console.log(attributesExt)

      form.reset({
        formRein: {
          reinFee: attributesExt.REINS_FEE,
          riskCommonDate: attributesExt.RISK_COMM_DATE,
          lapsedDate: attributesExt.LAPSED_DATE,
          frequency: attributesExt.FREQUENCY,
          installmentPrem: attributesExt.INSTALLMENT_PREM,
          agentCode: attributesExt.AGENT_CODE,
          agentName: attributesExt.AGENT_NAME,
          opl: attributesExt.OPL,
          apl: attributesExt.APL,
          reinClients: reinDetails,
          totalReinAmount: attributesExt.TOTAL_REINS_AMOUNT,
          attachmentFiles: []
        }
      })
    }
  }

  console.log('riscommon date', Number(getValues('formRein.riskCommonDate')))
  const fieldList: FieldList.FieldType[] = [
    {
      label: t('submission:RiskCommencementDate'),
      value: getValues('formRein.riskCommonDate')
        ? // ? PulseOpsFormat.datetoFormat(new Date(Number(getValues('formRein.riskCommonDate')) * 1000), 'DD/MM/YYYY')
          moment(getValues('formRein.riskCommonDate')).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('submission:LapsedDate'),
      value: getValues('formRein.lapsedDate')
        ? //? PulseOpsFormat.datetoFormat(new Date(Number(getValues('formRein.lapsedDate')) * 1000), 'DD/MM/YYYY')
          moment(getValues('formRein.lapsedDate')).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('submission:BillingFrequency'),
      value: t((mapFrequency.get(getValues('formRein.frequency') || '') as Frequency) || '-').toString()
    },
    {
      label: t('submission:InstallmentPremium'),
      value:
        getValues('formRein.installmentPrem') || getValues('formRein.installmentPrem') === 0
          ? PulseOpsFormat.thousandSepartor(getValues('formRein.installmentPrem')) + ' VND'
          : '-'
    },
    {
      label: t('submission:AutomaticPolicyLoan'),
      value:
        getValues('formRein.apl') || getValues('formRein.apl') === 0
          ? PulseOpsFormat.thousandSepartor(getValues('formRein.apl')) + ' VND'
          : '-'
    },
    {
      label: t('submission:OriginalLoan'),
      value:
        getValues('formRein.opl') || getValues('formRein.opl') === 0
          ? PulseOpsFormat.thousandSepartor(getValues('formRein.opl')) + ' VND'
          : '-'
    },
    {
      label: t('submission:ReinstatementFee'),
      value:
        getValues('formRein.reinFee') || getValues('formRein.reinFee') === 0
          ? PulseOpsFormat.thousandSepartor(getValues('formRein.reinFee')) + ' VND'
          : '-'
    },
    {
      label: t('submission:EstimatedTotalReinstatementAmount'),
      value: (
        <SC.SessionText color={'red'}>
          {getValues('formRein.totalReinAmount') || getValues('formRein.totalReinAmount') === 0
            ? PulseOpsFormat.thousandSepartor(getValues('formRein.totalReinAmount')) + ' VND'
            : '-'}
          {/* { PulseOpsFormat.thousandSepartor(2234800) + ' VND'} */}
        </SC.SessionText>
      )
    }
  ]

  const mapData = (): ReinstatementModel.ReinstatementSubmitData => {
    const lifeAssured: ReinstatementModel.LifeAssured[] = watch('formRein.reinClients')?.map((r) => {
      let attributesExt = {
        IS_CHANGE_HEALTH_STATE: false,
        IS_OCCUPATION_CHANGE: false,
        IS_ACTIVITY_CHANGE: false,
        IS_EXIST_REQUEST_COMPANY: false,
        CUR_OCCUPATION: '',
        NEW_OCCUPATION: '',
        NEW_ACTIVITY: '',
        COMPANY_NAME: ''
      }

      if (r.hAnswer) {
        attributesExt = { ...attributesExt, IS_CHANGE_HEALTH_STATE: true }
      }

      if (r.oAnswer) {
        attributesExt = {
          ...attributesExt,
          IS_OCCUPATION_CHANGE: true,
          CUR_OCCUPATION: r.curOccupation?.value || '',
          NEW_OCCUPATION: r.newOccupation?.value || ''
        }
      }

      if (r.aAnswer) {
        attributesExt = {
          ...attributesExt,
          IS_ACTIVITY_CHANGE: true,
          NEW_ACTIVITY: r.newActivity || ''
        }
      }

      if (r.cAnswer) {
        attributesExt = {
          ...attributesExt,
          IS_EXIST_REQUEST_COMPANY: true,
          COMPANY_NAME: r.newCompany || ''
        }
      }

      let lA: ReinstatementModel.LifeAssured = {
        name: r.name || '',
        clientId: r.clientNum || '',
        attributesExt
      }

      return lA
    })

    return {
      policyNo: policyNumber!,
      owners: {
        clientId: detail.owner.body.clientId
      },
      lifeAssured: lifeAssured,
      attributesExt: {
        TRANS_CODE: detail.reinData.attributesExt.TRANS_CODE,
        AGENT_CODE: form.getValues('formRein.agentCode'),
        AGENT_NAME: form.getValues('formRein.agentName'),
        RISK_COMM_DATE: form.getValues('formRein.riskCommonDate') || '',
        LAPSED_DATE: form.getValues('formRein.lapsedDate') || '',
        FREQUENCY: form.getValues('formRein.frequency') || '',
        INSTALLMENT_PREM: form.getValues('formRein.installmentPrem') || 0,
        APL: form.getValues('formRein.apl') || 0,
        OPL: form.getValues('formRein.opl') || 0,
        REINS_FEE: form.getValues('formRein.reinFee') || 0,
        TOTAL_REINS_AMOUNT: form.getValues('formRein.totalReinAmount') || 0
      }
    }
  }

  if (isConfirmed) {
    return <ConfirmTab form={form.getValues()} headerFields={fieldList} />
  }

  return <RequestTab form={form} occupations={occupationsOptions || []} headerFields={fieldList} />
}
