import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  TextStyle,
  useWindowDimensions,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import {
  AppContext,
  CategoryToLabel,
  formatNumberWithComma,
  InquiryComplaintService,
  SelectOption,
  TransactionLabel,
  TransactionStatusLabel,
  TransactionStatus,
  PulseOpsFormat,
  GeneralService,
  Locale,
  ModalComponent,
  Container,
  ContractMapping,
  ErrorHandling,
  FieldList,
  FrequencyMapping,
  getLanguage,
  Panel,
  PremiumStatusMapping,
  useTranslator,
  assets,
  TaskService,
  ServiceInquiryNote,
  Category
} from '@pulseops/common'
import { CanView } from '@pulseops/task/common'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { CepTableField, CepPanel } from '../cep-components'
import { useIsFocused, useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import i18next from 'i18next'
import { CepFilterTransactionModal } from './CepFilterTransactionModal'
import { CepFilterForm } from '../CEPFormSearch'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { CepStateInfo } from '../../state'
import { isEmpty } from 'lodash'
import {
  getPolicyInfo,
  getTransactionHistory,
  ItemTransactionHistory,
  PolicyInfoData,
  TransactionHistory,
  TransactionHistoryC,
  TransactionHistoryRes,
  CepPremiumStatus,
  CepPolicyStatus
} from '@pulseops/inquiry'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})
export const getStatus = (status: string): TransactionStatus => {
  switch (status) {
    case 'Completed':
      return TransactionStatus.COMPLETED
    case 'Pending':
      return TransactionStatus.PENDING
    case 'Rejected':
      return TransactionStatus.REJECTED
    case 'End':
      return TransactionStatus.END
    default:
      return TransactionStatus.IN_PROGRESS
  }
}

export const calculateAgingDay = (status: string | null, createDate?: Date | null, endDate?: Date | null): number => {
  switch (status) {
    case 'Completed':
    case 'End':
      return endDate ? moment(endDate).startOf('days').diff(moment(createDate).startOf('days'), 'days') : 0
    case 'Pending':
    case 'In process':
      return createDate ? moment().startOf('days').diff(moment(createDate).startOf('days'), 'days') : 0
    default:
      return 0
  }
}

export const YearAnniversary = [5, 10, 15, 20]

export const CepPolicyInfoScreen = (props: {
  id: string
  data: PolicyInfoData | null
  setData: (val: PolicyInfoData | null) => void
  messageState: CepStateInfo.MessageType
  transHistory: TransactionHistoryRes | null
  setTransHistory: (val: TransactionHistoryRes | null) => void
}) => {
  const { setData } = props
  const [loading, bindLoading] = useLoading()
  const { t } = useTranslator('TaskManagement')
  const { t: tCommon } = useTranslator('common')
  const ct = useTranslator('contract').t
  const pst = useTranslator('premiumStatus').t
  const ft = useTranslator('frequency').t
  const cep = useTranslator('CustomerEngagement').t
  const { i18n } = useTranslation()
  const [subServiceTypeList, setSubServiceTypeList] = React.useState<SelectOption[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<(SelectOption & { typeCode: string })[]>(
    []
  )
  const [isFilterModalVisible, setFilterModalVisible] = React.useState<boolean>(false)
  const [filter, setFilter] = React.useState<CepFilterForm.Validated>()
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const classes = useStyles()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()

  const [messageData, setMessageData] = React.useState<CepStateInfo.MessageType>({
    DOB: '',
    Anniversary: '',
    FullyPaid: ''
  })
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [infoVisible, setInfoVisible] = React.useState<boolean>(false)
  const [submittedData, setSubmittedData] = React.useState<TransactionHistoryC>({
    start: 0,
    size: 10,
    data: {
      fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(new Date(), true),
      toDate: PulseOpsFormat.serviceInquiry_endOfDate(new Date(), true),
      category: '',
      transactionType: [],
      subTransactionType: [],
      source: [],
      subServiceType: []
    }
  })
  const [DSData, setDSData] = React.useState<TransactionHistory[]>([])
  const [loadingTrans, setLoadingTrans] = React.useState<boolean>(true)
  const { height } = useWindowDimensions()
  const [noteDetail, setNoteDetail] = React.useState<ServiceInquiryNote>({
    customerComment: '',
    closedInfo: '',
    responseToCustomer: ''
  })

  React.useEffect(() => {
    if (isFocused) {
      const title = route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry')

      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: title,
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.reset({ index: 0, routes: [{ name: 'CEPSearchScreen' }] })
          // navigation.navigate('CEPSearchScreen'),
        },
        {
          title: t('Tab:PolicyInfo'),
          navigate: null
        }
      ])

      setMessageData((prevState) => ({
        ...prevState,
        DOB: props.messageState.DOB,
        Anniversary: props.messageState.Anniversary,
        FullyPaid: props.messageState.FullyPaid
      }))
    }
  }, [isFocused])

  const getData = (): ZIO<unknown, Throwable, PolicyInfoData | null> =>
    pipe(
      getPolicyInfo(props.id),
      ZIO.flatMap((_policyInfo) =>
        ZIO.effect(() => {
          const { policy, beneficiaries } = _policyInfo.data
          // policy
          const productName = policy ? (getLanguage() === 'en' ? policy.productNameEN : policy.productNameVN) : '-'
          const {
            policyStatus,
            premiumStatus,
            sumAssured,
            billingFrequency,
            policyOwnerCode,
            mainLifeAssuredCode,
            productCode,
            installmentPremium,
            riskCommencementDate,
            policyIssueDate,
            paidToDateBasic,
            premiumCessationDate,
            riskCessationDate,
            customerReceiveDate,
            customerReceiveMethod,
            firstIssueDate
          } = policy
          const POname = policy.policyOwnerName ? policy.policyOwnerName.name : ''
          const mainLA = getName(policy.mainLifeAssuredName ?? null)

          // check notify anniversary
          if (policyStatus === CepPolicyStatus.IF) {
            notifyAnniversary(riskCommencementDate ?? '')
            // check notify fully paid
            if (premiumStatus === CepPremiumStatus.FP || premiumStatus === CepPremiumStatus.PU) {
              setMessageData((prevState) => ({
                ...prevState,
                FullyPaid: i18next.t('message:MSFullyPaid')
              }))
            } else if (premiumStatus === CepPremiumStatus.PP) {
              notifyFullyPaid(paidToDateBasic ?? '', premiumCessationDate ?? '')
            }
          }

          return {
            policyInfo: {
              policyStatus: policyStatus ?? '-',
              premiumStatus: premiumStatus ?? '-',
              sumAssured: sumAssured ?? 0,
              billingFrequency: billingFrequency ?? '-',
              policyOwnerCode: policyOwnerCode ?? '-',
              policyOwnerName: POname ?? '-',
              mainLifeAssuredCode: mainLifeAssuredCode ?? '-',
              mainLifeAssuredName: mainLA ?? '-',
              productCode: productCode ?? '-',
              productName: productName ?? '-',
              installmentPremium: installmentPremium ?? 0,
              riskCommencementDate: riskCommencementDate ?? '',
              aplDate: policy && policy.aplDate ? policy.aplDate : '-',
              policyIssueDate: policyIssueDate ?? '-',
              paidToDateBasic: paidToDateBasic ?? '-',
              premiumCessationDate: premiumCessationDate ?? '-',
              riskCessationDate: riskCessationDate ?? '-',
              customerReceiveDate: customerReceiveDate ?? '-',
              customerReceiveMethod: customerReceiveMethod ?? '-',
              firstIssueDate: firstIssueDate ?? '-'
            },
            beneficiaries: beneficiaries
              ? beneficiaries.map((x, i) => ({
                  stt: (i + 1).toString(),
                  clientId: x.beneficiaryCode ?? '-',
                  percentage: x.beneficiaryPercentage + '%' ?? '0%',
                  relationshipType: x.beneficiaryRelationship ?? '',
                  clientName: getName(x.beneficiaryName ?? null) ?? '-'
                }))
              : []
          }
        })
      )
    )

  pipe(
    props.transHistory !== null ? ZIO.succeed(props.transHistory) : getTransactionHistory(props.id, submittedData),
    ZIO.map((res) => {
      const dataList = res.data ? mappingDataSource(res.data) : []
      props.setTransHistory?.(res)
      setDSData(dataList)
      setTotalItem(res.total ?? 0)
      setLoadingTrans(false)
    }),
    ErrorHandling.runDidMount()
  )

  const { policyInfo, beneficiaries } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    policyInfo: null,
    beneficiaries: []
  }

  const notifyAnniversary = (riskCommenDate: string) => {
    const today = new Date()

    const riskDate = riskCommenDate?.split('/').reverse().join('/')

    const RCD = new Date(riskDate)

    const currentYear = today.getFullYear()

    const yearAnni = currentYear - RCD.getFullYear()

    if (YearAnniversary.includes(yearAnni)) {
      if (today.getMonth() === RCD.getMonth()) {
        setMessageAnniversary(i18next.t('message:MSnotifyAnniversary', { year: yearAnni }))
      }
      return
    }
  }

  const setMessageAnniversary = (messageAnni: string) => {
    setMessageData((prevState) => ({
      ...prevState,
      Anniversary: messageAnni
    }))
  }

  const leapYear = (year: number) => {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
  }

  const notifyFullyPaid = (PTDate: string, PCDate: string) => {
    const paidTD = PTDate?.split('/').reverse().join('/')
    const premCD = PCDate?.split('/').reverse().join('/')

    const PTD = new Date(paidTD + ' 00:00:00')
    const PCD = new Date(premCD + ' 00:00:00')

    if (PTD.valueOf() === PCD.valueOf()) {
      setMessageData((prevState) => ({
        ...prevState,
        FullyPaid: i18next.t('message:MSFullyPaid')
      }))
    }
  }

  React.useEffect(() => {
    if (!isEmpty(messageData)) {
      const setMessage = () => pipe(CepStateInfo.action.setMessage(messageData), ZIO.unsafeRun({}))

      setMessage()
    }
  }, [messageData])

  const customerReceiveMethod = policyInfo?.customerReceiveMethod === 'ePolicy' ? t('eKit') : t('hardCopy')

  const policyInfoList = [
    {
      label: tCommon('PolicyOwner'),
      value: policyInfo?.policyOwnerCode + ' ' + policyInfo?.policyOwnerName
    },
    {
      label: t('MainLifeAssured'),
      value: policyInfo?.mainLifeAssuredCode + ' ' + policyInfo?.mainLifeAssuredName
    },
    {
      label: t('APLDate'),
      value: policyInfo?.aplDate
    },
    {
      label: t('ContractName'),
      value: policyInfo?.productName
    },
    {
      label: t('PolicyStatus'),
      value: ContractMapping.get(ct)(policyInfo?.policyStatus)
    },
    {
      label: t('PremiumStatus'),
      value: PremiumStatusMapping.get(pst)(policyInfo?.premiumStatus)
    },
    {
      label: t('SumAssured'),
      value: policyInfo?.sumAssured ? formatNumberWithComma(policyInfo?.sumAssured) + ' VND' : '-'
    },
    {
      label: t('InstallmentPremium'),
      value: policyInfo?.installmentPremium ? formatNumberWithComma(policyInfo?.installmentPremium) + ' VND' : '-'
    },
    {
      label: t('Frequency'),
      value: FrequencyMapping.get(ft)(policyInfo?.billingFrequency)
    },
    {
      label: t('1stIssueDate'),
      value: policyInfo?.firstIssueDate
    },
    {
      label: t('PolicyIssueDate'),
      value: policyInfo?.policyIssueDate
    },
    {
      label: t('RiskCommencementDate'),
      value: policyInfo?.riskCommencementDate ?? '-'
    },
    {
      label: t('PaidToDateBasic'),
      value: policyInfo?.paidToDateBasic
    },
    {
      label: cep('PremiumCessationDate'),
      value: policyInfo?.premiumCessationDate
    },
    {
      label: t('RiskCessationDate'),
      value: policyInfo?.riskCessationDate
    },
    {
      label: t('CustomerReceiveDate'),
      value: policyInfo?.customerReceiveDate
    },
    {
      label: t('CustomerReceiveMethod'),
      value: customerReceiveMethod
    }
  ]

  const columns = [
    {
      key: '1',
      title: tCommon('No.'),
      name: 'stt'
    },
    {
      key: '2',
      title: t('BeneficiaryName'),
      name: 'clientName'
    },
    {
      key: '3',
      title: t('Percentage'),
      name: 'percentage'
    },
    {
      key: '4',
      title: t('Relationship'),
      name: 'relationshipType'
    }
  ]

  const columnsTransactionHistory = [
    {
      key: '1',
      title: cep('CaseID'),
      name: 'businessKey',
      color: () => '#ED1B2E',
      widthCss: 300
    },
    {
      key: '2',
      title: cep('Source'),
      name: 'source'
    },
    {
      key: '3',
      title: cep('PolicyNumber'),
      name: 'policyNumber'
    },
    {
      key: '22',
      title: cep('POIDNumber'),
      name: 'clientNumber'
    },
    {
      key: '4',
      title: cep('POClientNumber'),
      name: 'poClientNumber'
    },
    {
      key: '5',
      title: cep('POName'),
      name: 'poName'
    },
    {
      key: '6',
      title: cep('POPhoneNumber'),
      name: 'poPhoneNumber'
    },
    {
      key: '7',
      title: cep('Category'),
      name: 'category'
    },
    {
      key: '8',
      title: cep('ServiceType'),
      name: 'serviceType'
    },
    {
      key: '9',
      title: cep('TransactionType'),
      name: 'transactionType'
    },
    {
      key: '10',
      title: cep('Group'),
      name: 'group'
    },
    {
      key: '11',
      title: cep('SubService'),
      name: 'subServiceType'
    },
    {
      key: '12',
      title: cep('SubTransactionType'),
      name: 'subTransactionType'
    },
    {
      key: '13',
      title: cep('SubmissionUser'),
      name: 'createdBy'
    },
    {
      key: '14',
      title: cep('CreatedDate'),
      name: 'createdDate'
    },
    {
      key: '15',
      title: cep('LastAssignee'),
      name: 'assignee'
    },
    {
      key: '16',
      title: cep('Aging'),
      name: 'agingDate'
    },
    {
      key: '17',
      title: cep('LastUpdatedDate'),
      name: 'lastUpdateDate'
    },
    {
      key: '18',
      title: cep('LastUpdatedBy'),
      name: 'lastUpdateByAdj'
    },
    {
      key: '19',
      title: cep('Status'),
      name: 'status'
    },
    {
      key: '20',
      title: cep('CurrentActivity'),
      name: 'activity'
    },
    {
      key: '21',
      title: cep('Note'),
      name: 'note'
    }
  ]

  const getName = (item: { firstName?: string; surName?: string; middleName?: string } | null) => {
    if (item) {
      return item.surName + ' ' + item.middleName + ' ' + item.firstName
    }

    return ''
  }

  const convertCategoryToLabel = (category: string): string => {
    const converted = CategoryToLabel(category)
    if (converted) {
      return t(`Reports:${converted}`)
    } else {
      return ''
    }
  }

  // load Sub Service Type
  pipe(
    InquiryComplaintService.getTypes(),
    ZIO.map((typeList) => {
      if (typeList && typeList.length > 0) {
        setSubServiceTypeList(
          typeList.slice().map((x) => ({
            label: i18n.language === 'en' ? x.name : x.nameVi,
            value: x.code
          }))
        )
      } else {
        setSubServiceTypeList([])
      }
    }),
    ErrorHandling.runDidMount([])
  )
  // load sub transaction type
  pipe(
    InquiryComplaintService.getSubType(),
    ZIO.map((typeList) => {
      if (typeList && typeList.length > 0) {
        setSubTransactionTypeList(
          typeList.slice().map((x) => ({
            label: i18n.language === 'en' ? x.name : x.nameVi,
            value: x.code,
            typeCode: x.typeCode
          }))
        )
      } else {
        setSubTransactionTypeList([])
      }
    }),
    ErrorHandling.runDidMount([])
  )
  // get relationship

  const relationships = pipe(
    GeneralService.getRelationship(),
    ZIO.map((res) => {
      return res.map((rela) => ({
        ...rela,
        relationshipEN: rela.relationshipEN || rela.nameEn || '',
        relationshipVN: rela.relationshipVN || rela.nameVn || '',
        nameEn: rela.relationshipEN || rela.nameEn || '',
        nameVn: rela.relationshipVN || rela.nameVn || ''
      }))
    }),
    ErrorHandling.runDidMount([])
  )
  const getRelationship = (code: string): string => {
    const r = relationships.find((r) => r.code === code)
    return r ? (i18n.language === Locale.en ? r.nameEn ?? code : r.nameVn) : code
  }

  const onSubmitTaskFilterForm = (data: CepFilterForm.Validated) => {
    setDSData([])
    setLoadingTrans((pre) => !pre)
    const today = new Date()
    const submitData: TransactionHistoryC = {
      start: pageNum,
      size: pageSize,
      data: {
        ...data,
        category: data.category?.value ?? '',
        fromDate: data.fromDate
          ? PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate, true)
          : PulseOpsFormat.serviceInquiry_StartOfDate(today, true),
        toDate: data.toDate
          ? PulseOpsFormat.serviceInquiry_endOfDate(data.toDate, true)
          : PulseOpsFormat.serviceInquiry_endOfDate(today, true),
        source: data?.source ? data.source.map((x) => x.value) : [],
        transactionType: data?.transactionType ? data.transactionType.map((x) => x.value) : undefined,
        subServiceType: data.subServiceType ? data.subServiceType.map((x) => x.value) : [],
        subTransactionType: data.subTransactionType ? data.subTransactionType.map((x) => x.value) : []
      }
    }
    setLoadingTrans(true)
    setFilter(data)
    setFilterModalVisible(false)
    setSubmittedData(submitData)
    getDataTransactionHistory(submitData)
  }

  const getDataTransactionHistory = (data: TransactionHistoryC) => {
    pipe(
      getTransactionHistory(props.id, data),
      ZIO.map((res) => {
        const { data, size, start, total } = res
        const dataList = data ? mappingDataSource(data) : []
        setDSData(dataList)
        props.setTransHistory(res)
        setTotalItem(total ?? 0)
        setLoadingTrans(false)
      }),
      ZIO.unsafeRun([])
    )
  }

  const mappingDataSource = (data: Array<ItemTransactionHistory>) => {
    return (data ?? [])
      .map((x) => ({
        id: x.id ?? '-',
        name: x.name ?? '-',
        businessKey: x.businessKey ?? '-',
        source: x.source ?? '-',
        category: x.category ?? '-',
        transactionType: t(TransactionLabel(x.transactionType)) ?? '-',
        policyNumber: x.policyNumber ?? '-',
        clientNumber: x.clientNumber ?? '-',
        poName: x.poName ?? '-',
        lastUpdateDate:
          !!x && x.lastUpdateDate && x.lastUpdateDateAdj
            ? moment(x.lastUpdateDateAdj).format('DD/MM/YYYY HH:mm:ss')
            : x.lastUpdateDateVer
            ? moment(x.lastUpdateDateVer).format('DD/MM/YYYY HH:mm:ss')
            : '-',
        subTransactionType: x.subTransactionType ?? '',
        agingDate: calculateAgingDay(
          x.status ?? '',
          x.createTime || x.startTime ? new Date(x.createTime ?? x.startTime ?? '') : new Date(),
          x.endTime ? new Date(x.endTime) : new Date()
        ),
        assignee: x?.assignee?.split('@')[0] ?? '-',
        assigneeName: x.assigneeName ?? '-',
        activity: x.activity ?? '-',
        createdDate: moment(x.createdDate).format('DD/MM/yyyy HH:mm:ss') ?? '-',
        request: x.request ?? '-',
        status: getStatus(x.status ?? ''),
        createdBy: x?.createdBy?.split('@')[0] ?? '-',
        lastUpdateByVer: x.lastUpdateByVer ?? '-',
        lastUpdateByAdj:
          !!x && x.lastUpdateByAdj
            ? x.lastUpdateByAdj.split('@')[0]
            : x.lastUpdateByVer
            ? x.lastUpdateByVer.split('@')[0]
            : '-',
        poClientNumber: x.poClientNumber ?? '-',
        poPhoneNumber: x.poPhoneNumber ?? '-',
        serviceType: x.serviceType ?? '-',
        group: x.group ?? '-',
        subServiceType: x.subServiceType ?? '',
        comment: x.comment ?? '',
        closeInfo: x.closeInfo ?? '',
        note: x.note ?? '-',
        action: x.action ?? '-',
        endTime: x.endTime ?? '-',
        createTime: x.createTime ?? '-',
        lastUpdateDateAdj: x.lastUpdateDateAdj ?? '-',
        lastUpdateDateVer: x.lastUpdateDateVer ?? '-',
        lifeAssuredName: x.lifeAssuredName ?? '-',
        startTime: x.startTime ?? '-'
      }))
      .sort((a, b) => {
        const a1 = a?.createdDate?.split(' ')
        const b1 = b?.createdDate?.split(' ')

        const timeA = moment(a1[0].split('/').reverse().join('/') + ' ' + a1[1]).valueOf() ?? 0
        const timeB = moment(b1[0].split('/').reverse().join('/') + ' ' + b1[1]).valueOf() ?? 0

        return timeB - timeA
      })
  }

  const getServiceInquiryNote = (taskId: string) => {
    pipe(
      TaskService.getServiceInquiryNote(taskId),
      ZIO.tap((note) => {
        setNoteDetail({
          customerComment: note.customerComment,
          closedInfo: note.closedInfo,
          responseToCustomer: note.responseToCustomer
        })
        setInfoVisible(true)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  return (
    <Container loading={loading}>
      {!!policyInfo && (
        <Panel title={tCommon('Policy')}>
          <FieldList dataSource={policyInfoList} />
        </Panel>
      )}
      {beneficiaries.length > 0 && (
        <Panel title={t('beneficiaryInfo')}>
          <CepTableField
            columns={columns}
            dataSource={beneficiaries.map((x, i) => ({
              ...x,
              stt: (i + 1).toString(),
              relationshipType: x.relationshipType ? getRelationship(x.relationshipType) : '-'
            }))}
            name="beneficiaryInfo"
          />
        </Panel>
      )}
      {/* {transactionHistories.length > 0 && ( */}
      <CepPanel
        title={cep('TransactionHistory')}
        isShowIconTitle={true}
        activeIcon={<assets.IconAppliedFilter />}
        nonActiveIcon={<assets.FilterIcon />}
        isActiveIcon={filter ? true : false}
        onPress={() => setFilterModalVisible(true)}
      >
        <View style={{ marginTop: 20, marginBottom: 20 }}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                  {columnsTransactionHistory.map((column, index) => (
                    <TableCell
                      key={column.key ? column.key : `table-header-${index}-${column.name}`}
                      align="left"
                      style={{ minWidth: column.widthCss ? column.widthCss : 150, fontWeight: 'bold', fontSize: 15 }}
                    >
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {DSData.length === 0 && loadingTrans && (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                      <CanView condition={loadingTrans}>
                        <View style={styles.loadingContainer}>
                          <ActivityIndicator size="large" color="red" />
                        </View>
                      </CanView>
                    </TableCell>
                  </TableRow>
                )}
                {DSData.length > 0 ? (
                  DSData.map((data, i) => (
                    <TableRow key={i}>
                      <TableCell
                        key={`table-header-${i}`}
                        align="left"
                        style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                      >
                        <Pressable
                          onPress={() => {
                            navigate('TaskDetailStack', {
                              screen: 'TaskDetailScreen',
                              params: {
                                id: data.id,
                                caterogy: data.category,
                                basket: '',
                                basketName: '',
                                isInquiry: '1',
                                isHistory: 'true',
                                isBreadcrumb: '1'
                              }
                            })
                          }}
                        >
                          <Text style={[styles.cellNoWrap, { color: '#ED1B2E' }]}>{data.businessKey}</Text>
                        </Pressable>
                      </TableCell>
                      <TableCell>{data.source}</TableCell>
                      <TableCell>{data.policyNumber}</TableCell>
                      <TableCell>{data.clientNumber}</TableCell>
                      <TableCell>{data.poClientNumber}</TableCell>
                      <TableCell>{data.poName}</TableCell>
                      <TableCell>{data.poPhoneNumber}</TableCell>
                      <TableCell>{convertCategoryToLabel(data.category)}</TableCell>
                      <TableCell>{data.serviceType}</TableCell>
                      <TableCell>{data.transactionType}</TableCell>
                      <TableCell>{data.group}</TableCell>
                      <TableCell>
                        {data.subServiceType
                          ? subServiceTypeList.find((item) => item.value === data.subServiceType)?.label
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {data.subTransactionType
                          ? subTransactionTypeList.find((item) => item.value === data.subTransactionType)?.label
                          : '-'}
                      </TableCell>
                      <TableCell>{data.createdBy}</TableCell>
                      <TableCell>{data.createdDate}</TableCell>
                      <TableCell>{data.assignee}</TableCell>
                      <TableCell>{data.agingDate}</TableCell>
                      <TableCell>{data.lastUpdateDate}</TableCell>
                      <TableCell>{data.lastUpdateByAdj}</TableCell>
                      {/* <TableCell>{data.status}</TableCell> */}
                      <TableCell
                        style={{ color: TransactionStatusLabel(data.status as TransactionStatus).color }}
                        className={classes.cellNoWrap}
                      >
                        {TransactionStatusLabel(data.status as TransactionStatus).label}
                      </TableCell>
                      <TableCell>{data.activity}</TableCell>
                      <TableCell>
                        {[Category.COMPLAINT, Category.INQUIRY].includes(data.category as Category) ? (
                          <TouchableOpacity onPress={() => getServiceInquiryNote(data.id)}>
                            <View style={styles.btnDetail}>
                              <Text style={{ textAlign: 'center', color: '#FFFFFF', fontWeight: '700' }}>
                                {t('common:Detail')}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    {!loadingTrans && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                          {t('common:Nodata')}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={pageNum}
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              setPageNum(page)
              setDSData([])
              setLoadingTrans(true)
              const data = {
                ...submittedData,
                size: pageSize,
                start: page
              }
              getDataTransactionHistory(data)
            }}
            onRowsPerPageChange={(e) => {
              const rowsPerPage = Number(e.target.value)
              setPageSize(rowsPerPage)
              setDSData([])
              setLoadingTrans(true)
              const data = {
                ...submittedData,
                size: rowsPerPage,
                start: pageNum
              }
              if (pageNum * rowsPerPage >= totalItem) {
                setPageNum(0)
                getDataTransactionHistory(data)
              } else {
                getDataTransactionHistory(data)
              }
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => i18next.t('common:Pagination', { from, to, count })}
            component={View}
          ></TablePagination>
        </View>
        {/* {loadingTrans && <LinearProgress color="secondary" />} */}
      </CepPanel>
      {/* )} */}

      <CepFilterTransactionModal
        visible={isFilterModalVisible}
        onPressSubmit={onSubmitTaskFilterForm}
        onPressClean={() => filter && setFilter(undefined)}
        onPressClose={() => setFilterModalVisible(false)}
      />
      <ModalComponent
        title={t('ServiceInquiry:DetailNote')}
        visible={infoVisible}
        onClose={() => setInfoVisible(false)}
        actions={[
          {
            text: t('common:Close'),
            type: 'filled',
            disabled: false,
            loading,
            onlyWide: false,
            style: { height: 40 },
            action: () => setInfoVisible(false)
          }
        ]}
        titleStyle={{ color: '#000000', fontWeight: '700', fontSize: 16 }}
      >
        <View style={{ marginHorizontal: 20, marginTop: 20, maxHeight: height - 200 }}>
          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:CustomerComment')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#D3DCE6',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.customerComment}</Text>
            </ScrollView>
          </View>

          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{cep('ResponsesToCustomer')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#D3DCE6',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.responseToCustomer}</Text>
            </ScrollView>
          </View>

          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ClosedInfo')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#D3DCE6',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.closedInfo}</Text>
            </ScrollView>
          </View>
        </View>
      </ModalComponent>
    </Container>
  )
}

const styles = StyleSheet.create({
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 30
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  tableColText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '300'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignCenter: {
    textAlign: 'center'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,
  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    padding: 10
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10
  }
})
