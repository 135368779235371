import React from 'react'
import { makeStyles } from '@material-ui/core'
import { StyleSheet, View } from 'react-native'

export const ContainerView = ({ children }: { children: React.ReactNode }) => {
  return <View style={styles.sessonContainer}>{children}</View>
}

export const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    flex: 1
  },

  cellWidth200: {
    minWidth: 200
  },
  cell: {
    flex: 1,
    height: 55
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    color: '#70777E',
    marginLeft: 15
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})

export const styles = StyleSheet.create({
  headerEmail: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 20
  },
  sessonText: {
    fontSize: 15,
    fontWeight: '800',
    marginBottom: 10
  },
  icon: {
    marginRight: 8
  },
  sessonContainer: {
    backgroundColor: '#FDFDFD',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    paddingHorizontal: 24,
    paddingVertical: 30,
    marginBottom: 16
  },
  row: {
    flexDirection: 'row',
    marginBottom: 20
  },
  selectContainer: {
    minWidth: 300,
    marginRight: 15,
    flex: 1
  },
  input: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#D3DCE6',
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 4,
    width: '100%',
    marginRight: 10
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  Button: {
    backgroundColor: '#ED1B2E',
    borderRadius: 40,
    paddingVertical: 8,
    paddingHorizontal: 20,
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    // height: 40,
    minWidth: 125,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    marginHorizontal: 10
  },
  normalText: {
    fontFamily: 'NotoSans_400Regular',
    fontSize: 15,
    color: '#4F4F4F'
  },
  boldText: {
    fontFamily: 'NotoSans_700Bold',
    fontSize: 15,
    color: '#4F4F4F'
  },
  valueText: {
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#D3DCE6'
  },
  titleColumn: {
    color: '#000',
    fontWeight: '700',
    fontSize: 15,
    whiteSpace: 'nowrap'
  },
  rowTableText: {
    fontSize: 14,
    color: 'grey'
  },
  totalText: {
    marginTop: 30,
    marginBottom: 15
  },
  expandTitle: {
    fontSize: 15,
    fontWeight: '800'
  },
  helperText: {
    color: 'red',
    fontSize: 12
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
})
