import { Customer, OccupationDetailType } from '../Client'
import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface ChangeOccupationPersonal {
  tag: TransactionType.CHANGE_OCCUPATION_PERSONAL
  source: SourceType
  clientName: string
  clientNum: string
  occupation: {
    occupationDetail: OccupationDetailType
    jobTitle: string
    specificJob: string
    workPlace: string
    workAddress: string
    salary: string
    occupationClass: string
  }
}

export const ChangeOccupationPersonal = (
  detail: api.ChangeOccupationPersonal,
  customer: Customer,
  occupation: OccupationDetailType
): ChangeOccupationPersonal => {
  const {
    body: { name }
  } = customer

  const { transactionType, source, payload } = detail
  const occupationAttributes = payload.body.attributesExt

  const jobTitle = occupationAttributes?.title ?? '-'
  const specificJob = occupationAttributes?.specificJob ?? '-'
  const workPlace = occupationAttributes?.workPlace ?? '-'
  const workAddress = occupationAttributes?.workAddress ?? '-'
  const salary = occupationAttributes?.salary ?? '-'
  const occupationClass = occupationAttributes?.class ?? '-'

  switch (detail.source) {
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS:
      return {
        tag: transactionType,
        source: source,
        clientName: name ?? '-',
        clientNum: payload.body.clientId ?? '',
        occupation: {
          occupationDetail: occupation ?? {
            id: '-',
            code: '-',
            classOccupation: '-',
            nameEn: '-',
            nameVn: '-',
            isEnableCompanyInfo: '-',
            positionCode: '-',
            positionName: '-'
          },
          jobTitle,
          specificJob,
          workPlace,
          workAddress,
          salary,
          occupationClass
        }
      }
  }
}
