import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { OBButton, OBIconButton, OBSectionCol, OBSectionContent, OBSectionRow } from '../ob-common'
import {
  AppContext,
  assets,
  DatePicker,
  downloadURI,
  ErrorHandling,
  OBImportFilePermission,
  PulseOpsFormat
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { OBImportFileService } from '../ob-service'
import { ZIO } from '@mxt/zio'

interface Props {
  permissions: string[]
}
const obExportFileTranslate = 'Outbound:OBImportFile.ExportPromotion'

type OBExportPromotionData = {
  fromDate: Date | null
  toDate: Date | null
}

export const OBExportPromotionData = (props: Props) => {
  const { permissions } = props
  const { t } = useTranslation()
  const [formError, setFormError] = React.useState<string>('')
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const { control, watch, trigger, handleSubmit } = useForm<OBExportPromotionData>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    },
    mode: 'onChange'
  })

  const validateFormSearch = (data: OBExportPromotionData): boolean => {
    const toDate = PulseOpsFormat.serviceInquiry_StartOfDate(data.toDate as Date)
    const fromDate = PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate as Date)

    if (
      Number(
        moment
          .duration(moment(toDate).diff(moment(fromDate)), 'milliseconds')
          .asDays()
          .toFixed(2)
      ) > 7
    ) {
      setFormError(t('message:OB0108', { k: 1 }))
      return false
    }
    return true
  }

  const onExportFile = handleSubmit((value) => {
    if (validateFormSearch(value)) {
      setFormError('')
      showGlobalLoading(true)
      pipe(
        OBImportFileService.exportPromotionFile(
          moment(value.toDate).format('DD-MM-YYYY'),
          moment(value.fromDate).format('DD-MM-YYYY')
        ),
        ZIO.tap(([_, blob]) => {
          showGlobalLoading(false)
          if (blob.type === 'application/json') {
            showToast(t('message:OB0032'), 'error')
          } else {
            downloadURI(window.URL.createObjectURL(blob), `Promotion_report_${moment().format('YYYYMMDD')}.xlsx`)
          }
          return ZIO.unit
        }),
        ErrorHandling.run()
      )
    }
  })

  return (
    <ScrollView style={styles.container}>
      <OBSectionContent>
        <OBSectionRow>
          <OBSectionCol>
            <Controller
              control={control}
              name="fromDate"
              rules={{
                validate: (value) => {
                  if (!value || !moment(value).isValid()) {
                    return `${t('message:MS020009', { field: t('ServiceInquiry:FromDate') })}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <DatePicker
                  required={true}
                  label={t('ServiceInquiry:FromDate')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS990032')}
                  minDate={undefined}
                  minDateMessage={''}
                  errorMessage={error?.message}
                />
              )}
            />
          </OBSectionCol>
          <OBSectionCol>
            <Controller
              control={control}
              name="toDate"
              rules={{
                validate: (value) => {
                  const fromDate = watch('fromDate')
                  if (!value || !moment(value).isValid()) {
                    return `${t('message:MS020009', { field: t('ServiceInquiry:ToDate') })}`
                  } else if (
                    moment(moment(value).format('YYYY/MM/DD')).valueOf() <
                    moment(moment(fromDate).format('YYYY/MM/DD')).valueOf()
                  ) {
                    return `${t('message:MS030044')}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <DatePicker
                  required={true}
                  label={t('ServiceInquiry:ToDate')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS990032')}
                  minDate={undefined}
                  errorMessage={error?.message}
                />
              )}
            />
          </OBSectionCol>
        </OBSectionRow>
      </OBSectionContent>
      <View style={{ marginLeft: 15, marginTop: 15 }}>
        {formError ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{formError}</Text> : null}
      </View>
      {permissions.includes(OBImportFilePermission.ExportExportPromotionData) ? (
        <View style={styles.footer}>
          <OBIconButton
            text={t(`${obExportFileTranslate}.Export`)}
            onHandleClickEvent={onExportFile}
            isHightLight={true}
            btnIcon={<assets.OBExportFile />}
          ></OBIconButton>
        </View>
      ) : null}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  evidenceContainer: {
    backgroundColor: '#F5F5F5'
  },
  fieldTitle: {
    color: '#4F4F4F',
    fontSize: 16,
    marginBottom: 15
  },
  fieldSubTitle: {
    color: '#70777E',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '700'
  },
  requiredText: {
    color: '#ED1B2E'
  },
  footer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 16
  },
  errorMessageText: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
