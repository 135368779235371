import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
  FlatList
} from 'react-native'
import { useTranslation } from 'react-i18next'
import {
  assets,
  UploadFileToStorageInfo,
  CashLessService,
  RadioButton,
  RecordFileInfo,
  AppContext,
  formatNumberWithComma,
  AuthService,
  ErrorHandling
} from '@pulseops/common'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  TablePagination
} from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
interface Props {
  setCanChangeTab: (canChangeTab: boolean) => void
}
export const UploadFileToStorageScreen = (props: Props) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setLoading] = React.useState(false)
  const [isRunJobLoad, setRunJobLoad] = React.useState<boolean>(false)
  const [recordFile, setRecordFile] = React.useState<RecordFileInfo[]>([])
  const [fileSelected, setFileSelected] = React.useState<UploadFileToStorageInfo>()
  const { height, width } = useWindowDimensions()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [originalFiles, setOriginalFiles] = React.useState<UploadFileToStorageInfo[]>([])
  const { setCanChangeTab } = props
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [totalTask, setTotalTask] = React.useState<number>(0)
  const [timeSubmit, setTimeSubmit] = React.useState<string>('')
  const [fileName, setFileName] = React.useState<string>('')
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const Status = [
    { label: 'All', value: '' },
    { label: 'Fail', value: 'fail' },
    { label: 'Time out', value: 'timeout' },
    { label: 'Duplicate', value: 'duplicate' },
    { label: 'Success', value: 'success' }
  ]
  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()
  const onChangeFile = (val: string) => {
    setFileSelected(originalFiles?.find((item) => item.name === val))
  }

  const onSearch = () => {
    pipe(
      CashLessService.getOriginalFile(),
      ZIO.map((data) => {
        setOriginalFiles(data)
      }),
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    onSearch()
  }, [])

  const handleRunJobImport = () => {
    setRunJobLoad(true)
    const getDate = moment().toISOString()
    setCanChangeTab(false)
    setRecordFile([])
    pipe(
      CashLessService.submitFileToPulseops(fileSelected?.name || '', userInfo?.email || ''),
      ZIO.flatMap((data) => {
        setFileName(fileSelected?.name || '')
        return pipe(
          CashLessService.loadFileDataSubmit(getDate, fileSelected?.name || '', page, pageSize),
          ZIO.map((res) => {
            setTimeSubmit(getDate)
            setRecordFile(res?.data)
            setTotalTask(Number(res?.total))
            onSearch()
            setFileSelected(undefined)
            setRunJobLoad(false)
            setCanChangeTab(true)
            showToast(t(`message:MS990075`), 'success')
          })
        )
      }),
      ZIO.catchAll((error) => {
        setRunJobLoad(false)
        setCanChangeTab(true)
        const path = `source.response.data.responseStatus.errors.0.code`
        const code = _.get(error, path)
        return ZIO.effect(() => {
          showToast(t(`message:${code}`), 'error')
        })
      }),
      ZIO.unsafeRun({})
    )
  }

  const onChangePage = () => {
    if (!_.isEmpty(fileName))
      pipe(
        CashLessService.loadFileDataSubmit(timeSubmit, fileName || '', page, pageSize),
        ZIO.map((res) => {
          setRecordFile(res?.data)
        }),
        ZIO.unsafeRun({})
      )
  }

  React.useEffect(() => {
    onChangePage()
  }, [page, pageSize])

  const onChangePageSize = (size: number) => {
    setPageSize(Number(size))
    setPage(0)
  }

  return (
    <View style={{ margin: 15 }}>
      <View style={styles.searchContainer}>
        <Text style={styles.sessonText}>{t('TaskManagement:FolderOriginal')}</Text>
        {!_.isEmpty(originalFiles) ? (
          <>
            <FlatList
              keyExtractor={(_item, index) => index.toString()}
              columnWrapperStyle={{ alignSelf: 'baseline' }}
              data={originalFiles}
              style={{ height: 110 }}
              numColumns={Math.floor((width - 75) / 290)}
              key={Math.floor((width - 75) / 290)}
              renderItem={({ item }) => {
                return (
                  <View style={[styles.row, { marginVertical: 10, marginRight: 10, width: 280 }]}>
                    <View style={{ width: 35, justifyContent: 'center' }}>
                      <RadioButton
                        key={item.name}
                        value={item.name}
                        selected={fileSelected?.name === item.name}
                        onChange={onChangeFile}
                        disable={isRunJobLoad}
                      />
                    </View>
                    <View style={{ marginRight: 5 }}>
                      <assets.XLSXIcon />
                    </View>
                    <View style={[styles.col, { justifyContent: 'center', width: 250 }]}>
                      <Text>{item.name}</Text>
                      <Text style={{ color: 'gray' }}>{moment(item.lastModified).format('DD/MM/YYYY HH:mm:ss')}</Text>
                    </View>
                  </View>
                )
              }}
            />
            {/* <ScrollView style={{ height: 200 }}>
              <View style={[styles.col, { alignSelf: 'flex-start', width: '100%' }]}>
                {originalFiles?.map((r, i) => (
                  <View style={[styles.row, { marginVertical: 10, marginRight: 10, width: '25%' }]}>
                    <View style={{ width: 35, justifyContent: 'center' }}>
                      <RadioButton
                        key={i}
                        value={item.name}
                        selected={fileSelected?.name === item.name}
                        onChange={onChangeFile}
                      />
                    </View>
                    <View style={{ marginRight: 5 }}>
                      <assets.XLSXIcon />
                    </View>
                    <View style={[styles.col, { justifyContent: 'center', width: 250 }]}>
                      <Text>{item.name}</Text>
                      <Text style={{ color: 'gray' }}>
                        {moment(item.lastModified).subtract(7, 'hours').format('DD/MM/YYYY HH:mm:ss')}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </ScrollView> */}
            <View style={{ alignItems: 'center', marginTop: 10 }}>
              <TouchableOpacity
                style={{ height: 40 }}
                onPress={handleRunJobImport}
                disabled={isRunJobLoad || _.isUndefined(fileSelected)}
              >
                <View
                  style={[
                    styles.btn,
                    {
                      backgroundColor: isRunJobLoad || _.isUndefined(fileSelected) ? '#B0B0B0' : '#ed1b2e'
                    }
                  ]}
                >
                  <Text style={styles.btnText}>{t('TaskManagement:RunJobImport')}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </>
        ) : _.isNull(originalFiles) ? (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="red" />
          </View>
        ) : (
          <View style={{ alignItems: 'center' }}>
            <Text style={{ color: 'gray', fontSize: 20 }}>{t('common:Nodata')}</Text>
          </View>
        )}
      </View>
      {isRunJobLoad ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text>
            <ActivityIndicator size="small" color="red" /> {t('common:WaitLoading')}
          </Text>
        </View>
      ) : (
        <>
          <Text style={styles.sessonText}>{t('TaskManagement:DetailInfo')}</Text>
          <View style={{ marginTop: 10 }}>
            <TableContainer component={Paper} className={classes.container} elevation={1}>
              <Table className={classes.table} stickyHeader>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:BankCode')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:PolicyNo/ProposalNo')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:Amount')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:RefNo')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:ReceivedDatePC')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:CreditDate')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:FileNameImport')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('TaskManagement:StatusPC')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>
                      {t('TaskManagement:EmployeeImplementation')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {isLoading ? (
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                          <ActivityIndicator size="large" color="red" />
                        </View>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {!_.isEmpty(recordFile) ? (
                        <>
                          {recordFile.map((item: RecordFileInfo, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className={classes.tableCell}>{item.bankCode}</TableCell>
                                <TableCell className={classes.tableCell}>
                                  <Text>{item.primaryPolicyNo || '-'}</Text>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {formatNumberWithComma((item?.amount || '').toString())}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{item.refNo}</TableCell>
                                <TableCell className={classes.tableCell}>
                                  {moment(item.receivedDate).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {moment(item.creditDate).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{item.fileName}</TableCell>
                                <TableCell className={classes.tableCell}>
                                  {Status.find((x) => x.value === item.status)?.label}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {(item.createdBy || '').split('@')[0]}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                            {t('common:Nodata')}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={page}
              rowsPerPage={pageSize}
              count={totalTask}
              onPageChange={(e, page) => {
                setPage(page)
              }}
              onRowsPerPageChange={(e) => {
                onChangePageSize(Number(e.target.value))
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          </View>
        </>
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  sessonText: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 15
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginVertical: 15,
    padding: 25
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  },
  btn: {
    height: 40,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  col: {
    flex: 1,
    flexDirection: 'column'
  },
  btnText: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'NotoSans_700Bold'
  }
})
