import { Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { AmlResultC } from './aml-result'

export const ContractDetailC = t.type({
  // thong tin chung
  masterContractNo: t.string,
  submitDate: DateFromISOString,
  status: Nullable(t.string),
  policyYear: t.number,
  firstPolicyEffectiveDate: DateFromISOString,
  policyTerm: t.number,
  exclusionTerm: Nullable(t.string),
  holdClaimsBenefitPayout: Nullable(t.string),
  premiumPaymentStatus: Nullable(t.string),

  // thong tin ben mua bao hiem
  policyHolder: t.type({
    policyHolder: t.string,
    businessAddress: t.type({
      address: t.string,
      country: t.string,
      city: Nullable(t.string),
      district: Nullable(t.string),
      ward: Nullable(t.string),
      phoneNumber: t.string,
      email: t.string
    }),
    contactAddress: t.type({
      address: t.string,
      country: t.string,
      city: Nullable(t.string),
      district: Nullable(t.string),
      ward: Nullable(t.string),
      phoneNumber: t.string,
      email: t.string
    }),
    businessSector: t.string,
    businessLicense: t.string,
    dateOfRegistration: DateFromISOString,
    placeOfRegistration: t.string,
    capital: t.number,
    taxCode: t.string,
    entityType: t.string,
    historyAmlResult: t.array(AmlResultC),
    uniqueIdentifierAml: Nullable(t.string)
  }),

  // nguoi dai dien theo phap luat
  legalRepresentative: t.type({
    fullName: t.string,
    position: t.string,
    idNumber: t.string,
    dateOfIssue: DateFromISOString,
    placeOfIssue: t.string,
    dateOfBirth: DateFromISOString,
    placeOfBirth: t.string,
    gender: t.string,
    nationality: t.string,
    otherNationality: Nullable(t.string),
    visa: Nullable(t.string),
    phoneNumber: t.string,
    email: t.string,
    permanentAddress: t.string,
    country: t.string,
    permanentCity: Nullable(t.string),
    permanentDistrict: Nullable(t.string),
    permanentWard: Nullable(t.string),
    residentAddress: t.string,
    country1: t.string,
    residentialCity: Nullable(t.string),
    residentialDistrict: Nullable(t.string),
    residentialWard: Nullable(t.string),
    overseaAddress: Nullable(t.string),
    country2: Nullable(t.string),
    overseaCity: Nullable(t.string),
    overseaDistrict: Nullable(t.string),
    overseaWard: Nullable(t.string),
    hasstockexchange: t.string,
    hasusshareholder: t.string,
    historyAmlResult: t.array(AmlResultC),
    uniqueIdentifierAml: Nullable(t.string)
  }),

  // nguoi dai dien lien he
  policyAdmin: t.type({
    fullName: t.string,
    position: t.string,
    idNumber: t.string,
    phoneNumber: t.string,
    email: t.string,
    historyAmlResult: t.array(AmlResultC),
    uniqueIdentifierAml: Nullable(t.string)
  }),

  totalEmployees: t.number,
  totalEmployeesInsured: t.number,
  proposedEffectiveDate: DateFromISOString,
  modeOfPayment: t.string,
  totalAnnualPremium: t.number,
  discountPromotion: Nullable(t.string),
  netAnnualPremium: Nullable(t.number),
  insufficientPremium: Nullable(t.number),
  previousCurrentInsurerName: Nullable(t.string),
  groupPolicyNumber: Nullable(t.string),
  groupPolicyEffectiveDate: Nullable(DateFromISOString),
  bankName: Nullable(t.string),
  bankBranch: Nullable(t.string),
  accountNumber: Nullable(t.string),
  accountName: Nullable(t.string),
  establishedDate: DateFromISOString,
  agentNumber1: t.string,
  agentName1: t.string,
  commissionRate1: t.string,
  agentNumber2: Nullable(t.string),
  agentName2: Nullable(t.string),
  commissionRate2: Nullable(t.string),
  historyAmlResult: t.array(AmlResultC),
  uuid: t.string,
  version: t.number
})
export type ContractDetail = t.TypeOf<typeof ContractDetailC>
