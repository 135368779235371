import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  ErrorHandling,
  form2,
  Input,
  sharedStyle,
  SubmissionService,
  TransactionType,
  PulseOpsFormat
} from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
// import i18next from 'i18next'
import * as t from 'io-ts'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps } from './policy-service-props'

const FormData = t.type({
  aplDate: form2.string.required,
  // reason: form2.string.requiredM(() =>
  //   i18next.t('message:MS020001', {
  //     field: i18next.t('submission:Reason')
  //   })
  // )
  reason: form2.string.optional
})
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

export const StopAPL = (props: PolicyServiceProps<FormDataRaw>) => {
  const { t } = useTranslation()

  const policyNumber = props.policyNumber!
  const defaultValues: FormDataRaw = {
    aplDate: '',
    reason: ''
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })

  const data = pipe(
    SubmissionService.getPolicy(policyNumber),
    ZIO.tap((policy) =>
      ZIO.effect(() => {
        base.setValue(
          'aplDate',
          policy.body.paidToDateAdvance ? PulseOpsFormat.dateStrWF(policy.body.paidToDateAdvance, 'DD/MM/yyyy') : ''
        )
      })
    ),
    ErrorHandling.runDidMount(null)
  )

  props.initSubmission({
    validate: async () => {
      const _form = await handleSubmit(() => undefined)()
      if (E.isRight(_form)) {
        const form = _form.right

        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/stop-apl`,
          body: {
            aplDate: form.aplDate,
            reason: form.reason
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.STOP_APL),
          collerationId: policyNumber,
          transaction: TransactionType.STOP_APL
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset({
        ...defaultValues,
        aplDate: base.watch('aplDate')
      })
    }
  })

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:StopAPL')}</Text>
        <View style={sharedStyle.sectionContent}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="aplDate"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('submission:AplDate')}
                    value={value ?? undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={true}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="reason"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('submission:Reason')}
                    value={value ?? undefined}
                    maxLength={200}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={props.isConfirmed}
                    errorMessage={error?.message}
                    placeholder={props.isConfirmed ? '' : t('submission:ReasonPlaceHolder')}
                  />
                )}
              />
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  }
} as const)
