import { pipe } from 'fp-ts/lib/function'
// import * as t from 'io-ts'
import { Task, ZIO } from '@mxt/zio'
import { SubmissionService } from './SubmissionService'

export namespace ScheduledPaymentStatementService {
  export interface SubmitData {
    fromDate: string
    toDate: string
  }

  export interface ScheduledPaymentStatementDetail {
    firstReleaseCashDate: string | null
  }

  export const checkAccess = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policy) => ({ basicCode: policy.body.basicCode ?? '' }))
    )

  export const getDetail = (policyNum: string): Task<string | null> =>
    pipe(
      SubmissionService.getPrucashDetail(policyNum),
      ZIO.map((p) => {
        const sortArray = p.subfileOccurs.sort((a, b) => a.loanNumber - b.loanNumber)
        return sortArray.length ? sortArray[0].loanStartDate : null
      })
    )

  //   export const submit = (policyNum: string, body: SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
  //     SubmissionService.submit(t.unknown)(`wf-api/policy/${policyNum}/scheduled-payment`, { body }, policyNum, requestauth, [])
}
