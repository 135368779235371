import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { Text, View } from '@pulseops/business/core'
import * as React from 'react'
import { RBAC, Permission, ErrorHandling } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import styled from 'styled-components/native'
import { ContractInfo } from './contract-info'
import { EmployeeList } from './employee-list'
import { ProductInfo } from './product-info'
import { ShareholderList } from './shareholder-list'

enum TabId {
  ContractInfo,
  ShareholderList,
  ProductInfo,
  EmployeeList
}

type Tab = {
  id: TabId
  title: string
  role: Permission
  element: () => JSX.Element
}

const tabs: Tab[] = [
  {
    id: TabId.ContractInfo,
    title: 'contract_info',
    role: Permission['ViewContractInfoGroupDetailGeneralInfo'],
    element: () => <ContractInfo />
  },
  {
    id: TabId.ShareholderList,
    title: 'shareholder_list',
    role: Permission['ViewShareholderGroupDetailGeneralInfo'],
    element: () => <ShareholderList />
  },
  {
    id: TabId.ProductInfo,
    title: 'product_info',
    role: Permission['ViewBenefitGroupDetailGeneralInfo'],
    element: () => <ProductInfo />
  },
  {
    id: TabId.EmployeeList,
    title: 'employee_list',
    role: Permission['ViewEmployeeGroupDetailGeneralInfo'],
    element: () => <EmployeeList />
  }
]

export const GeneralInfo = () => {
  const { t } = useTranslation('business')

  pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      const activeTabs = tabs.filter((tab) => permissions.includes(tab.role))
      setActiveTabs(activeTabs)
      setSelected(activeTabs[0] || null)
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const [selected, setSelected] = React.useState<Tab | null>(null)
  const [activeTabs, setActiveTabs] = React.useState<Tab[]>([])

  return (
    <SC.Container>
      <SC.TabRow>
        {selected &&
          activeTabs.map(({ id, title }) => (
            <SC.Tab
              key={id.toString()}
              selected={selected.id === id}
              disabled={selected.id === id}
              onPress={() => {
                setSelected(activeTabs.find((tab) => tab.id === id) || null)
              }}
            >
              <SC.TabText selected={selected.id === id}>{t(title)}</SC.TabText>
            </SC.Tab>
          ))}
      </SC.TabRow>
      <SC.TabPanel selected={true}>{selected?.element()}</SC.TabPanel>
    </SC.Container>
  )
}

export const SC = {
  Container: styled(View)`
    padding: 1rem;
    background: white;
  `,
  TabRow: styled(View)`
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  `,
  Tab: styled(Pressable)<{ selected?: boolean }>`
    color: #4f4f4f;
    border-bottom-width: ${(p) => (p.selected ? '3px' : '1px')};
    border-bottom-color: ${(p) => (p.selected ? p.theme.colors.main : '#C4C4C4')};
    padding-bottom: 9px;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  `,
  TabText: styled(Text)<{ selected?: boolean }>`
    font-size: 12px;
    font-weight: ${(p) => (p.selected ? 'bold' : 'normal')};
  `,
  TabPanel: styled(View)<{ selected?: boolean }>`
    display: ${(p) => (p.selected ? 'block' : 'none')};
    background: #ffffff;
  `
}
