import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export namespace ReleasePolicy {
  export interface InputData {
    body: {
      pageNum: number
      pageSize: number
    }
  }

  export const codec = t.type({
    createdDate: Maybe(DateFromISOString),
    createdBy: Maybe(t.string),
    createdName: Maybe(t.string),
    fileUploadName: Maybe(t.string),
    fileUploadUrl: Maybe(t.string),
    status: Maybe(t.number),
    fileErrorName: Maybe(t.string),
    fileErrorUrl: Maybe(t.string),
    linesProcess: Maybe(t.number),
    linesSuccess: Maybe(t.number),
    linesFailed: Maybe(t.number)
  })
  
  export const Response = t.type({
    exchangeId: t.string,
    body:
    t.type({
        data: t.union([t.array(codec), t.null]),
        size: t.number,
        start: t.number,
        total: t.number
    })
  })
  export type Response = t.TypeOf<typeof Response>

  export const ResponseStatus = t.type({
    responseStatus: t.type({
      code: t.number,
      message: t.string
    })
  })
  export type ResponseStatus = t.TypeOf<typeof Response>
}
