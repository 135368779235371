import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBGeneralTable, IBGridDataView, IBPanel } from '../../../../../common'
import { EstimatedValueInfo, IBService, InvestmentDataInfo, InvestmentFund } from '../../../../../ib-service'
import {
  PartialWithdrawalInfo,
  PartialWithdrawalHistory,
  AllocatedPremiumInfo,
  AllocationChargeInfo,
  CostOfInsuranceInfo,
  AdministrationChargeInfo,
  TopUpHistory,
  InvestmentInterest,
  AccountValueInfo
} from './IBInvestmentInfoPanelChildrens'
import { PulseOpsFormat, assets } from '@pulseops/common'
import { isNil } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
interface Props {
  scrollRef: React.MutableRefObject<ScrollView | null>
  policyNumber: string
  productCode: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  investmentInfo?: InvestmentDataInfo
  status: string
  productType: string
  listFundT25?: InvestmentFund[]
  basicCode: string
}

export const IBULP = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const {
    policyNumber,
    productCode,
    setInvestmentInfo,
    investmentInfo,
    productType,
    status,
    scrollRef,
    listFundT25,
    basicCode
  } = props
  const [layoutY, setLayoutY] = React.useState<number | null>(null)
  const [autoToggleKey, setAutoToggleKey] = React.useState<string | undefined | null>(null)
  const [loading, bindLoading] = useLoading()

  React.useEffect(() => {
    if (layoutY) {
      scrollRef.current?.scrollTo({ x: 0, y: 330 + layoutY, animated: true })
    }
  }, [layoutY])

  const scrollToKey = (key: string) => {
    setAutoToggleKey(key)
    setTimeout(() => {
      setAutoToggleKey(null)
      setLayoutY(null)
    }, 1000)
  }

  React.useEffect(() => {
    if (isNil(investmentInfo?.partialWithdrawHistory)) {
      const transCode = 'T619'
      pipe(
        IBService.ACMVQuery(policyNumber, 'PARTIAL_WITHDRAWAL_HISTORY', transCode, undefined, true),
        ZIO.map((res) => {
          if (res.data) {
            setInvestmentInfo({ ...(investmentInfo as InvestmentDataInfo), partialWithdrawHistory: res.data })
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataGridView = [
    {
      label: t('EstimatedValueTotal'),
      value: investmentInfo?.estimatedValueInfo?.estimatedValueTotal + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AccountValueInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalExcessPremium'),
      value: investmentInfo?.estimatedValueInfo?.totalExcessPremium + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('TopUpHistory'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAllocatedPremiumToBasicProductAndGroup1Riders'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocatedPremiumToBasicProductAndGroup1Riders + ' VNĐ' || '0'
    },
    {
      label: t('TotalAllocationCharges'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocationCharges + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AllocationChargeInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TargetPremiumEstimatedValue'),
      value: investmentInfo?.estimatedValueInfo?.targetPremiumEstimatedValue + ' VNĐ' || '0'
    },
    {
      label: t('TotalWithdrawnAmount'),
      value:
        PulseOpsFormat.thousandSepartor(
          investmentInfo?.partialWithdrawHistory?.reduce(
            (pre, cur) => (pre = pre + (cur.additionalProperties?.partialWithDrawMoney?.withDrawAmount as number)),
            0
          )
        ) + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('PartialWithdrawalHistory'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAllocatedPremiumToGroup2Riders'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocatedPremiumToGroup2Riders + ' VNĐ' || '0'
    },
    {
      label: t('TotalCostOfInsurance'),
      value: investmentInfo?.estimatedValueInfo?.totalCostOfInsurance + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('CostOfInsuranceInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TopUpPremiumEstimatedValue'),
      value: investmentInfo?.estimatedValueInfo?.topUpPremiumEstimatedValue + ' VNĐ' || '0'
    },
    {
      label: t('MaximumWithdrawableAmount'),
      value: basicCode === 'IPD1' ? '-' : investmentInfo?.estimatedValueInfo?.maximumWithdrawableAmount + ' VNĐ' || '0',
      labelSuffixIcon:
        basicCode === 'IPD1' ? (
          <></>
        ) : (
          <Pressable onPress={() => scrollToKey(t('PartialWithdrawalInfo'))}>
            <assets.IBChevronRightCircle />
          </Pressable>
        )
    },
    {
      label: t('TotalAllocatedPremiumToAccountValue'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocatedPremiumToAccountValue + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AllocatedPremiumInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAdministrationCharge'),
      value: investmentInfo?.estimatedValueInfo?.totalAdministrationCharge + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AdministrationChargeInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    }
  ]

  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <View>
          <Text style={{ fontSize: 15, fontWeight: '700', marginVertical: 20 }}>
            {t('AccountValueInformation').toUpperCase()}
          </Text>
          <IBGridDataView data={dataGridView} col={4} />
          <View style={{ marginTop: 10 }}>
            <IBPanel
              title={t('InvestmentInterest')}
              children={
                <InvestmentInterest
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                />
              }
            />
            <IBPanel
              title={t('AccountValueInfo')}
              children={
                <AccountValueInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('TopUpHistory')}
              children={
                <TopUpHistory
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                  listFundT25={listFundT25}
                  basicCode={basicCode}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('PartialWithdrawalHistory')}
              children={
                <PartialWithdrawalHistory
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('PartialWithdrawalInfo')}
              children={
                <PartialWithdrawalInfo
                  policyNumber={policyNumber}
                  productCode={productCode}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  status={status}
                  basicCode={basicCode}
                  productType={productType}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('AllocatedPremiumInfo')}
              children={
                <AllocatedPremiumInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('AllocationChargeInfo')}
              children={
                <AllocationChargeInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('CostOfInsuranceInfo')}
              children={
                <CostOfInsuranceInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('AdministrationChargeInfo')}
              children={
                <AdministrationChargeInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
          </View>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({})
