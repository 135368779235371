import { Pressable, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { IBStyles, IBGeneralTable } from '../../../../../common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { ClaimPaymentDetailInfo, IBService } from '../../../../../ib-service'
import { useLoading } from '@mxt/zio-react'
import { assets } from '@pulseops/common'
import React from 'react'
import moment from 'moment'

type Props = {
  title: string, isShowDetail: boolean, setShowDetail: (isShow: boolean) => void, sacsCode: string, sacsType: string, policyNumber: string
}
export const IBClaimPaymentDetail = React.memo(({title, isShowDetail, setShowDetail, sacsCode, sacsType, policyNumber}: Props) => {
  const { t } = useTranslation()
  const [loading, bindLoading] = useLoading(false)
  const [dataDetail, setDataDetail] = React.useState<ClaimPaymentDetailInfo[]>([])

  const dataTableClaimPayment = [
    { label: t('Inbound:EffDate'), field: 'effectiveDate', disabled: true },
    { label: t('Inbound:Transno'), field: 'transNo', disabled: true },
    { label: t('Inbound:GLCode'), field: 'glCode', disabled: true },
    { label: t('Inbound:Trandate'), field: 'transDate', disabled: true },
    { label: t('Inbound:CodeBenefit'), field: 'transCode', disabled: true },
    { label: t('Inbound:AccountingAmount'), field: 'accountingAmount', format: 'money', disabled: true }
  ]

  React.useEffect(() => {
    if(isShowDetail) {
      pipe(
        IBService.getClaimPaymentDetail(policyNumber, sacsCode, sacsType),
        ZIO.map((res) => {
          res && setDataDetail(res.map(((item) => ({
            ...item,
            accountingAmount: (item.moneySign === '-' && item.accountingAmount) ? (0 - item.accountingAmount) : item.accountingAmount
          }))).sort((p1, p2) => {
            if(p1.transDate && p2.transDate) {
              if(moment(p1.transDate, 'DD/MM/yyyy').toDate() < moment(p2.transDate, 'DD/MM/yyyy').toDate()) {
                return 1
              }
              else if(p1.transDate === p2.transDate) {
                if(p1.transNo && p2.transNo && Number(p1.transNo) < Number(p2.transNo)) return 1
                return -1
              }
            }
            return -1
          }))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [isShowDetail])

  return (
    <View style={{ paddingBottom: 15 }}>
      <View style={[IBStyles.row, { justifyContent: 'space-between', paddingBottom: 10, paddingRight: 20 }]}>
        <Text style={{ fontWeight: '700', color: '#70777E', fontSize: 15 }}>
          {title}
        </Text>
        <Pressable onPress={() => setShowDetail(!isShowDetail)}>
          {isShowDetail ? <assets.IBCircleCollapseIcon /> : <assets.IBCircleExpandIcon />}
        </Pressable>
      </View>
      {isShowDetail &&
        <IBGeneralTable
          dataTable={dataTableClaimPayment}
          data={dataDetail}
          autoPaging={true}
          loading={loading}
        />
      }
    </View>
  )
})