import {
  Columns,
  mapLasStatus,
  Panel,
  Table,
  GeneralService,
  ErrorHandling,
  getLanguage,
  PayoutPopup,
  PaymentType
} from '@pulseops/common'
import { LasStatus } from 'libs/common/src/service/model/LasStatus'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, Text, View, StyleSheet, ViewStyle } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Title } from '../../common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Button } from 'react-native-elements'
import { PayoutInfoPopup } from '@pulseops/submission/common'

export enum CashlessMethod {
  BANK = 'Bank transfer', // (Chuyển khoản qua ngân hàng)
  PAB = 'Paid at bank', //(Nhận tiền mặt bằng CMND tại ngân hàng)
  PAC = 'Receive cash at counter', //(Nhận tiền mặt tại quầy)
  MOMO = 'eWallet Momo' //(Ví điện tử Momo)
}

export interface PayoutMethod {
  payPremium: boolean
  repayLoan: boolean
  otherPremium: boolean
  cashless: boolean
  cashlessMethod: CashlessMethod | undefined
}

export interface PayoutMethodData extends PayoutMethod {
  totalPayoutAmount: string
  payouts: {
    payoutMethod: string
    detail: string
    amount: string
    status?: string | null
    note: string
  }[]
}

export const PayoutMethod = ({
  payoutMethodData,
  containerStyle,
  panelContainerStyle,
  paymentTitle,
  payeeData,
  isFractionFormat
}: {
  payoutMethodData: PayoutMethodData
  containerStyle?: ViewStyle
  panelContainerStyle?: ViewStyle
  paymentTitle?: ViewStyle
  payeeData?: PayoutPopup.Summary
  isFractionFormat?: boolean
}) => {
  const { t } = useTranslation()
  const [viewInfoSpecs, setViewInfoSpecs] = useState<{ index: number; visible: boolean }>({ index: -1, visible: false })

  const noteColor = (status: string) => mapLasStatus.get(status.toUpperCase() as LasStatus)?.color || ''

  const displayedColumns: Columns[] = [
    { key: 'payout1', name: 'payoutMethod', title: t('Payout:PayMethodSummary') },
    { key: 'payout2', name: 'detail', title: t('common:Detail') },
    { key: 'payout3', name: 'amount', title: t('Payout:PayoutAmount'), styles: { textAlign: 'right' } },
    { key: 'payout4', name: 'status', title: t('common:Status'), color: noteColor, styles: { textAlign: 'center' } },
    { key: 'payout5', name: 'note', title: t('common:Note'), color: noteColor, styles: { textAlign: 'center' } },
    {
      key: 'payout6',
      name: 'action',
      title: 'Action',
      color: noteColor,
      styles: { textAlign: 'center' },
      render: ({ index }) => {
        const isPayeeView = payoutMethodData.payouts.some(
          (x, i) =>
            [PaymentType.BANKTRANSFER, PaymentType.PAID_AT_BANK, PaymentType.CASH_AT_COUNTER].includes(
              x.payoutMethod as PaymentType
            ) && index === i
        )
        return isPayeeView ? (
          <View style={{ alignItems: 'center' }}>
            <Button
              title={<Text style={{ fontSize: 15, color: '#fff' }}>{t('submission:ViewInfo')}</Text>}
              buttonStyle={{
                width: 118,
                height: 32,
                borderRadius: 100
              }}
              onPress={() => setViewInfoSpecs({ index, visible: true })}
            />
          </View>
        ) : (
          <></>
        )
      }
    }
  ]

  const dataSource = pipe(
    GeneralService.getPayoutMethods(),
    ZIO.map((res) => res.body),
    ZIO.map((payoutMethods) => {
      const isEn = getLanguage() === 'en'
      return payoutMethodData.payouts.map((x) => {
        const payoutMethod = payoutMethods.find((item) => item.typeEnum === x.payoutMethod)

        return {
          ...x,
          status: mapLasStatus.get(x.status as LasStatus)?.label || '-',
          payoutMethod: payoutMethod ? (isEn ? payoutMethod.nameEn : payoutMethod.nameVi) : '-'
        }
      })
    }),
    ErrorHandling.runDidMount([])
  )

  return (
    <SafeAreaView style={containerStyle}>
      <ScrollView>
        <Title title={paymentTitle || t('Payout:PAYMENTINFORMATION')} />
        <Panel isExand={false} allowToggle={false} containerStyle={panelContainerStyle}>
          <Text style={{ fontSize: 16 }}>
            {t('Payout:TotalPayoutAmount')}:
            <Text style={{ fontWeight: '700', color: '#ed1b2e', marginLeft: 10 }}>
              {payoutMethodData.totalPayoutAmount} VND
            </Text>
          </Text>
          <View style={{ flexDirection: 'row', marginVertical: 10 }}>
            <Text style={{ width: '80%', fontSize: 15 }}>{t('Payout:PayoutDetails')}</Text>
            <Text style={{ width: '20%', textAlign: 'right', fontSize: 15, fontStyle: 'italic' }}>
              {t('Payout:Currency')}: VND
            </Text>
          </View>

          <Table
            containerStyle={styles.tableContainer}
            headerContainerStyle={styles.headerContainer}
            rowStyle={styles.rowContainer}
            headerTitleStyle={styles.headerTitle}
            columns={displayedColumns}
            dataSource={dataSource}
          ></Table>
        </Panel>
      </ScrollView>

      <PayoutInfoPopup
        visible={viewInfoSpecs.visible}
        data={payeeData}
        isFractionFormat={isFractionFormat}
        onClose={() => setViewInfoSpecs({ index: -1, visible: false })}
      ></PayoutInfoPopup>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  tableContainer: {
    marginTop: 16,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF'
  },

  headerContainer: {
    backgroundColor: '#e2e7ea',
    padding: 24
  },

  rowContainer: {
    paddingHorizontal: 24
  },

  headerTitle: {
    color: '#000',
    fontSize: 15,
    fontWeight: '700'
  },

  tableValue: {
    fontSize: 16,
    color: '#212529',
    fontWeight: '500'
  },

  tableValueAlignRight: {
    fontSize: 16,
    textAlign: 'right',
    color: '#212529',
    fontWeight: '500'
  },

  tableValueAlignCenter: {
    fontSize: 16,
    textAlign: 'center',
    color: '#212529',
    fontWeight: '500'
  }
})
