export namespace Duration {
  export interface DurationOption {
    value: string
    label: string
  }

  export enum Duration {
    From3To6Months = 'From3To6Months',
    From6To1Year = 'From6To1Year',
    From1To5Years = 'From1To5Years',
    From5To10Years = 'From5To10Years',
    From10To20Years = 'From10To20Years',
    From20Years = 'From20Years'
  }
}
