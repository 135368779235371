import { ZIO } from '@mxt/zio'
import { form2, SelectOption } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as React from 'react'
import { UseFormWatch } from 'react-hook-form'
import { FormService } from '../general-info/form-service'

export const useAddressForm = (watch: UseFormWatch<any>, addressPath: string) => {
  const [districtOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])

  const city: string | null = watch(`${addressPath}.city`)
  const district: string | null = watch(`${addressPath}.district`)

  React.useEffect(() => {
    if (city) {
      pipe(
        FormService.getDistrictOptions(city),
        ZIO.map((options) => setDistrictOptions(options)),
        ZIO.unsafeRun({})
      )
    }
    setDistrictOptions([])
    setWardOptions([])
  }, [city])

  React.useEffect(() => {
    if (district && city) {
      pipe(
        FormService.getWardOptions(city, district),
        ZIO.map((options) => setWardOptions(options)),
        ZIO.unsafeRun({})
      )
    }
    setWardOptions([])
  }, [district, city])

  return {
    districtOptions,
    wardOptions
  }
}

export namespace AddressForm {
  export const codec = t.type({
    city: form2.string.optional,
    district: form2.string.optional,
    ward: form2.string.optional
  })
}
