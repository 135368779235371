import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { ModalComponent, AppContext } from '@pulseops/common'
import { TaskDetailScreen } from '../../../../../../../task/src/task-detail/TaskDetailScreen'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { TaskDetailStackParamList } from '../../../../../../../task/src/task-detail/TaskDetailStackParamList'
type Props = StackScreenProps<TaskDetailStackParamList, 'TaskDetailScreen'>

export const IBModalTaskDetail = (props: {
  visible: boolean
  onClose: (data: { visible: boolean; id: string; category: string; suspendReason: string }) => void
  objDetail: {
    visible: boolean
    id: string
    category: string
    suspendReason: string
  }
}) => {
  const { visible, onClose, objDetail } = props
  const { showGlobalLoading, isGlobalLoadingVisible } = React.useContext(AppContext.AppContextInstance)
  const [data, setData] = React.useState<Props>({
    route: {
      params: {
        id: objDetail.id,
        caterogy: objDetail.category,
        basket: '',
        basketName: '',
        isInquiry: '1',
        isHistory: 'true',
        isBreadcrumb: '1',
        isPCWriteOff: objDetail.suspendReason === 'WRITE_OFF' ? 'true' : 'false',
        isChangeBreadrum: true
      }
    }
  })

  return (
    <ModalComponent
      title={``}
      modalWidth={'85%'}
      modalHeight={'90%'}
      visible={visible}
      onClose={() => {
        onClose({ id: '', category: '', suspendReason: '', visible: false })
        showGlobalLoading(false)
      }}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 0 }}
    >
      {isGlobalLoadingVisible && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color="red" />
        </View>
      )}
      <TaskDetailScreen {...data} />
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  loading: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    right: '50%',
    zIndex: 1
  }
})
