import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native-gesture-handler'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { CallOutDetailContext, OBContainer, OBFormat, OBGeneralService, OBLifeAssuredData } from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { AppContext, AuthService, CancelRiderService, Container, ContractMapping, ErrorHandling, PulseOpsFormat, useTranslator } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'

type DataClient = {
  lifeNo: string | null | undefined,
  clientNumber: string | null | undefined,
  clientFullName: string | null | undefined,
  genderCode: string | null | undefined,
  dob: string | null | undefined,
  idNumber: string | null | undefined,
}

type DataComponent = {
  coverageCode: string | null | undefined,
  statusCode: string | null | undefined,
  sumAssuredAmt: number | null | undefined,
  installmentPremiumAmy: number | null | undefined,
  paidToDate: string | null | undefined,
  riskCessationDate: string | null | undefined
  premCessDate: string | null | undefined
}

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#F4F4F4'
  },
  tableCell: {
    minWidth: 30,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: '#70777E'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})

export const OBLifeAssured = () => {
  const { t, i18n } = useTranslation()
  const [dataLifeAssured, setDataLifeAssured] = React.useState<OBLifeAssuredData[]>([])
  const [loading, bindLoader] = useLoading(false)
  const { policyNumber } = React.useContext(CallOutDetailContext)
  const ct = useTranslator('contract').t
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const classes = useStyles()

  const columnsClient = [
    t('Outbound:OBLifeAssured:ClientNo'),
    t('Outbound:OBLifeAssured:FullName'),
    t('Outbound:OBLifeAssured:Gender'),
    t('Outbound:OBLifeAssured:DateOfBirth'),
    t('Outbound:OBLifeAssured:IDNumber')
  ]

  const columnsComponent = [
    'No.',
    t('Outbound:OBLifeAssured:ComponentName'),
    t('Outbound:OBLifeAssured:Status'),
    t('Outbound:OBLifeAssured:SumAssured'),
    t('Outbound:OBLifeAssured:InstallmentPremium'),
    t('Outbound:OBLifeAssured:PaidToDate'),
    t('Outbound:OBLifeAssured:RiskCessationDate'),
    t('Outbound:OBLifeAssured:PremCessDate')
  ]
 
  const columnsComponentWidth = ['5%', '28%', '16%', '13%', '13%', '13%', '12%']

  React.useEffect(() => {
    pipe(
      OBGeneralService.getLifeAssured(policyNumber),
      ZIO.map((data) => {
        console.log(data)
        setDataLifeAssured(data || [])
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [policyNumber])

  const getGenderName = (gender?: string | null): string => {
    switch (gender) {
      case 'M':
        return t('common:Male')
      case 'F':
        return t('common:Female')
      default:
        return '-'
    }
  }

  const getFileByUrl = (url: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const fileType = res.headers.get("content-type") || ''
        const blobUrl = window.URL.createObjectURL(blob)
        if(fileType === 'image/tiff'){
          OBFormat.viewTiffFileByNewTab(blobUrl)
        } else {
          window.open(blobUrl, '_blank')
        }
      }),
      ErrorHandling.run()
    )
  }

  const onViewFile = (componentCode: string) => {
    if (componentCode) {
      pipe(
        OBGeneralService.getDocByComponent(componentCode),
        ZIO.flatMap((responseData) => {
          const url = responseData && responseData.length > 0 ? responseData[0].url : ''
          if (!!url) {
            return ZIO.fromPromise(() => getFileByUrl(url))
          } else {
            showToast(t('message:OB0004'), 'error')
            return ZIO.unit
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const riderNameList =
  pipe(
    CancelRiderService.getRiderInfoList(),
    ZIO.map((r) => {
      return r
    }),
    ErrorHandling.runDidMount()
  )

  const getRiderName = (code: string) => {
    const contractName = riderNameList && riderNameList.find((_) => _.code === code)
    return contractName ? i18n.language === 'en' ? contractName?.name : contractName?.nameVi : '-'
  }

  const TableLifeAssured = (dataClient: DataClient, dataComponent: DataComponent[], index: number) => {
    return (
      <OBContainer containerTitle={index === 0 ? t('Outbound:OBLifeAssured:MainLifeAssured') : t('Outbound:OBLifeAssured:LifeAssured') + ` ${index}`}>
        <View style={{ marginLeft: 20, marginRight: 20 }}>
          <TableContainer component={Paper} key={`${dataClient.lifeNo}`}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {columnsClient.map((colItem, index) => {
                    return (
                      <TableCell className={classes.tableCell} style={{ width: '20%' }} key={`${dataClient.lifeNo} - colItem_' - ${index}`}>{colItem}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={`dataItemClient + ${dataClient.clientNumber}`}>
                  <TableCell>{dataClient.clientNumber}</TableCell>
                  <TableCell>{dataClient.clientFullName}</TableCell>
                  <TableCell>{dataClient.genderCode ? getGenderName(dataClient.genderCode) : '-'}</TableCell>
                  <TableCell>{dataClient.dob ? PulseOpsFormat.dateStringtoFormat(dataClient.dob, 'DD/MM/YYYY') : '-'}</TableCell>
                  <TableCell>{dataClient.idNumber}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {dataComponent.length > 0 ? (
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    {columnsComponent.map((colItem, index) => {
                      return (
                        <TableCell className={classes.tableCell} style={{ width: columnsComponentWidth[index] }} key={`${dataClient.lifeNo} - colItemComponent' - ${index}`}>{colItem}</TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataComponent.map((dataItem, index) => {
                    return (
                      <TableRow key={`${dataClient.clientNumber} - ${dataItem.coverageCode} - ${index}`}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Pressable onPress={() => onViewFile(dataItem.coverageCode ?? '')}>
                            <Text style={lifeASStyle.linkText}>
                              {dataItem.coverageCode ? (dataItem.coverageCode + ' - ' + getRiderName(dataItem.coverageCode)) : '-'}
                            </Text>
                          </Pressable>
                        </TableCell>
                        <TableCell>{dataItem.statusCode ? ContractMapping.get(ct)(dataItem.statusCode) : '-'}</TableCell>
                        <TableCell>{dataItem.sumAssuredAmt ? PulseOpsFormat.thousandSepartor(dataItem.sumAssuredAmt) : '-'}</TableCell>
                        <TableCell>{dataItem.installmentPremiumAmy ? PulseOpsFormat.thousandSepartor(dataItem.installmentPremiumAmy) : '-'}</TableCell>
                        <TableCell>{dataItem.paidToDate ? PulseOpsFormat.dateStringtoFormat(dataItem.paidToDate, 'DD/MM/YYYY') : '-'}</TableCell>
                        <TableCell>{dataItem.riskCessationDate ? PulseOpsFormat.dateStringtoFormat(dataItem.riskCessationDate, 'DD/MM/YYYY') : '-'}</TableCell>
                        <TableCell>{dataItem.premCessDate ? PulseOpsFormat.dateStringtoFormat(dataItem.premCessDate, 'DD/MM/YYYY') : '-'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            ) : <View></View>
            }
          </TableContainer>
        </View>
      </OBContainer>
    )
  }

  const ListLifeAssure = () => {
    if (dataLifeAssured && dataLifeAssured.length > 0) {
      return dataLifeAssured.map((item, index) =>
        TableLifeAssured(item, item.riders || [], index)
      )
    }
    return (<View style={{ height: '100vh' }}></View>)
  }

  return (
    <Container loading={loading}>
      <ScrollView style={lifeASStyle.scrollView}>
        {ListLifeAssure()}
      </ScrollView>
    </Container>
  )
}
const lifeASStyle = StyleSheet.create({
  scrollView: {
    width: '100%',
    paddingTop: 24
  },
  header: {
    fontWeight: '700',
    paddingBottom: 20,
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16
  },
  sectionContainer: {
    marginVertical: 5,
    paddingVertical: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20
  },
  linkText: {
    color: '#5959FF',
    fontWeight: '500',
    textDecorationLine: 'underline'
  }
})