/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { NotificationData } from './notification-data'
export namespace NotificationService {
  export const NotificationTypeC = t.type({
    caseId: Maybe(t.string),
    clientId: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    policyNumber: Maybe(t.string),
    category: Maybe(t.string),
    transactionType: Maybe(t.string),
    transactionNameVi: Maybe(t.string),
    caseStatus: Maybe(t.string),
    message: Maybe(t.string),
    isAck: t.boolean,
    system: Maybe(t.string),
    createdDate: Maybe(t.string),
    messageEn: Maybe(t.string)
  })
  export const NotificationType = t.type({
    total: Maybe(t.number),
    start: Maybe(t.number),
    size: Maybe(t.number),
    order: Maybe(t.string),
    sort: Maybe(t.string),
    data: Maybe(t.array(NotificationTypeC)),
    fromDate: Maybe(t.string),
    toDate: Maybe(t.string)
  })

  export type NotificationInfoLog = t.TypeOf<typeof NotificationType>
  export type NotificationData = t.TypeOf<typeof NotificationTypeC>
  export const NotificationList = (data: NotificationData.notification) =>
    pipe(
      POApi.post('inbox-rest/api/notification/query')(NotificationType)(data),
      ZIO.map((response) => {
        return response
      })
    )
  export const TransactionNameList = (createdBy: string) => {
    return pipe(
      POApi.get(`inbox-rest/api/notification/user/${createdBy}`)(Maybe(t.array(t.string))),
      ZIO.map((res) => res)
    )
  }
}
