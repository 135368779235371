import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { assets } from '@pulseops/common'
import React from 'react'
import { Image, Text, TouchableOpacity } from 'react-native'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  }
})

export type ImageDialogProps = {
  onClose: (value: any) => void
  open: boolean
  selectedValue?: string
  selectedValueArray?: string[]
}

export const ImageDialog = (props: ImageDialogProps) => {
  const { dialog, dialogContent, dialogHeader } = useStyles()
  const { onClose, selectedValue, selectedValueArray, open } = props

  const handleClose = (props: ImageDialogProps) => {
    onClose(selectedValue)
  }

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center', marginLeft: 385 }}>Image viewer</Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose(null)
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        {selectedValue && (
          <Image
            style={{
              width: '100%',
              height: 500,
              resizeMode: 'contain'
            }}
            source={{ uri: selectedValue }}
          />
        )}
        {selectedValueArray &&
          selectedValueArray.map((x) => (
            <Image
              style={{
                width: '100%',
                height: 500,
                resizeMode: 'contain'
              }}
              source={{ uri: x }}
            />
          ))}
      </DialogContent>
    </Dialog>
  )
}
