import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { ProposalStatusCodeC } from './proposal-status-code'

export const StatusContractC = t.type({
  uuid: t.string,
  masterContractNo: t.string,
  policyYear: t.number,
  status: ProposalStatusCodeC,
  createdDate: DateFromISOString,
  createdBy: t.string
})

export type StatusContract = t.TypeOf<typeof StatusContractC>
