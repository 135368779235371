import React from 'react'
import { assets, Container, FieldList, Panel, PolicyInfoHeader, useMobile } from '@pulseops/common'
import { useTranslation } from 'react-i18next'

const menus = [
  {
    label: 'Policy number',
    icon: <assets.DocumentIcon />,
    value: '7045867'
  },
  {
    label: 'Policy owner',
    icon: <assets.OwnerIcon />,
    value: 'NGUYEN VAN A'
  }
]

export const CustomerInfo = (props: {
  data: {
    label: string
    value: string
  }[]
}) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()

  return (
    <Container>
      {isMobile && <PolicyInfoHeader menus={menus} />}
      {props.data.length > 0 && (
        <Panel title={t('TaskManagement:ContactInfo').toUpperCase()}>
          <FieldList dataSource={props.data} />
        </Panel>
      )}
    </Container>
  )
}
