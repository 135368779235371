import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Input } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { BasicSearchForm } from './basic-search-form'

interface Props {
  form: Omit<UseFormReturn<BasicSearchForm.Raw>, 'handleSubmit'>
}

export const FormSearchClaim: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation()
  const { control, formState } = form

  return (
    <>
      <View style={styles.row}>
        <View style={styles.col}>
          <Controller
            name="policyNumberClaim"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                title={t('ServiceInquiry:PolicyNumber')}
                value={value ?? undefined}
                maxLength={9}
                inputType="number"
                onChange={onChange}
                placeholder={t('ServiceInquiry:EnterPolicy')}
                errorMessage={formState.errors.policyNumberClaim?.message}
              />
            )}
          />
        </View>
        <View style={styles.col}>
          <Controller
            name="claimNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                title={t('ServiceInquiry:ClaimNumber')}
                value={value ?? undefined}
                maxLength={11}
                onChange={onChange}
                placeholder={t('ServiceInquiry:EnterClaimNumber')}
                errorMessage={formState.errors.claimNumber?.message}
              />
            )}
          />
        </View>
        <View style={styles.col}>
          <Controller
            name="idNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                title={t('ServiceInquiry:IDNumber')}
                value={value ?? undefined}
                maxLength={24}
                onChange={onChange}
                placeholder={t('ServiceInquiry:EnterIDNumber')}
                errorMessage={formState.errors.idNumber?.message}
              />
            )}
          />
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  }
} as const)
