import { SelectOption } from "@pulseops/common"
import { OBCallProgram, OBCallProgramTypeCode } from "../../ob-service"
export namespace TaskConst {

  export const callProgramList: SelectOption[]  = [
    { label: OBCallProgram.SQCB as string, value: OBCallProgramTypeCode.SQCB },
    { label: OBCallProgram.RMD as string, value: OBCallProgramTypeCode.RMD },
    // { label: OBCallProgram.OPL as string, value: 'OUTSTANDING_PREMIUM_LOAN' },
    // { label: OBCallProgram.PO as string, value: OBCallProgramTypeCode.PO },
    { label: OBCallProgram.CARE1 as string, value: OBCallProgramTypeCode.CARE1 },
    { label: OBCallProgram.CARE2 as string, value: OBCallProgramTypeCode.CARE2 },
    // { label: OBCallProgram.M3 as string, value: 'MATURITY_3M' },
    // { label: OBCallProgram.M12 as string, value: 'MATURITY_12M' },
    // { label: OBCallProgram.CMB as string, value: 'CALL_ME_BACK' },
    { label: OBCallProgram.MA1 as string, value: OBCallProgramTypeCode.MA1 },
    { label: OBCallProgram.MA2 as string, value: OBCallProgramTypeCode.MA2 },
    { label: OBCallProgram.CMB1 as string, value: OBCallProgramTypeCode.CMB1 },
    { label: OBCallProgram.CMB2 as string, value: OBCallProgramTypeCode.CMB2 },
    { label: OBCallProgram.IDC as string, value: OBCallProgramTypeCode.IDC },
    { label: OBCallProgram.ADHOCCCE as string, value: OBCallProgramTypeCode.ADHOCCCE },
    { label: OBCallProgram.ADHOCCS as string, value: OBCallProgramTypeCode.ADHOCCS },
    { label: OBCallProgram.RMDBAU as string, value: OBCallProgramTypeCode.RMDBAU },
    { label: OBCallProgram.WCC as string, value: OBCallProgramTypeCode.WCC },
    { label: OBCallProgram.APL as string, value: OBCallProgramTypeCode.APL },
    { label: OBCallProgram.LAPSED as string, value: OBCallProgramTypeCode.LAPSED },
  ]

  export const VIPList: Array<{ code: string, descriptionVN: string, descriptionEN: string }> = [
    {
      code: 'K',
      descriptionEN: 'Diamond',
      descriptionVN: 'Kim cương'
    },
    {
      code: 'P',
      descriptionEN: 'Platinum',
      descriptionVN: 'Bạch kim'
    },
    {
      code: 'V',
      descriptionEN: 'Gold',
      descriptionVN: 'Vàng'
    },
    {
      code: 'C',
      descriptionEN: 'Basic',
      descriptionVN: 'Cơ bản'
    }
  ]
  export const agencyList: SelectOption[] = [
    { label: 'Banca', value: 'BANCA' },
    { label: 'Agency', value: 'AGENCY' },
    { label: 'Pruventure', value: 'PRU_VENTURE' },
  ]

  export const caseStatusList: SelectOption[] = [
    { label: 'In-progress', value: 'IN_PROGRESS' },
    { label: 'Pending', value: 'PENDING' },
    // { label: 'Completed', value: 'COMPLETED' },
    // { label: 'End', value: 'ENDED' }
  ]
}