import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ZIO } from '@mxt/zio'
import { POApi } from '../POApi'
import { Task } from '@mxt/zio'
import { BeneficiaryDesignationData, CancelFromInception, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export const getFunction = (code: string) => (code[0] === 'U' ? 'T510' : code[0] === 'V' ? 'T619' : 'TOTAL')

export namespace CancelFromInceptionService {
  const getMedicalFee = (policyNum: string): Task<number> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/total-medical-fee`)(
        t.type(
          {
            body: t.type({
              attributesExt: t.type({
                TOTAL_MEDICAL_FEE: t.number
              })
            })
          },
          'Medical Fee'
        )
      ),
      ZIO.map((x) => x.body.attributesExt.TOTAL_MEDICAL_FEE)
    )
  export const getDetail = (policyNum: string): Task<CancelFromInception.Detail> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type(
          {
            body: t.type({
              policyNo: t.string,
              billToDate: Maybe(t.string),
              clientDespatchAddress: Maybe(t.string),
              contractDate: Maybe(t.string),
              premiumStatus: Maybe(t.string),
              productCode: Maybe(t.string),
              totalPremium: Maybe(t.number),
              owners: t.type({
                id: t.string
              })
            })
          },
          'Policy'
        )
      ),
      ZIO.flatMap((policy) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getAccountBalance(policyNum, 'TOTAL', [
              { sacsCode: 'LP', sacsType: 'S' },
              { sacsCode: 'LP', sacsType: 'U' }
            ]),
            SubmissionService.getAccountBalance(policyNum, 'TOTAL', [{ sacsCode: 'LN', sacsType: 'VP' }]),
            SubmissionService.getAccountBalance(policyNum, getFunction(policy.body.productCode || '-'), [
              { sacsCode: 'LP', sacsType: 'PS' }
            ]),

            SubmissionService.getAccountBalance(policyNum, 'B529', [{ sacsCode: 'LP', sacsType: 'S' }]),

            SubmissionService.getFileNetAccess(policyNum),
            getMedicalFee(policyNum)
          ),
          ZIO.map(([suspenseAmount, advancePremium, pwAmount, topUpAmount, verification, medicalFee]) => ({
            installmentPremium: policy.body.totalPremium ?? 0,
            advancePremium: advancePremium.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0,
            suspenseAmount: suspenseAmount.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0,
            medicalFee,
            pwAmount: pwAmount.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0,
            topUpAmount:
              topUpAmount.body.accountGroups?.reduce((sum, x) => {
                return x.sacsCode === 'LP' && x.sacsType === 'S' ? sum + x.amount : sum
              }, 0) || 0,
            hasFileNet: verification
          }))
        )
      )
    )
  export const getDataAccess = (policyNum: string): Task<CancelFromInception.AccessDataCheck> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map(
        (policy): CancelFromInception.AccessDataCheck => ({
          contractStatus: policy.body.status ?? '',
          coverageCode: policy.body.basicCode ?? '',
          proposalReceivedDate: String(policy.body.proposal?.proposalReceivedDate),
          riskCommenceDate: policy.body.riskCommenceDate ?? ''
        })
      )
    )

  export const submit =
    (
      policyNum: string,
      data: CancelFromInception.DataSubmit,
      documents: BeneficiaryDesignationData.BeneficiaryDocumentFile[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/cfi`,
        { body: data },
        policyNum,
        requestauth,
        documents
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/cfi`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )
}
