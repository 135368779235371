export namespace PolicyCertificateData {
  export interface DetailData {
    clientId: string
  }
  export interface SubmitData {
    clientId: string
    office: string
    reason: string
  }
}
