import { Controller, ControllerFieldState, ControllerRenderProps, UseFormStateReturn, useForm } from "react-hook-form"
import { OBTaskFilterForm } from "./OBTaskFilterForm"
import { Modal, ScrollView, TouchableOpacity, View, StyleSheet, useWindowDimensions, Text } from "react-native"
import { CanView, DatePicker, ErrorHandling, Input, RoundedButton, Select, SelectOption, SelectSearch, SubTaskType, assets, useMobile } from "@pulseops/common"
import { useTranslation } from "react-i18next"
import { SearchFields } from "../../ob-service-inquiry"
import React from 'react'
import { TaskConst } from "./OBTaskConst"
import { OBFormat } from "../../ob-common"
import moment from "moment"
import { pipe } from "fp-ts/lib/function"
import { OBTaskService } from "../../ob-service"
import { ZIO } from "@mxt/zio"
import { TaskManagementContext } from "../OBTaskManagementScreen"

interface Props {
  visible: boolean
  groupName: string
  categroryName: string
  onPressClose?: () => void
  onPressSubmit: (data: OBTaskFilterForm.FilterForm) => void
  onPressClear: (data: OBTaskFilterForm.FilterForm) => void
  basket: string
  isResetField: boolean
  // taskGroupSelected: TaskCount | undefined
}

export const OBTaskFilterModal = ({
  groupName,
  categroryName,
  visible,
  onPressClose,
  onPressSubmit,
  onPressClear,
  basket,
  isResetField
  //   taskGroupSelected
}: Props) => {
  const { getValues, control, handleSubmit, reset, watch, trigger, setValue } = useForm<OBTaskFilterForm.FilterForm>({
    defaultValues: {
      callingProgram: { label: 'ALL', value: '' },
      duplicatePO: { label: 'ALL', value: 'all' },
      vipRank: { label: 'ALL', value: '' },
      agency: { label: 'ALL', value: '' },
      caseStatus: { label: 'ALL', value: '' },
    },
    mode: 'onChange'
  })
  const { isWide, isMobile } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t, i18n } = useTranslation()
  const { managerList, staffList, callProgramList} = React.useContext(TaskManagementContext)

  React.useEffect(() => {
    if (isResetField) onReset()
  }, [isResetField])

  // const { managerList, staffList, callProgramList } = pipe(
  //   ZIO.zipPar(
  //     OBTaskService.getManagerNameArr(),
  //     OBTaskService.getStaffList(),
  //     OBTaskService.getCallProgramList()
  //   ),
  //   ZIO.map(([managerList, staffList, callProgramList]) => {
  //     return {
  //       managerList,
  //       staffList, 
  //       callProgramList
  //     }
  //   }),
  //   ErrorHandling.runDidMount()
  // ) || {
  //   managerList: [],
  //   staffList: [],
  //   callProgramList: []
  // }

  let searchFields: OBTaskFilterForm.FieldSearchFilter[] = [
    {
      formName: 'policyNumber',
      type: 'INPUT',
      title: t('ServiceInquiry:PolicyNumber'),
      inputType: 'text',
      // maxLength: 8,
      rules: {
        // minLength: { value: 8, message: t('message:OB0024', { field: t('ServiceInquiry:PolicyNumber'), k: 8 }) },
        validate: (value: string) => {
          if(value) {
            const dataList = value.split(';')
            for(let i = 0; i < dataList.length; i ++){
              if(dataList[i].length !== 8 || !dataList[i].match(/^[0-9]+$/))
                return t('message:OB0092')
            }
            if(dataList.length > 50) {
              return t('message:OB0097')
            }
          }
          return true
        }
      }
    },
    {
      formName: 'callingProgram',
      type: 'SELECT',
      title: t('Outbound:OBTaskManagement:CallingProgram'),
      option: [
        ...callProgramList.map((item)=> ({label: item.code, value: item.transactionTypeWF})),
        { label: 'ALL', value: '' },
      ]
    },
    {
      formName: 'clientNumber',
      type: 'INPUT',
      title: t('ServiceInquiry:ClientNumber'),
      inputType: 'number',
      maxLength: 8,
    },
    {
      formName: 'clientName',
      type: 'INPUT',
      title: t('Outbound:OBTaskManagement:ClientName'),
      inputType: 'text',
      maxLength: 50,
    },
    {
      formName: 'vipRank',
      type: 'SELECT',
      title: t('Outbound:OBTaskManagement:VIPRank'),
      option: [
        ...TaskConst.VIPList.map((vipItem) => {
          return {
            label: i18n.language === 'en' ? vipItem.descriptionEN : vipItem.descriptionVN,
            value: vipItem.code
          }
        }),
        { label: 'ALL', value: '' }
      ]
    },
    {
      formName: 'agency',
      type: 'SELECT',
      title: t('Outbound:OBServiceInquiry:Agency'),
      option: [
        ...TaskConst.agencyList,
        { label: 'ALL', value: '' }
      ]
    },
    {
      formNameDateFrom: 'appointmentFromDate',
      formNameDateTo: 'appointmentToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBServiceInquiry:AppointmentDate'),
      rules: {
        validate: (_: any) => {
          const appointmentFromDate = watch('appointmentFromDate')
          const appointmentToDate = watch('appointmentToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(appointmentToDate as Date, appointmentFromDate as Date)
          if (!appointmentFromDate && !appointmentToDate) {
            return true
          } else if ((appointmentFromDate && !appointmentToDate) || (!appointmentFromDate && appointmentToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBServiceInquiry:AppointmentDate') })}`
          }
          else if (moment(appointmentFromDate).valueOf() > moment(appointmentToDate).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'createdFromDate',
      formNameDateTo: 'createdToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBServiceInquiry:CreatedDate'),
      rules: {
        validate: (_: any) => {
          const createdFromDate = watch('createdFromDate')
          const createdToDate = watch('createdToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(createdToDate as Date, createdFromDate as Date)
          if (!createdFromDate && !createdToDate) {
            return true
          } else if ((createdFromDate && !createdToDate) || (!createdFromDate && createdToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBServiceInquiry:CreatedDate') })}`
          }
          else if (moment(createdFromDate).valueOf() > moment(createdToDate).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else if (moment(createdFromDate).valueOf() > moment().valueOf() || moment(createdToDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'firstIssueFromDate',
      formNameDateTo: 'firstIssueToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBTaskManagement:FirstIssueDate'),
      rules: {
        validate: (_: any) => {
          const firstIssueFromDate = watch('firstIssueFromDate')
          const firstIssueToDate = watch('firstIssueToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(firstIssueToDate as Date, firstIssueFromDate as Date)
          if (!firstIssueFromDate && !firstIssueToDate) {
            return true
          } else if ((firstIssueFromDate && !firstIssueToDate) || (!firstIssueFromDate && firstIssueToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBTaskManagement:FirstIssueDate') })}`
          }
          else if (moment(moment(firstIssueFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(firstIssueToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else if (moment(firstIssueFromDate).valueOf() > moment().valueOf() || moment(firstIssueToDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'lastIssueFromDate',
      formNameDateTo: 'lastIssueToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBTaskManagement:LastIssueDate'),
      rules: {
        validate: (_: any) => {
          const lastIssueFromDate = watch('lastIssueFromDate')
          const lastIssueToDate = watch('lastIssueToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(lastIssueToDate as Date, lastIssueFromDate as Date)
          if (!lastIssueFromDate && !lastIssueToDate) {
            return true
          } else if ((lastIssueFromDate && !lastIssueToDate) || (!lastIssueFromDate && lastIssueToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBTaskManagement:LastIssueDate') })}`
          }
          else if (moment(moment(lastIssueFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(lastIssueToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else if (moment(lastIssueFromDate).valueOf() > moment().valueOf() || moment(lastIssueToDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    // {
    //   formName: 'aging',
    //   type: 'INPUT',
    //   title: t('Outbound:OBTaskManagement:TotalAging'),
    //   inputType: 'number'
    // },
    {
      formName: 'numberOfAssignment',
      type: 'INPUT',
      title: t('Outbound:OBTaskManagement:ExecutionTime'),
      inputType: 'number'
    },
    // {
    //   formName: 'currentAssignee',
    //   type: 'SEARCHSELECT',
    //   title: t('Outbound:OBTaskManagement:CurrentAssignee'),
    //   option: [
    //     ...staffList.map((item) => ({ label: item, value: item }))
    //   ]
    // },
    {
      formName: 'leader',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:Leader'),
      option: [
        ...managerList.map((item) => ({ label: item, value: item }))
      ]
    },
    // {
    //   formName: 'caseStatus',
    //   type: 'SELECT',
    //   title: t('Outbound:OBServiceInquiry:CaseStatus'),
    //   option: [
    //     ...TaskConst.caseStatusList,
    //     { label: 'ALL', value: '' }
    //   ]
    // },
    // {
    //   formName: 'duplicatePO',
    //   type: 'SELECT',
    //   title: t('Outbound:OBTaskManagement:DuplicatePO'),
    //   option: [
    //     {
    //       label: 'True',
    //       value: 'true'
    //     },
    //     {
    //       label: 'False',
    //       value: 'false'
    //     },
    //     {
    //       label: 'All',
    //       value: 'all'
    //     }
    //   ]
    // },
    // {
    //   formName: 'caseId',
    //   type: 'INPUT',
    //   title: t('Outbound:OBServiceInquiry:CaseID'),
    //   inputType: 'text',
    // }
  ]

  if ([SubTaskType.QC,SubTaskType.REQC].includes(basket as SubTaskType)) {
    searchFields.push({
      formNameDateFrom: 'submitFromDate',
      formNameDateTo: 'submitToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBTaskManagement:SubmitDate'),
      rules: {
        validate: (_: any) => {
          const submitFromDate = watch('submitFromDate')
          const submitToDate = watch('submitToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(submitToDate as Date, submitFromDate as Date)
          if (!submitFromDate || !submitToDate) {
            return true
          }
          else if (moment(submitFromDate).valueOf() > moment(submitToDate).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          } else if (moment(submitToDate).valueOf() > moment().valueOf() || moment(submitFromDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    })
    searchFields.push({
      formName: 'lastAssignee',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBTaskManagement:LastAssignee'),
      option: [
        ...staffList.map((item) => ({ label: item, value: item }))
      ]
    })
    searchFields.push({
      formName: 'currentAssignee',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBTaskManagement:QCAssignee'),
      option: [
        ...staffList.map((item) => ({ label: item, value: item }))
      ]
    })
    searchFields.push({
      formName: 'caseId',
      type: 'INPUT',
      title: t('Outbound:OBServiceInquiry:CaseID'),
      inputType: 'text',
    })
  } else {
    basket === SubTaskType.Suspend && searchFields.push({
      formName: 'lastAssignee',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBTaskManagement:LastAssignee'),
      option: [
        ...staffList.map((item) => ({ label: item, value: item }))
      ]
    })
    searchFields = [...searchFields,
    {
      formName: 'totalAgingDays',
      type: 'INPUT',
      title: t('Outbound:OBTaskManagement:TotalAging'),
      inputType: 'number'
    },
    {
      formName: 'currentAssignee',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBTaskManagement:CurrentAssignee'),
      option: [
        ...staffList.map((item) => ({ label: item, value: item }))
      ]
    },
    {
      formName: 'duplicatePO',
      type: 'SELECT',
      title: t('Outbound:OBTaskManagement:DuplicatePO'),
      option: [
        {
          label: 'True',
          value: 'true'
        },
        {
          label: 'False',
          value: 'false'
        },
        {
          label: 'All',
          value: 'all'
        }
      ]
    },
    {
      formName: 'caseId',
      type: 'INPUT',
      title: t('Outbound:OBServiceInquiry:CaseID'),
      inputType: 'text',
    }
    ]
  }



  const searchFieldsSuspend = searchFields.filter((item) => item.type === 'DATE_FROM_TO' || (item.formName !== 'leader'))
  const searchFieldFilter = basket === 'SUSPENDED' ? searchFieldsSuspend : searchFields

  const onReset = () => {
    const defaultValues = {
      policyNumber: '',
      caseId: '',
      clientNumber: '',
      clientName: '',
      aging: '',
      totalAgingDays: '',
      numberOfAssignment: '',
      currentAssignee: { label: '', value: '' },
      leader: { label: '', value: '' },
      duplicatePO: { label: 'ALL', value: 'all' },
      callingProgram: { label: 'ALL', value: '' },
      vipRank: { label: 'ALL', value: '' },
      agency: { label: 'ALL', value: '' },
      caseStatus: { label: 'ALL', value: '' },
      createdFromDate: null,
      createdToDate: null,
      appointmentFromDate: null,
      appointmentToDate: null,
      lastAssignee: { label: '', value: '' },
      submitFromDate: null,
      submitToDate: null,
      firstIssueFromDate: null,
      firstIssueToDate: null,
      lastIssueFromDate: null,
      lastIssueToDate: null,
    }
    reset(defaultValues)
    setValue('createdFromDate', null)
    setValue('createdToDate', null)
    setValue('appointmentFromDate', null)
    setValue('appointmentToDate', null)
    onPressClear(defaultValues)
  }

  const getFieldSearch = ({
    field,
    controller,
  }: {
    field: OBTaskFilterForm.FieldSearchFilter
    controller: {
      field: ControllerRenderProps<OBTaskFilterForm.FilterForm, OBTaskFilterForm.TypeFilterField>
      fieldState: ControllerFieldState,
      formState: UseFormStateReturn<OBTaskFilterForm.FilterForm>
    },
  }) => {
    switch (field.type) {
      case 'INPUT':
        return (
          <Input
            title={field.title}
            value={controller.field.value as string | undefined}
            maxLength={field.maxLength}
            inputType={field.inputType as SearchFields.InputType}
            onChange={controller.field.onChange}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      case 'DATE':
        return (
          <DatePicker
            required={field.required}
            label={field.title}
            onChange={controller.field.onChange}
            value={controller.field.value as Date | undefined | null}
            maxDate={field.maxDate}
            maxDateMessage={field.maxDateMessage}
            minDate={field.minDate}
            minDateMessage={field.minDateMessage}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      case 'SELECT':
        return (
          <Select
            label={field.title}
            options={field.option}
            onChange={controller.field.onChange}
            value={controller.field.value as SelectOption | undefined | null}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      case 'SEARCHSELECT':
        return (
          <SelectSearch
            label={field.title}
            options={field.option}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            onChange={controller.field.onChange}
            value={controller.field.value as SelectOption | undefined | null}
            errorMessage={controller.fieldState.error?.message}
          />
        )
      default:
        return <></>
    }
  }

  const onSubmit = async () => {
    const isValidFilterForm = await trigger()
    console.log('isValidFilterForm:' + isValidFilterForm)
    if (isValidFilterForm) {
      const filterData = getValues()
      onPressSubmit(filterData)
    }

  }

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          justifyContent: isWide ? 'center' : 'flex-end',
          alignItems: 'center'
        }}
      >
        {/* Backdrop handle touch outsidem modal event*/}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onPressClose}
            style={{
              width: width,
              height: height,
              position: 'absolute'
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              height: height * 0.85,
              width: isWide ? 720 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingHorizontal: 20 }}>
            <TouchableOpacity onPress={onPressClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          {/* Filter Inputs */}
          <View style={{ marginTop: 20, flex: 1, paddingHorizontal: 20 }}>
            <ScrollView style={{ paddingBottom: isWide ? 0 : 16 }}>
              <View style={styles.row}>
                {searchFieldFilter.map((i) => i.type !== 'DATE_FROM_TO' ? (
                  <View style={styles.col}>
                    <Controller
                      name={i.formName as OBTaskFilterForm.TypeFilterField}
                      control={control}
                      rules={i.rules}
                      render={(controller) => getFieldSearch({ field: i, controller })}
                    />
                  </View>
                ) :
                  (
                    <View style={styles.col2}>
                      <Text style={[styles.label, { fontSize: isMobile ? 13 : 15 }]}>
                        {i.title}
                      </Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Controller
                          name={i.formNameDateFrom as OBTaskFilterForm.TypeFilterField}
                          control={control}
                          render={({ field }) => (
                            <DatePicker onChange={field.onChange} onBlur={field.onBlur} value={field.value as Date | undefined ?? null} />
                          )}
                        />
                        <Text style={{ color: '#000000', fontSize: 15, marginHorizontal: 10 }}>~</Text>
                        <Controller
                          name={i.formNameDateTo as OBTaskFilterForm.TypeFilterField}
                          control={control}
                          rules={i.rules}
                          render={({ field, fieldState: { error } }) => (
                            <DatePicker onChange={field.onChange} onBlur={field.onBlur} value={field.value as Date | undefined ?? null} errorMessage={error?.message} />
                          )}
                        />
                      </View>
                    </View>
                  )
                )}
              </View>
            </ScrollView>
          </View>
          {/* Apply Button */}

          <View
            style={[
              styles.btnGroup,
              { paddingTop: isWide ? 40 : 24 },
              isMobile
                ? {
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,

                  elevation: 5
                }
                : {}
            ]}
          >
            <RoundedButton
              showBorder
              activeOpacity={0.7}
              text={t('TaskManagement:Clear')}
              textStyle={styles.btnTitle}
              style={{ height: 39, marginEnd: 15 }}
              textColorDisable={'#B0B0B0'}
              textColorEnable={'#ED1B2E'}
              borderColorDisable={'#B0B0B0'}
              borderColorEnable={'#ED1B2E'}
              onPress={onReset}
            />
            <RoundedButton
              filled
              activeOpacity={0.7}
              text={t('TaskManagement:Apply')}
              textStyle={styles.btnTitle}
              style={{ height: 39 }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={onSubmit}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#4F4F4F',
    fontWeight: '600',
    fontSize: 20,
    marginEnd: 16
  },

  breadcumbCotnainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },

  breadcumbItem: {
    fontSize: 14,
    color: '#828282'
  },

  arrowIcon: {
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 25
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },

  startItem: {
    width: 250
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 2
  },

  error: {
    color: 'red',
    fontSize: 12
  },

  col: {
    width: '100%',
    maxWidth: '50%',
    marginBottom: 20,
    paddingHorizontal: 16
  },

  col2: {
    width: '100%',
    marginBottom: 20,
    paddingHorizontal: 16
  }
})
