import { GoAbroadModel } from '../GoAbroad'
import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface GoAbroad {
  tag: TransactionType.GO_ABROAD
  clientName: string
  goAbroadClients: GoAbroadModel.AbroadDetail[]
  premiumPayer?: GoAbroadModel.PremiumPayer | null
  source: SourceType
}

export const GoAbroad = (detail: api.GoAbroad, poName: string): GoAbroad => {
  const payload = detail.payload.body
  const preniumPayer = payload?.premiumPayer

  return {
    tag: TransactionType.GO_ABROAD,
    source: detail.source,
    clientName: poName ?? '',
    goAbroadClients: payload.goAbroadClients,
    premiumPayer: !!preniumPayer
      ? ({
          ...preniumPayer,
          addressWard: preniumPayer?.addressWard ?? '-',
          addressDistrict: preniumPayer?.addressDistrict ?? '-'
        } as GoAbroadModel.PremiumPayer)
      : null
  }
}
