import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

export const ZaloRecommendation = t.type({
  transactionType: t.literal(TransactionType.ZALO_RECOMMENDATION),
  payload: t.type({
    body: t.type({
      message: Maybe(t.string)
    })
  })
})
export type ZaloRecommendation = t.TypeOf<typeof ZaloRecommendation>
