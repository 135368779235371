import { AppContext, ModalComponent, useMobile, Checkbox } from '@pulseops/common'
import React, { useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, TextStyle, Text } from 'react-native'
import { SC } from './common'
import { IDoNotCallFrom } from './do-not-call-screen'

export type ConfirmModalProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  form: UseFormReturn<IDoNotCallFrom>
  onSubmit?: () => void
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const { onClose, open } = props
  const { isWide, isMobile } = useMobile()
  const { isGlobalLoadingVisible } = useContext(AppContext.AppContextInstance)

  const { t } = useTranslation()

  const { checkedCall, checkedEmail, checkedSMS, officeMobileCode, emailAddress, phoneNumber, customerName } =
    props.form.getValues()
  const handleClose = () => {
    onClose()
    // props.form.reset()
  }

  const hdSubmit = () => {
    // onClose()
    props.onSubmit?.()
  }

  return (
    <ModalComponent
      title={t('utilities:DoNotCallCap')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('common:Confirm'),
          type: 'filled',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39 },
          action: () => hdSubmit()
        }
      ]}
      modalWidth={846}
    >
      <View style={{ flex: 1 }}>
        <View style={styles.searchContainer}>
          <View style={[styles.row, { paddingBottom: 15 }]}>
            <View style={isMobile ? styles.col1 : styles.col}>
              <View>
                <View>
                  <SC.TitleText>{t('utilities:CustomerName')}</SC.TitleText>
                  <Text>{customerName ?? '-'}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.row, { paddingBottom: 15 }]}>
            <View style={isMobile ? styles.col1 : styles.col2}>
              <View>
                <View>
                  <SC.TitleText>{t('utilities:PhoneNumber')}</SC.TitleText>
                  <Text>{`${officeMobileCode?.label ?? ''}  ${phoneNumber ?? ''}`}</Text>
                </View>
              </View>
            </View>
            <View style={isMobile ? styles.col1 : styles.col2}>
              <View style={{ flex: 1, paddingTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                  <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Checkbox
                        onChange={(checked) => {
                          checked
                        }}
                        // onBlur={onBlur}
                        value={checkedSMS ?? false}
                        disabled={true}
                        checkBoxStyle={styles.tableCheckBox}
                      />
                      <Text>SMS</Text>
                    </View>
                  </View>
                  <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Checkbox
                        onChange={(checked) => {
                          checked
                        }}
                        // onBlur={onBlur}
                        value={checkedCall ?? false}
                        disabled={true}
                        checkBoxStyle={styles.tableCheckBox}
                      />
                      <Text>Call</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.row, { paddingBottom: 15 }]}>
            <View style={isMobile ? styles.col1 : styles.col2}>
              <View>
                <View>
                  <SC.TitleText>{t('utilities:EmailAddres')}</SC.TitleText>
                  <Text>{emailAddress ?? '-'}</Text>
                </View>
              </View>
            </View>
            <View style={isMobile ? styles.col1 : styles.col2}>
              <View style={{ flex: 1, paddingTop: 10 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox
                    onChange={(checked) => {
                      checked
                    }}
                    // onBlur={onBlur}
                    value={checkedEmail ?? false}
                    disabled={true}
                    checkBoxStyle={styles.tableCheckBox}
                  />
                  <Text>Email</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  headingTitle: {
    marginHorizontal: 30,
    marginVertical: 30,
    marginBottom: 10
  },

  searchContainer: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 20,
    marginVertical: 15,
    paddingBottom: 15,
    paddingTop: 24
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '100%',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  col2: {
    width: '50%',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 5,
    paddingLeft: 10
  },
  col1: {
    width: '50%',
    marginBottom: 16,
    paddingHorizontal: 5
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 6,
    marginHorizontal: 36
  }
} as const)
