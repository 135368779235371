import { Throwable, ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { OBRelatedDocumentInfo } from '@pulseops/outbound'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'

export const FundData = t.type({
  fundCode: Maybe(t.string),
  unitBalanceAmt: Maybe(t.number),
  estFundAmt: Maybe(t.number),
  offerPriceAmt: Maybe(t.number),
  latestUpdateDate: Maybe(t.string)
})

export const BensData = t.type({
  fullName: Maybe(t.string),
  dob: Maybe(t.string),
  relationshipCode: Maybe(t.string),
  genderCode: Maybe(t.string),
  percentage: Maybe(t.number)
})

export const PolicyData = t.type({
  policyNumber: Maybe(t.string),
  statusCode: Maybe(t.string),
  premiumStatusCode: Maybe(t.string),
  basicCode: Maybe(t.string),
  sumAssuredAmt: Maybe(t.number), // Bigdecimal
  installmentPremiumAmt: Maybe(t.number), // Bigdecimal
  firstIssueDate: Maybe(t.string),
  issueDate: Maybe(t.string),
  riskCommenceDate: Maybe(t.string),
  riskCessationDate: Maybe(t.string),
  paidToDateBasic: Maybe(t.string),
  paidToDateAdvance: Maybe(t.string),
  frequencyCode: Maybe(t.string), // BO LAS
  mainLifeAssuredNumber: Maybe(t.string),
  mainLifeAssuredFullName: Maybe(t.string),
  dispatchAddress: Maybe(t.string), //'Street' - 'Ward' - 'District' - 'City' - 'Country'
  daCountryCode: Maybe(t.string),
  consent: t.type({
    isOTP: t.boolean,
    isSms: t.boolean,
    isEmail: t.boolean,
    isEAL: t.boolean,
    isZalo: t.boolean
  }),
  funds: Nullable(t.array(FundData)),
  bens: Nullable(t.array(BensData)),
  ptDateAdvanceDLTrigger: Maybe(t.string)
})
export type PolicyData = t.TypeOf<typeof PolicyData>

export const PolicyOwnerData = t.type({
  idNumber: Maybe(t.string),
  email: Maybe(t.string),
  vipCode: Maybe(t.string),
  genderCode: Maybe(t.string),
  occupationCode: Maybe(t.string),
  incomeAmt: Maybe(t.number),
  dob: Maybe(t.string),
  address: Maybe(t.string),
  homePhone: Maybe(t.string),
  companyPhone: Maybe(t.string),
  mobilePhoneLatest: Maybe(t.string),
  mobilePhoneAtTriggerTime: Maybe(t.string),
  accountPruOnline: Maybe(t.string),
  accountStatusCode: Maybe(t.string),
  countryCode: Maybe(t.string),
  homePhoneAtTriggerTime: Maybe(t.string),
  companyPhoneAtTriggerTime: Maybe(t.string)
})
export type PolicyOwnerData = t.TypeOf<typeof PolicyOwnerData>

export const LifeAssureData = Nullable(
  t.array(
    t.type({
      lifeNo: Maybe(t.string),
      clientNumber: Maybe(t.string),
      clientFullName: Maybe(t.string),
      genderCode: Maybe(t.string),
      dob: Maybe(t.string),
      idNumber: Maybe(t.string),
      riders: Nullable(
        t.array(
          t.type({
            coverageCode: Maybe(t.string),
            statusCode: Maybe(t.string),
            sumAssuredAmt: Maybe(t.number),
            installmentPremiumAmy: Maybe(t.number),
            paidToDate: Maybe(t.string),
            riskCessationDate: Maybe(t.string),
            premCessDate: Maybe(t.string)
          })
        )
      )
    })
  )
)
export type LifeAssureData = t.TypeOf<typeof LifeAssureData>

export const AgentData = t.type({
  officeCode: Maybe(t.string),
  officeName: Maybe(t.string),
  gaCode: Maybe(t.string),
  gaName: Maybe(t.string),
  staffs: Nullable(
    t.array(
      t.type({
        role: Maybe(t.string),
        code: Maybe(t.string),
        fullName: Maybe(t.string),
        mobilePhone: Maybe(t.string),
        terminateDate: Maybe(t.string)
      })
    )
  ),
  oldAgents: Nullable(
    t.array(
      t.type({
        code: Maybe(t.string),
        fullName: Maybe(t.string),
        mobileCode: Maybe(t.string),
        mobilePhone: Maybe(t.string),
        fromDate: Maybe(t.string),
        toDate: Maybe(t.string)
      })
    )
  )
})
export type AgentData = t.TypeOf<typeof AgentData>

export const SpecialInfoData = t.type({
  categoryInsurance: Maybe(t.string),
  policyNumber: Maybe(t.string),
  customerReceivedDate: Maybe(t.string),
  documents: t.array(
    t.type({
      url: t.string,
      metaData: t.type({
        docid: t.string,
        maindoc: Maybe(t.string),
        subdoc: Maybe(t.string),
        batchno: Maybe(t.string)
      })
    })
  ),
  destOffice: Maybe(t.string),
  isOnlineCounseling: Maybe(t.boolean),
  isRequestCancelCFI: Maybe(t.boolean),
  isLetterContractHandover: Maybe(t.boolean),
  isSurveyFundsPruQuote: Maybe(t.boolean),
  isEstimatedTimePayFees: Maybe(t.boolean),
  isInsuranceClaimFile: Maybe(t.boolean),
  isInsuranceCert: Maybe(t.boolean)
})
export type SpecialInfoData = t.TypeOf<typeof SpecialInfoData>

export const NotificationData = t.type({
  isSpecialPolicy: Maybe(t.boolean),
  contactIndicator: Maybe(t.string),
  customerBehavior: Maybe(t.string)
})

export const ProductInfo = t.type({
  productCode: t.string,
  productNameVN: t.string,
  productNameEN: t.string,
  coverageName: t.string,
  coverageNameVN: t.string,
  coverageCode: t.string,
  group: t.string,
  type: t.string
})

export type NotificationData = t.TypeOf<typeof NotificationData>
export type ProductInfo = t.TypeOf<typeof ProductInfo>

export const PromotionInfo = t.type({
  policyNumber: t.string,
  havePromotion: t.boolean,
  promotions: Maybe(
    t.array(
      t.type({
        code: t.string,
        name: t.string,
        type: t.string,
        description: t.string,
        fromDate: t.string,
        toDate: t.string,
        percent: Maybe(t.string),
        amount: Maybe(t.string),
        maxAmount: Maybe(t.string),
        reinAmount: Maybe(t.string)
      })
    )
  )
})
export type PromotionInfo = t.TypeOf<typeof PromotionInfo>

export namespace OBGeneralService {
  export const getPolicyInfo = (policyNumber: string, caseId?: string) =>
    pipe(
      POApi.get(
        caseId ? `cs-api/outbound/policy/${policyNumber}?caseId=${caseId}` : `cs-api/outbound/policy/${policyNumber}`
      )(t.type({ data: PolicyData })),
      ZIO.map((policy) => policy.data)
    )

  export const getPolicyOwner = (policyNumber: string, caseId?: string) =>
    pipe(
      POApi.get(`cs-api/outbound/policy/${policyNumber}/owner?caseId=${caseId}`)(t.type({ data: PolicyOwnerData })),
      ZIO.map((policyOwner) => policyOwner.data)
    )

  export const getLifeAssured = (policyNumber: string) =>
    pipe(
      POApi.get(`cs-api/outbound/policy/${policyNumber}/life-assured`)(t.type({ data: LifeAssureData })),
      ZIO.map((lifeAssured) => lifeAssured.data)
    )

  export const getAgent = (policyNumber: string) =>
    pipe(
      POApi.get(`cs-api/outbound/policy/${policyNumber}/agent`)(t.type({ data: AgentData })),
      ZIO.map((agent) => agent.data)
    )

  export const getNotification = (policyNumber: string) =>
    pipe(
      POApi.get(`cs-api/outbound/policy/${policyNumber}/notification`)(t.type({ data: Maybe(NotificationData) })),
      ZIO.map((notification) => notification)
    )

  export const getSpecialInfo = (policyNumber: string, caseId: string) =>
    pipe(
      POApi.post(`cs-api/outbound/policy/special`)(t.type({ data: SpecialInfoData }))({
        policyNumber: policyNumber,
        caseId: caseId
      }),
      ZIO.map((special) => special.data)
    )

  export const getDocByComponent = (componentCode: string) =>
    pipe(
      POApi.get(`cs-api/outbound/policy/component-document?componentCode=${componentCode}`)(
        t.type({ data: Maybe(t.array(OBRelatedDocumentInfo)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getProductList = (): ZIO<unknown, Throwable, ProductInfo[]> =>
    pipe(
      POApi.get(`cs-api/outbound/table/t24-product`)(
        t.type({
          data: Maybe(t.array(ProductInfo)),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (response) => {
          const productList = response && response.data && response.data.length > 0 ? response.data : []
          return ZIO.succeed(productList)
        }
      )
    )

  export const getPromotionInfo = (policyNumber: string) =>
    pipe(
      POApi.get(`communication-rest/promotion?policyNumber=${policyNumber}`)(t.type({ data: Maybe(PromotionInfo) })),
      ZIO.map((promotionInfos) => promotionInfos),
      ZIO.catchAll((e) => {
        console.log(e)
        return ZIO.fail(e)
      })
    )
}
