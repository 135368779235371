import { DatePicker, SelectOption, Input } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Text } from 'react-native'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ClaimInfoForm } from '../ClaimInfoForm'
import { compareOnlyDate, isDateBefore } from '@pulseops/submission/common'

import { SubBenefit } from './SubBenefit'
import * as D from 'date-fns'
import _ from 'lodash'
interface Props {
  form: UseFormReturn<ClaimInfoForm>
  cb: (data: SelectOption) => void
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
}

export const InPatient: React.FC<Props> = ({ form, cb, setIsCanChangeTab }) => {
  const { control, watch } = form
  const { hospital } = watch()
  const { t } = useTranslation(['claim'])

  const inpatientActualLOS = (): string => {
    let result = '-'
    if (hospital.admissionDate && hospital.dischargeDate) {
      result = `${D.differenceInDays(hospital.dischargeDate, hospital.admissionDate)}`
    }
    return result.toString()
  }

  const onChangeDate = () => {
    form.watch('subBenefit').forEach((receipts, pIndex) => {
      receipts.receiptItems.forEach((receptItem, sIndex) => {
        if (receptItem.receiptItemCode?.value !== 'RCI02') {
          form.setValue(`subBenefit.${pIndex}.receiptItems.${sIndex}.duration`, inpatientActualLOS())
        }
      })
    })
  }

  return (
    <View>
      <View style={styles.row}>
        <View style={{ flex: 1 / 3, marginBottom: 32 }}>
          <Controller
            name={'hospital.admissionDate'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020009', { field: t('AdmissionDate') })
              },
              validate: (adminssionDate) => {
                const currentDate = new Date()
                const { dischargeDate } = hospital
                const compareDateNow = !!adminssionDate && !compareOnlyDate(adminssionDate, currentDate)
                const compareDateDischarge =
                  !!dischargeDate && !!adminssionDate && isDateBefore(dischargeDate, adminssionDate)
                const message1 = t('message:MS070003')
                const message2 = t('message:MS070006')
                if (compareDateNow) return message1
                if (compareDateDischarge) return message2
                return true
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                label={t('AdmissionDate')}
                required
                onChange={(e) => {
                  onChange(e)
                  onChangeDate()
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View style={{ flex: 1 / 3, marginBottom: 32, marginStart: 30 }}>
          <Controller
            name={'hospital.dischargeDate'}
            control={control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020009', { field: t('DischargeDate') })
              },
              validate: (dischargeDate) => {
                const currentDate = new Date()
                const { admissionDate } = hospital
                const compareDateNow = !!dischargeDate && !compareOnlyDate(dischargeDate, currentDate)
                const compareDateDischarge =
                  !!admissionDate && !!dischargeDate && isDateBefore(dischargeDate, admissionDate)
                const message1 = t('message:MS070003')
                const message2 = t('message:MS070006')
                if (compareDateNow) return message1
                if (compareDateDischarge) return message2
                return true
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                label={t('DischargeDate')}
                required
                onChange={(e) => {
                  onChange(e)
                  onChangeDate()
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View style={{ flex: 1 / 3, marginBottom: 32, marginStart: 30 }}>
          <Text style={styles.label}>{t('InpatientActualLOS')}</Text>
          <Text style={styles.value}>{inpatientActualLOS()}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={[styles.colFull, { paddingHorizontal: 0 }]}>
          <Controller
            name="hospital.remark"
            control={control}
            rules={{
              maxLength: {
                value: 500,
                message: `${t('message:MaxLength', { field: '500' })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('Remark')}
                onChange={(e) => {
                  onChange(e)
                  setIsCanChangeTab(false)
                }}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                maxLength={500}
                multiline
                numberOfLines={5}
                inputStyle={{ backgroundColor: '#FFFFFF' }}
              />
            )}
          />
        </View>
      </View>
      <SubBenefit form={form} cb={cb} setIsCanChangeTab={setIsCanChangeTab} />
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    marginTop: 20,
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderBottomColor: '#E5EAEF',
    borderBottomWidth: 3
  },
  contentText: {
    padding: 5,
    borderRadius: 20,
    width: 'fit-content',
    backgroundColor: 'white',
    marginBottom: 26
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  titleSectionText: {
    fontSize: 13,
    lineHeight: 22,
    fontWeight: '600',
    paddingStart: 10,
    paddingEnd: 10,
    color: '#4F4F4F'
  }
})
