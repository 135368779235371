import { formatNumberWithComma, Panel, TaskDetail } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList, Title } from '../common'

interface Props {
  detail: TaskDetail.ChangePremium
}

export const ChangePremium = ({ detail }: Props) => {
  const { t } = useTranslation()

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('submission:ChangePremium')} />
      <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('submission:CurrentSumAssured'),
              value: formatNumberWithComma(detail.currentSumAssured),
              suffix: 'VND'
            },
            {
              label: t('submission:CurrentInstallmentPremium'),
              value: formatNumberWithComma(detail.currentInstallmentPremium),
              suffix: 'VND'
            },
            {
              label: t('submission:NewInstallmentPremium'),
              value: formatNumberWithComma(detail.newInstallmentPremium),
              suffix: 'VND'
            },

            {
              label: t('submission:EstimatedRequirePayinAmount'),
              value: formatNumberWithComma(detail.requirePayInAmount),
              suffix: 'VND',
              isHighlight: true,
              isBold: true
            }
          ]}
        />
      </Panel>
    </View>
  )
}
