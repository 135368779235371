import { SelectOption } from "@pulseops/common"

export const enum TabSearchOB {
    BasicSearch = 'BasicSearch',
    AdvancedSearch = 'AdvancedSearch'
}

export const OBCurrentActivity = [
    { value: 'VERIFICATION', label: '5. Verification' },
    { value: 'PENDING', label: '7. Pending Activity' },
    { value: 'QC', label: '9. Quality Control' },
    {value: 'RE_QC_HOLDING', label: '10. Re QC Holding'},
    { value: 'RE_QC', label: '12. Re QC' }
]

export interface OBBasicSearch {
    policyNumber: string | undefined
    idNumber: string | undefined
    clientNumber: string | undefined
    phoneNumber: string | undefined
    fromDate: Date
    toDate: Date
}

export interface OBAdvantageSearch {
    lastCallingResult: SelectOption | undefined
    agency: SelectOption | undefined
    leader: SelectOption | undefined
    lastAssignee: SelectOption | undefined
    callProgram: SelectOption | undefined
    status: SelectOption | undefined
    currentActivity: SelectOption | undefined
    fromDate: Date | undefined | null
    toDate: Date | undefined | null
    firstIssueFromDate: Date | undefined | null
    firstIssueToDate: Date | undefined | null
    lastIssueFromDate: Date | undefined | null
    lastIssueToDate: Date | undefined | null
    createdFromDate: Date | undefined | null
    createdToDate: Date | undefined | null
    submitFromDate: Date | undefined | null
    submitToDate: Date | undefined | null
    paidToDateFromDate: Date | undefined | null
    paidToDateToDate: Date | undefined | null
}