import * as React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { BusinessStackParamList } from './business-stack-param-list'
import { ContractDetailScreen } from './contract-detail/contract-detail-screen'
import { ContractListScreen } from './contract-list-screen'
import { ClaimListScreen } from './claim/claim-list-screen'
import { ClaimDetailScreen } from './claim/claim-detail-screen'
import { BusinessScreen } from './business-screen'

const Stack = createStackNavigator<BusinessStackParamList>()

export const BusinessStack = () => {
  return (
    <Stack.Navigator initialRouteName="BusinessScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="BusinessScreen" component={BusinessScreen} />
      <Stack.Screen name="ClaimListScreen" component={ClaimListScreen} />
      <Stack.Screen name="ClaimDetailScreen" component={ClaimDetailScreen} />
      <Stack.Screen name="ContractListScreen" component={ContractListScreen} />
      <Stack.Screen name="ContractDetailScreen" component={ContractDetailScreen} />
    </Stack.Navigator>
  )
}
