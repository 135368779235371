import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { CashoutOptions } from './CashoutOptions'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.EXCELLENT_STUDENT_AWARD)
    }),
    codec
  ])

export const ExcellentStudentAward = Base(
  t.type({
    source: t.string,
    payload: t.type({
      body: t.type({
        policy: t.type({
          policyNo: t.string
        }),
        attributesExt: t.type({
          TOTAL_PAYOUT_AMOUNT: t.number,
          EXCELLENT_AWARD_AMOUNT: t.number,
          PRINCIPAL_AMOUNT: t.number,
          INTEREST_AMOUNT: t.number,
          NET_TOTAL_CASH_BENEFIT: t.number,
          LOAN_EXCEED_80_SURRENDER_VALUE: t.number
        }),
        cashOutOptions: Maybe(CashoutOptions)
      })
    })
  })
)
export type ExcellentStudentAward = t.TypeOf<typeof ExcellentStudentAward>
