import { MaybeUndefined, Maybe, Nullable } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { AmlResult } from '../../general-info/model/aml-result'
import { Employee } from '../../general-info/model/employee'
import { UwDecisionCode, UwDecisionCodeC } from './uw-decision-code'

export const UwEmployeeDecisionC = t.type({
  _id: MaybeUndefined(t.string),
  employeeKey: Nullable(t.string),
  employeeName: t.string,
  employeeId: t.string,
  email: t.string,
  employeePhoneNumber: t.string,
  gender: t.string,
  dateOfBirth: DateFromISOString,
  benefitClass: t.string,
  masterContractNo: t.string,
  nationality: t.string,
  uwDecision: UwDecisionCodeC,
  comment: Nullable(t.string),
  policyYear: t.number,
  basicSa: Nullable(t.number),
  amlResult: t.string,
  overFclAmount: t.number,
  createdDate: Maybe(DateFromISOString),
  createdBy: Maybe(t.string),
  basicPlan: Maybe(t.string),
  addRiderSa: Maybe(t.number),
  healthCarePlan: Maybe(t.string),
  overFcl: Maybe(t.string)
})

export type UwEmployeeDecision = t.TypeOf<typeof UwEmployeeDecisionC>

export const UwEmployeeDecision =
  ({
    decision,
    comment,
    overFclAmount,
    overFcl
  }: {
    decision: UwDecisionCode
    comment?: string | null
    overFclAmount?: number
    overFcl?: string
  }) =>
  (e: Employee): UwEmployeeDecision => ({
    _id: undefined,
    employeeKey: e.employeeKey,
    employeeName: e.employeeName,
    employeeId: e.employeeId,
    email: e.email,
    employeePhoneNumber: e.employeePhoneNumber,
    gender: e.gender,
    dateOfBirth: e.dateOfBirth,
    benefitClass: e.benefitClass,
    masterContractNo: e.masterContractNo,
    nationality: e.nationality,
    uwDecision: decision,
    comment: comment || '',
    policyYear: e.policyYear,
    basicSa: e?.basicSa,
    amlResult: pipe(
      AmlResult.getLatestOption(e.historyAmlResult),
      O.fold(
        () => '',
        (r) => r.amlResult
      )
    ),
    overFclAmount: overFclAmount || 0,
    overFcl: overFcl,
    createdDate: undefined,
    createdBy: undefined,
    basicPlan: e.basicPlan,
    addRiderSa: e.addRiderSa,
    healthCarePlan: e.healthcarePlan
  })
