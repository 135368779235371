import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace AddLackOfDocumentModel {
  export interface Options {
    value: string
    label: string
  }

  export interface TabState {
    tabIndex: number
    state: boolean
    canDelete: boolean
  }

  export const SuppendType = t.type({
    transactionType: t.string,
    code: t.string,
    docTypeEn: t.string,
    docTypeVn: t.string,
    typeEn: t.string,
    typeVn: t.string,
    claimNumber: Maybe(t.string),
    metadata: Maybe(
      t.type({
        classFile: t.string,
        docId: t.string,
        docTypeEn: t.string,
        docTypeVn: t.string,
        mainDoc: t.string,
        subDoc: t.string,
        transactionType: t.string,
        type: t.string
      })
    )
  })
  export type SuppendType = t.TypeOf<typeof SuppendType>

  export const SuppendTypes = t.type({
    body: t.array(SuppendType)
  })
  export type SuppendTypes = t.TypeOf<typeof SuppendTypes>

  export interface DataSubmit {
    addLackOfDocuments: {
      transactionType: string
      suspendCode: string
      caseId: string
      attachments: { name: string; url: string }[]
    }[]
  }
}
