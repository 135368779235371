import { capFisrtLetterEachWord, form2, PayoutPopup } from '@pulseops/common'
import * as t from 'io-ts'

export namespace CepILPForm {
  const fundItem = t.union([
    t.type({
      isRequired: t.literal(true),
      withdrawAmount: form2.string.required
    }),
    t.type({
      isRequired: t.literal(false),
      withdrawAmount: form2.string.optional
    })
  ])

  const ILP = t.type({
    fundListError: form2.string.optional,
    fundList: t.array(fundItem),
    withdrawFee: t.number,
    LASMessage: t.string,
    isLASMessage: t.boolean,
  })

  export const codec = ILP

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
