import { enumC } from '@mxt/zio/codec'

export enum SourceType {
  PULSE = 'PULSE',
  ZALO = 'ZALO',
  BANCA = 'BANCA',
  BANCA_SEA = 'BANCA_SEA',
  BANCA_SEABANK = 'BANCA_SEABANK',
  PRUONLINE = 'PRUONLINE',
  EKIOSK = 'EKIOSK',
  PRUGREAT = 'PRUGREAT',
  PULSE4OPS = 'PULSE4OPS',
  LANDING_PAGE = 'LANDING_PAGE',
  ONLINE_PAYMENT = 'ONLINEPAYMENT',
  PRUDAILY = 'PRUDAILY',
  ShopeePay = 'SHOPEEPAY',
  Ebanking_seabank = 'EBANKING_SEABANK',
  MSB_BANK = 'MSB_BANK',
  MOMO = 'MOMO',
  MOMO_FOR_AGENT = 'MOMO_FOR_AGENT',
  MY_VIB = 'MY_VIB',
  Shopee = 'SHOPEE',
  PAYOO = 'PAYOO',
  POS_SeABank = 'POS_SEABANK',
  VIB_AUTODEBIT = 'VIB_AUTODEBIT',
  Agribank_VBA = 'AGRIBANK_VBA',
  Viettel = 'VIETTEL',
  VNPOST = 'VNPOST',
  VIETTEL_TELCO = 'VIETTEL_TELCO',
  MPOS_EXIMBANK = 'MPOS_EXIMBANK',
  Offline = 'OFFLINE',
  SACOMBANK = 'SACOMBANK',
  PRUFORCE = 'PRUFORCE',
  CREDITLIFEPORTAL = 'CREDITLIFEPORTAL',
  BANCA_VIB = 'BANCA_VIB',
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND'
}
export const SourceTypeC = enumC(SourceType)
