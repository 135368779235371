import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { AuthService } from '../auth'

export namespace ViewManualService {

  export const SearchGuideLine = t.type({
    order: t.string,
    sort: t.string,
    size: t.number,
    start: t.number,
    departmentCode: Maybe(t.string),
    group: Maybe(t.string),
    mainDoc: Maybe(t.string),
    subDoc: Maybe(t.string),
    fromDate: Maybe(t.string),
    toDate: Maybe(t.string) 
  })
  export type SearchGuideLine = t.TypeOf<typeof SearchGuideLine>
  
  export const GuidelineTable = t.type({
    exchangeId: Maybe(t.string),
    source: Maybe(t.string),
    departmentCode: Maybe(t.string),
    group: Maybe(t.string),
    mainDoc: Maybe(t.string),
    subDoc: Maybe(t.string),
    createdDate: Maybe(t.string),
    businessKey: Maybe(t.string)
  })
  export type GuidelineTable = t.TypeOf<typeof GuidelineTable>
  
  export const SearchGuideLineData = t.type({
    body: 
      t.type({
        size: Maybe(t.number),
        start: Maybe(t.number),
        total: Maybe(t.number),
        data: t.array(GuidelineTable)
      })
  })
  export type SearchGuideLineData = t.TypeOf<typeof SearchGuideLineData>
  
  export const GetDataSearch = (data: SearchGuideLine) =>
    pipe(
      POApi.post(`wf-api/policy/manual-guideline/get-data`)(SearchGuideLineData)(data),
      ZIO.map((res) => res.body)
    )

  export const GetDataDownload = (businessKey: string) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`pvafilenet/document/manual-guideline/${businessKey}`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        })
      )
    )
}
