import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { LoanStatementsService } from './loan-statement-service'

export namespace PolicyCertificateService {
  export const getAccessData = (policyNum: string) =>
    pipe(
      LoanStatementsService.accessData(policyNum),
      ZIO.map((response) => {
        return response
      })
    )
}
