import { SelectOption } from '@pulseops/common'

export namespace ViewDocumentGuideForm {
  export interface GuideModal {
    departmentCode: SelectOption | null
    group: SelectOption | null,
    mainDoc: SelectOption | null,
    subDoc: SelectOption | null,
    fromDate?: Date
    toDate?: Date
  }

  export const GuideFormDefault: GuideModal = {
    departmentCode: null,
    group: null,
    mainDoc: null,
    subDoc: null,
    fromDate: undefined,
    toDate: undefined
  }
}
