import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { LasStatus } from './LasStatus'
import { PaymentMethod } from './PaymentMethod'

export const InternalTransfer = ['PAYPREMIUM', 'REPAY_APL', 'REPAY_OPL']

export const PoliciesReceived = t.type({
  payoutOptionType: Maybe(t.string),
  policyReceivedNum: Maybe(t.string),
  bankAccountName: Maybe(t.string),
  bankAccountNumber: Maybe(t.string),
  bankName: Maybe(t.string),
  bankBranch: Maybe(t.string),
  bankAddress: Maybe(t.string),
  amount: Maybe(t.string),
  reason: Maybe(t.string),
  nationalId: Maybe(t.string)
})
export type PoliciesReceived = t.TypeOf<typeof PoliciesReceived>

// const mapPaymentMethod = new Map<string, PaymentType>([
//   ['1', PaymentType.BANKTRANSFER],
//   ['2', PaymentType.PAYPREMIUM],
//   ['3', PaymentType.PAYLOAN],
//   ['4', PaymentType.PAYLOAN],
//   ['5', PaymentType.PAID_AT_BANK]
// ])

export const MapMethod = (
  method: PoliciesReceived,
  lasStatus: LasStatus | null,
  _correlationId: string
): PaymentMethod => {
  if (method && method.payoutOptionType) {
    return <PaymentMethod>{
      method: method.payoutOptionType,
      detail: InternalTransfer.includes(method.payoutOptionType)
        ? method.policyReceivedNum
        : `${method.bankName || '-'}\n${method.bankAccountName || '-'}\n${
            method.bankAccountNumber ? method.bankAccountNumber + '-' : ''
          }\n${method.nationalId || ''}`.replace(/(^-)/, ''),
      amount: Number(method.amount) || 0,
      status: lasStatus,
      note: method.reason || '-',
      action: '-'
    }
  } else {
    return <PaymentMethod>{
      method: '-',
      detail: method.policyReceivedNum || '-',
      amount: Number(method.amount) || 0,
      status: lasStatus,
      note: method.reason || '-',
      action: '-'
    }
  }
}
