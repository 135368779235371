import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { InboundParamList } from '..'
import { SafeAreaView, ScrollView, View, StyleSheet, Text, Pressable } from 'react-native'
import { IBButton, IBGeneralField, IBGeneralTable, TypeInputComponent } from '../common'
import { useForm } from 'react-hook-form'
import { IBDraftTransSearchForm } from './IBDraftTransSearchForm'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { DraftTransactionItem, DraftTransactionParams, IBService } from '../ib-service'
import { AppContext, AuthService, ErrorHandling, Permission, PulseOpsFormat, RBAC } from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import _ from 'lodash'

type Props = StackScreenProps<InboundParamList, 'IBDraftTransaction'>

export const IBDraftTransaction = (props: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)
  const [formError, setFormError] = React.useState<string>('')
  const [draftList, setDraftList] = React.useState<(DraftTransactionItem & { poName: string | undefined, idShow: string })[] | null>(null)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPage] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [dataSearch, setDataSearch] = React.useState<IBDraftTransSearchForm | null>(null)
  const [dataUser, setDataUser] = React.useState<string>('')
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []

  const defaultValues: IBDraftTransSearchForm = {
    policyNumber: '',
    clientNumber: '',
    fromDate: null,
    toDate: null
  }
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const SearchForm = useForm<IBDraftTransSearchForm>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })

  const dataTable = [
    {
      label: t('ReferenceID'),
      field: 'idShow',
      render: (value: string, index: number) => {
        return (
          <Pressable onPress={() => draftList && navigateToSubmitDraft(mapData(draftList[index]))}>
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('common:PolicyNumber'),
      field: 'policyNo',
    },
    {
      label: t('ClientNumber'),
      field: 'clientNo',
    },
    {
      label: t('OwnerName'),
      field: 'poName'
    },
    {
      label: t('TransactionTypeDraft'),
      field: 'transactionType',
    },
    {
      label: t('CreatedDate'),
      field: 'createdDate',
    },
    {
      label: t('LastUpdateDate'),
      field: 'updatedDate',
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:DraftTransaction'),
          navigate: null
        }
      ])
    }
    if (dataUser) {
      searchDataDraftList(dataSearch)
    }
  }, [isFocused])

  React.useEffect(() => {
    pipe(
      AuthService.userInfo,
      ZIO.map((userInfo) => {
        if (userInfo.email) {
          setDataUser(userInfo.email)
        }
      }),
      ZIO.unsafeRun({})
    )
  }, [])

  React.useEffect(() => {
    if (dataUser) {
      searchDataDraftList(dataSearch)
    }
  }, [dataUser])

  React.useEffect(() => {
    searchDataDraftList(dataSearch)
  }, [pageNum, pageSize])

  const onSearch = SearchForm.handleSubmit((value) => {
    SearchForm.clearErrors()
    setDataSearch(value)
    searchDataDraftList(value)
  })

  const mapData = (data: DraftTransactionItem | undefined): DraftTransactionParams | undefined => {
    return data ? {
      createdDate: data.createdDate,
      updatedDate: data.updatedDate,
      updatedBy: data.updatedBy,
      createdBy: data.createdBy,
      transactionType: data.transactionType,
      policyNo: data.policyNo,
      clientNo: data.clientNo,
      poName: data.payload?.poName,
      id: data.id,
      groupCode: data.payload?.groupCode,
      typeCode: data.payload?.typeCode,
      subTypeCode: data.payload?.subTypeCode,
      requesterRoleCode: data.payload?.requesterRoleCode,
      requesterFullName: data.payload?.requesterFullName,
      relationshipWithPO: data.payload?.relationshipWithPO,
      requesterNationalId: data.payload?.requesterNationalId,
      method: data.payload?.method,
      source: data.payload?.source,
      callId: data.payload?.callId,
      hadFirstContactResolution: data.payload?.hadFirstContactResolution,
      customerComment: data.payload?.customerComment,
      responseToCustomer: data.payload?.responseToCustomer,
      isUrgent: data.payload?.isUrgent,
      updateVersion: data.updateVersion,
      callBackLaterHour: data.payload?.callBackLaterHour,
      callbackAppointmentTime: data.payload?.callbackAppointmentTime
    } : undefined
  }

  const getCategoryCode = (categoryName: string | null | undefined) => {
    if (categoryName === 'INQUIRY') {
      return 'CS' + '-'
    } else if (categoryName === 'COMPLAINT') {
      return 'CH' + '-'
    } else {
      return ''
    }
  }

  const searchDataDraftList = (value: IBDraftTransSearchForm | null) => {
    pipe(
      IBService.searchDraftTransaction({
        pageIndex: pageNum,
        itemsPerPage: pageSize,
        orders: ['createdDate'],
        sort: 'desc',
        policyNo: value?.policyNumber ?? '',
        clientNo: value?.clientNumber ?? '',
        fromDate: value?.fromDate ? PulseOpsFormat.serviceInquiry_StartOfDate(value?.fromDate, true) : undefined,
        toDate: value?.toDate ? PulseOpsFormat.serviceInquiry_endOfDate(value?.toDate, true) : undefined,
        status: "VALID",
        createdBy: dataUser
      }),
      ZIO.map((res) => {
        if (res) {
          setTotalItem(res.paging?.total ?? 0)
          setDraftList(res.records.map((i) => ({
            ...i,
            idShow: getCategoryCode(i.transactionType) + (i.policyNo ? i.policyNo + '-' : '') +
              (!!i.createdDate ? PulseOpsFormat.dateStringtoFormat(i.createdDate, 'DD/MM/YYYY HH:mm:ss') : ''),
            createdDate: !!i.createdDate ? PulseOpsFormat.dateStringtoFormat(i.createdDate, 'DD/MM/YYYY HH:mm:ss') : '',
            updatedDate: !!i.updatedDate ? PulseOpsFormat.dateStringtoFormat(i.updatedDate, 'DD/MM/YYYY HH:mm:ss') : '',
            poName: i.payload?.poName ?? '',
          })))
          if (res.records.length === 0) setFormError(t('message:MS030029'))
          else setFormError('')
        }
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        setFormError(t('message:MS030029'))
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const navigateToSubmitDraft = (data: DraftTransactionParams | undefined) => {
    if (data && permissions.includes(Permission['ViewDraftTransactionDetail'])) {
      props.navigation.push('IBSubmitTransaction', data)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <IBGeneralField
            FieldForm={SearchForm}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.INPUT,
                formName: 'policyNumber',
                title: t('common:PolicyNumber'),
                inputType: 'number',
                maxLength: 9,
                rules: {
                  minLength: {
                    value: 8,
                    message: t('message:MS160014')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'fromDate',
                // required: true,
                title: t('FromDate'),
                maxDate: new Date(),
                rules: {
                  validate: () => {
                    const currentDate = new Date()
                    const toDate = SearchForm.watch('toDate')
                    const fromDate = SearchForm.watch('fromDate')
                    return !_.isNull(fromDate) &&
                      (fromDate?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:IB0004')}`
                      : !_.isNull(fromDate) && !_.isNull(toDate) &&
                        fromDate?.getTime() > toDate?.getTime()
                        ? `${t('message:MS030044')}`
                        // : FromToDateRangeMonth(1)({ fromDate: SearchForm.watch('fromDate'), toDate: SearchForm.watch('toDate') })
                        //   ? `${t('message:MS080004', { k: '1' })}`
                          : _.isNull(SearchForm.watch('fromDate')) && !_.isNull(toDate) ?
                            `${t('message:MS020009', { field: t('common:FromDate') })}` : true
                  }
                },
                placeHolder: 'dd/mm/yyyy',
                disabled: loading
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'toDate',
                // required: true,
                maxDate: new Date(),
                title: t('ToDate'),
                rules: {
                  validate: () => {
                    const currentDate = new Date()
                    const toDate = SearchForm.watch('toDate')
                    const fromDate = SearchForm.watch('fromDate')
                    return !_.isNull(toDate) &&
                      (toDate?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:IB0004')}`
                      : !_.isNull(fromDate) && !_.isNull(toDate) &&
                        (fromDate?.getTime() as number) > (toDate?.getTime() as number)
                        ? `${t('message:MS030044')}`
                        // : FromToDateRangeMonth(1)({ fromDate: fromDate, toDate: toDate })
                        //   ? `${t('message:MS080004', { k: '1' })}`
                          : _.isNull(toDate) && !_.isNull(fromDate) ?
                            `${t('message:MS020009', { field: t('common:ToDate') })}` : true
                  }
                },
                placeHolder: 'dd/mm/yyyy',
                disabled: loading
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'clientNumber',
                title: t('ClientNumber'),
                inputType: 'number',
                maxLength: 8,
                rules: {
                  minLength: {
                    value: 8,
                    message: t('message:IB0003')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              }
            ]}
          />
          <View style={{ width: '7%', minWidth: 140 }}>
            {permissions.includes(Permission['SearchTransactionDraftTransaction']) && 
              <IBButton
                onPress={() => {
                  setFormError('')
                  onSearch()
                }}
                title={t('Search')}
                backgroundFill
                disabled={loading}
              />
            }
          </View>
        </View>
        <View style={{ marginLeft: 15 }}>
          {formError ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{formError}</Text> : null}
        </View>
        <View style={{ margin: 15 }}>
          <IBGeneralTable
            dataTable={dataTable}
            data={draftList ?? []}
            loading={loading}
            paging={{
              setPage: setPage,
              setPageSize: setPageSize,
              page: pageNum,
              pageSize: pageSize,
              taskCount: totalItem
            }} 
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    height: '100%'
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  }
})