import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import React from 'react'
import { PCRequestListStackParamList } from './PCRequestListParamList'
import { StackScreenProps, StackNavigationProp } from '@react-navigation/stack'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import {
  AppContext,
  CashLessService,
  useMobile,
  TabList,
  RBAC,
  ErrorHandling,
  RequestListPolicyInfo,
  RequestListTransactionInfo,
  PulseOpsFormat,
  CancelRiderService,
  Input,
  form2,
  Alert,
  GeneralService,
  TASK_DETAIL_ROLES
} from '@pulseops/common'
import * as D from 'date-fns'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { PolicyInfoHeader } from './common'
import { GeneralInfoScreen } from '../../../task/src/task-detail/container/generalInfo'
import { RelatedInfo } from '../../../task/src/task-detail/container/related-info'
import { TaskDetailStackParamList } from '../../../task/src/task-detail/TaskDetailStackParamList'
import { PolicyInfoView } from './common'
import moment from 'moment'
import { ContainerView, styles } from './common/styles'
import { GridView } from './common/gridview'
import { RequestListDetailForm } from './PCRequestListDetailForm'
import { Controller } from 'react-hook-form'
import { TransactionInfoView } from './common'
import _ from 'lodash'
type Props = StackScreenProps<PCRequestListStackParamList, 'PCRequestListDetailScreen'>
type Props2 = StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>

export const PCRequestListDetail = (props: Props, props2: Props2) => {
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const { t, i18n } = useTranslation('pc')
  const [tabIndex, setTabIndex] = React.useState<number>(2)
  const [isTrigger, setTrigger] = React.useState<boolean>(false)
  const { policyNumber, revertRequestWOId, officeCode, policyOwnerName, status, onRefresh } = props.route.params
  const [policyInfo, setPolicyInfo] = React.useState<RequestListPolicyInfo>()
  const [transactionInfo, setTransactionInfo] = React.useState<RequestListTransactionInfo>()
  const [officeName, setOfficeName] = React.useState<string>('')
  const [flagQueryCaseIdNotWO, setFlagQueryCaseIdNotWO] = React.useState<boolean>(false)
  const [customerRequestInfo, setCustomerRequestInfo] =
    React.useState<{ paymentReason: string; remark: string; listFileName: string[] }>()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const editabled =
    !['COMPLETED', 'END'].includes(status) && permissions.includes(TASK_DETAIL_ROLES.EditPCRequestDetail)
  const [enableSave, setEnableSave] = React.useState<boolean>(false)
  const [caseId, setCaseId] = React.useState<string>()
  const refCaseId = React.useRef<string | null>(null)
  const { base, handleSubmit } = form2.useForm(RequestListDetailForm.codec)
  const { watch } = base

  const fields = React.useMemo(() => {
    return [
      {
        label: t('Cashless:paymentReason'),
        value: customerRequestInfo?.paymentReason
      },
      {
        label: t('Cashless:remark'),
        value: customerRequestInfo?.remark
      }
    ]
  }, [i18n, customerRequestInfo])

  const menus = React.useMemo(
    () => [
      {
        label: t('common:PolicyNumber'),
        value: policyNumber
      },
      {
        label: t('common:PolicyOwner'),
        value: policyOwnerName
      },
      {
        label: t('common:Office'),
        value: officeName ?? '-'
      }
    ],
    [i18n, officeName]
  )

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:PCRequestList'),
          navigate: () => {
            navigation.reset({
              routes: [{ name: 'PCRequestListScreen' }]
            })
          }
        },
        {
          title: policyNumber,
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    setTrigger(false)
  }, [transactionInfo])

  React.useEffect(() => {
    showGlobalLoading(true)
    pipe(
      ZIO.zipPar(
        CashLessService.getCommonInfo(props.route.params.policyNumber, undefined),
        CancelRiderService.getRiderInfoList(),
        CashLessService.getCustomerRequestInfo(revertRequestWOId),
        GeneralService.getOfficeCode(officeCode)
      ),
      ZIO.map(([res, rider, customerRequestInfo, officeItem]) => {
        const {
          accountGroups,
          subfileOccurs,
          loanActionB,
          checkDiscount,
          batchCtrCode,
          policyInfo,
          proposalInfo,
          interestRates
        } = res

        if (officeItem && officeItem.body && officeItem.body.length > 0) {
          const officeNameLanguage = i18n.language === 'vi' ? officeItem.body[0].nameVi : officeItem.body[0].nameEn
          const officeItemName = officeItem.body[0].code + ' - ' + officeNameLanguage
          setOfficeName(officeItemName)
        }

        const basicPrem = policyInfo
          ? (policyInfo?.attributesExt?.basicPremium ?? 0) + (policyInfo?.attributesExt?.riderPremium ?? 0)
          : proposalInfo
          ? (proposalInfo.sumAssured ?? 0) + (proposalInfo.totalPremium ?? 0)
          : 0

        const startDate = (subfileOccurs ?? []).reduce<Date | null>((pre, cur) => {
          const startDateM = cur.loanType === 'A' ? moment(cur.loanStartDate, 'DD/MM/YYYY') : null
          return pre ? (startDateM?.isAfter(pre) ? startDateM.toDate() : pre) : startDateM?.toDate() ?? null
        }, null)

        const totalAmount =
          accountGroups
            ?.filter(({ sacsCode, sacsType }) => (sacsCode === 'LP' && sacsType === 'U') || sacsType === 'S')
            .reduce((acc, { amount }) => acc + (amount ?? 0), 0) ?? 0

        const contract = rider.find((_) => _.code === policyInfo?.basicCode)
        const product = policyInfo?.productCode ?? proposalInfo?.contractTypeCode ?? ''
        if (customerRequestInfo.caseId) {
          refCaseId.current = customerRequestInfo.caseId
          base.setValue('caseId', customerRequestInfo.caseId || '')
          setCaseId(customerRequestInfo.caseId)
          handleQueryByCaseId(customerRequestInfo.caseId, true)
        }

        setCustomerRequestInfo({
          paymentReason: customerRequestInfo.paymentReason || '-',
          remark: customerRequestInfo.remark || '-',
          listFileName: customerRequestInfo.azureDocuments.reduce(
            (prev, curv) => (prev = [...prev, curv.filename]),
            [] as string[]
          )
        })
        setPolicyInfo({
          installmentPremium: basicPrem,
          product: `${product} - ${i18n.language === 'en' ? contract?.name : contract?.nameVi}`,
          lastestBilling: batchCtrCode?.lastPremiumDate ? PulseOpsFormat.date(batchCtrCode.lastPremiumDate) : '-',
          lastAPLDate: startDate ? PulseOpsFormat.date(startDate) : '-',
          latestAppliedPremium: batchCtrCode?.lastTransactionDate
            ? PulseOpsFormat.date(batchCtrCode.lastTransactionDate)
            : '-',
          excessPremium: totalAmount,
          policyLoanPrincipal: loanActionB?.balanceOPL ?? 0,
          aplPrinciple: loanActionB?.balanceAPL ?? 0,
          discountPremium: checkDiscount?.discountAmount ?? 0,
          policyLoanInterest: loanActionB?.interestOPL ?? 0,
          aplInterest: loanActionB?.interestAPL ?? 0
        })
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
    return () => {
      onRefresh && onRefresh()
    }
  }, [])

  const tabs = React.useMemo(() => {
    return [
      { id: 1, title: t('Tab:GeneralInfo') },
      { id: 2, title: t('Tab:RequestInfo') },
      { id: 3, title: t('Tab:RelatedInfo') }
    ]
  }, [isFocused])

  const onChangeTab = (i: number) => {
    setTabIndex(i)
  }

  const mappingTransactionInfo = (res: any, remainingAmount?: string | null) => {
    setTrigger(true)
    const { payload } = res.data
    const { body } = payload
    const adjustPolicy = _.isUndefined(body.priorProposal)
      ? null
      : body.priorProposal
      ? res.data.proposalNumber
      : res.data.policyNumber

    const dataMapping: RequestListTransactionInfo = {
      bankPartnerName: body.bankCode,
      customerSubmitDate: D.parseISO(body.transactionDate).toString(),
      partnerTransferDate: body.receiptDate != null ? D.parseISO(body.receiptDate).toString() : undefined,
      spendAmount: body.amount ?? 0,
      refNo: body.referenceNumber ?? '-',
      reason: body.paymentReason,
      policyNumberAppNumber: body.policyNumber ?? body.proposalNumber ?? '',
      adjustedPolicyNumber: adjustPolicy || '-',
      remainingAmount: remainingAmount ?? res.data.amount,
      cardType: body.cardType ?? '-',
      payeeName: body.payerName ?? '-',
      mobileNumber: body.phoneNumber,
      bankTransferPendingNo: res.data.pendingNum ?? undefined,
      remark: body.remark,
      source: payload.source,
      processId: res.data.id
    }

    return dataMapping
  }

  const handleQueryByCaseId = (caseId: string, loadingData: boolean) => {
    const keySplit = caseId.split('PC-')
    showGlobalLoading(true)
    pipe(
      ZIO.zipPar(
        CashLessService.getProcessInstanceWO(caseId, revertRequestWOId),
        CashLessService.getHistoryReceipt(keySplit[1])
      ),
      ZIO.flatMap(([res, historyCaseId]) => {
        showGlobalLoading(false)
        if (res.data) {
          if (!res.warningCode || loadingData) {
            setTransactionInfo(mappingTransactionInfo(res, historyCaseId?.remainingAmount?.toString()))
            return ZIO.unit
          } else {
            return Alert.modalM({
              title: '',
              content: res.warningCode.includes('MS990085') ? t('message:MS990085') : t('message:MS990086'),
              size: {
                width: '50%'
              },
              onOK: () => {
                ;(res.warningCode as string[]).includes('MS990085') && setFlagQueryCaseIdNotWO(true)
                if ((res.warningCode as string[]).length > 1) {
                  pipe(
                    ZIO.succeed(true),
                    ZIO.flatMap(() =>
                      Alert.modalM({
                        title: '',
                        content: t('message:MS990086'),
                        size: {
                          width: '50%'
                        },
                        onOK: () => {
                          setTransactionInfo(mappingTransactionInfo(res, historyCaseId?.remainingAmount?.toString()))
                        },
                        onClose: () => {
                          handleReset()
                        }
                      })
                    ),
                    ErrorHandling.run()
                  )
                } else {
                  setTransactionInfo(mappingTransactionInfo(res, historyCaseId?.remainingAmount?.toString()))
                }
              },
              onClose: () => {
                handleReset()
              }
            })
          }
        } else {
          // handleReset()
          setTransactionInfo(undefined)
          showToast(t('message:MS030029'), 'error')
        }
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        // handleReset()
        setTransactionInfo(undefined)
        showToast(t('message:MS030029'), 'error')
        showGlobalLoading(false)
        return ZIO.succeed(null)
      }),
      ErrorHandling.run()
    )
  }

  const handleReset = () => {
    if (refCaseId.current) {
      handleQueryByCaseId(refCaseId.current, true)
      setCaseId(refCaseId.current)
      base.setValue('caseId', refCaseId.current)
    } else {
      setCaseId('')
      setTransactionInfo(undefined)
      base.reset()
    }
  }

  const handleUpdateRevertRequest = handleSubmit((value) => {
    pipe(
      CashLessService.updateRevertRequest(value.caseId || '', revertRequestWOId),
      ZIO.map((res) => {
        refCaseId.current = value.caseId
        showToast(t('message:MS990017'), 'success')
        ZIO.succeed(true)
      }),
      ZIO.catchAll((error) => {
        showToast(t('TaskManagement:ErrorHandler'), 'error')
        return ZIO.fail(false)
      }),
      ZIO.unsafeRun({})
    )
  })

  const handleCompleteRevertRequest = handleSubmit((value) => {
    if (value.caseId) {
      pipe(
        CashLessService.completeRevertRequest(value.caseId, revertRequestWOId),
        ZIO.map((res) => {
          showToast(t(flagQueryCaseIdNotWO ? 'requestInfo:CompleteSuccess' : 'message:MS990084'), 'success')
          setTimeout(() => {
            props.navigation.navigate('PCRequestListScreen')
          }, 1000)
          ZIO.succeed(true)
        }),
        ZIO.catchAll((error) => {
          showToast(t('TaskManagement:ErrorHandler'), 'error')
          return ZIO.fail(false)
        }),
        ZIO.unsafeRun({})
      )
    }
  })

  const handleEndRevertRequest = () => {
    pipe(
      CashLessService.endRevertRequest(revertRequestWOId),
      ZIO.map((res) => {
        showToast(t('requestInfo:EndSuccess'), 'success')
        props.navigation.navigate('PCRequestListScreen')
        ZIO.succeed(true)
      }),
      ZIO.catchAll((error) => {
        showToast(t('TaskManagement:ErrorHandler'), 'error')
        return ZIO.fail(false)
      }),
      ZIO.unsafeRun({})
    )
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: useMobile().isMobile ? '#E5E5E5' : '#F9F9F9',
        paddingTop: 16
      }}
    >
      <View style={{ flex: 1 }}>
        <PolicyInfoHeader menus={menus} />
        <View style={{ marginHorizontal: 15 }}>
          <TabList menus={tabs} isSubTab={false} useOriginalId={true} tabIndex={tabIndex} onChangeTab={onChangeTab} />
        </View>
        <ScrollView style={{ backgroundColor: '#FFFFFF' }}>
          <View>
            {tabIndex === 1 && (
              <GeneralInfoScreen
                navigation={props2}
                policyNum={policyNumber}
                roles={permissions}
                isGeneral={true}
                caterogy={'PC'}
              />
            )}
            {tabIndex === 2 && policyInfo && (
              <View style={{ marginHorizontal: 30, marginBottom: 50 }}>
                <PolicyInfoView detail={policyInfo} />
                <View style={{ marginTop: 15 }}>
                  <Text style={styles.sessonText}>{t('Cashless:CustomerRequestInfo')}</Text>
                </View>
                <ContainerView>
                  <GridView data={fields} />
                </ContainerView>
                <View style={{ marginVertical: 15 }}>
                  <Text style={styles.sessonText}>{t('ServiceInquiry:pc:caseId').toUpperCase()} </Text>
                  <View style={{ width: '40%' }}>
                    <Controller
                      control={base.control}
                      name="caseId"
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          value={field.value ?? ''}
                          errorMessage={error?.message}
                          required
                          onBlur={() => {
                            if (editabled && caseId !== field.value?.trim()) {
                              setEnableSave(false)
                              if (field.value) {
                                setCaseId(field.value.trim())
                                field.onChange(field.value.trim())
                                handleQueryByCaseId(field.value.trim(), false)
                              } else {
                                handleReset()
                              }
                            }
                          }}
                          disabled={!editabled}
                          maxLength={50}
                          onFocus={() => {
                            setEnableSave(true)
                          }}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={{ marginVertical: 15, marginBottom: 100 }}>
                  {transactionInfo && !isTrigger && (
                    <>
                      <TransactionInfoView detail={transactionInfo} />
                      {editabled && (
                        <View style={{ marginVertical: 15 }}>
                          <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                            <TouchableOpacity
                              style={[styles.Button, { backgroundColor: '#fff' }]}
                              onPress={handleReset}
                            >
                              <Text style={{ color: '#ED1B2E', fontWeight: '700', fontSize: 15 }}>
                                {t('common:Cancel')}
                              </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.Button,
                                {
                                  backgroundColor: !enableSave ? '#ED1B2E' : '#B0B0B0',
                                  borderColor: !enableSave ? '#ED1B2E' : '#B0B0B0'
                                }
                              ]}
                              onPress={handleUpdateRevertRequest}
                              disabled={enableSave}
                            >
                              <Text style={{ color: '#fff', fontWeight: '700', fontSize: 15 }}>{t('common:Save')}</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </>
                  )}
                </View>
              </View>
            )}
            {tabIndex === 3 && (
              <>
                <RelatedInfo
                  processId={!editabled ? (transactionInfo?.processId as string) : 'null'}
                  taskId={!editabled ? (transactionInfo?.processId as string) : 'null'}
                  policyNum={policyNumber}
                  roles={permissions}
                  isInquiry={false}
                  isSuspend={true}
                  isQC={false}
                  disabled={false}
                  category={'PC'}
                  filesNamePC={customerRequestInfo?.listFileName}
                />
              </>
            )}
          </View>
        </ScrollView>
        {editabled && permissions.includes(TASK_DETAIL_ROLES.CompletePCRequestDetail) && tabIndex === 2 && (
          <View style={[styles.footer]}>
            <TouchableOpacity
              style={[
                styles.Button,
                {
                  backgroundColor: '#fff',
                  borderColor: refCaseId.current === watch().caseId ? '#ED1B2E' : '#B0B0B0'
                }
              ]}
              onPress={handleCompleteRevertRequest}
              disabled={refCaseId.current !== watch().caseId}
            >
              <Text
                style={{
                  color: refCaseId.current === watch().caseId ? '#ED1B2E' : '#B0B0B0',
                  fontWeight: '700',
                  fontSize: 15
                }}
              >
                {t('common:Complete')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.Button, { backgroundColor: '#fff' }]} onPress={handleEndRevertRequest}>
              <Text style={{ color: '#ED1B2E', fontWeight: '700', fontSize: 15 }}>{t('requestInfo:End')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  )
}
