import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.PAID_UP)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        requestPaidUpPeriod: Maybe(t.string),
        reason: Maybe(t.string)
      })
    })
  })
)

export const PaidUp = Pulse4Ops
export type PaidUp = t.TypeOf<typeof PaidUp>
