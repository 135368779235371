import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const PremiumChargeC = t.type({
  adjustment: t.string,
  standardPremium: t.number,
  loadingPremium: t.number,
  receivedDate: DateFromISOString
})

export type PremiumCharge = t.TypeOf<typeof PremiumChargeC>
