import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import moment from 'moment'
import { POApi } from '../POApi'
import { getDiffMonths } from '../shared/function'
import { LoanStatementsData, ProductEnum } from './model'
import { SubmissionService } from './SubmissionService'

export namespace LoanStatementsService {
  export const getDetail = (policyNum: string): Task<LoanStatementsData.DetailInfo> =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/policy/billing-change-apl/loan-enquiry`)(
          t.type({
            body: t.type({
              action: Maybe(t.string),
              contractType: Maybe(t.string),
              subfileOccurs: Maybe(
                t.array(
                  t.type({
                    accuredInterest: Maybe(t.number),
                    pendingInterest: Maybe(t.number),
                    currentBalance: Maybe(t.number),
                    loanNumber: Maybe(t.number),
                    loanStartDate: Maybe(t.string),
                    loanType: Maybe(t.string),
                    principalAmount: Maybe(t.number),
                    policyLoanTotal: Maybe(t.number),
                    numCon: Maybe(t.number)
                  })
                )
              )
            })
          })
        )({
          body: {
            contractNumber: policyNum,
            effectiveDate: moment(new Date()).format('yyyyMMDD')
          }
        }),
        SubmissionService.getPolicy(policyNum)
      ),
      //  ZIO.catchAll(() => ZIO.succeed(null))
      ZIO.map(([reponse, policyInfo]) => {
        const clientId = policyInfo.body.owners.id
        const recommandDate = policyInfo.body.riskCommenceDate
        const subfileOccurs = reponse?.body.subfileOccurs ?? []
        const loanEnquiryList = subfileOccurs.map((item) => ({
          loanNumber: item.loanNumber ?? 0,
          loanType: item.loanType ?? ''
        }))
        // filter to get item with oldest loanStartDate
        const customedLoanEqueryList = subfileOccurs.slice().sort(function (item1, item2) {
          return moment(item1.loanStartDate).valueOf() - moment(item2.loanStartDate).valueOf()
        })
        const loanStartDate =
          customedLoanEqueryList && customedLoanEqueryList.length > 0 ? customedLoanEqueryList[0].loanStartDate : ''
        return {
          loanStartDate: loanStartDate ?? '',
          loanEnquiry: loanEnquiryList,
          clientId: clientId ?? '',
          recommandDate: recommandDate ?? ''
        }
      })
    )

  export const accessData = (policyNum: string): Task<LoanStatementsData.AccessData> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policyInfo) => {
        return {
          contractStatus: String(policyInfo.body.status ?? ''),
          paidToDateBasic: policyInfo.body.paidToDateBasic ?? ''
        }
      }),
      ZIO.flatMap((response): Task<LoanStatementsData.AccessData> => {
        if (response.contractStatus === ProductEnum.Status.LA) {
          const currentDate = moment()
          const paidToDateBasic = moment(response.paidToDateBasic)
          const distanceMonth = getDiffMonths(paidToDateBasic, currentDate)
          if (distanceMonth <= 24) {
            return pipe(
              getLapseReinsEnquiry(policyNum),
              ZIO.map((res) => ({
                contractStatus: response.contractStatus,
                lapsedDate: res.lapseDate
              }))
            )
          } else {
            const contractInfo = {
              contractStatus: response.contractStatus,
              lapsedDate: ''
            }
            return pipe(
              contractInfo,
              ZIO.succeed,
              ZIO.map((res) => ({
                contractStatus: res.contractStatus,
                lapsedDate: ''
              }))
            )
          }
        } else {
          const contractInfo = {
            contractStatus: response.contractStatus,
            lapsedDate: ''
          }
          return pipe(
            contractInfo,
            ZIO.succeed,
            ZIO.map((res) => ({
              contractStatus: res.contractStatus,
              lapsedDate: ''
            }))
          )
        }
      })
    )
  export const getLapseReinsEnquiry = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/billing-change-apl/lapse-reins-enquiry`)(
        t.type({
          body: t.type({
            status: t.string,
            lapseDate: Maybe(t.string),
            riskCommenceDate: Maybe(t.string)
          })
        })
      )({
        body: {
          contractNumber: policyNum
        }
      }),
      ZIO.map((lapseEnquiryInfo) => {
        return {
          lapseDate: lapseEnquiryInfo.body.lapseDate ?? '',
          riskCommenceDate: lapseEnquiryInfo.body.riskCommenceDate ?? ''
        }
      })
    )
  // export const submitData = (policyNum: string, data: LoanStatementsData.SubmitData) => (requestauth: RequestAuthFormDataSubmit) => SubmissionService.submit(t.unknown)(
  //     `wf-api/policy/${policyNum}/loan-statement`,
  //     {body: data}, policyNum, requestauth, [])
  // pipe(
  //     PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/loan-statement`)(t.unknown)({
  //         body: data,
  //         otpTransaction: requestauth,
  //         source: SourceType.PULSE4OPS
  //     })
  // )
}
