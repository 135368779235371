export const LetterTemplate = {
  minor: [{ label: 'claim:CompleteClaimDecisionMinor', value: '03' }],
  major: [{ label: 'claim:CompleteClaimDecisionMajor', value: '04' }]
}

export const LetterTypeMinor = {
  claimType: 'CT0300',
  subClaimType: 'CT0301',
  templeteType: 'minor'
}
export const LetterTypeMajor = {
  claimType: '',
  subClaimType: '',
  templeteType: 'major'
}

export enum TemaplateType {
  '03' = 'minor',
  '04' = 'major'
}

export const StatusAcceptTemplate = ['Ex-gratia', 'Accept', 'Accept partial']

export const CodeStatusTemplate = ['DS01', 'DS02', 'DS03']

export enum BenifitClaimCode {
  'ICU' = 'HSA_0002',
  'HS' = 'HSA_0001'
}

export enum ClaimCodeType {
  'CI' = 'CT0100',
  'Death' = 'CT0200',
  'TPD' = 'CT0400',
  'MED' = 'CT0300'
}

export enum PartialPaymentStatus {
  Accept = 'ACCEPT',
  NoAccept = 'NOACCEPT'
}

export enum ReasonCode {
  accept = 'DS01',
  decline = 'DS02',
  acceptPartial = 'DS03',
  Exgratia = 'DS05'
}
