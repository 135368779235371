import * as t from 'io-ts'
import { Nullable } from '@mxt/zio/codec'
import { AmlResultC } from '../../general-info/model/aml-result'
import { UwDecisionCodeC } from './uw-decision-code'

export const EmployeeUwC = t.type({
  uwDecision: Nullable(UwDecisionCodeC),
  employeeKey: t.string,
  employeeName: t.string,
  employeeNo: t.string,
  employeeId: t.string,
  email: t.string,
  employeePhoneNumber: t.string,
  gender: t.string,
  benefitClass: t.string,
  masterContractNo: t.string,
  policyYear: t.number,
  historyAmlResult: t.array(AmlResultC),
  uuid: t.string
})
export type EmployeeUw = t.TypeOf<typeof EmployeeUwC>
