import { pipe } from 'fp-ts/lib/function'
import { Task, ZIO } from '@mxt/zio'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { LoanService } from './LoanService'
import { SubmissionService } from './SubmissionService'
import { BonusSurrenderService } from './BonusSurrenderService'
import {
  Agent,
  AgentInfo,
  AgentInfoDetail,
  BenGender,
  CashOutType,
  CustomerInfo,
  Frequency,
  IFQuotationInfo,
  IFQuotationPrintData,
  InvestmentInfo,
  Locale,
  mapBenGender,
  mapFrequency,
  POLAInfo,
  PolicyInfo,
  PolicyValueInfo,
  ProductEnum,
  ProductExtInfo,
  PruOnlineQuotationData,
  PruOnlineQuotationType
} from './model'
import { POApi } from '../POApi'
import { TaskDetailService } from './TaskDetailService'
import { GeneralService } from './GeneralService'
import { TopUpService } from './TopUpService'
import { CancelRiderService } from './CancelRiderService'
import { PulseOpsFormat } from '../Formatter'
import { PruCashPaymentService } from './PrucashPaymentService'
import { MaturityPaymentService } from './MaturityPaymentService'
import { PartialWithdrawalService } from './PartialWithdrawalService'
import { InquiryComplaintService } from './InquiryComplaintService'
import { AuthService } from '../auth'

export const CustomerRoleEnum = {
  OWNER: 'PolicyOwner',
  MAIN_LA: 'MainLifeAssured',
  LA: 'LifeAssured',
  BEN: 'Beneficiary'
}

export const benClientExt = t.intersection([
  t.type({
    nationalId: t.string,
    name: t.string,
    dob: t.string,
    sex: t.string
  }),
  SubmissionService.benClient
])

export type benClientExt = t.TypeOf<typeof benClientExt>

export const ClientsInfo = t.type({
  laClient: t.array(SubmissionService.ClientC),
  benClient: t.array(benClientExt)
})

export type ClientsInfo = t.TypeOf<typeof ClientsInfo>

export namespace GeneralInfoService {
  const getBonusValue = (policyNum: string): Task<number> =>
    pipe(
      BonusSurrenderService.accountBalance(policyNum),
      ZIO.flatMap(({ amountLERV, validPolicy }) =>
        validPolicy
          ? pipe(
            BonusSurrenderService.bonusSurrenderPolicyLoanEnquiry(
              {
                contractNumber: policyNum,
                bonusReserveValue: 0
              },
              true
            ),
            ZIO.catchAll((x) => ZIO.succeed(null)),
            ZIO.map((v) => v?.body.bonusValue || 0)
          )
          : ZIO.succeed(0)
      )
    )

  const policyHasSpecialNote = (
    policyNum: string
  ): Task<{
    statuses: ('PENDING' | 'IN_PROCESS')[]
    hasSpecialNote: boolean
    warnings: SubmissionService.WarningMessage[]
  }> =>
    pipe(
      ZIO.zipPar(
        TaskDetailService.getOnGoingRequestStatus(policyNum),
        POApi.post(`wf-api/other/client-policy-verify`)(
          t.type({
            body: t.type({
              clientRecOuts: Maybe(t.array(t.type({}))),
              policyRecOuts: Maybe(
                t.array(
                  t.type({
                    isClaim: t.string,
                    isWarning: t.string,
                    policyNum: t.string
                  })
                )
              )
            })
          })
        )({
          body: {
            function: '',
            clientRecs: [{ clientNumber: '', securityNo: '' }],
            policyRecs: [{ policyNum }]
          }
        }),
        SubmissionService.getPolicyWarning(policyNum)
      ),
      ZIO.map(([statuses, verify, warnings]) => ({
        statuses,
        hasSpecialNote: !!verify.body.policyRecOuts?.some((x) => x.isWarning === 'Y'),
        warnings
      }))
    )

  export const getPolicyInfo = (policyNum: string, locale: Locale = Locale.en): Task<PolicyInfo> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<PolicyInfo>{}),
            (clientId) =>
              pipe(
                ZIO.zipPar(
                  SubmissionService.getCustomer(clientId),
                  getClientsInfoData(policyNum),
                  TopUpService.GetPolicyExtraInfo([policyNum], ''),
                  SubmissionService.getPolicies([
                    {
                      policyNo: policyNum,
                      function: 'BILLREVERT'
                    }
                  ]),
                  !!policyNum
                    ? CancelRiderService.getRiderpolicyNumber(policyNum)
                    : CancelRiderService.getRiderInfoList(),
                  GeneralService.getRelationship(),
                  policyHasSpecialNote(policyNum)
                ),
                ZIO.map(
                  ([client, extraClient, policyExt, policyBillRevert, rider, relationships, statuses]): PolicyInfo => {
                    // const mainLA = extraClient.laClient.find((la) => la.clientId === policy.body.mainLifeAssuredNumber)
                    const mainLA = pipe(extraClient.laClient.find((la) => la.clientId === policy.body.mainLifeAssuredNumber),
                      O.fromNullable,
                      O.map((LAInfor) => {
                        return `${LAInfor.clientId} - ${LAInfor.name}`
                      }),
                      O.getOrElse(() => '')
                    )
                    const policyData = policy.body
                    const installmentPremium =
                      (policyData.attributesExt?.basicPremium ?? 0) + (policyData.attributesExt?.riderPremium ?? 0)
                    const mainPremiumCesDate = policyExt.policyExtraInfoDetail.find(
                      (p) => p.lifeNo === '01' && p.riderNo === '00'
                    )
                    const contract = rider.find((r) => r.code === policyData.basicCode)
                    const contractName = contract
                      ? locale === Locale.en
                        ? contract.name
                        : contract.nameVi
                      : policyData.basicCode
                    const getRelationship = (code: string): string => {
                      const r = relationships.find((r) => r.code === code)
                      return r ? (locale === Locale.en ? r.nameEn ?? code : r.nameVn) : code
                    }
                    return {
                      policyOwner: `${client.body.clientId} - ${client.body.name}`,
                      mainLA: mainLA,
                      APLDate: PulseOpsFormat.dateStrWF(policyBillRevert[0]?.paidToDateBasic, 'DD/MM/YYYY'),
                      contractName: contractName ?? '-',
                      policyStatus: policyData.status || '-',
                      premiumStatus: policyData.premiumStatus || '-',
                      sumAssured: PulseOpsFormat.thousandSepartor(mainPremiumCesDate?.sumAssured) + ' VND',
                      installmentPremium: PulseOpsFormat.thousandSepartor(installmentPremium) + ' VND',
                      frequency: mapFrequency.get(policyData.billFreq as Frequency) ?? '-',
                      firstIssuedDate: PulseOpsFormat.dateStrWF(policyData.firstIssueDate, 'DD/MM/YYYY'),
                      policyIssuedDate: PulseOpsFormat.dateStrWF(policyData.lastIssueDate, 'DD/MM/YYYY'),
                      riskCommenceDate: PulseOpsFormat.dateStrWF(policyData.riskCommenceDate, 'DD/MM/YYYY'),
                      paidToDateBasic: PulseOpsFormat.dateStrWF(policyData.paidToDateBasic, 'DD/MM/YYYY'),
                      premiumCessDate: PulseOpsFormat.dateStrWF(mainPremiumCesDate?.premCessDate, 'DD/MM/YYYY'),
                      riskCessDate: PulseOpsFormat.dateStrWF(mainPremiumCesDate?.riskCessDate, 'DD/MM/YYYY'),
                      paidToDateRider: PulseOpsFormat.dateStrWF(policyData.paidToDateRider, 'DD/MM/YYYY'),
                      customerReceiveDate: PulseOpsFormat.dateStrWF(
                        policyData.proposal?.proposalReceivedDate,
                        'DD/MM/YYYY'
                      ),
                      customerReceiveMethod: policyData.dispatchMethod ?? '',
                      bensData: extraClient.benClient.map((x) => ({
                        name: x.name,
                        percentage: x.percent.toString(),
                        relationship: getRelationship(x.relationShipCode)
                      })),
                      statuses: statuses.statuses,
                      hasSpecialNote: statuses.hasSpecialNote,
                      warningList: statuses.warnings,
                      contractNameRaw: {
                        en: contract?.name || '-',
                        vi: contract?.nameVi || '-'
                      }
                    }
                  }
                )
              )
          )
        )
      )
    )

  export const getClientsInfoData = (policyNum: string): Task<ClientsInfo> => {
    return pipe(
      SubmissionService.getClientsByPolicy(policyNum),
      ZIO.flatMap((res) =>
        pipe(
          res,
          O.fromNullable,
          O.filter((c) => !!c),
          O.fold(
            () => ZIO.succeed(<ClientsInfo>{}),
            (clients) =>
              pipe(
                ZIO.zipPar(
                  SubmissionService.getClients(clients.laList.map((l) => ({ clientId: l.clientNumberOfLA }))),
                  SubmissionService.getClients(clients.benList.map((l) => ({ clientId: l.clientNumberOfBen })))
                ),
                ZIO.map(([laClient, benClient]) => {
                  const benList: benClientExt[] = []
                  for (let i = 0; i < clients.benList.length; i++) {
                    benList.push({
                      ...clients.benList[i],
                      nationalId: benClient.body[i].externalIds?.SOE_VALUE ?? '-',
                      name: benClient.body[i].name ?? '-',
                      dob: benClient.body[i].dob ?? '-',
                      sex: benClient.body[i].sex ?? '-'
                    })
                  }
                  return {
                    laClient: laClient.body,
                    benClient: benList
                  }
                })
              )
          )
        )
      )
    )
  }

  export const getCustomerInfo = (policyNum: string): Task<CustomerInfo> =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      // SubmissionService.getOwnerInfoExt(policyNum),
      ZIO.map((o) => o.body),
      ZIO.flatMap((policy) => {
        if (policy.owners?.id) {
          return pipe(
            SubmissionService.getCustomer(policy.owners?.id ?? ''),
            ZIO.flatMap((owner) =>
              pipe(
                ZIO.zipPar(
                  SubmissionService.getCustomer(policy.clientDespatchAddress ?? ''),
                  SubmissionService.checkDuplicatePhone(owner.body.mobilePhone || '-'),
                  getClientsInfoData(policyNum),
                  GeneralService.getCountries
                ),
                ZIO.flatMap(([customerInfo, isNotDuplicatedPhoneNumber, clients, countries]) =>
                  ZIO.effect(() => {
                    const clientsInfo: POLAInfo[] = new Array<POLAInfo>()
                    if (!clients.laClient.find((l) => l.clientId === owner.body.clientId)) {
                      clientsInfo.push({
                        role: CustomerRoleEnum.OWNER,
                        name: owner.body.name ?? '-',
                        nationalId: owner.body.externalIds.SOE_VALUE ?? '-',
                        dob: PulseOpsFormat.dateStrWF(owner.body.dob, 'DD/MM/YYYY'),
                        gender: mapBenGender.get(owner.body.sex as BenGender) ?? '-'
                      })
                    }
                    const ownerExt = owner.body.attributesExt
                    return {
                      // country: Address.mapNational.get(owner.body.nationality) ?? '-',
                      street: customerInfo.body.addressDetails?.PRIMARY?.line1 ?? '',
                      country: countries.find((c) => c.code === customerInfo.body.countryCode)?.name ?? '-',
                      city: customerInfo.body.addressDetails?.PRIMARY?.line4 ?? '',
                      district: customerInfo.body.addressDetails?.PRIMARY?.line3 ?? '',
                      ward: customerInfo.body.addressDetails?.PRIMARY?.line2 ?? '',
                      vip: owner.body.classCode,
                      email: owner.body.email || '-',
                      mobilePhone: PulseOpsFormat.phoneNumber(owner.body.mobilePhone, '84') || '-',
                      companyPhone: PulseOpsFormat.phoneNumber(ownerExt.COMPANY_PHONE_NUMBER, '84') || '-',
                      isDuplicatedPhoneNumber: !isNotDuplicatedPhoneNumber,
                      emailInd: owner.body.customerEmailInd === '1' || owner.body.customerEmailInd === '3',
                      smsInd: owner.body.customerSmsInd === '1' || owner.body.customerSmsInd === '3',
                      po_la_info: clientsInfo
                        .concat(
                          clients.laClient.map((c, i) => ({
                            role: i === 0 ? CustomerRoleEnum.MAIN_LA : CustomerRoleEnum.LA,
                            name: c.name ?? '-',
                            nationalId: c.externalIds?.SOE_VALUE ?? '-',
                            dob: PulseOpsFormat.dateStrWF(c.dob, 'DD/MM/YYYY'),
                            gender: mapBenGender.get(c.sex as BenGender) ?? '-'
                          }))
                        )
                        .concat(
                          clients.benClient.map((c) => ({
                            role: CustomerRoleEnum.BEN,
                            name: c.name,
                            nationalId: c.nationalId ?? '-',
                            dob: PulseOpsFormat.dateStrWF(c.dob, 'DD/MM/YYYY'),
                            gender: mapBenGender.get(c.sex as BenGender) ?? '-'
                          }))
                        )
                    }
                  })
                )
              )
            )
          )
        }
        return ZIO.unit

      }


      )
    )

  export const getProductInfo = (policyNum: string, locale: string = 'en'): Task<ProductExtInfo> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        SubmissionService.getClientsByPolicy(policyNum),
        TopUpService.GetPolicyExtraInfo([policyNum], ''),
        !!policyNum ? CancelRiderService.getRiderpolicyNumber(policyNum) : CancelRiderService.getRiderInfoList()
      ),
      ZIO.flatMap(([policy, clients, policyExtra, rider]) =>
        pipe(
          clients,
          O.fromNullable,
          O.filter((c) => !!c),
          O.fold(
            () => ZIO.succeed(<ProductExtInfo>{}),
            (clients) =>
              pipe(
                SubmissionService.getClients(clients.laList.map((l) => ({ clientId: l.clientNumberOfLA }))),
                ZIO.map((laClient) => {
                  const productExtInfo: ProductExtInfo = {
                    owner: null,
                    laClient: []
                  }

                  const getContractName = (basicCode: string) => {
                    const contract = rider.find((r) => r.code === basicCode)
                    return contract ? (locale === Locale.en ? contract.name : contract.nameVi) : basicCode
                  }

                  clients.laList.forEach((l) => {
                    const clientInfo = laClient.body.find((cl) => cl.clientId === l.clientNumberOfLA)
                    const productInfo = policyExtra.policyExtraInfoDetail
                      .filter((p) => p.lifeNo === l.life)
                      .map((x) => ({
                        componentName: x.productCode + '-' + getContractName(x.productCode),
                        status: x.riderStatus,
                        paidToDate: PulseOpsFormat.dateStrWF(
                          x.riderNo === '00' && x.coverageNo === '01'
                            ? policy.body.paidToDateBasic
                            : policy.body.paidToDateRider,
                          'DD/MM/yyyy'
                        ),
                        sumAssured: PulseOpsFormat.thousandSepartor(x.sumAssured),
                        installmentPremium: PulseOpsFormat.thousandSepartor(x.installmentPremium),
                        premiumCessationDate: PulseOpsFormat.dateStrWF(x.premCessDate, 'DD/MM/yyyy')
                      }))
                    if (clientInfo && productInfo.length) {
                      if (l.life === '01') {
                        productExtInfo.owner = {
                          name: clientInfo.name,
                          life: l.life,
                          extInfos: productInfo
                        }
                      } else {
                        productExtInfo.laClient.push({
                          name: clientInfo.name,
                          life: l.life,
                          extInfos: productInfo
                        })
                      }
                    }
                  })
                  return productExtInfo
                })
              )
          )
        )
      )
    )

  export const getPolicyValue = (policyNum: string): Task<PolicyValueInfo> =>
    pipe(
      ZIO.zipPar(
        LoanService.registerLoanEnquiry(policyNum),
        PruCashPaymentService.getBenefit(policyNum),
        SubmissionService.getLoanEnquiry(policyNum),
        MaturityPaymentService.getCashoutMaturity(policyNum),
        SubmissionService.getPrucashDetail(policyNum),
        SubmissionService.getAccountBalance(policyNum, 'TOTAL', [
          { sacsCode: 'LP', sacsType: 'SB' },
          { sacsCode: 'LI', sacsType: 'MO' },
          { sacsCode: 'LX', sacsType: 'TF' },
          { sacsCode: 'LP', sacsType: 'PS' }
        ]),
        getBonusValue(policyNum)
      ),
      ZIO.flatMap(([loanEnquiry, pruCashBen, loanEnquiry1, maturityBen, prucashDetail, accBalance, bonusValue]) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getAccountBalance(policyNum, 'TOTAL', [{ sacsCode: 'LX', sacsType: 'EX' }]),
            SubmissionService.getAccountBalance(policyNum, 'TV9S', [{ sacsCode: 'LE', sacsType: 'RV' }]),
            SubmissionService.getAccountBalance(policyNum, 'TOTAL', [
              { sacsCode: 'LP', sacsType: 'S' },
              { sacsCode: 'LF', sacsType: 'WF' },
              { sacsCode: 'LP', sacsType: 'U' }
            ]),
            PartialWithdrawalService.getMaxPW(policyNum)
          ),
          ZIO.map(([prukit, bonusSurrender, suspAmt, maximumPartialWithdrawal]) => {
            const prucashBen = pruCashBen.body.totalCashBenefit
            const APL = loanEnquiry1.subfileOccurs.reduce(
              (sum, x) => (x.loanType === 'A' ? sum + x.policyLoanTotal : sum),
              0
            )
            const OPL = loanEnquiry1.subfileOccurs.reduce(
              (sum, x) => (x.loanType === 'P' ? sum + x.policyLoanTotal : sum),
              0
            )
            const maturityBenefit =
              maturityBen.body?.cashOutOptions?.find((e) => e.type === CashOutType.MATURITYBENEFIT)?.paymentOption
                ?.amount ?? 0
            return {
              APL: PulseOpsFormat.thousandSepartor(APL) + ' VND',
              OPL: PulseOpsFormat.thousandSepartor(OPL) + ' VND',
              surrVal: PulseOpsFormat.thousandSepartor(loanEnquiry.contractSurrenderValue1) + ' VND',
              loanAvalable: PulseOpsFormat.thousandSepartor(loanEnquiry.loanAmountAvailable1) + ' VND',
              loan80:
                PulseOpsFormat.thousandSepartor(pruCashBen.body.attributesExt?.LOAN_EXCEED_80_SURRENDER_VALUE) + ' VND',
              bonusVal: PulseOpsFormat.thousandSepartor(bonusValue) + ' VND',
              specialBonus:
                PulseOpsFormat.thousandSepartor(
                  accBalance.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0
                ) + ' VND',
              bonusSurr:
                PulseOpsFormat.thousandSepartor(
                  bonusSurrender.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0
                ) + ' VND',
              suspAmt:
                PulseOpsFormat.thousandSepartor(
                  suspAmt.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0
                ) + ' VND',
              prucashBen: PulseOpsFormat.thousandSepartor(prucashBen) + ' VND',
              PruKid:
                PulseOpsFormat.thousandSepartor(prukit.body.accountGroups?.reduce((sum, x) => sum + x.amount, 0) || 0) +
                ' VND',
              maturityBen: PulseOpsFormat.thousandSepartor(maturityBenefit) + ' VND',
              maxPwAmt: maximumPartialWithdrawal
                ? PulseOpsFormat.thousandSepartor(maximumPartialWithdrawal) + ' VND'
                : '-',
              apl_opl_detail: loanEnquiry1.subfileOccurs.map((x) => ({
                loanDesc: x.loanType === 'A' ? 'APL' : x.loanType === 'P' ? 'OPL' : '-',
                transDate: x.loanStartDate,
                principalAmount: PulseOpsFormat.thousandSepartor(x.principalAmount),
                currBalance: PulseOpsFormat.thousandSepartor(x.currentBalance),
                accuredInterest: PulseOpsFormat.thousandSepartor(x.accuredInterest),
                pendingInterest: PulseOpsFormat.thousandSepartor(x.pendingInterest),
                loanNumber: x.loanNumber
              })),
              prucash_ben: prucashDetail.subfileOccurs.map((p) => ({
                cashReleaseDate: PulseOpsFormat.dateStrWF(p.loanStartDate, 'DD/MM/YYYY'),
                principalAmount: PulseOpsFormat.thousandSepartor(p.principalAmount),
                interestAmount: PulseOpsFormat.thousandSepartor(
                  p.pendingInterest + p.accuredInterest < 0
                    ? 0 - (p.pendingInterest + p.accuredInterest)
                    : p.pendingInterest + p.accuredInterest
                ),
                totalPerPeriod: PulseOpsFormat.thousandSepartor(
                  p.policyLoanTotal < 0 ? 0 - p.policyLoanTotal : p.policyLoanTotal
                ),
                receivedDate: p.receivedDate ? PulseOpsFormat.dateStrWF(p.receivedDate, 'DD/MM/YYYY') : '-',
                receiveAmount: PulseOpsFormat.thousandSepartor(p.receivedAmount ?? 0)
              }))
            }
          })
        )
      )
    )

  export const getInvestmentInfo = (policyNum: string): Task<InvestmentInfo> =>
    pipe(
      ZIO.zipPar(
        TopUpService.GetPolicyExtraInfo([policyNum], ''),
        TopUpService.getDetail(policyNum),
        TopUpService.GetPolicyExtraInfo([policyNum], 'PARTIAL')
      ),
      ZIO.map(([policyExt, topupInfo, partialWD]) => {
        const investmentInfo: InvestmentInfo = {}
        if (topupInfo.coverageCode.startsWith('U')) {
          investmentInfo.ilp = {
            fundValue: policyExt.policyExtraInfoFund.map((f) => ({
              fundName: f.fundCode,
              // unitBalance: PulseOpsFormat.thousandSepartor(f.unitBalance),
              unitBalance: f.unitBalance ? f.unitBalance.toString().replace(/(\d)(?=(?:[0-9]{3})+\b)/g, '$1,') : '0',
              estimatedValue: PulseOpsFormat.thousandSepartor(Number(f.estimateValue.toFixed(0)))
            })),
            partialWithdrawalAmount: partialWD.policyExtraInfoFund
              .filter((f) => f.estimateValue > 0)
              .map((f) => ({
                fundName: f.fundCode,
                partialWithdrawal: PulseOpsFormat.thousandSepartor(f.estimateValue)
              }))
          }
        } else {
          investmentInfo.ulp = {
            totalEstimateValue: PulseOpsFormat.thousandSepartor(
              policyExt.policyExtraInfoFund.reduce((acc, curr) => acc + curr.estimateValue, 0)
            ),
            ilpPartialWithdrawal: PulseOpsFormat.thousandSepartor(
              partialWD.policyExtraInfoFund.reduce((acc, curr) => acc + curr.estimateValue, 0)
            )
          }
        }
        return investmentInfo
      })
    )

  export const getAgentInfo = (policyNum: string): Task<AgentInfo> =>
    pipe(
      SubmissionService.getAgentInfo(policyNum),
      ZIO.map((agent) => {
        const agentInfo: AgentInfo = {
          agentInfo: <AgentInfoDetail>{},
          oldAgentInfo: []
        }
        const getAgent = (team: SubmissionService.AgentTeam): Agent => ({
          name: team.name ? `${team.code ? team.code + '   ' + team.name : team.name}` : '-',
          mobilePhone: PulseOpsFormat.phoneNumber(
            team.contactDetails?.mobile.value,
            team.contactDetails?.mobile.countryCode
          )
        })

        agent.teams.forEach((team) => {
          switch (team.type) {
            case 'servicing':
              agentInfo.agentInfo.servicingAgent = getAgent(team)
              break
            case 'sharing':
              agentInfo.agentInfo.sharingAgent = getAgent(team)
              break
            case 'unit manager':
              agentInfo.agentInfo.unitManager = {
                ...getAgent(team),
                officeData: `${agent.office} ${agent.attributes.officeName}`
              }
              break
            case 'branch manager':
              agentInfo.agentInfo.branchManager = {
                ...getAgent(team),
                gaData: `${agent.attributes.gACode} ${agent.attributes.gAName}`
              }
              break
            case 'business development manager':
              agentInfo.agentInfo.businessDevManager = {
                ...getAgent(team),
                regionCode: agent.attributes.regionCode
              }
              break
            case 'old':
              agentInfo.oldAgentInfo.push({
                ...getAgent(team),
                terminateDate: PulseOpsFormat.dateStrWF(team.terminationDate, 'DD/MM/YYYY')
              })
              break
          }
        })

        return agentInfo
      })
    )

  export const getIfQuotationInfo = (policyNum: string): Task<IFQuotationInfo> =>
    pipe(
      ZIO.zipPar(
        CancelRiderService.getClientRiderInfo(policyNum),
        TopUpService.GetPolicyExtraInfo([policyNum], ''),
        CancelRiderService.getRiderInfoList(),
        InquiryComplaintService.getSources(),
        SubmissionService.getOwnerInfo(policyNum),
        getIFQuotationDataFromPruOnline(policyNum)
        // checkIFQuotationRule(policyNum)
      ),
      ZIO.map(([LifeAssuredList, policyExtraInfo, riderNameList, sourceList, ownerInfo, ifQuotationInfo]) => {
        let mainRiskCessDate = ''
        const poCMND = ownerInfo.body.externalIds.SOE_VALUE ?? ''
        //get riskCessDate of main product
        const mainProductItem = policyExtraInfo.policyExtraInfoDetail.find(
          (x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00'
        )?.riskCessDate
        mainRiskCessDate = mainProductItem ?? ''
        // console.log(`IFQuotationData : ${JSON.stringify(ifQuotationInfo)}`)
        const customedLAList = LifeAssuredList.map((lifeItem) => {
          const filteredRiderList = policyExtraInfo.policyExtraInfoDetail
            .filter(
              (x) =>
                x.lifeNo === lifeItem.life &&
                ((x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00') ||
                  x.riderStatus === ProductEnum.Status.IF)
            )
            .map((riderItem) => {
              return {
                productCode: riderItem.productCode,
                productName: '',
                riskCommDate: riderItem.riskCommDate,
                riskCessDate: riderItem.riskCessDate,
                lapsedDate: '',
                sumAssured: riderItem.sumAssured,
                installmentPrem: riderItem.installmentPremium,
                newInstallmentPremium: 0,
                lifeNo: riderItem.lifeNo,
                coverageNo: riderItem.coverageNo,
                riderNo: riderItem.riderNo,
                riderStatus: riderItem.riderStatus
              }
            })
          return {
            clientName: lifeItem.clientName,
            lifeAssured: lifeItem.clientNumberOfLA,
            extraRider: [],
            riders: filteredRiderList
          }
        })
        return {
          LAList: customedLAList.filter((x) => x.riders && x.riders.length > 0),
          riderNameList: riderNameList,
          sourceList: sourceList,
          mainRiskCessDate: mainRiskCessDate,
          poName: ownerInfo.body.name,
          poCMND: poCMND,
          pruOnlineQuotationData: ifQuotationInfo
        }
      })
    )

  export const getIFQuotationDataFromPruOnline = (policyNum: string): Task<PruOnlineQuotationData> =>
    pipe(
      POApi.get(`wf-api/if-quotation/get-data/${policyNum}`)(PruOnlineQuotationType),
      ZIO.map((responseData) => {
        return responseData.result
      })
    )

  export const checkIFQuotationRule = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/if-quotation/check-policy/${policyNum}`)(
        t.type({
          status: Maybe(t.string),
          tranDate: t.string,
          result: t.boolean
        })
      ),
      // ZIO.map((response) => response.result)
      ZIO.foldM(
        (error) => ZIO.succeed(false),
        (success) => ZIO.effect(() => success.result)
      )
    )

  export const printDocument = (printData: IFQuotationPrintData) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`document-generation/api/v1/generate-service/if-quotation-generate-document`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify({ body: printData })
          })
        })
      )
    )

  export const checkIFActuary = (policyNum: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/dao/check-invalid-surrender-value/${policyNum}`)(Maybe(t.boolean)),
      ZIO.map((responseData) => {
        return responseData ?? false
      })
    )
}
