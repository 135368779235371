import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { CashoutOptions } from './CashoutOptions'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CANCEL_FROM_INCEPTION)
    }),
    codec
  ])

const oldPayload = t.type({
  installmentPremium: Maybe(t.string),
  medicalFee: Maybe(t.string),
  reason: Maybe(t.string),
  // totalPayoutAmount: Maybe(t.string),
  // poClientNum: Maybe(t.string),
  // suspendAmount: t.string,
  policiesReceived: Maybe(
    t.array(
      t.type({
        payoutOptionType: Maybe(t.string),
        amount: Maybe(t.string),
        bankAccountNumber: Maybe(t.string),
        bankAccountName: Maybe(t.string),
        bankName: Maybe(t.string),
        bankBranch: Maybe(t.string),
        policyReceivedNum: Maybe(t.string),
        bankKey: Maybe(t.string)
      })
    )
  )
})

const newPayload = t.type({
  policy: Maybe(
    t.type({
      policyNo: Maybe(t.string)
    })
  ),
  attributesExt: Maybe(
    t.type({
      INSTALLMENT_PREMIUM: Maybe(t.number),
      MEDICAL_FEE: Maybe(t.number),
      REASON: Maybe(t.string),
      TOTAL_PAYOUT_AMOUNT: Maybe(t.number)
    })
  ),
  cashOutOptions: Maybe(CashoutOptions)
})

// export const CancelFromInception = Base(
//   t.type({
//     payload: t.type({
//       body: t.intersection([Maybe(oldPayload), Maybe(newPayload)])
//     })
//   })
// )

// export const CancelFromInception = t.type({
//   transactionType: t.literal(TransactionType.CANCEL_FROM_INCEPTION),
//   payload: t.type({
//     body: t.type({
//       installmentPremium: t.string,
//       medicalFee: t.string,
//       reason: t.string,
//       // totalPayoutAmount: Maybe(t.string),
//       // poClientNum: Maybe(t.string),
//       // suspendAmount: t.string,
//       policiesReceived: t.array(
//         t.type({
//           payoutOptionType: t.string,
//           amount: t.string,
//           bankAccountNumber: Maybe(t.string),
//           bankAccountName: Maybe(t.string),
//           bankName: Maybe(t.string),
//           bankBranch: Maybe(t.string),
//           policyReceivedNum: Maybe(t.string),
//           bankKey: Maybe(t.string)
//         })
//       )
//     })
//   })
// })

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.intersection([Maybe(oldPayload), Maybe(newPayload)])
    })
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: t.type({
      body: t.intersection([Maybe(oldPayload), Maybe(newPayload)])
    })
  })
)

export const CancelFromInception = t.union([PruOnline, Pulse4Ops])
export type CancelFromInception = t.TypeOf<typeof CancelFromInception>
