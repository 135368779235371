/* eslint-disable no-extra-boolean-cast */
/* eslint-disable prefer-const */
import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Input, SelectSearch, TaskDetail, ErrorHandling, SelectOption } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { GeneralForm } from './da-genneral-form'
import { assets } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { DaGeneralInfoService } from './da-genneral-info-service'

type Props = {
  detail: TaskDetail.AgentOnboardNew
  form: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'>
  provinceData: Array<DaGeneralInfoService.ProvinceType>
  agentGeneralData: DaGeneralInfoService.GeneralInfoLogRqData | null | undefined
}
export const AllocatedAddress = React.memo((props: Props) => {
  const { t, i18n } = useTranslation('RequestInfo')
  const [provinceOptions, setProvinceOptions] = React.useState<SelectOption[]>([])
  const [districOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])
  const { control, setValue, getValues, watch } = props.form
  const { detail, provinceData, agentGeneralData } = props
  const provinceCode = watch('ProvinceAllocated.value')
  const districtCode = watch('DistrictAllocated.value')
  // const wardCode = watch('WardAllocated.value')
  const [wardCode, setWardCode] = useState<string>('')
  const [locatedCode, setLocatedCode] = useState<string | null>('')
  // const codeWard = detail.Allocatedprovince! + detail.Allocateddistrict + detail.Allocatedward
  React.useEffect(() => {
    provinceData &&
      provinceData.length &&
      pipe(
        ZIO.zipPar(ZIO.succeed(detail), ZIO.succeed(provinceData)),
        ZIO.flatMap(([detail, provinces]) => {
          const provinceOptions = provinces.map((province) => {
            return {
              label: province.provinceCombine,
              value: province.provinceCode
            }
          })

          setProvinceOptions(provinceOptions)
          const chooseProvince = provinceOptions.find(
            (value) => value.value === getValues('ProvinceAllocated.value') || value.value === detail.Allocatedprovince
          )
          // const chooseProvince = provinceOptions.find(
          //   (value) => value.value === getValues('address.province.value') || value.value === detail.province
          // )
          return pipe(
            agentGeneralData === null
              ? DaGeneralInfoService.getDistricts(detail.Allocatedprovince ?? '')
              : DaGeneralInfoService.getDistricts(getValues('ProvinceAllocated.value')),
            ZIO.flatMap((districts) => {
              const districtOptions = districts.map((district) => {
                return {
                  label: district.districtCombine,
                  value: district.districtCode
                }
              })
              const chooseDistrict = districtOptions.find(
                (value) =>
                  value.value === getValues('DistrictAllocated.value') || value.value === detail.Allocateddistrict
              )
              setDistrictOptions(districtOptions)
              return pipe(
                agentGeneralData === null
                  ? DaGeneralInfoService.getWards({
                      provinceCode: detail.Allocatedprovince ?? '',
                      districtCode: detail.Allocateddistrict ?? ''
                    })
                  : DaGeneralInfoService.getWards({
                      provinceCode: getValues('ProvinceAllocated.value'),
                      districtCode: getValues('DistrictAllocated.value')
                    }),
                ZIO.map((wards) => {
                  const wardOptions = wards.map((ward) => {
                    return {
                      label: ward.wardCombine,
                      value: ward.wardCode
                    }
                  })
                  const chooseWards = wardOptions.find(
                    (value) => value.value === getValues('WardAllocated.value') || value.value === detail.Allocatedward
                  )

                  setWardOptions(wardOptions)
                  setValue('StreetAllocated', getValues('StreetAllocated') || detail.Allocatedaddress || '')
                  setValue(
                    'ProvinceAllocated',
                    getValues('ProvinceAllocated') || chooseProvince || { label: '', value: '' }
                  )
                  setValue(
                    'DistrictAllocated',
                    getValues('DistrictAllocated') || chooseDistrict || { label: '', value: '' }
                  )
                  setValue('WardAllocated', getValues('WardAllocated') || chooseWards || { label: '', value: '' })
                  return ZIO.unit
                })
              )
            })
          )
        }),
        ZIO.tapError((_) => {
          return ZIO.unit
        }),
        ErrorHandling.run({})
      )
  }, [provinceData, agentGeneralData])
  const getDistricts = (provinceCode: string) =>
    pipe(
      DaGeneralInfoService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.districtCode,
            label: i18n.language === 'vi' ? district.districtCombine : district.districtCombine
          }
        })
        setDistrictOptions(districtOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const getWards = (provinceCode: string, districtCode: string) =>
    pipe(
      DaGeneralInfoService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.wardCode,
            label: i18n.language === 'vi' ? ward.wardCombine : ward.wardCombine
          }
        })
        setWardOptions(wardOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  React.useEffect(() => {
    if (!!provinceCode || !!districtCode || !!watch('WardAllocated.value')) {
      let code = provinceCode + districtCode + watch('WardAllocated.value')
      setLocatedCode(code)
    } else {
      setLocatedCode(null)
    }
  }, [provinceCode, districtCode, wardCode, watch('WardAllocated.value'), agentGeneralData])
  React.useEffect(() => {
    if (agentGeneralData === null) {
      setLocatedCode(detail.AllocatedlocationCode ?? '')
    }
  }, [agentGeneralData])
  React.useEffect(() => {
    if (!!locatedCode || !locatedCode) {
      setValue('AllocatedlocationCode', locatedCode)
    }
  }, [locatedCode, agentGeneralData])
  return (
    <View style={styles.containers}>
      <View style={styles.row}>
        <View style={styles.col}>
          <Text
            style={{
              fontSize: 15,
              fontWeight: '800',
              color: '#4F4F4F',
              paddingBottom: 22
            }}
          >
            {t('AllocatedAddress')}
          </Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col}>
          <Controller
            name="ProvinceAllocated"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('ProvinceAllocated') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                // required={true}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('ProvinceAllocated')}
                options={provinceOptions}
                value={value}
                onBlur={onBlur}
                onChange={(value) => {
                  onChange(value)
                  // setValue('ProvinceAllocated', { label: '', value: '' })
                  setValue('DistrictAllocated', { label: '', value: '' })
                  setValue('WardAllocated', { label: '', value: '' })
                  getDistricts(value?.value ?? '')
                  setWardCode('')
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                // key={value}
              />
            )}
          />
        </View>

        <View style={styles.cols}>
          <Controller
            name="DistrictAllocated"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('DistrictAllocated') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                // required={true}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('DistrictAllocated')}
                options={districOptions}
                value={value}
                onBlur={onBlur}
                onChange={(value) => {
                  onChange(value)
                  setValue('WardAllocated', { label: '', value: '' })
                  getWards(provinceCode, value?.value ?? '')
                  setWardCode('')
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                // key={value}
              />
            )}
          />
        </View>
        <View style={styles.col}>
          <Controller
            name="WardAllocated"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020009', { field: t('WardAllocated') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                // required={true}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('WardAllocated')}
                options={wardOptions}
                value={value}
                onBlur={onBlur}
                onChange={(value) => {
                  setValue('WardAllocated', value)
                  onChange(value)
                  setWardCode(value?.value ?? '')
                }}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                // key={value}
              />
            )}
          />
        </View>
      </View>
      <View style={{ flex: 1, paddingVertical: 24 }}>
        <View style={styles.row}>
          <View style={styles.col}>
            <Controller
              name="StreetAllocated"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('StreetAllocated')}
                  disabled={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value == null ? '' : value}
                  errorMessage={error?.message}
                  maxLength={100}
                  // required={true}
                />
              )}
            />
          </View>

          <View style={styles.cols}>
            <Input title={t('LocationcodeAllocated')} value={locatedCode || '-'} disabled />
          </View>
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    marginBottom: 16
  },
  containers: {
    width: '100%',
    padding: 30,
    borderRadius: 8,
    marginBottom: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6'
  },
  col: {
    width: '33.33333333333%'
  },
  cols: {
    width: '33.33333333333%',
    paddingHorizontal: 30
  },
  row: {
    flexDirection: 'row'
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' }
})
