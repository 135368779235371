import { ZIO } from '@mxt/zio'
import {
  AddLackOfPayeeService,
  AppContext,
  assets,
  AuthService,
  ErrorHandling,
  GeneralService,
  Panel,
  PaymentType,
  PulseOpsService,
  SelectOption,
  SelectSearch,
  SubTasksResponse,
  SubTaskType,
  TaskDetail,
  TaskService,
  TaskType
} from '@pulseops/common'
import { useFocusEffect } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import * as AddLackOfPayeeForm from './add-lack-of-payee-form'
import moment from 'moment'
interface Props {
  form: Omit<UseFormReturn<AddLackOfPayeeForm.Form>, 'handleSubmit'>
  policyNum: string
  setDefaultPayeeIsNotPO: (inforPo: {
    payeeNotPO: boolean
    bankAccountName: string
    bankIdNumber: string
    dob: Date | null
  }) => void
}

type Option = {
  label: string
  value: string
  claimNumber?: string | null
}

export const ClaimInfo: React.FC<Props> = ({ form, policyNum, setDefaultPayeeIsNotPO }) => {
  const [caseIds, setCaseIds] = React.useState<Option[]>([])
  const { t, i18n } = useTranslation()

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const { watch, setValue, clearErrors } = form

  const paymentMethods = [
    {
      label: t('BankTransfer'),
      value: PaymentType.BANKTRANSFER
    },
    {
      label: t('CashAtTheCounter'),
      value: PaymentType.CASH_AT_COUNTER
    },
    {
      label: t('PayAtBank'),
      value: PaymentType.PAID_AT_BANK
    }
  ]

  useFocusEffect(
    React.useCallback(() => {
      pipe(
        AddLackOfPayeeService.getListCaseId(policyNum),
        ZIO.map((res) => {
          let options: Option[] = []
          options = res.data.map(({ caseId, claimNumber }) => ({
            label: caseId,
            value: caseId,
            claimNumber: claimNumber
          }))
          setCaseIds(options)
        }),
        ErrorHandling.run()
      )
    }, [])
  )

  const relationships =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) => {
        const relaList: SelectOption[] =
          r.map((item) => ({
            value: item.code,
            // label: `${item.code} - ${i18n.language === 'vi' ? item.nameVn : item.nameEn}`
            label: `${item.code} - ${i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN}`
          })) || []
        return relaList
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const countries =
    pipe(
      GeneralService.getCountries,
      ZIO.map((c) => {
        const countryList: SelectOption[] =
          c.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        return countryList
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const occupations =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((oc) => {
        const ocupationList: SelectOption[] =
          oc.map((item) => ({
            value: item.code,
            label: item.name
          })) || []

        return ocupationList
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const officeCodeLoad =
    pipe(
      GeneralService.getOfficeCodes('CSC'),
      ZIO.map((codes) => {
        return codes.map((item) => ({
          value: item.code,
          label: `${item.code} - ${i18n.language === 'vi' ? item.nameVi : item.nameEn}`
        }))
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const onChangeCaseId = (e: Option | null) => {
    if (!!e) {
      setValue('suspendType', { value: 'SCL17', label: t('claim:SCL17') })
      setValue('claimNumber', e.claimNumber || '')
      showGlobalLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.flatMap(({ email }) =>
          TaskService.queryTasks({
            suspended: true,
            isTeam: true,
            isFilterTeam: false,
            order: 'desc',
            active: true,
            filter: { caseId: e.value },
            taskCategory: {
              task: TaskType.Claim,
              subTask: SubTaskType.Suspend
            },
            category: TaskType.Claim,
            pagination: { pageIndex: 0, pageSize: 10 }
          })
        ),
        ZIO.flatMap((res) => {
          return pipe(
            PulseOpsService.getTaskDetail(res.items[0]?.id || '', SubTasksResponse.CL_SUSPEND),
            ZIO.tap((value) => {
              const task = value?.payload as TaskDetail.VerificationClaim
              const payment = task.PAYMENT
              setDefaultPayeeIsNotPO({
                payeeNotPO: payment.isPayeeNotPO ?? false,
                bankAccountName: payment?.bankTransfer?.accountName ?? '',
                bankIdNumber: payment.bankTransfer?.idNumber ?? '',
                dob: payment.dob ? moment(payment.dob).toDate() && moment(payment.dob, 'DD/MM/YYYY').toDate() : null
              })
              form.reset({
                ...form.getValues(),
                paymentMode: paymentMethods.find((item) => item.value === payment.paymentMethod),
                payeeIsNotPolicyOwner: payment.isPayeeNotPO ?? false,
                address: payment.address ?? '',
                foreignAddress: payment.foreignAddress ?? '',
                relationshipWithPO: relationships.find((rela) => rela.value === payment.relationshipWithPO) || null,
                dob: payment.dob ? moment(payment.dob).toDate() && moment(payment.dob, 'DD/MM/YYYY').toDate() : null,
                occupation: occupations.find((cond) => cond.value === payment?.occupation) || null,
                nationality: countries.find((cond) => cond.value === payment?.nationality) || null,
                nationality2: countries.find((cond) => cond.value === payment?.nationality2) || null,
                residenceCountry: countries.find((cond) => cond.value === payment?.residenceCountry) || null,
                claimType: task.claimType ?? '',
                gender:
                  [
                    {
                      value: 'F',
                      label: t('common:Female')
                    },
                    {
                      value: 'M',
                      label: t('common:Male')
                    }
                  ].find((cond) => cond.value === payment?.gender) || null
              })

              switch (payment.paymentMethod) {
                case PaymentType.BANKTRANSFER:
                  form.reset({
                    ...form.getValues(),
                    bankAccountName: payment?.bankTransfer?.accountName ?? '',
                    bankAccountNumber: payment?.bankTransfer?.accountNumber?.toString() ?? undefined,
                    bank:
                      payment.bankTransfer?.bank && payment.bankTransfer?.bankName
                        ? { value: payment.bankTransfer?.bank, label: payment.bankTransfer?.bankName }
                        : null,
                    bankBranch:
                      payment.bankTransfer?.branch && payment.bankTransfer?.branchName
                        ? { value: payment.bankTransfer?.branch, label: payment.bankTransfer?.branchName }
                        : null,
                    bankIdNumber: payment.bankTransfer?.idNumber
                  })
                  break
                case PaymentType.PAID_AT_BANK:
                  form.reset({
                    ...form.getValues(),
                    payeeName: payment?.payAtBank?.payeeName ?? '',
                    bank:
                      payment.payAtBank?.bank && payment.payAtBank?.bankName
                        ? { value: payment.payAtBank?.bank, label: payment.payAtBank?.bankName }
                        : null,
                    bankBranch:
                      payment.payAtBank?.branch && payment.payAtBank?.branchName
                        ? { value: payment.payAtBank?.branch, label: payment.payAtBank?.branchName }
                        : null,
                    payeeId: payment.payAtBank?.idNumber ?? '',
                    issuedBy: payment.payAtBank?.issuedBy,
                    issuedDate: payment.payAtBank?.issuedDate ? new Date(payment.payAtBank?.issuedDate) : null
                  })
                  break
                case PaymentType.CASH_AT_COUNTER:
                  form.reset({
                    ...form.getValues(),
                    payeeName: payment?.payAtCounter?.payeeName || '',
                    gaOffice: officeCodeLoad.find((cond) => cond.value === payment?.payAtCounter?.gaOffice) || null,
                    payeeId: payment.payAtCounter?.idNumber ?? ''
                  })
                  break
              }

              showGlobalLoading(false)
              return ZIO.unit
            })
          )
          // return res
        }),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.unsafeRun({})
      )
    } else {
      form.reset({
        caseId: null,
        suspendType: null,
        claimNumber: '',
        paymentMode: null,
        payeeIsNotPolicyOwner: false,
        bankIdNumber: '',
        bankAccountName: '',
        bankAccountNumber: '',
        address: '',
        bank: null,
        bankBranch: null,
        dob: null,
        foreignAddress: '',
        gaOffice: null,
        gender: null,
        issuedBy: '',
        issuedDate: null,
        nationality: null,
        nationality2: null,
        occupation: null,
        payeeId: '',
        payeeName: '',
        relationshipWithPO: null,
        residenceCountry: null,
        documentType: null
      })
      clearErrors('suspendType')
    }
  }

  return (
    <Panel title="" titleOutline={t('submission:ClaimInfo')} isExand={false}>
      <View style={style.section}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <View style={{ width: '33.3333333%', paddingRight: 10 }}>
            <Controller
              name="caseId"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('submission:CaseId') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  required
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('submission:CaseId')}
                  options={caseIds}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onChangeCaseId(e)
                  }}
                  placeholder={t('common:Select')}
                  errorMessage={error?.message}
                  key={value?.value}
                />
              )}
            />
          </View>
          <View style={{ width: '33.3333333%', paddingRight: 10 }}>
            <Controller
              name="suspendType"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('submission:SuspendType') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  required
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('submission:SuspendType')}
                  options={[{ value: 'SCL17', label: t('claim:SCL17') }]}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('common:Select')}
                  errorMessage={error?.message}
                  key={value?.value}
                />
              )}
            />
          </View>
          {!!watch('claimNumber') && (
            <View style={{ width: '33.3333333%' }}>
              <View style={[style.row, style.mB5]}>
                <Text style={[style.mR5, style.label]}>{t('submission:ClaimNumber')}</Text>
              </View>
              <Text style={style.text}>{watch('claimNumber')}</Text>
            </View>
          )}
        </View>
      </View>
    </Panel>
  )
}

const style = StyleSheet.create({
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  section: {
    marginBottom: 30
  },
  currencyText: {
    fontStyle: 'italic'
  },
  titleSectionText: {
    fontWeight: '700',
    marginVertical: 10,
    marginTop: 30,
    textTransform: 'uppercase',
    fontSize: 16
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  row: {
    flexDirection: 'row'
  },
  mB5: {
    marginBottom: 15
  },
  mR5: {
    marginRight: 5
  },
  text: {
    fontSize: 15
  }
})
