import * as React from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'

interface ErrorProps {
  message?: string
  style?: StyleProp<TextStyle>
}

export const Error = ({ message, style }: ErrorProps) => {
  return message ? <Text style={[{ fontSize: 11.25, color: '#ED1B2C' }, style]}>{message}</Text> : null
}
