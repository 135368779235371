import { POApi } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { Maybe } from '@mxt/zio/codec'
import { ZIO } from '@mxt/zio'

export namespace OBCallingResultDetailService {
  export const OBQuestionTableInfo = t.type({
    category: t.string,
    // callProgramCode: t.string,
    questionCode: t.string,
    questionDescEn: t.string,
    questionDescVN: t.string,
    questionOrder: t.number,
    transactionTypeWF: Maybe(t.string)
  })

  export const OBQuestionAnswerInfo = t.type({
    questionCode: t.string,
    answerCode: t.string,
    answerOrder: t.number
  })

  export const OBAnswerInfo = t.type({
    answerCode: t.string,
    answerEN: t.string,
    answerVN: t.string
  })
  export const OBCustomerBehaviorInput = t.type({
    clientNumber: t.string,
    behavior: t.string,
    source: t.string,
    businessKey: Maybe(t.string),
    policyNumber: Maybe(t.string)
  })

  export const OBCustomerBehaviorResponse = t.type({
    clientNumber: Maybe(t.string),
    behavior: Maybe(t.string),
    source: Maybe(t.string)
  })

  export const OBCallingResultDetailResponse = t.type({
    processInstanceId: Maybe(t.string),
    note: Maybe(t.string),
    additionalDetails: Maybe(
      t.type({
        customerUseSmartPhone: Maybe(t.boolean)
      })
    ),
    details: Maybe(
      t.array(
        t.type({
          questionCode: Maybe(t.string),
          answerCode: Maybe(t.string),
          answerNote: Maybe(t.string),
          answerDate: Maybe(t.string)
        })
      )
    )
  })

  export const A2S4AnswerConfig = t.type({
    answerCode: t.string,
    displayFunctionCode: t.string
  })

  export type QuestionTableInfo = t.TypeOf<typeof OBQuestionTableInfo>
  export type OBCustomerBehaviorInput = t.TypeOf<typeof OBCustomerBehaviorInput>
  export type OBCallingResultDetailResponse = t.TypeOf<typeof OBCallingResultDetailResponse>
  export type OBCustomerBehaviorResponse = t.OutputOf<typeof OBCustomerBehaviorResponse>
  export type A2S4AnswerConfig = t.OutputOf<typeof A2S4AnswerConfig>

  export const getQuestionTableList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a2s1-question`)(
        t.type({
          data: t.array(OBQuestionTableInfo),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseData) => {
        return responseData.data
      })
    )

  export const getQuestionAnswerList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a2s2-answers-of-question`)(
        t.type({
          data: t.array(OBQuestionAnswerInfo),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.data)
      )
    )

  export const getAnswerList = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a2s3-answers`)(
        t.type({
          data: t.array(OBAnswerInfo),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.data)
      )
    )

  export const saveCustomerBehavior = (inputData: OBCustomerBehaviorInput) =>
    pipe(
      POApi.post(`cs-api/outbound/customer/behavior`)(
        t.type({
          data: Maybe(OBCustomerBehaviorResponse),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      )(inputData),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.message)
      )
    )

  export const getCustomerBehaviorByClientNumber = (clientNumber: string) =>
    pipe(
      POApi.get(`cs-api/outbound/customer/${clientNumber}/behavior?source=OUTBOUND`)(
        t.type({
          data: Maybe(OBCustomerBehaviorResponse),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.data)
      )
    )

  export const saveCallingResultDetail = (inputData: OBCallingResultDetailResponse) =>
    pipe(
      POApi.post(`cs-api/outbound/calling-result`)(
        t.type({
          data: Maybe(OBCallingResultDetailResponse),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      )(inputData),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.message)
      )
    )

  export const getCallingResultDetailList = (proccessIntanceID: string) =>
    pipe(
      POApi.get(`cs-api/outbound/calling-result/${proccessIntanceID}`)(
        t.type({
          data: OBCallingResultDetailResponse,
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success.data)
      )
    )

  export const getA2S4AnswerConfigs = () =>
    pipe(
      POApi.get(`cs-api/outbound/table/a2s4-config-answer-with-ui`)(
        t.type({
          data: t.array(A2S4AnswerConfig),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const getLandingPageQuestionList = (IDCBusinessKey: string) =>
    pipe(
      POApi.get(`independence-rest/api/v1/idc/${IDCBusinessKey}/quota/ob-question`)(
        t.type({
          body: t.array(
            t.type({
              questionCode: t.string,
              questionName: t.string,
              passCheck: t.string
            })
          )
        })
      ),
      ZIO.map((originalQuestions) => {
        return originalQuestions.body
      })
    )
}
