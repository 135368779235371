import { useLoading } from '@mxt/zio-react'
import { Container, ErrorHandling, Input, SelectSearch, assets, SelectOption, AppContext, ModalComponent, Permission, DatePicker, Checkbox } from '@pulseops/common'
import {
  CallOutDetailContext,
  OBCallProgramTypeCode,
  OBCallingResultDetailConst,
  OBCallingResultDetailService,
  OBCustomerAnswerService,
  OBSectionCol,
  OBSectionContent,
  OBSectionRow,
  OBSharedStyles
} from '@pulseops/outbound'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { OBCallingResultDetailFrom } from './OBCallingResultDetailForm'
import moment from 'moment'
import { useIsFocused } from '@react-navigation/native'
import { orderBy } from 'lodash'
type questionTableInfo = {
  category: string;
  transactionTypeWF: string;
  questionCode: string;
  questionDescEn: string;
  questionDescVN: string;
  answerOptions: SelectOption[];
}

export const OBCallingResultDetails = () => {
  const [loading, bindLoader] = useLoading(true)
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { processInstanceId, clientNumber, isDisabled, setIsSavedCallingResultDetail, permissions, isSavedCallingResultDetail, policyNumber, caseId, callProgram, transactionType, IDCCustomerAnswerPayload } = React.useContext(CallOutDetailContext)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isOpenPopupClear, setIsOpenPopupClear] = React.useState(false)
  const [callingResultDetail, setCallingResultDetail] = React.useState<OBCallingResultDetailService.OBCallingResultDetailResponse>()
  const [questionTableList, setQuestionTableList] = React.useState<questionTableInfo[]>([])
  const callResultForm = useForm<OBCallingResultDetailFrom.OBCallingResultDetailData>({
    defaultValues: {
      questionAnswerList: [],
      isCustomerPhoneUse: false
    },
    mode: 'onChange'
  })
  const P4OCaseID = IDCCustomerAnswerPayload?.p4oCaseId ? IDCCustomerAnswerPayload?.p4oCaseId : ''
  const disabled = isDisabled || !permissions.includes(Permission['EditCallingResultDetailsOBTaskDetail'])

  const defaultValue = {
    label: 'N/A',
    value: 'A1'
  }

  const callingResultList = useFieldArray<OBCallingResultDetailFrom.OBCallingResultDetailData>({
    control: callResultForm.control,
    name: 'questionAnswerList'
  })

  const { apiQuestionTable, answerArr, questionAnswerArr, IDCCustomerAnswerList, A2S4AnswerConfigList, landingPageQuestionList } = pipe(
    ZIO.zipPar(
      OBCallingResultDetailService.getQuestionTableList(),
      OBCallingResultDetailService.getQuestionAnswerList(),
      OBCallingResultDetailService.getAnswerList(),
      transactionType === OBCallProgramTypeCode.IDC && P4OCaseID && isFocused ? pipe(
        OBCustomerAnswerService.getCustomerAnswer(P4OCaseID),
        ZIO.map((responseData) => {
          return responseData.answerResults
        })
      ) : ZIO.succeed([]),
      OBCallingResultDetailService.getA2S4AnswerConfigs(),
      transactionType === OBCallProgramTypeCode.IDC && IDCCustomerAnswerPayload?.idcType === OBCustomerAnswerService.IDCType.NORESPONSE ? OBCallingResultDetailService.getLandingPageQuestionList(P4OCaseID) : ZIO.succeed([])
    ),
    ZIO.map(([apiQuestionTable, questionAnswerArr, answerArr, IDCCustomerAnswer, A2S4AnswerConfigs, landingPageQuestionList]) => {
      return { apiQuestionTable: apiQuestionTable, questionAnswerArr, answerArr: answerArr, IDCCustomerAnswerList: IDCCustomerAnswer, A2S4AnswerConfigList: A2S4AnswerConfigs, landingPageQuestionList: landingPageQuestionList }
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([i18n.language, P4OCaseID])
  ) || {
    apiQuestionTable: [],
    answerArr: [],
    questionAnswerArr: [],
    IDCCustomerAnswerList: [],
    A2S4AnswerConfigList: [],
    landingPageQuestionList: []
  }

  React.useEffect(() => {
    return () => {
      callResultForm.reset({
        questionAnswerList: [],
        isCustomerPhoneUse: false
      })
    }
  }, [isFocused])

  const initialValuesForCallResultForm = (questionTableList: questionTableInfo[], isCustomerPhoneUse: boolean) => {
    let answerItem: SelectOption | undefined | null = null
    if (questionTableList && questionTableList.length > 0) {
      let questionAnswerList: OBCallingResultDetailFrom.OBQuestionAnswerInfo[] = []
      for (let i = 0; i < questionTableList.length; i++) {
        const questionItem = {
          label:
            i18n.language === 'en' ? questionTableList[i].questionDescEn : questionTableList[i].questionDescVN,
          value: questionTableList[i].questionCode
        }
        //set default value is N/A for question list
        switch (transactionType) {
          // case OBCallProgramTypeCode.IDC:
          //   answerItem = undefined
          //   break
          default:
            if (IDCCustomerAnswerPayload?.idcType === OBCustomerAnswerService.IDCType.NORESPONSE && isCustomerPhoneUse) {
              answerItem = null
            } else {
              answerItem = pipe(
                answerArr.find((x) => x.answerCode === 'A001'),
                O.fromNullable,
                O.map((data) => ({ label: data.answerEN, value: data.answerCode })),
                O.getOrElse(() => ({ label: '', value: '' }))
              )
            }
            break
        }

        questionAnswerList.push({
          questionItem: questionItem,
          answerItem: answerItem,
          answerNote: '',
          calendar: null
        })
        callingResultList.append({
          questionItem: questionItem,
          answerItem: answerItem,
          answerNote: '',
          calendar: null
        })
        callingResultList.update(i, {
          questionItem: questionItem,
          answerItem: answerItem,
          answerNote: '',
          calendar: null
        })
        callResultForm.setValue(`questionAnswerList.${i}.answerItem`, answerItem)
      }
      // console.log(`questionAnswerList: ` + JSON.stringify(questionAnswerList))
    }
  }

  const getInitialQuestionList = (isCustomerPhoneUse: boolean) => {
    let questionTableArr: questionTableInfo[] = []
    if (apiQuestionTable && apiQuestionTable.length > 0 && answerArr && answerArr.length > 0 && questionAnswerArr && questionAnswerArr.length > 0) {
      let sortedQuestions: OBCallingResultDetailService.QuestionTableInfo[] = []
      let answerOptions: SelectOption[] = []
      switch (transactionType) {
        case OBCallProgramTypeCode.IDC: {
          switch (IDCCustomerAnswerPayload?.idcType) {
            case OBCustomerAnswerService.IDCType.SAMPLING:
              sortedQuestions = apiQuestionTable.filter((x) => x.transactionTypeWF === transactionType).filter((p) => !!IDCCustomerAnswerList && IDCCustomerAnswerList?.some((item) => item.questionCode === p.questionCode))
                .sort((item1, item2) => Number(item1.questionOrder) - Number(item2.questionOrder))
              break
            case OBCustomerAnswerService.IDCType.NEGATIVE:
              sortedQuestions = apiQuestionTable.filter((x) => x.transactionTypeWF === transactionType).filter((p) => !!IDCCustomerAnswerList && IDCCustomerAnswerList?.some((item) => item.questionCode === p.questionCode && item.decisionResult === 'FAIL'))
                .sort((item1, item2) => Number(item1.questionOrder) - Number(item2.questionOrder))
              break
            case OBCustomerAnswerService.IDCType.NORESPONSE:
              {
                if (isCustomerPhoneUse) {
                  sortedQuestions = apiQuestionTable.filter((x) => x.transactionTypeWF === transactionType).filter((p) => !!landingPageQuestionList && landingPageQuestionList?.some((item) => item.questionCode === p.questionCode))
                    .sort((item1, item2) => Number(item1.questionOrder) - Number(item2.questionOrder))
                } else {
                  sortedQuestions = apiQuestionTable.filter((x) => x.transactionTypeWF === transactionType).filter((p) => p.questionCode === 'I001')
                }
                break
              }
          }
        }
          break
        default:
          sortedQuestions = apiQuestionTable.filter((x) => x.transactionTypeWF === transactionType).sort((item1, item2) => Number(item1.questionOrder) - Number(item2.questionOrder))
          break
      }
      questionTableArr = sortedQuestions.map((questionItem) => {
        let questionDescVN = '', questionDescEn = ''
        if (transactionType === OBCallProgramTypeCode.IDC && IDCCustomerAnswerPayload?.idcType === OBCustomerAnswerService.IDCType.NORESPONSE && isCustomerPhoneUse) {
          answerOptions = answerArr.filter((p) => ["A002", "A003"].includes(p.answerCode)).map((answItem) => ({ value: answItem.answerCode, label: i18n.language === 'en' ? answItem.answerEN : answItem.answerVN }))
        } else {
          const answerCodeList = orderBy(questionAnswerArr.filter((x) => x.questionCode === questionItem.questionCode), "answerOrder", "asc")
          answerOptions = answerCodeList.map((answerItem) => {
            const answerName = pipe(
              answerArr.find((x) => x.answerCode === answerItem.answerCode),
              O.fromNullable,
              O.map((item) => (i18n.language === 'en' ? item.answerEN : item.answerVN)),
              O.getOrElse(() => '')
            )
            return {
              label: answerName,
              value: answerItem.answerCode
            }
          })
        }
        switch (transactionType) {
          case OBCallProgramTypeCode.IDC:
            switch (IDCCustomerAnswerPayload?.idcType) {
              case OBCustomerAnswerService.IDCType.NORESPONSE:
                if (isCustomerPhoneUse) {
                  questionDescVN = pipe(landingPageQuestionList?.find((x) => x.questionCode === questionItem.questionCode),
                    O.fromNullable,
                    O.fold(
                      () => '',
                      (item) => item.questionName
                    ))
                  questionDescEn = pipe(landingPageQuestionList?.find((x) => x.questionCode === questionItem.questionCode),
                    O.fromNullable,
                    O.fold(
                      () => '',
                      (item) => item.questionName
                    ))
                } else {
                  questionDescVN = questionItem.questionDescVN
                  questionDescEn = questionItem.questionDescEn
                }
                break
              default:
                questionDescVN = pipe(IDCCustomerAnswerList?.find((x) => x.questionCode === questionItem.questionCode),
                  O.fromNullable,
                  O.fold(
                    () => '',
                    (item) => item.questionContent
                  ))
                questionDescEn = pipe(IDCCustomerAnswerList?.find((x) => x.questionCode === questionItem.questionCode),
                  O.fromNullable,
                  O.fold(
                    () => '',
                    (item) => item.questionContent
                  ))
                break
            }
            break
          default:
            questionDescVN = questionItem.questionDescVN
            questionDescEn = questionItem.questionDescEn
            break
        }
        return {
          category: questionItem.category,
          transactionTypeWF: questionItem.transactionTypeWF || '',
          questionCode: questionItem.questionCode,
          questionDescEn: questionDescEn,
          questionDescVN: questionDescVN,
          answerOptions: answerOptions
        }
      })
    }
    return questionTableArr
  }

  React.useEffect(() => {
    if (apiQuestionTable && apiQuestionTable.length > 0 && answerArr && answerArr.length > 0 && questionAnswerArr && questionAnswerArr.length > 0) {
      pipe(
        OBCallingResultDetailService.getCallingResultDetailList(processInstanceId),
        ZIO.map((responseData) => {
          let oldCustomerUseSmartPhone: boolean = callResultForm.watch('isCustomerPhoneUse')
          if (responseData && responseData.additionalDetails && responseData.additionalDetails.customerUseSmartPhone) {
            oldCustomerUseSmartPhone = responseData.additionalDetails.customerUseSmartPhone
            callResultForm.setValue('isCustomerPhoneUse', responseData.additionalDetails.customerUseSmartPhone)
          }
          const questionTableArr = getInitialQuestionList(oldCustomerUseSmartPhone)
          setQuestionTableList(questionTableArr)
          initialValuesForCallResultForm(questionTableArr, oldCustomerUseSmartPhone)
          setCallingResultDetail(responseData)
          updateCallResultForm(responseData)
          return responseData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [apiQuestionTable, answerArr, questionAnswerArr, IDCCustomerAnswerList, A2S4AnswerConfigList, landingPageQuestionList])



  const updateCallResultForm = (responseData: OBCallingResultDetailService.OBCallingResultDetailResponse | undefined) => {
    callResultForm.setValue('detailNote', responseData?.note || '')
    if (responseData && responseData.details && responseData.details.length > 0) {
      const questionAnswerList = callResultForm.getValues(`questionAnswerList`)
      let questionAnswerInfo: OBCallingResultDetailFrom.OBQuestionAnswerInfo | undefined = undefined

      for (let i = 0; i < questionAnswerList.length; i++) {
        const responseDataItem = pipe(
          responseData.details.find((x) => x.questionCode === questionAnswerList[i].questionItem.value),
          O.fromNullable,
          O.map((data) => ({ questionCode: data.questionCode || '', answerCode: data.answerCode || '', answerNote: data.answerNote || '', calendar: data.answerDate || '' })),
          O.getOrElse(() => ({ questionCode: '', answerCode: '', answerNote: '', calendar: '' }))
        )
        const answerItem = pipe(
          answerArr.find((x) => x.answerCode === responseDataItem?.answerCode),
          O.fromNullable,
          O.map((data) => ({
            label: i18n.language === 'en' ? data.answerEN : data.answerVN,
            value: data.answerCode
          })),
          O.getOrElse(() => ({ label: '', value: '' }))
        )
        const calendar = !!responseDataItem.calendar ? new Date(responseDataItem.calendar) : null
        questionAnswerInfo = {
          questionItem: questionAnswerList[i].questionItem,
          answerItem: answerItem,
          answerNote: responseDataItem.answerNote,
          calendar: calendar
        }
        callingResultList.update(i, questionAnswerInfo)
        callResultForm.setValue(`questionAnswerList.${i}`, questionAnswerInfo)
      }
    }
  }

  React.useEffect(() => {
    if (!!clientNumber) {
      pipe(
        transactionType !== OBCallProgramTypeCode.IDC
          ? OBCallingResultDetailService.getCustomerBehaviorByClientNumber(clientNumber)
          : ZIO.succeed({} as OBCallingResultDetailService.OBCustomerBehaviorResponse),
        ZIO.map((responseData) => {
          callResultForm.setValue('customerBehavior', responseData ? responseData?.behavior || '' : '')
          return responseData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [clientNumber])

  const onCheckedCustomerPhoneUseEvent = (isChecked: boolean) => {
    const questionTableArr = getInitialQuestionList(isChecked)
    setQuestionTableList(questionTableArr)
    callResultForm.reset({
      ...callResultForm.watch(),
      questionAnswerList: []
    })
    initialValuesForCallResultForm(questionTableArr, isChecked)
  }

  const checkNotSave = () => {
    if (isSavedCallingResultDetail) setIsSavedCallingResultDetail && setIsSavedCallingResultDetail(false)
  }

  const onSaveEvent = async () => {
    const isValidForm = await callResultForm.trigger()
    console.log(`callResultForm formstate:` + JSON.stringify(callResultForm.formState.errors))
    if (isValidForm) {
      const formData = callResultForm.getValues()
      showGlobalLoading(true)
      pipe(
        ZIO.effect(() => {
          const customerBehaviorData = {
            clientNumber: clientNumber,
            behavior: formData.customerBehavior,
            source: 'OUTBOUND',
            businessKey: caseId,
            policyNumber: policyNumber
          }
          return customerBehaviorData
        }),
        ZIO.flatMap((customerBehaviorData) =>
          !!customerBehaviorData.behavior && transactionType !== OBCallProgramTypeCode.IDC
            ? OBCallingResultDetailService.saveCustomerBehavior(customerBehaviorData)
            : ZIO.succeed('')
        ),
        ZIO.flatMap((_) => {
          const questionList = formData.questionAnswerList.map((questionItem) => {
            const answerNote = transactionType === OBCallProgramTypeCode.IDC && !!questionItem.answerNote ? questionItem.answerNote : undefined
            const calendar = !!questionItem.calendar ? moment(questionItem.calendar).format("YYYY-MM-DD") : undefined
            return {
              questionCode: questionItem.questionItem.value,
              answerCode: questionItem.answerItem?.value,
              answerNote: answerNote,
              answerDate: calendar
            }
          })
          const additionalDetails = transactionType === OBCallProgramTypeCode.IDC && IDCCustomerAnswerPayload?.idcType === OBCustomerAnswerService.IDCType.NORESPONSE ?
            { customerUseSmartPhone: formData.isCustomerPhoneUse } : null
          const callingData: OBCallingResultDetailService.OBCallingResultDetailResponse = {
            processInstanceId: processInstanceId,
            note: formData.detailNote,
            details: questionList,
            additionalDetails: additionalDetails
          }
          return OBCallingResultDetailService.saveCallingResultDetail(callingData)
        }),
        ZIO.map((response) => {
          showGlobalLoading(false)
          if (response === 'SUCCESS') {
            setIsSavedCallingResultDetail && setIsSavedCallingResultDetail(true)
            showToast(t('message:OB0040'), 'success')
          } else {
            showToast(t('message:OB0042'), 'error')
          }
          return response
        }),
        ZIO.catchAll((error) => {
          showGlobalLoading(false)
          showToast(t('message:OB0042'), 'error')
          return ZIO.fail(error)
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onClearAnswersEvent = () => {
    callResultForm.resetField('detailNote', { defaultValue: '', keepError: false })
    callingResultList && callingResultList.fields.map((item, i) => {
      callResultForm.setValue(`questionAnswerList.${i}.answerItem`, defaultValue)
    })
    setIsOpenPopupClear(false)
  }

  const isShowNoteBlock = (index: number, answerItem: SelectOption | undefined | null) => {
    const questionAnswItem = callResultForm.watch(`questionAnswerList.${index}.questionItem`)
    if (transactionType === OBCallProgramTypeCode.IDC && questionAnswItem && answerItem) {
      switch (IDCCustomerAnswerPayload?.idcType) {
        case OBCustomerAnswerService.IDCType.NORESPONSE:
          if (callResultForm.watch('isCustomerPhoneUse')) {
            const isShowNote = pipe(landingPageQuestionList.find((p) => p.questionCode === questionAnswItem.value),
              O.fromNullable,
              O.map((item) => {
                let showFlag: boolean = false
                if (item.passCheck === 'N' && ['A002'].includes(answerItem.value)) {
                  showFlag = true
                } else if (item.passCheck === 'Y' && ['A003'].includes(answerItem.value)) {
                  showFlag = true
                }
                return showFlag
              }),
              O.getOrElse(() => false))
            return isShowNote
          } else {
            return true
          }
        default:
          return true
      }

    } else {
      return false
    }
  }
  const isOpenCalendar = (index: number) => {
    const answerItem = callResultForm.watch(`questionAnswerList.${index}.answerItem`)
    if (answerItem && answerItem.value) {
      const isOpen = pipe(
        O.fromNullable(A2S4AnswerConfigList.find((x) => x.answerCode === answerItem.value)),
        O.fold(
          () => false,
          (answerConfig) => {
            return pipe(
              O.fromNullable(OBCallingResultDetailConst.A2S3FunctionConfigs.find((p) => p.code === answerConfig.displayFunctionCode && p.name.includes('Calendar'))),
              O.fold(
                () => false,
                (functionItem) => !!functionItem.name
              )
            )
          }
        )
      )
      return isOpen
    } else {
      return false
    }
  }

  const CallingQuestionAnswer = () => {
    return (
      <OBSectionRow style={{}}>
        {callingResultList &&
          callingResultList.fields.map((fieldItem, index) => {
            const questionName = `${index + 1}. ${fieldItem.questionItem.label}`
            return (
              <OBSectionCol style={[OBSharedStyles.sectionCol12, callStyles.secondLine]}>
                <Controller
                  control={callResultForm.control}
                  name={`questionAnswerList.${index}.answerItem`}
                  rules={{
                    validate: (val) => {
                      if (!val || !val.value) {
                        return `${t('message:MS020009', { field: index + 1 })}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <SelectSearch
                        label={questionName}
                        showPopupIcon={true}
                        value={value}
                        disabled={disabled}
                        onChange={(val) => {
                          onChange(val)
                          checkNotSave()
                          callResultForm.setValue(`questionAnswerList.${index}.answerNote`, '')
                          callResultForm.setValue(`questionAnswerList.${index}.calendar`, null)
                        }}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        options={questionTableList && questionTableList.length > 0 && questionTableList.length > index ? questionTableList[index].answerOptions : []}
                        errorMessage={!value || !value.value ? error?.message : ''}
                      />
                    )
                  }}
                />
                {isShowNoteBlock(index, callResultForm.watch(`questionAnswerList.${index}.answerItem`)) && (
                  <View style={callStyles.noteContent}>
                    <Controller
                      control={callResultForm.control}
                      name={`questionAnswerList.${index}.answerNote`}
                      // rules={{
                      //   validate: (val: string) => {
                      //     if (isShowNoteBlock(index, callResultForm.watch(`questionAnswerList.${index}.answerItem`)) && !val) {
                      //       return `${t('message:MS020001', { field: t('Outbound:OBCustomerAnwser:Opinion') })}`
                      //     } else {
                      //       return true
                      //     }
                      //   }
                      // }}
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                        return (
                          <Input
                            title={t('Outbound:OBCustomerAnwser:Opinion')}
                            value={value}
                            required={false}
                            disabled={disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                            multiline={true}
                            numberOfLines={2}
                            maxLength={1000}
                            errorMessage={error?.message}
                          />
                        )
                      }}
                    />
                  </View>
                )}
                {isOpenCalendar(index) && (
                  <View style={callStyles.calendarContent}>
                    <Controller
                      control={callResultForm.control}
                      name={`questionAnswerList.${index}.calendar`}
                      rules={{
                        validate: (val) => {
                          if (callResultForm.watch(`questionAnswerList.${index}.answerItem`)?.value === "A081" && (!val || !moment(val).isValid())) {
                            return `${t('message:MS020009', { field: t('Outbound:OBCalloutDetail:Calendar') })}`
                          } else if (moment(moment(val).format('YYYY/MM/DD')).valueOf() < moment(moment(new Date()).format('YYYY/MM/DD')).valueOf()) {
                            return `${t('message:OB0085')}`
                          } else {
                            return true
                          }
                        }
                      }}
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <DatePicker
                          label={t('Outbound:OBCalloutDetail:Calendar')}
                          required={true}
                          onChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          // maxDate={field.maxDate}
                          // maxDateMessage={''}
                          minDate={new Date()}
                          minDateMessage={''}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                )}
              </OBSectionCol>
            )
          })}
      </OBSectionRow>
    )
  }

  const PopupClearAnswer = () => {
    return (
      <ModalComponent
        title={''}
        visible={isOpenPopupClear}
        onClose={() => setIsOpenPopupClear(false)}
        actions={[
          {
            text: t('common:No'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39, marginEnd: 15 },
            action: () => setIsOpenPopupClear(false)
          },
          {
            text: t('common:Yes'),
            type: 'filled',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39 },
            action: () => onClearAnswersEvent()
          }
        ]}
      >
        <View>
          <Text style={{ textAlign: 'center' }}>
            {t('Outbound:OBCalloutDetail:ConfirmClearAnswer')}
          </Text>
        </View>
      </ModalComponent>
    )
  }

  const CallingResultDetails = () => {
    return (
      <OBSectionContent style={[{ marginBottom: 20 }]}>
        <Text style={callStyles.header}>{t('Outbound:OBCalloutDetail:CallingResult')}</Text>
        {transactionType === OBCallProgramTypeCode.IDC && IDCCustomerAnswerPayload?.idcType === OBCustomerAnswerService.IDCType.NORESPONSE && (
          <Controller
            control={callResultForm.control}
            name="isCustomerPhoneUse"
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <Checkbox
                  title={t(`Outbound:OBCalloutDetail:CustomerDoesNotUseSmartphone`)}
                  value={value}
                  onChange={(val) => {
                    onChange(val)
                    onCheckedCustomerPhoneUseEvent(val)
                  }}
                  onBlur={onBlur}
                  textStyle={{ fontWeight: 'bold' }}
                  disabled={disabled}
                />
              )
            }}
          />

        )}

        {CallingQuestionAnswer()}
        <OBSectionRow style={{ marginTop: 16 }}>
          <OBSectionCol style={OBSharedStyles.sectionCol12}>
            <Controller
              control={callResultForm.control}
              name="detailNote"
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020001', { field: t('Outbound:OBCalloutDetail:DetailsNote') })
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                return (
                  <Input
                    title={t('Outbound:OBCalloutDetail:DetailsNote')}
                    multiline={true}
                    required={true}
                    numberOfLines={5}
                    labelStyle={{ marginBottom: 8 }}
                    value={value}
                    maxLength={5000}
                    disabled={disabled}
                    onChange={(val) => {
                      onChange(val)
                      checkNotSave()
                    }}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    placeholder={t('Outbound:OBCalloutDetail:Enter')}
                  ></Input>
                )
              }}
            />
          </OBSectionCol>
        </OBSectionRow>
        <OBSectionRow style={callStyles.paddingRow}>
          {!disabled && transactionType !== OBCallProgramTypeCode.IDC && (
            <TouchableOpacity disabled={disabled} onPress={() => setIsOpenPopupClear(true)}>
              <View style={[OBSharedStyles.actionContent, callStyles.clearBtnContent]}>
                <Text style={OBSharedStyles.actionContentText}>{t('Outbound:OBCalloutDetail:ClearAnwser')}</Text>
              </View>
            </TouchableOpacity>
          )}
        </OBSectionRow>
      </OBSectionContent>
    )
  }

  const CustomerBehavior = () => {
    return (
      <OBSectionContent style={callStyles.container}>
        <Text style={callStyles.header}>{t('Outbound:OBCalloutDetail:CustomerBehavior')}</Text>
        <Controller
          control={callResultForm.control}
          name="customerBehavior"
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <Input
                title={t('Outbound:OBCalloutDetail:CustomerBehaviorField')}
                multiline={true}
                numberOfLines={5}
                labelStyle={{ marginBottom: 8 }}
                value={value}
                disabled={disabled}
                maxLength={120}
                onChange={(val) => {
                  onChange(val)
                  checkNotSave()
                }}
                onBlur={onBlur}
                placeholder={t('Outbound:OBCalloutDetail:Enter')}
              />
            )
          }}
        />
      </OBSectionContent>
    )
  }

  return (
    <Container loading={loading}>
      <View style={callStyles.container}>
        {CallingResultDetails()}
        {transactionType !== OBCallProgramTypeCode.IDC && CustomerBehavior()}
        {!disabled && (
          <OBSectionRow style={callStyles.paddingRow}>
            <TouchableOpacity onPress={() => onSaveEvent()}>
              <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight, { marginRight: 20 }]}>
                <Text style={OBSharedStyles.actionHightLightText}>{t('common:Save')}</Text>
              </View>
            </TouchableOpacity>
          </OBSectionRow>
        )}
        {PopupClearAnswer()}
      </View>
    </Container>
  )
}

const callStyles = StyleSheet.create({
  container: {
    display: 'flex',
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingVertical: 16
  },
  header: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16,
    marginBottom: 16
  },
  colQuestion: {
    width: '100%',
    maxWidth: '60%',
    marginBottom: 15
  },
  colAnswer: {
    width: '100%',
    maxWidth: '40%',
    marginBottom: 15
  },
  clearBtnContent: {
    backgroundColor: '#FEEDEE',
    borderColor: '#FEEDEE'
  },
  paddingRow: {
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  secondLine: {
    marginTop: 24
  },
  noteContent: {
    backgroundColor: '#F9F9F9',
    padding: 20,
    marginTop: 24
  },
  calendarContent: {
    backgroundColor: '#F9F9F9',
    padding: 20,
    marginTop: 24
  }
})
