import { SelectOption, TransactionType, mapTransactionType, TaskSource } from '@pulseops/common'

export namespace CandidateInformationConst {
  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }

  export const TemplateList = [
    {
      label: 'Excel',
      value: Extension.EXCEL
    }
    // {
    //   label: 'CSV',
    //   value: Extension.CSV
    // }
  ]
  // Complete, Suspend, Reject, End
  export const StatusList: SelectOption[] = [
    {
      label: 'Complete',
      value: 'COMPLETE'
    },
    {
      label: 'Suspend',
      value: 'SUSPEND'
    },
    {
      label: 'Reject',
      value: 'REJECT'
    },
    {
      label: 'End',
      value: 'END'
    }
  ]

  const TransactionLabel = (type: TransactionType | null): string => (type ? mapTransactionType.get(type) || '-' : '-')

  const transactions = (type: TransactionType) => ({
    label: TransactionLabel(type),
    value: type
  })

  export const TransactionList: SelectOption[] = [
    transactions(TransactionType.AGENT_ONBOARD_NEW),
    transactions(TransactionType.AGENT_ONBOARD_REINS)
  ]

  export const sourceDAOptions: SelectOption[] = [
    { label: 'PruDaily', value: TaskSource.PRUDAILY },
    { label: 'PruForce', value: TaskSource.PRUFORCE }
  ]

  // Agency, DSR, Millennial, Galerie, PTD
  export const SaleTypeList: SelectOption[] = [
    {
      label: 'Agency',
      value: 'AGENCY'
    },
    {
      label: 'DSR',
      value: 'DSR'
    },
    {
      label: 'Millennial',
      value: 'MILLENNIAL'
    },
    {
      label: 'Gallerie',
      value: 'GALLERIE'
    },
    {
      label: 'PTD',
      value: 'PTD'
    }
  ]
}
