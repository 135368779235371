import { Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { AmlResultC } from './aml-result'

export const ShareholderC = t.type({
  shareHolderKey: t.string,
  fullName: t.string,
  idNumber: t.string,
  dateOfBirth: DateFromISOString,
  gender: t.string,
  dateOfIssue: DateFromISOString,
  placeOfIssue: t.string,
  nationality: t.string,
  otherNationality: Nullable(t.string),
  visa: Nullable(t.string),
  permanentAddress: t.string,
  permanentAddressCountry: Nullable(t.string),
  permanentCity: Nullable(t.string),
  permanentDistrict: Nullable(t.string),
  permanentWard: Nullable(t.string),
  residentAddress: t.string,
  residentialAddressCountry: Nullable(t.string),
  residentialCity: Nullable(t.string),
  residentialDistrict: Nullable(t.string),
  residentialWard: Nullable(t.string),
  overseaAddress: Nullable(t.string),
  overseasAddressCountry: Nullable(t.string),
  overseaCity: Nullable(t.string),
  overseaDistrict: Nullable(t.string),
  overseaWard: Nullable(t.string),
  position: t.string,
  phoneNumber: t.string,
  capitalContributionRatio: t.string,
  masterContractNo: t.string,
  policyYear: t.number,
  historyAmlResult: t.array(AmlResultC),
  uuid: t.string,
  uniqueIdentifierAml: Nullable(t.string),
  version: t.number
})
export type Shareholder = t.TypeOf<typeof ShareholderC>
