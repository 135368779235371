import { AddressForm } from '@pulseops/business/core'
import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace ShareholderDetailForm {
  export const codec = t.type({
    fullName: form2.string.requiredMaxLength(100),
    dateOfBirth: form2.date.requiredPast,
    gender: form2.string.required,
    idNumber: form2.string.requiredMaxLength(25),
    dateOfIssue: form2.date.required,
    placeOfIssue: form2.string.requiredMaxLength(100),
    nationality: form2.string.required,
    otherNationality: form2.string.optional,
    visa: form2.string.optionalMaxLength(100),
    permanentAddress: form2.string.requiredMaxLength(500),
    permanentAddressCountry: form2.string.optional,
    permanent: AddressForm.codec,
    residentAddress: form2.string.requiredMaxLength(500),
    residentialAddressCountry: form2.string.optional,
    residential: AddressForm.codec,
    overseaAddress: form2.string.optionalMaxLength(500),
    overseasAddressCountry: form2.string.optional,
    overseas: AddressForm.codec,
    position: form2.string.requiredMaxLength(100),
    phoneNumber: form2.phone.required,
    capitalContributionRatio: form2.string.requiredMaxLength(3)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
