import { StorageBlob } from '@pulseops/common'
export namespace SignatureRegistration {
  export interface AccessData {
    status: string
  }
  export interface SubmitData {
    optionFlag: string
    clientId: string
    office: string
    documents: StorageBlob.FileContentSubmit[]
  }
}
