export namespace LoanStatementsData {
  export interface AccessData {
    contractStatus: string
    lapsedDate: string
  }
  export interface DetailInfo {
    loanStartDate: string
    loanEnquiry: LoanEnquiry[]
    clientId: string
    recommandDate: string
  }
  export interface LoanEnquiry {
    loanNumber: number
    loanType: string
  }
  export interface LapseEnquiryInfo {
    lapseDate: string
    riskCommenceDate: string
  }
  export interface SubmitData {
    clientId: string
    office: string
    fromDate: string
    toDate: string
  }
}
