import { Task } from '@mxt/zio'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { IHeatData, IHeatDataDetail } from './model'

export const IHeatDataRes = t.type({
  data: t.array(IHeatData.codec),
  total: t.number,
  page: t.number,
  size: t.number
})
export type IHeatDataRes = t.TypeOf<typeof IHeatDataRes>

export const IHeatDataDetailRes = t.type({
  data: t.array(IHeatDataDetail.codec)
})
export type IHeatDataDetailRes = t.TypeOf<typeof IHeatDataDetailRes>

export namespace IHeatDataService {
  //const { getAuth } = PulseOpsApi

  export const getIHeatData = (query: {
    policyNo?: string
    clientNum?: string
    page: number
    size: number
  }): Task<IHeatDataRes> =>
    POApi.getConfig({
      params: query
    })('pulseops/api/v1/iheat-data')(IHeatDataRes)

  export const getIHeatDataDetail = (query: { callId: string }) =>
    POApi.getConfig({ params: query })(`pulseops/api/v1/iheat-data-detail`)(IHeatDataDetailRes)
  // getAuth({ params: query })(`${PulseOpsApi.apiV1}/iheat-data-detail`, IHeatDataDetailRes)
}
