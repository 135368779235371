import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'
import * as core from '@material-ui/core'
import i18next from 'i18next'
import { assets } from '../assets'
import { FormControl } from './styled'
import { DatePickerProps } from './datepicker'
import { withStyles } from '@material-ui/core'

export const BorderDatePicker = (props: DatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SC.FormControl error={!!props.errorMessage}>
        <SC.KeyboardDatePicker
          inputVariant="outlined"
          clearable
          label={props.label}
          disabled={props.disabled}
          value={props.value || null}
          keyboardIcon={props.errorMessage ? <assets.IconCalendarGray /> : <assets.IconCalendar />}
          onChange={(date) => {
            if (props.onChange) {
              props.onChange(date)
            }
          }}
          onBlur={props.onBlur}
          placeholder="dd/mm/yyyy"
          format="dd/MM/yyyy"
          minDate={props.minDate}
          maxDate={props.maxDate}
          maxDateMessage={props.errorMessage ? '' : props.maxDateMessage}
          invalidDateMessage={props.errorMessage ? '' : i18next.t('form:error_invalid')}
        />
        {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
      </SC.FormControl>
    </MuiPickersUtilsProvider>
  )
}

const SC = {
  KeyboardDatePicker: withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 8,
        height: 40,
        padding: '5px 10px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        }
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 13,
        padding: 0
      },
      '& .MuiIconButton-root': {
        padding: 0
      }
    }
  })(KeyboardDatePicker),
  FormControl: withStyles({
    root: {
      margin: 0,
      width: '100%',
      minWidth: '120px'
    }
  })(FormControl)
}
