import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import { IBGeneralTable, IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { IBService, PolicyRiderData } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { assets } from '@pulseops/common'

interface Props {
  dataRiderList: PolicyRiderData[]
  setDataRiderList: (data: PolicyRiderData[]) => void
  claimNumber: string
}

export const IBPolicyRider = ({dataRiderList, setDataRiderList, claimNumber}: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if(claimNumber && dataRiderList.length === 0) {
      pipe(
        IBService.getPolicyAndRider(claimNumber),
        ZIO.map((res) => {
          res && setDataRiderList(res.map((i) => ({
            ...i,
            products: i.products && i?.products.map((product) => ({
              ...product,
              eventDate: product.eventDate ? moment(product.eventDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
              rescissionDate: product.rescissionDate ? moment(product.rescissionDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
              waivePremiumStartDate: product.waivePremiumStartDate ? moment(product.waivePremiumStartDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
              waivePremiumEndDate: product.waivePremiumEndDate ? moment(product.waivePremiumEndDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
            }))
          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])

  const dataTable = [
    {
      label: t('Coverage'),
      field: 'coverageNo'
    },
    {
      label: t('ProdType'),
      field: 'productCode'
    },
    {
      label: t('EventDateClaim'),
      field: 'eventDate',
      format: 'date'
    },
    {
      label: t('DuplicatedCase'),
      field: 'duplicatedCase'
    },
    // {
    //   label: t('Communicator'),
    //   field: 'communicator'
    // },
    {
      label: t('RelatedCase'),
      field: 'relatedCase'
    },
    {
      label: t('EvlStatus'),
      field: 'evaluationStatus'
    },
    {
      label: t('RejReason'),
      field: 'rejectReason'
    },
    {
      label: t('Rescission'),
      field: 'rescission'
    },
    {
      label: t('ResciDate'),
      field: 'rescissionDate',
      format: 'date'
    },
    {
      label: t('WOP'),
      field: 'ifWaivePremiumIndicator',
      render: (value: string) => {
        return value === '1' ? <assets.TaskIcon_Checked /> : <assets.TaskIcon_UnChecked />
      }
    },
    {
      label: t('WOPFrom'),
      field: 'waivePremiumStartDate',
      format: 'date'
    },
    {
      label: t('WOPTo'),
      field: 'waivePremiumEndDate',
      format: 'date'
    },
    {
      label: t('WaivedAmt'),
      field: 'waivePremiumAmount'
    }
  ]

  const policyFieldsInfo = (data: PolicyRiderData) => [
    {
      title: t('POname'),
      value: data?.clientName
    },
    {
      title: t('POClient'),
      value: data?.clientNumber
    },
    {
      title: t('EffeDate'),
      value: data?.effectiveDate
    },
    {
      title: t('PTD'),
      value: data?.paidToDate
    },
    {
      title: t('PolStatus'),
      value: data?.policyStatus
    },
    {
      title: t('StatusOnED'),
      value: data?.eventPolicyStatus
    },
    {
      title: t('EvlStatus'),
      value: data?.evaluationStatus
    }
  ]

  const TableRiders = (dataRiders: Object[]) => {
    return (
      <View style={{ maxWidth: '100%' }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={dataRiders}
          loading={loading}
          >
        </IBGeneralTable>
      </View>
    )
  }

  const ViewPolicyInfo = (data: PolicyRiderData) => {
    return (
      <View style={{ paddingBottom: 35 }}>
        <View style={IBStyles.row}>
          <Text style={{ color: 'red', fontWeight: 'bold', paddingBottom: 15, fontSize: 16 }}>{t('PolNo') + ':' + (data.policyNumber ?? '') }</Text>
        </View>
        <View style={IBStyles.row}>
          {data && policyFieldsInfo(data).map((field) => (
            <View style={[IBStyles.sectionCol3, { paddingBottom: 24 }]}>
              <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{field.title}</Text>
              </View>
              <Text style={{ fontSize: 15, marginTop: 4 }}>{field.value ?? '-'}</Text>
            </View>
          ))}
        </View>
        <View style={IBStyles.row}>
          {TableRiders(data.products ?? [])}
        </View>
      </View>
    )
  }

  return (
    <View>
      {dataRiderList.length > 0 ? 
        dataRiderList.map((i) => ViewPolicyInfo(i)) : 
        <Text style={{ textAlign: 'center', fontSize: 15 }}>
          {t('common:Nodata')}
        </Text>
      }
    </View>
  )
}