import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { Error } from './CepCommonModels'

export const PaymentRequestInfoC = t.type({
  data: t.type({
    proposalNo: t.string
  })
})
export type PaymentRequestInfoC = t.TypeOf<typeof PaymentRequestInfoC>

export const PaymentItemC = t.type({
    reqnNumber: Maybe(t.string),
    type: Maybe(t.string),
    amount: Maybe(t.union([t.string, t.number]) ),
    verifyDate: Maybe(t.string),
    bankCode: Maybe(t.string),
    authorisedBy: Maybe(t.string),
    verifyUser: Maybe(t.string),
    status: Maybe(t.string),
    authDate: Maybe(t.string),
    desc: Maybe(t.string),
    nationalId: Maybe(t.string),
    idForPayment: Maybe(t.string),
    accountName: Maybe(t.string),
    bankAccount:Maybe(t.string),
    bankKey: Maybe(t.string),
    bankName: Maybe(t.string),
    issDateAndAuthId: Maybe(t.string),
});

export type PaymentItemC = t.TypeOf<typeof PaymentItemC>
export const PaymentItemList = t.array(PaymentItemC)
export type PaymentItemList = t.TypeOf<typeof PaymentItemList>

export const PaymentData = t.type({
  data: PaymentItemList,
  error: Error
})

export type PaymentData = t.TypeOf<typeof PaymentData>

export const TranPaymentItemC = t.type({
  accountingAmount: Maybe(t.union([t.string, t.number])),
  originAmount: Maybe(t.union([t.string, t.number])),
  seqNo: Maybe(t.string),
  code: Maybe(t.string),
  type: Maybe(t.string),
  description: Maybe(t.string),
  tranKey: Maybe(t.string),
});

export type TranPaymentItemC = t.TypeOf<typeof TranPaymentItemC>
export const TranPaymentItemList = t.array(TranPaymentItemC)
export type TranPaymentItemList = t.TypeOf<typeof TranPaymentItemList>

export const TranPaymentData = t.type({
  data: TranPaymentItemList,
  error: Error
})

export type TranPaymentData = t.TypeOf<typeof TranPaymentData>
export const PaymentItemP2PC = t.type({
  beneficiaryName: Maybe(t.string),
  customerRef: Maybe(t.string),
  bankPaidDate: Maybe(t.string),
  bankRejectedDate: Maybe(t.string),
  paymentAmount: Maybe(t.string),
  policyNo: Maybe(t.string),
  pvNo: Maybe(t.string),
  authorizedDate: Maybe(t.string),
  userCreatePV: Maybe(t.string),
  verUserPV: Maybe(t.string),
  dept: Maybe(t.string),
  paymentService: Maybe(t.string),
  reason: Maybe(t.string),
  lps: Maybe(t.string),
})

export type PaymentItemP2PC = t.TypeOf<typeof PaymentItemP2PC>
export const PaymentItemP2PList = t.array(PaymentItemP2PC)
export type PaymentItemP2PList = t.TypeOf<typeof PaymentItemP2PList>

export const PaymentDataP2P = t.type( {
  data: PaymentItemP2PList,
  error: Error
});



