import React from 'react'
import { View, Text } from 'react-native'
import { assets, useMobile } from '@pulseops/common'
import { useTranslation } from 'react-i18next'

export const ClaimInfoHeader: React.FC<{
  menus: {
    policyNum: string
    policyOwner: string
    officeName: string
  }
}> = ({ menus }) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()
  const composeWidthItem = isMobile ? '100%' : '33%'
  const composeAlign = isMobile ? 'flex-start' : 'center'

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: menus.policyNum,
      icon: <assets.PolicyNumber />
    },
    {
      label: t('common:PolicyOwner'),
      value: menus.policyOwner,
      icon: <assets.MainBoyAvatar />
    },
    {
      label: t('common:Office'),
      value: menus.officeName,
      icon: <assets.Building />
    }
  ]

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: '#E5EAEF',
        margin: 20,
        borderRadius: 8,
        flexWrap: 'wrap'
      }}
    >
      {headers.map((m, i) => {
        const { value, label, icon } = m
        return (
          <View
            key={i}
            style={{
              flexDirection: 'row',
              justifyContent: composeAlign,
              alignItems: 'center',
              paddingVertical: 25,
              width: composeWidthItem,
              minWidth: 150,
              alignSelf: composeAlign
            }}
          >
            <View style={{ paddingHorizontal: 10 }}>{icon}</View>
            <Text style={{ fontSize: 17 }}>{`${label}: `}</Text>
            <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'red' }}>{value}</Text>
          </View>
        )
      })}
    </View>
  )
}
