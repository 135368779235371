import { TransactionType } from "../TransactionType"
import * as api from '../task-detail-api'

export interface VERIUploadDocument {
  tag: TransactionType.UPLOAD_DOCUMENT
  clientName: string
}

export const UploadDocument = (detail: api.TaskDetailBase & api.UploadDocument, poName: string): VERIUploadDocument => {
  return {
    tag: detail.transactionType,
    clientName: poName
  }
}