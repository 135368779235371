import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
  Pressable,
  ScrollView
} from 'react-native'
import React from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  DatePicker,
  SelectSearch,
  form2,
  Input,
  RequestListInfo,
  CashLessService,
  TransactionStatusLabel,
  TransactionStatus,
  downloadURI,
  RBAC,
  ErrorHandling,
  TASK_DETAIL_ROLES
} from '@pulseops/common'
import { Controller } from 'react-hook-form'
import { RequestListForm } from './PCRequestListForm'
import moment from 'moment'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { PCRequestListStackParamList } from './PCRequestListParamList'
import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
const Status = [
  { label: 'All', value: '' },
  { label: 'In progress', value: 'IN_PROGRESS' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'End', value: 'END' }
]
type Props = StackScreenProps<PCRequestListStackParamList, 'PCRequestListScreen'>

export const PCRequestList = (props: Props) => {
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const { t } = useTranslation('pc')
  const { height } = useWindowDimensions()
  const [isLoading, bindLoading] = useLoading(false)
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [totalTask, setTotalTask] = React.useState<number>(0)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []

  const [requestList, setRequestList] = React.useState<RequestListInfo[]>([])

  const { base, handleSubmit } = form2.useForm(RequestListForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      policyNumber: '',
      status: { label: 'All', value: '' }
    }
  })

  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:PCRequestList'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const onChangePageSize = (size: number) => {
    setPageSize(Number(size))
    setPage(0)
  }

  React.useEffect(() => {
    onSearch(page)
  }, [page, pageSize])

  const onSearch = (page: number) => {
    showGlobalLoading(true)
    const validDate = moment(base.watch().toDate).diff(base.watch().fromDate, 'days') <= 30
    if (validDate) {
      handleSubmit((value) => {
        const dataForm = base.watch()
        const isPolicyNum = dataForm.policyNumber?.length === 8
        pipe(
          CashLessService.searchRequestList(
            isPolicyNum ? dataForm.policyNumber || '' : '',
            !isPolicyNum ? dataForm.policyNumber || '' : '',
            dataForm.status?.value || '',
            moment(dataForm.fromDate).add(7, 'hours').toISOString(),
            moment(dataForm.toDate).add(7, 'hours').toISOString(),
            page * pageSize,
            pageSize
          ),
          ZIO.map((item) => {
            showGlobalLoading(false)
            if (!_.isEmpty(item.data)) {
              setRequestList(
                item.data.map((item) => ({
                  id: item.id,
                  status: Status.find((x) => x.value === item.status),
                  submissionDate: item.createdDate || '-',
                  policyNumber: item.policyNumber || '-',
                  caseId: item.caseId || '-',
                  idNumber: item.idNumber || '-',
                  policyOwnerName: item.policyOwnerName || '-',
                  paymentReason: item.paymentReason || '-',
                  officeCode: item.officeCode || '-'
                }))
              )
              setTotalTask(Number(item.total))
            } else {
              setRequestList([])
              showToast(t('message:MS030029'), 'error')
            }
            return ZIO.unit
          }),
          ZIO.catchAll((error) => {
            console.log(error)
            setRequestList([])
            return ZIO.succeed(null)
          }),
          ZIO.unsafeRun({})
        )
      })()
    }
  }

  const handleExportRequestList = handleSubmit((data) => {
    showGlobalLoading(true)
    const isPolicyNum = data.policyNumber?.length === 8
    pipe(
      CashLessService.exportRequestList(
        moment(data.fromDate).add(7, 'hours').toISOString(),
        moment(data.toDate).add(7, 'hours').toISOString(),
        isPolicyNum ? data.policyNumber || '' : '',
        !isPolicyNum ? data.policyNumber || '' : '',
        data.status?.value || '',
        page * pageSize,
        pageSize
      ),
      ZIO.flatMap((res) => {
        return ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      }),
      ZIO.tap(([res, blob]) => {
        showGlobalLoading(false)
        downloadURI(window.URL.createObjectURL(blob), `Report-PC-Request.xlsx`)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showGlobalLoading(false)
        return ZIO.succeed(null)
      }),
      ZIO.unsafeRun({})
    )
  })

  const handleOnRefresh = () => {
    onSearch(page)
  }

  const handleViewDetail = (policyNum: string, id: string, officeCode: string, poName: string, status: string) => {
    props.navigation.navigate('PCRequestListDetailScreen', {
      policyNumber: policyNum,
      revertRequestWOId: id,
      officeCode,
      policyOwnerName: poName,
      status,
      onRefresh: handleOnRefresh
    })
  }
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <View style={[styles.row, { marginBottom: 5 }]}>
            <Controller
              control={base.control}
              name="policyNumber"
              render={({ field, fieldState: { error } }) => (
                <View style={[styles.inputStyle, { marginTop: 6 }]}>
                  <Input
                    {...field}
                    value={field.value ?? ''}
                    title={t('ServiceInquiry:pc:policyNo')}
                    maxLength={9}
                    //   disabled={disableFields}
                    //   alwayShowUnderline={!disableFields}
                    errorMessage={error?.message}
                    inputType={'number'}
                  />
                </View>
              )}
            />
            <Controller
              control={base.control}
              name={'fromDate'}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <View style={styles.inputStyle}>
                    <DatePicker
                      label={t('common:FromDate')}
                      onChange={onChange}
                      value={value}
                      maxDate={new Date()}
                      minDate={moment(base.watch().toDate).subtract(30, 'days')}
                      errorMessage={error?.message}
                      required
                    />
                  </View>
                )
              }}
            />
            <Controller
              control={base.control}
              name={'toDate'}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <View style={styles.inputStyle}>
                    <DatePicker
                      label={t('common:ToDate')}
                      onChange={onChange}
                      value={value}
                      maxDate={moment(base.watch().fromDate).add(30, 'days')}
                      minDate={base.watch().fromDate}
                      errorMessage={error?.message}
                      required
                    />
                  </View>
                )
              }}
            />
          </View>
          <View style={styles.row}>
            <Controller
              name="status"
              control={base.control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <View style={styles.inputStyle}>
                  <SelectSearch
                    label={t('TaskManagement:StatusPC')}
                    options={Status}
                    placeholder={t('common:Select')}
                    onChange={(val) => {
                      onChange(val)
                    }}
                    onBlur={() => {
                      onChange((!value || !value.value) && { label: 'All', value: '' })
                    }}
                    value={value}
                    labelStyle={{ marginBottom: 8 }}
                    errorMessage={error?.message}
                    required
                  />
                </View>
              )}
            />
          </View>
          <View style={[styles.row, { alignItems: 'center', marginTop: 20 }]}>
            <TouchableOpacity
              style={{ height: 40 }}
              onPress={() => {
                setPage(0)
                onSearch(0)
              }}
            >
              <View style={[styles.btn]}>
                <Text style={styles.btnText}>{t('common:Search')}</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ height: 40, marginLeft: 30 }} onPress={handleExportRequestList}>
              <View
                style={[
                  styles.btn,
                  {
                    flexDirection: 'row'
                  }
                ]}
              >
                <Text style={styles.btnText}>{t('DistributionService:Export')}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ margin: 15 }}>
          <TableContainer component={Paper} className={classes.container} elevation={1}>
            <Table className={classes.table} stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableCellHeader} style={{ width: 100 }}>
                    {t('TaskManagement:Status')}
                  </TableCell>
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:SubmissionDatePC')}</TableCell>
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:PolicyLink')}</TableCell>
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:CaseID')}</TableCell>
                  <TableCell className={classes.tableCellHeader}>{t('common:IDNumber')}</TableCell>
                  <TableCell className={classes.tableCellHeader}>{t('common:PolicyOwner')}</TableCell>
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:PaymentReason')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {isLoading ? (
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator size="large" color="red" />
                      </View>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {requestList.length > 0 ? (
                      <>
                        {requestList.map((item: RequestListInfo, index) => {
                          const { label, color, backgroundColor } = TransactionStatusLabel(
                            item.status?.value as TransactionStatus
                          )
                          return (
                            <TableRow key={index}>
                              <TableCell className={classes.tableCell}>
                                <View
                                  style={[
                                    styles.container,
                                    { backgroundColor: item.status?.value === 'END' ? '#FEE0E0' : backgroundColor }
                                  ]}
                                >
                                  <Text style={[styles.lalel, { color }]}>{label}</Text>
                                </View>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {moment(item.submissionDate).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {permissions.includes(TASK_DETAIL_ROLES.ViewPCRequestDetail) ? (
                                  <Pressable
                                    onPress={() =>
                                      handleViewDetail(
                                        item.policyNumber || '',
                                        item.id,
                                        item.officeCode,
                                        item.policyOwnerName,
                                        item.status?.value || ''
                                      )
                                    }
                                    // disabled={!checkStatus(item?.status || '')}
                                  >
                                    <Text
                                      style={{
                                        fontWeight: '600',
                                        fontSize: 15,
                                        color: '#1ea5fc',
                                        textDecorationLine: 'underline'
                                      }}
                                    >
                                      {item.policyNumber || '-'}
                                    </Text>
                                  </Pressable>
                                ) : (
                                  <Text
                                    style={{
                                      fontWeight: '600',
                                      fontSize: 15,
                                      color: '#000000'
                                    }}
                                  >
                                    {item.policyNumber || '-'}
                                  </Text>
                                )}
                              </TableCell>
                              <TableCell className={classes.tableCell}>{item.caseId}</TableCell>
                              <TableCell className={classes.tableCell}>{item.idNumber}</TableCell>
                              <TableCell className={classes.tableCell}>{item.policyOwnerName}</TableCell>
                              <TableCell className={classes.tableCell}>{item.paymentReason}</TableCell>
                            </TableRow>
                          )
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                          {t('common:Nodata')}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={page}
            rowsPerPage={pageSize}
            count={totalTask}
            onPageChange={(e, page) => {
              setPage(page)
            }}
            onRowsPerPageChange={(e) => {
              onChangePageSize(Number(e.target.value))
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    padding: 25
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15,
    width: '30%'
  },
  sessonText: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 15
  },
  btn: {
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    paddingHorizontal: 30,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  btnUpload: {
    height: 40,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  rowBtn: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15
  },
  btnText: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'NotoSans_700Bold'
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'baseline',
    borderRadius: 16
    // maxHeight: 24
  },

  lalel: {
    fontSize: 13,
    fontWeight: '500',
    marginHorizontal: 16,
    marginVertical: 2,
    lineHeight: 24,
    whiteSpace: 'nowrap'
  }
})
