import { TouchableOpacity, View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native'
import * as React from 'react'
import { ControlProps } from '../FormProps'

export type RadioButtonProps = ControlProps<string> & {
  selected?: boolean
  label?: string
  style?: StyleProp<ViewStyle>
  radioStyle?: StyleProp<ViewStyle>
  disable?: boolean
  labelStyle?: StyleProp<TextStyle>
}

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={props.disable}
      style={[{ flexDirection: 'row', alignItems: 'center', marginRight: 50 }, props.style ? props.style : null]}
      onPress={() => props?.onChange && props?.onChange(props.value ?? '')}
    >
      <View
        style={[
          {
            height: 24,
            width: 24,
            borderRadius: 12,
            borderWidth: 2,
            borderColor: '#ACB0B5',
            alignItems: 'center',
            justifyContent: 'center'
          },
          props && props.radioStyle
        ]}
      >
        {props?.selected ? (
          <View
            style={{
              height: 12,
              width: 12,
              borderRadius: 6,
              backgroundColor: '#ED1B2E'
            }}
          />
        ) : null}
      </View>
      <Text style={[{ fontSize: 15, marginLeft: 12 }, props.labelStyle]}>{props.label}</Text>
    </TouchableOpacity>
  )
}
