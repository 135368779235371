import React from 'react'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StyleSheet, Text, View } from 'react-native'
import { createTheme, ThemeProvider } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import i18next from 'i18next'
import { assets, ControlProps, DatePickerProps } from '@pulseops/common'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

// type OBDatePickerProps = ControlProps<Date | null> & {}

export const OBDateTimePicker = (props: DatePickerProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.label && (
          <Text style={styles.label}>
            {props.label}
            {props.required && <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>}
          </Text>
        )}

        <View style={[styles.dateTimeContainer, { minWidth: props?.minWidth ?? 'auto' }]}>
          <KeyboardDateTimePicker
            InputProps={{ disableUnderline: true }}
            autoOk
            label=""
            // variant="inline"
            ampm={false}
            disabled={props.disabled}
            value={props.value}
            keyboardIcon={<assets.InputCalendar />}
            onKeyPress={(e) => {
              if (props.disableKeyInput) e.preventDefault()
            }}
            onChange={(date) => {
              if (props.onChange) {
                props.onChange(date)
              }
            }}
            onBlur={props.onBlur}
            minDate={props.minDate}
            maxDate={props.maxDate}
            minDateMessage={''}
            invalidDateMessage={props.errorMessage ? '' : props.invalidDateMessage || i18next.t('form:error_invalid')}
            format="dd/MM/yyyy HH:mm"
            placeholder="dd/mm/yyyy HH:mm"
          />
          {props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
        </View>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
const styles = StyleSheet.create({
  dateTimeContainer: {
    flex: 1,
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 16,
    backgroundColor: '#F6F7FA',
    borderColor: '#bdbec0',
    borderWidth: 1
  },
  label: {
    color: '#58647A',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 18,
    marginBottom: 4
  }
})
