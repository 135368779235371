import { ScrollView, View, Text, TextStyle, StyleSheet, TouchableOpacity } from 'react-native'
import { ImportDoc } from './ImportDoc'
import React from 'react'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { AppContext, AuthService, downloadURI, PulseOpsFormat, ReleasePolicyService } from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }
export interface UploadReleasePolicyItem {
  createdDate: Date | null | undefined,
  createdBy: string | null | undefined,
  createdName: string | null | undefined,
  fileUploadName: string | null | undefined,
  fileUploadUrl: string | null | undefined,
  status: number | null | undefined,
  fileErrorName: string | null | undefined,
  fileErrorUrl: string | null | undefined,
  linesProcess: number | null | undefined,
  linesSuccess: number | null | undefined,
  linesFailed: number | null | undefined
}

const useStyles = makeStyles({
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    minWidth: 180,
    fontSize: 15,
    color: '#70777E'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})

export const ReleasePolicyScreen = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [dataSource, setDataSource] = React.useState<UploadReleasePolicyItem[]>([])
  const isFocused = useIsFocused()
  const classes = useStyles()
  const [loading, bindLoading] = useLoading(false)
  const [isRelease, setIsRelease] = React.useState<boolean>(false)
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const handlePaging = (pageIndex: number, currentpageSize: number) => {
    getReleasePolicy(currentpageSize, pageIndex)
  }

  const [softOrder, setSoftOrder] = React.useState<'asc' | 'desc' | undefined>('asc')
  const [softItem, setSoftItem] = React.useState<string>('fileName')

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:ReleasePolicy'),
          navigate: null
        }
      ])
    }
    getReleasePolicy(pageSize, pageNum)
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    setDataSource([])
    setTotalItem(0)
    getReleasePolicy(pageSize, 0)
    setPageNum(0)
    setIsRelease(false)
  }, [isRelease])

  const getReleasePolicy = (size: number, num: number) => {
    const bodySet = {
      body: {
        pageNum: num + 1,
        pageSize: size
      }
    }
    pipe(
      ReleasePolicyService.getListReleasePolicy(bodySet),
      ZIO.tap((res) => {
        if (res?.body.data) {
          setDataSource(res?.body.data)
          setTotalItem(res?.body.total)
        }
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const importFile = (file: File) => {
    pipe(
      ReleasePolicyService.importReleasePolicyFile(file),
      ZIO.tap((res) => {
        setIsRelease(true)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const downloadFile = (url: string, fileName: string) => {
    if (url) {
      const container = new URL(url).searchParams.get('container') ?? ''
      const blob = new URL(url).searchParams.get('blob') ?? ''
      pipe(
        ZIO.zipPar(AuthService.token, AuthService.getLoginType),
        ZIO.flatMap(([token, loginType]) => {
          return ZIO.fromPromise(() =>
            fetch(url, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType,
                container: container,
                blob: blob
              }
            })
          )
        }),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.map(([res, blob]) => {
          return downloadURI(window.URL.createObjectURL(blob), fileName)
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const sortData = (soft: string) => {
    setSoftItem(soft)
    const compareItem = (a: any, b: any) => {
      console.log(a, b, 'item')
      const first = a ? a : '0'
      const second = b ? b : '0'
      console.log(first, second, 'item')

      if (first < second) {
        return softOrder === 'asc' ? -1 : 1;
      } else {
        if (first > second) {
          return softOrder === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
    }

    const compareFn = (i: UploadReleasePolicyItem, j: UploadReleasePolicyItem) => {
      switch (soft) {
        case 'fileName':
          return compareItem(i.fileUploadName, j.fileUploadName)
        case 'user':
          return compareItem(i.createdName, j.createdName)
        case 'date':
          return compareItem(i.createdDate, j.createdDate)
        case 'status':
          return compareItem(i.status, j.status)
        case 'errorFileName':
          return compareItem(i.fileErrorName, j.fileErrorName)
        case 'lineSuccess':
          return compareItem(i.linesSuccess, j.linesSuccess)
        case 'lineFail':
          return compareItem(i.linesFailed, j.linesFailed)
        default:
          return 0;
      }
    }
    const dataFilter = dataSource.sort(compareFn)
    setSoftOrder(softOrder === 'asc' ? 'desc' : 'asc')
    setDataSource([...dataFilter])
  }

  return (
    <ScrollView style={{ height: '100%', width: '100%', backgroundColor: '#FFFFFF' }}>
      <ImportDoc importFile={importFile}></ImportDoc>
      {dataSource && dataSource.length > 0 && (
        <View style={{ margin: 20, borderRadius: 8 }}>
          <TableContainer component={Paper} style={{ maxHeight: 500 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align='left' className={classes.tableCell} style={{ paddingLeft: 30 }}>
                    {t('ReleasePolicy:FileName')}
                    <TableSortLabel
                      active={softItem === 'fileName'}
                      direction={softOrder}
                      onClick={() => sortData('fileName')}
                      style={{ marginLeft: 10 }}
                    // IconComponent={() => <View style={{marginLeft: 20}}>{sortOrder === 'asc' ? <assets.ArrowDownDropdownIcon /> : }</View>}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {t('ReleasePolicy:SubmissionUser')}
                    <TableSortLabel
                      active={softItem === 'user'}
                      direction={softOrder}
                      onClick={() => sortData('user')}
                      style={{ marginLeft: 10 }}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {t('ReleasePolicy:LastUpdateDate')}
                    <TableSortLabel
                      active={softItem === 'date'}
                      direction={softOrder}
                      onClick={() => sortData('date')}
                      style={{ marginLeft: 10 }}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {t('ReleasePolicy:Status')}
                    <TableSortLabel
                      active={softItem === 'status'}
                      direction={softOrder}
                      onClick={() => sortData('status')}
                      style={{ marginLeft: 10 }}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {t('ReleasePolicy:ErrorFile')}
                    <TableSortLabel
                      active={softItem === 'errorFileName'}
                      direction={softOrder}
                      onClick={() => sortData('errorFileName')}
                      style={{ marginLeft: 10 }}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {t('ReleasePolicy:LineSuccess')}
                    <TableSortLabel
                      active={softItem === 'lineSuccess'}
                      direction={softOrder}
                      onClick={() => sortData('lineSuccess')}
                      style={{ marginLeft: 10 }}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.tableCell}>
                    {t('ReleasePolicy:LineFail')}
                    <TableSortLabel
                      active={softItem === 'lineFail'}
                      direction={softOrder}
                      onClick={() => sortData('lineFail')}
                      style={{ marginLeft: 10 }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ paddingLeft: 30 }} align='left'>
                      <TouchableOpacity
                        onPress={() => {
                          if (row.fileUploadUrl && row.fileUploadName)
                            downloadFile(row.fileUploadUrl, row.fileUploadName)
                        }}>
                        <Text style={[{ color: '#1ea5fc', textDecorationLine: 'underline' }, styles.cellNoWrap]}>
                          {row.fileUploadName ? row.fileUploadName : '-'}
                        </Text>
                      </TouchableOpacity>
                    </TableCell>
                    <TableCell align='left'>{row.createdName ? row.createdName : '-'}</TableCell>
                    <TableCell align='left'>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                    <TableCell align='left' style={{ color: row.status === 0 ? '#4CAF50' : '#FF6F00' }}>
                      {row.status === 0 ? 'Done' : row.status === 1 ? 'In process' : ''}
                    </TableCell>
                    <TableCell align='left'>
                      <TouchableOpacity
                        onPress={() => {
                          if (row.fileErrorUrl && row.fileErrorName)
                            downloadFile(row.fileErrorUrl, row.fileErrorName)
                        }}>
                        <Text style={[{ color: '#1ea5fc', textDecorationLine: 'underline' }, styles.cellNoWrap]}>
                          {row.fileErrorName ? row.fileErrorName : ''}
                        </Text>
                      </TouchableOpacity>
                    </TableCell>
                    <TableCell align='left'>{row.linesSuccess ? row.linesSuccess : 0}</TableCell>
                    <TableCell align='left'>{row.linesFailed ? row.linesFailed : 0}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={pageNum}
            rowsPerPageOptions={[5, 10, 25]}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              setPageNum(page)
              handlePaging(page, pageSize)
            }}
            onRowsPerPageChange={(e) => {
              const rowsPerPage = Number(e.target.value)
              setPageSize(rowsPerPage)
              if (pageNum * rowsPerPage >= totalItem) {
                setPageNum(0)
                handlePaging(0, rowsPerPage)
              } else {
                handlePaging(pageNum, rowsPerPage)
              }
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          ></TablePagination>
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  cellHeader: {
    minWidth: 180,
    fontWeight: 'bold',
    fontSize: 15,
    color: '#70777E'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle
} as const)
