import React from 'react'
import { TouchableOpacity, View, Text, ScrollView, ActivityIndicator, Pressable } from 'react-native'
import {
  assets,
  form2,
  AppContext,
  SelectSearch,
  useMobile,
  OfficeCode,
  DatePicker,
  useTranslator,
  CanView,
  PulseOpsFormat
} from '@pulseops/common'
import styled from 'styled-components/native'
import _ from 'lodash'
import { Controller } from 'react-hook-form'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import { UnitLinkFundPriceForm } from './unit-link-fund-form'
import { useLoading } from '@mxt/zio-react'
import { StackScreenProps } from '@react-navigation/stack'
import { UtilitiesStackParamList } from '../utilites-stack-params-list'
import * as rne from 'react-native-elements'
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Table
} from '@material-ui/core'
import { UnitLinkFormConst } from './unit-link-const'
import i18next from 'i18next'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { UnitFundFundPriceService } from './unit-link-fund-services'
import moment from 'moment'

type props = StackScreenProps<UtilitiesStackParamList, 'UnitFundFundPriceScreen'>

export const UnitFundFundPriceScreen = ({ navigation }: props) => {
  const [officeCodeSelected, setOfficeCodeSelected] = React.useState<OfficeCode>()
  const [isGA, setIsGA] = React.useState(false)
  const scrollViewRef = React.useRef<ScrollView>(null)
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()
  const cep = useTranslator('CustomerEngagement').t
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const { isMobile } = useMobile()
  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue,
      reset,
      trigger
    },
    handleSubmit
  } = form2.useForm(UnitLinkFundPriceForm.codec)

  const [maxDate] = React.useState<Date>(new Date())
  const [loadingTable, setLoadingTable] = React.useState<boolean>(false)
  const [dataSource, setDataSource] = React.useState<Array<UnitFundFundPriceService.UnitLinkItemC>>([])
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [loading, bindLoader] = useLoading(false)
  const formError = _.get(errors, '.message')
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Utilities'),
          navigate: () => {
            navigation.navigate('Utilities')
          }
        },
        {
          title: t('utilities:UnitLinkFundPriceHistory'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const unitLinkColumns = [
    {
      key: 1,
      title: cep('FundCode'),
      name: 'fundName',
      flex: 1
    },
    {
      key: 2,
      title: cep('FundName'),
      name: 'partialWithdrawal',
      flex: 2
    },
    {
      key: 3,
      title: cep('FundType'),
      name: 'partialWithdrawal',
      flex: 2
    },
    {
      key: 4,
      title: cep('EffectiveDate'),
      name: 'partialWithdrawal',
      flex: 2
    },
    {
      key: 5,
      title: cep('BarePrice'),
      name: 'partialWithdrawal',
      flex: 2
    },
    {
      key: 6,
      title: cep('BidPrice'),
      name: 'partialWithdrawal',
      flex: 2
    },
    {
      key: 7,
      title: cep('OfferPrice'),
      name: 'partialWithdrawal',
      flex: 2
    },
    {
      key: 8,
      title: cep('DateLastUpdated'),
      name: 'partialWithdrawal',
      flex: 2
    }
  ]

  const getData = (submitedData: UnitFundFundPriceService.QueryData) =>
    pipe(
      UnitFundFundPriceService.getDataUnitLinkFundPrice(submitedData),
      ZIO.map((res) => {
        return res
      })
    )

  const handlePagingData = handleSubmit((validated) => {
    const payload = {
      start: pageNum,
      size: pageSize,
      data: {
        fundCode: validated.fundList?.value ?? '',
        fundName: '',
        fromDate: validated.fromDate ? moment(validated.fromDate).format('DD/MM/yyyy') : undefined,
        toDate: validated.toDate ? moment(validated.toDate).format('DD/MM/yyyy') : undefined
      }
    }

    pipe(
      getData(payload),
      ZIO.map((res) => {
        setDataSource(res?.data ?? [])
        setTotalItem(res?.total ?? 0)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  })
  return (
    <ScrollView ref={scrollViewRef} style={{ backgroundColor: 'white' }}>
      <SC.Container>
        <SC.RowContainer>
          <SC.Line />
          <SC.Tab>
            <SC.TabText>{t('utilities:UnitLinkFundPriceHistory')}</SC.TabText>
          </SC.Tab>
        </SC.RowContainer>

        <SC.Header>
          <SC.RowContainer>
            <SC.SelectContainer isMobile={isMobile}>
              <Controller
                control={control}
                name="fundList"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectSearch
                      onChange={onChange}
                      label={t('common:FundName')}
                      //showUnderline={true}
                      placeholder={t('common:Select')}
                      value={value || { label: '', value: '' }}
                      options={UnitLinkFormConst.ListAllFunds.filter((item) => {
                        return !item.name.includes('Topup')
                      }).map((fund: { code: string; name: string }) => ({
                        label: t(fund.name),
                        value: fund.code
                      }))}
                      // errorMessage={_.get(errors, 'fundList')}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      labelStyle={{ marginBottom: 8 }}
                    />
                  )
                }}
              />
            </SC.SelectContainer>
            <SC.Divider />
            <SC.SelectContainer isMobile={isMobile}>
              <Controller
                control={control}
                name={'fromDate'}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      label={t('common:FromDate')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={!!value ? '' : error?.message}
                      maxDate={maxDate}
                      maxDateMessage={'Date should not be future'}
                      alwaysShow
                    />
                  )
                }}
              />
            </SC.SelectContainer>
            <SC.Divider />
            <SC.SelectContainer isMobile={isMobile}>
              <Controller
                control={control}
                name={'toDate'}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      label={t('common:ToDate')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={!!value ? '' : error?.message}
                      maxDate={maxDate}
                      maxDateMessage={'Date should not be future'}
                      alwaysShow
                    />
                  )
                }}
              />
            </SC.SelectContainer>
          </SC.RowContainer>
          {formError && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{formError}</Text>}
          <SC.RowContainer style={{ justifyContent: 'center', marginTop: 30 }}>
            <SC.ButtonContainer>
              <SC.Button
                title={t('CustomerEngagement:Search')}
                icon={<assets.IconCepSearch />}
                onPress={() => {
                  handlePagingData()
                }}
                loading={false}
              ></SC.Button>
            </SC.ButtonContainer>
          </SC.RowContainer>
        </SC.Header>

        <SC.Result>
          <SC.RowContainer>
            <SC.TabText style={{ color: '#70777E' }}>{t('CustomerEngagement:Result')}</SC.TabText>
            <View style={{ marginTop: 20, marginBottom: 20, maxWidth: '100%' }}>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      {unitLinkColumns.map((column, index) => (
                        <TableCell
                          key={column.key ? column.key : `table-header-${index}-${column.name}`}
                          align="left"
                          style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSource.length === 0 && loadingTable && (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                          <CanView condition={loadingTable}>
                            <View
                              style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingVertical: 10 }}
                            >
                              <ActivityIndicator size="large" color="red" />
                            </View>
                          </CanView>
                        </TableCell>
                      </TableRow>
                    )}
                    {dataSource.length > 0 ? (
                      dataSource.map((data, i) => (
                        <TableRow key={i}>
                          <TableCell>{data.fundCode ?? '-'}</TableCell>
                          <TableCell>{data.fundName ?? '-'}</TableCell>
                          <TableCell>{data.unitType ?? '-'}</TableCell>
                          <TableCell>{data.effDate ?? '-'}</TableCell>
                          <TableCell>{PulseOpsFormat.thousandSepartor(data.barePrice) ?? '-'}</TableCell>
                          <TableCell>{PulseOpsFormat.thousandSepartor(data.bidPrice) ?? '-'}</TableCell>
                          <TableCell>{PulseOpsFormat.thousandSepartor(data.unitPrice) ?? '-'}</TableCell>
                          <TableCell>{data.lastUpdatedTimestamp ?? '-'}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        {!loadingTable && (
                          <TableRow>
                            <TableCell style={{ textAlign: 'center' }} colSpan={22}>
                              {t('common:Nodata')}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                  page={pageNum}
                  rowsPerPageOptions={[5, 10, 25]}
                  rowsPerPage={pageSize}
                  count={totalItem}
                  onPageChange={(e, page) => {
                    setPageNum(page)
                    setDataSource([])
                    setLoadingTable(true)
                  }}
                  onRowsPerPageChange={(e) => {
                    const rowsPerPage = Number(e.target.value)
                    setPageSize(rowsPerPage)
                    setDataSource([])
                    setLoadingTable(true)
                  
                    if (pageNum * rowsPerPage >= totalItem) {
                      setPageNum(0)
                    } else {
                    }
                  }}
                  labelRowsPerPage={t('common:PaginationSize')}
                  labelDisplayedRows={({ from, to, count }) => i18next.t('common:Pagination', { from, to, count })}
                  component={View}
                ></TablePagination> */}
            </View>
          </SC.RowContainer>
        </SC.Result>
      </SC.Container>
    </ScrollView>
  )
}

const SC = {
  Container: styled(View)`
    align-items: flex-start;
    align-self: center;
    width: 70%;
    height: 100%;
  `,
  Header: styled(View)`
    width: 100%;
    margin-top: 24px;
    align-self: center;
    align-items: flex-start;
    padding: 20px;
    border-width: 1px;
    border-radius: 8px;
    background-color: #ffffff;
    border-color: #d3dce6;
  `,
  Result: styled(View)`
    width: 100%;
    margin-top: 24px;
    align-self: center;
    align-items: flex-start;
    padding: 20px;
    border-width: 1px;
    border-radius: 8px;
    background-color: #ffffff;
    border-color: #d3dce6;
  `,
  SelectContainer: styled(View)<{ isMobile?: boolean; width?: string }>`
    width: ${(p) => p.width || (p.isMobile ? '100%' : '32%')};
    /* min-width: max-content; */
    //max-width: 300px;
  `,
  InputContainer: styled(View)<{ error?: boolean; isMobile?: boolean; width?: string }>`
    width: ${(p) => p.width || (p.isMobile ? '100%' : '32%')};
    padding: 5px 0px 0px 0px;
    //max-width: 300px;
  `,
  RowContainer: styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content: space-between;
    width: 100%;
  `,
  Divider: styled(View)`
    width: 10px;
    height: 10px;
  `,
  RequestContainer: styled(View)`
    padding-top: 8px;
    width: 100%;
  `,
  PanelBody: styled(View)`
    padding: 0px 10px 0px 10px;
    align-items: flex-start;
    overflow: hidden;
  `,
  ButtonContainer: styled(TouchableOpacity)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  Padding: styled(View).attrs<{ paddingVertical: number; paddingHorizontal: number }>((p) => ({
    style: {
      padding: p.paddingVertical
    }
  }))<{ paddingVertical: number; paddingHorizontal: number }>``,

  Horizonlayout: styled(View)`
    flex-direction: row;
    align-self: center;
    justify-content: center;
  `,
  FooterContainer: styled(View)`
    padding: 20px 0px 20px 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
  `,
  TextHeader: styled(Text)`
    color: #70777e;
    font-weight: bold;
    font-size: 15;
  `,
  Line: styled(View)`
    position: absolute;
    bottom: 0px;
    height: 2px;
    border-width: 1;
    border-color: #d3dce6;
    width: 100%;
  `,
  Tab: styled(View)`
    min-width: 50px;
    justify-content: center;
    align-items: flex-start;
    border-bottom-color: red;
    border-bottom-width: 2px;
  `,
  TabText: styled(Text)`
    color: red;
    font-weight: bold;
    font-size: medium;
    padding: 5px 0px 5px 0px;
  `,
  TextSession: styled(Text)`
    color: black;
    font-weight: bold;
    font-size: 15px;
    padding-top: 16px;
  `,
  ErrorText: styled(Text)`
    color: red;
    font-size: 12px;
    padding: 5px 0px 5px 0px;
  `,
  Button: styled(rne.Button).attrs((p) => ({
    buttonStyle: {
      paddingVertical: 6,
      paddingHorizontal: 20,
      borderRadius: 20,
      borderWidth: 2
    },
    titleStyle: {
      fontFamily: p.theme.fonts.main,
      fontSize: 12,
      fontWeight: 700
    },
    icon: p.icon && <View style={{ marginRight: p.title ? 8 : 0 }}>{p.icon}</View>,
    disabled: p.disabled || p.loading
  }))``
}
