import { Paper } from '@material-ui/core'
import { Any, Task, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  AmlResult,
  DetailService,
  ImportButton,
  ImportService,
  Shareholder,
  Table,
  TableFooterPagination,
  TableStatus,
  TextLink,
  UtilRow
} from '@pulseops/business/core'
import { didUpdate, ErrorHandling, Permission, RBAC, subscribe } from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import { ContractDetailContext } from '../contract-detail-context'
import { ShareholderDetail } from './shareholder-detail'
import { useIsFocused } from '@react-navigation/native'

export const ShareholderList = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)
  const isFocused = useIsFocused()
  const service = DetailService.contract(contractId)
  const [calledTimes, setCalledTimes] = React.useState<number>(0)
  const [size, setSize] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [loading, bindLoading] = useLoading(false)

  const [detailKey, setDetailKey] = React.useState<string | null>(null)

  // pipe(
  //   didUpdate([size, page]),
  //   ZStream.chainEffect(([size, page]) => pipe(service.getShareholderList({ size, page }), bindLoading)),
  //   subscribe()
  // )

  React.useEffect(() => {
    pipe(
      service.getShareholderList({ size, page, isFocused, calledTimes }),
      ZIO.tap((_) => {
        setCalledTimes(calledTimes + 1)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }, [size, page])

  const rows: Shareholder[] | null = pipe(
    didUpdate([size, page]),
    ZStream.switchMap(([size, page]) => service.state.shareholder.size(size).watchPage(page)),
    subscribe([])
  )

  const total = pipe(
    didUpdate([size]),
    ZStream.switchMap(([size]) => service.state.shareholder.size(size).watchTotal),
    subscribe(0)
  )

  const reload = ZIO.effect(() => {
    if (page === 0) {
      pipe(service.getShareholderList({ size, page, isFocused, calledTimes }), bindLoading, ErrorHandling.run())
    } else {
      setPage(0)
    }
  })

  const [importLoading, bindImportLoading] = useLoading(false)

  if (detailKey) {
    return (
      <ShareholderDetail
        shareHolderKey={detailKey}
        onClose={() => {
          setDetailKey(null)
        }}
      />
    )
  }

  return (
    <>
      <UtilRow>
        {roles.includes(Permission['ImportShareholderGroupDetailGeneralInfo']) && (
          <ImportButton
            loading={importLoading}
            onImport={(file) => {
              pipe(
                ImportService.uploadShareholder(contractId)(file),
                ZIO.tap(
                  E.fold(
                    (): Task<Any> => ZIO.unit,
                    () => ZIO.zip(service.state.shareholder.clear, reload)
                  )
                ),
                bindImportLoading,
                ErrorHandling.run()
              )
            }}
          />
        )}
      </UtilRow>

      <Table.Container component={Paper}>
        <Table.Main>
          <Table.Head>
            <Table.Row>
              <Table.CellHead>{t('full_name')}</Table.CellHead>
              <Table.CellHead>{t('id_card_no')}</Table.CellHead>
              <Table.CellHead>{t('contrib_ratio')}</Table.CellHead>
              <Table.CellHead>{t('aml_result')}</Table.CellHead>
              <Table.CellHead />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <TableStatus colSpan={5} loading={loading} rows={rows} />
            {rows?.map((row) => (
              <Table.Row key={row.idNumber}>
                <Table.Cell>{row.fullName}</Table.Cell>
                <Table.Cell>{row.idNumber}</Table.Cell>
                <Table.Cell>{row.capitalContributionRatio}</Table.Cell>
                <Table.Cell>{AmlResult.formatLatest(row.historyAmlResult).result}</Table.Cell>
                <Table.Cell>
                  <Pressable
                    onPress={() => {
                      setDetailKey(row.shareHolderKey)
                    }}
                  >
                    <TextLink>{t('view_more')}</TextLink>
                  </Pressable>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooterPagination
            total={total}
            size={size}
            page={page}
            onPageChange={setPage}
            onSizeChange={setSize}
            colspan={5}
          />
        </Table.Main>
      </Table.Container>
    </>
  )
}
