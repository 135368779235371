import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Frequency, mapFrequency } from '../Frequency'
import { SubmissionService } from '../../SubmissionService'
import { PolicyS } from '../Policy'
import { PulseOpsFormat } from '../../../Formatter'

export interface BillingChangeReversal {
  tag: TransactionType.BILLING_FREQUENCY_REVERSAL

  clientName: string
  currentFrequency: string | null
  currentRenewalPremium: number | null
  oldFrequency: string | null
  oldRenewalPremium: number | null
  transactionDate: string
  loanInterest: number | null
  requirePayinAmount: number | null
}

export const BillingChangeReversal = (
  detail: api.BillingChangeReversal,
  clientName: string,
  info: SubmissionService.PolicyType,
  policyInfo: PolicyS
): BillingChangeReversal => {
  // const a = MoreData.APLTransactionDate.is(transactionDate.body) ? transactionDate.body : null
  // const b = a ? a.transactionDate : null
  // const transDate = !b || (b && b === 99999999) ? null : b
  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.EKIOSK:
    case SourceType.PRUGREAT:
    case SourceType.PULSE:
      const p = detail.payload.body
      console.log(p, policyInfo)
      return {
        tag: TransactionType.BILLING_FREQUENCY_REVERSAL,

        // clientName: poNameSql.body?.poName || getClientInfo(client)((client) => client.clientName),
        clientName,
        // currentFrequency: p.curBillingFrequency ||
        //   (sql.body && sql.body.renewalPremium
        //     ? mapFrequencyFromSQL.get(sql.body.billingFrequency) || null
        //     : null)
        // ,
        currentFrequency: mapFrequencyFromSQL.get(info.billFreq || '-') || '-',
        currentRenewalPremium: info.totalPremium || 0,
        oldFrequency: p.oldBillingFreq || (!!p.oldBillingFrequency ? p.oldBillingFrequency[0] : '-') || '-',
        // oldFrequency: mapFrequencyFromSQL.get(info. || '-') ?? '-',
        // oldRenewalPremium: policyInfo.attributesExt?.basicPremium ?? 0,
        // transactionDate: PulseOpsFormat.dateStrWF(policyInfo.paidToDateBasic,'DD/MM/YYYY'),
        oldRenewalPremium: null, //API not return
        transactionDate: '-', //API not return
        loanInterest: Number(p.automaticPolicyLoanInterest),
        requirePayinAmount: Number(p.payInAmount)
      }
    case SourceType.ZALO:
    case SourceType.PRUONLINE:
    case SourceType.PULSE4OPS:
      const pulseOpBody = detail.payload.body
      return {
        tag: TransactionType.BILLING_FREQUENCY_REVERSAL,
        clientName,
        currentFrequency: pulseOpBody.curBillingFrequency
          ? mapFrequency.get(pulseOpBody.curBillingFrequency as Frequency) ?? ''
          : '',
        currentRenewalPremium: pulseOpBody.curInstalmentAmount ? Number(pulseOpBody.curInstalmentAmount) : 0,
        oldFrequency: pulseOpBody.oldBillingFrequency
          ? mapFrequency.get(pulseOpBody.oldBillingFrequency[0] as Frequency) ?? ''
          : '',
        oldRenewalPremium: pulseOpBody.oldInstalmentAmount ? Number(pulseOpBody.oldInstalmentAmount) : 0,
        transactionDate: pulseOpBody.automaticPolicyLoanInterestDate
          ? PulseOpsFormat.dateStrWF(pulseOpBody.automaticPolicyLoanInterestDate[0], 'DD/MM/YYYY')
          : '-',
        loanInterest: pulseOpBody.automaticPolicyLoanInterest ? Number(pulseOpBody.automaticPolicyLoanInterest) : 0,
        requirePayinAmount: pulseOpBody.payInAmount ? Number(pulseOpBody.payInAmount) : 0
      }
  }
}

const mapFrequencyFromSQL = new Map<string, string>([
  ['01', 'Annual'],
  ['02', 'Half Yearly'],
  ['04', 'Quarterly'],
  ['12', 'Monthly']
])
