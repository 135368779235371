import { POApi } from '@pulseops/common'
import { CandidateInformationModel } from './CandidateInformationModel'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'

export namespace CandidateInformationServices {
  export const ReportDataC = t.type({
    body: Nullable(t.string)
  })

  export type ReportDataC = t.TypeOf<typeof ReportDataC>

  export const DataList = t.type({
    agentCategory: Maybe(t.string),
    agentCode: Nullable(t.string),
    agentType: Maybe(t.string),
    author: Nullable(t.string),
    businessKey: Maybe(t.string),
    clientId: Nullable(t.string),
    email: Maybe(t.string),
    eref: Maybe(t.string),
    gaCode1: Maybe(t.string),
    gaCode2: Maybe(t.string),
    gaType: Maybe(t.string),
    galleries: Maybe(t.string),
    id: Maybe(t.string),
    officeCode1: Maybe(t.string),
    officeCode2: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    ref: Maybe(t.string),
    regionCode: Maybe(t.string),
    saleType: Maybe(t.string),
    saleZone: Maybe(t.string),
    serviceBranch: Maybe(t.string),
    source: Maybe(t.string),
    status: Maybe(t.string),
    submissionDate: Maybe(t.number),
    taskId: Nullable(t.string),
    transactionType: Maybe(t.string),
    applicationInfoView: Maybe(
      t.type({
        agCodeReinAgent: Maybe(t.string),
        channelLink: Maybe(t.string),
        eref: Maybe(t.string),
        leaderCode: Maybe(t.string),
        leaderCodeDescription: Maybe(t.string),
        ocr: Maybe(t.string),
        officeCode: Maybe(t.string),
        oldUnit: Maybe(t.string),
        regionCode: Maybe(t.string),
        saleZone: Maybe(t.string),
        salesType: Maybe(t.string),
        subChanel: Maybe(t.string),
        submissionDate: Maybe(t.string),
        termiNationReason: Maybe(t.string),
        terminatedDate: Nullable(t.string)
      })
    ),
    bankAccountInfoView: Maybe(
      t.type({
        bankAccount: Maybe(t.string),
        bankBranchName: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        bankBranchNameOther: Nullable(t.string),
        bankCode: Maybe(t.string),
        bankName: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        bankNameOther: Nullable(t.string)
      })
    ),
    candidateInfoView: Maybe(
      t.type({
        checkDuplicate: Maybe(t.boolean),
        dateOfBirth: Maybe(t.string),
        firstName: Maybe(t.string),
        gender: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        idNo: Maybe(t.string),
        lastName: Maybe(t.string),
        oldIDNo: Maybe(t.string)
      })
    ),
    contactInfoView: Maybe(
      t.type({
        companyPhone: Maybe(t.string),
        districtAllocated: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        districtContact: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        districtResidential: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        email: Maybe(t.string),
        homePhone: Maybe(t.string),
        locationCodeAllocated: Maybe(t.string),
        locationCodeContact: Maybe(t.string),
        locationCodeResidential: Maybe(t.string),
        phoneNumber: Maybe(t.string),
        provinceAllocated: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        provinceContact: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        provinceResidential: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        streetAllocated: Maybe(t.string),
        streetContact: Maybe(t.string),
        streetResidential: Maybe(t.string),
        wardAllocated: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        wardContact: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        wardResidential: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        zalo: Maybe(t.string)
      })
    ),
    educationInfoView: Maybe(
      t.type({
        education: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        income: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        occupation: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        specialization: Maybe(t.string),
        taxCode: Maybe(t.string)
      })
    ),
    maritalStatusView: Maybe(
      t.type({
        maritalStatus: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        numberOfChildren: Maybe(t.string)
      })
    ),
    otherInfoView: Maybe(
      t.type({
        agCAT: Nullable(t.string),
        agentCodeRefer: Maybe(t.string),
        agentNameRefer: Maybe(t.string),
        ethNic: Maybe(t.string),
        idDate: Maybe(t.string),
        idExpiredDate: Maybe(t.string),
        idPlace: Maybe(t.string),
        major: Maybe(t.string),
        nationality: Maybe(t.type({ value: Maybe(t.string), label: Maybe(t.string) })),
        note: Maybe(t.string),
        placeOfBirth: Maybe(t.string),
        potentialCustomer: Maybe(t.string),
        status: Maybe(t.string)
      })
    ),
    qsCoreInfoView: Maybe(
      t.type({
        point: Nullable(t.string)
      })
    )
  })

  export type DataList = t.TypeOf<typeof DataList>

  export const ReportDataList = t.type({
    body: Maybe(
      t.type({
        currentPage: Maybe(t.number),
        elements: Maybe(t.array(DataList)),
        pageSize: Maybe(t.number),
        totalElement: Maybe(t.number),
        totalPage: Maybe(t.number)
      })
    ),
    responseStatus: Maybe(
      t.type({
        clientError: Nullable(t.string),
        code: Maybe(t.number),
        errors: Nullable(t.string),
        message: Maybe(t.string)
      })
    )
  })

  export type ReportDataList = t.TypeOf<typeof ReportDataList>

  export const getReportData = (query: CandidateInformationModel.QueryData) =>
    pipe(
      POApi.post(`distribution-agents-service/general-info/export-candidate-log`)(ReportDataC)(query),
      ZIO.map((res) => res)
    )

  export const getReport = (query: CandidateInformationModel.QueryDataPage) =>
    POApi.post(`distribution-agents-service/general-info/view-candidate-log`)(ReportDataList)(query)
}
