import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import { IBGeneralTable } from '../../../../../../common'
import { IBService, InvestmentDataInfo, InvestmentFund, TopUpHistoryDetail } from '../../../../../../ib-service'
import { useTranslation } from 'react-i18next'
import { isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { ModalComponent, TopUpConst } from '@pulseops/common'
interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  productType: string
  listFundT25?: InvestmentFund[]
  basicCode?: string
}

const ModalTopUpHistoryDetail = (props: {
  visible: boolean
  onClose: (visible: boolean) => void
  data: { policyNumber: string; transNo: string; topUpPremium: number }
  listFundT25?: InvestmentFund[]
}) => {
  const { width } = useWindowDimensions()
  const { visible, onClose, data, listFundT25 } = props
  const [loading, bindLoading] = useLoading(false)
  const [topUpHistoryDetail, setTopUpHistoryDetail] = React.useState<TopUpHistoryDetail[]>([])

  const { t, i18n } = useTranslation('Inbound')
  const getTopUpHistoryDetail = (data: { policyNumber: string; transNo: string }) =>
    pipe(
      IBService.getTopUpHistoryDetail(data.policyNumber, data.transNo),
      ZIO.map((res) => {
        setTopUpHistoryDetail(res.data)
      }),
      bindLoading,
      ZIO.unsafeRun([])
    )

  React.useEffect(() => {
    if (data) getTopUpHistoryDetail(data)
    return () => {
      setTopUpHistoryDetail([])
    }
  }, [data])

  const dataTable = [
    {
      label: t('TopUpFund'),
      field: 'fundCode'
    },
    {
      label: t('PercentTopUp'),
      field: 'percentAmount'
    },
    {
      label: t('ExcessPremium'),
      field: 'topUpPremium',
      format: 'money'
    },
    {
      label: t('AllocatedPremium'),
      field: 'topUpInvestment',
      format: 'money'
    },
    {
      label: t('RealUnitsFund'),
      field: 'realUnits'
    },
    {
      label: t('EffectivePrice'),
      field: 'effectivePrice',
      format: 'money'
    },
    {
      label: t('NonInvest'),
      field: 'nonInvest',
      format: 'money'
    }
  ]

  return (
    <ModalComponent
      title={`${t('TopUpHistoryDetail')}: ${data.transNo}`}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={topUpHistoryDetail.map((x) => ({
            ...x,
            fundCode: `${x.fundCode} ${
              i18n.language === 'vi'
                ? listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.inBoundNameVN
                : listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.fundNameEN
            }`,
            topUpPremium: data.topUpPremium
          }))}
          loading={loading}
        />
      </View>
    </ModalComponent>
  )
}

export const TopUpHistory = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo, productType, listFundT25, basicCode } = props
  const [loading, bindLoading] = useLoading()
  const [openModalDetail, setOpenModalDetail] = React.useState<boolean>(false)
  const [dataOpenModal, setDataOpenModal] = React.useState<{
    policyNumber: string
    transNo: string
    topUpPremium: number
  }>()
  React.useEffect(() => {
    if (isNil(investmentInfo?.topUpHistory)) {
      pipe(
        IBService.getTopUpHistory(
          policyNumber,
          productType,
          productType === 'ULP'
            ? basicCode === 'VLR4'
              ? 'T679'
              : ['VLR6', 'VLR7', 'VLR8'].includes(basicCode || '')
              ? 'B529'
              : ''
            : 'T679'
        ),
        ZIO.map((res) => {
          setInvestmentInfo({
            ...(investmentInfo as InvestmentDataInfo),
            topUpHistory: res.data
          })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataTableILP = [
    {
      label: t('TransNo'),
      field: 'transNo',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              const object = investmentInfo?.topUpHistory?.find((x) => x.transNo === value)
              setDataOpenModal({
                policyNumber: policyNumber,
                transNo: value,
                topUpPremium: object?.topUpPremium || 0
              })
              setOpenModalDetail(true)
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('TopUpTrandate'),
      field: 'topUpDate',
      format: 'date'
    },
    {
      label: t('ExcessPremium'),
      field: 'topUpPremium',
      format: 'money'
    },
    {
      label: t('AllocatedPremium'),
      field: 'topUpInvestment',
      format: 'money'
    }
  ]

  const dataTableULP = [
    {
      label: t('TopUpDate'),
      field: 'topUpDate',
      format: 'date'
    },
    {
      label: t('TopUpPremium'),
      field: 'topUpPremium',
      format: 'money'
    },
    {
      label: t('AllocatedPremiumToAccountValue'),
      field: 'accountAmount',
      format: 'money'
    }
  ]
  return (
    <View>
      {productType === 'ULP' ? (
        <IBGeneralTable
          data={
            orderBy(
              investmentInfo?.topUpHistory?.map((x) => ({
                ...x,
                topUpDate: moment(x.topUpDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                topUpPremium: Math.abs(x.topUpPremium || 0)
              })),
              'topUpDate',
              'desc'
            ) || []
          }
          dataTable={dataTableULP}
          loading={loading}
          autoPaging
        />
      ) : (
        <>
          <IBGeneralTable
            data={
              orderBy(
                investmentInfo?.topUpHistory?.map((x) => ({
                  ...x,
                  topUpDate: moment(x.topUpDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                  topUpPremium: x.moneySign === '-' ? (x.topUpPremium || 0) * -1 : x.topUpPremium
                })),
                ['topUpDate', 'transNo'],
                ['desc', 'desc']
              ) || []
            }
            dataTable={dataTableILP}
            loading={loading}
            autoPaging
          />
          {dataOpenModal ? (
            <ModalTopUpHistoryDetail
              visible={openModalDetail}
              onClose={setOpenModalDetail}
              data={dataOpenModal}
              listFundT25={listFundT25}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({})
