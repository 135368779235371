import { useLoading } from '@mxt/zio-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallOutDetailContext } from '../ob-common'
import { AppContext, Container, Input, Permission, Select, sharedStyle } from '@pulseops/common'
import { OBCallOutPopupService, OBContainer, OBFieldDescription, OBFieldTitle, OBQcService, OBQualityControlForm, OBREQCService, OBSectionCol, OBSectionContent, OBSectionRow, OBSharedStyles } from '@pulseops/outbound'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as O from 'fp-ts/lib/Option'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'

type QCTopicList = {
  topicCode: string
  topicENDesc: string
  topicVNDesc: string
}

const listAnswer = [
  {
    value: 'P',
    messageEn: 'Pass',
    messageVi: 'Đạt'
  },
  {
    value: 'F',
    messageEn: 'Fail',
    messageVi: 'Không đạt'
  },
  {
    value: 'NA',
    messageEn: 'N/A',
    messageVi: 'N/A'
  }
]

export const OBREQCScreen = (props: { mailInfo: string, isREQC?: boolean }) => {
  const [loading, bindLoader] = useLoading(false)
  const { t, i18n } = useTranslation()
  const { processInstanceId, isDisabled, caseId, policyNumber, assignee, teamLeader, callProgram,
    permissions, setIsSavedREQCScreen, transactionType, setIsNotREQCInputedRequiredField } = React.useContext(CallOutDetailContext)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [qcHistoryList, setQcHistoryList] = React.useState<OBREQCService.OBREQCHistory | null>(null)
  const [qcTotalScorePass, setQcTotalScorePass] = React.useState<number>(0)
  const [disableSave, setDisableSave] = React.useState<boolean>(true)
  const [qcTotalScore, setQcTotalScore] = React.useState<number>(0)
  const [listTopic, setListTopic] = React.useState<QCTopicList[]>([])
  const [callReceiverList, setCallReceiverList] = React.useState<OBCallOutPopupService.CallReceiverInfo[]>([])
  const disableEditByPermission = !permissions.includes(Permission['EditReQCOBTaskDetail'])
  const [isFinishFirstLoad, setFinishFirstLoad] = React.useState<boolean>(false)
  const [dataA12s6, setDataA12s6] = React.useState<OBQcService.A12S6Table[]>([])
  const [dataA12s2, setDataA12s2] = React.useState<OBQcService.A12S2Data[]>([])

  const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#F4F4F4'
    },
    tableCell: {
      minWidth: 120,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      color: '#70777E'
    },
    cellNoWrap: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()

  const columnsQCList = [
    t('Outbound:OBQC:No'),
    t('Outbound:OBQC:Categories'),
    t('Outbound:OBQC:Criteria'),
    t('Outbound:OBQC:Descriptions'),
    t('Outbound:OBREQC:FailureMechanism'),
    t('Outbound:OBREQC:MaxScore'),
    t('Outbound:OBQC:Value')
  ]

  const formQC = useForm<OBQualityControlForm.OBQualityControlDataSave>({
    defaultValues: {
      qcDataList: [],
      comment: ''
    }
  })

  const { fields, append } = useFieldArray<OBQualityControlForm.OBQualityControlDataSave>({
    control: formQC.control,
    name: 'qcDataList'
  })

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    pipe(
      ZIO.zipPar(
        OBREQCService.getHistorySave(processInstanceId),
        OBCallOutPopupService.getCallingResultList(),
        OBQcService.getA12S6Table(),
        OBQcService.getDataScoreA12S2()
      ),
      ZIO.map(([res, callReceiverRes, tableA12S6, a12s2]) => {
        res && setQcHistoryList(res)
        setCallReceiverList(callReceiverRes)
        tableA12S6 && setDataA12s6(tableA12S6)
        a12s2 && setDataA12s2(a12s2)
        if (res.reQCCommonInfo) {
          setQcTotalScorePass(res.reQCCommonInfo.reQCTotalPassScore ?? 0)
          setQcTotalScore(res.reQCCommonInfo.reQCTotalScore ?? 0)
          formQC.setValue('comment', res.reQCCommonInfo.comment ?? '')
        }
        res.reQCCriteriaList && setListTopic([...new Map(res.reQCCriteriaList.filter((p) => !!p.a12s1Criteria).map((item) => [item['a12s1Criteria']?.topicCode,
        {
          topicCode: item.a12s1Criteria?.topicCode ?? '',
          topicENDesc: item.a12s1Criteria?.topicENDesc ?? '',
          topicVNDesc: item.a12s1Criteria?.topicVNDesc ?? ''
        }
        ])).values()])
        res.reQCCriteriaList.map((item) => {
          // const dataAnwser = listAnswer.find((x) => x.value === item.answerValueCode)
          const dataAnwser = a12s2.find((x) => x.valueCode === item.answerValueCode)
          const itemQuestion = {
            criteriaCode: item.a12s1Criteria?.criteriaCode ?? '',
            answerValueCode: dataAnwser ? {
              value: dataAnwser?.valueCode,
              label: i18n.language === 'vi' ? dataAnwser?.valueVN : dataAnwser?.valueEN
            } : null
          }
          append(itemQuestion)
        })
        setFinishFirstLoad(true)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [processInstanceId])

  React.useEffect(() => {
    const qcDataList = formQC.watch('qcDataList')
    if (qcDataList && qcDataList.length > 0) {
      const isInValidData = qcDataList.some((item) => !item.answerValueCode || !item.answerValueCode.value)
      const isInValidComment = !formQC.watch('comment')
      // setIsNotInputedRequiredField && setIsNotInputedRequiredField(isInValidData || isInValidComment)
      setIsNotREQCInputedRequiredField && setIsNotREQCInputedRequiredField(isInValidData || isInValidComment)
    } else {
      // setIsNotInputedRequiredField && setIsNotInputedRequiredField(false)
      setIsNotREQCInputedRequiredField && setIsNotREQCInputedRequiredField(false)
    }
  }, [formQC.watch('qcDataList')])

  const getListOptionValue = (typeA1: boolean) => {
    const optionValue = dataA12s2.map((item) => ({
      value: item.valueCode,
      label: i18n.language === 'vi' ? item.valueVN : item.valueEN
    }))
    return typeA1 ? optionValue : optionValue.filter((i) => i.value !== 'PWC')
  }

  const findIndexQuestion = (criteriaCode: string) => {
    const findIndex = fields.findIndex((item) => item.criteriaCode === criteriaCode)
    return findIndex
  }

  const checkDisableSave = () => {
    const findItem = formQC.watch('qcDataList').find((item) => item.answerValueCode)
    // findItem && setDisableSave(false)
    if (findItem) {
      setDisableSave(false)
      // setIsSavedQCScreen && setIsSavedQCScreen(false)
      setIsSavedREQCScreen && setIsSavedREQCScreen(false)
    }
  }

  const getCallResultName = (callResultCode: string) => {
    return pipe(callReceiverList.find((x) => x.code === callResultCode), O.fromNullable, O.map((item) => i18n.language === 'en' ? item.nameEn : item.nameVi), O.getOrElse(() => ''))
  }

  const checkA7S6 = () => {
    const listFailureMechanism = qcHistoryList?.reQCCriteriaList.filter((qc) => qc.a12s1Criteria?.failureMechanism === 'Y')
    const checkFailureMechanism = formQC.watch('qcDataList').find((i) => i.answerValueCode?.value === 'F' && listFailureMechanism?.find((qc) => qc.a12s1Criteria?.criteriaCode === i.criteriaCode))
    if (checkFailureMechanism) {
      return 'OC01'
    }
    else {
      const qcRatiing = Math.round((qcTotalScorePass / qcTotalScore) * 10000) / 100
      const findItemCheckPass = dataA12s6.find((i) => i.transactionTypeWF === transactionType && i.comparisonOperator === '≥')
      const findItemCheckFail = dataA12s6.find((i) => i.transactionTypeWF === transactionType && i.comparisonOperator === '<')

      if (findItemCheckPass) {
        return (qcRatiing >= findItemCheckPass.qcRating) ? findItemCheckPass?.outcomeCode : (findItemCheckFail?.outcomeCode ?? '')
      }
    }
    return ''
  }

  const checkScoreValue = (value: string | undefined, oldValue: string | undefined, scoreMax: number) => {
    const scoreValue = dataA12s2.find((i => i.valueCode === value))?.valueScore
    const oldScoreValue = dataA12s2.find((i => i.valueCode === oldValue))?.valueScore

    if (scoreValue) {
      const newScore = scoreValue && !isNaN(Number(scoreValue)) ? Number(scoreValue) : 0
      const oldScore = oldScoreValue && !isNaN(Number(oldScoreValue)) ? Number(oldScoreValue) : 0
      switch (value) {
        case 'F': {
          if (!oldValue || oldValue === 'NA') setQcTotalScore(qcTotalScore + scoreMax)
          if ((oldValue === 'P' || oldValue === 'PWC')) setQcTotalScorePass(qcTotalScorePass - oldScore)
          return
        }
        case 'P': {
          if (!oldValue || oldValue === 'NA') setQcTotalScore(qcTotalScore + scoreMax)
          setQcTotalScorePass(qcTotalScorePass + newScore - oldScore)
          return
        }
        case 'NA': {
          if (oldValue) setQcTotalScore(qcTotalScore - scoreMax)
          if ((oldValue === 'P' || oldValue === 'PWC')) setQcTotalScorePass(qcTotalScorePass - oldScore)
          return
        }
        case 'PWC': {
          if (!oldValue || oldValue === 'NA') setQcTotalScore(qcTotalScore + scoreMax)
          setQcTotalScorePass(qcTotalScorePass + newScore - oldScore)
          return
        }
        default:
          return
      }
    }
    return
  }


  const saveHistory = async () => {
    const validate = await formQC.trigger()
    if (validate) {
      const qcList: OBREQCService.REQCList = formQC.watch('qcDataList').map((item) => {
        const findQcItem = (qcHistoryList && qcHistoryList.reQCCriteriaList.find((qcItem) => qcItem.a12s1Criteria?.criteriaCode === item.criteriaCode)?.a12s1Criteria) || {
          id: '',
          categoryENDesc: '',
          categoryVNDesc: '',
          criteriaENName: '',
          criteriaVNName: '',
          criteriaDescEN: '',
          criteriaDescVN: '',
          score: 0,
          topicCode: '',
          topicENDesc: '',
          topicVNDesc: '',
          answerTypeCode: '',
          failureMechanism: ''
        }
        return ({
          a12s1Criteria: {
            ...findQcItem,
            criteriaCode: item.criteriaCode ?? ''
          },
          answerValueCode: item.answerValueCode?.value ?? ''
        })
      })
      const reQCRating = Math.round((qcTotalScorePass / qcTotalScore) * 10000) / 100
      const mapDataSave: OBREQCService.OBSaveDataQC = {
        businessKey: caseId,
        processInstanceId: processInstanceId,
        reQCCommonInfo: {
          lastVerAssignee: qcHistoryList?.reQCCommonInfo.lastVerAssignee || '',
          callResult: qcHistoryList?.reQCCommonInfo.callResult || '',
          manualInternalFeedback: qcHistoryList?.reQCCommonInfo.manualInternalFeedback || '',
          reQCTotalPassScore: qcTotalScorePass,
          reQCTotalScore: qcTotalScore,
          lastQCAssignee: qcHistoryList?.reQCCommonInfo.lastQCAssignee || '',
          comment: formQC.watch('comment') ?? '',
          policyNumber: policyNumber,
          assignee: pipe(qcHistoryList?.reQCCommonInfo && qcHistoryList?.reQCCommonInfo.assignee, O.fromNullable, O.map((assigneeName) => assigneeName), O.getOrElse(() => '')),
          teamLeader: pipe(qcHistoryList?.reQCCommonInfo && qcHistoryList?.reQCCommonInfo.teamLeader, O.fromNullable, O.map((LeaderName) => LeaderName), O.getOrElse(() => '')),
          transactionType: transactionType || '',
          updateContactInfoFlag: qcHistoryList?.reQCCommonInfo.updateContactInfoFlag || '',
          reQCRating: reQCRating,
          reQCOutcome: checkA7S6()
        },
        reQCCriteriaList: qcList
      }
      // console.log(mapDataSave, 'mapDataSave')
      pipe(
        OBREQCService.saveHistoryREQC(mapDataSave),
        ZIO.map((response) => {
          showGlobalLoading(false)
          setDisableSave(true)
          setIsSavedREQCScreen && setIsSavedREQCScreen(true)
          // setIsNotInputedRequiredField && setIsNotInputedRequiredField(false)
          setIsNotREQCInputedRequiredField && setIsNotREQCInputedRequiredField(false)
          showToast(t('message:OB0040'), 'success')
          return response
        }),
        ZIO.catchAll((error) => {
          showGlobalLoading(false)
          showToast(t('message:OB0042'), 'error')
          return ZIO.fail(error)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const QCInfo = () => {
    return (
      qcHistoryList && qcHistoryList?.reQCCriteriaList.length > 0 &&
      <OBSectionContent style={{ marginBottom: 20 }}>
        <OBSectionRow>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBQC:CallResult')}></OBFieldTitle>
            <OBFieldDescription text={qcHistoryList?.reQCCommonInfo ? getCallResultName(qcHistoryList.reQCCommonInfo.callResult || '') : '-'}></OBFieldDescription>
          </OBSectionCol>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBQC:ManualInternalFB')}></OBFieldTitle>
            <OBFieldDescription text={qcHistoryList?.reQCCommonInfo?.manualInternalFeedback ? (qcHistoryList.reQCCommonInfo.manualInternalFeedback === 'Y' ? 'Yes' : 'No') : '-'}></OBFieldDescription>
          </OBSectionCol>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBREQC:UpdateContactInfo')}></OBFieldTitle>
            <OBFieldDescription text={qcHistoryList?.reQCCommonInfo?.updateContactInfoFlag ? (qcHistoryList.reQCCommonInfo.updateContactInfoFlag === 'Y' ? 'Yes' : 'No') : '-'}></OBFieldDescription>
          </OBSectionCol>
        </OBSectionRow>
        <OBSectionRow style={{ paddingTop: 20 }}>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBREQC:ReQCScore')}></OBFieldTitle>
            <OBFieldDescription text={qcTotalScorePass + '/' + qcTotalScore}></OBFieldDescription>
          </OBSectionCol>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBREQC:ReQCRatio')}></OBFieldTitle>
            <OBFieldDescription text={qcTotalScore > 0 ? `${Math.round((qcTotalScorePass / qcTotalScore) * 10000) / 100} %` : '0 %'}></OBFieldDescription>
          </OBSectionCol>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBREQC:ReQCResult')}></OBFieldTitle>
            <OBFieldDescription text={checkA7S6() ? t(`Outbound:OBREQC:${checkA7S6()}`) : '-'}></OBFieldDescription>
          </OBSectionCol>
        </OBSectionRow>
        <OBSectionRow style={{ paddingTop: 20 }}>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBREQC:LastAssigneeVer')}></OBFieldTitle>
            <OBFieldDescription text={qcHistoryList?.reQCCommonInfo ? qcHistoryList.reQCCommonInfo.lastVerAssignee ?? '' : '-'}></OBFieldDescription>
          </OBSectionCol>
          <OBSectionCol>
            <OBFieldTitle text={t('Outbound:OBREQC:UserQC')}></OBFieldTitle>
            <OBFieldDescription text={qcHistoryList?.reQCCommonInfo.lastQCAssignee || ''}></OBFieldDescription>
          </OBSectionCol>
        </OBSectionRow>
        <OBSectionRow style={{ paddingTop: 20 }}>
          <OBSectionCol style={OBSharedStyles.sectionCol11}>
            <Controller
              control={formQC.control}
              name="comment"
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020001', { field: t('Outbound:OBQC:Comment') })
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  title={t('Outbound:OBQC:Comment')}
                  required
                  labelStyle={{ fontSize: 13, paddingTop: 6 }}
                  disabled={isDisabled || disableEditByPermission || props.isREQC}
                  maxLength={1000}
                  multiline
                  numberOfLines={5}
                  value={value || ''}
                  onChange={(commentValue) => {
                    onChange(commentValue)
                    checkDisableSave()
                  }}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                />
              )}
            />
          </OBSectionCol>
        </OBSectionRow>
      </OBSectionContent>
    )
  }

  const TopicQC = (title: string, topicCode: string) => {
    const filterList = qcHistoryList ? qcHistoryList.reQCCriteriaList.filter((item) => item.a12s1Criteria?.topicCode === topicCode) : []
    return (
      <OBContainer containerTitle={title}>
        <View style={{ marginLeft: 20, marginRight: 20 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {columnsQCList.map((colItem, index) => {
                    return (
                      columnsQCList.length === index + 1 ? // column Value
                        <TableCell className={classes.tableCell} key={`colItem_' - ${index}`}>{
                          <>
                            <Text style={{ fontWeight: 'bold', color: '#70777E' }}>{colItem}</Text>
                            <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
                          </>
                        }</TableCell>
                        : <TableCell className={classes.tableCell} key={`colItem_' - ${index}`}>{colItem}</TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterList.map((item, index) => (
                  <TableRow>
                    <TableCell style={{ width: '8%' }} className={classes.cellNoWrap}>{index + 1}</TableCell>
                    <TableCell style={{ width: '28%' }}>{i18n.language === 'vi' ? item.a12s1Criteria?.categoryVNDesc ?? '' : item.a12s1Criteria?.categoryENDesc ?? ''}</TableCell>
                    <TableCell style={{ width: '28%' }}>{i18n.language === 'vi' ? item.a12s1Criteria?.criteriaVNName ?? '' : item.a12s1Criteria?.criteriaENName ?? ''}</TableCell>
                    <TableCell style={{ width: '40%' }}>{i18n.language === 'vi' ? item.a12s1Criteria?.criteriaDescVN ?? '' : item.a12s1Criteria?.criteriaDescEN ?? ''}</TableCell>
                    <TableCell style={{ width: '10%' }}>{item.a12s1Criteria?.failureMechanism ? (item.a12s1Criteria?.failureMechanism ?? '-') : '-'}</TableCell>
                    <TableCell style={{ width: '14%' }} className={classes.cellNoWrap}>{item.a12s1Criteria?.score ?? '-'}</TableCell>
                    <TableCell style={{ width: '10%' }} className={classes.cellNoWrap}>{
                      findIndexQuestion(item.a12s1Criteria?.criteriaCode ?? '') !== -1 ?
                        <Controller
                          control={formQC.control}
                          name={`qcDataList.${findIndexQuestion(item.a12s1Criteria?.criteriaCode ?? '') ?? index}.answerValueCode`}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <Select
                              value={value}
                              onChange={(statusVal) => {
                                onChange(statusVal)
                                checkDisableSave()
                                checkScoreValue(statusVal?.value, value?.value, item.a12s1Criteria?.score ?? 0)
                              }}
                              onBlur={onBlur}
                              disabled={isDisabled || disableEditByPermission || props.isREQC}
                              options={getListOptionValue(item.a12s1Criteria?.answerTypeCode === 'A1')}
                              errorMessage=''
                            />
                          )}
                        /> : '-'
                    }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      </OBContainer>
    )
  }

  const TopicQCList = () => {
    return isFinishFirstLoad && listTopic.map((item) =>
      <View>
        {TopicQC(i18n.language === 'vi' ? item.topicVNDesc : item.topicENDesc, item.topicCode)}
      </View>
    )
  }

  const ActionTab = () => {
    return (
      <OBSectionRow>
        {(isDisabled || disableEditByPermission || props.isREQC) ? (
          <></>
        ) : (
          qcHistoryList && qcHistoryList?.reQCCriteriaList.length > 0 &&
          <View style={[qcStyles.buttonContainer, { width: '100%', justifyContent: 'flex-start' }]}>
            <TouchableOpacity disabled={disableSave} style={[sharedStyle.button, disableSave ? sharedStyle.btnDisabled : sharedStyle.btnRed]} onPress={() => saveHistory()}>
              <Text style={[disableSave ? { color: '#B0B0B0' } : OBSharedStyles.actionHightLightText, { textAlign: 'center' }]}>{t('common:Save')}</Text>
            </TouchableOpacity>
          </View>
        )
        }
      </OBSectionRow>
    )
  }

  return (
    <Container loading={false}>
      <View style={qcStyles.container}>
        {QCInfo()}
        {TopicQCList()}
        {ActionTab()}
      </View>
    </Container>
  )
}

const qcStyles = StyleSheet.create({
  container: {
    display: 'flex',
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingVertical: 16
  },
  header: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16,
    marginBottom: 16
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70
  }
})