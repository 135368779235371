import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { CashoutOptions } from './CashoutOptions'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.PRUKID369)
    }),
    codec
  ])

export const Prukid369C = Base(
  t.type({
    source: t.string,
    payload: t.type({
      body: t.type({
        policy: t.type({
          policyNo: t.string
        }),
        attributesExt: t.type({
          TOTAL_PAYOUT_AMOUNT: t.number,
          PRU_KID_AMOUNT: t.number
        }),
        cashOutOptions: Maybe(CashoutOptions)
      })
    })
  })
)

export type Prukid369 = t.TypeOf<typeof Prukid369C>
