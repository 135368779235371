import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { IBButton, IBGeneralField, IBGeneralTable, TypeInputComponent } from '../../../../../common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { isNull } from 'lodash'
import { assets, TopUpConst } from '@pulseops/common'
import { useForm } from 'react-hook-form'
import { IBILPFundValueUnitForm } from './IBILPFundValueUnitForm'
import { pipe } from 'fp-ts/lib/function'
import { FundValueUnitData, IBService, InvestmentFund } from '../../../../../ib-service'
import moment from 'moment'
import { ZIO } from '@mxt/zio'

interface Props {
  listFundT25?: InvestmentFund[]
}
export enum FundType {
  TargetPremium = 'TP01',
  TopUp = 'TU02'
}
export const IBILPFundValueUnit = (props: Props) => {
  const [loading, bindLoading] = useLoading(false)
  const { t, i18n } = useTranslation('Inbound')
  const [data, setData] = React.useState<FundValueUnitData[]>([])
  const defaultValues: IBILPFundValueUnitForm = {
    fromDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    toDate: new Date(),
    fund: { label: '', value: '' }
  }

  const SearchForm = useForm<IBILPFundValueUnitForm>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })

  const dataTable = [
    {
      label: t('FundCode'),
      field: 'fundCode'
    },
    {
      label: t('FundName'),
      field: 'fundName'
    },
    {
      label: t('FundType'),
      field: 'unitType'
    },
    {
      label: t('EffectiveDateFVU'),
      field: 'effDate',
      format: 'date'
    },
    {
      label: t('BarePrice'),
      field: 'barePrice',
      format: 'money'
    },
    {
      label: t('BidPrice'),
      field: 'bidPrice',
      format: 'money'
    },
    {
      label: t('OfferPrice'),
      field: 'unitPrice',
      format: 'money'
    },
    {
      label: t('DateLastUpdated'),
      field: 'lastUpdatedTimestamp',
      format: 'date'
    }
  ]

  const onSearch = SearchForm.handleSubmit((value) => {
    pipe(
      IBService.UnitLinkFundPrice({
        fundCode: value.fund.value,
        fundName: '',
        fromDate: moment(value.fromDate).format('DD/MM/YYYY'),
        toDate: moment(value.toDate).format('DD/MM/YYYY')
      }),
      ZIO.map((res) => {
        if (res.data) {
          setData(
            res.data.map((x) => ({
              ...x,
              fundName:
                i18n.language === 'vi'
                  ? props.listFundT25?.find((z) => z.fundCode === (x.fundCode as string))?.inBoundNameVN
                  : props.listFundT25?.find((z) => z.fundCode === (x.fundCode as string))?.fundNameEN,
              effDate: moment(x.effDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              lastUpdatedTimestamp: moment(x.lastUpdatedTimestamp, 'DD/MM/YYYY').format('YYYYMMDD')
            }))
          )
        } else {
          setData([])
        }
      }),
      bindLoading,
      ZIO.catchAll((error) => {
        setData([])
        return ZIO.succeed(null)
      }),
      ZIO.unsafeRun({})
    )
  })

  return (
    <View>
      <View>
        <View style={styles.searchContainer}>
          <IBGeneralField
            FieldForm={SearchForm}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.SELECT,
                formName: 'fund',
                title: t('Inbound:FundName'),
                option: [
                  { label: t('Inbound:All'), value: '' },
                  ...(props.listFundT25
                    ?.filter(
                      (x) =>
                        x.fundTypeCode === FundType.TargetPremium &&
                        !['IFD7', 'VFD1', 'VFD2', 'VFD3', 'VFD5', 'VFT5', 'VFT6'].includes(x.fundCode as string)
                    )
                    .map((item) => ({
                      label: `${item.fundCode} ${
                        i18n.language === 'vi' ? (item.inBoundNameVN as string) : (item.fundNameEN as string)
                      }`,
                      value: item.fundCode as string
                    })) || [])
                ],
                disabled: loading
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'fromDate',
                title: t('FromDate'),
                rules: {
                  validate: () => {
                    const currentDate = new Date()
                    return !isNull(SearchForm.watch('fromDate')) &&
                      (SearchForm.watch('fromDate')?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:MS990032')}`
                      : true
                  }
                },
                disabled: loading,
                maxDate: SearchForm.watch('toDate'),
                maxDateMessage: t('message:MS990032')
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'toDate',
                title: t('ToDate'),
                rules: {
                  validate: () => {
                    const currentDate = new Date()
                    return !isNull(SearchForm.watch('toDate')) &&
                      (SearchForm.watch('toDate')?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:MS990032')}`
                      : true
                  }
                },
                disabled: loading,
                maxDate: new Date(),
                maxDateMessage: t('message:MS990032')
              }
            ]}
          />
        </View>
        <View style={styles.buttonContainer}>
          <IBButton
            onPress={onSearch}
            title={t('Search')}
            suffixIcon={<assets.IBSearchIcon />}
            backgroundFill
            disabled={loading}
          />
        </View>
      </View>
      <View style={{ margin: 15 }}>
        {data ? (
          <IBGeneralTable
            dataTable={dataTable}
            data={data}
            loading={loading}
            autoPaging={true}
            paginationStyle={{ marginRight: 60 }}
          />
        ) : (
          <></>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    alignItems: 'center',
    width: '100%'
  }
})
