import { StackScreenProps } from '@react-navigation/stack'
import { ReportsStackParamList } from '../ReportsStackParamList'
import { View, StyleSheet, ScrollView, SafeAreaView, TouchableOpacity, Text } from 'react-native'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  AppContext,
  DatePicker,
  ErrorHandling,
  Input,
  Permission,
  PulseOpsFormat,
  RBAC,
  Select,
  TransactionType,
  mapTransactionType
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { CheckingPayoutForm } from './CheckingPayoutForm'
import { useIsFocused } from '@react-navigation/native'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import moment from 'moment'
import { CheckingPayoutReportService, CheckingPayoutDataType } from '../Service/CheckingPayoutService'
import { ReportService } from '../Service/ReportExcelService'

type props = StackScreenProps<ReportsStackParamList, 'CheckingPayoutReportScreen'>

export const CheckingPayoutReportScreen = ({ navigation }: props) => {
  const { t } = useTranslation()
  const currentDate = new Date()
  const isFocused = useIsFocused()
  const [loading, bindLoader] = useLoading(false)
  const { changeBreadcrumb, showGlobalLoading } = useContext(AppContext.AppContextInstance)
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [dataReport, setDataReport] = React.useState<CheckingPayoutDataType[]>([])
  const [submittedData, setSubmittedData] = React.useState<CheckingPayoutReportService.PayloadReport>({})
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const totalOption = [
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    },
    {
      label: '3',
      value: '3'
    },
    {
      label: '4',
      value: '4'
    },
    {
      label: t('Reports:All'),
      value: 'All'
    }
  ]

  const defaultValue = {
    fromDate: currentDate,
    toDate: currentDate,
    policyNumber: '',
    transactionType: null,
    caseId: '',
    totalMethod: totalOption[1]
  }

  const columnCheckingPayout = [
    {
      id: 'businessKey',
      name: `Reports:CheckingPayoutTable.CaseId`,
      widthPx: 45
    },
    {
      id: 'policyNumber',
      name: `Reports:CheckingPayoutTable.PolicyNumber`,
      widthPx: 15
    },
    {
      id: 'transactionType',
      name: `Reports:CheckingPayoutTable.TransactionType`,
      widthPx: 30
    },
    {
      id: 'completedDate',
      name: `Reports:CheckingPayoutTable.CompletedDate`,
      widthPx: 20
    },
    {
      id: 'payPremium',
      name: `Reports:CheckingPayoutTable.PayPremium`,
      widthPx: 15
    },
    {
      id: 'repayLoan',
      name: `Reports:CheckingPayoutTable.RepayLoan`,
      widthPx: 15
    },
    {
      id: 'other',
      name: `Reports:CheckingPayoutTable.OtherMethod`,
      widthPx: 15
    },
    {
      id: 'bankTransfer',
      name: `Reports:CheckingPayoutTable.BankTranfer`,
      widthPx: 15
    },
    {
      id: 'paidAtBank',
      name: `Reports:CheckingPayoutTable.PaidAtBank`,
      widthPx: 15
    },
    {
      id: 'cashAtCounter',
      name: `Reports:CheckingPayoutTable.CashAtCounter`,
      widthPx: 15
    },
    {
      id: 'momo',
      name: `Reports:CheckingPayoutTable.Momo`,
      widthPx: 15
    },
    {
      id: 'totalMethod',
      name: `Reports:CheckingPayoutTable.TotalMethod`,
      widthPx: 15
    }
  ]

  const checkPayoutForm = useForm<CheckingPayoutForm.CheckingPayoutSearch>({
    defaultValues: defaultValue
  })

  const TransactionLabel = (type: TransactionType | null): string =>
    type ? t(`${mapTransactionType.get(type)}`) || '-' : '-'

  const transactions = (type: TransactionType) => ({
    label: TransactionLabel(type),
    value: type
  })

  const transactionPayoutOption = [
    transactions(TransactionType.BONUS_SURRENDER),
    transactions(TransactionType.CANCEL_FROM_INCEPTION),
    transactions(TransactionType.EXCELLENT_STUDENT_AWARD),
    transactions(TransactionType.EXCESS_PREMIUM),
    transactions(TransactionType.FULL_SURRENDER),
    transactions(TransactionType.MATURITY),
    transactions(TransactionType.LOAN),
    transactions(TransactionType.PARTIAL_SURRENDER),
    transactions(TransactionType.PARTIAL_WITHDRAWAL),
    transactions(TransactionType.PRUCASH_PAYMENT),
    transactions(TransactionType.PRUKID369),
    transactions(TransactionType.SPECIAL_BONUS)
  ]

  const titleHeaders = [
    t('Reports:CheckingPayoutTable.CaseId'),
    t('Reports:CheckingPayoutTable.PolicyNumber'),
    t('Reports:CheckingPayoutTable.TransactionType'),
    t('Reports:CheckingPayoutTable.CompletedDate'),
    t('Reports:CheckingPayoutTable.PayPremium'),
    t('Reports:CheckingPayoutTable.RepayLoan'),
    t('Reports:CheckingPayoutTable.OtherMethod'),
    t('Reports:CheckingPayoutTable.BankTranfer'),
    t('Reports:CheckingPayoutTable.PaidAtBank'),
    t('Reports:CheckingPayoutTable.CashAtCounter'),
    t('Reports:CheckingPayoutTable.Momo'),
    t('Reports:CheckingPayoutTable.TotalMethod')
  ]

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:CheckingPayout'),
          navigate: null
        }
      ])
    }
    checkPayoutForm.reset(defaultValue)
    setTotalItem(0)
    setPageSize(10)
    setPageNum(0)
    setDataReport([])
    setMessageResponse(null)
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const search = checkPayoutForm.handleSubmit((data) => {
    setPageNum(0)
    setMessageResponse(null)
    const getSubmitData = (): CheckingPayoutReportService.PayloadReport => {
      return {
        fromDate: data.fromDate ? PulseOpsFormat.datetoFormat(data.fromDate, 'YYYYMMDD') : undefined,
        toDate: data.toDate ? PulseOpsFormat.datetoFormat(data.toDate, 'YYYYMMDD') : undefined,
        transactionType: data.transactionType ? data.transactionType?.value : undefined,
        policyNumber: data.policyNumber,
        businessKey: data.caseId,
        totalMethod: data.totalMethod && data.totalMethod.value !== 'All' ? Number(data.totalMethod.value) : undefined
      }
    }

    const submitData: CheckingPayoutReportService.PayloadReport = getSubmitData()
    setSubmittedData(submitData)
    getDataReport(submitData, pageSize, pageNum)
  })

  const searchToExport = checkPayoutForm.handleSubmit((data) => {
    const getSubmitData = (): CheckingPayoutReportService.PayloadReport => {
      return {
        fromDate: data.fromDate ? PulseOpsFormat.datetoFormat(data.fromDate, 'YYYYMMDD') : undefined,
        toDate: data.toDate ? PulseOpsFormat.datetoFormat(data.toDate, 'YYYYMMDD') : undefined,
        transactionType: data.transactionType ? data.transactionType?.value : undefined,
        policyNumber: data.policyNumber,
        businessKey: data.caseId,
        totalMethod: data.totalMethod && data.totalMethod.value !== 'All' ? Number(data.totalMethod.value) : undefined
      }
    }

    const submitData: CheckingPayoutReportService.PayloadReport = getSubmitData()
    setSubmittedData(submitData)
    exportFile(submitData)
  })

  const getDataReport = (submitData: CheckingPayoutReportService.PayloadReport, size: number, start: number) => {
    pipe(
      CheckingPayoutReportService.viewReportCheckingPayout(submitData, size, start),
      ZIO.mapError((e) => {
        setTotalItem(0)
        setDataReport([])
        setMessageResponse(t('message:MS030029'))
        return ZIO.fail(e)
      }),
      ZIO.tap((res) => {
        setTotalItem(res.total)
        setDataReport(res.data)
        setMessageResponse(res.data.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const exportFile = (submitData: CheckingPayoutReportService.PayloadReport) => {
    pipe(
      CheckingPayoutReportService.viewReportCheckingPayout(submitData, 1000000000, 0),
      ZIO.mapError((e) => {
        return ZIO.fail(e)
      }),
      ZIO.flatMap((res) => {
        const today = PulseOpsFormat.datetoFormat(currentDate, 'DD/MM/YYYY')
        const fileName = 'Checking_payout_method_' + today
        const fromDate = checkPayoutForm.getValues('fromDate') ?? undefined
        const toDate = checkPayoutForm.getValues('toDate') ?? undefined
        const title = 'Checking Payout report'
        const columns = columnCheckingPayout.map(({ id, name, widthPx }) => ({
          id,
          name: t(name),
          width: widthPx,
          horizontal: 'left'
        }))
        const array =
          res.data &&
          res.data
            ?.map((res, index) => ({ ...res, id: `${index + 1}` }))
            .map(
              ({
                businessKey,
                policyNumber,
                transactionType,
                createdDate,
                payPremium,
                repayLoan,
                other,
                bankTransfer,
                paidAtBank,
                cashAtCounter,
                momo,
                totalMethod
              }) => [
                businessKey ?? '-',
                policyNumber ?? '-',
                transactionType ? TransactionLabel(transactionType as TransactionType) : '-',
                createdDate,
                payPremium || '-',
                repayLoan || '-',
                other || '-',
                bankTransfer || '-',
                paidAtBank || '-',
                cashAtCounter || '-',
                momo || '-',
                totalMethod
              ]
            )
        return ReportService.exportLetterReport(
          array,
          columns,
          fileName,
          'Checking_payout_method',
          fromDate,
          toDate,
          title
        )
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const clearFieldSearch = () => {
    checkPayoutForm.reset({
      fromDate: null,
      toDate: null,
      policyNumber: '',
      transactionType: null,
      caseId: '',
      totalMethod: null
    })
  }

  const renderTableHeader = () => {
    return titleHeaders.map((title, index) => {
      return (
        <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }} key={index}>
          {title}
        </TableCell>
      )
    })
  }

  const renderTableBody = () => {
    return dataReport?.map((row, index) => {
      return (
        <TableRow key={`${row.policyNumber}-${index}`}>
          <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
            {row.businessKey ?? '-'}
          </TableCell>
          <TableCell align="left">{row.policyNumber ?? '-'}</TableCell>
          <TableCell align="left">
            {row.transactionType ? TransactionLabel(row.transactionType as TransactionType) : '-'}
          </TableCell>
          <TableCell align="left">{row.createdDate ?? '-'}</TableCell>
          <TableCell align="left">{row.payPremium || '-'}</TableCell>
          <TableCell align="left">{row.repayLoan || '-'}</TableCell>
          <TableCell align="left">{row.other || '-'}</TableCell>
          <TableCell align="left">{row.bankTransfer || '-'}</TableCell>
          <TableCell align="left">{row.paidAtBank || '-'}</TableCell>
          <TableCell align="left">{row.cashAtCounter || '-'}</TableCell>
          <TableCell align="left">{row.momo || '-'}</TableCell>
          <TableCell align="left">{row.totalMethod ?? '-'}</TableCell>
        </TableRow>
      )
    })
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="caseId"
                control={checkPayoutForm.control}
                render={({ field: { onChange, value } }) => (
                  <Input title={t('common:CaseId')} value={value ?? undefined} inputType="text" onChange={onChange} />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="policyNumber"
                control={checkPayoutForm.control}
                rules={{
                  validate: (value) => {
                    if (value.length > 0 && value.length < 8) return `${t('message:MS990065')}`
                    return true
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    title={t('ServiceInquiry:PolicyNumber')}
                    value={value ?? undefined}
                    maxLength={8}
                    inputType="number"
                    onChange={onChange}
                    errorMessage={error ? error.message : ''}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="transactionType"
                control={checkPayoutForm.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('ServiceInquiry:TransactionType')}
                    options={transactionPayoutOption}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="fromDate"
                control={checkPayoutForm.control}
                rules={{
                  validate: (dateFrom) => {
                    const dateTo = checkPayoutForm.watch('toDate')
                    if (dateFrom && dateTo && moment(dateTo).isBefore(dateFrom)) return `${t('message:MS030044')}`
                    return true
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:FromDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxDate={currentDate}
                    maxDateMessage={t('message:MS990032')}
                    alwaysShow={true}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="toDate"
                control={checkPayoutForm.control}
                rules={{
                  validate: (dateTo) => {
                    const dateFrom = checkPayoutForm.watch('fromDate')
                    if (dateTo && dateFrom && moment(dateTo).isBefore(dateFrom)) return `${t('message:MS030044')}`
                    return true
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:ToDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxDate={currentDate}
                    alwaysShow={true}
                    maxDateMessage={t('message:MS990032')}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="totalMethod"
                control={checkPayoutForm.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('Reports:TotalMethod')}
                    options={totalOption}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </View>
          </View>
          <View style={[styles.rowButton, { alignItems: 'center' }]}>
            {permissions.includes(Permission['SearchCheckingPayoutMethod']) &&
              <TouchableOpacity style={[styles.buttonStyle, { marginRight: 10 }]} onPress={() => search()}>
                <View style={styles.btn}>
                  <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
                </View>
              </TouchableOpacity>
            }
            {permissions.includes(Permission['ExportCheckingPayoutMethod']) &&
              <TouchableOpacity style={[styles.buttonStyle, { marginRight: 10 }]} onPress={() => searchToExport()}>
                <View style={styles.btn}>
                  <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Export')}</Text>
                </View>
              </TouchableOpacity>
            }
            {permissions.includes(Permission['SearchCheckingPayoutMethod']) &&
              <TouchableOpacity style={styles.buttonStyle} onPress={() => clearFieldSearch()}>
                <View style={styles.btnCancel}>
                  <Text style={{ textAlign: 'center', color: '#ff0000' }}>{t('common:Cancel')}</Text>
                </View>
              </TouchableOpacity>
            }
          </View>
        </View>
        {dataReport.length === 0 ? (
          <View style={[styles.row, { marginLeft: 10, marginTop: 10 }]}>
            <Text style={{ color: '#ED1B2E' }}>{messageResponse ?? ''}</Text>
          </View>
        ) : (
          <View style={styles.viewTable}>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#e2e7ea' }}>{renderTableHeader()}</TableRow>
                </TableHead>

                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getDataReport(submittedData, pageSize, page)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                setPageNum(0)
                getDataReport(submittedData, Number(e.target.value), 0)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  textButtonRed: {
    color: '#ff0000',
    fontWeight: 'bold',
    paddingTop: 5
    // display: "flex",
  },

  buttonStyle: {
    width: 118,
    height: 40,
    marginLeft: 15
  },

  viewTable: {
    marginHorizontal: 15,
    marginTop: 30,
    marginBottom: 15
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 15,
    marginTop: 30,
    paddingBottom: 10,
    paddingTop: 16,
    flexDirection: 'column'
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingVertical: 8
  },

  rowButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingBottom: 10
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10
  },

  btnCancel: {
    width: 118,
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 100,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ED1B2E'
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
} as const)
