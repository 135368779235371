import { POApi } from '../POApi'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ZIO, Task } from '@mxt/zio'
import { CancelRider, ProductEnum } from './model'
import { SubmissionService } from './SubmissionService'
import { TopUpService } from './TopUpService'
import { CancelRiderService } from './CancelRiderService'

export namespace RiderAlterationService {
  const ErrorType = Maybe(
    t.type({
      code: Maybe(t.number),
      message: Maybe(t.string)
    })
  )
  const ClassItem = t.type({
    riderCode: t.string,
    classList: t.array(t.string)
  })

  const RiderNameInfo = t.type({
    id: Maybe(t.string),
    code: t.string,
    name: t.string,
    nameVi: t.string
  })

  export type ClassItem = t.TypeOf<typeof ClassItem>
  export type RiderNameInfo = t.TypeOf<typeof RiderNameInfo>

  export const getDetail = (policyNum: string): Task<CancelRider.DetailCancelRiderInfo> =>
    pipe(
      ZIO.zipPar(
        CancelRiderService.getClientRiderInfo(policyNum),
        TopUpService.GetPolicyExtraInfo([policyNum], ''),
        SubmissionService.getPolicy(policyNum),
        CancelRiderService.getRiderInfoList(),
        CancelRiderService.getMinPremium(policyNum)
      ),
      ZIO.map(([clients, policyExtra, policyInfo, riderNameList, minPrem]) => {
        const LARiderList = clients.map((clientItem) => {
          // filter x.lifeNo !=="01", x.coverageNo !=="01", x.riderNo !=="00" to get only rider products
          const cancelRiderList = policyExtra.policyExtraInfoDetail
            .filter(
              (x) =>
                (x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00') &&
                x.lifeNo === clientItem.life &&
                x.riderStatus === ProductEnum.Status.IF &&
                !CancelRider.UnCancelRiderList.includes(x.productCode)
            )
            .map((extraItem) => ({
              productCode: String(extraItem?.productCode),
              productName: '',
              riskCessDate: String(extraItem?.riskCessDate),
              riskCommDate: String(extraItem?.riskCommDate),
              lapsedDate: '',
              sumAssured: Number(extraItem?.sumAssured),
              installmentPrem: Number(extraItem?.installmentPremium),
              newInstallmentPremium: 0,
              lifeNo: extraItem.lifeNo,
              coverageNo: extraItem.coverageNo,
              riderNo: extraItem.riderNo,
              riderStatus: extraItem.riderStatus
            }))
          const filterRiderlist = policyExtra.policyExtraInfoDetail
            .filter((item) => item.lifeNo === '01' && item.coverageNo === '01' && item.riderNo === '00')
            .map((extra) => ({
              productCode: String(extra?.productCode)
            }))
          return {
            clientName: clientItem.clientName,
            lifeAssured: clientItem.clientNumberOfLA,
            lifeNo: clientItem.life,
            riders: cancelRiderList,
            extraRider: filterRiderlist
          }
        })
        return {
          totalPremium: Number(policyInfo.body.totalPremium),
          laRiderInfo: LARiderList,
          minPremium: minPrem,
          riderNameList: riderNameList,
          riderNameListTwo: LARiderList.filter((x) => x.extraRider && x.extraRider.length > 0)
        }
      })
    )

  export const getWaiverProducts = (tableName: string, riderCode: string) =>
    pipe(
      POApi.get(`wf-api/rider/get-items-by?tableName=${tableName}&riderCode=${riderCode}`)(
        t.type({
          body: t.array(t.string),
          responseStatus: ErrorType
        })
      ),
      ZIO.map((response) => {
        return response.body
      })
    )
  export const getRiderList = (policyNum: string, lifeNo: string) =>
    pipe(
      POApi.get(`wf-api/rider/get-list/${policyNum}/${lifeNo}`)(
        t.type({
          body: t.array(RiderNameInfo),
          responseStatus: ErrorType
        })
      ),
      ZIO.map((response) => {
        return {
          lifeNo,
          riderArr: response.body
        }
      })
    )

  export const getClassList = (tableName: string) =>
    pipe(
      POApi.get(`wf-api/rider/get-items/${tableName}`)(
        t.type({
          body: t.array(ClassItem)
        })
      ),
      ZIO.map((response) => response.body)
    )
}
