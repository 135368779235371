import {
  assets,
  Checkbox,
  DatePicker,
  ErrorHandling,
  Form,
  GeneralService,
  /*  Input,
    RadioButton,*/
  RadioButton,
  Select,
  SelectSearch,
  useMobile
} from '@pulseops/common'
import { compareOnlyDate } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ClaimService } from '../../ClaimService'
import { ClaimDataAccess, generalStyles, HealthType } from '../../common'
import { RequestInfoProps } from '../RequestInfoProps'

export const MedicalClaim = (props: RequestInfoProps & { claimDataAccess: ClaimDataAccess }) => {
  const { isMobile } = useMobile()
  const { form, claimDataAccess } = props
  const { claimSubBenefit } = claimDataAccess
  const { watch } = form
  const { subClaimType } = watch()
  const { t } = useTranslation('claim')
  const register = Form.register(form)
  const hospital = pipe(GeneralService.getHospital(), ErrorHandling.runDidMount())
  const getSubClaimType = () => {
    switch (subClaimType?.value) {
      case HealthType.InPatientBenefit:
        return <InPatient {...props} hospital={hospital?.map((h) => ({ value: h.code, label: h.nameEn })) ?? []} />
      default:
        return <></>
    }
  }

  return (
    <>
      <Text style={{ fontSize: 13, fontWeight: 'bold', margin: 18, textTransform: 'uppercase' }}>
        {t('HospitalCash')}
      </Text>
      <View style={{ flexDirection: isMobile ? 'column' : 'row', paddingLeft: 20, marginTop: 10 }}>
        <View style={{ flex: 1 / 3 }}>
          <Select
            {...register('subClaimType', {
              required: `${t('message:MS020001', { field: t('claim:Hospitalbenefit') })}`
            })}
            required
            label={t('claim:Hospitalbenefit')}
            options={
              claimSubBenefit.length > 0
                ? [claimSubBenefit[0]].map((item) => ({
                    value: item.benefitCode,
                    label: localStorage.getItem('locale') === 'en-US' ? item.benefitEN : item.benefitVN
                  }))
                : []
            }
          />
        </View>
      </View>
      <View style={styles.subClaimContainer}>{getSubClaimType()}</View>
    </>
  )
}

const InPatient = (props: RequestInfoProps & { hospital: { value: string; label: string }[] }) => {
  const { isMobile } = useMobile()
  const { form, hospital } = props
  const { watch } = form
  const { isRelatedToAccident, additionalDiagnosis } = watch()
  const register = Form.register(form)
  const errors = form.formState.errors

  const { t, i18n } = useTranslation('claim')
  const ct = useTranslation().t

  const radioGroups = [
    {
      code: 'Y',
      label: t('common:Yes')
    },
    {
      code: 'N',
      label: t('common:No')
    }
  ]

  const deleteAdditionDiagnosis = (idx: number) => {
    const list = [...additionalDiagnosis]
    list.splice(idx, 1)
    form.setValue('additionalDiagnosis', list)
  }

  const translator = (en: string, vi: string) => (i18n.language === 'en' ? en : vi)

  return (
    <View style={{ width: '100%' }}>
      <Text style={[styles.sectionTitle, { marginBottom: 20 }]}>{t('InPatient')}</Text>
      <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Text style={{ fontWeight: 'bold', color: '#70777E', paddingLeft: 4, paddingBottom: 3, fontSize: 15 }}>
            {t('RelatedToAccident')}? <Text style={{ color: '#ed1b2c', fontSize: 15 }}>*</Text>
          </Text>
          <View style={{ flexDirection: 'row', marginTop: 8 }}>
            {radioGroups.map((r, i) => (
              <RadioButton
                {...register('isRelatedToAccident', {
                  required: `${t('message:MS020001', { field: t('RelatedToAccident') })}`
                })}
                key={i}
                label={r.label}
                value={r.code}
                selected={isRelatedToAccident === r.code}
                onChange={(vl) => {
                  form.setValue('isRelatedToAccident', vl)
                }}
              />
            ))}
          </View>
          {!isRelatedToAccident && errors.isRelatedToAccident?.message && (
            <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errors.isRelatedToAccident?.message}</Text>
          )}
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <View style={{ marginTop: 15 }}>
            <Checkbox {...register('surgeryBenefit')} title={t('SurgeryBenefit')} />
          </View>
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <View style={{ marginTop: 15 }}>
            <Checkbox {...register('icuBenefit')} title={t('ICUBenefit')} />
          </View>
        </View>
      </View>
      <View style={{ flexDirection: isMobile ? 'column' : 'row', flex: 1, marginBottom: 30, width: '64%' }}>
        <View style={isMobile ? styles.mobileField : { flex: 2 }}>
          <SelectSearch
            {...register('mainDiagnosis', {
              required: `${ct('message:MS020001', { field: t('MainDiagnosis') })}`
            })}
            label={t('MainDiagnosis')}
            required
            placeholder={t('common:Select')}
            errorMessage={(errors.mainDiagnosis as FieldError | undefined)?.message || ''}
            searchOptions={ClaimService.searchDiagnostic(translator)}
          />
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <View style={isMobile ? styles.mobileField : { width: '64%', paddingBottom: 20 }}>
          <SelectSearch
            {...register('additionalDiagnosis', {
              pattern: {
                value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                message: t('message:MS050223')
              }
            })}
            multiple
            label={t('AdditionalDiagnosis')}
            placeholder={t('common:EnterAdditionalDiagnosis')}
            errorMessage={(form.formState.errors.additionalDiagnosis as FieldError | undefined)?.message || ''}
            searchOptions={ClaimService.searchDiagnostic(translator)}
            key={additionalDiagnosis && additionalDiagnosis.length}
          />
        </View>
        <View style={[styles.sectionContainer, { width: '64%' }]}>
          <Text style={{ fontWeight: 'bold', color: '#70777E', marginBottom: 10 }}>{t('AdditionalDiagnosis')}</Text>
          {additionalDiagnosis &&
            additionalDiagnosis.map((item, index) => (
              <View style={[styles.cause, { flexDirection: 'row' }]}>
                <Text>
                  {item.value} - {item.label}
                </Text>
                <TouchableOpacity
                  hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                  onPress={() => deleteAdditionDiagnosis(index)}
                  style={{ padding: 5 }}
                >
                  <assets.DeleteIcon />
                </TouchableOpacity>
              </View>
            ))}
        </View>
      </View>

      <View style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <SelectSearch
            {...register('hospital', {
              required: `${ct('message:MS020001', { field: t('Hospital') })}`
            })}
            errorMessage={(errors.hospital as FieldError | undefined)?.message || ''}
            options={hospital}
            required
            label={t('Hospital')}
            labelStyle={{ marginBottom: 8 }}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name={'admissionDate'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020001', { field: t('AdmissionDate') })}`
              },
              validate: (value) => {
                const a = form.watch('dischargeDate')
                const compareDischarge = !!a && !!value && !compareOnlyDate(value, a)
                const compareCurrentDate = !!value && !compareOnlyDate(value, new Date())
                const messageCurrent = ct('message:MS070003')
                const messageDischarge = ct('message:MS070006')
                if (compareDischarge) return messageDischarge
                if (compareCurrentDate) return messageCurrent
                return true
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                alwaysShow
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={error?.message}
                label={t('AdmissionDate')}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name={'dischargeDate'}
            control={form.control}
            rules={{
              required: {
                value: true,
                message: `${ct('message:MS020001', { field: t('DischargeDate') })}`
              },
              validate: (value) => {
                const a = form.watch('admissionDate')
                const compareAdmission = !!a && !!value && !compareOnlyDate(a, value)
                const compareCurrentDate = !!value && !compareOnlyDate(value, new Date())
                const messageCurrent = ct('message:MS070003')
                const messageAdmission = ct('message:MS070006')
                if (compareAdmission) return messageAdmission
                if (compareCurrentDate) return messageCurrent
                return true
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={error?.message}
                label={t('DischargeDate')}
              />
            )}
          />
        </View>
      </View>
    </View>
  )
}

const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    subClaimContainer: {
      flexDirection: 'row',
      backgroundColor: '#FAFAFA',
      paddingTop: 10,
      paddingLeft: 18,
      paddingRight: 18,
      borderTopWidth: 1,
      borderTopColor: '#D3DCE6',
      marginTop: 20
    },
    cause: {
      borderBottomColor: '#D3DCE6',
      borderBottomWidth: 1,
      marginTop: 15,
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })
}
