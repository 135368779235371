import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { Error } from './CepCommonModels'

export const ProductTypeC = t.type({
  data: t.type({
    productType: t.string,
    productCode: t.string,
    basicCode: t.string,
    status: t.string,
    salaryIncome: t.number,
    lastIssueDate: t.string,
    paidToDateAdvance: t.string,
    anniDate: t.string,
    paidToDateBasic: t.string,
    paidToDateRider: t.string,
    billToDate: t.string,
    billFreq: t.string,
    mainLifeAssuredNumber: t.string,
    clientDespatchAddress: t.string,
    riskCommenceDate: t.string,
    surrenderDate: t.string,
    lapsedDate: t.string,
    policyNo: t.string,
    totalPremium: t.number,
    contractDate: t.string,
    firstIssueDate: t.string,
    premiumStatus: t.string
  })
})

export type ProductTypeC = t.TypeOf<typeof ProductTypeC>

export const extraInfoC = t.type({
  data: t.type({
    function: t.string,
    policies: t.array(t.string)
  })
})
export type extraInfoC = t.TypeOf<typeof extraInfoC>

export const ExtraInfoResponse = t.type({
  data: t.type({
    policyExtraInfoDetails: t.array(
      t.type({
        productCode: t.string,
        lifeNo: t.string,
        coverageNo: t.string,
        riderNo: t.string,
        benefitOption: t.string,
        mortClass: t.string,
        sumAssured: t.number,
        installmentPremium: t.number,
        totalPaidBasicYearly: t.number,
        riskCommDate: t.string,
        premCessDate: t.string,
        riskCessDate: t.string,
        dealingDate: t.string,
        rerateDate: t.string,
        debtAmount: t.number,
        targetPremium: t.number,
        minTopUp: t.number,
        maxTopUp: t.number,
        policyNum: t.string,
        riderStatus: t.string,
        riderPremiumStatus: t.string
      })
    ),
    policyExtraInfoFunds: t.array(
      t.type({
        fundCode: t.string,
        unitBalance: t.number,
        price: t.number,
        priceDate: t.string,
        policyNum: t.string,
        estimateValue: t.number,
        fundNameEN: Maybe(t.string),
        fundNameVN: Maybe(t.string),
        fundTypeCode: Maybe(t.string),
        fundTypeName: Maybe(t.string)
      })
    )
  })
})

export type ExtraInfoResponse = t.TypeOf<typeof ExtraInfoResponse>

export const ILPTopupHistoryItem = t.type({
  transactionDate: Maybe(t.string),
  fundId: Maybe(t.string),
  effectivePrice: Maybe(t.number),
  fundAmount: Maybe(t.number),
  realUnit: Maybe(t.number),
  contractAmount: Maybe(t.number),
  topUpPremium: Maybe(t.number),
  sourcePercentAmount: Maybe(t.number),
})

export const ILPTopupHistoryList = t.array(ILPTopupHistoryItem)

export type ILPTopupHistoryList = t.TypeOf<typeof ILPTopupHistoryList>

export const ILPTopupHistory = t.type({
  data: ILPTopupHistoryList,
  error: Error
})
export type ILPTopupHistory = t.TypeOf<typeof ILPTopupHistory>
//ULP

export const ULPTopupHistoryItem = t.type({
  transDate: t.string,
  accountingAmount: Maybe(t.number),
  fundAmount: Maybe(t.number),
})

export const ULPTopupHistoryList = t.array(ULPTopupHistoryItem)

export type ULPTopupHistoryList = t.TypeOf<typeof ULPTopupHistoryList>

export const ULPTopupHistory = t.type({
  data: ULPTopupHistoryList,
  error: Error
})

export type ULPTopupHistory = t.TypeOf<typeof ULPTopupHistory>

export const FundAllocateItem = t.type({
  fundId: t.string,
  fundName: Maybe(t.string),
  percentage: t.string
})

export const FundAllocateList = t.array(FundAllocateItem)

export type FundAllocateList = t.TypeOf<typeof FundAllocateList>

export const FundAllocate = t.type({
  data: FundAllocateList,
  error: Error
})

export type FundAllocate = t.TypeOf<typeof FundAllocate>

const FundList = t.type( {
  actualValue: t.number,
  estimatedValue: t.number,
  fundCode: t.string,
  isEPA: Maybe(t.boolean)
});

export const PartialWithDraw = t.type({
  estimatedValue: Maybe(t.number),
  minimumPartialWithdrawal: Maybe(t.number),
  maximumPartialWithdrawal: Maybe(t.number),
  withDrawFee: Maybe(t.number),
  newSumAssured: Maybe(t.number),
  sumAssured: Maybe(t.number),
  fundList: Maybe(t.array(FundList))
})

export type PartialWithDraw = t.TypeOf<typeof PartialWithDraw>

export const PartialWithDrawResponse = t.type({
  data: PartialWithDraw,
  error: Error
})

export type PartialWithDrawResponse = t.TypeOf<typeof PartialWithDrawResponse>

export const PartialWithDrawRequest = t.type({
   data: t.type({
    details: t.array(t.type({
      coverage: t.string,
      fundCode: t.string,
      amount: t.number,
    }))
   }) 
})

export type PartialWithDrawRequest = t.TypeOf<typeof PartialWithDrawRequest>


export const PartialWithDrawILP = t.type({
  estimatedValue: Maybe(t.number),
  minimumPartialWithdrawal: Maybe(t.number),
  maximumPartialWithdrawal: Maybe(t.number),
  withDrawFee: Maybe(t.number),
  newSumAssured: Maybe(t.number),
  sumAssured: Maybe(t.number),
  fundList: Maybe(t.array(t.type( {
    fundCode: t.string,
    fundValue: t.number,
    minRemain: t.number,
  })))
})
export type PartialWithDrawILP = t.TypeOf<typeof PartialWithDrawILP>

export const PartialWithDrawResponseILP = t.type({
  data: Maybe(PartialWithDrawILP),
  error: Error
})

export type PartialWithDrawResponseILP = t.TypeOf<typeof PartialWithDrawResponseILP>
