import { AppContext, assets, BreadCrumb, useMobile } from '@pulseops/common'
import { OBHiddenTopMenuBlock } from '@pulseops/outbound'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import { UserStackParamList } from '../UserStackParamList'
import { UserDrawerParamList } from './UserDrawerParamList'
export const Header = ({ navigation }: { navigation: StackScreenProps<UserStackParamList, 'UserDrawer'> }) => {
  const { isWide } = useMobile()
  const { t } = useTranslation('')
  const { toggleDrawer } = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()
  const { breadcrumbs, isHiddenTopMenu } = React.useContext(AppContext.AppContextInstance)
  const props = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()
  const notHome = breadcrumbs.slice(1)

  return (
    <View style={styles.container}>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={toggleDrawer}
        style={{ width: 24, height: 24, justifyContent: 'center', alignItems: 'center', marginVertical: 13 }}
      >
        <assets.HambergerIcon />
      </TouchableOpacity>
      {isWide && (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{
            marginStart: 16,
            width: 138,
            height: 34,
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onPress={() => breadcrumbs[0].navigate && breadcrumbs[0].navigate()}
        >
          <assets.HeaderLogoWide />
        </TouchableOpacity>
      )}
      <View style={styles.menuContainer}>{isWide && <BreadCrumb navigation={notHome} />}</View>
      <View>
        <TouchableOpacity
          activeOpacity={0.7}
          style={{
            marginStart: 16,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
          }}
          onPress={() => {
            props.navigate('NotificationScreen')
          }}
        >
          <assets.IconMenuNotification />
          <Text style={{ paddingLeft: 6 }}>{t('menu:Notification')}</Text>
        </TouchableOpacity>
      </View>
      {/* <View style={styles.controlContainer}>
        <View
          style={{
            width: 28,
            height: 28,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#EB5757',
            borderRadius: 28,
            marginVertical: 3,
            marginStart: 3
          }}
        >
          <Text style={{ color: '#FFFFFF', fontSize: 13, fontWeight: 'bold' }}>NT</Text>
        </View>
        <Text style={{ marginStart: 10, marginEnd: 5, fontSize: 13, color: '#70777E' }}>Nguyễn Hoàng Phương Trang</Text>
        <View></View>
      </View> */}
      <OBHiddenTopMenuBlock isOpened={isHiddenTopMenu}></OBHiddenTopMenuBlock>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    paddingHorizontal: 16,
    shadowColor: 'rgba(0, 0, 0, 0.04)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },

  headerLogo: {
    marginStart: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },

  menuContainer: {
    flex: 1,
    paddingStart: 0
  },

  controlContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: 20
  },

  btnNotification: {
    marginStart: 8,
    marginEnd: 16
  },

  imgAvatar: {
    width: 34,
    height: 34,
    borderRadius: 17,
    marginEnd: 4
  }
})
