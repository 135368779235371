import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'

export namespace BonusSurrenderForm {
  const SurrenderForm = t.type({
    bonusValue: form2.number.required,
    principalAplOpl: form2.number.required,
    interestAplOpl: form2.number.required,
    bonusValueAmountToSurrender: form2.string.required,
    bonusReserveValue: form2.number.required,
    bonusReserveRemainValue: form2.number.required,
    cashValue: form2.number.required
  })

  type SurrenderForm = t.TypeOf<typeof SurrenderForm>

  type SurrenderBrand = {
    readonly SurrenderCash: unique symbol
  }

  type SurrenderCash = t.Branded<SurrenderForm, SurrenderBrand>

  const SurrenderCash = pipe(
    SurrenderForm,
    form2.refine(
      (l): l is SurrenderCash => true,
      (l) => i18next.t('message:MS050264'),
      'SurrenderCash'
    )
  )

  export const codec = SurrenderCash

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
