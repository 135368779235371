import { Maybe } from '@mxt/zio/codec'
import { form2, PulseOpsFormat } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import moment from 'moment'
export const enum TabSearch {
  BasicSearch = 'BasicSearch',
  AdvancedSearch = 'AdvancedSearch',
  ClaimSearch = 'ClaimSearch',
  CashOutSearch = 'CashOutSearch',
  CashOutComplete = 'CashOutComplete',
  PremiumCollection = 'PremiumCollection',
  DigitalClaimSearch = 'DigitalClaimSearch',
  TraditionalClaimSearch = 'TraditionalClaimSearch',
  AdminClaimSearch = 'AdminClaimSearch',
  DistributionServicesSearch = 'DistributionServicesSearch',
  IndependenceCheck = 'IndependenceCheck'
}

export namespace BasicSearchForm {
  let tab: TabSearch | null = null
  export const setTab = (tabSearch: TabSearch | null) => {
    tab = tabSearch
  }

  const MultipleSelectOption = t.array(
    t.type({
      label: t.string,
      value: t.string
    })
  )

  const Base = t.type({
    policyNumber: pipe(
      form2.string.optional,
      form2.string.minLength(8, () =>
        i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:PolicyNumber'), k: 8 })
      )
    ),
    policyNumberClaim: pipe(
      form2.string.optional,
      form2.string.minLength(8, () =>
        i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:PolicyNumber'), k: 8 })
      )
    ),
    idNumber: pipe(
      form2.string.optional,
      form2.string.minLength(2, () => i18next.t('message:MS050216'))
    ),
    clientNumber: pipe(
      form2.string.optional,
      form2.string.minLength(8, () =>
        i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:ClientNumber'), k: 8 })
      )
    ),
    phoneNumber: pipe(
      form2.string.optional,
      form2.string.minLength(10, () =>
        i18next.t('message:MS050013', { field: i18next.t('ServiceInquiry:PhoneNumber') })
      )
    ),
    source: MultipleSelectOption,
    category: form2.selectOption.optional,
    transactionType: MultipleSelectOption,
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:FromDate') })
    ),
    toDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:ToDate') })),
    subServiceType: MultipleSelectOption,
    subTransactionType: MultipleSelectOption,
    offices: MultipleSelectOption,
    status: form2.selectOption.optional,
    claimNumber: pipe(
      form2.string.optional
      // form2.string.minLength(11, () =>
      //   i18next.t('message:MS050013', { field: i18next.t('ServiceInquiry:ClaimNumber') })
      // )
    ),
    claimTypes: t.array(
      t.type({
        label: t.string,
        value: t.string,
        codes: t.array(
          t.type({
            claimCode: t.string,
            claimType: t.string,
            claimTypeShortName: t.string,
            claimTypeVN: t.string,
            claimTypeWF: t.string,
            id: t.string
          })
        )
      })
    )
  })
  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const getDeviationDate = () => (tab === TabSearch.AdvancedSearch ? 3 : 60)

  const FormSearch = pipe(
    Base,
    form2.refine(
      (data): data is Form => {
        switch (tab) {
          case TabSearch.BasicSearch:
            return !!data.policyNumber || !!data.clientNumber || !!data.idNumber || !!data.phoneNumber
          case TabSearch.ClaimSearch:
            return !!data.policyNumberClaim || !!data.claimNumber || !!data.idNumber
          case TabSearch.CashOutSearch:
            if (data.offices.length > 0 && data.offices[0].value === 'ALL') return !!data.policyNumber
            else return true
          case TabSearch.CashOutComplete:
            if (data.offices.length > 0 && data.offices[0].value === 'ALL') return !!data.policyNumber
            else return true
          default:
            return true
        }
      },
      () => {
        switch (tab) {
          case TabSearch.ClaimSearch:
            return i18next.t('message:MS990026')
          case TabSearch.CashOutSearch:
            return i18next.t('message:MS990065')
          case TabSearch.CashOutComplete:
            return i18next.t('message:MS990065')
          default:
            return i18next.t('message:MS990025')
        }
      },
      'Form'
    ),
    form2.refine(
      (data): data is Form => moment(data.toDate).isSameOrAfter(data.fromDate, 'day'),
      () => i18next.t('message:MS030044'),
      'Form'
    ),
    form2.refine(
      (data): data is Form => {
        const dateFrom = PulseOpsFormat.startOfDate(data.fromDate)
        const dateTo = PulseOpsFormat.startOfDate(data.toDate)
        return moment(dateTo).diff(dateFrom, 'months', true) <= getDeviationDate()
      },
      () => i18next.t('message:MS080004', { k: getDeviationDate() }),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

export namespace PremiumCollectionForm {
  type NumberString = t.Branded<
    NonEmptyString,
    {
      readonly NumberString: unique symbol
    }
  >

  const PolicyNumber = pipe(
    withMessage(NonEmptyString, () => 'Not policy number'),
    form2.refine(
      (l): l is NumberString => /\b\d{8}\b/.test(l),
      (l) => i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:PolicyNumber'), k: 8 }),
      'NumberString'
    )
  )

  const ClientNumber = pipe(
    withMessage(NonEmptyString, () => 'Not client number'),
    form2.refine(
      (l): l is NumberString => /\b\d{8}\b/.test(l),
      (l) => i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:ClientNumber'), k: 8 }),
      'NumberString'
    )
  )

  const ProposalNumber = pipe(
    withMessage(NonEmptyString, () => 'Not proposal number'),
    form2.refine(
      (l): l is NumberString => /\b\d{9}\b/.test(l),
      (l) => i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:proposalNumber'), k: 9 }),
      'NumberString'
    )
  )

  const Base = t.type({
    clientNumber: form2.optional(ClientNumber),
    policyNumber: form2.optional(PolicyNumber),
    proposalNumber: form2.optional(ProposalNumber),
    amount: form2.string.optional,
    bankCode: form2.selectOption.optional,
    referenceNumber: form2.string.optional,
    fromDate: form2.date.optional,
    toDate: form2.date.optional
  })

  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(
    Base,
    form2.refine(
      (l): l is Form => {
        const result =
          !!l.clientNumber ||
          !!l.policyNumber ||
          !!l.proposalNumber ||
          !!l.amount ||
          !!l.bankCode ||
          !!l.referenceNumber
        console.log('validated result', result)
        return result
      },
      (l) => i18next.t('ServiceInquiry:pc:error'),
      'Form'
    ),
    form2.refine(
      (l): l is Form => {
        return !!(l.fromDate && l.toDate && moment(l.fromDate).isSameOrBefore(l.toDate))
      },
      (l) => i18next.t('message:MS030044'),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}

export namespace DistributionServiceInquiryForm {
  type NumberString = t.Branded<
    NonEmptyString,
    {
      readonly NumberString: unique symbol
    }
  >

  const eRef = pipe(
    form2.string.optional,
    form2.string.minLength(11, () => i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:eRef'), k: 11 }))
  )

  const AgentCode = pipe(
    withMessage(NonEmptyString, () => 'Not client number'),
    form2.refine(
      (l): l is NumberString => /\b\d{8}\b/.test(l),
      (l) => i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:AgentCode'), k: 8 }),
      'NumberString'
    )
  )

  const IDNumber = pipe(
    form2.string.optional,
    form2.string.minLength(2, () => i18next.t('message:MS050216'))
  )

  const MultipleSelectOption = t.array(
    t.type({
      label: t.string,
      value: t.string
    })
  )

  const PhoneNumber = pipe(
    form2.string.optional,
    form2.string.minLength(10, () => i18next.t('message:MS050013', { field: i18next.t('ServiceInquiry:PhoneNumber') }))
  )

  const Base = t.type({
    eRef: form2.optional(eRef),
    agentCode: form2.optional(AgentCode),
    IDnumber: form2.optional(IDNumber),
    phoneNumber: form2.optional(PhoneNumber),
    fromDate: form2.date.optional,
    toDate: form2.date.optional,
    source: Maybe(MultipleSelectOption),
    category: form2.selectOption.optional,
    transactionType: Maybe(MultipleSelectOption)
  })

  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(
    Base,
    form2.refine(
      (l): l is Form => {
        return !!(l.fromDate && l.toDate && moment(l.fromDate).isSameOrBefore(l.toDate))
      },
      (l) => i18next.t('message:MS030044'),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}

export namespace IndependenceInquiryForm {
  type NumberString = t.Branded<
    NonEmptyString,
    {
      readonly NumberString: unique symbol
    }
  >

  const PolicyNumber = pipe(
    form2.string.optional,
    form2.string.minLength(8, () =>
      i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:PolicyNumber'), k: 8 })
    )
  )

  const ProposalNumber = pipe(
    form2.string.optional,
    form2.string.minLength(8, () =>
      i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:ProposalNumber'), k: 9 })
    )
  )

  const IDNumber = pipe(
    form2.string.optional,
    form2.string.minLength(2, () => i18next.t('message:MS050216'))
  )

  const MultipleSelectOption = t.array(
    t.type({
      label: t.string,
      value: t.string
    })
  )

  const PhoneNumber = pipe(
    form2.string.optional,
    form2.string.minLength(10, () => i18next.t('message:MS050013', { field: i18next.t('ServiceInquiry:PhoneNumber') }))
  )

  const Base = t.type({
    PolicyNumber: form2.optional(PolicyNumber),
    ProposalNumber: form2.optional(ProposalNumber),
    IDnumber: form2.optional(IDNumber),
    phoneNumber: form2.optional(PhoneNumber),
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:FromDate') })
    ),
    toDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:ToDate') })),
    source: Maybe(MultipleSelectOption),
    category: form2.selectOption.optional,
    transactionType: form2.selectOption.optional,
    taxNumber: form2.string.optional,
    agentLicense: form2.string.optional,
    signDateCompany: form2.date.optional
  })

  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>
  let tab: TabSearch | null = null
  const getDeviationDate = () => (tab === TabSearch.IndependenceCheck ? 3 : 60)
  const FormSearch = pipe(
    Base,
    // form2.refine(
    //   (l): l is Form => {
    //     return !!(l.fromDate && l.toDate && moment(l.fromDate).isSameOrBefore(l.toDate))
    //   },
    //   (l) => i18next.t('message:MS030044'),
    //   'Form'
    // )
    form2.refine(
      (data): data is Form => moment(data.toDate).isSameOrAfter(data.fromDate, 'day'),
      () => i18next.t('message:MS030044'),
      'Form'
    ),
    form2.refine(
      (data): data is Form => {
        const dateTo = PulseOpsFormat.startOfDate(data.toDate)
        const currentDate = new Date()
        const cDate = PulseOpsFormat.startOfDate(currentDate)
        return moment(dateTo).isSameOrBefore(cDate, 'day')
      },
      () => i18next.t('message:MS030044'),
      'Form'
    ),
    form2.refine(
      (data): data is Form => {
        const dateFrom = PulseOpsFormat.startOfDate(data.fromDate)
        const dateTo = PulseOpsFormat.startOfDate(data.toDate)
        return moment(dateTo).diff(dateFrom, 'months', true) <= getDeviationDate()
      },
      () => i18next.t('message:MS080004', { k: getDeviationDate() }),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}
