import React from 'react'

export namespace DAContext {
  export type DAContextType = {
    point: number
    // validComplete: boolean
    // setValidComplete: (value: boolean) => void
  }

  export const DAGeneralContextInstance = React.createContext<DAContextType>({
    point: 0
    // validComplete: false,
    // setValidComplete: () => {}
  })
}
