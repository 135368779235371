import { subscribe } from '@mxt/zio-react'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { FontAwesome5 } from '@expo/vector-icons'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AlertOptions, AlertService, ModalOptions } from './alert-dialog'
import { ZIO } from '@mxt/zio'
import { Alert } from '@material-ui/lab'
import { Modal, View, StyleSheet, Pressable, Text } from 'react-native'
import { assets } from '../../assets'

export const AlertDialog = () => {
  const { t } = useTranslation()
  const options: AlertOptions | null = pipe(
    AlertService.stateRef.watch((s) => s.options),
    subscribe()
  )
  const open = options != null

  const handleClose = () => {
    pipe(AlertService.stateRef.set({ options: null }), ZIO.run({}))
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{options?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            whiteSpace: 'pre-wrap',
            color: 'black'
          }}
        >
          {options?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {t('common:Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const theme = createTheme({
  zIndex: {
    snackbar: 9999
  }
})

export const SnackBarDialog = () => {
  const options: AlertOptions | null = pipe(
    AlertService.snackStateRef.watch((s) => s.options),
    subscribe()
  )
  const open = options != null

  const handleClose = () => {
    pipe(AlertService.snackStateRef.set({ options: null }), ZIO.run({}))
  }

  const iconType = React.useMemo(() => {
    switch (options?.type ?? '') {
      case 'success':
        return 'check-circle'
      case 'warning':
        return 'exclamation-circle'
      case 'error':
        return 'times-circle'
      case 'info':
        return 'info-circle'
      default:
        return ''
    }
  }, [options?.type])
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={open}
        message={options?.message}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options ? (
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={options?.type}
            style={{ width: '100%' }}
            color={options?.type}
            icon={<FontAwesome5 name={iconType} color={'#fff'} size={20} />}
          >
            {options?.message}
          </Alert>
        ) : (
          <View />
        )}
      </Snackbar>
    </ThemeProvider>
  )
}

export const ModalDialog = () => {
  const { t } = useTranslation()
  const options: ModalOptions | null = pipe(
    AlertService.modalStateRef.watch((s) => s.options),
    subscribe()
  )

  const open = options != null

  const handleClose = () => {
    pipe(AlertService.modalStateRef.set({ options: null }), ZIO.run({}))
  }

  const onClose = React.useCallback(() => {
    handleClose()
    options?.onClose?.call(null)
  }, [options])

  const onOK = React.useCallback(() => {
    handleClose()
    options?.onOK?.call(null)
  }, [options])

  return (
    options !== null && (
      <Modal animationType="fade" transparent={true} visible={open} onRequestClose={onClose}>
        <View style={styles.centeredView}>
          <View style={[styles.modalView, { width: options?.size?.width, height: options?.size?.height }]}>
            <Text style={styles.headerTitle}>{options?.title ?? ''}</Text>
            <View style={{ flex: 1 }}>
              {options?.content ? (
                <Text style={styles.contentText}>{options.content}</Text>
              ) : options?.child ? (
                options.child
              ) : (
                <View />
              )}
            </View>
            {/* options.action.map() // custom 1 mảng nhiều action khác nhau nếu không dùng mặc định Cancel, OK */}
            {options && !options?.hideAction && (
              <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                {!options?.hideCancle && (
                  <Pressable style={[styles.Button, { backgroundColor: '#fff' }]} onPress={onClose}>
                    <Text style={{ color: '#ED1B2E', fontWeight: '700', fontSize: 15 }}>{t('common:Cancel')}</Text>
                  </Pressable>
                )}
                <Pressable style={styles.Button} onPress={onOK}>
                  <Text style={{ color: '#fff', fontWeight: '700', fontSize: 15 }}>{t('common:Ok')}</Text>
                </Pressable>
              </View>
            )}
            {options && !options.hideCloseIcon ? (
              <Pressable style={[styles.xButton]} onPress={onClose}>
                <assets.CloseTaskModalIcon />
              </Pressable>
            ) : (
              ''
            )}
          </View>
        </View>
      </Modal>
    )
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: '#F194FF'
  },
  buttonClose: {
    backgroundColor: '#2196F3'
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center'
  },
  xButton: {
    position: 'absolute',
    top: 20,
    right: 20
  },
  Button: {
    backgroundColor: '#ED1B2E',
    borderRadius: 40,
    paddingVertical: 8,
    paddingHorizontal: 20,
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    minWidth: 125,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    marginHorizontal: 10
  },
  headerTitle: {
    fontWeight: '600',
    color: '#4F4F4F',
    fontSize: 20
  },
  contentText: {
    color: '#4F4F4F',
    fontSize: 15,
    paddingVertical: 15
  }
})
