import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { IBGeneralTable, IBGridDataView, IBPanel } from '../../../../../common'
import { useTranslation } from 'react-i18next'
import { PulseOpsFormat, assets } from '@pulseops/common'
import { isNil, isString } from 'lodash'
import { IBService, InvestmentDataInfo, InvestmentFund } from '../../../../../ib-service'
import {
  PartialWithdrawalInfo,
  PartialWithdrawalHistory,
  FundValueInfo,
  LoyaltyBonus,
  AllocatedPremiumInfo,
  AllocationChargeInfo,
  CostOfInsuranceInfo,
  AdministrationChargeInfo,
  TopUpHistory,
  FundSwitchHistory,
  PremiumRedirectionHistory
} from './IBInvestmentInfoPanelChildrens'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

interface Props {
  scrollRef: React.MutableRefObject<ScrollView | null>
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  productCode: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  status: string
  productType: string
  basicCode: string
  listFundT25?: InvestmentFund[]
}
export const IBILPFundInfo = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const {
    investmentInfo,
    policyNumber,
    productCode,
    setInvestmentInfo,
    status,
    productType,
    basicCode,
    scrollRef,
    listFundT25
  } = props

  const [layoutY, setLayoutY] = React.useState<number | null>(null)
  const [autoToggleKey, setAutoToggleKey] = React.useState<string | undefined | null>(null)
  const [loading, bindLoading] = useLoading()

  React.useEffect(() => {
    if (layoutY) {
      scrollRef.current?.scrollTo({ x: 0, y: 330 + layoutY, animated: true })
    }
  }, [layoutY])

  const scrollToKey = (key: string) => {
    setAutoToggleKey(key)
    setTimeout(() => {
      setAutoToggleKey(null)
      setLayoutY(null)
    }, 1000)
  }

  React.useEffect(() => {
    if (isNil(investmentInfo?.partialWithdrawHistory)) {
      const transCode = 'T510'
      pipe(
        IBService.ACMVQuery(policyNumber, 'PARTIAL_WITHDRAWAL_HISTORY', transCode, undefined, true),
        ZIO.map((res) => {
          if (res.data) {
            setInvestmentInfo({ ...(investmentInfo as InvestmentDataInfo), partialWithdrawHistory: res.data })
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo?.partialWithdrawHistory), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataGridView = [
    {
      label: t('TotalExcessPremium'),
      value: investmentInfo?.estimatedValueInfo?.totalExcessPremium + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('TopUpHistory'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAllocatedPremiumToBasicProductAndGroup1Riders'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocatedPremiumToBasicProductAndGroup1Riders + ' VNĐ' || '0'
    },
    {
      label: t('TotalAllocationCharges'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocationCharges + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AllocationChargeInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalWithdrawnAmount'),
      value:
        PulseOpsFormat.thousandSepartor(
          investmentInfo?.partialWithdrawHistory?.reduce(
            (pre, cur) => (pre = pre + (cur.additionalProperties?.partialWithDrawMoney?.withDrawAmount as number)),
            0
          )
        ) + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('PartialWithdrawalHistory'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAllocatedPremiumToGroup2Riders'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocatedPremiumToGroup2Riders + ' VNĐ' || '0'
    },
    {
      label: t('TotalCostOfInsurance'),
      value: investmentInfo?.estimatedValueInfo?.totalCostOfInsurance + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('CostOfInsuranceInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('MaximumWithdrawableAmount'),
      value: investmentInfo?.estimatedValueInfo?.maximumWithdrawableAmount + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('PartialWithdrawalInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAllocatedPremiumToAccountValue'),
      value: investmentInfo?.estimatedValueInfo?.totalAllocatedPremiumToAccountValue + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AllocatedPremiumInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('TotalAdministrationCharge'),
      value: investmentInfo?.estimatedValueInfo?.totalAdministrationCharge + ' VNĐ' || '0',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('AdministrationChargeInfo'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    }
  ]

  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <View>
          <View style={styles.container}>
            <View style={{ width: '23.75%', paddingHorizontal: 20, paddingTop: 30 }}>
              <View style={{ marginBottom: 30, marginRight: 15 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.labelText}>{t('FundValueTotal')}</Text>
                  <View style={{ marginLeft: 5 }}>
                    <Pressable onPress={() => scrollToKey(t('FundValueInfo'))}>
                      <assets.IBChevronRightCircle />
                    </Pressable>
                  </View>
                </View>
                <Text style={[styles.valueText]}>
                  {investmentInfo?.estimatedValueInfo?.fundValueTotal + ' VNĐ' || '0'}
                </Text>
              </View>
              <View style={{ marginBottom: 30, marginRight: 15 }}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.labelText}>{t('CurrentAllocatingRate')}</Text>
                  <View style={{ marginLeft: 5 }}>
                    <Pressable onPress={() => scrollToKey(t('PremiumRedirectionHistory'))}>
                      <assets.IBChevronRightCircle />
                    </Pressable>
                  </View>
                </View>
                <View>
                  {investmentInfo?.investmentSummary?.fundAllocatingRates?.map((item) => {
                    return (
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Text>
                          {item.fundId || '-'}{' '}
                          {i18n.language === 'vi'
                            ? listFundT25?.find((x) => x.fundCode === item.fundId)?.inBoundNameVN
                            : listFundT25?.find((x) => x.fundCode === item.fundId)?.fundNameEN}
                        </Text>
                        <Text>{parseInt(item.percentage as string).toFixed(0) || '-'}%</Text>
                      </View>
                    )
                  })}
                </View>
              </View>
            </View>
            <View style={{ width: '71.25%' }}>
              <IBGridDataView data={dataGridView} col={3} containerStyle={{ borderWidth: 0 }} />
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <IBPanel
              title={t('FundValueInfo')}
              children={
                <FundValueInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('LoyaltyBonus')}
              children={
                <LoyaltyBonus
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                />
              }
            />
            <IBPanel
              title={t('TopUpHistory')}
              children={
                <TopUpHistory
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('PartialWithdrawalHistory')}
              children={
                <PartialWithdrawalHistory
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('PartialWithdrawalInfo')}
              children={
                <PartialWithdrawalInfo
                  policyNumber={policyNumber}
                  productCode={productCode}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  status={status}
                  productType={productType}
                  basicCode={basicCode}
                  listFundT25={listFundT25}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('FundSwitchHistory')}
              children={
                <FundSwitchHistory
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  listFundT25={listFundT25}
                />
              }
            />
            <IBPanel
              title={t('PremiumRedirectionHistory')}
              children={
                <PremiumRedirectionHistory
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('AllocatedPremiumInfo')}
              children={
                <AllocatedPremiumInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('AllocationChargeInfo')}
              children={
                <AllocationChargeInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('CostOfInsuranceInfo')}
              children={
                <CostOfInsuranceInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('AdministrationChargeInfo')}
              children={
                <AdministrationChargeInfo
                  policyNumber={policyNumber}
                  setInvestmentInfo={setInvestmentInfo}
                  investmentInfo={investmentInfo}
                  productType={productType}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
          </View>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textStyle: {
    color: '#58647A',
    fontWeight: '700',
    fontSize: 15,
    marginRight: 5,
    marginBottom: 10
  },
  errorMsgStyle: {
    fontSize: 14,
    color: '#ED1B2E',
    fontStyle: 'italic',
    fontWeight: '400',
    marginBottom: 10
  },
  container: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#D3DCE6',
    flexDirection: 'row'
  },
  labelText: {
    fontWeight: '700',
    color: '#58647A',
    marginBottom: 10,
    fontSize: 15
  },
  valueText: {
    fontWeight: '400',
    fontSize: 15
  }
})
