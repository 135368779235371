import { PayoutPopup } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import { form2 } from '@pulseops/common'
import i18next from 'i18next'

export namespace ExcessPremiumForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  const ExcessPremium = t.intersection([
    t.type({
      receiptFlag: t.boolean,
      payout: t.array(PayoutPopup.SummaryCodec)
    }),

    t.union([
      t.type({
        receiptFlag: t.literal(true),
        documents: pipe(
          t.array(File),
          form2.refine(
            (l): l is FileList => l && l.length > 0,
            () => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
            'FileList'
          )
        )
      }),
      t.type({ receiptFlag: t.literal(false) })
    ])
  ])

  export type ExcessPremium = t.TypeOf<typeof ExcessPremium>

  export const codec = ExcessPremium

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
