import React from 'react'
import { TextStyle, View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { Paper, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Popover } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { useTranslation } from 'react-i18next'
import Icon from 'react-native-vector-icons/AntDesign'
export interface RowSelectedProps {
  value?: string
}
export type Columns = {
  key: string | number
  title: string | JSX.Element
  name: string
  styles?: TextStyle
  flex?: number
  require?: boolean
  color?: (val: string) => string
  render?: (props: RowSelectedProps) => JSX.Element
}
export type DataSource = {
  [key: string]: string | number | JSX.Element
}
export interface TableProps {
  columns: Columns[]
  dataSource: DataSource[]
  onRowSelected?: (index: number, data: DataSource) => void
}
const headerTableStyle: CSSProperties = {
  backgroundColor: '#f4f4f4',
  paddingHorizontal: 24
}

export const TableCustom = ({ columns, dataSource, onRowSelected, ...props }: TableProps) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState<number | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleSelectedRow = (data: DataSource, index: number, event: any) => {
    setSelected(index)
  }

  const handleYes = () => {
    const data = dataSource[selected || 0] as DataSource
    onRowSelected && onRowSelected(selected || 0, data)
    handleClose()
  }

  const handleClose = () => {
    setSelected(null)
    setAnchorEl(null)
  }
  return (
    <View style={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={headerTableStyle}>
            <TableRow>
              {columns.map((item) => {
                return (
                  <TableCell style={{ color: 'gray', fontWeight: '600' }} key={item.key}>
                    {item.title}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((data, i) => {
              return (
                <TableRow hover selected={selected === i} onClick={(e) => handleSelectedRow(data, i, e)}>
                  {columns.map((row, i) => {
                    const value = data[row.name] as string
                    return (
                      <TableCell
                        key={i}
                        onClick={(event: any) => {
                          setAnchorEl(event.currentTarget)
                        }}
                      >
                        {row.render ? row.render({ value }) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {dataSource.length === 0 && (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  {t('common:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        style={{ zIndex: 10000 }}
      >
        <View
          style={{
            flex: 1,
            padding: 12,
            width: 250
          }}
        >
          <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row', marginBottom: 10 }}>
              <View style={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}>
                <Icon name="exclamationcircle" size={18} color="#FBAD13" />
              </View>
              <View style={{ width: '80%' }}>
                {/* <Text>This information will be filled in your current form.</Text>
                <Text>Do you confirm?</Text> */}
                <Text>{t('message:DuplicateInformation')}</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <TouchableOpacity
                onPress={handleClose}
                style={[styles.button, { backgroundColor: 'white', borderWidth: 1, borderColor: 'gray' }]}
              >
                <Text>{t('common:No')}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleYes} style={[styles.button, { backgroundColor: 'red' }]}>
                <Text style={{ color: 'white' }}>{t('common:Yes')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Popover>
    </View>
  )
}
const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 15,
    margin: 5
  }
})
