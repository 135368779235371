import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'
import { LifeAssuredAdditions, LifeAssuredList } from './RiderAlteration'
import { lifeAssured } from './CancelRider'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.MAJOR_COMBINED_CHANGE)
    }),
    codec
  ])

const changeContract = t.type({
  basicProductCode: t.string,
  riskCommDate: t.string,
  age: t.number,
  sumAssured: t.number,
  policyYear: t.string,
  riskCessDate: t.string,
  premiumYear: t.string,
  installmentPremium: t.number
})

const billingChange = t.type({
  curBillingFrequency: t.string,
  curInstallmentPremium: t.string,
  newBillingFrequency: t.string,
  newInstallmentPremium: t.string,
  effectiveDateNewFrequency: t.string,
  requirePayinAmount: t.string
})

const productOptionSwitching = t.type({
  benefits: t.string,
  oldBenefit: t.string,
  currentSumAssured: t.number,
  newSumAssured: t.number,
  riskSumAssured: t.number,
  minimumSumAssured: t.number
})

const riderAlteration = t.type({
  isAddLA: t.boolean,
  isRiderAlteration: t.boolean,
  lifeAssuredList: LifeAssuredList,
  lifeAssuredAdditions: LifeAssuredAdditions
})

const riderCancellation = t.type({
  policyNo: Maybe(t.string),
  lifeAssureds: Maybe(t.array(lifeAssured))
})

const lifeAssuredReins = t.type({
  clientId: t.string,
  name: t.string,
  attributesExt: Maybe(
    t.type({
      IS_CHANGE_HEALTH_STATE: Maybe(t.boolean),
      IS_OCCUPATION_CHANGE: Maybe(t.boolean),
      IS_ACTIVITY_CHANGE: Maybe(t.boolean),
      IS_EXIST_REQUEST_COMPANY: Maybe(t.boolean),
      CUR_OCCUPATION: Maybe(t.string),
      NEW_OCCUPATION: Maybe(t.string),
      NEW_ACTIVITY: Maybe(t.string),
      COMPANY_NAME: Maybe(t.string)
    })
  )
})

const reinstatement = t.type({
  policyNo: t.string,
  owners: t.type({
    clientId: t.string
  }),
  lifeAssured: t.array(lifeAssuredReins),
  attributesExt: t.type({
    TRANS_CODE: t.string,
    AGENT_CODE: t.string,
    AGENT_NAME: t.string,
    RISK_COMM_DATE: Maybe(t.string),
    LAPSED_DATE: Maybe(t.string),
    FREQUENCY: Maybe(t.string),
    INSTALLMENT_PREM: Maybe(t.number),
    APL: Maybe(t.number),
    OPL: Maybe(t.number),
    REINS_FEE: Maybe(t.number),
    TOTAL_REINS_AMOUNT: Maybe(t.number)
  })
})

const riderInfo = t.type({
  coverageCode: t.string,
  riskCommDate: t.string,
  lapsedDate: t.string,
  sumAssured: t.number,
  riderPremium: t.number
})

const riderReinstatement = t.type({
  reinsFee: t.number,
  lifeAssuredList: t.array(
    t.type({
      clientNo: t.string,
      agentCode: t.string,
      healthStateChange: t.boolean,
      occupationChange: t.boolean,
      curOccupation: t.string,
      newOccupation: t.string,
      activityChange: t.boolean,
      newActivity: t.string,
      companyChange: t.boolean,
      newCompany: t.string,
      riderList: t.array(riderInfo)
    })
  )
})

const payloadPulse4Ops = t.type({
  body: t.type({
    majorType: t.string,
    changeContract: Maybe(changeContract),
    billingChange: Maybe(billingChange),
    productOptionSwitching: Maybe(productOptionSwitching),
    riderAlteration: Maybe(riderAlteration),
    riderCancellation: Maybe(riderCancellation),
    reinstatement: Maybe(reinstatement),
    riderReinstatement: Maybe(riderReinstatement),
    requirePayinAmount: Maybe(t.string)
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

export const MajorCombined = Pulse4Ops
export type MajorCombined = t.TypeOf<typeof MajorCombined>
