import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { ControlProps } from '../FormProps'
import { assets } from '../../assets'
import { makeStyles } from '@material-ui/core'
import { Text } from 'react-native'

export type DatePickerTableProps = ControlProps<Date | null> & {
  styles?: {
    backgroundColor?: string
    padding?: number
  }
  disabled?: boolean
}

export const DatePickerTable = (props: DatePickerTableProps) => {
  const useStyles = makeStyles({
    root: {
      '& .MuiInputBase-root': {
        borderColor: '#D3DCE6',
        borderWidth: 1,
        borderRadius: 8,
        backgroundColor: props.styles?.backgroundColor || 'transparent',
        padding: props.styles?.padding || 10,
        '& .MuiButtonBase-root': {
          padding: 0
        },
        '& .MuiInputBase-input': {
          padding: 0
        }
      }
    }
  })
  const { root } = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={root}
        clearable
        value={props.value != null ? props.value : null}
        onChange={(date) => {
          if (props.onChange) {
            props.onChange(date)
          }
        }}
        keyboardIcon={<assets.InputCalendar />}
        inputVariant="outlined"
        onBlur={props.onBlur}
        placeholder="dd/mm/yyyy"
        format="dd/MM/yyyy"
        fullWidth
        disabled={props.disabled === true}
      />
      {props.errorMessage && props.value !== null && (
        <Text style={{ color: '#ED2B1C', fontSize: 11.25 }}>{props.errorMessage}</Text>
      )}
    </MuiPickersUtilsProvider>
  )
}
