import { FileUploadData, StorageBlob } from '@pulseops/common'
import * as t from 'io-ts'

export namespace SUCommonRequestData {
  export interface DetailData {
    commonRequestList: CommonRequest[]
    documentTypeList: DocumentType[]
    transactionWFTypeList: TransactionWFType[]
  }

  export const CommonRequest = t.type({
    id: t.string,
    requestCode: t.string,
    requestNameEn: t.string,
    requestWF: t.string,
    requestNameVn: t.string,
    isCommonRequest: t.string
  })

  export const DocumentType = t.type({
    id: t.string,
    requestName: t.string,
    docTypeCode: t.string,
    docTypeEn: t.string,
    docTypeVn: t.string,
    mandatory: t.string
  })

  export const TransactionWFType = t.type({
    id: t.string,
    request: t.string,
    transactionTypeWF: t.string
  })

  export type CommonRequest = t.TypeOf<typeof CommonRequest>
  export type DocumentType = t.TypeOf<typeof DocumentType>
  export type TransactionWFType = t.TypeOf<typeof TransactionWFType>

  export interface DocumentTypeFiles {
    docTypeCode: string
    transactionTypeName: string
    attachmentList: FileUploadData[]
  }

  export interface SubmitedData {
    request: string
    transWf: string
  }

  export interface RequestInfoData {
    submitedData: SubmitedData
    documentFiles: StorageBlob.FileContentSubmit[]
  }
}
