import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
export const PolicyCertificate = t.type({
  transactionType: t.union([
    t.literal(TransactionType.POLICY_CERTIFICATE),
    t.literal(TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE)
  ]),
  payload: t.type({
    body: t.type({
      office: Maybe(t.string),
      reason: Maybe(t.string),
      status: Maybe(t.string)
    })
  }),
  source: Maybe(t.string)
})
export type PolicyCertificate = t.TypeOf<typeof PolicyCertificate>
