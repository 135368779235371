import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  SelectOption,
  PulseOpsService,
  Toast,
  AppContext,
  GeneralService,
  TaskType,
  SubTaskType,
  SelectSearch,
  assets
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Color } from '@material-ui/lab'
import * as O from 'fp-ts/lib/Option'

export type UnsuspendPopupProps = {
  onClose: () => void
  open: boolean
  onSuccess?: (mustRefreshTaskGroup?: boolean) => void
  selectedValue: {
    category: TaskType
    basket: SubTaskType
    taskIds: string[]
    updateToCore: boolean
    currAssignee: string
  }
}

export const UnsuspendPopup = (props: UnsuspendPopupProps) => {
  const { onClose, onSuccess, open, selectedValue } = props
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const [note, setNote] = useState<string>('')
  const [groupSelected, setGroupSelected] = useState<SelectOption | null>(null)
  const [userSelected, setUserSelected] = useState<SelectOption | null>(null)
  const [assignmentGroups, setAssignmentGroups] = useState<SelectOption[]>([])
  const [users, setUsers] = useState<SelectOption[]>([])

  const showToastinternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const handleClose = () => {
    setNote('')
    setUsers([])
    setAssignmentGroups([])
    setUserSelected(null)
    setGroupSelected(null)
    onClose()
  }

  React.useEffect(() => {
    if (selectedValue.category && open) {
      pipe(
        GeneralService.getUsersGroupsByTransaction(selectedValue.category),
        ZIO.tap((res) => {
          setAssignmentGroups(
            // [TaskType.IDC].includes(selectedValue.category) ?
            //   res.map((x) => ({
            //     value: x.groupName,
            //     label: x.groupName
            //   })) :
              res.map((x) => ({
                value: x.userGroup,
                label: x.groupName
              }))
          )
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [selectedValue.category, open])

  React.useEffect(() => {
    setUsers([])
    setUserSelected(null)
    if (groupSelected) {
      const customUserGroup = selectedValue.category === TaskType.IDC ?
      pipe(groupSelected.value,
        O.fromNullable,
        O.map((groupData) => {
          const groupInfoList = groupData.split('-')
          return groupInfoList[1]
        }),
        O.getOrElse(() => '')
      ) : groupSelected.value
      pipe(
        GeneralService.getUsersByGroups(
          [customUserGroup],
          selectedValue.currAssignee || '',
          selectedValue.category,
          selectedValue.basket
        ),
        ZIO.catchAll((_error) => ZIO.fail(null)),
        ZIO.tap((users) => {
          setUsers(users.map((item) => ({ label: item.name, value: item.email })))
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [groupSelected])

  const onHandleTransfer = () => {
    if (selectedValue.taskIds.length > 0 && userSelected) {
      showGlobalLoading(true)
      pipe(
        PulseOpsService.unSuspendTask(
          selectedValue.taskIds,
          userSelected.value,
          {
            author: 'admin',
            message: note
          },
          selectedValue.updateToCore,
          String(selectedValue.basket),
          groupSelected?.value ?? '',
          [TaskType.IDC].includes(selectedValue.category) ? selectedValue.category : undefined
        ),
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastinternal(t('requestInfo:UnsuspendFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          handleClose()
          showToast(t('requestInfo:UnsuspendSuccess'), 'success')
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ModalComponent
      title={t('claim:Unsuspend')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('claim:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('claim:Unsuspend'),
          type: 'filled',
          disabled: !groupSelected || !userSelected,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: onHandleTransfer
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16, flexDirection: isWide ? 'row' : 'column' }}>
          <View style={{ flex: 1, marginEnd: isWide ? 32 : 0, marginBottom: isWide ? 0 : 16 }}>
            <SelectSearch
              value={groupSelected}
              label={t('claim:Group')}
              showPopupIcon={true}
              required={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={assignmentGroups}
              onChange={setGroupSelected}
            />
          </View>
          <View style={{ flex: 1 }}>
            <SelectSearch
              value={userSelected}
              label={t('claim:User')}
              required={true}
              showPopupIcon={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={users}
              onChange={setUserSelected}
            />
          </View>
        </View>
        <TextInput
          title={t('claim:Comment')}
          value={note}
          onChange={setNote}
          placeholder={'...'}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
