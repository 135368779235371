import { SelectOption } from '@pulseops/common'
import * as t from 'io-ts'
const AdjudicationInfo = t.type({
  payableAmount: t.string,
  claimDecision: t.string,
  effectiveDate: t.string,
  policyStatus: t.string
})

const PayeeInfo = t.type({
  paymentMethod: t.string,
  isNewClient: t.boolean,
  isPayeeNotPO: t.boolean,
  payeeName: t.string,
  idNumber: t.string
})

const AttributesExt = t.type({
  PAYEE_INFO: PayeeInfo
})

const BankAccount = t.type({
  bankName: t.string,
  branchCode: t.string,
  accountNo: t.string,
  accountName: t.string
})
const PaymentOption = t.type({
  currency: t.string,
  amount: t.number
})

const Policy = t.type({
  policyNo: t.string,
  totalPremium: t.number,
  status: t.string
})
const CashOutOptions = t.type({
  type: t.string,
  policy: Policy,
  paymentOption: PaymentOption,
  bankAccount: BankAccount,
  attributesExt: AttributesExt
})

const ClaimInfo = t.type({
  claimNo: t.string,
  claimType: t.string,
  subClaimType: t.string,
  relatedOfAccident: t.boolean,
  claimEventDate: t.string,
  endClaimDate: t.string,
  parentBusinessKey: t.string,
  lastVerUpdatedBy: t.string,
  lastVerUpdatedDate: t.string,
  lastAdjUpdatedBy: t.string,
  lastAdjUpdatedDate: t.string
})

const PaymentInfo = t.type({
  cashOutOptions: t.array(CashOutOptions)
})

const ClaimPayoutSubmitData = t.type({
  claimInfo: ClaimInfo,
  adjudicationInfo: AdjudicationInfo,
  paymentInfo: PaymentInfo
})

export type ClaimPayoutSubmitData = t.TypeOf<typeof ClaimPayoutSubmitData>

