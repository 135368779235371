import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TaskResponse = t.type({
  id: t.string,
  assignee: Maybe(t.string),
  processInstanceId: Maybe(t.string),
  category: Maybe(t.string),
  transactionType: Maybe(t.string),
  correlationId: Maybe(t.string),
  createTime: DateFromISOString,
  endTime: Maybe(DateFromISOString),
  source: Maybe(t.string),
  vip: Maybe(t.string),
  businessKey: Maybe(t.string),
  updateToCore: t.boolean
})
export type TaskResponse = t.TypeOf<typeof TaskResponse>

export const QueryTaskResponse = t.type({
  data: t.union([t.array(TaskResponse), t.null]),
  total: t.number
})
export type QueryTaskResponse = t.TypeOf<typeof QueryTaskResponse>
