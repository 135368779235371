import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export const TemplateImportFileS = t.type({
  id: t.string,
  adhocCode: t.string,
  adhocName: t.string,
  createdBy: t.string,
  createdDate: t.string,
  departmentCode: t.string,
  updatedBy: t.string,
  updatedDate: t.string,
  headerTemplateEn: t.type({
    PARAM01: t.string,
    PARAM02: t.string,
    PARAM03: t.string,
    PARAM04: t.string,
    PARAM05: t.string,
    PARAM06: t.string,
    PARAM07: t.string,
    PARAM08: t.string,
    PARAM09: t.string,
    PARAM10: t.string,
    PARAM11: t.string,
    PARAM12: t.string,
    PARAM13: t.string,
    PARAM14: t.string,
    PARAM15: t.string,
    PARAM16: t.string,
    PARAM17: t.string,
    PARAM18: t.string,
    PARAM19: t.string,
    PARAM20: t.string,
  }),
  headerTemplateVn: t.type({
    PARAM01: t.string,
    PARAM02: t.string,
    PARAM03: t.string,
    PARAM04: t.string,
    PARAM05: t.string,
    PARAM06: t.string,
    PARAM07: t.string,
    PARAM08: t.string,
    PARAM09: t.string,
    PARAM10: t.string,
    PARAM11: t.string,
    PARAM12: t.string,
    PARAM13: t.string,
    PARAM14: t.string,
    PARAM15: t.string,
    PARAM16: t.string,
    PARAM17: t.string,
    PARAM18: t.string,
    PARAM19: t.string,
    PARAM20: t.string,
  }),
  headerParamType: Maybe(t.type({
    PARAM01: t.string,
    PARAM02: t.string,
    PARAM03: t.string,
    PARAM04: t.string,
    PARAM05: t.string,
    PARAM06: t.string,
    PARAM07: t.string,
    PARAM08: t.string,
    PARAM09: t.string,
    PARAM10: t.string,
    PARAM11: t.string,
    PARAM12: t.string,
    PARAM13: t.string,
    PARAM14: t.string,
    PARAM15: t.string,
    PARAM16: t.string,
    PARAM17: t.string,
    PARAM18: t.string,
    PARAM19: t.string,
    PARAM20: t.string
  }))
})

export type TemplateImportFile = t.TypeOf<typeof TemplateImportFileS>

const records = t.array(
  t.type({
    id: t.string,
    fileName: t.string,
    evidenceName: t.string,
    adhocCode: t.string,
    adhocName: t.string,
    departmentCode: t.string,
    status: t.string,
    totalRecord: Maybe(t.number),
    recordValid: Maybe(t.number),
    recordInvalid: Maybe(t.number),
    recordSubmitted: Maybe(t.number),
    createdBy: t.string,
    createdDate: t.string,
    updatedBy: t.string,
    updatedDate: t.string,
    recordFail: Maybe(t.number)
  })
)

const paging = t.type({
  pageIndex: t.number,
  itemsPerPage: t.number,
  total: t.number,
  pages: t.number
})

export const SearchImportFileS = t.type({
  data: t.type({
    records,
    paging
  })
})

export type SearchImportFile = t.TypeOf<typeof SearchImportFileS>

const _records = t.array(
  t.type({
    id: t.string,
    adhocCode: t.string,
    adhocName: t.string,
    departmentCode: t.string,
    policyNumber: t.string,
    paramData: Maybe(t.record(t.string, Maybe(t.string))),
    status: t.string,
    fileName: t.string,
    invalidReason: Maybe(t.string),
    createdBy: t.string,
    createdDate: t.string,
    updatedBy: t.string,
    updatedDate: t.string,
    evidenceName: Maybe(t.string)
  })
)

export const SearchDetailDataImportFileS = t.type({
  data: t.type({
    records: _records,
    paging
  })
})
export type SearchDetailDataImportFile = t.TypeOf<typeof SearchDetailDataImportFileS>
