import * as t from 'io-ts'

const SelectOption = t.type({
  value: t.string,
  label: t.string
})

const IBClientCallingResultForm = t.type({
  policyNumber: SelectOption,
  ownerName: t.string
})
export type IBClientCallingResultForm = t.TypeOf<typeof IBClientCallingResultForm>
