import { StyleSheet, Text, View, ScrollView, useWindowDimensions, Pressable } from 'react-native'
import { Fab } from '@material-ui/core'
import {
  assets,
  ErrorHandling,
  SelectOption,
  InquiryComplaintService,
  TopUpService,
  CancelRiderService,
  InquiryComplaintData,
  AuthService,
  PulseOpsFormat,
  AppContext,
  TransactionType,
  RBAC,
  form2,
  IBPolicyPermission,
  SubmissionService,
  SourceType,
  InternalFeedbackService
} from '@pulseops/common'
import { InquiryComplaintConst } from '@pulseops/task'
import { IBGeneralField, TypeInputComponent, IBButton, ClientScreenContext, IBButtonMultiTasks } from '../../common'
import {
  IBClientCallingResultForm,
  IBSalesPractice,
  IBGAOperation,
  IBMoneyMisappropriation,
  IBConservation,
  IBCommunicationInfo
} from './index'
import { useTranslation } from 'react-i18next'
import { useForm, UseFormReturn } from 'react-hook-form'
import { IBService, IACGroup, SaveDraft } from '../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ComplainInquiryForm } from '../../../../../submission/src/complain-inquiry'
import { UploadedFilesInfo } from '../../../../../submission/src/ps-submission'
import React from 'react'
import moment from 'moment'
import _, { isEmpty, orderBy } from 'lodash'
const IBModalCallResult = ({
  form,
  policyNumCallingResultForm,
  setPolicyLists,
  policyLists,
  showPopup,
  setShowPopup
}: {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  policyNumCallingResultForm: UseFormReturn<IBClientCallingResultForm>
  setPolicyLists: (data: (SelectOption & { policyOwner: string })[]) => void
  policyLists: (SelectOption & { policyOwner: string })[]
  showPopup: boolean
  setShowPopup: (open: boolean) => void
}) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoader] = useLoading(false)

  const modalForm = policyNumCallingResultForm

  const base = form
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const { height, width } = useWindowDimensions()
  const clientNumber = urlParams.get('clientNumber')
  const [groupList, setGroupList] = React.useState<IACGroup[]>([])
  const [subServiceTypeList, setSubServiceTypeList] = React.useState<InquiryComplaintData.IACType[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<InquiryComplaintData.IACSubtypes[]>([])
  const [requestorRoleList, setRequestorRoleList] = React.useState<SelectOption[]>(
    InquiryComplaintConst.requestorRoleList.map((item) => ({
      label: t(`roles:${item.label}`) as string,
      value: item.value
    })) || []
  )
  const [relationshipList, setRelationshipList] = React.useState<InquiryComplaintConst.Group[]>([])
  const [isDisabledFullname, setDisabledFullname] = React.useState<boolean>(false)
  const [isDisabledRPO, setDisabledRPO] = React.useState<boolean>(false)
  const [isDisabledFCR, setDisabledFCR] = React.useState<boolean>(false)
  const [isDisabledHot, setDisabledHot] = React.useState<boolean>(false)

  const [categoryCode, setCategoryCode] = React.useState<string>('')
  const [userInfo, setUserInfo] = React.useState<{ email: string; name: string }>({
    email: '',
    name: ''
  })
  const [isReset, setReset] = React.useState<boolean>(false)
  const [groupCode, setGroupCode] = React.useState<string>('')
  const [agentCodeError, setAgentCodeError] = React.useState<string>('')
  const [isShowConservation, setShowConservation] = React.useState<boolean>(false)
  const [isMaximize, setMaximize] = React.useState<boolean>(false)
  const accountLogIn: string = ''
  const listMethodShowCallID = ['MT02', 'MT03', 'MT12']
  const [clientIdPO, setClientIdPO] = React.useState<string>('')
  const listHoursCallmeBack = ['1', '2', '3', '4', '5', '6', '7', '8', '10', '14', '18', '22', '30', '46']
  const workingTimeList = pipe(
    InternalFeedbackService.getWorkingTimeList(moment().format('YYYY/MM/DD')),
    ZIO.map((workingList) => {
      return workingList ?? []
    }),
    ErrorHandling.runDidMount([])
  )

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const getCategoryCode = (value: string) => {
    setCategoryCode(value)
  }

  const getGroupCode = (value: string) => {
    setGroupCode(value)
    setAgentCodeError('')
  }

  const [detailData, setDetailData] = React.useState<{
    detail: InquiryComplaintData.DetailData | null
    mainProductCode?: string | null
    riders?: InquiryComplaintData.IACSources[] | null
  } | null>(null)

  pipe(
    AuthService.userInfo,
    ZIO.map((user) => {
      setUserInfo(user)
    }),
    ErrorHandling.runDidUpdate([detailData?.detail])
  )

  React.useEffect(() => {
    if (_.isEmpty(policyLists) && showPopup) {
      pipe(
        // IBService.searchRelatedPolicy({
        //   pageIndex: 0,
        //   itemsPerPage: 100,
        //   orders: ['policyNo'],
        //   sort: 'DESC',
        //   role: '',
        //   lstPolicyStatusFilter: [],
        //   policyNo: '',
        //   clientNumber: clientNumber as string
        // }),
        // ZIO.map((res) => {
        //   if (!_.isEmpty(res.records)) {
        //     setPolicyLists(
        //       res.records?.map((item) => ({
        //         value: item.policyNumber || '-',
        //         label: item.policyNumber || '-',
        //         policyOwner: item.policyOwnerName?.name || '-'
        //       }))
        //     )
        //   }
        //   return ZIO.unit
        // }),
        IBService.getPolicyList(clientNumber || ''),
        ZIO.map((policyArr) => {
          if (!_.isEmpty(policyArr)) {
            setPolicyLists(
              policyArr.map((item) => ({ value: item.policyNumber, label: item.policyNumber, policyOwner: '' }))
            )
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [showPopup])

  const getPOName = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicyHeader(policyNum),
      ZIO.map((policyInfo) => {
        modalForm.setValue('ownerName', policyInfo.ownerName || '')
        setClientIdPO(policyInfo.clientId)
        return policyInfo
      }),
      ZIO.unsafeRun({})
    )

  const converOptions = (arr: any[]): SelectOption[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  const arrangeAndFilterGroupListByCagetory = (groupList: InquiryComplaintData.IACGroup[]) => {
    const inquiryList = groupList.filter((x) => x.categoryName === 'Inquiry')
    const complaintList = groupList.filter((x) => x.categoryName === 'Complaint')
    return [...inquiryList, ...complaintList]
  }

  const setAppointmentTime = (callbackHour: string) => {
    const currentDate = new Date
    let nextDay = ''
    let estimatedTime: moment.Moment = moment()
    let originSLAMinutes = Number(callbackHour) * 60
    let originEffectiveTime = moment(currentDate)
    for (let i = 0; i < workingTimeList.length && i + 1 < workingTimeList.length; i++) {
      // calculate in working time
      if (!!workingTimeList[i].startTime && !!workingTimeList[i].endTime) {
        const startDate = workingTimeList[i].date + ' ' + workingTimeList[i].startTime
        const endDate = workingTimeList[i].date + ' ' + workingTimeList[i].endTime
        // if startDate > originEffectiveTime, We set value variable originEffectiveTime to equal with startdate
        if (moment(startDate).valueOf() >= originEffectiveTime.valueOf()) {
          const newEffectiveValue = moment(startDate).valueOf() - originEffectiveTime.valueOf()
          originEffectiveTime = moment(originEffectiveTime.valueOf() + newEffectiveValue)
        }
        if (moment(endDate).valueOf() > originEffectiveTime.valueOf()) {
          const SLAHour = Math.floor(originSLAMinutes / 60)
          const SLAMinutes = Math.round((originSLAMinutes / 60 - SLAHour) * 60)
          estimatedTime = moment(originEffectiveTime).add(SLAHour, 'hours').add(SLAMinutes, 'minutes')
          // if estimatedTime less than endDate, we get valid estimatedTime and  break loop
          if (moment(endDate).valueOf() >= estimatedTime.valueOf()) {
            break
          }
          // else we get distanceMinutes and set originEffectiveTime = nextDay
          const distanceMinutes = moment(endDate).diff(originEffectiveTime, 'minutes')
          nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
          originEffectiveTime = moment(nextDay)
          originSLAMinutes = originSLAMinutes - distanceMinutes
        } else {
          // if originEffectiveTime > endDate, we will set originEffectiveTime = next working day
          if (workingTimeList[i + 1].startTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          } else if (workingTimeList[i + 1].endTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].endTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          }
        }
      }
    }
    const appointmentTime = estimatedTime.format('DD/MM/YYYY HH:mm:ss')
    base.setValue('generalInfo.callbackAppointmentTime', appointmentTime)
  }

  const handleDetailData = (policyNumber: string) => {
    pipe(
      ZIO.zipPar(
        InquiryComplaintService.getDetail(policyNumber),
        TopUpService.GetPolicyExtraInfo([policyNumber], ''),
        CancelRiderService.getRiderInfoList()
      ),
      ZIO.map(([detail, policyExtraInfo, riders]) => {
        if (detail?.groupList) {
          setGroupList(arrangeAndFilterGroupListByCagetory(detail.groupList))
        }
        if (detail?.benList && detail.benList.length <= 0) {
          setRequestorRoleList(requestorRoleList.filter((x) => x.value !== '3'))
        }
        setDetailData({
          detail,
          mainProductCode: policyExtraInfo.policyExtraInfoDetail.find(
            (x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00'
          )?.productCode,
          riders
        })
        if (detail.sourceList) {
          const so14Item = detail.sourceList.find((x) => x.code === 'SO14')
          base.setValue(
            'generalInfo.source',
            so14Item
              ? {
                  value: so14Item.code,
                  label: i18n.language === 'en' ? so14Item.name : so14Item.nameVi
                }
              : null
          )
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const compareItemsByName = (item1: any, item2: any) => {
    return i18n.language === 'en' ? item1.name.localeCompare(item2.name) : item1.nameVi.localeCompare(item2.nameVi)
  }

  const getSubServiceTypeList = (groupCode: string) => {
    if (groupCode) {
      pipe(
        InquiryComplaintService.getTypes(groupCode),
        ZIO.map((groupList) => {
          if (groupList && groupList.length > 0) {
            setSubServiceTypeList(groupList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
          } else {
            setSubServiceTypeList([])
          }
        }),
        ZIO.catchAll((error) => {
          return ZIO.fail(null)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onShowConservation = (value: boolean) => {
    setShowConservation(value)
    setAgentCodeError('')
  }

  const onShowHideConservation = (e: SelectOption | null) => {
    if (e?.value === 'CS2701') {
      onShowConservation(true)
      base.setValue('generalInfo.hot', false)
      setDisabledHot(true)
      base.setValue('generalInfo.firstContactResolution', true)
      setDisabledFCR(true)
    } else {
      onShowConservation(false)
      base.setValue('generalInfo.hot', false)
      setDisabledHot(false)
      base.setValue('generalInfo.firstContactResolution', false)
      // setDisabledFCR(false)
    }
  }

  const onResetValue = (value: string) => {
    base.clearErrors()
    base.setValue('communicationInfo.customerComments', '')
    base.setValue('communicationInfo.responsesToCustomer', '')
    base.setValue('communicationInfo.attachmentFiles', [])

    base.setValue('conservationInfomation.attachmentFiles', [])
    base.setValue('conservationInfomation.conservationAgentCode', '')
    base.setValue('conservationInfomation.conservationAgentName', '')
    base.setValue('conservationInfomation.conservationBonus', null)
    base.setValue('conservationInfomation.conservationGAStaff', false)
    base.setValue('conservationInfomation.conservationNote', '')
    base.setValue('conservationInfomation.conservationResult', null)
    base.setValue('conservationInfomation.conservationSolution', null)
    base.setValue('conservationInfomation.reasonFullSurrender', null)
    if (value !== 'CHG4') {
      base.setValue('ga.generalAgency', null)
      base.setValue('ga.personToBeComplained', '')
    }
    if (value !== 'CHG3') {
      base.setValue('salesPractice.agentCode', '')
      base.setValue('salesPractice.agentName', '')
      base.setValue('salesPractice.POSignedInProposal', 'yes')
      base.setValue('salesPractice.customerReceivedDate', new Date())
      base.setValue('salesPractice.customerReceivedPolicyKit', 'yes')
      base.setValue('salesPractice.financialNeedAnalysis', null)
    }
    if (value !== 'CHG2') {
      base.setValue('moneyMissappropriation.list', [
        {
          receipt: 'no',
          receiptType: null,
          receiptNumber: '',
          complaintAmount: '',
          benefit_PremiumMisappropriatedDate: new Date()
        }
      ])
      base.setValue('moneyMissappropriation.totalComplaintAmount', '0')
    }
  }

  const onGroupChange = (e: SelectOption | null) => {
    setSubServiceTypeList([])
    setSubTransactionTypeList([])
    getGroupCode(e?.value || '')
    getSubServiceTypeList(e?.value || '')
    base.setValue('generalInfo.type', null)
    base.setValue('generalInfo.subType', null)
    onResetValue(e?.value || '')
    onShowHideConservation(null)
    const complaintItem = groupList.find((x) => x.code === e?.value)
    if (
      complaintItem?.categoryName === 'Complaint' ||
      (complaintItem?.categoryName === 'Inquiry' && accountLogIn === 'Customer')
    ) {
      base.setValue('generalInfo.firstContactResolution', false)
      setDisabledFCR(true)
    } else {
      setDisabledFCR(false)
      base.setValue('generalInfo.firstContactResolution', false)
    }
    if (complaintItem?.categoryName === 'Inquiry') {
      getCategoryCode('CS')
    } else {
      getCategoryCode('CH')
    }
  }

  const onTypeChange = (e: SelectOption | null) => {
    base.clearErrors('generalInfo.subType')
    base.setValue('generalInfo.subType', null)
    if (
      base.watch('generalInfo.group')?.value === 'CHG1' ||
      base.watch('generalInfo.group')?.value === 'CHG2' ||
      base.watch('generalInfo.group')?.value === 'CHG3' ||
      base.watch('generalInfo.group')?.value === 'CHG4'
    ) {
      setDisabledFCR(true)
      base.setValue('generalInfo.firstContactResolution', false)
    } else {
      setDisabledFCR(false)
    }
    setSubTransactionTypeList([])
    onShowHideConservation(null)
    onResetValue(e?.value || '')
    if (!!e) {
      getSubTransactionTypeList(e?.value)
    }
  }

  const getSubTransactionTypeList = (typeCode: string) => {
    pipe(
      InquiryComplaintService.getSubType(typeCode),
      ZIO.map((typeList) => {
        if (typeList && typeList.length > 0) {
          setSubTransactionTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
        } else {
          setSubTransactionTypeList([])
        }
      }),
      ZIO.catchAll((error) => {
        return ZIO.fail(null)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onSubTypeChange = (e: SelectOption | null) => {
    onShowHideConservation(e)
    onResetValue(e?.value || '')
  }

  const onChangeRequestRole = (e: any): void => {
    base.clearErrors([
      'generalInfo.relationshipWithPolicyOwner',
      'generalInfo.requestorFullName1',
      'generalInfo.requestorFullName'
    ])
    if (!!e) {
      switch (e.value) {
        case '1':
          base.setValue(
            'generalInfo.requestorFullName',
            modalForm.getValues().ownerName ? modalForm.getValues().ownerName : detailData?.detail?.poName || ''
          )
          base.setValue('generalInfo.relationshipWithPolicyOwner', null)
          base.clearErrors('generalInfo.relationshipWithPolicyOwner')
          setDisabledFullname(true)
          setDisabledRPO(true)
          setRelationshipList([])
          break
        case '2':
          base.setValue('generalInfo.requestorFullName1', null)
          base.setValue('generalInfo.relationshipWithPolicyOwner', null)
          base.clearErrors('generalInfo.relationshipWithPolicyOwner')
          setDisabledFullname(false)
          setDisabledRPO(true)
          setRelationshipList([])
          break
        case '3':
          base.setValue('generalInfo.requestorFullName1', null)
          base.setValue('generalInfo.relationshipWithPolicyOwner', null)
          base.clearErrors('generalInfo.relationshipWithPolicyOwner')
          setDisabledFullname(false)
          setDisabledRPO(true)
          setRelationshipList([])
          break
        case '4':
          base.setValue('generalInfo.requestorFullName', '')
          base.setValue('generalInfo.relationshipWithPolicyOwner', null)
          setDisabledFullname(false)
          setDisabledRPO(false)
          if (detailData?.detail?.CBrelationshipList) {
            setRelationshipList(
              detailData?.detail?.CBrelationshipList.map((relationshipItem) => {
                return {
                  code: relationshipItem.code,
                  nameVi: relationshipItem.name,
                  name: relationshipItem.nameEn,
                  parentCode: ''
                }
              })
            )
          } else {
            setRelationshipList([])
          }
          break
      }
    } else {
      base.setValue('generalInfo.requestorFullName', '')
      base.setValue('generalInfo.requestorFullName1', null)
      base.setValue('generalInfo.relationshipWithPolicyOwner', null)
      setDisabledFullname(false)
      setDisabledRPO(false)
      setRelationshipList([])
    }
  }

  const getRelationshipListByLA = (clientNumberOfLA: string) => {
    setRelationshipList([])

    if (detailData?.detail?.BORelationshipList && detailData?.detail?.BORelationshipList.length > 0) {
      const relationships: InquiryComplaintConst.Group[] = []
      let clientNumInfo = detailData?.detail.BORelationshipList.find((x) => x.relationship === clientNumberOfLA)
      const relationshipFilterItem = detailData?.detail.CBrelationshipList.find(
        (p) => p.code === clientNumInfo?.clientNum
      )
      if (relationshipFilterItem) {
        relationships.push({
          code: relationshipFilterItem?.code ?? '',
          nameVi: relationshipFilterItem?.name ?? '',
          name: relationshipFilterItem?.nameEn ?? '',
          parentCode: ''
        })
        base.setValue('generalInfo.relationshipWithPolicyOwner', {
          value: relationshipFilterItem?.code ?? '',
          label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
        })
        setRelationshipList(relationships)
      }
    }
  }

  const getRelationshipListByBE = (clientNumberOfBen: string) => {
    setRelationshipList([])
    if (
      detailData?.detail?.benList &&
      detailData?.detail.benList.length > 0 &&
      detailData?.detail.BERelationshipList &&
      detailData?.detail.BERelationshipList.length > 0
    ) {
      const relationships: InquiryComplaintConst.Group[] = []
      const benItem = detailData?.detail.benList.find((x) => x.clientNumberOfBen === clientNumberOfBen)
      const relationshipFilterItem = detailData?.detail.BERelationshipList.find(
        (p) => p.code === benItem?.relationShipCode
      )
      if (relationshipFilterItem) {
        relationships.push({
          code: relationshipFilterItem?.code ?? '',
          nameVi: relationshipFilterItem?.name ?? '',
          name: relationshipFilterItem?.nameEn ?? '',
          parentCode: ''
        })
        base.setValue('generalInfo.relationshipWithPolicyOwner', {
          value: relationshipFilterItem?.code ?? '',
          label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
        })
      }
      setRelationshipList(relationships)
    }
  }
  const onChangeRequestorFullName = (e: SelectOption | null) => {
    base.clearErrors(['generalInfo.relationshipWithPolicyOwner'])
    if (!!e) {
      const requestInfoRole = base.getValues('generalInfo.requestorRole')
      base.setValue('generalInfo.relationshipWithPolicyOwner', null)
      if (requestInfoRole?.value) {
        if (requestInfoRole.value === '2') {
          getRelationshipListByLA(e?.value)
        }
        if (requestInfoRole.value === '3') {
          getRelationshipListByBE(e?.value)
        }
      }
    } else {
      setRelationshipList([])
      base.setValue('generalInfo.relationshipWithPolicyOwner', null)
    }
  }

  const onChangeMethodOrSource = () => {
    const method = base.watch('generalInfo.method')?.value ?? ''
    const source = base.watch('generalInfo.source')?.value ?? ''
    if (listMethodShowCallID.includes(method) && source === 'SO14') {
      base.setValue('generalInfo.isRequireCallId', true)
    } else {
      base.setValue('generalInfo.isRequireCallId', false)
      base.clearErrors('generalInfo.callId')
    }
  }

  const onReset = () => {
    base.reset({
      generalInfo: {
        group: null,
        type: null,
        subType: null,
        requestorRole: null,
        requestorFullName: '',
        requestorFullName1: null,
        relationshipWithPolicyOwner: null,
        requestorNationalID: '',
        method: null,
        source: null,
        firstContactResolution: false,
        hot: false,
        callId: '',
        isRequireCallId: false,
        callBackLaterHour: null,
        callbackAppointmentTime: ''
      },
      ga: {
        generalAgency: null,
        personToBeComplained: ''
      },
      salesPractice: {
        agentCode: '',
        agentName: '',
        POSignedInProposal: 'yes',
        customerReceivedPolicyKit: 'yes',
        customerReceivedDate: new Date(),
        financialNeedAnalysis: null
      },
      communicationInfo: {
        customerComments: '',
        responsesToCustomer: '',
        attachmentFiles: []
      },
      moneyMissappropriation: {
        list: [
          {
            receipt: 'no',
            receiptType: null,
            complaintAmount: '',
            receiptNumber: '',
            benefit_PremiumMisappropriatedDate: new Date()
          }
        ],
        totalComplaintAmount: '0'
      },
      conservationInfomation: {
        conservationAgentCode: '',
        conservationAgentName: '',
        conservationBonus: null,
        conservationGAStaff: false,
        conservationNote: '',
        conservationResult: null,
        conservationSolution: null,
        reasonFullSurrender: null,
        attachmentFiles: []
      },
      isSubmited: false
    })
    modalForm.setValue('policyNumber', { value: '', label: '' })
    modalForm.setValue('ownerName', '')
    setReset((prevState) => !prevState)
    setGroupCode('')
    setAgentCodeError('')
    setShowConservation(false)
  }

  const onSubmit = () => {
    if (isShowConservation) {
      if (
        base.watch('conservationInfomation.conservationResult')?.value === 'FAIL' &&
        !base.watch('conservationInfomation.conservationGAStaff')
      ) {
        base
          .trigger([
            'generalInfo',
            'conservationInfomation.conservationGAStaff',
            'conservationInfomation.conservationAgentCode',
            'conservationInfomation.conservationAgentName',
            'conservationInfomation.conservationResult',
            'conservationInfomation.conservationSolution',
            'conservationInfomation.reasonFullSurrender',
            'conservationInfomation.attachmentFiles'
          ])
          .then((isValid) => {
            if (isValid) {
              handleSubmited()
            }
          })
      } else {
        base.trigger(['generalInfo', 'conservationInfomation']).then((isValid) => {
          if (isValid && !agentCodeError) {
            handleSubmited()
          }
        })
      }
    } else {
      if (groupCode === 'CHG2') {
        base.trigger(['generalInfo', 'moneyMissappropriation', 'communicationInfo']).then((isValid) => {
          if (isValid) {
            handleSubmited()
          }
        })
      } else if (groupCode === 'CHG3') {
        base.trigger(['generalInfo', 'salesPractice', 'communicationInfo']).then((isValid) => {
          if (isValid && !agentCodeError) {
            handleSubmited()
          }
        })
        base.trigger('salesPractice')
      } else if (groupCode === 'CHG4') {
        base.trigger(['generalInfo', 'ga', 'communicationInfo']).then((isValid) => {
          if (isValid) {
            handleSubmited()
          }
        })
      } else {
        base.trigger(['generalInfo', 'communicationInfo']).then((isValid) => {
          if (isValid) {
            handleSubmited()
          }
        })
      }
    }
  }

  const onSave = () => {
    if (isShowConservation) {
      base.trigger('generalInfo').then((isValid) => {
        if (isValid && !agentCodeError) {
          handleSaveDraft()
        }
      })
    } else {
      base.trigger(['generalInfo', 'communicationInfo.customerComments']).then((isValid) => {
        if (isValid && !agentCodeError) {
          handleSaveDraft()
        }
      })
    }
  }

  const mappingData = (isSave?: boolean) => {
    const { generalInfo, communicationInfo, conservationInfomation, ga, moneyMissappropriation, salesPractice } =
      base.getValues()
    console.log(communicationInfo.attachmentFiles[0]?.file, 'attachmentFiles')
    let COMPLAINTMISMONEYDETAILS: InquiryComplaintData.COMPLAINTMISMONEYDETAILS[] = []
    let COMPLAINTSALEDETAILS: InquiryComplaintData.COMPLAINTSALEDETAILS[] = []
    let COMPLAINTGADETAILS: InquiryComplaintData.COMPLAINTGADETAILS[] = []
    let CONSERVATIONINFO: InquiryComplaintData.CONSERVATIONINFO | null = null
    const hot = generalInfo.hot ? true : false
    const fcr = generalInfo.firstContactResolution ? true : false
    const totalComplaintAmount =
      groupCode === 'CHG2'
        ? moneyMissappropriation.totalComplaintAmount
          ? PulseOpsFormat.thousandSepartorReverse(moneyMissappropriation.totalComplaintAmount)
          : 0
        : 0
    const groupItem =
      detailData?.detail?.groupList && detailData?.detail.groupList.find((x) => x.code === generalInfo.group?.value)
    const categoryName =
      groupItem?.categoryName === 'Complaint' ? 'COMPLAINT' : groupItem?.categoryName === 'Inquiry' ? 'INQUIRY' : ''
    let touchPoint =
      detailData?.detail?.sourceList &&
      detailData?.detail.sourceList.find((x) => x.code === generalInfo.source?.value)?.touchPoint
    touchPoint = !!touchPoint ? touchPoint : ''

    if (groupCode === 'CHG2') {
      COMPLAINTMISMONEYDETAILS = moneyMissappropriation.list.map((controlItem) => {
        const misPremiumDate = moment(controlItem.benefit_PremiumMisappropriatedDate).format('YYYYMMDD')
        const Moneyitem: InquiryComplaintData.COMPLAINTMISMONEYDETAILS = {
          hasReciept: controlItem.receipt,
          recieptType: _.get(controlItem, 'receiptType.value') || '',
          recieptNum: _.get(controlItem, 'receiptNumber') || '',
          complaintAmount: controlItem.complaintAmount
            ? PulseOpsFormat.thousandSepartorReverse(controlItem.complaintAmount)
            : 0,
          misPremiumDate: misPremiumDate
        }
        return Moneyitem
      })
    }

    if (groupCode === 'CHG3') {
      const isPoSignedInProposal = salesPractice.POSignedInProposal === 'yes' ? true : false
      const isCustomerReceiveKit = salesPractice.customerReceivedPolicyKit === 'yes' ? true : false
      const customerReceiveDate = moment(salesPractice.customerReceivedDate).format('YYYYMMDD')
      const saleItem: InquiryComplaintData.COMPLAINTSALEDETAILS = {
        agentCode: salesPractice.agentCode || '',
        agentName: salesPractice.agentName || '',
        financeNeedCode: salesPractice.financialNeedAnalysis?.value || '',
        isPoSignedInProposal: isPoSignedInProposal,
        isCustomerReceiveKit: isCustomerReceiveKit,
        customerReceiveDate: customerReceiveDate
      }
      COMPLAINTSALEDETAILS.push(saleItem)
    }

    if (groupCode === 'CHG4') {
      const gaItem: InquiryComplaintData.COMPLAINTGADETAILS = {
        generalAgency: ga.generalAgency?.value || '',
        personComplaint: ga.personToBeComplained
      }
      COMPLAINTGADETAILS.push(gaItem)
    }

    if (isShowConservation) {
      let mainProductName: string = ''

      if (detailData?.detail && detailData?.riders && detailData?.mainProductCode) {
        const mainRider = detailData?.riders.find((r) => r.code === detailData?.mainProductCode)
        mainProductName = mainRider ? (i18n.language === 'en' ? mainRider.name : mainRider.nameVi) : ''
      }
      CONSERVATIONINFO = {
        product: mainProductName || '',
        servicingAgentCode: detailData?.detail?.agentCode || '',
        servicingAgentName: detailData?.detail?.agentName || '',
        conservationGAStaff: conservationInfomation.conservationGAStaff ? userInfo.name : '',
        conservationAgentCode: _.get(conservationInfomation, 'conservationAgentCode') || '',
        conservationAgentName: _.get(conservationInfomation, 'conservationAgentName') || '',
        reasonFullSurrender: conservationInfomation.reasonFullSurrender?.value || '',
        conservationResult: conservationInfomation.conservationResult?.value || '',
        conservationSolution: conservationInfomation.conservationSolution?.value || '',
        conservationBonus: _.get(conservationInfomation, 'conservationBonus.value') || '',
        conservationNote: conservationInfomation.conservationNote
      }
    }

    let requestorFullName = ''

    if (generalInfo.requestorRole?.value) {
      if (generalInfo.requestorRole.value === '1' || generalInfo.requestorRole.value === '4') {
        requestorFullName = _.get(generalInfo, 'requestorFullName')
      } else {
        requestorFullName = _.get(generalInfo, 'requestorFullName1.value') || ''
      }
    }

    const conditionAddCbt = (base.watch('generalInfo.group')?.value.startsWith('CS') && !base.watch('generalInfo.firstContactResolution'))

    const submitData: InquiryComplaintData.SubmitData = {
      CATEGORY_CODE: categoryName,
      GROUP_CODE: generalInfo.group?.value || '',
      SUB_SVC_TYPE_CODE: generalInfo.type?.value || '',
      SUB_TRANS_TYPE_CODE: generalInfo.subType?.value || '',
      REQUEST_WITH_ROLE: generalInfo.requestorRole?.value || '',
      REQUESTER_FULL_NAME: requestorFullName,
      REQUESTER_NATIONAL_ID: generalInfo.requestorNationalID,
      RLA_WITH_PO: _.get(generalInfo, 'relationshipWithPolicyOwner.value') || '',
      METHOD: generalInfo.method?.value || '',
      SOURCE: generalInfo.source?.value || '',
      TOUCH_POINT: touchPoint,
      HOT: hot,
      FCR: fcr,
      CUSTOMER_CMT: communicationInfo.customerComments || '',
      RES_TO_CUSTOMER: communicationInfo.responsesToCustomer || '',
      TOTAL_MIS_MONEY_AMT: totalComplaintAmount,
      COMPLAINT_MIS_MONEY_DETAILS: COMPLAINTMISMONEYDETAILS,
      COMPLAINT_SALE_DETAILS: COMPLAINTSALEDETAILS,
      COMPLAINT_GA_DETAILS: COMPLAINTGADETAILS,
      CONSERVATION_INFO: CONSERVATIONINFO ?? {},
      CALL_ID: base.watch('generalInfo.source')?.value === 'SO14' ? generalInfo?.callId : '',
      CALL_BACK_LATER_HOUR: conditionAddCbt && generalInfo.callBackLaterHour ? Number(generalInfo.callBackLaterHour?.value) : undefined,
      CALLBACK_APPOINTMENT_TIME: conditionAddCbt && generalInfo.callbackAppointmentTime ? moment(generalInfo.callbackAppointmentTime, 'DD/MM/YYYY HH:mm:ss').toISOString() : undefined,
      POLICY_OWNER_NAME: modalForm.watch().ownerName
    }

    const saveDraftData: SaveDraft = {
      policyNo: modalForm.watch().policyNumber.value,
      clientNo: clientIdPO,
      payload: {
        groupCode: generalInfo.group?.value || '',
        typeCode: generalInfo.type?.value || '',
        subTypeCode: generalInfo.subType?.value || '',
        requesterRoleCode: generalInfo.requestorRole?.value || '',
        requesterFullName: requestorFullName,
        relationshipWithPO: _.get(generalInfo, 'relationshipWithPolicyOwner.value') || '',
        requesterNationalId: generalInfo.requestorNationalID,
        method: generalInfo.method?.value || '',
        source: generalInfo.source?.value || '',
        callId: base.watch('generalInfo.source')?.value === 'SO14' ? generalInfo?.callId : '',
        hadFirstContactResolution: fcr,
        customerComment: communicationInfo.customerComments || '',
        responseToCustomer: communicationInfo.responsesToCustomer || '',
        documentUrls: [],
        isUrgent: hot,
        poName: modalForm.watch().ownerName,
        callBackLaterHour: conditionAddCbt && generalInfo.callBackLaterHour ? Number(generalInfo.callBackLaterHour?.value) : undefined,
        callbackAppointmentTime: conditionAddCbt && generalInfo.callbackAppointmentTime ? moment(generalInfo.callbackAppointmentTime, 'DD/MM/YYYY HH:mm:ss').toISOString() : undefined
      },
      transactionType: categoryName,
      attributesExt: {
        complainMisMoneyDetails: {
          list: COMPLAINTMISMONEYDETAILS,
          totalComplaintAmount: totalComplaintAmount
        },
        complainSaleDetails: COMPLAINTSALEDETAILS[0] ?? undefined,
        complaintGADetails: COMPLAINTGADETAILS[0] ?? undefined,
        conservationInfo: CONSERVATIONINFO ?? undefined
      }
    }

    return isSave ? saveDraftData : submitData
  }

  const getAttachmentFiles = () => {
    const { generalInfo, communicationInfo, conservationInfomation } = base.getValues()
    // let files: StorageBlob.FileContent[] = []
    const groupItem =
      detailData?.detail?.groupList && detailData?.detail.groupList.find((x) => x.code === generalInfo.group?.value)
    const categoryName =
      groupItem?.categoryName === 'Complaint'
        ? TransactionType.COMPLAINT
        : groupItem?.categoryName === 'Inquiry'
        ? TransactionType.INQUIRY
        : ''
    let docTypeCode = categoryName === TransactionType.COMPLAINT ? 'DCH02' : 'DCH05'

    let attachmentFilesControl = communicationInfo.attachmentFiles
    if (isShowConservation) {
      attachmentFilesControl = conservationInfomation.attachmentFiles
      docTypeCode = 'DCS01'
    }

    let uploadedFileItem: UploadedFilesInfo = {
      uploadFiles: attachmentFilesControl as ComplainInquiryForm.File[],
      transactionType: categoryName as TransactionType,
      docTypeCode: docTypeCode,
      category: categoryCode,
      policyNumber: modalForm.watch().policyNumber.value ?? '',
      officeCode: 'CCE'
    }
    return uploadedFileItem
  }

  const handleSubmited = () => {
    const inquiryData = mappingData()
    if (!base.watch('isSubmited')) {
      base.setValue('isSubmited', true)
      showGlobalLoading(true)
      pipe(
        ZIO.effect(() => getAttachmentFiles()),
        ZIO.flatMap((attachmentFilesInfo) => {
          return pipe(
            InquiryComplaintService.submitData(
              modalForm.watch().policyNumber.value,
              inquiryData as InquiryComplaintData.SubmitData,
              [],
              'CCE',
              attachmentFilesInfo,
              SourceType.INBOUND
            ),
            ZIO.flatMap((response) => {
              showToast(t('message:MS040011'), 'success')
              base.setValue('isSubmited', false)
              onReset()
              showGlobalLoading(false)
              return ZIO.unit
            })
          )
        }),
        ZIO.catchAll((error) => {
          showToast(t('message:MS050001'), 'error')
          base.setValue('isSubmited', false)
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        // bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const handleSaveDraft = () => {
    const data = mappingData(true)
    if (!base.watch('isSubmited')) {
      base.setValue('isSubmited', true)
      showGlobalLoading(true)
      pipe(
        ZIO.effect(() => getAttachmentFiles()),
        ZIO.flatMap((attachmentFilesInfo) => {
          return pipe(
            IBService.saveDraft(data as SaveDraft, attachmentFilesInfo),
            ZIO.flatMap((response) => {
              showToast(t('message:MS100003'), 'success')
              base.setValue('isSubmited', false)
              onReset()
              showGlobalLoading(false)
              return ZIO.unit
            })
          )
        }),
        ZIO.catchAll((error) => {
          showToast(t('message:MS050001'), 'error')
          base.setValue('isSubmited', false)
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        // bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView
      style={[styles.modalCallResult, { height: height * 0.8, width: isMaximize ? width * 0.8 : width * 0.55 }]}
      showsVerticalScrollIndicator={false}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 20,
          paddingTop: 20,
          marginBottom: 10
        }}
      >
        <Text style={{ fontWeight: '700', fontSize: 16 }}>{t('Inbound:RecordingCalls').toUpperCase()}</Text>
        {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}> */}
        <Pressable onPress={() => setMaximize(!isMaximize)} style={{ marginRight: 15 }}>
          {isMaximize ? <assets.IBArrowMinimize /> : <assets.IBArrowMaximize />}
        </Pressable>
        {/* <Pressable onPress={() => setShowPopup(false)}>
            <assets.IBXMark />
          </Pressable> */}
        {/* </View> */}
      </View>
      <View style={styles.formStyle}>
        <IBGeneralField
          FieldForm={modalForm}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.SELECT,
              formName: 'policyNumber',
              title: t('common:PolicyNumber'),
              option: orderBy(
                policyLists.map((item) => ({ value: item.value, label: item.label })),
                'value',
                'desc'
              ),
              required: true,
              handleData: (value) => {
                if (value) {
                  // modalForm.setValue('ownerName', policyLists.find((x) => x.value === value?.value)?.policyOwner || '-')
                  getPOName(value.value || '')
                  handleDetailData(value.value)
                }
              }
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'ownerName',
              title: t('Inbound:OwnerName'),
              inputType: 'input',
              disabled: true
            }
          ]}
        />
        <Text style={{ fontWeight: '700', fontSize: 16, marginBottom: 10 }}>
          {t('submission:GeneralInfomation').toUpperCase()}
        </Text>
        <IBGeneralField
          FieldForm={base}
          col={3}
          typeInput={[
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.group',
              title: t('requestInfo:Group'),
              option: converOptions(groupList),
              required: true,
              handleData: (value) => {
                onGroupChange(value)
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.type',
              title: t('requestInfo:Type'),
              option: converOptions(subServiceTypeList || []),
              required: true,
              handleData: (value) => {
                onTypeChange(value)
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.subType',
              title: t('requestInfo:SubType'),
              option: converOptions(subTransactionTypeList || []),
              required: true,
              handleData: (value) => {
                onSubTypeChange(value)
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.requestorRole',
              title: t('requestInfo:RequestorRole'),
              option: requestorRoleList,
              required: true,
              handleData: (value) => {
                onChangeRequestRole(value)
              }
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'generalInfo.requestorFullName',
              title: t('requestInfo:RequestorFullname'),
              inputType: 'input',
              disabled: isDisabledFullname,
              hidden: ['2', '3'].includes(base.watch('generalInfo.requestorRole')?.value || '')
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.requestorFullName1',
              title: t('requestInfo:RequestorFullname'),
              option:
                (detailData?.detail?.laList &&
                  detailData?.detail.laList
                    // .filter((la) => la.clientNumberOfLA !== detail?.clientId)
                    .map((item) => ({
                      label: item.clientName,
                      value: item.clientNumberOfLA
                    }))) ||
                [],
              required: true,
              disabled: isDisabledFullname,
              hidden: !(base.watch('generalInfo.requestorRole')?.value === '2'),
              handleData: (value) => {
                onChangeRequestorFullName(value)
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.requestorFullName1',
              title: t('requestInfo:RequestorFullname'),
              option:
                (detailData?.detail?.benList &&
                  detailData?.detail.benList.map((item) => ({
                    label: item.clientName,
                    value: item.clientNumberOfBen
                  }))) ||
                [],
              required: true,
              disabled: isDisabledFullname,
              hidden: !(base.watch('generalInfo.requestorRole')?.value === '3'),
              handleData: (value) => {
                onChangeRequestorFullName(value)
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.relationshipWithPolicyOwner',
              title: t('requestInfo:RelationshipWithPolicyOwner'),
              option: converOptions(relationshipList),
              required: !isDisabledRPO,
              disabled: isDisabledRPO
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'generalInfo.requestorNationalID',
              title: t('requestInfo:RequestorNationalID'),
              inputType: 'input'
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.method',
              title: t('requestInfo:Method'),
              option: converOptions(detailData?.detail?.methodList || []),
              handleData: () => onChangeMethodOrSource(),
              required: true
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.source',
              title: t('requestInfo:Source'),
              option: converOptions(detailData?.detail?.sourceList || []),
              handleData: () => onChangeMethodOrSource(),
              required: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'generalInfo.callId',
              title: t('requestInfo:CallId'),
              inputType: 'number',
              maxLength: 20,
              required: base.watch('generalInfo.isRequireCallId'),
              hidden: base.watch('generalInfo.source')?.value !== 'SO14'
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'generalInfo.hot',
              title: t('requestInfo:Hot'),
              disabled: isDisabledHot
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'generalInfo.firstContactResolution',
              title: t('requestInfo:FirstContactResolution'),
              disabled: isDisabledFCR
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.callBackLaterHour',
              title: t('requestInfo:CallBackLaterHour'),
              option: listHoursCallmeBack.map((hour) => ({ value: hour, label: hour })),
              handleData: (hour) => {hour && setAppointmentTime(hour.value)},
              onClear: (value) => { base.setValue('generalInfo.callbackAppointmentTime', '')},
              hidden: !(base.watch('generalInfo.group')?.value.startsWith('CS') && !base.watch('generalInfo.firstContactResolution'))
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'generalInfo.callbackAppointmentTime',
              title: t('requestInfo:CallbackAppointmentTime'),
              inputType: 'text',
              disabled: true,
              hidden: !(base.watch('generalInfo.group')?.value.startsWith('CS') && !base.watch('generalInfo.firstContactResolution'))
            }
          ]}
        />
        {groupCode === 'CHG2' && <IBMoneyMisappropriation form={base} detail={detailData?.detail} />}
        {groupCode === 'CHG3' && (
          <IBSalesPractice
            form={base}
            detail={detailData?.detail}
            setAgentCodeErr={setAgentCodeError}
            agentCodeError={agentCodeError}
          />
        )}
        {groupCode === 'CHG4' && <IBGAOperation form={base} detail={detailData?.detail} />}
        {isShowConservation && (
          <IBConservation
            form={base}
            detail={detailData?.detail}
            mainProductCode={detailData?.mainProductCode}
            riders={detailData?.riders}
            isReset={isReset}
            userInfo={userInfo}
            setAgentCodeErr={setAgentCodeError}
            agentCodeError={agentCodeError}
          />
        )}
        {!isShowConservation && <IBCommunicationInfo form={base} detail={detailData?.detail} />}
      </View>
      <View style={styles.btnBar}>
        <IBButton onPress={() => onReset()} title={t('common:ClearAll')} backgroundFill={false} width={200} />
        <IBButton onPress={() => onSave()} title={t('common:Save')} backgroundFill width={200} />
        <IBButton onPress={onSubmit} title={t('submission:Submit')} backgroundFill={false} width={200} />
      </View>
    </ScrollView>
  )
}

export const IBClientCallingResult = ({
  setFlagModal,
  flagOpenModal
}: {
  setFlagModal: (open: boolean) => void
  flagOpenModal: boolean | undefined
}) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const { permissions } = React.useContext(ClientScreenContext)

  React.useEffect(() => {
    setFlagModal(open)
  }, [open])

  React.useEffect(() => {
    if (!flagOpenModal) {
      setOpen(false)
    }
  }, [flagOpenModal])

  const defaultValues: ComplainInquiryForm.Raw = {
    generalInfo: {
      group: null,
      type: null,
      subType: null,
      requestorRole: null,
      requestorFullName: '',
      requestorFullName1: null,
      relationshipWithPolicyOwner: null,
      requestorNationalID: '',
      method: null,
      source: null,
      firstContactResolution: false,
      hot: false,
      callId: '',
      isRequireCallId: false,
      callBackLaterHour: null,
      callbackAppointmentTime: ''
    },
    ga: {
      generalAgency: null,
      personToBeComplained: ''
    },
    salesPractice: {
      agentCode: '',
      agentName: '',
      POSignedInProposal: 'yes',
      customerReceivedPolicyKit: 'yes',
      customerReceivedDate: new Date(),
      financialNeedAnalysis: null
    },
    communicationInfo: {
      customerComments: '',
      responsesToCustomer: '',
      attachmentFiles: []
    },
    moneyMissappropriation: {
      list: [
        {
          receipt: 'no',
          receiptType: null,
          complaintAmount: '',
          receiptNumber: '',
          benefit_PremiumMisappropriatedDate: new Date()
        }
      ],
      totalComplaintAmount: '0'
    },
    conservationInfomation: {
      conservationAgentCode: '',
      conservationAgentName: '',
      conservationBonus: null,
      conservationGAStaff: false,
      conservationNote: '',
      conservationResult: null,
      conservationSolution: null,
      reasonFullSurrender: null,
      attachmentFiles: []
    },
    isSubmited: false
  }

  const { base: callingResultForm } = form2.useForm(ComplainInquiryForm.codec, {
    defaultValues: defaultValues
  })

  const policyNumCallingResultForm = useForm<IBClientCallingResultForm>({
    mode: 'onChange'
  })
  const [policyLists, setPolicyLists] = React.useState<(SelectOption & { policyOwner: string })[]>([])

  return (
    <>
      {!permissions.includes(IBPolicyPermission.ViewIBComplainInquiryPopup) ? (
        <></>
      ) : (
        <View>
          <View style={[styles.fabModal, { display: !open ? 'none' : 'flex' }]}>
            <IBModalCallResult
              form={callingResultForm}
              policyNumCallingResultForm={policyNumCallingResultForm}
              setPolicyLists={setPolicyLists}
              policyLists={policyLists}
              showPopup={open}
              setShowPopup={setOpen}
            />
          </View>
          <Pressable
            style={[
              styles.CircleFab,
              {
                backgroundColor: open ? '#FFFFFF' : '#ED1B2E',
                opacity: flagOpenModal ? 0 : 1
                // bottom: open ? -18 : -10
              }
            ]}
            onPress={() => {
              setOpen(!open)
            }}
            disabled={!permissions.includes(IBPolicyPermission.EditIBComplainInquiryPopup) || flagOpenModal}
          >
            {open ? <assets.IBMultiTasksXIcon /> : <assets.IBArrowUpRightFromSquare />}
          </Pressable>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: 0
  },
  fabModal: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: -10
  },
  modalCallResult: {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 40,
    elevation: 40,
    shadowOpacity: 1
  },
  formStyle: {
    flex: 1,
    paddingHorizontal: 20
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 50,
    justifyContent: 'space-between'
  },
  CircleFab: {
    borderColor: '#ED1B2E',
    padding: 15,
    borderRadius: 40,
    borderWidth: 1
  }
})
