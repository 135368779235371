export type PageResult<A> = {
  items: A[]
  total: number
  pageIndex: number
  pageSize: number
}
export const PageResult = <T>(result: PageResult<T>): PageResult<T> => result

export type PageParameter = {
  pageIndex: number
  pageSize: number
}
