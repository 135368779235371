import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  IBService,
  InvestmentDataInfo,
  InvestmentFund,
  PartialWithdrawInfo,
  IBGeneralTable,
  IBGridDataView
} from '@pulseops/inbound'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { isNil, debounce, isEmpty, remove } from 'lodash'
import {
  Alert,
  AppContext,
  Input,
  InputTable,
  PartialWithdrawalService,
  PulseOpsFormat,
  formatNumberWithComma,
  formatStringToDecimalNumber,
  getLanguage,
  getNumberFromString
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { Column, Error } from '@pulseops/submission/common'
interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  productCode: string
  productType: string
  status: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  basicCode?: string
  listFundT25?: InvestmentFund[]
}
export const TopupFundList: Array<{ productCode: string; fundCode: string }> = [
  { productCode: 'VL4', fundCode: 'VFD6' },
  { productCode: 'VL3', fundCode: 'VFD4' },
  { productCode: 'VL5', fundCode: 'VFE5' },
  { productCode: 'VL6', fundCode: 'VFE6' },
  { productCode: 'VL7', fundCode: 'VFE6' }
]

enum FundType {
  TargetPremium = '1',
  TopUp = '2'
}

export const OBPartialWithdrawalInfo = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { policyNumber, productCode, investmentInfo, setInvestmentInfo, productType, status, basicCode, listFundT25 } =
    props
  const [loading, bindLoading] = useLoading()
  const [withdrawalAmount, setWithdrawalAmount] = React.useState<number>(0)
  const [visibleInp, setVisibleInp] = React.useState<boolean>(false)
  const [errorWithdrawalAmount, setErrorWithdrawalAmount] = React.useState<string>('')
  const [enableWATagetPremium, setEnableWATagetPremium] = React.useState<boolean>(false)
  const [msgErrorILP, setMsgErrorILP] = React.useState<{ index: number; errorMessage: string }[]>([])
  const [msgErrorTotalWithdrawAmount, setMsgErrorTotalWithdrawAmount] = React.useState<string>('')
  const [lasErrorMsg, setLasErrorMsg] = React.useState<boolean>(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [loadingULP, bindLoadingULP] = useLoading(false)

  React.useEffect(() => {
    if (isNil(investmentInfo?.partialWithdrawInfo)) {
      pipe(
        IBService.getPartialWithdrawInfo(policyNumber, productType as string),
        ZIO.map((res) => {
          if (productType === 'ILP' && res.data?.ilpFunds) {
            debounceGetTotalFee({
              ...res.data,
              ilpFunds: res.data.ilpFunds?.map((x) => ({
                ...x,
                isRequired: isTopupFundType(x.fundCode as string),
                withdrawAmount: '0'
              }))
            })
          }
          if (res.data) {
            setInvestmentInfo({
              ...(investmentInfo as InvestmentDataInfo),
              partialWithdrawInfo: {
                ...res.data,
                minimumPartialWithdrawal:
                  productType === 'ILP' && basicCode === 'ULR6' ? 0 : res.data.minimumPartialWithdrawal,
                maximumPartialWithdrawal: res.data.maximumPartialWithdrawal,
                ulpFunds: res.data?.ulpFunds?.map((x) => {
                  const isEPA = TopupFundList.find((z) => z.fundCode === x.fundCode)
                  return {
                    ...x,
                    isEPA: !!isEPA
                  }
                }),
                ilpFunds: res.data?.ilpFunds?.map((x) => ({
                  ...x,
                  isRequired: isTopupFundType(x.fundCode as string),
                  withdrawAmount: '0'
                }))
              }
            })
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const getTotalAmountByFundType = (
    fundList: {
      fundCode?: string | null
      minRemain?: number | null
      fundValue?: number | null
    }[],
    fundType: FundType
  ) => {
    return (
      fundList
        .filter((item) => item.fundCode?.endsWith(fundType))
        // .reduce((total, item) => total + item.fundValue, 0)
        .reduce((total, item) => Number((total + (item.fundValue as number)).toFixed(2)), 0)
    )
  }

  const { totalAmountAllTargetPremiumFund, totalAmountAllTopUpFund } = React.useMemo(() => {
    const fundList = investmentInfo?.partialWithdrawInfo?.ilpFunds || []
    const totalAmountAllTopUpFund = getTotalAmountByFundType(fundList, FundType.TopUp)
    const totalAmountAllTargetPremiumFund = getTotalAmountByFundType(fundList, FundType.TargetPremium)
    return {
      totalAmountAllTopUpFund,
      totalAmountAllTargetPremiumFund
    }
  }, [investmentInfo?.partialWithdrawInfo])

  React.useEffect(() => {
    if (totalAmountAllTopUpFund === 0 && totalAmountAllTargetPremiumFund > 0) {
      setEnableWATagetPremium(true)
    } else {
      setEnableWATagetPremium(false)
    }
  }, [totalAmountAllTopUpFund, totalAmountAllTargetPremiumFund])

  React.useEffect(() => {
    checkRulePwAmount()
  }, [])

  const getMessageByCode = (code: string | undefined) => {
    let message = ''
    const A = investmentInfo?.partialWithdrawInfo?.minimumPartialWithdrawal || 0
    const B = investmentInfo?.partialWithdrawInfo?.maximumPartialWithdrawal || 0
    switch (code) {
      case 'MS050168':
        message = t('message:MS050168')
        break
      case 'MS050034':
        message = t('message:MS050034')
        break
      case 'MS050280':
        message = t('message:MS050280')
        break
      case 'MS050242':
        message = t('message:MS050242', { Min: `${formatNumberWithComma(A)} VND` })
        break
      case 'MS050243':
        message = t('message:MS050243', { Max: `${formatNumberWithComma(B)} VND` })
        break
      case 'MS050244':
        message = t('message:MS050244')
        break
      case 'MS050245':
        message = t('message:MS050245')
        break

      default:
        message = (!visibleInp ? code : '') ?? ''
    }
    return message
  }

  const validateWithdrawalAmount = (
    value: number,
    fundData: {
      actualValue: string
      estimatedValue: string
      fundCode: string
      isEPA: boolean
    }[],
    isUpdatedWithdrawAmount = false
  ) => {
    const A = investmentInfo?.partialWithdrawInfo?.minimumPartialWithdrawal || 0
    const B = investmentInfo?.partialWithdrawInfo?.maximumPartialWithdrawal || 0
    const C = investmentInfo?.partialWithdrawInfo?.estimatedValue || 0
    const P = value
    if (P) {
      if (P < A) {
        setErrorWithdrawalAmount('MS050242')
        return
      } else if (P > B) {
        setErrorWithdrawalAmount('MS050243')
        return
      }
      // else if (P > C) {
      //   setErrorWithdrawalAmount('MS050244')
      //   return
      // } else if (P === C) {
      //   Alert.alert(t('message:MS050246'))
      //   setErrorWithdrawalAmount('MS050245')
      //   return
      // }
      else {
        debounceWithFrawalAmountFn(value, fundData, B)
        setErrorWithdrawalAmount('')
        return
      }
    }
  }

  const checkRulePwAmount = () => {
    let flag = false
    if (productType === 'ULP' || productType === 'TRAD') {
      if (productCode === 'IPD1') {
        flag = true
        setErrorWithdrawalAmount('MS050280')
      } else if (!productCode.startsWith('V') && !productCode.startsWith('U')) {
        flag = true
        setErrorWithdrawalAmount('MS050168')
      } else if (status !== 'IF') {
        flag = true
        setErrorWithdrawalAmount('MS050034')
      }
    }
    setVisibleInp(flag)
    return flag
  }

  const setNewSumAssuredValidation = (newSumAssured: number) => {
    return pipe(
      PartialWithdrawalService.getNewSumAssuredValidation(policyNumber, newSumAssured),
      ZIO.map((newSAMData) => {
        if (!!newSAMData) {
          !newSAMData.isValid &&
            showToast(t('message:MS050056', { min: `${formatNumberWithComma(newSAMData.minSA)} VND` }), 'error')
        }
      })
    )
  }

  const calcWithDrawalAmount = (
    amount: number,
    fundList: Array<{
      isEPA: boolean
      actualValue: string
      estimatedValue: string
      fundCode: string
    }>,
    maximumPartialWithdrawal: number
  ) => {
    pipe(
      ZIO.effect(() => {
        let topupFundAmount = 0,
          targetFundAmount = 0

        let fundEPA = '',
          fundTPA = ''

        if (fundList.length > 0) {
          fundEPA = fundList.find((x) => x.isEPA)?.fundCode ?? ''
          fundTPA = fundList.find((x) => !x.isEPA)?.fundCode ?? ''
          topupFundAmount = Number(fundList.find((x) => x.isEPA)?.estimatedValue ?? 0)
          targetFundAmount = Number(fundList.find((x) => !x.isEPA)?.estimatedValue ?? 0)
        }
        return {
          fundEPA,
          fundTPA,
          topupFundAmount,
          targetFundAmount
        }
      }),
      ZIO.flatMap((res) => {
        let detailsPayload: Array<{ coverage: string; fundCode: string; amount: number; percent: number }> = []
        if (amount > res.topupFundAmount) {
          !!res.fundEPA &&
            detailsPayload.push({ coverage: '01', fundCode: res.fundEPA, amount: res.topupFundAmount, percent: 0 })
          !!res.fundTPA &&
            detailsPayload.push({
              coverage: '01',
              fundCode: res.fundTPA,
              amount: amount - res.topupFundAmount,
              percent: 0
            })
        } else {
          detailsPayload = [
            {
              coverage: '01',
              fundCode: res.fundEPA,
              amount: amount,
              percent: 0
            }
          ]
        }

        return IBService.postPartialWithdrawInfo({
          policyNo: policyNumber,
          productType: productType,
          details: detailsPayload
        })
      }),
      ZIO.map((res) => {
        if (res.data) {
          setInvestmentInfo({
            ...(investmentInfo as InvestmentDataInfo),
            partialWithdrawInfo: {
              ...res.data,
              ulpFunds: res.data?.ulpFunds?.map((x) => {
                const isEPA = TopupFundList.find((z) => z.fundCode === x.fundCode)
                return {
                  ...x,
                  isEPA: !!isEPA
                }
              }),
              maximumPartialWithdrawal: maximumPartialWithdrawal,
              newSumAssured: res.data?.newSumAssured,
              withDrawFee: res.data?.withDrawFee
            }
          })
        }
        return { newSumAssured: res.data?.newSumAssured, partialWithdrawFee: res.data?.withDrawFee }
      }),
      ZIO.flatMap((newSumData) => {
        return setNewSumAssuredValidation(newSumData.newSumAssured as number)
      }),
      bindLoadingULP,
      ZIO.unsafeRun({})
    )
  }

  const debounceWithFrawalAmountFn = React.useCallback(debounce(calcWithDrawalAmount, 1000), [])

  const dataGridViewULP = [
    {
      label: t('EstimatedValue'),
      value: PulseOpsFormat.thousandSepartor(investmentInfo?.partialWithdrawInfo?.estimatedValue) + ' VNĐ' || '0'
    },
    {
      label: t('MinimumWithdrawableAmount'),
      value:
        PulseOpsFormat.thousandSepartor(investmentInfo?.partialWithdrawInfo?.minimumPartialWithdrawal) + ' VNĐ' || '0'
    },
    {
      label: t('MaximumWithdrawableAmountPW'),
      value:
        PulseOpsFormat.thousandSepartor(investmentInfo?.partialWithdrawInfo?.maximumPartialWithdrawal) + ' VNĐ' || '0'
    },
    {
      label: t('CurrentSumAssured'),
      value: PulseOpsFormat.thousandSepartor(investmentInfo?.partialWithdrawInfo?.sumAssured) + ' VNĐ' || '0'
    },
    {
      label: t('NewSumAssured'),
      value: PulseOpsFormat.thousandSepartor(investmentInfo?.partialWithdrawInfo?.newSumAssured) + ' VNĐ' || '0'
    },
    {
      label: t('WithdrawalFee'),
      value: PulseOpsFormat.thousandSepartor(investmentInfo?.partialWithdrawInfo?.withDrawFee) + ' VNĐ' || '0'
    },
    {
      label: t('WithdrawalAmount'),
      value: (
        <Input
          value={PulseOpsFormat.thousandSepartor(withdrawalAmount) || ''}
          inputType="money"
          disabled={visibleInp ? true : false}
          inputStyle={{ color: '#ED1B2E', fontSize: 18, fontWeight: 'bold' }}
          errorMessage={getMessageByCode(errorWithdrawalAmount)}
          onChange={(val) => {
            const withdrawVal = Number(val || 0)
            const fundData = investmentInfo?.partialWithdrawInfo?.ulpFunds
              ? investmentInfo?.partialWithdrawInfo?.ulpFunds.map((item) => ({
                  actualValue: item.actualValue?.toString() ?? '',
                  estimatedValue: item.estimatedValue?.toString() ?? '',
                  fundCode: item.fundCode ?? '',
                  isEPA: !!item.isEPA
                }))
              : []
            setWithdrawalAmount(parseInt(val))
            validateWithdrawalAmount(withdrawVal, fundData)
          }}
        />
      )
    }
  ]

  const dataGridViewILP = [
    {
      label: t('EstimatedValue'),
      value:
        formatStringToDecimalNumber(((investmentInfo?.partialWithdrawInfo?.estimatedValue as number) || 0).toFixed(2)) +
          ' VNĐ' || '0'
    },
    {
      label: t('MinimumWithdrawableAmount'),
      value:
        formatStringToDecimalNumber(
          ((investmentInfo?.partialWithdrawInfo?.minimumPartialWithdrawal as number) || 0).toFixed(2)
        ) + ' VNĐ' || '0'
    },
    {
      label: t('MaximumWithdrawableAmountPW'),
      value:
        formatStringToDecimalNumber(
          ((investmentInfo?.partialWithdrawInfo?.maximumPartialWithdrawal as number) || 0).toFixed(2)
        ) + ' VNĐ' || '0'
    },
    {
      label: t('WithdrawalFee'),
      value:
        formatStringToDecimalNumber(((investmentInfo?.partialWithdrawInfo?.withDrawFee as number) || 0).toFixed(2)) +
          ' VNĐ' || '0'
    }
  ]

  React.useEffect(() => {
    const fundList = investmentInfo?.partialWithdrawInfo?.ilpFunds
    if (!isEmpty(fundList) && !isNil(fundList)) {
      setInvestmentInfo({
        ...(investmentInfo as InvestmentDataInfo),
        partialWithdrawInfo: {
          ...(investmentInfo?.partialWithdrawInfo as PartialWithdrawInfo),
          ilpFunds: fundList.map((x) => {
            if (isTargetPremiumFundType(x.fundCode as string))
              return {
                ...x,
                withdrawAmount: '0',
                isRequired: enableWATagetPremium
              }
            return { ...x }
          })
        }
      })
    }
  }, [enableWATagetPremium])

  const isTargetPremiumFundType = (fundCode: string) => {
    return fundCode.endsWith(FundType.TargetPremium)
  }

  const isTopupFundType = (fundCode: string) => {
    return fundCode.endsWith(FundType.TopUp)
  }

  const validateValueFor_ULR1 = (index: number, E: number, D: number, minPWRemain: number) => {
    if (E > D) {
      return t('message:MS050248')
    } else if (D - E < minPWRemain) {
      return t('message:MS050247', { min: minPWRemain })
    }
    return ''
  }

  const validateValueFor_ULR4_URL5 = (index: number, E: number, D: number) => {
    if (E > D) {
      return t('message:MS050248')
    }
    return ''
  }

  const isFillAllWithdrawalAmountTopUp = () => {
    const fundList = investmentInfo?.partialWithdrawInfo?.ilpFunds || []
    const isFillAll =
      fundList
        .filter((_, index) => isTopupFundType(fundList[index].fundCode as string))
        .find((item) => !item.withdrawAmount) === undefined
    return isFillAll
  }

  const validateValueFor_ULR6 = (
    index: number,
    E: number,
    D: number,
    minPWRemain: number,
    fundList: {
      fundCode: string | null | undefined
      minRemain: number | null | undefined
      fundValue: number | null | undefined
      withdrawAmount: string | null | undefined
      isRequired: boolean | null | undefined
    }[]
  ) => {
    if (E > D) {
      return t('message:MS050159')
    } else {
      const totalWATopup = fundList
        .filter((_, idx) => isTopupFundType(fundList[idx].fundCode as string))
        .reduce((total, item) => {
          // total + Number(item.withdrawAmount)
          const numberString = getNumberFromString(item.withdrawAmount ?? '')
          const amount = Number(numberString)
          const totalSum = Number((total + amount).toFixed(2))
          return totalSum
        }, 0)
      const isFillAllTopupWithDrawal = isFillAllWithdrawalAmountTopUp()
      if (
        (isFillAllTopupWithDrawal && totalWATopup === totalAmountAllTopUpFund) ||
        (totalAmountAllTopUpFund === 0 && totalAmountAllTargetPremiumFund > 0)
      ) {
        setEnableWATagetPremium(true)
        const remaining = D - E
        const isTargetPremium = isTargetPremiumFundType(fundList[index].fundCode as string)
        if (remaining < minPWRemain && isTargetPremium) {
          return t('message:MS050247', { min: minPWRemain })
        }
      } else {
        setEnableWATagetPremium(false)
      }
    }
    return ''
  }

  const setStateWithdrawalAmount = (
    value: string,
    fundCode: string,
    isDisable: boolean,
    fundList:
      | {
          fundCode: string | null | undefined
          minRemain: number | null | undefined
          fundValue: number | null | undefined
          withdrawAmount: string | null | undefined
          isRequired: boolean | null | undefined
        }[]
      | null
      | undefined,
    investmentInfo: InvestmentDataInfo
  ) => {
    setInvestmentInfo({
      ...(investmentInfo as InvestmentDataInfo),
      partialWithdrawInfo: {
        ...(investmentInfo?.partialWithdrawInfo as PartialWithdrawInfo),
        ilpFunds: fundList?.map((x) => {
          if (x.fundCode === fundCode)
            return {
              ...x,
              withdrawAmount: value,
              isRequired: !isDisable
            }
          return { ...x }
        })
      }
    })
  }

  const debounceSetWithdrawalAmount = React.useCallback(debounce(setStateWithdrawalAmount, 1000), [])

  const dataTableILP = [
    {
      label: t('Fund'),
      field: 'fundCode',
      render: (value: string, index: number) => {
        const fund = investmentInfo?.partialWithdrawInfo?.ilpFunds || []
        const withdrawAmount = fund[index]?.withdrawAmount
        const fundName = listFundT25?.find((fund) => fund.fundCode === value)
        const isShowWarning =
          isTopupFundType(fund[index]?.fundCode || '') &&
          withdrawAmount &&
          Number(withdrawAmount) !== fund[index]?.fundValue
        return (
          <View>
            <Text>
              {value} - {i18n.language === 'vi' ? fundName?.inBoundNameVN : fundName?.fundNameEN}
            </Text>
            {isShowWarning && <Error message={t('message:MS050249')} style={{ paddingEnd: 16 }} />}
          </View>
        )
      }
    },
    { label: t('MinPWRemain'), field: 'minRemain' },
    {
      label: t('FundValueILP'),
      field: 'fundValue'
    },
    {
      label: t('WithdrawalAmount'),
      field: 'fundCode',
      isRequired: true,
      disabled: true,
      render: (value: string, index: number) => {
        const fundList = investmentInfo?.partialWithdrawInfo?.ilpFunds || []
        const isTargePremium = isTargetPremiumFundType(value)
        const isDisable = (isTargePremium && !enableWATagetPremium) || lasErrorMsg
        let messageError = ''
        return (
          <>
            <InputTable
              value={PulseOpsFormat.thousandSepartor(withdrawalAmount) || ''}
              inputType="money"
              disabled={isDisable}
              containerStyle={{ marginBottom: 5 }}
              inputStyle={{ fontSize: 15, fontWeight: 'bold' }}
              isFractionFormat={true}
              errorMessage={msgErrorILP.find((x) => x.index === index)?.errorMessage}
              onChange={(val) => {
                debounceSetWithdrawalAmount(val, value, isDisable, fundList, investmentInfo as InvestmentDataInfo)
                const fundListChange = fundList.map((x) => {
                  if (x.fundCode === value)
                    return {
                      ...x,
                      withdrawAmount: val,
                      isRequired: !isDisable
                    }
                  return { ...x }
                })
                if (val) {
                  const withdrawAmount = Number(getNumberFromString(val ?? ''))
                  const fundValue = fundListChange[index]?.fundValue || 0
                  const minPWRemain = Number(fundListChange[index]?.minRemain || 0)
                  if (basicCode === 'ULR1') {
                    messageError = validateValueFor_ULR1(index, withdrawAmount, fundValue, minPWRemain)
                  } else if (basicCode === 'ULR4' || basicCode === 'ULR5') {
                    messageError = validateValueFor_ULR4_URL5(index, withdrawAmount, fundValue)
                  } else if (basicCode === 'ULR6') {
                    messageError = validateValueFor_ULR6(index, withdrawAmount, fundValue, minPWRemain, fundListChange)
                  } else {
                    messageError = ''
                  }
                } else if (fundListChange[index]?.isRequired) {
                  messageError = t('form:error_required_field')
                }
                const cloneMsgErrorILP = msgErrorILP.filter((x) => x.index !== index)
                setMsgErrorILP([
                  ...cloneMsgErrorILP,
                  {
                    index: index,
                    errorMessage: messageError
                  }
                ])
              }}
            />
          </>
        )
      }
    }
  ]

  const totalWithdrawAmount = React.useMemo(() => {
    const result = investmentInfo?.partialWithdrawInfo?.ilpFunds?.reduce((total, item) => {
      const numberString = getNumberFromString(item.withdrawAmount ?? '')
      const amount = Number(numberString)
      const totalSum = Number((total + amount).toFixed(2))
      return totalSum
    }, 0)
    return result
  }, [investmentInfo?.partialWithdrawInfo?.ilpFunds])

  React.useEffect(() => {
    validateTotalWithdrawAmount()
  }, [investmentInfo?.partialWithdrawInfo])

  React.useEffect(() => {
    validateTotalWithdrawAmount(true)
  }, [totalWithdrawAmount])

  const getTotalFee = (investmentInfo: InvestmentDataInfo | null) => {
    if (investmentInfo?.partialWithdrawInfo?.ilpFunds) {
      const fundList = investmentInfo?.partialWithdrawInfo?.ilpFunds
      const fundDetailList = fundList
        ? fundList.map((fundItem: any, index: number) => {
            const withdrawAmount = getNumberFromString(fundList[index].withdrawAmount ?? '')
            return {
              coverage: '01',
              fundCode: fundItem.fundCode,
              amount: Number(withdrawAmount),
              percent: 0
            }
          })
        : []
      // fundDetailList = fundDetailList.filter((x: any) => Number(x.amount) > 0)
      const inputData = {
        policyNo: policyNumber,
        productType: productType,
        details: fundDetailList
      }
      pipe(
        IBService.postPartialWithdrawInfo(inputData),
        ZIO.foldM(
          (error) =>
            ZIO.effect(() => {
              if (error) {
                const errorSour = error.source as Error
                const messageList = errorSour.message.split('-')
                if (messageList && messageList.length > 1) {
                  setLasErrorMsg(true)
                  const errorMsg = t('message:MS050034') + messageList[1]
                  showToast(errorMsg, 'error')
                }
                setInvestmentInfo({
                  ...(investmentInfo as InvestmentDataInfo),
                  partialWithdrawInfo: {
                    ...(investmentInfo?.partialWithdrawInfo as PartialWithdrawInfo),
                    withDrawFee: 0
                  }
                })
              }
            }),
          (data) =>
            ZIO.effect(() => {
              if (data?.code === 'H355') {
                const message = data?.message
                if (message) {
                  setLasErrorMsg(true)
                  const errorMsg = t('message:MS050034') + message
                  showToast(errorMsg, 'error')
                }
                setInvestmentInfo({
                  ...(investmentInfo as InvestmentDataInfo),
                  partialWithdrawInfo: {
                    ...(investmentInfo?.partialWithdrawInfo as PartialWithdrawInfo),
                    withDrawFee: 0
                  }
                })
              }
              if (data?.data) {
                const partialWithdrawFee = Math.floor(data.data.withDrawFee ?? 0)
                setInvestmentInfo({
                  ...(investmentInfo as InvestmentDataInfo),
                  partialWithdrawInfo: {
                    ...(investmentInfo?.partialWithdrawInfo as PartialWithdrawInfo),
                    withDrawFee: partialWithdrawFee
                  }
                })
              }
            })
        ),
        ZIO.unsafeRun({})
      )
    }
  }

  const debounceGetTotalFee = React.useCallback(debounce(getTotalFee, 1000), [])

  const validateTotalWithdrawAmount = (isTotalFee?: boolean) => {
    const detail = investmentInfo?.partialWithdrawInfo
    const P = totalWithdrawAmount as number
    const B = detail?.minimumPartialWithdrawal || 0
    const C = detail?.maximumPartialWithdrawal || 0
    const coverageCode = basicCode || ''

    let isFormValid = false
    if (['ULR1', 'ULR4', 'ULR5', 'ULR6'].includes(coverageCode)) {
      if (P < B) {
        setMsgErrorTotalWithdrawAmount(t('message:MS050242', { Min: `${formatNumberWithComma(B)} VND` }))
      } else if (P > C) {
        setMsgErrorTotalWithdrawAmount(t('message:MS050243', { Max: `${formatNumberWithComma(C)} VND` }))
      } else if (coverageCode === 'ULR6' && P === C) {
        setMsgErrorTotalWithdrawAmount(t('message:MS050245'))
      } else {
        isFormValid = true
        setMsgErrorTotalWithdrawAmount('')
      }
    } else {
      isFormValid = true
      setMsgErrorTotalWithdrawAmount('')
    }
    isFormValid && isTotalFee === true && debounceGetTotalFee(investmentInfo as InvestmentDataInfo)
    return isFormValid
  }

  return (
    <View>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : productType === 'ULP' ? (
        <View>
          <IBGridDataView data={dataGridViewULP} col={3} />
          {loadingULP ? (
            <View style={styles.fab}>
              <ActivityIndicator size="large" color="red" />
            </View>
          ) : (
            <></>
          )}
        </View>
      ) : (
        <>
          <IBGridDataView data={dataGridViewILP} col={4} />
          <View style={{ marginTop: 10 }}>
            <Text style={[styles.textStyle, { color: '#1C1D1F', marginBottom: 10 }]}>
              {t('FundList').toUpperCase()}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.textStyle}>{t('TotalWithdrawAmount')}:</Text>
              <Text style={[styles.textStyle, { color: '#ED1B2E' }]}>
                {formatStringToDecimalNumber((totalWithdrawAmount as number).toFixed(2))}
                VNĐ
              </Text>
            </View>
            <Text style={{ color: '#09A4D8', fontSize: 14, marginBottom: 10, fontWeight: '600' }}>
              {t('MsgNotePWI')}
            </Text>
            <Text style={styles.errorMsgStyle}>{msgErrorTotalWithdrawAmount}</Text>
            <IBGeneralTable
              data={
                !isNil(investmentInfo?.partialWithdrawInfo?.ilpFunds)
                  ? investmentInfo?.partialWithdrawInfo?.ilpFunds.map((item) => ({
                      ...item,
                      fundValue: formatStringToDecimalNumber((item.fundValue as number).toFixed(2)) + ' VNĐ'
                    }))
                  : []
              }
              dataTable={dataTableILP}
              autoPaging
            />
          </View>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  textStyle: {
    color: '#58647A',
    fontWeight: '700',
    fontSize: 15,
    marginRight: 5,
    marginBottom: 10
  },
  errorMsgStyle: {
    fontSize: 14,
    color: '#ED1B2E',
    fontStyle: 'italic',
    fontWeight: '400',
    marginBottom: 10
  },
  fab: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  }
})
