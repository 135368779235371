import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Evaluation } from './Adjudication'
import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'
import { ErrorHandling, GeneralService } from '@pulseops/common'
import { ZIO } from '@mxt/zio'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#F4F4F4'
  },
  tableBody: {
    backgroundColor: '#FAFAFA'
  },
  labelStyle: {
    fontWeight: 'bold',
    color: '#70777E'
  }
})

interface Props {
  route: {
    params: {
      evaluations: Evaluation[]
      claimNumber: string
    }
  }
}

type EvaluationType = {
  evaluationEN: string
  evaluationVN: string
  evaluationCode: string
}

export const EvaluationScreen = (props: Props) => {
  const classes = useStyles()
  const { evaluations, claimNumber } = props.route.params
  const { t, i18n } = useTranslation(['Adjudication', 'claim'])
  const [evaluationTypes, setEvaluationTypes] = React.useState<EvaluationType[]>([])

  const compareItemsByCreateDate = (item1: string, item2: string) => {
    const temp1 = moment(item1).valueOf()
    const temp2 = moment(item2).valueOf()
    return temp1 > temp2 ? -1 : 1
  }

  const formatDiaryDate = (createdDate: string) => {
    return createdDate ? moment(new Date(createdDate)).format('DD/MM/YYYY HH:mm') : ''
  }

  const showEvaluation = (code: string): string => {
    let evaluationName = ''
    if (evaluationTypes.length > 0) {
      evaluationTypes.forEach((evaluation) => {
        if (evaluation.evaluationCode === code) {
          evaluationName = i18n.language === 'vi' ? evaluation?.evaluationVN : evaluation?.evaluationEN
        }
      })
    }

    return evaluationName
  }

  pipe(
    GeneralService.getEvaluationData(),
    ZIO.map((evaluavationList) => {
      setEvaluationTypes(evaluavationList || [])
    }),
    ErrorHandling.runDidMount()
  )

  return (
    <View style={{ flex: 1, width: '100%', backgroundColor: 'white' }}>
      <ScrollView>
        <View style={{ paddingBottom: 60, flex: 1, marginHorizontal: 16, marginBottom: 16, marginTop: 16 }}>
          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{t('claim:ViewAll')}</Text>
          <View style={style.claimNumberSection}>
            <Text style={style.label}>{t('claim:ClaimNumber')}</Text>
            <Text style={style.value}>{claimNumber}</Text>
          </View>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.labelStyle}>{t('claim:EvaluationType')}</TableCell>
                  <TableCell className={classes.labelStyle}>{t('claim:Subject')}</TableCell>
                  <TableCell className={classes.labelStyle}>{t('claim:CreatedDate')}</TableCell>
                  <TableCell className={classes.labelStyle}>{t('claim:CreatedBy')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evaluations
                  .sort((item1: Evaluation, item2: Evaluation) =>
                    compareItemsByCreateDate(item1.createdDate, item2.createdDate)
                  )
                  .map((item: Evaluation, index: number) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>{showEvaluation(item.evaluationCode) || '-'}</TableCell>
                        <TableCell>{item.subject}</TableCell>
                        <TableCell>{formatDiaryDate(item.createdDate)}</TableCell>
                        <TableCell>{item.createdBy}</TableCell>
                      </TableRow>
                      <TableRow className={classes.tableHeader}>
                        <TableCell colSpan={4}>
                          <View style={style.labelStyle}>{t('claim:Description')}</View>
                          {item.description}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      </ScrollView>
    </View>
  )
}

const style = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  claimNumberSection: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 20
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22,
    marginLeft: 20
  },
  labelStyle: {
    fontWeight: 'bold',
    color: '#70777E',
    paddingBottom: 15
  }
})
