import { View, Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { COLORS, TextCustomI } from './change-national-id-card.const'

export const TextCustom = styled(Text)<TextCustomI>`
  color: ${(props) => props?.textColor ?? 'black'};
  font-size: ${(props) => props?.fontSize ?? '16px'};
  font-weight: ${(props) => props?.fontWeight ?? 'normal'};
  font-style: ${(props) => props?.fontStyle ?? 'normal'}; ;
`
export const CN = {
  Container: styled(View)`
    width: 100%;
    background-color: ${COLORS.WHITE};
    padding: 24px 0;
  `,

  Row: styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  `,

  Col_3: styled(View)`
    width: 100%;
    max-width: 33.3333333333%;
    padding: 0 15px;
  `,
  Col_2: styled(View)`
    width: 100%;
    max-width: 50%;
    padding: 0 15px;
  `,

  GROUP: styled(View)`
    padding: 24px 0;
  `,

  BoxUpload: styled(TouchableOpacity)`
    border: 2px solid ${COLORS.HOWKES_BLUE};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 19px;
    width: 354px;
    height: 60px;
    cursor: pointer;
  `,

  Modal: {
    GroupCitizen: styled(View)`
      display: flex;
      flex-direction: row;
      justify-content: center;
    `,

    BoxGroup: styled(View)`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 80px;
      background: ${COLORS.WHITE};
      border: 1px dashed ${COLORS.HOWKES_BLUE};
      border-radius: 8px;
      width: 280px;
      min-height: 180px;
    `
  },

  PDFArea: styled(View)`
    margin: 24px 0;
  `,

  BoxPDF: styled(View)`
    width: 280px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 18px;
  `,

  BoxPDFLeft: styled(View)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  `
}
