import { ComplainInquiryForm } from "libs/submission/src/complain-inquiry"

export interface IBDraftTransSearchForm {
  policyNumber: string
  clientNumber: string
  fromDate: Date | null
  toDate: Date | null
}

export const defaultValuesSubmitDraft: ComplainInquiryForm.Raw = {
  generalInfo: {
    group: null,
    type: null,
    subType: null,
    requestorRole: null,
    requestorFullName: '',
    requestorFullName1: null,
    relationshipWithPolicyOwner: null,
    requestorNationalID: '',
    method: null,
    source: null,
    firstContactResolution: false,
    hot: false,
    callId: '',
    isRequireCallId: false,
    callBackLaterHour: null,
    callbackAppointmentTime: ''
  },
  ga: {
    generalAgency: null,
    personToBeComplained: ''
  },
  salesPractice: {
    agentCode: '',
    agentName: '',
    POSignedInProposal: 'yes',
    customerReceivedPolicyKit: 'yes',
    customerReceivedDate: new Date(),
    financialNeedAnalysis: null
  },
  communicationInfo: {
    customerComments: '',
    responsesToCustomer: '',
    attachmentFiles: []
  },
  moneyMissappropriation: {
    list: [
      {
        receipt: 'no',
        receiptType: null,
        complaintAmount: '',
        receiptNumber: '',
        benefit_PremiumMisappropriatedDate: new Date()
      }
    ],
    totalComplaintAmount: '0'
  },
  conservationInfomation: {
    conservationAgentCode: '',
    conservationAgentName: '',
    conservationBonus: null,
    conservationGAStaff: false,
    conservationNote: '',
    conservationResult: null,
    conservationSolution: null,
    reasonFullSurrender: null,
    attachmentFiles: []
  },
  isSubmited: false
}

export const resultOptions = [
  {
    code: 'SUCCESS',
    name: 'Success',
    nameVi: 'Thành công'
  },
  {
    code: 'FAIL',
    name: 'Fail',
    nameVi: 'Không thành công'
  }
]

export const bonusOptions = [
  {
    code: 'BO_01',
    name: '+/- 2%',
    nameVi: 'Cộng/trừ 2%'
  },
  {
    code: 'BO_02',
    name: 'Not +/- 2%',
    nameVi: 'Không cộng/trừ 2%'
  }
]