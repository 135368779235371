export namespace BeneficiaryDesignationData {
  export interface BeneficiaryDesignationDetail {
    idCardType: string
    idNumber: string
    issueAt: string
    relationWithOwner: string
    givenName: string
    firstName: string
    dob: string
    gender: string
    nationality: string
    telCode: string
    mobileNumber: string
    email: string
    percentage: number
    clientID: string
    occupation: string
    city: string
    district: string
    ward: string
    address: string
    foreignAddress: string
    foreignCountry: string
    nationality2: string
  }

  export interface BeneficiaryToSubmit {
    // idCardType: string
    // idNumber: string
    // issueAt: string
    // relationWithOwner: string
    // givenName: string
    // firstName: string
    // dob: string
    // gender: string
    // nationality: string
    // telCode: string
    // mobileNumber: string
    // email: string
    // percentage: number,
    // clientID: string
    //documents: BeneficiaryDocumentFile []
    percentage: number
    relationshipType: string
    customer: {
      firstName: string
      surName: string
      dob: string
      sex: string
      nationality: string
      clientId: string
      occupation: string
      externalIds: {
        SOE: string
        SOE_VALUE: string
        ISSUED_BY: string
        BC_PROVINCE_CODE: string
      }
      contactDetails: {
        EMAIL: {
          channel: string
          value: string
        }
        PHONE: {
          countryCode: string
          channel: string
          value: string
        }
      },
      addressDetails: {
        ADDRESS: {
          line1: string
          city: string
          district: string
          subDistrict: string
          countryCode: string
          location?: string
        },
        FOREIGN_ADDRESS: {
          line1: string
          countryCode: string
        }
      },
      attributesExt: {
        NATIONALITY_2: string
        nationality2Name: string
        countryName: string
        districtName: string
        wardName: string
      }
    }
  }
  export interface BeneficiaryDocumentFile {
    name: string
    url: string
  }
}
