import { assets } from '@pulseops/common'
import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Overlay } from 'react-native-elements'

type Status = {
  id: number
  name: string | JSX.Element
  icon: JSX.Element
}

const statuses = [
  {
    id: 1,
    name: 'Complete',
    icon: <assets.StatusCompleteIcon />
  },
  {
    id: 2,
    name: 'Transfer',
    icon: <assets.StatusTransferIcon />
  },
  {
    id: 3,
    name: 'Suspend',
    icon: <assets.StatusSuspendIcon />
  },
  {
    id: 4,
    name: 'Reject',
    icon: <assets.StatusRejectIcon />
  },
  {
    id: 5,
    name: 'End',
    icon: <assets.StatusEndIcon />
  },
  {
    id: 6,
    name: 'Close',
    icon: <assets.StatusCloseIcon />
  }
]

const StatusItem = ({ statuses, onSelect }: { statuses: Status[]; onSelect: () => void }) => {
  return (
    <View>
      {statuses.map((status, i) => (
        <View key={i} style={[styles.statusItem, { marginBottom: i === statuses.length - 1 ? 0 : 25 }]}>
          <TouchableOpacity
            onPress={onSelect}
            style={[styles.icon, { backgroundColor: i === statuses.length - 1 ? '#ED1B2E' : '#FFFFFF' }]}
          >
            {status.icon}
          </TouchableOpacity>
          <Text style={styles.iconText}>{status.name}</Text>
        </View>
      ))}
    </View>
  )
}

const StatusItemWide = ({ statuses, onSelect }: { statuses: Status[]; onSelect: () => void }) => {
  return (
    <>
      {statuses
        .filter((statusFilter: Status) => statusFilter.id !== statuses[statuses.length - 1].id)
        .map((status, i) => (
          <TouchableOpacity
            key={i}
            onPress={onSelect}
            style={[styles.btnActionWide, i === statuses.length - 1 && { marginRight: 0 }]}
          >
            <Text style={styles.iconTextWide}>{status.name}</Text>
          </TouchableOpacity>
        ))}
    </>
  )
}

const StatusButton = ({ onToggle }: { onToggle: () => void }) => {
  return (
    <TouchableOpacity style={[styles.icon, styles.btnAction]} onPress={onToggle}>
      <assets.StatusExpandIcon />
    </TouchableOpacity>
  )
}

export const StatusList = ({
  isWide,
  visible,
  onSelect
}: {
  isWide?: boolean
  visible: boolean
  onSelect: () => void
}) => {
  return (
    <>
      {!isWide ? (
        <>
          {!visible && <StatusButton onToggle={onSelect} />}
          <Overlay
            onRequestClose={() => onSelect()}
            isVisible={visible}
            animationType="fade"
            fullScreen
            overlayStyle={styles.overlay}
          >
            <View style={styles.btnWrapper}>
              <StatusItem statuses={statuses} onSelect={onSelect} />
            </View>
          </Overlay>
        </>
      ) : (
        <View style={styles.btnWrapperWide}>
          <StatusItemWide statuses={statuses} onSelect={onSelect} />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  overlay: { flex: 1, width: '100%', height: '100%', padding: 0, backgroundColor: 'transparent', position: 'relative' },
  btnWrapper: { position: 'absolute', bottom: 50, right: 26 },
  btnWrapperWide: {
    // width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 30,
    marginTop: 30,
    marginHorizontal: 15,
    backgroundColor: '#E5E5E5'
  },
  statusItem: {
    flexDirection: 'row-reverse',
    alignItems: 'center'
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: 9999,
    justifyContent: 'center',
    alignItems: 'center'
  },
  btnAction: {
    position: 'absolute',
    bottom: 50,
    right: 26,
    zIndex: 200,
    backgroundColor: '#ED1B2E'
  },
  btnActionWide: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    marginRight: 15,
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ED1B2E',
    borderRadius: 100
  },
  iconText: { marginEnd: 15, color: '#FFFFFF', fontSize: 20, fontWeight: 'bold' },
  iconTextWide: {
    color: '#ED1B2E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 28
  }
})
