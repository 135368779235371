import * as React from 'react'
import { TaskDetail, Panel, Checkbox } from '@pulseops/common'
import { View, Text, StyleSheet } from 'react-native'
import { InfoViewList, Title } from '../common'
import { useTranslation } from 'react-i18next'

interface Props {
  detail: TaskDetail.Eservice
}

export const EServiceRegistration = ({ detail }: Props) => {
  const { t } = useTranslation('requestInfo')

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('REGISTER_FOR_ESERVICE')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('NationalID'),
              value: detail.idNumber
            },
            {
              label: t('MobilePhoneNumber'),
              value: detail.mobileNumber
            },
            {
              label: '',
              value: ''
            },
            {
              label: t('Fullname'),
              value: detail.fullName
            },
            {
              label: t('Birthday'),
              value: detail.dob
            },
            {
              label: t('Gender'),
              value: detail.gender
            }
          ]}
        />
      </Panel>
      <Text style={[styles.label]}>{t('I_WeAgreedThat')}</Text>
      <Checkbox disabled={true} value={detail.otpConsent === '1'} textStyle={styles.label} title={t('ReceiveOTP')} />
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    color: 'rgba(0,0,0,.5)',
    fontWeight: '700'
  }
})
