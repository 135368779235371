import {
  Title,
  SURedating,
  RedatingService,
  ErrorHandling,
  AppContext,
  formatNumberWithComma,
  FrequencyMapping,
  Panel,
  ReinstatementModel,
  GeneralData,
  Input,
  SelectSearch,
  assets,
  SelectOption,
  ImgUploadMutiple,
  InquiryComplaintService,
  TransactionType,
  // GeneralService,
  StorageBlob,
  SubmissionService,
  PulseOpsFormat,
  TaskType
} from '@pulseops/common'
import {
  Column,
  FieldText,
  SectionCol,
  SectionContent,
  SectionRow,
  Error,
  AgentRow,
  AgentControl
} from '@pulseops/submission/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { ChooseView } from '../reinstatement/common'
import { RedatingFormData } from './redating.form'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import * as O from 'fp-ts/lib/Option'
import { Link } from '@material-ui/core'
import { RequestAuthenticateData } from '../../request-authen'

export const RedatingScreen: React.FC<PolicyServiceProps<SURedating.RedatingInputData>> = ({
  policyNumber,
  isConfirmed,
  initSubmission,
  officeCode
}) => {
  const { t } = useTranslation()
  const policyNum = policyNumber ?? ''
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [detailData, setDetailData] = React.useState<SURedating.DetailData>({
    totalPremium: 0,
    billingFrequency: '',
    riskCommenceDate: '',
    newRCD: '',
    lapsedDate: '',
    occupationList: [],
    reinData: undefined,
    EstimatedTotalReinstatementAmount: 0,
    lapsedPeriod: 0,
    agentCode: '',
    agentName: ''
  })

  const redatingForm = useForm<RedatingFormData.Redating>({
    defaultValues: {
      agentCode: '',
      agentName: '',
      healthDeclaration: [],
      LAList: []
    }
  })
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = redatingForm
  const { fields, append } = useFieldArray({
    control: control,
    name: 'LAList'
  })
  const [occupationList, setOccupationList] = React.useState<SelectOption[]>([])
  const [isRequiredHealth, setIRequiredHealth] = React.useState<boolean>(false)

  pipe(
    RedatingService.getDetail(policyNum),
    ZIO.flatMap((detailResponse) =>
      pipe(
        SubmissionService.getAgents([detailResponse.agentCode]),
        ZIO.map((agentInfo) => {
          const agentName = agentInfo && agentInfo.length > 0 ? agentInfo[0].name : ''
          return {
            ...detailResponse,
            agentName: agentName ?? ''
          }
        })
      )
    ),
    ZIO.map((responseData) => {
      if (!!responseData) {
        const currentMoment = new Date()
        const billRequency = responseData.billingFrequency
        const lapsedDate = responseData.lapsedDate ? moment(responseData.lapsedDate).format('YYYY/MM/DD') : ''
        const lapsedPeriod = PulseOpsFormat.diffTwoDateAsMonth(currentMoment, new Date(lapsedDate))
        const estimatedTotalAmount = ['01', '02', '04'].includes(billRequency)
          ? responseData.totalPremium
          : billRequency === '12'
          ? responseData.totalPremium * 3
          : 0
        const redatingData = {
          ...responseData,
          EstimatedTotalReinstatementAmount: estimatedTotalAmount,
          lapsedPeriod: lapsedPeriod
        }
        setDetailData(redatingData)
        const carearList = responseData.occupationList.map((item) => ({ label: item.name, value: item.code }))
        setOccupationList(carearList)
        responseData.reinData &&
          responseData.reinData?.lifeAssured.forEach((lifeItem) => {
            const LAItem = getLADetail(lifeItem, responseData.occupationList)
            append(LAItem)
          })
        setValue('agentCode', responseData.agentCode)
        setValue('agentName', responseData.agentName)
        isRequiredHealthDeclaration(redatingData)
      }
    }),
    ZIO.tapError((error) => {
      console.log(`Redating detail error: ${JSON.stringify(error)}`)
      return ZIO.unit
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const formatStringToDateString = (value: string) => {
    return value ? moment(value).format('DD/MM/YYYY') : ''
  }

  const formatCurrency = (value: number | string | undefined) => {
    return !!value ? formatNumberWithComma(value) : '0'
  }

  const mappingFrequency = (value: string) => {
    const frequencyItem = FrequencyMapping.FrequencyOption.find((x) => x.value === value)
    return !!frequencyItem ? t(frequencyItem.label) : ''
  }

  const getLADetail = (LAItem: ReinstatementModel.laClient, occupationList: GeneralData[]) => {
    const curOccupationName = pipe(
      occupationList.find((x) => x.code === LAItem.attributesExt.CUR_OCCUPATION),
      O.fromNullable,
      O.map((item) => item && item.name),
      O.getOrElse(() => '')
    )
    const lifeItem: RedatingFormData.LifeAssuredInfo = {
      idNumber: LAItem.clientId,
      clientName: LAItem.name,
      hAnswer: false,
      oAnswer: false,
      aAnswer: false,
      cAnswer: false,
      curOccupation: curOccupationName,
      curOccupationID: LAItem.attributesExt.CUR_OCCUPATION
    }
    return lifeItem
  }

  const onChangeAgentCode = (value: string) => {
    if (value && value.length === 8) {
      pipe(
        InquiryComplaintService.getAgentInfo(value),
        ZIO.map((response) => {
          if (!!response && !!response.agentName.trim() && response.agentCode !== 'ERROR') {
            setValue('agentName', response.agentName)
          } else {
            showToast(t('message:MS020019'), 'error')
            setValue('agentName', '')
          }
        }),
        bindLoader,
        ErrorHandling.run()
      )
    } else {
      setValue('agentName', '')
    }
  }

  const getSubmitedRedateData = () => {
    // const redatingFormValue = redatingForm.getValues()
    let submitedData!: SURedating.RedatingSubmissionData
    const redatingData = getRedatingInputData()
    const temFiles: StorageBlob.FileContentSubmit[] = []
    submitedData = {
      uploadedFiles: temFiles,
      redatingInputedData: redatingData
    }
    return submitedData
    // return isContinue
    //   ? pipe(
    //       ZIO.effect(() => {
    //         const redatingData = getRedatingInputData()
    //         const temFiles: StorageBlob.FileContentSubmit[] = []
    //         submitedData = {
    //           uploadedFiles: temFiles,
    //           redatingInputedData: redatingData
    //         }
    //         return submitedData
    //       }),
    //       bindLoader,
    //       ZIO.unsafeRun({})
    //     )
    //   : pipe(
    //       GeneralService.getMetaData(TransactionType.REDATING, 'DPS09', officeCode ?? ''),
    //       ZIO.flatMap((responseData) => {
    //         const metaDataRaw: StorageBlob.MetaDataUpload = {
    //           type: responseData.data.transactionType,
    //           doctype: responseData.data.doctypeEn,
    //           class: responseData.data.classFilenet,
    //           docid: responseData.data.docID,
    //           maindoc: responseData.data.mainDoc,
    //           subdoc: responseData.data.subDoc,
    //           polnum: policyNum,
    //           batchno: responseData.data.batchNo
    //         }
    //         const uploadFiles: StorageBlob.FileContent[] = redatingFormValue.healthDeclaration.map((x) => {
    //           return {
    //             file: x.file,
    //             metaData: metaDataRaw
    //           }
    //         })
    //         return pipe(StorageBlob.uploadToSubmit('PS', policyNum)(uploadFiles))
    //       }),
    //       ZIO.map((uploadedFiles) => {
    //         const redatingData = getRedatingInputData()
    //         submitedData = {
    //           uploadedFiles: uploadedFiles,
    //           redatingInputedData: redatingData
    //         }
    //         return submitedData
    //       }),
    //       bindLoader,
    //       ZIO.unsafeRun({})
    //     )
  }

  const getRedatingInputData = () => {
    const redatingFormValue = redatingForm.getValues()
    const lifeAssuredList = redatingFormValue.LAList.map((LAItem) => {
      let curOccupation = '',
        newOccupation = '',
        newActivity = '',
        newCompany = ''
      if (LAItem.oAnswer) {
        curOccupation = LAItem.curOccupationID
        newOccupation = LAItem.newOccupation?.value ?? ''
      }
      if (LAItem.aAnswer) {
        newActivity = LAItem.newActivity ?? ''
      }
      if (LAItem.cAnswer) {
        newCompany = LAItem.newCompany ?? ''
      }
      const lifeAssuredItem: SURedating.LifeAssuredData = {
        clientNo: LAItem.idNumber,
        healthStateChange: LAItem.hAnswer,
        occupationChange: LAItem.oAnswer,
        curOccupation: curOccupation,
        newOccupation: newOccupation,
        activityChange: LAItem.aAnswer,
        newActivity: newActivity,
        companyChange: LAItem.cAnswer,
        newCompany: newCompany
      }
      return lifeAssuredItem
    })
    const redatingInputData: SURedating.RedatingInputData = {
      rcd: detailData.riskCommenceDate,
      newRcd: detailData.newRCD,
      frequency: detailData.billingFrequency,
      regularPremium: detailData.totalPremium,
      lapsedDate: detailData.lapsedDate,
      totalReinsAmount: detailData.EstimatedTotalReinstatementAmount ?? 0,
      agentCode: watch('agentCode'),
      agentName: watch('agentName'),
      lifeAssuredList: lifeAssuredList
    }
    return redatingInputData
  }

  const checkAnyHealth = (formValue: RedatingFormData.Redating) => {
    return formValue.LAList.some((LAItem) => LAItem.hAnswer)
  }

  const validateNextTab = () => {
    const redatingFormValue = redatingForm.getValues()
    const lapsedPeriod = detailData.lapsedPeriod ?? 0
    if (lapsedPeriod < 12 && checkAnyHealth(redatingFormValue) && redatingFormValue.healthDeclaration.length <= 0) {
      showToast(t('message:MS050232'), 'error')
      return false
    } else if (lapsedPeriod >= 12 && redatingFormValue.healthDeclaration.length <= 0) {
      showToast(t('message:MS050232'), 'error')
      return false
    }
    return true
  }

  const isRequiredHealthDeclaration = (detail: SURedating.DetailData) => {
    let flag = false
    if (!!detail && !!detail.lapsedDate) {
      const redatingFormValue = redatingForm.getValues()
      const lapsedPeriod = detail.lapsedPeriod ?? 0
      if (lapsedPeriod < 12 && checkAnyHealth(redatingFormValue)) {
        flag = true
      } else if (lapsedPeriod >= 12) {
        flag = true
      }
    }
    setIRequiredHealth(flag)
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const redatingFormValue = redatingForm.getValues()
    uploadedFileList.push({
      uploadFiles: redatingFormValue.healthDeclaration,
      transactionType: TransactionType.REDATING,
      docTypeCode: 'DPS09',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async () => {
      const isValidForm = await redatingForm.trigger()
      if (isValidForm && validateNextTab()) {
        const submitedData = getSubmitedRedateData()
        return {
          url: (policyNum: string) => `wf-api/policy/${policyNum}/redating`,
          body: submitedData.redatingInputedData,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.REDATING),
          collerationId: policyNum,
          transaction: TransactionType.REDATING,
          documents: submitedData.uploadedFiles,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      } else {
        return false
      }
    },
    clear: () => {
      watch('LAList').forEach((LAItem, index) => {
        setValue(`LAList.${index}.hAnswer`, false)
        setValue(`LAList.${index}.oAnswer`, false)
        setValue(`LAList.${index}.newOccupation`, undefined)
        setValue(`LAList.${index}.aAnswer`, false)
        setValue(`LAList.${index}.newActivity`, '')
        setValue(`LAList.${index}.cAnswer`, false)
        setValue(`LAList.${index}.newCompany`, '')
      })
      setValue('healthDeclaration', [])
      setValue('agentCode', detailData.agentCode)
      setValue('agentName', detailData.agentName ?? '')
    }
  })

  return (
    <SafeAreaView style={redatingStyles.container}>
      <Title wrapperStyle={redatingStyles.noMarginBottom} title={t('Redating:REQUESTDETAIL')}></Title>
      <SectionContent sectionStyles={redatingStyles.sectionContent}>
        <SectionRow sectionStyles={redatingStyles.sectionRow}>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:RiskCommencementDate')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatStringToDateString(detailData.riskCommenceDate ?? '')}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText
              fieldStyles={redatingStyles.field_title}
              text={t('Redating:EstimatedNewRiskCommencementDate')}
            ></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatStringToDateString(detailData.newRCD ?? '')}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('Redating:BillingFrequency')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={mappingFrequency(detailData.billingFrequency)}
            />
          </SectionCol>
        </SectionRow>

        <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondLine]}>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:InstallmentPremium')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatCurrency(detailData.totalPremium ?? 0)}
              isCurrencyUnit={true}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:LapsedDate')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatStringToDateString(detailData.lapsedDate ?? '')}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText
              fieldStyles={redatingStyles.field_title}
              text={t('Redating:EstimatedTotalReinstatementAmount')}
            ></FieldText>
            <FieldText
              fieldStyles={[redatingStyles.field_description, redatingStyles.redLine]}
              text={formatCurrency(detailData.EstimatedTotalReinstatementAmount ?? 0)}
              isCurrencyUnit={true}
            />
          </SectionCol>
        </SectionRow>
      </SectionContent>
      <Title wrapperStyle={redatingStyles.secondLine} title={t('submission:StatementOfInsurability')}></Title>
      {fields.map((fieldItem, index) => {
        return (
          <Controller
            key={`LifeAssured_${index}`}
            control={control}
            name={`LAList.${index}`}
            render={({ field: { value, onChange } }) => {
              return !value ? (
                <></>
              ) : (
                <Panel title={fieldItem.clientName} key={index} isTransactionToggleExpand={true}>
                  <SectionRow sectionStyles={redatingStyles.sectionRow}>
                    <SectionCol sectionStyles={redatingStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangesInStateOfHealth')}
                        selected={value.hAnswer}
                        disable={isConfirmed}
                        onChange={(select) => {
                          onChange({ ...value, hAnswer: select })
                          isRequiredHealthDeclaration(detailData)
                        }}
                      />
                    </SectionCol>
                  </SectionRow>
                  <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                    <SectionCol sectionStyles={redatingStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangesInOccupation')}
                        selected={value.oAnswer}
                        disable={isConfirmed}
                        onChange={(select) => onChange({ ...value, oAnswer: select })}
                      />
                    </SectionCol>
                  </SectionRow>
                  {value && value.oAnswer && (
                    <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                      <SectionCol sectionStyles={redatingStyles.col_4}>
                        <Controller
                          control={control}
                          name={`LAList.${index}.curOccupation`}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Input
                              title={t('submission:CurrentOccupation')}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={true}
                              alwayShowUnderline={true}
                            />
                          )}
                        />
                      </SectionCol>
                      <SectionCol sectionStyles={redatingStyles.col_4}>
                        <Controller
                          control={control}
                          name={`LAList.${index}.newOccupation`}
                          rules={{
                            required: {
                              value: true,
                              message: t('message:MS020001', { field: t('submission:NewOccupation') })
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <SelectSearch
                              options={occupationList}
                              value={value}
                              label={t('submission:NewOccupation')}
                              required
                              onChange={onChange}
                              disabled={isConfirmed}
                              placeholder={t('common:Select')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              errorMessage={!!value ? '' : error?.message}
                            />
                          )}
                        />
                      </SectionCol>
                    </SectionRow>
                  )}
                  <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                    <SectionCol sectionStyles={redatingStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangeInActivities')}
                        selected={value.aAnswer}
                        disable={isConfirmed}
                        onChange={(select) => onChange({ ...value, aAnswer: select })}
                      />
                    </SectionCol>
                  </SectionRow>
                  {value && value.aAnswer && (
                    <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                      <SectionCol sectionStyles={redatingStyles.col_4}>
                        <Controller
                          control={control}
                          name={`LAList.${index}.newActivity`}
                          rules={{
                            required: {
                              value: true,
                              message: t('message:MS020001', { field: t('submission:NewActivity') })
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <Input
                              required
                              title={t('submission:NewActivity')}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={isConfirmed}
                              alwayShowUnderline={true}
                              errorMessage={!!value ? '' : error?.message}
                            />
                          )}
                        />
                      </SectionCol>
                    </SectionRow>
                  )}

                  <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                    <SectionCol sectionStyles={redatingStyles.col_12}>
                      <ChooseView
                        title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                        selected={value.cAnswer}
                        disable={isConfirmed}
                        onChange={(select) => onChange({ ...value, cAnswer: select })}
                      />
                    </SectionCol>
                  </SectionRow>
                  {value && value.cAnswer && (
                    <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                      <SectionCol sectionStyles={redatingStyles.col_4}>
                        <Controller
                          control={control}
                          name={`LAList.${index}.newCompany`}
                          rules={{
                            required: {
                              value: true,
                              message: t('message:MS020001', { field: t('submission:CompanyName') })
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <Input
                              required
                              title={t('submission:CompanyName')}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={isConfirmed}
                              alwayShowUnderline={true}
                              errorMessage={!!value ? '' : error?.message}
                            />
                          )}
                        />
                      </SectionCol>
                    </SectionRow>
                  )}
                </Panel>
              )
            }}
          />
        )
      })}

      <SectionRow sectionStyles={redatingStyles.sectionRow}>
        <SectionCol sectionStyles={redatingStyles.col_12}>
          <Text style={redatingStyles.field_title}>
            {t('submission:HealthNote')}
            <Link href={`${assets.UpdatedRedatingHealthyTemplate}`} target="_parent" download>
              <Text style={redatingStyles.underlineLink}>{t('submission:Here')}</Text>
            </Link>
          </Text>
        </SectionCol>
      </SectionRow>
      <SectionRow sectionStyles={redatingStyles.sectionRow}>
        <SectionCol sectionStyles={redatingStyles.col_12}>
          <FieldText
            fieldStyles={redatingStyles.field_title}
            text={t('submission:HealthDeclaration')}
            isRequired={isRequiredHealth}
          ></FieldText>
          <Controller
            control={control}
            name="healthDeclaration"
            rules={{
              required: {
                value: isRequiredHealth,
                // value: true,
                message: t('message:MS050232')
              }
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Column>
                <ImgUploadMutiple
                  value={value}
                  onChange={onChange}
                  timeFormat={'DD/MM/YYYY HH:mm'}
                  disabled={isConfirmed}
                />
                {error?.message && isRequiredHealth && (
                  <Error message={value && value.length > 0 ? '' : error.message} />
                )}
              </Column>
            )}
          />
        </SectionCol>
      </SectionRow>
      <Title wrapperStyle={redatingStyles.secondLine} title={t('Redating:REINSTATEMENTAGENT')}></Title>
      <SectionContent sectionStyles={redatingStyles.sectionContent}>
        <SectionRow sectionStyles={redatingStyles.sectionRow}>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText
              fieldStyles={[redatingStyles.field_title, redatingStyles.noMarginBottom]}
              text={t('Redating:Agent')}
              isRequired
            ></FieldText>
            <AgentRow>
              <AgentControl
                control={control}
                nameControl={'agentCode'}
                isErrorMessage={true}
                // errorMessage={t('message:MS020001', { field: t('Redating:Agent') })}
                disabled={isConfirmed}
                maxLength={8}
                onGlobalChange={(val) => {
                  onChangeAgentCode(val)
                }}
                containerStyles={{ width: '22%', marginRight: 15 }}
              />
              <AgentControl
                control={control}
                nameControl={'agentName'}
                isErrorMessage={false}
                disabled={true}
                onGlobalChange={() => {}}
                containerStyles={{ width: '70%' }}
              />
            </AgentRow>
            {errors.agentCode && (
              <Error message={!!watch('agentCode') ? '' : t('message:MS020001', { field: t('Redating:Agent') })} />
            )}
          </SectionCol>
        </SectionRow>
      </SectionContent>
    </SafeAreaView>
  )
}

const redatingStyles = StyleSheet.create({
  container: {
    marginTop: 15
  },
  noMarginBottom: {
    marginBottom: 0
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  secondSmallLine: {
    marginTop: 10
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  redLine: {
    color: '#ed1c2e',
    fontWeight: '700'
  },
  underlineLink: {
    textDecorationLine: 'underline'
  }
})
