import { form2 } from '@pulseops/common'
import { withGreater, withMaxStringLength } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, NumberFromString, withMessage } from 'io-ts-types'
import _ from 'lodash'
export namespace ComplainInquiryForm {
  export const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  export type FileListBrand = {
    readonly FileList: unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  export const withFileRequired = () =>
    pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l.length > 0,
        (l) => i18next.t('error_required_field', { ns: 'form' }),
        'FileList'
      )
    )

  const GeneralInfomation = t.intersection([
    t.type({
      group: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Group') })
      ),
      type: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Type') })
      ),
      subType: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:SubType') })
      ),
      requestorRole: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:RequestorRole') })
      ),
      requestorNationalID: t.string,
      method: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Method') })
      ),
      source: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Source') })
      ),
      hot: t.boolean,
      firstContactResolution: t.boolean,
      isRequireCallId: t.boolean,
      callId: t.string,
      callBackLaterHour: form2.selectOption.optional,
      callbackAppointmentTime: t.string
    }),
    t.union([
      t.type({
        requestorRole: form2.selectOption.value('1'),
        requestorFullName: pipe(
          withMessage(NonEmptyString, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:RequestorFullname') })
          ),
          withMaxStringLength(120)
        )
      }),
      t.type({
        requestorRole: form2.selectOption.value('2', '3'),
        requestorFullName1: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020009', { field: i18next.t('requestInfo:RequestorFullname') })
        ),
        relationshipWithPolicyOwner: form2.selectOption.optional
      }),
      t.type({
        requestorRole: form2.selectOption.value('4'),
        requestorFullName: pipe(
          withMessage(NonEmptyString, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:RequestorFullname') })
          ),
          withMaxStringLength(120)
        ),
        relationshipWithPolicyOwner: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020009', { field: i18next.t('requestInfo:RelationshipWithPolicyOwner') })
        )
      })
    ]),
    t.union([
      t.type({
        isRequireCallId: t.literal(true),
        callId: withMessage(NonEmptyString, () => i18next.t('message:IB0011'))
      }),
      t.type({
        isRequireCallId: t.literal(false),
        callId: form2.string.optional
      })
    ])
   
  ])

  export type GeneralInfomation = t.TypeOf<typeof GeneralInfomation>

  const ComunicationInfo = t.type({
    customerComments: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:CustomerComments') })
      ),
      withMaxStringLength(1000)
    ),
    responsesToCustomer: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:ResponseToCustomer') })
      ),
      withMaxStringLength(1000)
    ),
    attachmentFiles: t.array(File)
  })

  export type ComunicationInfo = t.TypeOf<typeof ComunicationInfo>

  const GaOperation = t.type({
    generalAgency: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020009', { field: i18next.t('requestInfo:GeneralAgency') })
    ),
    personToBeComplained: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:PersonToBeComplainted') })
      ),
      withMaxStringLength(120)
    )
  })

  export type GaOperation = t.TypeOf<typeof GaOperation>

  const SalesPracetice = t.type({
    agentCode: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:AgentCode') })
      ),
      form2.string.minLength(8, () => i18next.t('message:MS100005'))
    ),
    agentName: t.string,
    financialNeedAnalysis: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020009', { field: i18next.t('requestInfo:FinancialNeedAnalysis') })
    ),
    POSignedInProposal: withMessage(t.string, () =>
      i18next.t('message:MS020009', { field: i18next.t('requestInfo:POSignedInProposal') })
    ),
    customerReceivedPolicyKit: withMessage(t.string, () =>
      i18next.t('message:MS020009', { field: i18next.t('requestInfo:CustomerReceivedPolicyKit/e-kit') })
    ),
    customerReceivedDate: withMessage(form2.date.required, () =>
      i18next.t('message:MS020009', { field: i18next.t('requestInfo:CustomerReceivedDate') })
    )
  })

  export type SalesPracetice = t.TypeOf<typeof SalesPracetice>

  const MoneyMisappropriationItem = t.intersection([
    t.type({
      complaintAmount: pipe(
        withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('requestInfo:ComplaintAmount') })
        ),
        withGreater(0)
      ),
      benefit_PremiumMisappropriatedDate: withMessage(form2.date.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Benefit/PremiumMisappropriatedDate') })
      ),
      // receiptType: withMessage(form2.selectOption.required, () =>
      //   i18next.t('message:MS020001', { field: i18next.t('requestInfo:ReceiptType') })
      // )
      receipt: t.string
    }),
    t.union([
      t.type({
        receipt: t.literal('yes', undefined),
        receiptType: withMessage(form2.selectOption.value('RC01', 'RC02', 'RC03', 'RC04'), () =>
          i18next.t('message:MS020009', { field: i18next.t('requestInfo:ReceiptType') })
        ),
        // receiptType: t.literal('RC01', 'RC02') || t.literal('RC03', 'RC04') || t.literal(''),
        receiptNumber: withMessage(form2.string.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('requestInfo:ReceiptNumber') })
        )
      }),
      t.type({
        receipt: t.literal('yes', undefined),
        receiptType: withMessage(form2.selectOption.value('RC05', 'RC06'), () =>
          i18next.t('message:MS020009', { field: i18next.t('requestInfo:ReceiptType') })
        ),
        receiptNumber: form2.string.optional
      }),
      // t.type({
      //   receipt: t.literal('no'),
      //   receiptType: form2.selectOption.value('RC01', 'RC02', 'RC03', 'RC04'),
      //   // receiptType: t.literal('RC01', 'RC02') || t.literal('RC03', 'RC04') || t.literal(''),
      //   receiptNumber: withMessage(form2.string.required, () =>
      //     i18next.t('message:MS020001', { field: i18next.t('requestInfo:ReceiptNumber') })
      //   )
      // }),
      // t.type({
      //   receipt: t.literal('no'),
      //   receiptType: form2.selectOption.value('RC05', 'RC06'),
      //   receiptNumber: form2.string.optional
      // })
      t.type({
        receipt: t.literal('no'),
        receiptType: form2.selectOption.optional,
        receiptNumber: form2.string.optional
      })
    ])
  ])

  export type MoneyMisappropriationItem = t.TypeOf<typeof MoneyMisappropriationItem>

  type MoneyMisappropriationReceiptBrand = {
    readonly MoneyMisappropriationReceipt: unique symbol
  }

  export type MoneyMisappropriationReceipt = t.Branded<MoneyMisappropriationItem, MoneyMisappropriationReceiptBrand>

  type MoneyMisappropriationListBrand = {
    readonly MoneyMisappropriationList: unique symbol
  }
  export type MoneyMisappropriationList = t.Branded<MoneyMisappropriationItem[], MoneyMisappropriationListBrand>

  const MoneyMisappropriationList = pipe(
    t.array(MoneyMisappropriationItem),
    form2.refine(
      (l): l is MoneyMisappropriationList => l.length >= 1,
      (l) => i18next.t('error_list_min_length', { min: '1', ns: 'form' }),
      'MoneyMisappropriationList'
    )
  )

  const MoneyMisappropriation = t.type({
    list: MoneyMisappropriationList,
    totalComplaintAmount: NumberFromString
  })

  export type MoneyMisappropriation = t.TypeOf<typeof MoneyMisappropriation>

  const Conservation = t.intersection([
    t.type({
      reasonFullSurrender: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:ReasonForFullSurrender') })
      ),
      conservationResult: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Result') })
      ),
      conservationSolution: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020009', { field: i18next.t('requestInfo:Solution') })
      ),
      conservationNote: pipe(t.string, withMaxStringLength(1000)),
      attachmentFiles: withFileRequired()
    }),
    t.union([
      t.type({
        conservationGAStaff: t.literal(true)
      }),
      t.type({
        conservationGAStaff: t.literal(false),
        conservationAgentCode: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:ConservationAgent') })
          ),
          form2.string.minLength(8, () => i18next.t('message:MS100005'))
        ),
        conservationAgentName: pipe(t.string, withMaxStringLength(120)),
        conservationBonus: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020009', { field: i18next.t('requestInfo:Bonus') })
        )
      })
    ])
  ])

  export type Conservation = t.TypeOf<typeof Conservation>

  export const codec = t.type({
    generalInfo: GeneralInfomation,
    communicationInfo: ComunicationInfo,
    ga: GaOperation,
    salesPractice: SalesPracetice,
    moneyMissappropriation: MoneyMisappropriation,
    conservationInfomation: Conservation,
    isSubmited: t.boolean
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
