import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { CashOut } from './CashOutOption'

export namespace PartialWithdrawal {
  export enum Fund {
    EPA = 'EPA',
    TPA = 'TPA'
  }

  export enum V {
    R1 = 'VR1',
    S1 = 'VS1',
    L2 = 'VL2',
    L3 = 'VL3',
    L4 = 'VL4',
    L5 = 'VL5',
    L6 = 'VL6',
    L7 = 'VL7',
    LR6 = 'VLR6',
    LR7 = 'VLR7',
    LR8 = 'VLR8'
  }

  export enum U {
    R2 = 'UR2',
    R4 = 'UR4',
    R5 = 'UR5',
    R6 = 'UR6',
    LR2 = 'ULR2',
    LR4 = 'ULR4',
    LR5 = 'ULR5',
    LR6 = 'ULR6'
  }

  export const TopupFundList: Array<{ productCode: string; fundCode: string }> = [
    { productCode: 'VL4', fundCode: 'VFD6' },
    { productCode: 'VL3', fundCode: 'VFD4' },
    { productCode: 'VL5', fundCode: 'VFE5' },
    { productCode: 'VL6', fundCode: 'VFE6' },
    { productCode: 'VL7', fundCode: 'VFE6' }
  ]

  export const TargetFundList: Array<{ productCode: string; fundCode: string }> = [
    { productCode: 'VR1', fundCode: 'VFD1' },
    { productCode: 'VS1', fundCode: 'VFD1' },
    { productCode: 'VL2', fundCode: 'VFD2' },
    { productCode: 'VL4', fundCode: 'VFD5' },
    { productCode: 'VL3', fundCode: 'VFD3' },
    { productCode: 'VL5', fundCode: 'VFT5' },
    { productCode: 'VL6', fundCode: 'VFT6' },
    { productCode: 'VL7', fundCode: 'VFT6' }
  ]

  export interface fundULPList {
    isEPA: boolean
    actualValue: string
    estimatedValue: string
    fundCode: string
  }

  export interface FundData {
    fundName: string
    fundCode: string
    minPWRemain: number
    fundValue: number
    withdrawalAmount: number
    errorMessage: string
    disabled: boolean
  }

  export interface UlpFormValue {
    estimatedValue: number
    minimumPartialWithdrawal: number
    maximumPartialWithdrawal: number
    currentSumAssured: number
    newSumAssured: number
    partialWithdrawFee: number
    withdrawAmount: number
  }

  interface UlpRes extends UlpFormValue {
    existInT5538: boolean
    minSAM: number
    minSA: number
    maxSA: number
  }

  export interface IlpFormValue {
    estimatedValue: number
    minimumPartialWithdrawal: number
    maximumPartialWithdrawal: number
    withdrawFee: number
    totalWithdrawAmount: number
    fundList: FundData[]
  }
  export interface Detail {
    policyNum: string
    productCode: string
    coverageCode: string
    instalmentPremium: number
    frequency: number
    premiumStatus: string
    ilp?: IlpFormValue
    ulp?: UlpRes
  }

  type SubmitCommon = {
    TOTAL_PAYOUT_AMOUNT: number
    ESTIMATED_VALUE: number
    MINIMUM_PARTIAL_WITHDRAWAL: number
    MAXIMUM_PARTIAL_WITHDRAWAL: number
    WITHDRAW_FEE: number
  }

  type SubmitIlp = SubmitCommon & {
    TOTAL_WITHDRAW_AMOUNT: number
    FUND_LIST: {
      FUND_CODE: string
      FUND_VALUE: number
      'MIN_P/W_REMAIN': number
      WITHDRAW_AMOUNT: number
    }[]
  }

  type SubmitUlp = SubmitCommon & {
    CURRENT_SUM_ASSURED: number
    NEW_SUM_ASSURED: number
    WITHDRAW_AMOUNT: number
  }

  export type AttributesExt = SubmitUlp | SubmitIlp

  export interface SubmitData {
    policy: {
      policyNo: string
    }
    attributesExt: AttributesExt
    cashOutOptions: Array<CashOut.Option>
  }

  export interface PWUlpEnquiryReq {
    contractNumber: string
    details?: {
      // fund: string
      // coRD?: string
      // actualValue?: number
      percent?: number
      coverage?: string
      fundCode: string
      amount?: number
    }[]
  }

  export const PWUlpEnquiryRes = t.type({
    body: t.type({
      estimateTotal: t.string,
      maxWithdraw: t.string,
      minWithdraw: t.string,
      newSumAssured: t.string,
      sumAssured: t.string,
      totalFee: t.string,
      coverageRiderDetails: t.array(
        t.type({
          actualValue: t.string,
          estimatedValue: t.string,
          fundCode: t.string
        })
      )
    })
  })
  export type PWUlpEnquiryRes = t.TypeOf<typeof PWUlpEnquiryRes>

  export interface PWIlpEnquiryReq {
    contractNumber: string
    details?: {
      // fund: string
      // coRD?: string
      // actualValue?: number
      // percentage?: number
      percent?: number
      coverage?: string
      fundCode: string
      amount?: number
    }[]
  }

  export const PWIlpEnquiryRes = t.type({
    body: t.type({
      status: t.string,
      maxWithdraw: t.string,
      minWithdraw: t.string,
      estimateTotal: t.string,
      totalFee: t.string,
      coverageRiderDetails: t.array(
        t.type({
          minRemain: t.string,
          estimatedValue: t.string,
          fundCode: t.string
        })
      )
    })
  })

  export const PWIlpEnquiryResError = t.type({
    body: t.type({
      status: t.string,
      maxWithdraw: Maybe(t.string),
      minWithdraw: Maybe(t.string),
      estimateTotal: Maybe(t.string),
      totalFee: Maybe(t.string),
      coverageRiderDetails: Maybe(
        t.array(
          t.type({
            minRemain: t.string,
            estimatedValue: t.string,
            fundCode: t.string
          })
        )
      )
    }),
    responseStatus: t.type({
      code: Maybe(t.number),
      message: Maybe(t.string)
    })
  })
  export const getFundAll = t.type({
    fundCode: t.string,
    fundNameEN: t.string,
    fundNameVN: t.string,
    active: t.boolean
  })

  export type PWIlpEnquiryRes = t.TypeOf<typeof PWIlpEnquiryRes>

  export const T5538 = t.type({
    body: t.array(
      t.type({
        id: t.string,
        code: t.string,
        name: t.string
      })
    )
  })
  export type T5538 = t.TypeOf<typeof T5538>

  export const TU538 = t.type({
    body: t.array(
      t.type({
        id: t.string,
        min_1: t.string,
        min_2: t.string,
        min_3: t.string,
        min_4: t.string,
        min_5: t.string,
        min_6: t.string,
        min_7: t.string,
        min_8: t.string,
        min_9: t.string,
        min_10: t.string,
        max_1: t.string,
        max_2: t.string,
        max_3: t.string,
        max_4: t.string,
        max_5: t.string,
        max_6: t.string,
        max_7: t.string,
        max_8: t.string,
        max_9: t.string,
        max_10: t.string,
        age_1: t.string,
        age_2: t.string,
        age_3: t.string,
        age_4: t.string,
        age_5: t.string,
        age_6: t.string,
        age_7: t.string,
        age_8: t.string,
        age_9: t.string,
        age_10: t.string
      })
    )
  })
  export type TU538 = t.TypeOf<typeof TU538>

  export type KeyTU538 = keyof Omit<TU538['body'][number], 'id'>
}
