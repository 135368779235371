import * as React from 'react'

import { withStyles } from '@material-ui/core'
import * as core from '@material-ui/core'
import { ControlProps } from '@pulseops/common'

export type InputProps = ControlProps<string | null> & {
  label?: string
  value?: string | null
  type?: string
  placeholder?: string
  maxLength?: number
  disabled?: boolean
}

export const BorderInput = (props: InputProps) => {
  return (
    <core.FormControl error={!!props.errorMessage}>
      <SC.TextField
        label={props.label}
        placeholder={props.placeholder || 'Nhập...'}
        variant="outlined"
        value={props.value}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value as string)
        }}
        type={props.type || 'text'}
        inputProps={{
          ...(props.maxLength != null ? { maxLength: props.maxLength } : {})
        }}
        disabled={props.disabled}
      />
      {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
    </core.FormControl>
  )
}

const SC = {
  TextField: withStyles({
    root: {
      width: '100%',
      minWidth: '100px',
      '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 8,
        height: 40,
        padding: '5px 10px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        }
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 13,
        padding: 0
      }
    }
  })(core.TextField)
}
