import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import {
  assets,
  ErrorHandling,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  PulseOpsFormat,
  SelectOption
} from '@pulseops/common'
import { ComplainInquiryForm } from '../../../../../submission/src/complain-inquiry/complain-inquiry-form'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { IBGeneralField, TypeInputComponent } from '../../common'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail?: InquiryComplaintData.DetailData | null
}
export const IBGAOperation = ({ detail, form }: Props) => {
  const { t } = useTranslation()

  const [generalAgencyList, setGeneralAgencyList] = React.useState<InquiryComplaintData.IACSources[]>([])

  const { control } = form

  // Fecth API

  pipe(
    InquiryComplaintService.getGeneralAgency(),
    ZIO.map((genAgentList) => {
      setGeneralAgencyList(genAgentList)
    }),
    ErrorHandling.runDidMount()
  )
  return (
    <View>
      <Text style={{ fontWeight: '700', fontSize: 16, marginBottom: 10 }}>
        {t('requestInfo:ComplaintedGaOperationsDetails')}
      </Text>
      <View>
        <IBGeneralField
          FieldForm={form}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.SELECT,
              formName: `ga.generalAgency`,
              title: t('requestInfo:GeneralAgency'),
              option: generalAgencyList.map((item) => ({
                value: item.code,
                label: item.name
              })),
              required: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: `ga.personToBeComplained`,
              title: t('requestInfo:PersonToBeComplainted'),
              inputType: 'input',
              maxLength: 150
            }
          ]}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})
