import { Maybe, Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { AuthService, POApi, StorageBlob } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { Task, ZIO } from '@mxt/zio'

export namespace DaAddLackService {
  export const AddLackInfo = Maybe(
    t.type({
      detailList: Nullable(
        t.array(
          t.type({
            priority: Maybe(t.number),
            docID: Maybe(t.string),
            className: Maybe(t.string),
            maindoc: Maybe(t.string),
            subdoc: Maybe(t.string),
            required: Maybe(t.string),
            documentsEn: Maybe(t.string),
            documentsVn: Maybe(t.string),
            note: Maybe(t.string),
            lastedDate: Maybe(t.string),
            suspendCode: Maybe(t.string),
            status: Maybe(t.string),
            isClickSuspense: Maybe(t.boolean),
            xcondition: Maybe(t.string)
          })
        )
      ),
      processInstanceId: Maybe(t.string),
      taskId: Maybe(t.string),
      agentCode: Maybe(t.string),
      agentName: Maybe(t.string),
      clientId: Maybe(t.string),
      businessKey: Maybe(t.string),
      author: Maybe(t.string),
      createdDate: Maybe(t.number),
      responseStatus: Nullable(
        Maybe(
          t.type({
            suspendCode: Maybe(t.array(t.string)),
            suspendReason: Maybe(t.string),
            errors: Maybe(
              t.array(
                t.type({
                  code: Maybe(t.string),
                  type: Maybe(t.string),
                  message: Maybe(t.string)
                })
              )
            ),
            message: Maybe(t.string),
            code: Maybe(t.string)
          })
        )
      ),
      createdBy: Maybe(t.string),
      modifiedBy: Maybe(t.string),
      modifiedDate: Maybe(t.string),
      eref: Maybe(t.string)
    })
  )

  export const ResponseAddLack = Maybe(
    t.type({
      processInstanceId: Nullable(t.string),
      taskId: Nullable(t.string),
      agentCode: Nullable(t.string),
      agentName: Nullable(t.string),
      clientId: Nullable(t.string),
      businessKey: Nullable(t.string),
      author: Nullable(t.string),
      createdDate: Nullable(t.string),
      candidateInfo: Nullable(t.type({
        checkDuplicate: Maybe(t.boolean),
        dateOfBirth: Maybe(t.string),
        firstName: Maybe(t.string),
        gender: Maybe(t.type({
          value: Maybe(t.string),
          label: Maybe(t.string)
        })),
        idNo: Maybe(t.string),
        lastName: Maybe(t.string),
        oldIDNo: Maybe(t.string)
      })),
      responseStatus: Nullable(
        t.type({
          suspendCode: Nullable(t.array(Maybe(t.string))),
          suspendReason: Maybe(t.string),
          clientError: t.boolean,
          code: t.number,
          message: Maybe(t.string),
          errors: Maybe(
            t.array(
              t.type({
                code: Maybe(t.string),
                type: Maybe(t.string),
                message: Maybe(t.string)
              })
            )
          )
        })
      ),
      detailList: Nullable(
        t.array(
          t.type({
            priority: Maybe(t.number),
            docID: Maybe(t.string),
            className: Maybe(t.string),
            maindoc: Maybe(t.string),
            subdoc: Maybe(t.string),
            required: Maybe(t.string),
            documentsEn: Maybe(t.string),
            documentsVn: Maybe(t.string),
            note: Maybe(t.string),
            lastedDate: Maybe(t.string),
            suspendCode: Nullable(t.string),
            status: Maybe(t.string),
            isClickSuspense: Nullable(t.boolean),
            xcondition: Maybe(t.string)
          })
        )
      ),
      createdBy: Maybe(t.string),
      modifiedBy: Maybe(t.string),
      modifiedDate: Maybe(t.string),
      eref: Maybe(t.string),
      transactionType: Maybe(t.string)
    })
  )

  export type AddLackInfo = t.TypeOf<typeof AddLackInfo>
  export type ResponseAddLack = t.TypeOf<typeof ResponseAddLack>

  export const getAddLackData = (agentCode: string): Task<ResponseAddLack> => {
    return pipe(
      POApi.getConfig({ params: { agentCode } })(
        'distribution-agents-service/agent-document/get-document-status-lackdoc'
      )(ResponseAddLack),
      ZIO.map((res) => {
        if (res && res?.detailList && res?.detailList?.length > 0) {
          return res
        }
        return null
      })
    )
  }

  export const AddLackDoc = (businessKey: string, fileList: StorageBlob.FileContentSubmit[]) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap(user => {
        return  POApi.post(`distribution-agents-service/agent-document/add-document-status-lackdoc`)(AddLackInfo)({
          body: {
            caseId: businessKey,
            attachments: fileList,
            uploader: user.email
          }
        })
      }),
      ZIO.map((res) => res)
    )
}
