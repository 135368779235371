import { SelectOption } from '@pulseops/common'
export namespace ErrorHandlerReportConstants {
  export enum CurrentActivity {
    INIT_DATA = 'initialProcessData',
    AML_CALL = 'workflowStage_event-aml',
    AML_CHECK = 'workflowStage_event-aml-check',
    ASSIGNMENT = 'workflowStage_event-assignment',
    ASSIGNMENT_UW = 'workflowStage_event-assignment-uw',
    ASSIGNMENT_ADJ = 'workflowStage_event-assignment-adj-rule',
    AUTHORITY = 'workflowStage_event-authority',
    STP = 'workflowStage_check-stp'
  }

  export const CurrentActivityList: SelectOption[] = [
    {
      label: 'Init Data',
      value: CurrentActivity.INIT_DATA
    },
    {
      label: 'AML Call',
      value: CurrentActivity.AML_CALL
    },
    {
      label: 'AML Check',
      value: CurrentActivity.AML_CHECK
    },
    {
      label: 'Assignment',
      value: CurrentActivity.ASSIGNMENT
    },
    {
      label: 'Assignment UW',
      value: CurrentActivity.ASSIGNMENT_UW
    },
    {
      label: 'Assignment ADJ',
      value: CurrentActivity.ASSIGNMENT_ADJ
    },
    {
      label: 'Authority',
      value: CurrentActivity.AUTHORITY
    },
    {
      label: 'STP',
      value: CurrentActivity.STP
    }
  ]
}
