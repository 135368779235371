import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Container, ErrorHandling, FieldList, GeneralInfoService, Panel, useTranslator } from '@pulseops/common'

export interface AgentInfoData {
  agentInfo: FieldList.DataSources
  oldAgentInfo: FieldList.DataSources[]
}

export const AgentScreen = (props: {
  id: string
  data: AgentInfoData | null
  setData: (val: AgentInfoData | null) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const [loading, bindLoading] = useLoading()

  // const [agentInfo, setAgentInfo] = useState<FieldList.DataSources>([])
  // const [oldAgentInfo, setOldAgentInfo] = useState<FieldList.DataSources[]>([])
  const getData = (): ZIO<unknown, Throwable, AgentInfoData> =>
    pipe(
      GeneralInfoService.getAgentInfo(props.id),
      ZIO.map((agent) => {
        const { agentInfo, oldAgentInfo } = agent
        const { servicingAgent, sharingAgent, unitManager, branchManager, businessDevManager } = agentInfo

        return {
          agentInfo: [
            {
              label: t('Agent:ServicingAgent'),
              value: servicingAgent.name
            },
            {
              label: t('contact:MobilePhone'),
              value: servicingAgent.mobilePhone
            },
            {
              label: t('Agent:OfficeCode'),
              value: unitManager.officeData
            },
            {
              label: t('Agent:SharingAgent'),
              value: sharingAgent.name
            },
            {
              label: t('contact:MobilePhone'),
              value: sharingAgent.mobilePhone
            },
            {
              label: t('Agent:GAOffice'),
              value: branchManager.gaData
            },
            {
              label: t('Agent:UnitAgent'),
              value: unitManager.name
            },
            {
              label: t('contact:MobilePhone'),
              value: unitManager.mobilePhone
            },
            {
              label: '',
              value: ''
            },
            {
              label: t('Agent:BranchManager'),
              value: branchManager.name
            },
            {
              label: t('contact:MobilePhone'),
              value: branchManager.mobilePhone
            },
            {
              label: '',
              value: ''
            },
            {
              label: t('Agent:BDM'),
              value: businessDevManager.name
            },
            {
              label: t('Agent:Region'),
              value: businessDevManager.regionCode
            }
          ],
          oldAgentInfo: oldAgentInfo.map((oa) => [
            {
              label: t('Agent:OldAgent'),
              value: oa.name
            },
            {
              label: t('contact:MobilePhone'),
              value: oa.mobilePhone
            },
            {
              label: t('Agent:TerminateDate'),
              value: oa.terminateDate
            }
          ])
        }
      })
    )

  const { agentInfo, oldAgentInfo } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    agentInfo: [],
    oldAgentInfo: []
  }

  const existAgentInfo = agentInfo.length > 0
  const existOldAgentInfo = oldAgentInfo.length > 0

  return (
    <Container loading={loading}>
      {existAgentInfo && (
        <Panel title={t('Agent:agentInfo')}>
          <FieldList dataSource={agentInfo} />
        </Panel>
      )}
      {existOldAgentInfo && (
        <Panel title={t('Agent:oldAgentInfo')}>
          {oldAgentInfo.map((item, i) => (
            <FieldList dataSource={item} key={`OldAgent-${i}`} />
          ))}
        </Panel>
      )}
    </Container>
  )
}
