import moment from 'moment'
import { LasStatus } from './LasStatus'
import { QueryTask } from './QueryTask'
// import { SourceType } from './SourceType'
import { TransactionStatus } from './TransactionStatus'
import { TransactionType, TransactionTypeC } from './TransactionType'
import { AMLResult } from './AMLResult'
import { PulseOpsFormat } from '../../Formatter'
import { TaskType } from '@pulseops/common'
export interface TaskOp {
  id: string
  category: string
  caseId: string
  policyNumber: string
  clientNumber: string
  startDate: Date
  lastUpdated: Date | null
  source: string
  transactionType: TransactionType | null
  assignee: string
  assigneeName: string
  aging: string
  agingTotal: string | null
  agingDate: string | null
  status: TransactionStatus
  activity: string
  processInstanceId: string | null
  updateToCore: boolean
  createdDate: Date | null
  createdBy: string | null
  lastUpdateByAdj: string
  lastUpdateByVer: string
  lastUpdateDateAdj: Date | null
  lastUpdateDateVer: Date | null
  lastUpdateDate: Date | null
  subServiceType: string
  subTransactionType: string
  subTransactionCode?: string
  poClientNumber: string
  poName: string
  poPhoneNumber: string
  claimType: string
  lifeAssuredName: string
  claimNumber: string
  request: string
  amlResult: AMLResult | null
  redFlagResult: boolean | null
  endTime: Date | null | undefined
  printCount: number
  paymentMethod: string | null
  proposalNumber: string | null
  paymentNumber: string | null
  isCancelPayment: boolean | null
  cancelPaymentStatus: string | null
  officeCode: string | null
  eref: string | null
  agentCode: string | null
  suspendReason: string | null
  unsuspendReason: string | null
  amount: string | null
  pendingNum: string
  lastActivity: string | null
  actionName: string
  writeOffAction: string | null
  suspendReasonPC: string | null | undefined
  suspendTypesPC:
    | {
        code: string
        typeEn: string
        typeVn: string
      }[]
    | null
  subServiceTypeCode: string | null
  hasVoucher: boolean | null | undefined
  vouchers: { code: string; amount: string }[]
  stpFunction: string | null
  batchName: string | null | undefined
}

export interface TaskComment {
  username: string
  time: Date
  message: string
}

export interface OGRData {
  caseId: string
  transactionType: string
  activity: string
  status: string
  assignee: string
  source: string
}

export interface LasTransaction {
  number: string
  transactionDate: string
  date: string
  code: string
  description: string
}

export const TASK_ACTIVITY = {
  VERIFICATION: 'Verification',
  QC: 'Quality control',
  PENDING: 'Pending',
  INIT_PROCESS: 'AML call',
  AML_RE_CHECK: 'AML check',
  UPDATE_TO_CORE: 'Update to core',
  AUTHORITY_CHECK: 'Authority check'
}

export const TaskComment = (data: TaskComment): TaskComment => data

export const mapLasStatus = new Map<LasStatus, { label: string; color: string }>([
  [LasStatus.SUCCESS, { label: 'Success', color: '#37CFFF' }],
  [LasStatus.FAILED, { label: 'Failed', color: '#ED1B2E' }]
])

// const changeClientInfoPolicy = (task: QueryTask): boolean => task.transactionType === TransactionType.CHANGE_CLIENT_INFO
//   && SourceType.PRUONLINE === task.source

// const isClientNum = (type: string) =>
//   [TransactionType.CHANGE_CONTACT_INFO_PHONE,TransactionType.CHANGE_CONTACT_INFO_EMAIL, TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL,TransactionType.CHANGE_CLIENT_INFO, TransactionType.FATCA_DECLARATION].includes(type as TransactionType)

export const calculateAgingDay = (
  status: TransactionStatus | null,
  createDate?: Date | null,
  endDate?: Date | null
): number => {
  switch (status) {
    case TransactionStatus.COMPLETED:
    case TransactionStatus.END:
      return endDate ? moment(endDate).startOf('days').diff(moment(createDate).startOf('days'), 'days') : 0
    case TransactionStatus.PENDING:
    case TransactionStatus.IN_PROGRESS:
    case TransactionStatus.CALLING:
      return createDate ? moment().startOf('days').diff(moment(createDate).startOf('days'), 'days') : 0
    default:
      return 0
  }
}

export const getStatus = (task: QueryTask): TransactionStatus => {
  switch (task.status) {
    case 'COMPLETED':
    case 'Completed':
      return TransactionStatus.COMPLETED
    case 'Pending':
      return TransactionStatus.PENDING
    case 'REJECT':
    case 'Rejected':
      return TransactionStatus.REJECTED
    case 'End':
      return TransactionStatus.END
    case 'Calling':
        return TransactionStatus.CALLING
    default:
      return TransactionStatus.IN_PROGRESS
  }
}

export const getAMLResult = (task: QueryTask): AMLResult => {
  switch (task.amlResult) {
    case 'HIT':
      return AMLResult.HIT
    case 'NO_HIT':
      return AMLResult.NO_HIT
    case 'ERROR':
      return AMLResult.ERROR
    case 'WARNING':
      return AMLResult.WARNING
    case 'HIT_ACCEPT':
      return AMLResult.HIT_ACCEPT
    case 'HIT_DECLINE':
      return AMLResult.HIT_DECLINE
    case 'INSUFFICIENT_INFORMATION':
      return AMLResult.INSUFFICIENT_INFORMATION
    default:
      return AMLResult.NOTCONNECT
  }
}

export const TaskOp = (task: QueryTask, status: TransactionStatus | null): TaskOp => ({
  id: task.id,
  category: task.category || '-',
  caseId: task.businessKey || '-',
  // policyNumber: task.correlationId && (!isClientNum(task.transactionType || '-') || changeClientInfoPolicy(task)) ? task.correlationId  : '',
  // clientNumber: task.correlationId && (isClientNum(task.transactionType || '-') && !changeClientInfoPolicy(task)) ? task.correlationId : '',
  policyNumber: task.policyNumber && task.policyNumber !== 'null' ? task.policyNumber : '',
  clientNumber: task.clientNumber || '-',
  startDate: task.createTime ?? (task.startTime ? new Date(task.startTime) : new Date()),
  lastUpdated: null,
  source: task.source || '-',
  transactionType: TransactionTypeC.is(task.transactionType) ? task.transactionType : null,
  assignee: task.assignee || '-',
  assigneeName: task.assigneeName || '-',
  status: status || getStatus(task),
  aging: calculateAgingDay(
    status || getStatus(task),
    task.createTime ?? (task.startTime ? new Date(task.startTime) : new Date()),
    task.endTime
  ).toString(),
  agingTotal: (task.createdDate
    ? moment().startOf('days').diff(moment(task.createdDate).startOf('days'), 'days')
    : 0
  ).toString(),
  agingDate: task.agingDate?.toString() || null,
  activity: task.activity || '',
  processInstanceId: task.processInstanceId || '-',
  updateToCore: task.updateToCore,
  createdDate: task.createdDate || null,
  createdBy: task.createdBy || null,
  lastUpdateByAdj: task.lastUpdateByAdj || '',
  lastUpdateByVer: task.lastUpdateByVer || '',
  lastUpdateDateAdj: task.lastUpdateDateAdj || null,
  lastUpdateDateVer: task.lastUpdateDateVer || null,
  lastUpdateDate: task.lastUpdateDate || null,
  subServiceType: task.subServiceType || '',
  subTransactionType: task.subTransactionType || '',
  subTransactionCode: task.subTransactionCode || '',
  poClientNumber: task.poClientNumber || '',
  poName: task.poName || '',
  poPhoneNumber: task.poPhoneNumber || '',
  claimType: task.claimType || '',
  lifeAssuredName: task.lifeAssuredName || '',
  claimNumber: task.claimNumber || '',
  request: task.transactionType === TransactionType.COMMON_REQUEST_FORM && task.request ? task.request : '',
  amlResult: task.amlResult ? getAMLResult(task) : null,
  redFlagResult: task.redFlagResult === null || task.redFlagResult === undefined ? null : task.redFlagResult,
  endTime: task.endTime,
  printCount: !task.printCountCashAtCounter || task.printCountCashAtCounter === null ? 0 : task.printCountCashAtCounter,
  paymentMethod: task.paymentMethod || '',
  proposalNumber: task.proposalNumber ?? null,
  paymentNumber: task.paymentNumber ?? null,
  isCancelPayment: task.isCancelPayment ?? false,
  cancelPaymentStatus: task.cancelPaymentStatus ?? null,
  officeCode: task.officeCode ?? '',
  eref: task.eref || '',
  agentCode: task.agentCode || '',
  unsuspendReason: task.unsuspendReason || '',
  amount: task.amount || '',
  pendingNum: task.pendingNum || '',
  lastActivity: TASK_ACTIVITY[task?.lastActivity as keyof typeof TASK_ACTIVITY] ?? '-',
  actionName: task.actionName || '',
  writeOffAction: task.writeOffAction || '',
  suspendReasonPC: task.suspendReason || '',
  suspendReason:
    task.lastActivity === 'VERIFICATION'
      ? task.suspendReason === 'null'
        ? '-'
        : task.suspendReason || '-'
      : task.lastActivity === 'PENDING'
      ? task.unsuspendReason === 'TIME_OUT'
        ? '-'
        : task.unsuspendReason || '-'
      : '-',
  suspendTypesPC: task.suspendTypes ?? null,
  hasVoucher: task.hasVoucher || false,
  vouchers: task.vouchers || [],
  stpFunction: task.stpFunction || '',
  subServiceTypeCode: '',
  batchName: task.batchName || ''
})

export type TaskPremiumOp = TaskOp & {
  amount: string | null
  bankCode: string | null
  proposalNumber: string | null
  referenceNumber: string | null
  receiptDate: string | null
  paymentReason: string | null
  remark: string | null
}

export const TaskPremiumOp = (task: QueryTask, status: TransactionStatus | null): TaskPremiumOp => {
  return {
    ...TaskOp(task, status),
    amount: task.amount ?? null,
    bankCode: task.bankCode ?? null,
    proposalNumber: task.proposalNumber ?? null,
    referenceNumber: task.referenceNumber ?? null,
    receiptDate: task.receiptDate ? PulseOpsFormat.date(moment(task.receiptDate).toDate()) : null,
    paymentReason: task.paymentReason ?? null,
    remark: task.remark ?? null
  }
}

export type TaskDSOp = TaskOp & {
  eref: string | null
  agentCode: string | null
  agentFirstName: string
  agentLastName: string
  agentPhoneNumber: string
}

export const TaskDSOp = (task: QueryTask, status: TransactionStatus | null): TaskDSOp => {
  return {
    ...TaskOp(task, status),
    eref: task.eref ?? null,
    agentCode: task.agentCode ?? null,
    agentFirstName: task.agentFirstName ?? '',
    agentLastName: task.agentLastName ?? '',
    agentPhoneNumber: task.agentPhoneNumber ?? ''
  }
}

export const totalAgingDays = (date: Date) => {
  return moment().startOf('days').diff(moment(date).startOf('days'), 'days') ?? 0
}

export type TaskAccountingOP = TaskOp & {
  amount: string | null
  bankCode: string | null
  pvNumber: string | null
  paymentNumber: string | null
  paymentDate: number | null
  payee: string | null
  statusNote: string | null
  notes: string | null
  sumAmount: number | null
  idNumber: string | null
  lastUpdatedBy: string | null
  officeCode: string | null
  authorizedDate: number | null
}

export const TaskAccountingOP = (task: QueryTask, status: TransactionStatus | null): TaskAccountingOP => {
  return {
    ...TaskOp(task, status),
    amount: task.amount ?? null,
    bankCode: task.bankCode ?? null,
    pvNumber: task.pvNumber ?? null,
    paymentNumber: task.paymentNumber ?? null,
    paymentDate: task.paymentDate ?? null,
    payee: task.payeeName ?? null,
    statusNote: task.statusNote ?? null,
    notes: task.notes ?? null,
    sumAmount: task.sumAmount ?? null,
    idNumber: task.idNumber ?? null,
    lastUpdatedBy: task.lastUpdatedBy ?? null,
    officeCode: task.officeCode ?? null,
    authorizedDate: task.authorizedDate ?? null
  }
}
export type TaskIDCOp = TaskOp & {
  eref: string | null
  agentCode: string | null
  agentFirstName: string
  agentLastName: string
  agentPhoneNumber: string
  startTime: string | null
  channel: string | null
  callTimes: string | undefined | null
  smsTimes: string | undefined | null
  idNumber: string | undefined| null
  idcType: string
}

export const TaskIDCOp = (task: QueryTask, status: TransactionStatus | null): TaskIDCOp => {
  return {
    ...TaskOp(task, status),
    eref: task.eref ?? null,
    agentCode: task.agentCode ?? null,
    agentFirstName: task.agentFirstName ?? '',
    agentLastName: task.agentLastName ?? '',
    agentPhoneNumber: task.agentPhoneNumber ?? '',
    createdDate: task.createdDate || null,
    startTime: task.startTime || '',
    channel: task.channel || '',
    callTimes: task.callTimes?.toString() ?? '',
    smsTimes: task.smsTimes?.toString() ?? '',
    idNumber: task.idNumber?.toString() || (task.payload?.body?.idNumber || ''),
    idcType: task.idcType || '',
    poName: task.poName || (task.payload?.body?.poFullName || '')
  }
}
