import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { tableUseStyles } from './RequestTable'

export type TableGroupSetup = {
  title: string
  key: string
  stickyLeft?: boolean
  minWidth?: number
  alignRight?: boolean
}

export interface TableGroupProps {
  rows: any[][]
  setup: TableGroupSetup[]
  keyTitle: string
  actions?: (i: number, pageNum: number, pageSize: number) => void
  isPagination?: boolean
  groupKey: string
}

export const RequestTableGroup = ({ rows, setup, keyTitle, actions, isPagination, groupKey }: TableGroupProps) => {
  const classes = tableUseStyles()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const totalItem = rows.length
  const { t } = useTranslation()

  return (
    <View style={{ marginTop: 10 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {setup.map((x, i) => (
                <TableCell
                  style={!!x.minWidth ? { minWidth: x.minWidth } : {}}
                  className={x.stickyLeft ? classes.stickyLeftHeader : classes.none}
                  key={`header-${keyTitle}-${i}`}
                >
                  {x.title}
                </TableCell>
              ))}
              {actions !== undefined && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <React.Fragment key={`row-${keyTitle}-${i}`}>
                <TableRow>
                  <TableCell colSpan={setup.length}>{row[0][groupKey]}</TableCell>
                </TableRow>
                {row.map((y, j) => (
                  <TableRow key={`row-${keyTitle}-${i}-${j}`}>
                    {setup.map((z, k) => (
                      <TableCell
                        style={!!z.minWidth ? { minWidth: z.minWidth } : {}}
                        align={z.alignRight ? 'right' : undefined}
                        className={z.stickyLeft ? classes.stickyLeftCell : classes.none}
                        key={`header-${keyTitle}-${i}-${j}-${k}`}
                      >
                        {y[z.key]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={setup.length} style={{ textAlign: 'center' }}>
                  {t('common:noData')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isPagination !== false && (
        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={totalItem}
          onPageChange={(e, page) => {
            setPageNum(page)
          }}
          onRowsPerPageChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      )}
    </View>
  )
}
