import { CashOut, PayoutPopup } from '@pulseops/common'

export const mapCashOutOption = (value: PayoutPopup.Summary[]): CashOut.Option[] =>
  value.map((payout) => {
    if (payout.method !== PayoutPopup.PayoutMethods.BANKTRANSFER) {
      return {
        type: payout.method,
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0, // *
          status: 'IF'
        }
      }
    } else {
      return {
        type: payout.method,
        policy: {
          policyNo: payout.policyNum ?? '',
          totalPremium: payout.totalPremium ?? 0,
          status: 'IF'
        },
        paymentOption: {
          currency: 'VND',
          amount: Number(payout.amount)
        },
        bankAccount: {
          accountName: payout.bankAccountName ?? '',
          accountNo: payout.bankAccountNum ?? '',
          bankName: payout.bankName ?? '',
          branchCode: payout.bankBranchCode ?? '',
          bankKey: payout.bankBranchCode ?? '',
          nationalId: ''
        }
      }
    }
  })
