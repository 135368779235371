import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  DatePicker,
  ErrorHandling,
  form2,
  Input,
  PulseOpsFormat,
  RBAC,
  AVICADApi,
  downloadURI,
  AuthService,
  DistributionServices
} from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { pipe } from 'fp-ts/function'
import { default as React } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, Text, TextStyle, TouchableOpacity, View } from 'react-native'
import { ExportSearchForm, TabSearchForm, TypeImportList } from './distribution-service-form'
import * as _ from 'lodash'
import { RView } from './r-view'
// import { ImportAVICADScreen } from '../import-avi-cad-screen'
import { ExportTask } from './ExportTask'
import moment from 'moment'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  },
  cellMinWidth: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    minWidth: 240
  }
})

export const ExportAVICADScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const [tab, setTab] = React.useState<TabSearchForm | null>(null)
  //   const [pageSize, setPageSize] = React.useState<number>(10)
  //   const [pageNum, setPageNum] = React.useState<number>(0)
  //   const [totalItem, setTotalItem] = React.useState<number>(0)
  //   const [isCollapse, setIsCollapse] = React.useState<boolean>(false)
  //   const [infoVisible, setInfoVisible] = React.useState<boolean>(false)

  const { t } = useTranslation()

  //   const [rows, setRows] = React.useState<TaskOp[]>([])
  //   const [messageResponse, setMessageResponse] = React.useState<string | null>(null)

  //   const [noteDetail, setNoteDetail] = React.useState<ServiceInquiryNote>({
  //     customerComment: '',
  //     closedInfo: ''
  //   })
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  //   const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})

  const ExportTableRef = React.useRef<ExportRef | null>(null)

  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      setTab(TabSearchForm.ExportData)
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('TaskManagement:DistributionService'),
          navigate: () => {
            props.navigation.navigate('DistributionServicesScreen')
          }
        },
        {
          title: t('utilities:ExportAVICAD'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  //   React.useEffect(() => {
  //     setPageSize(10)
  //     setPageNum(0)
  //     setTotalItem(0)
  //     setIsCollapse(false)
  //     setInfoVisible(false)
  //     setRows([])
  //     setMessageResponse(null)
  //     setSubmittedData({})
  //   }, [tab])

  const getFormSearch = () => {
    // if (tab === TabSearchForm.ImportData) {
    //   return <ImportFormSearch />
    // }

    if (tab === TabSearchForm.ExportData) {
      return (
        <ExportFormSearch
          onExport={(submitData) => {
            const data: ExportTask.ExportAVICAD = {
              fromDate: submitData.fromDate
                ? PulseOpsFormat.datetoFormat(submitData.fromDate, 'DD/MM/yyyy').toString()
                : '',
              toDate: submitData.toDate ? PulseOpsFormat.datetoFormat(submitData.toDate, 'DD/MM/yyyy').toString() : '',
              username: submitData.userImport ?? ''
            }
            return exportAvicad(data)
          }}
          onSearch={(searchData) => {
            const data: ExportTask.SearchAVICAD = {
              fromDate: searchData.fromDate
                ? PulseOpsFormat.datetoFormat(searchData.fromDate, 'DD/MM/yyyy').toString()
                : '',
              toDate: searchData.toDate ? PulseOpsFormat.datetoFormat(searchData.toDate, 'DD/MM/yyyy').toString() : '',
              userImport: searchData.userImport ?? '',
              maxRecordPerPage: 10,
              pageToGet: 0,
              propertySort: 'idnum',
              directionSort: 'asc'
            }

            return ExportTableRef?.current?.onSearch?.(data)
          }}
        />
      )
    }
  }

  const getContentForm = () => {
    if (tab === TabSearchForm.ExportData) {
      return <ExportTable ref={ExportTableRef} />
    }
  }

  const exportAvicad = ({ fromDate, toDate, username }: ExportTask.ExportAVICAD) => {
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, AVICADApi.exportAVICAD(fromDate, toDate, username)),
      ZIO.flatMap(([token, loginType, res]) => {
        return !!res && !!res.body
          ? pipe(
              ZIO.fromPromise(() =>
                fetch('data:application/vnd.openxmlformats-officedocument.speadsheetml.sheet;base64,' + res.body, {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              ),
              ZIO.flatMap((res) =>
                ZIO.zipPar(
                  ZIO.succeed(res),
                  ZIO.fromPromise(() => res.blob())
                )
              ),
              ZIO.map(([res, blob]) => {
                return downloadURI(window.URL.createObjectURL(blob), `Report AVICAD_result_export.xlsx`)
              })
            )
          : ZIO.succeed(showToast(t(`message:MS030029`), 'error'))
      }),
      ZIO.unsafeRun({})
    )
  }

  return tab === null ? (
    <></>
  ) : (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View>
          <View style={styles.searchContainer}>
            {/* <View style={[styles.row, styles.titleContainer]}>
              <View style={[{ padding: 16 }, tab === TabSearchForm.ImportData ? { backgroundColor: '#FAFAFA' } : {}]}>
                <RadioButton
                  key="Import"
                  label={t('DistributionService:Import')}
                  selected={tab === TabSearchForm.ImportData}
                  onChange={() => setTab(TabSearchForm.ImportData)}
                />
              </View>
              <View style={[{ padding: 16 }, tab === TabSearchForm.ExportData ? { backgroundColor: '#FAFAFA' } : {}]}>
                <RadioButton
                  key="Export"
                  label={t('DistributionService:Export')}
                  selected={tab === TabSearchForm.ExportData}
                  onChange={() => setTab(TabSearchForm.ExportData)}
                />
              </View>
            </View> */}
            {/* get form */}
            {getFormSearch()}
            {/* end form */}
          </View>
          {/* content form */}
          {getContentForm()}
          {/* end content form */}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

// const ImportFormSearch = () => {
//   const { base } = form2.useForm(ImportSearchForm.codec, {
//     defaultValues: {
//       type: TypeImportList[0]
//     }
//   })
//   const { t } = useTranslation()

//   return (
//     <View style={{ paddingHorizontal: 20 }}>
//       <View style={styles.wrap}>
//         <Controller
//           name="type"
//           control={base.control}
//           render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
//             return (
//               <RView width={'30%'} style={styles.inputStyle}>
//                 <Select
//                   label={t('DistributionService:Type')}
//                   options={TypeImportList.map((item) => ({
//                     label: item.label,
//                     value: item.value
//                   }))}
//                   placeholder={t('common:Select')}
//                   onChange={(e) => {
//                     onChange(e)
//                   }}
//                   onBlur={onBlur}
//                   value={value}
//                   errorMessage={error?.message}
//                 />
//               </RView>
//             )
//           }}
//         />
//       </View>
//     </View>
//   )
// }
export interface ExportRef {
  onSearch: (data: any) => void
}

const ExportFormSearch = (props: {
  onExport: (data: ExportSearchForm.Validated) => void
  onSearch: (data: ExportSearchForm.Validated) => void
}) => {
  // props: { onSearch: (data: ExportSearchForm.Validated) => void, onExport: () => void, isShowBtnExport: boolean }
  const { onExport, onSearch } = props

  const { base, handleSubmit } = form2.useForm(ExportSearchForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  })
  const { t } = useTranslation()

  const currentDate = new Date()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const onExportData = handleSubmit((validated) => {
    return onExport({
      ...validated
    })
  })

  const onSearchAVICAD = handleSubmit((validated) => {
    return onSearch({
      ...validated
    })
  })

  return (
    <View style={{ paddingHorizontal: 20 }}>
      <View style={styles.wrap}>
        <Controller
          name="fromDate"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={styles.inputStyle}>
                <DatePicker
                  required
                  label={t('DistributionService:FromDate')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  maxDate={currentDate}
                  maxDateMessage={t('message:MS990032')}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
        <Controller
          name="toDate"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={styles.inputStyle}>
                <DatePicker
                  required
                  label={t('DistributionService:ToDate')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  maxDate={currentDate}
                  maxDateMessage={t('message:MS990032')}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
        <Controller
          name="userImport"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={[styles.inputStyle, { marginTop: 6 }]}>
                <Input
                  title={t('DistributionService:UserImport')}
                  value={value ?? undefined}
                  onChange={onChange}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
      </View>
      <View style={[styles.row, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={{ width: 118, height: 40 }}
          onPress={() => {
            onSearchAVICAD()
          }}
        >
          <View style={styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('DistributionService:View')}</Text>
          </View>
        </TouchableOpacity>
        {permissions.includes(DistributionServices.ExportAvicadDistributionServices) && (
          <TouchableOpacity
            style={{ width: 118, height: 40, marginLeft: 30 }}
            onPress={() => {
              onExportData()
            }}
          >
            <View style={styles.btn}>
              <Text style={{ textAlign: 'center', color: '#fff' }}>{t('DistributionService:Export')}</Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
      <View style={[styles.row, { marginTop: 6 }]}>
        <Text style={{ color: '#ED1B2E' }}>{_.get(base.formState.errors, '.message')}</Text>
      </View>
    </View>
  )
}

const ExportTable = React.forwardRef((props: {}, ref: React.Ref<ExportRef>) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [exportData, setExportData] = React.useState<Array<AVICADApi.SearchElement>>([])
  const [submittedData, setSubmittedData] = React.useState<ExportTask.SearchAVICAD>({
    fromDate: '',
    toDate: '',
    userImport: '',
    maxRecordPerPage: 0,
    pageToGet: 0,
    propertySort: '',
    directionSort: ''
  })

  const [loading, bindLoading] = useLoading(false)

  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)

  React.useImperativeHandle(ref, () => ({
    onSearch: (data) => {
      setSubmittedData(data)
      getPremiumCollectionTask(data, pageSize, pageNum)
    }
  }))

  const getPremiumCollectionTask = (submittedData: ExportTask.SearchAVICAD, pageSize: number, pageNum: number) => {
    pipe(
      AVICADApi.searchAVICAD({
        ...submittedData,
        maxRecordPerPage: pageSize,
        pageToGet: pageNum
      }),
      ZIO.tap((res) => {
        setTotalItem(res.body.totalElement)
        setMessageResponse(res.body.elements?.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        setExportData(res.body.elements ?? [])
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }
  if (exportData.length === 0) {
    return null
  }
  return (
    <View>
      {messageResponse ? (
        <View style={{ marginHorizontal: 30 }}>
          <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
        </View>
      ) : (
        <View style={{ marginHorizontal: 30 }}>
          <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
            <Table style={{ userSelect: 'text' }} stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableCell}>{t('DistributionService:IDnumber')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:IDCard')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:TaxCode')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:OrganizationName')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:AgentName')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:AgentCode')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Birthday')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Gender')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:CertificateNumber')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Insurer')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Status')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:AppointedDate')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:TerminatedDate')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:BlacklistDate')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:BlacklistCode')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:BlackListCheck')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:ISCOMPANYCheck')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:ISOTHERCOMPANYCheck')}</TableCell>
                  <TableCell className={classes.cellMinWidth}>{t('DistributionService:Result')}</TableCell>
                  <TableCell className={classes.cellMinWidth}>{t('DistributionService:ValidFlag')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:UserImport')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:ImportDate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exportData.map((row, index) => (
                  <TableRow key={`___list_row${index}`}>
                    <TableCell>{row.idnum ? row.idnum : '-'}</TableCell>
                    <TableCell>{row.idCard ? row.idCard : '-'}</TableCell>
                    <TableCell className={classes.cellNoWrap}> {row.taxCode ? row.taxCode : '-'}</TableCell>
                    <TableCell>{row.organizationName ? row.organizationName : '-'}</TableCell>
                    <TableCell>{row.agentName ? row.agentName : '-'}</TableCell>
                    <TableCell>{row.agentCode ? row.agentCode : '-'}</TableCell>
                    <TableCell>{row.birthday ? moment(row.birthday).format('DD/MM/yyyy') : '-'}</TableCell>
                    <TableCell>{row.gender ? row.gender : '-'}</TableCell>
                    <TableCell>{row.lifecer ? row.lifecer : '-'}</TableCell>
                    <TableCell>{row.insurer ? row.insurer : '-'}</TableCell>
                    <TableCell
                      // style={{ color: TransactionStatusLabel(row.agtsts).color }}
                      className={classes.cellNoWrap}
                    >
                      {/* {TransactionStatusLabel(row.agtsts).label} */}
                      {row.agtsts ?? '-'}
                    </TableCell>
                    <TableCell>{row.recentCont ? moment(row.recentCont).format('DD/MM/yyyy') : '-'}</TableCell>
                    <TableCell>{row.recentTer ? moment(row.recentTer).format('DD/MM/yyyy') : '-'}</TableCell>
                    <TableCell>{row.misdat ? row.misdat : '-'}</TableCell>
                    <TableCell>{row.blkCode ? row.blkCode : '-'}</TableCell>
                    <TableCell>{row.blacklist ? 'Y' : 'N'}</TableCell>
                    <TableCell>{row.isCompany ? 'Y' : 'N'}</TableCell>
                    <TableCell>{row.isOtherCompany ? 'Y' : 'N'}</TableCell>
                    <TableCell>{row.result ?? '-'}</TableCell>
                    <TableCell>{row.validFlag ?? '-'}</TableCell>
                    <TableCell>{row.userImport ?? '-'}</TableCell>
                    <TableCell>{row.importDate ? moment(row.importDate).format('DD/MM/yyyy') : '-'}</TableCell>
                    {/* <TableCell
                                style={{ color: TransactionStatusLabel(row.status).color }}
                                className={classes.cellNoWrap}
                            >
                                {TransactionStatusLabel(row.status).label}
                            </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={pageNum}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              setPageNum(page)
              getPremiumCollectionTask(submittedData, pageSize, page)
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageNum(0)
              getPremiumCollectionTask(submittedData, Number(e.target.value), 0)
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 30,
    paddingBottom: 16,
    paddingTop: 16
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  }
} as const)
