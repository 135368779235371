import React from 'react'
import { assets, SC } from './core'
import { View } from 'react-native'

export const Header = (props: any) => {
  const { isWide, t } = props
  return (
    <SC.Header>
      <SC.Logo isWide={isWide}>
        <assets.Logo />
      </SC.Logo>
    </SC.Header>
  )
}
