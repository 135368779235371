/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { View, Text, Pressable } from 'react-native'
import {
  TaskDetail,
  assets,
  SelectTable,
  ErrorHandling,
  AuthService,
  downloadURI,
  AppContext,
  Toast,
  SupplementaryInfoService,
  PulseOpsFormat
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { DaDocumentService } from './da-doccument-services'
import { UseFormReturn } from 'react-hook-form'
import { DocumentForm } from './da-document-form'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { GeneralForm } from '../da-general-info'
import { Footer } from '../da-request-info'
import { Color } from '@material-ui/lab'
import * as SupplementaryInfoForm from '../../../../task-detail/container/supplementary-info/SupplementaryInfoForm'

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 700,
    fontSize: 15,
    width: 'calc(100%/2.5)',
    paddingRight: 30,
    paddingLeft: 50,
    color: '#70777E'
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E'
  },
  tableHeaderCellbody: {
    paddingRight: 30,
    paddingLeft: 50
  },
  tableHeaderRow: {
    backgroundColor: 'white'
  },
  tableHea: {
    fontWeight: 700,
    fontSize: 15,
    color: '#000',
    paddingLeft: 50
  },
  tableCellUnder: {
    textDecorationLine: 'underline',
    color: 'blue',
    fontWeight: 700,
    fontSize: 15
  },
  minWidthCellTable: {
    width: 110
  }
})
export interface Status {
  value?: string
  label?: number
}
type Props = {
  detail: TaskDetail.AgentOnboardNew
  form: Omit<UseFormReturn<DocumentForm.Raw>, 'handleSubmit'>
  formGeneral: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'>
  isHistory: boolean | null
  checkSubTabIndex: (val: number) => void
  setIsScroll?: (isCheck: boolean) => void
  isScroll?: boolean
}

export const DAdocumentsScreen = (props: Props) => {
  const classes = useStyles()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const { t, i18n } = useTranslation(['RequestInfo'])
  const [loading, bindLoader] = useLoading(false)
  // const [checkStatus, setCheckStatus] = React.useState<Status[]>([])
  // const [suspend, setSuspend] = React.useState<string>('')
  // const [rejectSuspend, setRejectSuspend] = React.useState<string>('')

  const { control, setValue, getValues, trigger, watch } = props.form
  const [data, setData] = React.useState<Array<DaDocumentService.DetaiList>>([])
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const statusOptions = [
    { label: t('Blank'), value: 'blank' },
    { label: t('Done'), value: 'done' },
    { label: t('Reject'), value: 'reject' },
    { label: t('Lackdoc'), value: 'lackdoc' }
  ]

  const [arrSubpend, setArrSubpend] = React.useState<Array<number>>([])

  const { channelDocument } = pipe(
    DaDocumentService.getDocumentLog(props.detail.businessKey ?? ''),
    ZIO.flatMap((resLog) =>
      !!resLog && !!resLog.detailList && resLog.detailList.length > 0
        ? ZIO.succeed(resLog.detailList)
        : pipe(
            DaDocumentService.getChannelDocument({
              source: props.detail.source,
              channelId: props.formGeneral.getValues('channelLink') ?? '',
              idNo: props.detail.idNo
            }),
            ZIO.map((res) => {
              return res.data
            })
          )
    ),

    ZIO.map((res) => {
      const listSubpend = res
        .filter((x) => x.status === 'reject' && x.isClickSuspense === true)
        .map((item) => item.priority ?? 0)
      setArrSubpend(listSubpend)
      mappingDocumentData(res)
      return {
        channelDocument: res
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount({
      channelDocument: []
    })
  )

  React.useEffect(() => {
    if (channelDocument.length > 0) {
      setData(channelDocument)
    }
  }, [channelDocument])
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])
  const mappingDocumentData = (data: Array<DaDocumentService.DetaiList>) => {
    // let checkStatus: Status[] = []
    data.map((item: DaDocumentService.DetaiList, i: number) => {
      !!item.docID && setValue(`documentList.${i}.docID`, item.docID)
      !!item.priority && setValue(`documentList.${i}.priority`, item.priority.toString())
      !!item.className && setValue(`documentList.${i}.className`, item.className)
      !!item.maindoc && setValue(`documentList.${i}.maindoc`, item.maindoc)
      !!item.subdoc && setValue(`documentList.${i}.subdoc`, item.subdoc)
      !!item.required && setValue(`documentList.${i}.required`, item.required)
      !!item.documentsEn && setValue(`documentList.${i}.documentsEn`, item.documentsEn)
      !!item.documentsVn && setValue(`documentList.${i}.documentsVn`, item.documentsVn)
      !!item.note && setValue(`documentList.${i}.note`, item.note)
      !!item.suspendCode && setValue(`documentList.${i}.suspendCode`, item.suspendCode)
      !!item.xcondition && setValue(`documentList.${i}.xcondition`, item.xcondition)
      !!item.lastedDate && setValue(`documentList.${i}.lastedDate`, item.lastedDate)
      !!item.status
        ? setValue(`documentList.${i}.status`, getStatus(item.status))
        : setValue(`documentList.${i}.status`, statusOptions[0])
      setValue(`documentList.${i}.isSupend`, item.status === 'reject' ? true : false)
      !!item.isClickSuspense && setValue(`documentList.${i}.isClickSuspense`, item.isClickSuspense)

      // let obj:Status = { label: item?.priority ?? 0, value: item?.status ?? '' }
      // checkStatus.push(obj)
    })
  }

  const getStatus = (value: string) => {
    if (value) {
      return statusOptions.find((x) => x.value === value) ?? statusOptions[0]
    }
    return statusOptions[0]
  }

  const getDocumentView = (idNo: string, docId: string, className: string, index: number) => {
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        DaDocumentService.getDocumentFile(idNo, docId, className)
      ),
      ZIO.flatMap(([token, loginType, documentInfo]) => {
        updateIndexData(index, documentInfo)
        // setValue(`documentForm.${index}.lastedDate`, moment(res.data?.[0]?.createdDate).format('DD/MM/yyyy'));
        return !!documentInfo && !!documentInfo.data && !!documentInfo.data?.[0]?.url
          ? pipe(
              ZIO.fromPromise(() =>
                fetch(documentInfo.data?.[0]?.url ?? '', {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              ),
              ZIO.flatMap((res) =>
                ZIO.zipPar(
                  ZIO.succeed(res),
                  ZIO.fromPromise(() => res.blob())
                )
              ),
              ZIO.map(([res, blob]) => {
                const type = res.headers.get('content-type') || ''
                downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
              })
            )
          : ZIO.succeed(showToast(t('common:DowloadFail'), 'error'))
      }),
      ZIO.unsafeRun({})
    )
  }
  const updateIndexData = (idx: number, documentInfo: any) => {
    if (!!documentInfo && !!documentInfo.data && !!documentInfo.data?.[0]?.url) {
      const docInfo: Array<DaDocumentService.DetaiList> = [...data]
      const lastedDate = docInfo[idx].lastedDate
      docInfo[idx].lastedDate = documentInfo.data?.[0]?.createdDate
      setValue(`documentList.${idx}.lastedDate`, moment(documentInfo.data?.[0]?.createdDate).format('yyyy/MM/DD'))
      setData(docInfo)
      if (
        moment(lastedDate).format('yyyy/MM/DD HH:mm') <
        moment(documentInfo.data?.[0]?.createdDate).format('yyyy/MM/DD HH:mm')
      ) {
        setValue(`documentList.${idx}.isClickSuspense`, false)
      }
    }
  }

  const onReset = () => {
    channelDocument.map((item, i) => {
      !!item.status
        ? setValue(`documentList.${i}.status`, getStatus(item.status))
        : setValue(`documentList.${i}.status`, statusOptions[0])
    })
  }
  const maritalStatus = (status: string) => {
    if (status === 'S') {
      return '1'
    } else if (status === 'M') {
      return '2'
    } else if (status === 'D') {
      return '3'
    } else if (status === 'W') {
      return '4'
    } else {
      return '5'
    }
  }
  const onSave = () => {
    return pipe(
      ZIO.fromPromise(async () => {
        return await trigger()
      }),
      ZIO.flatMap((valid) => {
        const documentForm = getValues() as DocumentForm.Validated
        if (valid) {
          const detail: Array<DaDocumentService.DetaiList> = []
          documentForm.documentList.map((item, i) => {
            const obj: DaDocumentService.DetaiList = {
              ...item,
              status: item.status?.value ?? '',
              priority: item.priority === null ? null : +item.priority
            }
            detail.push(obj)
          })
          const arr = documentForm.documentList.filter(
            (ext) =>
              ext.status?.value === 'reject' && ext.isClickSuspense && !arrSubpend.includes(Number(ext.priority ?? 0))
          )
          const caseSubpended = [...arr].map((item) => Number(item.priority ?? 0))
          setArrSubpend((prevState) => [...prevState, ...caseSubpended])

          return pipe(
            AuthService.userInfo,
            ZIO.map((res) => {
              const data: DaDocumentService.DaDoccumentRqData = {
                processInstanceId: props.detail.processInstanceId,
                taskId: props.detail?.taskId,
                agentCode: props.detail?.agentCodes,
                clientId: props.detail?.idNo,
                businessKey: props.detail?.businessKey,
                author: res.email,
                source: props.detail.source,
                eref: props.detail?.eREF,
                detailList: detail
              }
              const daTms: DaDocumentService.DaTSMDoccumentRqData = {
                birthdayStr:
                  PulseOpsFormat.datetoFormat(props.formGeneral.getValues('dateofBirth'), 'DD/MM/YYYY') ?? '',
                identifyPassport: props.detail?.idNo ?? '',
                firstName: props.formGeneral.getValues('firstName') ?? '',
                lastName: props.formGeneral.getValues('lastName') ?? '',
                gender: props.formGeneral.getValues('genDer.value') === 'F' ? '2' : '1' ?? '',
                marriageStatus: maritalStatus(props.formGeneral.getValues('Maritalstatus.value') ?? ''),
                occupation: props.formGeneral.getValues('Occupation.value') ?? '',
                education: props.formGeneral.getValues('Education.value') ?? '',
                issueDateStr: PulseOpsFormat.datetoFormat(props.formGeneral.getValues('idDate'), 'DD/MM/YYYY') ?? '',
                issuePlace: props.formGeneral.getValues('idPlace') ?? '',
                mobile: props.formGeneral.getValues('phonenumber') ?? '',
                email: props.formGeneral.getValues('Email') ?? '',
                permanentAddress: props.formGeneral.getValues('StreetResidential') ?? '',
                permanentProvince: props.formGeneral.getValues('ProvinceResidential.value') ?? '',
                permanentDistrict:
                  props.formGeneral.getValues('ProvinceResidential.value') +
                    props.formGeneral.getValues('DistrictResidential.value') ?? '',
                permanentWard:
                  props.formGeneral.getValues('ProvinceResidential.value') +
                    props.formGeneral.getValues('DistrictResidential.value') +
                    props.formGeneral.getValues('WardResidential.value') ?? '',
                contactAddress: props.formGeneral.getValues('StreetContact') ?? '',
                contactProvince: props.formGeneral.getValues('ProvinceContact.value') ?? '',
                contactDistrict:
                  props.formGeneral.getValues('ProvinceContact.value') +
                    props.formGeneral.getValues('DistrictContact.value') ?? '',
                contactWard:
                  props.formGeneral.getValues('ProvinceContact.value') +
                    props.formGeneral.getValues('DistrictContact.value') +
                    props.formGeneral.getValues('WardContact.value') ?? '',
                unitCode: props.formGeneral.getValues('leaderCode') ?? ''
              }
              return { data, userInfo: res, daTms }
            }),
            ZIO.flatMap((documentData) => {
              return pipe(
                DaDocumentService.saveDocumentLog(documentData.data),
                ZIO.map((response) => ({
                  resultData: documentData.data,
                  userInfo: documentData.userInfo,
                  daTms: documentData.daTms
                }))
              )
            }),
            ZIO.flatMap((response) => {
              if (documentForm.documentList.find((x) => x.docID === '20101011' && x.status?.value === 'done')) {
                return DaDocumentService.saveTMSDocumentLog(response.daTms)
              }
              if (arr.length > 0) {
                return SupplementaryInfoService.save(
                  {
                    processInstanceId: props.detail.processInstanceId ?? '',
                    data: arr.map((item) => ({
                      pendingLetters: [],
                      status: SupplementaryInfoForm.Status.ACTIVE,
                      suspendCode: item.suspendCode ?? '',
                      user: response.userInfo.email
                    }))
                  },
                  []
                )
              }

              return ZIO.unit
            }),
            ZIO.tap((data) => {
              showToast(t('message:MS100003'), 'success')
              props.checkSubTabIndex(2)
              props?.setIsScroll && props?.setIsScroll(!props?.isScroll)
              return ZIO.unit
            }),
            ZIO.tapError((e) => {
              return ZIO.unit
            })
          )
        } else {
          return ZIO.succeed({})
        }
      }),
      ZIO.unsafeRun({})
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            backgroundColor: '#FAFAFA',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#D3DCE6',
            marginHorizontal: 16,
            marginVertical: 16
          }}
        >
          <TableContainer
            component={Paper}
            style={{
              borderRadius: 8,
              borderWidth: 1
              // maxHeight: 350
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                  <TableCell className={classes.tableHeader}>{t('Reports:DocumentName')}</TableCell>
                  <TableCell align={'center'} className={classes.tableHeaderCell}>
                    {t('Reports:Status')}
                  </TableCell>
                  <TableCell align={'center'} className={classes.tableHeaderCell}>
                    {t('Reports:Action')}
                  </TableCell>
                  <TableCell align={'center'} className={classes.tableHeaderCell}>
                    {/* {t('Reports:Add Suspend')} */}
                  </TableCell>
                  <TableCell align={'center'} className={classes.tableHeaderCell}>
                    {t('Reports:UpdatedDate')}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((item, idx) => {
                  return (
                    <TableRow key={`tableRow-${idx}`} className={classes.tableHeaderRow}>
                      <TableCell className={classes.tableHea}>
                        {i18n.language === 'en' ? item.documentsEn : item.documentsVn}
                      </TableCell>
                      <TableCell className={classes.minWidthCellTable} align={'center'}>
                        <View
                          style={{
                            opacity:
                              // watch(`documentList.${idx}.isSupend`) === true ||
                              watch(`documentList.${idx}.isClickSuspense`) === false ||
                              item.lastedDate ||
                              (item.docID === '20101011' && props.detail.salesType === 'PTD')
                                ? 1
                                : 0.5
                          }}
                        >
                          <Controller
                            name={`documentList.${idx}.status`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <SelectTable
                                id={idx.toString()}
                                options={statusOptions}
                                style={{ backgroundColor: '#FFF' }}
                                value={value}
                                onChange={(val) => {
                                  // let objNumber = checkStatus.findIndex((x) => x.label === item.priority)
                                  // let obj = checkStatus[objNumber]
                                  //  obj.value = val?.value
                                  // let arr = [...checkStatus]
                                  // arr.splice(objNumber, 1, obj)
                                  // setCheckStatus(arr)
                                  onChange(val)
                                  if (val?.value === 'reject') {
                                    setValue(`documentList.${idx}.isSupend`, true)
                                  } else {
                                    setValue(`documentList.${idx}.isSupend`, false)
                                  }
                                }}
                                iconSelect={<assets.IconArrowDownGray />}
                                disabled={
                                  item.lastedDate ||
                                  watch(`documentList.${idx}.isClickSuspense`) === false ||
                                  (item.docID === '20101011' && props.detail.salesType === 'PTD')
                                    ? false
                                    : true
                                }
                              />
                            )}
                          />
                        </View>
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableCellUnder}>
                        <TouchableOpacity
                          onPress={() => {
                            getDocumentView(props.detail.idNo ?? '', item.docID ?? '', item.className ?? '', idx)
                          }}
                          // disabled={!item.lastedDate}
                        >
                          <Text
                            style={{
                              textDecorationLine: 'underline',
                              color: 'blue',
                              padding: 10,
                              fontWeight: '700',
                              fontSize: 15
                              // opacity: !item.lastedDate ? 0.5 : 1
                            }}
                          >
                            {t('Show')}
                          </Text>
                        </TouchableOpacity>
                      </TableCell>
                      <TableCell align={'center'}>
                        <View>
                          <Pressable
                            disabled={
                              !watch(`documentList.${idx}.isSupend`) ||
                              watch(`documentList.${idx}.isClickSuspense`) === true
                            }
                            style={{
                              borderRadius: 100,
                              backgroundColor: '#ED1B2E',
                              height: 30,
                              width: 110,
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignSelf: 'center',
                              alignContent: 'center',
                              opacity:
                                !watch(`documentList.${idx}.isSupend`) ||
                                watch(`documentList.${idx}.isClickSuspense`) === true
                                  ? 0.5
                                  : 1
                            }}
                            onPress={() => {
                              setValue(`documentList.${idx}.isClickSuspense`, true)
                              // getDocumentView(props.detail.idNo ?? '', item.docID ?? '', item.className ?? '', idx)
                            }}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                alignContent: 'center'
                              }}
                            >
                              {' '}
                              <Text style={{ textAlign: 'center', color: '#FFF', fontWeight: 'bold', fontSize: 12 }}>
                                {t('RequestInfo:AddSuspend')}
                              </Text>
                            </View>
                          </Pressable>
                        </View>
                      </TableCell>
                      <TableCell align={'center'}>
                        {!!item.lastedDate ? moment(item.lastedDate).format('DD/MM/yyyy') : '-'}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </View>
      </View>
      {props.isHistory === true ? null : <Footer isLoading={loading} onReset={onReset} onSubmit={onSave} />}
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </View>
  )
}
