import { Task, ZIO } from '@mxt/zio'
import { HttpClient } from '@mxt/zio-http-client'
import { Alert, POApi } from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { Either } from 'fp-ts/Either'
import { constVoid, flow, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import i18next from 'i18next'
import * as t from 'io-ts'
import { ContractId } from './model/contract-id'

export namespace ImportService {
  export type ImportError = ImportError.Duplicated | ImportError.Fail | ImportError.NotInput

  export namespace ImportError {
    export type Duplicated = { tag: 'Duplicated'; msg: string }
    export const Duplicated = (msg: string): Duplicated => ({ tag: 'Duplicated', msg })

    export type Fail = { tag: 'Fail' }
    export const Fail: Fail = { tag: 'Fail' }

    export type NotInput = { tag: 'NotInput'; msg: string }
    export const NotInput = (msg: string): NotInput => ({ tag: 'NotInput', msg })
  }

  const upload =
    (url: string) =>
    (file: File): Task<Either<ImportError, void>> => {
      const formData = new FormData()
      formData.append('file', file)

      return pipe(
        POApi.post(url, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })(t.unknown)(formData),
        ZIO.foldM(
          (err) =>
            pipe(
              err.source,
              O.fromPredicate(HttpClient.isAxiosError),
              O.filterMap((source) =>
                source.response?.status === 400
                  ? pipe(
                      source.response?.data,
                      t.type({
                        result: t.keyof({ FAIL: null, DUPLICATED: null, NOT_INPUT: null }),
                        errorDescription: t.string
                      }).decode,
                      O.fromEither,
                      O.map(({ result, errorDescription }) => {
                        switch (result) {
                          case 'FAIL':
                            return ImportError.Fail
                          case 'DUPLICATED':
                            return ImportError.Duplicated(errorDescription)
                          case 'NOT_INPUT':
                            return ImportError.NotInput(errorDescription)
                        }
                      })
                    )
                  : O.none
              ),
              O.fold(
                (): Task<Either<ImportError, void>> => ZIO.fail(err),
                (importErr) => ZIO.succeed(E.left(importErr))
              )
            ),
          () => ZIO.succeed(E.right(constVoid()))
        ),
        ZIO.tap(
          flow(
            E.fold(
              (err) => {
                switch (err.tag) {
                  case 'Fail':
                    return i18next.t(`business:MS000018`)
                  case 'Duplicated':
                    return err.msg
                  case 'NotInput':
                    return err.msg
                }
              },
              () => i18next.t(`business:MS000019`)
            ),
            Alert.alertM
          )
        )
      )
    }

  export const uploadContract = upload('business-pulse/api/v1/contract-info/upload')

  export const uploadShareholder = (contractId: ContractId) =>
    upload(`business-pulse/api/v1/shareholder/upload?${ContractId.toParams(contractId)}`)

  export const uploadEmployee = (contractId: ContractId) =>
    upload(`business-pulse/api/v1/employee/upload?${ContractId.toParams(contractId)}`)

  export const uploadBenefit = (contractId: ContractId) =>
    upload(`business-pulse/api/v1/benefit/upload?${ContractId.toParams(contractId)}`)
}
