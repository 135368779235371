import { StyleSheet, View, ScrollView, SafeAreaView, Text, ActivityIndicator } from 'react-native'
import { TabList, AppContext, IBPolicyPermission, RBAC, ErrorHandling, SelectOption } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { IBClientPolicyTasks } from './ib-client-policy-tasks'
import { IBClientPolicyPrurewards } from './ib-client-policy-prurewards'
import { IBClientPolicyRequired } from './ib-client-policy-required'
import { IBClientPolicyTransaction } from './ib-client-policy-transaction'
import { StackScreenProps } from '@react-navigation/stack'
import { IBClientParamList } from '../IBClientParamList'
import React from 'react'
import _ from 'lodash'
import { pipe } from 'fp-ts/function'
import { IBService, PolicyInquiryCountData, PrurewardAgentData, PrurewardCustomerInfoData } from '../../ib-service'
import { ZIO } from '@mxt/zio'
import { IBPushToTop } from '../../common'

type Props = StackScreenProps<IBClientParamList, 'IBClientPolicyScreen'>
type ClaimDataShow = {
  claimNumber: string | undefined | null,
  processStatus: string | undefined | null,
  claimTypes: string | undefined | null,
  eventDates: string,
  registeredDate: string | undefined | null,
  approvalDate: string | undefined | null,
  caseCategory: string | undefined | null,
  faceAmount: number | undefined | null,
  cancelReason: string | undefined | null
}
export const IBClientPolicy = ({
  route,
  setListPolicyCount,
  listPolicyCount,

}: Props & {
  setListPolicyCount: (data: { policyInquiryCount: PolicyInquiryCountData[]; prurewardsPoint: number, listClaimNumbers: string[] }) => void
  listPolicyCount: {
    policyInquiryCount: PolicyInquiryCountData[]
    prurewardsPoint: number
    listClaimNumbers: string[]
  }
}) => {
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const { t } = useTranslation('Inbound')
  const isFocused = useIsFocused()
  const [officeCodes, setOfficeCodes] = React.useState<SelectOption[]>([])
  const [listCommonTransaction, setListCommonTransaction] = React.useState<{
    subServiceTypeList: SelectOption[]
    subTransactionTypeList: SelectOption[]
  }>()
  const [prurewardsData, setPrurewardsData] = React.useState<{
    customerInfo: PrurewardCustomerInfoData
    agentInfo: PrurewardAgentData
  }>()
  const [canChangeTab, setCanChangeTab] = React.useState<boolean>(true)
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [dataClaimInquiry, setDataClaimInquiry] = React.useState<ClaimDataShow[] | null>(null)
  const scrollRef = React.useRef<ScrollView | null>(null)

  React.useEffect(() => {
    if (_.isEmpty(listPolicyCount.policyInquiryCount) && route?.params?.clientNumber) {
      pipe(
        ZIO.zipPar(
          IBService.getCountPolicyInquiry(route?.params?.clientNumber as string),
          IBService.getPrurewardsSummary(route?.params?.clientNumber),
          IBService.getListClaimNumber(route?.params?.clientNumber)
        ),
        ZIO.map(([res, prurewardsSummary, claims]) => {
          setListPolicyCount({ policyInquiryCount: res.data, prurewardsPoint: prurewardsSummary.data.point || 0, listClaimNumbers: claims ?? [] })
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [])

  React.useEffect(() => {
    const breadCrumb = [
      {
        title: '',
        navigate: () => {
          navigation.navigate('HomeScreen')
        }
      },
      {
        title: t('ContactCenterInquirySystem'),
        navigate: () => {
          navigation.navigate('IBSearchScreen')
        }
      },
      {
        title: t('Client'),
        navigate: () => {
          navigation.navigate('DashBoardScreen', { clientNumber: route.params.clientNumber, tabIndex: 2 })
        }
      }
    ]
    const change = route?.params?.policyNumber
      ? [
          {
            title: t('common:Policy'),
            navigate: () => {
              navigation.reset({
                routes: [
                  { name: 'IBClientPolicyScreen', params: { clientNumber: route.params.clientNumber, tabIndex: 1 } }
                ]
              })
            }
          },
          { title: route?.params?.policyNumber, navigate: null }
        ]
      : [
          {
            title: tabIndex === 1 ? t('common:Policy') : tabIndex === 3 ? t('PRURewards') : t('Transaction'),
            navigate: null
          }
        ]

    if (isFocused) {
      changeBreadcrumb([...breadCrumb, ...change])
    }
  }, [isFocused, route?.params?.policyNumber, tabIndex])

  const onChangeTab = (i: number) => {
    if (canChangeTab) {
      if (route.params.policyNumber) {
        navigation.setParams({
          poNumber: null,
          policyNumber: null,
          policyOwnerName: null,
          mainProductName: null,
          policyStatus: null,
          premiumStatus: null,
          mainLAName: null,
          tabIndex: i
        })
      }
      //update url
      setTimeout(() => {
        const pathname = window.location.pathname
        window.history.pushState(
          {},
          document.title,
          `${pathname}?clientNumber=${route.params.clientNumber}&tabIndex=${i}`
        )
      }, 500)
      setTabIndex(i)
    }
  }

  const handlePushToTop = () => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true })
  }

  const tabs = React.useMemo(() => {
    let hasTab = []
    permissions.includes(IBPolicyPermission.ViewIBPolicyListTab) &&
      hasTab.push({
        id: 1,
        title: `${listPolicyCount.policyInquiryCount.find((x) => x.code === 'Total')?.value ?? 0} ${t('common:Policy')}`
      })
    permissions.includes(IBPolicyPermission.ViewIBTransactionTab) && 
      hasTab.push({
        id: 2,
        title: `${listPolicyCount.listClaimNumbers?.length ?? 0} ${t('ClaimRequest')}`
      })
    permissions.includes(IBPolicyPermission.ViewIBPruRewardsTab) &&
      hasTab.push({ id: 3, title: `${listPolicyCount.prurewardsPoint} PRURewards` })
    permissions.includes(IBPolicyPermission.ViewIBTransactionTab) &&
      hasTab.push({ id: 4, title: `${t('Transaction')}` })
    return hasTab
  }, [permissions, isFocused])

  React.useEffect(() => {
    !_.isEmpty(tabs) && route.params.tabIndex && setTabIndex(parseInt(route.params.tabIndex) ?? tabs[0]?.id)
  }, [tabs, route.params.tabIndex])

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#F9F9F9' }}>
      <View style={{ marginHorizontal: 10, marginBottom: 5 }}>
        <TabList menus={tabs} isSubTab={false} useOriginalId={true} tabIndex={tabIndex} onChangeTab={onChangeTab} />
      </View>
      <View style={{ flex: 1, width: '100%', height: '100%', marginTop: 5 }}>
        {tabIndex === 1 &&
          (!_.isEmpty(listPolicyCount.policyInquiryCount) ? (
            <IBClientPolicyTasks
              dataDirection={route?.params}
              clientNumber={route?.params?.clientNumber}
              tabIndex={parseInt(route?.params?.tabPolicyInfo as string)}
              listPolicyCount={listPolicyCount.policyInquiryCount}
            />
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          ))}
        {(tabIndex === 2 && (listPolicyCount.listClaimNumbers ? 
          <>
            <IBClientPolicyRequired
              setCanChangeTab={setCanChangeTab}
              listClaimNumbers={listPolicyCount.listClaimNumbers ?? []}
              clientNumber={route?.params?.clientNumber ?? ''}
              scrollRef={scrollRef}
              dataClaimInquiry={dataClaimInquiry}
              setDataClaimInquiry={setDataClaimInquiry}
            />
            <IBPushToTop onClick={handlePushToTop} />
          </>
          :
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          )
        )}
        {tabIndex === 3 &&
          (!_.isEmpty(listPolicyCount.policyInquiryCount) ? (
            <IBClientPolicyPrurewards
              setCanChangeTab={setCanChangeTab}
              data={prurewardsData}
              setData={setPrurewardsData}
              clientNumber={route?.params?.clientNumber}
            />
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          ))}
        {tabIndex === 4 &&
          (!_.isEmpty(listPolicyCount.policyInquiryCount) ? (
            <IBClientPolicyTransaction
              setOfficeCodes={setOfficeCodes}
              officeCodes={officeCodes}
              categoryDefault={route?.params?.transactionInfo as string}
              clientNumber={route?.params?.clientNumber}
              setListCommonTransaction={setListCommonTransaction}
              listCommonTransaction={listCommonTransaction}
              permissions={permissions}
            />
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          ))}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({})
