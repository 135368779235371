import { pipe } from 'fp-ts/function'
import { POApi } from '../POApi'
import { ZIO } from '@mxt/zio'
import { SubmissionService } from './SubmissionService'
import { Customer, ProductOptionSwitching } from './model'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import moment from 'moment'
import * as O from 'fp-ts/lib/Option'

export namespace ProductOptionSwitchingService {
  export const getDetail = (policyNum: string) => {
    return pipe(
      POApi.get(`wf-api/policy/${policyNum}/product-option-switching/get-data`)(ProductOptionSwitching.Detail),
      ZIO.map((response) => response.body)
    )
  }

  export const getRiskCessDate = (policyNum: string) => {
    return pipe(
      POApi.post(`wf-api/bo/policy-extra-info`)(
        t.type({
          body: t.type({
            policyExtraInfoDetails: t.array(
              t.type({
                productCode: t.string,
                riskCessDate: t.string,
                benefitOption: t.string
              })
            )
          })
        })
      )({
        body: {
          policies: [policyNum]
        }
      }),
      ZIO.map((response) => {
        const EUS3 = response.body.policyExtraInfoDetails.find((_) => _.productCode == 'EUS3')
        return (
          moment().diff(moment(EUS3?.riskCessDate).subtract(EUS3?.benefitOption === 'UNI' ? 5 : 7, 'years'), 'months') <
          0
        )
      })
    )
  }

  // const getAgeOfMainLifeAssured = (policyNum: string) =>
  //   pipe(
  //     SubmissionService.getPolicies([
  //       {
  //         policyNo: policyNum,
  //         function: 'OPTIONSW'
  //       }
  //     ]),
  //     ZIO.map((res) => ({
  //       ageOfMainLifeAssured: Number(res[0].proposalReceivedDate || 0)
  //     }))
  //   )

  export const getDataAccessCheck = (policyNum: string) => {
    return pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policyInfo) =>
        pipe(
          policyInfo.body.mainLifeAssuredNumber,
          O.fromNullable,
          O.filter((mainLifeAssuredNum) => mainLifeAssuredNum.length > 0),
          O.fold(
            () => ZIO.succeed(<Customer>{}),
            (clientId) => SubmissionService.getCustomer(clientId)
          ),
          ZIO.map((customerInfo) => {
            const dobVal = moment(customerInfo.body.dob)
            const ageOfLA = moment().diff(dobVal, 'years')
            return {
              converage: policyInfo.body.basicCode,
              contractStatus: policyInfo.body.status,
              ageOfMainLifeAssured: ageOfLA
            }
          })
        )
      ),
      ZIO.flatMap((response) => {
        return response.converage === 'ULR6'
          ? pipe(
              getDetail(policyNum),
              ZIO.map((data) => ({
                converage: response.converage,
                contractStatus: response.contractStatus,
                ageOfMainLifeAssured: response.ageOfMainLifeAssured,
                benefitOption: data.benefitOption
              }))
            )
          : ZIO.succeed({
              converage: response.converage,
              contractStatus: response.contractStatus,
              ageOfMainLifeAssured: response.ageOfMainLifeAssured,
              benefitOption: ''
            })
      })
    )
  }

  export const getNewSumAssured = (policyNumber: string, benefitOption: string) =>
    pipe(
      POApi.post(`wf-api/policy/product-option-switching/change-benefit-option`)(
        t.type({
          body: t.type({
            newSumAssured: Maybe(t.number),
            installmentPremium: Maybe(t.number)
          })
        })
      )({
        body: {
          policyNumber: policyNumber,
          benefitOption: benefitOption
        }
      }),
      ZIO.map((responseData) => {
        const data = responseData.body
        return {
          newSumAssured: data.newSumAssured ?? 0,
          newInstallmentPremium: data.installmentPremium ?? 0
        }
      })
    )
}
