import { Link } from '@material-ui/core'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  ImgUploadMutiple,
  Title,
  RiderAlterationData,
  RiderAlterationService,
  AddNewLAModal,
  LifeAssuredTableMJ,
  AlterRiderTableMJ,
  SubmissionService,
  CancelRider,
  RiderAlterationFormData,
  MajorAddNewLAModal
} from '@pulseops/common'
import React from 'react'
import { Controller, FieldArrayWithId, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { MajorCombinedForm } from '../major-combined-form'
import { Error, SectionContent } from '@pulseops/submission/common'
// import { AddNewLAModal } from './add-newLA-modal'
import { useIsFocused } from '@react-navigation/native'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'

interface Props {
  officeCode: string
  policyNum: string
  isConfirmed: boolean
  validateBeforeContinue: ({ validateRiderAlteration }: { validateRiderAlteration: () => Promise<boolean> }) => void
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}

export const RiderAlterationComponent: React.FC<Props> = ({
  policyNum,
  isConfirmed,
  form,
  validateBeforeContinue,
  officeCode
}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isShowedNewLA, setIsShowedNewLA] = React.useState<boolean>(false)
  const [newLAInfoIndex, setNewLAInfoIndex] = React.useState<number>(-1)
  const [waiverProductList, setWaiverProductList] = React.useState<string[]>([])
  const [classList, setClassList] = React.useState<RiderAlterationService.ClassItem[]>([])
  const [policyInfoData, setPolicyInfoData] = React.useState<RiderAlterationData.PolicyInfoData>()

  const LAFieldList = useFieldArray({
    control: form.control,
    name: 'riderAlteration.LARiderAlterationList'
  })

  const NewRiderLAList = useFieldArray({
    control: form.control,
    name: 'riderAlteration.newRiderLAList'
  })

  React.useEffect(() => {
    pipe(
      ZIO.zipPar(
        RiderAlterationService.getDetail(policyNum),
        getPolicyInfo(policyNum),
        RiderAlterationService.getWaiverProducts('TR517', ''),
        RiderAlterationService.getClassList('TV948')
      ),
      ZIO.flatMap(([responseData, policyInfo, waiverList, classArray]) => {
        setWaiverProductList(waiverList)
        setPolicyInfoData(policyInfo)
        setClassList(classArray)
        return ZIO.fromPromise(() => initData(responseData, waiverList, classArray))
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }, [policyNum])

  const initData = (
    responseData: CancelRider.DetailCancelRiderInfo,
    waiverList: string[],
    classArray: RiderAlterationService.ClassItem[]
    // riderList: SelectOption[]
  ) => {
    let riderFormArray: Array<RiderAlterationFormData.CurrentRiderForm> = []
    const LARiderItemList = responseData.laRiderInfo
    return pipe(
      LARiderItemList,
      A.map((lifeAssuredItem) => RiderAlterationService.getRiderList(policyNum, lifeAssuredItem.lifeNo ?? '')),
      ZIO.sequence,
      ZIO.map((riderNameArr) => {
        const lifeAssuredArr = LARiderItemList.map((item) => {
          const alteredRiderList = riderNameArr.find((x) => x.lifeNo === item.lifeNo)?.riderArr ?? []
          return {
            ...item,
            alteredRiderList: alteredRiderList
          }
        })
        return lifeAssuredArr
      }),
      ZIO.map((lifeAssuredList) => {
        lifeAssuredList.map((LAItem) => {
          LAItem.riders.map((rider) => {
            const riderInfoItem = responseData.riderNameList.find((x) => x.code === rider.productCode)
            rider.productName = getRiderName(riderInfoItem, i18n.language)
            const isWaiverProduct = waiverList.some((waiverItem) => waiverItem === rider.productCode)
            const classNumberList = getArrayFromUndefinedItem(classArray, rider.productCode)
            const isClassNumber = isWaiverProduct || classNumberList.length <= 0 ? false : true
            riderFormArray.push({
              riderCode: rider.productCode,
              riderName: rider.productName,
              isWaiverProduct: isWaiverProduct,
              isClassNumber: isClassNumber,
              classNumberList: isClassNumber ? classNumberList : [],
              // SelectRowForRider: false,
              riskCommDate: rider.riskCommDate,
              riskCessDate: rider.riskCessDate,
              sumAssured: rider.sumAssured,
              alteredSumAssured: '',
              installmentPrem: rider.installmentPrem,
              newInstallmentPremium: 0,
              lifeNo: rider.lifeNo,
              coverageNo: rider.coverageNo,
              riderNo: rider.riderNo,
              riderStatus: rider.riderStatus
            })
          })

          LAFieldList.append({
            // selectAllForLA: false,
            LAName: LAItem.clientName,
            lifeAssured: LAItem.lifeAssured,
            currentRiders: riderFormArray
          })
          //set default value for LANewRiderList
          const alteredRiderList = LAItem.alteredRiderList
            .filter((x) => !LAItem.riders.some((item) => item.productCode === x.code))
            .filter((riderInfo) => !LAItem.extraRider.some((rider) => rider.productCode === riderInfo.code))
          NewRiderLAList.append({
            LAName: LAItem.clientName,
            lifeAssured: LAItem.lifeAssured,
            isNewLA: false,
            newRiderList: [],
            lifeNo: LAItem.lifeNo ?? '',
            alteredRiderList: alteredRiderList
          })
          riderFormArray = []
        })
        form.setValue('riderAlteration.totalPremium', responseData.totalPremium)
        return lifeAssuredList
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const getArrayFromUndefinedItem = (array: RiderAlterationService.ClassItem[], riderCode: string) => {
    return pipe(
      array.find((x) => x.riderCode === riderCode)?.classList,
      O.fromNullable,
      O.map((item) => item),
      O.getOrElse(() => [] as string[])
    )
  }

  React.useEffect(() => {
    return () => {
      form.setValue('riderAlteration', {
        LARiderAlterationList: [],
        newRiderLAList: [],
        healthDocuments: [],
        isAddRider: false,
        isRiderAlteration: false,
        requiredPayinAmount: '',
        totalPremium: 0
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getPolicyInfo = (policyNum: string) => {
    return pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((response) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getCustomer(response.body.clientDespatchAddress ?? ''),
            SubmissionService.getOwnerInfo(policyNum)
          ),
          ZIO.map(([customerInfo, owner]) => {
            return {
              customerId: response.body?.owners.id ?? '',
              poName: owner.body.name,
              secuityNo: customerInfo.body.externalIds.SOE_VALUE ?? '',
              dob: customerInfo.body.dob ?? '',
              gender: customerInfo.body.sex ?? ''
            }
          })
        )
      )
    )
  }

  const getRiderName = (item: any, isEN = 'en') => {
    return !!item ? (isEN === 'en' ? item.name : item.nameVi) : ''
  }

  const openInsertLAModal = () => {
    setNewLAInfoIndex(-1)
    setIsShowedNewLA(true)
  }
  const closeAddLAModal = () => {
    setIsShowedNewLA(false)
    // setIsViewLADetail((oldValue) => !oldValue)
  }

  const addNewRiderForExistedLA = (
    index: number,
    fieldItem: FieldArrayWithId<MajorCombinedForm.MajorCombined, 'riderAlteration.newRiderLAList', 'id'>
  ) => {
    const newRiderList = [
      ...fieldItem.newRiderList,
      {
        riderName: { label: '', value: '' },
        sumAssured: '',
        policyPeriod: '',
        isWaiverProduct: false,
        isClassNumber: false,
        classNumberList: []
      }
    ]
    NewRiderLAList.update(index, {
      LAName: fieldItem.LAName,
      lifeAssured: fieldItem.lifeAssured,
      isNewLA: fieldItem.isNewLA,
      newRiderList: newRiderList,
      newLAInfo: fieldItem.newLAInfo,
      lifeNo: fieldItem.lifeNo,
      alteredRiderList: fieldItem.alteredRiderList
    })
  }

  const removeRiderForExistedLA = (
    index: number,
    subIndex: number,
    fieldItem: FieldArrayWithId<MajorCombinedForm.MajorCombined, 'riderAlteration.newRiderLAList', 'id'>
  ) => {
    // remove item in array with index
    // remove item in array with index
    // const filterList = fieldItem.newRiderList.filter((item, index) => index !== subIndex)
    let filterList = form.watch(`riderAlteration.newRiderLAList.${index}.newRiderList`)
    filterList.splice(subIndex, 1)
    // console.log('filterList:',filterList)
    form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList`,[])
    NewRiderLAList.update(index, {
      LAName: fieldItem.LAName,
      lifeAssured: fieldItem.lifeAssured,
      isNewLA: fieldItem.isNewLA,
      newLAInfo: fieldItem.newLAInfo,
      lifeNo: fieldItem.lifeNo,
      alteredRiderList: fieldItem.alteredRiderList,
      newRiderList: [],
    })
    form.setValue(`riderAlteration.newRiderLAList.${index}.alteredRiderList`,fieldItem.alteredRiderList)
    form.setValue(`riderAlteration.newRiderLAList.${index}.LAName`,fieldItem.LAName)
    form.setValue(`riderAlteration.newRiderLAList.${index}.lifeAssured`,fieldItem.lifeAssured)
    form.setValue(`riderAlteration.newRiderLAList.${index}.isNewLA`,fieldItem.isNewLA)
    form.setValue(`riderAlteration.newRiderLAList.${index}.newLAInfo`,fieldItem.newLAInfo)
    form.setValue(`riderAlteration.newRiderLAList.${index}.lifeNo`,fieldItem.lifeNo)
    form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList`,filterList)
    form.watch(`riderAlteration.newRiderLAList.${index}.newRiderList`).forEach((riderItem,riderIndex)=> {
      if(riderIndex < filterList.length){
        form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.riderName`,riderItem.riderName)
        form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.isWaiverProduct`,riderItem.isWaiverProduct)
        form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.policyPeriod`,riderItem.policyPeriod)
        form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.sumAssured`,riderItem.sumAssured)
        form.setValue(
          `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.isClassNumber`,
          riderItem.isClassNumber
        )
        form.setValue(
          `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.classNumberList`,
          riderItem.classNumberList
        )
      }
    })
  }

  const addNewRiderLA = (newItem: RiderAlterationFormData.NewLAInfo) => {
    pipe(
      ZIO.effect(() => {
        const lifeNumber = form.watch('riderAlteration.newRiderLAList').reduce((oldLifeNo, item) => {
          const lifeNo = Number(item.lifeNo ?? '0')
          if (oldLifeNo <= lifeNo) {
            return lifeNo
          } else {
            return oldLifeNo
          }
        }, 0)
        const lifeNo = '0' + (lifeNumber + 1).toString()
        return lifeNo
      }),
      ZIO.flatMap((lifeNum) => RiderAlterationService.getRiderList(policyNum, lifeNum)),
      ZIO.map((riderResponse) => {
        const LAName = newItem.surname + ' ' + newItem.firstName
        const alteredRiderList = riderResponse.riderArr
        const newLAItem: RiderAlterationFormData.NewRiderLAData = {
          LAName: LAName,
          lifeAssured: '',
          isNewLA: true,
          newRiderList: [],
          newLAInfo: newItem,
          lifeNo: riderResponse.lifeNo,
          alteredRiderList: alteredRiderList
        }
        NewRiderLAList.append(newLAItem)
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const updateNewLifeAssuresAfterDelete = (
    newItem: RiderAlterationFormData.NewRiderLAData,
    index: number,
    lifeNo: string
  ) => {
    return pipe(
      ZIO.succeed(lifeNo),
      ZIO.flatMap((lifeNum) => RiderAlterationService.getRiderList(policyNum, lifeNum)),
      ZIO.map((riderResponse) => {
        const alteredRiderList = riderResponse.riderArr
        const newRiderList = newItem.newRiderList
        console.log('newRiderList:' + newItem.newRiderList)
        form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList`, [])
        NewRiderLAList.update(index, {
          LAName: newItem.LAName,
          lifeAssured: newItem.lifeAssured,
          isNewLA: newItem.isNewLA,
          newLAInfo: newItem.newLAInfo,
          lifeNo: lifeNo,
          alteredRiderList: alteredRiderList,
          newRiderList: newRiderList
        })
        form.setValue(`riderAlteration.newRiderLAList.${index}.alteredRiderList`, alteredRiderList)
        form.setValue(`riderAlteration.newRiderLAList.${index}.LAName`, newItem.LAName)
        form.setValue(`riderAlteration.newRiderLAList.${index}.lifeAssured`, newItem.lifeAssured)
        form.setValue(`riderAlteration.newRiderLAList.${index}.isNewLA`, newItem.isNewLA)
        form.setValue(`riderAlteration.newRiderLAList.${index}.newLAInfo`, newItem.newLAInfo)
        form.setValue(`riderAlteration.newRiderLAList.${index}.lifeNo`, lifeNo)
        form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList`, newRiderList)
        form.watch(`riderAlteration.newRiderLAList.${index}.newRiderList`).forEach((riderItem, riderIndex) => {
          if (riderIndex < newRiderList.length) {
            form.setValue(
              `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.riderName`,
              riderItem.riderName
            )
            form.setValue(
              `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.isWaiverProduct`,
              riderItem.isWaiverProduct
            )
            form.setValue(
              `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.policyPeriod`,
              riderItem.policyPeriod
            )
            form.setValue(
              `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.sumAssured`,
              riderItem.sumAssured
            )
            form.setValue(
              `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.isClassNumber`,
              riderItem.isClassNumber
            )
            form.setValue(
              `riderAlteration.newRiderLAList.${index}.newRiderList.${riderIndex}.classNumberList`,
              riderItem.classNumberList
            )
          }
        })
      })
    )
  }

  const onChangeNewRider = (index: number, subIndex: number, riderCode: string) => {
    const isWaiverProduct = waiverProductList.some((item) => item === riderCode)
    const classNumberList = getArrayFromUndefinedItem(classList, riderCode)
    const isClassNumber = isWaiverProduct || classNumberList.length <= 0 ? false : true
    form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${subIndex}.sumAssured`, '')
    form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${subIndex}.isWaiverProduct`, isWaiverProduct)
    form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${subIndex}.isClassNumber`, isClassNumber)
    form.setValue(`riderAlteration.newRiderLAList.${index}.newRiderList.${subIndex}.classNumberList`, classNumberList)
  }

  const removeNewLA = (index: number) => {
    NewRiderLAList.remove(index)
    const newRiderLAList = form.watch(`riderAlteration.newRiderLAList`)
    pipe(
      newRiderLAList,
      A.map((LAItem) => {
        if (LAItem.isNewLA) {
          const index = newRiderLAList.indexOf(LAItem)
          const highestOldNum = form.watch('riderAlteration.newRiderLAList').reduce((oldLifeNo, item) => {
            const lifeNo = Number(item.lifeNo ?? '0')
            return oldLifeNo <= lifeNo && !item.isNewLA ? lifeNo : oldLifeNo
          }, 0)
          const lifeNo = '0' + (highestOldNum + 1).toString()
          console.log('index1: ' + index)
          console.log('lifeNo1: ' + lifeNo)
          return updateNewLifeAssuresAfterDelete(LAItem, index, lifeNo)
        } else {
          return ZIO.unit
        }
      }),
      ZIO.sequence,
      bindLoader,
      ErrorHandling.run()
    )
  }

  const viewLADetail = (index: number) => {
    setNewLAInfoIndex(index)
    // setIsViewLADetail(true)
    setIsShowedNewLA(true)
  }

  const openInsertedNewLAPopUp = () => {
    const submissionDetailData = form.watch(`riderAlteration.newRiderLAList.${newLAInfoIndex}.newLAInfo`)
    const isDetailView = newLAInfoIndex >= 0 ? true : false
    // console.log('viewItem', submissionDetailData, isShowedNewLA)
    return isShowedNewLA ? (
      <MajorAddNewLAModal
        title={t('RiderAlteration:NewLAInformation')}
        isVisible={isShowedNewLA}
        isConfirmed={isConfirmed || isDetailView}
        onClose={closeAddLAModal}
        isDetailView={isDetailView}
        detailIndex={newLAInfoIndex}
        policyInfoData={policyInfoData}
        // NewRiderLAList={NewRiderLAList}
        onSuccess={addNewRiderLA}
        submissionDetailData={submissionDetailData}
      />
    ) : (
      <></>
    )
  }

  return (
    <View style={riderStyles.container}>
      <Title title={t('requestInfo:RequestDetail')} wrapperStyle={riderStyles.titleText}></Title>
      <Controller
        control={form.control}
        name={'riderAlteration.isRiderAlteration'}
        render={({ field: { value, onChange, onBlur } }) => (
          <Checkbox
            title={t('RiderAlteration:RiderAlteration')}
            onChange={(checked) => {
              onChange(checked)
              // changeEventForLA(checked, index, fieldItem)
            }}
            onBlur={onBlur}
            value={value}
            disabled={isConfirmed}
            checkBoxStyle={riderStyles.tableCheckBox}
          />
        )}
      />
      {form.watch('riderAlteration.isRiderAlteration') && (
        <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
          <LifeAssuredTableMJ form={form} LAFieldList={LAFieldList} isConfirmed={isConfirmed} />
        </SectionContent>
      )}
      <View style={riderStyles.secondLine}>
        <Controller
          control={form.control}
          name={'riderAlteration.isAddRider'}
          render={({ field: { value, onChange, onBlur } }) => (
            <Checkbox
              title={t('RiderAlteration:AddRider')}
              onChange={(checked) => {
                onChange(checked)
                // changeEventForLA(checked, index, fieldItem)
              }}
              onBlur={onBlur}
              value={value}
              disabled={isConfirmed}
              checkBoxStyle={riderStyles.tableCheckBox}
            />
          )}
        />
      </View>
      {form.watch('riderAlteration.isAddRider') && (
        <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
          <AlterRiderTableMJ
            // newRiderList={newRiderList}
            form={form}
            NewRiderLAList={NewRiderLAList}
            disabled={isConfirmed}
            addNewRider={addNewRiderForExistedLA}
            removeRider={removeRiderForExistedLA}
            removeNewLA={removeNewLA}
            viewLADetail={viewLADetail}
            onChangeRider={onChangeNewRider}
          />
          {NewRiderLAList.fields.length < 4 && !isConfirmed && (
            <View style={riderStyles.btnNewLA}>
              <TouchableOpacity onPress={openInsertLAModal}>
                <View style={riderStyles.btnAdd}>
                  <Text style={riderStyles.btn_text}>{t('RiderAlteration:AddNewLA')}</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </SectionContent>
      )}

      <Title wrapperStyle={riderStyles.secondLine} title={t('submission:StatementOfInsurability')}></Title>
      <View style={{ flex: 1 }}>
        <Text style={riderStyles.field_title}>
          {t('submission:HealthNote')}
          <Link href={`${assets.UpdatedRedatingHealthyTemplate}`} onKeyPress={() => {}} target="_parent" download>
            <Text style={riderStyles.underlineLink}>{t('submission:Here')}</Text>
          </Link>
        </Text>
        <Text style={riderStyles.field_title}>
          {t('submission:HealthDeclaration')} <Text style={riderStyles.redLine}> *</Text>
        </Text>
        <Controller
          control={form.control}
          name={'riderAlteration.healthDocuments'}
          rules={{
            required: {
              value: true,
              message: t('message:MS050232')
            }
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <>
              <ImgUploadMutiple
                timeFormat={'DD/MM/YYYY HH:mm'}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isConfirmed}
              />
              {error?.message && <Error message={value && value.length > 0 ? '' : error.message} />}
            </>
          )}
        />
      </View>
      {openInsertedNewLAPopUp()}
    </View>
  )
}

const riderStyles = StyleSheet.create({
  container: {
    marginBottom: 10,
    flex: 1
  },
  titleText: {
    marginTop: 10
  },
  currencyContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  currentText: {
    fontStyle: 'italic'
  },
  tableHeaderText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 13,
    color: '#70777E'
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  },
  btnContent: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  btnNewLA: {
    // marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btnAdd: {
    minHeight: 40,
    width: '100%',
    borderRadius: 100,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderColor: '#ED1B2E',
    borderWidth: 1
    // backgroundColor: '#ED1B2E'
  },
  btn_text: {
    fontSize: 15,
    lineHeight: 20,
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  secondBtn: {
    marginLeft: 27
  },
  underlineLink: {
    textDecorationLine: 'underline'
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  panelContent: {
    backgroundColor: '#fff',
    marginTop: 20,
    borderWidth: 0,
    borderColor: '#fff'
  },
  secondLine: {
    marginTop: 20
  },
  redLine: {
    color: '#ed1b2c',
    fontSize: 15,
    fontWeight: 'bold'
  }
})
