import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace OtherPayPremiumForm {
  const OtherPayPremiumForm = t.type({
    formId: form2.string.required,
    amount: form2.string.required,
    minTopUp: form2.string.required,
    maxTopUp: form2.string.required
  })

  export const codec = t.type({
    list: t.array(OtherPayPremiumForm)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
