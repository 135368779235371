import { ZIO } from '@mxt/zio'
import { AmlHistory, ContractDetail, Form, FormService, Input, SectionHeader } from '@pulseops/business/core'
import { Format } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const ContractInfoView = ({ info }: { info: ContractDetail }) => {
  const { t } = useTranslation('business')

  const { policyHolder, legalRepresentative, policyAdmin } = info
  const {
    getCountryOptions,
    getProvinceOptions,
    getGenderName,
    getCountryName,
    getYesNoName,
    getProvinceName,
    getDistrictOptionsFull,
    getDistrictName,
    getWardOptionsFull,
    getWardName
  } = FormService

  const [viewData, setViewData] = React.useState({
    gender: '',

    businessCountry: '',
    businessCity: '',
    businessDistrict: '',
    businessWard: '',

    contactCountry: '',
    contactCity: '',
    contactDistrict: '',
    contactWard: '',

    permanentCountry: '',
    permanentCity: '',
    permanentDistrict: '',
    permanentWard: '',

    residentCountry: '',
    residentCity: '',
    residentDistrict: '',
    residentWard: '',

    overseaCountry: '',
    overseaCity: '',
    overseaDistrict: '',
    overseaWard: '',

    nationality: '',
    otherNationality: '',
    holdClaimsBenefitPayout: '',
    hasstockexchange: '',
    hasusshareholder: ''
  })

  const updateViewData = pipe(
    ZIO.zipPar(getCountryOptions, getProvinceOptions, getDistrictOptionsFull, getWardOptionsFull),
    ZIO.tap(([countryOptions, provinceOptions, districtOptionsFull, wardOptionsFull]) => {
      setViewData({
        gender: getGenderName(legalRepresentative.gender),

        businessCountry: getCountryName(policyHolder.businessAddress.country, countryOptions),
        businessCity: getProvinceName(policyHolder.businessAddress.city, provinceOptions),
        businessDistrict: getDistrictName(
          policyHolder.businessAddress.district,
          policyHolder.businessAddress.city,
          districtOptionsFull
        ),
        businessWard: getWardName(policyHolder.businessAddress.ward, wardOptionsFull),

        contactCountry: getCountryName(policyHolder.contactAddress.country, countryOptions),
        contactCity: getProvinceName(policyHolder.contactAddress.city, provinceOptions),
        contactDistrict: getDistrictName(
          policyHolder.contactAddress.district,
          policyHolder.contactAddress.city,
          districtOptionsFull
        ),
        contactWard: getWardName(policyHolder.contactAddress.ward, wardOptionsFull),

        permanentCountry: getCountryName(legalRepresentative.country, countryOptions),
        permanentCity: getProvinceName(legalRepresentative.permanentCity, provinceOptions),
        permanentDistrict: getDistrictName(
          legalRepresentative.permanentDistrict,
          legalRepresentative.permanentCity,
          districtOptionsFull
        ),
        permanentWard: getWardName(legalRepresentative.permanentWard, wardOptionsFull),

        residentCountry: getCountryName(legalRepresentative.country1, countryOptions),
        residentCity: getProvinceName(legalRepresentative.residentialCity, provinceOptions),
        residentDistrict: getDistrictName(
          legalRepresentative.residentialDistrict,
          legalRepresentative.residentialCity,
          districtOptionsFull
        ),
        residentWard: getWardName(legalRepresentative.residentialWard, wardOptionsFull),

        overseaCountry: getCountryName(legalRepresentative.country2, countryOptions),
        overseaCity: getProvinceName(legalRepresentative.overseaCity, provinceOptions),
        overseaDistrict: getDistrictName(
          legalRepresentative.overseaDistrict,
          legalRepresentative.overseaCity,
          districtOptionsFull
        ),
        overseaWard: getWardName(legalRepresentative.overseaWard, wardOptionsFull),

        nationality: getCountryName(legalRepresentative.nationality, countryOptions),
        otherNationality: getCountryName(legalRepresentative.otherNationality, countryOptions),
        holdClaimsBenefitPayout: getYesNoName(info.holdClaimsBenefitPayout),
        hasstockexchange: getYesNoName(legalRepresentative.hasstockexchange),
        hasusshareholder: getYesNoName(legalRepresentative.hasusshareholder)
      })
      return ZIO.unit
    })
  )

  React.useEffect(() => {
    pipe(updateViewData, ZIO.unsafeRun({}))
  }, [info])

  return (
    <>
      <Form.Group>
        <SectionHeader>{t('general_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('proposal_no')} value={info.masterContractNo} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('proposal_submitted_date')} value={Format.date(info.submitDate)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('policy_year')} value={info.policyYear.toString()} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('first_if_date')} value={Format.date(info.firstPolicyEffectiveDate)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('contract_term')} value={info.policyTerm.toString()} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('exclusion_term')} value={info.exclusionTerm || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('hold_claim_payout')} value={viewData.holdClaimsBenefitPayout} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('premium_payment_status')} value={info.premiumPaymentStatus || '-'} readonly />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('policy_owner_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('company_org_name')} value={policyHolder.policyHolder} readonly />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address')} value={policyHolder.businessAddress.address} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_country')} value={viewData.businessCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.businessCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.businessDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.businessWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('phone_number')} value={policyHolder.businessAddress.phoneNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('email')} value={policyHolder.businessAddress.email} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('contact_address')} value={policyHolder.contactAddress.address} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('contact_address_country')} value={viewData.contactCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.contactCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.contactDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.contactWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('phone_number')} value={policyHolder.contactAddress.phoneNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('email')} value={policyHolder.contactAddress.email} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_sector')} value={policyHolder.businessSector} readonly />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_license')} value={policyHolder.businessLicense} readonly />
          </Form.Col>
          <Form.Col>
            <Input
              label={t('business_registration_date')}
              value={Format.date(policyHolder.dateOfRegistration)}
              readonly
            />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_registration_place')} value={policyHolder.placeOfRegistration} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('authorized_capital')} value={Format.currencyForBusiness(policyHolder.capital)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('tax_code')} value={policyHolder.taxCode} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('entity_type')} value={policyHolder.entityType} readonly />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('legal_rep')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('full_name')} value={legalRepresentative.fullName} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('position')} value={legalRepresentative.position} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('id_card_no')} value={legalRepresentative.idNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('issue_date')} value={Format.date(legalRepresentative.dateOfIssue)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('issue_place')} value={legalRepresentative.placeOfIssue} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('dob')} value={Format.date(legalRepresentative.dateOfBirth)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('place_of_birth')} value={legalRepresentative.placeOfBirth} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('gender')} value={viewData.gender} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('nationality')} value={viewData.nationality} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('other_nationality')} value={viewData.otherNationality} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('visa')} value={legalRepresentative.visa || '-'} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('phone_number')} value={legalRepresentative.phoneNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('email')} value={legalRepresentative.email} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('perm_address')} value={legalRepresentative.permanentAddress} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('perm_address_country')} value={viewData.permanentCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.permanentCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.permanentDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.permanentWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('curr_address')} value={legalRepresentative.residentAddress} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('curr_address_country')} value={viewData.residentCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.residentCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.residentDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.residentWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('oversea_address')} value={legalRepresentative.overseaAddress || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('oversea_address_country')} value={viewData.overseaCountry} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('business_address_province')} value={viewData.overseaCity} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_district')} value={viewData.overseaDistrict} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('business_address_ward')} value={viewData.overseaWard} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('has_stock')} value={viewData.hasstockexchange} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('has_us_shareholder')} value={viewData.hasusshareholder} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <AmlHistory history={legalRepresentative.historyAmlResult} />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('contact_rep')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('full_name')} value={policyAdmin.fullName} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('position')} value={policyAdmin.position} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('id_card_no')} value={policyAdmin.idNumber} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('phone_number')} value={policyAdmin.phoneNumber} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('email')} value={policyAdmin.email} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <AmlHistory history={policyAdmin.historyAmlResult} />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('la_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('employee_count')} value={Format.number(info.totalEmployees)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('employee_la_count')} value={Format.number(info.totalEmployeesInsured)} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('group_policy_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('proposed_if_date')} value={Format.date(info.proposedEffectiveDate)} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('payment_period')} value={info.modeOfPayment} readonly />
          </Form.Col>
          <Form.Col>
            <Input
              label={t('total_annual_premium')}
              value={Format.currencyForBusiness(info.totalAnnualPremium)}
              readonly
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('discount')} value={info.discountPromotion || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('net_premium')} value={Format.currencyForBusiness(info.netAnnualPremium)} readonly />
          </Form.Col>
          <Form.Col>
            <Input
              label={t('insufficient_premium')}
              value={Format.currencyForBusiness(info.insufficientPremium)}
              readonly
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('curr_policy_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('curr_insurance_company')} value={info.previousCurrentInsurerName || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('policy_no')} value={info.groupPolicyNumber || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input
              label={t('if_date')}
              value={info.groupPolicyEffectiveDate ? Format.date(info.groupPolicyEffectiveDate) : '-'}
              readonly
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('bank_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('bank_name')} value={info.bankName || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('branch_name')} value={info.bankBranch || '-'} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('account_no')} value={info.accountNumber || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('account_holder')} value={info.accountName || '-'} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('other_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('created_date')} value={Format.date(info.establishedDate)} readonly />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('agent_code_1')} value={info.agentNumber1} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('agent_name_1')} value={info.agentName1} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('comm_ratio')} value={info.commissionRate1} readonly />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('agent_code_2')} value={info.agentNumber2 || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('agent_name_2')} value={info.agentName2 || '-'} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('comm_ratio')} value={info.commissionRate2 || '-'} readonly />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <AmlHistory title={t('aml_history_company')} history={info.historyAmlResult} />
    </>
  )
}
