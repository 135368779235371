import React from 'react'
import { TextInput, View, Text } from 'react-native'

interface Props {
  value: string
  onChange: (val: string) => void
  errorMessage?: string
  disabled?: boolean
  maxLength?: number
}
export const InputTable: React.FC<Props> = ({ value, onChange, errorMessage, disabled, maxLength }) => {
  return (
    <View>
      <TextInput
        value={value}
        onChangeText={(val) => onChange(val)}
        underlineColorAndroid="transparent"
        style={{ borderColor: '#BABABA', backgroundColor: '#FFF', borderRadius: 8, borderWidth: 1, padding: 10 }}
        editable={disabled !== true}
        maxLength={maxLength}
      />
      {errorMessage && <Text style={{ color: '#ED2B1C' }}>{errorMessage}</Text>}
    </View>
  )
}
