import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as E from 'fp-ts/Either'
import * as D from 'date-fns'

const format = 'yyyy-MM-dd'

export const WfDate = new t.Type<Date, string, unknown>(
  'WfDate',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      E.chain((s) => {
        try {
          const d = D.parse(s, format, new Date())
          return isNaN(d.getTime()) ? t.failure(u, c) : t.success(d)
        } catch (e) {
          return t.failure(u, c)
        }
      })
    ),
  (a) => D.format(a, format)
)
