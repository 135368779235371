import React from 'react'
import { TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { InputTable } from '@pulseops/common'
import _ from 'lodash'
import { RuleItem } from './da-rule-item'

export interface headerItem {
  title: string
  name: string
  maxL?: number
}
interface Props {
  headerList: headerItem[]
  onFilter?: (value: RuleItem) => void
}

export const HeaderTable = ({ headerList, onFilter }: Props) => {
  const [filterName, setFilterName] = React.useState<RuleItem>({})

  const handleFilter = _.debounce((text: string, type: string) => {
    const arr = { ...filterName }
    const newOb = { ...arr, [type]: text }
    setFilterName(newOb)
    if (onFilter) {
      onFilter(newOb)
    }
  }, 500)

  const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    }
  })

  const classes = useStyles()
  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        <TableCell className={classes.tableCellHeader}></TableCell>
        {headerList &&
          headerList.map((item, index) => (
            <TableCell key={index} className={classes.tableCellHeader}>
              {item.title}
              {onFilter && (
                <InputTable
                  onChange={(e) => handleFilter(e.toUpperCase(), item.name)}
                  inputStyle={{ marginTop: 16 }}
                  maxLength={item.maxL}
                />
              )}
            </TableCell>
          ))}
        <TableCell className={classes.tableCellHeader}></TableCell>
      </TableRow>
    </TableHead>
  )
}
