import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  AppContext,
  ErrorHandling,
  formatNumberWithComma,
  FundMapping,
  GeneralService,
  getPayeeNotPOData,
  Panel,
  PaymentMethod,
  PayoutChecker,
  PayoutPopup,
  PulseOpsFormat,
  SourceType,
  TaskDetail,
  TaskDetailApi,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { InfoViewList } from '../common'
import { PayoutMethod } from './common'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF',
    '& >tr > td': {
      color: '#000000',
      fontWeight: 'bold'
    }
  }
})

type Output = {
  label: string
  value: string | number | JSX.Element
  suffix?: string
  isHighlight?: boolean
}

export const PartialWithDrawal = ({
  detail,
  isSeaBankShowedMessage
}: {
  detail: TaskDetail.PartialWithdrawal
  isSeaBankShowedMessage: boolean
}) => {
  const { t, i18n } = useTranslation(['requestInfo', 'fund'])
  const classes = useStyles()
  const { isWide } = useMobile()
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const { ulp, ilp, methods, totalPayoutAmount } = detail
  let dataPartialInfo: Output[] = []
  let payoutMethods: PaymentMethod[] | null = []

  payoutMethods = pipe(
    GeneralService.getPayoutMethods(),
    ZIO.map(({ body }) => {
      const translator = FundMapping.getPayoudMethod(body, i18n.language)
      return methods.map((x) => ({
        ...x,
        method: translator(x.method)
      }))
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (detail.sourceType === SourceType.PULSE4OPS && !!detail.seaBankWarningMsg) {
      showToast(detail.seaBankWarningMsg, 'warning', undefined, 30000)
    }
  }, [detail])

  if (ilp) {
    const { estimatedValue, minimumPartialWithdrawal, maximumPartialWithdrawal, partialWithdrawFee } = ilp
    dataPartialInfo = [
      {
        label: t('EstimatedValue'),
        value: formatNumberWithComma(estimatedValue || 0),
        suffix: 'VND'
      },
      {
        label: t('Minimumpartialwithdrawal'),
        value: formatNumberWithComma(minimumPartialWithdrawal || 0),
        suffix: 'VND'
      },
      {
        label: t('Maximumpartialwithdrawal'),
        value: formatNumberWithComma(maximumPartialWithdrawal || 0),
        suffix: 'VND'
      },
      {
        label: t('Withdrawfee'),
        value: formatNumberWithComma(partialWithdrawFee || 0),
        suffix: 'VND'
      }
    ]
  } else if (ulp) {
    const {
      estimatedValue,
      minimumPartialWithdrawal,
      maximumPartialWithdrawal,
      currentSumAssured,
      newSumAssured,
      partialWithdrawFee,
      withdrawAmount
    } = ulp
    dataPartialInfo = [
      {
        label: t('EstimatedValue'),
        value: formatNumberWithComma(estimatedValue || 0),
        suffix: 'VND'
      },
      {
        label: t('Minimumpartialwithdrawal'),
        value: formatNumberWithComma(minimumPartialWithdrawal || 0),
        suffix: 'VND'
      },
      {
        label: t('Maximumpartialwithdrawal'),
        value: formatNumberWithComma(maximumPartialWithdrawal || 0),
        suffix: 'VND'
      },
      {
        label: t('CurrentSumAssured'),
        value: formatNumberWithComma(currentSumAssured || 0),
        suffix: 'VND'
      },
      {
        label: t('NewSumAssured'),
        value: formatNumberWithComma(newSumAssured || 0),
        suffix: 'VND'
      },
      {
        label: t('PartialWithdrawfee'),
        value: formatNumberWithComma(partialWithdrawFee || 0),
        suffix: 'VND'
      },
      {
        label: t('WithdrawAmount'),
        value: formatNumberWithComma(withdrawAmount || 0),
        suffix: 'VND',
        isHighlight: true
      }
    ]
  }

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: formatNumberWithComma(totalPayoutAmount),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: formatNumberWithComma(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  return (
    <View>
      <Panel title={t('PartialWithDrawalInfo')} isExand={false}>
        <InfoViewList dataSource={dataPartialInfo} />
        <View>
          {ilp && (
            <>
              <Text style={styles.text}>{t('Fundlist')}</Text>
              <View style={styles.totalWithDraw}>
                <Text style={styles.text}>{t('TotalWithdrawalamount')}:</Text>
                <View>
                  <Text style={[styles.text, styles.isHighlight]}>
                    {formatNumberWithComma(ilp?.totalWithdrawAmount ?? 0) + ' VND'}
                  </Text>
                </View>
              </View>
            </>
          )}
          {isWide && Platform.OS === 'web' && ilp && (
            <View style={styles.tableWrapper}>
              <View style={styles.currency}>
                <Text style={[styles.text, styles.italic]}>{t('Currency') + 'VND'}</Text>
              </View>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>{t('Fund name')}</TableCell>
                      <TableCell>{t('MinP/Wremain')}</TableCell>
                      <TableCell>{t('Fundvalue')}</TableCell>
                      {<TableCell>{t('WithdrawAmount')}</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ilp?.FUND_LIST?.map((fund: any) => ({
                      fundName: `${fund.FUND_CODE} - ${t(FundMapping.getFunds(fund.FUND_CODE))}`,
                      minPWRemain: fund['MIN_P/W_REMAIN'],
                      fundValue: fund.FUND_VALUE,
                      withdrawalAmount: fund.WITHDRAW_AMOUNT
                    })).map((fund: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {fund?.fundName}
                        </TableCell>
                        <TableCell>{fund && formatNumberWithComma(fund.minPWRemain ?? 0)}</TableCell>
                        <TableCell>{formatNumberWithComma(fund?.fundValue ?? 0)}</TableCell>
                        <TableCell>{formatNumberWithComma(fund?.withdrawalAmount ?? 0)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          )}
        </View>
      </Panel>
      {/* <Panel title={t('PaymentInformation')} isExand={false}>
        <View>
          <View style={styles.totalWithDraw}>
            <Text style={styles.text}>{t('TotalPayoutAmount')}:</Text>
            <View>
              <Text style={[styles.text, styles.isHighlight]}>
                {formatNumberWithComma(totalPayoutAmount ?? 0) + ' VND'}
              </Text>
            </View>
          </View>
          {isWide && Platform.OS === 'web' && (
            <View style={styles.tableWrapper}>
              <View style={[styles.currency, styles.flexBetween]}>
                <Text style={styles.text}>{t('Payoutdetails')}</Text>
                <Text style={[styles.text, styles.italic]}>{t('Currency') + ' VND'}</Text>
              </View>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>{t('PayoutMethod')}</TableCell>
                      <TableCell>{t('Detail')}</TableCell>
                      <TableCell>{t('Amount')}</TableCell>
                      <TableCell>{t('Status')}</TableCell>
                      <TableCell>{t('Note')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payoutMethods?.map((method: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell>{method?.method}</TableCell>
                        <TableCell>{method?.detail}</TableCell>
                        <TableCell>{formatNumberWithComma(method?.amount ?? 0)}</TableCell>
                        <TableCell>{method?.status}</TableCell>
                        <TableCell>{method?.note}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          )}
        </View>
      </Panel> */}
      <PayoutMethod
        payeeData={viewPayeeData}
        payoutMethodData={payoutData}
        isFractionFormat={ilp ? true : false}
      ></PayoutMethod>
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginRight: 10
  },
  totalWithDraw: {
    flexDirection: 'row',
    marginTop: 10
  },
  isHighlight: {
    color: '#EF1C2E',
    fontWeight: 'bold'
  },
  tableWrapper: {
    flex: 1,
    marginTop: 16
  },
  currency: {
    width: '100%',
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flexBetween: {
    justifyContent: 'space-between'
  },
  italic: {
    fontStyle: 'italic'
  }
})
