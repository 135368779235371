import * as React from 'react'
import { withStyles } from '@material-ui/core'
import * as core from '@material-ui/core'
import { ControlProps } from '@pulseops/common'

type InputProps = ControlProps<string | null> & {
  label?: string
  value?: string | null
  placeholder?: string
  maxLength?: number
  disabled?: boolean
}

export const BorderInputCurrency = (props: InputProps) => {
  const value = (props.value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <core.FormControl error={!!props.errorMessage}>
      <SC.TextField
        label={props.label}
        placeholder={props.placeholder || 'Nhập...'}
        variant="outlined"
        value={value}
        onChange={(event) => {
          const targetValue = event.target.value.replace(/[^0-9]/g, '')
          const numberValue = targetValue ? (/^\d+$/.test(targetValue) ? Number(targetValue).toString() : '') : ''
          props.onChange && props.onChange(numberValue)
        }}
        type="text"
        inputProps={{
          ...(props.maxLength != null ? { maxLength: props.maxLength } : {})
        }}
        disabled={props.disabled}
      />
      {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
    </core.FormControl>
  )
}

const SC = {
  TextField: withStyles({
    root: {
      width: '100%',
      minWidth: '100px',
      '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 8,
        height: 40,
        padding: '5px 10px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        }
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 13,
        padding: 0
      }
    }
  })(core.TextField)
}
