import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View } from 'react-native'
import { InputTable, InputTag } from '@pulseops/common'
import { styles } from './styles'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './quill.custom.css'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center',
    paddingBottom: 0
  },
  dialogContent: {},
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 25,
    paddingBottom: 15
  }
})

export type EmailPopupProps = {
  onClose: (value: any) => void
  open: boolean
}

export const EmailPopup = (props: EmailPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, open } = props

  const handleClose = (props: EmailPopupProps) => {
    onClose(null)
  }

  const [receiver, setReceiver] = useState<string[]>([])
  const [cc, setCC] = useState<string[]>([])
  const [bcc, setBCC] = useState<string[]>([])
  const [emailTitle, setEmailTitle] = useState<string>('')
  const [emailContent, setEmailContent] = useState<string>('')

  const modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike'], [{ align: [] }], ['image']]
  }

  const reset = () => {
    // setReceiver([])
    // setCC([])
    // setBCC([])
    // setEmailTitle('')
    setEmailContent('')
  }

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ color: '#70777E', fontSize: 13, fontWeight: '700' }}>Gửi email</Text>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View>
          <InputTag
            label={'Người nhận'}
            value={receiver}
            onChange={(val) => {
              setReceiver(val)
            }}
          />
          <InputTag
            label={'CC'}
            value={cc}
            onChange={(val) => {
              setCC(val)
            }}
          />
          <InputTag
            label={'BCC'}
            value={bcc}
            onChange={(val) => {
              setBCC(val)
            }}
          />
          <InputTable placeholder={'Nhập tiêu đề...'} value={emailTitle} onChange={setEmailTitle}></InputTable>
          <ReactQuill
            value={emailContent}
            onChange={setEmailContent}
            style={{ marginTop: 10 }}
            modules={modules}
            placeholder={'Nhập nội dung...'}
          >
            <View style={{ height: 200, backgroundColor: '#F9F9F9', borderRadius: 8, marginTop: 10 }}></View>
          </ReactQuill>
          {/* <ReactQuill theme="snow"/> */}
        </View>
      </DialogContent>
      <DialogActions className={dialogActions}>
        <TouchableOpacity style={{ paddingHorizontal: 29, paddingVertical: 5.5 }} onPress={() => reset()}>
          <Text style={{ color: '#BABABA' }}>Xóa</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose(null)}>
          <Text style={{ color: '#FFF' }}>Gửi</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}
