/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import { View, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Color } from '@material-ui/lab'
import {
  Tab,
  Toast,
  TabList,
  useMobile,
  ErrorHandling,
  RBAC,
  GroupPolicyPermission,
  MenuPermissions,
  form2
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { SEAWithdrawUploadFileScreen } from './SEAWithdrawUploadFileScreen'
import { SEAWithdrawImportHistoryScreen } from './SEAWithdrawImportHistoryScreen'
import { SEAWithdrawImportHistoryDetailScreen } from './SEAWithdrawImportHistoryDetailScreen'
import { SEAWithdrawRequestScreen } from './SEAWithdrawRequestScreen'
import { StackScreenProps } from '@react-navigation/stack'
import { SEABillingChangeStackParamList } from '../SEABillingChangeParamList'
import { SEAWithdrawChangeForm } from './SEAWithdrawChangeForm'

type Props = StackScreenProps<SEABillingChangeStackParamList, 'SEAWithdrawScreen'>
export function SEAWithdrawScreen(props: Props) {
  const { t } = useTranslation('BillingChange')
  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const scrollViewRef = React.useRef<ScrollView>(null)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }
  const defaultValuesStructure: SEAWithdrawChangeForm.Raw = {
    checkDialog: false,
    fromDate: new Date(),
    toDate: new Date(),
    importedFileName: '',
    checkReset: false
  }
  const ImportFileHistoryFomrm = form2.useForm(SEAWithdrawChangeForm.codec, {
    defaultValues: defaultValuesStructure
  })
  const tabs = React.useMemo(() => {
    const hasTab = []
    permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu) &&
      hasTab.push({ id: 0, title: t('UploadFile') })
    permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu) &&
      hasTab.push({ id: 1, title: t('ImportFileHistory') })
    permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu) &&
      hasTab.push({ id: 2, title: t('ImportFileHistoryDetail') })
    permissions.includes(GroupPolicyPermission.ViewImportFileForPartialWithdrawMenu) &&
      hasTab.push({ id: 3, title: t('RequestFormHistory') })
    return hasTab
  }, [permissions, t])

  React.useEffect(() => {
    tabs && setSubTabIndex(tabs[0]?.id)
  }, [tabs])
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: useMobile().isMobile ? '#E5E5E5' : '#F9F9F9',
        paddingTop: 16
      }}
    >
      <ScrollView ref={scrollViewRef}>
        <View style={{ flex: 1 }}>
          <View style={{ marginHorizontal: 15 }}>
            <TabList
              isScrollIcon={false}
              menus={tabs}
              isSubTab={false}
              useOriginalId={true}
              tabIndex={subTabIndex}
              onChangeTab={(index) => {
                setSubTabIndex(index)
              }}
            />
            <View>
              {subTabIndex === 0 && hasTab(0, tabs) && (
                <SEAWithdrawUploadFileScreen type={props.route.params.type} form={ImportFileHistoryFomrm.base} />
              )}
              {subTabIndex === 1 && hasTab(1, tabs) && (
                <SEAWithdrawImportHistoryScreen setSubTabIndex={setSubTabIndex} form={ImportFileHistoryFomrm.base} />
              )}
              {subTabIndex === 2 && hasTab(2, tabs) && (
                <SEAWithdrawImportHistoryDetailScreen form={ImportFileHistoryFomrm.base} />
              )}
              {subTabIndex === 3 && hasTab(3, tabs) && <SEAWithdrawRequestScreen form={ImportFileHistoryFomrm.base} />}
            </View>

            <Toast
              message={toast.message}
              visible={toast.open}
              type={toast.type}
              onClose={() => setToast({ open: false, message: '', type: undefined })}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
