import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface FundSwitching {
  tag: TransactionType.FUND_SWITCHING
  clientName: string
  fundType: string
  option: boolean
  sourceFundList: {
    sourceFundCode: string
    unitValue: number
    sourcePercentage: number
    sourceAmount: number
  }[]
  targetFundList: {
    targetFundCode: string
    targetPercentage: number
    targetAmount: number
  }[]
  source?: SourceType
}

export const FundSwitching = (detail: api.FundSwitching, poName: string): FundSwitching => {
  const payload = detail.payload.body
  return {
    tag: TransactionType.FUND_SWITCHING,
    clientName: poName ?? '',
    fundType: payload.attributesExt.FUND_TYPE ?? '-',
    option: payload.attributesExt.OPTION_FLAG ?? false,
    sourceFundList: payload.attributesExt.SOURCE_FUND_LIST.map((elm) => ({
      sourceAmount: elm?.sourceAmount ?? 0,
      sourceFundCode: elm?.sourceFundCode ?? '-',
      sourcePercentage: elm?.sourcePercentage ?? 0,
      unitValue: elm?.unitValue ?? 0
    })),
    targetFundList: payload.attributesExt.TARGET_FUND_LIST.map((elm) => ({
      targetFundCode: elm?.targetFundCode ?? '-',
      targetPercentage: elm?.targetPercentage ?? 0,
      targetAmount: elm?.targetAmount ?? 0
    })),
    source: detail.source
  }
}
