import { ZIO } from "@mxt/zio"
import { useLoading } from "@mxt/zio-react"
import { AppContext, AuthService, ContractMapping, ErrorHandling, PremiumStatusMapping, Select, SelectOption, assets, useTranslator } from "@pulseops/common"
import { IBGeneralTable, IBService, IBStyles, PolicySearchItem } from "@pulseops/inbound"
import { OBFormat, OBGeneralService, OBSectionCol, OBSectionContent, OBSectionRow, OBSpecialInfoData, OBTickBox, OBTitle } from "@pulseops/outbound"
import { pipe } from "fp-ts/lib/function"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"
import { View, Text } from "react-native"

interface Props {
  policyNumber: string
  clientNumber: string
  caseId: string
}

export const OBOthersMaturityTab = ({ policyNumber, clientNumber, caseId }: Props) => {
  const { t,i18n } = useTranslation()
  const ct = useTranslator('contract').t
  const ps = useTranslator('premiumStatus').t
  const [loading, bindLoading] = useLoading(false)
  const [dataFilter, setDataFilter] = React.useState<PolicySearchItem[]>([])
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)
  const [loadingFirst, setLoadingFirst] = React.useState<boolean>(false)
  const [dataSpecialInfo, setDataSpecialInfo] = React.useState<OBSpecialInfoData | null>(null)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const listStatusGroup = [
    {
      label: t('Outbound:OBSpecialInfo:All'),
      value: '1'
    },
    {
      label: t('Outbound:OBSpecialInfo:Proposal'),
      value: '2'
    },
    {
      label: t('Outbound:OBSpecialInfo:Inforce'),
      value: '3'
    },
    {
      label: t('Outbound:OBSpecialInfo:Lapsed'),
      value: '4'
    },
    {
      label: t('Outbound:OBSpecialInfo:Maturity'),
      value: '5'
    },
    {
      label: t('Outbound:OBSpecialInfo:DeathClaimTPD'),
      value: '6'
    },
    {
      label: t('Outbound:OBSpecialInfo:Others'),
      value: '7'
    }
  ]

  const listRole = [
    {
      label: t('Inbound:All'),
      value: ''
    },
    {
      label: t('Inbound:PO'),
      value: 'OW'
    },
    {
      label: t('Inbound:LA'),
      value: 'LF'
    },
    {
      label: t('Inbound:BEN'),
      value: 'BN'
    }
  ]
  const [itemsFilter, setItemsFilter] = React.useState<{statusGroup: SelectOption, role: SelectOption}>({statusGroup: listStatusGroup[0], role: listRole[0]})

  React.useEffect(() => {
    pipe(
      OBGeneralService.getSpecialInfo(policyNumber, caseId),
      ZIO.map((data) => {
        setDataSpecialInfo(data)
      }),
      ZIO.unsafeRun({})
    )
  }, [policyNumber])

  React.useEffect(() => {
    if (!loadingFirst) {
      setLoadingFirst(true)
      handlePolicySearch(page, pageSize)
    }
  }, [clientNumber, policyNumber])

  React.useEffect(() => {
    if (loadingFirst) {
      handlePolicySearch(page, pageSize)
    }
  }, [page, pageSize, itemsFilter])

  const dataTable = [
    {
      label: t('common:PolicyNumber'),
      field: 'policyNumber'
    },
    {
      label: t('Inbound:ProposalNum'),
      field: 'proposalNo',
      render: (value: string) => <Text>{value}</Text>
    },
    {
      label: t('Inbound:Product'),
      field: i18n.language === 'en' ? 'mainProductNameEn' : 'mainProductNameVi'
    },
    {
      label: t('Inbound:PolicyStatus'),
      field: 'policyStatus',
      render: (value: string) => {
        return ContractMapping.get(ct)(value)
      }
    },
    {
      label: t('Inbound:PremiumStatus'),
      field: 'premiumStatus',
      render: (value: string) => {
        return PremiumStatusMapping.get(ps)(value)
      }
    },
    {
      label: 'PO',
      field: 'isPO',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: 'LA',
      field: 'isLA',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: 'BEN',
      field: 'isBEN',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('Inbound:OwnerName'),
      field: 'poName',
    },
    {
      label: t('Inbound:MainLifeAssured'),
      field: 'mainLAName' 
    },
    {
      label: t('Inbound:PaidToDate'),
      field: 'paidToDateBasic',
      format: 'date'
    }
    // {
    //   label: t('APL'),
    //   field: 'aplAmount'
    // }
  ]

  const getPolicyStatusFilter = (key: string) => {
    switch (key) {
      case '1':
        return [
          'PS',
          'IF',
          'PU',
          'LA',
          'FZ',
          'MA',
          'DH',
          'TD',
          'RD',
          'DE',
          'PO',
          'WD',
          'DC',
          'CF',
          'SU',
          'TR',
          'EX',
          'AP',
          'CP',
          'HP',
          'MP',
          'PW',
          'TM',
          'UW',
          'VO',
          'VR'
        ]
      case '2':
        return ['PS']
      case '3':
        return ['IF', 'PU']
      case '4':
        return ['LA', 'FZ']
      case '5':
        return ['MA']
      case '7':
        return ['DH', 'TD', 'RD', 'DE']
      case '8':
        return ['PO', 'WD', 'DC', 'CF', 'SU', 'TR', 'EX', 'AP', 'CP', 'HP', 'MP', 'PW', 'TM', 'UW', 'VO', 'VR']
      default:
        return []
    }
  }

  const checkRulePaidToDate = (
    policyStatus: string,
    premCessDate: string,
    nextPaidPremium: number,
    paidToDate: string
  ) => {
    const checkDate = moment(premCessDate, 'DD/MM/YYYY').diff(moment(paidToDate, 'DD/MM/YYYY'))
    if (policyStatus === 'IF' && checkDate > 0 && nextPaidPremium > 0) {
      return true
    }
    if (policyStatus === 'LA' && checkDate) {
      return true
    }
    return false
  }

  const handlePolicySearch = (page: number, pageSize: number, searchByFilter?: boolean) => {
    if (policyNumber && clientNumber) {
      pipe(
        IBService.searchRelatedPolicy({
          pageIndex: page,
          itemsPerPage: pageSize,
          orders: ['policyNo'],
          sort: 'DESC',
          role: itemsFilter.role?.value ?? '',
          lstPolicyStatusFilter: itemsFilter.statusGroup ? getPolicyStatusFilter(itemsFilter.statusGroup?.value ) : [],
          clientNumber: clientNumber,
          policyNo: '',
        }),
        ZIO.map((res) => {
          setDataFilter(
            res.records.map((item) => ({
              ...item,
              isPO: item.roles?.includes('OW'),
              isLA: item.roles?.includes('LF'),
              isBEN: item.roles?.includes('BN'),
              mainLAName: item.mainLifeAssuredName?.name,
              poName: item.policyOwnerName?.name,
              mainProductNameVi: item.mainProduct?.nameVn,
              mainProductNameEn: item.mainProduct?.nameEn,
              paidToDateBasic: checkRulePaidToDate(
                item.policyStatus as string,
                item.premCessDate as string,
                item.nextPaidPremium as number,
                item.paidToDateBasic as string
              )
                ? _.isEmpty(item.paidToDateBasic) || _.isNil(item.paidToDateBasic)
                  ? ''
                  : moment(item.paidToDateBasic, 'DD/MM/YYYY').format('YYYYMMDD')
                : '',
              mainProductCode: item.mainProduct?.code
            }))
          )
          setTaskCount(res.paging.total)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const oneFileByUrl = (url: string) => {
    showGlobalLoading(true)
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const fileType = res.headers.get('content-type') || ''
        const blobUrl = window.URL.createObjectURL(blob)
        if (fileType === 'image/tiff') {
          OBFormat.viewTiffFileByNewTab(blobUrl)
        } else {
          // const fileString = window.URL.createObjectURL(blob)
          window.open(blobUrl, '_blank')
        }
        showGlobalLoading(false)
      }),
      ZIO.mapError((err) => {
        showGlobalLoading(false)
        return err
      }),
      ErrorHandling.run()
    )
  }

  const findUrl = (docId: string) => {
    const findData = dataSpecialInfo?.documents.find((item) => item.metaData.docid === docId)
    return findData?.url
  }

  const onHandlePressText = (docId: string) => {
    const url = findUrl(docId)
    if (url) oneFileByUrl(url)
  }

  const CommonInfo = () => {
    return (
      <OBSectionContent style={{marginTop: 20}}>
        <OBSectionRow style={IBStyles.row}>
            <OBSectionCol style={{ paddingVertical: 8 }}>
              <OBTickBox
                text={t('Outbound:OBSpecialInfo:PruQuote')}
                checked={dataSpecialInfo?.isSurveyFundsPruQuote ?? false}
                isHightLight={dataSpecialInfo?.isSurveyFundsPruQuote ?? false}
                onHandlePressText={() => onHandlePressText('10203011')}
              />
            </OBSectionCol>
        </OBSectionRow>
      </OBSectionContent>
    )
  }

  const ContractList = () => {
    return (
      <OBSectionContent style={{marginVertical: 20}}>
        <OBTitle textStyle={{}} text={t('Outbound:OBSpecialInfo:POContractList')}></OBTitle>
        <OBSectionRow style={[IBStyles.row, { marginVertical: 20 }]}>
          <OBSectionCol>
            <Select
              label={t('Outbound:OBSpecialInfo:StatusGroup')}
              options={listStatusGroup}
              onChange={(value) => value && setItemsFilter({...itemsFilter, statusGroup:value })}
              value={itemsFilter.statusGroup}
            />
          </OBSectionCol>
          <OBSectionCol>
            <Select
              label={t('Outbound:OBSpecialInfo:Role')}
              options={listRole}
              onChange={(value) => value && setItemsFilter({...itemsFilter, role:value })}
              value={itemsFilter.role}
            />
          </OBSectionCol>
        </OBSectionRow>
        <IBGeneralTable
          dataTable={dataTable}
          data={dataFilter}
          loading={loading}
          paging={{
            page: page,
            pageSize: pageSize,
            taskCount: taskCount,
            setPage: setPage,
            setPageSize: setPageSize
          }}
          paginationStyle={{ marginRight: 60 }}
        />
      </OBSectionContent>
    )
  }

  return (
    <View>
      {CommonInfo()}
      {ContractList()}
    </View>
  )
}