import { FontAwesome5 } from '@expo/vector-icons'
import { createTheme, Snackbar, SnackbarOrigin } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import { ThemeProvider } from '@material-ui/core'
import * as React from 'react'

const theme = createTheme({
  zIndex: {
    snackbar: 9999
  }
})
interface Props {
  visible: boolean
  message: string
  type?: Color
  onClose?: () => void
  anchorOrigin?: SnackbarOrigin | undefined
  duration?: number
  canClose?: boolean
}

export const Toast = ({ visible, message, type, anchorOrigin, onClose, duration = 3000, canClose = true }: Props) => {
  // Implement for web
  let icon: any = false
  let iconType: string = ''
  const color: string = '#FFFFFF'
  const size: number = 20

  switch (type) {
    case 'success':
      iconType = 'check-circle'
      break
    case 'warning':
      iconType = 'exclamation-circle'
      break
    case 'error':
      iconType = 'times-circle'
      break
    case 'info':
      iconType = 'info-circle'
      break
    default:
      iconType = ''
      break
  }

  if (type) {
    icon = <FontAwesome5 name={iconType} color={color} size={size} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={visible}
        autoHideDuration={duration}
        message={message}
        onClose={canClose ? onClose : () => {}}
        anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'right' }}
      >
        {type &&
          (canClose ? (
            <Alert variant="filled" onClose={onClose} severity={type} icon={icon} color={type}>
              {message}
            </Alert>
          ) : (
            <Alert variant="filled" severity={type} icon={icon} color={type}>
              {message}
            </Alert>
          ))}
      </Snackbar>
    </ThemeProvider>
  )

  // Inplemment for mobile not available
}
