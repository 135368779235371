import React from 'react'
import { ActivityIndicator, SafeAreaView, ScrollView, View } from 'react-native'
import { CallOutDetailContext } from '@pulseops/outbound'
import { IBService, InvestmentDataInfo, InvestmentFund, ProductTypeC } from '@pulseops/inbound'
import { ErrorHandling, PulseOpsFormat } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { isNil } from 'lodash'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { OBInvestmentULP } from './OBInvestmentULP'
import { OBInvestmentILP } from './OBInvestmentILP'
export enum FundType {
  TargetPremium = 'TP01',
  TopUp = 'TU02'
}

interface Props {
  productInfo?: ProductTypeC
}
export const OBInvestmentTab = (props: Props) => {
  const { policyNumber, clientNumber, scrollRef } = React.useContext(CallOutDetailContext)
  const [investmentInfo, setInvestmentInfo] = React.useState<InvestmentDataInfo>()
  const [listFundT25, setListFundT25] = React.useState<InvestmentFund[]>([])
  const { productInfo } = props
  const [loading, bindLoading] = useLoading()

  const getData = (): ZIO<unknown, Throwable, InvestmentDataInfo | null> =>
    pipe(
      ZIO.succeed(productInfo),
      ZIO.flatMap((productInfo) => {
        return pipe(
          ZIO.zipPar(
            IBService.getInvestmentSummary(
              policyNumber,
              productInfo?.body.productType as string,
              productInfo?.body.basicCode as string
            ),
            IBService.getInvestmentFund()
          ),
          ZIO.map(([investmentInfo, listFundT25]) => {
            setListFundT25(listFundT25.data)
            const listFundTopUp = listFundT25.data
              .filter((x) => x.fundTypeCode === FundType.TopUp)
              .map((x) => x.fundCode)

            const listFundTargetPremium = listFundT25.data
              .filter((x) => x.fundTypeCode === FundType.TargetPremium)
              .map((x) => x.fundCode)

            const investmentData = investmentInfo.data

            const targetPremiumEstimatedValue = (investmentData.policyExtraInfo?.policyExtraInfoFunds || [])
              .filter((x) => listFundTargetPremium.includes(x.fundCode as string))
              .reduce((pre, cur) => pre + (cur.estimateValue ?? 0), 0)

            const topUpPremiumEstimatedValue = (investmentData.policyExtraInfo?.policyExtraInfoFunds || [])
              .filter((x) => listFundTopUp.includes(x.fundCode as string))
              .reduce((pre, cur) => pre + (cur.estimateValue ?? 0), 0)

            const estimatedValueInfo = {
              estimatedValueTotal: PulseOpsFormat.thousandSepartor(
                targetPremiumEstimatedValue + topUpPremiumEstimatedValue
              ),
              totalExcessPremium: PulseOpsFormat.thousandSepartor(investmentData.totalExcessPremium),
              totalAllocatedPremiumToBasicProductAndGroup1Riders: PulseOpsFormat.thousandSepartor(
                investmentData.allocatedPremium?.mainProductAndRider1
              ),
              totalAllocationCharges: PulseOpsFormat.thousandSepartor(investmentData.totalAllocationCharges),
              targetPremiumEstimatedValue: PulseOpsFormat.thousandSepartor(targetPremiumEstimatedValue),
              totalWithdrawnAmount: '0',
              totalAllocatedPremiumToGroup2Riders: PulseOpsFormat.thousandSepartor(
                investmentData.allocatedPremium?.rider2
              ),
              totalCostOfInsurance: PulseOpsFormat.thousandSepartor(investmentData.costOfInsurance),
              topUpPremiumEstimatedValue: PulseOpsFormat.thousandSepartor(topUpPremiumEstimatedValue),
              maximumWithdrawableAmount: PulseOpsFormat.thousandSepartor(investmentData.maxWithDrawableAmt),
              totalAllocatedPremiumToAccountValue: PulseOpsFormat.thousandSepartor(investmentData.allocatedAccValue),
              totalAdministrationCharge: PulseOpsFormat.thousandSepartor(investmentData.administrationCharge),
              fundValueTotal:
                PulseOpsFormat.thousandSepartor(
                  investmentData.policyExtraInfo?.policyExtraInfoFunds?.reduce(
                    (pre, cur) => (pre = pre + (cur.estimateValue as number)),
                    0
                  )
                ) || '0'
            }
            return {
              investmentSummary: investmentData,
              estimatedValueInfo: estimatedValueInfo || undefined,
              partialWithdrawInfo: undefined,
              partialWithdrawHistory: undefined,
              fundValueInfo: undefined,
              loyaltyBonus: undefined,
              allocatedPremiumInfo: undefined,
              allocationChargeInfo: undefined,
              costOfInsuranceInfo: undefined,
              administrationChargeInfo: undefined,
              topUpHistory: undefined,
              fundSwitchHistory: undefined,
              premiumRedirectionHistory: undefined,
              investmentInterest: undefined,
              accountValue: undefined,
              extraInfo: undefined
            }
          })
        )
      })
    )

  React.useEffect(() => {
    pipe(
      !isNil(investmentInfo) ? ZIO.succeed(investmentInfo) : getData(),
      ZIO.map((res) => {
        if (res !== null && isNil(investmentInfo)) setInvestmentInfo(res)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [policyNumber])

  return (
    <View style={{ marginTop: 15 }}>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : productInfo?.body.productType === 'ULP' ? (
        <OBInvestmentULP
          scrollRef={scrollRef}
          policyNumber={policyNumber}
          productCode={productInfo.body.basicCode as string}
          setInvestmentInfo={setInvestmentInfo}
          investmentInfo={investmentInfo}
          status={productInfo?.body.status as string}
          productType={productInfo.body.productType as string}
          basicCode={productInfo?.body.basicCode as string}
          listFundT25={listFundT25}
        />
      ) : (
        <OBInvestmentILP
          scrollRef={scrollRef}
          policyNumber={policyNumber}
          productCode={productInfo?.body.basicCode as string}
          setInvestmentInfo={setInvestmentInfo}
          investmentInfo={investmentInfo}
          status={productInfo?.body.status as string}
          productType={productInfo?.body.productType as string}
          basicCode={productInfo?.body.basicCode as string}
          listFundT25={listFundT25}
        />
      )}
    </View>
  )
}
