import { ZIO } from '@mxt/zio'
import {
  assets,
  AuthService,
  ErrorHandling,
  Form,
  GeneralService,
  Input,
  SelectSearch,
  useMobile,
  DatePicker
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { generalStyles as styles, PaymentGeneralInfo } from '../../../../common'
import { DocumentInfo } from '../../add-more'
import { PaymentMethodProps } from '../PaymentMethodProps'

interface Props {
  formInfo: PaymentMethodProps
  officeCode: string
}

export const PayAtTheCounter: React.FC<Props> = (props) => {
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation('claim')
  const ct = useTranslation().t
  const { form, selectedDocumentType, setSelectedDocumentType } = props.formInfo
  const register = Form.register(form)

  const watchPO = form.watch('payeeIsNotPolicyOwner')

  const errors = form.formState.errors

  const officeCodeLoad =
    pipe(
      GeneralService.getOfficeCodes('CSC'),
      ZIO.map((codes) => {
        return codes.map((item) => ({
          value: item.code,
          label: `${item.code} - ${i18n.language === 'vi' ? item.nameVi : item.nameEn}`
        }))
      }),
      ErrorHandling.runDidMount()
    ) || []

  const getOfficeCode = pipe(
    AuthService.userInfo,
    ZIO.flatMap((x) =>
      x.officeCode !== null
        ? pipe(
            GeneralService.getOfficeCodeGA(x.officeCode),
            ZIO.map((y) => {
              const option = {
                label: y.body[0].shortName,
                value: y.body[0].code
              }
              form.setValue('gaOffice', option)
              return option
            })
          )
        : ZIO.succeed(null)
    ),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (!getOfficeCode && officeCodeLoad.length > 0) {
      form.setValue(
        'gaOffice',
        officeCodeLoad.filter((item) => item.value === form.watch().gaOffice?.value ?? props.officeCode)[0]
      )
    }
  }, [getOfficeCode, officeCodeLoad])
  return (
    <View style={{ width: '100%' }}>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Controller
            name="gaOffice"
            control={form.control}
            rules={{
              required: {
                value: !getOfficeCode,
                message: `${ct('message:MS020001', { field: t('GAOffice') })}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectSearch
                required={!getOfficeCode}
                popupIcon={getOfficeCode ? <></> : <assets.ArrowDownDropdownIcon />}
                label={t('GAOffice')}
                options={officeCodeLoad}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                key={value?.value}
                disabled={!!getOfficeCode}
                disableUnderline={!!getOfficeCode}
              />
            )}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('payeeName', {
              required: `${ct('message:MS020001', { field: t('PayeeName') })}`,
              maxLength: { value: 120, message: 'Max 120' },
              pattern: {
                value: /^[^<>'\"/;`%@*!(){}\[\],.:#+-=$^&\\|?~_-]*$/,
                message: `${t('message:MS050013', { field: t('PayeeName') })}`
              }
            })}
            maxLength={120}
            disabled={!watchPO}
            errorMessage={errors.payeeName?.message || ''}
            title={t('PayeeName')}
            required={watchPO}
          />
        </View>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <Input
            {...register('payeeId', {
              required: `${ct('message:MS020001', { field: t('IDNumber') })}`,
              maxLength: { value: 24, message: 'Max 24' },
              pattern: {
                value: /^((?!501|504)[0-9]*)$/,
                message: `${t('message:MS050013', { field: t('IDNumber') })}`
              }
            })}
            maxLength={24}
            disabled={!watchPO}
            errorMessage={errors.payeeId?.message || ''}
            title={t('IDNumber')}
            required={watchPO}
          />
        </View>
      </View>
      <View style={[{ flexDirection: isMobile ? 'column' : 'row' }]}>
        {!watchPO && (
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Controller
              name="dob"
              control={form.control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <DatePicker
                  disabled
                  label={t('contact:DOB')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        )}
      </View>
      {watchPO && <PaymentGeneralInfo form={form} />}
      <DocumentInfo
        form={form}
        setSelectedDocumentType={setSelectedDocumentType}
        selectedDocumentType={selectedDocumentType}
      />
    </View>
  )
}
