import { SafeAreaView, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import {
  PolicyInfoHeader,
  assets,
  TabList,
  ErrorHandling,
  PCDataImportPermission,
  RBAC,
  AppContext
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useForm } from 'react-hook-form'
import { useLoading } from '@mxt/zio-react'
import { FailListScreen, SubmitFileScreen, UploadFileToStorageScreen } from '../premium-collection'
import { useIsFocused, useNavigation } from '@react-navigation/native'
interface Props {}

export const PCDataImportScreen = (props: Props) => {
  const { t } = useTranslation('pc')
  const [tabIndex, setTabIndex] = React.useState<number>(1)
  const [canChangeTab, setCanChangeTab] = React.useState<boolean>(true)
  const isFocused = useIsFocused()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []

  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:PCDataImport'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const tabs = React.useMemo(() => {
    let hasTab = []
    permissions.includes(PCDataImportPermission.ViewSubmitFilePCDataImport) &&
      hasTab.push({ id: 1, title: t('UploadFileToAzure') })
    permissions.includes(PCDataImportPermission.ViewUploadFileToStoragePCDataImport) &&
      hasTab.push({ id: 2, title: t('ManualRunBatchJobImport') })
    permissions.includes(PCDataImportPermission.ViewFailListPCDataImport) &&
      hasTab.push({ id: 3, title: t('FailList') })
    return hasTab
  }, [permissions, isFocused])

  const onChangeTab = (i: number) => {
    if (canChangeTab) setTabIndex(i)
  }

  React.useEffect(() => {
    tabs && setTabIndex(tabs[0]?.id)
  }, [tabs])

  return (
    <SafeAreaView style={{ flex: 1, width: '100%', marginTop: 10 }}>
      <View style={{ marginHorizontal: 15 }}>
        <TabList menus={tabs} isSubTab={false} useOriginalId={true} tabIndex={tabIndex} onChangeTab={onChangeTab} />
      </View>
      <ScrollView style={{ height: '100%', backgroundColor: '#FFFFFF' }}>
        <View style={{ overflow: 'hidden' }}>
          {tabIndex === 1 && <SubmitFileScreen setCanChangeTab={setCanChangeTab} />}
          {tabIndex === 2 && <UploadFileToStorageScreen setCanChangeTab={setCanChangeTab} />}
          {tabIndex === 3 && <FailListScreen setCanChangeTab={setCanChangeTab} />}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}
