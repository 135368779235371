import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

export const EService = t.type({
  transactionType: t.literal(TransactionType.E_SERVICE_REGISTRATION),
  payload: t.type({
    body: t.type({
      email: Maybe(t.string),
      fullName: Maybe(t.string),
      gender: Maybe(t.string),
      idNumber: Maybe(t.string),
      mobileNumber: Maybe(t.string),
      otpConsent: Maybe(t.string),
      status: Maybe(t.string),
      telCode: Maybe(t.string),
      dob: Maybe(t.string)
    })
  }),
  source: t.literal(SourceType.PULSE4OPS)
})

export type EService = t.TypeOf<typeof EService>
