import {
  AppContext,
  BillingChangeReversal,
  BillingChangeReversalService,
  formatNumberWithComma,
  Frequency,
  FrequencyMapping,
  Select,
  TransactionType
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, SafeAreaView, StyleSheet } from 'react-native'
import { PolicyServiceProps } from '../policy-service-props'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'

export interface BillingChangeReversalFormData {
  oldFrequency: {
    value: Frequency
    label: string
  }
}

export const BillingChangeReversalScreen: React.FC<PolicyServiceProps<BillingChangeReversal.SubmitData>> = ({
  initSubmission,
  isConfirmed,
  policyNumber
}) => {
  const mapFrequency = FrequencyMapping.mapFrequency
  const policyNum = policyNumber ?? ''
  const { t } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [detailData, setDetailData] = React.useState<BillingChangeReversal.DetailData>({
    automaticPolicyLoanInterestDate: null,
    curBillingFrequency: '',
    curInstalmentAmount: '',
    oldBillingFrequency: [],
    oldInstalmentAmount: '',
    policyNo: '',
    automaticPolicyLoanInterest: 0,
    requirePayInAmount: 0,
    oldBillingFreqFormField: null
  })
  const billingChangeReversalForm = useForm<BillingChangeReversalFormData>()
  const { control, setValue, watch } = billingChangeReversalForm
  React.useEffect(() => {
    if (detailData && !detailData.policyNo) {
      loadingDetailData()
    }
  }, [detailData])

  const MapFrequencies = FrequencyMapping.FrequencyOption.filter(
    (freq) => detailData?.oldBillingFrequency.length && detailData?.oldBillingFrequency.includes(freq.value)
  )

  const formatCurrency = (value: number | string | undefined) => {
    return !!value ? formatNumberWithComma(value) : '0'
  }

  initSubmission({
    validate: async () => {
      if (await billingChangeReversalForm.trigger()) {
        const oldFrequencyItem = watch('oldFrequency').value
        return {
          url: (policyNumber: string) => `wf-api/policy/${policyNumber}/billing-change-apl`,
          body: {
            automaticPolicyLoanInterestDate: [moment(detailData.automaticPolicyLoanInterestDate).format('DD/MM/yyyy')],
            automaticPolicyLoanInterest: `${detailData.automaticPolicyLoanInterest}`,
            curBillingFrequency: detailData.curBillingFrequency,
            curInstalmentAmount: detailData.curInstalmentAmount,
            oldBillingFrequency: [oldFrequencyItem],
            oldInstalmentAmount: [detailData.oldInstalmentAmount],
            policyNo: policyNum,
            payInAmount: `${detailData.requirePayInAmount}`
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.BILLING_FREQUENCY_REVERSAL),
          collerationId: policyNum,
          transaction: TransactionType.BILLING_FREQUENCY_REVERSAL
        }
      } else {
        return false
      }
    },
    clear: () => {
      loadingDetailData()
    }
  })

  const loadingDetailData = (frequency?: Frequency) => {
    showGlobalLoading(true)
    pipe(
      BillingChangeReversalService.getDetail(policyNum, frequency),
      ZIO.map((responseData) => {
        const oldBillingFre = responseData.oldBillingFreqFormField
        let oldBillingItem = FrequencyMapping.FrequencyOption.find((x) => x.value === oldBillingFre)
        oldBillingItem = {
          ...oldBillingItem,
          label: oldBillingItem?.label ?? '',
          value: oldBillingItem?.value as Frequency
        }
        setDetailData(responseData)
        setValue('oldFrequency', oldBillingItem)
        return responseData
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const onChangeFrequency = (frequency: Frequency) => {
    loadingDetailData(frequency)
  }

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Text style={billingStyles.headerField}>{t('billingChangeReversal:BILLINGFREQUENCYREVERSAL')}</Text>
      <View style={billingStyles.sectionContent}>
        <View style={billingStyles.sectionRow}>
          <View style={billingStyles.col_4}>
            <Text style={billingStyles.field_title}>{t('billingChangeReversal:CurBillingFrequency')}</Text>
            <Text style={billingStyles.field_description}>
              {detailData?.curBillingFrequency
                ? t(mapFrequency.get(detailData.curBillingFrequency as Frequency) ?? '') ?? ''
                : ''}
            </Text>
          </View>
          <View style={billingStyles.col_4}>
            <Text style={billingStyles.field_title}>{t('billingChangeReversal:CurInstalmentAmount')}</Text>
            <Text style={billingStyles.field_description}>{formatCurrency(detailData?.curInstalmentAmount)} VND</Text>
          </View>
          <View style={billingStyles.col_4}>
            <Text style={billingStyles.field_title}>{t('billingChangeReversal:AutomaticPolicyLoanDate')}</Text>
            <Text style={billingStyles.field_description}>
              {detailData?.automaticPolicyLoanInterestDate
                ? moment(detailData.automaticPolicyLoanInterestDate).format('DD/MM/yyyy')
                : ''}
            </Text>
          </View>
        </View>

        <View style={[billingStyles.sectionRow, billingStyles.secondLine]}>
          <View style={billingStyles.col_4}>
            <Controller
              name="oldFrequency"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020009', { field: t('billingChangeReversal:OldBillingFrequency') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Select
                  onChange={(val) => {
                    const selectValue = val?.value as Frequency
                    onChange(val)
                    onChangeFrequency(selectValue)
                  }}
                  onBlur={onBlur}
                  value={value}
                  label={t('billingChangeReversal:OldBillingFrequency')}
                  disabled={isConfirmed}
                  errorMessage={error?.message}
                  options={MapFrequencies.map((item) => ({
                    label: t(`${item.label}`),
                    value: item.value
                  }))}
                ></Select>
              )}
            />
          </View>
          <View style={billingStyles.col_4}>
            <Text style={billingStyles.field_title}>{t('billingChangeReversal:OldInstallmentPremium')}</Text>
            <Text style={billingStyles.field_description}>{formatCurrency(detailData?.oldInstalmentAmount)} VND</Text>
          </View>
          <View style={billingStyles.col_4}>
            <Text style={billingStyles.field_title}>{t('billingChangeReversal:AutomaticPolicyLoanInterest')}</Text>
            <Text style={billingStyles.field_description}>
              {formatCurrency(detailData?.automaticPolicyLoanInterest)} VND
            </Text>
          </View>
        </View>
        <View style={[billingStyles.sectionRow, , billingStyles.secondLine]}>
          <View style={billingStyles.col_4}>
            <Text style={billingStyles.field_title}>{t('billingChangeReversal:RequirePayinAmount')}</Text>
            <Text style={[billingStyles.field_description, billingStyles.red_line]}>
              {formatCurrency(detailData?.requirePayInAmount)} VND
            </Text>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}
const billingStyles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  red_line: {
    color: '#ed1c2e'
  }
})
