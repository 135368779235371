
import React from 'react'
import { StyleSheet, View, Text, ViewStyle, StyleProp } from 'react-native'
import { assets } from '@pulseops/common'
import { TouchableOpacity } from 'react-native-gesture-handler'

type IDCContainerProps = {
  containerTitle?: (isOpen: boolean) => JSX.Element
  containerStyle?: StyleProp<ViewStyle>
  isOpenContainer?: boolean
}

export const IDCContainer: React.FC<IDCContainerProps> = (props) => {
  const { containerTitle, children, containerStyle, isOpenContainer = true } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(isOpenContainer)
  return (
    <View style={[containStyles.container, containerStyle]}>
      <View style={containStyles.headerContainer}>
        {/* <View style={containStyles.headerTextContent}>
        </View> */}
        <View style={{ flex: 90 }}>
          {containerTitle && containerTitle(isOpen)}
        </View>
        <View style={{ flex: 10, alignItems: 'flex-end' }}>
          <TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
            <View>
              {isOpen ? <assets.OBDropUp /> : <assets.OBDropDown />}
            </View>
          </TouchableOpacity>
        </View>
      </View>
      {isOpen && (
        <View style={containStyles.content}>
          {children}
        </View>
      )}
    </View>
  )
}
const containStyles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingVertical: 16,
    paddingRight: 24,
    paddingLeft: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  content: {
    marginTop: 16
  },
  headerTextContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    marginLeft: 24,
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16
  },
})