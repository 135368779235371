/* eslint-disable @typescript-eslint/ban-types */
import { Maybe } from '@mxt/zio/codec'
import { SelectOption } from '@pulseops/common'
import * as t from 'io-ts'

export namespace SeaAutoDebitRegisterReportModel {
  export const GenderData: { code: string; nameEN: string; nameVN: string }[] = [
    {
      code: 'Năm',
      nameVN: 'Năm',
      nameEN: 'Year'
    },
    {
      code: 'Mỗi Năm',
      nameVN: 'Mỗi Năm',
      nameEN: 'Every Year'
    },
    {
      code: 'Nửa năm',
      nameVN: 'Nửa năm',
      nameEN: 'Half-Yearly'
    },
    {
      code: 'Quý',
      nameVN: 'Quý',
      nameEN: 'Quarterly'
    },
    {
      code: 'Mỗi Quý',
      nameVN: 'Mỗi Quý',
      nameEN: 'Every Quarterly'
    },
    {
      code: 'Tháng',
      nameVN: 'Tháng',
      nameEN: 'Monthly'
    },
    {
      code: 'MỗiTháng',
      nameVN: 'Mỗi Tháng',
      nameEN: 'Every month'
    }
  ]
  const GenderMapData = new Map<string, Map<string, string>>(
    GenderData.map((gender) => [
      gender.code,
      new Map([
        ['vi', gender.nameVN],
        ['en', gender.nameEN]
      ])
    ])
  )

  export const getGender = (code: string) => (languageCode: string) =>
    GenderMapData.get(code)?.get(languageCode) ?? code
  export const getGenderOption = (code: string) => (languageCode: string) => ({
    value: code,
    label: getGender(code)(languageCode)
  })
  export const getGenderOptions = (languageCode: String): SelectOption[] =>
    GenderData.map((item) => {
      return {
        label: languageCode === 'vi' ? item.nameVN : item.nameEN,
        value: item.code
      }
    })

  const FrequentFeeRaw: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'Năm',
      vi: 'Năm',
      en: 'Year'
    },
    {
      code: 'Nửa năm',
      vi: 'Nửa năm',
      en: 'Half-Yearly'
    },
    {
      code: 'Quý',
      vi: 'Quý',
      en: 'Quarterly'
    },
    {
      code: 'Tháng',
      vi: 'Tháng',
      en: 'Monthly'
    }
  ]

  // eslint-disable-next-line @typescript-eslint/ban-types
  const FrequentFeeMapData = new Map<String, Map<string, string>>(
    FrequentFeeRaw.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getFrequentName = (code: string) => (languageCode: string) =>
    FrequentFeeMapData.get(code)?.get(languageCode) ?? code

  const FailReason: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'MS990106',
      vi: 'Số hợp đồng định dạng không đúng',
      en: 'Policy format is incorrect'
    },
    {
      code: 'MS990107',
      vi: 'Định kỳ nộp phí bảo hiểm hiện tại không đúng qui định',
      en: 'Current billing frequency is incorrect'
    },
    {
      code: 'MS990108',
      vi: 'Định dạng phí bảo hiểm định kỳ hiện tại không đúng ',
      en: 'Current installment premium format is incorrect'
    },
    {
      code: 'MS990109',
      vi: 'Định dạng ngày hiệu lực của định kỳ mới không đúng',
      en: 'Effective date of new frequency  format is incorrect'
    },

    {
      code: 'MS990110',
      vi: 'Định kỳ nộp phí bảo hiểm mới trùng định kỳ nộp phí bảo hiểm hiện tại',
      en: 'New billing frequency and Current billing frequency are the same'
    },
    {
      code: 'MS990111',
      vi: 'Định kỳ nộp phí bảo hiểm mới không đúng qui định',
      en: 'New billing frequency is incorrect'
    },
    {
      code: 'MS990112',
      vi: '	Định dạng phí bảo hiểm mới hiện tại không đúng',
      en: 'New installment premium format is incorrect'
    },
    {
      code: 'MS990113',
      vi: 'Họ tên NĐBH > 50 ký tự',
      en: 'Life Asure File Name > 50 characters'
    },

    {
      code: 'MS990114',
      vi: 'Có ký tự đặc biệt',
      en: 'The case has special character'
    },
    {
      code: 'MS990115',
      vi: 'Hợp đồng đã mất hiệu lực',
      en: 'The policy has been lapsed'
    },
    {
      code: 'MS990116',
      vi: 'Hợp đồng phí đơn kỳ',
      en: 'Single-term premium policy'
    },
    {
      code: 'MS990117',
      vi: 'Hợp đồng quá ngày được điều chỉnh định kỳ đóng phí',
      en: 'Policy is overdue to adjust billing frequency'
    },
    {
      code: 'MS990118',
      vi: 'Hợp đồng không thỏa điều kiện điều chỉnh định kỳ đóng phí',
      en: 'The policy does not meet the conditions for billing frequency'
    },
    {
      code: 'MS990119',
      vi: 'Hợp đồng không thuộc Hợp đồng nhóm Seabank',
      en: 'The Policy is out of scope Group Policy Seabank'
    },
    {
      code: 'MS990120',
      vi: '	Hợp đồng có ô trống',
      en: 'The Policy has a null field'
    },
    {
      code: 'MS990121',
      vi: 'Sai tên NĐBH',
      en: 'LA Name is incorrect'
    },
    {
      code: 'MS990122',
      vi: 'Hợp đồng bị trùng',
      en: 'Duplicated Policy'
    },
    {
      code: 'MS990127',
      vi: 'Lỗi khác',
      en: 'OTHER'
    }
  ]

  const FailReasonMapData = new Map<String, Map<string, string>>(
    FailReason.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getFailReasonName = (code: string) => (languageCode: string) =>
    FailReasonMapData.get(code)?.get(languageCode) ?? code

  export enum Status {
    ALL = 'All',
    REGISTER = 'N',
    CANCEL = 'D'
  }
  export enum Order {
    ASC = 'asc',
    DESC = 'desc'
  }

  export interface ExportData {
    order?: string
    sort?: string
    start?: number
    page?: number
    size?: number
    policyNumber?: string
    status: Status
    fromDate: Date
    toDate: Date
  }

  export const ReportDebitDataItem = t.array(
    t.type({
      policyNumber: Maybe(t.string),
      fromDate: Maybe(t.string),
      toDate: Maybe(t.string),
      currentFunction: Maybe(t.string),
      fundTopup1: Maybe(t.string),
      fundTopup2: Maybe(t.string),
      fundTopup3: Maybe(t.string),
      fundTopup4: Maybe(t.string),
      fundTopup5: Maybe(t.string),
      fundTopup6: Maybe(t.string),
      fundTopup7: Maybe(t.string),
      installPremium: Maybe(t.string),
      paymentStatus: Maybe(t.string),
      chargeDate: Maybe(t.string),
      reasonFail: Maybe(t.string),
      ptd: Maybe(t.string)
    })
  )
  export type ReportDebitDataItem = t.TypeOf<typeof ReportDebitDataItem>

  export const ReportsDebitData = t.type({
    data: Maybe(ReportDebitDataItem),
    total: t.number,
    page: Maybe(t.number),
    size: Maybe(t.number),
    order: Maybe(t.string),
    sort: Maybe(t.string)
  })
  export type ReportsDebitData = t.TypeOf<typeof ReportsDebitData>
}
