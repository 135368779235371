import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CashLessService,
  form2,
  ImgUploadMutiplePC,
  ImgUploadMutiple,
  AppContext,
  AuthService,
  ErrorHandling
} from '@pulseops/common'
import { Error } from '@pulseops/submission/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { SubmitFileForm } from './submit-file-form'
import { Controller } from 'react-hook-form'
import _ from 'lodash'
interface Props {
  setCanChangeTab: (canChangeTab: boolean) => void
}

export const SubmitFileScreen = (props: Props) => {
  const { setCanChangeTab } = props
  const { t } = useTranslation()
  const [isLoading, setLoading] = React.useState(false)
  const { base, handleSubmit } = form2.useForm(SubmitFileForm.codec)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const onSubmit = handleSubmit((data) => {
    if (!_.isEmpty(data)) {
      const fileItem = data.attachments[0].file
      const evidenceFile = data.evidences[0].file
      setCanChangeTab(false)
      setLoading(true)
      pipe(
        CashLessService.importFileToStore(fileItem, evidenceFile, userInfo?.email || ''),
        ZIO.map((data) => {
          showToast(t(`message:MS990066`), 'success')
          setCanChangeTab(true)
          setLoading(false)
        }),
        ZIO.catchAll((error) => {
          const path = `source.response.data.responseStatus.errors.0.code`
          const code = _.get(error, path)
          const message = code ? t(`message:${code}`) : t('common:SystemError')
          setCanChangeTab(true)
          setLoading(false)
          return ZIO.effect(() => {
            showToast(message, 'error')
          })
        }),
        ZIO.unsafeRun({})
      )
    } else {
      showToast(t('message:MS150004'), 'error')
    }
  })

  return (
    <View style={{ margin: 15 }}>
      <View style={styles.searchContainer}>
        <Text style={styles.sessonText}>{t('TaskManagement:ImportFile')}</Text>
        <View>
          <Text style={{ fontSize: 15, color: '#70777e', fontWeight: 'bold' }}>
            {t('utilities:File2Attachment')}
            <Text style={{ color: 'red' }}> *</Text>
          </Text>
          <Controller
            control={base.control}
            name={`attachments`}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS150004')}`
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <ImgUploadMutiplePC
                  value={value as any[]}
                  onChange={onChange}
                  timeFormat={'DD/MM/YYYY HH:mm'}
                  maxSizeFile={1}
                  maxTotalFile={1}
                  messageFormat={'TaskManagement:MSFormatUploadFile'}
                  validExtensions={['XLS', 'XLSX']}
                  messageMaxSize={'TaskManagement:MaxSizeFile1MB'}
                  maxNumFile={1}
                  takeAPicture={false}
                  showFullFileName
                  isFileDownload={false}
                  disabledDelete={isLoading}
                />
                {error?.message && <Error message={!!value && value.length > 0 ? '' : error?.message} />}
              </>
            )}
          />
        </View>
        <View style={styles.evidenceContainer}>
          <Text style={styles.sessonText}>{t('utilities:EvidenceDocument')}</Text>
          <View>
            <Text style={{ fontSize: 15, color: '#70777e', fontWeight: 'bold' }}>
              {t('utilities:File2Attachment')}
              <Text style={{ color: 'red' }}> *</Text>
            </Text>
            <Controller
              control={base.control}
              name={`evidences`}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS150004')}`
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <ImgUploadMutiplePC
                    value={value as any[]}
                    onChange={onChange}
                    timeFormat={'DD/MM/YYYY HH:mm'}
                    maxSizeFile={2}
                    maxTotalFile={2}
                    messageFormat={'TaskManagement:MSFormatUploadFileEvidencePC'}
                    messageMaxSize={'message:MS990070'}
                    maxNumFile={1}
                    takeAPicture={false}
                    showFullFileName
                    isFileDownload={false}
                    disabledDelete={isLoading}
                  />
                  {error?.message && <Error message={!!value && value.length > 0 ? '' : error?.message} />}
                </>
              )}
            />
          </View>
        </View>
        <View style={[styles.row, { alignItems: 'center', marginTop: 20, alignSelf: 'center' }]}>
          <TouchableOpacity
            style={{ width: 118, height: 40 }}
            onPress={onSubmit}
            disabled={isLoading || _.isEmpty(base.watch().attachments) || _.isEmpty(base.watch().evidences)}
          >
            <View
              style={[
                styles.btn,
                {
                  backgroundColor:
                    isLoading || _.isEmpty(base.watch().attachments) || _.isEmpty(base.watch().evidences)
                      ? '#B0B0B0'
                      : '#ed1b2e',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }
              ]}
            >
              {isLoading && <ActivityIndicator size="small" color="red" />}
              <Text style={styles.btnText}>{t('TaskManagement:UploadFile')}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  sessonText: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 15,
    marginBottom: 10
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginVertical: 15,
    padding: 25,
    flex: 1,
    flexDirection: 'column'
  },
  evidenceContainer: {
    backgroundColor: '#F9F9F9',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginVertical: 15,
    padding: 25,
    flex: 1,
    flexDirection: 'column',
    minHeight: 'auto'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15,
    width: '22%'
  },
  btn: {
    width: 118,
    height: 40,
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  btnText: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'NotoSans_700Bold'
  }
})
