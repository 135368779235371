import { OptionField } from '../OptionField'

export namespace Address {
  export interface AddressOption {
    code: string
    desVi: string
  }

  export interface ProvinceOption {
    code: string
    desVi: string
    birthCode: string
  }

  export const MockProvince: Array<ProvinceOption> = [
    { code: '08', desVi: 'Hồ Chí Minh', birthCode: 'SG' }
    // {code:"76",desVi:"An Giang"},
    // {code:"64",desVi:"Bà Rịa - Vũng Tàu"},
    // {code:"BG",desVi:"Bắc Giang"},
    // {code:"BC",desVi:"Bắc Kạn"},
    // {code:"BL",desVi:"Bạc Liêu"},
    // {code:"BN",desVi:"Bắc Ninh"},
    // {code:"75",desVi:"Bến Tre"},
    // {code:"56",desVi:"Bình Định"},
    // {code:"BD",desVi:"Bình Dương"},
    // {code:"BP",desVi:"Bình Phước"},
    // {code:"62",desVi:"Bình Thuận"},
    // {code:"CM",desVi:"Cà Mau"},
    // {code:"71",desVi:"Cần Thơ"},
    // {code:"26",desVi:"Cao Bằng"},
    // {code:"DN",desVi:"Đà Nẵng"},
    // {code:"DA",desVi:"Đắc Nông"},
    // {code:"50",desVi:"Dak Lak"},
    // {code:"DB",desVi:"Điện Biên"},
    // {code:"61",desVi:"Đồng Nai"},
    // {code:"67",desVi:"Đồng Tháp"},
    // {code:"59",desVi:"Gia Lai"},
    // {code:"19",desVi:"Hà Giang"},
    // {code:"HN",desVi:"Hà Nam"},
    // {code:"04",desVi:"Hà Nội"},
    // {code:"39",desVi:"Hà Tĩnh"},
    // {code:"HD",desVi:"Hải Dương"},
    // {code:"31",desVi:"Hải Phòng"},
    // {code:"HG",desVi:"Hậu Giang"},

    // {code:"18",desVi:"Hòa Bình"},
    // {code:"HY",desVi:"Hưng Yên"},
    // {code:"58",desVi:"Khánh Hòa"},
    // {code:"77",desVi:"Kiên Giang"},
    // {code:"60",desVi:"Kon Tum"},
    // {code:"23",desVi:"Lai Châu"},
    // {code:"63",desVi:"Lâm Đồng"},
    // {code:"25",desVi:"Lạng Sơn"},
    // {code:"20",desVi:"Lào Cai"},
    // {code:"72",desVi:"Long An"},
    // {code:"ND",desVi:"Nam Định"},
    // {code:"38",desVi:"Nghệ An"},
    // {code:"30",desVi:"Ninh Bình"},
    // {code:"68",desVi:"Ninh Thuận"},
    // {code:"PT",desVi:"Phú Thọ"},
    // {code:"57",desVi:"Phú Yên"},
    // {code:"52",desVi:"Quảng Bình"},
    // {code:"QN",desVi:"Quảng Nam"},
    // {code:"55",desVi:"Quảng Ngãi"},
    // {code:"33",desVi:"Quảng Ninh"},
    // {code:"79",desVi:"Quảng Trị"},
    // {code:"22",desVi:"Sơn La"},
    // {code:"66",desVi:"Tây Ninh"},
    // {code:"36",desVi:"Thái Bình"},
    // {code:"TN",desVi:"Thái Nguyên"},
    // {code:"37",desVi:"Thanh Hóa"},
    // {code:"54",desVi:"Thừa Thiên Huế"},
    // {code:"73",desVi:"Tiền Giang"},
    // {code:"74",desVi:"Trà Vinh"},
    // {code:"27",desVi:"Tuyên Quang"},
    // {code:"70",desVi:"Vĩnh Long"},
    // {code:"VP",desVi:"Vĩnh Phúc"},
    // {code:"29",desVi:"Yên Bái"},
  ]

  export const MockDistrict: Array<AddressOption> = [
    {
      code: '01',
      desVi: 'Quận 1'
    }
  ]

  export const MockWard: Array<AddressOption> = [
    {
      code: 'BN',
      desVi: 'Bến Nghé'
    },
    {
      code: 'BT',
      desVi: 'Bến Thành'
    },
    {
      code: 'CG',
      desVi: 'Cô Giang'
    },
    {
      code: 'CK',
      desVi: 'Cầu Kho'
    },
    {
      code: 'CO',
      desVi: 'Cầu Ông Lãnh'
    },
    {
      code: 'DK',
      desVi: 'ĐaKao'
    },
    {
      code: 'NC',
      desVi: 'Nguyễn Cư Trinh'
    }
  ]

  export const EmailIndicator: Array<{
    value: string
    labelEN: string
    labelVI: string
  }> = [
    {
      value: '1',
      labelVI: 'Thông tin hợp đồng',
      labelEN: 'Policy info'
    },
    {
      value: '3',
      labelVI: 'Thông tin hợp đồng & Prudential',
      labelEN: 'Policy & Prudential info'
    },
    {
      value: '4',
      labelVI: 'Từ chối nhận email',
      labelEN: 'Reject'
    }
  ]

  export const SMSIndicator: Array<{
    value: string
    labelEN: string
    labelVI: string
  }> = [
    {
      value: '1',
      labelVI: 'Thông tin hợp đồng',
      labelEN: 'Policy info'
    },
    {
      value: '3',
      labelVI: 'Thông tin hợp đồng & Prudential',
      labelEN: 'Policy & Prudential info'
    },
    {
      value: '4',
      labelVI: 'Từ chối nhận SMS',
      labelEN: 'Reject'
    }
  ]

  export const NationalOption: Array<OptionField> = [
    {
      label: 'Việt Nam',
      value: 'VN'
    },
    {
      label: 'Hoa Kỳ',
      value: 'USA'
    },
    {
      label: 'Nam Phi',
      value: 'ZA'
    },
    {
      label: 'Guam',
      value: 'GUM'
    },
    {
      label: 'Anh',
      value: 'GB'
    },
    {
      label: 'Pháp',
      value: 'F'
    },
    {
      label: 'Singapore',
      value: 'SGP'
    },
    {
      label: 'Iraq',
      value: 'IRQ'
    },
    {
      label: 'Iran',
      value: 'IR'
    },
    {
      label: 'Palestine',
      value: 'PSE'
    },
    {
      label: 'Công Gô',
      value: 'COG'
    }
  ]

  export const mapNational = new Map<string | null | undefined, string>()
  NationalOption.forEach((n) => mapNational.set(n.value, n.label))
}
