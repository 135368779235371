export const validateMail = (mail: string) => {
  if (/^\w+([\.-]?\w)*@\w+([\.-]?\w)*(\.\w{2,3})$/.test(mail)) {
    return true
  }
  return false
}

export const statusOption = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE'
  }
]

export const getTitleStatus = (status: string) => {
  return statusOption.find((i) => i.value === status)?.label ?? ''
}

export const getStyleStatus = (status: string) => {
  return { color: status === 'Active' ? '#1DC06F' : (status === 'Inactive' ? '#ED1B2D' : '#fffff'), fontSize: 15 }
}