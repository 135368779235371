import { Frequency } from './../entity/Frequency'

export namespace FrequencyMapping {
  export const FrequencyInfo = {
    Annual: `Annual`,
    HalfYearly: `Half yearly`,
    Quarterly: `Quarterly`,
    Monthly: `Monthly`
  }

  export const mapFrequency = new Map<Frequency | string | null | undefined, string>([
    [Frequency.Annual, FrequencyInfo.Annual],
    ['Annual', FrequencyInfo.Annual],
    [Frequency.HalfYearly, FrequencyInfo.HalfYearly],
    ['Half Yearly', FrequencyInfo.HalfYearly],
    [Frequency.Quarterly, FrequencyInfo.Quarterly],
    ['Quarterly', FrequencyInfo.Quarterly],
    [Frequency.Monthly, FrequencyInfo.Monthly],
    ['Monthly', FrequencyInfo.Monthly]
  ])

  export const FrequencyOption: Array<{ value: Frequency; label: string }> = [
    {
      value: Frequency.Annual,
      label: mapFrequency.get(Frequency.Annual) ?? ''
    },
    {
      value: Frequency.HalfYearly,
      label: mapFrequency.get(Frequency.HalfYearly) ?? ''
    },
    {
      value: Frequency.Monthly,
      label: mapFrequency.get(Frequency.Monthly) ?? ''
    },
    {
      value: Frequency.Quarterly,
      label: mapFrequency.get(Frequency.Quarterly) ?? ''
    }
  ]
}
