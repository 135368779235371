import { Task, Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { AppConfig, AuthService, POApi, StorageBlobApi } from '@pulseops/common'
import { VoiceRecordingModels } from './VoiceRecordingModels'

export namespace VoiceRecordingServices {
  export const getVoiceData = (processId: string): Task<VoiceRecordingModels.VoiceListC> =>
    pipe(
      POApi.get(`independence-rest/api/v1/voice/record-data/${processId}`)(VoiceRecordingModels.VoiceListC),
      ZIO.mapError((err) => {
        return Throwable(err)
      }),
      ZIO.flatMap((response) => {
        return ZIO.succeed(response)
      })
    )

  export const getVoiceDetail = (processId: string, filename: string): Task<VoiceRecordingModels.VoiceRecordDetailC> =>
    pipe(
      POApi.get(`independence-rest/api/v1/voice/record-check-result/${processId}?filename=${filename}`)(
        VoiceRecordingModels.VoiceRecordDetailC
      ),
      ZIO.mapError((err) => {
        return Throwable(err)
      }),
      ZIO.flatMap((response) => {
        return ZIO.succeed(response)
      })
    )

  export const getConfigTranscript = (script: string, processId: string): Task<VoiceRecordingModels.ConfigTrainscriptResponse> =>
    pipe(
      POApi.get(`independence-rest/api/v1/voice/content-voice-script/${processId}/${script}`)(VoiceRecordingModels.ConfigTrainscriptResponse),
      ZIO.map((res) => res)
    )

  export const getAudio = (azureUrl: string) =>
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, AppConfig.get),
      ZIO.flatMap(([token, loginType, config]) => {
        const currVersion = config.version.split('.').join('-') || '1-0-0'
        return ZIO.fromPromise(() => {
          return fetch(
            `${config.apiUrl}/independence-rest/${currVersion}/api/v1/voice/record-audio?azureUrl=${azureUrl}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            }
          )
        })
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        return {
          res: res,
          blob: blob
        }
      })
    )

  export const submitAIreasonUser = (
    data: VoiceRecordingModels.ResultListUserC
  ): Task<VoiceRecordingModels.ReasonUserC> =>
    pipe(
      POApi.post('independence-rest/api/v1/voice/record-check-result')(VoiceRecordingModels.ReasonUserC)(data),
      ZIO.map((res) => res)
    )

  export const getDynamicKeyWord = (
    processId: string,
    filename: string
  ): Task<Array<VoiceRecordingModels.DynamicKeywordC>> =>
    pipe(
      POApi.get(`independence-rest/api/v1/voice/dynamic-keyword-data/${processId}?documentId=${filename}`)(
        VoiceRecordingModels.DynamicKeywordResponseC
      ),
      ZIO.mapError((err) => {
        return Throwable(err)
      }),
      ZIO.flatMap((response) => {
        return ZIO.succeed(response.body ?? [])
      }),
      ZIO.catchAll(() => ZIO.succeed([])),
    )
    export const getApplicationInfo = (
      processId: string,
      source: string
    ): Task<VoiceRecordingModels.ApplicationInfoC> =>
      pipe(
        POApi.get(`independence-rest/api/v1/voice/submit-data/${processId}?source=${source}`)(
          VoiceRecordingModels.ApplicationInfoResponseC
        ),
        ZIO.mapError((err) => {
          return Throwable(err)
        }),
        ZIO.flatMap((response) => {
          return ZIO.succeed(response.body || {})
        }),
      )
}
