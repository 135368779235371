import { StyleSheet, View, Text, Dimensions, TouchableOpacity, Pressable } from 'react-native'
import React from 'react'
import { assets } from '@pulseops/common'
import {
  IBDivider,
  DashBoardHeader,
  DashBoardContent,
  DashBoardTreeView,
  IBDashBoardContentIconProps,
  TypeDashBoardComponent,
  IBDashBoardContentPopupProps
} from '../../common'
import { Collapse } from '@material-ui/core'
import _ from 'lodash'
const IBDashBoardHeader = (props: DashBoardHeader) => {
  const renderVIP = (vip?: string | null) => {
    switch (vip) {
      case 'V':
        return <assets.IBVipGold />
      case 'P':
        return <assets.IBVip1 />
      case 'K':
        return <assets.IBVipDiamond />
      default:
        return null
    }
  }
  return (
    <View style={styles.headerContainer}>
      <View style={{ width: renderVIP(props.level) ? 60 : 0 }}>
        {renderVIP(props.level) ? renderVIP(props.level) : <></>}
      </View>
      <View style={styles.headerRight}>
        <Text style={[styles.textStyle, { fontWeight: 'bold', color: '#ED1B2E', marginBottom: 7 }]}>
          {props.clientName}
        </Text>
        <Text style={styles.textStyle}>{`${props.nationName ? props.nationName + ' - ' : ''}${props.gender}`}</Text>
      </View>
    </View>
  )
}

const IBDashBoardContent = (props: DashBoardContent) => {
  return (
    <View style={{ paddingHorizontal: 15 }}>
      {props.dropDownShow ? (
        <View>
          <Text style={{ fontWeight: 'bold', marginBottom: 5, fontSize: 15 }}>{props.label ? props.label : <></>}</Text>
          <Pressable onPress={props.onClick} disabled={_.isNil(props.onClick)}>
            <Text
              style={[
                styles.textStyle,
                {
                  color: props.color ? props.color : '#000000',
                  fontWeight: props.bold ? 'bold' : 'normal',
                  display: props.visible ? 'none' : 'flex',
                  textDecorationLine: props.textDecorate
                }
              ]}
            >
              {props.value}
            </Text>
          </Pressable>
        </View>
      ) : (
        <Pressable onPress={props.onClick} disabled={_.isNil(props.onClick)}>
          <Text
            style={[
              styles.textStyle,
              {
                color: props.color ? props.color : '#000000',
                fontWeight: props.bold ? 'bold' : 'normal',
                display: props.visible ? 'none' : 'flex',
                textDecorationLine: props.textDecorate
              }
            ]}
          >
            {props.label ? <Text style={{ fontWeight: 'bold' }}>{props.label}: </Text> : <></>}
            {props.value}
          </Text>
        </Pressable>
      )}
    </View>
  )
}

const IBDashBoardContentTreeView = (props: DashBoardTreeView) => {
  const { label, value, treeView } = props
  const [open, setOpen] = React.useState<boolean>(false)
  return (
    <View>
      <View style={styles.treeView}>
        <IBDashBoardContent label={label} value={value} />
        <TouchableOpacity onPress={() => setOpen(!open)}>
          {open ? <assets.IBCollapseDown /> : <assets.IBCollapseRight />}
        </TouchableOpacity>
      </View>
      <View style={{ marginTop: open ? 5 : 0 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {treeView.map((item, index) => (
            <View style={{ marginBottom: 5 }}>
              <IBDashBoardContent
                label={item.label}
                value={item.value}
                color={item.color}
                visible={item.visible}
                bold={item.bold}
                textDecorate={item.textDecorate}
                onClick={item.onClick}
              />
            </View>
          ))}
        </Collapse>
      </View>
    </View>
  )
}

const IBDashBoardContentIcon = (props: IBDashBoardContentIconProps) => {
  const { icon, textNoti, content } = props
  return (
    <View style={{ paddingHorizontal: 15 }}>
      <View style={{ flexDirection: 'row', marginBottom: textNoti ? 10 : 0 }}>
        <View style={{ marginRight: 5 }}>{icon}</View>
        <View style={{ width: '100%', paddingRight: 30 }}>{content.map((i) => i.value)}</View>
      </View>
      {textNoti ? <Text style={styles.textNoti}>{textNoti}</Text> : <></>}
    </View>
  )
}

const IBDashBoardContentPopup = (props: IBDashBoardContentPopupProps) => {
  const { label, value, openPopup } = props
  return (
    <View style={styles.treeView}>
      <IBDashBoardContent label={label} value={value} />
      <TouchableOpacity style={{ alignSelf: 'center' }} onPress={openPopup} disabled={!value}>
        <assets.IBOpenPopupIcon />
      </TouchableOpacity>
    </View>
  )
}
interface Props {
  typeDashBoardComponent: (
    | DashBoardHeader
    | DashBoardContent
    | DashBoardTreeView
    | IBDashBoardContentIconProps
    | IBDashBoardContentPopupProps
  )[]
}

export const IBClientDashBoardScreen = (props: Props) => {
  const { typeDashBoardComponent } = props
  const height = Dimensions.get('window').height
  return (
    <View style={[styles.container, { height: height + 200 }]}>
      {typeDashBoardComponent.map((item, index) => {
        switch (item.type) {
          case TypeDashBoardComponent.HEADER:
            return (
              <>
                <IBDashBoardHeader
                  clientName={item.clientName}
                  nationName={item.nationName}
                  gender={item.gender}
                  level={item.level}
                />
                {item.divider && (
                  <IBDivider
                    marginHorizontal={item.divider.marginHorizontal}
                    borderStyle={item.divider.borderStyle}
                    hidden={item.divider.hidden}
                  />
                )}
              </>
            )
          case TypeDashBoardComponent.CONTENT: {
            if (item.hidden) return <></>
            return (
              <>
                <IBDashBoardContent
                  label={item.label}
                  value={item.value}
                  color={item.color}
                  dropDownShow={item.dropDownShow}
                />
                {item.divider && (
                  <IBDivider
                    marginHorizontal={item.divider.marginHorizontal}
                    borderStyle={item.divider.borderStyle}
                    hidden={item.divider.hidden}
                  />
                )}
              </>
            )
          }

          case TypeDashBoardComponent.CONTENT_ICON: {
            if (item.hidden) return <></>
            return (
              <>
                <IBDashBoardContentIcon icon={item.icon} content={item.content} textNoti={item.textNoti} />
                {item.divider && (
                  <IBDivider marginHorizontal={item.divider.marginHorizontal} borderStyle={item.divider.borderStyle} />
                )}
              </>
            )
          }

          case TypeDashBoardComponent.TREEVIEW: {
            if (item.hidden) return <></>
            return (
              <>
                <IBDashBoardContentTreeView
                  label={item.label}
                  value={item.value}
                  treeView={item.treeView}
                  type={item.type}
                />
                {item.divider && (
                  <IBDivider
                    marginHorizontal={item.divider.marginHorizontal}
                    borderStyle={item.divider.borderStyle}
                    hidden={item.divider.hidden}
                  />
                )}
              </>
            )
          }
          case TypeDashBoardComponent.CONTENT_POPUP: {
            if (item.hidden) return <></>
            return (
              <>
                <IBDashBoardContentPopup label={item.label} value={item.value} openPopup={item.openPopup} />
                {item.divider && (
                  <IBDivider
                    marginHorizontal={item.divider.marginHorizontal}
                    borderStyle={item.divider.borderStyle}
                    hidden={item.divider.hidden}
                  />
                )}
              </>
            )
          }
          default:
            return <></>
        }
      })}
      {/* <View>
        <TouchableOpacity style={styles.btnRefresh}>
          <assets.IBVectorIcon />
          <Text style={{ color: '#ED1B2E', fontSize: 16, marginLeft: 5 }}>Refresh</Text>
        </TouchableOpacity>
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%'
  },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    marginTop: 10
  },
  headerRight: {
    width: 210,
    paddingTop: 15
  },
  treeView: {
    paddingRight: 15,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textNoti: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    fontSize: 16
  },
  btnRefresh: {
    borderWidth: 1,
    borderColor: '#ED1B2E',
    paddingVertical: 8,
    paddingHorizontal: 12,
    margin: 15,
    borderRadius: 30,
    justifyContent: 'center',
    flexDirection: 'row'
  },
  textStyle: {
    fontSize: 15
  }
})
