import { FileUploadData2, SelectOption } from '@pulseops/common'
import { VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
export interface InquiryComplaintForm {
  caseInfo: {
    group: SelectOption | null
    type: SelectOption | null
    subType: SelectOption | null
    requestorRole: SelectOption | null
    requestorFullName: string
    requestorFullName1: SelectOption | null
    relationshipWithPolicyOwner: SelectOption | null
    method: SelectOption | null
    source: SelectOption | null
    hot: boolean
    firstContactResolution: string
    requestorNationalID: string
  },
  GA: {
    generalAgency: SelectOption | null
    personToBeComplained: string
    role: SelectOption | null
    verifiedAmount: string
    evidenceStatus: SelectOption | null
  }
  salesPractice: {
    financialNeedAnalysis: SelectOption | null
    POSignedInProposal: string
    customerReceivedPolicyKit: string
    customerReceivedDate: Date
    evidence: string
  }
  moneyMissappropriation: {
    list: {
      // receipt: boolean
      receipt: string
      receiptType: SelectOption | null
      receiptNumber: string
      complaintAmount: string
      benefit_PremiumMisappropriatedDate: any
      evidenceStatus: SelectOption | null
      verifiedAmount: string
    }[]
    totalComplaintAmount: string
    totalVerifiedAmount: string
  }
  communicationInfo: {
    customerComments: string
    responsesToCustomer: string
    ClosedInfo: string
  }
  resolution: ResolutionInfo
  conservation: {
    conservationGAStaff: boolean
    conservationAgentCode: string
    conservationAgentName: string
    reasonFullSurrender: SelectOption | null
    conservationResult: SelectOption | null
    conservationSolution: SelectOption | null
    conservationBonus: SelectOption | null
    conservationNote: string
  }
  additionalDocument: AdditionalDocumentForm[]
  payloadData: VeriPayloadData
  isSavePayloadData: boolean,
  callId: string
}

export interface ResolutionInfo {
  complaintAgainst: SelectOption | null
  significant: boolean
  agentCode: string
  agentName: string
  resolution: SelectOption | null
  responseMethod: SelectOption | null
  reasonForDealing: SelectOption | null
  correctiveSettlement: SelectOption | null
  detail: string
  amount: string
  changetype_Subtype: string
  reason: SelectOption | null
  reasonForExceedingTAT: SelectOption | null
  finalGroup: SelectOption | null
  finalType: SelectOption | null
  finalSubType: SelectOption | null
}

export interface AdditionalDocumentForm {
  isAzuredUpdated: boolean
  transactionType: SelectOption | null
  attachment: FileUploadData2[]
  metaData: {
    type: string
    doctype: string
    class: string
    docid: string
    maindoc: string
    subdoc: string
    polnum: string
    batchno: string
  }
}

export interface DiaryForm {
  diaryType: SelectOption | null
  description: string
}

export const VericomplaintList = ['CHG1', 'CHG2', 'CHG3', 'CHG4']
