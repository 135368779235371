import { COLORS } from './../constants'
import { View, Text } from 'react-native'
import styled from 'styled-components/native'
import { TextCustomI } from './models'

export const TextCustom = styled(Text)<TextCustomI>`
  color: ${(props) => props?.textColor ?? 'black'};
  font-size: ${(props) => props?.fontSize ?? '16px'};
  font-weight: ${(props) => props?.fontWeight ?? 'normal'};
  font-style: ${(props) => props?.fontStyle ?? 'normal'};
  margin-top: 4px;
`

export const SC = {
  Container: styled(View)`
    width: 100%;
    height: 100%;
    background-color: ${COLORS.WHITE};
  `,

  Header: styled(View)`
    width: 100%;
    margin-top: 8px;
    padding: 20px;
  `,

  Body: styled(View)`
    background: ${COLORS.SNOW};
    border: 1px solid ${COLORS.HOWKES_BLUE};
    border-radius: 8px;
    padding: 30px;
    width: 98%;
    margin: 0 auto;
  `,

  Footer: styled(View)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
  `
}

export const FA = {
  Container: styled(View)`
    width: 70%;
  `,
  RowContainer: styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 32px;
  `,
  SelectContainer: styled(View)<{ isMobile?: boolean; width?: string }>`
    width: ${(p) => p.width || (p.isMobile ? '100%' : '348px')};
  `,

  TabText: styled(Text)`
    color: red;
    font-weight: bold;
    font-size: medium;
    padding: 5px 0px 5px 0px;
  `
}
