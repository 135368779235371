import React from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import {
  assets,
  DatePicker,
  ErrorHandling,
  Input,
  ModalAction,
  ModalComponent,
  UnderwrittingService
} from '@pulseops/common'
import { FieldText, SectionCol, SectionRow } from '@pulseops/submission/common'
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Controller, FieldArrayWithId, useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { UnderwrittingForm } from './UnderwrittingForm'
import moment from 'moment'
// import { NotesModal, UnderwrittingNotesModalForm } from './UnderwrittingForm'

export interface NoteDetail {
  clientNumber: string
  clientName: string
  parentID: number
  isDisabledItem: boolean
  isNewLA?: boolean
}

type NotesModalProp = {
  title: string
  isVisible: boolean
  onClose: () => void
  policyNum: string
  detailData: NoteDetail | undefined
  disabled: boolean
  underwrittingForm: UseFormReturn<UnderwrittingForm.UnderwrittingFormInfo & { isUpdatedDecision: boolean }>
  saveNotes: (itemList: UnderwrittingForm.HistoryNote[], parentID: number) => void
}

export const UnderwrittingNotesModal = ({
  title,
  isVisible,
  onClose,
  policyNum,
  detailData,
  saveNotes,
  disabled,
  underwrittingForm
}: NotesModalProp) => {
  const { t } = useTranslation()
  const [noteHistoryList, setNoteHistoryList] = React.useState<UnderwrittingService.SaveNoteData[]>([])
  const { control, watch } = underwrittingForm
  const [isLoadingBar, setIsLoadingBar] = React.useState<boolean>(false)
  const parentID = detailData?.parentID ?? 0
  const { fields, append, remove, insert } = useFieldArray({
    control: control,
    name: `UWDecisionList.${parentID}.historyNotes`
  })

  // pipe(
  //   detailData && detailData?.clientNumber
  //     ? UnderwrittingService.getNoteList(detailData?.clientNumber)
  //     : ZIO.succeed([]),
  //   ZIO.map((historyList) => {
  //     setNoteHistoryList(historyList)
  //     return historyList
  //   }),
  //   ErrorHandling.runDidUpdate([detailData?.clientNumber])
  // )

  React.useEffect(() => {
    if (detailData && detailData.clientNumber) {
      getHistoryNoteList()
    }
    // if (detailData && detailData.clientNumber && watch('listNotes').length <= 0) {
    //   addNoteItem()
    // }
  }, [detailData?.clientNumber])

  const getHistoryNoteList = () => {
    setIsLoadingBar(true)
    pipe(
      UnderwrittingService.getNoteList(detailData?.clientNumber ?? ''),
      ZIO.map((historyList) => {
        setNoteHistoryList(historyList)
        return historyList
      }),
      ZIO.foldM(
        (_) =>
          ZIO.effect(() => {
            setIsLoadingBar(false)
          }),
        (_) =>
          ZIO.effect(() => {
            setIsLoadingBar(false)
          })
      ),
      ZIO.unsafeRun({})
    )
  }

  const deleteNoteItem = (index: number) => {
    remove(index)
  }

  const addNoteItem = () => {
    append({
      eventDate: new Date(),
      policyNo: policyNum,
      notes: '',
      clientNumber: detailData?.clientNumber ?? '',
      isOldNote: false
    })
  }

  const onSaveNotes = () => {
    // saveNotes(watch('listNotes'), detailData?.parentID ?? 0)
    onClose()
  }

  const getEventDate = (eventData: string) => {
    return moment(eventData).format('DD/MM/YYYY')
  }

  const modalActions: ModalAction[] = disabled
    ? []
    : [
      {
        text: t('Underwritting:AddNew'),
        type: 'filled',
        disabled: false,
        loading: false,
        onlyWide: false,
        style: { height: 39, marginEnd: 15 },
        action: () => addNoteItem()
      }
    ]

  return (
    <ModalComponent title={title} visible={isVisible} modalWidth={700} onClose={onClose} actions={modalActions}>
      <ScrollView style={noteStyles.modalContent}>
        <SectionRow>
          <SectionCol>
            <FieldText text={t('common:ClientNumber')}></FieldText>
            <FieldText text={!detailData?.isNewLA ? (detailData?.clientNumber ?? '') : ''}></FieldText>
          </SectionCol>
          <SectionCol>
            <FieldText text={t('submission:ClientName')}></FieldText>
            <FieldText text={detailData?.clientName ?? ''}></FieldText>
          </SectionCol>
        </SectionRow>
        <View>
          {isLoadingBar ? (
            <LinearProgress style={{ marginTop: 15 }} color="secondary" />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#e2e7ea' }}>
                    <TableCell>{t('Underwritting:EventDate')}</TableCell>
                    <TableCell style={{ minWidth: 100 }}>{t('Underwritting:PolicyNo')}</TableCell>
                    <TableCell style={{ minWidth: 250 }}>{t('Underwritting:Notes')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {noteHistoryList &&
                    noteHistoryList.map((noteItem) => (
                      <TableRow>
                        <TableCell>{getEventDate(noteItem.eventDate)}</TableCell>
                        <TableCell>{noteItem.policyNumber}</TableCell>
                        <TableCell>{noteItem.note}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                  {fields &&
                    fields.length > 0 &&
                    fields.map((fieldItem, index) => (
                      <TableRow>
                        <TableCell>
                          <Controller
                            control={control}
                            name={`UWDecisionList.${parentID}.historyNotes.${index}.eventDate`}
                            render={({ field: { value, onChange, onBlur }, fieldState }) => (
                              <DatePicker
                                // disabled={watch(`listNotes.${index}.isOldNote`)}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>{fieldItem.policyNo}</TableCell>
                        <TableCell>
                          <Controller
                            control={control}
                            name={`UWDecisionList.${parentID}.historyNotes.${index}.notes`}
                            render={({ field: { value, onChange, onBlur }, fieldState }) => (
                              <Input
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                numberOfLines={2}
                                multiline={true}
                                inputStyle={{ padding: 5 }}
                                alwayShowUnderline={false}
                                filled={true}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          {!disabled && (
                            <TouchableOpacity onPress={() => deleteNoteItem(index)}>
                              <assets.DeleteIcon />
                            </TouchableOpacity>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </View>
      </ScrollView>
    </ModalComponent>
  )
}
const noteStyles = StyleSheet.create({
  modalContent: {
    marginHorizontal: 20
  },
  tableHead: {
    backgroundColor: '#e2e7ea'
  }
})
