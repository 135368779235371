import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  ChangeContactInfoService,
  ErrorHandling,
  GeneralService,
  Label,
  Locale,
  Panel,
  SourceType,
  TaskDetail
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { TFunctionResult } from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'
import { InfoViewList, Title } from '../common'
import { DuplicateInfoTable } from './common'
import { Address } from './mock'

interface Props {
  detail: TaskDetail.ChangeContactInfo
}

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

export const ChangeContactInfo = ({ detail }: Props) => {
  const { t, i18n } = useTranslation()
  const [cityName, setCityName] = React.useState<string>('-')
  const [districtName, setDistrictName] = React.useState<string>('-')
  const [wardName, setWardName] = React.useState<string>('-')
  const [contactInfo, setContactInfo] = React.useState<any>(null)

  const [isLoading, bindLoading] = useLoading(false)

  const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []

  React.useEffect(() => {
    const { city, district, ward } = detail
    pipe(
      ZIO.zipPar(
        GeneralService.getProvinces,
        GeneralService.getDistricts(city),
        GeneralService.getWards({ provinceCode: city, districtCode: district }),
        ChangeContactInfoService.getDetail(detail?.policies[0]?.policyNumber || '')
      ),
      ZIO.tap(([provinces, districts, wards, contactInfoDetail]) => {
        const cityName = provinces.find((d) => d.code === city)?.name || '-'
        setCityName(cityName)
        const districtName = districts.find((d) => d.code === district)?.name || '-'
        setDistrictName(districtName)
        const wardName = wards.find((d) => d.code === ward)?.name || '-'
        setWardName(wardName)

        setContactInfo(contactInfoDetail)

        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [detail])

  const renderLoading = () => {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="red" />
      </View>
    )
  }

  const getSMSIndicator = (phoneInd: string | any) => {
    const indicator = Address.SMSIndicator.find((e) => e.value === phoneInd)
    return indicator ? (i18n.language === Locale.en ? indicator.labelEN : indicator.labelVI) : '-'
  }

  const getEmailIndicator = (emailInd: string): string => {
    const indicator = Address.EmailIndicator.find((e) => e.value === emailInd)
    return indicator ? (i18n.language === Locale.en ? indicator.labelEN : indicator.labelVI) : '-'
  }

  const addressFields: Output[] = [
    {
      label: t('requestInfo:Country'),
      value: detail.country,
      isHighlight: detail.countryCode !== contactInfo?.nationality
    },
    {
      label: t('contact:CityProvince'),
      value: cityName,
      isHighlight: detail.city !== contactInfo?.province
    },
    {
      label: t('requestInfo:District'),
      value: districtName,
      isHighlight: detail.district !== contactInfo?.district
    },
    {
      label: t('contact:WardSubDistrict'),
      value: wardName,
      isHighlight: detail.ward !== contactInfo?.ward
    },
    {
      label: t('contact:Address'),
      value: detail.street,
      isHighlight: detail.street !== contactInfo?.street
    }
  ]

  const phoneFields: Output[] = [
    {
      label: t('requestInfo:MobilePhone1'),
      isHighlight: detail?.mobilePhoneNumber !== contactInfo?.mobilePhoneNumber,
      value:
        detail.mobilePhoneCode && detail.mobilePhoneNumber
          ? `+${detail.mobilePhoneCode} ${detail.mobilePhoneNumber}`
          : '-'
    },
    {
      label: t('requestInfo:CompanyPhone'),
      isHighlight: detail?.officePhoneNumber !== contactInfo?.officePhoneNumber,
      value:
        detail.officePhoneCode && detail.officePhoneNumber
          ? `+${detail.officePhoneCode} ${detail.officePhoneNumber}`
          : '-'
    },
    {
      label: t('requestInfo:SMSIndicator'),
      value: getSMSIndicator(detail?.smsIndicator || '-'),
      isHighlight: detail?.smsIndicator !== contactInfo?.smsIndicator
    },
    {
      label: t('requestInfo:Verification'),
      value:
        (detail.mobilePhoneCode && detail.mobilePhoneNumber) ||
        (detail.officePhoneCode && detail.officePhoneNumber) ||
        getSMSIndicator(detail?.smsIndicator || '-') !== '-'
          ? detail.attributesExt?.mainPhoneNumber
            ? t('requestInfo:OwnerSubscriptionDocument')
            : t('requestInfo:OTPDocument', {
                code: detail.otpTransaction?.otp,
                phone: detail.otpTransaction?.receiverPhone
              })
          : '',
      isHighlight: detail?.smsIndicator !== contactInfo?.smsIndicator
    }
  ]

  const emailFields: Output[] = [
    {
      label: t('requestInfo:Email'),
      isHighlight: detail?.ward !== contactInfo?.ward,
      value: detail.email || '-'
    },
    {
      label: t('requestInfo:EmailIndicator'),
      isHighlight: detail?.emailIndicator !== contactInfo?.emailIndicator,
      value: getEmailIndicator(detail?.emailIndicator || '')
    }
  ]

  const isPulse4Ops = (): boolean => {
    return (
      detail.source === SourceType.PULSE4OPS ||
      detail.source === SourceType.OUTBOUND ||
      detail.source === SourceType.INBOUND
    )
  }

  const isBancaSea = (): boolean => {
    return detail.source === SourceType.BANCA_SEA
  }

  const isZalo = (): boolean => {
    return detail.source === SourceType.ZALO
  }

  const isPruonline = (): boolean => {
    return detail.source === SourceType.PRUONLINE
  }

  const isActiveAddress = () => {
    return addressFields.some((e) => e.value !== '-' && e.value !== '')
  }

  const isActiveEmail = () => {
    return emailFields.some((e) => e.value !== '-' && e.value !== '')
  }

  const isActivePhone = () => {
    return phoneFields.some((e) => e.value !== '-' && e.value !== '')
  }

  const getRelationshipFromT16s1 = (code: string) => {
    const found = relationshipT16s1.find((item) => item.code === code)
    return {
      en: found?.relationshipEN || '',
      vi: found?.relationshipVN || ''
    }
  }

  const phoneDupData =
    detail?.attributesExt?.PHONES_DUP.map((item) => ({
      clientNumber: item.clientId,
      clientName: item.clientName,
      relationship: getRelationshipFromT16s1(item.veriRelationship?.code || '')
    })) || []

  const emailDupData =
    detail?.attributesExt?.EMAILS_DUP.map((item) => ({
      clientNumber: item.clientId,
      clientName: item.clientName,
      relationship: getRelationshipFromT16s1(item.veriRelationship?.code || '')
    })) || []

  return isLoading ? (
    renderLoading()
  ) : (
    <View style={{ flex: 1 }}>
      {addressFields.length > 0 && isActiveAddress() && (
        <Panel
          title={''}
          isExand={false}
          titleOutline={
            isPulse4Ops() || isBancaSea() || isZalo() || isPruonline() ? t('requestInfo:ChangeAddress') : ''
          }
        >
          <InfoViewList dataSource={addressFields} />
          {detail.policies && detail.policies.length > 0 && (
            <>
              <Text style={styles.label}>{t('requestInfo:UpdatedForOtherPolicy')}</Text>
              {detail.policies.map(({ policyNumber, address }, index) => (
                <>
                  {detail.source !== SourceType.ZALO ? (
                    <Text key={index}>{`${policyNumber} ${address}`}</Text>
                  ) : (
                    <Text
                      key={index}
                    >{`${policyNumber} ${detail?.street}, ${wardName}, ${districtName}, ${cityName}`}</Text>
                  )}
                </>
              ))}
            </>
          )}
        </Panel>
      )}

      {(isPulse4Ops() || isBancaSea() || isZalo() || isPruonline()) && phoneFields.length > 0 && isActivePhone() && (
        <>
          <Title title={t('requestInfo:ChangePhoneNumber')} />
          <Panel title={''} isExand={false}>
            <InfoViewList dataSource={phoneFields} />
            {phoneDupData.length > 0 && (
              <>
                <Label title={t('requestInfo:dupPhone')} />
                <View style={{ marginTop: 6 }}>
                  <DuplicateInfoTable data={phoneDupData} />
                </View>
              </>
            )}
          </Panel>
        </>
      )}

      {(isPulse4Ops() || isBancaSea() || isZalo() || isPruonline()) && emailFields.length > 0 && isActiveEmail() && (
        <>
          <Title title={t('requestInfo:ChangeEmail')} />
          <Panel title={''} isExand={false}>
            <InfoViewList dataSource={emailFields} />
            {isPulse4Ops() && emailDupData.length > 0 && (
              <>
                <Label title={t('requestInfo:dupEmail')} />
                <View style={{ marginTop: 6 }}>
                  <DuplicateInfoTable data={emailDupData} />
                </View>
              </>
            )}
          </Panel>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },

  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },

  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '50%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  mR15: {
    marginRight: 15
  }
})
