import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

export const ZaloClaimDecision = t.type({
  transactionType: t.literal(TransactionType.ZALO_CLAIM_DECISION),
  payload: t.type({
    body: t.type({
      message: Maybe(t.string)
    })
  })
})
export type ZaloClaimDecision = t.TypeOf<typeof ZaloClaimDecision>
