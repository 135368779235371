import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, View, Text } from 'react-native'
import { AppContext, assets, DatePicker, formatNumberWithComma, Input, JournalService } from '@pulseops/common'
import { JournalsForm } from './transfer-journal-form'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
interface Props {
  disabled: boolean
  form: UseFormReturn<JournalsForm.JournalsData, object>
  policyNum: string
}

export const BonusJournalForm: React.FC<Props> = ({ disabled, form, policyNum }) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      form.setValue('journalBonus.accruedReversionaryBonus', '')
      form.setValue('journalBonus.effectiveDate', new Date())
      form.setValue('journalBonus.arbAmount', '')
    }
    pipe(
      JournalService.getCurrentBalanceData(policyNum, 'LE', 'RV'),
      ZIO.map((data) => {
        form.setValue('journalBonus.accruedReversionaryBonus', data.toString())
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const replaceAll = (str: string, from: string, to: string) => {
    return str.replace(new RegExp(from, 'g'), to)
  }

  const changeToAmount = (value: string) => {
    const checkLastValueNegative = value.indexOf('-')
    const valueRep = value !== '-' ? '-' + formatNumberWithComma(value.replace('-', '')) : value
    const lastValue = checkLastValueNegative !== -1 ? valueRep : formatNumberWithComma(value)
    return lastValue
  }

  return (
    <SafeAreaView style={style.container}>
      <View style={style.row}>
        <View style={style.col_4_first}>
          <Controller
            name="journalBonus.effectiveDate"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020001', { field: t('Journal:EffectiveDate') })
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                disabled={disabled}
                required
                label={t('Journal:EffectiveDate')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View style={style.col_4}>
          <Controller
            name="journalBonus.accruedReversionaryBonus"
            control={form.control}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <Input
                disabled={true}
                required
                title={t('Journal:AccruedBonus')}
                value={value ? changeToAmount(value) : ''}
                onBlur={onBlur}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
        <View style={style.col_4}>
          <View style={{ flexDirection: 'row' }}>
            <Controller
              name="journalBonus.arbAmount"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS050312')
                },
                validate: (value) => {
                  const reverBonus = form.getValues(`journalBonus.accruedReversionaryBonus`)
                  const checkNegative = value.indexOf('-') !== -1
                  const valueRep = replaceAll(value, ',', '')
                  const valueAdjust = value ? Number(valueRep.replace('-', '')) : 0
                  const valueReverBonus = reverBonus ? Number(reverBonus) : 0
                  if (checkNegative && valueAdjust > valueReverBonus) return `${t('message:MS050312')}`
                  return true
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  disabled={disabled}
                  containerStyle={{ width: '70%' }}
                  required
                  title={t('Journal:ARBAmount')}
                  value={value ? changeToAmount(value) : ''}
                  maxLength={20}
                  onBlur={onBlur}
                  onChange={(value) => {
                    const amount = replaceAll(value, ',', '')
                    if (amount.match(/^-?\d*$/) || !value) {
                      onChange(amount)
                    }
                  }}
                  errorMessage={error?.message}
                />
              )}
            />
            <Text style={style.labelVND}>{'VND'}</Text>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  container: {
    marginTop: 10,
    marginLeft: 35,
    marginRight: 20
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 30
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_4_first: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15
  },
  labelVND: {
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 15
  }
})
