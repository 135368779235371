import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST),
        t.literal(TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL)
      ])
    }),
    codec
  ])

export const PartialWithdrawalAutoDebit = Base(
  t.type({
    policyNumber: Maybe(t.string),
    transactionType: Maybe(t.string),
    source: Maybe(t.string),
    payload: t.type({
      body: t.type({
        attributesExt: t.type({
          FUND_LIST: Maybe(
            t.array(
              Maybe(
                t.type({
                  vFund06: t.string,
                  vFund07: t.string,
                  vFund01: t.string,
                  vFund02: t.string,
                  vFund03: t.string,
                  vFund04: t.string,
                  vFund05: t.string
                })
              )
            )
          )
        })
      })
    })
  })
)

export type PartialWithdrawalAutoDebit = t.TypeOf<typeof PartialWithdrawalAutoDebit>
