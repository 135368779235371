import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import { IBGeneralTable, IBGridDataView, IBSearchFilter } from '../../../../../common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { IBService, PaymentInfoDetail, PaymentListData } from '../../../../../ib-service'
import { ZIO } from '@mxt/zio'
import { AppContext, ModalComponent } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { isEmpty, isNil, orderBy } from 'lodash'

const ModalPaymentDetail = (props: {
  visible: boolean
  onClose: (visible: boolean) => void
  data: PaymentListData & { policyNumber: string }
  setData: (data: (PaymentListData & { policyNumber: string }) | undefined) => void
}) => {
  const { width } = useWindowDimensions()
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, data, setData } = props
  const [loading, bindLoading] = useLoading()
  const [dataDetail, setDataDetail] = React.useState<PaymentInfoDetail[]>()
  React.useEffect(() => {
    pipe(
      IBService.getPaymentInfoDetail(data.policyNumber as string, data.reqnNumber as string),
      ZIO.map((res) => {
        setDataDetail(res.data)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
    return () => {
      setData(undefined)
      setDataDetail(undefined)
    }
  }, [])

  const dataTable = [
    {
      label: 'Seq no.',
      field: 'seqNo'
    },
    {
      label: 'Code',
      field: 'code'
    },
    {
      label: 'Type',
      field: 'type'
    },
    {
      label: 'Description',
      field: 'description'
    },
    {
      label: 'Tran key',
      field: 'tranKey'
    },
    {
      label: 'Orig amount',
      field: 'originAmount',
      format: 'money'
    },
    {
      label: 'GL amount',
      field: 'accountingAmount',
      format: 'money'
    }
  ]

  const dataGridView = [
    {
      label: t('AccountName'),
      value: data.accountName || '-'
    },
    {
      label: t('AccountNumber'),
      value: data.bankAccount || '-'
    },
    {
      label: t('IDNumberAccount'),
      value: data.nationalId || '-'
    },
    {
      label: t('BankName'),
      value: `${data.bankKey || '-'} ${data.bankName || '-'}`
    },
    {
      label: t('IssuingDate&AuthoriseOfID'),
      value: data.issDateAndAuthId || '-'
    },
    {
      label: t('IDForPayment'),
      value: data.idForPayment || '-',
      hidden: !isNil(data.bankAccount) && !isEmpty(data.bankAccount)
    }
  ]

  return (
    <ModalComponent
      title={''}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <>
          <Text style={{ fontSize: 20, fontWeight: '700', marginBottom: 10 }}>
            {`${t('TransactionDetail')} - ${data.reqnNumber}`.toUpperCase()}
          </Text>
          <View style={{ marginBottom: 15 }}>
            <View style={{ flexDirection: 'row', marginBottom: 5, justifyContent: 'space-between' }}>
              <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{`${t('VerifyUser')}: ${
                data.verifyUser || '-'
              }`}</Text>
              <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{`${t('AuthorisedBy')}: ${
                data.authorisedBy || '-'
              }`}</Text>
            </View>
            <IBGeneralTable data={dataDetail || []} dataTable={dataTable} loading={loading} />
          </View>
        </>

        {!isEmpty(data.bankName) && !isNil(data.bankName) ? (
          <View>
            <Text style={{ fontSize: 20, fontWeight: '700', marginBottom: 10 }}>
              {t('BankTransferInformation').toUpperCase()}
            </Text>
            <IBGridDataView data={dataGridView} col={2} />
          </View>
        ) : (
          <></>
        )}
      </View>
    </ModalComponent>
  )
}

interface Props {
  policyNumber?: string
}

export const IBPaymentInfo = (props: Props) => {
  const [pvNoSearch, setPvNoSearch] = React.useState<string>('')
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading()
  const [data, setData] = React.useState<PaymentListData[]>([])
  const [dataFilter, setDataFilter] = React.useState<PaymentListData[]>([])
  const { policyNumber } = props
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [dataModal, setDataModal] = React.useState<PaymentListData & { policyNumber: string }>()
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    handleSearchPVNo()
  }, [])

  const handleSearchPVNo = () => {
    pipe(
      IBService.getListPaymentInfo(policyNumber as string),
      ZIO.map((res) => {
        setData(res.data)
        setDataFilter(res.data)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const dataTable = [
    {
      label: t('PaymentNo'),
      field: 'reqnNumber',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              setOpenModal(true)
              const obj = dataFilter.find((x) => x.reqnNumber === value)
              setDataModal({ ...(obj as PaymentListData), policyNumber: policyNumber as string })
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('Type'),
      field: 'type'
    },
    {
      label: t('BankCodePV'),
      field: 'bankCode'
    },
    {
      label: t('Amount'),
      field: 'amount',
      format: 'money'
    },
    {
      label: t('VerifiedDate'),
      field: 'verifyDate',
      format: 'date'
    },
    {
      label: t('AuthorizedDate'),
      field: 'authDate',
      format: 'date'
    },
    {
      label: t('ReturnedDate'),
      field: 'returnedDate',
      format: 'date'
    },
    {
      label: t('ReturnedReason'),
      field: 'reason'
    }
  ]
  return (
    <View style={styles.container}>
      <View style={styles.containerSearch}>
        <IBSearchFilter
          onPressSearch={() => {
            if (!isEmpty(pvNoSearch)) {
              const dataSearch = data.filter((x) => x.reqnNumber === pvNoSearch)
              if (!isEmpty(dataSearch)) {
                setDataFilter(dataSearch)
              } else {
                setDataFilter([])
                showToast(t('message:IB0009'), 'error')
              }
            } else {
              // showToast(t('message:IB0008', { fieldName: t('PaymentNo') }), 'error')
              setDataFilter(data)
            }
          }}
          value={pvNoSearch}
          onChangeSearchText={setPvNoSearch}
          inputContainerStyle={{ width: '20%' }}
          placeHolder={t('PaymentNo')}
        />
      </View>
      <View>
        <IBGeneralTable
          data={orderBy(
            dataFilter.map((item) => ({
              ...item,
              verifyDate: moment(item.verifyDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              authDate: moment(item.authDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              returnedDate: moment(item.returnedDate, 'DD/MM/YYYY').format('YYYYMMDD')
            })),
            'reqnNumber',
            'desc'
          )}
          dataTable={dataTable}
          autoPaging
          loading={loading}
          paginationStyle={{ marginRight: 60 }}
        />
      </View>
      {dataModal && openModal ? (
        <ModalPaymentDetail visible={openModal} onClose={setOpenModal} data={dataModal} setData={setDataModal} />
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10
  },
  containerSearch: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 10
  }
})
