import { default as React, useEffect } from 'react'
import { SafeAreaView, ScrollView, TextStyle, View, StyleSheet, Text } from 'react-native'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  GeneralService,
  Input,
  SelectOption,
  SelectSearch,
  RoundedButton,
  useMobile,
  RBAC,
  Permission,
  DoNotCallService
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { SC } from './common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { ConfirmModal } from './confirmModal'
import { useForm, Controller } from 'react-hook-form'
import { useLoading } from '@mxt/zio-react'
export interface IDoNotCallFrom {
  customerName: string
  phoneNumber: string
  officeMobileCode: any
  emailAddress: string
  checkedCall: boolean
  checkedSMS: boolean
  checkedEmail: boolean
}

export enum CheckboxVal {
  HUY = 'HUY',
  DK = 'DK'
}

export const DoNotCallScreen = () => {
  const formDoNotCall = useForm<IDoNotCallFrom>({
    defaultValues: {
      customerName: '',
      phoneNumber: '',
      officeMobileCode: {
        label: '',
        code: ''
      },
      emailAddress: '',
      checkedCall: false,
      checkedSMS: false,
      checkedEmail: false
    },
    shouldUnregister: false,
    mode: 'onChange'
  })

  const { control, watch, setValue, handleSubmit } = formDoNotCall

  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const { isMobile } = useMobile()

  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false)
  const [bindError, setError] = React.useState<string>('')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('utilities:DoNotCall'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const phoneCodeOptions: SelectOption[] = pipe(
    GeneralService.getMobileCodes,
    ZIO.map((codes) => {
      let VNcode = codes.find((x) => x.code === '84')
      setValue('officeMobileCode', {
        label: `+${VNcode?.code ?? ''}`,
        value: VNcode?.code ?? ''
      })
      return codes.map((code) => ({
        label: `+${code.code}`,
        value: code.code
      }))
    }),
    ErrorHandling.runDidMount({})
  )

  const onSubmit = async (dataFrom: IDoNotCallFrom) => {
    const isValidForm = await validateFields(dataFrom)
    if (isValidForm) {
      setIsOpenModal(true)
    }
  }

  const validateFields = async (data: IDoNotCallFrom) => {
    const isValid = await formDoNotCall.trigger()

    let checkedAllPhone = data.checkedCall || data.checkedSMS
    if (!isValid) {
      return false
    } else if (!data.phoneNumber && !data.emailAddress) {
      setError(t('message:MS990054'))
      return false
    } else if (data.phoneNumber && !checkedAllPhone) {
      setError(t('message:MS990055'))
      return false
    } else if (checkedAllPhone && !data.phoneNumber) {
      setError(`${t('message:MS020001', { field: t('utilities:PhoneNumber') })}`)
      return false
    } else if (data.emailAddress && !data.checkedEmail) {
      setError(t('message:MS990055'))
      return false
    } else if (!data.emailAddress && !!data.checkedEmail) {
      setError(`${t('message:MS020001', { field: t('utilities:EmailAddres') })}`)
      return false
    }
    setError('')
    return true
  }

  const onConfirm = () => {
    const data = formDoNotCall.getValues()
    const query: DoNotCallService.DoNotCallDetail = {
      dncValueTel: data.checkedCall ? CheckboxVal.DK : undefined,
      dncValueSms: data.checkedSMS ? CheckboxVal.DK : undefined,
      dncValueEmail: data.checkedEmail ? CheckboxVal.DK : undefined,
      sourceSystem: 'PULSEOPS',
      dncIdPhone: !!data.phoneNumber ? data.phoneNumber : undefined,
      dncIdEmail: !!data.emailAddress ? data.emailAddress : undefined
    }
    pipe(
      DoNotCallService.submitDoNotCallData(query),
      ZIO.tap((value) => {
        console.log(value)
        setIsOpenModal(false)
        dncResetForm()
        setError('')
        return ZIO.unit
      }),
      ZIO.foldM(
        (error) => ZIO.succeed(false),
        (success) => ZIO.succeed(showToast(t('message:MS100003'), 'success'))
      ),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const dncResetForm = () => {
    formDoNotCall.reset({
      customerName: '',
      phoneNumber: '',
      emailAddress: '',
      checkedCall: false,
      checkedSMS: false,
      checkedEmail: false,
      officeMobileCode: {
        label: '+84',
        code: '84'
      }
    })
  }
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.headingTitle}>
            <SC.BoldText color="#000000">{t('utilities:DoNotCallUpperCase')}</SC.BoldText>
          </View>

          <View style={styles.searchContainer}>
            <View style={[styles.row, { paddingBottom: 15 }]}>
              <View style={isMobile ? styles.col2 : styles.col}>
                <Controller
                  name="customerName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      title={t('utilities:CustomerName')}
                      value={value ?? undefined}
                      onChange={onChange}
                      placeholder={t('utilities:CustomerName')}
                      errorMessage={error?.message ?? ''}
                    />
                  )}
                />
              </View>
            </View>
            {/* // */}
            <View style={[styles.row, { paddingBottom: 15 }]}>
              <View style={isMobile ? styles.col2 : styles.col}>
                <View style={{ flex: 1, paddingTop: 4 }}>
                  <View>
                    <SC.TitleText>{t('utilities:PhoneNumber')}</SC.TitleText>
                    <View style={{ flexDirection: 'row', paddingTop: 5 }}>
                      <Controller
                        control={control}
                        name={'officeMobileCode'}
                        rules={{
                          required: {
                            value: !Boolean(watch('phoneNumber')) ? true : false,
                            message: `${t('message:MS020009', { field: t('utilities:PhoneNumber') })}`
                          }
                        }}
                        defaultValue={phoneCodeOptions?.[0] ?? { label: '', code: '' }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                          return (
                            <View style={isMobile ? { width: 50 } : { width: 110 }}>
                              <SelectSearch
                                options={phoneCodeOptions}
                                onChange={onChange}
                                value={value}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                hideLabel
                                errorMessage={error?.message ?? ''}
                              />
                            </View>
                          )
                        }}
                      />
                      <SC.Divider />
                      <Controller
                        control={control}
                        name={'phoneNumber'}
                        rules={{
                          minLength: {
                            value: 10,
                            message: t('message:MS990037')
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                          return (
                            <View style={{ flex: 1 }}>
                              <Input
                                onChange={(value) => {
                                  onChange(value)
                                }}
                                inputType="number"
                                onBlur={onBlur}
                                value={value}
                                maxLength={10}
                                errorMessage={error?.message ?? ''}
                              />
                            </View>
                          )
                        }}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <View style={isMobile ? styles.col2 : styles.col1}>
                <View style={{ flex: 1, paddingTop: 25 }}>
                  <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                    <View style={{ flex: 1 }}>
                      <Controller
                        name="checkedSMS"
                        control={control}
                        render={({ field: { onChange, value, onBlur } }) => {
                          return (
                            <Checkbox
                              title="SMS"
                              onChange={(checked) => {
                                onChange(checked)
                              }}
                              onBlur={onBlur}
                              value={value}
                              checkBoxStyle={styles.tableCheckBox}
                            />
                          )
                        }}
                      />
                      {/* </View> */}
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        name="checkedCall"
                        control={control}
                        render={({ field: { onChange, value, onBlur } }) => {
                          return (
                            <Checkbox
                              title="Call"
                              onChange={(checked) => {
                                onChange(checked)
                              }}
                              onBlur={onBlur}
                              value={value}
                              checkBoxStyle={styles.tableCheckBox}
                            />
                          )
                        }}
                      />
                      {/* </View> */}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={isMobile ? styles.col2 : styles.col}>
                <Controller
                  name="emailAddress"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t('message:MS990037')
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      title={t('utilities:EmailAddres')}
                      value={value ?? undefined}
                      onChange={onChange}
                      placeholder={t('utilities:EmailAddres')}
                      errorMessage={error?.message ?? ''}
                    />
                  )}
                />
              </View>
              <View style={isMobile ? styles.col2 : styles.col}>
                <View style={{ flex: 1, paddingTop: 25 }}>
                  <Controller
                    name="checkedEmail"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => {
                      return (
                        <Checkbox
                          title="Email"
                          onChange={(checked) => {
                            onChange(checked)
                          }}
                          onBlur={onBlur}
                          value={value}
                          checkBoxStyle={styles.tableCheckBox}
                        />
                      )
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
          <View style={styles.boxError}>
            <Text style={styles.colorRed}>{bindError ?? ''}</Text>
          </View>
          <SC.WarpperFixed>
            <View style={[styles.row, { paddingVertical: 15 }]}>
              <View style={[styles.col, { marginBottom: 0 }]}>
                <View style={{ flex: 1 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <RoundedButton
                      showBorder
                      activeOpacity={0.7}
                      text={t('utilities:ClearAll')}
                      textStyle={styles.btnTitle}
                      style={{ height: 39, marginEnd: 15 }}
                      textColorDisable={'#B0B0B0'}
                      textColorEnable={'#ED1B2E'}
                      borderColorDisable={'#B0B0B0'}
                      borderColorEnable={'#ED1B2E'}
                      onPress={() => {
                        dncResetForm()
                        setError('')
                      }}
                    />
                    <RoundedButton
                      showBorder
                      activeOpacity={0.7}
                      text={t('utilities:SubmitDnc')}
                      textStyle={styles.btnTitle}
                      style={{ height: 39, marginEnd: 15 }}
                      textColorDisable={'#B0B0B0'}
                      textColorEnable={'#FFF'}
                      borderColorDisable={'#B0B0B0'}
                      borderColorEnable={'#ED1B2E'}
                      bgColorEnable={'#ED1B2E'}
                      filled={true}
                      disabled={!roles.includes(Permission['EditPVADoNotCall'])}
                      onPress={handleSubmit(onSubmit)}
                    />
                  </View>
                </View>
              </View>
            </View>
          </SC.WarpperFixed>
          <ConfirmModal
            open={isOpenModal}
            onClose={() => {
              setIsOpenModal(false)
            }}
            form={formDoNotCall}
            onSubmit={() => {
              onConfirm()
            }}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  headingTitle: {
    marginHorizontal: 30,
    marginVertical: 30,
    marginBottom: 10
  },

  searchContainer: {
    backgroundColor: '#FFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 15,
    paddingBottom: 15,
    paddingTop: 24
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  col2: {
    width: '50%',
    marginBottom: 16,
    paddingHorizontal: 5
  },
  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 5,
    paddingLeft: 10
  },
  col1: {
    width: '25%',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 6,
    marginHorizontal: 36
  },
  boxError: {
    paddingHorizontal: 30
  },
  colorRed: {
    color: 'rgb(237, 27, 46)'
  }
} as const)
// footerView: {
//     position: 'fixed',
//     bottom: 0,
//     width: '100%',
//     marginHorizontal: 30,
//     marginVertical: 30,
//     marginBottom: 10,
// },
