import { StyleSheet, Text, View, Pressable, ScrollView, ActivityIndicator } from 'react-native'
import { IBDivider, IBGridDataView, IBPanel } from '../../../../../common'
import {
  assets,
  ErrorHandling,
  FrequencyMapping,
  useTranslator,
  formatNumberWithComma,
  PulseOpsFormat
} from '@pulseops/common'

import {
  IBService,
  PolicyInfoData,
  PolicyValueInfoData,
  IPremiumHistory,
  PremiumInfoData,
  IProductDetail,
  PremiumFrequencyDetailData,
  APLValueData,
  OPLValueData,
  BonusValueData,
  PrucashBenefits,
  SurrenderValueData,
  ContractValueData,
  ACMVQueryData,
  ReinData,
  MainListProduct,
  PaidUpInquiryData,
  MasterProductsData,
  LasTransactionHistoryData,
  PremiumPaymentHistoryData
} from '../../../../../ib-service'
import React, { forwardRef } from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import * as O from 'fp-ts/lib/Option'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
const PremiumHistory = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBPremiumHistory'))
const PremiumFrequencyDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBPremiumFrequencyDetail'))
const APLDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBAPLDetail'))
const OPLDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBOPLDetail'))
const BonusValueDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBBonusValueDetail'))
const PrucashBenefitDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBPrucashBenefitDetail'))
const SurrenderValueDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBSurrenderValueDetail'))
const IBPremiumAllocationHistory = React.lazy(
  () => import('./IBPolicyInfoTabPanelChildrens/IBPremiumAllocationHistory')
)
const IBSuspendAmountDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBSuspendAmountDetail'))
const IBLoanRepayInquiry = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBLoanRepayInquiry'))
const IBSuspendPremiumDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBSuspendPremiumDetail'))
const IBDiscountedPremiumHistory = React.lazy(
  () => import('./IBPolicyInfoTabPanelChildrens/IBDiscountedPremiumHistory')
)
const WriteOffAmountDetail = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBWriteOffAmountDetail'))
const IBReinstatement = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBReinstatement'))
const PaidUpInquiry = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBPaidUpInquiry'))
const PolicyStatusHistory = React.lazy(() => import('./IBPolicyInfoTabPanelChildrens/IBPolicyStatusHistory'))
const PremiumPaymentHistoryVIAPruonline = React.lazy(
  () => import('./IBPolicyInfoTabPanelChildrens/IBPremiumPaymentHistoryVIAPruonline')
)
interface IBPolINfoTabDataBoxProp {
  data: {
    label?: string
    labelSuffixIcon?: JSX.Element
    labelColor?: string
    value?: string
    divider?: boolean
    hidden?: boolean
    textAlignValue?: string
  }[]
}

const IBPolInfoTabDataBox = (props: IBPolINfoTabDataBoxProp) => {
  const { data } = props
  return (
    <View style={styles.polInfoDataBoxContainer}>
      {data.map((item, index) => {
        if (item.hidden) return <></>
        return item.divider ? (
          <IBDivider borderStyle="solid" />
        ) : (
          <View style={styles.polInfoDataBoxTextContainer}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ color: item.labelColor ? item.labelColor : '#58647A', fontWeight: '700', fontSize: 15 }}>
                {item.label}
              </Text>
              <View style={{ marginLeft: 5 }}>{item.labelSuffixIcon}</View>
            </View>
            <Text
              style={{
                fontSize: 15,
                textAlign: item.textAlignValue
                  ? (item.textAlignValue as 'center' | 'auto' | 'left' | 'right' | 'justify' | undefined)
                  : 'auto'
              }}
            >
              {item.value}
            </Text>
          </View>
        )
      })}
    </View>
  )
}

interface Props {
  scrollRef: React.MutableRefObject<ScrollView | null>
  policyNumber: string
  clientNumber: string
  data:
    | (PolicyInfoData &
        PolicyValueInfoData & {
          aplValue: APLValueData
          oplValue: OPLValueData
          prucashBenefits: PrucashBenefits
          contractValue: ContractValueData
        })
    | null
  setData: (
    data: PolicyInfoData &
      PolicyValueInfoData & {
        aplValue: APLValueData
        oplValue: OPLValueData
        prucashBenefits: PrucashBenefits
        contractValue: ContractValueData
      }
  ) => void
  notifyAnniversary: (riskCommenDate: string) => void
  notifyFullyPaid: (PTDate: string, PCDate: string, premiumStatus: string) => void
  messageData: { Anniversary: string; FullyPaid: string }
}

const getName = (item: { firstName?: string; surName?: string; middleName?: string } | null) => {
  if (item) {
    return item.surName + ' ' + item.middleName + ' ' + item.firstName
  }

  return ''
}

export interface PolicyInfoRef {
  scrollToKey: (key: string) => void
}

const IBPolicyInfoTab = (
  { scrollRef, policyNumber, clientNumber, data, setData, notifyAnniversary, notifyFullyPaid, messageData }: Props,
  ref: React.Ref<PolicyInfoRef>
) => {
  const [autoToggleKey, setAutoToggleKey] = React.useState<string | undefined | null>(null)
  const [loading, bindLoading] = useLoading()
  const [layoutY, setLayoutY] = React.useState<number | null>(null)
  const [productDetail, setProductDetail] = React.useState<IProductDetail | null>(null)
  const [dataPremiumHistory, setDataPremiumHistory] = React.useState<{
    listData: IPremiumHistory[]
    dataHistory: PremiumInfoData[]
  } | null>(null)
  const [dataBonusValue, setDataBonusValue] = React.useState<BonusValueData | null>(null)
  const [dataPremiumFrequency, setDataPremiumFrequency] = React.useState<PremiumFrequencyDetailData | null>(null)
  const [dataSurrenderValue, setDataSurrenderValue] = React.useState<SurrenderValueData | null>(null)
  const [dataPremiumAllocationHistory, setDataPremiumAllocationHistory] = React.useState<{
    ACMVData: ACMVQueryData[]
    listProduct: MasterProductsData[]
  } | null>(null)
  const [dataSuspendPremiumDetail, setDataSuspendPremiumDetail] = React.useState<ACMVQueryData[] | null>(null)
  const [dataSuspendAmountDetail, setDataSuspendAmountDetail] = React.useState<ACMVQueryData[] | null>(null)
  const [dataDiscountedPremiumHistory, setDataDiscountedPremiumHistory] = React.useState<ACMVQueryData[] | null>(null)
  const [dataWriteOffAmountDetail, setDataWriteOffAmountDetail] = React.useState<ACMVQueryData[] | null>(null)
  const [dataReinstatement, setDataReinstatement] = React.useState<ReinData | null>(null)
  const [dataPaidUpInquiry, setDataPaidUpInquiry] = React.useState<PaidUpInquiryData | null>()
  const [dataPolicyStatusHistory, setDataPolicyStatusHistory] = React.useState<LasTransactionHistoryData[] | null>(null)
  const [dataPremiumPaymentHistory, setDataPremiumPaymentHistory] = React.useState<PremiumPaymentHistoryData[] | null>(
    null
  )
  const { t, i18n } = useTranslation()
  const ft = useTranslator('frequency').t
  const scrollToKey = (key: string) => {
    setAutoToggleKey(key)
    setTimeout(() => {
      setAutoToggleKey(null)
      setLayoutY(null)
    }, 1000)
  }

  React.useImperativeHandle(ref, () => ({
    scrollToKey: scrollToKey
  }))

  React.useEffect(() => {
    if (layoutY) {
      scrollRef.current?.scrollTo({ x: 0, y: 620 + layoutY, animated: true })
    }
  }, [layoutY])

  const getData = (): ZIO<
    unknown,
    Throwable,
    | (PolicyInfoData &
        PolicyValueInfoData & {
          aplValue: APLValueData
          oplValue: OPLValueData
          prucashBenefits: PrucashBenefits
          contractValue: ContractValueData
        })
    | null
  > =>
    pipe(
      IBService.getProductType(policyNumber),
      ZIO.flatMap((productInfo) => {
        return pipe(
          ZIO.zipPar(
            IBService.getPolicyInfo(policyNumber),
            IBService.getContractValue({
              contractNumber: policyNumber,
              effectiveDate: undefined,
              contractStatus: productInfo?.body?.status,
              productType: productInfo?.body?.productType,
              coverageId: productInfo?.body?.basicCode
            }),
            ZIO.succeed(productInfo),
            IBService.getInvestmentInfo({ data: { function: '', policies: [`${policyNumber}`] } }),
            IBService.getAPLValue(policyNumber),
            IBService.getOPLValue(policyNumber),
            IBService.getPrucashBenefitDetail(policyNumber)
          ),
          ZIO.flatMap(([_policyInfo, _contractValue, product, checker, _aplValue, _oplValue, _prucashValue]) =>
            ZIO.effect(() => {
              const { policy, beneficiaries } = _policyInfo.data
              // policyInfo calc
              const productName = policy ? (i18n.language === 'en' ? policy.productNameEN : policy.productNameVN) : '-'
              const {
                policyStatus,
                premiumStatus,
                sumAssured,
                billingFrequency,
                policyOwnerCode,
                mainLifeAssuredCode,
                productCode,
                installmentPremium,
                riskCommencementDate,
                policyIssueDate,
                paidToDateBasic,
                premiumCessationDate,
                riskCessationDate,
                customerReceiveDate,
                customerReceiveMethod,
                firstIssueDate,
                uwDecisionDate,
                proposalDate,
                polCessationDate,
                destOffice,
                destOfficeName,
                paidToDateRider,
                premiumRiderAmount,
                writeOffAmount,
                waitingApplyPremiumAmount,
                discountAmount,
                premiumPaidInAdvanceAmount,
                suspendPremiumAmount,
                suspendAmount,
                paidUpNewSumAssured
              } = policy
              const POname = policy.policyOwnerName ? policy.policyOwnerName.name : ''
              const mainLA = getName(policy.mainLifeAssuredName ?? null)
              // check notify anniversary
              if (['IF', 'PU'].includes(policyStatus as string)) {
                if (policyStatus === 'IF') notifyAnniversary(riskCommencementDate ?? '')
                // check notify fully paid
                notifyFullyPaid(paidToDateBasic ?? '', premiumCessationDate ?? '', premiumStatus ?? '')
              }

              setProductDetail(product)

              const aplData = _aplValue.data.sort(function (a, b) {
                const timeB = b?.loanStartDate?.split('/').reverse().join('/') ?? ''
                const timeA = a?.loanStartDate?.split('/').reverse().join('/') ?? ''

                const newB = new Date(timeB)

                const newA = new Date(timeA)

                return newB.valueOf() - newA.valueOf()
              })

              const oplData = _oplValue.data.sort(function (a, b) {
                const timeB = b?.loanStartDate?.split('/').reverse().join('/') ?? ''
                const timeA = a?.loanStartDate?.split('/').reverse().join('/') ?? ''

                const newB = new Date(timeB)

                const newA = new Date(timeA)

                return newB.valueOf() - newA.valueOf()
              })

              const premiumBasic = pipe(
                checker.data.policyExtraInfoDetails.find(
                  (x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00'
                ),
                O.fromNullable,
                O.map((p) => p.installmentPremium),
                O.getOrElse(() => 0)
              )
              return {
                policyInfo: {
                  policyStatus: policyStatus ?? '-',
                  premiumStatus: premiumStatus ?? '-',
                  sumAssured: sumAssured ?? 0,
                  billingFrequency: billingFrequency ?? '-',
                  policyOwnerCode: policyOwnerCode ?? '-',
                  policyOwnerName: POname ?? '-',
                  mainLifeAssuredCode: mainLifeAssuredCode ?? '-',
                  mainLifeAssuredName: mainLA ?? '-',
                  productCode: productCode ?? '-',
                  productName: productName ?? '-',
                  installmentPremium: installmentPremium ?? 0,
                  riskCommencementDate: riskCommencementDate ?? '',
                  aplDate: policy && policy.aplDate ? policy.aplDate : '-',
                  policyIssueDate: policyIssueDate ?? '-',
                  paidToDateBasic: paidToDateBasic ?? '-',
                  premiumCessationDate: premiumCessationDate ?? '-',
                  riskCessationDate: riskCessationDate ?? '-',
                  customerReceiveDate: customerReceiveDate ?? '',
                  customerReceiveMethod: customerReceiveMethod ?? '-',
                  firstIssueDate: firstIssueDate ?? '-',
                  premiumBasic: premiumBasic,
                  uwDecisionDate: uwDecisionDate ?? '-',
                  proposalDate: proposalDate ?? '-',
                  polCessationDate: polCessationDate || '',
                  destOffice: destOffice ?? '-',
                  destOfficeName: destOfficeName ?? '',
                  paidToDateRider: paidToDateRider ?? '-',
                  premiumRiderAmount:
                    (installmentPremium as number) >= premiumBasic
                      ? (installmentPremium as number) - premiumBasic
                      : (installmentPremium as number),
                  writeOffAmount: writeOffAmount ?? 0,
                  waitingApplyPremiumAmount: waitingApplyPremiumAmount ?? 0,
                  discountAmount: discountAmount ?? 0,
                  premiumPaidInAdvanceAmount: premiumPaidInAdvanceAmount ?? 0,
                  suspendPremiumAmount: suspendPremiumAmount ?? 0,
                  suspendAmount: suspendAmount ?? 0,
                  paidUpNewSumAssured: paidUpNewSumAssured ?? 0,
                  dispatchAddress: policy.dispatchAddress ?? '-'
                },
                beneficiaries: beneficiaries
                  ? beneficiaries.map((x, i) => ({
                      stt: (i + 1).toString(),
                      clientId: x.beneficiaryCode ?? '-',
                      percentage: x.beneficiaryPercentage + '%' ?? '0%',
                      relationshipType: x.beneficiaryRelationship ?? '',
                      clientName: getName(x.beneficiaryName ?? null) ?? '-'
                    }))
                  : [],
                contractValue: _contractValue.data,
                aplValue: aplData,
                oplValue: oplData,
                productInfo: product?.body,
                validProduct: !checker.data.policyExtraInfoDetails.some(
                  (x) =>
                    ['UHS2', 'HSR4', 'VHS1'].includes(x.productCode) &&
                    x.riderStatus === 'IF' &&
                    x.sumAssured === 400000
                ),
                prucashBenefits: _prucashValue.data
              }
            })
          )
        )
      })
    )

  const { policyInfo, beneficiaries, contractValue, aplValue, oplValue, productInfo, validProduct, prucashBenefits } =
    pipe(
      data !== null ? ZIO.succeed(data) : getData(),
      ZIO.tap((x) => {
        if (x !== null && data === null) setData(x)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.runDidMount()
    ) || {
      policyInfo: null,
      beneficiaries: [],
      contractValue: null,
      fullSurrender: null,
      aplValue: [],
      oplValue: [],
      productInfo: null,
      validProduct: false,
      prucashBenefits: []
    }

  React.useEffect(() => {
    if (!dataBonusValue && policyNumber && productInfo) {
      pipe(
        IBService.getBonusValue({
          contractNumber: policyNumber,
          effectiveDate: undefined,
          contractStatus: productInfo?.status,
          productType: productInfo?.productType,
          coverageId: productInfo?.basicCode
        }),
        ZIO.map((res) => {
          const bonusData =
            res && res?.data && (res.data.bonusHistories as []).length > 0
              ? res?.data?.bonusHistories?.sort(function (a, b) {
                  const timeB = b?.transactionDate?.split('/').reverse().join('/') ?? ''
                  const timeA = a?.transactionDate?.split('/').reverse().join('/') ?? ''
                  const newB = new Date(timeB)
                  const newA = new Date(timeA)
                  return newB.valueOf() - newA.valueOf()
                })
              : []
          setDataBonusValue({ ...res.data, bonusHistories: bonusData })
        }),
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(dataBonusValue), ZIO.unsafeRun({}))
    }

    if (!dataSurrenderValue && policyNumber && productInfo) {
      pipe(
        IBService.getSurrenderValue({
          contractNumber: policyNumber,
          effectiveDate: undefined,
          contractStatus: productInfo?.status,
          productType: productInfo?.productType,
          coverageId: productInfo?.basicCode
        }),
        ZIO.map((res) => {
          setDataSurrenderValue(res.data)
        }),
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(data), ZIO.unsafeRun({}))
    }
  }, [policyNumber, productInfo])

  const getDataRecordBonusValue = () => {
    const dataDiff = dataBonusValue?.bonusHistories?.filter((i) => i.originAmount && i.originAmount > 0)?.length ?? 0
    const dataRecord = productInfo?.productCode === 'EN3' ? dataDiff / 2 : dataDiff
    return dataRecord
  }

  const calcPrucashAvailable = () => {
    if ((contractValue?.totalCashBenefit as number) <= (contractValue?.loanExceedSurrenderValue as number)) {
      return 0
    }
    return (contractValue?.totalCashBenefit as number) - (contractValue?.loanExceedSurrenderValue as number)
  }

  const dataBox2 = [
    {
      label: t('Inbound:PremiumFrequency'),
      value: FrequencyMapping.get(ft)(policyInfo?.billingFrequency),
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:PremiumFrequencyDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('Inbound:InstallmentPremium'),
      value: policyInfo?.installmentPremium ? formatNumberWithComma(policyInfo?.installmentPremium) + ' VND' : '-',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:PremiumAllocationHistory'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('Inbound:PaidToDateBasic'),
      value: messageData.FullyPaid ? '-' : policyInfo?.paidToDateBasic
    },
    {
      label: t('Inbound:PremiumBasic'),
      value: policyInfo?.premiumBasic ? formatNumberWithComma(policyInfo?.premiumBasic) + ' VND' : '-'
    },
    {
      label: t('Inbound:PaidToDateRider'),
      value: messageData.FullyPaid ? '-' : policyInfo?.paidToDateRider,
      hidden: !['VL2', 'VL3', 'VL5', 'UR5', 'UR6'].includes(policyInfo?.productCode as string)
    },
    {
      label: t('Inbound:PremiumRider'),
      value: policyInfo?.premiumRiderAmount ? formatNumberWithComma(policyInfo?.premiumRiderAmount) + ' VND' : '-',
      hidden: !['VL2', 'VL3', 'VL5', 'UR5', 'UR6'].includes(policyInfo?.productCode as string)
    },
    {
      label: t('Inbound:PremCessDateBasic'),
      value: policyInfo?.premiumCessationDate
    },
    {
      label: t('Inbound:RiskCessDateBasic'),
      value: policyInfo?.riskCessationDate
    },
    {
      label: t('Inbound:PolCessDateBasic'),
      value: policyInfo?.polCessationDate,
      hidden: isEmpty(policyInfo?.polCessationDate) || isNil(policyInfo?.polCessationDate)
    }
  ]

  const dataBox = [
    {
      label: t('Inbound:RiskCommDate'),
      value: policyInfo?.riskCommencementDate || '-'
    },
    {
      label: t('Inbound:ProposalDate'),
      value: policyInfo?.proposalDate
    },
    {
      label: t('Inbound:DecisionDate'),
      value: policyInfo?.uwDecisionDate
    },
    {
      label: t('Inbound:1stIssueDate'),
      value: policyInfo?.firstIssueDate || '-'
    },
    {
      label: t('Inbound:PolicyIssueDate'),
      value: policyInfo?.policyIssueDate
    },
    {
      label: t('Inbound:CustomerReceiveDate'),
      value: policyInfo?.customerReceiveDate ? policyInfo?.customerReceiveDate : '-'
    },
    {
      label: t('Inbound:CustomerReceiveMethod'),
      value: policyInfo?.destOffice === 'NNM' ? t('Inbound:eKit') : policyInfo?.destOffice ? t('Inbound:Hardcopy') : '-'
    },
    {
      label: t('Inbound:DestOffice'),
      value:
        !policyInfo?.destOffice || policyInfo?.destOffice === 'NNM'
          ? '-'
          : `${policyInfo.destOffice} - ${policyInfo.destOfficeName}`,
      textAlignValue: 'right'
    },
    {
      label: t('Inbound:EndOfTheTreeLookPeriod'),
      value: policyInfo?.customerReceiveDate
        ? moment(policyInfo?.customerReceiveDate, 'DD/MM/YYYY').add(21, 'days').format('DD/MM/YYYY')
        : '-'
    }
  ]

  const dataBox3 = [
    {
      label: `${t('Inbound:SuspendAmount')} (LP_S)`,
      value: policyInfo?.suspendAmount ? formatNumberWithComma(policyInfo?.suspendAmount) + ' VND' : '-',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:SuspendAmountDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: `${t('Inbound:SuspendPremium')} (LP_U)`,
      value: policyInfo?.suspendPremiumAmount ? formatNumberWithComma(policyInfo?.suspendPremiumAmount) + ' VND' : '-',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:SuspendPremiumDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: `${t('Inbound:PremiumPaidInAdvance')} (GL_RN)`,
      value: policyInfo?.premiumPaidInAdvanceAmount
        ? formatNumberWithComma(policyInfo?.premiumPaidInAdvanceAmount) + ' VND'
        : '-'
    },
    {
      label: t('Inbound:DiscountAmount'),
      value: policyInfo?.discountAmount ? formatNumberWithComma(policyInfo?.discountAmount) + ' VND' : '-',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:DiscountedPremiumHistory'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: `${t('Inbound:WaitingApplyPremium')} (LN_VP)`,
      value: policyInfo?.waitingApplyPremiumAmount
        ? formatNumberWithComma(policyInfo?.waitingApplyPremiumAmount) + ' VND'
        : '-'
    },
    {
      label: t('Inbound:WriteOffAmount'),
      value: policyInfo?.writeOffAmount ? formatNumberWithComma(policyInfo?.writeOffAmount) + ' VND' : '-',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:WriteOffAmountDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    }
  ]

  const dataGridView = [
    {
      label: `${t('Inbound:AutomaticPremiumLoan')} - ${aplValue.length ?? 0}`,
      value: PulseOpsFormat.thousandSepartor(contractValue?.automaticPremiumLoan) + ' VND',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:APLDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: `${t('Inbound:BonusValue')} - ${dataBonusValue?.bonusHistories ? getDataRecordBonusValue() : 0}`,
      value: PulseOpsFormat.thousandSepartor(contractValue?.bonusValue) + ' VND',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:BonusValueDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: `${t('Inbound:PrucashBenefit')} - ${prucashBenefits?.length ?? 0}`,
      value: PulseOpsFormat.thousandSepartor(contractValue?.totalCashBenefit) + ' VND',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:PrucashBenefitDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: `${t('Inbound:SuspendAmount')}`,
      value: PulseOpsFormat.thousandSepartor(contractValue?.suspenseAmount) + ' VND'
    },
    {
      label: `${t('Inbound:OriginalPremiumLoan')} - ${oplValue.length ?? 0}`,
      value: PulseOpsFormat.thousandSepartor(contractValue?.originalPolicyLoan) + ' VND',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:OPLDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('Inbound:BonusSurrender'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.bonusSurrender) + ' VND'
    },
    {
      label: t('Inbound:PrucashAvailable'),
      value: PulseOpsFormat.thousandSepartor(calcPrucashAvailable()) + ' VND'
    },
    {
      label: t('Inbound:MaturityBenefit'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.maturityBenefit) + ' VND'
    },
    {
      label: t('Inbound:LoanAmountExcess80SV'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.loanExceedSurrenderValue) + ' VND'
    },
    {
      label: t('Inbound:SpecialBonus'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.specialBonus) + ' VND'
    },
    {
      label: t('Inbound:MaxPartialWithDrawal'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.maxWithDraw) + ' VND'
    },
    {
      label: t('Inbound:PrevMaturityAdvance'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.prevMaturityAdvance) + ' VND'
    },
    {
      label: t('Inbound:OPLAvailable'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.loanAmountAvailable) + ' VND'
    },
    {
      label: t('Inbound:SurrenderValue'),
      // value: PulseOpsFormat.thousandSepartor(contractValue?.contractSurrenderValue) + ' VND',
      value: PulseOpsFormat.thousandSepartor(dataSurrenderValue?.total) + ' VND',
      labelSuffixIcon: (
        <Pressable onPress={() => scrollToKey(t('Inbound:SurrenderValueDetail'))}>
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      label: t('Inbound:KidProductRefundedPremium'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.pruKidBenefit) + ' VND'
    },
    {
      label: t('Inbound:TotalMaturityAdvance'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.totalMaturityAdvance) + ' VND'
    }
  ]
  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <View style={styles.container}>
          <View style={styles.rowHeader}>
            <IBPolInfoTabDataBox data={dataBox} />
            <IBPolInfoTabDataBox data={dataBox2} />
            <IBPolInfoTabDataBox data={dataBox3} />
          </View>
          <View style={styles.address}>
            <Text style={{ fontWeight: 'bold', fontSize: 15, marginRight: 5 }}>{t('Inbound:DespatchAddress')}:</Text>
            <Text>{policyInfo?.dispatchAddress}</Text>
          </View>
          <View>
            <Text style={{ fontSize: 15, fontWeight: '700', marginVertical: 20 }}>
              {t('Inbound:PolicyValue').toUpperCase()}
            </Text>
            <IBGridDataView data={dataGridView} col={4} />
          </View>
          <View style={{ marginTop: 10 }}>
            <IBPanel
              title={t('Inbound:PremiumHistory')}
              children={
                <PremiumHistory
                  policyNumber={policyNumber}
                  clientNumber={clientNumber}
                  data={dataPremiumHistory}
                  setData={setDataPremiumHistory}
                />
              }
            />
            <IBPanel
              title={t('Inbound:PremiumPaymentHistoryVIAPruonline')}
              children={
                <PremiumPaymentHistoryVIAPruonline
                  policyNumber={policyNumber}
                  data={dataPremiumPaymentHistory}
                  setData={setDataPremiumPaymentHistory}
                />
              }
            />
            <IBPanel
              title={t('Inbound:PremiumAllocationHistory')}
              children={
                <IBPremiumAllocationHistory
                  policyNumber={policyNumber}
                  data={dataPremiumAllocationHistory}
                  setData={setDataPremiumAllocationHistory}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:PremiumFrequencyDetail')}
              children={
                <PremiumFrequencyDetail
                  policyNumber={policyNumber}
                  data={dataPremiumFrequency}
                  productTypeInfo={productDetail}
                  validProduct={validProduct}
                  paidToDate={policyInfo?.paidToDateBasic}
                  riskCommDate={policyInfo?.riskCommencementDate}
                  setData={setDataPremiumFrequency}
                  policyStatus={policyInfo?.policyStatus}
                  premiumStatus={policyInfo?.premiumStatus}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:APLDetail')}
              children={<APLDetail aplValue={aplValue} policyNumber={policyNumber} />}
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:OPLDetail')}
              children={<OPLDetail oplValue={oplValue} policyNumber={policyNumber} />}
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            {(contractValue?.originalPolicyLoan as number) + (contractValue?.automaticPremiumLoan as number) > 0 ? (
              <IBPanel
                title={t('Inbound:LoanRepayInquiry')}
                children={<IBLoanRepayInquiry policyNumber={policyNumber} />}
              />
            ) : (
              <></>
            )}
            <IBPanel
              title={t('Inbound:BonusValueDetail')}
              children={
                <BonusValueDetail
                  policyNumber={policyNumber}
                  productInfo={productInfo}
                  dataBonusValue={dataBonusValue}
                  setDataBonusValue={setDataBonusValue}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:PrucashBenefitDetail')}
              children={<PrucashBenefitDetail prucashBenefits={prucashBenefits} policyNumber={policyNumber} />}
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:SurrenderValueDetail')}
              children={
                <SurrenderValueDetail
                  data={dataSurrenderValue}
                  setData={setDataSurrenderValue}
                  policyNumber={policyNumber}
                  productInfo={productInfo}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:SuspendAmountDetail')}
              children={
                <IBSuspendAmountDetail
                  policyNumber={policyNumber}
                  data={dataSuspendAmountDetail}
                  setData={setDataSuspendAmountDetail}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:SuspendPremiumDetail')}
              children={
                <IBSuspendPremiumDetail
                  policyNumber={policyNumber}
                  data={dataSuspendPremiumDetail}
                  setData={setDataSuspendPremiumDetail}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:DiscountedPremiumHistory')}
              children={
                <IBDiscountedPremiumHistory
                  policyNumber={policyNumber}
                  data={dataDiscountedPremiumHistory}
                  setData={setDataDiscountedPremiumHistory}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:WriteOffAmountDetail')}
              children={
                <WriteOffAmountDetail
                  policyNumber={policyNumber}
                  data={dataWriteOffAmountDetail}
                  setData={setDataWriteOffAmountDetail}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            <IBPanel
              title={t('Inbound:TransactionHistory')}
              children={
                <PolicyStatusHistory
                  policyNumber={policyNumber}
                  data={dataPolicyStatusHistory}
                  setData={setDataPolicyStatusHistory}
                />
              }
              autoToggleKey={autoToggleKey}
              setLayoutY={setLayoutY}
            />
            {policyInfo?.policyStatus !== 'LA' ? (
              <></>
            ) : (
              <IBPanel
                title={`${t('Inbound:Reinstatement')}`}
                children={
                  <IBReinstatement
                    policyNumber={policyNumber}
                    data={dataReinstatement}
                    setData={setDataReinstatement}
                  />
                }
                autoToggleKey={autoToggleKey}
                setLayoutY={setLayoutY}
              />
            )}
            {['U', 'V'].includes(productInfo?.basicCode?.charAt(0) || '') || productInfo?.basicCode === 'IPD1' ? (
              <></>
            ) : (
              <IBPanel
                title={t('Inbound:PaidUpInquiry')}
                children={
                  <PaidUpInquiry policyNumber={policyNumber} data={dataPaidUpInquiry} setData={setDataPaidUpInquiry} />
                }
              />
            )}
          </View>
        </View>
      )}
    </>
  )
}
export default forwardRef(IBPolicyInfoTab)

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10
  },
  polInfoDataBoxContainer: {
    backgroundColor: '#FFF',
    borderRadius: 10,
    padding: 15,
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    flex: 1
  },
  polInfoDataBoxTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5
  },
  rowHeader: {
    flexDirection: 'row',
    flex: 1,
    gap: 20,
    marginTop: 10
  },
  address: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
