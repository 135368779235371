
export namespace VoiceRecordingConst {
  interface RecordingTypeInfo {
    code: string
    nameVi: string
    nameEn: string
  }
  export const RecordingTypeConstList: Array<RecordingTypeInfo> = [
    { code: 'NO_AI_CHECK', nameEn: 'With out checking AI', nameVi: 'Không kiểm tra AI' },
    { code: 'PASS', nameEn: 'PASSING', nameVi: 'ĐẠT' },
    { code: 'SAMPLING', nameEn: 'SAMPLING', nameVi: 'CHỌN MẪU' },
    { code: 'FAIL', nameEn: 'FAILING', nameVi: 'CHƯA ĐẠT' },
  ]
} 