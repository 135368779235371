import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { PCRequestListStackParamList } from './PCRequestListParamList'

import { PCRequestList } from './PCRequestList'
import { PCRequestListDetail } from './PCRequestListDetail'

const Stack = createStackNavigator<PCRequestListStackParamList>()

export const PCRequestListStack = () => {
  return (
    <Stack.Navigator initialRouteName="PCRequestListScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="PCRequestListScreen" component={PCRequestList} />
      <Stack.Screen name="PCRequestListDetailScreen" component={PCRequestListDetail} />
    </Stack.Navigator>
  )
}
