import React, { useState, useEffect, useContext } from 'react'
import { View, StyleSheet, Text, ScrollView, useWindowDimensions, TouchableOpacity } from 'react-native'
import {
  Form,
  SelectOption,
  assets,
  RBAC,
  ErrorHandling,
  Permission,
  SubmissionService,
  useMobile,
  RoundedButton,
  GeneralService,
  //convertViToSearchable,
  ProductEnum,
  ChangeContactInfoService,
  AppContext,
  Divider,
  SelectSearch,
  AuthService,
  getLanguage,
  OfficeCode,
  BuildConfig,
  TransactionType
} from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { StaffSubmissionStackParamList } from '../StaffSubmissionStackParamList'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import { PolicyInput } from '../claim/common';
import { CircularProgress } from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { ZIO } from '@mxt/zio'
import Table from './Table'
import { TableData } from './Table'
import moment from 'moment'
import { Input } from '../../common/src/component'
import { useIsFocused, useLinkTo } from '@react-navigation/native'
import _ from 'lodash'

type StaffSubmissionForm = {
  officeCode: SelectOption | null
  policy: string
  primaryId: string
}

type Props = StackScreenProps<StaffSubmissionStackParamList, 'StaffSubmissionScreen'>

const RoundedButtonCustom = ({
  text,
  disable,
  onPress,
  loading
}: {
  text: string
  disable?: boolean
  onPress?: () => void
  loading?: boolean
}) => {
  const { isMobile } = useMobile()
  return loading === true ? (
    <View
      style={[
        styles.button,
        { width: isMobile ? '100%' : '25%', marginBottom: isMobile ? 10 : 0, alignItems: 'center' }
      ]}
    >
      <CircularProgress color="secondary"></CircularProgress>
    </View>
  ) : (
    <RoundedButton
      disabled={disable}
      text={text}
      activeOpacity={0.7}
      style={[styles.button, { width: isMobile ? '100%' : '25%', marginBottom: isMobile ? 10 : 0 }]}
      textStyle={styles.textStyle}
      showBorder={true}
      textColorEnable="#ED1B2E"
      textColorDisable="gray"
      borderColorEnable="#ED1B2E"
      borderColorDisable="gray"
      onPress={onPress}
    />
  )
}

export const ContactCenterScreen: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation()
  const { width } = useWindowDimensions()
  const linkTo = useLinkTo()
  const form = useForm<StaffSubmissionForm>()
  const register = Form.register(form)
  const [cells, setCells] = useState<TableData[][]>([])

  const [selectedPolicy, setSelectedPolicy] = useState<string>('')
  const [selectedPolicyOwner, setSelectedPolicyOwner] = useState<string>('')
  const [officeCodeSelected, setOfficeCodeSelected] = useState<OfficeCode>()

  const { showToast, changeBreadcrumb } = useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [loading, bindLoader] = useLoading(false)

  const { watch, trigger, formState, control } = form
  const { policy, officeCode } = watch()

  useEffect(() => {
    trigger().then()
  }, [policy])

  const getError = Form.getError(formState)

  const permissions: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        { title: t('Submission:ContactCenter'), navigate: null }
      ])
    }
  }, [isFocused])
  const reset = () => {
    setSelectedPolicy('')
    setCells([])
  }

  const getOfficeCode = pipe(
    AuthService.userInfo,
    ZIO.flatMap((x) =>
      x.officeCode !== null
        ? pipe(
            // GeneralService.getOfficeCodeGA(x.officeCode),
            GeneralService.getOfficeCode(x.officeCode),
            ZIO.map((y) => {
              const officeCode = y.body[0]
              if (officeCode) {
                setOfficeCodeSelected(officeCode)
                const option = {
                  label: getLanguage() === 'en' ? officeCode.nameEn : officeCode.nameVi,
                  value: officeCode.code
                }
                form.setValue('officeCode', option)
                return option
              }
              return null
            })
          )
        : ZIO.succeed(null)
    ),
    ErrorHandling.runDidMount()
  )

  const getPolicy = () =>
    pipe(
      policy && policy.length === 8
        ? SubmissionService.searchPolicy(policy)
        : SubmissionService.searchPolicyInquiry(policy),
      ZIO.tap((p) => {
        if (p.length > 0) {
          console.log(`found ${p.length} record`)
          const { policyNum, clientName, idNum, laName } = p[0]
          watch('primaryId')
          setSelectedPolicy(policyNum)
          setSelectedPolicyOwner(clientName)
          setCells([
            [
              {
                inputType: 'radio',
                value: policyNum
              },
              {
                value: policyNum
              },
              {
                value: idNum
              },
              {
                value: clientName
              },
              {
                value: laName
              }
            ]
          ])
        } else {
          //console.log("not found")
          showToast(t('message:MS030029'), 'info')
          reset()
        }
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showToast(t('message:MS030029'), 'info')
        reset()
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
      //ErrorHandling.run()
    )
  const headers = [
    `${t('common:Select')}`,
    `${t('common:Policy')}`,
    `${t('common:IDNumber')}`,
    `${t('common:PolicyOwner')}`,
    `${t('common:LifeAssured')}`
  ]

  const checkRoles = (role: Permission): boolean => {
    const claimAction = [Permission.ViewPolicyDetailSubmission, Permission.ViewFunctionListClaimsSubmission]
    const checker =
      !selectedPolicy ||
      (role === Permission.ViewFunctionListClaimsSubmission && selectedPolicy.length === 8) ||
      (!claimAction.includes(role) && selectedPolicy.length === 9)
    return checker || !permissions?.includes(role)
  }

  const checkOfficeCode = () => {
    if (!officeCode) {
      form.setError('officeCode', { message: t('message:MS020001', { field: t('common:Office').toLowerCase() }) })
      return false
    } else {
      return true
    }
  }

  // const officeCodes = pipe(
  //   GeneralService.getOfficeCodes('CCE'),
  //   // ZIO.map(x => x.body),
  //   ZIO.tap((x) => {
  //     return ZIO.unit
  //   }),
  //   ErrorHandling.runDidMount()
  // )

  // const filterOptions = (inputValue: string): UIO<SelectOption[]> =>
  //   pipe(
  //     ZIO.succeed(
  //       officeCodes
  //         ?.filter((x) => {
  //           const a = convertViToSearchable(inputValue, true)
  //           const b = convertViToSearchable(i18n.language === 'en' ? x.nameEn : x.nameVi, true)
  //           return b.indexOf(a) > -1
  //         })
  //         .map((x) => ({
  //           value: x.code,
  //           label: i18n.language === 'en' ? x.nameEn : x.nameVi
  //         })) || []
  //     ),
  //     ZIO.tap((x) => {
  //       console.log(x, inputValue, officeCodes)
  //       return ZIO.unit
  //     })
  //   )

  const officeCodes = pipe(GeneralService.getOfficeCodes('CCE'), ErrorHandling.runDidMount([]))

  const diffTwoDateAsMonth = (d1: Date, d2: Date) =>
    moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'months')

  const onPolicySearch = () => {
    if (!!policy && !loading && !getError('policy')) {
      form.clearErrors('officeCode')
      getPolicy()
    }
  }

  i18n.on('languageChanged', (lang) => {
    if (officeCodeSelected) {
      form.setValue('officeCode.label', lang === 'en' ? officeCodeSelected.nameEn : officeCodeSelected.nameVi)
    }
  })

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: width < 1441 ? width : 1440
        }}
      >
        <View style={{ flexDirection: 'row', paddingVertical: 10, paddingHorizontal: 10, alignItems: 'flex-start' }}>
          <Input
            {...register('policy', {
              validate: {
                minlength8: (policy) => policy?.length > 7 || `${t('common:MS090001')}`
              }
            })}
            containerStyle={{ flex: 2 }}
            suffixIcon={() => (
              <TouchableOpacity style={{ marginHorizontal: 15 }} onPress={onPolicySearch}>
                {loading ? <CircularProgress color={'secondary'} size={25} /> : <assets.Search20Icon />}
              </TouchableOpacity>
            )}
            numberOnly={true}
            placeholder={t('common:Policy/Proposal')}
            errorMessage={getError('policy')}
            maxLength={9}
            disabledSearch={!permissions?.includes(Permission.SearchSubmission)}
            onIconPressed={onPolicySearch}
          />
          <Divider width={30} />

          <View style={[styles.selectSearch]}>
            <View>
              <Controller
                key={`${officeCode?.label}-${officeCode?.value}`}
                control={control}
                name={'officeCode'}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <SelectSearch
                      value={value}
                      onChange={(value) => {
                        form.clearErrors('officeCode')
                        setOfficeCodeSelected(officeCodes.find((item) => item.code === value?.value))
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      label={''}
                      options={officeCodes.map((item) => ({
                        label: getLanguage() === 'en' ? item.nameEn : item.nameVi,
                        value: item.code
                      }))}
                      disableUnderline={true}
                      placeholder={t('common:ChooseOffice')}
                      disabled={getOfficeCode !== null}
                      prefixIcon={<assets.LocationPinIcon />}
                      errorMessage={_.get(form.formState.errors, 'officeCode.message')}
                      inputStyle={[
                        styles.selectSearchStyle,
                        officeCode === null ? { borderWidth: 1, borderColor: 'red' } : null
                      ]}
                      buildLabel={(select) => `${select.value} - ${select.label}`}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      inputWhenFocus={true}
                    />
                  )
                }}
              />
            </View>
          </View>
        </View>
        {cells.length === 0 && <Text style={styles.emptyText}>{t('common:SearchToSeePolicy')}</Text>}
        {cells.length !== 0 && <View style={styles.divider} />}
        {cells.length !== 0 && (
          <Table
            headers={headers}
            rows={cells}
            currentSelected={selectedPolicy}
            onSelectedChanged={(data) => {
              console.log(data.value)
              setSelectedPolicy(`${data.value || ''}`)
            }}
          />
        )}
        <View style={styles.divider} />

        <View style={styles.btnContainer}>
          <RoundedButtonCustom
            text={t('common:PolicyDetail')}
            onPress={() =>
              props.navigation.navigate('PolicyDetail', {
                policyNum: policy,
                policyOwner: cells[0][3].value?.toString() || '-',
                // officeCodeName: officeCode?.label || null
                officeCode: watch('officeCode')?.value || '-',
                officeName: watch('officeCode')?.label || '-',
                primaryId: watch('primaryId')
              })
            }
            //disable={checkRoles(Permission.ViewPolicyDetailSubmission)}
            disable={true}
          />
          <RoundedButtonCustom text={t('common:InquiryComplaint')} onPress={() => null} disable={true} />
          <RoundedButtonCustom
            text={t('common:ChangeContactInfo')}
            onPress={() => {
              if (checkOfficeCode()) {
                pipe(
                  ChangeContactInfoService.getDataAccess(policy),
                  ZIO.map((accessDataCheck) => {
                    if (
                      !accessDataCheck.contractStatus ||
                      !accessDataCheck.paidToDateAdvance ||
                      (accessDataCheck.contractStatus !== ProductEnum.Status.IF &&
                        accessDataCheck.contractStatus !== ProductEnum.Status.LA) ||
                      (accessDataCheck.contractStatus === ProductEnum.Status.LA &&
                        diffTwoDateAsMonth(new Date(), accessDataCheck.paidToDateAdvance) > 24)
                    ) {
                      showToast(t('message:MS050034'), 'error')
                    } else {
                      linkTo(
                        `${BuildConfig.contextPath}/user/drawer/staff-submission/submission-transaction-list/transaction-detail` +
                          `?officeCode=${officeCode?.value}&officeName=${officeCode?.label}&policyNum=${policy}` +
                          `&policyOwner=${selectedPolicyOwner}&transactionName=${
                            TransactionType.CHANGE_CONTACT_INFO
                          }&isCCE=${1}`
                      )
                    }
                    return accessDataCheck
                  }),
                  bindLoader,
                  ErrorHandling.run()
                )
              }
            }}
            disable={checkRoles(Permission.ViewPolicyDetailSubmission)}
          />
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  inputLabel: {
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: 30
  },
  input: {
    marginLeft: 30,
    marginRight: 30,
    height: 46,
    width: '50%',
    alignSelf: 'flex-start',
    borderColor: '#808080'
  },
  emptyText: {
    display: 'flex',
    justifyContent: 'center',
    color: 'gray',
    marginTop: 51,
    marginBottom: 55
  },
  divider: {
    height: 30
  },

  btnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 30
  },
  button: {
    height: 46,
    borderRadius: 20
  },
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  modalContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)'
  },
  modalCard: {
    alignSelf: 'center',
    padding: 25,
    borderRadius: 8,
    backgroundColor: 'white'
  },
  selectSearch: {
    flex: 3
    //  borderWidth: 1
  },
  selectSearchStyle: {
    shadowColor: 'grey',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    borderRadius: 8,
    paddingVertical: 10,
    height: 46,
    paddingRight: 10
  }
})
