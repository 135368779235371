import { AddressForm } from '@pulseops/business/core'
import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace ContractInfoForm {
  export const codec = t.type({
    submitDate: form2.date.required,
    firstPolicyEffectiveDate: form2.date.required,
    policyTerm: form2.number.required,
    exclusionTerm: form2.string.optionalMaxLength(50),
    holdClaimsBenefitPayout: form2.string.optional,
    premiumPaymentStatus: form2.string.optionalMaxLength(50),

    policyHolder: t.type({
      policyHolder: form2.string.requiredMaxLength(100),
      businessAddress: t.type({
        ...AddressForm.codec.props,
        address: form2.string.requiredMaxLength(500),
        country: form2.string.required,
        phoneNumber: form2.phone.required,
        email: form2.email.required
      }),
      contactAddress: t.type({
        ...AddressForm.codec.props,
        address: form2.string.requiredMaxLength(500),
        country: form2.string.required,
        phoneNumber: form2.phone.required,
        email: form2.email.required
      }),
      businessSector: form2.string.requiredMaxLength(100),
      businessLicense: form2.string.requiredMaxLength(20),
      dateOfRegistration: form2.date.required,
      placeOfRegistration: form2.string.required,
      capital: form2.number.required,
      taxCode: form2.string.requiredMaxLength(30),
      entityType: form2.string.required
    }),

    legalRepresentative: t.type({
      fullName: form2.string.requiredMaxLength(100),
      position: form2.string.requiredMaxLength(100),
      idNumber: form2.string.requiredMaxLength(25),
      dateOfIssue: form2.date.requiredPast,
      placeOfIssue: form2.string.requiredMaxLength(100),
      dateOfBirth: form2.date.requiredPast,
      placeOfBirth: form2.string.requiredMaxLength(100),
      gender: form2.string.required,
      nationality: form2.string.required,
      otherNationality: form2.string.optional,
      visa: form2.string.optionalMaxLength(100),
      phoneNumber: form2.phone.required,
      email: form2.email.required,
      permanentAddress: form2.string.requiredMaxLength(500),
      country: form2.string.required,
      permanent: AddressForm.codec,
      residentAddress: form2.string.requiredMaxLength(500),
      country1: form2.string.required,
      residential: AddressForm.codec,
      overseaAddress: form2.string.optionalMaxLength(500),
      country2: form2.string.optional,
      oversea: AddressForm.codec,
      hasstockexchange: form2.string.required,
      hasusshareholder: form2.string.required
    }),

    policyAdmin: t.type({
      fullName: form2.string.requiredMaxLength(100),
      position: form2.string.requiredMaxLength(100),
      idNumber: form2.string.requiredMaxLength(25),
      phoneNumber: form2.phone.required,
      email: form2.email.required
    }),

    totalEmployees: form2.int.requiredMax(9999999999),
    totalEmployeesInsured: form2.int.requiredMax(9999999999),
    proposedEffectiveDate: form2.date.required,
    modeOfPayment: form2.string.required,
    totalAnnualPremium: form2.number.requiredMin(0),
    discountPromotion: form2.string.optionalMaxLength(3),
    netAnnualPremium: form2.number.optionalMin(0),
    insufficientPremium: form2.number.optionalMin(0),
    previousCurrentInsurerName: form2.string.optionalMaxLength(100),
    groupPolicyNumber: form2.string.optionalMaxLength(20),
    groupPolicyEffectiveDate: form2.date.optional,
    bankName: form2.string.optionalMaxLength(100),
    bankBranch: form2.string.optionalMaxLength(100),
    accountNumber: form2.string.optionalMaxLength(50),
    accountName: form2.string.optionalMaxLength(100),
    establishedDate: form2.date.required,
    agentNumber1: form2.string.requiredMaxLength(20),
    agentName1: form2.string.requiredMaxLength(100),
    commissionRate1: form2.string.requiredMaxLength(4),
    agentNumber2: form2.string.optionalMaxLength(20),
    agentName2: form2.string.optionalMaxLength(100),
    commissionRate2: form2.string.optionalMaxLength(4)
  })
}
