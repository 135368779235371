import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { DIStackParamList } from './DIStackParamList'
import { DIDashboardScreen } from './digital-inquiry-dashboard'
import { PolicyInquiryScreen } from './policy-inquiry'
import { PolicyInquiryDetailScreen } from './policy-inquiry-detail'
import { VoucherInquiryScreen } from './voucher-inquiry'

const Stack = createStackNavigator<DIStackParamList>()

export const DIStack = () => {
  return (
    <Stack.Navigator initialRouteName="DIDashboardScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="DIDashboardScreen" component={DIDashboardScreen} />
      <Stack.Screen name="PolicyInquiryScreen" component={PolicyInquiryScreen} />
      <Stack.Screen name="PolicyInquiryDetailScreen" component={PolicyInquiryDetailScreen} />
      <Stack.Screen name="VoucherInquiryScreen" component={VoucherInquiryScreen} />
    </Stack.Navigator>
  )
}
