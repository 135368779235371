import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { ZIO, Task } from '@mxt/zio'
import { POApi } from '@pulseops/common'
import { ClientC, PolicyTypeC, SearchPolicy, Customer, PolicyS } from './model'
import { Nullable } from '@mxt/zio/codec'
import * as O from 'fp-ts/Option'

export const lAClient = t.type({
  clientNumberOfLA: t.string,
  life: t.string
})

export type lAClient = t.TypeOf<typeof lAClient>

export const benClient = t.type({
  clientNumberOfBen: t.string,
  percent: t.number,
  relationShipCode: t.string
})

export type benClient = t.TypeOf<typeof benClient>

export namespace StaffSubmissionService {
  export const getPolicy = (policyNum: string): Task<PolicyTypeC> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: PolicyTypeC
        })
      ),
      ZIO.map((policy) => policy.body)
    )

  export const getClientsByPolicy = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/bo/${policyNum}/clients`)(
        t.type({
          body: t.type({
            laList: t.array(lAClient),
            benList: t.array(benClient)
          })
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getPolicies = (
    policyNo: Array<{
      policyNo: string
      function?: string
    }>
  ): Task<Array<PolicyS>> =>
    pipe(
      POApi.post(`wf-api/policy/policies`)(
        t.type({
          body: t.array(PolicyTypeC)
        })
      )({
        body: policyNo
      }),
      ZIO.map((policies): Array<PolicyS> => {
        return policies.body.map((policy) => ({
          salaryIncome: policy.salaryIncome ?? 0,
          lastIssueDate: policy.lastIssueDate ?? '',
          paidToDateAdvance: policy.paidToDateAdvance ?? '',
          paidToDateBasic: policy.paidToDateBasic ?? '',
          contractStatus: policy.status ?? '',
          contractDate: policy.contractDate ?? '',
          productCode: policy.productCode ?? '',
          status: policy.status ?? '',
          productType: policy.productType ?? '',
          clientDespatchAddress: policy.clientDespatchAddress ?? '',
          policyNo: policy.policyNo ?? '',
          totalPremium: policy.totalPremium ?? 0,
          ownerId: policy.owners.id ?? '',
          mainLifeAssuredNumber: policy.mainLifeAssuredNumber ?? '',
          billFreq: policy.billFreq ?? '',
          premiumStatus: policy.premiumStatus ?? '',
          attributesExt: policy.attributesExt
            ? {
                basicPremium: policy.attributesExt.basicPremium,
                riderPremium: policy.attributesExt.riderPremium
              }
            : undefined
        }))
      })
    )

  export const getClients = (clientIds: Array<{ clientId: string }>) =>
    pipe(
      POApi.post(`wf-api/customer/customers`)(
        t.type({
          body: t.array(ClientC)
        })
      )({
        body: {
          clients: clientIds
        }
      }),
      ZIO.map((clients) => clients)
    )

  export const searchPolicy = (policyNum: string): Task<SearchPolicy[]> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            owners: t.type({
              id: Nullable(t.string)
            })
          })
        })
      ),
      ZIO.flatMap((res) =>
        pipe(
          res.body.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed([]),
            (clientId) =>
              pipe(
                getClientsByPolicy(policyNum),
                ZIO.flatMap((cl) =>
                  cl.laList.length
                    ? getClients([{ clientId }].concat(cl.laList.map((l) => ({ clientId: l.clientNumberOfLA }))))
                    : getClients([{ clientId }])
                ),
                ZIO.map((res): SearchPolicy[] => [
                  {
                    policyNum,
                    idNum: res.body[0]?.externalIds?.SOE_VALUE ?? '-',
                    clientName: res.body[0]?.name ?? '-',
                    laName: res.body.length < 2 ? '-' : res.body[1].name ?? '-'
                  }
                ])
              )
          )
        )
      )
    )

  export const getCustomer = (clientId: string): Task<Customer> =>
    pipe(POApi.get(`wf-api/customer/${clientId}`)(Customer))

  export const getOwnerInfo = (policyNum: string): Task<Customer> =>
    pipe(
      getPolicy(policyNum),
      ZIO.flatMap((policy) =>
        pipe(
          policy.owners.id,
          O.fromNullable,
          O.filter((id) => id.length > 0),
          O.fold(
            () => ZIO.succeed(<Customer>{}),
            (clientId) => getCustomer(clientId)
          )
        )
      )
    )
}
