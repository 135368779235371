import IconDocument from './icon-document.svg'
import IconPaper from './icon-paper.svg'
import IconEdit from './icon-edit.svg'
import IconTime from './icon-time.svg'
import IconDot from './icon-dot.svg'
import IconArrowLeft from './icon-arrow-left.svg'
import IconRefresh from './icon-refresh.svg'
import IconRefreshDisabled from './icon-refresh-disabled.svg'
import IconCancel from './icon-cancel.svg'
import IconSave from './icon-save.svg'
import IconClose from './icon-close.svg'
import IconBusiness from './icon-business.svg'
import IconSearch2 from './icon-search-2.svg'
import IconEditEmail from './icon-edit-email.svg'
import IconArrowDown from './icon-arrow-down.svg'
import IconCalendar from './icon-calendar.svg'
import IconArrowDownGray from './icon-arrow-down-gray.svg'
import IconCalendarGray from './icon-calendar-gray.svg'
import IconEditInRow from './icon-edit-in-row.svg'
import IconVerticalElipsis from './icon-vertical-elipsis.svg'
import IconFolder from './icon-folder.svg'
import IconArrowLeftCircleGray from './icon-arrow-left-circle-gray.svg'
import IconArrowRightCircleRed from './icon-arrow-right-circle-red.svg'
import IconArrowLeftCircleRed from './icon-arrow-left-circle-red.svg'
import IconArrowRightCircleGray from './icon-arrow-right-circle-gray.svg'
import IconDownload from './icon-download.svg'
import IconSearch from './icon-search.svg'
import IconAddNew from './icon-add-new.svg'
import IconSaveAddNew from './icon-save-add-new.svg'
import IconDeleteAddNew from './icon-delete-add-new.svg'
import IconIssue from './icon-issue.svg'
import IconDownloadWhite from './icon-download-white.svg'

export const assets = {
  IconDocument,
  IconPaper,
  IconEdit,
  IconTime,
  IconDot,
  IconArrowLeft,
  IconRefresh,
  IconRefreshDisabled,
  IconCancel,
  IconSave,
  IconClose,
  IconSearch2,
  IconBusiness,
  IconEditEmail,
  IconArrowDown,
  IconCalendar,
  IconArrowDownGray,
  IconCalendarGray,
  IconEditInRow,
  IconVerticalElipsis,
  IconFolder,
  IconArrowLeftCircleGray,
  IconArrowRightCircleRed,
  IconArrowLeftCircleRed,
  IconArrowRightCircleGray,
  IconDownload,
  IconSearch,
  IconAddNew,
  IconSaveAddNew,
  IconDeleteAddNew,
  IconIssue,
  IconDownloadWhite
}
