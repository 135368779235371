import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
export const FileAttachmentItem = t.type({
  fileName: t.string,
  fileExtension: t.string,
  size: t.number,
  base64: t.string,
  uploadedDate: form2.date.required,
  file: form2.file.required
})

// type FileAttachmentsBrandType = {
//   readonly FileAttachments: unique symbol
// }

// export type FileAttachmentList = t.Branded<FileAttachments, FileAttachmentsBrandType>

export const FileAttachments = t.array(FileAttachmentItem)

export type FileAttachmentItem = t.TypeOf<typeof FileAttachmentItem>
export type FileAttachments = t.TypeOf<typeof FileAttachments>
