export enum HistoryType {
  Contract = 'contract',
  Shareholder = 'shareholder',
  Employee = 'employee',
  Benefit = 'benefit'
}

export type HistoryId =
  | { historyType: HistoryType.Contract }
  | { historyType: HistoryType.Shareholder; shareHolderKey: string }
  | { historyType: HistoryType.Employee; employeeKey: string }
  | { historyType: HistoryType.Benefit }
