// import { Title } from "@pulseops/common"
import {
  AppContext,
  assets,
  DatePicker,
  ErrorHandling,
  GeneralService,
  Input,
  Select,
  SelectOption,
  SelectSearch,
  UploadDocumentReportService,
  useMobile,
  AuthService,
  RBAC,
  UploadDocumentReportPermission,
  isCasualLabor,
  UserRoles
} from '@pulseops/common'
import { SubmissionReportConst, ReportService } from '@pulseops/reports'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import { UploadDocumentReportFormData } from './upload-document-report.form'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Throwable, ZIO } from '@mxt/zio'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import moment from 'moment'
import { UploadDocumentReportConst } from './upload-document-report.const'
import { addMonths, isAfter, startOfDay } from 'date-fns/fp'

export interface UploadDocReportItem {
  no: string
  policyNo: string
  proposalNo: string
  agentCode: string
  agentName: string
  poName: string
  department: string
  documentType: string
  type2: string
  officeCode: string
  createdDate: string
}

export const UploadDocumentReportScreen = () => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const maxDate = new Date()
  const { isMobile } = useMobile()
  let isView: boolean = false
  const UploadDocumentReportForm = useForm<UploadDocumentReportFormData.UploadDocumentReport>({
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      policyNumber: '',
      proposalNumber: '',
      agentCode: '',
      template: {
        value: SubmissionReportConst.Extension.EXCEL,
        label: 'Excel'
      }
    }
  })
  const { control, setValue, watch } = UploadDocumentReportForm
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [departmentList, setDepartmentList] = React.useState<SelectOption[]>([])
  const [docTypeList, setDocTypeList] = React.useState<UploadDocumentReportService.ReportDocumentType[]>([])
  const [officeCodeList, setOfficeCodeList] = React.useState<SelectOption[]>([])
  const [policyNoFlag, setPolicyNoFlag] = React.useState<boolean>(false)
  const [proposalNoFlag, setProposalNoFlag] = React.useState<boolean>(false)
  const [agentNoFlag, setAgentNoFlag] = React.useState<boolean>(false)
  const isGaLoginFlag = React.useRef<boolean>(false)
  const [dataSource, setDataSource] = React.useState<UploadDocReportItem[]>([])
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('utilities:Utilities'),
          navigate: () => {
            navigate('UtilitiesStack', { screen: 'Utilities' })
          }
        },
        {
          title: t('utilities:UploadDocumentsReport'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  pipe(
    UploadDocumentReportService.getUploadDocumentReportInfo(),
    ZIO.map((uploadDocData) => {
      const { departmentArr, docTypeList } = uploadDocData
      if (!!departmentArr && departmentArr.DepartmentList && departmentArr.DepartmentList.length > 0) {
        const departItemList = departmentArr.DepartmentList.map((department) => {
          return {
            value: department.departmentShortName,
            label: i18n.language === 'vi' ? department.departmentVN : department.departmentEN
          }
        })
        setDepartmentList(departItemList)
      }
      if (docTypeList) {
        setDocTypeList(docTypeList)
      }
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  )

  pipe(
    GeneralService.getALLOfficeList(),
    ZIO.map((codes) => {
      if (codes && codes.length > 0) {
        const officeCodeArr = codes.map((code) => {
          return {
            value: code.code,
            label: i18n.language === 'vi' ? code.nameVi : code.nameEn
          }
        })
        setOfficeCodeList(officeCodeArr)
      }
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  )

  React.useEffect(() => {
    if (officeCodeList && officeCodeList.length > 0) {
      pipe(
        AuthService.userInfo,
        ZIO.flatMap((userData) => {
          const casualLaborInfo =
            userData.isGaLogin && !isCasualLabor()
              ? pipe(
                  RBAC.getPermission(userData.email),
                  ZIO.map((emailList) => {
                    let isCasualLabor: boolean = false
                    if (emailList && emailList.groups) {
                      isCasualLabor = emailList.groups.includes(UserRoles.CasualLabor)
                    }
                    return {
                      ...userData,
                      isCasualLabor: isCasualLabor
                    }
                  })
                )
              : ZIO.effect(() => ({
                  ...userData,
                  isCasualLabor: isCasualLabor()
                }))
          return casualLaborInfo
        }),
        ZIO.flatMap((userData) => {
          isGaLoginFlag.current = userData.isGaLogin && !userData.isCasualLabor
          return userData.isGaLogin
            ? pipe(officeCodeList, (officeList) => {
                const officeItem = officeList.find((x) => x.value === userData?.officeCode)
                // const officeCode = officeItem
                if (!!officeItem) {
                  const initialOfficeItem = {
                    label: officeItem.label,
                    value: officeItem.value
                  }
                  setValue('office', [initialOfficeItem])
                }
                return ZIO.unit
              })
            : ZIO.succeed(null)
        }),
        ErrorHandling.run()
      )
    }
  }, [officeCodeList])

  const onPolicyNumberChangeEvent = (value: string) => {
    if (value && value.length === 8) {
      setValue('proposalNumber', '')
      setValue('agentCode', '')
      setPolicyNoFlag(true)
    } else {
      setPolicyNoFlag(false)
    }
  }

  const onProposalNoChangeEvent = (value: string) => {
    if (value && value.length === 9) {
      setValue('policyNumber', '')
      setValue('agentCode', '')
      setProposalNoFlag(true)
    } else {
      setProposalNoFlag(false)
    }
  }

  const onAgentNoChangeEvent = (value: string) => {
    if (value && value.length === 8) {
      setValue('policyNumber', '')
      setValue('proposalNumber', '')
      setAgentNoFlag(true)
    } else {
      setAgentNoFlag(false)
    }
  }

  const getUploadReportQueryData = (pagSize: number, pagNumber: number) => {
    const agentCode = watch('agentCode')
    const policyNumber = watch('policyNumber')
    const proposalNumber = watch('proposalNumber')
    const listOfficeCode = watch('office').map((officeItem) => officeItem.value)
    const listDepartment = watch('department').map((departmentItem) => departmentItem.value)
    const fromDate = moment(watch('fromDate')).format('yyyy-MM-DD')
    const toDate = moment(watch('toDate')).format('yyyy-MM-DD')
    const inputData: UploadDocumentReportService.UploadReportInputData = {
      order: 'DESC',
      size: pagSize,
      sort: 'createdDate',
      start: pagNumber,
      listOfficeCode: listOfficeCode,
      agentCode: agentCode,
      policyNo: policyNumber,
      proposalNo: proposalNumber,
      listDepartment: listDepartment,
      fromDate: fromDate,
      toDate: toDate
    }
    return inputData
  }

  const validateUploadReportFields = async () => {
    // const agentCode = watch('agentCode')
    // const policyNumber = watch('policyNumber')
    // const proposalNumber = watch('proposalNumber')
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')
    const customedFromDate = moment(fromDate).format('YYYY/MM/DD')
    const customedToDate = moment(toDate).format('YYYY/MM/DD')
    const isValidForm = await UploadDocumentReportForm.trigger()
    if (!isValidForm) {
      return false
    }
    // else if (!agentCode && !policyNumber && !proposalNumber) {
    //   showToast(t('message:MS990035'), 'error')
    //   return false
    // }
    else if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
      return false
    } else if ((fromDate && !toDate) || (!fromDate && toDate)) {
      return false
    } else if (moment(customedFromDate) > moment(customedToDate)) {
      showToast(t('message:MS030044'), 'error')
      return false
    } else if (
      moment(customedFromDate).valueOf() > moment().valueOf() ||
      moment(customedToDate).valueOf() > moment().valueOf()
    ) {
      return false
    } else if (
      pipe(fromDate, startOfDay, addMonths(1), (fromDateAdd3Months) => isAfter(fromDateAdd3Months)(startOfDay(toDate)))
    ) {
      showToast(t('message:MS080004', { k: 1 }), 'error')
      return false
    }
    return true
  }

  const setSearchDefaultValues = () => {
    const currentDate = new Date()
    const departmentArr = watch('department')
    if (!watch('fromDate') && !watch('toDate')) {
      setValue('fromDate', currentDate)
      setValue('toDate', currentDate)
    }
    if (!departmentArr || departmentArr.length <= 0) {
      setValue('department', departmentList)
    }
  }

  const mappingDataSource = (reportData: UploadDocumentReportService.UploadReportListData, start: number = 0) => {
    const filteredReportList = reportData.map((reportItem, index) => {
      const createdDate = moment(reportItem.createdDate).format('DD/MM/YYYY')
      const departmentItem = departmentList.find((x) => x.value === reportItem.department)
      const officeItem = officeCodeList.find((x) => x.value === reportItem.officeCode)
      const officeCode = !!officeItem ? `${officeItem?.value} - ${officeItem?.label}` : reportItem.officeCode
      const documentTypeItem = docTypeList.find(
        (x) => x.departmentType === reportItem.department && x.docTypeCode === reportItem.documentType
      )
      const documentType = i18n.language === 'en' ? documentTypeItem?.doctypeEn : documentTypeItem?.doctypeVn
      const customerFullName =
        !!reportItem.policyNo || !!reportItem.proposalNo ? reportItem.poName : reportItem.agentName
      return {
        ...reportItem,
        no: (index + start + 1).toString(),
        department: departmentItem?.label ?? '',
        officeCode: officeCode,
        createdDate: createdDate,
        documentType: documentType ?? '',
        poName: customerFullName ?? ''
      }
    })
    isView && setDataSource(filteredReportList)
    return filteredReportList
  }

  const handleReport = async (
    handle: (report: UploadDocumentReportService.UploadDocumentReportOutputData) => ZIO<any, Throwable, any>
  ) => {
    if (await validateUploadReportFields()) {
      const size = isView ? pageSize : 0
      showGlobalLoading(true)
      const queryData = getUploadReportQueryData(size, 0)
      pipe(
        UploadDocumentReportService.searchUploadDocumentReport(queryData),
        ZIO.flatMap((reportData) => {
          return pipe(
            O.fromNullable(reportData),
            O.filter((x) => x.body.data && x.body.data.length > 0),
            O.fold(
              () => {
                showToast(t('message:MS030029'), 'error')
                isView && setDataSource([])
                showGlobalLoading(false)
                return ZIO.unit
              },
              (response) => {
                isView && mappingDataSource(response.body.data)
                showGlobalLoading(false)
                return handle(response)
              }
            )
          )
        }),
        ZIO.catchAll(() => {
          showToast(t('message:MS030029'), 'error')
          isView && setDataSource([])
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const handlePaging = (pageIndex: number, currentpageSize: number) => {
    const start = currentpageSize * pageIndex
    isView = true
    showGlobalLoading(true)
    const queryData = getUploadReportQueryData(currentpageSize, start)
    pipe(
      UploadDocumentReportService.searchUploadDocumentReport(queryData),
      ZIO.flatMap((reportData) => {
        return pipe(
          O.fromNullable(reportData),
          O.filter((x) => x.body.data && x.body.data.length > 0),
          O.fold(
            () => {
              showToast(t('message:MS030029'), 'error')
              isView && setDataSource([])
              showGlobalLoading(false)
              return ZIO.unit
            },
            (response) => {
              mappingDataSource(response.body.data, start)
              showGlobalLoading(false)
              return ZIO.unit
            }
          )
        )
      }),
      ZIO.catchAll(() => {
        showToast(t('message:MS030029'), 'error')
        isView && setDataSource([])
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const getDisplayedDataForExport = () => {
    const customedFromDate = watch('fromDate')
    const customedToDate = watch('toDate')
    const templateType = watch('template').value
    const fileName = 'RP0001 - Upload Documents Report'
    const sheetName = 'RP0001 - Upload Documents'
    const summary = {
      fromDate: customedFromDate,
      toDate: customedToDate,
      title: t(`utilities:UploadDocumentsReport`),
      category: 'Upload document'
    }
    const reportColumns = UploadDocumentReportConst.displayedColumns.map((columnItem) => {
      return {
        ...columnItem,
        name: t(columnItem.name)
      }
    })
    const columns = reportColumns.map(({ name, widthPx, horizontal }) => ({
      name,
      horizontal,
      width: widthPx
    }))
    return {
      templateType,
      summary,
      columns,
      fileName,
      sheetName
    }
  }

  const onViewReportClick = () => {
    isView = true
    setSearchDefaultValues()
    handleReport((report) => {
      setPageNum(0)
      setTotalItem(report.body.total)
      return ZIO.unit
    })
  }
  const onExcelExport = () => {
    isView = false
    setSearchDefaultValues()
    handleReport((report) => {
      const { templateType, summary, columns, fileName, sheetName } = getDisplayedDataForExport()
      const exportDataSource = mappingDataSource(report.body.data)
      const rows = exportDataSource.map(
        ({ no, policyNo, proposalNo, agentCode, poName, createdDate, officeCode, department, documentType, type2 }) => [
          no,
          policyNo,
          proposalNo,
          agentCode,
          poName,
          createdDate,
          officeCode,
          department,
          documentType,
          type2
        ]
      )
      isView = true
      return ReportService.exportTemplateRP0001(
        { ref: 'B8', colRef: 2, rowRef: 8 },
        summary,
        columns,
        rows,
        templateType,
        fileName,
        sheetName
      )
    })
  }
  return (
    <View style={uploadReportStyles.pageContent}>
      <ScrollView style={uploadReportStyles.container}>
        <View style={uploadReportStyles.sectionContent}>
          <View style={uploadReportStyles.sectionRow}>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="fromDate"
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('common:FromDate') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:FromDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                    maxDateMessage={'Date should not be future'}
                  />
                )}
              />
            </View>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="toDate"
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('common:ToDate') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:ToDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                    maxDateMessage={'Date should not be future'}
                  />
                )}
              />
            </View>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="office"
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('Reports:Office') })
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={t('Reports:Office')}
                    labelStyle={{ ...uploadReportStyles.selectSearchText, fontSize: isMobile ? 13 : 15 }}
                    options={officeCodeList}
                    errorMessage={!!value && value.length > 0 ? '' : error?.message}
                    required={true}
                    multiple={true}
                    disabled={isGaLoginFlag.current}
                    buildLabel={(select) => `${select.value} - ${select.label}`}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
          </View>

          <View style={[uploadReportStyles.sectionRow, uploadReportStyles.secondLine]}>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="department"
                // rules={{
                //   required: {
                //     value: true,
                //     message: t('message:MS020001', { field: t('utilities:department') })
                //   }
                // }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    label={t('utilities:department')}
                    labelStyle={{ ...uploadReportStyles.selectSearchText, fontSize: isMobile ? 13 : 15 }}
                    options={departmentList}
                    errorMessage={!!value && value.length > 0 ? '' : error?.message}
                    multiple={true}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                  />
                )}
              />
            </View>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="policyNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    onChange={(itemValue) => {
                      onChange(itemValue)
                      onPolicyNumberChangeEvent(itemValue)
                    }}
                    onBlur={onBlur}
                    disabled={proposalNoFlag || agentNoFlag}
                    title={t('utilities:PolicyNumber')}
                    maxLength={8}
                  />
                )}
              />
            </View>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="proposalNumber"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    onChange={(itemValue) => {
                      onChange(itemValue)
                      onProposalNoChangeEvent(itemValue)
                    }}
                    onBlur={onBlur}
                    disabled={policyNoFlag || agentNoFlag}
                    title={t('utilities:ProposalNumber')}
                    maxLength={9}
                  />
                )}
              />
            </View>
          </View>

          <View style={[uploadReportStyles.sectionRow, uploadReportStyles.secondLine]}>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="agentCode"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    onChange={(itemValue) => {
                      onChange(itemValue)
                      onAgentNoChangeEvent(itemValue)
                    }}
                    onBlur={onBlur}
                    disabled={policyNoFlag || proposalNoFlag}
                    title={t('utilities:AgentCode')}
                    maxLength={8}
                  />
                )}
              />
            </View>
            <View style={uploadReportStyles.col_4}>
              <Controller
                control={control}
                name="template"
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t('Reports:Template')}
                    options={SubmissionReportConst.TemplateList}
                  ></Select>
                )}
              />
            </View>
          </View>

          <View style={[uploadReportStyles.sectionRow, uploadReportStyles.secondLine]}>
            <View style={[uploadReportStyles.col_4, { flex: 1, flexDirection: 'row', alignItems: 'center' }]}>
              {permissions.includes(UploadDocumentReportPermission.ViewReportUploadDocument) && (
                <TouchableOpacity style={{ width: 118, height: 40 }} onPress={onViewReportClick}>
                  <View style={uploadReportStyles.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('utilities:view')}</Text>
                  </View>
                </TouchableOpacity>
              )}
              {permissions.includes(UploadDocumentReportPermission.ExportReportUploadDocument) && (
                <TouchableOpacity
                  style={[
                    { width: 118, height: 40 },
                    permissions.includes(UploadDocumentReportPermission.ViewReportUploadDocument) && { marginLeft: 30 }
                  ]}
                  onPress={onExcelExport}
                >
                  <View style={uploadReportStyles.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Export')}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>

        {dataSource && dataSource.length > 0 && (
          <View style={uploadReportStyles.tableContainer}>
            <TableContainer component={Paper} style={{ maxHeight: 350 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold', fontSize: 15 }}>
                      #
                    </TableCell>
                    <TableCell style={{ minWidth: 110, fontWeight: 'bold', fontSize: 15 }}>
                      {t('UploadDocReport:Policy')}
                    </TableCell>

                    <TableCell style={{ minWidth: 210, fontWeight: 'bold', fontSize: 15 }}>
                      {t('UploadDocReport:Proposal')}
                    </TableCell>
                    <TableCell style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                      {t('utilities:AgentCode')}
                    </TableCell>
                    <TableCell style={{ minWidth: 210, fontWeight: 'bold', fontSize: 15 }}>
                      {t('UploadDocReport:CAFullName')}
                    </TableCell>
                    <TableCell style={{ minWidth: 130, fontWeight: 'bold', fontSize: 15 }}>
                      {t('UploadDocReport:CreatedDate')}
                    </TableCell>
                    <TableCell style={{ minWidth: 230, fontWeight: 'bold', fontSize: 15 }}>
                      {t('common:Office')}
                    </TableCell>
                    <TableCell style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                      {t('utilities:department')}
                    </TableCell>
                    <TableCell style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                      {t('utilities:documentType')}
                    </TableCell>
                    <TableCell style={{ minWidth: 110, fontWeight: 'bold', fontSize: 15 }}>
                      {t('UploadDocReport:Type')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSource.map((dataItem, index) => (
                    <TableRow key={index}>
                      <TableCell>{dataItem.no}</TableCell>
                      <TableCell>{dataItem.policyNo}</TableCell>
                      <TableCell>{dataItem.proposalNo}</TableCell>
                      <TableCell>{dataItem.agentCode}</TableCell>
                      <TableCell>{dataItem.poName}</TableCell>
                      <TableCell>{dataItem.createdDate}</TableCell>
                      <TableCell>{dataItem.officeCode}</TableCell>
                      <TableCell>{dataItem.department}</TableCell>
                      <TableCell>{dataItem.documentType}</TableCell>
                      <TableCell>{dataItem.type2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              // rowsPerPageOptions={[5, 10, 25]}
              rowsPerPageOptions={[10, 25, 50, 100]}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                handlePaging(page, pageSize)
              }}
              onRowsPerPageChange={(e) => {
                const rowsPerPage = Number(e.target.value)
                setPageSize(rowsPerPage)
                if (pageNum * rowsPerPage >= totalItem) {
                  setPageNum(0)
                  handlePaging(0, rowsPerPage)
                } else {
                  handlePaging(pageNum, rowsPerPage)
                }
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            ></TablePagination>
          </View>
        )}
      </ScrollView>
    </View>
  )
}
const uploadReportStyles = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  selectSearchText: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 2
  },
  tableContainer: {
    marginTop: 20
  }
})
