import { CancelRider } from './CancelRider'

export namespace InquiryComplaintData {
  export interface DetailData {
    laList: CancelRider.CanRiderClientInfo[]
    benList: ICbenInfo[]
    poName: string
    agentCode: string
    agentName: string
    groupList: IACGroup[]
    methodList: IACSources[]
    sourceList: CSSources[]
    BORelationshipList: BORelationshipList[]
    CBrelationshipList: CBRelationshipList[]
    BERelationshipList: BERelationshipData[]
    clientId: string
  }

  export interface ICbenInfo {
    clientNumberOfBen: string
    relationShipCode: string
    clientName: string
  }

  export interface ICAgentInfo {
    agentCode: string
    agentName: string
    agentID?: string
  }

  export interface COMPLAINTMISMONEYDETAILS {
    hasReciept: string
    recieptType: string
    recieptNum: string
    complaintAmount: number
    misPremiumDate: string
    evidenceStatus?: string // update payload fields
    verifiedAmount?: number // update payload fields
  }

  export interface COMPLAINTSALEDETAILS {
    agentCode: string
    agentName: string
    financeNeedCode: string
    isPoSignedInProposal: boolean
    isCustomerReceiveKit: boolean
    customerReceiveDate: string
    evidence?: boolean // update payload fields
  }

  export interface COMPLAINTGADETAILS {
    generalAgency: string
    personComplaint: string
    role?: string // update payload fields
    verifiedAmount?: number // update payload fields
    evidenceStatus?: string // update payload fields
  }
  export interface CONSERVATIONINFO {
    product: string
    servicingAgentCode: string
    servicingAgentName: string
    conservationGAStaff: string
    conservationAgentCode: string
    conservationAgentName: string
    reasonFullSurrender: string
    conservationResult: string
    conservationSolution: string
    conservationBonus: string
    conservationNote: string
  }

  export interface CONSERVATIONINFO {
    product: string
    servicingAgentCode: string
    servicingAgentName: string
    conservationGAStaff: string
    conservationAgentCode: string
    conservationAgentName: string
    reasonFullSurrender: string
    conservationResult: string
    conservationSolution: string
    conservationBonus: string
    conservationNote: string
  }

  export interface SubmitData {
    CATEGORY_CODE: string
    GROUP_CODE: string
    SUB_SVC_TYPE_CODE: string
    SUB_TRANS_TYPE_CODE: string
    REQUEST_WITH_ROLE: string
    REQUESTER_FULL_NAME: string
    REQUESTER_NATIONAL_ID: string
    TOUCH_POINT: string
    RLA_WITH_PO: string
    METHOD: string
    SOURCE: string
    HOT: boolean
    FCR: boolean
    CUSTOMER_CMT: string
    RES_TO_CUSTOMER: string
    TOTAL_MIS_MONEY_AMT: number
    TOTAL_VERIFIED_AMOUNT?: number // update payload fields
    COMPLAINT_MIS_MONEY_DETAILS: COMPLAINTMISMONEYDETAILS[]
    COMPLAINT_SALE_DETAILS: COMPLAINTSALEDETAILS[]
    COMPLAINT_GA_DETAILS: COMPLAINTGADETAILS[]
    CONSERVATION_INFO?: CONSERVATIONINFO | {}
    CLOSED_INFO?: string
    CALL_ID?: string,
    CALL_BACK_LATER_HOUR?: number,
    CALLBACK_APPOINTMENT_TIME?: string
    POLICY_OWNER_NAME?: string
  }

  export interface BORelationshipList {
    // clientNumList: string[]
    // relationshipList: string[]
    clientNum: string
    relationship: string
  }

  export interface CBRelationshipList {
    id: string
    code: string
    name: string
    nameEn: string
  }

  export interface BERelationshipData {
    id: string
    code: string
    female: string
    male: string
    name: string
    nameEn: string
  }

  export interface IACGroup {
    id: string
    categoryName: string
    code: string
    name: string
    nameVi: string
  }

  export interface IACType {
    id: string
    groupCode: string
    code: string
    name: string
    nameVi: string
  }

  export interface IACSubtypes {
    id: string
    typeCode: string
    code: string
    name: string
    nameVi: string
    slaStandard: string | null | undefined
  }

  export interface IACSources {
    id: string
    code: string
    name: string
    nameVi: string
  }

  export interface IACReceiptType {
    code: string
    name: string
    nameEn: string
  }

  export interface CSSources {
    id: string
    code: string
    name: string
    nameVi: string
    touchPoint: string
  }
  export interface FullSurrenderReason {
    reasonCode: string
    reasonEN: string
    reasonVN: string
  }
  export interface Resolution {
    resolutionCode: string
    resolutionEN: string
    resolutionVN: string
  }

  export interface AdditionalDocumentList {
    name: string
    id: string
    url: string
  }

  export interface ViewHistoryList {
    historyID: string
    version: string
    editedBy: string
    editedDate: string
  }
}
