import { MaybeUndefined } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { UwDecisionCodeC } from './uw-decision-code'

export const UwDecisionC = t.type({
  createdBy: t.string,
  createdDate: DateFromISOString,
  finalUwDecision: UwDecisionCodeC,
  id: MaybeUndefined(t.string),
  masterContractNo: t.string,
  policyYear: t.number
})

export type UwDecision = t.TypeOf<typeof UwDecisionC>
