import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { PulseOpsFormat } from '../../../Formatter'
import { SubmissionService } from '../../SubmissionService'
import { ReinstatementService } from '../../ReinstatementService'
import { Frequency, mapFrequency } from '../Frequency'
import { Occupation } from '../mock'

export interface LifeAssured {
  clientNumber: string
  // questionFlag: boolean
  laName: string
  curOccupation?: string
  newOccupation?: string
  newActivity?: string
  newCompany?: string
  newHeathState: boolean
  newActivityState: boolean
}

export interface Reinstatement {
  tag: TransactionType.REINSTATEMENT
  clientName: string
  riskCommencementDate: string
  lapsedDate: string
  paymentFrequency: string
  installmentPremium: number
  reinstatementFee: number | null
  totalReinsAmount: number | null
  apl: number
  opl: number
  lifeAssured: LifeAssured[]
  agentNumber: string
  servicingAgent: string
  source: SourceType
}

interface ReinstatementMapData {
  lifeAssured: LifeAssured[]
  agentNumber?: string
  servicingAgent?: string
}

export const Reinstatement = (
  detail: api.Reinstatement,
  sqlFunds: SubmissionService.ClientQuery[],
  reinData: ReinstatementService.ReinData,
  poName: string
): Reinstatement => {
  const data = mapData(detail, sqlFunds)
  const { APL, OPL, REINS_FEE, TOTAL_REINS_AMOUNT, LAPSED_DATE, RISK_COMM_DATE, FREQUENCY, INSTALLMENT_PREM } =
    reinData.attributesExt

  return {
    tag: TransactionType.REINSTATEMENT,

    riskCommencementDate: RISK_COMM_DATE.length ? PulseOpsFormat.dateStr(RISK_COMM_DATE) : '-',
    paymentFrequency: FREQUENCY ? mapFrequency.get(FREQUENCY as Frequency) ?? '-' : '-', //missing
    apl: Number(APL),
    opl: Number(OPL),
    installmentPremium: Number(INSTALLMENT_PREM) || 0,
    reinstatementFee: Number(REINS_FEE),
    totalReinsAmount: Number(TOTAL_REINS_AMOUNT),
    clientName: poName,
    lapsedDate: LAPSED_DATE.length ? PulseOpsFormat.dateStr(LAPSED_DATE) : '-',
    lifeAssured: data?.lifeAssured ?? [],
    agentNumber: data.agentNumber ?? '',
    servicingAgent: data.servicingAgent ?? '',
    source: detail.source
  }
}

const mapData = (detail: api.Reinstatement, sqlFunds: SubmissionService.ClientQuery[]): ReinstatementMapData => {
  switch (detail.source) {
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS:
    case SourceType.BANCA_SEA:
      return {
        lifeAssured: detail.payload.body.lifeAssured.length
          ? detail.payload.body.lifeAssured.map(
              (fund): LifeAssured => ({
                clientNumber: fund.clientId || '-',
                laName: fund.name || '-',
                curOccupation:
                  Occupation.mapOcc.get(fund.attributesExt?.CUR_OCCUPATION ?? '-') ??
                  fund.attributesExt?.CUR_OCCUPATION ??
                  '',
                newOccupation:
                  Occupation.mapOcc.get(fund.attributesExt?.NEW_OCCUPATION ?? '-') ??
                  fund.attributesExt?.NEW_OCCUPATION ??
                  '',
                newActivity: fund.attributesExt?.NEW_ACTIVITY || '',
                newActivityState: !!fund.attributesExt?.IS_ACTIVITY_CHANGE,
                newCompany: fund.attributesExt?.COMPANY_NAME || '',
                newHeathState: !!fund.attributesExt?.IS_CHANGE_HEALTH_STATE
              })
            )
          : [],
        agentNumber: detail.payload.body.attributesExt.AGENT_CODE ?? '',
        servicingAgent: detail.payload.body.attributesExt.AGENT_NAME ?? ''
      }
    case SourceType.PULSE:
    case SourceType.BANCA:
    case SourceType.EKIOSK:
    case SourceType.PRUGREAT:
      return {
        lifeAssured: []
      }
  }
}
