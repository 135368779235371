import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ErrorHandling,
  Panel,
  useTranslator,
  AppContext,
  PulseOpsFormat,
} from '@pulseops/common'
import { CepTableField } from '../cep-components'
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { StyleSheet } from 'react-native'
import { CepPaymentModal } from './CepPaymentModal'
import { getPaymentData, getPaymentP2P, getTranPayment } from '@pulseops/inquiry'
import { PaymentItemC, PaymentItemList, PaymentItemP2PList, TranPaymentItemList } from '../../cep-api-services/CepPaymentModels'

export interface PAYInfo {
  paymentData: PaymentItemList
}
export const CepPaymentScreen = (props: {
  id: string
  data: PaymentItemList | null
  setData: (val: PaymentItemList | null) => void
  setOpenPayment: (e: boolean) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const cep = useTranslator('CustomerEngagement').t
  const [loading, bindLoading] = useLoading()

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [item, setItem] = React.useState<PaymentItemC | null>(null)
  const [itemP2P, setItemP2P] = React.useState<PaymentItemP2PList | null>(null)
  const [itemBank, setItemBank] = React.useState<TranPaymentItemList | null>(null)
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('CustomerEngagement:Payment'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getData = (): ZIO<unknown, Throwable, PaymentItemList> =>
    pipe(
      getPaymentData(props.id),
      ZIO.map((payment) => {
        return payment.data
      })
    )

    const { paymentData } = pipe(
      props.data != null ? ZIO.succeed(props.data) : getData(),
      bindLoading,
      ZIO.map((res) => {
        if (res !== null && props.data === null) props.setData(res)
        return {
          paymentData: res.map((item) => ({
            reqnNumber: item.reqnNumber ?? '',
            type: item.type ?? '',
            amount: PulseOpsFormat.thousandSepartor(Number(item.amount)) ?? 0,
            verifyDate: item.verifyDate ?? '',
            bankCode: item.bankCode ?? '',
            authorisedBy: item.authorisedBy ?? '',
            verifyUser: item.verifyUser ?? '',
            status: item.status ?? '',
            authDate: item.authDate ?? '',
            desc: item.desc ?? '',
            nationalId: item.nationalId ?? '',
            idForPayment: item.idForPayment ?? '',
            accountName: item.accountName ?? '',
            bankAccount: item.bankAccount ?? '',
            bankKey: item.bankKey ?? '',
            bankName: (item.bankKey ?? '') + ' '+  item.bankName ?? '',
            issDateAndAuthId: item.issDateAndAuthId ?? '',     
          }))
        }
      }),
      ErrorHandling.runDidMount()
    ) || {
      paymentData: []
    }

  const columnPayment = [
    {
      key: '1',
      title: cep('PaymentNo'),
      name: 'reqnNumber',
      color: () => '#1EA5FC',
      onPress: (index: number) => {
        setIsOpen(true)
        setItem(paymentData[index])
        getDetailData(paymentData[index])
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: '2',
      title: cep('Type'),
      name: 'type'
    },
    {
      key: '3',
      title: cep('PaymentAmount'),
      name: 'amount'
    },
    {
      key: '4',
      title: cep('VerfDate'),
      name: 'verifyDate'
    }
  ]

  const getDetailData = (data: PaymentItemC) => pipe(
    ZIO.zipPar(
      getTranPayment(props.id, data?.reqnNumber ?? ''),
      getPaymentP2P(props.id),
    ),
    ZIO.flatMap(([tranPayment, P2P]) => {
      const bankData = (tranPayment.data ?? []).map((item) => ({
        ...item,
        originAmount: PulseOpsFormat.thousandSepartor(Number(item.originAmount)),
        accountingAmount: PulseOpsFormat.thousandSepartor(Number(item.accountingAmount))
      }))
      setItemP2P(P2P)

      setItemBank(bankData)
      setIsOpen(true)
      return ZIO.unit
    }),
    ZIO.unsafeRun({})
  )

  return (
    <Container loading={loading}>
      <Panel title={cep('Payment')}>
        <CepTableField columns={columnPayment} dataSource={paymentData} name="Payment" />
      </Panel>
      <CepPaymentModal
        visible={isOpen}
        onPressClose={() => {
          setIsOpen(false)
          setItem(null)
        }}
        payData={item}
        id={props.id}
        bankData={itemBank}
        P2PData={itemP2P}
      />
    </Container>
  )
}

