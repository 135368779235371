import React from 'react'
import { Input, InternalFeedbackService, ModalComponent, useMobile } from '@pulseops/common'
import { ScrollView, StyleSheet } from 'react-native'
import { FieldText, SectionCol, SectionRow } from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'

type InternalFeedbackModalProps = {
  title: string
  feedbackDetail: InternalFeedbackService.InternalFeedbackDetail
  onClose: () => void
  onSuccess?: () => void
  open: boolean
}

export const InternalHistoryFeedbackModal = (props: InternalFeedbackModalProps) => {
  const { t } = useTranslation()
  const { isWide } = useMobile()
  const actionButtons = [
    {
      text: t('QC:Cancel'),
      type: 'outline' as 'outline',
      disabled: false,
      loading: false,
      onlyWide: false,
      style: { height: 39, marginEnd: 15 },
      action: props.onClose
    }
  ]
  return (
    <ModalComponent
      title={props.title}
      onClose={props.onClose}
      modalWidth={700}
      visible={props.open}
      actions={actionButtons}
    >
      <ScrollView style={{ marginHorizontal: 20, marginTop: isWide ? 20 : 16 }}>
        <SectionRow>
          <SectionCol>
            <FieldText text={t('InternalSuspend:Department')}></FieldText>
            <Input inputStyle={feedbackModalStyles.field_description} disabled value={props.feedbackDetail?.feedbackDepartment ?? ''}></Input>
          </SectionCol>
          <SectionCol>
            <FieldText text={t('InternalFeedback:Email')}></FieldText>
            <Input inputStyle={feedbackModalStyles.field_description} disabled value={props.feedbackDetail?.feedbackUser ?? ''}></Input>
          </SectionCol>
          <SectionCol>
            <FieldText text={t('InternalFeedback:FeedbackDate')}></FieldText>
            <Input inputStyle={feedbackModalStyles.field_description} disabled value={props.feedbackDetail?.feedbackDate ?? ''}></Input>
          </SectionCol>
        </SectionRow>
        <SectionRow sectionStyles={feedbackModalStyles.secondLine}>
          <SectionCol sectionStyles={feedbackModalStyles.col_12}>
            <FieldText text={t('InternalFeedback:FeedbackInformation')}></FieldText>
            <TextField
              value={props.feedbackDetail?.feedbackDetail}
              fullWidth
              multiline
              InputProps={{
                readOnly: true,
                disableUnderline: true
              }}
            />
          </SectionCol>
        </SectionRow>
      </ScrollView>
    </ModalComponent>
  )
}
const feedbackModalStyles = StyleSheet.create({
  container: {
    marginHorizontal: 20
  },
  col_12: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  }
})
