import { SelectOption, TransactionType } from '@pulseops/common'
export namespace JournalsForm {
  export interface JournalsData {
    category: string
    action: SelectOption
    transaction: TransactionType
    caseId: string
    journalPayable?: JournalsPayable
    journalUnit?: JournalsUnit[]
    journalPolicyAccount?: JournalsPolicyAccount[]
    journalBonus?: JournalsBonus
    journalRemove?: JournalsRemove
    totalPaymentAmount: number,
    remark: string
  }

  export enum JournalsOptions {
    PAYABLE = 'PAYABLE',
    UNIT_JOURNAL = 'UNIT_JOURNAL',
    POLICY_ACCOUNT_JOUNAL = 'POLICY_ACCOUNT_JOUNAL',
    BONUS_JOURNAL = 'BONUS_JOURNAL',
    REMOVE_JOURNAL = 'REMOVE_JOURNAL'
  }

  export interface JournalsPayable {
    releaseNumber?: string
    type?: SelectOption
    journalsTransfer: MoneyTransferPayable[]
    totalTransfer: number
    receivePolicy: string
    journalsReceive: MoneyReceivePayable[]
    totalReceive: number
    loanType?: SelectOption
  }

  export interface JournalsUnit {
    fund: string
    type: string
    unitBalance: string
    adjust: string
  }

  export interface JournalsPolicyAccount {
    fund: string
    unitType: string
    policyAccount: string
    adjust: string
  }

  export interface JournalsBonus {
    effectiveDate: Date
    accruedReversionaryBonus: string
    arbAmount: string
  }

  export interface JournalsRemove {
    journalNumber: string
  }

  export interface MoneyTransferPayable {
    SACCode: string
    SACType: string
    currentBalance: string
    tranferAmount: string
  }

  export interface MoneyReceivePayable {
    SACCode: string
    SACType: string
    amount: string
  }
}
