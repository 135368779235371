import { Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'

export const BenefitC = t.type({
  benefitKey: t.string,
  clazz: t.string,

  ddProductCodeB: t.string,
  ddProductNameB: t.string,
  geographicCoverageB: t.string,
  sumAssuredB: t.number,

  ddProductCodeR: Nullable(t.string),
  ddProductNameR: Nullable(t.string),
  geographicCoverageR: Nullable(t.string),
  sumAssuredR: Nullable(t.number),

  productCodeHCR: Nullable(t.string),
  productNameHCR: Nullable(t.string),
  healthcarePlan: Nullable(t.string)
})
export type Benefit = t.TypeOf<typeof BenefitC>
