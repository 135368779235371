import * as React from 'react'
import { Text, StyleSheet, TextProps } from 'react-native'
import { CanView } from './CanView'
import { useMobile } from '@pulseops/common'

interface Props {
  title: string
  required?: boolean
  titleProps?: TextProps
  requiedProps?: TextProps
}

export const Label = ({ title, required, titleProps = {}, requiedProps = {} }: Props) => {
  const { isWide } = useMobile()
  return (
    <CanView condition={!!title}>
      <Text style={[styles.label, { fontSize: isWide ? 15 : 13 }]} {...titleProps}>
        {title}
        <CanView condition={required === true}>
          <Text style={[styles.require, { fontSize: isWide ? 15 : 13 }]} {...requiedProps}>
            *
          </Text>
        </CanView>
      </Text>
    </CanView>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontWeight: 'bold'
  },
  require: {
    color: '#ED1B2E',
    fontWeight: 'bold',
    marginStart: 5
  }
})
