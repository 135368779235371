import { FileUploadData, StorageBlob, TransactionType } from '@pulseops/common'

export namespace RequestAuthenticateData {
  export const TransactionLabelShort = (type: TransactionType | null): string =>
    type ? mapTransactionTypeShort.get(type) || '-' : '-'

  const mapTransactionTypeShort = new Map<TransactionType, string>([
    [TransactionType.BENEFICIARY_DESIGNATION, 'Dieu chinh nguoi thu huong'],

    [TransactionType.CHANGE_CONTACT_INFO, 'Cap nhat thong tin lien lac'],
    [TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL, 'Cap nhat so dien thoai va thu dien tu'],
    [TransactionType.CHANGE_CONTACT_INFO_PHONE, 'Cap nhat so dien thoai'],
    [TransactionType.CHANGE_CONTACT_INFO_EMAIL, 'Cap nhat thu dien tu'],
    [TransactionType.CHANGE_CONTACT_INFO_ADDRESS, 'Cap nhat dia chi lien lac'],
    [TransactionType.CHANGE_CLIENT_INFO, 'Dieu chinh thong tin nhan than'],
    [TransactionType.CHANGE_PREMIUM, 'Dieu chinh phi bao hiem'],
    [TransactionType.CHANGE_SUM_ASSURED, 'Dieu chinh so tien bao hiem'],
    [TransactionType.PARTIAL_SURRENDER, 'Giam so tien bao hiem sau 2 nam phi dau tien'],
    [TransactionType.FULL_SURRENDER, 'Huy hop dong nhan gia tri hop dong'],

    [TransactionType.LOAN, 'Tam ung tu gia tri hoan lai'],
    [TransactionType.PRUCASH_PAYMENT, 'Nhan quyen loi dinh ky'],
    [TransactionType.MATURITY, 'Nhan quyen loi dao han'],
    [TransactionType.MATURITY_ADVANCE, 'Ung truoc quyen loi dao han'],

    [TransactionType.BILLING_CHANGE, 'Dieu chinh dinh ky nop phi'],
    [TransactionType.BILLING_FREQUENCY_REVERSAL, 'Chuyen tra dinh ky tu dong'],
    [TransactionType.REINSTATEMENT, 'Khoi phuc hieu luc hop dong'],
    [TransactionType.TOP_UP, 'Dau tu them'],
    [TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT, 'Xac nhan nop phi'],
    [TransactionType.CANCEL_FROM_INCEPTION, 'Huy, hoan phi HD'],
    [TransactionType.CANCEL_RIDER, 'Huy bo san pham bo tro'],
    [TransactionType.EXCESS_PREMIUM, 'Chi tra phi du'],
    [TransactionType.PARTIAL_WITHDRAWAL, 'Rut truoc mot phan gia tri HD'],
    [TransactionType.ASSIGNMENT, 'Chuyen nhuong hop dong'],

    [TransactionType.ZALO_CLAIM_DECISION, 'Zalo Claim Decision'],
    [TransactionType.ZALO_PREMIUM_COLLECTION, 'Zalo Premium Collection'],
    [TransactionType.ZALO_RECOMMENDATION, 'Zalo Recommendation'],
    [TransactionType.ZALO_REQUEST_PROCESS, 'Zalo Request Process'],
    [TransactionType.ZALO_OTHERS, 'Zalo Other'],

    [TransactionType.E_SERVICE_REGISTRATION, 'Dang ky dich vu truc tuyen'],
    [TransactionType.GO_ABROAD, 'Nuoc ngoai'],
    [TransactionType.LOAN_STATEMENTS, 'Sao ke tam ung gia tri hoan lai'],
    [TransactionType.POLICY_CERTIFICATE, 'Cap pho ban (Life Cert)'],
    [TransactionType.SPECIAL_BONUS, 'Bao tuc tich luy dac biet'],
    [TransactionType.BONUS_SURRENDER, 'Yeu cau rut truoc bao tuc'],
    [TransactionType.COMPLAINT_AND_INQUIRY, 'Inquiry & Complaint'],
    [TransactionType.PREMIUM_REDIRECTION, 'Thay doi ty le dau tu'],
    [TransactionType.SIGNATURE_REGISTRATION, 'Dang ky chu ky mau'],
    [TransactionType.EXCELLENT_STUDENT_AWARD, 'Quyen loi dang khoa'],
    [TransactionType.FUND_SWITCHING, 'Hoan doi quy'],
    [TransactionType.FATCA_DECLARATION, 'Chung tu FATCA'],
    [TransactionType.STOP_APL, 'Tam ngung su dung GTHL de dong phi'],
    [TransactionType.INHERITANCE, 'Thua ke'],
    [TransactionType.PRUKID369, 'Quyen loi hoan phi'],
    [TransactionType.ADD_LACK_OF_DOCUMENT, 'Bo sung chung tu'],
    [TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE, 'X/N gia tri HD va gia tri hoan lai'],
    [TransactionType.PAID_UP, 'Dung nop phi va duy tri hop dong'],
    [TransactionType.HEALTH_DECLARATION, 'Khai lai thong tin suc khoe'],
    [TransactionType.SCHEDULED_PAYMENT_STATEMENT, 'Ban ke chi tiet quyen loi dinh ky tren hop dong'],
    [TransactionType.FULL_SURRENDER_REVERSAL, 'Khoi phuc hieu luc HD sau khi huy'],
    [TransactionType.COMMON_REQUEST_FORM, 'Yeu cau chung'],
    [TransactionType.RIDER_ALTERATION, 'Dieu chinh san pham bo tro'],
    [TransactionType.MAJOR_CHANGE_IN_FREE_LOOK, 'D/chinh HD tai thoi gian can nhac'],
    [TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK, 'D/chinh HD sau thoi gian can nhac'],
    [TransactionType.PRODUCT_OPTION_SWITCHING, 'Thay doi quyen loi bao hiem'],
    [TransactionType.REDATING, 'Khoi phuc HD - Doi ngay hieu luc'],
    [TransactionType.RIDER_ALTERATION, 'Dieu chinh san pham bo tro'],
    [TransactionType.RIDER_REINSTATEMENT, 'Khoi phuc hieu luc san pham bo tro'],
    [TransactionType.MAJOR_COMBINED_CHANGE, 'Dieu chinh hop dong ket hop'],
    [TransactionType.CHANGE_NATIONAL_ID_CARD, 'Dieu chinh thong tin CCCD'],
    [TransactionType.CHANGE_OCCUPATION_PERSONAL, 'Dieu chinh nghe nghiep'],
    [TransactionType.CHANGE_DOB_GENDER, 'Dieu chinh ngay sinh va gioi tinh'],
    [TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL, 'Dieu chinh thong tin khac']
  ])

  export enum RequestAuthOption {
    OTP = 'OTP',
    SIGNATURE = 'SIGN_ON_AP',
    PAPER = 'ATTACH_REQUEST_FORM',
    AUTHENTICATION_PHONE = 'AUTHENTICATION_PHONE',
    OTP_AND_ATTACH_REQUEST_FORM = 'OTP_AND_ATTACH_REQUEST_FORM',
    OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM = 'OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM'
  }

  export enum OTPOption {
    ETOKEN = 'ETOKEN',
    ZALO = 'ZALO',
    PHONE = 'PHONE'
  }

  export interface RequestAuthFormValue {
    authOption: RequestAuthOption
    otp: {
      otpOption: OTPOption
      otpInput?: number
      otpConsent: boolean
      optConsent1: boolean
      authentication_option?: boolean
      authentication_data?: FileUploadData[]
    }
    paper: {
      data: FileUploadData[]
      consent: boolean
    }
  }

  export interface EformRequestAuthenticateData {
    transaction: TransactionType | null
    clientName: string
    transactionType: string
    transactionName: string
    policyNum: string
    collerationId: string
    phoneData: {
      phoneCode: string
      phoneNum: string
    }
    otpRegistered: boolean
    sendDate: string
    docReviewed: boolean
    metaData: StorageBlob.MetaDataUpload | undefined
    docTypeCode: string
    isCCE: boolean,
    isHideRequestInfo: boolean
    isHideOTP: boolean,
    mainPhoneNumber: boolean
  }

  export const DefaultRequestAuthenticateData = {
    transaction: null,
    clientName: '',
    transactionType: 'PS',
    transactionName: '',
    collerationId: '',
    policyNum: '',
    phoneData: {
      phoneCode: '',
      phoneNum: ''
    },
    docReviewed: false,
    otpRegistered: false,
    sendDate: '',
    metaData: undefined,
    docTypeCode: '',
    isCCE: false,
    isHideRequestInfo: false,
    isHideOTP: false,
    mainPhoneNumber: false
  }

  export const OptionFlag = {
    ATTACH_REQUEST_FORM: '1',
    SIGN_ON_AP: '2',
    OTP: ''
  }
}
