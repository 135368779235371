import * as React from 'react'
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'

interface Props {
  disable?: boolean
  active: boolean
  ActiveIcon: JSX.Element
  InactiveIcon: JSX.Element
  size?: number
  containerStyle?: ViewStyle
  onChanged?: (active: boolean) => void
}

export const IconSwitch = ({
  ActiveIcon,
  InactiveIcon,
  active,
  disable = false,
  size = 24,
  containerStyle = {},
  onChanged
}: Props) => {
  return (
    <TouchableOpacity
      disabled={disable}
      activeOpacity={1}
      style={[styles.container, containerStyle, { width: size, height: size }]}
      onPress={() => onChanged && onChanged(!active)}
    >
      {active ? ActiveIcon : InactiveIcon}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  }
})
