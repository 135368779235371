import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace TopUp {
  export interface Detail {
    policyNum: string
    suspendAmount: number
    minTopUp: number
    maxTopUp: number
    api: number
    coverageCode: string
    ULPsuspendAmount: number
    totalPremium: number
    policyExtraInfoFund: PolicyExtraInfoFund
    OGmaxTopUp: number
  }

  export interface AccessData {
    coverageCode: string
    contractStatus: string
    premiumStatus: string
    contractType: string
    totalPaidBasicAmount: number
    api: number
    paidToDateAdvance: string
    // requestDate: string
  }

  export interface SubmitData {
    totalTopupAmount: string
    topupFunds: Array<TopupFunds>
    option: string
    suspenseAmount: number
  }

  export interface SubmitULPData {
    policyNum: string
    premium: number
    percentOrAmount: string
    amount1: string
    fund1: string
  }

  export interface TopupFunds {
    fundCode: string
    ratio: string
    topupAmount: string
  }

  export interface TopUpAccountBalance {
    amount: number
    sacsCode: string
    sacsType: string
  }

  export interface PolicyExtraInfo {
    policyExtraInfoDetail: PolicyExtraInfoDetail[]
    policyExtraInfoFund: PolicyExtraInfoFund[]
  }

  export interface PolicyExtraInfoFund {
    fundCode: string
    unitBalance: number
    price: number
    priceDate: string
    policyNum: string
    estimateValue: number
  }

  export interface PolicyExtraInfoDetail {
    productCode: string
    lifeNo: string
    coverageNo: string
    riderNo: string
    benefitOption: string
    mortClass: string
    policyNum: string
    sumAssured: number
    installmentPremium: number
    totalPaidBasicYearly: number
    riskCommDate: string
    premCessDate: string
    riskCessDate: string
    riderPremiumStatus: string
    riderStatus: string
    dealingDate: string
    rerateDate: string
    debtAmount: number
    targetPremium: number
    minTopUp: number
    maxTopUp: number
  }

  export interface ResponseStatus {
    code: number
    message: string
    errors: {
      code: string
      type: string
      message: string
    }
  }

  export const PolicyExtraInfoDetails = t.array(
    t.type({
      policyNum: t.string,
      productCode: t.string,
      lifeNo: t.string,
      coverageNo: t.string,
      riderNo: t.string,
      benefitOption: t.string,
      mortClass: t.string,
      sumAssured: t.number,
      installmentPremium: t.number,
      totalPaidBasicYearly: t.number,
      riskCommDate: t.string,
      premCessDate: t.string,
      riskCessDate: t.string,
      riderPremiumStatus: t.string,
      riderStatus: t.string,
      dealingDate: t.string,
      rerateDate: t.string,
      debtAmount: t.number,
      targetPremium: t.number,
      minTopUp: t.number,
      maxTopUp: t.number
    })
  )

  export type PolicyExtraInfoDetails = t.TypeOf<typeof PolicyExtraInfoDetails>

  export const policyExtraInfoFunds = Maybe(
    t.array(
      t.type({
        fundCode: t.string,
        unitBalance: t.number,
        price: t.number,
        priceDate: t.string,
        policyNum: t.string,
        estimateValue: t.number
      })
    )
  )
  export type policyExtraInfoFunds = t.TypeOf<typeof policyExtraInfoFunds>

  export const TopupErrorResponse = t.union([
    t.type({
      errorCode: t.union([t.string, t.null]),
      errorDescription: t.union([t.string, t.null]),
      oErrorList: t.union([
        t.array(
          t.type({
            errorField: t.string,
            errorFieldPrefix: t.union([t.string, t.null])
          })
        ),
        t.null
      ])
    }),
    t.null
  ])

  export type TopupErrorResponse = t.TypeOf<typeof TopupErrorResponse>

  export const SubmitTopupResponse = t.type({
    body: t.null,
    oErrorResult: TopupErrorResponse,
    status: t.union([t.string, t.null])
  })
  export type SubmitTopupResponse = t.TypeOf<typeof SubmitTopupResponse>
}
