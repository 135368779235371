import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import { SubmissionAgentService } from '../common'

export namespace AgentTerminationForm {
  export const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  export type FileListBrand = {
    readonly FileList: unique symbol
  }

  export type FileList = t.Branded<File[], FileListBrand>

  export const withFileRequired = () =>
    pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l.length > 0,
        (l) => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
        'FileList'
      )
    )

  type AgentCodeBrand = {
    readonly AgentCode: unique symbol
  }

  type AgentCode = t.Branded<NonEmptyString, AgentCodeBrand>

  export const AgentCode = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('requestInfo:AgentCode') })),
    form2.refine(
      (l): l is AgentCode => /\b\d{8}\b/.test(l),
      (l) => i18next.t('message:MS100005'),
      'AgentCode'
    ),
    form2.refine(
      (l): l is AgentCode => l.startsWith('6'),
      (l) => i18next.t('message:MS050013', { field: i18next.t('utilities:AgentCode') }),
      'AgentCode'
    )
  )

  export const codec = t.type({
    AgentCode: AgentCode,
    TerminationReason: form2.selectOption.optional,
    dataPayload: SubmissionAgentService.AgentDetail,
    agentContent: withFileRequired()
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
