import { ActivityIndicator, StyleSheet, Text, useWindowDimensions, View } from 'react-native'
import React from 'react'
import { IBPolicyPermission, ModalAction, ModalComponent, TabList } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { ClientScreenContext, IBButton, IBGeneralField, IBGeneralTable, TypeInputComponent } from '../../common'
import { IBCustomerBehaviorForm } from './IBCustomerBehaviorForm'
import { useForm } from 'react-hook-form'
import { A15CharacterData, CustomerBehaviorData, IBService } from '../../ib-service'
import { isEmpty } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
interface Props {
  visible: boolean
  onClose: (visible: boolean) => void
}

const ModalConfirm = (
  props: Props & {
    onConfirm: () => void
  }
) => {
  const { visible, onClose, onConfirm } = props
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation('Inbound')
  const modalActions: ModalAction[] = [
    {
      text: t('NoConfirm'),
      type: 'outline',
      action: () => onClose(!visible),
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    },
    {
      text: t('Yes'),
      type: 'filled',
      action: () => onConfirm(),
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]
  return (
    <ModalComponent
      title={''}
      modalWidth={Math.min(width * 0.9, 500)}
      modalHeight={Math.min(height * 0.4, 180)}
      visible={visible}
      onClose={() => onClose(!visible)}
      actions={modalActions}
    >
      <View style={{ paddingTop: 20 }}>
        <Text style={styles.textConfirm}>{t('UpdateBehaviorConfirmation')}</Text>
      </View>
    </ModalComponent>
  )
}

const IBModalCustomerBehavior = ({ visible, onClose }: Props) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const clientNumber = urlParams.get('clientNumber')
  const { t, i18n } = useTranslation('Inbound')
  const { width, height } = useWindowDimensions()
  const [tabIndex, setTabIndex] = React.useState<number>(1)
  const [characters, setCharacters] = React.useState<A15CharacterData[]>([])
  const [historys, setHistorys] = React.useState<CustomerBehaviorData[]>([])
  const { lastestCustomerBehavior, setLastestCustomerBehavior, permissions } = React.useContext(ClientScreenContext)
  const [loading, bindLoading] = useLoading()
  const [visibleConfirm, setVisibleConfirm] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)

  const base = useForm<IBCustomerBehaviorForm>({
    mode: 'onChange',
    defaultValues: {
      behavior: lastestCustomerBehavior?.behavior || '',
      character: { label: '', value: '' }
    }
  })

  const tabs = [
    { id: 1, title: t('UpdateCustomerBehavior') },
    { id: 2, title: t('HistoryUpdateCustomerBehavior') }
  ]

  const dataTable = [
    { label: t('No'), field: 'no' },
    {
      label: t('CustomerCharacter'),
      field: 'characterCode',
      render: (value: string) => {
        const objCharacter = characters.find((x) => x.code === value)
        return <Text>{i18n.language === 'vi' ? objCharacter?.nameVi || '' : objCharacter?.nameEn || ''}</Text>
      }
    },
    { label: t('CustomerBehavior'), field: 'behavior' },
    { label: t('Source'), field: 'source' },
    { label: t('CreatedDate'), field: 'createdDate', format: 'dateTime' },
    { label: t('CreatedBy'), field: 'createdBy' }
  ]

  React.useEffect(() => {
    if (isEmpty(characters)) {
      pipe(
        IBService.getA15Character(),
        ZIO.map((res) => {
          setCharacters(res.data)
          const objCharacter = res.data.find((x) => x.code === lastestCustomerBehavior?.characterCode)
          base.setValue('character', {
            label: i18n.language === 'vi' ? objCharacter?.nameVi || '' : objCharacter?.nameEn || '',
            value: objCharacter?.code || ''
          })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  const handleSearch = (page: number, pageSize: number) => {
    pipe(
      IBService.getLastestHistoryCustomerBehavior({
        clientNumber: clientNumber as string,
        pageIndex: page,
        itemsPerPage: pageSize
      }),
      ZIO.map((res) => {
        setHistorys(
          res.records.map((x, index) => {
            return {
              ...x,
              no: index + 1,
              createdDate: moment(x.createdDate).add(7, 'hours').format('YYYYMMDDHHmmss'),
              createdBy: x.createdBy?.split('@')[0]
            }
          })
        )
        setTaskCount(res.paging.total ?? 0)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    if (tabIndex === 2) {
      handleSearch(0, 10)
    }
  }, [tabIndex])

  React.useEffect(() => {
    if (!isEmpty(historys)) handleSearch(page, pageSize)
  }, [page, pageSize])

  const onChangeTab = (i: number) => {
    setTabIndex(i)
  }

  const onUpdate = base.handleSubmit((value) => {
    setVisibleConfirm(false)
    pipe(
      IBService.updateCustomerBehavior({
        clientNumber: clientNumber,
        behavior: value.behavior,
        characterCode: permissions.includes(IBPolicyPermission.EditIBCharacterCustomerBehaviorPopup)
          ? value.character.value
          : null,
        source: 'INBOUND',
        businessKey: '',
        policyNumber: '',
        createdBy: undefined,
        createdDate: undefined,
        characterNameVi: undefined,
        characterNameEn: undefined
      }),
      ZIO.map((res) => {
        const objCharacter = characters.find((x) => x.code === res.data.characterCode)
        if (setLastestCustomerBehavior)
          setLastestCustomerBehavior({
            ...res.data,
            characterNameVi: objCharacter?.nameVi,
            characterNameEn: objCharacter?.nameEn
          })
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  return (
    <ModalComponent
      title={t('CustomerBehavior').toUpperCase()}
      titleStyle={{ color: '#000', fontSize: 16, fontWeight: '700' }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 10 }}
    >
      <View
        style={{
          marginHorizontal: 20,
          borderWidth: 1,
          borderColor: '#D3DCE6',
          borderRadius: 10
        }}
      >
        <View
          style={{
            paddingHorizontal: 10,
            paddingTop: 10,
            marginBottom: 10,
            backgroundColor: '#F4F4F4',
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10
          }}
        >
          <TabList menus={tabs} isSubTab={false} useOriginalId={true} tabIndex={tabIndex} onChangeTab={onChangeTab} />
        </View>
        {tabIndex === 1 ? (
          <View style={{ paddingHorizontal: 20 }}>
            <IBGeneralField
              FieldForm={base}
              col={1}
              typeInput={[
                {
                  type: TypeInputComponent.SELECT,
                  formName: 'character',
                  title: t('CustomerCharacter'),
                  option:
                    characters.map((x) => ({
                      label: i18n.language === 'vi' ? x.nameVi || '' : x.nameEn || '',
                      value: x.code || ''
                    })) || [],
                  disabled: !permissions.includes(IBPolicyPermission.EditIBCharacterCustomerBehaviorPopup),
                  labelStyle: {
                    color: !permissions.includes(IBPolicyPermission.EditIBCharacterCustomerBehaviorPopup)
                      ? '#a4a6a5'
                      : '#70777E'
                  }
                },
                {
                  type: TypeInputComponent.INPUT,
                  formName: 'behavior',
                  title: t('CustomerBehavior'),
                  inputType: 'input',
                  maxLength: 120,
                  disabled: !permissions.includes(IBPolicyPermission.EditIBBehaviorCustomerBehaviorPopup)
                }
              ]}
            />
            <View style={{ marginVertical: 15, alignSelf: 'flex-end' }}>
              <View style={{ flexDirection: 'row' }}>
                <IBButton
                  title={t('Close')}
                  onPress={() => onClose(!visible)}
                  backgroundFill={false}
                  containerStyle={{ marginRight: 10 }}
                />
                <IBButton
                  title={t('Update')}
                  onPress={() => {
                    setVisibleConfirm(true)
                  }}
                  backgroundFill={true}
                  disabled={
                    (base.watch().behavior === lastestCustomerBehavior?.behavior &&
                      base.watch().character?.value === lastestCustomerBehavior?.characterCode) ||
                    !permissions.includes(IBPolicyPermission.UpdateIBCustomerBehaviorPopup)
                  }
                />
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
        {tabIndex === 2 ? (
          <View style={{ paddingHorizontal: 20, marginBottom: 10 }}>
            <IBGeneralTable
              data={historys || []}
              dataTable={dataTable}
              maxHeight={300}
              paging={{ page, pageSize, setPage, setPageSize, taskCount }}
            />
          </View>
        ) : (
          <></>
        )}
        {loading ? (
          <View style={styles.fab}>
            <ActivityIndicator size="large" color="red" />
          </View>
        ) : (
          <></>
        )}
      </View>
      {visibleConfirm && <ModalConfirm onConfirm={onUpdate} onClose={setVisibleConfirm} visible={visibleConfirm} />}
    </ModalComponent>
  )
}

export default IBModalCustomerBehavior

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  },
  btnConfirm: {
    height: 40,
    marginHorizontal: 5
  },
  textConfirm: {
    fontWeight: 'bold',
    fontSize: 20,
    alignSelf: 'center'
  }
})
