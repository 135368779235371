import { StyleSheet, ActivityIndicator, View, Text, ViewStyle, StyleProp } from 'react-native'
import {
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination
} from '@material-ui/core'
import { TableHeaderSort, PulseOpsFormat, InputTable } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

interface Props {
  dataTable: {
    label: string | JSX.Element
    field: string
    isRequired?: boolean
    format?: string
    disabled?: boolean
    visible?: boolean
    render?: (val: string, index: number, item: any) => JSX.Element | string
    minWidthCell?: { lengthSet: number; minWidth: number }
    hideFilterCol?: boolean
    widthColumn?: string | number
    draggable?: boolean
  }[]
  data: Array<Object & { isHighlight?: boolean; color?: string }>
  dataTotalCell?: Object
  loading?: boolean
  totalCell?: boolean
  colSpanTotalCell?: number
  paging?: {
    page: number
    pageSize: number
    taskCount: number
    setPage: (page: number) => void
    setPageSize: (size: number) => void
  }
  autoPaging?: boolean
  paginationStyle?: StyleProp<ViewStyle>
  minWidth?: number | string
  maxWidth?: number | string
  wrap?: boolean
  maxHeight?: number
  maxWidthContainer?: boolean
  filterColumn?: {
    [key in string]: string
  }
  setDataDraggable?: (data: any[]) => void
}

export const IBGeneralTable = (props: Props) => {
  const { t } = useTranslation()
  const {
    dataTable,
    data = [],
    loading,
    paging,
    totalCell = false,
    dataTotalCell,
    colSpanTotalCell = 3,
    autoPaging = false,
    paginationStyle,
    minWidth,
    maxWidth,
    wrap = false,
    maxHeight = 500,
    maxWidthContainer,
    filterColumn,
    setDataDraggable
  } = props

  const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#E5EAEF'
      // color: '#58647A'
    },
    tableCell: {
      fontSize: 15,
      ...(!wrap && { whiteSpace: 'nowrap' })
    },
    cellNoWrap: {
      whiteSpace: 'nowrap'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    }
  })

  const classes = useStyles()
  const [dataList, setDataList] = React.useState<any[]>([])
  const [sortItem, setSortItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })

  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)
  const [dataPage, setDataPage] = React.useState<any[]>([])
  const [filterColumnList, setFilterColumnList] = React.useState(filterColumn)

  const handlePaging = (data: any[]) => {
    setDataPage(data.slice(pageSize * page, (1 + page) * pageSize))
  }

  React.useEffect(() => {
    setDataList(data)
  }, [data])

  React.useEffect(() => {
    setSortItem({ ...sortItem, field: '' })
    if (autoPaging) {
      if (!_.isEmpty(dataList)) handlePaging(dataList)
    }
  }, [page, pageSize])

  React.useEffect(() => {
    if (autoPaging && dataList) {
      if (page !== 0 && pageSize !== 10) {
        setPage(0)
        setPageSize(10)
      } else {
        handlePaging(dataList)
      }
      setTaskCount(dataList.length)
    }
  }, [dataList])

  const onChangePageSize = (size: number) => {
    if (autoPaging) {
      setPageSize(size)
      setPage(0)
    } else {
      paging?.setPageSize(size)
      paging?.setPage(0)
    }
  }

  React.useEffect(() => {
    if (filterColumn && filterColumnList) {
      let dataShow = data as any[]
      const keyFilter = Object.keys(filterColumnList)
      keyFilter.map((fieldSearchColumn: string) => {
        dataShow = !!filterColumnList[`${fieldSearchColumn}`] ? dataShow.filter((i) => like(i[`${fieldSearchColumn}`], filterColumnList[`${fieldSearchColumn}`])) : dataShow
      })
      if (autoPaging) {
        setPageSize(10)
        setPage(0)
      }
      setDataList(dataShow)
    }
  }, [filterColumnList, data])

  const like = (a: string | null | undefined, b: string) => (a ? a.includes(b.trim()) : false)

  const [draggingRow, setDraggingRow] = React.useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggingRow(index);
  };

  const handleDragEnter = (index: number, field: string) => {
    if (field !== "" && index !== draggingRow) {
      const newData = autoPaging ? [...dataPage] : [...dataList];
      const [movedItem] = newData.splice(draggingRow as number, 1);
      newData.splice(index, 0, movedItem);
      setDraggingRow(index);
      autoPaging ? setDataPage(newData.map((x, i) => ({ ...x, [field]: i + 1 }))) : setDataList(newData.map((x, i) => ({ ...x, [field]: i + 1 })));
    }
  };

  const handleDragEnd = () => {
    setDraggingRow(null);
    setDataDraggable && setDataDraggable(autoPaging ? dataPage : dataList)
  };

  return (
    <View style={{ ...maxWidthContainer && { minWidth: '100%' } }}>
      <TableContainer
        component={Paper}
        style={{
          maxHeight: maxHeight,
          ...(minWidth && { minWidth: minWidth }),
          ...(maxWidth && { maxWidth: maxWidth })
        }}
        elevation={1}
      >
        <Table style={{ userSelect: 'text' }} stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableHeaderSort
              data={autoPaging ? dataPage : dataList}
              setData={autoPaging ? setDataPage : setDataList}
              sortItem={sortItem}
              setSortItem={setSortItem}
              column={dataTable}
              sortVNI={true}
              isFilterColumn={!!filterColumn}
            />
          </TableHead>
          {filterColumnList &&
            <TableRow style={{ backgroundColor: '#F4F4F4', position: 'sticky', zIndex: 2, top: 56, left: 0 }}>
              {dataTable.map(({ field, hideFilterCol }) => {
                const fieldsColumn = Object.keys(filterColumnList)
                const checkDataColumn = fieldsColumn.find((i) => i === field && !hideFilterCol)
                return (
                  filterColumnList && filterColumn && checkDataColumn ?
                    <TableCell style={{ paddingTop: 0 }}>
                      <InputTable onChange={(val) => {
                        const dataShow = filterColumnList
                        dataShow[`${field}`] = val
                        setFilterColumnList({ ...dataShow })
                      }}
                        placeholder={t("common:Search")}></InputTable>
                    </TableCell> :
                    <TableCell></TableCell>
                )
              })}
            </TableRow>
          }
          <TableBody className={classes.tableBody}>
            {loading ? (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator size="large" color="red" />
                  </View>
                </TableCell>
              </TableRow>
            ) : _.isEmpty(autoPaging ? dataPage : dataList) ? (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  {t('common:Nodata')}
                </TableCell>
              </TableRow>
            ) : (
              (autoPaging ? dataPage : dataList).map((item, index) => {
                return (
                  <TableRow key={index} style={index === draggingRow ? { backgroundColor: "#d4d2d2" } : item.isHighlight && item.color ? { backgroundColor: item.color } : {}}>
                    {dataTable.map(({ field, render, format, visible, minWidthCell, draggable }) => {
                      const setMinWidth =
                        minWidthCell && item[field]?.length > minWidthCell?.lengthSet
                          ? { minWidth: minWidthCell.minWidth }
                          : undefined
                      if (!visible && !_.isUndefined(visible)) return <></>
                      return (
                        <TableCell className={classes.tableCell} style={{ ...setMinWidth, cursor: draggable ? "move" : undefined }}
                          draggable={draggable}
                          onDragStart={() => handleDragStart(index)}
                          onDragEnter={() => handleDragEnter(index, draggable ? field : "")}
                          onDragEnd={handleDragEnd}>
                          {
                            //@ts-ignore
                            render
                              ? render(item[field], index, item)
                              : format === 'money'
                                ? PulseOpsFormat.thousandSepartor(item[field])
                                : format === 'date'
                                  ? moment(item[field]).isValid()
                                    ? moment(item[field]).format('DD/MM/YYYY')
                                    : ''
                                  : format === 'dateTime'
                                    ? moment(item[field], 'YYYYMMDDHHmmss').isValid()
                                      ? moment(item[field], 'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm:ss')
                                      : ''
                                    : format === 'time'
                                      ? moment(item[field], 'HHmmss').isValid()
                                        ? moment(item[field], 'HHmmss').format('HH:mm:ss')
                                        : '-'
                                      : item[field]
                          }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            )}
            {(autoPaging ? dataPage : dataList).length > 0 && totalCell && (
              <TableRow key={(autoPaging ? dataPage : dataList).length}>
                <TableCell style={{ backgroundColor: '#F4F4F4' }} align="center" colSpan={colSpanTotalCell}>
                  <Text style={{ fontWeight: '700', fontSize: 16, color: '#000000' }}>{t('Inbound:Total')}</Text>
                </TableCell>
                {dataTable.map(({ field }) => {
                  //@ts-ignore
                  if (!_.isUndefined(dataTotalCell[field])) {
                    return (
                      <TableCell className={classes.tableCell}>
                        {
                          //@ts-ignore
                          dataTotalCell[field]
                        }
                      </TableCell>
                    )
                  }
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(paging && !autoPaging) || (!paging && autoPaging) ? (
        //@ts-ignore
        <TablePagination
          page={autoPaging ? page : paging?.page}
          rowsPerPage={autoPaging ? pageSize : paging?.pageSize}
          count={autoPaging ? taskCount : paging?.taskCount}
          onPageChange={(e, page) => {
            if (autoPaging) {
              setPage(page)
            } else {
              paging?.setPage(page)
            }
          }}
          onRowsPerPageChange={(e) => {
            onChangePageSize(Number(e.target.value))
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
          style={paginationStyle}
        />
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({})
