import { CashOut } from './CashOutOption'

export namespace FullSurrenderServiceModel {
  export interface Detail {
    cashDeposit: number
    suspenseAmount: number
    policyLoans: number
    policyDebt: number
    surrenderCharge: number
    totalSurrenderAmount: number
  }

  export interface SubmitData {
    policy: {
      policyNo: string
      owners: {
        dateOfDeath: string //yyyyMMdd
        attributesExt: {
          HAS_INHERITANCE: boolean
        }
      }
    }
    attributesExt: {
      TOTAL_SURRENDER_AMOUNT: number
      SUSPENSE_AMOUNT: number
      POLICY_LOAN: number
      CASH_DEPOSIT: number
      POLICY_DEBT: number
      SURRENDER_CHARGE: number
    }
    // cashOutOptions: Array<CashOutOptions>
    cashOutOptions: Array<CashOut.Option>
  }

  // export interface CashOutOptions {
  //   type: string
  //   paymentOption: PaymentOption
  //   bankAccount?: BankAccount
  //   policy: Policy
  // }
  // export interface PaymentOption {
  //   currency: string
  //   amount: number
  // }

  // export interface Policy {
  //   policyNo: string
  //   totalPremium: number
  //   status: string
  // }

  // export interface BankAccount {
  //   accountName: string
  //   accountNo: string
  //   bankName: string
  //   branchCode: string
  // }
}
