import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { SourceType } from '../SourceType'
import { TransactionType } from '../TransactionType'
import { PaymentData } from './PaymentData'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      // transactionType: t.literal(TransactionType.SPECIAL_BONUS)
      transactionType: t.union([
        t.literal(TransactionType.SPECIAL_BONUS),
        t.literal(TransactionType.SPECIAL_BONUS_CUSTOMER)
      ])
    }),
    codec
  ])

const payload = t.type({
  body: Maybe(
    t.type({
      cashOutOptions: Maybe(
        t.array(
          t.union([
            PaymentData.BankTransfer,
            PaymentData.EWalletMomo,
            PaymentData.PayPremium,
            PaymentData.Premium,
            PaymentData.PayLoan,
            PaymentData.TopUp,
            PaymentData.Reinstatement,
            PaymentData.NoType,
            PaymentData.RepayOpl,
            PaymentData.RepayApl,
            PaymentData.Other,
            PaymentData.Reins,
            PaymentData.PaidAtBank,
            PaymentData.CashAtCounter
          ])
        )
      ),
      attributesExt: t.type({
        SPECIAL_BONUS: Maybe(t.number),
        TOTAL_PAYOUT_AMOUNT: Maybe(t.number)
      }),
      policy: Maybe(
        t.type({
          policyNo: t.string
        })
      )
    })
  )
})

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload
  })
)

const LandingPage = Base(
  t.type({
    source: t.literal(SourceType.LANDING_PAGE),
    payload
  })
)

export const CashOutSpecialBonus = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, PruGreat, PruOnline, LandingPage])
export type CashOutSpecialBonus = t.TypeOf<typeof CashOutSpecialBonus>
