import { StyleSheet, Text, View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native'
import { assets } from '@pulseops/common'
import _ from 'lodash'
import React from 'react'
import { Suspense } from 'react'

interface Props {
  title: string
  children?: React.ReactNode
  autoToggleKey?: string | null
  setLayoutY?: (y: number) => void
  noContainer?: boolean
  noUpperTitle?: boolean
  styleContainer?: StyleProp<ViewStyle>
  intialToggle?: boolean
  close?: boolean
}

export const IBPanel = (props: Props) => {
  const { title, children, autoToggleKey, setLayoutY } = props
  const [toggle, setToggle] = React.useState<boolean>(props.intialToggle ?? false)
  const viewRef = React.useRef<View | null>(null)

  React.useEffect(() => {
    if (title === autoToggleKey && setLayoutY) {
      viewRef.current?.measure((fx, fy) => {
        setLayoutY(fy)
      })
      setToggle(true)
    }
  }, [autoToggleKey])

  React.useEffect(() => {
    if (props.close) {
      setToggle(false)
    }
  }, [props.close])

  return (
    <View ref={viewRef} key={title} style={props.noContainer ? props.styleContainer : [styles.container, props.styleContainer]}>
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={() => setToggle(!toggle)}
          style={{ marginRight: 20 }}
          disabled={_.isUndefined(children)}
        >
          {toggle ? <assets.IBCollapseIcon /> : <assets.IBExpandIcon />}
        </TouchableOpacity>
        <Text style={styles.titleText}>{props.noUpperTitle ? title : title.toUpperCase()}</Text>
      </View>
      {toggle ? (
        <View style={{ marginTop: 15 }}>
          <Suspense fallback={<>Loading...</>}>{children}</Suspense>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#D3DCE6',
    padding: 20,
    marginVertical: 10
  },
  titleText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#1C1D1F'
  }
})
