import * as React from 'react'
import { View, Text, TextInput, StyleSheet, TouchableOpacity } from 'react-native'
import { ControlProps } from '@pulseops/common'

type Props = ControlProps<string> & {
  title?: string
  disabled?: boolean
  disabledSearch?: boolean
  required?: boolean
  errorMessage?: string
  icon?: JSX.Element
  numberOnly?: boolean
  onIconPressed?: () => void
  placeholder?: string
  maxLength?: number
}

// function debounce (fn: (...args: any) => void, time: number) {
//   let timeoutId: number | null = 0
//   return (...args: any) => {
//     if (timeoutId) {
//       clearTimeout(timeoutId)
//     }
//     timeoutId = setTimeout(() => {
//       timeoutId = null
//       fn(...args)
//     }, time)
//   }
// }

export const PolicyInput = (props: Props) => {
  const {
    title,
    onChange,
    onBlur,
    required,
    errorMessage,
    disabled,
    icon,
    onIconPressed,
    numberOnly,
    value,
    placeholder,
    maxLength,
    disabledSearch
  } = props

  return (
    <View>
      {title && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold', color: '#70777E' }}>{props.title}</Text>
          {required && <Text style={{ color: 'red', marginLeft: 5 }}>*</Text>}
        </View>
      )}
      <View style={[icon ? styles(props).inputWithIconContainer : {}]}>
        {/*{...props}*/}
        <TextInput
          style={[
            icon ? styles(props).inputWithIcon : styles(props).input,
            {
              shadowOffset: { width: 0, height: 20 },
              shadowOpacity: 0.05,
              shadowRadius: 16,
              borderWidth: 1,
              borderColor: errorMessage ? 'red' : '#808080',
              borderRadius: 8
            }
          ]}
          placeholder={placeholder}
          maxLength={maxLength}
          onChangeText={(t) => onChange && onChange(numberOnly ? t.replace(/[^0-9]/g, '') : t)}
          editable={!(disabled === true || disabledSearch === true)}
          value={value}
          onBlur={onBlur}
          onSubmitEditing={onIconPressed}
          // autoFocus
          // onFocus={() => {
          //   console.log('focused');
          // }}
        />
        {icon && disabledSearch !== true && (
          <TouchableOpacity style={styles(props).iconStyle} onPress={onIconPressed}>
            {icon}
          </TouchableOpacity>
        )}
      </View>
      {errorMessage ? <Text style={{ color: 'red', fontSize: 12, marginTop: 10 }}>{errorMessage}</Text> : null}
    </View>
  )
}

const styles = ({ errorMessage, disabled }: Props) =>
  StyleSheet.create({
    inputWithIconContainer: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    input: {
      borderBottomColor: errorMessage ? 'red' : 'black',
      borderBottomWidth: disabled ? 0 : 1,
      height: 35
    },
    inputWithIcon: {
      flex: 1,
      paddingTop: 10,
      paddingRight: 40,
      paddingBottom: 10,
      paddingLeft: 10,
      backgroundColor: '#fff',
      color: '#424242',
      borderColor: errorMessage ? 'red' : 'black',
      borderWidth: errorMessage ? 1 : undefined,
      borderRadius: 8,
      fontSize: 15,
      height: 46
    },
    iconStyle: {
      position: 'absolute',
      right: 10
    }
  })
