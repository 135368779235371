import React, { useState } from 'react'
import { View, useWindowDimensions, ScrollView } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { DIStackParamList } from './DIStackParamList'
import {
  AppContext,
  DigitalInquiryService,
  ErrorHandling,
  Permission,
  PolicyInfoHeader,
  PulseOpsFormat,
  RBAC,
  TabList
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyInfoData } from './common'
import { CustomerInfo, PolicyInfo } from './tabs'
import { useIsFocused } from '@react-navigation/native'

type Props = StackScreenProps<DIStackParamList, 'PolicyInquiryDetailScreen'>

export const PolicyInquiryDetailScreen = (props: Props) => {
  const { width } = useWindowDimensions()
  const { t } = useTranslation()
  const isFocused = useIsFocused()

  const menus = [
    {
      label: t('common:PolicyNumber'),
      value: props.route.params?.policyNum || '-'
    }
  ]

  const [policyInfo, setPolicyInfo] = useState<PolicyInfoData>()
  const [customerInfo, setcustomerInfo] = useState<
    {
      value: string
      label: string
      colLength?: string
    }[]
  >([])

  const [tabs, setTabs] = useState<
    {
      id: number
      title: string
    }[]
  >([])
  const [tabIndex, setTabIndex] = useState<number>(0)

  const onChangeTab = (i: number) => {
    setTabIndex(i)
  }

  pipe(
    ZIO.zipPar(DigitalInquiryService.getDetail(props.route.params.policyNum || '-'), RBAC.permissions),
    ZIO.tap(([x, roles]) => {
      setPolicyInfo({
        proposalNo: x.proposalNo || '-',
        productCode: x.productCode || '-',
        productName: x.productName || '-',
        planName: x.planName || '-',
        firstIssueDate: PulseOpsFormat.dateStrWF(x.firstIssueDate, 'DD/MM/yyyy'),
        inceptionDate: PulseOpsFormat.dateStrWF(x.inceptionDate, 'DD/MM/yyyy'),
        expiryDate: PulseOpsFormat.dateStrWF(x.expiryDate, 'DD/MM/yyyy'),
        coverageTotalPremium: x.coverageTotalPremium || '0',
        coverageTotalSumAssured: x.coverageTotalSumAssured || '0',
        status: x.status || '-',
        partnerSource: x.source || '-',
        referralCode: x.referralCode || '-',
        rider: x.riderInfos.map((y) => ({
          componentCode: y.componentCode || '-',
          componentName: y.componentName || '-',
          riderRCD: PulseOpsFormat.dateStrWF(y.riskCommencementDate, 'DD/MM/yyyy') || '-',
          riderRCssD: PulseOpsFormat.dateStrWF(y.riskCessDate, 'DD/MM/yyyy') || '-',
          totalSA: y.sumAssured ? PulseOpsFormat.thousandSepartor(Number(y.sumAssured)) : '-',
          status: y.status || '-'
        })),
        transactionId: x.transactionId || '-',
        encryptedActivationCode: x.encryptedActivationCode || '-',
        partnerOrderId: x.partnerOrderId || '-',
        buyerUserId: x.buyerUserId || '-',
        activatorUserId: x.activatorUserId || '-',
        costVoucher: x.costVoucher != null ? x.costVoucher.toString() : undefined,
        differentVoucherAmount: x.differentVoucherAmount != null ? x.differentVoucherAmount.toString() : '-',
        aPE: x.ape != null ? x.ape.toString() : '-'
      })
      const dob: string = x.customerInfo.dob ? PulseOpsFormat.dateStrWF(x.customerInfo.dob, 'DD/MM/yyyy') : '-'
      const customer = roles.includes(Permission['DI-View-CustomerInfo1'])
        ? [
            { label: t('contact:POName'), value: x.customerInfo.fullName || '-' },
            { label: t('contact:Gender'), value: x.customerInfo.gender || '-' },
            { label: t('contact:DOB'), value: dob },
            { label: t('contact:Email'), value: x.customerInfo.emailID || '-' },
            { label: t('contact:NationalID'), value: x.customerInfo.nationalID || '-' },
            { label: t('contact:MobilePhone'), value: x.customerInfo.phoneNum || '-' },
            { label: t('contact:Address'), value: x.customerInfo.address || '-', colLength: 'col-6' }
          ]
        : roles.includes(Permission['DI-View-CustomerInfo2'])
        ? [
            { label: t('contact:POName'), value: x.customerInfo.fullName || '-' },
            { label: t('contact:NationalID'), value: x.customerInfo.nationalID || '-' }
          ]
        : roles.includes(Permission['DI-View-CustomerInfo3'])
        ? [
            { label: t('contact:DOB'), value: dob },
            { label: t('contact:Address'), value: x.customerInfo.address || '-', colLength: 'col-6' }
          ]
        : roles.includes(Permission['DI-View-CustomerInfo4'])
        ? [
            { label: t('contact:Gender'), value: x.customerInfo.gender || '-' },
            { label: t('contact:DOB'), value: dob }
          ]
        : roles.includes(Permission['DI-View-CustomerInfo5'])
        ? [
            { label: t('contact:POName'), value: x.customerInfo.fullName || '-' },
            { label: t('contact:Gender'), value: x.customerInfo.gender || '-' },
            { label: t('contact:DOB'), value: dob },
            { label: t('contact:NationalID'), value: x.customerInfo.nationalID || '-' },
            { label: t('contact:Address'), value: x.customerInfo.address || '-', colLength: 'col-6' }
          ]
        : []
      setcustomerInfo(customer)
      const tabTemp = []
      if (roles.includes(Permission['DI-View-PolicyInfo']))
        tabTemp.push({
          id: 1,
          title: t('common:Policy')
        })
      if (customer.length > 0)
        tabTemp.push({
          id: 2,
          title: t('common:Customer')
        })
      setTabs(tabTemp)
      return ZIO.unit
    }),
    ErrorHandling.runDidUpdate([isFocused])
  )

  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        {
          title: t('menu:Digital'),
          navigate: () => props.navigation.navigate('DIDashboardScreen')
        },
        {
          title: t('menu:PolicyInquiry'),
          navigate: () => props.navigation.navigate('PolicyInquiryScreen')
        },
        { title: props.route.params.policyNum || '-', navigate: null }
      ])
    }
  }, [isFocused])

  return (
    <View
      style={{
        width: width >= 1440 ? 1440 : '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%'
      }}
    >
      {/* <BreadCrumb navigation={[
        { title: "", navigate: () => {props.navigation.replace('HomeScreen')} },
        { title: t('home:DigitalInquiry'), navigate: () => {props.navigation.goBack()}},
        { title: props.route.params.policyNum || '-', navigate: null}
      ]}></BreadCrumb> */}
      <PolicyInfoHeader menus={menus} />
      <View style={{ marginHorizontal: 15 }}>
        <TabList menus={tabs} isSubTab={false} tabIndex={tabIndex} onChangeTab={onChangeTab} />
      </View>
      <ScrollView style={{ padding: 10 }}>
        {tabIndex === 0 && policyInfo && <PolicyInfo data={policyInfo}></PolicyInfo>}
        {tabIndex === 1 && customerInfo.length > 0 && <CustomerInfo data={customerInfo}></CustomerInfo>}
      </ScrollView>
    </View>
  )
}
