import { AppContext, assets, ErrorHandling, PolicyInfoHeader, RBAC, subscribe, useTranslator } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Text } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { GeneralInfoScreen } from './cep-generalInfo'
import { InquiryStackParamList } from '../InquiryStackParamList'
import { CepStateInfo } from '../state'
import { ZIO } from '@mxt/zio'
import { isEmpty } from 'lodash'
import { getCustomerInfo } from '@pulseops/inquiry'

type Props = StackScreenProps<InquiryStackParamList, 'CEPPolicyDetailScreen'>

export const CEPPolicyDetailScreen = ({ navigation, route }: Props) => {
  const { t } = useTranslation()

  const roles: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  const [dataPO, setDataPO] = React.useState<any>({})
  const cep = useTranslator('CustomerEngagement').t
  const message = pipe(CepStateInfo.message.watch, subscribe({}))
  const [messageDOB, setMessageDOB] = React.useState<string | null>(null)
  const menus = [
    {
      label: t('common:PolicyNumber'),
      icon: <assets.ProfileIcon />,
      value: route.params?.policyNum
    },
    {
      label: t('common:PolicyOwner'),
      value: route.params?.policyOwner
    },
    {
      label: t('common:ClientNumber'),
      value: `${route.params?.clientNumber ?? ''}`
    }
  ]

  useEffect(() => {
    pipe(CepStateInfo.action.reset(), ZIO.unsafeRun({}))
  }, [])

  useEffect(() => {
    if (!isEmpty(route.params.policyNum)) {
      const getDateOfBirth = () =>
        pipe(
          getCustomerInfo(route.params.policyNum),
          ZIO.map((res) => {
            const dataPO = res.data?.policyOwners?.find((x) => x?.role === 'OW')
            setDataPO(dataPO)
          }),
          ErrorHandling.run({})
        )

      getDateOfBirth()
    }
  }, [route.params.policyNum])

  useEffect(() => {
    if (!isEmpty(dataPO)) {
      const DOB = dataPO?.dateOfBirth?.split('/').reverse().join('/')

      let dataBOD = calculateDOB(DOB)

      setMessageDOB(dataBOD)

      let messageDOB = {
        ...message,
        DOB: dataBOD,
        Anniversary: message?.Anniversary ?? '',
        FullyPaid: message?.FullyPaid ?? ''
      }

      const checkDOBPolicy = () => pipe(CepStateInfo.action.setMessage(messageDOB), ZIO.unsafeRun({}))

      checkDOBPolicy()
    }
  }, [dataPO])

  const calculateDOB = (dateString: string): string => {
    var birthday = new Date(dateString)
    var today = new Date()

    const DOB = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate(), 23, 59, 59)
    const lastedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
    const oneDay = 24 * 60 * 60 * 1000

    const diffDays = Math.round(Math.abs((DOB.valueOf() - lastedToday.valueOf()) / oneDay))

    if (DOB.valueOf() - lastedToday.valueOf() >= 0) {
      if (diffDays === 0) {
        return cep('messageDOB')
      } else if (diffDays > 0 && diffDays <= 15) {
        return cep('messageWithinDOB')
      }
    }

    return ''
  }

  const renderSlickMessage = React.useCallback(() => {
    return (
      <View>
        {!isEmpty(message.FullyPaid) ? (
          <View style={styles.headerTitle}>
            <Text
              style={{
                color: '#0abb04',
                fontWeight: '500',
                paddingHorizontal: 4,
                paddingVertical: 1,
                backgroundColor: '#a7fa8e75',
                borderRadius: 4
              }}
            >
              {message.FullyPaid}
            </Text>
          </View>
        ) : null}
        {!isEmpty(messageDOB) ? (
          <View style={styles.headerTitle}>
            <Text
              style={{
                color: '#e60000',
                fontWeight: '500',
                paddingHorizontal: 4,
                paddingVertical: 1,
                backgroundColor: '#f8979754',
                borderRadius: 4
              }}
            >
              {messageDOB}
            </Text>
          </View>
        ) : null}
        {!isEmpty(message.Anniversary) ? (
          <View style={styles.headerTitle}>
            <Text
              style={{
                color: '#06c',
                fontWeight: '500',
                paddingHorizontal: 4,
                paddingVertical: 1,
                backgroundColor: '#99d2f880',
                borderRadius: 4
              }}
            >
              {message.Anniversary}
            </Text>
          </View>
        ) : null}
      </View>
    )
  }, [message, messageDOB])

  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <PolicyInfoHeader menus={menus} isNoti={true} />
        {renderSlickMessage()}
        <View style={{ backgroundColor: '#FFF' }}>
          {roles && (
            <GeneralInfoScreen
              navigation={navigation}
              policyNum={route.params.policyNum}
              roles={roles}
              isGaStaff={Boolean(parseInt(route.params.isGAstaff))}
              messageState={message}
              clientNumber={route.params.clientNumber}
              proposalNo={route.params.proposalNo}
            ></GeneralInfoScreen>
          )}
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  headerTitle: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 14,
    marginBottom: 12,
    marginHorizontal: 15,
    flex: 1,
    flexDirection: 'row'
  }
})
