import { getPayOutDetail, getCoreDetail } from './common/payout'
import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { ClientPolicy } from '../Client'
import { PaymentType } from '../PaymentType'

export interface PartialWithdrawal {
  tag: TransactionType.PARTIAL_WITHDRAWAL
  clientName: string
  totalPayoutAmount: number
  ulp?: {
    estimatedValue: number
    minimumPartialWithdrawal: number
    maximumPartialWithdrawal: number
    currentSumAssured: number
    newSumAssured: number
    partialWithdrawFee: number
    withdrawAmount: number
  }
  ilp?: {
    estimatedValue: number
    minimumPartialWithdrawal: number
    maximumPartialWithdrawal: number
    partialWithdrawFee: number
    totalWithdrawAmount: number
    FUND_LIST: {
      FUND_CODE: string
      FUND_VALUE: number
      'MIN_P/W_REMAIN': number
      WITHDRAW_AMOUNT: number
    }[]
  }
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
  seaBankWarningMsg?: string
  sourceType: string
}

export const PartialWithdrawal = (
  detail: api.PartialWithdrawal & api.TaskDetailBase,
  poName: string,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string,
  seaBankWarningMsg?: string
): PartialWithdrawal => {
  const coreStatus = getCoreDetail(detail.coreResponseDetail, detail.payload.body.cashOutOptions || [])
  const { attributesExt } = detail.payload.body
  const {
    TOTAL_PAYOUT_AMOUNT,
    ESTIMATED_VALUE,
    MINIMUM_PARTIAL_WITHDRAWAL,
    MAXIMUM_PARTIAL_WITHDRAWAL,
    WITHDRAW_FEE,
    CURRENT_SUM_ASSURED,
    NEW_SUM_ASSURED,
    WITHDRAW_AMOUNT,
    TOTAL_WITHDRAW_AMOUNT,
    FUND_LIST
  } = attributesExt
  const common = {
    estimatedValue: ESTIMATED_VALUE,
    minimumPartialWithdrawal: MINIMUM_PARTIAL_WITHDRAWAL,
    maximumPartialWithdrawal: MAXIMUM_PARTIAL_WITHDRAWAL,
    partialWithdrawFee: WITHDRAW_FEE
  }
  let ulp, ilp
  if (!!FUND_LIST && FUND_LIST.length > 0) {
    ilp = <PartialWithdrawal['ilp']>{
      ...common,
      totalWithdrawAmount: TOTAL_WITHDRAW_AMOUNT,
      FUND_LIST
    }
  } else {
    ulp = <PartialWithdrawal['ulp']>{
      ...common,
      currentSumAssured: CURRENT_SUM_ASSURED,
      newSumAssured: NEW_SUM_ASSURED,
      withdrawAmount: WITHDRAW_AMOUNT
    }
  }
  const cashOutList =
    detail.payload && detail.payload.body
      ? detail.payload.body.cashOutOptions?.filter(
        (x) =>
          PaymentType.BANKTRANSFER === x.type ||
          PaymentType.PAID_AT_BANK === x.type ||
          PaymentType.CASH_AT_COUNTER === x.type
      )
      : []
  return {
    tag: TransactionType.PARTIAL_WITHDRAWAL,
    clientName: poName ?? '',
    totalPayoutAmount: TOTAL_PAYOUT_AMOUNT,
    ulp,
    ilp,
    cashOutList: cashOutList,
    branchName: branchName,
    seaBankWarningMsg: seaBankWarningMsg,
    sourceType: detail.source,
    methods:
      detail.payload.body.cashOutOptions?.map((v, i) => ({
        method: v.type ?? '',
        detail: getPayOutDetail(
          {
            type: v.type as PaymentType,
            officeCode:
              (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) || '',

            bankAccount: {
              accountName:
                (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                (v.type === PaymentType.PAID_AT_BANK &&
                  (v.attributesExt?.PAYEE_INFO?.payeeName === '' ? poName : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                (v.type === PaymentType.CASH_AT_COUNTER &&
                  (v.attributesExt?.PAYEE_INFO?.payeeName === '' ? poName : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                '-',
              accountNo:
                (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                  detail.clientNumber) ||
                ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                  detail.clientNumber) ||
                ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                '-',
              bankName:
                (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                '-',
              branchName:
                (v.type === PaymentType.BANKTRANSFER && branchName) ||
                (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                undefined,
              bankCode: '-',
              branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
            },
            phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
            issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
            issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
            policy: {
              policyNo: v.policy?.policyNo || '-'
            }
          },
          clientPolicy
        ),
        amount: v.paymentOption.amount || 0,
        status: coreStatus[i].status,
        note: coreStatus[i].errorMes,
        action: '-',
        bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
      })) || []
  }
}
