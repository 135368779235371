import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import * as E from 'fp-ts/Either'
import moment from 'moment'

export const DateFromString = (formatStr: string) =>
  new t.Type<Date, string, unknown>(
    'DateFromString',
    (u): u is Date => u instanceof Date,
    (u, c) =>
      pipe(
        t.string.validate(u, c),
        E.chain((s) => {
          const date = moment(s, formatStr, true)
          return date.isValid() ? t.success(date.toDate()) : t.failure(u, c)
        })
      ),
    (a) => moment(a).format(formatStr)
  )
