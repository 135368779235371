import { AddLackOfDocumentModel, FileUploadData, SelectOption, StorageBlob } from '@pulseops/common'

export namespace AddlackofDocumentFormData {
  export interface AddlackofDocumentFormValue {
    caseId: SelectOption | null
    transactionType: string
    metaData: StorageBlob.MetaDataUpload | undefined
    suspendTypeOptions: AddLackOfDocumentModel.Options[]
    suspendType: SelectOption | null
    docType: string
    attachments: FileUploadData[]
  }

  export interface BeneficiaryDesignationRequestValue {
    docs: AddlackofDocumentFormValue[]
  }
  export interface DocFields {
    docs: AddlackofDocumentFormValue
    id: string
  }
}
