export enum PolicyStatus {
  IF = 'IF',
  PS = 'PS',
  LA = 'LA'
}
export const policyStatusMap = new Map<string, string>([
  [PolicyStatus.IF, `In Force`],
  [PolicyStatus.PS, `Proposal`],
  [PolicyStatus.LA, `Lapsed`]
])

export const getPolicyStatus = (str: string) => policyStatusMap.get(str) ?? `Lapsed`
