import { Paper, Table, TableHead, TableRow, TableCell, TableContainer, makeStyles, TableBody } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { AppContext, CustomedDateTimePicker, ErrorHandling, InternalConst, InternalFeedbackService, Label, PulseOpsFormat, Select, Title, form2, sharedStyle, useMobile } from '@pulseops/common'
import { InternalHistoryContentModal, InternalHistoryFeedbackModal, InternalSuspendHistoryForm, InternalSuspendInformationConst, InternalUserInfo, Status } from '@pulseops/task'
import moment from 'moment'
import React from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import { pipe } from 'fp-ts/lib/function'
import { useIsFocused } from '@react-navigation/native'
import { CallOutDetailContext, DataSuspendHistory, DataSuspendHistorySave, OBInternalRulesListData, OBInternalSuspendService, OBSectionRow, OBSuspendCodeData } from '@pulseops/outbound'

type Props = {
  disabled: boolean
  processInstanceId: string
  businessKey: string
  userInfo: InternalUserInfo
  suspendTypeList: OBSuspendCodeData[]
  listDataEmailRules: OBInternalRulesListData[]
}

export const OBInternalSuspendHistoryScreen = ({
  disabled,
  processInstanceId,
  userInfo,
  businessKey,
  suspendTypeList,
  listDataEmailRules
}: Props) => {
  const { t, i18n } = useTranslation()
  const [planSuspendDate, setPlanSuspendDate] = React.useState<Date | null>(null)
  const currentDate = new Date()
  const [dateError, setDateError] = React.useState<string>()
  const [enableButton, setEnableButton] = React.useState<boolean>(false)
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [isContentModalOpen, setIsContentModalOpen] = React.useState<boolean>(false)
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState<boolean>(false)
  const isFocused = useIsFocused()
  const [contentView, setContentView] = React.useState<string>('')
  const [feedbackDetail, setFeedbackDetail] = React.useState<InternalFeedbackService.InternalFeedbackDetail>()
  const [isEnabledUpdate, setIsEnabledUpdate] = React.useState<boolean>(false)
  const { setSuspendEnable } = React.useContext(CallOutDetailContext)

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const {
    base: { control, setValue, watch, trigger }
  } = form2.useForm(InternalSuspendHistoryForm.codec, {
    defaultValues: {
      suspendHistoryList: []
    }
  })
  const internalHistoryList = useWatch({ control, name: 'suspendHistoryList' })
  const { fields, append, remove } = useFieldArray<InternalSuspendHistoryForm.raw>({
    control: control,
    name: 'suspendHistoryList'
  })

  const useStyles = makeStyles({
    cellNoWrap: {
      whiteSpace: 'nowrap'
    }
  })
  const classes = useStyles()

  React.useEffect(() => {
    pipe(
      InternalFeedbackService.getInternalSuspendExpiredDate(processInstanceId),
      ZIO.foldM(
        (err) => {
           setPlanSuspendDate(null)
           return ZIO.fail(null)
        },
        (success) => {
          if(!!success) {
            const getHours = moment(success, 'DD/MM/yyyy HH:mm:ss').toDate().getHours()
            const planDate = moment(success, 'DD/MM/yyyy HH:mm:ss').toDate()
            planDate.setHours(getHours + 7)
            setPlanSuspendDate(planDate)
          }
            
          return ZIO.succeed(success)
        }
      ),
      ZIO.unsafeRun({})
    )

    if (processInstanceId && isFocused) {
      setInternalHistoryDataForm()
    }
    return () => {
      setValue('suspendHistoryList', [])
    }
  }, [processInstanceId])

  React.useEffect(() => {
    let isChangeUpdate = internalHistoryList.some((item, index) => item.status?.value !== item.oldStatusCode)
    setIsEnabledUpdate(isChangeUpdate)
  }, [internalHistoryList])

  const validateDate = (date: Date | null) => {
    if (!Number(date?.getTime())) {
      setDateError(t('common:InvalidDateFormat'))
    } else if (Number(date?.getTime()) < currentDate.getTime()) {
      setDateError(t('message:MS020043'))
    } else {
      setDateError(undefined)
    }
  }
  const disableButtonUpdate = () => !!dateError || !planSuspendDate || !enableButton || disabled

  const setStateShowSuspend = () => {
    if (businessKey) {
      pipe(
        ZIO.zipPar(
          InternalFeedbackService.getCaseStatusByCaseID(businessKey),
          OBInternalSuspendService.getInternalSuspendHistoryListOB(businessKey),
        ),
        ZIO.map(([caseStatus, historyList]) => {
          const status = historyList && historyList?.find(item => item.status === InternalConst.Status.ACTIVE)
          setSuspendEnable(!!status && caseStatus !== 'Pending')
        }),
        bindLoader,
        ErrorHandling.run()
      ) 
    }
  }

  const setInternalHistoryDataForm = () => {
    pipe(
      OBInternalSuspendService.getInternalSuspendHistoryListOB(businessKey),
      ZIO.map((historialList) => {
        if (historialList && historialList.length > 0) {
          for (let i = 0; i < historialList.length; i++) {
            const historyItem = historialList[i]
            const departmentItem = suspendTypeList.find((x) => x.departmentShortName === historyItem.department)
            const departmentName = i18n.language === 'vi' ? departmentItem?.departmentVN ?? '' : departmentItem?.department ?? ''
            const levelItem = InternalSuspendInformationConst.levelList.find((x) => x.code === historyItem.level)
            const levelName = (i18n.language === 'en' ? levelItem?.description : levelItem?.descriptionVi) ?? ''
            const userEmail = userInfo.email
            const newItem = {
              historyID: historyItem.id,
              status: undefined,
              oldStatusCode: historyItem.status,
              isCompleteStatus: false,
              suspendCode: historyItem.suspendCode,
              user: userEmail,
              slaHour: historyItem.slaHour,
              effectiveDate: historyItem.effectiveDate,
              dueDate: historyItem.dueDate,
              department: historyItem.department,
              departmentName: historyItem.department ? historyItem.department + '-' + departmentName : '',
              level: historyItem.level,
              levelName: levelName,
              content: historyItem.content,
              emailTo: historyItem.emailTo,
              emailCc: historyItem.emailCc,
              createdBy: historyItem.createdBy,
              createdDate: historyItem.createdDate,
              modifiedBy: historyItem.modifiedBy ?? '',
              modifiedDate: historyItem.modifiedDate ?? ''
            }
            append(newItem)
          }
          watch('suspendHistoryList').forEach((item, index) => {
            const historyItem = historialList[index]
            const isCompleteStatus = historyItem.status === Status.COMPLETE
            const statusItem = InternalSuspendInformationConst.statusList().find(
              (x) => x.value === historyItem.status
            ) ?? { value: '', label: '' }
            setValue(`suspendHistoryList.${index}.status`, statusItem)
            setValue(`suspendHistoryList.${index}.isCompleteStatus`, isCompleteStatus)
          })
        }
        return historialList
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const columnHistory = [
    t('InternalSuspend:Department'),
    t('InternalSuspend:Level'),
    t('InternalSuspend:EffectiveTime'),
    t('InternalSuspend:ServiceLevelAgreement'),
    t('InternalSuspend:DueDate'),
    t('common:Status'),
    t('SupplementaryInfo:CreatedUser'),
    t('Outbound:OBInternalSuspend:CreatedDate'),
    t('SupplementaryInfo:UpdatedUser'),
    t('SupplementaryInfo:UpdatedDate'),
    t('InternalSuspend:Content'),
    t('Outbound:OBInternalSuspend:FeedbackContent')
  ]

  const formatStringToDatetime = (value: string) => {
    const newDateFormat = !!value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
    return newDateFormat
  }

  const openSuspendContentModal = (content: string) => {
    setIsContentModalOpen(true)
    setContentView(content)
  }

  const openFeedbackContentModal = (internalSuspendID: string) => {
    pipe(
      InternalFeedbackService.getInternalFeedbackDetail(internalSuspendID),
      ZIO.map((responseData) => {
        if (!!responseData) {
          const feedbackDate = formatStringToDatetime(responseData.feedbackDate)
          const departmentItem = suspendTypeList.find((x) => x.departmentShortName === responseData.feedbackDepartment)
            const departmentName = responseData.feedbackDepartment + '-' + departmentItem?.department ?? ''
          setFeedbackDetail({
            ...responseData,
            feedbackDepartment: departmentName,
            feedbackDate: feedbackDate
          })
          setIsFeedbackModalOpen(true)
        }
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const cancelButtonEvent = () => {
    remove()
    setInternalHistoryDataForm()
  }

  const validateInternalSuspendDataForSave = () => {
    let isDuplicatedDepartment = false
    const internalHistorySuspendList = watch('suspendHistoryList')
    for (let i = 0; i < internalHistorySuspendList.length; i++) {
      const historyItem = internalHistorySuspendList[i]
      const duplicationFlag = internalHistorySuspendList.some(
        (oldItem, index) =>
          i !== index &&
          historyItem.department === oldItem.department &&
          historyItem.status?.value === Status.ACTIVE &&
          oldItem.status?.value === Status.ACTIVE
      )
      if (duplicationFlag) {
        isDuplicatedDepartment = true
        break
      }
    }
    return isDuplicatedDepartment
  }

  const mappingInternalSuspendHistoryList = () => {
    const updatedInternalSuspendList = watch('suspendHistoryList')
      .filter((x) => x.status?.value !== x.oldStatusCode)
      .map((internalHistory) => {
        const updatedInternalSuspend: DataSuspendHistory = {
          id: internalHistory.historyID,
          status: internalHistory.status?.value ?? '',
          suspendCode: internalHistory.suspendCode ?? '',
          user: internalHistory.user ?? '',
          slaHour: internalHistory.slaHour ?? '',
          effectiveDate: internalHistory.effectiveDate ?? '',
          dueDate: internalHistory.dueDate ?? '',
          department: internalHistory.department ?? '',
          level: internalHistory.level ?? '',
          content: internalHistory.content ?? '',
          emailTo: internalHistory.emailTo ?? '',
          emailCc: internalHistory.emailCc ?? '',
          timeZone: ''
        }
        return updatedInternalSuspend
      })
    const submitedInternalSuspendHistory: DataSuspendHistorySave = {
      data: updatedInternalSuspendList,
      processInstanceId: processInstanceId
    }
    return submitedInternalSuspendHistory
  }

  const updatedSuspendData = async () => {
    const isValidForm = await trigger()
    const isDuplicatedDepartment = validateInternalSuspendDataForSave()
    if (isValidForm && !isDuplicatedDepartment) {
      const updatedInternalHistoryData = mappingInternalSuspendHistoryList()
      pipe(
        OBInternalSuspendService.updatedInternalHistory(updatedInternalHistoryData),
        ZIO.map((responseData) => {
          if (!!responseData) {
            if (responseData.responseError.status === 'Success') {
              const status = internalHistoryList && internalHistoryList?.find(item => item.status?.value === InternalConst.Status.ACTIVE)
              setSuspendEnable(!!status)

              fields.forEach((fieldItem, index) => {
                const updatedItem = responseData.data?.find((x) => x.id === fieldItem.historyID)
                if (updatedItem) {
                  setValue(`suspendHistoryList.${index}.isCompleteStatus`, updatedItem.status === Status.COMPLETE)
                  setValue(`suspendHistoryList.${index}.oldStatusCode`, updatedItem.status)
                  updatedItem?.modifiedDate &&
                    setValue(`suspendHistoryList.${index}.modifiedDate`, updatedItem?.modifiedDate)
                  updatedItem?.modifiedBy && setValue(`suspendHistoryList.${index}.modifiedBy`, updatedItem?.modifiedBy)
                }
              })
              showToast(t('message:MS990017'), 'success')
            } else {
              showToast(t(`message:${responseData.responseError.message}`), 'error')
            }
          }
          return responseData
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
    if (isDuplicatedDepartment) {
      showToast(t(`message:MS050270`), 'error')
    }
  }

  const updatePlanSuspendDate = () => {
    if(planSuspendDate) {
      const createdBy = userInfo.email
      pipe(
        OBInternalSuspendService.updatePlanSuspendDate(processInstanceId, planSuspendDate.toISOString(), createdBy),
        ZIO.map((responseData) => {
          if(responseData.responseError.status === 'Success') {
            showToast(responseData.responseError.message, 'success')
          }
          else {
            showToast(responseData.responseError.message, 'error') 
          }
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  const FieldPlanDate = () => (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Text style={internalHistoryStyles.header}>{t('Tab:InternalSuspendHistory')}</Text>
        <View style={{ marginLeft: 10, marginBottom: 5, marginEnd: 30, alignSelf: 'flex-end', flexDirection: 'row'}}>
          <View style={{maxWidth: 250}}>
            <Label title={t('requestInfo:PlanUnsuspendDate')} />
            {!disabled ? 
              <CustomedDateTimePicker
                label=""
                value={planSuspendDate ? new Date(planSuspendDate) : null}
                minDate={currentDate}
                // maxDate={MAX_DATE}
                onChange={(val) => {
                  validateDate(val)
                  setEnableButton(true)
                  setPlanSuspendDate(val)
                }}
                errorMessage={dateError}
                invalidDateMessage={null}
                maxDateMessage={null}
                minDateMessage={null}
              /> :
              <Text style={{ paddingVertical: 10 }}>{planSuspendDate ? PulseOpsFormat.datetoFormat(planSuspendDate, 'DD/MM/YYYY HH:mm') : '-'}</Text>
            }
          </View>
          {!disabled && 
            <TouchableOpacity
              disabled={disableButtonUpdate()}
              style={disableButtonUpdate() ? 
                [sharedStyle.button, sharedStyle.btnDisabled] :
                [sharedStyle.button, sharedStyle.btnRed]}
              onPress={updatePlanSuspendDate}
            >
              <Text style={[disableButtonUpdate() ? sharedStyle.btnTextDisabled : sharedStyle.textButtonRed, { fontWeight: 'normal' }]}>
                {t('InternalSuspend:SaveTime')}
              </Text>
            </TouchableOpacity>
          }
        </View>
    </View>
  )

  const TableHistory = () => {
    return(
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ backgroundColor: '#e2e7ea' }}>    
              {columnHistory.map((item) => <TableCell key={`column-${item}`} style={{ minWidth: 150, fontWeight: 'bold', color: '#70777E' }}>{item}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields &&
              fields.length > 0 &&
              fields.map((suspendItem, index) => (
                <TableRow key={suspendItem.id}>
                  <TableCell className={classes.cellNoWrap}>{suspendItem.departmentName}</TableCell>
                  <TableCell className={classes.cellNoWrap}>{suspendItem.levelName}</TableCell>
                  <TableCell className={classes.cellNoWrap}>{formatStringToDatetime(suspendItem.effectiveDate ?? '')}</TableCell>
                  <TableCell className={classes.cellNoWrap}>{suspendItem.slaHour}</TableCell>
                  <TableCell className={classes.cellNoWrap}>{formatStringToDatetime(suspendItem.dueDate ?? '')}</TableCell>
                  <TableCell className={classes.cellNoWrap}>
                    <Controller
                      control={control}
                      name={`suspendHistoryList.${index}.status`}
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Select
                          value={value}
                          onChange={(statusVal) => {
                            onChange(statusVal)
                          }}
                          onBlur={onBlur}
                          disabled={watch(`suspendHistoryList.${index}.isCompleteStatus`) || disabled}
                          options={InternalSuspendInformationConst.statusList()}
                          errorMessage={!!value?.value ? '' : error?.message}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className={classes.cellNoWrap}>{suspendItem.createdBy}</TableCell>
                  <TableCell className={classes.cellNoWrap}>{formatStringToDatetime(suspendItem.createdDate ?? '')}</TableCell>
                  <TableCell className={classes.cellNoWrap}>{watch(`suspendHistoryList.${index}.modifiedBy`)}</TableCell>
                  <TableCell className={classes.cellNoWrap}>
                    {formatStringToDatetime(watch(`suspendHistoryList.${index}.modifiedDate`) ?? '')}
                  </TableCell>
                  <TableCell className={classes.cellNoWrap}>
                    <TouchableOpacity onPress={() => openSuspendContentModal(suspendItem.content ?? '')}>
                      <FontAwesome5 name="eye" color="#ED1B2E" />
                    </TouchableOpacity>
                  </TableCell>
                  <TableCell className={classes.cellNoWrap}>
                    <TouchableOpacity onPress={() => openFeedbackContentModal(suspendItem.historyID)}>
                      <FontAwesome5 name="eye" color="#ED1B2E" />
                    </TouchableOpacity>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
       </TableContainer>
    )
  }

  const Modals = () => {
    return(
      <>
       <InternalHistoryContentModal
          title={t('InternalSuspend:Content')}
          open={isContentModalOpen}
          onClose={() => setIsContentModalOpen(false)}
          content={contentView}
        />

        <InternalHistoryFeedbackModal
          title={t('InternalFeedback:FEEDBACKCONTENT')}
          open={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
          feedbackDetail={feedbackDetail}
        />
      </>
    )
  }

  const ActionList = () => {
    return (
      <OBSectionRow style={[{ width: '100%' }]}>
        <TouchableOpacity
          style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
          onPress={cancelButtonEvent}
        >
          <Text style={[sharedStyle.textButton, { fontWeight: 'normal' }]}>{t('common:Cancel')}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          disabled={!isEnabledUpdate}
          style={[sharedStyle.button, isEnabledUpdate ? sharedStyle.btnRed : sharedStyle.btnDisabled]}
          onPress={updatedSuspendData}
        >
          <Text style={[isEnabledUpdate ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled, { fontWeight: 'normal' }]}>
            {t('common:Update')}
          </Text>
        </TouchableOpacity>
      </OBSectionRow>
    )
  }
  
  return (
    <View style={internalHistoryStyles.container}>
     {FieldPlanDate()}
     {TableHistory()}
     {!disabled && ActionList()}
     {Modals()}
    </View>
  )
}
export const internalHistoryStyles = StyleSheet.create({
  container: {
    marginVertical: 30
  },
  header: {
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#58647A',
    fontSize: 16,
    marginBottom: 16
  }
})