import React from 'react'
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack'
import { AdmintoolList, SettingStackParamList, StackItemType } from './SettingStackParamList'
import { SettingScreen } from './SettingScreen'
import { ConfigT4S7MaxCaseScreen } from './AdmintoolTab/outbound/ConfigT4S7MaxCaseScreen'
import { ConfigT4S14ManagerTeamScreen } from './AdmintoolTab/outbound/ConfigT4S14ManagerTeam'
import { ConfigT4S15OutboundStaffListScreen } from './AdmintoolTab/outbound/ConfigT4S15OutboundStaffListScreen'
import { ConfigT4S17TransferTransaction } from './AdmintoolTab/outbound/ConfigT4S17TransferTransaction'
import { ConfigT4S18InboundStaffListScreen } from './AdmintoolTab/inbound/ConfigT4S18InboundStaffListScreen'
import { ConfigA2S3AnswersListScreen } from './AdmintoolTab/outbound/ConfigA2S3AnswersListScreen'
import { ConfigA2S1A2S2QAListScreen } from './AdmintoolTab/outbound/ConfigA2S1A2S2QAListScreen'
const Stack = createStackNavigator<SettingStackParamList>()

type Props = StackScreenProps<SettingStackParamList, 'SettingScreen'>

const stackNavigatorItem: Array<{ component: any; name: StackItemType | 'SettingScreen' }> = [
  { component: SettingScreen, name: 'SettingScreen' },
  { component: ConfigT4S7MaxCaseScreen, name: AdmintoolList.ConfigT4S7MaxCaseScreen },
  { component: ConfigT4S14ManagerTeamScreen, name: AdmintoolList.ConfigT4S14ManagerTeamScreen },
  { component: ConfigT4S15OutboundStaffListScreen, name: AdmintoolList.ConfigT4S15OutboundStaffListScreen },
  { component: ConfigT4S17TransferTransaction, name: AdmintoolList.ConfigT4S17TransferTransaction },
  { component: ConfigT4S18InboundStaffListScreen, name: AdmintoolList.ConfigT4S18InboundStaffListScreen },
  { component: ConfigA2S3AnswersListScreen, name: AdmintoolList.ConfigA2S3AnswersListScreen },
  { component: ConfigA2S1A2S2QAListScreen, name: AdmintoolList.ConfigA2S1A2S2QAListScreen }
]

export const SettingStack = ({ route }: Props) => {
  const renderStack = () => {
    return stackNavigatorItem.map((item, index) => {
      return <Stack.Screen key={index} name={item.name} component={item.component} />
    })
  }

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName="SettingScreen">
      {renderStack()}
    </Stack.Navigator>
  )
}
