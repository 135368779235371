import React, { useContext } from 'react'
import { pipe } from 'fp-ts/lib/function'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native'
import { useIsFocused } from '@react-navigation/core'
import { AppContext, useMobile, ImgUploadMutiple3, RoundedButton, AuthService } from '@pulseops/common'
import { Error } from '@pulseops/submission/common'
import styled from 'styled-components/native'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useNavigation } from '@react-navigation/native'
import { CertApi } from './ImportCert'

export interface FileUploadData {
  fileName: string
  fileExtension: string
  size: number
  base64: string
  uploadedDate: Date
  file: File
}
export interface UploadImgRef {
  clearError: () => void
}
export type AttchFileForm = {
  attachmentFiles: FileUploadData[]
}
export const ImportCertScreen: React.FC<{}> = () => {
  const form = useForm<AttchFileForm>({
    defaultValues: {
      attachmentFiles: []
    },
    shouldUnregister: false,
    mode: 'onChange'
  })
  const { control, handleSubmit } = form

  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { height } = useWindowDimensions()
  const { isMobile } = useMobile()
  const { showGlobalLoading, showToast, changeBreadcrumb } = useContext(AppContext.AppContextInstance)

  const [isLoading, bindLoader] = useLoading(false)
  const uploadImgRef = React.useRef<UploadImgRef | null>(null)
  const { navigate } = useNavigation()

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:DistributionServices'),
          navigate: () => {
            navigate('DistributionServicesScreen')
          }
        },
        { title: t('DistributionService:ImportCert'), navigate: null }
      ])
    }
    form.clearErrors('attachmentFiles')
    form.reset()

    uploadImgRef?.current?.clearError()
  }, [isFocused])

  const onSubmit = async (data: AttchFileForm) => {
    const file = data.attachmentFiles[0].file as File
    return pipe(
      AuthService.userInfo,
      ZIO.flatMap((user) => {
        return pipe(
          CertApi.uploadCert(file, user.email),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                form.reset()
                return showToast(t(`message:MS030029`), 'error')
              }),
            (success) =>
              ZIO.effect(() => {
                form.reset()
                return showToast(t(`message:MS040011`), 'success')
              })
          )
        )
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  return (
    <View style={{ backgroundColor: '#FFF', height: height, flex: 1 }}>
      <ScrollView style={{ height: '100%' }}>
        <View
          style={[
            styles.searchContainer,
            isMobile ? { paddingHorizontal: 30, paddingVertical: 30 } : { width: 800, marginHorizontal: 'auto' }
          ]}
        >
          <Controller
            name="attachmentFiles"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('message:MS150004')}`
              }
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <View style={{ flex: 1 }}>
                <ImgUploadMutiple3
                  value={value}
                  onChange={onChange}
                  timeFormat={'DD/MM/YYYY HH:mm'}
                  maxNumFile={1}
                  ref={uploadImgRef}
                />
                {error?.message && !!value && <Error message={error.message} />}
              </View>
            )}
          />
        </View>
        <SC.ContainerFooter style={isMobile ? { paddingHorizontal: 20, paddingVertical: 20 } : {}}>
          <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <RoundedButton
                showBorder
                activeOpacity={0.7}
                text={t('common:SubmitRequest')}
                textStyle={styles.btnTitle}
                style={{ height: 39, marginEnd: 15 }}
                textColorDisable={'#B0B0B0'}
                textColorEnable={'#ED1B2E'}
                borderColorDisable={'#B0B0B0'}
                borderColorEnable={'#ED1B2E'}
                filled={true}
                disabled={isLoading}
                onPress={handleSubmit(onSubmit)}
              />
            </View>
          </View>
        </SC.ContainerFooter>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9'
  },

  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    marginTop: 30,
    marginLeft: 30,
    marginRight: 30,
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    paddingHorizontal: 50,
    paddingVertical: 60
  },

  searchBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 30,
    paddingHorizontal: 18.5,
    paddingVertical: 15,
    lineHeight: 34
  },

  searchBtnText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#fff'
  },

  inputContainer: {
    flex: 1,
    paddingRight: 5
  },

  btnContainer: {
    marginLeft: 10
  },

  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 40,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: '#fff',
    color: '#424242',
    borderRadius: 8,
    fontSize: 15,
    height: 46,
    borderWidth: 1,
    borderColor: '#D3DCE6'
  },

  searchIcon: {
    position: 'absolute',
    right: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  headingTitle: {
    fontWeight: '700',
    fontSize: 13,
    lineHeight: 1.5
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 6,
    marginHorizontal: 36
  }
})

const SC = {
  ContainerFooter: styled(View)`
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 55px;
    padding-left: 55px;
  `
}
