import React from 'react'
import {
  assets,
  Container,
  FieldList,
  Panel,
  PolicyInfoHeader,
  PulseOpsFormat,
  TableField,
  useMobile
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { PolicyInfoData } from '../common'

const menus = [
  {
    label: 'Policy number',
    icon: <assets.DocumentIcon />,
    value: '7045867'
  },
  {
    label: 'Policy owner',
    icon: <assets.OwnerIcon />,
    value: 'NGUYEN VAN A'
  }
]

export const PolicyInfo = (props: { data: PolicyInfoData }) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()

  const data = props.data
    ? [
        { label: t('policy:proposalNo'), value: props.data.proposalNo },
        { label: t('policy:productCode'), value: props.data.productCode },
        { label: t('policy:productName'), value: props.data.productName },
        { label: t('policy:planName'), value: props.data.planName },
        { label: t('policy:inceptionDate'), value: props.data.inceptionDate },
        { label: t('policy:firstIssueDate'), value: props.data.firstIssueDate },
        { label: t('policy:expiryDate'), value: props.data.expiryDate },
        {
          label: t('policy:coverageTotalPremium'),
          value: `${PulseOpsFormat.thousandSepartor(Number(props.data.coverageTotalPremium))} VND`
        },
        {
          label: t('policy:coverageTotalSumAssured'),
          value: `${PulseOpsFormat.thousandSepartor(Number(props.data.coverageTotalSumAssured))} VND`
        },
        { label: t('policy:Status'), value: props.data.status },
        { label: t('policy:PartnerSource'), value: props.data.partnerSource },
        { label: t('policy:referralCode'), value: props.data.referralCode },
        { label: t('policy:transactionID'), value: props.data.transactionId }
      ]
    : []

  const columns = [
    {
      key: '1',
      title: t('TaskManagement:ComponentCode'),
      name: 'componentCode'
    },
    {
      key: '2',
      title: t('TaskManagement:ComponentName'),
      name: 'componentName'
    },
    {
      key: '3',
      title: t('TaskManagement:RiderRCD'),
      name: 'riderRCD'
    },
    {
      key: '4',
      title: t('TaskManagement:RiderRCssD'),
      name: 'riderRCssD'
    },
    {
      key: '5',
      title: t('TaskManagement:TotalSA'),
      name: 'totalSA'
    },
    {
      key: '6',
      title: t('common:Status'),
      name: 'status'
    }
  ]
  const riders = props.data ? props.data.rider : []

  const voucherData = props.data
    ? [
        { label: t('policy:EncryptedActivationCode_VoucherCode'), value: props.data.encryptedActivationCode },
        { label: t('policy:PartnerOrderID_VoucherOrder'), value: props.data.partnerOrderId },
        { label: t('policy:BuyerUserID'), value: props.data.buyerUserId },
        { label: t('policy:ActivatorUserID'), value: props.data.activatorUserId },
        {
          label: t('policy:Cost_Voucher'),
          value: PulseOpsFormat.thousandSepartorStr(props.data.costVoucher, '-', ' VND')
        },
        { label: t('policy:DifferentVoucherAmount'), value: props.data.differentVoucherAmount },
        { label: t('policy:APE'), value: props.data.aPE }
      ]
    : []

  return (
    <Container>
      {isMobile && <PolicyInfoHeader menus={menus} />}
      {data.length > 0 && (
        <Panel title={t('policy:PolicyInfo')}>
          <FieldList dataSource={data} />
        </Panel>
      )}
      {voucherData.length > 0 && (
        <Panel title={t('policy:VoucherInfo')}>
          <FieldList dataSource={voucherData} />
        </Panel>
      )}
      {riders.length > 0 && (
        <Panel title={t('policy:RiderInfo')}>
          <TableField columns={columns} dataSource={riders} name={t('policy:RiderInfo')} />
        </Panel>
      )}
    </Container>
  )
}
