import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

export const ZaloPremiumCollection = t.type({
  transactionType: t.literal(TransactionType.ZALO_PREMIUM_COLLECTION),
  payload: t.type({
    body: t.type({
      message: Maybe(t.string)
    })
  })
})
export type ZaloPremiumCollection = t.TypeOf<typeof ZaloPremiumCollection>
