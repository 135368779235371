import { Task } from '@mxt/zio'
import { Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { RequestAuthFormDataSubmit, TopUp } from './model'
import { SubmissionService } from './SubmissionService'

export namespace TopUpService {
  export interface HL06_INSTPREM {
    tag: 'HL06_INSTPREM'
  }

  export const HL06_INSTPREM: HL06_INSTPREM = { tag: 'HL06_INSTPREM' }
  export type InvalidSubmitTopup = HL06_INSTPREM | Throwable

  export enum TopUpStatus {
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS'
  }

  export const getDetail = (policyNum: string): Task<TopUp.Detail> =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/policy/account-balance`)(
          t.type({
            body: Maybe(
              t.type({
                accountGroups: Maybe(
                  t.array(
                    t.type({
                      amount: Maybe(t.number),
                      sacsCode: Maybe(t.string),
                      sacsType: Maybe(t.string)
                    })
                  )
                )
              })
            )
          })
        )({
          body: {
            policyNum,
            function: 'TOTAL',
            accountGroups: [
              {
                sacsCode: 'LP',
                sacsType: 'S'
              },
              {
                sacsCode: 'LP',
                sacsType: 'U'
              },
              {
                sacsCode: 'LE',
                sacsType: 'SP'
              }
            ]
          }
        }),
        SubmissionService.getPolicy(policyNum),
        GetPolicyExtraInfo([policyNum], 'BASIC')
      ),
      ZIO.map(([accountBalance, policyInfo, extraInfo]) => {
        const basicCode = policyInfo.body.basicCode
        const extraItem = extraInfo?.policyExtraInfoDetail.find((x) => x.policyNum === policyNum)
        const extraFund = extraInfo?.policyExtraInfoFund.find((x) => x.policyNum === policyNum)
        const apiPre = ['ULR1', 'ULR4'].includes(String(policyInfo.body.basicCode))
          ? Number(policyInfo.body.totalPremium) * Number(policyInfo.body.billFreq)
          : Number(extraItem?.targetPremium ?? 0)
        const suspenseAmount = accountBalance.body?.accountGroups?.reduce(function (total, next) {
          return ['S', 'U'].includes(String(next.sacsType)) ? total + Number(next.amount) : total
        }, 0)
        const ULPSuspenseAmount = accountBalance.body?.accountGroups?.reduce(function (total, next) {
          return ['SP'].includes(String(next.sacsType)) ? total + Number(next.amount) : total
        }, 0)
        return {
          policyNum: policyNum,
          minTopUp: Number(extraItem?.minTopUp ?? 0),
          maxTopUp: apiPre * Number(extraItem?.maxTopUp ?? 0),
          OGmaxTopUp: Number(extraItem?.maxTopUp ?? 0),
          api: apiPre,
          suspendAmount: Number(suspenseAmount),
          ULPsuspendAmount: Number(ULPSuspenseAmount),
          coverageCode: String(basicCode),
          totalPremium: Number(policyInfo.body.totalPremium),
          policyExtraInfoFund: extraFund as TopUp.PolicyExtraInfoFund
        }
      })
    )
  export const submit = (policyNum: string, submitData: TopUp.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
    pipe(
      SubmissionService.submit(
        t.type({
          status: t.string
        })
      )(
        `wf-api/policy/${policyNum}/top-up`,
        {
          body: {
            attributesExt: {
              TOP_UP: submitData
            }
          }
        },
        policyNum,
        requestauth,
        []
      ),
      // POApi.post(`wf-api/policy/${policyNum}/top-up`)(
      //   t.type({
      //     status: t.string
      //   })
      // )({
      //   body: {
      //     attributesExt: {
      //       TOP_UP: submitData
      //     }
      //   },
      //   otpTransaction: requestauth,
      //   source: SourceType.PULSE4OPS
      // }),
      ZIO.flatMap((response): Task<string> => {
        switch (response.status) {
          case TopUpStatus.SUCCESS:
            return ZIO.succeed(response.status)
          case TopUpStatus.FAILED:
            return ZIO.fail(Throwable('submit topup failed'))
          default:
            return ZIO.fail(Throwable('submit topup failed'))
        }
      })
    )

  export const getDataAccess = (policyNum: string): Task<TopUp.AccessData> =>
    pipe(
      ZIO.zipPar(GetPolicyExtraInfo([policyNum], 'BASIC'), SubmissionService.getPolicy(policyNum)),
      ZIO.map(([extra, policy]) => {
        const extraItem = extra?.policyExtraInfoDetail.find((x) => x.policyNum === policyNum)
        return {
          coverageCode: String(policy.body.basicCode),
          contractStatus: String(policy.body.status),
          premiumStatus: String(policy.body.premiumStatus),
          contractType: String(policy.body.productCode),
          totalPaidBasicAmount: Number(extraItem?.totalPaidBasicYearly),
          api: Number(policy.body.attributesExt?.basicPremium),
          paidToDateAdvance: String(policy.body.paidToDateAdvance)
        }
      })
    )
  export const getAccountBalance = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            },
            {
              sacsCode: 'LP',
              sacsType: 'U'
            }
          ]
        }
      }),
      ZIO.map((response) => {
        return (
          response.body?.accountGroups?.map((item) => {
            return {
              amount: Number(item.amount),
              sacsCode: String(item.sacsCode),
              sacsType: String(item.sacsType)
            }
          }) || []
        )
      })
    )
  export const GetPolicyExtraInfo = (policyList: string[], topUpFunction: string): Task<TopUp.PolicyExtraInfo> =>
    pipe(
      POApi.post(`wf-api/bo/policy-extra-info`)(
        t.type({
          body: t.type({
            action: Maybe(t.string),
            status: Maybe(t.string),
            policyExtraInfoDetails: TopUp.PolicyExtraInfoDetails,
            policyExtraInfoFunds: TopUp.policyExtraInfoFunds
          })
        })
      )({
        body: {
          policies: policyList,
          function: topUpFunction
        }
      }),
      ZIO.map((response) => {
        const policyExtraInfoFund =
          response.body.policyExtraInfoFunds && response.body.policyExtraInfoFunds.length > 0
            ? response.body.policyExtraInfoFunds
            : []
        return {
          policyExtraInfoDetail: response.body.policyExtraInfoDetails,
          policyExtraInfoFund: policyExtraInfoFund
        }
      })
    )
}
