import { Configuration, InteractionRequiredAuthError } from '@azure/msal-browser'
import * as msal from '@azure/msal-browser'
import { Task, Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { Option } from 'fp-ts/Option'
import { AppConfig, BuildConfig } from '../config'
import { POLogging } from '../POLogging'
import * as O from 'fp-ts/Option'

export namespace AuthStaff {
  const logger = POLogging.getLogger('AuthStaff')

  const redirectUri = BuildConfig.baseUrl + BuildConfig.contextPath
  const msalInstance: Task<msal.PublicClientApplication> = pipe(
    AppConfig.get,
    ZIO.map(
      (config): Configuration => ({
        auth: {
          clientId: config.aad.clientId,
          authority: config.aad.authority,
          redirectUri
        }
      })
    ),
    ZIO.map((msalConfig) => new msal.PublicClientApplication(msalConfig)),
    ZIO.cached()
  )

  export const handleRedirectPromise: Task<msal.AuthenticationResult> = pipe(
    msalInstance,
    ZIO.flatMap((msalInstance) => ZIO.fromPromise(() => msalInstance.handleRedirectPromise())),
    ZIO.tap((res) => logger.info('handleRedirectPromise', res)),
    ZIO.map(O.fromNullable),
    ZIO.absolveOption(() => Throwable('handleRedirectPromise result undefined'))
  )

  export const loginRedirect: Task<void> = pipe(
    ZIO.zip(msalInstance, AppConfig.get),
    ZIO.flatMap(([msalInstance, config]) =>
      ZIO.fromPromise(() =>
        msalInstance.loginRedirect({
          scopes: config.aad.scopes
          // loginHint: username
        })
      )
    )
  )

  export const getAccount = (username: string) =>
    pipe(
      msalInstance,
      ZIO.map((msalInstance) =>
        pipe(O.fromNullable(username), O.chainNullableK(msalInstance.getAccountByUsername.bind(msalInstance)))
      ),
      ZIO.tap((account) => logger.info('getAccount', account))
    )

  export const acquireTokenSilent = (username: string): Task<Option<msal.AuthenticationResult>> =>
    pipe(
      getAccount(username),
      ZIO.mapFoldOptionM(
        () => ZIO.succeed(O.none),
        (account) =>
          pipe(
            ZIO.zip(msalInstance, AppConfig.get),
            ZIO.flatMap(([msalInstance, config]) =>
              ZIO.fromPromise(() => msalInstance.acquireTokenSilent({ scopes: config.aad.scopes, account }))
            ),
            ZIO.tap((token) => logger.info('acquireTokenSilent', token)),
            ZIO.foldM(
              (err) => (err instanceof InteractionRequiredAuthError ? ZIO.succeed(O.none) : ZIO.fail(Throwable(err))),
              (result) => ZIO.succeed(O.some(result))
            )
          )
      )
    )
}
