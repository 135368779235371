import { Tabs, Tab, makeStyles } from '@material-ui/core'
import { TaskCount } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'

const useStyles = makeStyles({
  root: {
    '& .MuiTabs-fixed': {
      // borderRadius: 16,
      // boxShadow: 'none',
      // backgroundColor: 'blue',
      // shadowColor: '#0F0',
      borderBottom: '1px solid #C4C4C4'
    },
    '& .MuiTab-root': {
      // set style for child component
      lineHeight: 1,
      minHeight: 35,
      minWidth: 80,
      textTransform: 'capitalize',
      // margin: '0px 10px',
      marginRight: 30,
      padding: '6px 1px'
    },
    '&.MuiTabs-root': {
      // set style for root component
      minHeight: 35
    }
  }
})
//  interface CategoryTabInfo {
//   label: string
//   value?: string
//   key: string
//   count?: number
// }

export type OBTaskGroupTabProps = {
  dataSource: TaskCount[]
  permisison?: string[]
  // onChangeTab: (val: number) => void
  onChangeTab: (taskGroup: TaskCount) => void
  activeTabValue?: TaskCount
}

export const OBTaskGroupTab = (props: OBTaskGroupTabProps) => {
  const { t } = useTranslation()
  const { activeTabValue, dataSource, onChangeTab } = props
  const classes = useStyles()
 
  const handleChange = (event: React.ChangeEvent<{}>, value: TaskCount) => {
    onChangeTab(value)
  }
  const getTabLabel = (isActive: boolean, label: (converter: (val: string) => string) => string, count?: number) => {
    return (
      <View style={tabStyles.tabContent}>
        <View><Text style={[isActive && tabStyles.activeText]}>{label(t)}</Text></View>
        {!!count &&
          <View style={tabStyles.tabCircleContent}>
            <Text style={tabStyles.tabCircleText}>{count}</Text>
          </View>}
      </View>
    )
  }
  return (
    <Tabs
      value={activeTabValue?.type}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="simple tabs example"
      classes={{
        root: classes.root
      }}
      TabIndicatorProps={{
        style: {
          transition: 'none'
        }
      }}
    >
      {dataSource &&
        dataSource.map((dataItem, index) => {
          // console.log('dataItem.count:' + dataItem.count)
          dataItem.label
          return (
            <Tab
              key={`dataItem_${index}`}
              label={getTabLabel(activeTabValue?.type === dataItem.type, dataItem.label, dataItem.value)}
              value={dataItem.type}
              disableRipple
            ></Tab>
          )
        })}
    </Tabs>
  )
}
const tabStyles = StyleSheet.create({
  activeText: {
    // color: '#ED1B2E'
    color: '#4F4F4F',
    fontSize: 14,
    fontFamily: 'NotoSans_400Regular',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  tabCircleContent: {
    marginLeft: 4,
    minWidth: 24,
    height: 24,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 3
  },
  tabCircleText: {
    color: '#ED1B2E',
    fontFamily: 'NotoSans_400Regular',
    lineHeight: 20
  }
})
