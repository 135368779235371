import {
  assets,
  SubTaskCount,
  SubTaskType,
  TaskOp,
  AuthService,
  TaskService,
  TaskSource,
  TransactionType,
  CommonRequestService,
  ErrorHandling,
  TaskType,
  InquiryComplaintService,
  SelectOption,
  SUCommonRequestData
} from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, ScrollView, Text, TouchableOpacity, View, ViewStyle, StyleSheet } from 'react-native'
import { TaskCard, TaskCardType } from '../TaskCard'
import { CanView } from '../../../common/src'
import { BIG_SCREEN_SIZE, useBigScreenThreshold } from './Utils'
import { QueryTaskOptions } from './TaskFilterFormTypes'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { formatPeriodTime, parseAging } from './TaskFilterHelper'
import { from, Subscription } from 'rxjs'
import { useLoading } from '@mxt/zio-react'
import { TaskFilterForm } from './TaskFilterForm'
import { Duration } from '@mxt/zio/date'

const MAX_PAGE_ITEMS = 10
const UNASSIGNED_TASKS_FILTER = {
  USER_CHARACTER: '" "',
  VALUE: 'NULL_FILTER_ASSIGNEE'
}
const TASK_GROUP_SHOW_LAST_ACTIVITY = [
  TaskType.PolicyService,
  TaskType.Complaint,
  TaskType.CustomerService,
  TaskType.Claim
]
const BASKET_VER_QC_SUS = [
  SubTaskType.Verification,
  SubTaskType.QC,
  SubTaskType.Suspend,
  SubTaskType.Adjudication,
  SubTaskType.Underwriting
]

const getTaskList = ({
  subTask,
  pageIndex,
  pageSize = MAX_PAGE_ITEMS,
  searchPolicyNum = '',
  filter,
  isTeam = false
}: QueryTaskOptions) => {
  const isSuspended = [SubTaskType.Suspend, SubTaskType.WriteOff].includes(subTask?.code.subTask as SubTaskType)
  const getEref = subTask?.code.task == 'DS' ? searchPolicyNum.trim() || (filter?.eref || '').trim() : undefined
  const getCorrelationId = ['DS', 'IDC'].includes(subTask?.code.task as string) ? undefined : searchPolicyNum.trim() || (filter?.client || '').trim()
  const policyNum = searchPolicyNum && subTask?.code.task === TaskType.IDC ? searchPolicyNum.trim() : undefined
  return pipe(
    AuthService.userInfo,
    ZIO.flatMap(({ email }) =>
      TaskService.queryTasks({
        suspended: isSuspended,
        isTeam: isTeam,
        isFilterTeam: !!filter && !!filter.assignee,
        order: 'desc',
        active: true,
        filter: {
          assignee: subTask?.code.task === TaskType.IDC && !filter?.assignee && isTeam ? '' :
            (filter?.assignee === UNASSIGNED_TASKS_FILTER.USER_CHARACTER
              ? UNASSIGNED_TASKS_FILTER.VALUE
              : (filter?.assignee || email).trim()),
          caseId: (filter?.caseId || '').trim(),
          client: (filter?.client || '').trim(),
          eref: getEref,
          agentCode: (filter?.agentCode || '').trim(),
          correlationId: getCorrelationId,
          claimNumber: filter?.claimNumber || '',
          source: filter?.source.map((item) => item.value) as TaskSource[],
          paymentMethod: !!filter?.paymentMethod ? filter?.paymentMethod.value : '',
          lastActivity: filter?.lastActivity ? filter?.lastActivity.value ?? '' : '',
          createdBy: filter?.submittedBy ?? '',
          amlResult: filter?.AMLScreening ? filter?.AMLScreening.value : undefined,
          policyNum: policyNum,
          redFlagResult:
            filter?.AMLRedflag && filter?.AMLRedflag.value
              ? filter?.AMLRedflag.value === 'Y'
                ? true
                : false
              : undefined,
          transactionType: filter?.transactionType.map((item) => item.value) as TransactionType[],
          ...(filter?.agingFrom && filter.agingTo && parseAging(Number(filter.agingFrom), Number(filter.agingTo))),
          ...(filter?.createdFromDate &&
            filter?.createdToDate &&
            formatPeriodTime(filter.createdFromDate, filter.createdToDate))
        },
        taskCategory: subTask?.code,
        category: subTask?.code.task as string,
        pagination: { pageIndex, pageSize }
      })
    ),
    ZIO.map((res) => {
      if (filter?.assignee && isSuspended) {
        res.items = []
        res.pageIndex = 0
        res.pageSize = 0
        res.total = 0
      }
      return res
    })
  )
}

interface Props {
  title: string
  searchPolicyNum?: string
  filter?: TaskFilterForm.Validated
  shouldRefresh?: boolean
  disableSelect?: boolean
  canComment?: boolean
  cardType?: TaskCardType
  basket: SubTaskCount
  taskSelected: Map<string, TaskOp>
  containerStyle?: ViewStyle
  onTaskSelected: (task: TaskOp) => void
  onTaskUnSelected: (task: TaskOp) => void
  onPressTaskChatIcon?: (task: TaskOp) => void
  onRefreshed?: () => void
  subServiceTypeList: SelectOption[]
  subTransactionTypeList: (SelectOption & { slaStandard: string })[]
  commonRequests: SUCommonRequestData.CommonRequest[]
}

export const TaskList = ({
  cardType = 'MyTask',
  basket,
  taskSelected,
  title,
  searchPolicyNum = '',
  filter,
  shouldRefresh = false,
  disableSelect = false,
  canComment = true,
  containerStyle = {},
  onTaskSelected,
  onTaskUnSelected,
  onPressTaskChatIcon,
  onRefreshed,
  subServiceTypeList,
  subTransactionTypeList,
  commonRequests
}: Props) => {
  const { t } = useTranslation('TaskManagement')
  const { isBigScreen } = useBigScreenThreshold(BIG_SCREEN_SIZE)

  const [page, setPage] = React.useState<number>(0)
  const [tasks, setTasks] = React.useState<TaskOp[]>([])
  const [totalTask, setTotalTask] = React.useState<number>(0)
  const [isLoading, bindLoading] = useLoading(false)
  const [isLoadMore, bindLoadMore] = useLoading(false)
  const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true)
  const { i18n } = useTranslation()
  // const commonRequests = pipe(
  //   CommonRequestService.getCommonRequestList(),
  //   ZIO.map((commonrequests) => commonrequests),
  //   ErrorHandling.runDidMount()
  // )

  // const { subServiceTypeList, subTransactionTypeArr } = pipe(
  //   ZIO.zipPar(InquiryComplaintService.getTypesFromCachedApi(), InquiryComplaintService.getSubTypeFromCachedApi()),
  //   ZIO.map(([typeList, subTypeArr]) => {
  //     const subServiceTypeList = typeList.slice().map((x) => ({
  //       label: i18n.language === 'en' ? x.name : x.nameVi,
  //       value: x.code
  //     }))
  //     const subTransactionTypeArr = subTypeArr.slice().map((x) => ({
  //       label: i18n.language === 'en' ? x.name : x.nameVi,
  //       value: x.code,
  //       slaStandard: x.slaStandard ?? ''
  //     }))
  //     return { subServiceTypeList, subTransactionTypeArr }
  //   }),
  //   ErrorHandling.runDidUpdate([i18n.language])
  // ) || {
  //   subServiceTypeList: [],
  //   subTransactionTypeArr: []
  // }

  const getTasks = () => {
    let subscribe: Subscription | undefined = undefined
    if (basket.value > 0) {
      const promise = pipe(
        getTaskList({
          pageIndex: page,
          subTask: basket,
          isTeam: cardType === 'TeamTask',
          searchPolicyNum,
          filter
        }),
        page === 0 ? bindLoading : bindLoadMore,
        ZIO.unsafeRun({})
      )

      subscribe = from(promise).subscribe({
        next: (response) => {
          const { items, total } = response
          const oldItems = page === 0 ? [] : tasks
          setTasks([...oldItems, ...items])

          setTotalTask(total)
          subscribe?.remove(subscribe)
        },
        error: (error) => { },
        complete: () => {
          shouldRefresh && onRefreshed && onRefreshed()
        }
      })
    } else {
      shouldRefresh && onRefreshed && onRefreshed()
    }

    return () => {
      subscribe && subscribe.unsubscribe()
    }
  }

  React.useEffect(() => {
    return getTasks()
  }, [page])

  React.useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false)
      return
    }

    if (page !== 0) {
      setPage(0)
      return
    }

    return getTasks()
  }, [searchPolicyNum, filter])

  React.useEffect(() => {
    if (shouldRefresh) {
      if (page !== 0) {
        setPage(0)
        return () => { }
      }
      return getTasks()
    }
    return () => { }
  }, [shouldRefresh])

  const onPressShowMore = () => {
    setPage(page + 1)
  }

  const checkSuspendWriteOffPC = (item: TaskOp) => {
    return (
      basket.code.subTask === SubTaskType.Suspend &&
      item.category === TaskType.PremiumCollection &&
      item.writeOffAction !== 'ENABLE_WRITE_OFF'
    )
  }

  const checkUnsuspendWriteOffPC = (item: TaskOp) => {
    return (
      basket.code.subTask === SubTaskType.WriteOff &&
      item.category === TaskType.PremiumCollection &&
      item.writeOffAction !== 'ENABLE_REVERT_WRITE_OFF'
    )
  }
  return (
    <View
      style={[
        styles.laneContainer,
        isBigScreen ? wideStyles.laneContainer : mobileStyles.laneContainer,
        containerStyle
      ]}
    >
      <View
        style={[
          styles.laneHeaderContainer,
          isBigScreen ? wideStyles.laneHeaderContainer : mobileStyles.laneHeaderContainer
        ]}
      >
        <Text style={[styles.laneTitle, isBigScreen ? wideStyles.laneTitle : mobileStyles.laneTitle]}>{title}</Text>

        <CanView condition={isBigScreen}>
          <View style={styles.taskCountContainer}>
            <Text style={[styles.taskCountValue, { marginHorizontal: totalTask < 100 ? 0 : 6 }]}>{totalTask}</Text>
          </View>
        </CanView>

        {/* <CanView condition={!isBigScreen}>
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <TouchableOpacity activeOpacity={0.7} style={mobileStyles.btnScrollToEnd}>
              <assets.ArrowRightIcon />
            </TouchableOpacity>
          </View>
        </CanView> */}
      </View>

      {/* Render loading*/}
      <CanView condition={isLoading}>
        <View style={[styles.loadingContainer, { marginTop: isBigScreen ? 88 : 0 }]}>
          <ActivityIndicator color="red" />
        </View>
      </CanView>

      {/* Render Empty */}
      <CanView condition={!isLoading && tasks.length === 0}>
        <View style={[styles.noRecordContainer, { marginTop: isBigScreen ? 88 : 0 }]}>
          <Text style={styles.noRecord}>{t('Norecord')}</Text>
        </View>
      </CanView>

      <CanView condition={!isLoading && tasks.length > 0}>
        <ScrollView
          key={tasks.length}
          scrollEnabled={!isBigScreen}
          horizontal={!isBigScreen}
          style={{ marginStart: isBigScreen ? 0 : 12, paddingTop: isBigScreen ? 5 : 0 }}
        >
          <View style={isBigScreen ? wideStyles.listTaskContainer : mobileStyles.listTaskContainer}>
            {/* Render List Task */}
            <CanView condition={!isLoading && tasks.length > 0}>
              {tasks.map((item, index) => {
                let wrapRequest: string
                let wrapTaskOps: TaskOp
                if (item.transactionType === TransactionType.COMMON_REQUEST_FORM) {
                  const requestObj = commonRequests?.find((c) => c.requestCode === item.request)
                  wrapRequest = requestObj
                    ? i18n.language === 'vi'
                      ? requestObj.requestNameVn
                      : requestObj.requestNameEn
                    : '-'
                  wrapTaskOps = { ...item, request: wrapRequest }
                } else {
                  wrapTaskOps = item
                }

                const isShowLastActivity =
                  TASK_GROUP_SHOW_LAST_ACTIVITY.includes(basket.code.task) &&
                  BASKET_VER_QC_SUS.includes(basket.code.subTask)

                return (
                  <TaskCard
                    canComment={canComment}
                    basket={basket}
                    key={wrapTaskOps.id}
                    index={index}
                    cardType={cardType}
                    data={wrapTaskOps}
                    disableSelect={disableSelect || checkSuspendWriteOffPC(item) || checkUnsuspendWriteOffPC(item)}
                    selected={taskSelected?.has(wrapTaskOps.id)}
                    onSelected={(task, _index) => onTaskSelected(task)}
                    onUnSelected={(task, _index) => onTaskUnSelected(task)}
                    onPressChatIcon={(task, _index) => onPressTaskChatIcon && onPressTaskChatIcon(task)}
                    containerStyle={isBigScreen ? wideStyles.taskContainer : mobileStyles.taskContainer}
                    isShowLastActivity={isShowLastActivity}
                    subServiceTypeList={subServiceTypeList}
                    subTransactionTypeList={subTransactionTypeList}
                  />
                )
              })}
            </CanView>

            {/* Render see more task button for mobile */}
            <CanView condition={!isLoading && !isBigScreen && totalTask > 0}>
              {isLoadMore ? (
                <ActivityIndicator size="small" color="red" style={{ marginHorizontal: 20 }} />
              ) : (
                <View style={{ justifyContent: 'center' }}>
                  <TouchableOpacity
                    disabled={tasks.length === totalTask}
                    activeOpacity={0.7}
                    style={mobileStyles.btnShowMoreTask}
                    onPress={onPressShowMore}
                  >
                    <Text
                      style={[
                        mobileStyles.btnShowMoreTaskTitle,
                        { color: tasks.length === totalTask ? '#BDBDBD' : '#4F4F4F' }
                      ]}
                    >
                      {t('Seemore')}
                    </Text>
                    {tasks.length < totalTask ? (
                      <assets.ShowMoreMobileIcon_Active />
                    ) : (
                      <assets.ShowMoreMobileIcon_Inactive />
                    )}
                  </TouchableOpacity>
                </View>
              )}
            </CanView>

            {/* Render see more task button for wide device */}
            <CanView condition={!isLoading && isBigScreen && totalTask > 0}>
              {isLoadMore ? (
                <ActivityIndicator size="small" color="red" style={{ marginVertical: 20 }} />
              ) : (
                <TouchableOpacity
                  activeOpacity={0.7}
                  disabled={tasks.length === totalTask}
                  style={wideStyles.btnShowMoreTask}
                  onPress={onPressShowMore}
                >
                  <Text
                    style={[
                      wideStyles.btnShowMoreTaskTitle,
                      { color: tasks.length === totalTask ? '#BDBDBD' : '#333333' }
                    ]}
                  >
                    {t('Seemorecases')}
                  </Text>
                  {tasks.length === totalTask ? (
                    <assets.ShowMoreTaskWideIcon_Inactive />
                  ) : (
                    <assets.ShowMoreTaskWideIcon_Active />
                  )}
                </TouchableOpacity>
              )}
            </CanView>
          </View>
        </ScrollView>
      </CanView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },

  laneContainer: {
    flex: 1,
    borderTopStartRadius: 12,
    borderBottomStartRadius: 12,
    backgroundColor: '#F4F4F4'
  },

  laneHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  laneTitle: {
    fontWeight: 'bold',
    color: '#4F4F4F',
    marginEnd: 6
  },

  taskCountContainer: {
    minWidth: 26,
    height: 26,
    backgroundColor: '#FFFFFF',
    borderRadius: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  taskCountValue: {
    fontWeight: '500',
    fontSize: 15,
    color: '#ED1B2E'
  },

  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 164
  },

  noRecordContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 164
  },

  noRecord: {
    color: '#70777E',
    fontSize: 16,
    fontWeight: '500'
  }
})

const wideStyles = StyleSheet.create({
  laneContainer: {
    borderTopEndRadius: 12,
    borderBottomEndRadius: 12
  },

  laneHeaderContainer: {
    marginTop: 10,
    marginStart: 10,
    marginEnd: 10
  },

  laneTitle: {
    fontSize: 18
  },

  btnShowMoreTask: {
    flexDirection: 'row',
    alignSelf: 'baseline',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginStart: 20,
    marginBottom: 20
  },

  btnShowMoreTaskTitle: {
    fontWeight: '500',
    fontSize: 15,
    marginEnd: 4
  },

  listTaskContainer: {
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 10
  },

  taskContainer: {
    marginVertical: 5
  }
})

const mobileStyles = StyleSheet.create({
  laneContainer: {
    borderTopEndRadius: 0,
    borderBottomEndRadius: 0
  },

  laneHeaderContainer: {
    marginTop: 24,
    marginStart: 22,
    marginEnd: 12
  },

  laneTitle: {
    fontSize: 20
  },

  listTaskContainer: {
    flexDirection: 'row',
    marginHorizontal: 12
  },

  btnShowMoreTask: {
    flexDirection: 'row',
    alignSelf: 'baseline',
    alignItems: 'center',
    marginStart: 4
  },

  btnShowMoreTaskTitle: {
    fontWeight: '600',
    fontSize: 15,
    marginEnd: 4
  },

  btnScrollToEnd: {
    width: 28,
    height: 28,
    backgroundColor: '#FFFFFF',
    borderColor: '#BDBDBD',
    borderWidth: 1,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center'
  },

  taskContainer: {
    marginTop: 12,
    marginBottom: 16,
    marginEnd: 14
  }
})
