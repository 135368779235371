import { AppContext, assets, Permission, RBAC, ErrorHandling } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { LanguageTab } from './LanguageTab'
import { ContactUsTab } from './ContactUsTab'
import { AssignmentTab } from './AssignmentTab'
import { pipe } from 'fp-ts/lib/function'
import { OBAssignmentByLeaderTab, OBAssignmentTab } from '@pulseops/outbound'
import { useIsFocused } from '@react-navigation/native'
import { AdmintoolTab } from './AdmintoolTab'
import { StackScreenProps } from '@react-navigation/stack'
import { SettingStackParamList } from './SettingStackParamList'

type Props = StackScreenProps<SettingStackParamList, 'SettingScreen'>

export const SettingScreen = (props: Props) => {
  const { t } = useTranslation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [activeIndex, setActiveIndex] = React.useState<number>(0)
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const checkPermissionAdmintool = roles.includes(Permission['AdminToolOBSetting']) ||  roles.includes(Permission['AdminToolIBSetting'])
  const menu = [
    {
      _id: '',
      title: 'Setting:Language',
      icon: <assets.LanguageIcon />,
      scene: <LanguageTab />
    },
    {
      _id: Permission['EditAssignmentOnOffSetting'],
      title: 'Setting:Assignment',
      icon: <assets.AssignmentIcon />,
      scene: <AssignmentTab />
    },
    {
      _id: Permission['EditAssignmentByONOFFOBSetting'],
      title: 'Setting:OBAssignmentON_OFF',
      icon: <assets.AssignmentIcon />,
      scene: <OBAssignmentTab />
    },
    {
      _id: Permission['EditAssignmentByONOFFLeaderOBSetting'],
      title: 'Setting:OBAssignmentON_OFFForStaff',
      icon: <assets.AssignmentIcon />,
      scene: <OBAssignmentByLeaderTab />
    },
    {
      _id: '',
      title: 'Setting:ContactUs',
      icon: <assets.ContactUsIcon />,
      scene: <ContactUsTab />
    },
    {
      _id: 'AdminTool',
      title: 'Setting:AdminTool',
      icon: <assets.ContactUsIcon />,
      scene: <AdmintoolTab roles={roles}/>,
      tabTitle: 'adminTool'
    }
  ].filter((_) => (_._id ? (roles.includes(_._id) || (_.tabTitle === 'adminTool' && checkPermissionAdmintool)) : true))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: null },
        { title: t(menu[activeIndex].title), navigate: null }
      ])
    }

  }, [activeIndex, isFocused])

  React.useEffect(() => {
    const tabTitle = props.route.params?.tabTitle
    if(roles && tabTitle) {
      const findIndex = menu.findIndex((i) => i.tabTitle === tabTitle)
      findIndex !== -1 && setActiveIndex(findIndex)
    } 
  }, [roles, props.route.params])

  return (
    <View style={styles.container}>
      <View style={styles.main}>
        <View style={styles.leftBox}>
          {menu.map((item, index) => {
            const isActive = index === activeIndex
            return (
              <TouchableOpacity
                key={index}
                activeOpacity={0.7}
                style={styles.menuItemContainer}
                onPress={() => setActiveIndex(index)}
              >
                <View style={styles.menuIcon}>{item.icon}</View>
                <Text
                  style={{
                    fontSize: 15,
                    color: isActive ? '#ED1B2E' : '#000000',
                    fontWeight: isActive ? 'bold' : 'normal'
                  }}
                >
                  {t(item.title)}
                </Text>
              </TouchableOpacity>
            )
          })}
        </View>
        <View style={styles.rightBox}>{menu[activeIndex].scene}</View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },

  main: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    flexDirection: 'row',
    margin: 15,
    overflow: 'hidden'
  },
  leftBox: {
    flex: 0.3,
    paddingVertical: 30,
    paddingHorizontal: 15,
    borderEndWidth: 1,
    borderEndColor: '#BABBBF'
  },
  rightBox: {
    flex: 0.7
  },

  menuItemContainer: {
    marginBottom: 12,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    borderRadius: 8,
    overflow: 'hidden',
    alignItems: 'center',
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9
  },

  menuIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 26,
    height: 26,
    marginEnd: 10
  }
})
