import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Columns,
  Container,
  ErrorHandling,
  FieldList,
  FundMapping,
  GeneralInfoService,
  Panel,
  TableField
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface InvestmentInfoData {
  fundValue: TableField.DataSources
  pwAmount: TableField.DataSources
  ulpInfo: FieldList.DataSources
}

export const InvestmentScreen = (props: {
  id: string
  data: InvestmentInfoData | null
  setData: (val: InvestmentInfoData | null) => void
}) => {
  const { t } = useTranslation()
  const [loading, bindLoading] = useLoading()

  // const [fundValue, setFundValue] = useState<TableField.DataSources>([])
  // const [pwAmount, setPwAmount] = useState<TableField.DataSources>([])
  // const [ulpInfo, setUlpInfo] = useState<FieldList.DataSources>([])
  const getData = (): ZIO<unknown, Throwable, InvestmentInfoData | null> =>
    pipe(
      GeneralInfoService.getInvestmentInfo(props.id),
      ZIO.map((investmentInfo) => {
        return {
          fundValue: investmentInfo.ilp ? investmentInfo.ilp.fundValue : [],
          pwAmount: investmentInfo.ilp ? investmentInfo.ilp.partialWithdrawalAmount : [],
          ulpInfo:
            !investmentInfo.ilp && investmentInfo.ulp
              ? [
                  {
                    label: 'TaskManagement:TotalEstimateValue',
                    value: investmentInfo.ulp.totalEstimateValue,
                    suffix: 'VND'
                  },
                  {
                    label: 'TaskManagement:PartialWithdrawalAmount',
                    value: investmentInfo.ulp.ilpPartialWithdrawal,
                    suffix: 'VND'
                  }
                ]
              : []
        }
        // if (investmentInfo.ilp) {
        //   setFundValue(
        //     investmentInfo.ilp.fundValue.map((f) => ({
        //       ...f,
        //       fundName: FundMapping.getFunds(f.fundName)
        //     }))
        //   )
        //   setPwAmount(
        //     investmentInfo.ilp.partialWithdrawalAmount.map((f) => ({
        //       ...f,
        //       fundName: FundMapping.getFunds(f.fundName)
        //     }))
        //   )
        // } else if (investmentInfo.ulp) {
        //   setUlpInfo([
        //     {
        //       label: t('TaskManagement:TotalEstimateValue'),
        //       value: investmentInfo.ulp.totalEstimateValue,
        //       suffix: 'VND'
        //     },
        //     {
        //       label: t('TaskManagement:PartialWithdrawalAmount'),
        //       value: investmentInfo.ulp.ilpPartialWithdrawal,
        //       suffix: 'VND'
        //     }
        //   ])
        // }
        // return ZIO.unit
      })
    )

  const { fundValue, pwAmount, ulpInfo } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    fundValue: [],
    pwAmount: [],
    ulpInfo: []
  }

  const fundValueColumns: Columns[] = [
    {
      key: 'fn',
      title: t('TaskManagement:FundName'),
      name: 'fundName'
    },
    {
      key: 'ub',
      title: t('TaskManagement:UnitBalance'),
      name: 'unitBalance'
    },
    {
      key: 'efv',
      title: t('TaskManagement:EstimateFundValue'),
      name: 'estimatedValue'
    }
  ]
  const pwColumns: Columns[] = [
    {
      key: 'fn',
      title: t('TaskManagement:FundName'),
      name: 'fundName',
      flex: 1
    },
    {
      key: 'pwA',
      title: t('TaskManagement:PartialWithdrawalAmount'),
      name: 'partialWithdrawal',
      flex: 2
      // styles: { textAlign: 'right'}
    }
  ]

  return (
    <Container loading={loading}>
      {fundValue.length > 0 && (
        <Panel title={t('TaskManagement:FundValue')} containerStyle={{ backgroundColor: '#FAFAFA' }}>
          <TableField
            columns={fundValueColumns}
            dataSource={fundValue.map((item: any) => ({ ...item, fundName: t(FundMapping.getFunds(item.fundName)) }))}
          />
        </Panel>
      )}
      <Panel title={t('TaskManagement:PartialWithdrawalAmount')} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <TableField
          columns={pwColumns}
          dataSource={pwAmount.map((item: any) => ({ ...item, fundName: t(FundMapping.getFunds(item.fundName)) }))}
        />
      </Panel>
      <Panel title={t('TaskManagement:AccountValue')} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <FieldList
          dataSource={ulpInfo.map(
            (item) =>
              ({
                ...item,
                label: typeof item.label === 'string' ? t(item.label) : item.label
              } as FieldList.FieldType)
          )}
        />
      </Panel>
    </Container>
  )
}
