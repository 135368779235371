import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ClientName, Error } from './CepCommonModels'

export const NBUWRequestInfoC = t.type({
  data: t.type({
    proposalNo: t.string
  })
})
export type NBUWRequestInfoC = t.TypeOf<typeof NBUWRequestInfoC>

export const NbuwRequestInfos = t.type({
  requestRole: Maybe(t.string),
  roleName: Maybe(t.string),
  remark: Maybe(t.string),
  requestStatus: Maybe(t.string),
  requestType: Maybe(t.string),
  createdDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  lastUpdatedDate: Maybe(t.string),
  lastUpdatedBy: Maybe(t.string)
})

export type NbuwRequestInfos = t.TypeOf<typeof NbuwRequestInfos>

export const UwRequestInfo = t.type({
  proposalFinalUWDecision: Maybe(t.string),
  remarkForCallCenter: Maybe(t.string),
  reason: Maybe(t.string)
})

const ReinsuranceRequestDetails = t.type({
  isRole: Maybe(t.string),
  clientNumber: Maybe(t.string),
  clientName: Maybe(ClientName),
  isDescription: Maybe(t.string),
  meDescription: Maybe(t.string),
  reinDescription: Maybe(t.string),
  lifeNo: Maybe(t.string),
  uwDecisionBasic: Maybe(t.string),
  letterSummaryContent: Maybe(t.string),
  uwDecision: Maybe(t.string)
})
export type ReinsuranceRequestDetails = t.TypeOf<typeof ReinsuranceRequestDetails>
const ReinsuranceRequestInfo = t.type({
  provinceCode: Maybe(t.string),
  provinceName: Maybe(t.string),
  meServiceLevel: Maybe(t.string),
  panelDoctor: Maybe(t.string),
  suspendRemark: Maybe(t.string),
  conditionAcceptContent: Maybe(t.string),
  reinsuranceRequestDetails: t.array(ReinsuranceRequestDetails)
})

export const NBUWRequestInfoData = t.type({
  policyNumber: Maybe(t.string),
  proposalNumber: Maybe(t.string),
  policyStatus: Maybe(t.string),
  premiumStatus: Maybe(t.string),
  nbuwRequestInfos: Maybe(t.array(NbuwRequestInfos)),
  uwRequestInfo: Maybe(UwRequestInfo),
  reinsuranceRequestInfo: Maybe(ReinsuranceRequestInfo)
})

export const NBUWRootResponseC = t.type({
  data: NBUWRequestInfoData,
  error: Error
})

export type NBUWRootResponseC = t.TypeOf<typeof NBUWRootResponseC>
