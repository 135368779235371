import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { withMessage } from 'io-ts-types'
import i18next from 'i18next'
export namespace InternalFeedbackForm {
  const internalFeedbackDetail = t.type({
    content: t.string,
    dueDate: form2.string.optional,
    departmentName: form2.string.optional,
    departmentCode: form2.string.optional,
    caseID: form2.string.optional,
    internalID: form2.string.optional,
    userEmail: form2.string.optional,
    policyOwner: t.string,
    feedbackDetail: withMessage(form2.string.required, () =>
      i18next.t(`message:MS020001`, { field: i18next.t('InternalFeedback:FeedbackDetails') })
    )
  })

  export const codec = internalFeedbackDetail
  export type validated = t.TypeOf<typeof codec>
  export type raw = t.OutputOf<typeof codec>
}
