import { OBSharedStyles } from '@pulseops/outbound'
import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Container, ErrorHandling, TaskDetailService } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { IDCDetailContext } from '../../IDCDetailContext'
import { useTranslation } from 'react-i18next'

export const IDCHistoryTab = () => {
  const { t } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { processInstanceID } = React.useContext(IDCDetailContext)

  const historyList = pipe(
    TaskDetailService.GetRelatedHistory(processInstanceID),
    ZIO.map((result) => {
      return result
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([processInstanceID])
  ) || []


  return (
    <Container loading={isLoading}>
      <View style={OBSharedStyles.policyInfoContainer}>
        <View style={{ borderColor: '#D3DCE6', backgroundColor: '#fafafa', borderRadius: 8, borderWidth: 1 }}>
          <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 24 }}>
            <View style={{ width: '33.3333333333%' }}>
              <Text style={[styles.titleTable, styles.font16]}>{t('HistoryTab:Field')}</Text>
            </View>
            <View style={{ width: '33.3333333333%' }}>
              <Text style={[styles.titleTable, styles.font16]}>{t('HistoryTab:OriginalValue')}</Text>
            </View>
            <View style={{ width: '33.3333333333%' }}>
              <Text style={[styles.titleTable, styles.font16]}>{t('HistoryTab:NewValue')}</Text>
            </View>
          </View>
          {historyList.map((row, i) => (
            <View
              key={`history-row-${i}`}
              style={{ borderTopColor: '#eee', borderTopWidth: 2, paddingHorizontal: 24, paddingVertical: 8 }}
            >
              <View style={{ width: '100%' }}>
                <Text style={[styles.font16]}>
                  {row.name} - (<Text style={{ color: '#ef1c2e' }}>{row.email}</Text>){' '}
                  {i === historyList.length - 1 ? 'created case' : 'made changes'} - {row.createdDate} - {row.workFlowStage}
                </Text>
              </View>
              {row.fields.map((item, j) => (
                <View style={{ width: '100%', flexDirection: 'row', marginVertical: 2.5 }} key={`history-row-data-${j}`}>
                  <View style={{ width: '33.3333333333%' }}>
                    <Text style={[styles.font16]}>{item.name}</Text>
                  </View>
                  <View style={{ width: '33.3333333333%' }}>
                    <Text style={[styles.font16]}>{item.oldValue}</Text>
                  </View>
                  <View style={{ width: '33.3333333333%' }}>
                    <Text style={[styles.font16]}>{item.newValue}</Text>
                  </View>
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    </Container>

  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  titleSectionText: {
    fontSize: 16,
    lineHeight: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  titleTable: {
    color: '#70777e',
    fontWeight: '700'
  },
  font16: {
    fontSize: 16
  }
})
