import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { IBGeneralTable } from '../../../../../../common'
import { ACMVQueryData, IBService, InvestmentDataInfo } from '../../../../../../ib-service'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { groupBy, isEmpty, isNil, isString, keys, orderBy, sortBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PulseOpsFormat, assets } from '@pulseops/common'
import moment from 'moment'

interface PanelCProps {
  title: string | React.ReactNode
  children?: React.ReactNode
}

const PanelC = (props: PanelCProps) => {
  const [toggle, setToggle] = React.useState<boolean>(false)
  const { title, children } = props

  return (
    <View style={{ marginBottom: 10 }}>
      <View style={styles.title}>
        {isString(title) ? (
          <Text style={{ color: '#70777e', fontWeight: 'bold', fontSize: 15 }}>{title}</Text>
        ) : (
          <View style={{ width: '90%' }}>{title}</View>
        )}
        <TouchableOpacity onPress={() => setToggle(!toggle)}>
          {toggle ? <assets.IBCircleCollapseIcon /> : <assets.IBCircleExpandIcon />}
        </TouchableOpacity>
      </View>
      {toggle ? <View style={{ marginTop: 15 }}>{children}</View> : <></>}
    </View>
  )
}

interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
}
export const CostOfInsuranceInfo = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo } = props
  const [loading, bindLoading] = useLoading()

  React.useEffect(() => {
    if (isNil(investmentInfo?.costOfInsuranceInfo)) {
      const accountingCodes = [{ sacsCode: 'LE', sacsType: 'MC' }]
      pipe(
        ZIO.zipPar(
          IBService.ACMVQuery(policyNumber, 'COST_OF_INSURANCE_INFO', undefined, accountingCodes, false),
          IBService.getListMasterDataProduct()
        ),
        ZIO.map(([res, listProduct]) => {
          setInvestmentInfo({
            ...(investmentInfo as InvestmentDataInfo),
            costOfInsuranceInfo: {
              ACMVData: res.data?.map((item) => ({
                ...item,
                accountingAmount: item.moneySign === '-' ? (item.accountingAmount || 0) * -1 : item.accountingAmount
              })),
              listProduct: listProduct.data || []
            }
          })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataTable = [
    {
      label: t('EffectiveDate'),
      field: 'effectiveDate',
      format: 'date'
    },
    { label: t('TransNo'), field: 'transNo' },
    { label: t('TransactionDate'), field: 'transDate', format: 'date' },
    {
      label: t('Code'),
      field: 'transCode'
    },
    {
      label: t('AccountingAmount'),
      field: 'accountingAmount',
      format: 'money'
    }
  ]

  return (
    <View>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        Object.values(groupBy(investmentInfo?.costOfInsuranceInfo?.ACMVData as ACMVQueryData[], (item) => item.accNo))
          .sort((a, b) => parseInt(a[0].accNo as string) - parseInt(b[0].accNo as string))
          .map((item, index) => {
            const key = item[0].additionalProperties?.productCode
            const product = investmentInfo?.costOfInsuranceInfo?.listProduct?.find((x) => x.code === key)
            const nameProduct = i18n.language === 'vi' ? product?.nameVn : product?.nameEn || '-'

            const dataGeneralTable = orderBy(
              item.map((x) => ({
                ...x,
                effectiveDate: moment(x.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD'),
                transDate: moment(x.transDate, 'DD/MM/YYYY').format('YYYYMMDD')
              })),
              ['transDate', 'transNo'],
              ['desc', 'desc']
            )
            return (
              <PanelC
                title={`${key} ${nameProduct}: ${PulseOpsFormat.thousandSepartor(
                  Math.abs(item.reduce((pre, cur) => (pre = pre + (cur.accountingAmount as number)), 0))
                )}`}
                children={<IBGeneralTable data={dataGeneralTable} dataTable={dataTable} autoPaging />}
              />
            )
          })
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textStyle: {
    color: '#58647A',
    fontWeight: '700',
    fontSize: 15,
    marginRight: 5,
    marginBottom: 10
  }
})
