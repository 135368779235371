import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { ClaimStackParamList } from './ClaimStackParamList'

import { ClaimListScreen } from './ClaimListScreen'
import { ClaimScreen } from './ClaimScreen'
import { PayoutScreen } from './payout'
import { AddLackOfDocumentsScreen } from './add-lack-of-documents'
import { AddLackOfPayeeScreen } from './add-lack-of-payee'

const Stack = createStackNavigator<ClaimStackParamList>()

export const ClaimStack = () => {
  return (
    <Stack.Navigator initialRouteName="ClaimListScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="ClaimListScreen" component={ClaimListScreen} />
      <Stack.Screen name="ClaimScreen" component={ClaimScreen} />
      <Stack.Screen name="AddLackOfDocumentsScreen" component={AddLackOfDocumentsScreen} />
      <Stack.Screen name="AddLackOfPayeeScreen" component={AddLackOfPayeeScreen} />
      <Stack.Screen name="PayoutScreen" component={PayoutScreen} />
    </Stack.Navigator>
  )
}
