import {
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
  ScrollView,
  TouchableOpacity,
  FlatList,
  useWindowDimensions
} from 'react-native'
import {
  assets,
  TransactionType,
  Form,
  GeneralService,
  PulseOpsFormat,
  Select,
  useMobile,
  replaceSpecialCharactersInFileName
} from '@pulseops/common'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { DocumentInfoForm } from '../claim/document-info/DocumentInfoForm'
import { DocumentOption, FileUploadData, generalStyles } from '../claim'

const MB = 1048576
interface Props {
  onNext?: (documents: DocumentOption[]) => void
  triggerOnNext: (actions: () => void) => void
  triggerOnReset: (actions: () => void) => void
  onSubmit: (documents: DocumentOption[]) => void
  setDocuments: (documents: DocumentOption[]) => void
  onChangeTab: (tabIndex: number) => void
  visible: boolean
}

export const DocumentCashOut: React.FC<Props> = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t, i18n } = useTranslation('claim')
  const { width } = useWindowDimensions()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [documentTypes, setDocumentTypes] = React.useState<DocumentOption[]>([])
  const [selectedDocumentType, setSelectedDocumentType] = React.useState<DocumentOption[]>([])
  const flatListRef = React.useRef<FlatList>(null)

  const all = useTranslation().t

  const maxSize = 10 * MB
  const maxTotal = 50 * MB
  // const maxFile = 5
  const validExtensions = ['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']

  const [errorMess, setErrorMess] = React.useState<string>('')
  const [currSize, setCurrSize] = React.useState<number>(0)

  const [errorDocType, setErrorDocType] = React.useState<string>('')

  const form = useForm<DocumentInfoForm>()

  const register = Form.register(form)
  const { watch } = form
  const { documentType } = watch()

  props.triggerOnNext(() => {
    if (selectedDocumentType.filter((s) => s.required).every((s) => s.files.length) && errorMess === '') {
      props.onSubmit(selectedDocumentType)
      props.onChangeTab(1)
    } else {
      if (errorMess === '') setErrorMess(`${all('message:MS020001', { field: t('DocumentInfo') })}`)
    }
  })
  props.setDocuments(selectedDocumentType)

  props.triggerOnReset(() => {
    pipe(
      GeneralService.getListOptional(TransactionType.CASH_OUT_REQUEST),
      ZIO.map((listOptionals) => {
        const list: DocumentOption[] = listOptionals.map((d) => ({
          value: d.docTypeCode,
          label: d.doctypeEn,
          files: [],
          required: false,
          docTypeCode: d.docTypeCode,
          labelVn: d.doctypeVn
        }))
        const { newSelect, newDocs } = handleData([], list)
        setDocumentTypes(newDocs)
        setSelectedDocumentType(newSelect)
        setCurrSize(0)
        setTabIndex(0)
        return ZIO.unit
      }),
      ZIO.catchAll(() => {
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  })

  React.useEffect(() => {
    pipe(
      GeneralService.getListOptional(TransactionType.CASH_OUT_REQUEST),
      ZIO.map((listOptionals) => {
        const list: DocumentOption[] = listOptionals.map((d) => ({
          value: d.docTypeCode,
          label: d.doctypeEn,
          files: [],
          required: false,
          docTypeCode: d.docTypeCode,
          labelVn: d.doctypeVn
        }))
        setDocumentTypes(list)
        setSelectedDocumentType([])
        return ZIO.unit
      }),
      ZIO.catchAll(() => {
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }, [])

  const handleData = (requiredList: DocumentOption[], optionalList: DocumentOption[]) => {
    let newSelect = [...requiredList]
    let newDocs = [...optionalList]

    newSelect = requiredList.filter((el) => el.docTypeCode !== 'DPS01')
    if (!optionalList.some((item) => item.docTypeCode === 'DPS01')) {
      newDocs = [...optionalList]
    }
    return { newSelect, newDocs }
  }

  const setIndexAndScroll = (index: number): void => {
    setTabIndex(index)
    flatListRef.current?.scrollToOffset({ offset: index * width, animated: true })
  }

  const addDoc = () => {
    if (documentType && !selectedDocumentType.find((f) => f.value === documentType.value)) {
      const newSelectedDocs = [...selectedDocumentType]
      const oldDoc = documentTypes.find((f) => f.value === documentType.value)
      oldDoc && newSelectedDocs.push(oldDoc)
      setSelectedDocumentType(newSelectedDocs)
      const cloneDocumentTypes = [...documentTypes]
      const idx = cloneDocumentTypes.findIndex((d) => d.value === documentType.value)
      cloneDocumentTypes.splice(idx, 1)
      setDocumentTypes(cloneDocumentTypes)
      form.setValue('documentType', null)
      setErrorDocType('')
    } else {
      setErrorDocType(`${all('message:MS020009', { field: t('DocumentTypeOptional') })}`)
    }
  }

  const deleteDoc = (doc: DocumentOption) => {
    const newSelectedDocs = [...selectedDocumentType]
    const idx = newSelectedDocs.findIndex((d) => d.value === doc.value)
    if (idx > -1) {
      newSelectedDocs[idx].files = []
      newSelectedDocs.splice(idx, 1)
      setSelectedDocumentType(newSelectedDocs)
      setTabIndex(0)
    }
    const cloneDocumentTypes = [...documentTypes]
    cloneDocumentTypes.push(doc)
    setDocumentTypes(cloneDocumentTypes)
  }

  const onUploaded = (files: FileUploadData[]) => {
    const file = files[0]

    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      setErrorMess(all('message:MS040003', { files: validExtensions.join('/') }))
    } else if (file.size >= maxSize) {
      setErrorMess(all('message:MS040004'))
    } else if (currSize + file.size >= maxTotal) {
      setErrorMess(all('message:MS040005'))
    }
    // else if (selectedDocumentType.length >= maxFile) {
    //   setErrorMess(all('message:MS050279',{size: maxFile}))
    // }
    else {
      if (tabIndex === undefined) return
      const cloneSelectedDoc = [...selectedDocumentType]
      cloneSelectedDoc[tabIndex].files = [...cloneSelectedDoc[tabIndex].files, file]
      setSelectedDocumentType(cloneSelectedDoc)
      setCurrSize(currSize + file.size)
      setErrorMess('')
    }
  }

  const deleteAttachment = (tabIdx: number, attachmentIdx: number) => {
    const currSelectedDocs = [...selectedDocumentType]
    const currDoc = currSelectedDocs[tabIdx]
    if (currSize - currDoc.files[attachmentIdx].size < maxTotal) setErrorMess('')
    setCurrSize(currSize - currDoc.files[attachmentIdx].size)
    currDoc.files.splice(attachmentIdx, 1)
    setSelectedDocumentType(currSelectedDocs)
  }

  return (
    <View
      style={{
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 16,
        display: props.visible ? 'flex' : 'none'
      }}
    >
      <View style={{ width: '100%', marginTop: 20, flexDirection: 'row' }}>
        <View style={{ width: isMobile ? '66%' : '33%' }}>
          <Select
            {...register('documentType')}
            options={documentTypes.map((d) => ({ value: d.value, label: i18n.language === 'en' ? d.label : d.labelVn }))}
            label={t('CashOut:DocType')}
          />
        </View>
        <TouchableHighlight style={styles.addBtn} onPress={() => addDoc()}>
          <Text style={[styles.submitTextRed]}>{t('Add')}</Text>
        </TouchableHighlight>
      </View>
      <Text style={{ color: '#ed1b2c' }}>{errorDocType}</Text>
      <Text style={{ fontStyle: 'italic', color: '#70777E', marginTop: 20 }}>{t('ImageFormat')}</Text>

      <View style={[styles.sectionContainer]}>
        <ScrollView horizontal={true}>
          {selectedDocumentType.map((doc, index) => (
            <TouchableOpacity
              key={`doc.value-${doc.value}-${index}`}
              style={[
                tabIndex === index
                  ? {
                      borderRadius: 15,
                      backgroundColor: '#E5EAEF'
                    }
                  : {},
                {
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 23,
                  paddingRight: 23,
                  flexDirection: 'row'
                }
              ]}
              onPress={() => setIndexAndScroll(index)}
            >
              <Text style={tabIndex === index ? { fontWeight: 'bold' } : { color: '#70777E' }}>
                {i18n.language === 'en' ? doc.label : doc.labelVn}</Text>
              {doc.required && <Text style={{ color: 'red' }}> *</Text>}
              {!doc.required && (
                <TouchableOpacity style={{ marginLeft: 4 }} onPress={() => deleteDoc(doc)}>
                  <assets.RemoveIcon />
                </TouchableOpacity>
              )}
            </TouchableOpacity>
          ))}
        </ScrollView>

        <View style={{ flexDirection: 'row', width: '100%' }}>
          {selectedDocumentType.map((document, index) => (
            <View
              style={{
                width: '100%',
                marginTop: 30,
                flexDirection: isMobile ? 'column' : 'row',
                display: index === tabIndex ? 'flex' : 'none'
              }}
              key={`selected-doc-${index}`}
            >
              <View style={{ flexDirection: 'column', marginBottom: 23, width: '25%', marginRight: 20 }}>
                {isWide && <AddMore onUploaded={onUploaded} />}
              </View>
              {isMobile && (
                <View
                  style={{
                    borderBottomColor: '#C4C4C4',
                    borderBottomWidth: 1,
                    marginBottom: 30
                  }}
                />
              )}
              <ScrollView style={{ height: 170 }}>
                <View
                  style={{
                    flex: 1,
                    flexWrap: 'wrap',
                    marginTop: 8,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}
                >
                  {document.files?.map((file: FileUploadData, attachmentIdx) => (
                    <View
                      key={`File: ${file.fileName}-${attachmentIdx}`}
                      style={{
                        width: isWide ? '50%' : '100%',
                        paddingLeft: isWide ? 15 : 0,
                        paddingBottom: 10,
                        flexDirection: 'row'
                      }}
                    >
                      <View style={{ paddingRight: 15 }}>
                        <img src={file.base64} width={38} height={41} />
                      </View>
                      <View style={{ flexDirection: 'column', maxWidth: 255, paddingRight: 5 }}>
                        <Text>{file.fileName}</Text>
                        <Text>{PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD-MM-YYYY hh:mm')}</Text>
                      </View>
                      <TouchableHighlight style={{ height: 15 }} onPress={() => deleteAttachment(index, attachmentIdx)}>
                        <assets.RemoveIcon />
                      </TouchableHighlight>
                    </View>
                  ))}
                </View>
              </ScrollView>

              {isMobile && <AddMore onUploaded={onUploaded} />}
            </View>
          ))}
        </View>
      </View>

      <Text style={{ color: '#ed1b2c' }}>{errorMess}</Text>

      {/* <View style={{flexDirection: "row", justifyContent: "center", marginTop: 10}}>
          <TouchableHighlight
            style={[styles.btnWhite, { marginRight: isMobile ? 15 : 40 }]}
            onPress={console.log}>
            <Text style={[styles.submitTextRed]}>Reset</Text>
          </TouchableHighlight>
          <TouchableHighlight
            style={[styles.btnRed, { marginRight: isMobile ? 0 : 40 }]}
            onPress={() => {
              if (onNext && selectedDocumentType.filter(s => s.required).every(s => s.files.length)) {
                onNext(selectedDocumentType)
              }
            }}>
            <Text style={[styles.submitTextWhite]}>{t('Continue')}</Text>
          </TouchableHighlight>
        </View> */}
    </View>
  )
}

export const AddMore = ({ onUploaded }: { onUploaded: (file: FileUploadData[]) => void }) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()

  const inputRef = React.createRef<HTMLInputElement>()
  const upload = () => inputRef.current?.click()
  const handleUpload = (files: FileList) => {
    const fileList: FileUploadData[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      // const fileExt = file.name.split('.').pop() ?? ''
      const reader = new FileReader()

      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''

      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && onUploaded(fileList)
      }
    }
  }

  return (
    <TouchableOpacity
      style={
        isMobile
          ? {
              flexDirection: 'row'
            }
          : {
              backgroundColor: '#FFFFFF',
              borderRadius: 8,
              marginTop: 31,
              paddingTop: 18,
              paddingBottom: 18,
              borderWidth: 2,
              borderColor: '#D3DCE6',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row'
            }
      }
      onPress={upload}
    >
      <assets.UploadIcon />
      <Text style={{ color: '#ED1B2E', fontWeight: 'bold', marginLeft: 10 }}>{t('claim:AMF')}</Text>
      <input
        ref={inputRef}
        hidden
        type="file"
        multiple={true}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.files) {
            handleUpload(event.target.files)
            event.target.files = null
            event.target.value = ''
          }
        }}
      />
    </TouchableOpacity>
  )
}

export const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    label: {
      color: '#70777E',
      fontWeight: 'bold',
      fontSize: 15,
      marginBottom: 2
    },
    fileLabel: {
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 200,
      flexDirection: 'column'
    },
    addBtn: {
      marginLeft: 26,
      marginRight: 40,
      marginTop: 15,
      justifyContent: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: 100,
      borderWidth: 1,
      borderColor: '#ED1B2E',
      minWidth: 34,
      height: 40,
      paddingLeft: 29,
      paddingRight: 29
    }
  })
}
