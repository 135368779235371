import { Color } from '@material-ui/lab'
import { AppContext, ModalComponent, Toast, useMobile, DatePicker, Input } from '@pulseops/common'
import React, { useContext } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { StatusForm } from './status-form'

export type StatusModalProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  form: UseFormReturn<StatusForm>
  onSubmit: () => void
}

export const StatusModal = (props: StatusModalProps) => {
  const { onClose, open } = props
  const { isWide } = useMobile()
  const { isGlobalLoadingVisible } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const { t } = useTranslation('business')

  const { control } = props.form

  const handleClose = () => {
    onClose()
    props.form.reset()
  }

  const hdSubmit = () => {
    const { effectDate, refunded } = props.form.getValues()
    if (!effectDate || !refunded) {
      showToastInternal(t('MS000023'), 'error')
      return
    } else {
      props.onSubmit()
      onClose()
    }
  }

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  return (
    <ModalComponent
      title={t('status_of_the_insured')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('common:Save'),
          type: 'filled',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39 },
          action: () => hdSubmit()
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16 }}>
          <Controller
            control={control}
            name="effectDate"
            render={({ field }) => (
              <DatePicker
                label={t('member_removal_effective_date')}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                required
              />
            )}
          />
        </View>
        <View>
          <Controller
            name="refunded"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                required
                title={t('refunded_premium')}
                value={(value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                onBlur={onBlur}
                onChange={(value) => {
                  const targetValue = (value || '').replace(/[^0-9]/g, '')
                  onChange(targetValue)
                }}
              />
            )}
          />
        </View>
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
