import React from 'react'
import { Text, StyleSheet, View } from 'react-native'
import { OBButton, OBFieldTitle, OBFormat, OBPanel, OBSectionCol, OBSectionRow, OBSharedStyles } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  AuthService,
  Container,
  ErrorHandling,
  Input,
  PulseOpsService,
  TaskComment
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { CallOutDetailContext } from '../ob-common'
import { ScrollView } from 'react-native-gesture-handler'

export const OBComment = () => {
  const { t } = useTranslation()
  const [isLoading, bindLoading] = useLoading(false)
  const { processInstanceId, taskId, isDisabled } = React.useContext(CallOutDetailContext)
  const [commentList, setCommentList] = React.useState<TaskComment[]>([])
  const [comment, setComment] = React.useState<string>('')
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  pipe(
    PulseOpsService.getCommentList(processInstanceId),
    ZIO.tap((comments) => {
      setCommentList(comments)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([processInstanceId])
  )

  const onHandleAddEvent = () => {
    comment !== ''
      ? pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) =>
            pipe(
              PulseOpsService.addComment(taskId, processInstanceId, {
                author: user.email,
                message: comment
              }),
              ZIO.tap((res) => {
                setCommentList([res, ...commentList])
                setComment('')
                return ZIO.unit
              })
            )
          ),
          bindLoading,
          ErrorHandling.run()
        )
      : showToast(`${t('message:MS020001', { field: t('TaskManagement:Comment') })}`, 'error')
  }
  const onHandleClearEvent = () => {
    setComment('')
  }
  return (
    <Container loading={isLoading}>
      <View style={commentStyles.container}>
        <ScrollView style={commentStyles.commentHeader} showsVerticalScrollIndicator={true}>
          {commentList &&
            commentList.length > 0 &&
            commentList.map((commentItem) => {
              return (
                <OBPanel
                  paneltitle={commentItem.username}
                  addedCommentDate={OBFormat.formatDateToDateString(commentItem.time)}
                >
                  <Text>{commentItem.message}</Text>
                </OBPanel>
              )
            })}
        </ScrollView>

        <OBSectionRow>
          <OBSectionCol style={OBSharedStyles.sectionCol12}>
            <OBFieldTitle text={t('Tab:Comment')}></OBFieldTitle>
            <Input
              multiline={true}
              value={comment}
              onChange={setComment}
              numberOfLines={5}
              maxLength={500}
              disabled={isDisabled}
              containerStyle={{ width: '100%' }}
            />
          </OBSectionCol>
        </OBSectionRow>
        {!isDisabled && (
          <View style={commentStyles.commentFooter}>
            <OBButton
              text={t('Outbound:OBTaskManagement:Clear')}
              onHandleClickEvent={onHandleClearEvent}
              buttonContainerStyle={commentStyles.cancelBtnContent}
            ></OBButton>
            <OBButton text={t('common:Add')} onHandleClickEvent={onHandleAddEvent} isHightLight></OBButton>
          </View>
        )}
      </View>
    </Container>
  )
}
const commentStyles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingVertical: 24
  },
  commentHeader: {
    maxHeight: 250
  },
  commentFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24
  },
  cancelBtnContent: {
    backgroundColor: '#FEEDEE'
  }
})
