export namespace JournalModel {
  export enum JournalType {
    PAYABLE_ACCOUNT = 'PAYABLE_ACCOUNT',
    UNIT_JOURNAL = 'UNIT_JOURNAL',
    POLICY_ACCOUNT_JOURNAL = 'POLICY_ACCOUNT_JOURNAL',
    BONUS_JOURNAL = 'BONUS_JOURNAL',
    REMOVE_JOURNAL = 'REMOVE_JOURNAL'
  }

  export type OptionalRequest = PayableAccount | UnitJournal | PolicyAccountJournal | BonusJournal | RemoveJournal

  export interface JournalSubmitData {
    policyNo: string
    category: string
    action: string
    caseID: string | undefined
    transaction: string | undefined
    remark: string | undefined
    optionalRequest: OptionalRequest
    attributesExt?: {
      TOTAL_PAYOUT_AMOUNT: number
    }
  }

  export interface PayableAccount {
    type: string
    policyTransfer: {
      releaseNumber: string
      type: string
      total: number
      policyDetails: PolicyDetailsTranfer[]
    }
    receivingPolicy: {
      policy: string
      total: number
      policyDetails: PolicyDetailsReceive[]
    }
  }

  export interface PolicyDetailsTranfer {
    code: string
    type: string
    balance: number
    amount: number
  }

  export interface PolicyDetailsReceive {
    code: string
    type: string
    amount: number
  }

  export interface UnitJournal {
    type: string
    policyTransfer: {
      policyDetails: PolicyDetailsTranfer[]
    }
  }

  export interface PolicyAccountJournal {
    type: string
    policyTransfer: {
      policyDetails: PolicyDetailsTranfer[]
    }
  }

  export interface BonusJournal {
    type: string
    policyTransfer: {
      effectiveDate: string
      bonus: number
      amount: number
    }
  }

  export interface RemoveJournal {
    type: string
    policyTransfer: {
      journalNumber: string
    }
  }
}
