import { POApi } from '@pulseops/common'
import { SaleStructureReportModel } from './SaleStructureReportModel'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'

export namespace SaleStructureReportService {
  export const ReportDataC = t.type({
    body: Nullable(t.string)
  })

  export type ReportDataC = t.TypeOf<typeof ReportDataC>

  export const DataList = t.type({
    agentCategory: Maybe(t.string),
    agentCode: Nullable(t.string),
    agentType: Maybe(t.string),
    author: Nullable(t.string),
    businessKey: Maybe(t.string),
    clientId: Nullable(t.string),
    email: Maybe(t.string),
    eref: Maybe(t.string),
    gaCode1: Maybe(t.string),
    gaCode2: Maybe(t.string),
    gaType: Maybe(t.string),
    galleries: Maybe(t.string),
    id: Maybe(t.string),
    officeCode1: Maybe(t.string),
    officeCode2: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    ref: Maybe(t.string),
    regionCode: Maybe(t.string),
    saleType: Maybe(t.string),
    saleZone: Maybe(t.string),
    serviceBranch: Maybe(t.string),
    source: Maybe(t.string),
    status: Maybe(t.string),
    submissionDate: Maybe(t.number),
    taskId: Nullable(t.string),
    transactionType: Maybe(t.string)
  })

  export type DataList = t.TypeOf<typeof DataList>

  export const ReportDataList = t.type({
    body: Maybe(
      t.type({
        currentPage: Maybe(t.number),
        elements: Maybe(t.array(DataList)),
        pageSize: Maybe(t.number),
        totalElement: Maybe(t.number),
        totalPage: Maybe(t.number)
      })
    ),
    responseStatus: Maybe(
      t.type({
        clientError: Nullable(t.string),
        code: Maybe(t.number),
        errors: Nullable(t.string),
        message: Maybe(t.string)
      })
    )
  })

  export type ReportDataList = t.TypeOf<typeof ReportDataList>

  export const getReportData = (query: SaleStructureReportModel.QueryData) =>
    pipe(
      POApi.post(`distribution-agents-service/distribution-service/export-sales-structure-log`)(ReportDataC)(query),
      ZIO.map((res) => res)
    )

  export const getReport = (query: SaleStructureReportModel.QueryDataPage) =>
    POApi.post(`distribution-agents-service/distribution-service/view-sales-structure-log`)(ReportDataList)(query)
}
