import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { getCoreDetail, getPayOutDetail } from './common/payout'
import { PaymentType } from '../PaymentType'
import { ClientPolicy } from '../Client'
import { TransactionType } from '../TransactionType'
import { PruKid369Model } from '../PruKid369'
import { PulseOpsFormat } from '../../../Formatter'

export interface PruKid369Model {
  tag: TransactionType.PRUKID369
  clientName: string
  prukidAmount: string
  principalAmount: string
  interestAmount: string
  totalAmount: string
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
}

export const Prukid369 = (
  detail: api.Prukid369 & api.TaskDetailBase,
  coreDetail: PruKid369Model.DetailVerify,
  clientName: string,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string
): PruKid369Model => {
  const coreStatus = getCoreDetail(detail.coreResponseDetail, detail.payload.body.cashOutOptions || [])
  const cashOutList =
    detail.payload && detail.payload.body
      ? detail.payload.body.cashOutOptions?.filter(
          (x) =>
            PaymentType.BANKTRANSFER === x.type ||
            PaymentType.PAID_AT_BANK === x.type ||
            PaymentType.CASH_AT_COUNTER === x.type
        )
      : []
  return {
    tag: TransactionType.PRUKID369,
    clientName,
    prukidAmount: PulseOpsFormat.thousandSepartor(coreDetail.prukidAmount),
    principalAmount: PulseOpsFormat.thousandSepartor(coreDetail.principalAmount),
    interestAmount: PulseOpsFormat.thousandSepartor(coreDetail.interestAmount),
    totalAmount: PulseOpsFormat.thousandSepartor(detail.payload.body.attributesExt.TOTAL_PAYOUT_AMOUNT),
    cashOutList: cashOutList,
    branchName: branchName,
    methods:
      detail.payload.body.cashOutOptions?.map((v, i) => {
        return {
          method: v.type || '-',
          detail: getPayOutDetail(
            {
              type: v.type as PaymentType,
              officeCode:
                (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) || '',

              bankAccount: {
                accountName:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                  (v.type === PaymentType.PAID_AT_BANK &&
                    (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                      ? clientName
                      : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                  (v.type === PaymentType.CASH_AT_COUNTER &&
                    (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                      ? clientName
                      : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                  ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? clientName) ||
                  '-',
                accountNo:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                  ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                    detail.clientNumber) ||
                  ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                    detail.clientNumber) ||
                  ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                  '-',
                bankName:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                  (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                  '-',
                branchName:
                  (v.type === PaymentType.BANKTRANSFER && branchName) ||
                  (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                  undefined,
                bankCode: '-',
                branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
              },
              phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
              issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
              issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
              policy: {
                policyNo: v.policy?.policyNo || '-'
              }
            },
            clientPolicy
          ),
          amount: v.paymentOption.amount || 0,
          status: coreStatus[i].status,
          note: coreStatus[i].errorMes,
          action: '-',
          bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
        }
      }) || []
  }
}
