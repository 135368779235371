import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, Modal, useWindowDimensions } from 'react-native'
import {
  assets,
  RoundedButton,
  useMobile,
  Select,
  GeneralService,
  TaskCount,
  SubTaskCount,
  Form
} from '@pulseops/common'
import { CanView, TextInput, Label } from '../../../common/src'
import { useForm } from 'react-hook-form'
import {} from '../../../common/src/TextInput'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { TaskUnsuspendForm } from './TaskUnsuspendForm'
import { useTranslation } from 'react-i18next'
interface Props {
  visible: boolean
  taskGroup: TaskCount | undefined
  basket: SubTaskCount | undefined
  userGroups?: { userGroup: string; groupName: string }[]
  onPressClose?: () => void
  onPressTransfer?: (data: TaskUnsuspendForm) => void
}

export const TaskUnsuspendFormModal = ({
  visible,
  taskGroup,
  userGroups = [],
  basket,
  onPressClose,
  onPressTransfer
}: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation('TaskManagement')

  const [users, setUsers] = React.useState<{ name: string; email: string }[]>([])

  const form = useForm<TaskUnsuspendForm>({
    defaultValues: {
      comment: '',
      userGroup: undefined,
      user: undefined
    }
  })

  const { watch, reset, handleSubmit, setValue } = form

  const register = Form.register(form)

  const { userGroup, user } = watch()

  const onClose = () => {
    setUsers([])
    reset()
    onPressClose && onPressClose()
  }

  const onSubmit = (data: TaskUnsuspendForm) => {
    onPressTransfer && onPressTransfer(data)
  }

  React.useEffect(() => {
    if (taskGroup && basket && userGroup) {
      setUsers([])
      setValue('user', null)
      pipe(
        GeneralService.getUsersByGroups([userGroup.value], '', taskGroup.type, basket.code.subTask),
        ZIO.catchAll((_error) => ZIO.fail(null)),
        ZIO.tap((users) => ZIO.effectTotal(() => setUsers(users))),
        ZIO.unsafeRun({})
      )
    }
  }, [userGroup])

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.25)',
          justifyContent: isWide ? 'center' : 'flex-end'
        }}
      >
        {/* Backdrop handle touch outsidem modal event*/}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onClose}
            style={{
              position: 'absolute',
              width: width,
              height: height
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              borderRadius: 8,
              width: isWide ? 600 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0
            }
          ]}
        >
          <View style={[styles.headerContainer, { marginTop: isWide ? 20 : 16 }]}>
            <Text style={styles.headerTitle}>{t('Unsuspend')}</Text>
            <CanView condition={isWide}>
              <TouchableOpacity style={styles.btnClose} activeOpacity={0.7} onPress={onClose}>
                <assets.CloseTaskModalIcon />
              </TouchableOpacity>
            </CanView>
          </View>

          <View style={{ marginHorizontal: 20, flexDirection: isWide ? 'row' : 'column', marginTop: isWide ? 38 : 24 }}>
            <View style={{ flex: 1, marginEnd: isWide ? 60 : 0 }}>
              <Label title={t('Group')} />
              <Select
                {...register('userGroup')}
                options={userGroups.map((item) => ({ label: item.groupName, value: item.userGroup }))}
              />
            </View>
            <View style={{ flex: 1, marginTop: isMobile ? 16 : 0 }}>
              <Label title={t('User')} required />
              <Select
                {...register('user', {
                  validate: {
                    required: (value) => !!value
                  }
                })}
                options={users.map((item) => ({ label: item.name, value: item.email }))}
              />
            </View>
          </View>
          <View
            style={{
              marginHorizontal: 20,
              marginTop: isWide ? 20 : 16
            }}
          >
            <TextInput
              {...register('comment')}
              title={t('Comment')}
              placeholder={t('WriteComment')}
              textInputProps={{
                multiline: true,
                style: [styles.input, { marginTop: 10, height: isWide ? 140 : 60 }],
                placeholderTextColor: '#B0B0B0'
              }}
            />
          </View>
          {/* Apply Button */}
          <View
            style={[
              styles.btnGroup,
              { marginBottom: 30, marginTop: isWide ? 30 : 28, marginHorizontal: isWide ? 0 : 16 }
            ]}
          >
            <CanView condition={isWide}>
              <RoundedButton
                showBorder
                activeOpacity={0.7}
                text={t('Cancel')}
                textStyle={styles.btnTitle}
                style={{ height: 39, marginEnd: 15, width: 108 }}
                textColorDisable={'#ED1B2E'}
                textColorEnable={'#ED1B2E'}
                borderColorDisable={'#ED1B2E'}
                borderColorEnable={'#ED1B2E'}
                onPress={onClose}
              />
            </CanView>
            <RoundedButton
              filled
              activeOpacity={0.7}
              disabled={!user}
              text={t('Unsuspend')}
              textStyle={styles.btnTitle}
              style={{ height: 39, width: isWide ? 108 : '100%' }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={() => {
                handleSubmit(onSubmit)()
                setUsers([])
                reset()
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF'
  },

  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20
  },

  headerTitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  headerTitle: {
    fontWeight: '600',
    color: '#4F4F4F',
    fontSize: 20,
    marginEnd: 16
  },

  taskId: {
    color: '#828282',
    fontSize: 14
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  noti: {
    color: '#B0B0B0',
    fontSize: 13,
    marginTop: 11,
    marginHorizontal: 20
  },

  noMessage: {
    color: '#B0B0B0',
    fontSize: 13,
    marginTop: 11,
    textAlign: 'center'
  },

  emptyList: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  mesageContainer: {
    backgroundColor: '#FAFAFA',
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 15,
    paddingBottom: 12,
    marginBottom: 16
  },

  messageInfo: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  message: {
    color: '#000000',
    fontSize: 13,
    marginTop: 12
  },

  sender: {
    color: '#333333',
    fontSize: 13,
    fontWeight: 'bold',
    marginEnd: 12
  },

  sentDate: {
    color: '#70777E',
    fontSize: 13
  },

  separateLine: {
    height: 1,
    backgroundColor: '#C4C4C4'
  },

  input: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
