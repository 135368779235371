import { Any, Task, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  assets,
  Button,
  DetailService,
  GroupProduct,
  ImportButton,
  ImportService,
  RowLoading,
  UtilRow,
  View
} from '@pulseops/business/core'
import { ErrorHandling, Permission, RBAC, subscribe } from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../../contract-detail-context'
import { ProductInfoEdit } from './product-info-edit'
import { ProductInfoView } from './product-info-view'

export const ProductInfo = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const [loading, bindLoading] = useLoading(false)
  const [importLoading, bindImportLoading] = useLoading(false)
  const [isEdit, setIsEdit] = React.useState(false)

  pipe(service.getBenefit, bindLoading, ErrorHandling.runDidMount())

  const product: GroupProduct | null = pipe(service.state.benefit.watchGroupProduct, subscribe())

  const reload = ZIO.effect(() => {
    pipe(service.getBenefit, bindLoading, ErrorHandling.run())
  })

  if (loading) {
    return <RowLoading />
  }

  return (
    <View>
      <UtilRow>
        {roles.includes(Permission['ImportBenefitGroupDetailGeneralInfo']) && (
          <ImportButton
            onImport={(file) => {
              pipe(
                ImportService.uploadBenefit(contractId)(file),
                ZIO.tap(
                  E.fold(
                    (): Task<Any> => ZIO.unit,
                    () => pipe(ZIO.zip(service.state.benefit.clear, reload))
                  )
                ),
                bindImportLoading,
                ErrorHandling.run()
              )
            }}
            loading={importLoading}
            disabled={isEdit}
          />
        )}
        {/*<Button title={t('edit_history')} type="outline" icon={<assets.IconTime />} disabled={true} />*/}
        {roles.includes(Permission['EditBenefitGroupDetailGeneralInfo']) && (
          <Button
            title={t('edit')}
            type="outline"
            icon={<assets.IconEdit />}
            onPress={() => {
              setIsEdit(true)
            }}
            disabled={isEdit || importLoading || !product}
          />
        )}
      </UtilRow>
      {isEdit ? (
        <ProductInfoEdit
          onClose={() => {
            setIsEdit(false)
          }}
        />
      ) : (
        <ProductInfoView product={product} />
      )}
    </View>
  )
}
