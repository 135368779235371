import { Maybe } from '@mxt/zio/codec'
import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { withMessage } from 'io-ts-types'
export namespace AddLackForm {
  export const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  export type FileListBrand = {
    readonly FileList: unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  export const withFileRequired = () =>
    pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l.length > 0,
        (l) => i18next.t('error_required_field', { ns: 'form' }),
        'FileList'
      )
    )

  export const MetaData = Maybe(
    t.type({
      type: t.string,
      doctype: t.string,
      class: t.string,
      docid: t.string,
      maindoc: t.string,
      subdoc: t.string,
      batchno: t.string,
      polnum: t.string
    })
  )

  export type MetaData = t.TypeOf<typeof MetaData>

  export const AddLackOfDocumentItem = t.type({
    caseId: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020009', { field: i18next.t('submission:CaseId') })
    ),
    suspendCode: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020009', { field: i18next.t('submission:SuspendType') })
    ),
    attachments: withFileRequired(),
    transactionType: t.string,
    metaData: MetaData
  })

  export type AddLackOfDocumentItem = t.TypeOf<typeof AddLackOfDocumentItem>

  type AddLackDocumentsBrand = {
    readonly AddLackDocumentEmpty: unique symbol
  }
  export type AddLackDocumentEmpty = t.Branded<AddLackOfDocumentItem[], AddLackDocumentsBrand>

  export const AddLackDocuments = pipe(
    t.array(AddLackOfDocumentItem),
    form2.refine(
      (l): l is AddLackDocumentEmpty => l.length >= 1,
      (l) => i18next.t('error_list_min_length', { min: '1', ns: 'form' }),
      'AddLackDocumentEmpty'
    )
  )

  export const codec = t.type({
    addLackOfDocuments: AddLackDocuments
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
