import React from 'react'
import { View } from 'react-native'
import { SC } from './styles'

export const ValueShow: React.FC<{ title: string; value: string }> = ({ value, title }) => {
  return (
    <SC.InputContainer minWidth={150}>
      <SC.Padding>
        <SC.TitleText>{title}</SC.TitleText>
        <SC.Padding vertical={5}>
          <SC.NormalText>{value}</SC.NormalText>
        </SC.Padding>
      </SC.Padding>
    </SC.InputContainer>
  )
}

export const ConfirmView: React.FC<{
  isConfirmed?: boolean
  title: string
  value: string
  required?: boolean
  confirmBuilder?: () => JSX.Element
}> = ({ isConfirmed = false, title, value, required = true, confirmBuilder, children }) => {
  return (
    <View style={{ width: '100%' }}>
      {isConfirmed ? (
        confirmBuilder?.call(null) ?? (
          <View style={{ flex: 1 }}>
            <SC.Padding>
              <SC.TitleText>
                {title}
                <SC.ErrorText>{required ? ' * ' : ''}</SC.ErrorText>
              </SC.TitleText>
              <SC.Padding vertical={5}>
                <SC.NormalText>{value}</SC.NormalText>
              </SC.Padding>
            </SC.Padding>
          </View>
        )
      ) : (
        <View>{children}</View>
      )}
    </View>
  )
}
