import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, View } from 'react-native'
import { IBGeneralTable } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { BenefitItemData, IBService } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

interface Props {
  benefitlist: BenefitItemData
  setBenefitlist: (data: BenefitItemData) => void
  claimNumber: string
}

export const IBBenefitItem = ({benefitlist, setBenefitlist, claimNumber}: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if(claimNumber) {
      pipe(
        IBService.getBenefitItem(claimNumber),
        ZIO.map((res) => {
          res && setBenefitlist(res.map((i) => ({
            ...i,
            benefitName: `${i.benefitCode ?? ''}: ${i.benefitName ?? ''}`
          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])

  const dataTable = [
    {
      label: t('BenefitItem'),
      field: 'benefitName'
    },
    {
      label: t('PolNo'),
      field: 'policyNumber'
    },
    {
      label: t('Coverage'),
      field: 'coverageNo'
    },
    {
      label: t('ProdType'),
      field: 'productCode'
    },
    {
      label: t('CalculatedAmt'),
      field: 'caculatedAmount',
      format: 'money'
    },
    {
      label: t('PayableAmt'),
      field: 'payableAmount',
      format: 'money'
    }
  ]

  return (
    <View>
       {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <IBGeneralTable
          dataTable={dataTable}
          data={benefitlist}
          loading={loading}
        >
        </IBGeneralTable>
      )}
    </View>
  )
}