import { useMobile } from '@pulseops/common'
import * as React from 'react'
import { View, StyleSheet, TouchableOpacity, Text, ScrollView, ViewStyle } from 'react-native'

export interface TaskItem {
  title: string
  type: string
}

interface Props {
  data: TaskItem[]
  activeItemIndex?: number
  containerStyle?: ViewStyle
  onTabChanged?: (TransactionType: string) => void
}

export const SubTabsList = ({ data = [], activeItemIndex = 0, containerStyle = {}, onTabChanged }: Props) => {
  const { isWide } = useMobile()
  const [currentItemIndex, setCurrentItemIndex] = React.useState(activeItemIndex)
  const [isPassedFirstTime, setPassedFirstTime] = React.useState(false)

  React.useEffect(() => {
    if (isPassedFirstTime) {
      onTabChanged && onTabChanged(data[currentItemIndex].type)
    } else {
      setPassedFirstTime(true)
    }
  }, [currentItemIndex])

  return (
    <View style={containerStyle}>
      <ScrollView horizontal={true} style={{ paddingBottom: 6 }} showsHorizontalScrollIndicator={false}>
        {data.map((item, index) => {
          const isActiveItem = index === currentItemIndex
          const isFinalItem = index === data.length - 1
          return (
            <View key={index}>
              <TouchableOpacity
                key={index}
                activeOpacity={1}
                style={[styles.itemContainer, { marginEnd: isFinalItem ? 0 : 40 }]}
                onPress={() => setCurrentItemIndex(index)}
              >
                <View style={styles.titleContainer}>
                  <Text style={[styles.title, { fontWeight: isActiveItem ? '700' : '500' }]}>{item.title}</Text>
                  {isActiveItem && <View style={styles.activeUnderline} />}
                </View>
              </TouchableOpacity>
            </View>
          )
        })}
      </ScrollView>
      {isWide && <View style={styles.underline} />}
    </View>
  )
}

const styles = StyleSheet.create({
  underline: {
    height: 1,
    backgroundColor: '#C4C4C4',
    position: 'absolute',
    width: '100%',
    start: 0,
    bottom: 1,
    zIndex: -1
  },

  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },

  titleContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  title: {
    color: '#4F4F4F',
    fontSize: 15,
    marginEnd: 4
  },

  activeUnderline: {
    backgroundColor: '#ED1B2E',
    height: 3,
    width: '100%',
    borderRadius: 3,
    position: 'absolute',
    start: 0,
    bottom: -6
  },

  counterContainer: {
    width: 24,
    height: 24,
    borderRadius: 24,
    borderColor: '#ED1B2E',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  count: {
    color: '#ED1B2E',
    fontSize: 10,
    fontWeight: '600'
  }
})
