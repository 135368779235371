import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export const ORGResponseC = t.type({
  activity: t.string,
  assignee: Maybe(t.string),
  businessKey: Maybe(t.string),
  claimNumber: Maybe(t.string),
  createdDate: t.string,
  id: t.string,
  source: t.string,
  status: t.string,
  transactionType: t.string
})

export type ORGResponse = t.TypeOf<typeof ORGResponseC>

export const ORGCodex = t.type({
  data: Maybe(t.array(ORGResponseC)),
  order: t.string,
  size: t.number,
  sort: t.string,
  start: t.number,
  total: t.number
})

export type ORG = t.TypeOf<typeof ORGCodex>
