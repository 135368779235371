import {
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles
} from '@material-ui/core'
import { Any, Task, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  AmlResult,
  DetailService,
  Employee,
  ImportButton,
  ImportService,
  NbuwService,
  Table,
  TableFooterPagination,
  TableStatus,
  Text,
  UtilRow,
  Select
} from '@pulseops/business/core'
import { didUpdate, ErrorHandling, Permission, RBAC, subscribe, assets } from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, TouchableOpacity, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import { ContractDetailContext } from '../contract-detail-context'
import { EmployeeDetail } from './employee-detail'
import { StatusForm } from './status-modal/status-form'
import { StatusModal } from './status-modal/status-modal'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'
import { useIsFocused } from '@react-navigation/native'

const useStyles = makeStyles({
  cellWidth: {
    minWidth: 170
  },
  scroll: {
    overflowY: 'scroll'
  }
})

export const EmployeeList = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const [size, setSize] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [calledTimes, setcalledTimes] = React.useState<number>(0)
  const [loading, bindLoading] = useLoading(false)
  const classes = useStyles()
  const isFocused = useIsFocused()

  const [detailKey, setDetailKey] = React.useState<string | null>(null)

  const [isOpenEvaluationModal, setIsOpenEvaluationModal] = React.useState<boolean>(false)
  const [employeeKey, setEmployeeKey] = React.useState<string>('')
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [loadingConfirm, bindLoadingConfirm] = useLoading(false)

  const statusOptions = [
    { label: t('active'), value: 'active' },
    { label: t('inactive'), value: 'inactive' }
  ]

  const evaluationForm = useForm<StatusForm>({ mode: 'all' })

  // pipe(
  //   didUpdate([size, page]),
  //   ZStream.chainEffect(([size, page]) =>
  //     pipe(service.getEmployeeList({ size, page, isFocused, calledTimes: calledTimes }), bindLoading)
  //   ),
  //   ZStream.tap((_) => {
  //     setcalledTimes(1)
  //     return ZIO.unit
  //   }),
  //   subscribe()
  // )

  React.useEffect(() => {
    pipe(
      service.getEmployeeList({ size, page, isFocused, calledTimes: calledTimes }),
      ZIO.tap((_) => {
        setcalledTimes(calledTimes + 1)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }, [size, page])

  const rows: Employee[] | null = pipe(
    didUpdate([size, page]),
    ZStream.switchMap(([size, page]) => service.state.employee.size(size).watchPage(page)),
    subscribe([])
  )

  const total = pipe(
    didUpdate([size]),
    ZStream.switchMap(([size]) => service.state.employee.size(size).watchTotal),
    subscribe(0)
  )

  const reload = ZIO.effect(() => {
    if (page === 0) {
      pipe(service.getEmployeeList({ size, page, isFocused, calledTimes }), bindLoading, ErrorHandling.run())
    } else {
      setPage(0)
    }
  })

  const [importLoading, bindImportLoading] = useLoading(false)

  if (detailKey) {
    return (
      <EmployeeDetail
        employeeKey={detailKey}
        onClose={() => {
          setDetailKey(null)
        }}
      />
    )
  }

  const onSubmit = (values: StatusForm) => {
    const { effectDate, refunded } = values
    const memberRemovalEffectiveDate = moment(effectDate).format('YYYY-MM-DD')
    const refundedPremium = Number(refunded)
    return pipe(
      service.updateEmployeeStatus({ memberRemovalEffectiveDate, refundedPremium, employeeKey }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const handleChange = (key: string, val: string | null) => {
    if (val === 'inactive') {
      setIsOpenEvaluationModal(true)
      setEmployeeKey(key)
    }
  }

  const handleDelete = (key: string) => {
    setConfirmOpen(true)
    setEmployeeKey(key)
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  const deleteEmployee = () => {
    const nbuwService = NbuwService.forContract(contractId)
    return pipe(
      service.deleteEmployee(employeeKey),
      bindLoadingConfirm,
      ZIO.tap(() =>
        ZIO.zip(
          ZIO.effectTotal(() => {
            setConfirmOpen(false)
          }),
          service.state.employee.clear,
          reload,
          nbuwService.state.employee.clear,
          nbuwService.state.employeeUw.clear
        )
      ),
      ErrorHandling.run()
    )
  }

  return (
    <>
      <UtilRow>
        {roles.includes(Permission['ImportEmployeeGroupDetailGeneralInfo']) && (
          <ImportButton
            loading={importLoading}
            onImport={(file) => {
              const nbuwService = NbuwService.forContract(contractId)
              pipe(
                ImportService.uploadEmployee(contractId)(file),
                ZIO.tap(
                  E.fold(
                    (): Task<Any> => ZIO.unit,
                    () =>
                      ZIO.zip(
                        service.state.employee.clear,
                        reload,
                        nbuwService.state.employee.clear,
                        nbuwService.state.employeeUw.clear
                      )
                  )
                ),
                bindImportLoading,
                ErrorHandling.run()
              )
            }}
          />
        )}
      </UtilRow>

      <Table.Container component={Paper} className={classes.scroll}>
        <Table.Main>
          <Table.Head>
            <Table.Row>
              <Table.CellHead className={classes.cellWidth}>{t('employee_name')}</Table.CellHead>
              <Table.CellHead className={classes.cellWidth}>{t('id_card_no')}</Table.CellHead>
              <Table.CellHead>{t('email')}</Table.CellHead>
              <Table.CellHead>{t('effectDate')}</Table.CellHead>
              <Table.CellHead>{t('employee_status')}</Table.CellHead>
              <Table.CellHead className={classes.cellWidth}>{t('benefit_class')}</Table.CellHead>
              <Table.CellHead>{t('aml_result')}</Table.CellHead>
              <Table.CellHead />
              <Table.CellHead />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <TableStatus colSpan={8} loading={loading} rows={rows} />
            {rows?.map((row, index) => (
              <Table.Row key={index.toString()}>
                <Table.Cell className={classes.cellWidth}>{row.employeeName}</Table.Cell>
                <Table.Cell className={classes.cellWidth}>{row.employeeId}</Table.Cell>
                <Table.Cell>{row.email}</Table.Cell>
                <Table.Cell>{row.effectiveDate ? moment(row.effectiveDate).format('DD/MM/YYYY') : '-'}</Table.Cell>
                <Table.Cell style={{ marginTop: '-21px' }}>
                  <Select
                    minWidth="90%"
                    defaultValue={row.status || '-'}
                    options={statusOptions}
                    disabled={row.effectiveDate === null}
                    onChange={(val) => {
                      handleChange(row.employeeKey, val)
                    }}
                  />
                </Table.Cell>
                <Table.Cell className={classes.cellWidth}>{row.benefitClass}</Table.Cell>
                <Table.Cell>{AmlResult.formatLatest(row.historyAmlResult).result}</Table.Cell>

                <Table.Cell>
                  <Pressable
                    onPress={() => {
                      setDetailKey(row.employeeKey)
                    }}
                  >
                    <SC.Link>{t('view_more')}</SC.Link>
                  </Pressable>
                </Table.Cell>
                <Table.Cell align="center">
                  <TouchableOpacity
                    onPress={() => handleDelete(row.employeeKey)}
                    style={[styles.row, { alignItems: 'center' }]}
                  >
                    <assets.DeleteBin />
                    <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Delete')}</Text>
                  </TouchableOpacity>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooterPagination
            total={total}
            size={size}
            page={page}
            onPageChange={setPage}
            onSizeChange={setSize}
            colspan={8}
          />
        </Table.Main>
      </Table.Container>

      <StatusModal
        open={isOpenEvaluationModal}
        onClose={() => {
          setIsOpenEvaluationModal(false)
          evaluationForm.reset()
        }}
        form={evaluationForm}
        onSubmit={evaluationForm.handleSubmit(onSubmit)}
      />
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Thông báo</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              whiteSpace: 'pre-wrap',
              color: 'black'
            }}
          >
            Bạn có chắc muốn xóa nhân viên này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} disabled={loadingConfirm}>
            Không
          </Button>
          <Button onClick={deleteEmployee} color="primary" autoFocus disabled={loadingConfirm}>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SC = {
  Link: styled(Text)`
    text-decoration-line: underline;
    color: #1ea5fc;
  `,
  TableCell: withStyles({
    root: {
      paddingLeft: 0
    }
  })(Table.Cell)
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  headerCell: {
    color: '#70777E'
  }
})
