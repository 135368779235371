import * as React from 'react'
import { CircularProgress, TableCell, TableRow } from '@material-ui/core'

export const TableLoading = ({ colSpan }: { colSpan: number }) => {
  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        style={{
          textAlign: 'center'
        }}
      >
        <CircularProgress color={'primary'} size={50} />
      </TableCell>
    </TableRow>
  )
}
