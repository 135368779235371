import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'
import { useMobile } from '@pulseops/common'
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'

export namespace TableField {
  export type Columns = {
    key?: string | number
    title: string | JSX.Element
    name: string
    align?: 'left' | 'center' | 'right'
    color?: (val: string) => string
    styleCss?: any
    widthCss?: number
    onPress?: (index: number) => void
  }
  // StyleProp<TextStyle>
  type keyType = string | number | JSX.Element

  export type DataSource<T = keyType> = {
    [key: string]: T
  }

  export type DataSources<T = keyType> = DataSource<T>[]

  // export interface TableBody {
  //   columns: TableField.Columns[]
  //   dataSource: TableField.DataSources
  //   name?: string
  //   styleBody?: StyleProp<TextStyle>
  // }
}
interface DataTotal {
  title: string
  value: string
}
interface TableProps {
  columns: TableField.Columns[]
  dataSource: TableField.DataSources | any[]
  name?: string
  title?: string
  display?: boolean
  styleHeader?: StyleProp<TextStyle>
  styleBody?: StyleProp<TextStyle>
  totalCell?: boolean
  dataTotalCell?: Array<DataTotal>
}

function alignStyle(align: TableField.Columns['align']) {
  switch (align) {
    case 'right':
      return styles.alignRight
    case 'center':
      return styles.alignCenter
    default:
      return {}
  }
}

const TableCol = ({
  cols,
  columns,
  index
}: {
  cols: TableField.DataSource
  columns: TableField.Columns[]
  index: number
}) => {
  return (
    <>
      {columns.map((col, i) => {
        const value = cols[col.name] as string

        return (
          <TableCell>
            <Text
              style={[
                styles.tableColText,
                alignStyle(col.align),
                { color: col.color ? col.color(value) : '#000' },
                col.styleCss ? col.styleCss : {}
              ]}
              onPress={() => {
                col.onPress ? col.onPress(index) : undefined
              }}
            >
              {value}
            </Text>
          </TableCell>
        )
      })}
    </>
  )
}

const TableMobile = ({ columns, dataSource }: TableProps) => {
  return (
    <View>
      {(dataSource as any[]).map((row: any, index: number) => (
        <View style={[mobileStyles.item, index % 2 ? {} : mobileStyles.gray]}>
          {columns.map(({ key, title, name }) => (
            <View>
              <Text key={key} style={mobileStyles.label}>
                {title}
              </Text>
              <Text key={key} style={mobileStyles.value}>
                {row[name]}
              </Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

export const CepTableField = (props: TableProps) => {
  const { columns, dataSource, title, name, display, styleHeader, styleBody, totalCell = false, dataTotalCell } = props
  const { t } = useTranslation('requestInfo')
  const { isMobile } = useMobile()

  if (!display) {
    return null
  }

  return isMobile ? (
    <TableMobile {...props} />
  ) : (
    <View style={{ marginTop: 20, marginBottom: 20 }}>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ backgroundColor: '#F4F4F4' }}>
              {columns.map((column, index) => (
                <TableCell
                  key={column.key ? column.key : `table-header-${index}-${column.name}`}
                  align="left"
                  style={{ minWidth: column.widthCss ? column.widthCss : 150, fontWeight: 'bold', fontSize: 15 }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource && dataSource.length > 0 ? (
              dataSource.map((data, i) => (
                <TableRow key={i}>
                  <TableCol cols={data} columns={columns} index={i} />
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                  {t('common:Nodata')}
                </TableCell>
              </TableRow>
            )}
            {dataSource.length > 0 && totalCell && (
              <TableRow key={dataSource.length}>
                <TableCell style={{ backgroundColor: '#F4F4F4' }} align="center" colSpan={3}>
                  <Text style={{ fontWeight: '700', fontSize: 16, color: '#000000' }}>Total</Text>
                </TableCell>
                {dataTotalCell?.length &&
                  dataTotalCell?.map((x) => {
                    return (
                      <TableCell>
                        <Text>{x.value} </Text>
                      </TableCell>
                    )
                  })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </View>
  )
}

CepTableField.defaultProps = {
  display: true
}

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    borderTopColor: '#EEEEEE',
    borderTopWidth: 2,
    paddingVertical: 10
  },
  tableCol: {
    justifyContent: 'center',
    alignContent: 'center'
  },
  tableColHeaderText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700'
  },
  tableColText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '300'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignCenter: {
    textAlign: 'center'
  },
  currencty: {
    fontSize: 15,
    lineHeight: 24,
    fontStyle: 'italic',
    fontWeight: '300',
    textAlign: 'right',
    color: '#70777E',
    flex: 1
  },
  header: {
    flexDirection: 'row'
  },
  title: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800'
  }
})

const mobileStyles = StyleSheet.create({
  label: {
    color: '#70777E'
  },
  value: {
    lineHeight: 22,
    fontSize: 15,
    marginBottom: 18
  },
  gray: {
    backgroundColor: '#FAFAFA'
  },
  item: {
    paddingTop: 18,
    paddingHorizontal: 16
  }
})
