import * as t from 'io-ts'
import { ZIO, Throwable } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { getPartialWithDrawILP, getPartialWithDrawIULP } from './CepApi'
import { PartialWithdrawal } from '@pulseops/common'



export namespace CepService {
    export const getPWData = (policyNo: string, productType: string) => pipe(
        ZIO.zipPar(
            ZIO.succeed(policyNo),
            ZIO.succeed(productType)
        ),
        ZIO.flatMap(([policy, product]) => {
            return product === "ULP" || product === "TRAD" ? pipe(
                getPartialWithDrawIULP(policy),
                ZIO.map((pwInfo) => ({
                    ilp: null as any,
                    ulp: {
                          ...pwInfo.data,
                          fundList: (pwInfo.data.fundList ?? []).filter((x) => !!x.fundCode)
                          .map((item) => {
                            const isEPA = PartialWithdrawal.TopupFundList.find((x) => x.fundCode === item.fundCode)
                            // const isTPA = PartialWithdrawal.TargetFundList.find(x=> x.fundCode === item.fundCode)
                            return {
                              ...item,
                              isEPA: !!isEPA
                            }
                          })
                      }
                }))
            ) : pipe(
                getPartialWithDrawILP(policy),
                ZIO.map((pwInfo) => ({
                    ulp: null as any,
                    ilp: pwInfo.data
                }))
            )
        })
    )
}