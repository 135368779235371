import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { SubmissionService } from './SubmissionService'

export namespace PartialSurrenderService {
  export const getDetail = (policyNum: string) =>
    pipe(
      SubmissionService.getAccountBalance(policyNum, 'TOTAL', [
        { sacsCode: 'LN', sacsType: 'VP' },
        { sacsCode: 'LP', sacsType: 'S' },
        { sacsCode: 'LF', sacsType: 'WF' }
      ]),
      ZIO.map((accountBalance) => {
        const advancePremium =
          accountBalance.body?.accountGroups?.reduce(function (total, next) {
            return next.sacsCode === 'LN' && next.sacsType === 'VP' ? total + next.amount : total
          }, 0) || 0
        const suspenseAmount =
          accountBalance.body?.accountGroups?.reduce(function (total, next) {
            return (next.sacsCode === 'LP' && next.sacsType === 'S') ||
              (next.sacsCode === 'LF' && next.sacsType === 'WF')
              ? total + next.amount
              : total
          }, 0) || 0
        return {
          advancePremium,
          suspenseAmount
        }
      })
    )
}
