import React from 'react'
import { View, StyleSheet, SafeAreaView, ScrollView, Pressable, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { IBSearchForm } from './IBSearchForm'
import { IBGeneralField, TypeInputComponent, IBButton, IBGeneralTable } from '../common'
import { useForm } from 'react-hook-form'
import { assets, AppContext, RBAC, ErrorHandling, IBPolicyPermission } from '@pulseops/common'
import moment from 'moment'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { IBService, ClientInfo } from '../ib-service'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
import { StackScreenProps } from '@react-navigation/stack'
import { InboundParamList } from '..'
type Props = StackScreenProps<InboundParamList, 'IBSearchScreen'>

export const IBSearchScreen = (props: Props) => {
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const [loading, bindLoading] = useLoading(false)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const defaultValues: IBSearchForm = {
    contractNumber: '',
    nationalId: '',
    clientNumber: '',
    phoneNumber: '',
    email: '',
    fullName: '',
    dob: null
  }
  const { t, i18n } = useTranslation('Inbound')
  const SearchForm = useForm<IBSearchForm>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })
  const [formError, setFormError] = React.useState<string>('')
  const [clientList, setClientList] = React.useState<ClientInfo>()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('ContactCenterInquirySystem'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const validateFormSearch = (data: IBSearchForm) => {
    const cloneData = { ...data }
    if (_.isEmpty(_.values(data).filter((x) => x))) {
      setFormError(t('message:IB0005'))
      return false
    }
    if (data.fullName) {
      _.unset(cloneData, 'fullName')
    } else if (data.dob) {
      _.unset(cloneData, 'dob')
    }
    if (_.isEmpty(_.values(cloneData).filter((x) => x))) {
      setFormError(t('message:IB0006'))
      return false
    }
    setFormError('')
    return true
  }

  const onSearch = SearchForm.handleSubmit((value) => {
    if (validateFormSearch(value)) {
      const renderComma = (canShow: string, finalText?: boolean) => {
        if (finalText) return canShow
        return !_.isEmpty(canShow) ? `${canShow}, ` : ''
      }
    
      setClientList([])
      pipe(
        IBService.searchClientInfo({
          ...value,
          dob: value.dob ? moment(value.dob).format('DD/MM/YYYY') : '',
          contractNumber: value.contractNumber,
          pageIndex: 0,
          itemsPerPage: 0,
          orders: ['clientId'],
          sort: 'ASC'
        }),
        ZIO.map((res) => {
          if (_.isEmpty(res.records)) {
            setFormError(t('message:IB0007'))
          } else {
            setClientList(
              res.records.map((item) => ({
                ...item,
                phoneNumber: _.isNil(item.phoneNumber) && _.isEmpty(item.phoneNumber) ? '-' : item.phoneNumber,
                dob: _.isNil(item.dob) && _.isEmpty(item.dob) ? '-' : moment(item.dob, 'DD/MM/YYYY').format('YYYYMMDD'),
                email: _.isNil(item.email) && _.isEmpty(item.email) ? '-' : item.email,
                addressDetail: !_.isNil(item.address)
                  ? `${renderComma(item.address?.address as string)}${renderComma(
                      item.address?.ward as string
                    )}${renderComma(item.address?.district as string)}${renderComma(
                      item.address?.city as string
                    )}${renderComma(item.address?.country as string, true)}`
                  : '-'
              }))
            )
            setFormError('')
          }
          return ZIO.unit
        }),
        ZIO.catchAll((error) => {
          setFormError(t('message:IB0007'))
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  })

  const dataTable = [
    {
      label: t('ClientNumber'),
      field: 'clientNumber',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              props.navigation.push('IBClientScreenStack', {
                screen: 'DashBoardScreen',
                params: { clientNumber: value }
              })
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('ClientName'),
      field: 'fullName'
    },
    {
      label: t('IdNumber'),
      field: 'nationalId'
    },
    {
      label: t('DOB'),
      field: 'dob',
      format: 'date'
    },
    {
      label: t('PhoneNumber'),
      field: 'phoneNumber'
    },
    {
      label: t('Email'),
      field: 'email'
    },
    {
      label: t('Address'),
      field: 'addressDetail'
    }
  ]

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <IBGeneralField
            FieldForm={SearchForm}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.INPUT,
                formName: 'contractNumber',
                title: t('PolicyNumber'),
                inputType: 'number',
                maxLength: 9,
                rules: {
                  minLength: {
                    value: 8,
                    message: t('message:IB0001')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'nationalId',
                title: t('IdNumber'),
                inputType: 'input',
                maxLength: 24,
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'phoneNumber',
                title: t('PhoneNumber'),
                inputType: 'number',
                maxLength: 255,
                rules: {
                  minLength: {
                    value: 10,
                    message: t('message:IB0002')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'clientNumber',
                title: t('ClientNumber'),
                inputType: 'number',
                maxLength: 8,
                rules: {
                  minLength: {
                    value: 8,
                    message: t('message:IB0003')
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'email',
                title: t('Email'),
                inputType: 'input',
                maxLength: 255,
                rules: {
                  validate: () => {
                    return SearchForm.watch('email').length > 0 &&
                      !SearchForm.watch('email')
                        .toLowerCase()
                        .match(/\S+@\S+\.\S+/)
                      ? `${t('message:IB0010')}`
                      : true
                  }
                },
                placeHolder: t('common:Input'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'fullName',
                title: t('ClientName'),
                inputType: 'input',
                maxLength: 255,
                placeHolder: t('Inbound:MSIBSearch'),
                disabled: loading,
                alwayShowUnderline: true
              },
              {
                type: TypeInputComponent.DATE,
                formName: 'dob',
                title: t('DOBSearch'),
                rules: {
                  validate: () => {
                    const currentDate = new Date()
                    return !_.isNull(SearchForm.watch('dob')) &&
                      (SearchForm.watch('dob')?.getTime() as number) > currentDate.getTime()
                      ? `${t('message:IB0004')}`
                      : true
                  }
                },
                placeHolder: t('Inbound:MSIBSearch'),
                disabled: loading
              }
            ]}
          />
          <View style={styles.buttonContainer}>
            <IBButton
              onPress={onSearch}
              title={t('Search')}
              suffixIcon={<assets.IBSearchIcon />}
              backgroundFill
              disabled={!permissions.includes(IBPolicyPermission.SearchIBContactCenterInquiry) || loading}
            />
          </View>
        </View>
        <View style={{ marginLeft: 15 }}>
          {formError ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{formError}</Text> : null}
        </View>
        <View style={{ margin: 15 }}>
          {clientList ? (
            <IBGeneralTable dataTable={dataTable} data={clientList} loading={loading} autoPaging={true} />
          ) : (
            <></>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    height: '100%'
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    alignItems: 'center',
    width: '100%'
  }
})
