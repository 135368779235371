import DateFnsUtils from '@date-io/date-fns'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import i18next from 'i18next'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { assets } from '../../assets'
import { DatePickerProps } from './DatePicker'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

const useStyles = makeStyles({
  root: {
    '& input::placeholder': {
      color: '#70777E',
      opacity: 1
    }
  }
})

export const DatePicker = (props: DatePickerProps) => {
  const { hideUnderline = false, alwaysShow = !props.required, keyboardIconRed, placeHolder = 'dd/mm/yyyy' } = props

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.required && (
          <Text style={[styles.label, props.labelStyle]}>
            {props.label}
            <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>{' '}
          </Text>
        )}
        {props.alwaysShow && <Text style={[styles.label, props.labelStyle]}>{props.label}</Text>}
        <View style={[{ flex: 1 }, props.containerStyle]}>
          <KeyboardDatePicker
            className={useStyles().root}
            clearable
            label={props.required || alwaysShow ? '' : props.label}
            disabled={props.disabled}
            value={props.value || null}
            keyboardIcon={keyboardIconRed ? <assets.IconCalendarRed /> : <assets.InputCalendar />}
            onChange={(date) => {
              if (props.onChange) {
                props.onChange(date)
              }
            }}
            onBlur={props.onBlur}
            placeholder={placeHolder}
            format="dd/MM/yyyy"
            minDate={props.minDate}
            maxDate={props.maxDate}
            maxDateMessage={props.errorMessage ? '' : props.maxDateMessage}
            minDateMessage={props.errorMessage ? '' : props.minDateMessage}
            invalidDateMessage={props.errorMessage ? '' : props.invalidDateMessage || i18next.t('form:error_invalid')}
            InputProps={{
              disableUnderline: true,
              style: {
                color: props.highlightedField ? '#ED1B2C' : '#000', /// highlightedField hết hạn cccd và cmnd
                minHeight: 33
              }
            }}
          />
          {!hideUnderline && <View style={styles.underline} />}
          {props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
        </View>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 8
  },

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  }
})
