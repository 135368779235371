import { StyleSheet, Text, View, TouchableOpacity, TextInput, ViewStyle } from 'react-native'
import { assets, useMobile } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import React from 'react'
interface Props {
  value?: string
  inputContainerStyle?: ViewStyle
  onChangeSearchText?: (text: string) => void
  onPressSearch: () => void
  placeHolder?: string
  type?: 'number' | 'input'
}
export const IBSearchFilter = ({
  inputContainerStyle = {},
  value = '',
  onChangeSearchText,
  onPressSearch,
  placeHolder,
  type = 'number'
}: Props) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()

  return (
    <View
      style={[
        styles.searchContainer,
        {
          height: isMobile ? 28 : 30
        },
        inputContainerStyle
      ]}
    >
      <View style={styles.inputContainer}>
        <TextInput
          value={value}
          placeholder={placeHolder}
          placeholderTextColor={'#70777E'}
          style={[styles.input, { fontSize: isMobile ? 12 : 15 }]}
          onChangeText={(value) => {
            if (type === 'number' && value.length > 0 && value.indexOf(',') <= 0 && !value.match(/^[0-9]+$/)) {
              return
            }
            onChangeSearchText && onChangeSearchText(value)
          }}
          onSubmitEditing={onPressSearch}
        />
      </View>
      <TouchableOpacity
        onPress={onPressSearch}
        activeOpacity={0.7}
        style={[
          styles.btnSearchContainer,
          {
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 18,
            borderRadius: 20
          }
        ]}
      >
        <assets.SearchTaskIcon />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 20,
    borderColor: '#D3DCE6',
    backgroundColor: '#F9F9F9'
  },
  btnSearchContainer: {
    backgroundColor: '#ED1B2E',
    height: '100%'
  },

  inputContainer: {
    flex: 1,
    marginVertical: 4,
    marginStart: 15,
    marginEnd: 10
  },

  input: {
    flex: 1,
    height: '100%',
    fontWeight: '400',
    color: '#70777E'
  }
})
