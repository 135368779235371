import { CashOut } from '@pulseops/common'
export namespace LoanModel {
  export interface Detail {
    policyNum: string
    principalAplOpl: number | null | undefined
    interestAplOpl: number | null | undefined
    contractSurrenderValue: number | null | undefined
    loanAmountAvailable: number | null | undefined
  }

  export interface ViewDetail {
    policyNum: string
    principalAplOpl: string
    interestAplOpl: string
    contractSurrenderValue: string
    loanAmountAvailable: string
  }

  export interface AccessDataCheck {
    coverageCode: string
    contractStatus: string
    loanAmountAvailable: number
  }

  export interface SubmitData {
    cashOutOptions: Array<CashOut.Option>
    attributesExt: AttributesExt
  }

  export interface AttributesExt {
    TOTAL_LOAN: number
  }

  export interface LoanInfo {
    interestAPL: number
    interestOPL: number
    balanceAPL: number
    balanceOPL: number
  }
}
