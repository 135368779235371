export namespace CancelRiderFormData {
  export interface CancelAllRiderForm {
    cancelAllRider: boolean
    LARiderForm: Array<LARiderForm>
    reEstimatedInstallPremium: number
  }

  export interface LARiderForm {
    selectAllForLA: boolean
    LAName: string
    lifeAssured: string
    cancelRiderForm: Array<RiderForm>
  }

  export interface RiderForm {
    riderCode: string
    riderName: string
    SelectRowForRider: boolean
    riskCommDate: string
    riskCessDate: string
    sumAssured: number
    installmentPrem: number
    newInstallmentPremium: number
    lifeNo: string
    coverageNo: string
    riderNo: string
    riderStatus: string
  }
}
