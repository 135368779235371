import * as React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import {
  useTranslator,
  ModalComponent,
  Panel,
  FieldList,
  ErrorHandling
} from '@pulseops/common'
import _, { isEmpty } from 'lodash'
import { CepTableField } from '../cep-components'
import { PaymentItemC, PaymentItemP2PList, TranPaymentItemList } from '../../cep-api-services/CepPaymentModels'

interface Props {
  visible: boolean
  onPressClose?: () => void
  payData: PaymentItemC | null
  id: string
  bankData: TranPaymentItemList  | null
  P2PData: PaymentItemP2PList  | null
}

export const CepPaymentModal = ({ visible, onPressClose, payData, id, bankData, P2PData }: Props) => {
  // const { isWide, isMobile } = useMobile()
  const cep = useTranslator('CustomerEngagement').t
  
  const columnPayment = [
    {
      key: '1',
      title: cep('Seq No.'),
      name: 'seqNo'
    },
    {
      key: '2',
      title: cep('Code'),
      name: 'code'
    },
    {
      key: '3',
      title: cep('Type'),
      name: 'type'
    },
    {
      key: '4',
      title: cep('Description'),
      name: 'description'
    },
    {
      key: '5',
      title: cep('Tran Key'),
      name: 'tranKey'
    },
    {
      key: '6',
      title: cep('Original Amount'),
      name: 'originAmount'
    },
    {
      key: '7',
      title: cep('GL Amount'),
      name: 'accountingAmount'
    }
  ]

  const policyInfoList = [
    {
      label: cep('BankCode'),
      value: payData?.bankCode ?? ''
    },
    {
      label: cep('AuthDate'),
      value: payData?.authDate ?? ''
    },
    {
      label: cep('AuthorisedBy'),
      value: payData?.authorisedBy ?? ''
    },
    {
      label: cep('Verf.User'),
      value: payData?.verifyUser ?? ''
    },
    {
      label: cep('Verf.Date'),
      value: payData?.verifyDate ?? ''
    },
    {
      label: cep('PaymentType'),
      value: payData?.type ?? ''
    }
  ]

  const columnBank = [
    {
      key: '1',
      title: cep('Payment Number'),
      name: 'reqnNumber'
    },
    {
      key: '2',
      title: cep('ID Number'),
      name: 'nationalId'
    },
    {
      key: '3',
      title: cep('ID For Payment'),
      name: 'idForPayment'
    },
    {
      key: '4',
      title: cep('Account Name'),
      name: 'accountName'
    },
    {
      key: '5',
      title: cep('Account Number'),
      name: 'bankAccount'
    },
    {
      key: '6',
      title: cep('Bank Name'),
      name: 'bankName'
    },
    {
      key: '7',
      title: cep('Issuing Date & Authorise of ID'),
      name: 'issDateAndAuthId'
    }
  ]

  const columnP2P = [
    {
      key: '1',
      title: cep('Beneficiary Name'),
      name: 'beneficiaryName'
    },
    {
      key: '2',
      title: cep('Returned Date'),
      name: 'bankPaidDate'
    },
    {
      key: '3',
      title: cep('Payment Amount'),
      name: 'paymentAmount'
    },
    {
      key: '4',
      title: cep('PV No (Payment No.)'),
      name: 'pvNo'
    },
    {
      key: '5',
      title: cep('Authorized Date'),
      name: 'authorizedDate'
    },
    {
      key: '6',
      title: cep('User Create PV'),
      name: 'userCreatePV'
    },
    {
      key: '7',
      title: cep('Ver User PV'),
      name: 'verUserPV'
    },
    {
      key: '8',
      title: cep('Dept'),
      name: 'dept'
    },
    {
      key: '9',
      title: cep('Payment Service'),
      name: 'paymentService'
    },
    {
      key: '10',
      title: cep('Returned Reason'),
      name: 'reason'
    },
    {
      key: '10',
      title: cep('L_PS'),
      name: 'lps'
    }
  ]

  return (
    <ModalComponent
      title={cep('PaymentInfo')}
      visible={visible}
      onClose={() => {
        onPressClose?.()
      }}
      actions={[]}
      modalWidth={1082}
      titleStyle={{ color: '#000000' }}
      contentStyle={{ overflow: 'scroll' }}
    >
      <View
        style={{
          marginHorizontal: 20
        }}
      >
        <ScrollView style={{ marginHorizontal: 20 }}>
          {/* <Text>{cep('TransactionDetail')}</Text> */}
          <Panel title={cep('TransactionDetail')} isExand={false}>
            <FieldList dataSource={policyInfoList} />
            <CepTableField
              columns={columnPayment}
              dataSource={bankData ?? []}
              name="Payment"
            />
          </Panel>
          <Panel title={cep('BankTranferInformation').toUpperCase()} isExand={false}>
            <CepTableField
              columns={columnBank}
              dataSource={[
                {
                  reqnNumber: payData?.reqnNumber,
                  nationalId: payData?.nationalId,
                  idForPayment: payData?.idForPayment,
                  accountName: payData?.accountName,
                  bankName: payData?.bankName,
                  bankAccount: payData?.bankAccount,
                  issDateAndAuthId: payData?.issDateAndAuthId
                }
              ]}
              name="Payment"
            />
          </Panel>
          <Panel title={cep('P2P INFO')} isExand={false}>
            <CepTableField columns={columnP2P} dataSource={P2PData ?? []} name="Payment" />
          </Panel>
        </ScrollView>
      </View>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#000000',
    fontWeight: '700',
    fontSize: 20,
    marginEnd: 16
  },

  breadcumbCotnainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },

  breadcumbItem: {
    fontSize: 14,
    color: '#828282'
  },

  arrowIcon: {
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 25
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },

  row: {
    alignItems: 'flex-end'
  },

  startItem: {
    width: 250
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 8
  },

  error: {
    color: 'red',
    fontSize: 12
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_2: {
    width: '100%',
    maxWidth: '50%'
  },
  col_1: {
    width: '100%',
    maxWidth: '100%'
  },
  inputStyle: {
    borderColor: '#D3DCE6',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 3
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  }
})
