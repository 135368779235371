import { Throwable, ZIO } from '@mxt/zio'
import { NavigationContainerRef } from '@react-navigation/native'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { Subject } from 'rxjs'

export namespace RootNavigation {
  export const navigationRef: React.Ref<NavigationContainerRef> = React.createRef()
  const isReady = new Subject()

  export const ready = () => {
    isReady.next(null)
    isReady.complete()
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  export const navigate = (name: string, params?: object) =>
    pipe(
      ZIO.fromPromiseTotal(() => isReady.toPromise()),
      ZIO.flatMap(() => ZIO.effectTotal(() => navigationRef.current)),
      ZIO.flatMap((navigation) =>
        navigation
          ? ZIO.effect(() => {
              navigation.navigate(name, params)
            })
          : ZIO.fail(Throwable('navigation is undefined'))
      )
    )
}
