import {
  Checkbox,
  CriticalIllnessType,
  ErrorHandling,
  Form,
  GeneralService,
  HospitalType,
  ICDType,
  Select,
  SubClaimType,
  SubClaimTypeBenefit,
  TaskDetail,
  useMobile,
  VerClaimType,
  SourceType
} from '@pulseops/common'
import { ClaimType } from '@pulseops/submission'
import { pipe } from 'fp-ts/function'
import moment from 'moment'
import * as React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { Panel } from '../claim-common'
import { ClaimInfoForm } from './ClaimInfoForm'
import { CriticalIllness, Death, HospitalCash, TotalPermanentDisability } from './index'
import _ from 'lodash'
export enum SubClaimTypeEnum {
  CRITICAL_ILLNESS = 'CT0101',
  HOSPITAL_CASH = 'CT0301',
  REIMBURSEMENT = 'CT0302',
  DEATH = 'CT0201',
  TOTAL_PERMANENT_DISABILITY = 'CT0401'
}

export enum ClaimTypeEnum {
  CRITICAL_ILLNESS = 'CT0100',
  DEATH = 'CT0200',
  MEDICAL = 'CT0300',
  DISABILITY = 'CT0400'
}

interface Props {
  innerProps: {
    accidents: VerClaimType[] | null
    claimNumber: string | null
    claimTypes?: SubClaimType[] | null
    subClaimTypes?: SubClaimTypeBenefit[] | null
    tpdTypes?: VerClaimType[] | null
    ciTypes?: CriticalIllnessType[] | null
    icdTypes: ICDType[] | null
    hospitals: HospitalType[] | null
    form: UseFormReturn<ClaimInfoForm>
    detail: TaskDetail.VerificationClaim
  }
  disabled?: boolean
  isEditable?: boolean
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
}

export const ClaimTypeTab: React.FC<Props> = ({ innerProps, disabled, isEditable = true, setIsCanChangeTab }) => {
  const { accidents, claimNumber, tpdTypes, ciTypes, icdTypes, hospitals, form, detail } = innerProps

  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation(['claim', 'verificationClaim'])

  const register = Form.register(form)
  const { watch, control } = form
  const { claimType } = watch()
  const { lifeAssured, attributes } = detail?.detail?.payload.body
  const lifeAssuredList = [{ label: lifeAssured.name, value: lifeAssured.name }]
  const { source } = detail?.detail
  const dataSubClaimType = register('subClaimType')

  const listClaim = pipe(GeneralService.getClaims, ErrorHandling.runDidMount())

  const renderSubClaimType = () => {
    return (
      <View style={[style.col, { paddingEnd: 25, marginLeft: 50 }]}>
        <Text style={style.label}>{t('SubClaimType')}</Text>
        <Select
          {...dataSubClaimType}
          disabled
          options={
            detail?.subClaimType.map((res) => ({
              value: res.subClaimTypeCode,
              label: `${res.subClaimTypeCode} - ${i18n.language === 'en' ? res.subClaimType : res.subClaimType}`
            })) || []
          }
          showUnderline={false}
        />
      </View>
    )
  }

  const checkSurery = (): boolean => {
    if (detail.PATIENT_IN && detail.PATIENT_IN?.surgeryBenefit) {
      return true
    }
    return false
  }

  return (
    <View>
      <ScrollView>
        <Panel title="" isExand={false} isHiddenTitle={true} styling={style.sectionInfo}>
          <View>
            <View style={style.row}>
              <View style={style.col}>
                <Text style={style.label}>{t('ClaimNumber')}</Text>
                <Text style={style.value}>{claimNumber}</Text>
              </View>
              <View style={{ flex: 1 / 3, marginBottom: 32, flexDirection: 'row', marginStart: 30 }}>
                <Controller
                  name="directBilling"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      title={t('DirectBilling')}
                      value={value}
                      onBlur={onBlur}
                      disabled={form.watch('claimType.value') !== ClaimType.MEDICAL}
                      onChange={(e) => {
                        onChange(e)
                        setIsCanChangeTab(false)
                      }}
                    />
                  )}
                />
              </View>
            </View>
            <View style={style.row}>
              <View style={[isMobile ? { width: '100%' } : style.col]}>
                <Text style={style.label}>{t('ClaimType')}</Text>
                <Text style={style.value}>
                  {i18n.language === 'en'
                    ? listClaim?.find((item) => item?.claimCode === claimType?.value)?.claimType || ''
                    : listClaim?.find((item) => item?.claimCode === claimType?.value)?.claimTypeVN || ''}
                </Text>
              </View>
              {renderSubClaimType()}
            </View>
            <View style={style.row}>
              <View style={[style.col, { marginRight: 50 }]}>
                <Controller
                  name={'lifeAssuredName'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020009', { field: t('LifeAssuredName') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Select
                      options={lifeAssuredList}
                      onChange={onChange}
                      onBlur={onBlur}
                      required
                      label={t('LifeAssuredName')}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
              {source === SourceType.PULSE ? (
                <>
                  <View style={[style.col, { marginRight: 50 }]}>
                    <Text style={style.label}>{t('LifeAssuredID')}</Text>
                    <Text style={style.value}>{detail?.clientIdNumber ?? '-'}</Text>
                  </View>

                  <View style={style.col}>
                    <Text style={style.label}>{t('LifeAssuredDOB')}</Text>
                    <Text style={style.value}>
                      {detail?.clientDob ? moment(detail?.clientDob).format('DD/MM/YYYY') : '-'}
                    </Text>
                  </View>
                </>
              ) : (
                <>
                  <View style={[style.col, { marginRight: 50 }]}>
                    <Text style={style.label}>{t('LifeAssuredID')}</Text>
                    <Text style={style.value}>{lifeAssured?.externalIds?.SOE_VALUE ?? '-'}</Text>
                  </View>

                  <View style={style.col}>
                    <Text style={style.label}>{t('LifeAssuredDOB')}</Text>
                    <Text style={style.value}>
                      {lifeAssured?.dob ? moment(lifeAssured?.dob).format('DD/MM/YYYY') : '-'}
                    </Text>
                  </View>
                </>
              )}
            </View>

            <View style={style.row}>
              <View style={[style.col, { marginRight: 50 }]}>
                <Text style={style.label}>{t('ClaimantName')}</Text>
                <Text style={style.value}>{attributes?.CLAIM_ANT_NAME ?? '-'}</Text>
              </View>

              <View style={[style.col, { marginRight: 50 }]}>
                <Text style={style.label}>{t('ClaimantPhone')}</Text>
                <Text style={style.value}>{attributes?.CLAIM_ANT_PHONE ?? '-'}</Text>
              </View>

              <View style={style.col}>
                <Text style={style.label}>{t('ClaimantEmail')}</Text>
                <Text style={style.value}>{attributes?.CLAIM_ANT_EMAIL ?? '-'}</Text>
              </View>
            </View>

            <View style={style.row}>
              <View style={style.col}>
                <Text style={style.label}>{t('ClaimantAddress')}</Text>
                <Text style={style.value}>
                  {_.isEmpty(attributes?.CLAIM_ANT_ADDRESS) ? '-' : attributes?.CLAIM_ANT_ADDRESS ?? '-'}
                </Text>
              </View>
            </View>
          </View>
        </Panel>
        {claimType?.value === ClaimTypeEnum.MEDICAL && (
          <HospitalCash
            form={form}
            icdName={icdTypes}
            hospital={hospitals}
            isSurery={checkSurery()}
            detail={detail}
            disabled={disabled || !isEditable}
            setIsCanChangeTab={setIsCanChangeTab}
            accidents={accidents}
          />
        )}
        {claimType?.value === ClaimTypeEnum.DISABILITY && (
          <TotalPermanentDisability
            form={form}
            tpdTypes={tpdTypes}
            icdName={icdTypes}
            disabled={disabled || !isEditable}
            setIsCanChangeTab={setIsCanChangeTab}
            accidents={accidents}
          />
        )}
        {claimType?.value === ClaimTypeEnum.CRITICAL_ILLNESS && (
          <CriticalIllness
            form={form}
            ciTypes={ciTypes}
            icdName={icdTypes}
            accidents={accidents}
            disabled={disabled || !isEditable}
            setIsCanChangeTab={setIsCanChangeTab}
          />
        )}
        {claimType?.value === ClaimTypeEnum.DEATH && (
          <Death
            form={form}
            icdName={icdTypes}
            disabled={disabled || !isEditable}
            setIsCanChangeTab={setIsCanChangeTab}
            accidents={accidents}
          />
        )}
      </ScrollView>
    </View>
  )
}

const style = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
