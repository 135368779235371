import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace IHeatDataDetail {
  export const codec = t.type({
    callId: t.string,
    entryDate: Maybe(t.string),
    entryTime: Maybe(t.string),
    journalType: Maybe(t.string),
    tracker: Maybe(t.string),
    entryText: Maybe(t.string)
  })
}
export type IHeatDataDetail = t.TypeOf<typeof IHeatDataDetail.codec>
