import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  ErrorHandling,
  SpecialBonusService,
  SpecialBonusData,
  TransactionType,
  PulseOpsFormat,
  PayoutPopup,
  GeneralService,
  TaskType,
  StorageBlob
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestAuthenticateData } from '../../request-authen'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { SC } from './styles'
import _ from 'lodash'
import { PayoutMethod, PayoutMethodRef, mapCashOutOption } from '../../payout-method'
type Props = PolicyServiceProps<SpecialBonusData.SubmitData> & {}

export const SpecialBonus: React.FC<Props> = ({ initSubmission, policyNumber, isConfirmed, officeCode }) => {
  const { t } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [sumary, setSumary] = React.useState<PayoutPopup.Summary[]>([])

  //const { base: form } = form2.useForm(PayoutForm.codec)
  const ref = React.useRef<PayoutMethodRef | null>(null)

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(sumary),
      transactionType: TransactionType.SPECIAL_BONUS,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      let uploadedFiles: StorageBlob.FileContentSubmit[] = []
      if (sumary.length > 0 && totalPayoutAmount > 0 && totalPayoutAmount === specialBonus) {
        // if (!isContinue) {
        //   uploadedFiles = await pipe(
        //     GeneralService.getAzureStorageFiles(
        //       GeneralService.getPayoutDocuments(sumary),
        //       TransactionType.SPECIAL_BONUS,
        //       'DPS01',
        //       TaskType.PolicyService,
        //       policyNumber ?? '',
        //       officeCode ?? ''
        //     ),
        //     bindLoader,
        //     ZIO.unsafeRun({})
        //   )
        // }
        return {
          url: (policyNum) => `wf-api/policy/${policyNum}/special-bonus`,
          body: mapData(),
          collerationId: policyNumber || '',
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.SPECIAL_BONUS),
          transaction: TransactionType.SPECIAL_BONUS,
          documents: uploadedFiles,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      } else if (sumary.length === 0) {
        showToast(t('message:MS020001', { field: t('submission:PayoutMethod').toLowerCase() }), 'error')
        return false
      } else if (totalPayoutAmount === 0) {
        showToast(t('message:MS050241'), 'error')
        return false
      } else if (totalPayoutAmount !== specialBonus) {
        showToast(t('message:MS050170'), 'error')
        return false
      } else {
        return false
      }
    },
    clear: () => {
      //form.reset()
      ref.current?.clearData()
    }
  })

  const totalPayoutAmount = React.useMemo(() => sumary.reduce((prev, curr) => prev + Number(curr.amount), 0), [sumary])

  const mapData = (): SpecialBonusData.SubmitData => {
    return {
      policy: {
        policyNo: policyNumber!
      },
      attributesExt: {
        SPECIAL_BONUS: Number(specialBonus),
        TOTAL_PAYOUT_AMOUNT: totalPayoutAmount
      },
      cashOutOptions: mapCashOutOption(sumary)
    }
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:SPECIAL_BONUS'), navigate: null }
      ])
    }
    return () => {
      ref.current?.clearData()
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const specialBonus =
    pipe(
      SpecialBonusService.getDetail(policyNumber!),
      ZIO.map((detail) => {
        return detail.specialBonus
      }),
      bindLoader,
      ErrorHandling.runDidUpdate([policyNumber])
    ) || 0

  // const SpecialBonusInfoComponent = React.useMemo(() => {
  //   return (
  //     <SC.Container>
  //       <SC.Padding vertical={10}>
  //         <SC.SessionText>{t('submission:SpecialBonusInfo').toUpperCase()}</SC.SessionText>
  //       </SC.Padding>
  //       <SC.PanelContainer>
  //         <SC.TitleText>{t('submission:SpecialBonus')}</SC.TitleText>
  //         <SC.NormalText>{`${PulseOpsFormat.thousandSepartor(
  //           Number.isInteger(specialBonus) ? specialBonus : 0
  //         )} VND`}</SC.NormalText>
  //       </SC.PanelContainer>
  //     </SC.Container>
  //   )
  // }, [specialBonus])

  return (
    <SC.Container>
      <SC.Container>
        <SC.Padding vertical={10}>
          <SC.SessionText>{t('submission:SpecialBonusInfo').toUpperCase()}</SC.SessionText>
        </SC.Padding>
        <SC.PanelContainer>
          <SC.TitleText>{t('submission:SpecialBonus')}</SC.TitleText>
          <SC.NormalText>{`${PulseOpsFormat.thousandSepartor(
            Number.isInteger(specialBonus) ? specialBonus : 0
          )} VND`}</SC.NormalText>
        </SC.PanelContainer>
      </SC.Container>
      <PayoutMethod
        onChange={(value) => setSumary(value)}
        ref={ref}
        value={sumary}
        editable={!isConfirmed}
        policyNum={policyNumber!}
        maxAmount={specialBonus}
        methods={[
          PayoutPopup.PayoutMethods.PAYPREMIUM,
          PayoutPopup.PayoutMethods.PAYLOAN,
          PayoutPopup.PayoutMethods.OTHER,
          PayoutPopup.PayoutMethods.CASHLESS,
          PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
          PayoutPopup.PayoutMethods.PAID_AT_BANK,
          PayoutPopup.PayoutMethods.BANKTRANSFER,
          // PayoutPopup.PayoutMethods.MOMO
        ]}
        transactionType={TransactionType.SPECIAL_BONUS}
        officeCode={officeCode}
      />
    </SC.Container>
  )
}
