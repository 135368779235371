import { AppContext, RoundedButton, useMobile } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, useWindowDimensions, View } from 'react-native'
import { BusinessStackParamList } from './business-stack-param-list'

type Props = StackScreenProps<BusinessStackParamList, 'BusinessScreen'>

const RoundedButtonCustom = ({
  text,
  disable,
  onPress
}: {
  text: string
  disable?: boolean
  onPress?: () => void
  loading?: boolean
}) => {
  return (
    <RoundedButton
      disabled={disable}
      text={text}
      activeOpacity={0.7}
      style={[styles.btnRounded, { width: '100%', maxWidth: 400, marginBottom: 20 }]}
      textStyle={styles.textStyle}
      showBorder={true}
      textColorEnable="#ED1B2E"
      textColorDisable="gray"
      borderColorEnable="#ED1B2E"
      borderColorDisable="gray"
      onPress={onPress}
    />
  )
}

export const BusinessScreen = (props: Props) => {
  const { height } = useWindowDimensions()
  const { t } = useTranslation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { isMobile } = useMobile()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => props.navigation.replace('HomeScreen')
        },
        {
          title: t('menu:BackOffice'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  return (
    <View style={{ backgroundColor: '#FFF', height: height, flex: 1 }}>
      <View style={{ flexDirection: 'row', padding: 100 }}>
        <View style={{ width: isMobile ? '100%' : '33%' }}>
          <View style={{ flexDirection: 'column' }}>
            <RoundedButtonCustom
              text={t('business:ContractList')}
              onPress={() => props.navigation.navigate('ContractListScreen')}
            />
            <RoundedButtonCustom
              text={t('business:ClaimList')}
              onPress={() => props.navigation.navigate('ClaimListScreen')}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  btnRounded: {
    height: 46,
    borderRadius: 20
  }
})
