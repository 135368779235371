import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import i18next from 'i18next'
import { pipe } from 'fp-ts/lib/function'
import { NonEmptyString } from 'io-ts-types'

export type PolicyNumberBrand = {
  readonly PolicyNumber: unique symbol
}

export const validatePolicyNumber = (val: string) => /^[0-9]*$/.test(val) && (val.length === 8 || val.length === 9)

export const PolicyNumber = pipe(
  NonEmptyString,
  form2.refine(
    (val): val is t.Branded<NonEmptyString, PolicyNumberBrand> => validatePolicyNumber(val),
    () => i18next.t('message:MS090001'),
    'PolicyNumber'
  )
)
export namespace RequestListForm {
  const Base = t.type({
    policyNumber: pipe(
      form2.string.optional,
      form2.string.minLength(8, () => i18next.t('message:MS090001'))
    ),
    // policyNumber: pipe(
    //   form2.string.requiredM(() =>
    //     i18next.t('message:MS020001', {
    //       field: i18next.t('TaskManagement:policy')
    //     })
    //   ),
    //   form2.string.minLength(8, () => i18next.t('Số HĐ/HSYCBH gồm 8 hoặc 9 chữ số'))
    // ),
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('ServiceInquiry:FromDate')
      })
    ),
    toDate: form2.date.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('ServiceInquiry:ToDate')
      })
    ),
    status: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('TaskManagement:StatusPC')
      })
    )
  })

  export const codec = Base
  export type Validated = t.TypeOf<typeof codec>
  export type Raw = t.OutputOf<typeof codec>
}
