import React from 'react'
import { View, Text } from 'react-native'

export const OBReportScreen = () => {
  return (
    <View>
      <Text>OBReportScreen</Text>
    </View>
  )
}
