import * as React from 'react';
import { View, Text, TextInput, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { ControlProps } from './FormProps';
import { formatNumberWithComma, formatStringToDecimalNumber } from '@pulseops/common';
import debounce from 'lodash/debounce';

const numberRegExp = new RegExp('^[0-9]*$');

const formatInput = (inputType: 'text' | 'number' | 'money' = 'text', value: string, defaultValue: string = '') => {
  let output = defaultValue;
  if (value.length === 0) {
    return output;
  }

  if (inputType === 'number') {
    const isNumber = value.match(numberRegExp) !== null;
    output = isNumber ? value : defaultValue;
  } else if (inputType === 'money') {
    value = [...value].filter((item) => !isNaN(Number(item))).join('');
    const isNumber = value.match(numberRegExp) !== null;
    output = isNumber ? formatStringToDecimalNumber(value) : defaultValue;
  } else {
    output = value;
  }
  return output;
};

type Props = ControlProps<string> & {
  title?: string;
  containerStyle?: StyleProp<ViewStyle>;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  inputType?: 'text' | 'number' | 'money';
  moneyFormater?: (value: string, defaultValue?: string) => string;
  maxLength?: number;
  textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
  inputStyle?: StyleProp<TextStyle>;
  isFractionFormat?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const InputTable = (props: Props) => {
  const {
    containerStyle,
    value,
    errorMessage,
    placeholder,
    inputType = 'text',
    maxLength,
    disabled,
    title,
    textAlign,
    inputStyle,
    onChange,
    onBlur,
    onFocus,
    isFractionFormat,
  } = props;

  const [text, setText] = React.useState<string>(() => {
    return formatInput(inputType, value || '');
  });

  const handleChange = React.useCallback(
    debounce((newValue: string) => {
      setText(newValue);
      onChange?.(newValue);
    }, 500),
    [onChange]
  );

  const handleFocus = () => {
    onFocus?.();
  };

  const handleBlur = () => {
    onBlur?.();
  };

  React.useEffect(() => {
    if (!value) {
      setText('');
    } else {
      setText(formatInput(inputType, value));
    }
  }, [value, inputType]);

  return (
    <View style={containerStyle}>
      {!!title && (
        <View>
          <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, lineHeight: 20 }]}>{title}</Text>
        </View>
      )}
      <TextInput
        style={[
          {
            borderColor: disabled ? 'transparent' : '#D3DCE6',
            borderWidth: 1,
            height: 35,
            fontSize: 15,
            borderRadius: 8,
            padding: 5,
            backgroundColor: disabled ? 'transparent' : '#FFF',
            textAlign: textAlign,
          },
          inputStyle,
        ]}
        value={
          inputType === 'money' && !isFractionFormat
            ? formatNumberWithComma(text !== value ? value ?? '' : text)
            : text
        }
        onChangeText={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        editable={!disabled}
        maxLength={maxLength}
        placeholder={placeholder}
      />
      {errorMessage ? <Text style={{ color: '#ED2B1C', fontSize: 11.25 }}>{errorMessage}</Text> : null}
    </View>
  );
};