import { enumC } from '@mxt/zio/codec'

export enum Frequency {
  Annual = '01',
  HalfYearly = '02',
  Quarterly = '04',
  Monthly = '12'
}
export const FrequencyC = enumC(Frequency)

export const mapFrequency = new Map<Frequency, string>([
  [Frequency.Annual, 'Annual'],
  [Frequency.HalfYearly, 'Half Yearly'],
  [Frequency.Quarterly, 'Quarterly'],
  [Frequency.Monthly, 'Monthly']
])
