import { PulseOpsFormat } from '../../../Formatter'
import { Customer } from '../Client'
import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface ChangeDOBGender {
  tag: TransactionType.CHANGE_DOB_GENDER
  source: SourceType
  clientName: string
  data: {
    clientNum: string
    dob: string
    gender: string
    isCheckChangeDOBGroup: boolean
    isCheckChangeGenderGroup: boolean
  }
}

export const ChangeDOBGender = (detail: api.ChangeDOBGender, customer: Customer): ChangeDOBGender => {
  const { transactionType, source, payload } = detail

  const { clientNum, dob, gender, isCheckChangeDOBGroup, isCheckChangeGenderGroup } = payload.body.attributesExt ?? {}

  const {
    body: { name }
  } = customer

  switch (source) {
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS:
      return {
        tag: transactionType,
        source: source,
        clientName: name,
        data: {
          clientNum: clientNum ?? '-',
          dob: PulseOpsFormat.dateWF(dob ?? '', 'YYYYMMDD', 'DD/MM/YYYY') ?? '-',
          gender: gender ?? '-',
          isCheckChangeDOBGroup: isCheckChangeDOBGroup ?? false,
          isCheckChangeGenderGroup: isCheckChangeGenderGroup ?? false
        }
      }
  }
}
