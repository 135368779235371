import { ErrorHandling, GeneralService, Label, Locale, Panel, SourceType, TaskDetail } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList } from '../common'
import { DuplicateInfoItem, DuplicateInfoTable } from './common'
import { Address } from './mock'

type DataSource = {
  label: string | number | boolean | JSX.Element | TFunctionResult
  value: string | number | boolean | JSX.Element
  suffix?: string | number | boolean | JSX.Element
  isHighlight?: boolean
}

export const ChangeContactInfoPhone = ({ detail }: { detail?: TaskDetail.ChangePhoneInfo }) => {
  const { t, i18n } = useTranslation('requestInfo')

  const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []

  const getRelationshipFromT16s1 = (code: string) => {
    const found = relationshipT16s1.find((item) => item.code === code)
    return {
      en: found?.relationshipEN || '',
      vi: found?.relationshipVN || ''
    }
  }

  const getEmailIndicator = (emailInd: string): string => {
    const indicator = Address.EmailIndicator.find((e) => e.value === emailInd)
    return indicator ? (i18n.language === Locale.en ? indicator.labelEN : indicator.labelVI) : '-'
  }
  let data: DataSource[] = []
  if (detail?.detail.source === SourceType.PULSE4OPS || detail?.detail.source === SourceType.PRUONLINE || detail?.detail.source === SourceType.OUTBOUND || detail?.detail.source === SourceType.INBOUND) {
    data = [
      {
        label: t('MobilePhone1'),
        value: detail?.detail.phoneNumber || '-'
      },
      {
        label: t('CompanyPhone'),
        value: detail?.detail.officePhone || '-'
      },
      {
        label: t('SMSIndicator'),
        value: getEmailIndicator(detail?.detail?.sms_Ind) || '-'
      },
      {
        label: t('requestInfo:Verification'),
        value: detail.attributesExt?.mainPhoneNumber ? t('requestInfo:OwnerSubscriptionDocument') as string : t('requestInfo:OTPDocument', { code: detail.otpTransaction?.otp, phone: detail.otpTransaction?.receiverPhone }) as string,
      }
    ]
  } else if (detail?.detail.source === SourceType.PULSE) {
    data = [
      {
        label: t('MobilePhone1'),
        value: detail?.detail.phoneNumber || '-'
      }
    ]
  }

  const phoneDups: DuplicateInfoItem[] = (detail?.attributesExt?.PHONES_DUP || []).map((item) => ({
    clientName: item.clientName,
    clientNumber: item.clientId,
    relationship: getRelationshipFromT16s1(item.veriRelationship?.code || '')
  }))

  return (
    <Panel title={t('ChangePhoneNumber')} isExand={false}>
      <InfoViewList dataSource={data} />
      {phoneDups.length > 0 && (
        <>
          <Label title={t('requestInfo:dupPhone')} />
          <View style={{ marginTop: 6 }}>
            <DuplicateInfoTable data={phoneDups} />
          </View>
        </>
      )}
    </Panel>
  )
}
