import { Policy } from './../Client'
import { Frequency } from '../OpEformDetailApi/BillingChange'
import * as eformApi from './../OpEformDetailApi'

export const mapFrequency = new Map<Frequency, string>([
  [Frequency.Annual, 'Năm'],
  [Frequency.HalfYearly, 'Nửa năm'],
  [Frequency.Quarterly, 'Quý'],
  [Frequency.Monthly, 'Tháng']
])

export interface EformBillingChange {
  curBillingFrequency: string // Định kỳ nộp phí bảo hiểm hiện tại
  curInstallmentPremium: string // Phí bảo hiểm định kỳ hiện tại
  newBillingFrequency: Frequency | null // Định kỳ nộp phí bảo hiểm mới
  newInstallmentPremium: string // phi bh dinh ky moi
  suspenseAmount: string //phi du
  advancePremium: string // phi bh nop trc
  effectiveDateNewFrequency: string // ngay hieu luc cua dinh ky moi
  requirePayinAmount: string // so tien can nop
}

export const EformBillingChange = (detail: eformApi.EFormBillingChange, policy: Policy): EformBillingChange => {
  const newInstallmentPremium = 0

  const contractDay = policy.body.contractDate?.split('-')[2] ?? ''
  const contractMonth = policy.body.contractDate?.split('-')[1] ?? ''
  const effectiveDateNewFrequency = `${contractDay}/${contractMonth}/${new Date().getFullYear()}`

  return {
    curBillingFrequency: detail.body.curBillingFrequency ? mapFrequency.get(detail.body.curBillingFrequency) ?? '' : '',
    curInstallmentPremium: detail.body.curRenewalPremium ?? '0',
    newBillingFrequency: Frequency.Annual,
    newInstallmentPremium: '', //ContractDate --> policy/id --> BE chờ Las (a Hoàng)
    suspenseAmount: detail.body.suspendAmount ?? '0',
    advancePremium: detail.body.premiumAdv ?? '0',
    effectiveDateNewFrequency: effectiveDateNewFrequency,
    requirePayinAmount: (
      newInstallmentPremium -
      (parseInt(detail?.body?.suspendAmount ?? '0') + parseInt(detail.body.premiumAdv ?? '0'))
    ).toString()
  }
}
