import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, View } from 'react-native'
import { IBGeneralTable, IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { IBService, PaymentAllocationData } from '../../../ib-service'
import { isNull } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'

interface Props {
  paymentAllocationData: PaymentAllocationData
  setPaymentAllocationData: (data: PaymentAllocationData) => void
  claimNumber: string
}

export const IBPaymentAllocation = ({ paymentAllocationData, setPaymentAllocationData, claimNumber }: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if(claimNumber) {
      pipe(
        IBService.getPaymentAllocation(claimNumber),
        ZIO.map((res) => {
          res && setPaymentAllocationData(res.map((i) => ({
            ...i,
            firstInstallmentDate: i.firstInstallmentDate ? moment(i.firstInstallmentDate, 'DD/MM/YYYY').format('YYYYMMDD') : ''
          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])


  const dataTable = [
    {
      label: t('Payee'),
      field: 'payeeName'
    },
    {
      label: t('PayPercent'),
      field: 'paymentPercentage'
    },
    {
      label: t('PayAmt'),
      field: 'paymentAmount',
      format: 'money'
    },
    {
      label: t('PayMode'),
      field: 'paymentMode'
    },
    {
      label: t('BankCode'),
      field: 'bankCode'
    },
    {
      label: t('Account'),
      field: 'account'
    },
    {
      label: t('Schedule'),
      field: 'scheduleOption',
      render: (value: string) => {
        return value === 'L' ? 'Lumpsum' : value === 'I' ? 'Instalment' : ''
      }
    },
    {
      label: t('FirstInst'),
      field: 'firstInstallmentDate',
      format: 'date'
    },
    {
      label: t('Frep'),
      field: 'installmentFrequency',
      format: 'money'
    },
    {
      label: t('TotalInstNo'),
      field: 'totalInstallmentNumber',
    },
    {
      label: t('InstAmt'),
      field: 'installmentAmount',
      format: 'money'
    },
    {
      label: t('Address'),
      field: 'postAddress'
    }
  ]

  return (
    <View>
      {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <IBGeneralTable
          dataTable={dataTable}
          data={paymentAllocationData}
          loading={loading}
        >
        </IBGeneralTable>
      )}
    </View>
  )
}