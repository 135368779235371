import DateFnsUtils from '@date-io/date-fns'
import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { YearMonthPickerProps } from './YearPicker'
import { assets } from '../../assets'

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

const useStyles = makeStyles({
  root: {
    '& input::placeholder': {
      color: '#70777E',
      opacity: 1
    }
  }
})

export const YearMonthPicker = (props: YearMonthPickerProps) => {
  // const [selectedDate, handleDateChange] = useState<Date | null>(null)
  const { hideUnderline = false } = props

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.required && (
          <Text style={styles.label}>
            {props.label}
            <Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>{' '}
          </Text>
        )}
        {props.alwaysShow && <Text style={styles.label}>{props.label}</Text>}
        <View style={{ flex: 1 }}>
          <KeyboardDatePicker
            className={useStyles().root}
            clearable
            openTo="year"
            views={['year', 'month']}
            label={props.required || props.alwaysShow ? '' : props.label}
            keyboardIcon={<assets.InputCalendar />}
            placeholder="mm/yyyy"
            format="MM/yyyy"
            disabled={props.disabled}
            value={props.value || null}
            onChange={(date) => {
              // handleDateChange(date)
              if (props.onChange) {
                props.onChange(date)
              }
            }}
            onBlur={props.onBlur}
            InputProps={{
              disableUnderline: true,
              style: {
                minHeight: 33
              }
            }}
          />
          {!hideUnderline && <View style={styles.underline} />}
          {props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
        </View>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 2
  },

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  }
})
