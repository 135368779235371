import * as React from 'react'
import { Text, TextInput as RNTextInput, TextInputProps } from 'react-native'
import { ControlProps } from '@pulseops/common'
import { Label } from './Label'

interface Props extends ControlProps<string> {
  placeholder?: string
  title?: string
  disabled?: boolean
  required?: boolean
  errorMessage?: string
  textInputProps?: TextInputProps
}
export const TextInput = ({
  title,
  placeholder,
  disabled,
  required,
  value,
  errorMessage,
  textInputProps = {},
  onChange,
  onBlur
}: Props) => {
  return (
    <>
      <Label title={title || ''} required={required} />
      <RNTextInput
        {...textInputProps}
        editable={!disabled}
        placeholder={placeholder}
        value={value}
        onChangeText={onChange}
        onBlur={onBlur}
      />
      {errorMessage ? <Text>{errorMessage}</Text> : null}
    </>
  )
}
