import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface PaidUpModal {
  tag: TransactionType.PAID_UP
  clientName: string
  detail: api.PaidUp
}

export const PaidUp = (detail: api.PaidUp, poName: string): PaidUpModal => ({
  tag: TransactionType.PAID_UP,
  clientName: poName ?? '',
  detail
})
