import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
export namespace CertApi {
  export const SearchElement = t.type({
    id: Maybe(t.string),
    fullName: Maybe(t.string),
    dob: Maybe(t.string),
    sex: Maybe(t.string),
    idNumber: Maybe(t.string),
    ciNumber: Maybe(t.string),
    examCode: Maybe(t.string),
    examDate: Maybe(t.string),
    examPlace: Maybe(t.string),
    mofNumber: Maybe(t.string),
    mofDate: Maybe(t.string),
    certificateNumber: Maybe(t.string),
    certificateDate: Maybe(t.string),
    validflag: Maybe(t.number),
    userImport: Maybe(t.string),
    importDate: Maybe(t.number)
  })

  export type SearchElement = t.TypeOf<typeof SearchElement>

  export const QueryData = t.type({
    // maxRecordPerPage: Maybe(t.number),
    // pageToGet: Maybe(t.number),
    // propertySort: Maybe(t.string),
    // directionSort: Maybe(t.string),
    fromDate: Maybe(t.string),
    toDate: Maybe(t.string),
    ciNumber: Maybe(t.string),
    idNumber: Maybe(t.string)
  })
  export type QueryData = t.TypeOf<typeof QueryData>
  export const ReportDataC = t.type({
    body: Nullable(t.string)
  })

  export type ReportDataC = t.TypeOf<typeof ReportDataC>
  export const exportCert = (query: QueryData) =>
    pipe(
      POApi.post(`distribution-agents-service/certificate/export`)(ReportDataC)(query),
      ZIO.map((res) => res)
    )
  export const searchCert = (data: any) => {
    return pipe(
      POApi.post('distribution-agents-service/certificate/search')(
        t.type({
          body: t.type({
            currentPage: t.number,
            elements: Maybe(t.array(SearchElement)),
            pageSize: t.number,
            totalElement: t.number,
            totalPage: t.number
          })
        })
      )(data),
      ZIO.map((res) => res)
    )
  }
}
