import { ZIO } from '@mxt/zio'
import moment from 'moment'
import {
  AppConfig,
  AppContext,
  changeLanguage,
  Checkbox,
  ErrorHandling,
  form2,
  PulseOpsFormat,
  useMobile,
  assets as assetsCommon,
  SelectOption,
  RadioButtonGroup,
  FullSurrenderReversalService,
  CircelInput
} from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  SafeAreaView,
  ImageBackground,
  Pressable,
  Linking,
  ScrollView,
  View,
  StyleSheet,
  ActivityIndicator,
  Modal,
  Text,
  Image
} from 'react-native'
import {
  assets,
  BorderFilledInput,
  Button,
  ButtonBack,
  ButtonModal,
  FormControl,
  SC,
  SC as SCore,
  Services
} from './core'
import { LandingPageIDCInfoStackParamList } from './LandingPageIDCStackParamList'
import { Header } from './Header'
import { withMaxStringLength } from './validators'
import OtpInput from 'react-otp-input'
import styled from 'styled-components'
import { useLoading } from '@mxt/zio-react'
import _ from 'lodash'
import { Maybe } from '@mxt/zio/codec'
import { FormControlLabel } from '@material-ui/core'
import { CircelInputLanding } from './core/components/circle-input-landing'
import { CheckboxIDC } from './core/components/checkbox-idc'

enum CheckboxValue {
  CONFIRM = 'Y',
  NO = 'N'
}
enum DecisionResult {
  PASSS = 'PASS',
  FAIL = 'FAIL'
}

enum StatusIDC {
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED'
}

const FormC = t.type({
  proposalNum: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:proposalNumberIDC') })),
    // withMinStringLength(9, 'Vui lòng nhập số hồ sơ 9 kí số.'),
    withMaxStringLength(9, 'Vui lòng nhập số hồ sơ 9 kí số.')
  ),
  id: pipe(
    form2.string.requiredM(() => i18next.t('message:MS020001', { field: i18next.t('landingPage:id') })),
    // withMinStringLength(8, 'message:MS090008'),
    withMaxStringLength(50, 'Vui lòng nhập số CMND/ CCCD/ Hộ chiếu')
  ),
  quota: Maybe(
    t.array(
      t.type({
        questionCode: t.string,
        questionName: t.string,
        commentUser: t.string,
        passCheck: t.string,
        checkedValue: t.string
      })
    )
  ),
  captchaValue: form2.string.optional
})

type Props = StackScreenProps<LandingPageIDCInfoStackParamList, 'HomeScreen'>

const TIMEOUT = 30
const RESEND_TIME = 3

export const HomeScreen = (props: Props) => {
  const { t } = useTranslation('landingPage')
  const { t: m } = useTranslation('message')

  const { isWide } = useMobile()

  const [loading, setLoading] = React.useState(false)
  const [otp, setOtp] = React.useState<string>('')
  const [time, setTime] = React.useState<number>(TIMEOUT)
  const [errorOtp, setErrorOtp] = React.useState<string | null>(null)
  const [submitting, bindSubmitting] = useLoading(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [otpTime, setOtpTime] = React.useState<number>(0)
  const [showQuota, setShowQuota] = React.useState<boolean>(false)
  const [showError, setShowError] = React.useState<boolean>(false)
  const [reviewCheck, setReviewCheck] = React.useState<boolean>(false)
  const [confirmStep, setConfirm] = React.useState<boolean>(false)
  const [otpStep, setOtpStep] = React.useState<boolean>(false)
  const [successStep, setSuccessStep] = React.useState<boolean>(false)
  const [proposalInfo, setProposalInfo] = React.useState<{
    phoneNumber: string
    processInstanceId: string
  } | null>(null)
  const [messageSubmitted, setMessageSubmitted] = React.useState<string>('Quý khách đã cung cấp thông tin thành công')
  const [expiredTime, setExpiredTime] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const reCaptchaKey = pipe(
    AppConfig.get,
    ZIO.map((_) => _.reCaptchaKey),
    ErrorHandling.runDidMount()
  )
  const { processInstanceId, id } = props.route.params || {}

  const processId = processInstanceId || id || ''
  const {
    base: {
      control,
      formState: { errors },
      setValue,
      watch,
      setError
    },
    handleSubmit
  } = form2.useForm(FormC, {
    defaultValues: {
      quota: []
    }
  })
  const onSumit = handleSubmit((validated) => {
    return pipe(
      Services.checkProposal({
        processInstanceId: processId,
        proposalNumber: validated.proposalNum,
        idNumber: validated.id
      }),
      ZIO.tap((res) => {
        //@ts-ignore
        if(res?.credential?.error === "400") {
          if(res?.credential?.error_code == '017'){
            setErrorMessage("Tình trạng hồ sơ không thỏa Kiểm tra độc lập. Vui lòng liên hệ Tư vấn viên để được hỗ trợ thêm")
          } else {
            setErrorMessage('Thông tin chưa đúng')
          }
          setLoading(false)
          return ZIO.unit
        }
        if (res.status === StatusIDC.COMPLETED) {
          let m =
            'Quý khách đã gửi phản hồi nội dung KIỂM TRA ĐỘC LẬP thành công vào ngày ' +
            PulseOpsFormat.dateStringtoFormat(res.endTime, 'DD/MM/YYYY, HH:mm:ss')
          setMessageSubmitted(m)
          setSuccessStep(true)
          return ZIO.unit
        } else if (res.status === StatusIDC.EXPIRED) {
          setExpiredTime(true)
          return ZIO.unit
        }
        setProposalInfo({
          phoneNumber: res?.phoneNumber ?? '',
          processInstanceId: res?.processInstanceId ?? ''
        })
        return ZIO.effect(() => {
          getQuotaQuestion(res?.processInstanceId ?? '')
        })
      }),
      ZIO.tapError((err) => {
        setErrorMessage('Thông tin chưa đúng')
        // console.log(err, 'err')
        // setError('id', {
        //   message: 'Thông tin chưa đúng'
        // })

        // setError('proposalNum', {
        //   message: 'Thông tin chưa đúng'
        // })
        setLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  })

  React.useEffect(() => {
    changeLanguage('vi')
    localStorage.setItem('locale', 'vi-VN')
  }, [])

  const onChange = (value: string | null) => {
    setValue('captchaValue', value)
  }

  const convertMinute = (seconds: number): string => {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2)
    //const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60

    return [minutes, seconds % 60].map(format).join(':')
  }

  const resendHandle = () => {
    setErrorOtp(null)
    if (time === 0) {
      sendOtp()
    }
  }

  const sendOtp = () => {
    setOtp('')
    setTime(TIMEOUT)
    setOtpTime(otpTime + 1)

    onConfirm()
  }
  React.useEffect(() => {
    let timer: number | false
    if (otpStep) {
      //@ts-ignore
      timer = time > 0 && setInterval(() => setTime(time - 1), 1000)
    } else {
      setTime(TIMEOUT)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [time, otpStep])

  const getQuotaQuestion = (processInstanceId: string) => {
    return pipe(
      Services.getQuotaList(processInstanceId),
      ZIO.tapError((e) => {
        console.log(e)
        setLoading(false)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        setLoading(false)
        const data = res?.body?.map((item) => ({
          questionCode: item.questionCode,
          questionName: item.questionName,
          commentUser: '',
          checkedValue: '',
          passCheck: item.passCheck
        }))
        setValue('quota', data)
        setShowQuota(true)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showToast(
          'Something went wrong',
          'error',
          {
            horizontal: 'right',
            vertical: 'top'
          },
          15000
        )
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }
  // console.log(watch('quota'), 'quota')

  const onValidateQuota = () => {
    const list = watch('quota')

    const error =
      list?.filter(
        (item: { checkedValue: string; passCheck: string; commentUser: string }) =>
          item.checkedValue !== item.passCheck && item.commentUser === ''
      ) ?? []

    if (error?.length > 0) {
      return setShowError(true)
    } else {
      setShowError(false)
    }

    setReviewCheck(true)
  }
  const renderTitle = React.useMemo(() => {
    if (showQuota && !reviewCheck) {
      return (
        <Text
          style={{
            fontSize: 20,
            color: '#FFFFFF',
            fontWeight: '500',
            textAlign: 'center'
          }}
        >
          Quý khách vui lòng cung cấp các thông tin sau:
        </Text>
      )
    } else if (reviewCheck && !otpStep) {
      return (
        <Text
          style={{
            fontSize: 20,
            color: '#FFFFFF',
            fontWeight: '500',
            textAlign: 'center'
          }}
        >
          Xác nhận thông tin
        </Text>
      )
    } else if (otpStep) {
      return (
        <Text
          style={{
            fontSize: 20,
            color: '#FFFFFF',
            fontWeight: '500',
            textAlign: 'center'
          }}
        >
          Quý khách vui lòng nhập OTP được gửi đến số điện thoại {proposalInfo?.phoneNumber ?? ''}
        </Text>
      )
    } else if (!showQuota) {
      return (
        <>
          <Text
            // @ts-ignore
            style={{
              fontSize: 20,
              color: '#FFFFFF',
              fontWeight: '700',
              // lineHeight: '42px',
              textAlign: 'center',
              paddingHorizontal: 10
            }}
          >
            Cảm ơn Quý khách đã tin tưởng lựa chọn Prudential để tham gia sản phẩm bảo hiểm tài chính bảo vệ bản thân và
            gia đình. Nhằm đảm bảo chất lượng quá trình tư vấn bán hàng, Quý khách vui lòng phản hồi nội dung KIỂM TRA
            ĐỘC LẬP để chúng tôi có đủ căn cứ tiếp tục xử lý hồ sơ yêu cầu bảo hiểm của Quý khách.
          </Text>
        </>
      )
    }
    return null
  }, [showQuota, otpStep, reviewCheck, expiredTime])

  const renderContent = () => {
    if (successStep || expiredTime) {
      return (
        <View
          style={{
            paddingVertical: '24px',
            paddingHorizontal: '35px',
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16
          }}
        >
          <View style={{ padding: 45, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            {expiredTime ? <assets.ClockIcon /> : <assets.EnvelopeIcon />}
            {expiredTime ? (
              <View>
                <Text style={{ paddingTop: 10, fontSize: 18, fontWeight: '500', textAlign: 'center' }}>
                  Đã quá thời hạn phản hồi nội dung kiểm tra độc lập.
                </Text>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                  <Text style={{ fontSize: 18, fontWeight: '500', }}>Vui lòng liên hệ Tổng đài </Text>
                  <Text style={{ fontSize: 18, fontWeight: '500', color: '#EF3E51' }}>1800 1247 </Text>
                  <Text style={{ fontSize: 18, fontWeight: '500', }}>để được hỗ trợ thêm.</Text>
                </View>
              </View>
            ) : (
              <Text style={{ paddingTop: 10, fontSize: 18, fontWeight: '500', textAlign: 'center' }}>
                {messageSubmitted}
              </Text>
            )}
          </View>
        </View>
      )
    } else if ((showQuota || reviewCheck) && !otpStep) {
      return (
        <View
          style={{
            paddingVertical: '24px',
            paddingHorizontal: '35px',
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16
          }}
        >
          <ScrollView
            style={{
              height: 400,
              paddingRight: 15
            }}
            persistentScrollbar={true}
          >
            <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontSize: 14, fontWeight: '700', color: '#27272A', marginRight: 30 }}>Có</Text>
              <Text style={{ fontSize: 14, fontWeight: '700', color: '#27272A' }}>Không</Text>
            </View>

            {watch('quota')?.map((item, i) => {
              return (
                <View
                  key={i}
                  style={{
                    borderWidth: 1,
                    borderColor: '#EEEEEE',
                    borderRadius: 16,
                    paddingVertical: 10,
                    paddingLeft: 10,
                    marginVertical: 10
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'space-between',
                      flexDirection: 'row'
                    }}
                  >
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                      <Text style={{ fontSize: 14, fontWeight: '500', color: '#27272A' }}>{item.questionName}</Text>
                    </View>
                    <View style={{ marginLeft: 10 }}>
                      <Controller
                        name={`quota.${i}.checkedValue`}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                          return (
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                              <CheckboxIDC
                                checkedIcon="checkbox-marked"
                                uncheckedIcon="checkbox-blank-outline"
                                value={value === CheckboxValue.CONFIRM ? true : false}
                                onChange={() => {
                                  setValue(`quota.${i}.commentUser`, '')
                                  onChange(CheckboxValue.CONFIRM)
                                }}
                                disabled={reviewCheck}
                              />
                              <CheckboxIDC
                                checkedIcon="checkbox-marked"
                                uncheckedIcon="checkbox-blank-outline"
                                value={value === CheckboxValue.NO ? true : false}
                                onChange={() => {
                                  setValue(`quota.${i}.commentUser`, '')
                                  onChange(CheckboxValue.NO)
                                }}
                                disabled={reviewCheck}
                              />
                            </View>
                          )
                        }}
                      />
                    </View>
                  </View>
                  {watch(`quota.${i}.checkedValue`) !== '' &&
                  watch(`quota.${i}.checkedValue`) !== watch(`quota.${i}.passCheck`) ? (
                    <View style={{ marginTop: 10, paddingRight: 10 }}>
                      <Controller
                        name={`quota.${i}.commentUser`}
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <CircelInputLanding
                            value={value ?? undefined}
                            inputType={'text'}
                            onChange={onChange}
                            placeholder={t('PlaceholderIdc')}
                            errorMessage={error?.message}
                            inputStyle={[
                              error?.message ? { borderColor: '#ED1B2E', borderWidth: 1, borderStyle: 'solid' } : {}
                            ]}
                            labelStyle={{ color: '#27272A', fontWeight: '500', fontSize: 18, paddingBottom: 5 }}
                            required={false}
                            checkAuto={true}
                            disabled={reviewCheck}
                            maxLength={128}
                          />
                        )}
                      />
                    </View>
                  ) : null}
                </View>
              )
            })}
          </ScrollView>
          {reviewCheck ? (
            <View
              style={{
                marginTop: 20
              }}
            >
              <View>
                <FormControlLabel
                  control={
                    <CheckboxIDC
                      checkedIcon="checkbox-marked"
                      uncheckedIcon="checkbox-blank-outline"
                      onChange={() => {
                        setConfirm((pre) => !pre)
                      }}
                      value={confirmStep}
                    />
                  }
                  label={
                    <Text style={{fontWeight: '500'}}>
                      Tôi xác nhận đã tự mình đọc và hiểu nội dung các vấn đề nêu trên và tự mình thực hiện các câu trả
                      lời. Tôi đồng ý cho phép PVA sử dụng các thông tin mà tôi cung cấp bên trên để làm cơ sở cho việc
                      kiểm tra độc lập và thẩm định phát hành hợp đồng theo quy định của Luật Kinh doanh Bảo Hiểm.
                    </Text>
                  }
                />
              </View>
              <View
                style={{
                  marginTop: 20
                }}
              >
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <ButtonBack
                    style={{ width: '140px' }}
                    title={t('btnBack')}
                    onPress={() => {
                      setConfirm(false)
                      setReviewCheck(false)
                    }}
                  />
                  <ButtonModal
                    style={{ width: '180px', marginLeft: 15 }}
                    title={t('confirm')}
                    onPress={onConfirm}
                    loading={loading}
                    disabled={!confirmStep}
                  />
                </View>
              </View>
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: isWide ? 'center' : 'flex-end',
                justifyContent: isWide ? 'space-between' : 'flex-end',
                flexDirection: isWide ? 'row' : 'column',
                marginTop: 20
              }}
            >
              <View>
                {showError ? (
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Text><assets.ErrorIcon /></Text>
                    <Text style={{ color: '#ED1B2E', fontSize: 14 }}> Vui lòng điền đầy đủ thông tin</Text>
                  </View>
                ) : (
                  ''
                )}
              </View>
              <View style={{ paddingTop: isWide ? 0 : 10 }}>
                <ButtonModal
                  style={{ width: '180px', marginLeft: 15 }}
                  title={t('countinue')}
                  onPress={onValidateQuota}
                  loading={loading}
                />
              </View>
            </View>
          )}
        </View>
      )
    } else if (otpStep) {
      return (
        <View
          style={{
            paddingVertical: '24px',
            paddingHorizontal: '35px',
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16
          }}
        >
          <View style={{ padding: 45 }}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              separator={<View style={{ width: 15 }} />}
              isDisabled={submitting}
              inputStyle={{
                width: isWide ? 44 : 35,
                height: isWide ? 44 : 35,
                fontSize: 15,
                borderRadius: 20,
                background: '#FFFFFF',
                borderWidth: 1,
                color: '#70777E',
                borderColor: '#AAAAAA'
              }}
              containerStyle={{
                alignSelf: 'center'
              }}
              placeholder="------"
            />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 15,
                justifyContent: 'center'
              }}
            >
              <Pressable onPress={resendHandle} disabled={time !== 0 || otpTime >= RESEND_TIME}>
                {/* disabled={time !== 0 || otpTime > RESEND_TIME} */}
                <Text
                  style={{
                    color: time !== 0 || otpTime >= RESEND_TIME ? 'lightgray' : 'black',
                    textDecorationLine: time !== 0 || otpTime >= RESEND_TIME ? 'none' : 'underline',
                    fontWeight: '700'
                  }}
                >
                  Gửi lại OTP
                </Text>
              </Pressable>
              <Text> {time !== 0 ? `sau ${convertMinute(time)}` : null}</Text>
            </View>
          </View>
          <View>
            <View>
              {errorOtp ? (
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text><assets.ErrorIcon /></Text>
                  <Text style={{ color: '#ED1B2E', fontSize: 14, marginLeft: 2 }}> {errorOtp}</Text>
                </View>
              ) : (
                ''
              )}
            </View>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: isWide ? 'center' : 'flex-end',
              justifyContent: 'flex-end',
              flexDirection: isWide ? 'row' : 'column',
              marginTop: 20
            }}
          >
            <ButtonModal
              style={{ width: '180px', marginLeft: 15 }}
              title={t('confirm')}
              onPress={verifyOTPFinal}
              loading={loading}
              disabled={otp.length !== 6}
            />
          </View>
        </View>
      )
    } else if (!showQuota) {
      return (
        <View
          style={{
            paddingVertical: '24px',
            paddingHorizontal: '35px',
            backgroundColor: '#FFFFFF',
            borderBottomLeftRadius: 16,
            borderBottomRightRadius: 16
          }}
        >
          <View style={{ paddingVertical: '15px' }}>
            <Controller
              name={'proposalNum'}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <BorderFilledInput
                  isDigit
                  controlStyle={{ width: '100%' }}
                  label={`${t('landingPage:policyNumber')}*`}
                  value={value || ''}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  onBlur={onBlur}
                  title={`${t('proposal_idc')}`}
                  labelStyle={{ color: '#27272A', fontWeight: '500', fontSize: 18, paddingBottom: 5 }}
                  inputStyle={[error?.message || errorMessage !== '' ? { borderColor: '#ED1B2E', borderWidth: 1, borderStyle: 'solid' } : {}]}
                  maxLength={9}
                  required={false}
                  // errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={{ paddingVertical: '15px' }}>
            <Controller
              name={'id'}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <CircelInputLanding
                  value={value ?? undefined}
                  inputType={'text'}
                  onChange={(val) => {
                    const pattern = /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=?_ ]*$/g
                    if (pattern.test(val)) {
                      onChange(val)
                    }
                  }}
                  title={`${t('id_idc')}`}
                  // errorMessage={error?.message}
                  inputStyle={[error?.message || errorMessage !== '' ? { borderColor: '#ED1B2E', borderWidth: 1, borderStyle: 'solid' } : {}]}
                  labelStyle={{ color: '#27272A', fontWeight: '500', fontSize: 18, paddingBottom: 5 }}
                  required={false}
                  maxLength={50}
                  checkAuto={false}
                />
              )}
            />
          </View>
          {
            errorMessage != '' ? <View>
            <Text style={{color: 'red'}}>{errorMessage}</Text>
          </View> : null }
          <View style={{ paddingVertical: '10px' }}>
            {reCaptchaKey && <ReCAPTCHA sitekey={reCaptchaKey} hl="vi" onChange={onChange} />}
          </View>
          <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <ButtonModal
              style={{ width: '180px' }}
              title={t('confirm')}
              onPress={() => {
                onSumit()
                setLoading(true)
              }}
              loading={loading}
              disabled={!watch('captchaValue')}
            />
          </View>
        </View>
      )
    }
    return null
  }

  const onConfirm = () => {
    if (!confirmStep) return
    setLoading(true)
    return pipe(
      Services.otpRequest({
        body: {
          processInstanceId: proposalInfo?.processInstanceId ?? '',
          proposalNumber: watch('proposalNum') ?? '',
          transactionCode: 'LDP',
          idNumber: watch('id') ?? '',
          sourceOtp: 'LANDING_PAGE'
        }
      }),
      ZIO.tapError((e) => {
        console.log(e)
        setLoading(false)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        let message = ''
        if (res?.responseStatus?.errors?.[0].code) {
          message = getErrorMessage(res?.responseStatus?.errors?.[0].code ?? '') ?? ''
        }
        setErrorOtp(message)
        setOtpStep(true)
        setLoading(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        let message = ''
        //@ts-ignore
        if (error?.source?.response?.status === 400) {
          //@ts-ignore
          message = getErrorMessage(error?.source?.response?.data?.responseStatus?.errors?.[0].code ?? '') ?? ''
          //@ts-ignore
        }

        return ZIO.effect(() => {
          setOtpStep(true)
          setLoading(false)
          setErrorOtp(message)
        })

        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const verifyOTPFinal = () => {
    setErrorOtp(null)
    setLoading(true)
    const answers = watch('quota')
    const answerData =
      answers?.map((item) => ({
        questionCode: item.questionCode,
        passCheck: item.passCheck,
        commentUser: item.commentUser,
        decisionResult: item.checkedValue === item.passCheck ? DecisionResult.PASSS : DecisionResult.FAIL,
        questionContent: item.questionName,
        answerUser: item.checkedValue
      })) ?? []

    return pipe(
      Services.saveQuestion({
        body: {
          processInstanceId: proposalInfo?.processInstanceId ?? '',
          proposalNumber: watch('proposalNum'),
          answerResults: answerData,
          otp: otp
        }
      }),
      ZIO.tapError((e) => {
        console.log(e)
        setLoading(false)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        setLoading(false)
        let message = ''
        if (res.responseStatus?.errors?.[0].code) {
          message = getErrorMessage(res.responseStatus?.errors?.[0].code ?? '') ?? ''
          setErrorOtp(message)
        } else {
          setSuccessStep(true)
        }

        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        setLoading(false)
        console.log(error, 'error')
        let message = ''
        //@ts-ignore
        if (error?.source?.response?.status === 400) {
          //@ts-ignore
          message = getErrorMessage(error?.source?.response?.data?.responseStatus?.errors?.[0].code ?? '') ?? ''
          //@ts-ignore
        }

        return ZIO.effect(() => {
          setLoading(false)
          setErrorOtp(message)
        })
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }
  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'MSG01001':
        return t('message:MSG01001')

      case 'MSG01002':
        return t('message:MSG01002')

      case 'MSG01003':
        return t('message:MSG01003')

      case 'MSG01004':
        return t('message:MSG01004')

      case 'MSG01005':
        return t('message:MSG01005')

      case 'MSG01006':
        return t('message:MSG01006')

      default:
        //@ts-ignore
        return error?.source?.response?.data?.responseStatus?.errors?.[0].message
    }
  }
  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#e5e5e5' }}>
      <Header isWide={isWide} t={t} />
      <ScrollView style={{ flex: 1 }}>
        <View style={{ position: 'relative', paddingBottom: 100 }}>
          <Image
            style={{
              width: '100%',
              height: 275,
              resizeMode: 'cover'
            }}
            source={assets.BGImage}
          ></Image>
          <View
            // @ts-ignore
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
              width: isWide ? 900 : '100%',
              top: 220,
              borderRadius: '16px',
              overflow: 'hidden',
              paddingBottom: 50,
              boxShadow: '0px 60px 113px -130px #0000001A'
            }}
          >
            {!successStep && !expiredTime ? <View style={{ backgroundColor: '#EF3E51', paddingVertical: 24 }}>{renderTitle}</View> : ''}
            {renderContent()}
          </View>
        </View>
      </ScrollView>

      <View style={[styles.footer]}>
        <View style={{ flex: 1, flexDirection: isWide ? 'row' : 'column', width: 1220, justifyContent: 'center' }}>
          <Text style={{ marginLeft: isWide ? 0 : 10, fontSize: 18, fontWeight: '500', paddingTop: 10 }}>
            {t('supportFooterIDC')}
          </Text>
          <View style={{ paddingVertical: isWide ? 0 : 6, paddingHorizontal: isWide ? 20 : 0 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#FFFFFF',
                borderRadius: 48,
                paddingVertical: 8,
                paddingHorizontal: 16,
                alignItems: 'center'
              }}
            >
              <assets.PhoneIcon />
              <Text style={{ fontWeight: '500', marginLeft: 10 }}>Tổng đài </Text>{' '}
              <Text style={{ fontWeight: '500', color: '#ED1B2E' }}>18001247</Text>{' '}
              <Text style={{ fontWeight: '500' }}> (miễn cước)</Text>
            </View>
          </View>
          <View style={{ paddingVertical: isWide ? 0 : 6 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#FFFFFF',
                borderRadius: 48,
                paddingVertical: 8,
                paddingHorizontal: 16,
                alignItems: 'center'
              }}
            >
              <assets.MailIcon />
              <Text style={{ color: '#ED1B2E', fontWeight: '500', marginLeft: 10 }}>
                customer.service@prudential.com.vn
              </Text>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}
const Core = {
  Container: styled(View)`
    flex: 1;
    background-color: #f9f9f9;
    align-self: center;
  `,
  ScrollContainer: styled(ScrollView)`
    flex: 1;
    background: #f7f7f7;
  `,
  Card: styled(View)`
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  `,
  HeaderItem: styled(View)`
    margin-bottom: 4px;
    flex-direction: row;
  `,
  NormalText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: ${(p) => (p.isWide ? '20' : '18')}px;
  `,
  HighLightText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '24' : '20')}px;
    color: #ed1b2e;
  `,
  CardBorder: styled(View)<{ isWide?: boolean }>`
    border: 1px solid #d3dce6;
    background: #fff;
    padding: ${(p) => (p.isWide ? '30px 40px' : '20px')};
    margin-top: ${(p) => (p.isWide ? '30' : '0')}px;
    border-radius: 8px;
    box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
  `,
  SessionText: styled(Text)<{ isWide?: boolean }>`
    margin-bottom: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '32' : '24')}px;
  `,
  TitleText: styled(Text)<{ isWide?: boolean }>`
    color: #000000;
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: ${(p) => (p.isWide ? '18' : '16')}px;
    margin-bottom: 5px;
  `,
  ItemInfoContainer: styled(View)<{ marginBottom?: string }>`
    margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : '40')}px;
    display: flex;
    flex-direction: row;
  `,
  SendText: styled(Text)<{ disabled?: boolean }>`
    text-align: center;
    color: red;
    opacity: ${(p) => (p.disabled ? 0.6 : 1)};
    font-size: 15;
    font-weight: 600;
    margin-top: 40px;
  `,
  HelperText: styled(Text)`
    text-align: flex-start;
    color: #e53935;
    font-size: 13px;
    margin-top: 2px;
  `,
  Icon: styled(View)`
    margin-right: 31px;
  `
}
const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#F0F0F0',
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 10,
    justifyContent: 'space-evenly'
  },
  errorText: {
    textAlign: 'left',
    color: '#e53935',
    fontSize: 13,
    marginTop: 2
  },
  sendText: {
    textAlign: 'center',
    color: '#ed1b2e',
    fontSize: 15,
    fontWeight: 600,
    marginTop: 40
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginHorizontal: 50
  },
  col_45: {
    width: '100%',
    maxWidth: '100%'
  }
})
