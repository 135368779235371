// import { form2 } from '@pulseops/common'
// import i18next from 'i18next'
// import * as t from 'io-ts'

import { FileUploadData, SelectOption } from '@pulseops/common'

export namespace RedatingFormData {
  export interface Redating {
    LAList: LifeAssuredInfo[]
    agentCode: string
    agentName: string
    healthDeclaration: FileUploadData[]
  }

  export interface LifeAssuredInfo {
    idNumber: string
    clientName: string
    hAnswer: boolean
    oAnswer: boolean
    aAnswer: boolean
    cAnswer: boolean
    // healthDeclaration?: string
    curOccupation: string
    curOccupationID: string
    newOccupation?: SelectOption
    newActivity?: string
    newCompany?: string
  }
}
