import { Panel, TaskDetail, TransactionType } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList, Title } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element
  suffix?: string
  isHighlight?: boolean
}

export const LoanStatements = ({ detail }: { detail: TaskDetail.VeriLoanStatements }) => {
  const { t } = useTranslation(['requestInfo'])

  const convertTransactionTypeToName = (type: any) => {
    switch (type) {
      case TransactionType.LOAN_STATEMENTS:
        return t('LoanStatements')
      default:
        return ''
    }
  }

  let data: Output[] = []

  data = [
    {
      label: t('DocumentType'),
      value: convertTransactionTypeToName(detail?.tag)
    },
    {
      label: t('FromDate'),
      value: detail.fromDate
    },
    {
      label: t('ToDate'),
      value: detail?.toDate
    }
  ]

  return (
    <>
      <Title title={t('LetterRequest')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList dataSource={data} />
      </Panel>
    </>
  )
}
