/* eslint-disable prefer-const */
import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React, { useContext } from 'react'
import {
  AppConfig,
  AppContext,
  AuthService,
  ErrorHandling,
  FileUploadData,
  IBPolicyPermission,
  Input,
  ModalComponent,
  PulseOpsApi,
  PulseOpsFormat,
  PulseOpsService,
  assets
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { IBButton, IBGeneralField, IBGeneralTable, IBGridDataView, TypeInputComponent } from '../../common'
import { useForm } from 'react-hook-form'
import { ClickToSendEmailForm, FileUpload } from '../../ib-client/ib-click-to-send-email'
import { isEmpty, isNil, orderBy } from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { ClickToSendCaseDetailData, IBService } from '../../ib-service'
import { ZIO } from '@mxt/zio'
import { EmailManagementContext } from '../IBEmailManagementContext'
import { TaskCategory, TaskDecision } from '../common'
import moment from 'moment'
import { Backdrop, Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core'

interface Props {
  visible: boolean
  onClose: (value: boolean) => void
  objectModal?: { policyNumber: string; processInstanceId: string; taskId: string }
}

const IBModalCancel = (
  props: Props & {
    onAction: (taskDecistion: TaskDecision, comment: string) => void
  }
) => {
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, onAction } = props
  const { width, height } = useWindowDimensions()
  const [reasonText, setReasonText] = React.useState<string>('')

  const onCancel = () => {
    onAction(TaskDecision.CANCEL, reasonText)
    onClose(!visible)
  }
  return (
    <ModalComponent
      title={t('CancelConfirmation').toUpperCase()}
      titleStyle={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700', textAlign: 'center' }}
      visible={visible}
      modalWidth={width * 0.4}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 10, marginTop: 0 }}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <Input
          required={true}
          title={t('Reason')}
          value={reasonText}
          maxLength={1000}
          inputType={'text'}
          onChange={(value) => {
            setReasonText(value)
          }}
          multiline={true}
          numberOfLines={3}
          placeholder={t('common:Input')}
        />
        <View style={[styles.btnBar, { marginTop: 15, justifyContent: 'flex-end' }]}>
          <IBButton
            onPress={() => {
              onClose(!visible)
            }}
            title={t('Exit')}
            backgroundFill={false}
            widthDiv={'20%'}
            containerStyle={{ marginRight: 15 }}
          />
          <IBButton
            onPress={onCancel}
            title={t('CancelEmail')}
            backgroundFill
            widthDiv={'35%'}
            disabled={isEmpty(reasonText)}
          />
        </View>
      </View>
    </ModalComponent>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: '16px 10px 24px',
      width: '70%',
      height: '90%',
      overflow: 'auto',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column'
    }
  })
)

export const IBModalListingOfReturned = (
  props: Props & {
    flagSuccess: boolean
    setFlagSuccess: (flag: boolean) => void
  }
) => {
  const { t, i18n } = useTranslation('Inbound')
  const classes = useStyles()
  const { visible, onClose, objectModal, flagSuccess, setFlagSuccess } = props
  const [openModalCancel, setOpenModalCancel] = React.useState<boolean>(false)
  const { width, height } = useWindowDimensions()
  const [loading, bindLoading] = useLoading(false)
  const [loadingLoadFile, bindLoadingLoadFile] = useLoading(false)
  const isFocused = useIsFocused()
  const { masterDataList, permissions } = useContext(EmailManagementContext)
  const [originalData, setOriginalData] = React.useState<ClickToSendCaseDetailData>()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [comment, setComment] = React.useState<string>('')
  const [listImgDefaultTemplate, setListImgDefaultTemplate] = React.useState<{ name: string; blob: string }[]>([])
  const [listImgInsertContent, setListImgInsertContent] = React.useState<{ file: FileUpload; blobUrl: string }[]>([])
  const [imgInsertCur, setImgInsertCur] = React.useState<{ file: FileUpload; blobUrl: string }>()
  const form = useForm<ClickToSendEmailForm>({
    mode: 'onChange',
    defaultValues: {
      attachmentFiles: []
    }
  })

  const getFileByUrl = (
    url: string,
    name: string,
    params: { updatedDate: string; createdDate: string },
    filename: string
  ) => {
    const container = url.slice(url.lastIndexOf('/') + 1)
    const apiUrl = url.slice(0, url.lastIndexOf('/'))
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        let reader = new FileReader()
        const updateDate = params?.updatedDate
          ? moment(params?.updatedDate).toDate() ?? new Date()
          : params?.createdDate
          ? moment(params?.createdDate).toDate() ?? new Date()
          : new Date()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          const base64String = reader.result
          const blobFile = base64String ? new Blob([base64String]) : null
          let fileUpload = new File(blobFile ? [blobFile] : [], filename ?? '')
          const lastDotIndex = filename.lastIndexOf('.')
          const fileExtention = filename.substring(lastDotIndex + 1, filename.length)
          let file: FileUploadData = {
            fileName: filename.substring(0, lastDotIndex) ?? '',
            fileExtension: fileExtention === 'msg' ? 'msg' : blob.type.split('/')[1] ?? blob.type,
            size: blob.size,
            base64: base64String ? base64String.toString() : '',
            uploadedDate: updateDate,
            file: fileUpload
          }
          let fileList = form.watch('attachmentFiles')
          base64String && fileList.push(file)
          if (fileList.length > 0 && base64String) {
            form.setValue('attachmentFiles', fileList)
          }
        }
      })
    )
  }

  const getImgByUrl = (name: string, content: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, AppConfig.get),
      ZIO.flatMap(([token, loginType, cf]) => {
        const currVersion = cf.version.split('.').join('-') || '1-0-0'
        const url = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
        const container = url.slice(url.lastIndexOf('/') + 1)
        const apiUrl = url.slice(0, url.lastIndexOf('/'))
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const splitName = name.split('/')
        return { name: splitName[splitName.length - 1].split('.')[0], blob: window.URL.createObjectURL(blob) }
      })
    )
  }

  const loadOriginData = (res: ClickToSendCaseDetailData) => {
    pipe(
      ZIO.sequence(
        (res.payload?.attachDocuments || []).map((item) =>
          getFileByUrl(
            item.url as string,
            item.type as string,
            {
              updatedDate: res.lastUpdatedDate || '',
              createdDate: res.startTime || ''
            },
            item.filename
          )
        )
      ),
      bindLoadingLoadFile,
      ZIO.unsafeRun({})
    )
    const group = masterDataList.a14s0s.find((x) => x.code === res.payload?.mailGroupCode)
    const type = masterDataList.a14s1s.find((x) => x.code === res.payload?.requestTypeCode)
    form.setValue('policyNumber', { label: res.policyNumber || '', value: res.policyNumber || '' })
    form.setValue('policyOwner', res.policyOwnerName || '')
    form.setValue('group', {
      value: group?.code || '',
      label: i18n.language === 'vi' ? group?.nameVi || '-' : group?.nameEn || '-'
    })
    form.setValue('type', {
      value: type?.code || '',
      label: i18n.language === 'vi' ? type?.nameVi || '-' : type?.nameEn || '-'
    })
    form.setValue('note', res.payload?.note || '')
    form.setValue('sendTo', res.payload?.emailTo?.reduce((pre, cur) => (pre += `${cur};`), '') || '')
    form.setValue('BCC', res.payload?.emailBCC?.reduce((pre, cur) => (pre += `${cur};`), '') || '')
    form.setValue('subject', res.payload?.subject || '')
    if (res.payload?.content) {
      if (!isEmpty(res.payload?.content.match(/alt="(.*?)"/g)) && !isNil(res.payload?.content.match(/alt="(.*?)"/g))) {
        const arrayImg = res.payload?.content.match(/alt="(.*?)"/g)?.map((match) => match.match(/alt="(.*?)"/)[1])
        pipe(
          ZIO.sequence((arrayImg || []).map((url) => getImgByUrl(url, res.payload?.content || ''))),
          ZIO.map((imgs) => {
            setListImgDefaultTemplate(imgs)
            const insertImgToContent = imgs.reduce(
              (pre, cur) => pre?.replace(`{${cur.name}}`, cur.blob),
              res.payload?.content
            )
            form.setValue('content', insertImgToContent || '')
            form.setValue('contentOriginal', insertImgToContent || '')
          }),
          ZIO.unsafeRun({})
        )
      } else {
        form.setValue('contentOriginal', res.payload?.content)
        form.setValue('content', res.payload?.content)
      }
    }
  }

  const clearStateOnClose = () => {
    setImgInsertCur(undefined)
    setListImgInsertContent([])
    setListImgDefaultTemplate([])
    setOriginalData(undefined)
    setComment('')
    form.reset()
    onClose(!visible)
  }

  React.useEffect(() => {
    if (objectModal)
      pipe(
        ZIO.zipPar(
          IBService.getClickToSendCaseDetail(objectModal?.processInstanceId || ''),
          PulseOpsApi.getCommentList(objectModal?.processInstanceId || '')
        ),
        ZIO.map(([res, comments]) => {
          if (!isEmpty(comments)) {
            setComment(comments[0].message)
          }
          setOriginalData(res)
          loadOriginData(res)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
  }, [isFocused, objectModal])

  function capitalizeFirstLetters(str: string) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const handleLoadingTemplate = (code: string) => {
    const codeTemplate = masterDataList.a14s1s.find((x) => x.code === code)
    pipe(
      IBService.getA14S4(codeTemplate?.templateCodeVi || ''),
      ZIO.map((template) => {
        if (!isEmpty(template)) {
          setListImgInsertContent([])
          const content =
            template[0].htmlTemplate
              ?.replace(/{policyOwnerName}/g, capitalizeFirstLetters(form.watch().policyOwner.toLowerCase()))
              .replace(/{policyNo}/g, form.watch().policyNumber.value) || ''
          form.setValue(
            'subject',
            template[0].subject
              ?.replace(/{policyOwnerName}/g, capitalizeFirstLetters(form.watch().policyOwner.toLowerCase()))
              .replace(/{policyNo}/g, form.watch().policyNumber.value) || ''
          )
          form.clearErrors('subject')

          if (!isEmpty(content.match(/alt="(.*?)"/g)) && !isNil(content.match(/alt="(.*?)"/g))) {
            const arrayImg = content.match(/alt="(.*?)"/g)?.map((match) => match.match(/alt="(.*?)"/)[1])
            pipe(
              ZIO.sequence((arrayImg || []).map((url) => getImgByUrl(url, content))),
              ZIO.map((imgs) => {
                setListImgDefaultTemplate(imgs)
                const insertImgToContent = imgs.reduce((pre, cur) => pre.replace(`{${cur.name}}`, cur.blob), content)
                form.setValue('content', insertImgToContent)
                form.setValue('contentOriginal', insertImgToContent)
              }),
              ZIO.unsafeRun({})
            )
          } else {
            form.setValue('contentOriginal', content)
            form.setValue('content', content)
          }
        }
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const onBehaviorButtonCheckValid = (buttonType: TaskDecision) =>
    form.handleSubmit((value) => {
      switch (buttonType) {
        case TaskDecision.COMPLETE:
          onAction(TaskDecision.COMPLETE)
          break
        case TaskDecision.CANCEL:
          setOpenModalCancel(true)
          break
        default:
          break
      }
    })

  const onAction = (taskDecistion: TaskDecision, comment?: string) => {
    const valueForm = form.watch()
    const listOriginalAttachFiles = originalData?.payload?.attachDocuments?.map((docs) => docs.filename)
    const listAttachFilesNew = valueForm.attachmentFiles.filter((x) => !listOriginalAttachFiles?.includes(x.file.name))
    const filterImgInsertContent = listImgInsertContent.filter((x) => form.watch().content.includes(x.blobUrl))

    pipe(
      ZIO.sequence(
        (filterImgInsertContent
          ? [...filterImgInsertContent.map((x) => x.file), ...listAttachFilesNew]
          : listAttachFilesNew
        ).map(({ file }) => {
          return IBService.importFileClickToSend(file, originalData?.policyNumber as string)
        })
      ),
      ZIO.flatMap((docs) => {
        return pipe(
          AppConfig.get,
          ZIO.flatMap((cf) => {
            const currVersion = cf.version.split('.').join('-') || '1-0-0'
            const apiUrl = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
            let documentUrls = docs.map((file) => ({ ...file, url: apiUrl }))
            const emailTo =
              valueForm.sendTo.charAt(valueForm.sendTo.length - 1) === ';'
                ? valueForm.sendTo.slice(0, -1)
                : valueForm.sendTo
            const emailBCC =
              valueForm.BCC.charAt(valueForm.BCC.length - 1) === ';' ? valueForm.BCC.slice(0, -1) : valueForm.BCC

            let content = valueForm.content
            content = listImgDefaultTemplate.reduce((pre, cur) => pre.replace(`${cur.blob}`, `{${cur.name}}`), content)
            const getDocTypes = docs.map((x) => x.type)

            const imgInserts = filterImgInsertContent.map((item) => {
              const getDoc = getDocTypes.find((x) => item.file.file.name === x.split('/')[2].split('_')[1])
              documentUrls = documentUrls.filter((x) => x.type !== getDoc)
              return {
                fileName: (getDoc as string).split('/')[2] || '',
                blobUrl: item.blobUrl,
                docsUrl: getDoc
              }
            })
            content = imgInserts.reduce(
              (pre, cur) =>
                pre
                  .replace(`{${cur.fileName.split('_')[1]}}`, cur.docsUrl || '')
                  .replace(`${cur.blobUrl}`, `{${cur.fileName.split('.')[0]}}`),
              content
            )

            const filterOriginalData = originalData?.payload?.attachDocuments?.filter((x) =>
              valueForm.attachmentFiles.find((file) => file.file.name === x.filename)
            )
            return IBService.postCompleteTaskClickToSend({
              processInstanceId: originalData?.processInstanceId || '',
              taskId: objectModal?.taskId || '',
              decision: taskDecistion,
              taskCategory: TaskCategory.RECHECK_EMAIL,
              categoryCode: 'IB',
              comment: comment || '',
              payload: {
                ...originalData?.payload,
                mailGroupCode: valueForm.group.value,
                requestTypeCode: valueForm.type.value,
                note: valueForm.note,
                emailTo: emailTo
                  .split(';')
                  .filter((x) => x)
                  .map((z) => z.trim()),
                emailBCC: emailBCC
                  .split(';')
                  .filter((x) => x)
                  .map((z) => z.trim()),
                subject: valueForm.subject,
                content: content,
                attachDocuments: [...(filterOriginalData || []), ...documentUrls]
              }
            })
          })
        )
      }),
      ZIO.map((res) => {
        if ([TaskDecision.COMPLETE].includes(taskDecistion)) {
          showToast(t('message:IB0018'), 'success')
        } else {
          showToast(t('message:IB0014'), 'success')
        }
        setFlagSuccess(true)
        clearStateOnClose()
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        console.log(error)
        showToast(t('message:IB0013'), 'error')
        return ZIO.succeed(null)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    if (imgInsertCur) setListImgInsertContent([...listImgInsertContent, imgInsertCur])
  }, [imgInsertCur])

  return (
    <Modal
      open={visible}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      children={
        <>
          <Fade in={visible}>
            <div className={classes.paper}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 10,
                  paddingHorizontal: 15
                }}
              >
                <Text style={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700' }}>
                  {t('EmailInformation').toUpperCase()}
                </Text>
                <Pressable onPress={() => clearStateOnClose()}>
                  <assets.CloseTaskModalIcon />
                </Pressable>
              </View>
              <ScrollView>
                <View style={{ margin: 15 }}>
                  <IBGridDataView data={[{ label: t('ReasonReturn'), value: comment }]} col={3} />
                </View>
                <View style={styles.boxContainer}>
                  <Text style={{ fontSize: 16, fontWeight: '700', color: '#27272A', marginBottom: 10 }}>
                    {t('EmailDetail')}
                  </Text>
                  <IBGeneralField
                    FieldForm={form}
                    col={2}
                    typeInput={[
                      {
                        type: TypeInputComponent.SELECT,
                        formName: 'policyNumber',
                        title: t('common:PolicyNumber'),
                        option: [],
                        rules: {
                          validate: () => {
                            return isNil(form.watch('policyNumber'))
                              ? `${t('message:MS020009', { field: t('common:PolicyNumber') })}`
                              : true
                          }
                        },
                        disabled: true,
                        required: true
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'policyOwner',
                        title: t('OwnerName'),
                        inputType: 'input',
                        disabled: true,
                        inputStyle: { color: 'rgba(0, 0, 0, 0.38)' }
                      },
                      {
                        type: TypeInputComponent.SELECT,
                        formName: 'group',
                        title: t('Group'),
                        option:
                          masterDataList.a14s0s.map((item) => ({
                            value: item.code || '',
                            label: i18n.language === 'vi' ? item.nameVi || '-' : item.nameEn || '-'
                          })) || [],
                        required: true,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('group')) ? `${t('message:MS020009', { field: t('Group') })}` : true
                          }
                        },
                        handleData: (value) => {
                          if (value) {
                            form.setValue('type', { label: '', value: '' })
                            form.setValue('subject', '')
                            form.setValue('content', '')
                            form.setValue('attachmentFiles', [])
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.SELECT,
                        formName: 'type',
                        title: t('Type'),
                        option: form.watch().group
                          ? masterDataList.a14s1s
                              .filter((x) => x.groupCode === (form.watch().group.value || ''))
                              .map((item) => ({
                                value: item.code || '',
                                label: i18n.language === 'vi' ? item.nameVi || '-' : item.nameEn || '-'
                              }))
                          : [],
                        rules: {
                          validate: () => {
                            return isNil(form.watch('type')) ? `${t('message:MS020009', { field: t('Type') })}` : true
                          }
                        },
                        required: true,
                        handleData: (value) => {
                          if (value) {
                            handleLoadingTemplate(value.value)
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'note',
                        title: t('Note'),
                        inputType: 'input',
                        expandRow: 2,
                        multiline: true,
                        numberOfLines: 3,
                        maxLength: 1000
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'sendTo',
                        title: t('To'),
                        inputType: 'input',
                        required: true,
                        rules: {
                          validate: () => {
                            if (isNil(form.watch('sendTo')) || isEmpty(form.watch('sendTo'))) {
                              return `${t('message:MS020001', { field: t('To') })}`
                            } else {
                              const sendTo = form.watch('sendTo')
                              const sendToSplit = (
                                sendTo.charAt(sendTo.length - 1) === ';' ? sendTo.slice(0, -1) : sendTo
                              )
                                .split(';')
                                .filter((x) => x)
                                .map((z) => z.trim())
                              const toCheckEmpty = sendToSplit.filter((x) => x)
                              if (toCheckEmpty.length === 0) {
                                return `${t('message:MS020001', { field: t('To') })}`
                              }
                              const toCheckValid = sendToSplit.some(
                                (x) => !x.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                              )
                              if (toCheckValid) {
                                return `${t('message:IB0010')}`
                              }
                              return true
                            }
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'BCC',
                        title: t('Bcc'),
                        inputType: 'input',
                        rules: {
                          validate: () => {
                            if (isNil(form.watch('BCC')) || isEmpty(form.watch('BCC'))) {
                              return true
                            } else {
                              const BCC = form.watch('BCC')
                              const BCCSplit = (BCC.charAt(BCC.length - 1) === ';' ? BCC.slice(0, -1) : BCC)
                                .split(';')
                                .filter((x) => x)
                                .map((z) => z.trim())

                              const toCheckValid = BCCSplit.some(
                                (x) => !x.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                              )
                              if (toCheckValid) {
                                return `${t('message:IB0010')}`
                              }
                              return true
                            }
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'subject',
                        title: t('SubjectEmail'),
                        inputType: 'input',
                        required: true,
                        maxLength: 200,
                        expandRow: 2,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('subject')) || isEmpty(form.watch('subject'))
                              ? `${t('message:MS020001', { field: t('SubjectEmail') })}`
                              : true
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.CKEDITOR,
                        formName: 'content',
                        config: {
                          fontSize_sizes:
                            '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt',
                          resize_enabled: true,
                          language: i18n.language,
                          extraAllowedContent: 'dl dt dd mark',
                          allowedContent: true,
                          extraPlugins: 'image3,colorbutton,font',
                          removePlugins: 'image,elementspath,sourcearea,sourcedialog,codemirror',
                          versionCheck: false
                        },
                        expandRow: 2,
                        title: t('Content'),
                        required: true,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('content')) || isEmpty(form.watch('content'))
                              ? `${t('message:MS020001', { field: t('Content') })}`
                              : true
                          }
                        },
                        handleUploadFiles: (file: FileUpload, blobUrl: string) => {
                          setImgInsertCur({ file, blobUrl })
                        }
                      },
                      {
                        type: TypeInputComponent.IMPORT,
                        formName: 'attachmentFiles',
                        title: t('requestInfo:Attachment'),
                        width: '100%',
                        maxSizeFile: 20,
                        maxTotalFile: 20,
                        messageMaxTotalSize: 'message:IB0016',
                        messageMaxSize: 'message:IB0015',
                        // showFullFileName: true,
                        messageFormat: 'Inbound:ImageFormat20MB',
                        // formatFileNameVi: true,
                        messageValidExtensions: 'message:IB0017'
                      }
                    ]}
                  />
                </View>
                <View style={styles.btnBar}>
                  <IBButton
                    onPress={() => {
                      if (originalData) {
                        form.setValue('attachmentFiles', [])
                        loadOriginData(originalData)
                      }
                    }}
                    title={t('ReturnToOrigin')}
                    backgroundFill={false}
                    widthDiv={'30%'}
                    disabled={
                      !permissions.includes(IBPolicyPermission.IBReturnOriginalEmailReturnedEmailDetail) ||
                      loadingLoadFile
                    }
                  />
                  <IBButton
                    onPress={onBehaviorButtonCheckValid(TaskDecision.CANCEL)}
                    title={t('Cancel')}
                    backgroundFill={false}
                    widthDiv={'30%'}
                    disabled={!permissions.includes(IBPolicyPermission.IBCancelReturnedEmailDetail) || loadingLoadFile}
                  />
                  <IBButton
                    onPress={onBehaviorButtonCheckValid(TaskDecision.COMPLETE)}
                    title={t('Submit')}
                    backgroundFill
                    widthDiv={'30%'}
                    disabled={!permissions.includes(IBPolicyPermission.IBSubmitReturnedEmailDetail) || loadingLoadFile}
                  />
                </View>
                {loading ? (
                  <View style={styles.loadingBlur}>
                    <ActivityIndicator size="large" color="red" />
                  </View>
                ) : (
                  <></>
                )}
              </ScrollView>
              <IBModalCancel visible={openModalCancel} onClose={setOpenModalCancel} onAction={onAction} />
            </div>
          </Fade>
        </>
      }
    ></Modal>
  )
}

const styles = StyleSheet.create({
  boxContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    padding: 20,
    flex: 1,
    flexDirection: 'column'
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    justifyContent: 'space-evenly'
  },
  loadingBlur: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  }
})
