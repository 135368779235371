/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  FieldList,
  FileUploadData,
  form2,
  ImgUploadMutiple,
  Input,
  Panel,
  useMobile,
  assets,
  TransactionType,
  PulseOpsFormat,
  SelectSearch,
  getLanguage,
  AppContext,
  formatNumberWithComma,
  Checkbox
} from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { InfoAgentProps, SC, SubmissionAgentService, UploadedFilesInfo } from '../common'
import { PolicyServicePayload } from '../AgentWrapScreen'
import { AgentTerminationForm } from './agentTerminationForm'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { AgentConst } from '../common'
import { Link } from '@material-ui/core'

export interface AgentRef {
  clearForm: () => void
  submit: () => void
}

interface Props {
  isContinue: (val: boolean) => void
  isTab: boolean
  onCheckValid: (value: number) => void
  dataForm: (data: PolicyServicePayload) => void
  agentNum: string
  setErrMessage: (value: string) => void
  officeCode: string
}

export const AgentTerminationScreen = forwardRef<AgentRef, Props>(
  ({ isContinue, isTab, onCheckValid, dataForm, agentNum, officeCode, setErrMessage }, ref: React.Ref<AgentRef>) => {
    const { isMobile } = useMobile()
    const { t } = useTranslation()
    const [isLoading, bindLoader] = useLoading(false)
    const { showToast } = React.useContext(AppContext.AppContextInstance)
    const [dataGet, setDataGet] = useState<SubmissionAgentService.TerminationDetail>()

    const form = form2.useForm(AgentTerminationForm.codec, {
      defaultValues: {
        AgentCode: '',
        TerminationReason: undefined,
        dataPayload: {
          agent: dataGet
        },
        agentContent: []
      }
    })

    const {
      handleSubmit,
      // trigger,
      base: {
        control,
        reset,
        getValues,
        watch,
        setValue,
        formState: { errors }
      }
    } = form

    const agentN = watch('AgentCode')

    const checkTerminateDate = (terDate: string) => {
      if (terDate && terDate !== '') {
        return true
      } else {
        return false
      }
    }

    const agentInfoList: InfoAgentProps[] = [
      {
        label: t('submission:TerminationDate'),
        value: dataGet?.terminateDate ?? '-',
        isHighlight: checkTerminateDate(dataGet?.terminateDate ?? '')
      },
      {
        label: t('submission:AgentType'),
        value: dataGet?.agentType ?? '-'
      },
      {
        label: t('submission:Title'),
        value: dataGet?.title ?? '-'
      },
      {
        label: t('submission:UnitDes'),
        value: dataGet?.unitDescription ?? '-'
      },
      {
        label: t('submission:BranchDes'),
        value: dataGet?.branchDescription ?? '-'
      },
      {
        label: t('submission:OfficeDes'),
        value: dataGet?.officeDescription ?? '-'
      },
      {
        label: t('submission:RegionBDM'),
        value: dataGet?.regionDescription ?? '-'
      },
      {
        label: t('submission:SubzoneBDD'),
        value: dataGet?.subzoneDescription ?? '-'
      },
      {
        label: t('submission:ZoneCodeTH'),
        value: dataGet?.zoneDescription ?? '-'
      }
    ]
    const checkAppointedDate = () => {
      const appDate = PulseOpsFormat.stringToDate(dataGet?.appointedDate)?.getTime()
      const newDate = PulseOpsFormat.stringToDate('01/01/2019')?.getTime()
      return appDate && newDate && appDate < newDate
    }

    const CheckedSignatureView = (value: boolean) => {
      return (
        <SC.Padding>
          {/* <SC.Divider /> */}
          <SC.Row>
            <Checkbox title={''} value={value} disabled checkBoxStyle={{ padding: 0, marginTop: -4 }} />
          </SC.Row>
        </SC.Padding>
      )
    }

    const recallList: FieldList.FieldType[] = [
      {
        label: t('submission:ConsultantCard'),
        render: ({}) => CheckedSignatureView(true),
        visible: checkAppointedDate() ? true : false
      },
      {
        label: t('submission:Debt'),
        // value: dataGet?.debt ? `${formatNumberWithComma(dataGet?.debt ?? '0')} VND` : '-'
        render: ({}) => DebtView()
      },
      { label: t('submission:TerminationReason'), render: ({}) => ReasonView() }
    ]

    const getAttachmentFiles = () => {
      let fileList: UploadedFilesInfo[] = []
      const fileUpload = form.base.getValues()
      const files = fileUpload.agentContent as FileUploadData[]
      const documentFiles: UploadedFilesInfo = {
        uploadFiles: files,
        transactionType: TransactionType.AGENT_TERMINATION_AG,
        docTypeCode: 'DSA02',
        category: 'DS',
        agentNumber: fileUpload.AgentCode,
        officeCode: officeCode ?? ''
      }
      fileList.push(documentFiles)
      return fileList
    }

    const onReset = () => {
      reset({
        AgentCode: agentNum,
        TerminationReason: undefined,
        dataPayload: {
          agent: dataGet
        },
        agentContent: []
      })
    }

    const onSubmit = handleSubmit((values) => {
      const newOb = {
        agent: {
          ...getValues().dataPayload?.agent,
          agentCode: getValues('AgentCode'),
          terminationReason: getValues('TerminationReason.value')
        }
      }
      dataForm({
        url: (agentNum: string) => `distribution-agents-service/distribution-service/registerFromPulseOp`,
        body: newOb,
        collerationId: agentNum,
        method: 'POST',
        transactionName: TransactionType.AGENT_TERMINATION_AG,
        uploadedFilesInfo: getAttachmentFiles()
      })
      onCheckValid(1)
    })

    useImperativeHandle(ref, () => ({
      clearForm: onReset,
      submit: onSubmit
    }))

    useEffect(() => {
      if (!!agentNum) {
        setValue('AgentCode', agentNum)
      }
    }, [])

    React.useMemo(() => {
      if (agentN && agentN.length > 7) {
        return pipe(
          SubmissionAgentService.agentGetDetail(agentN),
          ZIO.tap((res) => {
            if (res) {
              setDataGet(res ?? {})
              setValue('dataPayload.agent', res || {})
              if (res.terminateDate) {
                setErrMessage(t('submission:TerminateDateErr'))
              } else {
                setErrMessage('')
              }
            } else {
              setDataGet(undefined)
              showToast('Agent is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Agent is not found', 'info')
            setDataGet(undefined)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        return
      }
    }, [agentN])

    const ReasonView = () => {
      return (
        <Controller
          name="TerminationReason"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <SelectSearch
                required={true}
                options={AgentConst.ReasonList.map((item) => ({
                  label: getLanguage() === 'en' ? item.nameEn : item.nameVn,
                  value: item.value
                }))}
                placeholder={t('common:Select')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? { value: '', label: '' }}
                errorMessage={error?.message}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                disabled={isTab}
              />
            )
          }}
        />
      )
    }

    const downloadView = (type: string) => {
      return (
        <SC.BoldText>
          <Link href={`${assets.AgentTerminationTemplate}`} target="_parent" download>
            <assets.Download />
          </Link>
        </SC.BoldText>
      )
    }

    const DebtView = (index?: number) => {
      return (
        <Input
          value={dataGet?.debt ? `${formatNumberWithComma(dataGet?.debt ?? '0')} VND` : '-'}
          disabled={true}
          errorMessage={dataGet?.debt && +dataGet?.debt < 0 ? t('submission:DebtNote') : ''}
        />
      )
    }

    return (
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.container}>
          <View style={{ flex: 1 }}>
            <Panel title={t('submission:AgentInformation')} isChangeIcon={true}>
              <View style={[isMobile ? styles.rowMobile : styles.row]}>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Controller
                    control={control}
                    name={'AgentCode'}
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                      return (
                        <Input
                          title={t('submission:AgentNumber')}
                          value={value}
                          onChange={onChange}
                          maxLength={8}
                          onBlur={onBlur}
                          required={true}
                          disabled={isTab}
                          errorMessage={error?.message}
                        />
                      )
                    }}
                  />
                </View>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <View>
                    <Text style={[isMobile ? styles.labelMobile : styles.label]}>{t('submission:AgentName')}</Text>
                    <Text style={[styles.value]}>{dataGet?.agentName}</Text>
                  </View>
                </View>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <View>
                    <Text style={[isMobile ? styles.labelMobile : styles.label]}>{t('submission:AppointedDate')}</Text>
                    <Text style={[styles.value]}>{dataGet?.appointedDate ?? '-'}</Text>
                  </View>
                </View>
              </View>
              <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
                <FieldList dataSource={agentInfoList} />
              </View>
            </Panel>
          </View>
          <Panel title={t('submission:RecalledItems')} isChangeIcon={true}>
            <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
              <FieldList dataSource={recallList} />
              <View style={[isMobile ? styles.rowMobile : styles.row]}>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Input
                    title={t('ReleasePolicy:DownloadFile')}
                    value={'TerminationRequestForm.pdf'}
                    disabled={true}
                    alwayShowUnderline={false}
                    suffix={downloadView('office')}
                  />
                </View>
              </View>
            </View>
            <View style={styles.colFull}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.label]}>{t('CFI:CFIFileAttachment')}</Text>
                <Text style={[styles.requiredSub]}>*</Text>
              </View>
              <Controller
                control={control}
                name="agentContent"
                render={({ field, fieldState: { error } }) => (
                  <Column>
                    <ImgUploadMutiple
                      {...field}
                      disabled={isTab}
                      onChange={field.onChange}
                      value={field.value as any}
                      errorMessage={error?.message || ''}
                    />
                    {error?.message && <Error message={error?.message} />}
                  </Column>
                )}
              />
            </View>
          </Panel>
        </View>
      </SafeAreaView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 'auto',
    justifyContent: 'center',
    display: 'flex'
  },
  row: {
    flexDirection: 'row',
    marginBottom: 32
  },
  rowMobile: {
    flexDirection: 'column',
    marginBottom: 0
  },
  col: {
    width: '33.33333%',
    marginTop: 32,
    paddingRight: 16
  },
  colFull: {
    width: '100%',
    paddingRight: 16,
    marginBottom: 16
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  highLight: { color: '#eD1B2E' },
  requiredSub: {
    color: '#eD1B2E',
    marginLeft: 5,
    fontSize: 15,
    fontWeight: 'bold'
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },
  rowFooter: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  labelMobile: {
    color: '#70777E',
    fontSize: 15,
    lineHeight: 22
  }
})
