import { ZIO } from '@mxt/zio'
import {
  DatePicker,
  ErrorHandling,
  Form,
  GeneralService,
  Input,
  Select,
  SelectOption,
  SourceType,
  TaskDetail
} from '@pulseops/common'
import { Bank, Branch } from '@pulseops/submission'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { ClaimInfoForm } from '../claim-type/ClaimInfoForm'
import moment from 'moment'
interface Props {
  props: {
    detail: TaskDetail.VerificationClaim
    form: UseFormReturn<ClaimInfoForm>
    bank: Bank[] | null
    provinces:
      | {
          code: string
          name: string
          bcCode: string
        }[]
      | null
    bankBranchs: Branch[] | null
  }
}

export const PaidAtBank: React.FC<Props> = ({ props }) => {
  const { form, bank, bankBranchs, detail } = props
  const { PAYMENT } = detail
  const register = Form.register(form)
  const { watch } = form
  const { isNotPolicyOwner } = watch()
  const { t, i18n } = useTranslation('claim')

  const relationships =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) => {
        const relaList: SelectOption[] =
          r.map((item) => ({
            value: item.code,
            // label: `${item.code} - ${i18n.language === 'vi' ? item.nameVn : item.nameEn}`
            label: `${item.code} - ${i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN}`
          })) || []
        const rela = relaList.find((cond) => cond.value === PAYMENT?.relationshipWithPO) || null
        form.setValue('paidAtBank.relationshipWithPO', rela)
        return relaList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const countries =
    pipe(
      GeneralService.getCountries,
      ZIO.map((c) => {
        const countryList: SelectOption[] =
          c.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        const nationality = countryList.find((cond) => cond.value === PAYMENT?.nationality) || null
        const nationality2 = countryList.find((cond) => cond.value === PAYMENT?.nationality2) || null
        const residenceCountry = countryList.find((cond) => cond.value === PAYMENT?.residenceCountry) || null
        form.setValue('paidAtBank.nationality', nationality)
        form.setValue('paidAtBank.nationality2', nationality2)
        form.setValue('paidAtBank.residenceCountry', residenceCountry)
        return countryList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const occupations =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((oc) => {
        const ocupationList: SelectOption[] =
          oc.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        const occupation = ocupationList.find((cond) => cond.value === PAYMENT?.occupation) || null

        form.setValue('paidAtBank.occupation', occupation)
        return ocupationList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  React.useEffect(() => {
    const genderGen = (value: string) =>
      value === 'F' ? `${t('common:Female')}` : value === 'M' ? `${t('common:Male')}` : value

    const genderObj = PAYMENT?.gender
      ? {
          value: PAYMENT?.gender || '-',
          label: PAYMENT?.gender ? genderGen(PAYMENT.gender) : '-'
        }
      : null
    form.setValue('paidAtBank.gender', genderObj)
    if (isNotPolicyOwner) {
      form.setValue('paidAtBank.issuedDate', moment(PAYMENT?.payAtBank?.issuedDate).toDate() || '-')
      form.setValue('paidAtBank.issuedBy', PAYMENT?.payAtBank?.issuedBy || '-')
      form.setValue('paidAtBank.dateOfBirth', moment(PAYMENT?.dob).toDate() || '-')
      form.setValue('paidAtBank.payeeName', PAYMENT?.payAtBank?.payeeName || '-')
      form.setValue('paidAtBank.idNumber', PAYMENT?.payAtBank?.idNumber || '-')
    }
  }, [i18n.language, detail])

  return (
    <SafeAreaView>
      <View style={styles.row}>
        <View style={styles.col}>
          <Text style={styles.label}>{t('PayeeName')}</Text>
          <Input {...register('paidAtBank.payeeName')} disabled title={''} />
        </View>
        <View style={styles.col}>
          <Text style={styles.label}>{t('IDNumber')}</Text>
          <Input {...register('paidAtBank.idNumber')} disabled title={''} />
        </View>
        <View style={[styles.col, { paddingEnd: 100 }]}>
          <Text style={styles.label}>{t('IssuedDate')}</Text>
          <DatePicker {...register('paidAtBank.issuedDate')} label={''} disabled />
        </View>
      </View>

      <View style={styles.row}>
        <View style={[styles.col, { paddingEnd: 100 }]}>
          <Text style={styles.label}>{t('IssuedBy')}</Text>
          <Input {...register('paidAtBank.issuedBy')} disabled title={''} />
        </View>
      </View>
      {detail?.detail?.source === SourceType.PULSE && (
        <View style={styles.row}>
          <View style={styles.colFull}>
            <Text style={styles.label}>{t('Bank')}</Text>
            <Text>{`${watch('paidAtBank.bankName')?.label || ''}   ${watch('paidAtBank.branch')?.label || ''}`}</Text>
          </View>
        </View>
      )}
      {detail?.detail?.source !== SourceType.PULSE && (
        <View style={styles.row}>
          <View style={[styles.col, { paddingEnd: 100 }]}>
            <Select
              {...register('paidAtBank.bankName')}
              disabled
              options={bank?.map((v) => ({ value: v.code, label: v.name })) || []}
              label={t('Bank')}
              showUnderline={false}
            />
          </View>
          {PAYMENT.payAtBank?.branch && (
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Select
                {...register('paidAtBank.branch')}
                disabled
                options={bankBranchs?.map((v) => ({ value: v.code, label: v.name })) || []}
                label={t('Branch')}
                showUnderline={false}
              />
            </View>
          )}
        </View>
      )}

      {!isNotPolicyOwner && detail?.detail?.source !== SourceType.PULSE && (
        <View style={styles.row}>
          <View style={[styles.col, { paddingEnd: 100 }]}>
            <Text style={styles.label}>{t('DateOfBirth')}</Text>
            <DatePicker {...register('bankTransferForm.dateOfBirth')} disabled label={''} />
          </View>
        </View>
      )}
      {isNotPolicyOwner && (
        <>
          <View style={styles.row}>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Select
                {...register('paidAtBank.relationshipWithPO')}
                disabled
                options={relationships}
                label={t('RelationWithPolicyOwner')}
                showUnderline={false}
              />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('DateOfBirth')}</Text>
              <DatePicker {...register('paidAtBank.dateOfBirth')} disabled label={''} />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Gender')}</Text>
              <Select
                {...register('paidAtBank.gender')}
                disabled
                options={[
                  {
                    value: 'F',
                    label: t('common:Female')
                  },
                  {
                    value: 'M',
                    label: t('common:Male')
                  }
                ]}
                showUnderline={false}
              />
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>{t('ResidenceCountry')}</Text>
              <Select {...register('paidAtBank.residenceCountry')} disabled options={countries} showUnderline={false} />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Occupation')}</Text>
              <Select {...register('paidAtBank.occupation')} options={occupations} disabled showUnderline={false} />
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Nationality')}</Text>
              <Select {...register('paidAtBank.nationality')} disabled options={countries} showUnderline={false} />
            </View>
            <View style={[styles.col]}>
              <Text style={styles.label}>{t('Address')}</Text>
              <Input {...register('paidAtBank.address')} disabled title={''} multiline numberOfLines={4} />
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.col]}>
              <Text style={styles.label}>{t('Nationality2')}</Text>
              <Select {...register('paidAtBank.nationality2')} disabled options={countries} showUnderline={false} />
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>{t('ForeignAddress')}</Text>
              <Input {...register('paidAtBank.foreignAddress')} disabled title={''} multiline numberOfLines={4} />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  colFull: {
    width: '100%',
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
