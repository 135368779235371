import React, { useEffect } from 'react'
import { useVoiceRecording } from '../BussinessLogic'
import { CircularProgress } from '@material-ui/core'

interface Props {
  progressBarRef: any
  audioRef: any
  timeProgress: number
}
const ProgressBar = React.memo (({ progressBarRef, audioRef, timeProgress }: Props) => {
  const { state, controller } = useVoiceRecording()
  const handleProgressChange = (e: any) => {
    audioRef.current.currentTime = e.target.value
  }

  return (
    <div className="progress">
      <input
        type="range"
        ref={progressBarRef}
        min="0"
        max={Math.ceil(state.duration ?? 0)}
        value={Math.ceil(timeProgress)}
        onChange={handleProgressChange}
        disabled={state.loadingSrc}
      />
      <span className="time current">{controller.formatTime(timeProgress)}</span>
      <span className="time">/</span>
      <span className="time">{controller.formatTime(state.duration)}</span>
      {state.loadingSrc ? (
        <CircularProgress color="primary" size={20} />
      ) : (
        <div className="loop">
          <span>1x</span>
        </div>
      )}
    </div>
  )
})

export default ProgressBar
