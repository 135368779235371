import { Paper } from '@material-ui/core'
import { Any, Task, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import { BusinessStackParamList } from '@pulseops/business'
import {
  GeneralInfoState,
  ContractItem,
  ContractService,
  ImportButton,
  ImportService,
  Table,
  TableFooterPagination,
  TableStatus,
  UtilRow,
  SearchInput
} from '@pulseops/business/core'
import {
  AppContext,
  BuildConfig,
  didUpdate,
  ErrorHandling,
  Format,
  RBAC,
  Permission,
  subscribe
} from '@pulseops/common'
import { Link, useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'

type Props = StackScreenProps<BusinessStackParamList, 'ContractListScreen'>

export const ContractListScreen = ({ navigation }: Props) => {
  const { t } = useTranslation('business')
  const { t: menu } = useTranslation('menu')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const [size, setSize] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [query, setQuery] = React.useState('')
  const [loading, bindLoading] = useLoading(false)

  pipe(
    didUpdate([size, page, query]),
    ZStream.chainEffect(([size, page, query]) =>
      pipe(ContractService.getContractList({ size, page, masterContractNo: query }), bindLoading)
    ),
    subscribe()
  )

  const rows: ContractItem[] | null = pipe(
    didUpdate([size, page, query]),
    ZStream.switchMap(([size, page, query]) => GeneralInfoState.contractItem.size(size, query).watchPage(page)),
    subscribe([])
  )

  const total = pipe(
    didUpdate([size, query]),
    ZStream.switchMap(([size, query]) => GeneralInfoState.contractItem.size(size, query).watchTotal),
    subscribe(0)
  )

  const reload = ZIO.effect(() => {
    if (page === 0) {
      pipe(ContractService.getContractList({ size, page, masterContractNo: query }), bindLoading, ErrorHandling.run())
    } else {
      setPage(0)
    }
  })

  const [importLoading, bindImportLoading] = useLoading(false)

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.replace('HomeScreen')
        },
        {
          title: menu('BackOffice'),
          navigate: () => navigation.navigate('BusinessScreen')
        },
        { title: t('ContractList'), navigate: null }
      ])
    }
  }, [isFocused])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <SC.Container>
          <UtilRow>
            {roles.includes(Permission['SearchGroupListGroupList']) && (
              <SearchInput placeholder="Số HSYCBH" onClick={setQuery} />
            )}
            {roles.includes(Permission['ImportContractInfoGroupList']) && (
              <ImportButton
                loading={importLoading}
                onImport={(file) => {
                  pipe(
                    ImportService.uploadContract(file),
                    ZIO.tap(
                      E.fold(
                        (): Task<Any> => ZIO.unit,
                        () => ZIO.zip(GeneralInfoState.contractItem.clear, reload)
                      )
                    ),
                    bindImportLoading,
                    ErrorHandling.run()
                  )
                }}
              />
            )}
          </UtilRow>

          {roles.includes(Permission['ViewGroupListGroupList']) && (
            <Table.Container component={Paper}>
              <Table.Main>
                <Table.Head>
                  <Table.Row>
                    <Table.CellHead>STT</Table.CellHead>
                    <Table.CellHead>{t('proposal_no')}</Table.CellHead>
                    <Table.CellHead>{t('proposal_submitted_date')}</Table.CellHead>
                    <Table.CellHead>{t('company_org_name')}</Table.CellHead>
                    <Table.CellHead>{t('tax_code')}</Table.CellHead>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  <TableStatus colSpan={5} loading={loading} rows={rows} />
                  {rows?.map((row, i) => (
                    <Table.Row key={row.masterContractNo}>
                      <Table.Cell>{i + 1}</Table.Cell>
                      <Table.Cell>
                        <SC.Link
                          to={`${
                            BuildConfig.contextPath
                          }/user/drawer/business/contract-detail?masterContractNo=${encodeURIComponent(
                            row.masterContractNo
                          )}&policyYear=${row.policyYear}`}
                        >
                          {row.masterContractNo}
                        </SC.Link>
                      </Table.Cell>
                      <Table.Cell>{Format.date(row.submittedDate)}</Table.Cell>
                      <Table.Cell>{row.companyName}</Table.Cell>
                      <Table.Cell>{row.taxCode}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <TableFooterPagination
                  total={total}
                  size={size}
                  page={page}
                  onPageChange={setPage}
                  onSizeChange={setSize}
                  colspan={5}
                />
              </Table.Main>
            </Table.Container>
          )}
        </SC.Container>
      </ScrollView>
    </SafeAreaView>
  )
}

const SC = {
  Container: styled(View)`
    padding: 1rem;
  `,
  Link: styled(Link)`
    text-decoration-line: underline;
    color: #1ea5fc;
  `
}
