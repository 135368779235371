import { Task, Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { benClient, lAClient } from './GoAbroadService'
import { InquiryComplaintService } from './InquiryComplaintService'
import { CancelRider, InquiryComplaintData, ProductEnum, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'
import { TopUpService } from './TopUpService'

export namespace CancelRiderService {
  export const getDetail = (
    policyNum: string,
    riderStatus = ProductEnum.Status.IF
  ): Task<CancelRider.DetailCancelRiderInfo> =>
    pipe(
      ZIO.zipPar(
        getClientRiderInfo(policyNum),
        TopUpService.GetPolicyExtraInfo([policyNum], ''),
        SubmissionService.getPolicy(policyNum),
        getRiderInfoList(),
        getMinPremium(policyNum)
      ),
      ZIO.map(([clients, policyExtra, policyInfo, riderNameList, minPrem]) => {
        const LARiderList = clients.map((clientItem) => {
          // filter x.lifeNo !=="01", x.coverageNo !=="01", x.riderNo !=="00" to get only rider products
          const cancelRiderList = policyExtra.policyExtraInfoDetail
            .filter(
              (x) =>
                (x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00') &&
                x.lifeNo === clientItem.life &&
                x.riderStatus === riderStatus &&
                !CancelRider.UnCancelRiderList.includes(x.productCode)
            )
            .map((extraItem) => ({
              productCode: String(extraItem?.productCode),
              productName: '',
              riskCessDate: String(extraItem?.riskCessDate),
              riskCommDate: String(extraItem?.riskCommDate),
              lapsedDate: '',
              sumAssured: Number(extraItem?.sumAssured),
              installmentPrem: Number(extraItem?.installmentPremium),
              newInstallmentPremium: 0,
              lifeNo: extraItem.lifeNo,
              coverageNo: extraItem.coverageNo,
              riderNo: extraItem.riderNo,
              riderStatus: extraItem.riderStatus
            }))
          const filterRiderlist = policyExtra.policyExtraInfoDetail
            .filter((item) => item.lifeNo === '01' && item.coverageNo === '01' && item.riderNo === '00')
            .map((extra) => ({
              productCode: String(extra?.productCode)
            }))
          return {
            clientName: clientItem.clientName,
            lifeAssured: clientItem.clientNumberOfLA,
            riders: cancelRiderList,
            extraRider: filterRiderlist
          }
        })
        return {
          totalPremium: Number(policyInfo.body.totalPremium),
          laRiderInfo: LARiderList.filter((x) => x.riders && x.riders.length > 0),
          minPremium: minPrem,
          riderNameList: riderNameList,
          riderNameListTwo: LARiderList.filter((x) => x.extraRider && x.extraRider.length > 0)
        }
      })
    )

  export const getRiderInfoList = (): Task<InquiryComplaintData.IACSources[]> =>
    pipe(
      POApi.get(`wf-api/rider/get-list`)(
        t.type({
          // status: t.string,
          body: InquiryComplaintService.IACSource,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((riderInfoList) => {
        return riderInfoList.body
      })
    )
  export const getRiderpolicyNumber = (policyNumber: string): Task<InquiryComplaintData.IACSources[]> =>
    pipe(
      POApi.get(`wf-api/rider/get-list-by-policy-number/${policyNumber}`)(
        t.type({
          // status: t.string,
          body: InquiryComplaintService.IACSource,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((riderInfoList) => {
        return riderInfoList.body
      })
    )
  export const getClientRiderInfo = (policyNum: string): Task<CancelRider.CanRiderClientInfo[]> =>
    pipe(
      POApi.get(`wf-api/bo/${policyNum}/clients`)(
        t.type({
          body: Maybe(
            t.type({
              laList: t.array(lAClient),
              benList: t.array(benClient)
            })
          )
        })
      ),
      ZIO.map((client) => {
        const lAClient = client.body && client.body.laList ? client.body?.laList : []
        return lAClient
      }),
      ZIO.flatMap((lAClients) => {
        const clientIDList = lAClients.map((x) => ({
          clientId: x.clientNumberOfLA
        }))
        return pipe(
          SubmissionService.getClients(clientIDList),
          ZIO.map((clientsList) => {
            const clientInfoList = lAClients.map((item) => {
              const clientItem = clientsList.body.find((x) => x.clientId === item.clientNumberOfLA)
              return {
                clientNumberOfLA: item.clientNumberOfLA,
                life: item.life,
                clientName: String(clientItem?.name)
              }
            })
            return clientInfoList
          })
        )
      })
    )

  export const getMinPremium = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/other/client-policy-verify`)(
        t.type({
          body: t.type({
            policyRecOuts: t.array(
              t.type({
                errorCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        body: {
          function: 'CHECK_MIN_PREM',
          clientRecs: [{ clientNumber: '', securityNo: '' }],
          policyRecs: [{ policyNum }]
        }
      }),
      ZIO.map((verify) => {
        const minData = Number(
          verify.body.policyRecOuts && verify.body.policyRecOuts.length > 0
            ? verify.body.policyRecOuts[0].errorCode ?? '0'
            : '0'
        )
        return minData / 100
      })
    )

  export const getAccessData = (policyNum: string): Task<CancelRider.CancelRiderAccessData> =>
    pipe(
      ZIO.zipPar(TopUpService.GetPolicyExtraInfo([policyNum], ''), SubmissionService.getPolicy(policyNum)),
      ZIO.map(([extraInfo, policyInfo]) => {
        const proposalReceivedDate = policyInfo.body.proposal?.proposalReceivedDate
        const contractStatus = policyInfo.body.status
        // filter ra cac san pham rider(khong xet san pham chinh)
        const existedItem = extraInfo.policyExtraInfoDetail
          .filter(
            (x) =>
              (x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00') &&
              !CancelRider.UnCancelRiderList.includes(x.productCode)
          )
          .find((x) => x.riderStatus === ProductEnum.Status.IF)
        return {
          contractStatus: String(contractStatus),
          notExistAnyRider: existedItem ? false : true,
          proposalReceivedDate: String(proposalReceivedDate)
        }
      })
    )

  export const submitData =
    (policyNum: string, data: CancelRider.SubmitCancelRiderData) => (requestauth: RequestAuthFormDataSubmit) =>
      pipe(
        SubmissionService.submit(t.type({ status: t.string }))(
          `wf-api/policy/${policyNum}/cancel-rider/submission-for-staff`,
          { body: data },
          policyNum,
          requestauth,
          []
        ),
        ZIO.flatMap((response): Task<string> => {
          switch (response.status) {
            case TopUpService.TopUpStatus.SUCCESS:
              return ZIO.succeed(response.status)
            case TopUpService.TopUpStatus.FAILED:
              return ZIO.fail(Throwable('submit cancel rider failed'))
            default:
              return ZIO.fail(Throwable('submit cancel rider failed'))
          }
        })
      )
}
