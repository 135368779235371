import {
  AppContext,
  AuthService,
  ErrorHandling,
  GeneralService,
  Permission,
  PolicyInfoHeader,
  PulseOpsApi,
  RBAC,
  RequestAuthFormDataSubmit,
  sharedStyle,
  StorageBlob,
  SubmissionService,
  T0Data,
  TabList,
  TaskType,
  Title,
  TransactionType,
  useMobile
} from '@pulseops/common'
import { TaskDetailAction } from '@pulseops/task'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { Task, ZIO } from '@mxt/zio'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import * as tc from 'io-ts'
import { View, Text, SafeAreaView, ScrollView } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { DocumentOption } from '../claim'
import { RequestAuthen, RequestAuthenticateData } from '../ps-submission/transactionScreen/request-authen'
import { PolicyServiceSubmit, UploadedFilesInfo } from '../ps-submission/transactionScreen/screens'
import { DocumentCashOut } from './docCashOut'
import { DocumentCashOutConfirm } from './docCashOutConfirm'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
// import { generalStyles } from '../claim'
import { useIsFocused } from '@react-navigation/native'
import { CashoutStackParamList } from './CashoutStackParamList'
import * as A from 'fp-ts/lib/Array'

type Props = StackScreenProps<CashoutStackParamList, 'CashOutScreen'>
type CashOutInfo = {
  paymentMethod?: string | undefined | null
  isPayeeNotPO?: boolean | undefined | null
  dob?: string | undefined | null
  gender?: string | undefined | null
  phone?: string | undefined | null
  email?: string | undefined | null
  salutation?: string | undefined | null
  married?: string | undefined | null
  occupation?: string | undefined | null
  relationshipWithPO?: string | undefined | null
  nationality?: string | undefined | null
  address?: string | undefined | null
  nationality2?: string | undefined | null
  foreignAddress?: string | undefined | null
  foreignCountry?: string | undefined | null
  isNewClient?: boolean | undefined | null
  residenceCountry?: string | undefined | null
  payeeName?: string | undefined | null
  name?: string | undefined | null
  idNumber?: string | undefined | null
  dod?: string | undefined | null
  surName?: string | undefined | null
  giveName?: string | undefined | null
  city?: string | undefined | null
  district?: string | undefined | null
  ward?: string | undefined | null
  street?: string | undefined | null
}

export const CashOutScreen = (props: Props) => {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const documentRef = React.useRef<DocumentOption[]>([])
  const [officeName, setOfficeName] = React.useState<string>('')
  const [officeCode, setOfficeCode] = React.useState<string>('')

  const [selectedDocumentType, setSelectedDocumentType] = React.useState<DocumentOption[]>([])
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoader] = useLoading(false)
  const [payeeInfo, setPayeeInfo] = React.useState<CashOutInfo>()
  const [isPayeeNotPO, setisPayeeNotPO] = React.useState<boolean>(true)
  const [payableAmount, setPayableAmount] = React.useState<string>('-')
  const [transactionId, setTransactionId] = React.useState<string>('')
  const [paymentType, setPaymentType] = React.useState<TransactionType>()
  const [relationship, setRelationship] = React.useState<string>('')
  const [paymentNumber, setPaymentNumber] = React.useState<string>('-')
  const [clientIdNum, setClientId] = React.useState<string>('')
  const isFocused = useIsFocused()

  const [occupationName, setOccupation] = React.useState<string>('-')
  const [residenceCountryName, setResidenceCountry] = React.useState<string>('-')
  const [nationName, setNation] = React.useState<string>('-')
  const [nation2Name, setNation2] = React.useState<string>('-')

  const [officeGA, setOfficeGA] = React.useState<string>('')
  const [eformData, setEformData] = React.useState<RequestAuthenticateData.EformRequestAuthenticateData>(
    RequestAuthenticateData.DefaultRequestAuthenticateData
  )

  const header = [
    {
      label: t('common:PolicyNumber'),
      value: props?.route?.params?.policyNum ?? '-'
    },
    {
      label: t('common:PolicyOwner'),
      value: `${props?.route?.params?.clientNum ?? ''} - ${props?.route?.params?.policyOwner ?? ''}`
    },
    {
      label: t('common:Office'),
      value: `${officeName ?? '-'}`
    }
  ]

  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const requestAuthenForm = useForm<RequestAuthenticateData.RequestAuthFormValue>({
    defaultValues: {
      authOption: RequestAuthenticateData.RequestAuthOption.OTP,
      otp: {
        otpOption: RequestAuthenticateData.OTPOption.PHONE,
        otpInput: undefined,
        otpConsent: false
      },
      paper: {
        data: [],
        consent: false
      }
    }
  })

  const { watch, setValue } = requestAuthenForm

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const docCallbackRef = React.useRef<() => void>(() => {
    /* do nothing */
  })
  const registerDocCallback = (callback: () => void) => {
    docCallbackRef.current = callback
  }
  const docResetCallbackRef = React.useRef<() => void>(() => {
    /* do nothing */
  })
  const registerResetDocCallback = (callback: () => void) => {
    docResetCallbackRef.current = callback
  }

  const menus = [
    {
      id: 1,
      title: t('submission:Request')
    },
    {
      id: 2,
      title: t('submission:Confirm')
    }
  ]

  const onChangeTab = (index: number) => {
    setTabIndex(index)
  }

  const getGenderName = (gender?: string | null): string => {
    switch (gender) {
      case 'M':
        return t('common:Male')
      case 'F':
        return t('common:Female')
      default:
        return '-'
    }
  }

  const validateForm = () => {
    const createdDate = props.route.params.createdDate ? new Date(props.route.params.createdDate).getDate() : undefined
    const currentDate = new Date().getDate()
    if(createdDate && createdDate !== currentDate && selectedDocumentType.length === 0) {
      showToast(t('message:MS030040'), 'error')
      return false
    }
    return true
  }

  const validateNoFile = () => {
    const checkFileHasType = selectedDocumentType.length > 0 && !!selectedDocumentType.find((item) => item.files?.length === 0)
    if(checkFileHasType) {
      showToast(t('message:MS030040'), 'error')
      return false
    }
    return true
  }

  const onSubmit = () => (requestauth: RequestAuthFormDataSubmit) => {
    return pipe(
      ZIO.succeed(getUploadedFilesInfo()),
      ZIO.flatMap((uploadFile) =>
        !!uploadFile && uploadFile.length > 0
          ? pipe(
              uploadFile,
              A.map((uploadedItem) => {
                return GeneralService.getAzureFilesForCashOut(
                  uploadedItem?.uploadFiles,
                  uploadedItem.transactionType,
                  uploadedItem.docTypeCode,
                  uploadedItem.category,
                  uploadedItem.policyNumber,
                  uploadedItem.officeCode
                )
              }),
              ZIO.sequence,
              ZIO.map((uploadedList) => {
                let uploadFiles: StorageBlob.CashOutSubmitFile[] = []
                uploadedList.map((arrayItem) => {
                  uploadFiles.push(arrayItem)
                })
                return uploadFiles
              })
            )
          : ZIO.succeed([])
      ),
      ZIO.flatMap((azuredDocuments) => {
        let uploadedFiles: StorageBlob.FileContentSubmit[] = []
        const data = {
          paymentOffice: officeGA ? officeGA : officeCode,
          addLackOfCashOuts:
            documentRef.current.length === 0
              ? [
                  {
                    transactionType: TransactionType.LACK_OF_CASHOUT,
                    suspendCode: 'SPS50',
                    processInstanceId: props?.route?.params?.id,
                    caseId: props?.route?.params?.caseId,
                    attachments: []
                  }
                ]
              : documentRef.current.map((docItem) => {
                  let docFilter: StorageBlob.FileContentSubmit[] = []
                  azuredDocuments.map((azureItem) => {
                    if (azureItem.docTypeCode === docItem.docTypeCode)
                      docFilter = [...azureItem.storageFiles, ...docFilter]
                  })
                  return {
                    transactionType: TransactionType.LACK_OF_CASHOUT as string,
                    suspendCode: 'SPS50',
                    processInstanceId: props?.route?.params?.id,
                    caseId: props?.route?.params?.caseId,
                    attachments: docFilter.map((file) => ({
                      name: file.name
                    }))
                  }
                })
        }
        azuredDocuments.map((item) => {
          uploadedFiles = [...item.storageFiles, ...uploadedFiles]
        })
        return SubmissionService.submit(tc.unknown)(
          `wf-api/lack-of-cash-out/policy/${props.route.params?.policyNum}/add-lack-of-cash-out`,
          { body: data },
          props.route.params?.policyNum,
          null,
          uploadedFiles,
          clientIdNum,
          undefined,
          officeCode ?? '',
          false,
          undefined,
          true
        )
      }),
      ZIO.foldM(
        (err) => {
          showGlobalLoading(false)
          return ZIO.fail(err)
        },
        (_) => {
          showGlobalLoading(false)
          return ZIO.unit
        }
      )
    )
  }

  const getOfficeNameByCode = (officeCode: string) => {
    if (officeCode) {
      pipe(
        GeneralService.getOfficeCode(officeCode),
        ZIO.map((officeItem) => {
          let officeItemName = '',
            officeNameLanguage = ''
          if (officeItem && officeItem.body && officeItem.body.length > 0) {
            officeNameLanguage = i18n.language === 'vi' ? officeItem.body[0].nameVi : officeItem.body[0].nameEn
            officeItemName = officeItem.body[0].code + ' - ' + officeNameLanguage
            setOfficeName(officeItemName)
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const convertToAmount = (amountNumber: number): string => {
    const amount = (amountNumber + '.').replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return amount.split('.')[0]
  }

  const getTransactionTypeLabel = (transactionType: string) => {
    const transactionItem = T0Data.getValue(transactionType)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : ''
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const documentTypeFiles = documentRef.current.filter((x) => !!x.files && x.files.length > 0)
    documentTypeFiles.forEach((documentItem) => {
      uploadedFileList.push({
        uploadFiles: documentItem.files,
        transactionType: TransactionType.CASH_OUT_REQUEST,
        docTypeCode: documentItem.docTypeCode,
        category: TaskType.PolicyService,
        policyNumber: props.route.params?.policyNum ?? '',
        officeCode: officeGA ? officeGA : officeCode ?? ''
      })
    })
    return uploadedFileList
  }

  const getOccupationFromCode = (code: string) => {
    pipe(
      GeneralService.getOccupations,
      ZIO.map((e) => {
        let name = '-'
        e.map((item) => {
          if (item.code === code) {
            name = item.name
          }
        })
        setOccupation(name)
      }),
      ZIO.unsafeRun({})
    )
  }

  const getCountryFromCode = (codeCountry: string, codeNation: string, codeNation2: string) => {
    pipe(
      GeneralService.getCountries,
      ZIO.map((e) => {
        let nameCountry = ''
        let nameNation = ''
        let nameNation2 = ''
        e.map((item) => {
          if (item.code === codeCountry) nameCountry = item.name
          if (item.code === codeNation) nameNation = item.name
          if (item.code === codeNation2) nameNation2 = item.name
        })
        setResidenceCountry(nameCountry)
        setNation(nameNation)
        setNation2(nameNation2)
      }),
      ZIO.unsafeRun({})
    )
  }

  const getRelationShipFromCode = (codeRelationShip: string) => {
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((e) => {
        let nameRelationShip = ''
        e.map((item) => {
          if (item.code === codeRelationShip)
            nameRelationShip = i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN
        })
        setRelationship(nameRelationShip)
      }),
      ZIO.unsafeRun({})
    )
  }

  const onSubmitDataWithAuthen = (submitService: (_: RequestAuthFormDataSubmit) => Task<unknown>) => {
    return (verify: RequestAuthenticateData.RequestAuthFormValue) => {
      showGlobalLoading(true)
      return pipe(
        verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
          ? submitService({
              type: RequestAuthenticateData.RequestAuthOption.OTP,
              data: {
                otp: verify.otp.otpInput,
                transactionName: eformData.transactionName,
                transactionCode: `${eformData.transactionType}-${eformData.collerationId}-${eformData.sendDate}`,
                receiverPhone: eformData.phoneData.phoneNum,
                payload: ''
              }
            })
          : pipe(
              StorageBlob.uploadToSubmit(
                'PS',
                eformData.collerationId
              )(
                verify.paper.data.map((x) => ({
                  file: x.file,
                  metaData: eformData.metaData
                }))
              ),
              ZIO.flatMap((x) =>
                submitService({
                  type: RequestAuthenticateData.RequestAuthOption.PAPER,
                  data: {
                    authFlag: eformData.docReviewed,
                    attachments: x
                  }
                })
              )
            ),
        ZIO.tap((e) => {
          showToast(t('message:RequestSendSuccessfully'), 'success')
          showGlobalLoading(false)
          requestAuthenForm.reset({
            authOption: RequestAuthenticateData.RequestAuthOption.OTP,
            otp: {
              otpOption: RequestAuthenticateData.OTPOption.PHONE,
              otpInput: undefined,
              otpConsent: false
            },
            paper: {
              data: [],
              consent: false
            }
          })
          props.navigation.navigate('CashOutHistoryList')
          return ZIO.unit
        }),
        ZIO.mapError((err) => {
          let getErrorMess = ''
          if (err.source && err.source.message.includes(' - ')) {
            const code = err.source.message.split(' - ')[0]
            const time = err.source.message.split(' - ')[1].replace('t=', '')
            getErrorMess =
              code === 'MS050252'
                ? t('message:MS050252', { t: time })
                : code === 'MS050254'
                ? t('message:MS050254', { t: time })
                : code === 'MS050253'
                ? t('message:MS050253')
                : code === 'MS050255'
                ? t('message:MS050255')
                : 'Unknown error'
          } else {
            getErrorMess = t('message:MS050001')
          }
          showToast(getErrorMess, 'error')
          showGlobalLoading(false)
          // return Throwable('Done')
          return getErrorMess
        }),
        ZIO.unsafeRun({})
      )
      // : showOTPErrorMsg(verify, isValidAuthDoc, isValidAuthFlag)
    }
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Cashout'),
          navigate: () => {
            props.navigation.navigate('CashOutHistoryList')
          }
        },
        {
          title: transactionId ? transactionId : '-',
          navigate: null
        }
      ])
    }
  }, [isFocused, transactionId])

  React.useEffect(() => {
    if (props.route.params?.policyNum) {
      pipe(
        SubmissionService.getOwnerInfo(props.route.params?.policyNum || ''),
        ZIO.map((responseItem) => {
          const policyItem = {
            policyNum: props.route.params?.policyNum || '',
            clientId: responseItem?.body?.clientId,
            poName: `${responseItem?.body?.surName} ${responseItem?.body?.firstName}`
          }
          setClientId(responseItem?.body?.clientId)
          return policyItem
        }),
        ZIO.mapError((e) => {
          showGlobalLoading(false)
          return ZIO.fail(e)
        }),
        ZIO.flatMap((clientItem) => {
          return pipe(
            SubmissionService.getNewOwnerInfo(clientItem.policyNum),
            ZIO.map((customerInfo) => {
              setEformData({
                ...eformData,
                policyNum: clientItem.policyNum,
                phoneData: {
                  phoneCode: customerInfo.attributesExt.MOBILE_CODE,
                  phoneNum: customerInfo.mobilePhone ?? ''
                },
                otpRegistered: customerInfo.otpConsentStatus === '1',
                isCCE: false
              })
              // showGlobalLoading(false)
              return customerInfo
            })
          )
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [props.route.params?.policyNum])

  // PAYEE != PO
  React.useEffect(() => {
    setOfficeName('')
    setTabIndex(0)
    if (props?.route?.params) {
      showGlobalLoading(true)
      pipe(
        PulseOpsApi.getHistoricDetail(props?.route?.params?.id),
        ZIO.mapError((e) => {
          showGlobalLoading(false)
          return ZIO.fail(e)
        }),
        ZIO.map((value) => {
          setTransactionId(value?.businessKey || '')
          setPaymentType(value?.transactionType)
          if (
            value?.transactionType === TransactionType.SPECIAL_BONUS ||
            value?.transactionType === TransactionType.MATURITY ||
            value?.transactionType === TransactionType.BONUS_SURRENDER ||
            value?.transactionType === TransactionType.EXCELLENT_STUDENT_AWARD ||
            value?.transactionType === TransactionType.FULL_SURRENDER ||
            value?.transactionType === TransactionType.LOAN ||
            value?.transactionType === TransactionType.PARTIAL_SURRENDER ||
            value?.transactionType === TransactionType.PARTIAL_WITHDRAWAL ||
            value?.transactionType === TransactionType.PRUCASH_PAYMENT ||
            value?.transactionType === TransactionType.PRUKID369 ||
            value?.transactionType === TransactionType.EXCESS_PREMIUM ||
            value?.transactionType === TransactionType.CANCEL_FROM_INCEPTION
          ) {
            if (value.payload?.body?.cashOutOptions)
              value.payload.body.cashOutOptions.map((o) => {
                if (o.type === 'CASH_AT_COUNTER') {
                  setPayableAmount(o.paymentOption?.amount ? convertToAmount(o.paymentOption.amount) : '')
                  if (o.attributesExt?.PAYEE_INFO && o.attributesExt?.PAYEE_INFO.isPayeeNotPO) {
                    setPayeeInfo(o.attributesExt?.PAYEE_INFO)
                    setisPayeeNotPO(o.attributesExt?.PAYEE_INFO?.isPayeeNotPO)
                    getOccupationFromCode(o.attributesExt?.PAYEE_INFO?.occupation || '')
                    getCountryFromCode(
                      o.attributesExt?.PAYEE_INFO?.residenceCountry || '',
                      o.attributesExt?.PAYEE_INFO?.nationality || '',
                      o.attributesExt?.PAYEE_INFO?.nationality2 || ''
                    )
                    getRelationShipFromCode(o.attributesExt?.PAYEE_INFO?.relationshipWithPO || '')
                  } else setisPayeeNotPO(false)
                }
              })
          }
          setPaymentNumber(value?.paymentNumber || '-')
          getOfficeNameByCode(value.payload.officeCode || '')
          setOfficeCode(value.payload.officeCode || '')
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [props?.route?.params])

  // PAYEE = PO
  React.useEffect(() => {
    if (props.route.params?.policyNum && !isPayeeNotPO) {
      showGlobalLoading(true)
      pipe(
        PulseOpsApi.getCustomer(props?.route?.params.clientNum),
        ZIO.map((client) => {
          if (client.body) {
            const address = client.body.addressDetails.PRIMARY
            const fAddress = client.body.attributesExt
            const fullFAddress = `${fAddress.FOREIGN_DISTRICT ?? ''} ${fAddress.FOREIGN_WARD ?? ''} ${
              fAddress.FOREIGN_CITY ?? ''
            } ${fAddress.FOREIGN_COUNTRY ?? ''}`
            setPayeeInfo({
              dob: client.body.dob,
              idNumber: client.body.externalIds?.SOE_VALUE ?? client.body.clientId,
              payeeName: client.body.name,
              gender: client.body.sex,
              address: address
                ? `${address?.line1 ?? ''} ${address?.line2 ?? ''} ${address?.line3 ?? ''} ${address?.line4 ?? ''}`
                : '-',
              foreignAddress: fullFAddress.length > 3 ? fullFAddress : '-'
            })
            getOccupationFromCode(client.body.occupation || '')
            getCountryFromCode(
              client.body.countryCode || '',
              client.body.nationality || '',
              fAddress.NATIONALITY_2 || ''
            )
          }
        }),
        ZIO.mapError((e) => {
          // console.log(e, 'error')
          showGlobalLoading(false)
          return ZIO.fail(e)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [isPayeeNotPO])

  React.useEffect(() => {
    pipe(
      AuthService.userInfo,
      ZIO.map((userInfo) => {
        if(userInfo.officeCode) {
          setOfficeGA(userInfo.officeCode)
        }
      }),
      ZIO.unsafeRun({})
    )
  }, [])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          backgroundColor: useMobile().isMobile ? '#E5E5E5' : '#F9F9F9',
          paddingTop: 16
        }}
      >
        <ScrollView style={{ paddingBottom: 20 }}>
          <PolicyInfoHeader menus={header} />
          <View style={{ marginHorizontal: 15 }}>
            <TabList
              menus={menus}
              isSubTab={false}
              tabIndex={tabIndex}
              onChangeTab={onChangeTab}
              predicateDisable={(index) => index === 1}
            />
          </View>
          <View style={{ backgroundColor: '#FFF', paddingHorizontal: 20, paddingVertical: 15 }}>
            <Text style={sharedStyle.sectionTitle}>{t('submission:requestType')}</Text>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#d3dce6',
                marginTop: 15,
                marginBottom: 15,
                paddingTop: 20,
                paddingBottom: 20
              }}
            >
              <View style={{ flexDirection: 'row' }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('TaskManagement:Catergories')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{t('TransactionGroup:Common')}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('submission:serviceType')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{t('CashOut:PayoutCash')}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('submission:transactionType')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{t('CashOut:CashOutRequest')}</Text>
                </View>
              </View>
            </View>

            <Title title={t('CashOut:TransInfo')}></Title>
            <View style={styleView}>
              <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('policy:transactionID')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{transactionId || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('CashOut:PaymentNum')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{paymentNumber || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('CashOut:PaymentType')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{getTransactionTypeLabel(paymentType ?? '')}</Text>
                </View>
              </View>
              <View style={styleFirstElement}>
                <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('CashOut:PayableAmount')}</Text>
                <Text style={{ fontSize: 15, marginTop: 4 }}>{payableAmount}</Text>
              </View>
            </View>

            {/* <Title title={t('CashOut:RelatedDoc')}></Title>
            <View style={styleView}>
              <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('CashOut:RelatedDoc')}</Text>
                </View>
                <TouchableHighlight style={[generalStyles.btnRed]}>
                  <Text style={[generalStyles.submitTextWhite]}>{t('common:View')}</Text>
                </TouchableHighlight>
              </View>
            </View> */}

            <Title title={t('CashOut:PayeeInfo')}></Title>
            <View style={styleView}>
              <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:PayeeName')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{payeeInfo?.payeeName || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('CashOut:IDCard')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{payeeInfo?.idNumber || '-'}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('claim:RelationWithPolicyOwner')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{relationship || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:DateOfBirth')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>
                    {payeeInfo?.dob ? moment(payeeInfo?.dob).format('DD/MM/yyyy') : '-'}
                  </Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:Gender')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{getGenderName(payeeInfo?.gender)}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:Occupation')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{occupationName || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('claim:ResidenceCountry')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{residenceCountryName || '-'}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:Nationality')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{nationName || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:Address')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{payeeInfo?.address || '-'}</Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={styleFirstElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{t('claim:Nationality2')}</Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{nation2Name || '-'}</Text>
                </View>
                <View style={styleElement}>
                  <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>
                    {t('claim:ForeignAddress')}
                  </Text>
                  <Text style={{ fontSize: 15, marginTop: 4 }}>{payeeInfo?.foreignAddress || '-'}</Text>
                </View>
              </View>
              <DocumentCashOut
                onSubmit={(documents) => {
                  documentRef.current = documents
                  // onSubmit()
                }}
                setDocuments={(documents) => {
                  setSelectedDocumentType(documents)
                }}
                triggerOnNext={registerDocCallback}
                triggerOnReset={registerResetDocCallback}
                onChangeTab={onChangeTab}
                visible={tabIndex === 0 ? true : false}
              />
              {tabIndex === 1 && (
                // display documents confirm screen
                <DocumentCashOutConfirm selectedDocumentType={selectedDocumentType} />
              )}
            </View>
            {/* {tabIndex === 1 && (
              <View>
                <RequestAuthen
                  hideOTP={false}
                  requestAuthenData={eformData as RequestAuthenticateData.EformRequestAuthenticateData}
                  authenForm={requestAuthenForm}
                  updateSendDate={updateSendDate}
                  updatedocReviewed={updatedocReviewed}
                  updateRequestAuthenData={setEformData}
                  isByPassAuthPaper={true}
                  officeCode={officeCode}
                  transactionType={TransactionType.COMMON_REQUEST_FORM}
                />
              </View>
            )} */}
          </View>
          {roles.includes(Permission['EditableCashoutdetail']) && (
            <TaskDetailAction
              listAction={[
                {
                  name: t('submission:Continue'),
                  action: async () => {
                    if(validateForm() && validateNoFile()) {
                      docCallbackRef.current()
                      setEformData({
                        ...eformData,
                        transactionName: getTransactionTypeLabel(TransactionType.COMMON_REQUEST_FORM),
                        collerationId: props.route.params?.policyNum,
                        transaction: TransactionType.COMMON_REQUEST_FORM
                      })
                    }
                  },
                  isVisible: tabIndex === 0
                },
                {
                  name: t('submission:SendRequest'),
                  action: async () => {
                    const requestAuthenItem = requestAuthenForm.getValues()
                    setValue('otp.otpInput', 111111)
                    onSubmitDataWithAuthen(onSubmit())(requestAuthenItem)
                  },
                  isSubmissionDisabled: false,
                  // isHighLightBtn: validateSubmitedButtonForAddLack(),
                  isHighLightBtn: false,
                  isVisible: tabIndex === 1
                }
              ]}
            />
          )}
        </ScrollView>
      </View>
    </SafeAreaView>
  )
}

const styleView = {
  backgroundColor: '#FAFAFA',
  borderRadius: 8,
  borderWidth: 1,
  borderColor: '#d3dce6',
  marginBottom: 15,
  paddingTop: 20
}
const styleElement = {
  paddingBottom: 16,
  width: '100%',
  maxWidth: '33.3333333333%',
  paddingRight: 15,
  paddingLeft: 15
}
const styleFirstElement = {
  paddingBottom: 16,
  width: '100%',
  maxWidth: '33.3333333333%',
  paddingRight: 15,
  paddingLeft: 20
}
