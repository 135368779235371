import { SourceType } from './../SourceType'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

const Duplication = t.array(
  t.type({
    clientNum: t.string,
    relationship: Maybe(t.string),
    clientName: t.string
  })
)
const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.CHANGE_CLIENT_INFO),
        t.literal(TransactionType.CHANGE_NATIONAL_ID_CARD)
      ])
    }),
    codec
  ])

const payload = t.type({
  body: t.type({
    nationalId: Maybe(t.string),
    nationality: Maybe(t.string),
    fullName: Maybe(t.string),
    dob: Maybe(t.string),
    gender: Maybe(t.string), // pruonline k co
    certificateNum: Maybe(t.string),
    policyNo: Maybe(t.string),
    poClientNum: Maybe(t.string),
    country: Maybe(t.string),
    externalIds: Maybe(
      t.type({
        NATIONAL_ID: Maybe(t.string),
        idType: Maybe(t.string),
        idNumber: Maybe(t.string),
        issuedBy: Maybe(t.string),
        issuedDate: Maybe(t.string)
      })
    )
  })
})

const payloadZaloEkiosk = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    dob: Maybe(t.string),
    name: Maybe(t.string),
    nationality: Maybe(t.string),
    sex: Maybe(t.string),
    attributes: Maybe(
      t.type({
        LOCATION_CODE: Maybe(t.string),
        LOCATION_DESC: Maybe(t.string),
        NATIONAL_ID_ISSUE_DATE: Maybe(t.string)
      })
    ),
    externalIds: Maybe(
      t.type({
        NATIONAL_ID: t.string
      })
    ),
    attributesExt: Maybe(
      t.type({
        issuedDate: Maybe(t.string),
        issuedBy: Maybe(t.string),
        idType: Maybe(t.string),
        idNumber: Maybe(t.string)
      })
    )
  })
})

const payloadPruOnline = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    dob: Maybe(t.string),
    name: Maybe(t.string),
    attributes: Maybe(
      t.type({
        NATIONAL_ID_ISSUE_DATE: Maybe(t.string)
      })
    ),
    externalIds: Maybe(
      t.type({
        BIRTH_CERTIFICATE: Maybe(t.string),
        NATIONAL_ID: Maybe(t.string)
      })
    )
  })
})

const payloadPulse4Ops = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    surName: Maybe(t.string),
    firstName: Maybe(t.string),
    dob: Maybe(t.string),
    sex: Maybe(t.string),
    nationality: Maybe(t.string),
    occupation: Maybe(t.string),
    contactDetails: Maybe(
      t.type({
        phone: t.type({
          countryCode: t.string,
          value: t.string
        }),
        email: t.type({
          value: t.string
        })
      })
    ),
    addressDetails: Maybe(t.type({
      ADDRESS: Maybe(t.type({
        line1: Maybe(t.string),
        city: Maybe(t.string),
        district: Maybe(t.string),
        subDistrict: Maybe(t.string),
        countryCode: Maybe(t.string),
      })),
      FOREIGN_ADDRESS: Maybe(t.type({
        line1: Maybe(t.string),
        countryCode: Maybe(t.string),
      }))
    })),
    attributesExt: Maybe(
      t.type({
        clientNum: Maybe(t.string),
        idType: Maybe(t.string),
        idNumber: Maybe(t.string),
        issuedBy: Maybe(t.string),
        issuedDate: Maybe(t.string),
        usTaxDeclarationFrom: Maybe(t.string),
        occupationFlag: Maybe(t.string),
        specificJob: Maybe(t.string),
        title: Maybe(t.string),
        workPlace: Maybe(t.string),
        workAddress: Maybe(t.string),
        salary: Maybe(t.string),
        duplicatePhones: Maybe(Duplication),
        duplicateEmails: Maybe(Duplication),
        PO_EMAIL: Maybe(t.string),
        PO_ID_NUM: Maybe(t.string),
        PO_MOBILE: Maybe(t.string),
        PO_NAME: Maybe(t.string),
        NATIONALITY_2: Maybe(t.string),
        nationality2Name: Maybe(t.string),
        countryName: Maybe(t.string),
        districtName: Maybe(t.string),
        wardName: Maybe(t.string),
        taxResidencyCountry: Maybe(t.string),
      })
    )
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload: payloadZaloEkiosk
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadZaloEkiosk
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPruOnline
  })
)

const LandingPage = Base(
  t.type({
    source: t.literal(SourceType.LANDING_PAGE),
    payload: payloadZaloEkiosk
  })
)
export const ChangeClientInfo = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, PruGreat, PruOnline, LandingPage])
export type ChangeClientInfo = t.TypeOf<typeof ChangeClientInfo>

//email, phone (pulse co)
