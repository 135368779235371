import {
  AppContext,
  assets,
  PanelGroupProps,
  PanelItem,
  PanelProp,
  SearchPanel,
  TransactionType
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestRelatedCashlessCollectionStackParamList } from './RequestRelatedCashlessCollectionStackParamList'
import { PolicyInfoHeader } from '../../ps-submission/transactionScreen/screens'

type Props = StackScreenProps<RequestRelatedCashlessCollectionStackParamList, 'RequestRelatedCashlessCollectionScreen'>

export const RequestRelatedCashlessCollection = (props: Props) => {
  const { t } = useTranslation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const navigationParams = {
    policyNum: props.route.params.policyNum,
    policyOwner: props.route.params.policyOwner,
    officeCode: props.route.params.officeCode,
    officeName: props.route.params.officeName,
    proposalNum: props.route.params.proposalNum
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.navigate('HomeScreen')
        },
        {
          title: t('menu:PCRequest'),
          navigate: () => navigation.navigate('PCRequestStack', { screen: 'PCRequestScreen' })
        },
        {
          title: t('common:RequestRelatedCashlessCollection'),
          navigate: () => null
        }
      ])
    }
  }, [isFocused])

  const itemsGroup: PanelGroupProps[] = [
    {
      id: 'WriteOff',
      name: t('TaskManagement:WriteOff'),
      icon: () => <assets.ClaimService />,
      children: [
        {
          id: 'CustomersAddPaymentInformation',
          name: t('TransactionType:CUSTOMERS_ADD_PAYMENT_INFORMATION'),
          navigator: () =>
            props.navigation.navigate('WriteOffSubmissionCommonScreen', {
              ...navigationParams,
              transactionType: TransactionType.CUSTOMERS_ADD_PAYMENT_INFORMATION
            })
        },
        {
          id: 'RefundAccordingToCustomerRequest',
          name: t('TransactionType:REFUND_ACCORDING_TO_CUSTOMER_REQUEST'),
          navigator: () =>
            props.navigation.navigate('WriteOffSubmissionCommonScreen', {
              ...navigationParams,
              transactionType: TransactionType.REFUND_ACCORDING_TO_CUSTOMER_REQUEST
            })
        },
        {
          id: 'RequestACardRefund',
          name: t('TransactionType:REQUEST_A_CARD_REFUND'),
          navigator: () =>
            props.navigation.navigate('WriteOffSubmissionCommonScreen', {
              ...navigationParams,
              transactionType: TransactionType.REQUEST_A_CARD_REFUND
            })
        },
        {
          id: 'RequestToAdjustPaymentContent',
          name: t('TransactionType:REQUEST_TO_ADJUST_PAYMENT_CONTENT'),
          navigator: () =>
            props.navigation.navigate('WriteOffSubmissionCommonScreen', {
              ...navigationParams,
              transactionType: TransactionType.REQUEST_TO_ADJUST_PAYMENT_CONTENT
            })
        }
      ]
    }
  ]

  const data: PanelProp | null = {
    itemsGroup: itemsGroup,
    itemsSearch: ([] as PanelItem[]).concat(...itemsGroup.map((group) => group.children))
  }

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: navigationParams?.policyNum ?? ''
    },
    {
      label: t('common:PolicyOwner'),
      value: navigationParams.policyOwner
    },
    {
      label: t('common:Office'),
      value: `${props.route.params?.officeCode ?? ''} - ${props.route.params?.officeName ?? ''}`
    }
  ]
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <PolicyInfoHeader menus={headers} />
        {data && <SearchPanel data={data} />}
      </ScrollView>
    </SafeAreaView>
  )
}
