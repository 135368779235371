export namespace AddLackConstant {
    export interface addLackT17Model {
        docTypeCode: string,
        docId: string,
        category: string,
        transactionType: string
        }
    export const AddLackT17List:addLackT17Model[] = [
        { docTypeCode: 'DSA05', category: 'DS', docId: '20101011', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA05', category: 'DS', docId: '20101011', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA06', category: 'DS', docId: '20101021', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA06', category: 'DS', docId: '20101021', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA07', category: 'DS', docId: '20101041', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA07', category: 'DS', docId: '20101041', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA08', category: 'DS', docId: '20102141', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA08', category: 'DS', docId: '20102141', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA09', category: 'DS', docId: '20102161', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA09', category: 'DS', docId: '20102161', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA10', category: 'DS', docId: '20102171', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA10', category: 'DS', docId: '20102171', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA11', category: 'DS', docId: '20102031', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA11', category: 'DS', docId: '20102031', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA12', category: 'DS', docId: '20102991', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA12', category: 'DS', docId: '20102991', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA13', category: 'DS', docId: '20102181', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA13', category: 'DS', docId: '20102181', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA14', category: 'DS', docId: '20102191', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA14', category: 'DS', docId: '20102191', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA15', category: 'DS', docId: '20102011', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA15', category: 'DS', docId: '20102011', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA17', category: 'DS', docId: '20102201', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA17', category: 'DS', docId: '20102201', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA18', category: 'DS', docId: '20101031', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA18', category: 'DS', docId: '20101031', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA19', category: 'DS', docId: '20102211', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA19', category: 'DS', docId: '20102211', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA20', category: 'DS', docId: '20102301', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA20', category: 'DS', docId: '20102301', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA21', category: 'DS', docId: '20102291', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA21', category: 'DS', docId: '20102291', transactionType: 'AGENT_ONBOARD_REINS'},
        { docTypeCode: 'DSA22', category: 'DS', docId: '20102281', transactionType: 'AGENT_ONBOARD_NEW'},
        { docTypeCode: 'DSA22', category: 'DS', docId: '20102281', transactionType: 'AGENT_ONBOARD_REINS'},
    ]
}




