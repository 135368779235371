import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { LDApi } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import i18next from 'i18next'
import moment from 'moment'

const Body = t.type({
  phoneNumber: t.string,
  bankNumber: t.string,
  bankName: t.string
})

const OTPRequestC = t.type({
  body: Body
})

type OTPRequest = t.TypeOf<typeof OTPRequestC>

const SubmitBankInfoC = t.type({
  body: Body
})

type SubmitBankInfo = t.TypeOf<typeof SubmitBankInfoC>

export interface VerifyOTP {
  body: BodyOTP
  otpTransaction: OtpTransaction
}

export interface BodyOTP {
  phoneNumber: string
  policyNumber: string
  bankAccount: string
  bankName: string
}

export interface OtpTransaction {
  receiverPhone: string
  otp: string
}

export namespace Services {
  export const checkProposal = (data: { processInstanceId: string; proposalNumber: string; idNumber: string }) =>
    pipe(
      LDApi.Postlogin(`authentication/api/v1/kyc/customer`)(
        t.type({
          credential: Maybe(t.type({
            access_token: Maybe(t.string),
            expires_in: Maybe(t.number),
            refresh_expires_in: Maybe(t.number),
            refresh_token: Maybe(t.string),
            token_type: Maybe(t.string),
            error: Maybe(t.string),
            error_code: Maybe(t.string),
            error_description: Maybe(t.string),
          })),
          phoneNumber: Maybe(t.string),
          processInstanceId: Maybe(t.string),
          status: Maybe(t.string),
          endTime: Maybe(t.string)
        })
      )(data),
      ZIO.map((res) => {
        return res
      })
    )

  export const getQuotaList = (processInstanceId: string) =>
    pipe(
      LDApi.get(`landing-page/quota/get-question/${processInstanceId}`)(
        t.type({
          body: Maybe(
            t.array(
              t.type({
                questionCode: t.string,
                questionName: t.string,
                passCheck: t.string
              })
            )
          )
        })
      )
    )

  export const saveQuestion = (data: {
    body: {
      processInstanceId: string
      proposalNumber: string
      answerResults: Array<{
        questionCode: string
        passCheck: string
        commentUser: string
        decisionResult: string
        questionContent: string
        answerUser: string
      }>
      otp: string
    }
  }) =>
    pipe(
      LDApi.post(`landing-page/quota/add-answer`)(
        t.type({
          exchangeId: t.string,
          body: Maybe(t.string),
          responseStatus: Maybe(
            t.type({
              code: Maybe(t.number),
              message: Maybe(t.string),
              errors: Maybe(t.array(t.type({
                code: Maybe(t.string),
                message: Maybe(t.string),
              })))
            })
          )
        })
      )(data),
      ZIO.map((res) => res)
    )
  export const otpRequest = (data: {
    body: {
      processInstanceId: string
      proposalNumber: string
      transactionCode: string
      idNumber: string
      sourceOtp: string
    }
  }) =>
    pipe(
      LDApi.post(`landing-page/api/v1/otp/send-otp`)(
        t.type({
          body: Maybe(t.type({
            processInstanceId: t.string,
            transactionCode: t.string,
            phoneNumber: t.string
          })),
          responseStatus: Maybe(t.type({
            code: Maybe(t.number),
            message: Maybe(t.string),
            errors: Maybe(t.array(t.type({
              code: Maybe(t.string),
              message: Maybe(t.string),
            })))
          }))
        })
      )(data)
    )

  export const verifyOTP = (data: VerifyOTP) =>
    pipe(
      LDApi.post(`authentication/api/v1/add-bank-account/verify-otp`)(
        t.type({
          responseStatus: t.type({
            code: Maybe(t.number),
            message: Maybe(t.string)
          })
        })
      )({
        ...data,
        body: {
          ...data.body,
          status: 'COMPLETED'
        }
      }),
      ZIO.map((res) => {
        return res
      })
    )
}
