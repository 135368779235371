import * as D from 'date-fns'

export namespace Format {
  const currencyFormat = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' })

  export const currency = (n: number) => currencyFormat.format(n)

  export const date = (date: Date) => D.format(date, 'dd/MM/yyyy')

  export const dateDigit = (date: Date) => D.format(date, 'yyyyMMdd')

  export const datetime = (date: Date) => D.format(date, 'dd/MM/yyyy HH:mm')

  const numberFormat = new Intl.NumberFormat('vi-VN')

  export const number = (n: number) => numberFormat.format(n)

  export const numberWithDot = (input: number | string) => input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  export const numberWithComma = (input: number | string) => input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  export const currencyForBusiness = (input?: number | string | null) => (input ? `${numberWithComma(input)}đ` : '-')
}
