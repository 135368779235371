import React, { useState } from 'react'
import { View, StyleSheet, useWindowDimensions, TouchableOpacity, Platform, Text } from 'react-native'
import {
  ErrorHandling,
  RBAC,
  useMobile,
  FromToDateRangeMonth,
  DigitalInquiryService,
  PulseOpsFormat,
  Permission,
  AppContext,
  Input,
  DatePicker,
  sharedStyle
} from '@pulseops/common'
import TableContainer from '@material-ui/core/TableContainer'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { StackScreenProps } from '@react-navigation/stack'
import { DIStackParamList } from './DIStackParamList'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { PolicyInquiryExportPopup } from './policy-inquiry-export'
import { useIsFocused } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'
import { isFuture, isValid } from 'date-fns'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
})

type Props = StackScreenProps<DIStackParamList, 'PolicyInquiryScreen'>

export const PolicyInquiryScreen = (props: Props) => {
  const { width, height } = useWindowDimensions()
  const { isWide } = useMobile()

  const classes = useStyles()

  const [policy, setPolicy] = React.useState<string>('')
  const [nationalId, setNationalId] = React.useState<string>('')
  const [fromDate, setFromDate] = React.useState<Date | null>(null)
  const [toDate, setToDate] = React.useState<Date | null>(null)

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [loading, bindLoading] = useLoading(false)
  const [isSearch, setIsSearch] = React.useState<boolean>(false)

  const { t } = useTranslation()

  const roles: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  const [errMess, setErrMess] = useState<string>('')

  const [rows, setRows] = React.useState<
    {
      policyNo: string
      proposalNo: string
      planName: string
      productCode: string
      productName: string
      partnerSource: string
    }[]
  >([])

  const getPolicy = (pageSize: number, pageNum: number) => {
    const dateDiff = toDate && fromDate ? toDate.getTime() - fromDate.getTime() : 0
    if ((!policy && !nationalId && !fromDate && !toDate) || (fromDate && !toDate) || (!fromDate && toDate)) {
      setErrMess(t('message:MS990020'))
    } else if (
      (fromDate && (!isValid(fromDate) || isFuture(fromDate))) ||
      (toDate && (!isValid(toDate) || isFuture(toDate)))
    ) {
      setErrMess(t('message:MS990020'))
    } else if (FromToDateRangeMonth(3)({ fromDate, toDate })) {
      setErrMess(t('message:MS080004', { k: '3' }))
    } else if (!!policy && policy.length !== 9) {
      setErrMess(t('message:MS990019'))
    } else if (!!nationalId && nationalId.length !== 9 && nationalId.length !== 12) {
      setErrMess(t('message:MS090008'))
    } else if (dateDiff < 0) {
      setErrMess(t('message:MS030044'))
    } else {
      setErrMess('')
      pipe(
        DigitalInquiryService.getPolicyList(
          policy,
          nationalId,
          PulseOpsFormat.datetoFormat(fromDate, 'yyyyMMDD'),
          PulseOpsFormat.datetoFormat(toDate, 'yyyyMMDD'),
          {
            pageSize,
            pageNum
          }
        ),
        ZIO.tap((res) => {
          setTotalItem(res.totalElements)
          setRows(
            res.content
              ? res.content.map((x) => ({
                  policyNo: x.policyNo,
                  proposalNo: x.proposalNo || '',
                  planName: x.planName || '',
                  productCode: x.productCode || '',
                  productName: x.productName || '',
                  partnerSource: x.source || ''
                }))
              : []
          )
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
    }
  }

  const exportData = () => {
    setOpenExport(true)
  }

  const notEmpty = (): boolean => {
    return (
      rows.length > 0 &&
      !!roles &&
      (roles.includes(Permission['DI-Export1']) ||
        roles.includes(Permission['DI-Export2']) ||
        roles.includes(Permission['DI-Export3']) ||
        roles.includes(Permission['DI-Export4']) ||
        roles.includes(Permission['DI-Export5']))
    )
  }

  const [openExport, setOpenExport] = useState<boolean>(false)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        {
          title: t('menu:Digital'),
          navigate: () => props.navigation.navigate('DIDashboardScreen')
        },
        {
          title: t('menu:PolicyInquiry'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    if (isSearch) {
      const pageNum = 0
      const pageSize = 10
      setPageNum(pageNum)
      setPageSize(pageSize)
      getPolicy(pageSize, pageNum)
      setIsSearch(false)
    }
  }, [isSearch])

  return (
    <View style={{ backgroundColor: '#FFF', height: height, flex: 1 }}>
      <ScrollView
        style={{
          width: width >= 1440 ? 1440 : '100%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        {loading && <LinearProgress color="secondary" />}
        <View style={styles.searchContainer}>
          <View style={styles.inputContainer}>
            <Input
              labelStyle={{ marginBottom: 8 }}
              title={t('common:PolicyNumber')}
              value={policy}
              maxLength={9}
              inputType="number"
              onChange={(text) => {
                setPolicy(text.trim())
              }}
            />
            {/* <TextInput
              style={styles.input}
              placeholder={'Số HSYCBH'}
              onChangeText={text => {
                setPolicy(text.trim())
              }}
              underlineColorAndroid="transparent"
            /> */}
          </View>
          <View style={styles.inputContainer}>
            <Input
              labelStyle={{ marginBottom: 8 }}
              title={t('DigitalInquiry:IDNumber')}
              value={nationalId}
              inputType="number"
              maxLength={12}
              onChange={(text) => {
                setNationalId(text.trim())
              }}
            />
            {/* <TextInput
              style={styles.input}
              placeholder={'Số HSYCBH'}
              onChangeText={text => {
                setPolicy(text.trim())
              }}
              underlineColorAndroid="transparent"
            /> */}
          </View>
          <View style={styles.inputContainer}>
            <DatePicker
              maxDate={new Date()}
              label={t('common:FromDate')}
              value={fromDate}
              onChange={(val) => {
                val?.setHours(0, 0, 0, 0)
                setFromDate(val)
              }}
              alwaysShow={true}
            ></DatePicker>
            {/* <DatePickerTable value={fromDate} onChange={val => setFromDate(val)} styles={{backgroundColor: '#FFF', padding: 13}}></DatePickerTable> */}
          </View>
          <View style={styles.inputContainer}>
            <DatePicker
              maxDate={new Date()}
              label={t('common:ToDate')}
              value={toDate}
              onChange={(val) => {
                val?.setHours(0, 0, 0, 0)
                setToDate(val)
              }}
              alwaysShow={true}
            ></DatePicker>
            {/* <DatePickerTable value={toDate} onChange={val => setToDate(val)} styles={{backgroundColor: '#FFF', padding: 13}}></DatePickerTable> */}
          </View>
          <View style={styles.btnContainer}>
            <TouchableOpacity
              style={styles.searchBtn}
              onPress={() => {
                setIsSearch(true)
              }}
            >
              <Text style={styles.searchBtnText}>{t('common:Search')}</Text>
            </TouchableOpacity>
          </View>
        </View>
        {!!errMess && <Text style={[styles.section, { color: '#ED1B2C', marginTop: 5 }]}>{errMess}</Text>}
        <View style={[styles.section, { flexDirection: 'row', justifyContent: 'flex-end' }]}>
          {notEmpty() && (
            <TouchableOpacity onPress={() => exportData()} style={sharedStyle.button}>
              <Text style={sharedStyle.textButton}>{t('common:Export')}</Text>
            </TouchableOpacity>
          )}
        </View>

        {isWide && Platform.OS === 'web' && rows.length > 0 && (
          <View style={[styles.section, { marginTop: 10 }]}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    {/* Số hợp đồng */}
                    <TableCell>{t('common:PolicyNumber')}</TableCell>
                    {/* Số HSYCBH */}
                    <TableCell>{t('policy:proposalNo')}</TableCell>
                    {/* Gói bảo hiểm */}
                    <TableCell>{t('policy:planName')}</TableCell>
                    {/* Mã sản phẩm */}
                    <TableCell>{t('policy:productCode')}</TableCell>
                    {/* Tên sản phẩm */}
                    <TableCell>{t('policy:productName')}</TableCell>
                    {/* Đối tác/Nguồn */}
                    <TableCell>{t('policy:PartnerSource')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={`DITable-${i}`}>
                      <TableCell component="th" scope="row">
                        <Text
                          style={{ color: 'blue' }}
                          onPress={() => {
                            props.navigation.navigate('PolicyInquiryDetailScreen', { policyNum: row.policyNo })
                          }}
                        >
                          {row.policyNo}
                        </Text>
                      </TableCell>
                      <TableCell>{row.proposalNo}</TableCell>
                      <TableCell>{row.planName}</TableCell>
                      <TableCell>{row.productCode}</TableCell>
                      <TableCell>{row.productName}</TableCell>
                      <TableCell>{row.partnerSource}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getPolicy(pageSize, page)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                getPolicy(Number(e.target.value), pageNum)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          </View>
        )}

        <PolicyInquiryExportPopup
          selectedValue={{
            policy,
            nationalId,
            fromDate: PulseOpsFormat.datetoFormat(fromDate, 'yyyyMMDD'),
            toDate: PulseOpsFormat.datetoFormat(toDate, 'yyyyMMDD'),
            roles: roles || []
          }}
          open={openExport}
          onClose={() => {
            setOpenExport(false)
          }}
        ></PolicyInquiryExportPopup>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9'
  },

  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    marginTop: 30,
    marginLeft: 30,
    marginRight: 30,
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    paddingHorizontal: 16,
    paddingVertical: 16
  },

  searchBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 30,
    paddingHorizontal: 18.5,
    paddingVertical: 15,
    lineHeight: 34
  },

  searchBtnText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#fff'
  },

  inputContainer: {
    // width: '30%',
    flex: 1,
    paddingRight: 5
  },

  btnContainer: {
    marginLeft: 10
  },

  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 40,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: '#fff',
    color: '#424242',
    borderRadius: 8,
    fontSize: 15,
    height: 46,
    borderWidth: 1,
    borderColor: '#D3DCE6'
  },

  searchIcon: {
    position: 'absolute',
    right: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
})
