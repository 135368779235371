import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface AssignmentModel {
  tag: TransactionType.ASSIGNMENT
  clientName: string
  detail: api.Assignment
}

export const Assignment = (detail: api.Assignment, poName: string): AssignmentModel => ({
  tag: TransactionType.ASSIGNMENT,
  clientName: poName ?? '',
  detail
})
