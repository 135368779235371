// import { ZIO } from '@mxt/zio'
import {
  // AppContext,
  // ErrorHandling,
  form2,
  // GeneralService,
  ImgUploadMutiple,
  sharedStyle,
  // StorageBlob,
  TaskType,
  TransactionType
} from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import _ from 'lodash'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { SC } from '../common'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps, UploadedFilesInfo } from './policy-service-props'

const FileMeta = t.type({
  fileName: t.string,
  fileExtension: t.string,
  size: t.number,
  base64: t.string,
  uploadedDate: form2.date.required,
  file: form2.file.required
})
type FileMeta = t.TypeOf<typeof FileMeta>
type FileAttachments = t.Branded<
  FileMeta[],
  {
    readonly FileAttachments: unique symbol
  }
>

const FormData = t.type({
  fileAttachments: pipe(
    t.array(FileMeta),
    form2.refine(
      (files): files is FileAttachments => files.length > 0,
      () => i18next.t('message:MS020001', { field: i18next.t('submission:HealthRedeclaration') }),
      'FileAttachments'
    )
  )
})
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

export const HealthRedeclaration = (props: PolicyServiceProps<null>) => {
  const { t } = useTranslation()
  // const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const policyNumber = props.policyNumber!
  const defaultValues: FormDataRaw = {
    fileAttachments: []
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })

  const getUploadedFilesInfo = (formData: FormDataRaw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    uploadedFileList.push({
      uploadFiles: formData.fileAttachments as FileMeta[],
      transactionType: TransactionType.HEALTH_DECLARATION,
      docTypeCode: 'DPS08',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: props.officeCode ?? ''
    })
    return uploadedFileList
  }

  props.initSubmission({
    validate: async (isContinue) => {
      const _form = await handleSubmit(() => undefined)()
      if (E.isRight(_form)) {
        const form = _form.right

        // let fileMeta: StorageBlob.FileContentSubmit[] = []
        // if (!isContinue) {
        //   showGlobalLoading(true)
        //   fileMeta = await pipe(
        //     GeneralService.getMetaData(TransactionType.HEALTH_DECLARATION || '', 'DPS08'),
        //     ZIO.flatMap((metaData) => {
        //       const files: StorageBlob.FileContent[] = form.fileAttachments.map((x) => ({
        //         file: x.file,
        //         metaData: {
        //           type: metaData.data.type,
        //           doctype: metaData.data.doctypeEn,
        //           class: metaData.data.classFilenet,
        //           docid: metaData.data.docID,
        //           maindoc: metaData.data.mainDoc,
        //           subdoc: metaData.data.subDoc,
        //           polnum: policyNumber,
        //           batchno: metaData.data.batchNo
        //         }
        //       }))
        //       return StorageBlob.uploadToSubmit('PS', policyNumber)(files)
        //     }),
        //     ErrorHandling.run({})
        //   )
        //   showGlobalLoading(false)
        // }

        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/health-redeclaration`,
          body: null,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.HEALTH_DECLARATION),
          collerationId: props.policyNumber ?? '',
          transaction: TransactionType.HEALTH_DECLARATION,
          // documents: fileMeta,
          uploadedFilesInfo: getUploadedFilesInfo(form)
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset(defaultValues)
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:HealthRedeclaration')}</Text>
        <View style={sharedStyle.sectionContent}>
          <Text style={{ color: '#70777E', fontSize: 15, fontWeight: 'bold' }}>
            {t('submission:HERED_FileAttachment')}
            <Text style={{ color: '#ed1c2e' }}> *</Text>
          </Text>
          <Controller
            name={'fileAttachments'}
            control={base.control}
            render={({ field: { value, onChange } }) => {
              const mapValue =
                value?.map((file) => ({
                  ...file,
                  uploadedDate: file.uploadedDate!,
                  file: file.file!
                })) || []
              return (
                <View>
                  <ImgUploadMutiple
                    value={mapValue}
                    onChange={onChange}
                    disabled={props.isConfirmed}
                    errorMessage={_.get(base.formState.errors, 'fileAttachments.message')}
                  />
                  {base.formState.errors.fileAttachments && (
                    <SC.ErrorText>{_.get(base.formState.errors, 'fileAttachments.message')}</SC.ErrorText>
                  )}
                </View>
              )
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}
