import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import { SubmissionService } from './SubmissionService'
import * as t from 'io-ts'
// import { PulseOpsFormat } from '..'
import moment from 'moment'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { NumberFromString } from 'io-ts-types'
import { PulseOpsFormat } from '../Formatter'
import { AuthService } from '../auth/AuthService'
import { CancelRiderService } from './CancelRiderService'
import { UploadFileToStorageInfo, RecordFileInfo, FailListInfo, PulseOpsApi } from '@pulseops/common'
import * as api from './model/task-detail-api'

export namespace CashLessService {
  export const ProposalDTO = t.type({
    agentCode: Maybe(t.string),
    agentName: Maybe(t.string),
    contractTypeCode: Maybe(t.string),
    frequency: Maybe(t.string),
    location: Maybe(t.string),
    officeCode: Maybe(t.string),
    paymentMethod: Maybe(t.string),
    poIsLA: Maybe(t.string),
    policyNum: Maybe(t.string),
    policyTerm: Maybe(t.number),
    premiumTerm: Maybe(t.number),
    proposalDate: Maybe(t.string),
    proposalNum: Maybe(t.string),
    receiptDate: Maybe(t.string),
    sumAssured: Maybe(t.number),
    totalPremium: Maybe(t.number),
    trNumber: Maybe(t.string)
  })

  export type ProposalDTO = t.TypeOf<typeof ProposalDTO>

  const ReceiptApiC = t.type({
    action: t.string,
    receiptNo: Nullable(t.string),
    transactionCode: Nullable(t.string),
    receiptDate: Nullable(t.string),
    paymentType: Nullable(t.string),
    policyNumber: Nullable(t.string),
    proposalNumber: Nullable(t.string),
    lastUpdatedBy: Nullable(t.string),
    createdDate: Nullable(t.string), // ưu tiên lấy field này -> receiptDate
    transactions: Nullable(
      t.array(
        t.type({
          amount: t.number,
          newDescription: Nullable(t.string),
          subAccountCode: t.string,
          subAccountType: t.string
        })
      )
    )
  })

  export type ReceiptApiC = t.TypeOf<typeof ReceiptApiC>

  export const InterestRate = t.type({
    fromDate: t.string,
    toDate: Maybe(t.string),
    rate: NumberFromString
  })

  export type InterestRate = t.TypeOf<typeof InterestRate>
  export interface CashLessDetail {
    totalAmount: number
    basicPremium: number
    policyInfo?: SubmissionService.PolicyType
    proposalInfo?: ProposalDTO
    interestAPL: number
    interestOPL: number
    balanceAPL: number
    balanceOPL: number
    discountAmount: number
    lastPremiumDate?: string
    lastTransactionDate?: string
    loanStarDate?: string
    remainingCore: number
    coreReceips: ReceiptApiC[]
    coreJournals: ReceiptApiC[]
    coreNonCash: ReceiptApiC[]
    coreDiscounts: ReceiptApiC[]
    minAplDate?: Date
    minOplDate?: Date
    LPSAmount: number
    LPUAmount: number
    LNVPAmount: number
    LPE1Amount: number
    interestRates: { fromDate: Date; toDate: Date; rate: number }[]
    contractNameRaw?: {
      en: string
      vi: string
    }
    transactionStatus?: string
    warningCode?: string
    premiumAmount?: number
    insPremium?: number
  }

  export const getRiderTable = pipe(
    POApi.get(`wf-api/rider/get-list`)(
      t.type({
        body: t.array(
          t.type({
            id: t.string,
            code: t.string,
            name: t.string,
            nameVi: t.string
          })
        )
      })
    ),
    ZIO.map((riderInfoList) => {
      return riderInfoList.body
    })
  )

  export const getTV636 = pipe(
    POApi.get('wf-api/table/tv636/get-all')(
      t.type({
        body: t.array(
          t.type({
            id: t.string,
            item: t.string,
            longDesc: t.string,
            shortDesc: t.string
          })
        )
      })
    ),
    ZIO.map((res) => {
      return res.body
    })
  )

  export const getPolicy = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(
        t.type({
          body: t.type({
            policyNo: Maybe(t.string),
            owners: t.type({ id: Maybe(t.string) })
          })
        })
      ),
      ZIO.map(({ body }) => (body.owners.id ? body.policyNo : undefined)),
      ZIO.catchAll(() => ZIO.succeed(undefined))
    )

  export const getProposalInfo = (proposalNumber: string) =>
    pipe(
      POApi.post(`wf-api/customer/proposal-info`)(
        t.type({
          body: t.type({
            policyNum: Maybe(t.string)
          })
        })
      )({
        body: {
          proposalNum: proposalNumber
        }
      }),
      ZIO.map((res) => res.body.policyNum),
      ZIO.catchAll(() => ZIO.succeed(undefined)) // tạm thời để default là một số policyNumber tạm thời
    )

  export const updatePolicyNumber = (
    taskId: string,
    payload: any,
    processInstanceId: string
    // policyNumberUpdate: string
  ): Task<void> =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((user) => {
        return pipe(
          ZIO.effect(() => {
            // attempt disable this feature update common field

            // return policyNumberUpdate.length === 8 || policyNumberUpdate.length === 9
            //   ? [
            //       {
            //         name: policyNumberUpdate.length === 8 ? 'policyNumber' : 'proposalNumber',
            //         type: string,
            //         value: policyNumberUpdate
            //       }
            //     ]
            //   : []
            return []
          }),
          ZIO.flatMap((variables) =>
            POApi.post(`pulseops/api/v1/payload/save-payload-info/${taskId}`)(t.unknown)({
              logChange: [],
              payload,
              processInstanceId,
              user: user.email,
              variables
            })
          )
        )
      }),
      ZIO.asVoid
    )

  export const deleteReceipts = (businessKey: string, transactionCodes: string[]) => {
    return pipe(
      POApi.post(`premium-collection-internal/premium/report-data/delete/history-receipt`)(t.unknown)({
        businessKey,
        transactionCodes
      }),
      ZIO.map((_) => true),
      ZIO.catchAll(() => ZIO.succeed(false))
    )
  }

  export const getHistoryReceipt = (businessKey: string) =>
    pipe(
      POApi.get(`premium-collection-internal/premium/report-data/process-instance-id/${businessKey}`)(
        t.type({
          body: Nullable(
            t.type({
              remainingAmount: Nullable(t.number),
              policyNumber: Nullable(t.string),
              receiptHistory: Nullable(t.array(ReceiptApiC)),
              journalReceiptHistory: Nullable(t.array(ReceiptApiC)),
              nonCashReceiptHistory: Nullable(t.array(ReceiptApiC)),
              discountReceiptHistory: Nullable(t.array(ReceiptApiC)),
              transactionStatus: Nullable(t.string),
              warningCode: Nullable(t.string)
            })
          )
        })
      ),
      ZIO.map((data) => {
        return data.body
      }),
      ZIO.catchAll(() => ZIO.succeed(null))
    )

  export const getCommonInfo = (policyNumber?: string, proposalNumber?: string) =>
    pipe(
      POApi.post(`wf-api/contract/get-policy-common-info`)(
        t.type({
          body: t.type({
            batchCodeDTO: Maybe(
              t.type({
                result: Maybe(
                  t.array(
                    t.type({
                      policyNumber: t.string,
                      effectiveDate: t.string,
                      transactionDate: t.string,
                      lastPremiumdate: t.string,
                      batchCode: t.string
                    })
                  )
                )
              })
            ),
            premiumCheckDTO: Maybe(
              t.type({
                result: Maybe(
                  t.array(
                    t.type({
                      policyNumber: t.string,
                      premiumAmount: NumberFromString,
                      suspendAmount: NumberFromString,
                      lpu: NumberFromString,
                      lnvp: NumberFromString,
                      twoD1Amount: NumberFromString,
                      discountAmount: NumberFromString
                    })
                  )
                )
              })
            ),
            policyLoanEnquiryBDTO: Maybe(
              t.type({
                interestAPL: t.number,
                interestOPL: t.number,
                balanceAPL: t.number,
                balanceOPL: t.number
              })
            ),
            accountBalanceDTO: Maybe(
              t.type({
                accountGroups: Maybe(
                  t.array(
                    t.type({
                      sacsCode: t.string,
                      sacsType: t.string,
                      amount: t.number
                    })
                  )
                )
              })
            ),
            loanEnquiryDTO: Maybe(
              t.type({
                subfileOccurs: Maybe(
                  t.array(
                    t.type({
                      accuredInterest: t.number,
                      currentBalance: t.number,
                      policyLoanTotal: t.number,
                      pendingInterest: t.number,
                      principalAmount: t.number,
                      loanNumber: t.number,
                      loanStartDate: t.string,
                      loanType: t.string
                    })
                  )
                )
              })
            ),
            policyDTO: Maybe(SubmissionService.PolicyTypeC),
            proposalDTO: Maybe(ProposalDTO),
            interestRateDTO: Maybe(
              t.type({
                result: Maybe(t.array(InterestRate))
              })
            )
          })
        })
      )({
        body: {
          policyNum: policyNumber,
          proposalNum: proposalNumber,
          effectiveDate: moment(new Date()).format('yyyyMMDD'),
          batchCodes: ['B673', 'B679', 'BR74', 'B522'],
          policyNums: [policyNumber],
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            },
            {
              sacsCode: 'LP',
              sacsType: 'U'
            },
            {
              sacsCode: 'LN',
              sacsType: 'VP'
            },
            {
              sacsCode: 'LP',
              sacsType: 'E1'
            }
          ]
        }
      }),
      ZIO.map((data) => {
        const checkDiscount = data.body.premiumCheckDTO?.result?.find((r) => r.policyNumber === policyNumber)
        const bactchCode = data.body.batchCodeDTO?.result
        const lastPremiumDate =
          bactchCode && bactchCode.length > 0
            ? bactchCode.reduce<Date | null>((preDate, cur) => {
                const curLength = cur.lastPremiumdate.length
                const curDate =
                  cur.batchCode === 'BR74'
                    ? moment(`${curLength === 6 ? '20' : curLength === 5 ? '200' : '2000'}${cur.lastPremiumdate}`)
                    : null // convert yyMMDD to yyyyMMDD
                return preDate ? (curDate?.isAfter(preDate) ? curDate.toDate() : preDate) : curDate?.toDate() ?? null
              }, null)
            : null

        const lastTransactionDate =
          bactchCode && bactchCode.length > 0
            ? bactchCode.reduce<Date | null>((preDate, cur) => {
                const curDate = cur.batchCode === 'B679' ? moment(cur.transactionDate) : null
                return preDate ? (curDate?.isAfter(preDate) ? curDate.toDate() : preDate) : curDate?.toDate() ?? null
              }, null)
            : null

        return {
          subfileOccurs: data.body.loanEnquiryDTO?.subfileOccurs ?? [],
          accountGroups: data.body.accountBalanceDTO?.accountGroups ?? [],
          loanActionB: data.body.policyLoanEnquiryBDTO,
          checkDiscount,
          batchCtrCode: {
            lastPremiumDate,
            lastTransactionDate
          },
          policyInfo: data.body.policyDTO,
          proposalInfo: data.body.proposalDTO,
          interestRates: data.body.interestRateDTO?.result ?? []
        }
      })
    )

  export const getDetailData = (policyNumber: string, proposalNumber: string, businessKey: string) =>
    pipe(
      //SubmissionService.getPolicy(policyNumber),
      ZIO.succeed(businessKey),
      ZIO.flatMap((businessKey) => {
        const keySplit = businessKey.split('PC-')
        const wrapBuzkey = keySplit.length > 1 ? keySplit[1] : ''
        return ZIO.zipPar(
          getCommonInfo(!!policyNumber && policyNumber != 'null' ? policyNumber : '', proposalNumber),
          getHistoryReceipt(wrapBuzkey),
          CancelRiderService.getRiderInfoList()
          //ZIO.succeed(policyInfo)
        )
      }),
      ZIO.flatMap(([commonInfo, coreDetail, rider]) => {
        const {
          accountGroups,
          subfileOccurs,
          loanActionB,
          checkDiscount,
          batchCtrCode,
          policyInfo,
          proposalInfo,
          interestRates
        } = commonInfo

        const contract = rider.find((_) => _.code === policyInfo?.basicCode)
        const totalAmount =
          accountGroups
            ?.filter(({ sacsCode, sacsType }) => (sacsCode === 'LP' && sacsType === 'U') || sacsType === 'S')
            .reduce((acc, { amount }) => acc + (amount ?? 0), 0) ?? 0

        const startDate = (subfileOccurs ?? []).reduce<Date | null>((pre, cur) => {
          const startDateM = cur.loanType === 'A' ? moment(cur.loanStartDate, 'DD/MM/YYYY') : null
          return pre ? (startDateM?.isAfter(pre) ? startDateM.toDate() : pre) : startDateM?.toDate() ?? null
        }, null)

        const minOplDate = (subfileOccurs ?? []).reduce<Date | null>((pre, cur) => {
          // P for OPL
          if (cur.currentBalance > 0 && cur.loanType === 'P') {
            const startDateM = moment(cur.loanStartDate, 'DD/MM/YYYY')
            return pre ? (startDateM.isBefore(pre) ? startDateM.toDate() : pre) : startDateM.toDate()
          }

          return pre
        }, null)

        const minAplDate = (subfileOccurs ?? []).reduce<Date | null>((pre, cur) => {
          // A for APL
          if (cur.currentBalance > 0 && cur.loanType === 'A') {
            const startDateM = moment(cur.loanStartDate, 'DD/MM/YYYY')
            return pre ? (startDateM.isAfter(pre) ? startDateM.toDate() : pre) : startDateM.toDate()
          }
          return pre
        }, null)

        const basicPrem = policyInfo
          ? (policyInfo?.attributesExt?.basicPremium ?? 0) + (policyInfo?.attributesExt?.riderPremium ?? 0)
          : proposalInfo
          ? (proposalInfo.sumAssured ?? 0) + (proposalInfo.totalPremium ?? 0)
          : 0

        const LPSAmount = accountGroups?.find((a) => a.sacsCode === 'LP' && a.sacsType === 'S')?.amount ?? 0
        const LPUAmount = accountGroups?.find((a) => a.sacsCode === 'LP' && a.sacsType === 'U')?.amount ?? 0

        const LNVPAmount = accountGroups?.find((a) => a.sacsCode === 'LN' && a.sacsType === 'VP')?.amount ?? 0
        const LPE1Amount = accountGroups?.find((a) => a.sacsCode === 'LP' && a.sacsType === 'E1')?.amount ?? 0

        // const wrapReceipHistory = coreDetail?.receiptHistory?.map((r) => ({
        //   ...r,
        //   transactions: r.transactions ? r.transactions : []
        // }))

        const interestRatesWrapper = interestRates.map((i) => ({
          fromDate: moment(i.fromDate).toDate(),
          toDate: !!i.toDate ? moment(i.toDate).toDate() : new Date(),
          rate: i.rate
        }))

        return ZIO.succeed({
          totalAmount: totalAmount,
          basicPremium: basicPrem,
          policyInfo: policyInfo ?? undefined,
          proposalInfo: proposalInfo ?? undefined,
          interestAPL: loanActionB?.interestAPL ?? 0,
          interestOPL: loanActionB?.interestOPL ?? 0,
          balanceAPL: loanActionB?.balanceAPL ?? 0,
          balanceOPL: loanActionB?.balanceOPL ?? 0,
          discountAmount: checkDiscount?.discountAmount ?? 0,
          premiumAmount: checkDiscount?.premiumAmount ?? 0,
          loanStarDate: startDate ? PulseOpsFormat.date(startDate) : '-',
          lastPremiumDate: batchCtrCode?.lastPremiumDate ? PulseOpsFormat.date(batchCtrCode.lastPremiumDate) : '-',
          lastTransactionDate: batchCtrCode?.lastTransactionDate
            ? PulseOpsFormat.date(batchCtrCode.lastTransactionDate)
            : '-',
          remainingCore: coreDetail?.remainingAmount ?? 0,
          coreReceips:
            coreDetail?.receiptHistory?.map((r) => ({ ...r, receiptDate: r.createdDate ?? r.receiptDate })) ?? [],
          coreJournals:
            coreDetail?.journalReceiptHistory?.map((r) => ({ ...r, receiptDate: r.createdDate ?? r.receiptDate })) ??
            [],
          coreNonCash:
            coreDetail?.nonCashReceiptHistory?.map((r) => ({ ...r, receiptDate: r.createdDate ?? r.receiptDate })) ??
            [],
          coreDiscounts:
            coreDetail?.discountReceiptHistory?.map((r) => ({ ...r, receiptDate: r.createdDate ?? r.receiptDate })) ??
            [],
          minAplDate: minAplDate ?? undefined,
          minOplDate: minOplDate ?? undefined,
          LPSAmount,
          LPUAmount,
          LNVPAmount,
          LPE1Amount,
          interestRates: interestRatesWrapper,
          contractNameRaw: {
            en: contract?.name || '-',
            vi: contract?.nameVi || '-'
          },
          transactionStatus: coreDetail?.transactionStatus || '',
          warningCode: coreDetail?.warningCode || ''
        })
      }),
      ZIO.flatMap((cashlessDetail) =>
        ZIO.zipPar(
          PulseOpsApi.getPolicy(
            !!policyNumber && policyNumber != 'null' ? policyNumber : cashlessDetail.proposalInfo?.policyNum || 'null'
          ),
          ZIO.succeed(cashlessDetail)
        )
      ),
      ZIO.map(
        ([policyInfo, cashlessDetail]): CashLessDetail => ({
          ...cashlessDetail,
          insPremium: policyInfo.body.attributesExt?.riderPremium || 0
        })
      ),
      ZIO.catchAll((_) => {
        return ZIO.succeed<CashLessDetail>({
          totalAmount: 0,
          basicPremium: 0,
          policyInfo: undefined,
          proposalInfo: undefined,
          interestAPL: 0,
          interestOPL: 0,
          balanceAPL: 0,
          balanceOPL: 0,
          discountAmount: 0,
          loanStarDate: '-',
          lastPremiumDate: '-',
          lastTransactionDate: '-',
          remainingCore: 0,
          coreReceips: [],
          coreJournals: [],
          coreNonCash: [],
          coreDiscounts: [],
          minAplDate: undefined,
          minOplDate: undefined,
          LPSAmount: 0,
          LPUAmount: 0,
          LNVPAmount: 0,
          LPE1Amount: 0,
          interestRates: [],
          contractNameRaw: {
            en: '-',
            vi: '-'
          },
          insPremium: 0
        })
      })
    )

  export const importFileToStore = (file: File, evidenceFile: File, createdBy: string) => {
    const userEmail = JSON.stringify({
      createdBy: createdBy
    })
    const formData = new FormData()
    formData.append('attachmentFile', file)
    formData.append('evidenceFile', evidenceFile)
    formData.append('jsonRequestDTO', userEmail)
    return pipe(
      POApi.post(`premium-collection-internal/premium/job/import-file-to-store`, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })(t.unknown)(formData),
      ZIO.map((data) => {
        return data
      })
    )
  }
  export const getOriginalFile = () =>
    pipe(
      POApi.get('premium-collection-internal/premium/job/get-original-file-in-store')(
        t.type({
          body: t.array(UploadFileToStorageInfo)
        })
      ),
      ZIO.map((data) => data.body)
    )
  export const submitFileToPulseops = (fileName: string, createdBy: string) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/job/submit-file-to-pulseops`)(t.unknown)({ fileName, createdBy }),
      ZIO.map((data) => data)
    )
  export const loadFileDataSubmit = (createDate: string, fileName: string, start: number, size: number) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/job/load-file-data-submit`)(
        t.type({
          body: t.type({
            data: t.array(RecordFileInfo),
            total: Maybe(t.number),
            order: Maybe(t.string),
            size: Maybe(t.number),
            sort: Maybe(t.string),
            start: Maybe(t.number)
          })
        })
      )({
        start: start,
        size: size,
        order: 'createdDate',
        sort: 'desc',
        createDate,
        fileName
      }),
      ZIO.map((data) => data.body)
    )

  export const searchFailList = (
    start: number,
    size: number,
    order: string,
    sort: string,
    fromDate: string,
    toDate: string,
    bankCode: string,
    status: string
  ) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/job/load-data-submit`)(
        t.type({
          body: t.type({
            data: t.array(FailListInfo),
            total: Maybe(t.number),
            order: Maybe(t.string),
            size: Maybe(t.number),
            sort: Maybe(t.string),
            start: Maybe(t.number)
          })
        })
      )({
        start,
        size,
        order,
        sort,
        fromDate,
        toDate,
        bankCode,
        status
      }),
      ZIO.map((data) => data.body)
    )

  export const exportFailList = (fromDate: string, toDate: string, bankCode: string, status: string) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`premium-collection-internal/premium/job/export/fail-list`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-control': 'no-cache',
              Pragma: 'no-cache',
              'Content-type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify({
              fromDate,
              toDate,
              bankCode,
              status
            })
          })
        })
      )
    )

  export const submitCaseDetail = (
    data: {
      objectId: string
      receivedDate: string
      creditDate: string
      amount: string
      refNo: string
      paymentReason: string
      primaryPolicyNo: string
      bankCode: string
      updatedBy: string
    }[]
  ) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/job/submit/fail-list`)(t.unknown)(data),
      ZIO.map((data) => data)
    )

  export const updateCaseDetail = (data: {
    objectId: string
    receivedDate: string
    creditDate: string
    amount: string
    refNo: string
    paymentReason: string
    primaryPolicyNo: string
    bankCode: string
    status: string
    sourceData: string
    createDate: string
    updatedDate: string
    createBy: string
    updatedBy: string
  }) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/job/update-case-detail`)(t.unknown)(data),
      ZIO.map((data) => data)
    )

  export const loadCaseDetail = (objectId: string) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/job/load-case-detail`)(t.type({ body: FailListInfo }))({
        objectId
      }),
      ZIO.map((data) => data.body)
    )
  export const SuspendWriteOffAction = (data: { processInstanceId: string }[]) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/write-off/suspend`)(t.unknown)(data),
      ZIO.map((_) => _)
    )
  export const UnsuspendWriteOffAction = (
    data: { processInstanceId: string; assignee: string; group: string; comment: string }[]
  ) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/write-off/unsuspend`)(t.unknown)(data),
      ZIO.map((_) => _)
    )
  export const getListWriteOffHistory = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/supplementary/get-write-off-history-list?processInstanceId=${processInstanceId}`)(
        t.type({
          data: t.array(
            t.type({
              id: t.string,
              suspendGroupCode: t.string,
              suspendGroupVN: t.string,
              suspendGroupEN: t.string,
              suspendCode: t.string,
              suspendType: t.string,
              suspendTypeVN: t.string,
              pendingLetters: Maybe(t.string),
              status: t.string,
              createdBy: t.string,
              createdDate: t.string,
              modifiedBy: Maybe(t.string),
              modifiedDate: Maybe(t.string)
            })
          )
        })
      ),
      ZIO.map((res) => res.data)
    )
  export const searchRequestList = (
    policyNum: string,
    proposalNum: string,
    status: string,
    fromDate: string,
    toDate: string,
    start: number,
    size: number
  ) => {
    return pipe(
      POApi.post(`premium-collection-internal/premium/write-off/revert-requests/search`)(
        t.type({
          body: t.type({
            start: t.number,
            size: t.number,
            order: t.string,
            sort: t.string,
            total: t.number,
            data: t.array(
              t.type({
                id: t.string,
                status: Maybe(t.string),
                createdDate: Maybe(t.string),
                policyNumber: Maybe(t.string),
                caseId: Maybe(t.string),
                idNumber: Maybe(t.string),
                policyOwnerName: Maybe(t.string),
                paymentReason: Maybe(t.string),
                officeCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        start,
        size,
        order: 'createdDate',
        sort: 'DESC',
        policyNum,
        proposalNum,
        status,
        fromDate,
        toDate
      }),
      ZIO.map((_) => _.body)
    )
  }

  export const getCustomerRequestInfo = (revertRequestWOId: string) =>
    pipe(
      POApi.get(`premium-collection-internal/premium/write-off/customer-request-info/${revertRequestWOId}`)(
        t.type({
          body: t.type({
            azureDocuments: t.array(t.type({ filename: t.string })),
            paymentReason: Maybe(t.string),
            remark: Maybe(t.string),
            caseId: Maybe(t.string)
          })
        })
      ),
      ZIO.map((_) => _.body)
    )
  export const getProcessInstanceWO = (caseId: string, revertRequestWOId: string) =>
    pipe(
      POApi.get(
        `premium-collection-internal/premium/write-off/get-process-instance?caseId=${caseId}&requestId=${revertRequestWOId}`
      )(
        t.type({
          body: t.type({
            data: t.intersection([api.CashLess, t.type({ pendingNum: t.string })]),
            warningCode: Maybe(t.array(t.string))
          })
        })
      ),
      ZIO.map((_) => _.body)
    )
  export const updateRevertRequest = (caseId: string, revertRequestWOId: string) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/write-off/update-revert-request`)(t.unknown)({
        id: revertRequestWOId,
        caseId
      }),
      ZIO.map((_) => _)
    )
  export const completeRevertRequest = (caseId: string, revertRequestWOId: string) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/write-off/complete-revert-request`)(t.unknown)({
        revertWriteOffRequestId: revertRequestWOId,
        businessKey: caseId
      }),
      ZIO.map((_) => _)
    )

  export const endRevertRequest = (revertRequestWOId: string) =>
    pipe(
      POApi.put(`premium-collection-internal/premium/write-off/end-revert-request`)(t.unknown)({
        id: revertRequestWOId
      }),
      ZIO.map((_) => _)
    )

  export const exportRequestList = (
    fromDate: string,
    toDate: string,
    policyNum: string,
    proposalNum: string,
    status: string,
    start: number,
    size: number
  ) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`premium-collection-internal/premium/write-off/export-write-off-requests`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-control': 'no-cache',
              Pragma: 'no-cache',
              'Content-type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify({
              start,
              size,
              order: 'createdDate',
              sort: 'DESC',
              policyNum,
              proposalNum,
              fromDate,
              toDate,
              status
            })
          })
        })
      )
    )
  export const submissionWriteOff = (
    exchangeId: string | null,
    proposalNumber: string,
    azureDocuments: { filename: string; url: string }[],
    officeCode: string,
    paymentReason: string,
    policyNumber: string,
    remark: string,
    transactionType: string
  ) => {
    return pipe(
      POApi.post(`premium-collection-internal/premium/write-off/submit-verification-request`)(t.unknown)({
        exchangeId,
        proposalNumber,
        azureDocuments,
        officeCode,
        paymentReason,
        policyNumber,
        remark,
        transactionType
      }),
      ZIO.map((_) => true)
    )
  }

  export const getListCaseId = (policyNum: string) => {
    return pipe(
      POApi.get(`premium-collection-internal/premium/aml/get-suspend-red-flag/${policyNum}`)(
        t.type({
          body: Maybe(
            t.array(
              t.type({
                id: Maybe(t.string),
                suspended: Maybe(t.boolean),
                completed: Maybe(t.boolean),
                assignee: Maybe(t.string),
                assigneeName: Maybe(t.string),
                status: Maybe(t.string),
                activity: Maybe(t.string),
                lastUpdateByVer: Maybe(t.string),
                lastUpdateByAdj: Maybe(t.string),
                subServiceType: Maybe(t.string),
                subTransactionType: Maybe(t.string),
                claimNumber: Maybe(t.string),
                claimType: Maybe(t.string),
                lifeAssuredName: Maybe(t.string),
                poClientNumber: Maybe(t.string),
                poName: Maybe(t.string),
                poPhoneNumber: Maybe(t.string),
                newType: Maybe(t.string),
                newSubType: Maybe(t.string),
                request: Maybe(t.string),
                exchangeId: Maybe(t.string),
                pvNumber: Maybe(t.string),
                startTime: Maybe(t.string),
                endTime: Maybe(t.string),
                pendingNum: Maybe(t.string),
                proposalNumber: Maybe(t.string),
                businessKey: Maybe(t.string),
                correlationId: Maybe(t.string),
                source: Maybe(t.string),
                vip: Maybe(t.string),
                category: Maybe(t.string),
                transactionType: Maybe(t.string),
                policyNumber: Maybe(t.string),
                paymentReason: Maybe(t.string),
                bankCode: Maybe(t.string),
                amount: Maybe(t.string),
                processInstanceId: Maybe(t.string),
                suspendCode: Maybe(t.string),
                suspendReason: Maybe(t.string),
                writeOffAction: Maybe(t.string),
                comment: Maybe(t.string),
                payload: Maybe(t.string),
                amlResult: Maybe(t.string)
              })
            )
          )
        })
      ),
      ZIO.map((_) => _)
    )
  }

  export const submitAmlRequest = (data: {
    body: {
      suspendCode: string
      caseId: string
      processInstanceId: string
      unsuspendTypeAml: string
      userId: string
      poClientNum: string
      poNationalId: string
      policyNum: string
      category: string
      serviceType: string
      source: string
      transactionType: string
      remark: string
      assignee: string
      amlResult: string
      azureDocuments: { filename: string; url: string }[]
      containerName: string
    }
    authenOption: string
    primaryPolicyNo: string
    clientId: string
    officeCode: string
    idNumber: string
    transactionType: string
    vip: string
    eventKey: string
    proposalNumber: string
    exchangeId: string
    correlationId: string
    createdBy: string
    createdDate: string
    source: string
  }) => {
    return pipe(
      POApi.post(`premium-collection-internal/premium/aml/submit-aml-request`)(t.unknown)(data),
      ZIO.map((_) => _)
    )
  }
}
