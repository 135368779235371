import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  CancelRider,
  Container,
  ErrorHandling,
  GeneralInfoService,
  InquiryComplaintData,
  sharedStyle,
  TableGroupSetup,
  RequestTableGroup,
  PulseOpsFormat,
  PruOnlineQuotationData,
  RequestTable,
  TableSetup,
  FileUploadData,
  Form,
  Input,
  ImgUploadMutiple,
  StorageBlob,
  GeneralService,
  TransactionType,
  AppContext,
  InquiryComplaintService,
  Select,
  SelectOption,
  AuthState,
  IFQuotationPrintData,
  BonusRating
} from '@pulseops/common'
import { Text, StyleSheet, View, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { FieldError, useForm } from 'react-hook-form'
import moment from 'moment'
import * as O from 'fp-ts/lib/Option'
import { useIsFocused, useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
interface DetailData {
  prefix: string
  id: string
  crTable: string
  tier: string
  period: string
  rateLow: string
  rateMedium: string
  rateHigh: string
}
export interface IFQuotationData {
  riderNameList: InquiryComplaintData.IACSources[]
  LAInfoList: CancelRider.LARiderInfo[]
  sourceList: InquiryComplaintData.CSSources[]
  pruOnQuotaData: PruOnlineQuotationData
  reverBonusRatesList: DetailData[]
  terminalBonusRates: DetailData[]
  poData: {
    name: string
    nationalID: string
  }
  riskCessationDate: string
}

export type FormData = {
  customerResponse: SelectOption | null
  customerComment: string
  responseToCustomer: string
  files: FileUploadData[]
}

export const CustomerResponseList: Array<{ code: string; nameEn: string; name: string }> = [
  {
    code: 'CRA01',
    nameEn: 'Agree',
    name: 'Đồng ý'
  },
  {
    code: 'CRA02',
    nameEn: 'Disagree',
    name: 'Không đồng ý và khiếu nại'
  }
]

const formatRound100 = (numb: number) => {
  const modNum = numb % 100
  const _diffMin = 100 - modNum
  return PulseOpsFormat.thousandSepartor(_diffMin <= 50 ? numb + _diffMin : numb + _diffMin - 100)
}

export const IFQuotationScreen = (props: {
  id: string
  data: IFQuotationData | null
  setData: (val: IFQuotationData | null) => void
  editPermissionFlag: boolean
}) => {
  const [loading, bindLoading] = useLoading()
  const { t, i18n } = useTranslation()
  const { width } = useWindowDimensions()

  const form = useForm<FormData>({ mode: 'all' })
  const register = Form.register(form)

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('Tab:IFQuotation'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const customerResponseList = CustomerResponseList.map((x) => ({
    label: i18n.language === 'en' ? x.nameEn : x.name,
    value: x.code
  }))

  const getRiderName = (item: InquiryComplaintData.IACSources | undefined, locale: string) =>
    (locale === 'en' ? item?.name : item?.nameVi) || '-'

  const convertValueToPercent = (item: string) => {
    const percentValue = Number(item) * 100
    return Number(percentValue.toFixed(4))
  }

  const convertRateToPercent = (value: string | undefined) => {
    const percentRate = !!value ? convertValueToPercent(value) : 0
    return percentRate.toString()
  }

  const getData = (): ZIO<unknown, Throwable, IFQuotationData> =>
    pipe(
      GeneralInfoService.getIfQuotationInfo(props.id),
      ZIO.map((quotationResult) => {
        const riderNameList = !!quotationResult ? quotationResult.riderNameList : []
        const sourceList = !!quotationResult ? quotationResult.sourceList : []
        const LAInfoList =
          !!quotationResult && quotationResult.LAList && quotationResult.LAList.length > 0
            ? quotationResult.LAList.map((x) => ({
                ...x,
                riders: x.riders.map((y) => {
                  const riderNameItem = quotationResult.riderNameList.find((z) => z.code === y.productCode)
                  return {
                    ...y,
                    productName: getRiderName(riderNameItem, i18n.language)
                  }
                })
              }))
            : []
        const pruOnQuotaData = quotationResult.pruOnlineQuotationData
        let reverBonusRatesList = new Array<DetailData>()
        let terminalBonusRates = new Array<DetailData>()
        const list = ['0', '1', '2', '3']
        list.forEach((finder) => {
          const bonusRate = pruOnQuotaData.reversionaryBonusRates.find((x) => x.period === finder)
          const termiBonusRate = pruOnQuotaData.terminalBonusRates.find((x) => x.period === finder)
          reverBonusRatesList.push({
            prefix: bonusRate?.prefix ?? '',
            id: bonusRate?.id ?? '',
            crTable: bonusRate?.crTable ?? '',
            tier: bonusRate?.tier ?? '',
            period: bonusRate?.period ?? '',
            rateLow: convertRateToPercent(bonusRate?.rateLow),
            rateMedium: convertRateToPercent(bonusRate?.rateMedium),
            rateHigh: convertRateToPercent(bonusRate?.rateHigh)
          })
          terminalBonusRates.push({
            prefix: termiBonusRate?.prefix ?? '',
            id: termiBonusRate?.id ?? '',
            crTable: termiBonusRate?.crTable ?? '',
            tier: termiBonusRate?.tier ?? '',
            period: termiBonusRate?.period ?? '',
            rateLow: convertRateToPercent(termiBonusRate?.rateLow),
            rateMedium: convertRateToPercent(termiBonusRate?.rateMedium),
            rateHigh: convertRateToPercent(termiBonusRate?.rateHigh)
          })
        })
        return {
          riderNameList,
          LAInfoList,
          sourceList,
          pruOnQuotaData,
          reverBonusRatesList,
          terminalBonusRates,
          poData: {
            name: quotationResult.poName,
            nationalID: quotationResult.poCMND
          },
          riskCessationDate: PulseOpsFormat.dateStringtoFormat(quotationResult.mainRiskCessDate, 'DD/MM/YYYY')
        }
      })
    )

  // const loginType = localStorage.getItem('type')
  // if (loginType === LOGIN_TYPE.GA) {
  //   source = 'SO01'
  // } else if (loginType === LOGIN_TYPE.STAFF) {
  //   source = 'SO41'
  // }
  const {
    sourceList,
    LAInfoList,
    pruOnQuotaData,
    reverBonusRatesList,
    terminalBonusRates,
    poData,
    source,
    riskCessationDate,
    riderNameList
  } = pipe(
    ZIO.zipPar(props.data ? ZIO.succeed(props.data) : getData(), AuthState.auth.get),
    ZIO.map(([data, auth]) => {
      return {
        ...data,
        source: auth.tag === AuthState.Status.GA ? 'SO01' : auth.tag === AuthState.Status.Staff ? 'SO41' : ''
      }
    }),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    riderNameList: [],
    LAInfoList: new Array<CancelRider.LARiderInfo>(),
    sourceList: [],
    reverBonusRatesList: new Array<DetailData>(),
    terminalBonusRates: new Array<DetailData>(),
    source: ''
  }

  const dateFormat = (date: string | undefined) => (date ? PulseOpsFormat.dateStrWF(date, 'DD/MM/YYYY') : '-')
  const currentDate = PulseOpsFormat.date(new Date())
  const currentDateYear = new Date().getFullYear()

  const LAInfoTableColumns: TableGroupSetup[] = [
    { key: 'productName', title: t('IFQ:ComponentAndLifeAssured'), stickyLeft: true },
    { key: 'riskCessDate', title: t('TaskManagement:RiskCessationDate'), stickyLeft: true },
    { key: 'sumAssured', title: t('Payout:SumAssured'), stickyLeft: true, alignRight: true }
  ]

  const LAInfoTableData = LAInfoList.reduce(
    (final, x) => [
      ...final,
      x.riders.map((y) => ({
        clientName: x.clientName,
        productName: y.productName,
        riskCessDate: dateFormat(y.riskCessDate),
        sumAssured: PulseOpsFormat.thousandSepartor(y.sumAssured)
      }))
    ],
    new Array()
  )

  const PolicyValueTableColumns: TableSetup[] = [
    { key: 'title', title: '' },
    { key: 'data', title: '' }
  ]

  const PolicyValueTableData = [
    {
      title: `${t('IFQ:CashBenefitBalanceUntil')} ${currentDate}`,
      data: formatRound100(Number(pruOnQuotaData?.cashBenefitBalance))
    },
    {
      title: `${t('IFQ:ReversionaryBonusUntil')} ${PulseOpsFormat.dateWF(
        pruOnQuotaData?.lastBonusDate,
        'DD/MM/YYYY HH:mm:ss',
        'DD/MM/YYYY'
      )} (1)`,
      data: formatRound100(Number(pruOnQuotaData?.reversionaryBonus))
    },
    {
      title: `${t('IFQ:SurrenderValueUntil')} ${currentDate} (2)`,
      data: formatRound100(Number(pruOnQuotaData?.surrenderValue))
    },
    {
      title: `${t('IFQ:MaturityAdvance')} (3)`,
      data: formatRound100(Number(pruOnQuotaData?.maturityAdvance))
    }
  ]

  const ProjValueTableColumns: TableSetup[] = [
    { key: 'title', title: '' },
    { key: 'low', title: t('common:low'), stickyLeft: true },
    { key: 'medium', title: t('common:medium'), stickyLeft: true },
    { key: 'high', title: t('common:high'), stickyLeft: true }
  ]

  const ProjValueTableData = [
    {
      title: t('IFQ:ProjectedReversionaryBonus') + ' (4)',
      low: formatRound100(Number(pruOnQuotaData?.projectedReversionaryBonusLow)),
      medium: formatRound100(Number(pruOnQuotaData?.projectedReversionaryBonusMedium)),
      high: formatRound100(Number(pruOnQuotaData?.projectedReversionaryBonusHigh))
    },
    {
      title: t('IFQ:ProjectedTerminalBonus') + ' (5)',
      low: formatRound100(Number(pruOnQuotaData?.projectedTerminalBonusLow)),
      medium: formatRound100(Number(pruOnQuotaData?.projectedTerminalBonusMedium)),
      high: formatRound100(Number(pruOnQuotaData?.projectedTerminalBonusHigh))
    },
    {
      title: t('IFQ:ProjectedCashBenefitBalance') + ' (6)',
      low: formatRound100(Number(pruOnQuotaData?.projectedCashBenefitBalanceLow)),
      medium: formatRound100(Number(pruOnQuotaData?.projectedCashBenefitBalanceMedium)),
      high: formatRound100(Number(pruOnQuotaData?.projectedCashBenefitBalanceHigh))
    },
    {
      title: t('IFQ:MaturityValue') + ' (7)',
      low: formatRound100(Number(pruOnQuotaData?.projectedSurrenderValueLow)),
      medium: formatRound100(Number(pruOnQuotaData?.projectedSurrenderValueMedium)),
      high: formatRound100(Number(pruOnQuotaData?.projectedSurrenderValueHigh))
    }
  ]

  const ProjReverTableColumns: TableSetup[] = [
    { key: 'title', title: t('IFQ:PRBR'), stickyLeft: true },
    { key: 'D2021', title: `${currentDateYear}`, stickyLeft: true },
    { key: 'D2022', title: `${currentDateYear + 1}`, stickyLeft: true },
    { key: 'D2023', title: `${currentDateYear + 2}`, stickyLeft: true },
    { key: 'D2024', title: `${currentDateYear + 3} ${t('IFQ:Onwards')}`, stickyLeft: true }
  ]

  const ProjReverTableData =
    reverBonusRatesList.length === 4
      ? [
          {
            title: t('common:low'),
            D2021: reverBonusRatesList[0].rateLow,
            D2022: reverBonusRatesList[1].rateLow,
            D2023: reverBonusRatesList[2].rateLow,
            D2024: reverBonusRatesList[3].rateLow
          },
          {
            title: t('common:medium'),
            D2021: reverBonusRatesList[0].rateMedium,
            D2022: reverBonusRatesList[1].rateMedium,
            D2023: reverBonusRatesList[2].rateMedium,
            D2024: reverBonusRatesList[3].rateMedium
          },
          {
            title: t('common:high'),
            D2021: reverBonusRatesList[0].rateHigh,
            D2022: reverBonusRatesList[1].rateHigh,
            D2023: reverBonusRatesList[2].rateHigh,
            D2024: reverBonusRatesList[3].rateHigh
          }
        ]
      : []

  const ProjTermTableColumns: TableSetup[] = [
    { key: 'title', title: t('IFQ:PTBR'), stickyLeft: true },
    { key: 'D2021', title: `${currentDateYear}`, stickyLeft: true },
    { key: 'D2022', title: `${currentDateYear + 1}`, stickyLeft: true },
    { key: 'D2023', title: `${currentDateYear + 2}`, stickyLeft: true },
    { key: 'D2024', title: `${currentDateYear + 3} ${t('IFQ:Onwards')}`, stickyLeft: true }
  ]

  const ProjTermTableData =
    terminalBonusRates.length === 4
      ? [
          {
            title: t('common:low'),
            D2021: terminalBonusRates[0].rateLow,
            D2022: terminalBonusRates[1].rateLow,
            D2023: terminalBonusRates[2].rateLow,
            D2024: terminalBonusRates[3].rateLow
          },
          {
            title: t('common:medium'),
            D2021: terminalBonusRates[0].rateMedium,
            D2022: terminalBonusRates[1].rateMedium,
            D2023: terminalBonusRates[2].rateMedium,
            D2024: terminalBonusRates[3].rateMedium
          },
          {
            title: t('common:high'),
            D2021: terminalBonusRates[0].rateHigh,
            D2022: terminalBonusRates[1].rateHigh,
            D2023: terminalBonusRates[2].rateHigh,
            D2024: terminalBonusRates[3].rateHigh
          }
        ]
      : []

  // const downloadURI = (uri: string, name: string) => {
  //   const link = document.createElement('a')
  //   link.download = name
  //   link.target = '_blank'
  //   link.href = uri
  //   document.body.appendChild(link)
  //   link.click()
  //   document.body.removeChild(link)
  // }

  const mapDataForSubmission = () => {
    const hot = form.getValues().customerResponse?.value === 'CRA01' ? false : true
    const FCR = form.getValues().customerResponse?.value === 'CRA01' ? true : false
    const requestorRole = {
      value: '1',
      text: t('common:PolicyOwner')
    }
    const touchPoint = sourceList.find((x) => x.code === source)?.touchPoint ?? ''
    const submitData: InquiryComplaintData.SubmitData = {
      CATEGORY_CODE: 'INQUIRY',
      GROUP_CODE: 'CSG6',
      SUB_SVC_TYPE_CODE: 'CS13',
      SUB_TRANS_TYPE_CODE: 'CS1312',
      REQUEST_WITH_ROLE: requestorRole.value,
      REQUESTER_FULL_NAME: poData?.name || '-',
      RLA_WITH_PO: '',
      REQUESTER_NATIONAL_ID: poData?.nationalID || '-',
      TOUCH_POINT: touchPoint,
      METHOD: 'MT01',
      SOURCE: source,
      HOT: hot,
      FCR: FCR,
      RES_TO_CUSTOMER: form.getValues().responseToCustomer,
      CUSTOMER_CMT: form.getValues().customerComment,
      TOTAL_MIS_MONEY_AMT: 0,
      COMPLAINT_SALE_DETAILS: [],
      COMPLAINT_GA_DETAILS: [],
      COMPLAINT_MIS_MONEY_DETAILS: [],
      CONSERVATION_INFO: {}
    }
    return submitData
  }

  const getAttachmentFilesWithMetaData = () => {
    let metaDataFiles: StorageBlob.FileContent[] = []
    const uploadedFile = form.getValues().files
    if (uploadedFile && uploadedFile.length > 0) {
      return pipe(
        GeneralService.getMetaData(TransactionType.INQUIRY, 'DCH05'),
        ZIO.map((metaDataResponse) => {
          const metaDataRaw: StorageBlob.MetaDataUpload = {
            type: metaDataResponse.data.transactionType,
            doctype: metaDataResponse.data.doctypeEn,
            class: metaDataResponse.data.classFilenet,
            docid: metaDataResponse.data.docID,
            maindoc: metaDataResponse.data.mainDoc,
            subdoc: metaDataResponse.data.subDoc,
            polnum: props.id,
            batchno: metaDataResponse.data.batchNo
          }
          metaDataFiles = uploadedFile.map((x) => {
            return {
              file: x.file,
              metaData: metaDataRaw
            }
          })
          return metaDataFiles
        })
      )
    }
    return pipe(metaDataFiles, ZIO.succeed)
  }

  const [submitting, bindLoader] = useLoading(false)

  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const submitQuotation = () => {
    form.handleSubmit((_) => {
      const submissionData = mapDataForSubmission()
      return pipe(
        getAttachmentFilesWithMetaData(),
        ZIO.flatMap((metaFiles) => StorageBlob.uploadToSubmit('CS', props.id)(metaFiles)),
        ZIO.tapError((_) => {
          showToast(t('message:MS050001'), 'error')
          return ZIO.unit
        }),
        ZIO.flatMap((blobFiles) => {
          return pipe(
            InquiryComplaintService.submitData(props.id, submissionData, blobFiles),
            ZIO.map((response) => {
              showToast(t('message:MS040011'), 'success')
              form.reset()
              return response
            })
          )
        }),
        bindLoader,
        ErrorHandling.run()
      )
    })()
  }

  const mappingDataForPrint = () => {
    let componentNameBasic = '',
      componentNameCode = ''
    const printingDate = moment().format('DD/MM/YYYY')
    const benefitList = LAInfoList.map((LAItem, index) => {
      const riderList = LAItem.riders.map((x, subIndex) => {
        const riderNameItem = riderNameList.find((z) => z.code === x.productCode)
        const riderName = riderNameItem?.nameVi ?? ''
        //componentNameBasic = name of main rider of LA1
        if (index === 0 && subIndex === 0) {
          componentNameBasic = riderName
          componentNameCode = x.productCode
        }
        return {
          name: riderName,
          riskCessDate: dateFormat(x.riskCessDate),
          sumAssured: PulseOpsFormat.thousandSepartor(x.sumAssured)
        }
      })
      return {
        lifeAssuredName: LAItem.clientName,
        components: riderList
      }
    })
    const policyValue = PolicyValueTableData.map((x) => ({ title: x.title, value: x.data }))
    let projectValue: {
      name: string
      lowValue: string
      mediumValue: string
      highValue: string
    }[] = []
    if (componentNameCode === 'ENP1') {
      projectValue = ProjValueTableData.filter(
        (item) => componentNameCode === 'ENP1' && !item.title.includes('(6)')
      ).map((x) => ({
        name: pipe(
          x.title,
          O.fromNullable,
          O.map((name) => (name.includes('(7)') ? name.replace('(7)', '(6)') : name)),
          O.getOrElse(() => '')
        ),
        lowValue: x.low,
        mediumValue: x.medium,
        highValue: x.high
      }))
    } else {
      projectValue = ProjValueTableData.map((x) => ({
        name: x.title,
        lowValue: x.low,
        mediumValue: x.medium,
        highValue: x.high
      }))
    }
    const reversionaryBonusRating: BonusRating = reverBonusRatesList.map((bonusItem, index) => {
      const currentYear = currentDateYear + index
      return {
        year: String(currentYear),
        tier: bonusItem.tier,
        lowRate: Number(bonusItem.rateLow),
        mediumRate: Number(bonusItem.rateMedium),
        highRate: Number(bonusItem.rateHigh)
      }
    })
    const terminalBonusRating: BonusRating = terminalBonusRates.map((bonusItem, index) => {
      const currentYear = currentDateYear + index
      return {
        year: String(currentYear),
        tier: bonusItem.tier,
        lowRate: Number(bonusItem.rateLow),
        mediumRate: Number(bonusItem.rateMedium),
        highRate: Number(bonusItem.rateHigh)
      }
    })
    const printData: IFQuotationPrintData = {
      componentNameBasic: componentNameBasic,
      componentNameCode: componentNameCode,
      policyNumber: props.id,
      printingDate: printingDate,
      policyOwnerName: poData?.name ?? '',
      policyRiskCessDate: riskCessationDate ?? '',
      couponInterestRateLow: convertRateToPercent(pruOnQuotaData?.couponInterestRate.rateLow),
      couponInterestRateMedium: convertRateToPercent(pruOnQuotaData?.couponInterestRate.rateMedium),
      couponInterestRateHigh: convertRateToPercent(pruOnQuotaData?.couponInterestRate.rateHigh),
      benefits: benefitList,
      policyValue,
      projectValue,
      reversionaryBonusRating: reversionaryBonusRating,
      terminalBonusRating: terminalBonusRating
    }
    // console.log(`printData: ${JSON.stringify(printData)}`)
    return printData
  }

  const printIFQuotation = () => {
    form.handleSubmit((_) => {
      const printData = mappingDataForPrint()
      pipe(
        GeneralInfoService.printDocument(printData),
        ZIO.flatMap((res) =>
          ZIO.zipPar(
            ZIO.succeed(res),
            ZIO.fromPromise(() => res.blob())
          )
        ),
        ZIO.tap(([res, blob]) => {
          // const type = res.headers.get('content-type') ?? ''
          // downloadURI(window.URL.createObjectURL(blob), `IFQuotation.${type.split('/')[1]}`)
          const fileString = window.URL.createObjectURL(blob)
          window.open(fileString, '_blank')
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
    })()
  }

  return (
    <Container loading={loading}>
      <View
        style={{ paddingHorizontal: 20, width: width < 1166 ? width : 1166, marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Text style={sharedStyle.sectionInfoHeaderTitle}>{t('IFQ:Benefit')}</Text>
        <Text style={{ textAlign: 'right' }}>{t('Payout:Currency')}: VND</Text>
        {LAInfoTableData.length > 0 && (
          <RequestTableGroup
            rows={LAInfoTableData}
            setup={LAInfoTableColumns}
            keyTitle={'rowBenKey'}
            groupKey="clientName"
            isPagination={false}
          ></RequestTableGroup>
        )}

        <Text style={[sharedStyle.sectionInfoHeaderTitle, { marginTop: 20 }]}>{t('IFQ:PolicyValue')}</Text>
        <Text style={{ textAlign: 'right' }}>{t('Payout:Currency')}: VND</Text>
        <RequestTable
          rows={PolicyValueTableData}
          setup={PolicyValueTableColumns}
          keyTitle={'rowBenKey'}
          noHeader
          noPagination
        ></RequestTable>

        <Text style={styles.text}>
          (1) Giá trị bảo tức tích lũy sẽ được chi trả cho khách hàng tại thời điểm đáo hạn hợp đồng hoặc khi xảy ra sự
          kiện bảo hiểm. Tổng bảo tức tích lũy nêu trên chưa khấu trừ các khoản bảo tức đã được trích cho quyền lợi hỗ
          trợ kinh phí học đại học và quyền lợi hỗ trợ kinh phí tốt nghiệp đại học, nếu có Bảo tức được công bố hàng năm
          phụ thuộc vào hoạt động đầu tư của Quỹ chủ hợp đồng tham gia chia lãi với danh mục đầu tư phần lớn là trái
          phiếu chính phủ. Do đó, bảo tức là không cam kết, có thể bằng 0 (không) khi trái phiếu chính phủ có xu hướng
          giảm. Giá trị đáo hạn của hợp đồng sẽ thay đổi theo bảo tức được công bố hàng năm.
        </Text>

        <Text style={styles.text}>(2) Đã bao gồm quyền lợi định kỳ và khấu trừ các khoản tạm ứng (nếu có).</Text>

        <Text style={styles.text}>
          (3) Dùng để nộp phí bảo hiểm đầu tiên hoặc phí bảo hiểm đóng thêm cho hợp đồng bảo hiểm mới (nếu có).
        </Text>

        <Text style={[sharedStyle.sectionInfoHeaderTitle, { marginTop: 10 }]}>{t('IFQ:ProjectedValue')}</Text>
        <Text style={{ textAlign: 'right' }}>{t('Payout:Currency')}: VND</Text>
        <RequestTable
          rows={ProjValueTableData}
          setup={ProjValueTableColumns}
          keyTitle={'rowBenKey'}
          noPagination
        ></RequestTable>
        <Text style={styles.text}>
          (4) Giá trị bảo tức tích lũy ước tính tại ngày đáo hạn bao gồm giá trị bảo tức tích lũy thực tế tại ngày{' '}
          {currentDate} và các giá trị bảo tức ước tính trong những năm tiếp theo đến ngày đáo hạn {riskCessationDate}{' '}
          dựa trên tỉ lệ bảo tức tích lũy giả định như sau:
        </Text>
        <Text style={{ textAlign: 'right' }}>{t('Payout:Currency')}: %</Text>
        <RequestTable
          rows={ProjReverTableData}
          setup={ProjReverTableColumns}
          keyTitle={'rowBenKey'}
          noPagination
        ></RequestTable>
        <Text style={styles.text}>
          (5) Giá trị lãi chia cuối hợp đồng là giá trị Bên mua bảo hiểm (BMBH) sẽ được chi trả tại Ngày kết thúc hợp
          đồng, nếu có.
        </Text>
        <Text style={styles.text}>
          Giá trị lãi chia cuối hợp đồng ước tính được chi trả tại ngày đáo hạn {riskCessationDate} dựa trên tỉ lệ lãi
          chia cuối hợp đồng giả định như sau:
        </Text>
        <Text style={{ textAlign: 'right' }}>{t('Payout:Currency')}: %</Text>
        <RequestTable
          rows={ProjTermTableData}
          setup={ProjTermTableColumns}
          keyTitle={'rowBenKey'}
          noPagination
        ></RequestTable>
        <Text style={styles.text}>
          (6) Giá trị quyền lợi tiền mặt định kỳ ước tính đến ngày đáo hạn {riskCessationDate} được dựa trên lãi suất
          tích lũy giả định là {convertRateToPercent(pruOnQuotaData?.couponInterestRate.rateLow)}%,{' '}
          {convertRateToPercent(pruOnQuotaData?.couponInterestRate.rateMedium)}%,{' '}
          {convertRateToPercent(pruOnQuotaData?.couponInterestRate.rateHigh)}% tương ứng với mức Thấp, Trung bình và
          Cao.
        </Text>
        <Text style={styles.text}>
          (7) Giá trị đáo hạn ước tính tại ngày đáo hạn {riskCessationDate} bao gồm các quyền lợi không được đảm bảo
          (bảo tức tích lũy và lãi chia cuối hợp đồng ước tính phía trên) và các quyền lợi được đảm bảo.
        </Text>

        {/* <Text style={[sharedStyle.sectionInfoHeaderTitle, { marginTop: 20 }]}>{t('IFQ:COMMUNICATIONINFO')}</Text>
        <View style={[sharedStyle.sectionInfo, { marginTop: 15 }]}>
          <View
            style={{
              width: '33%',
              paddingBottom: 30,
              paddingRight: 30
            }}
          >
            <Select
              {...register('customerResponse', {
                required: `${t('message:MS020001', { field: t('IFQ:customerResponse') })}`
              })}
              required
              disabled={!props.editPermissionFlag}
              options={customerResponseList}
              label={t('IFQ:customerResponse')}
              errorMessage={(form.formState.errors.customerResponse as FieldError | undefined)?.message || ''}
            />
          </View>

          <View
            style={{
              width: '100%',
              paddingBottom: 30,
              paddingRight: 30
            }}
          >
            <Input
              {...register('customerComment', {
                required: `${t('message:MS020001', { field: t('IFQ:customerComment') })}`
              })}
              numberOfLines={5}
              multiline
              required
              disabled={!props.editPermissionFlag}
              title={t('IFQ:customerComment')}
              errorMessage={form.formState.errors.customerComment?.message}
            />
          </View>
          <View
            style={{
              width: '100%',
              paddingBottom: 30,
              paddingRight: 30
            }}
          >
            <Input
              {...register('responseToCustomer', {
                required: `${t('message:MS020001', { field: t('IFQ:responseToCustomer') })}`
              })}
              numberOfLines={5}
              multiline
              required
              title={t('IFQ:responseToCustomer')}
              disabled={!props.editPermissionFlag}
              errorMessage={form.formState.errors.responseToCustomer?.message}
            />
          </View>
          <Text style={styles.attachmentText}>{t('IFQ:FileAttachment')}</Text>
          <ImgUploadMutiple {...register('files')}></ImgUploadMutiple>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
          <TouchableOpacity
            style={[sharedStyle.button, props.editPermissionFlag ? sharedStyle.btnRed : sharedStyle.btnDisabled]}
            onPress={() => {
              submitQuotation()
            }}
            disabled={!props.editPermissionFlag}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {submitting && <ActivityIndicator style={{ marginEnd: 13 }} color="#ED1B2E" />}
              <Text style={props.editPermissionFlag ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
                {t('common:Complete')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={[sharedStyle.button, sharedStyle.btnRed]}
            onPress={() => {
              printIFQuotation()
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Text style={sharedStyle.textButtonRed}>{t('common:Print')}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity style={[sharedStyle.button, sharedStyle.btnDisabled]} onPress={() => {}}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Text style={sharedStyle.btnTextDisabled}>{t('common:SendEmail')}</Text>
            </View>
          </TouchableOpacity>
        </View> */}
      </View>
    </Container>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginTop: 15,
    marginBottom: 15,
    paddingHorizontal: 20
  },
  attachmentText: {
    fontWeight: 'bold',
    color: '#70777E',
    fontSize: 15
  }
})
