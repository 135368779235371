import { form2, PulseOpsFormat, ReportType } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'

export namespace ReportForm {
  const Base = t.type({
    reportType: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('Reports:ReportType')
      })
    ),
    template: form2.selectOption.requiredM(() =>
      i18next.t('message:MS020009', {
        field: i18next.t('Reports:Template')
      })
    ),
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:FromDate') })
    ),
    toDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:ToDate') }))
  })
  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(
    Base,
    form2.refine(
      // (data): data is Form => moment(data.toDate).isSameOrAfter(data.fromDate, 'day'),
      (data): data is Form => moment(data.fromDate).valueOf() < moment(data.toDate).valueOf(),
      () => i18next.t('message:MS030044'),
      'Form'
    ),
    form2.refine(
      (data): data is Form => {
        const reportType = data.reportType.value
        const dateFrom = PulseOpsFormat.startOfDate(data?.fromDate)
        const dateTo = PulseOpsFormat.startOfDate(data?.toDate)
        return reportType === ReportType.ASSIGNMENT_BY_ON_OFF || moment(dateTo).diff(dateFrom, 'months', true) <= 3
      },
      () => i18next.t('message:MS080004', { k: 3 }),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
