import { ErrorHandling, GeneralService, Label, Locale, Panel, SourceType, TaskDetail } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList } from '../common'
import { DuplicateInfoItem, DuplicateInfoTable } from './common'
import { Address } from './mock'

type DataSource = {
  label: string | number | boolean | JSX.Element | TFunctionResult
  value: string | number | boolean | JSX.Element
  suffix?: string | number | boolean | JSX.Element
  isHighlight?: boolean
}

export const ChangeContactInfoEmail = ({ detail }: { detail?: TaskDetail.ChangeEmailInfo }) => {
  const { t, i18n } = useTranslation('requestInfo')

  const relationshipT16s1 = pipe(GeneralService.getRelationship(), ErrorHandling.runDidMount()) || []

  const getRelationshipFromT16s1 = (code: string) => {
    const found = relationshipT16s1.find((item) => item.code === code)
    return {
      en: found?.relationshipEN || '',
      vi: found?.relationshipVN || ''
    }
  }

  const getEmailIndicator = (emailInd: string): string => {
    const indicator = Address.EmailIndicator.find((e) => e.value === emailInd)
    return indicator ? (i18n.language === Locale.en ? indicator.labelEN : indicator.labelVI) : '-'
  }

  const data: DataSource[] = [
    {
      label: 'Email',
      value: detail?.detail.email || '-'
    }
  ]

  if (detail?.detail.source === SourceType.PULSE4OPS || detail?.detail.source === SourceType.PRUONLINE || detail?.detail.source === SourceType.OUTBOUND || detail?.detail.source === SourceType.INBOUND) {
    data.push({
      label: t('EmailIndicator'),
      value: getEmailIndicator(detail?.detail?.email_ind || '')
    })
  }

  const emailDups: DuplicateInfoItem[] = (detail?.attributesExt?.EMAILS_DUP || []).map((item) => ({
    clientName: item.clientName,
    clientNumber: item.clientId,
    relationship: getRelationshipFromT16s1(item.veriRelationship?.code || '')
  }))

  return (
    <Panel title={t('ChangeEmail')} isExand={false}>
      <InfoViewList dataSource={data} />
      {emailDups.length > 0 && (
        <>
          <Label title={t('requestInfo:dupEmail')} />
          <View style={{ marginTop: 6 }}>
            <DuplicateInfoTable data={emailDups} />
          </View>
        </>
      )}
    </Panel>
  )
}
