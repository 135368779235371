import { ModalComponent, useMobile } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Text } from 'react-native-elements'
import { ScrollView } from 'react-native-gesture-handler'

export type EvaluationViewModalProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {}
  content: string
}

export const EvaluationModalView = (props: EvaluationViewModalProps) => {
  const { onClose, open } = props
  const { isWide } = useMobile()

  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  return (
    <ModalComponent title={t('claim:EvaluationDetail')} visible={open} onClose={handleClose} actions={[]}>
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16,
          maxHeight: 400
        }}
      >
        <ScrollView>
          <Text>{props.content}</Text>
        </ScrollView>
      </View>
    </ModalComponent>
  )
}
