import { SelectOption } from '@pulseops/common'
import { SelectOptionModel } from './CEPInterface';

export namespace CepFormConst {
  export const PruFundList: { code: string; nameEn: string; nameVn: string }[] = [
    {
      code: '',
      nameEn: 'All',
      nameVn: 'Tất cả'
    },
    {
      code: 'UBL1',
      nameEn: 'PRUlink Balanced',
      nameVn: 'Quỹ PRUlink Cân Bằng'
    },
    {
      code: 'UEQ1',
      nameEn: 'PRUlink VN Equity',
      nameVn: 'Quỹ PRUlink Cổ Phiếu Việt Nam'
    },
    {
      code: 'UFI1',
      nameEn: 'PRUlink VN FixedInc',
      nameVn: 'Quỹ PRUlink Trái Phiếu Việt Nam'
    },
    {
      code: 'UGR1',
      nameEn: 'PRUlink Growth',
      nameVn: 'Quỹ PRUlink Tăng Trưởng'
    },
    {
      code: 'UPS1',
      nameEn: 'PRUlink Preserver',
      nameVn: 'Quỹ PRUlink Bảo Toàn'
    },
    {
      code: 'USB1',
      nameEn: 'PRUlink Stable',
      nameVn: 'Quỹ PRUlink Bền Vững'
    },
    {
      code: 'UBL2',
      nameEn: 'PRUlink Balanced top-up',
      nameVn: 'Quỹ PRUlink Cân Bằng'
    },
    {
      code: 'UEQ2',
      nameEn: 'PRUlink VN Equity top-up',
      nameVn: 'Quỹ PRUlink Cổ Phiếu Việt Nam'
    },
    {
      code: 'UFI2',
      nameEn: 'PRUlink VN FixedInc top-up',
      nameVn: 'Quỹ PRUlink Trái Phiếu Việt Nam'
    },
    {
      code: 'UGR2',
      nameEn: 'PRUlink Growth top-up',
      nameVn: 'Quỹ PRUlink Tăng Trưởng'
    },
    {
      code: 'UPS2',
      nameEn: 'PRUlink Preserver top-up',
      nameVn: 'Quỹ PRUlink Bảo Toàn'
    },
    {
      code: 'USB2',
      nameEn: 'PRUlink Stable top-up',
      nameVn: 'Quỹ PRUlink Bền Vững'
    }
  ]

  export enum DescriptionCoreTH {
    BR74 = 'Billing Freq. Change (APL)',
    T522 = 'Billing Changes',
    T551 = 'Premium Redirection - Modify'
  }

  export enum Category {
    ALL = 'ALL',
    COMPLAINT = 'CH',
    INQUIRY = 'CS',
    POLICY_SERVICE = 'PS',
    GENERAL = 'GI',
    CLAIM_SERVICES = 'CL',
    PREMIUM_COLLECTION = 'PC',
    DISTRIBUTION_SERVICES = 'DS'
  }

  export const ServiceInquiryCategories: SelectOption[] = [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Complaint',
      value: Category.COMPLAINT
    },
    {
      label: 'Inquiry',
      value: Category.INQUIRY
    },
    {
      label: 'PolicyServices',
      value: Category.POLICY_SERVICE
    },
    {
      label: 'General',
      value: Category.GENERAL
    },
    {
      label: 'PremiumCollection',
      value: Category.PREMIUM_COLLECTION
    },
    {
      label: 'Distribution Services',
      value: Category.DISTRIBUTION_SERVICES
    },
    {
      label: 'ClaimServices',
      value: Category.CLAIM_SERVICES
    }
  ]

  export const StatusPointList: SelectOptionModel[] = [
    { code: '', nameEn: 'ALL', nameVn: 'Tất cả' },
    { code: 'ACTIVE', nameEn: 'ACTIVE', nameVn: 'Hoạt động' },
    { code: 'CANCELED', nameEn: 'CANCELED', nameVn: 'Đã hủy' },
    { code: 'EXPIRED', nameEn: 'EXPIRED', nameVn: 'Hết hạn' },
    { code: 'REDEEMED', nameEn: 'REDEEMED', nameVn: 'Đã đổi quà' },
    { code: 'REVERTED', nameEn: 'REVERTED', nameVn: 'Điểm đã reverted' }
  ]

  export const PointTypeList: SelectOptionModel[] = [
    { code: 'POINT_PAYMENT', nameEn: 'POINT_PAYMENT', nameVn: 'POINT_PAYMENT' },
    { code: 'POINT_BIRTHDAY', nameEn: 'POINT_BIRTHDAY', nameVn: 'POINT_BIRTHDAY' },
    { code: 'POINT_REDEMPTION', nameEn: 'POINT_REDEMPTION', nameVn: 'POINT_REDEMPTION' },
    { code: 'POINT_ANNIVERSARY', nameEn: 'POINT_ANNIVERSARY', nameVn: 'POINT_ANNIVERSARY' },
    { code: 'POINT_BIRTHDAY_FIRST_LOGIN', nameEn: 'POINT_BIRTHDAY_FIRST_LOGIN', nameVn: 'POINT_BIRTHDAY_FIRST_LOGIN' },
    { code: 'POINT_NEW_YEAR', nameEn: 'POINT_NEW_YEAR', nameVn: 'POINT_NEW_YEAR' },
    { code: 'POINT_EXPIRED', nameEn: 'POINT_EXPIRED', nameVn: 'POINT_EXPIRED' },
    { code: 'POINT_CANCELLED', nameEn: 'POINT_CANCELLED', nameVn: 'POINT_CANCELLED' },
    { code: 'REFUND_POINT', nameEn: 'REFUND_POINT', nameVn: 'REFUND_POINT' },
    { code: 'PLUS_POINT', nameEn: 'PLUS_POINT', nameVn: 'PLUS_POINT' }
  ]

  export const StatusGiftList: SelectOptionModel[] = [
    { code: 'SUCCESS', nameEn: 'SUCCESS', nameVn: 'Thành công' },
    { code: 'FAIL', nameEn: 'FAIL', nameVn: 'Thất bại' },
    { code: 'RETURN', nameEn: 'RETURN', nameVn: 'Chuyển hoàn' },
    { code: 'IS_EXPORTED', nameEn: 'IS_EXPORTED', nameVn: 'Mã đã xuất' },
    { code: 'PROCESSING', nameEn: 'PROCESSING', nameVn: 'Chờ xử lý' }
  ]

  export const StatusRedeemList: SelectOptionModel[] = [
    { code: 'IS_EXPORTED', nameEn: 'IS_EXPORTED', nameVn: 'Mã đã xuất' },
    { code: 'IS_USED', nameEn: 'IS_USED', nameVn: 'Mã đã dùng' }
  ]
}
