import { Any, ZIO } from '@mxt/zio'
import { Ref, ZStream } from '@mxt/zio/stream'
import { Alert, subscribe } from '@pulseops/common'
import { Either } from 'fp-ts/Either'
import { constVoid, pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Subject } from 'rxjs'
import styled from 'styled-components/native'
import { Button, View } from './styled'
import { assets } from '../assets'
import * as E from 'fp-ts/Either'

export namespace EditFooterService {
  export const onSave = new Subject()
  export const onClose = new Subject()
  export const onIssue = new Subject()
  export const onUpdateIssue = new Subject()

  export const footerState = Ref.make<{
    loading: boolean
    showing: boolean
    hideCancel: boolean
    hideSave?: boolean
    showIssueBtn?: boolean
    showUpdateIssue?: boolean
  }>({
    loading: false,
    showing: false,
    hideCancel: false
  })

  export const setLoading = (value: boolean) =>
    pipe(
      footerState.update((s) => ({
        ...s,
        loading: value
      }))
    )

  export const close = footerState.set({
    showing: false,
    loading: false,
    hideCancel: false
  })

  export const useFooter = (
    opts: {
      hideCancel?: boolean
      hideSave?: boolean
      showIssueBtn?: boolean
      showUpdateIssue?: boolean
    } = {}
  ) => {
    const onCloseRef = React.useRef(constVoid)
    const onSaveRef = React.useRef<() => Promise<Either<Any, Any>>>(() => Promise.resolve(E.left(null)))
    const onIssueRef = React.useRef<() => Promise<Either<Any, Any>>>(() => Promise.resolve(E.left(null)))
    const onUpdateIssueRef = React.useRef<() => Promise<Either<Any, Any>>>(() => Promise.resolve(E.left(null)))

    React.useEffect(() => {
      pipe(
        footerState.set({
          showing: true,
          loading: false,
          hideCancel: !!opts.hideCancel,
          hideSave: !!opts.hideSave,
          showIssueBtn: !!opts.showIssueBtn,
          showUpdateIssue: !!opts.showUpdateIssue
        }),
        ZIO.run({})
      )

      return () => {
        pipe(close, ZIO.run({}))
      }
    }, [opts.hideCancel, opts.hideSave, opts.showIssueBtn, opts.showUpdateIssue])

    const registerOnClose = (onClose: () => void) => {
      onCloseRef.current = onClose
    }
    const registerOnSave = (onSave: () => Promise<Either<Any, Any>>) => {
      onSaveRef.current = onSave
    }
    const registerOnIssue = (onIssue: () => Promise<Either<Any, Any>>) => {
      onIssueRef.current = onIssue
    }

    const registerOnUpdate = (onUpdateIssue: () => Promise<Either<Any, Any>>) => {
      onUpdateIssueRef.current = onUpdateIssue
    }

    pipe(
      onClose,
      ZStream.fromObservable,
      ZStream.chainEffect(() =>
        ZIO.effect(() => {
          onCloseRef.current()
        })
      ),
      subscribe()
    )

    pipe(
      onSave,
      ZStream.fromObservable,
      ZStream.chainEffect(() =>
        pipe(
          setLoading(true),
          ZIO.flatMap(() => ZIO.fromPromise(() => onSaveRef.current())),
          ZIO.tapBoth(
            () => setLoading(false),
            () => setLoading(false)
          ),
          ZIO.tap(
            E.fold(
              () => Alert.alertM('Dữ liệu nhập chưa đúng. Vui lòng kiểm tra lại.'),
              () =>
                ZIO.effect(() => {
                  onCloseRef.current()
                })
            )
          )
        )
      ),
      subscribe()
    )

    pipe(
      onIssue,
      ZStream.fromObservable,
      ZStream.chainEffect(() =>
        pipe(
          setLoading(true),
          ZIO.flatMap(() => ZIO.fromPromise(() => onIssueRef.current())),
          ZIO.tapBoth(
            () => setLoading(false),
            () => setLoading(false)
          ),
          ZIO.tap(
            E.fold(
              () => Alert.alertM('Dữ liệu nhập chưa đúng. Vui lòng kiểm tra lại.'),
              () =>
                ZIO.effect(() => {
                  onCloseRef.current()
                })
            )
          )
        )
      ),
      subscribe()
    )

    pipe(
      onUpdateIssue,
      ZStream.fromObservable,
      ZStream.chainEffect(() =>
        pipe(
          setLoading(true),
          ZIO.flatMap(() => ZIO.fromPromise(() => onUpdateIssueRef.current())),
          ZIO.tapBoth(
            () => setLoading(false),
            () => setLoading(false)
          ),
          ZIO.tap(
            E.fold(
              () => Alert.alertM('Dữ liệu nhập chưa đúng. Vui lòng kiểm tra lại.'),
              () =>
                ZIO.effect(() => {
                  onUpdateIssueRef.current()
                })
            )
          )
        )
      ),
      subscribe()
    )

    return [registerOnSave, registerOnClose, registerOnIssue, registerOnUpdate] as const
  }
}

export const EditFooter = ({ showing }: { showing: boolean }) => {
  const { t } = useTranslation('business')

  const loading = pipe(
    EditFooterService.footerState.watch((s) => s.loading),
    subscribe(false)
  )
  const hideCancel = pipe(
    EditFooterService.footerState.watch((s) => s.hideCancel),
    subscribe(true)
  )
  const hideSave = pipe(
    EditFooterService.footerState.watch((s) => s.hideSave),
    subscribe(false)
  )
  const showIssueBtn = pipe(
    EditFooterService.footerState.watch((s) => s.showIssueBtn),
    subscribe(false)
  )

  const showUpdateIssue = pipe(
    EditFooterService.footerState.watch((s) => s.showUpdateIssue),
    subscribe(false)
  )

  if (!showing || (hideSave && hideCancel && !showIssueBtn && !showUpdateIssue)) {
    return null
  }

  return (
    <SC.BottomRow>
      {!hideSave && (
        <Button
          title={t('save')}
          icon={<assets.IconSave />}
          onPress={() => {
            EditFooterService.onSave.next(undefined)
          }}
          loading={loading}
        />
      )}
      {!hideCancel && (
        <Button
          title={t('cancel')}
          type="outline"
          icon={<assets.IconCancel />}
          onPress={() => {
            pipe(EditFooterService.close, ZIO.run({}))
            EditFooterService.onClose.next(undefined)
          }}
          disabled={loading}
        />
      )}
      {showIssueBtn && (
        <Button
          title={t('issue_policy')}
          icon={<assets.IconIssue />}
          onPress={() => {
            EditFooterService.onIssue.next(undefined)
          }}
          loading={loading}
        />
      )}

      {showUpdateIssue && (
        <Button
          title={t('update_issue')}
          icon={<assets.IconSave />}
          onPress={() => {
            EditFooterService.onUpdateIssue.next(undefined)
          }}
          loading={loading}
        />
      )}
    </SC.BottomRow>
  )
}

const SC = {
  BottomRow: styled(View)`
    background: white;
    z-index: 1;
    position: sticky;
    bottom: 0;
    height: 68px;
    left: 0;
    right: 0;
    shadow-opacity: 0.75;
    shadow-radius: 5px;
    shadow-color: #0000001a;
    shadow-offset: 0px -2px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  `
}
