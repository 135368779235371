import { TextField, makeStyles, withStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ControlProps, SelectOption } from '@pulseops/common'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

type OBSelectSearchProps = ControlProps<SelectOption | null> & {
  options?: SelectOption[]
  title?: string
  placeholder?: string
  errorMessage?: string
}

const useSelectStyles = makeStyles({
  root: {
    '&.MuiAutocomplete-root': {
      backgroundColor: '#F6F7FA',
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#bdbec0 !important',
      borderWidth: '1px !important'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: '#bdbec0 !important',
      borderWidth: '1px !important'
    },
    '& .MuiInputBase-root': {
      padding: 2
    },
    // '& .Mui-focused': {
    //   borderWidth: 1,
    //   borderColor: 'blue'
    // },
    '& input::placeholder': {
      color: '#70777E',
      opacity: 1
    },
    // '& .Mui-disabled': {
    //   color: '#ED1B2E'
    // }
  }
})

export function OBSelectSearch ({ options, title, value, onChange, placeholder, errorMessage }: OBSelectSearchProps) {
  const classes = useSelectStyles()
  return (
    <View>
      {title && (
        <View style={selectStyles.titleContent}>
          <Text style={selectStyles.title}>{title}</Text>
        </View>
      )}
      <Autocomplete
        id="combo-box-demo"
        options={options || []}
        getOptionLabel={(option) => option.label}
        style={{ width: '100%' }}
        classes={{
          root: classes.root
        }}
        value={value}
        onChange={(event, val) => { onChange && onChange(val) }}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} variant="outlined" />}
      />
      {errorMessage && (
        <View>
          <Text style={selectStyles.errorText}>{errorMessage}</Text>
        </View>
      )}

    </View>
  )
}
const selectStyles = StyleSheet.create({
  titleContent: {
    marginBottom: 4
  },
  title: {
    color: '#58647A',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 18,
  },
  errorText: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})