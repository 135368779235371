import * as React from 'react'
import { TouchableOpacity, Text, StyleSheet, TouchableOpacityProps, TextStyle, ActivityIndicator } from 'react-native'

interface Props extends TouchableOpacityProps {
  text: string
  textStyle?: TextStyle
  textColorDisable?: string
  textColorEnable?: string
  bgColorDisable?: string
  bgColorEnable?: string
  loadingColor?: string
  borderColorDisable?: string
  borderColorEnable?: string
  showBorder?: boolean
  filled?: boolean
  isLoading?: boolean
}

export const RoundedButton = ({
  text,
  textStyle = {},
  textColorDisable = '#000000',
  textColorEnable = '#000000',
  bgColorDisable = '#FFFFFF',
  bgColorEnable = '#FFFFFF',
  borderColorDisable = '#000000',
  borderColorEnable = '#000000',
  showBorder = false,
  filled = false,
  isLoading = false,
  loadingColor = 'red',
  ...props
}: Props) => {
  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.container,
        filled ? { backgroundColor: props.disabled ? bgColorDisable : bgColorEnable } : {},
        showBorder ? { borderColor: props.disabled ? borderColorDisable : borderColorEnable, borderWidth: 1 } : {},
        props.style
      ]}
    >
      {isLoading && <ActivityIndicator style={{ marginEnd: 13 }} color={loadingColor} />}
      <Text style={[styles.title, { color: props.disabled ? textColorDisable : textColorEnable }, textStyle]}>
        {text}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    borderRadius: 20,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
    // paddingHorizontal: 26,
  },

  title: {
    marginVertical: 6,
    fontWeight: '600',
    fontSize: 12
  }
})
