import * as t from 'io-ts'
import { enumC, Maybe } from '@mxt/zio/codec'

export enum Frequency {
  Annual = '01',
  HalfYearly = '02',
  Quarterly = '04',
  Monthly = '12'
}
export const FrequencyC = enumC(Frequency, 'Frequency')

export const EFormBillingChange = t.type({
  body: t.type({
    billToDate: Maybe(t.string),
    curBillingFrequency: Maybe(FrequencyC),
    curRenewalPremium: Maybe(t.string),
    premiumAdv: Maybe(t.string),
    suspendAmount: Maybe(t.string)
  })
})
export type EFormBillingChange = t.TypeOf<typeof EFormBillingChange>
