import { CircularProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  ErrorHandling,
  GeneralService,
  PaymentType,
  PolicyInfoHeader,
  PulseOpsFormat,
  StorageBlob,
  SubmissionService,
  Toast,
  TransactionType,
  useMobile,
  AuthService
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  FlatList,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View
} from 'react-native'
import _ from 'lodash'
import { ClaimService } from './ClaimService'
import { ClaimStackParamList } from './ClaimStackParamList'
import { ClaimSubmitData, ClaimType, DocumentOption, HealthType } from './common'
import { DocumentInfo } from './document-info'
import { PaymentMethod } from './payment-method'
// import { PaymentMethodForm } from './payment-method/PaymentMethodForm'
import { RequestInfoTab } from './request-info'
import * as A from 'fp-ts/lib/Array'
import { RequestInfoForm } from './request-info/RequestInfoForm'
import * as AddLackOfPayeeForm from './add-lack-of-payee/add-lack-of-payee-form'

interface Tab {
  title: string
  index: number
  component: JSX.Element
}

const generateUUID = () => {
  let uuidValue = '',
    k,
    randomValue
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += '-'
    }
    uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
  }
  return uuidValue
}

type Props = StackScreenProps<ClaimStackParamList, 'ClaimScreen'>

export const ClaimScreen = (props: Props) => {
  const { t } = useTranslation('claim')
  const at = useTranslation('claim').t
  const ref = React.useRef<{ scrollTo: (scrollObj: { y: number; animated: boolean }) => void }>()
  const { width, height } = useWindowDimensions()
  const [tabIndex, setTabIndex] = React.useState(0)
  const { isMobile, isWide } = useMobile()
  const [loading, bindLoader] = useLoading(false)
  const uuid = generateUUID()
  const [isValid, setValid] = React.useState(false)
  const documentRef = React.useRef<DocumentOption[]>([])
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const flatListRef = React.useRef<FlatList>(null)
  const [diagnostics, setDiagnostics] = React.useState<
    {
      diseaseNameEn: string
      diseaseNameVn: string
      icdcode: string
      id: string
    }[]
  >([])

  const requestInfoForm = useForm<RequestInfoForm>({ criteriaMode: 'firstError', mode: 'all' })
  const { data, requestFormDefaultValues } = pipe(
    ClaimService.getDataAccess(props.route.params.policyNum, uuid),
    ZIO.map((data) => {
      const requestFormDefaultValues = {
        isNotPolicyOwner: false,
        ownerName: data?.ownerName || '-',
        ownerPhoneCode: data?.ownerPhoneCode || null,
        ownerPhoneNumber: data?.claimantPhoneNumber || '-',
        ownerId: data?.ownerId || '-',
        ownerEmail: data?.ownerEmail || '-',
        ownerContractName: data?.contractName,
        lifeAssured: data?.laList[0] || null,
        claimantName: data?.ownerName || '-',
        claimantPhoneCode: data?.ownerPhoneCode || null,
        claimantPhoneNumber: data?.ownerPhoneNumber || '-',
        claimantAddress: data?.ownerAddress || '-',
        claimantEmail: data?.ownerEmail || '-',
        claimNo: data?.claimNo,
        dob: data?.dob
      }
      requestInfoForm.reset(requestFormDefaultValues)
      return {
        data,
        requestFormDefaultValues
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  ) || {
    data: null,
    requestFormDefaultValues: null
  }

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const sourceOfBusiness = pipe(
    SubmissionService.getPolicyInquiry(props.route.params.policyNum),
    ErrorHandling.runDidMount()
  )

  const docCallbackRef = React.useRef<() => void>(() => {
    /* do nothing */
  })
  const registerDocCallback = (callback: () => void) => {
    docCallbackRef.current = callback
  }
  const docResetCallbackRef = React.useRef<() => void>(() => {
    /* do nothing */
  })
  const registerResetDocCallback = (callback: () => void) => {
    docResetCallbackRef.current = callback
  }

  const paymentOnNextRef = React.useRef<() => void>(() => {
    /* do nothing */
  })
  const registerOnNextPaymentCallback = (callback: () => void) => {
    paymentOnNextRef.current = callback
  }

  const paymentResetCallbackRef = React.useRef<() => void>(() => {
    /* do nothing */
  })
  const registerResetPaymentCallback = (callback: () => void) => {
    paymentResetCallbackRef.current = callback
  }

  const [submitSuccess, setSubmitSucess] = useState<boolean>()
  const handleSuccessClose = () => {
    navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
  }

  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()

  const paymentForm = useForm<AddLackOfPayeeForm.Form>({ mode: 'all' })

  const { watch } = requestInfoForm
  const { claimType } = watch()

  const bankBranch = paymentForm.watch('bankBranch.value') || paymentForm.getValues('bankBranch.value')
  const bankValue = paymentForm.watch('bank.value')
  const [skipCheckNapas, setSkipCheckNapas] = React.useState(false)
  const { t: m } = useTranslation('message')

  const menus = [
    {
      label: t('PolicyNumber'),
      value: props?.route?.params?.policyNum || '-'
    },
    {
      label: t('PolicyOwner'),
      icon: <assets.ProfileIcon />,
      value: data?.ownerName || '-'
    },
    {
      label: t('ClientNumber'),
      icon: <assets.ProfileIcon />,
      value: data?.ownerId || '-'
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: at('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: at('common:ClaimServices'),
          navigate: () =>
            props.navigation.navigate('ClaimListScreen', {
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeCode: props.route.params.officeCode,
              officeName: props.route.params.officeName
            })
        },
        { title: t('NewClaimRegister'), navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    const { mainDiagnosis } = requestInfoForm.getValues()
    pipe(
      GeneralService.searchDiagnostic(mainDiagnosis?.label || ''),
      ZIO.map((diagnostics) => {
        setDiagnostics(diagnostics)
      }),
      ErrorHandling.run()
    )
  }, [requestInfoForm.getValues().mainDiagnosis])

  const onTabChange = (index: number) => {
    if (index < tabIndex) setIndexAndScroll(index)
  }

  const setIndexAndScroll = (index: number): void => {
    setTabIndex(index)
    flatListRef.current?.scrollToOffset({ offset: index * width, animated: true })
    ref.current?.scrollTo({
      y: 0,
      animated: true
    })
  }

  const buildPaymentMode = (source: ClaimSubmitData): void => {
    const {
      paymentMode,
      payeeIsNotPolicyOwner,
      dob,
      gender,
      occupation,
      relationshipWithPO,
      nationality,
      residenceCountry,
      nationality2,
      address,
      foreignAddress,
      bank,
      bankAccountName,
      bankAccountNumber,
      bankBranch,
      bankIdNumber,
      gaOffice,
      issuedBy,
      issuedDate,
      payeeId,
      payeeName
    } = paymentForm.getValues()
    const payment = source.attributes.PAYMENT
    payment.paymentMethod = paymentMode?.value ?? ''
    payment.paymentMethodLabel = t(paymentMode?.value ?? '', 'vi')
    payment.isPayeeNotPO = payeeIsNotPolicyOwner

    payment.dob = PulseOpsFormat.date(dob)
    payment.gender = gender?.value || ''
    payment.genderLabel = gender?.labelVn || ''
    payment.occupation = occupation?.value || ''
    payment.occupationLabel = occupation?.label || ''
    payment.relationshipWithPO = relationshipWithPO?.value || ''
    payment.relationshipWithPOLabel = relationshipWithPO?.labelVn || ''
    payment.nationality = nationality?.value || ''
    payment.nationalityLabel = nationality?.label || ''
    payment.nationality2 = nationality2?.value || ''
    payment.nationality2Label = nationality2?.label || ''
    payment.residenceCountry = residenceCountry?.value || ''
    payment.residenceCountryLabel = residenceCountry?.label || ''
    payment.address = address
    payment.foreignAddress = foreignAddress

    switch (paymentMode?.value) {
      case PaymentType.BANKTRANSFER:
        payment.bankTransfer = {
          accountName: bankAccountName,
          idNumber: bankIdNumber,
          bank: bank?.value ?? '',
          bankName: bank?.label ?? '',
          branch: bankBranch?.value || '',
          branchName: bankBranch?.label || '',
          accountNumber: bankAccountNumber
        }
        break
      case PaymentType.PAID_AT_BANK:
        payment.payAtBank = {
          payeeName: payeeName,
          idNumber: payeeId,
          issuedDate: PulseOpsFormat.date(issuedDate),
          issuedBy: issuedBy ?? '',
          bank: bank?.value ?? '',
          bankName: bank?.label ?? '',
          branch: bankBranch?.value || '',
          branchName: bankBranch?.label || ''
        }
        break
      case PaymentType.CASH_AT_COUNTER:
        payment.payAtCounter = {
          payeeName: payeeName,
          idNumber: payeeId,
          gaOffice: gaOffice?.value || '' // TODO
        }
        break
    }
  }

  const buildSubClaimData = (source: ClaimSubmitData): void => {
    const {
      claimType,
      isRelatedToAccident,
      subClaimType,
      hospital,
      diagnosis,
      claimEventDate,
      admissionDate,
      dischargeDate,
      surgeryBenefit,
      visitDate,
      fee,
      icuBenefit,
      mainDiagnosis,
      additionalDiagnosis
    } = requestInfoForm.getValues()
    const attribute = source.attributes
    switch (claimType?.value) {
      case ClaimType.CRITICAL_ILLNESS:
        attribute.CRITICAL_ILLNESS = {
          claimEventDate: PulseOpsFormat.date(claimEventDate),
          isRelatedToAccident: isRelatedToAccident === 'Y',
          diagnosis: diagnosis ?? []
        }
        break
      case ClaimType.MEDICAL:
        switch (subClaimType?.value) {
          case HealthType.InPatientBenefit:
            attribute.PATIENT_IN = {
              hospital: hospital?.value ?? '',
              hospitalLabel: hospital?.label ?? '',
              diagnosis: mainDiagnosis?.value ?? null,
              diagnosisLabel: diagnostics.find((_) => _.icdcode === mainDiagnosis?.value)?.diseaseNameVn ?? '',
              admissionDate: admissionDate ? PulseOpsFormat.date(admissionDate) : '',
              dischargeDate: admissionDate ? PulseOpsFormat.date(dischargeDate) : '',
              surgeryBenefit: surgeryBenefit ? true : false,
              icuBenefit: icuBenefit ? true : false,
              isRelatedToAccident: isRelatedToAccident === 'Y',
              additionalDiagnosis: additionalDiagnosis ?? []
            }
            break
          case HealthType.OutPatientBenefit:
            attribute.PATIENT_OUT = {
              hospital: hospital?.value ?? '',
              diagnosis: mainDiagnosis?.value ?? null,
              diagnosisLabel: diagnostics.find((_) => _.icdcode === mainDiagnosis?.value)?.diseaseNameVn ?? '',
              admissionDate: PulseOpsFormat.date(admissionDate),
              surgeryDate: PulseOpsFormat.date(visitDate),
              fee: Number(fee),
              isRelatedToAccident: isRelatedToAccident === 'Y'
            }
            break
          case HealthType.DentalBenefit:
            attribute.PATIENT_DENTAL = {
              hospital: hospital?.value ?? '',
              diagnosis: mainDiagnosis?.value ?? null,
              diagnosisLabel: diagnostics.find((_) => _.icdcode === mainDiagnosis?.value)?.diseaseNameVn ?? '',
              admissionDate: PulseOpsFormat.date(admissionDate),
              fee: Number(fee),
              isRelatedToAccident: isRelatedToAccident === 'Y'
            }
            break
          case HealthType.MaternityCare:
            attribute.PATIENT_MATERNITY = {
              hospital: hospital?.value ?? '',
              diagnosis: mainDiagnosis?.value ?? null,
              diagnosisLabel: diagnostics.find((_) => _.icdcode === mainDiagnosis?.value)?.diseaseNameVn ?? '',
              admissionDate: PulseOpsFormat.date(admissionDate),
              isRelatedToAccident: isRelatedToAccident === 'Y'
            }
            break

          default:
            break
        }
        break
      case ClaimType.DEATH:
        attribute.DEATH = {
          claimEventDate: PulseOpsFormat.date(claimEventDate),
          cause: diagnosis ?? [],
          isRelatedToAccident: isRelatedToAccident === 'Y'
        }
        break
      case ClaimType.DISABILITY:
        attribute.DISABILITY = {
          claimEventDate: PulseOpsFormat.date(claimEventDate),
          cause: diagnosis ?? [],
          isRelatedToAccident: isRelatedToAccident === 'Y'
        }
        break
      default:
        break
    }
  }
  const getSubmitData = (): ClaimSubmitData => {
    const {
      claimType,
      lifeAssured,
      ownerId,
      ownerName,
      ownerEmail,
      ownerPhoneCode,
      ownerPhoneNumber,
      isNotPolicyOwner,
      claimantName,
      claimantPhoneNumber,
      claimantAddress,
      claimantEmail,
      claimNo,
      dob
    } = requestInfoForm.getValues()

    const submitData: ClaimSubmitData = {
      policy: {
        sourceOfBusiness: sourceOfBusiness?.sourceOfBusiness || '',
        owners: {
          clientId: ownerId,
          name: ownerName,
          contactDetails: {
            EMAIL: {
              channel: 'email',
              value: ownerEmail
            },
            PHONE: {
              channel: 'phone',
              countryCode: ownerPhoneCode?.value ?? '',
              value: ownerPhoneNumber
            }
          }
        }
      },
      claimNo: claimNo,
      claimType: claimType?.value ?? '',
      lifeAssured: {
        name: lifeAssured?.label ?? '',
        dob: dob,
        externalIds: {
          SOE_VALUE: ownerId
        }
      },
      attributes: {
        SUBMISSION_USER_FULL_NAME: userInfo?.name || '',
        IS_CLAIM_ANT_NOT_PO: isNotPolicyOwner,
        CLAIM_ANT_NAME: claimantName,
        CLAIM_ANT_PHONE: claimantPhoneNumber,
        CLAIM_ANT_ADDRESS: claimantAddress,
        CLAIM_ANT_EMAIL: claimantEmail,
        PAYMENT: {} as any,
        DOCUMENTS: Array.isArray(documentRef.current)
          ? documentRef.current.map((item) => ({
              label: item.label,
              labelVn: item.labelVn,
              value: item.value,
              originalValue: item.originalValue || ''
            }))
          : []
      }
    }

    buildSubClaimData(submitData)
    buildPaymentMode(submitData)

    return submitData
  }

  const onSubmit = paymentForm.handleSubmit(() => {
    setIsLoading(true)
    pipe(
      !!documentRef.current && documentRef.current.length > 0
        ? pipe(
            documentRef.current,
            A.map((uploadedItem) => {
              return GeneralService.getAzureStorageFiles(
                uploadedItem?.files,
                TransactionType.NEW_CLAIM_REGISTER,
                uploadedItem.docTypeCode,
                'CL',
                props?.route?.params?.policyNum,
                props.route.params.officeCode
              )
            }),
            ZIO.sequence,
            ZIO.map((uploadedList) => {
              let uploadFiles: StorageBlob.FileContentSubmit[] = []
              uploadedList.map((arrayItem) => {
                uploadFiles = [...arrayItem, ...uploadFiles]
              })
              return uploadFiles
            })
          )
        : ZIO.succeed([]),
      ZIO.flatMap((file) => {
        return pipe(
          ClaimService.submit(
            props.route.params.policyNum,
            getSubmitData(),
            file,
            uuid,
            props.route.params.officeCode,
            true
          ),
          ZIO.tap((_) => {
            setSubmitSucess(true)
            return ZIO.unit
          })
        )
      }),
      ZIO.catchAll((e) =>
        ZIO.effectTotal(() => {
          setIsLoading(false)
          alert(JSON.stringify(e))
        })
      ),
      ZIO.unsafeRun({})
    )
  })
  const tabs: Tab[] = [
    {
      title: t('RequestInfo'),
      index: 0,
      component:
        data !== null ? (
          <RequestInfoTab
            isValid={isValid}
            form={requestInfoForm}
            claimDataAccess={data}
            onNext={() => setIndexAndScroll(1)}
            onReset={() => requestInfoForm.reset({ ...requestFormDefaultValues })}
          />
        ) : (
          <></>
        )
    },
    {
      title: t('PaymentMethod'),
      index: 1,
      component: (
        <PaymentMethod
          policyNum={props.route.params.policyNum}
          officeCode={props.route.params.officeCode}
          dob={requestInfoForm.getValues('dob') || ''}
          form={paymentForm}
          isLoading={isLoading}
          triggerOnReset={registerResetPaymentCallback}
          triggerOnNext={registerOnNextPaymentCallback}
          claimType={requestInfoForm.getValues('claimType')?.value || ''}
        />
      )
    },
    {
      title: t('DocumentInfo'),
      index: 2,
      component: (
        <DocumentInfo
          selectedClaimType={claimType?.value}
          onSubmit={(documents) => {
            documentRef.current = documents
            onSubmit()
          }}
          payeeIsNotPolicyOwner={paymentForm.getValues()?.payeeIsNotPolicyOwner}
          triggerOnNext={registerDocCallback}
          triggerOnReset={registerResetDocCallback}
        />
      )
    }
  ]

  const renderFooter = (tab: number) => {
    return (
      <View style={[styles.footer, isWide ? { marginRight: 19 } : {}]}>
        {tab === 0 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              requestInfoForm.reset({ ...requestFormDefaultValues })
            }}
          >
            <Text style={styles.textButton}>{t('ClearAll')}</Text>
          </TouchableOpacity>
        )}

        {tab === 0 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              requestInfoForm.trigger().then((status) => {
                setValid((prevState) => !prevState)
                if (!status) {
                  // setShowModal((prevState) => !prevState)
                } else {
                  setIndexAndScroll(1)
                }
              })
            }}
          >
            <Text style={styles.textButton}>{t('Continue')}</Text>
          </TouchableOpacity>
        )}

        {tab === 1 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              paymentResetCallbackRef.current()
            }}
          >
            <Text style={styles.textButton}>{t('ClearAll')}</Text>
          </TouchableOpacity>
        )}

        {tab === 1 && (
          <TouchableOpacity style={styles.button} onPress={onNextPaymentTab}>
            <Text style={styles.textButton}>{t('Continue')}</Text>
          </TouchableOpacity>
        )}

        {tab === 2 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              docResetCallbackRef.current()
            }}
          >
            <Text style={styles.textButton}>{t('ClearAll')}</Text>
          </TouchableOpacity>
        )}

        {tab === 2 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              docCallbackRef.current()
            }}
            disabled={isLoading}
          >
            <Text style={styles.textButton}>
              {t('SendRequest')} {isLoading && <ActivityIndicator color="red" />}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  const onNextPaymentTab = () => {
    paymentOnNextRef.current()
    if (!!paymentForm.watch('paymentMode')) {
      if (paymentForm.watch('payeeIsNotPolicyOwner')) {
        if (paymentForm.watch('paymentMode')?.value === PaymentType.BANKTRANSFER) {
          paymentForm
            .trigger([
              'payeeIsNotPolicyOwner',
              'paymentMode',
              'bank',
              'bankBranch',
              'bankAccountName',
              'bankIdNumber',
              'bankAccountNumber',
              'relationshipWithPO',
              'dob',
              'gender',
              'occupation',
              'residenceCountry',
              'nationality',
              'address',
              'nationality2',
              'foreignAddress'
            ])
            .then((status) => {
              checkNapas(status)
            })
        } else if (paymentForm.watch('paymentMode')?.value === PaymentType.CASH_AT_COUNTER) {
          paymentForm
            .trigger([
              'payeeIsNotPolicyOwner',
              'paymentMode',
              'gaOffice',
              'payeeName',
              'payeeId',
              'relationshipWithPO',
              'dob',
              'gender',
              'occupation',
              'residenceCountry',
              'nationality',
              'address',
              'nationality2',
              'foreignAddress'
            ])
            .then((status) => {
              if (status) setIndexAndScroll(2)
            })
        } else if (paymentForm.watch('paymentMode')?.value === PaymentType.PAID_AT_BANK) {
          paymentForm
            .trigger([
              'payeeIsNotPolicyOwner',
              'paymentMode',
              'payeeName',
              'payeeId',
              'issuedDate',
              'issuedBy',
              'bank',
              'bankBranch',
              'relationshipWithPO',
              'dob',
              'gender',
              'occupation',
              'residenceCountry',
              'nationality',
              'address',
              'nationality2',
              'foreignAddress'
            ])
            .then((status) => {
              if (status) setIndexAndScroll(2)
            })
        }
      } else {
        if (paymentForm.watch('paymentMode')?.value === PaymentType.BANKTRANSFER) {
          paymentForm
            .trigger([
              'payeeIsNotPolicyOwner',
              'bank',
              'paymentMode',
              'bankBranch',
              'bankAccountName',
              'bankIdNumber',
              'bankAccountNumber',
              'dob'
            ])
            .then((status) => {
              checkNapas(status)
            })
        } else if (paymentForm.watch('paymentMode')?.value === PaymentType.CASH_AT_COUNTER) {
          paymentForm
            .trigger(['payeeIsNotPolicyOwner', 'paymentMode', 'gaOffice', 'payeeName', 'payeeId', 'dob'])
            .then((status) => {
              if (status) setIndexAndScroll(2)
            })
        } else if (paymentForm.watch('paymentMode')?.value === PaymentType.PAID_AT_BANK) {
          paymentForm
            .trigger([
              'payeeIsNotPolicyOwner',
              'paymentMode',
              'payeeName',
              'payeeId',
              'issuedDate',
              'issuedBy',
              'bank',
              'bankBranch',
              'dob'
            ])
            .then((status) => {
              if (status) setIndexAndScroll(2)
            })
        }
      }
    } else {
      paymentForm.trigger(['paymentMode'])
    }
  }

  React.useEffect(() => {
    !_.isNil(bankBranch) &&
      tabIndex === 1 &&
      paymentForm.watch('paymentMode')?.value === PaymentType.BANKTRANSFER &&
      pipe(
        ClaimService.checkBenID(bankBranch),
        ZIO.map((res) => {
          let isCheck = res.napasFlag === 'Y' ? true : false
          setSkipCheckNapas(isCheck)
          !isCheck &&
            paymentForm.setError('bank', {
              message: m('MSBENID')
            })
        }),
        ZIO.unsafeRun({})
      )
  }, [bankBranch, tabIndex, paymentForm.watch('paymentMode')?.value])

  React.useEffect(() => {
    setSkipCheckNapas(false)
    paymentForm.setError('bank', {
      message: ''
    })
  }, [bankValue])

  const checkNapas = (status: boolean) => {
    return pipe(
      !skipCheckNapas
        ? ZIO.succeed({
            body: null,
            status: null,
            poll: null,
            responseStatus: null
          })
        : ClaimService.checkBankNapas({
            accountName: paymentForm.getValues('bankAccountName'),
            accountNo: paymentForm.getValues('bankAccountNumber'),
            bankName: bankValue ?? '',
            branchCode: bankBranch ?? ''
          }),
      ZIO.map((res) => {
        if (status) setIndexAndScroll(2)
      }),
      ZIO.catchAll((error) => {
        console.log(error, 'error')
        let message = ''
        if (error?.source?.response?.data?.status?.code === 115021) {
          message = m('MS070029', { name: error?.source?.response?.data?.body.fullName })
        } else if (error?.source?.response?.data?.status?.code === 115020) {
          message = m('MS070030')
        } else {
          message = m('MS030197')
        }
        return ZIO.effect(() => {
          showToast(message || m('MS030029'), 'error', {
            horizontal: 'right',
            vertical: 'top'
          })
        })
      }),
      ZIO.unsafeRun({})
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {loading && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: width,
            height: height,
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,.32)',
            zIndex: 1000,
            paddingLeft: width / 2
          }}
        >
          <CircularProgress color={'secondary'} size={50} />
        </View>
      )}
      {!loading && data !== null && (
        <ScrollView ref={ref as React.LegacyRef<any>}>
          <PolicyInfoHeader menus={menus} />
          <View style={[{ marginLeft: 'auto', marginRight: 'auto', width: width }]}>
            <View style={{ flexDirection: 'row', paddingLeft: isMobile ? 10 : 15, paddingRight: 15 }}>
              {tabs.map(({ title, index }) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    tabIndex === index ? styles.tabActive : styles.tab,
                    { minWidth: isWide ? 260 : (width - 20) / 3 }
                  ]}
                  onPress={() => onTabChange(index)}
                >
                  <Text
                    style={[
                      styles.tabLabel,
                      tabIndex === index && styles.tabLabelActive,
                      { fontSize: isMobile ? 11 : 14 }
                    ]}
                  >
                    {title}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>

            <View style={{ backgroundColor: '#FFFFFF', height: '100%', overflow: 'hidden', width: width }}>
              <FlatList
                scrollEnabled={false}
                keyExtractor={(_item, index) => index.toString()}
                ref={flatListRef}
                horizontal={true}
                pagingEnabled={true}
                data={tabs}
                renderItem={({ item }) => {
                  return (
                    <View
                      style={{ width: width, paddingEnd: 20 }}
                      onMoveShouldSetResponder={(e) => {
                        e.stopPropagation()
                        return false
                      }}
                    >
                      {item.component}
                    </View>
                  )
                }}
              />
            </View>
          </View>
        </ScrollView>
      )}
      {renderFooter(tabIndex)}
      <Toast
        visible={submitSuccess === true}
        message={t('message:MS070017', { claimNumber: requestInfoForm.getValues().claimNo })}
        type={'success'}
        onClose={handleSuccessClose}
      />
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  container: {
    height: 68,
    marginTop: 15,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  icon: {
    height: 22,
    width: 17
  },
  label: {
    marginLeft: 10,
    fontSize: 17
  },
  // Tab ++
  tabLabel: {
    paddingTop: 8,
    paddingBottom: 8
  },
  tabLabelActive: {
    color: 'red',
    fontWeight: 'bold'
  },
  tab: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E7',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  tabActive: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  // Tab --
  policyInfoContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  policyInfoSectionMobile: {
    flexDirection: 'row',
    width: '100%',
    paddingLeft: 10
  },
  policyInfoSectionWide: {
    flexDirection: 'row',
    flex: 1 / 3,
    width: 'auto',
    paddingLeft: 33
  },
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },

  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnRed: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
