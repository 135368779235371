import { SubClaimTypeBenefit, TransactionType, SelectOption, SourceType } from '@pulseops/common'
// import { TaskDetail } from '..'
import * as api from '../task-detail-api'
// import { adjudicationApi } from '../task-detail-api/AdjudicationModel'
export interface Medical {
  claimEventDate: string
}

export interface TotalPermanentDisability {
  claimEventDate: string | null | undefined
  occurrenceDate: string | null | undefined
  accidentName: SelectOption | null | undefined
  cause: SelectOption[] | string | undefined | null
  isRelatedToAccident: boolean
  tpdName: string | SelectOption[] | undefined | null
  remark: string | null | undefined
}
export interface Death {
  claimEventDate: string | null | undefined
  occurrenceDate: string | null | undefined
  accidentName: SelectOption | null | undefined
  cause: SelectOption[] | string | undefined | null
  isRelatedToAccident: boolean
  tpdName: string | SelectOption[] | undefined | null
  remark: string | null | undefined
}
export interface CriticalIllness {
  claimEventDate: string | null | undefined
  occurrenceDate: string | null | undefined
  deathDate: string | null | undefined
  accidentName: SelectOption | null | undefined
  isRelatedToAccident: boolean
  diagnosis: string | SelectOption[] | undefined | null
  ciName: string | SelectOption[] | undefined | null
  remark: string | null | undefined
}

export enum ClaimType {
  CRITICAL_ILLNESS = 'CT0100',
  DEATH = 'CT0200',
  MEDICAL = 'CT0300',
  DISABILITY = 'CT0400'
}
export interface PolicyInfoOwners {
  owners: {
    clientId: string
    name: string
    contactDetails: {
      EMAIL: {
        channel: string // Contact.ChannelEnum
        value: string
      }
      PHONE: {
        channel: string // Contact.ChannelEnum
        countryCode: string
        value: string
      }
    }
  }
}

export interface PolicyRider {
  id: string | null | undefined
  policyNo: string
  status: string
  premiumStatus: string | null | undefined
  effectiveDate: string
  productCode: string
  productOptions: {
    idMain: string | null | undefined
    productMainCode: string
    productMainName: string
    mainTotalSumAssured: number
    productMainStatus: string
    riskCessDate: string
    planName: string | null | undefined
    productName: string | null | undefined
    productComponent: {
      idSub: string | null | undefined
      component: {
        id: string | null | undefined
        name: string
        code: string
      }
      options: {
        totalSumAssured: string | null | undefined
        totalPremium: string | null | undefined
        claimTemplateType: string | null | undefined
      }
      riskCessDate: string
      productSubCode: string
      subTotalSumAssured: string
      subStatus: string
      isICU: boolean
    }[]
  }[]
  currency: string | undefined
}

export interface VerificationClaim {
  source?: SourceType
  tag: TransactionType.NEW_CLAIM_REGISTER
  detail: api.VerificationClaim & api.TaskDetailBase
  clientName: string
  clientDob?: string
  clientIdNumber?: string
  claimNumber: string
  claimType: string
  subClaimType: SubClaimTypeBenefit[]
  relatedToAccident: boolean
  directBilling: boolean | null | undefined
  documents:
    | {
        name: string | null | undefined
        url: string | null | undefined
      }[]
    | null
    | undefined

  policy: {
    sourceOfBusiness: string
    owners: {
      clientId: string
      name: string
      contactDetails: {
        EMAIL: {
          channel: string // Contact.ChannelEnum
          value: string
        }
        PHONE: {
          channel: string // Contact.ChannelEnum
          countryCode: string
          value: string
        }
      }
    }
  }
  tdpInfo?: TotalPermanentDisability
  death?: Death
  criticalIllness?: CriticalIllness
  isMedical: boolean
  PATIENT_IN?: {
    hospital: string
    diagnosis: SelectOption | string | null
    admissionDate: string | null | undefined
    dischargeDate: string | null | undefined
    surgeryBenefit: boolean | null | undefined | string
    icuBenefit: boolean | null | undefined
    additionalDiagnosis: SelectOption[] | null | undefined
    occurrenceDate: string | null | undefined
    accidentName: SelectOption | null | undefined
    hospitalLabel: string | null | undefined
    diagnosisLabel: string | null | undefined
  }
  PATIENT_OUT?: {
    hospital: string
    diagnosis: SelectOption | string
    admissionDate: string
    surgeryDate: string
    fee: number
    icuBenefit: boolean | null | undefined
    occurrenceDate: string | null | undefined
    accidentName: SelectOption | null | undefined
  }
  PATIENT_DENTAL?: {
    hospital: string
    diagnosis: SelectOption | string
    admissionDate: string
    fee: number
    icuBenefit: boolean | null | undefined
    occurrenceDate: string | null | undefined
    accidentName: SelectOption | null | undefined
  }
  PATIENT_MATERNITY?: {
    hospital: string
    diagnosis: SelectOption | string
    admissionDate: string
    icuBenefit: boolean | null | undefined
    occurrenceDate: string | null | undefined
    accidentName: SelectOption | null | undefined
  }
  PAYMENT: {
    paymentMethod: string
    isPayeeNotPO?: boolean | null | undefined
    relationshipWithPO: string | null | undefined
    dob: string | null | undefined
    gender: string | null | undefined
    occupation: string | null | undefined
    residenceCountry: string | null | undefined
    nationality: string | null | undefined
    nationality2: string | null | undefined
    address: string | null | undefined
    foreignAddress: string | null | undefined
    bankTransfer?:
      | {
          accountName: string
          idNumber: string
          bank: string
          bankName: string | null | undefined
          branch: string | null | undefined
          branchName: string | null | undefined
          accountNumber: string | number
        }
      | null
      | undefined
    payAtBank?:
      | {
          payeeName: string
          idNumber: string
          issuedDate: string
          issuedBy: string
          bankName: string
          bank: string | null | undefined
          branch: string | null | undefined
          branchName: string | null | undefined
        }
      | null
      | undefined
    payAtCounter?:
      | {
          payeeName: string | null | undefined
          idNumber: string | null | undefined
          gaOffice: string
        }
      | null
      | undefined
    paymentMethodLabel?: string
  }
  policyRider: PolicyRider
}

export const VerificationClaim = (
  detail: api.VerificationClaim & api.TaskDetailBase,
  poName: string,
  subClaimType: SubClaimTypeBenefit[],
  pva: PolicyRider,
  poDob?: string,
  poExternalIds?: string
): VerificationClaim => {
  const { documents } = detail.payload
  const { claimNo, claimType, attributes, policy, directBilling } = detail.payload.body
  const { CRITICAL_ILLNESS, DEATH, DISABILITY, PAYMENT, PATIENT_IN, PATIENT_OUT, PATIENT_DENTAL, PATIENT_MATERNITY } =
    attributes
  let relatedToAccident: boolean

  switch (claimType) {
    case ClaimType.CRITICAL_ILLNESS:
      relatedToAccident = CRITICAL_ILLNESS?.isRelatedToAccident || false
      break
    case ClaimType.DEATH:
      relatedToAccident = DEATH?.isRelatedToAccident || false
      break
    case ClaimType.DISABILITY:
      relatedToAccident = DISABILITY?.isRelatedToAccident || false
      break
    case ClaimType.MEDICAL:
      relatedToAccident =
        PATIENT_IN?.isRelatedToAccident ||
        PATIENT_OUT?.isRelatedToAccident ||
        PATIENT_DENTAL?.isRelatedToAccident ||
        PATIENT_MATERNITY?.isRelatedToAccident ||
        false
      break
    default:
      relatedToAccident = false
      break
  }

  return {
    source: detail.source as SourceType,
    tag: TransactionType.NEW_CLAIM_REGISTER,
    clientName: poName,
    clientDob: poDob ?? '',
    clientIdNumber: poExternalIds ?? '',
    detail: detail,
    claimNumber: detail.claimNumber ?? '',
    claimType: claimType,
    directBilling: directBilling,
    subClaimType: subClaimType,
    relatedToAccident: relatedToAccident,
    criticalIllness: CRITICAL_ILLNESS || undefined,
    death: DEATH || undefined,
    tdpInfo: DISABILITY || undefined,
    PAYMENT: PAYMENT,
    PATIENT_IN: PATIENT_IN || undefined,
    PATIENT_OUT: PATIENT_OUT || undefined,
    PATIENT_DENTAL: PATIENT_DENTAL || undefined,
    PATIENT_MATERNITY: PATIENT_MATERNITY || undefined,
    isMedical: claimType === ClaimType.MEDICAL || false,
    policy: policy,
    documents: documents,
    policyRider: pva
  }
}
