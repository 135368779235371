import { Nullable, Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { AmlResultC } from './aml-result'
import { UwDecisionCodeC } from '../../nbuw/model/uw-decision-code'

export const EmployeeC = t.type({
  employeeKey: t.string,
  employeeName: t.string,
  employeeNo: t.string,
  employeeId: t.string,
  dateOfIssue: DateFromISOString,
  placeOfIssue: t.string,
  email: t.string,
  employeePhoneNumber: t.string,
  gender: t.string,
  dateOfBirth: DateFromISOString,
  maritalStatus: t.string,
  nationality: t.string,
  otherNationality: Nullable(t.string),
  visa: Nullable(t.string),
  permanentAddress: t.string,
  permanentAddressCountry: t.string,
  permanentCity: Nullable(t.string),
  permanentDistrict: Nullable(t.string),
  permanentWard: Nullable(t.string),
  residentialAddress: t.string,
  residentialAddressCountry: t.string,
  residentialCity: Nullable(t.string),
  residentialDistrict: Nullable(t.string),
  residentialWard: Nullable(t.string),
  overseasAddress: Nullable(t.string),
  overseasAddressCountry: Nullable(t.string),
  overseaCity: Nullable(t.string),
  overseaDistrict: Nullable(t.string),
  overseaWard: Nullable(t.string),
  occupation: t.string,
  occupationCode: t.string,
  benefitClass: t.string,
  memberType: Maybe(t.string),
  bankName: Nullable(t.string),
  bankBranch: Nullable(t.string),
  bankAccountNo: Nullable(t.string),
  accountName: Nullable(t.string),
  masterContractNo: t.string,
  policyYear: t.number,
  lifeCertificateNo: Nullable(t.string),
  lifeCertificateEffectiveDate: Maybe(DateFromISOString),
  benefitExpiryDate: Maybe(DateFromISOString),
  basicPlan: Nullable(t.string),
  basicSa: Nullable(t.number),
  lifeCertificateEndDate: Maybe(DateFromISOString),
  addRiderSa: Nullable(t.number),
  healthcarePlan: Nullable(t.string),
  overFcl: Nullable(t.string),
  overFclAmount: t.number,
  exclusion: Nullable(t.string),
  waitingPeriod: Maybe(t.string),
  preExisting: Nullable(t.string),
  preExistingDate: Nullable(DateFromISOString),
  holdClaimsBenefitsPayout: Nullable(t.string),
  vip: Nullable(t.string),
  standardPremium: t.number,
  loadingPremium: Nullable(t.number),
  totalPremium: Nullable(t.number),
  historyAmlResult: t.array(AmlResultC),
  uuid: t.string,
  uniqueIdentifierAml: Nullable(t.string),
  version: t.number,
  createdDate: Maybe(DateFromISOString),
  createdBy: Maybe(t.string),
  status: Nullable(t.string),
  effectDate: Nullable(t.string),
  effectiveDate: Nullable(t.string),
  comment: Maybe(t.string),
  memberRemovalEffectiveDate: Maybe(DateFromISOString),
  refundedPremium: Nullable(t.number),
  uwDecision: Maybe(UwDecisionCodeC)
})
export type Employee = t.TypeOf<typeof EmployeeC>
