import React from 'react'
import { DatePicker, Input, useMobile, Select, ReportPermission, SelectSearch, assets } from '@pulseops/common'
import styled from 'styled-components/native'
import { StyleSheet, View, ScrollView, TouchableOpacity, Text } from 'react-native'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ErrorHandlerReportConstants } from './constants'
import { SubmissionReportConst } from '../SubmissionReportConst'

interface Props {
  control: any
  permissions: string[]
  onViewReport: any
}
export const SearchFields = (props: Props) => {
  const { control, permissions, onViewReport } = props

  const { isMobile } = useMobile()
  const { t } = useTranslation()

  const [maxDate] = React.useState<Date>(new Date())

  return (
    <SC.SectionContent>
      <View style={styles.sectionRow}>
        <SC.Column4>
          <Controller
            name="policyNumber"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                placeholder={t('common:Input')}
                title={t('ServiceInquiry:PolicyNumber')}
                value={field?.value ?? ''}
                labelStyle={{ ...styles.label, fontSize: isMobile ? 13 : 15 }}
              />
            )}
          />
        </SC.Column4>
        <SC.Column4>
          <Controller
            name="currentActivity"
            control={control}
            render={({ field, fieldState }) => (
              <SelectSearch
                {...field}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('TaskManagement:ErrorHandlerTable.CurrentActivity')}
                options={ErrorHandlerReportConstants.CurrentActivityList.map((item) => ({
                  label: t(`Reports:${item.label}`),
                  value: item.value
                }))}
                placeholder={t('common:Select')}
              />
            )}
          />
        </SC.Column4>
        <SC.Column4>
          <Controller
            name="category"
            control={control}
            render={({ field, fieldState }) => (
              <SelectSearch
                {...field}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                label={t('Reports:Category')}
                options={SubmissionReportConst.CategoryList.map((item) => ({
                  label: t(`Reports:${item.label}`),
                  value: item.value
                }))}
                placeholder={t('common:Select')}
              />
            )}
          />
        </SC.Column4>
      </View>
      <View style={[styles.sectionRow, styles.secondLine]}>
        <SC.Column4>
          <Controller
            name="fromDate"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                label={t('common:FromDate')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={!!value ? '' : error?.message}
                maxDate={maxDate}
                alwaysShow={true}
              />
            )}
          />
        </SC.Column4>
        <SC.Column4>
          <Controller
            name="toDate"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <DatePicker
                label={t('common:ToDate')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={!!value ? '' : error?.message}
                maxDate={maxDate}
                alwaysShow={true}
              />
            )}
          />
        </SC.Column4>
      </View>
      {permissions.includes(ReportPermission.SearchErrorHandlerReports) && (
        <View style={[styles.sectionRow, styles.secondLine]}>
          <SC.Column4>
            <TouchableOpacity style={{ width: 118, height: 40 }} onPress={onViewReport}>
              <View style={styles.primaryBtn}>
                <Text style={{ textAlign: 'center', color: '#fff' }}>{t('View')}</Text>
              </View>
            </TouchableOpacity>
          </SC.Column4>
        </View>
      )}
    </SC.SectionContent>
  )
}

const SC = {
  PageContent: styled(View)`
    background-color: #fff;
    height: 100%;
    width: 100%;
  `,
  Container: styled(ScrollView)`
    margin-left: 'auto';
    margin-right: 'auto';
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  `,
  SectionContent: styled(View)`
    padding: 20px;
    background-color: #fafafa;
    border: 1px solid #d3dce6;
    boxsizing: border-box;
    borderradius: 8px;
    margintop: 30px;
  `,
  Column4: styled(View)`
    width: 100%;
    max-width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  `
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 2
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  }
})
