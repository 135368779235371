import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import {
  assets,
  ErrorHandling,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  PulseOpsFormat,
  SelectOption
} from '@pulseops/common'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ComplainInquiryForm } from '../../../../../submission/src/complain-inquiry/complain-inquiry-form'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { IBGeneralField, TypeInputComponent } from '../../common'
interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail?: InquiryComplaintData.DetailData | null
  setAgentCodeErr: (value: string) => void
  agentCodeError: string
}

export const IBSalesPractice = ({ form, setAgentCodeErr, agentCodeError }: Props) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])

  const [financialNeedAnalysisList, setFinancialNeedAnalysisList] = React.useState<
    InquiryComplaintData.IACReceiptType[]
  >([])
  const { control, setValue, clearErrors } = form

  pipe(
    InquiryComplaintService.getT22Data('fna'),
    ZIO.map((list) => {
      setFinancialNeedAnalysisList(list)
    }),
    ErrorHandling.runDidMount()
  )

  const onChangeAgentCode = (e: string) => {
    if (e && e.length === 8) {
      pipe(
        InquiryComplaintService.getAgentInfo(e),
        ZIO.map((res) => {
          if (!!res.agentName && res.agentName !== ' ') {
            setAgentCodeErr('')
            setValue('salesPractice.agentName', res.agentName)
            clearErrors('salesPractice.agentCode')
          } else {
            setValue('salesPractice.agentName', '')
            setAgentCodeErr(t('message:MS100005'))
          }
        }),
        ErrorHandling.run()
      )
    } else {
      setAgentCodeErr('')
      setValue('salesPractice.agentName', '')
    }
  }
  return (
    <View>
      <Text style={{ fontWeight: '700', fontSize: 16, marginBottom: 10 }}>{t('ComplaintedSalesPracticeDetails')}</Text>
      <Panel title="">
        <IBGeneralField
          FieldForm={form}
          col={3}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'salesPractice.agentCode',
              title: t('Agent'),
              inputType: 'input',
              required: true,
              maxLength: 8,
              handleData: (value) => {
                const newVal = value?.replace(/\D/g, '')
                form.setValue('salesPractice.agentCode', newVal as string)
                onChangeAgentCode(value as string)
              },
              errorMessage: agentCodeError
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'salesPractice.agentName',
              title: '',
              inputType: 'input',
              disabled: true,
              multiline: true
            },
            {
              type: TypeInputComponent.SELECT,
              formName: `salesPractice.financialNeedAnalysis`,
              title: t('FinancialNeedAnalysis'),
              option: financialNeedAnalysisList.map((item) => ({
                value: item.code,
                label: i18n.language === 'en' ? item.nameEn : item.name
              })),
              required: true
            },
            {
              type: TypeInputComponent.RADIOGROUP,
              formName: 'salesPractice.POSignedInProposal',
              title: t('POSignedInProposal'),
              required: true,
              option: [
                { id: 'yes', label: t('common:Yes') },
                { id: 'no', label: t('common:No') }
              ]
            },
            {
              type: TypeInputComponent.RADIOGROUP,
              formName: 'salesPractice.customerReceivedPolicyKit',
              title: t('CustomerReceivedPolicyKit/e-kit'),
              required: true,
              option: [
                { id: 'yes', label: t('common:Yes') },
                { id: 'no', label: t('common:No') }
              ]
            },
            {
              type: TypeInputComponent.DATE,
              formName: `salesPractice.customerReceivedDate`,
              title: t('CustomerReceivedDate'),
              required: true
            }
          ]}
        />
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({})
