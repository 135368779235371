import React from 'react'
import { SelectOption } from "@pulseops/common"
import { TemplateImportFile } from '../ob-service/ob-model/OBImportType'

export type OBImportFileContextInfo = {
  AdhocNameList: SelectOption []
  AdhocImportTemplates: TemplateImportFile []
  setIsUploadImportfile?: (val: boolean) => void
}
const defaultContextVal: OBImportFileContextInfo = {
  AdhocNameList: [],
  AdhocImportTemplates: [],
  setIsUploadImportfile: (_) => {}
}

export const OBImportFileContext = React.createContext<OBImportFileContextInfo>(defaultContextVal)