import {
  formatNumberWithComma,
  Panel,
  PayoutChecker,
  PayoutPopup,
  PulseOpsFormat,
  Checkbox,
  TaskDetail,
  getPayeeNotPOData,
  TaskDetailApi,
  SourceType,
  AppContext
} from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { InfoViewList, Title } from '../common'
import { PayoutMethod } from './common'

interface Props {
  detail: TaskDetail.FullSurrender
  isSeaBankShowedMessage: boolean
}

export const FullSurrender = ({ detail, isSeaBankShowedMessage }: Props) => {
  const { t } = useTranslation()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.totalAmount),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  React.useEffect(() => {
    if (detail.sourceType === SourceType.PULSE4OPS && !!detail.seaBankWarningMsg) {
      showToast(detail.seaBankWarningMsg, 'warning', undefined, 30000)
    }
  }, [detail])

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('submission:FullSurrender')} />
      <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('submission:CashDeposit'),
              value: formatNumberWithComma(detail.cashDeposit),
              suffix: 'VND'
            },
            {
              label: t('submission:SuspenseAmount'),
              value: formatNumberWithComma(detail.suspenseAmount),
              suffix: 'VND'
            },
            {
              label: t('submission:PolicyLoans'),
              value: formatNumberWithComma(detail.policyLoans),
              suffix: 'VND'
            },

            {
              label: t('submission:PolicyDebt'),
              value: formatNumberWithComma(detail.policyDebt),
              suffix: 'VND'
            },
            {
              label: t('submission:SurrenderCharge'),
              value: formatNumberWithComma(detail.surrenderCharge),
              suffix: 'VND'
            },
            {
              label: t('submission:TotalSurrenderAmount'),
              value: formatNumberWithComma(Math.floor(detail.totalSurrenderAmount)),
              suffix: 'VND',
              isHighlight: true,
              isBold: true
            }
          ]}
        />
      </Panel>

      <View style={{ marginBottom: 40, marginTop: 20 }}>
        <Checkbox
          title={t('submission:PODeath')}
          textStyle={styles.sectionInfoHeaderTitle}
          value={detail.inheritanceFlag}
          disabled
        />
        {detail.inheritanceFlag && (
          <>
            <Title title={t('submission:INHERITANCE_INFO')} />
            <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
              <InfoViewList
                dataSource={[
                  {
                    label: t('requestInfo:PODeathDate'),
                    value: detail.deathDate
                  }
                ]}
              />
            </Panel>
          </>
        )}
      </View>

      <PayoutMethod
        paymentTitle={t('Payout:PAYMENTINFO')}
        payoutMethodData={payoutData}
        payeeData={viewPayeeData}
        panelContainerStyle={{ backgroundColor: '#FAFAFA' }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfoHeaderTitle: {
    color: '#000',
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  }
})
