import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { HeaderTable } from '../../common'
import {
  Checkbox,
  TextInput,
  RoundedButton,
  QCService,
  getLanguage,
  AppContext,
  Panel,
  GeneralService,
  ErrorHandling,
  SubTaskType,
  TaskType,
  AuthService,
  TASK_DETAIL_ROLES
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { QCTabData, QCTabAdju } from '../../data'
import _ from 'lodash'
import { CompletePopup, onCompleteAction, onRecheckAction } from '../../actions'
import {
  requestRefreshAllBasketInDashboard,
  requestRefreshAllInDashboard,
  requestRefreshDashboardAfterTranfer
} from '@pulseops/task/common'
import { StackNavigationProp } from '@react-navigation/stack'
import { TaskDetailStackParamList } from '@pulseops/task'
interface Props {
  navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
  data: QCTabData
  dataClaim?: QCTabAdju
  processId: string
  onDataChange: (newData: QCTabData) => void
  onDataCLChange: (newData: QCTabAdju) => void
  isClaim: boolean
  detail?: any
  roles: string[]
  isUWQC?: boolean
}

interface PropsCL {
  navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
  data: QCTabAdju
  onDataChange: (newData: QCTabAdju) => void
  scoreDefault: number
  detail?: any
  roles: string[]
}

interface PropsHeaderPanel {
  topic: string
  minusScore: number
}

const HeaderPanel = ({ topic, minusScore }: PropsHeaderPanel) => {
  const { t } = useTranslation()
  return (
    <View style={{ flex: 1, flexDirection: 'row', width: '70vw' }}>
      <View style={{ flex: 1, flexDirection: 'row', width: '50%' }}>
        <Text style={{ color: 'grey' }}>{t('QC:Topic')}: </Text>
        <Text style={{ fontWeight: '400' }}>{topic}</Text>
      </View>
      <View style={{ flex: 1, flexDirection: 'row', width: '50%' }}>
        <Text style={{ color: 'grey' }}>{t('QC:MaximumTopicMinusScore')}: </Text>
        <Text style={{ fontWeight: '400' }}>{minusScore}</Text>
      </View>
    </View>
  )
}

const QCClaim = ({ data, onDataChange, scoreDefault, detail, navigation, roles }: PropsCL) => {
  const { t } = useTranslation()
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const { showGlobalLoading, showToast, getFooterClaim } = React.useContext(AppContext.AppContextInstance)
  const [completeOpen, setCompleteOpen] = React.useState<boolean>(false)
  React.useEffect(() => {
    const isSupsend = detail.subTask?.split('-')[1] === 'SUSPENDED'
    getFooterClaim(
      isSupsend || SubTaskType.Adjudication === detail.basket
        ? []
        : [
            {
              name: t('common:Complete'),
              action: detail
                ? onCompleteAction(showGlobalLoading, showToast, t)(detail.subTask, detail.subTaskCode, detail)(
                    setCompleteOpen,
                    () => {},
                    checkCompleteMessage
                  )
                : () => null,
              isVisible: roles.includes(TASK_DETAIL_ROLES.EDIT_QC_CL)
            },
            {
              name: t('TaskManagement:Transfer'),
              action:
                detail && data
                  ? onRecheckAction(showGlobalLoading, showToast, t)(detail, data.mistakes, detail?.caterogy)(
                      onHandleActionSuccess
                    )
                  : () => null,
              isVisible: roles.includes(TASK_DETAIL_ROLES.EDIT_QC_CL)
            },
            {
              name: t('common:Save'),
              action: onPressSave,
              isVisible: roles.includes(TASK_DETAIL_ROLES.EDIT_QC_CL)
            },
            {
              name: t('common:Cancel'),
              action: onPressCancel,
              isVisible: roles.includes(TASK_DETAIL_ROLES.EDIT_QC_CL)
            }
          ]
    )
  }, [data])

  const checkCompleteMessage = () => {
    if (Number(data.totalScore) < 100) {
      return false
    }
    return true
  }

  const onPressSave = () => {
    showGlobalLoading(true)
    pipe(
      GeneralService.saveQCScoreVerification({
        caseId: detail?.caseId || '',
        policyNumber: detail?.policyNumber || '',
        policyOwner: detail?.policyOwner || '',
        clientNumber: detail?.clientNumber || '',
        totalScore: data.totalScore,
        qcScoreDetailTopics: data.mistakes.map((_) => ({
          errorId: _.error_id,
          qcCheck: _.qcCheck,
          minusScore: _.score,
          topic: _.topic
        })),
        remark: data.remark,
        lastUser: data.lastUser || '',
        userGroup: 'Administrator'
      }),
      ZIO.map((data) => {
        showGlobalLoading(false)
        showToast(t('message:MS990017'), 'success')
        console.log(data)
      }),
      ErrorHandling.run()
    )
  }
  const onPressCancel = () => {
    onDataChange({
      ...data,
      totalScore: `${scoreDefault}`,
      mistakes: data.mistakes.map((item) => ({ ...item, qcCheck: false })),
      remark: ''
    })
  }

  const onHandleActionSuccess = (mustRefreshTaskGroup = false, isTranfer = false) => {
    if (mustRefreshTaskGroup) {
      isTranfer ? requestRefreshDashboardAfterTranfer() : requestRefreshAllInDashboard()
    } else {
      requestRefreshAllBasketInDashboard()
    }

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('UserDrawer', {
        screen: 'TaskDashboardScreen'
      })
    }
  }
  return (
    <>
      <Text style={[styles.label, { marginTop: 22 }]}>
        {t('QC:LastUser')}:<Text style={styles.lastUser}>{data.lastUser}</Text>
      </Text>
      <Text style={[styles.label, { marginTop: 16, marginBottom: 10 }]}>
        {t('QC:TotalScore')}:
        <Text style={styles.totalScore}>
          {data.totalScore} / {scoreDefault}
        </Text>
      </Text>
      {_.map(_.groupBy(data.mistakes, 'topic'), (topics, key) => {
        return (
          <Panel key={key} title={<HeaderPanel topic={key} minusScore={topics[0].minusScore || 0} />}>
            <HeaderTable
              columns={[
                {
                  key: 0,
                  name: 'id',
                  title: t('QC:No'),
                  flex: 1,
                  styles: styles.tableValue
                },
                {
                  key: 1,
                  name: 'type',
                  title: t('QC:MistakeError'),
                  flex: 4,
                  styles: styles.tableValue
                },
                {
                  key: 2,
                  name: 'score',
                  title: t('QC:MinusScore'),
                  flex: 1,
                  styles: styles.tableValue
                },
                {
                  key: 3,
                  name: 'selected',
                  title: (
                    <div style={{ textAlign: 'center' }}>
                      <h5 style={{ margin: 0 }}>{t('QC:Mistake')}</h5>
                      <h5 style={{ margin: 0 }}>(Yes = Check, No = Uncheck)</h5>
                    </div>
                  ),
                  flex: 1,
                  styles: styles.tableValueCenter
                }
              ]}
              dataSource={topics.map((item, index) => ({
                id: index + 1,
                type: getLanguage() === 'vi' ? item.desc_vi : item.desc_en,
                score: item.score,
                selected: (
                  <Checkbox
                    value={item.qcCheck}
                    onChange={() => {
                      topics[index].qcCheck = !item.qcCheck
                      const totalScore = _.sum(
                        _.map(_.groupBy(data.mistakes, 'topic'), (topic) =>
                          topic.reduce(
                            (total, item) =>
                              item.qcCheck
                                ? item.minusScore < total + item.score
                                  ? item.minusScore
                                  : total + item.score
                                : total,
                            0
                          )
                        )
                      )
                      onDataChange({
                        ...data,
                        totalScore: `${(data.maxMinusScore as number) - totalScore}`,
                        mistakes: [...data.mistakes]
                      })
                    }}
                    disabled={SubTaskType.Adjudication === detail.basket}
                  />
                )
              }))}
            />
          </Panel>
        )
      })}
      <Text style={[styles.label, { marginTop: 35 }]}>{t('QC:Remarks')}</Text>

      <TextInput
        value={data.remark}
        onChange={(value) => onDataChange({ ...data, remark: value })}
        textInputProps={{
          multiline: true,
          style: styles.remarkInput
        }}
        disabled={SubTaskType.Adjudication === detail.basket}
      />
      {detail && (
        <CompletePopup
          open={completeOpen}
          onClose={() => setCompleteOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail.id || '-',
            processId: detail.processId || '-',
            updateToCore: detail.updateToCore,
            policyNum: detail.primaryPolicyNo || '-',
            category: detail.category || ''
          }}
        />
      )}
    </>
  )
}

export const QCCheck = ({
  processId,
  data,
  onDataChange,
  isClaim,
  dataClaim,
  onDataCLChange,
  detail,
  navigation,
  roles,
  isUWQC
}: Props) => {
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()

  const scoreDefault = React.useRef(dataClaim?.maxMinusScore as number)

  const onPressCancel = () => {
    data.remark = ''
    data.mistakes = data.mistakes.map((item) => ({ ...item, qcCheck: false }))
    data.totalScore = `${data.mistakes.reduce((total, item) => total + item.minusScore, 0)}`
    onDataChange({ ...data })
  }

  const onPressSave = () => {
    showGlobalLoading(true)
    pipe(
      QCService.saveQC({
        processInstanceId: processId,
        lastUser: data.lastUser,
        totalScore: `${data.totalScore}`,
        remark: data.remark,
        userId: '', // this.form.value.comment.author,
        detailList: data.mistakes,
        ...(isUWQC ? {activityBasket: 'UW-QC'} : {})
      }),
      ZIO.catchAll((_error) => {
        showGlobalLoading(false)
        showToast(t('QC:SavingQCInfoFailed'), 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        showToast(t('QC:SavingQCInfoSuccess'), 'success')
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  if (isClaim && dataClaim)
    return (
      <QCClaim
        data={dataClaim}
        onDataChange={onDataCLChange}
        scoreDefault={scoreDefault.current || 0}
        detail={detail}
        navigation={navigation}
        roles={roles}
      />
    )

  return (
    <View style={{ flex: 1 }}>
      <Text style={[styles.label, { marginTop: 22 }]}>
        {t('QC:LastUser')}:<Text style={styles.lastUser}>{data.lastUser}</Text>
      </Text>
      <Text style={[styles.label, { marginTop: 16 }]}>
        {t('QC:TotalScore')}:<Text style={styles.totalScore}>{data.totalScore}</Text>
      </Text>
      <HeaderTable
        columns={[
          {
            key: 0,
            name: 'id',
            title: t('QC:No'),
            flex: 1,
            styles: styles.tableValue
          },
          {
            key: 1,
            name: 'type',
            title: t('QC:MistakeError'),
            flex: 4,
            styles: styles.tableValue
          },
          {
            key: 2,
            name: 'score',
            title: t('QC:MinusScore'),
            flex: 1,
            styles: styles.tableValue
          },
          {
            key: 3,
            name: 'selected',
            title: t('QC:QCCheck'),
            flex: 1,
            styles: styles.tableValueCenter
          }
        ]}
        dataSource={data.mistakes.map((item, index) => ({
          // id: item.errorId,
          id: (index + 1),
          type: getLanguage() === 'vi' ? item.desc_vi : item.desc_en,
          score: item.minusScore,
          selected: (
            <Checkbox
              value={item.qcCheck}
              onChange={() => {
                data.mistakes[index].qcCheck = !item.qcCheck
                const totalScore = data.mistakes.reduce(
                  (total, item) => (item.qcCheck ? total : total + item.minusScore),
                  0
                )
                onDataChange({
                  ...data,
                  totalScore: `${totalScore}`,
                  mistakes: [...data.mistakes]
                })
              }}
            />
          )
        }))}
      />
      <Text style={[styles.label, { marginTop: 35 }]}>{t('QC:Remarks')}</Text>

      <TextInput
        value={data.remark}
        onChange={(value) => onDataChange({ ...data, remark: value })}
        textInputProps={{
          multiline: true,
          style: styles.remarkInput
        }}
      />

      <View style={styles.actionContainer}>
        <RoundedButton
          showBorder
          activeOpacity={0.7}
          text={t('QC:Cancel')}
          disabled={false}
          textStyle={styles.btnTitle}
          style={{ height: 39, marginEnd: 16 }}
          textColorDisable={'#ED1B2E'}
          textColorEnable={'#ED1B2E'}
          borderColorDisable={'#ED1B2E'}
          borderColorEnable={'#ED1B2E'}
          onPress={onPressCancel}
        />
        <RoundedButton
          filled
          activeOpacity={0.7}
          text={t('QC:Save')}
          disabled={false}
          textStyle={styles.btnTitle}
          style={{ height: 39 }}
          textColorDisable={'#FFFFFF'}
          textColorEnable={'#FFFFFF'}
          bgColorDisable={'#B0B0B0'}
          bgColorEnable={'#ED1B2E'}
          onPress={onPressSave}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold'
  },

  tableValue: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '300'
  },
  tableValueCenter: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '300',
    alignSelf: 'center'
  },
  lastUser: {
    color: '#000000',
    fontSize: 17,
    marginStart: 16
  },

  totalScore: {
    color: '#ED1B2E',
    fontSize: 17,
    marginStart: 16,
    fontWeight: '800'
  },

  remarkInput: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginTop: 10,
    height: 150,
    paddingVertical: 10,
    paddingHorizontal: 20
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },

  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 45
  }
})
