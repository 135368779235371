import { AppConfig, POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { CompareOCResponse, GetClientsByPolicyResponse, GetOwnerInfoResponse, ScanIdentifyCardResponse } from './models'
import * as t from 'io-ts'

const enum ENV {
  PRD = 'pva-prd-az1',
  STAG = 'pva-stag'
}

export interface ChangeNationalIdCardRequestDTO {
  clientName: string
  clientNumber: string
  identifyNumberUserInput: string
  identifyNumberOCR: string
  fullNameOCR: string
  dob: string
  gender: string
  idExprieDateOCR: string | null
}

export type replaceFuncType<Type> = {
  array: Array<Type>
  typeKey: keyof Type
  typeValue: string | number
  data: object
}

export namespace ChangeNationalIDCardService {
  export const replaceArrayObject = <Type extends object>({
    array,
    typeKey,
    typeValue,
    data
  }: replaceFuncType<Type>) => {
    const arrayClone = [...array] ?? []
    const index = arrayClone.findIndex((elm) => elm?.[typeKey] === typeValue)
    const obj = arrayClone.find((elm) => elm?.[typeKey] === typeValue) ?? {}

    return [
      ...arrayClone?.slice(0, index),
      {
        ...obj,
        ...data
      },
      ...arrayClone?.slice(index + 1)
    ]
  }

  export const bytesToMegabyte = (bytes: number) => {
    return +(bytes / 1048576).toFixed(2)
  }

  export const removeAccents = (str: string) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  }

  export const getClients = (clientIds: Array<{ clientId: string }>) =>
    pipe(
      POApi.post(`wf-api/customer/customers`)(
        t.type({
          body: t.array(GetOwnerInfoResponse)
        })
      )({
        body: {
          clients: clientIds
        }
      }),
      ZIO.map((clients) => clients)
    )

  export const getClientsByPolicy = (policyNumber: string) => {
    return pipe(
      POApi.get(`wf-api/bo/${policyNumber}/clients`)(GetClientsByPolicyResponse),
      ZIO.map((body) => {
        return body.body ?? {}
      })
    )
  }

  export const scanIdentifyCard = (file: File) => {
    const formData: FormData = new FormData()
    formData.append('image_base64', file)

    return pipe(
      AppConfig.get,
      ZIO.flatMap((config) =>
        POApi.post(`ocr-rest/ocr/scan/identifycard`, {
          headers: {
            'Ocp-Apim-Subscription-Key':
              config.env === ENV.PRD
                ? 'd951c9f878cd4b99b28f2263e8df5ab1'
                : config.env === ENV.STAG
                ? '5255a454fbeb49229926df7243e90cf5'
                : '1c6d8d2a9a7f474fa1e728a500b9c4f7'
          }
        })(ScanIdentifyCardResponse)(formData)
      ),
      ZIO.map((body) => body.data?.[0] ?? {})
    )
  }

  export const compareOCR = (body: ChangeNationalIdCardRequestDTO) => {
    return pipe(
      POApi.post('wf-api/customer/national-id-card/compare')(CompareOCResponse)({
        body
      }),
      ZIO.map((res) => {
        return res.body ?? {}
      })
    )
  }
}
