export enum AdmintoolList {
  ConfigT4S7MaxCaseScreen = 'ConfigT4S7MaxCaseScreen',
  ConfigT4S14ManagerTeamScreen = 'ConfigT4S14ManagerTeamScreen',
  ConfigT4S15OutboundStaffListScreen = 'ConfigT4S15OutboundStaffListScreen',
  ConfigT4S17TransferTransaction = 'ConfigT4S17TransferTransaction',
  ConfigT4S18InboundStaffListScreen = 'ConfigT4S18InboundStaffListScreen',
  ConfigA2S3AnswersListScreen = 'ConfigA2S3AnswersListScreen',
  ConfigA2S1A2S2QAListScreen = 'ConfigA2S1A2S2QAListScreen'
}

export type StackItemType = AdmintoolList

type SettingScreenParam = {
  HomeScreen: undefined
  SettingScreen:
    | undefined
    | {
        tabTitle?: string
      }
}

export type SettingStackParamList = {
  [key in StackItemType]: undefined
} & SettingScreenParam
