import { AppContext, AuthService, Container, ErrorHandling, PulseOpsFormat } from '@pulseops/common'
import { CallOutDetailContext, OBFieldDescription, OBFieldTitle, OBFormat, OBGeneralService, OBSectionCol, OBSectionContent, OBSectionRow, OBSharedStyles, OBSpecialInfoData, OBTickBox } from '@pulseops/outbound'
import React from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import {pipe} from 'fp-ts/lib/function'
import {ZIO} from '@mxt/zio'

export const OBWCCSpecialInformation = () => {
  const {t} = useTranslation()
  const [dataSpecialInfo, setDataSpecialInfo] = React.useState<OBSpecialInfoData | null>(null)
  const [loading, bindLoader] = useLoading(false)
  const { policyNumber, caseId } = React.useContext(CallOutDetailContext)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    pipe(
      OBGeneralService.getSpecialInfo(policyNumber, caseId),
      ZIO.map((data) => {
        setDataSpecialInfo(data)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [policyNumber])

  const oneFileByUrl = (url: string) => {
    showGlobalLoading(true)
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const fileType = res.headers.get('content-type') || ''
        const blobUrl = window.URL.createObjectURL(blob)
        if (fileType === 'image/tiff') {
          OBFormat.viewTiffFileByNewTab(blobUrl)
        } else {
          // const fileString = window.URL.createObjectURL(blob)
          window.open(blobUrl, '_blank')
        }
        showGlobalLoading(false)
      }),
      ZIO.mapError((err) => {
        showGlobalLoading(false)
        return err
      }),
      ErrorHandling.run()
    )
  }

  const findUrl = (docId: string) => {
    const findData = dataSpecialInfo?.documents.find((item) => item.metaData.docid === docId)
    return findData?.url
  }

  const onHandlePressText = (docId: string, docId2?: string, docId3?: string) => {
    const url = findUrl(docId) ?? (docId2 && findUrl(docId2)) ?? (docId3 && findUrl(docId3))
    if (url) oneFileByUrl(url)
  }

  return (
    <Container loading={loading}>
      <View style={OBSharedStyles.policyInfoContainer}>
        <OBSectionContent>
          <OBSectionRow>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBSpecialInfo:CustomerReceivedDate')}></OBFieldTitle>
              <OBFieldDescription
                text={
                  dataSpecialInfo?.customerReceivedDate
                    ? PulseOpsFormat.dateStringtoFormat(dataSpecialInfo?.customerReceivedDate, 'DD/MM/YYYY')
                    : '-'
                }
              ></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBSpecialInfo:DestOffice')}></OBFieldTitle>
              <OBFieldDescription text={dataSpecialInfo?.destOffice || '-'}></OBFieldDescription>
            </OBSectionCol>
            <OBSectionCol>
              <OBFieldTitle text={t('Outbound:OBSpecialInfo:Category')}></OBFieldTitle>
              <OBFieldDescription text={dataSpecialInfo?.categoryInsurance || '-'}></OBFieldDescription>
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={OBSharedStyles.generalSecondLine}>
            <OBSectionCol style={{ paddingVertical: 8 }}>
              <OBTickBox
                text={t('Outbound:OBSpecialInfo:OnlineConsulting')}
                checked={dataSpecialInfo?.isOnlineCounseling ?? false}
              ></OBTickBox>
            </OBSectionCol>
            <OBSectionCol style={{ paddingVertical: 8 }}>
              <OBTickBox
                text={t('Outbound:OBSpecialInfo:RequestCancel')}
                isHightLight={dataSpecialInfo?.isRequestCancelCFI ?? false}
                checked={dataSpecialInfo?.isRequestCancelCFI ?? false}
                onHandlePressText={() => onHandlePressText('10408341', '10408051', '10408991')}
              />
            </OBSectionCol>
            <OBSectionCol style={{ paddingVertical: 8 }}>
              <OBTickBox
                text={t('Outbound:OBSpecialInfo:ConfirmationReceive')}
                isHightLight={dataSpecialInfo?.isLetterContractHandover ?? false}
                checked={dataSpecialInfo?.isLetterContractHandover ?? false}
                onHandlePressText={() => onHandlePressText('10213181')}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={OBSharedStyles.generalSecondLine}>
            <OBSectionCol style={{ paddingVertical: 8 }}>
              <OBTickBox
                text={t('Outbound:OBSpecialInfo:PruQuote')}
                checked={dataSpecialInfo?.isSurveyFundsPruQuote ?? false}
                isHightLight={dataSpecialInfo?.isSurveyFundsPruQuote ?? false}
                onHandlePressText={() => onHandlePressText('10203011' , '102030119')}
              />
            </OBSectionCol>
            <OBSectionCol style={{ paddingVertical: 8 }}>
              <OBTickBox
                text={t('Outbound:OBSpecialInfo:Proposal')}
                checked={dataSpecialInfo?.isInsuranceClaimFile ?? false}
                isHightLight={dataSpecialInfo?.isInsuranceClaimFile ?? false}
                onHandlePressText={() => onHandlePressText('10201151', '10201141')}
              />
            </OBSectionCol>
          </OBSectionRow>
        </OBSectionContent>
      </View>
    </Container>
  )
}