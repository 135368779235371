import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  BonusSurrenderService,
  ErrorHandling,
  formatNumberWithComma,
  FundMapping,
  GeneralService,
  getPayeeNotPOData,
  Panel,
  PayoutChecker,
  PayoutPopup,
  PulseOpsFormat,
  TableField,
  TaskDetail,
  TaskDetailApi
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunctionResult } from 'i18next'
import { LasStatus } from 'libs/common/src/service/model/LasStatus'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'
import { InfoViewList, Title } from '../common'
import { PayoutMethod } from './common'

type DataSource = {
  label: string | number | boolean | JSX.Element | TFunctionResult
  value: string | number | boolean | JSX.Element
  suffix?: string | number | boolean | JSX.Element
  isHighlight?: boolean
}

type DataTable = {
  payoutMethod: string
  detail: string
  amount: string
  status: LasStatus | null | undefined
  note: string | undefined
}

export const BonusSurrender = ({ detail, policyNum }: { detail: TaskDetail.BonusSurrender; policyNum: string }) => {
  const { t, i18n } = useTranslation('requestInfo')
  const [data, setData] = React.useState<DataSource[]>([])
  const [dataTable, setDataTable] = React.useState<DataTable[]>([])
  const [totalPayoutAmount, setTotalPayoutAmount] = React.useState<string>('')
  const [isLoading, bindLoading] = useLoading(false)

  pipe(
    ZIO.zipPar(BonusSurrenderService.getAccountBalance(policyNum), GeneralService.getPayoutMethods()),
    ZIO.flatMap(([accountBalance, payoutMethod]) => {
      const translator = FundMapping.getPayoudMethod(payoutMethod.body, i18n.language)

      const bonusReserveRemainValue: number =
        accountBalance.amountLERV - (detail?.attributesExt?.BONUS_AMOUNT || 0) < 0
          ? 0
          : accountBalance.amountLERV - (detail?.attributesExt?.BONUS_AMOUNT || 0)

      setData([
        {
          label: t('BonusValue'),
          value: formatNumberWithComma(detail?.attributesExt.bonusValue || 0),
          suffix: 'VND'
        },
        {
          label: t('Principal(APL/OPL)'),
          value: formatNumberWithComma(detail?.attributesExt.principalAplOpl || 0),
          suffix: 'VND'
        },
        {
          label: t('Interest(APL/OPL)'),
          value: formatNumberWithComma(detail?.attributesExt.interestAplOpl || 0),
          suffix: 'VND'
        },
        {
          label: t('BonusValueAmountToSurrender'),
          value: formatNumberWithComma(detail?.attributesExt.bonusValueAmountToSurrender || 0),
          suffix: 'VND'
        },
        {
          label: t('BonusReserveValue'),
          value: formatNumberWithComma(detail?.attributesExt.bonusReserveValue || 0),
          suffix: 'VND',
          isHighlight: true
        },
        {
          label: t('BonusReserveRemainValue'),
          value: formatNumberWithComma(bonusReserveRemainValue),
          suffix: 'VND'
        }
      ])

      setTotalPayoutAmount(PulseOpsFormat.thousandSepartor(detail?.attributesExt.TOTAL_PAYOUT_AMOUNT))

      let payoutDetails: DataTable[] = []
      payoutDetails =
        detail?.methods?.map((x) => ({
          payoutMethod: translator(x.method),
          detail: x.detail,
          amount: PulseOpsFormat.thousandSepartor(x.amount),
          status: x.status,
          note: x.note
        })) || []

      setDataTable(payoutDetails)

      return ZIO.succeed(true)
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  // const columns = [
  //   {
  //     key: '1',
  //     title: t('PayoutMethod'),
  //     name: 'payoutMethod'
  //   },
  //   {
  //     key: '2',
  //     title: t('Detail'),
  //     name: 'detail'
  //   },
  //   {
  //     key: '3',
  //     title: t('Amount'),
  //     name: 'amount'
  //   },
  //   {
  //     key: '4',
  //     title: t('Status'),
  //     name: 'status'
  //   },
  //   {
  //     key: '5',
  //     title: t('Note'),
  //     name: 'note'
  //   }
  // ]

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.attributesExt.TOTAL_PAYOUT_AMOUNT),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  const renderLoading = () => {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="red" />
      </View>
    )
  }

  return isLoading ? (
    renderLoading()
  ) : (
    <View>
      <Title title={t('BonusSurrenderInformation')} />
      <Panel title="" isExand={false}>
        <InfoViewList dataSource={data} />
      </Panel>
      {/* <Title title={t('PaymentInformation')} />
      <Panel title="" isExand={false}>
        <View style={styles.textHorizontal}>
          <Text style={styles.text}>{t('TotalPayoutAmount')}</Text>
          <Text style={styles.isHightLight}>{totalPayoutAmount}</Text>
        </View>
        <Text style={[styles.text, styles.mb16]}>{t('PayoutDetail')}</Text>
        <TableField
          columns={columns}
          dataSource={dataTable}
          styleHeader={styles.headerTable}
          styleBody={styles.bodyTable}
        />
      </Panel> */}
      <PayoutMethod payeeData={viewPayeeData} payoutMethodData={payoutData}></PayoutMethod>
    </View>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  textHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16
  },
  text: {
    fontSize: 16
  },
  isHightLight: {
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#eD1B2E'
  },
  mb16: {
    marginBottom: 16
  },
  headerTable: {
    backgroundColor: '#e2e7ea',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  bodyTable: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  }
})
