import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace SEAWithdrawChangeForm {
  const Base = t.type({
    checkDialog: t.boolean,
    fromDate: form2.date.optional,
    toDate: form2.date.optional,
    importedFileName: form2.string.optional,
    checkReset: t.boolean
  })
  export const codec = Base
  export type Validated = t.TypeOf<typeof codec>
  export type Raw = t.OutputOf<typeof codec>
}
