import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface TransactionFieldList {
  dataSource: Array<{ label: string; value: string }>
}

export const TransactionFieldList = (props: TransactionFieldList) => {
  return (
    <View style={transactionFieldStyle.sectionContent}>
      <View style={transactionFieldStyle.sectionRow}>
        {props.dataSource.map((item, i) => {
          return (
            <View key={i} style={transactionFieldStyle.col_4}>
              <Text style={transactionFieldStyle.field_title}>{item.label}</Text>
              <Text style={transactionFieldStyle.field_description}>{item.value}</Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}

const transactionFieldStyle = StyleSheet.create({
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15,
    marginBottom: 20
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  }
})
