import { assets } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, Text, View, Linking } from 'react-native'
import { Button } from 'react-native-elements'
import styled from 'styled-components/native'
import { UniversalLink } from '../../const'

export const AGREE_COOKIE_KEY = 'cookies_key'
type Props = {
  onClose: () => void
  onConfirm: () => void
}
export const ConsentPopup = ({ onClose, onConfirm }: Props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = React.useState<boolean>(false)
  React.useEffect(() => {
    const isAgreeCookie = localStorage.getItem(AGREE_COOKIE_KEY) ?? '0'
    setVisible(isAgreeCookie !== '1')
  }, [])
  if (visible) {
    return (
      <SC.ConsentContainer>
        <SC.ContentContainer>
          <SC.NormalText>
            Website hiện đang sử dụng cookies nhằm mang lại những trải nghiệm tốt nhất cho người dùng. Bằng việc tiếp
            tục sử dụng website, bạn đã đồng ý với{' '}
            <Pressable onPress={() => Linking.openURL(UniversalLink.TERM_LINK)}>
              <SC.LinkText>Chính sách bảo mật</SC.LinkText>
            </Pressable>{' '}
            và cho phép chúng tôi sử dụng cookies.
          </SC.NormalText>
          <SC.Button
            title={t('landingPage:confirm')}
            onPress={() => {
              localStorage.setItem(AGREE_COOKIE_KEY, '1')
              setVisible(false)
              onConfirm()
            }}
          />
        </SC.ContentContainer>
        <SC.CloseButton>
          <Pressable
            onPress={() => {
              localStorage.setItem(AGREE_COOKIE_KEY, '0')
              setVisible(false)
              onClose()
            }}
          >
            <assets.CloseTaskModalIcon />
          </Pressable>
        </SC.CloseButton>
      </SC.ConsentContainer>
    )
  }
  return <></>
}

const SC = {
  ConsentContainer: styled(View)`
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #000;
  `,
  ContentContainer: styled(View)`
    max-width: 70%;
  `,
  NormalText: styled(Text)`
    font-size: 14px;
    color: #fff;
    text-align: center;
  `,
  LinkText: styled(Text)`
    text-decoration: underline;
    text-decoration-color: #fff;
    font-size: 15px;
    font-family: 'NotoSans_400Regular';
    color: #fff;
    margin-bottom: 8px;
  `,
  CloseButton: styled(View)`
    position: absolute;
    right: 10px;
    top: 10px;
  `,
  Button: styled(Button).attrs(() => ({
    buttonStyle: {
      backgroundColor: 'red',
      borderRadius: 100,
      alignSelf: 'center',
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16
    },
    titleStyle: {
      fontFamily: 'NotoSans_700Bold',
      fontSize: 15
    }
  }))``
}
