import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'

export namespace CandidateInformationForm {
  const Base = t.type({
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('common:SubmissionDateFrom') })
    ),
    toDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('common:SubmissionDateTo') })),
    saleType: form2.selectOption.optional,
    template: form2.selectOption.optional,
    transactionType: form2.selectOption.optional,
    source: form2.selectOption.optional,
    email: form2.email.optional,
    status: form2.selectOption.optional
  })

  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(
    Base,
    form2.refine(
      (l): l is Form => {
        return !!(l.fromDate && l.toDate && moment(l.fromDate).isSameOrBefore(l.toDate))
      },
      (l) => i18next.t('message:MS030044'),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}
