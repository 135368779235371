export namespace OBUploadedHistoryConst {
  export type DepartmentInfoConst = {
    code: string
    nameVi: string
    nameEn: string
  }
  export const DepartmentListConst: DepartmentInfoConst[] = [
    { code: '', nameVi: 'Tất cả', nameEn: 'All' },
    { code: 'CS', nameVi: 'CS', nameEn: 'CS' },
    { code: 'CCE', nameVi: 'CCE', nameEn: 'CCE' }
  ]

  export const StatusTriggerConst = [
    { code: '', nameVi: 'Tất cả', nameEn: 'All' },
    { code: 'valid', nameVi: 'Hợp lệ', nameEn: 'Valid' },
    { code: 'invalid', nameVi: 'Không hợp lệ', nameEn: 'Invalid' },
    { code: 'success', nameVi: 'Thành công', nameEn: 'Success' },
    { code: 'timeout', nameVi: 'Lỗi kết nối', nameEn: 'Time out' },
    { code: 'fail', nameVi: 'Thất bại', nameEn: 'Fail trigger' },
    { code: 'submit', nameVi: 'Đã gửi', nameEn: 'Submitted'}
  ]

  export const OBUploadStatusConst: DepartmentInfoConst[] = [
    { code: 'reading', nameEn: 'Reading', nameVi: 'Đang đọc' },
    { code: 'uploaded', nameEn: 'Wait for system to verify detail', nameVi: 'Đã tải lên' },
    { code: 'completed', nameEn: 'Completed', nameVi: 'Hoàn tất' },
  ]
}
