import { StyleSheet, Text, View, TouchableOpacity, useWindowDimensions, ScrollView } from 'react-native'
import React, { useContext } from 'react'
import _ from 'lodash'
import { AppContext, PulseOpsFormat } from '@pulseops/common'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { IBClientParamList } from '../IBClientParamList'
import { useTranslation } from 'react-i18next'
import { IBService, PolicySummaryData, PrurewardsSummaryData } from '../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ClientScreenContext } from '../../common'

interface IBBox {
  title: {
    value: number
    label: string
    backgroundColor: string
    color: string
    onClick?: () => void
  }
  content: {
    value: number | string
    label: string | JSX.Element
    hidden?: boolean
    onClick?: () => void
  }[]
}

const IBBox = (props: IBBox) => {
  const { title, content } = props
  const width = useWindowDimensions().width - 300
  return (
    <View style={[styles.containerBox, { width: width < 1200 ? 305 : 360, height: width < 1200 ? 249 : 304 }]}>
      <View style={[styles.boxTitle, { backgroundColor: title.backgroundColor }]}>
        <TouchableOpacity onPress={title.onClick} disabled={_.isUndefined(title.onClick)}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.boxTitleNumber, { color: title.color }]}>{title.value}</Text>
            <Text style={[styles.boxTitleLabel, { color: title.color }]}>{title.label}</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.boxContent}>
        {content
          .filter((x) => !x.hidden)
          .map((item) => (
            <View style={{ marginBottom: 5, flexDirection: 'row' }}>
              <Text
                style={{
                  color: '#ED1B2E',
                  fontWeight: '700',
                  marginRight: !_.isString(item.value) ? 5 : 0,
                  fontSize: 15
                }}
              >
                {item.value}
              </Text>
              <TouchableOpacity onPress={item.onClick} disabled={_.isUndefined(item.onClick)}>
                <Text style={{ fontSize: 15 }}>{item.label}</Text>
              </TouchableOpacity>
            </View>
          ))}
      </View>
    </View>
  )
}

type Props = StackScreenProps<IBClientParamList, 'DashBoardScreen'>

export const IBClientDataBox = ({
  route,
  setPolicySummaryData,
  policySummaryData
}: Props & {
  policySummaryData: PolicySummaryData & { prurewardsSummary: PrurewardsSummaryData }
  setPolicySummaryData: (data: PolicySummaryData & { prurewardsSummary: PrurewardsSummaryData }) => void
}) => {
  const width = useWindowDimensions().width - 300
  const navigation = useNavigation()
  const { t } = useTranslation('Inbound')
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoading] = useLoading(false)
  const isFocused = useIsFocused()
  const { vipLevel } = useContext(ClientScreenContext)
  const handleChange = (tabIndex: number, tabPolicyInfo?: number, transactionInfo?: string) => {
    navigation.reset({
      routes: [
        {
          name: 'IBClientPolicyScreen',
          params: {
            clientNumber: route?.params?.clientNumber,
            tabIndex: tabIndex,
            tabPolicyInfo: tabPolicyInfo,
            transactionInfo: transactionInfo
          }
        }
      ]
    })
  }

  React.useEffect(() => {
    if (_.isNil(policySummaryData)) {
      pipe(
        ZIO.zipPar(
          IBService.getPolicySummary(route?.params?.clientNumber),
          IBService.getPrurewardsSummary(route?.params?.clientNumber)
        ),
        ZIO.map(([res, prurewardsSummary]) => {
          setPolicySummaryData({ ...res.data, prurewardsSummary: prurewardsSummary.data })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  const dataTransaction = [
    {
      title: {
        value:
          policySummaryData?.complaintTransactionSummary?.reduce((cur, pre) => (cur += pre.transactionQuantity), 0) ??
          0,
        label: t('Complaint'),
        backgroundColor: '#F2F2FF',
        color: '#5959FF',
        onClick: () => {
          handleChange(4, undefined, 'CH')
        }
      },
      content: [
        {
          value:
            policySummaryData?.complaintTransactionSummary?.find((x) => x.statusGroup === 'Processing')
              ?.transactionQuantity ?? 0,
          label: t('InprogressComplaint')
        },
        {
          value:
            policySummaryData?.complaintTransactionSummary?.find((x) => x.statusGroup === 'Completed')
              ?.transactionQuantity ?? 0,
          label: t('CompletedComplaint')
        }
      ]
    },
    {
      title: {
        value:
          policySummaryData?.requestTransactionSummary?.reduce((cur, pre) => (cur += pre.transactionQuantity), 0) ?? 0,
        label: t('Request'),
        backgroundColor: '#F9F0FC',
        color: '#B340DA',
        onClick: () => {
          handleChange(4, undefined, 'PS')
        }
      },
      content: [
        {
          value:
            policySummaryData?.requestTransactionSummary?.find((x) => x.statusGroup === 'Pending')
              ?.transactionQuantity ?? 0,
          label: t('PendingRequest')
        },
        {
          value:
            policySummaryData?.requestTransactionSummary?.find((x) => x.statusGroup === 'Processing')
              ?.transactionQuantity ?? 0,
          label: t('InprogressRequest')
        },
        {
          value:
            policySummaryData?.requestTransactionSummary?.find((x) => x.statusGroup === 'Completed')
              ?.transactionQuantity ?? 0,
          label: t('CompletedRequest')
        }
      ]
    },
    {
      title: {
        value:
          policySummaryData?.queryTransactionSummary?.reduce((cur, pre) => (cur += pre.transactionQuantity), 0) ?? 0,
        label: t('Query'),
        backgroundColor: '#D1D7E3',
        color: '#58647A',
        onClick: () => {
          handleChange(4, undefined, 'CS')
        }
      },
      content: [
        {
          value:
            policySummaryData?.queryTransactionSummary?.find((x) => x.statusGroup === 'Processing')
              ?.transactionQuantity ?? 0,
          label: t('InprogressQuery')
        },
        {
          value:
            policySummaryData?.queryTransactionSummary?.find((x) => x.statusGroup === 'Completed')
              ?.transactionQuantity ?? 0,
          label: t('CompletedQuery')
        }
      ]
    }
  ]

  const data = [
    {
      title: {
        value: policySummaryData?.policySummary?.reduce((cur, pre) => (cur += pre.totalPolicy), 0) ?? 0,
        label: t('common:Policy'),
        backgroundColor: '#FEF8ED',
        color: '#F2A916',
        onClick: () => {
          handleChange(1)
        }
      },
      content: [
        {
          value: policySummaryData?.policySummary?.find((x) => x.statusGroup === 'Proposal')?.totalPolicy ?? 0,
          label: t('DBProposal'),
          onClick: () => {
            handleChange(1, 1)
          }
        },
        {
          value: policySummaryData?.policySummary?.find((x) => x.statusGroup === 'InforcePaidUp')?.totalPolicy ?? 0,
          label: t('DBInforce'),
          onClick: () => {
            handleChange(1, 2)
          }
        },
        {
          value: policySummaryData?.policySummary?.find((x) => x.statusGroup === 'LapsedFrozen')?.totalPolicy ?? 0,
          label: t('DBLapse'),
          onClick: () => {
            handleChange(1, 3)
          }
        },
        {
          value: policySummaryData?.policySummary?.find((x) => x.statusGroup === 'Matured')?.totalPolicy ?? 0,
          label: t('DBMatured'),
          onClick: () => {
            handleChange(1, 4)
          }
        },
        {
          value: policySummaryData?.policySummary?.find((x) => x.statusGroup === 'Claim')?.totalPolicy ?? 0,
          label: t('DBPostpone'),
          onClick: () => {
            handleChange(1, 5)
          }
        },
        {
          value: policySummaryData?.policySummary?.find((x) => x.statusGroup === 'Other')?.totalPolicy ?? 0,
          label: t('DBDeathClaim'),
          onClick: () => {
            handleChange(1, 6)
          }
        }
      ]
    },
    {
      title: {
        value: policySummaryData?.claimSummary?.reduce((cur, pre) => (cur += pre.totalClaim ?? 0), 0) ?? 0,
        label: t('ClaimRequest'),
        backgroundColor: '#EDFAF4',
        color: '#1DC06F',
        onClick: () => {
          handleChange(2)
        }
      },
      content: [
        {
          value: policySummaryData?.claimSummary?.find((x) => x.statusGroup === 'InProgress')?.totalClaim ?? 0,
          label: t('InProgressClaim')
        },
        {
          value: policySummaryData?.claimSummary?.find((x) => x.statusGroup === 'Completed')?.totalClaim ?? 0,
          label: t('CompletedClaim')
        }
      ]
    },
    {
      title: {
        value: policySummaryData?.prurewardsSummary?.point || 0,
        label: 'PRURewards',
        backgroundColor: '#EBF8F7',
        color: '#00AA96',
        onClick: () => {
          handleChange(3)
        }
      },
      content: [
        {
          value: '',
          label: (
            <Text style={{ color: '#ED1B2E', fontWeight: '700' }}>{`${
              policySummaryData?.prurewardsSummary?.expiredPointInNextMonth || 0
            } ${t('ExpiredPointInUpcomingMonth')}`}</Text>
          )
        },
        {
          value: policySummaryData?.prurewardsSummary?.codeRedeemSevenDay || 0,
          label: t('RedeemedCodeWithinLast7Days')
        },
        {
          value: policySummaryData?.prurewardsSummary?.giftRedeemThirtyDay || 0,
          label: t('RedeemedGiftWithinLast30Days')
        },
        {
          value: policySummaryData?.prurewardsSummary?.healthCheckInvitation || 0,
          label: t('RedeemedHealthCheckLetterInThisYear'),
          hidden: !['P', 'K'].includes(vipLevel)
        },
        {
          value: parseInt(policySummaryData?.prurewardsSummary?.upDownTier || '0') || 0,
          label: t('VIPTierChange')
        },
        {
          value: '',
          label: (
            <Text style={{ color: '#000', fontWeight: '700' }}>
              {t('APEVND', { amount: PulseOpsFormat.thousandSepartor(policySummaryData?.prurewardsSummary?.ape) || 0 })}
            </Text>
          )
        }
      ]
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('ContactCenterInquirySystem'),
          navigate: () => {
            navigation.navigate('IBSearchScreen')
          }
        },
        {
          title: t('Client'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  return (
    <ScrollView
      contentContainerStyle={{ width: '100%' }}
      style={{ backgroundColor: '#F9F9F9' }}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
        <View style={[styles.container]}>
          {data.map((item) => (
            <IBBox title={item.title} content={item.content} />
          ))}
        </View>
      </View>
      <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
        <View style={styles.borderTransaction}>
          <TouchableOpacity onPress={() => handleChange(4)}>
            <Text style={styles.textInBorder}>{t('Transaction')}</Text>
          </TouchableOpacity>
          <View style={styles.container}>
            {dataTransaction.map((item) => (
              <IBBox title={item.title} content={item.content} />
            ))}
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  containerBox: {
    // height: 304,
    // width: 360,
    margin: 15
  },
  boxTitle: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    justifyContent: 'space-between'
  },
  boxContent: {
    backgroundColor: '#FFFFFF',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: '70%',
    padding: 15
  },
  boxTitleNumber: {
    fontSize: 40,
    fontWeight: '700',
    lineHeight: 42,
    marginRight: 10
  },
  boxTitleLabel: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 42
  },
  borderTransaction: {
    borderWidth: 1,
    borderColor: '#09A4D8',
    borderRadius: 20,
    marginTop: 15
  },
  textInBorder: {
    width: 'max-content',
    marginTop: -25,
    marginLeft: 20,
    backgroundColor: '#F9F9F9',
    color: '#09A4D8',
    fontSize: 28,
    lineHeight: 42,
    fontWeight: '700'
  }
})
