import { Task } from '@mxt/zio'
import { Any, Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/pipeable'
import * as t from 'io-ts'
import moment from 'moment'
import { POApi } from '../POApi'
import { GoAbroadModel, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export const lAClient = t.type({
  clientNumberOfLA: t.string,
  life: t.string
})

export const benClient = t.type({
  clientNumberOfBen: t.string,
  percent: t.number
})

export type lAClient = t.TypeOf<typeof lAClient>
export type benClient = t.TypeOf<typeof benClient>

const BaseRole = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      clientCode: t.string,
      role: t.union([t.literal('LA'), t.literal('BEN'), t.literal('OWNER')])
    }),
    codec
  ])

export const laAbroadClient = BaseRole(lAClient)
export const benAbroadClient = BaseRole(benClient)
export const ownerAbroadClient = BaseRole(SubmissionService.ClientInfo)

export type laAbroadClient = t.TypeOf<typeof laAbroadClient>
export type benAbroadClient = t.TypeOf<typeof benAbroadClient>
export type ownerAbroadClient = t.TypeOf<typeof ownerAbroadClient>

export const AbroadClient = t.union([laAbroadClient, benAbroadClient, ownerAbroadClient])
export type AbroadClient = laAbroadClient | benAbroadClient | ownerAbroadClient

export const AbroadClientMapping = t.type({
  info: SubmissionService.ClientInfo,
  detail: AbroadClient
})

export type AbroadClientMapping = t.TypeOf<typeof AbroadClientMapping>

export namespace GoAbroadService {
  export const getClientsByPolicyNum = (policyNum: string): Task<AbroadClient[]> =>
    pipe(
      POApi.get(`wf-api/bo/${policyNum}/clients`)(
        t.type({
          body: Maybe(
            t.type({
              laList: t.array(lAClient),
              benList: t.array(benClient)
            })
          )
        })
      ),
      ZIO.map((client) => {
        let results: AbroadClient[] = []
        const lAClient = client.body?.laList
        const benClient = client.body?.benList
        if (lAClient && lAClient.length) {
          results = results.concat(
            lAClient.map(
              (c) =>
                <AbroadClient>{
                  ...c,
                  clientCode: c.clientNumberOfLA,
                  role: 'LA'
                }
            )
          )
        }
        if (benClient && benClient.length) {
          // Do not get Ben right now
          // results = results.concat(benClient.map(c => <AbroadClient>{
          //   ...c,
          //   clientCode: c.clientNumberOfBen,
          //   role: 'BEN'
          // }))
        }
        return results
      })
    )

  // @ts-ignore
  export const getAbroadClients = (policyNmber: string): ZIO<Any, Throwable, AbroadClientMapping[]> =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNmber), getClientsByPolicyNum(policyNmber)),
      ZIO.flatMap(([policy, clients]) => {
        const newClients = clients.filter((c) => c.clientCode)
        if (!newClients.find((c) => c.clientCode === policy.body.owners.id)) {
          newClients.push(<AbroadClient>{
            clientCode: policy.body.owners.id,
            role: 'OWNER'
          })
        }
        return pipe(
          newClients,
          A.map((c) =>
            pipe(
              SubmissionService.getCustomer(c.clientCode),
              ZIO.map(
                (cInfo): AbroadClientMapping => ({
                  info: <SubmissionService.ClientInfo>{
                    // ...cInfo,
                    clientName: cInfo.body.name,
                    idNum: cInfo.body.clientId,
                    email: cInfo.body.email,
                    birthDate: cInfo.body.dob,
                    gender: cInfo.body.sex,
                    clientPhoneNumber: cInfo.body.mobilePhone,
                    dod: cInfo.body.dod
                  },
                  detail: { ...c }
                })
              )
            )
          ),
          ZIO.sequence
        )
      }),
      ZIO.map((arr): AbroadClientMapping[] => {
        return arr.filter((a) => !moment(a.info.dod, 'YYYY/MM/DD').isBefore(new Date()))
      })
    )

  export const submit =
    (policyNum: string, data: GoAbroadModel.GoAbroadSubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/go-abroad`,
        { body: data },
        policyNum,
        requestauth,
        []
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/go-abroad`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )
}
