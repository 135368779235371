import React from 'react'
import { View, Platform } from 'react-native'
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form'
import { ReinstatementForm, ChooseView } from './common'
import {
  assets,
  Panel,
  SelectOption,
  FieldList,
  AppContext,
  getDiffMonths,
  SubmissionService,
  ErrorHandling,
  ImgUploadMutiple,
  SelectSearch,
  BuildConfig
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { SC, Input, ValueShow } from '../../common'
import _ from 'lodash'

export const RequestTab: React.FC<{
  policyNum?: string
  form: Omit<UseFormReturn<ReinstatementForm.Raw>, 'handleSubmit'>
  headerFields: FieldList.FieldType[]
  occupations: SelectOption[]
}> = ({ form, occupations, headerFields }) => {
  const { t } = useTranslation()
  const {
    control,
    watch,
    formState: { errors }
  } = form
  //const { reinClients } = watch()
  const { fields } = useFieldArray<ReinstatementForm.Raw>({
    control,
    name: 'formRein.reinClients'
  })
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  // const onCurrOpcupitationChange = React.useRef(new Subject<string>()).current
  // const onNewCompanyChangeChange = React.useRef(new Subject<string>()).current
  // const onNewActivityChange = React.useRef(new Subject<string>()).current

  // const debounceFn = React.useCallback(_.debounce(() => console.log('sasd'), 300), [])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  /* 
      Lapsed period < 12 months AND Any changes in state of health = "Change" 
      OR
      Lapsed period ≥ 12 months
    */
  const checkRequiredHealthDeclare = () => {
    const lapseDate = moment(watch('formRein.lapsedDate') ?? new Date())
    const currentDate = moment(new Date())
    // const diffM = getDiffMonths(lapseDate, endDate)
    const diffM = moment(currentDate).diff(lapseDate, 'months')
    // console.log('diff mounnn', diffM)
    const requiredFlag = diffM >= 12 || (diffM < 12 && watch('formRein.reinClients')?.some((c) => c.hAnswer))
    return requiredFlag
  }

  const getAgentNameByCode = (agentCode: string) => {
    return pipe(
      ZIO.zipPar(
        ZIO.fromPromise(() => form.trigger('formRein.agentCode')),
        ZIO.succeed(agentCode)
      ),
      ZIO.flatMap(([isValid, value]) => {
        if (isValid) {
          return pipe(
            SubmissionService.getAgents([value]),
            ZIO.map((c) => c[0]),
            ZIO.foldM(
              (_) => ZIO.unit,
              (client) => {
                if (client && client.clientId === 'ERROR') {
                  form.setError('formRein.agentCode', { message: t('message:MS020019') })
                }
                form.setValue('formRein.agentName', client.name || '')
                return ZIO.unit
              }
            )
          )
        }
        form.setValue('formRein.agentName', '')
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:ReinstatementInfomation').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <FieldList dataSource={headerFields} />
      </SC.PanelContainer>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:StatementOfInsurability').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      {fields &&
        fields.map((value, index) => {
          return (
            <Controller
              key={`list_rein_${index}`}
              control={control}
              name={`formRein.reinClients.${index}`}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Panel title={value.name || ''} containerStyle={{ backgroundColor: '#fafafa' }}>
                    <ChooseView
                      title={t('submission:AnyChangesInStateOfHealth')}
                      selected={value.hAnswer}
                      onChange={(select) => onChange({ ...value, hAnswer: select })}
                    />

                    <ChooseView
                      title={t('submission:AnyChangesInOccupation')}
                      selected={value.oAnswer}
                      onChange={(select) => onChange({ ...value, oAnswer: select })}
                    />
                    {value.oAnswer && (
                      <SC.Row>
                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            {/* <Input
                                title={t('submission:CurrentOccupation')}
                                //onChange={(text) => onChange({ ...value, curOccupation: text })}
                                placeholder={'Input'}
                                value={value.curOccupation}
                                onBlur={onBlur}
                                disabled
                                errorMessage={_.get(errors, `reinClients.${index}.curOccupation`)?.message}
                              /> */}
                            <ValueShow
                              title={t('submission:CurrentOccupation')}
                              value={value.curOccupation?.label || ''}
                            />
                          </SC.Padding>
                        </SC.InputContainer>

                        <SC.Divider width={20} />

                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <SelectSearch
                              options={occupations}
                              value={value.newOccupation}
                              label={t('submission:NewOccupation')}
                              required
                              onChange={(select) => {
                                onChange({ ...value, newOccupation: select })
                              }}
                              placeholder={t('common:Select')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              errorMessage={_.get(errors, `formRein.reinClients.${index}.newOccupation`)?.message}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}

                    <ChooseView
                      title={t('submission:AnyChangeInActivities')}
                      selected={value.aAnswer}
                      onChange={(select) => onChange({ ...value, aAnswer: select })}
                    />

                    {value.aAnswer && (
                      <SC.Row>
                        <SC.InputContainer maxWidth={300}>
                          <SC.Padding vertical={10}>
                            <Input
                              title={t('submission:NewActivity')}
                              value={value.newActivity}
                              onChange={(text) => onChange({ ...value, newActivity: text })}
                              required
                              onBlur={onBlur}
                              maxLength={100}
                              placeholder={'Input'}
                              errorMessage={_.get(errors, `formRein.reinClients.${index}.newActivity`)?.message}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}
                    <ChooseView
                      title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                      selected={value.cAnswer}
                      onChange={(select) => onChange({ ...value, cAnswer: select })}
                    />

                    {value.cAnswer && (
                      <SC.Row>
                        <SC.InputContainer>
                          <SC.Padding vertical={5}>
                            <Input
                              title={t('submission:CompanyName')}
                              value={value.newCompany}
                              onChange={(text) => onChange({ ...value, newCompany: text })}
                              required
                              onBlur={onBlur}
                              placeholder={'Input'}
                              errorMessage={_.get(errors, `formRein.reinClients.${index}.newCompany`)?.message}
                            />
                          </SC.Padding>
                        </SC.InputContainer>
                      </SC.Row>
                    )}
                  </Panel>
                )
              }}
            />
          )
        })}
      {checkRequiredHealthDeclare() && (
        <SC.Padding>
          {Platform.OS === 'web' && (
            <SC.BoldText>
              {t('submission:HealthNote')}
              <a href={`${assets.UpdatedRedatingHealthyTemplate}`} download style={{ color: 'red' }}>
                {t('submission:Here')}
              </a>
            </SC.BoldText>
          )}
          <Controller
            control={control}
            name="formRein.attachmentFiles"
            render={({ field: { value, onChange } }) => {
              const wrapFile =
                value?.map((file) => ({ ...file, uploadedDate: file.uploadedDate!, file: file.file! })) || []
              return (
                <SC.Padding>
                  <SC.Padding top={8}>
                    <SC.BoldText>
                      {t('submission:HealthDeclaration')} <SC.ErrorText>*</SC.ErrorText>
                    </SC.BoldText>
                  </SC.Padding>
                  <ImgUploadMutiple value={wrapFile} onChange={onChange} />
                  <SC.ErrorText>{_.get(errors, 'formRein.message')}</SC.ErrorText>
                </SC.Padding>
              )
            }}
          />
        </SC.Padding>
      )}

      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:ReinstatementAgent').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <SC.Row>
          <Controller
            control={control}
            name={'formRein.agentCode'}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <SC.InputContainer minWidth={300}>
                  <Input
                    title={t('submission:Agent')}
                    value={value}
                    onBlur={() => {
                      onBlur()
                      getAgentNameByCode(value)
                    }}
                    maxLength={8}
                    required
                    onChange={(value) => {
                      onChange(value)
                      //const a = await form.trigger('agentCode')
                    }}
                    errorMessage={errors.formRein?.agentCode?.message}
                  />
                </SC.InputContainer>
              )
            }}
          />
          <SC.Divider />
          <SC.CenterSelf>
            <Controller
              control={control}
              name={'formRein.agentName'}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <View style={{ alignSelf: 'flex-end', flex: 1 }}>
                    <Input value={value} disabled />
                  </View>
                )
              }}
            />
          </SC.CenterSelf>
        </SC.Row>
      </SC.PanelContainer>
    </SC.Container>
  )
}
