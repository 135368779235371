import { RiderAlterationService } from '../RiderAlterationService'
import { InquiryComplaintData } from './InquiryComplaint'

export namespace CancelRider {
  export const UnCancelRiderList: string[] = ['PSR1', 'FSR1', 'FCR1', 'PER1', 'PER2', 'PER3']

  export interface CanRiderClientInfo {
    clientNumberOfLA: string
    life: string
    clientName: string
  }

  export interface LARiderInfo {
    clientName: string
    lifeAssured: string
    lifeNo?: string
    riders: CancelRiderInfo[]
    extraRider: FilterRiderInfo[]
    alteredRiderList?: RiderAlterationService.RiderNameInfo[]
  }

  export interface DetailCancelRiderInfo {
    totalPremium: number
    laRiderInfo: LARiderInfo[]
    minPremium: number
    riderNameList: InquiryComplaintData.IACSources[]
  }

  export interface CancelRiderInfo {
    productCode: string
    productName: string
    riskCommDate: string
    riskCessDate: string
    lapsedDate?: string | null
    sumAssured: number
    installmentPrem: number
    newInstallmentPremium: number
    lifeNo: string
    coverageNo: string
    riderNo: string
    riderStatus: string
  }
  export interface FilterRiderInfo {
    productCode: string
  }
  export interface SubmitedRiderInfo {
    productCode: string
    riskCommDate: string
    riskCessDate: string
    sumAssured: number
    installmentPremium: number
    newInstallmentPremium: number
    lifeNo: string
    coverageNo: string
    riderNo: string
    status: string
  }

  export interface CancelRiderAccessData {
    contractStatus: string
    notExistAnyRider: boolean
    proposalReceivedDate: string
  }

  export interface SubmitCancelRiderData {
    lifeAssureds: SubmitLARider[]
  }

  export interface SubmitLARider {
    lifeAssured: string
    riders: SubmitedRiderInfo[]
  }
}
