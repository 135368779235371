import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { AdmintoolService, OBSharedStyles } from '@pulseops/outbound'
import { AppContext, ErrorHandling, Input, ModalComponent, Permission, RBAC, SelectOption, SelectSearch, assets } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { Controller, useForm } from 'react-hook-form'
import { IBGeneralTable } from '@pulseops/inbound'
import { validateMail } from './commonFunction'
import { useLoading } from '@mxt/zio-react'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'

type ManagerForm = {
  manager: SelectOption | null
  team: string
  id: string
  teamName: string
}

type DataTable = {
  no: number
  id: string
  manager: string
  teamNo?: number
  team: string
}

interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }
export const ConfigT4S14ManagerTeamScreen = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [isOpenEditPopup, setOpenEditPopup] = React.useState<boolean>(false)
  const roles: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidMount([]))
  const [dataTable, setDataTable] = React.useState<DataTable[]>([])
  const [isLoading, bindLoading] = useLoading(false)
  const [reload, setReload] = React.useState<boolean>(false)
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { height, width } = useWindowDimensions()
  const [messageError, setMessageError] = React.useState<string | null>(null)


  const formManager = useForm<ManagerForm>({
    defaultValues: {
      id: '',
      manager: null,
      team: '',
      teamName: ''
    }
  })
  const filterColumnList = {
    manager: '',
    team: '',
  }

  const listMailManager = pipe(
    AdmintoolService.getListMailManager(),
    ZIO.map((res) => res && _.uniqBy(res.map((elm) => ({ label: elm.email ?? '', value: elm.email ?? '' })), 'value')),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen' }) },
        { title: t('Setting:AdminTool'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen', params: { tabTitle: 'adminTool' } }) },
        { title: t('Outbound:AdminTool:T4S14'), navigate: null }
      ])
    }

  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    pipe(
      AdmintoolService.getT4S14Config(),
      ZIO.map((res) => {
        res.data && setDataTable(res.data.map((i, index) => ({
          no: index + 1,
          id: i.id ?? '',
          manager: i.managerEmail ?? '',
          team: i.teamName ?? '',
          teamNo: i.teamNo ?? undefined
        }))
        .sort((p1,p2) => p1.teamNo && p2.teamNo && p1.teamNo > p2.teamNo ? 1 : -1)
        .map((i, index) => ({
          ...i,
          no: index + 1,
        })))
        reload && setReload(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [reload])

  const columns = [
    {
      label: t('Outbound:AdminTool:No'),
      field: 'no',
      disabled: true
    },
    {
      label: t('Outbound:AdminTool:Team'),
      field: 'team'
    },
    {
      label: t('Outbound:AdminTool:Manager'),
      field: 'manager'
    },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (value: string) => {
        return (
          roles.includes(Permission.ViewT4S14ManagerOfTeam) ?
            <TouchableOpacity
              onPress={() => {
                formManager.reset()
                setMessageError('')
                setOpenEditPopup(true)
                const findData = dataTable.find((i) => i.id === value)
                const findMail = listMailManager?.find((i) => i.value === findData?.manager)
                formManager.setValue('id', findData?.id ?? '')
                formManager.setValue('manager', findMail ? findMail : findData?.manager ? { value: findData?.manager, label: findData?.manager } : null)
                formManager.setValue('team', findData?.teamNo?.toString() ?? '')
                formManager.setValue('teamName', findData?.team ?? '')
              }}
            >
              <assets.OBPencilGray />
            </TouchableOpacity> :
            <></>
        )
      }
    }
  ]

  const validateExistMail = (mail: string, idValue: string) => {
    const findData = dataTable.find((i) => i.manager.toLocaleLowerCase() === mail.toLocaleLowerCase() && i.id !== idValue)
    if (findData) return false
    return true
  }

  // const validateManagertMail = (mail: string) => {
  //   return pipe(
  //     RBAC.getPermission(mail),
  //     ZIO.map((res) => {
  //       if(res.groups && (res.groups.includes('OB-Manager') || res.groups.includes('OB-Manager-BAU'))) return true
  //       return false
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  // }

  const TableConfig = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <IBGeneralTable
          dataTable={columns}
          data={dataTable}
          maxWidthContainer
          maxHeight={height - height * 0.25}
          maxWidth={width - width * 0.025}
          autoPaging
          filterColumn={filterColumnList}
        >
        </IBGeneralTable>
      </View>
    )
  }

  const FieldEdit = () => {
    const findData = dataTable.find((i) => i.id === formManager.watch('id'))
    return (
      <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <View style={styles.row}>
          <View style={[OBSharedStyles.sectionCol6, { paddingHorizontal: 0 }]}>
            <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 8 }}>{t('Outbound:AdminTool:Team')}</Text>
            <Text style={{ marginTop: 4, marginBottom: 5, fontSize: 15 }}>{findData?.team ?? ''}</Text>
          </View>
          <View style={[OBSharedStyles.sectionCol6, { paddingHorizontal: 0 }]}>
            <Controller
              control={formManager.control}
              name={'manager'}
              rules={{
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Manager') }) },
                validate: (option: SelectOption) => {
                  if (option.value && !validateMail(option.value)) {
                    return t('message:OB0028')
                  }
                  if (option.value && !validateExistMail(option.value, formManager.watch('id'))) {
                    return t('message:IB0020')
                  }
                  return true
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch                  
                  required={true}
                  label={t("Outbound:OBAssignment:ManagerName")}
                  showPopupIcon={true}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  value={value}
                  // isShowFullText={true}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={listMailManager ?? []}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row', paddingTop: 10 }}>
          <Text style={{ color: '#ED1B2E' }}>
            {messageError}
          </Text>
        </View>
      </View>
    )
  }

  const EditPopup = () => {
    return (
      <ModalComponent
        children={FieldEdit()}
        visible={isOpenEditPopup}
        onClose={() => setOpenEditPopup(false)}
        title={t('Outbound:AdminTool:Edit')}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => setOpenEditPopup(false),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35, marginRight: 10 }
          },
          {
            text: t('common:Save'),
            type: 'filled',
            action: () => onSave(),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35 }
          }
        ]}
        centerTitle
        modalWidth={800}
      />
    )
  }

  const onSave = formManager.handleSubmit((value) => {
    pipe(
      AdmintoolService.updateT4S14Config({
        id: value.id,
        managerEmail: value.manager?.value ?? '',
        teamNo: value.team ? Number(value.team) : undefined,
        teamName: value.teamName ?? '',
      }),
      ZIO.map((res) => {
        setReload(true)
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showToast(error.source.message, 'error')
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  return (
    <View style={styles.container}>
      {
        roles.includes(Permission.ViewT4S14ManagerOfTeam) &&
        <>
          {TableConfig()}
          {EditPopup()}
        </>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
    paddingVertical: 10
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  addButton: {
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    flexDirection: 'row',
    borderRadius: 8,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: 10
  },
})
