import { ProductCode } from '@pulseops/business/core'
import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace ProductInfoForm {
  export const codec = t.type({
    benefits: t.array(
      t.intersection([
        t.type({
          clazz: form2.string.requiredMaxLength(50),

          ddProductCodeB: form2.stringEnum(ProductCode).required,
          geographicCoverageB: form2.string.requiredMaxLength(100),
          sumAssuredB: form2.number.requiredMin(0)
        }),
        t.union([
          t.type({
            grad: t.literal(true),
            geographicCoverageR: form2.string.requiredMaxLength(100),
            sumAssuredR: form2.number.requiredMin(0)
          }),
          t.type({
            grad: t.literal(false)
          })
        ]),
        t.union([
          t.type({
            grhc: t.literal(true),
            healthcarePlan: form2.string.requiredMaxLength(2)
          }),
          t.type({
            grhc: t.literal(false)
          })
        ])
      ])
    )
  })
}
