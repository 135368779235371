import { Task, ZIO } from '@mxt/zio'
import { Alert } from '@pulseops/common'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import { DetailService } from '../general-info/detail-service'
import { ContractId } from '../general-info/model/contract-id'
import { FormService } from '../general-info/form-service'
import { PremiumDecision } from './model/premium-decision'
import { ProposalStatusCode } from './model/proposal-status-code'
import { UwEmployeeDecision } from './model/uw-employee-decision'
import { LatestVerificationDecision } from './model/verification-decision-history'
import { NbuwApi } from './nbuw-api'
import { NbuwState } from './nbuw-state'
import * as O from 'fp-ts/Option'
import * as NEA from 'fp-ts/NonEmptyArray'

export namespace NbuwService {
  export const forContract = (contractId: ContractId) => {
    const state = NbuwState.forContract(contractId)
    const detailService = DetailService.contract(contractId)

    const getVerificationDecision = pipe(
      NbuwApi.getVerificationDecision(contractId),
      ZIO.map((res) => res.data),
      NbuwState.cache(state.verificationDecision.lens)
    )

    const addVerificationDecision = (body: NbuwApi.AddVerificationDecisionBody) =>
      pipe(
        NbuwApi.addVerificationDecision(body),
        ZIO.flatMap(() =>
          pipe(
            NbuwApi.getVerificationDecision(contractId),
            ZIO.flatMap((res) => state.verificationDecision.set(res.data))
          )
        ),
        ZIO.tap(() => Alert.alertM(i18next.t(`business:MS000020`)))
      )

    const getLatestVerificationDecision = pipe(getVerificationDecision, ZIO.map(LatestVerificationDecision))

    const getUwDecision = ({ page, size }: { page: number; size: number }) => {
      const service = state.uwDecision.size(size)
      const pageLens = service.pageLens(page)
      return pipe(
        NbuwApi.getUwDecision({ ...contractId, page, size }),
        ZIO.flatMap(service.updatePage),
        NbuwState.cache(pageLens)
      )
    }

    const getOverFclEmployeeList = ({ size, page }: { size: number; page: number }): Task<string[]> => {
      const service = state.employee.size(size)
      const pageLens = service.pageLens(page)

      return pipe(
        NbuwApi.getEmployeeOverFcl({ ...contractId, size, page, overFcl: FormService.overFclYes }),
        ZIO.flatMap(service.updatePage),
        NbuwState.cache(pageLens)
      )
    }

    const _getUwDecisionEmployees = ({
      page,
      size
    }: {
      page: number
      size: number
    }): Task<{
      data: UwEmployeeDecision[]
      total: number
    }> =>
      pipe(
        NbuwApi.getUwDecisionEmployees({
          ...contractId,
          page,
          size
        }),

        ZIO.flatMap((res) => ZIO.succeed(res))
      )

    const getUwDecisionEmployees = ({ page, size }: { page: number; size: number }) => {
      const service = state.employeeDecision.size(size)
      const pageLens = service.pageLens(page)
      return pipe(_getUwDecisionEmployees({ page, size }), ZIO.flatMap(service.updatePage), NbuwState.cache(pageLens))
    }

    const saveUwDecision = ({
      finalUwDecision,
      employees,
      reloadUwDecision,
      reloadUwEmployees,
      reloadListEmployee
    }: {
      finalUwDecision: string
      employees: UwEmployeeDecision[]
      reloadUwDecision: Task<void>
      reloadUwEmployees: Task<void>
      reloadListEmployee: Task<void>
    }) =>
      pipe(
        ZIO.zip(
          pipe(
            NbuwApi.addUwDecision({ ...contractId, finalUwDecision }),
            ZIO.flatMap(() => state.uwDecision.clear),
            ZIO.flatMap(() => state.employee.clear),
            ZIO.flatMap(() => reloadUwDecision),
            ZIO.flatMap(() => reloadListEmployee)
          ),
          A.isNonEmpty(employees)
            ? pipe(
                NbuwApi.addUwDecisionEmployees({ employeeUWList: employees }),
                ZIO.flatMap(() => state.employeeDecision.clear),
                ZIO.flatMap(() => state.employee.clear),
                ZIO.flatMap(() => reloadUwEmployees)
                // ZIO.flatMap(() => reloadListEmployee)
              )
            : ZIO.unit
        ),
        ZIO.tap(() => Alert.alertM(i18next.t(`business:MS000020`))),
        ZIO.asVoid
      )

    const savePremiumCheck = ({
      premiumDecisions,
      reloadDecision,
      totalPremiumCharge
    }: {
      premiumDecisions: PremiumDecision[]
      totalPremiumCharge: NbuwApi.AddPremiumChargeTotalBody['totalPremiumCharge']
      reloadDecision: Task<void>
    }) =>
      pipe(
        ZIO.zip(
          A.isNonEmpty(premiumDecisions)
            ? pipe(
                NbuwApi.addPremiumDecision({ premiumDecisions }),
                ZIO.flatMap(() => state.premiumDecision.clear),
                ZIO.flatMap(() => reloadDecision)
              )
            : ZIO.unit,
          A.isNonEmpty(totalPremiumCharge.premiumCharges)
            ? pipe(
                state.premiumTotal.getId,
                ZIO.flatMap((id) =>
                  NbuwApi.addPremiumChargeTotal({
                    totalPremiumCharge: { ...totalPremiumCharge, id }
                  })
                ),
                ZIO.flatMap((res) => state.premiumTotal.set(O.some(res.totalPremiumCharge))),
                ZIO.asVoid
              )
            : ZIO.unit
        ),
        ZIO.tap(() => Alert.alertM(i18next.t(`business:MS000020`)))
      )

    const getPremiumDecision = ({ page, size }: { page: number; size: number }) => {
      const service = state.premiumDecision.size(size)
      const pageLens = service.pageLens(page)
      return pipe(
        NbuwApi.getPremiumDecision({ ...contractId, page, size }),
        ZIO.flatMap(service.updatePage),
        NbuwState.cache(pageLens)
      )
    }

    const getPremiumChargeTotal = pipe(
      NbuwApi.getPremiumChargeTotal(contractId),
      ZIO.map((res) => O.fromNullable(res.data)),
      NbuwState.cache(state.premiumTotal.lens)
    )

    const getStatusContract = ({ page, size }: { page: number; size: number }) => {
      const service = state.statusContract.size(size)
      const pageLens = service.pageLens(page)
      return pipe(
        NbuwApi.getStatusContract({ ...contractId, page, size }),
        ZIO.flatMap(service.updatePage),
        NbuwState.cache(pageLens)
      )
    }

    const addStatusContract = (status: ProposalStatusCode, reload: Task<void>) =>
      pipe(
        NbuwApi.addStatusContract({
          statusContractInfo: {
            ...contractId,
            status
          }
        }),
        ZIO.flatMap(() => state.statusContract.clear),
        ZIO.flatMap(() => reload),
        ZIO.flatMap(() => detailService.refreshInfo),
        ZIO.tap(() => Alert.alertM(i18next.t(`business:MS000020`)))
      )

    const updateAfterRelease = pipe(
      NbuwApi.updateAfterRelease({ ...contractId }),
      ZIO.tap(() => Alert.alertM(i18next.t(`business:MS000029`)))
    )

    const uploadHandbook = (file: File) => pipe(NbuwApi.uploadHandbook({ ...contractId, file }))

    const checkContract = pipe(
      NbuwApi.checkContract(contractId),
      ZIO.map(({ errorCodes }) => pipe(errorCodes, O.fromNullable, O.chain(NEA.fromArray))),
      ZIO.tap(
        O.fold(
          () => ZIO.unit,
          (errorCodes) => pipe(errorCodes.map((err) => i18next.t(`business:${err.code}`)).join('\n'), Alert.alertM)
        )
      )
    )

    const checkContractAfterRelease = pipe(
      NbuwApi.checkContractAfterRelease(contractId),
      ZIO.map(({ errorCodes }) => pipe(errorCodes, O.fromNullable, O.chain(NEA.fromArray))),
      ZIO.tap(
        O.fold(
          () => ZIO.unit,
          (errorCodes) => pipe(errorCodes.map((err) => i18next.t(`business:${err.code}`)).join('\n'), Alert.alertM)
        )
      )
    )

    const issuePolicy = NbuwApi.contractRelease(contractId)

    const getEmployeeUwList = ({ size, page }: { size: number; page: number }): Task<string[]> => {
      const service = state.employeeUw.size(size)
      const pageLens = service.pageLens(page)

      return pipe(
        NbuwApi.getEmployeeUwDecision({ ...contractId, size, page }),
        ZIO.flatMap(service.updatePage),
        NbuwState.cache(pageLens)
      )
    }

    const getEcardList = (params: { page: number; size: number; fromDate: Date; toDate: Date }) => {
      const service = state.ecard.size(params.size)
      const pageLens = service.pageLens(params.page)

      return pipe(NbuwApi.getReportEcard(params), ZIO.flatMap(service.updatePage), NbuwState.cache(pageLens))
    }

    const hdSearchECard = (params: {
      page: number
      size: number
      fromDate: string | null
      toDate: string | null
      masterContractNo: string
      policyYear: number
    }) => {
      return pipe(NbuwApi.getSearchECard(params))
    }

    const exportEcard = (params: {
      fromDate: string | null
      toDate: string | null
      masterContractNo: string
      policyYear: number
    }) => {
      return pipe(NbuwApi.exportEcard(params))
    }

    return {
      state,
      getVerificationDecision,
      getLatestVerificationDecision,
      addVerificationDecision,
      getUwDecision,
      getOverFclEmployeeList,
      getUwDecisionEmployees,
      saveUwDecision,
      savePremiumCheck,
      getPremiumDecision,
      getPremiumChargeTotal,
      getStatusContract,
      addStatusContract,
      uploadHandbook,
      checkContract,
      issuePolicy,
      getEmployeeUwList,
      getEcardList,
      updateAfterRelease,
      checkContractAfterRelease,
      hdSearchECard,
      exportEcard
    }
  }
}
