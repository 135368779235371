import { SearchFields } from "../../ob-service-inquiry"
import { SelectOption } from "@pulseops/common"

export namespace OBTaskFilterForm {
    export interface FilterForm {
        policyNumber: string | undefined
        caseId: string | undefined
        callingProgram: SelectOption | undefined
        clientNumber: string | undefined
        clientName: string | undefined
        vipRank: SelectOption | undefined
        agency: SelectOption | undefined
        aging: string | undefined
        totalAgingDays:  string | undefined
        numberOfAssignment: string | undefined
        currentAssignee: SelectOption | undefined
        leader: SelectOption | undefined
        caseStatus: SelectOption | undefined
        duplicatePO: SelectOption | undefined
        createdFromDate: Date | undefined | null
        createdToDate: Date | undefined | null
        appointmentFromDate: Date | undefined | null
        appointmentToDate: Date | undefined | null
        lastAssignee: SelectOption | undefined
        submitFromDate: Date | undefined | null
        submitToDate: Date | undefined | null
        firstIssueFromDate: Date | undefined | null
        firstIssueToDate: Date | undefined | null
        lastIssueFromDate: Date | undefined | null
        lastIssueToDate: Date | undefined | null
    }

    export type TypeFilterField = 'policyNumber' | 'caseId' | 'callingProgram' | 'clientNumber' | 'clientName' | 'vipRank' | 'agency' 
    | 'aging' | 'numberOfAssignment' | 'currentAssignee' | 'leader' | 'caseStatus' | 'duplicatePO' | 'createdFromDate' | 'createdToDate' 
    | 'appointmentFromDate' | 'appointmentToDate'
    export interface FieldSearchDateFromTo {
        title: string,
        type: 'DATE_FROM_TO',
        formNameDateFrom: string,
        formNameDateTo: string,
        rules?: Object
        required?: boolean
    }

    export type FieldSearchFilter = SearchFields.FieldSearch | FieldSearchDateFromTo
}