import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import * as E from 'fp-ts/Either'
import moment from 'moment'

export const DateFromNumber = new t.Type<Date, string, unknown>(
  'DateFromNumber',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    pipe(
      t.number.validate(u, c),
      E.chain((n) => {
        const date = moment(n.toString(), 'YYYYMMDD', true)
        return date.isValid() ? t.success(date.toDate()) : t.failure(u, c)
      })
    ),
  (a) => moment(a).format('YYYYMMDD')
)
