import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Customer } from '../Client'
import { MoreData } from '../MoreData'

export interface ZaloOther {
  tag: TransactionType.ZALO_OTHERS
  clientName: string

  note: string
}

export const ZaloOther = (detail: api.ZaloOther, client: Customer, sql: MoreData.PoNameFromSql): ZaloOther => {
  return {
    tag: TransactionType.ZALO_OTHERS,
    clientName: sql.body?.poName || client.body.name,

    note: detail.payload.body.message || ''
  }
}
