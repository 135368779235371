import { AppContext, assets, ErrorHandling, PanelProp, Permission, RBAC, SearchPanel } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { AccountingStackParamList } from './AccountingStackParamList'

type Props = StackScreenProps<AccountingStackParamList, 'AccountingList'>

export const AccountingListScreen = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const ht = useTranslation('menu').t
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: ht('Accounting'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const data: PanelProp = {
    itemsSearch: [
      {
        id: Permission['ViewCreatenote'],
        name: t('Accounting:CreateNote'),
        navigator: () => {
          navigation.navigate('AccountingCreateNoteScreen')
        }
      },
      {
        id: Permission['ViewUpdatenote'],
        name: t('Accounting:UpdateNote'),
        navigator: () => {
          navigation.navigate('AccountingUpdateNoteScreen', { pvNumber: undefined })
        }
      }
    ].filter((_) => roles.includes(_.id)),
    itemsGroup: [
      {
        id: 'AccountingManagement',
        name: t('Accounting:AccountingManagement'),
        icon: () => <assets.ReportEform />,
        children: [
          {
            id: Permission['ViewCreatenote'],
            name: t('Accounting:CreateNote'),
            navigator: () => {
              navigation.navigate('AccountingCreateNoteScreen')
            }
          },
          {
            id: Permission['ViewUpdatenote'],
            name: t('Accounting:UpdateNote'),
            navigator: () => {
              navigation.navigate('AccountingUpdateNoteScreen', { pvNumber: undefined })
            }
          }
        ].filter((_) => roles.includes(_.id))
      }
    ]
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>{data && <SearchPanel data={data} />}</ScrollView>
    </SafeAreaView>
  )
}
