import { Container, PulseOpsFormat, TaskDetailService } from '@pulseops/common'
import { OBDataSource, OBGeneralTable, OBGeneralTableColum } from '@pulseops/outbound'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { ZIO } from '@mxt/zio'
import { IDCDetailContext } from '../IDCDetailContext'
import { useIsFocused } from '@react-navigation/native'
import { useLoading } from '@mxt/zio-react'

type Pagination = {
  pageNum: number
  pageSize: number
  total: number
}

type ErrLogModel = {
  transactionDate: string
  system: string
  errorCode: string
  desp: string
}

export const IDCErrorLog = () => {
  const { t } = useTranslation()
  const { processInstanceID } = React.useContext(IDCDetailContext)
  const [orderBy, setOrderBy] = React.useState<string>('startTime')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  // const [pageSize, setPageSize] = React.useState<number>(10)
  const [rows, setRows] = React.useState<ErrLogModel[]>([])
  const [{ pageNum, pageSize, total }, setPagination] = React.useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })
  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t('ErrorLog:transDate'),
      fieldName: 'transactionDate',
      disabled: true
    },
    {
      title: t('common:system'),
      fieldName: 'system',
      disabled: true
    },
    {
      title: t('common:errorCode'),
      fieldName: 'errorCode',
      disabled: true
    },
    {
      title: t('common:description'),
      fieldName: 'desp',
      disabled: true
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      loadData(pageNum, pageSize)
    }
    return () => {
      setRows([])
    }
  }, [isFocused])

  const loadData = (pageNum: number, pageSize: number) =>
    pipe(
      TaskDetailService.GetWFComment(
        processInstanceID,
        {
          pageSize: pageSize,
          pageNum: pageNum
        },
        {
          order: order,
          field: orderBy
        }
      ),
      ZIO.map((res) => {
        setPagination({
          pageSize: pageSize,
          pageNum: pageNum,
          total: res.total
        })
        return res.data
          ? res.data.map((x) => ({
            transactionDate: PulseOpsFormat.dateStrWF(x.createDate, 'DD/MM/YYYY'),
            system: x.systemName ?? '',
            errorCode: x.errorCode ?? '',
            desp: x.description ?? ''
          }))
          : []
      }),
      ZIO.tap((res) => {
        setRows(res)
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )



  const onPageChange = (pageNum: number, pageSize: number) => {
    loadData(pageNum, pageSize)
  }

  const onRowsPerPageChange = (pageNum: number, pageSize: number) => {
    loadData(pageNum, pageSize)
  }

  return (
    <Container loading={isLoading}>
      <View>
        <OBGeneralTable
          displayedColumns={displayedColumns}
          dataSource={rows as OBDataSource[]}
          isPagination
          totalRecord={total}
          pageNum={pageNum}
          pageSize={pageSize}
          onPageChange={(pageNum, pageSize) => onPageChange(pageNum, pageSize)}
          onRowsPerPageChange={(pageNum, pageSize) => {
            onRowsPerPageChange(pageNum, pageSize)
          }}
          setPageNum={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
          setPageSize={(pageSize) => setPagination((state) => ({ ...state, pageSize }))}
          setTotalRecord={(total) => setPagination((state) => ({ ...state, total }))}
        >
        </OBGeneralTable>
      </View>
    </Container>

  )
}