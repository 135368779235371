import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Pressable,
  ActivityIndicator
} from 'react-native'
import React from 'react'
import { IBCategoryTab, CategoryTabInfo, IBSearchFilter, IBGeneralTable } from '../../../common'
import {
  assets,
  ContractMapping,
  useTranslator,
  PremiumStatusMapping,
  CancelRiderService,
  AppContext,
  PulseOpsFormat
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { IBClientPolicyTasksDetail } from './ib-client-policy-tasks-detail'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import {
  IBService,
  PolicyDetail,
  PolicySearchItem,
  PolicyInquiryCountData,
  MainProductsData,
  MainProductsResponse
} from '../../../ib-service'
import _, { isEmpty } from 'lodash'
import moment from 'moment'
import { Grid } from '@material-ui/core'

interface PolicyInfoBoxProps {
  data: PolicySearchItem
}

const PolicyInfoBox = (props: PolicyInfoBoxProps) => {
  const { data } = props
  const { t, i18n } = useTranslation('Inbound')
  const ct = useTranslator('contract').t
  const ps = useTranslator('premiumStatus').t
  const handleNavPolicyDetail = () => {
    window.open(
      `${window.location.pathname}?clientNumber=${data?.poClientNumber}&tabIndex=1&poNumber=${data?.idNumber}&policyNumber=${data.policyNumber}&policyOwnerName=${data?.poName}&mainProductNameVi=${data?.mainProductNameVi}&mainProductNameEn=${data?.mainProductNameEn}&policyStatus=${data?.policyStatus}&premiumStatus=${data?.premiumStatus}&mainLAName=${data?.mainLifeAssuredName?.name}&mainProductCode=${data?.mainProductCode}`
    )
  }

  const handleNavPO = (data: string) => {
    const pathname = window.location.pathname
    const splitStr = pathname.split('/')
    const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
    window.open(`${url}/ib-dashboard?clientNumber=${data}`)
  }
  return (
    <View style={styles.policyInfoBox}>
      <View style={styles.boxHeader}>
        <View style={[styles.headerText, { marginBottom: 15 }]}>
          <Pressable onPress={handleNavPolicyDetail}>
            <Text style={styles.headerTextPolicyNumber}>{data.policyNumber}</Text>
          </Pressable>
          <View style={{ flexDirection: 'row' }}>
            {data?.roles
              ?.filter((x) => ['OW'].includes(x))
              .map((item) => (
                <View style={styles.headerTextBox}>
                  <Text style={{ color: '#ED1B2E' }}>{'PO'}</Text>
                </View>
              ))}
            {data?.roles
              ?.filter((x) => ['LF'].includes(x))
              .map((item) => (
                <View style={styles.headerTextBox}>
                  <Text style={{ color: '#ED1B2E' }}>{'LA'}</Text>
                </View>
              ))}
            {data?.roles
              ?.filter((x) => ['BN'].includes(x))
              .map((item) => (
                <View style={styles.headerTextBox}>
                  <Text style={{ color: '#ED1B2E' }}>{'BEN'}</Text>
                </View>
              ))}
          </View>
        </View>
        <View style={[styles.headerText, { marginBottom: 10 }]}>
          <Text style={[styles.headerTextTitle, { width: 150 }]}>{t('PolicyStatus')}</Text>
          <Text style={{ textAlign: 'right' }}>{ContractMapping.get(ct)(data.policyStatus as string) || '-'}</Text>
        </View>
        <View style={[styles.headerText]}>
          <Text style={[styles.headerTextTitle, { width: 150 }]}>{t('PremiumStatus')}</Text>
          <Text style={{ textAlign: 'right' }}>
            {PremiumStatusMapping.get(ps)(data.premiumStatus as string) || '-'}
          </Text>
        </View>
      </View>
      <View style={styles.boxContent}>
        <View style={styles.contentTitle}>
          <Text style={styles.contentTitleText}>
            {i18n.language === 'en' ? data.mainProductNameEn : data.mainProductNameVi}
          </Text>
        </View>
        <View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('OwnerNameGV')}</Text>
            <Pressable
              onPress={() => {
                handleNavPO(data.poClientNumber as string)
              }}
              style={{ flex: 1, marginLeft: 10 }}
            >
              <Text style={{ textDecorationLine: 'underline', textAlign: 'right' }}>{data.poName || '-'}</Text>
            </Pressable>
          </View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('MainLifeAssuredGV')}</Text>
            <Pressable
              onPress={() => {
                handleNavPO(data.mainLifeAssuredClientNumber as string)
              }}
              style={{ flex: 1, marginLeft: 10 }}
            >
              <Text style={{ textDecorationLine: 'underline', textAlign: 'right' }}>{data.mainLAName || '-'}</Text>
            </Pressable>
          </View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('PaidToDate')}</Text>
            <Text>{!_.isEmpty(data.paidToDateBasic) ? moment(data.paidToDateBasic).format('DD/MM/YYYY') : '-'}</Text>
          </View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('APL')}</Text>
            <Text>{PulseOpsFormat.thousandSepartor(data.aplAmount) || '-'}</Text>
          </View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('Agent')}</Text>
            <Text>{data.servicingAgent?.agentName?.name || '-'}</Text>
          </View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('AgentCode')}</Text>
            <Text>{data.servicingAgent?.agentCode || '-'}</Text>
          </View>
          <View style={[styles.headerText, { marginBottom: 15 }]}>
            <Text style={styles.headerTextTitle}>{t('ProposalNoGV')}</Text>
            <Text>{data.proposalNo || '-'}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

interface Props {
  dataDirection?: PolicyDetail
  clientNumber?: string
  tabIndex?: number
  listPolicyCount: PolicyInquiryCountData[]
}

export const IBClientPolicyTasks = ({ dataDirection, clientNumber, tabIndex, listPolicyCount }: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const ct = useTranslator('contract').t
  const ps = useTranslator('premiumStatus').t
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(tabIndex ? tabIndex : 0)
  const [categoryTabIndex2, setCategoryTabIndex2] = React.useState<number>(0)
  const [isGridView, setIsGridView] = React.useState(false)
  const [loading, bindLoading] = useLoading(false)
  const [policySearch, setPolicySearch] = React.useState<string>('')
  const [dataFilter, setDataFilter] = React.useState<PolicySearchItem[]>([])
  const [policy, setPolicy] = React.useState<PolicyDetail | undefined>(
    dataDirection?.policyNumber ? dataDirection : undefined
  )
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageSizeGridView, setPageSizeGridView] = React.useState<number>(6)
  const [taskCount, setTaskCount] = React.useState<number>(0)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [loadingFirst, setLoadingFirst] = React.useState<boolean>(false)
  const [isMainProducts, setMainProducts] = React.useState<MainProductsResponse>()

  const dataTable = [
    {
      label: t('common:PolicyNumber'),
      field: 'policyNumber',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              const object = dataFilter.find((i) => i.policyNumber === value)
              window.open(
                `${window.location.pathname}?clientNumber=${object?.poClientNumber}&tabIndex=1&poNumber=${object?.idNumber}&policyNumber=${value}&policyOwnerName=${object?.poName}&mainProductNameVi=${object?.mainProductNameVi}&mainProductNameEn=${object?.mainProductNameEn}&policyStatus=${object?.policyStatus}&premiumStatus=${object?.premiumStatus}&mainLAName=${object?.mainLifeAssuredName?.name}&mainProductCode=${object?.mainProductCode}`
              )
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('ProposalNum'),
      field: 'proposalNo',
      render: (value: string) => <Text>{value}</Text>
    },
    {
      label: t('Product'),
      field: i18n.language === 'en' ? 'mainProductNameEn' : 'mainProductNameVi'
    },
    {
      label: t('PolicyStatus'),
      field: 'policyStatus',
      render: (value: string) => {
        return ContractMapping.get(ct)(value)
      }
    },
    {
      label: t('PremiumStatus'),
      field: 'premiumStatus',
      render: (value: string) => {
        return PremiumStatusMapping.get(ps)(value)
      }
    },
    {
      label: 'PO',
      field: 'isPO',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: 'LA',
      field: 'isLA',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: 'BEN',
      field: 'isBEN',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OwnerName'),
      field: 'poName',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              const object = dataFilter.find((i) => i.poName === value)
              const pathname = window.location.pathname
              const splitStr = pathname.split('/')
              const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
              window.open(`${url}/ib-dashboard?clientNumber=${object?.poClientNumber}`)
            }}
            style={{ flexDirection: 'row' }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                textDecorationLine: 'underline',
                flex: 1
              }}
              numberOfLines={1}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('MainLifeAssured'),
      field: 'mainLAName',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              const object = dataFilter.find((i) => i.mainLAName === value)
              const pathname = window.location.pathname
              const splitStr = pathname.split('/')
              const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
              window.open(`${url}/ib-dashboard?clientNumber=${object?.mainLifeAssuredClientNumber}`)
            }}
            style={{ flexDirection: 'row' }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                textDecorationLine: 'underline',
                flex: 1
              }}
              numberOfLines={1}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('PaidToDate'),
      field: 'paidToDateBasic',
      format: 'date'
    },
    {
      label: t('APL'),
      field: 'aplAmount',
      format: 'money'
    }
  ]

  React.useEffect(() => {
    if (loadingFirst) {
      if (isGridView) {
        handlePolicySearch(0, pageSizeGridView)
      } else {
        setPageSizeGridView(6)
        handlePolicySearch(page, pageSize)
      }
    }
  }, [isGridView, pageSizeGridView])

  React.useEffect(() => {
    if (clientNumber && !loadingFirst && !_.isEmpty(listPolicyCount)) {
      setLoadingFirst(true)
      handlePolicySearch(page, pageSize)
    }
  }, [clientNumber])

  React.useEffect(() => {
    if (loadingFirst) {
      handlePolicySearch(page, pageSize)
    }
  }, [page, pageSize])

  React.useEffect(() => {
    if (clientNumber && loadingFirst) {
      if (isGridView) {
        handlePolicySearch(0, pageSizeGridView)
      } else {
        if (page !== 0) {
          setPage(0)
        } else {
          handlePolicySearch(0, pageSize)
        }
      }
    }
  }, [categoryTabIndex2, categoryTabIndex])

  const checkRulePaidToDate = (
    policyStatus: string,
    premCessDate: string,
    nextPaidPremium: number,
    paidToDate: string
  ) => {
    const checkDate = moment(premCessDate, 'DD/MM/YYYY').diff(moment(paidToDate, 'DD/MM/YYYY'))
    if (policyStatus === 'IF' && checkDate > 0 && nextPaidPremium > 0) {
      return true
    }
    if (policyStatus === 'LA' && checkDate) {
      return true
    }
    return false
  }

  const handlePolicySearch = (page: number, pageSize: number, searchByFilter?: boolean) => {
    if (!policy) {
      pipe(
        ZIO.zipPar(
          IBService.searchRelatedPolicy({
            pageIndex: page,
            itemsPerPage: pageSize,
            orders: ['policyNo'],
            sort: 'DESC',
            role: getRoleFilter(categoryTabIndex2),
            lstPolicyStatusFilter: getPolicyStatusFilter(categoryTabIndex),
            clientNumber: clientNumber as string,
            ...(policySearch.length < 9 && { policyNo: policySearch }),
            ...(policySearch.length >= 9 && { proposalNumber: policySearch })
          }),
          !isEmpty(isMainProducts?.data) ? ZIO.succeed(isMainProducts) : IBService.getListMainProductPolicyInquiry()
        ),
        ZIO.map(([res, mainProducts]) => {
          setMainProducts(mainProducts)
          setDataFilter(
            res.records.map((item) => {
              const mainProduct = (isMainProducts || mainProducts)?.data.find((x) => x.code === item.mainProduct?.code)
              return {
                ...item,
                isPO: item.roles?.includes('OW'),
                isLA: item.roles?.includes('LF'),
                isBEN: item.roles?.includes('BN'),
                mainLAName: item.mainLifeAssuredName?.name,
                poName: item.policyOwnerName?.name,
                mainProductNameVi: mainProduct?.nameVn,
                mainProductNameEn: mainProduct?.nameEn,
                paidToDateBasic: checkRulePaidToDate(
                  item.policyStatus as string,
                  item.premCessDate as string,
                  item.nextPaidPremium as number,
                  item.paidToDateBasic as string
                )
                  ? _.isEmpty(item.paidToDateBasic) || _.isNil(item.paidToDateBasic)
                    ? ''
                    : moment(item.paidToDateBasic, 'DD/MM/YYYY').format('YYYYMMDD')
                  : '',
                mainProductCode: item.mainProduct?.code,
                aplAmount: item.aplAmount || 0
              }
            })
          )
          setTaskCount(res.paging.total)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const getPolicyStatusFilter = (index: number) => {
    switch (index) {
      case 0:
        return [
          'PS',
          'IF',
          'PU',
          'LA',
          'FZ',
          'MA',
          'DH',
          'TD',
          'RD',
          'DE',
          'PO',
          'WD',
          'DC',
          'CF',
          'SU',
          'TR',
          'EX',
          'AP',
          'CP',
          'HP',
          'MP',
          'PW',
          'TM',
          'UW',
          'VO',
          'VR'
        ]
      case 1:
        return ['PS']
      case 2:
        return ['IF', 'PU']
      case 3:
        return ['LA', 'FZ']
      case 4:
        return ['MA']
      case 5:
        return ['DH', 'TD', 'RD', 'DE']
      case 6:
        return ['PO', 'WD', 'DC', 'CF', 'SU', 'TR', 'EX', 'AP', 'CP', 'HP', 'MP', 'PW', 'TM', 'UW', 'VO', 'VR']
      default:
        return []
    }
  }

  const getRoleFilter = (index: number) => {
    switch (index) {
      case 1:
        return 'OW'
      case 2:
        return 'LF'
      case 3:
        return 'BN'
      default:
        return ''
    }
  }

  const getPolicyStatusCountFilter = (index: number) => {
    switch (index) {
      case 1:
        return listPolicyCount?.find((x) => x.code === 'Proposal')
      case 2:
        return listPolicyCount?.find((x) => x.code === 'InforcePaidUp')
      case 3:
        return listPolicyCount?.find((x) => x.code === 'LapsedFrozen')
      case 4:
        return listPolicyCount?.find((x) => x.code === 'Maturity')
      case 5:
        return listPolicyCount?.find((x) => x.code === 'Claim')
      case 6:
        return listPolicyCount?.find((x) => x.code === 'Others')
      default:
        return listPolicyCount?.find((x) => x.code === 'Total')
    }
  }

  const categoryTabList: Array<CategoryTabInfo> = [
    {
      label: t('All'),
      key: '1',
      count: listPolicyCount.find((x) => x.code === 'Total')?.value ?? 0
    },
    {
      label: t('Proposal'),
      key: '2',
      count: listPolicyCount.find((x) => x.code === 'Proposal')?.value ?? 0
    },
    {
      label: t('Inforce'),
      key: '3',
      count: listPolicyCount.find((x) => x.code === 'InforcePaidUp')?.value ?? 0
    },
    {
      label: t('Lapsed'),
      key: '4',
      count: listPolicyCount.find((x) => x.code === 'LapsedFrozen')?.value ?? 0
    },
    {
      label: t('Maturity'),
      key: '5',
      count: listPolicyCount.find((x) => x.code === 'Maturity')?.value ?? 0
    },
    {
      label: t('DeathClaimTPD'),
      key: '6',
      count: listPolicyCount.find((x) => x.code === 'Claim')?.value ?? 0
    },
    {
      label: t('Others'),
      key: '7',
      count: listPolicyCount.find((x) => x.code === 'Others')?.value ?? 0
    }
  ]

  const categoryTabList2: Array<CategoryTabInfo> = [
    {
      label: t('All'),
      key: '1',
      count: getPolicyStatusCountFilter(categoryTabIndex)?.childs?.find((x) => x.code === 'Total')?.value ?? 0
    },
    {
      label: t('PO'),
      key: '2',
      count: getPolicyStatusCountFilter(categoryTabIndex)?.childs?.find((x) => x.code === 'OW')?.value ?? 0
    },
    {
      label: t('LA'),
      key: '3',
      count: getPolicyStatusCountFilter(categoryTabIndex)?.childs?.find((x) => x.code === 'LF')?.value ?? 0
    },
    {
      label: t('BEN'),
      key: '4',
      count: getPolicyStatusCountFilter(categoryTabIndex)?.childs?.find((x) => x.code === 'BN')?.value ?? 0
    }
  ]

  return (
    <>
      {policy ? (
        !_.isEmpty(listPolicyCount) ? (
          <IBClientPolicyTasksDetail data={policy} clientNumber={clientNumber ?? ''} />
        ) : (
          <></>
        )
      ) : (
        <View style={styles.container}>
          <IBCategoryTab
            dataSource={categoryTabList}
            onChangeTab={setCategoryTabIndex}
            activeTabIndex={categoryTabIndex}
          />
          <View style={styles.tabListContainer}>
            <View style={styles.tabListGrid}>
              <TouchableOpacity onPress={() => setIsGridView((prevState) => !prevState)}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {isGridView ? <assets.TableViewIcon /> : <assets.GridViewIcon />}
                  <Text style={{ marginHorizontal: 10, color: '#ED1B2E', fontWeight: '700', fontSize: 16 }}>
                    {!isGridView ? t('TaskManagement:GridView') : t('TaskManagement:ListView')}
                  </Text>
                </View>
              </TouchableOpacity>
              <View style={styles.dividerCol}></View>
              <ScrollView horizontal showsVerticalScrollIndicator={false}>
                <IBCategoryTab
                  dataSource={categoryTabList2}
                  onChangeTab={setCategoryTabIndex2}
                  activeTabIndex={categoryTabIndex2}
                />
              </ScrollView>
            </View>
            <View style={{ marginTop: 10, width: '30%' }}>
              <IBSearchFilter
                onPressSearch={() => {
                  if (isGridView) {
                    handlePolicySearch(0, pageSizeGridView)
                  } else {
                    handlePolicySearch(0, pageSize)
                  }
                }}
                value={policySearch}
                onChangeSearchText={setPolicySearch}
                placeHolder={t('PolicyNumber')}
              />
            </View>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.table}>
              {!isGridView ? (
                <IBGeneralTable
                  dataTable={dataTable}
                  data={dataFilter}
                  loading={loading}
                  paging={{
                    page: page,
                    pageSize: pageSize,
                    taskCount: taskCount,
                    setPage: setPage,
                    setPageSize: setPageSize
                  }}
                  paginationStyle={{ marginRight: 60 }}
                />
              ) : (
                <View style={styles.policyInfoBoxContainer}>
                  <>
                    <Grid container spacing={2}>
                      {dataFilter.map((item, index) => (
                        <Grid
                          item
                          xs={4}
                          lg={4}
                          md={4}
                          xl={4}
                          style={{ display: 'flex', justifyContent: 'center' }}
                          key={index}
                        >
                          <PolicyInfoBox data={item} />
                        </Grid>
                      ))}
                    </Grid>
                    {dataFilter.length === 0 ? (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 20,
                          marginTop: 10
                        }}
                      >
                        <Text>{t('common:Nodata')}</Text>
                      </View>
                    ) : dataFilter.length === taskCount ? (
                      <></>
                    ) : (
                      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        {loading ? (
                          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <ActivityIndicator size="large" color="red" />
                          </View>
                        ) : (
                          <Pressable
                            onPress={() => {
                              setPageSizeGridView((pageSize) => pageSize + 6)
                            }}
                          >
                            <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
                              <Text style={{ color: '#58647A', marginRight: 5 }}>{t('ShowMorePolicy')}</Text>
                              <assets.IBShowMore />
                            </View>
                          </Pressable>
                        )}
                      </View>
                    )}
                  </>
                </View>
              )}
            </View>
          </ScrollView>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  tabListContainer: {
    flexDirection: 'row',
    marginHorizontal: 5,
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  tabListGrid: {
    marginTop: 10,
    marginRight: 15,
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    width: '70%'
  },
  dividerCol: {
    width: 2,
    height: 28,
    backgroundColor: '#D3DCE6',
    marginRight: 10
  },
  table: {
    marginTop: 15
  },
  policyInfoBox: {
    width: '100%',
    // height: 390,
    backgroundColor: '#FFFFFF',
    margin: 10,
    borderWidth: 1,
    borderColor: '#CCC',
    borderRadius: 10
  },
  policyInfoBoxContainer: {
    // flexDirection: 'row',
  },
  boxHeader: {
    backgroundColor: '#EEE',
    paddingVertical: 20,
    paddingHorizontal: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  boxContent: {
    // height: 250,
    backgroundColor: '#FFFFFF',
    padding: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10
  },
  headerText: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerTextPolicyNumber: {
    color: '#58647A',
    fontSize: 28,
    textDecorationLine: 'underline',
    fontWeight: '700'
  },
  headerTextBox: {
    width: 40,
    justifyContent: 'center',
    textAlign: 'center',
    borderWidth: 1,
    borderColor: '#ED1B2E',
    marginRight: 5,
    borderRadius: 5
  },
  headerTextTitle: {
    fontSize: 14,
    fontWeight: '700',
    color: '#58647A',
    width: 110
  },
  contentTitle: {
    marginTop: 5,
    marginBottom: 15
  },
  contentTitleText: {
    fontWeight: '700',
    color: '#ED1B2E',
    fontSize: 16
  }
})
