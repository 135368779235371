// import { BeneficiaryDesignationDetail, BeneficiaryToSubmit } from './../model/BeneficiaryDesignation'
import * as t from 'io-ts'
import { ZIO, Task } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import * as A from 'fp-ts/Array'
// import { RequestAuthFormDataSubmit } from 'packages/pulse-ops/core/src/entity'
// import { BeneficiaryDesignationData } from '../model/BeneficiaryDesignation'
import { POApi } from '../POApi'
import { pipe } from 'fp-ts/lib/function'
import { SubmissionService } from './SubmissionService'
import { BeneficiaryDesignationData, RequestAuthFormDataSubmit } from './model'
import * as O from 'fp-ts/lib/Option'

export interface BenDataAccessCheck {
  contractStatus: string | null
}

export namespace BeneficiaryDesignationService {
  export const getDetail = (policyNum: string): Task<BeneficiaryDesignationData.BeneficiaryDesignationDetail[]> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/beneficiary`)(
        t.type({
          body: t.array(
            t.type({
              customer: t.type({
                clientId: Maybe(t.string)
              }),
              relationshipType: Maybe(t.string),
              percentage: Maybe(t.number)
            })
          )
        })
      ),
      ZIO.flatMap((details): Task<BeneficiaryDesignationData.BeneficiaryDesignationDetail[]> => {
        const a = pipe(
          details.body,
          A.map((d) =>
            pipe(
              POApi.get(`wf-api/customer/${d.customer.clientId}`)(
                t.type({
                  body: t.type({
                    firstName: Maybe(t.string),
                    nationality: Maybe(t.string),
                    sex: Maybe(t.string),
                    dob: Maybe(t.string),
                    surName: Maybe(t.string),
                    email: Maybe(t.string),
                    mobilePhone: Maybe(t.string),
                    countryCode: Maybe(t.string),
                    occupation: Maybe(t.string),
                    location: Maybe(t.string),
                    externalIds: Maybe(
                      t.type({
                        SOE: Maybe(t.string),
                        SOE_VALUE: Maybe(t.string)
                      })
                    ),
                    addressDetails: Maybe(
                      t.type({
                        PRIMARY: t.type({
                          line1: Maybe(t.string)
                        })
                      })
                    ),
                    attributesExt: t.type({
                      MOBILE_CODE: t.string,
                      FOREIGN_COUNTRY: Maybe(t.string),
                      FOREIGN_STREET: Maybe(t.string),
                      NATIONALITY_2: Maybe(t.string)
                    })
                  })
                })
              ),
              ZIO.map(
                (cus): BeneficiaryDesignationData.BeneficiaryDesignationDetail => ({
                  idCardType: cus.body.externalIds?.SOE ?? '',
                  idNumber: cus.body.externalIds?.SOE_VALUE ?? '',
                  issueAt: '',
                  relationWithOwner: d.relationshipType ?? '', // 1
                  givenName: cus.body.surName ?? '', //1
                  firstName: cus.body.firstName ?? '', //1
                  dob: cus.body.dob ?? '', //1
                  gender: cus.body.sex ?? '', //1
                  nationality: cus.body.nationality ?? '', //1
                  telCode: cus.body.attributesExt.MOBILE_CODE,
                  mobileNumber: cus.body.mobilePhone ?? '',
                  email: cus.body.email ?? '', //1
                  percentage: d.percentage ?? 0,
                  clientID: d.customer.clientId ?? '', //1,
                  occupation: cus.body.occupation ?? '',
                  city: cus.body.location?.slice(0, 2) ?? '',
                  district: cus.body.location?.slice(2, 4) ?? '',
                  ward: cus.body.location ?? '',
                  address: cus.body.addressDetails?.PRIMARY.line1 ?? '',
                  foreignAddress: cus.body.attributesExt.FOREIGN_STREET ?? '',
                  foreignCountry: cus.body.attributesExt.FOREIGN_COUNTRY ?? '',
                  nationality2: cus.body.attributesExt.NATIONALITY_2 ?? ''
                })
              )
            )
          ),
          ZIO.sequence
        )
        return a
      })
    )

  export const getDataCheckAccess = (policyNum: string) =>
    pipe(
      SubmissionService.getPolicies([
        {
          policyNo: policyNum
        }
      ]),
      ZIO.map((policies): BenDataAccessCheck => {
        return {
          contractStatus: policies[0].contractStatus
        }
      })
    )

  export const submit =
    (
      // detail : Array<BeneficiaryDesignationData.BeneficiaryDesignationDetail>,
      policyNum: string,
      data: Array<BeneficiaryDesignationData.BeneficiaryToSubmit>,
      documents: BeneficiaryDesignationData.BeneficiaryDocumentFile[]
    ) =>
      (requestauth: RequestAuthFormDataSubmit) =>
        SubmissionService.submitPut(t.unknown)(
          `wf-api/policy/${policyNum}/beneficiary`,
          { body: data },
          // {body: data.map((ben) => ({
          //     percentage: ben.percentage ?? 0,
          //     relationshipType: ben.relationWithOwner ?? '',
          //     customer: {
          //       firstName: ben.firstName ?? '',
          //       surName: ben.givenName ?? '',
          //       dob: ben.dob ?? '',
          //       sex: ben.gender,
          //       nationality: ben.nationality ?? '',
          //       clientId: ben.clientID ?? '',
          //       externalIds: {
          //         SOE: ben.idCardType ?? '',
          //         SOE_VALUE: ben.idNumber ?? '',
          //         ISSUED_BY: ben.issueAt ?? ''
          //       },
          //       contactDetails: {
          //         EMAIL: {
          //           channel: '',
          //           value: ben.email ?? '' ,
          //         },
          //         PHONE: {
          //           countryCode: ben.telCode ? ben.telCode : '',
          //           channel: '',
          //           value: ben.mobileNumber ? `${ben.mobileNumber}` : ''
          //         }
          //       }
          //     }
          policyNum,
          requestauth,
          documents
        )

  export const getSeaBankMessgeList = (policyNumber: string, clientNumber: string) => pipe(
    POApi.get(`wf-api/table/t88-sea-bank-checking-message/get-all`)(t.type({
      body: t.array(t.type({
        policyNumber: t.string,
        clientNumber: t.string,
        message1: t.string,
        message2: t.string,
        remark: t.string
      }))
    })),
    ZIO.map((responseData) => {
      const warningMsg = pipe(
        O.fromNullable(responseData.body.find(x => x.policyNumber === policyNumber)),
        O.fold(
          () => '',
          (messageItem) => {
            if (messageItem.clientNumber === clientNumber) {
              return messageItem.message1
            } else {
              return messageItem.message2
            }
          }
        )
      )
      return warningMsg
    })
  )
}
