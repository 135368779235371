import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import { Throwable, ZIO } from '@mxt/zio'
import * as O from 'fp-ts/Option'
import * as Js from 'fp-ts/Json'
import { Ref, RefEnv, ZRef } from '@mxt/zio/stream'
import { flow } from 'fp-ts/function'

export namespace RefSessionStorage {
  export const make = <C extends t.Mixed>(codec: C, value: t.TypeOf<C>): Ref<Throwable, t.TypeOf<C>> => {
    const key = codec.name

    const env: RefEnv<Throwable, t.TypeOf<C>> = {
      get: pipe(
        ZIO.effect(() => sessionStorage.getItem(key)),
        ZIO.map(
          flow(
            O.fromNullable,
            O.chain((str) => pipe(Js.parse(str), O.fromEither)),
            O.chain(flow(codec.decode, O.fromEither)),
            O.getOrElse(() => value)
          )
        )
      ),
      set: (a) =>
        pipe(
          ZIO.effect(() => codec.encode(a)),
          ZIO.flatMap((json) =>
            pipe(
              Js.stringify(json),
              ZIO.fromEither,
              ZIO.mapError((err) => Throwable('stringify error', err))
            )
          ),
          ZIO.flatMap((str) =>
            ZIO.effect(() => {
              sessionStorage.setItem(key, str)
            })
          )
        )
    }

    return ZRef.make(env)
  }
}
