import { MaybeUndefined, Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { PremiumDecisionCodeC } from './premium-decision-code'

export const PremiumDecisionC = t.type({
  id: MaybeUndefined(t.string),
  masterContractNo: t.string,
  policyYear: t.number,
  premiumDebitNote: t.string,
  decision: t.boolean,
  comment: t.string,
  status: PremiumDecisionCodeC,
  invoiceAmount: t.number,
  releaseDate: DateFromISOString,
  requestDate: Nullable(DateFromISOString),
  completeDate: Nullable(DateFromISOString),
  createdDate: MaybeUndefined(DateFromISOString),
  createdBy: MaybeUndefined(t.string)
})

export type PremiumDecision = t.TypeOf<typeof PremiumDecisionC>
