import React from 'react'
import { PolicyServiceProps } from '../policy-service-props'
import {
  form2,
  ErrorHandling,
  AppContext,
  ReinstatementModel,
  ReinstatementService,
  GeneralService,
  PulseOpsFormat,
  SelectOption,
  TransactionType,
  StorageBlob,
  RiderReinstatementModel,
  CancelRiderService,
  CancelRider,
  sharedStyle,
  Panel,
  useTranslator,
  TableField,
  getDiffMonths,
  ProductEnum,
  RiderReinstatementService,
  convertToAmount,
  TaskType
} from '@pulseops/common'
import { RiderReinstatementForm } from './common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { SC } from '../../common'
import _ from 'lodash'
import { RequestAuthenticateData } from '../../request-authen'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { ConfirmTab } from './confirm_tab'
import { RequestTab } from './request_tab'
import moment from 'moment'
import { ScrollView, TouchableOpacity, View, Text, StyleSheet } from 'react-native'
import { UploadedFilesInfo } from '../policy-service-props'

type Props = PolicyServiceProps<RiderReinstatementModel.RiderReinstatementSubmitData> & {}

export const RiderReinstatementScreen: React.FC<Props> = ({ policyNumber, initSubmission, isConfirmed, officeCode }) => {
  const [loading, bindLoader] = useLoading(false)
  const { t, i18n } = useTranslation()
  const { showGlobalLoading, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [data, setData] = React.useState<CancelRider.LARiderInfo[]>([])
  const [detailLapsedDate, setLapsedDate] = React.useState<string>('')
  const [reinsFee, setReinsFee] = React.useState<number>()
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const { navigate } = useNavigation()
  const isFocused = useIsFocused()
  const { base: form, handleSubmit } = form2.useForm(RiderReinstatementForm.codec)
  const st = useTranslator('submission').t
  const { getValues, watch } = form

  const columns = [
    {
      key: '1',
      title: st('Rider'),
      name: 'productName'
    },
    {
      key: '2',
      title: st('RiskCommDate'),
      name: 'riskCommDate'
    },
    {
      key: '3',
      title: st('LapsedDate'),
      name: 'lapsedDate'
    },
    {
      key: '4',
      title: st('SumAssured'),
      name: 'sumAssured'
    },
    {
      key: '5',
      title: st('InstallmentPremium'),
      name: 'installmentPrem'
    }
  ]

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    setErrorMessage('')
    pipe(
      ZIO.zipPar(RiderReinstatementService.getLapseDate(policyNumber!), CancelRiderService.getDetail(policyNumber!, ProductEnum.Status.LA)),
      ZIO.map(([lapseDateData, detail]) => {
        // const day = moment().startOf('days').diff(moment(detailGetLapsedDate.reinData.attributesExt.LAPSED_DATE).startOf('days'), 'days')
        setLapsedDate(lapseDateData)
        // if (day <= 730) {
        const riderInfoList = detail.laRiderInfo
        riderInfoList.map((item, index) => {
          const riderList = detail.riderNameList
          item.riders.map((itemRider, indexRider) => {
            const riderFind = riderList.filter((i) => i.code === itemRider.productCode)
            riderInfoList[index].riders[indexRider].riskCommDate = PulseOpsFormat.dateStringtoFormat(riderInfoList[index].riders[indexRider].riskCommDate, 'DD/MM/YYYY')
            riderInfoList[index].riders[indexRider].lapsedDate = PulseOpsFormat.dateStringtoFormat(lapseDateData, 'DD/MM/YYYY')
            if (riderFind[0])
              riderInfoList[index].riders[indexRider].productName = itemRider.productCode + " - " + (i18n.language === "vi" ? riderFind[0].nameVi : riderFind[0].name)
          })
        })
        setData(riderInfoList)
        // }
        // else
        //   setData([])

      }),
      bindLoader,
      ErrorHandling.run()
    )
  }, [i18n.language])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionScreen' })
        },
        { title: t('TransactionType:RIDER_REINSTATEMENT'), navigate: null }
      ])
    }
  }, [isFocused])

  initSubmission({
    validate: async (isContinue) => {

      if (checkAttachFile() && data.length >= 1)
        handleSubmit((validate) => {
          console.log('handlesubmit', validate)
        })()

      await form.trigger()

      if (form.formState.isValid && checkAttachFile() && data.length >= 1) {
        setErrorMessage('')
        return {
          url: () => `wf-api/policy/${policyNumber!}/rider-reinstatement`,
          body: mapData(),
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.RIDER_REINSTATEMENT) || '',
          collerationId: policyNumber || '',
          transaction: TransactionType.RIDER_REINSTATEMENT,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      }
      setErrorMessage(t('message:MS050232'))
      return false
    },
    clear: () => resetForm()
  })

  const { detail, occupationsOptions, dataRiders } = pipe(
    ZIO.zipPar(ReinstatementService.getDetail(policyNumber!), GeneralService.getOccupations, CancelRiderService.getDetail(policyNumber!, ProductEnum.Status.LA), RiderReinstatementService.getLapseDate(policyNumber!)),
    ZIO.map(([detail, occupations, data, lapseDate]) => {
      const occupationsOptions = occupations.map((occupation) => {
        return {
          value: occupation.code,
          label: occupation.name
        }
      })
      const dataRiders = data
      //return ZIO.unit
      initData(detail, occupationsOptions, dataRiders, lapseDate)
      return {
        detail,
        occupationsOptions,
        dataRiders
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  const resetForm = () => {
    setErrorMessage('')
    initData(detail, occupationsOptions, dataRiders, detailLapsedDate)
  }

  const initData = (detail: ReinstatementModel.Detail, occupations: SelectOption[], dataRiders: CancelRider.DetailCancelRiderInfo, lapseDate: string) => {
    // const day = moment().startOf('days').diff(moment(detail.reinData.attributesExt.LAPSED_DATE).startOf('days'), 'days')
    // const checkDay = detail && detail.reinData && day <= 730
    let reinData: RiderReinstatementForm.ReinDetail[] = []
    detail.reinData.lifeAssured.map((client, index) => {
      const riders: RiderReinstatementForm.RiderDetail[] = []
      const riderFindIndex = dataRiders.laRiderInfo.findIndex((item) => item.lifeAssured === client.clientId)
      if (riderFindIndex !== -1) {
        dataRiders.laRiderInfo[riderFindIndex].riders.map((item, itemIndex) => {
          riders[itemIndex] =
          {
            coverageCode: item.productCode,
            riskCommDate: item.riskCommDate,
            lapsedDate: lapseDate,
            sumAssured: item.sumAssured,
            riderPremium: item.installmentPrem,
          }
        })
        const currentOccupation = occupations.find((o) => o.value === client.attributesExt.CUR_OCCUPATION)
        const reinDetail: RiderReinstatementForm.ReinDetail = {
          name: client.name,
          clientNum: client.clientId,
          healthAnswer: false,
          occupationAnswer: false,
          activitiesAnswer: false,
          companyAnswer: false,
          curOccupation: currentOccupation ?? { label: '', value: '' },
          agentCode: detail.reinData.attributesExt.AGENT_CODE,
          riderList: riders
        }
        reinData.push(reinDetail)
      }
    })

    form.reset({
      formRein: {
        reinFee: reinsFee ?? 0,
        lifeAssuredList: reinData,
        attachmentFiles: []
      }
    })
  }

  const getFee = () => {
    if (policyNumber)
      pipe(
        RiderReinstatementService.getReinStateFee(policyNumber),
        ZIO.tap((value) => {
          form.setValue('formRein.reinFee', value)
          setReinsFee(value)
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
    else
      setReinsFee(0)
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    uploadedFileList.push({
      uploadFiles: form.getValues('formRein.attachmentFiles') as RiderReinstatementForm.FileMeta[],
      transactionType: TransactionType.RIDER_REINSTATEMENT,
      docTypeCode: 'DPS09',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  const checkAttachFile = () => {
    const lapseDate = moment(detailLapsedDate)
    const endDate = moment(new Date())
    const diffM = getDiffMonths(lapseDate, endDate)
    const attachmentFiles = watch('formRein.attachmentFiles')
    const lifeAssuredList = watch('formRein.lifeAssuredList')
    if (diffM >= 12 || lifeAssuredList.some((c) => c.healthAnswer)) {
      return !!attachmentFiles && attachmentFiles.length >= 1
    }
    return true
  }

  const mapData = (): RiderReinstatementModel.RiderReinstatementSubmitData => {
    const lifeAssured: RiderReinstatementModel.LifeAssured[] = watch('formRein.lifeAssuredList').map((r) => {
      let lA: RiderReinstatementModel.LifeAssured = {
        clientNo: r.clientNum ?? "",
        healthStateChange: r.healthAnswer,
        occupationChange: r.occupationAnswer,
        curOccupation: r.curOccupation?.value || '',
        newOccupation: r.occupationAnswer && r.newOccupation?.value || '',
        activityChange: r.activitiesAnswer,
        newActivity: r.activitiesAnswer && r.newActivity || '',
        companyChange: r.companyAnswer,
        newCompany: r.companyAnswer && r.newCompany || '',
        agentCode: r.agentCode,
        riderList: r.riderList
      }

      return lA
    })
    const reinFee = watch('formRein.reinFee')
    return {
      reinsFee: reinFee ?? 0,
      lifeAssuredList: lifeAssured
    }
  }
  return (
    <ScrollView>
      <SC.Container>
        <SC.Padding vertical={10}>
          <Text style={sharedStyle.sectionTitle}>{t('submission:RidersList').toUpperCase()}</Text>
        </SC.Padding>
        <View>
          {data.map((item, index) => {
            return (
              <Panel isExand key={index} title={<Text style={{ marginTop: 16 }}>{item.clientName}</Text>}>
                <View>
                  <TableField columns={columns} dataSource={item.riders} currency="VND" styleBody={styles.bodyTable} styleHeader={styles.headerTable} />
                </View>
              </Panel>)
          })}
        </View>
        {!isConfirmed &&
          <View>
            <TouchableOpacity
              style={[sharedStyle.button, { borderColor: '#ED1B2E', backgroundColor: '#EE1A2D', width: 300, marginLeft: 10 }]} onPress={getFee}
            >
              <Text style={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>
                {t('submission:CalculateReinstateFee')}
              </Text>
            </TouchableOpacity>
          </View>
        }
        {(reinsFee || reinsFee === 0) &&
          <View style={{ marginVertical: 10, marginLeft: 10 }}>
            <SC.TitleText>{t('submission:EstimatedReinstateFee')}</SC.TitleText>
            <SC.BoldText color={'#EE1A2D'}>{convertToAmount(reinsFee) + " VND"}</SC.BoldText>
          </View>}
        {isConfirmed ?
          <ConfirmTab form={form.getValues()} />
          :
          <RequestTab dataLapsedDate={detailLapsedDate} form={form} occupations={occupationsOptions || []} errorMessage={errorMessage} />
        }
      </SC.Container>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  bodyTable: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16,
  },
  headerTable: {
    paddingVertical: 16,
    paddingHorizontal: 16
  },
})
