import { FileUploadData, RiderAlterationData, SelectOption } from '@pulseops/common'

export namespace RiderAlterationFormData {
  export interface RiderAlteration {
    isRiderAlteration: boolean
    LARiderAlterationList: Array<LARiderAlterationData>
    isAddRider: boolean
    requiredPayinAmount: string
    healthDocuments: FileUploadData[]
    totalPremium: number
    newRiderLAList: Array<NewRiderLAData>
  }

  export interface LARiderAlterationData {
    selectAllForLA: boolean
    LAName: string
    lifeAssured: string
    lifeNo: string
    currentRiders: Array<CurrentRiderForm>
  }

  export interface NewRiderLAData {
    LAName: string
    lifeAssured: string
    isNewLA: boolean
    newRiderList: Array<NewRider>
    newLAInfo?: NewLAInfo
    alteredRiderList: SelectOption[]
  }

  export interface CurrentRiderForm {
    riderCode: string
    riderName: string
    isWaiverProduct: boolean
    isClassNumber: boolean
    classNumberList: string[]
    SelectRowForRider: boolean
    riskCommDate: string
    riskCessDate: string
    sumAssured: number
    alteredSumAssured: string
    installmentPrem: number
    newInstallmentPremium: number
    lifeNo: string
    coverageNo: string
    riderNo: string
    riderStatus: string
  }

  export interface NewRider {
    riderName: SelectOption
    sumAssured: string
    policyPeriod: string
    isWaiverProduct: boolean
    isClassNumber: boolean
    classNumberList: string[]
  }

  export interface NewLAInfo {
    surname: string
    firstName: string
    documentType: SelectOption
    IDNumber: string
    issuePlace: SelectOption
    issuedDate: Date
    USTaxDeclaration: string
    dateOfBirth: Date
    gender: SelectOption
    mobilePhoneCode: SelectOption
    mobilePhone: string
    email: string
    nationality: SelectOption
    occupation: SelectOption
    jobDescription: string
    jobTitle: string
    companyName: string
    companyAddress: string
    averageIncome: SelectOption
    identifyDocument: FileUploadData[]
    dupPhone: RiderAlterationData.RelationShip[]
    dupEmail: RiderAlterationData.RelationShip[]
  }
}
