import { ZIO } from '@mxt/zio'
import {
  assets,
  DatePicker,
  ErrorHandling,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  PulseOpsFormat,
  RadioButtonGroup,
  SelectOption,
  SelectSearch,
  Title,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as _ from 'lodash'
import React from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ComplainInquiryForm } from './complain-inquiry-form'
interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail: InquiryComplaintData.DetailData | null
}

type Option = {
  label: string
  value: string
}

export const MoneyMisappropriation: React.FC<Props> = ({ detail, form }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()

  const {
    control,
    formState: { errors },
    watch,
    clearErrors
  } = form

  const { fields, append, remove, update } = useFieldArray<ComplainInquiryForm.Raw>({
    control,
    name: 'moneyMissappropriation.list'
  })

  // Fecth API

  const receiptTypeList = pipe(
    InquiryComplaintService.getT22Data('receipt-type'),
    ZIO.map((list) => {
      return list.map((item) => ({
        ...item,
        name: item.nameEn,
        nameVi: item.name
      }))
    }),
    ErrorHandling.runDidMount()
  )

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const converOptions = (arr: any[]): Option[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  const onAddMoneyMisappropriationItem = () => {
    append({
      receipt: 'no',
      receiptType: null,
      complaintAmount: '',
      receiptNumber: '',
      benefit_PremiumMisappropriatedDate: new Date()
    })
  }

  const onRemoveItem = (index: number) => {
    remove(index)
    setTotalComplaintAmount()
  }

  const setTotalComplaintAmount = () => {
    let total = 0
    const list = form.getValues('moneyMissappropriation.list')
    total = _.reduce(list, (sum, n) => sum + PulseOpsFormat.thousandSepartorReverse(n.complaintAmount), 0)
    form.setValue('moneyMissappropriation.totalComplaintAmount', PulseOpsFormat.thousandSepartor(total))
  }

  const onComplaintAmountChange = (val: string, index: number) => {
    const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    form.setValue(
      `moneyMissappropriation.list.${index}.complaintAmount` as 'moneyMissappropriation.list.0.complaintAmount',
      newVal
    )
    setTotalComplaintAmount()
  }

  const onChangeReceipt = (e: string, index: number) => {
    if (watch(`moneyMissappropriation.list.${index}.receipt`) === 'no') {
      update(index, {
        receipt: 'no',
        receiptType: null,
        complaintAmount: '',
        receiptNumber: '',
        benefit_PremiumMisappropriatedDate: new Date()
      })
    }
  }

  const onChangeReceiptType = (e: SelectOption | null, index: number) => {
    console.log('onChangeReceiptType', !!e)
    if (!!e) {
      clearErrors(`moneyMissappropriation.list.${index}.receiptType`)
    }
  }

  return (
    <View>
      <Title title={t('ComplaintedMoneyMisappropriationDetails')} />
      {fields.map((field, index) => (
        <Panel title="" key={field.id}>
          <View style={isMobile ? styles.rowMobile : styles.row}>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={`moneyMissappropriation.list.${index}.receipt` as 'moneyMissappropriation.list.0.receipt'}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <RadioButtonGroup
                    title={t('Receipt')}
                    colLength={'100%'}
                    required
                    value={value}
                    options={[
                      { id: 'yes', label: t('common:Yes') },
                      { id: 'no', label: t('common:No') }
                    ]}
                    onChange={(e) => {
                      onChange(e)
                      onChangeReceipt(e, index)
                    }}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={`moneyMissappropriation.list.${index}.receiptType` as 'moneyMissappropriation.list.0.receiptType'}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    required={watch(`moneyMissappropriation.list.${index}.receipt`) === 'yes'}
                    label={t('ReceiptType')}
                    onChange={(e) => {
                      onChange(e)
                      onChangeReceiptType(e, index)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={
                      watch(`moneyMissappropriation.list.${index}.receipt`) === 'no' || value?.value
                        ? ''
                        : error?.message
                    }
                    placeholder={showOptionLanguage()}
                    options={receiptTypeList ? converOptions(receiptTypeList) : []}
                    isShowFullText={true}
                  />
                )}
              />
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={
                  `moneyMissappropriation.list.${index}.receiptNumber` as 'moneyMissappropriation.list.0.receiptNumber'
                }
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required={
                      watch(`moneyMissappropriation.list.${index}.receiptType`)?.value !== 'RC06' &&
                      watch(`moneyMissappropriation.list.${index}.receiptType`)?.value !== 'RC05' &&
                      watch(`moneyMissappropriation.list.${index}.receipt`) !== 'no'
                    }
                    title={t('ReceiptNumber')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ''}
                    errorMessage={error?.message}
                    maxLength={15}
                  />
                )}
              />
            </View>
          </View>
          <View style={isMobile ? styles.rowMobile : styles.row}>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={
                  `moneyMissappropriation.list.${index}.complaintAmount` as 'moneyMissappropriation.list.0.complaintAmount'
                }
                control={control}
                render={({ field: { onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required
                    title={t('ComplaintAmount')}
                    onChange={(val: string) => onComplaintAmountChange(val, index)}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    suffix="VNĐ"
                  />
                )}
              />
            </View>
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name={
                  `moneyMissappropriation.list.${index}.benefit_PremiumMisappropriatedDate` as 'moneyMissappropriation.list.0.benefit_PremiumMisappropriatedDate'
                }
                defaultValue={new Date()}
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('Benefit/PremiumMisappropriatedDate')}
                    required
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
          {fields.length > 1 && (
            <View style={isMobile ? styles.rowMobile : styles.row}>
              <View style={isMobile ? styles.colFull : styles.col}>
                <TouchableOpacity onPress={() => onRemoveItem(index)} style={[styles.row, { alignItems: 'center' }]}>
                  <assets.DeleteBin />
                  <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Delete')}</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </Panel>
      ))}
      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={styles.colFull}>
          <Text style={{ color: '#ED1B2E' }}>{_.get(errors.moneyMissappropriation as unknown, 'list')?.message}</Text>
        </View>
      </View>
      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={styles.colFull}>
          <TouchableOpacity onPress={onAddMoneyMisappropriationItem} style={[styles.row, { alignItems: 'center' }]}>
            <assets.PlusCircleIcon />
            <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Add')}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={isMobile ? styles.rowMobile : styles.row}>
        <View style={isMobile ? styles.colFull : styles.col}>
          <Controller
            name="moneyMissappropriation.totalComplaintAmount"
            control={control}
            defaultValue={'0'}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('TotalComplaintAmount')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={error?.message}
                disabled
                suffix="VNĐ"
              />
            )}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
