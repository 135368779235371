import * as React from 'react'
import {
  Title,
  Panel,
  Divider,
  HeaderTable,
  PayoutPopup,
  PayoutService,
  ErrorHandling,
  AppContext,
  GeneralService,
  formatNumberWithComma,
  ControlProps
} from '@pulseops/common'
import { Row, Column, Label, Checkbox, Error } from '@pulseops/submission/common'
import { PayPremiumPopup } from './pay-premium-popup'
import { OtherPayPremiumPopup } from './topup-popup'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'

export interface PayoutMethodMaturityAdvanceRef {
  clearData: () => void
}

interface Props extends ControlProps<PayoutPopup.Summary[]> {
  methods?: PayoutPopup.PayoutMethods[]
  policyNum: string
  editable?: boolean
  enable?: boolean
}

const _PayoutMethodMaturityAdvance = (
  {
    policyNum,
    methods = [PayoutPopup.PayoutMethods.PAYPREMIUM, PayoutPopup.PayoutMethods.OTHER],
    editable = true,
    enable = true,
    value,
    errorMessage,
    onChange
  }: Props,
  ref: React.Ref<PayoutMethodMaturityAdvanceRef>
) => {
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()

  const [isPayPremiumChecked, setIsPayPremiumChecked] = React.useState<boolean>(false)
  const [isOtherPayPremiumChecked, setIsOtherPayPremiumChecked] = React.useState<boolean>(false)

  const [isPayPremiumToggle, setIsPayPremiumToggle] = React.useState<boolean>(false)
  const [isOtherPayPremiumToggle, setIsOtherPayPremiumToggle] = React.useState<boolean>(false)

  const [payPremiumDetails, setPayPremiumDetails] = React.useState<PayoutPopup.PayPremiumData[]>([])
  const [otherPayPremiumDetails, setOtherPayPremiumDetails] = React.useState<PayoutPopup.OtherPayPremiumData[]>([])

  const [payPremiumPayoutData, setPayPremiumPayoutData] = React.useState<PayoutPopup.PayoutData[]>([])
  const [otherPayPremiumPayoutData, setOtherPayPremiumPayoutData] = React.useState<PayoutPopup.PayoutData[]>([])

  const [translator, setTranslator] = React.useState<PayoutPopup.PayoutMethodTranslator[]>([])
  const [isLoading, bindLoading] = useLoading(false)

  const [totalPayoutAmount, setTotalPayoutAmount] = React.useState<number>(0)

  React.useImperativeHandle(
    ref,
    () => ({
      clearData: () => {
        setIsPayPremiumChecked(false)
        setIsOtherPayPremiumChecked(false)

        setPayPremiumPayoutData([])
        setOtherPayPremiumPayoutData([])
      }
    }),
    []
  )

  const getPayoutMethodTranslator = () => {
    return pipe(
      GeneralService.getPayoutMethods(),
      ZIO.tap((x) => {
        const translator = x.body.map((x) => ({
          typeEnum: x.typeEnum,
          nameEn: x.nameEn,
          nameVi: x.nameVi
        }))
        setTranslator(translator)
        return ZIO.unit
      })
    )
  }

  const getPayPremiumDetails = (showModal: (isVisible: boolean) => void) => () => {
    pipe(
      PayoutService.getPayPremiumMaturityAdvDetail(policyNum),
      ZIO.tap((details) => {
        details = details.filter((x) => x.policyNum !== policyNum)
        setPayPremiumDetails(details)
        showModal(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getOtherPremiumDetails = (showModal: (isVisible: boolean) => void) => () => {
    pipe(
      PayoutService.getTopupMaturityAdvDetail(policyNum),
      ZIO.tap((details) => {
        details = details.filter((x) => x.policyNum !== policyNum)
        setOtherPayPremiumDetails(details)
        showModal(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const getPayoutMethodData = () => {
    let data: PayoutPopup.Summary[] = []
    if (isPayPremiumChecked) {
      data = data.concat(
        payPremiumPayoutData.map(
          (payPremium): PayoutPopup.Summary => ({
            method: payPremium.method,
            methodView: PayoutPopup.translateMethod(payPremium.method, translator),
            detail: `${payPremium.policyNum} - ${payPremium.poName}`,
            amount: payPremium.amount,
            policyNum: payPremium.policyNum,
            poName: payPremium.poName,
            totalPremium: payPremium.totalPremium,

            bankAccountName: null,
            bankAccountNum: null,
            bankBranchCode: null,
            bankBranchName: null,
            bankCode: null,
            bankName: null,
            nationalId: null,
            payee: null,
            officeCode: null,
            // issueDate: null,
            // issueBy: null
            officeBankCode: undefined,
            officeType: undefined
          })
        )
      )
    }

    if (isOtherPayPremiumChecked) {
      data = data.concat(
        otherPayPremiumPayoutData.map(
          (otherPremium): PayoutPopup.Summary => ({
            method: otherPremium.method,
            methodView: PayoutPopup.translateMethod(otherPremium.method, translator),
            detail: `${otherPremium.policyNum} - ${otherPremium.poName}`,
            amount: otherPremium.amount,
            policyNum: otherPremium.policyNum,
            poName: otherPremium.poName,
            totalPremium: otherPremium.totalPremium,

            bankAccountName: null,
            bankAccountNum: null,
            bankBranchCode: null,
            bankBranchName: null,
            bankCode: null,
            bankName: null,
            nationalId: null,
            payee: null,
            officeCode: null,
            // issueDate: null,
            // issueBy: null
            officeBankCode: undefined,
            officeType: undefined
          })
        )
      )
    }

    return data
  }

  React.useEffect(() => {
    const data = getPayoutMethodData()
    onChange && onChange(data)
  }, [payPremiumPayoutData, otherPayPremiumPayoutData])

  React.useEffect(() => {
    if (value) {
      const totalPayoutAmount = value.reduce((total, item) => total + item.amount, 0)
      setTotalPayoutAmount(totalPayoutAmount)
    }
  }, [value])

  React.useEffect(() => {
    pipe(getPayoutMethodTranslator(), bindLoading, ErrorHandling.run())
  }, [])

  React.useEffect(() => showGlobalLoading(isLoading), [isLoading])

  return (
    <>
      <Title title={t('submission:PAYOUT_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        {editable && (
          <>
            <Label title={t('submission:PayoutMethod')} required />
            <Divider height={17} />
            <Column alignItems="baseline">
              <Row flex={1} flexWrap="wrap">
                {methods.includes(PayoutPopup.PayoutMethods.PAYPREMIUM) && (
                  <Checkbox
                    title={t('submission:Paypremium')}
                    value={isPayPremiumChecked}
                    enable={enable}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        getPayPremiumDetails(setIsPayPremiumToggle)()
                      } else {
                        setPayPremiumPayoutData([])
                      }
                      setIsPayPremiumChecked(isChecked)
                    }}
                    style={{ marginEnd: 50 }}
                  />
                )}

                {methods.includes(PayoutPopup.PayoutMethods.OTHER) && (
                  <Checkbox
                    title={t('submission:OtherPremiumMaturityAdv')}
                    value={isOtherPayPremiumChecked}
                    enable={enable}
                    onChange={(isChecked) => {
                      setIsOtherPayPremiumChecked(isChecked)
                      if (isChecked) {
                        getOtherPremiumDetails(setIsOtherPayPremiumToggle)()
                      } else {
                        setOtherPayPremiumPayoutData([])
                      }
                    }}
                    style={{ marginEnd: 50 }}
                  />
                )}
              </Row>
            </Column>
            <Divider height={30} />
          </>
        )}
        <Row>
          <Label title={`${t('requestInfo:TotalPayoutAmount')}: `} color="#000000" />
          <Label title={`${formatNumberWithComma(totalPayoutAmount)} VND`} color={'#ED1B2E'} fontWeight="bold" />
        </Row>
        <Row justifyContent="space-between" marginTop={16} marginBottom={10}>
          <Label title={t('submission:PaymentSummary')} />
          <Label title={`${t('requestInfo:Currency')}: VND`} fontStyle="italic" />
        </Row>
        <HeaderTable
          columns={[
            {
              key: '0',
              title: t('Payout:PayMethodSummary'),
              name: 'methodView'
            },
            {
              key: '1',
              title: t('requestInfo:Detail'),
              name: 'detail'
            },
            {
              key: '2',
              title: t('requestInfo:Amount'),
              name: 'amount',
              styles: {
                textAlign: 'right'
              }
            }
          ]}
          dataSource={(value || []).map((item) => ({ ...item, amount: formatNumberWithComma(item.amount) })) as any[]}
        />
        {errorMessage && <Error style={{ marginTop: 10 }} message={errorMessage} />}
      </Panel>
      <PayPremiumPopup
        visible={isPayPremiumToggle}
        primaryPolicy={policyNum}
        details={payPremiumDetails}
        onClose={() => {
          setIsPayPremiumToggle(false)
          setIsPayPremiumChecked(false)
        }}
        onConfirm={(payoutData) => {
          setPayPremiumPayoutData(payoutData)
          setIsPayPremiumToggle(false)
        }}
      />

      <OtherPayPremiumPopup
        visible={isOtherPayPremiumToggle}
        primaryPolicy={policyNum}
        details={otherPayPremiumDetails}
        onClose={() => {
          setIsOtherPayPremiumToggle(false)
          setIsOtherPayPremiumChecked(false)
        }}
        onConfirm={(payoutData) => {
          setOtherPayPremiumPayoutData(payoutData)
          setIsOtherPayPremiumToggle(false)
        }}
      />
    </>
  )
}

export const PayoutMethodMaturityAdvance = React.forwardRef(_PayoutMethodMaturityAdvance)
