import * as React from 'react'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Modal,
  useWindowDimensions,
  TextInput,
  FlatList,
  ActivityIndicator,
  RefreshControl
} from 'react-native'
import {
  assets,
  AuthService,
  ErrorHandling,
  PulseOpsService,
  RoundedButton,
  TaskComment,
  useMobile,
  AppContext
} from '@pulseops/common'
import { CanView } from '../../../common/src'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
interface Props {
  taskId: string | undefined
  processId: string | undefined
  caseId: string | undefined
  visible: boolean
  onPressClose: () => void
}

export const TaskCommentFormModal = ({ visible, taskId, processId, caseId, onPressClose }: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation('TaskManagement')

  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [isSending, setIsSending] = React.useState<boolean>(false)
  const [isLoadingComments, bindIsLoadingComment] = useLoading(false)
  const [comments, setComments] = React.useState<TaskComment[]>([])
  const [message, setMessage] = React.useState<string>('')

  const author = pipe(AuthService.userInfo, ErrorHandling.runDidMount())

  const onClose = () => {
    setMessage('')
    setComments([])
    onPressClose && onPressClose()
  }

  const getComments = () => {
    if (processId && isMobile) {
      setComments([])
      pipe(
        PulseOpsService.getCommentList(processId),
        ZIO.catchAll((_error) => {
          // Error handling
          return ZIO.fail(null)
        }),
        ZIO.tap((value) => {
          setComments(value)
          return ZIO.unit
        }),
        bindIsLoadingComment,
        ZIO.unsafeRun({})
      )
    }
  }

  const sendMessage = () => {
    if (taskId && processId && author && author.email) {
      setIsSending(true)
      pipe(
        PulseOpsService.addComment(taskId, processId, { author: author.email, message }),
        ZIO.catchAll((_error) => {
          // Error Handing
          setIsSending(false)
          showToast(t('AddCommentFail'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          setIsSending(false)
          showToast(t('AddCommentSuccess'), 'success')
          setMessage('')
          getComments()
          isWide && onClose()
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    } else {
      showToast(t('AddCommentFail'), 'success')
    }
  }

  React.useEffect(() => getComments(), [processId])

  return (
    <Modal visible={visible} transparent={true} animationType={isWide ? 'fade' : 'slide'}>
      <View style={[styles.modal, { justifyContent: isWide ? 'center' : 'flex-end' }]}>
        {/* Backdrop handle touch outsidem modal event*/}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onClose}
            style={{
              position: 'absolute',
              width: width,
              height: height
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              borderRadius: 8,
              width: isWide ? 600 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0
            }
          ]}
        >
          {/* Header */}
          <View style={styles.headerContainer}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('Comment')}</Text>
              <Text style={styles.caseId}>{caseId}</Text>
            </View>
            <CanView condition={isWide}>
              <TouchableOpacity style={styles.btnClose} activeOpacity={0.7} onPress={onClose}>
                <assets.CloseTaskModalIcon />
              </TouchableOpacity>
            </CanView>
          </View>

          {/* Message List */}
          <CanView condition={isMobile}>
            <View style={{ height: height * 0.6 }}>
              <FlatList
                style={{ marginHorizontal: 20, flexGrow: 1 }}
                contentContainerStyle={{ flexGrow: 1 }}
                data={comments}
                refreshing={true}
                refreshControl={<RefreshControl refreshing={true} colors={['red']} />}
                ListEmptyComponent={
                  <View style={styles.emptyListContainer}>
                    <CanView condition={isLoadingComments}>
                      <ActivityIndicator color={'red'} />
                    </CanView>
                    <CanView condition={!isLoadingComments}>
                      <Text style={styles.emptyListText}>{t('NoCommentYet')}</Text>
                    </CanView>
                  </View>
                }
                renderItem={({ item }) => {
                  return (
                    <View style={{ marginVertical: 6 }}>
                      <View style={styles.messageItem}>
                        <Text style={styles.sender}>{item.username}</Text>
                        <Text style={styles.message}>{item.message}</Text>
                      </View>
                      <Text style={styles.sentDate}>{format(item.time, 'dd/MM/yyyy HH:mm')}</Text>
                    </View>
                  )
                }}
              />
            </View>
          </CanView>
          <View style={isMobile && styles.bottomBox}>
            {/* Message Input */}
            <View
              style={[
                styles.inputContainer,
                {
                  height: isWide ? 197 : 73
                }
              ]}
            >
              <TextInput
                multiline
                value={message}
                editable={!isSending}
                placeholder={t('WriteComment')}
                placeholderTextColor="#B0B0B0"
                style={styles.input}
                onChangeText={setMessage}
              />
            </View>

            <View style={styles.actionContainer}>
              <RoundedButton
                showBorder
                activeOpacity={0.7}
                text={t('Cancel')}
                textStyle={styles.btnTitle}
                style={{ height: 39, marginEnd: 15 }}
                textColorDisable={'#B0B0B0'}
                textColorEnable={'#ED1B2E'}
                borderColorDisable={'#B0B0B0'}
                borderColorEnable={'#ED1B2E'}
                onPress={onClose}
              />
              <RoundedButton
                disabled={!message || isSending}
                isLoading={isSending}
                activeOpacity={0.7}
                text={t('SubmitComment')}
                filled
                loadingColor={'#FFFFFF'}
                textColorDisable={'#FFFFFF'}
                textColorEnable={'#FFFFFF'}
                bgColorDisable={isSending ? '#ED1B2E' : '#B0B0B0'}
                bgColorEnable={'#ED1B2E'}
                onPress={sendMessage}
                style={{
                  minWidth: 100,
                  height: 39,
                  paddingHorizontal: 13
                }}
                textStyle={{ fontSize: 15 }}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)'
  },

  container: {
    backgroundColor: '#FFFFFF'
  },

  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    marginTop: 20,
    marginBottom: 10
  },

  headerTitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  headerTitle: {
    fontWeight: '600',
    color: '#4F4F4F',
    fontSize: 20,
    marginEnd: 16
  },

  caseId: {
    fontSize: 14,
    color: '#828282'
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  bottomBox: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,

    elevation: 6
  },

  input: {
    fontSize: 13,
    flex: 1
  },

  inputContainer: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#B0B0B0',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 15,
    marginHorizontal: 20
  },

  messageContainer: {
    height: 197,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },

  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 12,
    paddingBottom: 20
  },

  btnSubmit: {
    minWidth: 100,
    height: 39,
    paddingHorizontal: 13
  },

  emptyListContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  emptyListText: {
    fontSize: 13,
    color: '#B0B0B0'
  },

  messageItem: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#FAFAFA',
    paddingStart: 10,
    paddingEnd: 20
  },

  sender: {
    marginTop: 8,
    fontSize: 13,
    fontWeight: 'bold',
    color: '#333333'
  },

  message: {
    fontSize: 13,
    color: '#000000',
    marginTop: 8,
    marginBottom: 10
  },

  sentDate: {
    fontSize: 11,
    color: '#70777E',
    marginStart: 10,
    marginTop: 8
  },

  btnTitle: {
    fontSize: 15,
    marginVertical: 5,
    marginHorizontal: 29
  }
})
