import * as React from 'react'
import { ModalComponent, ModalComponentRef, ModalAction } from '@pulseops/common'
import { ScrollView, StyleSheet, View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'

interface Props {
  visible: boolean
  onClose: () => void
  onConfirm: () => void
  detail: {
    benefitName: string
    maxNumberOf: number
    acceptDateInHistory: number
    remainingDate: number
    acceptDateOfThisClaim: number
    benefitClaimType?: string
  }
}

export const RemainingBenefitPopup = ({ visible, onClose, onConfirm, detail }: Props) => {
  const { t } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const onPressClose = () => {
    onClose()
  }

  // const onPressConfirm = () => {
  //   console.log('run')
  // }

  const modalActions: ModalAction[] = []

  return (
    <ModalComponent
      ref={modalRef}
      // title={t('claim:RemainingBenefit')}
      title={`${t('claim:RemainingBenefit')} - ${t(`claim:${detail.benefitClaimType}`)}`}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      // modalWidth={Math.min(width * 0.9, 1024)}
      // modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <View
          style={{
            flex: 1,
            paddingHorizontal: 30
          }}
        >
          <View style={{ flexDirection: 'row', marginTop: 30 }}>
            <View style={{ flex: 1, marginEnd: 30 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t('Adjudication:BenefitName')}
                </Text>
              </View>
              <Text>{detail.benefitName}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 30 }}>
            <View style={{ flex: 1, marginEnd: 30 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t(`claim:${detail.benefitClaimType === 'Other' ? 'MaxAcceptedTime' : 'MaxAcceptedDate'}`)}
                </Text>
              </View>
              <Text>{detail.maxNumberOf}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                {t(`claim:${detail.benefitClaimType === 'Other' ? 'AcceptedTimeInHistory' : 'AcceptedDateInHistory'}`)}
              </Text>
              <Text>{detail.acceptDateInHistory}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 30 }}>
            <View style={{ flex: 1, marginEnd: 30 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t(`claim:${detail.benefitClaimType === 'Other' ? 'RemainingTime' : 'RemainingDate'}`)}
                </Text>
              </View>
              <Text>{detail.remainingDate}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t(
                    `claim:${
                      detail.benefitClaimType === 'Other' ? 'AcceptedTimeOfThisClaim' : 'AcceptedDateOfThisClaim'
                    }`
                  )}
                </Text>
              </View>
              <Text>{detail.acceptDateOfThisClaim}</Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#fff'
  },

  container: {
    paddingHorizontal: 30
  }
})
