
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native'

export type OBTabViewInfo = {
  tabName: string
  tabKey: number
}

type OBTabViewProps = {
  isActiveTabIndex: number
  setIsActiveTabIndex: (tabIndex: number) => void
  data: Array<OBTabViewInfo>
}

export const OBTabView = (props: OBTabViewProps) => {
  const { data, isActiveTabIndex, setIsActiveTabIndex } = props
  const { t } = useTranslation()
  return (
    <View style={tabStyles.tabContainer}>
      {data && data.map((dataItem, index) => {
        return (
          <TouchableOpacity key={`dataItem_${index}`} onPress={() => setIsActiveTabIndex(dataItem.tabKey)}>
            <View style={[tabStyles.tabContent, isActiveTabIndex === dataItem.tabKey && tabStyles.tabHightLight]}>
              <Text style={[tabStyles.tabText, isActiveTabIndex === dataItem.tabKey && tabStyles.tabHightLightText]}>{dataItem.tabName}</Text>
            </View>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

const tabStyles = StyleSheet.create({
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 24
  },
  tabContent: {
    paddingHorizontal: 24,
    paddingVertical: 6,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E7',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16
  },
  tabText: {
    color: '#828282',
    textAlign: 'center',
    // fontFamily: Noto Sans;
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 21
  },
  tabHightLight: {
    backgroundColor: '#fff'
  },
  tabHightLightText: {
    color: '#ED1B2E',
    textAlign: 'center',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 21
  }
})