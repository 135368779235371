import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ClientName } from './CepCommonModels'

export const ProductPolicyRes = t.type({
  data: Maybe(
    t.type({
      lifeAssureds: Maybe(
        t.array(
          t.type({
            lifeAssuredCode: Maybe(t.string),
            lifeAssuredName: Maybe(ClientName),
            life: Maybe(t.string),
            products: Maybe(
              t.array(
                t.type({
                  componentName: Maybe(t.string),
                  productCode: Maybe(t.string),
                  coverageStatus: Maybe(t.string),
                  premiumStatus: Maybe(t.string),
                  rerateDate: Maybe(t.string),
                  sumAssured: Maybe(t.number),
                  installmentPremium: Maybe(t.number),
                  paidToDate: Maybe(t.string),
                  premCessDate: Maybe(t.string),
                  covRiskCessDate: Maybe(t.string),
                  riskCessDate: Maybe(t.string),
                  rider: Maybe(t.string),
                  coverage: Maybe(t.string),
                  benefitOption: Maybe(t.string),
                  planCode: Maybe(t.string),
                  mortalityClass: Maybe(t.string)
                })
              )
            )
          })
        )
      ),
      policyNumber: Maybe(t.string)
    })
  )
})

export type ProductPolicyRes = t.TypeOf<typeof ProductPolicyRes>
