import { Throwable, UIO, ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'
import { AuthService } from '../auth'
import { FileUploadData, SelectOption } from '../component'
import { ErrorHandling } from '../ErrorHandling'
import { POApi } from '../POApi'
import { StorageBlob } from '../storage-blob'
import { PayoutPopup, TransactionType } from './model'
import * as O from 'fp-ts/lib/Option'

export const GeneralData = t.type({
  id: Maybe(t.string),
  code: t.string,
  name: t.string
})

export const IARelationship = t.type({
  code: t.string,
  name: t.string,
  nameEn: t.string,
  la1: t.string,
  la2: t.string,
  la3: t.string,
  la4: t.string
})

export const VerClaimType = t.type({
  id: Maybe(t.string),
  code: t.string,
  nameEn: t.string,
  nameVi: t.string
})

export const HospitalType = t.type({
  id: t.string,
  code: t.string,
  nameEn: t.string,
  nameVn: t.string
})

export const SubClaimTypeBenefit = t.type({
  id: t.string,
  subClaimType: t.string,
  subClaimTypeCode: t.string,
  benefit: t.string
})

export const ClaimType = t.type({
  claimType: Maybe(t.string),
  id: t.string,
  productCode: Maybe(t.string),
  productEN: t.string,
  productVN: Maybe(t.string),
  productWF: t.string
})

export const SubClaimType = t.type({
  claimType: t.string,
  claimTypeCode: t.string,
  id: t.string,
  subClaimType: t.string
})

export const ClaimSubBenefit = t.type({
  benefitCode: t.string,
  benefitEN: t.string,
  benefitVN: t.string,
  benefitWF: t.string,
  id: t.string
})

export const CriticalIllnessType = t.type({
  id: t.string,
  cilCode: t.string,
  citype: t.string,
  nameEn: t.string,
  nameVn: t.string,
  product: t.string
})

export const ICDType = t.type({
  id: t.string,
  diseaseNameEn: t.string,
  diseaseNameVn: t.string,
  icdcode: t.string
})

export const ProvinceType = t.type({
  code: t.string,
  name: t.string,
  bcCode: t.string
})

export const Relationships = t.type({
  id: t.string,
  code: t.string,
  nameEn: Maybe(t.string),
  nameVn: Maybe(t.string),
  relationshipEN: Maybe(t.string),
  relationshipVN: Maybe(t.string),
  female: t.string,
  male: t.string,
  beneficiaryDesignation: t.string,
  changeContactInfo: t.string,
  changeClientInfo: t.string,
  assignment: t.string,
  inheritance: t.string,
  riderAlteration: t.string,
  newClaimRegister: t.string,
  complaint: t.string
})

export const ReceiptType = t.type({
  id: t.string,
  receiptItemCode: t.string,
  receiptItemVN: t.string,
  receiptItemEN: t.string,
  receiptItemWF: t.string,
  subBenefit: t.string
})

export const DecisionType = t.type({
  code: t.string,
  nameEn: t.string,
  nameVi: t.string,
  nameWf: t.string
})

export const SurgeryType = t.type({
  id: t.string,
  surgeryCode: t.string,
  surgeryType: t.string,
  nameEn: t.string,
  nameVn: t.string
})

export const RelationshipT16s2 = t.type({
  code: t.string,
  id: t.string,
  la1: t.string,
  la2: t.string,
  la3: t.string,
  la4: t.string,
  relationshipEN: t.string
})

export const ClaimTypeInfo = t.type({
  claimCode: t.string,
  claimType: t.string,
  claimTypeShortName: t.string,
  claimTypeVN: t.string,
  claimTypeWF: t.string,
  id: t.string
})

export const ComponentPolicy = t.type({
  benefitClaimCode: t.string,
  benefitClaimEN: t.string,
  componentCode: t.string,
  componentEN: t.string,
  componentVN: t.string,
  componentWF: t.string,
  id: t.string,
  planName: t.string,
  productCode: t.string,
  productEN: t.string
})

export const BenefitClaim = t.type({
  benefitClaimCode: t.string,
  benefitClaimEN: t.string,
  benefitClaimVN: t.string,
  benefitClaimWF: t.string,
  id: t.string,
  eligible: Maybe(t.boolean),
  claimAmount: Maybe(t.string),
  benefitName: Maybe(t.string),
  eventDate: Maybe(t.string),
  autoDecision: Maybe(t.string),
  adjudicatorDecision: Maybe(
    t.type({
      value: t.string,
      label: t.string
    })
  ),
  declineReason: Maybe(
    t.type({
      value: t.string,
      label: t.string
    })
  ),
  claimBenefitAmount: Maybe(t.string),
  excludedAmount: Maybe(t.string),
  CMPExGAmount: Maybe(t.string),
  remarks: Maybe(t.string),
  componentCode: Maybe(t.string)
})

export const ComponentOfEachBenefit = t.array(
  t.type({
    benefitClaimCode: t.array(BenefitClaim),
    benefitClaimEN: t.string,
    componentCode: t.string,
    componentEN: t.string,
    componentVN: t.string,
    componentWF: t.string,
    id: t.string,
    planName: t.string,
    productCode: t.string,
    productEN: t.string
  })
)

export const SubBenefit = t.type({
  subBenefitCode: t.string,
  subBenefitEN: t.string,
  subBenefitVN: t.string,
  subBenefitWF: t.string,
  id: t.string,
  benefit: t.string
})

export const OfficeCode = t.type({
  code: t.string,
  nameEn: t.string,
  nameVi: t.string,
  type: t.string,
  status: t.string,
  bankCode: Maybe(t.string),
  gaCode: Maybe(t.string)
})

export const EvaluationInfo = t.type({
  evaluationEN: t.string,
  evaluationVN: t.string,
  evaluationCode: t.string
})

export const DeclineReason = t.type({
  declineEN: t.string,
  declineVN: t.string,
  declineCode: t.string,
  criticalIllness: t.string,
  death: t.string,
  declineWF: t.string,
  healthReimbursement: t.string,
  hospitalCash: t.string,
  totalPermanentDisability: t.string
})

export const HistoryLetter = t.type({
  createdDate: t.string,
  createdBy: t.string,
  status: t.string,
  policyNumber: t.string,
  documentDetails: Maybe(
    t.array(
      t.type({
        url: t.string,
        name: t.string,
        id: Maybe(t.string)
      })
    )
  )
})

export const LogFinalLetter = t.type({
  createdDate: t.string,
  createdBy: t.string,
  status: t.string,
  policyNumber: t.string,
  documentDetails: Maybe(
    t.array(
      t.type({
        url: t.string,
        name: t.string,
        id: Maybe(t.string)
      })
    )
  )
})

export const AutoDecision = t.array(
  t.type({
    caseId: t.string,
    productEN: t.string,
    productCode: t.string,
    componentEN: t.string,
    componentCode: t.string,
    planName: t.string,
    benefitClaimEN: t.string,
    benefitClaimCode: t.string,
    los: t.string,
    rcd: t.string,
    eventDate: t.string,
    exclusion: t.string,
    deathDate: t.string,
    claimEventDate: t.string
  })
)

export const ClaimCaseDuplicatedList = t.array(
  t.type({
    id: Maybe(t.string),
    businessKey: Maybe(t.string),
    claimNo: Maybe(t.string),
    claimStatus: Maybe(t.string),
    activity: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    policy: Maybe(t.string)
  })
)

export const ClaimNewCase = t.type({
  admissionDate: t.string,
  businessKey: t.string,
  claimActivity: t.string,
  claimNo: t.string,
  claimStatus: t.string,
  claimType: t.string,
  clientNumber: t.string,
  dischargeDate: t.string,
  policy: t.string,
  processInstanceId: t.string,
  components: t.array(
    t.type({
      benefitClaimCode: t.array(
        t.type({
          benefitClaimCode: t.string,
          benefitClaimEN: t.string,
          eligible: t.boolean,
          id: t.string
        })
      ),
      componentCode: t.string,
      componentEN: t.string,
      id: t.string,
      planName: t.string,
      productCode: t.string,
      productEN: t.string
    })
  )
})

export const Document = t.type({
  url: t.string,
  name: t.string
})

export const IssuedPlaceInfo = t.type({
  code: Maybe(t.string),
  name: Maybe(t.string),
  bcCode: Maybe(t.string),
  issueBy: Maybe(t.string)
})
export const ScoreVerification = t.type({
  caseId: t.string,
  policyNumber: t.string,
  policyOwner: t.string,
  clientNumber: t.string,
  totalScore: t.string,
  qcScoreDetailTopics: t.array(
    t.type({
      errorId: t.string,
      qcCheck: t.boolean,
      minusScore: t.number,
      topic: t.string
    })
  ),
  remark: t.string,
  lastUser: t.string,
  userGroup: t.string
})

export const UserGroupInfo = t.type({
  category: t.string,
  userGroup: t.string,
  groupName: t.string
})

export const UWUserGroupInputData = t.type({
  category: t.string,
  // uwResult: t.string,
  transactionTypeList: t.array(t.string)
})
export const AssignmentUserInfo = t.type({
  name: t.string,
  email: t.string,
  status: t.boolean
})
export const UploadFileToStorageInfo = t.type({
  inputStream: Maybe(t.string),
  contentType: t.string,
  name: t.string,
  metadata: Maybe(t.type({})),
  blobType: t.string,
  createdTime: t.string,
  lastModified: t.string
})

export const RecordFileInfo = t.type({
  objectId: Maybe(t.string),
  receivedDate: Maybe(t.string),
  creditDate: Maybe(t.string),
  amount: Maybe(t.number),
  refNo: Maybe(t.string),
  paymentReason: Maybe(t.string),
  primaryPolicyNo: Maybe(t.string),
  bankCode: Maybe(t.string),
  status: Maybe(t.string),
  sourceData: Maybe(t.string),
  createdDate: Maybe(t.string),
  updatedDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  updatedBy: Maybe(t.string),
  fileName: Maybe(t.string)
})

export const FailListInfo = t.type({
  objectId: Maybe(t.string),
  receivedDate: Maybe(t.string),
  creditDate: Maybe(t.string),
  amount: Maybe(t.number),
  refNo: Maybe(t.string),
  paymentReason: Maybe(t.string),
  primaryPolicyNo: Maybe(t.string),
  bankCode: Maybe(t.string),
  status: Maybe(t.string),
  sourceData: Maybe(t.string),
  createdDate: Maybe(t.string),
  updatedDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  updatedBy: Maybe(t.string),
  fileName: Maybe(t.string)
})

export const TransferData = t.type({
  transactionType: t.string,
  category: t.string,
  activityBasket: t.string,
  userQCGroupLevel: t.string,
  manager: t.boolean
})

export const RequestListInfo = t.type({
  status: Maybe(t.type({ label: t.string, value: t.string })),
  submissionDate: t.string,
  policyNumber: t.string,
  caseId: t.string,
  idNumber: t.string,
  policyOwnerName: t.string,
  paymentReason: t.string,
  id: t.string,
  officeCode: t.string
})

export const RequestListPolicyInfo = t.type({
  installmentPremium: t.number,
  product: t.string,
  lastestBilling: t.string,
  lastAPLDate: t.string,
  latestAppliedPremium: t.string,
  excessPremium: t.number,
  policyLoanPrincipal: t.number,
  aplPrinciple: t.number,
  discountPremium: t.number,
  policyLoanInterest: t.number,
  aplInterest: t.number
})

export const RequestListTransactionInfo = t.type({
  bankPartnerName: t.string,
  customerSubmitDate: t.string,
  partnerTransferDate: Maybe(t.string),
  spendAmount: t.number,
  refNo: t.string,
  reason: t.string,
  policyNumberAppNumber: t.string,
  adjustedPolicyNumber: t.string,
  remainingAmount: t.string,
  cardType: t.string,
  payeeName: t.string,
  mobileNumber: t.string,
  bankTransferPendingNo: t.string,
  remark: Maybe(t.string),
  source: t.string,
  processId: t.string
})

export const ManualTransferGroupInfo = t.type({
  userGroupCodeToTransfer: t.string,
  activityBasketToTransfer: t.string
})

export const ReportStatusInfo = t.type({
  reportCode: t.string,
  reportName: t.string,
  activeReport: t.boolean
})

export type GeneralData = t.TypeOf<typeof GeneralData>
export type IARelationship = t.TypeOf<typeof IARelationship>
export type VerClaimType = t.TypeOf<typeof VerClaimType>
export type HospitalType = t.TypeOf<typeof HospitalType>
export type SubClaimTypeBenefit = t.TypeOf<typeof SubClaimTypeBenefit>
export type ClaimSubBenefit = t.TypeOf<typeof ClaimSubBenefit>
export type ClaimType = t.TypeOf<typeof ClaimType>
export type SubClaimType = t.TypeOf<typeof SubClaimType>
export type CriticalIllnessType = t.TypeOf<typeof CriticalIllnessType>
export type ICDType = t.TypeOf<typeof ICDType>
export type ProvinceType = t.TypeOf<typeof ProvinceType>
export type Relationships = t.TypeOf<typeof Relationships>
export type RelationshipT16s2 = t.TypeOf<typeof RelationshipT16s2>
export type ReceiptType = t.TypeOf<typeof ReceiptType>
export type DecisionType = t.TypeOf<typeof DecisionType>
export type SurgeryType = t.TypeOf<typeof SurgeryType>
export type ClaimTypeInfo = t.TypeOf<typeof ClaimTypeInfo>
export type ComponentPolicy = t.TypeOf<typeof ComponentPolicy>
export type BenefitClaim = t.TypeOf<typeof BenefitClaim>
export type ComponentOfEachBenefit = t.TypeOf<typeof ComponentOfEachBenefit>
export type SubBenefit = t.TypeOf<typeof SubBenefit>
export type OfficeCode = t.TypeOf<typeof OfficeCode>
export type EvaluationInfo = t.TypeOf<typeof EvaluationInfo>
export type DeclineReason = t.TypeOf<typeof DeclineReason>
export type HistoryLetter = t.TypeOf<typeof HistoryLetter>
export type LogFinalLetter = t.TypeOf<typeof LogFinalLetter>
export type Document = t.TypeOf<typeof Document>
export type IssuedPlaceInfo = t.TypeOf<typeof IssuedPlaceInfo>
export type ClaimCaseDuplicatedList = t.TypeOf<typeof ClaimCaseDuplicatedList>
export type UserGroupInfo = t.TypeOf<typeof UserGroupInfo>
export type UWUserGroupInputData = t.TypeOf<typeof UWUserGroupInputData>
export type AssignmentUserInfo = t.TypeOf<typeof AssignmentUserInfo>
export type ScoreVerification = t.TypeOf<typeof ScoreVerification>
export type TransferData = t.TypeOf<typeof TransferData>

export type UploadFileToStorageInfo = t.TypeOf<typeof UploadFileToStorageInfo>
export type FailListInfo = t.TypeOf<typeof FailListInfo>
export type AutoDecision = t.TypeOf<typeof AutoDecision>
export type ClaimNewCase = t.TypeOf<typeof ClaimNewCase>
export type RecordFileInfo = t.TypeOf<typeof RecordFileInfo>
export type RequestListInfo = t.TypeOf<typeof RequestListInfo>
export type RequestListPolicyInfo = t.TypeOf<typeof RequestListPolicyInfo>
export type RequestListTransactionInfo = t.TypeOf<typeof RequestListTransactionInfo>
export type ReportStatusInfo = t.TypeOf<typeof ReportStatusInfo>
export namespace GeneralService {
  export const getIARelationship = (params: 'la0' | 'la1' | 'la2' | 'la3' | 'la4' = 'la0') =>
    pipe(
      POApi.get(`wf-api/general/data/get-ia-relationships/${params}`)(
        t.type({
          body: t.array(IARelationship)
        })
      ),
      ZIO.map((policy) => policy.body)
    )

  export const getGoAbroadPurposes = pipe(
    POApi.get(`wf-api/general/data/get-go-abroad-purposes`)(
      t.type({
        body: t.array(GeneralData)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getOccupations = pipe(
    POApi.get(`wf-api/general/data/get-occupations`)(
      t.type({
        body: t.array(GeneralData)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getSalaries = pipe(
    POApi.get(`wf-api/general/data/get-salaries`)(
      t.type({
        body: t.array(
          t.type({
            id: Maybe(t.string),
            code: t.string,
            name: t.string,
            nameEn: t.string
          })
        )
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getProvinces = pipe(
    POApi.get(`wf-api/general/data/get-provinces`)(
      t.type({
        body: t.array(ProvinceType)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getDistricts = (provinceCode?: string) =>
    pipe(
      POApi.post(`wf-api/general/data/get-districts`)(
        t.type({
          body: t.array(GeneralData)
        })
      )({
        body: {
          provinceCode
        }
      }),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? [])
    )

  export const getWards = (data: { provinceCode?: string; districtCode?: string }) =>
    pipe(
      POApi.post(`wf-api/general/data/get-wards`)(
        t.type({
          body: t.array(GeneralData)
        })
      )({
        body: {
          provinceCode: data.provinceCode,
          districtCode: data.districtCode
        }
      }),
      ZIO.catchAll((e) => ZIO.succeed(null)),
      ZIO.map((policy) => policy?.body ?? [])
    )

  export const getIssuedPlaceList = () =>
    pipe(
      POApi.get(`wf-api/general/data/get-place-of-issue`)(
        t.type({
          body: Maybe(t.array(IssuedPlaceInfo))
        })
      ),
      ZIO.map((responseData) => {
        return responseData.body
      })
    )

  export const getAllLocation = pipe(
    POApi.get(`wf-api/general/data/get-all-location`)(
      t.type({
        body: t.array(
          t.type({
            code: t.string,
            ward: t.string,
            district: t.string,
            province: t.string
          })
        )
      })
    ),
    ZIO.map((res) =>
      res.body.reduce<{ wards: Record<string, string>; districts: Record<string, string> }>(
        (data, item) => {
          data.wards[item.code] = item.ward
          data.districts[item.code.substring(0, 4)] = item.district
          return data
        },
        { wards: {}, districts: {} }
      )
    ),
    ZIO.cached()
  )

  export const getRelationships = pipe(
    POApi.get(`wf-api/general/data/get-relationships`)(
      t.type({
        body: t.array(
          t.type({
            id: Maybe(t.string),
            code: t.string,
            name: t.string,
            nameEn: Maybe(t.string),
            male: t.string,
            female: t.string
          })
        )
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getCountries = pipe(
    POApi.get(`wf-api/general/data/get-countries`)(
      t.type({
        body: t.array(GeneralData)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getCountriesTV536 = pipe(
    POApi.get(`wf-api/general/data/get-destination-countries`)(
      t.type({
        body: t.array(GeneralData)
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getMobileCodes = pipe(
    POApi.get(`wf-api/general/data/get-mobile-codes`)(
      t.type({
        body: t.array(
          t.type({
            id: t.string,
            code: t.string,
            countryName: t.string
          })
        )
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getIACRelationships = pipe(
    POApi.get(`wf-api/general/data/get-po-relationships`)(
      t.type({
        body: t.array(
          t.type({
            id: t.string,
            code: t.string,
            name: t.string,
            nameEn: t.string
          })
        )
      })
    ),
    ZIO.map((policy) => policy.body)
  )

  export const getUsersGroupsByTransaction = (category: string) =>
    pipe(
      POApi.getConfig({ params: { category } })(`pulseops/api/v1/permission/task-mannagement/group`)(
        t.type({
          groups: t.array(
            t.type({
              userGroup: t.string,
              groupName: t.string
            })
          )
        })
      ),
      ZIO.map((policy) => policy.groups)
    )

  export const getUsersGroupsByTransactionForClaim = (category: string, taskId: string) =>
    pipe(
      POApi.getConfig({ params: { category, taskId } })(`pulseops/api/v1/permission/task-mannagement/task/group`)(
        t.type({
          groups: t.array(
            t.type({
              userGroup: t.string,
              groupName: t.string
            })
          )
        })
      ),
      ZIO.map((policy) => policy.groups)
    )

  export const getUsersGroupsByTransactionType = (category: string, transactionTypeList: string[]) =>
    pipe(
      POApi.post(`pulseops/api/v1/permission/task-mannagement/get-by-transaction-and-category`)(
        t.type({
          groups: t.array(UserGroupInfo)
        })
      )({
        category: category,
        transactionTypeList: transactionTypeList
      }),
      ZIO.map((item) => item.groups)
    )

  export const getUserGroupForUW = (data: UWUserGroupInputData) =>
    pipe(
      POApi.post(`pulseops/api/v1/t4s13/get-user-group-code-t4s13`)(
        t.type({
          groups: t.array(UserGroupInfo)
        })
      )(data),
      ZIO.map((response) => {
        const filterList = response.groups.filter((x) => x.userGroup.includes('Underwriting'))
        return filterList
      })
    )

  export const getManualTransferGroups = (categoryCode: string, activityBasket: string, transactionType: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/tasks/manual-transfer-config`)(t.array(ManualTransferGroupInfo))({
        categoryCode: categoryCode,
        activityBasket: activityBasket,
        transactionType: transactionType
      }),
      ZIO.map((responseData) => {
        return responseData.filter((x) => x.activityBasketToTransfer === activityBasket)
      })
    )

  export const getUsersByGroups = (groups: string[], currAssignee: string, category: string, basket: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/permission/users/group`)(
        t.type({
          data: t.array(
            // t.type({
            //   name: t.string,
            //   email: t.string,
            //   status: t.boolean
            // })
            AssignmentUserInfo
          )
        })
      )(
        {
          basket,
          category,
          groups
        }
        // [...groups]
      ),
      ZIO.map((policy) => policy.data.filter((x) => x.email !== currAssignee) || [])
    )

  export const getRejectReasonByGroup = (processId: string) =>
    pipe(
      POApi.getConfig({ params: { processInstanceId: processId } })(`pulseops/api/v1/reject-reason/get-reason-list`)(
        t.type({
          data: Maybe(t.array(
            t.type({
              code: t.string,
              // id: t.string,
              nameEn: t.string,
              nameVn: t.string
              // userGroup: t.string
            })
          ))
        })
      ),
      ZIO.map((policy) => policy && policy.data ? policy.data : [])
    )

  export const getMetaData = (transactionType: string, docTypeCode: string, officeCode?: string) =>
    pipe(
      POApi.getConfig({ params: { transactionType, docTypeCode } })(`pulseops/api/v1/filenet/get-metadata`)(
        t.type({
          data: t.type({
            transactionType: t.string,
            doctypeVn: t.string,
            doctypeEn: t.string,
            docTypeCode: t.string,
            classFilenet: t.string,
            docID: t.string,
            mainDoc: t.string,
            subDoc: t.string,
            batchNo: t.string,
            type: t.string,
            refNum: t.string
          })
        })
      ),
      // ZIO.map((x) => {
      //   const officeC = officeCode || ''
      //   const currentDateFormat = moment().format('DD/MM/YYYY')
      //   const batchNo = officeC
      //     ? currentDateFormat + '-' + x.data.type + '-' + officeCode
      //     : currentDateFormat + '-' + x.data.type
      //   return { data: { ...x.data, batchNo } }
      // })
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (x) =>
          ZIO.effect(() => {
            const officeC = officeCode === '-' ? '' : officeCode || ''
            const currentDateFormat = moment().format('DD/MM/YYYY')
            const batchNo = officeC
              ? currentDateFormat + '-' + x.data.type + '-' + officeCode
              : currentDateFormat + '-' + x.data.type
            return { data: { ...x.data, batchNo } }
          })
      )
    )

  export const getMetaDataAgent = (transactionType: string, docTypeCode: string, officeCode?: string) =>
    pipe(
      POApi.getConfig({ params: { transactionType, docTypeCode } })(`pulseops/api/v1/filenet/get-metadata`)(
        t.type({
          data: t.type({
            transactionType: t.string,
            doctypeVn: t.string,
            doctypeEn: t.string,
            docTypeCode: t.string,
            classFilenet: t.string,
            docID: t.string,
            mainDoc: t.string,
            subDoc: t.string,
            batchNo: Maybe(t.string),
            type: t.string,
            refNum: Maybe(t.string)
          })
        })
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (x) =>
          ZIO.effect(() => {
            const officeC = officeCode === '-' ? '' : officeCode || ''
            const currentDateFormat = moment().format('DD/MM/YYYY')
            const batchNo = officeC
              ? currentDateFormat + '-' + x.data.type + '-' + officeCode
              : currentDateFormat + '-' + x.data.type
            // const batchNo = '[Type]-[OfficeCode]-[TransDate:yyyymmdd]'
            // const refNum = '[Source]-[CaseId]'
            return { data: { ...x.data, batchNo } }
          })
      )
    )

  export const getRequestFormSign = (policyNum: string) =>
    pipe(
      POApi.getConfig({ params: { policyNum } })(`pulseops/api/v1/sign-authentication/get-sign-authentication`)(
        t.type({
          data: t.array(
            t.type({
              groupType: t.string,
              policyNumber: t.string,
              signFiles: t.array(
                t.type({
                  metaData: t.type({
                    batchno: Maybe(t.string),
                    docid: Maybe(t.string),
                    doctype: Maybe(t.string),
                    maindoc: Maybe(t.string),
                    subdoc: Maybe(t.string)
                  }),
                  url: t.string
                })
              ),
              transactionType: t.string
            })
          )
        })
      )
    )

  export const verAddUserCheckFlag = (processInstanceId: string) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((info) =>
        POApi.post(`pulseops/api/v1/sign-authentication/add-flag`)(t.unknown)({
          processInstanceId,
          user: info.email
        })
      )
    )

  export const authFormUserCheckFlag = (processInstanceId: string) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((info) =>
        POApi.getConfig({
          params: {
            processInstanceId,
            user: info.email
          }
        })(`pulseops/api/v1/sign-authentication/check-exist-flag-view`)(
          t.type({
            isFlag: t.boolean
          })
        )
      ),
      ZIO.map((x) => x.isFlag)
    )

  export const checkPolicyWithLoanNumber = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/other/client-policy-verify`)(
        t.type({
          body: t.type({
            policyRecOuts: t.array(
              t.type({
                errorCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        body: {
          function: 'CHECK_LOAN',
          clientRecs: [{ clientNumber: '', securityNo: '' }],
          policyRecs: [{ policyNum }]
        }
      }),
      ZIO.map((verify) => verify.body.policyRecOuts.some(({ errorCode }) => !!errorCode))
    )

  export const getTransactionList = () =>
    pipe(
      POApi.get(`wf-api/table/t0/get-transactions-activated`)(
        t.type({
          body: t.array(
            t.type({
              nameEn: t.string,
              nameVi: t.string,
              typeWf: t.string
            })
          )
        })
      ),
      ZIO.map((_) => _.body)
    )

  export const getPayoutMethods = () =>
    pipe(
      POApi.get(`wf-api/table/t14/get-payout-methods`)(
        t.type({
          body: t.array(
            t.type({
              id: t.string,
              methodCode: t.string,
              typeEnum: t.string,
              nameEn: t.string,
              nameVi: t.string
            })
          )
        })
      )
    )

  export const getAccidentName = () =>
    pipe(
      POApi.get(`wf-api/table/t57/get-all`)(
        t.type({
          body: t.array(VerClaimType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getTotalPermanentDisability = (inputValue?: string) =>
    pipe(
      POApi.get(`wf-api/table/t56/get-all`)(
        t.type({
          body: t.array(VerClaimType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getCritialIllness = (inputValue?: string) =>
    pipe(
      POApi.get(`wf-api/table/t54/get-all`)(
        t.type({
          body: t.array(CriticalIllnessType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getDecision = (child: string) =>
    pipe(
      POApi.getConfig({ params: { child: child } })(`wf-api/table/t55/get-all?`)(
        t.type({
          body: t.array(DecisionType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getICDName = (inputValue?: string) =>
    pipe(
      POApi.get(`wf-api/table/t51/get-all`)(
        t.type({
          body: t.array(ICDType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getHospitals = () =>
    pipe(
      POApi.get(`wf-api/table/t52/get-all`)(
        t.type({
          body: t.array(HospitalType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getClaimType = () =>
    pipe(
      POApi.get(`wf-api/table/t50s0/get-all`)(
        t.type({
          body: t.array(ClaimType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getClaimNumber = (uuid: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/claim/generation-claim-number?sourceOfBusiness=DIGITAL&exchangeId=${uuid}`)(
        t.type({
          claimNumber: t.string
        })
      ),
      ZIO.map((res) => res.claimNumber)
    )

  export const getSubClaimType = () =>
    pipe(
      POApi.get(`wf-api/table/t50s1/get-all`)(
        t.type({
          body: t.array(SubClaimType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getClaims = pipe(
    POApi.get(`wf-api/table/t0s6/get-all`)(
      t.type({
        body: t.array(ClaimTypeInfo)
      })
    ),
    ZIO.map((res) => res.body)
  )

  export const getRelationship = () =>
    pipe(
      POApi.get(`wf-api/table/t16s1/get-all`)(
        t.type({
          body: t.array(Relationships)
        })
      ),
      ZIO.map((res) =>
        res.body.map((rela) => ({
          ...rela,
          relationshipEN: rela.relationshipEN || rela.nameEn || '',
          relationshipVN: rela.relationshipVN || rela.nameVn || '',
          nameEn: rela.relationshipEN || rela.nameEn || '',
          nameVn: rela.relationshipVN || rela.nameVn || ''
        }))
      )
    )

  export const getRelationshipt16s2 = pipe(
    POApi.get(`wf-api/table/t16s2/get-all`)(
      t.type({
        body: t.array(RelationshipT16s2)
      })
    ),
    ZIO.map((res) => res.body)
  )

  export const getRelationshipt16s3 = pipe(
    POApi.get(`wf-api/table/t16s3/get-all`)(
      t.type({
        body: t.array(
          t.type({
            code: t.string,
            id: t.string,
            la1: t.string,
            la2: t.string,
            la3: t.string,
            la4: t.string,
            relationshipEN: t.string
          })
        )
      })
    ),
    ZIO.map((res) => res.body)
  )

  export const getSubClaimTypeBenefit = () =>
    pipe(
      POApi.get(`wf-api/table/t50s2/get-all`)(
        t.type({
          body: t.array(SubClaimTypeBenefit)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getClaimSubBenefit = pipe(
    POApi.get(`wf-api/table/t50s3/get-all`)(
      t.type({
        body: t.array(ClaimSubBenefit)
      })
    ),
    ZIO.map((res) => res.body)
  )

  export const getReceiptData = () =>
    pipe(
      POApi.get(`wf-api/table/t50s5/get-all`)(
        t.type({
          body: t.array(ReceiptType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getSurgeryData = () =>
    pipe(
      POApi.get(`wf-api/table/t59/get-all`)(
        t.type({
          body: t.array(SurgeryType)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const searchSurgeryDataByName = (textSearch: string) =>
    textSearch && textSearch.length
      ? pipe(
        POApi.get(`wf-api/table/t59/search-by-name/${textSearch}`)(
          t.type({
            body: t.array(SurgeryType)
          })
        ),
        ZIO.map((t) => t.body),
        ZIO.cached()
      )
      : getSurgeryData()

  export const getSurgeryDataByName =
    (translator: (valEn: string, valVi: string) => string) =>
      (inputValue: string): UIO<SelectOption[]> =>
        pipe(
          GeneralService.searchSurgeryDataByName(inputValue),
          ZIO.map((d) => d.map((res) => ({ value: res.id, label: translator(res.nameEn, res.nameVn) }))),
          ZIO.provide({ inputValue }),
          ErrorHandling.catchAll([])
        )

  export const tpdSearchOptions = (inputValue: string): UIO<SelectOption[]> =>
    pipe(
      GeneralService.getTotalPermanentDisability(inputValue),
      ZIO.map((d) =>
        d
          .filter((option) =>
            i18next.language === 'en'
              ? option.nameEn.toLowerCase().includes(inputValue.toLowerCase())
              : option.nameVi.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((option) => ({
            value: option.code,
            label: `${option.code} - ${i18next.language === 'en' ? option.nameEn : option.nameVi}`
          }))
      ),
      ZIO.provide({ inputValue }),
      ErrorHandling.catchAll([])
    )
  export const ciSearchOptions = (inputValue: string): UIO<SelectOption[]> =>
    pipe(
      GeneralService.getCritialIllness(inputValue),
      ZIO.map((d) =>
        d
          .filter((option) =>
            i18next.language === 'en'
              ? option.nameEn.toLowerCase().includes(inputValue.toLowerCase())
              : option.nameVn.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((option) => ({
            value: option.cilCode,
            label: `${option.cilCode} - ${i18next.language === 'en' ? option.nameEn : option.nameVn}`
          }))
      ),
      ZIO.provide({ inputValue }),
      ErrorHandling.catchAll([])
    )
  export const icdSearchOptions = (inputValue: string): UIO<SelectOption[]> =>
    pipe(
      GeneralService.getICDName(inputValue),
      ZIO.map((d) =>
        d
          .filter((option) =>
            i18next.language === 'en_US'
              ? option.diseaseNameEn.toLowerCase().includes(inputValue.toLowerCase())
              : option.diseaseNameVn.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((option) => ({
            value: option.icdcode,
            label: `${i18next.language === 'en_US' ? option.diseaseNameEn : option.diseaseNameVn}`
          }))
      ),
      ZIO.provide({ inputValue }),
      ErrorHandling.catchAll([])
    )

  export const getDiagnostic = () =>
    pipe(
      POApi.get(`wf-api/table/t51/get-all`)(
        t.type({
          body: t.array(
            t.type({
              diseaseNameEn: t.string,
              diseaseNameVn: t.string,
              icdcode: t.string,
              id: t.string
            })
          )
        })
      ),
      ZIO.map((t) => t.body),
      ZIO.cached()
    )

  export const searchDiagnostic = (textSearch: string) =>
    textSearch && textSearch.length
      ? pipe(
        POApi.get(`wf-api/table/t51/search-by-name/${textSearch}`)(
          t.type({
            body: t.array(
              t.type({
                diseaseNameEn: t.string,
                diseaseNameVn: t.string,
                icdcode: t.string,
                id: t.string
              })
            )
          })
        ),
        ZIO.map((t) => t.body),
        ZIO.cached()
      )
      : getDiagnostic()

  export const getHospital = () =>
    pipe(
      POApi.get(`wf-api/table/t52/get-all`)(
        t.type({
          body: t.array(
            t.type({
              nameEn: t.string,
              nameVn: t.string,
              code: t.string,
              id: t.string
            })
          )
        })
      ),
      ZIO.map((t) => t.body)
    )

  export const getDocumentType = pipe(
    POApi.get(`wf-api/table/t53/get-all`)(
      t.type({
        body: t.array(
          t.type({
            claimCode: t.string,
            claimType: t.string,
            claimTypeVn: t.string,
            docTypeCode: t.string,
            docTypeEn: t.string,
            docTypeVn: t.string,
            id: t.string,
            originalCopied: t.string,
            originalDocument: t.string,
            required: t.string
          })
        )
      })
    ),
    ZIO.map((d) => d.body),
    ZIO.cached()
  )

  export const getListDocs = (claimTypeCode: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/filenet/get-claim-document-matrix?claimTypeCode=${claimTypeCode}`)(
        t.type({
          data: t.array(
            t.type({
              claimType: t.string,
              claimTypeCode: t.string,
              docTypeCode: t.string,
              docTypeEN: t.string,
              docTypeVN: t.string,
              required: t.string
            })
          )
        })
      ),
      ZIO.map((res) => res.data)
    )

  export const getListOptional = (transactionType: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/filenet/get-optional-document?transactionType=${transactionType}`)(
        t.type({
          data: t.array(
            t.type({
              doctypeVn: t.string,
              doctypeEn: t.string,
              docTypeCode: t.string
            })
          )
        })
      ),
      ZIO.map((res) => res.data)
    )

  export const getOfficeCodes = (type: string) =>
    pipe(
      POApi.get(`wf-api/table/t33/get-office`)(
        t.type({
          // POApi.get(`wf-api/table/t33/get-office`)(t.type({
          body: t.array(OfficeCode)
        })
      ),
      ZIO.map((res) => {
        return res.body.filter((element) => element.type === type)
      })
    )

  export const getALLOfficeList = () =>
    pipe(
      POApi.get(`wf-api/table/t33/get-office`)(
        t.type({
          // POApi.get(`wf-api/table/t33/get-office`)(t.type({
          body: t.array(OfficeCode)
        })
      ),
      ZIO.map((res) => {
        return res.body
      })
    )

  export const getOfficeCode = (code: string) =>
    pipe(
      POApi.getConfig({ params: { code } })(`wf-api/table/t33/get-office`)(
        t.type({
          // POApi.get(`wf-api/table/t33/get-office`)(t.type({
          body: t.array(OfficeCode)
        })
      )
    )

  export const getOfficeCodeGA = (code: string) =>
    pipe(
      POApi.get(`wf-api/table/tv604-office/${code}`)(
        t.type({
          body: t.array(
            t.type({
              code: t.string,
              shortName: t.string
            })
          )
        })
      )
    )

  export const getOfficeCashOut = () =>
    pipe(
      POApi.get(`wf-api/table/t33/get-office`)(
        t.type({
          // POApi.get(`wf-api/table/t33/get-office`)(t.type({
          body: t.array(OfficeCode)
        })
      ),
      ZIO.map((res) => {
        return res.body.filter(
          (element) =>
            element.type === 'GA' ||
            element.type === 'TO' ||
            element.code === 'PRU' ||
            element.code === 'VCO' ||
            element.code === 'DNG' ||
            element.code === 'HCM' ||
            element.code === 'NTG'
        )
      })
    )

  export const GetMetaDataByDepartmentType = (departmentShortName: string, docTypeCode: string, officeCode: string) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/filenet/department/get-metadata?departmentShortName=${departmentShortName}&docTypeCode=${docTypeCode}`
      )(
        t.type({
          data: t.type({
            transactionType: t.string,
            doctypeVn: t.string,
            doctypeEn: t.string,
            docTypeCode: t.string,
            classFilenet: t.string,
            docID: t.string,
            mainDoc: t.string,
            subDoc: t.string,
            batchNo: Maybe(t.string),
            type: t.string,
            refNum: Maybe(t.string),
            trigger: Maybe(t.string),
            doctypeBPM: Maybe(t.string)
          })
        })
      ),
      ZIO.map((metaData) => {
        // const currentDateFormat = moment().format('YYYYMMDD')
        // const batchNo = metaData.data.type + '-' + officeCode + '-' + currentDateFormat
        // return metaData.data
        const currentDateFormat = moment().format('DD/MM/YYYY')
        const batchNo = currentDateFormat + '-' + metaData.data.type + '-' + officeCode
        return {
          data: { ...metaData.data, batchNo }
        }
      })
    )

  export const getComponent = () =>
    pipe(
      POApi.get(`wf-api/table/t58s2/get-all`)(
        t.type({
          body: t.array(ComponentPolicy)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getBenefits = () =>
    pipe(
      POApi.get(`wf-api/table/t58s1/get-all`)(
        t.type({
          body: t.array(BenefitClaim)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getEvaluationData = () =>
    pipe(
      POApi.get(`wf-api/table/t55s9/get-all`)(
        t.type({
          body: t.array(EvaluationInfo)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getDeclineReason = () =>
    pipe(
      POApi.get(`wf-api/table/t60/get-all`)(
        t.type({
          body: t.array(DeclineReason)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getAllSubBenefit = () =>
    pipe(
      POApi.get(`wf-api/table/t50s4/get-all`)(
        t.type({
          body: t.array(SubBenefit)
        })
      ),
      ZIO.map((res) => res.body)
    )

  export const getHistoryLetter = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/claim/final-letter/get-final-letter?processInstanceId=${processInstanceId}`)(
        t.type({
          data: Maybe(t.array(HistoryLetter))
        })
      ),
      ZIO.map((res) => res.data)
    )

  export const saveLogFinal = (
    policyNumber: string,
    documentDetails: Document[],
    processInstanceId: string,
    taskId: string,
    author: string
  ) =>
    pipe(
      POApi.post(`pulseops/api/v1/claim/final-letter/save-final-letter`)(LogFinalLetter)({
        policyNumber,
        documentDetails,
        processInstanceId,
        taskId,
        author
      }),
      ZIO.map((policy) => policy)
    )

  export const getListHospital = pipe(
    POApi.get(`wf-api/table/t52/get-all`)(
      t.type({
        body: t.array(
          t.type({
            code: t.string,
            nameVn: t.string,
            nameEn: t.string,
            exclusion: t.string
          })
        )
      })
    ),
    ZIO.map((res) => res.body)
  )

  export const updateEligible = (
    processInstanceId: string,
    policyNumber: string,
    claimType: string,
    subClaimType: string,
    haveAccidentalBenefit: string,
    haveTPDorCIorICD: string[],
    haveICUBenefit: string,
    durationICU: string,
    durationHospitalization: string,
    haveEventDate: string,
    businessKey: string,
    taskId: string,
    source: string,
    author: string,
    detailList: {
      id: string
      benefitClaimEN: string
      benefitClaimWF: string
      benefitClaimVN: string
      benefitClaimCode: string
      claimAmount: string
      eligible: boolean
      sumAssure: string
      componentCode: string
    }[]
  ) =>
    pipe(
      POApi.post(`claim-rest/api/update-eligible`)(t.unknown)({
        processInstanceId,
        policyNumber,
        data: {
          claimType,
          subClaimType,
          haveAccidentalBenefit,
          haveTPDorCIorICD,
          haveICUBenefit,
          durationICU,
          durationHospitalization,
          haveEventDate
        },
        taskId,
        source,
        author,
        businessKey,
        detailList
      })
    )

  export const getPolicyRiderInfo = (
    processInstanceId: string,
    policyNumber: string,
    claimType: string,
    subClaimType: string,
    haveAccidentalBenefit: string,
    haveTPDorCIorICD: string[],
    haveICUBenefit: string,
    durationICU: string,
    durationHospitalization: string,
    haveEventDate: string,
    admissionDate: string,
    businessKey: string,
    claimActivity: string,
    claimNo: string,
    clientNumber: string,
    dischargeDate: string,
    claimEventDate: string,
    effectiveDate: string
  ) =>
    pipe(
      POApi.post(`claim-rest/api/get-policy-rider-info`)(
        t.type({
          eligibleResponse: Maybe(
            t.type({
              data: Maybe(t.array(BenefitClaim)),
              status: Maybe(t.type({ code: Maybe(t.number) }))
            })
          ),
          claimCaseDuplicateHistory: Maybe(
            t.type({
              claimCaseDuplicatedList: Maybe(ClaimCaseDuplicatedList)
            })
          )
        })
      )({
        eligibleRequest: {
          processInstanceId,
          policyNumber,
          data: {
            claimType,
            subClaimType,
            haveAccidentalBenefit,
            haveTPDorCIorICD,
            haveICUBenefit,
            durationICU,
            durationHospitalization,
            haveEventDate
          }
        },
        admissionDate,
        businessKey,
        claimActivity,
        claimNo,
        claimType,
        clientNumber,
        dischargeDate,
        policy: policyNumber,
        processInstanceId,
        claimEventDate,
        effectiveDate
      }),
      ZIO.map((res) => res)
    )

  export const getCalculationRule = (
    processInstanceId: string,
    taskId: string,
    source: string,
    author: string,
    policyNumber: string,
    productCode: string,
    planName: string,
    componentCode: string,
    benefitClaimCode: string,
    durationICU: string,
    durationHospitalization: string,
    sumAssured: string,
    benefitClaimAmount: string
  ) =>
    pipe(
      POApi.post(`claim-rest/api/get-calculation-rule`)(
        t.type({
          data: Maybe(
            t.type({
              currentTime: Maybe(t.number),
              currentDuration: Maybe(t.number),
              percentage: Maybe(t.number),
              benefitClaimAmount: Maybe(t.number),
              benefitClaimFormula: Maybe(t.string),
              benefitClaimType: Maybe(t.string),
              declineCode: Maybe(t.string),
              declineVN: Maybe(t.string),
              declineEN: Maybe(t.string)
            })
          )
        })
      )({
        processInstanceId,
        taskId,
        source,
        author,
        policyNumber,
        productCode,
        planName,
        componentCode,
        benefitClaimCode,
        durationICU,
        durationHospitalization,
        sumAssured,
        benefitClaimAmount
      }),
      ZIO.map((res) => res.data)
    )

  export const getPvNumber = (paymentType: string, idNumber: string, businessKey: string) =>
    pipe(
      POApi.post(`wf-api/general/data/get-pv-number`)(
        t.type({
          body: t.string
        })
      )({
        body: {
          paymentType,
          idNumber,
          businessKey
        }
      }),
      ZIO.map((res) => res.body)
    )

  export const getRemainingBenefit = (
    processInstanceId: string,
    taskId: string,
    source: string,
    author: string,
    policyNumber: string,
    productCode: string,
    planName: string,
    componentCode: string,
    benefitClaimCode: string,
    durationICU: string,
    durationHospitalization: string,
    sumAssured: string,
    benefitClaimAmount: string,
    claimDecisionCode: string
  ) =>
    pipe(
      POApi.post(`claim-rest/api/get-remaining-benefit`)(
        t.type({
          claimNumberOfDate: Maybe(t.number),
          acceptedDateInHistory: Maybe(t.number),
          remainingDate: Maybe(t.number),
          acceptedDateOfClaim: Maybe(t.number),
          benefitClaimType: Maybe(t.string)
        })
      )({
        processInstanceId,
        taskId,
        source,
        author,
        policyNumber,
        productCode,
        planName,
        componentCode,
        benefitClaimCode,
        durationICU,
        durationHospitalization,
        sumAssured,
        benefitClaimAmount,
        claimDecisionCode
      })
    )

  export const saveBenefitClaimDecision = (
    policyNumber: string,
    detailList: {
      productCode: string
      planName: string
      componentCode: string
      benefitClaimCode: string
      sumAssured: string
      adjudicationDecision: string
      durationICU: string
      durationHospitalization: string
      benefitClaimAmount: string
    }[],
    source: string,
    processInstanceId: string,
    businessKey: string,
    author: string
  ) =>
    pipe(
      POApi.post(`claim-rest/api/save-benefit-claim-decision`)(
        t.type({
          claimNumberOfDate: Maybe(t.number),
          acceptedDateInHistory: Maybe(t.number),
          remainingDate: Maybe(t.number),
          acceptedDateOfClaim: Maybe(t.number)
        })
      )({
        policyNumber,
        detailList,
        source,
        processInstanceId,
        businessKey,
        author
      })
    )

  export const getCheckTraineeQualified = pipe(
    POApi.get(`wf-api/general/data/get-countries`)(
      t.type({
        body: t.array(GeneralData)
      })
    ),
    ZIO.map((policy) => policy.body)
  )
  export const getMetaFilesByAttachedFiles = (
    payoutDocuments: FileUploadData[],
    transactionType: string,
    docTypeCode: string,
    policyNumber: string,
    officeCode: string
  ) => {
    let files: StorageBlob.FileContent[] = []
    const transactionIDList = [TransactionType.FULL_SURRENDER, TransactionType.CHANGE_SUM_ASSURED]
    return pipe(
      ZIO.succeed(payoutDocuments),
      ZIO.flatMap((payoutDocFiles) =>
        !!payoutDocFiles && payoutDocFiles.length > 0
          ? pipe(
            GeneralService.getMetaData(transactionType, docTypeCode, officeCode),
            ZIO.map((metaDataRes) => {
              const metaDataRaw: StorageBlob.MetaDataUpload = {
                type: metaDataRes.data.transactionType,
                doctype: metaDataRes.data.doctypeEn,
                class: metaDataRes.data.classFilenet,
                docid: metaDataRes.data.docID,
                maindoc: metaDataRes.data.mainDoc,
                subdoc: metaDataRes.data.subDoc,
                polnum: policyNumber,
                batchno: metaDataRes.data.batchNo
              }
              files = payoutDocFiles.map((x) => {
                const fileItem = transactionIDList.includes(transactionType as TransactionType)
                  ? {
                    file: x.file!,
                    metaData: metaDataRaw,
                    id: x.uploadedDate.getTime()
                  }
                  : {
                    file: x.file,
                    metaData: metaDataRaw
                  }
                return fileItem
              })
              return files
            })
          )
          : ZIO.succeed(files)
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )
  }

  export const getAzureStorageFiles = (
    uploadFiles: FileUploadData[],
    transactionType: string,
    docTypeCode: string,
    category: string,
    policyNumber: string,
    officeCode: string,
    transactionCustomUrl?: string
  ) => {
    return pipe(
      getMetaFilesByAttachedFiles(uploadFiles, transactionType, docTypeCode, policyNumber, officeCode),
      ZIO.flatMap((metaDataFiles) =>
        metaDataFiles && metaDataFiles.length > 0
          ? StorageBlob.uploadToSubmit(category, policyNumber, undefined, transactionCustomUrl)(metaDataFiles)
          : ZIO.succeed([])
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )
  }

  export const getMetaFilesByAttachedFilesAgent = (
    payoutDocuments: FileUploadData[],
    transactionType: string,
    docTypeCode: string,
    agentNumber: string,
    officeCode: string
  ) => {
    let files: StorageBlob.FileContent[] = []
    return pipe(
      ZIO.succeed(payoutDocuments),
      ZIO.flatMap((payoutDocFiles) =>
        !!payoutDocFiles && payoutDocFiles.length > 0
          ? pipe(
              GeneralService.getMetaDataAgent(transactionType, docTypeCode, officeCode),
              ZIO.map((metaDataRes) => {
                const metaDataRaw: StorageBlob.MetaDataUpload = {
                  type: metaDataRes.data.transactionType,
                  doctype: metaDataRes.data.doctypeEn,
                  class: metaDataRes.data.classFilenet,
                  docid: metaDataRes.data.docID,
                  maindoc: metaDataRes.data.mainDoc,
                  subdoc: metaDataRes.data.subDoc,
                  agentnum: agentNumber,
                  batchno: metaDataRes.data.batchNo,
                  functionType: 'uploadDocument',
                  polnum: ''
                }
                files = payoutDocFiles.map((x) => {
                  const fileItem = {
                    file: x.file,
                    metaData: metaDataRaw
                  }
                  return fileItem
                })
                return files
              })
            )
          : ZIO.succeed(files)
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )
  }
  export const getAzureFilesForCashOut = (
    uploadFiles: FileUploadData[],
    transactionType: string,
    docTypeCode: string,
    category: string,
    policyNumber: string,
    officeCode: string
  ) => {
    return pipe(
      getMetaFilesByAttachedFiles(uploadFiles, transactionType, docTypeCode, policyNumber, officeCode),
      ZIO.flatMap((metaDataFiles) =>
        metaDataFiles && metaDataFiles.length > 0
          ? StorageBlob.uploadToSubmit(category, policyNumber)(metaDataFiles)
          : ZIO.succeed([])
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) =>
          ZIO.effect(() => {
            return {
              storageFiles: success,
              docTypeCode
            }
          })
      )
    )
  }

  export const getAzureStorageFilesAgent = (
    uploadFiles: FileUploadData[],
    transactionType: string,
    docTypeCode: string,
    category: string,
    agentNumber: string,
    officeCode: string
  ) => {
    return pipe(
      getMetaFilesByAttachedFilesAgent(uploadFiles, transactionType, docTypeCode, agentNumber, officeCode),
      ZIO.flatMap((metaDataFiles) =>
        metaDataFiles && metaDataFiles.length > 0
          ? StorageBlob.uploadToSubmit(category, agentNumber)(metaDataFiles)
          : ZIO.succeed([])
      ),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )
  }

  export const getPayoutDocuments = (payoutData: PayoutPopup.Summary[]) => {
    let payoutDocuments: FileUploadData[] = []
    payoutData.map((payoutItem) => {
      const relativeDocs = payoutItem.payee?.isPayeeNotPO ? payoutItem.payee.relativeDocument : []
      payoutDocuments = [...(relativeDocs as []), ...payoutDocuments]
    })
    return payoutDocuments
  }

  export const getAutoDecision = (autoDecisions: AutoDecision) =>
    pipe(
      POApi.post(`claim-rest/api/get-auto-decision`)(
        t.array(
          t.type({
            caseId: t.string,
            condition: t.string,
            autoDecision: t.string,
            declineReason: t.string,
            decisionCode: t.string
          })
        )
      )(autoDecisions)
    )

  export const getErrorMistake = (category: string, userGroup: string, isUWQC?: boolean) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/qc-score/get-qc-score-to-evaluate?category=${category}&userGroup=${userGroup}&activityBasket=${
          isUWQC ? 'UW-QC' : 'QC'
        }`
      )(
        t.array(
          t.type({
            errors: t.array(
              t.type({
                desc_en: t.string,
                desc_vi: t.string,
                error_id: t.string,
                score: t.number
              })
            ),
            topic: Maybe(t.string),
            topicVN: Maybe(t.string),
            maximumTopicMinusScore: Maybe(t.number),
            qcCode: Maybe(t.string)
          })
        )
      ),
      ZIO.map((res) => res)
    )

  export const getHistoryQCScore = (caseId: string, userGroup: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/get-qc-score-evaluation-history?caseId=${caseId}&userGroup=${userGroup}`)(
        t.type({
          remark: t.string,
          totalScore: Maybe(t.string),
          lastUser: Maybe(t.string),
          qcScoreDetailTopics: Maybe(
            t.array(
              t.type({
                errorId: t.string,
                minusScore: t.number,
                qcCheck: t.boolean
              })
            )
          )
        })
      ),
      ZIO.map((res) => res)
    )

  export const saveQCScoreVerification = (data: ScoreVerification) =>
    pipe(
      POApi.post(`pulseops/api/v1/qc-score/save-qc-score-evaluation-history`)(t.type({}))(data),
      ZIO.map((res) => res)
    )

  // export const getDublicateClaimList = (businessKey: string) =>
  //   pipe(
  //     POApi.get(`pulseops/api/v1/claim/get-duplicate-claim-list/${businessKey}`)(
  //       t.type({
  //         claimCaseDuplicatedList: ClaimCaseDuplicatedList
  //       })
  //     ),
  //     ZIO.map((res) => res.claimCaseDuplicatedList)
  //   )
  export const saveDublicateClaim = (data: ClaimNewCase) =>
    pipe(POApi.post(`pulseops/api/v1/claim/save-claim-duplicate-into-history`)(t.unknown)(data))
  export const triggerActionClaim = (businessKey: string) =>
    pipe(POApi.post(`pulseops/api/v1/claim/update-new-case-to-check-duplicate/${businessKey}`)(t.unknown)({}))

  // export const getUsersGroupsQCTa
  export const getUserGroupLevel = (category: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/permission/qc/group-level?category=${category}`)(
        // t.array(t.type({
        //   userGroupCode: Maybe(t.string),
        //   qcGroupCode: Maybe(t.string),
        //   level: Maybe(t.number),
        // }))
        t.type({
          qcGroupLevels: t.array(t.string),
          manager: t.boolean
        })
      ),
      ZIO.map((item) => item)
    )
  export const getUserQCGroupLevelByCategory = (category: string, transactionType: string, activityBasket: string) =>
    pipe(
      getUserGroupLevel(category),
      ZIO.flatMap((groupLevelData) => {
        // 1 user hase only 1 user QC group level on prod
        const userQCGroupLevel =
          groupLevelData && groupLevelData.qcGroupLevels.length > 0 ? groupLevelData.qcGroupLevels[0] : ''
        const transferData: TransferData = {
          transactionType: transactionType,
          category: category,
          activityBasket: activityBasket,
          userQCGroupLevel: userQCGroupLevel,
          manager: groupLevelData.manager
        }
        return getQCGroupLevelToTransfer(transferData)
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      })
    )

  export const getQCGroupLevelToTransfer = (transferData: TransferData) =>
    pipe(
      POApi.post(`pulseops/api/v1/manual-transfer/qc-config`)(
        Maybe(
          t.array(
            t.type({
              userQCGroupLevelToTransfer: t.string
            })
          )
        )
      )({
        transactionType: transferData.transactionType,
        category: transferData.category,
        activityBasket: transferData.activityBasket,
        userQCGroupLevel: transferData.userQCGroupLevel,
        manager: transferData.manager
      }),
      ZIO.map((response) => {
        const userQCGroupLevel = !!response ? response : []
        return userQCGroupLevel
      })
    )
  export const getUsersByGroupsQC = (groups: string[]) =>
    pipe(
      POApi.get(`pulseops/api/v1/manual-transfer/qc-users?qcGroupLevel=${groups}`)(
        t.type({
          data: t.array(
            // t.type({
            //   name: t.string,
            //   email: t.string,
            //   status: t.boolean
            // })
            AssignmentUserInfo
          )
        })
      ),
      ZIO.map((policy) => policy.data)
    )
  export const generalPaymentRef = (claimNo: string, createdDate: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/claim/generate-payment-ref/?claimNo=${claimNo}&createdDate=${createdDate}`)(
        t.type({ paymentRef: t.string })
      ),
      ZIO.map((res) => res)
    )

  export const getVariables = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/history/historic-process-instances/variables/${processInstanceId}`)(
        t.type({
          variables: t.type({
            paymentRef: t.string
          })
        })
      ),
      ZIO.map((response) => {
        return response.variables
      })
    )
  export const getListClaimNo = (policyNum: string) =>
    pipe(
      POApi.get(`claim-rest/api/claim-history/get-list-claim-number/${policyNum}`)(
        t.array(
          t.type({
            businessKey: t.string,
            claimNumber: t.string,
            parentProcessInstanceId: t.string
          })
        )
      ),
      ZIO.map((data) => data)
    )

  export const getOccupationDetailByCode = (code: string) => {
    return pipe(
      POApi.get(`wf-api/table/t80s1/get-by-code?code=${code}`)(
        t.type({
          body: Maybe(
            t.type({
              id: t.string,
              code: t.string,
              classOccupation: t.string,
              nameEn: t.string,
              nameVn: t.string,
              isEnableCompanyInfo: t.string,
              positionCode: t.string,
              positionName: t.string
            })
          ),
          responseStatus: Maybe(
            t.type({
              code: Maybe(t.number),
              message: Maybe(t.string)
            })
          )
        })
      ),
      ZIO.map((res) => {
        return res.body
      })
    )
  }
  export const getVisiableReportScreenStatus = () =>
    pipe(
      POApi.get(`pulseops/api/v1/reporting/get-reports-available-status`)(
        t.type({
          data: t.array(ReportStatusInfo)
        })
      ),
      ZIO.foldM(
        (_) => ZIO.succeed(false),
        (success) =>
          ZIO.effect(() => {
            const isSubmisisonReport = success.data
              ? pipe(
                  success.data.find((x) => x.reportCode === 'SUBMISSION_REPORT'),
                  O.fromNullable,
                  O.map((item) => item.activeReport),
                  O.getOrElse(() => false)
                )
              : false
            return isSubmisisonReport
          })
      )
    )
}
