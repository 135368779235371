import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ClientName } from './CepCommonModels'

export interface policySearch {
  start: number
  size: number
  data: {
    policyNo: string
    poIDNumber: string
    poMobilePhone: string
    poClientNumber: string
    officeCode?: string
  }
}

export const PolicySearchItem = t.type({
  policyNumber: Maybe(t.string),
  policyStatus: Maybe(t.string),
  premiumStatus: Maybe(t.string),
  clientNumber: Maybe(t.string),
  proposalNo: Maybe(t.string),
  policyOwnerName: Maybe(ClientName),
  mainLifeAssuredName: Maybe(ClientName),
  idnumber: Maybe(t.string),
  roles: Maybe(t.array(t.string))
})

export type PolicySearchItem = t.TypeOf<typeof PolicySearchItem>

export const IPolicySearch = t.type({
  policyNumber: Maybe(t.string),
  policyStatus: Maybe(t.string),
  premiumStatus: Maybe(t.string),
  clientNumber: Maybe(t.string),
  policyOwnerName: Maybe(t.string),
  mainLifeAssuredName: Maybe(t.string),
  idnumber: Maybe(t.string),
  roles: Maybe(t.array(t.string)),
  proposalNo: Maybe(t.string)
})

export type IPolicySearch = t.TypeOf<typeof IPolicySearch>

export const policySearchResponse = t.type({
  data: t.type({
    policies: Maybe(t.array(PolicySearchItem)),
    gacodeInValid: t.boolean
  }),
  size: Maybe(t.number),
  start: Maybe(t.number),
  total: Maybe(t.number)
})

export type policySearchResponse = t.TypeOf<typeof policySearchResponse>
