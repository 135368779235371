import { SubmissionService } from '../SubmissionService'
import { Customer } from './Client'

export namespace ReinstatementModel {
  type PolicyType = SubmissionService.PolicyType
  export interface laClient {
    attributesExt: {
      CUR_OCCUPATION: string
    }
    clientId: string
    name: string
  }

  export interface ReinData {
    attributesExt: {
      AGENT_CODE: string
      AGENT_NAME: string
      APL: number
      FREQUENCY?: string
      INSTALLMENT_PREM?: number
      LAPSED_DATE: string
      OPL: number
      REINS_FEE?: number
      RISK_COMM_DATE: string
      TOTAL_REINS_AMOUNT?: number
      TRANS_CODE: string
    }
    lifeAssured: laClient[]
  }

  export interface Detail {
    owner: Customer
    reinData: ReinData
  }

  export interface LifeAssured {
    name: string
    clientId: string
    attributesExt: {
      IS_CHANGE_HEALTH_STATE: boolean
      IS_OCCUPATION_CHANGE: boolean
      IS_ACTIVITY_CHANGE: boolean
      IS_EXIST_REQUEST_COMPANY: boolean
      CUR_OCCUPATION: string
      NEW_OCCUPATION: string
      NEW_ACTIVITY: string
      COMPANY_NAME: string
    }
  }
  export interface AccessData {
    isPoDeath: boolean
    isMainLADeath: boolean
    policy: PolicyType
    reinData: ReinData
  }

  export interface ReinstatementSubmitData {
    policyNo: string
    // attachmentFiles: StorageBlob.FileContent[]
    owners: {
      clientId: string
    }
    lifeAssured: LifeAssured[]
    attributesExt: {
      TRANS_CODE: string
      AGENT_CODE: string
      AGENT_NAME: string
      // AUTHEN_OPTION: string, //1,2,3 ~ OTP, SIGN ON APP, ATTACT REQEUST FORM
      // OFFICE_CODE: string,
      RISK_COMM_DATE: string // YYYYMMDD -  ngay hieu luc hien tai
      LAPSED_DATE: string // ngay mat hieu luc
      FREQUENCY: string // dinh ky
      INSTALLMENT_PREM: number // phi bao hiem dinh ky
      APL: number // tam ung nop phi tu dong
      OPL: number // tam ung gia tri hoan lai
      REINS_FEE: number
      TOTAL_REINS_AMOUNT: number
    }
  }

}
