import { Paper } from '@material-ui/core'
import {
  NbuwService,
  SectionHeader,
  Table,
  TableFooterPagination,
  TableStatus,
  Text,
  VerificationDecisionChange,
  View
} from '@pulseops/business/core'
import { Format, subscribe } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../contract-detail-context'
import { SC } from '../sc'

export const VerificationHistory = () => {
  const { t } = useTranslation('business')
  const { contractId } = React.useContext(ContractDetailContext)
  const service = NbuwService.forContract(contractId)

  const history: VerificationDecisionChange[] = pipe(service.state.verificationDecision.watchHistory, subscribe([]))
  const [rows, setRows] = React.useState<VerificationDecisionChange[]>([])

  const [sizeTable, setSizeTable] = React.useState(10)
  const [pageTable, setPageTable] = React.useState(0)

  React.useEffect(() => {
    setRows(history.slice(pageTable * sizeTable, pageTable * sizeTable + sizeTable))
  }, [sizeTable, pageTable, history])

  return (
    <SC.BorderContainer>
      <SectionHeader>{t('verification_decision_history')}</SectionHeader>
      <Table.Container component={Paper}>
        <Table.Main>
          <Table.Head>
            <Table.Row>
              <Table.CellHead>{t('paper_requirement')}</Table.CellHead>
              <Table.CellHead>{t('old_value')}</Table.CellHead>
              <Table.CellHead>{t('new_value')}</Table.CellHead>
              <Table.CellHead>{t('change_by')}</Table.CellHead>
              <Table.CellHead>{t('change_date')}</Table.CellHead>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <TableStatus colSpan={5} rows={rows} />
            {rows?.map((row, i) => (
              <Table.Row key={i}>
                <Table.Cell>{t(row.docId)}</Table.Cell>
                <Table.Cell>
                  {row.oldValues.map((val, j) => (
                    <View key={j}>
                      <Text>{val}</Text>
                    </View>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {row.newValues.map((val, j) => (
                    <View key={j}>
                      <Text>{val}</Text>
                    </View>
                  ))}
                </Table.Cell>
                <Table.Cell>{row.createdBy}</Table.Cell>
                <Table.Cell>{Format.datetime(row.createdDate)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooterPagination
            total={history.length}
            size={sizeTable}
            page={pageTable}
            onPageChange={setPageTable}
            onSizeChange={setSizeTable}
            colspan={5}
          />
        </Table.Main>
      </Table.Container>
    </SC.BorderContainer>
  )
}
