import { SelectOption } from '@pulseops/common'

export const COLORS = {
  SNOW: '#fafafa',
  HOWKES_BLUE: '#d3dce6',
  ALIZARIN: '#ED1B2E',
  WHITE: '#ffff',
  ROLLING_STONE: '#70777e',
  BLACK: '#000000',
  MYSTIC: '#E5EAEF',
  BISCAY: '#1B365D'
}

export enum TypeFieldEnum {
  TEXTBOX = 'TEXTBOX',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  DATE_PICKER = 'DATE_PICKER',
  RADIO = 'RADIO'
}

export type typeField = `${TypeFieldEnum}`
export type typeNameField = 'departmentCode' | 'group' | 'mainDoc' | 'subDoc' | 'fromDate' | 'toDate'

export type GroupsViewType<TN> = {
  type: typeField
  formName: TN
  required: boolean
  label: string
  options?: SelectOption[]
  placeholder: string
  popupIcon?: string
  editable: boolean
  rules?: object
}

export const groupsViewsConst: (t:any) => GroupsViewType<typeNameField>[] = (t:any) => [
  {
    formName: 'departmentCode',
    type: TypeFieldEnum['DROPDOWN_LIST'],
    placeholder: '-',
    label: t('utilities:department'),
    required: false,
    editable: true,
    options: [],
    rules: {}
  },
  {
    formName: 'group',
    type: TypeFieldEnum['DROPDOWN_LIST'],
    placeholder: '-',
    label: t('utilities:group'),
    required: false,
    editable: true,
    options: [],
    rules: {}
  },
  {
    formName: 'mainDoc',
    type: TypeFieldEnum['DROPDOWN_LIST'],
    placeholder: '-',
    label: t('utilities:title'),
    required: false,
    editable: true,
    options: [],
    rules: {}
  },
  {
    formName: 'subDoc',
    type: TypeFieldEnum['DROPDOWN_LIST'],
    placeholder: '-',
    label: t('utilities:description'),
    required: false,
    editable: true,
    options: [],
    rules: {}
  },
  {
    formName: 'fromDate',
    type: TypeFieldEnum['DATE_PICKER'],
    placeholder: '-',
    label: t('ServiceInquiry:FromDate'),
    required: false,
    editable: true,
    options: [],
    rules: {}
  },
  {
    formName: 'toDate',
    type: TypeFieldEnum['DATE_PICKER'],
    placeholder: '-',
    label: t('ServiceInquiry:ToDate'),
    required: false,
    editable: true,
    options: [],
    rules: {}
  }
]

export enum TypeActionEnum {
  SEARCH = 'SEARCH',
  CLEAR = 'CLEAR'
}

export type TypeAction = `${TypeActionEnum}`

export const actions = (t: any) => [
  {
    type: 'SEARCH',
    text: t('common:Search'),
    textColorEnable: COLORS.WHITE,
    bgColorEnable: COLORS.ALIZARIN,
    borderColorEnable: COLORS.ALIZARIN
  },

  {
    type: 'CLEAR',
    text: t('common:ClearAll'),
    textColorEnable: COLORS.ALIZARIN,
    bgColorEnable: COLORS.WHITE,
    borderColorEnable: COLORS.ALIZARIN
  }
]

export const titleHeaders = (t: any) => [
  t('utilities:department'),
  t('utilities:group'),
  t('utilities:title'),
  t('utilities:description'),
  t('utilities:updateDate'),
  t('utilities:action')
]
