import { useMobile } from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, TextStyle, StyleProp, ViewStyle } from 'react-native'
import { RadioButtonGroup } from './RadioBtn'
import { Checkbox } from '@pulseops/submission/common'

export namespace FieldList {
  export type Value = string | number | boolean | JSX.Element

  export type RenderProps = { index?: number }

  type FieldTypeBase = {
    index?: number
    required?: boolean
    render?: (props: RenderProps) => JSX.Element // render function just support for InfoViewItem field type
    labelStyle?: StyleProp<TextStyle>
    bold?: boolean
  }

  export type FieldTypeInput = {
    fieldType?: 'input'
    label: string | number | boolean | JSX.Element | TFunctionResult
    value?: Value
    suffix?: string | number | boolean | JSX.Element
    isHighlight?: boolean
    isHighlightWarning?: boolean
    colLength?: string
    visible?: boolean
    viewStyle?: StyleProp<ViewStyle>
  } & FieldTypeBase

  export type FieldTypeRadio = {
    fieldType: 'radio'
    label: string
    value: string
    radioOptions: { label: string; id: string }[]
    colLength?: string
    visible?: boolean
  } & FieldTypeBase

  export type FieldTypeCheckbox = {
    fieldType?: 'checkbox'
    label: string
    value?: boolean
    suffix?: string | number | boolean | JSX.Element
    isHighlight?: boolean
    isHighlightWarning?: boolean
    colLength?: string
    visible?: boolean
  } & FieldTypeBase

  export type FieldType = FieldTypeInput | FieldTypeRadio | FieldTypeCheckbox

  export type DataSource = FieldType

  export type DataSources = DataSource[]
}

const InfoViewItemMobile: React.FC<FieldList.FieldTypeInput> = (props) => {
  const { index, label, value, suffix, isHighlight, required, bold, labelStyle, render, isHighlightWarning } = props
  return (
    <View style={mobileStyle.item}>
      <Text style={[mobileStyle.label, labelStyle]}>
        {label}
        {required && <Text style={[styles.label, { color: 'red', marginStart: 4 }]}>*</Text>}
      </Text>
      {render ? (
        render({ index })
      ) : (
        <Text
          style={[
            mobileStyle.value,
            isHighlight && styles.highLight,
            isHighlightWarning && styles.highLightWarning,
            bold && { fontWeight: 'bold' }
          ]}
        >
          {value ? value : '-'} {suffix}
        </Text>
      )}
    </View>
  )
}

const InfoViewItem: React.FC<FieldList.FieldTypeInput> = (props) => {
  const { isMobile } = useMobile()
  const {
    index,
    label,
    value,
    suffix,
    isHighlight,
    colLength,
    required,
    bold,
    labelStyle,
    render,
    viewStyle,
    isHighlightWarning
  } = props

  return isMobile ? (
    <InfoViewItemMobile {...props} />
  ) : (
    <View
      style={[
        colLength
          ? {
              width: colLength,
              marginBottom: 32
            }
          : styles.col,
        viewStyle
      ]}
    >
      <Text style={[styles.label, labelStyle]}>
        {label}
        {required && <Text style={[styles.label, { color: 'red', marginStart: 4 }]}>*</Text>}
      </Text>
      {render ? (
        render({ index })
      ) : (
        <Text
          style={[
            styles.value,
            isHighlight && styles.highLight,
            isHighlightWarning && styles.highLightWarning,
            ,
            bold && { fontWeight: 'bold' }
          ]}
        >
          {value ? value : '-'} {suffix}
        </Text>
      )}
    </View>
  )
}

export const FieldList = ({
  dataSource,
  titleStyle,
  viewStyle
}: {
  dataSource: FieldList.DataSource[]
  titleStyle?: TextStyle
  viewStyle?: StyleProp<ViewStyle>
}) => {
  const { isMobile } = useMobile()
  const { t } = useTranslation()

  let xhtml: any[] = [<Text style={styles.noData}>{t('common:Nodata')}</Text>]
  if (dataSource?.length > 0) {
    xhtml = dataSource.map((data, i) => {
      if (data.visible === false) {
        return null
      }

      return data.fieldType === 'radio' ? (
        <RadioButtonGroup
          title={data.label}
          titleStyle={titleStyle}
          options={data.radioOptions}
          value={data.value}
          disabled
          required={data.required}
          colLength={data.colLength}
          key={`radio-btn-${i}`}
        />
      ) : data.fieldType === 'checkbox' ? (
        <Checkbox key={`checkbox-${i}`} value={data.value} title={data.label} style={{ marginEnd: 50 }} />
      ) : (
        <InfoViewItem
          key={i}
          index={i}
          required={data.required}
          label={data.label}
          value={data.value}
          suffix={data.suffix}
          isHighlight={data.isHighlight}
          isHighlightWarning={data.isHighlightWarning}
          bold={data.bold}
          colLength={data.colLength}
          render={data.render}
          labelStyle={data.labelStyle}
          viewStyle={viewStyle}
        />
      )
    })
  }

  return isMobile ? <View style={mobileStyle.container}>{xhtml}</View> : <View style={styles.row}>{xhtml}</View>
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  noData: { textAlign: 'center', width: '100%' },
  highLight: { color: '#eD1B2E' },
  highLightWarning: { color: '#FF6F00' }
})

const mobileStyle = StyleSheet.create({
  container: {
    paddingBottom: 8
  },
  item: {
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    lineHeight: 22
  },
  value: {
    fontSize: 15,
    lineHeight: 22
  }
})
