import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { LasStatus } from './LasStatus'

export namespace LapseReins {
  export const LapseReins = t.type({
    body: t.type({
      action: Maybe(t.string),
      benefitLimitAmountA: Maybe(t.number),
      benefitLimitAmountB: Maybe(t.number),
      contractNumber: Maybe(t.string),
      exchangeId: Maybe(t.string),
      lapseDate: Maybe(t.string),
      paidToDate: Maybe(t.string),
      projectedPaidToDate: Maybe(t.string),
      reinstatementFee: Maybe(t.number),
      status: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)]),
      totalBillL: Maybe(t.number),
      totalBillR: Maybe(t.number),
      totalOutsPremium: Maybe(t.number),
      volbanfr: Maybe(t.number),
      volbanto: Maybe(t.number),
      riskCommenceDate: Maybe(t.string),
      paymentFrequency: Maybe(t.string)
    })
  })

  export type LapseReins = t.TypeOf<typeof LapseReins>
}
