// React Lib
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react'
import {
  Controller,
  ControllerRenderProps,
  useForm,
  UseFormStateReturn,
  ControllerFieldState,
  UseFormGetValues,
  UseFormReset,
  UseFormClearErrors
} from 'react-hook-form'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

// Common
import { Input } from '@pulseops/common'
import { FormDataFPT } from '../change-national-id-card.form'
import {
  FieldsType,
  GroupsViewType,
  groupViewsFPTConst,
  typeField,
  TypeFieldEnum,
  typeGroupName,
  typeNameFieldFPT
} from '../change-national-id-card.const'
import { CN } from '../change-national-id-card.style'
import { isEmpty } from 'lodash'

const { Row } = CN

export type keyFormType = 'name' | 'id' | 'dob' | 'sex' | 'doe'

export type errorsFormCitizenType = {
  key: keyFormType
  percent: string
}

export type valuesFormCitizenType = {
  key: keyFormType
  value: string
}

export interface FormCitizenRef {
  getValues: UseFormGetValues<FormDataFPT>
  setValues: (
    values: {
      key: keyFormType
      value: string
    }[]
  ) => void[]
  setErrors: (errors: errorsFormCitizenType[]) => void[]

  reset: UseFormReset<FormDataFPT>
  clearErrors: UseFormClearErrors<FormDataFPT>
  defaultValuesFormCitizen: FormDataFPT
}

interface Props {
  stepModal: 1 | 2
}

export const defaultValuesFormCitizen: FormDataFPT = {
  id: '',
  dob: '',
  doe: '',
  name: '',
  sex: ''
}

const FormCitizen = ({ stepModal }: Props, ref: Ref<FormCitizenRef>) => {
  const { t: translate_change_nationalID, i18n } = useTranslation('CHANGE_NATIONALID_CARD_PS')
  const [groupsViewsFpt, setGroupsViewsFpt] = useState<GroupsViewType<typeGroupName, typeNameFieldFPT>[] | []>([])
  const formsFPT = useForm<FormDataFPT>({
    defaultValues: defaultValuesFormCitizen
  })
  const { control, getValues, setValue, setError, reset, clearErrors } = formsFPT

  const setValues = (values: valuesFormCitizenType[]) => {
    return values.map(({ key, value }) => {
      return setValue(key, value)
    })
  }

  const setErrors = (errors: errorsFormCitizenType[]) => {
    const _errors = errors.filter((error) => +error.percent !== 100)

    return _errors.map((error) => {
      return setError(error.key, {
        type: 'validate',
        message: translate_change_nationalID('ERROR.WARNING_PERCENT', { percent: error.percent })
      })
    })
  }

  useImperativeHandle(
    ref,
    () => ({
      getValues,
      setValues,
      setErrors,
      reset,
      clearErrors,

      defaultValuesFormCitizen
    }),
    [stepModal]
  )

  useEffect(() => {
    if (isEmpty(i18n.language)) return
    setGroupsViewsFpt(groupViewsFPTConst(translate_change_nationalID))
  }, [i18n.language])

  const renderTypeField = ({
    field,
    controller
  }: {
    field: FieldsType<typeNameFieldFPT>
    controller: {
      field: ControllerRenderProps<FormDataFPT, typeNameFieldFPT>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<FormDataFPT>
    }
  }) => {
    const { type, label, disabled, placeholder, required } = field
    const {
      field: { onChange, onBlur, value },
      fieldState: { error }
    } = controller

    const fieldTypeMap = new Map<typeField | 'DEFAULT', Function>([
      [
        TypeFieldEnum['TEXTBOX'],
        () => (
          <Input
            title={label}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            value={value as string}
            onBlur={onBlur}
            errorMessage={error?.message}
            onChange={onChange}
          />
        )
      ],

      ['DEFAULT', () => <></>]
    ])

    return (fieldTypeMap.get(type) ?? fieldTypeMap.get('DEFAULT'))?.()
  }

  const renderFields = (fields: FieldsType<typeNameFieldFPT>[]) => {
    return fields.map((_field, index) => {
      const { formName } = _field
      return (
        <View key={index} style={{ marginTop: 32 }}>
          <Controller
            name={formName}
            control={control}
            render={(controller) => <>{renderTypeField({ field: _field, controller })}</>}
          />
        </View>
      )
    })
  }

  const renderGroupViewsFromCitizen = () => {
    return groupsViewsFpt.map(({ isVisible, fields, groupName }, index) => {
      return (
        <View key={index} style={{ flex: 1, ...(groupName === 'back_size_group' ? { marginLeft: 40 } : {}) }}>
          {isVisible && renderFields(fields)}
        </View>
      )
    })
  }

  return <Row style={{ alignItems: 'flex-start' }}>{stepModal === 2 && renderGroupViewsFromCitizen()}</Row>
}

export default forwardRef(FormCitizen)
