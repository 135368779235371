import * as React from 'react'

import { assets } from '../assets'

export type SendEmailInputProps = {
  email?: string
  icon?: JSX.Element
  editale?: boolean
}

export const SendEmail = ({ email, icon = <assets.IconEditEmail /> }: SendEmailInputProps) => {
  return <a href={`mailto:${email}`}>{icon}</a>
}
