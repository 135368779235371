import * as React from 'react'
import { Modal, StyleSheet, Text, TextInput, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { CanView, useMobile, assets, RoundedButton, Input } from '@pulseops/common'

interface Props {
  onClose: () => void
  onSetComment: (e: string, x: string) => void
  open: boolean
  comment: string
  name: string
}

export const LeaderCodeModal = ({ onClose, onSetComment, comment, open, name }: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()
  const [newComment, setNewComment] = React.useState<string>('')
  const handleClose = () => {
    // setComment('')
    onClose()
  }
  const handleSave = () => {
    onSetComment(newComment, name)
    onClose()
  }
  React.useEffect(() => {
    setNewComment(comment)
  }, [comment])

  const inputRef = React.createRef<TextInput>()
  React.useEffect(() => {
    open && inputRef.current?.focus()
  }, [open])

  return (
    <Modal visible={open} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          alignItems: 'center',
          justifyContent: isWide ? 'center' : 'center'
        }}
      >
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={handleClose}
            style={{ position: 'absolute', height: height, width: width }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              height: name === 'agentcodeRefer' ? 295 : 370,
              width: isWide ? 500 : '60%',
              borderBottomStartRadius: 8,
              borderBottomEndRadius: 8,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('RequestInfo:LeaderCodeChanged')}</Text>
            </View>
            <TouchableOpacity onPress={handleClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          <View
            style={{
              marginTop: 20,
              paddingHorizontal: 20,
              alignItems: 'center'
            }}
          >
            <Text style={{ fontSize: 16 }}>{t('RequestInfo:popupLeaderCode')}</Text>
          </View>
          {name === 'leaderCode' ? (
            <View
              style={{
                paddingHorizontal: 25,
                paddingVertical: 20
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {`\u25AA`} {t('RequestInfo:Leadercodedescription')}
              </Text>
              <Text style={{ fontSize: 16 }}>
                {`\u25AA`} {t('RequestInfo:Officecode')}
              </Text>
              <Text style={{ fontSize: 16 }}>
                {`\u25AA`} {t('RequestInfo:SaleZone')}
              </Text>
              <Text style={{ fontSize: 16 }}>
                {`\u25AA`} {t('RequestInfo:Regioncode')}
              </Text>
              <Text style={{ fontSize: 16 }}>
                {`\u25AA`} {t('RequestInfo:Regiondescription')}
              </Text>
            </View>
          ) : (
            <View
              style={{
                paddingHorizontal: 25,
                paddingVertical: 20
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {`\u25AA`} {t('RequestInfo:Agentnamerefer')}
              </Text>
            </View>
          )}

          <View
            style={{
              marginHorizontal: 20
            }}
          >
            {name === 'leaderCode' ? (
              <Input
                onKeyEnter={() => handleSave()}
                refs={inputRef}
                onChange={(e) => {
                  if (e.length > 3) {
                    return null
                  } else {
                    setNewComment(e)
                  }
                }}
                value={newComment}
                maxLength={3}
              />
            ) : (
              <Input
                onKeyEnter={() => handleSave()}
                refs={inputRef}
                onChange={(e) => {
                  if (e.length > 8) {
                    return null
                  } else {
                    setNewComment(e)
                  }
                }}
                value={newComment}
                maxLength={8}
                inputType="number"
              />
            )}
            {/* <TextInput
              value={newComment}
              onChange={(e) => {
                if ((e.length > 3 && name === 'leaderCode') || (!e.match(/^[0-9]+$/) && e.length > 8 && name === 'agentcodeRefer')) {
                  return null
                } else {
                  setNewComment(e)
                }
              }}
              placeholder={t('RequestInfo:Leadercode')}
              textInputProps={{
                maxLength: name === 'leaderCode' ? 3 : 8,
                multiline: true,
                style: [
                  {
                    borderWidth: 1,
                    borderColor: '#D3DCE6',
                    borderRadius: 8,
                    padding: 16,
                    fontSize: 15,
                    lineHeight: 22,
                    color: '#000000'
                  }
                ],
                placeholderTextColor: '#B0B0B0'
              }}
            /> */}
          </View>
          <View style={[styles.btnGroup, { marginBottom: 16, marginHorizontal: isWide ? 0 : 16, marginTop: 16 }]}>
            <RoundedButton
              filled
              disabled={name === 'leaderCode' && !newComment}
              activeOpacity={0.7}
              text={t('Confirm')}
              textStyle={styles.btnTitle}
              style={{ height: 39, width: isWide ? 108 : '100%' }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={handleSave}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#4F4F4F',
    fontWeight: '600',
    fontSize: 20,
    marginEnd: 16
  },

  input: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 4
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
