import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CHANGE_DOB_GENDER)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    attributesExt: Maybe(
      t.type({
        clientNum: Maybe(t.string),
        dob: Maybe(t.string),
        gender: Maybe(t.string),
        isCheckChangeDOBGroup: t.boolean,
        isCheckChangeGenderGroup: t.boolean
      })
    )
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadPulse4Ops
  })
)

const Pruonline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPulse4Ops
  })
)

export const ChangeDOBGender = t.union([Pulse4Ops, Zalo, Pruonline])
export type ChangeDOBGender = t.TypeOf<typeof ChangeDOBGender>
