export enum OBCallProgram {
  WCC = 'WCC',
  SQCB = 'SQCB',
  RMD = 'RMD',
  // OPL = 'OPL',
  // PO = 'PO',
  CARE1 = 'CARE1',
  CARE2 = 'CARE2',
  // M3 = 'M3',
  // M12 = 'M12',
  // CMB = 'CMB'
  MA1 = 'MA1',
  MA2 = 'MA2',
  CMB1 = 'CMB1',
  CMB2 = 'CMB2',
  IDC = 'IDC',
  ADHOCCCE = 'ADHOCCCE',
  ADHOCCS = 'ADHOCCS',
  RMDBAU = 'RMDBAU',
  APL = 'APL',
  LAPSED = 'LAPSED'
}

export const defaultDataAssignmentOB = [
  {
    status: 'OFF',
    callProgram: OBCallProgram.WCC
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.SQCB
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.RMD
  },
  // {
  //   status: 'OFF',
  //   callProgram: OBCallProgram.OPL
  // },
  // {
  //   status: 'OFF',
  //   callProgram: OBCallProgram.PO
  // },
  {
    status: 'OFF',
    callProgram: OBCallProgram.CARE1
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.CARE2
  },
  // {
  //   status: 'OFF',
  //   callProgram: OBCallProgram.M3
  // },
  // {
  //   status: 'OFF',
  //   callProgram: OBCallProgram.M12
  // }
  {
    status: 'OFF',
    callProgram: OBCallProgram.IDC
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.ADHOCCCE
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.ADHOCCS
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.RMDBAU
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.MA1
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.MA2
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.APL
  },
  {
    status: 'OFF',
    callProgram: OBCallProgram.LAPSED
  }
]

export enum OBCallProgramTypeCode {
  WCC = 'WELCOME_CALL',
  SQCB = 'SALE_QUALITY_CALL_BACK',
  RMD = 'PREMIUM_REMINDER',
  // OPL = 'OUTSTANDING_PREMIUM_LOAN',
  // PO = 'PREMIUM_OVERDUE',
  CARE1 = 'CARE_1',
  CARE2 = 'CARE_2',
  // M3 = 'MATURITY_3M',
  // M12 = 'MATURITY_12M',
  // CMB = 'CALL_ME_BACK',
  MA1 = 'MATURITY_1',
  MA2 = 'MATURITY_2',
  CMB1 = 'CALL_ME_BACK_1',
  CMB2 = 'CALL_ME_BACK_2',
  IDC = 'INDEPENDENT_CHECK',
  ADHOCCCE = 'ADHOC_CCE',
  ADHOCCS = 'ADHOC_CS',
  RMDBAU = 'PREMIUM_REMINDER_BAU',
  APL = 'AUTO_PREMIUM_LOAN',
  LAPSED = 'LAPSED'
}

export const mapCallProgram = new Map<OBCallProgramTypeCode, OBCallProgram>([
[OBCallProgramTypeCode.WCC, OBCallProgram.WCC],
[OBCallProgramTypeCode.SQCB, OBCallProgram.SQCB],
[OBCallProgramTypeCode.RMD, OBCallProgram.RMD],
[OBCallProgramTypeCode.APL, OBCallProgram.APL],
// [OBCallProgramTypeCode.OPL, OBCallProgram.OPL],
// [OBCallProgramTypeCode.PO, OBCallProgram.PO],
[OBCallProgramTypeCode.CARE1, OBCallProgram.CARE1],
[OBCallProgramTypeCode.CARE2, OBCallProgram.CARE2],
// [OBCallProgramTypeCode.M3, OBCallProgram.M3],
// [OBCallProgramTypeCode.M12, OBCallProgram.M12],
// [OBCallProgramTypeCode.CMB, OBCallProgram.CMB]
[OBCallProgramTypeCode.MA1, OBCallProgram.MA1],
[OBCallProgramTypeCode.MA2, OBCallProgram.MA2],
[OBCallProgramTypeCode.CMB1, OBCallProgram.CMB1],
[OBCallProgramTypeCode.CMB2, OBCallProgram.CMB2],
[OBCallProgramTypeCode.IDC, OBCallProgram.IDC],
[OBCallProgramTypeCode.ADHOCCCE, OBCallProgram.ADHOCCCE],
[OBCallProgramTypeCode.ADHOCCS, OBCallProgram.ADHOCCS],
[OBCallProgramTypeCode.RMDBAU, OBCallProgram.RMDBAU],
[OBCallProgramTypeCode.APL, OBCallProgram.APL],
[OBCallProgramTypeCode.LAPSED, OBCallProgram.LAPSED]
])