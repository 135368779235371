// React Lib
import React, {
  useState,
  forwardRef,
  Ref,
  Dispatch,
  SetStateAction,
  useImperativeHandle,
  ReactChild,
  ReactFragment
} from 'react'
import { ModalComponent } from '@pulseops/common'
import { View, ScrollView } from 'react-native'
import { TextCustom } from '../change-national-id-card.style'
import { useTranslation } from 'react-i18next'
import { COLORS } from '../change-national-id-card.const'

export interface ModalUploadCitizenRef {
  isShowModalUpload: boolean
  setIsShowModalUpload: Dispatch<SetStateAction<boolean>>
  stepModal: 1 | 2
  setStepModal: React.Dispatch<React.SetStateAction<1 | 2>>
  setStateButton: React.Dispatch<
    React.SetStateAction<{
      loading: boolean
      disable: boolean
    }>
  >
  setError: React.Dispatch<React.SetStateAction<string>>
}

export interface Props {
  onSave?: () => void
  onCancel?: () => void
  children: (step: 1 | 2) => void | ReactChild | ReactFragment | boolean | null | undefined | any
}

const ModalUploadCitizen = ({ onCancel, onSave, children }: Props, ref: Ref<ModalUploadCitizenRef>) => {
  const { t: translate_change_nationalID } = useTranslation('CHANGE_NATIONALID_CARD_PS')
  const [stepModal, setStepModal] = useState<1 | 2>(1)
  const [isShowModalUpload, setIsShowModalUpload] = useState<boolean>(false)
  const [{ loading, disable }, setStateButton] = useState<{ loading: boolean; disable: boolean }>({
    loading: false,
    disable: false
  })
  const [error, setError] = useState<string>('')

  useImperativeHandle(
    ref,
    () => ({
      isShowModalUpload,
      setIsShowModalUpload,
      stepModal,
      setStepModal,
      setStateButton,
      setError
    }),
    [isShowModalUpload, stepModal, error]
  )

  /**
   * Views
   */
  const renderModalContent = () => {
    return (
      <>
        <TextCustom fontSize="14px" fontWeight="400" textColor={COLORS.ROLLING_STONE}>
          {translate_change_nationalID('MODAL_UPLOAD_CITIZEN.SUB_TITLE')}
        </TextCustom>

        {error.length > 0 && (
          <TextCustom
            fontSize="13px"
            fontWeight="400"
            textColor={COLORS.ALIZARIN}
            fontStyle="italic"
            style={{ marginTop: 12 }}
          >
            Error: {error}
          </TextCustom>
        )}

        {children?.(stepModal)}
      </>
    )
  }

  return (
    <ModalComponent
      title={`${translate_change_nationalID('MODAL_UPLOAD_CITIZEN.TITLE')}`}
      modalWidth={640}
      visible={isShowModalUpload}
      actions={[
        {
          text: translate_change_nationalID('MODAL_UPLOAD_CITIZEN.ACTION.CANCEL'),
          type: 'outline',
          action() {
            onCancel?.()
          },
          disabled: disable,
          loading: false,
          onlyWide: false,
          style: { marginRight: 12 }
        },
        {
          text: translate_change_nationalID('MODAL_UPLOAD_CITIZEN.ACTION.SAVE'),
          type: 'filled',
          action() {
            onSave?.()
          },
          disabled: disable,
          loading,
          onlyWide: false,
          style: { paddingLeft: 12 }
        }
      ]}
      onClose={() => {
        onCancel?.()
        setIsShowModalUpload(false)
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <View style={{ paddingHorizontal: 24 }}>{renderModalContent()}</View>
      </ScrollView>
    </ModalComponent>
  )
}

export default forwardRef(ModalUploadCitizen)
