import * as core from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import { theme } from '@pulseops/common'
import * as rne from 'react-native-elements'
import styled from 'styled-components/native'

const borderColor = '#D3DCE6'
const fontSize = 13

const labelStyle = {
  color: '#70777E',
  fontSize,
  fontWeight: 700,
  fontFamily: theme.fonts.main
}

const minHeight = 30

export const InputStyle = {
  borderColor,
  labelStyle,
  minHeight
}

export const Input = styled(rne.Input).attrs<{
  readonly?: boolean
  disabled?: boolean
}>((p) => ({
  labelStyle,
  inputStyle: {
    fontFamily: p.theme.fonts.main,
    fontSize,
    minHeight
  },
  inputContainerStyle: {
    borderColor
  },
  disabledInputStyle: {
    color: p.readonly ? 'black' : undefined,
    opacity: p.readonly ? 1 : undefined
  },
  disabled: p.disabled || p.readonly
}))<{
  readonly?: boolean
}>``

export const InputBase = withStyles({
  root: {
    'label + &': {
      marginTop: 16
    },
    div: {
      paddingTop: 8,
      paddingBottom: 4.5
    }
  },
  input: {
    position: 'relative',
    fontSize,
    borderBottom: '1px solid ' + borderColor
  }
})(core.InputBase)

export const InputLabel = withStyles({
  root: {
    ...labelStyle,
    transform: 'translate(0, 2px) scale(1)'
  }
})(core.InputLabel)
