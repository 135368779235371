import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import { BillingChangeReversal, ProductEnum } from './model'
import { Frequency } from './model/Frequency'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import moment from 'moment'
import { PulseOpsFormat } from '../Formatter'
import { SubmissionService } from './SubmissionService'

export namespace BillingChangeReversalService {
  export interface DetailRes {
    body: {
      automaticPolicyLoanInterestDate: Array<string> | null | undefined
      curBillingFrequency: string | null | undefined
      curInstalmentAmount: string | null | undefined
      oldBillingFrequency: Array<string> | null | undefined
      oldInstalmentAmount: Array<string> | null | undefined
      policyNo: string | null | undefined
    }
  }

  export const getDetail = (policyNum: string, oldBillingFreq?: Frequency): Task<BillingChangeReversal.DetailData> =>
    pipe(
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNum}`)(
          t.type({
            body: t.type({
              status: Maybe(t.string)
            })
          })
        ),
        POApi.get(`wf-api/policy/${policyNum}/billing-change-apl`)(
          t.type({
            body: t.type({
              automaticPolicyLoanInterestDate: Maybe(t.array(t.string)),
              curBillingFrequency: Maybe(t.string),
              curInstalmentAmount: Maybe(t.string),
              oldBillingFrequency: Maybe(t.array(t.string)),
              oldInstalmentAmount: Maybe(t.array(t.string)),
              policyNo: Maybe(t.string)
            })
          })
        ),
        POApi.post(`wf-api/policy/billing-change-apl/loan-enquiry`)(
          t.type({
            body: t.type({
              action: Maybe(t.string),
              contractType: Maybe(t.string),
              subfileOccurs: Maybe(
                t.array(
                  t.type({
                    accuredInterest: Maybe(t.number),
                    pendingInterest: Maybe(t.number),
                    currentBalance: Maybe(t.number),
                    loanNumber: Maybe(t.number),
                    loanStartDate: Maybe(t.string),
                    loanType: Maybe(t.string),
                    principalAmount: Maybe(t.number),
                    policyLoanTotal: Maybe(t.number)
                  })
                )
              )
            })
          })
        )({
          body: {
            contractNumber: policyNum,
            effectiveDate: moment(new Date()).format('yyyyMMDD')
          }
        })
      ),
      ZIO.flatMap(([policy, detail, loan]) =>
        policy.body.status === ProductEnum.Status.LA
          ? pipe(
              ZIO.zip(
                SubmissionService.getPolicies([
                  {
                    policyNo: policyNum,
                    function: 'BILLREVERT'
                  }
                ]),
                POApi.post(`wf-api/policy/billing-change-apl/lapse-reins-enquiry`)(
                  t.type({
                    body: t.type({
                      volbanfr: Maybe(t.number)
                    })
                  })
                )({
                  body: {
                    contractNumber: policyNum
                  }
                })
              ),

              ZIO.map(([date, lapse]): BillingChangeReversal.DetailData => {
                // PRODUCT STATUS = LA
                const oldBillingIndex = oldBillingFreq
                  ? detail.body.oldBillingFrequency
                    ? detail.body.oldBillingFrequency.findIndex((e) => e === oldBillingFreq)
                    : 0
                  : 0
                // const date = detail.body.automaticPolicyLoanInterestDate && detail.body.automaticPolicyLoanInterestDate[oldBillingIndex] !== '0' ? new Date(detail.body.automaticPolicyLoanInterestDate[oldBillingIndex]) : null
                const dateLas =
                  date && date.length > 0
                    ? (() => {
                        const tmp = date[0].paidToDateBasic.toString()

                        const year = Number(tmp.substr(0, 4))
                        const month = Number(tmp.substr(4, 2)) - 1
                        const day = Number(tmp.substr(6, 2))
                        return new Date(year, month, day)
                      })()
                    : null
                console.log(`date: ${dateLas}`)
                const loanTypeA = loan.body.subfileOccurs?.filter((e) => e.loanType === 'A')
                const subfileOccurs =
                  dateLas && loanTypeA?.length
                    ? loanTypeA.filter(
                        (e) =>
                          e.loanStartDate &&
                          isAfterOrSameDate(
                            new Date(e.loanStartDate.split('/').reverse().join('/')),
                            new Date(dateLas.getTime() + 86400000 * 60)
                          )
                      )
                    : []

                const oldInstalmentAmount = detail.body.oldInstalmentAmount
                  ? Number(detail.body.oldInstalmentAmount[oldBillingIndex])
                  : 0
                let automaticPolicyLoanInterest = 0
                if (subfileOccurs.length) {
                  const principleLoan = subfileOccurs.reduce((total, e) => total + (e.principalAmount ?? 0), 0)
                  const totalLoan = subfileOccurs.reduce((total, e) => total + (e.policyLoanTotal ?? 0), 0)
                  automaticPolicyLoanInterest = totalLoan - principleLoan
                }

                const loanTypeAB = loan.body.subfileOccurs?.filter((e) => e.loanType === 'A' || e.loanType === 'B')
                const interestPL = loanTypeAB?.length
                  ? loanTypeAB.reduce((total, e) => total + (e.accuredInterest ?? 0) + (e.pendingInterest ?? 0), 0)
                  : 0
                const volbanFr = lapse.body.volbanfr ?? 0

                const requirePayInAmount = volbanFr + interestPL // volbanfr + interestAPL  + interestOPL --> type A, B
                return mapDetail(
                  detail,
                  dateLas,
                  oldInstalmentAmount,
                  requirePayInAmount,
                  automaticPolicyLoanInterest,
                  oldBillingIndex
                )
              })
            )
          : ZIO.effectTotal((): BillingChangeReversal.DetailData => {
              // PRODUCT STATUS  = IF

              const oldBillingIndex = oldBillingFreq
                ? detail.body.oldBillingFrequency
                  ? detail.body.oldBillingFrequency.findIndex((e) => e === oldBillingFreq)
                  : 0
                : 0
              const date =
                detail.body.automaticPolicyLoanInterestDate &&
                detail.body.automaticPolicyLoanInterestDate[oldBillingIndex] !== '0'
                  ? new Date(detail.body.automaticPolicyLoanInterestDate[oldBillingIndex])
                  : null

              const loanTypeA = loan.body.subfileOccurs?.filter((e) => e.loanType === 'A')
              const subfileOccurs =
                date && loanTypeA?.length
                  ? loanTypeA.filter(
                      (e) =>
                        e.loanStartDate &&
                        isAfterOrSameDate(new Date(e.loanStartDate.split('/').reverse().join('/')), date)
                    )
                  : []

              const oldInstalmentAmount = detail.body.oldInstalmentAmount
                ? Number(detail.body.oldInstalmentAmount[oldBillingIndex])
                : 0
              let automaticPolicyLoanInterest = 0
              if (subfileOccurs.length) {
                const principleLoan = subfileOccurs.reduce((total, e) => total + (e.principalAmount ?? 0), 0)
                const totalLoan = subfileOccurs.reduce((total, e) => total + (e.policyLoanTotal ?? 0), 0)
                automaticPolicyLoanInterest = totalLoan - principleLoan
              }
              const requirePayInAmount =
                policy.body.status == ProductEnum.Status.IF ? oldInstalmentAmount + automaticPolicyLoanInterest : 0 // volbanfr + interestAPL  + interestOPL

              return mapDetail(
                detail,
                date,
                oldInstalmentAmount,
                requirePayInAmount,
                automaticPolicyLoanInterest,
                oldBillingIndex
              )
            })
      )
    )

  export const mapDetail = (
    detail: DetailRes,
    date: Date | null,
    oldInstalmentAmount: number,
    requirePayInAmount: number,
    automaticPolicyLoanInterest: number,
    oldBillingIndex: number
  ): BillingChangeReversal.DetailData => ({
    automaticPolicyLoanInterestDate: date,
    curBillingFrequency: detail.body.curBillingFrequency ?? '0',
    curInstalmentAmount: detail.body.curInstalmentAmount ?? '0',
    oldBillingFrequency: detail.body.oldBillingFrequency ?? [],
    oldInstalmentAmount: `${oldInstalmentAmount}`, // (A)
    policyNo: detail.body.policyNo ?? '',
    automaticPolicyLoanInterest, // (B),
    requirePayInAmount, // A + B
    oldBillingFreqFormField: detail.body.oldBillingFrequency
      ? (detail.body.oldBillingFrequency[oldBillingIndex] as Frequency)
      : null
  })

  export const isAfterOrSameDate = (date1: Date, date2: Date) => {
    const _d1 = moment(date1).startOf('days')
    const _d2 = moment(date2).startOf('days')
    const isAfter = moment(_d1).isSameOrAfter(_d2)
    return isAfter
  }

  export const getDataCheckAccess = (policyNum: string): Task<BillingChangeReversal.DataCheckAccess> =>
    pipe(
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNum}`)(
          t.type({
            body: t.type({
              status: Maybe(t.string)
            })
          })
        ),
        POApi.get(`wf-api/policy/${policyNum}/billing-change-apl`)(
          t.type({
            body: t.type({
              automaticPolicyLoanInterestDate: Maybe(t.array(t.string)),
              curBillingFrequency: Maybe(t.string),
              curInstalmentAmount: Maybe(t.string),
              oldBillingFrequency: Maybe(t.array(t.string)),
              oldInstalmentAmount: Maybe(t.array(t.string)),
              policyNo: Maybe(t.string)
            })
          })
        )
      ),

      ZIO.map(
        ([policy, detail]): BillingChangeReversal.DataCheckAccess => ({
          curFreq: detail.body.curBillingFrequency ?? '',
          contractStatus: policy.body.status ?? '',
          exceed120days:
            PulseOpsFormat.getDifferenceInDays(
              new Date(),
              new Date(
                detail.body.automaticPolicyLoanInterestDate ? detail.body.automaticPolicyLoanInterestDate[0] : ''
              )
            ) > 120,
          hasLeastOldTransaction: detail.body.oldBillingFrequency
            ? detail.body.oldBillingFrequency.length > 0 && detail.body.oldBillingFrequency[0].length > 0
            : false
        })
      )
    )

  // export const submit = (detail: BillingChangeReversal.DetailData, oldFreq: Frequency)  => (requestauth: RequestAuthFormDataSubmit) =>
  //   SubmissionService.submit(t.unknown)(
  //     `wf-api/policy/${detail.policyNo}/billing-change-apl`,
  //     {body: {
  //       automaticPolicyLoanInterestDate: [moment(detail.automaticPolicyLoanInterestDate).format('DD/MM/yyyy')],
  //       automaticPolicyLoanInterest: `${detail.automaticPolicyLoanInterest}`,
  //       curBillingFrequency: detail.curBillingFrequency,
  //       curInstalmentAmount: detail.curInstalmentAmount,
  //       oldBillingFrequency: [oldFreq],
  //       oldInstalmentAmount: [detail.oldInstalmentAmount],
  //       policyNo: detail.policyNo,
  //       payInAmount: `${detail.requirePayInAmount}`
  //     }}, detail.policyNo, requestauth, [])
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${detail.policyNo}/billing-change-apl`)(t.unknown)({
  //     body: {
  //       automaticPolicyLoanInterestDate: [moment(detail.automaticPolicyLoanInterestDate).format('DD/MM/yyyy')],
  //       automaticPolicyLoanInterest: `${detail.automaticPolicyLoanInterest}`,
  //       curBillingFrequency: detail.curBillingFrequency,
  //       curInstalmentAmount: detail.curInstalmentAmount,
  //       oldBillingFrequency: [oldFreq],
  //       oldInstalmentAmount: [detail.oldInstalmentAmount],
  //       policyNo: detail.policyNo,
  //       payInAmount: `${detail.requirePayInAmount}`
  //     },
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )
}
