import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { IBService, InvestmentDataInfo } from '../../../../../../ib-service'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { IBGeneralTable } from '../../../../../../common'
import { isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
}
export const PremiumRedirectionHistory = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo } = props
  const [loading, bindLoading] = useLoading()
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)

  const handlePaging = () => {
    pipe(
      IBService.getPremiumRedirectionHistory({
        pageIndex: page,
        itemsPerPage: pageSize,
        policyNo: policyNumber,
        codes: ['T551'],
        validFlag: true
      }),
      ZIO.map((res) => {
        setTaskCount(res.paging.total || 0)
        setInvestmentInfo({
          ...(investmentInfo as InvestmentDataInfo),
          premiumRedirectionHistory: res.records
        })
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    handlePaging()
  }, [page, pageSize])

  const dataTable = [
    { label: t('TransDatePRH'), field: 'transDate', format: 'date' },
    { label: t('Code'), field: 'transNo' },
    { label: t('Description'), field: 'description' }
  ]

  return (
    <View>
      <IBGeneralTable
        data={
          orderBy(
            investmentInfo?.premiumRedirectionHistory?.map((x) => ({
              ...x,
              description: 'Premium Redirection - Modify',
              transDate: moment(x.transDate, 'DD/MM/YYYY').format('YYYYMMDD')
            })),
            'transDate',
            'desc'
          ) || []
        }
        dataTable={dataTable}
        paging={{
          page: page,
          pageSize: pageSize,
          taskCount: taskCount,
          setPage: setPage,
          setPageSize: setPageSize
        }}
        loading={loading}
      />
    </View>
  )
}

const styles = StyleSheet.create({})
