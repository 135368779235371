import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { LasStatus } from '../LasStatus'
import { PoliciesReceived } from '../PoliciesReceived'
import { TransactionType } from '../TransactionType'
import { PaymentData } from './PaymentData'

export const CashOutLoan = t.type({
  transactionType: t.literal(TransactionType.LOAN),
  source: Maybe(t.string),
  payload: Maybe(
    t.type({
      additionalProperties: Maybe(
        t.type({
          surrenderValue: Maybe(t.string),
          principalAplOpl: Maybe(t.string),
          interestAplOpl: Maybe(t.string),
          netTotalCashBenefit: Maybe(t.string),
          totalPayoutAmount: Maybe(t.string),
          loanAmountAvailable: Maybe(t.array(PoliciesReceived)),
          policiesReceived: Maybe(t.array(PoliciesReceived))
        })
      ),
      body: Maybe(
        t.type({
          cashOutOptions: t.array(
            t.union([
              PaymentData.BankTransfer,
              PaymentData.EWalletMomo,
              PaymentData.PayPremium,
              PaymentData.Premium,
              PaymentData.PayLoan,
              PaymentData.TopUp,
              PaymentData.Reinstatement,
              PaymentData.NoType,
              PaymentData.RepayOpl,
              PaymentData.RepayApl,
              PaymentData.Other,
              PaymentData.PaidAtBank,
              PaymentData.CashAtCounter
            ])
          )
        })
      ),
      lasStatus: Maybe(t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)]))
    })
  )
})
export type CashOutLoan = t.TypeOf<typeof CashOutLoan>
