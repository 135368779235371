import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { StructureData } from './model/Structure'
import { Maybe, Nullable } from '@mxt/zio/codec'
export namespace StructureService {
  export const SalesStructureTypeC = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agenCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createDate: Maybe(t.string),
    responseStatus: t.type({
      code: Maybe(t.string),
      message: Maybe(t.string)
    }),
    data: Maybe(
      t.type({
        officeCode1: Maybe(t.string),
        gaCode1: Maybe(t.string),
        regionCode: Maybe(t.string),
        saleZone: Maybe(t.string),
        serviceBranch: Maybe(t.string),
        gaType: Maybe(t.string),
        officeCode2: Maybe(t.string),
        gaCode2: Maybe(t.string)
      })
    ),
    eref: Maybe(t.string)
  })
  export type SalesStructureType = t.TypeOf<typeof SalesStructureTypeC>

  export const TableCodeT = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agenCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createDate: Maybe(t.string),
    responseStatus: t.type({
      code: Maybe(t.string),
      message: Maybe(t.string)
    }),
    data: Maybe(
      t.type({
        item: Maybe(t.string),
        code: Maybe(t.string),
        childCode: Maybe(t.string)
      })
    ),
    datas: Maybe(
      t.array(
        t.type({
          item: Maybe(t.string),
          code: Maybe(t.string)
        })
      )
    ),
    eref: Maybe(t.string)
  })

  export type TableCodeT = t.TypeOf<typeof TableCodeT>

  export const OrDetail = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createdDate: Maybe(t.number),
    responseStatus: Maybe(
      t.type({
        clientError: Maybe(t.boolean),
        code: Maybe(t.string),
        message: Maybe(t.string),
        errors: Maybe(
          t.array(
            t.type({
              code: Maybe(t.string),
              type: Maybe(t.string),
              message: Maybe(t.string)
            })
          )
        )
      })
    ),
    data: Nullable(
      t.array(
        t.type({
          basicAgentType: Maybe(t.string),
          reportToAgent: Maybe(t.string),
          reportToType: Maybe(t.string),
          effectiveFrom: Maybe(t.string),
          effectiveTo: Maybe(t.string),
          description: Maybe(t.string)
        })
      )
    ),
    eref: Maybe(t.string)
  })

  export type OrDetail = t.TypeOf<typeof OrDetail>

  export const StructureLogRqData = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    source: Maybe(t.string),
    id: Maybe(t.string),
    agentType: Maybe(t.string),
    galleries: Maybe(t.string),
    agentCategory: Maybe(t.string),
    ref: Maybe(t.string),
    officeCode1: Maybe(t.string),
    gaCode1: Maybe(t.string),
    regionCode: Maybe(t.string),
    saleZone: Maybe(t.string),
    serviceBranch: Maybe(t.string),
    gaType: Maybe(t.string),
    officeCode2: Maybe(t.string),
    gaCode2: Maybe(t.string),
    eref: Maybe(t.string),
    email: Maybe(t.string),
    saleType: Maybe(t.string),
    status: Maybe(t.string),
    submissionDate: Maybe(t.union([t.string, t.number])),
    transactionType: Maybe(t.string)
  })

  export type StructureLogRqData = t.TypeOf<typeof StructureLogRqData>

  export const StructureLog = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createdDate: Maybe(t.string),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    ),
    data: Nullable(StructureLogRqData),
    eref: Maybe(t.string)
  })

  export type StructureLog = t.TypeOf<typeof StructureLog>

  export const getSalesStructureRule = (data: StructureData.SalesStructureRule): Task<SalesStructureType> => {
    return pipe(
      POApi.post('distribution-agents-service/distribution-service/get-sales-structure-rule')(SalesStructureTypeC)(
        data
      ),
      ZIO.map((res) => res)
    )
  }

  export const getTableTV604 = (officeCode: string): Task<TableCodeT> => {
    return pipe(
      POApi.getConfig({ params: { officeCode, regionType: 'O' } })(
        'distribution-agents-service/table/get-tv604-ga-code'
      )(TableCodeT),
      ZIO.map((res) => res)
    )
  }

  export const getTableT72 = (officeCode: string): Task<TableCodeT> => {
    return pipe(
      POApi.getConfig({ params: { officeCode, regionType: 'O' } })(
        'distribution-agents-service/table/get-t72-location-region'
      )(TableCodeT),
      ZIO.map((res) => res)
    )
  }

  export const getTableTT518 = (leaderCode: string): Task<TableCodeT> => {
    return pipe(
      POApi.getConfig({ params: { leaderCode } })('distribution-agents-service/table/get-tt518-office-code')(
        TableCodeT
      ),
      ZIO.map((res) => res)
    )
  }

  export const getTableT5696 = (regionCode: string): Task<TableCodeT> => {
    return pipe(
      POApi.getConfig({ params: { regionCode } })('distribution-agents-service/table/get-t5696-sale-zone')(TableCodeT),
      ZIO.map((res) => res)
    )
  }

  export const getTableT5628 = (regionCode: string): Task<TableCodeT> => {
    return pipe(
      POApi.getConfig({ params: { regionCode } })('distribution-agents-service/table/get-t5628-service-branch')(
        TableCodeT
      ),
      ZIO.map((res) => res)
    )
  }
  export const getTableOrDetail = (agentNum: string, unitCode: string, saleType: string): Task<OrDetail> => {
    return pipe(
      POApi.getConfig({ params: { agentNum, unitCode, saleType } })('distribution-agents-service/onboard-rules/get-by')(
        OrDetail
      ),
      ZIO.map((res) => res)
    )
  }
  export const saveStructureLog = (data: StructureLogRqData): Task<StructureLog> => {
    return pipe(
      POApi.post('distribution-agents-service/distribution-service/save-sales-structure-log')(StructureLog)(data),
      ZIO.map((res) => res)
    )
  }
  export const getStructureLog = (businessKey: string): Task<StructureLog> => {
    return pipe(
      POApi.getConfig({ params: { businessKey } })(
        'distribution-agents-service/distribution-service/get-sales-structure-log'
      )(StructureLog),
      ZIO.map((res) => res)
    )
  }
}
