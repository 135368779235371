import { Ref } from '@mxt/zio/stream'
import { Employee } from '@pulseops/business/core'
import { ErrorHandling, RootNavigation } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { BusinessViewEmployeeOverFCLModalParam } from '../../root-stack-param-list'

export namespace BusinessViewEmployeeOverFCLModalService {
  export const viewing = Ref.make<Employee | null>(null)

  export const view = (param: BusinessViewEmployeeOverFCLModalParam) => {
    pipe(RootNavigation.navigate('BusinessViewEmployeeOverFCLModal', param), ErrorHandling.run())
  }
}
