import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  CustomerRoleEnum,
  ErrorHandling,
  FieldList,
  Panel,
  TableField,
  TaskDetailService,
  useTranslator
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'

export interface CustomerInfoClaim {
  contactInfoFields: FieldList.DataSources
  personalInfoSource: TableField.DataSources
}

export const CustomerDigitalScreen = (props: {
  id: string
  data: CustomerInfoClaim | null
  isEditable?: boolean
  setData: (val: CustomerInfoClaim | null) => void
}) => {
  const { isEditable = true } = props

  const [loading, bindLoading] = useLoading()

  const { t } = useTranslator('TaskManagement')
  const ct = useTranslator('contact').t

  const getData = (): ZIO<unknown, Throwable, CustomerInfoClaim> =>
    pipe(
      TaskDetailService.getDigitalPolicyFull(props.id),
      ZIO.map((res) => {
        return {
          contactInfoFields:
            res === null
              ? []
              : [
                  {
                    label: ct('Address'),
                    value: res.customer.street
                  },
                  {
                    label: ct('Ward'),
                    value: res.customer.ward
                  },
                  {
                    label: ct('District'),
                    value: res.customer.district
                  },
                  {
                    label: ct('City'),
                    value: res.customer.city
                  },
                  {
                    label: ct('Country'),
                    value: res.customer.country
                  },
                  {
                    label: ct('Email'),
                    value: res.customer.email
                  },
                  {
                    label: ct('MobilePhone'),
                    value: res.customer.mobilePhone
                  }
                ],
          personalInfoSource: [
            {
              role: t(`roles:${CustomerRoleEnum.OWNER}`),
              name: res.policy.policyOwnerName,
              nationalId: res.clients?.nationalID || '-',
              dob: res.clients?.dob || '-',
              gender: res.clients?.sex || '-'
            }
          ]
        }
      })
    )

  const { contactInfoFields, personalInfoSource } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data !== null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    contactInfoFields: [],
    personalInfoSource: []
  }

  const columns = [
    {
      key: '1',
      title: ct('Role'),
      name: 'role'
    },
    {
      key: '2',
      title: ct('Fullname'),
      name: 'name'
    },
    {
      key: '3',
      title: ct('NationalID'),
      name: 'nationalId'
    },
    {
      key: '4',
      title: ct('DOB'),
      name: 'dob'
    },
    {
      key: '5',
      title: ct('Gender'),
      name: 'gender'
    }
  ]

  return (
    <Container loading={loading}>
      {contactInfoFields && contactInfoFields.length > 0 && (
        <Panel title={t('ContactInfo').toUpperCase()} allowToggle={isEditable}>
          <FieldList dataSource={contactInfoFields} />
        </Panel>
      )}
      {personalInfoSource.length > 0 && (
        <Panel title={t('ClientInfo').toUpperCase()} allowToggle={isEditable}>
          <TableField columns={columns} dataSource={personalInfoSource} currency="" />
        </Panel>
      )}
    </Container>
  )
}
