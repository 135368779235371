import * as t from 'io-ts'

export namespace eServiceRegistrationForm {
  const eServiceRegistrationForm = t.type({
    idNumber: t.string,
    fullName: t.string,
    dob: t.string,
    gender: t.string,
    telCode: t.string,
    mobileNumber: t.string,
    email: t.string,
    otpConsent: t.boolean,
    poClientNum: t.string
  })

  export type eServiceRegistration = t.TypeOf<typeof eServiceRegistrationForm>

  export const codec = eServiceRegistrationForm

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
