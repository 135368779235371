import * as React from 'react'
import { View, StyleSheet, Text } from 'react-native'
import {
  FieldList,
  Panel,
  Container,
  Input,
  Checkbox,
  TaskDetail,
  SelectOption,
  GeneralService,
  ErrorHandling,
  Form,
  DatePicker,
  formatNumberWithComma,
  AppContext,
  SubTasksResponse,
  SubTaskType,
  TaskType,
  TransactionType,
  TaskService,
  AuthService,
  SubmissionService,
  SelectSearch,
  assets,
  TaskDetailService
} from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { ClaimInfoForm, BankTransferForm } from '../claim-info/claim-type/ClaimInfoForm'
import i18next from 'i18next'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { BankTransfer, PayAtCounter, PaidAtBank } from '../claim-info'
import { Branch, ClaimService } from '@pulseops/submission'
import { CompleteClaim } from '../../actions/index'
import {
  requestRefreshAllInDashboard,
  requestRefreshDashboardAfterTranfer,
  requestRefreshAllBasketInDashboard
} from '@pulseops/task/common'
import { StackNavigationProp } from '@react-navigation/stack'
import { TaskDetailStackParamList } from '../../TaskDetailStackParamList'
import { onUnsuspendAction, onEndAction, checkRolesForButton, EndPopup, TransferPopup } from '../../actions'
import _ from 'lodash'
interface Props {
  detail: TaskDetail.Type
  subTask: SubTasksResponse
  setIsUnSuspendPopupOpen: (val: boolean) => void
  clients: {
    firstName: string
    surName: string
    dob: string
    sex: string
    nationality: string
    occupation: {
      desc: string
    }
    addressDetails: {
      Current: {
        line1: string
        country: string
      }
    }
  }
  navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
  roles: string[]
}

export const PayoutVerification: React.FC<Props> = ({
  detail,
  clients,
  navigation,
  subTask,
  setIsUnSuspendPopupOpen,
  roles
}) => {
  const detailData: TaskDetail.VerificationClaimPayout = React.useMemo(
    () => detail?.payload as TaskDetail.VerificationClaimPayout,
    [detail]
  )

  const { t, i18n } = useTranslation('claim')
  const ct = useTranslation().t
  const { showToast, getFooterClaim, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isCompleteLoading, setIsCompleteLoading] = React.useState(false)
  const [isEndPopupOpen, setIsEndPopupOpen] = React.useState<boolean>(false)
  const [isTransferModalVisible, setTransferModalVisible] = React.useState<boolean>(false)
  const [bankBranch, setBankBranch] = React.useState<SelectOption[]>([])

  const ownerInfo = pipe(
    SubmissionService.getOwnerInfoDigital(detailData.detail.policyNumber as string),
    ErrorHandling.runDidMount()
  )

  const gender = [
    {
      value: 'M',
      label: t('common:Male')
    },
    {
      value: 'F',
      label: t('common:Female')
    }
  ]

  const { adjudicationInfo, claimInfo, paymentInfo } = detailData.detail.payload.body

  const PAYMENT = paymentInfo.cashOutOptions[0]

  const [editableForm, setEditableForm] = React.useState<boolean>(true)

  const [canShowField, setCanShowField] = React.useState<boolean>(PAYMENT.attributesExt.PAYEE_INFO.isPayeeNotPO)

  let defaultPaymentForm: Partial<ClaimInfoForm> = {
    isNotPolicyOwner: !PAYMENT.attributesExt.PAYEE_INFO.isPayeeNotPO ? true : false,
    changePayeeInfo: PAYMENT.attributesExt.PAYEE_INFO.changePayeeInfo || false,
    bankTransferForm: {
      accountName: PAYMENT?.bankAccount.accountName || '-',
      idNumber: PAYMENT?.attributesExt.PAYEE_INFO.idNumber || '-',
      accountNumber: PAYMENT?.bankAccount?.accountNo?.toString() || '-',
      branch: null,
      bank: null,
      address: PAYMENT?.attributesExt.PAYEE_INFO.address || '-',
      dateOfBirth: PAYMENT?.attributesExt.PAYEE_INFO.dob
        ? moment(PAYMENT?.attributesExt.PAYEE_INFO.dob, 'DD/MM/YYYY').toDate()
        : null,
      foreignAddress: PAYMENT?.attributesExt.PAYEE_INFO.foreignAddress || '-',
      gender: gender.find((cond) => cond.value === PAYMENT?.attributesExt.PAYEE_INFO.gender) || null,
      nationality: null,
      nationality2: null,
      occupation: null,
      relationshipWithPO: null,
      residenceCountry: null,
      paymentAmount: PAYMENT?.paymentOption?.amount?.toString()
    }
  }
  const form = useForm<ClaimInfoForm>({ defaultValues: defaultPaymentForm })

  const clonePaymentForm = React.useRef<Partial<ClaimInfoForm> | null>(null)
  const errors = form.formState.errors

  const register = Form.register(form)

  const paymentRefCurrent = pipe(
    GeneralService.getVariables(detail.processId),
    ZIO.map((item) => item.paymentRef),
    ErrorHandling.runDidMount()
  )
  const decision = pipe(
    GeneralService.getDecision('s1'),
    ZIO.map((item) => item.find((c) => c.code === adjudicationInfo.claimDecision)),
    ErrorHandling.runDidMount()
  )

  const productName = pipe(
    SubmissionService.getPolicyDigitalPVA(detail.primaryPolicyNo),
    ZIO.map((policy) => {
      return policy.productOptions?.map((item) => item.product.fullName)
    }),
    ErrorHandling.runDidMount()
  )

  const newPaymentRef: string | undefined | null = pipe(
    AuthService.userInfo,
    ZIO.flatMap(({ email }) =>
      TaskService.queryTasks({
        suspended: false,
        isTeam: true,
        isFilterTeam: false,
        order: 'desc',
        active: true,
        filter: {
          assignee: email,
          caseId: detail.caseId
        },
        taskCategory: {
          task: 'CL' as TaskType,
          subTask: 'PV' as SubTaskType
        },
        category: TaskType.Claim,
        pagination: { pageIndex: 0, pageSize: 10 }
      })
    ),
    ZIO.flatMap((item) => {
      return item.items[0].unsuspendReason
        ? GeneralService.generalPaymentRef(
            detail.claimNumber,
            detailData.detail.payload.createdDate || moment().toString()
          )
        : ZIO.succeed(undefined)
    }),
    ZIO.map((item) => {
      return item?.paymentRef ? item.paymentRef : undefined
    }),
    ErrorHandling.runDidMount()
  )

  const { banks, relationships, countries, occupations, pvStatus } = pipe(
    ZIO.zipPar(
      ClaimService.getBanks(),
      GeneralService.getRelationship(),
      GeneralService.getCountries,
      GeneralService.getOccupations,
      ClaimService.getBankBranch(PAYMENT?.bankAccount?.branchCode ?? ''),
      ClaimService.getStatusPV(detailData.detail.claimNumber as string)
    ),
    ZIO.map(([bank, relationship, country, occupation, branch, pvStatus]) => {
      const bankDetail = bank?.find((item) => item.code === PAYMENT?.bankAccount.bankCode)
      const relationships = relationship.map((item) => ({
        value: item.code,
        // label: i18n.language === 'vi' ? item.nameVn : item.nameEn
        label: i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN,
        labelVn: item.relationshipVN
      }))
      const countries = country.map((item) => ({
        value: item.code,
        label: item.name
      }))
      const occupations = occupation.map((item) => ({
        value: item.code,
        label: item.name
      }))

      const payeeInfo = PAYMENT.attributesExt.PAYEE_INFO

      const bankTransferInfo = {
        bank: {
          label: bankDetail?.name || '',
          value: bankDetail?.code || ''
        },
        branch: {
          label: branch[0].name,
          value: branch[0].code
        },
        nationality: countries.find((res) => res.value === payeeInfo.nationality) || null,
        nationality2: countries.find((res) => res.value === payeeInfo.nationality2) || null,
        residenceCountry: countries.find((res) => res.value === payeeInfo.residenceCountry) || null,
        relationshipWithPO: relationships.find((res) => res.value === payeeInfo.relationshipWithPolicyOwner) || null,
        occupation: occupations.find((res) => res.value === payeeInfo.occupation) || null,
        pvStatus: pvStatus.logStatus,
        pvType: pvStatus.pvType
      }

      form.setValue('bankTransferForm.bank', bankTransferInfo.bank)
      form.setValue(`bankTransferForm.branch`, bankTransferInfo.branch)
      form.setValue('bankTransferForm.nationality', bankTransferInfo.nationality)
      form.setValue('bankTransferForm.nationality2', bankTransferInfo.nationality2)
      form.setValue(`bankTransferForm.residenceCountry`, bankTransferInfo.residenceCountry)
      form.setValue(`bankTransferForm.relationshipWithPO`, bankTransferInfo.relationshipWithPO)
      form.setValue(`bankTransferForm.occupation`, bankTransferInfo.occupation)
      form.setValue(`bankTransferForm.pvStatus`, pvStatus.logStatus || '-')
      form.setValue(`bankTransferForm.pvType`, pvStatus.pvType || '-')

      clonePaymentForm.current = {
        ...defaultPaymentForm,
        bankTransferForm: { ...defaultPaymentForm.bankTransferForm, ...bankTransferInfo } as BankTransferForm
      }
      return {
        banks: bank,
        relationships,
        countries,
        occupations,
        pvStatus: pvStatus.logStatus
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    banks: [],
    relationships: [],
    countries: [],
    occupations: [],
    pvStatus: ''
  }

  React.useEffect(() => {
    const isSupsend = subTask.split('-')[1] === 'SUSPENDED'
    getFooterClaim(
      isSupsend
        ? [
            {
              name: t('claim:Unsuspend'),
              action: onUnsuspendAction(setIsUnSuspendPopupOpen)
            }
          ]
        : [
            { name: t('Complete'), action: onComplete, isLoading: isCompleteLoading },
            {
              name: t('claim:Transfer'),
              action: () => {
                setTransferModalVisible(true)
              },
              isVisible: checkRolesForButton('CL', roles, false)('Transfer')
            },
            {
              name: t('End'),
              action: detail ? onEndAction(showToast, t)()(setIsEndPopupOpen, () => {}) : () => {},
              isVisible: checkRolesForButton('CL', roles, false)('End')
            },
            ,
            { name: t('Save'), action: onSubmit, isLoading: false }
          ]
    )
  }, [detail, isCompleteLoading])

  const onSubmit = form.handleSubmit((data) => {
    const detailSubmit = detailData.detail.payload
    const bankAccount = {
      bankName: data.bankTransferForm.bank?.label,
      bankCode: data.bankTransferForm.bank?.value,
      branchCode: data.bankTransferForm.branch?.value,
      accountNo: data.bankTransferForm.accountNumber,
      accountName: data.bankTransferForm.accountName
    }
    const payeeInfo = {
      paymentMethod: PAYMENT?.attributesExt.PAYEE_INFO.paymentMethod,
      isNewClient: !data.isNotPolicyOwner,
      isPayeeNotPO: !data.isNotPolicyOwner,
      changePayeeInfo: data.changePayeeInfo,
      payeeName: data.bankTransferForm.accountName,
      idNumber: data.bankTransferForm.idNumber,
      dob: data.bankTransferForm.dateOfBirth,
      gender: !data.isNotPolicyOwner ? data.bankTransferForm.gender?.value : null,
      occupation: !data.isNotPolicyOwner ? data.bankTransferForm.occupation?.value : null,
      relationshipWithPolicyOwner: !data.isNotPolicyOwner ? data.bankTransferForm.relationshipWithPO?.value : null,
      nationality: !data.isNotPolicyOwner ? data.bankTransferForm.nationality?.value : null,
      nationality2: !data.isNotPolicyOwner ? data.bankTransferForm.nationality2?.value : null,
      residenceCountry: !data.isNotPolicyOwner ? data.bankTransferForm.residenceCountry?.value : null,
      address: !data.isNotPolicyOwner ? data.bankTransferForm.address : null,
      foreignAddress: !data.isNotPolicyOwner ? data.bankTransferForm.foreignAddress : null
    }
    showGlobalLoading(true)
    pipe(
      TaskDetailService.saveLog(
        detail.id,
        {
          ...detailSubmit,
          body: {
            ...detailSubmit.body,
            paymentInfo: {
              cashOutOptions: [
                {
                  ...detailSubmit.body.paymentInfo.cashOutOptions[0],
                  bankAccount,
                  attributesExt: {
                    PAYEE_INFO: payeeInfo
                  }
                }
              ]
            }
          }
        },
        detail.processId,
        []
      ),
      ZIO.tap((_) => {
        showToast(t('message:MS990017'), 'success')
        showGlobalLoading(false)
        clonePaymentForm.current = form.getValues()
        return ZIO.unit
      }),
      ZIO.catchAll((e) =>
        ZIO.effectTotal(() => {
          showGlobalLoading(false)
        })
      ),
      ZIO.unsafeRun({})
    )
  })

  const onComplete = () => {
    const completeCase = (newPaymentRef: string | null | undefined) => {
      CompleteClaim(showToast, setIsCompleteLoading, t)(
        detail,
        PAYMENT?.paymentOption?.amount ?? 0,
        claimInfo.claimType ?? '',
        claimInfo.subClaimType ?? '',
        newPaymentRef ?? undefined,
        form.getValues().bankTransferForm.pvStatus === 'REJECT' ? 'REPAYMENT' : undefined
      )(() => {
        requestRefreshAllInDashboard()
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          navigation.navigate('UserDrawer', {
            screen: 'TaskDashboardScreen'
          })
        }
      })
    }

    if (!_.isEqual(clonePaymentForm.current, form.getValues())) {
      showToast(t('message:MS990003', { tab: t('Tab:PayoutVerification'), action: t('common:Complete') }), 'error')
    } else {
      if (form.getValues().bankTransferForm.pvStatus === 'REJECT' && !newPaymentRef) {
        pipe(
          GeneralService.generalPaymentRef(
            detail.claimNumber,
            detailData.detail.payload.createdDate || moment().toString()
          ),
          ZIO.map((paymentRef) => {
            completeCase(paymentRef.paymentRef)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        completeCase(newPaymentRef)
      }
    }
  }

  const renderPaymentModeTitle = () => {
    switch (PAYMENT?.attributesExt.PAYEE_INFO.paymentMethod) {
      case 'BANKTRANSFER':
        return t('BankTransfer')
      case 'CASH_AT_COUNTER':
        return t('CashAtTheCounter')
      case 'PAID_AT_BANK':
        return t('PayAtBank')
      default:
        return '-'
    }
  }

  const onHandleActionSuccess = (mustRefreshTaskGroup = false, isTranfer = false) => {
    if (mustRefreshTaskGroup) {
      isTranfer ? requestRefreshDashboardAfterTranfer() : requestRefreshAllInDashboard()
    } else {
      requestRefreshAllBasketInDashboard()
    }

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('UserDrawer', {
        screen: 'TaskDashboardScreen'
      })
    }
  }

  React.useEffect(() => {
    if (form.watch('bankTransferForm.bank')?.value) {
      pipe(
        ClaimService.getBankBranch(form.watch('bankTransferForm.bank')?.value || ''),
        ZIO.tap((bankBranch) => {
          setBankBranch(bankBranch.map((branch) => ({ value: branch.code, label: branch.name })))
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [form.watch('bankTransferForm.bank')])

  const onChangeBank = (e: SelectOption | null) => {
    if (e) {
      setBankBranch([])
      form.setValue('bankTransferForm.branch', null)
    }
  }

  const policyInfo = [
    {
      label: t('PolicyNumber'),
      value: PAYMENT.policy.policyNo || '-'
    },
    {
      label: t('EffectiveDate'),
      value: moment(adjudicationInfo.effectiveDate).format('DD/MM/YYYY') || '-'
    },
    {
      label: t('PolicyStatus'),
      value: adjudicationInfo.policyStatus || '-'
    },
    {
      label: t('ProductName'),
      value: productName ? productName[0] : '-'
    },
    {
      label: t('ClaimNumber'),
      value: claimInfo.claimNo || '-'
    },
    {
      label: t('Adjudication:ClaimDecision'),
      value: i18n.language === 'vi' ? decision?.nameVi : decision?.nameEn || '-'
    },
    {
      label: t('CaseID'),
      value: `${claimInfo.parentBusinessKey}` || '-'
    },
    {
      label: t('PayableAmount'),
      isHighlight: true,
      value: formatNumberWithComma(adjudicationInfo.payableAmount ?? 0) || '-'
    },
    {
      label: t('PaymentRef'),
      value: paymentRefCurrent || '-'
    }
  ]

  const checkEditableForm = (e: boolean) => {
    setEditableForm(e)
    if (e) {
      form.reset(clonePaymentForm.current as ClaimInfoForm)
    }
  }

  const onCheckClaimant = (e: boolean) => {
    if (e === false && ownerInfo) {
      form.clearErrors()
      form.setValue('bankTransferForm.accountName', ownerInfo.fullname || '-')
      form.setValue('bankTransferForm.idNumber', ownerInfo.nationalID || '-')
      form.setValue('bankTransferForm.dateOfBirth', moment(ownerInfo.dob).toDate())
      form.setValue('bankTransferForm.bank', null)
      form.setValue('bankTransferForm.branch', null)
      form.setValue('bankTransferForm.accountNumber', '')
    } else {
      const firstData = clonePaymentForm.current as ClaimInfoForm
      form.setValue('bankTransferForm.accountName', firstData?.bankTransferForm?.accountName)
      form.setValue('bankTransferForm.idNumber', firstData?.bankTransferForm?.idNumber)
      form.setValue('bankTransferForm.dateOfBirth', firstData?.bankTransferForm?.dateOfBirth)
      form.setValue('bankTransferForm.bank', firstData?.bankTransferForm?.bank)
      form.setValue('bankTransferForm.branch', firstData?.bankTransferForm?.branch)
      form.setValue('bankTransferForm.accountNumber', firstData?.bankTransferForm?.accountNumber)
    }
  }

  return (
    <View style={styles.container}>
      <Container>
        <Panel titleOutline={`PAYOUT INFO`} isExand={false}>
          <View style={[styles.sectionRow, { marginBottom: 30 }]}>
            <View style={[styles.col_3, { paddingRight: 30, paddingTop: 3 }]}>
              <Text style={styles.label}>{t('PaymentMethodSelect')}</Text>
              <Text style={styles.value}>{renderPaymentModeTitle()}</Text>
            </View>
            <View style={[styles.col_3]}>
              <Input
                {...register('bankTransferForm.paymentAmount')}
                title={t('PaymentAmount')}
                inputType="money"
                containerStyle={{ marginEnd: 30 }}
                disabled
                inputStyle={{ fontSize: 15, fontWeight: 'normal' }}
              />
            </View>
          </View>
          <View style={[styles.sectionRow, { marginBottom: 30 }]}>
            <View style={[styles.col_3]}>
              <Input
                {...register('bankTransferForm.pvStatus')}
                title={t('PVStatus')}
                disabled
                containerStyle={{ marginEnd: 30 }}
                inputStyle={{ fontSize: 15, fontWeight: 'normal' }}
              />
            </View>
            <View style={[styles.col_3]}>
              <Input
                {...register('bankTransferForm.pvType')}
                title={t('PVType')}
                disabled
                containerStyle={{ marginEnd: 30 }}
                inputStyle={{ fontSize: 15, fontWeight: 'normal' }}
              />
            </View>
          </View>
          <View style={[styles.sectionRow, { marginBottom: 16 }]}>
            <View style={{ flex: 1 / 3, flexDirection: 'row', alignItems: 'center' }}>
              <Controller
                name="changePayeeInfo"
                control={form.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Checkbox
                    textStyle={{ fontWeight: 'bold' }}
                    title={t('ChangePayeeInfo')}
                    value={value || false}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e)
                      checkEditableForm(!e)
                    }}
                    errorMessage={error?.message}
                    disabled={
                      !_.isEqual(pvStatus, 'REJECT') ||
                      clonePaymentForm?.current?.isNotPolicyOwner !== form.getValues().isNotPolicyOwner
                    }
                  />
                )}
              />
            </View>
            <View style={{ flex: 1 / 3, flexDirection: 'row', alignItems: 'center' }}>
              <Controller
                name="isNotPolicyOwner"
                control={form.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Checkbox
                    textStyle={{ fontWeight: 'bold' }}
                    title={t('PayeeIsPolicyOwner')}
                    value={value || false}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e)
                      setCanShowField(!e)
                      if (clonePaymentForm?.current?.isNotPolicyOwner === !e) {
                        form.setValue('changePayeeInfo', true)
                        checkEditableForm(false)
                        onCheckClaimant(false)
                      } else {
                        form.setValue('changePayeeInfo', false)
                        checkEditableForm(true)
                        onCheckClaimant(true)
                      }
                    }}
                    errorMessage={error?.message}
                    disabled={!_.isEqual(pvStatus, 'REJECT')}
                  />
                )}
              />
            </View>
            <View style={{ flex: 1 / 3, flexDirection: 'row', alignItems: 'center' }}></View>
          </View>
          <View style={[styles.sectionRow, { marginBottom: 30 }]}>
            <View style={[styles.col_3, { paddingRight: 30 }]}>
              <Input
                {...register('bankTransferForm.accountName', {
                  required: `${ct('message:MS020001', { field: t('AccountName') })}`,
                  maxLength: { value: 120, message: 'Max 120' },
                  pattern: {
                    value: /^[^<>'\"/;`%@*!(){}\[\],.:#+-=$^&\\|?~_-]*$/,
                    message: `${t('message:MS050013', { field: t('AccountName') })}`
                  }
                })}
                errorMessage={errors.bankTransferForm?.accountName?.message || ''}
                maxLength={120}
                title={t('AccountName')}
                disabled={form.getValues().isNotPolicyOwner || editableForm}
              />
            </View>
            <View style={[styles.col_3]}>
              <Input
                {...register('bankTransferForm.idNumber', {
                  required: `${ct('message:MS020001', { field: t('IDNumber') })}`,
                  maxLength: { value: 24, message: 'Max 24' },
                  pattern: {
                    value: /^((?!501|504)[0-9]*)$/,
                    message: `${t('message:MS050013', { field: t('IDNumber') })}`
                  }
                })}
                maxLength={24}
                errorMessage={errors.bankTransferForm?.idNumber?.message || ''}
                title={t('IDNumber')}
                disabled={form.getValues().isNotPolicyOwner || editableForm}
              />
            </View>
            <View style={[styles.col_3]}>
              <Controller
                name="bankTransferForm.dateOfBirth"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('contact:DOB') })}`
                  },
                  validate: (date) =>
                    (date && moment(date).toDate().getTime() < new Date().getTime()) ||
                    `${t('message:MS050013', { field: t('contact:DOB') })}`
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    required
                    label={t('contact:DOB')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    disabled={form.getValues().isNotPolicyOwner || editableForm}
                  />
                )}
              />
            </View>
          </View>
          <View style={[styles.sectionRow, { marginBottom: 30 }]}>
            <View style={[styles.col_3, { paddingRight: 30 }]}>
              <Controller
                name="bankTransferForm.bank"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: `${ct('message:MS020001', { field: t('Bank') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Bank')}
                    options={banks?.map((v) => ({ value: v.code, label: v.name })) || []}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      onChange(e)
                      onChangeBank(e)
                    }}
                    placeholder={t('common:Select')}
                    errorMessage={error?.message}
                    key={value?.value}
                    disabled={editableForm}
                  />
                )}
              />
            </View>
            <View style={[styles.col_3, , { paddingRight: 30 }]}>
              <Controller
                name="bankTransferForm.branch"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: `${ct('message:MS020001', { field: t('Branch') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    required
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Branch')}
                    options={bankBranch || []}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={t('common:Select')}
                    errorMessage={error?.message}
                    key={value?.value}
                    disabled={editableForm}
                  />
                )}
              />
            </View>
            <View style={[styles.col_3]}>
              <Input
                {...register('bankTransferForm.accountNumber', {
                  required: `${ct('message:MS020001', { field: t('AccountNumber') })}`,
                  maxLength: 20,
                  pattern: {
                    value: /^[^*&$#\\<>|~`!@%^()/';.,:/[/\]\"+=_-]*$/g,
                    message: t('message:MS050223')
                  }
                })}
                maxLength={20}
                errorMessage={errors.bankTransferForm?.accountNumber?.message || ''}
                title={t('AccountNumber')}
                required={true}
                disabled={editableForm}
              />
            </View>
          </View>
          {canShowField ? (
            <>
              <View style={[styles.sectionRow, { marginBottom: 30 }]}>
                <View style={[styles.col_3, { paddingRight: 30 }]}>
                  <Controller
                    name="bankTransferForm.relationshipWithPO"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('requestInfo:RelationshipWithPolicyOwner') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      const valueRelationships = value ? relationships.find((x) => x.value === value.value) : value
                      return (
                        <SelectSearch
                          required
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                          label={t('requestInfo:RelationshipWithPolicyOwner')}
                          options={relationships}
                          value={valueRelationships}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder={t('common:Select')}
                          errorMessage={error?.message}
                          key={value?.value}
                          disabled={editableForm}
                        />
                      )
                    }}
                  />
                </View>
                <View style={[styles.col_3, { paddingRight: 30 }]}>
                  <Controller
                    name="bankTransferForm.gender"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('contact:Gender') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      const valueGender = value ? gender.find((x) => x.value === value.value) : value
                      return (
                        <SelectSearch
                          required
                          popupIcon={<assets.ArrowDownDropdownIcon />}
                          label={t('contact:Gender')}
                          options={gender}
                          value={valueGender}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder={t('common:Select')}
                          errorMessage={error?.message}
                          key={value?.value}
                          disabled={editableForm}
                        />
                      )
                    }}
                  />
                </View>
              </View>
              <View style={[styles.sectionRow, { marginBottom: 30 }]}>
                <View style={[styles.col_3, { paddingRight: 30 }]}>
                  <Controller
                    name="bankTransferForm.occupation"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('contact:Occupation') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('contact:Occupation')}
                        options={occupations}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        errorMessage={error?.message}
                        key={value?.value}
                        disabled={editableForm}
                      />
                    )}
                  />
                </View>
                <View style={[styles.col_3]}>
                  <Controller
                    name="bankTransferForm.residenceCountry"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('contact:ResidenceCountry') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('contact:ResidenceCountry')}
                        options={countries}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        errorMessage={error?.message}
                        key={value?.value}
                        disabled={editableForm}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={[styles.sectionRow, { marginBottom: 30 }]}>
                <View style={[styles.col_3, { paddingRight: 30 }]}>
                  <Controller
                    name="bankTransferForm.nationality"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('contact:Nationality') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('contact:Nationality')}
                        options={countries}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        errorMessage={error?.message}
                        key={value?.value}
                        disabled={editableForm}
                      />
                    )}
                  />
                </View>
                <View style={[styles.col_2]}>
                  <Controller
                    name="bankTransferForm.address"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: t('contact:Address') })}`
                      },
                      pattern: {
                        // value: /^[^*&$#\\<>|~`!@%^()';.:\[\]"+=?]*$/g,
                        value: /^[^*&$#\\<>|]*$/g,
                        message: t('message:MS050223')
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        required
                        title={t('contact:Address')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                        maxLength={250}
                        disabled={editableForm}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={[styles.sectionRow, { marginBottom: 30 }]}>
                <View style={[styles.col_3, { paddingRight: 30 }]}>
                  <Controller
                    name="bankTransferForm.nationality2"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: t('contact:Nationality') + ' 2' })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('contact:Nationality') + ' 2'}
                        options={countries}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        errorMessage={error?.message}
                        key={value?.value}
                        disabled={editableForm}
                      />
                    )}
                  />
                </View>
                <View style={[styles.col_2]}>
                  <Controller
                    name="bankTransferForm.foreignAddress"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020001', { field: t('contact:Address') })}`
                      },
                      pattern: {
                        // value: /^[^*&$#\\<>|~`!@%^()';.:\[\]"+=?]*$/g,
                        value: /^[^*&$#\\<>|]*$/g,
                        message: t('message:MS050223')
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        required
                        title={t('contact:ForeignAddress')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                        maxLength={250}
                        disabled={editableForm}
                      />
                    )}
                  />
                </View>
              </View>
            </>
          ) : null}
        </Panel>
        <Panel titleOutline={`POLICY INFORMATION - ${PAYMENT.policy.policyNo}`} isExand={false}>
          <FieldList dataSource={policyInfo} />
        </Panel>
      </Container>
      {detail && (
        <EndPopup
          open={isEndPopupOpen}
          onClose={() => setIsEndPopupOpen(false)}
          onSuccess={() => onHandleActionSuccess(true)}
          selectedValue={{
            taskId: detail.id,
            updateToCore: detail.updateToCore,
            category: detail.category,
            caseId: detail.caseId
          }}
        />
      )}
      {detail && (
        <TransferPopup
          open={isTransferModalVisible}
          isClaim={true}
          onClose={() => setTransferModalVisible(false)}
          onSuccess={() => onHandleActionSuccess(true, true)}
          selectedValue={{
            category: detail.category as TaskType,
            basket: SubTaskType.Payout,
            taskIds: [detail.id],
            updateToCore: detail.updateToCore,
            transactionType: TransactionType.PAYOUT
          }}
        />
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 30
  },
  paddingCheckBox: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 0,
    paddingBottom: 10
  },
  sectionRow: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  },
  col_2: {
    width: '100%',
    maxWidth: '50%'
  },
  col_3: {
    width: '100%',
    maxWidth: '33%'
  },
  col_20: {
    width: '100%',
    maxWidth: '20%'
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 15
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22,
    marginTop: 10
  }
})
