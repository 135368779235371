import * as t from 'io-ts'
import { SourceType } from '../SourceType'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.REDATING)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    rcd: t.string,
    newRcd: t.string,
    frequency: t.string,
    regularPremium: t.number,
    lapsedDate: t.string,
    totalReinsAmount: t.number,
    agentCode: t.string,
    agentName: t.string,
    lifeAssuredList: t.array(
      t.type({
        clientNo: t.string,
        healthStateChange: t.boolean,
        occupationChange: t.boolean,
        curOccupation: t.string,
        newOccupation: t.string,
        activityChange: t.boolean,
        newActivity: t.string,
        companyChange: t.boolean,
        newCompany: t.string
      })
    )
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const PulseOps = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: payloadPulse4Ops
  })
)

export const Redating = t.union([Pulse4Ops, PulseOps])
export type Redating = t.TypeOf<typeof Redating>
