import { SelectOption } from "@pulseops/common"

export namespace OBQualityControlForm {
  export interface OBQualityControlDataSave {
    qcDataList: OBQCDataQuestion[]
    comment: string
  }

  export interface OBQCDataQuestion {
    criteriaCode: string,
    answerValueCode: SelectOption | null
  }
}