export namespace AddLackInterface {
  export interface TableList {
    priority?: number | null
    docID?: string | null
    className?: string | null
    maindoc?: string | null
    subdoc?: string | null
    required?: string | null
    documentsEn?: string | null
    documentsVn?: string | null
    note?: string | null
    lastedDate?: string | null
    suspendCode?: string | null
    status?: string | null
    isClickSuspense?: boolean | null
    xCondition?: string | null
  }
}
