import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
  Pressable,
  Animated
} from 'react-native'
import React from 'react'
import { Drawer, Fab, makeStyles } from '@material-ui/core'
import {
  AppContext,
  AuthService,
  ErrorHandling,
  GeneralService,
  ModalComponent,
  RBAC,
  SelectOption,
  assets,
  form2
} from '@pulseops/common'
import {
  DashBoardHeader,
  DashBoardContent,
  DashBoardTreeView,
  IBDashBoardContentIconProps,
  TypeDashBoardComponent,
  IBDashBoardContentPopupProps,
  IBGeneralTable,
  ClientScreenContext,
  IBButtonMultiTasks
} from '../common'
import { IBClientDashBoardScreen } from './ib-client-dashboard'
import { IBClientGeneralInfo } from './ib-client-general-info'
import { IBClientDataBox } from './ib-client-data-box'
import { IBClientCallingResult } from './ib-client-calling-result'
import { IBCustomerBehavior } from './ib-customer-behavior'
import { IBChangeContactInfo } from './ib-change-contact-info'
import { IBClientPolicy } from './ib-client-policy'
import { useTranslation, TFunction } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { InboundParamList } from '../InboundParamList'
import { createStackNavigator } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import {
  IBService,
  CustomerProfileData,
  PolicySummaryData,
  PolicyInquiryCountData,
  FAOInfo,
  PrurewardsSummaryData,
  CustomerBehaviorData
} from '../ib-service'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import _, { sortBy } from 'lodash'
import { IBClickToSendEmail } from './ib-click-to-send-email'
import { IBSendZNS } from './ib-send-zns/IBSendZNS'

const IBClientPolicyStack = createStackNavigator()

type Props = StackScreenProps<InboundParamList, 'IBClientScreenStack'>

const useStyles = makeStyles({
  paper: {
    width: 300,
    zIndex: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: 1
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white'
    }
  }
})

export const convertDataToDashBoardClient = (
  data: CustomerProfileData,
  t: TFunction<'Inbound'>,
  setOpenPopupFAO: (open: boolean) => void,
  setOpenPopupAgent: (open: boolean) => void,
  handleMakeCallAvaya: (phoneNumber: string) => void
): (
  | DashBoardHeader
  | DashBoardContent
  | DashBoardTreeView
  | IBDashBoardContentIconProps
  | IBDashBoardContentPopupProps
)[] => {
  const divider = {
    marginHorizontal: 15
  }

  const handleNewClient = (clientNumber: string) => {
    window.open(`${window.location.pathname}?clientNumber=${clientNumber}`)
  }

  const renderHyphen = (canShow: string | boolean) => {
    return canShow ? '-' : ''
  }

  const renderComma = (canShow: string) => {
    return !_.isEmpty(canShow) && !_.isNil(canShow) ? `${canShow}, ` : ''
  }

  const callOutIndicator = new Map([
    [undefined, t('NoSpecialNotes')],
    [null, t('NoSpecialNotes')],
    ['', t('NoSpecialNotes')],
    ['0', t('NoSpecialNotes')],
    ['1', t('DoNotCallOut')],
    ['2', t('DoNotCallOutForPR')],
    ['3', t('DoNotCallOutForService')]
  ])

  const mobileIndicator = (option: string) => {
    switch (option) {
      case '1':
        return t('PolInfo')
      case '3':
        return t('PolPruInfo')
      case '4':
        return t('RefuseSMS')
      default:
        return ''
    }
  }

  const emailIndicator = (option: string) => {
    switch (option) {
      case '1':
        return t('PolInfo')
      case '3':
        return t('PolPruInfo')
      case '4':
        return t('RefuseEmail')
      default:
        return ''
    }
  }

  const zaloIndicator = (option: string) => {
    switch (option) {
      case '1':
        return t('PolInfo')
      case '3':
        return t('PolPruInfo')
      case '4':
        return t('RefuseZalo')
      default:
        return ''
    }
  }

  const getGender = (sex?: string | null) => {
    switch (sex) {
      case 'F':
        return t('common:Female')
      case 'M':
        return t('common:Male')
      case 'U':
        return t('submission:Unknown')
      default:
        return ''
    }
  }

  const getMarried = (code: string) => {
    switch (code) {
      case 'S':
        return t('Single')
      case 'M':
        return t('Married')
      case 'U':
        return t('Unknown')
      case 'D':
        return t('Divorced')
      case 'W':
        return t('Widowed')
      default:
        return '-'
    }
  }

  const dataDashboard: (
    | DashBoardHeader
    | DashBoardContent
    | DashBoardTreeView
    | IBDashBoardContentIconProps
    | IBDashBoardContentPopupProps
  )[] = [
    {
      type: TypeDashBoardComponent.HEADER,
      clientName: data.fullName || '-',
      nationName: data.nationalName || '',
      gender: getGender(data.sex),
      level: data.vip,
      divider
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: t('ClientNumber'),
      value: (
        <Text
          style={{
            marginBottom:
              (!_.isNil(data?.aliasClientIds) && !_.isEmpty(data?.aliasClientIds)) ||
              (!_.isNil(data?.masterClientId) && !_.isEmpty(data?.masterClientId))
                ? 10
                : 0
          }}
        >
          {data.clientNumber || '-'}
        </Text>
      ),
      divider: {
        marginHorizontal: 15,
        hidden:
          (!_.isNil(data?.aliasClientIds) && !_.isEmpty(data?.aliasClientIds)) ||
          (!_.isNil(data?.masterClientId) && !_.isEmpty(data?.masterClientId))
      }
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: 'Alias',
      value: (
        <View style={{ marginBottom: 10 }}>
          {data.aliasClientIds?.map((x) => {
            return (
              <Pressable
                onPress={() => {
                  const pathname = window.location.pathname
                  const splitStr = pathname.split('/')
                  const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
                  window.open(`${url}/ib-dashboard?clientNumber=${x}`)
                }}
              >
                <Text style={{ marginBottom: 5, textDecorationLine: 'underline' }}>{x}</Text>
              </Pressable>
            )
          })}
        </View>
      ),
      dropDownShow: true,
      hidden: _.isNil(data?.aliasClientIds) || _.isEmpty(data?.aliasClientIds)
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: 'Master',
      value: (
        <Pressable
          onPress={() => {
            const pathname = window.location.pathname
            const splitStr = pathname.split('/')
            const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
            window.open(`${url}/ib-dashboard?clientNumber=${data.masterClientId}`)
          }}
        >
          <Text style={{ marginBottom: 5, textDecorationLine: 'underline' }}>{data.masterClientId}</Text>
        </Pressable>
      ),
      dropDownShow: true,
      divider,
      hidden: _.isNil(data?.masterClientId) || _.isEmpty(data?.masterClientId)
    },
    {
      type: TypeDashBoardComponent.CONTENT_POPUP,
      label: t('AgentCode'),
      value: !_.isEmpty(data?.agents) ? _.maxBy(data?.agents, (v) => v.terminationDate)?.code ?? '-' : '-',
      divider,
      openPopup: () => {
        setOpenPopupAgent(true)
      },
      hidden: _.isEmpty(data.agents) || _.isNil(data.agents) || data.customerType === 'C'
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: 'MyHR',
      value: data.myHR || '-',
      divider,
      hidden: _.isEmpty(data.myHR) || _.isNil(data.myHR)
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: data.customerType === 'C' ? t('License') : t('IDNumber'),
      value: data.nationalId || '-',
      divider
    },
    {
      type: TypeDashBoardComponent.CONTENT_ICON,
      icon: <assets.IBCakeDOB />,
      content: [{ value: <Text style={styles.textStyle}>{`${data.dob} - ${data.currentAge} ${t('Age')}`}</Text> }],
      textNoti: data.msgDOB,
      divider,
      hidden: _.isEmpty(data.dob) || _.isNil(data.dob)
    },
    // {
    //   type: TypeDashBoardComponent.CONTENT_ICON,
    //   icon: <assets.IBCallIcon />,
    //   content: [
    //     {
    //       value: (
    //         <Text style={styles.textStyle}>
    //           {data?.mobilePhoneNumber} {renderHyphen(!_.isEmpty(data?.mobilePhoneNumber))}{' '}
    //           {mobileIndicator(data?.mobilePhoneIndicator ?? '-')}
    //         </Text>
    //       )
    //     },
    //     {
    //       value: (
    //         <View style={[styles.otpConsent]}>
    //           <Text style={styles.textStyle}>OTP consent: </Text>
    //           {data.otpConsent === '1' ? <assets.IBTickTrue /> : '-'}
    //         </View>
    //       )
    //     }
    //   ],
    //   hidden: _.isEmpty(data?.mobilePhoneNumber) || _.isNil(data.mobilePhoneNumber)
    // },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: '',
      value: (
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            marginBottom:
              !_.isEmpty(data.homePhoneNumber) ||
              !_.isEmpty(data.officePhoneNumber) ||
              !_.isEmpty(data.mobilePhoneNumber)
                ? 10
                : 0
          }}
        >
          <Text style={{ fontSize: 16 }}>
            <Text style={{ fontWeight: 'bold' }}>{`${t('CalloutIndicator')}: `}</Text>
            {`${callOutIndicator.get(data.callIndicator) || '-'}`}
          </Text>
        </View>
      ),
      dropDownShow: true,
      divider: {
        hidden:
          !_.isEmpty(data.homePhoneNumber) || !_.isEmpty(data.officePhoneNumber) || !_.isEmpty(data.mobilePhoneNumber),
        marginHorizontal: 15
      }
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: '',
      value: (
        <View style={{ width: '100%' }}>
          <View style={[styles.textAvaya, { marginTop: 0 }]}>
            <Text style={styles.textStyle}>
              {data?.mobilePhoneNumber} {renderHyphen(!_.isEmpty(data?.mobilePhoneNumber))}{' '}
              {mobileIndicator(data?.mobilePhoneIndicator ?? '-')}
            </Text>
            <Pressable onPress={() => handleMakeCallAvaya(data?.mobilePhoneNumber as string)}>
              <assets.IBCallAvayaIcon />
            </Pressable>
          </View>

          <View style={[styles.otpConsent]}>
            <Text style={styles.textStyle}>OTP consent: </Text>
            {data.otpConsent === '1' ? <assets.IBTickTrue /> : '-'}
          </View>
        </View>
      ),
      divider: { hidden: !_.isEmpty(data.homePhoneNumber) && !_.isEmpty(data.officePhoneNumber) },
      hidden: _.isEmpty(data?.mobilePhoneNumber) || _.isNil(data.mobilePhoneNumber)
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: '',
      value: (
        <View style={{ width: '100%' }}>
          {data?.homePhoneNumber && (
            <View style={styles.textAvaya}>
              <Text style={styles.textStyle}>{data?.homePhoneNumber}</Text>
              <Pressable onPress={() => handleMakeCallAvaya(data?.homePhoneNumber as string)}>
                <assets.IBCallAvayaIcon />
              </Pressable>
            </View>
          )}
          {data?.officePhoneNumber && (
            <View style={styles.textAvaya}>
              <Text style={styles.textStyle}>{data?.officePhoneNumber}</Text>
              <Pressable onPress={() => handleMakeCallAvaya(data?.officePhoneNumber as string)}>
                <assets.IBCallAvayaIcon />
              </Pressable>
            </View>
          )}
        </View>
      ),
      divider,
      hidden: _.isEmpty(data.homePhoneNumber) && _.isEmpty(data.officePhoneNumber)
    },
    // {
    //   type: TypeDashBoardComponent.CONTENT_ICON,
    //   icon: <assets.IBCall2Icon />,
    //   content: [
    //     {
    //       value: (
    //         <Text>{`${data.officePhoneNumber ?? ''} ${
    //           (data.homePhoneNumber as string) && (data.officePhoneNumber as string) ? ';' : ''
    //         } ${data.homePhoneNumber ?? ''}`}</Text>
    //       )
    //     }
    //   ],
    //   divider,
    //   hidden: _.isEmpty(data.homePhoneNumber) && _.isEmpty(data.officePhoneNumber)
    // },
    {
      type: TypeDashBoardComponent.TREEVIEW,
      label: t('DuplicatePhoneNumber'),
      value: '',
      treeView:
        data?.mobileDuplicateObjs || data?.agentDuplicate
          ? [
              { label: '', value: data.mobilePhoneNumber || '-', color: 'red' },
              {
                label: '',
                value: t('DuplicateClient') || '-',
                bold: true,
                visible: _.isEmpty(data?.mobileDuplicateObjs) || _.isNil(data?.mobileDuplicateObjs)
              },
              ...(data?.mobileDuplicateObjs || []).map((item) => ({
                label: '',
                value: `${item.clientNumber} - ${item.fullName}`,
                textDecorate: 'underline',
                onClick: () => handleNewClient(item.clientNumber as string)
              })),
              {
                label: '',
                value: t('DuplicateAgent') || '-',
                bold: true,
                visible: _.isEmpty(data?.agentDuplicate) || _.isNil(data?.agentDuplicate)
              },
              ...(data?.agentDuplicate || []).map((item) => ({
                label: '',
                value: `${item.agentCode} - ${item.agentName}`
              }))
            ]
          : [],
      divider,
      hidden:
        (_.isEmpty(data?.mobileDuplicateObjs) && _.isEmpty(data?.agentDuplicate)) ||
        (_.isNil(data?.mobileDuplicateObjs) && _.isNil(data?.agentDuplicate))
    },
    {
      type: TypeDashBoardComponent.CONTENT_ICON,
      icon: <assets.IBEmailIcon />,
      content: [
        { value: <Text>{data.email}</Text> },
        { value: <Text>{emailIndicator(data.emailIndicator ?? '-')}</Text> }
      ],
      divider,
      hidden: _.isEmpty(data?.email) || _.isNil(data?.email)
    },
    {
      type: TypeDashBoardComponent.CONTENT_ICON,
      icon: <assets.IBZaloIcon />,
      content: [
        {
          value: (
            <Text>
              {data.zalo?.zaloName} - {zaloIndicator(data.zalo?.indicator ?? '-')}
            </Text>
          )
        }
      ],
      divider,
      hidden: _.isEmpty(data?.zalo?.zaloName) || _.isNil(data?.zalo?.zaloName)
    },
    {
      type: TypeDashBoardComponent.CONTENT_ICON,
      icon: <assets.IBLocalIcon />,
      content: [
        {
          value: (
            <Text>
              {`${renderComma(data.address?.address as string)}${renderComma(
                data.address?.ward as string
              )}${renderComma(data.address?.district as string)}${renderComma(
                data.address?.city as string
              )}${renderComma(data.address?.country as string)}`.slice(0, -2)}
            </Text>
          )
        }
      ],
      divider,
      hidden: _.isNil(data.address)
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: t('WorkPlace'),
      value: (
        <Text>
          {`${renderComma(data.workPlace?.address as string)}${renderComma(
            data.workPlace?.street as string
          )}${renderComma(data.workPlace?.ward as string)}${renderComma(
            data.workPlace?.district as string
          )}${renderComma(data.workPlace?.city as string)}`.slice(0, -2)}
        </Text>
      ),
      divider,
      dropDownShow: true,
      hidden: _.isNil(data?.workPlace) || Object.values(data.workPlace).every((x) => !x)
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: t('ForeignCountry'),
      value: (
        <Text>
          {`${renderComma(data.foreignCountry?.street as string)}${renderComma(
            data.foreignCountry?.ward as string
          )}${renderComma(data.foreignCountry?.district as string)}${renderComma(
            data.foreignCountry?.city as string
          )}${renderComma(data.foreignCountry?.country as string)}`.slice(0, -2)}
        </Text>
      ),
      divider,
      dropDownShow: true,
      hidden: _.isNil(data?.foreignCountry) || Object.values(data.foreignCountry).every((x) => !x)
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: 'PRUOnline:',
      value: (
        <View>
          {data?.pruOnlineList?.map((item) => {
            return (
              <View style={[styles.otpConsent, { marginBottom: 10 }]}>
                <Text>{item.account || '-'} </Text>
                {item.signature === '1' ? <assets.IBTickTrue /> : <assets.IBTickFalse />}
              </View>
            )
          })}
        </View>
      ),
      divider,
      dropDownShow: true,
      hidden: _.isNil(data?.pruOnlineList)
    },
    // {
    //   type: TypeDashBoardComponent.TREEVIEW,
    //   label: '',
    //   value: 'Bổ sung tờ khai W8-BEN',
    //   treeView: [{ label: 'Tờ khai', value: '123456789' }],
    //   divider
    // },
    {
      type: TypeDashBoardComponent.CONTENT_POPUP,
      label: 'FAO',
      value: data.fao as string,
      openPopup: () => {
        setOpenPopupFAO(true)
      },
      divider,
      hidden: _.isEmpty(data?.fao) || _.isNil(data?.fao)
    },
    {
      type: TypeDashBoardComponent.CONTENT_ICON,
      icon: <assets.IBMarriedStatusIcon />,
      content: [{ value: <Text style={{ fontSize: 15 }}>{getMarried(data.maritalStatus as string)}</Text> }],
      divider,
      hidden: _.isEmpty(data.maritalStatus) || _.isNil(data.maritalStatus) || data.customerType === 'C'
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: t('Father/Mother'),
      value: (
        <View>
          {data?.familyRelationships
            ?.filter((x) => x.relationshipDesc === 'Father/Mother')
            .map((x) => (
              <Pressable
                onPress={() => {
                  const pathname = window.location.pathname
                  const splitStr = pathname.split('/')
                  const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
                  window.open(`${url}/ib-dashboard?clientNumber=${x.clientId}`)
                }}
              >
                <Text style={{ marginBottom: 5, textDecorationLine: 'underline' }}>{x.clientFullName}</Text>
              </Pressable>
            ))}
        </View>
      ),
      hidden: data?.familyRelationships?.filter((x) => x.relationshipDesc === 'Father/Mother').length === 0
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: t('Wife/Husband'),
      value: (
        <View>
          {data?.familyRelationships
            ?.filter((x) => x.relationshipDesc === 'Wife/Husband')
            .map((x) => (
              <Pressable
                onPress={() => {
                  const pathname = window.location.pathname
                  const splitStr = pathname.split('/')
                  const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
                  window.open(`${url}/ib-dashboard?clientNumber=${x.clientId}`)
                }}
              >
                <Text style={{ marginBottom: 5, textDecorationLine: 'underline' }}>{x.clientFullName}</Text>
              </Pressable>
            ))}
        </View>
      ),
      hidden: data?.familyRelationships?.filter((x) => x.relationshipDesc === 'Wife/Husband').length === 0
    },
    {
      type: TypeDashBoardComponent.CONTENT,
      label: t('Children'),
      value: (
        <View>
          {data?.familyRelationships
            ?.filter((x) => x.relationshipDesc === 'Children')
            .map((x) => (
              <Pressable
                onPress={() => {
                  const pathname = window.location.pathname
                  const splitStr = pathname.split('/')
                  const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
                  window.open(`${url}/ib-dashboard?clientNumber=${x.clientId}`)
                }}
              >
                <Text style={{ marginBottom: 5, textDecorationLine: 'underline' }}>{x.clientFullName}</Text>
              </Pressable>
            ))}
        </View>
      ),
      hidden: data?.familyRelationships?.filter((x) => x.relationshipDesc === 'Children').length === 0
    }
  ]

  const filterArrayShow = dataDashboard.filter((x) => !x.hidden)
  filterArrayShow[filterArrayShow.length - 1].divider = undefined
  return filterArrayShow
}

const ModalAgents = (props: {
  visible: boolean
  onClose: (open: boolean) => void
  data?: {
    clientId?: string | null
    code?: string | null
    officeCode?: string | null
    officeName?: string | null
    terminationDate?: string | null
    terReasonCode?: string | null
    terReasonName?: string | null
  }[]
}) => {
  const { visible, onClose, data } = props
  const { width } = useWindowDimensions()
  const { t } = useTranslation('Inbound')

  return (
    <ModalComponent
      title={t('AgentDetail')}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
        <View>
          <Text style={{ color: '#58647A', fontSize: 15, marginBottom: 10, fontWeight: '700' }}>{t('AgentCode')}</Text>
          {data?.map((x) => (
            <Text style={{ fontSize: 15, marginBottom: 10 }}>{x.code || '-'}</Text>
          ))}
        </View>
        <View>
          <Text style={{ color: '#58647A', fontSize: 15, marginBottom: 10, fontWeight: '700' }}>{t('OfficeName')}</Text>
          {data?.map((x) => (
            <Text style={{ fontSize: 15, marginBottom: 10 }}>{x.officeName || '-'}</Text>
          ))}
        </View>
        <View>
          <Text style={{ color: '#58647A', fontSize: 15, marginBottom: 10, fontWeight: '700' }}>
            {t('DateTerminate')}
          </Text>
          {data?.map((x) => (
            <Text style={{ fontSize: 15, marginBottom: 10 }}>
              {x.terminationDate && moment(x.terminationDate, 'YYYYMMDD').isValid()
                ? moment(x.terminationDate, 'YYYYMMDD').format('DD/MM/YYYY')
                : '-'}
            </Text>
          ))}
        </View>
        <View>
          <Text style={{ color: '#58647A', fontSize: 15, marginBottom: 10, fontWeight: '700' }}>
            {t('TerminateReason')}
          </Text>
          {data?.map((x) => (
            <Text style={{ fontSize: 15, marginBottom: 10 }}>
              {x.terminationDate === '99999999' ? '-' : x.terReasonName || '-'}
            </Text>
          ))}
        </View>
      </View>
    </ModalComponent>
  )
}

const ModalFAO = (props: {
  clientNumber: string
  visible: boolean
  onClose: (open: boolean) => void
  data: FAOInfo[]
  setData: (data: FAOInfo[]) => void
  registeredAddress: string
  taxInfo: string
  listCountry?: {
    id: string | null | undefined
    code: string
    name: string
  }[]
}) => {
  const { t } = useTranslation('Inbound')
  const { clientNumber, visible, onClose, data, setData, taxInfo, registeredAddress, listCountry } = props
  const { width } = useWindowDimensions()
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if (clientNumber && _.isEmpty(data)) {
      pipe(
        IBService.getFAODetail(clientNumber),
        ZIO.map((faoInfo) => {
          setData(
            faoInfo.data.map((item) => ({
              ...item,
              nationality: listCountry?.find((x) => x.code === item.nationality)?.name ?? '-'
            }))
          )
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  const dataTable = [
    {
      label: t('CoOwner'),
      field: 'fullName',
      disabled: true
    },
    {
      label: t('Role'),
      field: 'title',
      disabled: true
    },
    {
      label: t('Gender'),
      field: 'gender',
      disabled: true
    },
    {
      label: t('IDNumber'),
      field: 'idNumber',
      disabled: true
    },
    {
      label: t('DOB'),
      field: 'dob',
      disabled: true
    },
    {
      label: t('Nationality'),
      field: 'nationality',
      disabled: true
    },
    {
      label: t('Address'),
      field: 'address',
      disabled: true
    }
  ]
  return (
    <ModalComponent
      title={t('OrganizationDetail')}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20, marginTop: 20 }}>
        <View style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontWeight: 'bold', fontSize: 15, marginRight: 5, color: '#58647A' }}>{`${t(
            'TaxIdNumber'
          )}:`}</Text>
          <Text style={{ fontSize: 15 }}>{taxInfo ?? ''}</Text>
        </View>
        <View style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontWeight: 'bold', fontSize: 15, marginRight: 5, color: '#58647A' }}>{`${t(
            'RegisteredAddress'
          )}:`}</Text>
          <Text style={{ fontSize: 15 }}>{registeredAddress ?? ''}</Text>
        </View>
        <IBGeneralTable dataTable={dataTable} data={data} loading={loading} />
      </View>
    </ModalComponent>
  )
}

const DrawerDashBoard = (props: {
  setGeneralInfo: (data: { occupation: string; paymentMethod: string; waitingCall: string; vipLevel: string }) => void
  setListClientNumber: (data: { label: string; value: string }[]) => void
  setLastestCustomerBehavior: (data: CustomerBehaviorData) => void
  setMasterClientName: (data: string) => void
}) => {
  const [open, setOpen] = React.useState<boolean>(true)
  const [customerProfileData, setCustomerProfileData] = React.useState<CustomerProfileData | undefined>(undefined)
  const [openPopupFAO, setOpenPopupFAO] = React.useState<boolean>(false)
  const [openPopupAgent, setOpenPopupAgent] = React.useState<boolean>(false)
  const [dataFAO, setDataFAO] = React.useState<FAOInfo[]>([])
  const [listCountry, setListCountry] = React.useState<
    {
      id: string | null | undefined
      code: string
      name: string
    }[]
  >()

  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [extensionNumber, setExtensionNumber] = React.useState<string>('')

  const renderComma = (canShow: string) => {
    return !_.isEmpty(canShow) && !_.isNil(canShow) ? `${canShow}, ` : ''
  }

  const calculateDOB = (dateString: string): string => {
    const birthday = new Date(dateString)
    const today = new Date()

    const DOB = new Date(today.getFullYear(), birthday.getMonth(), birthday.getDate(), 23, 59, 59)
    const lastedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
    const oneDay = 24 * 60 * 60 * 1000

    const diffDays = Math.round(Math.abs((DOB.valueOf() - lastedToday.valueOf()) / oneDay))

    if (DOB.valueOf() - lastedToday.valueOf() >= 0) {
      if (diffDays === 0) {
        return t('messageDOB')
      } else if (diffDays > 0 && diffDays <= 15) {
        return t('messageWithinDOB')
      }
    }

    return ''
  }
  const classes = useStyles()
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading()
  const [makeCallLoading, bindMakeCallLoading] = useLoading()
  const userEmail = pipe(
    AuthService.userInfo,
    ZIO.map((res) => res.email),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    setImmediate(() => {
      const urlParams = new URLSearchParams(window.location.search)
      const clientNumber = urlParams.get('clientNumber')
      if (clientNumber) {
        pipe(
          ZIO.zipPar(
            IBService.getCustomerProfile(clientNumber),
            GeneralService.getCountries,
            IBService.getLastestCustomerBehavior(clientNumber)
          ),
          ZIO.flatMap(([res, countries, lastestCustomerBehavior]) =>
            pipe(
              ZIO.zipPar(
                res.data.customerType === 'P' && res.data.mobilePhoneNumber
                  ? IBService.getDuplicatePhone({ phoneNumber: res.data.mobilePhoneNumber || '', clientNumber })
                  : ZIO.succeed({ data: [] }),
                res.data.paymentType === '1'
                  ? IBService.getOfficeCodesByBankCode(res.data.bankCode as string)
                  : ZIO.succeed(undefined),
                res.data.clientType === 'ALIAS'
                  ? IBService.getCustomerProfile(res.data.masterClientId as string)
                  : ZIO.succeed(undefined),
                lastestCustomerBehavior.data.characterCode
                  ? IBService.getA15Character(lastestCustomerBehavior.data.characterCode)
                  : ZIO.succeed(undefined)
              ),
              ZIO.map(([duplicatePhone, officesByBankCode, profileAlias, a15Character]) => {
                setListCountry(countries)
                props.setMasterClientName(res.data.fullName)
                props.setLastestCustomerBehavior({
                  ...lastestCustomerBehavior.data,
                  characterNameVi: a15Character?.data[0].nameVi,
                  characterNameEn: a15Character?.data[0].nameEn
                })
                props.setListClientNumber(
                  [clientNumber, ...(res?.data?.aliasClientIds || []), res.data.masterClientId as string]
                    .filter((x) => x)
                    .map((item) => ({ label: item, value: item }))
                )
                props.setGeneralInfo({
                  occupation: res.data.occupationName || '-',
                  paymentMethod: res.data.paymentType
                    ? res.data.paymentType === '1'
                      ? t('CastAtMsg', {
                          office: officesByBankCode
                            ? `${officesByBankCode?.type || '-'} ${
                                i18n.language === 'vi'
                                  ? officesByBankCode?.nameVi || '-'
                                  : officesByBankCode?.nameEn || '-'
                              }`
                            : res.data.bankCode
                        })
                      : ['3', '6', '9'].includes(res.data.paymentType as string)
                      ? t(`TransactionType:CASHLESS_${res.data.bankCode}`)
                      : t('CollectorAgent')
                    : '-',
                  waitingCall: '-',
                  vipLevel: res.data.vip || '-'
                })
                setCustomerProfileData({
                  ...res.data,
                  vip: res.data.clientType === 'ALIAS' ? profileAlias?.data.vip : res.data.vip,
                  msgDOB: calculateDOB(moment(res.data.dob, 'DD/MM/YYYY').format('YYYY/MM/DD')),
                  mobileDuplicateObjs: duplicatePhone.data
                    .filter((x) => x.clientNumber)
                    .map((item) => ({
                      clientNumber: item.clientNumber,
                      mobilePhoneNumber: res.data.mobilePhoneNumber || '',
                      fullName: item.clientName?.name
                    })),
                  agentDuplicate: duplicatePhone.data
                    .filter((x) => x.agentCode)
                    .map((item) => ({
                      agentCode: item.agentCode,
                      agentName: item.agentName?.name
                    })),
                  nationalName: countries.find((c) => c.code === res.data.nationality)?.name,
                  registeredAddress: `${renderComma(res.data.faoAddress?.street as string)}${renderComma(
                    res.data.faoAddress?.ward as string
                  )}${renderComma(res.data.faoAddress?.district as string)}${renderComma(
                    res.data.faoAddress?.city as string
                  )}${renderComma(res.data.faoAddress?.country as string)}`.slice(0, -2)
                })
              })
            )
          ),
          bindLoading,
          ZIO.unsafeRun({})
        )
      }
    })
  }, [])

  const handleMakeCallAvaya = (phoneNumber: string) => {
    pipe(
      !!extensionNumber ? ZIO.succeed(extensionNumber) : IBService.getExtensionNumberList(userEmail || ''),
      ZIO.flatMap((extensionNo) => {
        if (extensionNo) {
          setExtensionNumber(extensionNo)
          return IBService.makeCallAvaya(extensionNo, phoneNumber)
        }
        showToast(t('message:IB0019'), 'error')
        return ZIO.fail(null)
      }),
      ZIO.map((callData) => {
        if (!!callData && !callData.includes('Call Failed')) {
          console.log('Call Success')
        } else {
          showToast(t('message:OB0054'), 'error')
        }
        return callData
      }),
      ZIO.mapError((error) => {
        return error
      }),
      bindMakeCallLoading,
      ZIO.unsafeRun({})
    )
  }
  return (
    <>
      <TouchableOpacity
        onPress={() => setOpen(!open)}
        style={[styles.buttonDrawer, { zIndex: 999, left: open ? 299 : '0%' }]}
      >
        {open ? <assets.IBChevronLeft /> : <assets.IBChevronRight />}
      </TouchableOpacity>
      <View style={[styles.colLeft, { width: open ? 301 : 0 }]}>
        <Drawer
          open={open}
          anchor={'left'}
          onClose={() => setOpen(false)}
          variant="persistent"
          classes={{ paper: classes.paper }}
        >
          {loading ? (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          ) : (
            <IBClientDashBoardScreen
              typeDashBoardComponent={convertDataToDashBoardClient(
                customerProfileData as CustomerProfileData,
                t,
                setOpenPopupFAO,
                setOpenPopupAgent,
                handleMakeCallAvaya
              )}
            />
          )}
        </Drawer>
      </View>
      {openPopupFAO && (
        <ModalFAO
          visible={openPopupFAO}
          onClose={setOpenPopupFAO}
          clientNumber={customerProfileData?.clientNumber as string}
          data={dataFAO}
          setData={setDataFAO}
          registeredAddress={customerProfileData?.registeredAddress as string}
          taxInfo={customerProfileData?.taxInfo as string}
          listCountry={listCountry}
        />
      )}
      {openPopupAgent && (
        <ModalAgents
          visible={openPopupAgent}
          onClose={setOpenPopupAgent}
          data={_.orderBy(
            customerProfileData?.agents,
            (value) => (_.isNull(value.terminationDate) ? Infinity : value.terminationDate),
            'desc'
          )}
        />
      )}
    </>
  )
}

// const IBMultiTasksFabComponent = React.memo(() => {
//   const [openToggle, setOpenToggle] = React.useState<boolean>(false)
//   const [isModalOpenClientCallingResult, setModalOpenClientCallingResult] = React.useState<boolean>(false)
//   const [isModalOpenChangeContactInfo, setModalOpenChangeContactInfo] = React.useState<boolean>(false)
//   const [flagOpenModal, setOpenModal] = React.useState<string[]>()
//   const fadeAnim = React.useRef(new Animated.Value(0)).current
//   const positionAnim = React.useRef(new Animated.ValueXY({ x: 100, y: 50 })).current

//   React.useEffect(() => {
//     if (openToggle) {
//       Animated.parallel([
//         Animated.timing(fadeAnim, {
//           toValue: 1,
//           duration: 500,
//           useNativeDriver: true
//         }),
//         Animated.timing(positionAnim, {
//           toValue: { x: 0, y: -20 },
//           duration: 500,
//           useNativeDriver: true
//         })
//       ]).start()
//     } else {
//       fadeAnim.setValue(0)
//       positionAnim.setValue({ x: 0, y: 45 })
//     }
//   }, [openToggle])

//   return (
//     <View style={[styles.fab, { height: openToggle ? 205 : 60, justifyContent: 'flex-end' }]}>
//       <div onMouseEnter={() => setOpenToggle(true)} onMouseLeave={() => setOpenToggle(!_.isEmpty(flagOpenModal))}>
//         {openToggle ? (
//           <>
//             <Animated.View
//               style={[
//                 {
//                   opacity: fadeAnim,
//                   transform: positionAnim.getTranslateTransform()
//                 }
//               ]}
//             >
//               {_.isEmpty(flagOpenModal) || flagOpenModal?.includes(IBButtonMultiTasks.CustomerBehavior) ? (
//                 <IBCustomerBehavior setFlagModal={setOpenModal} />
//               ) : (
//                 <></>
//               )}
//               {_.isEmpty(flagOpenModal) || flagOpenModal?.includes(IBButtonMultiTasks.ChangeContactInfo) ? (
//                 <IBChangeContactInfo setFlagModal={setOpenModal} />
//               ) : (
//                 <></>
//               )}
//               {_.isEmpty(flagOpenModal) || flagOpenModal?.includes(IBButtonMultiTasks.ClientCallingResult) ? (
//                 <IBClientCallingResult setFlagModal={setOpenModal} />
//               ) : (
//                 <></>
//               )}
//             </Animated.View>
//           </>
//         ) : (
//           <></>
//         )}
//         {!_.isEmpty(flagOpenModal) ? (
//           <></>
//         ) : (
//           <View style={[styles.CircleFab, { backgroundColor: openToggle ? '#FFFFFF' : '#ED1B2E' }]}>
//             {openToggle ? <assets.IBMultiTasksXIcon /> : <assets.IBMultiTasksPlusIcon />}
//           </View>
//         )}
//       </div>
//     </View>
//   )
// })

const IBMultiTasksFabComponent = React.memo(() => {
  const [openToggle, setOpenToggle] = React.useState<boolean>(false)
  const [flagOpenModal, setOpenModal] = React.useState<boolean>(false)
  const fadeAnim = React.useRef(new Animated.Value(0)).current
  const positionAnim = React.useRef(new Animated.ValueXY({ x: 100, y: 50 })).current

  React.useEffect(() => {
    if (openToggle) {
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true
        }),
        Animated.timing(positionAnim, {
          toValue: { x: 0, y: -10 },
          duration: 500,
          useNativeDriver: true
        })
      ]).start()
    } else {
      fadeAnim.setValue(0)
      positionAnim.setValue({ x: 0, y: 45 })
    }
  }, [openToggle])

  return (
    <View style={[styles.fab, { height: openToggle ? 255 : 60, justifyContent: 'flex-end' }]}>
      <div onMouseEnter={() => setOpenToggle(true)} onMouseLeave={() => setOpenToggle(flagOpenModal)}>
        <View style={{ opacity: openToggle ? 1 : 0, height: openToggle ? 320 : 0 }}>
          <Animated.View
            style={[
              {
                opacity: fadeAnim,
                transform: positionAnim.getTranslateTransform(),
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
              }
            ]}
          >
            <IBSendZNS setFlagModal={setOpenModal} flagOpenModal={flagOpenModal} />

            <IBCustomerBehavior setFlagModal={setOpenModal} flagOpenModal={flagOpenModal} />

            <IBChangeContactInfo setFlagModal={setOpenModal} flagOpenModal={flagOpenModal} />

            <IBClickToSendEmail setFlagModal={setOpenModal} flagOpenModal={flagOpenModal} />

            <IBClientCallingResult setFlagModal={setOpenModal} flagOpenModal={flagOpenModal} />
          </Animated.View>
        </View>
        <Pressable
          style={[styles.CircleFab, { backgroundColor: openToggle ? '#FFFFFF' : '#ED1B2E' }]}
          disabled={!flagOpenModal}
          onPress={() => {
            setOpenModal(false)
          }}
        >
          {openToggle ? <assets.IBMultiTasksXIcon /> : <assets.IBMultiTasksPlusIcon />}
        </Pressable>
      </div>
    </View>
  )
})

const IBClientPolicyStackComponent = React.memo(() => {
  const [policySummaryData, setPolicySummaryData] = React.useState<
    PolicySummaryData & { prurewardsSummary: PrurewardsSummaryData }
  >()
  const [listPolicyCount, setListPolicyCount] = React.useState<{
    policyInquiryCount: PolicyInquiryCountData[]
    prurewardsPoint: number
    listClaimNumbers: string[]
  }>({ policyInquiryCount: [], prurewardsPoint: 0, listClaimNumbers: [] })

  React.useEffect(() => {
    return () => {
      setPolicySummaryData(undefined)
    }
  }, [])
  return (
    <IBClientPolicyStack.Navigator initialRouteName="DashBoardScreen" screenOptions={{ headerShown: false }}>
      <IBClientPolicyStack.Screen
        name="DashBoardScreen"
        component={(props) => (
          <IBClientDataBox
            {...props}
            setPolicySummaryData={setPolicySummaryData}
            policySummaryData={policySummaryData}
          />
        )}
      />
      <IBClientPolicyStack.Screen
        name="IBClientPolicyScreen"
        component={(props) => (
          <IBClientPolicy {...props} setListPolicyCount={setListPolicyCount} listPolicyCount={listPolicyCount} />
        )}
      />
    </IBClientPolicyStack.Navigator>
  )
})

export const IBClientScreenStack = ({ route }: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const [generalInfo, setGeneralInfo] = React.useState<{
    occupation: string
    paymentMethod: string
    waitingCall: string
    vipLevel: string
  }>()

  const [lastestCustomerBehavior, setLastestCustomerBehavior] = React.useState<CustomerBehaviorData>()

  const [listClientNumber, setListClientNumber] = React.useState<{ label: string; value: string }[]>([])

  const [masterClientName, setMasterClientName] = React.useState<string>()
  const dataGeneralInfo = [
    {
      icon: <assets.IBBriefCaseIcon />,
      data: {
        label: t('Occupation'),
        value: generalInfo?.occupation || '-'
      }
    },
    {
      icon: <assets.IBAngryIcon />,
      data: {
        label: t('Personality'),
        value:
          i18n.language === 'vi'
            ? lastestCustomerBehavior?.characterNameVi || '-'
            : lastestCustomerBehavior?.characterNameEn || '-'
      }
    },
    {
      icon: <assets.IBMoneyIcon />,
      data: {
        label: t('LatestPaymentMethod'),
        value: generalInfo?.paymentMethod || '-'
      }
    },
    {
      icon: <assets.IBClockIcon />,
      data: {
        label: t('WaitingCall'),
        value: '-'
      }
    }
  ]

  return (
    <ClientScreenContext.Provider
      value={{
        listClientNumber: listClientNumber,
        vipLevel: generalInfo?.vipLevel || '-',
        permissions: permissions,
        lastestCustomerBehavior: lastestCustomerBehavior,
        setLastestCustomerBehavior: setLastestCustomerBehavior,
        masterClientName: masterClientName
      }}
    >
      <SafeAreaView style={{ flex: 1, backgroundColor: '#F9F9F9' }}>
        <View style={[styles.container]}>
          <DrawerDashBoard
            setGeneralInfo={setGeneralInfo}
            setListClientNumber={setListClientNumber}
            setLastestCustomerBehavior={setLastestCustomerBehavior}
            setMasterClientName={setMasterClientName}
          />
          <View style={[styles.colRight]}>
            <IBClientGeneralInfo data={dataGeneralInfo} behaviorContent={lastestCustomerBehavior?.behavior || ''} />
            <IBClientPolicyStackComponent />
          </View>
        </View>
        {!_.isUndefined(generalInfo) && <IBMultiTasksFabComponent />}
      </SafeAreaView>
    </ClientScreenContext.Provider>
  )
}

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: 20
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#F9F9F9'
  },
  colLeft: {
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: '#E0E0E0',
    transform: [{ translateY: -1 }]
  },
  colRight: {
    height: '100%',
    zIndex: 1,
    padding: 15,
    paddingRight: 30,
    flexShrink: 1,
    flexGrow: 1,
    backgroundColor: '#F9F9F9'
  },
  buttonDrawer: {
    position: 'absolute',
    top: 180
  },
  otpConsent: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  textStyle: {
    fontSize: 15
  },
  CircleFab: {
    borderColor: '#ED1B2E',
    padding: 15,
    borderRadius: 40,
    borderWidth: 1
  },
  textAvaya: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'center',
    width: '100%'
  }
})
