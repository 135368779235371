import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.GO_ABROAD)
    }),
    codec
  ])

export const PremiumPayer = t.type({
  clientName: t.string,
  aboardAddress: t.union([t.string, t.undefined]),
  addressWard: t.string,
  addressDistrict: t.string,
  addressCity: t.string,
  addressCountry: t.string,
  clientPhoneCode: t.string,
  clientPhoneNumber: t.string
})

export type PremiumPayer = t.TypeOf<typeof PremiumPayer>

const payloadPulse4Ops = t.type({
  body: t.type({
    goAbroadClients: t.array(
      t.type({
        clientName: t.string,
        clientCode: t.string,

        destinationCountry: t.string,
        purpose: t.string,
        departureDate: t.string,
        duration: t.string,
        fatca: t.union([t.boolean, t.string]),

        aboardAddress: t.string,
        addressCountry: t.string,
        addressCity: t.string,
        addressDistrict: t.string,
        addressWard: t.string,

        clientPhoneCode: t.string,
        clientPhoneNumber: t.string
      })
    ),
    premiumPayer: Maybe(PremiumPayer)
  })
})

const payloadPulse = t.type({
  body: t.type({
    goAbroadClients: t.array(
      t.type({
        clientName: t.string,
        clientCode: t.string,

        destinationCountry: t.string,
        purpose: t.string,
        departureDate: t.string,
        duration: t.string,
        fatca: t.union([t.boolean, t.string]),

        aboardAddress: t.union([t.string, t.undefined]),
        addressCountry: t.union([t.string, t.undefined]),
        addressCity: t.string,
        addressDistrict: t.string,
        addressWard: t.string,

        clientPhoneCode: t.string,
        clientPhoneNumber: t.string
      })
    ),
    premiumPayer: Maybe(PremiumPayer)
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: payloadPulse
  })
)

const payloadPruonline = t.type({
  body: t.type({
    goAbroadClients: t.array(
      t.type({
        clientName: t.string,
        clientCode: t.string,

        destinationCountry: t.string,
        purpose: t.string,
        departureDate: t.string,
        duration: t.string,
        fatca: t.union([t.boolean, t.string]),

        aboardAddress: t.string,
        addressCountry: t.string,
        addressCity: t.string,
        addressDistrict: t.string,
        addressWard: t.string,

        clientPhoneCode: t.string,
        clientPhoneNumber: t.string
      })
    ),
    premiumPayer: Maybe(
      t.type({
        clientName: t.string,
        aboardAddress: t.union([t.string, t.undefined]),
        addressWard: Maybe(t.string),
        addressDistrict: Maybe(t.string),
        addressCity: t.string,
        addressCountry: t.string,
        clientPhoneCode: t.string,
        clientPhoneNumber: t.string
      })
    )
  })
})

const pruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPruonline
  })
)

export const GoAbroad = t.union([Pulse, Pulse4Ops, pruOnline])
export type GoAbroad = t.TypeOf<typeof GoAbroad>
