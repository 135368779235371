import { capFisrtLetterEachWord, form2, PayoutPopup } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'

export namespace ILPForm {
  const fundItem = t.union([
    t.type({
      isRequired: t.literal(true),
      withdrawAmount: form2.string.required
    }),
    t.type({
      isRequired: t.literal(false),
      withdrawAmount: form2.string.optional
    })
  ])

  const ILP = t.type({
    fundListError: form2.string.optional,
    isLASMessage: t.boolean,
    LASMessage: t.string,
    fundList: t.array(fundItem),
    withdrawFee: t.number,
    payout: pipe(
      t.array(PayoutPopup.SummaryCodec),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray => arr && arr.length > 0,
        () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
        'EmptyArray'
      ),
      form2.refine(
        (arr): arr is PayoutPopup.PayoutSummaryArray =>
          arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0,
        () => i18next.t('message:MS050241'),
        'TotalAmountLessThanZero'
      )
    )
  })

  export const codec = ILP

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
