import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  // ErrorHandling,
  Form,
  formatNumberWithComma,
  Input,
  InquiryComplaintData,
  // InquiryComplaintService,
  Panel,
  SelectSearch,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { VeriInquiryComplaintData, VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React, { useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Title } from '../../common'
import { InquiryComplaintForm } from './InquiryComplaintForm'
import * as O from 'fp-ts/lib/Option'
import { useLoading } from '@mxt/zio-react'
interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  validateReadOnly: () => boolean
  updatedPayloadData: VeriPayloadData
}

export const GAOperation: React.FC<Props> = ({ detail, form, validateReadOnly, updatedPayloadData }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)
  // const [generalAgencyList, setGeneralAgencyList] = useState<InquiryComplaintData.IACSources[]>([])
  // const [roleList, setRoleList] = useState<InquiryComplaintData.IACReceiptType[]>([])
  // const [evidenceStatusList, setEvidenceStatusList] = React.useState<InquiryComplaintData.IACReceiptType[]>([])
  const register = Form.register(form)
  const { setValue, control, watch } = form
  const submissionData = updatedPayloadData.body.attributesExt
  const isDisabled = validateReadOnly()

  useEffect(() => {
    if (submissionData) {
      const gaDetail = submissionData.COMPLAINT_GA_DETAILS
      pipe(
        ZIO.effect(() => {
          return {
            genAgentList: detail.complaintedDataList.generalAgencyList,
            roleArr: detail.complaintedDataList.roleList
          }
        }),
        ZIO.map((responseData) => {
          if (gaDetail && gaDetail.length > 0 && responseData) {
            const generalAgency =
              responseData.genAgentList
                .map((item) => ({
                  label: item.name,
                  value: item.code
                }))
                .find((generalAgency) => generalAgency.value === gaDetail[0].generalAgency) || null
            const roleItem = findItemFromArray(responseData.roleArr, gaDetail[0].role)
            const evidenceItem = findItemFromArray(
              detail.complaintedDataList.evidenceStatusArr,
              gaDetail[0].evidenceStatus
            )
            setValue('GA.role', roleItem)
            setValue('GA.evidenceStatus', evidenceItem)
            gaDetail[0].verifiedAmount && setValue('GA.verifiedAmount', String(gaDetail[0].verifiedAmount))
            setValue('GA.generalAgency', generalAgency)
            setValue('GA.personToBeComplained', gaDetail[0].personComplaint)
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }

    return () => {}
  }, [submissionData])

  useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const findItemFromArray = (roleArr: InquiryComplaintData.IACReceiptType[], roleCode: string | undefined) => {
    return pipe(
      roleArr.find((x) => x.code === roleCode),
      O.fromNullable,
      O.map((role) => ({
        label: i18n.language === 'en' ? role.nameEn : role.name,
        value: role.code
      })),
      O.getOrElse(() => ({
        label: '',
        value: ''
      }))
    )
  }

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  return (
    <View>
      <Title title={t('ComplaintedGaOperationsDetails')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <SelectSearch
              label={t('GeneralAgency')}
              disabled={isDisabled}
              options={detail.complaintedDataList.generalAgencyList.map((item) => ({
                value: item.code,
                label: item.name
              }))}
              // showUnderline={false}
              {...register('GA.generalAgency')}
              placeholder={showOptionLanguage()}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              key={watch('GA.generalAgency')?.value}
              isShowFullText={true}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            {/* <Input title={t('PersonToBeComplainted')} {...register('GA.personToBeComplained')} required /> */}
            <Controller
              control={control}
              name={`GA.personToBeComplained`}
              rules={{
                required: {
                  value: true,
                  message: t('message:MS020001', { field: t('PersonToBeComplainted') })
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  title={t('PersonToBeComplainted')}
                  disabled={isDisabled}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  required={true}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="GA.role"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('Role')}
                  disabled={isDisabled}
                  options={detail.complaintedDataList.roleList.map((roleItem) => ({
                    value: roleItem.code,
                    label: i18n.language === 'en' ? roleItem.nameEn : roleItem.name
                  }))}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={showOptionLanguage()}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="GA.verifiedAmount"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <Input
                  title={t('VerifiedAmount')}
                  disabled={isDisabled}
                  value={formatNumberWithComma(value)}
                  onChange={(val: string) => {
                    const verifiedVal = val.replace(/[^0-9]/g, '')
                    onChange(verifiedVal)
                  }}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  isAllowComma={true}
                  suffix={'VND'}
                  inputType={'number'}
                />
              )}
            />
          </View>

          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="GA.evidenceStatus"
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <SelectSearch
                  label={t('EvidenceStatus')}
                  disabled={isDisabled}
                  options={detail.complaintedDataList.evidenceStatusArr.map((evidenItem) => ({
                    value: evidenItem.code,
                    label: i18n.language === 'en' ? evidenItem.nameEn : evidenItem.name
                  }))}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={showOptionLanguage()}
                  onChange={(val) => {
                    onChange(val)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
