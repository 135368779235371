import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { SubmissionService } from './SubmissionService'
import { ChangeClientInfo, TransactionType } from './model'

export namespace UnderwrittingService {
  export const UWDecisionInfo = t.type({
    id: Maybe(t.string),
    uwDecision: t.string,
    codeUWDecision: t.string,
    priority: t.number
  })

  export const SaveDecisionData = t.type({
    processInstanceId: t.string,
    userRole: Maybe(t.string),
    clientNumber: t.string,
    clientName: t.string,
    transactionType: t.string,
    uwDecision: t.string,
    suspendContent: t.string,
    caseId: Maybe(t.string)
  })

  export const UpdateDecisionData = t.type({
    clientNumber: t.string,
    processInstanceId: t.string,
    clientName: t.string,
    transactionType: t.string,
    uwDecision: t.string,
    suspendContent: t.string,
    caseId: Maybe(t.string)
  })

  export const SaveNoteData = t.type({
    clientNumber: t.string,
    eventDate: t.string,
    policyNumber: t.string,
    note: t.string
  })

  export const T82InputData = t.type({
    transactionTypeWF: t.string,
    processDecision: t.string,
    uwDecision: t.string,
    productType: Maybe(t.string),
    riderHCR: Maybe(t.string),
    conditionOther: Maybe(t.string),
    updateToCore: Maybe(t.string)
  })

  export const SaveDecisionDataList = t.array(SaveDecisionData)

  export const SaveDecisionResponse = t.type({
    payload: SaveDecisionDataList
  })

  export const SaveNoteResponse = t.type({
    payload: t.array(SaveNoteData)
  })

  export type UWDecisionInfo = t.TypeOf<typeof UWDecisionInfo>
  export type SaveDecisionData = t.TypeOf<typeof SaveDecisionData>
  export type SaveDecisionDataList = t.TypeOf<typeof SaveDecisionDataList>
  export type SaveNoteData = t.TypeOf<typeof SaveNoteData>
  export type T82InputData = t.TypeOf<typeof T82InputData>
  export type UpdateDecisionData = t.TypeOf<typeof UpdateDecisionData>

  export const getLifeAssuredForUWList = (policyNum: string) =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNum), SubmissionService.getClientsByPolicy(policyNum)),
      ZIO.flatMap(([policyInfo, customerList]) => {
        return pipe(
          SubmissionService.getClients(
            [...customerList.laList.map((x) => x.clientNumberOfLA)].map((x) => ({ clientId: x }))
          ),
          ZIO.map((ClientPolicyList): ChangeClientInfo.Detail => {
            const getRole = (id: string) =>
              customerList.laList.map((x) => x.clientNumberOfLA).includes(id) ? 'LifeAssured' : '-'
            return {
              customerId: policyInfo.body.owners.id || '-',
              customerData: ClientPolicyList.body
                .filter((x) => x.dod === '99999999')
                .map((x) => {
                  return {
                    customerId: x.clientId || '-',
                    idType: x.externalIds?.SOE || '-',
                    idNum: x.externalIds?.SOE_VALUE || '-',
                    issuedAt: '',
                    name: x.firstName,
                    surName: x.surName ?? '',
                    dod: x.dod || '-',
                    fatca: x.attributesExt.FATCA_TAX_DECLARE !== 'NONE',
                    dob: x.dob || '-',
                    gender: x.sex || '-',
                    nationality: x.countryCode || '-',
                    mobilePhoneCode: x.attributesExt.MOBILE_CODE || '-',
                    mobilePhoneNumber: x.mobilePhone || '-',
                    // email: x.email || '-',
                    email: x.email || '',
                    role: getRole(x.clientId || '-')
                  }
                }),
              occupation: {
                job: '-',
                jobSpec: '-',
                title: '-',
                companyName: '-',
                companyAddress: '-',
                salary: '-'
              }
            }
          })
        )
      })
    )

  export const getUWDecisionList = () =>
    pipe(
      POApi.get(`pulseops/api/v1/t82/get-decision`)(t.array(UWDecisionInfo)),
      ZIO.map((responseData) => {
        return responseData
      })
    )
  export const saveDecision = (data: SaveDecisionDataList) =>
    pipe(
      POApi.post(`pulseops/api/v1/underwriting/save-decision`)(SaveDecisionResponse)(data),
      ZIO.map((response) => {
        return response.payload
      })
    )

  export const saveNote = (data: SaveNoteData[]) =>
    pipe(
      POApi.post(`pulseops/api/v1/underwriting/save-note`)(SaveNoteResponse)(data),
      ZIO.map((response) => {
        return response.payload
      })
    )

  export const getUWClientDecisionList = (processInstanceId: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/underwriting/get-decision`)(SaveDecisionResponse)({
        processInstanceId: processInstanceId
      }),
      ZIO.map((response) => {
        const filteredData = response.payload.map((item) => ({
          processInstanceId: item.processInstanceId,
          userRole: item.userRole ?? '',
          clientNumber: item.clientNumber,
          clientName: item.clientName,
          transactionType: item.transactionType,
          uwDecision: item.uwDecision,
          suspendContent: item.suspendContent,
          caseId: item.caseId
        }))
        return filteredData
      })
    )

  export const getNoteList = (clientNumber: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/underwriting/get-note?clientNumber=${clientNumber}`)(SaveNoteResponse),
      ZIO.map((responseData) => {
        return responseData.payload
      })
    )

  export const getNextStepFromT82 = (data: T82InputData) =>
    pipe(
      POApi.post(`pulseops/api/v1/t82/get-next-step`)(
        t.type({
          message: Maybe(t.string),
          payload: t.type({
            nextStep: t.string
          })
        })
      )(data),
      ZIO.map((responseData) => {
        return responseData.payload.nextStep
      })
    )

  export const updateUWClientDecisions = (data: UpdateDecisionData[]) =>
    pipe(
      POApi.post(`pulseops/api/v1/underwriting/update-decision`)(SaveDecisionResponse)(data),
      ZIO.map((resultData) => {
        return resultData.payload
      })
    )

  export const checkUWTransactiontype = (transactionType: TransactionType, source: string) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/underwriting/uw-check-for-transaction-type?transactionType=${transactionType}&source=${source}`
      )(
        t.type({
          status: t.type({
            code: Maybe(t.number),
            message: Maybe(t.string)
          }),
          value: t.boolean
        })
      ),
      ZIO.map((responseData) => {
        return responseData.value
      })
    )
}
