import { Task, ZIO } from '@mxt/zio'
import { Ref } from '@mxt/zio/stream'
import { HistoryItem } from '@pulseops/business/core'
import { ErrorHandling, RootNavigation } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { BusinessEditHistoryModalParam } from './root-stack-param-list'

export namespace EditHistoryModalService {
  export const viewing = Ref.make<HistoryItem | null>(null)

  export const useHistory = (resetHistory: Task<void>) => {
    React.useEffect(() => {
      const reset = pipe(
        viewing.set(null),
        ZIO.flatMap(() => resetHistory)
      )

      pipe(reset, ErrorHandling.run())

      return () => {
        pipe(reset, ErrorHandling.run())
      }
    }, [])
  }

  export const viewHistory = (param: BusinessEditHistoryModalParam) => {
    pipe(RootNavigation.navigate('BusinessEditHistoryModal', param), ErrorHandling.run())
  }
}
