import { SelectOption } from '@pulseops/common'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'

export namespace SEAWithdrawChangeConst {
  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }

  export enum Report {
    PM = 'PM',
    SM = 'SM'
  }

  export const Status: SelectOption[] = [
    { label: 'All', value: SEABillingChangeModal.Status.ALL },
    { label: 'Duplicate', value: SEABillingChangeModal.Status.DUPLICATE },
    { label: 'Time_out', value: SEABillingChangeModal.Status.TIMEOUT },
    { label: 'Fail', value: SEABillingChangeModal.Status.FAIL },
    { label: 'Success', value: SEABillingChangeModal.Status.SUCCESS }
  ]
  export const StatusVi: SelectOption[] = [
    { label: 'Tất cả', value: SEABillingChangeModal.Status.ALL },
    { label: 'Trùng', value: SEABillingChangeModal.Status.DUPLICATE },
    { label: 'Quá hạn', value: SEABillingChangeModal.Status.TIMEOUT },
    { label: 'Thất bại', value: SEABillingChangeModal.Status.FAIL },
    { label: 'Thành công', value: SEABillingChangeModal.Status.SUCCESS }
  ]
  export const ActionVi: SelectOption[] = [
    { label: 'Tất cả', value: SEABillingChangeModal.Action.ALL },
    { label: 'Đăng ký yêu cầu', value: SEABillingChangeModal.Action.N },
    { label: 'Huỷ đăng ký', value: SEABillingChangeModal.Action.D }
  ]
  export const ActionEn: SelectOption[] = [
    { label: 'All', value: SEABillingChangeModal.Action.ALL },
    { label: 'Register', value: SEABillingChangeModal.Action.N },
    { label: 'Cancel', value: SEABillingChangeModal.Action.D }
  ]
  export const TemplateList = [
    {
      label: 'Excel',
      value: Extension.EXCEL
    },
    {
      label: 'CSV',
      value: Extension.CSV
    }
  ]

  export const displayedColumns = [
    {
      id: 'id',
      name: `BillingChange:No`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'policyNumber',
      name: `BillingChange:Policy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'paymentMethod',
      name: `BillingChange:Payoutmethod`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 28
    },
    {
      id: 'exchangeId',
      name: `BillingChange:Fundlist`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 25
    },
    {
      id: 'fromDate',
      name: `BillingChange:Fromday`,
      horizontal: 'center',
      textAlign: 'center',
      widthPx: 25
    },
    {
      id: 'toDate',
      name: `BillingChange:Todate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 25
    },
    {
      id: 'createdDate',
      name: `BillingChange:Importdate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 23
    },
    {
      id: 'functionType',
      name: `BillingChange:Action`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 20
    },
    {
      id: 'importedFile',
      name: `BillingChange:ImportedFileName`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'status',
      name: `BillingChange:Status`,
      horizontal: 'center',
      textAlign: 'center',
      width: 8,
      widthPx: 16
    },
    {
      id: 'document',
      name: `BillingChange:Consentname`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'reason',
      name: `BillingChange:Failreason`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    },
    {
      id: 'importBy',
      name: `BillingChange:UserImplement`,
      textAlign: 'center',
      horizontal: 'center',
      width: 8,
      widthPx: 35
    }
  ]
  export enum ImportType {
    ImportBillingChange = 1,
    ImportPartialWithdramw = 2
  }
}
