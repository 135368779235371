import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { AmlRequestStackParamList } from './AmlRequestStackParamList'

import { AmlRequestListScreen } from './AmlRequestListScreen'
import { AmlRequestSubmissionCommonScreen } from './AmlRequestSubmissionCommon'

const Stack = createStackNavigator<AmlRequestStackParamList>()

export const AmlRequestStack = () => {
  return (
    <Stack.Navigator initialRouteName="AmlRequestListScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="AmlRequestListScreen" component={AmlRequestListScreen} />
      <Stack.Screen name="AmlRequestSubmissionCommonScreen" component={AmlRequestSubmissionCommonScreen} />
    </Stack.Navigator>
  )
}
