import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core'
import {
  AppContext,
  DatePicker,
  ErrorHandling,
  form2,
  InternalFeedbackService,
  Label,
  Select,
  sharedStyle,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Title } from '../../../common'
import { ZIO } from '@mxt/zio'
import { FontAwesome5 } from '@expo/vector-icons'
import { InternalSuspendHistoryForm } from './InternalSuspendHistory.form'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { InternalSuspendInformationConst } from '../internal-suspend-information'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import { InternalUserInfo } from '../InternalSuspendScreen'
import { Status } from '../../supplementary-info'
import { InternalHistoryContentModal } from './InternalHistoryContentModal'
import { InternalHistoryFeedbackModal } from './InternalHistoryFeedbackModal'
import { InternalContext } from '../InternalSuspendScreen'
import { useIsFocused } from '@react-navigation/native'

interface InternalSuspendHistoryProps {
  processInstanceId: string
  businessKey: string
  T31DepartmentList: InternalFeedbackService.T31DepartmentList
  userInfo: InternalUserInfo
  category: string
}

export const InternalSuspendHistory = (props: InternalSuspendHistoryProps) => {
  const { t, i18n } = useTranslation()
  const { isWide } = useMobile()
  const isFocused = useIsFocused()
  const {
    base: { control, setValue, watch, trigger }
  } = form2.useForm(InternalSuspendHistoryForm.codec, {
    defaultValues: {
      suspendHistoryList: []
    }
  })

  const { fields, append, remove } = useFieldArray<InternalSuspendHistoryForm.raw>({
    control: control,
    name: 'suspendHistoryList'
  })
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, getFooter, showToast } = React.useContext(AppContext.AppContextInstance)
  const [isContentModalOpen, setIsContentModalOpen] = React.useState<boolean>(false)
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = React.useState<boolean>(false)
  const [contentView, setContentView] = React.useState<string>('')
  const [feedbackDetail, setFeedbackDetail] = React.useState<InternalFeedbackService.InternalFeedbackDetail>()
  const { disabled } = React.useContext(InternalContext)
  const internalHistoryList = useWatch({ control, name: 'suspendHistoryList' })
  const [isEnabledUpdate, setIsEnabledUpdate] = React.useState<boolean>(false)
  const [planSuspendDate, setPlanSuspendDate] = React.useState<Date | null>(null)
  const currentDate = new Date()
  const [dateError, setDateError] = React.useState<string>()
  const [enableButton, setEnableButton] = React.useState<boolean>(false)

  React.useMemo(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (props.businessKey && isFocused) {
      setInternalHistoryDataForm()
      if (!['PS', 'CS', 'CH'].includes(props.category)){
        addFooter(false)
      }
    }
    return () => {
      setValue('suspendHistoryList', [])
      getFooter(<></>)
    }
  }, [props.businessKey])

  React.useEffect(() => {
    let isChangeUpdate = internalHistoryList.some((item, index) => item.status?.value !== item.oldStatusCode)
    setIsEnabledUpdate(isChangeUpdate)
    if (!['PS', 'CS', 'CH'].includes(props.category)){
      addFooter(isChangeUpdate)
    }
  }, [internalHistoryList])

  React.useEffect(() => {
    pipe(
      InternalFeedbackService.getInternalSuspendExpiredDate(props.processInstanceId),
      ZIO.foldM(
        (err) => {
           setPlanSuspendDate(null)
           return ZIO.fail(null)
        },
        (success) => {
          setPlanSuspendDate(moment(success, 'DD/MM/yyyy HH:mm:ss').toDate())
          return ZIO.succeed(success)
        }
      ),
      ZIO.unsafeRun({})
    )
  }, [props.processInstanceId])


  function setInternalHistoryDataForm() {
    pipe(
      InternalFeedbackService.getInternalSuspendHistoryList(props.businessKey),
      ZIO.map((historialList) => {
        if (historialList && historialList.length > 0) {
          for (let i = 0; i < historialList.length; i++) {
            const historyItem = historialList[i]
            const departmentItem = props.T31DepartmentList.find((x) => x.departmentCode === historyItem.department)
            const departmentName =
              historyItem.department +
                '-' +
                (i18n.language === 'en' ? departmentItem?.departmentEN : departmentItem?.departmentVN) ?? ''
            const levelItem = InternalSuspendInformationConst.levelList.find((x) => x.code === historyItem.level)
            const levelName = (i18n.language === 'en' ? levelItem?.description : levelItem?.descriptionVi) ?? ''
            const userEmail = props.userInfo.email
            const newItem = {
              historyID: historyItem.id,
              status: undefined,
              oldStatusCode: historyItem.status,
              isCompleteStatus: false,
              suspendCode: historyItem.suspendCode,
              user: userEmail,
              slaHour: historyItem.slaHour,
              effectiveDate: historyItem.effectiveDate,
              dueDate: historyItem.dueDate,
              department: historyItem.department,
              departmentName: departmentName,
              level: historyItem.level,
              levelName: levelName,
              content: historyItem.content,
              emailTo: historyItem.emailTo,
              emailCc: historyItem.emailCc,
              createdBy: historyItem.createdBy,
              createdDate: historyItem.createdDate,
              modifiedBy: historyItem.modifiedBy ?? '',
              modifiedDate: historyItem.modifiedDate ?? ''
            }
            append(newItem)
          }
          watch('suspendHistoryList').forEach((item, index) => {
            const historyItem = historialList[index]
            const isCompleteStatus = historyItem.status === Status.COMPLETE
            const statusItem = InternalSuspendInformationConst.statusList().find(
              (x) => x.value === historyItem.status
            ) ?? { value: '', label: '' }
            setValue(`suspendHistoryList.${index}.status`, statusItem)
            setValue(`suspendHistoryList.${index}.isCompleteStatus`, isCompleteStatus)
          })
        }
        return historialList
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const addFooter = (isEnabledUpdate: boolean) => {
    getFooter(
      disabled ? (
        <></>
      ) : (
        <View style={[sharedStyle.footer, isWide ? { marginRight: 19 } : { width: '100%', justifyContent: 'center' }]}>
          <TouchableOpacity
            style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
            onPress={cancelButtonEvent}
          >
            <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            disabled={!isEnabledUpdate}
            style={[sharedStyle.button, isEnabledUpdate ? sharedStyle.btnRed : sharedStyle.btnDisabled]}
            onPress={UpdatedSuspendData}
          >
            <Text style={isEnabledUpdate ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
              {t('common:Update')}
            </Text>
          </TouchableOpacity>
        </View>
      )
    )
  }

  const formatStringToDatetime = (value: string) => {
    const newDateFormat = !!value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
    return newDateFormat
  }

  const validateInternalSuspendDataForSave = () => {
    let isDuplicatedDepartment = false
    const internalHistorySuspendList = watch('suspendHistoryList')
    for (let i = 0; i < internalHistorySuspendList.length; i++) {
      const historyItem = internalHistorySuspendList[i]
      const duplicationFlag = internalHistorySuspendList.some(
        (oldItem, index) =>
          i !== index &&
          historyItem.department === oldItem.department &&
          historyItem.status?.value === Status.ACTIVE &&
          oldItem.status?.value === Status.ACTIVE
      )
      if (duplicationFlag) {
        isDuplicatedDepartment = true
        break
      }
    }
    return isDuplicatedDepartment
  }

  const mappingInternalSuspendHistoryList = () => {
    const updatedInternalSuspendList = watch('suspendHistoryList')
      .filter((x) => x.status?.value !== x.oldStatusCode)
      .map((internalHistory) => {
        const updatedInternalSuspend: InternalFeedbackService.InsertedInternalSuspendData = {
          id: internalHistory.historyID,
          status: internalHistory.status?.value ?? '',
          suspendCode: internalHistory.suspendCode ?? '',
          user: internalHistory.user ?? '',
          slaHour: internalHistory.slaHour ?? '',
          effectiveDate: internalHistory.effectiveDate ?? '',
          dueDate: internalHistory.dueDate ?? '',
          department: internalHistory.department ?? '',
          level: internalHistory.level ?? '',
          content: internalHistory.content ?? '',
          emailTo: internalHistory.emailTo ?? '',
          emailCc: internalHistory.emailCc ?? '',
          timeZone: ''
        }
        return updatedInternalSuspend
      })
    const submitedInternalSuspendHistory: InternalFeedbackService.SavedInternalSuspendData = {
      data: updatedInternalSuspendList,
      processInstanceId: props.businessKey
    }
    return submitedInternalSuspendHistory
  }

  const UpdatedSuspendData = async () => {
    const isValidForm = await trigger()
    const isDuplicatedDepartment = validateInternalSuspendDataForSave()
    if (isValidForm && !isDuplicatedDepartment) {
      const updatedInternalHistoryData = mappingInternalSuspendHistoryList()
      pipe(
        InternalFeedbackService.updatedInternalSuspendHistory(updatedInternalHistoryData),
        ZIO.map((responseData) => {
          if (!!responseData) {
            if (responseData.responseError.status === 'Success') {
              fields.forEach((fieldItem, index) => {
                const updatedItem = responseData.data?.find((x) => x.id === fieldItem.historyID)
                if (updatedItem) {
                  setValue(`suspendHistoryList.${index}.isCompleteStatus`, updatedItem.status === Status.COMPLETE)
                  setValue(`suspendHistoryList.${index}.oldStatusCode`, updatedItem.status)
                  updatedItem?.modifiedDate &&
                    setValue(`suspendHistoryList.${index}.modifiedDate`, updatedItem?.modifiedDate)
                  updatedItem?.modifiedBy && setValue(`suspendHistoryList.${index}.modifiedBy`, updatedItem?.modifiedBy)
                }
              })
              showToast(t('message:MS990017'), 'success')
            } else {
              showToast(t(`message:${responseData.responseError.code}`), 'error')
            }
          }
          return responseData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
    if (isDuplicatedDepartment) {
      showToast(t(`message:MS050270`), 'error')
    }
  }

  const cancelButtonEvent = () => {
    remove()
    setInternalHistoryDataForm()
  }

  const closeSuspendContentModal = () => {
    setIsContentModalOpen(false)
  }

  const openSuspendContentModal = (content: string) => {
    setIsContentModalOpen(true)
    setContentView(content)
  }

  const closeFeedbackContentModal = () => {
    setIsFeedbackModalOpen(false)
  }

  const openFeedbackContentModal = (internalSuspendID: string) => {
    pipe(
      InternalFeedbackService.getInternalFeedbackDetail(internalSuspendID),
      ZIO.map((responseData) => {
        if (!!responseData) {
          const feedbackDate = formatStringToDatetime(responseData.feedbackDate)
          const departmentItem = props.T31DepartmentList.find(
            (x) => x.departmentCode === responseData.feedbackDepartment
          )
          const departmentName =
            responseData.feedbackDepartment +
              '-' +
              (i18n.language === 'en' ? departmentItem?.departmentEN : departmentItem?.departmentVN) ?? ''
          setFeedbackDetail({
            ...responseData,
            feedbackDepartment: departmentName,
            feedbackDate: feedbackDate
          })
          setIsFeedbackModalOpen(true)
        }
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  // const onChangeEventStatus = (statusValue: string, index: number) => {
  // }
  const validateDate = (date: Date | null) => {
    if (!Number(date?.getTime())) {
      setDateError(t('common:InvalidDateFormat'))
    } else if (Number(date?.getTime()) < currentDate.getTime()) {
      setDateError(t('message:MS020043'))
    } else {
      setDateError(undefined)
    }
  }

  const updatePlanSuspendDate = () => {
    if(planSuspendDate) {
      const getHours = planSuspendDate.getHours()
      planSuspendDate.setHours(getHours + 7)
      pipe(
        InternalFeedbackService.updatePlanSuspendDate(props.processInstanceId, planSuspendDate.toISOString()),
        ZIO.map((responseData) => {
          setEnableButton(false)
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  const disableButtonUpdate = () => !!dateError || !planSuspendDate || !enableButton

  return (
    <View style={[internalHistoryStyles.container]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Title title={t('Tab:InternalSuspendHistory')}></Title>
        {!disabled && (
          <View style={{ marginBottom: 5, marginEnd: 10, alignSelf: 'flex-end', flexDirection: 'row'}}>
            <View style={{maxWidth: 250}}>
              <Label title={t('requestInfo:PlanUnsuspendDate')} />
              <DatePicker
                label=""
                value={planSuspendDate}
                minDate={currentDate}
                // maxDate={MAX_DATE}
                onChange={(val) => {
                  validateDate(val)
                  setEnableButton(true)
                  setPlanSuspendDate(val)
                }}
                errorMessage={dateError}
                invalidDateMessage={null}
                maxDateMessage={null}
                minDateMessage={null}
              />
            </View>
            <TouchableOpacity
              disabled={disableButtonUpdate()}
              style={disableButtonUpdate() ? 
                [sharedStyle.button, sharedStyle.btnDisabled] :
                [sharedStyle.button, sharedStyle.btnRed]}
              onPress={updatePlanSuspendDate}
            >
              <Text style={disableButtonUpdate() ? sharedStyle.btnTextDisabled : sharedStyle.textButtonRed}>
                {t('InternalSuspend:SaveTime')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow style={{ backgroundColor: '#e2e7ea' }}>
                <TableCell align="center" style={{ fontWeight: 'bold', fontSize: 15 }}>
                  #
                </TableCell>
                <TableCell align="left" style={{ minWidth: 220, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:Department')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:Level')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:EffectiveTime')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 250, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:ServiceLevelAgreement')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:DueDate')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('common:Status')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('SupplementaryInfo:CreatedUser')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('common:CreatedDate')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('SupplementaryInfo:UpdatedUser')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('SupplementaryInfo:UpdatedDate')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:Content')}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 200, fontWeight: 'bold', fontSize: 15 }}>
                  {t('InternalSuspend:FeedbackContent')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields &&
                fields.length > 0 &&
                fields.map((suspendItem, index) => (
                  <TableRow key={suspendItem.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="left">{suspendItem.departmentName}</TableCell>
                    <TableCell align="left">{suspendItem.levelName}</TableCell>
                    <TableCell align="left">{formatStringToDatetime(suspendItem.effectiveDate ?? '')}</TableCell>
                    <TableCell align="left">{suspendItem.slaHour}</TableCell>
                    <TableCell align="left">{formatStringToDatetime(suspendItem.dueDate ?? '')}</TableCell>
                    <TableCell align="left">
                      <Controller
                        control={control}
                        name={`suspendHistoryList.${index}.status`}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <Select
                            value={value}
                            onChange={(statusVal) => {
                              onChange(statusVal)
                              // onChangeEventStatus(statusVal?.value ?? '', index)
                            }}
                            onBlur={onBlur}
                            disabled={watch(`suspendHistoryList.${index}.isCompleteStatus`) || disabled}
                            options={InternalSuspendInformationConst.statusList()}
                            errorMessage={!!value?.value ? '' : error?.message}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="left">{suspendItem.createdBy}</TableCell>
                    <TableCell align="left">{formatStringToDatetime(suspendItem.createdDate ?? '')}</TableCell>
                    {/* <TableCell align="left">{suspendItem.modifiedBy}</TableCell> */}
                    <TableCell align="left">{watch(`suspendHistoryList.${index}.modifiedBy`)}</TableCell>
                    {/* <TableCell align="left">{formatStringToDatetime(suspendItem.modifiedDate ?? '')}</TableCell> */}
                    <TableCell align="left">
                      {formatStringToDatetime(watch(`suspendHistoryList.${index}.modifiedDate`) ?? '')}
                    </TableCell>
                    <TableCell align="left">
                      <TouchableOpacity onPress={() => openSuspendContentModal(suspendItem.content ?? '')}>
                        <FontAwesome5 name="eye" color="#ED1B2E" />
                      </TouchableOpacity>
                    </TableCell>
                    <TableCell align="left">
                      <TouchableOpacity onPress={() => openFeedbackContentModal(suspendItem.historyID)}>
                        <FontAwesome5 name="eye" color="#ED1B2E" />
                      </TouchableOpacity>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <InternalHistoryContentModal
          title={t('InternalSuspend:Content')}
          open={isContentModalOpen}
          onClose={closeSuspendContentModal}
          content={contentView}
        />

        <InternalHistoryFeedbackModal
          title={t('InternalFeedback:FEEDBACKCONTENT')}
          open={isFeedbackModalOpen}
          onClose={closeFeedbackContentModal}
          feedbackDetail={feedbackDetail}
        />
      </View>
      {['PS', 'CS', 'CH'].includes(props.category) && (
        disabled ? (
          <></>
        ) : (
          <View style={[internalHistoryStyles.buttonContainer, !isWide && { width: '100%', justifyContent: 'center' }]}>
            <TouchableOpacity
              style={[sharedStyle.button, { marginRight: 15, borderColor: '#ED1B2E' }]}
              onPress={cancelButtonEvent}
            >
              <Text style={sharedStyle.textButton}>{t('common:Cancel')}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={!isEnabledUpdate}
              style={[sharedStyle.button, isEnabledUpdate ? sharedStyle.btnRed : sharedStyle.btnDisabled]}
              onPress={UpdatedSuspendData}
            >
              <Text style={isEnabledUpdate ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
                {t('common:Update')}
              </Text>
            </TouchableOpacity>
          </View>
        )
      )}
    </View>
  )
}
export const internalHistoryStyles = StyleSheet.create({
  container: {
    marginBottom: 80
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70
  }
})
