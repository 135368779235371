import { Input, ModalAction, ModalComponent, ModalComponentRef, PayoutPopup } from '@pulseops/common'
import { FieldText } from '@pulseops/submission/common'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Text, View, StyleSheet, useWindowDimensions, ScrollView } from 'react-native'
import { OwnerInfo } from '../bank-transfer'
import { EwalletMomoForm } from './ewallet-momo.form'

export type MomoProps = {
  visible: boolean
  maxAmount: number
  primaryPolicy: string
  ownerInfo: OwnerInfo
  isFractionFormat?: boolean
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData) => void
}

export const EwalletMomoPopup = ({
  visible,
  onClose,
  ownerInfo,
  isFractionFormat,
  maxAmount,
  onConfirm,
  primaryPolicy
}: MomoProps) => {
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()
  const modalRef = React.useRef<ModalComponentRef | null>(null)
  const ewalletMomoForm = useForm<EwalletMomoForm.EwalletMomoData>({
    defaultValues: {
      policyOwner: ownerInfo.ownerName,
      IDNumber: ownerInfo.nationalId,
      phoneNumber: ownerInfo.mobilePhone,
      amount: maxAmount.toString()
    },
    mode: 'all'
  })

  React.useEffect(() => {
    if (ownerInfo) {
      ewalletMomoForm.setValue('policyOwner', ownerInfo.ownerName)
      ewalletMomoForm.setValue('IDNumber', ownerInfo.nationalId)
      ewalletMomoForm.setValue('phoneNumber', ownerInfo.mobilePhone)
    }
  }, [ownerInfo])

  const onPressConfirm = ewalletMomoForm.handleSubmit((_) => {
    const formData = ewalletMomoForm.getValues()
    const amount = Number(formData.amount)
    const payeeName = formData.policyOwner
    const payout: PayoutPopup.PayoutData = {
      method: PayoutPopup.PayoutMethods.MOMO,
      amount: amount,
      policyNum: primaryPolicy,
      poName: payeeName,
      officeCode: '',
      bankCode: '',
      bankName: '',
      bankAccountNum: '',
      bankAccountName: '',
      bankBranchCode: '',
      bankBranchName: '',
      nationalId: formData.IDNumber,
      officeBankCode: '',
      officeType: '',
      payee: {
        paymentMethod: PayoutPopup.PayoutMethods.MOMO,
        isPayeeNotPO: false,
        isNewClient: false,
        payeeName: payeeName,
        idNumber: formData.IDNumber,
        dob: undefined,
        gender: undefined,
        phone: formData.phoneNumber,
        email: undefined,
        salutation: undefined,
        married: undefined,
        occupation: undefined,
        relationshipWithPO: undefined,
        nationality: undefined,
        address: undefined,
        nationality2: undefined,
        foreignAddress: undefined,
        foreignCountry: undefined,
        residenceCountry: undefined,
        issuedDate: undefined,
        issuedBy: undefined,
        issueCode: undefined,
        name: undefined,
        dod: undefined,
        surName: undefined,
        giveName: undefined,
        city: undefined,
        district: undefined,
        ward: undefined,
        street: undefined,
        relativeDocument: undefined
      }
    }
    onConfirm(payout)
  })

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: momoStyles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled: !ewalletMomoForm.formState.isValid || Number(ewalletMomoForm.watch('amount') || 0) > maxAmount,
      loading: false,
      onlyWide: false,
      style: momoStyles.btnConfirm
    }
  ]

  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:EWalletMomo')}
      titleStyle={momoStyles.modalTitle}
      contentStyle={momoStyles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onClose}
      actions={modalActions}
    >
      <ScrollView style={momoStyles.container}>
        <View style={momoStyles.modalBlock}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <FieldText text={t('common:PolicyOwner')}></FieldText>
              <FieldText
                fieldStyles={momoStyles.field_description}
                text={ewalletMomoForm.watch('policyOwner')}
              ></FieldText>
            </View>
            <View style={{ flex: 1 }}>
              <FieldText text={t('submission:IDNumber')}></FieldText>
              <FieldText
                fieldStyles={momoStyles.field_description}
                text={ewalletMomoForm.watch('IDNumber')}
              ></FieldText>
            </View>
          </View>

          <View style={{ flex: 1, flexDirection: 'row', marginTop: 20 }}>
            <View style={{ flex: 1 }}>
              <FieldText text={t('submission:PhoneNumber')}></FieldText>
              <FieldText
                fieldStyles={momoStyles.field_description}
                text={ewalletMomoForm.watch('phoneNumber')}
              ></FieldText>
            </View>
            <View style={{ flex: 1 }}>
              <Controller
                control={ewalletMomoForm.control}
                name={'amount'}
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('submission:Amount') })
                  },
                  validate: (value) => {
                    if (Number(value) <= 0) {
                      return `${t('message:MS100004', { num: 0 })}`
                    } else if (Number(value || 0) > maxAmount) {
                      return `${t('message:MS050159')}`
                    } else if (Number(value) > 50000000) {
                      return `${t('message:MS040007', { k: 50 })}`
                    } else {
                      return true
                    }
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    required={true}
                    title={t('submission:Amount')}
                    containerStyle={{ flex: 1 }}
                    inputType={'money'}
                    value={field.value || ''}
                    isFractionFormat={isFractionFormat}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>
        </View>
        <View style={{ marginTop: 20 }}>
          <Text style={momoStyles.noteText}>{t('submission:EwalletNote')}</Text>
        </View>
      </ScrollView>
    </ModalComponent>
  )
}
const momoStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 30
  },
  modalTitle: {
    textAlign: 'center'
  },
  modalContent: {
    backgroundColor: '#EEEEEE'
  },
  modalBlock: {
    flex: 1,
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginTop: 20,
    paddingHorizontal: 30,
    paddingVertical: 22
  },
  btnCancel: {
    height: 39,
    marginEnd: 15
  },
  btnConfirm: {
    height: 39
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  noteText: {
    fontSize: 15,
    color: '#ED1B2C'
  }
})
