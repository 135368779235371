import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

export const FSR_LifeAssureds = t.array(
  t.type({
    attributesExt: t.type({
      CLIENT_NO: t.string,
      H_ANSWER: t.string,
      O_ANSWER: t.string,
      CURRENT_OCCUPATION: t.string,
      NEW_OCCUPATION: t.string,
      A_ANSWER: t.string,
      NEW_ACTIVITY: t.string,
      C_ANSWER: t.string,
      COMPANY_NAME: t.string,
      AGENT_CODE: t.string
    })
  })
)

export const FullSurrenderReversalData = t.type({
  transactionType: t.literal(TransactionType.FULL_SURRENDER_REVERSAL),
  payload: t.type({
    body: t.type({
      surrenderValue: Maybe(t.number),
      attributesExt: t.type({
        PAID_CASH_BENEFITS: t.string,
        NOT_PAID_PREMIUM: t.string,
        POLICY_LOANS: Maybe(t.string),
        REINSTATEMENT_FEE: t.string,
        REASON: Maybe(t.string)
      }),
      lifeAssureds: FSR_LifeAssureds
    })
  }),
  source: Maybe(t.string)
})
export type FullSurrenderReversalData = t.TypeOf<typeof FullSurrenderReversalData>
