import * as React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { assets, getLanguage } from '@pulseops/common'
import { AppConfig } from '../../config'

interface Props {
  data: AppConfig.Notice
}

export const Notice = ({ data }: Props) => {
  return data?.messageEn && data?.messageVi ? (
    <View
      style={[
        styles.container,
        {
          backgroundColor: data?.bgColor || '#ED1B2E'
        }
      ]}
    >
      <assets.NoticeIcon />
      <Text
        style={[
          styles.message,
          {
            color: data?.textColor || '#FFFFFF'
          }
        ]}
      >
        {getLanguage() === 'en' ? data.messageEn : data.messageVi}
      </Text>
    </View>
  ) : null
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 13,
    flexDirection: 'row'
  },
  message: {
    marginStart: 12,
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 20
  }
})
