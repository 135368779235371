import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions, ScrollView } from 'react-native'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { UploadDocumentsService, ErrorHandling, Divider, UploadDocumentData } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type Props = {
  taskId: string
}
export const UploadDocumentDetail: React.FC<Props> = ({ taskId }) => {
  //const [requestDetails, setRequestDetails] = React.useState<UploadDocumentsService.DetailUploadDocument[]>([])
  const [loading, bindLoading] = useLoading(false)
  const { height } = useWindowDimensions()
  const composeHeigth = Math.max(height - 200, 400)
  pipe(
    UploadDocumentsService.getHistoricUploadDocumentDetail(taskId),
    ZIO.map((value) => {
      console.log(value)
    })
  )

  const { requests, departments } = pipe(
    ZIO.zipPar(UploadDocumentsService.getHistoricUploadDocumentDetail(taskId), UploadDocumentsService.getDetail()),
    ZIO.map(([requests, departments]) => {
      //console.log('data success', requests)
      return {
        requests,
        departments
      }
    }),
    ZIO.tapError((_) => ZIO.unit),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || { requests: null, departments: null }

  if (loading && !requests && !departments) {
    return (
      <View style={{ alignSelf: 'center' }}>
        <CircularProgress color={'primary'} />
      </View>
    )
  }
  return (
    <View style={[styles.container, { maxHeight: composeHeigth }]}>
      <ScrollView>
        {requests?.map((request) => {
          return <DetailView detail={request} departments={departments!} />
        })}
      </ScrollView>
    </View>
  )
}

const DetailView: React.FC<{
  detail: UploadDocumentsService.DetailUploadDocument
  departments: UploadDocumentData.DetailData
}> = ({ detail, departments }) => {
  const { t, i18n } = useTranslation()
  //const [ loading, bindLoading ] = useLoading()
  const departName = departments.DepartmentList.find((v) => v.departmentShortName === detail.department)
  const showDeparmentName = departName
    ? i18n.language === 'vi'
      ? departName.departmentVN
      : departName.departmentEN
    : ''

  const documentType = pipe(
    UploadDocumentsService.getDocumentTypeName(detail.department, detail.documentType),
    ZIO.nullable,
    ZIO.map((doc) => {
      if (doc) {
        return i18n.language === 'vi' ? doc.doctypeVn : doc.doctypeEn
      }
      return ''
    }),
    //bindLoading,
    ErrorHandling.runDidUpdate([i18n.language])
  )
  return (
    <View style={styles.pannel}>
      <View style={styles.row}>
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Text style={styles.title}>{t('utilities:department')}</Text>
          <Text>{showDeparmentName}</Text>
        </View>
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Text style={styles.title}>{t('utilities:documentType')}</Text>
          <Text>{documentType || ''}</Text>
        </View>
      </View>
      <Divider height={20} />
      <Text style={[styles.title, { paddingHorizontal: 10, paddingVertical: 5 }]}>{t('utilities:FileAttachment')}</Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {detail.requestDocuments.map((document) => {
          return (
            <View style={styles.fileContainer}>
              <Text>{document.name}</Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center'
  },
  pannel: {
    borderWidth: 1,
    borderColor: '#d3dce6',
    borderRadius: 8,
    backgroundColor: '#fafafa',
    padding: 16,
    margin: 16
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#70777E'
  },
  row: {
    flexDirection: 'row'
  },
  fileContainer: {
    width: '45%',
    marginHorizontal: 10,
    borderBottomWidth: 1,
    paddingVertical: 10,
    borderStyle: 'dashed',
    borderBottomColor: '#D3DCE6'
  }
})
