import React from 'react'
import { View, useWindowDimensions, Platform, Text } from 'react-native'
import {
  useMobile,
  InputTable,
  ErrorHandling,
  RBAC,
  BackOfficePermission,
  SelectTable,
  SelectOption,
  AppContext,
  Alert
} from '@pulseops/common'
import TableContainer from '@material-ui/core/TableContainer'
import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { StackScreenProps } from '@react-navigation/stack'
import { BOStackParamList } from './BOStackParamList'
import { pipe } from 'fp-ts/lib/function'
import { ImportButton } from './import-button'
import { BOModelMapping, BOService } from './services'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { LoadingScreen } from './common'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useIsFocused } from '@react-navigation/native'
import * as E from 'fp-ts/Either'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellWidth200: {
    minWidth: 200
  },

  stickyLeft: {
    position: 'sticky',
    left: 0
  }
})

type Props = StackScreenProps<BOStackParamList, 'BODashboardScreen'>

export const BODashboardScreen = (props: Props) => {
  const { width } = useWindowDimensions()
  const { isWide } = useMobile()

  const classes = useStyles()

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)

  const [quotationFilter, setQuotationFilter] = React.useState<string | undefined>(undefined)
  const [statusFilter, setStatusFilter] = React.useState<SelectOption | undefined | null>(undefined)

  const { t } = useTranslation()

  const [loading, bindLoading] = useLoading()

  const [rows, setRows] = React.useState<
    {
      id: string
      policyNum: string
      createdDate: string
      companyName: string
      email: string
      agentName: string
      agentPhone: string
      agentEmail: string
      status: string
      detail: BOModelMapping
    }[]
  >([])

  const loader = (
    pageSize: number,
    pageNum: number,
    sort: string,
    order: 'asc' | 'desc',
    quotationFilter: string | undefined,
    statusFilter: string | undefined
  ) =>
    pipe(
      BOService.getBOList(pageSize, pageNum, sort, order, quotationFilter, statusFilter),
      ZIO.tap((res) => {
        setTotalItem(res.length)
        setRows(
          res.map((x, i) => ({
            id: `Policy-${i}-${x.policyNum}`,
            policyNum: x.policyNum,
            createdDate: x.createdDate,
            companyName: x.company.companyName,
            email: x.company.companyEmail,
            agentName: x.agent.agentname,
            agentPhone: x.agent.agentMobile,
            agentEmail: x.agent.agentEmail,
            status: x.status,
            detail: x
          }))
        )
        return ZIO.unit
      }),
      bindLoading
    )

  const roles = pipe(
    ZIO.zipPar(loader(pageSize, pageNum, 'createAt', 'desc', quotationFilter, statusFilter?.value), RBAC.permissions),
    ZIO.flatMap(([_, permission]) => pipe(BOService.setPermission(permission))),
    ZIO.absolveOption(() => Throwable('MissingPermission')),
    ErrorHandling.runDidMount()
  )

  const getPolicy = (
    pageSize: number,
    pageNum: number,
    sort: string,
    order: 'asc' | 'desc',
    quotationFilter: string | undefined,
    statusFilter: string | undefined
  ) => {
    pipe(loader(pageSize, pageNum, sort, order, quotationFilter, statusFilter), ErrorHandling.run())
  }

  const statusOptions = [
    { label: 'submitted', value: 'submitted' },
    { label: 'in-evaluation', value: 'in-evaluation' },
    { label: 'withdrawn', value: 'withdrawn' }
  ]

  const status = (status: string) => (
    <View
      style={{
        backgroundColor: 'rgba(86, 204, 242, 0.3)',
        borderRadius: 16,
        paddingVertical: 3,
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      <Text style={{ color: '#1EA5FC', textAlign: 'center' }}>{status === 'IF' ? 'In-force' : status}</Text>
    </View>
  )

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        { title: 'Mẫu đơn yêu cầu', navigate: null },
        { title: 'Danh sách', navigate: null }
      ])
    }
  }, [])

  return (
    <SafeAreaView style={{ height: '100%' }}>
      <LoadingScreen loading={loading}></LoadingScreen>
      <View
        style={{
          // width: width > 1099 ? width - 120 * 2 : "100%",
          width: width >= 1440 ? 1440 : '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '100%'
        }}
      >
        <View
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '10px'
          }}
        >
          <ImportButton
            onImport={(file) => {
              pipe(
                BOService.importContractInfo(file),
                ZIO.map(
                  E.fold(
                    (err) => {
                      switch (err.tag) {
                        case 'Fail':
                          return 'message:MS000018'
                        case 'Duplicated':
                          return 'message:MS000016'
                      }
                    },
                    () => 'message:MS000019'
                  )
                ),
                ZIO.map((m) => t(m)),
                ZIO.flatMap(Alert.alertM),
                ErrorHandling.run()
              )
            }}
          />
        </View>
        {/* <BreadCrumb navigation={[
        { title: "", navigate: () => {props.navigation.replace('HomeScreen')} },
        { title: "Mẫu đơn yêu cầu", navigate: null},
        { title: "Danh sách", navigate: null},
      ]}></BreadCrumb> */}

        {roles && roles.includes(BackOfficePermission.List.View) && isWide && Platform.OS === 'web' && (
          <View style={{ marginTop: 20, paddingHorizontal: 10 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell
                      style={{ backgroundColor: '#E5EAEF', fontWeight: 'bold' }}
                      className={classes.stickyLeft}
                    >
                      Số HSYCBH
                    </TableCell>
                    <TableCell className={classes.cellWidth200} style={{ fontWeight: 'bold' }}>
                      Ngày lập HSYCBH
                    </TableCell>
                    <TableCell className={classes.cellWidth200} style={{ fontWeight: 'bold' }}>
                      Tên Công Ty/Tổ Chức
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Thư điện tử</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Họ và tên TVV</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Điện thoại TVV</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Email TVV</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Trạng thái HSYCBH</TableCell>
                  </TableRow>
                  {roles && roles.includes(BackOfficePermission.List.Search) && false && (
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#E5EAEF', zIndex: 1 }} className={classes.stickyLeft}>
                        <InputTable
                          value={quotationFilter}
                          onChange={(value) => {
                            pipe(setQuotationFilter(value), () =>
                              getPolicy(pageSize, pageNum, 'createAt', 'desc', value, statusFilter?.value)
                            )
                          }}
                        ></InputTable>
                      </TableCell>
                      <TableCell className={classes.cellWidth200}></TableCell>
                      <TableCell className={classes.cellWidth200}></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <SelectTable
                          id={'Status'}
                          options={statusOptions}
                          style={{ backgroundColor: '#FFF' }}
                          value={statusFilter}
                          onChange={(val) => {
                            pipe(setStatusFilter(val), () =>
                              getPolicy(pageSize, pageNum, 'createAt', 'desc', quotationFilter, val?.value)
                            )
                          }}
                        ></SelectTable>
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={`Botable-${row.id}`}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ backgroundColor: '#FFF' }}
                        className={classes.stickyLeft}
                      >
                        <Text
                          style={{ color: 'blue' }}
                          onPress={() => {
                            pipe(
                              BOService.setDetail(row.detail),
                              ZIO.tap((x) => {
                                props.navigation.navigate('BODetailScreen', { policyNum: row.policyNum })
                                return ZIO.unit
                              }),
                              ErrorHandling.run()
                            )
                          }}
                        >
                          {row.policyNum}
                        </Text>
                      </TableCell>
                      <TableCell className={classes.cellWidth200}>{row.createdDate}</TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.agentName}</TableCell>
                      <TableCell>{row.agentPhone}</TableCell>
                      <TableCell>{row.agentEmail}</TableCell>
                      <TableCell>
                        <View>{status(row.status)}</View>
                      </TableCell>
                    </TableRow>
                  ))}
                  {rows.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                        {t('common:noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getPolicy(pageSize, page, 'createAt', 'desc', quotationFilter, statusFilter?.value)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                getPolicy(Number(e.target.value), pageNum, 'createAt', 'desc', quotationFilter, statusFilter?.value)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}
