import { useWindowDimensions } from 'react-native'

export const useMobile = () => {
  const { width } = useWindowDimensions()
  const isMobile = width < 768
  const isWide = !isMobile
  return {
    isMobile,
    isWide, 
    width
  }
}
