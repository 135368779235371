export type ContractId = {
  masterContractNo: string
  policyYear: number
}

export namespace ContractId {
  export const toString = (id: ContractId): string => [id.masterContractNo, id.policyYear].join('_')

  export const toParams = ({ masterContractNo, policyYear }: ContractId): string =>
    [`masterContractNo=${masterContractNo}`, `policyYear=${policyYear}`].join('&')
}
