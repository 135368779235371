import { Any, ZIO } from '@mxt/zio'
import { enumC } from '@mxt/zio/codec'
import * as D from 'date-fns'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import { Either } from 'fp-ts/Either'
import { identity, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { Refinement } from 'fp-ts/Refinement'
import i18next from 'i18next'
import * as t from 'io-ts'
import { ContextEntry } from 'io-ts'
import { date as dateCodec, IntFromString, NonEmptyString, NumberFromString, withMessage } from 'io-ts-types'
import * as _ from 'lodash'
import {
  DeepPartial,
  FieldErrors,
  Resolver,
  ResolverResult,
  UnpackNestedValue,
  useForm as useFormBase,
  UseFormReturn
} from 'react-hook-form'
import { Format } from './format'

export namespace form2 {
  const resolver = <C extends t.Any>(formCodec: C): Resolver<t.OutputOf<C>> => {
    return (values, context, options) => {
      // console.log('validate', values)
      console.log('validate result', formCodec.decode(values))
      return pipe(
        formCodec.decode(values),
        E.fold(
          (errors) =>
            pipe(
              errors,
              A.map((error) => {
                const context = error.context.filter((c, i, arr) => {
                  const getPrevType = (step: number): string | null => {
                    const prevContext = arr[i - step]
                    if (!prevContext) {
                      return null
                    }
                    const prevType = _.get(prevContext.type, '_tag')

                    if (prevType === 'RefinementType') {
                      return _.get(prevContext.type, 'type._tag')
                    }
                    return prevType
                  }

                  const prevType = getPrevType(1)

                  return prevType ? !['UnionType', 'IntersectionType'].includes(prevType) : true
                })

                const path = context
                  .map((c) => c.key)
                  .filter(Boolean)
                  .join('.')

                const type = pipe(
                  A.last(context as ContextEntry[]),
                  O.map((c) => c.type.name),
                  O.getOrElse(() => '')
                )
                return { path, type, message: error.message, context: error.context }
              }),
              A.reduce({}, (errors: FieldErrors<any>, err) =>
                err.message
                  ? _.set(errors, err.path, {
                      type: err.type,
                      message: err.message,
                      context: err.context
                    })
                  : errors
              ),
              (errors) => ({
                values: {},
                errors: errors
              })
            ),
          (values): ResolverResult<t.TypeOf<C>> => ({ values, errors: {} })
        )
      )
    }
  }

  export function refine<C extends t.Any, N extends string, B extends { readonly [K in N]: symbol }>(
    predicate: Refinement<t.TypeOf<C>, t.Branded<t.TypeOf<C>, B>>,
    message: (a: t.TypeOf<C>) => string,
    name: N
  ): (codec: C) => t.BrandC<C, B> {
    return (codec) => {
      return new t.RefinementType(
        name,
        (u): u is t.TypeOf<C> => codec.is(u) && predicate(u),
        (i, c) => {
          const e = codec.validate(i, c)
          if (E.isLeft(e)) {
            return e
          }
          const a = e.right
          return predicate(a) ? t.success(a) : t.failure(a, c, message(a))
        },
        codec.encode,
        codec,
        predicate
      )
    }
  }

  export type Form<C extends t.Mixed> = {
    base: Omit<UseFormReturn<t.OutputOf<C>>, 'handleSubmit'>
    handleSubmit: (
      f: (validated: t.TypeOf<C>) => void
    ) => (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  }

  type FormMode = 'all' | 'onChange' | 'onSubmit' | 'onBlur' | 'onTouched'

  export const useForm = <C extends t.Mixed>(
    formCodec: C,
    options?: {
      defaultValues: UnpackNestedValue<DeepPartial<t.OutputOf<C>>> | undefined
    },
    formMode: FormMode = 'all'
  ) => {
    type Raw = t.OutputOf<C>
    type Validated = t.TypeOf<C>
    const base = useFormBase<Raw>({
      resolver: resolver(formCodec),
      defaultValues: options?.defaultValues,
      // mode: 'all'
      mode: formMode
    })

    return {
      base: base as Omit<UseFormReturn<Raw>, 'handleSubmit'>,
      handleSubmit:
        (
          handler: (validated: Validated) => void | Promise<Any>
        ): (() => Promise<Either<FieldErrors<Raw>, Validated>>) =>
        () =>
          pipe(
            ZIO.effectAsync<Any, FieldErrors<Raw>, Validated>((cb) => {
              base.handleSubmit(
                (data) => {
                  const a = handler(data)
                  const b = a || Promise.resolve()

                  return b.then(() => {
                    cb(ZIO.succeed(data))
                  })
                },
                (errors) => {
                  console.log('submit errors', errors)
                  cb(ZIO.fail(errors))
                }
              )()
            }),
            ZIO.runPromise({})
          )
    }
  }

  export function optional<C extends t.Mixed>(codec: C) {
    const isOptional = (u: t.InputOf<C>) => u == null || u === ''
    return new t.Type<t.TypeOf<C> | null, t.OutputOf<C> | null, t.InputOf<C> | null>(
      codec.name,
      (u): u is t.TypeOf<C> | null => isOptional(u) || codec.is(u),
      (u, c) => pipe(isOptional(u) ? t.success(u) : codec.validate(u, c)),
      (a) => (isOptional(a) ? null : codec.encode(a))
    )
  }

  const requiredMessage = () => i18next.t('error_required_field', { ns: 'form' })
  const invalidDateMessage = () => i18next.t('error_invalid_date', { ns: 'form' })

  export namespace string {
    export const required: t.Type<NonEmptyString, string | null> = withMessage(NonEmptyString, requiredMessage)
    export const requiredM = (message: () => string): t.Type<NonEmptyString, string | null> =>
      withMessage(NonEmptyString, message)
    export type Required = t.TypeOf<typeof required>

    export const optional = form2.optional(t.string)
    export type Optional = t.TypeOf<typeof optional>

    export type MinLengthStringBrand = {
      readonly MinLengthString: unique symbol
    }

    export const minLength =
      (minLength: number, message: () => string) =>
      <Codec extends t.Mixed>(codec: Codec) =>
        pipe(
          codec,
          refine(
            (val): val is t.Branded<t.TypeOf<typeof codec>, MinLengthStringBrand> => !val || val.length >= minLength,
            () => message(),
            'MinLengthString'
          )
        )

    export type MaxLengthStringBrand = {
      readonly MaxLengthString: unique symbol
    }
    export type MaxLengthString = t.Branded<string, MaxLengthStringBrand>
    export const maxLength =
      <Codec extends t.Mixed>(codec: Codec) =>
      (maxLen: number, message: () => string = () => i18next.t('form:error_max_str', { maxLen: maxLen })) =>
        pipe(
          codec,
          refine(
            (val): val is MaxLengthString => !val || val.length <= maxLen,
            () => message(),
            'MaxLengthString'
          )
        )
    export const requiredMaxLength = maxLength(required)
    export const optionalMaxLength = maxLength(optional)
  }

  export const stringEnum = <T extends string>(
    e:
      | object
      | {
          [k: string]: T
        }
  ) => {
    const codec = enumC(e)
    const required: t.Type<T, string | null> = withMessage(codec, requiredMessage)
    const requiredM = (message: () => string): t.Type<T, string | null> => withMessage(codec, message)
    const optional = form2.optional(codec)

    return {
      required,
      requiredM,
      optional
    }
  }

  export namespace email {
    export type EmailBrand = {
      readonly Email: unique symbol
    }

    export const validate = (val: string) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val)

    export const required = pipe(
      string.required,
      refine(
        (val): val is t.Branded<NonEmptyString, EmailBrand> => validate(val),
        () => i18next.t('form:error_email'),
        'Email'
      )
    )

    export const optional = pipe(
      string.optional,
      refine(
        (val): val is t.Branded<string | null, EmailBrand> => !val || validate(val),
        () => i18next.t('form:error_email'),
        'Email'
      )
    )
  }

  export namespace phone {
    export type PhoneNumberBrand = {
      readonly PhoneNumber: unique symbol
    }

    const validate = (val: string) => /^[0-9]*$/.test(val) && val.length <= 10

    export const required = pipe(
      string.required,
      refine(
        (val): val is t.Branded<NonEmptyString, PhoneNumberBrand> => validate(val),
        () => i18next.t('form:error_phone'),
        'PhoneNumber'
      )
    )

    export const optional = pipe(
      string.optional,
      refine(
        (val): val is t.Branded<string | null, PhoneNumberBrand> => !val || validate(val),
        () => i18next.t('form:error_phone'),
        'PhoneNumber'
      )
    )
  }

  export namespace number {
    export const required: t.Type<number, string | null> = withMessage(NumberFromString, (i, c) =>
      string.required.is(i) ? i18next.t('form:error_required_num') : requiredMessage()
    )
    export const requiredM = (message: () => string): t.Type<number, string | null> =>
      withMessage(NumberFromString, message)
    export const optional = form2.optional(required)

    export type MaxNumberBrand = {
      readonly MaxNumber: unique symbol
    }
    export const requiredMax = (max: number) =>
      pipe(
        required,
        refine(
          (val): val is t.Branded<number, MaxNumberBrand> => val <= max,
          () => i18next.t('form:error_max_num', { max: Format.number(max) }),
          'MaxNumber'
        )
      )
    export const optionalMax = (max: number) =>
      pipe(
        optional,
        refine(
          (val): val is t.Branded<number | null, MaxNumberBrand> => val == null || val <= max,
          () => i18next.t('form:error_max_num', { max: Format.number(max) }),
          'MaxNumber'
        )
      )

    export type MinNumberBrand = {
      readonly MinNumber: unique symbol
    }
    export const requiredMin = (min: number) =>
      pipe(
        required,
        refine(
          (val): val is t.Branded<number, MinNumberBrand> => val >= min,
          () => i18next.t('form:error_min_num', { min: Format.number(min) }),
          'MinNumber'
        )
      )
    export const optionalMin = (min: number) =>
      pipe(
        optional,
        refine(
          (val): val is t.Branded<number | null, MinNumberBrand> => val == null || val >= min,
          () => i18next.t('form:error_min_num', { min: Format.number(min) }),
          'MinNumber'
        )
      )
  }

  export namespace int {
    export const required: t.Type<t.Int, string | null> = withMessage(IntFromString, (i, c) =>
      string.required.is(i) ? i18next.t('form:error_required_int') : requiredMessage()
    )
    export const optional = form2.optional(required)

    export type MaxIntBrand = {
      readonly MaxInt: unique symbol
    }
    export const requiredMax = (max: number) =>
      pipe(
        required,
        refine(
          (val): val is t.Branded<t.Int, MaxIntBrand> => val <= max,
          () => i18next.t('form:error_max_num', { max: Format.number(max) }),
          'MaxInt'
        )
      )
    export const optionalMax = (max: number) =>
      pipe(
        optional,
        refine(
          (val): val is t.Branded<t.Int | null, MaxIntBrand> => val == null || val <= max,
          () => i18next.t('form:error_max_num', { max: Format.number(max) }),
          'MaxInt'
        )
      )

    export type MinIntBrand = {
      readonly MinInt: unique symbol
    }
    export const requiredMin = (min: number) =>
      pipe(
        required,
        refine(
          (val): val is t.Branded<t.Int, MinIntBrand> => val >= min,
          () => i18next.t('form:error_min_num', { min: Format.number(min) }),
          'MinInt'
        )
      )
    export const optionalMin = (min: number) =>
      pipe(
        optional,
        refine(
          (val): val is t.Branded<t.Int | null, MinIntBrand> => val == null || val >= min,
          () => i18next.t('form:error_min_num', { min: Format.number(min) }),
          'MinInt'
        )
      )
  }

  type LiteralValue = string | number | boolean

  export function literal<V extends LiteralValue>(value: V) {
    return {
      required: t.literal(value) as any as t.Type<V, string | null>
    }
  }

  export namespace date {
    export type ValidDateBrand = {
      readonly ValidDate: unique symbol
    }

    export type ValidDate = t.Branded<Date, ValidDateBrand>

    export const required: t.Type<ValidDate, Date | null> = pipe(
      withMessage(dateCodec, requiredMessage),
      refine(
        (d): d is ValidDate => !isNaN(d.getTime()),
        () => invalidDateMessage(),
        'ValidDate'
      )
    )
    export const requiredM = (message: () => string): t.Type<Date, Date | null> => withMessage(dateCodec, message)

    export const optional = form2.optional(required)

    export type MinDateBrand = {
      readonly MinDate: unique symbol
    }
    export const requiredMin = (min: Date) =>
      pipe(
        required,
        refine(
          (d): d is t.Branded<ValidDate, MinDateBrand> => D.isAfter(d, min),
          () => i18next.t('error_min_date', { minDate: D.format(min, 'dd-MM-yyyy'), ns: 'form' }),
          'MinDate'
        )
      )
    export const optionalMin = (min: Date) =>
      pipe(
        optional,
        refine(
          (d): d is t.Branded<ValidDate | null, MinDateBrand> => d == null || D.isAfter(d, min),
          () => i18next.t('error_min_date', { minDate: D.format(min, 'dd-MM-yyyy'), ns: 'form' }),
          'MinDate'
        )
      )

    export type MaxDateBrand = {
      readonly MaxDate: unique symbol
    }
    export const requiredMax = (max: Date) =>
      pipe(
        required,
        refine(
          (d): d is t.Branded<ValidDate, MaxDateBrand> => D.isBefore(d, max),
          () => i18next.t('error_max_date', { maxDate: D.format(max, 'dd-MM-yyyy'), ns: 'form' }),
          'MaxDate'
        )
      )
    export const optionalMax = (max: Date) =>
      pipe(
        optional,
        refine(
          (d): d is t.Branded<ValidDate | null, MaxDateBrand> => d == null || D.isBefore(d, max),
          () => i18next.t('error_max_date', { maxDate: D.format(max, 'dd-MM-yyyy'), ns: 'form' }),
          'MaxDate'
        )
      )

    export type PastDateBrand = {
      readonly PastDate: unique symbol
    }
    export const requiredPast = pipe(
      required,
      refine(
        (d): d is t.Branded<ValidDate, PastDateBrand> => D.isBefore(d, new Date()),
        () => i18next.t('form:error_past_date'),
        'PastDate'
      )
    )
    export const optionalPast = pipe(
      optional,
      refine(
        (d): d is t.Branded<ValidDate | null, PastDateBrand> => d == null || D.isBefore(d, new Date()),
        () => i18next.t('form:error_past_date'),
        'PastDate'
      )
    )
  }

  export namespace selectOption {
    export type SelectOption<V = string> = {
      label: string
      value: V
    }

    export const required: t.Type<SelectOption, SelectOption | null> = withMessage(
      t.type({
        label: t.string,
        value: t.string
      }),
      requiredMessage
    )
    export const requiredM = (message: () => string): t.Type<SelectOption, SelectOption | null> =>
      withMessage(
        t.type({
          label: t.string,
          value: t.string
        }),
        message
      )

    export const optional = form2.optional(required)

    export const requiredEnum = <T>(
      e:
        | object
        | {
            [k: string]: T
          }
    ): t.Type<SelectOption<T>, SelectOption<T> | null> =>
      withMessage(
        t.type({
          label: t.string,
          value: enumC(e)
        }),
        requiredMessage
      )

    export const value = <T extends string>(...value: T[]) => {
      return t.type({
        label: t.string,
        value: pipe(
          value,
          A.reduce({}, (res: Record<string, unknown>, key) => ({
            ...res,
            [key]: null
          })),
          t.keyof
        )
      }) as unknown as t.Type<SelectOption<T>, SelectOption<T> | null>
    }
  }

  export namespace file {
    export const required = withMessage(
      new t.Type<File, File | null>(
        'File',
        (u): u is File => u instanceof File,
        (u, c) => (u != null && u instanceof File ? t.success(u) : t.failure(u, c)),
        identity
      ),
      requiredMessage
    )
    export const normal = new t.Type<File, File>(
      'File',
      (u): u is File => u instanceof File,
      (u, c) => (u != null && u instanceof File ? t.success(u) : t.failure(u, c)),
      identity
    )

    export const optional = form2.optional(required)
  }

  const onChangeFilter =
    (matcher: { [Symbol.match](string: string): RegExpMatchArray | null }) =>
    (onChange: (...event: unknown[]) => void) =>
    (text: string) => {
      const matches = text.match(matcher)
      onChange((matches && matches[1]) || '')
    }

  export const onChangePercent = onChangeFilter(/((100|[1-9][0-9]?|0)%?)/)

  export const onBlurPercent =
    (field: { value: string | null; onChange: (...event: unknown[]) => void; onBlur: () => void }) => () => {
      const value = field.value
      if (value && !value.includes('%')) {
        field.onChange(value + '%')
      }
      field.onBlur()
    }

  export const registerPercent = (field: {
    value: string | null
    onChange: (...event: unknown[]) => void
    onBlur: () => void
  }) => ({
    value: field.value || '',
    onChangeText: onChangePercent(field.onChange),
    onBlur: () => {
      const value = field.value
      if (value && !value.includes('%')) {
        field.onChange(value + '%')
      }
      field.onBlur()
    }
  })
}
