import { assets } from '@pulseops/common'

enum FormGroupE {
  DEPARTMENT = 'department',
  GROUP = 'group',
  MAINDOC = 'mainDoc',
  SUBDOC = 'subDoc'
}

export type FormGroupEType = `${FormGroupE}`

export interface SelectOption {
  value: string
  label: string
  type?: string
}

export interface FormGroupI {
  type: FormGroupEType
  required: boolean
  label: string
  options: SelectOption[]
  placeholder: string
  popupIcon: string
}

export interface FormValueI {
  department: SelectOption | null
  group: SelectOption | null
  mainDoc: SelectOption | null
  subDoc: SelectOption | null
}

export interface TextCustomI {
  textColor?: string
  alignText?: string
  fontSize?: string
  fontWeight?: string
  fontStyle?: 'italic' | 'normal' | 'oblique'
}

export const formsGroup = (t: any): FormGroupI[] => [
  {
    type: 'department',
    required: true,
    label: t('utilities:department'),
    options: [],
    placeholder: t('common:Select'),
    popupIcon: assets.ArrowDownDropdownIcon
  },
  {
    type: 'group',
    required: true,
    label: t('utilities:group'),
    options: [],
    placeholder: t('common:Select'),
    popupIcon: assets.ArrowDownDropdownIcon
  },
  {
    type: 'mainDoc',
    required: true,
    label: t('utilities:documentType'),
    options: [],
    placeholder: t('common:Select'),
    popupIcon: assets.ArrowDownDropdownIcon
  },
  {
    type: 'subDoc',
    required: true,
    label: t('utilities:subDoc'),
    options: [],
    placeholder: t('common:Select'),
    popupIcon: assets.ArrowDownDropdownIcon
  }
]
