import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.CHANGE_CONTACT_INFO),
        t.literal(TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL)
      ])
    }),
    codec
  ])

const payload = Base(
  t.type({
    body: t.type({
      city: Maybe(t.string),
      companyPhone: Maybe(t.string), // pruonline
      country: Maybe(t.string), // pruonline
      countryCode: Maybe(t.string),
      district: Maybe(t.string),
      street: Maybe(t.string),
      ward: Maybe(t.string),
      mobilePhone: Maybe(t.string), //pruonline, zalo
      email: Maybe(t.string), //pruonline, zalo
      homePhone: Maybe(t.string), // pruonline
      locationIHeat: Maybe(t.string), // pruonline
      poClientNum: Maybe(t.string),
      policyNo: Maybe(t.string),
      listOfAppliedPolicy: Maybe(t.array(t.string)),
      dispatchAddress: Maybe(
        t.type({
          city: Maybe(t.string),
          countryCode: Maybe(t.string),
          district: Maybe(t.string),
          line1: Maybe(t.string),
          subDistrict: Maybe(t.string),
          zipcode: Maybe(t.string)
        })
      )
    })
  })
)

const newPayload = t.type({
  body: t.type({
    contactDetails: Maybe(
      t.type({
        EMAIL: Maybe(
          t.type({
            value: Maybe(t.string)
          })
        ),
        EMAIL_IND: Maybe(
          t.type({
            value: Maybe(t.string)
          })
        ),
        COMPANY_CODE: Maybe(t.string),
        OFFICE_PHONE_NUMBER: Maybe(t.string),
        OFFICE_PHONE: Maybe(
          t.type({
            value: t.string,
            countryCode: t.string
          })
        ),
        PHONE: Maybe(
          t.type({
            countryCode: Maybe(t.string),
            value: Maybe(t.string)
          })
        ),
        SMS_IND: Maybe(
          t.type({
            value: Maybe(t.string)
          })
        )
      })
    ),
    dispatchAddress: Maybe(
      t.type({
        city: Maybe(t.string),
        countryCode: Maybe(t.string),
        district: Maybe(t.string),
        line1: Maybe(t.string),
        subDistrict: Maybe(t.string),
        zipcode: Maybe(t.string)
      })
    ),
    owners: t.type({
      clientId: Maybe(t.string)
    }),
    policyNo: Maybe(t.string),
    priorPolicies: Maybe(
      t.array(
        t.type({
          policyNo: Maybe(t.string)
        })
      )
    ),
    attributesExt: Maybe(
      t.type({
        EMAILS_DUP: Maybe(
          t.array(
            t.type({
              clientId: Maybe(t.string),
              relationshipType: Maybe(t.string)
            })
          )
        ),
        PHONES_DUP: Maybe(
          t.array(
            t.type({
              clientId: Maybe(t.string),
              relationshipType: Maybe(t.string)
            })
          )
        ),
        mainPhoneNumber: Maybe(t.boolean)
      })
    ), 
  }),
  otpTransaction: Maybe(t.type({
    otp: Maybe(t.string),
    receiverPhone: Maybe(t.string)
  }))
})

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: newPayload
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: newPayload
  })
)

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload: newPayload
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: newPayload
  })
)

const Banca_Sea = Base(
  t.type({
    source: t.literal(SourceType.BANCA_SEA),
    payload: newPayload
  })
)

const Outbound = Base(
  t.type({
    source: t.literal(SourceType.OUTBOUND),
    payload: newPayload
  })
)

const Inbound = Base(
  t.type({
    source: t.literal(SourceType.INBOUND),
    payload: newPayload
  })
)

export const ChangeContactInfo = t.union([PruOnline, Pulse, Zalo, Banca, Ekiosk, PruGreat, Pulse4Ops, Banca_Sea, Outbound, Inbound])
export type ChangeContactInfo = t.TypeOf<typeof ChangeContactInfo>
