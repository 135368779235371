import * as React from 'react'
import { Any } from '@mxt/zio'
import { TableLoading } from './table-loading'
import { TableNoResult } from './table-no-result'

export const TableStatus: React.FC<{
  colSpan: number
  loading?: boolean
  rows?: Any[] | null
}> = (props) => {
  if (props.loading) {
    return <TableLoading colSpan={props.colSpan} />
  }
  if (props.rows?.length === 0) {
    return <TableNoResult colSpan={props.colSpan} />
  }
  return null
}
