import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import i18next from 'i18next'
import { pipe } from 'fp-ts/lib/function'
import { NonEmptyString } from 'io-ts-types'

export namespace RequestListDetailForm {
  const Base = t.type({
    caseId: form2.string.optional
    // requiredM(() =>
    //   i18next.t('message:MS020001', {
    //     field: i18next.t('ServiceInquiry:pc:caseId')
    //   })
    // )
  })

  export const codec = Base
  export type Validated = t.TypeOf<typeof codec>
  export type Raw = t.OutputOf<typeof codec>
}
