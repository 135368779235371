import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { StorageBlob } from '../storage-blob'
import { SourceType } from './model'
import { Maybe } from '@mxt/zio/codec'


export namespace UploadManualService {
  export interface BodyUploadGuide {
    departmentCode: string,
    group: string,
    mainDoc: string,
    subDoc: string,
  }

  export const ResponseGuide = t.type({
    exchangeId: Maybe(t.string),
    body: Maybe(
      t.type({
        departmentCode: Maybe(t.string),
        group: Maybe(t.string),
        mainDoc: Maybe(t.string),
        subDoc: Maybe(t.string),
      })
    ),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.number),
        message: Maybe(t.string)
      })
    )
  })

  export type ResponseGuide = t.Type<typeof ResponseGuide>

  export const SubmitGuide = (documents: StorageBlob.FileContentSubmit[], bodyInfo: BodyUploadGuide) =>
    pipe(
      POApi.post(`wf-api/policy/manual-guideline/save`)(ResponseGuide)({
        source: SourceType.PULSE4OPS,
        body: bodyInfo,
        documents: documents,
        authenOption: 'BY_PASS',
        primaryPolicyNo: '11111111'
      })
    )
  
  export const DepartmentT17s2 = t.type({
    id: t.string,
    department: t.string,
    departmentCode: t.string,
    group: t.string,
    docTypeEN: t.string,
    className: t.string,
    docId: t.string,
    mainDoc: t.string,
    subDoc: t.string,
    customer: t.string,
    internalStaff: t.string,
    saleForces: t.string
  })
  export type DepartmentT17s2Type = t.TypeOf<typeof DepartmentT17s2>
  
  export const finalSentence = (mySentence: string) => {
    return mySentence?.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  }

  export const getAllt17s2 = () => {
    return pipe(
      POApi.get(`wf-api/table/t17s2/get-all`)(
        t.type({
          body: t.array(DepartmentT17s2)
        })
      ),
      ZIO.map((res) => res.body)
    )
  }
}
