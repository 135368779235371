// import i18next from "i18next"
import { OBCallProgram } from "../ob-service/ob-model"

export type strConvert = (val: string) => string

export const EnableCallPrograms = [
  OBCallProgram.SQCB,
  OBCallProgram.RMD,
  OBCallProgram.CARE1,
  OBCallProgram.CARE2,
  OBCallProgram.IDC,
  OBCallProgram.ADHOCCCE,
  OBCallProgram.ADHOCCS,
  OBCallProgram.WCC,
  OBCallProgram.RMDBAU,
  OBCallProgram.MA1,
  OBCallProgram.MA2,
  OBCallProgram.APL,
  OBCallProgram.LAPSED
]

const getCallProgramLabel = (callType: OBCallProgram) => (strconver: strConvert): string => {
  switch (callType) {
    case OBCallProgram.WCC:
      return strconver('Outbound:OBAssignment:WellcomeCall')
    case OBCallProgram.SQCB:
      return strconver('Outbound:OBAssignment:SaleQuality')
    case OBCallProgram.RMD:
      return strconver('Outbound:OBAssignment:PremiumReminder')
    // case OBCallProgram.PO:
    //   return strconver('Outbound:OBAssignment:PremiumOverdue')
    case OBCallProgram.CARE1:
      return strconver('Outbound:OBAssignment:Care1')
    case OBCallProgram.CARE2:
      return strconver('Outbound:OBAssignment:Care2')
    case OBCallProgram.IDC:
      return strconver('Outbound:OBAssignment:IDC')
    case OBCallProgram.ADHOCCCE:
      return strconver('Outbound:OBAssignment:AdhocCCE')
    case OBCallProgram.ADHOCCS:
      return strconver('Outbound:OBAssignment:AdhocCS')
    case OBCallProgram.RMDBAU:
      return strconver('Outbound:OBAssignment:PremiumReminderBAU')
    case OBCallProgram.MA1:
      return strconver('Outbound:OBAssignment:Maturity1')
    case OBCallProgram.MA2:
      return strconver('Outbound:OBAssignment:Maturity2')
    case OBCallProgram.APL:
      return strconver('Outbound:OBAssignment:AutoPremiumLoan')
    case OBCallProgram.LAPSED:
      return strconver('Outbound:OBAssignment:Lapsed')
    default:
      return ''
  }
}

export const originAssignmentOBList: Array<{ title: (value: strConvert) => string, value: boolean, callProgram: OBCallProgram }> = [
  {
    title: getCallProgramLabel(OBCallProgram.WCC),
    value: false,
    callProgram: OBCallProgram.WCC
  },
  {
    title: getCallProgramLabel(OBCallProgram.SQCB),
    value: false,
    callProgram: OBCallProgram.SQCB
  },
  {
    title: getCallProgramLabel(OBCallProgram.RMD),
    value: false,
    callProgram: OBCallProgram.RMD
  },
  // {
  //   title: t('Outbound:OBAssignment:OutstandingPremiumLoan'),
  //   value: getValueStatus('OPL'),
  //   callProgram: OBCallProgram.OPL
  // },
  // {
  //   title: getCallProgramLabel(OBCallProgram.PO),
  //   value: false,
  //   callProgram: OBCallProgram.PO
  // },
  {
    title: getCallProgramLabel(OBCallProgram.CARE1),
    value: false,
    callProgram: OBCallProgram.CARE1
  },
  {
    title: getCallProgramLabel(OBCallProgram.CARE2),
    value: false,
    callProgram: OBCallProgram.CARE2
  },
  {
    title: getCallProgramLabel(OBCallProgram.IDC),
    value: false,
    callProgram: OBCallProgram.IDC
  },
  {
    title: getCallProgramLabel(OBCallProgram.ADHOCCCE),
    value: false,
    callProgram: OBCallProgram.ADHOCCCE
  },
  {
    title: getCallProgramLabel(OBCallProgram.ADHOCCS),
    value: false,
    callProgram: OBCallProgram.ADHOCCS
  },
  {
    title: getCallProgramLabel(OBCallProgram.RMDBAU),
    value: false,
    callProgram: OBCallProgram.RMDBAU
  },
  {
    title: getCallProgramLabel(OBCallProgram.MA1),
    value: false,
    callProgram: OBCallProgram.MA1
  },
  {
    title: getCallProgramLabel(OBCallProgram.MA2),
    value: false,
    callProgram: OBCallProgram.MA2
  },
  {
    title: getCallProgramLabel(OBCallProgram.APL),
    value: false,
    callProgram: OBCallProgram.APL
  },
  {
    title: getCallProgramLabel(OBCallProgram.LAPSED),
    value: false,
    callProgram: OBCallProgram.LAPSED
  }
]