import React from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '@pulseops/common'
import { InfoViewList } from '@pulseops/task'

export const RequestType = () => {
  const { t } = useTranslation()

  const data = [
    {
      label: t('submission:category'),
      value: t('submission:General')
    },
    {
      label: t('submission:serviceType'),
      value: t('submission:AdditionalDocument')
    },
    {
      label: t('submission:transactionType'),
      value: t('submission:AddLackOfDocuments')
    }
  ]

  return (
    <Panel title="" titleOutline={t('submission:requestType')} isExand={false}>
      <InfoViewList dataSource={data} containerStyle={{ marginBottom: 0 }} />
    </Panel>
  )
}
