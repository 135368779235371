import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.STOP_APL)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        aplDate: t.string,
        reason: t.string
      })
    })
  })
)

// export const GoAbroad = t.union([Pulse, Pulse4Ops])
export const StopApl = Pulse4Ops
export type StopApl = t.TypeOf<typeof StopApl>
