import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import * as A from 'fp-ts/lib/Array'

import { GeneralService, POApi, StorageBlob, SubmissionService, UploadDocumentData } from '..'
import { UploadDocument } from './model/task-detail-api/UploadDocument'

export namespace UploadDocumentsService {
  export const getDepartmentListForT31 = (): Task<UploadDocumentData.DepartmentT31[]> =>
    pipe(
      POApi.get(`wf-api/table/t31/get-all`)(
        t.type({
          body: t.array(UploadDocumentData.DepartmentT31)
        })
      ),
      ZIO.map((departmentList) => departmentList.body)
    )

  export const getDepartmentListForT71 = (): Task<UploadDocumentData.DepartmentT17[]> =>
    pipe(
      POApi.get(`pulseops/api/v1/filenet/get-department`)(
        t.type({
          data: t.array(UploadDocumentData.DepartmentT17)
        })
      ),
      ZIO.map((responseData) => responseData.data)
    )

  export const getDocumentType = (departmentShortName: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/filenet/get-doctype?departmentShortName=${departmentShortName}`)(
        t.type({
          data: Maybe(t.array(UploadDocumentData.DocumentType))
        })
      ),
      ZIO.map((docList) => docList.data)
    )
  
  export const getDocumentTypeImaging = (transactionType: string, departmentShortName: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/filenet/transaction/${transactionType}/filenet-configs`, 
        { params: {
          departmentShortName: departmentShortName
        }})(
        t.type({
          data: Maybe(t.array(UploadDocumentData.ImagingDocumentType))
        })
      ),
      ZIO.map((docList) => docList.data?.filter((i) => i.uploadFile === 'Y'))
    )

  export const getDepartmentImaging = (transactionType: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/filenet/transaction/${transactionType}/departments`)(
        t.type({
          data: Maybe(t.array(UploadDocumentData.DepartmentT17))
        })
      ),
      ZIO.map((departmentList) => departmentList.data ?? [])
    )

  export const getDetail = (): Task<UploadDocumentData.DetailData> =>
    pipe(
      ZIO.zipPar(getDepartmentListForT31(), getDepartmentListForT71()),
      ZIO.map(([departmentList, departmentT17List]) => {
        const newDepartmentT17List = departmentT17List.map((departmentItem) => {
          const departItem = departmentList.find((x) => x.departmentShortName === departmentItem.departmentShortName)
          return {
            departmentCode: departItem?.departmentCode ?? '',
            departmentEN: departItem?.departmentEN ?? '',
            departmentVN: departItem?.departmentVN ?? '',
            departmentShortName: departItem?.departmentShortName ?? ''
          }
        })
        return {
          DepartmentList: newDepartmentT17List.filter((i) => i.departmentCode)
        }
      })
    )

  export function getMetaDataInfoByDepartment(
    departmentList: UploadDocumentData.DepartmentFormInfo[],
    policyNum: string
  ) {
    return pipe(
      departmentList,
      A.map((departmentItem) => {
        return pipe(
          GeneralService.GetMetaDataByDepartmentType(
            departmentItem.department,
            departmentItem.documentType,
            departmentItem.officeCode
          ),
          ZIO.map((metaDataRes) => {
            const metaDataRaw = {
              type: metaDataRes.data.transactionType,
              doctype: metaDataRes.data.doctypeEn,
              class: metaDataRes.data.classFilenet,
              docid: metaDataRes.data.docID,
              maindoc: metaDataRes.data.mainDoc,
              subdoc: metaDataRes.data.subDoc,
              polnum: policyNum,
              batchno: metaDataRes.data.batchNo,
              docTypeCode: metaDataRes.data.docTypeCode,
              trigger: metaDataRes.data.trigger ?? '',
              doctypeBPM: metaDataRes.data.doctypeBPM ?? '',
              // additional fields
              departType: metaDataRes.data.type,
              department: departmentItem.department,
              documentType: departmentItem.documentType
            }
            return metaDataRaw
          })
        )
      }),
      ZIO.sequence
    )
  }

  export type FileDetail = {
    id: string
    name: string
    url: string
  }

  export type DetailUploadDocument = {
    agentCode: string
    agentName: string
    department: string
    documentType: string
    poClientId: string
    poName: string
    policyNo: string
    proposalNo: string
    requestDocuments: FileDetail[]
    poIdNumber: string
    batchName?: string
  }

  export const getDocumentDetail = (taskId: string) => {
    return pipe(POApi.get(`pulseops/history/historic-process-instances/${taskId || ''}`)(UploadDocument))
  }

  export const getHistoricUploadDocumentDetail = (taskId: string) => {
    return pipe(
      getDocumentDetail(taskId),
      ZIO.map((detail) => {
        //const departmentasdShortName = (detail.payload as UploadDocument).payload.body.
        //console.log('dededededasdasdasde', detail)
        const uploadDocumentData = detail.payload
        const uploadFiles = uploadDocumentData.documents

        const requestDetailsWrap: DetailUploadDocument[] = uploadDocumentData.body.requestDetails.map((detail) => {
          const files = detail.requestDocuments.map((documentsId) => {
            const document = uploadFiles.find((v) => v.id === documentsId)
            return document!
          })
          return {
            ...detail,
            agentCode: detail.agentCode ?? '',
            agentName: detail.agentName ?? '',
            poName: detail.poName ?? '',
            policyNo: detail.policyNo ?? '',
            proposalNo: detail.policyNo ?? '',
            poIdNumber: detail.poIdNumber ?? '',
            batchName: uploadDocumentData.body.batchName ?? '',
            requestDocuments: files
          }
          //return requestDetailsWrap
        })

        return requestDetailsWrap
      })
    )
  }

  export const getDepartmentTypeName = (departmentShortName: string) => {
    return pipe(
      getDetail(),
      ZIO.map((departmentList) => {
        return departmentList.DepartmentList.find((v) => v.departmentShortName === departmentShortName)
      })
    )
  }

  export const getDocumentTypeName = (departmentShortName: string, docTypeCode: string) => {
    return pipe(
      getDocumentType(departmentShortName),
      ZIO.map((list) => {
        if (list) {
          return list.find((v) => v.docTypeCode === docTypeCode)
        }
        return null
      })
    )
  }

  export const submitData = (
    data: UploadDocumentData.SubmitData,
    primaryPolicyNo: string,
    clientID: string,
    documents: StorageBlob.FileContentSubmit[],
    officeCode: string
  ) =>
    SubmissionService.submit(t.unknown)(
      `wf-api/rm-kofax/upload-document`,
      { body: data },
      primaryPolicyNo,
      null,
      documents,
      clientID,
      undefined,
      officeCode
    )

  export const submitDataImagingUpload = (
    data: UploadDocumentData.SubmitData & { batchName: string },
    primaryPolicyNo: string,
    clientID: string,
    documents: StorageBlob.FileContentSubmit[],
    officeCode: string
  ) =>
    SubmissionService.submit(t.unknown)(
      `wf-api/kofax/upload-documents`,
      { body: data },
      primaryPolicyNo,
      null,
      documents,
      clientID,
      undefined,
      officeCode
    )
}
