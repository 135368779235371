import React from 'react'
import { StyleSheet, View } from 'react-native'

type OBHiddenTopMenuBlock = {
  isOpened: boolean
}

export const OBHiddenTopMenuBlock = (props: OBHiddenTopMenuBlock) => {
  return props.isOpened ? <View style={hiddenStyles.container}></View> : <></>
}
const hiddenStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: 56,
    backgroundColor: 'rgba(0,0,0,0.1)',
    top: 0,
    left: 0
  }
})
