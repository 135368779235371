/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useImperativeHandle } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Text, TouchableOpacity, StyleSheet, Image, View } from 'react-native'
import {
  assets,
  AuthService,
  ErrorHandling,
  GeneralService,
  useTranslator,
  Checkbox,
  Container
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { FontAwesome5 } from '@expo/vector-icons'
import { Controller, UseFormReturn } from 'react-hook-form'
import { SEABillingChangeForm } from '../billing-change/SEABillingChangeForm'
import { SEABillingChangeService } from '../billing-change/SEABillingChangeService'
const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  tableHeader: {
    backgroundColor: '#E5EAEF',
    borderColor: '#D3DCE6'
  }
})
export interface FormSEASignCheckDialog {
  isCheckDialog?: boolean
  setIsCheckDialog?: (value: boolean) => void
}
type SignCheckDialogProps = {
  onClose: (value: any) => void
  onDocReviewed: (value: boolean) => void
  open: boolean
  isRequestForm: boolean
  docReviewed: boolean
  ref: FormSEASignCheckDialog
  base: Omit<UseFormReturn<SEABillingChangeForm.Raw>, 'handleSubmit'>
}
const SignCheckDialog = forwardRef<FormSEASignCheckDialog, SignCheckDialogProps>((props, ref) => {
  const { dialog, dialogContent, dialogHeader, dialogActions, tableHeader } = useStyles()
  const { onClose, open } = props
  const [isView, setIsView] = React.useState<boolean>(false)
  const [isCheckDialog, setIsCheckDialog] = React.useState<boolean>(false)
  const [files, setFiles] = React.useState<
    {
      policyNum: string
      mainDoc: string
      subDoc: string
      signture: string
    }[]
  >([])
  const policyNum = '75417733' // làm theo DOC
  const policyNum1 = '75387029' // làm theo DOC
  const policyNum2 = '75411122'
  const [img, setImg] = React.useState<string>('')
  const [loading, bindLoader] = useLoading()
  const { t } = useTranslation()
  const emptyMess = t('message:MS050079', { policyNum: policyNum + ', ' + policyNum1 + ', ' + policyNum2 })
  const [warnErr, setWarnErr] = React.useState<string>('')

  useImperativeHandle(ref, () => ({
    isCheckDialog: isCheckDialog,
    setIsCheckDialog: setIsCheckDialog
  }))

  const handleClose = () => {
    onClose(null)
  }
  const registerCheck = (): Promise<unknown> => {
    return pipe(
      ZIO.succeed(props.onDocReviewed(true)),
      ZIO.tap((_) => {
        setIsView(true)
        props.base.setValue('checkDialog', true)
        setIsCheckDialog(true)
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement('a')
    link.download = name
    link.target = '_blank'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getLink = (url: string) => {
    registerCheck()
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const type = res.headers.get('content-type') || ''
        if (['image/png', '	image/jpg', 'image/jpeg'].includes(type)) {
          setImg(url)
        } else {
          downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
        }
      }),
      ErrorHandling.run()
    )
  }

  const onOk = () => {
    if (files.length === 0) {
      props.base.watch('checkDialog') === true
        ? (() => {
            registerCheck().finally(() => {
              props.base.setValue('checkDialog', true)
              handleClose()
            })
          })()
        : setWarnErr(t('message:MS020009', { field: t('SignCheck:SignatureChecked') }))
    } else {
      isView ? handleClose() : setWarnErr(t('message:MS050213'))
    }
  }

  pipe(
    ZIO.zipPar(
      props.isRequestForm ? ZIO.succeed(!!props.docReviewed) : SEABillingChangeService.authFormUserCheckFlag(''),
      GeneralService.getRequestFormSign(policyNum),
      GeneralService.getRequestFormSign(policyNum1),
      GeneralService.getRequestFormSign(policyNum2)
      /// BA note in DOC
    ),
    ZIO.flatMap(([x, files, files1, files2]) => {
      const _files =
        files?.data[0]?.signFiles?.map((y) => {
          return {
            policyNum: policyNum,
            mainDoc: y.metaData.maindoc || '-',
            subDoc: y.metaData.subdoc || '-',
            signture: y.url
          }
        }) || []
      const _files1 =
        files1?.data[0]?.signFiles?.map((y) => {
          return {
            policyNum: policyNum1,
            mainDoc: y.metaData.maindoc || '-',
            subDoc: y.metaData.subdoc || '-',
            signture: y.url
          }
        }) || []
      const _files2 =
        files2?.data[0]?.signFiles?.map((y) => {
          return {
            policyNum: policyNum2,
            mainDoc: y.metaData.maindoc || '-',
            subDoc: y.metaData.subdoc || '-',
            signture: y.url
          }
        }) || []
      return ZIO.zipPar(ZIO.succeed(x), ZIO.succeed(_files), ZIO.succeed(_files1), ZIO.succeed(_files2))
    }),
    ZIO.tap(([x, _files, _files1, _files2]) => {
      const filesFuture = [..._files, ..._files1, ..._files2]
      setFiles(filesFuture)
      return ZIO.unit
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  )
  const popup = useTranslator('SignCheck').t
  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <View style={styles.headerContent}>
          <View style={styles.headerFlexCol1}>
            <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>{popup('popupTitle')}</Text>
          </View>
          <View style={styles.headerFlexCol2}>
            <TouchableOpacity
              // style={{ position: 'absolute', right: 20, top: 20 }}
              onPress={() => {
                onClose(null)
              }}
            >
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
        </View>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <Container loading={loading} isSignCheck={true}>
          {files.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={tableHeader}>
                  <TableRow>
                    <TableCell>{t('common:PolicyNumber')}</TableCell>
                    <TableCell>{t('SignCheck:mainDoc')}</TableCell>
                    <TableCell>{t('SignCheck:subDoc')}</TableCell>
                    <TableCell>{t('SignCheck:column')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((row, i) => (
                    <TableRow key={`doc-row-${i}`}>
                      <TableCell>{row.policyNum}</TableCell>
                      <TableCell>{row.mainDoc}</TableCell>
                      <TableCell>{row.subDoc}</TableCell>
                      <TableCell>
                        <Text
                          style={{ color: '#ED1B2E', flexDirection: 'row', alignContent: 'center', padding: 10 }}
                          onPress={() => {
                            getLink(row.signture)
                          }}
                        >
                          <FontAwesome5 name="eye" size="20" color="#ed1b2e"></FontAwesome5>
                          <Text style={{ color: '#ed1b2e', marginLeft: 10 }}>{t('SignCheck:btn')}</Text>
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <View>
              <Text>{emptyMess}</Text>
              <View>
                <Controller
                  name="checkDialog"
                  control={props.base.control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox
                      value={value}
                      onBlur={onBlur}
                      onChange={(val) => {
                        onChange(val)
                      }}
                    />
                  )}
                />
              </View>
            </View>
          )}
          {!!img && <Image style={{ width: 800, height: 800 }} source={{ uri: img }}></Image>}
          {((files.length > 0 && !isView) || (files.length <= 0 && !props.base.watch('checkDialog'))) && (
            <Text>{warnErr}</Text>
          )}
        </Container>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onOk()}>
          <Text style={{ color: '#FFF' }}>OK</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
})

export default SignCheckDialog

export const styles = StyleSheet.create({
  actionBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    paddingHorizontal: 29,
    paddingVertical: 5.5
  },
  headerContent: {
    flex: 1,
    flexDirection: 'row'
  },
  headerFlexCol1: {
    flex: 82
  },
  headerFlexCol2: {
    flex: 8,
    alignItems: 'flex-end'
  }
})
