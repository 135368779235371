import { assets } from '@pulseops/common'
import React, { useState } from 'react'
import { TextInput, TouchableOpacity, View, Text } from 'react-native'
import styled from 'styled-components/native'
import { Control, Controller, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type InputProps<T> = {
  control?: Control<T>
  name: Path<T>
  errorMessage?: string
}

export const Input = <T extends Record<string, unknown>>(props: InputProps<T>) => {
  const { t } = useTranslation('login')
  const { control, name, errorMessage } = props
  const [hidePass, setHidePass] = useState(true)

  const secureTextEntry = name === 'password'

  const IconLeft = name === 'userName' ? assets.UsernameInput : assets.Lock
  return (
    <SC.Container>
      <SC.WrapperInput>
        <IconLeft width={20} height={20} />
        <Controller
          control={control}
          name={name}
          render={({ field: { name, value, onChange } }) => (
            <SC.TextInput
              value={value as string}
              onChange={onChange}
              placeholder={name === 'userName' ? t('EmailOrUsername') : t('Password')}
              secureTextEntry={secureTextEntry && hidePass}
              placeholderTextColor={'#70777E'}
            />
          )}
        />
        {secureTextEntry ? (
          <TouchableOpacity onPress={() => setHidePass(!hidePass)}>
            {hidePass ? <assets.EyeOff /> : <assets.EyeOn />}
          </TouchableOpacity>
        ) : null}
      </SC.WrapperInput>
      <SC.TextError>{errorMessage}</SC.TextError>
    </SC.Container>
  )
}

const SC = {
  Container: styled(View)`
    margin-bottom: 30px;
  `,
  WrapperInput: styled(View)<{ magrinTop?: number }>`
    flex-direction: row;
    padding: 15px;
    border-radius: 8px;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    height: 46px;
    box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
  `,
  TextInput: styled(TextInput)`
    font-family: ${(p) => p.theme.fonts.main};
    font-size: 15;
    flex: 1;
    min-width: 50px;
    color: #70777e;
    margin-right: 15px;
    margin-left: 15px;
  `,
  TextError: styled(Text)`
    font-family: ${(p) => p.theme.fonts.main};
    font-size: 13px;
    color: #ed1b2e;
    margin-top: 5px;
  `
}
