import { enumC } from '@mxt/zio/codec'

export enum PremiumDecisionCode {
  InForce = 'IF',
  Lapse = 'L',
  Complete = 'C'
}

export const PremiumDecisionCodeC = enumC(PremiumDecisionCode)

export const PremiumDecisionLabel = (code: PremiumDecisionCode): string => {
  switch (code) {
    case PremiumDecisionCode.InForce:
      return 'Hiệu lực'
    case PremiumDecisionCode.Lapse:
      return 'Mất hiệu lực'
    case PremiumDecisionCode.Complete:
      return 'Hoàn tất'
  }
}
