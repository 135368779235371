import { SelectOption } from '@pulseops/common'
import React from 'react'
export interface RuleItem {
  id?: string | null
  locationCode?: string | null
  officeCode?: string | null
  leaderOfficeCode?: string | null
  note?: string | null
  leaderCode?: string | null
  regionCode?: string | null
  regionType?: string | null
  docId?: string
  class?: string
  mainDoc?: string
  subDoc?: string
  channelsMapping?: SelectOption[] | undefined
  required?: string
  documentEn?: string
  documentVn?: string
  xCondition?: string
  suspendCode?: string
  version?: string | null
  isChangeCode?: boolean | null
}
export enum TypeTab {
  rule8,
  rule4,
  rule3,
  region,
  channel
}
