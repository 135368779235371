import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, View } from 'react-native'
import { FieldList, TabList, TransactionType } from '@pulseops/common'
import { OtpRequest, RequestAuthenticateData } from './index'
import { UseFormReturn } from 'react-hook-form'

export interface AgentWrapper {
  tab: {
    isShowed: boolean
    current: number
    isValidated: boolean
    setTab: (val: number) => void
  }
  agentInfo: FieldList.DataSource[]
  requestAuthenData: RequestAuthenticateData.EformRequestAuthenticateData
  authenForm: UseFormReturn<RequestAuthenticateData.RequestAuthFormValue>
  updateSendDate: (sendDate: string) => void
  updatedocReviewed: (docReview: boolean) => void
  updateRequestAuthenData: (data: RequestAuthenticateData.EformRequestAuthenticateData) => void
  isByPassAuthPaper: boolean
  officeCode: string
  transactionType: string
}

export const AgentWrapper = (props: PropsWithChildren<AgentWrapper>) => {
  const { t } = useTranslation()
  const menus = [
    { id: 1, title: t('submission:Request') },
    { id: 2, title: t('submission:Confirm') }
  ]

  const onChangeTab = (index: number) => {
    if (props.tab.isValidated) {
      props.tab.setTab(index)
    }
  }

  const isHideOTPTab = () => {
    return [TransactionType.E_SERVICE_REGISTRATION].includes(props.transactionType as TransactionType)
  }


  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ marginHorizontal: 15 }}>
        <TabList
          menus={menus}
          isSubTab={true}
          tabIndex={props.tab.current}
          onChangeTab={onChangeTab}
          predicateDisable={(index) => index === 1}
          gutterBottom={true}
        />
      </View>
      <View style={{ backgroundColor: '#fff', paddingHorizontal: 20, paddingVertical: 15 }}>
        {props.children}
        {props.tab.current === 1 && (
          <View>
            <OtpRequest
              hideOTP={isHideOTPTab()}
              requestAuthenData={props.requestAuthenData}
              authenForm={props.authenForm}
              updateSendDate={props.updateSendDate}
              updatedocReviewed={props.updatedocReviewed}
              updateRequestAuthenData={props.updateRequestAuthenData}
              isByPassAuthPaper={props.isByPassAuthPaper}
              officeCode={props.officeCode}
              transactionType={props.transactionType}
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}
