import { TextField } from '@material-ui/core'
import { ModalComponent, useMobile } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

export type DiaryModalProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {}
  content: string
}

export const DiaryModalView = (props: DiaryModalProps) => {
  const { onClose, open } = props
  const { isWide } = useMobile()

  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  return (
    <ModalComponent title={t('requestInfo:Detail')} visible={open} onClose={handleClose} actions={[]}>
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16,
          maxHeight: 400
        }}
      >
        <ScrollView>
          <TextField
            value={props.content}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true // disable underline
            }}
          />
        </ScrollView>
      </View>
    </ModalComponent>
  )
}
