interface SelectOption {
  label: string
  value: string
}

export enum ReportType {
  // COMPLAINT_HANDLING = 'COMPLAINT_HANDLING',
  // DATA_EXTRACTION_DAI = 'DATA_EXTRACTION_DAI',
  RAW_DATA = 'RAW_DATA',
  DATA_CONSERVATION = 'DATA_CONSERVATION',
  INCENTIVE_REPORT = 'INCENTIVE_REPORT',
  ASSIGNMENT_BY_ON_OFF = 'ASSIGNMENT_BY_ON_OFF'
}

export const ReportTypeList: SelectOption[] = [
  // { label: 'Complaint Handling', value: ReportType.COMPLAINT_HANDLING },
  // { label: 'Data Extraction - DA&I', value: ReportType.DATA_EXTRACTION_DAI },
  { label: 'Raw data', value: ReportType.RAW_DATA },
  { label: 'Data conservation', value: ReportType.DATA_CONSERVATION },
  { label: 'Incentive Report', value: ReportType.INCENTIVE_REPORT },
  { label: 'Assignment by on/off', value: ReportType.ASSIGNMENT_BY_ON_OFF }
]
