import { PCReason, SacsData } from '../mock'
import { TaskDetail, PulseOpsFormat } from '@pulseops/common'
import moment from 'moment'

export const getListSacCodeByPayinCode = (about?: PCReason.ActionEnum) => {
  switch (about) {
    case PCReason.ActionEnum.J:
      return [{ label: 'LN', value: 'LN' }]
    case PCReason.ActionEnum.N:
      return [{ label: 'LP', value: 'LP' }]
    case PCReason.ActionEnum.R:
      return [{ label: 'LP', value: 'LP' }]
    case PCReason.ActionEnum.G:
      return [{ label: 'SC', value: 'SC' }]
    case PCReason.ActionEnum.L:
      return [{ label: 'LP', value: 'LP' }]
    default:
      return []
  }
}

export const getSacTypeBySacCode = (about: PCReason.ActionEnum, sacCode: string) => {
  switch (about) {
    case PCReason.ActionEnum.J: {
      if (sacCode === 'LN') {
        return {
          sacsType: [
            {
              value: 'VP',
              label: 'VP'
            }
          ],
          defaultValue: {
            value: 'VP',
            label: 'VP'
          }
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.N: {
      if (sacCode === 'LP') {
        return {
          sacsType: [
            {
              value: 'S',
              label: 'S'
            }
          ],
          defaultValue: {
            value: 'S',
            label: 'S'
          }
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.R: {
      if (sacCode === 'LP') {
        return {
          sacsType: [
            {
              value: 'U',
              label: 'U'
            }
          ],
          defaultValue: {
            value: 'U',
            label: 'U'
          }
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.G: {
      if (sacCode === 'SC') {
        return {
          sacsType: [
            {
              value: 'I',
              label: 'I'
            }
          ],
          defaultValue: {
            value: 'I',
            label: 'I'
          }
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.L: {
      if (sacCode === 'LN') {
        return {
          sacsType: [
            {
              value: 'AO',
              label: 'AO'
            },
            {
              value: 'AI',
              label: 'AI'
            }
          ],
          defaultValue: null
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    default:
      return { sacType: [], defaultValue: null }
  }
}

export const getListSacCodeByActionCode = (action?: PCReason.ActionEnum) => {
  switch (action) {
    case PCReason.ActionEnum.J:
      // return [
      //   { label: 'LN', value: 'LN' },
      //   { label: 'LP', value: 'LP' }
      // ]
      return SacsData.getOptionsCodeByCode('LN', 'LP')('vi')
    case PCReason.ActionEnum.N:
    case PCReason.ActionEnum.R:
      //return [{ label: 'LP', value: 'LP' }]
      return SacsData.getOptionsCodeByCode('LP')('vi')
    case PCReason.ActionEnum.G:
      //return [{ label: 'SC', value: 'SC' }]
      return SacsData.getOptionsCodeByCode('SC')('vi')
    case PCReason.ActionEnum.L:
      //return [{ label: 'LN', value: 'LN' }]
      return SacsData.getOptionsCodeByCode('LN')('vi')
    default:
      return []
  }
}

export const getJournalSacsCodeByCode = (action?: PCReason.ActionEnum) => {
  switch (action) {
    case PCReason.ActionEnum.J:
      return SacsData.getOptionsCodeByCode('LN', 'LP')('vi')
    case PCReason.ActionEnum.A:
      return SacsData.getOptionsCodeByCode('LP')('vi')
    case PCReason.ActionEnum.L:
      return SacsData.getOptionsCodeByCode('LP', 'LN')('vi')
    default:
      return []
  }
}

export const getNonCashSascCodeByCode = (action?: PCReason.ActionEnum) => {
  switch (action) {
    case PCReason.ActionEnum.J:
      return SacsData.getOptionsCodeByCode('LP', 'LN')('vi')
    case PCReason.ActionEnum.L:
      return SacsData.getOptionsCodeByCode('LN')('vi')
    case PCReason.ActionEnum.N:
      return SacsData.getOptionsCodeByCode('LP')('vi')
    default:
      return []
  }
}

export const getSacTypeByActionCode = (about: PCReason.ActionEnum, sacCode: string) => {
  switch (about) {
    case PCReason.ActionEnum.J: {
      if (sacCode === 'LN') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('VP')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('VP')('vi')
        }
      }

      if (sacCode === 'LP') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('E1', 'PT', 'S')('vi'),
          defaultValue: null
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.G: {
      if (sacCode === 'SC') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('I')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('I')('vi')
        }
      }
      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.R: {
      if (sacCode === 'LP') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('U')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('U')('vi')
        }
      }

      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.N: {
      if (sacCode === 'LP') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('S')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('S')('vi')
        }
      }

      return { sacsType: [], defaultValue: null }
    }

    case PCReason.ActionEnum.L: {
      if (sacCode === 'LN') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('AO', 'AI', 'LO', 'LI')('vi'),

          defaultValue: null
        }
      }
      return {
        sacsType: [],
        defaultValue: null
      }
    }

    default:
      return {
        sacsType: [],
        defaultValue: null
      }
  }
}

export const getJournalTypeByActionCode = (about: PCReason.ActionEnum, sacCode: string) => {
  switch (about) {
    case PCReason.ActionEnum.J: {
      return { sacsType: SacsData.getOptionsTypeByCode('S', 'VP', 'E1')('vi'), defaultValue: null }
    }

    case PCReason.ActionEnum.A: {
      return { sacsType: SacsData.getOptionsTypeByCode('S', 'U', 'E1')('vi'), defaultValue: null }
    }

    case PCReason.ActionEnum.L: {
      return { sacsType: SacsData.getOptionsTypeByCode('S', 'AO', 'AI', 'LO', 'LI')('vi'), defaultValue: null }
    }

    default:
      return {
        sacsType: [],
        defaultValue: null
      }
  }
}

export const getNoncashCodeOptions = (languageCode: string) => SacsData.getOptionsCodeByCode('LN', 'LP')(languageCode)

export const getNoncashTypesOptions = (languageCode: string) =>
  SacsData.getOptionsTypeByCode('VP', 'S', 'AI', 'LI')(languageCode)

export const getJournalCodeOptions = (languageCode: string) => SacsData.getOptionsCodeByCode('LN', 'LP')(languageCode)

export const getJournalTypeOptions = (languageCode: string) =>
  SacsData.getOptionsTypeByCode('VP', 'S', 'U', 'AO', 'AI', 'LO', 'LI', 'E1')(languageCode)

export const getNoncashTypesByActionCode = (about: PCReason.ActionEnum, sacCode: string) => {
  // bỏ hàm này
  const defaultValue = {
    sacsType: [],
    defaultValue: null
  }
  switch (about) {
    case PCReason.ActionEnum.J: {
      if (sacCode === 'LN') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('VP')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('VP')('vi')
        }
      }

      if (sacCode === 'LP') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('S')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('S')('vi')
        }
      }

      return defaultValue
    }

    case PCReason.ActionEnum.L: {
      if (sacCode === 'LN') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('AI', 'LI')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('AI')('vi')
        }
      }

      return defaultValue
    }

    case PCReason.ActionEnum.N: {
      if (sacCode === 'LP') {
        return {
          sacsType: SacsData.getOptionsTypeByCode('S')('vi'),
          defaultValue: SacsData.getSacsTypeByCode('S')('vi')
        }
      }

      return defaultValue
    }

    default:
      return defaultValue
  }
}

export const calAmountAction = (params: {
  detail: TaskDetail.VeriCashLess
  action: PCReason.ActionEnum
  sacCode: string
  sacType: string
  remainingAmount?: string
  index?: number
}): { amount: string; allowEdit: boolean; allowDelete: boolean } => {
  const { detail, action, sacCode, sacType, remainingAmount = '0', index = 0 } = params
  const { discount } = detail
  const renAmountFormat = PulseOpsFormat.thousandSepartor(Number(detail.basicPremium ?? '0'))
  const receiveAmountFormat = PulseOpsFormat.thousandSepartor(Number(detail.amount ?? '0'))
  const discountFormat = PulseOpsFormat.thousandSepartor(Number(discount ?? '0'))
  const defaultReceipt = {
    amount: '',
    allowEdit: true,
    allowDelete: true
  }

  switch (action) {
    case PCReason.ActionEnum.J: {
      if (sacCode === 'LN' && sacType === 'VP') {
        return {
          amount: renAmountFormat,
          allowEdit: false,
          allowDelete: index > 0
        }
      }

      if (sacCode === 'LP' && sacType === 'E1') {
        return {
          amount: discount === 0 ? '' : `-${discountFormat}`,
          allowEdit: false,
          allowDelete: index > 0
        }
      }

      if (sacCode === 'LP' && sacType === 'PT') {
        return {
          amount: '-5,000',
          allowEdit: false,
          allowDelete: index > 0
        }
      }

      if (sacCode === 'LP' && sacType === 'S') {
        return {
          amount: detail.hasVoucher
            ? `-${PulseOpsFormat.thousandSepartor(detail.vouchers.reduce((pre, cur) => (pre = +cur.amount), 0))}`
            : '',
          allowEdit: true,
          allowDelete: index > 0
        }
      }

      return { amount: '', allowEdit: true, allowDelete: true }
    }
    case PCReason.ActionEnum.G: {
      if (sacCode === 'SC' && sacType === 'I') {
        return {
          amount: receiveAmountFormat,
          allowEdit: true,
          allowDelete: index > 0
        }
      }
      return defaultReceipt
    }

    case PCReason.ActionEnum.R: {
      if (sacCode === 'LP' && sacType === 'U') {
        return {
          amount: remainingAmount,
          allowEdit: true,
          allowDelete: index > 0
        }
      }
      return defaultReceipt
    }

    case PCReason.ActionEnum.N: {
      if (sacCode === 'LP' && sacType === 'S') {
        //const amount = Math.max(Number(detail.amount) - (detail.ao + detail.ai), 0)
        return {
          amount: remainingAmount,
          allowEdit: true,
          allowDelete: index > 0
        }
      }
      return defaultReceipt
    }

    case PCReason.ActionEnum.L: {
      // if (sacCode === 'LN' && (sacType === 'AO' || sacType === 'AI' || sacType === 'LO' || sacType === 'LI')) {
      //   return {
      //     amount: '0',
      //     allowEdit: true
      //   }
      // }
      if (sacCode === 'LN') {
        if (sacType === 'AO') {
          return {
            amount: PulseOpsFormat.thousandSepartor(detail.ao),
            allowEdit: true,
            allowDelete: true
          }
        }

        if (sacType === 'AI') {
          return {
            amount: PulseOpsFormat.thousandSepartor(detail.ai),
            allowEdit: true,
            allowDelete: true
          }
        }

        if (sacType === 'LO') {
          return {
            amount: PulseOpsFormat.thousandSepartor(detail.lo),
            allowEdit: true,
            allowDelete: true
          }
        }

        if (sacType === 'LI') {
          return {
            amount: PulseOpsFormat.thousandSepartor(detail.li),
            allowEdit: true,
            allowDelete: true
          }
        }

        return defaultReceipt
      }

      return defaultReceipt
    }

    default:
      return {
        amount: '0',
        allowEdit: true,
        allowDelete: true
      }
  }
}

export const calNonCash = (params: {
  detail: TaskDetail.VeriCashLess
  action: PCReason.ActionEnum
  sacCode: string
  sacType: string
  receiptLNLO: number
  receiptLNAO: number
  receiptLPS: number
  bankCode: string
}): { amount: number; allowEdit: boolean; allowDelete: boolean; errorMessage?: string } => {
  const { detail, action, sacCode, sacType, receiptLNAO, receiptLNLO, receiptLPS, bankCode } = params
  const defaultValue = {
    amount: 0,
    allowDelete: true,
    allowEdit: true
  }

  const dayFormat = 'YYYY-MM-DD'
  const transactionDate = moment(detail.transactionDate).format(dayFormat)
  const currentDate = moment().format(dayFormat)

  if (action === PCReason.ActionEnum.J) {
    const renCondition = Number(detail.amount) + (detail.LPSAmount + receiptLPS) >= detail.basicPremium + 30000
    if (sacCode === 'LN' && sacType === 'VP' && renCondition) {
      return {
        amount: detail.basicPremium,
        allowEdit: true,
        allowDelete: true
      }
    }

    if (sacCode === 'LP' && sacType === 'S' && renCondition) {
      return {
        amount: -(detail.LPSAmount + receiptLPS),
        allowDelete: true,
        allowEdit: true
      }
    }

    return defaultValue
  }

  if (action === PCReason.ActionEnum.L) {
    const beforeDate = moment(detail.transactionDate).isBefore(moment().format(dayFormat))
    const interestRate = detail.interestRate

    if (beforeDate) {
      if (sacCode === 'LN' && sacType === 'AI') {
        const minAplDate = detail.minAplDate
        let interestRateDate: number = 0

        const currentDateSameMinApl = moment(currentDate).isSame(moment(minAplDate).format(dayFormat), 'days')

        const currentDateAfterMinApl = moment(currentDate).isAfter(moment(minAplDate).format(dayFormat))

        const transDateBeforeMinApl = moment(moment(transactionDate).format(dayFormat)).isSameOrBefore(
          moment(minAplDate),
          'days'
        )

        if (!minAplDate) {
          // không có ngày nợ
          interestRateDate = 0
        } else if (currentDateSameMinApl) {
          // = 0 nếu cùng ngày vì ngày nợ bằng 0
          interestRateDate = 0
        } else if (transDateBeforeMinApl && currentDateAfterMinApl) {
          interestRateDate = Math.abs(moment(currentDate).diff(moment(minAplDate).format(dayFormat), 'days'))
        } else if (!transDateBeforeMinApl && currentDateAfterMinApl) {
          interestRateDate = Math.abs(moment(currentDate).diff(transactionDate, 'days'))
        }

        const amount = interestRateDate * receiptLNAO * ((interestRate * 0.01) / 365)

        return {
          amount: formatRound100(amount), // interestRateDate tính số ngày quá hạn đóng
          allowDelete: true,
          allowEdit: false
        }
      }

      if (sacCode === 'LN' && sacType === 'LI') {
        let interestRateDate: number = 0
        const bankCode30Pattern = /^(O1|V2|D7|I2|D6)$/

        if (bankCode30Pattern.test(bankCode)) {
          const opl30 = moment(moment(detail.minOplDate).format(dayFormat)).add(30, 'day')

          const transDateSameOrBeforeOpl30 = moment(transactionDate).isSameOrBefore(opl30)
          const currentDateSameOrBeforeOpl30 = moment(currentDate).isSameOrBefore(opl30)

          if (currentDateSameOrBeforeOpl30) {
            interestRateDate = 0
          } else {
            if (transDateSameOrBeforeOpl30) {
              interestRateDate = Math.abs(
                moment(currentDate).diff(moment(moment(detail.minOplDate).format(dayFormat)).add(31, 'day'), 'days')
              )
            } else {
              interestRateDate = Math.abs(moment(currentDate).diff(transactionDate, 'days'))
            }
          }
        } else {
          const minOplDate = moment(detail.minOplDate).format(dayFormat) // nếu minOPlDate bằng undefined thì moment sẽ bằng currentDate => currentDate - minOplDate = 0
          const curentSameOrBeforeOplDate = moment(currentDate).isSameOrBefore(minOplDate)

          if (curentSameOrBeforeOplDate) {
            interestRateDate = 0
          } else {
            const transactionSameOrBeforeOpl = moment(transactionDate).isSameOrBefore(minOplDate)
            if (transactionSameOrBeforeOpl) {
              interestRateDate = Math.abs(moment(currentDate).diff(minOplDate, 'days'))
            } else {
              interestRateDate = Math.abs(moment(currentDate).diff(transactionDate, 'days'))
            }
          }
        }

        const amount = interestRateDate * receiptLNLO * ((interestRate * 0.01) / 365)

        return {
          amount: formatRound100(amount),
          allowEdit: false,
          allowDelete: true
        }
      }
    } else {
      return {
        amount: 0,
        allowDelete: true,
        allowEdit: false,
        errorMessage: ''
      }
    }

    return defaultValue
  }

  if (action === PCReason.ActionEnum.N && sacCode === 'LP' && sacType === 'S') {
    return {
      amount: Number(detail.basicPremium) - (detail.LPSAmount + receiptLPS),
      allowEdit: true,
      allowDelete: true
    }
  }

  return defaultValue
}

export const generateUUID = () => {
  let uuidValue = '',
    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    charactersLength = characters.length
  for (let i = 0; i < 10; i++) {
    uuidValue += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return uuidValue
}

export const checkNonCashCondition = (params: {
  detail: TaskDetail.VeriCashLess
  action: PCReason.ActionEnum
  receiptLPS: number
}): {
  valid: boolean
  messageCode: string
} => {
  const { detail, action, receiptLPS } = params
  if (action === PCReason.ActionEnum.J) {
    const valid = Number(detail.amount) + (detail.LPSAmount + receiptLPS) >= detail.basicPremium - 30000
    return {
      valid: valid,
      messageCode: !valid ? 'noncashRenError' : ''
    }
  }

  if (action === PCReason.ActionEnum.L) {
    const valid = moment().isAfter(detail.transactionDate)
    return {
      valid,
      messageCode: !valid ? 'noncashAPLError2' : ''
    }
  }

  return {
    valid: true,
    messageCode: ''
  }
}

export const checkNoncashAmount = (params: { action: PCReason.ActionEnum; amount: string }) => {
  const { action, amount } = params
  const wAmount = PulseOpsFormat.thousandSepartorReverse(amount)
  if (action === PCReason.ActionEnum.J || action === PCReason.ActionEnum.N) {
    if (wAmount < 0 || wAmount > 30000) {
      return 'noncashRenError'
    }
    return ''
  }

  if (action === PCReason.ActionEnum.L) {
    if (wAmount < 0) {
      return 'noncashOPLError1'
    }
  }

  return ''
}

export const checkJournalAmount = (params: {
  sacCode: string
  sacType: string
  lpe1: number
  lps: number
  lpu: number
  lnvp: number
  amount: string
}) => {
  const { sacCode, sacType, lpe1, lps, lpu, lnvp, amount } = params
  if (sacCode === 'LP' && sacType === 'S') {
    return {
      valid: Math.abs(PulseOpsFormat.thousandSepartorReverse(amount ?? '0')) <= lps,
      //message: `Tiền journal lớn hơn ${PulseOpsFormat.thousandSepartor(lps)} VND`
      message: 'journalError'
    }
  }

  if (sacCode === 'LP' && sacType === 'U') {
    return {
      valid: Math.abs(PulseOpsFormat.thousandSepartorReverse(amount ?? '0')) <= lpu,
      //message: `Tiền journal lớn hơn ${PulseOpsFormat.thousandSepartor(lpu)} VND`
      message: 'journalError'
    }
  }
  if (sacCode === 'LN' && sacType === 'VP') {
    return {
      valid: Math.abs(PulseOpsFormat.thousandSepartorReverse(amount ?? '0')) <= lnvp,
      //message: `Tiền journal lớn hơn ${PulseOpsFormat.thousandSepartor(lnvp)} VND`
      message: 'journalError'
    }
  }
  if (sacCode === 'LP' && sacType === 'E1') {
    return {
      valid: Math.abs(PulseOpsFormat.thousandSepartorReverse(amount ?? '0')) <= lpe1,
      //message: `Tiền journal lớn hơn ${PulseOpsFormat.thousandSepartor(lpe1)} VND`
      message: 'journalError'
    }
  }

  return {
    valid: true,
    message: ''
  }
}

export const formatRound100 = (numb: number) => {
  const roundNumber = Math.round(numb)
  const modNum = roundNumber % 100
  const _diffMin = 100 - modNum
  return _diffMin <= 50 ? roundNumber + _diffMin : roundNumber + _diffMin - 100
}
