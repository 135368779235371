import { TransactionType } from '../TransactionType'
import * as api from '../task-detail-api'

export interface Voice_Recording {
  agentName: string | null | undefined
  agentCode: string | null | undefined
  proposalNumber: string | null | undefined
  tag: TransactionType.VOICE_RECORDING
  policyNumber: string | null | undefined
  policyStatus: string | null | undefined
  saleChannel: string | null | undefined
  officeCode: string | null | undefined
  recordDate: string | null | undefined
  recordDuration: string | null | undefined
  voiceCheckRuleAIResult: string | null | undefined
  autoVerification: string | null | undefined
  scriptType: string | null | undefined
  supplementInfoRequestDate: string | null | undefined
  caseSubmittedDate: string | null | undefined
  verificationDecision: string | null | undefined
  laType: string | null | undefined
  channel: string | null | undefined
  productCode: string | null | undefined
  poRole: string | null | undefined
  poType: string | null | undefined
  blackListAgent: boolean | null | undefined
  policyOwnerName: string | null | undefined
  voiceRecordingType: string | null | undefined
  firstSuspendDate: string | null | undefined
  signDateCompany: string | null | undefined
  employeeAmount: number | null | undefined
  clientNumber: string | null | undefined
  taxNumber: string | null | undefined
  agentLicense: string | null | undefined
  sequencePolicy: string | null | undefined
  totalPolicy: string | null | undefined
  parentPolicyNumber: string | null | undefined
  numberOfInsured: string | null | undefined
  agentCertificate: string | null | undefined
  parentProposalNumber: string | null | undefined
}
export const VoiceRecording = (detail: api.VoiceRecordingModel & api.TaskDetailBase): Voice_Recording => {
  // console.log(detail, 'detaildetaildetail')
  return {
    tag: detail.transactionType,
    ...detail.payload.body,
    policyStatus: detail.policyStatus || detail.payload.body.policyStatus || '',
    saleChannel: detail.payload.body.channel || '',
    recordDate: detail.payload?.body?.recordDocument?.recordStartDate,
    recordDuration: detail.payload?.body?.recordDocument?.totalRecordTime,
    officeCode: detail.officeCode || detail.payload.body.officeCode || '',
    policyOwnerName: detail.payload.body.poFullName || '',
    verificationDecision: detail.verificationDecision || detail.payload.body.verificationDecision || '',
    autoVerification: detail.autoVerification || detail.payload.body.autoVerification || '',
    supplementInfoRequestDate: detail.supplementInfoRequestDate || detail.payload.body.supplementInfoRequestDate || '',
    voiceCheckRuleAIResult: detail.voiceCheckRuleAIResult || detail.payload.body.voiceCheckRuleAIResult || '',
    voiceRecordingType: detail.voiceRecordingType || '',
    firstSuspendDate: detail.firstSuspendDate || null,
    blackListAgent: detail.blackListAgent || detail.payload.body.blackListAgent,
    signDateCompany: detail.signDateCompany || detail.payload.body.signDateCompany || '',
    employeeAmount: detail.employeeAmount || detail.payload.body.employeeAmount || 0,
    clientNumber: detail.clientNumber || detail.payload.body.clientNumber,
    policyNumber: detail.policyNumber || '',
    taxNumber: detail.payload.body.taxNumber || '',
    agentLicense: detail.payload.body.agentLicense || '',
    sequencePolicy: detail.payload.body.sequencePolicy || '',
    totalPolicy: detail.payload.body.totalPolicy || '',
    parentPolicyNumber: detail.parentPolicyNumber || '',
    numberOfInsured: detail.numberOfInsured || detail.payload.body.numberOfInsured || '',
    agentCertificate: detail.payload.body.agentCertificate || '',
    parentProposalNumber:  detail.payload.body.parentProposalNumber || '',
  }
}
