import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { isNil, isString, orderBy } from 'lodash'
import { PulseOpsFormat, TopUpConst, assets } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { AccountValueDetailData, IBGeneralTable, IBService, InvestmentDataInfo, InvestmentFund } from '@pulseops/inbound'
interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  listFundT25?: InvestmentFund[]
}

interface PanelCProps {
  title: string
  children?: React.ReactNode
  fundCode: string
  handleToggle: (fundCode: string) => void
}

const PanelC = (props: PanelCProps) => {
  const [toggle, setToggle] = React.useState<boolean>(false)
  const { title, children, fundCode } = props

  return (
    <View style={{ marginBottom: 10 }}>
      <View style={styles.title}>
        <Text style={{ color: '#70777e', fontWeight: 'bold', fontSize: 15 }}>{title}</Text>
        <TouchableOpacity
          onPress={() => {
            props.handleToggle(fundCode)
            setToggle(!toggle)
          }}
        >
          {toggle ? <assets.IBCircleCollapseIcon /> : <assets.IBCircleExpandIcon />}
        </TouchableOpacity>
      </View>
      {toggle ? <View style={{ marginTop: 15 }}>{children}</View> : <></>}
    </View>
  )
}

export const OBAccountValueInfo = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { investmentInfo, policyNumber, setInvestmentInfo, listFundT25 } = props
  const [loading, bindLoading] = useLoading()
  const [accountValueDetail, setAccountValueDetail] = React.useState<
    { fundCode: string; listDetail: AccountValueDetailData[] }[]
  >([])
  const [loadingByFundCode, setLoadingByFundCode] = React.useState<string[]>([])
  React.useEffect(() => {
    if (isNil(investmentInfo?.accountValue)) {
      pipe(
        IBService.getAccountValueInfo(policyNumber),
        ZIO.map((res) => {
          setInvestmentInfo({ ...(investmentInfo as InvestmentDataInfo), accountValue: res.data })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const getAccountValueDetail = (fundCode: string) => {
    if (!accountValueDetail?.some((x) => x.fundCode === fundCode)) {
      setLoadingByFundCode([...loadingByFundCode, fundCode])
      pipe(
        IBService.getAccountValueDetail({
          pageIndex: 0,
          itemsPerPage: 1000,
          orders: ['effectiveDate'],
          sort: 'DESC',
          fundCode: fundCode,
          policyNumber: policyNumber
        }),
        ZIO.map((res) => {
          setAccountValueDetail([...accountValueDetail, { fundCode: fundCode, listDetail: res.records }])
          setLoadingByFundCode(loadingByFundCode.filter((x) => x !== fundCode))
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const dataTable2 = [
    {
      label: t('EffectiveDate'),
      field: 'effectiveDate',
      format: 'date'
    },
    {
      label: t('FundAmount'),
      field: 'fundAmount',
      format: 'money'
    },
    {
      label: t('Code'),
      field: 'transCode'
    }
  ]

  return (
    <View>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        investmentInfo?.accountValue?.map((x) => {
          const fundName =
            i18n.language === 'vi'
              ? listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.inBoundNameVN
              : listFundT25?.find((fund) => fund.fundCode === x.fundCode)?.fundNameEN
          return (
            <PanelC
              title={`${x.fundCode} ${fundName}: ${PulseOpsFormat.thousandSepartor(x.amount)}`}
              children={
                <IBGeneralTable
                  data={
                    orderBy(
                      accountValueDetail
                        ?.find((c) => c.fundCode === x.fundCode)
                        ?.listDetail.map((z) => ({
                          ...z,
                          effectiveDate: moment(z.effectiveDate, 'DD/MM/YYYY').format('YYYYMMDD')
                        })),
                      'effectiveDate',
                      'desc'
                    ) || []
                  }
                  dataTable={dataTable2}
                  loading={loadingByFundCode.includes(x.fundCode as string)}
                  autoPaging
                />
              }
              fundCode={x.fundCode as string}
              handleToggle={getAccountValueDetail}
            />
          )
        })
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textStyle: {
    color: '#58647A',
    fontWeight: '700',
    fontSize: 15,
    marginRight: 5,
    marginBottom: 10
  }
})
