import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet, useWindowDimensions, FlatList, TouchableOpacity } from 'react-native'
import { Divider } from './common'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestChangeTab } from './RequestChangeTab'

interface UpDateInforScreenProps {}

const Header = (info: { policyNumb: string; owner: string; office: string }) => {
  const { policyNumb, owner, office } = info
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const Item = ({ prefix, suffix }: { prefix: string; suffix: string }) => (
    <View style={{ flex: 1 }}>
      <Text style={{ color: 'black' }}>
        {`${prefix}: `}
        <Text style={{ color: 'red', fontWeight: '800' }}>{suffix}</Text>
      </Text>
    </View>
  )

  return (
    <View style={[styles.headerContainer, { width: width < 1441 ? width : 1440 }]}>
      <Item prefix={t('common:PolicyNumber')} suffix={policyNumb} />
      <Item prefix={t('PolicyOwner')} suffix={owner} />
      <Item prefix={t('GAOffice')} suffix={office} />
    </View>
  )
}

export const UpdateInfoScreen: React.FC<UpDateInforScreenProps> = (props) => {
  // const { isMobile } = useMobile();
  const { width } = useWindowDimensions()
  const composeWidth = width < 1441 ? width : 1440
  const flatListRef = useRef<FlatList>(null)
  const [tabIndex, changeTabIndex] = useState<number>(1)
  const tabs: { id: number; title: string; component: JSX.Element }[] = [
    {
      id: 1,
      title: 'Yêu Cầu',
      component: <RequestChangeTab title="Hoang" />
    },
    {
      id: 2,
      title: 'Xác Nhận',
      component: <RequestChangeTab title="Son" />
    }
  ]

  const onTabChange = (index: number) => {
    changeTabIndex(index)
    flatListRef.current?.scrollToOffset({ offset: (index - 1) * composeWidth, animated: true })
  }

  return (
    <SafeAreaView>
      <View style={styles.container}>
        <Divider />
        <Header policyNumb={'019231223'} owner={'Hoang'} office={'A70-Quan 5'} />
        <View style={styles.divider} />
        <View style={[styles.tabHeader, { width: composeWidth }]}>
          <View style={styles.line} />
          {tabs.map(({ id, title }) => {
            return (
              <TouchableOpacity
                key={id}
                style={[tabIndex === id ? styles.tabActive : styles.tab]}
                onPress={() => onTabChange(id)}
              >
                <Text style={[styles.tabLabel, tabIndex === id && styles.tabLabelActive]}>{title}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
        <Divider />
        <View style={{ width: composeWidth }}>
          <FlatList
            scrollEnabled={true}
            keyExtractor={(_item, index) => `change_info_tab${index}`}
            ref={flatListRef}
            horizontal
            pagingEnabled
            data={tabs}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{ width: composeWidth, backgroundColor: '#FFF' }}
                  onMoveShouldSetResponder={(e) => {
                    e.stopPropagation()
                    return false
                  }}
                >
                  {item.component}
                </View>
              )
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#ffffff'
  },
  headerContainer: {
    borderRadius: 8,
    backgroundColor: '#E5EAEF',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 40,
    alignSelf: 'center',
    minHeight: 60
  },
  divider: {
    height: 30
  },
  tabHeader: {
    flexDirection: 'row'
  },
  tabActive: {
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: 'red',
    borderBottomWidth: 2
  },
  tabLabel: {
    paddingTop: 8,
    paddingBottom: 8
  },
  tabLabelActive: {
    color: 'red',
    fontWeight: 'bold'
  },
  tab: {
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#D3DCE6',
    borderBottomWidth: 2
  },
  line: {
    position: 'absolute',
    bottom: 0,
    height: 2,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    width: '100%'
  }
})
