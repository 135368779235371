import * as t from 'io-ts'
import { Assignment } from './Assignment'
import { CashOutLoan } from './CashOutLoan'
import { CashOutMaturity } from './CashOutMaturity'
import { PartialSurrender } from './PartialSurrender'
import { ChangeSumAssured } from './ChangeSumAssured'
import { ChangePremium } from './ChangePremium'
import { CashOutPrucash } from './CashOutPrucash'
import { ChangeBeneficialInfo } from './ChangeBeneficialInfo'
import { ChangeEmailInfo } from './ChangeEmailInfo'
import { ChangePhoneInfo } from './ChangePhoneInfo'
import { TaskDetailBase } from './TaskDetailBase'
import { ChangePrimaryAddressInfo } from './ChangePrimaryAddressInfo'
import { BillingChange } from './BillingChange'
import { BonusSurrender } from './BonusSurrender'
import { Reinstatement } from './Reinstatement'
import { SignatureRegistration } from './SignatureRegistration'
import { BillingChangeReversal } from './BillingChangeReversal'
import { ChangeClientInfo } from './ChangeClientInfo'
import { Topup } from './Topup'
import { ConfirmationPremiumReceipt } from './ConfirmationPremiumReceipt'
import { CancelFromInception } from './CancelFromInception'
import { ExcessPremium } from './ExcessPremium'
import { PartialWithdrawal } from './PartialWithdrawal'
import { ChangeContactInfo } from './ChangeContactInfo'
import { MaturityAdvance } from './MaturityAdvance'
import { ZaloClaimDecision } from './ZaloClaimDecision'
import { ZaloOther } from './ZaloOther'
import { ZaloPremiumCollection } from './ZaloPremiumCollection'
import { ZaloRecommendation } from './ZaloRecommendation'
import { ZaloRequestProcess } from './ZaloRequestProcess'
import { EService } from './EService'
import { GoAbroad } from './GoAbroad'
import { CancelRider } from './CancelRider'
import { LoanStatements } from './LoanStatements'
import { PolicyCertificate } from './PolicyCertificate'
import { CashOutSpecialBonus } from './CashOutSpecialBonus'
import { InquiryComplaint } from './InquiryComplaint'
import { PremiumRedirection } from './PremiumRedirection'
import { ExcellentStudentAward } from './ExcellentStudentAward'
import { FundSwitching } from './FundSwitching'
import { FatcaDeclaration } from './FatcaDeclaration'
import { StopApl } from './StopApl'
import { Inheritance } from './Inheritance'
import { PaidUp } from './PaidUp'
import { Prukid369C, Prukid369 } from './Prukid369'
import { HealthDeclaration } from './HealthRedeclaration'
import { ScheduledPaymentStatement } from './ScheduledPaymentStatement'
import { FullSurrenderReversalData } from './FullSurrenderReversal'
import { FullSurrender } from './FullSurrender'
import { VerificationClaim } from './VerificationClaim'
import { VerificationClaimPayout } from './VerificationClaimPayout'
import { PayLoadCommonRequest } from './CommonRequest'
import { ProductOptionSwitching } from './ProductOptionSwitching'
import { Redating } from './Redating'
import { RiderReinstatement } from './RiderReinstatement'
import { RiderAlteration, NewRiderAlterationPayload } from './RiderAlteration'
import { CashLess } from './CashLess'
import { AgentOnboardNew } from './AgentOnboardNew'
import { MajorCombined } from './MajorCombined'
import { TransferJournal } from './TransferJournal'
import { PaymentData } from './PaymentData'
import { AgentVerificationNew } from './AgentVerificationNew'
import { UploadDocument } from './UploadDocument'
import { ChangeNationalIDCard } from './ChangeNationalIDCard'
import { ChangeOccupationPersonal } from './ChangeOccupationPersonal'
import { ChangeDOBGender } from './ChangeDOBGender'
import { ChangeOtherInfomationPersonal } from './ChangeOtherInfomationPersonal'
import { PartialWithdrawalAutoDebit } from './PartialWithdrawalAutoDebit'
import { IndependenceCheck } from './IndependenceCheck'
import { VoiceRecordingModel } from './VoiceRecordingModel'

export const codec = t.union(
  [
    TaskDetailBase(CashOutLoan),
    TaskDetailBase(CashOutMaturity),
    TaskDetailBase(PartialSurrender),
    TaskDetailBase(ChangeSumAssured),
    TaskDetailBase(ChangePremium),
    TaskDetailBase(CashOutPrucash),
    TaskDetailBase(ChangeBeneficialInfo),
    TaskDetailBase(ChangeEmailInfo),
    TaskDetailBase(ChangePhoneInfo),
    TaskDetailBase(ChangePrimaryAddressInfo),
    TaskDetailBase(ChangeContactInfo),
    TaskDetailBase(BillingChange),
    TaskDetailBase(Reinstatement),
    TaskDetailBase(BillingChangeReversal),
    TaskDetailBase(ChangeClientInfo),
    TaskDetailBase(Topup),
    TaskDetailBase(ConfirmationPremiumReceipt),
    TaskDetailBase(CancelFromInception),
    TaskDetailBase(CancelRider),
    TaskDetailBase(ExcessPremium),
    TaskDetailBase(PartialWithdrawal),
    TaskDetailBase(MaturityAdvance),

    TaskDetailBase(ZaloClaimDecision),
    TaskDetailBase(ZaloOther),
    TaskDetailBase(ZaloPremiumCollection),
    TaskDetailBase(ZaloRecommendation),
    TaskDetailBase(ZaloRequestProcess),

    TaskDetailBase(EService),
    TaskDetailBase(GoAbroad),
    TaskDetailBase(LoanStatements),
    TaskDetailBase(PolicyCertificate),
    TaskDetailBase(CashOutSpecialBonus),
    TaskDetailBase(InquiryComplaint),
    TaskDetailBase(PremiumRedirection),
    TaskDetailBase(ExcellentStudentAward),
    TaskDetailBase(FundSwitching),
    TaskDetailBase(SignatureRegistration),
    TaskDetailBase(BonusSurrender),
    TaskDetailBase(FatcaDeclaration),
    TaskDetailBase(StopApl),
    TaskDetailBase(Assignment),
    TaskDetailBase(Inheritance),
    TaskDetailBase(PaidUp),
    TaskDetailBase(Prukid369C),
    TaskDetailBase(HealthDeclaration),
    TaskDetailBase(ScheduledPaymentStatement),
    TaskDetailBase(FullSurrenderReversalData),
    TaskDetailBase(FullSurrender),
    TaskDetailBase(VerificationClaim),
    TaskDetailBase(VerificationClaimPayout),
    TaskDetailBase(PayLoadCommonRequest),

    TaskDetailBase(ProductOptionSwitching),
    TaskDetailBase(Redating),
    TaskDetailBase(RiderAlteration),
    TaskDetailBase(RiderReinstatement),
    TaskDetailBase(MajorCombined),

    TaskDetailBase(CashLess),
    TaskDetailBase(AgentOnboardNew),
    TaskDetailBase(TransferJournal),
    TaskDetailBase(AgentVerificationNew),
    TaskDetailBase(UploadDocument),
    TaskDetailBase(ChangeNationalIDCard),
    TaskDetailBase(ChangeOccupationPersonal),
    TaskDetailBase(ChangeDOBGender),
    TaskDetailBase(ChangeOtherInfomationPersonal),
    TaskDetailBase(ChangeNationalIDCard),
    TaskDetailBase(PartialWithdrawalAutoDebit),

    TaskDetailBase(IndependenceCheck),
    TaskDetailBase(VoiceRecordingModel),
  ],
  'OpTaskDetailApi'
)

export type Type = t.TypeOf<typeof codec>

export {
  TaskDetailBase,
  CashOutLoan,
  CashOutMaturity,
  PartialSurrender,
  ChangeSumAssured,
  ChangePremium,
  CashOutPrucash,
  ChangeBeneficialInfo,
  ChangeEmailInfo,
  ChangePhoneInfo,
  ChangePrimaryAddressInfo,
  ChangeContactInfo,
  BillingChange,
  Reinstatement,
  BillingChangeReversal,
  ChangeClientInfo,
  Topup,
  ConfirmationPremiumReceipt,
  CancelFromInception,
  CancelRider,
  ExcessPremium,
  PartialWithdrawal,
  MaturityAdvance,
  ZaloRecommendation,
  ZaloRequestProcess,
  ZaloPremiumCollection,
  ZaloOther,
  ZaloClaimDecision,
  EService,
  GoAbroad,
  LoanStatements,
  PolicyCertificate,
  CashOutSpecialBonus,
  InquiryComplaint,
  PremiumRedirection,
  ExcellentStudentAward,
  FundSwitching,
  SignatureRegistration,
  BonusSurrender,
  FatcaDeclaration,
  StopApl,
  Assignment,
  Inheritance,
  PaidUp,
  Prukid369,
  HealthDeclaration,
  ScheduledPaymentStatement,
  FullSurrenderReversalData,
  FullSurrender,
  VerificationClaim,
  PayLoadCommonRequest,
  ProductOptionSwitching,
  Redating,
  RiderReinstatement,
  RiderAlteration,
  CashLess,
  AgentOnboardNew,
  MajorCombined,
  TransferJournal,
  PaymentData,
  AgentVerificationNew,
  NewRiderAlterationPayload,
  UploadDocument,
  VerificationClaimPayout,
  ChangeNationalIDCard,
  ChangeOccupationPersonal,
  ChangeDOBGender,
  ChangeOtherInfomationPersonal,
  PartialWithdrawalAutoDebit,
  IndependenceCheck,
  VoiceRecordingModel
}

export * from './AdjudicationModel'
