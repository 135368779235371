import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.union([
        t.literal(TransactionType.AGENT_TERMINATION_AG),
        t.literal(TransactionType.AGENT_TRANSFER_POLICY),
        t.literal(TransactionType.AGENT_OFFICE_MOVING),
        t.literal(TransactionType.AGENT_UNIT_TRANSFER)
      ])
    }),
    codec
  ])

const AgentVerification = Base(
  t.type({
    transactionType: Maybe(t.string),
    businessKey: Maybe(t.string),
    category: Maybe(t.string),
    id: Maybe(t.string),
    taskId: Maybe(t.string),
    galleries: Maybe(t.string),
    payload: t.type({
      access_token: Maybe(t.string),
      action: Maybe(t.string),
      additionalProperties: Maybe(t.type({})),
      agentCode: Maybe(t.string),
      authenOption: Maybe(t.string),
      authenRequestForm: Maybe(t.string),
      body: t.type({
        officeCode: Maybe(t.string),
        terDate: Maybe(t.string),
        oldUnit: Maybe(t.string),
        newUnit: Maybe(t.string),
        eREF: Maybe(t.string),
        subChanel: Maybe(t.string),
        salesType: Maybe(t.string),
        agCAT: Maybe(t.string),
        agent: Maybe(
          t.type({
            agentCode: Maybe(t.string),
            clientNum: Maybe(t.string),
            agentSurName: Maybe(t.string),
            agentGivName: Maybe(t.string),
            agentName: Maybe(t.string),
            appointedDate: Maybe(t.string),
            terminateDate: Maybe(t.string),
            agentType: Maybe(t.string),
            title: Maybe(t.string),
            unitCode: Maybe(t.string),
            unitDescription: Maybe(t.string),
            branchCode: Maybe(t.string),
            branchDescription: Maybe(t.string),
            officeCode: Maybe(t.string),
            officeDescription: Maybe(t.string),
            regionCode: Maybe(t.string),
            regionDescription: Maybe(t.string),
            subzoneCode: Maybe(t.string),
            subzoneDescription: Maybe(t.string),
            zoneCode: Maybe(t.string),
            zoneDescription: Maybe(t.string),
            debt: Maybe(t.string),
            terminationReason: Maybe(t.string)
          })
        ),
        newOffice: Maybe(
          t.type({
            code: Maybe(t.string),
            name: Maybe(t.string),
            address: Maybe(t.string),
            gaCode: Maybe(t.string),
            officeMovementReason: Maybe(t.string)
          })
        ),
        newUnitLeader: Maybe(
          t.type({
            code: Maybe(t.string),
            desc: Maybe(t.string),
            unitMovementReason: Maybe(t.string)
          })
        ),
        newBranch: Maybe(
          t.type({
            code: Maybe(t.string),
            desc: Maybe(t.string)
          })
        ),
        transferPolicy: Maybe(
          t.type({
            cnttype: Maybe(t.string),
            cownGivName: Maybe(t.string),
            cownName: Maybe(t.string),
            cownNum: Maybe(t.string),
            cownSurName: Maybe(t.string),
            issueDate: Maybe(t.string),
            occDate: Maybe(t.string),
            policyNum: Maybe(t.string),
            policyYear: Maybe(t.number),
            ptDate: Maybe(t.string),
            serviceAgents: Maybe(
              t.array(
                t.type({
                  agentCode: Maybe(t.string),
                  agentGivName: Maybe(t.string),
                  agentName: Maybe(t.string),
                  agentSurName: Maybe(t.string),
                  appointedDate: Maybe(t.string),
                  clientNum: Maybe(t.string),
                  regionCode: Maybe(t.string),
                  regionDescription: Maybe(t.string),
                  salezone: Maybe(t.string)
                })
              )
            ),
            statcode: Maybe(t.string),
            transferAgent: Maybe(
              t.type({
                agentCode: Maybe(t.string),
                agentGivName: Maybe(t.string),
                agentName: Maybe(t.string),
                agentSurName: Maybe(t.string),
                appointedDate: Maybe(t.string),
                clientNum: Maybe(t.string),
                qualifiedProduct: Maybe(t.string),
                rateInforce: Maybe(t.number),
                regionCode: Maybe(t.string),
                regionDescription: Maybe(t.string),
                salezone: Maybe(t.string),
                workTime: Maybe(t.number)
              })
            ),
            transferReason: Maybe(t.string)
          })
        ),
        transactionType: Maybe(t.string)
      }),
      clientId: Maybe(t.string),
      correlationId: Maybe(t.string),
      createdBy: Maybe(t.string),
      createdDate: Maybe(t.string),
      documents: Maybe(
        t.array(
          t.type({
            id: Maybe(t.string),
            type: Maybe(t.string),
            desc: Maybe(t.string),
            comments: Maybe(t.string),
            name: Maybe(t.string),
            filename: Maybe(t.string),
            format: Maybe(t.string),
            contentType: Maybe(t.string),
            extension: Maybe(t.string),
            compression: Maybe(t.string),
            encryption: Maybe(t.string),
            isStatic: Maybe(t.string),
            isPublic: Maybe(t.string),
            tags: Maybe(t.string),
            url: Maybe(t.string),
            content: Maybe(t.string),
            parts: Maybe(t.string),
            version: Maybe(t.string),
            language: Maybe(t.string),
            category: Maybe(t.string),
            subCategory: Maybe(t.string),
            parentId: Maybe(t.string),
            contentLength: Maybe(t.string),
            publishedDate: Maybe(t.string),
            published: Maybe(t.string),
            overlays: Maybe(t.string),
            auditDetail: Maybe(t.string)
          })
        )
      ),
      errorMsg: Maybe(t.string),
      exchangeId: Maybe(t.string),
      functionUI: Maybe(t.string),
      idNumber: Maybe(t.string),
      isCCE: Maybe(t.boolean),
      lasStatus: Maybe(t.string),
      location: Maybe(t.string),
      msgId: Maybe(t.string),
      officeCode: Maybe(t.string),
      primaryPolicyNo: Maybe(t.string),
      refresh_token: Maybe(t.string),
      source: t.string,
      systemName: Maybe(t.string),
      timestamp: Maybe(t.string),
      transType: Maybe(t.string)
    })
  })
)

export const AgentVerificationNew = AgentVerification
export type AgentVerificationNew = t.TypeOf<typeof AgentVerificationNew>
