import * as React from 'react'
import { TaskDetail, Panel, FieldList, formatNumberWithComma, getLanguage, TopupOption } from '@pulseops/common'
import { View, Text, StyleSheet } from 'react-native'
import { InfoViewList, Title, HeaderTable } from '../common'
import { useTranslation } from 'react-i18next'

interface Props {
  detail: TaskDetail.Topup
}

export const Topup = ({ detail }: Props) => {
  const { t } = useTranslation('requestInfo')

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('TOP_UP_INFORMATION')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('TopUpAmount'),
              value: formatNumberWithComma(detail.totalTopupAmount),
              suffix: 'VND',
              isHighlight: true
            },
            {
              label: t('SuspenseAmount'),
              value: formatNumberWithComma(detail.suspendAmount),
              suffix: 'VND'
            },
            {
              label: t('RequirePayinAmount'),
              value: formatNumberWithComma(detail.requirePayinAmount),
              suffix: 'VND'
            }
          ]}
        />
        <FieldList
          titleStyle={styles.label}
          dataSource={[
            {
              label: t('TopUpOption'),
              fieldType: 'radio',
              value: detail.topupOption || '',
              radioOptions: [
                {
                  label: t('ByPercentage'),
                  id: TopupOption.Ratio
                },
                {
                  label: t('ByAmount'),
                  id: TopupOption.Amount
                }
              ]
            }
          ]}
        />
        <Text style={styles.label}>{t('FundList')}</Text>
        <View style={styles.tableTitle}>
          <Text style={styles.value}>
            {`${t('TotalTopUpAmount')}: `}
            <Text style={styles.highLight}>{`${formatNumberWithComma(detail.totalTopupAmount)} VND`}</Text>
          </Text>
          <Text style={[styles.value, { fontStyle: 'italic', color: '#70777E' }]}>{`${t('Currency')} : VND`}</Text>
        </View>
        <HeaderTable
          columns={[
            {
              title: t('Fund'),
              name: 'fund',
              key: '0'
            },
            {
              title: t('Ratio'),
              name: 'ratio',
              key: '1'
            },
            {
              title: t('Amount'),
              name: 'amount',
              key: '2'
            }
          ]}
          dataSource={detail.funds.map((item) => ({ ...item, fund: item.fund(getLanguage()) }))}
        />
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20
  },

  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },

  highLight: {
    color: '#eD1B2E'
  },

  tableTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8
  }
})
