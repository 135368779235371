import { BorderView, Text, View } from '@pulseops/business/core'
import styled from 'styled-components/native'

export const SC = {
  NoResult: styled(Text)`
    text-align: center;
  `,
  ProductContainer: styled(BorderView)`
    background: #fafafa;
    padding-top: 14px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 1rem;
  `,
  ProductTitle: styled(Text)`
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 14px;
  `,
  BenefitList: styled(View)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
  `,
  BenefitItem: styled(View)`
    flex-grow: 1;
    margin-top: 1rem;
  `,
  ItemBreak: styled(View)`
    flex-basis: 100%;
    width: 0;
  `,
  BenefitContent: styled(BorderView)`
    background: white;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 30px;
  `
}
