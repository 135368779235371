import { ZIO } from '@mxt/zio'
import {
  AppContext,
  Columns,
  CurrencyContainer,
  HeaderTable,
  Input,
  InputTable,
  PremiumRedirectionService,
  RowSelectedProps,
  SubPremiumRedirectionData,
  Title,
  TransactionType
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native'
import { RequestAuthenticateData } from '../../request-authen'
import { PolicyServiceProps } from '../policy-service-props'
import { PremiumRedirectionConst } from './premium-redirection-const'

export interface PremiumRedirectionForm {
  fundList: FUND[]
}

type FUND = {
  fundCode: string
  fundName: string
  currentPercentage: number
  redirectPercentage: number
}

export const PremiumRedirectionScreen: React.FC<PolicyServiceProps<SubPremiumRedirectionData.SubmitData>> = ({
  initSubmission,
  isConfirmed,
  policyNumber
}) => {
  const policyNum = policyNumber ?? ''
  const { t, i18n } = useTranslation()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()

  const [POClientId, setPOClientId] = React.useState<string>('')

  const form = useForm<PremiumRedirectionForm>({
    mode: 'all'
  })
  const { control, watch } = form

  const { append } = useFieldArray<PremiumRedirectionForm>({
    name: 'fundList',
    control: control
  })

  React.useEffect(() => {
    form.clearErrors()
    form.setValue('fundList', [])
    loadingDetailData()
  }, [isFocused])

  const sumRedirectPercentage = () => {
    let sum = 0
    watch('fundList').forEach((controlItem) => {
      const redirectPercent = controlItem.redirectPercentage ?? 0
      sum += Number(redirectPercent)
    })
    return sum
  }

  initSubmission({
    validate: async () => {
      if ((await form.trigger()) && sumRedirectPercentage() === 100) {
        const funds = watch('fundList')
        return {
          url: (policyNumber: string) => `wf-api/policy/${policyNumber}/premium-redirection`,
          body: {
            policyNo: policyNum,
            correlationId: policyNum,
            transactionType: TransactionType.PREMIUM_REDIRECTION,
            fundList: funds,
            poClientNum: POClientId,
            office: ''
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PREMIUM_REDIRECTION),
          collerationId: policyNum,
          transaction: TransactionType.PREMIUM_REDIRECTION
        }
      } else {
        showToast(t('message:MS050040'), 'error')
        return false
      }
    },
    clear: () => {
      form.clearErrors()
      form.setValue('fundList', [])
      loadingDetailData()
    }
  })

  const getFundName = (item: any) => {
    return i18n.language === 'en' ? item.name : item.nameVi
  }

  const loadingDetailData = () => {
    showGlobalLoading(true)
    pipe(
      PremiumRedirectionService.detail(policyNum),
      ZIO.map((responseData) => {
        const LA_FundList = responseData.fundList
        const fundList = PremiumRedirectionConst.fundList
        const fundData: SubPremiumRedirectionData.SUBFundInfo[] = []
        if (LA_FundList && LA_FundList.length > 0) {
          fundList.map((item: any) => {
            const DBFundItem = LA_FundList.find((x) => x.fundCode === item.code)
            const fundName = DBFundItem
              ? getFundName(fundList.find((res: any) => res.code === DBFundItem.fundCode))
              : getFundName(item)
            const customedFund = {
              fundCode: DBFundItem ? DBFundItem.fundCode : item.code,
              fundName: fundName,
              currentPercentage: DBFundItem ? DBFundItem.currentPercentage : 0,
              redirectPercentage: DBFundItem ? DBFundItem.redirectPercentage : 0
            }
            append(customedFund)
            fundData.push(customedFund)
          })
          setPOClientId(responseData.POClientId)
        }
        return responseData
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const renderFundNameController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`fundList.${index}.fundName`}
        render={({ field }) => <Input {...field} value={field.value || ''} disabled />}
      />
    )
  }

  const renderCurrentPercentageController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`fundList.${index}.currentPercentage`}
        render={({ field }) => <Input {...field} value={field.value.toString() || ''} disabled />}
      />
    )
  }

  const renderRedirectPercentController = ({ index }: RowSelectedProps) => {
    return (
      <Controller
        control={control}
        name={`fundList.${index}.redirectPercentage` as 'fundList.0.redirectPercentage'}
        rules={{
          required: {
            value: true,
            message: `${t('message:MS020001', { field: t('requestInfo:RedirectPercentage') })}`
          },
          max: {
            value: 100,
            message: `${t('message:MS050277')}`
          },
          min: {
            value: 0,
            message: `${t('message:MS050277')}`
          },
          validate: {
            positive: (v) => v % 5 === 0 || `${t('message:MS050277')}`
          }
        }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <InputTable
            onChange={onChange}
            onBlur={onBlur}
            value={value?.toString()}
            errorMessage={error?.message}
            inputType="number"
            maxLength={3}
            disabled={isConfirmed}
          />
        )}
      />
    )
  }

  const columns: Columns[] = [
    {
      key: '0',
      name: 'fundName',
      title: t('TaskManagement:FundName'),
      render: renderFundNameController
    },
    {
      key: '1',
      name: 'currentPercentage',
      title: t('requestInfo:CurrentPercentage'),
      render: renderCurrentPercentageController
    },
    {
      key: '2',
      name: 'redirectPercentage',
      require: true,
      title: t('requestInfo:RedirectPercentage'),
      render: renderRedirectPercentController
    }
  ]

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Title title={t('submission:PREMIUMREDIRECTIONINFORMATION')} />
      <CurrencyContainer text={t('requestInfo:Currency')}></CurrencyContainer>
      <HeaderTable
        // selectable={false}
        // unit={'VND'}
        headerContainerStyle={{ backgroundColor: '#D3DCE6' }}
        rowStyle={{ backgroundColor: '#FAFAFA' }}
        selectBoxContainerStyle={{ marginEnd: 100 }}
        columns={columns}
        dataSource={
          form.watch('fundList')?.map((fundItem: FUND) => ({
            fundName: fundItem.fundName,
            currentPercentage: fundItem.currentPercentage,
            redirectPercentage: fundItem.redirectPercentage
          })) || []
        }
      />
    </SafeAreaView>
  )
}
