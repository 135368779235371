import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  SelectOption,
  PulseOpsService,
  Toast,
  AppContext,
  GeneralService,
  TaskType,
  SubTaskType,
  PulseOpsApi,
  SelectSearch,
  assets,
  TransactionType,
  UserGroupInfo,
  HighLightSelectSearch,
  AssignmentUserInfo
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Color } from '@material-ui/lab'
import * as O from 'fp-ts/lib/Option'

export type TransferPopupProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  isClaim?: boolean
  selectedValue: {
    category: TaskType
    basket: SubTaskType
    taskIds: string[]
    updateToCore: boolean
    transactionType: TransactionType
    isUWCase?: boolean
  }
}

export const TransferPopup = (props: TransferPopupProps) => {
  const { onClose, onSuccess, open, selectedValue, isClaim = false } = props
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const [note, setNote] = useState<string>('')
  const [groupSelected, setGroupSelected] = useState<SelectOption | null>(null)
  const [userSelected, setUserSelected] = useState<SelectOption | null>(null)
  const [assignmentGroups, setAssignmentGroups] = useState<SelectOption[]>([])
  // const [users, setUsers] = useState<SelectOption[]>([])
  const [users, setUsers] = useState<AssignmentUserInfo[]>([])

  const showToastinternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const handleClose = () => {
    setNote('')
    setUsers([])
    setAssignmentGroups([])
    setUserSelected(null)
    setGroupSelected(null)
    onClose()
  }

  React.useEffect(() => {
    if (selectedValue.category && open && selectedValue.taskIds.length > 0) {
      pipe(
        isClaim
          ? GeneralService.getUsersGroupsByTransactionForClaim(selectedValue.category, selectedValue.taskIds[0])
          : (selectedValue.basket === SubTaskType.QC || selectedValue.basket === SubTaskType.QCUnderwriting) && ['PS', 'CH', 'CS', 'IDC'].includes(selectedValue.category ?? '')
            ? getGroupLevelByCategory()
            : [TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService, TaskType.IDC].includes(selectedValue.category)
              ? getGroupsByType()
              : GeneralService.getUsersGroupsByTransaction(selectedValue.category),
        ZIO.tap((res) => {
          const hideGroup = ['Underwriting1', 'Underwriting2']
          setAssignmentGroups(
            res
              .map((x) => ({
                value: x.userGroup,
                label: x.groupName
              }))
              .filter((x) => !hideGroup.includes(x.label))
          )
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [selectedValue.category, open])

  React.useEffect(() => {
    setUsers([])
    setUserSelected(null)
    if (groupSelected) {
      const customUserGroup = selectedValue.category === TaskType.IDC && selectedValue.basket === SubTaskType.Verification ?
        pipe(groupSelected.value,
          O.fromNullable,
          O.map((groupData) => {
            const groupInfoList = groupData.split('-')
            return groupInfoList[1]
          }),
          O.getOrElse(() => '')
        ) : groupSelected.value
      pipe(
        [TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService, TaskType.IDC].includes(selectedValue.category) &&
          (selectedValue.basket === SubTaskType.QC || selectedValue.basket === SubTaskType.QCUnderwriting)
          ? GeneralService.getUsersByGroupsQC([customUserGroup])
          : GeneralService.getUsersByGroups([customUserGroup], '', selectedValue.category, selectedValue.basket),
        ZIO.catchAll((_error) => ZIO.fail(null)),
        ZIO.tap((users) => {
          // setUsers(users.map((item) => ({ label: item.name, value: item.email })))
          setUsers(users)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [groupSelected])

  const getGroupsByType = () =>
    pipe(
      // GeneralService.getUsersGroupsByTransactionType(selectedValue.category, [selectedValue.transactionType]),
      GeneralService.getManualTransferGroups(
        selectedValue.category,
        `${selectedValue.category}-${selectedValue.basket}`,
        selectedValue.transactionType
      ),
      ZIO.map((response) => {
        let userGroupList: UserGroupInfo[] = []
        selectedValue.category === TaskType.IDC ? response.map((groupItem) => {
          const groupTransferList = groupItem.userGroupCodeToTransfer.split('-')
          const category = groupTransferList[0]
          const groupInfo = {
            category: category,
            userGroup: groupItem.userGroupCodeToTransfer,
            groupName: groupItem.userGroupCodeToTransfer
          }
          userGroupList.push(groupInfo)
        }) : response.map((groupItem) => {
          const groupTransferList = groupItem.userGroupCodeToTransfer.split('-')
          const category = groupTransferList[0]
          const userGroup = groupTransferList[1]
          const groupInfo = {
            category: category,
            userGroup: userGroup,
            groupName: groupItem.userGroupCodeToTransfer
          }
          userGroupList.push(groupInfo)
        })
        // if (userGroupList.some((x) => x.userGroup.includes('Alteration'))) {
        //   userGroupList.push({
        //     category: 'PS',
        //     userGroup: 'Underwriting4',
        //     groupName: 'Underwriting4'
        //   })
        //   userGroupList.push({
        //     category: 'PS',
        //     userGroup: 'Underwriting3',
        //     groupName: 'Underwriting3'
        //   })
        // }
        return userGroupList
      })
    )

  const getGroupLevelByCategory = () => {
    const activityBasket = selectedValue.category + '-' + selectedValue.basket
    return pipe(
      GeneralService.getUserQCGroupLevelByCategory(
        selectedValue.category,
        selectedValue.transactionType,
        activityBasket === 'PS-UW-QC' ? 'UW-QC' : activityBasket
      ),
      ZIO.map((groupArr) => {
        let userGroupList: UserGroupInfo[] = []
        groupArr.forEach((groupItem) => {
          //filter duplication groups
          if (!!groupItem && !userGroupList.some((group) => group.userGroup === groupItem.userQCGroupLevelToTransfer)) {
            let data = {
              category: selectedValue.category ?? '',
              userGroup: groupItem.userQCGroupLevelToTransfer,
              groupName: groupItem.userQCGroupLevelToTransfer
            }
            userGroupList.push(data)
          }
        })
        return userGroupList
      })
    )
  }

  const onHandleTransfer = () => {
    if (selectedValue.taskIds.length > 0 && userSelected && groupSelected) {
      showGlobalLoading(true)
      pipe(
        selectedValue.taskIds.length === 1
          ? PulseOpsService.transferTask(
            selectedValue.taskIds[0],
            userSelected.value,
            groupSelected.value,
            {
              author: 'admin',
              message: note
            },
            selectedValue.updateToCore,
            [TaskType.IDC].includes(selectedValue.category) ? TaskType.IDC : undefined
          )
          : PulseOpsApi.transferList({
            taskList: selectedValue.taskIds,
            assigneeGroup: groupSelected.value,
            assignee: userSelected.value,
            category: [TaskType.IDC].includes(selectedValue.category) ? TaskType.IDC : undefined
          }),
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastinternal(t('requestInfo:TransferFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          showGlobalLoading(false)
          handleClose()
          showToast(t('requestInfo:TransferSuccess'), 'success')
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ModalComponent
      title={t('claim:TransferTask')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      modalWidth={650}
      actions={[
        {
          text: t('claim:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('claim:Transfer'),
          type: 'filled',
          disabled: !groupSelected || !userSelected,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: onHandleTransfer
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16, flexDirection: isWide ? 'row' : 'column' }}>
          <View style={{ flex: 55, marginEnd: isWide ? 30 : 0, marginBottom: isWide ? 0 : 16 }}>
            <SelectSearch
              value={groupSelected}
              label={t('claim:Group')}
              showPopupIcon={true}
              required={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={assignmentGroups}
              onChange={setGroupSelected}
            />
          </View>
          <View style={{ flex: 45 }}>
            <HighLightSelectSearch
              value={userSelected}
              label={t('claim:User')}
              required={true}
              showPopupIcon={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              options={users.map((item) => ({ label: item.name, value: item.email }))}
              onChange={setUserSelected}
              hightlightList={users}
            />
          </View>
        </View>
        <TextInput
          title={t('claim:Comment')}
          value={note}
          onChange={setNote}
          placeholder={'...'}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
