import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.HEALTH_DECLARATION)
    }),
    codec
  ])

export const HealthDeclaration = Base(
  t.type({
    source: t.string
  })
)
export type HealthDeclaration = t.TypeOf<typeof HealthDeclaration>
