import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface documents {
  id: string | null | undefined
  type: string | null | undefined
  desc: string | null | undefined
  comments: string | null | undefined
  name: string | null | undefined
  filename: string | null | undefined
  format: string | null | undefined
  contentType: string | null | undefined
  extension: string | null | undefined
  compression: string | null | undefined
  encryption: string | null | undefined
  isStatic: string | null | undefined
  isPublic: string | null | undefined
  tags: string | null | undefined
  url: string | null | undefined
  content: string | null | undefined
  parts: string | null | undefined
  version: string | null | undefined
  language: string | null | undefined
  category: string | null | undefined
  subCategory: string | null | undefined
  parentId: string | null | undefined
  contentLength: string | null | undefined
  publishedDate: string | null | undefined
  published: string | null | undefined
  overlays: string | null | undefined
  auditDetail: string | null | undefined
}

export interface serviceAgents {
  agentCode: string | null | undefined
  agentGivName: string | null | undefined
  agentName: string | null | undefined
  agentSurName: string | null | undefined
  appointedDate: string | null | undefined
  clientNum: string | null | undefined
  regionCode: string | null | undefined
  regionDescription: string | null | undefined
  salezone: string | null | undefined
}
export interface transferAgent {
  agentCode: string | null | undefined
  agentGivName: string | null | undefined
  agentName: string | null | undefined
  agentSurName: string | null | undefined
  appointedDate: string | null | undefined
  clientNum: string | null | undefined
  qualifiedProduct: string | null | undefined
  rateInforce: number | null | undefined
  regionCode: string | null | undefined
  regionDescription: string | null | undefined
  salezone: string | null | undefined
  workTime: number | null | undefined
}
export interface AgentVerificationNew {
  tag:
    | TransactionType.AGENT_TERMINATION_AG
    | TransactionType.AGENT_TRANSFER_POLICY
    | TransactionType.AGENT_OFFICE_MOVING
    | TransactionType.AGENT_UNIT_TRANSFER
  eREF: string | null | undefined
  subChanel: string | null | undefined
  salesType: string | null | undefined
  createdDate: string | null | undefined
  agCAT: string | null | undefined
  agentCode: string | null | undefined
  agentName: string | null | undefined
  appointedDate: string | null | undefined
  branchCode: string | null | undefined
  terminateDate: string | null | undefined
  agentType: string | null | undefined
  title: string | null | undefined
  unitDescription: string | null | undefined
  branchDescription: string | null | undefined
  officeDescription: string | null | undefined
  subzoneDescription: string | null | undefined
  zoneDescription: string | null | undefined
  debt: string | null | undefined
  regionDescription: string | null | undefined
  terminationReason: string | null | undefined
  oldUnit: string | null | undefined
  terdate: string | null | undefined
  officeCode: string | null | undefined
  officeCodes: string | null | undefined
  processInstanceId: string | null | undefined
  structure: unknown | null | undefined
  source: string
  transactionType: string | null | undefined
  newUnit: string | null | undefined
  businessKey: string | null | undefined
  id: string | null | undefined
  taskId: string | null | undefined
  galleries: string | null | undefined
  clientId: string | null | undefined
  access_token: string | null | undefined
  action: string | null | undefined
  additionalProperties: unknown | null | undefined
  authenOption: string | null | undefined
  authenRequestForm: string | null | undefined
  correlationId: string | null | undefined
  createdBy: string | null | undefined
  documents: Array<documents> | null | undefined
  errorMsg: string | null | undefined
  exchangeId: string | null | undefined
  functionUI: string | null | undefined
  idNumber: string | null | undefined
  isCCE: boolean | null | undefined
  lasStatus: string | null | undefined
  location: string | null | undefined
  msgId: string | null | undefined
  primaryPolicyNo: string | null | undefined
  refresh_token: string | null | undefined
  systemName: string | null | undefined
  timestamp: string | null | undefined
  transType: string | null | undefined
  agentCodes: string | null | undefined
  officeGaCode: string | null | undefined
  officeCodeagent: string | null | undefined
  officeName: string | null | undefined
  officeAddress: string | null | undefined
  officeMovementReason: string | null | undefined
  unitLeaderCode: string | null | undefined
  unitLeaderDesc: string | null | undefined
  unitMovementReason: string | null | undefined
  branchCodeagent: string | null | undefined
  branchDesc: string | null | undefined
  category: string | null | undefined
  transferPolicy: any
  transferAgent: transferAgent | null | undefined
  serviceAgents: Array<serviceAgents> | null | undefined
}

export const AgentVerificationNew = (detail: api.AgentVerificationNew & api.TaskDetailBase): AgentVerificationNew => {
  const payload = detail.payload.body
  const CreatedDate = detail.payload.createdDate
  const structure = detail
  return {
    tag:
      TransactionType.AGENT_TERMINATION_AG ||
      TransactionType.AGENT_TRANSFER_POLICY ||
      TransactionType.AGENT_OFFICE_MOVING ||
      TransactionType.AGENT_UNIT_TRANSFER,
    eREF: payload.eREF,
    subChanel: payload.subChanel,
    salesType: payload.salesType,
    createdDate: CreatedDate,
    agCAT: payload.agCAT,
    agentCode: payload.agent?.agentCode,
    agentName: payload.agent?.agentName,
    appointedDate: payload.agent?.appointedDate,
    branchCode: payload.agent?.branchCode,
    terminateDate: payload.agent?.terminateDate,
    agentType: payload.agent?.agentType,
    title: payload.agent?.title,
    unitDescription: payload.agent?.unitDescription,
    branchDescription: payload.agent?.branchDescription,
    officeDescription: payload.agent?.officeDescription,
    subzoneDescription: payload.agent?.subzoneDescription,
    zoneDescription: payload.agent?.zoneDescription,
    debt: payload.agent?.debt,
    regionDescription: payload.agent?.regionDescription,
    terminationReason: payload.agent?.terminationReason,
    oldUnit: payload.oldUnit,
    terdate: payload.terDate,
    officeCode: payload.officeCode,
    officeCodes: detail.payload.officeCode,
    structure: structure,
    processInstanceId: detail.processInstanceId,
    source: detail.payload.source,
    transactionType: detail.transactionType,
    newUnit: payload.newUnit,
    businessKey: detail.businessKey,
    id: detail.id,
    taskId: detail.taskId,
    galleries: detail.galleries,
    clientId: detail.payload.clientId,
    access_token: detail.payload.access_token,
    action: detail.payload.action,
    additionalProperties: detail.payload.additionalProperties,
    authenOption: detail.payload.authenOption,
    authenRequestForm: detail.payload.authenRequestForm,
    correlationId: detail.payload.correlationId,
    createdBy: detail.payload.createdBy,
    documents: detail.payload.documents,
    errorMsg: detail.payload.errorMsg,
    exchangeId: detail.payload.exchangeId,
    functionUI: detail.payload.functionUI,
    idNumber: detail.payload.idNumber,
    isCCE: detail.payload.isCCE,
    lasStatus: detail.payload.lasStatus,
    location: detail.payload.location,
    msgId: detail.payload.msgId,
    primaryPolicyNo: detail.payload.primaryPolicyNo,
    refresh_token: detail.payload.refresh_token,
    systemName: detail.payload.systemName,
    timestamp: detail.payload.timestamp,
    transType: detail.payload.transType,
    agentCodes: detail.payload.agentCode,
    officeGaCode: payload.newOffice?.gaCode,
    officeCodeagent: payload.newOffice?.code,
    officeName: payload.newOffice?.name,
    officeAddress: payload.newOffice?.address,
    officeMovementReason: payload.newOffice?.officeMovementReason,
    unitLeaderCode: payload.newUnitLeader?.code,
    unitLeaderDesc: payload.newUnitLeader?.desc,
    unitMovementReason: payload.newUnitLeader?.unitMovementReason,
    branchCodeagent: payload.newBranch?.code,
    branchDesc: payload.newBranch?.desc,
    category: detail.category,
    transferPolicy: payload.transferPolicy,
    transferAgent: payload.transferPolicy?.transferAgent,
    serviceAgents: payload.transferPolicy?.serviceAgents
  }
}
