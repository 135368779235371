import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from '@pulseops/common'
import { View, Text, Pressable, ActivityIndicator } from 'react-native'
import { IBGeneralTable, IBStyles } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { IBService, PendingNoticeData } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { listCommunicationType } from './IBClaimSearchForm'
import { ScrollView } from 'react-native-gesture-handler'

interface Props {
  pendingNotelist: PendingNoticeData[]
  setPendingNotelist: (data: PendingNoticeData[]) => void
  claimNumber: string
}

export const IBPendingNoteList = ({ pendingNotelist, setPendingNotelist, claimNumber }: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)
  const [isShowDetailPopup, setShowDetailPopup] = React.useState<boolean>(false)
  const [indexDetail, setIndexDetail] = React.useState<number>(0)

  const listProcessStatus = [
    {
      label: 'Started',
      value:  'S'
    },
    {
      label: 'Opened',
      value:  'O'
    },
    {
      label: 'In-progress',
      value:  'I'
    },
    {
      label: 'Solved',
      value:  'F'
    },
    {
      label: 'Rejected',
      value:  'R'
    },
    {
      label: 'Cancelled',
      value:  'C'
    }
  ]

  React.useEffect(() => {
    if (claimNumber && pendingNotelist.length === 0) {
      pipe(
        IBService.getPendingNoticeList(claimNumber),
        ZIO.map((res) => {
          res && setPendingNotelist(res)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])

  const dataTable = [
    {
      label: t('SeqNo'),
      field: 'seqNo',
      render: (value: string, index: number) => {
        return (
          <Pressable
            onPress={() => {
              setShowDetailPopup(true)
              setIndexDetail(index)
            }}
          >
            <Text
              style={{
                fontSize: 15,
                color: '#09A4D8',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('ClaimNo'),
      field: 'claimNumber'
    },
    {
      label: t('Rank'),
      field: 'rank'
    },
    {
      label: t('PolNo'),
      field: 'policyNumber'
    },
    {
      label: t('Subject'),
      field: 'subject'
    },
    {
      label: t('RequestBy'),
      field: 'requestedBy'
    },
    {
      label: t('Communicator'),
      field: 'communicator'
    },
    {
      label: t('SubmitTime'),
      field: 'submitTime',
    },
    {
      label: t('CompleteCancelTime'),
      field: 'closedTime',
    },
    {
      label: t('Status'),
      field: 'status',
      render: (value: string) => listProcessStatus.find((i) => value === i.value)?.label ?? ''
    }
  ]

  const dataPendingNoteDetail = (data: PendingNoticeData) => [
    {
      title: t('SeqNo'),
      value: data?.seqNo,
      style: IBStyles.sectionCol4
    },
    {
      title: t('ClaimNo'),
      value: data?.claimNumber,
      style: IBStyles.sectionCol4
    },
    {
      title: t('PolNo'),
      value: data?.policyNumber,
      style: IBStyles.sectionCol4
    },
    {
      title: t('RequestBy'),
      value: data?.requestedBy,
      style: IBStyles.sectionCol4
    },
    {
      title: t('Communicator'),
      value: data?.communicator,
      style: IBStyles.sectionCol4
    },
    {
      title: t('CommunicationType'),
      value: data?.communicationType ? getCommunicationLabel(data?.communicationType) : '',
      style: IBStyles.sectionCol4
    },
    {
      title: t('DueDate'),
      value: data?.dueDate,
      style: IBStyles.sectionCol4
    },
    {
      title: t('SubmitTime'),
      value: data?.submitTime,
      style: IBStyles.sectionCol4
    },
    {
      title: t('CompleteCancelTime'),
      value: data?.closedTime,
      style: IBStyles.sectionCol4
    },
    {
      title: t('CancelReason'),
      value: data?.cancelReason,
      style: IBStyles.sectionCol12
    },
    {
      title: t('PendingCode'),
      value: data?.pendingCode,
      style: IBStyles.sectionCol4
    },
    {
      title: t('Subject'),
      value: data?.subject,
      style: IBStyles.sectionCol4
    },
    {
      title: t('Status'),
      value: data?.status ? getStatusLabel(data?.status) : '',
      style: IBStyles.sectionCol4
    },
    {
      title: t('RequiredDoc'),
      value: data?.requiredDocumentDescription,
      style: IBStyles.sectionCol4
    },
    {
      title: t('SupportedDoc'),
      value: data?.supportedDocumentDescription,
      style: IBStyles.sectionCol4
    },
    {
      title: t('DelegationDoc'),
      value: data?.delegatedDocumentDescription,
      style: IBStyles.sectionCol4
    },
    {
      title: t('SolutionCode'),
      value: data?.solutionCode,
      style: IBStyles.sectionCol4
    },
    {
      title: t('Feedback'),
      value: data?.feedback,
      style: IBStyles.sectionCol8
    }
  ]

  const getCommunicationLabel = (type: string) => {
    return listCommunicationType.find((i) => type === i.value)?.label ?? ''
  }

  const getStatusLabel = (status: string) => {
    return listProcessStatus.find((i) => status === i.value)?.label ?? ''
  }

  const PendingNoteDetail = (index: number) => {
    return (
      <ScrollView>
        <View style={[IBStyles.row, { paddingBottom: 15, paddingHorizontal: 20 }]}>
          {dataPendingNoteDetail(pendingNotelist[index]).map((field) => (
            <View style={[field.style, { paddingBottom: 24, paddingRight: 10 }]}>
              <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{field.title}</Text>
              </View>
              <Text style={{ fontSize: 15, marginTop: 4 }}>{field.value ?? '-'}</Text>
            </View>
          ))}
        </View>
      </ScrollView>
    )
  }

  return (
    <View>
      {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <View>
          <IBGeneralTable
            dataTable={dataTable}
            data={pendingNotelist}
            loading={loading}
            autoPaging={true}
            paginationStyle={{ marginRight: 60 }}
          />
          <ModalComponent
            children={PendingNoteDetail(indexDetail)}
            titleStyle={{ fontWeight: 'bold' }}
            visible={isShowDetailPopup}
            onClose={() => setShowDetailPopup(false)}
            title={t('PendingNoteDetail')}
            modalWidth={1100}
            actions={[]}
          >
          </ModalComponent>
        </View>
      )}
    </View>
  )
}