import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const DistrictType = t.type({
  code: t.string,
  desc: Maybe(t.string),
  id: Maybe(t.string),
  name: t.string,
  nameEn: Maybe(t.string)
})

export type DistrictType = t.TypeOf<typeof DistrictType>
