import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { PulseOpsFormat } from '../Formatter'
import { InquiryComplaintService } from './InquiryComplaintService'
import { AssignmentModel } from './model'
import { SubmissionService } from './SubmissionService'

export namespace AssignmentService {
  export const getDetail = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getClientsByPolicy(policyNum),
        SubmissionService.getPolicy(policyNum),
        InquiryComplaintService.getIAC_ClientRelationShip(policyNum)
      ),
      ZIO.flatMap(([customerList, policyInfo, relationShipList]) => {
        const benList = customerList.benList.filter((x) => x.clientNumberOfBen !== policyInfo.body.owners.id)
        const laList = customerList.laList.filter((x) => x.clientNumberOfLA !== policyInfo.body.owners.id)
        return pipe(
          SubmissionService.getClients(
            [...benList.map((x) => x.clientNumberOfBen), ...laList.map((x) => x.clientNumberOfLA)]
              .filter(PulseOpsFormat.onlyUniqueArrayFilter)
              .map((x) => ({ clientId: x }))
          ),
          ZIO.map((ClientPolicyList): AssignmentModel.Detail => {
            const getRole = (id: string) =>
              id === policyInfo.body.owners.id
                ? 'PolicyOwner'
                : customerList.laList.map((x) => x.clientNumberOfLA).includes(id)
                ? 'LifeAssured'
                : customerList.benList.map((x) => x.clientNumberOfBen).includes(id)
                ? 'Beneficiary'
                : '-'

            const getRelationShipWithLA = (LANumber: string) => {
              const { BORelationshipList, CBrelationshipList } = relationShipList
              const clientId = laList.find(({ life }) => life === LANumber)?.clientNumberOfLA
              const clientNumInfo = BORelationshipList.find((x) => x.relationship === clientId)
              const relationshipFilterItem = CBrelationshipList.find((p) => p.code === clientNumInfo?.clientNum)
              return relationshipFilterItem ? relationshipFilterItem.code : ''
            }

            const relationShipWithMainLA = (clientId: string | null) => {
              const { BORelationshipList, CBrelationshipList, BERelationshipList } = relationShipList
              const result = {
                relationShipWithMainLifeAssured: '',
                relationShipWithMainLifeAssuredLA2: '',
                relationShipWithMainLifeAssuredLA3: '',
                relationShipWithMainLifeAssuredLA4: ''
              }

              // LA
              const clientNumInfo = BORelationshipList.find((x) => x.relationship === clientId)
              const relationshipFilterItem = CBrelationshipList.find((p) => p.code === clientNumInfo?.clientNum)
              if (relationshipFilterItem) {
                result.relationShipWithMainLifeAssured = relationshipFilterItem.code
                result.relationShipWithMainLifeAssuredLA2 = getRelationShipWithLA('02')
                result.relationShipWithMainLifeAssuredLA3 = getRelationShipWithLA('03')
                result.relationShipWithMainLifeAssuredLA4 = getRelationShipWithLA('04')
                return result
              }

              // BEN
              const benItem = benList.find((x) => x.clientNumberOfBen === clientId)
              const relationshipFilterItemBEN = BERelationshipList.find((p) => p.code === benItem?.relationShipCode)
              if (relationshipFilterItemBEN) {
                result.relationShipWithMainLifeAssured = relationshipFilterItemBEN.code
              }

              return result
            }

            return {
              customerId: policyInfo.body.owners.id || '-',
              policyHasBen: benList.length > 0,
              laList: laList.map((la) => {
                const client = ClientPolicyList.body.find(({ clientId }) => clientId === la.clientNumberOfLA)
                return {
                  ...la,
                  name: client?.name ?? ''
                }
              }),
              customerData: ClientPolicyList.body
                .filter((x) => x.dod === '99999999')
                .map((x) => ({
                  customerId: x.clientId || '',
                  idType: x.externalIds?.SOE || '',
                  idNum: x.externalIds?.SOE_VALUE || '',
                  issuedAt: '',
                  name: x.firstName,
                  surName: x.surName || '',
                  dod: x.dod || '',
                  fatca: false,
                  dob: x.dob || '',
                  gender: x.sex || '',
                  nationality: x.nationality || '',
                  nationality2: x.attributesExt.NATIONALITY_2 || '',
                  mobilePhoneCode: x.attributesExt.MOBILE_CODE || '',
                  mobilePhoneNumber: x.mobilePhone || '',
                  email: x.email || '',
                  role: getRole(x.clientId || ''),
                  occupation: x.occupation || '',
                  address: x.addressDetails?.PRIMARY.line1 || '',
                  countryCode: x.countryCode || '',
                  city: x.location?.slice(0, 2) || '',
                  district: x.location?.slice(2, 4) || '',
                  ward: x.location || '',
                  foreignAddress: x.attributesExt.FOREIGN_STREET || '',
                  foreignCountry: x.attributesExt.FOREIGN_COUNTRY || '',
                  ...relationShipWithMainLA(x.clientId ?? '')
                }))
            }
          })
        )
      })
    )

  export const getAssigneeState = () =>
    pipe(
      SubmissionService.getStateAssignee(),
      ZIO.map((rs) => rs.data)
    )

  export const changeAssignee = (status: boolean) =>
    pipe(
      SubmissionService.changeAssigneeState(status),
      ZIO.map((rs) => rs)
    )
}
