import { Format } from '@pulseops/common'
import * as D from 'fp-ts/Date'
import { flow, pipe } from 'fp-ts/function'
import * as NEA from 'fp-ts/NonEmptyArray'
import { NonEmptyArray } from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import { Option } from 'fp-ts/Option'
import { contramap } from 'fp-ts/Ord'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const AmlResultC = t.type({
  scanDt: DateFromISOString,
  amlResult: t.string
})
export type AmlResult = t.TypeOf<typeof AmlResultC>

export namespace AmlResult {
  const byScan = pipe(
    D.Ord,
    contramap((e: AmlResult) => e.scanDt)
  )
  export const getLatest = (history: NonEmptyArray<AmlResult>): AmlResult => pipe(history, NEA.max(byScan))

  export const getLatestOption = (history: Array<AmlResult>): Option<AmlResult> =>
    pipe(history, NEA.fromArray, O.map(NEA.max(byScan)))

  export const format = (result: Option<AmlResult>) =>
    pipe(
      result,
      O.fold(
        () => ({
          scanDate: '-',
          result: '-'
        }),
        (result) => ({
          scanDate: Format.date(result.scanDt),
          result: result.amlResult
        })
      )
    )

  export const formatLatest = flow(getLatestOption, format)
}
