import React from 'react'
import { StyleSheet, View } from 'react-native'
import { OBFieldDescription, OBFieldTitle, OBSectionCol, OBSectionContent, OBSectionRow, OBSharedStyles, OBTitle } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { AppContext, ErrorHandling, Input, PulseOpsFormat } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { OBCustomerAnswerService } from '../../ob-service'
import { CallOutDetailContext } from '../ob-common'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'
import { OBCustomerAnswerConst } from './OBCustomerAnswerConst'
import { useIsFocused } from '@react-navigation/native'

export const OBCustomerAnswer = () => {
  const [isLoading, bindLoader] = useLoading(false)
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const { caseId, IDCCustomerAnswerPayload } = React.useContext(CallOutDetailContext)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const P4OCaseID = IDCCustomerAnswerPayload?.p4oCaseId ? IDCCustomerAnswerPayload?.p4oCaseId : ''

  const customerAnswer = pipe(
    isFocused && P4OCaseID ? OBCustomerAnswerService.getCustomerAnswer(P4OCaseID) : ZIO.succeed({} as OBCustomerAnswerService.CustomerAnswerResponse),
    ZIO.map((responseData) => {
      return responseData
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([P4OCaseID])
  )

  React.useEffect(() => { showGlobalLoading(isLoading) }, [isLoading])

  const optionItem = (idcType: string) => {
    const IDCTypeName = pipe(
      OBCustomerAnswerConst.IDCTypeConst.find((p) => p.code === idcType),
      O.fromNullable,
      O.fold(
        () => '-',
        (typeItem) => i18n.language === 'en' ? typeItem.nameEn : typeItem.nameVi
      )
    )
    return IDCTypeName
  }

  return (
    <View style={customerStyles.container}>
      <OBSectionContent style={customerStyles.answerHeader}>
        <View>
          <OBFieldTitle text={t('Outbound:OBCustomerAnwser:IDCType')}></OBFieldTitle>
          <OBFieldDescription text={optionItem(IDCCustomerAnswerPayload?.idcType || '')}></OBFieldDescription>
        </View>
        <View>
          <OBFieldTitle text={t('Outbound:OBCustomerAnwser:SendSMSTime')}></OBFieldTitle>
          <OBFieldDescription text={customerAnswer?.smsSenderDate ? PulseOpsFormat.dateStringtoFormat(customerAnswer?.smsSenderDate, 'DD/MM/YYYY - HH:mm:ss') : '-'}></OBFieldDescription>
        </View>
        <View>
          <OBFieldTitle text={t('Outbound:OBCustomerAnwser:FeedbackTime')}></OBFieldTitle>
          <OBFieldDescription text={customerAnswer?.completeTimeQuestion ? PulseOpsFormat.dateStringtoFormat(customerAnswer?.completeTimeQuestion, 'DD/MM/YYYY - HH:mm:ss') : '-'}></OBFieldDescription>
        </View>
        <View>
          <OBFieldTitle text={t('Outbound:OBCustomerAnwser:TimesOfSendingSMSToCustomers')}></OBFieldTitle>
          <OBFieldDescription text={customerAnswer?.timeRetry ? customerAnswer?.timeRetry.toString() : '-'}></OBFieldDescription>
        </View>
      </OBSectionContent>
      <OBSectionContent style={customerStyles.answerBody}>
        <OBTitle text={t('Outbound:OBCustomerAnwser:CustomerAnwser')}></OBTitle>
        {customerAnswer && customerAnswer.answerResults && customerAnswer.answerResults.length > 0 && customerAnswer.answerResults.map((customerItem, index) => {
          const questionItem = `${index + 1}. ${customerItem.questionContent}`
          const answerItem = customerItem.answerUser === 'Y' ? 'Yes' : 'No'
          return (
            <OBSectionRow style={customerStyles.secondLine}>
              <OBSectionCol style={OBSharedStyles.sectionCol12}>
                <OBFieldTitle text={questionItem}></OBFieldTitle>
                <OBFieldDescription text={answerItem}></OBFieldDescription>
                {customerItem.decisionResult === 'FAIL' && (
                  <View style={customerStyles.inputContainer}>
                    <Input title={t('Outbound:OBCustomerAnwser:Opinion')} value={customerItem.commentUser} multiline={true} disabled />
                  </View>
                )}
              </OBSectionCol>
            </OBSectionRow>
          )
        })}

      </OBSectionContent>
    </View>
  )
}

const customerStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  answerHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
  },
  answerBody: {
    marginVertical: 24,
  },
  secondLine: {
    marginTop: 20,
  },
  inputContainer: {
    backgroundColor: '#F9F9F9',
    padding: 20,
    marginTop: 24
  }
})