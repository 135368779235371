import { SectionHeader } from './section-header'
import { View } from './styled'
import styled from 'styled-components/native'

export const Form = {
  Group: styled(View)`
    margin-bottom: 1rem;
  `,
  GroupTitle: SectionHeader,
  Row: styled(View)`
    flex-direction: row;
    margin-left: 50px;
    margin-right: 50px;
  `,
  Col: styled(View)`
    flex: 1;
  `
}
