import React from 'react'
import { StyleProp, ViewStyle, View, Text, Dimensions } from 'react-native'
import { TableCell, TableBody, Table, TableContainer, TableHead, Paper, TableRow, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import { IHeatData } from '@pulseops/common'
import { useTranslation } from 'react-i18next'

type keyType = string | number | JSX.Element

export type DataSource<T = keyType> = {
  [key: string]: T
}

export type Columns = {
  title: string
  name: string
  builder?: (data: IHeatData) => JSX.Element
  cellStyle?: StyleProp<ViewStyle>
  headerCellStyle?: StyleProp<ViewStyle>
}

const useStyles = makeStyles({
  container: {
    maxHeight: Dimensions.get('window').height - 340
    //width: Dimensions.get('window').width - 20
  },
  tableHeader: {
    backgroundColor: '#F4F4F4',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    justifyContent: 'flex-start'
  },
  headerCell: {
    position: 'sticky',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E',
    alignSelf: 'flex-start'
  },
  cell: {
    whiteSpace: 'nowrap'
  },
  stickyLeft: {
    position: 'sticky',
    left: 0
  }
})

export const TableIheat: React.FC<{ columns: Columns[]; dataSource: IHeatData[] }> = ({ columns, dataSource }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {columns.map((col, index) => {
              return <TableCell className={classes.headerCell}>{col.title}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.map((row, index) => {
            return (
              <TableRow key={`row_table_${index}`} hover>
                {columns.map((col, index) => {
                  const name = _.get(row, col.name) || ''
                  return (
                    <TableCell key={`cell_table_${index}`} className={classes.cell}>
                      {col.builder?.call(null, row) || name}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
        {dataSource.length === 0 && (
          <TableRow>
            <TableCell style={{ textAlign: 'center' }} colSpan={12}>
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Text>{t('common:noRecord')}</Text>
              </View>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </TableContainer>
  )
}
