import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ContractMapping,
  ErrorHandling,
  FieldList,
  Panel,
  TaskDetailService,
  useTranslator
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'

export const PolicyInfoDigitalScreen = (props: {
  id: string
  data: FieldList.DataSources
  isEditable?: boolean
  setData: (val: FieldList.DataSources) => void
}) => {
  const { isEditable = true } = props

  const [loading, bindLoading] = useLoading()

  const { t: tCommon } = useTranslator('common')
  const { t } = useTranslator('TaskManagement')
  const ct = useTranslator('contract').t

  const getData = (): ZIO<unknown, Throwable, FieldList.DataSources> =>
    pipe(
      TaskDetailService.getDigitalPolicyFull(props.id),
      ZIO.map((res) => {
        console.log(res)
        return res === null
          ? []
          : [
              {
                label: tCommon('PolicyOwner'),
                value: res.policy.policyOwner
              },
              {
                label: t('MainLifeAssured'),
                value: res.policy.mainLA
              },
              {
                label: t('ContractName'),
                value: res.policy.contractName
              },
              {
                label: t('PolicyStatus'),
                value: ContractMapping.get(ct)(res.policy.policyStatus),
                isHighlightWarning: res.policy?.policyStatus !== 'INFORCE',
                bold: true
              },
              {
                label: t('SumAssured'),
                value: res.policy.sumAssured
              },
              {
                label: t('InstallmentPremium'),
                value: res.policy.installmentPremium
              },
              {
                label: t('Frequency'),
                value: res.policy.frequency
              },
              {
                label: t('1stIssueDate'),
                value: res.policy.firstIssuedDate
              },
              {
                label: t('RiskCommencementDate'),
                value: res.policy.riskCommenceDate
              },
              {
                label: t('PremiumCessationDate'),
                value: res.policy.premiumCessDate
              },
              {
                label: t('RiskCessationDate'),
                value: res.policy.riskCessDate
              }
            ]
      })
    )

  const policyInfoList = pipe(
    props.data.length > 0 ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x.length > 0 && props.data.length === 0) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  return (
    <Container loading={loading}>
      {policyInfoList && policyInfoList?.length > 0 && (
        <Panel title={tCommon('Policy')} allowToggle={isEditable}>
          <FieldList dataSource={policyInfoList} />
        </Panel>
      )}
    </Container>
  )
}
