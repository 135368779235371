import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { SourceType } from '../SourceType'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.RIDER_ALTERATION)
    }),
    codec
  ])

const RiderListAdditional = t.type({
  coverageCode: t.string,
  sumAssured: t.number,
  policyPeriod: t.number
})

export const LifeAssuredAdditions = t.array(
  t.type({
    lifeAssured: t.type({
      lastName: t.string,
      firstName: t.string,
      fullName: t.string,
      isNewLA: t.boolean,
      documentType: t.string,
      idNumber: t.string,
      issueDate: t.string,
      issuePlace: Maybe(t.string),
      isUSTax: t.boolean,
      dateOfBirth: t.string,
      gender: t.string,
      nationality: t.string,
      mobileCode: t.string,
      mobilePhone: t.string,
      email: t.string,
      occupation: t.string,
      jobDescription: t.string,
      jobTitle: t.string,
      companyName: t.string,
      companyAddress: t.string,
      averageIncome: t.string,
      lifeNo: Maybe(t.string),
      clientNumber: Maybe(t.string),
      riderListAdditional: t.array(RiderListAdditional),
      duplicatePhones: t.array(
        t.type({
          clientName: t.string,
          clientID: t.string,
          relationshipType: t.string
        })
      ),
      duplicateEmails: t.array(
        t.type({
          clientName: t.string,
          clientID: t.string,
          relationshipType: t.string
        })
      ),
      addressDetails: Maybe(t.type({
        ADDRESS: Maybe(t.type({
          line1: Maybe(t.string),
          city: Maybe(t.string),
          district: Maybe(t.string),
          subDistrict: Maybe(t.string),
          countryCode: Maybe(t.string),
        })),
        FOREIGN_ADDRESS: Maybe(t.type({
          line1: Maybe(t.string),
          countryCode: Maybe(t.string),
        }))
      })),
      attributesExt: Maybe(t.type({
        NATIONALITY_2: Maybe(t.string),
        nationality2Name: Maybe(t.string),
        countryName: Maybe(t.string),
        districtName: Maybe(t.string),
        wardName: Maybe(t.string),
        cityCode: Maybe(t.string),
        districtCode: Maybe(t.string),
        subDistrictCode: Maybe(t.string),
      }))
    })
  })
)

export const LifeAssuredList = t.array(
  t.type({
    clientNo: t.string,
    riderList: t.array(
      t.type({
        coverageCode: t.string,
        riskCommDate: t.string,
        riskCessDate: t.string,
        sumAssured: t.number,
        installmentPremium: t.number,
        newInstallmentPremium: t.number,
        alteredSumAssured: Maybe(t.number),
        lifeNo: t.string,
        coverageNo: t.string,
        riderNo: t.string,
        riderStatus: t.string
      })
    )
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        request: Maybe(t.string),
        requiredPayInAmount: Maybe(t.number),
        isRiderAlteration: Maybe(t.boolean),
        isAddLA: Maybe(t.boolean),
        lifeAssuredList: LifeAssuredList,
        lifeAssuredAdditions: LifeAssuredAdditions
      })
    })
  })
)

const riderCommonRequest = t.type({
  transactionType: t.literal(TransactionType.RIDER_ALTERATION),
  payload: t.type({
    body: t.type({
      request: t.string,
      transWf: Maybe(t.string)
    })
  }),
  source: Maybe(t.string)
})

export const RiderAlteration = t.union([riderCommonRequest, Pulse4Ops])
export const NewRiderAlterationPayload = Pulse4Ops
export type RiderAlteration = t.TypeOf<typeof RiderAlteration>
export type NewRiderAlterationPayload = t.TypeOf<typeof NewRiderAlterationPayload>
