import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React from 'react'
import {
  assets,
  ErrorHandling,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  PulseOpsFormat,
  SelectOption
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { ComplainInquiryForm } from '../../../../../submission/src/complain-inquiry/complain-inquiry-form'
import { IBGeneralField, TypeInputComponent } from '../../common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail?: InquiryComplaintData.DetailData | null
}

export const IBMoneyMisappropriation = ({ detail, form }: Props) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])

  const {
    control,
    formState: { errors },
    watch,
    clearErrors
  } = form

  const { fields, append, remove, update } = useFieldArray<ComplainInquiryForm.Raw>({
    control,
    name: 'moneyMissappropriation.list'
  })

  const receiptTypeList = pipe(
    InquiryComplaintService.getT22Data('receipt-type'),
    ZIO.map((list) => {
      return list.map((item) => ({
        ...item,
        name: item.nameEn,
        nameVi: item.name
      }))
    }),
    ErrorHandling.runDidMount()
  )

  const onChangeReceipt = (e: string, index: number) => {
    if (watch(`moneyMissappropriation.list.${index}.receipt`) === 'no') {
      update(index, {
        receipt: 'no',
        receiptType: null,
        complaintAmount: '',
        receiptNumber: '',
        benefit_PremiumMisappropriatedDate: new Date()
      })
    }
  }

  const onChangeReceiptType = (e: SelectOption | null, index: number) => {
    if (!!e) {
      clearErrors(`moneyMissappropriation.list.${index}.receiptType`)
    }
  }

  const converOptions = (arr: any[]): SelectOption[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  const setTotalComplaintAmount = () => {
    let total = 0
    const list = form.getValues('moneyMissappropriation.list')
    total = _.reduce(list, (sum, n) => sum + PulseOpsFormat.thousandSepartorReverse(n.complaintAmount), 0)
    form.setValue('moneyMissappropriation.totalComplaintAmount', PulseOpsFormat.thousandSepartor(total))
  }

  const onComplaintAmountChange = (val: string, index: number) => {
    const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    form.setValue(
      `moneyMissappropriation.list.${index}.complaintAmount` as 'moneyMissappropriation.list.0.complaintAmount',
      newVal
    )
    setTotalComplaintAmount()
  }

  const onRemoveItem = (index: number) => {
    remove(index)
    setTotalComplaintAmount()
  }

  const onAddMoneyMisappropriationItem = () => {
    append({
      receipt: 'no',
      receiptType: null,
      complaintAmount: '',
      receiptNumber: '',
      benefit_PremiumMisappropriatedDate: new Date()
    })
  }
  return (
    <View>
      <Text style={{ fontWeight: '700', fontSize: 16, marginBottom: 10 }}>
        {t('ComplaintedMoneyMisappropriationDetails')}
      </Text>
      <View style={styles.formStyle}>
        {fields.map((field, index) => (
          <Panel title="">
            <IBGeneralField
              FieldForm={form}
              col={3}
              typeInput={[
                {
                  type: TypeInputComponent.RADIOGROUP,
                  formName: `moneyMissappropriation.list.${index}.receipt`,
                  title: t('Receipt'),
                  required: true,
                  option: [
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ],
                  handleData: (value) => {
                    onChangeReceipt(value as string, index)
                  }
                },
                {
                  type: TypeInputComponent.SELECT,
                  formName: `moneyMissappropriation.list.${index}.receiptType`,
                  title: t('ReceiptType'),
                  option: receiptTypeList ? converOptions(receiptTypeList) : [],
                  required: watch(`moneyMissappropriation.list.${index}.receipt`) === 'yes',
                  handleData: (value) => {
                    onChangeReceiptType(value, index)
                  }
                },
                {
                  type: TypeInputComponent.INPUT,
                  formName: `moneyMissappropriation.list.${index}.receiptNumber`,
                  title: t('ReceiptNumber'),
                  inputType: 'input',
                  required:
                    watch(`moneyMissappropriation.list.${index}.receiptType`)?.value !== 'RC06' &&
                    watch(`moneyMissappropriation.list.${index}.receiptType`)?.value !== 'RC05' &&
                    watch(`moneyMissappropriation.list.${index}.receipt`) !== 'no',
                  maxLength: 15
                },
                {
                  type: TypeInputComponent.INPUT,
                  formName: `moneyMissappropriation.list.${index}.complaintAmount`,
                  title: t('ComplaintAmount'),
                  inputType: 'input',
                  suffix: 'VNĐ',
                  handleData: (value) => {
                    onComplaintAmountChange(value as string, index)
                  }
                },
                {
                  type: TypeInputComponent.DATE,
                  formName: `moneyMissappropriation.list.${index}.benefit_PremiumMisappropriatedDate`,
                  title: t('Benefit/PremiumMisappropriatedDate'),
                  required: true
                }
              ]}
            />
            <View>
              <TouchableOpacity
                onPress={() => onRemoveItem(index)}
                style={{ alignItems: 'center', flexDirection: 'row' }}
              >
                <assets.DeleteBin />
                <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Delete')}</Text>
              </TouchableOpacity>
            </View>
          </Panel>
        ))}
        <View style={{ marginBottom: 15 }}>
          <Text style={{ color: '#ED1B2E' }}>{_.get(errors.moneyMissappropriation as unknown, 'list')?.message}</Text>
        </View>
        <View style={{ marginBottom: 15 }}>
          <TouchableOpacity
            onPress={onAddMoneyMisappropriationItem}
            style={{ alignItems: 'center', flexDirection: 'row' }}
          >
            <assets.PlusCircleIcon />
            <Text style={{ marginLeft: 10, fontWeight: '700' }}>{t('common:Add')}</Text>
          </TouchableOpacity>
        </View>
        <View>
          <IBGeneralField
            FieldForm={form}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.INPUT,
                formName: `moneyMissappropriation.totalComplaintAmount`,
                title: t('TotalComplaintAmount'),
                inputType: 'input',
                disabled: true,
                suffix: 'VNĐ'
              }
            ]}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  formStyle: {
    flex: 1
  }
})
