import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'

export namespace SearchForm {
  const Base = t.type({
    policyNumber: pipe(
      form2.string.optional,
      form2.string.minLength(8, () =>
        i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:PolicyNumber'), k: 8 })
      )
    ),
    clientNumber: pipe(
      form2.string.optional,
      form2.string.minLength(8, () =>
        i18next.t('message:MS990031', { field: i18next.t('ServiceInquiry:ClientNumber'), k: 8 })
      )
    ),
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:FromDate') })
    ),
    toDate: form2.date.requiredM(() => i18next.t('message:MS020009', { field: i18next.t('ServiceInquiry:ToDate') }))
  })
  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const form = pipe(
    Base,
    form2.refine(
      (data): data is Form => moment(data.toDate).isSameOrAfter(data.fromDate, 'day'),
      () => i18next.t('message:MS030044'),
      'Form'
    ),
    form2.refine(
      (data): data is Form => moment(data.toDate).diff(data.fromDate, 'months', true) <= 3,
      () => i18next.t('message:MS080004', { k: 3 }),
      'Form'
    )
  )

  export const codec = form

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
