export namespace AgentConst {
  export const OfficeReasonList = [
    { value: '01', nameVn: 'Chuyển đổi nơi cư trú', nameEn: 'Relocation' },
    { value: '02', nameVn: 'Khác', nameEn: 'Others' }
  ]

  export const LeaderReasonList = [
    { value: '01', nameVn: 'Là TVV không có leader hỗ trợ', nameEn: 'Agent has no Unit (Dummy)' },
    { value: '02', nameVn: 'Leader mới là người thân', nameEn: "New Leader is agent's relatives" },
    { value: '03', nameVn: 'Leader mới là người giới thiệu', nameEn: "New Leader is agent's referral" },
    {
      value: '04',
      nameVn: 'Leader mới ở gần nhà, dễ hỗ trợ hoạt động',
      nameEn: 'New Leader can support agent easily due to living near/in the same area'
    },
    { value: '05', nameVn: 'Leader cũ không còn hoạt động', nameEn: 'Old Leader was inactive' },
    { value: '06', nameVn: 'Leader cũ không hỗ trợ', nameEn: "Old Leader didn't support" },
    {
      value: '07',
      nameVn: 'Mâu thuẫn với Leader cũ, không thể tiếp tục hoạt động',
      nameEn: 'Agent cannot work due to confliction with Old Leader'
    },
    { value: '08', nameVn: 'Chuyển về Leader cũ trước đây', nameEn: 'Transfer to old Unit' },
    { value: '09', nameVn: 'Khác', nameEn: 'Others' }
  ]

  export const ReasonList = [
    { value: '01', nameVn: 'Lý do cá nhân', nameEn: 'Personal reason' },
    { value: '02', nameVn: 'Chuyển công tác khác', nameEn: 'Change to another job' },
    { value: '03', nameVn: 'Chuyển sang làm nhân viên Công ty', nameEn: "Change to be Prudentail's employee" },
    { value: '04', nameVn: 'Chuyển sang làm nhân viên GA', nameEn: 'Change to be GA Staff' },
    { value: '05', nameVn: 'Định cư ở nước ngoài', nameEn: 'Going to living abroad' },
    { value: '06', nameVn: 'Đi du học ở nước ngoài', nameEn: 'Study abroad' },
    { value: '07', nameVn: 'Đi nghĩa vụ', nameEn: 'Join the military' },
    { value: '08', nameVn: 'Không đủ thời gian', nameEn: 'Not enough time' },
    { value: '09', nameVn: 'Không phù hợp với công việc', nameEn: 'Unsuitable for the job' },
    { value: '10', nameVn: 'Không thu xếp được công việc cũ', nameEn: 'Change to another job' },
    { value: '11', nameVn: 'Sức khỏe kém', nameEn: 'Bad heathy' },
    { value: '12', nameVn: 'Chuyển kênh phân phối', nameEn: 'Change to other distribution channel' }
  ]

  export const TransferList = [
    {
      value: '01',
      nameVn: 'KH không hài lòng về quy trình thay đổi ĐL của công ty (Complant)',
      nameEn: "Customers are not satisfied with the company's process of changing agent (Complant)"
    },
    {
      value: '02',
      nameVn: 'KH mất lòng tin do tư cách của ĐL (Complant)',
      nameEn: "Customers distrust due to agent's capacity (Complant)"
    },
    {
      value: '03',
      nameVn: 'KH yêu cầu thay đổi ĐL vì KH thay đổi nơi cư ngụ, ĐL khó có thể chăm sóc khách hàng tốt (NonComplant)',
      nameEn:
        'Customer requested to change service agent because the customer changed the residence, agent could not take care of customer (NonCompplant)'
    },
    {
      value: '04',
      nameVn: 'KH yêu cầu thay đổi ĐL vì ĐL có thái độ không đúng mực với KH (Complant)',
      nameEn:
        'Customer requested to change service agent because the agent had an inappropriate attitude towards the customer (Complant)'
    },
    {
      value: '05',
      nameVn: 'KH yêu cầu thay đổi ĐL vì ĐL cũ đã nghỉ việc/bị tạm đình chỉ công việc/qua đời (NonComplant)',
      nameEn:
        'Customer requested to change service agent because the old employee terminated/suspended from work/died (NonCompplant)'
    },
    {
      value: '06',
      nameVn: 'KH yêu cầu thay đổi ĐL vì ĐL không thu phí như đã hứa (Complant)',
      nameEn: 'Customer requested to change service agent because agent does not collect the fee as promised (Complant)'
    },
    {
      value: '07',
      nameVn: 'KH yêu cầu thay đổi ĐL vì ĐL không thu phí như đã hứa (HĐ đã chuyển qua nhiều ĐL phục vụ) (Complant)',
      nameEn:
        'Customer requested to change service agent because agent does not collect the fee as promised (the contract has been transferred to many servicing agents) (Complant)'
    },
    {
      value: '08',
      nameVn: 'KH yêu cầu thay đổi ĐL vì ĐL không thực hiện một số điều chỉnh trong HĐ bảo hiểm (Complant)',
      nameEn:
        'Customer requested to change service agent because gent does not make some adjustments in the insurance contract (Complant)'
    },
    {
      value: '09',
      nameVn: 'KH yêu cầu thay đổi ĐL vì ĐL không tặng quà như đã hứa (NonComplant)',
      nameEn:
        'Customer requested to change service agent because agent did not give the gift as promised (NonCompplant)'
    },
    {
      value: '10',
      nameVn: 'KH yêu cầu thay đổi ĐL vì KH thay đổi nơi cư ngụ, không thể tiếp tục chăm sóc khách hàng (NonComplant)',
      nameEn:
        'Customer requested to change service agent because agent changed the residence and could not continue to take care of the customer (NonCompplant)'
    },
    {
      value: '11',
      nameVn: 'KH yêu cầu thay đổi ĐL vì đã lâu ĐL không thăm hỏi KH (Complant)',
      nameEn:
        'Customer requested to change service agent because agent did not visit the customer for a long time (Complant)'
    },
    {
      value: '12',
      nameVn: 'KH yêu cầu thay đổi ĐL và hiện tại HĐ chưa phân công ĐL mới (Complant)',
      nameEn:
        'Customer requested to change service agent and currently the contract has not assigned a new servicing agent (Complant)'
    },
    {
      value: '13',
      nameVn: 'Kể từ khi thay đổi ĐL, ĐL mới không liên lạc, chăm sóc khách hàng (Complant)',
      nameEn: 'Since changing new servicing agent, new agent has not contacted and taken care of customer (Complant)'
    },
    {
      value: '14',
      nameVn: 'ĐL mới có mối quan hệ thân cận với KH (NonComplant)',
      nameEn: 'New agent has a close relationship with customer (NonCompplant)'
    },
    {
      value: '15',
      nameVn: 'ĐL ở xa không phục vụ được (Complant)',
      nameEn: 'Current servicing agent was far away so cannot have good service to customer (Complant)'
    }
  ]
}
