import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  AmlResult,
  AmlService,
  assets,
  BorderContainer,
  Button,
  ContractDetail,
  ContractDetailC,
  DetailService,
  HistoryType,
  RefreshButton,
  RowLoading,
  UtilRow
} from '@pulseops/business/core'
import { ErrorHandling, subscribe, RBAC, Permission } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EditHistoryModalService } from '../../../edit-history-modal-service'
import { ContractDetailContext } from '../../contract-detail-context'
import { ContractInfoEdit } from './contract-info-edit'
import { ContractInfoView } from './contract-info-view'

export const ContractInfo = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const [loading, bindLoading] = useLoading(false)
  const [refreshLoading, bindRefreshLoading] = useLoading(false)
  const [amlLoading, bindAmlLoading] = useLoading(false)

  const [isEdit, setIsEdit] = React.useState(false)

  const info: ContractDetail | null = pipe(service.state.detail.watch, subscribe())

  EditHistoryModalService.useHistory(service.state.history.clear)

  const history: ContractDetail | null = pipe(
    EditHistoryModalService.viewing.stream,
    ZStream.chainEffect((item) =>
      pipe(
        service.state.detail.get,
        ZIO.map((info) =>
          pipe(
            item,
            O.fromNullable,
            O.filter((item) => !!info && item.version < info.version),
            O.chain((item) => pipe(item, ContractDetailC.decode, O.fromEither)),
            O.toNullable
          )
        )
      )
    ),
    subscribe()
  )

  const refresh = () => {
    pipe(service.refreshInfo, bindRefreshLoading, ErrorHandling.run())
  }

  const scanAml = () => {
    if (!info) return
    pipe(
      AmlService.scanContract({
        uuid: info.uuid,
        uniqueIdentifierAmlPolicyHolder: info.policyHolder.uniqueIdentifierAml ?? '',
        uniqueIdentifierAmlPolicyAdmin: info.policyAdmin.uniqueIdentifierAml ?? '',
        uniqueIdentifierAmlLegal: info.legalRepresentative.uniqueIdentifierAml ?? '',
        amlResultPolicyHolder: AmlResult.formatLatest(info.policyHolder.historyAmlResult).result,
        amlResultPolicyAdmin: AmlResult.formatLatest(info.policyAdmin.historyAmlResult).result,
        amlResultLegal: AmlResult.formatLatest(info.legalRepresentative.historyAmlResult).result,
        idNumberPolicyHolder: '',
        idNumberPolicyAdmin: info.policyAdmin.idNumber,
        idNumberLegal: info.legalRepresentative.idNumber
        // uuid: info.uuid,
        // policyAdmin: {
        //   fullName: info.policyAdmin.fullName,
        //   position: info.policyAdmin.position,
        //   idNumber: info.policyAdmin.idNumber
        // },
        // policyHolder: {
        //   policyHolder: info.policyHolder.policyHolder,
        //   businessSector: info.policyHolder.businessSector,
        //   businessLicense: info.policyHolder.businessLicense,
        //   dateOfRegistration: info.policyHolder.dateOfRegistration,
        //   placeOfRegistration: info.policyHolder.placeOfRegistration,
        //   contactAddress: info.policyHolder.contactAddress,
        //   businessAddress: info.policyHolder.businessAddress
        // },
        // legalRepresentative: {
        //   fullName: info.legalRepresentative.fullName,
        //   position: info.legalRepresentative.position,
        //   idNumber: info.legalRepresentative.idNumber,
        //   dateOfBirth: info.legalRepresentative.dateOfBirth,
        //   gender: info.legalRepresentative.gender,
        //   nationality: info.legalRepresentative.nationality,
        //   permanentAddress: info.legalRepresentative.permanentAddress,
        //   country: info.legalRepresentative.country,
        //   residentAddress: info.legalRepresentative.residentAddress,
        //   country1: info.legalRepresentative.country1
        // },
        // uniqueIdentifierAml: info?.policyAdmin?.uniqueIdentifierAml ?? ''
      }),
      bindAmlLoading,
      ErrorHandling.run()
    )
  }

  pipe(service.getInfo, bindLoading, ErrorHandling.runDidMount())

  if (loading) {
    return <RowLoading />
  }

  if (!info) {
    return null
  }

  return (
    <>
      <UtilRow>
        <Button
          title={t('edit_history')}
          type="outline"
          icon={<assets.IconTime />}
          onPress={() => {
            EditHistoryModalService.viewHistory({
              historyType: HistoryType.Contract,
              masterContractNo: contractId.masterContractNo,
              policyYear: contractId.policyYear.toString()
            })
          }}
          disabled={isEdit || refreshLoading || amlLoading}
        />
        {roles.includes(Permission['EditContractInfoGroupDetailGeneralInfo']) && (
          <Button
            title={t('edit')}
            type="outline"
            icon={<assets.IconEdit />}
            onPress={() => {
              setIsEdit(true)
            }}
            disabled={isEdit || refreshLoading || amlLoading || !!history}
          />
        )}
        <Button
          title={t('update_aml')}
          type="outline"
          icon={<assets.IconPaper />}
          onPress={scanAml}
          loading={amlLoading}
          disabled={isEdit || refreshLoading || !!history}
        />
        <RefreshButton onPress={refresh} loading={refreshLoading} disabled={isEdit || amlLoading || !!history} />
      </UtilRow>
      <BorderContainer>
        {isEdit ? (
          <ContractInfoEdit
            onClose={() => {
              setIsEdit(false)
            }}
          />
        ) : (
          <ContractInfoView info={history || info} />
        )}
      </BorderContainer>
    </>
  )
}
