import * as React from 'react'
import { CheckBox as CheckBoxCore, CheckBoxProps } from 'react-native-elements'

export const CheckBox = (props: CheckBoxProps & { title: string | JSX.Element }) => {
  return (
    <CheckBoxCore
      {...props}
      title={props.title || ''}
      checkedIcon="dot-circle-o"
      uncheckedIcon="circle-o"
      containerStyle={
        props?.containerStyle ?? { borderRadius: 8, height: 62, justifyContent: 'center', backgroundColor: '#FFFFFF' }
      }
    />
  )
}
