import { FileUploadData, Frequency, RiderAlterationFormData, SelectOption } from '@pulseops/common'
import { CancelRiderFormData } from '../cancel-rider'

export namespace MajorCombinedForm {
  type Option = {
    value: Frequency
    label: string
  }
  export interface MajorCombined {
    major: string
    changeContract?: ChangeContractForm
    billingChange?: BillingChangeForm
    productOptionSwitching?: ProductOptionSwitchingForm
    cancelRider?: CancelRiderFormData.CancelAllRiderForm & { totalPremium: number }
    reinstatement?: ReinstatementForm
    riderReinstatement?: RiderReinstatementForm
    riderAlteration?: RiderAlterationFormData.RiderAlteration
    requirePayinAmount?: string
  }

  interface ChangeContractForm {
    baseProductCode: string
    riskCommDate: string
    age: number
    sumAssured: string
    policyYear: string
    riskCessationDate: string
    premiumYear: string
    installmentPremium: string
  }

  interface BillingChangeForm {
    curBillingFrequency: string
    curInstallmentPremium: string
    newBillingFrequency?: Option
    newInstallmentPremium: string
    effectiveDateNewFrequency: string
    requirePayinAmount: string
  }

  interface ProductOptionSwitchingForm {
    benefits: SelectOption | null
    oldBenefit: string
    currentSumAssured: number
    newSumAssured: number
    newInstallmentPremium: number
    attachmentFiles: FileUploadData[]
  }

  interface RiderReinstatementForm {
    reinFee: number
    lifeAssuredList: LifeAssured[]
    attachmentFiles: FileUploadData[]
  }

  export interface LifeAssured {
    name: string
    clientNum: string
    healthAnswer: boolean
    occupationAnswer: boolean
    activitiesAnswer: boolean
    newActivity?: string
    companyAnswer: boolean
    newCompany?: string
    curOccupation: SelectOption
    newOccupation?: SelectOption
    agentCode: string
    riderList: RiderDetail[]
  }

  export interface RiderDetail {
    coverageCode: string
    riskCommDate: string
    lapsedDate: string
    sumAssured: number
    riderPremium: number
  }

  interface ReinstatementForm {
    riskCommonDate: string
    lapsedDate: string
    frequency: string
    installmentPrem: number
    transCode: string
    apl: number
    opl: number
    reinFee: number
    totalReinAmount: number
    agentCode: string
    agentName: string
    clientId: string
    reinClients: ReinDetail[]
    attachmentFiles: FileUploadData[]
  }

  export interface ReinDetail {
    name: string
    clientNum: string
    healthAnswer: boolean
    occupationAnswer: boolean
    activitiesAnswer: boolean
    companyAnswer: boolean
    curOccupation?: SelectOption
    newOccupation?: SelectOption
    newActivity?: string
    newCompany?: string
  }
}
