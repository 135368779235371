import * as t from 'io-ts'
import { Nullable } from '@mxt/zio/codec'

export const HistoryC = t.array(
  t.type({
    createdDate: t.string,
    fields: Nullable(
      t.array(
        t.type({
          name: t.string,
          newValue: Nullable(t.string),
          oldValue: Nullable(t.string)
        })
      )
    ),
    processInstanceId: t.string,
    user: Nullable(t.string),
    workFlowStage: Nullable(t.string)
  })
)

export const History = t.type({
  fields: t.array(t.type({ name: t.string, newValue: t.string, oldValue: t.string })),
  field: t.string,
  newValue: t.string,
  originalValue: t.string,
  email: Nullable(t.string),
  name: t.string,
  createdDate: t.string,
  workFlowStage: t.string
})
export type History = t.TypeOf<typeof History>
