import { View, Text, StyleSheet, SafeAreaView, ScrollView, TouchableOpacity } from 'react-native'
import React from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import {
  AppContext,
  // PolicyInfoHeader,
  Panel,
  form2,
  useMobile,
  Select,
  Input,
  CashLessService,
  ErrorHandling,
  AuthService,
  TransactionLabel,
  TransactionType,
  SelectSearch,
  assets,
  ImgUploadMutiplePC,
  GeneralService
} from '@pulseops/common'
import { Error } from '@pulseops/submission/common'
import { CashLess, InfoViewList } from '@pulseops/task'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { AmlRequestStackParamList } from '../AmlRequestStackParamList'
import { AmlRequestSubmissionForm } from './AmlRequestSubmissionForm'
import { Controller, UseFormReturn } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyInfoHeader } from '../../ps-submission/transactionScreen/screens'
import moment from 'moment'
import _ from 'lodash'
type Props = StackScreenProps<AmlRequestStackParamList, 'AmlRequestSubmissionCommonScreen'>

export const AmlRequestSubmissionCommonScreen = (props: Props) => {
  const { t, i18n } = useTranslation()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const { isMobile } = useMobile()

  const { base, handleSubmit } = form2.useForm(AmlRequestSubmissionForm.codec, { defaultValues: { attachments: [] } })

  const { control, reset } = base

  const listCaseId = pipe(CashLessService.getListCaseId(props.route.params.policyNum), ErrorHandling.runDidMount({}))
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())

  const getDocsType = () => {
    switch (props.route.params.transactionType) {
      case TransactionType.AML_RED_FLAG_ACCEPT:
        return 'DPC05'
      case TransactionType.AML_RED_FLAG_DECLINE:
        return 'DPC06'
      default:
        return ''
    }
  }

  React.useEffect(() => {
    if (!_.isEmpty(listCaseId.body) && listCaseId.body) {
      base.setValue('caseId', {
        label: listCaseId?.body[0].businessKey || '',
        value: listCaseId?.body[0].businessKey || ''
      })
      handleSelectCaseId(listCaseId?.body[0].businessKey as string)
    }
  }, [listCaseId])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.navigate('HomeScreen')
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('menu:AmlRequest'),
          navigate: () =>
            navigation.navigate('AmlRequestListScreen', {
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeCode: props.route.params.officeCode,
              officeName: props.route.params.officeName
            })
        },
        {
          title: t(`TransactionType:${props.route.params?.transactionType}`),
          navigate: () => null
        }
      ])
    }
  }, [isFocused])

  const headers = [
    {
      label: t('common:PolicyNumber'),
      value: props.route.params?.policyNum ?? ''
    },
    {
      label: t('common:PolicyOwner'),
      value: props.route.params?.policyOwner
    },
    {
      label: t('common:Office'),
      value: `${props.route.params?.officeCode ?? ''} - ${props.route.params?.officeName ?? ''}`
    }
  ]

  const requestTypeData = [
    {
      label: t('submission:category'),
      value: t('TaskManagement:PremiumCollection')
    },
    {
      label: t('submission:serviceType'),
      value: t('submission:Payin')
    },
    {
      label: t('submission:transactionType'),
      value: t(`TransactionType:${props.route.params?.transactionType}`)
    }
  ]
  const onReset = () => {
    reset()
  }

  const onSubmit = handleSubmit((value) => {
    const params = props.route.params
    showGlobalLoading(true)
    const findObjectByCaseIdSelected = listCaseId.body?.find((item) => item.businessKey === value.caseId.value)
    pipe(
      GeneralService.getAzureStorageFiles(
        value?.attachments,
        params.transactionType,
        getDocsType(),
        'PC',
        params.policyNum,
        params.officeCode,
        'AML'
      ),
      ZIO.flatMap((doccument) =>
        CashLessService.submitAmlRequest({
          body: {
            suspendCode: value.suspendType.value,
            caseId: value.caseId.value,
            processInstanceId: findObjectByCaseIdSelected?.id || '',
            unsuspendTypeAml: params.transactionType,
            userId: '',
            poClientNum: findObjectByCaseIdSelected?.poClientNumber || '',
            poNationalId: '',
            policyNum: params.policyNum || '',
            category: findObjectByCaseIdSelected?.category || '',
            serviceType: '',
            source: findObjectByCaseIdSelected?.source || '',
            transactionType: findObjectByCaseIdSelected?.transactionType || '',
            remark: value.remark,
            assignee: findObjectByCaseIdSelected?.assignee || '',
            amlResult: findObjectByCaseIdSelected?.amlResult || '',
            azureDocuments: doccument.map((item) => ({ filename: item.name, url: item.url })),
            containerName: 'premiumcollection'
          },
          authenOption: '',
          primaryPolicyNo: params.policyNum,
          clientId: '',
          officeCode: params.officeCode,
          idNumber: '',
          transactionType: params.transactionType,
          vip: '',
          eventKey: '',
          proposalNumber: '',
          exchangeId: '',
          correlationId: params.policyNum || '',
          createdBy: userInfo?.email || '',
          createdDate: moment().toISOString(),
          source: ''
        })
      ),
      ZIO.map((item) => {
        showGlobalLoading(false)
        showToast(t('message:MS040011'), 'success')
        navigation.navigate('AmlRequestStack', {
          screen: 'AmlRequestListScreen',
          params: {
            policyNum: props.route.params.policyNum,
            policyOwner: props.route.params.policyOwner,
            officeCode: props.route.params.officeCode,
            officeName: props.route.params.officeName
          }
        })
      }),
      ZIO.catchAll((error) => {
        console.log(error)
        showGlobalLoading(false)
        return ZIO.succeed(null)
      }),
      ZIO.unsafeRun({})
    )
  })

  const handleSelectCaseId = (caseId: string) => {
    const findObjectByCaseIdSelected = listCaseId.body?.find((item) => item.businessKey === caseId)

    base.setValue('suspendType', {
      value: 'AML05',
      label: i18n.language === 'en' ? 'Red-Flag' : 'Chờ AML kiểm tra hợp đồng'
    })
    base.setValue(
      'transactionType',
      t(TransactionLabel(findObjectByCaseIdSelected?.transactionType as TransactionType))
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, width: '100%', backgroundColor: 'white' }}>
      <ScrollView>
        <View style={{ flex: 1, width: '100%' }}>
          <View style={{ flex: 1, width: '100%', backgroundColor: '#F2F2F2' }}>
            <PolicyInfoHeader menus={headers} />
          </View>
          <View style={[{ marginHorizontal: 'auto', width: '100%', paddingHorizontal: 15, paddingTop: 15 }]}>
            <Panel title={t('submission:requestType')} isExand={true} containerStyle={{ backgroundColor: '#F9F9F9' }}>
              <InfoViewList dataSource={requestTypeData} containerStyle={{ marginBottom: 0 }} />
            </Panel>
            <Panel
              title={t('AddLackOfDocuments:SUPPLEMENTARYINFORMATION')}
              isExand={true}
              containerStyle={{ marginBottom: '5%' }}
            >
              <View style={isMobile ? styles.rowMobile : styles.row}>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Controller
                    name={`caseId`}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('submission:CaseId')}
                        required
                        options={(listCaseId.body || []).map((item) => ({
                          value: item.businessKey || '',
                          label: item.businessKey || ''
                        }))}
                        placeholder={t('common:Select')}
                        onChange={(e) => {
                          handleSelectCaseId(e?.value as string)
                          onChange(e)
                        }}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                      />
                      // <Select
                      //   required
                      //   label={t('submission:CaseId')}
                      //   onChange={(e) => {
                      //     handleSelectCaseId(e?.value as string)
                      //     onChange(e)
                      //   }}
                      //   onBlur={onBlur}
                      //   value={value}
                      //   errorMessage={error?.message}
                      //   placeholder={t('common:Select')}
                      //   options={(listCaseId.body || []).map((item) => ({
                      //     value: item.businessKey || '',
                      //     label: item.businessKey || ''
                      //   }))}
                      // />
                    )}
                  />
                </View>
                <View style={isMobile ? styles.colFull : [styles.col, { width: '300px' }]}>
                  <Controller
                    name={`suspendType`}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Select
                        required
                        label={t('submission:SuspendType')}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={error?.message}
                        placeholder={t('common:Select')}
                        options={[
                          {
                            value: 'AML05',
                            label: i18n.language === 'en' ? 'Red-Flag' : 'Chờ AML kiểm tra hợp đồng'
                          }
                        ]}
                        disabled
                      />
                    )}
                  />
                </View>
                {base.watch().transactionType && (
                  <View style={isMobile ? styles.colFull : [styles.col, { width: '100%' }]}>
                    <Controller
                      name={'transactionType'}
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          title={t('submission:AddRequestForTransactionType')}
                          onBlur={onBlur}
                          value={value || ''}
                          errorMessage={error?.message}
                          disabled
                        />
                      )}
                    />
                  </View>
                )}
              </View>
              <View style={isMobile ? styles.rowMobile : styles.row}>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Controller
                    name={'remark'}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        required
                        title={t('submission:Remark')}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        errorMessage={error?.message}
                        maxLength={255}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={isMobile ? styles.rowMobile : styles.row}>
                <View style={{ paddingHorizontal: 16, width: '100%' }}>
                  <Text style={{ fontSize: 15, color: '#70777e', fontWeight: 'bold' }}>
                    {t('submission:FileAttachment')}
                    <Text style={{ color: 'red' }}> *</Text>
                  </Text>
                  <Controller
                    control={control}
                    name={'attachments'}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS150004')}`
                      }
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return <>
                        <ImgUploadMutiplePC
                          value={value as any[]}
                          onChange={onChange}
                          timeFormat={'DD/MM/YYYY HH:mm'}
                          maxTotalFile={2}
                          messageFormat={'TaskManagement:MSFormatUploadFileEvidencePC2'}
                          validExtensions={['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF']}
                          messageMaxSize={'message:MS990070'}
                          takeAPicture={false}
                          isFileDownload={false}
                          formatFileNameVi={true}
                        />
                        {error?.message && <Error message={error?.message} />}
                      </>
                    }}
                  />
                </View>
              </View>
            </Panel>
          </View>
        </View>
      </ScrollView>
      <View style={[styles.footer]}>
        <TouchableOpacity style={styles.button} onPress={() => onReset()}>
          <Text style={styles.textButton}>{t('submission:ClearAll')}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={onSubmit}>
          <Text style={styles.textButton}>{t('submission:SendRequest')}</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  rowBtn: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  btnRed: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
