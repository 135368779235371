import { TFunctionResult } from 'i18next'
import React from 'react'
import { View, Text, TextStyle, StyleProp, ViewStyle } from 'react-native'
import { ControlProps } from '../FormProps'
import { RadioButton, RadioButtonProps } from '../radio-button'

export type RadioBtnProps = ControlProps<string> & {
  title?: string | TFunctionResult
  titleStyle?: TextStyle | undefined
  value: string
  options: {
    id: string
    label: string
  }[]
  disabled?: boolean
  required?: boolean
  colLength?: string
  horizontal?: boolean
  radioButtonProps?: RadioButtonProps
  style?: StyleProp<ViewStyle>
  errorMessage?: string
}

export const RadioButtonGroup = ({ horizontal = true, radioButtonProps, ...props }: RadioBtnProps) => (
  <>
    <View
      style={[
        { paddingBottom: props.errorMessage ? 12 : 30, width: props.colLength ? props.colLength : '33.33333333333%' },
        props.style
      ]}
    >
      {props.title && (
        <Text
          style={[
            { fontWeight: 'bold', color: '#70777E', paddingLeft: 4, paddingBottom: 7, fontSize: 15 },
            props?.titleStyle
          ]}
        >
          {props.title} {props.required === true && <Text style={{ color: '#ED1B2C' }}>*</Text>}
        </Text>
      )}
      <View style={{ flexDirection: horizontal ? 'row' : 'column' }}>
        {props.options.map((r, i) => (
          <RadioButton
            {...radioButtonProps}
            disable={props.disabled}
            key={i}
            label={r.label}
            value={r.id}
            selected={r.id === props.value}
            onChange={props.disabled === true || r.id === props.value ? () => {} : props.onChange}
            // onChange={props.disabled === true ? () => {} : props.onChange}
          />
        ))}
      </View>
    </View>
    {props.errorMessage ? <Text style={{ color: 'red', fontSize: 12 }}>{props.errorMessage}</Text> : null}
  </>
)
