import { BusinessStackParamList } from '@pulseops/business'
import { ClaimService, Form, Input, View, Table } from '@pulseops/business/core'
import { StackScreenProps } from '@react-navigation/stack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/native'
import { Link, useIsFocused } from '@react-navigation/native'
import { AppContext, BuildConfig, ErrorHandling, Format } from '@pulseops/common'
import { SafeAreaView, ScrollView } from 'react-native'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { LinearProgress, Paper } from '@material-ui/core'

type Props = StackScreenProps<BusinessStackParamList, 'ClaimDetailScreen'>

export const ClaimDetailScreen = ({ navigation, route }: Props) => {
  const { t } = useTranslation('business')
  const { t: menu } = useTranslation('menu')
  const { pvaClaimId, polNum, cliNum, history } = route.params

  const ref = React.useRef<ScrollView>(null)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const { detail, claimList } = pipe(
    ClaimService.getClaimDetail({ pvaClaimId, polNum, cliNum }),
    ZIO.map((claimList) => {
      return {
        detail: claimList[Number(history || '0')] || null,
        claimList
      }
    }),
    ErrorHandling.runDidUpdate({ detail: null, claimList: [] }, [pvaClaimId, polNum, cliNum, history])
  )

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.replace('HomeScreen')
        },
        {
          title: menu('BackOffice'),
          navigate: () => navigation.navigate('BusinessScreen')
        },
        {
          title: t('ClaimList'),
          navigate: () => navigation.navigate('ClaimListScreen')
        },
        { title: t('ClaimDetail'), navigate: null }
      ])
    }
  }, [isFocused])

  const getGenderName = (gender?: string | null): string => {
    switch (gender) {
      case 'M':
        return 'Nam'
      case 'F':
        return 'Nữ'
      default:
        return '-'
    }
  }

  const getPayTypeName = (payType?: string | null): string => {
    switch (payType) {
      case '1':
        return 'Tiền mặt'
      case '7':
        return 'Nhận tiền mặt tại ngân hàng'
      case '8':
        return 'Chuyển khoản'
      default:
        return '-'
    }
  }

  return detail == null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView ref={ref}>
        <SC.Content>
          <Form.Group>
            <Form.GroupTitle>{t('insurance_event_info')}</Form.GroupTitle>
            <Form.Row>
              <Form.Col>
                <Input label={t('pva_no')} value={detail.pvaClaimId || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('ndbh_id_2')} value={detail.cliNum || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('ndbh_name')} value={detail.cliName || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('pol_num')} value={detail.polNum || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input
                  label={t('application_type')}
                  value={ClaimService.caseTypeMap[detail.caseType] || '-'}
                  readonly
                />
              </Form.Col>
              <Form.Col>
                <Input label={t('claim_type')} value={ClaimService.claimTypeMap[detail.claimType] || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('event_dt')} value={detail.eventDt || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('discharge_dt')} value={detail.dischargeDt || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('pva_application_date')} value={detail.pvaReceivedDt || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('ins_no')} value={detail.insClaimId || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input
                  label={t('application_status')}
                  value={ClaimService.applicationStatusMap[detail.claimStatCd] || '-'}
                  readonly
                />
              </Form.Col>
              <Form.Col>
                <Input label={t('insReceived_dt')} value={detail.insReceivedDt || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('insLastRequestDt')} value={detail.insLastRequestDt || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('insLastDocRcvDt')} value={detail.insLastDocRcvDt || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('insSubDt')} value={detail.insSubDt || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('claimProvAmnt')} value={Format.currencyForBusiness(detail.claimProvAmnt)} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('payout_amount')} value={Format.currencyForBusiness(detail.claimApprAmnt)} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('claimActuAmnt')} value={Format.currencyForBusiness(detail.claimActuAmnt)} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('claimDeduAmnt')} value={Format.currencyForBusiness(detail.claimDeduAmnt)} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('insPayDt')} value={detail.insPayDt || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('regFrom')} value={detail.regFrom || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input
                  multiline
                  numberOfLines={3}
                  label={t('deductReason')}
                  value={detail.deductReason || '-'}
                  readonly
                />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input
                  multiline
                  numberOfLines={3}
                  label={t('description')}
                  value={detail.description || '-'}
                  readonly
                />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input
                  multiline
                  numberOfLines={3}
                  label={t('investigate')}
                  value={detail.investigate || '-'}
                  readonly
                />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input multiline numberOfLines={3} label={t('assessment')} value={detail.assessment || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.GroupTitle>{t('diagnostic')}</Form.GroupTitle>
            <Form.Row>
              <Form.Col>
                <Input label={t('diagCd')} value={detail.diagnosis.diag.diagCd || '-'} readonly />
              </Form.Col>
              <Form.Col style={{ flex: 2 }}>
                <Input label={t('diagName')} value={detail.diagnosis.diag.diagName || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.GroupTitle>{t('payment_info')}</Form.GroupTitle>
            <Form.Row>
              <Form.Col>
                <Input label={t('payType')} value={getPayTypeName(detail.payees.payee.payType)} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('cliNum')} value={detail.payees.payee.cliNum || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('cliName')} value={detail.payees.payee.cliName || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('gender')} value={getGenderName(detail.payees.payee.gender)} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('idNum')} value={detail.payees.payee.idNum || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('birthDt')} value={detail.payees.payee.birthDt || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('bankCd')} value={detail.payees.payee.bankCd || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('bankName')} value={detail.payees.payee.bankName || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('bankAccNum')} value={detail.payees.payee.bankAccNum || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('bankAccName')} value={detail.payees.payee.bankAccName || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('idIssueDt')} value={detail.payees.payee.idIssueDt || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('idIssuePlace')} value={detail.payees.payee.idIssuePlace || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.GroupTitle>{t('history')}</Form.GroupTitle>
            <Table.Container component={Paper}>
              <Table.Main>
                <Table.Head>
                  <Table.Row>
                    <Table.CellHead>{t('pva_no')}</Table.CellHead>
                    <Table.CellHead>{t('ins_no')}</Table.CellHead>
                    <Table.CellHead>{t('policy_no')}</Table.CellHead>
                    <Table.CellHead>{t('pva_application_date')}</Table.CellHead>
                    <Table.CellHead>{t('payout_amount')}</Table.CellHead>
                    <Table.CellHead>{t('application_status')}</Table.CellHead>
                    <Table.CellHead>{t('ndbh_id')}</Table.CellHead>
                    <Table.CellHead>{t('ndbh_name')}</Table.CellHead>
                    <Table.CellHead>{t('application_type')}</Table.CellHead>
                    <Table.CellHead>{t('transfer_date')}</Table.CellHead>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {claimList.map((row, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <SC.Link
                          to={`${BuildConfig.contextPath}/user/drawer/business/claim-detail?pvaClaimId=${pvaClaimId}&polNum=${polNum}&cliNum=${cliNum}&history=${i}`}
                          onPress={() => ref.current?.scrollTo({ y: 0, animated: true })}
                        >
                          {row.pvaClaimId || '-'}
                        </SC.Link>
                      </Table.Cell>
                      <Table.Cell>{row.insClaimId || '-'}</Table.Cell>
                      <Table.Cell>{row.polNum || '-'}</Table.Cell>
                      <Table.Cell>{row.pvaReceivedDt || '-'}</Table.Cell>
                      <Table.Cell>{Format.currencyForBusiness(row.claimApprAmnt)}</Table.Cell>
                      <Table.Cell>{ClaimService.applicationStatusMap[row.claimStatCd] || '-'}</Table.Cell>
                      <Table.Cell>{row.cliNum || '-'}</Table.Cell>
                      <Table.Cell>{row.cliName || '-'}</Table.Cell>
                      <Table.Cell>{ClaimService.caseTypeMap[row.caseType] || '-'}</Table.Cell>
                      <Table.Cell>{Format.date(row.transferDate)}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Main>
            </Table.Container>
          </Form.Group>
        </SC.Content>
      </ScrollView>
    </SafeAreaView>
  )
}

const SC = {
  Content: styled(View)`
    margin: 1rem;
    padding: 1rem 2rem;
    border-radius: 8px;
    background: #ffffff;
  `,
  Link: styled(Link)`
    text-decoration-line: underline;
    color: #1ea5fc;
  `
}
