import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'

export namespace GoAboardForm {
  const Selection = t.type({
    label: form2.string.required,
    value: form2.string.required
  })

  type NumberStringBrand = {
    readonly NumberString: unique symbol
  }

  type NumberString = t.Branded<NonEmptyString, NumberStringBrand>

  const PhoneNumber = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })),
    form2.refine(
      (l): l is NumberString => /\b\d{10}\b/.test(l),
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
      'NumberString'
    )
  )

  const PhoneAboard = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })),
    form2.refine(
      (l): l is NumberString => /^[0-9]*$/.test(l),
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
      'NumberString'
    ),
    form2.refine(
      (l): l is NumberString => l.length >= 8 && l.length <= 20,
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
      'NumberString'
    )
  )
  // const CommonAddress = t.type({
  //   clientName: withMessage(Selection, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:SelectClient').toLowerCase() })
  //   ),
  //   aboardAddress: withMessage(form2.string.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:HouseNumber').toLowerCase() })
  //   ),
  //   addressCountry: withMessage(Selection, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:Country').toLowerCase() })
  //   ),
  //   addressCity: withMessage(form2.string.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:CityProvince').toLowerCase() })
  //   ),
  //   addressDistrict: withMessage(form2.string.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:District').toLowerCase() })
  //   ),
  //   addressWard: withMessage(form2.string.required, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:Ward').toLowerCase() })
  //   ),
  //   clientPhoneCode: withMessage(Selection, () =>
  //     i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone').toLowerCase() })
  //   ),
  //   clientPhoneNumber: PhoneAboard
  // })

  // export type CommonAddress = t.TypeOf<typeof CommonAddress>
  type NotSpecialStringBrand = {
    readonly NotSpecialString: unique symbol
  }

  type NotSpecialString = t.Branded<NonEmptyString, NotSpecialStringBrand>

  const NotSpecialStringC = form2.refine(
    (l): l is NotSpecialString => !/[!@#$%^&*?":{}|()<>\\]/.test(l),
    (l) => i18next.t('message:MS050223'),
    'NotSpecialString'
  )

  export const AboardForm = t.type({
    clientName: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:SelectClient').toLowerCase() })
    ),
    aboardAddress: pipe(
      withMessage(NonEmptyString, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:HouseNumber').toLowerCase() })
      ),
      NotSpecialStringC
    ),
    //abroadAddress: NotSpecialString,
    addressCountry: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Country').toLowerCase() })
    ),
    addressCity: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:CityProvince').toLowerCase() })
      ),
      NotSpecialStringC
    ),
    addressDistrict: pipe(
      withMessage(form2.string.optional, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:District').toLowerCase() })
      ),
      NotSpecialStringC
    ),
    addressWard: pipe(
      withMessage(form2.string.optional, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Ward').toLowerCase() })
      ),
      NotSpecialStringC
    ),
    clientPhoneCode: withMessage(form2.optional(Selection), () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone').toLowerCase() })
    ),
    clientPhoneNumber: form2.optional(PhoneAboard),
    destinationCountry: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:DestinationCountry').toLowerCase() })
    ),
    purpose: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Purpose').toLowerCase() })
    ),
    departureDate: withMessage(form2.date.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:DepartureDate').toLowerCase() })
    ),
    duration: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Duration').toLowerCase() })
    ),
    fatca: t.boolean
    //clientCode: form2.string.required
  })

  export type AboardForm = t.TypeOf<typeof AboardForm>
  export type AboardFormRaw = t.OutputOf<typeof AboardForm>

  type AboardFormCountryBrand = {
    readonly AboardFormCountry: unique symbol
  }

  type AboardFormCountry = t.Branded<AboardForm, AboardFormCountryBrand>

  const AboardFormCountry = pipe(
    AboardForm,
    form2.refine(
      (l): l is AboardFormCountry => l.destinationCountry.value === l.addressCountry.value,
      (l) => i18next.t('message:MS020002', { field: i18next.t('submission:Country') }),
      'AboardFormCountry'
    )
  )

  // type AboardFormCountryBrand = {
  //   readonly AboardFormCountry: unique symbol
  // }

  // type AboardFormCountry = t.Branded<AboardForm, AboardFormCountryBrand>

  // const AboardFormCountry = pipe(
  //   AboardForm,
  //   form2.refine(
  //     (l): l is AboardFormCountry => l.destinationCountry.value === l.addressCountry.value,
  //     (l) => i18next.t('message:MS020002', { field: i18next.t('submission:Country') }),
  //     'AboardFormCountry'
  //   )
  // )

  const PremiumPayerForm = t.type({
    payer: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Payer').toLowerCase() })
      ),
      NotSpecialStringC
    ),
    address: pipe(
      withMessage(NonEmptyString, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:HouseNumber').toLowerCase() })
      ),
      NotSpecialStringC
    ),
    country: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Country').toLowerCase() })
    ),
    city: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:City').toLowerCase() })
    ),
    district: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:District').toLowerCase() })
    ),
    ward: withMessage(Selection, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Ward').toLowerCase() })
    ),
    mobileCode: withMessage(form2.optional(Selection), () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone').toLowerCase() })
    ),
    mobileNumber: form2.optional(PhoneNumber)
  })

  type PremiumPayerForm = t.TypeOf<typeof PremiumPayerForm>

  const GoAboardForm = t.intersection([
    t.type({
      goAbroadClients: t.array(AboardFormCountry),
      isPremiumPayer: t.boolean
    }),
    t.union([
      t.type({
        isPremiumPayer: t.literal(false)
      }),
      t.type({
        isPremiumPayer: t.literal(true),
        premiumPayer: PremiumPayerForm
      })
    ])
  ])

  export const codec = GoAboardForm

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
