import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { HomeScreen } from './HomeScreen'
import { LandingPageBankInfoStackParamList } from './LandingPageBankStackParamList'
const Stack = createStackNavigator<LandingPageBankInfoStackParamList>()

export const LandingPageBankStack = () => {
  return (
    <Stack.Navigator initialRouteName="HomeScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
    </Stack.Navigator>
  )
}
