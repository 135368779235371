import {
  AddLackOfDocumentModel,
  AddLackOfDocumentService,
  AppContext,
  assets,
  ImgUploadMutiple,
  Panel,
  Select,
  StorageBlob,
  T0Data,
  Title,
  TransactionType
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import React from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, SafeAreaView, StyleSheet, TouchableOpacity } from 'react-native'
import { AddlackofDocumentFormData } from './add-lack-documents.form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps } from '../policy-service-props'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { RequestAuthenticateData } from '../../request-authen'
import { Column, Error } from '@pulseops/submission/common'

export interface AddLackOfDocumentProps {
  policyServiceData: PolicyServiceProps<AddLackOfDocumentModel.DataSubmit>
  requestAuthenData: RequestAuthenticateData.EformRequestAuthenticateData
  updateRequestAuthenData: (data: RequestAuthenticateData.EformRequestAuthenticateData) => void
}

export const AddLackofDocument: React.FC<AddLackOfDocumentProps> = ({
  policyServiceData,
  updateRequestAuthenData,
  requestAuthenData
}) => {
  const { policyNumber, initSubmission, isConfirmed, officeCode } = policyServiceData
  const policyNum = policyNumber ?? ''
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const [caseIdOptions, setCaseIdOptions] = React.useState<AddLackOfDocumentModel.Options[]>([])
  const [suspendTypes, setSuspendTypes] = React.useState<AddLackOfDocumentModel.SuppendType[]>([])
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const addLackForm = useForm<AddlackofDocumentFormData.BeneficiaryDesignationRequestValue>({
    defaultValues: {
      docs: []
    }
  })
  const { control, watch, setValue, setError } = addLackForm
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'docs'
  })

  React.useEffect(() => {
    if (isFocused) {
      loadCaseIDList()
      addNewDoc()
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const loadCaseIDList = () => {
    return pipe(
      AddLackOfDocumentService.getListCaseId(policyNum),
      ZIO.map((caseIds) => {
        if (!!caseIds && caseIds.body && caseIds.body.length > 0) {
          const caseIdOptionList = caseIds.body.map(({ caseId }) => ({
            label: caseId,
            value: caseId
          }))
          setCaseIdOptions(caseIdOptionList)
        }
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const addNewDoc = () => {
    append({
      caseId: null,
      transactionType: '',
      suspendTypeOptions: [],
      suspendType: null,
      docType: '',
      attachments: undefined,
      metaData: undefined
    })
  }

  const addNewItemEvent = () => {
    addNewDoc()
  }

  const removeItemEvent = (index: number) => {
    remove(index)
  }

  const onCaseIDChangeEvent = (index: number, caseIDOption: AddLackOfDocumentModel.Options | null) => {
    const caseId = caseIDOption?.value ?? ''
    pipe(
      AddLackOfDocumentService.getSuspendType(caseId),
      ZIO.map((suspendTypeData) => {
        let docType: string = ''
        let suspendTypeOptions: AddLackOfDocumentModel.Options[] = []
        setSuspendTypes(suspendTypeData.body)
        if (!!suspendTypeData && suspendTypeData.body && suspendTypeData.body.length > 0) {
          const transactionTypeItem = T0Data.getValue(suspendTypeData.body[0].transactionType)
          docType = transactionTypeItem
            ? i18n.language === 'en'
              ? transactionTypeItem.transactionType
              : transactionTypeItem.transactionTypeVn
            : ''
          suspendTypeOptions = suspendTypeData.body.map(({ code, typeEn, typeVn }) => ({
            label: i18n.language === 'en' ? typeEn : typeVn,
            value: code
          }))
        }
        setValue(`docs.${index}.transactionType`, '')
        setValue(`docs.${index}.suspendType`, null)
        setValue(`docs.${index}.metaData`, undefined)
        setValue(`docs.${index}.docType`, docType)
        setValue(`docs.${index}.suspendTypeOptions`, suspendTypeOptions)
        setError(`docs.${index}.suspendType`, {
          message: `${t('message:MS020001', { field: t('submission:SuspendType') })}`
        })
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onSuspendTypeChangeEvent = (index: number, suspendTypeOption: AddLackOfDocumentModel.Options | null) => {
    let transactionType = ''
    let metaData: StorageBlob.MetaDataUpload | undefined
    const suspendTypeItem = suspendTypes.find(({ code }) => code === suspendTypeOption?.value)
    if (!!suspendTypeItem) {
      transactionType = suspendTypeItem.transactionType
      if (suspendTypeItem.metadata) {
        const currentDateFormat = moment().format('DD/MM/YYYY')
        const {
          transactionType,
          docTypeEn,
          classFile,
          docId,
          mainDoc,
          subDoc,
          type: typeCode
        } = suspendTypeItem.metadata
        const customedBatchNo = !!officeCode
          ? currentDateFormat + '-' + typeCode + '-' + officeCode
          : currentDateFormat + '-' + typeCode
        metaData = {
          type: transactionType,
          doctype: docTypeEn,
          class: classFile,
          docid: docId,
          maindoc: mainDoc,
          subdoc: subDoc,
          // batchno: `${typeCode}-${D.format(new Date(), 'yyyyMMdd')}`,
          batchno: customedBatchNo,
          polnum: policyNum
        }
      }
    }
    setValue(`docs.${index}.transactionType`, transactionType)
    setValue(`docs.${index}.metaData`, metaData)
  }

  const getAddLackSubmitedData = () => {
    const addLackFormValue = addLackForm.getValues()
    return pipe(
      ZIO.sequence(
        addLackFormValue.docs.map((docItem) =>
          StorageBlob.uploadToSubmit(
            'PS',
            policyNum
          )(
            docItem.attachments.map(({ file }) => ({
              file,
              metaData: docItem.metaData
            }))
          )
        )
      ),
      ZIO.map((documents) => {
        const mappingData: AddLackOfDocumentModel.DataSubmit = {
          addLackOfDocuments: addLackFormValue.docs.map(({ transactionType, suspendType, caseId }, index) => ({
            transactionType,
            caseId: caseId?.value ?? '',
            suspendCode: suspendType?.value ?? '',
            attachments: documents[index]
          }))
        }
        // console.log(`mappingData: ${JSON.stringify(mappingData)}`)
        return mappingData
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const validSuspendType = () => {
    const docList = watch('docs')
    const isInValidSuspendType = docList.some(({ caseId, suspendType }, index) =>
      docList.some(
        (cItem, cIndex) =>
          index !== cIndex && caseId?.value === cItem.caseId?.value && suspendType?.value === cItem.suspendType?.value
      )
    )
    isInValidSuspendType && showToast(t('message:MS050291'), 'error')
    return isInValidSuspendType
  }

  initSubmission({
    validate: async () => {
      const isTrigger = await addLackForm.trigger()
      if (isTrigger && fields.length > 0 && !validSuspendType()) {
        const transactionInfo = {
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.ADD_LACK_OF_DOCUMENT),
          collerationId: policyNum,
          transaction: TransactionType.ADD_LACK_OF_DOCUMENT
        }
        updateRequestAuthenData({
          ...requestAuthenData,
          transactionName: transactionInfo.transactionName,
          collerationId: transactionInfo.collerationId,
          transaction: transactionInfo.transaction
        })
        const submitedData = await getAddLackSubmitedData()
        return {
          url: (policyNum: string) => `wf-api/lack-of-document/policy/${policyNum}/add-lack-of-document`,
          body: submitedData,
          transactionName: transactionInfo.transactionName,
          collerationId: transactionInfo.collerationId,
          transaction: transactionInfo.transaction
        }
      } else {
        return false
      }
    },
    clear: () => {
      remove()
      addNewDoc()
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View>
        <Title title={t('AddLackOfDocuments:SUPPLEMENTARYINFORMATION')}></Title>
        {fields.map((fieldItem, index) => (
          <Panel title="" key={fieldItem.id}>
            <View style={addLackStyles.sectionRow}>
              <View style={addLackStyles.col_4}>
                <Controller
                  control={control}
                  name={`docs.${index}.caseId`}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020001', { field: t('submission:CaseId') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Select
                      label={t('submission:CaseId')}
                      value={value}
                      onChange={(item) => {
                        onChange(item)
                        onCaseIDChangeEvent(index, item)
                      }}
                      onBlur={onBlur}
                      required={true}
                      disabled={isConfirmed}
                      errorMessage={value ? '' : error?.message}
                      options={caseIdOptions}
                    />
                  )}
                />
              </View>
              <View style={addLackStyles.col_4}>
                <Controller
                  control={control}
                  name={`docs.${index}.suspendType`}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020001', { field: t('submission:SuspendType') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Select
                      label={t('submission:SuspendType')}
                      value={value}
                      onChange={(item) => {
                        onChange(item)
                        onSuspendTypeChangeEvent(index, item)
                      }}
                      onBlur={onBlur}
                      required={true}
                      disabled={isConfirmed}
                      errorMessage={!!value && !!value.value ? '' : error?.message}
                      options={watch(`docs.${index}.suspendTypeOptions`) ?? []}
                    />
                  )}
                />
              </View>
              {!!watch(`docs.${index}.docType`) && (
                <View style={addLackStyles.col_4}>
                  <Text style={addLackStyles.field_title}>{t('submission:AddDocumentForRequest')}</Text>
                  <Text style={addLackStyles.field_description}>{watch(`docs.${index}.docType`)}</Text>
                </View>
              )}
            </View>
            <View style={[addLackStyles.secondLine]}>
              <View>
                <Text style={addLackStyles.field_title}>
                  {t('AddLackOfDocuments:FileAttachment')}
                  <Text style={addLackStyles.requiredText}>*</Text>
                </Text>
                <Controller
                  control={control}
                  name={`docs.${index}.attachments`}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS150004')}`
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Column>
                      <ImgUploadMutiple value={value} onChange={onChange} timeFormat={'DD/MM/YYYY HH:mm'} />
                      {error?.message && <Error message={!!value && value.length > 0 ? '' : error?.message} />}
                    </Column>
                  )}
                />
              </View>
            </View>
            {!isConfirmed && (
              <View style={[addLackStyles.secondLine]}>
                <TouchableOpacity onPress={() => removeItemEvent(index)}>
                  <View style={addLackStyles.btnContent}>
                    <assets.DeleteBin />
                    <Text style={[addLackStyles.btn_text, addLackStyles.field_title]}>{t('common:Delete')}</Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </Panel>
        ))}

        {!isConfirmed && (
          <View style={[addLackStyles.secondLine]}>
            <TouchableOpacity onPress={addNewItemEvent}>
              <View style={addLackStyles.btnContent}>
                <assets.PlusCircleIcon />
                <Text style={[addLackStyles.btn_text, addLackStyles.field_title]}>{t('common:Add')}</Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}
const addLackStyles = StyleSheet.create({
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  btnContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btn_text: {
    marginLeft: 10,
    marginTop: 4
  },
  requiredText: {
    color: 'red',
    marginLeft: 5,
    fontSize: 12
  }
})
