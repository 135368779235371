import * as React from 'react'
import {
  ModalComponent,
  PayoutPopup,
  ModalComponentRef,
  ModalAction,
  Input,
  SelectSearch,
  assets,
  DatePicker,
  ImgUploadMutiple,
  form2,
  PayoutService,
  ErrorHandling,
  SelectOption,
  PulseOpsFormat,
  GeneralService,
  GeneralData,
  Panel,
  Title,
  removeAbundantSpacesInText
} from '@pulseops/common'
import { useWindowDimensions, ScrollView, StyleSheet, Text, View } from 'react-native'
import {
  Checkbox,
  Error,
  Label,
  withMaxDate,
  withMaxStringLength,
  withMinDate,
  withMinStringLength,
  withNumberGreater
} from '@pulseops/submission/common'
import { useTranslation } from 'react-i18next'
// import { BankTransferForm } from './bank-transfer.form'
import { Controller } from 'react-hook-form'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { Bank, BankBranch, Client, OwnerInfo, specialCharacters } from './constants'
import { Button } from '@pulseops/business/core'
import _ from 'lodash'
import { TableCustom } from '../../common/table'
import moment from 'moment'
import { BankTransferConst } from './bank-transfer.const'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import i18next from 'i18next'
import * as O from 'fp-ts/lib/Option'

export namespace BankTransferForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })
  type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  type ForeignAddressBrand = {
    readonly MS020001: unique symbol
    readonly MS050223: unique symbol
  }

  type ForeignAddress = t.Branded<string | null, ForeignAddressBrand>

  export type FileList = t.Branded<File[], FileListBrand>

  const BankTransferform = t.intersection([
    t.type({
      bank: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Bank') })
      ),
      branch: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Branch') })
      ),
      accountNumber: pipe(
        withMessage(form2.string.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:AccountNumber') })
        ),
        withMaxStringLength(20)
      ),
      amount: pipe(
        withMessage(form2.number.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('requestInfo:Amount') })
        ),
        withNumberGreater(0)
      )
    }),
    t.union([
      t.type({
        payeeNotPO: t.literal(false),
        accountName: pipe(form2.string.required),
        idNumber: pipe(form2.string.required)
      }),
      t.type({
        payeeNotPO: t.literal(true),
        accountName: pipe(form2.string.required, withMaxStringLength(120)),
        // idNumber: pipe(form2.string.required, withMinStringLength(12), withMaxStringLength(24)),
        idNumber: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:IDNumber') })
          ),
          withMinStringLength(8),
          withMaxStringLength(24)
        ),
        idNumberSearch: pipe(
          withMessage(form2.string.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:IDNumber') })
          ),
          withMinStringLength(8),
          withMaxStringLength(24)
        ),
        payeeName: t.string,
        surname: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CASurname') })
        ),
        givenName: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:GivenName') })
        ),
        dob: pipe(
          withMessage(form2.date.required, () =>
            i18next.t('message:MS020001', { field: i18next.t('requestInfo:DOB') })
          ),
          withMaxDate(new Date(), 'message:MS050013', 'submission:DateOfBirth'),
          withMinDate(new Date('1900-01-01'))
        ),
        mobileNumber: pipe(
          // withMessage(form2.string.required, () =>
          //   i18next.t('message:MS020001', { field: i18next.t('submission:PhoneNumber') })
          // )
          form2.string.optional
        ),
        email: pipe(
          // withMessage(form2.string.required, () =>
          //   i18next.t('message:MS020001', { field: i18next.t('requestInfo:Email') })
          // )
          withMessage(form2.email.optional, () =>
            i18next.t('message:MS050013', { field: i18next.t('requestInfo:Email') })
          )
        ),
        relationshipWithPO: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:RelationshipWithPO') })
        ),
        occupation: pipe(form2.selectOption.optional),
        gender: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Gender') })
        ),
        salutation: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Salutation') })
        ),
        married: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:SubMarried') })
        ),
        nationality: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Nationality') })
        ),
        residenceCountry: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:ResidenceCountry') })
        ),
        city: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:CityProvince') })
        ),
        district: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:District') })
        ),
        ward: withMessage(form2.selectOption.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:Ward') })
        ),
        street: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('submission:HouseNumber') })
        ),
        nationality2: pipe(form2.selectOption.optional),
        foreignCountry: pipe(form2.selectOption.optional),
        // foreignAddress: pipe(form2.string.optional),
        foreignAddress: pipe(
          form2.string.optional,
          form2.refine(
            (l): l is ForeignAddress =>
              !!l ||
              (bankTransferFormData.payeeNotPO &&
                (['VN'].includes(bankTransferFormData?.nationality?.value ?? '') ||
                  ['VN'].includes(bankTransferFormData?.nationality2?.value ?? ''))),
            () => i18next.t('message:MS020001', { field: i18next.t('submission:ForeignAddress') }),
            'MS020001'
          ),
          form2.refine(
            (l): l is ForeignAddress => !l || !specialCharacters.some((item) => l.includes(item)),
            () => i18next.t('message:MS050223'),
            'MS050223'
          )
        ),
        relativeDocument: pipe(
          t.array(File),
          form2.refine(
            (l): l is FileList => l && l.length > 0,
            () => i18next.t('message:MS020001', { field: i18next.t('submission:RelativeDocument') }),
            'FileList'
          )
        )
      })
    ])
  ])

  export const codec = BankTransferform

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

let bankTransferFormData: BankTransferForm.Raw = {
  amount: '',
  payeeNotPO: true,
  payeeName: '',
  accountNumber: '',
  accountName: '',
  idNumber: '',
  bank: null,
  branch: null,
  nationality: null,
  nationality2: null,
  dob: undefined,
  gender: null,
  mobileNumber: '',
  email: '',
  salutation: null,
  married: null,
  occupation: null,
  relationshipWithPO: null,
  foreignAddress: '',
  foreignCountry: null,
  residenceCountry: null,
  surname: '',
  givenName: '',
  city: null,
  district: null,
  ward: null,
  street: '',
  relativeDocument: [],
  idNumberSearch: ''
}

export type DataSource = {
  [key: string]: string | number | JSX.Element
}
interface Props {
  primaryPolicy: string
  visible: boolean
  maxAmount: number
  ownerInfo: OwnerInfo
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData) => void
  isShowPayeeNotPoOption?: boolean
  isFractionFormat?: boolean
}

export const BankTransferPopup = ({
  visible,
  primaryPolicy,
  maxAmount,
  ownerInfo,
  isShowPayeeNotPoOption = false,
  onClose,
  onConfirm,
  isFractionFormat
}: Props) => {
  const { width, height } = useWindowDimensions()
  const { t, i18n } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const [banks, setBanks] = React.useState<Bank[]>([])

  const [bankBranchs, setBankBranchs] = React.useState<BankBranch[]>([])
  const [doubClients, setDoubClients] = React.useState<Client[] | null>([])
  const [searchMsg, setSearchMsg] = React.useState<string>('')
  const [copyInfo, setCopyInfo] = React.useState<boolean>(false)
  const [isIDNumberSearch, setIsIDNumberSearch] = React.useState<boolean>(false)
  const [districtOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])

  const bankTransferForm = form2.useForm(
    BankTransferForm.codec,
    {
      defaultValues: { payeeNotPO: false }
    },
    'onChange'
  )

  const formData = bankTransferForm.base.watch()
  const { payeeNotPO, amount } = formData
  bankTransferFormData = Object.assign(bankTransferFormData, {
    ...formData
  })

  const { iacRela, nationalityOptions, occupations, salution, married, genders, provinces } = pipe(
    ZIO.zipPar(
      GeneralService.getCountries,
      GeneralService.getOccupations,
      GeneralService.getIACRelationships,
      GeneralService.getProvinces
    ),
    ZIO.map(([countries, occupations, iacRela, provinces]) => {
      const nationalityOptions = countries
      const salution = BankTransferConst.salution.map((item) => ({
        value: item.value,
        label: t(item.label)
      }))
      const married = BankTransferConst.married.map((item) => ({
        value: item.value,
        label: t(item.label)
      }))
      const genders = BankTransferConst.genders.map((item) => ({
        value: item.value,
        label: t(item.label)
      }))
      return {
        iacRela: iacRela?.map((item) => {
          return {
            value: item.code,
            // label: t(`submission:${item.name}`)
            name: item.name,
            nameEn: item.nameEn
          }
        }),
        nationalityOptions: nationalityOptions.map((item: GeneralData) => {
          return {
            value: item.code,
            label: t(`submission:${item.name}`)
          }
        }),
        occupations: occupations.map((item: GeneralData) => {
          return {
            value: item.code,
            label: t(`submission:${item.name}`)
          }
        }),
        provinces: provinces.map((item) => {
          return {
            value: item.code,
            label: item.name
          }
        }),
        salution,
        married,
        genders
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    iaRela: [],
    nationalityOptions: [],
    occupations: [],
    salution: [],
    married: [],
    genders: [],
    provinces: []
  }

  React.useEffect(() => {
    bankTransferForm.base.setValue('amount', `${maxAmount}`)
  }, [maxAmount])

  React.useEffect(() => {
    bankTransferForm.base.setValue('accountName', ownerInfo.ownerName)
    bankTransferForm.base.setValue('idNumber', ownerInfo.nationalId)
  }, [ownerInfo])

  React.useEffect(() => {
    if (payeeNotPO) {
      bankTransferForm.base.setValue('idNumberSearch', '')
      bankTransferForm.base.setValue('relativeDocument', [])
    } else {
      setDoubClients(null)
      bankTransferForm.base.reset({
        amount: maxAmount.toString(),
        accountName: ownerInfo?.ownerName,
        idNumber: ownerInfo?.nationalId,
        payeeNotPO: false
      })
    }
  }, [payeeNotPO])

  React.useEffect(() => {
    getBanks()
    return () => {
      bankTransferForm.base.reset({ payeeNotPO: false })
    }
  }, [])

  const bankOptions = React.useMemo(
    () => banks.map((item): SelectOption => ({ label: item.name, value: item.code })),
    [banks]
  )

  const bankBranchOptions = React.useMemo(
    () => bankBranchs.map((item): SelectOption => ({ label: item.name, value: item.code })),
    [bankBranchs]
  )

  const getBanks = () => {
    pipe(
      PayoutService.getBanks("2"),
      ZIO.tap((banks) => {
        setBanks(banks.length ? banks : [])
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const getBankBranch = (code: string) =>
    pipe(
      ZIO.effect(() => {
        const codePulse = pipe(banks.find(x => x.code === code), O.fromNullable, O.map((item) => item.codePulse ?? ''), O.getOrElse(() => ''))
        return {
          code: code, codePulse: codePulse
        }
      }),
      ZIO.flatMap((bankCodeInfo) => PayoutService.bankBranch(bankCodeInfo.code, bankCodeInfo.codePulse)),
      ZIO.tap((branchs) => {
        setBankBranchs(branchs.length ? branchs : [])
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const getDistricts = (provinceCode: string) => {
    bankTransferForm.base.setValue('district', null)
    bankTransferForm.base.setValue('ward', null)
    return pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.code,
            label: i18n.language === 'vi' ? district.name : district.name
          }
        })
        setDistrictOptions(districtOptions)
        return districtOptions
      }),
      ErrorHandling.run()
    )
  }

  const getWards = (provinceCode: string, districtCode: string) => {
    bankTransferForm.base.setValue('ward', null)
    return pipe(
      GeneralService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.code,
            label: i18n.language === 'vi' ? ward.name : ward.name
          }
        })
        setWardOptions(wardOptions)
        return wardOptions
      }),
      ErrorHandling.run()
    )
  }

  const isForeignAddressRequired = () => {
    return (
      !['VN'].includes(bankTransferForm.base.watch('nationality.value') ?? '') &&
      !['VN'].includes(bankTransferForm.base.watch('nationality2.value') ?? '')
    )
  }

  const onChangeNationality = (nationality: SelectOption | null, nationality2: SelectOption | null) => {
    if (nationality?.value === 'VN' || nationality2?.value === 'VN') {
      bankTransferForm.base.clearErrors('foreignAddress')
    }
  }

  const getClientsInfo = () => {
    if (_.get(formData, 'idNumberSearch')) {
      pipe(
        PayoutService.getClients(_.get(formData, 'idNumberSearch')),
        ZIO.tap((clients) => {
          const checkDouble = clients.reduce((unique: Client[], o) => {
            if (
              !unique.some(
                (obj: Client) => obj?.sureName === o.sureName && obj?.gender === o.gender && obj?.dob === o.dob
              )
            ) {
              unique.push({
                ...o,
                sureName: o.sureName,
                giveName: o.giveName,
                fullName: o.sureName + ' ' + o.giveName,
                address01: o.address01 ?? '',
                address02: o.address02 ?? '',
                address03: o.address03 ?? '',
                address04: o.address04 ?? '',
                idDate: o.idDate ?? '',
                nationality2: o.nationality2 ?? '',
                email: o.email.trim(),
                foreignStreet: o.foreignStreet ?? '',
                foreignCountry: o.foreignCountry ?? ''
              })
            }
            return unique
          }, [])
          setIsIDNumberSearch(true)
          setDoubClients(checkDouble)
          if (_.isEmpty(clients)) {
            setCopyInfo(false)
            bankTransferForm.base.setValue('amount', maxAmount.toString())
            bankTransferForm.base.setValue('payeeName', '')
            bankTransferForm.base.setValue('surname', '')
            bankTransferForm.base.setValue('givenName', '')
            bankTransferForm.base.setValue('idNumber', _.get(formData, 'idNumberSearch') ?? '')
            bankTransferForm.base.setValue('relativeDocument', [])
            setSearchMsg(t('message:MS030033'))
          } else {
            setSearchMsg('')
          }
          return ZIO.unit
        }),
        ErrorHandling.run()
      )
    }
  }

  const onPressClose = () => {
    onClose()
  }

  const getItemByList = (list: SelectOption[] | null, oldItem: string) => {
    const newItem = pipe(
      list?.find((x) => oldItem.trimEnd().toLowerCase().includes(x.label.toLowerCase())),
      O.some,
      O.toUndefined
      // O.getOrElse(() => ({ value: '', label: '' }))
    )
    return newItem
  }

  const getAddressInfo = (proviceVal: string, districtVal: string, wardVal: string) => {
    return pipe(
      ZIO.effect(() => {
        const cityItem = getItemByList(provinces, proviceVal)
        return cityItem
      }),
      ZIO.flatMap((cityItem) => {
        return !!cityItem
          ? pipe(
              ZIO.fromPromise(() => getDistricts(cityItem?.value ?? '')),
              ZIO.map((districtList) => {
                const districtItem = getItemByList(districtList, districtVal)
                return {
                  cityItem,
                  districtItem
                }
              })
            )
          : ZIO.succeed(cityItem)
      }),
      ZIO.flatMap((dataItem) =>
        !!dataItem
          ? pipe(
              ZIO.fromPromise(() => getWards(dataItem.cityItem.value, dataItem.districtItem?.value ?? '')),
              ZIO.map((wardList) => {
                const wardItem = getItemByList(wardList, wardVal)
                return {
                  cityItem: dataItem.cityItem,
                  districtItem: dataItem.districtItem,
                  wardItem
                }
              })
            )
          : ZIO.succeed(dataItem)
      ),
      ZIO.unsafeRun({})
    )
  }

  const getForeignCountry = (foreignCode: string) => {
    return nationalityOptions.find((item) => item.value === foreignCode)
  }

  const handleCopyInfo = async (index: number, data: DataSource) => {
    const dataItem = await getAddressInfo(
      data?.address04 as string,
      data?.address03 as string,
      data?.address02 as string
    )
    bankTransferForm.base.reset({
      ...formData,
      ...{
        // payeeName: data?.sureName.toString(),
        surname: data?.sureName.toString(),
        givenName: data?.giveName.toString(),
        idNumber: data?.securityNo.toString(),
        payeeName: data?.sureName.toString(),
        married: married.find((_) => _.value === data.married) || null,
        salutation: salution.find((_) => _.value === data.salutation) || null,
        occupation: occupations.find((_) => _.value === data.occupationCode) || null,
        nationality: nationalityOptions.find((_) => _.value === data.nationality) || null,
        nationality2: nationalityOptions.find((_) => _.value === data.nationality2) || null,
        gender: genders.find((_) => _.value === data.gender) || null,
        residenceCountry: nationalityOptions.find((_) => _.value === data.countryCode) || null,
        dob: PulseOpsFormat.getStringToDate(data.dob.toString(), 'YYYYMMDD'),
        // address: `${data?.address01} ${data?.address02} ${data?.address03} ${data?.address04}`,
        address: '',
        mobileNumber: data?.mobileNumber?.toString().trim(),
        email: data?.email?.toString(),
        city: dataItem?.cityItem,
        district: dataItem?.districtItem,
        ward: dataItem?.wardItem,
        street: `${data?.address01 ?? ''}`,
        foreignAddress: data?.foreignStreet.toString(),
        foreignCountry: getForeignCountry(data?.foreignCountry.toString())
      }
    })
    setCopyInfo(true)
    setDoubClients([])
  }

  const onPressConfirm = bankTransferForm.handleSubmit((data) => {
    const amount = typeof data.amount === 'string' ? Number(data.amount) : data.amount
    const payeeName = payeeNotPO
      ? removeAbundantSpacesInText(_.get(formData, 'surname')) +
        ' ' +
        removeAbundantSpacesInText(_.get(formData, 'givenName'))
      : _.get(formData, 'accountName') ?? ''
    const relativeDocument = payeeNotPO
      ? _.get(formData, 'relativeDocument').map((item) => ({
          ...item,
          uploadedDate: item.uploadedDate as Date,
          file: item.file as File
        }))
      : undefined
    const payout: PayoutPopup.PayoutData = {
      method: PayoutPopup.PayoutMethods.BANKTRANSFER,
      amount: amount,
      policyNum: primaryPolicy,
      // poName: data.accountName,
      poName: payeeName,
      bankCode: data.bank.value,
      bankName: data.bank.label,
      bankAccountNum: data.accountNumber,
      // bankAccountName: data.accountName,
      bankAccountName: payeeName,
      bankBranchCode: data.branch.value,
      bankBranchName: data.branch.label,
      nationalId: data.idNumber,
      payee: payeeNotPO
        ? {
            paymentMethod: PayoutPopup.PayoutMethods.BANKTRANSFER,
            isPayeeNotPO: true,
            dob: moment(_.get(formData, 'dob')).format('YYYYMMDD') ?? '',
            gender: _.get(formData, 'gender.value') ?? '',
            phone: _.get(formData, 'mobileNumber') ?? '',
            email: _.get(formData, 'email') ?? '',
            salutation: _.get(formData, 'salutation.value') ?? '',
            married: _.get(formData, 'married.value') ?? '',
            occupation: _.get(formData, 'occupation.value') ?? '',
            relationshipWithPO: _.get(formData, 'relationshipWithPO.value') ?? '',
            nationality: _.get(formData, 'nationality.value') ?? '',
            address: _.get(formData, 'address') ?? '',
            nationality2: _.get(formData, 'nationality2.value') ?? '',
            foreignAddress: removeAbundantSpacesInText(_.get(formData, 'foreignAddress') ?? ''),
            foreignCountry: _.get(formData, 'foreignCountry.value') ?? '',
            isNewClient: !copyInfo,
            residenceCountry: _.get(formData, 'residenceCountry.value') ?? '',
            payeeName: payeeName,
            issuedDate: undefined,
            issuedBy: undefined,
            issueCode: undefined,
            name: payeeName,
            idNumber: _.get(formData, 'idNumber') ?? '',
            dod: '',
            surName: removeAbundantSpacesInText(_.get(formData, 'surname') ?? ''),
            giveName: removeAbundantSpacesInText(_.get(formData, 'givenName') ?? ''),
            city: _.get(formData, 'city.label') ?? '',
            district: _.get(formData, 'district.label') ?? '',
            ward: _.get(formData, 'ward.label') ?? '',
            street: removeAbundantSpacesInText(_.get(formData, 'street') ?? ''),
            relativeDocument: relativeDocument
          }
        : {
            paymentMethod: PayoutPopup.PayoutMethods.BANKTRANSFER,
            isPayeeNotPO: false,
            isNewClient: false,
            payeeName: payeeName,
            idNumber: _.get(formData, 'idNumber') ?? '',
            issuedDate: undefined,
            issuedBy: undefined,
            issueCode: undefined,
            dob: undefined,
            gender: undefined,
            phone: undefined,
            email: undefined,
            salutation: undefined,
            married: undefined,
            occupation: undefined,
            relationshipWithPO: undefined,
            nationality: undefined,
            address: undefined,
            nationality2: undefined,
            foreignAddress: undefined,
            foreignCountry: undefined,
            residenceCountry: undefined,
            name: undefined,
            dod: undefined,
            surName: undefined,
            giveName: undefined,
            city: undefined,
            district: undefined,
            ward: undefined,
            street: undefined,
            relativeDocument: relativeDocument
          },
      officeBankCode: undefined,
      officeType: undefined
    }

    onConfirm(payout)
  })

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled: !bankTransferForm.base.formState.isValid || Number(amount || 0) > maxAmount,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]

  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:BankTransfer')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <View
          style={{
            flex: 1,
            backgroundColor: '#FAFAFA',
            borderWidth: 1,
            borderColor: '#D3DCE6',
            marginTop: 20,
            paddingHorizontal: 30,
            paddingVertical: 22
          }}
        >
          {/* {isShowPayeeNotPoOption && (
            <Controller
              control={bankTransferForm.base.control}
              name="payeeNotPO"
              render={({ field }) => (
                <Checkbox {...field} title={t('submission:PayeeIsNotPO')} fontWeight="bold" enable={false} />
              )}
            />
          )} */}
          <Controller
            control={bankTransferForm.base.control}
            name="payeeNotPO"
            render={({ field }) => <Checkbox {...field} title={t('submission:PayeeIsNotPO')} fontWeight="bold" />}
          />

          {payeeNotPO ? (
            <>
              <View style={{ flexDirection: 'row', marginTop: 22, width: '50%' }}>
                <Controller
                  control={bankTransferForm.base.control}
                  name="idNumberSearch"
                  render={({ field, fieldState: { error } }) => (
                    <View>
                      <Input
                        {...field}
                        title={t('submission:IDNumber')}
                        containerStyle={{ flex: 1 }}
                        maxLength={24}
                        disabled={!payeeNotPO}
                        errorMessage={payeeNotPO ? error?.message : ''}
                        inputType={'number'}
                        required
                      />
                      {!error?.message && !!searchMsg && <Text style={styles.searchMessage}>{searchMsg}</Text>}
                    </View>
                  )}
                />
                <Button
                  title={t('common:Search')}
                  onPress={getClientsInfo}
                  disabled={_.get(formData, 'idNumberSearch.length') < 8}
                  style={{ marginTop: 20 }}
                />
              </View>
              {!_.isEmpty(doubClients) && (
                <View style={{ flexDirection: 'row', marginTop: 22 }}>
                  <TableCustom
                    columns={[
                      {
                        key: '0',
                        title: t('submission:FullName'),
                        name: 'fullName'
                      },
                      {
                        key: '1',
                        title: t('submission:DOB'),
                        name: 'dob',
                        render: ({ value }) => <Text>{moment(value).format('DD/MM/YYYY')}</Text>
                      },
                      {
                        key: '2',
                        title: t('submission:IDNumber'),
                        name: 'securityNo'
                      },
                      {
                        key: '3',
                        title: t('submission:Gender'),
                        name: 'gender',
                        render: ({ value }) => <Text>{t(`submission:${value === 'F' ? 'Female' : 'Male'}`)}</Text>
                      }
                    ]}
                    dataSource={doubClients as any[]}
                    onRowSelected={handleCopyInfo}
                  />
                </View>
              )}

              {isIDNumberSearch && !!doubClients && doubClients.length <= 0 && (
                <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA', marginTop: 22 }}>
                  <Title title={t('submission:PayeeInformation')} />
                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="surname"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            title={t('submission:CASurname')}
                            containerStyle={{ flex: 1, marginEnd: 30 }}
                            disabled={!payeeNotPO || copyInfo}
                            maxLength={120}
                            required
                            errorMessage={payeeNotPO ? error?.message : ''}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="givenName"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            title={t('submission:GivenName')}
                            containerStyle={{ flex: 1 }}
                            disabled={!payeeNotPO || copyInfo}
                            maxLength={120}
                            required
                            errorMessage={payeeNotPO ? error?.message : ''}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="idNumber"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            title={t('submission:IDNumber')}
                            containerStyle={{ flex: 1, marginEnd: 30 }}
                            maxLength={24}
                            errorMessage={payeeNotPO ? error?.message : ''}
                            required
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="bank"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            {...field}
                            required={true}
                            label={t('submission:Bank')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={bankOptions}
                            errorMessage={error?.message}
                            onChange={(option) => {
                              setBankBranchs([])
                              bankTransferForm.base.setValue('branch', null)
                              if (option) {
                                getBankBranch(option.value)
                              }
                              field.onChange(option)
                              bankTransferForm.base.setValue('accountNumber', '')
                            }}
                          />
                        )}
                      />
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="branch"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('branch')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Branch')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={bankBranchOptions}
                            disabled={_.isUndefined(formData.bank)}
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="accountNumber"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            required={true}
                            title={t('submission:AccountNumber')}
                            containerStyle={{ flex: 1, marginEnd: 30 }}
                            inputType={bankTransferForm.base.getValues('bank.value') !== 'SEAB' ? 'number' : 'text'}
                            maxLength={20}
                            value={field.value || ''}
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="amount"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            required={true}
                            title={t('requestInfo:Amount')}
                            containerStyle={{ flex: 1 }}
                            inputType={'money'}
                            value={field.value || ''}
                            isFractionFormat={isFractionFormat}
                            errorMessage={Number(field.value || 0) > maxAmount ? t('message:MS050159') : error?.message}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="dob"
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            {...field}
                            required={true}
                            label={t('submission:DateOfBirth')}
                            maxDate={new Date()}
                            minDateMessage={''}
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="mobileNumber"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            title={t('submission:PhoneNumber')}
                            containerStyle={{ flex: 1 }}
                            disabled={!payeeNotPO}
                            errorMessage={payeeNotPO ? error?.message : ''}
                            value={field.value ?? ''}
                            maxLength={10}
                            inputType={'number'}
                            // required
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="email"
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            title={t('submission:Email')}
                            containerStyle={{ flex: 1, marginEnd: 30 }}
                            disabled={!payeeNotPO}
                            errorMessage={payeeNotPO ? error?.message : ''}
                            value={field.value ?? ''}
                            // required
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="relationshipWithPO"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('relationshipWithPO')?.value}
                            {...field}
                            required={true}
                            label={t('submission:RelationshipWithPO')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={iacRela?.map((x) => ({
                              value: x.value,
                              label: i18n.language === 'en' ? x.nameEn : x.name
                            }))}
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="occupation"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('occupation')?.value}
                            {...field}
                            // required={true}
                            label={t('submission:Occupation')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={occupations}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="gender"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('gender')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Gender')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={genders}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="salutation"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('salutation')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Salutation')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={salution}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="married"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('married')?.value}
                            {...field}
                            required={true}
                            label={t('submission:SubMarried')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={married}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="nationality"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('nationality')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Nationality')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            onChange={(val) => {
                              field.onChange(val)
                              onChangeNationality(val, bankTransferForm.base.getValues('nationality2'))
                            }}
                            options={nationalityOptions}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="residenceCountry"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('residenceCountry')?.value}
                            {...field}
                            required={true}
                            label={t('submission:ResidenceCountry')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="city"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('city')?.value}
                            {...field}
                            onChange={(provinceItem) => {
                              field.onChange(provinceItem)
                              getDistricts(provinceItem?.value ?? '')
                            }}
                            required={true}
                            label={t('submission:CityProvince')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={provinces}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            // disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="district"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('district')?.value}
                            {...field}
                            onChange={(districtItem) => {
                              field.onChange(districtItem)
                              getWards(formData?.city?.value ?? '', districtItem?.value ?? '')
                            }}
                            required={true}
                            label={t('submission:District')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={districtOptions}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            // disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="ward"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('ward')?.value}
                            {...field}
                            required={true}
                            label={t('submission:Ward')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={wardOptions}
                            errorMessage={payeeNotPO && !_.isUndefined(field.value) ? error?.message : ''}
                            // disabled={!payeeNotPO || copyInfo}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={bankTransferForm.base.control}
                      name="street"
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('submission:HouseNumber')}
                          containerStyle={{ flex: 1 }}
                          disabled={!payeeNotPO || copyInfo}
                          maxLength={50}
                          required
                          errorMessage={payeeNotPO ? error?.message : ''}
                        />
                      )}
                    />
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="nationality2"
                        render={({ field, fieldState: { error } }) => (
                          <View style={{ flex: 1 }}>
                            <SelectSearch
                              key={bankTransferForm.base.getValues('nationality2')?.value}
                              {...field}
                              label={t('submission:Nationality2')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              options={nationalityOptions}
                              onChange={(val) => {
                                field.onChange(val)
                                onChangeNationality(bankTransferForm.base.getValues('nationality'), val)
                              }}
                              errorMessage={error?.message}
                              disabled={!payeeNotPO}
                            />
                          </View>
                        )}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Controller
                        control={bankTransferForm.base.control}
                        name="foreignCountry"
                        render={({ field, fieldState: { error } }) => (
                          <SelectSearch
                            key={bankTransferForm.base.getValues('foreignCountry')?.value}
                            {...field}
                            label={t('submission:ForeignCountry')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            options={nationalityOptions}
                            errorMessage={error?.message}
                            disabled={!payeeNotPO}
                          />
                        )}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: 'row', marginTop: 22 }}>
                    <Controller
                      control={bankTransferForm.base.control}
                      name="foreignAddress"
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <Input
                          value={value || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('submission:ForeignAddress')}
                          containerStyle={{ flex: 1, marginEnd: 30 }}
                          disabled={!payeeNotPO}
                          required={isForeignAddressRequired()}
                          errorMessage={payeeNotPO ? error?.message : ''}
                          maxLength={30}
                        />
                      )}
                    />
                  </View>

                  <View style={{ marginTop: 30 }}>
                    <Label title={t('submission:RelativeDocument')} required fontWeight="bold" />
                    <Controller
                      control={bankTransferForm.base.control}
                      name="relativeDocument"
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <ImgUploadMutiple {...field} value={field.value as any[]} />
                          {error?.message && <Error message={error.message} />}
                        </>
                      )}
                    />
                  </View>
                </Panel>
              )}
            </>
          ) : (
            <>
              <View style={{ flexDirection: 'row', marginTop: 22 }}>
                <Controller
                  control={bankTransferForm.base.control}
                  name="accountName"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      title={t('submission:AccountName')}
                      containerStyle={{ flex: 1, marginEnd: 30 }}
                      disabled={!payeeNotPO}
                      maxLength={120}
                      errorMessage={payeeNotPO ? error?.message : ''}
                    />
                  )}
                />

                <Controller
                  control={bankTransferForm.base.control}
                  name="idNumber"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      title={t('submission:IDNumber')}
                      containerStyle={{ flex: 1 }}
                      maxLength={24}
                      disabled={!payeeNotPO}
                      errorMessage={payeeNotPO ? error?.message : ''}
                    />
                  )}
                />
              </View>

              <View style={{ flexDirection: 'row', marginTop: 30 }}>
                <Controller
                  control={bankTransferForm.base.control}
                  name="bank"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1, marginEnd: 30 }}>
                      <SelectSearch
                        {...field}
                        required={true}
                        label={t('submission:Bank')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        options={bankOptions}
                        errorMessage={error?.message}
                        onChange={(option) => {
                          setBankBranchs([])
                          bankTransferForm.base.setValue('branch', null)
                          if (option) {
                            getBankBranch(option.value)
                          }
                          field.onChange(option)
                          bankTransferForm.base.setValue('accountNumber', '')
                        }}
                      />
                    </View>
                  )}
                />

                <Controller
                  control={bankTransferForm.base.control}
                  name="branch"
                  render={({ field, fieldState: { error } }) => (
                    <View style={{ flex: 1 }}>
                      <SelectSearch
                        key={bankTransferForm.base.getValues('branch')?.value}
                        {...field}
                        required={true}
                        label={t('submission:Branch')}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        options={bankBranchOptions}
                        errorMessage={error?.message}
                      />
                    </View>
                  )}
                />
              </View>

              <View style={{ flexDirection: 'row', marginTop: 30 }}>
                <Controller
                  control={bankTransferForm.base.control}
                  name="accountNumber"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      required={true}
                      title={t('submission:AccountNumber')}
                      containerStyle={{ flex: 1, marginEnd: 30 }}
                      inputType={bankTransferForm.base.getValues('bank.value') !== 'SEAB' ? 'number' : 'text'}
                      maxLength={20}
                      value={field.value || ''}
                      errorMessage={error?.message}
                    />
                  )}
                />

                <Controller
                  control={bankTransferForm.base.control}
                  name="amount"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      required={true}
                      title={t('requestInfo:Amount')}
                      containerStyle={{ flex: 1 }}
                      inputType={'money'}
                      value={field.value || ''}
                      isFractionFormat={isFractionFormat}
                      errorMessage={Number(field.value || 0) > maxAmount ? t('message:MS050159') : error?.message}
                    />
                  )}
                />
              </View>
            </>
          )}
        </View>
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
