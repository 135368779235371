import React from 'react'
// import { SC } from './styles'
import _ from 'lodash'
import { SelectOption, GeneralService, ErrorHandling, ControlProps, assets } from '@pulseops/common'
import { identity, pipe } from 'fp-ts/lib/function'
import { UIO, ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, Text, FlatList, TextStyle, StyleProp, ViewStyle, TouchableOpacity } from 'react-native'
import { useLoading } from '@mxt/zio-react'
import * as R from 'rxjs'
import * as Rx from 'rxjs/operators'
import { CircularProgress, createTheme, makeStyles, Popper, TextField, ThemeProvider } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import styled from 'styled-components/native'

export const SC = {
  Container: styled(View)`
    width: 100%;
    align-self: center;
  `,
  CenterSelf: styled(View)`
    align-self: center;
  `,
  FlexStartSelf: styled(View)`
    align-self: flex-start;
  `,
  FlexEndSelf: styled(View)`
    align-self: flex-end;
  `,
  PanelContainer: styled(View)`
    border: 1px solid #d3dce6;
    border-radius: 8px;
    background-color: #fafafa;
    padding: 16px;
    :hover {
      background-color: red;
    }
  `,
  Row: styled(View).attrs<{ alignItems?: string }>((p) => ({
    style: {
      alignItems: p.alignItems
    }
  }))<{ alignItems?: string }>`
    flex-direction: row;
    //justify-content: space-beetween;
    //flex-wrap: wrap;
  `,
  Input: styled(View).attrs<{ flex?: number }>((p) => ({
    style: {
      flex: p.flex
    }
  }))``,
  SessionText: styled(Text)<{ color?: string }>`
    font-weight: bold;
    color: ${(p) => p.color ?? '#000'};
  `,
  TitleText: styled(Text)<{ fontSize?: number }>`
    font-weight: bold;
    color: #70777e;
    font-size: ${(p) => (p.fontSize ?? 15) + 'px'};
  `,
  NormalText: styled(Text)<{ color?: string }>`
    color: ${(p) => p.color};
  `,
  Divider: styled(View)<{ height?: number; width?: number; spacer?: boolean }>`
    width: ${(p) => (p.width ?? 10) + 'px'};
    height: ${(p) => (p.height ?? 10) + 'px'};
  `,
  Colum: styled(View)<{ flex?: number }>`
    flex: ${(p) => p.flex || 1};
  `,
  ErrorText: styled(Text)<{ fontSize?: number }>`
    color: red;
    font-size: ${(p) => p.fontSize && p.fontSize + 'px'};
  `,
  InfoText: styled(Text)<{ color?: string }>`
    color: ${(props) => props.color || 'red'};
    padding: 20px 0px 20px 0px;
  `,
  InputContainer: styled(View)<{ flex?: number; maxWidth?: number; minWidth?: number }>`
    flex: ${(p) => p.flex};
    max-width: ${(p) => (p.maxWidth || 250) + 'px'};
    min-width: ${(p) => (p.minWidth ? p.minWidth + 'px' : null)};
  `,
  Padding: styled(View)<{
    top?: number
    bottom?: number
    left?: number
    right?: number
    vertical?: number
    horizontal?: number
  }>`
    padding-top: ${(p) => p.top || p.vertical || 0};
    padding-bottom: ${(p) => p.bottom || p.vertical || 0};
    padding-right: ${(p) => p.right || p.horizontal || 0};
    padding-left: ${(p) => p.left || p.horizontal || 0};
  `,
  BoldText: styled(Text)<{ color?: string }>`
    font-weight: bold;
    color: ${(p) => p.color || 'grey'};
    font-size: 15px;
  `,
  ImageThumb: styled.Image`
    width: 50px;
    height: 50px;
  `,
  ItalicText: styled.Text`
    font-style: italic;
    color: grey;
    font-size: small;
  `,
  DashBottomContainer: styled(View)`
    border-style: dashed;
    border-bottom-width: 1px;
    padding: 5px 0px 5px 0px;
  `,
  ContainerPercent: styled(View).attrs<{ width?: number; height?: number; flex?: number }>((props) => ({
    style: {
      width: props.width && props.width + '%'
    }
  }))<{ width?: number; height?: number; flex?: number }>`
    width: ${(p) => (p.width ? p.width + '%' : null)};
    height: ${(p) => (p.height ? p.height + '%' : null)};
    flex: ${(p) => p.flex};
    justify-content: flex-start;
  `,
  ButtonContainer: styled(TouchableOpacity)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  RowWrap: styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
  `,
  RowNowrap: styled(View)`
    flex-direction: row;
    justify-content: space-beetween;
    width: 100%;
  `,
  Spacer: styled(View)`
    flex: 1;
  `,
  ThinText: styled(Text)`
    font-weight: 300;
  `,
  ExpandView: styled(View)`
    flex: 1;
  `
}

type Props = {
  // headerBuilder: () => JSX.Element
  //itemBuilder: (index: number) => JSX.Element | null
  // length: number,
  value: Relationship[]
  onChange: (value: Relationship[]) => void
  disable?: boolean
  relationshipOptions?: SelectOption[]
}

type Relationship = {
  clientId: string | null
  clientName: string | null
  relationship: SelectOption | null
  role: string | null
  secuityNo: string | null
  dob: string | null
  gender: string | null
  poName: string | null
  //sameClient: boolean
}
// const useStyles = makeStyles({
//   container: {
//     maxHeight: 300
//   },
//   tableHeader: {
//     backgroundColor: '#F4F4F4'
//   }
// })
export const DuplicateTable: React.FC<Props> = ({ value = [], onChange, disable = false, relationshipOptions }) => {
  // const classes = useStyles()
  const { t, i18n } = useTranslation()
  const relationOptions =
    relationshipOptions ??
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) => {
        return r
          .filter((r) => r.changeClientInfo === 'Y')
          .map((r) => ({
            label: i18n.language === 'vi' ? r.nameVn : r.nameEn,
            value: r.code
          }))
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    )
  return (
    <SC.Container>
      {/* <TableContainer component={Paper} className={classes.container}>
        <Table stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>{t('submission:ClientNumber')}</TableCell>
              <TableCell>{t('submission:ClientName')}</TableCell>
              <TableCell>{t('submission:RelationshipWithPO')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((v, index) => {
              return (
                <TableRow key={`row____${v.clientId}`}>
                  <TableCell style={{ width: '20%' }}>{v?.clientId ?? ''}</TableCell>
                  <TableCell style={{ width: '50%' }}>{v?.clientName ?? ''}</TableCell>
                  <TableCell style={{ width: '30%' }}>
                    <View style={{ borderWidth: 1, borderColor: '#e5eaef', borderRadius: 4 }}>
                      <SC.Padding horizontal={10}>
                        <Select
                          options={relationOptions ?? []}
                          required
                          onChange={(selectValue) => {
                            let temp = value
                            temp[index] = { ...value[index], relationship: selectValue }
                            onChange(temp)
                          }}
                          value={v.relationship}
                          //  errorMessage={
                          //    _.get(errors, `email.relationships.${index}`)?.message ||
                          //    _.get(errors, `email.relationships.${index}.role`)?.message
                          //  }
                          showUnderline={false}
                          disabled={disable}
                          placeholder={t('common:Select')}
                        />
                      </SC.Padding>
                    </View>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
      <View style={{ borderWidth: 1, borderColor: '#D3DCE6', borderRadius: 8, overflow: 'hidden' }}>
        {/* Header */}
        <View style={styles.header}>
          <Text style={[styles.headerText, { flex: 2 }]}>{t('submission:ClientNumber')}</Text>
          <Text style={[styles.headerText, { flex: 5 }]}>{t('submission:ClientName')}</Text>
          <Text style={[styles.headerText, { flex: 3 }]}>{t('submission:RelationshipWithPO')}</Text>
        </View>
        <View style={styles.body}>
          <FlatList
            data={value}
            keyExtractor={(item, index) => `_list_table_${item.clientId}`}
            extraData={value}
            renderItem={({ item, index }) => {
              return (
                <View style={styles.row}>
                  <View style={[styles.content, { flex: 2 }]}>
                    <Text style={styles.text}>{item.clientId ?? ''}</Text>
                  </View>
                  <View style={[styles.content, { flex: 5 }]}>
                    <Text style={styles.text}>{item.clientName ?? ''}</Text>
                  </View>
                  <View style={{ flex: 3, borderWidth: 1, borderColor: '#e5eaef', borderRadius: 4 }}>
                    <SC.Padding horizontal={10}>
                      <CustomSelectSearch
                        value={item.relationship}
                        disableUnderline={true}
                        options={relationOptions ?? []}
                        onChange={(selectValue) => {
                          let temp = value
                          temp[index] = { ...value[index], relationship: selectValue }
                          onChange(temp)
                        }}
                        placeholder={t('common:Select')}
                        maxLength={100}
                        disabled={disable}
                      />
                    </SC.Padding>
                  </View>
                </View>
              )
            }}
          />
        </View>
      </View>
    </SC.Container>
  )
}

const theme = createTheme({
  zIndex: {
    modal: 9999
  }
})

const useStyles = makeStyles({
  root: {
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'none'
    }
  },
  textField: {
    borderColor: '#D3DCE6'
  }
})

type SelectProps = {
  //label?: string
  options?: SelectOption[]
  searchOptions?: (inputValue: string) => UIO<SelectOption[]>
  disabled?: boolean
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  popupIcon?: JSX.Element
  showPopupIcon?: boolean
  required?: boolean
  readOnly?: boolean
  labelStyle?: TextStyle
  placeholder?: string
  disableUnderline?: boolean
  prefixIcon?: JSX.Element
  inputStyle?: StyleProp<ViewStyle>
  buildLabel?: (selectOption: SelectOption) => string
  hideLabel?: boolean
  maxLength?: number
}

type SearchProps = ControlProps<SelectOption | null> & SelectProps

type MultipleProps = ControlProps<SelectOption[]> &
  SelectProps & {
    multiple: true
  }

export function CustomSelectSearch(props: MultipleProps): JSX.Element
export function CustomSelectSearch(props: SearchProps): JSX.Element
export function CustomSelectSearch(_props: any): JSX.Element {
  const props: ControlProps<SelectOption | SelectOption[] | null> &
    SelectProps & {
      multiple?: boolean
    } = _props
  const [options, setOptions] = React.useState<SelectOption[]>([])
  // const [inputValue, setInputValue] = React.useState<string>('')
  // const [selected, setSelected] = React.useState<SelectOption | SelectOption[] | null>(null)
  const [loading, bindLoading] = useLoading(false)
  const { t } = useTranslation()

  const inputChange = new R.Subject<string>()

  React.useEffect(() => {
    // Update the document title using the browser API
    inputChange.pipe(Rx.debounceTime(1000)).subscribe((res) => {
      pipe(
        props.searchOptions !== undefined ? props.searchOptions(res) : ZIO.succeed(new Array<SelectOption>()),
        ZIO.tap((options) =>
          ZIO.effectTotal(() => {
            setOptions(options)
          })
        ),
        bindLoading,
        ZIO.run({})
      )
    })
  })

  return (
    <ThemeProvider theme={theme} key={JSON.stringify(props.value) || 'nullValue'}>
      <Autocomplete
        className={useStyles().root}
        multiple={props.multiple}
        options={props.searchOptions ? options : props.options || []}
        popupIcon={props.showPopupIcon === false ? null : props?.popupIcon || <assets.ArrowDownDropdownIcon />}
        style={{}}
        PopperComponent={(props) => {
          return <Popper {...props} style={{ ...(props.style || {}), zIndex: 9999 }} />
        }}
        renderInput={(params) => (
          <View>
            <View style={[{ flexDirection: 'row' }, props.inputStyle]}>
              {props.prefixIcon && (
                <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>{props.prefixIcon}</View>
              )}
              <TextField
                {...params}
                placeholder={props.placeholder}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  readOnly: props.readOnly,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  disableUnderline: props.disableUnderline
                }}
                inputProps={{
                  ...params.inputProps,
                  maxLength: props.maxLength
                }}
                InputLabelProps={{ shrink: props.disableUnderline }}
                fullWidth
              />
            </View>
          </View>
        )}
        getOptionLabel={(o: SelectOption) => (props.buildLabel ? props.buildLabel(o) : o.label)}
        filterOptions={props.searchOptions ? identity : undefined}
        // value={props.value || selected || null}
        value={props.value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
          setOptions(newValue ? [...(Array.isArray(newValue) ? newValue : [newValue]), ...options] : options)
          // setSelected(newValue)
          if (props.onChange) {
            props.onChange(newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          inputChange.next(newInputValue)
        }}
        //inputValue={inputValue}
        loading={loading}
        onBlur={props.onBlur}
        noOptionsText={t('common:NoOptions')}
      />
      {props.errorMessage && <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text>}
    </ThemeProvider>
  )
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#E5EAEF',
    paddingHorizontal: 24,
    paddingVertical: 24,
    flexDirection: 'row'
  },
  headerText: {
    fontWeight: 'bold'
  },
  body: {
    maxHeight: 300,
    width: '100%'
  },
  row: {
    //backgroundColor: '#E5EAEF',
    paddingHorizontal: 24,
    paddingVertical: 8,
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderColor: '#EEEEEE'
  },
  content: {
    justifyContent: 'center'
  },
  text: {
    fontWeight: '300',
    justifyContent: 'center'
  },
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  }
})
