import * as React from 'react'
import * as core from '@material-ui/core'
import { ControlProps, SelectOption } from '@pulseops/common'
import { FormControl, TextRequired } from './styled'
import { InputBase, InputLabel } from './input'
import { assets } from '../assets'
import clsx from 'clsx'

export type Props = ControlProps<string | null> & {
  label?: string
  required?: boolean
  options?: SelectOption[]
  disabled?: boolean
  defaultValue?: string
  minWidth?: string
}

export const Select = (props: Props) => {
  const sc = useStyles()

  return (
    <FormControl error={!!props.errorMessage} style={{ minWidth: props.minWidth }}>
      <InputLabel>
        {props.label}
        {props.required && <TextRequired>*</TextRequired>}
      </InputLabel>
      <core.Select
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        input={<InputBase />}
        value={props.value}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value as string)
        }}
        onBlur={props.onBlur}
        IconComponent={({ className, classes, ...rest }) => (
          <assets.IconArrowDownGray {...rest} className={clsx(className, sc.select)} />
        )}
        displayEmpty
      >
        <core.MenuItem value="" style={{ display: 'none' }}>
          Chọn
        </core.MenuItem>
        {(props.options || []).map((o) => (
          <core.MenuItem key={o.value} value={o.value}>
            {o.label}
          </core.MenuItem>
        ))}
      </core.Select>
      {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
    </FormControl>
  )
}

Select.defaultProps = {
  minWidth: '50%'
}

const useStyles = core.makeStyles((theme) => ({
  select: {
    position: 'absolute',
    right: 0,
    top: 'auto'
  }
}))
