import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View } from 'react-native'
import { assets } from '@pulseops/common'
import { infoGen, styles } from '../../../common'
import { BOCompanyBenInfo } from '../../../services'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export type BOGeneralInfoPolicyPopupProps = {
  onClose: (value: any) => void
  open: boolean
  selectedValue: BOCompanyBenInfo | null
}

export const BOGeneralInfoPolicyPopup = (props: BOGeneralInfoPolicyPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, selectedValue, open } = props

  const handleClose = (props: BOGeneralInfoPolicyPopupProps) => {
    onClose(selectedValue)
  }
  const infoMapper = infoGen(false)

  const memberInfo = [
    [{ id: 'memberName', title: 'Họ và tên', value: selectedValue?.fullName || '-' }],
    [
      { id: 'memberDOB', title: 'Ngày sinh', value: selectedValue?.dob || '-' },
      { id: 'memberGender', title: 'Giới tính', value: selectedValue?.gender || '-' }
    ],
    [
      { id: 'memberOccupation', title: 'Nghề nghiệp', value: selectedValue?.position || '-' },
      { id: 'memberTitle', title: 'Chức vụ', value: selectedValue?.position || '-' }
    ],
    [{ id: 'memberPhone', title: 'Số điện thoại', value: selectedValue?.mobile || '-' }],
    [{ id: 'memberNationID', title: 'Số CMND / CCCD / Hộ chiếu', value: selectedValue?.nationalId || '-' }],
    [
      { id: 'memberNationIDCreatedDate', title: 'Ngày cấp', value: selectedValue?.nationalIdIssueDate || '-' },
      { id: 'memberNationIDCreatedLocation', title: 'Nơi cấp', value: selectedValue?.nationalIdIssuePlace || '-' }
    ],
    [
      { id: 'memberNation', title: 'Quốc tịch', value: selectedValue?.nationality || '-' },
      { id: 'memberOtherNation', title: 'Quốc tịch khác', value: selectedValue?.otherNationality || '-' }
    ],
    [{ id: 'memberVISA', title: 'Thị thực nhập cảnh', value: selectedValue?.visa || '-' }],
    [
      { id: 'memberAddress', title: 'Địa chỉ thường trú', value: selectedValue?.addressPerm || '-' },
      { id: 'memberAddressCurr', title: 'Nơi ở hiện tại', value: selectedValue?.addressCurr || '-' }
    ],
    [{ id: 'memberAddress1', title: 'Địa chỉ tại nước ngoài', value: selectedValue?.addressOversea || '-' }],
    [{ id: 'memberContribution', title: 'Tỷ lệ góp vốn', value: selectedValue?.capitalContribute || '-' }]
  ]

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center', marginLeft: 110 }}>
          Thành viên HĐQT / HĐTV, Ban Giám đốc và các Cổ đông / TV góp vốn chính
        </Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose(null)
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin thành viên chi tiết</Text>
          {infoMapper(memberInfo, 'memberInfo')}
        </View>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose(null)}>
          <Text style={{ color: '#FFF' }}>OK</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}
