import React, { useState } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import {
  OBButton,
  OBGeneralTable,
  OBGeneralTableColum,
  OBIconButton,
  OBSectionCol,
  OBSectionContent,
  OBSectionRow,
  OBSharedStyles
} from '../ob-common'
import {
  Container,
  DatePicker,
  Input,
  SelectOption,
  SelectSearch,
  assets,
  ErrorHandling,
  downloadURI,
  useGlobalLoading,
  OBImportFilePermission,
  AppContext
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { OBUploadedHistoryConst } from './OBUploadedHistoryConst'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'
import { OBImportFileService, PayloadExportFileImport, PayloadSearchImportFile } from '../ob-service'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { isArray, isEmpty } from 'lodash'
import { OBImportFileContext } from './OBImportFileContext'
import * as O from 'fp-ts/lib/Option'

type OBFileUploadedHistoryData = {
  fromDate: Date | null
  toDate: Date | null
  department: SelectOption | undefined
  fileName: string | undefined
  adhocName: SelectOption | undefined
  uploadedBy: string | undefined
}

type Pagination = {
  pageNum: number
  pageSize: number
  total: number
}

type UploadHistory = {
  STT: number
  fileName: string
  adhocName: string
  uploadDate: string
  totalRow: number
  totalRowValid: number
  totalRowInvalid: number
  totalRowSubmitted: number
  uploadedBy: string
  status: string
  totalRowFailTrigger: number
  evidenceName: string
}

const obImportFileTranslate = 'Outbound:OBImportFile.UploadHistory'

type Props = {
  permissions: string[]
}

export const OBFileUploadedHistory = (props: Props) => {
  const { permissions } = props
  const { t, i18n } = useTranslation()
  const { bindLoader } = useGlobalLoading(false)
  const [loading, bindLoading] = useLoading(false)
  // const [adhocNameOption, setAdhocNameOption] = useState<SelectOption[]>([])
  const [uploadedHistoryList, setUploadedHistoryList] = useState<UploadHistory[] | null>(null)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { AdhocNameList } = React.useContext(OBImportFileContext)
  const [{ pageNum, pageSize, total }, setPagination] = useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })

  const { control, watch, trigger } = useForm<OBFileUploadedHistoryData>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
      department: undefined
    },
    mode: 'onChange'
  })

  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t('Outbound:OBActionHistory:No'),
      fieldName: 'STT',
      // disabled: true
      // onRender: (rowItem) => {
      //   const item = uploadedHistoryList?.[rowItem.index]
      //   return <Text>{item?.STT || 0}</Text>
      // }
    },
    {
      title: t(`${obImportFileTranslate}.FileName`),
      fieldName: 'fileName',
      // disabled: true,
      onRender: (rowItem) => {
        // const item = uploadedHistoryList?.[rowItem.index]
        const fileName = rowItem.selectItem["fileName"] as string
        return (
          <Pressable onPress={() => callAPIdownloadOriginImportFile({ fileName: fileName })}>
            <Text style={fileUploadStyles.hightLightText}>{fileName}</Text>
          </Pressable>
        )
      }
    },
    {
      title: t(`${obImportFileTranslate}.AdhocCode`),
      fieldName: 'adhocCode',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.AdhocName`),
      fieldName: 'adhocName',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.UploadDate`),
      fieldName: 'uploadDate',
      // disabled: true
      onRender: (rowItem) => {
        const uploadDate = rowItem.selectItem["uploadDate"] as string
        return <Text>{moment(uploadDate).format('DD/MM/YYYY')}</Text>
      }
    },
    {
      title: t(`${obImportFileTranslate}.TotalRow`),
      fieldName: 'totalRow',
      // disabled: true,
      onRender: (rowItem) => {
        // const item = uploadedHistoryList?.[rowItem.index]
        const fileName = rowItem.selectItem["fileName"] as string
        const totalRow = rowItem.selectItem["totalRow"] as number
        const isHaveTotalRow = totalRow !== 0
        return (
          <TouchableOpacity
            onPress={() => callAPIExportCompleteDataImportFile({ fileName: fileName })}
            disabled={!isHaveTotalRow}
          >
            <Text style={isHaveTotalRow && fileUploadStyles.hightLightText}>{totalRow ?? '-'}</Text>
          </TouchableOpacity>
        )
      }
    },
    {
      title: t(`${obImportFileTranslate}.TotalRowValid`),
      fieldName: 'totalRowValid',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.TotalRowInValid`),
      fieldName: 'totalRowInvalid',
      // disabled: true,
      onRender: (rowItem) => {
        // const item = uploadedHistoryList?.[rowItem.index]
        const fileName = rowItem.selectItem["fileName"] as string
        const totalRowInvalid = rowItem.selectItem["totalRowInvalid"] as number
        const isHaveTotalRowInvalid = totalRowInvalid !== 0
        return (
          <TouchableOpacity
            onPress={() => callAPIExportInvalidDataImportFile({ fileName: fileName ?? '' })}
            disabled={!isHaveTotalRowInvalid}
          >
            <Text style={isHaveTotalRowInvalid && fileUploadStyles.hightLightText}>{totalRowInvalid ?? '-'}</Text>
          </TouchableOpacity>
        )
      }
    },
    {
      title: t(`${obImportFileTranslate}.TotalRowFailTrigger`),
      fieldName: 'totalRowFailTrigger',
      // disabled: true,
      onRender: (rowItem) => {
        const totalRowFailTrigger = rowItem.selectItem["totalRowFailTrigger"] as number
        const fileName = rowItem.selectItem["fileName"] as string
        const isHaveTotalRowFailTrigger = totalRowFailTrigger !== 0
        return (
          <TouchableOpacity
            onPress={() => callAPIExportTriggerFailedDataImportFile({ fileName: fileName })}
            disabled={!isHaveTotalRowFailTrigger}
          >
            <Text style={isHaveTotalRowFailTrigger && fileUploadStyles.hightLightText}>{totalRowFailTrigger}</Text>
          </TouchableOpacity>
        )
      }
    },
    {
      title: t(`${obImportFileTranslate}.TotalRowSubmitted`),
      fieldName: 'totalRowSubmitted',
      // disabled: true
    },
    {
      title: t('Outbound:OBImportFile.UploadDetailHistory.Status'),
      fieldName: 'status',
      // disabled: true,
      onRender: (rowItem) => {
        const status = rowItem.selectItem["status"] as string
        const statusName = pipe(
          status,
          O.fromNullable,
          O.fold(
            () => '',
            (statusCode) => {
              const statusItem = pipe(
                OBUploadedHistoryConst.OBUploadStatusConst.find((x) => x.code === statusCode),
                O.fromNullable,
                O.fold(
                  () => '',
                  (data) => i18n.language === 'en' ? data.nameEn : data.nameVi
                )
              )
              return statusItem
            }
          )
        )
        return (
          <>{statusName}</>
        )
      }
    },
    {
      title: t(`${obImportFileTranslate}.UploadedBy`),
      fieldName: 'uploadedBy',
      // disabled: true
    },
    {
      title: t(`${obImportFileTranslate}.EvidenceFile`),
      fieldName: 'evidenceName',
      onRender: (rowItem) => {
        const evidenceName = rowItem.selectItem["evidenceName"] as string
        return !!evidenceName ? (
          <TouchableOpacity
            onPress={() => getEvidenceFile(evidenceName)}
            disabled={false}
          >
            <Text style={fileUploadStyles.hightLightText}>{evidenceName}</Text>
          </TouchableOpacity>
        ) : (
          <></>
        )
      }
    }
  ]


  const callAPIdownloadOriginImportFile = ({ fileName }: { fileName: string }) => {
    return pipe(
      OBImportFileService.downloadOriginImportFile({ fileName }),
      ZIO.map(([_, blob]) => {
        downloadURI(window.URL.createObjectURL(blob), fileName)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const callAPIExportCompleteDataImportFile = ({ fileName }: { fileName: string }) => {
    return pipe(
      OBImportFileService.exportCompleteDataImportFile({ fileName }),
      ZIO.tap(([_, blob]) => {
        const timeToDownloadFile = moment().format('YYYYMMDDHHmmss')
        const _fileName = `${fileName.split('.')[0]}_TotalRow_${timeToDownloadFile}.xlsx`

        downloadURI(window.URL.createObjectURL(blob), _fileName)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const callAPIExportInvalidDataImportFile = ({ fileName }: { fileName: string }) => {
    return pipe(
      OBImportFileService.exportInvalidDataImportFile({ fileName }),
      ZIO.tap(([_, blob]) => {
        const timeToDownloadFile = moment().format('YYYYMMDDHHmmss')
        const _fileName = `${fileName.split('.')[0]}_TotalRowInvalid_${timeToDownloadFile}.xlsx`

        downloadURI(window.URL.createObjectURL(blob), _fileName)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const callAPIExportTriggerFailedDataImportFile = ({ fileName }: { fileName: string }) => {
    return pipe(
      OBImportFileService.exportTriggerFailedDataImportFile({ fileName }),
      ZIO.tap(([_, blob]) => {
        const timeToDownloadFile = moment().format('YYYYMMDDHHmmss')
        const _fileName = `${fileName.split('.')[0]}_TotalRowFailTrigger_${timeToDownloadFile}.xlsx`

        downloadURI(window.URL.createObjectURL(blob), _fileName)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const callAPISearchImportFile = (payload: PayloadSearchImportFile) => {
    return pipe(
      OBImportFileService.searchImportFile({ ...payload }),
      ZIO.map((res) => {
        const { records, paging } = res ?? {}

        const { total, pageIndex, itemsPerPage } = { ...paging } ?? {}

        const _records = records.map(
          (
            {
              fileName,
              adhocCode,
              adhocName,
              updatedDate,
              totalRecord,
              recordValid,
              recordInvalid,
              recordSubmitted,
              createdBy,
              createdDate,
              status,
              recordFail,
              evidenceName
            },
            index
          ) => {
            return {
              STT: Number(pageIndex * itemsPerPage + (index + 1) || 0),
              fileName: fileName || '-',
              adhocCode: adhocCode || '-',
              adhocName: adhocName || '-',
              // uploadDate: moment(createdDate).format('DD/MM/YYYY') || '-',
              uploadDate: createdDate || '',
              totalRow: totalRecord ?? 0,
              totalRowValid: recordValid ?? 0,
              totalRowInvalid: recordInvalid ?? 0,
              totalRowSubmitted: recordSubmitted ?? 0,
              uploadedBy: createdBy || '-',
              status: status,
              totalRowFailTrigger: recordFail || 0,
              evidenceName: evidenceName || ''
            }
          }
        )
        setUploadedHistoryList(_records)
        setPagination({
          total,
          pageNum: pageIndex,
          pageSize: itemsPerPage
        })
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const callAPIExportFileImport = (payload: PayloadExportFileImport) => {
    return pipe(
      OBImportFileService.exportFileImport({ ...payload }),
      ZIO.tap(([_, blob]) => {
        switch (blob.type) {
          case 'application/json':
            showToast(t('message:OB0032'), 'error')
            break
          default:
            const timeToDownloadFile = moment().format('YYYYMMDD')
            const _fileName = `Adhoc_report_${timeToDownloadFile}.xlsx`
            downloadURI(window.URL.createObjectURL(blob), _fileName)
            break
        }
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const getEvidenceFile = (fileName: string) => {
    const inputData = { fileName: fileName }
    pipe(
      OBImportFileService.getEvidenceFile(inputData),
      ZIO.tap(([_, blob]) => {
        downloadURI(window.URL.createObjectURL(blob), fileName)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  /**
   * Function
   */

  const mappingPayloadSearch = (pagination: { pageNum: number; pageSize: number }) => {
    const { fromDate = '', toDate = '', adhocName, department, fileName = '', uploadedBy = '' } = watch()

    return {
      fromDate: moment(fromDate).format('DD-MM-YYYY'),
      toDate: moment(toDate).format('DD-MM-YYYY'),
      adhocName: adhocName?.label ?? '',
      departmentCode: department?.value ?? '',
      fileName,
      createdBy: uploadedBy,
      paging: {
        pageIndex: pagination.pageNum || 0,
        itemsPerPage: pagination.pageSize || 10
      }
    }
  }

  /**
   * Actions
   */

  const onSearchEvent = async () => {
    const isValidForm = await trigger()

    if (!isValidForm) return

    const payload = mappingPayloadSearch({ pageNum: 0, pageSize: 10 })

    return pipe(payload, callAPISearchImportFile)
  }

  const onExportEvent = async () => {
    const isValidForm = await trigger()
    if (!isValidForm) return

    const payload = mappingPayloadSearch({ pageNum, pageSize })
    return callAPIExportFileImport({ ...payload })
  }

  const onPageChange = ({ pageNum, pageSize }: { pageNum: number; pageSize: number }) => {
    const payload = mappingPayloadSearch({ pageNum, pageSize })
    return pipe(payload, callAPISearchImportFile)
  }

  const onRowsPerPageChange = ({ pageNum, pageSize }: { pageNum: number; pageSize: number }) => {
    const payload = mappingPayloadSearch({ pageNum: pageNum, pageSize: pageSize })
    return pipe(payload, callAPISearchImportFile)
  }

  /**
   * Views
   */

  const renderTable = () => {
    if (!uploadedHistoryList) return <></>
    if (isArray(uploadedHistoryList) && isEmpty(uploadedHistoryList))
      return <Text style={{ textAlign: 'center', marginTop: 12 }}> {t('common:noRecord')}</Text>
    return (
      <OBGeneralTable
        isPagination
        stickyHeader
        rowsPerPageOptions={[10, 20, 50, 100]}
        displayedColumns={displayedColumns}
        dataSource={uploadedHistoryList}
        totalRecord={total}
        pageNum={pageNum}
        pageSize={pageSize}
        onPageChange={(pageNum, pageSize) => onPageChange({ pageNum, pageSize })}
        onRowsPerPageChange={(pageNum, pageSize) => onRowsPerPageChange({ pageNum, pageSize })}
        setPageNum={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
        setPageSize={(pageSize) => setPagination((state) => ({ ...state, pageSize }))}
        setTotalRecord={(total) => setPagination((state) => ({ ...state, total }))}
      />
    )
  }

  return (
    <ScrollView style={fileUploadStyles.container}>
      <OBSectionContent>
        <OBSectionRow>
          <OBSectionCol>
            <Controller
              control={control}
              name="fromDate"
              rules={{
                validate: (value) => {
                  const toDate = watch('toDate')
                  const monthDistance =
                    moment(toDate).isValid() && moment(value).isValid()
                      ? moment(toDate).startOf('days').diff(moment(value).startOf('days'), 'months')
                      : 0
                  if (!value || !moment(value).isValid()) {
                    return `${t('message:MS020009', { field: t('ServiceInquiry:FromDate') })}`
                  } else if (moment(value).valueOf() > moment().valueOf()) {
                    return `${t('message:MS990032')}`
                  } else if (monthDistance > 12) {
                    return `${t('message:MS080004', { k: 12 })}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <DatePicker
                  required={true}
                  label={t('ServiceInquiry:FromDate')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS990032')}
                  minDate={undefined}
                  minDateMessage={''}
                  errorMessage={error?.message}
                />
              )}
            />
          </OBSectionCol>
          <OBSectionCol>
            <Controller
              control={control}
              name="toDate"
              rules={{
                validate: (value) => {
                  const fromDate = watch('fromDate')
                  const monthDistance =
                    moment(value).isValid() && moment(fromDate).isValid()
                      ? moment(value).startOf('days').diff(moment(fromDate).startOf('days'), 'months')
                      : 0
                  if (!value || !moment(value).isValid()) {
                    return `${t('message:MS020009', { field: t('ServiceInquiry:ToDate') })}`
                  } else if (moment(value).valueOf() > moment().valueOf()) {
                    return `${t('message:MS990032')}`
                  } else if (
                    moment(moment(value).format('YYYY/MM/DD')).valueOf() <
                    moment(moment(fromDate).format('YYYY/MM/DD')).valueOf()
                  ) {
                    return `${t('message:MS030044')}`
                  } else if (monthDistance > 12) {
                    return `${t('message:MS080004', { k: 12 })}`
                  } else {
                    return true
                  }
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <DatePicker
                  required={true}
                  label={t('ServiceInquiry:ToDate')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS990032')}
                  minDate={undefined}
                  errorMessage={error?.message}
                />
              )}
            />
          </OBSectionCol>
          <OBSectionCol>
            <Controller
              control={control}
              name={'department'}
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectSearch
                  label={t('Outbound:OBUploadFile:Department')}
                  options={OBUploadedHistoryConst.DepartmentListConst.map((item) => ({
                    value: item.code,
                    label: i18n.language === 'en' ? item.nameEn : item.nameVi
                  }))}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={t('common:Select')}
                />
              )}
            />
          </OBSectionCol>
        </OBSectionRow>
        <OBSectionRow style={fileUploadStyles.secondLine}>
          <OBSectionCol>
            <Controller
              control={control}
              name={'fileName'}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input title={t('Outbound:OBUploadFile:FileName')} value={value} onChange={onChange} onBlur={onBlur} />
              )}
            />
          </OBSectionCol>
          <OBSectionCol>
            <Controller
              control={control}
              name={'adhocName'}
              render={({ field: { value, onChange, onBlur } }) => (
                <SelectSearch
                  label={t('Outbound:OBUploadFile:AdhocName')}
                  options={AdhocNameList}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  placeholder={t('common:Select')}
                />
              )}
            />
          </OBSectionCol>
          <OBSectionCol>
            <Controller
              control={control}
              name={'uploadedBy'}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  title={t('Outbound:OBUploadFile:UploadedBy')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={''}
                />
              )}
            />
          </OBSectionCol>
        </OBSectionRow>
        <View style={[fileUploadStyles.buttonContainer, fileUploadStyles.secondLine]}>
          {permissions.includes(OBImportFilePermission.SearchFileImportHistoryAdhoc) ? (
            <OBButton
              text={t(`${obImportFileTranslate}.Search`)}
              onHandleClickEvent={() => onSearchEvent()}
              isHightLight={true}
            />
          ) : null}

          {permissions.includes(OBImportFilePermission.ExportFileImportHistoryAdhoc) ? (
            <OBIconButton
              text={t(`${obImportFileTranslate}.Export`)}
              onHandleClickEvent={() => onExportEvent()}
              buttonContainerStyle={fileUploadStyles.secondBtn}
              isHightLight={true}
              btnIcon={<assets.OBExportFile />}
            />
          ) : null}
        </View>
      </OBSectionContent>
      <OBSectionRow>
        <OBSectionCol style={OBSharedStyles.sectionCol12}>
          <Container loading={loading}>{renderTable()}</Container>
        </OBSectionCol>
      </OBSectionRow>
    </ScrollView>
  )
}
const fileUploadStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  secondLine: {
    marginTop: 25
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  secondBtn: {
    marginLeft: 15
  },
  hightLightText: {
    textDecorationLine: 'underline',
    color: 'rgb(30, 165, 252)'
  }
})
