import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { IBULP } from './IBULP'
import { IBILP } from './IBILP'
import { IBService, InvestmentDataInfo, InvestmentFund, ProductTypeC } from '../../../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { ErrorHandling, PulseOpsFormat } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { isNil } from 'lodash'
export enum FundType {
  TargetPremium = 'TP01',
  TopUp = 'TU02'
}

interface Props {
  scrollRef: React.MutableRefObject<ScrollView | null>
  investmentInfo?: InvestmentDataInfo
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  policyNumber: string
  status: string
  productInfo: ProductTypeC
  listFundT25: InvestmentFund[]
  setListFundT25: (data: InvestmentFund[]) => void
}

export const IBInvesmentInfo = (props: Props) => {
  const {
    investmentInfo,
    setInvestmentInfo,
    policyNumber,
    status,
    productInfo,
    scrollRef,
    listFundT25,
    setListFundT25
  } = props
  const [loading, bindLoading] = useLoading()
  const getData = (): ZIO<unknown, Throwable, InvestmentDataInfo | null> =>
    pipe(
      ZIO.succeed(productInfo),
      ZIO.flatMap((productInfo) => {
        return pipe(
          ZIO.zipPar(
            IBService.getInvestmentSummary(
              policyNumber,
              productInfo.body.productType as string,
              productInfo.body.basicCode as string
            ),
            IBService.getInvestmentFund()
          ),
          ZIO.map(([investmentInfo, listFundT25]) => {
            setListFundT25(listFundT25.data)
            const listFundTopUp = listFundT25.data
              .filter((x) => x.fundTypeCode === FundType.TopUp)
              .map((x) => x.fundCode)

            const listFundTargetPremium = listFundT25.data
              .filter((x) => x.fundTypeCode === FundType.TargetPremium)
              .map((x) => x.fundCode)

            const investmentData = investmentInfo.data

            const targetPremiumEstimatedValue = (investmentData.policyExtraInfo?.policyExtraInfoFunds || [])
              .filter((x) => listFundTargetPremium.includes(x.fundCode as string))
              .reduce((pre, cur) => pre + (cur.estimateValue ?? 0), 0)

            const topUpPremiumEstimatedValue = (investmentData.policyExtraInfo?.policyExtraInfoFunds || [])
              .filter((x) => listFundTopUp.includes(x.fundCode as string))
              .reduce((pre, cur) => pre + (cur.estimateValue ?? 0), 0)

            const estimatedValueInfo = {
              estimatedValueTotal: PulseOpsFormat.thousandSepartor(
                targetPremiumEstimatedValue + topUpPremiumEstimatedValue
              ),
              totalExcessPremium: PulseOpsFormat.thousandSepartor(investmentData.totalExcessPremium),
              totalAllocatedPremiumToBasicProductAndGroup1Riders: PulseOpsFormat.thousandSepartor(
                investmentData.allocatedPremium?.mainProductAndRider1
              ),
              totalAllocationCharges: PulseOpsFormat.thousandSepartor(investmentData.totalAllocationCharges),
              targetPremiumEstimatedValue: PulseOpsFormat.thousandSepartor(targetPremiumEstimatedValue),
              totalWithdrawnAmount: '0',
              totalAllocatedPremiumToGroup2Riders: PulseOpsFormat.thousandSepartor(
                investmentData.allocatedPremium?.rider2
              ),
              totalCostOfInsurance: PulseOpsFormat.thousandSepartor(investmentData.costOfInsurance),
              topUpPremiumEstimatedValue: PulseOpsFormat.thousandSepartor(topUpPremiumEstimatedValue),
              maximumWithdrawableAmount: PulseOpsFormat.thousandSepartor(investmentData.maxWithDrawableAmt),
              totalAllocatedPremiumToAccountValue: PulseOpsFormat.thousandSepartor(investmentData.allocatedAccValue),
              totalAdministrationCharge: PulseOpsFormat.thousandSepartor(investmentData.administrationCharge),
              fundValueTotal:
                PulseOpsFormat.thousandSepartor(
                  investmentData.policyExtraInfo?.policyExtraInfoFunds?.reduce(
                    (pre, cur) => (pre = pre + (cur.estimateValue as number)),
                    0
                  )
                ) || '0'
            }
            return {
              investmentSummary: investmentData,
              estimatedValueInfo: estimatedValueInfo || undefined,
              partialWithdrawInfo: props.investmentInfo?.partialWithdrawInfo,
              partialWithdrawHistory: props.investmentInfo?.partialWithdrawHistory,
              fundValueInfo: props.investmentInfo?.fundValueInfo,
              loyaltyBonus: props.investmentInfo?.loyaltyBonus,
              allocatedPremiumInfo: props.investmentInfo?.allocatedPremiumInfo,
              allocationChargeInfo: props.investmentInfo?.allocationChargeInfo,
              costOfInsuranceInfo: props.investmentInfo?.costOfInsuranceInfo,
              administrationChargeInfo: props.investmentInfo?.administrationChargeInfo,
              topUpHistory: props.investmentInfo?.topUpHistory,
              fundSwitchHistory: props.investmentInfo?.fundSwitchHistory,
              premiumRedirectionHistory: props.investmentInfo?.premiumRedirectionHistory,
              investmentInterest: props.investmentInfo?.investmentInterest,
              accountValue: props.investmentInfo?.accountValue,
              extraInfo: props.investmentInfo?.extraInfo
            }
          })
        )
      })
    )

  React.useEffect(() => {
    pipe(
      !isNil(investmentInfo) ? ZIO.succeed(investmentInfo) : getData(),
      ZIO.map((res) => {
        if (res !== null && isNil(investmentInfo)) setInvestmentInfo(res)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [])

  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : productInfo.body.productType === 'ULP' ? (
        <IBULP
          scrollRef={scrollRef}
          policyNumber={policyNumber}
          productCode={productInfo.body.productCode as string}
          setInvestmentInfo={setInvestmentInfo}
          investmentInfo={investmentInfo}
          status={status}
          basicCode={productInfo.body.basicCode as string}
          productType={productInfo.body.productType as string}
          listFundT25={listFundT25}
        />
      ) : (
        <IBILP
          scrollRef={scrollRef}
          policyNumber={policyNumber}
          productCode={productInfo.body.productCode as string}
          setInvestmentInfo={setInvestmentInfo}
          investmentInfo={investmentInfo}
          status={status}
          productType={productInfo.body.productType as string}
          basicCode={productInfo.body.basicCode as string}
          listFundT25={listFundT25}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({})
