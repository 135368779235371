import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { OBCategoryTab, CategoryTabInfo } from '../../ob-task-management'
import { useTranslation } from 'react-i18next'
import { OBPolicyInfo } from './OBPolicyInfo'
import { OBPolicyOwner } from './OBPolicyOwner'
import { OBAgent } from './OBAgent'
import { OBSpecialInformation } from './ob-special-information/OBSpecialInformation'
import { OBLifeAssured } from './OBLifeAssured'
import { CallOutDetailContext } from '@pulseops/outbound'
import { Permission } from '@pulseops/common'

export const OBGeneralInfomation = () => {
  const { t, i18n } = useTranslation()
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const [tabIndex, setTabIndex] = React.useState<CategoryTabInfo | null>(null)
  const { permissions } = React.useContext(CallOutDetailContext)
  const [categoryTabList, setCategoryTabList] = React.useState<CategoryTabInfo[]>([])

  const checkPermissionTabView = () => {
    const listTabPermission: CategoryTabInfo[] = []

    permissions.includes(Permission['ViewGeneralInfoPolicyOBTaskDetail']) &&
      listTabPermission.push({
        label: t('Outbound:OBCalloutDetail:PolicyInfo'),
        key: '1'
      })

    permissions.includes(Permission['ViewGeneralInfoPolicyOwnerOBTaskDetail']) &&
      listTabPermission.push({
        label: t('Outbound:OBCalloutDetail:PolicyOwner'),
        key: '2'
      })

    permissions.includes(Permission['ViewGeneralInfoLifeAssuredOBTaskDetail']) &&
      listTabPermission.push({
        label: t('Outbound:OBCalloutDetail:LifeAssured'),
        key: '3'
      })

    permissions.includes(Permission['ViewGeneralInfoAgentOBTaskDetail']) &&
      listTabPermission.push({
        label: t('Tab:AgentInfo'),
        key: '4'
      })

    permissions.includes(Permission['ViewGeneralInfoSpecialOBTaskDetail']) &&
      listTabPermission.push({
        label: t('Outbound:OBCalloutDetail:SpecialInformation'),
        key: '5'
      })

    listTabPermission.length > 0 && setCategoryTabIndex(0)
    listTabPermission.length > 0 && setTabIndex(listTabPermission[0])
    setCategoryTabList([...listTabPermission])
  }

  const getViewByTabIndex = (tab: CategoryTabInfo) => {
    // console.log(tab, 'tab')
    switch (tab.key) {
      case '1':
        return <OBPolicyInfo></OBPolicyInfo>
      case '2':
        return <OBPolicyOwner></OBPolicyOwner>
      case '3':
        return <OBLifeAssured></OBLifeAssured>
      case '4':
        return <OBAgent></OBAgent>
      case '5':
        return <OBSpecialInformation></OBSpecialInformation>
      default:
        return <></>
    }
  }

  React.useEffect(() => {
    checkPermissionTabView()
  }, [permissions, i18n.language])

  return (
    <View style={generalStyles.container}>
      <OBCategoryTab
        dataSource={categoryTabList}
        onChangeTab={(index) => {
          setCategoryTabIndex(index), setTabIndex(categoryTabList[index])
        }}
        activeTabIndex={categoryTabIndex}
      ></OBCategoryTab>
      {tabIndex && getViewByTabIndex(tabIndex)}
    </View>
  )
}
const generalStyles = StyleSheet.create({
  container: {
    display: 'flex',
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  }
})
