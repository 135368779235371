import { Task, Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import moment from 'moment'
import { POApi } from '../POApi'
import { GeneralService } from './GeneralService'
import { BeneficiaryDesignationData, InquiryComplaintData, SourceType } from './model'
import { SubmissionService } from './SubmissionService'
import * as O from 'fp-ts/Option'
import { UploadedFilesInfo } from 'libs/submission/src/ps-submission/transactionScreen/screens'
import { IACPayloadInfo } from './model/task-detail-api/InquiryComplaint'
import { VeriPayloadData } from './model/task-detail/InquiryComplaint'
import { CloudData } from '../component'
import { Duration } from '@mxt/zio/date'

export namespace InquiryComplaintService {
  export const IACgroup = t.array(
    t.type({
      id: t.string,
      categoryName: t.string,
      code: t.string,
      name: t.string,
      nameVi: t.string
    })
  )

  export const IACType = t.array(
    t.type({
      id: t.string,
      groupCode: t.string,
      code: t.string,
      name: t.string,
      nameVi: t.string
    })
  )

  export const IACSubType = t.array(
    t.type({
      id: t.string,
      typeCode: t.string,
      code: t.string,
      name: t.string,
      nameVi: t.string,
      slaStandard: Maybe(t.string)
    })
  )

  export const IACSource = t.array(
    t.type({
      id: t.string,
      code: t.string,
      name: t.string,
      nameVi: t.string
    })
  )

  export const IACMethod = t.array(
    t.type({
      id: t.string,
      code: t.string,
      name: t.string,
      nameVi: Maybe(t.string)
    })
  )

  export const IACReceiptType = t.array(
    t.type({
      code: t.string,
      name: t.string,
      nameEn: t.string
    })
  )

  export type IACMethod = t.TypeOf<typeof IACMethod>
  export type IACSource = t.TypeOf<typeof IACSource>
  export type IACSubType = t.TypeOf<typeof IACSubType>
  export type IACType = t.TypeOf<typeof IACType>
  export type IACgroup = t.TypeOf<typeof IACgroup>
  export type IACReceiptType = t.TypeOf<typeof IACReceiptType>

  // verification models
  export const VeriIACDiary = t.type({
    id: t.string,
    processInstanceId: t.string,
    typeCode: t.string,
    description: t.string,
    createdDate: t.string,
    createdBy: t.string,
    isViewed: t.union([t.boolean, t.undefined])
  })

  export const VeriIACDiaryType = t.type({
    id: t.string,
    desc_vi: t.string,
    desc_en: t.string
  })

  export interface VeriSortData {
    order: string
    page: number
    processInstanceId: string
    size: number
    sort: string
  }

  export const VeriResolutionData = t.type({
    id: t.string,
    agentCode: t.string,
    agentName: t.string,
    amount: t.string,
    attachmentIds: t.array(t.string),
    changeType: t.boolean,
    complaintAgainstId: t.string,
    correctiveStatementId: t.string,
    createdBy: t.string,
    createdDate: t.string,
    createdLetter: t.boolean,
    isSerious: t.boolean,
    processInstanceId: t.string,
    reasonDealingId: t.string,
    reasonId: t.string,
    resolutionActionId: t.string,
    responseMethodId: t.string,
    reasonExceedingId: Maybe(t.string),
    detail: Maybe(t.string),
    newGroup: Maybe(t.string),
    newType: Maybe(t.string),
    newSubType: Maybe(t.string)
  })

  export const clientRelationShip = t.type({
    action: Maybe(t.string),
    status: Maybe(t.string),
    clientNum1: Maybe(t.string),
    clientNum2: Maybe(t.string),
    clientNum3: Maybe(t.string),
    clientNum4: Maybe(t.string),
    clientNum5: Maybe(t.string),
    clientNum6: Maybe(t.string),
    clientNum7: Maybe(t.string),
    clientNum8: Maybe(t.string),
    clientNum9: Maybe(t.string),
    clientNum10: Maybe(t.string),
    relationship1: Maybe(t.string),
    relationship2: Maybe(t.string),
    relationship3: Maybe(t.string),
    relationship4: Maybe(t.string),
    relationship5: Maybe(t.string),
    relationship6: Maybe(t.string),
    relationship7: Maybe(t.string),
    relationship8: Maybe(t.string),
    relationship9: Maybe(t.string),
    relationship10: Maybe(t.string)
  })

  export const CSSource = t.array(
    t.type({
      id: t.string,
      code: t.string,
      name: Maybe(t.string),
      nameVi: Maybe(t.string),
      nameEn: Maybe(t.string),
      touchPoint: Maybe(t.string)
    })
  )

  export const DocTypeListWithMeta = t.array(
    t.type({
      transactionType: t.string,
      departmentShortName: t.string,
      department: t.string,
      trigger: t.string,
      doctypeBPM: t.string,
      doctypeVn: t.string,
      doctypeEn: t.string,
      docTypeCode: t.string,
      classFilenet: t.string,
      docID: t.string,
      mainDoc: t.string,
      subDoc: t.string,
      batchNo: t.string,
      type: t.string,
      refNum: t.string
    })
  )

  export const EditingHistoryData = t.type({
    prefix: Maybe(t.string),
    id: Maybe(t.string),
    payLoad: Maybe(t.string),
    vtsIV: Maybe(t.string),
    policyNumber: Maybe(t.string),
    caseId: Maybe(t.string),
    transactionType: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    parentProcessInstanceId: Maybe(t.string),
    isSubmissionForm: Maybe(t.string),
    createdBy: Maybe(t.string),
    createdDate: Maybe(t.string)
  })

  export const ResponseStatus = Maybe(
    t.type({
      responseStatus: t.type({
        code: t.number,
        message: t.string
      })
    })
  )

  export const PayloadHistoryData = t.type({
    data: t.array(EditingHistoryData),
    total: t.number,
    start: t.number,
    sort: Maybe(t.string),
    order: Maybe(t.string),
    size: t.number
  })

  export const PayloadHistoryResponse = t.intersection([PayloadHistoryData, ResponseStatus])

  export const PayloadViewInput = t.type({
    start: t.number,
    size: t.number,
    processInstanceId: t.string
  })

  export const EditingHistoryDataList = t.array(EditingHistoryData)

  export type VeriIACDiary = t.TypeOf<typeof VeriIACDiary>
  export type VeriIACDiaryType = t.TypeOf<typeof VeriIACDiaryType>
  export type VeriResolutionData = t.TypeOf<typeof VeriResolutionData>
  export type DocTypeListWithMeta = t.TypeOf<typeof DocTypeListWithMeta>
  export type EditingHistoryData = t.TypeOf<typeof EditingHistoryData>
  export type EditingHistoryDataList = t.TypeOf<typeof EditingHistoryDataList>
  export type PayloadViewInput = t.TypeOf<typeof PayloadViewInput>
  export type PayloadHistoryResponse = t.TypeOf<typeof PayloadHistoryResponse>
  export type PayloadHistoryData = t.TypeOf<typeof PayloadHistoryData>

  export const getDetail = (policyNum: string): Task<InquiryComplaintData.DetailData> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getClientsByPolicy(policyNum),
        SubmissionService.getPolicyHeader(policyNum),
        SubmissionService.getPolicy(policyNum),
        getGroups(),
        getMethods(),
        getSources(),
        getIAC_ClientRelationShip(policyNum)
      ),
      ZIO.flatMap(([clientInfo, policyHeaderInfo, policyInfo, groupList, methodList, sourceList, relationShipList]) => {
        const agentCode = policyInfo.body.servingAgents?.agent?.code ?? ''
        //console.log(`relationShipList:${JSON.stringify(relationShipList)}`)
        return pipe(
          ZIO.zipPar(
            SubmissionService.getClients(clientInfo.laList.map((l) => ({ clientId: l.clientNumberOfLA }))),
            SubmissionService.getClients(clientInfo.benList.map((l) => ({ clientId: l.clientNumberOfBen }))),
            SubmissionService.getAgents([agentCode])
          ),
          ZIO.map(([laInfo, benInfo, agentInfo]) => {
            const laList = clientInfo.laList.map((item) => {
              const clientItem = laInfo.body.find((x) => x.clientId === item.clientNumberOfLA)
              return {
                clientNumberOfLA: item.clientNumberOfLA,
                life: item.life,
                clientName: String(clientItem?.name)
              }
            })
            const benList = clientInfo.benList.map((item) => {
              const clientItem = benInfo.body.find((x) => x.clientId === item.clientNumberOfBen)
              return {
                clientNumberOfBen: item.clientNumberOfBen,
                relationShipCode: item.relationShipCode,
                clientName: String(clientItem?.name)
              }
            })
            const agentName = agentInfo && agentInfo.length > 0 ? agentInfo[0].name : ''
            const BERelationshipList = relationShipList.BERelationshipList.map((item) => {
              return {
                // ...item,
                // id: item.id ?? '',
                // nameEn: item.nameEn ?? '',
                // name: item.nameVn ?? '',
                id: item.id,
                code: item.code,
                female: item.female,
                male: item.male,
                name: item.relationshipVN,
                nameEn: item.relationshipEN
              }
            })
            return {
              laList: laList,
              benList: benList,
              poName: policyHeaderInfo.ownerName,
              agentCode: agentCode,
              agentName: agentName ?? '',
              groupList: groupList,
              methodList: methodList,
              sourceList: sourceList,
              BORelationshipList: relationShipList.BORelationshipList,
              CBrelationshipList: relationShipList.CBrelationshipList,
              BERelationshipList: BERelationshipList,
              clientId: policyHeaderInfo.clientId
            }
          })
        )
      })
    )

  export const getAgentInfo = (agentCode: string): Task<InquiryComplaintData.ICAgentInfo> =>
    pipe(
      SubmissionService.getAgents([agentCode]),
      ZIO.map((agentInfo) => {
        const agentName = agentInfo && agentInfo.length > 0 ? agentInfo[0].name : ''
        const agentID = agentInfo && agentInfo.length > 0 ? agentInfo[0].clientId : ''
        return {
          agentCode: agentCode,
          agentName: agentName ?? '',
          agentID: agentID ?? ''
        }
      })
    )

  export const submitData = (
    policyNum: string,
    data: InquiryComplaintData.SubmitData, 
    documents: BeneficiaryDesignationData.BeneficiaryDocumentFile[],
    uploadOfficeCode?: string,
    uploadedFilesInfo?: UploadedFilesInfo,
    otherSource?: SourceType
  ) =>
    pipe(
      !!uploadedFilesInfo
        ? GeneralService.getAzureStorageFiles(
          uploadedFilesInfo?.uploadFiles,
          uploadedFilesInfo.transactionType,
          uploadedFilesInfo.docTypeCode,
          uploadedFilesInfo.category,
          uploadedFilesInfo.policyNumber,
          uploadedFilesInfo.officeCode
        )
        : ZIO.succeed([]),
      ZIO.flatMap((azuredFiles) =>
        SubmissionService.submit(t.unknown)(
          `wf-api/policy/${policyNum}/complaint-and-inquiry`,
          {
            body: {
              attributesExt: data
            }
          },
          policyNum,
          null,
          azuredFiles,
          undefined,
          undefined,
          uploadOfficeCode,
          undefined,
          undefined,
          undefined,
          otherSource
        )
      )
    )

  export const getGroups = (): Task<InquiryComplaintData.IACGroup[]> =>
    pipe(
      POApi.get(`wf-api/complaint/get-groups`)(
        t.type({
          // status: t.string,
          body: IACgroup,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((groupList) => {
        return groupList.body
      })
    )

  export const getTypes = (groupCode?: string): Task<InquiryComplaintData.IACType[]> =>
    pipe(
      POApi.post(`wf-api/complaint/get-types`)(
        t.type({
          // status: t.string,
          body: IACType,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      )({
        body: {
          groupCode: groupCode || null
        }
      }),
      ZIO.map((typeList) => {
        return typeList.body
      })
    )

  export const getTypesFromCachedApi = (groupCode?: string): Task<InquiryComplaintData.IACType[]> =>
    pipe(
      POApi.post(`wf-api/complaint/get-types`)(
        t.type({
          // status: t.string,
          body: IACType,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      )({
        body: {
          groupCode: groupCode || null
        }
      }),
      ZIO.map((typeList) => {
        return typeList.body
      }),
      ZIO.cached(Duration.minutes(5))  // cache 5 minutes, api will be called when user reload page or user access this page after 5 minutes
    )

  export const getSubType = (typeCode?: string): Task<InquiryComplaintData.IACSubtypes[]> =>
    pipe(
      POApi.post(`wf-api/complaint/get-subtypes`)(
        t.type({
          // status: t.string,
          body: IACSubType,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      )({
        body: {
          typeCode: typeCode || null
        }
      }),
      ZIO.map((subtypeList) => {
        return subtypeList.body
      })
    )

  export const getSubTypeFromCachedApi = (typeCode?: string): Task<InquiryComplaintData.IACSubtypes[]> =>
    pipe(
      POApi.post(`wf-api/complaint/get-subtypes`)(
        t.type({
          // status: t.string,
          body: IACSubType,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      )({
        body: {
          typeCode: typeCode || null
        }
      }),
      ZIO.map((subtypeList) => {
        return subtypeList.body
      }),
      ZIO.cached(Duration.minutes(5))
    )

  export const getSources = (): Task<InquiryComplaintData.CSSources[]> =>
    pipe(
      POApi.get(`wf-api/complaint/get-sources`)(
        t.type({
          body: CSSource,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((list) => {
        const resultList = list.body
        const sourceList = resultList.map((source) => {
          return {
            id: source.id,
            code: source.code,
            name: source.name ?? '',
            nameVi: source.nameVi ?? '',
            touchPoint: source.touchPoint ?? ''
          }
        })
        return sourceList
      })
    )

  export const getMethods = (): Task<InquiryComplaintData.IACSources[]> =>
    pipe(
      POApi.get(`wf-api/complaint/get-methods`)(
        t.type({
          // status: t.string,
          body: IACMethod,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((list) => {
        return list.body.map((item) => ({
          id: item.id,
          code: item.code,
          name: item.name,
          nameVi: item.nameVi ?? ''
        }))
      })
    )

  export const getGeneralAgency = (): Task<InquiryComplaintData.IACSources[]> =>
    pipe(
      POApi.get(`wf-api/agent/get-general-agencies`)(
        t.type({
          // status: t.string,
          body: IACMethod,
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      ),
      ZIO.map((list) => {
        return list.body.map((item) => ({
          id: item.id,
          code: item.code,
          name: item.name,
          nameVi: ''
        }))
      })
    )

  export const getIAC_ClientRelationShip = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        GeneralService.getIACRelationships,
        GeneralService.getRelationship()
      ),
      ZIO.flatMap(([policyInfo, CBrelationshipList, BERelationshipList]) => {
        const clientId = policyInfo.body.owners.id
        //console.log(`BERelationshipList: ${JSON.stringify(BERelationshipList)}`)
        return pipe(
          POApi.post(`wf-api/bo/client-relationship/action-b`)(
            t.type({
              // status: t.string,
              body: clientRelationShip
            })
          )({
            body: {
              primaryClientNum: clientId
            }
          }),
          ZIO.map((clientRelationInfo) => {
            const clientNumList = Object.entries(clientRelationInfo.body)
              .filter(([key, value]) => key.includes('clientNum') && !!value)
              .map(([key, value]) => {
                return value || ''
              })
            const relationshipList = Object.entries(clientRelationInfo.body)
              .filter(([key, value]) => key.includes('relationship') && !!value)
              .map(([key, value]) => {
                return value || ''
              })
            const BORelationshipList = clientNumList.map((item, index) => {
              return {
                clientNum: item,
                relationship: relationshipList[index]
              }
            })
            return {
              BORelationshipList: BORelationshipList,
              CBrelationshipList: CBrelationshipList,
              BERelationshipList: BERelationshipList
            }
          })
        )
      })
    )

  export const getT22Data = (type: string): Task<InquiryComplaintData.IACReceiptType[]> =>
    pipe(
      POApi.get(`wf-api/table/t22/get-data/${type}`)(
        t.type({
          // status: t.string,
          body: IACReceiptType
        })
      ),
      ZIO.map((receiptTypeList) => {
        return receiptTypeList.body
      })
    )

  // services for verification Screen
  export const addNewDiary = (newDiary: VeriIACDiary) =>
    pipe(
      POApi.post(`pulseops/api/v1/cai/add-diary`)(VeriIACDiary)({
        createdBy: newDiary.createdBy,
        description: newDiary.description,
        processInstanceId: newDiary.processInstanceId,
        typeCode: newDiary.typeCode
      }),
      ZIO.map((response) => {
        return response
      })
    )

  export const getDiaryTypeList = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/diary-types`)(
        t.type({
          id: t.string,
          types: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.types
      })
    )

  export const getDiaryList = (sortData: VeriSortData) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/cai/diaries?order=${sortData.order}&page=${sortData.page}&processInstanceId=${sortData.processInstanceId}&size=${sortData.size}&sort=${sortData.sort}`
      )(
        t.type({
          data: t.array(VeriIACDiary)
        })
      )
    )

  export const getComplaintAgainst = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-complaint-against`)(
        t.type({
          id: t.string,
          complaintAgainst: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.complaintAgainst
      })
    )

  export const getResolution = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-actions`)(
        t.type({
          id: t.string,
          actions: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.actions
      })
    )

  export const getResponseMethod = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-response-methods`)(
        t.type({
          id: t.string,
          responseMethods: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.responseMethods
      })
    )

  export const getReasonForDealing = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-reason-dealings`)(
        t.type({
          id: t.string,
          reasonDealing: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.reasonDealing
      })
    )

  export const getCorrectiveSettlement = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-corrective-settlement`)(
        t.type({
          id: t.string,
          correctiveStatements: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.correctiveStatements
      })
    )

  export const getReasonForExceedingTAT = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-reason-exceeding`)(
        t.type({
          id: t.string,
          reasonExceeding: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.reasonExceeding
      })
    )

  export const getReason = () =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution-reason`)(
        t.type({
          id: t.string,
          reason: t.array(VeriIACDiaryType)
        })
      ),
      ZIO.map((response) => {
        return response.reason
      })
    )

  export const getResolutionByProID = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/cai/resolution?processInstanceId=${processInstanceId}`)(VeriResolutionData),
      ZIO.mapError((err) => {
        return Throwable(err)
      }),
      ZIO.flatMap((response): Task<VeriResolutionData> => {
        if (response) {
          return ZIO.succeed(response)
        } else {
          return ZIO.fail(Throwable('no resolution from api response'))
        }
      })
    )

  export const addResolution = (data: VeriResolutionData) =>
    pipe(
      POApi.post(`pulseops/api/v1/cai/add-resolution`)(VeriResolutionData)({
        agentCode: data.agentCode,
        agentName: data.agentName,
        amount: data.amount,
        attachmentIds: data.attachmentIds,
        changeType: data.changeType,
        complaintAgainstId: data.complaintAgainstId,
        correctiveStatementId: data.correctiveStatementId,
        createdBy: data.createdBy,
        createdLetter: data.createdLetter,
        isSerious: data.isSerious,
        processInstanceId: data.processInstanceId,
        reasonDealingId: data.reasonDealingId,
        reasonId: data.reasonId,
        resolutionActionId: data.resolutionActionId,
        responseMethodId: data.responseMethodId,
        reasonExceedingId: data.reasonExceedingId,
        detail: data.detail,
        newGroup: data.newGroup,
        newType: data.newType,
        newSubType: data.newSubType
      })
    )

  export const getFullSurrenderReasonList = (): Task<InquiryComplaintData.FullSurrenderReason[]> =>
    pipe(
      POApi.get(`wf-api/table/t32s1/get-all`)(
        t.type({
          body: t.array(
            t.type({
              reasonCode: t.string,
              reasonEN: t.string,
              reasonVN: t.string
            })
          )
        })
      ),
      ZIO.map((data) => {
        return data.body
      })
    )

  export const getResolutionList = (): Task<InquiryComplaintData.Resolution[]> =>
    pipe(
      POApi.get(`wf-api/table/t32s2/get-all`)(
        t.type({
          body: t.array(
            t.type({
              resolutionCode: t.string,
              resolutionEN: t.string,
              resolutionVN: t.string
            })
          )
        })
      ),
      ZIO.map((data) => {
        return data.body
      })
    )

  export const getDocTypeListIncludeMetaData = (categoryList: string[], officeCode: string) =>
    pipe(
      POApi.post('pulseops/api/v1/filenet/get-all-metadata')(
        t.type({
          data: DocTypeListWithMeta
        })
      )({
        category: categoryList
        // docId: [],
        // docTypeCode: []
      }),
      ZIO.map((response) => {
        let customedBatchNo = ''
        const currentDateFormat = moment().format('DD/MM/YYYY')
        const docTypeList =
          response && response.data.length > 0
            ? response.data.map((docTypeItem) => {
              customedBatchNo = officeCode
                ? currentDateFormat + '-' + docTypeItem.type + '-' + officeCode
                : currentDateFormat + '-' + docTypeItem.type
              return {
                ...docTypeItem,
                batchNo: customedBatchNo
              }
            })
            : []
        return docTypeList
      })
    )

  export const getComplaintSubtypes = (groupCode: string) =>
    pipe(
      POApi.post(`wf-api/complaint/get-complaint-subtypes-by-code`)(
        t.type({
          // status: t.string,
          body: t.array(
            t.type({
              slaStandard: t.string
            })
          ),
          responseStatus: Maybe(
            t.type({
              code: t.number,
              message: t.string
            })
          )
        })
      )({
        body: {
          groupCode: groupCode || null
        }
      }),
      ZIO.map((typeList) =>
        pipe(
          typeList.body[0]?.slaStandard,
          O.fromNullable,
          O.map((sla) => parseInt(sla)),
          O.filter((sla) => !isNaN(sla)),
          O.toNullable
          // O.getOrElse(() => 40)
        )
      )
    )

  export const getEditingHistoryList = (inputData: PayloadViewInput) =>
    pipe(
      POApi.post(`pulseops/api/v1/payload/get-payload-info-list/${inputData.processInstanceId}`)(
        PayloadHistoryResponse
      )({
        start: inputData.start,
        size: inputData.size
      }),
      ZIO.mapError((error) => {
        return Throwable(error)
      }),
      ZIO.flatMap((responseData) => {
        if (!!responseData) {
          return ZIO.succeed(responseData)
        } else {
          return ZIO.fail(Throwable('No payload list from api response'))
        }
      })
    )

  export const getPayloadDataByHistoryID = (historyID: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/payload/get-payload-info/${historyID}`)(
        t.type({
          payload: IACPayloadInfo
        })
      ),
      ZIO.map((responseData) => {
        const payLoadInfo = responseData.payload.body.attributesExt
        const COMPLAINT_MIS_MONEY_DETAILS =
          payLoadInfo.COMPLAINT_MIS_MONEY_DETAILS && payLoadInfo.COMPLAINT_MIS_MONEY_DETAILS.length > 0
            ? payLoadInfo.COMPLAINT_MIS_MONEY_DETAILS.map((item) => {
              return {
                hasReciept: item?.hasReciept ?? '',
                recieptType: item?.recieptType ?? '',
                recieptNum: item?.recieptNum ?? '',
                complaintAmount: item?.complaintAmount ?? 0,
                misPremiumDate: item?.misPremiumDate ?? '',
                evidenceStatus: item?.evidenceStatus ?? '',
                verifiedAmount: item?.verifiedAmount ?? 0
              }
            })
            : []
        const COMPLAINT_SALE_DETAILS =
          payLoadInfo.COMPLAINT_SALE_DETAILS && payLoadInfo.COMPLAINT_SALE_DETAILS.length > 0
            ? payLoadInfo.COMPLAINT_SALE_DETAILS.map((item) => ({
              agentCode: item?.agentCode ?? '',
              agentName: item?.agentName ?? '',
              financeNeedCode: item?.financeNeedCode ?? '',
              isPoSignedInProposal: item?.isPoSignedInProposal as boolean,
              isCustomerReceiveKit: item?.isCustomerReceiveKit as boolean,
              customerReceiveDate: item?.customerReceiveDate ?? '',
              evidence: item?.evidence as boolean
            }))
            : []
        const COMPLAINT_GA_DETAILS =
          payLoadInfo.COMPLAINT_GA_DETAILS && payLoadInfo.COMPLAINT_GA_DETAILS.length > 0
            ? payLoadInfo.COMPLAINT_GA_DETAILS.map((item) => ({
              generalAgency: item?.generalAgency ?? '',
              personComplaint: item?.personComplaint ?? '',
              role: item?.role ?? '',
              verifiedAmount: item?.verifiedAmount ?? 0,
              evidenceStatus: item?.evidenceStatus ?? ''
            }))
            : []
        const submissionData: InquiryComplaintData.SubmitData = {
          ...payLoadInfo,
          FCR: Boolean(payLoadInfo.FCR),
          HOT: Boolean(payLoadInfo.HOT),
          TOTAL_MIS_MONEY_AMT: payLoadInfo.TOTAL_MIS_MONEY_AMT ?? 0,
          SOURCE: payLoadInfo.SOURCE ?? '',
          METHOD: payLoadInfo.METHOD ?? '',
          RLA_WITH_PO: payLoadInfo.RLA_WITH_PO ?? '',
          GROUP_CODE: payLoadInfo.GROUP_CODE ?? '',
          REQUESTER_FULL_NAME: payLoadInfo.REQUESTER_FULL_NAME ?? '',
          REQUEST_WITH_ROLE: payLoadInfo.REQUEST_WITH_ROLE ?? '',
          REQUESTER_NATIONAL_ID: payLoadInfo.REQUESTER_NATIONAL_ID ?? '',
          CUSTOMER_CMT: payLoadInfo.CUSTOMER_CMT ?? '',
          RES_TO_CUSTOMER: payLoadInfo.RES_TO_CUSTOMER ?? '',
          TOUCH_POINT: '',
          CATEGORY_CODE: payLoadInfo.CATEGORY_CODE ?? '',
          SUB_SVC_TYPE_CODE: payLoadInfo.SUB_SVC_TYPE_CODE ?? '',
          SUB_TRANS_TYPE_CODE: payLoadInfo.SUB_TRANS_TYPE_CODE ?? '',
          COMPLAINT_MIS_MONEY_DETAILS: COMPLAINT_MIS_MONEY_DETAILS,
          COMPLAINT_SALE_DETAILS: COMPLAINT_SALE_DETAILS,
          COMPLAINT_GA_DETAILS: COMPLAINT_GA_DETAILS,
          CONSERVATION_INFO: payLoadInfo.CONSERVATION_INFO || undefined,
          CLOSED_INFO: payLoadInfo.CLOSED_INFO ?? '',
          TOTAL_VERIFIED_AMOUNT: payLoadInfo.TOTAL_VERIFIED_AMOUNT ?? 0,
          CALL_ID: payLoadInfo.CALL_ID ?? ''
        }

        const documents =
          !!responseData.payload.documents && responseData.payload.documents.length > 0
            ? responseData.payload.documents.map((item) => ({
              name: item.name ?? '',
              url: item.url ?? ''
            }))
            : []
        const additionalDocuments = !!responseData.payload.additionalDocuments
          ? responseData.payload.additionalDocuments.map((item) => {
            const attachmentList = !!item.attachmentList
              ? item.attachmentList.map((subItem) => {
                const uploadedDate =
                  !!subItem && !!subItem.uploadedDate ? new Date(subItem.uploadedDate) : new Date()
                // const tagItem = subItem.tag ? (subItem.tag === 'Cloud' ? 'Cloud' : 'Local' ) : 'Local'
                const attachmentItem: CloudData = {
                  tag: 'Cloud',
                  fileName: subItem.fileName ?? '',
                  fileExtension: subItem.fileExtension ?? '',
                  size: Number(subItem.size ?? 0),
                  base64: subItem.base64 ?? '',
                  uploadedDate: uploadedDate,
                  url: subItem.url ?? '',
                  blobInfo: subItem.url ?? '',
                  storageConfig: {
                    sas: subItem.storageConfig?.sas ?? ''
                  }
                }
                return attachmentItem
              })
              : []
            return {
              transactionType: item.transactionType ?? '',
              attachmentList: attachmentList
            }
          })
          : undefined
        const veriPayloadData: VeriPayloadData = {
          source: responseData.payload.source ?? '',
          createdBy: responseData.payload.createdBy ?? '',
          documents: documents,
          primaryPolicyNo: responseData.payload.primaryPolicyNo ?? '',
          clientId: responseData.payload.clientId ?? '',
          officeCode: responseData.payload.officeCode ?? '',
          createdDate: responseData.payload.createdDate ?? '',
          body: {
            attributesExt: submissionData,
            auditDetail: {
              createTime: responseData.payload.body.auditDetail?.createTime ?? ''
            }
          },
          additionalDocuments: additionalDocuments
        }
        return veriPayloadData
      })
    )
}
