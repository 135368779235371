import { PayoutPopup } from '@pulseops/common'
import * as t from 'io-ts'
import { DateOfDeathForm } from '../../date-of-death'

export namespace PrucashPaymentForm {
  const PrucashPayment = t.intersection([
    t.type({
      isPoPassAway: t.boolean,
      payout: t.array(PayoutPopup.SummaryCodec)
    }),
    t.union([
      t.intersection([t.type({ isPoPassAway: t.literal(true) }), DateOfDeathForm.codec]),
      t.type({ isPoPassAway: t.literal(false) })
    ])
  ])

  export type PrucashPayment = t.TypeOf<typeof PrucashPayment>

  export const codec = PrucashPayment

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
