import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace SubmissionReportModal {
  export enum Category {
    ALL = 'ALL',
    COMPLAINT = 'CH',
    INQUIRY = 'CS',
    POLICY_SERVICE = 'PS',
    CLAIM_SERVICES = 'CL',
    GENERAL = 'GI',
    PREMIUM_COLLECTION = 'PC'
  }

  export enum Order {
    ASC = 'asc',
    DESC = 'desc'
  }

  export interface QueryData {
    category: Category
    fromDate: string //2021/07/01
    toDate: string
    order: Order
    size: number
    sort: string
    start: number
  }

  export const ReportData = t.array(
    t.type({
      activity: Maybe(t.string),
      assignee: Maybe(t.string),
      businessKey: t.string,
      category: t.string,
      closedDate: Maybe(t.string),
      createdDate: t.string,
      lastUpdateUser: Maybe(t.string),
      policyNum: t.string,
      processInstanceId: t.string,
      requestUser: t.string,
      serviceCode: Maybe(t.string),
      serviceTypeEn: Maybe(t.string),
      serviceTypeVn: Maybe(t.string),
      source: t.string,
      status: t.string,
      transactionType: t.string,
      subServiceTypeCode: t.string,
      subTransactionCode: t.string,
      officeCode: t.string,
      userGroup: Maybe(t.string),
      request: Maybe(t.string),
      newType: Maybe(t.string),
      newSubType: Maybe(t.string)
    })
  )
  export type ReportData = t.TypeOf<typeof ReportData>

  export const ReportsData = t.type({
    data: Maybe(ReportData),
    total: t.number,
    order: t.string,
    size: t.number,
    sort: t.string,
    start: t.number
  })
  export type ReportsData = t.TypeOf<typeof ReportsData>
}
