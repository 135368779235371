import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface ChangePremium {
  tag: TransactionType.CHANGE_PREMIUM

  currentInstallmentPremium: number
  currentSumAssured: number
  newInstallmentPremium: number
  requirePayInAmount: number
}

export const ChangePremium = (detail: api.ChangePremium & api.TaskDetailBase): ChangePremium => {
  const payload = detail.payload.body

  return {
    tag: TransactionType.CHANGE_PREMIUM,

    currentInstallmentPremium: payload.currentInstallmentPremium,
    currentSumAssured: payload.currentSumAssured,
    newInstallmentPremium: payload.newInstallmentPremium,
    requirePayInAmount: payload.requirePayInAmount
  }
}
