import {
  formatNumberWithComma,
  FundCode,
  Input,
  InputTable,
  RadioButtonGroup,
  Title,
  TopUp,
  TopUpConst
} from '@pulseops/common'
import React from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'
import { TopUpFormData } from '../top-up.form'

export interface TopUpILPProps {
  formILP: UseFormReturn<TopUpFormData.RequestFormValue>
  topUpDetail: TopUp.Detail
  isDisable: boolean
}

export const TopUpILPScreen: React.FC<TopUpILPProps> = ({ formILP, topUpDetail, isDisable }) => {
  const { t, i18n } = useTranslation()
  const { control, setValue, setError, watch } = formILP
  const { fields, append, update, remove } = useFieldArray({
    control: control,
    name: 'fundList'
  })
  const topUpOptionList = [
    { id: 'ByPercentage', label: t('requestInfo:ByPercentage') },
    { id: 'ByAmount', label: t('requestInfo:ByAmount') }
  ]
  // const [amountByPercent, setAmoutByPercent] = React.useState<number>(0)

  const getFundLabel = (fundItem: { fundCode: FundCode; desVi: string; desEn: string }) => {
    return i18n.language === 'en' ? fundItem.desEn : fundItem.desVi
  }

  React.useMemo(() => {
    setValue('suspenseAmount', topUpDetail.suspendAmount ?? 0)
    if (topUpDetail && topUpDetail.coverageCode) {
      remove()
      console.log('suspenseAmount: 1')
      TopUpConst.FundList.filter(
        (x) =>
          (topUpDetail.coverageCode !== 'ULR6' && x.fundCode.includes('1')) ||
          (topUpDetail.coverageCode === 'ULR6' && x.fundCode.includes('2'))
      ).map((fund) => {
        append({
          fundName: getFundLabel(fund),
          fundCode: fund.fundCode,
          percentage: 0,
          amount: 0,
          amountByPercent: 0
        })
      })
    }
  }, [topUpDetail, i18n.language])

  const onChangeTopUpAmountEvent = (amount: number) => {
    updateAllAmountByPercent()
    if (amount > topUpDetail.maxTopUp) {
      setError('topUpAmount', {
        message: `${t('message:MS050266', {
          min: formatNumberWithComma(topUpDetail.minTopUp),
          max: formatNumberWithComma(topUpDetail.maxTopUp)
        })}`
      })
    } else if (amount < topUpDetail.minTopUp) {
      setError('topUpAmount', {
        message: `${t('message:MS050266', {
          min: formatNumberWithComma(topUpDetail.minTopUp),
          max: formatNumberWithComma(topUpDetail.maxTopUp)
        })}`
      })
    } else {
      setError('topUpAmount', {
        message: ''
      })
    }

    const requirePayin = amount - watch('suspenseAmount') > 0 ? amount - watch('suspenseAmount') : 0
    setValue('requirePayinAmount', requirePayin)
  }

  const updateAllAmountByPercent = () => {
    const topUpOPT = watch('topUpOption')
    watch('fundList').forEach((fundItem, index) => {
      if (topUpOPT === TopUpConst.TopUpOption.ByPercentage && fundItem.percentage > 0) {
        onChangePercentageEvent(String(fundItem.percentage), index)
      }
    })
  }

  const onChangePercentageEvent = (percentage: string, index: number) => {
    if (!!percentage && Number(percentage) % 5 !== 0) {
      setError(`fundList.${index}.percentage`, {
        message: t('message:MS050041')
      })
    } else if (!!percentage && (isNaN(Number(percentage)) || Number(percentage) < 1 || Number(percentage) > 100)) {
      setError(`fundList.${index}.percentage`, {
        message: t('message:MS050041')
      })
    } else {
      setError(`fundList.${index}.percentage`, {
        message: ''
      })
    }
    const amountValue = (Number(percentage ?? 0) / 100) * watch('topUpAmount')
    setValue(`fundList.${index}.amountByPercent`, amountValue)
  }

  const onChangeTopUpOptionEvent = (option: string) => {
    if (option === TopUpConst.TopUpOption.ByAmount) {
      clearFundPercentage()
    } else {
      clearFundAmount()
    }
  }

  function clearFundPercentage() {
    fields.forEach((fieldItem, index) => {
      update(index, {
        ...fieldItem,
        percentage: 0
      })
    })
  }

  function clearFundAmount() {
    fields.forEach((fieldItem, index) => {
      update(index, {
        ...fieldItem,
        amount: 0
      })
    })
  }

  const formatPercentAmountWithComa = (value: number) => {
    const roundValue = value.toFixed()
    return formatNumberWithComma(roundValue)
  }

  return (
    <View>
      <Title title={t('requestInfo:TOP_UP_INFORMATION')}></Title>
      <View style={topUpILPStyles.sectionContent}>
        <View style={topUpILPStyles.sectionRow}>
          <View style={topUpILPStyles.col_4}>
            <Controller
              control={control}
              name="topUpAmount"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('requestInfo:TopUpAmount') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  onChange={(val) => {
                    const amountVal = val.replace(/[^0-9]/g, '')
                    onChange(amountVal)
                    onChangeTopUpAmountEvent(Number(amountVal))
                  }}
                  onBlur={onBlur}
                  value={formatNumberWithComma(value)}
                  title={t('requestInfo:TopUpAmount')}
                  inputType={'number'}
                  required={true}
                  alwayShowUnderline={true}
                  isAllowComma={true}
                  disabled={isDisable}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={topUpILPStyles.col_4}>
            <Controller
              control={control}
              name="suspenseAmount"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('requestInfo:SuspenseAmount') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={true}
                  value={formatNumberWithComma(value)}
                  alwayShowUnderline={true}
                  title={t('requestInfo:SuspenseAmount')}
                  inputType={'number'}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={topUpILPStyles.col_4}>
            <Controller
              control={control}
              name="requirePayinAmount"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('requestInfo:RequirePayinAmount') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={true}
                  value={formatNumberWithComma(value)}
                  alwayShowUnderline={true}
                  title={t('requestInfo:RequirePayinAmount')}
                  inputType={'number'}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
        <View style={[topUpILPStyles.sectionRow, topUpILPStyles.secondLine]}>
          <View style={topUpILPStyles.col_4}>
            <Controller
              control={control}
              name="topUpOption"
              render={({ field: { onChange, onBlur, value } }) => (
                <RadioButtonGroup
                  title={t('requestInfo:TopUpOption')}
                  colLength={'100%'}
                  onChange={(valItem) => {
                    onChange(valItem)
                    onChangeTopUpOptionEvent(valItem)
                  }}
                  onBlur={onBlur}
                  value={value}
                  required={true}
                  disabled={isDisable}
                  options={topUpOptionList}
                  // errorMessage={''}
                />
              )}
            />
          </View>
        </View>
        <View>
          <Text>{t('requestInfo:FundList')}</Text>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Text style={{ fontStyle: 'italic' }}>{t('requestInfo:Currency')} : VND</Text>
          </View>
          {/* topup table */}
          <View style={{ display: 'flex' }}>
            <View>
              {/* table header */}
              <View>
                {/* table row */}
                <View style={{ flexDirection: 'row' }}>
                  <View style={topUpILPStyles.tableHeaderColumn4}>
                    <Text style={topUpILPStyles.tableHeaderText}>{t('requestInfo:Fund')}</Text>
                  </View>
                  <View style={topUpILPStyles.tableHeaderColumn3}>
                    <Text style={topUpILPStyles.tableHeaderText}>
                      {t('requestInfo:Ratio')}
                      {watch('topUpOption') === TopUpConst.TopUpOption.ByPercentage && (
                        <Text style={topUpILPStyles.requiredText}>*</Text>
                      )}
                    </Text>
                  </View>
                  <View style={topUpILPStyles.tableHeaderColumn3}>
                    <Text style={[topUpILPStyles.tableHeaderText, { textAlign: 'right' }]}>
                      {t('TopUp:TopupAmountPerFund')}
                      {watch('topUpOption') === TopUpConst.TopUpOption.ByAmount && (
                        <Text style={topUpILPStyles.requiredText}>*</Text>
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              {/* table body */}
              <View>
                {/* table row */}
                {fields.map((fieldItem, index) => (
                  <View style={topUpILPStyles.tableBodyRow} key={index}>
                    <View style={[topUpILPStyles.tableHeaderColumn4, topUpILPStyles.justifyContentCol]}>
                      <Text>
                        {fieldItem.fundCode} - {fieldItem.fundName}
                      </Text>
                    </View>
                    <View style={[topUpILPStyles.tableHeaderColumn3, topUpILPStyles.minHeightCol]}>
                      {watch('topUpOption') === TopUpConst.TopUpOption.ByPercentage && (
                        <Controller
                          control={control}
                          name={`fundList.${index}.percentage`}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <Input
                              onChange={(val) => {
                                onChange(val)
                                onChangePercentageEvent(val, index)
                              }}
                              onBlur={onBlur}
                              value={String(value)}
                              disabled={isDisable}
                              inputType={'number'}
                              inputStyle={topUpILPStyles.inputBorder}
                              errorMessage={error?.message}
                            />
                          )}
                        />
                      )}
                    </View>
                    <View style={[topUpILPStyles.tableHeaderColumn3, topUpILPStyles.justifyContentCol]}>
                      {watch('topUpOption') === TopUpConst.TopUpOption.ByPercentage && (
                        <Text style={[{ textAlign: 'right' }]}>
                          {formatPercentAmountWithComa(watch(`fundList.${index}.amountByPercent`))}
                        </Text>
                      )}
                      {watch('topUpOption') === TopUpConst.TopUpOption.ByAmount && (
                        <Controller
                          control={control}
                          name={`fundList.${index}.amount`}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <InputTable
                              value={String(value)}
                              onChange={onChange}
                              onBlur={onBlur}
                              inputType={'money'}
                              disabled={isDisable}
                              errorMessage={error?.message}
                            />
                          )}
                        />
                      )}
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
const topUpILPStyles = StyleSheet.create({
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  tableHeaderText: {
    fontSize: 15,
    fontWeight: '700'
  },
  inputBorder: {
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8
  },
  tableBodyRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#d3dce6'
  },
  tableHeaderColumn4: {
    flex: 40,
    paddingVertical: 10,
    paddingRight: 10
  },
  tableHeaderColumn3: {
    flex: 30,
    paddingVertical: 10,
    paddingRight: 10
  },
  justifyContentCol: {
    justifyContent: 'center'
  },
  minHeightCol: {
    minHeight: 66
  },
  requiredText: {
    color: 'red',
    marginLeft: 5,
    fontSize: 14
  }
})
