import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { CashoutOptions } from './CashoutOptions'

export const FullSurrender = t.type({
  transactionType: t.literal(TransactionType.FULL_SURRENDER),
  payload: t.type({
    body: t.type({
      attributesExt: t.type({
        CASH_DEPOSIT: t.number,
        POLICY_DEBT: t.number,
        POLICY_LOAN: t.number,
        SURRENDER_CHARGE: t.number,
        SUSPENSE_AMOUNT: t.number,
        TOTAL_SURRENDER_AMOUNT: t.number
      }),
      cashOutOptions: CashoutOptions,
      policy: t.type({
        owners: t.type({
          dateOfDeath: t.string,
          attributesExt: t.type({
            HAS_INHERITANCE: t.boolean
          })
        })
      })
    })
  }),
  source: Maybe(t.string)
})
export type FullSurrender = t.TypeOf<typeof FullSurrender>
