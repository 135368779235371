import { ControlProps } from '@pulseops/common'
import React from 'react'
import { Text, StyleSheet, View, TextInput, StyleProp, ViewStyle } from 'react-native'

type OBInputProps = ControlProps<string> & {
  title?: string
  maxLength?: number
  placeholder?: string
  multiline?: boolean
  errorMessage?: string
  containerStyle?: StyleProp<ViewStyle>
}
export const OBInput = ({ title, maxLength, multiline, onChange, value, placeholder, errorMessage, containerStyle }: OBInputProps) => {
  return (
    <View style={[containerStyle]}>
      {title && (
        <View style={inputStyles.titleContent}>
          <Text style={inputStyles.titleText}>{title}</Text>
        </View>
      )}
      <View style={inputStyles.container}>
        <TextInput multiline={multiline} maxLength={maxLength} onChangeText={(text) => onChange && onChange(text)} value={value} placeholderTextColor={'#70777E'} placeholder={placeholder} style={inputStyles.inputContent} />
        {errorMessage && (
          <View>
            <Text style={inputStyles.errorText}>{errorMessage}</Text>
          </View>
        )}
      </View>

    </View>
  )
}

const inputStyles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#F6F7FA',
    paddingVertical: 9,
    paddingHorizontal: 16,
    borderColor: '#bdbec0',
    borderWidth: 1
  },
  titleContent: {
    marginBottom: 4
  },
  titleText: {
    color: '#58647A',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 18,
  },
  inputContent: {
    // width: '100%',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 23
  },
  errorText: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})