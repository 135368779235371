import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleProp, TextStyle } from 'react-native'
import { PulseOpsFormat, TaskDetail } from '@pulseops/common'
import { GridView } from './gridview'
import { ContainerView, styles } from './styles'

export const PolicyInfoView = (props: { detail: TaskDetail.VeriCashLess }) => {
  const { detail } = props
  const { t: ca, i18n } = useTranslation('Cashless')

  const fields = React.useMemo(() => {
    // const rider = riderList.find((e) => e.code === detail.productCode)
    // const productName = [detail.productCode, detail.productType].filter((s): s is string => !!s).join(' - ')
    return [
      {
        label: ca('installmentPremium'),
        value: PulseOpsFormat.currency(detail.basicPremium ?? 0) + ' VND'
      },
      {
        label: ca('product'),
        // value: productName
        value: `${detail.productCode} - ${i18n.language === 'en' ? detail.productName?.en : detail.productName?.vi}`
      },
      {
        label: '',
        value: ''
      },
      {
        label: ca('lastestBilling'),
        value: detail.lastPremiumDate
      },
      {
        label: ca('lastAPLDate'),
        value: detail.loanStarDate
      },
      {
        label: ca('latestAppliedPremium'),
        value: detail.lastTransactionDate
      },
      {
        label: ca('excessPremium'),
        value: PulseOpsFormat.currency(detail.amountLP ?? 0) + ' VND'
      },
      {
        label: ca('policyLoanPrincipal'),
        value: PulseOpsFormat.currency(detail.lo ?? 0) + ' VND'
      },
      {
        label: ca('aplPrinciple'),
        value: PulseOpsFormat.currency(detail.ao ?? 0) + ' VND'
      },
      {
        label: ca('discountPremium'),
        value: PulseOpsFormat.currency(detail.discount ?? 0) + ' VND',
        valueStyle: {
          fontWeight: detail.discount > 0 ? 'bold' : 'normal'
        } as StyleProp<TextStyle>
      },
      {
        label: ca('policyLoanInterest'),
        value: PulseOpsFormat.currency(detail.li ?? 0) + ' VND'
      },
      {
        label: ca('aplInterest'),
        value: PulseOpsFormat.currency(detail.ai ?? 0) + ' VND'
      },
      {
        label: ca('InsPremium'),
        value: PulseOpsFormat.currency(detail.InsPremium ?? 0) + ' VND'
      }
    ]
  }, [i18n])

  return (
    <View>
      <View style={{ marginTop: detail.hasVoucher ? 50 : 15 }}>
        <Text style={styles.sessonText}>{ca('policyInfo')}</Text>
      </View>
      <ContainerView>
        <GridView data={fields} />
      </ContainerView>
    </View>
  )
}
