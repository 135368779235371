import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { AuthService, POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export const AssignmentData = Maybe(t.array(
  t.type({
    status: t.string,
    callProgram: t.string
  })
))

export type AssignmentData = t.TypeOf<typeof AssignmentData>

export const AssignmentResponse = t.type({
  email: t.string,
  assignmentStatuses: Nullable(t.array(
    t.type({
      status: t.string,
      callProgram: t.string
    })
  ))
})

export type AssignmentResponse = t.TypeOf<typeof AssignmentResponse>

export namespace OBAssignmentService {
  export const getAssigneeState = (userEmail: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/outbound/assignment/status?email=${userEmail}`)(AssignmentData),
      ZIO.map((res) => res)
    )

  export const changeAssignee = (assignmentStatuses: { status: string, callProgram: string }[]) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((currentUser) =>
        POApi.post(`pulseops/api/v1/outbound/assignment/status`)(AssignmentResponse)({ email: currentUser.email, assignmentStatuses: assignmentStatuses })),
      ZIO.map((res) => res)
    )

  export const checkStatus = (email: string, callProgram: string) => 
    pipe(
      POApi.get(`pulseops/api/v1/outbound/assignment/status?email=${email}`)(AssignmentData),
      ZIO.map((res) => res && res.find((item) => item.callProgram === callProgram)?.status )
  )

  export const changeAssignmentByLeader = (assignmentStatuses: { status: string, callProgram: string }[], mail: string, emailManager: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/outbound/assignment/status`)(AssignmentResponse)({ email: mail, assignmentStatuses: assignmentStatuses, emailManager: emailManager }),
      ZIO.map((res) => res)
    )

  export const getAssigneeStateByLeader = (mail: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/outbound/assignment/status?email=${mail}`)(AssignmentData),
      ZIO.map((res) => res)
    )

  export const getAuthorizationCallTypeListForUser = (userEmail: string) => pipe(
    POApi.get(`pulseops/api/v1/outbound/assignment/authorized-transactions?email=${userEmail}`)(t.array(t.string)),
    ZIO.map((response)=> response)
  )
}
