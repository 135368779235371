export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD'

export enum DashboardRefreshOptions {
  REFRESH_ALL_BASKET = 'REFRESH_ALL_BASKET', // Just refresh task list
  REFRESH_TASK_GROUP = 'REFRESH_TASK_GROUP', // Refresh task count and task list
  REFRESH_TEAM_TASK = 'REFRESH_TEAM_TASK',
  REFRESH_ALL_BASKET_AFTER_TRANSFER = 'REFRESH_ALL_BASKET_AFTER_TRANSFER'
}

// Just refresh task list for active tab in dashboard(My Task tab or Team task tab)
export const requestRefreshAllBasketInDashboard = () => {
  localStorage.setItem(REFRESH_DASHBOARD, DashboardRefreshOptions.REFRESH_ALL_BASKET)
}

// Refresh task count and task list for active tab in dashboard(My Task tab or Team task tab)
export const requestRefreshAllInDashboard = () => {
  localStorage.setItem(REFRESH_DASHBOARD, DashboardRefreshOptions.REFRESH_TASK_GROUP)
}

// Refresh task count and task list for Team Task
export const requestRefreshTeamTask = () => {
  localStorage.setItem(REFRESH_DASHBOARD, DashboardRefreshOptions.REFRESH_TEAM_TASK)
}

export const requestRefreshDashboardAfterTranfer = () => {
  localStorage.setItem(REFRESH_DASHBOARD, DashboardRefreshOptions.REFRESH_ALL_BASKET_AFTER_TRANSFER)
}

export const isRefreshAllBasketInDashboard = () => {
  const option = localStorage.getItem(REFRESH_DASHBOARD)
  return option === DashboardRefreshOptions.REFRESH_ALL_BASKET
}

export const isRefreshAllInDashboard = () => {
  const option = localStorage.getItem(REFRESH_DASHBOARD)
  return option === DashboardRefreshOptions.REFRESH_TASK_GROUP
}

export const isRefreshTeamTaskTab = () => {
  const option = localStorage.getItem(REFRESH_DASHBOARD)
  return option === DashboardRefreshOptions.REFRESH_TEAM_TASK
}

export const isRefreshDashboardAfterTransfer = () => {
  const option = localStorage.getItem(REFRESH_DASHBOARD)
  return option === DashboardRefreshOptions.REFRESH_ALL_BASKET_AFTER_TRANSFER
}

export const refreshDashboardHandled = () => {
  localStorage.removeItem(REFRESH_DASHBOARD)
}
