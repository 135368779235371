import * as React from 'react'
import { View, Text, TextInput, StyleSheet, TextStyle, StyleProp, ViewStyle } from 'react-native'
import { ControlProps } from '../FormProps'
import { formatNumberWithComma } from '../../shared'
import { formatCurrencyWithFraction, formatStringToDecimalNumber, getNumberFromString } from '../../service'

const numberRegExp = new RegExp('^[0-9]*$')

const formatInput = (inputType: 'text' | 'number' | 'money' = 'text', value: string, defaultValue = '') => {
  let output = defaultValue
  if (value.length === 0) {
    return output
  }

  if (inputType === 'number') {
    const isNumber = value.match(numberRegExp) !== null
    output = isNumber ? value : defaultValue
  } else if (inputType === 'money') {
    value = [...value].filter((item) => !isNaN(Number(item))).join('')
    const isNumber = value.match(numberRegExp) !== null
    output = isNumber && Number(value) > -1 ? value : defaultValue
  } else {
    output = value
  }
  return output
}

type Props = ControlProps<string> & {
  placeholder?: string
  title?: string
  labelStyle?: TextStyle
  disabled?: boolean
  required?: boolean
  alwayShowUnderline?: boolean
  errorMessage?: string
  maxLength?: number
  numberOfLines?: number
  multiline?: boolean
  textAlign?: 'left' | 'right' | 'center' | 'auto' | 'justify'
  suffix?: string | JSX.Element
  inputType?: 'text' | 'number' | 'money'
  containerStyle?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<ViewStyle & TextStyle>
  isAllowComma?: boolean
  isFractionFormat?: boolean
  filled?: boolean
  refs?: React.LegacyRef<TextInput>
  onFocus?: () => void
  isAllowSpecialChar?: boolean
  regexParten?: RegExp
}

export const Input = (props: Props) => {
  const {
    title,
    value,
    onChange,
    onBlur,
    onKeyEnter,
    required,
    alwayShowUnderline = false,
    errorMessage,
    disabled,
    labelStyle,
    maxLength,
    textAlign,
    numberOfLines,
    multiline,
    suffix,
    inputType = 'text',
    containerStyle,
    inputStyle,
    placeholder,
    isAllowComma,
    isFractionFormat,
    filled,
    refs,
    onFocus,
    isAllowSpecialChar,
    regexParten
  } = props

  const [text, setText] = React.useState<string>(() => {
    return formatInput(inputType, value || '')
  })

  React.useEffect(() => {
    if (!value) {
      setText('')
    }
  }, [value])

  return (
    <View style={containerStyle}>
      {title && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }, labelStyle]}>
            {props.title}
          </Text>
          {required && <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>}
        </View>
      )}
      <View style={suffix ? { position: 'relative' } : {}}>
        <TextInput
          ref={refs}
          style={
            disabled && !alwayShowUnderline
              ? [
                  styles.common,
                  inputStyle,
                  { textAlign: textAlign, paddingRight: suffix ? 35 : 0, borderBottomWidth: 0 }
                ]
              : [
                  styles.common,
                  inputStyle,
                  { paddingRight: suffix ? 35 : 0 },
                  multiline ? styles.outLine : styles.dashline,
                  filled && styles.outLine
                ]
          }
          // textAlign = {textAlign}
          // value={inputType === 'money' ? formatNumberWithComma(text, '') : value}
          value={
            inputType === 'money'
              ? isFractionFormat === true
                ? formatCurrencyWithFraction(value ?? '')
                : formatNumberWithComma(text, '')
              : value
          }
          onChangeText={(value) => {
            if (inputType === 'money') {
              const output =
                value.length === 0
                  ? ''
                  : !isFractionFormat
                  ? formatInput(inputType, value, text)
                  : getNumberFromString(formatStringToDecimalNumber(value))
              setText(output)
              onChange && onChange(output)
            } else {
              if (
                isAllowComma &&
                inputType === 'number' &&
                value.length > 0 &&
                value.indexOf(',') <= 0 &&
                !value.match(/^[0-9]+$/)
              ) {
                return
              } else if (!isAllowComma && inputType === 'number' && value.length > 0 && !value.match(/^[0-9]+$/)) {
                return
              }
              onChange && onChange(value)
            }
          }}
          onBlur={onBlur}
          onKeyPress={(event) => {
            if (!isAllowSpecialChar && regexParten && event.nativeEvent.key.match(regexParten)) {
              event.preventDefault()
              return
            }
            if (onKeyEnter && event.nativeEvent.key == 'Enter') {
              onKeyEnter()
            }
          }}
          editable={!disabled}
          maxLength={maxLength}
          numberOfLines={numberOfLines}
          multiline={multiline}
          placeholder={placeholder}
          placeholderTextColor={'#70777E'}
          // selectTextOnFocus={!disabled}
          // autoFocus
          onFocus={onFocus}
        />
        <View style={suffix ? { position: 'absolute', right: 1, top: 7 } : {}}>
          <Text>{suffix}</Text>
        </View>
      </View>
      {(!disabled || alwayShowUnderline) && !filled && !multiline && <View style={styles.underline} />}
      {errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errorMessage}</Text> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  common: {
    minHeight: 33,
    fontSize: 15
    // lineHeight: 20
  },
  outLine: {
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8
  },
  dashline: {},

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  }
})
