import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  AmlResult,
  AmlService,
  assets,
  Button,
  DetailNav,
  DetailService,
  HistoryType,
  RefreshButton,
  Shareholder,
  ShareholderC,
  UtilRow,
  View
} from '@pulseops/business/core'
import { didUpdate, ErrorHandling, Permission, RBAC, subscribe } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/native'
import { EditHistoryModalService } from '../../../edit-history-modal-service'
import { ContractDetailContext } from '../../contract-detail-context'
import { ShareholderDetailEdit } from './shareholder-detail-edit'
import { ShareholderDetailView } from './shareholder-detail-view'

type Props = {
  shareHolderKey: string
  onClose: () => void
}

export const ShareholderDetail = ({ shareHolderKey, onClose }: Props) => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const [refreshLoading, bindRefreshLoading] = useLoading(false)
  const [amlLoading, bindAmlLoading] = useLoading(false)
  const [isEdit, setIsEdit] = React.useState(false)

  const detail: Shareholder | null = pipe(
    didUpdate([shareHolderKey]),
    ZStream.switchMap(([shareHolderKey]) => service.state.shareholder.item(shareHolderKey).watch),
    subscribe()
  )

  EditHistoryModalService.useHistory(service.state.history.clear)

  const history: Shareholder | null = pipe(
    EditHistoryModalService.viewing.stream,
    ZStream.chainEffect((item) =>
      pipe(
        service.state.shareholder.item(shareHolderKey).get,
        ZIO.map((detail) =>
          pipe(
            item,
            O.fromNullable,
            O.filter((item) => !!detail && item.version < detail.version),
            O.chain((item) => pipe(item, ShareholderC.decode, O.fromEither)),
            O.toNullable
          )
        )
      )
    ),
    subscribe()
  )

  const refresh = () => {
    pipe(service.refreshShareholder(shareHolderKey), bindRefreshLoading, ErrorHandling.run())
  }

  const scanAml = () => {
    if (!detail) {
      return
    }
    pipe(
      AmlService.scanShareholder({
        uuid: detail.uuid,
        fullName: detail.fullName,
        dateOfBirth: detail.dateOfBirth,
        gender: detail.gender,
        idNumber: detail.idNumber,
        nationality: detail.nationality,
        permanentAddress: detail.permanentAddress,
        residentAddress: detail.residentAddress,
        position: detail.position,
        uniqueIdentifierAml: detail.uniqueIdentifierAml ?? '',
        amlResult: AmlResult.formatLatest(detail.historyAmlResult).result,
        clientId: detail.idNumber
      }),
      bindAmlLoading,
      ErrorHandling.run()
    )
  }

  if (!detail) {
    return null
  }

  return (
    <>
      <UtilRow>
        <Button
          title={t('edit_history')}
          type="outline"
          icon={<assets.IconTime />}
          onPress={() => {
            EditHistoryModalService.viewHistory({
              historyType: HistoryType.Shareholder,
              masterContractNo: contractId.masterContractNo,
              policyYear: contractId.policyYear.toString(),
              shareHolderKey
            })
          }}
          disabled={isEdit || refreshLoading || amlLoading}
        />
        {roles.includes(Permission['EditShareholderGroupDetailGeneralInfo']) && (
          <Button
            title={t('edit')}
            type="outline"
            icon={<assets.IconEdit />}
            onPress={() => {
              setIsEdit(true)
            }}
            disabled={isEdit || refreshLoading || amlLoading || !!history}
          />
        )}
        <Button
          title={t('update_aml')}
          type="outline"
          icon={<assets.IconPaper />}
          onPress={scanAml}
          loading={amlLoading}
          disabled={isEdit || refreshLoading || !!history}
        />
        <RefreshButton onPress={refresh} loading={refreshLoading} disabled={isEdit || amlLoading || !!history} />
      </UtilRow>

      <SC.Content>
        <DetailNav parentTitle={t('shareholder_list')} childTitle={t('shareholder_info')} onPressParent={onClose} />

        {isEdit ? (
          <ShareholderDetailEdit
            shareHolderKey={shareHolderKey}
            onClose={() => {
              setIsEdit(false)
            }}
          />
        ) : (
          <ShareholderDetailView detail={history || detail} />
        )}
      </SC.Content>
    </>
  )
}

const SC = {
  Content: styled(View)`
    padding-left: 2rem;
    padding-right: 2rem;
  `
}
