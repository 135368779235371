import { form2, Input, sharedStyle, TransactionType } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps } from './policy-service-props'
import * as t from 'io-ts'
import * as E from 'fp-ts/Either'
import { Controller } from 'react-hook-form'
import i18next from 'i18next'

const FormData = t.type({
  reason: form2.string.requiredM(() =>
    i18next.t('message:MS020001', {
      field: i18next.t('submission:Reason')
    })
  )
})
type FormData = t.TypeOf<typeof FormData>

export const PolicyCertificate = (props: PolicyServiceProps<FormData>) => {
  const { t } = useTranslation()

  const defaultValues = {
    reason: ''
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })

  props.initSubmission({
    validate: async () => {
      const data = await handleSubmit(() => undefined)()
      if (E.isRight(data)) {
        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/policy-certificate`,
          body: {
            reason: data.right.reason,
            clientId: props.clientNumber,
            office: ''
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.POLICY_CERTIFICATE),
          collerationId: props.policyNumber ?? '',
          transaction: TransactionType.POLICY_CERTIFICATE
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset(defaultValues)
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:LetterRequest')}</Text>
        <View style={sharedStyle.sectionContent}>
          <Controller
            name="reason"
            control={base.control}
            render={({ field: { onChange, value } }) => (
              <Input
                required
                title={t('submission:Reason')}
                value={value ?? undefined}
                maxLength={500}
                onChange={onChange}
                disabled={props.isConfirmed}
                errorMessage={base.formState.errors.reason?.message}
                placeholder={t('submission:ReasonPlaceHolder')}
              />
            )}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}
