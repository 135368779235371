import React from 'react'
import { View, Text, ViewStyle } from 'react-native'
import { useMobile } from '@pulseops/common'
export const PolicyInfoHeader: React.FC<{ menus: { value: string; label: string; icon?: JSX.Element }[], bg?: string, headerContainerStyle?: ViewStyle }> = ({
  menus,
  bg = '#E5EAEF',
  headerContainerStyle
}) => {
  const { isMobile } = useMobile()
  const composeWidthItem = isMobile ? '100%' : '33%'
  const composeAlign = isMobile ? 'flex-start' : 'center'
  return (
    <View
      style={[{
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: bg,
        margin: 20,
        borderRadius: 8,
        flexWrap: 'wrap'
      }, headerContainerStyle]}
    >
      {menus.map((m, i) => {
        const { value, label, icon } = m
        return (
          <View
            key={i}
            style={{
              flexDirection: 'row',
              justifyContent: composeAlign,
              alignItems: 'center',
              paddingVertical: 25,
              width: composeWidthItem,
              minWidth: 150,
              alignSelf: composeAlign
            }}
          >
            {icon && (
              <View style={{ paddingHorizontal: 10 }}>
                {icon}
                <Text style={{ fontSize: 17 }}>{`${label}: `}</Text>
                <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'red' }}>{value}</Text>
              </View>
            )}
            {!icon && (
              <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 33 }}>
                <Text style={{ fontSize: 17, lineHeight: 22 }}>{label}</Text>
                <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'red', lineHeight: 22 }}>{value}</Text>
              </View>
            )}
          </View>
        )
      })}
    </View>
  )
}
