import React from 'react'
import { SelectOption, ReportPermission, TaskService, ErrorActivity } from '@pulseops/common'
import { formatPeriodTime, ErrorHandlerTable } from '@pulseops/task'
import { ScrollView, View } from 'react-native'
import styled from 'styled-components/native'
import { useForm } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { TablePagination } from '@material-ui/core'

import { SearchFields } from './SearchFields'

interface Props {
  permissions: string[],
  active: boolean
}

interface SearchFormData {
  policyNumber: string | undefined
  currentActivity: SelectOption
  category: SelectOption
  fromDate: Date | null
  toDate: Date | null
}

export const MissCaseID = (props: Props) => {
  const { permissions, active } = props

  const [isLoading, bindLoading] = useLoading(false)
  const { t } = useTranslation(['Reports', 'common'])

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageIndex, setPageIndex] = React.useState<number>(0)
  const [dataSource, setDataSource] = React.useState<ErrorActivity.TaskOp[]>([])
  const [count, setCount] = React.useState<number>(0)

  const getPrevious6MonthsDay = (date = new Date()) => {
    let previous6MonthsDay = new Date(date.getTime())
    previous6MonthsDay.setMonth(date.getMonth() - 6)
    return previous6MonthsDay
  }

  const searchForm = useForm<SearchFormData>({
    defaultValues: {
      policyNumber: undefined,
      currentActivity: undefined,
      category: undefined,
      fromDate: getPrevious6MonthsDay(),
      toDate: new Date()
    }
  })

  const { control, watch } = searchForm

  let policyNumber = watch('policyNumber')
  let currentActivity = watch('currentActivity')
  let category = watch('category')
  let fromDate = watch('fromDate')
  let toDate = watch('toDate')

  React.useEffect(() => {
    if (permissions.includes(ReportPermission.SearchErrorHandlerReports) && active) {
      onViewReport(pageIndex, pageSize)
    }
  }, [permissions, active])

  const onViewReport = (pageIndex: number, pageSize: number) => {
    pipe(
      TaskService.getHistoricProcessInstances({
        filter: {
          policyNum: policyNumber ? policyNumber.trim() : undefined,
          ...(fromDate && toDate && formatPeriodTime(fromDate, toDate))
        },
        category: category?.value,
        activities: currentActivity?.value ? [currentActivity.value] : undefined,
        businessKeyEmpty: true,
        pagination: { pageIndex, pageSize }
      }),
      ZIO.tap((response) => {
        setDataSource(response.items)
        setCount(response.total)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  return (
    <SC.Container>
      {permissions.includes(ReportPermission.ViewErrorHandlerReports) && (
        <SearchFields
          control={control}
          permissions={permissions}
          onViewReport={() => onViewReport(pageIndex, pageSize)}
        />
      )}
      {permissions.includes(ReportPermission.SearchErrorHandlerReports) && (
        <SC.TableContainer>
          <ErrorHandlerTable dataSource={dataSource} isLoading={isLoading} topSpace={470} />
          <TablePagination
            page={pageIndex}
            rowsPerPage={pageSize}
            count={count}
            onPageChange={(e, page) => {
              setPageIndex(page)
              onViewReport(page, pageSize)
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageIndex(0)
              onViewReport(0, Number(e.target.value))
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </SC.TableContainer>
      )}
    </SC.Container>
  )
}

const SC = {
  Container: styled(ScrollView)`
    width: 100%;
    padding: 15px;
  `,
  TableContainer: styled(View)`
    margin-top: 20px;
    margin-bottom: 20px;
  `
}
