import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import {
  assets,
  ErrorHandling,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  SelectOption,
  AppContext
} from '@pulseops/common'
import { ComplainInquiryForm } from '../../../../../submission/src/complain-inquiry/complain-inquiry-form'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { IBGeneralField, IBStyles, TypeInputComponent } from '../../common'
import { useLoading } from '@mxt/zio-react'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail?: InquiryComplaintData.DetailData | null
  mainProductCode?: string | null
  riders?: InquiryComplaintData.IACSources[] | null
  isReset: boolean
  userInfo: {
    email: string
    name: string
  }
  setAgentCodeErr: (value: string) => void
  agentCodeError: string
}
export const IBConservation = ({
  detail,
  form,
  riders,
  mainProductCode,
  isReset,
  userInfo,
  setAgentCodeErr,
  agentCodeError
}: Props) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { control, watch, setValue, clearErrors } = form
  const [loader, bindLoader] = useLoading()

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [fullSurrenderReasonList, setFullSurrenderReasonList] = React.useState<
    InquiryComplaintData.FullSurrenderReason[]
  >([])
  const [resolutionList, setResolutionList] = React.useState<InquiryComplaintData.Resolution[]>([])

  const [isDisableBonus, setDisableBonus] = React.useState<boolean>(false)
  const [isDisableSolution, setDisableSolution] = React.useState<boolean>(false)
  let mainProductName: string = ''
  if (detail && riders && mainProductCode) {
    const mainRider = riders.find((r) => r.code === mainProductCode)
    mainProductName = mainRider ? (i18n.language === 'en' ? mainRider.name : mainRider.nameVi) : ''
  }

  const resultOptions = [
    {
      code: 'SUCCESS',
      name: 'Success',
      nameVi: 'Thành công'
    },
    {
      code: 'FAIL',
      name: 'Fail',
      nameVi: 'Không thành công'
    }
  ]

  const bonusOptions = [
    {
      code: 'BO_01',
      name: '+/- 2%',
      nameVi: 'Cộng/trừ 2%'
    },
    {
      code: 'BO_02',
      name: 'Not +/- 2%',
      nameVi: 'Không cộng/trừ 2%'
    }
  ]

  React.useEffect(() => {
    setDisableBonus(false)
    setDisableSolution(false)
  }, [isReset])

  React.useEffect(() => {
    showGlobalLoading(loader)
  }, [loader])

  const converOptions = (arr: any[]): SelectOption[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }
  //   FETCH API

  pipe(
    InquiryComplaintService.getFullSurrenderReasonList(),
    ZIO.map((list) => {
      setFullSurrenderReasonList(list)
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  )
  pipe(
    InquiryComplaintService.getResolutionList(),
    ZIO.map((list) => {
      setResolutionList(list)
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  )

  const onChangeResult = (e: SelectOption | null) => {
    setValue('conservationInfomation.conservationSolution', null)
    setValue('conservationInfomation.conservationBonus', null)
    if (e?.value === 'FAIL') {
      setValue('conservationInfomation.conservationSolution', {
        value: 'FULL_SURRENDER',
        label: i18n.language === 'en' ? 'Full surrender' : 'Hủy hợp đồng'
      })
      setDisableBonus(true)
      setDisableSolution(true)
    } else {
      setDisableSolution(false)
      if (form.getValues('conservationInfomation.conservationGAStaff') === true) {
        setDisableBonus(true)
      } else {
        setDisableBonus(false)
      }
    }
  }

  const onChangeGaStaff = (e: boolean) => {
    setValue('conservationInfomation.conservationBonus', null)
    setValue('conservationInfomation.conservationAgentCode', '')
    setValue('conservationInfomation.conservationAgentName', '')
    setAgentCodeErr('')
    clearErrors('conservationInfomation')
    if (e) {
      setDisableBonus(true)
    } else {
      if (form.getValues('conservationInfomation.conservationResult')?.value === 'FAIL') {
        setDisableBonus(true)
      } else {
        setDisableBonus(false)
      }
    }
  }

  const onChangeAgentCode = (e: string) => {
    if (e && e.length === 8) {
      pipe(
        InquiryComplaintService.getAgentInfo(e),
        ZIO.map((res) => {
          if (!!res.agentName && res.agentName !== ' ') {
            setAgentCodeErr('')
            setValue('conservationInfomation.conservationAgentName', res.agentName)
            clearErrors('conservationInfomation.conservationAgentCode')
          } else {
            setValue('conservationInfomation.conservationAgentName', '')
            setAgentCodeErr(t('message:MS100005'))
          }
        }),
        ErrorHandling.run()
      )
    } else {
      setAgentCodeErr('')
      setValue('conservationInfomation.conservationAgentName', '')
    }
  }
  return (
    <View>
      <Panel title="">
        <View style={[IBStyles.row, { marginBottom: 20 }]}>
          <View style={{ width: '32.5%' }}>
            <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>{t('Product')}</Text>
            <Text>{mainProductName}</Text>
          </View>
          <View style={{ width: '32.5%' }}>
            <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }}>{t('ServicingAgent')}</Text>
            <Text>{`${detail?.agentCode} ${detail?.agentName}`}</Text>
          </View>
        </View>
        <View>
          <IBGeneralField
            FieldForm={form}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.CHECKBOX,
                formName: 'conservationInfomation.conservationGAStaff',
                title: watch('conservationInfomation.conservationGAStaff') ? userInfo.name : t('ConservationGAStaff'),
                handleData: (value) => {
                  onChangeGaStaff(value as boolean)
                }
              },
              {
                type: TypeInputComponent.COMBINED,
                formName: 'conservationInfomation.conservationAgentCode',
                title: t('ConservationAgent'),
                inputType: 'input',
                disabled: watch('conservationInfomation.conservationGAStaff'),
                required: !watch('conservationInfomation.conservationGAStaff'),
                handleData: (value) => {
                  const newVal = value?.replace(/\D/g, '')
                  form.setValue('conservationInfomation.conservationAgentCode', newVal as string)
                  onChangeAgentCode(value as string)
                },
                errorMessage: agentCodeError,
                maxLength: 8,
                formName2: 'conservationInfomation.conservationAgentName',
                widthField1: '30%',
                widthField2: '70%'
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'conservationInfomation.reasonFullSurrender',
                title: t('ReasonForFullSurrender'),
                option: fullSurrenderReasonList.map((item) => ({
                  value: item.reasonCode,
                  label: i18n.language === 'en' ? item.reasonEN : item.reasonVN
                })),
                required: true
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'conservationInfomation.conservationResult',
                title: t('Result'),
                option: converOptions(resultOptions),
                required: true,
                handleData: (value) => {
                  onChangeResult(value)
                }
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'conservationInfomation.conservationSolution',
                title: t('Solution'),
                option:
                  watch('conservationInfomation.conservationResult')?.value !== 'FAIL'
                    ? resolutionList.map((item) => ({
                        value: item.resolutionCode,
                        label: i18n.language === 'en' ? item.resolutionEN : item.resolutionVN
                      }))
                    : converOptions([{ code: 'FULL_SURRENDER', name: 'Full surrender', nameVi: 'Hủy hợp đồng' }]),
                required: true,
                disabled: isDisableSolution
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'conservationInfomation.conservationBonus',
                title: t('Bonus'),
                option: converOptions(bonusOptions),
                required: !isDisableBonus,
                disabled: isDisableBonus
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'conservationInfomation.conservationNote',
                title: t('Note'),
                inputType: 'input',
                multiline: true,
                numberOfLines: 5,
                maxLength: 1000,
                width: '100%'
              },
              {
                type: TypeInputComponent.IMPORT,
                formName: 'conservationInfomation.attachmentFiles',
                title: t('requestInfo:Attachment'),
                required: true,
                width: '100%'
              }
            ]}
          />
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({})
