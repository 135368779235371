import { Link } from '@material-ui/core'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  ImgUploadMutiple,
  Input,
  Title,
  TransactionType,
  RiderAlterationData,
  RiderAlterationService,
  TaskType,
  FileUploadData,
  AddNewLAModal,
  LifeAssuredTable,
  AlterRiderTable,
  removeAbundantSpacesInText,
  SubmissionService,
  getNumberFromString,
  CancelRider,
  RiderAlterationFormData
} from '@pulseops/common'
import React from 'react'
import { Controller, FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
// import { RiderAlterationFormData } from './rider-alteration.form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { Error, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { RequestAuthenticateData } from '../../request-authen'
import { useIsFocused } from '@react-navigation/native'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'

export const RiderAlteration = (props: PolicyServiceProps<RiderAlterationData.SubmissionData>) => {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isShowedNewLA, setIsShowedNewLA] = React.useState<boolean>(false)
  const [newLAInfoIndex, setNewLAInfoIndex] = React.useState<number>(-1)
  const [waiverProductList, setWaiverProductList] = React.useState<string[]>([])
  const [classList, setClassList] = React.useState<RiderAlterationService.ClassItem[]>([])
  const [riderDetail, setRiderDetail] = React.useState<CancelRider.DetailCancelRiderInfo>()
  const [policyInfoData, setPolicyInfoData] = React.useState<RiderAlterationData.PolicyInfoData>()
  const policyNum = props.policyNumber ?? ''
  const officeCode = props.officeCode ?? ''
  // const riderAlterationForm = props.riderAlterationForm
  const riderAlterationForm = useForm<RiderAlterationFormData.RiderAlteration>({
    defaultValues: {
      LARiderAlterationList: [],
      newRiderLAList: [],
      healthDocuments: [],
      isAddRider: false,
      isRiderAlteration: false,
      requiredPayinAmount: ''
    }
  })

  const LAFieldList = useFieldArray({
    control: riderAlterationForm.control,
    name: 'LARiderAlterationList'
  })

  const NewRiderLAList = useFieldArray({
    control: riderAlterationForm.control,
    name: 'newRiderLAList'
  })

  const getPolicyInfo = (policyNum: string) => {
    return pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((response) =>
        pipe(
          ZIO.zipPar(
            SubmissionService.getCustomer(response.body.clientDespatchAddress ?? ''),
            SubmissionService.getOwnerInfo(policyNum)
          ),
          ZIO.map(([customerInfo, owner]) => {
            return {
              customerId: response.body?.owners.id ?? '',
              poName: owner.body.name,
              secuityNo: customerInfo.body.externalIds.SOE_VALUE ?? '',
              dob: customerInfo.body.dob ?? '',
              gender: customerInfo.body.sex ?? ''
            }
          })
        )
      )
    )
  }

  pipe(
    ZIO.zipPar(
      RiderAlterationService.getDetail(policyNum),
      getPolicyInfo(policyNum),
      RiderAlterationService.getWaiverProducts('TR517', ''),
      RiderAlterationService.getClassList('TV948')
    ),
    ZIO.flatMap(([responseData, policyInfo, waiverList, classArray]) => {
      setRiderDetail(responseData)
      setWaiverProductList(waiverList)
      setPolicyInfoData(policyInfo)
      setClassList(classArray)
      return ZIO.fromPromise(() => initData(responseData, waiverList, classArray))
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([props.policyNumber])
  )

  const initData = (
    responseData: CancelRider.DetailCancelRiderInfo,
    waiverList: string[],
    classArray: RiderAlterationService.ClassItem[]
    // riderList: SelectOption[]
  ) => {
    let riderFormArray: Array<RiderAlterationFormData.CurrentRiderForm> = []
    const LARiderItemList = responseData.laRiderInfo
    return pipe(
      LARiderItemList,
      A.map((lifeAssuredItem) => RiderAlterationService.getRiderList(policyNum, lifeAssuredItem.lifeNo ?? '')),
      ZIO.sequence,
      ZIO.map((riderNameArr) => {
        const lifeAssuredArr = LARiderItemList.map((item) => {
          const alteredRiderList = riderNameArr.find((x) => x.lifeNo === item.lifeNo)?.riderArr ?? []
          return {
            ...item,
            alteredRiderList: alteredRiderList
          }
        })
        return lifeAssuredArr
      }),
      ZIO.map((lifeAssuredList) => {
        lifeAssuredList.map((LAItem) => {
          LAItem.riders.map((rider) => {
            const riderInfoItem = responseData.riderNameList.find((x) => x.code === rider.productCode)
            rider.productName = getRiderName(riderInfoItem, i18n.language)
            const isWaiverProduct = waiverList.some((waiveritem) => waiveritem === rider.productCode)
            const classNumberList = getArrayFromUndefinedItem(classArray, rider.productCode)
            const isClassNumber = isWaiverProduct || classNumberList.length <= 0 ? false : true
            riderFormArray.push({
              riderCode: rider.productCode,
              riderName: rider.productName,
              isWaiverProduct: isWaiverProduct,
              isClassNumber: isClassNumber,
              classNumberList: isClassNumber ? classNumberList : [],
              // SelectRowForRider: false,
              riskCommDate: rider.riskCommDate,
              riskCessDate: rider.riskCessDate,
              sumAssured: rider.sumAssured,
              alteredSumAssured: '',
              installmentPrem: rider.installmentPrem,
              newInstallmentPremium: 0,
              lifeNo: rider.lifeNo,
              coverageNo: rider.coverageNo,
              riderNo: rider.riderNo,
              riderStatus: rider.riderStatus
            })
          })

          LAFieldList.append({
            // selectAllForLA: false,
            LAName: LAItem.clientName,
            lifeAssured: LAItem.lifeAssured,
            currentRiders: riderFormArray
          })
          //set default value for LANewRiderList
          const alteredRiderList = LAItem.alteredRiderList
            .filter((x) => !LAItem.riders.some((item) => item.productCode === x.code))
            .filter((riderinfo) => !LAItem.extraRider.some((rider) => rider.productCode === riderinfo.code))
          NewRiderLAList.append({
            LAName: LAItem.clientName,
            lifeAssured: LAItem.lifeAssured,
            isNewLA: false,
            newRiderList: [],
            lifeNo: LAItem.lifeNo ?? '',
            alteredRiderList: alteredRiderList
          })
          riderFormArray = []
        })
        riderAlterationForm.setValue('totalPremium', responseData.totalPremium)
        return lifeAssuredList
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const getArrayFromUndefinedItem = (array: RiderAlterationService.ClassItem[], riderCode: string) => {
    return pipe(
      array.find((x) => x.riderCode === riderCode)?.classList,
      O.fromNullable,
      O.map((item) => item),
      O.getOrElse(() => [] as string[])
    )
  }

  React.useEffect(() => {
    return () => {
      riderAlterationForm.reset({
        LARiderAlterationList: [],
        newRiderLAList: [],
        healthDocuments: [],
        isAddRider: false,
        isRiderAlteration: false,
        requiredPayinAmount: ''
      })
    }
  }, [policyNum])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getRiderName = (item: any, isEN = 'en') => {
    return !!item ? (isEN === 'en' ? item.name : item.nameVi) : ''
  }

  const openInsertLAModal = () => {
    setNewLAInfoIndex(-1)
    setIsShowedNewLA(true)
  }
  const closeAddLAModal = () => {
    setIsShowedNewLA(false)
    // setIsViewLADetail((oldValue) => !oldValue)
  }

  const convertDateStringForSubmit = (strDate: string) => {
    return moment(strDate).format('YYYY-MM-DD')
  }

  const addNewRiderForExistedLA = (
    index: number,
    fieldItem: FieldArrayWithId<RiderAlterationFormData.RiderAlteration, 'newRiderLAList', 'id'>
  ) => {
    const newRiderList = [
      ...fieldItem.newRiderList,
      {
        riderName: { label: '', value: '' },
        sumAssured: '',
        policyPeriod: '',
        isWaiverProduct: false,
        isClassNumber: false,
        classNumberList: []
      }
    ]
    NewRiderLAList.update(index, {
      LAName: fieldItem.LAName,
      lifeAssured: fieldItem.lifeAssured,
      isNewLA: fieldItem.isNewLA,
      newRiderList: newRiderList,
      newLAInfo: fieldItem.newLAInfo,
      lifeNo: fieldItem.lifeNo,
      alteredRiderList: fieldItem.alteredRiderList
    })
  }

  const removeRiderForExistedLA = (
    index: number,
    subIndex: number,
    fieldItem: FieldArrayWithId<RiderAlterationFormData.RiderAlteration, 'newRiderLAList', 'id'>
  ) => {
    // remove item in array with index
    // const filterList = fieldItem.newRiderList.filter((item, index) => index !== subIndex)
    let filterList = riderAlterationForm.watch(`newRiderLAList.${index}.newRiderList`)
    filterList.splice(subIndex, 1)
    // console.log('filterList:',filterList)
    riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList`, [])
    NewRiderLAList.update(index, {
      LAName: fieldItem.LAName,
      lifeAssured: fieldItem.lifeAssured,
      isNewLA: fieldItem.isNewLA,
      newLAInfo: fieldItem.newLAInfo,
      lifeNo: fieldItem.lifeNo,
      alteredRiderList: fieldItem.alteredRiderList,
      newRiderList: []
    })
    riderAlterationForm.setValue(`newRiderLAList.${index}.alteredRiderList`, fieldItem.alteredRiderList)
    riderAlterationForm.setValue(`newRiderLAList.${index}.LAName`, fieldItem.LAName)
    riderAlterationForm.setValue(`newRiderLAList.${index}.lifeAssured`, fieldItem.lifeAssured)
    riderAlterationForm.setValue(`newRiderLAList.${index}.isNewLA`, fieldItem.isNewLA)
    riderAlterationForm.setValue(`newRiderLAList.${index}.newLAInfo`, fieldItem.newLAInfo)
    riderAlterationForm.setValue(`newRiderLAList.${index}.lifeNo`, fieldItem.lifeNo)
    riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList`, filterList)
    riderAlterationForm.watch(`newRiderLAList.${index}.newRiderList`).forEach((riderItem, riderIndex) => {
      if (riderIndex < filterList.length) {
        riderAlterationForm.setValue(
          `newRiderLAList.${index}.newRiderList.${riderIndex}.riderName`,
          riderItem.riderName
        )
        riderAlterationForm.setValue(
          `newRiderLAList.${index}.newRiderList.${riderIndex}.isWaiverProduct`,
          riderItem.isWaiverProduct
        )
        riderAlterationForm.setValue(
          `newRiderLAList.${index}.newRiderList.${riderIndex}.policyPeriod`,
          riderItem.policyPeriod
        )
        riderAlterationForm.setValue(
          `newRiderLAList.${index}.newRiderList.${riderIndex}.sumAssured`,
          riderItem.sumAssured
        )
        riderAlterationForm.setValue(
          `newRiderLAList.${index}.newRiderList.${riderIndex}.isClassNumber`,
          riderItem.isClassNumber
        )
        riderAlterationForm.setValue(
          `newRiderLAList.${index}.newRiderList.${riderIndex}.classNumberList`,
          riderItem.classNumberList
        )
      }
    })
  }

  const addNewRiderLA = (newItem: RiderAlterationFormData.NewLAInfo) => {
    pipe(
      ZIO.effect(() => {
        const lifeNumber = riderAlterationForm.watch('newRiderLAList').reduce((oldLifeNo, item) => {
          const lifeNo = Number(item.lifeNo ?? '0')
          if (oldLifeNo <= lifeNo) {
            return lifeNo
          } else {
            return oldLifeNo
          }
        }, 0)
        const lifeNo = '0' + (lifeNumber + 1).toString()
        return lifeNo
      }),
      ZIO.flatMap((lifeNum) => RiderAlterationService.getRiderList(policyNum, lifeNum)),
      ZIO.map((riderResponse) => {
        const LAName = newItem.surname + ' ' + newItem.firstName
        const alteredRiderList = riderResponse.riderArr
        const newLAItem: RiderAlterationFormData.NewRiderLAData = {
          LAName: LAName,
          lifeAssured: '',
          isNewLA: true,
          newRiderList: [],
          newLAInfo: newItem,
          lifeNo: riderResponse.lifeNo,
          alteredRiderList: alteredRiderList
        }
        NewRiderLAList.append(newLAItem)
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const updateNewLifeAssuresAfterDelete = (
    newItem: RiderAlterationFormData.NewRiderLAData,
    index: number,
    lifeNo: string
  ) => {
    return pipe(
      ZIO.succeed(lifeNo),
      ZIO.flatMap((lifeNum) => RiderAlterationService.getRiderList(policyNum, lifeNum)),
      ZIO.map((riderResponse) => {
        const alteredRiderList = riderResponse.riderArr
        const newRiderList = newItem.newRiderList
        console.log('newRiderList:' + newItem.newRiderList)
        riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList`, [])
        NewRiderLAList.update(index, {
          LAName: newItem.LAName,
          lifeAssured: newItem.lifeAssured,
          isNewLA: newItem.isNewLA,
          newLAInfo: newItem.newLAInfo,
          lifeNo: lifeNo,
          alteredRiderList: alteredRiderList,
          newRiderList: newRiderList
        })
        riderAlterationForm.setValue(`newRiderLAList.${index}.alteredRiderList`, alteredRiderList)
        riderAlterationForm.setValue(`newRiderLAList.${index}.LAName`, newItem.LAName)
        riderAlterationForm.setValue(`newRiderLAList.${index}.lifeAssured`, newItem.lifeAssured)
        riderAlterationForm.setValue(`newRiderLAList.${index}.isNewLA`, newItem.isNewLA)
        riderAlterationForm.setValue(`newRiderLAList.${index}.newLAInfo`, newItem.newLAInfo)
        riderAlterationForm.setValue(`newRiderLAList.${index}.lifeNo`, lifeNo)
        riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList`, newRiderList)
        riderAlterationForm.watch(`newRiderLAList.${index}.newRiderList`).forEach((riderItem, riderIndex) => {
          if (riderIndex < newRiderList.length) {
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.newRiderList.${riderIndex}.riderName`,
              riderItem.riderName
            )
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.newRiderList.${riderIndex}.isWaiverProduct`,
              riderItem.isWaiverProduct
            )
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.newRiderList.${riderIndex}.policyPeriod`,
              riderItem.policyPeriod
            )
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.newRiderList.${riderIndex}.sumAssured`,
              riderItem.sumAssured
            )
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.newRiderList.${riderIndex}.isClassNumber`,
              riderItem.isClassNumber
            )
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.newRiderList.${riderIndex}.classNumberList`,
              riderItem.classNumberList
            )
          }
        })
      })
    )
  }

  const onChangeNewRider = (index: number, subIndex: number, riderCode: string) => {
    const isWaiverProduct = waiverProductList.some((item) => item === riderCode)
    const classNumberList = getArrayFromUndefinedItem(classList, riderCode)
    const isClassNumber = isWaiverProduct || classNumberList.length <= 0 ? false : true
    riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList.${subIndex}.sumAssured`, '')
    riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList.${subIndex}.isWaiverProduct`, isWaiverProduct)
    riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList.${subIndex}.isClassNumber`, isClassNumber)
    riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList.${subIndex}.classNumberList`, classNumberList)
  }

  const removeNewLA = (index: number) => {
    NewRiderLAList.remove(index)
    const newRiderLAList = riderAlterationForm.watch(`newRiderLAList`)
    pipe(
      newRiderLAList,
      A.map((LAItem) => {
        if (LAItem.isNewLA) {
          const index = newRiderLAList.indexOf(LAItem)
          const highestOldNum = riderAlterationForm.watch('newRiderLAList').reduce((oldLifeNo, item) => {
            const lifeNo = Number(item.lifeNo ?? '0')
            return oldLifeNo <= lifeNo && !item.isNewLA ? lifeNo : oldLifeNo
          }, 0)
          const lifeNo = '0' + (highestOldNum + 1).toString()
          console.log('index1: ' + index)
          console.log('lifeNo1: ' + lifeNo)
          return updateNewLifeAssuresAfterDelete(LAItem, index, lifeNo)
        } else {
          return ZIO.unit
        }
      }),
      ZIO.sequence,
      bindLoader,
      ErrorHandling.run()
    )
  }

  const viewLADetail = (index: number) => {
    setNewLAInfoIndex(index)
    // setIsViewLADetail(true)
    setIsShowedNewLA(true)
  }

  const openInsertedNewLAPopUp = () => {
    const submissionDetailData = riderAlterationForm.watch(`newRiderLAList.${newLAInfoIndex}.newLAInfo`)
    const isDetailView = newLAInfoIndex >= 0 ? true : false
    return isShowedNewLA ? (
      <AddNewLAModal
        title={t('RiderAlteration:NewLAInformation')}
        isVisible={isShowedNewLA}
        isConfirmed={props.isConfirmed || isDetailView}
        onClose={closeAddLAModal}
        isDetailView={isDetailView}
        detailIndex={newLAInfoIndex}
        policyInfoData={policyInfoData}
        riderAlterationForm={riderAlterationForm}
        // NewRiderLAList={NewRiderLAList}
        onSuccess={addNewRiderLA}
        submissionDetailData={submissionDetailData}
      />
    ) : (
      <></>
    )
  }

  const getRiderSubmissionData = (riderForm: RiderAlterationFormData.RiderAlteration) => {
    const requiredPayInAmount = Number(riderForm.requiredPayinAmount ?? '0')
    const lifeAssuredList: Array<RiderAlterationData.LifeAssuredList> = !riderForm.isRiderAlteration
      ? []
      : riderForm.LARiderAlterationList.map((LAItem) => {
        const riderList = LAItem.currentRiders.map((riderItem) => {
          const riskCommDate = convertDateStringForSubmit(riderItem.riskCommDate)
          const riskCessDate = convertDateStringForSubmit(riderItem.riskCessDate)
          const newInstallmentPremium = riderForm.totalPremium - riderItem.installmentPrem
          const alteredSumAssured = Number(getNumberFromString(riderItem.alteredSumAssured))
          return {
            coverageCode: riderItem.riderCode,
            riskCommDate: riskCommDate,
            riskCessDate: riskCessDate,
            sumAssured: riderItem.sumAssured,
            installmentPremium: riderItem.installmentPrem,
            newInstallmentPremium: newInstallmentPremium,
            alteredSumAssured: alteredSumAssured,
            lifeNo: riderItem.lifeNo,
            coverageNo: riderItem.coverageNo,
            riderNo: riderItem.riderNo,
            riderStatus: riderItem.riderStatus
          }
        })
        return {
          clientNo: LAItem.lifeAssured,
          riderList: riderList
        }
      })
    const lifeAssuredAdditions = !riderForm.isAddRider
      ? []
      : riderForm.newRiderLAList.map((newItem) => {
        const isUSTax = newItem.newLAInfo?.USTaxDeclaration === 'Y' ? true : false
        const dateOfBirth = newItem.isNewLA ? moment(newItem.newLAInfo?.dateOfBirth).format('YYYYMMDD') : ''
        const issueDate = newItem.isNewLA ? moment(newItem.newLAInfo?.issuedDate).format('YYYYMMDD') : ''
        const issuePlace = !!newItem.newLAInfo?.issuePlace ? newItem.newLAInfo?.issuePlace.value : ''
        const averageIncome = !!newItem.newLAInfo?.averageIncome ? newItem.newLAInfo?.averageIncome.value : ''
        const fullName = newItem.isNewLA
          ? removeAbundantSpacesInText(newItem.newLAInfo?.surname ?? '') +
          ' ' +
          removeAbundantSpacesInText(newItem.newLAInfo?.firstName ?? '')
          : newItem.LAName
        let newLifeAssured: RiderAlterationData.NewLifeAssured = {
          lastName: newItem.newLAInfo?.surname ?? '',
          firstName: newItem.newLAInfo?.firstName ?? '',
          fullName: fullName,
          isNewLA: newItem.isNewLA,
          documentType: newItem.newLAInfo?.documentType.value ?? '',
          idNumber: newItem.newLAInfo?.IDNumber ?? '',
          issuePlace: issuePlace,
          issueDate: issueDate,
          isUSTax: isUSTax,
          dateOfBirth: dateOfBirth,
          gender: newItem.newLAInfo?.gender.value ?? '',
          nationality: newItem.newLAInfo?.nationality.value ?? '',
          mobileCode: newItem.newLAInfo?.mobilePhoneCode.value ?? '',
          mobilePhone: newItem.newLAInfo?.mobilePhone ?? '',
          email: newItem.newLAInfo?.email ?? '',
          occupation: newItem.newLAInfo?.occupation.value ?? '',
          jobDescription: newItem.newLAInfo?.jobDescription ?? '',
          jobTitle: newItem.newLAInfo?.jobTitle ?? '',
          companyName: newItem.newLAInfo?.companyName ?? '',
          companyAddress: newItem.newLAInfo?.companyAddress ?? '',
          averageIncome: averageIncome,
          lifeNo: newItem.lifeNo ?? '',
          clientNumber: newItem.lifeAssured,
          riderListAdditional: newItem.newRiderList.map((subRider) => ({
            coverageCode: subRider.riderName.value,
            sumAssured: Number(subRider.sumAssured),
            policyPeriod: Number(subRider.policyPeriod)
          })),
          duplicatePhones:
            !!newItem.newLAInfo && !!newItem.newLAInfo?.dupPhone
              ? newItem.newLAInfo?.dupPhone.map((p) => ({
                clientName: p.clientName ?? '',
                clientID: p.clientId ?? '',
                relationshipType: p.relationship?.value ?? ''
              }))
              : [],
          duplicateEmails:
            !!newItem.newLAInfo && !!newItem.newLAInfo?.dupEmail
              ? newItem.newLAInfo?.dupEmail.map((e) => ({
                clientName: e.clientName ?? '',
                clientID: e.clientId ?? '',
                relationshipType: e.relationship?.value ?? ''
              }))
              : [],
          addressDetails: {
            ADDRESS: {
              line1: newItem?.newLAInfo?.street ?? '',
              city: newItem.newLAInfo?.city?.label ?? '',
              district: newItem.newLAInfo?.district?.label ?? '',
              subDistrict: newItem.newLAInfo?.ward?.label ?? '',
              countryCode: newItem.newLAInfo?.country?.value ?? '',
              location: newItem.newLAInfo?.ward?.value ?? ''
            },
            FOREIGN_ADDRESS: {
              line1: newItem.newLAInfo?.foreignAddress ?? '',
              countryCode: newItem.newLAInfo?.countryOfForeignAddress?.value ?? ''
            }
          },
          attributesExt: {
            NATIONALITY_2: newItem.newLAInfo?.nationality2?.value ?? '',
            nationality2Name: newItem.newLAInfo?.nationality2?.label ?? '',
            countryName:  newItem.newLAInfo?.country?.label ?? '',
            districtName: newItem.newLAInfo?.district?.label ?? '',
            wardName: newItem.newLAInfo?.ward?.label ?? '',
            cityCode: newItem.newLAInfo?.city?.value ?? '',
            districtCode: newItem.newLAInfo?.district?.value ?? '',
            subDistrictCode: newItem.newLAInfo?.ward?.value ?? ''
          }
        }
        return {
          lifeAssured: newLifeAssured
        }
      })
    const submitedData: RiderAlterationData.SubmissionData = {
      isRiderAlteration: riderForm.isRiderAlteration,
      isAddLA: riderForm.isAddRider,
      requiredPayInAmount: requiredPayInAmount,
      lifeAssuredList: lifeAssuredList,
      lifeAssuredAdditions: lifeAssuredAdditions
    }
    return submitedData
  }

  const getUploadedFilesInfo = (riderForm: RiderAlterationFormData.RiderAlteration) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    let identifyDocuments: FileUploadData[] = []
    riderForm.newRiderLAList.map((newLA) => {
      identifyDocuments =
        newLA.isNewLA && !!newLA.newLAInfo
          ? [...identifyDocuments, ...newLA.newLAInfo?.identifyDocument]
          : identifyDocuments
    })
    if (identifyDocuments.length > 0) {
      uploadedFileList.push({
        uploadFiles: identifyDocuments,
        transactionType: TransactionType.RIDER_ALTERATION,
        docTypeCode: 'DPS10',
        category: TaskType.PolicyService,
        policyNumber: policyNum ?? '',
        officeCode: officeCode ?? ''
      })
    }
    //health declaration
    uploadedFileList.push({
      uploadFiles: riderForm.healthDocuments,
      transactionType: TransactionType.RIDER_ALTERATION,
      docTypeCode: 'DPS09',
      category: TaskType.PolicyService,
      policyNumber: policyNum ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  props.initSubmission({
    validate: async (isContinue) => {
      const isValidForm = await riderAlterationForm.trigger()
      if (isValidForm) {
        const riderForm = riderAlterationForm.getValues()
        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/rider-alteration`,
          body: getRiderSubmissionData(riderForm),
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.RIDER_ALTERATION),
          collerationId: policyNum,
          transaction: TransactionType.RIDER_ALTERATION,
          uploadedFilesInfo: getUploadedFilesInfo(riderForm)
        }
      } else {
        return false
      }
    },
    clear: () => {
      riderAlterationForm.reset({
        LARiderAlterationList: [],
        newRiderLAList: [],
        healthDocuments: [],
        isAddRider: false,
        isRiderAlteration: false,
        requiredPayinAmount: '',
        totalPremium: 0
      })
      initData(riderDetail as CancelRider.DetailCancelRiderInfo, waiverProductList, classList)
    }
  })

  return (
    <View style={riderStyles.container}>
      <Title title={t('requestInfo:RequestDetail')} wrapperStyle={riderStyles.titleText}></Title>
      <Controller
        control={riderAlterationForm.control}
        name={'isRiderAlteration'}
        render={({ field: { value, onChange, onBlur } }) => (
          <Checkbox
            title={t('RiderAlteration:RiderAlteration')}
            onChange={(checked) => {
              onChange(checked)
              // changeEventForLA(checked, index, fieldItem)
            }}
            onBlur={onBlur}
            value={value}
            disabled={props.isConfirmed}
            checkBoxStyle={riderStyles.tableCheckBox}
          />
        )}
      />
      {riderAlterationForm.watch('isRiderAlteration') && (
        <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
          <LifeAssuredTable
            riderAlterationForm={riderAlterationForm}
            LAFieldList={LAFieldList}
            isConfirmed={props.isConfirmed}
          ></LifeAssuredTable>
        </SectionContent>
      )}
      <View style={riderStyles.secondLine}>
        <Controller
          control={riderAlterationForm.control}
          name={'isAddRider'}
          render={({ field: { value, onChange, onBlur } }) => (
            <Checkbox
              title={t('RiderAlteration:AddRider')}
              onChange={(checked) => {
                onChange(checked)
                // changeEventForLA(checked, index, fieldItem)
              }}
              onBlur={onBlur}
              value={value}
              disabled={props.isConfirmed}
              checkBoxStyle={riderStyles.tableCheckBox}
            />
          )}
        />
      </View>
      {riderAlterationForm.watch('isAddRider') && (
        <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
          <AlterRiderTable
            // newRiderList={newRiderList}
            riderAlterationForm={riderAlterationForm}
            NewRiderLAList={NewRiderLAList}
            disabled={props.isConfirmed}
            addNewRider={addNewRiderForExistedLA}
            removeRider={removeRiderForExistedLA}
            removeNewLA={removeNewLA}
            viewLADetail={viewLADetail}
            onChangeRider={onChangeNewRider}
          ></AlterRiderTable>
          {NewRiderLAList.fields.length < 4 && !props.isConfirmed && (
            <View style={riderStyles.btnNewLA}>
              <TouchableOpacity onPress={openInsertLAModal}>
                <View style={riderStyles.btnAdd}>
                  <Text style={riderStyles.btn_text}>{t('RiderAlteration:AddNewLA')}</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </SectionContent>
      )}

      <Title wrapperStyle={riderStyles.secondLine} title={t('submission:StatementOfInsurability')}></Title>
      <View style={{ flex: 1 }}>
        <Text style={riderStyles.field_title}>
          {t('submission:HealthNote')}
          <Link href={`${assets.UpdatedRedatingHealthyTemplate}`} target="_parent" download>
            <Text style={riderStyles.underlineLink}>{t('submission:Here')}</Text>
          </Link>
        </Text>
        <Text style={riderStyles.field_title}>
          {t('submission:HealthDeclaration')} <Text style={riderStyles.redLine}> *</Text>
        </Text>
        <Controller
          control={riderAlterationForm.control}
          name={'healthDocuments'}
          rules={{
            required: {
              value: true,
              message: t('message:MS050232')
            }
          }}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <>
              <ImgUploadMutiple
                timeFormat={'DD/MM/YYYY HH:mm'}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={props.isConfirmed}
              />
              {error?.message && <Error message={value && value.length > 0 ? '' : error.message} />}
            </>
          )}
        />
      </View>
      <Title wrapperStyle={riderStyles.secondLine} title={t('submission:TEMPORARYPAYININFO')}></Title>
      <SectionRow>
        <SectionCol>
          <Controller
            control={riderAlterationForm.control}
            name={'requiredPayinAmount'}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <Input
                title={t('submission:RequirePayInAmount')}
                // required={true}
                inputType={'money'}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={props.isConfirmed}
                errorMessage={!!value ? error?.message : ''}
              />
            )}
          />
        </SectionCol>
      </SectionRow>
      {openInsertedNewLAPopUp()}
    </View>
  )
}

const riderStyles = StyleSheet.create({
  container: {
    marginBottom: 40,
    flex: 1
  },
  titleText: {
    marginTop: 30
  },
  currencyContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  currentText: {
    fontStyle: 'italic'
  },
  tableHeaderText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 13,
    color: '#70777E'
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  },
  btnContent: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  btnNewLA: {
    // marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btnAdd: {
    minHeight: 40,
    width: '100%',
    borderRadius: 100,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderColor: '#ED1B2E',
    borderWidth: 1
    // backgroundColor: '#ED1B2E'
  },
  btn_text: {
    fontSize: 15,
    lineHeight: 20,
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  secondBtn: {
    marginLeft: 27
  },
  underlineLink: {
    textDecorationLine: 'underline'
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  panelContent: {
    backgroundColor: '#fff',
    marginTop: 20,
    borderWidth: 0,
    borderColor: '#fff'
  },
  secondLine: {
    marginTop: 20
  },
  redLine: {
    color: '#ed1b2c',
    fontSize: 15,
    fontWeight: 'bold'
  }
})
