export namespace UploadDocumentReportConst {
  export const displayedColumns = [
    { id: 'no', name: '#', width: 5, widthPx: 5, horizontal: 'center', textAlign: 'center' },
    { id: 'policyNo', name: `UploadDocReport:Policy`, widthPx: 15, horizontal: 'center', textAlign: 'center' },
    { id: 'proposalNo', name: `UploadDocReport:Proposal`, widthPx: 25, horizontal: 'center', textAlign: 'center' },
    { id: 'agentCode', name: `UploadDocReport:Agent`, widthPx: 25, horizontal: 'center', textAlign: 'center' },
    { id: 'poName', name: `UploadDocReport:CAFullName`, widthPx: 25, horizontal: 'center', textAlign: 'center' },
    { id: 'createdDate', name: `UploadDocReport:CreatedDate`, widthPx: 15, horizontal: 'center', textAlign: 'center' },
    { id: 'officeCode', name: `common:Office`, widthPx: 25 },
    { id: 'department', name: `utilities:department`, widthPx: 23 },
    { id: 'documentType', name: `utilities:documentType`, widthPx: 23 },
    { id: 'type2', name: `UploadDocReport:Type`, widthPx: 15, horizontal: 'center', textAlign: 'center' }
  ]
}
