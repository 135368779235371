import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { FrequencyC } from './Frequency'

export const EFormBillingChange = t.type({
  body: t.type({
    billToDate: Maybe(t.string),
    curBillingFrequency: Maybe(FrequencyC),
    curRenewalPremium: Maybe(t.string),
    premiumAdv: Maybe(t.string),
    suspendAmount: Maybe(t.string)
  })
})
export type EFormBillingChange = t.TypeOf<typeof EFormBillingChange>
