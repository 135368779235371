import { Permission, assets } from '@pulseops/common'
import React from 'react'
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native'
import { OBSharedStyles } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { OBCallProgram, OBCallProgramTypeCode } from '../../ob-service'
import { CallOutDetailContext } from './OBCallOutDetailContext'

type OBCallOutFooterProps = {
  onSubmitActionEvent?: () => void
  onSuspendActionEvent?: () => void
  onUnsuspendActionEvent?: () => void
  onCalloutClickEvent?: () => void
  onQCCompleteEvent?: () => void
  permissions?: string[]
  isSuspendEnable?: boolean
  isShowUnsuspend?: boolean
  isQC?: boolean
  isInquiry?: boolean
  isDisableSubmit?: boolean
  isShowCallButton?: boolean
  isREQC?: boolean
  onAcceptClickEvent?: () => void
  onRejectClickEvent?: () => void
  onResendSMSClickEvent?: () => void
  onREQCCompleteEvent?: () => void
  isDisableSuspend?: boolean
}
export const OBCallOutFooter = (props: OBCallOutFooterProps) => {
  const { t } = useTranslation()
  const permissions = props.permissions ?? []
  const { A7s10Config, transactionType } = React.useContext(CallOutDetailContext)
  return (
    <View style={footerStyles.calloutFooter}>
      <View style={OBSharedStyles.actionContainer}>
        {(props.isQC || props.isREQC) && permissions.includes(Permission['CompleteOBTaskDetail']) ? (
          <TouchableOpacity onPress={() => { props.isREQC ? props.onREQCCompleteEvent && props.onREQCCompleteEvent() : props.onQCCompleteEvent && props.onQCCompleteEvent() }}>
            <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight, footerStyles.actionLine]}>
              <Text style={OBSharedStyles.actionHightLightText}>{t('common:Complete')}</Text>
            </View>
          </TouchableOpacity>
        ) : (
          <>
            {transactionType === OBCallProgramTypeCode.IDC && !props.isSuspendEnable && !props.isInquiry ? (
              <>
                {permissions.includes(Permission['SubmitOBTaskDetail']) && A7s10Config?.includes('SUBMIT') &&
                  <TouchableOpacity onPress={() => props.onSubmitActionEvent && props.onSubmitActionEvent()} disabled={props.isDisableSubmit}>
                    <View style={[OBSharedStyles.actionContent, !props.isDisableSubmit ? OBSharedStyles.actionHightLight : OBSharedStyles.actionDisable, footerStyles.actionLine]}>
                      <Text style={!props.isDisableSubmit ? OBSharedStyles.actionHightLightText : OBSharedStyles.actionDisableText}>{t('submission:Submit')}</Text>
                    </View>
                  </TouchableOpacity>
                }
                {permissions.includes(Permission['AcceptOBTaskDetail']) && A7s10Config?.includes('ACCEPT') && (
                  <TouchableOpacity onPress={() => { props.onAcceptClickEvent && props.onAcceptClickEvent() }}>
                    <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight, footerStyles.actionLine]}>
                      <Text style={OBSharedStyles.actionHightLightText}>{t('Outbound:OBCalloutDetail:Accept')}</Text>
                    </View>
                  </TouchableOpacity>
                )}
                {permissions.includes(Permission['RejectOBTaskDetail']) && A7s10Config?.includes('REJECT') && (
                  <TouchableOpacity onPress={() => { props.onRejectClickEvent && props.onRejectClickEvent() }}>
                    <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight, footerStyles.actionLine]}>
                      <Text style={OBSharedStyles.actionHightLightText}>{t('Outbound:OBCalloutDetail:Reject')}</Text>
                    </View>
                  </TouchableOpacity>
                )}
                {permissions.includes(Permission['ResendSMSOBTaskDetail']) && A7s10Config?.includes('SEND_SMS') && (
                  <TouchableOpacity onPress={() => { props.onResendSMSClickEvent && props.onResendSMSClickEvent() }}>
                    <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight, footerStyles.actionLine]}>
                      <Text style={OBSharedStyles.actionHightLightText}>{t('Outbound:OBCalloutDetail:ResendSMS')}</Text>
                    </View>
                  </TouchableOpacity>
                )}
              </>
            ) : (
              <>
                {permissions.includes(Permission['UnSuspendOBTaskDetail']) && props.isShowUnsuspend &&
                  <TouchableOpacity onPress={() => props.onUnsuspendActionEvent && props.onUnsuspendActionEvent()}>
                    <View style={[OBSharedStyles.actionContent, footerStyles.actionLine]}>
                      <Text style={OBSharedStyles.actionContentText}>{t('TaskManagement:Unsuspend')}</Text>
                    </View>
                  </TouchableOpacity>
                }
                {permissions.includes(Permission['SuspendOBTaskDetail']) && props.isSuspendEnable && !props.isInquiry && !props.isShowUnsuspend &&
                  <TouchableOpacity onPress={() => props.onSuspendActionEvent && props.onSuspendActionEvent()} disabled={props.isDisableSuspend}>
                    <View style={[OBSharedStyles.actionContent, !props.isDisableSuspend ? OBSharedStyles.actionHightLight : OBSharedStyles.actionDisable, footerStyles.actionLine]}>
                      <Text style={!props.isDisableSuspend ? OBSharedStyles.actionHightLightText : OBSharedStyles.actionDisableText}>{t('TaskManagement:Suspend')}</Text>
                    </View>
                  </TouchableOpacity>
                }
                {permissions.includes(Permission['SubmitOBTaskDetail']) && !props.isSuspendEnable && !props.isInquiry && !props.isShowUnsuspend &&
                  <TouchableOpacity onPress={() => props.onSubmitActionEvent && props.onSubmitActionEvent()} disabled={props.isDisableSubmit}>
                    <View style={[OBSharedStyles.actionContent, !props.isDisableSubmit ? OBSharedStyles.actionHightLight : OBSharedStyles.actionDisable, footerStyles.actionLine]}>
                      <Text style={!props.isDisableSubmit ? OBSharedStyles.actionHightLightText : OBSharedStyles.actionDisableText}>{t('submission:Submit')}</Text>
                    </View>
                  </TouchableOpacity>
                }
              </>
            )
            }
          </>
        )}
      </View>
      {props.isShowCallButton &&
        <View>
          <TouchableOpacity onPress={() => props.onCalloutClickEvent && props.onCalloutClickEvent()}>
            <assets.OutboundCallButton />
          </TouchableOpacity>
        </View>
      }
    </View>
  )
}
const footerStyles = StyleSheet.create({
  calloutFooter: {
    display: 'flex',
    paddingHorizontal: 24,
    flexDirection: 'row',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    paddingVertical: 12,
    boxShadow: '0px -5px 24px 0px rgba(0, 0, 0, 0.10)'
  },
  actionLine: {
    marginLeft: 8
  }
})