import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  Select,
  SelectOption,
  PulseOpsService,
  Toast,
  AppContext,
  GeneralService,
  getLanguage,
  TaskType
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Color } from '@material-ui/lab'
import { VoiceDataGridForm, VoiceRecordingModels, VoiceRecordingServices } from '../../container/voice-recording'

export type RejectPopupProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {
    taskId: string
    updateToCore: boolean
    processId: string
    category?: string
    caseId?: string
    dynamicKeywordData?: Array<VoiceDataGridForm.DynamicKeywordData>
    isSavedDynamicKeyword?: boolean
  }
}

export const RejectPopup = (props: RejectPopupProps) => {
  const { onClose, onSuccess, open, selectedValue } = props
  const { isWide } = useMobile()
  const { t } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const [note, setNote] = useState<string>('')
  const [reason, setReason] = useState<SelectOption | null>(null)
  const [reasonOptions, setReasonOptions] = useState<SelectOption[]>([])

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const isEn = getLanguage() === 'en'

  React.useEffect(() => {
    if (open) {
      pipe(
        GeneralService.getRejectReasonByGroup(selectedValue.processId),
        ZIO.tap((res) => {
          setReasonOptions(
            res.map((x) => ({
              // suspendBy: x.userGroup,
              value: x.code,
              label: isEn ? x.nameEn : x.nameVn
            }))
          )
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }, [selectedValue.processId, open])

  const handleClose = () => {
    setNote('')
    setReason(null)
    onClose()
  }

  const getUserResultDataForVoiceRecording = () => {
    const submitedData = selectedValue.dynamicKeywordData?.map((item) => ({
      scriptId: item.scriptId || '',
      keywordId: item.keywordId || '',
      result: item.result?.value || '',
      reason: item.reason || '',
      updateKeywordType: item.isGroup ? 'UPDATE_KEYWORD_TYPE_GROUP_EVALUATION' : 'UPDATE_KEYWORD_TYPE_DYNAMIC'
    }))
    const data = {
      resultList: submitedData,
      processInstanceId: selectedValue.processId
    }
    return data
  }

  const handleRejectAction = () => {
    if (reason) {
      showGlobalLoading(true)
      pipe(
        selectedValue.category === TaskType.IDC &&
          selectedValue.dynamicKeywordData &&
          selectedValue.dynamicKeywordData.length > 0 &&
          !selectedValue.isSavedDynamicKeyword ?
          VoiceRecordingServices.submitAIreasonUser(getUserResultDataForVoiceRecording())
          : ZIO.succeed({} as VoiceRecordingModels.ReasonUserC),
        ZIO.flatMap((_) =>
          PulseOpsService.rejectTask(
            selectedValue.taskId,
            {
              author: 'admin',
              message: note,
              reason: reason.value
            },
            selectedValue.updateToCore,
            selectedValue.category
          ),
        ),

        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastInternal(t('requestInfo:RejectFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          if (selectedValue.category === TaskType.Claim) {
            pipe(GeneralService.triggerActionClaim(selectedValue.caseId || ''), ZIO.unsafeRun({}))
          }
          showGlobalLoading(false)
          handleClose()
          showToast(t('requestInfo:RejectSuccess'), 'success')
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ModalComponent
      title={t('claim:Reject')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('claim:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('claim:Reject'),
          type: 'filled',
          disabled: !reason,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: handleRejectAction
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16, width: isWide ? '50%' : '100%' }}>
          <Select
            value={reason}
            onChange={setReason}
            label={t('claim:Reason')}
            required={true}
            options={reasonOptions}
          />
        </View>
        <TextInput
          title={t('claim:Comment')}
          value={note}
          onChange={setNote}
          placeholder={'...'}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
