import {
  FundMapping,
  Panel,
  PulseOpsFormat,
  RadioButton,
  SourceType,
  TableField,
  TaskDetail,
  useMobile
} from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { InfoViewList, Title } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

export const FundSwitching = ({ detail }: { detail?: TaskDetail.FundSwitching }) => {
  const { t } = useTranslation(['requestInfo', 'fund'])
  const { isMobile } = useMobile()

  const is1toN: boolean = detail?.option || false
  let isTargetPremium: boolean = false
  let dataTarget: Output[] = []

  if (is1toN) {
    const sourceFundValue = detail?.sourceFundList[0] || null
    dataTarget = [
      {
        label: t('SourceFund'),
        value: sourceFundValue?.sourceFundCode
          ? `${sourceFundValue?.sourceFundCode} - ${t(FundMapping.getFunds(sourceFundValue.sourceFundCode))}`
          : '-'
      },
      {
        label: t('UnitValue'),
        value: sourceFundValue?.unitValue ? PulseOpsFormat.thousandSepartor(sourceFundValue.unitValue) : '-',
        suffix: 'VND'
      },
      {
        label: t('SourcePercentage') + ' (%)',
        value: sourceFundValue?.sourcePercentage
          ? detail?.source !== SourceType.PRUONLINE
            ? PulseOpsFormat.thousandSepartor(sourceFundValue.sourcePercentage)
            : `${sourceFundValue.sourcePercentage}`
          : '-'
      },
      {
        label: t('TargetAmount'),
        value: sourceFundValue?.sourceAmount ? PulseOpsFormat.thousandSepartor(sourceFundValue.sourceAmount) : '-',
        suffix: 'VND'
      }
    ]
  } else {
    const targetFundValue = detail?.targetFundList[0] || null
    isTargetPremium = (targetFundValue && targetFundValue.targetFundCode.endsWith('1')) || false
    dataTarget = [
      {
        label: t('TargetFund'),
        value: targetFundValue?.targetFundCode
          ? `${targetFundValue?.targetFundCode} - ${t(FundMapping.getFunds(targetFundValue.targetFundCode))}`
          : '-'
      },
      {
        label: t('ReceivedPercentage') + ' (%)',
        value: targetFundValue?.targetPercentage
          ? PulseOpsFormat.thousandSepartor(targetFundValue.targetPercentage)
          : '-'
        // suffix: '%'
      },
      {
        label: t('TargetAmount'),
        value: targetFundValue?.targetAmount ? PulseOpsFormat.thousandSepartor(targetFundValue.targetAmount) : '-',
        suffix: 'VND'
      }
    ]
  }

  const columnsTarget = [
    {
      key: '1',
      title: t('TargetFund'),
      name: 'targetFundCode'
    },
    {
      key: '2',
      title: t('TargetPercentage') + ' (%)',
      name: 'targetPercentage'
    },
    {
      key: '3',
      title: t('TargetAmount'),
      name: 'targetAmount'
    }
  ]

  const columnsSource = [
    {
      key: '1',
      title: t('SourceFund'),
      name: 'sourceFundCode'
    },
    {
      key: '2',
      title: t('UnitValue'),
      name: 'unitValue'
    },
    {
      key: '3',
      title: t('SourcePercentage') + ' (%)',
      name: 'sourcePercentage'
    },
    {
      key: '4',
      title: t('SourceAmount'),
      name: 'sourceAmount'
    }
  ]

  return (
    <View>
      <Title title={t('FundSwitching')} />
      <Panel title="" isExand={false}>
        <View style={[!isMobile && styles.row, { marginBottom: 32 }]}>
          <View style={isMobile ? styles.colMobile : styles.col}>
            <RadioButton label={t('From1FundToManyFunds')} selected={is1toN} />
          </View>
          <View style={isMobile ? styles.colMobile : styles.col}>
            <View style={styles.text}>
              <RadioButton label={t('FromManyFundsTo1Fund')} selected={!is1toN} />
            </View>

            {!is1toN && (
              <View style={styles.subSelect}>
                <View style={styles.text}>
                  <RadioButton label={t('TargetPremiumAccount')} selected={isTargetPremium} />
                </View>
                <View style={styles.text}>
                  <RadioButton label={t('TopupAccount')} selected={!isTargetPremium} />
                </View>
              </View>
            )}
          </View>
        </View>
        {is1toN && (
          <>
            <InfoViewList dataSource={dataTarget} />
            <TableField
              styleHeader={styles.headerTable}
              styleBody={styles.bodyTable}
              columns={columnsTarget}
              dataSource={
                (detail &&
                  detail?.targetFundList.map((item) => ({
                    ...item,
                    targetFundCode: `${item?.targetFundCode} - ${t(FundMapping.getFunds(item.targetFundCode))}`,
                    targetAmount: PulseOpsFormat.thousandSepartor(item.targetAmount)
                  }))) ||
                []
              }
            />
          </>
        )}
        {!is1toN && (
          <>
            <View style={styles.mb16}>
              <TableField
                styleHeader={styles.headerTable}
                styleBody={styles.bodyTable}
                columns={columnsSource}
                dataSource={
                  (detail &&
                    detail.sourceFundList.map((s) => ({
                      ...s,
                      sourceFundCode: `${s?.sourceFundCode} - ${t(FundMapping.getFunds(s.sourceFundCode))}`,
                      unitValue: PulseOpsFormat.thousandSepartor(s.unitValue),
                      sourceAmount: PulseOpsFormat.thousandSepartor(s.sourceAmount)
                    }))) ||
                  []
                }
              />
            </View>
            <InfoViewList dataSource={dataTarget} />
          </>
        )}
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  col: {
    width: '33.333333334%'
  },
  colMobile: {
    width: '100%'
  },
  subSelect: {
    marginLeft: 30
  },
  text: {
    fontSize: 16,
    marginBottom: 10
  },
  isHightLight: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#eD1B2E'
  },
  mb16: {
    marginBottom: 16
  },
  headerTable: {
    backgroundColor: '#e2e7ea',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  bodyTable: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  }
})
