import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { SelectOption, form2 } from '@pulseops/common'

export namespace VoiceDataGridForm {
  const form = t.type({
    dynamicKeyword: t.array(
      t.type({
        segmentId: Maybe(t.string),
        keywordId: Maybe(t.string),
        owner: Maybe(t.string),
        key: Maybe(t.string),
        value: Maybe(t.string),
        result: form2.selectOption.optional,
        reason: Maybe(t.string),
        threshold: Maybe(t.string),
        cof: Maybe(t.string),
        scriptId: Maybe(t.string),
        worthValue: Maybe(t.string),
        systemRating: Maybe(t.string),
        warningMatching: Maybe(t.boolean),
        isGroup: Maybe(t.boolean),
        passed: form2.selectOption.optional,
        isSaved: t.boolean
      })
    ),
    owner: Maybe(t.string),
    keyword: Maybe(t.string),
    worthKeyword: Maybe(t.string),
    result: Maybe(t.string),
    resultSystem: Maybe(t.string)
    // keywordGroupEvaluations: t.array(
    //   t.type({
    //     autoScoringPassed: Maybe(t.boolean),
    //     cof: Maybe(t.string),
    //     keywordGroupName: Maybe(t.string),
    //     keywordValue: Maybe(t.string),
    //     owner: Maybe(t.string),
    //     segmentId: Maybe(t.string),
    //     weight: Maybe(t.number),
    //     result: form2.selectOption.optional,
    //     reason: Maybe(t.string),
    //     scriptId: Maybe(t.string),
    //     order: Maybe(t.union([t.string, t.number]))
    //   })
    // )
  })

  export const DynamicKeywordData = t.type({
    segmentId: Maybe(t.string),
    keywordId: Maybe(t.string),
    owner: Maybe(t.string),
    key: Maybe(t.string),
    value: Maybe(t.string),
    result: form2.selectOption.optional,
    reason: Maybe(t.string),
    threshold: Maybe(t.string),
    cof: Maybe(t.string),
    scriptId: Maybe(t.string),
    worthValue: Maybe(t.string),
    systemRating: Maybe(t.string),
    warningMatching: Maybe(t.boolean),
    isGroup: Maybe(t.boolean),
    passed: form2.selectOption.optional,
    isSaved: t.boolean,
    isChangedData: t.boolean
  })



  export const codec = form

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
  export type DynamicKeywordData = t.TypeOf<typeof DynamicKeywordData>

  export const reasonOption: SelectOption[] = [
    {
      label: 'Pass',
      value: 'pass'
    },
    {
      label: 'Fail',
      value: 'fail'
    }
  ]
}
