import { Task, Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import { AuthService } from '../auth'
import { POApi } from '../POApi'
import {
  AccountBalanceC,
  AccountBalanceRequest,
  Beneficiary,
  Customer,
  EFormBillingChange,
  FundCodeC,
  GetBanksResponse,
  GetCommentListResponse,
  LapseReins,
  LoanEnqueryResponse,
  MoreData,
  Policy,
  QueryTaskResponse,
  SourceType,
  SubTasksResponse,
  //SUCommonRequestData,
  TaskDetailApi,
  TransactionType,
  Vip
} from './model'
import * as t from 'io-ts'
import * as O from 'fp-ts/Option'

const nhatEmApi = 'wf-api'
const tanApi = 'pulseops'
const cleanObj = (obj: any) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export const PulseOpsApi = {
  getTaskById: (taskId: string) =>
    pipe(
      POApi.get(`${tanApi}/api/v1/tasks/${taskId}`)(TaskDetailApi.codec),
      ZIO.tap((v) => ZIO.effect(() => console.log(v)))
    ),
  getTaskSuspendById: (processInstanceId: string) =>
    pipe(
      POApi.get(`${tanApi}/history/historic-process-instances/${processInstanceId}`)(TaskDetailApi.codec),
      ZIO.tap((v) => ZIO.effect(() => console.log(v)))
    ),

  // getHistoricTasks: (data: {
  //   start: number
  //   size: number
  //   order?: 'desc' | 'asc'
  //   sourceIn?: SourceType[]
  //   transactionTypeIn?: TransactionType[]
  //   correlationId?: string
  //   startedAfter?: Date
  //   startedBefore?: Date
  //   involvedUser?: string
  // }) => POApi.post(
  //   `${tanApi}/api/v1/query/process-instances`,
  //   QueryInquiryResponse
  // )({
  //     order: 'desc',
  //     ...data
  //   }
  // ),

  getHistoricTasks: (data: {
    start: number
    size: number
    order?: 'desc' | 'asc'
    sourceIn?: SourceType[]
    transactionTypeIn?: TransactionType[]
    correlationId?: string
    businessKey?: string
    startedAfter?: Date
    startedBefore?: Date
    involvedUser?: string
  }) =>
    pipe(
      POApi.getConfig({ params: { order: data.order || 'desc', ...data } })(
        `pulseops/history/historic-process-instances?finished=true&processFinished=true`
      )(
        // `${tanApi}/api/v1/history/historic-task-instances?finished=true&processFinished=true`,
        QueryTaskResponse
      )
    ),

  getHistoricDetail: (taskId: string) =>
    pipe(
      POApi.get(`${tanApi}/history/historic-process-instances/${taskId || ''}`)(
        // `${tanApi}/api/v1/history/historic-task-instances/${taskId || ''}`,
        TaskDetailApi.codec
        // t.unknown
      ),
      // ZIO.filterOrFail(
      //   (res) => res.data !== null && res.data.length > 0,
      //   (_) => Throwable(`${taskId} detail empty`)
      // ),

      ZIO.map((v) => {
        return { ...v, processInstanceId: v.id }
      }),
      ZIO.tap((v) => ZIO.effect(() => console.log(v)))
    ),

  taskAction: ({
    taskId,
    action,
    outcome,
    author,
    comment,
    variables,
    reason,
    isCancelPayment,
    category
  }: {
    taskId: string
    action: 'complete' | 'transfer' | 'assign'
    outcome: 'complete' | 'reject' | 'suspend' | 'end' | 'unsuspend'
    author: string
    comment?: string
    variables: any[] | undefined
    reason?: string
    isCancelPayment?: boolean
    category?: string
  }) =>
    pipe(
      POApi.post(`${tanApi}/api/v1/tasks/${taskId}`)(t.unknown)(
        cleanObj({
          action,
          reason,
          outcome,
          variables,
          author,
          comment,
          isCancelPayment,
          category
        })
      ),
      ZIO.asVoid
    ),

  transferAction: ({
    taskId,
    assignee,
    author,
    variables,
    comment,
    assigneeGroup,
    category
  }: {
    taskId: string
    assignee: string
    author: string
    variables: any[]
    comment?: string
    assigneeGroup?: string,
    category?: string
  }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        POApi.post(`${tanApi}/api/v1/tasks/${taskId}`)(t.unknown)(
          cleanObj({
            action: 'assign',
            assignee,
            author: x.email,
            comment,
            assigneeGroup,
            variables,
            category
          })
        )
      ),
      ZIO.asVoid
    ),

  transferList: ({
    taskList,
    assignee,
    assigneeGroup,
    category
  }: {
    taskList: string[]
    assignee: string
    assigneeGroup?: string,
    category?: string
  }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((token) =>
        POApi.put(`${tanApi}/api/v1/tasks/assign-task-list`)(t.unknown)({
          assignee: assignee,
          assigneeGroup: assigneeGroup,
          taskIds: taskList,
          user: token.email,
          category
        })
      ),
      ZIO.asVoid
    ),

  transferToMe: ({ taskId, category }: { taskId: string[], category?: string }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((token) =>
        PulseOpsApi.transferList({
          taskList: taskId,
          assignee: token.email,
          category: category
        })
      )
    ),

  reCheckAction: ({ taskId, variables, outcome = 'transfer', category }: { taskId: string; variables: any[], outcome?: string, category?: string }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        POApi.post(`${tanApi}/api/v1/tasks/${taskId}`)(t.unknown)({
          action: 'complete',
          outcome: outcome,
          author: x.email,
          variables,
          category
        })
      ),
      ZIO.asVoid
    ),

  unSuspendAction: ({
    processInstanceIds,
    // action,
    assignee,
    comment,
    author,
    variables,
    basket,
    userGroupCode,
    category
  }: {
    processInstanceIds: string[]
    // action: 'activate',
    assignee: string
    comment: string
    author: string
    variables: any
    basket: string,
    userGroupCode: string
    category?: string
  }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        POApi.put(`${tanApi}/api/v1/runtime/process-instances/unsuspend`)(t.unknown)({
          // action,
          processInstanceIds,
          assignee,
          comment,
          author: x.email,
          basket,
          userGroupCode,
          category
        })
      ),
      ZIO.asVoid
    ),

  // getClient: (clientId: string) =>
  //   pipe(
  //     POApi.get(`${nhatEmApi}/client/${clientId}`, t.union([Client, t.type({})]) ),
  //     ZIO.map(O.fromPredicate(Client.is)),
  //     ZIO.mapError((err) => {
  //       if (err.source.message === `${Client.name} decode error`) {
  //         return Throwable('Cannot get client info on failed case. Waiting for PAN API.')
  //       }
  //       return err
  //     })
  //   ),

  getPoNameByPolicy: (policyNum: string) =>
    pipe(
      POApi.get(`${nhatEmApi}/policy/${policyNum}`)(
        t.type({
          owner: t.type({
            id: t.string
          })
        })
      ),
      ZIO.flatMap((policy) =>
        pipe(
          POApi.get(`${nhatEmApi}/customer/${policy.owner.id}`)(Customer),
          ZIO.map((client) => ({
            poName: client.body.name
          }))
        )
      )
    ),

  getCustomer: (clientId: string) =>
    pipe(
      POApi.get(`${nhatEmApi}/customer/${clientId}`)(Customer),
      ZIO.tap((v) => ZIO.effect(() => console.log(v)))
    ),

  getBillingChangeData: (policyNum: string) =>
    pipe(POApi.get(`${nhatEmApi}/policy/${policyNum}/billing-change`)(EFormBillingChange)),

  getPolicy: (policyNum: string) => pipe(POApi.get(`${nhatEmApi}/policy/${policyNum}`)(Policy)),
  getCustomerBeneficiary: (clientId: string) =>
    pipe(
      POApi.get(`${nhatEmApi}/policy/${clientId}/beneficiary`)(t.union([Beneficiary, t.type({})])),
      ZIO.map(O.fromPredicate(Beneficiary.is)),
      ZIO.mapError((err) => {
        if (err.source.message === `${Beneficiary.name} decode error`) {
          return Throwable('Cannot get client info on failed case. Waiting for PAN API.')
        }
        return err
      })
    ),

  // getCustomerAddress: (clientId: string) =>
  //   pipe(
  //     POApi.get(`${nhatEmApi}/customer/${clientId}/address`, t.union([Address, t.type({})]) ),
  //     ZIO.map(O.fromPredicate(Address.is)),
  //     ZIO.mapError((err) => {
  //       if (err.source.message === `Address decode error`) {
  //         return Throwable('Cannot get client info on failed case. Waiting for PAN API.')
  //       }
  //       return err
  //     })
  //   ),
  getAccountBalance: (reqBody: AccountBalanceRequest) =>
    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(AccountBalanceC)({
        body: reqBody
      }),
      ZIO.map((res) => res)
    ),
  getLapseReinsEnquiry: (policyNum: string) =>
    pipe(
      POApi.post(`${nhatEmApi}/policy/billing-change-apl/lapse-reins-enquiry`)(LapseReins.LapseReins)({
        body: {
          contractNumber: policyNum
        }
      }),
      ZIO.map((lapse) => lapse),
      ZIO.catchAll(() => ZIO.succeed(null))
    ),

  getAutomaticPolicyLoan: (policyNum: string, effectiveDate: string) =>
    pipe(
      POApi.post(`${nhatEmApi}/policy/billing-change-apl/loan-enquiry`)(LoanEnqueryResponse.Loan)({
        body: {
          contractNumber: policyNum,
          effectiveDate
        }
      }),
      ZIO.map((loanAuto) => loanAuto)
    ),

  queryTasks: (data: {
    sourceIn?: SourceType[]
    vipIn?: Vip[]
    transactionTypeIn?: TransactionType[]
    correlationId?: string
    businessKey?: string
    claimNumber?: string
    createdAfter?: Date
    createdBefore?: Date
    createdOn?: string
    start: number
    size: number
    order?: 'desc' | 'asc'
    taskCategory?: SubTasksResponse
    //category?: string
    active?: boolean
  }) =>
    POApi.post(`${tanApi}/api/v1/query/tasks`)(QueryTaskResponse)({
      active: true,
      order: 'desc',
      ...data
    }),
  getCommentList: (taskId: string) => POApi.get(`${tanApi}/api/v1/tasks/${taskId}/comments`)(GetCommentListResponse),
  addComment: (taskId: string, data: { author: string; message: string }) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        POApi.post(`${tanApi}/api/v1/tasks/${taskId}/comments`)(t.unknown)({
          message: data.message,
          author: x.email
        })
      ),
      ZIO.asVoid
    ),

  getBanks: pipe(
    POApi.post(`${nhatEmApi}/bank/search`)(t.type({ body: GetBanksResponse }))({ body: {} }),
    ZIO.map((res) => {
      return res.body.filter((bank) => bank.code !== 'VRB')
    }),
    ZIO.cached()
  ),

  getBankBranchs: (code: string) =>
    pipe(
      POApi.get(`wf-api/bank/${code}/branch/search`)(
        t.type({
          body: t.array(
            t.type({
              code: t.string,
              name: t.string
            })
          )
        })
      ),
      ZIO.map((res) => res.body)
    ),

  getErrorHandler: pipe(POApi.get(`${tanApi}/api/v1/management/deadletter-jobs`)(t.unknown), ZIO.asVoid),

  sqlCFI: (policyNum: string): Task<MoreData.CFI> =>
    // postThao(
    //   `Select a.OCCDATE as Effective_date, b.INCDTE as Customer_received_date,case when SUM(c.SACSCURBAL) >= 0 then 0 else SUM(c.SACSCURBAL) * -1 end Advance_premium from chdr as a join atmupf as b on a.chdrcoy = b.chdrcoy and a.chdrnum = b.chdrnum join ACBL as c on a.chdrcoy = c.RLDGCOY and a.chdrnum = substr(c.RLDGACCT,1,8) and c.SACSCODE = 'LN' and c.SACSTYP = 'VP' where a.chdrcoy ='2' and a.chdrnum = ${correlationId} and a.validflag ='1' group by b.INCDTE, a.OCCDATE`,
    //   MoreData.CFI
    // ),
    pipe(
      ZIO.zipPar(
        PulseOpsApi.sqlBillingChangeAdvancePremium(policyNum),
        POApi.get(`${nhatEmApi}/policy/${policyNum}`)(
          t.type({
            body: t.type({
              riskCommenceDate: t.string,
              proposal: t.type({
                proposalReceivedDate: t.string
              })
            })
          })
        )
      ),
      ZIO.map(([balance, date]) => {
        const strToDate = (str: string): Date | 99999999 => {
          str = str.replace(/-/g, '')
          return str === '99999999' ? 99999999 : new Date(`${str.substr(0, 4)}-${str.substr(4, 2)}-${str.substr(6, 2)}`)
        }
        return {
          body: {
            customerReceivedDate: strToDate(date.body.riskCommenceDate),
            effectiveDate: strToDate(date.body.proposal.proposalReceivedDate),
            advancePremium: balance.body?.advancePremium || 0
          }
        }
      })
    ),

  sqlBillingChangeSuspendAmount: (policyNum: string): Task<MoreData.BillingChangeSuspendAmount> =>
    // postThao(
    //   `SELECT case when SUM(SACSCURBAL) >= 0 then 0 else SUM(SACSCURBAL) * -1 end Suspense_Amount FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'S' and substr(RLDGACCT,1,8) = ${correlationId}  group by substr(RLDGACCT,1,8)`,
    //   MoreData.BillingChangeSuspendAmount
    // ),
    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: t.array(
              t.type({
                amount: t.number,
                sacsCode: t.string,
                sacsType: t.string
              })
            )
          })
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            }
          ]
        }
      }),
      ZIO.map((x) => {
        return {
          body: {
            suspenseAmount: x.body.accountGroups[0].amount
          }
        }
      })
    ),

  sqlBillingChangeAdvancePremium: (policyNum: string): Task<MoreData.BillingChangeAdvancePremium> =>
    // postThao(
    //   `SELECT case when SUM(SACSCURBAL) >= 0 then 0 else SUM(SACSCURBAL) * -1 end Advance_Premium FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LN' and SACSTYP = 'VP' and substr(RLDGACCT,1,8) = ${correlationId} group by substr(RLDGACCT,1,8)`,
    //   MoreData.BillingChangeAdvancePremium
    // ),
    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: t.array(
              t.type({
                amount: t.number,
                sacsCode: t.string,
                sacsType: t.string
              })
            )
          })
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LN',
              sacsType: 'VP'
            }
          ]
        }
      }),
      ZIO.map((x) => {
        return {
          body: {
            advancePremium: x.body.accountGroups[0].amount
          }
        }
      })
    ),

  sqlBillingChangeReversalDate: (
    policyNo: string
  ): Task<MoreData.BillingChangeReversalDate> => // UPdate to paid to date basic
    // postSqlListThao(
    //   // `SELECT PTRNEFF as translate, DATESUB as date FROM PTRNPF WHERE CHDRCOY = '2' and CHDRNUM = '73819789' and validflag <> '1' and  BATCTRCDE = 'B522'`,
    //   `SELECT PTRNEFF as translate, DATESUB as date FROM PTRNPF WHERE CHDRCOY = '2' and CHDRNUM = '${correlationId}' and validflag <> '1' and  BATCTRCDE = 'B522'`,
    //   MoreData.BillingChangeReversalDateC
    // ),

    pipe(
      POApi.post(`${nhatEmApi}/policy/policies`)(
        t.type({
          body: t.array(
            t.type({
              paidToDateRider: t.string,
              paidToDateBasic: t.string
            })
          )
        })
      )({
        body: [
          {
            policyNo,
            function: 'BILLREVERT'
          }
        ]
      }),
      ZIO.map((x) => {
        return {
          body: x.body.map((y) => ({
            translate: Number(y.paidToDateBasic),
            date: Number(y.paidToDateRider)
          }))
        }
      })
    ),

  sqlMaturityNetTotalBenefit: (policyNum: string): Task<MoreData.MaturityNetTotalBenefit> =>
    // postThao(
    //   `SELECT case when SUM(SACSCURBAL) >= 0 then 0 else SUM(SACSCURBAL) * -1 end Total_Maturity_Benefit FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'MP' and substr(RLDGACCT,1,8) = ${correlationId} group by substr(RLDGACCT,1,8)`,
    //   MoreData.MaturityNetTotalBenefit
    // ),

    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: t.array(
              t.type({
                amount: t.number,
                sacsCode: t.string,
                sacsType: t.string
              })
            )
          })
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'MP'
            }
          ]
        }
      }),
      ZIO.map((x) => {
        return {
          body: {
            totalMaturityBenefit: x.body.accountGroups[0].amount
          }
        }
      })
    ),

  sqlMaturitySuspendAmount: (policyNum: string): Task<MoreData.MaturitySuspendAmount> =>
    // postThao(
    //   `select SUM(a.SACSCURBAL) as Suspend_Amount from(SELECT substr(RLDGACCT,1,8), case when SACSCURBAL >= 0 then 0 else SACSCURBAL * -1 end SACSCURBAL FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'S' and substr(RLDGACCT,1,8) = ${correlationId} union all SELECT substr(RLDGACCT,1,8), case when SACSCURBAL >= 0 then 0 else SACSCURBAL * -1 end SACSCURBAL FROM acbl WHERE RLDGCOY = '2' and SACSCODE = 'LF' and SACSTYP = 'WF' and substr(RLDGACCT,1,8) = ${correlationId} ) a `,
    //   MoreData.MaturitySuspendAmount
    // ),
    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: t.array(
              t.type({
                amount: t.number,
                sacsCode: t.string,
                sacsType: t.string
              })
            )
          })
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            },
            {
              sacsCode: 'LW',
              sacsType: 'WF'
            }
          ]
        }
      }),
      ZIO.map((x) => {
        return {
          body: {
            suspendAmount: x.body.accountGroups[0].amount + x.body.accountGroups[1].amount
          }
        }
      })
    ),

  sqlExcessPremium: (policyNum: string): Task<MoreData.ExcessPremium> =>
    // postThao(
    //   `select SUM(a.SACSCURBAL) as Excess_Premium from(SELECT substr(RLDGACCT,1,8), case when SACSCURBAL >= 0 then 0 else SACSCURBAL * -1 end SACSCURBAL FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'S'  and substr(RLDGACCT,1,8) = ${correlationId} union all SELECT substr(RLDGACCT,1,8), case when SACSCURBAL >= 0 then 0 else SACSCURBAL * -1 end SACSCURBAL FROM acbl WHERE RLDGCOY ='2' and SACSCODE ='LF' and SACSTYP = 'WF' and  substr(RLDGACCT,1,8) = ${correlationId} union all SELECT substr(RLDGACCT,1,8), case when SACSCURBAL >= 0 then 0 else SACSCURBAL * -1 end SACSCURBAL FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'U' and substr(RLDGACCT,1,8) = ${correlationId} ) a`,
    //   MoreData.ExcessPremium
    // ),
    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: t.array(
              t.type({
                amount: t.number,
                sacsCode: t.string,
                sacsType: t.string
              })
            )
          })
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            },
            {
              sacsCode: 'LF',
              sacsType: 'WF'
            },
            {
              sacsCode: 'LP',
              sacsType: 'U'
            }
          ]
        }
      }),
      ZIO.map((x) => {
        return {
          body: {
            excessPremium:
              x.body.accountGroups[0].amount + x.body.accountGroups[1].amount + x.body.accountGroups[2].amount
          }
        }
      })
    ),

  sqlTopupSuspendAmount: (policyNum: string): Task<MoreData.TopupSuspendAmount> =>
    // postThao(
    //   `Select SUM(a.SACSCURBAL) as Suspend_Amount from (SELECT substr(RLDGACCT,1,8), case when SACSCURBAL >= 0 then 0 else SACSCURBAL * -1 end SACSCURBAL FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'S'  and substr(RLDGACCT,1,8) = ${correlationId} union all SELECT substr(RLDGACCT,1,8), SACSCURBAL FROM acbl WHERE RLDGCOY ='2' and SACSCODE = 'LP' and SACSTYP = 'U' and substr(RLDGACCT,1,8) = ${correlationId} ) a`,
    //   MoreData.TopupSuspendAmount
    // ),
    pipe(
      POApi.post(`${nhatEmApi}/policy/account-balance`)(
        t.type({
          body: t.type({
            accountGroups: t.array(
              t.type({
                amount: t.number,
                sacsCode: t.string,
                sacsType: t.string
              })
            )
          })
        })
      )({
        body: {
          policyNum,
          function: 'TOTAL',
          accountGroups: [
            {
              sacsCode: 'LP',
              sacsType: 'S'
            },
            {
              sacsCode: 'LP',
              sacsType: 'U'
            }
          ]
        }
      }),
      ZIO.map((x) => {
        return {
          body: {
            suspendAmount: x.body.accountGroups[0].amount + x.body.accountGroups[1].amount
          }
        }
      })
    ),

  sqlAPL: (policyNum: string): Task<MoreData.APL> =>
    // postThao(
    //   `select billfreq as Billing_Frequency, SINSTAMT06 as Renewal_Premium from payr where chdrcoy ='2' and validflag ='1' and chdrnum = ${correlationId} `,
    //   MoreData.APL
    // ),
    pipe(
      POApi.get(`${nhatEmApi}/policy/${policyNum}`)(
        t.type({
          body: t.type({
            billFreq: t.string,
            attributesExt: t.type({
              basicPremium: t.number,
              riderPremium: t.number
            })
          })
        })
      ),
      ZIO.map((x) => {
        return {
          body: {
            billingFrequency: x.body.billFreq,
            renewalPremium: x.body.attributesExt.basicPremium + x.body.attributesExt.riderPremium
          }
        }
      })
    ),

  sqlAPLTransactionDate: (policyNo: string): Task<MoreData.APLTransactionDateBody> =>
    // postThao(
    //   `SELECT max( PTRNEFF ) as transaction_date FROM ptrn WHERE CHDRCOY ='2' and BATCTRCDE ='BR74' and chdrnum = ${correlationId} `,
    //   MoreData.APLTransactionDateBody
    // ),
    pipe(
      PulseOpsApi.sqlBillingChangeReversalDate(policyNo),
      ZIO.map((x) => {
        return {
          body: x.body
            ? x.body.map((y) => ({
              transactionDate: y.translate
            }))
            : []
        }
      })
    ),

  // sqlReinsFunds: (correlationId: string): Task<MoreData.ReinsFunds> =>
  //   postSqlListThao(
  //     `select li.lifcnum, trim(cl.lsurname) || ' ' || trim(cl.LGIVNAME) as LA_Name from life as li join clnt as cl on cl.clntnum = li.lifcnum where  li.chdrnum = ${correlationId} and li.chdrcoy ='2' and li.validflag <> '2'`,
  //     MoreData.ReinsFunds
  //   ),

  // sqlReinsAgent: (correlationId: string): Task<MoreData.ReinsAgent> =>
  //   postThao(
  //     `select ch.agntnum as Agent_Number, trim(cl.lsurname) || ' ' || trim(cl.LGIVNAME) as Servicing_Agent from chdr as ch  join agnt as ag on ag.agntcoy = ch.chdrcoy and ch.agntnum = ag.agntnum join clnt as cl on cl.clntcoy ='9' and cl.clntnum = ag.clntnum where ch.chdrcoy ='2' and ch.validflag ='1' and ch.chdrnum = ${correlationId} `,
  //     MoreData.ReinsAgent
  //   ),

  sqlPwFunds: (correlationId: string): Task<MoreData.PwFunds> =>
    // postSqlListThao(
    //   `SELECT ut.UNIT_VIRTUAL_FUND  as Fund_Value, ut.CURRENT_UNIT_BAL * vp.UNIT_BARE_PRICE as Fund_Amount FROM utrs as ut join vprc as vp on ut.UNIT_VIRTUAL_FUND = vp.UNIT_VIRTUAL_FUND and vp.jobno = ( SELECT max(jobno) FROM VPRC) WHERE ut.chdrcoy ='2' and ut.chdrnum = ${correlationId}`,
    //   MoreData.PwFunds
    // ),
    pipe(
      POApi.post(`${nhatEmApi}/bo/policy-extra-info`)(
        t.type({
          body: t.type({
            policyExtraInfoFunds: t.array(
              t.type({
                fundCode: FundCodeC,
                unitBalance: t.number,
                price: t.number,
                priceDate: t.string,
                policyNum: t.string,
                estimateValue: t.number
              })
            )
          })
        })
      )({
        body: {
          policies: [correlationId],
          function: 'BASIC'
        }
      }),
      ZIO.map((x) => ({
        body: x.body.policyExtraInfoFunds.map((x) => ({
          fundAmount: x.estimateValue,
          fundValue: x.fundCode
        }))
      }))
    ),

  // sqlChangeContactInfoLocation: (ward: string, district: string, city: string): Task<MoreData.ChangeContactInfoLocation> =>
  //   postThao(
  //     `select item.itemitem as location FROM itempf item WHERE item.itemtabl = 'TH627' AND item.validflag = '1' AND item.itempfx = 'IT' AND item.itemcoy = '2' AND itemitem != '99' AND trim(substr(item.itemitem, 3, 2)) != '99' AND trim(substr(item.itemitem, 1, 2)) != '99' AND trim(substr(item.itemitem, 5, LENGTH(item.itemitem) - 4)) != '99' AND trim(substr(item.genarea, 91, 15)) || ' ' || trim(substr(item.genarea, 1, 30)) = ${ward} AND trim(substr(item.genarea, 106, 15)) || ' ' || trim(substr(item.genarea, 31, 30)) = ${district} AND trim(substr(item.genarea, 121, 15)) || ' ' || trim(substr(item.genarea, 61, 30)) = ${city}`,
  //     MoreData.ChangeContactInfoLocation
  //   ),

  sqlPoName: (correlationId: string) =>
    pipe(
      PulseOpsApi.getPoNameByPolicy(correlationId),
      ZIO.map((x) => ({
        body: {
          poName: x.poName
        }
      }))
    ),

  sqlChangeContactInfoAddress: (clientId: string): Task<MoreData.AddressFromSql> =>
    // postThao(
    //   `select trim(clnt.CLTADDR01) || trim(clex.zstrnm01) || ' ' ||  trim(clnt.CLTADDR02) || trim(clex.zstrnm02) || ' ' || trim(clnt.CLTADDR03) || trim(clex.zstrnm03) || ' ' || trim(clnt.CLTADDR04) || trim(clex.zstrnm04) as address from (SELECT CASE WHEN ch.despnum = ' ' THEN ch.cownnum ELSE ch.despnum END AS Despatch FROM chdr ch WHERE CHDRCOY = '2' and VALIDFLAG = '1' and chdrnum = '${correlationId}' ) a join clnt on a.Despatch = clnt.clntnum and clnt.clntcoy = '9' join clex on a.Despatch = clex.clntnum and clex.clntcoy = '9'`,
    //   MoreData.AddressFromSql
    // )
    pipe(
      POApi.get(`${nhatEmApi}/customer/${clientId}`)(
        t.type({
          body: t.type({
            addressDetails: t.type({
              PRIMARY: Maybe(
                t.type({
                  line1: t.string,
                  line2: t.string,
                  line3: t.string,
                  line4: t.string
                })
              )
            })
          })
        })
      ),
      ZIO.map((x) => {
        const address = x.body.addressDetails.PRIMARY
        return {
          body: {
            address: address
              ? [address.line1, address.line2, address.line3, address.line4].filter(Boolean).join(', ')
              : '-'
          }
        }
      })
    )
}
