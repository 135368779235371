export namespace ProductOptionSwitchingConst {
  export enum ULPProduct {
    VLR2 = 'VLR2',
    VLR3 = 'VLR3',
    EUS3 = 'EUS3',
    VLR5 = 'VLR5',
    ULR6 = 'ULR6'
  }

  export const productMinMaxList: Array<{ productCode: string; minSA: number; maxSA: number }> = [
    { productCode: ULPProduct.VLR2, minSA: 100000000, maxSA: 1200000000 },
    { productCode: ULPProduct.VLR3, minSA: 200000000, maxSA: 1200000000 },
    { productCode: ULPProduct.VLR5, minSA: 40000000, maxSA: 99999999999999 }
  ]

  export const mappingMinMaxList = new Map<string, { productCode: string; minSA: number; maxSA: number }>(
    productMinMaxList.map((item) => [item.productCode, item])
  )
  export const getProductMM = (productCode: string | undefined) =>
    mappingMinMaxList.get(productCode ?? '') ?? {
      productCode: '',
      minSA: 0,
      maxSA: 0
    }
}
