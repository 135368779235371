import * as React from 'react'
import * as rn from 'react-native'
import * as rne from 'react-native-elements'
import styled from 'styled-components/native'
import { styled as styledM } from '@material-ui/core/styles'
import * as core from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { FormControlTypeMap } from '@material-ui/core/FormControl'
import { theme } from '../../theme'

export const Text = styled(rn.Text)`
  font-family: ${(p) => p.theme.fonts.main};
`

export const TextRequired = styled(rn.Text)`
  font-family: ${(p) => p.theme.fonts.main};
  color: red;
  margin-left: 5px;
`

export const View = styled(rn.View)`
  font-family: ${(p) => p.theme.fonts.main};
`

export const Button = styled(rne.Button).attrs((p) => ({
  containerStyle: {
    width: '100%'
  },
  buttonStyle: {
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderWidth: 2
  },
  titleStyle: {
    fontFamily: theme.fonts.main,
    fontSize: 18,
    fontWeight: 700
  },
  icon: p.icon && <View style={{ marginRight: p.title ? 8 : 0 }}>{p.icon}</View>,
  disabled: p.disabled || p.loading
}))``
export const ButtonModal = styled(rne.Button).attrs((p) => ({
  containerStyle: {
    
  },
  buttonStyle: {
    paddingVertical: 6,
    paddingHorizontal: 40,
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: 'rgb(237, 27, 46)'
  },
  titleStyle: {
    fontFamily: theme.fonts.main,
    fontSize: 18,
    fontWeight: 700
  },
  icon: p.icon && <View style={{ marginRight: p.title ? 8 : 0 }}>{p.icon}</View>,
  disabled: p.disabled || p.loading
}))``
export const BorderView = styled(View)`
  border-width: 1px;
  border-color: #d3dce6;
  border-radius: 8px;
`

export const FormControl = styledM(core.FormControl)({
  marginRight: 10,
  marginLeft: 10,
  marginBottom: 9.5
}) as OverridableComponent<FormControlTypeMap>

export const BorderContainer = styled(BorderView)`
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 36px;
  padding-right: 36px;
  margin-bottom: 1rem;
`
export const TextLink = styled(Text)`
  text-decoration-line: underline;
  color: #1ea5fc;
`
