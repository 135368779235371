import { TopUpConst } from '@pulseops/common'

export namespace TopUpFormData {
  export interface FundListFormValue {
    fundName: string
    fundCode: TopUpConst.FundCode
    percentage: number
    amount: number
    amountByPercent: number
  }

  export interface RequestFormValue {
    topUpAmount: number
    suspenseAmount: number
    requirePayinAmount: number
    topUpOption: TopUpConst.TopUpOption
    fundList: Array<FundListFormValue>
    agentCode: string
    // payin: PayInMethodFormValue
  }

  export interface RequestULPForm {
    topUpAmount: number
    suspenseAmount: number
    requirePayinAmount: number
    agentCode: string
  }

  export interface ULPFormValue {
    request: RequestULPForm
  }
}
