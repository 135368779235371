import * as React from 'react'
import { withStyles } from '@material-ui/core'
import * as core from '@material-ui/core'
import { ControlProps } from '@pulseops/common'

type InputProps = ControlProps<string | null> & {
  label?: string
  value?: string | null
  type?: string
  placeholder?: string
  maxLength?: number
  disabled?: boolean
  isDigit?: boolean
  controlStyle?: React.CSSProperties
}

export const BorderFilledInput = (props: InputProps) => {
  return (
    <core.FormControl style={props.controlStyle} error={!!props.errorMessage}>
      <SC.TextField
        label={props.label}
        placeholder={props.placeholder ?? ''}
        variant="filled"
        error={!!props.errorMessage}
        value={props.value}
        onChange={(event) => {
          let val = event.target.value || ''
          if (props.isDigit) {
            val = val.replace(/[^0-9]/g, '')
          }
          props.onChange && props.onChange(val)
        }}
        type={props.type || 'text'}
        inputProps={{
          ...(props.maxLength != null ? { maxLength: props.maxLength } : {})
        }}
        disabled={props.disabled}
      />
      {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
    </core.FormControl>
  )
}

const SC = {
  TextField: withStyles({
    root: {
      width: '100%',
      minWidth: '100px',
      '& .MuiFilledInput-root': {
        background: '#FFFFFF',
        borderRadius: 8,
        height: 70,
        padding: '31px 16px 11px 24px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        },
        '&.Mui-error': {
          border: '1px solid #F80016'
        }
      },
      '& .MuiFilledInput-input': {
        fontSize: 18,
        padding: 0
      },
      '& .MuiFilledInput-underline': {
        '&:before': {
          border: 'none'
        },
        '&:after': {
          border: 'none'
        }
      },
      '& .MuiInputLabel-filled': {
        color: '#000000',
        fontWeight: 300,
        paddingLeft: 15,
        marginTop: 9,
        '&.MuiInputLabel-shrink': {
          fontWeight: 700,
          marginTop: 0,
          transform: 'translate(12px, 16px) scale(0.75)'
        }
      }
    }
  })(core.TextField)
}
