import { POApi, TransactionType } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { isNil } from 'lodash'

export const PaymentReportItem = t.type({
  policyNo: Maybe(t.string),
  policyNumber: Maybe(t.string),
  policyOwner: Maybe(t.string),
  payee: Maybe(t.string),
  idNumber: Maybe(t.string),
  agentCode: Maybe(t.string),
  paymentNumber: Maybe(t.string),
  pvNumber: Maybe(t.string),
  paymentDate: Maybe(t.string),
  amount: t.number,
  pvStatus: Maybe(t.string),
  transactionType: Maybe(t.string),
  paymentOffice: Maybe(t.string),
  // officeName: Maybe(t.string),
  bankCode: Maybe(t.string),
  createdDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  verifiedDate: Maybe(t.string),
  verifiedBy: Maybe(t.string),
  authorizedDate: Maybe(t.string),
  authorizedBy: Maybe(t.string),
  status: Maybe(t.string),
  note: Maybe(t.string)
})

export const SubmissionReportItem = t.type({
  policyNumber: Maybe(t.string),
  policyOwner: Maybe(t.string),
  payee: Maybe(t.string),
  idNumber: Maybe(t.string),
  agentCode: Maybe(t.string),
  paymentNumber: Maybe(t.string),
  pvNumber: Maybe(t.string),
  paymentDate: Maybe(t.string),
  amount: Maybe(t.number),
  paymentOffice: Maybe(t.string),
  bankCode: Maybe(t.string),
  pvStatus: Maybe(t.string),
  transactionType: Maybe(t.string),
  createdDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  verifiedDate: Maybe(t.string),
  verifiedBy: Maybe(t.string),
  authorizedDate: Maybe(t.string),
  authorizedBy: Maybe(t.string),
  status: Maybe(t.string),
  note: Maybe(t.string)
})

export const Response = t.type({
  data: t.array(PaymentReportItem),
  size: Maybe(t.number),
  start: Maybe(t.number),
  total: Maybe(t.number)
})

export const ResponseSubmissionReport = t.type({
  body: t.array(SubmissionReportItem),
  size: Maybe(t.number),
  start: Maybe(t.number),
  total: Maybe(t.number)
})

export const ViewReport = t.type({})

export type PaymentReportItemType = t.TypeOf<typeof PaymentReportItem>
export type ResponseType = t.TypeOf<typeof Response>
export type SubmissionReportType = t.TypeOf<typeof SubmissionReportItem>

export namespace PaymentReportService {
  export const formatCurrency = (num: number) => {
    if (isNil(num)) return '-'
    return `${num}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '-'
  }

  export const viewReport = ({
    fromDate,
    toDate,
    officeCode,
    size,
    start
  }: {
    fromDate: string
    toDate: string
    officeCode: string[]
    size?: number
    start?: number
  }) => {
    return pipe(
      POApi.post('cep-rest/payment/report')(Response)({
        data: { fromDate, toDate, officeCode },
        size,
        start
      }),
      ZIO.catchAll(() => ZIO.succeed(null)),
      ZIO.map((res) => {
        const { data = [], size = 10, start = 0, total = 0 } = res ?? {}

        return {
          data,
          start,
          size,
          total
        }
      })
    )
  }

  export const viewReportSubmission = ({
    fromDate,
    toDate,
    officeCode,
    size,
    start
  }: {
    fromDate: string
    toDate: string
    officeCode: string[]
    size?: number
    start?: number
  }) => {
    return pipe(
      POApi.post('pulseops/api/v1/cashout-note/generate-report-data')(ResponseSubmissionReport)({
        fromDate,
        toDate,
        officeCode,
        size,
        start
      }),
      ZIO.catchAll(() => ZIO.succeed(null)),
      ZIO.map((res) => {
        const { body = [], size = 10, start = 0, total = 0 } = res ?? {}

        return {
          data: body,
          start,
          size,
          total
        }
      })
    )
  }
}
