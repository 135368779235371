import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import {
  AppContext,
  RBAC,
  ErrorHandling,
  assets,
  form2,
  ContractMapping,
  useTranslator,
  AuthService,
  CircelInput,
  useMobile,
  Checkbox,
  Permission,
  PremiumStatusMapping
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
// import { TaskDetailStackParamList } from '@pulseops/task'
import { InquiryStackParamList } from '../InquiryStackParamList'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { Controller } from 'react-hook-form'
import _, { isEmpty } from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { BasicSearchForm } from './cep-basic-form-search'
import { ZIO } from '@mxt/zio'
import i18next from 'i18next'
import { CEPSearchPanel } from '../cep-policy-detail/cep-components'
import { ISortDataType } from '../cep-policy-detail/CEPInterface'
import { IPolicySearch } from '../cep-api-services/CepSearchModels'
import { searchPolicyInfo } from '../cep-api-services/CepApi'

type Props = StackScreenProps<InquiryStackParamList, 'InquiryScreen'>

export const CEPSearchScreen = ({ navigation }: Props) => {
  const { t, i18n } = useTranslation()
  const ct = useTranslator('contract').t
  const ht = useTranslation('menu').t
  const cep = useTranslator('CustomerEngagement').t
  const formSearch = form2.useForm(BasicSearchForm.codec, {
    defaultValues: {
      poIDNumber: '',
      policyNo: '',
      poMobilePhone: '',
      poClientNumber: '',
      proposalNo: ''
    }
  })
  const { base, handleSubmit } = formSearch
  const [dataSource, setDataSource] = React.useState<Array<IPolicySearch>>([])
  const isFocused = useIsFocused()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [submittedData, setSubmittedData] = React.useState<any>({})
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [loading, bindLoading] = useLoading(false)
  const formError = _.get(base.formState.errors, '.message')
  const [sortConfig, setSortConfig] = React.useState<ISortDataType | null>(null)
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const { isMobile } = useMobile()
  const [isHide, setIsHide] = React.useState<boolean>(false)
  const [optionQuery, setOptionQuery] = React.useState<{
    isMobilePhone: boolean
    isClientNumber: boolean
  }>({
    isMobilePhone: false,
    isClientNumber: false
  })

  const [listInput, setListInput] = React.useState<Array<string>>(['policyNo', 'poIDNumber'])
  const [isAnimate, setAnimate] = React.useState<boolean>(false)

  const RenderInputData = (
    name: 'poIDNumber' | 'policyNo' | 'poMobilePhone' | 'poClientNumber',
    typeInput: 'text' | 'number' | 'money' = 'text',
    title: string,
    key: number,
    icon?: JSX.Element | undefined,
    onIconPressed?: () => void | undefined,
    checkAuto?: boolean | undefined
  ) => {
    return (
      <View style={[isMobile ? SUREStyle.col_1 : SUREStyle.col_2, { marginTop: 24 }]}>
        <Controller
          name={name}
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <CircelInput
              value={value ?? undefined}
              inputType={typeInput}
              onChange={onChange}
              placeholder={title}
              errorMessage={error?.message}
              inputStyle={[error?.message ? { borderColor: '#ED1B2E', borderWidth: 1, borderStyle: 'solid' } : {}]}
              icon={icon}
              onIconPressed={onIconPressed}
              checkAuto={checkAuto}
              maxLength={name === 'policyNo' ? 9 : undefined}
            />
          )}
        />
      </View>
    )
  }

  const ToggleInputMoblie = () =>{
    setOptionQuery((prevState) => ({
      ...prevState,
      isMobilePhone: !prevState.isMobilePhone
    }))
    formSearch.base.resetField('poMobilePhone')
    setIsHide(false)
    checkExInputList('poMobilePhone')
  }
  const ToggleInputClient = () => {
    setOptionQuery((prevState) => ({
      ...prevState,
      isClientNumber: !prevState.isClientNumber
    }))
    formSearch.base.resetField('poClientNumber')
    setIsHide(false)
    checkExInputList('poClientNumber')
  }

  const RenderComponent = (componentName: string, i: number) => {
    switch (componentName) {
      case 'policyNo':
        return RenderInputData('policyNo', 'number', t('CustomerEngagement:PolicyNumber/Proposal'), i)
      case 'poIDNumber':
        return RenderInputData('poIDNumber', 'number', t('CustomerEngagement:IDNumberSearch'), i)
      case 'poMobilePhone':
        return RenderInputData(
          'poMobilePhone',
          'number',
          t('CustomerEngagement:phoneNumber'),
          i,
          <assets.RemoveIcon style={{ backgroundColor: '#bdbcc1', borderRadius: 20, padding: 2 }} />,
          ToggleInputMoblie,
          true
        )
      case 'poClientNumber':
        return RenderInputData(
          'poClientNumber',
          'number',
          t('CustomerEngagement:clientNum'),
          i,
          <assets.RemoveIcon style={{ backgroundColor: '#bdbcc1', borderRadius: 20, padding: 2 }} />,
          ToggleInputClient,
          true
        )

      default:
        break
    }
  }
  const pst = useTranslator('premiumStatus').t

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: ht('360Inquiry'),
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        }
      ])
    }

    setAnimate(false)

    formSearch.base.reset()

    setDataSource([])
  }, [isFocused])

  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const checkExInputList = (name: string) => {
    const inputData = [...listInput]

    const index = inputData.indexOf(name)

    if (index === -1) {
      inputData.push(name)
    } else {
      inputData.splice(index, 1)
    }

    setListInput(inputData)
  }

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const onViewReportClick = handleSubmit((validated) => {
    let data = {
      ...validated,
      policyNo: validated.policyNo?.length === 8 ? validated.policyNo : '',
      proposalNo: validated.policyNo?.length === 9 ? validated.policyNo : ''
    }
    setMessageResponse('')
    setSubmittedData(validated)
    handleData(data, pageNum, pageSize)
  })

  const handleData = (submitData: any, page: number, size: number) => {
    const isValid = validateForm()
    if (isValid) {
      let officeCode = userInfo?.isGaLogin ? userInfo?.officeCode : undefined
      let data = {
        start: page,
        size: size,
        data: {
          ...submitData,
          officeCode
        }
      }
      pipe(
        searchPolicyInfo(data),
        ZIO.map((res) => {
          const { gacodeInValid, policies } = res.data
          gacodeInValid ? setMessageResponse(i18next.t('message:MS990027')) : ''
          let data = {
            ...res,
            data:
              policies &&
              policies?.map((x) => ({
                ...x,
                policyOwnerName: !!x && x.policyOwnerName ? x.policyOwnerName.name : '-',
                mainLifeAssuredName: !!x && x.mainLifeAssuredName ? getName(x.mainLifeAssuredName) : '-',
                policyStatus: !!x && x.policyStatus ? ContractMapping.get(ct)(x.policyStatus) : '-',
                premiumStatus: !!x && x.premiumStatus ? PremiumStatusMapping.get(pst)(x?.premiumStatus) : '-',
                roles: !!x && x.roles ? mappingRoles(x.roles) : []
              }))
          }
          if ((policies ?? []).length === 0) {
            setMessageResponse(i18next.t('message:MS030029'))
          }
          ;(policies ?? []).length > 0 ? setAnimate(true) : ''
          setDataSource(data.data ?? [])
          setTotalItem(data.total ?? 0)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const validateForm = (): boolean => {
    if (
      isEmpty(base.getValues('policyNo')) &&
      isEmpty(base.getValues('poClientNumber')) &&
      isEmpty(base.getValues('poIDNumber')) &&
      isEmpty(base.getValues('poMobilePhone'))
    ) {
      setMessageResponse(i18next.t('message:MS990056'))
      return false
    } else if (userInfo?.isGaLogin && userInfo.officeCode === null) {
      setMessageResponse(i18next.t('message:MS990027'))
      return false
    } else if (userInfo?.isGaLogin && isEmpty(base.getValues('policyNo'))) {
      setMessageResponse(i18next.t('message:MS990029'))
      return false
    }

    return true
  }

  const getName = (item: { firstName?: string; surName?: string; middleName?: string } | null) => {
    if (!!item) {
      return item.surName + ' ' + item.middleName + ' ' + item.firstName
    }

    return ''
  }

  const requestSort = (key: any) => {
    let sort = 'descending'
    if (sortConfig && sortConfig.key === key && sortConfig.sort === 'descending') {
      sort = 'ascending'
    }
    setSortConfig({ key, sort })
  }

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return (
        <Text>
          <assets.IconArrowDownGray />
        </Text>
      )
    }
    if (sortConfig?.key === name) {
      if (sortConfig?.sort === 'descending') {
        return (
          <Text style={{ transform: [{ rotate: '180deg' }] }}>
            <assets.IconArrowDownGray />
          </Text>
        )
      }
      return (
        <Text>
          <assets.IconArrowDownGray />
        </Text>
      )
    } else {
      return (
        <Text>
          <assets.IconArrowDownGray />
        </Text>
      )
    }
  }

  const sortedItems = React.useMemo(() => {
    let sortableItems: Array<IPolicySearch> = [...dataSource]

    if (sortConfig !== null) {
      sortableItems = _.orderBy(dataSource, [sortConfig.key], [sortConfig.sort === 'ascending' ? 'asc' : 'desc'])
    }
    return sortableItems
  }, [dataSource, sortConfig])

  const mappingRoles = (roles: Array<string>): Array<string> => {
    let data = [...roles]

    let priorities: Array<string> = []

    const isIndexOW = data.indexOf('OW')

    if (isIndexOW !== -1) {
      data[isIndexOW] = 'PO'
      priorities = [...priorities, data[isIndexOW]]
    }

    const isIndexLF = data.indexOf('LF')

    if (isIndexLF !== -1) {
      data[isIndexLF] = 'LA'
      priorities = [...priorities, data[isIndexLF]]
    }

    const isIndexBN = data.indexOf('BN')

    if (isIndexBN !== -1) {
      data[isIndexBN] = 'BEN'
      priorities = [...priorities, data[isIndexBN]]
    }

    return priorities
  }

  const handlePaging = (
    data: {
      poIDNumber: string
      policyNo: string
      poMobilePhone: string
      poClientNumber: string
      proposalNo: string
    },
    start: number,
    size: number
  ) => {
    const sbData = {
      ...data,
      policyNo: data.policyNo?.length === 8 ? data.policyNo : '',
      proposalNo: data.policyNo?.length === 9 ? data.policyNo : ''
    }

    handleData(sbData, start, size)
  }

  return (
    <View style={SUREStyle.pageContent}>
      <ScrollView style={[SUREStyle.container]}>
        <View>
          <Text style={SUREStyle.sectionHeading}>{ht('360Inquiry')}</Text>
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <CEPSearchPanel AnimatedY={isAnimate} />
          <View
            style={{
              margin: 'auto',
              width: isMobile ? '90%' : '70%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              zIndex: 10
            }}
          >
            <View style={{ width: '88%', flex: 1 }}>
              <View style={SUREStyle.sectionRow}>
                {listInput.map((componentName, i) => {
                  return RenderComponent(componentName, i)
                })}
              </View>
            </View>
            {/* xử lý tìm kiếm nâng cao */}
            <View
              style={{
                position: 'relative',
                marginLeft: 10,
                marginBottom: 'auto',
                width: isMobile ? '90%' : '12%',
                paddingTop: 24,
                paddingHorizontal: 4
              }}
            >
              <TouchableOpacity
                style={[SUREStyle.buttonSearchAd, { flex: 1, flexDirection: 'row', marginBottom: 10 }]}
                onPress={() => {
                  setIsHide((pre) => !pre)
                }}
              >
                <Text style={[SUREStyle.textAdSearch, { paddingRight: 10 }]}>{cep('More')}</Text>

                <Text>
                  <assets.IconCepArrowDownRed />
                </Text>
              </TouchableOpacity>
              {isHide && (
                <View style={[SUREStyle.viewOptionBox, { width: 240, paddingLeft: 15 }]}>
                  <View style={SUREStyle.sectionCol}>
                    <View style={SUREStyle.col_1}>
                      <Checkbox
                        title={t('CustomerEngagement:phoneNumber')}
                        value={optionQuery.isMobilePhone}
                        textStyle={{ color: '#333333' }}
                        onChange={() => {
                          setOptionQuery((prevState) => ({
                            ...prevState,
                            isMobilePhone: !prevState.isMobilePhone
                          }))
                          checkExInputList('poMobilePhone')
                          setIsHide(false)
                        }}
                      />
                    </View>
                    <View style={SUREStyle.col_1}>
                      <Checkbox
                        title={t('CustomerEngagement:clientNum')}
                        value={optionQuery.isClientNumber}
                        textStyle={{ color: '#333333' }}
                        onChange={() => {
                          setOptionQuery((prevState) => ({
                            ...prevState,
                            isClientNumber: !prevState.isClientNumber
                          }))
                          checkExInputList('poClientNumber')
                          setIsHide(false)
                        }}
                      />
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
          {messageResponse && (
            <View
              style={{
                margin: 'auto',
                width: isMobile ? '90%' : '70%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginVertical: 15
              }}
            >
              <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
            </View>
          )}
          <View style={[SUREStyle.secondLine]}>
            <View style={[SUREStyle.col_1, { alignItems: 'center' }]}>
              {roles.includes(Permission['Search360Inquiry']) && (
                <TouchableOpacity style={SUREStyle.inputSearch} onPress={onViewReportClick}>
                  <Text style={{ textAlign: 'center', color: '#fff', width: 75, fontWeight: '700' }}>
                    {t('CustomerEngagement:Search')}
                  </Text>
                  <assets.IconCepSearch />
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
        {sortedItems.length > 0 && (
          <View>
            <Text style={SUREStyle.sectionHeading}>{cep('Result').toUpperCase()}</Text>
          </View>
        )}
        {sortedItems.length > 0 && (
          <View style={{ marginBottom: 20 }}>
            <TableContainer
              component={Paper}
              style={{ maxHeight: 400, borderWidth: 1, borderColor: '#D3DCE6', borderStyle: 'solid' }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                    <TableCell align="left" style={{ minWidth: 110 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}> {t('CustomerEngagement:Policy')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('policyNumber')
                          }}
                        >
                          {getClassNamesFor('policyNumber')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{t('CustomerEngagement:PolicyOwner')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('policyOwnerName')
                          }}
                        >
                          {getClassNamesFor('policyOwnerName')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 180 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{t('CustomerEngagement:PolicyStatus')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('policyStatus')
                          }}
                        >
                          {' '}
                          {getClassNamesFor('policyStatus')}{' '}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>
                          {t('CustomerEngagement:PremiumStatus')}
                        </Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('premiumStatus')
                          }}
                        >
                          {getClassNamesFor('premiumStatus')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{t('CustomerEngagement:clientNum')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('clientNumber')
                          }}
                        >
                          {getClassNamesFor('clientNumber')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{t('CustomerEngagement:IDNumber')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('idnumber')
                          }}
                        >
                          {getClassNamesFor('idnumber')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{t('CustomerEngagement:Role')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('roles')
                          }}
                        >
                          {getClassNamesFor('roles')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{t('CustomerEngagement:LifeAssured')}</Text>
                        <TouchableOpacity
                          onPress={() => {
                            requestSort('mainLifeAssuredName')
                          }}
                        >
                          {getClassNamesFor('mainLifeAssuredName')}
                        </TouchableOpacity>
                      </View>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedItems.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">
                        <Pressable
                          onPress={() =>
                            navigation.navigate('CEPPolicyDetailScreen', {
                              policyNum: row.policyNumber ?? '',
                              policyOwner: row && row.policyOwnerName ? row.policyOwnerName : '',
                              officeCode: '',
                              officeName: '',
                              primaryId: row.clientNumber ?? '',
                              clientNumber: row.clientNumber ? row.clientNumber : '',
                              isGAstaff: userInfo?.isGaLogin ? '1' : '0',
                              isSubmission: 'false',
                              proposalNo: row.proposalNo ?? ''
                            })
                          }
                        >
                          <Text
                            numberOfLines={1}
                            style={{
                              fontWeight: '600',
                              fontSize: 15,
                              color: '#1ea5fc',
                              textDecorationLine: 'underline'
                            }}
                          >
                            {row.policyNumber || '-'}
                          </Text>
                        </Pressable>
                      </TableCell>
                      <TableCell align="left">{row.policyOwnerName ? row.policyOwnerName : '-'}</TableCell>
                      <TableCell align="left">{row.policyStatus ? row.policyStatus : '-'}</TableCell>
                      <TableCell align="left">{row.premiumStatus ? row.premiumStatus : '-'}</TableCell>
                      <TableCell align="left">{row.clientNumber ?? '-'}</TableCell>
                      <TableCell align="left">{row.idnumber ?? '-'}</TableCell>
                      <TableCell align="left">{row.roles ? row.roles.join(' - ') : '-'}</TableCell>
                      <TableCell align="left">{row.mainLifeAssuredName ? row.mainLifeAssuredName : '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                handlePaging(submittedData, page, pageSize)
              }}
              onRowsPerPageChange={(e) => {
                const rowsPerPage = Number(e.target.value)
                setPageSize(rowsPerPage)
                if (pageNum * rowsPerPage >= totalItem) {
                  setPageNum(0)
                  handlePaging(submittedData, 0, rowsPerPage)
                } else {
                  handlePaging(submittedData, pageNum, rowsPerPage)
                }
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            ></TablePagination>
          </View>
        )}
      </ScrollView>
    </View>
  )
}

const SUREStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10
  },
  sectionContent: {
    backgroundColor: '#F4F4F4',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  sectionCol: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column'
  },
  secondLine: {
    marginTop: 30
  },
  col_4: {
    width: '100%',
    maxWidth: '24%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    // width: '100%',
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  cellWidth200: {
    minWidth: '200px'
  },
  sectionHeading: {
    paddingVertical: 20,
    // fontFamily: 'Noto Sans',
    fontFamily: 'NotoSans_700Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    color: '#4F4F4F'
  },
  sectionContentForm: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
    borderRadius: 8
  },
  tabHeading: {
    flex: 1,
    flexDirection: 'row'
  },
  tabHeadingBox: {
    paddingVertical: 15,
    paddingHorizontal: 40,
    color: '#000000',
    fontFamily: 'NotoSans_700Bold',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginLeft: 10,
    marginTop: 10
  },
  col_2: {
    width: '100%',
    maxWidth: '50%',
    paddingRight: 10,
    paddingLeft: 10
  },
  inputCss: {
    height: 50,
    backgroundColor: '#FFFFFF',
    border: '2px solid #D3DCE6',
    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 60,
    paddingLeft: 20
  },
  col_1: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 10,
    paddingLeft: 10
  },
  buttonSearchAd: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: 100,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    borderWidth: 2,
    borderColor: '#ed1b2e'
  },
  textAdSearch: {
    fontFamily: 'NotoSans_700Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    color: '#ed1b2e'
  },
  viewOptionBox: {
    position: 'absolute',
    top: '102%',
    left: 0,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3DCE6',
    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    zIndex: 100
  },
  col_3: {
    width: '100%',
    maxWidth: '33%',
    paddingRight: 15,
    paddingLeft: 15
  },
  inputSearch: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: 320,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  }
})
