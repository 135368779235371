import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Customer } from '../Client'
import { MoreData } from '../MoreData'

export interface ZaloPremiumCollection {
  tag: TransactionType.ZALO_PREMIUM_COLLECTION
  clientName: string

  note: string
}

export const ZaloPremiumCollection = (
  detail: api.ZaloPremiumCollection,
  client: Customer,
  sql: MoreData.PoNameFromSql
): ZaloPremiumCollection => {
  return {
    tag: TransactionType.ZALO_PREMIUM_COLLECTION,
    clientName: sql.body?.poName || client.body.name,

    note: detail.payload.body.message || ''
  }
}
