import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

import {
  Columns,
  Container,
  ContractMapping,
  ErrorHandling,
  GeneralInfoService,
  Panel,
  ProductExtInfo,
  ProductInfo,
  Table,
  TableField,
  useTranslator
} from '@pulseops/common'

import i18n from 'i18next'
import { StyleSheet } from 'react-native'

export interface ProductInfoData {
  productInfo: ProductExtInfo | null
  mainLAD: TableField.DataSources
}

export const ProductScreen = (props: {
  id: string
  data: ProductInfoData | null
  setData: (val: ProductInfoData | null) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const { t: tPolicy } = useTranslator('TaskManagement')
  const ct = useTranslator('contract').t
  const commont = useTranslator('common').t
  const [loading, bindLoading] = useLoading()

  const getData = (): ZIO<unknown, Throwable, ProductInfoData | null> =>
    pipe(
      GeneralInfoService.getProductInfo(props.id, i18n.language),
      ZIO.foldM(
        (_) => ZIO.succeed(null),
        (productInfo) =>
          ZIO.effect(() => {
            return {
              productInfo,
              mainLAD: productInfo.owner
                ? productInfo.owner.extInfos.map((e, i) => ({
                    number: ++i,
                    componentName: e.componentName,
                    status: ContractMapping.get(ct)(e.status),
                    sum: e.sumAssured,
                    instalmentPremium: e.installmentPremium,
                    paidToDate: e.paidToDate,
                    premiumCessationDate: e.premiumCessationDate
                  }))
                : []
            }
            // setProductInfo(productInfo)
            // if (productInfo.owner) {
            //   setMainLA(
            //     productInfo.owner?.extInfos.map((e, i) => ({
            //       number: ++i,
            //       componentName: e.componentName,
            //       status: ContractMapping.get(ct)(e.status),
            //       sum: e.sumAssured,
            //       instalmentPremium: e.installmentPremium,
            //       paidToDate: e.paidToDate,
            //       premiumCessationDate: e.premiumCessationDate
            //     }))
            //   )
            // }
          })
      )
    )

  const { productInfo, mainLAD } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      console.log(x)
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    productInfo: null,
    mainLAD: []
  }

  const columns = [
    {
      key: '1',
      title: `${commont('No.')}`,
      name: 'number',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '2',
      title: t('ComponentName'),
      name: 'componentName',
      flex: 3,
      styles: styles.columTextLeft
    },
    {
      key: '3',
      title: commont('Status'),
      name: 'status',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '4',
      title: `${t('SumAssured')} (${t('basic')})`,
      name: 'sum',
      styles: styles.columTextRight,
      flex: 2
    },
    {
      key: '5',
      title: `${t('InstalmentPremium')} (${t('basic')})`,
      name: 'instalmentPremium',
      styles: styles.columTextRight,
      flex: 2
    },
    {
      key: '6',
      title: `${t('PaidToDate')} (${t('basic')})`,
      name: 'paidToDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '7',
      title: `${t('PremCessDate')} (${t('basic')})`,
      name: 'premiumCessationDate',
      flex: 2,
      styles: styles.columTextLeft
    }
  ]

  const lifeAssuredColumns: Columns[] = [
    {
      key: '1',
      title: `${commont('No.')}`,
      name: 'number',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '2',
      title: t('ComponentName'),
      name: 'componentName',
      flex: 3,
      styles: styles.columTextLeft
    },
    {
      key: '3',
      title: t('CoverageStatus'),
      name: 'status',
      flex: 1,
      styles: styles.columTextLeft
    },
    {
      key: '4',
      title: `${t('SumAssured')} (${t('rider')})`,
      name: 'sum',
      flex: 2,
      styles: styles.columTextRight
    },
    {
      key: '5',
      title: `${t('InstalmentPremium')} (${t('rider')})`,
      name: 'instalmentPremium',
      flex: 2,
      styles: styles.columTextRight
    },
    {
      key: '6',
      title: `${t('PaidToDate')} (${t('rider')})`,
      name: 'paidToDate',
      flex: 2,
      styles: styles.columTextLeft
    },
    {
      key: '7',
      title: `${t('PremCessDate')} (${t('rider')})`,
      name: 'premiumCessationDate',
      flex: 2,
      styles: styles.columTextLeft
    }
  ]

  function getLaTableFields(la: ProductInfo) {
    return la.extInfos.map((l, i) => ({
      number: (++i).toString(),
      componentName: l.componentName,
      status: ContractMapping.get(ct)(l.status),
      sum: l.sumAssured,
      instalmentPremium: l.installmentPremium,
      paidToDate: l.paidToDate,
      premiumCessationDate: l.premiumCessationDate
    }))
  }

  // console.log('productInfo', productInfo);

  return (
    <Container loading={loading} display={!!productInfo}>
      <Panel title={productInfo?.owner?.name ?? ''} allowToggle={true} titleOutline={tPolicy('MainLifeAssured')}>
        <Table
          columns={columns}
          dataSource={mainLAD}
          headerContainerStyle={{ alignItems: 'flex-start' }}
          headerTitleStyle={{ textAlign: 'left' }}
        />
      </Panel>
      {productInfo?.laClient.slice(0, 1).map((item, i) => (
        <Panel title={item?.name || '-'} titleOutline={commont('LifeAssured')} key={`ProductScreenMAIN-${i}`}>
          <Table columns={lifeAssuredColumns} dataSource={getLaTableFields(item)} />
        </Panel>
      ))}
      {productInfo?.laClient.slice(1).map((item, i) => (
        <Panel title={item?.name || '-'} key={`ProductScreenSUB-${i}`}>
          <Table columns={lifeAssuredColumns} dataSource={getLaTableFields(item)} />
        </Panel>
      ))}
    </Container>
  )
}

const styles = StyleSheet.create({
  columTextRight: {
    textAlign: 'right',
    paddingHorizontal: 15
  },
  columTextLeft: {
    textAlign: 'left',
    paddingHorizontal: 15
  }
})
