export namespace FatcaDeclaration {
  export interface Detail {
    poName: string
    gaOffice: string
    clientId: string
  }

  export interface SubmitData {
    customer: {
      clientId: string
    }
    attributesExt: {
      PRIMARY_POLICY: string
      PO_CLIENT_NUMBER: string
      GA_OFFICE_CODE: string
      DOCUMENTS: {
        name: string
        url: string
      }[]
    }
  }
}
