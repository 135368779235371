import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PulseOpsFormat } from '@pulseops/common'
// import { PulseOpsFormat } from '@pulseops/core'

export namespace ReportService {
  export interface Column {
    id?: string
    name: string
    width?: number
    horizontal?: string
  }

  export interface Table {
    ref: string
    rowRef: number
    colRef: number
  }

  export interface Summary {
    title: string
    fromDate: Date
    toDate: Date
    category: string
  }

  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }

  export interface SumaryWithPaymentReport {
    totalCurrency: string
    title: string
    fromDate: Date
    toDate: Date
    totalRecord: number
  }

  const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

  export const exportTemplateRP0001 = (
    tableInfo: Table,
    summary: Summary,
    columns: Column[],
    rows: any[][],
    extension: string = Extension.EXCEL,
    fullFileName?: string,
    fullSheetName?: string
  ) => {
    const fileName = !!fullFileName ? fullFileName : 'RP0001 - Submission Report'
    const sheetName = !!fullSheetName ? fullSheetName : 'RP0001 - Submission (eForm)'
    const name = 'Calibri'
    const horizontal = 'center'
    const vertical = 'middle'
    const wrapText = true
    const bold = true
    const size = 11
    const font = { bold, name, size }
    const isExcel = extension === Extension.EXCEL

    const { colRef, rowRef, ref } = tableInfo
    const { category, fromDate, toDate, title } = summary
    const rowEnd = rows.length + rowRef

    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName)

    ws.views = [{ showGridLines: false }]
    columns.forEach((column, cIndex) => {
      const index = colRef + cIndex
      const width = column.width
      const horizontal = column.horizontal as any
      ws.getColumn(index).alignment = { horizontal, vertical, wrapText }
      ws.getColumn(index).width = width

      ws.getCell(1, index).value = '' // init for export csv
      for (let rIndex = rowRef; rIndex <= rowEnd; rIndex++) {
        ws.getCell(rIndex, index).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }
    })

    const writeExcel = () => {
      ws.mergeCells('B2:L2')
      ws.getCell('B2').value = title
      ws.getCell('B2').style = { font: { bold, size: 20 } }
      ws.getCell('B2').alignment = { vertical, horizontal }

      ws.getCell('B4').alignment = { horizontal: 'left' }
      ws.getCell('B4').value = {
        richText: [
          { font, text: 'Reporting period: ' },
          {
            font: { name, size },
            text: `${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
          }
        ]
      }

      ws.getCell('B5').alignment = { horizontal: 'left' }
      ws.getCell('B5').value = {
        richText: [
          { font, text: 'Reporting date: ' },
          {
            font: { name, size },
            text: PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')
          }
        ]
      }

      ws.getCell('B6').alignment = { horizontal: 'left' }
      ws.getCell('B6').value = {
        richText: [
          { font, text: 'Category: ' },
          {
            font: { name, size },
            text: category
          }
        ]
      }
    }

    const writeCSV = () => {
      ws.getCell('B2').value = title
      ws.getCell('B4').value = `Reporting period: ${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
      ws.getCell('B5').value = `Reporting date: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`
      ws.getCell('B6').value = `Category: ${category}`
    }

    isExcel ? writeExcel() : writeCSV()

    ws.addTable({
      name: 'SubmissionReport',
      ref,
      headerRow: true,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true
      },
      columns,
      rows
    })

    return pipe(
      ZIO.fromPromise(() => (isExcel ? wb.xlsx : wb.csv).writeBuffer()),
      ZIO.map((data) => new Blob([data], { type })),
      ZIO.tap((blob) => {
        FileSaver.saveAs(blob, `${fileName}.${extension}`)
        return ZIO.unit
      })
    )
  }

  export const exportTemplatePaymentReport = async (
    tableInfo: Table,
    summary: SumaryWithPaymentReport,
    columns: Column[],
    rows: any[][],
    extension: string = Extension.EXCEL,
    fullFileName?: string,
    fullSheetName?: string
  ) => {
    const isExcel = extension === Extension.EXCEL
    const fileName = !!fullFileName ? fullFileName : 'RP0002 - Payment Report'
    const sheetName = !!fullSheetName ? fullSheetName : 'RP0002 - Payment'

    const name = 'Calibri'
    const horizontal = 'center'
    const vertical = 'middle'
    const wrapText = true
    const bold = true
    const size = 13
    const font = { bold, name, size }
    const { colRef, rowRef, ref } = tableInfo

    const rowEnd = rows.length + rowRef

    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName, {
      pageSetup: {
        horizontalCentered: true,
        fitToPage: true,
        paperSize: 9,
        orientation: 'landscape',
        margins: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          header: 0,
          footer: 0
        }
      },
      views: [{ showGridLines: false }]
    })

    const { fromDate, toDate, title, totalCurrency, totalRecord } = summary

    columns.forEach((column, cIndex) => {
      const index = colRef + cIndex
      const width = column.width
      const horizontal = column.horizontal as any
      ws.getColumn(index).alignment = { horizontal, vertical, wrapText }
      ws.getColumn(index).width = width
      ws.getColumn(index).font = { size: 10, color: { theme: 1 } }

      ws.getCell(1, index).value = '' // init for export csv
      for (let rIndex = rowRef; rIndex <= rowEnd; rIndex++) {
        ws.getCell(rIndex, index).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }
    })

    const renderTitle = () => {
      ws.mergeCells('B2:R2')
      ws.getCell('B2').value = title
      ws.getCell('B2').style = { font: { bold, size: 20 } }
      ws.getCell('B2').alignment = { vertical, horizontal }
    }

    const renderSubtitle = () => {
      ws.getCell('I4').alignment = { horizontal: 'left' }
      ws.getCell('I4').value = {
        richText: [
          { font, text: 'Từ ngày: ' },
          { font: { name, size }, text: `${PulseOpsFormat.date(fromDate)}` }
        ]
      }

      ws.getCell('L4').alignment = { horizontal: 'left' }
      ws.getCell('L4').value = {
        richText: [
          { font, text: 'Đến ngày: ' },
          { font: { name, size }, text: `${PulseOpsFormat.date(toDate)}` }
        ]
      }

      ws.getCell('I5').alignment = { horizontal: 'left' }
      ws.getCell('I5').value = {
        richText: [
          { font, text: 'Ngày báo cáo: ' },
          {
            font: { name, size },
            text: PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')
          }
        ]
      }
    }

    const renderTotal = () => {
      ws.getCell('A6').alignment = { horizontal: 'left' }
      ws.getCell('A6').value = {
        richText: [
          { font, text: 'Tổng số phiếu chi: ' },
          {
            font: { name, size },
            text: `${totalRecord}`
          }
        ]
      }

      ws.getCell('A7').alignment = { horizontal: 'left' }
      ws.getCell('A7').value = {
        richText: [
          { font, text: 'Tổng số tiền đã chi: ' },
          {
            font: { name, size },
            text: `${totalCurrency}`
          }
        ]
      }
    }

    const writeExcel = () => {
      renderTitle()

      renderSubtitle()

      renderTotal()
    }

    const writeCSV = () => {
      ws.getCell('B2').value = title
      ws.getCell('M4').value = `From Date: ${PulseOpsFormat.date(fromDate)}`
      ws.getCell('O4').value = `To Date: ${PulseOpsFormat.date(toDate)}`
      ws.getCell('M5').value = `Reporting Date: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`
      ws.getCell('B6').value = `Total Payment: ${totalRecord}`
      ws.getCell('B7').value = `Total Amount ${totalCurrency}`
    }

    ws.addTable({
      name: 'PaymentReport',
      ref,
      headerRow: true,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true
      },
      columns,
      rows
    })

    isExcel ? writeExcel() : writeCSV()

    const buf = await (isExcel ? wb.xlsx : wb.csv).writeBuffer()

    FileSaver.saveAs(new Blob([buf]), `${fileName}.${extension}`)
  }

  export const exportAssignmenCSV = (rows: any[][], fileName: string, sheetName: string, extension: string) => {
    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName)
    const isExcel = extension === Extension.EXCEL
    ws.addRows(rows)

    return pipe(
      ZIO.fromPromise(() => (isExcel ? wb.xlsx : wb.csv).writeBuffer()),
      ZIO.map((data) => {
        return new Blob([data], { type })
      }),
      ZIO.tap((blob) => {
        FileSaver.saveAs(blob, `${fileName}.${extension}`)
        return ZIO.unit
      })
    )
  }

  export const exportLetterReport = (rows: any[][], columns: Column[], fileName: string, sheetName: string, fromDate?: Date, toDate?: Date, title?: string) => {
    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName)

    columns.forEach((column, cIndex) => {
      const index = cIndex + 1
      const width = column.width
      const horizontal = column.horizontal as any
      ws.getColumn(index).alignment = { horizontal }
      ws.getColumn(index).width = width
      ws.getColumn(index).font = { size: 10, color: { theme: 1 } }
      ws.getCell(1, index).value = ''
    })

    const renderTitle = () => {
      ws.mergeCells('C1:F1')
      ws.getCell('C1').value = title
      ws.getCell('C1').style = { font: { bold: true, size: 20 } }
    }

    const renderSubtitle = () => {
      ws.getCell('A2').alignment = { horizontal: 'left' }
      ws.getCell('A2').value = {
        richText: [
          { font: { size: 12 }, text: 'From date: ' },
          { font: { size: 12 }, text: `${PulseOpsFormat.date(fromDate)}` }
        ]
      }

      ws.getCell('A3').alignment = { horizontal: 'left' }
      ws.getCell('A3').value = {
        richText: [
          { font: { size: 12 }, text: 'To date: ' },
          { font: { size: 12 }, text: `${PulseOpsFormat.date(toDate)}` }
        ]
      }
    }

    ws.addTable({
      name: 'Letter_Report',
      ref: 'A7',
      headerRow: true,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true
      },
      columns,
      rows
    })

    renderTitle()
    renderSubtitle()

    return pipe(
      ZIO.fromPromise(() => wb.xlsx.writeBuffer()),
      ZIO.map((data) => {
        return new Blob([data], { type })
      }),
      ZIO.tap((blob) => {
        FileSaver.saveAs(blob, `${fileName}.${Extension.EXCEL}`)
        return ZIO.unit
      })
    )
  }
}
