import { CashOut } from './CashOutOption'

export namespace SpecialBonusData {
  export interface SBAccountGroups {
    sacsCode: string
    sacsType: string
  }

  export interface DetailData {
    specialBonus: number
  }

  export interface SubmitData {
    policy: {
      policyNo: string
    }
    attributesExt: {
      TOTAL_PAYOUT_AMOUNT: number
      SPECIAL_BONUS: number
    }
    cashOutOptions: Array<CashOut.Option>
  }
}
