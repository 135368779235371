import { FileUploadData, ImgUploadMutiple, SelectSearch, UploadManualService, useMobile } from '@pulseops/common'
import React, { forwardRef, useState, useImperativeHandle, SetStateAction, Dispatch, useEffect } from 'react'
import { FA, TextCustom } from './styles'
import { useTranslation } from 'react-i18next'
import { FormGroupEType, FormGroupI, formsGroup, FormValueI } from './models'
import { FlatList, View } from 'react-native'

import { useForm, Controller } from 'react-hook-form'
import { COLORS } from '../constants'
import { isEmpty, isNil, uniqBy } from 'lodash'
import { SelectOption } from './models'

const { Container, SelectContainer, RowContainer, TabText } = FA

enum POSITION {
  DEPARTMENT = 0,
  GROUP = 1,
  DOCUMENT_TYPE = 2,
  SUB_DOC = 3
}

export interface FormAreaRef {
  getValuesForm?: () => void
  submit?: () => void
  clearForm?: () => void
  files: FileUploadData[] | null
  setFiles: Dispatch<SetStateAction<FileUploadData[] | null>>
  setFormGroups: Dispatch<SetStateAction<FormGroupI[]>>
  formGoups: FormGroupI[]
  getAllsData: UploadManualService.DepartmentT17s2Type[]
  setGetAllsData: React.Dispatch<React.SetStateAction<UploadManualService.DepartmentT17s2Type[]>>
}

interface Props {
  ref: FormAreaRef
  onSubmit: (data: FormValueI) => void
}

const defaultValues: FormValueI = {
  department: null,
  group: null,
  mainDoc: null,
  subDoc: null
}

const FormArea = forwardRef<FormAreaRef, Props>((props, ref) => {
  const { t , i18n} = useTranslation()
  const { isMobile } = useMobile()
  const [formGoups, setFormGroups] = useState<FormGroupI[]>(() => formsGroup(t))
  const [files, setFiles] = useState<FileUploadData[] | null>(null)
  const [getAllsData, setGetAllsData] = useState<UploadManualService.DepartmentT17s2Type[]>([])
  const [checkEmpty, setCheckEmpty] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    watch,
    resetField
  } = useForm<FormValueI>({
    defaultValues
  })

  useImperativeHandle(ref, () => ({
    submit: () => { handleSubmit(props.onSubmit)(), setCheckEmpty(true) },
    getValuesForm: () => getValues(),
    clearForm: () => {
      setCheckEmpty(false)
      reset({
        ...defaultValues
      })
    },
    files,
    formGoups,
    setFiles,
    setFormGroups,
    setGetAllsData,
    getAllsData
  }))

  useEffect(() => {
    if(isEmpty(i18n.language)) return;
    setFormGroups(formsGroup(t))
  },[i18n.language])

  const getValuesForm = () => {
    const department = watch('department') ?? { label: '', value: '' }
    const group = watch('group') ?? { label: '', value: '' }
    const mainDoc = watch('mainDoc') ?? { label: '', value: '' }
    const subDoc = watch('subDoc') ?? { label: '', value: '' }
    return {
      department,
      group,
      mainDoc,
      subDoc
    }
  }

  const replaceFormItem =
    (arrays: FormGroupI[]) =>
    ({ position, options }: { position: number; options: string[] }) => {
      return arrays.fill(
        {
          ...arrays[position],
          options: options.map((elm) => ({
            label: elm,
            value: elm
          }))
        },
        position,
        position + 1
      )
    }

  const clearFields = (array: FormGroupI[]) => {
    return array.forEach((item) => resetField(`${item.type}`))
  }

  const mappingArrayFuture = (
    arrays: FormGroupI[],
    position: number,
    callBack: ({
      arraysEmptyOptions,
      diffArrays,
      results
    }: {
      arraysEmptyOptions: FormGroupI[]
      diffArrays: FormGroupI[]
      results: FormGroupI[]
    }) => void
  ) => {
    const arraysEmptyOptions = arrays
      .filter((_, index) => index > position)
      .map((elm) => ({
        ...elm,
        options: []
      }))
    const diffArrays = arrays.filter(
      ({ type: type1 }) => !arraysEmptyOptions.some(({ type: type2 }) => type2 === type1)
    )
    return callBack({ arraysEmptyOptions, diffArrays, results: [...diffArrays, ...arraysEmptyOptions] })
  }

  const handleResetField = (type: FormGroupEType) => {
    const formGoupsClone = [...formGoups]
    const position = formGoupsClone.findIndex((elm) => elm.type === type)

    return mappingArrayFuture(formGoupsClone, position, ({ arraysEmptyOptions, results }) => {
      clearFields(arraysEmptyOptions)
      setFormGroups(results)
    })
  }

  const getUniqueArrayObject = (arr: UploadManualService.DepartmentT17s2Type[], byType: FormGroupEType) => {
    return uniqBy(arr, byType)?.map((elm) => elm[byType])
  }

  const handleChangeSelect = (payload: SelectOption | null, item: FormGroupI) => {
    const { type } = item ?? {}
    const getAllsDataClone = [...getAllsData]
    const formGroupClone = [...formGoups]

    const replaceFormItemWithType = replaceFormItem(formGroupClone)

    if (isNil(payload)) return handleResetField(type)

    const { department, group, mainDoc } = getValuesForm() ?? {}

    const changeTypeHandler = {
      department: (callBack: (arrays: FormGroupI[]) => void) => {
        const filterGroup = getAllsDataClone.filter((elm) => elm.departmentCode === department.value)
        const groups = getUniqueArrayObject(filterGroup, 'group')

        const formGroupFuture = replaceFormItemWithType({
          position: POSITION.GROUP,
          options: groups
        })
        return callBack(formGroupFuture)
      },

      group: (callBack: (arrays: FormGroupI[]) => void) => {
        const filterDocumentsType = getAllsDataClone.filter(
          (elm) => elm.departmentCode === department.value && elm.group === group.value
        )
        const documentsType = getUniqueArrayObject(filterDocumentsType, 'mainDoc')
        const formGroupFuture = replaceFormItemWithType({
          position: POSITION.DOCUMENT_TYPE,
          options: documentsType
        })

        return callBack(formGroupFuture)
      },

      mainDoc: (callBack: (arrays: FormGroupI[]) => void) => {
        const filterSubDocs = getAllsDataClone.filter(
          (elm) => elm.departmentCode === department.value && elm.group === group.value && elm.mainDoc === mainDoc.value
        )
        const subDocs = getUniqueArrayObject(filterSubDocs, 'subDoc')
        const formGroupFuture = replaceFormItemWithType({
          position: POSITION.SUB_DOC,
          options: subDocs
        })

        return callBack(formGroupFuture)
      },
      subDoc: () => {}
    }

    return changeTypeHandler?.[type]?.((formGroupFuture) => {
      const formGroupFutureClone = [...formGroupFuture]
      const position = formGroupFutureClone.findIndex((elm) => elm.type === type)
      mappingArrayFuture(formGroupFutureClone, position + 1, ({ results }) => {
        return setFormGroups(results)
      })
      mappingArrayFuture(formGroupFutureClone, position, ({ arraysEmptyOptions }) => {
        return clearFields(arraysEmptyOptions)
      })
    })
  }

  const renderFormGroup = (item: FormGroupI, index: number) => {
    return (
      <RowContainer>
        <SelectContainer isMobile={isMobile}>
          <Controller
            name={item.type}
            key={`${item?.label}-${index}`}
            control={control}
            rules={{
              required: { value: true, message: `${t('message:MS020009', { field: item.label })}` }
            }}
            render={({ field: { value, onChange }, fieldState: { error }  }) => (
              <SelectSearch
                required
                label={item.label}
                options={item.options}
                onChange={(e) => {
                  onChange(e), handleChangeSelect(e, item)
                }}
                placeholder={item.placeholder}
                popupIcon={<item.popupIcon />}
                value={value as SelectOption}
                errorMessage={error && error.message}
              />
            )}
          />
        </SelectContainer>
      </RowContainer>
    )
  }

  return (
    <Container>
      <FlatList
        data={formGoups}
        numColumns={2}
        columnWrapperStyle={{ margin: '12px' }}
        renderItem={({ item, index }) => renderFormGroup(item, index)}
      />

      <View style={{ marginLeft: 10 }}>
        <TextCustom textColor={COLORS.ROLLING_STONE} fontWeight="bold" fontSize="15px">
          {t('IFQ:FileAttachment')} <TabText>*</TabText>
        </TextCustom>
        <ImgUploadMutiple
          value={files ?? []}
          onChange={(files) => setFiles([...files])}
          timeFormat={'DD/MM/YYYY HH:mm'}
          maxNumFile={100}
        />
        {files && isEmpty(files) && checkEmpty && (
          <TextCustom textColor={COLORS.ALIZARIN} fontStyle="italic" fontSize="13px">
            {t('message:MS020001', { field: t('utilities:FileAttachment') })}
          </TextCustom>
        )}
      </View>
    </Container>
  )
})

export default FormArea
