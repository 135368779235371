import * as React from 'react'
import { Color } from '@material-ui/lab'
import { SnackbarOrigin } from '@material-ui/core'

export type ShowToastFunc = (
  message: string,
  type?: Color,
  anchorOrigin?: SnackbarOrigin,
  duration?: number,
  canClose?: boolean
) => void

export type Breadcrumb = {
  title: string
  navigate: (() => void) | null
}

export namespace AppContext {
  export interface AppContextState {
    showToast: ShowToastFunc
    showGlobalLoading: (visible: boolean) => void
    isGlobalLoadingVisible: boolean
    breadcrumbs: Breadcrumb[]
    changeBreadcrumb: (breadcrumbs: Breadcrumb[]) => void
    footer: JSX.Element | null
    getFooter: (supplementFooter: JSX.Element | null) => void
    footerClaimActions: any[]
    getFooterClaim: (footerClaimActions: any[]) => void
    isHiddenTopMenu: boolean
    setIsHiddenTopMenu: (isOpen: boolean) => void
  }

  const AppContextDefaultValue: AppContextState = {
    showToast: (_) => {},
    showGlobalLoading: (_) => {},
    isGlobalLoadingVisible: false,
    breadcrumbs: [],
    changeBreadcrumb: (_) => {},
    footer: <></>,
    getFooter: (_) => {},
    footerClaimActions: [],
    getFooterClaim: (_) => {},
    isHiddenTopMenu: false,
    setIsHiddenTopMenu: (_) => {}
  }

  export const AppContextInstance = React.createContext<AppContextState>(AppContextDefaultValue)
}
