import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'

export namespace BasicSearchForm {
  const idNumber = pipe(
    form2.string.optional,
    form2.string.minLength(8, () => i18next.t('message:MS090008'))
  )

  const policyNo = pipe(
    form2.string.optional,
    form2.string.minLength(8, () => i18next.t('common:MS090001'))
  )

  const PhoneNumber = pipe(
    form2.string.optional,
    form2.string.minLength(10, () => i18next.t('message:MS050013', { field: i18next.t('message:MS990057') }))
  )

  const clientNo = pipe(
    form2.string.optional,
    form2.string.minLength(8, () => i18next.t('message:MS990016'))
  )

  const proposalNo = pipe(
    form2.string.optional,
    form2.string.minLength(9, () => i18next.t('message:MS990016'))
  )
  const Base = t.type({
    poIDNumber: form2.optional(idNumber),
    policyNo: form2.optional(policyNo),
    poMobilePhone: form2.optional(PhoneNumber),
    poClientNumber: form2.optional(clientNo),
    proposalNo: form2.optional(proposalNo)
  })

  type Base = t.TypeOf<typeof Base>

  //   type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(Base)

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}
