import React from 'react'
import { A14S0Data, A14S19Response, A14S1Data, A14S5Data, ExtensionNumberReponse } from '../ib-service'
import { SelectOption } from '@pulseops/common'

type EmailManagementContext = {
  masterDataList: {
    policyLists: (SelectOption & { policyOwner: string })[]
    a14s0s: A14S0Data[]
    a14s1s: A14S1Data[]
    a14s5s: A14S5Data[]
    a14s19: A14S19Response[]
    a14s18: ExtensionNumberReponse[]
  }
  permissions: string[]
}

const defaultDetailContext: EmailManagementContext = {
  masterDataList: { policyLists: [], a14s0s: [], a14s1s: [], a14s5s: [], a14s19: [], a14s18: [] },
  permissions: []
}

export const EmailManagementContext = React.createContext<EmailManagementContext>(defaultDetailContext)
