import * as React from 'react'
import { withStyles } from '@material-ui/core'
import * as core from '@material-ui/core'
import { ControlProps } from '@pulseops/common'
import { StyleProp, TextStyle, View, ViewStyle, Text, StyleSheet, TextInput } from 'react-native'

type InputProps = ControlProps<string | null> & {
  label?: string
  value?: string | null
  type?: string
  placeholder?: string
  maxLength?: number
  disabled?: boolean
  isDigit?: boolean
  controlStyle?: React.CSSProperties
  title?: string
  containerStyle?: StyleProp<ViewStyle>
  labelStyle?: TextStyle
  required?: boolean
  inputStyle?: StyleProp<ViewStyle & TextStyle>
  textAlign?: 'left' | 'right' | 'center' | 'auto' | 'justify'
  suffix?: string | JSX.Element
  icon?: JSX.Element | undefined
  multiline?: boolean
  inputType?: 'text' | 'number' | 'money'
}

export const BorderFilledInput = (props: InputProps) => {
  return (
    <View style={props.containerStyle}>
      {props.title && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }, props.labelStyle]}>
            {props.title}
          </Text>
          {props.required && (
            <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>
          )}
        </View>
      )}
      <View style={props.suffix ? { position: 'relative' } : {}}>
        <TextInput
          style={
            props.disabled
              ? [
                  styles.common,
                  props.icon ? styles.circelInputIconCss : styles.circelInputCss,
                  props.inputStyle,
                  { textAlign: props.textAlign, paddingRight: props.suffix ? 35 : 0 }
                ]
              : [
                  styles.common,
                  props.icon ? styles.circelInputIconCss : styles.circelInputCss,
                  props.inputStyle,
                  { paddingRight: props.suffix ? 35 : 0 },
                  props.multiline ? styles.outLine : styles.dashline
                ]
          }
          placeholder={props.placeholder ?? ''}
          placeholderTextColor={'#70777E'}
          value={props.value ?? undefined}
          onChange={(event) => {
            //@ts-ignore
            let val = event.target.value || ''
            if (props.isDigit) {
              val = val.replace(/[^0-9]/g, '')
            }
            props.onChange && props.onChange(val)
          }}
          //@ts-ignore
          type={props.inputType || 'text'}
          inputProps={{
            ...(props.maxLength != null ? { maxLength: props.maxLength } : {})
          }}
          disabled={props.disabled}
          maxLength={props.maxLength}
        />
      </View>
      {props.errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{props.errorMessage}</Text> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  common: {
    minHeight: 33,
    fontSize: 15
    // lineHeight: 20
  },
  outLine: {
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8
  },
  dashline: {},

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  },
  circelInputCss: {
    height: 50,
    backgroundColor: '#FFFFFF',
    border: '2px solid #D3DCE6',
    // boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 60,
    paddingLeft: 20
  },
  circelInputIconCss: {
    height: 44,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3DCE6',
    // boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 60,
    paddingLeft: 20
  },
  iconStyle: {
    position: 'absolute',
    right: 10,
    top: 11
  }
})
