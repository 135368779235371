import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { StorageBlob } from '../storage-blob'
import { FatcaDeclaration, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'
export namespace FatcaDeclarationService {
  export const getDetail = (policyNum: string): Task<FatcaDeclaration.Detail> =>
    pipe(
      SubmissionService.getPolicyHeader(policyNum),
      ZIO.map((data): FatcaDeclaration.Detail => {
        return {
          poName: data.ownerName,
          gaOffice: '',
          clientId: data.clientId
        }
      })
    )

  export const submit =
    (
      primaryPolicyNo: string,
      clientId: string,
      data: FatcaDeclaration.SubmitData,
      documents: StorageBlob.FileContentSubmit[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/customer/${clientId}/taxInfo`,
        {
          body: data
        },
        primaryPolicyNo,
        requestauth,
        documents,
        clientId
      )
}
