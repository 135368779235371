import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBGeneralTable } from '../../../../../common'
import { DocsData, DocumentsTabsData, IBService } from '../../../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { isEmpty, orderBy } from 'lodash'
import { downloadURI } from '@pulseops/common'

interface Props {
  data: DocumentsTabsData
  setData: (data: DocumentsTabsData) => void
  policyNumber: string
}

export const IBPolicyInfomationDocs = (props: Props) => {
  const { data, setData, policyNumber } = props
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)
  const dataTable = [
    {
      label: t('MainDoc'),
      field: 'maindoc'
    },
    {
      label: t('SubDoc'),
      field: 'subdoc'
    },
    {
      label: t('DocumentTitle'),
      field: 'documenttitle'
    },
    {
      label: t('DocumentId'),
      field: 'docid',
      render: (value: string, index: number) => {
        return (
          <Pressable
            onPress={() => {
              const object = (data.PolicyInfomationDocs as DocsData[])[index]
              pipe(
                IBService.downloadURL(object.url as string),
                ZIO.flatMap((res) => {
                  return ZIO.zipPar(
                    ZIO.succeed(res),
                    ZIO.fromPromise(() => res.blob())
                  )
                }),
                ZIO.tap(([res, blob]) => {
                  const type = res.headers.get('content-type') || ''
                  downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
                  return ZIO.unit
                }),
                ZIO.unsafeRun({})
              )
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('AddedOn'),
      field: 'datecreated',
      format: 'dateTime'
    },
    {
      label: t('ModifiedOn'),
      field: 'datelastmodified',
      format: 'dateTime'
    },
    {
      label: t('BatchNo'),
      field: 'batchno'
    }
  ]

  React.useEffect(() => {
    if (isEmpty(data.PolicyInfomationDocs)) {
      pipe(
        IBService.getDocsList(policyNumber, 'POLICYINFO'),
        ZIO.map((res) => {
          if (res.data) {
            setData({ ...data, PolicyInfomationDocs: res.data })
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  return (
    <View>
      <IBGeneralTable
        data={
          orderBy(
            data.PolicyInfomationDocs?.map((item) => ({
              ...item.metaData,
              datecreated: moment(item.metaData.datecreated, 'DD/MM/YYYY HH:mm:ss').format('YYYYMMDDHHmmss'),
              datelastmodified: moment(item.metaData.datelastmodified, 'DD/MM/YYYY HH:mm:ss').format('YYYYMMDDHHmmss')
            })),
            'datecreated',
            'desc'
          ) || []
        }
        dataTable={dataTable}
        paginationStyle={{ marginRight: 60 }}
        loading={loading}
        autoPaging
      />
    </View>
  )
}

const styles = StyleSheet.create({})
