import { UIO, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import {
  AppContext,
  PulseOpsFormat,
  RequestListTransactionInfo,
  SourceType,
  formatNumberWithComma
} from '@pulseops/common'
import { PartnerChannel, PCReason } from './PcReason'
import { GridView } from './gridview'
import { styles, ContainerView } from './styles'
import moment from 'moment'
import _ from 'lodash'
export const TransactionInfoView = (props: { detail: RequestListTransactionInfo }) => {
  const { detail } = props
  const { t: ca, i18n } = useTranslation('Cashless')

  const transactionFields = React.useMemo(() => {
    return [
      {
        label: ca('bankPartnerName'),
        value: (
          <View style={{ flexDirection: 'row' }}>
            {detail.source === SourceType.Offline ? (
              <>
                <Text style={{ fontSize: 15, marginRight: 5 }}>Offline</Text>
                <Text style={{ marginRight: 15, fontSize: 15 }}>
                  ({PartnerChannel.getPartnerNameByCode(detail.bankPartnerName ?? '')(i18n.language)})
                </Text>
                <Text style={{ fontSize: 15 }}>{detail.bankPartnerName ?? ''}</Text>
              </>
            ) : (
              <>
                <Text style={{ marginRight: 15, fontSize: 15 }}>
                  {PartnerChannel.getPartnerNameByCode(detail.bankPartnerName ?? '')(i18n.language)}
                </Text>
                <Text style={{ fontSize: 15 }}>{detail.bankPartnerName ?? ''}</Text>
              </>
            )}
          </View>
        )
      },
      {
        label: ca('customerSubmitDate'),
        value: moment(detail.customerSubmitDate).format('DD/MM/YYYY')
      },
      {
        label: ca('partnerTransferDate'),
        value: moment(detail.partnerTransferDate).format('DD/MM/YYYY')
      },
      {
        label: ca('spendAmount'),
        value: PulseOpsFormat.thousandSepartor(detail.spendAmount) + ' VND'
      },
      {
        label: ca('refNo'),
        value: detail.refNo
      },
      {
        label: ca('reason'),
        value:
          detail.reason && detail.remark
            ? PCReason.getDescriptionReasonByCode(detail.reason ?? '') + ' - ' + detail.remark
            : detail.remark && !detail.reason
            ? detail.remark
            : !detail.remark && detail.reason
            ? PCReason.getDescriptionReasonByCode(detail.reason ?? '')
            : '-'
      },
      {
        label: ca('policyNumberAppNumber'),
        value: detail.policyNumberAppNumber
      },
      {
        label: ca('adjustedPolicyNumber'),
        value: detail.adjustedPolicyNumber
      },
      {
        label: ca('remainingAmount'),
        value: formatNumberWithComma(detail.remainingAmount) + ' VND'
      },
      {
        label: ca('cardType'),
        value: detail.cardType
      },
      {
        label: ca('payeeName'),
        value: detail.payeeName
      },
      {
        label: ca('mobileNumber'),
        value: detail.mobileNumber
      },
      {
        label: ca('bankTransferPendingNo'),
        value: detail.bankTransferPendingNo ?? '-'
      }
    ]
  }, [detail, i18n.language])

  return (
    <View>
      <Text style={styles.sessonText}>{ca('transactionInfo')}</Text>
      <ContainerView>
        <GridView data={transactionFields} />
      </ContainerView>
    </View>
  )
}
