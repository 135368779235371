import React, { useState, useContext } from 'react'
import { View } from 'react-native'
import {
  ModalComponent,
  useMobile,
  TextInput,
  Select,
  WfMockData,
  SelectOption,
  PulseOpsService,
  Toast,
  AppContext,
  AuthService,
  ErrorHandling,
  GeneralService,
  TaskType
} from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { Color } from '@material-ui/lab'

export type EndPopupProps = {
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  selectedValue: {
    taskId: string
    updateToCore: boolean
    category?: string
    caseId?: string
  }
}

export const EndPopup = (props: EndPopupProps) => {
  const { onClose, onSuccess, open, selectedValue } = props
  const { isWide } = useMobile()

  const [note, setNote] = useState<string>('')
  const [reason, setReason] = useState<SelectOption | null>(null)
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, isGlobalLoadingVisible, showToast } = useContext(AppContext.AppContextInstance)

  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }

  const { t, i18n } = useTranslation()

  const handleClose = () => {
    setNote('')
    setReason(null)
    onClose()
  }

  const handleEndAction = () => {
    if (reason) {
      showGlobalLoading(true)
      pipe(
        AuthService.userInfo,
        ZIO.flatMap(({ email }) => {
          return PulseOpsService.endTask(
            selectedValue.taskId,
            {
              author: email,
              message: note,
              reason: reason.value
            },
            selectedValue.updateToCore,
            selectedValue.category
          )
        }),
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          showToastInternal(t('requestInfo:EndFailed'), 'error')
          return ZIO.fail(null)
        }),
        ZIO.tap(() => {
          if (selectedValue.category === TaskType.Claim) {
            pipe(GeneralService.triggerActionClaim(selectedValue.caseId || ''), ZIO.unsafeRun({}))
          }
          showGlobalLoading(false)
          handleClose()
          showToast(t('requestInfo:EndSuccess'), 'success')
          onSuccess && onSuccess()
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const reasonOptions: SelectOption[] =
    pipe(
      selectedValue.category ?? '',
      WfMockData.reasonEndWithCategory,
      ZIO.succeed,
      ZIO.map((reasons) => {
        return reasons.map((r) => ({
          label: i18n.language === 'vi' ? r.nameVi ?? r.name : r.name,
          value: r.code
        }))
      }),
      ErrorHandling.runDidUpdate([i18n.language])
    ) ?? []

  return (
    <ModalComponent
      title={t('requestInfo:End')}
      visible={open && !isGlobalLoadingVisible}
      onClose={handleClose}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 39, marginEnd: 15 },
          action: handleClose
        },
        {
          text: t('requestInfo:End'),
          type: 'filled',
          disabled: !reason,
          loading,
          onlyWide: false,
          style: { height: 39 },
          action: handleEndAction
        }
      ]}
    >
      <View
        style={{
          marginHorizontal: 20,
          marginTop: isWide ? 20 : 16
        }}
      >
        <View style={{ marginBottom: 16, width: isWide ? '50%' : '100%' }}>
          <Select
            value={reason}
            onChange={setReason}
            label={t('claim:Reason')}
            required={true}
            // options={WfMockData.reasonsEnd.map((item) => ({
            //   label: item.name,
            //   value: item.code
            // }))}
            options={reasonOptions}
          />
        </View>
        <TextInput
          title={t('TaskManagement:Comment')}
          value={note}
          onChange={setNote}
          placeholder={t('TaskManagement:WriteComment')}
          textInputProps={{
            maxLength: 500,
            multiline: true,
            style: [
              {
                borderWidth: 1,
                borderColor: '#D3DCE6',
                borderRadius: 8,
                padding: 16,
                fontSize: 15,
                lineHeight: 22,
                color: '#000000'
              },
              { marginTop: 10, height: isWide ? 140 : 60 }
            ],
            placeholderTextColor: '#B0B0B0'
          }}
        />
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
