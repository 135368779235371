import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import * as A from 'fp-ts/Array'
import { SelectOption } from '@pulseops/common'
import { GeneralInfoApi } from './general-info-api'
import { ProductCode } from './model/product-code'

export namespace FormService {
  export const getCountryOptions = pipe(
    GeneralInfoApi.getCountries,
    ZIO.map(
      A.map(
        (c): SelectOption => ({
          label: c.name,
          value: c.code
        })
      )
    ),
    ZIO.cached()
  )

  export const getCountryName = (code: string | null, options: SelectOption[]) => {
    const option = options.find((_) => _.value === code)
    return option ? option.label : code || '-'
  }

  export const getProvinceOptions = pipe(
    GeneralInfoApi.getProvinces,
    ZIO.map(
      A.map(
        (c): SelectOption => ({
          label: c.name,
          value: c.code
        })
      )
    ),
    ZIO.cached()
  )
  export const getProvinceName = (code: string | null, options: SelectOption[]) => {
    const option = options.find((_) => _.value === code)
    return option ? option.label : code || '-'
  }

  export const getDistrictOptions = (provinceCode: string) =>
    pipe(
      GeneralInfoApi.getDistricts(provinceCode),
      ZIO.map(
        A.map(
          (c): SelectOption => ({
            label: c.name,
            value: c.code
          })
        )
      )
    )
  export const getDistrictOptionsFull = pipe(
    GeneralInfoApi.getAllLocation,
    ZIO.map((localtion) => localtion.districts),
    ZIO.cached()
  )
  export const getDistrictName = (
    district: string | null,
    province: string | null,
    options: Record<string, string>
  ) => {
    return district && province ? options[province + district] || province + district : '-'
  }

  export const getWardOptions = (provinceCode: string, districtCode: string) =>
    pipe(
      GeneralInfoApi.getWards({ provinceCode, districtCode }),
      ZIO.map(
        A.map(
          (c): SelectOption => ({
            label: c.name,
            value: c.code
          })
        )
      )
    )
  export const getWardOptionsFull = pipe(
    GeneralInfoApi.getAllLocation,
    ZIO.map((localtion) => localtion.wards),
    ZIO.cached()
  )
  export const getWardName = (ward: string | null, options: Record<string, string>) => {
    return ward ? options[ward] || ward : '-'
  }

  export const genderOptions: SelectOption[] = [
    { label: 'Nam', value: 'M' },
    { label: 'Nữ', value: 'F' }
  ]
  export const getGenderName = (code?: string | null): string => {
    switch (code) {
      case 'M':
        return 'Nam'
      case 'F':
        return 'Nữ'
      default:
        return code || '-'
    }
  }

  export const maritalStatusOptions: SelectOption[] = [
    { label: 'Đã kết hôn', value: 'M' },
    { label: 'Độc thân', value: 'S' }
  ]
  export const getMaritalStatusName = (code: string | null): string => {
    switch (code) {
      case 'M':
        return 'Đã kết hôn'
      case 'S':
        return 'Độc thân'
      default:
        return code || '-'
    }
  }

  export const termOptions: SelectOption[] = new Array(5)
    .fill(null)
    .map((v, i) => (i + 1).toString())
    .map(
      (a): SelectOption => ({
        label: a,
        value: a
      })
    )

  export const yesNoOptions: SelectOption[] = [
    { label: 'Có', value: 'Có' },
    { label: 'Không', value: 'Không' }
  ]
  export const getYesNoName = (code: string | boolean | null): string => {
    switch (code) {
      case 'Y':
      case true:
      case 'Có':
        return 'Có'
      case 'N':
      case false:
      case 'Không':
        return 'Không'
      default:
        return code || '-'
    }
  }
  export const decisionOptions: SelectOption[] = [
    { label: 'Có', value: 'Y' },
    { label: 'Không', value: 'N' }
  ]
  export const decisionYes = 'Y' as const
  export const decisionNo = 'N' as const
  export const overFclYes = 'Có' as const
  export const overFclNo = 'Không' as const
  export const getDecisionName = getYesNoName

  export const entityOptions: SelectOption[] = [
    { label: 'Tổ chức tài chính', value: 'Tổ chức tài chính' },
    { label: 'Quỹ tín thác', value: 'Quỹ tín thác' },
    { label: 'Loại hình khác', value: 'Loại hình khác' }
  ]

  export const paymentModeOptions: SelectOption[] = [
    { label: 'Năm', value: 'Năm' },
    { label: 'Nửa năm', value: 'Nửa năm' }
  ]

  export const products = {
    [ProductCode.GBTL]: 'Bảo hiểm Tử Vong, Thương Tật Toàn Bộ Và Vĩnh Viễn',
    [ProductCode.GBAD]: 'Bảo hiểm Tử Vong Và Thương Tật Do Tai Nạn',
    [ProductCode.GRAD]: 'Bảo hiểm Tử Vong Và Thương Tật Do Tai Nạn',
    [ProductCode.GRHC]: 'Bảo hiểm Bảo Vệ Sức Khỏe'
  }

  export const productOptions: SelectOption[] = [
    { label: `GBTL - ${products.GBTL}`, value: 'GBTL' },
    { label: `GBAD - ${products.GBAD}`, value: 'GBAD' }
  ]

  export const hcPlanOptions: SelectOption[] = new Array(20)
    .fill(null)
    .map((v, i) => (i + 1).toString())
    .map((value) => ({ label: value, value }))

  export const statusOptions: SelectOption[] = [
    { label: 'Hiệu lực', value: 'Active' },
    { label: 'Mất hiệu lực', value: 'Inactive' },
    { label: 'Hoàn tất', value: 'Complete' }
  ]
  export const getStatusName = (code: string | null): string => {
    switch (code) {
      case 'Active':
        return 'Hiệu lực'
      case 'Inactive':
        return 'Mất hiệu lực'
      case 'Complete':
        return 'Hoàn tất'
      default:
        return code || '-'
    }
  }
}
