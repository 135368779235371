import { enumC } from '@mxt/zio/codec'

export enum UwDecisionCode {
  Medical = 'ME/IS',
  Accept = 'AC',
  ConditionalAccept = 'CA',
  Postpone = 'PP',
  Decline = 'DC',
  NotTakenUp = 'NTU',
  Withdraw = 'WD'
}

export const UwDecisionCodeC = enumC(UwDecisionCode)

export const UwDecisionLabel = (decision: UwDecisionCode | null): string => {
  if (!decision) {
    return '-'
  }
  switch (decision) {
    case UwDecisionCode.Medical:
      return 'Mời khám/Bổ sung thông tin'
    case UwDecisionCode.Accept:
      return 'Chấp thuận'
    case UwDecisionCode.ConditionalAccept:
      return 'Chấp thuận có điều kiện'
    case UwDecisionCode.Postpone:
      return 'Tạm hoãn'
    case UwDecisionCode.Decline:
      return 'Từ chối'
    case UwDecisionCode.NotTakenUp:
      return 'Quá hạn'
    case UwDecisionCode.Withdraw:
      return 'Hủy HSYCBH'
  }
}
