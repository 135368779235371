import { ZIO } from '@mxt/zio'
import moment from 'moment'
import {
  AppConfig,
  AppContext,
  changeLanguage,
  ErrorHandling,
  form2,
  PulseOpsFormat,
  useMobile
} from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import { SafeAreaView, ImageBackground, Pressable, Linking, ScrollView, View, StyleSheet } from 'react-native'
import { assets, BorderFilledDatePicker, BorderFilledInput, Button, SC, Services } from './core'
import { LandingPageStackParamList } from './LandingPageStackParamList'
import { UniversalLink } from './const'
import { Header } from './Header'
import { withMinStringLength, withMaxStringLength } from './validators'

const FormC = t.type({
  id: pipe(
    form2.string.requiredM(() =>
      //
      i18next.t('message:MS020001', { field: i18next.t('landingPage:id') })
    ),
    withMinStringLength(8, 'message:MS090008'),
    withMaxStringLength(24, 'message:MS090008')
  ),
  dob: form2.date.requiredM(() =>
    //
    i18next.t('message:MS020001', { field: i18next.t('landingPage:dob') })
  ),
  phoneNumber: pipe(
    form2.string.requiredM(() =>
      //
      i18next.t('message:MS020001', { field: i18next.t('landingPage:phoneNumber') })
    ),
    withMinStringLength(8, 'message:MS090009'),
    withMaxStringLength(12, 'message:MS090008')
  ),
  captchaValue: form2.string.required
})

type Props = StackScreenProps<LandingPageStackParamList, 'HomeScreen'>

const YESTERDAY = PulseOpsFormat.getYesterday()

export const HomeScreen = (props: Props) => {
  const { t } = useTranslation('landingPage')
  const { t: m } = useTranslation('message')

  const { isMobile, isWide } = useMobile()

  const [loading, setLoading] = React.useState(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const reCaptchaKey = pipe(
    AppConfig.get,
    ZIO.map((_) => _.reCaptchaKey),
    ErrorHandling.runDidMount()
  )

  const {
    base: {
      control,
      formState: { errors },
      setValue,
      watch
    },
    handleSubmit
  } = form2.useForm(FormC)

  const onSumit = handleSubmit((validated) => {
    const payload = {
      idNumber: validated.id,
      dob: moment(validated.dob).format('YYYYMMDD'),
      phoneNumber: validated.phoneNumber,
      captchaValue: validated.captchaValue
    }

    return pipe(
      setLoading(true),
      () => Services.login(payload),
      ZIO.tap(() =>
        ZIO.effect(() => {
          setLoading(false)
          props.navigation.navigate('PolicyListScreen', payload)
        })
      ),
      ZIO.catchAll((error) => {
        let message = ''
        if (error?.source?.response?.status === 500 && error?.source?.response?.data?.error === 'MS050034') {
          message = m('MS050034')
        }
        return ZIO.effect(() => {
          setLoading(false)
          showToast(message || m('MS030029'), 'error', {
            horizontal: 'right',
            vertical: 'top'
          })
        })
      }),
      ErrorHandling.run()
    )
  })

  React.useEffect(() => {
    changeLanguage('vi')
    localStorage.setItem('locale', 'vi-VN')
  }, [])

  const onChange = (value: string | null) => {
    setValue('captchaValue', value)
  }

  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#e5e5e5' }}>
      <Header isWide={isWide} t={t} />
      <ImageBackground style={{ flex: 1 }} source={isMobile ? assets.HomeBackground : assets.HomeBackgroundWide}>
        <ScrollView>
          <SC.Container maxWidth="566" isWide={isWide}>
            <View style={styles.form}>
              <SC.Form.Group marginBottom="0">
                <SC.Form.Row marginBottom="18" isMobile={isMobile}>
                  <SC.MessageTitle isWide={isWide}>{isMobile ? m('MS050300_mobile') : m('MS050300')}</SC.MessageTitle>
                </SC.Form.Row>
                <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="id"
                    render={({ field }) => (
                      <BorderFilledInput
                        controlStyle={{ width: '100%' }}
                        label={`${t('id')}*`}
                        value={field.value || ''}
                        onChange={(val) => {
                          field.onChange(val == null ? val : val.replace(/(\s|\*|&|\$|#|\\|<|>|\|)/g, ''))
                        }}
                        onBlur={field.onBlur}
                        maxLength={24}
                        errorMessage={errors?.id?.message}
                      />
                    )}
                  />
                </SC.Form.Row>
                <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ field }) => (
                      <BorderFilledDatePicker
                        label={`${t('dob')} (dd/mm/yyyy)*`}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        maxDate={YESTERDAY}
                        maxDateMessage={m('MS050022')}
                        minDateMessage={m('MS070024')}
                        errorMessage={errors?.dob?.message}
                      />
                    )}
                  />
                </SC.Form.Row>
                <SC.Form.Row marginBottom="38" isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <BorderFilledInput
                        isDigit
                        controlStyle={{ width: '100%' }}
                        label={`${t('phoneNumber')}*`}
                        value={field.value || ''}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        maxLength={12}
                        errorMessage={errors?.phoneNumber?.message}
                      />
                    )}
                  />
                </SC.Form.Row>
                {reCaptchaKey && (
                  <SC.Form.Row style={{ justifyContent: 'center' }} marginBottom="38" isMobile={isMobile}>
                    <ReCAPTCHA sitekey={reCaptchaKey} hl="vi" onChange={onChange} />
                  </SC.Form.Row>
                )}
                <SC.Form.Row marginBottom="28" isMobile={isMobile}>
                  <Button
                    title={t('findPolicy')}
                    onPress={onSumit}
                    loading={loading}
                    disabled={!watch('captchaValue')}
                  />
                </SC.Form.Row>
              </SC.Form.Group>
              <SC.Universal isWide={isWide}>
                <SC.LinkingRow>
                  <Pressable onPress={() => Linking.openURL(UniversalLink.PRIVACY_LINK)}>
                    <SC.LinkText>{t('owner_notice')}</SC.LinkText>
                  </Pressable>
                  <Pressable onPress={() => Linking.openURL(UniversalLink.TERM_LINK)}>
                    <SC.LinkText>{t('term')}</SC.LinkText>
                  </Pressable>
                </SC.LinkingRow>
              </SC.Universal>
            </View>
          </SC.Container>
        </ScrollView>
      </ImageBackground>
      <View style={styles.footer}>
        <SC.FooterText isWide={isWide}>{t('copy_right')}</SC.FooterText>
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#000000'
  }
})
