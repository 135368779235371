import React from 'react'
import { View } from 'react-native'
import { ReinstatementForm, ChooseView } from './common'
import { useTranslation } from 'react-i18next'
import { FieldList, Panel } from '@pulseops/common'
import { SC, ValueShow, FilePresent, Input } from '../../common'
export const ConfirmTab: React.FC<{ form: ReinstatementForm.Raw; headerFields: FieldList.FieldType[] }> = ({
  form,
  headerFields
}) => {
  const { reinClients, attachmentFiles } = form.formRein
  const { t } = useTranslation()
  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:ReinstatementInfomation').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <FieldList dataSource={headerFields} />
      </SC.PanelContainer>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:StatementOfInsurability').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      {reinClients &&
        reinClients.map((value, index) => {
          return (
            <Panel
              key={`list_rein_clientss_${index}`}
              title={value.name || ''}
              containerStyle={{ backgroundColor: '#fafafa' }}
            >
              <ChooseView title={t('submission:AnyChangesInStateOfHealth')} selected={value.hAnswer} disable />
              <ChooseView title={t('submission:AnyChangesInOccupation')} selected={value.oAnswer} disable />
              {value.oAnswer && (
                <SC.Row>
                  <ValueShow title={t('submission:CurrentOccupation')} value={value.curOccupation?.label || ''} />
                  <ValueShow title={t('submission:NewOccupation')} value={value.newOccupation?.label || ''} />
                </SC.Row>
              )}
              <ChooseView title={t('submission:AnyChangeInActivities')} selected={value.aAnswer} disable />
              {value.aAnswer && (
                <SC.Row>
                  <ValueShow title={t('submission:NewActivity')} value={value.newActivity} />
                </SC.Row>
              )}

              <ChooseView
                title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                selected={value.cAnswer}
                disable
              />
              {value.cAnswer && (
                <SC.Row>
                  <ValueShow title={t('submission:NewCompany')} value={value.newCompany} />
                </SC.Row>
              )}
            </Panel>
          )
        })}

      {attachmentFiles && attachmentFiles.length > 0 && (
        <SC.Padding>
          <SC.Padding vertical={10} horizontal={10}>
            <SC.TitleText>{t('submission:HealthDeclaration')}</SC.TitleText>
          </SC.Padding>
          <SC.Row>
            {attachmentFiles.map((value, index) => {
              const wrapValue = { ...value, uploadedDate: value.uploadedDate!, file: value.file! }
              return (
                <SC.InputContainer key={`file_health_declaration_${index}`}>
                  <SC.Padding horizontal={10}>
                    <FilePresent file={wrapValue} />
                  </SC.Padding>
                </SC.InputContainer>
              )
            })}
          </SC.Row>
        </SC.Padding>
      )}
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:ReinstatementAgent').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <SC.Row alignItems={'flex-end'}>
          <SC.InputContainer maxWidth={250}>
            <Input disabled title={t('submission:AgentCode')} value={form.formRein.agentCode} />
          </SC.InputContainer>
          <View style={{ alignSelf: 'flex-end' }}>
            <SC.NormalText>{form.formRein.agentName}</SC.NormalText>
          </View>
        </SC.Row>
      </SC.PanelContainer>
    </SC.Container>
  )
}
