import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBGeneralTable } from '../../../../../common'
import { DocsClaimAdvanceData, DocsData, DocumentsTabsData, IBService } from '../../../../../ib-service'
import { useLoading } from '@mxt/zio-react'
import { isEmpty, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { downloadURI } from '@pulseops/common'

interface Props {
  data: DocumentsTabsData
  setData: (data: DocumentsTabsData) => void
  policyNumber: string
}
export const IBClaimAdvanceDocs = (props: Props) => {
  const { data, setData, policyNumber } = props
  const [loading, bindLoading] = useLoading(false)
  const { t, i18n } = useTranslation('Inbound')
  const dataTable = [
    {
      label: t('Type'),
      field: 'letterCode',
      render: (value: string, index: number) => {
        return (
          <Pressable
            onPress={() => {
              const object = (data.ClaimAdvanceDocs as DocsClaimAdvanceData[])[index]
              pipe(
                IBService.getClaimLetter(object.id as number),
                ZIO.map((res) => {
                  const url = 'data:application/pdf;base64,' + res.data.letterFile

                  ;(window.open() as Window).document.write(
                    `<title>${
                      res.data.fileName
                    }</title><body style="overflow: hidden; margin: 0"><object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data=${encodeURI(
                      url
                    )}></object></body>`
                  )
                  return ZIO.unit
                }),
                ZIO.unsafeRun({})
              )
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('ClaimNoDocs'),
      field: 'claimNumber'
    },
    {
      label: t('PaymentAmount'),
      field: 'paymentAmount',
      format: 'money'
    },
    {
      label: t('ApproveDate'),
      field: 'approveDate',
      format: 'date'
    },
    {
      label: t('Approver'),
      field: 'approver'
    },
    {
      label: t('FinalPrint'),
      field: 'finalPrintDate',
      format: 'date'
    },
    {
      label: t('VerifiedDateDocs'),
      field: 'closedDate',
      format: 'date'
    },
    {
      label: t('VerifiedBy'),
      field: 'closedBy'
    }
  ]

  React.useEffect(() => {
    if (isEmpty(data.ClaimAdvanceDocs)) {
      pipe(
        IBService.getDocsClaimAdvanceList(policyNumber),
        ZIO.map((res) => {
          if (res.data) {
            setData({ ...data, ClaimAdvanceDocs: res.data })
          }
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  return (
    <View>
      <IBGeneralTable
        data={
          orderBy(
            data.ClaimAdvanceDocs?.map((item) => ({
              ...item,
              approveDate: moment(item.approveDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              finalPrintDate: moment(item.finalPrintDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              closedDate: moment(item.closedDate, 'DD/MM/YYYY').format('YYYYMMDD')
            })),
            'closedDate',
            'desc'
          ) || []
        }
        dataTable={dataTable}
        paginationStyle={{ marginRight: 60 }}
        loading={loading}
        autoPaging
      />
    </View>
  )
}

const styles = StyleSheet.create({})
