import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import i18next from 'i18next'

export namespace UploadForm {
  const SelectOption = t.type({
    label: NonEmptyString,
    value: NonEmptyString
  })

  type StringBrand = {
    readonly String: unique symbol
  }
  export type String = t.Branded<NonEmptyString, StringBrand>

  type PolicyNumberBrand = {
    readonly PolicyNumber: unique symbol
  }

  type PolicyNumber = t.Branded<NonEmptyString, PolicyNumberBrand>

  export const PolicyNumber = pipe(
    withMessage(NonEmptyString, () =>
      i18next.t('message:MS020001', { field: i18next.t('utilities:PolicyNumber').toLowerCase() })
    ),
    form2.refine(
      (s): s is PolicyNumber => /\b\d{8,9}\b/.test(s),
      (s) => i18next.t('message:MS090011'),
      'PolicyNumber'
    )
  )

  type ProposalNumberBrand = {
    readonly ProposalNumber: unique symbol
  }

  type ProposalNumber = t.Branded<NonEmptyString, ProposalNumberBrand>

  const ProposalNumber = pipe(
    withMessage(NonEmptyString, () =>
      i18next.t('message:MS020001', { field: i18next.t('utilities:ProposalNumber').toLowerCase() })
    ),
    form2.refine(
      (s): s is ProposalNumber => /\b\d{9}\b/.test(s),
      (s) => i18next.t('message:MS050294'),
      'ProposalNumber'
    )
  )

  const Header = t.intersection([
    t.type({
      type: withMessage(SelectOption, () =>
        i18next.t('message:MS020001', { field: i18next.t('utilities:type').toLowerCase() })
      ),
      batchName: withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('utilities:BatchName').toLowerCase() }))
    }),
    t.union([
      t.type({
        policyNumber: PolicyNumber,
        policyOwner: withMessage(NonEmptyString, () => i18next.t('message:MS030029')),
        poClientId: NonEmptyString,
        poIdNumber: NonEmptyString,
      }),
      t.type({
        proposalNumber: ProposalNumber,
        policyOwner: withMessage(NonEmptyString, () =>
          i18next.t('message:MS020001', { field: i18next.t('utilities:PolicyOwner').toLowerCase() })
        )
      })
    ])
  ])

  export type Header = t.TypeOf<typeof Header>

  const FileMeta = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type FileMeta = t.TypeOf<typeof FileMeta>

  type FileListBrand = {
    readonly FileList: unique symbol
  }

  export type FileList = t.Branded<FileMeta[], FileListBrand>

  const FileList = pipe(
    t.array(FileMeta),
    form2.refine(
      (l): l is FileList => l.length >= 1,
      (l) => i18next.t('message:MS020001', { field: i18next.t('utilities:FileAttachment') }),
      'FileList'
    )
  )

  const Item = t.type({
    department: withMessage(SelectOption, () =>
      i18next.t('message:MS020001', { field: i18next.t('utilities:department').toLowerCase() })
    ),
    documentType: withMessage(SelectOption, () =>
      i18next.t('message:MS020001', { field: i18next.t('utilities:documentType').toLowerCase() })
    ),
    files: withMessage(FileList, () =>
      i18next.t('message:MS020001', { field: i18next.t('utilities:FileAttachment').toLowerCase() })
    )
  })

  export type Item = t.TypeOf<typeof Item>

  const Upload = t.type({
    header: Header,
    content: t.array(Item)
  })

  export type Upload = t.TypeOf<typeof Upload>

  type UploadContentBrand = {
    readonly UploadContent: unique symbol
  }

  export type UploadContent = t.Branded<Upload, UploadContentBrand>

  const UploadContent = pipe(
    Upload,
    form2.refine(
      (l): l is UploadContent => l.content.length >= 1,
      (l) => `Min length is ${l.content.length}`,
      'UploadContent'
    )
  )

  export const codec = t.type({
    upload: UploadContent
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
