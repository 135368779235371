/* eslint-disable prefer-const */
import { ActivityIndicator, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React, { useContext } from 'react'
import {
  Input,
  ModalComponent,
  Alert,
  AuthService,
  PulseOpsFormat,
  FileUploadData,
  ErrorHandling,
  AppContext,
  AppConfig,
  assets,
  PulseOpsApi
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { IBButton, IBGeneralField, IBGeneralTable, IBGridDataView, TypeInputComponent } from '../../common'
import { useForm } from 'react-hook-form'
import { ClickToSendEmailForm } from '../../ib-client/ib-click-to-send-email'
import { isEmpty, isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { EmailManagementContext } from '../IBEmailManagementContext'
import { useIsFocused } from '@react-navigation/native'
import { ClickToSendCaseDetailData, IBService } from '../../ib-service'
import { useLoading } from '@mxt/zio-react'
import { Status, TaskCategory, TaskDecision } from '../common'
import moment from 'moment'

interface Props {
  visible: boolean
  onClose: (value: boolean) => void
  objectModal?: { policyNumber: string; processInstanceId: string; status: string }
}

export const IBModalListingOfHistory = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, objectModal } = props
  const { width, height } = useWindowDimensions()
  const { masterDataList } = useContext(EmailManagementContext)
  const isFocused = useIsFocused()
  const [loading, bindLoading] = useLoading(false)
  const [comment, setComment] = React.useState<string>('')

  const form = useForm<ClickToSendEmailForm>({
    mode: 'onChange',
    defaultValues: {
      attachmentFiles: []
    }
  })

  const getFileByUrl = (
    url: string,
    name: string,
    params: { updatedDate: string; createdDate: string },
    filename: string
  ) => {
    const container = url.slice(url.lastIndexOf('/') + 1)
    const apiUrl = url.slice(0, url.lastIndexOf('/'))
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        let reader = new FileReader()
        const updateDate = params?.updatedDate
          ? moment(params?.updatedDate).toDate() ?? new Date()
          : params?.createdDate
          ? moment(params?.createdDate).toDate() ?? new Date()
          : new Date()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          const base64String = reader.result
          const blobFile = base64String ? new Blob([base64String]) : null
          let fileUpload = new File(blobFile ? [blobFile] : [], filename ?? '')
          const lastDotIndex = filename.lastIndexOf('.')
          const fileExtention = filename.substring(lastDotIndex + 1, filename.length)
          let file: FileUploadData = {
            fileName: filename.substring(0, lastDotIndex) ?? '',
            fileExtension: fileExtention === 'msg' ? 'msg' : blob.type.split('/')[1] ?? blob.type,
            size: blob.size,
            base64: base64String ? base64String.toString() : '',
            uploadedDate: updateDate,
            file: fileUpload
          }
          let fileList = form.watch('attachmentFiles')
          base64String && fileList.push(file)
          if (fileList.length > 0 && base64String) {
            form.setValue('attachmentFiles', fileList)
          }
        }
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  React.useEffect(() => {
    pipe(
      ZIO.zipPar(
        IBService.getClickToSendCaseDetail(objectModal?.processInstanceId || ''),
        ['CANCELED', 'RETURNED'].includes(objectModal?.status || '')
          ? PulseOpsApi.getCommentList(objectModal?.processInstanceId || '')
          : ZIO.succeed([])
      ),
      ZIO.map(([res, reasons]) => {
        if (!isEmpty(reasons)) {
          setComment(reasons[0].message)
        }
        loadOriginData(res)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [isFocused])

  const getImgByUrl = (name: string, content: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, AppConfig.get),
      ZIO.flatMap(([token, loginType, cf]) => {
        const currVersion = cf.version.split('.').join('-') || '1-0-0'
        const url = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
        const container = url.slice(url.lastIndexOf('/') + 1)
        const apiUrl = url.slice(0, url.lastIndexOf('/'))
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const splitName = name.split('/')
        return { name: splitName[splitName.length - 1].split('.')[0], blob: window.URL.createObjectURL(blob) }
      })
    )
  }

  const loadOriginData = (res: ClickToSendCaseDetailData) => {
    res.payload?.attachDocuments?.map((item) => {
      getFileByUrl(
        item.url as string,
        item.type as string,
        {
          updatedDate: res.lastUpdatedDate || '',
          createdDate: res.startTime || ''
        },
        item.filename
      )
    })
    const group = masterDataList.a14s0s.find((x) => x.code === res.payload?.mailGroupCode)
    const type = masterDataList.a14s1s.find((x) => x.code === res.payload?.requestTypeCode)
    form.setValue('policyNumber', { label: res.policyNumber || '', value: res.policyNumber || '' })
    form.setValue('policyOwner', res.policyOwnerName || '')
    form.setValue('group', {
      value: group?.code || '',
      label: i18n.language === 'vi' ? group?.nameVi || '-' : group?.nameEn || '-'
    })
    form.setValue('type', {
      value: type?.code || '',
      label: i18n.language === 'vi' ? type?.nameVi || '-' : type?.nameEn || '-'
    })
    form.setValue('note', res.payload?.note || '')
    form.setValue('sendTo', res.payload?.emailTo?.reduce((pre, cur) => (pre += `${cur};`), '') || '')
    form.setValue('BCC', res.payload?.emailBCC?.reduce((pre, cur) => (pre += `${cur};`), '') || '')
    form.setValue('subject', res.payload?.subject || '')
    if (res.payload?.content) {
      if (!isEmpty(res.payload?.content.match(/alt="(.*?)"/g)) && !isNil(res.payload?.content.match(/alt="(.*?)"/g))) {
        const arrayImg = res.payload?.content.match(/alt="(.*?)"/g)?.map((match) => match.match(/alt="(.*?)"/)[1])
        pipe(
          ZIO.sequence((arrayImg || []).map((url) => getImgByUrl(url, res.payload?.content || ''))),
          ZIO.map((imgs) => {
            const insertImgToContent = imgs.reduce(
              (pre, cur) => pre?.replace(`{${cur.name}}`, cur.blob),
              res.payload?.content
            )
            form.setValue('content', insertImgToContent || '')
            form.setValue('contentOriginal', insertImgToContent || '')
          }),
          ZIO.unsafeRun({})
        )
      } else {
        form.setValue('contentOriginal', res.payload?.content)
        form.setValue('content', res.payload?.content)
      }
    }
  }

  const contentHtml = { __html: form.watch().content }

  const handleRenderIcon = (fileExtension: string) => {
    switch (fileExtension) {
      case 'pdf':
        return <assets.IconPdf />
      case 'jpeg':
        return <assets.IconJpeg />
      case 'jpg':
        return <assets.IconJpg />
      case 'msg':
        return <assets.IconMsg />
      case 'png':
        return <assets.IconPng />
      case 'tif':
        return <assets.IconTif />
      case 'tiff':
        return <assets.IconTiff />
      case 'xls':
        return <assets.XLSXIcon />
      case 'xlsx':
        return <assets.XLSXIcon />
      default:
        return <assets.DocumentIcon />
    }
  }

  return (
    <ModalComponent
      title={t('EmailDetail').toUpperCase()}
      titleStyle={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700' }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 10, marginTop: 0 }}
    >
      <ScrollView>
        {['CANCELED', 'RETURNED'].includes(objectModal?.status || '') && (
          <View style={{ margin: 15 }}>
            <IBGridDataView
              data={[
                { label: objectModal?.status === 'RETURNED' ? t('ReasonReturn') : t('ReasonCancel'), value: comment }
              ]}
              col={3}
            />
          </View>
        )}

        <View style={styles.boxContainer}>
          <IBGeneralField
            FieldForm={form}
            col={2}
            typeInput={[
              {
                type: TypeInputComponent.INPUT,
                formName: 'policyNumber.label',
                title: t('common:PolicyNumber'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'policyOwner',
                title: t('OwnerName'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'group.label',
                title: t('Group'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'type.label',
                title: t('Type'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'note',
                title: t('Note'),
                inputType: 'input',
                alwayShowUnderline: true,
                expandRow: 2,
                multiline: true,
                disabled: true,
                numberOfLines: 3,
                maxLength: 1000
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'sendTo',
                title: t('To'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'BCC',
                title: t('Bcc'),
                disabled: true,
                inputType: 'input'
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'subject',
                title: t('SubjectEmail'),
                inputType: 'input',
                maxLength: 200,
                disabled: true,
                expandRow: 2
              }
            ]}
          />
          <View>
            <View>
              <Text style={{ fontSize: 15, lineHeight: 20, color: '#70777e', fontWeight: 'bold', marginBottom: 10 }}>
                {t('Content')}
              </Text>
              <ScrollView style={styles.contentHtmlBox} showsHorizontalScrollIndicator={false}>
                <div dangerouslySetInnerHTML={contentHtml} />
              </ScrollView>
            </View>
            <View>
              <Text style={{ fontSize: 15, lineHeight: 20, color: '#70777e', fontWeight: 'bold', marginVertical: 10 }}>
                {t('requestInfo:Attachment')}
              </Text>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {form.watch().attachmentFiles.map((x) => {
                  return (
                    <View style={{ width: '50%', alignItems: 'center', flexDirection: 'row', marginBottom: 10 }}>
                      <View style={{ marginRight: 10 }}>{handleRenderIcon(x.fileExtension)}</View>
                      <Text style={{ width: '80%' }}>{`${x.file.name}`}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      {loading ? (
        <View style={styles.loadingBlur}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <></>
      )}
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  boxContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    padding: 20,
    flex: 1,
    flexDirection: 'column'
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 50,
    justifyContent: 'space-between'
  },
  loadingBlur: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  },
  contentHtmlBox: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 20,
    padding: 15,
    height: 300
  }
})
