import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View } from 'react-native'
import { assets } from '@pulseops/common'
import { infoGen, styles } from '../../common'

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF',
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: '#E5E5E5'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export interface BOInquiryMemberPopupSV {
  memberName: string
  memberID: string
  memberAge: string
  memberGender: string
  memberPhone: string
  memberEmail: string
  memberGroup: string
  memberBenAmount: string
  memberOverFCLAmount: string
  memberDecision: string
  memberTime: string
  memberNote: string
  memberUID: string
}

export type BOInquiryMemberPopupProps = {
  onClose: (value: any) => void
  open: boolean
  selectedValue: BOInquiryMemberPopupSV | null
}

export const BOInquiryMemberPopup = (props: BOInquiryMemberPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, selectedValue, open } = props

  const handleClose = (props: BOInquiryMemberPopupProps) => {
    onClose(selectedValue)
  }
  const infoMapper = infoGen(false)

  const memberInfo = [
    [
      { id: 'memberName', title: 'Họ và tên', value: selectedValue?.memberName || '-' },
      { id: 'memberID', title: 'ID member', value: selectedValue?.memberID || '-' }
    ],
    [
      { id: 'memberAge', title: 'Tuổi', value: selectedValue?.memberAge || '-' },
      { id: 'memberGender', title: 'Giới tính', value: selectedValue?.memberGender || '-' }
    ],
    [
      { id: 'memberPhone', title: 'Số điện thoại', value: selectedValue?.memberPhone || '-' },
      { id: 'memberEmail', title: 'Thư điện tử', value: selectedValue?.memberEmail || '-' }
    ],
    [{ id: 'memberGroup', title: 'Nhóm', value: selectedValue?.memberGroup || '-' }],
    [
      { id: 'memberBenAmount', title: 'Số tiền bảo hiểm', value: selectedValue?.memberBenAmount || '-' },
      { id: 'memberOverFCLAmount', title: 'Số tiền vượt quá FCL', value: selectedValue?.memberOverFCLAmount || '-' }
    ],
    [
      { id: 'memberDecision', title: 'Quyết định', value: selectedValue?.memberDecision || '-' },
      { id: 'memberTime', title: 'Thời gian', value: selectedValue?.memberTime || '-' }
    ],
    [{ id: 'memberNote', title: 'Ghi chú', value: selectedValue?.memberNote || '-' }],
    [{ id: 'memberUID', title: 'User ID', value: selectedValue?.memberUID || '-' }]
  ]

  return (
    <Dialog maxWidth={'md'} fullWidth={true} className={dialog} onClose={handleClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center', marginLeft: 385 }}>
          Thông tin thành viên
        </Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose(null)
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View style={styles.infoContainer}>
          <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin chi tiết</Text>
          {infoMapper(memberInfo, 'memberInfo')}
        </View>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose(null)}>
          <Text style={{ color: '#FFF' }}>OK</Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}
