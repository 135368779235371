import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO, Task } from '@mxt/zio'
import * as A from 'fp-ts/lib/Array'
// import * as O from 'fp-ts/lib/Option'
import { UploadDocumentsService } from './UploadDocumentsService'
import { Maybe } from '@mxt/zio/codec'
// import { UploadDocumentData } from "./model"

export namespace UploadDocumentReportService {
  export interface ReportDocumentType {
    docTypeCode: string
    doctypeVn: string
    doctypeEn: string
    departmentType: string
  }

  export interface UploadReportInputData {
    order: string
    size: number
    sort: string
    start: number
    listOfficeCode: string[]
    agentCode: string
    policyNo: string
    proposalNo: string
    listDepartment: string[]
    fromDate: string
    toDate: string
  }

  export const UploadReportListData = t.array(
    t.type({
      policyNo: t.string,
      proposalNo: t.string,
      agentCode: t.string,
      agentName: t.string,
      poName: Maybe(t.string),
      department: t.string,
      documentType: t.string,
      type2: t.string,
      officeCode: t.string,
      createdDate: t.string
    })
  )

  export const UploadDocumentReportOutputData = t.type({
    body: t.type({
      data: UploadReportListData,
      order: t.string,
      size: t.number,
      sort: t.string,
      start: t.number,
      total: t.number
    })
  })

  export type UploadReportListData = t.TypeOf<typeof UploadReportListData>
  export type UploadDocumentReportOutputData = t.TypeOf<typeof UploadDocumentReportOutputData>

  export const searchUploadDocumentReport = (queryData: UploadReportInputData): Task<UploadDocumentReportOutputData> =>
    pipe(
      POApi.post('wf-api/submission/search-document-report')(UploadDocumentReportOutputData)({
        body: queryData
      }),
      ZIO.map((dataList) => {
        return dataList
      })
    )

  export const getDocumentTypeListByDepartCode = (departCodeArr: string[]) => {
    return pipe(
      departCodeArr,
      A.map((departCode) => {
        return pipe(
          UploadDocumentsService.getDocumentType(departCode),
          ZIO.map((documentTypeData) => {
            const docList =
              documentTypeData?.map((item) => ({
                docTypeCode: item.docTypeCode,
                doctypeVn: item.doctypeVn,
                doctypeEn: item.doctypeEn,
                departmentType: departCode
              })) || []
            return docList
          })
        )
      }),
      ZIO.sequence
    )
  }

  export const getUploadDocumentReportInfo = () =>
    pipe(
      ZIO.zipPar(UploadDocumentsService.getDepartmentListForT31(), UploadDocumentsService.getDepartmentListForT71()),
      ZIO.map(([departmentList, departmentT17List]) => {
        const newDepartmentT17List = departmentT17List.map((departmentItem) => {
          const departItem = departmentList.find((x) => x.departmentShortName === departmentItem.departmentShortName)
          return {
            departmentCode: departItem?.departmentCode ?? '',
            departmentEN: departItem?.departmentEN ?? '',
            departmentVN: departItem?.departmentVN ?? '',
            departmentShortName: departItem?.departmentShortName ?? ''
          }
        })
        return {
          DepartmentList: newDepartmentT17List
        }
      }),
      ZIO.flatMap((departArr) => {
        const departList = departArr.DepartmentList.map((item) => item.departmentShortName)
        return ZIO.zipPar(ZIO.succeed(departArr), getDocumentTypeListByDepartCode(departList))
      }),
      ZIO.map(([departmentArr, documentTypeList]) => {
        let docTypeList: UploadDocumentReportService.ReportDocumentType[] = []
        documentTypeList.forEach((docTypeListItem) => {
          docTypeListItem.forEach((docItem) => {
            docTypeList.push(docItem)
          })
        })
        return {
          departmentArr,
          docTypeList
        }
      })
    )
}
