import { Color } from '@material-ui/lab'
import { ZIO } from '@mxt/zio'
import { Ref } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'

export type AlertDialogProps = {
  title?: string
  message: string
}

export type AlertOptions = {
  title?: string
  message: string
  type?: Color
}

export type ModalOptions = {
  title: string
  content?: string
  child?: React.ReactNode
  size?: {
    height?: number | string
    width?: number | string
  }
  onClose?: () => void
  onOK?: () => void
  //actions?: [] // mọi người implement nhé :)))
  hideAction?: boolean
  hideCancle?: boolean
  hideCloseIcon?: boolean
}

export namespace AlertService {
  type State = { options: AlertOptions | null }

  export const stateRef = Ref.make<State>({ options: null })

  type SnackState = { options: AlertOptions | null }

  export const snackStateRef = Ref.make<SnackState>({ options: null })

  type ModalState = { options: ModalOptions | null }

  export const modalStateRef = Ref.make<ModalState>({ options: null })
}

export namespace Alert {
  export const alertM = (message: string, title: string = i18next.t('common:alert')) =>
    pipe(
      AlertService.stateRef.set({
        options: {
          title,
          message
        }
      }),
      ZIO.asVoid
    )

  export const alert = (message: string, title?: string) => {
    pipe(alertM(message, title), ZIO.run({}))
  }

  export const snackbarM = (message: string, title: string = i18next.t('common:alert'), type?: Color) =>
    pipe(
      AlertService.snackStateRef.set({
        options: {
          title,
          message,
          type
        }
      }),
      ZIO.asVoid
    )

  export const snackbar = (message: string, title?: string, type?: Color) => {
    pipe(snackbarM(message, title, type), ZIO.run({}))
  }

  export const modalM = (options: ModalOptions) => {
    return pipe(AlertService.modalStateRef.set({ options }), ZIO.asVoid)
  }

  export const modal = (options: ModalOptions) => {
    pipe(modalM(options), ZIO.run({}))
  }
}
