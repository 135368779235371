import { CircularProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  AuthService,
  Divider,
  ErrorHandling,
  Form,
  GeneralService,
  getLanguage,
  Permission,
  RadioButton,
  RBAC,
  RoundedButton,
  SelectOption,
  SelectSearch,
  SubmissionService,
  useMobile,
  OfficeCode,
  isCasualLabor,
  UserRoles
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { Input } from '../../common/src/component'
import { PCRequestStackParamList } from './PCRequestStackParamList'

type PCRequestForm = {
  officeCode: SelectOption | null
  policy: string
  primaryId: string
  proposalNumber: string
}

const generateUUID = () => {
  let uuidValue = '',
    k,
    randomValue
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += '-'
    }
    uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
  }
  return uuidValue
}

type Props = StackScreenProps<PCRequestStackParamList, 'PCRequestScreen'>

export const PCRequest = (props: Props) => {
  const { width } = useWindowDimensions()
  const { t, i18n } = useTranslation('common')
  const [selectedPolicy, setSelectedPolicy] = React.useState<string>('')
  const [cells, setCells] = React.useState<TableData[][]>([])
  const [loading, bindLoader] = useLoading(false)
  const [officeCodeSelected, setOfficeCodeSelected] = React.useState<OfficeCode>()
  const [isGA, setIsGA] = React.useState(false)

  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)

  const form = useForm<PCRequestForm>()

  const { watch, trigger, formState, control, setValue } = form

  const getError = Form.getError(formState)
  const { policy, officeCode } = watch()

  const register = Form.register(form)

  pipe(
    AuthService.userInfo,
    ZIO.flatMap((userData) => {
      const casualLaborInfo =
        userData.isGaLogin && !isCasualLabor()
          ? pipe(
              RBAC.getPermission(userData.email),
              ZIO.map((emailList) => {
                let isCasualLabor: boolean = false
                if (emailList && emailList.groups) {
                  isCasualLabor = emailList.groups.includes(UserRoles.CasualLabor)
                }
                return {
                  ...userData,
                  isCasualLabor: isCasualLabor
                }
              })
            )
          : ZIO.effect(() => ({
              ...userData,
              isCasualLabor: isCasualLabor()
            }))
      return casualLaborInfo
    }),
    ZIO.tap((x) => {
      setIsGA(x.isGaLogin && !x.isCasualLabor)
      return ZIO.unit
    }),
    ZIO.flatMap((x) =>
      x.officeCode !== null
        ? pipe(
            GeneralService.getOfficeCode(x.officeCode),
            ZIO.tap((y) => {
              const officeCode = y.body[0]
              if (officeCode) {
                setOfficeCodeSelected(officeCode)
                const option = {
                  label: getLanguage() === 'en' ? officeCode.nameEn : officeCode.nameVi,
                  value: officeCode.code
                }
                form.setValue('officeCode', option)
              }
              return ZIO.unit
            })
          )
        : ZIO.succeed(null)
    ),
    ErrorHandling.runDidMount()
  )

  const officeCodes: OfficeCode[] = pipe(GeneralService.getOfficeCodes('CSC'), ErrorHandling.runDidMount([]))

  const permissions: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  React.useEffect(() => {
    trigger().then()
  }, [policy])

  const reset = () => {
    setSelectedPolicy('')
    setCells([])
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        { title: t('menu:PCRequest'), navigate: null }
      ])
    }
  }, [isFocused])

  const getPolicy = () =>
    pipe(
      policy && policy.length === 8
        ? SubmissionService.searchPolicy(policy)
        : SubmissionService.searchPolicyInquiry(policy),
      ZIO.tap((p) => {
        if (p.length > 0) {
          const { policyNum, clientName, idNum, laName, primaryId, proposalNumber } = p[0]
          setSelectedPolicy(policyNum)
          setValue('primaryId', primaryId)
          setValue('proposalNumber', proposalNumber || '')
          setCells([
            [
              {
                inputType: 'radio',
                value: policyNum
              },
              {
                value: policyNum
              },
              {
                value: idNum
              },
              {
                value: clientName
              },
              {
                value: laName
              }
            ]
          ])
        } else {
          showToast(t('message:MS030029'), 'info')
          reset()
        }
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showToast(t('message:MS030029'), 'info')
        reset()
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )

  const headers = [`${t('Select')}`, `${t('Policy')}`, `${t('IDNumber')}`, `${t('PolicyOwner')}`, `${t('LifeAssured')}`]

  const checkRoles = (role: Permission): boolean => {
    if (isGA && !officeCodeSelected) {
      return true
    }

    const checker = !selectedPolicy
    return checker || !permissions?.includes(role)
  }

  const onPolicySearch = () => {
    if (!!policy && !loading && !getError('policy')) {
      getPolicy()
      form.clearErrors('officeCode')
    }
  }

  const checkOfficeCode = () => {
    if (!officeCode) {
      form.setError('officeCode', { message: t('message:MS020001', { field: t('common:Office').toLowerCase() }) })
      return false
    } else {
      return true
    }
  }

  i18n.on('languageChanged', (lang) => {
    if (officeCodeSelected) {
      form.setValue('officeCode.label', lang === 'en' ? officeCodeSelected.nameEn : officeCodeSelected.nameVi)
    }
  })

  return (
    <View style={{ backgroundColor: '#fff', height: '100%' }}>
      <ScrollView
        style={{
          width: width <= 1440 ? width : 1440,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <View style={{ flexDirection: 'row', padding: 10, alignItems: 'flex-start' }}>
          <Input
            {...register('policy', {
              required: `${t('message:MS020001', { field: t('Policy/Proposal') })}`,
              validate: {
                minlength8: (policy) => policy?.length > 7 || `${t('common:MS090001')}`
              }
            })}
            containerStyle={{ flex: 2 }}
            suffixIcon={() => (
              <TouchableOpacity
                style={{ marginHorizontal: 15 }}
                onPress={onPolicySearch}
                disabled={!policy || policy.length <= 7}
              >
                {loading ? (
                  <CircularProgress color={'secondary'} size={25} />
                ) : policy && policy.length > 7 ? (
                  <assets.Search20Icon />
                ) : (
                  <assets.SearchDisableIcon />
                )}
              </TouchableOpacity>
            )}
            numberOnly={true}
            placeholder={t('Policy/Proposal')}
            errorMessage={getError('policy')}
            maxLength={9}
            disabledSearch={!permissions?.includes(Permission.SearchSubmission)}
            onIconPressed={() => {
              policy && policy.length > 7 && onPolicySearch()
            }}
          />
          <Divider width={30} />

          <View style={styles.selectSearch}>
            <View>
              <Controller
                key={`${officeCode?.label}-${officeCode?.value}`}
                control={control}
                name={'officeCode'}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      value={value}
                      onChange={(value) => {
                        form.clearErrors('officeCode')
                        setOfficeCodeSelected(officeCodes.find((item) => item.code === value?.value))
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      label={''}
                      options={officeCodes.map((item) => ({
                        label: getLanguage() === 'en' ? item.nameEn : item.nameVi,
                        value: item.code
                      }))}
                      disableUnderline={true}
                      placeholder={t('ChooseOffice')}
                      disabled={isGA}
                      prefixIcon={<assets.LocationPinIcon />}
                      errorMessage={error?.message}
                      inputStyle={[
                        styles.selectSearchStyle,
                        officeCode === null ? { borderWidth: 1, borderColor: 'red' } : null
                      ]}
                      buildLabel={(select) => `${select.value} - ${select.label}`}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      inputWhenFocus={true}
                    />
                  )
                }}
              />
            </View>
          </View>
        </View>

        {cells.length === 0 && (
          <Text style={{ display: 'flex', justifyContent: 'center', color: 'gray', marginTop: 51, marginBottom: 55 }}>
            {t('SearchToSeePolicy')}
          </Text>
        )}

        {cells.length !== 0 && (
          <View style={{ marginLeft: 15, marginRight: 15, marginTop: 40, marginBottom: 88 }}>
            <Table headers={headers} cells={cells} />
          </View>
        )}

        <View style={[styles.btnContainer]}>
          <RoundedButtonCustom
            text={t('RequestRelatedCashlessCollection')}
            onPress={() => {
              if (checkOfficeCode()) {
                props.navigation.navigate('RequestRelatedCashlessCollectionStack', {
                  screen: 'RequestRelatedCashlessCollectionScreen',
                  params: {
                    policyNum: selectedPolicy,
                    proposalNum: watch('proposalNumber'),
                    policyOwner: cells[0][3].value?.toString() || '-',
                    officeCode: watch('officeCode')?.value || '-',
                    officeName: watch('officeCode')?.label || '-',
                    primaryId: watch('primaryId')
                  }
                })
              }
            }}
            disable={checkRoles(Permission.ViewRequestCashlessPCRequest)}
          />
        </View>
      </ScrollView>
    </View>
  )
}

const RoundedButtonCustom = ({
  text,
  disable,
  onPress,
  loading
}: {
  text: string
  disable?: boolean
  onPress?: () => void
  loading?: boolean
}) => {
  const { isMobile } = useMobile()
  return loading === true ? (
    <View
      style={[
        styles.btnRounded,
        { width: isMobile ? '100%' : '25%', marginBottom: isMobile ? 10 : 0, alignItems: 'center' }
      ]}
    >
      <CircularProgress color="secondary"></CircularProgress>
    </View>
  ) : (
    <RoundedButton
      disabled={disable}
      text={text}
      activeOpacity={0.7}
      style={[styles.btnRounded, { width: isMobile ? '100%' : '25%', marginBottom: isMobile ? 10 : 0 }]}
      textStyle={styles.textStyle}
      showBorder={true}
      textColorEnable="#ED1B2E"
      textColorDisable="gray"
      borderColorEnable="#ED1B2E"
      borderColorDisable="gray"
      onPress={onPress}
    />
  )
}

interface TableData {
  inputType?: 'text' | 'radio' // text by default
  value?: number | string
}

interface CellProps {
  data: TableData[]
}

interface TableProps {
  headers: string[]
  cells: TableData[][]
  onSelectedChanged?: (data: TableData) => void
}

const Table: React.FC<TableProps> = ({ headers, cells, onSelectedChanged }) => {
  const Cell: React.FC<CellProps> = ({ data }) => {
    return (
      <View
        style={[
          { borderBottomWidth: 2, borderBottomColor: '#EEEEEE' },
          { flex: 1, alignSelf: 'stretch', flexDirection: 'row', minHeight: 50, paddingLeft: 15, paddingVertical: 15 }
        ]}
      >
        {data.map((d, i) => (
          <View
            key={i}
            style={{
              flex: 1,
              alignSelf: 'stretch'
            }}
          >
            {(!d.inputType || d.inputType === 'text') && <Text style={styles.tableTextStyle}>{d.value}</Text>}
            {d.inputType === 'radio' && (
              <RadioButton
                onChange={() => {
                  onSelectedChanged && onSelectedChanged(d)
                }}
                selected={true}
              />
            )}
          </View>
        ))}
      </View>
    )
  }

  const renderHeader = (data: (string | number)[]) => (
    <View
      style={{
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        minHeight: 74,
        backgroundColor: '#E5EAEF',
        paddingLeft: 15
      }}
    >
      {data.map((d, i) => (
        <View
          key={i}
          style={{
            flex: 1,
            alignSelf: 'stretch',
            justifyContent: 'center'
          }}
        >
          <Text style={{ fontWeight: 'bold', fontSize: 15, textTransform: 'capitalize' }}>{d}</Text>
        </View>
      ))}
    </View>
  )

  return (
    <View style={{ borderRadius: 15, borderWidth: 1, borderColor: '#D3DCE6', overflow: 'hidden' }}>
      {renderHeader(headers)}
      {cells.map((r, i) => (
        <React.Fragment key={i}>
          <Cell data={r} />
        </React.Fragment>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  searchSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  inputContainer: {
    marginVertical: 20,
    marginLeft: 30,
    marginRight: 30
  },
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  tableTextStyle: {
    fontSize: 16
  },
  btnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: 30
  },
  btnRounded: {
    height: 46,
    borderRadius: 20
  },
  selectSearch: {
    flex: 3
  },
  selectSearchStyle: {
    shadowColor: 'grey',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    borderRadius: 8,
    paddingVertical: 10,
    height: 46,
    paddingRight: 10
  }
})
