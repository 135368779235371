import * as React from 'react'
import { Panel, Title, DatePicker, Divider, ImgUploadMutiple } from '@pulseops/common'
import { Column, Label, Error, Row } from '@pulseops/submission/common'
import { Controller, Control } from 'react-hook-form'
import moment from 'moment'
import { FilePresent } from '../common'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'
interface Props {
  deathDateControl: { control: Control<any, any>; name: string }
  fileControl: { control: Control<any, any>; name: string }
  editable?: boolean
  sumAssured?: number
}

export const DateOfDeath = ({ deathDateControl, fileControl, sumAssured, editable = true }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t('submission:INHERITANCE_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <Column alignSelf="baseline" alignItems="baseline">
          <Label title={t('requestInfo:PODeathDate')} required={editable} fontWeight="bold" />
          <Controller
            control={deathDateControl.control}
            name={deathDateControl.name}
            render={({ field, fieldState: { error } }) =>
              editable ? (
                <DatePicker
                  {...field}
                  errorMessage={error?.message || ''}
                  maxDate={new Date()}
                  maxDateMessage={t('message:MS050028')}
                  onChange={(date) => {
                    date?.setHours(0, 0, 0, 0)
                    field.onChange && field.onChange(date)
                  }}
                />
              ) : (
                <Label title={moment(field.value).format('DD/MM/YYYY')} color={'#000000'} />
              )
            }
          />
        </Column>
        <Divider height={16} />
        {sumAssured != null && (
          <>
            {sumAssured <= 50 * 1000000 && (
              <View>
                <Text style={styles.fontSize15}>
                  ● Giấy tờ chứng minh quan hệ thừa kế của đại diện nhận tiền với BMBH và bản sao CMND/CCCD
                </Text>
                <Text style={styles.fontSize15}>● Giấy cam kết tự chịu trách nhiệm</Text>
                <Text style={[styles.fontSize15, styles.fontItalic]}>
                  PO mới/Người nhận QLBH là hàng thừa kế thứ nhất với BMBH và phải có mối quan hệ bảo hiểm đối với Người
                  được BH chính.
                </Text>
              </View>
            )}
            {sumAssured > 50 * 1000000 && sumAssured <= 100 * 1000000 && (
              <View>
                <Text style={styles.fontSize15}>
                  ● Văn bản thỏa thuận cử người đại diện nhận QLBH/đứng tên BMBH mới: có đầy đủ chữ ký của hàng thừa kế
                  (không cần địa phương chứng thực về chữ ký)
                </Text>
                <Text style={styles.fontSize15}>
                  ● Bảng tường trình quan hệ nhân thân: có chính quyền địa phương chứng thực về chữ ký của người đại
                  diện nhận quyền lợi. Các trang trên cùng văn bản phải có giáp lai giữa các trang.Chữ ký của người đại
                  diện nhận QLBH phải trên Bảng tường trình quan hệ nhân thân phải khớp với chữ ký trên Văn bản thỏa
                  thuận cử người đại diện nhận QLBH.
                </Text>
                <Text style={styles.fontSize15}>
                  ● Giấy tờ chứng minh quan hệ thừa kế của người đại diện nhận QLBH đối với BMBH và bản sao CMND/CCCD
                </Text>
              </View>
            )}
            {sumAssured > 100 * 1000000 && sumAssured <= 300 * 1000000 && (
              <View>
                <Text style={styles.fontSize15}>
                  ● Văn bản thỏa thuận cử người đại diện nhận QLBH/đứng tên BMBH mới: Phải được chính quyền địa phương
                  chứng thực chữ ký của tất cả những người được liệt kê, các trang trên cùng văn bản phải có dấu mộc
                  giáp lai giữa các trang.
                </Text>
                <Text style={styles.fontSize15}>
                  ● Giấy tờ chứng minh quan hệ thừa kế của tất cả hàng thừa kế và giấy chứng tử của hàng thừa kế đã qua
                  đời
                </Text>
                <Text style={styles.fontSize15}>● Bản sao CMND/CCCD của người đại diện nhận tiền</Text>
              </View>
            )}
            {sumAssured >= 300 * 1000000 && (
              <View>
                <Text style={styles.fontSize15}>● Văn bản thỏa thuận phân chia di sản; hoặc</Text>
                <Text style={styles.fontSize15}>● Văn bản khai nhận di sản</Text>
              </View>
            )}
          </>
        )}
        <Divider height={12} />
        <Label title={t('utilities:FileAttachment')} required={editable} fontWeight="bold" />
        <Divider height={14} />
        <Controller
          control={fileControl.control}
          name={fileControl.name}
          render={({ field, fieldState: { error } }) =>
            editable ? (
              <Column>
                <ImgUploadMutiple {...field} value={field.value as any[]} />
                {error?.message && <Error message={error.message} />}
              </Column>
            ) : (
              <Row flexWrap={'wrap'} flexGrow={1}>
                {(field.value as any[]).map((item) => (
                  <Column marginEnd={20}>
                    <FilePresent file={item} />
                  </Column>
                ))}
              </Row>
            )
          }
        />
      </Panel>
    </>
  )
}

const styles = StyleSheet.create({
  fontSize15: {
    fontSize: 15
  },
  fontItalic: {
    fontStyle: 'italic'
  }
} as const)
