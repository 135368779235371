import * as React from 'react'
import { ColorValue, Text } from 'react-native'

interface Props {
  title: string
  required?: boolean
  color?: ColorValue
  fontSize?: number
  fontWeight?: 'normal' | 'bold' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
  fontStyle?: 'normal' | 'italic'
}

export const Label: React.FC<Props> = ({
  title,
  required = false,
  color = '#70777E',
  fontWeight = 'normal',
  fontSize = 15,
  fontStyle = 'normal',
  children
}) => {
  return (
    <Text style={{ color, fontSize, fontWeight, fontStyle }}>
      {title}
      {required && <Text style={{ color: '#ED1B2E', fontWeight, fontStyle }}>{` *`}</Text>}
      {children}
    </Text>
  )
}
