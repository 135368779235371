export namespace Relationship {
  export enum Code {
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    A4 = 'A4',
    A5 = 'A5',
    BB = 'BB',
    BN = 'BN',
    BR = 'BR',
    BU = 'BU',
    BW = 'BW',
    CH = 'CH',
    DA = 'DA',
    DS = 'DS',
    DT = 'DT',
    EX = 'EX',
    FA = 'FA',
    FC = 'FC',
    FH = 'FH',
    FR = 'FR',
    FW = 'FW',
    GF = 'GF',
    GM = 'GM',
    HS = 'HS',
    HU = 'HU',
    JL = 'JL',
    LA = 'LA',
    MO = 'MO',
    NE = 'NE',
    O = 'O',
    OT = 'OT',
    PA = 'PA',
    PO = 'PO',
    SB = 'SB',
    SO = 'SO',
    SP = 'SP',
    ST = 'ST',
    SW = 'SW',
    TK = 'TK',
    TR = 'TR',
    TT = 'TT',
    U1 = 'U1',
    U2 = 'U2',
    U3 = 'U3',
    U4 = 'U4',
    WB = 'WB',
    WI = 'WI',
    N = 'N'
  }

  // export const MockRelationShip: { code: Code, value: string }[] = [
  //   {code: Code.A1,value:"Elder sister of one's parents"},
  //   {code: Code.A2,value:"Aunt"},
  //   {code: Code.A3,value:"Mother's sister"},
  //   {code: Code.A4,value:"Father's younger brother's wife"},
  //   {code: Code.A5,value:"Auntie"},
  //   {code: Code.BB,value:"Brothers of husband"},
  //   {code: Code.BN,value:"Benificiary"},
  //   {code: Code.BR,value:"Brother"},
  //   {code: Code.BU,value:"Business relationship"},
  //   {code: Code.BW,value:"Brother of wife"},
  //   {code: Code.CH,value:"Children"},
  //   {code: Code.DA,value:"Daughter"},
  //   {code: Code.DS,value:"As list attached"},
  //   {code: Code.DT,value:"Practitioners"},
  //   {code: Code.EX,value:"Testament"},
  //   {code: Code.FA,value:"Father"},
  //   {code: Code.FC,value:"Adopted child"},
  //   {code: Code.FH,value:"Fiance"},
  //   {code: Code.FR,value:"Friends"},
  //   {code: Code.FW,value:"Fiancee"},
  //   {code: Code.GF,value:"Grandfather"},
  //   {code: Code.GM,value:"Grandmother"},
  //   {code: Code.HS,value:"Brother in law"},
  //   {code: Code.HU,value:"Husband"},
  //   {code: Code.JL,value:"Abandon your spouse"},
  //   {code: Code.LA,value:"Life Assure"},
  //   {code: Code.MO,value:"Mother"},
  //   {code: Code.NE,value:"Grandchildren"},
  //   {code: Code.O, value:"Another relationship"},
  //   {code: Code.OT,value:"Others"},
  //   {code: Code.PA,value:"Father/mother"},
  //   {code: Code.PO,value:"Policy Owner"},
  //   {code: Code.SB,value:"Sister of husband"},
  //   {code: Code.SO,value:"Son"},
  //   {code: Code.SP,value:"Wife/Husband"},
  //   {code: Code.ST,value:"Sisters"},
  //   {code: Code.SW,value:"Sister of wife"},
  //   {code: Code.TK,value:"Law of inheritance"},
  //   {code: Code.TR,value:"Trustee"},
  //   {code: Code.TT,value:"Charity"},
  //   {code: Code.U1,value:"Old father's brother"},
  //   {code: Code.U2,value:"Uncle"},
  //   {code: Code.U3,value:"Maternal uncle"},
  //   {code: Code.U4,value:"Husband of one's aunt"},
  //   {code: Code.WB,value:"Sister-in-law"},
  //   {code: Code.WI,value:"Wife"}
  // ]

  export const Mocks: Array<{
    code: string
    desEn: string
    desVi: string
  }> = [
    { code: Code.A1, desEn: "Elder sister of one's parents", desVi: 'Bác gái' },
    { code: Code.A2, desEn: 'Aunt', desVi: 'Cô' },
    { code: Code.A3, desEn: "Mother's sister", desVi: 'Dì' },
    { code: Code.A4, desEn: "Father's younger brother's wife", desVi: 'Thím' },
    { code: Code.A5, desEn: 'Auntie', desVi: 'Mợ' },
    { code: Code.BB, desEn: 'Brothers of husband', desVi: 'Anh/em chồng' },
    { code: Code.BN, desEn: 'Benificiary', desVi: 'Người Thụ hưởng' },
    { code: Code.BR, desEn: 'Brother', desVi: 'Anh/Em trai' },
    { code: Code.BU, desEn: 'Business relationship', desVi: 'Quan hệ kinh doanh' },
    { code: Code.BW, desEn: 'Brother of wife', desVi: 'Anh/em vợ' },
    { code: Code.CH, desEn: 'Children', desVi: 'Con' },
    { code: Code.DA, desEn: 'Daughter', desVi: 'Con gái' },
    { code: Code.DS, desEn: 'As list attached', desVi: 'Theo danh sách đính kèm' },
    { code: Code.DT, desEn: 'Practitioners', desVi: 'Đệ tử' },
    { code: Code.EX, desEn: 'Testament', desVi: 'Người thi hành di chúc' },
    { code: Code.FA, desEn: 'Father', desVi: 'Cha' },
    { code: Code.FC, desEn: 'Adopted child', desVi: 'Con nuôi' },
    { code: Code.FH, desEn: 'Fiance', desVi: 'Chồng chưa cưới' },
    { code: Code.FR, desEn: 'Friends', desVi: 'Bạn' },
    { code: Code.FW, desEn: 'Fiancee', desVi: 'Vợ chưa cưới' },
    { code: Code.GF, desEn: 'Grandfather', desVi: 'Ông' },
    { code: Code.GM, desEn: 'Grandmother', desVi: 'Bà' },
    { code: Code.HS, desEn: 'Brother in law', desVi: 'Anh/em rể' },
    { code: Code.HU, desEn: 'Husband', desVi: 'Chồng' },
    { code: Code.JL, desEn: 'Abandon your spouse', desVi: 'Từ bỏ BHTP ng.hôn phối' },
    { code: Code.LA, desEn: 'Life Assure', desVi: 'Người được bảo hiểm' },
    { code: Code.MO, desEn: 'Mother', desVi: 'Mẹ' },
    { code: Code.NE, desEn: 'Grandchildren', desVi: 'Cháu' },
    { code: Code.O, desEn: 'Another relationship', desVi: 'Quan hệ khác' },
    { code: Code.OT, desEn: 'Others', desVi: 'Khác' },
    { code: Code.PA, desEn: 'Father/mother', desVi: 'Cha/mẹ' },
    { code: Code.PO, desEn: 'Policy Owner', desVi: 'Người mua bảo hiểm' },
    { code: Code.SB, desEn: 'Sister of husband', desVi: 'Chị/em chồng' },
    { code: Code.SO, desEn: 'Son', desVi: 'Con trai' },
    { code: Code.SP, desEn: 'Wife/Husband', desVi: 'Vợ/Chồng' },
    { code: Code.ST, desEn: 'Sisters', desVi: 'Chị/Em gái' },
    { code: Code.SW, desEn: 'Sister of wife', desVi: 'Chị/Em vợ' },
    { code: Code.TK, desEn: 'Law of inheritance', desVi: 'Theo luật thừa kế' },
    { code: Code.TR, desEn: 'Trustee', desVi: 'Người được ủy thác' },
    { code: Code.TT, desEn: 'Charity', desVi: 'Làm từ thiện' },
    { code: Code.U1, desEn: "Old father's brother", desVi: 'Bác trai' },
    { code: Code.U2, desEn: 'Uncle', desVi: 'Chú' },
    { code: Code.U3, desEn: 'Maternal uncle', desVi: 'Cậu' },
    { code: Code.U4, desEn: "Husband of one's aunt", desVi: 'Dượng' },
    { code: Code.WB, desEn: 'Sister-in-law', desVi: 'Chị/Em dâu' },
    { code: Code.WI, desEn: 'Wife', desVi: 'Vợ' },
    { code: Code.N, desEn: 'Grandchildren', desVi: 'Cháu' }
  ]

  export const mapRelationShip = new Map<string | undefined, { desEn: string; desVi: string }>()
  Mocks.forEach((rel) => {
    mapRelationShip.set(rel.code, { desEn: rel.desEn, desVi: rel.desVi })
  })

  export const IndicatorRelations: Array<{
    code: string
    desEn: string
    desVi: string
  }> = [
    { code: Relationship.Code.PA, desEn: 'Father/mother', desVi: 'Cha/mẹ' },
    { code: Relationship.Code.SP, desEn: 'Wife/Husband', desVi: 'Vợ/Chồng' },
    { code: Relationship.Code.CH, desEn: 'Children', desVi: 'Con' }
  ]

  export const WithMainLifeAssured: Array<{
    code: string
    desEn: string
    desVi: string
  }> = [
    { code: Code.FA, desEn: 'Father', desVi: 'Cha' },
    { code: Code.MO, desEn: 'Mother', desVi: 'Mẹ' },
    { code: Code.HU, desEn: 'Husband', desVi: 'Chồng' },
    { code: Code.WI, desEn: 'Wife', desVi: 'Vợ' },
    { code: Code.CH, desEn: 'Children', desVi: 'Con' },
    { code: Code.BR, desEn: 'Brother', desVi: 'Anh em ruột' },
    { code: Code.ST, desEn: 'Sisters', desVi: 'Chị em ruột' },
    { code: Code.GM, desEn: 'Grandmother', desVi: 'Bà nội / ngoại' },
    { code: Code.NE, desEn: 'Grandchildren', desVi: 'Cháu nội / ngoại' },
    { code: Code.A2, desEn: 'Aunt', desVi: 'Dì' },
    { code: Code.A3, desEn: 'Aunt', desVi: 'Cô' },
    { code: Code.U1, desEn: 'Aunt', desVi: 'Bác trai' },
    { code: Code.U2, desEn: 'Aunt', desVi: 'Chú' },
    { code: Code.U3, desEn: 'Aunt', desVi: 'Cậu' },
    { code: Code.U4, desEn: 'Aunt', desVi: 'Dượng' }
  ]
}
