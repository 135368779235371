import { createTheme, FormControlLabel, FormGroup, Switch } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ScrollView, Text } from 'react-native'
import i18next from 'i18next'
import { OBButton, OBSectionCol, OBSectionRow, OBSharedStyles } from '../ob-common'
import { assets, ErrorHandling, SelectSearch, SelectOption, AppContext, AuthService } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { CallProgramInfo, defaultDataAssignmentOB, mapCallProgram, OBAssignmentService, OBCallProgram, OBCallProgramTypeCode, OBGetCasesService, OBTaskService } from '../ob-service'
import { ZIO } from '@mxt/zio'
import { useIsFocused } from '@react-navigation/native'
import { Controller, useForm } from 'react-hook-form'
import { EnableCallPrograms, originAssignmentOBList } from './OBAssignmentTabConst'
import * as O from 'fp-ts/lib/Option'

type AssignmentData = {
  callProgram: string,
  status: string
}

type AssignmentByLeaderFormData = {
  manager: SelectOption | undefined
  assignee: SelectOption | undefined
}

export const OBAssignmentByLeaderTab = () => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  // const [assignee, setAssignee] = React.useState<SelectOption | null>()
  const [staffList, setStaffList] = React.useState<SelectOption[]>([])
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [listAssignmentStatus, setListAssignmentStatus] = React.useState<AssignmentData[]>([])
  const [authorizedTransactionCodeList, setAuthorizedTransactionCodeList] = React.useState<string[]>([])
  const assignmentByLeaderForm = useForm<AssignmentByLeaderFormData>({
    defaultValues: {
      manager: undefined,
      assignee: undefined
    }
  })
  const theme = createTheme({
    overrides: {
      MuiSwitch: {
        root: {
          width: '85px',
          height: '48px'
        },
        switchBase: {
          // transform: 'translateX(2px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(35px)'
          }
        },
        track: {
          opacity: 1,
          backgroundColor: '#BDBDBD',
          borderRadius: 22 / 2,
          '&:before, &:after': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 23,
            height: 23
          },
          '&:before': {
            right: '50%',
            content: `${i18next.t('common:ON')}`,
            fontSize: 9,
            textAlign: 'center',
            lineHeight: 2.5,
            color: 'white',
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: 400
          },
          '&:after': {
            left: '50%',
            content: `${i18next.t('common:OFF')}`,
            fontSize: 9,
            textAlign: 'center',
            lineHeight: 2.5,
            color: 'white',
            fontFamily: 'Noto Sans',
            fontStyle: 'normal',
            fontWeight: 400
          },
          '$checked$checked + &': {
            opacity: 1,
            backgroundColor: 'red'
          }
        },
        thumb: {
          boxShadow: 'none',
          width: 32,
          height: 32,
          color: 'white'
        }
      }
    }
  })

  const managerList = pipe(
    OBTaskService.getManagerNameArr(),
    ZIO.map((responseData) => {
      return responseData.map((item) => ({ label: item, value: item }))
    }),
    ErrorHandling.runDidMount()
  ) || []


  React.useEffect(() => {
    if (!!assignmentByLeaderForm.watch("assignee"))
      pipe(
        ZIO.zipPar(
          OBAssignmentService.getAssigneeStateByLeader(assignmentByLeaderForm.watch("assignee")?.value || ''),
          OBAssignmentService.getAuthorizationCallTypeListForUser(assignmentByLeaderForm.watch("assignee")?.value || '')
        ),
        ZIO.map(([previousAssignmentList, transactionList]) => {
          const authorizationCallTypeCodes = transactionList.map((item) => pipe(
            mapCallProgram.get(item as OBCallProgramTypeCode),
            O.fromNullable,
            O.map((p) => p),
            O.getOrElse(() => '')
          ))
          setAuthorizedTransactionCodeList(authorizationCallTypeCodes)
          if (previousAssignmentList && previousAssignmentList.length > 0) {
            setListAssignmentStatus([...previousAssignmentList.filter((x) => authorizationCallTypeCodes.some((p) => p === x.callProgram)), ...defaultDataAssignmentOB.filter((x) => !previousAssignmentList.some((p) => p.callProgram === x.callProgram) && authorizationCallTypeCodes.some((k) => k === x.callProgram))])
          } else {
            setListAssignmentStatus(defaultDataAssignmentOB.filter((x) => authorizationCallTypeCodes.some((p) => p === x.callProgram)))
          }
        }),
        ErrorHandling.run()
      )
  }, [assignmentByLeaderForm.watch("assignee")])

  const updateStatus = (arr: AssignmentData[], callProgram: OBCallProgram, status: Partial<AssignmentData>): AssignmentData[] => {
    return arr.map((item) => (item.callProgram === callProgram ? { ...item, ...status } : item))
  }

  const handleChange = (status: boolean, callProgram: OBCallProgram) => {
    const newList = updateStatus(listAssignmentStatus, callProgram, { status: status === true ? "ON" : "OFF" })
    setListAssignmentStatus([...newList])
  }

  const onChangeManagerEvent = (managerEmail: string) => {
    assignmentByLeaderForm.setValue("assignee", undefined)
    setStaffList([])
    setAuthorizedTransactionCodeList([])
    if (!!managerEmail) {
      pipe(
        OBTaskService.getUserEmailListByLeader(managerEmail),
        ZIO.map((userEmailData) => {
          const userEmails = userEmailData
            .map((item) => ({ label: item, value: item }))
          setStaffList(userEmails ?? [])
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const handleSubmit = React.useCallback(() => {
    if (assignmentByLeaderForm.watch("assignee")) {
      pipe(
        OBAssignmentService.changeAssignmentByLeader(listAssignmentStatus, assignmentByLeaderForm.watch("assignee")?.value || '', assignmentByLeaderForm.watch("manager")?.value || ''),
        ZIO.map((res) => {
          showToast(t('common:SaveSuccess'), 'success')
        }),
        ErrorHandling.run()
      )
    }
  }, [listAssignmentStatus])

  const ListAssignment = () => {
    return originAssignmentOBList.filter((p) => authorizedTransactionCodeList.some((x) => x === p.callProgram)).map((item) => {
      const itemCheckedVal = listAssignmentStatus.some((p) => p.callProgram === item.callProgram && p.status === 'ON')
      return EnableCallPrograms.includes(item.callProgram) ? (
        <View style={assignmentStyles.wrapper}>
          <Text style={{ marginRight: '20px', minWidth: 180 }}>{item.title(t)}</Text>
          <ThemeProvider theme={theme}>
            <FormGroup>
              <FormControlLabel control={<Switch checked={itemCheckedVal} onChange={(e) => handleChange(e.target.checked, item.callProgram)} />} label="" />
            </FormGroup>
          </ThemeProvider>
        </View>
      ) : <></>
    })
  }

  return (
    <ScrollView style={assignmentStyles.container}>
      <Text style={assignmentStyles.headerTitle}>{t('Setting:OBAssignmentON_OFFForStaff')}</Text>
      <OBSectionRow>
        <OBSectionCol style={OBSharedStyles.sectionCol6}>
          <Controller
            control={assignmentByLeaderForm.control}
            name="manager"
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <SelectSearch
                  label={t("Outbound:OBAssignment:ManagerName")}
                  showPopupIcon={true}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  value={value}
                  // isShowFullText={true}
                  onChange={(val) => {
                    onChange(val)
                    onChangeManagerEvent(val?.value || '')
                  }}
                  onBlur={onBlur}
                  options={managerList}
                />
              )
            }}
          />
        </OBSectionCol>
        <OBSectionCol style={OBSharedStyles.sectionCol6}>
          <Controller
            control={assignmentByLeaderForm.control}
            name="assignee"
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <SelectSearch
                  label={t("Outbound:OBAssignment:SearchEmployee")}
                  showPopupIcon={true}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  value={value}
                  // isShowFullText={true}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={staffList}
                />
              )
            }}
          />
        </OBSectionCol>
      </OBSectionRow>
      {!!assignmentByLeaderForm.watch("assignee") && !!assignmentByLeaderForm.watch("assignee")?.value && listAssignmentStatus && listAssignmentStatus.length > 0 && (
        <OBSectionRow style={{ marginTop: 50 }}>
          <OBSectionCol style={[OBSharedStyles.sectionCol6, { paddingBottom: 10 }]}>
          </OBSectionCol>
          <OBSectionCol style={[OBSharedStyles.sectionCol6, { paddingBottom: 10 }]}>
            <Text style={[assignmentStyles.headerTitle, { fontSize: 15 }]}>Assign to {assignmentByLeaderForm.watch("assignee")?.label}</Text>
            {ListAssignment()}
            <View style={assignmentStyles.buttonContent}>
              <OBButton text={t('common:Save')} onHandleClickEvent={() => handleSubmit()} isHightLight></OBButton>
            </View>
          </OBSectionCol>
        </OBSectionRow>
      )}
    </ScrollView>
  )
}
const assignmentStyles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingHorizontal: 60,
    paddingTop: 40
  },
  headerTitle: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 30
  },
  wrapper: {
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonContent: {
    width: 100,
    marginTop: 30,
    marginRight: 'auto'
  }
})
