import { CircularProgress } from '@material-ui/core'
import * as React from 'react'
import { Pressable } from 'react-native'
import { assets } from '../assets'

export const RefreshButton = ({
  disabled,
  loading,
  onPress
}: {
  loading?: boolean
  disabled?: boolean
  onPress: () => void
}) => {
  return (
    <Pressable disabled={disabled || loading} onPress={onPress}>
      {loading ? <CircularProgress size={28} /> : disabled ? <assets.IconRefreshDisabled /> : <assets.IconRefresh />}
    </Pressable>
  )
}
