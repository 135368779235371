import { ClientPolicy } from '../Client'
import * as api from '../task-detail-api'
import { PaymentType } from '../PaymentType'
import { TransactionType } from '../TransactionType'
import { getPayOutDetail, getCoreDetail } from './common/payout'
import { PaymentMethod } from '../PaymentMethod'

export interface BonusSurrender {
  tag: TransactionType.BONUS_SURRENDER
  clientName: string
  attributesExt: {
    BONUS_AMOUNT: number
    TOTAL_PAYOUT_AMOUNT: number
    bonusValue: number
    bonusValueAmountToSurrender: number
    bonusReserveValue: number
    interestAplOpl: number
    principalAplOpl: number
  }
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
}

export const BonusSurrender = (
  detail: api.BonusSurrender & api.TaskDetailBase,
  poName: string,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string
): BonusSurrender => {
  const coreStatus = getCoreDetail(detail.coreResponseDetail, detail.payload.body.cashOutOptions || [])
  const cashOutList =
    detail.payload && detail.payload.body
      ? detail.payload.body.cashOutOptions?.filter(
          (x) =>
            PaymentType.BANKTRANSFER === x.type ||
            PaymentType.PAID_AT_BANK === x.type ||
            PaymentType.CASH_AT_COUNTER === x.type
        )
      : []
  return {
    tag: TransactionType.BONUS_SURRENDER,
    clientName: poName ?? '',
    ...detail.payload.body,
    cashOutList: cashOutList,
    branchName: branchName,
    methods:
      detail.payload.body.cashOutOptions?.map((v, i) => ({
        method: v.type ?? '',
        detail: getPayOutDetail(
          {
            type: v.type as PaymentType,
            officeCode:
              (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) || '',

            bankAccount: {
              accountName:
                (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                (v.type === PaymentType.PAID_AT_BANK &&
                  (v.attributesExt?.PAYEE_INFO?.payeeName === '' ? poName : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                (v.type === PaymentType.CASH_AT_COUNTER &&
                  (v.attributesExt?.PAYEE_INFO?.payeeName === '' ? poName : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                (v.type === PaymentType.MOMO &&
                  (v.attributesExt?.PAYEE_INFO?.payeeName === '' ? poName : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                '-',
              accountNo:
                (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                  detail.clientNumber) ||
                ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                  detail.clientNumber) ||
                ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                '-',
              bankName:
                (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                '-',
              branchName:
                (v.type === PaymentType.BANKTRANSFER && branchName) ||
                (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                undefined,
              bankCode: '-',
              branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
            },
            phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
            issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
            issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
            policy: {
              policyNo: v.policy?.policyNo || '-'
            }
          },
          clientPolicy
        ),
        amount: v.paymentOption.amount || 0,
        status: coreStatus[i].status,
        note: coreStatus[i].errorMes,
        action: '-',
        bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '-' : undefined
      })) || []
  }
}
