import { assets, useMobile } from '@pulseops/common'
import React from 'react'
import { ImageBackground, StyleSheet, Text, View } from 'react-native'

interface Props {
  menus: {
    label: string
    icon: JSX.Element
    value: string
  }[]
}

export const PolicyHeader: React.FC<Props> = ({ menus }) => {
  const { isMobile, isWide } = useMobile()

  return (
    <View style={[styles.header, isWide && styles.headerWide]}>
      <ImageBackground source={isMobile && assets.TaskDetailHeaderBackground} resizeMode="cover" style={styles.image}>
        <View style={[styles.container, { flexWrap: isMobile ? 'wrap' : 'nowrap' }]}>
          {menus.map((m, i) => (
            <View
              key={i}
              style={
                isMobile
                  ? styles.infoWrapper
                  : [styles.infoWrapper, styles.infoWrapperWide, i === menus.length - 1 && { flex: 2 / 3 }]
              }
            >
              {isWide && <View style={styles.icon}>{m.icon}</View>}
              <Text style={[styles.label, isMobile ? { flex: 1 } : {}]}>
                {m.label} {isWide && ': '}
              </Text>
              <Text style={[styles.bold, styles.label, styles.labelActive, isMobile ? { flex: 2 } : {}]}>
                {m.value}
              </Text>
            </View>
          ))}
        </View>
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    height: 68,
    marginTop: 10,
    marginBottom: 15
  },
  headerWide: {
    flexDirection: 'row',
    marginHorizontal: 15,
    backgroundColor: '#FFF',
    borderRadius: 8
  },
  image: {
    width: '100%',
    height: '100%'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 10
  },
  label: {
    fontSize: 15,
    lineHeight: 22,
    color: '#70777E'
  },
  labelActive: {
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  // Tab --
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 10
  },
  infoWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 10
  },
  infoWrapperWide: {
    flex: 1 / 3,
    width: 'auto',
    paddingLeft: 33
  },
  bold: {
    fontWeight: 'bold'
  }
})
