import { View, StyleSheet } from 'react-native'
import React from 'react'
import { Occupation, TaskDetail, translator } from '@pulseops/common'
import { InfoViewList, Title } from '../common'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

interface Props {
  detail: TaskDetail.ChangeOccupationPersonal
}

interface mappingDataI {
  isBorder: boolean
  title: string
  dataSource: Array<{ label: string; value: string }>
}

const ChangeOccupationPersonal = ({ detail }: Props) => {
  const { clientName, occupation } = detail
  const { occupationDetail, jobTitle, salary, specificJob, workAddress, workPlace, occupationClass } = occupation
  const { t, i18n } = useTranslation()

  const mappingData: mappingDataI[] = [
    {
      isBorder: true,
      title: t('CHANGE_OCCUPATION:GROUPS_VIEWS.LABEL_GROUP.info_change_client_group'),
      dataSource: [
        { label: t('submission:category'), value: t('Reports:PolicyService') },
        { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
        {
          label: t('submission:transactionType'),
          value: t(`TransactionType:${detail.tag}`)
        }
      ]
    },
    {
      isBorder: false,
      title: '',
      dataSource: [{ label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.selectClient'), value: clientName }]
    },
    {
      isBorder: true,
      title: '',

      dataSource: [
        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.occupation'),
          value: (i18n.language === 'vi' ? occupationDetail?.nameVn : occupationDetail?.nameEn) ?? '-'
        },
        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.jobDescription'),
          value: specificJob
        },
        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.jobTitle'),
          value: jobTitle
        },

        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.companyName'),
          value: workPlace
        },
        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.companyAddress'),
          value: workAddress
        },
        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.salary'),
          value: t(translator(Occupation.getSalaryTitle(salary)))
        },

        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.occupationGroup'),
          value: `${occupationDetail?.positionCode} - ${occupationDetail?.positionName}`
        },
        {
          label: t('CHANGE_OCCUPATION:GROUPS_VIEWS.FIELDS.occupationClass'),
          value: occupationClass
        }
      ]
    }
  ]

  const renderViews = () => {
    if (isEmpty(detail)) return

    return mappingData.map((group, index) => {
      const { isBorder, title, dataSource } = group
      return (
        <View key={index} style={{ paddingVertical: 12 }}>
          {!!title ? <Title title={title} /> : null}
          <View style={[isBorder && styles.borderGroup]}>
            {!isEmpty(dataSource) ? <InfoViewList dataSource={dataSource} /> : null}
          </View>
        </View>
      )
    })
  }

  return <>{renderViews()}</>
}

export default ChangeOccupationPersonal

const styles = StyleSheet.create({
  borderGroup: {
    borderRadius: 6,
    paddingHorizontal: 12,
    paddingVertical: 30,
    borderStyle: 'solid',
    borderColor: '#d3dce6',
    borderWidth: 1
  }
})
