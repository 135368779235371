/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react'
import * as _ from 'lodash'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ScrollView, View, StyleSheet, TouchableOpacity, Text, useWindowDimensions } from 'react-native'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import {
  AppContext,
  AuthService,
  DatePicker,
  ErrorHandling,
  form2,
  FromToDateRangeMonth,
  Input,
  SelectOption,
  SelectSearch,
  TransactionLabel,
  TransactionType,
  useMobile
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { NotificationForm } from './model'
import moment from 'moment'
import { NotificationService } from './services/notification-services'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Paper, Table, TableBody, TableContainer, TablePagination, LinearProgress } from '@material-ui/core'
import { ViewStatus } from './compoment'
import { NotificationData } from './services'
import i18next from 'i18next'
interface Props extends DrawerContentComponentProps<DrawerContentOptions> {}
export const NotificationScreen = ({ navigation }: Props) => {
  const isFocused = useIsFocused()
  const { t } = useTranslation('Notification')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoading] = useLoading(false)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [message, setMessage] = React.useState<string | null>(null)
  const [errMess, setErrMess] = React.useState<string>('')
  const [submittedData, setSubmittedData] = React.useState<NotificationData.notification>({})
  const currentDate = new Date()
  const [data, setData] = React.useState<Array<NotificationService.NotificationData>>([])
  const [listTransaction, setListTransaction] = React.useState<SelectOption[]>([
    {
      value: '',
      label: ''
    }
  ])
  const [errorMsg, setErrorMsg] = React.useState<string>('')
  const [errorFuturedate, setErrorFuturedate] = React.useState<string>('')
  const [fromDate, setFromDate] = React.useState<Date | null>(null)
  const [toDate, setToDate] = React.useState<Date | null>(null)
  const { base, handleSubmit } = form2.useForm(NotificationForm.codec, {
    defaultValues: {
      fromDate: null,
      toDate: null
    }
  })
  const formError = _.get(base.formState.errors, '.message')

  const getListNotification = (submittedData: NotificationData.notification, pageStart: number, perPage: number) => {
    const transactionType = base.getValues('transactionName')?.map((item) => item.value)
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((userinfo) =>
        NotificationService.NotificationList({
          order: 'notificationDate',
          sort: 'DESC',
          start: pageStart ?? page,
          size: perPage ?? rowsPerPage,
          policyNumber: submittedData.policyNumber ?? '',
          transactionTypes: transactionType,
          fromDate: submittedData.fromDate ?? undefined,
          toDate: submittedData.toDate ?? undefined,
          createdBy: userinfo.isGaLogin ? userinfo.name : userinfo.email
        })
      ),
      ZIO.tap((res) => {
        setData(res?.data ?? [])
        setTotalItem(res.total ?? 0)
        setMessageResponse(res?.data?.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const search = handleSubmit((data) => {
    setFromDate(data.fromDate)
    setToDate(data.toDate)
    if (data && data.fromDate && data.toDate && moment(data.toDate).isBefore(data.fromDate)) {
      setErrorFuturedate('')
      setErrMess('')
      setMessage('')
      setErrorMsg(t('message:MS030044'))
    } else if (data && data.toDate.getTime() > currentDate.getTime()) {
      setErrorMsg('')
      setErrMess('')
      setMessage('')
      setErrorFuturedate(t('message:MS990032'))
    } else if (
      data &&
      data?.policyNumber === undefined &&
      !!data?.transactionName &&
      data.transactionName?.length === 0
    ) {
      setErrorFuturedate('')
      setErrMess('')
      setErrorMsg('')
      setMessage(i18next.t('message:MS020036'))
    } else if (FromToDateRangeMonth(12)({ fromDate, toDate })) {
      setErrorFuturedate('')
      setErrorMsg('')
      setMessage('')
      setErrMess(t('message:MS080004', { k: '12' }))
    } else {
      setErrorFuturedate('')
      setErrMess('')
      setErrorMsg('')
      setMessage('')
      setMessageResponse(null)
      setPage(0)
      const submitData: NotificationData.notification = {
        order: 'notificationDate',
        sort: 'DESC',
        policyNumber: !!data.policyNumber ? data.policyNumber : undefined,
        transactionTypes: !!data?.transactionName ? data?.transactionName?.map((item) => item.value) : undefined,
        fromDate: !!data.fromDate ? moment(data.fromDate).format('yyyy-MM-DD') : undefined,
        toDate: !!data?.toDate ? moment(data?.toDate).format('yyyy-MM-DD') : undefined
      }
      setSubmittedData(submitData)
      getListNotification(submitData, 0, rowsPerPage)
    }
  })
  const onReset = () => {
    setErrorFuturedate('')
    setErrMess('')
    setMessage('')
    setMessageResponse(null)
    setErrorMsg('')
    base.reset({
      policyNumber: null,
      fromDate: null,
      toDate: null,
      transactionName: null
    })
    getListNotification({}, 0, 10)
  }

  const getListTransactionNames =
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((userinfo) =>
        NotificationService.TransactionNameList(userinfo.isGaLogin ? userinfo.name : userinfo.email)
      ),
      ZIO.map((res) => res),
      ZIO.tap((item) => {
        const listName: SelectOption[] = []
        if (!!item && item.length > 0) {
          item.forEach((element) => {
            listName.push({
              label: t(TransactionLabel(element as TransactionType)),
              value: element
            })
          })
          setListTransaction(listName)
        }

        return ZIO.unit
      }),
      ErrorHandling.runDidUpdate([i18next.language])
    ) || []

  React.useEffect(() => {
    getListTransactionNames
    getListNotification({}, 0, 10)
    setErrorFuturedate('')
    setErrMess('')
    setMessage('')
    setMessageResponse(null)
    setErrorMsg('')
    setSubmittedData({})
    base.reset({})
  }, [])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Notification'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            <View style={styles.row}>
              <View style={styles.col}>
                <View style={{ flex: 1 }}>
                  <Controller
                    name="policyNumber"
                    control={base.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('PolicyNumber')}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        errorMessage={error?.message}
                        maxLength={8}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={styles.col}>
                <View style={{ flex: 1 }}>
                  <Controller
                    name="transactionName"
                    control={base.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <SelectSearch
                          label={t('TransactionName')}
                          options={listTransaction}
                          placeholder={t('common:Select')}
                          onChange={onChange}
                          value={value ?? undefined}
                          multiple={true}
                          errorMessage={error?.message}
                        />
                      )
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="fromDate"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <DatePicker
                      required
                      label={t('FromDate')}
                      // onChange={onChange}
                      onChange={(val) => {
                        onChange(val)
                        setFromDate(val)
                        // onChangeFromDate(val)
                      }}
                      value={value}
                      maxDate={currentDate}
                      alwaysShow={false}
                      // minDate={toDate ?? undefined}
                      minDateMessage={''}
                      errorMessage={error?.message}
                      maxDateMessage={t('message:MS990032')}
                    />
                  )}
                />
              </View>
              <View style={styles.col}>
                <Controller
                  name="toDate"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <DatePicker
                      alwaysShow={false}
                      required
                      label={t('ToDate')}
                      // onChange={onChange}
                      onChange={(val) => {
                        onChange(val)
                        setToDate(val)
                        // onChangeToDate(val)
                      }}
                      value={value}
                      maxDateMessage={t('message:MS990032')}
                      minDateMessage={''}
                      maxDate={currentDate}
                      minDate={base.watch('fromDate')}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
            <View style={styles.rowbnt}>
              <TouchableOpacity
                onPress={() => {
                  search()
                }}
              >
                <View style={styles.btnsearch}>
                  <Text style={styles.btnText}>{t('Search')}</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  onReset()
                }}
                style={{ marginLeft: 30 }}
              >
                <View style={styles.btnclear}>
                  <Text style={styles.btnTextCl}>{t('Clear')}</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.row, { marginTop: 6, marginHorizontal: 16 }]}>
              {!!formError && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{formError}</Text>}
              {!!message && <Text style={{ color: '#ed1b2e', marginVertical: 12 }}>{message}</Text>}
              {!!errorMsg && <Text style={{ color: '#ED1B2E' }}>{errorMsg}</Text>}
              {!!errMess && <Text style={{ color: '#ED1B2E' }}>{errMess}</Text>}
              {!!errorFuturedate && <Text style={{ color: '#ED1B2E' }}>{errorFuturedate}</Text>}
            </View>
          </View>
          <Notification formNotification={base} message={messageResponse} loading={loading} data={data} />
          {!!data && data.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalItem}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              onPageChange={(e, page) => {
                setPage(page)
                getListNotification(submittedData, page, rowsPerPage)
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(Number(e.target.value))
                setPage(0)
                getListNotification(submittedData, page, Number(e.target.value))
              }}
            />
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  borderWith: {
    backgroundColor: '#26a4f2',
    marginHorizontal: 20
  },
  containerbody: {
    backgroundColor: '#fff',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 45,
    marginVertical: 20,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderLeftWidth: 7,
    borderLeftColor: '#26a4f2'
  },
  container: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 16,
    marginVertical: 16,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 16,
    marginVertical: 16,
    paddingVertical: 16,
    paddingHorizontal: 16
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },
  rowbnt: {
    flexDirection: 'row',
    paddingHorizontal: 30,
    paddingVertical: 5
  },
  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btnsearch: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    boxShadow: 'none',
    paddingVertical: 10,
    paddingHorizontal: 25
  },
  btnclear: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    boxShadow: 'none',
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderColor: '#ed1b2e',
    borderWidth: 1
  },

  btnText: {
    textAlign: 'center',
    color: '#fff',
    marginLeft: 6,
    fontWeight: 'bold',
    fontSize: 15
  },
  btnTextCl: {
    textAlign: 'center',
    color: '#ed1b2e',
    marginLeft: 6,
    fontWeight: 'bold',
    fontSize: 15
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  }
} as const)
export const Notification = React.forwardRef(
  (props: {
    message: string | null
    loading: boolean
    data: Array<NotificationService.NotificationData>
    formNotification: Omit<UseFormReturn<NotificationForm.Raw>, 'handleSubmit'> | unknown
  }, ref) => {
    const { t, i18n } = useTranslation('Notification')
    const { height } = useWindowDimensions()
    const { isMobile } = useMobile()
    if (props.loading) {
      return <LinearProgress color="secondary" />
    }
    return (
      <View>
        {props.message ? (
          <View style={{ marginHorizontal: 30 }}>
            <Text style={{ color: '#ED1B2E' }}>{props.message}</Text>
          </View>
        ) : (
          <View>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16,
                maxHeight: height
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  maxHeight: height
                }}
              >
                <Table stickyHeader>
                  <TableBody>
                    {props.data.map((item, index) => {
                      return (
                        <View style={styles.containerbody}>
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingVertical: 5
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                              <Text
                                style={{
                                  flex: 0.25,
                                  textAlign: isMobile ? 'left' : 'right',
                                  marginRight: 10,
                                  fontSize: 15
                                }}
                              >
                                {t('CaseID')}
                              </Text>
                              <Text style={{ flex: 1, fontSize: 15, fontWeight: 'bold' }}>{item.caseId}</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                              <Text
                                style={{
                                  flex: 0.3,
                                  textAlign: isMobile ? 'left' : 'right',
                                  marginRight: 10,
                                  fontSize: 15
                                }}
                              >
                                {t('Category')}{' '}
                              </Text>
                              <Text style={{ flex: 1, fontSize: 15, fontWeight: 'bold' }}>{item.category}</Text>
                            </View>
                            <View style={{ flex: 0.5, flexDirection: isMobile ? 'column' : 'row' }}>
                              <Text
                                style={{
                                  flex: 0.5,
                                  textAlign: isMobile ? 'left' : 'right',
                                  marginRight: 10,
                                  fontSize: 15
                                }}
                              >
                                {t('CaseStatus')}{' '}
                              </Text>
                              <Text style={{ flex: 1, fontSize: 15, fontWeight: 'bold' }}>
                                {' '}
                                <ViewStatus text={item.caseStatus} />
                              </Text>
                            </View>
                          </View>
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 5 }}>
                            <View style={{ flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                              <Text
                                style={{
                                  flex: 0.25,
                                  textAlign: isMobile ? 'left' : 'right',
                                  marginRight: 10,
                                  fontSize: 15
                                }}
                              >
                                {t('PolicyNumber')}
                              </Text>
                              <Text style={{ flex: 1, fontSize: 15, fontWeight: 'bold' }}>{item.policyNumber}</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                              <Text
                                style={{
                                  flex: 0.3,
                                  textAlign: isMobile ? 'left' : 'right',
                                  marginRight: 12,
                                  fontSize: 15
                                }}
                              >
                                {t('TransactionnameDetail')}{' '}
                              </Text>
                              <Text style={{ flex: 1, fontSize: 15, fontWeight: 'bold' }}>
                                {t(TransactionLabel(item.transactionType as TransactionType))}
                              </Text>
                            </View>
                            <View style={{ flex: 0.5, flexDirection: isMobile ? 'column' : 'row' }}>
                              <Text
                                style={{
                                  flex: 0.5,
                                  textAlign: isMobile ? 'left' : 'right',
                                  marginRight: 10,
                                  fontSize: 15
                                }}
                              >
                                {t('CreatedDate')}{' '}
                              </Text>
                              <Text style={{ flex: 1, fontSize: 15, fontWeight: 'bold' }}>
                                {moment(item.createdDate).format('DD/MM/YYYY')}
                              </Text>
                            </View>
                          </View>
                          <View style={{ paddingVertical: 5 }}>
                            <Text style={{ marginLeft: isMobile ? 0 : i18n.language === 'vi' ? 19 : 18, fontSize: 15 }}>
                              {i18n.language === 'vi' ? item.message : item.messageEn}
                            </Text>
                          </View>
                        </View>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </View>
        )}
      </View>
    )
  }
)
