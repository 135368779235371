import { Ref } from '@mxt/zio/stream'
import { Employee } from '@pulseops/business/core'
import { ErrorHandling, RootNavigation } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import { BusinessViewEmployeeModalParam } from '../../root-stack-param-list'

export namespace BusinessViewEmployeeModalService {
  export const viewing = Ref.make<Employee | null>(null)

  export const view = (param: BusinessViewEmployeeModalParam) => {
    pipe(RootNavigation.navigate('BusinessViewEmployeeModal', param), ErrorHandling.run())
  }
}
