import { Customer } from '../Client'
import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { GeneralData } from '../../GeneralService'
import moment from 'moment'
import { isNil, isString } from 'lodash'

export interface ChangeOtherInfomationPersonal {
  tag: TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL
  source: SourceType
  clientName: string
  data: {
    firstName: string
    surName: string
    clientNum: string
    idType: string
    idNumber: string
    issuedBy: string
    issuedDate: string

    isCheckChangeFullNameGroup: boolean
    isCheckChangeCardGroup: boolean
    isCheckChangeNationalityGroup: boolean

    nationality: string
    usTaxDeclarationFrom: string | boolean
    countryOfForeignAddress: string
    taxResidencyCountry: string
    nationality2: string
    foreignAddress: string
  }
}

interface Props {
  detail: api.ChangeOtherInfomationPersonal
  customer: Customer
  countries: GeneralData[]
  provinces: {
    code: string
    name: string
    bcCode: string
  }[]
}

export const ChangeOtherInfomationPersonal = ({
  detail,
  customer,
  countries,
  provinces
}: Props): ChangeOtherInfomationPersonal => {
  const { transactionType, source, payload } = detail

  const { attributesExt, firstName, surName, nationality, addressDetails } = payload.body ?? {}
  const {
    clientNum,
    idNumber,
    idType,
    issuedBy,
    issuedDate,
    usTaxDeclarationFrom,
    isCheckChangeCardGroup,
    isCheckChangeFullNameGroup,
    isCheckChangeNationalityGroup,
    nationality2Name = '',
    taxResidencyCountryName = ''
  } = attributesExt ?? {}

  const { FOREIGN_ADDRESS } = addressDetails ?? {
    FOREIGN_ADDRESS: {
      line1: '',
      countryCode: '',
      country: ''
    }
  }

  const {
    body: { name }
  } = customer

  switch (source) {
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS: {
      return {
        tag: transactionType,
        source: source,
        clientName: name || '-',
        data: {
          firstName: firstName || '-',
          surName: surName || '-',
          clientNum: clientNum || '-',
          idType: idType || '-',
          idNumber: idNumber || '-',
          issuedBy: provinces.find((p) => p.code === issuedBy)?.name || issuedBy || '-',
          issuedDate: issuedDate ? moment(issuedDate).format('DD/MM/YYYY') : '-',
          nationality: countries.find((x) => x.code === nationality)?.name || '-',
          usTaxDeclarationFrom: !isNil(usTaxDeclarationFrom)
            ? isString(usTaxDeclarationFrom)
              ? usTaxDeclarationFrom
              : usTaxDeclarationFrom
              ? 'Y'
              : 'N'
            : '-',

          isCheckChangeCardGroup: isCheckChangeCardGroup ?? false,
          isCheckChangeFullNameGroup: isCheckChangeFullNameGroup ?? false,
          isCheckChangeNationalityGroup: isCheckChangeNationalityGroup ?? false,
          countryOfForeignAddress: FOREIGN_ADDRESS?.country || '-',
          taxResidencyCountry: taxResidencyCountryName || '-',
          nationality2: nationality2Name || '-',
          foreignAddress: FOREIGN_ADDRESS?.line1 || '-'
        }
      }
    }
  }
}
