import { SUCommonRequestData } from '../CommonRequest'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
// import {SUCommonRequestData} from '@pulseops/staff-submission-core'

export interface VeriCommonRequest {
  tag:
    | TransactionType.COMMON_REQUEST_FORM
    | TransactionType.RIDER_ALTERATION
    | TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK
    | TransactionType.MAJOR_CHANGE_IN_FREE_LOOK
    | TransactionType.OTHER_MAJOR_ALTERATION
    | TransactionType.OTHER_MINOR_ALTERATION
    | TransactionType.OTHER_PAYOUT
  clientName: string
  request: string
  commonRequestList: SUCommonRequestData.CommonRequest[]
}

export const CommonRequest = (
  detail: api.PayLoadCommonRequest & api.TaskDetailBase,
  poName: string,
  commonRequestList: SUCommonRequestData.CommonRequest[]
): VeriCommonRequest => {
  const payload = detail.payload?.body
  const temp = detail.transactionType
  return {
    tag: temp,
    clientName: poName,
    request: payload?.request ?? '',
    commonRequestList: commonRequestList
  }
}
