/* eslint-disable @typescript-eslint/ban-types */
import { Maybe } from '@mxt/zio/codec'
import { SelectOption } from '@pulseops/common'
import * as t from 'io-ts'

export namespace SEABillingChangeModal {
  export const GenderData: { code: string; nameEN: string; nameVN: string }[] = [
    {
      code: 'Năm',
      nameVN: 'Năm',
      nameEN: 'Year'
    },
    {
      code: 'Mỗi Năm',
      nameVN: 'Mỗi Năm',
      nameEN: 'Every Year'
    },
    {
      code: 'Nửa năm',
      nameVN: 'Nửa năm',
      nameEN: 'Half-Yearly'
    },
    {
      code: 'Quý',
      nameVN: 'Quý',
      nameEN: 'Quarterly'
    },
    {
      code: 'Mỗi Quý',
      nameVN: 'Mỗi Quý',
      nameEN: 'Every Quarterly'
    },
    {
      code: 'Tháng',
      nameVN: 'Tháng',
      nameEN: 'Monthly'
    },
    {
      code: 'MỗiTháng',
      nameVN: 'Mỗi Tháng',
      nameEN: 'Every month'
    }
  ]
  const GenderMapData = new Map<string, Map<string, string>>(
    GenderData.map((gender) => [
      gender.code,
      new Map([
        ['vi', gender.nameVN],
        ['en', gender.nameEN]
      ])
    ])
  )

  export const getGender = (code: string) => (languageCode: string) =>
    GenderMapData.get(code)?.get(languageCode) ?? code
  export const getGenderOption = (code: string) => (languageCode: string) => ({
    value: code,
    label: getGender(code)(languageCode)
  })
  export const getGenderOptions = (languageCode: String): SelectOption[] =>
    GenderData.map((item) => {
      return {
        label: languageCode === 'vi' ? item.nameVN : item.nameEN,
        value: item.code
      }
    })

  const FrequentFeeRaw: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'Năm',
      vi: 'Năm',
      en: 'Year'
    },
    {
      code: 'Nửa năm',
      vi: 'Nửa năm',
      en: 'Half-Yearly'
    },
    {
      code: 'Quý',
      vi: 'Quý',
      en: 'Quarterly'
    },
    {
      code: 'Tháng',
      vi: 'Tháng',
      en: 'Monthly'
    }
  ]

  // eslint-disable-next-line @typescript-eslint/ban-types
  const FrequentFeeMapData = new Map<String, Map<string, string>>(
    FrequentFeeRaw.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getFrequentName = (code: string) => (languageCode: string) =>
    FrequentFeeMapData.get(code)?.get(languageCode) ?? code

  const FailReason: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'MS990106',
      vi: 'Số hợp đồng định dạng không đúng',
      en: 'Policy format is incorrect'
    },
    {
      code: 'MS990107',
      vi: 'Định kỳ nộp phí bảo hiểm hiện tại không đúng qui định',
      en: 'Current billing frequency is incorrect'
    },
    {
      code: 'MS990108',
      vi: 'Định dạng phí bảo hiểm định kỳ hiện tại không đúng ',
      en: 'Current installment premium format is incorrect'
    },
    {
      code: 'MS990109',
      vi: 'Định dạng ngày hiệu lực của định kỳ mới không đúng',
      en: 'Effective date of new frequency  format is incorrect'
    },

    {
      code: 'MS990110',
      vi: 'Định kỳ nộp phí bảo hiểm mới trùng định kỳ nộp phí bảo hiểm hiện tại',
      en: 'New billing frequency and Current billing frequency are the same'
    },
    {
      code: 'MS990111',
      vi: 'Định kỳ nộp phí bảo hiểm mới không đúng qui định',
      en: 'New billing frequency is incorrect'
    },
    {
      code: 'MS990112',
      vi: '	Định dạng phí bảo hiểm mới hiện tại không đúng',
      en: 'New installment premium format is incorrect'
    },
    {
      code: 'MS990113',
      vi: 'Họ tên NĐBH > 50 ký tự',
      en: 'Life Asure File Name > 50 characters'
    },

    {
      code: 'MS990114',
      vi: 'Có ký tự đặc biệt',
      en: 'The case has special character'
    },
    {
      code: 'MS990115',
      vi: 'Hợp đồng đã mất hiệu lực',
      en: 'The policy has been lapsed'
    },
    {
      code: 'MS990116',
      vi: 'Hợp đồng phí đơn kỳ',
      en: 'Single-term premium policy'
    },
    {
      code: 'MS990117',
      vi: 'Hợp đồng quá ngày được điều chỉnh định kỳ đóng phí',
      en: 'Policy is overdue to adjust billing frequency'
    },
    {
      code: 'MS990118',
      vi: 'Hợp đồng không thỏa điều kiện điều chỉnh định kỳ đóng phí',
      en: 'The policy does not meet the conditions for billing frequency'
    },
    {
      code: 'MS990119',
      vi: 'Hợp đồng không thuộc Hợp đồng nhóm Seabank',
      en: 'The Policy is out of scope Group Policy Seabank'
    },
    {
      code: 'MS990120',
      vi: '	Hợp đồng có ô trống',
      en: 'The Policy has a null field'
    },
    {
      code: 'MS990121',
      vi: 'Sai tên NĐBH',
      en: 'LA Name is incorrect'
    },
    {
      code: 'MS990122',
      vi: 'Hợp đồng bị trùng',
      en: 'Duplicated Policy'
    },
    {
      code: 'MS990127',
      vi: 'Lỗi khác',
      en: 'OTHER'
    },
    {
      code: 'MS050219',
      vi: 'Nhân viên Prudential sẽ thông báo đến khách hàng phí Đổi định kỳ trong thời gian sớm nhất',
      en: 'Prudential Agent will notify the periodic change fee in the shortest time.'
    },
    {
      code: 'MS050220',
      vi: 'Phí định kỳ mới không đạt Phí tối thiểu. Vui lòng chọn lại định kỳ mới',
      en: 'The new premium does not meet the Minimum fee. Please select new period.'
    }
  ]

  const FailReasonMapData = new Map<String, Map<string, string>>(
    FailReason.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getFailReasonName = (code: string) => (languageCode: string) =>
    FailReasonMapData.get(code)?.get(languageCode) ?? code

  const ActionName: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'N',
      vi: 'Đăng ký yêu cầu',
      en: 'Register'
    },
    {
      code: 'D',
      vi: 'Huỷ đăng ký',
      en: 'Cancel'
    }
  ]

  const ActionNameMapData = new Map<String, Map<string, string>>(
    ActionName.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getActionName = (code: string) => (languageCode: string) =>
    ActionNameMapData.get(code)?.get(languageCode) ?? '-'

  const StatusName: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'Success',
      vi: 'Thành công',
      en: 'Success'
    },
    {
      code: 'Waiting',
      vi: 'Đang xử lý',
      en: 'Waiting'
    },
    {
      code: 'Fail',
      vi: 'Thất bại',
      en: 'Fail'
    },
    {
      code: 'Duplicate',
      vi: 'Trùng tập tin',
      en: 'Duplicate'
    },
    {
      code: 'Timeout',
      vi: 'Hết hạn',
      en: 'Timeout'
    },
    {
      code: 'Error',
      vi: 'Lỗi',
      en: 'Error'
    }
  ]

  const StatusNameMapData = new Map<String, Map<string, string>>(
    StatusName.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getStatusName = (code: string) => (languageCode: string) =>
    StatusNameMapData.get(code)?.get(languageCode) ?? code

  const FailReasonWithdraw: {
    code: string
    vi: string
    en: string
  }[] = [
    {
      code: 'MS990106',
      vi: 'Số hợp đồng định dạng không đúng',
      en: 'Policy format is incorrect'
    },
    {
      code: 'MS990119',
      vi: 'Hợp đồng không thuộc Hợp đồng nhóm Seabank',
      en: 'The Policy is out of scope Group Policy Seabank'
    },
    {
      code: 'MS990123',
      vi: 'Sai hình thức chi trả',
      en: 'Payment method is incorrect'
    },
    {
      code: 'MS990124',
      vi: 'Định dạng ngày không đúng',
      en: 'Date format is incorrect'
    },

    {
      code: 'MS990125',
      vi: 'Đến ngày phải lớn hơn Từ ngày',
      en: 'To date must be greater than From date'
    },
    {
      code: 'MS990126',
      vi: 'Trùng danh sách quỹ ưu tiên ',
      en: 'Priority fund list is duplicated'
    },
    {
      code: 'MS990114',
      vi: 'Có ký tự đặc biệt',
      en: 'The case has special character'
    },
    {
      code: 'MS990115',
      vi: 'Hợp đồng đã mất hiệu lực',
      en: 'The policy has been lapsed'
    },

    {
      code: 'MS990122',
      vi: 'Hợp đồng bị trùng',
      en: 'Duplicated Policy '
    },
    {
      code: 'MS990120',
      vi: 'Hợp đồng có ô trống',
      en: 'The Policy has a null field'
    },
    {
      code: 'MS990129',
      vi: 'Vui lòng thực hiện danh sách quỹ đầu tư thêm',
      en: 'Please implement topup fund list'
    },
    {
      code: 'MS990130',
      vi: 'Hợp đồng chưa thực hiện tính năng đăng ký',
      en: 'The Policy has not yet registered'
    },
    {
      code: 'MS990131',
      vi: 'Vui lòng thực hiện tính năng N hoặc D',
      en: 'Please implement action N or D'
    },
    {
      code: 'MS990127',
      vi: 'Lỗi khác',
      en: 'OTHER'
    },
    {
      code: 'MS050219',
      vi: 'Nhân viên Prudential sẽ thông báo đến khách hàng phí Đổi định kỳ trong thời gian sớm nhất',
      en: 'Prudential Agent will notify the periodic change fee in the shortest time.'
    },
    {
      code: 'MS990132',
      vi: 'Hợp đồng không có quỹ đầu tư thêm',
      en: 'The Policy has not topup fund'
    },
    {
      code: 'MS050220',
      vi: 'Phí định kỳ mới không đạt Phí tối thiểu. Vui lòng chọn lại định kỳ mới',
      en: 'The new premium does not meet the Minimum fee. Please select new period.'
    }
  ]

  const FailReasonWithdrawMapData = new Map<String, Map<string, string>>(
    FailReasonWithdraw.map((item) => [
      item.code,
      new Map<string, string>([
        ['vi', item.vi],
        ['en', item.en]
      ])
    ])
  )

  export const getFailReasonWithdrawName = (code: string) => (languageCode: string) =>
    FailReasonWithdrawMapData.get(code)?.get(languageCode) ?? code

  export enum Status {
    ALL = 'All',
    DUPLICATE = 'Duplicate',
    TIMEOUT = 'Time_out',
    FAIL = 'Fail',
    SUCCESS = 'Success'
  }
  export enum Action {
    ALL = '',
    N = 'N',
    D = 'D'
  }
  export enum Order {
    ASC = 'asc',
    DESC = 'desc'
  }

  export interface ExportData {
    order?: string
    sort?: string
    start?: number
    page?: number
    size?: number
    policyNumber?: string
    status: Status
    fromCreatedDate: Date
    toCreatedDate: Date
    implementUser?: string
    importedFile?: string
  }
  export interface ExportDataWithfraw {
    order?: string
    sort?: string
    start?: number
    page?: number
    size?: number
    policyNumber?: string
    status: Status
    functionType: Action
    fromCreatedDate: Date
    toCreatedDate: Date
    implementUser?: string
    importedFile?: string
  }
  export interface ExportDataLog {
    order?: string
    sort?: string
    start?: number
    page?: number
    size?: number
    fromCreatedDate: Date
    toCreatedDate: Date
    implementUser?: string
    importedFile?: string
  }
  export const ReportData = t.array(
    t.type({
      id: t.string,
      policyNumber: Maybe(t.string),
      exchangeIdWF: Maybe(t.string),
      currentBillingFrequency: Maybe(t.string),
      currentInstallmentPremium: Maybe(t.string),
      newDateEffect: Maybe(t.string),
      newBillingFrequency: Maybe(t.string),
      newInstallmentPremium: Maybe(t.string),
      requirePayInAmount: Maybe(t.string),
      lifeAssuredFulName: Maybe(t.string),
      status: Maybe(t.string),
      reason: Maybe(t.string),
      importedFile: Maybe(t.string),
      document: Maybe(t.string),
      importBy: Maybe(t.string),
      createdDate: Maybe(t.string)
    })
  )

  export const ReportWithdrawData = t.array(
    t.type({
      id: t.string,
      exchangeId: Maybe(t.string),
      policyNumber: Maybe(t.string),
      paymentMethod: Maybe(t.string),
      fromDate: Maybe(t.string),
      toDate: Maybe(t.string),
      fundPriorityFirst: Maybe(t.string),
      fundPrioritySecond: Maybe(t.string),
      fundPriorityThird: Maybe(t.string),
      fundPriorityFourth: Maybe(t.string),
      fundPriorityFifth: Maybe(t.string),
      fundPrioritySixth: Maybe(t.string),
      fundPrioritySeventh: Maybe(t.string),
      functionType: Maybe(t.string),
      document: Maybe(t.string),
      importedFile: Maybe(t.string),
      status: Maybe(t.string),
      reason: Maybe(t.string),
      importBy: Maybe(t.string),
      createdDate: Maybe(t.string),
      createdBy: Maybe(t.string),
      createdImportDate: Maybe(t.string),
      modifiedBy: Maybe(t.string),
      modifiedDate: Maybe(t.string),
      flag: Maybe(t.string)
    })
  )
  export const DetailWithdrawData = t.type({
    id: Maybe(t.string),
    exchangeId: Maybe(t.string),
    policyNumber: Maybe(t.string),
    paymentMethod: Maybe(t.string),
    fromDate: Maybe(t.string),
    toDate: Maybe(t.string),
    fundPriorityFirst: Maybe(t.string),
    fundPrioritySecond: Maybe(t.string),
    fundPriorityThird: Maybe(t.string),
    fundPriorityFourth: Maybe(t.string),
    fundPriorityFifth: Maybe(t.string),
    fundPrioritySixth: Maybe(t.string),
    fundPrioritySeventh: Maybe(t.string),
    functionType: Maybe(t.string),
    document: Maybe(t.string),
    importedFile: Maybe(t.string),
    status: Maybe(t.string),
    reason: Maybe(t.string),
    importBy: Maybe(t.string),
    createdDate: Maybe(t.string),
    createdBy: Maybe(t.string),
    createdImportDate: Maybe(t.string),
    modifiedBy: Maybe(t.string),
    modifiedDate: Maybe(t.string),
    flag: Maybe(t.string)
  })
  export const DetailData = t.type({
    id: Maybe(t.string),
    policyNumber: Maybe(t.string),
    exchangeIdWF: Maybe(t.string),
    currentBillingFrequency: Maybe(t.string),
    currentInstallmentPremium: Maybe(t.string),
    newDateEffect: Maybe(t.string),
    newBillingFrequency: Maybe(t.string),
    newInstallmentPremium: Maybe(t.string),
    requirePayInAmount: Maybe(t.string),
    lifeAssuredFulName: Maybe(t.string),
    status: Maybe(t.string),
    reason: Maybe(t.string),
    importedFile: Maybe(t.string),
    document: Maybe(t.string),
    importBy: Maybe(t.string),
    createdDate: Maybe(t.string)
  })
  export const QueryAutoDebitlogData = t.array(
    t.type({
      id: Maybe(t.string),
      exchangeId: Maybe(t.string),
      excelFileImportName: Maybe(t.string),
      fileExcelSize: Maybe(t.number),
      totalInputRecords: Maybe(t.number),
      filePdfName: Maybe(t.string),
      filePdfSize: Maybe(t.number),
      importBy: Maybe(t.string),
      createdDate: Maybe(t.string),
      waiting: Maybe(t.number),
      success: Maybe(t.number),
      fail: Maybe(t.number),
      duplicate: Maybe(t.number)
    })
  )
  export type QueryAutoDebitlogData = t.TypeOf<typeof QueryAutoDebitlogData>
  export type DetailWithdrawData = t.TypeOf<typeof DetailWithdrawData>
  export type DetailData = t.TypeOf<typeof DetailData>
  export type ReportData = t.TypeOf<typeof ReportData>
  export type ReportWithdrawData = t.TypeOf<typeof ReportWithdrawData>

  export const ReportsData = t.type({
    data: Maybe(ReportData),
    total: t.number,
    page: t.number,
    size: t.number,
    order: t.string,
    sort: t.string
  })

  export const ReportsWithdrawData = t.type({
    data: Maybe(ReportWithdrawData),
    total: t.number,
    page: t.number,
    size: t.number,
    order: t.string,
    sort: t.string
  })
  export const QueryAutoDebitlogsData = t.type({
    data: Maybe(QueryAutoDebitlogData),
    start: t.number,
    order: Maybe(t.string),
    sort: Maybe(t.string),
    total: t.number,
    page: t.number,
    size: t.number
  })
  export type QueryAutoDebitlogsData = t.TypeOf<typeof QueryAutoDebitlogsData>
  export type ReportsData = t.TypeOf<typeof ReportsData>
  export type ReportsWithdrawData = t.TypeOf<typeof ReportsWithdrawData>
}
