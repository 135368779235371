import React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { OBButton, OBFieldTitle, OBFormat, OBSectionContent } from '../ob-common'
import { useTranslation } from 'react-i18next'
import { AppContext, ImgUploadMutiplePC, OBImportFilePermission, form2 } from '@pulseops/common'
import { SectionContent } from '@pulseops/submission/common'
import { Controller } from 'react-hook-form'
import * as _ from 'lodash'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import { OBImportFileService } from '../ob-service'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { OBImportFileContext } from './OBImportFileContext'

const obImportFileTranslate = 'Outbound:OBImportFile.UploadFile'

export namespace OBUploadFileForm {
  const FileInfo = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  type UploadFileBrandData = {
    readonly MS150004: unique symbol
    readonly OB0059: unique symbol
    readonly OB0060: unique symbol
    readonly XXXMsg: unique symbol
  }

  type EvidenceDocumentBrandData = {
    readonly EvidenceDocumentMsg: unique symbol
  }

  export type FileInfo = t.TypeOf<typeof FileInfo>
  export type UploadFileBrand = t.Branded<FileInfo[], UploadFileBrandData>
  export type EvidenceDocumentBrand = t.Branded<FileInfo[], EvidenceDocumentBrandData>

  export const OBUploadFileData = t.type({
    uploadFiles: pipe(
      t.array(FileInfo),
      form2.refine(
        (l): l is UploadFileBrand => l && l.length > 0,
        () => i18next.t('message:MS150004'),
        'MS150004'
      ),
      form2.refine(
        (files): files is UploadFileBrand =>
          files && files.length > 0 && ['CCE', 'CS'].includes(files[0].fileName.split('_')[0]),
        () => i18next.t('message:OB0059'),
        'OB0059'
      ),
      form2.refine(
        (files): files is UploadFileBrand =>
          OBFormat.isRightFormatWithYYYYMMDD(files[0].fileName.split('_')[1]) &&
          OBFormat.isValidDate(files[0].fileName.split('_')[1], 'YYYYMMDD') &&
          OBFormat.isEqualWithCurrentDate(files[0].fileName.split('_')[1]),
        () => i18next.t('message:OB0060'),
        'OB0060'
      ),
      form2.refine(
        (files): files is UploadFileBrand => OBFormat.isRightFormatWithXXX(files[0].fileName.split('_')[2]),
        () => 'Tên file sai định dạng 3 ký tự cuối. Vui lòng chọn file đúng định dạng 3 ký tự cuối xxx',
        'XXXMsg'
      )
    ),
    evidenceDocuments: pipe(
      t.array(FileInfo),
      form2.refine(
        (l): l is EvidenceDocumentBrand =>
          !l ||
          l.length <= 0 ||
          uploadFileInitialData.uploadFiles.length <= 0 ||
          (l.length > 0 &&
            uploadFileInitialData.uploadFiles.length > 0 &&
            l[0].fileName === uploadFileInitialData.uploadFiles[0].fileName),
        () => i18next.t('message:OB0079'),
        'EvidenceDocumentMsg'
      )
    )
  })

  export type OBUploadFileData = t.TypeOf<typeof OBUploadFileData>
  export type OBUploadFileDataRaw = t.OutputOf<typeof OBUploadFileData>
}

let uploadFileInitialData: OBUploadFileForm.OBUploadFileDataRaw = {
  uploadFiles: [],
  evidenceDocuments: []
}

type Props = {
  permissions: string[]
}

export const OBUploadFile = (props: Props) => {
  const { t } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const {setIsUploadImportfile} = React.useContext(OBImportFileContext)
  const { permissions } = props

  const uploadFileForm = form2.useForm(OBUploadFileForm.OBUploadFileData, {
    defaultValues: {
      uploadFiles: [],
      evidenceDocuments: []
    }
  })

  uploadFileInitialData = Object.assign(uploadFileInitialData, uploadFileForm.base.watch())

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const onHandleImportFile = () => {
    if (uploadFileForm.base.formState.isValid) {
      const formData = uploadFileForm.base.getValues()
      const evidenceDocument =
        formData.evidenceDocuments && formData.evidenceDocuments.length > 0
          ? (formData.evidenceDocuments[0].file as Blob)
          : ''
      pipe(
        OBImportFileService.importFile(formData.uploadFiles[0].file as Blob, evidenceDocument),
        ZIO.map((response) => {
          switch (response.code) {
            case 'OB0068': {
              showToast(t('message:OB0068'), 'success')
              resetForm()
              setIsUploadImportfile && setIsUploadImportfile(true)
              return
            }

            default: {
              return showToast(t(`message:${response.code}`), 'error')
            }
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const resetForm = () => {
    uploadFileForm.base.reset({
      uploadFiles: [],
      evidenceDocuments: []
    })
  }

  return (
    <ScrollView style={uploadStyles.container}>
      <OBSectionContent>
        <OBFieldTitle
          textStyle={uploadStyles.fieldTitle}
          text={t(`${obImportFileTranslate}.ImportFile`).toUpperCase()}
        ></OBFieldTitle>
        <Text style={uploadStyles.fieldSubTitle}>
          {t(`${obImportFileTranslate}.ImportFileAttachment`)}{' '}
          <Text style={[uploadStyles.fieldSubTitle, uploadStyles.requiredText]}>*</Text>
        </Text>
        <Controller
          control={uploadFileForm.base.control}
          name={'uploadFiles'}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <>
              <ImgUploadMutiplePC
                value={value as any[]}
                onChange={(val) => {
                  onChange(val)
                  uploadFileForm.base.trigger('evidenceDocuments')
                }}
                onBlur={onBlur}
                timeFormat={'DD/MM/YYYY HH:mm'}
                maxSizeFile={10}
                maxTotalFile={10}
                messageFormat={'TaskManagement:MSFormatUploadFileXLSX'}
                validExtensions={['XLS', 'XLSX']}
                messageMaxSize={'TaskManagement:MaxSizeFile10MB'}
                maxNumFile={1}
                takeAPicture={false}
                showFullFileName
                isFileDownload={false}
                errorMessage={'message:OB0075'}
              />
              {error && error.message && <Text style={uploadStyles.errorMessageText}>{error.message}</Text>}
            </>
          )}
        />
        <SectionContent sectionStyles={uploadStyles.evidenceContainer}>
          <OBFieldTitle
            textStyle={uploadStyles.fieldTitle}
            text={t(`${obImportFileTranslate}.EvidenceDocument`).toUpperCase()}
          ></OBFieldTitle>
          <Text style={uploadStyles.fieldSubTitle}>{t(`${obImportFileTranslate}.EvidenceAttachment`)}</Text>
          <Controller
            control={uploadFileForm.base.control}
            name={'evidenceDocuments'}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <>
                <ImgUploadMutiplePC
                  value={value as any[]}
                  onChange={onChange}
                  onBlur={onBlur}
                  timeFormat={'DD/MM/YYYY HH:mm'}
                  maxSizeFile={10}
                  maxTotalFile={10}
                  messageFormat={'TaskManagement:MSFormatUploadFileEvidenceOB'}
                  messageMaxSize={'message:OB0076'}
                  maxNumFile={1}
                  takeAPicture={false}
                  showFullFileName
                  isFileDownload={false}
                  validExtensions={['PNG', 'JPG', 'JPEG', 'PDF', 'TIF', 'TIFF', 'MSG', 'DOCX']}
                  errorMessage={'message:OB0078'}
                />
                {error && error.message && <Text style={uploadStyles.errorMessageText}>{error.message}</Text>}
              </>
            )}
          />
        </SectionContent>
      </OBSectionContent>
      
      {permissions.includes(OBImportFilePermission.ImportImportFileAdhoc) ? (
        <View style={uploadStyles.footer}>
          <OBButton
            text={t(`${obImportFileTranslate}.ImportFile`)}
            onHandleClickEvent={() => onHandleImportFile()}
            isHightLight={uploadFileForm.base.formState.isValid}
            disabled={!uploadFileForm.base.formState.isValid}
          ></OBButton>
        </View>
      ) : null}
    </ScrollView>
  )
}
const uploadStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  evidenceContainer: {
    backgroundColor: '#F5F5F5'
  },
  fieldTitle: {
    color: '#4F4F4F',
    fontSize: 16,
    marginBottom: 15
  },
  fieldSubTitle: {
    color: '#70777E',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '700'
  },
  requiredText: {
    color: '#ED1B2E'
  },
  footer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 16
  },
  errorMessageText: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
