import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { IBGeneralTable, IBGridDataView, IBPanel } from '../../../common'
import { useTranslation } from 'react-i18next'
import {
  IBService,
  PrurewardAgentData,
  PrurewardCustomerInfoData,
  PrurewardCustomerNoteData,
  PrurewardGiftRedemptionHistoryData,
  PrurewardPointHistoryData,
  PrurewardRedeemCodeHistoryData,
  PrurewardTierHistoryData
} from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { isEmpty, isNil, orderBy } from 'lodash'
import moment from 'moment'
import { PointTypeList, StatusGiftList, StatusPointList, StatusRedeemList } from './IBPrurewardsConst'
import { PulseOpsFormat, SelectOption, SelectSearch, assets } from '@pulseops/common'

const IBPointHistory = (props: {
  data?: PrurewardPointHistoryData[]
  setData: (data: PrurewardPointHistoryData[]) => void
  clientNumber?: string
}) => {
  const { t, i18n } = useTranslation('Inbound')
  const statusPoint = StatusPointList.map((item) => ({
    value: item.code,
    label: i18n.language === 'vi' ? item.nameVn : item.nameEn
  }))

  const typePoint = PointTypeList.map((item) => ({
    value: item.code,
    label: i18n.language === 'vi' ? item.nameVn : item.nameEn
  }))
  const { data, setData, clientNumber } = props
  const [loading, bindLoading] = useLoading()
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)
  const [statusFilter, setStatusFilter] = React.useState<SelectOption>(
    statusPoint.find((x) => x.value === '') as SelectOption
  )
  const [typeFilter, setTypeFilter] = React.useState<SelectOption>(
    typePoint.find((x) => x.value === '') as SelectOption
  )

  const handleSearch = (status: string, pointType: string) => {
    pipe(
      IBService.postRewardPointHistory({
        pageIndex: page,
        itemsPerPage: pageSize,
        orders: ['pointTransferCreatedAt'],
        sort: 'desc',
        pointType: pointType,
        status: status,
        clientNo: clientNumber as string
      }),
      ZIO.map((res) => {
        setData(res.records)
        setTaskCount(res.paging.total)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    handleSearch(statusFilter?.value ?? '', typeFilter?.value ?? '')
  }, [statusFilter, typeFilter, page, pageSize])

  const dataTable = [
    { label: t('Issuer'), field: 'issuer' },
    {
      label: (
        <View style={{ marginTop: 3 }}>
          <Text style={{ marginRight: 5, fontWeight: 'bold', color: '#70777E', fontSize: 15, width: 200 }}>
            {t('StatusPruRewards')}
          </Text>
          <SelectSearch
            inputStyle={styles.selectStyle}
            disableUnderline={true}
            value={statusFilter}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            placeholder={t('common:Select')}
            onChange={(val) => {
              if (val) {
                setStatusFilter(val)
              }
            }}
            options={statusPoint}
          />
        </View>
      ),
      field: 'status',
      disabled: true,
      render: (value: string) => {
        return (
          <View style={{ width: 200 }}>
            <Text>{value}</Text>
          </View>
        )
      }
    },
    {
      label: (
        <View style={{ marginTop: 3 }}>
          <Text style={{ marginRight: 5, fontWeight: 'bold', color: '#70777E', fontSize: 15, width: 300 }}>
            {t('PointType')}
          </Text>
          <SelectSearch
            inputStyle={styles.selectStyle}
            disableUnderline={true}
            value={typeFilter}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            placeholder={t('common:Select')}
            onChange={(val) => {
              if (val) {
                setTypeFilter(val)
              }
            }}
            options={typePoint}
          />
        </View>
      ),
      field: 'pointType',
      disabled: true,
      render: (value: string) => {
        return (
          <View style={{ width: 200 }}>
            <Text>{value}</Text>
          </View>
        )
      }
    },
    { label: t('PointAmount'), field: 'pointValue', format: 'money' },
    { label: t('Note'), field: 'pointComment' },
    { label: t('SystemNote'), field: 'pointRemark' },
    { label: t('CreatedDate'), field: 'pointTransferCreatedAt', format: 'dateTime' },
    { label: t('ExpirationDate'), field: 'pointTransferExpiredAt', format: 'dateTime' }
  ]

  return (
    <IBGeneralTable
      dataTable={dataTable}
      data={
        data?.map((item) => ({
          ...item,
          pointTransferCreatedAt: moment(item.pointTransferCreatedAt, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss'),
          pointTransferExpiredAt: moment(item.pointTransferExpiredAt, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss')
        })) || []
      }
      paging={{
        page: page,
        pageSize: pageSize,
        taskCount: taskCount,
        setPage: setPage,
        setPageSize: setPageSize
      }}
      loading={loading}
    />
  )
}

const IBTierHistory = (props: {
  data?: PrurewardTierHistoryData
  setData: (data: PrurewardTierHistoryData) => void
  clientNumber?: string
}) => {
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading()
  const { data, setData, clientNumber } = props

  React.useEffect(() => {
    if (isNil(data)) {
      pipe(
        IBService.getTierHistory(clientNumber as string),
        ZIO.map((res) => {
          setData(res.data)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  const dataTable = [
    { label: t('CustomerName'), field: 'customerName' },
    { label: t('Point'), field: 'point', format: 'money' },
    { label: t('OldAPE'), field: 'oldAPE', format: 'money' },
    { label: t('NewAPE'), field: 'newAPE', format: 'money' },
    { label: t('OldVIPLevel'), field: 'oldVipLevel' },
    { label: t('NewVIPLevel'), field: 'newVipLevel' },
    { label: t('CreatedDate'), field: 'createdDate', format: 'dateTime' }
  ]

  return (
    <IBGeneralTable
      dataTable={dataTable}
      data={
        orderBy(
          data?.tierHistory.map((item) => ({
            customerName: data.clientName.name,
            point: item.activePoint,
            oldAPE: item.oldAPE,
            newAPE: item.newAPE,
            oldVipLevel: item.oldTier,
            newVipLevel: item.newTier,
            createdDate: moment(item.createdDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss')
          })),
          'createdDate',
          'desc'
        ) || []
      }
      autoPaging={true}
      loading={loading}
    />
  )
}

const IBCodeRedeemHistory = (props: {
  data?: PrurewardRedeemCodeHistoryData[]
  setData: (data: PrurewardRedeemCodeHistoryData[]) => void
  clientNumber?: string
}) => {
  const { t, i18n } = useTranslation('Inbound')
  const statusRedeem = StatusRedeemList.map((item) => ({
    value: item.code,
    label: i18n.language === 'vi' ? item.nameVn : item.nameEn
  }))

  const [loading, bindLoading] = useLoading()
  const { data, setData, clientNumber } = props
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)
  const [statusFilter, setStatusFilter] = React.useState<SelectOption>(
    statusRedeem.find((x) => x.value === '') as SelectOption
  )

  const handleSearch = (status: string) => {
    pipe(
      IBService.postRedeemCodeHistory({
        pageIndex: page,
        itemsPerPage: pageSize,
        orders: ['redeemCodeDate'],
        sort: 'desc',
        codeUsingStatus: status,
        clientNo: clientNumber as string
      }),
      ZIO.map((res) => {
        setData(res.records)
        setTaskCount(res.paging.total)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    handleSearch(statusFilter?.value ?? '')
  }, [statusFilter, page, pageSize])

  const dataTable = [
    { label: t('CodeNumber'), field: 'code' },
    { label: t('Provider'), field: 'providerName' },
    { label: t('Deal'), field: 'dealName' },
    {
      label: (
        <View style={{ marginTop: 3 }}>
          <Text style={{ marginRight: 5, fontWeight: 'bold', color: '#70777E', fontSize: 15, width: 200 }}>
            {t('UsageStatus')}
          </Text>
          <SelectSearch
            inputStyle={styles.selectStyle}
            disableUnderline={true}
            value={statusFilter}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            placeholder={t('common:Select')}
            onChange={(val) => {
              if (val) {
                setStatusFilter(val)
              }
            }}
            options={statusRedeem}
          />
        </View>
      ),
      field: 'codeUsingStatus',
      disabled: true,
      render: (value: string) => {
        const obj = StatusRedeemList.find((x) => x.code === value)
        return (
          <View style={{ width: 200 }}>
            <Text>{i18n.language === 'vi' ? obj?.nameVn : obj?.nameEn}</Text>
          </View>
        )
      }
    },
    { label: t('CodeRedeemDate'), field: 'redeemCodeDate', format: 'dateTime' },
    { label: t('CodeExpiredDate'), field: 'expiredDate', format: 'dateTime' },
    { label: t('Channel'), field: 'channel' }
  ]

  return (
    <IBGeneralTable
      dataTable={dataTable}
      data={
        data?.map((item) => ({
          ...item,
          redeemCodeDate: moment(item.redeemCodeDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss'),
          expiredDate: moment(item.expiredDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss')
        })) || []
      }
      paging={{
        page: page,
        pageSize: pageSize,
        taskCount: taskCount,
        setPage: setPage,
        setPageSize: setPageSize
      }}
      loading={loading}
    />
  )
}

const IBGiftRedeemAndHeathCheck = (props: {
  data?: PrurewardGiftRedemptionHistoryData[]
  setData: (data: PrurewardGiftRedemptionHistoryData[]) => void
  clientNumber?: string
}) => {
  const { t, i18n } = useTranslation('Inbound')
  const statusGift = StatusGiftList.map((item) => ({
    value: item.code,
    label: i18n.language === 'vi' ? item.nameVn : item.nameEn
  }))

  const [loading, bindLoading] = useLoading()
  const { data, setData, clientNumber } = props
  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [taskCount, setTaskCount] = React.useState<number>(0)
  const [statusFilter, setStatusFilter] = React.useState<SelectOption>(
    statusGift.find((x) => x.value === '') as SelectOption
  )

  const handleSearch = (status: string) => {
    pipe(
      IBService.postGiftRedemptionHistory({
        pageIndex: page,
        itemsPerPage: pageSize,
        orders: ['promotionCreateDate'],
        sort: 'desc',
        deliveryStatus: status,
        clientNo: clientNumber as string
      }),
      ZIO.map((res) => {
        setData(res.records.filter((x) => !isNil(x.deliveryStatus)))
        setTaskCount(res.paging.total)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    handleSearch(statusFilter?.value ?? '')
  }, [statusFilter, page, pageSize])

  const dataTable = [
    { label: t('TransactionIDPruRewards'), field: 'transactionId' },
    { label: t('PromotionID'), field: 'promotionId' },
    { label: t('DealName'), field: 'promotionName' },
    { label: t('Provider'), field: 'providerName' },
    { label: t('RedeemDate'), field: 'promotionCreateDate', format: 'dateTime' },
    { label: t('DeliveryDate/ReturnedDate'), field: 'promotionReceivedDate', format: 'dateTime' },
    {
      label: (
        <View style={{ marginTop: 3 }}>
          <Text style={{ marginRight: 5, fontWeight: 'bold', color: '#70777E', fontSize: 15, width: 200 }}>
            {t('GiftStatus')}
          </Text>
          <SelectSearch
            inputStyle={styles.selectStyle}
            disableUnderline={true}
            value={statusFilter}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            placeholder={t('common:Select')}
            onChange={(val) => {
              if (val) {
                setStatusFilter(val)
              }
            }}
            options={statusGift}
          />
        </View>
      ),
      field: 'deliveryStatus',
      disabled: true,
      render: (value: string) => {
        const textValue = statusGift.find((x) => x.value === value)
        return (
          <View style={{ width: 200 }}>
            <Text>{textValue?.label}</Text>
          </View>
        )
      }
    },
    { label: t('AgentDeliveyDate'), field: 'deliveryDate', format: 'dateTime' },
    { label: t('Note'), field: 'deliveryNote' }
  ]

  return (
    <IBGeneralTable
      dataTable={dataTable}
      data={
        data?.map((item) => ({
          ...item,
          promotionCreateDate: moment(item.promotionCreateDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss'),
          promotionReceivedDate: moment(item.promotionReceivedDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss'),
          deliveryDate: moment(item.deliveryDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss')
        })) || []
      }
      paging={{
        page: page,
        pageSize: pageSize,
        taskCount: taskCount,
        setPage: setPage,
        setPageSize: setPageSize
      }}
      loading={loading}
    />
  )
}

const IBNote = (props: {
  data?: PrurewardCustomerNoteData[]
  setData: (data: PrurewardCustomerNoteData[]) => void
  clientNumber?: string
}) => {
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading()
  const { data, setData, clientNumber } = props

  React.useEffect(() => {
    if (isNil(data) || isEmpty(data)) {
      pipe(
        IBService.getPrurewardCustomerNote(clientNumber as string),
        ZIO.map((res) => {
          setData(res.data)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [])

  const dataTable = [
    { label: t('NoteCode'), field: 'noteId' },
    { label: t('CreatedBy'), field: 'noteCreatedBy' },
    { label: t('CreatedDateNote'), field: 'noteCreatedDate', format: 'dateTime' },
    { label: t('Content'), field: 'noteContent' }
  ]

  return (
    <IBGeneralTable
      dataTable={dataTable}
      data={
        data?.map((item) => ({
          ...item,
          noteCreatedDate: moment(item.noteCreatedDate, 'DD/MM/YYYY HHmmss').format('YYYYMMDDHHmmss')
        })) || []
      }
      autoPaging={true}
      loading={loading}
    />
  )
}

interface Props {
  setCanChangeTab: (canChangeTab: boolean) => void
  data?: {
    customerInfo: PrurewardCustomerInfoData
    agentInfo: PrurewardAgentData
  }
  setData: (data: { customerInfo: PrurewardCustomerInfoData; agentInfo: PrurewardAgentData }) => void
  clientNumber?: string
}
export const IBClientPolicyPrurewards = (props: Props) => {
  const { t, i18n } = useTranslation('Inbound')
  const { data, setData, clientNumber } = props
  const [loading, bindLoading] = useLoading()
  const [pointHistoryData, setPointHistoryData] = React.useState<PrurewardPointHistoryData[]>()
  const [tierHistoryData, setTierHistoryData] = React.useState<PrurewardTierHistoryData>()
  const [redeemHistoryData, setRedeemHistoryData] = React.useState<PrurewardRedeemCodeHistoryData[]>()
  const [giftListHistoryData, setGiftListHistoryData] = React.useState<PrurewardGiftRedemptionHistoryData[]>()
  const [customerNoteData, setCustomerNoteData] = React.useState<PrurewardCustomerNoteData[]>()
  React.useEffect(() => {
    if (isNil(data)) {
      pipe(
        ZIO.zipPar(
          IBService.getPrurewardCustomerInfo(clientNumber as string),
          IBService.getPrurewardAgent(clientNumber as string)
        ),
        ZIO.map(([customerInfo, agentInfo]) => {
          setData({ customerInfo: customerInfo.data, agentInfo: agentInfo.data })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(data), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataGridView = [
    {
      label: t('VIPTier'),
      value: data?.customerInfo.vip || '-'
    },
    {
      label: t('CurrentPoint'),
      value: data?.customerInfo.point?.toString() || '-'
    },
    {
      label: t('ExpiredPointInUpcomingMonth'),
      value: data?.customerInfo.expiredPointInNextMonth?.toString() || '-'
    },
    {
      label: t('VIPDate'),
      value: data?.customerInfo.enrolledDate || '-'
    },
    {
      label: t('ExpiredPoint'),
      value: data?.customerInfo.expiredPoint?.toString() || '-'
    },
    {
      label: t('ExpiredPointInNextThreeMonth'),
      value: data?.customerInfo.expiredPointIn03Month?.toString() || '-'
    },
    {
      label: t('TotalAPE'),
      value: PulseOpsFormat.thousandSepartor(data?.customerInfo.ape) || '-'
    },
    {
      label: t('UsedPoint'),
      value: data?.customerInfo.usedPoint?.toString() || '-'
    },
    {
      label: t('ExpiredPointInNextSixMonth'),
      value: data?.customerInfo.expiredPointIn06Month?.toString() || '-'
    }
  ]

  const dataGridViewAgent = [
    {
      label: t('ServicingAgentCode'),
      value: data?.agentInfo.agentCode || '-'
    },
    {
      label: t('ServicingAgentName'),
      value: data?.agentInfo.agentName?.name || '-'
    },
    {
      label: t('ServicingAgentPhone'),
      value: data?.agentInfo.agentPhone || '-'
    },
    {
      label: t('ServicingAgentStatus'),
      value: data?.agentInfo.agentStatus || '-'
    }
  ]
  return (
    <>
      {loading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.container}>
            <Text style={styles.titleStyle}>{t('CustomerInfo').toUpperCase()}</Text>
            <IBGridDataView col={3} data={dataGridView} />
            <Text style={[styles.titleStyle, { marginTop: 20 }]}>{t('GentInformationOnPrurewards').toUpperCase()}</Text>
            <IBGridDataView col={4} data={dataGridViewAgent} />
            <IBPanel
              title={t('PointHistory')}
              children={
                <IBPointHistory data={pointHistoryData} setData={setPointHistoryData} clientNumber={clientNumber} />
              }
            />
            <IBPanel
              title={t('TierHistory')}
              children={
                <IBTierHistory setData={setTierHistoryData} data={tierHistoryData} clientNumber={clientNumber} />
              }
              styleContainer={{ marginTop: 0 }}
            />
            <IBPanel
              title={t('CodeRedeemHistory')}
              children={
                <IBCodeRedeemHistory
                  setData={setRedeemHistoryData}
                  data={redeemHistoryData}
                  clientNumber={clientNumber}
                />
              }
              styleContainer={{ marginTop: 0 }}
            />
            <IBPanel
              title={t('GiftRedeemHistory/HeathCheckInvitationLetter')}
              children={
                <IBGiftRedeemAndHeathCheck
                  setData={setGiftListHistoryData}
                  data={giftListHistoryData}
                  clientNumber={clientNumber}
                />
              }
              styleContainer={{ marginTop: 0 }}
            />
            <IBPanel
              title={t('Note')}
              children={<IBNote setData={setCustomerNoteData} data={customerNoteData} clientNumber={clientNumber} />}
              styleContainer={{ marginTop: 0 }}
            />
          </View>
        </ScrollView>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
    marginTop: 10
  },
  titleStyle: {
    fontSize: 15,
    color: '#1C1D1F',
    fontWeight: '700',
    marginBottom: 10
  },
  selectStyle: {
    borderRadius: 6,
    borderColor: '#D3DCE6',
    borderWidth: 1,
    backgroundColor: '#fff',
    marginTop: 10,
    paddingHorizontal: 8,
    paddingVertical: 4
  }
})
