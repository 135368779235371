import { Throwable, ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Container,
  ContractMapping,
  ErrorHandling,
  GeneralInfoDigitalProductRes,
  Panel,
  ProductInfo,
  Table,
  TableField,
  TaskDetailService,
  useTranslator
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'

export interface ProductInfoDigitalData {
  productInfo: GeneralInfoDigitalProductRes
  mainLAD: TableField.DataSources
}

export const ProductDigitalScreen = (props: {
  id: string
  data: ProductInfoDigitalData | null
  isEditable?: boolean
  setData: (val: ProductInfoDigitalData | null) => void
}) => {
  const { isEditable = true } = props

  const [loading, bindLoading] = useLoading()

  // const [mainLAD, setMainLA] = useState<TableField.DataSources>([])
  const { t } = useTranslator('TaskManagement')
  const ct = useTranslator('contract').t
  const cmt = useTranslator('common').t

  const getData = (): ZIO<unknown, Throwable, ProductInfoDigitalData> =>
    pipe(
      TaskDetailService.getDigitalProduct(props.id),
      ZIO.map((res) => {
        return {
          productInfo: res,
          mainLAD: res.owner
            ? res.owner.extInfos.map((e, i) => ({
                number: ++i,
                componentName: e.componentName,
                status: ContractMapping.get(ct)(e.status),
                sum: e.sumAssured,
                instalmentPremium: e.installmentPremium,
                paidToDate: e.paidToDate,
                premiumCessationDate: e.premiumCessationDate
              }))
            : []
        }
      })
    )

  const { productInfo, mainLAD } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    productInfo: null,
    mainLAD: []
  }

  const columns = [
    {
      key: '1',
      title: t('ComponentName'),
      name: 'componentName'
    },
    {
      key: '2',
      title: cmt('Status'),
      name: 'status'
    },
    {
      key: '3',
      title: `${t('SumAssured')} (${t('basic')})`,
      name: 'sum'
    },
    {
      key: '4',
      title: `${t('InstalmentPremium')} (${t('basic')})`,
      name: 'instalmentPremium'
    },
    {
      key: '5',
      title: `${t('PaidToDate')} (${t('basic')})`,
      name: 'paidToDate'
    },
    {
      key: '6',
      title: `${t('PremCessDate')} (${t('basic')})`,
      name: 'premiumCessationDate'
    }
  ]

  const lifeAssuredColumns = [
    {
      key: '1',
      title: t('ComponentName'),
      name: 'componentName'
    },
    {
      key: '2',
      title: t('CoverageStatus'),
      name: 'status'
    },
    {
      key: '3',
      title: `${t('SumAssured')} (${t('rider')})`,
      name: 'sum'
    },
    {
      key: '4',
      title: `${t('InstalmentPremium')} (${t('rider')})`,
      name: 'instalmentPremium'
    },
    {
      key: '5',
      title: `${t('PaidToDate')} (${t('rider')})`,
      name: 'paidToDate'
    },
    {
      key: '6',
      title: `${t('PremCessDate')} (${t('rider')})`,
      name: 'premiumCessationDate'
    }
  ]

  const getLaTableFields = (la: ProductInfo) => {
    return la.extInfos.map((l, i) => ({
      number: (++i).toString(),
      componentName: l.componentName,
      status: ContractMapping.get(ct)(l.status),
      sum: l.sumAssured,
      instalmentPremium: l.installmentPremium,
      paidToDate: l.paidToDate,
      premiumCessationDate: l.premiumCessationDate
    }))
  }

  return (
    <Container loading={loading}>
      <Panel title={productInfo?.owner?.name ?? ''} allowToggle={false} titleOutline={t('MainLifeAssured')}>
        <Table columns={columns} dataSource={mainLAD} />
      </Panel>
      {productInfo?.laClient.slice(0, 1).map((item, i) => (
        <Panel
          title={item?.name || '-'}
          titleOutline={cmt('LifeAssured')}
          key={`ProductScreenMAIN-${i}`}
          allowToggle={isEditable}
        >
          <Table columns={lifeAssuredColumns} dataSource={getLaTableFields(item)} />
        </Panel>
      ))}
    </Container>
  )
}
