import { View, Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

export const SC = {
  Container: styled(View)`
    width: 100%;
    align-self: center;
  `,
  CenterSelf: styled(View)`
    align-self: center;
  `,
  FlexStartSelf: styled(View)`
    align-self: flex-start;
  `,
  FlexEndSelf: styled(View)`
    align-self: flex-end;
  `,
  PanelContainer: styled(View)`
    border: 1px solid #d3dce6;
    border-radius: 8px;
    background-color: #fafafa;
    padding: 16px;
    :hover {
      background-color: red;
    }
  `,
  Row: styled(View).attrs<{ alignItems?: string }>((p) => ({
    style: {
      alignItems: p.alignItems
    }
  }))<{ alignItems?: string }>`
    flex-direction: row;
    //justify-content: space-beetween;
    //flex-wrap: wrap;
  `,
  Input: styled(View).attrs<{ flex?: number }>((p) => ({
    style: {
      flex: p.flex
    }
  }))``,
  SessionText: styled(Text)<{ color?: string }>`
    font-weight: bold;
    color: ${(p) => p.color ?? '#000'};
  `,
  TitleText: styled(Text)<{ fontSize?: number }>`
    font-weight: bold;
    color: #70777e;
    font-size: ${(p) => (p.fontSize ?? 15) + 'px'};
  `,
  NormalText: styled(Text)<{ color?: string }>`
    color: ${(p) => p.color};
  `,
  Divider: styled(View)<{ height?: number; width?: number; spacer?: boolean }>`
    width: ${(p) => (p.width ?? 10) + 'px'};
    height: ${(p) => (p.height ?? 10) + 'px'};
  `,
  Colum: styled(View)<{ flex?: number }>`
    flex: ${(p) => p.flex || 1};
  `,
  ErrorText: styled(Text)<{ fontSize?: number }>`
    color: red;
    font-size: ${(p) => p.fontSize && p.fontSize + 'px'};
  `,
  InfoText: styled(Text)<{ color?: string }>`
    color: ${(props) => props.color || 'red'};
    padding: 20px 0px 20px 0px;
  `,
  InputContainer: styled(View)<{ flex?: number; maxWidth?: number; minWidth?: number }>`
    flex: ${(p) => p.flex};
    max-width: ${(p) => (p.maxWidth || 250) + 'px'};
    min-width: ${(p) => (p.minWidth ? p.minWidth + 'px' : null)};
  `,
  Padding: styled(View)<{
    top?: number
    bottom?: number
    left?: number
    right?: number
    vertical?: number
    horizontal?: number
  }>`
    padding-top: ${(p) => p.top || p.vertical || 0};
    padding-bottom: ${(p) => p.bottom || p.vertical || 0};
    padding-right: ${(p) => p.right || p.horizontal || 0};
    padding-left: ${(p) => p.left || p.horizontal || 0};
  `,
  BoldText: styled(Text)<{ color?: string }>`
    font-weight: bold;
    color: ${(p) => p.color || 'grey'};
    font-size: 15px;
  `,
  ImageThumb: styled.Image`
    width: 50px;
    height: 50px;
  `,
  ItalicText: styled.Text`
    font-style: italic;
    color: grey;
    font-size: small;
  `,
  DashBottomContainer: styled(View)`
    border-style: dashed;
    border-bottom-width: 1px;
    padding: 5px 0px 5px 0px;
  `,
  ContainerPercent: styled(View).attrs<{ width?: number; height?: number; flex?: number }>((props) => ({
    style: {
      width: props.width && props.width + '%'
    }
  }))<{ width?: number; height?: number; flex?: number }>`
    width: ${(p) => (p.width ? p.width + '%' : null)};
    height: ${(p) => (p.height ? p.height + '%' : null)};
    flex: ${(p) => p.flex};
    justify-content: flex-start;
  `,
  ButtonContainer: styled(TouchableOpacity)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  RowWrap: styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
  `,
  RowNowrap: styled(View)`
    flex-direction: row;
    justify-content: space-beetween;
    width: 100%;
  `,
  Spacer: styled(View)`
    flex: 1;
  `,
  ThinText: styled(Text)`
    font-weight: 300;
  `,
  ExpandView: styled(View)`
    flex: 1;
  `
}
