import {
  Title,
  MajorCombined,
  AppContext,
  ReinstatementModel,
  RiderReinstatementModel,
  TransactionType,
  SubmissionService,
  TaskType,
  CancelRider,
  FileUploadData,
  Input,
  RiderAlterationData,
  removeAbundantSpacesInText,
  RiderAlterationFormData
} from '@pulseops/common'
import { Checkbox } from '@pulseops/submission/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { MajorCombinedForm } from './major-combined-form'
import { Controller, useForm } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'
import {
  BillingChangeComponent,
  CancelRiderComponent,
  ChangeContractComponent,
  ProductOptionSwitchingComponent,
  ReinstatementComponent,
  RiderAlterationComponent,
  RiderReinstatementComponent
} from './transaction-component'
import { useIsFocused, useNavigation } from '@react-navigation/native'

interface OptionMajors {
  changeContract: boolean
  billingChange: boolean
  productOptionSwitching: boolean
  riderAlteration: boolean
  riderCancellation: boolean
  reinstatement: boolean
  riderReinstatement: boolean
}

export const MajorCombinedScreen: React.FC<PolicyServiceProps<MajorCombined.SubmitMajorCombined>> = ({
  policyNumber,
  isConfirmed,
  initSubmission,
  officeCode
}) => {
  const { t } = useTranslation()
  const policyNum = policyNumber ?? ''
  const [isLoading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [majorType, setMajorType] = React.useState<string>('')
  const { navigate } = useNavigation()

  const majorCombinedForm = useForm<MajorCombinedForm.MajorCombined>({
    defaultValues: {
      major: '',
      requirePayinAmount: ''
    }
  })

  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = majorCombinedForm

  const [optionMajors, setOptionMajors] = React.useState<OptionMajors>({
    changeContract: false,
    billingChange: false,
    productOptionSwitching: false,
    riderAlteration: false,
    riderCancellation: false,
    reinstatement: false,
    riderReinstatement: false
  })

  const MajorTypes = {
    MJ_IN_FREE_LOOK: 'MAJOR_CHANGE_IN_FREE_LOOK',
    MJ_AFTER_FREE_LOOK: 'MAJOR_CHANGE_AFTER_FREE_LOOK'
  }

  React.useEffect(() => {
    if (isFocused) {
      //NOTE: Reset data when re focus to this screen
      majorCombinedForm.reset()
      setOptionMajors({
        changeContract: false,
        billingChange: false,
        productOptionSwitching: false,
        riderAlteration: false,
        riderCancellation: false,
        reinstatement: false,
        riderReinstatement: false
      })
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionScreen' })
        },
        { title: t('TransactionType:MAJOR_COMBINED_CHANGE'), navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.map((policyData) => {
        const policyItem = policyData.body
        const requestDate = moment()
        // const customerRecdDate = moment(policyItem.proposal?.proposalReceivedDate)
        const policyLastIssueDate = moment(policyItem?.lastIssueDate)
        const customerRecdDate =
          policyItem.proposal?.proposalReceivedDate && moment(policyItem.proposal?.proposalReceivedDate, true).isValid()
            ? moment(policyItem.proposal?.proposalReceivedDate)
            : policyLastIssueDate
        const distanceDate = requestDate.diff(customerRecdDate, 'days')
        const checkInFreeLook = distanceDate <= 21
        const checkAfterFreeLook = distanceDate > 21
        if (checkAfterFreeLook) {
          setMajorType(MajorTypes.MJ_AFTER_FREE_LOOK)
        } else if (checkInFreeLook) {
          setMajorType(MajorTypes.MJ_IN_FREE_LOOK)
        }
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [])

  let checkBillingChange = async () => {
    return true
  }

  let checkChangeContract = async () => {
    return true
  }

  let checkProductOption = async () => {
    return true
  }

  let checkCancelRider = async () => {
    return true
  }

  let checkReinstatement = async () => {
    return true
  }

  let checkRiderReinstatement = async () => {
    return true
  }

  let checkRiderAlteration = async () => {
    return true
  }

  const validateBeforeContinue = ({
    validateBillingChange,
    validateChangeContract,
    validateProductOption,
    validateCancelRider,
    validateReinstatement,
    validateRiderReinstatement,
    validateRiderAlteration
  }: {
    validateBillingChange?: () => Promise<boolean>
    validateChangeContract?: () => Promise<boolean>
    validateProductOption?: () => Promise<boolean>
    validateCancelRider?: () => Promise<boolean>
    validateReinstatement?: () => Promise<boolean>
    validateRiderReinstatement?: () => Promise<boolean>
    validateRiderAlteration?: () => Promise<boolean>
  }) => {
    checkBillingChange = validateBillingChange ?? checkBillingChange
    checkChangeContract = validateChangeContract ?? checkChangeContract
    checkProductOption = validateProductOption ?? checkProductOption
    checkCancelRider = validateCancelRider ?? checkCancelRider
    checkReinstatement = validateReinstatement ?? checkProductOption
    checkRiderReinstatement = validateRiderReinstatement ?? checkRiderReinstatement
    checkRiderAlteration = validateRiderAlteration ?? checkRiderAlteration
  }

  const lifeAssuredRiderReins = () => {
    const lifeAssured: RiderReinstatementModel.LifeAssured[] = watch('riderReinstatement.lifeAssuredList').map((r) => {
      let lA: RiderReinstatementModel.LifeAssured = {
        clientNo: r.clientNum ?? '',
        healthStateChange: r.healthAnswer,
        occupationChange: r.occupationAnswer,
        curOccupation: r.curOccupation?.value || '',
        newOccupation: (r.occupationAnswer && r.newOccupation?.value) || '',
        activityChange: r.activitiesAnswer,
        newActivity: (r.activitiesAnswer && r.newActivity) || '',
        companyChange: r.companyAnswer,
        newCompany: (r.companyAnswer && r.newCompany) || '',
        agentCode: r.agentCode,
        riderList: r.riderList
      }
      return lA
    })
    return lifeAssured
  }

  const lifeAssuredReins = () => {
    const lifeAssured: ReinstatementModel.LifeAssured[] =
      majorCombinedForm.getValues('reinstatement')?.reinClients?.map((r) => {
        let attributesExt = {
          IS_CHANGE_HEALTH_STATE: false,
          IS_OCCUPATION_CHANGE: false,
          IS_ACTIVITY_CHANGE: false,
          IS_EXIST_REQUEST_COMPANY: false,
          CUR_OCCUPATION: '',
          NEW_OCCUPATION: '',
          NEW_ACTIVITY: '',
          COMPANY_NAME: ''
        }

        if (r.healthAnswer) {
          attributesExt = { ...attributesExt, IS_CHANGE_HEALTH_STATE: true }
        }

        if (r.occupationAnswer) {
          attributesExt = {
            ...attributesExt,
            IS_OCCUPATION_CHANGE: true,
            CUR_OCCUPATION: r.curOccupation?.value || '',
            NEW_OCCUPATION: r.newOccupation?.value || ''
          }
        }

        if (r.activitiesAnswer) {
          attributesExt = {
            ...attributesExt,
            IS_ACTIVITY_CHANGE: true,
            NEW_ACTIVITY: r.newActivity || ''
          }
        }

        if (r.companyAnswer) {
          attributesExt = {
            ...attributesExt,
            IS_EXIST_REQUEST_COMPANY: true,
            COMPANY_NAME: r.newCompany || ''
          }
        }

        let lA: ReinstatementModel.LifeAssured = {
          name: r.name || '',
          clientId: r.clientNum || '',
          attributesExt
        }

        return lA
      }) || []
    return lifeAssured
  }

  const getRiderAlterData = (riderForm: RiderAlterationFormData.RiderAlteration) => {
    const lifeAssuredList: Array<RiderAlterationData.LifeAssuredList> = !riderForm.isRiderAlteration
      ? []
      : riderForm.LARiderAlterationList.map((LAItem) => {
        const riderList = LAItem.currentRiders.map((riderItem) => {
          const riskCommDate = convertDateStringForSubmit(riderItem.riskCommDate)
          const riskCessDate = convertDateStringForSubmit(riderItem.riskCessDate)
          const newInstallmentPremium = riderForm.totalPremium - riderItem.installmentPrem
          const alteredSumAssured = Number(riderItem.alteredSumAssured)
          return {
            coverageCode: riderItem.riderCode,
            riskCommDate: riskCommDate,
            riskCessDate: riskCessDate,
            sumAssured: riderItem.sumAssured,
            installmentPremium: riderItem.installmentPrem,
            newInstallmentPremium: newInstallmentPremium,
            alteredSumAssured: alteredSumAssured,
            lifeNo: riderItem.lifeNo,
            coverageNo: riderItem.coverageNo,
            riderNo: riderItem.riderNo,
            riderStatus: riderItem.riderStatus
          }
        })
        return {
          clientNo: LAItem.lifeAssured,
          riderList: riderList
        }
      })
    const lifeAssuredAdditions = !riderForm.isAddRider
      ? []
      : riderForm.newRiderLAList.map((newItem) => {
        const isUSTax = newItem.newLAInfo?.USTaxDeclaration === 'Y' ? true : false
        const dateOfBirth = newItem.isNewLA ? moment(newItem.newLAInfo?.dateOfBirth).format('YYYYMMDD') : ''
        const issueDate = newItem.isNewLA ? moment(newItem.newLAInfo?.issuedDate).format('YYYYMMDD') : ''
        const issuePlace = !!newItem.newLAInfo?.issuePlace ? newItem.newLAInfo?.issuePlace.value : ''
        const averageIncome = !!newItem.newLAInfo?.averageIncome ? newItem.newLAInfo?.averageIncome.value : ''
        const fullName = newItem.isNewLA
          ? removeAbundantSpacesInText(newItem.newLAInfo?.surname ?? '') +
          ' ' +
          removeAbundantSpacesInText(newItem.newLAInfo?.firstName ?? '')
          : newItem.LAName
        let newLifeAssured: RiderAlterationData.NewLifeAssured = {
          lastName: newItem.newLAInfo?.surname ?? '',
          firstName: newItem.newLAInfo?.firstName ?? '',
          fullName: fullName,
          isNewLA: newItem.isNewLA,
          documentType: newItem.newLAInfo?.documentType.value ?? '',
          idNumber: newItem.newLAInfo?.IDNumber ?? '',
          issuePlace: issuePlace,
          issueDate: issueDate,
          isUSTax: isUSTax,
          dateOfBirth: dateOfBirth,
          gender: newItem.newLAInfo?.gender.value ?? '',
          nationality: newItem.newLAInfo?.nationality.value ?? '',
          mobileCode: newItem.newLAInfo?.mobilePhoneCode.value ?? '',
          mobilePhone: newItem.newLAInfo?.mobilePhone ?? '',
          email: newItem.newLAInfo?.email ?? '',
          occupation: newItem.newLAInfo?.occupation.value ?? '',
          jobDescription: newItem.newLAInfo?.jobDescription ?? '',
          jobTitle: newItem.newLAInfo?.jobTitle ?? '',
          companyName: newItem.newLAInfo?.companyName ?? '',
          companyAddress: newItem.newLAInfo?.companyAddress ?? '',
          averageIncome: averageIncome,
          lifeNo: newItem.lifeNo ?? '',
          clientNumber: newItem.lifeAssured,
          riderListAdditional: newItem.newRiderList.map((subRider) => ({
            coverageCode: subRider.riderName.value,
            sumAssured: Number(subRider.sumAssured),
            policyPeriod: Number(subRider.policyPeriod)
          })),
          duplicatePhones:
            !!newItem.newLAInfo && !!newItem.newLAInfo?.dupPhone
              ? newItem.newLAInfo?.dupPhone.map((p) => ({
                clientName: p.clientName ?? '',
                clientID: p.clientId ?? '',
                relationshipType: p.relationship?.value ?? ''
              }))
              : [],
          duplicateEmails:
            !!newItem.newLAInfo && !!newItem.newLAInfo?.dupEmail
              ? newItem.newLAInfo?.dupEmail.map((e) => ({
                clientName: e.clientName ?? '',
                clientID: e.clientId ?? '',
                relationshipType: e.relationship?.value ?? ''
              }))
              : []
        }
        return {
          lifeAssured: newLifeAssured
        }
      })
    const submitedData: MajorCombined.RiderAlteration = {
      isRiderAlteration: riderForm.isRiderAlteration ?? false,
      isAddLA: riderForm.isAddRider ?? false,
      lifeAssuredList: lifeAssuredList,
      lifeAssuredAdditions: lifeAssuredAdditions
    }
    console.log('submitedData', JSON.stringify(submitedData))
    return submitedData
  }

  const convertDateStringForSubmit = (strDate: string) => {
    return moment(strDate).format('YYYY-MM-DD')
  }

  const cancelRider = (): CancelRider.SubmitLARider[] => {
    const cancelRiderFormArray = majorCombinedForm.getValues('cancelRider.LARiderForm')
    const totalPremium = majorCombinedForm.getValues('cancelRider.totalPremium')
    let mappingCancelRiderData: CancelRider.SubmitLARider[] = cancelRiderFormArray.map((fieldItem) => {
      const riderListForEachLA: CancelRider.SubmitedRiderInfo[] = fieldItem.cancelRiderForm
        .filter((x) => x.SelectRowForRider)
        .map((subItem) => {
          const riskCommDate = convertDateStringForSubmit(subItem.riskCommDate)
          const riskCessDate = convertDateStringForSubmit(subItem.riskCessDate)
          const newInstallmentPremium = totalPremium ?? 0 - subItem.installmentPrem
          return {
            productCode: subItem.riderCode,
            riskCommDate: riskCommDate,
            riskCessDate: riskCessDate,
            sumAssured: subItem.sumAssured,
            installmentPremium: subItem.installmentPrem,
            newInstallmentPremium: newInstallmentPremium,
            lifeNo: subItem.lifeNo,
            coverageNo: subItem.coverageNo,
            riderNo: subItem.riderNo,
            status: subItem.riderStatus
          }
        })
      return {
        lifeAssured: fieldItem.lifeAssured,
        riders: riderListForEachLA
      }
    })
    return mappingCancelRiderData.filter((x) => !!x && x.riders.length > 0) || []
  }

  const getSubmitedData = (): MajorCombined.SubmitMajorCombined => {
    const majorData = majorCombinedForm.getValues()
    const billingChangeData: MajorCombined.BillingChange | undefined = optionMajors.billingChange
      ? {
        curBillingFrequency: majorData.billingChange?.curBillingFrequency || '',
        curInstallmentPremium: majorData.billingChange?.curInstallmentPremium || '',
        newBillingFrequency: majorData.billingChange?.newBillingFrequency?.value || '',
        newInstallmentPremium: majorData.billingChange?.newInstallmentPremium || '',
        effectiveDateNewFrequency: majorData.billingChange?.effectiveDateNewFrequency || '',
        requirePayinAmount: majorData.billingChange?.requirePayinAmount || ''
      }
      : undefined

    const changeContractData: MajorCombined.ChangeContractType | undefined = optionMajors.changeContract
      ? {
        basicProductCode: majorData.changeContract?.baseProductCode || '',
        riskCommDate: majorData.changeContract?.riskCommDate || '',
        age: majorData.changeContract?.age || 0,
        sumAssured: majorData.changeContract?.sumAssured ? Number(majorData.changeContract?.sumAssured) : 0,
        policyYear: majorData.changeContract?.policyYear || '',
        riskCessDate: majorData.changeContract?.riskCessationDate || '',
        premiumYear: majorData.changeContract?.premiumYear || '',
        installmentPremium: majorData.changeContract?.installmentPremium
          ? Number(majorData.changeContract?.installmentPremium)
          : 0
      }
      : undefined

    const productOptionSwitchingData: MajorCombined.ProductOptionSwitching | undefined =
      optionMajors.productOptionSwitching
        ? {
          benefits: majorData.productOptionSwitching?.benefits?.value || '',
          oldBenefit: majorData.productOptionSwitching?.oldBenefit || '',
          currentSumAssured: majorData.productOptionSwitching?.currentSumAssured || 0,
          newSumAssured: majorData.productOptionSwitching?.newSumAssured || 0,
          riskSumAssured: 0,
          minimumSumAssured: 0
        }
        : undefined

    const riderAlterationData: MajorCombined.RiderAlteration | undefined =
      optionMajors.riderAlteration && majorData.riderAlteration
        ? getRiderAlterData(majorData.riderAlteration)
        : undefined

    const reinstatementData: ReinstatementModel.ReinstatementSubmitData | undefined = optionMajors.reinstatement
      ? {
        policyNo: policyNum,
        owners: {
          clientId: majorData.reinstatement?.clientId || ''
        },
        lifeAssured: lifeAssuredReins(),
        attributesExt: {
          TRANS_CODE: majorData.reinstatement?.transCode || '',
          AGENT_CODE: majorData.reinstatement?.agentCode || '',
          AGENT_NAME: majorData.reinstatement?.agentName || '',
          RISK_COMM_DATE: majorData.reinstatement?.riskCommonDate || '',
          LAPSED_DATE: majorData.reinstatement?.lapsedDate || '',
          FREQUENCY: majorData.reinstatement?.frequency || '',
          INSTALLMENT_PREM: majorData.reinstatement?.installmentPrem || 0,
          APL: majorData.reinstatement?.apl || 0,
          OPL: majorData.reinstatement?.opl || 0,
          REINS_FEE: majorData.reinstatement?.reinFee || 0,
          TOTAL_REINS_AMOUNT: majorData.reinstatement?.totalReinAmount || 0
        }
      }
      : undefined

    const riderReinstatementData: RiderReinstatementModel.RiderReinstatementSubmitData | undefined =
      optionMajors.riderReinstatement
        ? {
          reinsFee: majorData.riderReinstatement?.reinFee || 0,
          lifeAssuredList: lifeAssuredRiderReins()
        }
        : undefined

    const cancelRiderData: MajorCombined.CancelRider | undefined = optionMajors.riderCancellation
      ? {
        policyNo: policyNum,
        lifeAssureds: cancelRider()
      }
      : undefined

    return {
      majorType: majorType,
      changeContract: changeContractData,
      billingChange: billingChangeData,
      productOptionSwitching: productOptionSwitchingData,
      riderCancellation: cancelRiderData,
      riderAlteration: riderAlterationData,
      reinstatement: reinstatementData,
      riderReinstatement: riderReinstatementData,
      requirePayinAmount: majorCombinedForm.watch('requirePayinAmount') || ''
    }
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const listAttachmentFiles = [
      majorCombinedForm.getValues('productOptionSwitching.attachmentFiles'),
      majorCombinedForm.getValues('reinstatement.attachmentFiles'),
      majorCombinedForm.getValues('riderReinstatement.attachmentFiles'),
      majorCombinedForm.getValues('riderAlteration.healthDocuments')
    ]
    if (majorCombinedForm.getValues('riderAlteration.newRiderLAList')?.length > 0) {
      majorCombinedForm.getValues('riderAlteration.newRiderLAList').map((item) => {
        if (item.newLAInfo && item.newLAInfo?.identifyDocument) {
          uploadedFileList.push({
            uploadFiles: item.newLAInfo?.identifyDocument as FileUploadData[],
            transactionType: TransactionType.MAJOR_COMBINED_CHANGE,
            docTypeCode: 'DPS10',
            category: TaskType.PolicyService,
            policyNumber: policyNumber ?? '',
            officeCode: officeCode ?? ''
          })
        }
      })
    }
    listAttachmentFiles.map((trans) => {
      if (trans) {
        uploadedFileList.push({
          uploadFiles: trans as FileUploadData[],
          transactionType: TransactionType.MAJOR_COMBINED_CHANGE,
          docTypeCode: 'DPS09',
          category: TaskType.PolicyService,
          policyNumber: policyNumber ?? '',
          officeCode: officeCode ?? ''
        })
      }
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async () => {
      const isValidForm = await majorCombinedForm.trigger()
      const validateComponent =
        (await checkBillingChange()) &&
        (await checkChangeContract()) &&
        (await checkProductOption()) &&
        (await checkCancelRider()) &&
        (await checkReinstatement()) &&
        (await checkRiderReinstatement()) &&
        (await checkRiderAlteration())
      console.log(isValidForm, 'isValidForm')
      console.log(validateComponent, 'validateComponent')
      if (isValidForm && validateComponent) {
        const submitedData = getSubmitedData()
        console.log(submitedData)
        return {
          url: (policyNum: string) => `wf-api/policy/${policyNum}/major-combine-change`,
          body: submitedData,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.MAJOR_COMBINED_CHANGE),
          collerationId: policyNum,
          transaction: TransactionType.MAJOR_COMBINED_CHANGE,
          // documents: submitedData.uploadedFiles,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      } else {
        return false
      }
    },
    clear: () => {
      setOptionMajors({
        changeContract: false,
        billingChange: false,
        productOptionSwitching: false,
        riderAlteration: false,
        riderCancellation: false,
        reinstatement: false,
        riderReinstatement: false
      })
      majorCombinedForm.reset()
    }
  })

  return (
    <SafeAreaView style={styles.containerMarginBottom}>
      <Title wrapperStyle={styles.marginTop} title={t('requestInfo:RequestDetail')}></Title>
      {majorType === MajorTypes.MJ_AFTER_FREE_LOOK ? (
        <Title wrapperStyle={styles.marginTop} title={t('MajorCombined:AfterFreeLook')}></Title>
      ) : (
        <Title wrapperStyle={styles.noMarginBottom} title={t('MajorCombined:InFreeLook')}></Title>
      )}
      <View style={optionMajors.changeContract ? styles.containerMarginBottom : styles.container}>
        <Checkbox
          enable={!isConfirmed}
          style={styles.marginCheckBoxSelected}
          fontWeight={'800'}
          // uppercase={true}
          title={t('TransactionType:CHANGE_CONTRACT_TYPE')}
          value={optionMajors.changeContract}
          onChange={() => {
            setOptionMajors({ ...optionMajors, changeContract: !optionMajors.changeContract })
            majorCombinedForm.resetField('changeContract')
          }}
        />
        {optionMajors.changeContract && (
          <ChangeContractComponent
            policyNum={policyNum}
            isConfirmed={isConfirmed}
            form={majorCombinedForm}
            validateBeforeContinue={validateBeforeContinue}
          ></ChangeContractComponent>
        )}
      </View>

      <View style={optionMajors.billingChange ? styles.containerMarginBottom : styles.container}>
        <Checkbox
          enable={!isConfirmed}
          style={{ marginBottom: 10 }}
          fontWeight={'800'}
          // uppercase={true}
          title={t('TransactionType:BILLING_CHANGE')}
          value={optionMajors.billingChange}
          onChange={() => {
            setOptionMajors({ ...optionMajors, billingChange: !optionMajors.billingChange })
            majorCombinedForm.resetField('billingChange')
          }}
        />
        {optionMajors.billingChange && (
          <BillingChangeComponent
            policyNum={policyNum}
            isConfirmed={isConfirmed}
            form={majorCombinedForm}
            validateBeforeContinue={validateBeforeContinue}
          ></BillingChangeComponent>
        )}
      </View>

      {majorType === MajorTypes.MJ_AFTER_FREE_LOOK && (
        <View style={optionMajors.productOptionSwitching ? styles.containerMarginBottom : styles.container}>
          <Checkbox
            enable={!isConfirmed}
            style={styles.marginCheckBoxSelected}
            fontWeight={'800'}
            // uppercase={true}
            title={t('TransactionType:PRODUCT_OPTION_SWITCHING')}
            value={optionMajors.productOptionSwitching}
            onChange={() => {
              setOptionMajors({ ...optionMajors, productOptionSwitching: !optionMajors.productOptionSwitching })
              majorCombinedForm.resetField('productOptionSwitching')
            }}
          />
          {optionMajors.productOptionSwitching && (
            <ProductOptionSwitchingComponent
              policyNum={policyNum}
              isConfirmed={isConfirmed}
              form={majorCombinedForm}
              validateBeforeContinue={validateBeforeContinue}
            ></ProductOptionSwitchingComponent>
          )}
        </View>
      )}

      <View style={optionMajors.riderCancellation ? styles.containerMarginBottom : styles.container}>
        <Checkbox
          enable={!isConfirmed}
          style={styles.marginCheckBoxSelected}
          fontWeight={'800'}
          // uppercase={true}
          title={t('TransactionType:CANCEL_RIDER')}
          value={optionMajors.riderCancellation}
          onChange={() => {
            setOptionMajors({ ...optionMajors, riderCancellation: !optionMajors.riderCancellation })
            majorCombinedForm.resetField('cancelRider')
          }}
        />
        {optionMajors.riderCancellation && (
          <CancelRiderComponent
            policyNum={policyNum}
            isConfirmed={isConfirmed}
            validateBeforeContinue={validateBeforeContinue}
            form={majorCombinedForm}
          ></CancelRiderComponent>
        )}
      </View>

      <View style={optionMajors.riderAlteration ? styles.containerMarginBottom : styles.container}>
        <Checkbox
          enable={!isConfirmed}
          style={styles.marginCheckBoxSelected}
          fontWeight={'800'}
          // uppercase={true}
          title={t('TransactionType:RIDER_ALTERATION')}
          value={optionMajors.riderAlteration}
          onChange={() => {
            setOptionMajors({ ...optionMajors, riderAlteration: !optionMajors.riderAlteration })
            majorCombinedForm.resetField('riderAlteration')
          }}
        />
        {optionMajors.riderAlteration && (
          <RiderAlterationComponent
            policyNum={policyNum}
            isConfirmed={isConfirmed}
            officeCode={officeCode ?? ''}
            validateBeforeContinue={validateBeforeContinue}
            form={majorCombinedForm}
          />
        )}
      </View>

      {majorType === MajorTypes.MJ_AFTER_FREE_LOOK && (
        <View style={optionMajors.reinstatement ? styles.containerMarginBottom : styles.container}>
          <Checkbox
            enable={!isConfirmed}
            style={styles.marginCheckBoxSelected}
            fontWeight={'800'}
            // uppercase={true}
            title={t('TransactionType:REINSTATEMENT')}
            value={optionMajors.reinstatement}
            onChange={() => {
              setOptionMajors({ ...optionMajors, reinstatement: !optionMajors.reinstatement })
              majorCombinedForm.resetField('reinstatement')
            }}
          />
          {optionMajors.reinstatement && (
            <ReinstatementComponent
              policyNum={policyNum}
              isConfirmed={isConfirmed}
              validateBeforeContinue={validateBeforeContinue}
              form={majorCombinedForm}
            ></ReinstatementComponent>
          )}
        </View>
      )}

      {majorType === MajorTypes.MJ_AFTER_FREE_LOOK && (
        <View style={optionMajors.riderReinstatement ? styles.containerMarginBottom : styles.container}>
          <Checkbox
            enable={!isConfirmed}
            style={styles.marginCheckBoxSelected}
            fontWeight={'800'}
            // uppercase={true}
            title={t('TransactionType:RIDER_REINSTATEMENT')}
            value={optionMajors.riderReinstatement}
            onChange={() => {
              setOptionMajors({ ...optionMajors, riderReinstatement: !optionMajors.riderReinstatement })
              majorCombinedForm.resetField('riderReinstatement')
            }}
          />
          {optionMajors.riderReinstatement && (
            <RiderReinstatementComponent
              policyNum={policyNum}
              isConfirmed={isConfirmed}
              validateBeforeContinue={validateBeforeContinue}
              form={majorCombinedForm}
            ></RiderReinstatementComponent>
          )}
        </View>
      )}

      <View style={[styles.containerMarginBottom, { width: '33%' }]}>
        <Title wrapperStyle={styles.secondLine} title={t('submission:TEMPORARYPAYININFO')}></Title>
        <Controller
          key={`requirePayinAmount`}
          control={majorCombinedForm.control}
          name={`requirePayinAmount`}
          render={({ field: { value, onChange, onBlur } }) => (
            <Input
              disabled={isConfirmed}
              title={t('MajorCombined:RequiredPayinAmount')}
              value={value}
              inputType={'money'}
              onChange={onChange}
              onBlur={onBlur}
              maxLength={100}
            />
          )}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 15
  },
  containerMarginBottom: {
    marginTop: 30,
    marginBottom: 10
  },
  noMarginBottom: {
    marginBottom: 0
  },
  marginTop: {
    marginTop: 15,
    marginBottom: 10
  },
  marginCheckBoxSelected: {
    marginBottom: 10
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  secondSmallLine: {
    marginTop: 10
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  redLine: {
    color: '#ed1c2e',
    fontWeight: '700'
  },
  underlineLink: {
    textDecorationLine: 'underline'
  }
})
