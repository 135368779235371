import { Pagination } from '@material-ui/lab'
import { Format } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/native'
import { AmlResult } from '../general-info/model/aml-result'
import { Form } from './form'
import { Input } from './input'
import { SectionHeader } from './section-header'
import { Text, View } from './styled'

const size = 5

export const AmlHistory = ({ title, history }: { title?: string; history: AmlResult[] }) => {
  const { t } = useTranslation('business')
  const [page, setPage] = React.useState(1)

  const start = (page - 1) * size
  const list = history.slice(start, start + size)
  return (
    <Form.Group>
      <SC.TopRow>
        <SectionHeader>{title || t('aml_history')}</SectionHeader>
        {history.length > size ? (
          <Pagination
            count={Math.ceil(history.length / size)}
            page={page}
            onChange={(event, page) => {
              setPage(page)
            }}
          />
        ) : null}
      </SC.TopRow>
      {list.length ? (
        list.map((r, i) => (
          <Form.Row key={i.toString()}>
            <Form.Col>
              <Input label={t('aml_scan_date')} value={Format.date(r.scanDt)} readonly />
            </Form.Col>
            <Form.Col>
              <Input label={t('result_aml')} value={r.amlResult} readonly />
            </Form.Col>
            <Form.Col />
          </Form.Row>
        ))
      ) : (
        <Form.Row>
          <Form.Col>
            <Text
              style={{
                paddingHorizontal: 10
              }}
            >
              {t('no_result')}
            </Text>
          </Form.Col>
        </Form.Row>
      )}
    </Form.Group>
  )
}

const SC = {
  TopRow: styled(View)`
    flex-direction: row;
    gap: 10rem;
  `
}
