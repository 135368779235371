import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
export namespace TaskFilterForm {
  const SelectOption = t.type({
    label: t.string,
    value: t.string
  })

  const MultipleSelectOption = t.array(SelectOption)

  export const codec = t.type({
    client: form2.string.optional,
    assignee: form2.string.optional,
    caseId: form2.string.optional,

    transactionType: MultipleSelectOption,
    source: MultipleSelectOption,
    category: form2.selectOption.optional,
    status: form2.selectOption.optional,

    createdFromDate: form2.date.optional,
    createdToDate: form2.date.optional,

    agingFrom: form2.string.optional,
    agingTo: form2.string.optional,
    paymentMethod: form2.selectOption.optional,
    eref: form2.string.optional,
    agentCode: form2.string.optional,
    submittedBy: form2.string.optional,
    lastActivity: form2.selectOption.optional,
    AMLScreening: form2.selectOption.optional,
    AMLRedflag: form2.selectOption.optional,
    claimNumber: form2.string.optional
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
