import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { adjudicationC, policyRider, verificationC } from './AdjudicationModel'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.PAYOUT)
    }),
    codec
  ])

export const VerificationClaimPayout = Base(
  t.type({
    source: Maybe(t.string),
    claimNumber: Maybe(t.string),
    policyNumber: Maybe(t.string),
    payload: t.type({
      source: Maybe(t.string),
      exchangeId: Maybe(t.string),
      createdBy: Maybe(t.string),
      body: t.type({
        claimInfo: t.type({
          claimNo: Maybe(t.string),
          claimType: Maybe(t.string),
          subClaimType: Maybe(t.string),
          surgeryBenefit: Maybe(t.boolean),
          relatedOfAccident: Maybe(t.boolean),
          claimEventDate: Maybe(t.string),
          endClaimDate: Maybe(t.string),
          parentBusinessKey: Maybe(t.string),
          lastVerUpdatedBy: Maybe(t.string),
          lastVerUpdatedDate: Maybe(t.string),
          lastAdjUpdatedBy: Maybe(t.string),
          lastAdjUpdatedDate: Maybe(t.string)
        }),
        adjudicationInfo: t.type({
          payableAmount: Maybe(t.string),
          claimDecision: Maybe(t.string),
          effectiveDate: Maybe(t.string),
          policyStatus: Maybe(t.string)
        }),
        paymentInfo: t.type({
          cashOutOptions: t.array(
            t.type({
              type: Maybe(t.string),
              policy: t.type({
                policyNo: Maybe(t.string),
                totalPremium: Maybe(t.number),
                status: Maybe(t.string)
              }),
              paymentOption: t.type({
                currency: Maybe(t.string),
                amount: Maybe(t.number)
              }),
              bankAccount: t.type({
                bankName: Maybe(t.string),
                bankCode: Maybe(t.string),
                branchCode: Maybe(t.string),
                accountNo: Maybe(t.string),
                accountName: Maybe(t.string)
              }),
              attributesExt: t.type({
                PAYEE_INFO: t.type({
                  paymentMethod: Maybe(t.string),
                  isNewClient: Maybe(t.boolean),
                  isPayeeNotPO: Maybe(t.boolean),
                  changePayeeInfo: Maybe(t.boolean),
                  payeeName: Maybe(t.string),
                  idNumber: Maybe(t.string),
                  dob: Maybe(t.string),
                  gender: Maybe(t.string),
                  occupation: Maybe(t.string),
                  relationshipWithPolicyOwner: Maybe(t.string),
                  nationality: Maybe(t.string),
                  nationality2: Maybe(t.string),
                  residenceCountry: Maybe(t.string),
                  address: Maybe(t.string),
                  foreignAddress: Maybe(t.string)
                })
              })
            })
          ),
          refNumber: Maybe(t.string)
        })
      }),
      documents: Maybe(t.Array),
      primaryPolicyNo: Maybe(t.string),
      clientId: Maybe(t.string),
      officeCode: Maybe(t.string),
      createdDate: Maybe(t.string)
    })
  })
)

export type VerificationClaimPayout = t.TypeOf<typeof VerificationClaimPayout>
