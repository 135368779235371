/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-extra-boolean-cast */
import { View, StyleSheet, useWindowDimensions, ScrollView, Text, TouchableOpacity } from 'react-native'
import React, { useRef, useState } from 'react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import {
  AppContext,
  useMobile,
  AuthService,
  ErrorHandling,
  assets,
  GeneralService,
  TransactionType,
  StorageBlob,
  form2,
  RBAC,
  GroupPolicyPermission,
  MenuPermissions,
  AppConfig
} from '@pulseops/common'

import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { FieldText, SectionCol, SectionRow } from '@pulseops/submission/common'
import { FileUploadData, ImgUploadMutiple } from '../common/SEAmgUploadMultiple'
import SignCheckDialog, { FormSEASignCheckDialog } from '../common/SEASignCheckDialog'
import { SEAWithdrawChangeForm } from './SEAWithdrawChangeForm'
import { SEAWithdrawChangeService } from './SEAWithdrawChangeService'
import { ImgUploadMutipleXLS } from '../common/SEAImgUploadMutipleXLS'
import { SC } from '../styles'
import { addHours } from 'date-fns'
import SEAWithdrawSignCheckDialog from '../common/SEAWithdrawSignCheckDialog'
import { UseFormReturn } from 'react-hook-form'
export enum ImportType {
  ImportBillingChange = 1,
  ImportPartialWithdramw = 2
}
type Props = {
  type: ImportType
  form: Omit<UseFormReturn<SEAWithdrawChangeForm.Raw>, 'handleSubmit'>
}
export const SEAWithdrawUploadFileScreen = (props: Props) => {
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const { t } = useTranslation('BillingChange')
  const { height } = useWindowDimensions()
  const [loading, bindLoader] = useLoading(false)
  const { isMobile } = useMobile()
  const [isSignaturePopupOpen, setIsSignaturePopupOpen] = React.useState<boolean>(false)
  const [isCheck, setisCheck] = React.useState<boolean>(false)
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const [files, setFiles] = useState<{ PDF: FileUploadData[]; XLSX: FileUploadData[] } | null>(null)
  const formRef = useRef<FormSEASignCheckDialog | any>()
  const { base } = form2.useForm(SEAWithdrawChangeForm.codec)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    props.form.setValue('checkReset', false)
    showGlobalLoading(loading)
  }, [loading, props.form.watch('checkReset')])
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('ImportFileForPartialWithdraw'),
          navigate: null
        }
      ])
    }
  }, [isFocused])
  const configAruze = pipe(
    AppConfig.get,
    ZIO.map((_) => _),
    ErrorHandling.runDidMount()
  )
  const folder = () => {
    if (configAruze?.env === 'pva-prd-az1') {
      return 'banca_sea'
    } else if (configAruze?.env === 'pva-stag') {
      return 'banca_seastag'
    } else {
      return 'banca_seauat'
    }
  }
  const openSignatureCheckPopup = () => {
    setisCheck(formRef?.current?.isCheckDialog as any)
    setIsSignaturePopupOpen(true)
  }

  const handleCloseSignCheck = () => {
    setisCheck(formRef?.current?.isCheckDialog as any)
    setIsSignaturePopupOpen(false)
  }

  const getAttachmentFilesWithMetaData = () => {
    let metaDataFiles: StorageBlob.FileContent[] = []
    if ((files?.PDF && files.PDF.length > 0) || (files?.XLSX && files.XLSX.length > 0)) {
      return pipe(
        GeneralService.getMetaData(TransactionType.PARTIAL_WITHDRAWAL, 'DPS17'),
        ZIO.map((metaDataResponse) => {
          const metaDataRaw: StorageBlob.MetaDataUpload = {
            type: metaDataResponse.data.transactionType,
            doctype: metaDataResponse.data.doctypeEn,
            class: metaDataResponse.data.classFilenet,
            docid: metaDataResponse.data.docID,
            maindoc: metaDataResponse.data.mainDoc,
            subdoc: metaDataResponse.data.subDoc,
            polnum: '',
            batchno: metaDataResponse.data.batchNo
          }
          metaDataFiles = [...files.PDF, ...files.XLSX].map((x) => {
            return {
              filename: x.fileName,
              file: x.file,
              metaData: metaDataRaw
            }
          })
          return metaDataFiles
        })
      )
    }
    return pipe(metaDataFiles, ZIO.succeed)
  }
  const submitQuotation = () => {
    showGlobalLoading(true)
    return pipe(
      getAttachmentFilesWithMetaData(),
      ZIO.flatMap((metaFiles) => StorageBlob.uploadToSubmitSEA(folder(), '')(metaFiles)),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        showToast(t('message:MS990097'), 'error')
        return ZIO.unit
      }),
      ZIO.flatMap((res) => {
        const authenRequestForm = {
          authFlag: true,
          signatureDocuments: res
        }
        const date = new Date()
        const createdDate = addHours(date, 7).toISOString()
        return res && res.length > 0
          ? SEAWithdrawChangeService.submitGuide(res, userInfo?.email ?? '', authenRequestForm, createdDate)
          : ZIO.succeed({})
      }),
      ZIO.tap((res) => {
        showGlobalLoading(false)
        showToast(t('message:MS990066'), 'success')
        setFiles({ PDF: [], XLSX: [] })
        return ZIO.unit
      }),
      ZIO.foldM(
        (error: any) =>
          ZIO.effect(() => {
            if (
              error?.source?.response?.data?.responseStatus?.errors[0]?.code === 'MS990101' ||
              error?.source?.message === 'StorageBlob: Upload fail'
            ) {
              showToast(t('message:MS990101'), 'error')
            } else {
              showToast(t('message:MS990127'), 'error')
            }
            showGlobalLoading(false)
            return false
          }),
        (success) =>
          ZIO.effect(() => {
            showGlobalLoading(false)
            return success
          })
      ),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }
  const checkDuplicate = (filename: string) => {
    showGlobalLoading(true)
    return pipe(
      SEAWithdrawChangeService.getCheckDuplicateFile(filename),
      ZIO.map((res): any => {
        return submitQuotation()
      }),
      ZIO.catchAll((error) => {
        showGlobalLoading(false)
        showToast(t('message:MS990097'), 'error')
        return ZIO.succeed(true)
      }),
      ZIO.unsafeRun({})
    )
  }
  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <View style={{ backgroundColor: '#FFF', height: height, flex: 1 }}>
            <View>
              <Text style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: 15 }}>{t('UploadGroup')}</Text>
              <SectionRow sectionStyles={styles.secondLine}>
                <SectionCol sectionStyles={styles.col12}>
                  <FieldText text={t('Attachment')} isRequired={true}></FieldText>
                  <ImgUploadMutipleXLS
                    value={files?.XLSX ?? []}
                    onChange={(files) =>
                      setFiles((prev: any) => ({
                        ...prev,
                        XLSX: [...files]
                      }))
                    }
                    type={props.type}
                  />
                </SectionCol>
              </SectionRow>
            </View>
            <View style={[styles.searchContainer]}>
              <SectionRow sectionStyles={styles.secondLine}>
                <SectionCol sectionStyles={styles.col12}>
                  <FieldText
                    fieldStyles={{ textTransform: 'uppercase', color: 'black', marginTop: -20, paddingBottom: 5 }}
                    text={t('EvidenceDoc')}
                    isRequired={false}
                  ></FieldText>
                  <View style={{ flexDirection: 'row' }}>
                    <FieldText text={t('Attachment')} isRequired={true}></FieldText>
                    <TouchableOpacity onPress={openSignatureCheckPopup}>
                      <View style={[styles.signatureContent]}>
                        <assets.EyesIcon />
                        <Text
                          style={{
                            marginLeft: 16,
                            fontSize: 16,
                            color: '#2797fe',
                            textDecorationLine: 'underline',
                            fontWeight: '500'
                          }}
                        >
                          {t('SignatureCheck')}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <ImgUploadMutiple
                    value={files?.PDF ?? []}
                    onChange={(files) =>
                      setFiles((prev: any) => ({
                        ...prev,
                        PDF: [...files]
                      }))
                    }
                  ></ImgUploadMutiple>
                </SectionCol>
              </SectionRow>
            </View>
            {permissions &&
              (permissions.includes(GroupPolicyPermission.ImportFileImportFileAutoDebit)) && (
                <SC.ContainerFooter style={isMobile ? { paddingHorizontal: 20, paddingVertical: 20 } : {}}>
                  <View style={{ flex: 1 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                      <TouchableOpacity
                        disabled={!!files?.PDF && !!files?.XLSX ? false : true}
                        style={!!files?.PDF && !!files?.XLSX ? styles.buttonRed : styles.buttonWhite}
                        onPress={() => {
                          const namePDF = files?.PDF[0]?.fileName.substring(12)
                          const nameXLSX = files?.XLSX[0]?.fileName.substring(8)
                          if (
                            files?.PDF[0]?.fileName === null ||
                            files?.PDF[0]?.fileName === undefined ||
                            files?.XLSX[0]?.fileName === null ||
                            files?.XLSX[0]?.fileName === undefined
                          ) {
                            showToast(t('message:MS030040'), 'error')
                          }
                          if (isCheck === false || isCheck === undefined || isCheck === null) {
                            return showToast(t('message:MS050261'), 'error')
                          }
                          if (namePDF !== nameXLSX) {
                            return showToast(t('message:MS990103'), 'error')
                          }
                          checkDuplicate(files?.XLSX[0]?.file?.name ?? '')
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            alignContent: 'center'
                          }}
                        >
                          <Text style={styles.textButtonRed}>{t('Import')}</Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </SC.ContainerFooter>
              )}
          </View>
        </View>
      </ScrollView>
      {isSignaturePopupOpen && (
        <SEAWithdrawSignCheckDialog
          ref={formRef}
          open={isSignaturePopupOpen}
          onClose={handleCloseSignCheck}
          onDocReviewed={() => {}}
          isRequestForm={false}
          docReviewed={false}
          base={base}
        ></SEAWithdrawSignCheckDialog>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginTop: 15,
    padding: 25,
    marginRight: 15
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15,
    width: '30%'
  },
  sessonText: {
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 15
  },
  btn: {
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    paddingHorizontal: 30,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  btnUpload: {
    height: 40,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10,
    boxShadow: 'none'
  },
  rowBtn: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 15
  },
  btnText: {
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'NotoSans_700Bold'
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'baseline',
    borderRadius: 16
    // maxHeight: 24
  },

  lalel: {
    fontSize: 13,
    fontWeight: '500',
    marginHorizontal: 16,
    marginVertical: 2,
    lineHeight: 24,
    whiteSpace: 'nowrap'
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 6,
    marginHorizontal: 36
  },
  secondLine: {
    marginTop: 20
  },
  col12: {
    width: '100%',
    maxWidth: '100%'
  },
  textButtonRed: {
    textAlign: 'center',
    color: '#FFF',
    fontWeight: 'bold'
  },
  buttonRed: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  buttonWhite: {
    borderRadius: 100,
    backgroundColor: '#ccc',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  signatureContent: {
    marginLeft: 25,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
})
