import {
  AppContext,
  CommonRequestService,
  ErrorHandling,
  ImgUploadMutiple,
  Select,
  // StorageBlob,
  SUCommonRequestData,
  // TaskType,
  Title,
  TransactionType
} from '@pulseops/common'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { CommonRequestFormData } from './common-request.form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { Column, Error } from '@pulseops/submission/common'
import { RequestAuthenticateData } from '../../request-authen'

export const CommonRequestFormScreen: React.FC<PolicyServiceProps<SUCommonRequestData.SubmitedData>> = ({
  initSubmission,
  policyNumber,
  isConfirmed,
  officeCode
}) => {
  const { t, i18n } = useTranslation()
  const policyNum = policyNumber ?? ''
  const [transactionList, setTransactionList] = React.useState<SUCommonRequestData.CommonRequest[]>([])
  const [documentTypeList, setDocumentTypeList] = React.useState<SUCommonRequestData.DocumentType[]>([])

  const [transactionWFTypeList, setTransactionWFTypeList] = React.useState<SUCommonRequestData.TransactionWFType[]>([])
  const [category, setCategory] = React.useState<string>('')

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoader] = useLoading(false)

  const commonRequestForm = useForm<CommonRequestFormData.CommonRequestData>({
    defaultValues: {
      request: undefined,
      documentType: []
    }
  })

  const { control, watch, reset } = commonRequestForm
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'documentType'
  })

  React.useEffect(() => {
    if (!!policyNumber) {
      pipe(
        CommonRequestService.getDetail(policyNumber),
        ZIO.map((responseData) => {
          if (!!responseData) {
            setTransactionList(responseData.commonRequestList)
            setDocumentTypeList(responseData.documentTypeList)
            setTransactionWFTypeList(responseData.transactionWFTypeList)
          }
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
    return () => {
      remove()
      reset({ request: undefined, documentType: [] })
    }
  }, [policyNumber])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getTransactionNameByLocale = (item: SUCommonRequestData.CommonRequest) => {
    return i18n.language === 'en' ? item.requestNameEn : item.requestNameVn
  }

  const getDocumentTypeNameByLocale = (documentType: SUCommonRequestData.DocumentType) => {
    return i18n.language === 'en' ? documentType.docTypeEn : documentType.docTypeVn
  }

  function getDocumentTypeData(
    documentType: SUCommonRequestData.DocumentType,
    docTypeName: string,
    transactionTypeName: string
  ) {
    const documentTypeForm: CommonRequestFormData.DocumentType = {
      transactionTypeName: transactionTypeName,
      requestName: documentType.requestName,
      docTypeCode: documentType.docTypeCode,
      docTypeName: docTypeName,
      mandatory: documentType.mandatory,
      attachmentFiles: []
    }
    return documentTypeForm
  }

  function onChangeRequestEvent(value: string) {
    remove()
    if (!!value) {
      // this.category = value === 'RQ01' ? 'DA' : 'PS'
      setCategory(value === 'RQ01' ? 'DA' : 'PS')
      const transactionItem = transactionList.find((x) => x.requestCode === value)
      const requestNameEn = transactionItem?.requestNameEn ?? ''
      const transactionTypeName = transactionItem?.requestWF ?? ''
      const attachmentList = documentTypeList.slice().filter((x) => x.requestName === requestNameEn)
      attachmentList.forEach((item, index) => {
        const docTypeName = getDocumentTypeNameByLocale(item)
        // const documentTypeForm = CommonRequestForm.getDocumentTypeForm(item, docTypeName, transactionTypeName)
        // this.documentTypeArray.push(documentTypeForm)
        append(getDocumentTypeData(item, docTypeName, transactionTypeName))
      })
    }
  }

  const getTransactionInfo = () => {
    const requestVal = watch('request')?.value ?? ''
    const transactionItem = transactionList.find((x) => x.requestCode === requestVal)
    const transactionTypeName = transactionItem?.requestWF ?? ''
    const requestNameEn = transactionItem?.requestNameEn ?? ''
    const documentItem = documentTypeList.find(
      (x) =>
        (x.requestName === requestNameEn && x.docTypeEn === 'Request form') ||
        (x.requestName === requestNameEn && x.docTypeEn === 'Change Agent')
    )
    const doctypeCode = documentItem?.docTypeCode ?? ''
    const shortItem = transactionWFTypeList.find((x) => x.request === requestNameEn)
    const transactionType = shortItem?.transactionTypeWF as TransactionType
    const shortLabel = RequestAuthenticateData.TransactionLabelShort(transactionType)
    return {
      transactionTypeName,
      doctypeCode,
      shortLabel
    }
  }

  const getAttachmentFiles = () => {
    const commonFormData = commonRequestForm.getValues()
    const documentFiles = commonFormData.documentType.map((documentItem) => {
      // const documentTypeForm = documentItem as FormGroup<CommonRequestForm.DocumentType>
      const docTypeCode = documentItem.docTypeCode
      const transactionTypeName = documentItem.transactionTypeName
      const attachmentList = documentItem.attachmentFiles
      return {
        docTypeCode: docTypeCode,
        transactionTypeName: transactionTypeName,
        attachmentList: attachmentList
      }
    })
    return documentFiles
  }

  // const getFileswithMetaData = () => {
  //   let documentTypeFiles = getAttachmentFiles()
  //   documentTypeFiles = documentTypeFiles.filter((x) => !!x.attachmentList && x.attachmentList.length > 0)
  //   return pipe(
  //     ZIO.zipPar(
  //       CommonRequestService.getMetaDataForApi(policyNum, documentTypeFiles, officeCode),
  //       pipe(documentTypeFiles, ZIO.succeed)
  //     ),
  //     ZIO.map(([metaDataFiles, documentFiles]) => {
  //       let metaFiles: StorageBlob.FileContent[] = []
  //       documentFiles.map((fileItem) => {
  //         const mapFiles = fileItem.attachmentList.map((item) => {
  //           const metaItem = metaDataFiles.find(
  //             (x) => x.transactionTypeName === fileItem.transactionTypeName && x.docTypeCode === fileItem.docTypeCode
  //           )
  //           const metaDataRaw: StorageBlob.MetaDataUpload = {
  //             type: metaItem?.type ?? '',
  //             doctype: metaItem?.doctype ?? '',
  //             class: metaItem?.class ?? '',
  //             docid: metaItem?.docid ?? '',
  //             maindoc: metaItem?.maindoc ?? '',
  //             subdoc: metaItem?.subdoc ?? '',
  //             polnum: policyNum,
  //             batchno: metaItem?.batchno ?? ''
  //           }
  //           return {
  //             file: item.file,
  //             metaData: metaDataRaw
  //           }
  //         })
  //         metaFiles = [...metaFiles, ...mapFiles]
  //       })
  //       return metaFiles
  //     })
  //   )
  // }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    let documentTypeFiles = getAttachmentFiles()
    documentTypeFiles = documentTypeFiles.filter((x) => !!x.attachmentList && x.attachmentList.length > 0)
    documentTypeFiles.forEach((documentItem) => {
      uploadedFileList.push({
        uploadFiles: documentItem.attachmentList,
        transactionType: documentItem.transactionTypeName as TransactionType,
        docTypeCode: documentItem.docTypeCode,
        category: category,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    })
    return uploadedFileList
  }

  const getCommonRequestSubmitedData = (isContinue: boolean) => {
    // let submitedData: SUCommonRequestData.RequestInfoData
    const requestCode = watch('request')?.value ?? ''
    const requestItem = transactionList.find((x) => x.requestCode === requestCode)
    const requestNameEn = requestItem?.requestNameEn ?? ''
    const shortItem = transactionWFTypeList.find((x) => x.request === requestNameEn)
    const transWf = shortItem?.transactionTypeWF ?? ''
    // return isContinue
    //   ? pipe(
    //       ZIO.effect(() => {
    //         submitedData = {
    //           submitedData: {
    //             request: requestCode,
    //             transWf: transWf
    //           },
    //           documentFiles: []
    //         }
    //         return submitedData
    //       }),
    //       bindLoader,
    //       ZIO.unsafeRun({})
    //     )
    //   : pipe(
    //       getFileswithMetaData(),
    //       ZIO.flatMap((metaDataResponse) => {
    //         return pipe(StorageBlob.uploadToSubmit(category, policyNum)(metaDataResponse))
    //       }),
    //       ZIO.map((azureFiles) => {
    //         submitedData = {
    //           submitedData: {
    //             request: requestCode,
    //             transWf: transWf
    //           },
    //           documentFiles: azureFiles
    //         }
    //         return submitedData
    //       }),
    //       bindLoader,
    //       ZIO.unsafeRun({})
    //     )
    return {
      submitedData: {
        request: requestCode,
        transWf: transWf
      },
      documentFiles: []
    }
  }

  initSubmission({
    validate: async (isContinue) => {
      const isValid = await commonRequestForm.trigger()
      if (isValid) {
        const transactionInfo = getTransactionInfo()
        const requestCommonData = getCommonRequestSubmitedData(!!isContinue)
        return {
          url: (policyNum: string) => `wf-api/policy/common-request-form`,
          body: requestCommonData.submitedData,
          transactionName: transactionInfo.shortLabel,
          collerationId: policyNum,
          transaction: TransactionType.COMMON_REQUEST_FORM,
          // documents: requestCommonData.documentFiles,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      } else {
        return false
      }
    },
    clear: () => {
      remove()
      reset({ request: undefined, documentType: [] })
    }
  })

  return (
    <SafeAreaView style={commonRequestStyles.areaContainer}>
      <View>
        <Title title={t('CommonRequestForm:CommonRequestInfo')}></Title>
        <View style={commonRequestStyles.sectionContent}>
          <View style={commonRequestStyles.sectionRow}>
            <View style={commonRequestStyles.col_4}>
              <Controller
                control={control}
                name="request"
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020001', { field: t('CommonRequestForm:Request') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('CommonRequestForm:Request')}
                    value={value}
                    onChange={(val) => {
                      onChange(val)
                      onChangeRequestEvent(val?.value ?? '')
                    }}
                    onBlur={onBlur}
                    disabled={isConfirmed}
                    options={transactionList.map((transactionItem) => ({
                      value: transactionItem.requestCode,
                      label: getTransactionNameByLocale(transactionItem)
                    }))}
                    errorMessage={!value ? error?.message : ''}
                  />
                )}
              />
            </View>
          </View>
        </View>
        {fields.map((fieldItem, index) => (
          <View style={commonRequestStyles.sectionContent} key={index}>
            <View>
              <Text style={commonRequestStyles.field_title}>
                {fieldItem.docTypeName}
                {fieldItem.mandatory === 'Y' && <Text style={commonRequestStyles.requiredText}>*</Text>}
              </Text>
              <Controller
                control={control}
                name={`documentType.${index}.attachmentFiles`}
                rules={{
                  required: {
                    value: fieldItem.mandatory === 'Y' ? true : false,
                    // message: `${t('message:MS020001', { field: fieldItem.docTypeName })}`
                    message: `${t('message:MS050029')}`
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Column>
                    <ImgUploadMutiple
                      value={value}
                      onChange={onChange}
                      timeFormat={'DD/MM/YYYY HH:mm'}
                      disabled={isConfirmed}
                    />
                    {error?.message && (
                      <Error
                        style={commonRequestStyles.messageLine}
                        message={!!value && value.length > 0 ? '' : error?.message}
                      />
                    )}
                  </Column>
                )}
              />
            </View>
          </View>
        ))}
      </View>
    </SafeAreaView>
  )
}
const commonRequestStyles = StyleSheet.create({
  areaContainer: {
    flex: 1,
    marginTop: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  requiredText: {
    color: 'red',
    marginLeft: 5,
    fontSize: 14
  },
  messageLine: {
    marginTop: 10
  }
})
