import { form2, PulseOpsFormat } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'

export const enum TabSearchForm {
  ImportData = 'ImportData',
  ExportData = 'ExportData'
}

interface SelectOption {
  label: string
  value: string
}

export const TypeImportList: SelectOption[] = [{ label: 'CERT', value: 'cert' }]

export namespace BasicSearchForm {
  let tab: TabSearchForm | null = null
  export const setTab = (tabSearch: TabSearchForm | null) => {
    tab = tabSearch
  }

  const Base = t.type({})
  type Base = t.TypeOf<typeof Base>

  const FormSearch = pipe(
    Base
    // form2.refine(
    //   (data): data is Form => moment(data.toDate).isSameOrAfter(data.fromDate, 'day'),
    //   () => i18next.t('message:MS030044'),
    //   'Form'
    // ),
    // form2.refine(
    //   (data): data is Form => {
    //     const dateFrom = PulseOpsFormat.startOfDate(data.fromDate)
    //     const dateTo = PulseOpsFormat.startOfDate(data.toDate)
    //     return moment(dateTo).diff(dateFrom, 'months', true) <= getDeviationDate()
    //   },
    //   () => i18next.t('message:MS080004', { k: getDeviationDate() }),
    //   'Form'
    // )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}

export namespace ImportSearchForm {
  const Base = t.type({
    type: form2.selectOption.optional
  })

  type Base = t.TypeOf<typeof Base>

  // type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const FormSearch = pipe(Base)

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}

export namespace ExportSearchForm {
  let tab: TabSearchForm | null = null
  export const setTab = (tabSearch: TabSearchForm | null) => {
    tab = tabSearch
  }

  const Base = t.type({
    fromDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('DistributionService:FromDate') })
    ),
    toDate: form2.date.requiredM(() =>
      i18next.t('message:MS020009', { field: i18next.t('DistributionService:ToDate') })
    ),
    ciNumber: form2.string.optional,
    idNumber: form2.string.optional
  })

  type Base = t.TypeOf<typeof Base>

  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  const getDeviationDate = () => (tab === TabSearchForm.ExportData ? 3 : 60)

  const FormSearch = pipe(
    Base,
    form2.refine(
      (data): data is Form => moment(data.toDate).isSameOrAfter(data.fromDate, 'day'),
      () => i18next.t('message:MS030044'),
      'Form'
    ),
    form2.refine(
      (data): data is Form => {
        const dateFrom = PulseOpsFormat.startOfDate(data.fromDate)
        const dateTo = PulseOpsFormat.startOfDate(data.toDate)
        return moment(dateTo).diff(dateFrom, 'months', true) <= getDeviationDate()
      },
      () => i18next.t('message:MS080004', { k: getDeviationDate() }),
      'Form'
    )
  )

  export const codec = FormSearch

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.Type<typeof codec>
}
