import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import {
  DatePickerTable,
  FieldList,
  Input,
  InputTable,
  Panel,
  Select,
  SelectOption,
  SelectSearch,
  TaskDetail,
  formatNumberWithComma
} from '@pulseops/common'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Controller, useFieldArray, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { AllocationForm, CalculationForm } from './AdjudicationForm'
interface Props {
  props: {
    faceAmount: string
    payload: TaskDetail.VerificationClaim
    claimNumber: string
    decision: {
      value: string
      label: string
    }[][]
  }
  form: UseFormReturn<AllocationForm>
  formCalculation: UseFormReturn<CalculationForm>
  disabled?: boolean
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
  claimDecision: {
    value: string
    label: string
  } | null
}

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#F4F4F4',
    borderColor: '#D3DCE6'
  },
  tableBody: {
    backgroundColor: '#FAFAFA'
  },
  tableStyle: {
    width: 'max-content',
    overflowX: 'auto'
  },
  tableHeaderText: {
    fontWeight: 'bold',
    color: '#70777E'
  }
})

export const Allocation: React.FC<Props> = ({
  props,
  form,
  formCalculation,
  disabled,
  setIsCanChangeTab,
  claimDecision
}) => {
  const { t, i18n } = useTranslation(['Adjudication', 'claim'])
  const { payload, decision } = props
  const classes = useStyles()
  const policyStatus = (): string => {
    let result = '-'
    if (payload?.policyRider.status === 'INFORCE') {
      result = t('Adjudication:Inforce')
    }
    return result
  }
  const { control, watch } = form

  const summaryDetail = useWatch({
    control: formCalculation.control,
    name: 'summaryDetail'
  })

  const allocationDetailForm = useFieldArray<AllocationForm>({
    control,
    name: 'allocation'
  }).fields

  const productName = (): string => {
    let result = '-'
    const rider = payload?.policyRider?.productOptions.map((item) => item.productMainCode + ' ' + item.productMainName)
    if (rider.length > 0) {
      result = rider[0]
    }
    return result
  }

  const allocationInformation = [
    {
      label: t('claim:PolicyNumber'),
      value: payload?.policyRider?.policyNo || '-'
    },
    {
      label: t('claim:EffectiveDate'),
      value: payload?.policyRider?.effectiveDate
        ? moment(payload?.policyRider?.effectiveDate).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('claim:PolicyStatus'),
      value: t(`claim:${payload?.policyRider.status}`),
      isHighlightWarning: payload?.policyRider.status !== 'INFORCE',
      bold: true
    },
    {
      label: t('claim:ProductName'),
      value: productName()
    },
    {
      label: t('claim:ClaimNumber1'),
      value: payload.claimNumber
    },
    {
      label: t('Adjudication:ClaimDecision'),
      value: (
        <Select
          showUnderline={false}
          disabled
          onChange={(val: SelectOption | null) => {
            setIsCanChangeTab(false)
          }}
          value={claimDecision}
          options={decision[0]}
        />
      )
    },
    {
      label: t('Adjudication:TotalNetPayableAmount'),
      value: (
        <Text style={{ color: 'red', fontWeight: 'bold' }}>
          {summaryDetail?.totalNetPayableAmount ? summaryDetail.totalNetPayableAmount : 0 + ' VNĐ'}
        </Text>
      )
    }
  ]

  return (
    <SafeAreaView>
      <View>
        <Panel
          title={`${t('Adjudication:PolicyInformation')} - ${payload?.policyRider?.policyNo || '-'}`}
          isExand={true}
        >
          <FieldList dataSource={allocationInformation} />
          <View style={{ marginTop: 30 }}>
            <TableContainer component={Paper}>
              <Table className={classes.tableStyle}>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableHeaderText} style={{ width: 300 }}>
                      {t('Adjudication:BenefitName')}
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>{t('claim:PayableAmount')}</TableCell>
                    <TableCell className={classes.tableHeaderText}>{t('Adjudication:Schedule')}</TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      {t('Adjudication:LumpsumPayDate1stInstallmentDate')}
                    </TableCell>
                    <TableCell className={classes.tableHeaderText}>{t('Adjudication:PaymentFrequency')}</TableCell>
                    <TableCell className={classes.tableHeaderText}>
                      {t('Adjudication:TotalInstallmentNumber')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {allocationDetailForm.map((data, index) => (
                    <React.Fragment key={data.id}>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Controller
                            name={`allocation.${index}.componentCode` as 'allocation.0.componentCode'}
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <Input
                                disabled
                                onBlur={onBlur}
                                onChange={onChange}
                                inputStyle={{
                                  fontWeight: 'bold'
                                }}
                                value={`${
                                  i18n.language === 'en'
                                    ? watch(`allocation.${index}.componentEN`)
                                    : watch(`allocation.${index}.componentVN`)
                                }`}
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      {form.watch(`allocation.${index}.benefitClaimCode`)?.map((item, key) => {
                        // const isDeclineDecision = ['DS02', 'DS03', 'DS05'].includes(
                        //   allocation[index].benefitClaimCode[key].adjudicatorDecision?.value || ''
                        // )
                        return (
                          <TableRow key={item.id}>
                            <TableCell component="th" scope="row">
                              <Controller
                                name={
                                  `allocation.${index}.benefitClaimCode.${key}.benefitClaimCode` as 'allocation.0.benefitClaimCode.0.benefitClaimCode'
                                }
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                  <Input
                                    disabled
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={`${watch(`allocation.${index}.benefitClaimCode.${key}.benefitClaimCode`)} ${
                                      i18n.language === 'en'
                                        ? watch(`allocation.${index}.benefitClaimCode.${key}.benefitClaimEN`)
                                        : watch(`allocation.${index}.benefitClaimCode.${key}.benefitClaimVN`)
                                    }`}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={
                                  `allocation.${index}.benefitClaimCode.${key}.paymentAmount` as 'allocation.0.benefitClaimCode.0.paymentAmount'
                                }
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                  <Input
                                    disabled
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={formatNumberWithComma(value || '0')}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={
                                  `allocation.${index}.benefitClaimCode.${key}.schedule` as 'allocation.0.benefitClaimCode.0.schedule'
                                }
                                control={control}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                  <SelectSearch
                                    popupIcon={<></>}
                                    disableUnderline={true}
                                    inputStyle={[style.selectStyle, { minWidth: 300 }]}
                                    errorMessage={error?.message}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    options={decision[1] || []}
                                    placeholder={t('common:Select')}
                                    disabled
                                    key={value?.value}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: '150px' }}>
                              <Controller
                                name={
                                  `allocation.${index}.benefitClaimCode.${key}.lumpsumPayDateInstallmentDate` as 'allocation.0.benefitClaimCode.0.lumpsumPayDateInstallmentDate'
                                }
                                control={control}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                  <DatePickerTable
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e)
                                      setIsCanChangeTab(false)
                                    }}
                                    onBlur={onBlur}
                                    styles={{ backgroundColor: '#FFF', padding: 13 }}
                                    errorMessage={error?.message}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={
                                  `allocation.${index}.benefitClaimCode.${key}.paymentFrequency` as 'allocation.0.benefitClaimCode.0.paymentFrequency'
                                }
                                control={control}
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                  <SelectSearch
                                    popupIcon={<></>}
                                    disableUnderline={true}
                                    disabled
                                    inputStyle={[style.selectStyle, { minWidth: 150 }]}
                                    errorMessage={error?.message}
                                    onChange={(e) => {
                                      onChange(e)
                                      setIsCanChangeTab(false)
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    options={decision[2] || []}
                                    placeholder={t('common:Select')}
                                    key={value?.value}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Controller
                                name={
                                  `allocation.${index}.benefitClaimCode.${key}.totalInstallmentNumber` as 'allocation.0.benefitClaimCode.0.totalInstallmentNumber'
                                }
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <InputTable
                                    value={value + ''}
                                    onChange={(val: string) => {
                                      const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      onChange(newVal)
                                      setIsCanChangeTab(false)
                                    }}
                                    errorMessage={error?.message}
                                    inputStyle={{ borderColor: '#D3DCE6' }}
                                    maxLength={3}
                                    disabled
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </React.Fragment>
                  ))}
                  {allocationDetailForm.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <View>
                          <Text style={{ textAlign: 'center' }}>{t('common:Nodata')}</Text>
                        </View>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </View>
        </Panel>
      </View>
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800',
    paddingTop: 14,
    textTransform: 'uppercase'
  },
  sectionInfo: {
    width: '100%',
    padding: 20,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 18
  },
  btnTitle: {
    fontSize: 15,
    marginVertical: 5,
    marginHorizontal: 29
  },
  selectStyle: {
    borderColor: '#BABABA',
    backgroundColor: '#FFF',
    borderWidth: 1,
    padding: 5,
    borderRadius: 8
  }
})
