import * as React from 'react'
import { ModalComponent, ModalComponentRef, ModalAction, assets } from '@pulseops/common'
import { ScrollView, StyleSheet, View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Warning } from '@pulseops/submission/common'

interface Props {
  visible: boolean
  onClose: () => void
  onConfirm: () => void
  detail: {
    benefitName: string
    benefitClaimAmount: number
    sumAssured: string
    duration: number
    benefitClaimType: string
    benefitClaimFormula: string
    declineVN: string
    declineEN: string
    declineCode: string
  }
}
const formatNumber = (number: string) => {
  return number.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const BenefitClaimAmountFomulaDetailPopup = ({ visible, onClose, onConfirm, detail }: Props) => {
  const { t, i18n } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const onPressClose = () => {
    onClose()
  }
  const modalActions: ModalAction[] = []
  const benefitClaimFormula = React.useMemo(() => detail?.benefitClaimFormula.split(' '), [detail])
  return (
    <ModalComponent
      ref={modalRef}
      title={`${t('claim:BenefitClaimAmountFormula')} - ${t(
        `claim:${detail.benefitClaimType === 'Other' ? 'Percentage' : detail.benefitClaimType}`
      )}`}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      // modalWidth={Math.min(width * 0.9, 1024)}
      // modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <View
          style={{
            flex: 1,
            paddingHorizontal: 30
          }}
        >
          {detail.declineCode && (
            <View
              style={{
                flexDirection: 'row',
                marginTop: 30,
                backgroundColor: '#FCF6E6',
                width: '100%',
                padding: 10,
                borderRadius: 5,
                alignItems: 'center'
              }}
            >
              <assets.WarningCircle style={{ marginRight: 10 }} />
              <Warning
                message={i18n.language === 'en' ? detail.declineEN : detail.declineVN}
                style={{ fontSize: 16 }}
              />
            </View>
          )}
          <View style={{ flexDirection: 'row', marginTop: 30 }}>
            <View style={{ flex: 1, marginEnd: 30 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t('Adjudication:BenefitName')}
                </Text>
              </View>
              <Text>{detail.benefitName}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                {t('claim:BenefitClaimAmountFormula')}
              </Text>
              <Text>{`${detail?.benefitClaimFormula.replace(
                benefitClaimFormula[0],
                formatNumber(benefitClaimFormula[0])
              )}${detail?.benefitClaimType === 'Other' ? '%' : ''} = ${formatNumber(
                detail?.benefitClaimAmount.toString()
              )}`}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 30 }}>
            <View style={{ flex: 1, marginEnd: 30 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t('claim:SumAssured')}
                </Text>
              </View>
              <Text>{formatNumber(detail.sumAssured.toString())}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }]}>
                  {t(
                    detail.benefitClaimType === 'Other'
                      ? 'claim:Percentage'
                      : detail.benefitClaimType === 'ICU'
                      ? 'claim:DurationICU'
                      : 'claim:DurationHospitalization'
                  )}
                </Text>
              </View>
              <Text>
                {detail.duration}
                {detail.benefitClaimType === 'Other' ? '%' : ' days'}
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#fff'
  },

  container: {
    paddingHorizontal: 30
  }
})
