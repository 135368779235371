import {
  AppContext,
  formatNumberWithComma,
  GeneralService,
  PayoutPopup,
  PruKid369Model,
  PruKid369Service,
  // StorageBlob,
  TaskType,
  TransactionFieldList,
  TransactionType
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text } from 'react-native'
import { ZIO } from '@mxt/zio'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../payout-method'
import { RequestAuthenticateData } from '../../request-authen'
// import moment from 'moment'
import { useLoading } from '@mxt/zio-react'

export const PruKid369: React.FC<PolicyServiceProps<PruKid369Model.SubmitData>> = ({
  initSubmission,
  isConfirmed,
  policyNumber,
  officeCode
}) => {
  const { t } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [detailData, setDetailData] = React.useState<PruKid369Model.Detail>()
  const [payoutData, setPayoutData] = React.useState<PayoutPopup.Summary[]>([])
  const [messageFlag1, setMessageFlag1] = React.useState<boolean>(false)
  const [messageFlag2, setMessageFlag2] = React.useState<boolean>(true)
  const payoutRef = React.useRef<PayoutMethodRef | null>(null)
  const [loading, bindLoader] = useLoading(false)

  const formatCurrency = (value: number | string | undefined) => {
    return !!value ? formatNumberWithComma(value) : '0'
  }

  const pruKidFields = [{ label: t('Prukid:PrukidAmount'), value: `${formatCurrency(detailData?.prukidAmount)} VND` }]
  const allowedPayoutMethods: PayoutPopup.PayoutMethods[] = [
    PayoutPopup.PayoutMethods.CASHLESS,
    PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
    PayoutPopup.PayoutMethods.PAID_AT_BANK,
    PayoutPopup.PayoutMethods.BANKTRANSFER,
    // PayoutPopup.PayoutMethods.MOMO
    // PayoutPopup.PayoutMethods.PAID_AT_BANK, PayoutPopup.PayoutMethods.MOMO,
    // PayoutPopup.PayoutMethods.REPAY_OPL
  ]

  React.useEffect(() => {
    // if (!detailData) {
    // console.log('detailData: 1')
    showGlobalLoading(true)
    changePayoutData([])
    pipe(
      PruKid369Service.getDetail(policyNumber ?? ''),
      ZIO.map((responseData) => {
        if (!!responseData) {
          setDetailData({ prukidAmount: responseData.prukidAmount })
        }
      }),
      ZIO.tap((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
    // }
    return () => {
      setPayoutData([])
    }
  }, [policyNumber])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const changePayoutData = (data: PayoutPopup.Summary[]) => {
    setPayoutData([])
    if (data && data.length > 0) {
      setPayoutData(data)
      const sum = data.reduce((sum, x) => sum + x.amount, 0)
      setMessageFlag1(sum !== detailData?.prukidAmount && sum !== 0)
      setMessageFlag2(sum === 0)
    } else {
      setMessageFlag1(false)
      setMessageFlag2(true)
    }
  }
  const totalPayoutAmount = () => {
    return payoutData.reduce((total, next) => total + Number(next.amount), 0)
  }
  const isRequestValid = () => {
    const flag = payoutData.length > 0 && !messageFlag1 && !messageFlag2
    return flag
  }

  // const getCashOutData = () => {
  //   const cashOutData = payoutData.map((item) => {
  //     const payee = item.payee
  //     const bankItem: PruKid369Model.CashOutOptions = {
  //       type: item.method,
  //       paymentOption: {
  //         currency: 'VND',
  //         amount: String(item.amount)
  //       },
  //       bankAccount:
  //         item.method === 'CASH_AT_COUNTER'
  //           ? {
  //               bankCode: item?.officeBankCode ?? ''
  //             }
  //           : item.method === 'PAID_AT_BANK'
  //           ? {
  //               bankCode: item.bankCode ?? '',
  //               bankName: item.bankName ?? '',
  //               branchCode: item.bankBranchCode ?? '',
  //               branchName: item.bankBranchName ?? ''
  //             }
  //           : item.bankAccountNum && item.bankName
  //           ? {
  //               accountName: String(item.bankAccountName),
  //               accountNo: item.bankAccountNum ?? '',
  //               bankName: item.bankName ?? '',
  //               branchCode: String(item.bankBranchCode)
  //             }
  //           : null,
  //       attributesExt: ['CASH_AT_COUNTER', 'PAID_AT_BANK','BANKTRANSFER'].includes(item.method)
  //         ? {
  //             PVA_CASH_OUT_OPTION_INFO:
  //               item.method === 'CASH_AT_COUNTER'
  //                 ? {
  //                     officeCode: item.officeCode ?? '',
  //                     officeType: item.officeType ?? ''
  //                   }
  //                 : undefined,
  //             PAYEE_INFO: {
  //               paymentMethod: payee?.paymentMethod ?? '',
  //               isNewClient: payee?.isNewClient ?? false,
  //               isPayeeNotPO: payee?.isPayeeNotPO ?? false,
  //               dob: payee?.dob ?? '',
  //               gender: payee?.gender ?? '',
  //               phone: payee?.phone ?? '',
  //               email: payee?.email ?? '',
  //               salutation: payee?.salutation ?? '',
  //               married: payee?.married ?? '',
  //               occupation: payee?.occupation ?? '',
  //               relationshipWithPO: payee?.relationshipWithPO ?? '',
  //               nationality: payee?.nationality ?? '',
  //               address: payee?.address ?? '',
  //               nationality2: payee?.nationality2 ?? '',
  //               foreignAddress: payee?.foreignAddress ?? '',
  //               foreignCountry: payee?.foreignCountry ?? '',
  //               residenceCountry: payee?.residenceCountry ?? '',
  //               name: payee?.name ?? '',
  //               idNumber: payee?.idNumber ?? '',
  //               dod: payee?.dod ?? '',
  //               payeeName: payee?.name ?? '',
  //               issuedDate: !!payee?.issuedDate ? moment(payee?.issuedDate).format('YYYYMMDD') : '',
  //               issuedBy: payee?.issuedBy ?? '',
  //               surName: payee?.surName ?? '',
  //               giveName: payee?.giveName ?? '',
  //               city: payee?.city ?? '',
  //               district: payee?.district ?? '',
  //               ward: payee?.ward ?? '',
  //               street: payee?.street ?? ''
  //             }
  //           }
  //         : null,
  //       policy: {
  //         policyNo: String(item.policyNum),
  //         totalPremium: String(item.totalPremium ? item.totalPremium : item.amount),
  //         status: ''
  //       }
  //     }
  //     return bankItem
  //   })
  //   return cashOutData
  // }

  const mappSubmitedData = (): PruKid369Model.SubmitData => {
    // const cashOutData = getCashOutData()
    return {
      policy: {
        policyNo: policyNumber ?? ''
      },
      attributesExt: {
        PRU_KID_AMOUNT: detailData?.prukidAmount ?? 0,
        TOTAL_PAYOUT_AMOUNT: totalPayoutAmount()
      },
      // cashOutOptions: cashOutData
      cashOutOptions: mapCashOutOption(payoutData)
    }
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(payoutData),
      transactionType: TransactionType.PRUKID369,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isValid = isRequestValid()
      // console.log(`Prukid isValid : `+isValid)
      if (isValid) {
        return {
          url: (policyNum: string) => `wf-api/policy/${policyNum}/pru-kid`,
          body: mappSubmitedData(),
          transaction: TransactionType.PRUKID369,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PRUKID369),
          collerationId: policyNumber ?? '',
          // documents: uploadedFiles,
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      } else {
        return false
      }
    },
    clear: () => {
      payoutRef.current?.clearData()
    }
  })

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <Text style={prukidStyles.headerField}>{t('Prukid:PRUKIDINFO')}</Text>
      <TransactionFieldList dataSource={pruKidFields}></TransactionFieldList>
      <PayoutMethod
        transactionType={TransactionType.PRUKID369}
        value={payoutData}
        policyNum={policyNumber ?? ''}
        methods={allowedPayoutMethods}
        maxAmount={detailData?.prukidAmount}
        onChange={changePayoutData}
        editable={!isConfirmed}
        ref={payoutRef}
        officeCode={officeCode}
      />
      {messageFlag1 && <Text style={prukidStyles.error_message}>{t('message:MS050170')}</Text>}
      {messageFlag2 && <Text style={prukidStyles.error_message}>{t('message:MS050241')}</Text>}
    </SafeAreaView>
  )
}
const prukidStyles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  error_message: {
    color: '#ed1c2e'
  }
})
