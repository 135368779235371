// React Lib
import React, { createRef, forwardRef, LegacyRef, Ref, useRef, useState, useImperativeHandle } from 'react'
import { TFunction } from 'i18next'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View } from 'react-native'

// Common
import { assets } from '@pulseops/common'
import { ChangeNationalIDCardService } from '../change-national-id-card-service'
import {
  CallBackValidateUploadFileType,
  COLORS,
  FILE_ERROR,
  GroupCitizenI,
  STATUS
} from '../change-national-id-card.const'
import { CN, TextCustom } from '../change-national-id-card.style'
import UploadFileCitizen, { DocumentType, EventType } from '../UploadFile/upload-file'

const {
  Modal: { BoxGroup }
} = CN

const createGroupCitizenUploads = ({ group, content, url = '', isUpload = false, fileUpload }: GroupCitizenI) => {
  return {
    group,
    content,
    url,
    isUpload,
    fileUpload
  }
}

const defaultGroupCitizenUploads = (t: TFunction): GroupCitizenI[] => [
  createGroupCitizenUploads({
    group: 1,
    content: { title: t('MODAL_UPLOAD_CITIZEN.FRONT_SIDE'), action: t('MODAL_UPLOAD_CITIZEN.UPLOAD_PICTURE') }
  }),
  createGroupCitizenUploads({
    group: 2,
    content: { title: t('MODAL_UPLOAD_CITIZEN.BACK_SIDE'), action: t('MODAL_UPLOAD_CITIZEN.UPLOAD_PICTURE') }
  })
]

export interface UploadCitizenRef {
  groupCitizenUploads: GroupCitizenI[]
  setGroupCitizenUploads: React.Dispatch<React.SetStateAction<GroupCitizenI[]>>
  validateUploadFile: (callBack: ({ status, typeError, message }: CallBackValidateUploadFileType) => void) => void
  defaultGroupCitizenUploads: GroupCitizenI[]
}

interface Props {
  stepModal: 1 | 2
}

const UploadImageCitizen = ({ stepModal }: Props, ref: Ref<UploadCitizenRef>) => {
  const isStep1 = stepModal === 1
  const { t: translate_change_nationalID } = useTranslation('CHANGE_NATIONALID_CARD_PS')
  const [groupCitizenUploads, setGroupCitizenUploads] = useState<GroupCitizenI[]>(() =>
    defaultGroupCitizenUploads(translate_change_nationalID)
  )
  const inputRefs = useRef<LegacyRef<HTMLInputElement> | any>(Array(groupCitizenUploads.length).fill(createRef()))

  const validateUploadFile = (callBack: ({ status, typeError, message }: CallBackValidateUploadFileType) => void) => {
    const groups = [...groupCitizenUploads]

    const isEnoughFiles = groups.every((elm) => elm.isUpload)
    const isLargeFileSize = groups
      ?.map((elm) => ChangeNationalIDCardService.bytesToMegabyte(elm.fileUpload?.size || 0) || 0)
      ?.some((size) => size > 10)

    if (!isEnoughFiles) {
      return callBack({
        status: STATUS['ERROR'],
        typeError: FILE_ERROR['MISSING_FILE'],
        message: translate_change_nationalID('ERROR.ENOUGH_UPLOAD_FILE')
      })
    }

    if (isLargeFileSize) {
      return callBack({
        status: STATUS['ERROR'],
        typeError: FILE_ERROR['LARGE_FILE_SIZE'],
        message: translate_change_nationalID('message:MS040004')
      })
    }

    return callBack({ status: STATUS['SUCCESS'], message: '' })
  }

  useImperativeHandle(
    ref,
    () => ({
      groupCitizenUploads,
      setGroupCitizenUploads,
      defaultGroupCitizenUploads: defaultGroupCitizenUploads(translate_change_nationalID),
      validateUploadFile
    }),
    [JSON.stringify(groupCitizenUploads)]
  )

  const handleUploadFile = (e: EventType, index: number) => {
    const { onChange } = inputRefs.current?.[index]?.current ?? {}
    const groupCitizenUploadsClone = [...groupCitizenUploads]

    return onChange(e, ({ fileUpload, url, groupCitizen }: DocumentType) => {
      if (!groupCitizen) return
      const { content, group } = groupCitizen

      const groupCitizenUploadsFuture = ChangeNationalIDCardService.replaceArrayObject<GroupCitizenI>({
        array: groupCitizenUploadsClone,
        typeKey: 'group',
        typeValue: group,
        data: {
          isUpload: true,
          url,
          content: {
            ...content,
            action: translate_change_nationalID('MODAL_UPLOAD_CITIZEN.CHANGE_PICTURE')
          },
          fileUpload
        }
      })
      return setGroupCitizenUploads([...(groupCitizenUploadsFuture as GroupCitizenI[])])
    })
  }

  const renderGroupCitizenUploads = () => {
    return [...groupCitizenUploads].map((item, index) => {
      const { group, content, url } = item
      const { title, action } = content

      return (
        <BoxGroup
          key={group}
          style={{
            ...(group === 1 ? { marginRight: 40 } : {})
          }}
        >
          <View>
            {isEmpty(url) ? (
              group === 1 ? (
                <assets.ChangeNationalIDCard.ModalFrontSide />
              ) : (
                <assets.ChangeNationalIDCard.ModalBackSide />
              )
            ) : (
              <img src={url} alt={title} style={{ width: isStep1 ? 200 : 240, height: isStep1 ? 120 : 140 }} />
            )}
          </View>

          {isStep1 && (
            <>
              <TextCustom style={{ marginVertical: 8 }}>{title}</TextCustom>
              <TouchableOpacity onPress={() => inputRefs.current[index].current.openUpload(item)}>
                <UploadFileCitizen ref={inputRefs.current[index]} onUpload={(e) => handleUploadFile(e, index)}>
                  <TextCustom textColor={COLORS.ALIZARIN} style={{ textDecorationLine: 'underline' }}>
                    {action}
                  </TextCustom>
                </UploadFileCitizen>
              </TouchableOpacity>
            </>
          )}
        </BoxGroup>
      )
    })
  }
  return <>{renderGroupCitizenUploads()}</>
}

export default forwardRef(UploadImageCitizen)
