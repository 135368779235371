import React from 'react'
import _ from 'lodash'
import {
  SignatureRegistration,
  ImgUploadMutiple,
  form2,
  PulseOpsFormat,
  TransactionType,
  ErrorHandling,
  StorageBlob,
  AppContext,
  GeneralService,
  TaskType
} from '@pulseops/common'
// import moment from 'moment'
import { SC } from './style'
import { ZIO } from '@mxt/zio'
import { View } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { PolicyServiceProps } from '../policy-service-props'
import { UseFormReturn, Controller } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { SignatureRegistrationForm } from './signature-registration-form'

type Props = PolicyServiceProps<SignatureRegistration.SubmitData> & {
  policyNumber: string
  officeCode?: string
  eformData?: RequestAuthenticateData.EformRequestAuthenticateData
  clientId: string
  isConfirmed: boolean
  optionFlag: string
}

export const SignatureRegistrationScreen: React.FC<Props> = ({
  policyNumber,
  initSubmission,
  isConfirmed,
  officeCode,
  eformData,
  clientId,
  optionFlag
}) => {
  const { base: form } = form2.useForm(SignatureRegistrationForm.codec, {
    defaultValues: {
      listFile: []
    }
  })
  const { showGlobalLoading, changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { t } = useTranslation()
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigation.navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:SIGNATURE_REGISTRATION'), navigate: null }
      ])
    }
  }, [isFocused])

  const getSubmitedData = (files: StorageBlob.FileContentSubmit[]) => {
    const data = {
      optionFlag,
      clientId,
      office: officeCode!!,
      documents: files
        ? files.map((fileInfo) => {
            return {
              name: fileInfo.name,
              url: fileInfo.url
            }
          })
        : []
    }
    return data
  }

  initSubmission({
    validate: async (isContinue) => {
      await form.trigger()
      if (form.formState.isValid) {
        // const metaDataRespone = eformData
        // const batchno = `${moment().format('DD/MM/YYYY')}-PSR-${officeCode}`
        // const metaDataRaw: StorageBlob.MetaDataUpload = {
        //   type: metaDataRespone?.metaData?.type ?? '',
        //   doctype: metaDataRespone?.metaData?.doctype ?? '',
        //   class: metaDataRespone?.metaData?.class ?? '',
        //   docid: metaDataRespone?.metaData?.docid ?? '',
        //   maindoc: metaDataRespone?.metaData?.maindoc ?? '',
        //   subdoc: metaDataRespone?.metaData?.subdoc ?? '',
        //   polnum: policyNumber!!,
        //   batchno
        // }
        // const metaDataFiles = form.getValues().listFile.map((x) => {
        //   return {
        //     file: x.file!!,
        //     metaData: metaDataRaw
        //   }
        // })
        return pipe(
          !!isContinue
            ? ZIO.succeed([])
            : GeneralService.getAzureStorageFiles(
                form.getValues().listFile as SignatureRegistrationForm.FileMeta[],
                TransactionType.SIGNATURE_REGISTRATION,
                'DPS17',
                TaskType.PolicyService,
                policyNumber ?? '',
                officeCode ?? ''
              ),
          // !!isContinue ? ZIO.succeed([]) : StorageBlob.uploadToSubmit('PS', policyNumber)(metaDataFiles),
          ZIO.map((files) => {
            const submitedData = getSubmitedData(files)
            return {
              url: () => `wf-api/policy/${policyNumber}/signature-registration`,
              body: submitedData,
              transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.SIGNATURE_REGISTRATION),
              collerationId: policyNumber ?? '',
              transaction: TransactionType.SIGNATURE_REGISTRATION,
              documents: files
            }
          }),
          ZIO.tapError((_) => {
            showToast(t('message:MS050001'), 'error')
            return ZIO.unit
          }),
          bindLoader,
          ErrorHandling.run({})
        )
      }
      return false
    },
    clear: () => {
      form.reset({ listFile: [] })
    }
  })

  if (isConfirmed) {
    return <ConfirmTab form={form.getValues()} />
  }
  return <RequestTab form={form} />
}

const RequestTab: React.FC<{
  form: Omit<UseFormReturn<SignatureRegistrationForm.Raw>, 'handleSubmit'>
}> = ({ form }) => {
  const {
    control,
    formState: { errors }
  } = form

  const { t } = useTranslation()

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:SignatureRegistrationInformation')}</SC.SessionText>
      </SC.Padding>
      <Controller
        control={control}
        name={'listFile'}
        render={({ field: { value, onChange } }) => {
          const mapValue = value.map((file) => ({ ...file, uploadedDate: file.uploadedDate!!, file: file.file!! }))
          return (
            <SC.PanelContainer>
              <SC.TitleText>
                {t('submission:HERED_FileAttachment')} <SC.ErrorText>*</SC.ErrorText>
              </SC.TitleText>
              <ImgUploadMutiple value={mapValue} onChange={onChange} errorMessage={_.get(errors, 'listFile.message')} />
              {errors.listFile && <SC.ErrorText>{_.get(errors, 'listFile.message')}</SC.ErrorText>}
            </SC.PanelContainer>
          )
        }}
      />
    </SC.Container>
  )
}

const ConfirmTab: React.FC<{ form: SignatureRegistrationForm.Raw }> = ({ form }) => {
  const { t } = useTranslation()
  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:SignatureRegistrationInformation')}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <SC.TitleText>{t('utilities:FileAttachment')}</SC.TitleText>
        <SC.Row>
          {form.listFile.map((file, index) => {
            return (
              <SC.Padding key={`list_file_thumb_${index}`} vertical={5} right={10}>
                <View style={{ width: 200, marginRight: 20 }}>
                  <SC.BottomDashContainer>
                    <SC.Padding vertical={10} right={10}>
                      <SC.Row>
                        <SC.ImageThumb source={{ uri: file.base64 }} />
                        <SC.Divider />
                        <SC.Colum>
                          <SC.NormalText>{file.fileName}</SC.NormalText>
                          <SC.ItalicText>
                            {PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD/MM/YYYY HH:mm')}
                          </SC.ItalicText>
                        </SC.Colum>
                        <SC.Divider />
                      </SC.Row>
                    </SC.Padding>
                  </SC.BottomDashContainer>
                </View>
              </SC.Padding>
            )
          })}
        </SC.Row>
      </SC.PanelContainer>
    </SC.Container>
  )
}
