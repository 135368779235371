import React from 'react'
import { View } from 'react-native'
import { ModalComponent } from '@pulseops/common'
import { TextField } from '@material-ui/core'
import { IBGeneralTable } from '../../../../../common'

type IBModalData = {
  title: string
  content?: string | string[]
  collumnDatable?: {
    label: string
    field: string
    format?: string
    disabled?: boolean
    visible?: boolean
    render?: ((val: string, index: number) => string | JSX.Element)
  }[]
  dataShow?: Object[]
  onClose: () => void
  onSuccess?: () => void
  open: boolean
  wrap?: boolean
}

export const IBDataDetailModal = (props: IBModalData) => {
  return (
    <ModalComponent
      title={props.title}
      modalWidth={790}
      onClose={props.onClose}
      visible={props.open}
      actions={[]}
    >
      <View style={{ marginHorizontal: 20 }}>
        {props.collumnDatable && props.dataShow ?
          <IBGeneralTable
            dataTable={props.collumnDatable}
            data={props.dataShow}
            maxWidth={750}
            wrap={props.wrap}
          /> :
          <TextField
            value={props.content ?? ''}
            fullWidth
            multiline
            InputProps={{
              readOnly: true,
              disableUnderline: true
          }}
          />
        } 
      </View>
    </ModalComponent>
  )
}