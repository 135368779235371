import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { LoanStatementsService } from './loan-statement-service'
import { LoanStatementsData } from './model'

export namespace ConfirmationLetterService {
  export const getAccessData = (policyNum: string): Task<LoanStatementsData.AccessData> =>
    pipe(
      LoanStatementsService.accessData(policyNum),
      ZIO.map((response) => {
        return response
      })
    )

  // export const sumbitData = (policyNum: string, data: ConfirmationLetterData.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
  //   SubmissionService.submit(t.unknown)(`wf-api/policy/${policyNum}/confirmation-letter/account-and-surrender-value`,
  //     { body: data }, policyNum, requestauth, [])
}
