import { POApi } from "@pulseops/common"
import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'

export namespace OBOnGoingRequestService {

  export interface OGRModel {
    caseId: string
    transactionType: string
    // claimNumber: string
    createdDate: string
    activity: string
    status: string
    assignee: string
    source: string
  }


  export const OBORGResponseC = t.type({
    activity: t.string,
    assignee: Maybe(t.string),
    businessKey: Maybe(t.string),
    claimNumber: Maybe(t.string),
    createdDate: t.string,
    id: t.string,
    source: t.string,
    status: t.string,
    transactionType: t.string,
    policyNumber: Maybe(t.string),
    lastAssignee: Maybe(t.string),
  })

  export type ORGResponse = t.TypeOf<typeof OBORGResponseC>

  export const OBORGCodex = t.type({
    data: Maybe(t.array(OBORGResponseC)),
    order: t.string,
    size: t.number,
    sort: t.string,
    start: t.number,
    total: t.number
  })

  export type OBORG = t.TypeOf<typeof OBORGCodex>

  export const GetOGR = (
    policyNum: string,
    pagination: {
      pageSize: number
      pageNum: number
    },
    sort: {
      order: string
      field: string
    },
    categoryCode: string
  ): Task<OBORG> =>
    pipe(
      POApi.post(`pulseops/api/v1/query/on-going-request`)(OBORGCodex)({
        policyNum,
        order: sort.order,
        sort: sort.field,
        categoryCode: categoryCode,
        size: pagination.pageSize,
        start: pagination.pageNum * pagination.pageSize
      })
    )
}