import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  AuthState,
  CanView,
  ErrorHandling,
  InquiryComplaintService,
  MyTaskPermissions,
  Permission,
  RBAC,
  TaskService,
  TeamTaskPermissions
} from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { BIG_SCREEN_SIZE } from './common'
import { MyTaskTab } from './MyTaskTab'
import { TabView } from './TabView'
import { TeamTaskTab } from './TeamTaskTab'
interface Props extends DrawerContentComponentProps<DrawerContentOptions> {}

export const TaskDashboardScreen = ({ navigation }: Props) => {
  const { width } = useWindowDimensions()
  const { t, i18n } = useTranslation('TaskManagement')
  const ht = useTranslation('home').t
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const [tabActiveIndex, setTabActiveIndex] = React.useState(0)

  // const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const authData = pipe(AuthState.auth.get)
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidUpdate([authData])) || []
 

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: ht('TaskManagement'), navigate: null }
      ])
    }
  }, [isFocused])

  // const hasMyTaskTab = () => {
  //   console.log('asdasdasdas')
  //   return (
  //     permissions.find((item) => Object.values(MyTaskPermissions.CH).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(MyTaskPermissions.CL).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(MyTaskPermissions.CS).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(MyTaskPermissions.PS).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(MyTaskPermissions.UW).includes(item as Permission)) !== undefined ||
  //     permissions.includes(MyTaskPermissions.ViewErrorHandlerMyTask)
  //   )
  // }

  const hasMyTaskTab = React.useMemo(() => {
    return (
      permissions.find((item) => Object.values(MyTaskPermissions.CH).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.DS).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.CL).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.CS).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.PS).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.UW).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.PC).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(MyTaskPermissions.IDC).includes(item as Permission)) !== undefined ||
      permissions.includes(MyTaskPermissions.ViewErrorHandlerMyTask)
    )
  }, [permissions])

  // const hasTeamTaskTab = () => {
  //   return (
  //     permissions.find((item) => Object.values(TeamTaskPermissions.CH).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(TeamTaskPermissions.CL).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(TeamTaskPermissions.CS).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(TeamTaskPermissions.PS).includes(item as Permission)) !== undefined ||
  //     permissions.find((item) => Object.values(TeamTaskPermissions.UW).includes(item as Permission)) !== undefined ||
  //     permissions.includes(TeamTaskPermissions.ViewErrorHandlerTeamTask)
  //   )
  // }

  const hasTeamTaskTab = React.useMemo(() => {
    return (
      permissions.find((item) => Object.values(TeamTaskPermissions.CH).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.DS).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.CL).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.CS).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.PS).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.UW).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.PC).includes(item as Permission)) !== undefined ||
      permissions.find((item) => Object.values(TeamTaskPermissions.IDC).includes(item as Permission)) !== undefined ||
      permissions.includes(TeamTaskPermissions.ViewErrorHandlerTeamTask)
    )
  }, [permissions])

  const { subServiceTypeList, subTransactionTypeList } = pipe(
    ZIO.zipPar(InquiryComplaintService.getTypesFromCachedApi(), InquiryComplaintService.getSubTypeFromCachedApi()),
    ZIO.map(([typeList, subTypeArr]) => {
      const subServiceTypeList = typeList.slice().map((x) => ({
        label: i18n.language === 'en' ? x.name : x.nameVi,
        value: x.code
      }))
      const subTransactionTypeList = subTypeArr.slice().map((x) => ({
        label: i18n.language === 'en' ? x.name : x.nameVi,
        value: x.code,
        slaStandard: x.slaStandard ?? ''
      }))
      return { subServiceTypeList, subTransactionTypeList }
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  ) || {
    subServiceTypeList: [],
    subTransactionTypeList: []
  }

  const getTabScenes = () => {
    const tabs = []
    if (hasMyTaskTab) {
      tabs.push({
        title: t('MyTasks'),
        Component: <MyTaskTab active={tabActiveIndex === tabs.length} permissions={permissions} subServiceTypeList={subServiceTypeList} subTransactionTypeList={subTransactionTypeList}/>
      })
    }

    if (hasTeamTaskTab) {
      tabs.push({
        title: t('TeamTasks'),
        Component: <TeamTaskTab active={tabActiveIndex === tabs.length} permissions={permissions} subServiceTypeList={subServiceTypeList} subTransactionTypeList={subTransactionTypeList}/>
      })
    }

    return tabs
  }

 

  React.useEffect(() => {
    if (hasTeamTaskTab) {
      pipe(TaskService.countTeamTasks, ZIO.unsafeRun({}))
    }
  }, [hasTeamTaskTab])

  return (
    <View style={styles.container}>
      <CanView condition={width < BIG_SCREEN_SIZE}>
        <View style={styles.FABContainer}>
          <TouchableOpacity activeOpacity={0.7} style={styles.timeFAB}>
            <assets.TimeCircleIcon />
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.7} style={styles.calendarFAB}>
            <assets.CalenderIcon />
          </TouchableOpacity>
        </View>
      </CanView>
      <View style={styles.bottomBox}>
        <TabView
          initTabIndex={0}
          containerStyle={styles.kanbanBoard}
          onTabChanged={setTabActiveIndex}
          scenes={getTabScenes()}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F9F9F9',
    paddingTop: 16
  },

  bottomBox: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },

  kanbanBoard: {
    flex: 1
  },

  FABContainer: {
    position: 'absolute',
    bottom: 38,
    end: 34,
    zIndex: 4
  },

  timeFAB: {
    width: 55,
    height: 55,
    borderRadius: 55,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderColor: '#E5EAEF',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3
  },

  calendarFAB: {
    width: 55,
    height: 55,
    borderRadius: 55,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ED1B2E',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3
  }
})
