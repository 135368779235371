import React from 'react'
import { ScrollView, Text, View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { LinearProgress } from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'
import { CallOutDetailContext, OBHistoryType, OBTaskService } from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'

export const OBHistory = () => {
  const { t } = useTranslation()
  const [loading, bindLoading] = useLoading(false)
  const [rows, setRows] = React.useState<OBHistoryType[]>([])
  const { processInstanceId } = React.useContext(CallOutDetailContext)
  const autoBot = 'Automation Bot'
  React.useEffect(() => {
    if(processInstanceId) {
      pipe(
        OBTaskService.getHistory(processInstanceId),
        ZIO.map((res) => {
          if(res && res.length > 0) setRows(res)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [processInstanceId])

  return (
    <ScrollView style={styles.container}>
      {loading && <LinearProgress color="secondary" />}
      <View style={{ borderColor: '#D3DCE6', backgroundColor: '#fafafa', borderRadius: 8, borderWidth: 1 }}>
        <View style={{ flexDirection: 'row', paddingVertical: 8, paddingHorizontal: 24 }}>
          <View style={{ width: '33.3333333333%' }}>
            <Text style={[styles.titleTable]}>{t('HistoryTab:Field')}</Text>
          </View>
          <View style={{ width: '33.3333333333%' }}>
            <Text style={[styles.titleTable]}>{t('HistoryTab:OriginalValue')}</Text>
          </View>
          <View style={{ width: '33.3333333333%' }}>
            <Text style={[styles.titleTable]}>{t('HistoryTab:NewValue')}</Text>
          </View>
        </View>
        {rows.map((row, i) => (
          <View
            key={`history-row-${i}`}
            style={{ borderTopColor: '#eee', borderTopWidth: 2, paddingHorizontal: 24, paddingVertical: 8 }}
          >
            <View style={{ width: '100%' }}>
              {row.user !== autoBot ?
                <Text style={[styles.font15]}>
                  {row.user} - (<Text style={{ color: '#ef1c2e' }}>{row.user}</Text>){' '}
                  {i === rows.length - 1 ? 'created case' : 'made changes'} - {row.createdDate} - {row.workFlowStage}
                </Text> :
                <Text style={[styles.font15]}>
                  <Text style={{ color: '#ef1c2e' }}>{row.user}</Text>{' '}
                  {i === rows.length - 1 ? 'created case' : 'made changes'} - {row.createdDate} - {row.workFlowStage}
                </Text>
              }
            </View>
            {row.fields && row.fields.map((item, j) => (
              <View style={{ width: '100%', flexDirection: 'row', marginVertical: 2.5 }} key={`history-row-data-${j}`}>
                <View style={{ width: '33.3333333333%' }}>
                  <Text style={[styles.font15]}>{item.name}</Text>
                </View>
                <View style={{ width: '33.3333333333%' }}>
                  <Text style={[styles.font15]}>{item.oldValue}</Text>
                </View>
                <View style={{ width: '33.3333333333%' }}>
                  <Text style={[styles.font15]}>{item.newValue}</Text>
                </View>
              </View>
            ))}
          </View>
        ))}
      </View>
    </ScrollView>
  )
}
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    paddingVertical: 24
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  titleSectionText: {
    fontSize: 16,
    lineHeight: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  titleTable: {
    color: '#70777e',
    fontWeight: '700'
  },
  font15: {
    fontSize: 15
  }
})