import * as t from 'io-ts'
import { form2 } from '@pulseops/common'
import i18next from 'i18next'
import { withMessage } from 'io-ts-types'

export namespace LoanStatementsForm {
  const LoanStatementsForm = t.type({
    fromDate: withMessage(form2.date.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('common:FromDate') })
    ),
    toDate: withMessage(form2.date.required, () => i18next.t('message:MS020001', { field: i18next.t('common:ToDate') }))
  })

  export type LoanStatements = t.TypeOf<typeof LoanStatementsForm>

  export const codec = LoanStatementsForm

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
