import React from 'react'
import { StyleSheet, Pressable, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { OBFormat, OBSharedStyles } from '../../ob-common'
import { OBRelatedDocRequestForm, OBTaskService } from '../../ob-service'
import { pipe } from 'fp-ts/lib/function'
import { CallOutDetailContext } from '../ob-common'
import { useLoading } from '@mxt/zio-react'
import { ZIO } from '@mxt/zio'
import { AuthService, Container, ErrorHandling, InputTable } from '@pulseops/common'
import { ImageDialog } from '@pulseops/task'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, makeStyles } from '@material-ui/core'
import _ from 'lodash'

export const tableStyles = makeStyles({
  root: {
    "& .MuiTableHead-root": {
      backgroundColor: '#F4F4F4'
    },
    "& .MuiTableCell-head": {
      color: '#58647A',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    "& .MuiTableCell-body": {
      color: '#1C1D1F',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
    }
  }
})

export const OBRelatedDocument = () => {
  const { t } = useTranslation()
  const { isDisabled, policyNumber, processInstanceId } = React.useContext(CallOutDetailContext)
  const [isLoading, bindLoader] = useLoading(false)
  const [relatedDocList, setRelatedDocList] = React.useState<OBRelatedDocRequestForm[]>([])
  const [filteredDocList, setFilteredDocList] = React.useState<OBRelatedDocRequestForm[]>([])
  const [image, setImage] = React.useState<string>('')
  const [openPopup, setOpen] = React.useState<boolean>(false)
  const classes = tableStyles()
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState(5)
  const [totalRecord, setTotalRecord] = React.useState(0)
  const [docIdFilter, setdocIdFilter] = React.useState<string>('')
  const [docTypeFilter, setdocTypeFilter] = React.useState<string>('')
  const [mainDocFilter, setmainDocFilter] = React.useState<string>('')
  const [subDocFilter, setsubDocFilter] = React.useState<string>('')
  const [batchNumFilter, setbatchNumFilter] = React.useState<string>('')
  const [sourceFilter, setsourceFilter] = React.useState<string>('')
  // const [softItem, setSoftItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
  //   field: '',
  //   order: 'asc'
  // })

  const [orderBy, setOrderBy] = React.useState<string>('source')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc')
  // const displayedColumns: OBGeneralTableColum[] = [
  //   {
  //     title: t('RelatedDoc:docType'),
  //     fieldName: 'docType',
  //     disabled: true
  //   },
  //   {
  //     title: t('RelatedDoc:docId'),
  //     fieldName: 'docId',
  //     disabled: false,
  //     onRender: (selectedData) => {
  //       const docItem = relatedDocList[selectedData.index]
  //       return (
  //         <Pressable onPress={() => getFileByUrl(docItem.url)}>
  //           <Text style={docStyle.highLightText}>{docItem.docId}</Text>
  //         </Pressable>
  //       )
  //     }
  //   },
  //   {
  //     title: t('RelatedDoc:mainDoc'),
  //     fieldName: 'mainDoc'
  //   },
  //   {
  //     title: t('RelatedDoc:subDoc'),
  //     fieldName: 'subDoc'
  //   },
  //   {
  //     title: t('RelatedDoc:batchNum'),
  //     fieldName: 'batchNum'
  //   },
  //   {
  //     title: t('RelatedDoc:source'),
  //     fieldName: 'source'
  //   }
  // ]


  const headerName = [
    { title: t('RelatedDoc:docType'), id: 'docType' },
    { title: t('RelatedDoc:docId'), id: 'docId' },
    { title: t('RelatedDoc:mainDoc'), id: 'mainDoc' },
    { title: t('RelatedDoc:subDoc'), id: 'subDoc' },
    { title: t('RelatedDoc:batchNum'), id: 'batchNum' },
    { title: t('RelatedDoc:source'), id: 'source' }
  ]

  React.useEffect(() => {
    if (policyNumber) {
      pipe(
        OBTaskService.getDocuments(policyNumber),
        ZIO.map((documents) => {
          if (documents.message === 'SUCCESS') {
            const docArr = documents.data ? documents.data.map((docItem) => ({
              url: docItem.url,
              docType: '-',
              docId: docItem.metaData.docid,
              mainDoc: docItem.metaData.maindoc ?? '',
              subDoc: docItem.metaData.subdoc ?? '',
              batchNum: docItem.metaData.batchno ?? '',
              source: 'FILENET',
            })) : []
            setRelatedDocList(docArr ?? [])
            setTotalRecord(docArr.length || 0)
            setFilteredDocList(docArr.filter((item, index) => index < pageSize))
            // setFilteredDocList(docArr)
          } else {
            setRelatedDocList([])
            setFilteredDocList([])
          }
          return documents
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [policyNumber])

  const handleSort = (data: any, order: 'asc' | 'desc' | undefined, field: string | undefined = '') => {
    return _.orderBy(
      data,
      (o) => {
        if (field) {
          if (!!parseInt(o[field])) {
            return parseInt(o[field])
          }
          return _.isString(o[field]) ? o[field].toUpperCase() : o[field]
        }
        return o[field]
      },
      order
    )
  }

  React.useEffect(() => {
    const listenerEvent = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        updateFilteredDocList()
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listenerEvent)
    return () => {
      document.removeEventListener('keydown', listenerEvent)
    }
  }, [relatedDocList, docIdFilter, docTypeFilter, mainDocFilter, subDocFilter, batchNumFilter, sourceFilter])

  const updateFilteredDocList = () => {
    if (relatedDocList && relatedDocList.length > 0) {
      let docList: OBRelatedDocRequestForm[] = handleSort(relatedDocList, order, orderBy)
      docList = docList.filter((x) =>
        (!docIdFilter || (!!docIdFilter && like(x.docId, docIdFilter))) &&
        (!docTypeFilter || (!!docTypeFilter && like(x.docType, docTypeFilter))) &&
        (!mainDocFilter || (!!mainDocFilter && like(x.mainDoc, mainDocFilter))) &&
        (!subDocFilter || (!!subDocFilter && like(x.subDoc, subDocFilter))) &&
        (!batchNumFilter || (!!batchNumFilter && like(x.batchNum, batchNumFilter))) &&
        (!sourceFilter || (!!sourceFilter && like(x.source, sourceFilter)))
      )
      setFilteredDocList(docList.filter((item, index) => index < pageSize))
    }
  }

  React.useEffect(() => {
    updateFilteredDocList()
  }, [orderBy, order])

  const like = (a: string | null | undefined, b: string) => (a ? a.includes(b.trim()) : false)

  const openImage = (image: string) => {
    setImage(image)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement('a')
    link.download = name
    link.target = '_blank'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getFileByUrl = (url: string) => {
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const type = res.headers.get('content-type') || ''
        // if (['image/png', '	image/jpg', 'image/jpeg'].includes(type)) {
        //   openImage(url)
        // } else {
        //   downloadURI(window.URL.createObjectURL(blob), `file.${type.split('/')[1]}`)
        // }
        const blobUrl = window.URL.createObjectURL(blob)
        if(type === 'image/tiff'){
          OBFormat.viewTiffFileByNewTab(blobUrl)
        } else {
          window.open(blobUrl, '_blank')
        }
      }),
      ErrorHandling.run()
    )
  }

  const setFilter = (field: string, value: 'asc' | 'desc') => {
    const newOrder = value === 'asc' ? 'desc' : 'asc'
    setOrderBy(field)
    setOrder(newOrder)

  }

  const getHandlingPage = (pageNu: number, pageSi: number) => {
    let filterList: OBRelatedDocRequestForm[] = []
    setdocTypeFilter('')
    setdocIdFilter('')
    setmainDocFilter('')
    setsubDocFilter('')
    setbatchNumFilter('')
    setsourceFilter('')
    setPageNum(pageNu)
    setPageSize(pageSi)
    for (let i = 0 + (pageNu * pageSi); i < (pageNu + 1) * pageSi && i < relatedDocList.length; i++) {
      filterList.push(relatedDocList[i])
    }
    setFilteredDocList(filterList)
  }

  return (
    <View style={[OBSharedStyles.policyInfoContainer]}>
      <Container loading={isLoading}>
        <TableContainer>
          <Table classes={{ root: classes.root }}>
            <TableHead>
              <TableRow>
                {headerName.map((header) => {
                  return (
                    <TableCell key={header.id} align={'left'} sortDirection={orderBy === header.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={() => setFilter(header.id, order)}
                      >
                        {header.title}
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
              </TableRow>
              <TableRow>
                <TableCell>
                  <InputTable value={docTypeFilter} onChange={(val) => setdocTypeFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable value={docIdFilter} onChange={(val) => setdocIdFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable value={mainDocFilter} onChange={(val) => setmainDocFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable value={subDocFilter} onChange={(val) => setsubDocFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable value={batchNumFilter} onChange={(val) => setbatchNumFilter(val)}></InputTable>
                </TableCell>
                <TableCell>
                  <InputTable value={sourceFilter} onChange={(val) => setsourceFilter(val)}></InputTable>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDocList && filteredDocList.map((dataItem, index) => {
                return (
                  <TableRow key={`dataItem_${index}`}>
                    <TableCell>
                      {dataItem.docType}
                    </TableCell>
                    <TableCell>
                      <Pressable onPress={() => { getFileByUrl(dataItem.url) }}>
                        <Text style={{ color: '#ED1B2E' }}>
                          {dataItem.docId}
                        </Text>
                      </Pressable>
                    </TableCell>
                    <TableCell>
                      {dataItem.mainDoc}
                    </TableCell>
                    <TableCell>
                      {dataItem.subDoc}
                    </TableCell>
                    <TableCell>
                      {dataItem.batchNum}
                    </TableCell>
                    <TableCell>
                      {dataItem.source}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          count={totalRecord}
          rowsPerPage={pageSize}
          page={pageNum}
          onPageChange={(e, page) => {
            setPageNum(page)
            getHandlingPage(page, pageSize)
          }}
          onRowsPerPageChange={(e) => {
            const pageSiz = Number(e.target.value)
            setPageSize(pageSiz)
            if (totalRecord <= pageSiz * pageNum) {
              setPageNum(0)
              getHandlingPage(0, pageSiz)
            } else {
              getHandlingPage(pageNum, pageSiz)
            }
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        ></TablePagination>
        <ImageDialog selectedValue={image} open={openPopup} onClose={handleClose}></ImageDialog>
      </Container>
    </View>
  )
}

const docStyle = StyleSheet.create({
  highLightText: {
    color: '#ED1B2D'
  }
})