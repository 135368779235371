import { Task, ZIO } from '@mxt/zio'
import { HttpClient } from '@mxt/zio-http-client'
import { Nullable } from '@mxt/zio/codec'
import { Alert, POApi } from '@pulseops/common'
import * as E from 'fp-ts/Either'
import { Either } from 'fp-ts/Either'
import { constVoid, flow, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import i18next from 'i18next'
import * as t from 'io-ts'
import { mapUpdateBody } from '../general-info/general-info-api'
import { FormService } from '../general-info/form-service'
import { EmployeeC } from '../general-info/model/employee'
import { EcardC } from './model/ecard'
import { EmployeeUwC } from './model/employee-uw'
import { PremiumCharge } from './model/premium-charge'
import { PremiumChargeTotalC } from './model/premium-charge-total'
import { PremiumDecision, PremiumDecisionC } from './model/premium-decision'
import { StatusContractC } from './model/status-contract'
import { UwDecisionC } from './model/uw-decision'
import { UwEmployeeDecision, UwEmployeeDecisionC } from './model/uw-employee-decision'
import { VerificationDecisionC } from './model/verification-decision'
import { ReportECardC, ExportReportECard } from './model/report-ecard'

export namespace NbuwApi {
  export type AddVerificationDecisionBody = {
    checkList: {
      masterContractNo: string
      policyYear: number
      checklistName: string
      decision: boolean
      comment: string | null
      status: string | null
      requestDate: Date | null
      completeDate: Date | null
    }[]
  }
  export const addVerificationDecision = (body: AddVerificationDecisionBody) =>
    POApi.post('business-pulse/api/v1/add-verification-decision')(t.unknown)({
      checkList: body.checkList.map(mapUpdateBody)
    })

  export const getVerificationDecision = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/verification-decision')(
      t.type({
        data: t.array(VerificationDecisionC)
      })
    )

  export const getUwDecision = (params: { masterContractNo: string; policyYear: number; page: number; size: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/uw-decision')(
      t.type({
        data: t.array(UwDecisionC),
        total: t.number
      })
    )

  export type AddUwDecisionBody = {
    finalUwDecision: string
    masterContractNo: string
    policyYear: number
  }
  export const addUwDecision = (body: AddUwDecisionBody) =>
    pipe(POApi.post('business-pulse/api/v1/add-uw-decision')(t.unknown)(body))

  export const getEmployeeOverFcl = (params: {
    masterContractNo: string
    policyYear: number
    overFcl: typeof FormService.overFclYes | typeof FormService.overFclNo
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/employee/get-over-fcl')(
      t.type({
        data: t.array(EmployeeC),
        total: t.number
      })
    )

  export type AddUwDecisionEmployeesBody = {
    employeeUWList: UwEmployeeDecision[]
  }
  export const addUwDecisionEmployees = (body: AddUwDecisionEmployeesBody) =>
    POApi.post('business-pulse/api/v1/add-uw-decision-employees')(t.unknown)({
      employeeUWList: body.employeeUWList.map(mapUpdateBody)
    })

  export const getUwDecisionEmployees = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/uw-decision-employees')(
      t.type({
        data: t.array(UwEmployeeDecisionC),
        total: t.number
      })
    )

  export type AddPremiumDecisionBody = {
    premiumDecisions: PremiumDecision[]
  }
  export const addPremiumDecision = (body: AddPremiumDecisionBody) =>
    POApi.post('business-pulse/api/v1/add-premium-decision')(t.unknown)({
      premiumDecisions: body.premiumDecisions.map(mapUpdateBody)
    })

  export const getPremiumDecision = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/premium-decision')(
      t.type({
        data: t.array(PremiumDecisionC),
        total: t.number
      })
    )

  export type AddPremiumChargeTotalBody = {
    totalPremiumCharge: {
      id?: string
      masterContractNo: string
      policyYear: number
      premiumCharges: PremiumCharge[]
      total: number
      premiumReceived: number
      premiumPending: number
    }
  }
  export const addPremiumChargeTotal = (body: AddPremiumChargeTotalBody) =>
    POApi.post('business-pulse/api/v1/add-total-premium-charge')(
      t.type({
        totalPremiumCharge: PremiumChargeTotalC
      })
    )({
      totalPremiumCharge: {
        ...body.totalPremiumCharge,
        premiumCharges: body.totalPremiumCharge.premiumCharges.map(mapUpdateBody)
      }
    })

  export const getPremiumChargeTotal = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/total-premium/get')(
      t.type({
        data: Nullable(PremiumChargeTotalC)
      })
    )

  export type AddStatusContract = {
    statusContractInfo: {
      masterContractNo: string
      policyYear: number
      status: string
    }
  }
  export const addStatusContract = (body: AddStatusContract) =>
    POApi.post('business-pulse/api/v1/add-status-contract')(t.unknown)(body)

  export const getStatusContract = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/status-contract')(
      t.type({
        data: t.array(StatusContractC),
        total: t.number
      })
    )

  export const contractRelease = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.post('business-pulse/api/v1/contract-info/release', {
      params
    })(t.unknown)({})

  export type UploadError = UploadError.Duplicated | UploadError.Fail | UploadError.NotInput

  export namespace UploadError {
    export type Duplicated = { tag: 'Duplicated'; msg: string }
    export const Duplicated = (msg: string): Duplicated => ({ tag: 'Duplicated', msg })

    export type Fail = { tag: 'Fail' }
    export const Fail: Fail = { tag: 'Fail' }

    export type NotInput = { tag: 'NotInput'; msg: string }
    export const NotInput = (msg: string): NotInput => ({ tag: 'NotInput', msg })
  }

  export const uploadHandbook = ({
    file,
    masterContractNo,
    policyYear
  }: {
    file: File
    masterContractNo: string
    policyYear: number
  }): Task<Either<UploadError, void>> => {
    const formData = new FormData()
    formData.append('file', file)

    return pipe(
      POApi.post('business-pulse/api/v1/handbook/upload', {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: { masterContractNo, policyYear }
      })(t.unknown)(formData),
      ZIO.foldM(
        (err) =>
          pipe(
            err.source,
            O.fromPredicate(HttpClient.isAxiosError),
            O.filterMap((source) =>
              source.response?.status === 400
                ? pipe(
                    source.response?.data,
                    t.type({
                      result: t.keyof({ FAIL: null, DUPLICATED: null, NOT_INPUT: null }),
                      errorDescription: t.string
                    }).decode,
                    O.fromEither,
                    O.map(({ result, errorDescription }) => {
                      switch (result) {
                        case 'FAIL':
                          return UploadError.Fail
                        case 'DUPLICATED':
                          return UploadError.Duplicated(errorDescription)
                        case 'NOT_INPUT':
                          return UploadError.NotInput(errorDescription)
                      }
                    })
                  )
                : O.none
            ),
            O.fold(
              (): Task<Either<UploadError, void>> => ZIO.fail(err),
              (importErr) => ZIO.succeed(E.left(importErr))
            )
          ),
        () => ZIO.succeed(E.right(constVoid()))
      ),
      ZIO.tap(
        flow(
          E.fold(
            (err) => {
              switch (err.tag) {
                case 'Fail':
                  return i18next.t(`business:MS000018`)
                case 'Duplicated':
                  return err.msg
                case 'NotInput':
                  return err.msg
              }
            },
            () => i18next.t(`business:MS000019`)
          ),
          Alert.alertM
        )
      )
    )
  }

  export const checkContract = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/check-contract')(
      t.type({
        errorCodes: Nullable(
          t.array(
            t.type({
              code: t.string
            })
          )
        )
      })
    )

  export const getEmployeeUwDecision = (params: {
    masterContractNo: string
    policyYear: number
    page: number
    size: number
  }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/employee/get-list-member')(
      t.type({
        data: t.array(EmployeeUwC),
        total: t.number
      })
    )

  export const getReportEcard = (params: { page: number; size: number; fromDate: Date; toDate: Date }) =>
    POApi.post('business-pulse/api/v1/report-ecard', {
      params: mapUpdateBody(params)
    })(
      t.type({
        data: t.array(EcardC),
        total: t.number
      })
    )({})

  export const updateAfterRelease = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.post('business-pulse/api/v1/contract-info/update-after-release', { params })(
      t.type({
        errorCodes: Nullable(
          t.array(
            t.type({
              code: t.string
            })
          )
        )
      })
    )({})

  export const checkContractAfterRelease = (params: { masterContractNo: string; policyYear: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/check-contract-after-release')(
      t.type({
        errorCodes: Nullable(
          t.array(
            t.type({
              code: t.string
            })
          )
        )
      })
    )
  export const getSearchECard = (params: {
    page: number
    size: number
    fromDate: string | null
    toDate: string | null
    masterContractNo: string
    policyYear: number
  }) =>
    POApi.post('business-pulse/api/v1/report-ecard', {
      params: mapUpdateBody(params)
    })(
      t.type({
        data: t.array(ReportECardC),
        page: t.number,
        size: t.number,
        total: t.number
      })
    )({})

  export const exportEcard = (params: {
    fromDate: string | null
    toDate: string | null
    masterContractNo: string
    policyYear: number
  }) =>
    POApi.post('business-pulse/api/v1/export-ecard', {
      params: mapUpdateBody(params)
    })(t.array(ExportReportECard))({})
}
