import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginHorizontal: 'auto'
    // justifyContent: 'center'
    // maxWidth: '1199px'
  },
  // Tab ++
  tabContainer: {
    marginHorizontal: 16,
    marginBottom: 16
  },
  // Tab --
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },
  // General Info Item
  generalInfoItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 22,
    marginBottom: 12
  },
  generalInfoItemText: {
    fontSize: 16,
    lineHeight: 28
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 16
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  sectionInfoContentRow: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sectionInfoContentCol: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  sectionInfoContentLabel: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  sectionInfoContentValue: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
