import { CashOutPrucash } from './CashOutPrucash'
import { CashOutMaturity } from './CashOutMaturity'
import { PartialSurrender } from './PartialSurrender'
import { ChangeSumAssured } from './ChangeSumAssured'
import { ChangePremium } from './ChangePremium'
import { CashOutLoan } from './CashOutLoan'
import { ChangeBeneficialInfo, NewBeneficiaryInfo, CurrentBeneficiaryInfo } from './ChangeBeneficalInfo'
import { ChangeEmailInfo } from './ChangeEmailInfo'
import { ChangePhoneInfo } from './ChangePhoneInfo'
import * as TaskDetailApi from '../task-detail-api'
import { ChangePrimaryAddressInfo } from './ChangePrimaryAddressInfo'
import { BillingChange } from './BillingChange'
import { Reinstatement } from './Reinstatement'
import { BillingChangeReversal } from './BillingChangeReversal'
import { ChangeClientInfo } from './ChangeClientInfo'
import { PreviewTopUpInfo, Topup } from './Topup'
import { ConfirmationPremiumReceipt } from './ConfirmationPremiumReceipt'
import { CancelFromInception } from './CancelFromInception'
import { ExcessPremium } from './ExcessPremium'
import { PartialWithdrawal } from './PartialWithdrawal'
import { MaturityAdvance } from './MaturityAdvance'
import { ChangeContactInfo } from './ChangeContactInfo'
import { ZaloRequestProcess } from './ZaloRequestProcess'
import { ZaloClaimDecision } from './ZaloClaimDecision'
import { ZaloOther } from './ZaloOther'
import { ZaloPremiumCollection } from './ZaloPremiumCollection'
import { ZaloRecommendation } from './ZaloRecommendation'
import { Eservice } from './EService'
import { GoAbroad } from './GoAbroad'
import { TransactionType } from '../TransactionType'
import { CancelRider, CancelRiderData, VeriLARiderInfo } from './CancelRider'
import { LoanStatements, VeriLoanStatements } from './LoanStatements'
import { PolicyCertificate, VeriPolicyCertificateData } from './PolicyCertificate'
import { CashOutSpecialBonus } from './CashOutSpecialBonus'
import { InquiryComplaint, VeriInquiryComplaintData } from './InquiryComplaint'
import { PremiumRedirection, VeriPremiumRedirectionData } from './PremiumRedirection'
import { ExcellentStudentAwardModel, ExcellentStudentAward } from './ExcellentStudentAward'
import { FundSwitching } from './FundSwitching'
import { SignatureRegistration } from './SignatureRegistration'
import { BonusSurrender } from './BonusSurrender'
import { FatcaDeclaration } from './FatcaDeclaration'
import { StopApl } from './StopApl'
import { Assignment, AssignmentModel } from './Assignment'
import { Inheritance } from './Inheritance'
import { PaidUpModal, PaidUp } from './PaidUp'
import { Prukid369, PruKid369Model } from './PruKid369'
import { HealthRedeclaration } from './HealthRedeclaration'
import { ScheduledPaymentStatement } from './ScheduledPaymentStatement'
import { FullSurrenderReversal, VeriFullSurrenderReversalData } from './FullSurrenderReversal'
import { FullSurrender } from './FullSurrender'
import { VerificationClaim } from './VerificationClaim'
import { CommonRequest, VeriCommonRequest } from './CommonRequest'
import { ProductOptionSwitching } from './ProductOptionSwitching'
import { VERIRedating, Redating } from './Redating'
import { RiderReinstatement, VERIRiderRein } from './RiderReinstatement'
import { RiderAlteration, VERIRiderAlteration } from './RiderAlteration'
import { CashLess, VeriCashLess } from './CashLess'
import { AgentOnboardNew } from './AgentOnboardNew'
import { VERIMajorCombined, MajorCombinedChange } from './MajorCombined'
import { TransferJournal } from './TransferJournal'
import { isNull } from 'lodash'
import { AgentVerificationNew } from './AgentVerificationNew'
import { UploadDocument, VERIUploadDocument } from './UploadDocument'
import { PartialWithdrawalAutoDebit } from './PartialWithdrawalAutoDebit'
import { VerificationClaimPayout } from './VerificationClaimPayout'
import { ChangeNationalIDCard } from './ChangeNationalIDCard'
import { ChangeOccupationPersonal } from './ChangeOccupationPersonal'
import { ChangeDOBGender } from './ChangeDOBGender'
import { ChangeOtherInfomationPersonal } from './ChangeOtherInfomationPersonal'
import { Independence_Check, IndependenceCheck } from './IndependenceCheck'
import { VoiceRecording, Voice_Recording } from './VoiceRecording'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
export type Payload =
  | CashOutLoan
  | CashOutMaturity
  | PartialSurrender
  | ChangeSumAssured
  | ChangePremium
  | CashOutPrucash
  | ChangeBeneficialInfo
  | ChangeEmailInfo
  | ChangePhoneInfo
  | ChangePrimaryAddressInfo
  | ChangeContactInfo
  | BillingChange
  | Reinstatement
  | BillingChangeReversal
  | ChangeClientInfo
  | Topup
  | ConfirmationPremiumReceipt
  | CancelFromInception
  | CancelRiderData
  | ExcessPremium
  | PartialWithdrawal
  | MaturityAdvance
  | ZaloRequestProcess
  | ZaloClaimDecision
  | ZaloOther
  | ZaloPremiumCollection
  | ZaloRecommendation
  | Eservice
  | GoAbroad
  | VeriLoanStatements
  | VeriPolicyCertificateData
  | CashOutSpecialBonus
  | VeriInquiryComplaintData
  | VeriPremiumRedirectionData
  | ExcellentStudentAwardModel
  | BonusSurrender
  | FundSwitching
  | SignatureRegistration
  | FatcaDeclaration
  | StopApl
  | AssignmentModel
  | Inheritance
  | PaidUpModal
  | PruKid369Model
  | HealthRedeclaration
  | ScheduledPaymentStatement
  | VeriFullSurrenderReversalData
  | FullSurrender
  | VerificationClaim
  | VerificationClaimPayout
  | VeriCommonRequest
  | ProductOptionSwitching
  | VERIRedating
  | VERIRiderRein
  | VERIRiderAlteration
  | VeriCashLess
  | AgentOnboardNew
  | VERIMajorCombined
  | TransferJournal
  | AgentVerificationNew
  | VERIUploadDocument
  | ChangeNationalIDCard
  | ChangeOccupationPersonal
  | ChangeDOBGender
  | ChangeOtherInfomationPersonal
  | PartialWithdrawalAutoDebit
  | Independence_Check
  | Voice_Recording

export interface Type {
  id: string
  caseId: string
  processId: string
  claimNumber: string
  clientNumber: string
  primaryPolicyNo: string
  policyNumber: string
  assignee: string
  payload: Payload
  updateToCore: boolean
  updateToCoreConfig: boolean
  collerationId: string
  otpRegistered: boolean
  category: string
  authenOption: string
  officeCode: string
  source: string
  isCancelPayment: boolean
  pvNumberCashout: string
  transaction: string
  amlResult: string
  redFlagResult: string | boolean
  unsuspendTypeAML: string
  uwResult: string
  pvNumber: string
  pvStatus: string
  p40ProcessInstanceId: string
}
export const init =
  (detail: TaskDetailApi.Type, authenOption: string) =>
  (otp: boolean) =>
  (payload: Payload): Type => {
    return {
      id: detail.id,
      primaryPolicyNo: detail.policyNumber || (detail.proposalNumber || ''),
      caseId: detail.businessKey,
      processId: detail.processInstanceId ?? '',
      claimNumber: detail.claimNumber || '-',
      clientNumber: detail.clientNumber || '-',
      policyNumber: getPolicyNumber(detail),
      assignee: detail.assignee ?? '-',
      payload,
      updateToCore: detail.updateToCore,
      updateToCoreConfig: detail.updateToCoreConfig,
      collerationId: detail.correlationId || '',
      otpRegistered: true,
      category: detail.category ?? '',
      authenOption,
      officeCode: detail.payload.officeCode ?? '',
      source: detail.source ?? '',
      isCancelPayment: !!detail.isCancelPayment,
      pvNumberCashout: detail.paymentNumber ?? '',
      transaction: detail.transactionType ?? '',
      amlResult: detail.amlResult ?? '-',
      redFlagResult: !isNull(detail.redFlagResult) ? (detail.redFlagResult ? 'Y' : 'N') : '-',
      unsuspendTypeAML: detail.unsuspendTypeAML ?? '-',
      uwResult: detail.uwResult ?? '',
      pvNumber: detail.pvNumber ?? '',
      pvStatus: detail.pvStatus ?? '',
      p40ProcessInstanceId: detail.p40ProcessInstanceId || ''
    }
  }

const getPolicyNumber = (detail: TaskDetailApi.Type): string => {
  switch (detail.transactionType) {
    case TransactionType.CHANGE_NATIONAL_ID_CARD:
    case TransactionType.CHANGE_OCCUPATION_PERSONAL:
    case TransactionType.CHANGE_CONTACT_INFO_PHONE:
    case TransactionType.CHANGE_CONTACT_INFO_EMAIL:
    case TransactionType.CHANGE_CONTACT_INFO_ADDRESS:
    case TransactionType.CHANGE_CONTACT_INFO:
    case TransactionType.CHANGE_CLIENT_INFO: {
      //case TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL:
      const changeContactApi = detail as TaskDetailApi.ChangeContactInfo
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return changeContactApi.payload.body.policyNo || ''
    }
    case TransactionType.VOICE_RECORDING: {
      const policyNumber = pipe(detail.policyNumber,
        O.fromNullable,
        O.fold(
          () => '',
          (itemData) => {
            const policyNum = itemData !== 'N/A' && itemData !== 'null' ? itemData : ''
            return policyNum
          }
        )
      )
      return policyNumber
    }
    default:
      return detail.correlationId || ''
  }
}

export {
  CashOutLoan,
  CashOutMaturity,
  PartialSurrender,
  ChangeSumAssured,
  ChangePremium,
  CashOutPrucash,
  ChangeBeneficialInfo,
  ChangeEmailInfo,
  ChangePhoneInfo,
  NewBeneficiaryInfo,
  CurrentBeneficiaryInfo,
  ChangePrimaryAddressInfo,
  ChangeContactInfo,
  BillingChange,
  Reinstatement,
  BillingChangeReversal,
  ChangeClientInfo,
  Topup,
  PreviewTopUpInfo,
  ConfirmationPremiumReceipt,
  CancelFromInception,
  CancelRider,
  VeriLARiderInfo,
  ExcessPremium,
  PartialWithdrawal,
  MaturityAdvance,
  ZaloRecommendation,
  ZaloPremiumCollection,
  ZaloClaimDecision,
  ZaloOther,
  ZaloRequestProcess,
  Eservice,
  GoAbroad,
  VeriLoanStatements,
  LoanStatements,
  PolicyCertificate,
  CashOutSpecialBonus,
  VeriPolicyCertificateData,
  InquiryComplaint,
  PremiumRedirection,
  VeriPremiumRedirectionData,
  ExcellentStudentAwardModel,
  ExcellentStudentAward,
  BonusSurrender,
  FundSwitching,
  SignatureRegistration,
  FatcaDeclaration,
  StopApl,
  Assignment,
  AssignmentModel,
  Inheritance,
  PaidUp,
  PaidUpModal,
  PruKid369Model,
  Prukid369,
  HealthRedeclaration,
  ScheduledPaymentStatement,
  VeriFullSurrenderReversalData,
  FullSurrenderReversal,
  FullSurrender,
  VerificationClaim,
  VerificationClaimPayout,
  CommonRequest,
  VeriCommonRequest,
  ProductOptionSwitching,
  Redating,
  VERIRedating,
  RiderReinstatement,
  VERIRiderRein,
  RiderAlteration,
  VERIRiderAlteration,
  CashLess,
  VeriCashLess,
  AgentOnboardNew,
  MajorCombinedChange,
  VERIMajorCombined,
  TransferJournal,
  AgentVerificationNew,
  UploadDocument,
  VERIUploadDocument,
  ChangeNationalIDCard,
  ChangeOccupationPersonal,
  ChangeDOBGender,
  ChangeOtherInfomationPersonal,
  PartialWithdrawalAutoDebit,
  IndependenceCheck,
  Voice_Recording,
  VoiceRecording
}

export interface PolicySearchRes {
  policyNum: string
  clientNum: string
  nationalID: string
  clientName: string
  lifeAssure: string
}
