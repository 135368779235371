import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'
import { ContractInfo } from './contract-info'

export const ContractItemC = t.type({
  masterContractNo: t.string,
  submittedDate: DateFromISOString,
  companyName: t.string,
  taxCode: t.string,
  policyYear: t.number
})
export type ContractItem = t.TypeOf<typeof ContractItemC>

export const ContractItem = (c: ContractInfo): ContractItem => ({
  masterContractNo: c.masterContractNo,
  submittedDate: c.submitDate,
  companyName: c.policyHolder.policyHolder,
  taxCode: c.policyHolder.taxCode,
  policyYear: c.policyYear
})
