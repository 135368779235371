import { ZIO } from '@mxt/zio'
import { AuthService, ErrorHandling, assets } from '@pulseops/common'
import { OBFormat } from '@pulseops/outbound'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Pressable, StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
// import UTIF from 'utif'
type DataField = {
  title: string
  value: string | number | undefined
  textStyle?: StyleProp<TextStyle>
  styleCol?: StyleProp<ViewStyle>
}
type CheckBoxField = {
  title: string
  value: boolean | undefined
  urlLink?: string
}

type Prop = {
  dataSource: DataField[]
  checkboxList?: CheckBoxField[]
}

export const OBFieldList = ({ dataSource, checkboxList }: Prop) => {

  const oneFileByUrl = (url: string) => {
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const fileType = res.headers.get('content-type') || ''
        const fileString = window.URL.createObjectURL(blob)
        if (fileType === "image/tiff") {
          OBFormat.viewTiffFileByNewTab(fileString)
        } else {
          window.open(fileString, '_blank')
        }
      }),
      ErrorHandling.run()
    )
  }



  return (
    <View style={styles.row}>
      {dataSource.map((i) => (
        <View style={styles.col}>
          <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 13, marginTop: 5, marginBottom: 4 }}>{i.title}</Text>
          <Text style={[i.textStyle ? i.textStyle : {}, { marginTop: 4, marginBottom: 5, fontSize: 15 }]}>{i.value || i.title ? i.value : ''}</Text>
        </View>
      ))}
      {checkboxList && checkboxList.map((i) => (
        <View style={i.value ? styles.colCheckBox : styles.colCheckBoxNoTick}>
          <View style={{ flexDirection: 'row', marginVertical: 8 }}>
            <View style={{ marginRight: 8 }}>
              {i.value ? <assets.OBTickBox /> : <assets.OBNoTick />}
            </View>
            {i.urlLink ?
              <Pressable onPress={() => i.urlLink ? oneFileByUrl(i.urlLink) : {}}>
                <Text style={styles.linkText}>{i.title}</Text>
              </Pressable>
              : <Text>{i.title}</Text>
            }
          </View>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: 24,
    flexWrap: 'wrap'
  },

  col: {
    width: '100%',
    maxWidth: '33.33%',
    marginBottom: 15,
  },

  colCheckBox: {
    width: '100%',
    maxWidth: '33.33%',
    marginBottom: 15,
    paddingLeft: 2
  },

  colCheckBoxNoTick: {
    width: '100%',
    maxWidth: '33.33%',
    marginBottom: 15,
  },

  linkText: {
    color: '#5959FF',
    fontWeight: '500',
    textDecorationLine: 'underline'
  }
})