import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Default = t.type({
  transactionType: t.literal(TransactionType.CHANGE_CONTACT_INFO_EMAIL),
  payload: t.type({
    body: t.type({
      value: Maybe(t.string)
    })
  }),
  source: t.union([
    t.literal(SourceType.BANCA),
    t.literal(SourceType.EKIOSK),
    t.literal(SourceType.PRUGREAT),
    t.literal(SourceType.PULSE),
    t.literal(SourceType.ZALO)
  ])
})

const Pulse4Ops = t.type({
  transactionType: t.literal(TransactionType.CHANGE_CONTACT_INFO_EMAIL),
  payload: t.type({
    body: t.type({
      contactDetails: t.type({
        EMAIL: t.type({
          value: t.string
        }),
        EMAIL_IND: Maybe(
          t.type({
            value: Maybe(t.string)
          })
        )
      }),
      attributesExt: Maybe(
        t.type({
          EMAILS_DUP: Maybe(
            t.array(
              t.type({
                clientId: Maybe(t.string),
                relationshipType: Maybe(t.string)
              })
            )
          )
        })
      )
    })
  }),
  source: t.union([t.literal(SourceType.PULSE4OPS), t.literal(SourceType.BANCA_SEA), t.literal(SourceType.PRUONLINE), t.literal(SourceType.OUTBOUND), t.literal(SourceType.INBOUND)])
})
export const ChangeEmailInfo = t.union([Default, Pulse4Ops])
export type ChangeEmailInfo = t.TypeOf<typeof ChangeEmailInfo>
