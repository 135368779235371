import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const lAClient = t.type({
  clientNumberOfLA: t.string,
  life: t.string
})

export type lAClient = t.TypeOf<typeof lAClient>

export const benClient = t.type({
  clientNumberOfBen: t.string,
  percent: t.number,
  relationShipCode: t.string
})

export type benClient = t.TypeOf<typeof benClient>

export const GetClientsByPolicyResponse = t.type({
  body: t.type({
    laList: t.array(lAClient),
    benList: t.array(benClient)
  })
})

export type GetClientsByPolicyResponseType = t.TypeOf<typeof GetClientsByPolicyResponse>

export const GetOwnerInfoResponse = t.type({
  clientId: Maybe(t.string),
  email: Maybe(t.string),
  name: Maybe(t.string),
  mobilePhone: Maybe(t.string),
  externalIds: Maybe(
    t.type({
      SOE_VALUE: Maybe(t.string)
    })
  ),
  attributesExt: Maybe(
    t.type({
      MOBILE_CODE: Maybe(t.string),
      BASIC_PRODUCT_CODE: Maybe(t.string)
    })
  ),
  firstName: Maybe(t.string),
  surName: Maybe(t.string),
  dob: Maybe(t.string),
  sex: Maybe(t.string),
  nationality: Maybe(t.string)
})

export type GetOwnerInfoResponseType = t.TypeOf<typeof GetOwnerInfoResponse>

export const ClientC = t.type({
  clientId: Maybe(t.string),
  email: Maybe(t.string),
  name: Maybe(t.string),
  mobilePhone: Maybe(t.string),
  externalIds: Maybe(
    t.type({
      SOE_VALUE: Maybe(t.string)
    })
  ),
  attributesExt: Maybe(
    t.type({
      MOBILE_CODE: Maybe(t.string),
      BASIC_PRODUCT_CODE: Maybe(t.string)
    })
  ),
  firstName: Maybe(t.string),
  surName: Maybe(t.string),
  dob: Maybe(t.string),
  sex: Maybe(t.string),
  nationality: Maybe(t.string)
})

export type ClientC = t.TypeOf<typeof ClientC>
