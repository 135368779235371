import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace InternalSuspendHistoryForm {
  const SuspendHistoryInfo = t.type({
    historyID: t.string,
    status: form2.selectOption.optional,
    oldStatusCode: t.string,
    suspendCode: form2.string.optional,
    user: form2.string.optional,
    slaHour: form2.string.optional,
    effectiveDate: form2.string.optional,
    dueDate: form2.string.optional,
    department: form2.string.optional,
    departmentName: form2.string.optional,
    level: form2.string.optional,
    levelName: form2.string.optional,
    content: form2.string.optional,
    emailTo: form2.string.optional,
    emailCc: form2.string.optional,
    createdBy: form2.string.optional,
    createdDate: form2.string.optional,
    modifiedBy: form2.string.optional,
    modifiedDate: form2.string.optional,
    isCompleteStatus: t.boolean
  })

  const InternalSuspendHistoryList = t.type({
    suspendHistoryList: t.array(SuspendHistoryInfo)
  })

  export type SuspendHistoryInfo = t.TypeOf<typeof SuspendHistoryInfo>
  export const codec = InternalSuspendHistoryList
  export type validated = t.TypeOf<typeof codec>
  export type raw = t.OutputOf<typeof codec>
}
