import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface ScheduledPaymentStatement {
  tag: TransactionType.SCHEDULED_PAYMENT_STATEMENT
  clientName: string
  fromDate: string
  toDate: string
}

export const ScheduledPaymentStatement = (
  detail: api.ScheduledPaymentStatement,
  poName: string
): ScheduledPaymentStatement => ({
  tag: TransactionType.SCHEDULED_PAYMENT_STATEMENT,
  clientName: poName ?? '',
  fromDate: detail.payload.body.fromDate ?? '-',
  toDate: detail.payload.body.toDate ?? '-'
})
