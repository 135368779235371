import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { AdmintoolService, OBSharedStyles } from '@pulseops/outbound'
import { AppContext, ErrorHandling, ModalComponent, Permission, RBAC, SelectOption, assets } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useForm } from 'react-hook-form'
import { IBGeneralField, IBGeneralTable, TypeInputComponent } from '@pulseops/inbound'
import { getStyleStatus, getTitleStatus, statusOption, validateMail } from './commonFunction'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
type TransferTransactionForm = {
  id: string
  user: SelectOption | null
  status: SelectOption | null
  sqcb: boolean
  rmd: boolean
  care1: boolean
  care2: boolean
  wcc: boolean
  rmdBau: boolean
  idc: boolean
  adHocCS: boolean
  adHocCCE: boolean
  apl: boolean
  lapsed: boolean
  ma1: boolean
  ma2: boolean
}

type DataTable = {
  id: string
  no: number
  user: string
  status: string
  sqcb: boolean
  rmd: boolean
  care1: boolean
  care2: boolean
  wcc: boolean
  rmdBau: boolean
  idc: boolean
  adHocCS: boolean
  adHocCCE: boolean
  apl: boolean
  lapsed: boolean
  ma1: boolean
  ma2: boolean
}

interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }
export const ConfigT4S17TransferTransaction = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [isOpenAddPopup, setOpenAddPopup] = React.useState<boolean>(false)
  const [isOpenEditPopup, setOpenEditPopup] = React.useState<boolean>(false)
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [dataTable, setDataTable] = React.useState<DataTable[]>([])
  const [isLoading, bindLoading] = useLoading(false)
  const [reload, setReload] = React.useState<boolean>(false)
  const { height, width } = useWindowDimensions()
  const [messageError, setMessageError] = React.useState<string | null>(null)
  const listCallProgram =
    ['OB-SQCB', 'OB-RMD', 'OB-CARE1', 'OB-CARE2', 'OB-WCC', 'OB-APL', 'OB-ADHOCCCE', 'OB-ADHOCCS', 'OB-RMDBAU', 'OB-MA1', 'OB-MA2', 'OB-IDC', 'OB-LAPSED']

  const mapCallProgramTransfer = new Map<string, keyof TransferTransactionForm>([
    ['OB-WCC', 'wcc'],
    ['OB-SQCB', 'sqcb'],
    ['OB-RMD', 'rmd'],
    ['OB-APL', 'apl'],
    ['OB-CARE1', 'care1'],
    ['OB-CARE2', 'care2'],
    ['OB-MA1', 'ma1'],
    ['OB-MA2', 'ma2'],
    ['OB-IDC', 'idc'],
    ['OB-ADHOCCCE', 'adHocCCE'],
    ['OB-ADHOCCS', 'adHocCS'],
    ['OB-RMDBAU', 'rmdBau'],
    ['OB-LAPSED', 'lapsed']
  ])

  const formTransferTrans = useForm<TransferTransactionForm>({
    defaultValues: {
      id: '',
      user: null,
      status: statusOption[0],
      sqcb: false,
      rmd: false,
      care1: false,
      care2: false,
      wcc: false,
      rmdBau: false,
      idc: false,
      adHocCS: false,
      adHocCCE: false,
      apl: false,
      lapsed: false,
      ma1: false,
      ma2: false,
    }
  })

  const filterColumnList = {
    user: '',
    status: '',
  }

  const listMailManager = pipe(
    AdmintoolService.getListMailManager(),
    ZIO.map((res) => res && _.uniqBy(res.map((elm) => ({ label: elm.email ?? '', value: elm.email ?? '' })), 'value')),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen' }) },
        { title: t('Setting:AdminTool'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen', params: { tabTitle: 'adminTool' } }) },
        { title: t('Outbound:AdminTool:T4S17'), navigate: null }
      ])
    }

  }, [isFocused])

  React.useEffect(() => {
    pipe(
      AdmintoolService.getT4S17Config(),
      ZIO.map((res) => {
        res.data && setDataTable(res.data.map((i, index) => ({
          id: i.id ?? '',
          no: index + 1,
          user: i.email ?? '',
          status: i.status ? getTitleStatus(i.status) : '',
          sqcb: i.listUserGroupCode?.includes('OB-SQCB') ?? false,
          rmd: i.listUserGroupCode?.includes('OB-RMD') ?? false,
          care1: i.listUserGroupCode?.includes('OB-CARE1') ?? false,
          care2: i.listUserGroupCode?.includes('OB-CARE2') ?? false,
          wcc: i.listUserGroupCode?.includes('OB-WCC') ?? false,
          rmdBau: i.listUserGroupCode?.includes('OB-RMDBAU') ?? false,
          idc: i.listUserGroupCode?.includes('OB-IDC') ?? false,
          adHocCS: i.listUserGroupCode?.includes('OB-ADHOCCS') ?? false,
          adHocCCE: i.listUserGroupCode?.includes('OB-ADHOCCCE') ?? false,
          apl: i.listUserGroupCode?.includes('OB-APL') ?? false,
          lapsed: i.listUserGroupCode?.includes('OB-LAPSED') ?? false,
          ma1: i.listUserGroupCode?.includes('OB-MA1') ?? false,
          ma2: i.listUserGroupCode?.includes('OB-MA2') ?? false
        }))
        .sort((p1,p2) => p1.user.toLowerCase() > p2.user.toLowerCase() ? 1 : -1)
        .map((i, index) => ({
          ...i,
          no: index + 1,
        })))
        reload && setReload(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [reload])

  const columns = [
    {
      label: t('Outbound:AdminTool:No'),
      field: 'no',
      disabled: true
    },
    {
      label: t('Outbound:AdminTool:User'),
      field: 'user'
    },
    {
      label: t('Outbound:AdminTool:Status'),
      field: 'status',
      render: (value: string) => {
        return <Text style={getStyleStatus(value)}>{value}</Text>
      }
    },
    {
      label: t('OB-SQCB'),
      field: 'sqcb',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-RMD'),
      field: 'rmd',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-CARE1'),
      field: 'care1',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-CARE2'),
      field: 'care2',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-WCC'),
      field: 'wcc',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-RMDBAU'),
      field: 'rmdBau',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-IDC'),
      field: 'idc',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-ADHOCCS'),
      field: 'adHocCS',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-ADHOCCCE'),
      field: 'adHocCCE',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-APL'),
      field: 'apl',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-LAPSED'),
      field: 'lapsed',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-MA1'),
      field: 'ma1',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: t('OB-MA2'),
      field: 'ma2',
      render: (value: string) => {
        return value ? <assets.IBCheckRole /> : <></>
      }
    },
    {
      label: '',
      field: 'id',
      render: (value: string) => {
        return (
          roles.includes(Permission.UpdateT4S17SetupTransferTransaction) ?
            <TouchableOpacity
              onPress={() => {
                formTransferTrans.reset()
                setOpenEditPopup(true)
                const findData = dataTable.find((i) => i.id === value)
                const findMail = listMailManager?.find((i) => i.value === findData?.user)
                formTransferTrans.setValue('id', findData?.id ?? '')
                formTransferTrans.setValue('user', findMail ? findMail : findData?.user ? { value: findData?.user, label: findData?.user } : null)
                formTransferTrans.setValue('status', statusOption.find((i) => i.label === findData?.status) || null)
                formTransferTrans.setValue('sqcb', findData?.sqcb ?? false)
                formTransferTrans.setValue('rmd', findData?.rmd ?? false)
                formTransferTrans.setValue('care1', findData?.care1 ?? false)
                formTransferTrans.setValue('care2', findData?.care2 ?? false)
                formTransferTrans.setValue('wcc', findData?.wcc ?? false)
                formTransferTrans.setValue('rmdBau', findData?.rmdBau ?? false)
                formTransferTrans.setValue('idc', findData?.idc ?? false)
                formTransferTrans.setValue('adHocCS', findData?.adHocCS ?? false)
                formTransferTrans.setValue('adHocCCE', findData?.adHocCCE ?? false)
                formTransferTrans.setValue('apl', findData?.apl ?? false)
                formTransferTrans.setValue('lapsed', findData?.lapsed ?? false)
                formTransferTrans.setValue('ma1', findData?.ma1 ?? false)
                formTransferTrans.setValue('ma2', findData?.ma2 ?? false)
              }}
            >
              <assets.OBPencilGray />
            </TouchableOpacity> :
            <></>
        )
      }
    }
  ]

  const validateExistMail = (mail: string, idValue: string) => {
    const findData = dataTable.find((i) => i.user.toLocaleLowerCase() === mail.toLocaleLowerCase() && i.id !== idValue)
    if (findData) return false
    return true
  }

  const AddButton = () => {
    return (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', paddingBottom: 10 }}>
        <TouchableOpacity
          onPress={() => {
            setOpenAddPopup(true)
            formTransferTrans.reset()
          }}
        >
          <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight]}>
            <Text style={OBSharedStyles.actionHightLightText}>
              {t('Outbound:AdminTool:AddButton')}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  const TableConfig = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <IBGeneralTable
          dataTable={columns}
          data={dataTable}
          maxHeight={height - height * 0.3}
          maxWidth={width - width * 0.025}
          autoPaging
          maxWidthContainer
          filterColumn={filterColumnList}
        >
        </IBGeneralTable>
      </View>
    )
  }

  const FieldEdit = () => {
    return (
      <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <IBGeneralField
          FieldForm={formTransferTrans}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.SELECT,
              formName: 'user',
              title: t('Outbound:AdminTool:User'),
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:User') }) },
                validate: (option: SelectOption) => {
                  if (option.value && !validateMail(option.value))
                    return t('message:OB0028')
                  if (option.value && !validateExistMail(option.value, formTransferTrans.watch('id'))) {
                    return t('message:IB0020')
                  }
                  return true
                }
              },
              option: listMailManager ?? []
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'status',
              title: t('Outbound:AdminTool:Status'),
              option: statusOption,
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Status') }) },
              }
            },
          ]}
        >
        </IBGeneralField>
        <IBGeneralField
          FieldForm={formTransferTrans}
          col={5}
          typeInput={[
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'sqcb',
              title: t('OB-SQCB'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'rmd',
              title: t('OB-RMD'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'care1',
              title: t('OB-CARE1'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'care2',
              title: t('OB-CARE2'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'wcc',
              title: t('OB-WCC'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'rmdBau',
              title: t('OB-RMDBAU'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'idc',
              title: t('OB-IDC'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'adHocCS',
              title: t('OB-ADHOCCS'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'adHocCCE',
              title: t('OB-ADHOCCCE'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'apl',
              title: t('OB-APL'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'lapsed',
              title: t('OB-LAPSED'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'ma1',
              title: t('OB-MA1'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            },
            {
              type: TypeInputComponent.CHECKBOX,
              formName: 'ma2',
              title: t('OB-MA2'),
              textStyle: styles.textCheckBox,
              checkBoxStyle: styles.checkbox
            }
          ]}
        >
        </IBGeneralField>
        <View style={{ flexDirection: 'row', paddingTop: 10 }}>
          <Text style={{ color: '#ED1B2E' }}>
            {messageError}
          </Text>
        </View>
      </View>
    )
  }

  const AddPopup = () => {
    return (
      <ModalComponent
        children={FieldEdit()}
        visible={isOpenAddPopup}
        onClose={() => setOpenAddPopup(false)}
        title={t('Outbound:AdminTool:CreateNew')}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => setOpenAddPopup(false),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35, marginRight: 10 }
          },
          {
            text: t('common:Add'),
            type: 'filled',
            action: () => onAdd(),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35 }
          }
        ]}
        centerTitle
        modalWidth={850}
      />
    )
  }

  const EditPopup = () => {
    return (
      <ModalComponent
        children={FieldEdit()}
        visible={isOpenEditPopup}
        onClose={() => setOpenEditPopup(false)}
        title={t('Outbound:AdminTool:Edit')}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => setOpenEditPopup(false),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35, marginRight: 10 }
          },
          {
            text: t('common:Save'),
            type: 'filled',
            action: () => onSave(),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35 }
          }
        ]}
        centerTitle
        modalWidth={850}
      />
    )
  }

  const validateError = () => {
    setMessageError('')
    const data = formTransferTrans.watch()
    if (!(data.sqcb || data.rmd || data.care1 || data.care2 || data.wcc ||
      data.rmdBau || data.idc || data.adHocCCE || data.adHocCS || data.apl ||
      data.lapsed || data.ma1 || data.ma2)) {
      setMessageError(t('message:OB0094'))
      return false
    }
    // if(!roles.includes('OB-Manager') || !roles.includes('OB-Manager-BAU')) {
    //   setMessageError(t('message:OB0093'))
    //   return false
    // }
    return true
  }

  const onSave = formTransferTrans.handleSubmit((value) => {
    if (validateError()) {
      const listMappingCallProgram = [] as string[]
      listCallProgram.map((i) => {
        if (mapCallProgramTransfer.get(i) && (value[`${mapCallProgramTransfer.get(i) as keyof TransferTransactionForm}`])) {
          listMappingCallProgram.push(i as string)
        }
      })
      pipe(
        AdmintoolService.updateT4S17Config({
          id: value.id ?? '',
          email: value.user?.value ?? '',
          listUserGroupCode: listMappingCallProgram,
          status: value.status?.value ?? ''
        }),
        ZIO.map((res) => {
          setReload(true)
          setOpenEditPopup(false)
          return ZIO.unit
        }),
        ZIO.catchAll((error) => {
          showToast(error.source.message, 'error')
          setOpenEditPopup(false)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  })

  const onAdd = formTransferTrans.handleSubmit((value) => {
    if (validateError()) {
      const listMappingCallProgram = [] as string[]
      listCallProgram.map((i) => {
        if (mapCallProgramTransfer.get(i) && (value[`${mapCallProgramTransfer.get(i) as keyof TransferTransactionForm}`])) {
          listMappingCallProgram.push(i as string)
        }
      })
      pipe(
        AdmintoolService.addT4S17Config({
          email: value.user?.value ?? '',
          listUserGroupCode: listMappingCallProgram,
          status: value.status?.value ?? ''
        }),
        ZIO.map((res) => {
          setReload(true)
          setOpenAddPopup(false)
          return ZIO.unit
        }),
        ZIO.catchAll((error) => {
          showToast(error.source.message, 'error')
          setOpenAddPopup(false)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  })

  return (
    <View style={styles.container}>
      {
        roles.includes(Permission.ViewT4S17SetupTransferTransaction) &&
        <>
          {roles.includes(Permission.CreateNewT4S17SetupTransferTransaction) && AddButton()}
          {TableConfig()}
          {AddPopup()}
          {EditPopup()}
        </>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
    paddingVertical: 10
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  addButton: {
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    flexDirection: 'row',
    borderRadius: 8,
  },
  textCheckBox: {
    fontWeight: 'bold',
    color: '#70777E',
    marginLeft: 6
  },
  checkbox: {
    padding: 0
  }
})
