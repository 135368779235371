import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { View } from './styled'

export const RowLoading = () => {
  return (
    <View
      style={{
        alignItems: 'center'
      }}
    >
      <CircularProgress color={'primary'} size={50} />
    </View>
  )
}
