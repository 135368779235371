import i18next from 'i18next'
import { Status } from '../../supplementary-info/SupplementaryInfoForm'

export namespace InternalSuspendInformationConst {
  export interface LevelInfoData {
    code: string
    descriptionVi: string
    description: string
  }
  export const levelList: Array<LevelInfoData> = [
    {
      code: 'LV01',
      descriptionVi: 'Bình thường',
      description: 'Normal'
    },

    {
      code: 'LV02',
      descriptionVi: 'Khẩn',
      description: 'Urgent'
    },
    {
      code: 'LV03',
      descriptionVi: 'Nghiêm trọng',
      description: 'Critical'
    }
  ]

  export const statusList = () => [
    { value: Status.ACTIVE, label: i18next.t('Status:Active') },
    { value: Status.INACTIVE, label: i18next.t('Status:Inactive') },
    { value: Status.COMPLETE, label: i18next.t('Status:Complete') }
    // {
    //   code: 'S01',
    //   descriptionVi: 'Đang hiệu lực',
    //   description: 'Active'
    // },
    // {
    //   code: 'S02',
    //   descriptionVi: 'Hết hiệu lực',
    //   description: 'Inactive'
    // }
  ]
}
