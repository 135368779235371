import { Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View, useWindowDimensions } from 'react-native'
import React from 'react'
import {
  CategoryTabInfo,
  IBButton,
  IBCategoryTab,
  IBGeneralField,
  IBGeneralTable,
  TypeInputComponent,
  ClientScreenContext
} from '../../../common'
import { useTranslation } from 'react-i18next'
import { IBSearchTransactionForm } from './IBClientPolicyTransactionForm'
import {
  GeneralService,
  SelectOption,
  assets,
  SourceList,
  TaskService,
  PulseOpsFormat,
  Category,
  TaskSource,
  TaskOp,
  CategoryToLabel,
  TransactionLabel,
  InquiryComplaintService,
  TransactionStatusLabel,
  TransactionStatus,
  TransactionType,
  Permission,
  ErrorHandling,
  ServiceInquiryNote,
  ModalComponent,
  PulseOpsService,
  TaskComment,
  AppContext
} from '@pulseops/common'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { isEmpty, isNil, isNull } from 'lodash'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { IBModalTaskDetail } from './ib-modal-task-detail'
import { UploadDocumentDetail } from './upload-document-view'
import { Panel } from './commentPanel'
interface Props {
  setOfficeCodes: (offices: SelectOption[]) => void
  officeCodes: SelectOption[]
  categoryDefault: string
  clientNumber?: string
  listCommonTransaction:
    | {
        subServiceTypeList: SelectOption[]
        subTransactionTypeList: SelectOption[]
      }
    | undefined
  setListCommonTransaction: (data: {
    subServiceTypeList: SelectOption[]
    subTransactionTypeList: SelectOption[]
  }) => void
  permissions: string[]
}

const enum CancelPaymentStatus {
  Accept = 'ACCEPT_CANCEL',
  Reject = 'REJECT_CANCEL'
}

const cancelPaymentLabel = [
  {
    value: CancelPaymentStatus.Accept,
    label: 'Accept cancel'
  },
  {
    value: CancelPaymentStatus.Reject,
    label: 'Reject cancel'
  }
]

export const ServiceInquiryCategories: SelectOption[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Complaint',
    value: Category.COMPLAINT
  },
  {
    label: 'Inquiry',
    value: Category.INQUIRY
  },
  {
    label: 'PolicyServices',
    value: Category.POLICY_SERVICE
  },
  {
    label: 'General',
    value: Category.GENERAL
  },
  {
    label: 'PremiumCollection',
    value: Category.PREMIUM_COLLECTION
  },
  {
    label: 'DistributionServices',
    value: Category.DISTRIBUTION_SERVICES
  }
]
const showCancelPaymentText = (value: string) => {
  return cancelPaymentLabel.find((item) => item.value === value)?.label ?? '-'
}
export const IBClientPolicyTransaction = (props: Props) => {
  const {
    setOfficeCodes,
    officeCodes,
    categoryDefault,
    clientNumber,
    listCommonTransaction,
    setListCommonTransaction,
    permissions
  } = props
  const { t, i18n } = useTranslation('Inbound')
  const { height } = useWindowDimensions()
  const categoryDefaultMap = ServiceInquiryCategories.find((x) => x.value === categoryDefault)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { listClientNumber } = React.useContext(ClientScreenContext)
  const defaultValues: IBSearchTransactionForm = {
    contractNumber: '',
    fromDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    toDate: new Date(),
    clientNumber: { label: clientNumber as string, value: clientNumber as string },
    office: [],
    source: [],
    category: categoryDefaultMap
      ? { label: t(`Reports:${categoryDefaultMap?.label}`), value: categoryDefaultMap?.value }
      : {
          label: 'All',
          value: ''
        }
  }

  const [loading, bindLoading] = useLoading(false)
  const [rows, setRows] = React.useState<TaskOp[]>([])
  const [objDetail, setObjDetail] = React.useState<{
    visible: boolean
    id: string
    category: string
    suspendReason: string
  }>({ visible: false, id: '', category: '', suspendReason: '' })
  const SearchForm = useForm<IBSearchTransactionForm>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [formError, setFormError] = React.useState<string>('')
  const [noteDetail, setNoteDetail] = React.useState<ServiceInquiryNote>({
    customerComment: '',
    closedInfo: '',
    responseToCustomer: ''
  })

  const [infoVisible, setInfoVisible] = React.useState<boolean>(false)
  const [uploadDocVisible, setUploadDocVisible] = React.useState<boolean>(false)
  const [uploadDocumentTaskId, setUploadDocumentTaskId] = React.useState<string>()
  const [PSComment, setPSComment] = React.useState<boolean>(false)
  const [PSCommentData, setPSCommentData] = React.useState<TaskComment[]>([])
  const isFocused = useIsFocused()

  React.useEffect(() => {
    if (!listCommonTransaction) {
      pipe(
        ZIO.zipPar(InquiryComplaintService.getTypes(), InquiryComplaintService.getSubType()),
        ZIO.map(([subService, subTransaction]) => {
          const subServiceTypes =
            subService.slice().map((x) => ({
              label: i18n.language === 'en' ? x.name : x.nameVi,
              value: x.code
            })) ?? []
          const subTransactionTypes =
            subTransaction.slice().map((x) => ({
              label: i18n.language === 'en' ? x.name : x.nameVi,
              value: x.code,
              typeCode: x.typeCode
            })) ?? []
          onSearch()
          setListCommonTransaction({
            subServiceTypeList: subServiceTypes,
            subTransactionTypeList: subTransactionTypes
          })
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      onSearch()
    }
  }, [isFocused])

  React.useEffect(() => {
    if (isEmpty(officeCodes)) {
      pipe(
        GeneralService.getALLOfficeList(),
        ZIO.map((codes) => {
          setOfficeCodes(
            codes.map((code) => ({
              value: code.code,
              label: `${code.code} - ${i18n.language === 'vi' ? code.nameVi : code.nameEn}`
            }))
          )
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [isFocused])

  React.useEffect(() => {
    if (!isEmpty(rows)) {
      onSearch()
    }
  }, [pageNum, pageSize])

  const validateFormSearch = (data: IBSearchTransactionForm): boolean => {
    const toDate = PulseOpsFormat.serviceInquiry_StartOfDate(data.toDate as Date)
    const fromDate = PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate as Date)
    if (isNil(data.fromDate) || isNil(data.toDate)) {
      setFormError(t('message:MS020009', { field: isNil(data.fromDate) ? t('FromDate') : t('ToDate') }))
      return false
    } else if (moment(data.fromDate).diff(moment(data.toDate)) > 0) {
      setFormError(t('message:MS030044'))
      return false
    } else if (
      Number(
        moment
          .duration(moment(toDate).diff(moment(fromDate)), 'milliseconds')
          .asMonths()
          .toFixed(2)
      ) > 3.02 &&
      !isEmpty(data.office)
    ) {
      setFormError(t('message:MS080004', { k: 3 }))
      return false
    }
    return true
  }

  const onSearch = SearchForm.handleSubmit((data) => {
    if (validateFormSearch(data)) {
      pipe(
        TaskService.getHistoricTasksInbound({
          pagination: {
            pageIndex: pageNum,
            pageSize: pageSize
          },
          order: 'desc',
          filter: {
            clientNum: data.clientNumber.value,
            policyNum: data.contractNumber || undefined,
            fromDate: PulseOpsFormat.serviceInquiry_StartOfDate(data.fromDate as Date, true),
            toDate: PulseOpsFormat.serviceInquiry_endOfDate(data.toDate as Date, true),
            source: !isEmpty(data.source) ? data.source?.map((x) => x.value as TaskSource) : undefined,
            offices: !isEmpty(data.office) ? data.office?.map((x) => x.value) : undefined,
            categories: data.category?.value
              ? [data.category?.value]
              : [
                  Category.COMPLAINT,
                  Category.INQUIRY,
                  Category.POLICY_SERVICE,
                  Category.GENERAL,
                  Category.PREMIUM_COLLECTION
                ],
            transactionType: undefined,
            subServiceTypes: undefined,
            subTransactionTypes: undefined
          }
        }),
        ZIO.map((res) => {
          if (!isEmpty(res.items)) {
            setTotalItem(res.total)
            setFormError('')
            setRows(
              res.items.map((item) => {
                return {
                  ...item,
                  policyNumber:
                    (isNil(item.policyNumber) || item.policyNumber === 'null'
                      ? item.proposalNumber
                      : item.policyNumber) || '-',
                  categoryName: t(`Reports:${ServiceInquiryCategories.find((x) => x.value === item.category)?.label}`),
                  transactionName: t(TransactionLabel(item.transactionType)),
                  subServiceTypeName:
                    listCommonTransaction?.subServiceTypeList?.find((x) => x.value === item.subServiceType)?.label ||
                    '-',
                  subTransactionTypeName:
                    listCommonTransaction?.subTransactionTypeList?.find((x) => x.value === item.subTransactionType)
                      ?.label || '-',
                  userSubmission: item.createdBy ? item.createdBy.split('@')[0] : '-',
                  createdDateName: moment(item.createdDate).format('YYYYMMDDHHmmss'),
                  lastUpdateDateName: item.lastUpdateDateAdj
                    ? moment(item.lastUpdateDateAdj).format('YYYYMMDDHHmmss')
                    : item.lastUpdateDateVer
                    ? moment(item.lastUpdateDateVer).format('YYYYMMDDHHmmss')
                    : '-',
                  lastUpdateBy: item.lastUpdateByAdj
                    ? item.lastUpdateByAdj.split('@')[0]
                    : item.lastUpdateByVer
                    ? item.lastUpdateByVer.split('@')[0]
                    : '-',
                  cancelPaymentStatus: item.cancelPaymentStatus ? showCancelPaymentText(item.cancelPaymentStatus) : '-',
                  serviceTypeName: '-',
                  groupName: '-'
                }
              })
            )
          } else {
            setRows([])
            setFormError(t('message:MS030029'))
          }
          return ZIO.unit
        }),
        ZIO.catchAll((e) => {
          setFormError(t('message:MS030029'))
          setRows([])
          return ZIO.succeed([])
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  })

  const getServiceInquiryNote = (taskId: string) => {
    showGlobalLoading(true)
    pipe(
      TaskService.getServiceInquiryNote(taskId),
      ZIO.tap((note) => {
        setNoteDetail({
          customerComment: note.customerComment,
          closedInfo: note.closedInfo,
          responseToCustomer: note.responseToCustomer
        })
        setInfoVisible(true)
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  const showUploadDocument = (taskId: string) => {
    setUploadDocVisible(true)
    setUploadDocumentTaskId(taskId)
  }

  const getCommentById = (processId: string) => {
    showGlobalLoading(true)
    return pipe(
      PulseOpsService.getCommentList(processId),
      ZIO.tap((comments) => {
        showGlobalLoading(false), setPSComment(true)
        setPSCommentData(comments ?? [])
        return ZIO.unit
      }),
      ZIO.mapError((e) => {
        showGlobalLoading(false)
        return ZIO.fail(e)
      }),
      ZIO.unsafeRun({})
    )
  }

  const dataTable = [
    {
      label: t('Inbound:table:CaseID'),
      disabled: true,
      field: 'caseId',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              const caseInfo = rows.find((x) => x.caseId === value)
              setObjDetail({
                visible: true,
                id: caseInfo?.id as string,
                category: caseInfo?.category as string,
                suspendReason: caseInfo?.suspendReasonPC as string
              })
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline',
                width: 400
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('Inbound:table:Source'),
      field: 'source'
    },
    {
      label: t('Inbound:table:PolicyNumber'),
      field: 'policyNumber'
    },
    {
      label: t('Inbound:table:Category'),
      field: 'categoryName'
    },
    {
      label: t('Inbound:table:ServiceType'),
      field: 'serviceTypeName'
    },
    {
      label: t('Inbound:table:Transaction'),
      field: 'transactionName'
    },
    {
      label: t('Inbound:table:Group'),
      field: 'groupName'
    },
    {
      label: t('Inbound:table:SubService'),
      field: 'subServiceTypeName'
    },
    {
      label: t('Inbound:table:SubTransaction'),
      field: 'subTransactionTypeName'
    },
    {
      label: t('Inbound:table:UserSubmission'),
      field: 'userSubmission'
    },
    {
      label: t('Inbound:table:CreatedDate'),
      field: 'createdDateName',
      format: 'dateTime'
    },
    {
      label: t('Inbound:table:LastAssignee'),
      field: 'assigneeName'
    },
    {
      label: t('Inbound:table:Aging'),
      field: 'aging'
    },
    {
      label: t('Inbound:table:LastUpdatedDate'),
      field: 'lastUpdateDateName',
      format: 'dateTime'
    },
    {
      label: t('Inbound:table:LastUpdatedBy'),
      field: 'lastUpdateBy'
    },
    {
      label: t('Inbound:table:CancelPaymentStatus'),
      field: 'cancelPaymentSatus'
    },
    {
      label: t('Inbound:table:Status'),
      field: 'status',
      render: (value: string) => {
        return (
          <View style={{ width: 100 }}>
            <Text style={{ color: TransactionStatusLabel(value as TransactionStatus).color, fontSize: 15 }}>
              {TransactionStatusLabel(value as TransactionStatus).label}
            </Text>
          </View>
        )
      }
    },
    {
      label: t('Inbound:table:CurrentActivity'),
      field: 'activity'
    },
    {
      label: t('Inbound:table:Note'),
      field: 'id',
      disabled: true,
      render: (value: string) => {
        const object = rows.find((x) => x.id === value)
        return (
          <>
            {permissions.includes(Permission['ViewNoteServiceInquiry']) &&
            [Category.COMPLAINT, Category.INQUIRY].includes(object?.category as Category) ? (
              <TouchableOpacity onPress={() => getServiceInquiryNote(value)}>
                <View style={styles.btnDetail}>
                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                </View>
              </TouchableOpacity>
            ) : object?.transactionType === TransactionType.UPLOAD_DOCUMENT ? (
              <TouchableOpacity onPress={() => showUploadDocument(value)}>
                <View style={styles.btnDetail}>
                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                </View>
              </TouchableOpacity>
            ) : (
              <Text> {'-'}</Text>
            )}
          </>
        )
      }
    },
    {
      label: '',
      field: 'id',
      render: (value: string) => {
        return (
          <View>
            <TouchableOpacity
              onPress={() => {
                getCommentById(value)
              }}
            >
              <assets.IconComment />
            </TouchableOpacity>
          </View>
        )
      }
    }
  ]

  const handleLinkIheat = () => {
    const pathname = window.location.pathname
    const splitStr = pathname.split('/')
    const url = pathname.replace(
      `/${splitStr[splitStr.length - 3]}/${splitStr[splitStr.length - 2]}/${splitStr[splitStr.length - 1]}`,
      ''
    )
    window.open(`${url}/utility/inquiryiheat?clientNumber=${clientNumber}`)
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={{ marginVertical: 10 }}>
        <Pressable style={styles.btnIHeat} onPress={handleLinkIheat}>
          <Text style={{ color: '#ED1B2E', fontSize: 15, fontWeight: 'bold' }}>iHeat</Text>
          <assets.IBArrowRight />
        </Pressable>
      </View>
      <View style={styles.searchContainer}>
        <IBGeneralField
          FieldForm={SearchForm}
          col={4}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'contractNumber',
              title: t('common:PolicyNumber'),
              inputType: 'number',
              maxLength: 8,
              placeHolder: t('common:Input'),
              disabled: loading,
              alwayShowUnderline: true
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'clientNumber',
              title: t('ClientNumber'),
              option: listClientNumber,
              placeholder: t('Select'),
              rules: {
                validate: () => {
                  return isNil(SearchForm.watch('clientNumber'))
                    ? `${t('message:MS020009', { field: t('ClientNumber') })}`
                    : true
                }
              },
              required: true
            },
            {
              type: TypeInputComponent.DATE,
              formName: 'fromDate',
              title: t('FromDate'),
              rules: {
                validate: () => {
                  const currentDate = new Date()
                  return !isNull(SearchForm.watch('fromDate')) &&
                    (SearchForm.watch('fromDate')?.getTime() as number) > currentDate.getTime()
                    ? `${t('message:MS990032')}`
                    : true
                }
              },
              disabled: loading,
              required: true,
              maxDate: SearchForm.watch('toDate'),
              maxDateMessage: t('message:MS990032')
            },
            {
              type: TypeInputComponent.DATE,
              formName: 'toDate',
              title: t('ToDate'),
              rules: {
                validate: () => {
                  const currentDate = new Date()
                  return !isNull(SearchForm.watch('toDate')) &&
                    (SearchForm.watch('toDate')?.getTime() as number) > currentDate.getTime()
                    ? `${t('message:MS990032')}`
                    : true
                }
              },
              disabled: loading,
              required: true,
              maxDate: new Date(),
              maxDateMessage: t('message:MS990032')
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'office',
              title: t('Office'),
              option: officeCodes,
              placeholder: t('Select'),
              multiple: true
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'source',
              title: t('Source'),
              option: [...SourceList,... [
                { label: 'Outbound', value: 'OUTBOUND' },
                { label: 'Inbound', value: 'INBOUND' }
              ]],
              placeholder: t('Select'),
              multiple: true
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'category',
              title: t('Category'),
              option: ServiceInquiryCategories.map((item) => ({
                label: t(`Reports:${item.label}`),
                value: item.value
              })),
              placeholder: t('Select')
            }
          ]}
        />
        <View style={styles.buttonContainer}>
          <IBButton onPress={onSearch} title={t('Search')} backgroundFill />
        </View>
      </View>
      <View style={{ marginLeft: 15, marginBottom: 15 }}>
        {formError ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{formError}</Text> : null}
      </View>
      <View style={{ marginHorizontal: 15 }}>
        {rows ? (
          <IBGeneralTable
            dataTable={dataTable}
            data={rows}
            loading={loading}
            paging={{
              page: pageNum,
              pageSize: pageSize,
              taskCount: totalItem,
              setPage: setPageNum,
              setPageSize: setPageSize
            }}
            paginationStyle={{ marginRight: 60 }}
          />
        ) : (
          <></>
        )}
      </View>
      {!isEmpty(objDetail.id) && (
        <IBModalTaskDetail visible={objDetail.visible} onClose={setObjDetail} objDetail={objDetail} />
      )}
      <ModalComponent
        title={t('ServiceInquiry:DetailNote')}
        visible={infoVisible}
        onClose={() => setInfoVisible(false)}
        actions={[
          {
            text: t('common:Close'),
            type: 'filled',
            disabled: false,
            loading,
            onlyWide: false,
            style: { height: 40 },
            action: () => setInfoVisible(false)
          }
        ]}
      >
        <View style={{ marginHorizontal: 20, marginTop: 20, maxHeight: height - 200 }}>
          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:CustomerComment')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#4f4f4f',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.customerComment}</Text>
            </ScrollView>
          </View>
          {/* Response to Customer */}
          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ResponseToCustomer')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#4f4f4f',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.responseToCustomer}</Text>
            </ScrollView>
          </View>

          <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ClosedInfo')}</Text>
          <View
            style={{
              minHeight: 80,
              maxHeight: 'calc((100vh - 300px)/2)',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#4f4f4f',
              backgroundColor: '#fff',
              padding: 9
            }}
          >
            <ScrollView>
              <Text>{noteDetail.closedInfo}</Text>
            </ScrollView>
          </View>
        </View>
      </ModalComponent>

      <ModalComponent
        title={t('common:Detail')}
        visible={uploadDocVisible}
        onClose={() => {
          setUploadDocVisible(false)
          //setUploadDocumentTaskId(undefined)
        }}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'filled',
            disabled: false,
            loading,
            onlyWide: false,
            style: { height: 40 },
            action: () => {
              setUploadDocVisible(false)
              //setUploadDocumentTaskId(undefined)
            }
          }
        ]}
      >
        {uploadDocumentTaskId && <UploadDocumentDetail taskId={uploadDocumentTaskId} />}
      </ModalComponent>

      <ModalComponent
        title={t('ServiceInquiry:CommentDetail')}
        visible={PSComment}
        onClose={() => {
          setPSComment(false), setPSCommentData([])
        }}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'filled',
            disabled: false,
            loading,
            onlyWide: false,
            style: { height: 40 },
            action: () => {
              setPSComment(false), setPSCommentData([])
            }
          }
        ]}
        modalWidth={864}
      >
        {PSCommentData.length > 0 && (
          <View
            style={{
              marginHorizontal: 20,
              marginTop: 20,
              maxHeight: height - 200,
              borderWidth: 1,
              borderRadius: 8,
              borderColor: '#dcdcdc',
              paddingTop: 16,
              paddingHorizontal: 16
            }}
          >
            {PSCommentData.map((user, index) => (
              <Panel
                key={index}
                title={user.username}
                comment={PulseOpsFormat.datetoFormat(user.time, 'DD/MM/YYYY HH:mm:ss')}
                isHiddenTitle={false}
                isExand={true}
                isHaveComment
              >
                <Text>{user.message}</Text>
              </Panel>
            ))}
          </View>
        )}
        {PSCommentData.length === 0 && (
          <View
            style={{
              marginHorizontal: 20,
              marginTop: 20,
              maxHeight: height - 200,
              borderWidth: 1,
              borderRadius: 8,
              borderColor: '#dcdcdc',
              paddingHorizontal: 16,
              minHeight: 80
            }}
          >
            <Text style={{ marginVertical: 'auto' }}>{t('ServiceInquiry:CommentNotFound')}</Text>
          </View>
        )}
      </ModalComponent>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'flex-start',
    width: '100%'
  },
  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },
  btnIHeat: {
    borderWidth: 1,
    borderColor: '#ED1B2E',
    width: 100,
    marginLeft: 20,
    padding: 10,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
