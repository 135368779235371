import { TopUp } from './Topup'

export namespace FundSwitchingModel {
  export interface Detail {
    productCode: string
    targetPremiumAccount: TopUp.PolicyExtraInfoFund[]
    topUpAccount: TopUp.PolicyExtraInfoFund[]
  }

  export interface AccessData {
    coverageCode: string
    contractStatus: string
  }

  export interface SourceFundData {
    sourceFundCode: string
    unitValue: number
    sourcePercentage: number
    sourceAmount: number
  }

  export interface TargetFundData {
    targetFundCode: string
    targetPercentage: number
    targetAmount: number
  }

  export interface SubmitData {
    attributesExt: {
      PRIMARY_POLICY: string
      PO_CLIENT_NUMBER: string
      GA_OFFICE_CODE: string
      OPTION_FLAG: boolean
      FUND_TYPE: 'MSP_C_FWA' | 'MSP_C_FWD' //1 = MSP_C_FWA , 2 = MSP_C_FWD
      SOURCE_FUND_LIST: SourceFundData[]
      TARGET_FUND_LIST: TargetFundData[]
    }
  }
}
