import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
export const coreResponseDetailC = t.array(
  t.type({
    status: Maybe(t.string),
    methodName: Maybe(t.string),
    policyNo: Maybe(t.string),
    historyActions: t.array(
      t.type({
        output: Maybe(
          t.type({
            oErrorResult: Maybe(
              t.type({
                errorCode: Maybe(t.string),
                errorDescription: Maybe(t.string)
              })
            )
          })
        )
      })
    )
  })
)
export type CoreResponseDetail = t.TypeOf<typeof coreResponseDetailC>
export interface TaskDetailBase {
  id: string
  processInstanceId: string | null | undefined
  correlationId: string | null | undefined
  businessKey: string
  pendingNum: string | null | undefined
  coreResponseDetail: CoreResponseDetail | null
  clientNumber: string | null | undefined
  source: string | null | undefined
  createdBy?: string | null
}

export const TaskDetailBase = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      id: t.string,
      processInstanceId: Maybe(t.string),
      policyNumber: Maybe(t.string),
      claimNumber: Maybe(t.string),
      clientNumber: Maybe(t.string),
      correlationId: Maybe(t.string),
      businessKey: t.string,
      assignee: Maybe(t.string),
      coreResponseDetail: t.union([coreResponseDetailC, t.null]),
      updateToCore: t.boolean,
      updateToCoreConfig: t.boolean,
      pendingNum: Maybe(t.string),
      category: Maybe(t.string),
      proposalNumber: Maybe(t.string),
      source: Maybe(t.string),
      payload: t.type({
        authenOption: Maybe(t.string),
        officeCode: Maybe(t.string),
        createdDate: Maybe(t.string)
      }),
      paymentNumber: Maybe(t.string),
      isCancelPayment: Maybe(t.boolean),
      amlResult: Maybe(t.string),
      redFlagResult: Maybe(t.boolean),
      unsuspendTypeAML: Maybe(t.string),
      uwResult: Maybe(t.string),
      pvNumber: Maybe(t.string),
      pvStatus: Maybe(t.string),
      p40ProcessInstanceId: Maybe(t.string)
    }),
    codec
    // t.type({
    //   processInstanceId: Maybe(t.string),

    // })
  ])
