import React from 'react'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Text, TouchableOpacity, View, StyleSheet, TextInput } from 'react-native'
import { assets, DigitalInquiryService, ErrorHandling, exportCSV, Permission, PulseOpsFormat } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

type ExportList = any

const useStyles = makeStyles({
  dialog: {},
  dialogHeader: {
    backgroundColor: '#FFF'
  },
  dialogContent: {},
  dialogActions: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export type PolicyInquiryExportPopupProps = {
  onClose: () => void
  open: boolean
  selectedValue: {
    nationalId: string
    policy: string
    fromDate: string
    toDate: string
    roles: string[]
  }
}

export const PolicyInquiryExportPopup = (props: PolicyInquiryExportPopupProps) => {
  const { dialog, dialogContent, dialogHeader, dialogActions } = useStyles()
  const { onClose, selectedValue, open } = props

  const { t } = useTranslation()

  const [reason, setReason] = React.useState<string>('')
  const [errMess, setErrMess] = React.useState<string>('')

  const [loading, bindLoader] = useLoading(false)

  const mapping1 = (exportData: string[][]) => (data: ExportList) => {
    exportData.push([
      'No',
      'ProposalNo',
      'PolicyNo',
      'ProductName',
      'PlanName',
      'ProductCode',
      'Partner/Source',
      'firstIssueDate',
      'InceptionDate',
      'ExpiryDate',
      'CoveragetotalPremium',
      'CoveragetotalSummAssured',
      'RiderRiskCessDate',
      'RiderRiskCommencementDate',
      'Riderstatus',
      'Status',
      'Referral Code',
      'Fullname',
      'Gender',
      'Dob',
      'NationalID',
      'EmailID',
      'Phonenum',
      'Address',
      'TransactionID',
      'OrderID/Order SN',
      'Buyer UserID',
      'Activator UserID',
      'Cost/Voucher',
      'Different Voucher Amount',
      'APE',
      'Voucher code/Activation Code'
    ])
    return [
      ...exportData,
      ...data.reduce((final: any[], x: any) => {
        return [
          ...final,
          ...x.riderInfos.reduce(
            (rider: any, y: any, i: any) => [
              ...rider,
              [
                (final.length + i + 1).toString(),
                x.proposalNo,
                x.policyNo,
                x.productName,
                x.planName,
                x.productCode,
                x.source,
                x.firstIssueDate,
                x.inceptionDate,
                x.expiryDate,
                x.coverageTotalPremium,
                x.coverageTotalSumAssured,
                y.riskCessDate,
                y.riskCommencementDate,
                y.status,
                x.status,
                x.referralCode,
                x.customerInfo.fullName,
                x.customerInfo.gender,
                x.customerInfo.dob,
                x.customerInfo.nationalID,
                x.customerInfo.emailID,
                x.customerInfo.phoneNum,
                x.customerInfo.address,
                x.transactionId,
                x.partnerOrderId,
                x.buyerUserId,
                x.activatorUserId,
                x.costVoucher,
                x.differentVoucherAmount,
                x.aPE,
                x.encryptedActivationCode
              ]
            ],
            [] as any[]
          )
        ]
      }, [] as any[])
    ]
  }

  const mapping2 = (exportData: string[][]) => (data: ExportList) => {
    exportData.push([
      'No',
      'ProposalNo',
      'PolicyNo',
      'ProductName',
      'PlanName',
      'ProductCode',
      'Partner/Source',
      'firstIssueDate',
      'InceptionDate',
      'ExpiryDate',
      'CoveragetotalPremium',
      'CoveragetotalSummAssured',
      'RiderRiskCessDate',
      'RiderRiskCommencementDate',
      'Riderstatus',
      'Status',
      'Referral Code',
      'TransactionID',
      'OrderID/Order SN',
      'Buyer UserID',
      'Activator UserID',
      'Cost/Voucher',
      'Different Voucher Amount',
      'APE',
      'Voucher code/Activation Code'
    ])
    return [
      ...exportData,
      ...data.reduce((final: any[], x: any) => {
        return [
          ...final,
          ...x.riderInfos.reduce(
            (rider: any, y: any, i: any) => [
              ...rider,
              [
                (final.length + i + 1).toString(),
                x.proposalNo,
                x.policyNo,
                x.productName,
                x.planName,
                x.productCode,
                x.source,
                x.firstIssueDate,
                x.inceptionDate,
                x.expiryDate,
                x.coverageTotalPremium,
                x.coverageTotalSumAssured,
                y.riskCessDate,
                y.riskCommencementDate,
                y.status,
                x.status,
                x.referralCode,
                x.transactionId,
                x.partnerOrderId,
                x.buyerUserId,
                x.activatorUserId,
                x.costVoucher,
                x.differentVoucherAmount,
                x.aPE,
                x.encryptedActivationCode
              ]
            ],
            [] as any[]
          )
        ]
      }, [] as any[])
    ]
  }

  const mapping3 = (exportData: string[][]) => (data: ExportList) => {
    exportData.push([
      'No',
      'ProposalNo',
      'PolicyNo',
      'ProductName',
      'PlanName',
      'ProductCode',
      'Partner/Source',
      'firstIssueDate',
      'InceptionDate',
      'ExpiryDate',
      'CoveragetotalPremium',
      'CoveragetotalSummAssured',
      'RiderRiskCessDate',
      'RiderRiskCommencementDate',
      'Riderstatus',
      'Status',
      'Referral Code',
      'Fullname',
      'NationalID',
      'TransactionID',
      'OrderID/Order SN',
      'Buyer UserID',
      'Activator UserID',
      'Cost/Voucher',
      'Different Voucher Amount',
      'APE',
      'Voucher code/Activation Code'
    ])
    return [
      ...exportData,
      ...data.reduce((final: any[], x: any) => {
        return [
          ...final,
          ...x.riderInfos.reduce(
            (rider: any, y: any, i: any) => [
              ...rider,
              [
                (final.length + i + 1).toString(),
                x.proposalNo,
                x.policyNo,
                x.productName,
                x.planName,
                x.productCode,
                x.source,
                x.firstIssueDate,
                x.inceptionDate,
                x.expiryDate,
                x.coverageTotalPremium,
                x.coverageTotalSumAssured,
                y.riskCessDate,
                y.riskCommencementDate,
                y.status,
                x.status,
                x.referralCode,
                x.customerInfo.fullName,
                x.customerInfo.nationalID,
                x.transactionId,
                x.partnerOrderId,
                x.buyerUserId,
                x.activatorUserId,
                x.costVoucher,
                x.differentVoucherAmount,
                x.aPE,
                x.encryptedActivationCode
              ]
            ],
            [] as any[]
          )
        ]
      }, [] as any[])
    ]
  }

  const mapping4 = (exportData: string[][]) => (data: ExportList) => {
    exportData.push([
      'No',
      'ProposalNo',
      'PolicyNo',
      'ProductName',
      'PlanName',
      'ProductCode',
      'Partner/Source',
      'firstIssueDate',
      'InceptionDate',
      'ExpiryDate',
      'CoveragetotalPremium',
      'CoveragetotalSummAssured',
      'RiderRiskCessDate',
      'RiderRiskCommencementDate',
      'Riderstatus',
      'Status',
      'Referral Code',
      'Dob',
      'Address',
      'TransactionID',
      'OrderID/Order SN',
      'Buyer UserID',
      'Activator UserID',
      'Cost/Voucher',
      'Different Voucher Amount',
      'APE',
      'Voucher code/Activation Code'
    ])
    return [
      ...exportData,
      ...data.reduce((final: any[], x: any) => {
        return [
          ...final,
          ...x.riderInfos.reduce(
            (rider: any, y: any, i: any) => [
              ...rider,
              [
                (final.length + i + 1).toString(),
                x.proposalNo,
                x.policyNo,
                x.productName,
                x.planName,
                x.productCode,
                x.source,
                x.firstIssueDate,
                x.inceptionDate,
                x.expiryDate,
                x.coverageTotalPremium,
                x.coverageTotalSumAssured,
                y.riskCessDate,
                y.riskCommencementDate,
                y.status,
                x.status,
                x.referralCode,
                x.customerInfo.dob,
                x.customerInfo.address,
                x.transactionId,
                x.partnerOrderId,
                x.buyerUserId,
                x.activatorUserId,
                x.costVoucher,
                x.differentVoucherAmount,
                x.aPE,
                x.encryptedActivationCode
              ]
            ],
            [] as any[]
          )
        ]
      }, [] as any[])
    ]
  }

  const mapping5 = (exportData: string[][]) => (data: ExportList) => {
    exportData.push([
      'No',
      'ProposalNo',
      'PolicyNo',
      'ProductName',
      'PlanName',
      'ProductCode',
      'Partner/Source',
      'firstIssueDate',
      'InceptionDate',
      'ExpiryDate',
      'CoveragetotalPremium',
      'CoveragetotalSummAssured',
      'RiderRiskCessDate',
      'RiderRiskCommencementDate',
      'Riderstatus',
      'Status',
      'Referral Code',
      'Gender',
      'Dob',
      'TransactionID',
      'OrderID/Order SN',
      'Buyer UserID',
      'Activator UserID',
      'Cost/Voucher',
      'Different Voucher Amount',
      'APE',
      'Voucher code/Activation Code'
    ])
    return [
      ...exportData,
      ...data.reduce((final: any[], x: any) => {
        return [
          ...final,
          ...x.riderInfos.reduce(
            (rider: any, y: any, i: any) => [
              ...rider,
              [
                (final.length + i + 1).toString(),
                x.proposalNo,
                x.policyNo,
                x.productName,
                x.planName,
                x.productCode,
                x.source,
                x.firstIssueDate,
                x.inceptionDate,
                x.expiryDate,
                x.coverageTotalPremium,
                x.coverageTotalSumAssured,
                y.riskCessDate,
                y.riskCommencementDate,
                y.status,
                x.status,
                x.referralCode,
                x.customerInfo.gender,
                x.customerInfo.dob,
                x.transactionId,
                x.partnerOrderId,
                x.buyerUserId,
                x.activatorUserId,
                x.costVoucher,
                x.differentVoucherAmount,
                x.aPE,
                x.encryptedActivationCode
              ]
            ],
            [] as any[]
          )
        ]
      }, [] as any[])
    ]
  }

  const onExport = () => {
    if (reason.length > 50) {
      pipe(
        DigitalInquiryService.exportPolicyData({
          reason: reason,
          policyNum: selectedValue.policy,
          nationalId: selectedValue.nationalId,
          fromDate: selectedValue.fromDate,
          toDate: selectedValue.toDate
        }),
        ZIO.map((res) => {
          const inputData: ExportList = res.map((x) => ({
            ...x,
            firstIssueDate: PulseOpsFormat.dateStrWF(x.firstIssueDate, 'DD/MM/yyyy'),
            inceptionDate: PulseOpsFormat.dateStrWF(x.inceptionDate, 'DD/MM/yyyy'),
            expiryDate: PulseOpsFormat.dateStrWF(x.expiryDate, 'DD/MM/yyyy'),
            customerInfo: {
              ...x.customerInfo,
              dob: PulseOpsFormat.dateStrWF(x.customerInfo.dob, 'DD/MM/yyyy')
            },
            riderInfos:
              x.riderInfos.length > 0
                ? [
                    {
                      ...x.riderInfos[0],
                      riskCommencementDate: PulseOpsFormat.dateStrWF(
                        x.riderInfos[0].riskCommencementDate,
                        'DD/MM/yyyy'
                      ),
                      riskCessDate: PulseOpsFormat.dateStrWF(x.riderInfos[0].riskCessDate, 'DD/MM/yyyy')
                    }
                  ]
                : [
                    {
                      policyNo: '',
                      componentCode: '',
                      componentName: '',
                      riskCessDate: '',
                      riskCommencementDate: '',
                      status: '',
                      sumAssured: '',
                      riderFlag: ''
                    }
                  ]
          }))
          const fromDateToDate =
            selectedValue.fromDate && selectedValue.toDate
              ? `${PulseOpsFormat.dateStrWF(selectedValue.fromDate, 'DD/MM/yyyy')} - ${PulseOpsFormat.dateStrWF(
                  selectedValue.toDate,
                  'DD/MM/yyyy'
                )}`
              : ''
          const commonData = [
            ['PULSE DPAS POLICY'],
            [`Reporting period: ${fromDateToDate}`],
            [`Reporting date: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`]
          ]
          const data: string[][] | null = selectedValue.roles.includes(Permission['DI-Export1'])
            ? mapping1(commonData)(inputData)
            : selectedValue.roles.includes(Permission['DI-Export2'])
            ? mapping2(commonData)(inputData)
            : selectedValue.roles.includes(Permission['DI-Export3'])
            ? mapping3(commonData)(inputData)
            : selectedValue.roles.includes(Permission['DI-Export4'])
            ? mapping4(commonData)(inputData)
            : selectedValue.roles.includes(Permission['DI-Export5'])
            ? mapping5(commonData)(inputData)
            : null
          return data
        }),
        ZIO.tap((data) => {
          if (data !== null) exportCSV('policy-info', data)
          onClose()
          return ZIO.unit
        }),
        bindLoader,
        ErrorHandling.run()
      )
    } else {
      setErrMess(
        reason.length < 50 && reason.length > 0
          ? t('message:MS990018')
          : t('message:MS020001', { field: t('common:ReasonExport') })
      )
    }
  }

  return (
    <Dialog maxWidth={'md'} className={dialog} onClose={onClose} open={open}>
      <DialogTitle className={dialogHeader}>
        <Text style={{ fontSize: 18, fontWeight: '700', textAlign: 'center' }}>{t('common:Export')}</Text>
        <TouchableOpacity
          style={{ position: 'absolute', right: 20, top: 20 }}
          onPress={() => {
            onClose()
          }}
        >
          <assets.CloseTaskModalIcon />
        </TouchableOpacity>
      </DialogTitle>
      <DialogContent className={dialogContent}>
        <View>
          <Text style={{ margin: 10, marginBottom: 5 }}>
            {t('common:ReasonExport')} <Text style={{ color: '#ED1B2C' }}>*</Text>{' '}
          </Text>
          <TextInput
            style={{
              borderColor: '#BABABA',
              borderRadius: 8,
              borderWidth: 1,
              padding: 10,
              marginHorizontal: 10,
              width: 600
            }}
            multiline={true}
            numberOfLines={10}
            maxLength={500}
            onChangeText={(text) => setReason(text)}
            placeholder={'...'}
            value={reason}
          />
          <View style={{ flexDirection: 'row', margin: 10, marginTop: 5 }}>
            <Text style={{ color: '#ED1B2C', width: '80%' }}> {errMess} </Text>
            <Text style={{ width: '80%', textAlign: 'right' }}> {reason.length} / 500 </Text>
          </View>
        </View>
      </DialogContent>

      <DialogActions className={dialogActions}>
        <TouchableOpacity style={styles.actionBtn} onPress={() => onClose()}>
          <Text style={{ color: '#ED1B2C' }}>{t('common:Cancel')}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.actionBtn, { backgroundColor: '#ED1B2C', flexDirection: 'row' }]}
          onPress={() => onExport()}
        >
          <Text style={{ color: '#FFF', display: 'flex', alignItems: 'center' }}>
            {t('common:Export')} {loading && <CircularProgress size={14} color={'inherit'} />}
          </Text>
        </TouchableOpacity>
      </DialogActions>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  actionBtn: {
    borderRadius: 100,
    paddingVertical: 10,
    paddingHorizontal: 20
  }
})
