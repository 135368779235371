import { Throwable, ZIO } from '@mxt/zio'
import {
  DatePicker,
  DetailService,
  EditFooterService,
  Employee,
  Form,
  FormService,
  Input,
  InputCurrency,
  Select,
  useAddressForm
} from '@pulseops/business/core'
import { ErrorHandling, form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../../contract-detail-context'
import { EmployeeDetailForm } from './employee-detail-form'

export const EmployeeDetailEdit = ({ employeeKey, onClose }: { employeeKey: string; onClose: () => void }) => {
  const [registerOnSave, registerOnClose] = EditFooterService.useFooter()

  registerOnClose(onClose)

  const { t } = useTranslation('business')

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const detail: Employee | null = pipe(
    service.state.employee.item(employeeKey).get,
    ZIO.tap((detail) => {
      if (detail) {
        setValue('permanent.city', detail.permanentCity)
        setValue('permanent.district', detail.permanentDistrict)
        setValue('permanent.ward', detail.permanentWard)
        setValue('residential.city', detail.residentialCity)
        setValue('residential.district', detail.residentialDistrict)
        setValue('residential.ward', detail.residentialWard)
        setValue('overseas.city', detail.overseaCity)
        setValue('overseas.district', detail.overseaDistrict)
        setValue('overseas.ward', detail.overseaWard)
      }
      return ZIO.unit
    }),
    ErrorHandling.runDidMount()
  )

  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue
    },
    handleSubmit
  } = form2.useForm(EmployeeDetailForm.codec)

  const countryOptions = pipe(FormService.getCountryOptions, ErrorHandling.runDidMount([]))
  const provinceOptions = pipe(FormService.getProvinceOptions, ErrorHandling.runDidMount([]))

  const permanentAddress = useAddressForm(watch, 'permanent')
  const residentialAddress = useAddressForm(watch, 'residential')
  const overseasAddress = useAddressForm(watch, 'overseas')

  pipe(
    handleSubmit((validated) =>
      pipe(
        detail,
        ZIO.fromPredicate(
          (a): a is Employee => a != null,
          () => Throwable('employee detail not found')
        ),
        ZIO.flatMap((detail) =>
          service.updateEmployee({
            ...detail,
            ...validated,
            permanentCity: validated.permanent.city,
            permanentDistrict: validated.permanent.district,
            permanentWard: validated.permanent.ward,
            residentialCity: validated.residential.city,
            residentialDistrict: validated.residential.district,
            residentialWard: validated.residential.ward,
            overseaCity: validated.overseas.city,
            overseaDistrict: validated.overseas.district,
            overseaWard: validated.overseas.ward
          })
        ),
        ZIO.tap(() => service.state.history.clear),
        ErrorHandling.run()
      )
    ),
    registerOnSave
  )

  if (!detail) {
    return null
  }

  return (
    <>
      <Form.Group>
        <Form.GroupTitle>{t('employee_info_detail')}</Form.GroupTitle>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="employeeName"
              defaultValue={detail.employeeName}
              render={({ field }) => (
                <Input
                  label={t('full_name_employee')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.employeeName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="employeeNo"
              defaultValue={detail.employeeNo}
              render={({ field }) => (
                <Input
                  label={t('employee_code')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.employeeNo?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="employeeId"
              defaultValue={detail.employeeId}
              render={({ field }) => (
                <Input
                  label={t('id_card_no')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.employeeId?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="dateOfIssue"
              defaultValue={detail.dateOfIssue}
              render={({ field }) => (
                <DatePicker
                  label={t('issue_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.dateOfIssue?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="placeOfIssue"
              defaultValue={detail.placeOfIssue}
              render={({ field }) => (
                <Input
                  label={t('issue_place')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.placeOfIssue?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="dateOfBirth"
              defaultValue={detail.dateOfBirth}
              render={({ field }) => (
                <DatePicker
                  label={t('dob')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.dateOfBirth?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="gender"
              defaultValue={detail.gender}
              render={({ field }) => (
                <Select
                  options={FormService.genderOptions}
                  label={t('gender')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.gender?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="maritalStatus"
              defaultValue={detail.maritalStatus}
              render={({ field }) => (
                <Select
                  options={FormService.maritalStatusOptions}
                  label={t('marital_status')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.maritalStatus?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="employeePhoneNumber"
              defaultValue={detail.employeePhoneNumber}
              render={({ field }) => (
                <Input
                  label={t('phone_number')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.employeePhoneNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="email"
              defaultValue={detail.email}
              render={({ field }) => (
                <Input
                  label={t('email')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.email?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="nationality"
              defaultValue={detail.nationality}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('nationality')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.nationality?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="otherNationality"
              defaultValue={detail.otherNationality}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('other_nationality')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.otherNationality?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="visa"
              defaultValue={detail.visa}
              render={({ field }) => (
                <Input
                  label={t('visa')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.visa?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="permanentAddress"
              defaultValue={detail.permanentAddress}
              render={({ field }) => (
                <Input
                  label={t('perm_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.permanentAddress?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="permanentAddressCountry"
              defaultValue={detail.permanentAddressCountry}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('perm_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.permanentAddressCountry?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="permanent.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('permanent.district', null)
                    setValue('permanent.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.permanent?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="permanent.district"
              render={({ field }) => (
                <Select
                  disabled={permanentAddress.districtOptions.length === 0}
                  options={permanentAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('permanent.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.permanent?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="permanent.ward"
              render={({ field }) => (
                <Select
                  disabled={permanentAddress.wardOptions.length === 0}
                  options={permanentAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.permanent?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="residentialAddress"
              defaultValue={detail.residentialAddress}
              render={({ field }) => (
                <Input
                  label={t('curr_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.residentialAddress?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="residentialAddressCountry"
              defaultValue={detail.residentialAddressCountry}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('curr_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.residentialAddressCountry?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="residential.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('residential.district', null)
                    setValue('residential.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.residential?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="residential.district"
              render={({ field }) => (
                <Select
                  disabled={residentialAddress.districtOptions.length === 0}
                  options={residentialAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('residential.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.residential?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="residential.ward"
              render={({ field }) => (
                <Select
                  disabled={residentialAddress.wardOptions.length === 0}
                  options={residentialAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.residential?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="overseasAddress"
              defaultValue={detail.overseasAddress}
              render={({ field }) => (
                <Input
                  label={t('oversea_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overseasAddress?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="overseasAddressCountry"
              defaultValue={detail.overseasAddressCountry}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('oversea_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overseasAddressCountry?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="overseas.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('overseas.district', null)
                    setValue('overseas.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overseas?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="overseas.district"
              render={({ field }) => (
                <Select
                  disabled={overseasAddress.districtOptions.length === 0}
                  options={overseasAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('overseas.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overseas?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="overseas.ward"
              render={({ field }) => (
                <Select
                  disabled={overseasAddress.wardOptions.length === 0}
                  options={overseasAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overseas?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="occupation"
              defaultValue={detail.occupation}
              render={({ field }) => (
                <Input
                  label={t('occupation')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.occupation?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="occupationCode"
              defaultValue={detail.occupationCode}
              render={({ field }) => (
                <Input
                  label={t('occupation_code')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.occupationCode?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="benefitClass"
              defaultValue={detail.benefitClass}
              render={({ field }) => (
                <Input
                  label={t('benefit_class')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.benefitClass?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col></Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="bankName"
              defaultValue={detail.bankName}
              render={({ field }) => (
                <Input
                  label={t('bank_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.bankName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="bankBranch"
              defaultValue={detail.bankBranch}
              render={({ field }) => (
                <Input
                  label={t('branch_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.bankBranch?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="bankAccountNo"
              defaultValue={detail.bankAccountNo}
              render={({ field }) => (
                <Input
                  label={t('account_no')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.bankAccountNo?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="accountName"
              defaultValue={detail.accountName}
              render={({ field }) => (
                <Input
                  label={t('account_holder')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.accountName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('group_policy_no')} value={detail.masterContractNo} readonly />
          </Form.Col>
          <Form.Col>
            <Input label={t('policy_year')} value={detail.policyYear.toString()} readonly />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Input label={t('cert_no')} value={detail.lifeCertificateNo || ''} readonly />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="lifeCertificateEffectiveDate"
              defaultValue={detail.lifeCertificateEffectiveDate}
              render={({ field }) => (
                <DatePicker
                  label={t('policy_if_date_la')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.lifeCertificateEffectiveDate?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="basicPlan"
              defaultValue={detail.basicPlan}
              render={({ field }) => (
                <Input
                  label={t('basic_plan')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.basicPlan?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="basicSa"
              defaultValue={detail?.basicSa?.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('basic_sa')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.basicSa?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="addRiderSa"
              defaultValue={detail?.addRiderSa?.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('add_rider_sa')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.addRiderSa?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="healthcarePlan"
              defaultValue={detail.healthcarePlan}
              render={({ field }) => (
                <Input
                  label={t('hc_plan')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.healthcarePlan?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="overFcl"
              defaultValue={detail.overFcl}
              render={({ field }) => (
                <Select
                  options={FormService.yesNoOptions}
                  label={t('over_fcl')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overFcl?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="overFclAmount"
              defaultValue={detail.overFclAmount.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('over_fcl_amount')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.overFclAmount?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="exclusion"
              defaultValue={detail.exclusion}
              render={({ field }) => (
                <Input
                  label={t('exclusion')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.exclusion?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col></Form.Col>
          <Form.Col></Form.Col>
        </Form.Row>
        {/* <Form.Row>
          <Form.Col>
          </Form.Col>
          <Form.Col>
          </Form.Col>
          <Form.Col>
          </Form.Col>
        </Form.Row> */}
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="standardPremium"
              defaultValue={detail.standardPremium.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('total_standard_premium')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.standardPremium?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="loadingPremium"
              defaultValue={detail.loadingPremium?.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('total_loading_premium')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.loadingPremium?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="totalPremium"
              defaultValue={detail?.totalPremium?.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('total_premium')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.totalPremium?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>
      <Form.Group></Form.Group>
    </>
  )
}
