import { form2 } from '@pulseops/common'
import * as t from 'io-ts'

export namespace UploadFileToStorageForm {
  const Base = t.type({
    fromDate: form2.date.optional,
    toDate: form2.date.optional,
    bankCode: form2.selectOption.optional,
    status: form2.selectOption.optional
  })
  export const codec = Base

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
