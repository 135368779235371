import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { GeneralData } from '../../GeneralService'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/lib/Option'
import { ReinstatementModel } from '../Reinstatement'

export interface RiderReinstatementPayload {
  reinsFee: number,
  lifeAssuredList: LifeAssuredData[]
}
export interface LifeAssuredData {
  clientNo: string
  agentCode: string,
  clientName: string
  healthStateChange: boolean
  occupationChange: boolean
  curOccupation: string
  newOccupation: string
  activityChange: boolean
  newActivity: string
  companyChange: boolean
  newCompany: string
  riderList: RiderInfo[]
}

export interface RiderInfo {
  coverageCode: string,
  riskCommDate: string,
  lapsedDate: string,
  sumAssured: number,
  riderPremium: number,
}

export interface VERIRiderRein {
  tag: TransactionType.RIDER_REINSTATEMENT
  clientName: string
  submissionData: RiderReinstatementPayload
}

export const RiderReinstatement = (
  detail: api.RiderReinstatement,
  poName: string,
  occupationList: GeneralData[],
  reinData: ReinstatementModel.ReinData
): VERIRiderRein => {
  let payloadData = detail.payload.body
  const LAList = payloadData.lifeAssuredList.map((LAItem) => {
    let currentOccupation = '',
      newOccupation = ''
    if (LAItem.occupationChange) {
      currentOccupation = pipe(
        occupationList.find((x) => x.code === LAItem.curOccupation),
        O.fromNullable,
        O.map((item) => item && item.name),
        O.getOrElse(() => '')
      )
      newOccupation = pipe(
        occupationList.find((x) => x.code === LAItem.newOccupation),
        O.fromNullable,
        O.map((item) => item && item.name),
        O.getOrElse(() => '')
      )
    }
    const clientName = pipe(
      reinData.lifeAssured.find((x) => x.clientId === LAItem.clientNo),
      O.fromNullable,
      O.map((item) => item && item.name),
      O.getOrElse(() => '')
    )
    return {
      ...LAItem,
      clientName: clientName,
      curOccupation: currentOccupation,
      newOccupation: newOccupation
    }
  })

  const submissionData: RiderReinstatementPayload = {
    reinsFee: payloadData.reinsFee,
    lifeAssuredList: LAList
  }
  switch (detail.source) {
    case SourceType.PULSE:
    case SourceType.PULSE4OPS: {
      return {
        tag: TransactionType.RIDER_REINSTATEMENT,
        clientName: poName,
        submissionData: submissionData
      }
    }
  }
}
