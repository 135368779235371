import { enumC } from '@mxt/zio/codec'

export enum DocId {
  Quotation = 'Quotation',
  Proposal = 'Proposal',
  EmployeeList = 'EmployeeList',
  BusinessLicense = 'BusinessLicense',
  LegalRepId = 'LegalRepId',
  BeneficialOwnerId = 'BeneficialOwnerId',
  ChiefAccountantContract = 'ChiefAccountantContract',
  DirectorContract = 'DirectorContract',
  AlternatePolicyOwner = 'AlternatePolicyOwner',
  FATCA = 'FATCA',
  AML = 'AML'
}

export const DocIdC = enumC(DocId)
