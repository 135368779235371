import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { OBCallingRecordForm } from '../ob-call-out-popup'
import { ScrollView } from 'react-native'
import { ADDHOCImportedParamPayload, CustomerAnswerPayload } from '../../ob-service'

type CallOutDetailContextInfo = {
  policyNumber: string
  caseId: string
  permissions: string[]
  processInstanceId: string
  taskId: string
  callProgram: string
  assignee: string
  teamLeader: string
  clientNumber: string
  transactionType?: string
  isDisabled: boolean
  isSuspendEnable: boolean
  setSuspendEnable: (enable: boolean) => void
  isDisableSubmit: boolean
  setDisabledSubmit: (enable: boolean) => void
  mobilePhoneNumber: string
  channel: string
  isSavedCalloutPopupData?: boolean
  setIsSavedCalloutPopupData?: (val: boolean) => void
  isSavedCallingResultDetail?: boolean
  setIsSavedCallingResultDetail?: (val: boolean) => void
  callingRecordForm?: UseFormReturn<OBCallingRecordForm.OBCallingRecord>
  aging?: number
  category?: string
  // isSavedQCScreen?: boolean
  setIsSavedQCScreen?: (val: boolean) => void
  setIsNotInputedRequiredField?: (val: boolean) => void
  isUpdatedChangeContactInfo?: boolean
  setIsUpdatedChangeContactInfo?: (val: boolean) => void
  isSendComunication?: boolean
  setIsSendComunication?: (val: boolean) => void
  isOpenedAvayaCallBlock?: boolean
  setIsOpenedAvayaCallBlock?: (val: boolean) => void
  onAvayaCallClickEvent?: (phoneNumber: string, phoneType: string) => void
  avayaCallID?: string
  setAvayaCallID?: (val: string) => void
  avayaCallingPhoneNumber?: string
  avayaCallDate?: Date
  setAvayaCallDate?: (val?: Date) => void
  isSavedAfterCallingAvaya?: boolean
  setIsSavedAfterCallingAvaya?: (val: boolean) => void
  phoneType?: string
  setPhoneType: (val: string) => void
  scrollRef: React.MutableRefObject<ScrollView | null>
  IDCCustomerAnswerPayload?: CustomerAnswerPayload
  A7s10Config?: string[]
  executionTime?: number
  ADDHOCImportedParamData?: ADDHOCImportedParamPayload
  setIsSavedREQCScreen?: (val: boolean) => void
  setIsNotREQCInputedRequiredField?: (val: boolean) => void
  setIsDisableSuspend?: (val: boolean) => void
  isDisableSuspend?: boolean
  isReloadedPolicyOwnerTab?: boolean
  setIsReloadedPolicyOwnerTab?: (val: boolean) => void
  promotionFlag?: {
    hasPromotion: boolean
    countPromotion: number
    typePromotion: string | null
    premiumRein: string | null
  }
}

const defaultDetailContext: CallOutDetailContextInfo = {
  policyNumber: '',
  caseId: '',
  permissions: [],
  processInstanceId: '',
  taskId: '',
  callProgram: '',
  assignee: '',
  teamLeader: '',
  clientNumber: '',
  transactionType: '',
  isDisabled: false,
  isSuspendEnable: false,
  setSuspendEnable: (_) => {},
  setDisabledSubmit: (_) => {},
  isDisableSubmit: true,
  mobilePhoneNumber: '',
  channel: '',
  // isClickedCallPopupSavedButton: false,
  // setIsClickedCallPopupSavedButton: (_) => { },
  isSavedCalloutPopupData: false,
  setIsSavedCalloutPopupData: (_) => {},
  isSavedCallingResultDetail: false,
  setIsSavedCallingResultDetail: (_) => {},
  callingRecordForm: undefined,
  aging: undefined,
  category: '',
  setIsSavedQCScreen: (_) => {},
  isUpdatedChangeContactInfo: false,
  setIsUpdatedChangeContactInfo: (_) => {},
  isSendComunication: false,
  setIsSendComunication: (_) => {},
  isOpenedAvayaCallBlock: false,
  setIsOpenedAvayaCallBlock: (_) => {},
  onAvayaCallClickEvent: (_) => {},
  avayaCallID: '',
  setAvayaCallID: (_) => {},
  avayaCallingPhoneNumber: '',
  avayaCallDate: undefined,
  setAvayaCallDate: (_) => {},
  isSavedAfterCallingAvaya: false,
  setIsSavedAfterCallingAvaya: (_) => {},
  phoneType: '',
  setPhoneType: (_) => {},
  scrollRef: { current: null },
  IDCCustomerAnswerPayload: undefined,
  A7s10Config: [],
  executionTime: 0,
  ADDHOCImportedParamData: undefined,
  setIsSavedREQCScreen: (_) => {},
  setIsNotREQCInputedRequiredField: (_) => {},
  setIsDisableSuspend: (_) => {},
  isDisableSuspend: false,
  isReloadedPolicyOwnerTab: false,
  setIsReloadedPolicyOwnerTab: (_) => {},
  promotionFlag: undefined
}

export const CallOutDetailContext = React.createContext<CallOutDetailContextInfo>(defaultDetailContext)
