import { refCache, RefSessionStorage } from '@pulseops/common'
import * as t from 'io-ts'
import { Lens } from 'monocle-ts'
import { flow, pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

export namespace CepStateInfo {
  export const MessageType = t.type({
    DOB: t.string,
    Anniversary: t.string,
    FullyPaid: t.string
  })

  export type MessageType = t.TypeOf<typeof MessageType>

  export const codec = t.type(
    {
      message: MessageType
    },
    'CepStateInfo'
  )
  // ANNIVERSARY
  const ref = RefSessionStorage.make(codec, {
    message: {
      DOB: '',
      Anniversary: '',
      FullyPaid: ''
    }
  })

  const lens = {
    message: Lens.fromProp<CepStateInfo>()('message')
  }

  export const cache = refCache(ref)

  export const message = {
    set: (val: MessageType) => pipe(val, lens.message.set, ref.update),
    get: pipe(flow(lens.message.get), ref.select),
    getOption: pipe(lens.message.get, ref.select),
    watch: pipe(flow(lens.message.get), ref.watch)
  }

  export const action = {
    setMessage: (val: MessageType) => pipe(message.set(val)),
    reset: () =>
      ref.set({
        message: {
          DOB: '',
          Anniversary: '',
          FullyPaid: ''
        }
      })
  }
}

export type CepStateInfo = t.TypeOf<typeof CepStateInfo.codec>
