export namespace UnderwrittingConst {
  export const UWDecisionList = [
    { value: '1', label: 'Void' },
    { value: '2', label: 'Decline' },
    { value: '3', label: 'Postpone' },
    { value: '4', label: 'Conditonal accept' },
    { value: '5', label: 'NTU' },
    { value: '6', label: 'Accept' },
    { value: '7', label: 'ME/ BSTT' },
    { value: '8', label: 'IS' }
  ]
}
