import { View, Text, TouchableOpacity, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import React from 'react'
interface Props {
  onPress: () => void
  disabled?: boolean
  title: string
  prefixIcon?: React.ReactNode
  suffixIcon?: React.ReactNode
  backgroundFill: boolean
  width?: string | number
  containerStyle?: StyleProp<ViewStyle>
  widthDiv?: string | number
}
export const IBButton = (props: Props) => {
  const {
    onPress,
    disabled,
    title,
    prefixIcon,
    suffixIcon,
    backgroundFill = false,
    width = '100%',
    containerStyle,
    widthDiv
  } = props
  return (
    <TouchableOpacity
      style={[{ height: 40, alignItems: 'center', width: widthDiv ? widthDiv : 'auto' }, containerStyle]}
      onPress={onPress}
      disabled={disabled}
    >
      <View
        style={[
          styles.btn,
          {
            backgroundColor: disabled ? '#E3E7ED' : backgroundFill ? '#ed1b2e' : '#ffffff',
            width: width,
            borderColor: disabled ? '#E3E7ED' : '#ed1b2e',
            alignItems: "center"
          }
        ]}
      >
        <View>{prefixIcon}</View>
        <Text style={[styles.btnText, { color: disabled ? '#fff' : backgroundFill ? '#fff' : '#ed1b2e' }]}>
          {title}
        </Text>
        <View>{suffixIcon}</View>
      </View>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  btn: {
    height: 40,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 10,
    boxShadow: 'none',
    flexDirection: 'row',
    borderWidth: 1,

    justifyContent: 'center'
  },
  btnText: {
    marginHorizontal: 5,
    fontSize: 15,
    textAlign: 'center',
    fontFamily: 'NotoSans_700Bold'
  }
})
