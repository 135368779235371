import { StyleSheet, Text, View, TouchableOpacity, ScrollView } from 'react-native'
import { assets } from '@pulseops/common'
import React from 'react'
interface Props {
  onClick: () => void
  visible?: boolean
  changeBottom?: number
}
export const IBPushToTop = ({ onClick, visible = true, changeBottom }: Props) => {
  return (
    <>
      {visible ? (
        <View style={changeBottom ? [styles.fab, { bottom: changeBottom }] : styles.fab }>
          <TouchableOpacity onPress={onClick} style={styles.borderIcon}>
            <assets.IBPushToTopIcon />
          </TouchableOpacity>
        </View>
      ) : (
        <></>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    right: 7,
    bottom: 80
  },
  borderIcon: {
    backgroundColor: '#D1D7E3',
    padding: 10,
    borderRadius: 40
  }
})
