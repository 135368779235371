import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { AddLackOfDocumentModel } from './model'
import { SubmissionService } from './SubmissionService'

export namespace AddLackOfDocumentService {
  export const getListCaseId = (policyNum: string) =>
    POApi.get(`wf-api/lack-of-document/get-add-doc-list-case?policyNum=${policyNum}`)(
      t.type({
        body: t.array(
          t.type({
            caseId: t.string,
            claimNumber: Maybe(t.string)
          })
        )
      })
    )

  export const getSuspendType = (caseId: string) =>
    POApi.get(`wf-api/lack-of-document/get-add-doc-info?caseId=${caseId}`)(AddLackOfDocumentModel.SuppendTypes)

  export const submit = (policyNum: string, data: AddLackOfDocumentModel.DataSubmit, isClaim: boolean) =>
    SubmissionService.submit(t.unknown)(
      `wf-api/lack-of-document/policy/${policyNum}/add-lack-of-document`,
      { body: data },
      policyNum,
      null,
      [],
      '',
      undefined,
      undefined,
      isClaim
    )
}
