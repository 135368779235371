import { ZIO } from '@mxt/zio'
import {
  assets,
  ErrorHandling,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  SelectSearch,
  Title,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React, { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ComplainInquiryForm } from './complain-inquiry-form'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail: InquiryComplaintData.DetailData | null
}

export const GAOperation: React.FC<Props> = ({ detail, form }) => {
  const { t } = useTranslation()
  const { isMobile } = useMobile()

  const [generalAgencyList, setGeneralAgencyList] = useState<InquiryComplaintData.IACSources[]>([])

  const { control } = form

  // Fecth API

  pipe(
    InquiryComplaintService.getGeneralAgency(),
    ZIO.map((genAgentList) => {
      setGeneralAgencyList(genAgentList)
    }),
    ErrorHandling.runDidMount()
  )

  return (
    <View>
      <Title title={t('requestInfo:ComplaintedGaOperationsDetails')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="ga.generalAgency"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:GeneralAgency')}
                  required
                  options={generalAgencyList.map((item) => ({
                    value: item.code,
                    label: item.name
                  }))}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="ga.personToBeComplained"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('requestInfo:PersonToBeComplainted')}
                  required
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  maxLength={120}
                />
              )}
            />
          </View>
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
