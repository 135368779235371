import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface Eservice {
  tag: TransactionType.E_SERVICE_REGISTRATION
  clientName: string
  email?: string | null
  fullName?: string | null
  gender?: string | null
  idNumber?: string | null
  mobileNumber?: string | null
  otpConsent?: string | null
  status?: string | null
  telCode?: string | null
  dob?: string | null
}

export const Eservice = (detail: api.EService, poName: string): Eservice => {
  const payload = detail.payload.body
  return {
    tag: TransactionType.E_SERVICE_REGISTRATION,
    clientName: poName ?? '-',
    email: payload.email,
    fullName: payload.fullName,
    gender: payload.gender,
    idNumber: payload.idNumber,
    mobileNumber: payload.mobileNumber,
    otpConsent: payload.otpConsent,
    status: payload.status,
    telCode: payload.telCode,
    dob: payload.dob
  }
}
