import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { SourceType } from '../SourceType'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.TOP_UP)
    }),
    codec
  ])

const payload = t.type({
  body: t.type({
    amount: Maybe(t.string),
    attachmentRef: Maybe(t.string),
    percentage: Maybe(t.number),
    poClientNum: Maybe(t.string),
    poNationalId: Maybe(t.string),
    policyNo: Maybe(t.string),
    selectedFund: Maybe(t.string),
    serviceType: Maybe(t.string),
    source: Maybe(t.string),
    transactionType: Maybe(t.string),
    userId: Maybe(t.string)
  })
})

const payloadPulse4Ops = t.type({
  body: t.type({
    attributesExt: t.type({
      TOP_UP: t.type({
        option: t.string,
        status: Maybe(t.string),
        suspenseAmount: Maybe(t.number),
        totalTopupAmount: t.string,
        topupFunds: Maybe(
          t.array(
            t.type({
              fundCode: t.string,
              ratio: t.string,
              topupAmount: Maybe(t.string)
            })
          )
        )
      })
    })
  }),
  source: Maybe(t.string)
})

const payloadZalo = t.type({
  body: t.type({
    attributesExt: t.type({
      TOP_UP: t.type({
        option: Maybe(t.string),
        status: Maybe(t.string),
        suspenseAmount: Maybe(t.number),
        totalTopupAmount: Maybe(t.union([t.string, t.number])),
        topupFunds: Maybe(
          t.array(
            t.type({
              fundCode: Maybe(t.string),
              ratio: Maybe(t.string),
              topupAmount: Maybe(t.union([t.string, t.number]))
            })
          )
        )
      })
    })
  }),
  source: Maybe(t.string)
})

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload: payload
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: payloadPulse4Ops
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadZalo
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPulse4Ops
  })
)

const Banca_sea = Base(
  t.type({
    source: t.literal(SourceType.BANCA_SEA),
    payload: payloadPulse4Ops
  })
)

export const Topup = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, PruGreat, PruOnline, Banca_sea])
export type Topup = t.TypeOf<typeof Topup>
