import { Throwable } from '@mxt/zio'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'react-native'
import { Button } from 'react-native-elements'
import * as R from '@mxt/zio-react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ErrorHandling } from '@pulseops/common'
import { RootStackParamList } from './RootStackParamList'
import { ScrollView } from 'react-native-gesture-handler'

type Props = StackScreenProps<RootStackParamList, 'ErrorScreen'>

export const ErrorScreen = (props: Props) => {
  const { t } = useTranslation('common')

  const errors: Throwable[] = pipe(ErrorHandling.errorsRef.stream, R.subscribe([]))

  return (
    <SafeAreaView style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <ScrollView>
        <Text>{t('SomeThingWentWrong')}</Text>
        <Button
          onPress={() => {
            console.log(errors)
          }}
          title={'SHOW'}
        />
        <Button onPress={() => props.navigation.goBack()} title={t('Dismiss')} />
      </ScrollView>
    </SafeAreaView>
  )
}
