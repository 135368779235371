import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface SignatureRegistration {
  tag: TransactionType.SIGNATURE_REGISTRATION
  clientNumber: string
  clientName: string
  optionFlag: string
  documents: {
    url: string
    name: string
  }[]
}

export const SignatureRegistration = (
  detail: api.SignatureRegistration,
  poName: string,
  clientNumber: string
): SignatureRegistration => ({
  tag: TransactionType.SIGNATURE_REGISTRATION,
  clientNumber,
  clientName: poName ?? '',
  ...detail.payload.body
})
