import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppConfig,
  AppContext,
  AuthService,
  ErrorHandling,
  FileUploadData,
  IBPolicyPermission,
  SelectOption,
  StorageBlob,
  SubmissionService,
  assets
} from '@pulseops/common'
import { UseFormReturn, useForm } from 'react-hook-form'
import { ClickToSendEmailForm, FileUpload } from './IBClickToSendEmailForm'
import { ClientScreenContext, IBButton, IBGeneralField, TypeInputComponent } from '../../common'
import { isEmpty, isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { A14S0Data, A14S1Data, A14S5Data, IBService } from '../../ib-service'
import { ZIO } from '@mxt/zio'
import { UploadedFilesInfo } from '@pulseops/submission'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { string } from 'prop-types'
import randomBytes from 'randombytes'
interface Props {
  showPopup: boolean
  setShowPopup: (open: boolean) => void
  form: UseFormReturn<ClickToSendEmailForm, any>
  setMasterDataList: (data: {
    policyLists: (SelectOption & { policyOwner: string })[]
    a14s0s: A14S0Data[]
    a14s1s: A14S1Data[]
    a14s5s: A14S5Data[]
  }) => void
  masterDataList: {
    policyLists: (SelectOption & { policyOwner: string })[]
    a14s0s: A14S0Data[]
    a14s1s: A14S1Data[]
    a14s5s: A14S5Data[]
  }
}
export const IBPopupClickToSend = (props: Props) => {
  const { showPopup, setShowPopup, form, masterDataList, setMasterDataList } = props
  const { height, width } = useWindowDimensions()
  const { t, i18n } = useTranslation('Inbound')
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const clientNumber = urlParams.get('clientNumber')
  const userEmail = pipe(
    AuthService.userInfo,
    ZIO.map((res) => res.email),
    ErrorHandling.runDidMount()
  )
  const { permissions } = React.useContext(ClientScreenContext)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [isMaximize, setMaximize] = React.useState<boolean>(false)
  const [listImgInsertContent, setListImgInsertContent] = React.useState<{ file: FileUpload; blobUrl: string }[]>([])
  const [imgInsertCur, setImgInsertCur] = React.useState<{ file: FileUpload; blobUrl: string }>()
  const [listImgDefaultTemplate, setListImgDefaultTemplate] = React.useState<{ name: string; blob: string }[]>([])
  const [loading, bindLoading] = useLoading(false)
  const { policyLists, a14s0s, a14s1s, a14s5s } = masterDataList
  React.useEffect(() => {
    if (isEmpty(masterDataList.policyLists) && showPopup) {
      pipe(
        ZIO.zipPar(
          IBService.getPolicyList(clientNumber || ''),
          IBService.getA14S0(),
          IBService.getA14S1(),
          IBService.getA14S5()
        ),
        ZIO.map(([policyArr, A14S0, A14S1, A14S5]) => {
          setMasterDataList({
            policyLists: policyArr.map((item) => ({
              value: item.policyNumber,
              label: item.policyNumber,
              policyOwner: ''
            })),
            a14s0s: A14S0 as A14S0Data[],
            a14s1s: A14S1 as A14S1Data[],
            a14s5s: A14S5 as A14S5Data[]
          })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [showPopup])

  const getPOName = (policyNum: string) =>
    pipe(
      SubmissionService.getNewOwnerInfo(policyNum),
      ZIO.map((policyInfo) => {
        const bcc = a14s5s.reduce((pre, cur) => (pre += cur.email + ';'), '')
        form.setValue('policyOwner', policyInfo.name || '')
        form.setValue('sendTo', `${policyInfo.attributesExt.EMAIL};`)
        form.setValue('BCC', bcc)
        form.clearErrors('sendTo')
        return policyInfo
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )

  const getImgByUrl = (name: string, content: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, AppConfig.get),
      ZIO.flatMap(([token, loginType, cf]) => {
        const currVersion = cf.version.split('.').join('-') || '1-0-0'
        const url = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
        const container = url.slice(url.lastIndexOf('/') + 1)
        const apiUrl = url.slice(0, url.lastIndexOf('/'))
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const splitName = name.split('/')
        return { name: splitName[splitName.length - 1].split('.')[0], blob: window.URL.createObjectURL(blob) }
      })
    )
  }

  function capitalizeFirstLetters(str: string) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const handleLoadingTemplate = (code: string) => {
    const codeTemplate = a14s1s.find((x) => x.code === code)
    pipe(
      IBService.getA14S4(codeTemplate?.templateCodeVi || ''),
      ZIO.map((template) => {
        if (!isEmpty(template)) {
          setListImgInsertContent([])
          const content =
            template[0].htmlTemplate
              ?.replace(/{policyOwnerName}/g, capitalizeFirstLetters(form.watch().policyOwner.toLowerCase()))
              .replace(/{policyNo}/g, form.watch().policyNumber.value) || ''
          form.setValue(
            'subject',
            template[0].subject
              ?.replace(/{policyOwnerName}/g, capitalizeFirstLetters(form.watch().policyOwner.toLowerCase()))
              .replace(/{policyNo}/g, form.watch().policyNumber.value) || ''
          )
          form.clearErrors('subject')

          if (!isEmpty(content.match(/alt="(.*?)"/g)) && !isNil(content.match(/alt="(.*?)"/g))) {
            const arrayImg = content.match(/alt="(.*?)"/g)?.map((match) => match.match(/alt="(.*?)"/)[1])
            pipe(
              ZIO.sequence((arrayImg || []).map((url) => getImgByUrl(url, content))),
              ZIO.map((imgs) => {
                setListImgDefaultTemplate(imgs)
                const insertImgToContent = imgs.reduce((pre, cur) => pre.replace(`{${cur.name}}`, cur.blob), content)
                form.setValue('content', insertImgToContent)
                form.setValue('contentOriginal', insertImgToContent)
              }),
              ZIO.unsafeRun({})
            )
          } else {
            form.setValue('contentOriginal', content)
            form.setValue('content', content)
          }
        }
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const onReset = () => {
    form.reset()
    form.setValue('sendTo', '')
    form.setValue('BCC', '')
    form.setValue('subject', '')
    form.setValue('policyOwner', '')
    form.setValue('content', '')
    form.setValue('note', '')
    setListImgInsertContent([])
    setImgInsertCur(undefined)
    setListImgDefaultTemplate([])
  }

  const onSubmit = form.handleSubmit((value) => {
    const filterImgInsertContent = listImgInsertContent.filter((x) => value.content.includes(x.blobUrl))
    pipe(
      ZIO.sequence(
        (filterImgInsertContent
          ? [...filterImgInsertContent.map((x) => x.file), ...value.attachmentFiles]
          : value.attachmentFiles
        ).map(({ file }) => {
          return IBService.importFileClickToSend(file, value.policyNumber.value)
        })
      ),
      ZIO.flatMap((docs) => {
        return pipe(
          ZIO.zipPar(AppConfig.get, IBService.getTeamCodeByUser(userEmail || '')),
          ZIO.flatMap(([cf, teamCode]) => {
            const currVersion = cf.version.split('.').join('-') || '1-0-0'
            const apiUrl = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
            let documentUrls = docs.map((file) => ({ ...file, url: apiUrl }))
            const emailTo =
              value.sendTo.charAt(value.sendTo.length - 1) === ';' ? value.sendTo.slice(0, -1) : value.sendTo
            const emailBCC = value.BCC.charAt(value.BCC.length - 1) === ';' ? value.BCC.slice(0, -1) : value.BCC
            value.content = listImgDefaultTemplate.reduce(
              (pre, cur) => pre.replace(`${cur.blob}`, `{${cur.name}}`),
              value.content
            )
            const getDocTypes = docs.map((x) => x.type)
            const imgInserts = filterImgInsertContent.map((item) => {
              const getDoc = getDocTypes.find((x) => item.file.file.name === x.split('/')[2].split('_')[1])
              documentUrls = documentUrls.filter((x) => x.type !== getDoc)
              return {
                fileName: (getDoc as string).split('/')[2] || '',
                blobUrl: item.blobUrl,
                docsUrl: getDoc
              }
            })
            value.content = imgInserts.reduce(
              (pre, cur) =>
                pre
                  .replace(`{${cur.fileName.split('_')[1]}}`, cur.docsUrl || '')
                  .replace(`${cur.blobUrl}`, `{${cur.fileName.split('.')[0]}}`),
              value.content
            )

            return IBService.submitClickToSend({
              emailTo: emailTo
                .split(';')
                .filter((x) => x)
                .map((z) => z.trim()),
              emailBCC: emailBCC
                .split(';')
                .filter((x) => x)
                .map((z) => z.trim()),
              subject: value.subject,
              content: value.content,
              policyNumber: value.policyNumber.value,
              clientNumber: clientNumber || '',
              policyOwnerName: value.policyOwner,
              cceGroupCode: teamCode || '',
              requestTypeCode: value.type.value,
              categoryCode: 'IB',
              attachDocuments: documentUrls,
              mailGroupCode: value.group.value,
              note: value.note
            })
          })
        )
      }),
      ZIO.map((item) => {
        showToast(t('message:IB0018'), 'success')
        onReset()
      }),
      ZIO.catchAll((error) => {
        console.log(error)
        return ZIO.succeed(null)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  React.useEffect(() => {
    if (imgInsertCur) setListImgInsertContent([...listImgInsertContent, imgInsertCur])
  }, [imgInsertCur])
  if (!showPopup) return <></>
  return (
    <View style={[styles.modalCallResult, { height: height * 0.75, width: isMaximize ? width * 0.8 : width * 0.55 }]}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingTop: 20,
            marginBottom: 10
          }}
        >
          <Text style={{ fontWeight: '700', fontSize: 16 }}>{t('ClickToSendEmail').toUpperCase()}</Text>
          <Pressable onPress={() => setMaximize(!isMaximize)} style={{ marginRight: 15 }}>
            {isMaximize ? <assets.IBArrowMinimize /> : <assets.IBArrowMaximize />}
          </Pressable>
        </View>
        <View style={styles.formStyle}>
          <IBGeneralField
            FieldForm={form}
            col={2}
            typeInput={[
              {
                type: TypeInputComponent.SELECT,
                formName: 'policyNumber',
                title: t('common:PolicyNumber'),
                option: orderBy(
                  policyLists.map((item) => ({ value: item.value, label: item.label })),
                  'value',
                  'desc'
                ),
                required: true,
                rules: {
                  validate: () => {
                    return isNil(form.watch('policyNumber'))
                      ? `${t('message:MS020009', { field: t('common:PolicyNumber') })}`
                      : true
                  }
                },
                handleData: (value) => {
                  if (value) {
                    getPOName(value.value || '')
                  }
                }
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'policyOwner',
                title: t('OwnerName'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'group',
                title: t('Group'),
                option:
                  a14s0s.map((item) => ({
                    value: item.code || '',
                    label: i18n.language === 'vi' ? item.nameVi || '-' : item.nameEn || '-'
                  })) || [],
                required: true,
                rules: {
                  validate: () => {
                    return isNil(form.watch('group')) ? `${t('message:MS020009', { field: t('Group') })}` : true
                  }
                },
                disabled: !form.watch().policyNumber,
                handleData: (value) => {
                  form.setValue('type', { label: '', value: '' })
                  form.setValue('subject', '')
                  form.setValue('content', '')
                  form.setValue('attachmentFiles', [])
                }
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'type',
                title: t('Type'),
                option: form.watch().group
                  ? a14s1s
                      .filter((x) => x.groupCode === (form.watch().group.value || ''))
                      .map((item) => ({
                        value: item.code || '',
                        label: i18n.language === 'vi' ? item.nameVi || '-' : item.nameEn || '-'
                      }))
                  : [],
                required: true,
                rules: {
                  validate: () => {
                    return isNil(form.watch('type')) ? `${t('message:MS020009', { field: t('Type') })}` : true
                  }
                },
                disabled: !form.watch().policyNumber,
                handleData: (value) => {
                  if (value) {
                    handleLoadingTemplate(value.value)
                  }
                }
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'note',
                title: t('Note'),
                inputType: 'input',
                expandRow: 2,
                multiline: true,
                numberOfLines: 3,
                maxLength: 1000
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'sendTo',
                title: t('To'),
                inputType: 'input',
                required: true,
                rules: {
                  validate: () => {
                    if (isNil(form.watch('sendTo')) || isEmpty(form.watch('sendTo'))) {
                      return `${t('message:MS020001', { field: t('To') })}`
                    } else {
                      const sendTo = form.watch('sendTo')
                      const sendToSplit = (sendTo.charAt(sendTo.length - 1) === ';' ? sendTo.slice(0, -1) : sendTo)
                        .split(';')
                        .filter((x) => x)
                        .map((z) => z.trim())

                      const toCheckEmpty = sendToSplit.filter((x) => x)
                      if (toCheckEmpty.length === 0) {
                        return `${t('message:MS020001', { field: t('To') })}`
                      }

                      const toCheckValid = sendToSplit.some(
                        (x) => !x.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                      )
                      if (toCheckValid) {
                        return `${t('message:IB0010')}`
                      }
                      return true
                    }
                  }
                }
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'BCC',
                title: t('Bcc'),
                rules: {
                  validate: () => {
                    if (isNil(form.watch('BCC')) || isEmpty(form.watch('BCC'))) {
                      return true
                    } else {
                      const BCC = form.watch('BCC')
                      const BCCSplit = (BCC.charAt(BCC.length - 1) === ';' ? BCC.slice(0, -1) : BCC)
                        .split(';')
                        .filter((x) => x)
                        .map((z) => z.trim())

                      const toCheckValid = BCCSplit.some(
                        (x) => !x.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                      )
                      if (toCheckValid) {
                        return `${t('message:IB0010')}`
                      }
                      return true
                    }
                  }
                },
                inputType: 'input'
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'subject',
                title: t('SubjectEmail'),
                inputType: 'input',
                required: true,
                rules: {
                  validate: () => {
                    return isNil(form.watch('subject')) || isEmpty(form.watch('subject'))
                      ? `${t('message:MS020001', { field: t('SubjectEmail') })}`
                      : true
                  }
                },
                maxLength: 200,
                expandRow: 2
              },
              {
                type: TypeInputComponent.CKEDITOR,
                formName: 'content',
                config: {
                  fontSize_sizes:
                    '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt',
                  resize_enabled: true,
                  language: i18n.language,
                  extraAllowedContent: 'dl dt dd mark',
                  allowedContent: true,
                  extraPlugins: 'image3,colorbutton,font',
                  removePlugins: 'image,elementspath,sourcearea,sourcedialog,codemirror',
                  versionCheck: false
                },
                expandRow: 2,
                title: t('Content'),
                required: true,
                rules: {
                  validate: () => {
                    return isNil(form.watch('content')) || isEmpty(form.watch('content'))
                      ? `${t('message:MS020001', { field: t('Content') })}`
                      : true
                  }
                },
                handleUploadFiles: (file: FileUpload, blobUrl: string) => {
                  setImgInsertCur({ file, blobUrl })
                }
              },
              {
                type: TypeInputComponent.IMPORT,
                formName: 'attachmentFiles',
                title: t('requestInfo:Attachment'),
                width: '100%',
                maxSizeFile: 20,
                maxTotalFile: 20,
                messageMaxTotalSize: 'message:IB0016',
                messageMaxSize: 'message:IB0015',
                // showFullFileName: true,
                messageFormat: 'Inbound:ImageFormat20MB',
                // formatFileNameVi: true,
                messageValidExtensions: 'message:IB0017'
              }
            ]}
          />
        </View>
        <View style={styles.btnBar}>
          <IBButton
            onPress={() => {
              form.setValue('content', form.watch().contentOriginal)
            }}
            title={t('ReturnToOrigin')}
            backgroundFill={false}
            widthDiv={'35%'}
            disabled={!permissions.includes(IBPolicyPermission.IBReturnOriginalEmailPopup)}
          />
          <IBButton
            onPress={onReset}
            title={t('ClearAll')}
            backgroundFill={false}
            widthDiv={'25%'}
            disabled={!permissions.includes(IBPolicyPermission.IBClearAllEmailPopup)}
          />
          <IBButton
            onPress={onSubmit}
            title={t('Submit')}
            backgroundFill
            widthDiv={'25%'}
            disabled={!permissions.includes(IBPolicyPermission.IBSubmitEmailPopup)}
          />
        </View>
        {loading ? (
          <View style={styles.loadingBlur}>
            <ActivityIndicator size="large" color="red" />
          </View>
        ) : (
          <></>
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  modalCallResult: {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 40,
    elevation: 40,
    shadowOpacity: 1
  },
  formStyle: {
    flex: 1,
    padding: 20,
    paddingRight: 0,
    height: '100%'
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 50,
    justifyContent: 'space-between'
  },
  loadingBlur: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  }
})
