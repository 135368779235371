import {
  formatNumberWithComma,
  getPayeeNotPOData,
  Panel,
  PayoutChecker,
  PayoutPopup,
  PulseOpsFormat,
  TaskDetail,
  TaskDetailApi
} from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList, Title } from '../common'
import { PayoutMethod } from './common'
interface Props {
  detail: TaskDetail.PartialSurrender
}

export const PartialSurrender = ({ detail }: Props) => {
  const { t } = useTranslation()

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method

  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.totalAmount),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  return (
    <View style={{ flex: 1 }}>
      <Title title={t('submission:PartialSurrender')} />
      <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('submission:CurrentSumAssured'),
              value: formatNumberWithComma(detail.currentSumAssured),
              suffix: 'VND'
            },
            {
              label: t('submission:NewSumAssured'),
              value: formatNumberWithComma(detail.newSumAssured),
              suffix: 'VND'
            },
            {
              label: t('submission:AdvancePremium'),
              value: formatNumberWithComma(detail.advancePremium),
              suffix: 'VND'
            },

            {
              label: t('submission:SuspenseAmount'),
              value: formatNumberWithComma(detail.suspenseAmount),
              suffix: 'VND'
            },
            {
              label: t('submission:LoanOverLimit'),
              value: formatNumberWithComma(detail.loanOverLimit),
              suffix: 'VND'
            },
            {
              label: t('submission:EstimatedPaidSurrenderP'),
              value: formatNumberWithComma(detail.estimatedPaidSurrender),
              suffix: 'VND',
              isHighlight: true,
              isBold: true
            }
          ]}
        />
      </Panel>

      <PayoutMethod
        payoutMethodData={payoutData}
        payeeData={viewPayeeData}
        panelContainerStyle={{ backgroundColor: '#FAFAFA' }}
      />
    </View>
  )
}
