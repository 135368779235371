import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { AddLackOfDocumentModel } from './model'
import { SubmissionService } from './SubmissionService'

export namespace AddLackOfPayeeService {
  export type DataSubmit = {
    lackOfPayeeDTOS: {
      transactionType: string
      suspendCode: string
      caseId: string
      author: string
      payment: Payment
      attachments: {
        name: string
        url: string
      }[]
    }[]
  }

  export type Payment = {
    paymentMethod: string
    dob: string | null
    gender: string
    occupation: string
    relationshipWithPO: string
    nationality: string
    nationality2: string
    residenceCountry: string
    address: string
    foreignAddress: string
    bankTransfer?: {
      accountName: string
      idNumber: string
      bank: string
      bankName: string
      branch: string
      branchName: string
      accountNumber: string
    }
    payAtBank?: {
      payeeName: string
      idNumber: string
      issuedDate: string
      issuedBy: string
      bank: string
      bankName: string
      branch: string
      branchName: string
    }
    payAtCounter?: {
      gaOffice: string
      payeeName: string
      idNumber: string
    }
    isPayeeNotPO: boolean
  }
  export const getListCaseId = (policyNum: string) =>
    POApi.get(`pulseops/api/v1/add-lack-of-payee/get-payee-list-case?policyNumber=${policyNum}`)(
      t.type({
        data: t.array(
          t.type({
            caseId: t.string,
            claimNumber: Maybe(t.string)
          })
        )
      })
    )

  export const getSuspendType = (caseId: string) =>
    POApi.get(`pulseops/api/v1/add-lack-of-payee/get-add-doc-info?caseId=${caseId}`)(
      AddLackOfDocumentModel.SuppendTypes
    )

  export const submit = (policyNum: string, data: DataSubmit, isClaim: boolean) =>
    SubmissionService.submit(t.unknown)(
      `wf-api/lack-of-payee/policy/${policyNum}/add-lack-of-payee`,
      { body: data },
      policyNum,
      null,
      [],
      '',
      undefined,
      undefined,
      isClaim
    )
}
