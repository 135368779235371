import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'

import {
  assets,
  Container,
  ErrorHandling,
  FieldList,
  GeneralInfoService,
  Panel,
  TableField,
  useMobile,
  useTranslator,
  Checkbox
} from '@pulseops/common'
import { PolicyInfoHeader } from '../../common'
import { View, Text } from 'react-native'

const menus = [
  {
    label: 'Policy number',
    icon: <assets.DocumentIcon />,
    value: '7045867'
  },
  {
    label: 'Policy owner',
    icon: <assets.OwnerIcon />,
    value: 'NGUYEN VAN A'
  }
]

export interface CustomerInfoData {
  contactInfoFields: FieldList.DataSources
  personalInfoSource: TableField.DataSources
}

export const CustomerScreen = (props: {
  id: string
  data: CustomerInfoData | null
  setData: (val: CustomerInfoData) => void
}) => {
  const { isMobile } = useMobile()
  const [loading, bindLoading] = useLoading()
  const { t } = useTranslator('TaskManagement')
  const cct = useTranslator('classCode').t
  const ct = useTranslator('contact').t
  const rolesT = useTranslator('roles').t
  const { t: tCommon } = useTranslator('common')
  const requestInfoT = useTranslator('requestInfo').t

  // const [contactInfoFields, setContactInfoFields] = useState<FieldList.DataSources>([])
  // const [personalInfoSource, setPersonalInfoSource] = useState<TableField.DataSources>([])

  function getClassCode(code: string) {
    switch (code) {
      case 'V':
        return cct('Gold')
      case 'P':
        return cct('Platinum')
      case 'D':
        return cct('Diamond')
      default:
        return cct('Basic')
    }
  }

  const getData = (): ZIO<unknown, Throwable, CustomerInfoData> =>
    pipe(
      GeneralInfoService.getCustomerInfo(props.id),
      ZIO.flatMap((_customerInfo) =>
        ZIO.effect(() => {
          return _customerInfo
            ? {
                contactInfoFields: [
                  {
                    label: ct('Address'),
                    value: _customerInfo.street
                  },
                  {
                    label: ct('Ward'),
                    value: _customerInfo.ward
                  },
                  {
                    label: ct('District'),
                    value: _customerInfo.district
                  },
                  {
                    label: ct('City'),
                    value: _customerInfo.city
                  },
                  {
                    label: ct('Country'),
                    value: _customerInfo.country
                  },
                  {
                    label: t('VIP'),
                    value: getClassCode(_customerInfo.vip)
                  },
                  {
                    label: ct('Email'),
                    value: _customerInfo.email
                  },
                  {
                    label: ct('MobilePhone'),
                    value: _customerInfo.mobilePhone
                  },
                  {
                    label: t('CompanyPhone'),
                    value: _customerInfo.companyPhone
                  },
                  {
                    label: t('SMSEmailIndicator'),
                    value: (
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ marginRight: 30 }}>
                          <Checkbox title="SMS" value={_customerInfo.smsInd} />
                        </View>
                        <View>
                          <Checkbox title="Email" value={_customerInfo.smsInd} />
                        </View>
                      </View>
                    )
                  }
                ],
                personalInfoSource: _customerInfo.po_la_info.map((x) => ({
                  ...x,
                  gender: x.gender === 'Male' || x.gender === 'F' ? tCommon('Male') : tCommon('Female'),
                  role: rolesT(x.role)
                }))
              }
            : {
                contactInfoFields: [],
                personalInfoSource: []
              }
        })
      )
    )
  const { contactInfoFields, personalInfoSource } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([props.data])
  ) || {
    contactInfoFields: [],
    personalInfoSource: []
  }

  const columns = [
    {
      key: '1',
      title: ct('Role'),
      name: 'role'
    },
    {
      key: '2',
      title: ct('Fullname'),
      name: 'name'
    },
    {
      key: '3',
      title: requestInfoT('IDNumber'),
      name: 'nationalId'
    },
    {
      key: '4',
      title: requestInfoT('DateOfBirth'),
      name: 'dob'
    },
    {
      key: '5',
      title: ct('Gender'),
      name: 'gender'
    }
  ]

  return (
    <Container loading={loading}>
      {isMobile && <PolicyInfoHeader menus={menus} />}
      {
        contactInfoFields?.length === 0 && personalInfoSource.length === 0 ? <View>
          <Text style={{textAlign: 'center'}}>No data</Text>
        </View> : ''
      }
      {contactInfoFields?.length > 0 && (
        <Panel title={t('ContactInfo').toUpperCase()}>
          <FieldList dataSource={contactInfoFields} />
        </Panel>
      )}
      {personalInfoSource.length > 0 && (
        <Panel title={t('ClientInfo').toUpperCase()}>
          <TableField columns={columns} dataSource={personalInfoSource} currency="" />
        </Panel>
      )}
    </Container>
  )
}
