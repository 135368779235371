import React from 'react'
import { StyleSheet, View, Text, ViewStyle, StyleProp, TextStyle } from 'react-native'

type OBTitleProps = {
  text: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export const OBTitle = (props: OBTitleProps) => {
  return (
    <View style={[titleStyles.container, props.style]}>
      <Text style={titleStyles.text}>{props.text}</Text>
    </View>
  )
}
const titleStyles = StyleSheet.create({
  container: {
    display: 'flex'
  },
  text: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 24,
    textTransform: 'uppercase',
    color: '#4F4F4F'
  }
})