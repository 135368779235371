import { ZIO } from '@mxt/zio'
import { initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import { pipe } from 'fp-ts/function'
import { AppConfig } from './config'

export namespace firebase {
  const app = pipe(
    AppConfig.get,
    ZIO.flatMap((appConfig) => ZIO.effect(() => initializeApp(appConfig.firebase))),
    ZIO.cached()
  )

  const perf = pipe(
    app,
    ZIO.flatMap((app) => ZIO.effect(() => getPerformance(app)))
  )

  export const initApp = () =>
    pipe(
      perf,
      ZIO.fold(
        (error) => {
          console.log('init firebase error', error)
        },
        () => {
          console.log('init firebase success')
        }
      ),
      ZIO.run({})
    )
}
