import { ChangeContactInfoService } from '@pulseops/submission/common'
import { TaskDetail, GeneralService, Panel, TransactionType } from '@pulseops/common'
import { SourceType } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList } from '../common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { ErrorHandling } from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import { Text, StyleSheet, View, ActivityIndicator } from 'react-native'
import { useLoading } from '@mxt/zio-react'

type DataSource = {
  label: string | number | boolean | JSX.Element | TFunctionResult
  value: string | number | boolean | JSX.Element
  suffix?: string | number | boolean | JSX.Element
  isHighlight?: boolean
}

export const ChangeContactInfoAddress = ({ detail: gDetail }: { detail?: TaskDetail.ChangePrimaryAddressInfo }) => {
  const { t } = useTranslation()
  const [isLoading, bindLoading] = useLoading(false)

  const defaultCity = '08' // HCM
  const defaultDistrict = '01'

  let data: DataSource[] = []
  let priorPolicies: { policyNumber: string; address: string }[] = []

  if (gDetail?.detail.source === SourceType.PULSE4OPS || gDetail?.detail.source === SourceType.BANCA_SEA || gDetail?.detail.source === SourceType.OUTBOUND || gDetail?.detail.source === SourceType.INBOUND) {
    const { district, city, country, ward } = gDetail.detail
    const isPSDigital =
      gDetail?.detail.source === SourceType.BANCA_SEA &&
      gDetail?.tag === TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL
    const { detail, countries, provinces, districts, wards } = pipe(
      ZIO.zipPar(
        ChangeContactInfoService.getDetail(gDetail.policyNo, isPSDigital),
        GeneralService.getCountries,
        GeneralService.getProvinces,
        GeneralService.getDistricts(city ?? defaultCity),
        GeneralService.getWards({
          provinceCode: city ?? defaultCity,
          districtCode: district ?? defaultDistrict
        })
      ),
      ZIO.map(([detail, countries, provinces, districts, wards]) => {
        return { detail, countries, provinces, districts, wards }
      }),
      bindLoading,
      ErrorHandling.runDidMount()
    ) || { detail: null, countries: [], provinces: [], districts: [], wards: [] }

    const countryName: string = countries.find((c) => country === c.code)?.name ?? '-'
    const cityName: string = provinces.find((c) => c.code === city)?.name ?? '-'
    const districtName: string = districts.find((c) => c.code === district)?.name ?? '-'
    const wardName: string = wards.find((c) => c.code === ward)?.name ?? '-'

    priorPolicies = gDetail.detail.priorPolicies || []
    data = [
      {
        label: t('requestInfo:Country'),
        value: countryName,
        isHighlight: country !== detail?.nationality
      },
      {
        label: t('contact:CityProvince'),
        value: cityName,
        isHighlight: city !== detail?.province
      },
      {
        label: t('requestInfo:District'),
        value: districtName,
        isHighlight: city !== detail?.district
      },
      {
        label: t('contact:WardSubDistrict'),
        value: wardName,
        isHighlight: city !== detail?.ward
      },
      {
        label: t('contact:Address'),
        value: gDetail.detail.street,
        isHighlight: gDetail.detail.street !== detail?.street
      }
    ]
  } else if (gDetail?.detail.source === SourceType.PULSE) {
    const { district, city, country, ward } = gDetail.detail
    const { detail, countries } = pipe(
      ZIO.zipPar(ChangeContactInfoService.getDetail(gDetail.policyNo), GeneralService.getCountries),
      ZIO.map(([detail, countries]) => {
        return { detail, countries }
      }),
      bindLoading,
      ErrorHandling.runDidMount()
    ) || { detail: null, countries: [], provinces: [], districts: [], wards: [] }

    const countryName: string = countries.find((c) => country === c.code)?.name || '-'
    const cityName: string = city || '-'
    const districtName: string = district || '-'
    const wardName: string = ward || '-'

    data = [
      {
        label: t('requestInfo:Country'),
        value: countryName,
        isHighlight: country !== detail?.nationality
      },
      {
        label: t('contact:CityProvince'),
        value: cityName,
        isHighlight: city !== detail?.province
      },
      {
        label: t('requestInfo:District'),
        value: districtName,
        isHighlight: city !== detail?.district
      },
      {
        label: t('contact:WardSubDistrict'),
        value: wardName,
        isHighlight: city !== detail?.ward
      },
      {
        label: t('contact:Address'),
        value: gDetail.detail.street,
        isHighlight: gDetail.detail.street !== detail?.street
      }
    ]
  } else {
    data = [
      {
        label: t('contact:Address'),
        value: gDetail?.detail.source || '-'
      }
    ]
  }

  return isLoading ? (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator size="large" color="#ED1B2E" />
    </View>
  ) : (
    <Panel title={t('requestInfo:ChangeAddress')} isExand={false}>
      <InfoViewList dataSource={data} />
      {priorPolicies.length > 0 && (
        <>
          <Text style={styles.label}>{t('requestInfo:UpdatedForOtherPolicy')}</Text>
          {priorPolicies.map(({ policyNumber, address }, index) => (
            <Text key={index}>{`${policyNumber} ${address}`}</Text>
          ))}
        </>
      )}
    </Panel>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  }
})
