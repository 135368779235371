import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { FullSurrenderServiceModel } from './model'
import { TopUpService } from './TopUpService'

export namespace FullSurrenderService {
  export const getDetail = (policyNumber: string) =>
    pipe(
      ZIO.zipPar(
        POApi.get(`wf-api/policy/${policyNumber}/full-surrender`)(
          t.type({
            body: t.type({
              attributesExt: t.type({
                CASH_DEPOSIT: t.number,
                POLICY_LOAN: t.number,
                POLICY_DEBT: t.number,
                TOTAL_SURRENDER_AMOUNT: t.number,
                SURRENDER_CHARGE: t.number,
                SUSPENSE_AMOUNT: t.number
              })
            })
          })
        ),
        TopUpService.GetPolicyExtraInfo([policyNumber], '')
      ),
      ZIO.map(([_, policyExt]) => {
        const coverageCode =
          policyExt.policyExtraInfoDetail.find((x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00')
            ?.productCode ?? ''
        return {
          cashDeposit: _.body.attributesExt.CASH_DEPOSIT,
          suspenseAmount: _.body.attributesExt.SUSPENSE_AMOUNT,
          policyLoans: _.body.attributesExt.POLICY_LOAN,
          policyDebt: _.body.attributesExt.POLICY_DEBT,
          surrenderCharge: _.body.attributesExt.SURRENDER_CHARGE,
          totalSurrenderAmount: _.body.attributesExt.TOTAL_SURRENDER_AMOUNT,
          coverageCode: coverageCode,
          sumAssured: policyExt.policyExtraInfoDetail.reduce((acc, curr) => {
            return (curr.lifeNo === '01' && curr.riderNo === '00') || curr.productCode.startsWith('TLR')
              ? acc + curr.sumAssured
              : acc
          }, 0)
        }
      })
    )

  export const getAccess = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/full-surrender/validate`)(
        t.type({
          body: t.type({
            isValid: t.boolean
          })
        })
      ),
      ZIO.map((_) => _.body.isValid),
      ZIO.catchAll(() => ZIO.succeed(false))
    )

  export type SubmitData = FullSurrenderServiceModel.SubmitData
}
