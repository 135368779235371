import { pipe } from 'fp-ts/lib/function'
import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { AuthService } from '../auth'
import { POApi } from '../POApi'

export namespace Digital {
  export const PolicyInquiryC = t.type({
    policyNo: t.string,
    proposalNo: Maybe(t.string),
    productName: Maybe(t.string),
    planName: Maybe(t.string),
    productCode: Maybe(t.string),
    source: Maybe(t.string),
    firstIssueDate: Maybe(t.string),
    inceptionDate: Maybe(t.string),
    expiryDate: Maybe(t.string),
    coverageTotalPremium: Maybe(t.string),
    coverageTotalSumAssured: Maybe(t.string),
    referralCode: Maybe(t.string),
    status: Maybe(t.string),
    transactionId: Maybe(t.string),
    customerInfo: t.type({
      policyNo: Maybe(t.string),
      role: Maybe(t.string),
      fullName: Maybe(t.string),
      gender: Maybe(t.string),
      dob: Maybe(t.string),
      emailID: Maybe(t.string),
      nationalID: Maybe(t.string),
      phoneNum: Maybe(t.string),
      address: Maybe(t.string)
    }),
    riderInfos: t.array(
      t.type({
        policyNo: Maybe(t.string),
        componentCode: Maybe(t.string),
        componentName: Maybe(t.string),
        riskCessDate: Maybe(t.string),
        riskCommencementDate: Maybe(t.string),
        status: Maybe(t.string),
        sumAssured: Maybe(t.string),
        riderFlag: Maybe(t.string)
      })
    ),
    encryptedActivationCode: Maybe(t.string),
    partnerOrderId: Maybe(t.string),
    buyerUserId: Maybe(t.string),
    activatorUserId: Maybe(t.string),
    costVoucher: Maybe(t.number),
    differentVoucherAmount: Maybe(t.number),
    ape: Maybe(t.number)
  })
  export type PolicyInquiry = t.TypeOf<typeof PolicyInquiryC>

  export const PolicyInquiryDetailC = t.type({
    policyNo: t.string,
    proposalNo: Maybe(t.string),
    productName: Maybe(t.string),
    planName: Maybe(t.string),
    productCode: Maybe(t.string),
    source: Maybe(t.string),
    firstIssueDate: Maybe(t.string),
    inceptionDate: Maybe(t.string),
    expiryDate: Maybe(t.string),
    coverageTotalPremium: Maybe(t.string),
    status: Maybe(t.string)
  })
  export type PolicyInquiryDetail = t.TypeOf<typeof PolicyInquiryDetailC>

  export const PolicyInquiryListC = t.type({
    content: t.array(PolicyInquiryDetailC),
    totalElements: t.number
  })
  export type PolicyInquiryList = t.TypeOf<typeof PolicyInquiryListC>

  export const PolicyExportListC = t.array(PolicyInquiryC)
  export type PolicyExportList = t.TypeOf<typeof PolicyExportListC>

  export const VoucherInquiryC = t.type({
    chaVoucherCode: t.string,
    orderId: Maybe(t.string),
    batchId: Maybe(t.string),
    quantity: Maybe(t.number),
    purchasedDate: Maybe(t.string),
    expiryDate: Maybe(t.string),
    paymentsTxRef: Maybe(t.string),
    basePrice: Maybe(t.number)
  })
  export type VoucherInquiry = t.TypeOf<typeof VoucherInquiryC>

  export const VoucherInquiryListC = t.type({
    content: t.array(VoucherInquiryC),
    totalElements: t.number
  })
  export type VoucherInquiryList = t.TypeOf<typeof VoucherInquiryListC>

  export const VoucherExportListC = t.array(VoucherInquiryC)
  export type VoucherExportList = t.TypeOf<typeof VoucherExportListC>
}

export namespace DigitalInquiryService {
  export const getPolicyList = (
    policyNum: string,
    nationalId: string,
    fromDate: string,
    toDate: string,
    pagination: {
      pageSize: number
      pageNum: number
    }
  ): Task<Digital.PolicyInquiryList> =>
    POApi.post(`pvadigital/policy`, { params: { page: pagination.pageNum, size: pagination.pageSize } })(
      Digital.PolicyInquiryListC
    )({
      body: {
        fromDate,
        toDate,
        policyNum,
        nationalId
      }
    })

  export const getDetail = (policyNum: string): Task<Digital.PolicyInquiry> =>
    POApi.get(`pvadigital/policy/${policyNum}`)(Digital.PolicyInquiryC)

  export const exportPolicyData = (data: {
    reason: string
    policyNum: string
    nationalId: string
    fromDate: string
    toDate: string
  }): Task<Digital.PolicyExportList> =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((user) => {
        return POApi.post(`pvadigital/policy/add-export-reason`)(Digital.PolicyExportListC)({
          body: {
            reason: data.reason,
            createdBy: user.email,
            fromDate: data.fromDate,
            policyNum: data.policyNum,
            nationalId: data.nationalId,
            toDate: data.toDate
          }
        })
      })
    )

  export const getVoucherList = (
    voucherCode: string,
    fromDate: string,
    toDate: string,
    pagination: {
      pageSize: number
      pageNum: number
    }
  ): Task<Digital.VoucherInquiryList> =>
    POApi.post(`pvadigital/digital/voucher`, { params: { page: pagination.pageNum, size: pagination.pageSize } })(
      Digital.VoucherInquiryListC
    )({
      body: {
        fromDate,
        toDate,
        chaVoucherCode: voucherCode
      }
    })

  export const exportVoucherData = (data: {
    reason: string
    voucherCode: string
    fromDate: string
    toDate: string
  }): Task<Digital.VoucherExportList> =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((user) => {
        return POApi.post(`pvadigital/digital/voucher/add-export-reason`)(Digital.VoucherExportListC)({
          body: {
            reason: data.reason,
            createdBy: user.email,
            fromDate: data.fromDate,
            chaVoucherCode: data.voucherCode,
            toDate: data.toDate
          }
        })
      })
    )
}
