import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  DatePicker,
  ErrorHandling,
  form2,
  Input,
  PulseOpsFormat,
  RBAC,
  downloadURI,
  AuthService,
  DistributionServices
} from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { pipe } from 'fp-ts/function'
import { default as React } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, Text, TextStyle, TouchableOpacity, View } from 'react-native'
import { ExportSearchForm, TabSearchForm } from './distribution-service-form'
import * as _ from 'lodash'
import { RView } from './r-view'
import { ExportTask } from './ExportTask'
import moment from 'moment'
import { CertApi } from './export-cert-service'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  },
  cellMinWidth: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    minWidth: 240
  }
})

export const ExportCertScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const [tab, setTab] = React.useState<TabSearchForm | null>(null)

  const { t } = useTranslation()

  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const ExportTableRef = React.useRef<ExportRef | null>(null)

  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      setTab(TabSearchForm.ExportData)
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('TaskManagement:DistributionService'),
          navigate: () => {
            props.navigation.navigate('DistributionServicesScreen')
          }
        },
        {
          title: t('DistributionService:ExportCert'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getFormSearch = () => {
    if (tab === TabSearchForm.ExportData) {
      return (
        <ExportFormSearch
          onExport={(submitData) => {
            const data: ExportTask.ExportCert = {
              fromDate: submitData.fromDate
                ? PulseOpsFormat.datetoFormat(submitData.fromDate, 'DD/MM/yyyy').toString()
                : '',
              toDate: submitData.toDate ? PulseOpsFormat.datetoFormat(submitData.toDate, 'DD/MM/yyyy').toString() : '',
              ciNumber: submitData?.ciNumber ?? '',
              idNumber: submitData?.idNumber ?? ''
            }
            return exportCert(data)
          }}
          onSearch={(searchData) => {
            const data: ExportTask.SearchCert = {
              fromDate: searchData.fromDate
                ? PulseOpsFormat.datetoFormat(searchData.fromDate, 'DD/MM/yyyy').toString()
                : '',
              toDate: searchData.toDate ? PulseOpsFormat.datetoFormat(searchData.toDate, 'DD/MM/yyyy').toString() : '',
              ciNumber: searchData.ciNumber ?? '',
              idNumber: searchData.idNumber ?? '',
              maxRecordPerPage: 10,
              pageToGet: 0,
              propertySort: 'idNumber',
              directionSort: 'asc'
            }

            return ExportTableRef?.current?.onSearch?.(data)
          }}
        />
      )
    }
  }

  const getContentForm = () => {
    if (tab === TabSearchForm.ExportData) {
      return <ExportTable ref={ExportTableRef} />
    }
  }

  const exportCert = ({ fromDate, toDate, ciNumber, idNumber }: ExportTask.ExportCert) => {
    let data = {
      fromDate: fromDate,
      toDate: toDate,
      ciNumber: ciNumber,
      idNumber: idNumber
    }
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, CertApi.exportCert(data)),
      ZIO.flatMap(([token, loginType, res]) => {
        return !!res && !!res.body
          ? pipe(
              ZIO.fromPromise(() =>
                fetch('data:application/vnd.openxmlformats-officedocument.speadsheetml.sheet;base64,' + res.body, {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Authen-Vendor': loginType
                  }
                })
              ),
              ZIO.flatMap((res) =>
                ZIO.zipPar(
                  ZIO.succeed(res),
                  ZIO.fromPromise(() => res.blob())
                )
              ),
              ZIO.map(([res, blob]) => {
                return downloadURI(window.URL.createObjectURL(blob), `Report Cert_result_export.xlsx`)
              })
            )
          : ZIO.succeed(showToast(t(`message:MS030029`), 'error'))
      }),
      ZIO.unsafeRun({})
    )
  }

  return tab === null ? (
    <></>
  ) : (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View>
          <View style={styles.searchContainer}>
            {getFormSearch()}
            {/* end form */}
          </View>
          {/* content form */}
          {getContentForm()}
          {/* end content form */}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

export interface ExportRef {
  onSearch: (data: any) => void
}

const ExportFormSearch = (props: {
  onExport: (data: ExportSearchForm.Validated) => void
  onSearch: (data: ExportSearchForm.Validated) => void
}) => {
  const { onExport, onSearch } = props

  const { base, handleSubmit } = form2.useForm(ExportSearchForm.codec, {
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  })
  const { t } = useTranslation()

  const currentDate = new Date()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const onExportData = handleSubmit((validated) => {
    return onExport({
      ...validated
    })
  })

  const onSearchAVICAD = handleSubmit((validated) => {
    return onSearch({
      ...validated
    })
  })

  return (
    <View style={{ paddingHorizontal: 20 }}>
      <View style={styles.wrap}>
        <Controller
          name="fromDate"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={styles.inputStyle}>
                <DatePicker
                  required
                  label={t('DistributionService:FromDate')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  maxDate={currentDate}
                  maxDateMessage={t('message:MS990032')}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
        <Controller
          name="toDate"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={styles.inputStyle}>
                <DatePicker
                  required
                  label={t('DistributionService:ToDate')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  maxDate={currentDate}
                  maxDateMessage={t('message:MS990032')}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
        <Controller
          name="idNumber"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={[styles.inputStyle, { marginTop: 6 }]}>
                <Input
                  title={t('DistributionService:idNumber')}
                  value={value ?? undefined}
                  onChange={onChange}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
      </View>
      <View style={styles.wrap}>
        <Controller
          name="ciNumber"
          control={base.control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <RView width={'30%'} style={[styles.inputStyle, { marginTop: 6 }]}>
                <Input
                  title={t('DistributionService:ciNumber')}
                  value={value ?? undefined}
                  onChange={onChange}
                  errorMessage={error?.message}
                />
              </RView>
            )
          }}
        />
      </View>
      <View style={[styles.row, { alignItems: 'center' }]}>
        <TouchableOpacity
          style={{ width: 118, height: 40 }}
          onPress={() => {
            onSearchAVICAD()
          }}
        >
          <View style={styles.btn}>
            <Text style={{ textAlign: 'center', color: '#fff' }}>{t('DistributionService:View')}</Text>
          </View>
        </TouchableOpacity>
        {permissions.includes(DistributionServices.ExportAvicadDistributionServices) && (
          <TouchableOpacity
            style={{ width: 118, height: 40, marginLeft: 30 }}
            onPress={() => {
              onExportData()
            }}
          >
            <View style={styles.btn}>
              <Text style={{ textAlign: 'center', color: '#fff' }}>{t('DistributionService:Export')}</Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
      <View style={[styles.row, { marginTop: 6 }]}>
        <Text style={{ color: '#ED1B2E' }}>{_.get(base.formState.errors, '.message')}</Text>
      </View>
    </View>
  )
}

const ExportTable = React.forwardRef((props: {}, ref: React.Ref<ExportRef>) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [exportData, setExportData] = React.useState<Array<CertApi.SearchElement>>([])
  const [submittedData, setSubmittedData] = React.useState<ExportTask.SearchCert>({
    fromDate: '',
    toDate: '',
    ciNumber: '',
    idNumber: '',
    maxRecordPerPage: 0,
    pageToGet: 0,
    propertySort: '',
    directionSort: ''
  })

  const [loading, bindLoading] = useLoading(false)

  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)

  React.useImperativeHandle(ref, () => ({
    onSearch: (data) => {
      setSubmittedData(data)
      getPremiumCollectionTask(data, pageSize, pageNum)
    }
  }))

  const getPremiumCollectionTask = (submittedData: ExportTask.SearchCert, pageSize: number, pageNum: number) => {
    pipe(
      CertApi.searchCert({
        ...submittedData,
        maxRecordPerPage: pageSize,
        pageToGet: pageNum
      }),
      ZIO.tap((res) => {
        setTotalItem(res.body.totalElement)
        setMessageResponse(res.body.elements?.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      ZIO.tap((res) => {
        setExportData(res.body.elements ?? [])
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }
  if (exportData.length === 0) {
    return null
  }
  return (
    <View>
      {messageResponse ? (
        <View style={{ marginHorizontal: 30 }}>
          <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
        </View>
      ) : (
        <View style={{ marginHorizontal: 30 }}>
          <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
            <Table style={{ userSelect: 'text' }} stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableCell}>{t('DistributionService:FullName')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:DateOfBirth')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Sex')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:IdNumber')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:CiNumber')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:ExamCode')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:ExamDate')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:ExamPlace')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:MofNumber')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:MofDate')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:CertNumber')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:CertDate')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Validflag')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Userimport')}</TableCell>
                  <TableCell className={classes.tableCell}>{t('DistributionService:Importdate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exportData.map((row, index) => (
                  <TableRow key={`___list_row${index}`}>
                    <TableCell>{row.fullName ? row.fullName : '-'}</TableCell>
                    <TableCell>{row.dob ? row.dob : '-'}</TableCell>
                    <TableCell className={classes.cellNoWrap}> {row.sex ? row.sex : '-'}</TableCell>
                    <TableCell>{row.idNumber ? row.idNumber : '-'}</TableCell>
                    <TableCell>{row.ciNumber ? row.ciNumber : '-'}</TableCell>
                    <TableCell>{row.examCode ? row.examCode : '-'}</TableCell>
                    <TableCell>{row.examDate ? row.examDate : '-'}</TableCell>
                    <TableCell>{row.examPlace ? row.examPlace : '-'}</TableCell>
                    <TableCell>{row.mofNumber ? row.mofNumber : '-'}</TableCell>
                    <TableCell>{row.mofDate ? row.mofDate : '-'}</TableCell>
                    <TableCell className={classes.cellNoWrap}>{row.certificateNumber ?? '-'}</TableCell>
                    <TableCell>{row.certificateDate ? row.certificateDate : '-'}</TableCell>
                    <TableCell>{row.validflag ? row.validflag : '-'}</TableCell>
                    <TableCell>{row.userImport ? row.userImport : '-'}</TableCell>
                    <TableCell>{row.importDate ? moment(row.importDate).format('DD/MM/yyyy') : '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={pageNum}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              setPageNum(page)
              getPremiumCollectionTask(submittedData, pageSize, page)
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageNum(0)
              getPremiumCollectionTask(submittedData, Number(e.target.value), 0)
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 30,
    paddingBottom: 16,
    paddingTop: 16
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  }
} as const)
