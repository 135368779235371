export namespace Banks {
  export const RawBanks: { bankCode: string; name: string; nameEn?: string }[] = [
    {
      bankCode: '',
      name: 'All'
    },
    {
      bankCode: 'V2',
      name: 'MyVIB'
    },
    {
      bankCode: 'M2',
      name: 'Momo'
    },
    {
      bankCode: 'A9',
      name: 'VNPost'
    },
    {
      bankCode: 'S6',
      name: 'VBA'
    },
    {
      bankCode: 'L3',
      name: 'Vietel'
    },
    {
      bankCode: 'D7',
      name: 'POS SeaBank'
    },
    {
      bankCode: 'I2',
      name: 'Ebanking SeaBank'
    },
    {
      bankCode: 'O1',
      name: 'PruOnline - Thẻ quốc tế',
      nameEn: 'PruOnline - International Card (Visa/Mastercard)'
    },
    {
      bankCode: 'O2',
      name: 'PruOnline - Thẻ ATM',
      nameEn: 'PruOnline - Domestic Card (ATM)'
    },
    {
      bankCode: 'O3',
      name: 'Recurring'
    },
    {
      bankCode: 'DK',
      name: 'VNPay'
    },
    {
      bankCode: 'C9',
      name: 'Zalo'
    },
    {
      bankCode: 'P2',
      name: 'Shopee'
    },
    {
      bankCode: 'M7',
      name: 'Mpos - Sacombank'
    },
    {
      bankCode: 'W1',
      name: 'Vietel-Telco'
    },
    {
      bankCode: 'T3',
      name: 'TCB'
    },
    {
      bankCode: 'A7',
      name: 'Citi'
    },
    {
      bankCode: 'S1',
      name: 'Maritime'
    },
    {
      bankCode: 'P3',
      name: 'PVCombank'
    },
    {
      bankCode: 'S2',
      name: 'VCB'
    },
    {
      bankCode: 'B3',
      name: 'Seabank'
    },
    {
      bankCode: 'S3',
      name: 'Sinhan'
    },
    {
      bankCode: 'C3',
      name: 'SCB'
    },
    {
      bankCode: 'U3',
      name: 'UOBank'
    },
    {
      bankCode: 'V3',
      name: 'VIB'
    },
    {
      bankCode: 'I3',
      name: 'VietBank'
    },
    {
      bankCode: 'C7',
      name: 'POS Eximbank'
    },
    {
      bankCode: 'X1',
      name: 'Auto debit VCB'
    },
    {
      bankCode: 'A0',
      name: 'ShopeePay'
    },
    {
      bankCode: 'I4',
      name: 'MSB BANK'
    },
    {
      bankCode: 'M3',
      name: 'MOMO FOR AGENT'
    },
    {
      bankCode: 'P9',
      name: 'PAYOO'
    },
    {
      bankCode: 'V4',
      name: 'VIB AUTODEBIT'
    },
    {
      bankCode: 'M9',
      name: 'Momo PruOnline'
    },
    {
      bankCode: 'D6',
      name: 'Sacombank App'
    },
    {
      bankCode: 'D8',
      name: 'Sacombank Counter'
    },
    {
      bankCode: 'D9',
      name: 'Sacombank Banktransfer'
    },
    {
      bankCode: 'V5',
      name: 'Credit Life Portal'
    }
  ]
}
