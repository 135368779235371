import { GeneralData } from '../GeneralService'
import { ReinstatementModel } from './Reinstatement'
import * as t from 'io-ts'
import { StorageBlob } from '../../storage-blob'

export namespace SURedating {
  export interface DetailData {
    totalPremium: number
    riskCommenceDate: string
    billingFrequency: string
    newRCD: string
    lapsedDate: string
    reinData: ReinstatementModel.ReinData | undefined
    occupationList: GeneralData[]
    EstimatedTotalReinstatementAmount?: number
    lapsedPeriod?: number
    agentCode: string
    agentName?: string
  }

  export interface RedatingSubmissionData {
    uploadedFiles: StorageBlob.FileContentSubmit[]
    redatingInputedData: RedatingInputData
  }

  export interface RedatingInputData {
    rcd: string
    newRcd: string
    frequency: string
    regularPremium: number
    lapsedDate: string
    totalReinsAmount: number
    agentCode: string
    agentName: string
    lifeAssuredList: LifeAssuredData[]
  }

  export interface LifeAssuredData {
    clientNo: string
    healthStateChange: boolean
    occupationChange: boolean
    curOccupation: string
    newOccupation: string
    activityChange: boolean
    newActivity: string
    companyChange: boolean
    newCompany: string
  }

  export interface AcessData {
    PODateOfDead: string
    coverageCode: string
    contractStatus: string
    paidToDateAdvance: string
    riskCommenceDate: string
    errorCode: string
    lapsedDate: string
  }

  export const redatingInfo = t.type({
    policyNumber: t.string,
    billFreq: t.string,
    totalPremium: t.number,
    riskCommenceDate: t.string,
    newRCD: t.string,
    lapsedDate: t.string
  })

  export type redatingInfo = t.TypeOf<typeof redatingInfo>
}
