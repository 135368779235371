import { Task, Throwable, ZIO } from '@mxt/zio'
import { Ref } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import { Lens } from 'monocle-ts'
import * as O from 'fp-ts/Option'

export const refCache =
  <S>(ref: Ref<Throwable, S>) =>
    <A, B extends A>(lens: Lens<S, B | null>) =>
      (zio: Task<A>) =>
        pipe(
          ref.select(lens.get),
          ZIO.map(O.fromNullable),
          ZIO.mapFoldOptionM(
            () =>
              pipe(
                zio,
                ZIO.tap((a) => pipe(lens.set(a as B), ref.update))
              ),
            ZIO.succeed
          )
        )

export const refCacheWithCondition = <S>(ref: Ref<Throwable, S>) =>
  <A, B extends A>(lens: Lens<S, B | null>, isCallZIO: boolean) =>
    (zio: Task<A>) =>
      pipe(
        ref.select(lens.get),
        ZIO.map(O.fromNullable),
        ZIO.mapFoldOptionM(
          () =>
            pipe(
              zio,
              ZIO.tap((a) => pipe(lens.set(a as B), ref.update))
            ),
          (refData) => isCallZIO ?
            pipe(
              zio,
              ZIO.tap((a) => pipe(lens.set(a as B), ref.update))
            ) : ZIO.succeed(refData)
        )
      )