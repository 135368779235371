import React from 'react'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import { IDCSharedStyles } from '../IDCSharedStyles'
import { OBActionHistoryService, OBCallOutPopupConst, OBCallOutPopupService, OBContainer, OBCustomerAnswerConst, OBFieldDescription, OBFieldTitle, OBFormat, OBGeneralTable, OBGeneralTableColum, OBSectionCol, OBSectionRow } from '@pulseops/outbound'
import { useTranslation } from 'react-i18next'
import * as O from 'fp-ts/lib/Option'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { Container, ErrorHandling, IDCDetailService } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { IDCContainer } from '../independence-components'
import { IDCDetailContext } from '../IDCDetailContext'

export const IDCActionHistory = () => {
  const [isLoading, bindLoader] = useLoading(false)
  const { t, i18n } = useTranslation()
  // const processInstanceId = '5a836657-db88-11ee-ab42-2e54100ee1f4'
  const { processInstanceID, OBSelectedCaseID, setOBSelectedCaseID } = React.useContext(IDCDetailContext)
  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t('Outbound:OBActionHistory:No'),
      fieldName: 'STT'
    },
    {
      title: t('Call ID'),
      fieldName: 'callId',
    },
    {
      title: t('Outbound:OBTaskManagement:CurrentAssignee'),
      fieldName: 'assignee'
    },
    {
      title: t('Outbound:OBActionHistory:TeamLeader'),
      fieldName: 'teamLeader'
    },
    {
      title: t('Outbound:OBTaskManagement:CallingProgram'),
      fieldName: 'callProgram'
    },
    {
      title: t('Outbound:OBActionHistory:ActionDate'),
      fieldName: 'actionDate',
      minWidth: 150
    },
    {
      title: t('Outbound:OBActionHistory:ActionType'),
      fieldName: 'actionType',
      minWidth: 150
    },
    // {
    //   title: t('Outbound:OBActionHistory:ConnectionStatus'),
    //   fieldName: '', // dang thieu
    //   minWidth: 160
    // },
    {
      title: t('Outbound:OBActionHistory:CallReceiver'),
      fieldName: 'callingReceiver',
      minWidth: 170
    },
    // {
    //   title: t('Outbound:OBActionHistory:CallDuration'),
    //   fieldName: 'manualDuration'
    // },
    {
      title: t('Outbound:OBActionHistory:CallResult'),
      fieldName: 'callingResult'
    },
    {
      title: t('Outbound:OBCallOutPopup:CallbackTime'),
      fieldName: 'callbackTime'
    },
    {
      title: t('Outbound:OBActionHistory:QuickNote'),
      fieldName: 'quickNote'
    },
    {
      title: t('Outbound:OBActionHistory:CommunicationHistory'),
      fieldName: 'communicationHistory', //field sending type
      minWidth: 160
    },
    {
      title: t('Outbound:OBActionHistory:SavedDate'),
      fieldName: 'saveDate',// thieu field save time
      minWidth: 170
    },
    {
      title: t('Outbound:OBActionHistory:Hint'),
      fieldName: 'suggestion',
      minWidth: 140
    }
  ]

  const getActionType = (callingMethod: string, phoneNumber: string) => {
    switch (callingMethod) {
      case 'CM02': {
        const actionType = 'M' + '-' + phoneNumber
        return actionType
      }
      case 'CM03': {
        const actionType = 'H' + '-' + phoneNumber
        return actionType
      }
      case 'CM04': {
        const actionType = 'C' + '-' + phoneNumber
        return actionType
      }
      case 'CM05': {
        const actionType = 'O' + '-' + phoneNumber
        return actionType
      }
      default: {
        return ''
      }

    }
  }

  React.useEffect(()=> {
    return ()=> {
      setOBSelectedCaseID('')
    }
  },[])

  const actionHistoryList = pipe(
    ZIO.zipPar(
      IDCDetailService.getActionHistoryList(processInstanceID),
      IDCDetailService.getReceiverAndCallResultList()
    ),
    ZIO.map(([responseData, callReceiverAndCallingResultList]) => {
      const filterData = pipe(
        responseData,
        O.fromNullable,
        O.fold(
          () => [],
          (dataList) => {
            const actionList = dataList.map((actionHistory) => {
              const actionHistoryList = actionHistory.callOutInfo.map((item) => {
                const suggestionItem = pipe(
                  OBCallOutPopupConst.HintList.find((x) => x.code === item.callingInfoDTO.hint),
                  O.fromNullable,
                  O.map((item) => (i18n.language === 'en' ? item.nameEn : item.nameVi)),
                  O.getOrElse(() => '')
                )
                const savedDate = item.savedDate ? OBFormat.formatDateToDateString(new Date(item.savedDate)) : ''
                const calledDate = item.calledDate ? OBFormat.formatDateToDateString(new Date(item.calledDate)) : ''
                const actionType = getActionType(item.callingInfoDTO.callingMethod || '', item.callingInfoDTO.phoneNumber || '')
                const callingReceiver = pipe(
                  callReceiverAndCallingResultList.callReceiverList.find((x) => x.code === item.callingInfoDTO.callReceiver),
                  O.fromNullable,
                  O.map((item) => (i18n.language === 'en' ? item.nameEn : item.nameVi)),
                  O.getOrElse(() => '')
                )
                const callingResult = pipe(
                  callReceiverAndCallingResultList.callResultList.find((x) => x.code === item.callingInfoDTO.callingResult),
                  O.fromNullable,
                  O.map((item) => (i18n.language === 'en' ? item.nameEn : item.nameVi)),
                  O.getOrElse(() => '')
                )
                const callbackTime = pipe(
                  item.callingInfoDTO.callbackTime,
                  O.fromNullable,
                  O.map((callBackItem) => OBFormat.formatDateToDateString(new Date(callBackItem))),
                  O.getOrElse(() => '')
                )
                return {
                  callId: pipe(item.callingInfoDTO.callId, O.fromNullable, O.map((item) => item), O.getOrElse(() => '')),
                  assignee: item.assignee || '',
                  teamLeader: item.teamLeader || '',
                  callProgram: item.transactionType || '',
                  actionDate: calledDate,
                  calledDate: item.calledDate || '',
                  callingReceiver: callingReceiver,
                  manualDuration: item.callingInfoDTO.callingDuration || '',
                  callingResult: callingResult,
                  callbackTime: callbackTime,
                  quickNote: item.callingInfoDTO.quickNote || '',
                  suggestion: suggestionItem,
                  saveDate: savedDate,
                  actionType: actionType,
                  communicationHistory: item.sendingFileInfoDTO.communicationHistory || '',
                  originSaveDate: item.savedDate || ''
                }
              })
              return {
                ...actionHistory,
                callOutInfo: actionHistoryList
              }
            })
            return actionList
          }
        )
      )
      return filterData
    }),
    ZIO.catchAll((err) => {
      return ZIO.fail(err)
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([processInstanceID])
  ) || []

  const optionItem = (idcType: string) => {
    const IDCTypeName = pipe(
      OBCustomerAnswerConst.IDCTypeConst.find((p) => p.code === idcType),
      O.fromNullable,
      O.fold(
        () => '-',
        (typeItem) => i18n.language === 'en' ? typeItem.nameEn : typeItem.nameVi
      )
    )
    return IDCTypeName
  }

  return (
    <Container loading={isLoading}>
      <View style={IDCSharedStyles.container}>
        {actionHistoryList && actionHistoryList.map((actionItem, index) => {
          return (
            <>
              <IDCContainer containerStyle={index > 0 && actionHistoryStyles.secondLine} containerTitle={(isContainerOpen) => (
                <>
                  <View style={actionHistoryStyles.answerHeader1}>
                    <OBSectionCol>
                      <OBFieldTitle text={t('Business key')}></OBFieldTitle>
                      <OBFieldDescription text={actionItem.obBusinessKey || ''}></OBFieldDescription>
                    </OBSectionCol>
                    <OBSectionCol>
                      <OBFieldTitle text={t('Outbound:OBCustomerAnwser:IDCType')}></OBFieldTitle>
                      <OBFieldDescription text={optionItem(actionItem.idcInfo.idcType)}></OBFieldDescription>
                    </OBSectionCol>
                    {/* <View>
                      <OBFieldTitle text={t('IDCDetail:CreateBy')}></OBFieldTitle>
                      <OBFieldDescription text={''}></OBFieldDescription>
                    </View>
                    <View>
                      <OBFieldTitle text={t('IDCDetail:CreateDate')}></OBFieldTitle>
                      <OBFieldDescription text={''}></OBFieldDescription>
                    </View> */}
                  </View>
                  {/* {isContainerOpen && (
                    <View style={{ display: 'flex' }}>
                      <OBFieldTitle text={t('Business key')}></OBFieldTitle>
                      <OBFieldDescription text={actionItem.obBusinessKey || ''}></OBFieldDescription>
                    </View>
                  )} */}
                </>
              )} isOpenContainer={OBSelectedCaseID === actionItem.obBusinessKey}>
                <OBGeneralTable displayedColumns={displayedColumns} dataSource={actionItem.callOutInfo} containerStyle={{marginVertical: 0}}></OBGeneralTable>
              </IDCContainer>
            </>
          )
        })}
      </View>
    </Container>

  )
}

export const actionHistoryStyles = StyleSheet.create({
  answerHeader1: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -15
  },
  secondLine: {
    marginTop: 24
  },
})