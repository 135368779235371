import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  Checkbox,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  SelectOption,
  SelectSearch,
  useMobile
} from '@pulseops/common'
import { InquiryComplaintConst } from '@pulseops/task'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ComplainInquiryForm } from './complain-inquiry-form'
import { useLoading } from '@mxt/zio-react'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail: InquiryComplaintData.DetailData | null
  getCategoryCode: (value: string) => void
  getGroupCode: (value: string) => void
  onShowConservation: (value: boolean) => void
  isReset: boolean
  policyOwner: string
}

type Option = {
  label: string
  value: string
}

export const GeneralInfomation: React.FC<Props> = ({
  form,
  detail,
  getCategoryCode,
  getGroupCode,
  onShowConservation,
  isReset,
  policyOwner
}) => {
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [groupList, setGroupList] = useState<InquiryComplaintData.IACGroup[]>([])
  const [subServiceTypeList, setSubServiceTypeList] = useState<InquiryComplaintData.IACType[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = useState<InquiryComplaintData.IACSubtypes[]>([])
  const [requestorRoleList, setRequestorRoleList] = useState<Option[]>(
    InquiryComplaintConst.requestorRoleList.map((item) => ({
      label: t(`roles:${item.label}`) as string,
      value: item.value
    })) || []
  )
  const [relationshipList, setRelationshipList] = useState<InquiryComplaintConst.Group[]>([])
  const [isDisabledFullname, setDisabledFullname] = useState<boolean>(false)
  const [isDisabledRPO, setDisabledRPO] = useState<boolean>(false)
  const [isDisabledFCR, setDisabledFCR] = useState<boolean>(false)
  const [isDisabledHot, setDisabledHot] = useState<boolean>(false)
  const [isLoading, bindLoader] = useLoading(false)
  const listMethodShowCallID = ['MT02', 'MT03', 'MT12']

  const { control, watch, setValue, clearErrors } = form

  const accountLogIn: string = ''

  useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setDisabledFullname(false)
    setDisabledRPO(false)
    setDisabledFCR(false)
    setDisabledHot(false)
  }, [isReset])

  useEffect(() => {
    if (detail?.groupList) {
      setGroupList(arrangeAndFilterGroupListByCagetory(detail.groupList))
    }
    if (detail?.benList && detail.benList.length <= 0) {
      setRequestorRoleList(requestorRoleList.filter((x) => x.value !== '3'))
    }
  }, [detail])

  const converOptions = (arr: any[]): Option[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  const arrangeAndFilterGroupListByCagetory = (groupList: InquiryComplaintData.IACGroup[]) => {
    const inquiryList = groupList.filter((x) => x.categoryName === 'Inquiry')
    const complaintList = groupList.filter((x) => x.categoryName === 'Complaint')
    return [...inquiryList, ...complaintList]
  }

  const onGroupChange = (e: Option | null) => {
    // if (!e) {
    //   setSubServiceTypeList([])
    //   setSubTransactionTypeList([])
    // }
    setSubServiceTypeList([])
    setSubTransactionTypeList([])
    getGroupCode(e?.value || '')
    getSubServiceTypeList(e?.value || '')
    setValue('generalInfo.type', null)
    setValue('generalInfo.subType', null)
    onShowHideConservation(null)
    onResetValue(e?.value || '')
    const complaintItem = groupList.find((x) => x.code === e?.value)
    if (
      complaintItem?.categoryName === 'Complaint' ||
      (complaintItem?.categoryName === 'Inquiry' && accountLogIn === 'Customer')
    ) {
      setValue('generalInfo.firstContactResolution', false)
      setDisabledFCR(true)
    } else {
      setDisabledFCR(false)
      setValue('generalInfo.firstContactResolution', false)
    }
    if (complaintItem?.categoryName === 'Inquiry') {
      getCategoryCode('CS')
    } else {
      getCategoryCode('CH')
    }
  }

  const onTypeChange = (e: Option | null) => {
    clearErrors('generalInfo.subType')
    setValue('generalInfo.subType', null)
    console.log('GROUP', watch('generalInfo.group'))
    if (
      watch('generalInfo.group')?.value === 'CHG1' ||
      watch('generalInfo.group')?.value === 'CHG2' ||
      watch('generalInfo.group')?.value === 'CHG3' ||
      watch('generalInfo.group')?.value === 'CHG4'
    ) {
      setDisabledFCR(true)
      setValue('generalInfo.firstContactResolution', false)
    } else {
      setDisabledFCR(false)
    }

    setSubTransactionTypeList([])
    onShowHideConservation(null)
    onResetValue(e?.value || '')
    if (!!e) {
      getSubTransactionTypeList(e?.value)
    }
  }

  const onSubTypeChange = (e: Option | null) => {
    onShowHideConservation(e)
    onResetValue(e?.value || '')
  }

  const onChangeRequestRole = (e: any): void => {
    clearErrors([
      'generalInfo.relationshipWithPolicyOwner',
      'generalInfo.requestorFullName1',
      'generalInfo.requestorFullName'
    ])
    if (!!e) {
      switch (e.value) {
        case '1':
          setValue('generalInfo.requestorFullName', policyOwner ? policyOwner : detail?.poName || '')
          setValue('generalInfo.relationshipWithPolicyOwner', null)
          clearErrors('generalInfo.relationshipWithPolicyOwner')
          setDisabledFullname(true)
          setDisabledRPO(true)
          setRelationshipList([])
          break
        case '2':
          setValue('generalInfo.requestorFullName1', null)
          setValue('generalInfo.relationshipWithPolicyOwner', null)
          clearErrors('generalInfo.relationshipWithPolicyOwner')
          setDisabledFullname(false)
          setDisabledRPO(true)
          setRelationshipList([])
          break
        case '3':
          setValue('generalInfo.requestorFullName1', null)
          setValue('generalInfo.relationshipWithPolicyOwner', null)
          clearErrors('generalInfo.relationshipWithPolicyOwner')
          setDisabledFullname(false)
          setDisabledRPO(true)
          setRelationshipList([])
          break
        case '4':
          setValue('generalInfo.requestorFullName', '')
          setValue('generalInfo.relationshipWithPolicyOwner', null)
          setDisabledFullname(false)
          setDisabledRPO(false)
          if (detail?.CBrelationshipList) {
            setRelationshipList(
              detail.CBrelationshipList.map((relationshipItem) => {
                return {
                  code: relationshipItem.code,
                  nameVi: relationshipItem.name,
                  name: relationshipItem.nameEn,
                  parentCode: ''
                }
              })
            )
          } else {
            setRelationshipList([])
          }
          break
      }
    } else {
      setValue('generalInfo.requestorFullName', '')
      setValue('generalInfo.requestorFullName1', null)
      setValue('generalInfo.relationshipWithPolicyOwner', null)
      setDisabledFullname(false)
      setDisabledRPO(false)
      setRelationshipList([])
    }
  }

  const onChangeRequestorFullName = (e: any) => {
    clearErrors(['generalInfo.relationshipWithPolicyOwner'])
    if (!!e) {
      const requestInfoRole = form.getValues('generalInfo.requestorRole')
      setValue('generalInfo.relationshipWithPolicyOwner', null)
      if (requestInfoRole?.value) {
        if (requestInfoRole.value === '2') {
          getRelationshipListByLA(e?.value)
        }
        if (requestInfoRole.value === '3') {
          getRelationshipListByBE(e?.value)
        }
      }
    } else {
      setRelationshipList([])
      setValue('generalInfo.relationshipWithPolicyOwner', null)
    }
  }

  const onShowHideConservation = (e: Option | null) => {
    if (e?.value === 'CS2701') {
      onShowConservation(true)
      setValue('generalInfo.hot', false)
      setDisabledHot(true)
      setValue('generalInfo.firstContactResolution', true)
      setDisabledFCR(true)
    } else {
      onShowConservation(false)
      setValue('generalInfo.hot', false)
      setDisabledHot(false)
      setValue('generalInfo.firstContactResolution', false)
      // setDisabledFCR(false)
    }
  }

  const onResetValue = (value: string) => {
    clearErrors()
    setValue('communicationInfo.customerComments', '')
    setValue('communicationInfo.responsesToCustomer', '')
    setValue('communicationInfo.attachmentFiles', [])

    setValue('conservationInfomation.attachmentFiles', [])
    setValue('conservationInfomation.conservationAgentCode', '')
    setValue('conservationInfomation.conservationAgentName', '')
    setValue('conservationInfomation.conservationBonus', null)
    setValue('conservationInfomation.conservationGAStaff', false)
    setValue('conservationInfomation.conservationNote', '')
    setValue('conservationInfomation.conservationResult', null)
    setValue('conservationInfomation.conservationSolution', null)
    setValue('conservationInfomation.reasonFullSurrender', null)
    if (value !== 'CHG4') {
      setValue('ga.generalAgency', null)
      setValue('ga.personToBeComplained', '')
    }
    if (value !== 'CHG3') {
      setValue('salesPractice.agentCode', '')
      setValue('salesPractice.agentName', '')
      setValue('salesPractice.POSignedInProposal', 'yes')
      setValue('salesPractice.customerReceivedDate', new Date())
      setValue('salesPractice.customerReceivedPolicyKit', 'yes')
      setValue('salesPractice.financialNeedAnalysis', null)
    }
    if (value !== 'CHG2') {
      setValue('moneyMissappropriation.list', [
        {
          receipt: 'no',
          receiptType: null,
          receiptNumber: '',
          complaintAmount: '',
          benefit_PremiumMisappropriatedDate: new Date()
        }
      ])
      setValue('moneyMissappropriation.totalComplaintAmount', '0')
    }
  }

  const compareItemsByName = (item1: any, item2: any) => {
    return i18n.language === 'en' ? item1.name.localeCompare(item2.name) : item1.nameVi.localeCompare(item2.nameVi)
  }

  const getSubServiceTypeList = (groupCode: string) => {
    if (groupCode) {
      pipe(
        InquiryComplaintService.getTypes(groupCode),
        ZIO.map((groupList) => {
          if (groupList && groupList.length > 0) {
            setSubServiceTypeList(groupList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
          } else {
            setSubServiceTypeList([])
          }
        }),
        ZIO.catchAll((error) => {
          return ZIO.fail(null)
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const getSubTransactionTypeList = (typeCode: string) => {
    pipe(
      InquiryComplaintService.getSubType(typeCode),
      ZIO.map((typeList) => {
        if (typeList && typeList.length > 0) {
          setSubTransactionTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
        } else {
          setSubTransactionTypeList([])
        }
      }),
      ZIO.catchAll((error) => {
        return ZIO.fail(null)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getRelationshipListByLA = (clientNumberOfLA: string) => {
    setRelationshipList([])

    if (detail?.BORelationshipList && detail?.BORelationshipList.length > 0) {
      const relationships: InquiryComplaintConst.Group[] = []
      let clientNumInfo = detail.BORelationshipList.find((x) => x.relationship === clientNumberOfLA)
      const relationshipFilterItem = detail.CBrelationshipList.find((p) => p.code === clientNumInfo?.clientNum)
      if (relationshipFilterItem) {
        relationships.push({
          code: relationshipFilterItem?.code ?? '',
          nameVi: relationshipFilterItem?.name ?? '',
          name: relationshipFilterItem?.nameEn ?? '',
          parentCode: ''
        })
        setValue('generalInfo.relationshipWithPolicyOwner', {
          value: relationshipFilterItem?.code ?? '',
          label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
        })
        setRelationshipList(relationships)
      }
    }
  }

  const getRelationshipListByBE = (clientNumberOfBen: string) => {
    setRelationshipList([])
    if (
      detail?.benList &&
      detail.benList.length > 0 &&
      detail.BERelationshipList &&
      detail.BERelationshipList.length > 0
    ) {
      const relationships: InquiryComplaintConst.Group[] = []
      const benItem = detail.benList.find((x) => x.clientNumberOfBen === clientNumberOfBen)
      const relationshipFilterItem = detail.BERelationshipList.find((p) => p.code === benItem?.relationShipCode)
      if (relationshipFilterItem) {
        relationships.push({
          code: relationshipFilterItem?.code ?? '',
          nameVi: relationshipFilterItem?.name ?? '',
          name: relationshipFilterItem?.nameEn ?? '',
          parentCode: ''
        })
        setValue('generalInfo.relationshipWithPolicyOwner', {
          value: relationshipFilterItem?.code ?? '',
          label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
        })
      }
      setRelationshipList(relationships)
    }
  }

  return (
    <View>
      <Panel title="" titleOutline={t('submission:GeneralInfomation')} isExand={false}>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.group"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:Group')}
                  required
                  options={converOptions(groupList || [])}
                  placeholder={t('common:Select')}
                  onChange={(e: Option | null) => {
                    onGroupChange(e)
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.type"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:Type')}
                  required
                  options={converOptions(subServiceTypeList || [])}
                  placeholder={t('common:Select')}
                  onChange={(e: Option | null) => {
                    onTypeChange(e)
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.subType"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:SubType')}
                  required
                  options={converOptions(subTransactionTypeList || [])}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onSubTypeChange(e)
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.requestorRole"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:RequestorRole')}
                  required
                  options={requestorRoleList}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChangeRequestRole(e)
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          {!['2', '3'].includes(watch('generalInfo.requestorRole')?.value || '') && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name="generalInfo.requestorFullName"
                control={control}
                render={({ field: { onBlur, value }, fieldState: { error } }) => (
                  <Input
                    title={t('requestInfo:RequestorFullname')}
                    required
                    onChange={(e) => form.setValue('generalInfo.requestorFullName', e.toUpperCase())}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    disabled={isDisabledFullname}
                    maxLength={120}
                  />
                )}
              />
            </View>
          )}
          {watch('generalInfo.requestorRole')?.value === '2' && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name="generalInfo.requestorFullName1"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('requestInfo:RequestorFullname')}
                    required
                    options={
                      (detail?.laList &&
                        detail.laList
                          // .filter((la) => la.clientNumberOfLA !== detail?.clientId)
                          .map((item) => ({
                            label: item.clientName,
                            value: item.clientNumberOfLA
                          }))) ||
                      []
                    }
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChangeRequestorFullName(e)
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    disabled={isDisabledFullname}
                    isShowFullText={true}
                  />
                )}
              />
            </View>
          )}
          {watch('generalInfo.requestorRole')?.value === '3' && (
            <View style={isMobile ? styles.colFull : styles.col}>
              <Controller
                name="generalInfo.requestorFullName1"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <SelectSearch
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('requestInfo:RequestorFullname')}
                    required
                    options={
                      (detail?.benList &&
                        detail.benList.map((item) => ({
                          label: item.clientName,
                          value: item.clientNumberOfBen
                        }))) ||
                      []
                    }
                    placeholder={t('common:Select')}
                    onChange={(e) => {
                      onChangeRequestorFullName(e)
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                    disabled={isDisabledFullname}
                    isShowFullText={true}
                  />
                )}
              />
            </View>
          )}
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.relationshipWithPolicyOwner"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:RelationshipWithPolicyOwner')}
                  required={!isDisabledRPO}
                  options={converOptions(relationshipList)}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder={t('common:Select')}
                  errorMessage={error?.message}
                  disabled={isDisabledRPO}
                  key={value?.value}
                  disableUnderline={isDisabledRPO}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.requestorNationalID"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('requestInfo:RequestorNationalID')}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  maxLength={24}
                />
              )}
            />
          </View>

          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.method"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:Method')}
                  required
                  options={converOptions(detail?.methodList || [])}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.source"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('requestInfo:Source')}
                  required
                  options={converOptions(detail?.sourceList || [])}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.hot"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={t('requestInfo:Hot')}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={isDisabledHot}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="generalInfo.firstContactResolution"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={t('requestInfo:FirstContactResolution')}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  disabled={isDisabledFCR}
                />
              )}
            />
          </View>
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  mb16: {
    marginBottom: 16
  }
})
