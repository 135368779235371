import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { AdmintoolService, OBGeneralTable, OBSharedStyles } from '@pulseops/outbound'
import { AppContext, ErrorHandling, ModalComponent, Permission, RBAC, SelectOption, assets } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useForm } from 'react-hook-form'
import { IBGeneralField, IBGeneralTable, TypeInputComponent } from '@pulseops/inbound'
import { getStyleStatus, getTitleStatus, statusOption, validateMail } from './commonFunction'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
type StaffListForm = {
  id: string
  user: string
  team: SelectOption | null
  extensionCode: string
  blackList: SelectOption | null
  status: SelectOption | null
}

type DataTable = {
  no: number
  id: string
  user: string
  team: string
  extensionCode: string
  blackList: string
  status: string
}

const blackListOption = [
  {
    label: 'Yes',
    value: 'Y'
  },
  {
    label: 'No',
    value: 'N'
  }
]

interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }
export const ConfigT4S15OutboundStaffListScreen = ({ navigation }: Props) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [isOpenAddPopup, setOpenAddPopup] = React.useState<boolean>(false)
  const [isOpenEditPopup, setOpenEditPopup] = React.useState<boolean>(false)
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [dataTable, setDataTable] = React.useState<DataTable[]>([])
  const [reload, setReload] = React.useState<boolean>(false)
  const [isLoading, bindLoading] = useLoading(false)
  const { height, width } = useWindowDimensions()
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [teamOption, setTeamOptione] = React.useState<SelectOption[]>([])

  const formStaffList = useForm<StaffListForm>({
    defaultValues: {
      id: '',
      user: '',
      team: null,
      extensionCode: '',
      blackList: blackListOption[1],
      status: statusOption[0]
    }
  })

  const filterColumnList = {
    user: '',
    team: '',
    extensionCode: ''
  }

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    pipe(
      ZIO.zip(
        AdmintoolService.getT4S15Config(),
        AdmintoolService.getT4S14Config()
      ),
      ZIO.map(([res, team]) => {
        const listTeam = team.data?.map((i) => ({
          label: i.teamName ?? '',
          value: i.teamNo?.toString() ?? ''
        })).sort((p1,p2) => Number(p1.value) > Number(p2.value) ? 1 : -1) || []
        res.data && setDataTable(res.data
        .map((i, index) => ({
          no: index + 1,
          id: i.id ?? '',
          user: i.staffEmail ?? '',
          team: listTeam && listTeam?.length > 0 ? (listTeam?.find((team) => i.teamNo && team.value === i.teamNo.toString())?.label ?? '') : i.teamNo?.toString() ?? '',
          extensionCode: i.extensionCode && i.extensionCode !== '-' ? i.extensionCode : '',
          blackList: i.blackList ?? '',
          status: i.status ? getTitleStatus(i.status) : ''
        }))
        .sort((p1,p2) => Number(p1.team) > Number(p2.team) ? 1 : (p1.team === p2.team ? p1.user.toLowerCase() > p2.user.toLowerCase() ? 1 : -1 : -1))
        .map((i, index) => ({
          ...i,
          no: index + 1,
        })))
        setTeamOptione(listTeam ?? [])
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }, [])

  React.useEffect(() => {
    if(reload) {
      pipe(
        AdmintoolService.getT4S15Config(),
        ZIO.map((res) => {
          res.data && setDataTable(res.data
          .map((i, index) => ({
            no: index + 1,
            id: i.id ?? '',
            user: i.staffEmail ?? '',
            team: teamOption && teamOption?.length > 0 ? (teamOption?.find((team) => i.teamNo && team.value === i.teamNo.toString())?.label ?? '') : i.teamNo?.toString() ?? '',
            extensionCode: i.extensionCode && i.extensionCode !== '-' ? i.extensionCode : '',
            blackList: i.blackList ?? '',
            status: i.status ? getTitleStatus(i.status) : ''
          }))
          .sort((p1,p2) => Number(p1.team) > Number(p2.team) ? 1 : (p1.team === p2.team ? p1.user.toLowerCase() > p2.user.toLowerCase() ? 1 : -1 : -1))
          .map((i, index) => ({
            ...i,
            no: index + 1,
          })))
          reload && setReload(false)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [reload])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen' }) },
        { title: t('Setting:AdminTool'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen', params: { tabTitle: 'adminTool' } }) },
        { title: t('Outbound:AdminTool:T4S15'), navigate: null }
      ])
    }

  }, [isFocused])

  const columns = [
    {
      label: t('Outbound:AdminTool:No'),
      field: 'no',
      disabled: true
    },
    {
      label: t('Outbound:AdminTool:User'),
      field: 'user'
    },
    {
      label: t('Outbound:AdminTool:Team'),
      field: 'team'
    },
    {
      label: t('Outbound:AdminTool:ExtensionCode'),
      field: 'extensionCode'
    },
    {
      label: t('Outbound:AdminTool:Blacklist'),
      field: 'blackList',
      render: (value: string) => {
        return <Text>{blackListOption.find((i) => i.value === value)?.label}</Text>
      }
    },
    {
      label: t('Outbound:AdminTool:Status'),
      field: 'status',
      render: (value: string) => {
        return <Text style={getStyleStatus(value)}>{value}</Text>
      }
    },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (value: string) => {
        return (
          roles.includes(Permission.UpdateT4S15OutboundStaffList) ?
            <TouchableOpacity
              onPress={() => {
                formStaffList.reset()
                setOpenEditPopup(true)
                const findData = dataTable.find((i) => i.id === value)
                formStaffList.setValue('id', findData?.id ?? '')
                formStaffList.setValue('user', findData?.user ?? '')
                formStaffList.setValue('team', teamOption?.find((i) => findData && i.label === findData?.team) || null)
                formStaffList.setValue('extensionCode', findData?.extensionCode ?? '')
                formStaffList.setValue('blackList', blackListOption.find((i) => findData && i.value === findData?.blackList) ?? null)
                formStaffList.setValue('status', statusOption.find((i) => findData && i.label === findData?.status) ?? null)
              }}
            >
              <assets.OBPencilGray />
            </TouchableOpacity> :
            <></>
        )
      }
    }
  ]

  const validateExistMail = (mail: string, idValue: string) => {
    const findData = dataTable.find((i) => i.user.toLocaleLowerCase() === mail.toLocaleLowerCase() && i.id !== idValue)
    if (findData) return false
    return true
  }

  const validateExistExtensionCode = (code: string, idValue: string) => {
    const findData = dataTable.find((i) => i.extensionCode === code && i.id !== idValue && i.status === 'Active')
    if (findData) return false
    return true
  }

  const AddButton = () => {
    return (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', paddingBottom: 10 }}>
        <TouchableOpacity
          onPress={() => {
            setOpenAddPopup(true)
            formStaffList.reset()
          }}
        >
          <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight]}>
            <Text style={OBSharedStyles.actionHightLightText}>
              {t('Outbound:AdminTool:AddButton')}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  const TableConfig = () => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <IBGeneralTable
          dataTable={columns}
          data={dataTable}
          autoPaging
          maxHeight={height - height * 0.25}
          maxWidth={width - width * 0.025}
          maxWidthContainer
          filterColumn={filterColumnList}
        >
        </IBGeneralTable>
      </View>
    )
  }

  const FieldEdit = () => {
    return (
      <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <IBGeneralField
          FieldForm={formStaffList}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'user',
              title: t('Outbound:AdminTool:User'),
              inputType: 'input',
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:User') }) },
                validate: (value: string) => {
                  if (!validateMail(value))
                    return t('message:OB0028')
                  if (value && !validateExistMail(value, formStaffList.watch('id'))) {
                    return t('message:IB0020')
                  }
                  return true
                }
              },
              maxLength: 100
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'team',
              title: t('Outbound:AdminTool:Team'),
              option: teamOption ?? [],
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:User') }) }
              }
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'extensionCode',
              title: t('Outbound:AdminTool:ExtensionCode'),
              inputType: 'number',
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:ExtensionCode') }) },
                validate: (value: string) => {
                  if (value && !validateExistExtensionCode(value, formStaffList.watch('id'))) {
                    return t('message:OB0096')
                  }
                  return true
                }
              },
              maxLength: 5
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'blackList',
              title: t('Outbound:AdminTool:Blacklist'),
              option: blackListOption,
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Blacklist') }) }
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'status',
              title: t('Outbound:AdminTool:Status'),
              option: statusOption,
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Status') }) }
              }
            },
          ]}
        >
        </IBGeneralField>
      </View>
    )
  }

  const AddPopup = () => {
    return (
      <ModalComponent
        children={FieldEdit()}
        visible={isOpenAddPopup}
        onClose={() => setOpenAddPopup(false)}
        title={t('Outbound:AdminTool:CreateNew')}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => setOpenAddPopup(false),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35, marginRight: 10 }
          },
          {
            text: t('common:Add'),
            type: 'filled',
            action: () => onAdd(),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35 }
          }
        ]}
        centerTitle
        modalWidth={800}
      />
    )
  }

  const EditPopup = () => {
    return (
      <ModalComponent
        children={FieldEdit()}
        visible={isOpenEditPopup}
        onClose={() => setOpenEditPopup(false)}
        title={t('Outbound:AdminTool:Edit')}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'outline',
            action: () => setOpenEditPopup(false),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35, marginRight: 10 }
          },
          {
            text: t('common:Save'),
            type: 'filled',
            action: () => onSave(),
            disabled: isLoading,
            loading: false,
            onlyWide: false,
            style: { height: 35 }
          }
        ]}
        centerTitle
        modalWidth={800}
      />
    )
  }

  const onSave = formStaffList.handleSubmit((value) => {
    pipe(
      AdmintoolService.updateT4S15Config({
        id: value.id ?? '',
        staffEmail: value.user ?? '',
        teamNo: value.team?.value ? Number(value.team?.value) : undefined,
        extensionCode: value.extensionCode ?? '',
        blackList: value.blackList?.value ?? '',
        status: value.status?.value ?? ''
      }),
      ZIO.map((res) => {
        setReload(true)
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showToast(error.source.message, 'error')
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  const onAdd = formStaffList.handleSubmit((value) => {
    pipe(
      AdmintoolService.addT4S15Config({
        staffEmail: value.user ?? '',
        teamNo: value.team?.value ? Number(value.team?.value) : undefined,
        extensionCode: value.extensionCode ?? '',
        blackList: value.blackList?.value ?? '',
        status: value.status?.value ?? ''
      }),
      ZIO.map((res) => {
        setReload(true)
        setOpenAddPopup(false)
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        showToast(error.source.message, 'error')
        setOpenAddPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  return (
    <View style={styles.container}>
      {
        roles.includes(Permission.ViewT4S15OutboundStaffList) &&
        <>
          {roles.includes(Permission.CreateNewT4S15OutboundStaffList) && AddButton()}
          {TableConfig()}
          {AddPopup()}
          {EditPopup()}
        </>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  addButton: {
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    flexDirection: 'row',
    borderRadius: 8,
  }
})
