import { Task, Throwable, ZIO } from '@mxt/zio'
import * as t from 'io-ts'
import { HttpClient } from '@mxt/zio-http-client'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { pipe } from 'fp-ts/function'
import { AppConfig } from './config'
import * as E from 'fp-ts/Either'

const TOKEN_KEY = 'LANDING_PAGE_TOKEN'

export namespace LDApi {
  const getToken = (): string => localStorage.getItem(TOKEN_KEY) || ''
  const setToken = (token: string): void => localStorage.setItem(TOKEN_KEY, token)

  const buildUrl = (api: string): Task<string> =>
    pipe(
      AppConfig.get,
      ZIO.map((cf) => {
        let aplUrl = api
        const currVersion = cf.version.split('.').join('-') || '1-0-0'
        const validPrefix = [
          'wf-api',
          'pulseops',
          'digitalclaim',
          'pvadigital',
          'business-pulse',
          'authentication',
          'customer',
          'zalo',
          'ocr-rest',
          'add-bank-account',
          'landing-page'
        ]
        const prefix = validPrefix.find((s) => aplUrl.startsWith(s))
        if (prefix) {
          aplUrl = aplUrl.replace(prefix, `${prefix}/${currVersion}`)
        }
        return `${cf.apiUrl}/${aplUrl}`
      })
    )

  export const isAuthenticated = !!getToken()

  export const get =
    (url: string, config?: AxiosRequestConfig) =>
    <C extends t.Mixed>(response: C) =>
      pipe(
        buildUrl(url),
        ZIO.flatMap((hostName) =>
          HttpClient.get({
            ...config,
            headers: {
              Authorization: `Bearer ${getToken()}`,
              'X-Authen-Vendor': 'GUEST',
              ...config?.headers
            }
          })(hostName, response)
        )
      )

  export const post =
    <A>(url: string, config?: AxiosRequestConfig) =>
    <C extends t.Mixed>(response: C) =>
    (body: A) =>
      pipe(
        buildUrl(url),
        ZIO.flatMap((hostName) =>
          HttpClient.post({
            ...config,
            headers: {
              Authorization: `Bearer ${getToken()}`,
              'X-Authen-Vendor': 'GUEST',
              ...config?.headers
            }
          })(hostName)(response)(body)
        )
      )

  export const login = (url: string, params: Record<string, string>) =>
    pipe(
      buildUrl(url),
      ZIO.flatMap((hostName) =>
        HttpClient.get({ params })(
          hostName,
          t.type({
            access_token: t.string
          })
        )
      ),
      ZIO.map((_) => setToken(_.access_token))
    )

  type ResponseError = (e: AxiosError) => Throwable
  const errRes = t.type({
    responseStatus: t.type({
      code: t.number,
      errors: t.array(
        t.type({
          code: t.string,
          message: t.string
        })
      ),
      message: t.string
    })
  })
  export const baseError: ResponseError = (e: AxiosError) =>
    pipe(
      errRes.decode(e.response?.data),
      E.fold(
        (e) => Throwable(e),
        (a) => Throwable(`${a.responseStatus.errors[0].code} - ${a.responseStatus.errors[0].message}`)
      )
    )
  export const headerError: ResponseError = (e: AxiosError) =>
    pipe(
      t
        .type({
          header: errRes
        })
        .decode(e.response?.data),
      E.fold(
        (e) => Throwable(e),
        (a) => Throwable(`${a.header.responseStatus.errors[0].code} - ${a.header.responseStatus.errors[0].message}`)
      )
    )

  export const postError =
    (config?: AxiosRequestConfig) =>
    <Body>(url: string) =>
    <C extends t.Mixed>(response: C, errMapper: ResponseError = baseError) =>
    (body?: Body) =>
      pipe(
        buildUrl(url),
        ZIO.flatMap(([token, loginType, hostName]) =>
          pipe(
            HttpClient.postAxios({
              ...config,
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })(hostName)(body),
            ZIO.mapError((e) => errMapper(e)),
            ZIO.flatMap((a) =>
              pipe(
                response.decode(a.data),
                E.mapLeft((e) => Throwable(e)),
                E.map((a) => a),
                ZIO.fromEither
              )
            )
          )
        )
      )

  export const Postlogin =
    <A>(url: string, config?: AxiosRequestConfig) =>
    <C extends t.Mixed>(response: C) =>
    (body: A) =>
      pipe(
        buildUrl(url),
        ZIO.flatMap((hostName) =>
          HttpClient.post({
            ...config,
            headers: {
              'X-Authen-Vendor': 'GUEST',
              ...config?.headers
            }
          })(hostName)(response)(body)
        ),
        ZIO.map((res) => {
          if(res?.credential) {
            setToken(res.credential.access_token)
          }
          return res
        })
      )
}
