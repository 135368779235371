import * as t from 'io-ts'
import { PageParameter, PageResult } from '@pulseops/common'
import { Maybe } from '@mxt/zio/codec'
import { DateFromISOString } from 'io-ts-types'
import { pipe } from 'fp-ts/lib/function'
import * as A from 'fp-ts/Array'

import { TaskSource } from '../../TaskSource'
import { TransactionType, TransactionTypeC } from '../../TransactionType'
import { SubTaskCode, SubTaskCodeFromString } from '../../TaskType'
import { QueryTask } from '../../QueryTask'
import { TransactionStatus } from '../../TransactionStatus'
import { AMLResult } from '../../AMLResult'

export namespace ErrorActivity {
  export const getAMLResult = (task: ErrorActivity): AMLResult => {
    switch (task.amlResult) {
      case 'HIT':
        return AMLResult.HIT
      case 'NO_HIT':
        return AMLResult.NO_HIT
      case 'ERROR':
        return AMLResult.ERROR
      case 'WARNING':
        return AMLResult.WARNING
      case 'HIT_ACCEPT':
        return AMLResult.HIT_ACCEPT
      case 'HIT_DECLINE':
        return AMLResult.HIT_DECLINE
      default:
        return AMLResult.NOTCONNECT
    }
  }

  export interface TaskOp {
    id: string | null
    category: string
    caseId: string
    policyNumber: string
    clientNumber: string
    source: string
    transactionType: TransactionType | null
    assignee: string
    assigneeName: string
    agingDate: string | null
    status: TransactionStatus | null
    processInstanceId: string | null
    updateToCore: boolean
    createdDate: Date | null
    createdBy: string | null
    lastUpdateDate: Date | null
    subTransactionType: string
    poName: string
    claimNumber: string
    request: string
    amlResult: AMLResult | null
    redFlagResult: boolean | null
    paymentMethod: string | null
    proposalNumber: string | null
    eref: string
    stuckTime: Date
    currentActivity: string
    categoryError: string
    nameError: string
  }

  export type InputData = {
    pagination: PageParameter
    filter: QueryTask.Filter
    taskCategory?: SubTaskCode
    category?: string
    activities?: string[]
  }

  export type PostDataType = {
    sourceIn?: TaskSource[]
    transactionTypeIn?: TransactionType[]
    policyNumber?: string
    createdAfter?: Date
    createdBefore?: Date
    start: number
    size: number
    taskCategory?: string
    activities?: string[]
    offices?: string[]
    category?: string
  }

  export const PostData = ({
    pagination,
    filter,
    taskCategory,
    category,
    activities
  }: InputData): PostDataType => ({
    size: pagination.pageSize,
    start: pagination.pageIndex,
    sourceIn: filter.source,
    transactionTypeIn: filter.transactionType,
    policyNumber: filter.policyNum,
    createdAfter: filter.fromDate,
    createdBefore: filter.toDate,
    taskCategory: taskCategory && SubTaskCodeFromString.encode(taskCategory),
    offices: filter.offices,
    activities: activities,
    category: category
  })

  export const codec = t.type({
    id: Maybe(t.string),
    name: Maybe(t.string),
    businessKey: Maybe(t.string),
    correlationId: Maybe(t.string),
    source: Maybe(t.string),
    vip: Maybe(t.string),
    category: Maybe(t.string),
    transactionType: Maybe(t.string),
    payload: Maybe(t.string),
    coreResponseDetail: Maybe(t.string),
    updateToCoreConfig: Maybe(t.boolean),
    updateToCore: Maybe(t.boolean),
    processInstanceId: Maybe(t.string),
    parentProcessInstanceId: Maybe(t.string),
    attachmentURLs: Maybe(t.string),
    policyNumber: Maybe(t.string),
    clientNumber: Maybe(t.string),
    claimNumber: Maybe(t.string),
    createdBy: Maybe(t.string),
    amlResult: Maybe(
      t.union([
        t.literal('HIT'),
        t.literal('NO_HIT'),
        t.literal('ERROR'),
        t.literal('WARNING'),
        t.literal('HIT_ACCEPT'),
        t.literal('HIT_DECLINE'),
        t.literal('NOTCONNECT')
      ])
    ),
    paymentNumber: Maybe(t.string),
    poName: Maybe(t.string),
    lastUpdateDate: Maybe(DateFromISOString),
    lastVerUpdate: Maybe(t.string),
    lastUpdateByVer: Maybe(t.string),
    subTransactionCode: Maybe(t.string),
    subTransactionType: Maybe(t.string),
    agingDate: Maybe(t.number),
    redFlagResult: Maybe(t.boolean),
    pendingNum: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    paymentMethod: Maybe(t.string),
    amount: Maybe(t.string),
    bankCode: Maybe(t.string),
    paymentReason: Maybe(t.string),
    remark: Maybe(t.string),
    uwDecision: Maybe(t.string),
    lastActivity: Maybe(t.string),
    suspended: Maybe(t.boolean),
    ended: Maybe(t.boolean),
    startTime: Maybe(DateFromISOString),
    completed: Maybe(t.boolean),
    newType: Maybe(t.boolean),
    newSubType: Maybe(t.string),
    request: Maybe(t.string),
    currentActivityName: Maybe(t.string),
    createdDate: Maybe(DateFromISOString),
    assignee: Maybe(t.string),
    assigneeName: Maybe(t.string),
    agentCode: Maybe(t.string),
    agentPhoneNumber: Maybe(t.string),
    agentLastName: Maybe(t.string),
    agentFirstName: Maybe(t.string),
    officeCode: Maybe(t.string),
    eref: Maybe(t.string),
    is_skip_uw_check: Maybe(t.string),
    is_pass_verification: Maybe(t.string),
    is_skip_authority: Maybe(t.string),
    categoryError: Maybe(t.string),
    nameError: Maybe(t.string)
  })

  export const Response = t.type({
    data: t.union([t.array(ErrorActivity.codec), t.null]),
    total: t.number
  })

  export type Response = t.TypeOf<typeof Response>

  export const TaskOp = (task: ErrorActivity, status: TransactionStatus | null): TaskOp => ({
    id: task.id || null,
    category: task.category || '-',
    caseId: task.businessKey || '-',
    policyNumber: task.policyNumber && task.policyNumber !== 'null' ? task.policyNumber : '-',
    clientNumber: task.clientNumber && task.clientNumber !== 'null' ? task.clientNumber : '-',
    source: task.source || '-',
    transactionType: TransactionTypeC.is(task.transactionType) ? task.transactionType : null,
    assignee: task.assignee || '-',
    assigneeName: task.assigneeName || '-',
    status: status || null,
    agingDate: task.agingDate?.toString() || null,
    processInstanceId: task.processInstanceId || '-',
    updateToCore: task.updateToCore || false,
    createdDate: task.createdDate || null,
    createdBy: task.createdBy || '-',
    lastUpdateDate: task.lastUpdateDate || null,
    subTransactionType: task.subTransactionType || '',
    poName: task.poName || '',
    claimNumber: task.claimNumber || '',
    request: task.transactionType === TransactionType.COMMON_REQUEST_FORM && task.request ? task.request : '',
    amlResult: task.amlResult ? getAMLResult(task) : null,
    redFlagResult: task.redFlagResult === null || task.redFlagResult === undefined ? null : task.redFlagResult,
    paymentMethod: task.paymentMethod || '',
    proposalNumber: task.proposalNumber ?? null,
    eref: task.eref || '',
    stuckTime: task.startTime ?? (task.startTime ? new Date(task.startTime) : new Date()),
    currentActivity: task?.currentActivityName || '-',
    categoryError: task?.categoryError || '-',
    nameError: task?.nameError || '-'
  })

  export const toPageResult =
    (pagination: PageParameter, status: TransactionStatus | null) =>
    (result: Response): PageResult<TaskOp> =>
      PageResult({
        items: pipe(
          result.data || [],
          A.map((task) => TaskOp(task, status))
        ),
        total: result.total,
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      })
}

export type ErrorActivity = t.TypeOf<typeof ErrorActivity.codec>
