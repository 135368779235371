import React, { PropsWithChildren } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import { FieldList, sharedStyle, TabList, useMobile, TransactionType } from '@pulseops/common'
import { RequestAuthen, RequestAuthenticateData } from './request-authen'
import { UseFormReturn } from 'react-hook-form'
export interface TransactionWrapper {
  tab: {
    isShowed: boolean
    current: number
    isValidated: boolean
    setTab: (val: number) => void
  }
  transactionInfos: FieldList.DataSource[]
  requestAuthenData: RequestAuthenticateData.EformRequestAuthenticateData
  authenForm: UseFormReturn<RequestAuthenticateData.RequestAuthFormValue>
  updateSendDate: (sendDate: string) => void
  updatedocReviewed: (docReview: boolean) => void
  updateRequestAuthenData: (data: RequestAuthenticateData.EformRequestAuthenticateData) => void
  isByPassAuthPaper: boolean
  officeCode: string
  transactionType: string
  noAuthen?: boolean
}

export const TransactionWrapper = (props: PropsWithChildren<TransactionWrapper>) => {
  const { t } = useTranslation()

  const menus = [
    {
      id: 1,
      title: t('submission:Request')
    },
    {
      id: 2,
      title: t('submission:Confirm')
    }
  ]

  const onChangeTab = (index: number) => {
    if (props.tab.isValidated) {
      props.tab.setTab(index)
    }
  }

  const isHideOPTTab = () => {
    return [TransactionType.E_SERVICE_REGISTRATION, TransactionType.INHERITANCE, TransactionType.ASSIGNMENT].includes(
      props.transactionType as TransactionType
    )
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ marginHorizontal: 15 }}>
        {props.tab.isShowed && (
          <TabList
            menus={menus}
            isSubTab={false}
            tabIndex={props.tab.current}
            onChangeTab={onChangeTab}
            predicateDisable={(index) => index === 1}
          />
        )}
      </View>
      <View style={{ backgroundColor: '#FFF', paddingHorizontal: 20, paddingVertical: 15 }}>
        {props.transactionType !== TransactionType.JOURNAL_ALTERATION &&
          props.transactionType !== TransactionType.JOURNAL_PAYOUT && (
            <View>
              <Text style={sharedStyle.sectionTitle}>{t('submission:requestType')}</Text>
              <View
                style={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#d3dce6',
                  padding: 20,
                  marginTop: 15
                }}
              >
                <RequestInfoPanel dataSource={props.transactionInfos}></RequestInfoPanel>
              </View>
            </View>
          )}
        {/* <View style={{ marginTop: 10 }}>
          <Text style={{ color: '#ED1B2C', fontSize: 16 }}>
            {t('message:MS030041')}
          </Text>
        </View> */}

        {props.children}
        {props.tab.current === 1 && !props.noAuthen && (
          <View>
            <RequestAuthen
              hideOTP={isHideOPTTab() || (props.transactionType === TransactionType.CHANGE_CONTACT_INFO && props.requestAuthenData.isHideOTP)}
              requestAuthenData={props.requestAuthenData}
              authenForm={props.authenForm}
              updateSendDate={props.updateSendDate}
              updatedocReviewed={props.updatedocReviewed}
              updateRequestAuthenData={props.updateRequestAuthenData}
              isByPassAuthPaper={props.isByPassAuthPaper}
              officeCode={props.officeCode}
              transactionType={props.transactionType}
              isHiddenRequestForm={props.transactionType === TransactionType.CHANGE_CONTACT_INFO && props.requestAuthenData.isHideRequestInfo}
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  )
}

 export const RequestInfoPanel: React.FC<{ dataSource: FieldList.DataSource[] }> = ({ dataSource }) => {
  const { isWide } = useMobile()
  const Item = ({ data }: { data: FieldList.DataSource }) => {
    return (
      <View
        style={{
          // flex: isWide ? 1 : undefined,
          // width: isWide ? undefined : '100%',
          paddingBottom: isWide ? undefined : 16,
          // minWidth: 200
          width: '100%',
          maxWidth: '33.3333333333%',
          paddingRight: 15,
          paddingLeft: 15
        }}
      >
        <Text style={{ fontWeight: 'bold', fontSize: 15, color: '#70777E' }}>{data.label}</Text>
        <Text style={{ fontSize: 15, marginTop: 4 }}>{data.value}</Text>
      </View>
    )
  }
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -15,
        marginLeft: -15
      }}
    >
      {dataSource.map((data, index) => {
        return <Item key={index} data={data} />
      })}
    </View>
  )
}
