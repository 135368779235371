import React from 'react'
import { Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text, Pressable, TouchableOpacity, TextInput } from 'react-native'
import {
  Alert,
  assets,
  Input,
  PulseOpsFormat,
  SelectOption,
  SelectTable,
  TaskDetail,
  Select
} from '../../../../../common/src'
import { PCReason } from '../mock'
import { CashLessForm, CashLessFormBase, defaultSelect } from './cash-less-form'
import { ContainerView, styles, useStyles } from './styles'
import _ from 'lodash'
import { getJournalCodeOptions, getJournalTypeOptions } from './helper'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { SelectTableOption } from '../common'

export const Journals = (props: {
  pendingCode: SelectOption[]
  form: CashLessFormBase
  disable?: boolean
  detail: TaskDetail.VeriCashLess
  canAction: boolean
}) => {
  const { form, detail, canAction } = props
  const { control } = form
  const { t: ca } = useTranslation('Cashless')
  const aboutSelect = React.useMemo(() => [defaultSelect, ...PCReason.getActionJournal()], [])
  const { fields, append } = useFieldArray({
    control,
    name: 'journal'
  })

  const showTitle = React.useMemo(() => {
    return canAction || fields.filter((f) => !f.receiptNo).length > 0
  }, [fields])

  const errors = form.formState

  const receipts = useWatch({ control: form.control, name: 'receips' })

  const nonCash = useWatch({ control: form.control, name: 'nonCash' })

  //   const validCheckCanAction = React.useMemo(() => {
  //     // kiem tra xem co bat ki receipt nao hop le hay khong moi enable journal
  //     return (
  //       (receipts ?? []).some(
  //         (r) => !!r.about?.value && r.info.some((i) => !!i.sacsCode?.value && !!i.sacsType?.value)
  //       ) && canAction
  //     )
  //   }, [receipts])

  const totalAmountLpsTransaction = React.useCallback((transaction: CashLessForm.RawTransaction[] = []) => {
    return transaction.reduce<number>(
      (p, c) =>
        p +
        (c.sacsCode?.value === 'LP' && c.sacsType?.value === 'S'
          ? PulseOpsFormat.thousandSepartorReverse(c.amount ?? '0')
          : 0),
      0
    )
  }, [])

  const totalReceiptLps = React.useMemo(() => {
    // only calculate when receipts change
    const totalReciptsLps = (receipts ?? []).reduce<number>((pre, cur) => {
      return pre + totalAmountLpsTransaction(cur.info ?? [])
    }, 0)
    return totalReciptsLps
  }, [receipts])

  const totalReceiptsLpu = React.useMemo(() => {
    const totalReceiptLpu = (receipts ?? []).reduce<number>(
      (pre, cur) =>
        pre +
        (cur.info ?? []).reduce<number>(
          (p, c) =>
            p +
            (c.sacsCode?.value === 'LP' && c.sacsType?.value === 'U'
              ? PulseOpsFormat.thousandSepartorReverse(c.amount ?? '0')
              : 0),
          0
        ),
      0
    )

    return totalReceiptLpu
  }, [receipts])

  const totalNonCashLps = React.useMemo(() => {
    const totalNoncashLps = totalAmountLpsTransaction(nonCash?.info ?? [])
    return totalNoncashLps
  }, [nonCash])

  const lpsAmountRemaining = React.useMemo(() => {
    return totalReceiptLps + totalNonCashLps + detail.LPSAmount
  }, [totalReceiptLps, totalNonCashLps])

  const lpuAmountRemaining = React.useMemo(() => {
    return totalReceiptsLpu + detail.LPUAmount
  }, [totalReceiptsLpu])

  const getTotalAmount = (index: number) =>
    (form.watch(`journal.${index}.info`) ?? []).reduce<number>((pre, cur) => {
      return pre + Number(PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'))
    }, 0) ?? 0
  //   const defaultFrom: CashLessForm.RawJournalFrom = React.useMemo(() => {
  //     return {
  //       sacsCode: null,
  //       sacsType: null,
  //       amount: '',
  //       allowDetele: true,
  //       allowEdit: true,
  //       sacsTypeSelection: [],
  //       action: '',
  //       lnvpAmount: detail.LNVPAmount,
  //       lpe1Amount: detail.LPE1Amount,
  //       //lpsAmount: detail.LPSAmount,
  //       lpsAmount: lpsAmountRemaining,
  //       lpuAmount: lpuAmountRemaining
  //     }
  //   }, [lpsAmountRemaining])

  return (
    <View
    // pointerEvents={!validCheckCanAction ? 'none' : undefined}
    >
      {showTitle && <Text style={styles.sessonText}>JOURNAL</Text>}
      {fields.map((v, index) => {
        const error = _.get(errors, `journal.${index}.message`)
        const canTouch = canAction && !v.receiptNo
        if (!!v.receiptNo) {
          return <View />
        }

        return (
          <ContainerView>
            <View style={styles.row}>
              <View style={styles.selectContainer}>
                <Controller
                  control={control}
                  name={`journal.${index}.about`}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      onChange={(v) => {
                        onChange(v)
                        const currentJournal = form.getValues(`journal.${index}`)
                        form.setValue(`journal.${index}`, {
                          ...currentJournal,
                          about: v,
                          //from: { ...defaultFrom, action: v?.value ?? '' },
                          info: []
                        })
                      }}
                      options={aboutSelect}
                      label={ca('chooseAction')}
                    />
                  )}
                />
              </View>
              <View style={styles.selectContainer}>
                <View style={{ borderBottomWidth: 1, borderBottomColor: '#D3DCE6' }}>
                  <Input
                    title={ca('amount')}
                    value={PulseOpsFormat.thousandSepartor(getTotalAmount(index)) + ' VND'}
                    disabled={true}
                    inputStyle={{
                      fontWeight: getTotalAmount(index) === 0 ? 'normal' : 'bold'
                    }}
                  />
                </View>
                {/* <Controller
                  control={form.control}
                  name={`journal.${index}`}
                  render={({ field: { value } }) => {
                    const totalAmount =
                      (value?.info ?? []).reduce<number>((pre, cur) => {
                        return pre + Number(PulseOpsFormat.thousandSepartorReverse(cur.amount ?? '0'))
                      }, 0) ?? 0
                    return (
                     
                    )
                  }}
                /> */}
              </View>
              <View style={styles.selectContainer} />
            </View>
            <JournalTable
              form={form}
              tableIndex={index}
              detail={detail}
              canAction={canAction}
              lpsRemainingAmount={lpsAmountRemaining}
              lpuRemainingAmount={lpuAmountRemaining}
            />
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

            {canTouch && ( // chỉ cho xoá khi đang trong màn hình Ver hoặc không tồn tại ReceipNo trong Receip (có receiptNo nghĩa là đã tồn tại trong core)
              <View style={{ position: 'absolute', top: 7, right: 7 }}>
                <Pressable
                  onPress={() =>
                    Alert.modal({
                      title: ca('warning'),
                      size: {
                        width: '30%'
                      },
                      content: ca('warningDeleteReceipt'),
                      onOK: () => {
                        const journals = form.getValues(`journal`) ?? []
                        form.setValue(`journal`, [
                          ...journals.slice(0, index),
                          ...journals.slice(index + 1, journals.length)
                        ])
                      }
                    })
                  }
                >
                  <assets.IconXRed />
                </Pressable>
              </View>
            )}
          </ContainerView>
        )
      })}

      {canAction && (
        <TouchableOpacity
          style={[styles.Button, { alignSelf: 'flex-end', marginEnd: 40, marginBottom: 25 }]}
          onPress={() =>
            append({
              about: defaultSelect,
              pendingCode: null,
              receiptNo: null,
              transactionCode: null,
              info: [],
              lnvpAmount: detail.LNVPAmount,
              lpe1Amount: detail.LPE1Amount,
              lpuAmount: lpuAmountRemaining,
              lpsAmount: lpsAmountRemaining
            })
          }
        >
          <Text style={[styles.boldText, { fontSize: 12, color: '#fff' }]}>{ca('addJournal')}</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

const JournalTable = (props: {
  form: CashLessFormBase
  tableIndex: number
  detail: TaskDetail.VeriCashLess
  canAction: boolean
  lpsRemainingAmount: number
  lpuRemainingAmount: number
}) => {
  const { form, tableIndex, canAction, detail, lpsRemainingAmount, lpuRemainingAmount } = props

  const classes = useStyles()

  const {
    t: ca,
    i18n: { language }
  } = useTranslation('Cashless')
  //const { append } = useFieldArray({ control: form.control, name: `journal.${tableIndex}.info` })
  const payinMethod = useWatch({ control: form.control, name: `journal.${tableIndex}.about.value` })

  const receiptNo = form.getValues(`journal.${tableIndex}.receiptNo`)

  const showButton = canAction && !receiptNo

  const { errors } = useFormState({ control: form.control })

  //   const checkJournal = React.useMemo(() => {
  //     return checkJournalAmount({
  //       sacCode: 'A',
  //       sacType: 'PC',
  //       lpe1: detail.LPE1Amount,
  //       lnvp: detail.LNVPAmount,
  //       lps: lpsRemainingAmount,
  //       lpu: lpuRemainingAmount,
  //       amount: '0' ?? '0'
  //     })
  //   }, [data])

  const journalCodeOptions = React.useMemo(() => {
    switch (payinMethod) {
      case 'MSP_R_RPJ':
        return getJournalCodeOptions(language)
      case 'MSP_C_RTA':
        return getJournalCodeOptions(language).filter((code) => ['LP'].includes(code.value))
      case 'MSP_R_RPL':
        return getJournalCodeOptions(language)
      default:
        return getJournalCodeOptions(language)
    }
  }, [language, payinMethod])

  const journalTypeOptions = React.useMemo(() => {
    switch (payinMethod) {
      case 'MSP_R_RPJ':
        return getJournalTypeOptions(language).filter((type) => ['S', 'VP', 'E1'].includes(type.value))
      case 'MSP_C_RTA':
        return getJournalTypeOptions(language).filter((type) => ['S', 'U', 'E1'].includes(type.value))
      case 'MSP_R_RPL':
        return getJournalTypeOptions(language).filter((type) => ['S', 'AO', 'AI', 'LO', 'LI'].includes(type.value))
      default:
        return getJournalTypeOptions(language)
    }
  }, [language, payinMethod])

  React.useEffect(() => {
    form.setValue(`journal.${tableIndex}`, {
      ...form.getValues(`journal.${tableIndex}`),
      lnvpAmount: detail.LNVPAmount,
      lpe1Amount: detail.LPE1Amount,
      lpsAmount: lpsRemainingAmount,
      lpuAmount: lpuRemainingAmount
    })
  }, [detail, lpsRemainingAmount, lpuRemainingAmount])

  const journalTypeOptionsBySacCode = (index: number) => {
    switch (payinMethod) {
      case 'MSP_R_RPJ':
        switch (form.getValues(`journal.${tableIndex}.info.${index}.sacsCode.value`)) {
          case 'LP':
            return journalTypeOptions.filter((type) => ['S', 'E1'].includes(type.value))
          case 'LN':
            return journalTypeOptions.filter((type) => ['VP'].includes(type.value))
        }
      case 'MSP_C_RTA':
        switch (form.getValues(`journal.${tableIndex}.info.${index}.sacsCode.value`)) {
          case 'LP':
            return journalTypeOptions.filter((type) => ['S', 'U', 'E1'].includes(type.value))
        }
      case 'MSP_R_RPL':
        switch (form.getValues(`journal.${tableIndex}.info.${index}.sacsCode.value`)) {
          case 'LP':
            return journalTypeOptions.filter((type) => ['S'].includes(type.value))
          case 'LN':
            return journalTypeOptions.filter((type) => ['AO', 'AI', 'LO', 'LI'].includes(type.value))
        }
      default:
        return journalTypeOptions
    }
  }
  //console.log('Current Data', data)
  return (
    <View>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableCell className={classes.stickyLeft} width={'33%'}>
              <Text style={styles.titleColumn}>{ca('sacCode')}</Text>
            </TableCell>
            <TableCell className={classes.stickyLeft} width={'33%'}>
              <Text style={styles.titleColumn}>{ca('sacType')}</Text>
            </TableCell>
            <TableCell className={classes.stickyLeft} width={'33%'}>
              <Text style={styles.titleColumn}>{ca('amount')}</Text>
            </TableCell>
          </TableHead>
          <TableBody>
            {useWatch({ control: form.control, name: `journal.${tableIndex}.info` }).map((receipt, index) => {
              return (
                <TableRow key={`__list__journal_${receipt?.sacsCode?.value}_${index}`}>
                  <Controller
                    control={form.control}
                    name={`journal.${tableIndex}.info.${index}.sacsCode`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TableCell className={classes.cell}>
                          <SelectTable
                            id={`${tableIndex}${index}`}
                            options={journalCodeOptions}
                            value={value ?? { value: '', label: '' }}
                            onChange={(v) => {
                              const currentInfo = form.getValues(`journal.${tableIndex}.info.${index}`)
                              onChange(v)
                              form.setValue(`journal.${tableIndex}.info.${index}`, {
                                ...currentInfo,
                                sacsCode: v,
                                amount: '',
                                sacsTypeSelection: [],
                                allowEdit: true,
                                allowDetele: true
                              })
                            }}
                            disabled={!payinMethod}
                          />
                        </TableCell>
                      )
                    }}
                  />
                  <Controller
                    control={form.control}
                    name={`journal.${tableIndex}.info.${index}.sacsType`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TableCell className={classes.cell}>
                          <SelectTable
                            id={`${tableIndex}${index}${value?.value}`}
                            key={`${tableIndex}${index}${value?.value}`}
                            options={journalTypeOptionsBySacCode(index)}
                            value={value}
                            onChange={(v) => {
                              const currentInfo = form.getValues(`journal.${tableIndex}.info.${index}`)
                              onChange(v)
                              const ruleInsPremium = currentInfo.sacsCode?.value === 'LN' && v?.value === 'VP'
                              form.setValue(`journal.${tableIndex}.info.${index}`, {
                                ...currentInfo,
                                amount: ruleInsPremium
                                  ? PulseOpsFormat.addThousandSeparator(String(detail.InsPremium))
                                  : ''
                              })
                            }}
                          />
                        </TableCell>
                      )
                    }}
                  />
                  <Controller
                    control={form.control}
                    name={`journal.${tableIndex}.info.${index}.amount`}
                    render={({ field: { value, onChange } }) => {
                      const allowEdit = form.watch(`journal.${tableIndex}.info.${index}.allowEdit`)

                      return (
                        <TableCell className={classes.cell}>
                          <View style={styles.inputContainer}>
                            <TextInput
                              key={`key_state${index}`}
                              onChangeText={(value) => {
                                form.setValue(
                                  `journal.${tableIndex}.info.${index}.amount`,
                                  PulseOpsFormat.addThousandSeparator(value)
                                )
                              }}
                              value={value ?? ''}
                              style={styles.input}
                              maxLength={15}
                              editable={allowEdit ?? undefined}
                            />

                            {showButton && (
                              <Pressable
                                onPress={() => {
                                  Alert.modal({
                                    title: ca('warning'),
                                    size: {
                                      width: '30%'
                                    },
                                    content: ca('warningDeleteLine'),
                                    onOK: () => {
                                      const infos = form.getValues(`journal.${tableIndex}.info`)
                                      form.setValue(`journal.${tableIndex}.info`, [
                                        ...infos.slice(0, index),
                                        ...infos.slice(index + 1)
                                      ])
                                    }
                                  })
                                }}
                                //disabled={!allowDetele}
                              >
                                <View style={{ opacity: 1.0 }}>
                                  <assets.DeleteIcon />
                                </View>
                              </Pressable>
                            )}
                          </View>
                        </TableCell>
                      )
                    }}
                  />
                </TableRow>
              )
            })}

            {showButton && (
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>
                  <View style={{ flex: 1, justifyContent: 'center' }}>
                    <TouchableOpacity
                      onPress={() => {
                        // if (!form.getValues(`journal.${tableIndex}.from`)) {
                        //   const reason = form.getValues(`journal.${tableIndex}.about`)
                        //   form.setValue(`journal.${tableIndex}.from`, {
                        //     sacsType: null,
                        //     sacsCode: null,
                        //     amount: null,
                        //     sacsTypeSelection: [],
                        //     allowDetele: true,
                        //     allowEdit: true,
                        //     lpe1Amount: detail.LPE1Amount,
                        //     lpsAmount: detail.LPSAmount,
                        //     lnvpAmount: detail.LNVPAmount,
                        //     lpuAmount: detail.LPUAmount,
                        //     action: reason?.value ?? ''
                        //   })
                        // } else {
                        //   const currentInfo = form.getValues(`journal.${tableIndex}.info`) ?? []
                        //   form.setValue(`journal.${tableIndex}.info`, [
                        //     ...currentInfo,
                        //     {
                        //       sacsType: null,
                        //       sacsCode: null,
                        //       amount: null,
                        //       sacsTypeSelection: [],
                        //       allowDetele: true,
                        //       allowEdit: true
                        //     }
                        //   ])
                        // }
                        const currentInfo = form.getValues(`journal.${tableIndex}.info`) ?? []
                        form.setValue(`journal.${tableIndex}.info`, [
                          ...currentInfo,
                          {
                            sacsType: null,
                            sacsCode: null,
                            amount: null,
                            sacsTypeSelection: [],
                            allowDetele: true,
                            allowEdit: true
                          }
                        ])
                      }}
                      style={[styles.Button, { alignSelf: 'flex-end', backgroundColor: '#fff' }]}
                    >
                      <Text style={[styles.boldText, { color: '#ED1B2E', fontSize: 12 }]}>{ca('addRow')}</Text>
                    </TouchableOpacity>
                  </View>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {!checkJournal.valid && <Text style={{ color: 'red', marginTop: 15 }}>{ca(checkJournal.message)}</Text>} */}
      <Text style={{ color: 'red', marginTop: 15 }}>{_.get(errors, `journal.${tableIndex}.message`)}</Text>
    </View>
  )
}
