import DateFnsUtils from '@date-io/date-fns'
import { InputStyle } from './input'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as React from 'react'
import { ControlProps, theme } from '@pulseops/common'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import * as core from '@material-ui/core'
import i18next from 'i18next'
import { assets } from '../assets'
import { FormControl } from './styled'

export type DatePickerProps = ControlProps<Date | null> & {
  label?: string
  alwaysShow?: boolean
  required?: boolean
  disabled?: boolean
  minDate?: ParsableDate
  maxDate?: ParsableDate
  maxDateMessage?: string
  placeholder?: string
}

export const DatePicker = (props: DatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl error={!!props.errorMessage}>
        <SC.KeyboardDatePicker
          clearable
          label={props.label}
          disabled={props.disabled}
          value={props.value || null}
          keyboardIcon={props.errorMessage ? <assets.IconCalendar /> : <assets.IconCalendarGray />}
          onChange={(date) => {
            if (props.onChange) {
              props.onChange(date)
            }
          }}
          onBlur={props.onBlur}
          placeholder={props.placeholder || 'dd/mm/yyyy'}
          format="dd/MM/yyyy"
          minDate={props.minDate}
          maxDate={props.maxDate}
          maxDateMessage={props.errorMessage ? '' : props.maxDateMessage}
          invalidDateMessage={props.errorMessage ? '' : i18next.t('form:error_invalid')}
        />
        {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

const SC = {
  KeyboardDatePicker: core.withStyles({
    root: {
      '& .MuiFormLabel-root': {
        ...InputStyle.labelStyle,
        position: 'relative',
        top: 'unset',
        left: 'unset',
        transform: 'unset',
        lineHeight: 'normal',
        whiteSpace: 'nowrap'
      },
      '& .MuiInputBase-root': {
        fontSize: 13,
        fontFamily: theme.fonts.main,
        margin: 0,
        borderBottom: '1px solid ' + InputStyle.borderColor
      },
      '& .MuiInputBase-input': {
        minHeight: InputStyle.minHeight,
        padding: 0
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'unset'
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'unset'
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'unset'
      }
    }
  })(KeyboardDatePicker)
}
