import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import { withMinStringLength, withMaxStringLength, withStringPattern } from '@pulseops/submission/common'
export namespace PolicyOfOrtherClientForm {
  const POCform = t.type({
    policyNumber: pipe(
      form2.string.required,
      withStringPattern('^[0-9]*$', 'message:MS070018'),
      withMinStringLength(8),
      withMaxStringLength(8)
    ),
    contractStatus: form2.string.required,
    poName: form2.string.required,
    amount: form2.number.required,
    totalPremium: form2.number.required
  })

  export const codec = t.type({
    list: t.array(POCform)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
