export namespace ExportTask {
  export type ExportCert = {
    fromDate: string
    toDate: string
    ciNumber: string
    idNumber: string
  }
  export type SearchCert = {
    fromDate: string
    toDate: string
    ciNumber: string
    idNumber: string
    maxRecordPerPage: number
    pageToGet: number
    propertySort: string
    directionSort: string
  }
}
