import { SelectOption, PayoutPopup } from '@pulseops/common'
import { RepayLoanConst } from './repay-loan.constant'
import i18next from 'i18next'

export const getRepayForOption = (apl: number, opl: number) => {
  const options: SelectOption[] = []
  if (apl > 0) {
    options.push({
      value: RepayLoanConst.RepayFor.REPAYAPL,
      label: i18next.t('submission:Repay_APL')
    })
  }

  if (opl > 0) {
    options.push({
      value: RepayLoanConst.RepayFor.REPAYOPL,
      label: i18next.t('submission:Repay_OPL')
    })
  }

  if (apl > 0 && opl > 0) {
    options.push({
      value: RepayLoanConst.RepayFor.REPAY_OPL_APL,
      label: i18next.t('submission:Repay_APL_&_OPL')
    })
  }

  return options
}

export const getPayoutMethod = (repayFor: string) => {
  switch (repayFor) {
    case RepayLoanConst.RepayFor.REPAYAPL:
      return PayoutPopup.PayoutMethods.REPAY_APL
    case RepayLoanConst.RepayFor.REPAYOPL:
      return PayoutPopup.PayoutMethods.REPAY_OPL
    default:
      return PayoutPopup.PayoutMethods.PAYLOAN
  }
}
