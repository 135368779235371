/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import { View, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Color } from '@material-ui/lab'
import {
  Tab,
  Toast,
  TabList,
  useMobile,
  ErrorHandling,
  RBAC,
  GroupPolicyPermission,
  MenuPermissions
} from '@pulseops/common'
import { SEABillingChanceUploadFileScreen } from './SEAUploadFileScreen'
import { SEABillingChangeRequestScreen } from './SEARequestHistoryScreen'
import { pipe } from 'fp-ts/lib/function'
import { SEABillingChangeImportHistoryScreen } from './SEAImportHistoryScreen'
import { StackScreenProps } from '@react-navigation/stack'
import { SEABillingChangeStackParamList } from '../SEABillingChangeParamList'

type Props = StackScreenProps<SEABillingChangeStackParamList, 'SEABillingChangeScreen'>

export function SEABillingChanceScreen(props: Props) {
  const { t } = useTranslation('BillingChange')
  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const scrollViewRef = React.useRef<ScrollView>(null)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }

  const tabs = React.useMemo(() => {
    const hasTab = []
    permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu) &&
      hasTab.push({ id: 0, title: t('UploadFile') })
    permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu) &&
      hasTab.push({ id: 1, title: t('ImportFileHistory') })
    permissions.includes(GroupPolicyPermission.ViewImportFileForBillingChangeMenu) &&
      hasTab.push({ id: 2, title: t('RequestFormHistory') })
    return hasTab
  }, [permissions, t])

  React.useEffect(() => {
    tabs && setSubTabIndex(tabs[0]?.id)
  }, [tabs])
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: useMobile().isMobile ? '#E5E5E5' : '#F9F9F9',
        paddingTop: 16
      }}
    >
      <ScrollView ref={scrollViewRef}>
        <View style={{ flex: 1 }}>
          <View style={{ marginHorizontal: 15 }}>
            <TabList
              isScrollIcon={false}
              menus={tabs}
              isSubTab={false}
              useOriginalId={true}
              tabIndex={subTabIndex}
              onChangeTab={(index) => {
                setSubTabIndex(index)
              }}
            />
            <View>
              {subTabIndex === 0 && hasTab(0, tabs) && (
                <SEABillingChanceUploadFileScreen type={props.route.params.type} />
              )}
              {subTabIndex === 1 && hasTab(1, tabs) && <SEABillingChangeImportHistoryScreen />}
              {subTabIndex === 2 && hasTab(2, tabs) && <SEABillingChangeRequestScreen />}
            </View>

            <Toast
              message={toast.message}
              visible={toast.open}
              type={toast.type}
              onClose={() => setToast({ open: false, message: '', type: undefined })}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
