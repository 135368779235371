import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  AmlResult,
  ContractDetail,
  DetailService,
  EditFooterService,
  EmployeeUw,
  Form,
  Input,
  NbuwService,
  ProposalStatusOptions,
  ProposalStatusLabel,
  SectionHeader,
  Select,
  StatusContract,
  Table,
  TableFooterPagination,
  TableStatus,
  UtilRow,
  UwDecisionLabel,
  ProposalStatusCode,
  RefreshButton,
  ProposalStatusInput
} from '@pulseops/business/core'
import { Alert, didUpdate, ErrorHandling, form2, Format, RBAC, Permission, subscribe } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../contract-detail-context'
import { SC, StrongText } from '../sc'
import { IssueForm } from './issue-form'
import { UploadButton } from './upload-button'

export const Issue = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)
  const service = NbuwService.forContract(contractId)
  const detailService = DetailService.contract(contractId)

  const {
    base: {
      control,
      formState: { errors },
      setValue,
      getValues
    },
    handleSubmit
  } = form2.useForm(IssueForm.codec)

  const [sizeTable1, setSizeTable1] = React.useState(10)
  const [pageTable1, setPageTable1] = React.useState(0)
  const [loading1, bindLoading1] = useLoading(false)

  const [sizeTable2, setSizeTable2] = React.useState(10)
  const [pageTable2, setPageTable2] = React.useState(0)
  const [loading2, bindLoading2] = useLoading(false)

  const [refreshLoading, bindRefreshLoading] = useLoading(false)
  const [isRelease, setStatusRelease] = React.useState(false)
  const [isPostReleaseUpdate, setPostReleaseUpdate] = React.useState(false)
  const EXCEPTION_STATUS: any = [
    ProposalStatusCode.Issuing,
    ProposalStatusCode.AfterInForce,
    ProposalStatusCode.AfterIssuing,
    ProposalStatusCode.InForce
  ]

  pipe(
    didUpdate([sizeTable1, pageTable1]),
    ZStream.chainEffect(([size, page]) => pipe(service.getEmployeeUwList({ size, page }), bindLoading1)),
    subscribe()
  )

  const rowsTable1: EmployeeUw[] | null = pipe(
    didUpdate([sizeTable1, pageTable1]),
    ZStream.switchMap(([size, page]) => service.state.employeeUw.size(size).watchPage(page)),
    subscribe([])
  )

  const totalTable1 = pipe(
    didUpdate([sizeTable1]),
    ZStream.switchMap(([size]) => service.state.employeeUw.size(size).watchTotal),
    subscribe(0)
  )

  pipe(
    didUpdate([sizeTable2, pageTable2]),
    ZStream.chainEffect(([size, page]) => pipe(service.getStatusContract({ size, page }), bindLoading2)),
    subscribe()
  )

  const rowsTable2: StatusContract[] | null = pipe(
    didUpdate([sizeTable2, pageTable2]),
    ZStream.switchMap(([size, page]) => service.state.statusContract.size(size).watchPage(page)),
    subscribe([])
  )

  const totalTable2 = pipe(
    didUpdate([sizeTable2]),
    ZStream.switchMap(([size]) => service.state.statusContract.size(size).watchTotal),
    subscribe(0)
  )

  const info: ContractDetail | null = pipe(
    detailService.getInfo,
    ErrorHandling.runDidUpdate(null, [getValues('proposalStatus')])
  )

  const isUploadHandbook = roles.includes(Permission['UploadHandbookGroupDetailIssuance'])
  const isEditDecision = roles.includes(Permission['EditIssuanceDecisionGroupDetailIssuance'])
  const [registerOnSave, , registerOnIssue, registerOnUpdate] = EditFooterService.useFooter({
    hideCancel: true,
    showIssueBtn: isEditDecision && !isRelease,
    hideSave: isEditDecision && isRelease,
    showUpdateIssue: isEditDecision && isRelease
  })

  const reloadStatusContract = ZIO.effect(() => {
    if (pageTable2 === 0) {
      pipe(service.getStatusContract({ size: sizeTable2, page: pageTable2 }), bindLoading2, ErrorHandling.run())
    } else {
      setPageTable2(0)
    }
  })

  pipe(
    handleSubmit((validated) =>
      pipe(service.addStatusContract(validated.proposalStatus, reloadStatusContract), ErrorHandling.run())
    ),
    registerOnSave
  )

  const [loadingUpdate, bindLoadingUpdate] = useLoading(false)
  const updateAfterRelease = () => {
    pipe(
      service.updateAfterRelease,
      bindLoadingUpdate,
      ZIO.tap(() =>
        ZIO.zip(
          ZIO.effectTotal(() => {
            setPostReleaseUpdate(false)
            setStatusRelease(true)
          }),
          Alert.alertM('Hợp đồng đang cập nhật sau phát hành'),
          detailService.refreshInfo,
          service.state.statusContract.clear,
          reloadStatusContract
        )
      ),
      ZIO.flatMap(() => detailService.getInfo),
      ZIO.map((info) => setValue('proposalStatus', info.status || ProposalStatusCode['Proposal'])),
      ErrorHandling.run()
    )
  }

  pipe(
    () =>
      pipe(
        service.checkContractAfterRelease,
        ZIO.tap(
          O.fold(
            () =>
              ZIO.effectTotal(() => {
                setPostReleaseUpdate(true)
              }),
            () => ZIO.unit
          )
        ),
        ZIO.runPromise({})
      ),
    registerOnUpdate
  )

  const [loadingUpload, bindLoadingUpload] = useLoading(false)
  const onUpload = (file: File) => {
    pipe(service.uploadHandbook(file), bindLoadingUpload, ErrorHandling.run())
  }

  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  const handleClose = () => {
    setPostReleaseUpdate(false)
  }

  pipe(
    () =>
      pipe(
        service.checkContract,
        ZIO.tap(
          O.fold(
            () =>
              ZIO.effectTotal(() => {
                setConfirmOpen(true)
              }),
            () => ZIO.unit
          )
        ),
        ZIO.runPromise({})
      ),
    registerOnIssue
  )

  const [loadingIssue, bindLoadingIssue] = useLoading(false)
  const issuePolicy = () => {
    pipe(
      service.issuePolicy,
      bindLoadingIssue,
      ZIO.tap(() =>
        ZIO.zip(
          ZIO.effectTotal(() => {
            setConfirmOpen(false)
            setStatusRelease(true)
          }),
          Alert.alertM('Hợp đồng đang được phát hành'),
          detailService.refreshInfo,
          service.state.statusContract.clear,
          reloadStatusContract
        )
      ),
      ZIO.flatMap(() => detailService.getInfo),
      ZIO.map((info) => setValue('proposalStatus', info.status || ProposalStatusCode['Proposal'])),
      ErrorHandling.run()
    )
  }

  const refresh = () => {
    pipe(
      ZIO.zip(detailService.refreshInfo, service.state.statusContract.clear, reloadStatusContract),
      ZIO.flatMap(() => detailService.getInfo),
      ZIO.map((info) => setValue('proposalStatus', info.status || ProposalStatusCode['Proposal'])),
      bindRefreshLoading,
      ErrorHandling.run()
    )
  }

  const isExceptionStatus = () => EXCEPTION_STATUS.some((value: any) => value === info?.status)

  React.useEffect(() => {
    if (isExceptionStatus()) setStatusRelease(true)
  }, [info])

  return (
    <>
      <SC.Container>
        {isUploadHandbook && (
          <UtilRow>
            <UploadButton onUpload={onUpload} loading={loadingUpload} />
            <RefreshButton
              onPress={refresh}
              loading={refreshLoading}
              disabled={loadingUpload || loading1 || loading2}
            />
          </UtilRow>
        )}
        <SC.BorderContainer>
          <Form.Group>
            <SectionHeader>{t('contract_info')}</SectionHeader>
            <Form.Row>
              <Form.Col>
                <Input label={t('proposal_no')} value={contractId.masterContractNo} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('policy_no')} value={contractId.masterContractNo} readonly />
              </Form.Col>
              <Form.Col>
                {info ? (
                  isExceptionStatus() ? (
                    <Input
                      label={t('proposal_status')}
                      value={ProposalStatusInput.find((_) => _.value === info.status)?.label || '-'}
                      readonly
                    />
                  ) : (
                    <Controller
                      control={control}
                      name="proposalStatus"
                      defaultValue={info.status || ProposalStatusCode['Proposal']}
                      render={({ field }) => (
                        <Select
                          options={ProposalStatusOptions}
                          label={t('proposal_status')}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          disabled={!isEditDecision}
                          errorMessage={errors?.proposalStatus?.message}
                        />
                      )}
                    />
                  )
                ) : null}
              </Form.Col>
            </Form.Row>
          </Form.Group>
        </SC.BorderContainer>

        <SC.BorderContainer>
          <SectionHeader>{t('member_list')}</SectionHeader>
          <Table.Container component={Paper}>
            <Table.Main>
              <Table.Head>
                <Table.Row>
                  <SC.TableCellHead align="center">STT</SC.TableCellHead>
                  <SC.TableCellHead>{t('employee_fullname')}</SC.TableCellHead>
                  <SC.TableCellHead>ID</SC.TableCellHead>
                  <SC.TableCellHead>{t('phone_number')}</SC.TableCellHead>
                  <SC.TableCellHead>{t('email')}</SC.TableCellHead>
                  <SC.TableCellHead>{t('benefit_group')}</SC.TableCellHead>
                  <SC.TableCellHead>AML</SC.TableCellHead>
                  <SC.TableCellHead>{t('UW_decision')}</SC.TableCellHead>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <TableStatus colSpan={8} rows={rowsTable1} loading={loading1} />
                {rowsTable1?.map((row, i) => (
                  <Table.Row key={i}>
                    <SC.TableCell align="center">
                      <StrongText>{i + 1}</StrongText>
                    </SC.TableCell>
                    <SC.TableCell>{row.employeeName}</SC.TableCell>
                    <SC.TableCell>{row.employeeId || '-'}</SC.TableCell>
                    <SC.TableCell>{row.employeePhoneNumber || '-'}</SC.TableCell>
                    <SC.TableCell>{row.email || '-'}</SC.TableCell>
                    <SC.TableCell>{row.benefitClass || '-'}</SC.TableCell>
                    <SC.TableCell>{AmlResult.formatLatest(row.historyAmlResult).result}</SC.TableCell>
                    <SC.TableCell>{UwDecisionLabel(row.uwDecision)}</SC.TableCell>
                  </Table.Row>
                ))}
              </Table.Body>
              <TableFooterPagination
                total={totalTable1}
                size={sizeTable1}
                page={pageTable1}
                onPageChange={setPageTable1}
                onSizeChange={setSizeTable1}
                colspan={8}
              />
            </Table.Main>
          </Table.Container>
        </SC.BorderContainer>

        <SC.BorderContainer>
          <SectionHeader>{t('issue_history')}</SectionHeader>
          <Table.Container component={Paper}>
            <Table.Main>
              <Table.Head>
                <Table.Row>
                  <Table.CellHead align="center">STT</Table.CellHead>
                  <Table.CellHead>{t('status')}</Table.CellHead>
                  <Table.CellHead>UserID</Table.CellHead>
                  <Table.CellHead align="center">{t('implementation_date')}</Table.CellHead>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <TableStatus colSpan={4} rows={rowsTable2} loading={loading2} />
                {rowsTable2?.map((row, i) => (
                  <Table.Row key={i}>
                    <Table.Cell align="center">
                      <StrongText>{i + 1}</StrongText>
                    </Table.Cell>
                    <Table.Cell>{ProposalStatusLabel(row.status)}</Table.Cell>
                    <Table.Cell>{row.createdBy}</Table.Cell>
                    <Table.Cell align="center">{Format.datetime(row.createdDate)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <TableFooterPagination
                total={totalTable2}
                size={sizeTable2}
                page={pageTable2}
                onPageChange={setPageTable2}
                onSizeChange={setSizeTable2}
                colspan={4}
              />
            </Table.Main>
          </Table.Container>
        </SC.BorderContainer>
      </SC.Container>
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Thông báo</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              whiteSpace: 'pre-wrap',
              color: 'black'
            }}
          >
            Phát hành hợp đồng
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} disabled={loadingIssue}>
            Không
          </Button>
          <Button onClick={issuePolicy} color="primary" autoFocus disabled={loadingIssue}>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isPostReleaseUpdate} onClose={handleClose}>
        <DialogTitle>Thông báo</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              whiteSpace: 'pre-wrap',
              color: 'black'
            }}
          >
            {t('update_issue')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loadingUpdate}>
            {t('common:No')}
          </Button>
          <Button onClick={updateAfterRelease} color="primary" autoFocus disabled={loadingIssue}>
            {t('common:Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
