import { ZIO, Task, UIO } from '@mxt/zio'
import { Nullable } from '@mxt/zio/codec'
import { Duration } from '@mxt/zio/date'
import { ErrorHandling, GeneralService, POApi, SelectOption, SubmissionService, SubClaimType } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'

export interface Bank {
  code: string
  name: string
}
export interface Branch {
  code: string
  name: string
}
export interface ClaimDataAccess {
  policyNum: string
  mobileCodes: { value: string; label: string }[]
  ownerName: string
  ownerId: string
  ownerPhoneCode: { value: string; label: string }
  ownerPhoneNumber: string
  ownerEmail: string
  ownerAddress: string
  laList: { value: string; label: string }[]
  claimantName: string
  claimantPhoneCode: { value: string; label: string }
  claimantPhoneNumber: string
  claimTypes: SubClaimType[]
  claimSubBenefit: {
    benefitCode: string
    benefitEN: string
    benefitVN: string
    benefitWF: string
    id: string
  }[]
  claimNo: string
  dob: string
}

interface ClaimSubmitData {
  policy: {
    sourceOfBusiness: string
    owners: {
      clientId: string
      name: string
      contactDetails: {
        EMAIL: {
          channel: 'email' // Contact.ChannelEnum
          value: string
        }
        PHONE: {
          channel: 'phone' // Contact.ChannelEnum
          countryCode: string
          value: string
        }
      }
    }
  }
  lifeAssured: {
    name: string
    dob: string
    externalIds: {
      SOE_VALUE: string
    }
  }
  claimType: string
  claimNo: string
  attributes: {
    IS_CLAIM_ANT_NOT_PO: boolean
    CLAIM_ANT_NAME: string
    CLAIM_ANT_PHONE: string
    CLAIM_ANT_ADDRESS: string
    CLAIM_ANT_EMAIL: string
    DEATH?: {
      claimEventDate: string
      isRelatedToAccident: boolean
      cause: SelectOption[]
    }
    DISABILITY?: {
      claimEventDate: string
      isRelatedToAccident: boolean
      cause: SelectOption[]
    }
    CRITICAL_ILLNESS?: {
      claimEventDate: string
      isRelatedToAccident: boolean
      diagnosis: SelectOption[]
    }
    PATIENT_IN?: {
      hospital: string
      diagnosis: string | null
      admissionDate: string
      dischargeDate: string
      surgeryBenefit: boolean
      icuBenefit: boolean
      isRelatedToAccident: boolean
      additionalDiagnosis: SelectOption[]
    }
    PATIENT_OUT?: {
      hospital: string
      diagnosis: string | null
      admissionDate: string
      surgeryDate: string
      fee: number
      isRelatedToAccident: boolean
    }
    PATIENT_DENTAL?: {
      hospital: string
      diagnosis: string | null
      admissionDate: string
      fee: number
      isRelatedToAccident: boolean
    }
    PATIENT_MATERNITY?: {
      hospital: string
      diagnosis: string | null
      admissionDate: string
      isRelatedToAccident: boolean
    }
    PAYMENT: {
      paymentMethod: string
      isPayeeNotPO: boolean
      dob: string
      gender: string
      occupation: string
      relationshipWithPO: string
      nationality: string
      nationality2: string
      residenceCountry: string
      address: string
      foreignAddress: string
      bankTransfer?: {
        accountName: string
        idNumber: string
        bank: string
        bankName: string
        branch: string
        branchName: string
        accountNumber: string
      }
      payAtBank?: {
        payeeName: string
        idNumber: string
        issuedDate: string
        issuedBy: string
        bank: string
        bankName: string
        branch: string
        branchName: string
      }
      payAtCounter?: {
        payeeName: string
        idNumber: string
        gaOffice: string
      }
    }
  }
}

export namespace ClaimService {
  const getOptionsMemoize = pipe(
    ZIO.succeed([
      {
        label: 'Nguyen Van B',
        value: 'value'
      },
      {
        label: 'Nguyen Van C',
        value: 'value 2'
      }
    ]),
    ZIO.tap(() => ZIO.sleep(Duration.seconds(3)))
  )

  export const searchOptions = (inputValue: string) =>
    pipe(getOptionsMemoize, ZIO.provide({ inputValue }), ErrorHandling.catchAll([]))

  export const searchDiagnostic =
    (translator: (valEn: string, valVi: string) => string) =>
    (inputValue: string): UIO<SelectOption[]> =>
      pipe(
        GeneralService.searchDiagnostic(inputValue),
        ZIO.map((d) => d.map((di) => ({ value: di.icdcode, label: translator(di.diseaseNameEn, di.diseaseNameVn) }))),
        ZIO.provide({ inputValue }),
        ErrorHandling.catchAll([])
      )

  export const getDataAccess = (policyNum: string, uuid: string): Task<ClaimDataAccess | null> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicyInquiry(policyNum),
        GeneralService.getMobileCodes,
        GeneralService.getClaimType(),
        GeneralService.getSubClaimType(),
        GeneralService.getSubClaimTypeBenefit(),
        GeneralService.getClaimSubBenefit,
        GeneralService.getClaimNumber(uuid)
      ),
      ZIO.map(([policyInquiry, mobileCodes, claimTypes, subClaimTypes, claimBenefit, subClaimBenefit, claimNumber]) => {
        const owner = policyInquiry.customerRoles.find((x) => x.role === 'Owner')
        const productCode = policyInquiry?.product?.code
        const cTypes = claimTypes.find((c) => c.productCode === productCode)
        if (!owner || !cTypes) return null
        const totalClaimTypes = subClaimTypes.filter((s) => cTypes.claimType?.includes(s.claimTypeCode))
        const claimTypeCodes: string[] = totalClaimTypes.reduce(
          (acc: string[], curr) => acc.concat(curr.subClaimType),
          []
        )
        const subBenefit = claimBenefit.filter((b) => claimTypeCodes.includes(b.subClaimTypeCode))
        const { firstName, surName, externalIds, contactDetails, addressDetails, dob } = owner.customer
        const { PHONE, EMAIL } = contactDetails
        const { Current } = addressDetails
        return {
          policyNum,
          mobileCodes: mobileCodes.map((m) => ({ value: m.code, label: `+${m.code}` })),
          ownerName: `${firstName} ${surName}`,
          ownerId: externalIds.NATIONAL_ID,
          ownerPhoneCode: { value: PHONE?.countryCode || '', label: `+${PHONE?.countryCode || ''}` },
          ownerPhoneNumber: PHONE?.value ?? '',
          ownerEmail: EMAIL?.value || '',
          ownerAddress: `${Current.line1} ${Current.line2 || ''} ${Current.line3 || ''} ${Current.line4 || ''}`.trim(),
          laList:
            policyInquiry?.lifeAssured?.map(({ firstName, surName }) => ({
              value: `${surName} ${firstName}`,
              label: `${surName} ${firstName}`
            })) || [],
          claimantName: `${firstName} ${surName}`,
          claimantPhoneCode: { value: PHONE?.countryCode || '', label: `+${PHONE?.countryCode || ''}` },
          claimantPhoneNumber: PHONE?.value || '',
          claimTypes: totalClaimTypes,
          claimSubBenefit: subClaimBenefit.filter((s) => subBenefit.some((sub) => sub.benefit?.includes('N/A'))),
          claimNo: claimNumber,
          dob: dob
        }
      })
    )

  const bankRes = t.type({
    body: t.array(
      t.type({
        code: t.string,
        name: t.string
      })
    )
  })

  export const getBanks = (): Task<Array<Bank>> =>
    pipe(
      POApi.post(`wf-api/bank/search`)(bankRes)({
        body: {}
      }),
      ZIO.map((banks) => banks.body.filter((bank) => bank.code !== 'NULL' && bank.code !== 'VRB'))
    )

  const branchRes = t.type({
    body: t.array(
      t.type({
        code: t.string,
        name: t.string
      })
    )
  })
  export const getBankBranch = (code: string): Task<Array<Branch>> =>
    pipe(
      POApi.get(`wf-api/bank/${code}/branch/search`)(branchRes),
      ZIO.map((branches) =>
        branches.body.map((x) => ({
          code: x.code,
          name: x.name
        }))
      )
    )

  export const submit = (
    policyNum: string,
    data: ClaimSubmitData,
    documents: { name: string; url: string }[],
    exchangeId: string,
    officeCode: string,
    isClaim: boolean
  ) =>
    SubmissionService.submit(t.unknown)(
      `pulseops/partner/claim`,
      { body: data },
      policyNum,
      null,
      documents,
      data.policy.owners.clientId,
      undefined,
      officeCode,
      isClaim,
      exchangeId
    )
}
