import * as t from 'io-ts'

export namespace AssignmentModel {
  export const Relations = t.type({
    body: t.array(
      t.type({
        code: t.string,
        la1: t.string,
        la2: t.string,
        la3: t.string,
        la4: t.string,
        name: t.string,
        nameEn: t.string
      })
    )
  })

  export type Options = Array<{
    value: string
    label: string
  }>

  export interface CustomerData {
    customerId: string
    idType: string
    idNum: string
    issuedAt: string
    name: string
    surName: string
    fatca: boolean
    dob: string
    dod: string
    gender: string
    nationality: string
    nationality2: string
    mobilePhoneCode: string
    mobilePhoneNumber: string
    email: string
    role: string
    occupation: string
    address: string
    countryCode: string
    city: string
    district: string
    ward: string
    foreignAddress: string
    foreignCountry: string
    relationShipWithMainLifeAssured: string
    relationShipWithMainLifeAssuredLA2: string
    relationShipWithMainLifeAssuredLA3: string
    relationShipWithMainLifeAssuredLA4: string
  }

  export interface Detail {
    customerId: string
    policyHasBen: boolean
    laList: {
      clientNumberOfLA: string
      life: string
      name: string
    }[]
    customerData: CustomerData[]
  }

  export interface DataSubmit {
    clientId: string
    surName: string
    firstName: string
    dob: string
    dateOfDeath?: string | null
    sex: string
    nationality: string
    occupation: string
    smsIndicator: string
    emailIndicator: string
    foreignCountry: string
    contactDetails: {
      PHONE: {
        countryCode: string
        value: string
      }
      EMAIL: {
        value: string
      }
    }
    addressDetails: {
      ADDRESS: {
        line1: string
        countryCode: string
        city: string
        district: string
        subDistrict: string
        location?: string
      }
      FOREIGN_ADDRESS: {
        line1: string
      }
    }
    attributesExt: {
      POLICY_NO: string
      CLIENT_NO: string
      ID_NUMBER: string
      RELATIONSHIP_1: string
      IS_FATCA: string
      NATIONALITY_2: string
      CLIENT_NO_2?: string | null
      CLIENT_NAME_2?: string | null
      RELATIONSHIP_2?: string | null
      CLIENT_NO_3?: string | null
      CLIENT_NAME_3?: string | null
      RELATIONSHIP_3?: string | null
      CLIENT_NO_4?: string | null
      CLIENT_NAME_4?: string | null
      RELATIONSHIP_4?: string | null
      DUPLICATE_PHONES?: {
        CLIENT_NUM: string
        CLIENT_NAME: string
        RELATIONSHIP?: string
      }[]
      DUPLICATE_EMAILS?: {
        CLIENT_NUM: string
        CLIENT_NAME: string
        RELATIONSHIP?: string
      }[]
      POLICY_HAS_BEN: string
      LOCATION_CODE: string

      newPolicyOwner: string
      nationality1Name: string
      nationality2Name: string
      occupationName: string
      dobFormated: string
      countryName: string
      taxResidencyCountry?: string
    }
  }
}
