import * as React from 'react'
import { ContractId } from '@pulseops/business/core'

export type ContractDetailContext = {
  contractId: ContractId
}

export const ContractDetailContext = React.createContext<ContractDetailContext>({
  contractId: {
    masterContractNo: '',
    policyYear: 1
  }
})
