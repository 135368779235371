import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { ClientPolicy, Customer } from '../Client'
import { PaymentType } from '../PaymentType'
import { InternalPayments } from '../task-detail-api/PaymentData'
import { getCoreDetail, getDetail, getPayOutDetail } from './common/payout'
import { SourceType } from '../SourceType'
import { PrucashPayment } from '../PruCashPayment'

export interface CashOutPrucash {
  tag: TransactionType.PRUCASH_PAYMENT
  clientName: string
  totalCashBenefits: number | null
  notPaidPremium: number | null
  loanExceed: number | null
  netTotalCashBenefits: number | null
  totalAmount: number
  methods: PaymentMethod[]
  inheritanceFlag: boolean
  deathDate: string
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
}
export const CashOutPrucash = (
  detail: api.CashOutPrucash & api.TaskDetailBase,
  client: Customer,
  poName: string,
  prucashData: PrucashPayment.Detail,
  clientPolicy: Array<ClientPolicy>,
  branchName?: string
): CashOutPrucash => {
  const coreStatus = getCoreDetail(detail.coreResponseDetail, detail.payload.body?.cashOutOptions ?? [])
  switch (detail.source) {
    case SourceType.EKIOSK:
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
    // case SourceType.PRUONLINE:
    case SourceType.PULSE:
      const p = detail.payload
      return {
        tag: TransactionType.PRUCASH_PAYMENT,
        clientName: poName || client.body.name,

        inheritanceFlag:
          p.additionalProperties?.inheritanceFlag && p.additionalProperties?.inheritanceFlag === 'Y' ? true : false,
        deathDate: p.additionalProperties?.deathDate ?? '',
        totalCashBenefits: prucashData.totalCashBenefit,
        notPaidPremium: prucashData.notPaidPremium,
        loanExceed: prucashData.loanExceedSurrenderValue,
        netTotalCashBenefits: prucashData.netTotalCashBenefit,
        totalAmount: p.body?.cashOutOptions?.reduce((sum, val) => sum + (val.paymentOption.amount || 0), 0) ?? 0,
        cashOutList: undefined,
        methods:
          p.body?.cashOutOptions?.map((v, i) => {
            const _detail =
              v.type === PaymentType.BANKTRANSFER
                ? `${v.bankAccount?.bankName ? v.bankAccount?.bankName + ' - ' : ''} ${
                    v.bankAccount?.accountName ? v.bankAccount.accountName + ' -' : ''
                  } ${v.bankAccount?.accountNo || ''}`
                : // : v.type === PaymentType.EWALLET
                // ? `${v.bankAccount?.branchCode}`
                v.type === PaymentType.MOMO
                ? `${v.attributesExt?.PAYEE_INFO?.payeeName} ${v.attributesExt?.PAYEE_INFO?.idNumber} - ${
                    v.attributesExt?.PAYEE_INFO?.phone ?? ''
                  }`
                : v.type && InternalPayments.includes(v.type)
                ? getDetail(v.policy?.policyNo, clientPolicy)
                : getDetail(v.policy?.policyNo, clientPolicy)

            return {
              method: v.type || '-',
              detail: _detail && _detail.length > 1 ? _detail : getDetail(v.policy?.policyNo, clientPolicy),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '' : undefined
            }
          }) ?? []
      }
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS:
      const info = detail.payload.body
      const cashOutList =
        detail.payload && detail.payload.body
          ? detail.payload.body.cashOutOptions?.filter(
              (x) =>
                PaymentType.BANKTRANSFER === x.type ||
                PaymentType.PAID_AT_BANK === x.type ||
                PaymentType.CASH_AT_COUNTER === x.type
            )
          : []
      return {
        tag: TransactionType.PRUCASH_PAYMENT,
        clientName: poName || client.body.name,
        inheritanceFlag: info?.policy?.attributesExt.inheritanceFlag === 'Y',
        deathDate: info?.policy?.owners.dateOfDeath ? info.policy.owners.dateOfDeath : '-',
        totalCashBenefits: prucashData.totalCashBenefit,
        notPaidPremium: prucashData.notPaidPremium,
        loanExceed: prucashData.loanExceedSurrenderValue,
        netTotalCashBenefits: prucashData.netTotalCashBenefit,
        totalAmount: info?.cashOutOptions?.reduce((sum, val) => sum + (val.paymentOption.amount || 0), 0) ?? 0,
        cashOutList: cashOutList,
        branchName: branchName,
        methods:
          info?.cashOutOptions?.map((v, i) => {
            // const _detail =
            //   v.type === PaymentType.BANKTRANSFER
            //     ? `${v.bankAccount?.bankName ? v.bankAccount?.bankName + ' - ' : ''} ${
            //         v.bankAccount?.accountName + '-' ?? ''
            //       } ${v.bankAccount?.accountNo || ''}`
            //     : v.type === PaymentType.EWALLET
            //     ? `${v.bankAccount?.branchCode}`
            //     : v.type && InternalPayments.includes(v.type)
            //     ? getDetail(v.policy?.policyNo, clientPolicy)
            //     : getDetail(v.policy?.policyNo, clientPolicy)
            // console.log(v)
            return {
              method: v.type || '-',
              // detail: _detail && _detail.length > 1 ? _detail : getDetail(v.policy?.policyNo, clientPolicy),
              detail: getPayOutDetail(
                {
                  type: v.type as PaymentType,
                  officeCode:
                    (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) ||
                    '',

                  bankAccount: {
                    accountName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                      (v.type === PaymentType.PAID_AT_BANK &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      (v.type === PaymentType.CASH_AT_COUNTER &&
                        (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                          ? poName
                          : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                      '-',
                    accountNo:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                      ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                        detail.clientNumber) ||
                      ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                      '-',
                    bankName:
                      (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.bankName) ||
                      (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                      '-',
                    branchName:
                      (v.type === PaymentType.BANKTRANSFER && branchName) ||
                      (v.type === PaymentType.PAID_AT_BANK
                        ? detail.source !== SourceType.PRUONLINE
                          ? branchName
                          : v.bankAccount?.branchCode
                        : '') ||
                      undefined,
                    bankCode: '-',
                    branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
                  },
                  phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
                  issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
                  issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
                  policy: {
                    policyNo: v.policy?.policyNo || '-'
                  }
                },
                clientPolicy
              ),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '' : undefined
            }
          }) ?? []
      }
  }
}
