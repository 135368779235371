import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { StaffSubmissionStackParamList } from './StaffSubmissionStackParamList'
import { ClaimStack } from './claim'
import { StaffSubmissionScreen } from './StaffSubmissionScreen'
import { PolicyDetailScreen } from './policy-detail'
import { ContactCenterScreen } from './contact-center'
import { UpdateInfoScreen } from './update-info-form'
import { PSSubmissionStack } from './ps-submission'
import { ComplainInquiryScreen } from './complain-inquiry'
import { InquiryStack } from '@pulseops/inquiry'
import { AmlRequestStack } from './aml-request'
const Stack = createStackNavigator<StaffSubmissionStackParamList>()

export const StaffSubmissionStack = () => {
  return (
    <Stack.Navigator initialRouteName="StaffSubmissionScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="StaffSubmissionScreen" component={StaffSubmissionScreen} />
      <Stack.Screen name="ContactCenterScreen" component={ContactCenterScreen} />
      <Stack.Screen name="ClaimStack" component={ClaimStack} />
      <Stack.Screen name="PolicyDetail" component={PolicyDetailScreen} />
      <Stack.Screen name="UpdateInfoScreen" component={UpdateInfoScreen} />
      <Stack.Screen name="PSSubmissionStack" component={PSSubmissionStack} />
      <Stack.Screen name="ComplainInquiryScreen" component={ComplainInquiryScreen} />
      <Stack.Screen name="InquiryStack" component={InquiryStack} />
      <Stack.Screen name="AmlRequestStack" component={AmlRequestStack} />
    </Stack.Navigator>
  )
}
