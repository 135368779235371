export namespace PremiumRedirectionConst {
  export const fundList = [
    { code: 'UBL1', name: 'PRUlink Balanced', nameVi: 'Quỹ PRUlink Cân Bằng' },
    { code: 'UEQ1', name: 'PRUlink VN Equity', nameVi: 'Quỹ PRUlink Cổ Phiếu Việt Nam' },
    { code: 'UFI1', name: 'PRUlink VN FixedInc', nameVi: 'Quỹ PRUlink Trái Phiếu Việt Nam' },
    { code: 'UGR1', name: 'PRUlink Growth', nameVi: 'Quỹ PRUlink Tăng Trưởng' },
    { code: 'UPS1', name: 'PRUlink Preserver', nameVi: 'Quỹ PRUlink Bảo Toàn' },
    { code: 'USB1', name: 'PRUlink Stable', nameVi: 'Quỹ PRUlink Bền Vững' },
    {
      code: 'USE1',
      name: 'Prulink Vietnam Sustainable Development Equity Fund',
      nameVi: 'Quỹ PRUlink Tương lai xanh'
    }
  ]
}
