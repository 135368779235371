import { animated, useSpring } from '@react-spring/native'
import React from 'react'
import { Pressable, ViewStyle } from 'react-native'
import { StyleProp } from 'react-native'
import { View, Text } from 'react-native'
import styled from 'styled-components/native'
import { assets } from '../../assets'

type Props = {
  label?: string | JSX.Element
  initCollapse?: boolean
  children?: React.ReactNode
  disabled?: boolean
  icon?: JSX.Element
  headerContainerStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  bodyStyle?: StyleProp<ViewStyle>
}
export const CollapseView = ({
  children,
  initCollapse = false,
  disabled = false,
  label,
  icon,
  headerContainerStyle,
  containerStyle,
  bodyStyle
}: Props) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(initCollapse)
  const [contentHeight, setHeight] = React.useState<number>(0)

  //   const iconAnimStyle = useSpring({
  //     transform: { rotate: isCollapsed ? '0deg' : '180deg' }
  //   })
  const anim = useSpring({
    trans: isCollapsed ? 0 : 1
  })

  const togglePanel = () => {
    setIsCollapsed((prevState) => !prevState)
  }

  const rotate = anim.trans.to({
    range: [0, 1],
    output: ['-180deg', '0deg']
  })
  const height = anim.trans.to({
    range: [0, 1],
    output: [0, contentHeight]
  })
  return (
    <SC.Panel style={containerStyle}>
      <Pressable onPress={() => !disabled && togglePanel()}>
        <SC.PanelHeading style={headerContainerStyle}>
          <View style={{ flex: 1 }}>{typeof label === 'string' ? <Text>{label}</Text> : label}</View>
          <SC.IconContainer style={{ transform: [{ rotate }] }}>{icon ?? <assets.IconArrowDownRed />}</SC.IconContainer>
        </SC.PanelHeading>
      </Pressable>
      <SC.PanelContent style={{ height, opacity: anim.trans }}>
        <SC.PanelContentInner style={bodyStyle} onLayout={(event) => setHeight(event.nativeEvent.layout.height)}>
          {children}
        </SC.PanelContentInner>
      </SC.PanelContent>
    </SC.Panel>
  )
}

const SC = {
  Panel: styled(View)`
    text-align: left;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 16px;
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14); */
    /* border-width: 1px;
    border-color: 'grey'; */
    border: 1px solid #d3dce6;
  `,

  PanelHeading: styled(View)`
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  `,
  PanelContent: styled(animated(View))`
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    color: #000;
    overflow: hidden;
  `,
  PanelContentInner: styled(View)`
    padding-top: 5px;
    padding-bottom: 5px;
  `,

  IconContainer: styled(animated(View))`
    margin-left: 5px;
  `
}
