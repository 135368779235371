export enum RequestAuthOption {
  OTP = 'OTP',
  SIGNATURE = 'SIGN_ON_AP',
  PAPER = 'ATTACH_REQUEST_FORM',
  BYPASS = 'BY_PASS',
  AUTHENTICATION_PHONE = 'AUTHENTICATION_PHONE',
  OTP_AND_ATTACH_REQUEST_FORM = 'OTP_AND_ATTACH_REQUEST_FORM',
  OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM = 'OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM'
}

export type RequestAuthFormDataSubmit = RequestAuthOTP | RequestAuthPaper | RequestAuthByPass | RequestAuthOTPPaper | RequestOwnerSubscriptionAuthPaper

export type RequestAuthByPass = {
  type: RequestAuthOption.BYPASS
  data: {}
}

export type RequestAuthOTP = {
  type: RequestAuthOption.OTP
  data: {
    otp: number
    transactionName: string
    transactionCode: string
    receiverPhone: string
    payload: string
  }
}

export type RequestAuthPaper = {
  type: RequestAuthOption.PAPER
  data: {
    authFlag: boolean
    attachments: {
      url: string
      name: string
    }[]
  }
}
export type RequestAuthOTPPaper = {
  type: RequestAuthOption.OTP_AND_ATTACH_REQUEST_FORM
  data: {
    otp?: number
    transactionName?: string
    transactionCode?: string
    receiverPhone?: string
    payload?: string
    authFlag: boolean
    attachments: {
      url: string
      name: string
    }[]
  }
}
export type RequestOwnerSubscriptionAuthPaper = {
  type: RequestAuthOption.OWNER_SUBSCRIPTION_DOCUMENT_AND_ATTACH_REQUEST_FORM
  data: {
    authFlag: boolean
    attachments: {
      url: string
      name: string
    }[]
  }
}