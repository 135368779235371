import { Maybe } from '@mxt/zio/codec'
import { AuthService, POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { SearchDetailDataImportFileS, SearchImportFileS, TemplateImportFileS } from './ob-model/OBImportType'

const urlImport = 'cs-api/outbound/import'

export type PayloadSearchImportFile = {
  fromDate: string
  toDate: string
  adhocName: string
  departmentCode: string
  fileName: string
  createdBy: string
  paging: {
    pageIndex: number
    itemsPerPage: number
  }
}

export type PayloadExportFileImport = PayloadSearchImportFile & { sort?: string }

export type PayloadSearchDetailDataImport = {
  fromDate: string
  toDate: string
  adhocName: string
  departmentCode: string
  policyNumber: string
  fileName: string
  status: string
  paging: {
    pageIndex: number
    itemsPerPage: number
  }
}

const buildUrlForDownloadFile = (data: { url: string; payload: any }) => {
  const { url, payload } = data

  return pipe(
    ZIO.zipPar(AuthService.token, AuthService.getLoginType, POApi.buildUrl(url)),
    ZIO.flatMap(([token, loginType, url]) => {
      return ZIO.fromPromise(() =>
        fetch(url, {
          method: 'POST',
          headers: {
            'Cache-control': 'no-cache',
            Pragma: 'no-cache',
            'Content-type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${token}`,
            'X-Authen-Vendor': loginType
          },
          body: JSON.stringify({
            ...payload
          })
        })
      )
    }),

    ZIO.flatMap((res) =>
      ZIO.zipPar(
        ZIO.succeed(res),
        ZIO.fromPromise(() => res.blob())
      )
    )
  )
}

export namespace OBImportFileService {
  export const importFile = (importFile: Blob, evidenceFile: string | Blob) => {
    let formData = new FormData()
    formData.append('importFile', importFile)
    formData.append('evidenceFile', evidenceFile)
    return pipe(
      POApi.postError({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })(`${urlImport}/import-file-to-store`)(
        t.type({
          code: t.string,
          message: t.string,
          errors: Maybe(t.string)
        }),
        POApi.importError
      )(formData),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (success: { code: string; message: string }) => ZIO.succeed(success)
      )
    )
  }

  export const importPromotionFile = (importFile: Blob) => {
    let formData = new FormData()
    formData.append('importFile', importFile)
    return pipe(
      POApi.postError({
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })(`communication-rest/promotion/import`)(
        t.type({
          code: t.string,
          message: t.string,
          errors: Maybe(t.string)
        }),
        POApi.importError
      )(formData),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (success: { code: string; message: string }) => ZIO.succeed(success)
      )
    )
  }

  export const getListTemplateImportFile = () => {
    return pipe(
      POApi.get(`${urlImport}/list-template-import-file`)(
        t.type({
          data: t.array(TemplateImportFileS)
        })
      ),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      ),
      ZIO.map((res) => res.data || [])
    )
  }

  export const searchImportFile = (payload: PayloadSearchImportFile) => {
    return pipe(
      POApi.post(`${urlImport}/search-import-file`)(SearchImportFileS)({ ...payload }),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      ),
      ZIO.map((res) => res.data ?? {})
    )
  }

  export const exportFileImport = (payload: PayloadExportFileImport) => {
    return pipe(buildUrlForDownloadFile({ url: `${urlImport}/export-file-import`, payload: { ...payload } }))
  }

  export const downloadOriginImportFile = (payload: { fileName: string }) => {
    return pipe(buildUrlForDownloadFile({ url: `${urlImport}/download-original-import-file`, payload: { ...payload } }))
  }

  export const exportCompleteDataImportFile = (payload: { fileName: string }) => {
    return pipe(
      buildUrlForDownloadFile({ url: `${urlImport}/export-complete-data-import-file`, payload: { ...payload } })
    )
  }

  export const exportInvalidDataImportFile = (payload: { fileName: string }) => {
    return pipe(
      buildUrlForDownloadFile({ url: `${urlImport}/export-invalid-data-import-file`, payload: { ...payload } })
    )
  }

  export const searchDetailDataImport = (payload: PayloadSearchDetailDataImport) => {
    return pipe(
      POApi.post(`${urlImport}/search-detail-data-import`)(SearchDetailDataImportFileS)({ ...payload }),
      ZIO.foldM(
        (err) => ZIO.fail(err),
        (res) => ZIO.succeed(res)
      ),
      ZIO.map((res) => res.data ?? {})
    )
  }

  export const exportTriggerFailedDataImportFile = (payload: { fileName: string }) => {
    return pipe(buildUrlForDownloadFile({ url: `${urlImport}/export-fail-data-import-file`, payload: { ...payload } }))
  }

  export const getEvidenceFile = (inputData: { fileName: string }) => {
    return buildUrlForDownloadFile({ url: `cs-api/outbound/import/download-evidence-file`, payload: { ...inputData } })
  }

  export const exportPromotionFile = (toDate: string, fromDate: string) => {
    return buildUrlForDownloadFile({ url: `communication-rest/promotion/export`, payload: { toDate, fromDate } })
  }
}
