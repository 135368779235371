import React, { useState } from 'react'
import { View, StyleSheet, useWindowDimensions, TouchableOpacity, Platform, Text } from 'react-native'
import {
  ErrorHandling,
  RBAC,
  useMobile,
  FromToDateRangeMonth,
  DigitalInquiryService,
  PulseOpsFormat,
  Permission,
  AppContext,
  Input,
  DatePicker,
  sharedStyle,
  Digital
} from '@pulseops/common'
import TableContainer from '@material-ui/core/TableContainer'
import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { StackScreenProps } from '@react-navigation/stack'
import { DIStackParamList } from './DIStackParamList'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { VoucherInquiryExportPopup } from './voucher-inquiry-export'
import { useIsFocused } from '@react-navigation/native'
import { ScrollView } from 'react-native-gesture-handler'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
})

type Props = StackScreenProps<DIStackParamList, 'VoucherInquiryScreen'>

export const VoucherInquiryScreen = (props: Props) => {
  const { width, height } = useWindowDimensions()
  const { isWide } = useMobile()

  const classes = useStyles()

  const [voucherCode, setVoucherCode] = React.useState<string>('')
  const [fromDate, setFromDate] = React.useState<Date | null>(null)
  const [toDate, setToDate] = React.useState<Date | null>(null)

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [loading, bindLoading] = useLoading(false)

  const { t } = useTranslation()

  const roles: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  const [errMess, setErrMess] = useState<string>('')

  const [rows, setRows] = React.useState<Digital.VoucherInquiry[]>([])

  const getVoucher = (pageSize: number, pageNum: number) => {
    const dateDiff = toDate && fromDate ? toDate.getTime() - fromDate.getTime() : 0
    if (FromToDateRangeMonth(3)({ fromDate, toDate })) {
      setErrMess(t('message:MS080004', { k: '3' }))
    } else if (!voucherCode && !fromDate && !fromDate) {
      setErrMess(t('message:MS990020_VOUCHER'))
    } else if (dateDiff < 0) {
      setErrMess(t('message:MS030044'))
    } else {
      setErrMess('')
      pipe(
        DigitalInquiryService.getVoucherList(
          voucherCode,
          fromDate ? PulseOpsFormat.datetoFormat(fromDate, 'yyyyMMDD') : '',
          toDate ? PulseOpsFormat.datetoFormat(toDate, 'yyyyMMDD') : '',
          {
            pageSize,
            pageNum
          }
        ),
        ZIO.tap((res) => {
          setTotalItem(res.totalElements)
          setRows(res.content)
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
    }
  }

  const exportData = () => {
    setOpenExport(true)
  }

  const notEmpty = (): boolean => {
    return rows.length > 0 && !!roles && roles.includes(Permission['DI-Voucher-Export'])
  }

  const [openExport, setOpenExport] = useState<boolean>(false)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        {
          title: t('menu:Digital'),
          navigate: () => props.navigation.navigate('DIDashboardScreen')
        },
        {
          title: t('menu:VoucherInquiry'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  return (
    <View style={{ backgroundColor: '#FFF', height: height, flex: 1 }}>
      <ScrollView
        style={{
          width: width >= 1440 ? 1440 : '100%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        {loading && <LinearProgress color="secondary" />}
        <View style={styles.searchContainer}>
          <View style={styles.inputContainer}>
            <Input
              labelStyle={{ marginBottom: 8 }}
              title={t('common:VoucherCode')}
              value={voucherCode}
              onChange={(text) => {
                setVoucherCode(text.trim())
              }}
            />
          </View>
          <View style={styles.inputContainer}>
            <DatePicker
              maxDate={new Date()}
              label={t('common:FromDate')}
              value={fromDate}
              onChange={(val) => setFromDate(val)}
              alwaysShow={true}
            ></DatePicker>
          </View>
          <View style={styles.inputContainer}>
            <DatePicker
              maxDate={new Date()}
              label={t('common:ToDate')}
              value={toDate}
              onChange={(val) => setToDate(val)}
              alwaysShow={true}
            ></DatePicker>
          </View>
          <View style={styles.btnContainer}>
            <TouchableOpacity
              style={styles.searchBtn}
              onPress={() => {
                getVoucher(pageSize, pageNum)
              }}
            >
              <Text style={styles.searchBtnText}>{t('common:Search')}</Text>
            </TouchableOpacity>
          </View>
        </View>
        {!!errMess && <Text style={[styles.section, { color: '#ED1B2C', marginTop: 5 }]}>{errMess}</Text>}
        <View style={[styles.section, { flexDirection: 'row', justifyContent: 'flex-end' }]}>
          {notEmpty() && (
            <TouchableOpacity onPress={() => exportData()} style={sharedStyle.button}>
              <Text style={sharedStyle.textButton}>{t('common:Export')}</Text>
            </TouchableOpacity>
          )}
        </View>

        {isWide && Platform.OS === 'web' && rows.length > 0 && (
          <View style={[styles.section, { marginTop: 10 }]}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell>{t('VoucherInquiryTable:VoucherCode')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:OrderID')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:Batch')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:VouchersSoldNumber')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:OrderIncome')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:PurchasedOrderDate')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:VouchersExpiredDate')}</TableCell>
                    <TableCell>{t('VoucherInquiryTable:TransactionID')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={`DITable-${i}`}>
                      <TableCell>{row.chaVoucherCode}</TableCell>
                      <TableCell>{row.orderId}</TableCell>
                      <TableCell>{row.batchId}</TableCell>
                      <TableCell>{row.quantity}</TableCell>
                      <TableCell>
                        {row.basePrice != null && row.quantity != null
                          ? PulseOpsFormat.thousandSepartorStr((row.basePrice * row.quantity).toString())
                          : ''}
                      </TableCell>
                      <TableCell>{row.purchasedDate}</TableCell>
                      <TableCell>{row.expiryDate}</TableCell>
                      <TableCell>{row.paymentsTxRef}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getVoucher(pageSize, page)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                getVoucher(Number(e.target.value), pageNum)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          </View>
        )}

        <VoucherInquiryExportPopup
          selectedValue={{
            voucherCode: voucherCode,
            fromDate: fromDate ? PulseOpsFormat.datetoFormat(fromDate, 'yyyyMMDD') : '',
            toDate: toDate ? PulseOpsFormat.datetoFormat(toDate, 'yyyyMMDD') : '',
            roles: roles || []
          }}
          open={openExport}
          onClose={() => {
            setOpenExport(false)
          }}
        ></VoucherInquiryExportPopup>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9'
  },

  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    marginTop: 30,
    marginLeft: 30,
    marginRight: 30,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    paddingHorizontal: 16,
    paddingVertical: 16
  },

  searchBtn: {
    backgroundColor: '#ED1B2E',
    borderRadius: 30,
    paddingHorizontal: 18.5,
    paddingVertical: 15,
    lineHeight: 34
  },

  searchBtnText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#fff'
  },

  inputContainer: {
    width: '30%',
    paddingRight: 5
  },

  btnContainer: {
    marginLeft: 10
  },

  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 40,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: '#fff',
    color: '#424242',
    borderRadius: 8,
    fontSize: 15,
    height: 46,
    borderWidth: 1,
    borderColor: '#D3DCE6'
  },

  searchIcon: {
    position: 'absolute',
    right: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
})
