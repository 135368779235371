/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  AppContext,
  ErrorHandling,
  GeneralService,
  Permission,
  RBAC,
  StorageBlob,
  SubmissionService,
  TransactionType
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { AgentRef, AgentTerminationScreen } from './agent-termination'
import { useForm } from 'react-hook-form'
import { FooterAction, AgentWrapper, RequestAuthenticateData, SubmissionAgentService } from './common/index'
import { AgentTransferScreen } from './agent-transfer'
import { AgentMovementScreen } from './agent-movement'
import { UploadedFilesInfo } from './common'
import { UtilitiesStackParamList } from '../utilites-stack-params-list'

export enum tabType {
  request = 'RQ',
  confirm = 'CF'
}

export interface PolicyServicePayload {
  url?: (policyNumber: string) => string
  body?: any
  transactionName?: string
  collerationId?: string
  transaction?: TransactionType
  documents?: StorageBlob.FileContentSubmit[]
  method?: 'POST' | 'PUT'
  uploadedFilesInfo?: UploadedFilesInfo[]
}

type Props = StackScreenProps<UtilitiesStackParamList, 'AgentWrapScreen'>

export const AgentWrapScreen = (props: Props) => {
  const { route } = props
  const transactionProps = route.params?.params?.transactionName ? route.params?.params.transactionName : ''
  const params = props.route?.params?.params
  const docTypeProps = route.params?.params?.docTypeCode ?? ''
  const formRef = useRef<AgentRef | any>()
  const { clearForm, submit } = formRef?.current ?? {}

  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [dataForm, setDataForm] = useState<PolicyServicePayload>({})
  const [hideBtnContinue, setHideBtnContinue] = useState<boolean>(false)
  const [errMessage, setErrMessage] = useState<string>('')
  const [checkTransaction, setCheckTransaction] = useState<string>('')

  const [eformData, setEformData] = React.useState<RequestAuthenticateData.EformRequestAuthenticateData>(
    RequestAuthenticateData.DefaultRequestAuthenticateData
  )
  const [agentInfo, setAgentInfo] = React.useState<{ agentNum: string; clientId: string; poName: string }>({
    agentNum: '',
    clientId: '',
    poName: ''
  })

  const permissions: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('AgentSearchScreen')
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('AgentSearchScreen')
        },
        {
          title: t(`${params.transaction}`),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    if (params?.agentNum && params?.agentNum.startsWith('6')) {
      showGlobalLoading(true)
      pipe(
        SubmissionService.DAgetOwnerInfo(params?.agentNum || ''),
        ZIO.map((responseItem) => {
          const policyItem = {
            agentNum: params?.agentNum || '',
            clientId: responseItem.body.clientId,
            poName: `${responseItem.body.surName} ${responseItem.body.firstName}`
          }
          setAgentInfo(policyItem)
          return responseItem?.body
        }),
        ZIO.tap((res) => {
          setEformData({
            ...eformData,
            agentNum: params?.agentNum,
            phoneData: {
              phoneCode: res.attributesExt.MOBILE_CODE,
              phoneNum: res.mobilePhone ?? ''
            },
            otpRegistered: res.otpConsentStatus === '1',
            isCCE: params?.isCCE === '1'
          })
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.mapError((_) => {
          showGlobalLoading(false)
        }),
        ZIO.unsafeRun({})
      )
    } else {
      showGlobalLoading(true)
      pipe(
        SubmissionService.getOwnerInfoTransferDA(params?.agentNum || ''),
        ZIO.map((responseItem) => {
          const policyItem = {
            agentNum: params?.agentNum || '',
            clientId: responseItem?.body?.clientId,
            poName: `${responseItem?.body?.surName} ${responseItem?.body?.firstName}`
          }
          setAgentInfo(policyItem)
          return policyItem
        }),
        ZIO.flatMap((clientItem) => {
          return pipe(
            SubmissionService.getNewOwnerInfo(clientItem.agentNum),
            ZIO.map((customerInfo) => {
              setEformData({
                ...eformData,
                agentNum: clientItem.agentNum,
                phoneData: {
                  phoneCode: customerInfo.attributesExt.MOBILE_CODE,
                  phoneNum: customerInfo.mobilePhone ?? ''
                },
                otpRegistered: customerInfo.otpConsentStatus === '1',
                isCCE: params?.isCCE === '1'
              })
              showGlobalLoading(false)
              return customerInfo
            })
          )
        }),
        ZIO.mapError((_) => {
          showGlobalLoading(false)
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [params?.agentNum])

  const requestAuthenForm = useForm<RequestAuthenticateData.RequestAuthFormValue>({
    defaultValues: {
      authOption: RequestAuthenticateData.RequestAuthOption.OTP,
      otp: {
        otpOption: RequestAuthenticateData.OTPOption.PHONE,
        otpInput: undefined,
        otpConsent: false
      },
      paper: {
        data: [],
        consent: false
      }
    }
  })
  const { watch } = requestAuthenForm

  const validateRequestAuthenForm = () => {
    return !!watch('otp.otpInput') && watch('otp.otpConsent') && validateOtp()
  }

  const validateOtp = () => {
    if (watch('otp.otpInput').toString().length !== 6) {
      return false
    }
    return true
  }

  const getTransactionName = () => {
    switch (dataForm?.transactionName) {
      case TransactionType.AGENT_TERMINATION_AG: {
        return 'yeu cau thanh ly hop dong'
      }
      case TransactionType.AGENT_UNIT_TRANSFER: {
        return 'yeu cau thay doi phong (Unit)'
      }
      case TransactionType.AGENT_OFFICE_MOVING: {
        return 'yeu cau thay doi van phong hoat dong'
      }
      case TransactionType.AGENT_TRANSFER_POLICY: {
        return 'yeu cau thay doi TVV phuc vu hoat dong'
      }
      default:
        return transactionProps || ''
    }
  }

  const CheckTransactionName = () => {
    switch (transactionProps) {
      case TransactionType.AGENT_TERMINATION_AG: {
        return permissions?.includes(Permission.EditAgentTermination)
      }
      case TransactionType.AGENT_OFFICE_MOVING: {
        return permissions?.includes(Permission.EditAgentMovement)
      }
      case TransactionType.AGENT_TRANSFER_POLICY: {
        return permissions?.includes(Permission.EditAgentTransfer)
      }
      default:
        return false
    }
  }

  const checkTransactionType = () => {
    return transactionProps === TransactionType.AGENT_OFFICE_MOVING
  }

  const onSubmit = (uploadedFilesInfo?: UploadedFilesInfo[]) => {
    const transactionName = getTransactionName()
    const transactionMovement = checkTransaction?.split('-')[0] ?? ''
    const docTypeCodeMovement = checkTransaction?.split('-')[1] ?? ''

    if (!!uploadedFilesInfo && uploadedFilesInfo.length > 0 && uploadedFilesInfo[0].uploadFiles) {
      showGlobalLoading(true)
      const otpData: SubmissionAgentService.OtpInfo = {
        otp: requestAuthenForm.getValues('otp.otpInput')?.toString(),
        payload: '',
        receiverPhone: eformData.phoneData.phoneNum,
        transactionCode: `${eformData.transactionType}-${eformData.agentNum}-${eformData.sendDate}`,
        transactionName: transactionName
      }
      return pipe(
        checkTransactionType()
          ? GeneralService.getMetaDataAgent(transactionMovement, docTypeCodeMovement, params?.officeCode)
          : GeneralService.getMetaDataAgent(transactionProps, docTypeProps, params?.officeCode),
        ZIO.map((dataRow) => {
          let newFileList: StorageBlob.FileContent[] = []
          uploadedFilesInfo.forEach((files) => {
            files.uploadFiles.forEach((item) => {
              const files: StorageBlob.FileContent = {
                file: item.file,
                metaData: {
                  type: dataRow.data.transactionType,
                  doctype: dataRow.data.doctypeEn,
                  class: dataRow.data.classFilenet,
                  docid: dataRow.data.docID,
                  maindoc: dataRow.data.mainDoc,
                  subdoc: dataRow.data.subDoc,
                  agentnum: agentInfo.agentNum,
                  polnum: '',
                  batchno: dataRow.data.batchNo,
                  functionType: 'uploadDocument'
                }
              }
              newFileList.push(files)
            })
          })
          return newFileList
        }),
        ZIO.flatMap((data) => {
          return StorageBlob.uploadToSubmit('DS', agentInfo.agentNum)(data)
        }),
        ZIO.flatMap((documents) =>
          transactionProps === TransactionType.AGENT_TRANSFER_POLICY
            ? SubmissionAgentService.addAgentSubmission(
                dataForm.body,
                transactionProps,
                documents,
                otpData,
                true,
                params?.agentNum ?? ''
              )
            : checkTransactionType()
            ? SubmissionAgentService.addAgentSubmission(dataForm.body, transactionMovement, documents, otpData)
            : SubmissionAgentService.addAgentSubmission(dataForm.body, transactionProps, documents, otpData)
        ),
        ZIO.tap((res) => {
          if (res) {
            showToast(t('message:RequestSendSuccessfully'), 'success')
            showGlobalLoading(false)
            props.navigation.navigate('AgentSearchScreen')
          } else {
            showToast(t('message:MS050001'), 'error')
            showGlobalLoading(false)
          }
          return ZIO.unit
        }),
        ZIO.mapError((err) => {
          let getErrorMess = ''
          if (err.source && err.source.message.includes(' - ')) {
            const code = err.source.message.split(' - ')[0]
            const time = err.source.message.split(' - ')[1].replace('t=', '')
            getErrorMess =
              code === 'MS050252'
                ? t('message:MS050252', { t: time })
                : code === 'MS050254'
                ? t('message:MS050254', { t: time })
                : code === 'MS050253'
                ? t('message:MS050253')
                : code === 'MS050255'
                ? t('message:MS050255')
                : 'Unknown error'
          } else {
            getErrorMess = t('message:MS050001')
          }
          showToast(getErrorMess, 'error')
          showGlobalLoading(false)
          return getErrorMess
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const onClear = () => {
    clearForm()
  }

  const continueForm = () => {
    submit()
  }

  const updatedocReviewed = (docReview: boolean) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      docReviewed: docReview
    }
    setEformData(eFormItem)
  }

  const isByPassAuthPaper = () => {
    return false
  }

  const updateSendDate = (sendDate: string) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      sendDate: sendDate
    }
    setEformData(eFormItem)
  }

  const transactionInfos = () => {
    return [
      { label: t('submission:category'), value: t('submission:PolicyServices') },
      { label: t('submission:serviceType'), value: t('TransactionGroup:LetterRequest') },
      { label: t('submission:transactionType'), value: t(`TransactionType:${params.transactionName}`) }
    ]
  }

  const transactionRender = () => {
    switch (transactionProps as TransactionType) {
      case TransactionType.AGENT_TERMINATION_AG:
        return (
          <AgentTerminationScreen
            isContinue={setHideBtnContinue}
            isTab={tabIndex === 0 ? false : true}
            onCheckValid={setTabIndex}
            setErrMessage={setErrMessage}
            dataForm={setDataForm}
            ref={formRef}
            agentNum={params.agentNum ?? ''}
            officeCode={params.officeCode ?? ''}
          />
        )
      case TransactionType.AGENT_OFFICE_MOVING:
        return (
          <AgentMovementScreen
            isContinue={setHideBtnContinue}
            isTab={tabIndex === 0 ? false : true}
            onCheckValid={setTabIndex}
            setErrMessage={setErrMessage}
            dataForm={setDataForm}
            ref={formRef}
            agentNum={params.agentNum ?? ''}
            officeCode={params.officeCode ?? ''}
            setTransaction={setCheckTransaction}
          />
        )
      case TransactionType.AGENT_TRANSFER_POLICY:
        return (
          <AgentTransferScreen
            isContinue={setHideBtnContinue}
            isTab={tabIndex === 0 ? false : true}
            onCheckValid={setTabIndex}
            setErrMessage={setErrMessage}
            dataForm={setDataForm}
            ref={formRef}
            agentNum={params.agentNum ?? ''}
            officeCode={params.officeCode ?? ''}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <AgentWrapper
          tab={{ isShowed: true, current: tabIndex, isValidated: true, setTab: setTabIndex }}
          requestAuthenData={eformData as RequestAuthenticateData.EformRequestAuthenticateData}
          authenForm={requestAuthenForm}
          updateSendDate={updateSendDate}
          updatedocReviewed={updatedocReviewed}
          updateRequestAuthenData={setEformData}
          isByPassAuthPaper={isByPassAuthPaper()}
          agentInfo={transactionInfos()}
          transactionType={
            getTransactionName().includes('Unit') ? TransactionType.AGENT_UNIT_TRANSFER : params?.transactionName
          }
          officeCode={params?.officeCode}
        >
          {transactionRender()}
        </AgentWrapper>
      </ScrollView>
      {CheckTransactionName() && (
        <FooterAction
          listAction={[
            {
              name: t('submission:ClearAll'),
              action: () => onClear(),
              isVisible: tabIndex === 0
            },
            {
              name: t('submission:Continue'),
              action: async () => {
                if (!errMessage) {
                  continueForm()
                } else {
                  showToast(errMessage, 'error')
                }
              },
              isSubmissionDisabled: hideBtnContinue,
              isVisible: tabIndex === 0
            },
            {
              name: t('submission:NewRequest'),
              action: () => {
                props.navigation.navigate('AgentSearchScreen')
              },
              isVisible: tabIndex === 1
            },
            {
              name: t('submission:SendRequest'),
              action: async () => {
                const isCheckValidateOtp = validateRequestAuthenForm()
                if (isCheckValidateOtp) {
                  onSubmit(dataForm?.uploadedFilesInfo || undefined)
                } else {
                  showToast('OTP is not valid')
                }
              },
              isSubmissionDisabled: !validateRequestAuthenForm(),
              isHighLightBtn: false,
              isVisible: tabIndex === 1
            }
          ]}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    backgroundColor: '#fff'
  }
})
