import * as React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
// import { assets } from '@pulseops/common'
import { FontAwesome5 } from '@expo/vector-icons'

type Props = {
  navigation: {
    title: string
    navigate: (() => void) | null
  }[]
}

export const BreadCrumb: React.FC<Props> = (props: Props) => {
  return (
    <View style={{ flexDirection: 'row', marginLeft: 30, marginVertical: 20, alignItems: 'center' }}>
      {props.navigation.map((x, i) => {
        return (
          <View style={{ flexDirection: 'row', alignItems: 'center' }} key={`bc-${i}`}>
            {i !== 0 && (
              <Text style={{ marginHorizontal: 10 }}>
                <FontAwesome5 name="angle-right" size={15} color="#BABABA" />
              </Text>
            )}
            {i === props.navigation.length - 1 || x.navigate === null ? (
              <Text style={{ color: '#70777E' }}>{x.title}</Text>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  x.navigate && x.navigate()
                }}
              >
                <Text style={{ color: '#ED1B2E' }}>{x.title}</Text>
              </TouchableOpacity>
            )}
          </View>
        )
      })}
    </View>
  )
}
