import { enumC } from '@mxt/zio/codec'

export enum SubTasksResponse {
  PS_VER = 'PS-VER',
  PS_QC = 'PS-QC',
  PS_SUSPENDED = 'PS-SUSPENDED',

  DS_VER = 'DS-VER',
  DS_QC = 'DS-QC',
  DS_SUSPENDED = 'DS-SUSPENDED',

  CS_VER = 'CS-VER',
  CS_QC = 'CS-QC',
  CS_SUSPENDED = 'CS-SUSPENDED',

  C_VER = 'C-VER',
  C_QC = 'C-QC',
  C_SUSPENDED = 'C-SUSPENDED',

  CH_VER = 'CH-VER',
  CH_QC = 'CH-QC',
  CH_SUSPENDED = 'CH-SUSPENDED',

  UW_VER = 'UW-VER',
  UW_QC = 'PS-UW-QC',
  UW_SUSPENDED = 'UW-SUSPENDED',

  TOTAL = 'TOTAL',
  CL_VER = 'CL-VER',
  CL_SUSPEND = 'CL-SUSPENDED',
  CL_QC = 'CL-QC',
  CL_ADJ = 'CL-ADJ',

  PC_VER = 'PC-VER',
  PC_QC = 'PC-QC',
  PC_WRITEOFF = 'PC-WRITEOFF',
  PC_SUSPENDED = 'PC-SUSPENDED',

  CL_PV = 'CL-PV',

  IDC_VER = 'IDC-VER',
  IDC_QC = 'IDC-QC',
  IDC_SUSPENDED = 'IDC-SUSPENDED'
}
export const SubTasksResponseC = enumC(SubTasksResponse)

export const SupendedTaskList = [
  SubTasksResponse.PS_SUSPENDED,
  SubTasksResponse.CH_SUSPENDED,
  SubTasksResponse.CS_SUSPENDED,
  SubTasksResponse.C_SUSPENDED,
  SubTasksResponse.UW_SUSPENDED,
  SubTasksResponse.CL_SUSPEND,
  SubTasksResponse.PC_SUSPENDED,
  SubTasksResponse.PC_WRITEOFF,
  SubTasksResponse.DS_SUSPENDED,
  SubTasksResponse.IDC_SUSPENDED
]
export const QCTaskList = [
  SubTasksResponse.PS_QC,
  SubTasksResponse.CH_QC,
  SubTasksResponse.CS_QC,
  SubTasksResponse.PC_QC,
  SubTasksResponse.DS_QC,
  SubTasksResponse.UW_QC,
  SubTasksResponse.IDC_QC
]
export const CLTaskList = [SubTasksResponse.CL_VER, SubTasksResponse.CL_ADJ]
