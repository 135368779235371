import React from 'react'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import { AppContext, assets, JournalService } from '@pulseops/common'
import { SelectBox } from './component'
import { JournalsForm } from './transfer-journal-form'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

interface Props {
  disabled: boolean
  form: UseFormReturn<JournalsForm.JournalsData, object>
  policyNum: string
}

const defaultValueUnit = {
  fund: '',
  type: 'A',
  unitBalance: '',
  adjust: ''
}

const useStylesTextField = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;'
    }
  }
}))

export const UnitJournalForm: React.FC<Props> = ({ disabled, form, policyNum }) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const classesTextField = useStylesTextField()

  const fieldJournalUnit = useFieldArray({
    control: form.control,
    name: 'journalUnit'
  })
  const [disableAddRow, setDisableAddRow] = React.useState<boolean>(false)
  const [listFund, setListFund] = React.useState<string[]>([])
  const [listFundBalance, setListFundBalance] = React.useState<JournalService.FundBalanceData[]>([])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    if (isFocused && form.watch('journalUnit')?.length === 0) {
      fieldJournalUnit.append(defaultValueUnit)
    }
    pipe(
      JournalService.getFundBalanceData(policyNum),
      ZIO.map((data) => {
        setListFundBalance(data)
        const datafund: string[] = []
        data.map((item) => {
          datafund.push(item.fundCode)
        })
        setListFund(datafund)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [isFocused])

  // const changeToAmount = (value: string) => {
  //     const valueSplit = value.split('.')
  //     const checkLastValueNegative = value.indexOf('-')
  //     const numberFormatDecimal = value.indexOf('.') !== -1 ? formatNumberWithComma(valueSplit[0].replace('-', '')) + '.' + valueSplit[1]
  //         : formatNumberWithComma(value.replace('-', ''))
  //     const valueRep = value !== '-' ? '-' + numberFormatDecimal : value
  //     const lastValue = checkLastValueNegative !== -1 ? valueRep : numberFormatDecimal
  //     return lastValue
  // }

  return (
    <SafeAreaView style={style.container}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={styleView.backGround}>
              <TableCell style={{ width: '23%' }}>{t('Journal:Fund')}</TableCell>
              <TableCell style={{ width: '23%' }}>{t('Journal:Type')}</TableCell>
              <TableCell style={{ width: '24%' }}>{t('Journal:UnitBalance')}</TableCell>
              <TableCell style={{ width: '24%' }}>{t('Journal:Adjust')}</TableCell>
              <TableCell style={{ width: '6%' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fieldJournalUnit.fields.map((fieldItem, index) => {
              return (
                <TableRow key={`journalsUnit_${fieldItem.id}`}>
                  <TableCell>
                    <Controller
                      key={`fund_${fieldItem.id}`}
                      control={form.control}
                      name={`journalUnit.${index}.fund`}
                      rules={{
                        required: {
                          value: true,
                          message: t('message:MS020001', { field: t('Journal:Fund') })
                        }
                      }}
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) =>
                        disabled ? (
                          <Text style={{ fontSize: 16 }}>{value}</Text>
                        ) : (
                          <SelectBox
                            onChange={(value) => {
                              onChange(value)
                              const fundSelect = listFundBalance.filter((item) => item.fundCode === value)
                              if (fundSelect)
                                form.setValue(`journalUnit.${index}.unitBalance`, fundSelect[0].unitBalance.toString())
                            }}
                            options={listFund}
                            onBlur={onBlur}
                            value={value}
                            errorMessage={error?.message}
                          />
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Text style={{ fontSize: 16 }}>{form.watch(`journalUnit.${index}.type`)}</Text>
                  </TableCell>
                  <TableCell>
                    <Controller
                      key={`unitBalance_${fieldItem.id}`}
                      control={form.control}
                      name={`journalUnit.${index}.unitBalance`}
                      render={({ field: { value } }) => <Text style={{ fontSize: 16 }}>{value ? value : ''}</Text>}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      key={`adjust_${fieldItem.id}`}
                      control={form.control}
                      name={`journalUnit.${index}.adjust`}
                      rules={{
                        required: {
                          value: true,
                          message: t('message:MS040008')
                        },
                        validate: (value) => {
                          const currentBalance = form.getValues(`journalUnit.${index}.unitBalance`)
                          const checkNegative = value.indexOf('-') !== -1
                          const valueAdjust = value ? Number(value.replace('-', '')) : 0
                          const valueBalance = currentBalance ? Number(currentBalance) : 0
                          if ((checkNegative && valueAdjust > valueBalance) || value === '-')
                            return `${t('message:MS040008')}`
                          return true
                        }
                      }}
                      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                        <View>
                          <View style={{ flexDirection: 'row' }}>
                            {disabled ? (
                              <Text style={style.fontSize}>{value}</Text>
                            ) : (
                              <TextField
                                classes={classesTextField}
                                disabled={disabled}
                                size={'small'}
                                variant={'outlined'}
                                value={value ? value : ''}
                                onBlur={onBlur}
                                inputProps={{
                                  maxLength: 20
                                }}
                                onChange={(e) => {
                                  const checkDecimal = e.target.value.indexOf('.') !== -1
                                  const amountChange = e.target.value
                                  if (checkDecimal) {
                                    if (amountChange.match(/^-?\d+[.]?\d{0,5}$/) || !amountChange)
                                      onChange(amountChange)
                                  } else {
                                    if (amountChange === '-' || amountChange.match(/^-?\d*$/) || !amountChange)
                                      onChange(amountChange)
                                  }
                                }}
                              ></TextField>
                            )}
                          </View>
                          {!!error?.message ? (
                            <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{error.message}</Text>
                          ) : null}
                        </View>
                      )}
                    />
                  </TableCell>
                  {!disabled && (
                    <TableCell align="center">
                      <TouchableOpacity
                        style={{ alignItems: 'center' }}
                        onPress={() => {
                          fieldJournalUnit.remove(index)
                          setDisableAddRow(false)
                        }}
                      >
                        <assets.DeleteIcon />
                      </TouchableOpacity>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!disabled && (
        <View style={{ alignItems: 'flex-end' }}>
          <TouchableOpacity
            style={disableAddRow ? style.btnDisabled : style.button}
            disabled={disableAddRow}
            onPress={() => {
              const rowNumbers = form.getValues('journalUnit')?.length
              if (rowNumbers && rowNumbers >= 1) setDisableAddRow(true)
              fieldJournalUnit.append(defaultValueUnit)
            }}
          >
            <Text style={disableAddRow ? style.btnTextDisabled : style.textButton}>{t('Journal:AddNew')}</Text>
          </TouchableOpacity>
        </View>
      )}
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  container: {
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 30
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_4_first: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15
  },
  button: {
    width: 130,
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  labelVND: {
    paddingVertical: 10,
    paddingLeft: 20,
    fontSize: 16
  },
  fontSize: {
    fontSize: 16
  }
})

const styleView = {
  tableCellSelect: {
    width: '100%',
    borderRadius: '6px',
    paddingRight: 20
  },
  tableCellInput: {
    width: '100%'
  },
  backGround: {
    backgroundColor: '#E8E8E8'
  }
}
