import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const ReportECardC = t.type({
  azureUrls: t.array(t.string),
  basic: Maybe(t.string),
  certificateNum: t.number,
  effectiveDate: t.string,
  emails: t.array(t.string),
  employeeKey: Maybe(t.string),
  employeeName: t.string,
  fileNetUrls: t.array(t.string),
  hasEcard: t.boolean,
  hcPlan: t.string,
  isSentCustomer: t.boolean,
  masterContractNo: t.string,
  policyYear: Maybe(t.number),
  processInstanceId: t.string,
  rider: Maybe(t.array(Maybe(t.string))),
  uuid: t.string
})

export type ReportECardC = t.TypeOf<typeof ReportECardC>

export const ExportReportECard = t.type({
  certificateNum: t.number,
  contractAddress: t.string,
  effectiveDate: t.string,
  employeeName: t.string,
  masterContractNo: t.string,
  phoneNumber: t.string,
  policyAdminName: t.string,
  policyHolder: t.string,
  policyYear: t.number
})

export type ExportReportECard = t.TypeOf<typeof ExportReportECard>
