import { CheckBox } from 'react-native-elements'
import * as React from 'react'
import { StyleProp, TextStyle, ViewStyle, View, Text } from 'react-native'
import { ControlProps } from '@pulseops/common'


type Props = ControlProps<boolean> & {
  title?: string
  textStyle?: TextStyle
  disabled?: boolean
  checkBoxStyle?: StyleProp<ViewStyle>
  checkedIcon?: string
  uncheckedIcon?: string
}

export const CheckboxIDC: React.FC<Props> = (props) => {
  const { title, textStyle, onChange, value, checkBoxStyle, errorMessage } = props

  const onBlur = () => {
    if (props.onBlur) {
      props.onBlur()
    }
  }
  return (
    <View>
      <CheckBox
        checkedIcon={props.checkedIcon}
        uncheckedIcon={props.uncheckedIcon}
        activeOpacity={0.7}
        title={title}
        checked={value}
        checkedColor={props.disabled ? '#0000000D' : '#ED1B2E'}
        onPress={() => onChange && onChange(!value)}
        disabled={props.disabled}
        onBlur={onBlur}
        containerStyle={[
          { borderWidth: 0, paddingLeft: 0, marginLeft: 0, backgroundColor: 'transparent' },
          checkBoxStyle
        ]}
        iconType="material-community"
        textStyle={textStyle ? textStyle : { fontWeight: 'normal' }}
        uncheckedColor={props.disabled ? '#ecf0f1' : 'rgb(191, 191, 191)'}
      />
      {errorMessage && (
        <View>
          <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errorMessage}</Text>
        </View>
      )}
    </View>
  )
}
