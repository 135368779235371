import { Maybe } from '@mxt/zio/codec'
import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { withMessage } from 'io-ts-types'

export namespace AmlRequestSubmissionForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  export const AmlRequestSubmission = t.type({
    caseId: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:CaseId') })
    ),
    remark: withMessage(form2.string.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Remark') })
    ),
    suspendType: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:SuspendType') })
    ),
    transactionType: form2.string.optional,
    attachments: pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l && l.length > 0,
        () => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
        'FileList'
      )
    )
  })

  export const codec = AmlRequestSubmission

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
