export namespace StopAplData {
  export interface Detail {
    ptdateAdvance: string
  }

  export interface SubmitData {
    aplDate: string
    reason: string
  }
}
