import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const ClientName = t.type({
  surName: t.string,
  middleName: t.string,
  firstName: t.string,
  name: t.string
})

export const Error = Maybe(
  t.type({
    code: Maybe(t.number),
    message: Maybe(t.string),
    errors: Maybe(
      t.array(
        t.type({
          domain: Maybe(t.string),
          reason: Maybe(t.string),
          message: Maybe(t.string),
          errorCode: Maybe(t.string)
        })
      )
    )
  })
)
