import { ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { OBButton, OBFieldTitle, OBFormat, OBSectionContent } from '../ob-common'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { AppContext, ImgUploadMutiplePC, OBImportFilePermission, form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { OBImportFileService } from '../ob-service'
import { OBImportFileContext } from './OBImportFileContext'

namespace OBUploadFileForm {
  const FileInfo = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  type UploadFileBrandData = {
    readonly MS150004: unique symbol
    readonly OB0098: unique symbol
    readonly OB0060: unique symbol
    readonly XXXMsg: unique symbol
  }

  export type FileInfo = t.TypeOf<typeof FileInfo>
  export type UploadFileBrand = t.Branded<FileInfo[], UploadFileBrandData>

  export const OBUploadFileData = t.type({
    uploadFiles: pipe(
      t.array(FileInfo),
      form2.refine(
        (l): l is UploadFileBrand => l && l.length > 0,
        () => i18next.t('message:MS150004'),
        'MS150004'
      ),
      form2.refine(
        (files): files is UploadFileBrand =>
          files && files.length > 0 && ['Promotion'].includes(files[0].fileName.split('_')[0]),
        () => i18next.t('message:OB0098'),
        'OB0098'
      ),
      form2.refine(
        (files): files is UploadFileBrand =>
          OBFormat.isRightFormatWithYYYYMMDD(files[0].fileName.split('_')[1]) &&
          OBFormat.isValidDate(files[0].fileName.split('_')[1], 'YYYYMMDD') &&
          OBFormat.isEqualWithCurrentDate(files[0].fileName.split('_')[1]),
        () => i18next.t('message:OB0060'),
        'OB0060'
      ),
      form2.refine(
        (files): files is UploadFileBrand => OBFormat.isRightFormatWithXXX(files[0].fileName.split('_')[2]),
        () => i18next.t('message:OB0098'),
        'OB0098'
      )
    )
  })

  export type OBUploadFileData = t.TypeOf<typeof OBUploadFileData>
  export type OBUploadFileDataRaw = t.OutputOf<typeof OBUploadFileData>
}

interface Props {
  permissions: string[]
}
const obImportFileTranslate = 'Outbound:OBImportFile.UploadFile'

export const OBImportPromotionFiles = (props: Props) => {
  const { permissions } = props
  const { t } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const uploadFileForm = form2.useForm(OBUploadFileForm.OBUploadFileData, {
    defaultValues: {
      uploadFiles: []
    }
  })

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const resetForm = () => {
    uploadFileForm.base.reset({
      uploadFiles: []
    })
  }

  const onHandleImportFile = () => {
    if (uploadFileForm.base.formState.isValid) {
      const formData = uploadFileForm.base.getValues()

      pipe(
        OBImportFileService.importPromotionFile(formData.uploadFiles[0].file as Blob),
        ZIO.map((response) => {
          switch (response.code) {
            case 'OB0068': {
              showToast(t('message:OB0068'), 'success')
              resetForm()
              return
            }
            case 'OB0082': {
              showToast(t('message:OB0082'), 'error')
              return
            }
            default: {
              return showToast(t(`message:${response.code}`), 'error')
            }
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <ScrollView style={styles.container}>
      <OBSectionContent>
        <OBFieldTitle
          textStyle={styles.fieldTitle}
          text={t(`${obImportFileTranslate}.ImportFile`).toUpperCase()}
        ></OBFieldTitle>
        <Text style={styles.fieldSubTitle}>
          {t(`${obImportFileTranslate}.ImportFileAttachment`)}{' '}
          <Text style={[styles.fieldSubTitle, styles.requiredText]}>*</Text>
        </Text>
        <Controller
          control={uploadFileForm.base.control}
          name={'uploadFiles'}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <>
              <ImgUploadMutiplePC
                value={value as any[]}
                onChange={(val) => {
                  onChange(val)
                }}
                onBlur={onBlur}
                timeFormat={'DD/MM/YYYY HH:mm'}
                maxSizeFile={2}
                maxTotalFile={2}
                messageFormat={'TaskManagement:MSFormatUploadFileXLSX2MB'}
                validExtensions={['XLS', 'XLSX']}
                messageMaxSize={'message:OB0099'}
                maxNumFile={1}
                takeAPicture={false}
                showFullFileName
                isFileDownload={false}
                errorMessage={'message:OB0075'}
              />
              {error && error.message && <Text style={styles.errorMessageText}>{error.message}</Text>}
            </>
          )}
        />
      </OBSectionContent>
      {permissions.includes(OBImportFilePermission.ImportImportFilePromotion) ? (
        <View style={styles.footer}>
          <OBButton
            text={t(`${obImportFileTranslate}.ImportFile`)}
            onHandleClickEvent={() => onHandleImportFile()}
            isHightLight={uploadFileForm.base.formState.isValid}
            disabled={!uploadFileForm.base.formState.isValid}
          ></OBButton>
        </View>
      ) : null}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  },
  evidenceContainer: {
    backgroundColor: '#F5F5F5'
  },
  fieldTitle: {
    color: '#4F4F4F',
    fontSize: 16,
    marginBottom: 15
  },
  fieldSubTitle: {
    color: '#70777E',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '700'
  },
  requiredText: {
    color: '#ED1B2E'
  },
  footer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 16
  },
  errorMessageText: {
    color: '#ED1B2C',
    fontSize: 11.25
  }
})
