import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { DatePicker, useMobile } from '@pulseops/common'
import * as React from 'react'
import { StyleSheet, Text, View, Platform } from 'react-native'
import { Button } from 'react-native-elements'

const rows = [
  {
    id: 1,
    transactionNumber: '00007',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Cancel From Inception'
  },
  {
    id: 2,
    transactionNumber: '00008',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Special Bonus '
  },
  {
    id: 3,
    transactionNumber: '00009',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Anticipated Endownment Release'
  },
  {
    id: 4,
    transactionNumber: '000010',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Register Death Claim'
  },
  {
    id: 5,
    transactionNumber: '000011',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Register Dependent Supp'
  },
  {
    id: 6,
    transactionNumber: '000012',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Approval WOP & TPD - Claim'
  },
  {
    id: 7,
    transactionNumber: '000013',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Lapsed Reins UnitDeal '
  },
  {
    id: 8,
    transactionNumber: '000014',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Make Paid-Up '
  },
  {
    id: 9,
    transactionNumber: '000015',
    transactionDate: '25/04/2018',
    effectiveDate: '25/04/2018',
    description: 'Go abroad (Jet UW)'
  }
]

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  }
})

export const TransactionHistoryScreen = () => {
  const classes = useStyles()
  const { isWide } = useMobile()

  return (
    <View>
      <Text style={styles.title}>TRANSACTION HISTORY</Text>
      <View style={styles.sectionControlGroup}>
        <View style={styles.sectionControl}>
          <DatePicker label="From date" />
        </View>
        <View style={styles.sectionControl}>
          <DatePicker label="To date" />
        </View>
        <View>
          <Button title="Search" buttonStyle={styles.btnSearch} titleStyle={styles.btnSearchText} />
        </View>
      </View>
      {isWide && Platform.OS === 'web' && (
        <View>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>Transaction number</TableCell>
                  <TableCell>Transaction date</TableCell>
                  <TableCell>Effective date</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.transactionNumber}
                    </TableCell>
                    <TableCell>{row.transactionDate}</TableCell>
                    <TableCell>{row.effectiveDate}</TableCell>
                    <TableCell>{row.description}</TableCell>
                  </TableRow>
                ))}
                <TableRow></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination page={0} rowsPerPage={10} count={9} onPageChange={(e, page) => null} component={View} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  title: {
    color: '#000000',
    fontSize: 16,
    lineHeight: 28,
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  sectionControlGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 30,
    marginBottom: 16,
    borderRadius: 8,
    backgroundColor: '#FAFAFA'
  },
  sectionControl: {
    flex: 1 / 3,
    width: '100%',
    paddingVertical: 6,
    paddingHorizontal: 12,
    marginRight: 25
  },
  btnSearch: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    width: 'auto',
    paddingVertical: 5.5,
    paddingHorizontal: 29
  },
  btnSearchText: {
    fontSize: 15,
    lineHeight: 28
  }
})
