import React from 'react'
import { View } from "react-native"
import { IDCSharedStyles } from '../../IDCSharedStyles'
import { CategoryTabInfo, OBCategoryTab } from '@pulseops/outbound'
import { useTranslation } from 'react-i18next'
import { IDCHistoryTab } from './IDCHistoryTab'

export const IDCRelatedInfo = () => {
  const { t, i18n } = useTranslation()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [categoryTabList, setCategoryTabList] = React.useState<CategoryTabInfo[]>([])

  React.useEffect(() => {
    checkPermissionTabView()
  }, [i18n.language])

  const checkPermissionTabView = () => {
    let tabList: CategoryTabInfo[] = []
    tabList.push({
      label: t('Tab:History'),
      key: '0'
    })
    setCategoryTabList(tabList)
  }

  return (
    <View style={IDCSharedStyles.container}>
      <OBCategoryTab
        dataSource={categoryTabList}
        onChangeTab={(index) => {
          setTabIndex(index)
        }}
        activeTabIndex={tabIndex}
      ></OBCategoryTab>
      {tabIndex === 0 && <IDCHistoryTab></IDCHistoryTab>}
    </View>
  )
}