import { Throwable, ZIO } from '@mxt/zio'
import {
  ContractDetail,
  DatePicker,
  DetailService,
  EditFooterService,
  Form,
  FormService,
  Input,
  SectionHeader,
  Select,
  useAddressForm,
  InputCurrency
} from '@pulseops/business/core'
import { ErrorHandling, form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../../contract-detail-context'
import { ContractInfoForm } from './contract-info-form'

export const ContractInfoEdit = ({ onClose }: { onClose: () => void }) => {
  const [registerOnSave, registerOnClose] = EditFooterService.useFooter()

  registerOnClose(onClose)

  const { t } = useTranslation('business')

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const info: ContractDetail | null = pipe(
    service.state.detail.get,
    ZIO.tap((info) => {
      if (info) {
        const { policyHolder, legalRepresentative } = info
        setValue('policyHolder.businessAddress.city', policyHolder.businessAddress.city)
        setValue('policyHolder.businessAddress.district', policyHolder.businessAddress.district)
        setValue('policyHolder.businessAddress.ward', policyHolder.businessAddress.ward)
        setValue('policyHolder.contactAddress.city', policyHolder.contactAddress.city)
        setValue('policyHolder.contactAddress.district', policyHolder.contactAddress.district)
        setValue('policyHolder.contactAddress.ward', policyHolder.contactAddress.ward)

        setValue('legalRepresentative.permanent.city', legalRepresentative.permanentCity)
        setValue('legalRepresentative.permanent.district', legalRepresentative.permanentDistrict)
        setValue('legalRepresentative.permanent.ward', legalRepresentative.permanentWard)
        setValue('legalRepresentative.residential.city', legalRepresentative.residentialCity)
        setValue('legalRepresentative.residential.district', legalRepresentative.residentialDistrict)
        setValue('legalRepresentative.residential.ward', legalRepresentative.residentialWard)
        setValue('legalRepresentative.oversea.city', legalRepresentative.overseaCity)
        setValue('legalRepresentative.oversea.district', legalRepresentative.overseaDistrict)
        setValue('legalRepresentative.oversea.ward', legalRepresentative.overseaWard)
      }
      return ZIO.unit
    }),
    ErrorHandling.runDidMount()
  )

  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue
    },
    handleSubmit
  } = form2.useForm(ContractInfoForm.codec)

  const countryOptions = pipe(FormService.getCountryOptions, ErrorHandling.runDidMount([]))
  const provinceOptions = pipe(FormService.getProvinceOptions, ErrorHandling.runDidMount([]))

  const businessAddress = useAddressForm(watch, 'policyHolder.businessAddress')
  const contactAddress = useAddressForm(watch, 'policyHolder.contactAddress')

  const permanentAddress = useAddressForm(watch, 'legalRepresentative.permanent')
  const residentialAddress = useAddressForm(watch, 'legalRepresentative.residential')
  const overseaAddress = useAddressForm(watch, 'legalRepresentative.oversea')

  pipe(
    handleSubmit((validated) =>
      pipe(
        info,
        ZIO.fromPredicate(
          (a): a is ContractDetail => a != null,
          () => Throwable('contract info not found')
        ),
        ZIO.flatMap((info) =>
          service.updateInfo({
            ...info,
            ...validated,
            policyHolder: {
              ...info.policyHolder,
              ...validated.policyHolder
            },
            legalRepresentative: {
              ...info.legalRepresentative,
              ...validated.legalRepresentative,
              permanentCity: validated.legalRepresentative.permanent.city,
              permanentDistrict: validated.legalRepresentative.permanent.district,
              permanentWard: validated.legalRepresentative.permanent.ward,
              residentialCity: validated.legalRepresentative.residential.city,
              residentialDistrict: validated.legalRepresentative.residential.district,
              residentialWard: validated.legalRepresentative.residential.ward,
              overseaCity: validated.legalRepresentative.oversea.city,
              overseaDistrict: validated.legalRepresentative.oversea.district,
              overseaWard: validated.legalRepresentative.oversea.ward
            },
            policyAdmin: {
              ...info.policyAdmin,
              ...validated.policyAdmin
            }
          })
        ),
        ZIO.tap(() => service.state.history.clear),
        ErrorHandling.run()
      )
    ),
    registerOnSave
  )

  if (!info) {
    return null
  }

  const { policyHolder, legalRepresentative, policyAdmin } = info

  return (
    <>
      <Form.Group>
        <SectionHeader>{t('general_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Input label={t('proposal_no')} value={info.masterContractNo} readonly />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="submitDate"
              defaultValue={info.submitDate}
              render={({ field }) => (
                <DatePicker
                  label={t('proposal_submitted_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.submitDate?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Input label={t('policy_year')} value={info.policyYear.toString()} />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="firstPolicyEffectiveDate"
              defaultValue={info.firstPolicyEffectiveDate}
              render={({ field }) => (
                <DatePicker
                  label={t('first_if_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.firstPolicyEffectiveDate?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyTerm"
              defaultValue={info.policyTerm.toString()}
              render={({ field }) => (
                <Select
                  options={FormService.termOptions}
                  label={t('contract_term')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyTerm?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="exclusionTerm"
              defaultValue={info.exclusionTerm}
              render={({ field }) => (
                <Input
                  label={t('exclusion_term')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.exclusionTerm?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="holdClaimsBenefitPayout"
              defaultValue={info.holdClaimsBenefitPayout}
              render={({ field }) => (
                <Select
                  options={FormService.yesNoOptions}
                  label={t('hold_claim_payout')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.holdClaimsBenefitPayout?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="premiumPaymentStatus"
              defaultValue={info.premiumPaymentStatus}
              render={({ field }) => (
                <Input
                  label={t('premium_payment_status')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.premiumPaymentStatus?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>
      <Form.Group>
        <SectionHeader>{t('policy_owner_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.policyHolder"
              defaultValue={policyHolder.policyHolder}
              render={({ field }) => (
                <Input
                  label={t('company_org_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.policyHolder?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.address"
              defaultValue={policyHolder.businessAddress.address}
              render={({ field }) => (
                <Input
                  label={t('business_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.address?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.country"
              defaultValue={policyHolder.businessAddress.country}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('business_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.country?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('policyHolder.businessAddress.district', null)
                    setValue('policyHolder.businessAddress.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.district"
              render={({ field }) => (
                <Select
                  disabled={businessAddress.districtOptions.length === 0}
                  options={businessAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('policyHolder.businessAddress.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.ward"
              render={({ field }) => (
                <Select
                  disabled={businessAddress.wardOptions.length === 0}
                  options={businessAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.phoneNumber"
              defaultValue={policyHolder.businessAddress.phoneNumber}
              render={({ field }) => (
                <Input
                  label={t('phone_number')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.phoneNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessAddress.email"
              defaultValue={policyHolder.businessAddress.email}
              render={({ field }) => (
                <Input
                  label={t('email')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessAddress?.email?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.address"
              defaultValue={policyHolder.contactAddress.address}
              render={({ field }) => (
                <Input
                  label={t('contact_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.address?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.country"
              defaultValue={policyHolder.contactAddress.country}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('contact_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.country?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('policyHolder.contactAddress.district', null)
                    setValue('policyHolder.contactAddress.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.district"
              render={({ field }) => (
                <Select
                  disabled={contactAddress.districtOptions.length === 0}
                  options={contactAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('policyHolder.contactAddress.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.ward"
              render={({ field }) => (
                <Select
                  disabled={contactAddress.wardOptions.length === 0}
                  options={contactAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.phoneNumber"
              defaultValue={policyHolder.contactAddress.phoneNumber}
              render={({ field }) => (
                <Input
                  label={t('phone_number')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.phoneNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.contactAddress.email"
              defaultValue={policyHolder.contactAddress.email}
              render={({ field }) => (
                <Input
                  label={t('email')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.contactAddress?.email?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessSector"
              defaultValue={policyHolder.businessSector}
              render={({ field }) => (
                <Input
                  label={t('business_sector')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessSector?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.businessLicense"
              defaultValue={policyHolder.businessLicense}
              render={({ field }) => (
                <Input
                  label={t('business_license')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.businessLicense?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.dateOfRegistration"
              defaultValue={policyHolder.dateOfRegistration}
              render={({ field }) => (
                <DatePicker
                  label={t('business_registration_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.dateOfRegistration?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.placeOfRegistration"
              defaultValue={policyHolder.placeOfRegistration}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('business_registration_place')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.placeOfRegistration?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.capital"
              defaultValue={policyHolder.capital.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('authorized_capital')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.capital?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.taxCode"
              defaultValue={policyHolder.taxCode}
              render={({ field }) => (
                <Input
                  label={t('tax_code')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.taxCode?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyHolder.entityType"
              defaultValue={policyHolder.entityType}
              render={({ field }) => (
                <Select
                  options={FormService.entityOptions}
                  label={t('entity_type')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyHolder?.entityType?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('legal_rep')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.fullName"
              defaultValue={legalRepresentative.fullName}
              render={({ field }) => (
                <Input
                  label={t('full_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.fullName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.position"
              defaultValue={legalRepresentative.position}
              render={({ field }) => (
                <Input
                  label={t('position')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.position?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.idNumber"
              defaultValue={legalRepresentative.idNumber}
              render={({ field }) => (
                <Input
                  label={t('id_card_no')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.idNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.dateOfIssue"
              defaultValue={legalRepresentative.dateOfIssue}
              render={({ field }) => (
                <DatePicker
                  label={t('issue_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.dateOfIssue?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.placeOfIssue"
              defaultValue={legalRepresentative.placeOfIssue}
              render={({ field }) => (
                <Input
                  label={t('issue_place')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.placeOfIssue?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.dateOfBirth"
              defaultValue={legalRepresentative.dateOfBirth}
              render={({ field }) => (
                <DatePicker
                  label={t('dob')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.dateOfBirth?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.placeOfBirth"
              defaultValue={legalRepresentative.placeOfBirth}
              render={({ field }) => (
                <Input
                  label={t('place_of_birth')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.placeOfBirth?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.gender"
              defaultValue={legalRepresentative.gender}
              render={({ field }) => (
                <Select
                  options={FormService.genderOptions}
                  label={t('gender')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.gender?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.nationality"
              defaultValue={legalRepresentative.nationality}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('nationality')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.nationality?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.otherNationality"
              defaultValue={legalRepresentative.otherNationality}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('other_nationality')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.otherNationality?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.visa"
              defaultValue={legalRepresentative.visa}
              render={({ field }) => (
                <Input
                  label={t('visa')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.visa?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.phoneNumber"
              defaultValue={legalRepresentative.phoneNumber}
              render={({ field }) => (
                <Input
                  label={t('phone_number')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.phoneNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.email"
              defaultValue={legalRepresentative.email}
              render={({ field }) => (
                <Input
                  label={t('email')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.email?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.permanentAddress"
              defaultValue={legalRepresentative.permanentAddress}
              render={({ field }) => (
                <Input
                  label={t('perm_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.permanentAddress?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.country"
              defaultValue={legalRepresentative.country}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('perm_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.country?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.permanent.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('legalRepresentative.permanent.district', null)
                    setValue('legalRepresentative.permanent.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.permanent?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.permanent.district"
              render={({ field }) => (
                <Select
                  disabled={permanentAddress.districtOptions.length === 0}
                  options={permanentAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('legalRepresentative.permanent.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.permanent?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.permanent.ward"
              render={({ field }) => (
                <Select
                  disabled={permanentAddress.wardOptions.length === 0}
                  options={permanentAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.permanent?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.residentAddress"
              defaultValue={legalRepresentative.residentAddress}
              render={({ field }) => (
                <Input
                  label={t('curr_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.residentAddress?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.country1"
              defaultValue={legalRepresentative.country1}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('curr_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.country1?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.residential.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('legalRepresentative.residential.district', null)
                    setValue('legalRepresentative.residential.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.residential?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.residential.district"
              render={({ field }) => (
                <Select
                  disabled={residentialAddress.districtOptions.length === 0}
                  options={residentialAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('legalRepresentative.residential.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.residential?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.residential.ward"
              render={({ field }) => (
                <Select
                  disabled={residentialAddress.wardOptions.length === 0}
                  options={residentialAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.residential?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.overseaAddress"
              defaultValue={legalRepresentative.overseaAddress}
              render={({ field }) => (
                <Input
                  label={t('oversea_address')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.overseaAddress?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.country2"
              defaultValue={legalRepresentative.country2}
              render={({ field }) => (
                <Select
                  options={countryOptions}
                  label={t('oversea_address_country')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.country2?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.oversea.city"
              render={({ field }) => (
                <Select
                  options={provinceOptions}
                  label={t('business_address_province')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('legalRepresentative.oversea.district', null)
                    setValue('legalRepresentative.oversea.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.oversea?.city?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.oversea.district"
              render={({ field }) => (
                <Select
                  disabled={overseaAddress.districtOptions.length === 0}
                  options={overseaAddress.districtOptions}
                  label={t('business_address_district')}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('legalRepresentative.oversea.ward', null)
                  }}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.oversea?.district?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.oversea.ward"
              render={({ field }) => (
                <Select
                  disabled={overseaAddress.wardOptions.length === 0}
                  options={overseaAddress.wardOptions}
                  label={t('business_address_ward')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.oversea?.ward?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.hasstockexchange"
              defaultValue={legalRepresentative.hasstockexchange}
              render={({ field }) => (
                <Select
                  options={FormService.yesNoOptions}
                  label={t('has_stock')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.hasstockexchange?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="legalRepresentative.hasusshareholder"
              defaultValue={legalRepresentative.hasusshareholder}
              render={({ field }) => (
                <Select
                  options={FormService.yesNoOptions}
                  label={t('has_us_shareholder')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.legalRepresentative?.hasusshareholder?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('contact_rep')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyAdmin.fullName"
              defaultValue={policyAdmin.fullName}
              render={({ field }) => (
                <Input
                  label={t('full_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyAdmin?.fullName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyAdmin.position"
              defaultValue={policyAdmin.position}
              render={({ field }) => (
                <Input
                  label={t('position')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyAdmin?.position?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyAdmin.idNumber"
              defaultValue={policyAdmin.idNumber}
              render={({ field }) => (
                <Input
                  label={t('id_card_no')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyAdmin?.idNumber?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="policyAdmin.phoneNumber"
              defaultValue={policyAdmin.phoneNumber}
              render={({ field }) => (
                <Input
                  label={t('phone_number')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyAdmin?.phoneNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="policyAdmin.email"
              defaultValue={policyAdmin.email}
              render={({ field }) => (
                <Input
                  label={t('email')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.policyAdmin?.email?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('la_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="totalEmployees"
              defaultValue={info.totalEmployees.toString()}
              render={({ field }) => (
                <Input
                  label={t('employee_count')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.totalEmployees?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="totalEmployeesInsured"
              defaultValue={info.totalEmployeesInsured.toString()}
              render={({ field }) => (
                <Input
                  label={t('employee_la_count')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.totalEmployeesInsured?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('group_policy_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="proposedEffectiveDate"
              defaultValue={info.proposedEffectiveDate}
              render={({ field }) => (
                <DatePicker
                  label={t('proposed_if_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.proposedEffectiveDate?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="modeOfPayment"
              defaultValue={info.modeOfPayment}
              render={({ field }) => (
                <Select
                  options={FormService.paymentModeOptions}
                  label={t('payment_period')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.modeOfPayment?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="totalAnnualPremium"
              defaultValue={info.totalAnnualPremium.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('total_annual_premium')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.totalAnnualPremium?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="discountPromotion"
              defaultValue={info.discountPromotion}
              render={({ field }) => (
                <Input
                  label={t('discount')}
                  {...form2.registerPercent(field)}
                  errorMessage={errors?.discountPromotion?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="netAnnualPremium"
              defaultValue={info.netAnnualPremium?.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('net_premium')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.netAnnualPremium?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="insufficientPremium"
              defaultValue={info.insufficientPremium?.toString()}
              render={({ field }) => (
                <InputCurrency
                  label={t('insufficient_premium')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.insufficientPremium?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('curr_policy_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="previousCurrentInsurerName"
              defaultValue={info.previousCurrentInsurerName}
              render={({ field }) => (
                <Input
                  label={t('curr_insurance_company')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.previousCurrentInsurerName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="groupPolicyNumber"
              defaultValue={info.groupPolicyNumber}
              render={({ field }) => (
                <Input
                  label={t('policy_no')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.groupPolicyNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="groupPolicyEffectiveDate"
              defaultValue={info.groupPolicyEffectiveDate}
              render={({ field }) => (
                <DatePicker
                  label={t('if_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.groupPolicyEffectiveDate?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('bank_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="bankName"
              defaultValue={info.bankName}
              render={({ field }) => (
                <Input
                  label={t('bank_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.bankName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="bankBranch"
              defaultValue={info.bankBranch}
              render={({ field }) => (
                <Input
                  label={t('branch_name')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.bankBranch?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="accountNumber"
              defaultValue={info.accountNumber}
              render={({ field }) => (
                <Input
                  label={t('account_no')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.accountNumber?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="accountName"
              defaultValue={info.accountName}
              render={({ field }) => (
                <Input
                  label={t('account_holder')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.accountName?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <SectionHeader>{t('other_info')}</SectionHeader>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="establishedDate"
              defaultValue={info.establishedDate}
              render={({ field }) => (
                <DatePicker
                  label={t('created_date')}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.establishedDate?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col />
          <Form.Col />
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="agentNumber1"
              defaultValue={info.agentNumber1}
              render={({ field }) => (
                <Input
                  label={t('agent_code_1')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.agentNumber1?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="agentName1"
              defaultValue={info.agentName1}
              render={({ field }) => (
                <Input
                  label={t('agent_name_1')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.agentName1?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="commissionRate1"
              defaultValue={info.commissionRate1}
              render={({ field }) => (
                <Input
                  label={t('comm_ratio')}
                  {...form2.registerPercent(field)}
                  errorMessage={errors?.commissionRate1?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
        <Form.Row>
          <Form.Col>
            <Controller
              control={control}
              name="agentNumber2"
              defaultValue={info.agentNumber2}
              render={({ field }) => (
                <Input
                  label={t('agent_code_2')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.agentNumber2?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="agentName2"
              defaultValue={info.agentName2}
              render={({ field }) => (
                <Input
                  label={t('agent_name_2')}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                  onBlur={field.onBlur}
                  errorMessage={errors?.agentName2?.message}
                />
              )}
            />
          </Form.Col>
          <Form.Col>
            <Controller
              control={control}
              name="commissionRate2"
              defaultValue={info.commissionRate2}
              render={({ field }) => (
                <Input
                  label={t('comm_ratio')}
                  {...form2.registerPercent(field)}
                  errorMessage={errors?.commissionRate2?.message}
                />
              )}
            />
          </Form.Col>
        </Form.Row>
      </Form.Group>
    </>
  )
}
