import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
export namespace PaidUpForm {
  export const codec = t.type({
    reason: form2.string.optional,
    requestPaidUpPeriod: form2.string.optional
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
