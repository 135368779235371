import { CashOut } from './CashOutOption'

export namespace PruKid369Model {
  export interface Detail {
    prukidAmount: number
  }

  export interface DetailVerify {
    prukidAmount: number
    principalAmount: number
    interestAmount: number
  }

  export interface AccessData {
    checkerCode: boolean
    checkerBalance: boolean
  }
  export interface SubmitData {
    policy: {
      policyNo: string
    }
    attributesExt: {
      TOTAL_PAYOUT_AMOUNT: number
      PRU_KID_AMOUNT: number
    }
    // cashOutOptions: Array<CashOutOptions>
    cashOutOptions: Array<CashOut.Option>
  }

  export interface CashOutOptions {
    type: string
    paymentOption: PaymentOption
    bankAccount: BankAccount | null
    policy: Policy
    // cashAtCounter: CashAtCounter | null
    // payAtBank: PayAtBank | null
    // clientInfo: Payee | null | undefined
    attributesExt:
      | {
          PVA_CASH_OUT_OPTION_INFO: OfficeInfo | null | undefined
          PAYEE_INFO: Payee | null | undefined
        }
      | null
      | undefined
  }

  export interface Payee {
    paymentMethod: string
    isNewClient: boolean
    isPayeeNotPO: boolean
    dob: string
    gender: string
    phone: string
    email: string
    salutation: string
    married: string
    occupation: string
    relationshipWithPO: string
    nationality: string
    address: string
    nationality2: string
    foreignAddress: string
    foreignCountry: string
    residenceCountry: string
    name: string
    idNumber: string
    dod: string
    payeeName: string
    issuedDate: string
    issuedBy: string
    surName: string
    giveName: string
    city: string
    district: string
    ward: string
    street: string | null | undefined
  }
  export interface PaymentOption {
    currency: string
    amount: string
  }
  export interface CashAtCounter {
    gaOffice: string
    payeeName: string
    idNumber: string
  }
  export interface PayAtBank {
    payeeName: string
    idNumber: string
    issueDate: string
    issueBy: string
    bank: string
    bankName: string
    branch: string
    branchName: string
  }
  export interface Policy {
    policyNo: string
    totalPremium: string
    status: string
  }

  export interface BankAccount {
    accountName?: string
    accountNo?: string
    bankName?: string
    branchCode?: string
    bankCode?: string
    branchName?: string
  }

  export interface OfficeInfo {
    officeCode: string
    officeType: string
  }
}
