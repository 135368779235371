import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, View } from 'react-native'
import { IBGeneralTable } from '../../../common'
import { useLoading } from '@mxt/zio-react'
import { DocumentsClaimData, IBService } from '../../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'

interface Props {
  claimDocList: DocumentsClaimData
  setClaimDocList: (data: DocumentsClaimData) => void
  claimNumber: string
}

export const IBClaimDocuments = ({ claimDocList, setClaimDocList, claimNumber }: Props) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  React.useEffect(() => {
    if(claimNumber && claimDocList.length === 0) {
      pipe(
        IBService.getDocsClaim(claimNumber),
        ZIO.map((res) => {
          res && setClaimDocList(res.map((i) => ({
            ...i,
            firstReceivedDate: i.firstReceivedDate ? moment(i.firstReceivedDate, 'DD/MM/YYYY').format('YYYYMMDD') : '',
            lastReceivedDate: i.lastReceivedDate ? moment(i.lastReceivedDate, 'DD/MM/YYYY').format('YYYYMMDD') : ''

          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [claimNumber])

  const dataTable = [
    {
      label: t('Code'),
      field: 'code'
    },
    {
      label: t('NameDoc'),
      field: 'name'
    },
    {
      label: t('NumberCopies'),
      field: 'numberOfCopies'
    },
    {
      label: t('FirstReceivedDate'),
      field: 'firstReceivedDate',
      format: 'date'
    },
    {
      label: t('LastReceivedDate'),
      field: 'lastReceivedDate',
      format: 'date'
    },
    {
      label: t('RemarkClaim'),
      field: 'remark'
    },
    // {
    //   label: t('Image'),
    //   field: 'image'
    // }
  ]

  return (
    <View>
       {loading ? (
        <View>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <IBGeneralTable
          dataTable={dataTable}
          data={claimDocList ?? []}
          loading={loading}
        >
        </IBGeneralTable>
      )}
    </View>
  )
}