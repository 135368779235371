import React from 'react'
import { StyleSheet, View } from 'react-native'
import { OBFieldDescription, OBFieldTitle } from './OBSectionContent'
import { assets } from '@pulseops/common'
import { TouchableOpacity } from 'react-native-gesture-handler'

type OBPanelProps = {
  paneltitle?: string
  addedCommentDate?: string
}

export const OBPanel: React.FC<OBPanelProps> = (props) => {
  const { paneltitle, addedCommentDate, children } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  return (
    <View style={panelStyles.container}>
      <View style={panelStyles.headerContainer}>
        <View style={panelStyles.headerTextContent}>
          {paneltitle && <OBFieldDescription style={panelStyles.headerTextContent1} textStyle={{ fontWeight: '600' }} text={paneltitle}></OBFieldDescription>}
          {addedCommentDate && <OBFieldTitle style={panelStyles.headerTextContent2} text={`added a comment - ${addedCommentDate}`} textStyle={panelStyles.headerText2}></OBFieldTitle>}
        </View>
        <TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
          <View>
            {isOpen ? <assets.OBPanelCollapse /> : <assets.OBPanelExtend />}
          </View>
        </TouchableOpacity>
      </View>
      {isOpen && (
        <View style={panelStyles.content}>
          {children}
        </View>
      )}
    </View>
  )
}
const panelStyles = StyleSheet.create({
  container: {
    display: 'flex',
    padding: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#FAFAFA',
    marginBottom: 24
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: 24
  },
  headerTextContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerTextContent1: {
    marginRight: 16
  },
  headerTextContent2: {
    paddingTop: 1
  },
  headerText2: {
    fontWeight: '400',
    fontSize: 15
  }
})