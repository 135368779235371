import * as React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { CashoutStackParamList } from './CashoutStackParamList'
import { CashOutHistoryList } from './cashout-history-list'
import { CashOutScreen } from './cashout-screen'

const Stack = createStackNavigator<CashoutStackParamList>()

export const CashoutStack = () => {
  return (
    <Stack.Navigator initialRouteName="CashOutHistoryList" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="CashOutHistoryList" component={CashOutHistoryList} />
      <Stack.Screen name="CashOutScreen" component={CashOutScreen} />
    </Stack.Navigator>
  )
}
