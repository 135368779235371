import { pipe } from 'fp-ts/lib/function'
import randomBytes from 'randombytes'
import { Moment } from 'moment'
import { PayoutMethods } from '../service/model/task-detail/common/payout'
import { removeAbundantSpacesInText } from '../service'

export function exportCSV(fileName: string, rows: string[][]) {
  pipe(
    rows
      .map((e) => e.map((cell) => '"' + (cell ? cell.toString() : '').replace(/"/g, '""') + '"').join(','))
      .join('\n'),
    (content) => {
      const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(content)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${fileName}.csv`)
      document.body.appendChild(link)

      link.click()
    }
  )
}

export const downloadURI = (uri: string, name: string) => {
  const link = document.createElement('a')
  link.download = name
  link.target = '_blank'
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const PayoutChecker = (val: PayoutMethods) => (checkee: string) => {
  return val === checkee
}

export const convertUnicodeToViqr = (str: string) => {
  if (str == null || str.length == 0) {
    return str;
  }

  var VIQR_char = [ "y~", "Y~", "y?", "Y?", "y.", "Y.", "y`", "Y`", "u+.", "U+.", "u+~", "U+~", "u+?", "U+?",
      "u+`", "U+`", "u+'", "U+'", "u?", "U?", "u.", "U.", "o+.", "O+.", "o+~", "O+~", "o+?", "O+?", "o+`",
      "O+`", "o+'", "O+'", "o^.", "O^.", "o^~", "O^~", "o^?", "O^?", "o^`", "O^`", "o^'", "O^'", "o?", "O?",
      "o.", "O.", "i.", "I.", "i?", "I?", "e^.", "E^.", "e^~", "E^~", "e^?", "E^?", "e^`", "E^`", "e^'",
      "E^'", "e~", "E~", "e?", "E?", "e.", "E.", "a(.", "A(.", "a(~", "A(~", "a(?", "A(?", "a(`", "A(`",
      "a('", "A('", "a^.", "A^.", "a^~", "A^~", "a^?", "A^?", "a^`", "A^`", "a^'", "A^'", "a?", "A?", "a.",
      "A.", "u+", "U+", "o+", "O+", "u~", "U~", "i~", "I~", "dd", "a(", "A(", "y'", "u'", "u`", "o~", "o^",
      "o'", "o`", "i'", "i`", "e^", "e'", "e`", "a~", "a^", "a'", "a`", "Y'", "U'", "U`", "O~", "O^", "O'",
      "O`", "DD", "I'", "I`", "E^", "E'", "E`", "A~", "A^", "A'", "A`" ];
  
  var Unicode_char = [ "\u1EF9", "\u1EF8", "\u1EF7", "\u1EF6", "\u1EF5", "\u1EF4", "\u1EF3", "\u1EF2",
      "\u1EF1", "\u1EF0", "\u1EEF", "\u1EEE", "\u1EED", "\u1EEC", "\u1EEB", "\u1EEA", "\u1EE9", "\u1EE8",
      "\u1EE7", "\u1EE6", "\u1EE5", "\u1EE4", "\u1EE3", "\u1EE2", "\u1EE1", "\u1EE0", "\u1EDF", "\u1EDE",
      "\u1EDD", "\u1EDC", "\u1EDB", "\u1EDA", "\u1ED9", "\u1ED8", "\u1ED7", "\u1ED6", "\u1ED5", "\u1ED4",
      "\u1ED3", "\u1ED2", "\u1ED1", "\u1ED0", "\u1ECF", "\u1ECE", "\u1ECD", "\u1ECC", "\u1ECB", "\u1ECA",
      "\u1EC9", "\u1EC8", "\u1EC7", "\u1EC6", "\u1EC5", "\u1EC4", "\u1EC3", "\u1EC2", "\u1EC1", "\u1EC0",
      "\u1EBF", "\u1EBE", "\u1EBD", "\u1EBC", "\u1EBB", "\u1EBA", "\u1EB9", "\u1EB8", "\u1EB7", "\u1EB6",
      "\u1EB5", "\u1EB4", "\u1EB3", "\u1EB2", "\u1EB1", "\u1EB0", "\u1EAF", "\u1EAE", "\u1EAD", "\u1EAC",
      "\u1EAB", "\u1EAA", "\u1EA9", "\u1EA8", "\u1EA7", "\u1EA6", "\u1EA5", "\u1EA4", "\u1EA3", "\u1EA2",
      "\u1EA1", "\u1EA0", "\u01B0", "\u01AF", "\u01A1", "\u01A0", "\u0169", "\u0168", "\u0129", "\u0128",
      "\u0111", "\u0103", "\u0102", "\u00FD", "\u00FA", "\u00F9", "\u00F5", "\u00F4", "\u00F3", "\u00F2",
      "\u00ED", "\u00EC", "\u00EA", "\u00E9", "\u00E8", "\u00E3", "\u00E2", "\u00E1", "\u00E0", "\u00DD",
      "\u00DA", "\u00D9", "\u00D5", "\u00D4", "\u00D3", "\u00D2", "\u0110", "\u00CD", "\u00CC", "\u00CA",
      "\u00C9", "\u00C8", "\u00C3", "\u00C2", "\u00C1", "\u00C0"];

  str = replaceArrayString(str, Unicode_char, VIQR_char);
  return str;
}

export const replaceArrayString = (text: string, patternArrayString: string [], replaceArrayString: string [])=> {
  var startIndex=0, foundIndex = 0;
  
  for (var i = 0; i < patternArrayString.length; i++) {
    var result = '';
    
    startIndex = 0;
    // Look for a pattern to replaceArrayString
    while ((foundIndex = text.indexOf(patternArrayString[i], startIndex)) >= 0) {
      result = result.concat(text.substring(startIndex, foundIndex));
      result =  result.concat(replaceArrayString[i]);
      startIndex = foundIndex + patternArrayString[i].length;
    }
    result = result.concat(text.substring(startIndex));
    text = result;
  }
  return text;
}

export function convertViToSearchable(str: string, toUpperCase = false) {
  str = str.toLowerCase()
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ề|ê|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ô|ò|ó|ọ|ỏ|õ|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư

  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return toUpperCase ? str.toUpperCase() : str
}
export function convertSEABillingChange(str: string, toUpperCase = false) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ề|ê|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ô|ò|ó|ọ|ỏ|õ|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư

  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return toUpperCase ? str.toUpperCase() : str
}
export const getAbsoluteMonths = (momentDate: Moment) => {
  const months = Number(momentDate.format('MM'))
  const years = Number(momentDate.format('YYYY'))
  return months + years * 12
}

export const getDiffMonths = (start: Moment, end: Moment) => {
  return getAbsoluteMonths(end) - getAbsoluteMonths(start)
}

export const isCasualLabor = (): boolean => {
  const isCasualLabor = localStorage.getItem('isCasualLabor') === 'true'
  return isCasualLabor
}

export const randowDefaultFileName = (fileName: string) => {
  const filterFileName = removeAbundantSpacesInText(fileName) // remove abundant space in file name
  let newRandomName =
    filterFileName.length <= 3
      ? pipe(
          randomBytes(4).toString('base64').slice(0, 4),
          (str) => str.replace(/\+/g, '-').replace(/\//g, '_'),
          (str) => 'file_' + str
        )
      : filterFileName.replace(/\s+/g, '_')
  return newRandomName
}

export const customFileNameByLength = (fileName: string) => {
  const filterFileName = removeAbundantSpacesInText(fileName) // remove abundant space in file name
  let newRandomName =
    filterFileName.length <= 3
      ? pipe(
          randomBytes(4).toString('base64').slice(0, 4),
          (str) => str.replace(/\+/g, '').replace(/\//g, '').replace(/\s+/g, ''),
          (str) => 'file' + str
        )
      : filterFileName.replace(/\s+/g, '')
  return newRandomName
}

export const replaceSpecialCharactersInFileName = (fileName: string) => {
  const noViFileName = convertViToSearchable(fileName)
  const filterName = noViFileName.replace(/[`~!@#$%^&*|+\=?;:'",.<>\-\_\{\}\[\]\\\/]/gi, '')
  const customedName = customFileNameByLength(filterName)
  return customedName
}
export const replaceFileNameSEABillingChange = (fileName: string) => {
  const noViFileName = convertSEABillingChange(fileName)
  const filterName = noViFileName.replace(/[`~!@#$%^&*|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '')
  const customedName = randowDefaultFileName(filterName)
  return customedName
}
export const convertToAmount = (amountNumber: number): string => {
  const amount = (amountNumber + '.').replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return amount.split('.')[0]
}
