import {
  FieldList,
  Panel,
  PayoutChecker,
  PayoutPopup,
  PulseOpsFormat,
  TaskDetail,
  Checkbox,
  TransactionType,
  getPayeeNotPOData,
  TaskDetailApi
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { PayoutMethod } from './common'
import { Title, InfoViewList } from '../common'
import { format } from 'date-fns'

export const PrucashPayment = ({ detail }: { detail: TaskDetail.CashOutPrucash }) => {
  const { t } = useTranslation()

  const fields = [
    {
      label: t('Prucash:TotalPrucashBenefit'),
      value: detail.totalCashBenefits ? PulseOpsFormat.thousandSepartor(detail.totalCashBenefits) : '0',
      suffix: 'VND'
    },
    {
      label: t('Payout:NotPaidPremium'),
      value: detail.notPaidPremium ? PulseOpsFormat.thousandSepartor(detail.notPaidPremium) : '0',
      suffix: 'VND'
    },
    {
      label: t('Prucash:LoanOverLimit'),
      value: detail.loanExceed ? PulseOpsFormat.thousandSepartor(detail.loanExceed) : '0',
      suffix: 'VND'
    },
    {
      label: t('Payout:NetTotalCashBenefits'),
      value: detail.netTotalCashBenefits ? PulseOpsFormat.thousandSepartor(detail.netTotalCashBenefits) : '0',
      suffix: 'VND'
    }
  ]

  const cashlessMethod = detail.methods.find(
    (x) => Object.values(PayoutPopup.CashlessMethod).findIndex((y) => y == x.method) >= 0
  )?.method
  const payoutData = {
    payPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYPREMIUM)(x.method)) >= 0,
    repayLoan: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.PAYLOAN)(x.method)) >= 0,
    otherPremium: detail.methods.findIndex((x) => PayoutChecker(PayoutPopup.PayoutMethods.OTHER)(x.method)) >= 0,
    cashless: !!cashlessMethod,
    cashlessMethod: cashlessMethod as PayoutPopup.CashlessMethod,
    totalPayoutAmount: PulseOpsFormat.thousandSepartor(detail?.totalAmount),
    payouts: detail?.methods.map((x) => ({
      payoutMethod: x.method,
      detail: x.detail,
      amount: PulseOpsFormat.thousandSepartor(x.amount),
      status: x.status,
      note: x.note || '-'
    }))
  }

  const viewPayeeData = React.useMemo(() => {
    const viewData =
      detail && detail.cashOutList && detail.cashOutList.length > 0
        ? getPayeeNotPOData(detail.cashOutList as TaskDetailApi.PaymentData.CashOutList, detail.branchName ?? '')
        : undefined
    return viewData
  }, [detail.cashOutList])

  const formatPODate = (dateStr: string) => {
    if (!dateStr) {
      return '-'
    }

    if (detail.tag === TransactionType.PRUCASH_PAYMENT) {
      return dateStr
    }

    return format(new Date(dateStr), 'dd/MM/yyyy')
  }

  return (
    <View>
      <Panel titleOutline={t('Prucash:info')} isExand={false} allowToggle={false}>
        <FieldList dataSource={fields} />
      </Panel>
      <View style={{ marginBottom: 40, marginTop: 20 }}>
        <Checkbox
          title={t('requestInfo:POLICY_OWNER_PASSED_AWAY')}
          textStyle={styles.sectionInfoHeaderTitle}
          value={detail.inheritanceFlag}
          disabled
        />
        {detail.inheritanceFlag && (
          <>
            <Title title={t('requestInfo:INHERITANCE_INFOMATION')} />
            <Panel key={0} isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
              <InfoViewList
                dataSource={[
                  {
                    label: t('requestInfo:PODeathDate'),
                    value: formatPODate(detail.deathDate)
                  }
                ]}
              />
            </Panel>
          </>
        )}
      </View>
      <PayoutMethod payeeData={viewPayeeData} payoutMethodData={payoutData}></PayoutMethod>
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfoHeaderTitle: {
    color: '#000',
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  }
})
