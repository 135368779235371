import { LinearProgress } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  capFisrtLetterEachWord,
  ErrorHandling,
  form2,
  Input,
  PayoutPopup,
  sharedStyle,
  SubmissionService,
  PartialSurrenderService,
  TransactionType,
  PulseOpsFormat,
  CashOut,
  AppContext,
  // StorageBlob,
  GeneralService,
  TaskType
} from '@pulseops/common'
import { Error } from '@pulseops/submission/common'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RequestAuthenticateData } from '../request-authen'
import { PolicyServiceProps, UploadedFilesInfo } from './policy-service-props'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../payout-method'
import { useLoading } from '@mxt/zio-react'
const FormData = t.type({
  currentSumAssured: form2.string.optional,
  newSumAssured: form2.string.requiredM(() =>
    i18next.t('message:MS020001', { field: i18next.t('submission:NewSumAssured') })
  ),
  advancePremium: form2.string.optional,
  suspenseAmount: form2.string.optional,
  loanOverLimit: form2.string.required,
  estimatedPaidSurrender: form2.string.optional,
  payout: pipe(
    t.array(PayoutPopup.SummaryCodec),
    form2.refine(
      (arr): arr is PayoutPopup.PayoutSummaryArray => arr && arr.length > 0,
      () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
      'EmptyArray'
    ),
    form2.refine(
      (arr): arr is PayoutPopup.PayoutSummaryArray =>
        arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0,
      () => i18next.t('message:MS050241'),
      'TotalAmountLessThanZero'
    )
  )
})
type FormData = t.TypeOf<typeof FormData>
type FormDataRaw = t.OutputOf<typeof FormData>

type SubmitData = {
  policy: {
    policyNo: string
  }
  attributesExt: {
    CURRENT_SUM_ASSURED: string
    NEW_SUM_ASSURED: string
    ADVANCE_PREMIUM: string
    SUSPENSE_AMOUNT: string
    LOAN_OVER_LIMIT: string
    ESTIMATED_PAID_SURRENDER: string
  }
  cashOutOptions: Array<CashOut.Option>
}

export const PartialSurrender = (props: PolicyServiceProps<SubmitData>) => {
  const { t } = useTranslation()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)
  const [isLoading, bindLoader] = useLoading(false)

  const policyNumber = props.policyNumber!
  const officeCode = props.officeCode
  const defaultValues: FormDataRaw = {
    currentSumAssured: '0',
    newSumAssured: '',
    advancePremium: '0',
    suspenseAmount: '0',
    loanOverLimit: '0',
    estimatedPaidSurrender: '0',
    payout: []
  }

  const { base, handleSubmit } = form2.useForm(FormData, { defaultValues })

  const data = pipe(
    ZIO.zipPar(
      PartialSurrenderService.getDetail(policyNumber),
      SubmissionService.getTraditionalPartSurrender(policyNumber, 0, 0, 'INITIALIZE')
    ),
    ZIO.map(([{ advancePremium, suspenseAmount }, data]) => {
      if (!data.hasError) {
        const { policyLoan, sumAssured, surrenderAmount } = data

        base.setValue('advancePremium', advancePremium.toString())
        base.setValue('suspenseAmount', suspenseAmount.toString())
        base.setValue('currentSumAssured', sumAssured.toString())

        return { advancePremium, suspenseAmount, policyLoan, sumAssured, surrenderAmount }
      }
      return null
    }),
    ErrorHandling.runDidMount(null)
  )

  const newSumAssured = base.watch('newSumAssured')
  const estimatedPaidSurrender = base.watch('estimatedPaidSurrender')

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const onBlurNewSumAssured = () => {
    if (data && newSumAssured) {
      pipe(
        showGlobalLoading(true),
        () => SubmissionService.getTraditionalPartSurrender(policyNumber, Number(newSumAssured), 0, 'enquiry'),
        ZIO.map((data) => {
          if (!data.hasError) {
            const { surrenderAmount } = data

            base.setValue('estimatedPaidSurrender', surrenderAmount.toString())

            updateLoanOverLimit(Number(newSumAssured), data.policyLoan, data.sumAssured, data.surrenderAmount)
          } else {
            base.setError('newSumAssured', {
              message: t('message:MS050036')
            })
          }
        }),
        ZIO.tapBoth(
          () => ZIO.succeed(showGlobalLoading(false)),
          () => ZIO.succeed(showGlobalLoading(false))
        ),
        ErrorHandling.run()
      )
    }
  }

  const updateLoanOverLimit = (
    newSumAssured: number,
    policyLoan: number,
    sumAssured: number,
    surrenderAmount: number
  ) => {
    let loanOverLimit =
      policyLoan - (0.8 * surrenderAmount - (surrenderAmount * (sumAssured - newSumAssured)) / sumAssured)
    loanOverLimit = isFinite(loanOverLimit) ? Math.floor(loanOverLimit) : 0
    loanOverLimit = loanOverLimit < 0 ? 0 : loanOverLimit

    base.setValue('loanOverLimit', loanOverLimit.toString())
  }

  const getUploadedFilesInfo = (formData: FormDataRaw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(formData.payout),
      transactionType: TransactionType.PARTIAL_SURRENDER,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  props.initSubmission({
    validate: async (isContinue) => {
      const _form = await handleSubmit(() => undefined)()
      if (E.isRight(_form) && data) {
        const form = _form.right
        // if (!isContinue) {
        //   const payoutDocuments = getPayoutDocuments(base.getValues())
        //   uploadedFiles = await pipe(
        //     GeneralService.getMetaForPayoutDocuments(
        //       payoutDocuments,
        //       TransactionType.PARTIAL_SURRENDER,
        //       'DPS01',
        //       policyNumber,
        //       props.officeCode ?? ''
        //     ),
        //     ZIO.flatMap((metaDataFiles) =>
        //       metaDataFiles && metaDataFiles.length > 0
        //         ? StorageBlob.uploadToSubmit('PS', policyNumber)(metaDataFiles)
        //         : ZIO.succeed([])
        //     ),
        //     bindLoader,
        //     ZIO.unsafeRun({})
        //   )
        // }
        return {
          url: (policyNumber) => `wf-api/policy/${policyNumber}/partial-surrender`,
          body: {
            policy: {
              policyNo: policyNumber
            },
            attributesExt: {
              CURRENT_SUM_ASSURED: Number(form.currentSumAssured).toString(),
              NEW_SUM_ASSURED: Number(form.newSumAssured).toString(),
              ADVANCE_PREMIUM: data.advancePremium.toString(),
              SUSPENSE_AMOUNT: data.suspenseAmount.toString(),
              LOAN_OVER_LIMIT: Number(form.loanOverLimit).toString(),
              ESTIMATED_PAID_SURRENDER: Number(form.estimatedPaidSurrender).toString()
            },
            cashOutOptions: mapCashOutOption(form.payout)
          },
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.PARTIAL_SURRENDER),
          collerationId: policyNumber,
          transaction: TransactionType.PARTIAL_SURRENDER,
          uploadedFilesInfo: getUploadedFilesInfo(form)
        }
      } else {
        return false
      }
    },
    clear: () => {
      base.reset({
        ...defaultValues,
        advancePremium: base.watch('advancePremium'),
        suspenseAmount: base.watch('suspenseAmount')
      })
      payoutRef.current?.clearData()
    }
  })

  return data === null ? (
    <LinearProgress style={{ marginTop: 15 }} color="secondary" />
  ) : (
    <SafeAreaView style={{ flex: 1, marginTop: 15 }}>
      <View style={{ backgroundColor: '#FFF' }}>
        <Text style={sharedStyle.sectionTitle}>{t('submission:PartialSurrender')}</Text>
        <View style={[sharedStyle.sectionContent, { paddingHorizontal: 5 }]}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:CurrentSumAssured')}
                value={toCurrency(base.watch('currentSumAssured'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="newSumAssured"
                control={base.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    required
                    title={t('submission:NewSumAssured')}
                    value={value ?? undefined}
                    maxLength={15}
                    onChange={onChange}
                    onBlur={() => {
                      onBlurNewSumAssured()
                      return onBlur()
                    }}
                    inputType="money"
                    disabled={props.isConfirmed}
                    suffix="VND"
                    containerStyle={{ maxWidth: 200 }}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:AdvancePremium')}
                value={toCurrency(base.watch('advancePremium'))}
                disabled={true}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Input
                title={t('submission:SuspenseAmount')}
                value={toCurrency(base.watch('suspenseAmount'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:LoanOverLimit')}
                value={toCurrency(base.watch('loanOverLimit'))}
                disabled={true}
              />
            </View>
            <View style={styles.col}>
              <Input
                title={t('submission:EstimatedPaidSurrenderP')}
                value={toCurrency(base.watch('estimatedPaidSurrender'))}
                disabled={true}
                inputStyle={{ color: 'rgb(237, 27, 46)', fontWeight: 'bold' }}
              />
            </View>
          </View>
          <View style={styles.row}>
            <Error
              style={{ paddingHorizontal: 16, fontSize: 13, fontStyle: 'italic' }}
              message={t('submission:PartialSurrenderNote')}
            />
          </View>
        </View>
      </View>
      <View style={{ backgroundColor: '#FFF' }}>
        <Controller
          control={base.control}
          name="payout"
          render={({ field, fieldState: { error } }) => (
            <PayoutMethod
              {...field}
              ref={payoutRef}
              editable={!props.isConfirmed}
              policyNum={policyNumber}
              maxAmount={estimatedPaidSurrender ? Number(estimatedPaidSurrender) : 0}
              transactionType={TransactionType.PARTIAL_SURRENDER}
              errorMessage={error?.message}
              methods={[
                PayoutPopup.PayoutMethods.PAYPREMIUM,
                PayoutPopup.PayoutMethods.PAYLOAN,
                PayoutPopup.PayoutMethods.CASHLESS,
                PayoutPopup.PayoutMethods.OTHER,
                PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
                PayoutPopup.PayoutMethods.PAID_AT_BANK,
                PayoutPopup.PayoutMethods.BANKTRANSFER,
                // PayoutPopup.PayoutMethods.MOMO
              ]}
              officeCode={props.officeCode}
            />
          )}
        />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 15
  }
} as const)

function toCurrency(value: number | string | null | undefined) {
  return PulseOpsFormat.thousandSepartor(value ? Number(value) : 0) + ' VND'
}
