import { Task, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { GeneralInfoApi } from './general-info-api'
import { GeneralInfoState } from './general-info-state'
import { ContractItem } from './model/contract-item'

export namespace ContractService {
  export const getContractList = ({
    size,
    page,
    masterContractNo
  }: {
    size: number
    page: number
    masterContractNo?: string
  }): Task<string[]> => {
    const service = GeneralInfoState.contractItem.size(size, masterContractNo)
    const pageLens = service.pageLens(page)

    return pipe(
      GeneralInfoApi.getAllContractInfo({ size, page, masterContractNo }),
      ZIO.map((res) => ({
        ...res,
        data: res.data.map(ContractItem)
      })),
      ZIO.flatMap(service.updatePage),
      GeneralInfoState.cache(pageLens)
    )
  }
}
