import React from 'react'
import { OBGeneralTable, OBGeneralTableColum } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { CallOutDetailContext, OBCallProgramTypeCode, OBDataSource, OBOnGoingRequestService, mapCallProgram } from '@pulseops/outbound'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { Container, PulseOpsFormat, TaskType } from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { useLoading } from '@mxt/zio-react'
import { useWindowDimensions } from 'react-native'

export const OBOnGoingRequest = () => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { height } = useWindowDimensions()
  const [isLoading, bindLoader] = useLoading(false)
  const { policyNumber, category } = React.useContext(CallOutDetailContext)
  const [orderBy, setOrderBy] = React.useState<string>('startTime')
  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [total, setTotal] = React.useState<number>(0)
  const [rows, setRows] = React.useState<OBDataSource[]>([])

  const displayedColumns: OBGeneralTableColum[] = [
    {
      title: t('Outbound:OBOnGoingRequest:PolicyNo'),
      fieldName: 'policyNumber',
      disabled: true
    },
    {
      title: t('Outbound:OBServiceInquiry:CaseID'),
      fieldName: 'caseId',
      minWidth: 315,
      disabled: true
    },
    {
      title: t('common:CreatedDate'),
      fieldName: 'createdDate',
      minWidth: 170,
      disabled: true
    },
    {
      title: t('Outbound:OBOnGoingRequest:Callprogram'),
      fieldName: 'transactionType',
      disabled: true
    },
    {
      title: t('common:Activity'),
      fieldName: 'activity',
      minWidth: 140,
      disabled: true
    },
    {
      title: t('common:Status'),
      fieldName: 'status',
      minWidth: 140,
      disabled: true
    },
    {
      title: t('Outbound:OBOnGoingRequest:CurrentAssignee'),
      fieldName: 'assignee'
    },
    {
      title: t('Outbound:OBOnGoingRequest:LastAssignee'),
      fieldName: 'lastAssignee',
      disabled: true
    },
    {
      title: t('common:Source'),
      fieldName: 'source',
      disabled: true
    },
  ]

  React.useEffect(() => {
    isFocused && loadOnGoingRequestList(pageSize, pageNum)
  }, [policyNumber, isFocused])

  const getLabelCallProgram = (callProgram: string) => {
    const findCallProgram = mapCallProgram.get(callProgram as OBCallProgramTypeCode)
    return findCallProgram || ''
  }

  const loadOnGoingRequestList = (pageSiz: number, pageNo: number) => pipe(
    OBOnGoingRequestService.GetOGR(policyNumber, { pageSize: pageSiz, pageNum: pageNo }, { order: order, field: orderBy }, TaskType.Outbound),
    ZIO.map((res) => {
      setTotal(res.total)
      return res.data
        ? res.data
          .filter((c) => c.transactionType !== 'PAYOUT')
          .map((x) => ({
            policyNumber: x.policyNumber || '',
            caseId: x.businessKey ?? '-',
            createdDate: x.createdDate
              ? PulseOpsFormat.datetoFormat(new Date(x.createdDate), 'DD/MM/yyyy HH:mm:ss')
              : '-',
            transactionType: getLabelCallProgram(x.transactionType),
            activity: x.activity ?? '-',
            status: x.status ?? '-',
            assignee: x.assignee || '-',
            lastAssignee: x.lastAssignee || '',
            source: x.source ?? '-'
          }))
        : []
    }),
    ZIO.tap((res) => {
      setRows(res)
      return ZIO.unit
    }),
    ZIO.tapError((res) => {
      setRows([])
      return ZIO.unit
    }),
    bindLoader,
    ZIO.unsafeRun({})
  )

  const onPageChange = (pageNum: number, pageSize: number) => {
    loadOnGoingRequestList(pageSize, pageNum)
  }

  const onRowsPerPageChange = (pageNum: number, pageSize: number) => {
    loadOnGoingRequestList(pageSize, pageNum)
  }

  return (
    <Container loading={isLoading}>
      <OBGeneralTable
        displayedColumns={displayedColumns}
        dataSource={rows}
        isPagination={true}
        totalRecord={total}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        setPageSize={setPageSize}
        setTotalRecord={setTotal}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        // containerStyle={{height: height}}
      ></OBGeneralTable>
    </Container>
  )
}