import React from 'react'
import { StyleSheet, Text} from 'react-native'
import { useTranslation } from 'react-i18next'
import { OBModalAction, OBModalPopup, OBSharedStyles } from '@pulseops/outbound'

type OBAcceptPopupProp = {
  isVisible: boolean
  onCloseEvent?: () => void
  onConfirmRejectEvent?: () => void
}

export const OBRejectPopup = (props: OBAcceptPopupProp) => {
  const { t } = useTranslation()

  const modalButtons: OBModalAction[] = [
    {
      text: t('common:No'),
      onClickEvent: () => props.onCloseEvent && props.onCloseEvent(),
      actionStyle: [OBSharedStyles.actionContent, acceptStyles.actionLine],
      textStyle: OBSharedStyles.actionContentText
    },
    {
      text: t('common:Yes'),
      onClickEvent: () => props.onConfirmRejectEvent && props.onConfirmRejectEvent(),
      actionStyle: [OBSharedStyles.actionContent, OBSharedStyles.actionHightLight],
      textStyle: OBSharedStyles.actionHightLightText
    }
  ]
  return (
    <OBModalPopup
      isVisible={props.isVisible}
      modalBody={<Text> {t("Reject confirmation")}</Text>}
      onCloseEvent={()=> props.onCloseEvent && props.onCloseEvent()}
      modalActions={modalButtons}
    >
    </OBModalPopup>
  )
}

const acceptStyles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    minWidth: 400,
    minHeight: 150,
    paddingHorizontal: 24,
    paddingVertical: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.08)'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  actionLine: {
    marginRight: 15
  }
})