import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'

export const PolicyTypeC = t.type({
  anniDate: Maybe(t.string),
  salaryIncome: Maybe(t.number),
  basicCode: Maybe(t.string), // Coverage Code
  lastIssueDate: Maybe(t.string),
  paidToDateAdvance: Maybe(t.string),
  paidToDateBasic: Maybe(t.string),
  paidToDateRider: Maybe(t.string),
  billToDate: Maybe(t.string),
  billFreq: Maybe(t.string),
  mainLifeAssuredNumber: Maybe(t.string), // Client Id
  productCode: Maybe(t.string), // Coverage Type
  productType: Maybe(t.string),
  clientDespatchAddress: Maybe(t.string),
  policyNo: Maybe(t.string),
  totalPremium: Maybe(t.number), // Installment Premium
  contractDate: Maybe(t.string), // Risk Command Date
  dispatchMethod: Maybe(t.string),
  firstIssueDate: Maybe(t.string),
  status: Maybe(t.string), // Contract Status
  premiumStatus: Maybe(t.string),
  riskCommenceDate: Maybe(t.string),
  proposal: Maybe(
    t.type({
      proposalNo: Maybe(t.string),
      proposalReceivedDate: Maybe(t.string)
    })
  ),
  owners: t.type({
    id: Nullable(t.string) // Owner Id
  }),
  servingAgents: Maybe(
    t.type({
      agent: Maybe(
        t.type({
          code: Maybe(t.string)
        })
      )
    })
  ),
  attributesExt: Maybe(
    t.type({
      basicPremium: Maybe(t.number),
      riderPremium: Maybe(t.number)
    })
  )
})

export type PolicyTypeC = t.TypeOf<typeof PolicyTypeC>

export interface SearchPolicy {
  policyNum: string
  idNum: string
  clientName: string
  laName: string
}

export interface PolicyS {
  attributesExt?: {
    basicPremium: number | null | undefined
    riderPremium: number | null | undefined
  }
  salaryIncome: number
  lastIssueDate: string
  paidToDateAdvance: string
  paidToDateBasic: string
  contractStatus: string
  contractDate: string
  productCode: string
  productType: string
  clientDespatchAddress: string
  policyNo: string
  totalPremium: number
  ownerId: string
  mainLifeAssuredNumber: string
  billFreq: string
  premiumStatus: string
  status: string
}
