import * as React from 'react'
import { Column, FieldText, Error } from '@pulseops/submission/common'
import {
  FieldList,
  Panel,
  Select,
  ProductOptionSwitchingService,
  ErrorHandling,
  formatNumberWithComma,
  ProductOptionSwitching as ProductOptionSwitchingData,
  SelectOption,
  AppContext,
  ImgUploadMutiple
} from '@pulseops/common'
import { View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useLoading } from '@mxt/zio-react'
import { MajorCombinedForm } from '../major-combined-form'
import { FileAttachmentItem, FileAttachments } from '../../../common'
import { ProductOptionSwitchingConst } from '../../product-option-switching'
import { ZIO } from '@mxt/zio'
import { useIsFocused } from '@react-navigation/native'

interface Props {
  policyNum: string
  isConfirmed: boolean
  validateBeforeContinue: ({ validateProductOption }: { validateProductOption: () => Promise<boolean> }) => void
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}

export const ProductOptionSwitchingComponent = ({ policyNum, isConfirmed, form, validateBeforeContinue }: Props) => {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const detail = pipe(ProductOptionSwitchingService.getDetail(policyNum), bindLoader, ErrorHandling.runDidMount(null))
  const isFocused = useIsFocused()

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    return () => {
      form.setValue('productOptionSwitching', {
        attachmentFiles: [],
        oldBenefit: '',
        currentSumAssured: 0,
        benefits: null,
        newSumAssured: 0,
        newInstallmentPremium: 0
      })
    }
  }, [isFocused])

  React.useEffect(() => {
    if (detail) {
      form.setValue('productOptionSwitching.currentSumAssured', detail.currentSumAssured)
      form.setValue('productOptionSwitching.oldBenefit', detail?.benefitOption)
    }
  }, [detail])

  const isVLR = (coverageCode: string) => {
    return coverageCode.startsWith('VLR') || coverageCode === 'ULR6'
  }

  const isValidatedBenefits = () => {
    const checkedBenefit =
      form.watch('productOptionSwitching.benefits')?.value === 'OPT1' ||
      form.watch('productOptionSwitching.benefits')?.value === 'HSC'
    return checkedBenefit
  }

  const getNewSumAssured = (policyNumber: string, benefitOption: string) => {
    pipe(
      [ProductOptionSwitchingConst.ULPProduct.VLR2, ProductOptionSwitchingConst.ULPProduct.VLR3].includes(
        detail?.coverageCode as ProductOptionSwitchingConst.ULPProduct
      )
        ? pipe(
            ProductOptionSwitchingService.getNewSumAssured(policyNumber, benefitOption),
            ZIO.map((response) => ({
              newSumAssured: response.newSumAssured || 0,
              newInstallmentPremium: detail?.curRenewalPremium || 0
            }))
          )
        : ZIO.succeed({
            newSumAssured: detail?.currentSumAssured || 0,
            newInstallmentPremium: detail?.curRenewalPremium || 0
          }),
      ZIO.map((responseData) => {
        form.setValue('productOptionSwitching.newSumAssured', responseData.newSumAssured)
        form.setValue('productOptionSwitching.newInstallmentPremium', responseData.newInstallmentPremium)
      }),
      ZIO.unsafeRun({})
    )
  }

  const mapVLROptions = (tpdBenefitList: ProductOptionSwitchingData.TpdBenefit[]): SelectOption[] => {
    const isExistedBenefit = tpdBenefitList.some((x) => x.tpdBenefit === 'OPT1')
    if (detail?.coverageCode === ProductOptionSwitchingConst.ULPProduct.ULR6 && !isExistedBenefit) {
      tpdBenefitList.push({
        tpdBenefit: 'OPT1',
        description: 'Death/TPD benefit option',
        descriptionVi: 'Quyền lợi Tử vong/Thương tật toàn bộ và vĩnh viễn sau 70 tuổi'
      })
    }
    return tpdBenefitList
      .filter((_) => _.tpdBenefit !== detail?.benefitOption)
      .map((item) => ({
        label: i18n.language === 'en' ? item.description : item.descriptionVi,
        value: item.tpdBenefit
      }))
  }

  const mapEUSOptions = (eduBenefitList: ProductOptionSwitchingData.EduBenefit[]): SelectOption[] => {
    const filteredEduBenefitList: ProductOptionSwitchingData.EduBenefit[] = detail
      ? eduBenefitList.filter((eduItem) => eduItem.coverageCode === detail?.coverageCode)
      : []
    let eduOptionList: SelectOption[] = []
    if (filteredEduBenefitList.length > 0) {
      eduOptionList = [
        {
          label:
            i18n.language === 'en' ? filteredEduBenefitList[0].description1 : filteredEduBenefitList[0].description1Vi,
          value: filteredEduBenefitList[0].option1
        },
        {
          label:
            i18n.language === 'en' ? filteredEduBenefitList[0].description2 : filteredEduBenefitList[0].description2Vi,
          value: filteredEduBenefitList[0].option2
        }
      ].filter((_) => _.value !== detail?.benefitOption)
    }
    return eduOptionList
  }

  const isRequiredHealthDeclaration = () => {
    return (
      form.watch('productOptionSwitching.benefits')?.value !== 'OPT1' &&
      form.watch('productOptionSwitching.benefits')?.value !== 'HSC'
    )
  }

  const checkRiskCessDate = async () => {
    const riskCessDateEUS3 =
      detail?.coverageCode === 'EUS3'
        ? await pipe(ProductOptionSwitchingService.getRiskCessDate(policyNum), ErrorHandling.run())
        : true
    if (!riskCessDateEUS3) {
      showToast(t('message:MS050034'), 'error')
    }
    return riskCessDateEUS3
  }

  const validateNextStep = async () => {
    const MMItem = ProductOptionSwitchingConst.getProductMM(detail?.coverageCode)
    if (detail?.coverageCode === 'EUS3') {
      return await checkRiskCessDate()
    } else {
      switch (detail?.coverageCode) {
        // case ProductOptionSwitchingConst.ULPProduct.VLR3:
        case ProductOptionSwitchingConst.ULPProduct.VLR2: {
          if (
            form.watch('productOptionSwitching.newSumAssured') < MMItem.minSA ||
            form.watch('productOptionSwitching.newSumAssured') > MMItem.maxSA
          ) {
            showToast(t('message:MS050034'), 'error')
            return false
          } else {
            return true
          }
        }
        default:
          return true
      }
    }
  }

  const renderTextValue = (value: string) => {
    return (
      <Text
        style={{
          fontSize: 15,
          color: '#000000'
        }}
      >
        {t(`${detail?.coverageCode}:${value}`)}
      </Text>
    )
  }

  validateBeforeContinue({
    validateProductOption: async () => {
      const isNextStep = await validateNextStep()
      if (detail && isNextStep) return true
      else return false
    }
  })

  const getFieldListData = (coverageCode: string): FieldList.FieldType[] => {
    const isEUS = !isVLR(coverageCode)
    const fields: FieldList.FieldType[] = [
      {
        label: isEUS ? t('submission:CurrentEducationalBenefits') : t('submission:CurrentInsuranceBenefits'),
        value: '',
        labelStyle: { marginBottom: 6 },
        render: () => renderTextValue(detail?.benefitOption || '-')
      },
      {
        label: t('submission:CurrentSumAssured'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () => renderTextValue(`${formatNumberWithComma(detail?.currentSumAssured || 0)} VND`)
      },
      {
        label: t('submission:InstallmentPremium'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () => renderTextValue(`${formatNumberWithComma(detail?.curRenewalPremium || 0)} VND`)
      },
      {
        label: isEUS ? t('submission:NewEducationalBenefits') : t('submission:NewInsuranceBenefits'),
        required: !isConfirmed,
        labelStyle: { marginBottom: isConfirmed ? 6 : 0 },
        render: () => {
          return (
            <Controller
              control={form.control}
              name="productOptionSwitching.benefits"
              rules={{
                required: {
                  value: true,
                  message: isEUS
                    ? t('message:MS020001', { field: t('submission:NewEducationalBenefits') })
                    : t('message:MS020001', { field: t('submission:NewInsuranceBenefits') })
                }
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                const isReadOnly = isConfirmed
                if (isReadOnly) {
                  return renderTextValue(value?.label || '-')
                }
                return (
                  <View>
                    <Select
                      value={value}
                      onBlur={onBlur}
                      onChange={(val) => {
                        onChange(val)
                        getNewSumAssured(policyNum, val?.value ?? '')
                      }}
                      placeholder={t('common:Select')}
                      disabled={isConfirmed}
                      options={
                        isEUS
                          ? mapEUSOptions(detail?.eduBenefitList || [])
                          : mapVLROptions(detail?.tpdBenefitList || [])
                      }
                      errorMessage={error?.message}
                    />
                  </View>
                )
              }}
            />
          )
        }
      },
      {
        label: t('submission:NewSumAssured'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () =>
          renderTextValue(`${formatNumberWithComma(form.watch('productOptionSwitching.newSumAssured'))} VND`)
      },
      {
        label: t('submission:NewInstallmentPremium'),
        labelStyle: { marginBottom: 6 },
        value: '',
        suffix: 'VND',
        render: () =>
          renderTextValue(`${formatNumberWithComma(form.watch('productOptionSwitching.newInstallmentPremium'))} VND`)
      }
    ]
    return fields
  }

  return (
    <Column flex={1}>
      {detail && (
        <>
          <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
            <FieldList dataSource={getFieldListData(detail.coverageCode)} viewStyle={{ paddingHorizontal: 15 }} />
            <View style={{ paddingHorizontal: 15 }}>
              <FieldText
                text={t('submission:HealthDeclaration')}
                isRequired={isRequiredHealthDeclaration()}
              ></FieldText>
              <Controller
                control={form.control}
                name={'productOptionSwitching.attachmentFiles'}
                rules={{
                  validate: (val) => {
                    if (!isValidatedBenefits() && (!!!val || val?.length === 0)) {
                      return `${t('message:MS020001', { field: t('submission:HealthDeclaration') })}`
                    } 
                    return true
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                  <Column>
                    <ImgUploadMutiple
                      value={value as FileAttachments}
                      onChange={onChange}
                      onBlur={onBlur}
                      timeFormat={'DD/MM/YYYY HH:mm'}
                      disabled={isConfirmed}
                    />
                    {error?.message && isRequiredHealthDeclaration() && (
                      <Error message={!!value && value.length > 0 ? '' : error?.message} />
                    )}
                  </Column>
                )}
              />
            </View>
          </Panel>
        </>
      )}
    </Column>
  )
}
