import * as t from 'io-ts'

export const CountryC = t.type({
  code: t.string,
  id: t.string,
  name: t.string,
  pcaCode: t.string,
  pcaName: t.string
})
export type Country = t.TypeOf<typeof CountryC>
