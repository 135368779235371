import { SelectOption } from '@pulseops/common'
// import { useTranslation } from 'react-i18next'
// import { SubmissionReportModal } from './Model/SubmissionReportModal'
import { SubmissionReportModal } from '../lib/model/SubmissionReportModal'

export namespace SubmissionReportConst {
  // export enum Category {
  //   ALL = 'ALL',
  //   COMPLAINT = 'CH',
  //   INQUIRY = 'CS',
  //   POLICY_SERVICE = 'PS'
  // }

  // export enum Order {
  //   ASC = 'asc',
  //   DESC = 'desc'
  // }

  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }

  export enum Report {
    PM = 'PM',
    SM = 'SM'
  }

  export const CategoryList: SelectOption[] = [
    {
      label: 'All',
      value: SubmissionReportModal.Category.ALL
    },
    {
      label: 'Complaint',
      value: SubmissionReportModal.Category.COMPLAINT
    },
    {
      label: 'Inquiry',
      value: SubmissionReportModal.Category.INQUIRY
    },
    {
      label: 'PolicyService',
      value: SubmissionReportModal.Category.POLICY_SERVICE
    },
    {
      label: 'General',
      value: SubmissionReportModal.Category.GENERAL
    },
    {
      label: 'ClaimServices',
      value: SubmissionReportModal.Category.CLAIM_SERVICES
    },
    {
      label: 'PremiumCollection',
      value: SubmissionReportModal.Category.PREMIUM_COLLECTION
    }
  ]

  export const TemplateList = [
    {
      label: 'Excel',
      value: Extension.EXCEL
    },
    {
      label: 'CSV',
      value: Extension.CSV
    }
  ]

  export const ReportList = [
    {
      label: 'Payment Report For Accountant',
      value: Report.PM
    },
    {
      label: 'Payment Report For GA',
      value: Report.SM
    }
  ]

  export const displayedColumnsPaymentReport = [
    { id: 'no', name: '#', width: 5, widthPx: 5, horizontal: 'center', textAlign: 'center' },
    {
      id: 'policyNo',
      name: `Reports:Payment.TableHeader.Policy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 12
    },
    {
      id: 'policyOwner',
      name: `Reports:Payment.TableHeader.PolicyOwner`,
      horizontal: 'center',
      textAlign: 'center',
      width: 12,
      widthPx: 12
    },
    {
      id: 'payee',
      name: `Reports:Payment.TableHeader.Payee`,
      horizontal: 'center',
      textAlign: 'center',
      width: 12,
      widthPx: 12
    },
    {
      id: 'idNumber',
      name: `Reports:Payment.TableHeader.IDNumber`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'agentCode',
      name: `Reports:Payment.TableHeader.AgentCode`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'paymentNumber',
      name: `Reports:Payment.TableHeader.PaymentNumber`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'pvNumber',
      name: `Reports:Payment.TableHeader.PVNumber`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'paymentDate',
      name: `Reports:Payment.TableHeader.PaymentDate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 12
    },
    {
      id: 'amount',
      name: `Reports:Payment.TableHeader.Amount`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'paymentOffice',
      name: `Reports:Payment.TableHeader.PaymentOffice`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'officeName',
      name: `Reports:Payment.TableHeader.BankCode`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'pvStatus',
      name: `Reports:Payment.TableHeader.PVStatus`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'transactionType',
      name: `Reports:Payment.TableHeader.TransactionType`,
      horizontal: 'center',
      textAlign: 'center',
      width: 12,
      widthPx: 12
    },
    {
      id: 'createdDate',
      name: `Reports:Payment.TableHeader.CreatedDate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 12
    },
    {
      id: 'createdBy',
      name: `Reports:Payment.TableHeader.CreatedBy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 16,
      widthPx: 16
    },
    {
      id: 'verifiedDate',
      name: `Reports:Payment.TableHeader.VerifiedDate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 12
    },
    {
      id: 'verifiedBy',
      name: `Reports:Payment.TableHeader.VerifiedBy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 16,
      widthPx: 16
    },
    {
      id: 'authorizedDate',
      name: `Reports:Payment.TableHeader.AuthorizedDate`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 12
    },
    {
      id: 'authorizedBy',
      name: `Reports:Payment.TableHeader.AuthorizedBy`,
      horizontal: 'center',
      textAlign: 'center',
      width: 12,
      widthPx: 12
    },
    {
      id: 'status',
      name: `Reports:Payment.TableHeader.Status`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    },
    {
      id: 'note',
      name: `Reports:Payment.TableHeader.Note`,
      horizontal: 'center',
      textAlign: 'center',
      width: 10,
      widthPx: 10
    }
  ]

  export const displayedColumns = [
    { id: 'no', name: '#', width: 5, widthPx: 5, horizontal: 'center ', textAlign: 'center' },
    {
      id: 'policyNum',
      name: `Reports:PolicyNumber`,
      horizontal: 'center',
      textAlign: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'category',
      name: `Reports:Category`,
      widthPx: 17
    },
    {
      id: 'transactionType',
      // name: $localize`:@@TransactionType:Transaction Type`,
      name: `Reports:TransactionType`,
      widthPx: 23
    },
    {
      id: 'request',
      name: `Reports:Request`,
      widthPx: 17
    },
    {
      id: 'subServiceType',
      // name: $localize`:@@SubServiceType:Sub Service Type`,
      name: `Reports:SubServiceType`,
      width: 8,
      widthPx: 16
    },
    {
      id: 'subTransactionType',
      name: `Reports:SubTransactionType`,
      width: 8,
      widthPx: 20
    },
    {
      id: 'finalSubServiceType',
      name: `Reports:FinalSubServiceType`,
      width: 8,
      widthPx: 20
    },
    {
      id: 'finalSubTransactionType',
      name: `Reports:FinalSubTransactionType`,
      width: 8,
      widthPx: 23
    },
    {
      id: 'createdDate',
      name: `Reports:CreatedDate`,
      textAlign: 'center',
      horizontal: 'center',
      width: 5,
      widthPx: 15
    },
    {
      id: 'closeDate',
      name: `Reports:ClosedDate`,
      textAlign: 'center',
      horizontal: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'submissionUser',
      name: `Reports:SubmissionUser`,
      widthPx: 25
    },
    {
      id: 'lastUpdateBy',
      name: `Reports:LastUpdateBy`,
      widthPx: 25
    },
    {
      id: 'assignee',
      name: `Reports:LastAssignee`,
      width: 11,
      widthPx: 25
    },
    {
      id: 'userGroup',
      name: `Reports:UserGroup`,
      width: 7,
      widthPx: 15
    },
    {
      id: 'status',
      name: `Reports:Status`,
      textAlign: 'center',
      horizontal: 'center',
      width: 5,
      widthPx: 15
    },
    {
      id: 'source',
      name: `Reports:Source`,
      textAlign: 'center',
      horizontal: 'center',
      width: 6,
      widthPx: 15
    },
    {
      id: 'office',
      name: `Reports:Office`,
      textAlign: 'center',
      horizontal: 'center',
      width: 7,
      widthPx: 15
    }
  ]

  export enum ReportClaimType {
    PaymentVoucher = '01_PV_REPORT',
    PaymentGateway = '02_PG_REPORT',
    SCBTransaction = '03_SCB_REPORT',
    ClaimReserve = '04_RESERVE_BOOKING',
    PayableBooking = '05_APPROVAL_BOOKING',
    RepaymentBooking = '06_REPAYMENT_BOOKING',
    ReleaseBooking = '07_RELEASE_BOOKING',
    ReturnBooking = '08_RETURN_BOOKING'
  }

  export const ExportBookingReport = [
    {
      value: ReportClaimType.PaymentVoucher,
      label: 'P4OPaymentVoucher'
    },
    {
      value: ReportClaimType.ClaimReserve,
      label: 'ClaimReserveBooking'
    },
    {
      value: ReportClaimType.PayableBooking,
      label: 'PayableBookingApproval'
    },
    {
      value: ReportClaimType.RepaymentBooking,
      label: 'RepaymentBooking'
    },
    {
      value: ReportClaimType.ReleaseBooking,
      label: 'ReleaseBooking'
    },
    {
      value: ReportClaimType.ReturnBooking,
      label: 'ReturnBooking'
    }
  ]
  
  export const ExportPVReport = [
    {
      value: ReportClaimType.PaymentVoucher,
      label: 'P4OPaymentVoucher'
    },
    {
      value: ReportClaimType.PaymentGateway,
      label: 'PaymentGatewayTransactionReport'
    },
    {
      value: ReportClaimType.SCBTransaction,
      label: 'SCBTransactionReport'
    },
  ]
  
  export const ReportPayoutClaim = [
    {
      value: ReportClaimType.PaymentVoucher,
      label: 'P4OPaymentVoucher'
    },
    {
      value: ReportClaimType.PaymentGateway,
      label: 'PaymentGatewayTransactionReport'
    },
    {
      value: ReportClaimType.SCBTransaction,
      label: 'SCBTransactionReport'
    },
    {
      value: ReportClaimType.ClaimReserve,
      label: 'ClaimReserveBooking'
    },
    {
      value: ReportClaimType.PayableBooking,
      label: 'PayableBookingApproval'
    },
    {
      value: ReportClaimType.RepaymentBooking,
      label: 'RepaymentBooking'
    },
    {
      value: ReportClaimType.ReleaseBooking,
      label: 'ReleaseBooking'
    },
    {
      value: ReportClaimType.ReturnBooking,
      label: 'ReturnBooking'
    }
  ]
}
