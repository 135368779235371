import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { ClientPolicy } from '../Client'
import { PaymentType } from '../PaymentType'
// import { InternalPayments } from '../task-detail-api/PaymentData'
import { getCoreDetail, getPayOutDetail } from './common/payout'
import { SourceType } from '../SourceType'

export interface CashOutSpecialBonus {
  tag: TransactionType.SPECIAL_BONUS | TransactionType.SPECIAL_BONUS_CUSTOMER
  clientName: string
  totalPayoutAmount: number
  specialBonus: number
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
}

export const CashOutSpecialBonus = (
  poName: string,
  detail: api.CashOutSpecialBonus & api.TaskDetailBase,
  clientPolicy: Array<ClientPolicy>,
  banks: Array<{ code: string; name: string }>,
  amount: number,
  branchName?: string
): CashOutSpecialBonus => {
  let specialBonus = 0
  const info = detail.payload.body
  const coreStatus = getCoreDetail(detail.coreResponseDetail, detail.payload.body?.cashOutOptions ?? [])
  const cashOutList =
    detail.payload && detail.payload.body
      ? detail.payload.body.cashOutOptions?.filter(
          (x) =>
            PaymentType.BANKTRANSFER === x.type ||
            PaymentType.PAID_AT_BANK === x.type ||
            PaymentType.CASH_AT_COUNTER === x.type
        )
      : []
  if (detail.source === SourceType.PULSE) {
    specialBonus = amount
  } else {
    specialBonus = info?.attributesExt.SPECIAL_BONUS ?? 0
  }
  return {
    tag: TransactionType.SPECIAL_BONUS,
    clientName: poName,
    totalPayoutAmount: info?.attributesExt?.TOTAL_PAYOUT_AMOUNT ?? 0,
    specialBonus: specialBonus,
    cashOutList: cashOutList,
    branchName: branchName,
    methods:
      info?.cashOutOptions?.map((v, i) => {
        let nameOfBank = ''
        if (detail.source === SourceType.PULSE) {
          nameOfBank =
            banks.find((bank) => v.type === PaymentType.BANKTRANSFER && bank.code === v?.bankAccount?.bankName)?.name ||
            ''
        } else {
          nameOfBank = (v.type === PaymentType.BANKTRANSFER && v?.bankAccount?.bankName) || ''
        }

        // const _detail =
        //   v.type === PaymentType.BANKTRANSFER
        //     ? `${nameOfBank + ' - '} ${v.bankAccount?.accountName + '-' ?? ''} ${v.bankAccount?.accountNo || ''}`
        //     : v.type === PaymentType.EWALLET
        //     ? `${v.bankAccount?.branchCode}`
        //     : v.type && InternalPayments.includes(v.type)
        //     ? getDetail(v.policy?.policyNo, clientPolicy)
        //     : getDetail(v.policy?.policyNo, clientPolicy)
        return {
          method: v.type || '-',
          // detail: _detail && _detail.length > 1 ? _detail : getDetail(v.policy?.policyNo, clientPolicy),
          detail: getPayOutDetail(
            {
              type: v.type as PaymentType,
              officeCode:
                (v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode) || '',

              bankAccount: {
                accountName:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountName) ||
                  (v.type === PaymentType.PAID_AT_BANK &&
                    (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                      ? poName
                      : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                  (v.type === PaymentType.CASH_AT_COUNTER &&
                    (v.attributesExt?.PAYEE_INFO?.payeeName === ''
                      ? poName
                      : v.attributesExt?.PAYEE_INFO?.payeeName)) ||
                  ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.payeeName) ?? poName) ||
                  '-',
                accountNo:
                  (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.accountNo) ||
                  ((v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                    detail.clientNumber) ||
                  ((v.type === PaymentType.CASH_AT_COUNTER && v.attributesExt?.PAYEE_INFO?.idNumber) ??
                    detail.clientNumber) ||
                  ((v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.idNumber) ?? detail.clientNumber) ||
                  '-',
                bankName:
                  (v.type === PaymentType.BANKTRANSFER && nameOfBank) ||
                  (v.type === PaymentType.PAID_AT_BANK && v.bankAccount?.bankName) ||
                  '-',
                branchName:
                  (v.type === PaymentType.BANKTRANSFER && branchName) ||
                  (v.type === PaymentType.PAID_AT_BANK && branchName) ||
                  undefined,
                bankCode: '-',
                branchCode: (v.type === PaymentType.BANKTRANSFER && v.bankAccount?.branchCode) || '-'
              },
              phoneNumber: (v.type === PaymentType.MOMO && v.attributesExt?.PAYEE_INFO?.phone) || '',
              issuedDate: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedDate) || '',
              issuedBy: (v.type === PaymentType.PAID_AT_BANK && v.attributesExt?.PAYEE_INFO?.issuedBy) || '',
              policy: {
                policyNo: v.policy?.policyNo || '-'
              }
            },
            clientPolicy
          ),
          amount: v.paymentOption.amount || 0,
          status: coreStatus[i].status,
          note: coreStatus[i].errorMes,
          action: '-',
          bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '' : undefined
        }
      }) ?? []
  }
}
