import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { Input } from '@pulseops/common'
import { JournalsForm } from './transfer-journal-form'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useIsFocused } from '@react-navigation/native'

interface Props {
  disabled: boolean
  form: UseFormReturn<JournalsForm.JournalsData, object>
}

const defaultValueRemove = {
  journalNumber: ''
}

export const RemoveJournalForm: React.FC<Props> = ({ disabled, form }) => {
  const { t } = useTranslation()
  const isFocused = useIsFocused()

  React.useEffect(() => {
    if (isFocused) {
      form.setValue('journalRemove', defaultValueRemove)
    }
  }, [isFocused])

  return (
    <SafeAreaView style={style.container}>
      <View style={style.row}>
        <View style={style.col_4_first}>
          <Controller
            name="journalRemove.journalNumber"
            control={form.control}
            rules={{
              required: {
                value: true,
                message: t('message:MS020001', { field: t('Journal:JournalNumber') })
              }
            }}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <Input
                disabled={disabled}
                required
                title={t('Journal:JournalNumber')}
                value={value || ''}
                onChange={onChange}
                inputType={'number'}
                maxLength={8}
                //   placeholder={t('ServiceInquiry:EnterIDNumber')}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  container: {
    marginTop: 10,
    marginLeft: 35
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  row: {
    flexDirection: 'row'
  },
  col_4_first: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' }
})
