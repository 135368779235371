import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'
import { DateFromString } from './DateFromString'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT)
    }),
    codec
  ])

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: t.type({
      body: t.type({
        fromDate: Maybe(t.string),
        toDate: Maybe(t.string),
        reason: Maybe(t.string),
        poClientNum: Maybe(t.string)
      })
    })
  })
)

const ZaloEkioskCodec = t.type({
  payload: t.type({
    body: t.type({
      fromDate: Maybe(t.union([DateFromString('DD/MM/YYYY'), t.string])),
      toDate: Maybe(t.union([DateFromString('DD/MM/YYYY'), t.string])),
      reason: Maybe(t.string),
      poClientNum: Maybe(t.string)
    })
  })
})

const Zalo = Base(t.intersection([t.type({ source: t.literal(SourceType.ZALO) }), ZaloEkioskCodec]))

const Ekiosk = Base(t.intersection([t.type({ source: t.literal(SourceType.EKIOSK) }), ZaloEkioskCodec]))

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: t.type({
      body: t.type({
        poClientNum: Maybe(t.string)
      })
    })
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload: t.type({
      body: t.type({
        poClientNum: Maybe(t.string)
      })
    })
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload: t.type({
      body: t.type({
        poClientNum: Maybe(t.string)
      })
    })
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: t.type({
      body: t.type({
        fromDate: Maybe(t.string),
        toDate: Maybe(t.string),
        reason: Maybe(t.string),
        poClientNum: Maybe(t.string)
      })
    })
  })
)

export const ConfirmationPremiumReceipt = t.union([PruOnline, Pulse, Zalo, Banca, Ekiosk, PruGreat, Pulse4Ops])
export type ConfirmationPremiumReceipt = t.TypeOf<typeof ConfirmationPremiumReceipt>
