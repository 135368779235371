export namespace DistributionServicesExportConst {
  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }

  export const displayedColumns = [
    { id: 'no', name: '#', width: 5, widthPx: 5, horizontal: 'center', textAlign: 'center' },
    {
      id: 'caseID',
      name: `ServiceInquiry:table:CaseID`,
      horizontal: 'center',
      textAlign: 'center',
      width: 11,
      widthPx: 25
    },
    {
      id: 'eRef',
      name: `ServiceInquiry:table:eRef`,
      widthPx: 17
    },
    {
      id: 'source',
      name: `ServiceInquiry:table:Source`,
      widthPx: 23
    },
    {
      id: 'agentCode',
      name: `ServiceInquiry:table:AgentCode`,
      widthPx: 17
    },
    {
      id: 'idNumber',
      name: `ServiceInquiry:table:IDNumber`,
      width: 8,
      widthPx: 16
    },
    {
      id: 'name',
      name: `ServiceInquiry:table:Name`,
      width: 8,
      widthPx: 20
    },
    {
      id: 'phone',
      name: `ServiceInquiry:table:Phone`,
      width: 8,
      widthPx: 20
    },
    {
      id: 'category',
      name: `ServiceInquiry:table:Category`,
      width: 8,
      widthPx: 23
    },
    {
      id: 'transactionType',
      name: `ServiceInquiry:table:Transaction`,
      textAlign: 'center',
      horizontal: 'center',
      width: 5,
      widthPx: 15
    },
    {
      id: 'createDate',
      name: `ServiceInquiry:table:CreatedDate`,
      textAlign: 'center',
      horizontal: 'center',
      width: 7,
      widthPx: 15
    },
    {
      id: 'assignee',
      name: `ServiceInquiry:table:LastAssignee`,
      width: 11,
      widthPx: 25
    },
    {
      id: 'aging',
      name: `ServiceInquiry:table:Aging`,
      widthPx: 25
    },
    {
      id: 'lastUpdateBy',
      name: `ServiceInquiry:table:LastUpdatedBy`,
      widthPx: 25
    },

    {
      id: 'lastUpdatedDate',
      name: `ServiceInquiry:table:LastUpdatedDate`,
      width: 7,
      widthPx: 15
    },
    {
      id: 'status',
      name: `ServiceInquiry:table:Status`,
      textAlign: 'center',
      horizontal: 'center',
      width: 5,
      widthPx: 15
    },
    {
      id: 'currentActivity',
      name: `ServiceInquiry:table:CurrentActivity`,
      textAlign: 'center',
      horizontal: 'center',
      width: 6,
      widthPx: 15
    }
  ]
}
