import { TransactionType } from './TransactionType'

export namespace ConfirmOfPremium {
  export interface Detail {
    rcd: Date
    billingFrequency: string
  }

  export interface AccessDataCheck {
    contractStatus: string
    lapseDate: Date
  }

  export interface SubmitData {
    transactionType: TransactionType
    fromDate: string
    toDate: string
    reason: string
  }
}
