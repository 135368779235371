import { View, Text } from 'react-native'
import styled from 'styled-components/native'

export const SC = {
  Container: styled(View)<{ backgroundColor?: string }>`
    width: 100%;
    background-color: ${(p) => p.backgroundColor};
    align-self: center;
  `,
  CenterSelf: styled(View)`
    align-self: center;
    justify-content: center;
  `,
  PanelContainer: styled(View)`
    border: 1px solid #d3dce6;
    border-radius: 8px;
    background-color: #fafafa;
    padding: 16px;
    :hover {
      background-color: red;
    }
  `,
  Row: styled(View)`
    flex-direction: row;
    justify-content: space-beetween;
    //align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
  `,
  Input: styled(View).attrs<{ flex?: number }>((p) => ({
    style: {
      flex: p.flex
    }
  }))``,
  SessionText: styled(Text)<{ color?: string }>`
    font-weight: bold;
    color: ${(p) => p.color};
  `,
  TitleText: styled(Text)<{ fontSize?: number }>`
    font-weight: bold;
    color: #70777e;
    font-size: ${(p) => (p.fontSize ?? 15) + 'px'};
  `,
  NormalText: styled(Text)<{ color?: string }>`
    color: ${(p) => p.color};
  `,
  Divider: styled(View)<{ height?: number; width?: number }>`
    width: ${(p) => (p.width ?? 10) + 'px'};
    height: ${(p) => (p.height ?? 10) + 'px'};
  `,
  Colum: styled(View)<{ flex?: number }>`
    flex: ${(p) => p.flex || 1};
  `,
  ErrorText: styled(Text)`
    color: red;
  `,
  InfoText: styled(Text)<{ color?: string }>`
    color: ${(props) => props.color || 'red'};
    padding: 20px 0px 20px 0px;
  `,
  InputContainer: styled(View)<{ flex?: number; maxWidth?: number }>`
    flex: ${(p) => p.flex || 1};
    max-width: ${(p) => p.maxWidth && p.maxWidth + 'px'};
    z-index: 99999;
  `,
  Padding: styled(View)<{
    top?: number
    bottom?: number
    left?: number
    right?: number
    vertical?: number
    horizontal?: number
  }>`
    padding-top: ${(p) => p.top || p.vertical || 0};
    padding-bottom: ${(p) => p.bottom || p.vertical || 0};
    padding-right: ${(p) => p.right || p.horizontal || 0};
    padding-left: ${(p) => p.left || p.horizontal || 0};
  `,
  ImageThumb: styled.Image`
    width: 50px;
    height: 50px;
  `,
  ItalicText: styled.Text`
    font-style: italic;
    color: grey;
    font-size: small;
  `,
  BottomDashContainer: styled(View)`
    border-bottom-width: 1px;
    border-style: dashed;
  `,
  RowNowrap: styled(View)`
    flex-direction: row;
    justify-content: space-beetween;
    width: 100%;
  `,
  Center: styled(View)`
    align-items: center;
    justify-content: center;
  `,
  Spacer: styled(View)`
    flex: 1;
  `,
  ThinText: styled(Text)`
    font-weight: 300;
  `
}
