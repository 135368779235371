import { FontAwesome5 } from '@expo/vector-icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
interface Props {
  title: string
  isExand?: boolean
  children?: React.ReactNode
  isHiddenTitle: boolean
  styling?: ViewStyle
  isHaveComment?: boolean
  comment?: string
}

export const PanelComment: React.FC<Props> = ({
  title,
  isExand,
  children,
  isHiddenTitle,
  styling = styles.sectionInfo,
  isHaveComment = false,
  comment = ''
}) => {
  const [toggle, setToggle] = React.useState(false)
  const { t } = useTranslation()

  const onToggle = () => {
    setToggle((prevState: boolean) => !prevState)
  }

  const handleChangeTitle = (): JSX.Element | undefined => {
    if (isHiddenTitle === true) {
      if (toggle === true) {
        return <Text style={styles.sectionInfoHeaderTitle}></Text>
      } else {
        return <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
      }
    } else {
      return <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
    }
  }

  return (
    <View style={[styling, { borderWidth: 1, borderRadius: 8, borderColor: '#dcdcdc', backgroundColor: '#FAFAFA' }]}>
      <View style={styles.sectionInfoHeader}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 15 }}>
          {handleChangeTitle()}
          {isHaveComment === true && (
            // <Text style={{ color: '#70777E', marginStart: 25, fontSize: 13 }}>added a comment - {comment}</Text>
            <Text style={{ color: '#70777E', marginStart: 25, fontSize: 13 }}>
              {t('Tab:Added_A_Comment')} - {comment}
            </Text>
          )}
        </View>
        {isExand ? (
          <TouchableOpacity onPress={onToggle} style={{ marginTop: 3 }}>
            {<FontAwesome5 name={toggle ? 'chevron-up' : 'chevron-down'} color="#1B365D" size={15} />}
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
      {toggle && <View style={styles.sectionInfoContent}>{children}</View>}
    </View>
  )
}

const styles = StyleSheet.create({
  sectionInfo: {
    width: '100%',
    padding: 16,
    marginBottom: 16
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between'
  },
  sectionInfoHeaderTitle: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfoContent: {
    marginTop: 16,
    paddingLeft: 16
  },
  currencyText: {
    fontStyle: 'italic'
  }
})
