import { RBAC, ErrorHandling } from '@pulseops/common'
import { AppContext } from '@pulseops/common'
import { TabView } from '@pulseops/task'
import { useIsFocused } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, View } from 'react-native'
import _ from 'lodash'
import styled from 'styled-components/native'
import { pipe } from 'fp-ts/lib/function'

import { ReportsStackParamList } from '../ReportsStackParamList'
import { MissCaseID } from './MissCaseID'
import { StuckWF } from './StuckWF'

type props = StackScreenProps<ReportsStackParamList, 'ErrorHandlerReportScreen'>

export const ErrorHandlerReportScreen = ({ navigation }: props) => {
  const { t } = useTranslation('Reports')
  const isFocused = useIsFocused()

  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [tabActiveIndex, setTabActiveIndex] = React.useState(0)

  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('ErrorHandler.ErrorHandlerReport'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getTabScenes = () => {
    const tabs = [
      {
        title: t('ErrorHandler.StuckWF'),
        Component: <StuckWF active={tabActiveIndex === 0} permissions={permissions} />
      },
      // {
      //   title: t('ErrorHandler.AutoTrigger'),
      //   Component: <></>
      // },
      {
        title: t('ErrorHandler.MissCaseID'),
        Component: <MissCaseID active={tabActiveIndex === 1} permissions={permissions} />
      }
    ]
    return tabs
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <SC.PageContent>
        <SC.TabViewContainer>
          <TabView initTabIndex={0} onTabChanged={setTabActiveIndex} scenes={getTabScenes()} />
        </SC.TabViewContainer>
      </SC.PageContent>
    </SafeAreaView>
  )
}

const SC = {
  PageContent: styled(View)`
    background-color: #fff;
    height: 100%;
    width: 100%;
  `,
  TabViewContainer: styled(View)`
    background-color: #f9f9f9;
    padding-top: 16px;
  `
}
