/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
// Lib
import React, { useState, useRef, useContext, Ref, RefObject, useCallback } from 'react'
import { Controller, ControllerRenderProps, useForm, UseFormStateReturn, ControllerFieldState } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { View, TouchableOpacity } from 'react-native'

// Common
import {
  SelectSearch,
  DatePicker,
  Input,
  assets,
  AppContext,
  TransactionType,
  StorageBlob,
  Alert,
  TaskType,
  FileUploadData,
  ErrorHandling,
  ChangeClientInfoService,
  ChangeClientInfo
} from '@pulseops/common'
import {
  CallBackValidateUploadFileType,
  COLORS,
  FieldsType,
  FilesPDF,
  groupsViewsConst,
  GroupsViewType,
  STATUS,
  typeField,
  TypeFieldEnum,
  typeGroupName,
  typeNameField
} from './change-national-id-card.const'
import { FormData } from './change-national-id-card.form'
import { ModalUploadCitizenRef } from './ModalUploadCitizen/modal-upload-citizen'
import { ChangeNationalIdCardRequestDTO, ChangeNationalIDCardService } from './change-national-id-card-service'
import { CN, TextCustom } from './change-national-id-card.style'
import { ScanIdentifyCardResponseType } from './models'
import { RequestAuthenticateData } from '../../request-authen'
import { UploadedFilesInfo } from '../policy-service-props'

// Component
import ModalUploadCitizen from './ModalUploadCitizen/modal-upload-citizen'
import UploadImageCitizen from './ModalUploadCitizen/upload-image-citizen'
import FormCitizen from './ModalUploadCitizen/form-citizen'
import { errorsFormCitizenType, FormCitizenRef, UploadCitizenRef, valuesFormCitizenType } from './ModalUploadCitizen'
import { PolicyType } from '../../../PSSubmissionScreen'
import { RouteProp, useRoute } from '@react-navigation/native'
import { PSSubmissionStackParamList } from '../../../PSSubmissionStackParamList'

const {
  Container,
  Row,
  Col_3,
  GROUP,
  BoxUpload,
  Modal: { GroupCitizen },
  PDFArea,
  BoxPDF,
  BoxPDFLeft
} = CN

interface Props {
  isConfirmed: boolean
  clientNumber?: string
  officeCode?: string
  groupName: string
  initSubmission: ({ validate, clear }: { validate: any; clear: () => void }) => void
  policyInfo: PolicyType
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
  setEformData: React.Dispatch<React.SetStateAction<RequestAuthenticateData.EformRequestAuthenticateData>>
}

const defaultValues = {
  selectClient: null,
  idCitizenNumber: '',
  issuedBy: null,
  issuedDate: null
}

const ChangeNationalIDCardScreen: React.FC<Props> = ({
  initSubmission,
  policyInfo,
  setTabIndex,
  setEformData,
  isConfirmed,
  officeCode
}) => {
  const { t } = useTranslation('landingPage')
  const { t: translate_change_nationalID, i18n } = useTranslation('CHANGE_NATIONALID_CARD_PS')
  const { showToast, showGlobalLoading } = useContext(AppContext.AppContextInstance)
  const { params } = useRoute<RouteProp<PSSubmissionStackParamList, 'PSSubmissionScreen'>>()
  const { policyNum = '' } = params

  const modalUploadCitizenRef = useRef<ModalUploadCitizenRef>()
  const uploadImageCitizenRef = useRef<UploadCitizenRef>()
  const formCitizenRef = useRef<FormCitizenRef>()

  const [groupsViews, setGroupsViews] = useState<GroupsViewType<typeGroupName, typeNameField>[] | []>(() =>
    groupsViewsConst(translate_change_nationalID)
  )
  const [isAvailableShowAlert, setIsAvailableShowAlert] = useState<boolean>(false)
  const [lstCustomer, setLstCustomer] = useState<ChangeClientInfo.CustomerData[]>([])
  const [{ filesPdf, valuesForm }, setValuesSubmission] = useState<{
    filesPdf: FilesPDF[]
    valuesForm: { id: string; name: string; sex: string; dob: string; doe: string }
  }>({
    filesPdf: [],
    valuesForm: {
      id: '',
      dob: '',
      doe: '',
      name: '',
      sex: ''
    }
  })

  const forms = useForm<FormData>({
    defaultValues
  })
  const { control, trigger, reset, getValues, watch } = forms

  const getRoles = useCallback(
    (role: string) => {
      return role === 'PolicyOwner'
        ? t('roles:PolicyOwner')
        : role === 'Beneficiary'
        ? t('roles:Beneficiary')
        : role === 'LifeAssured'
        ? t('roles:LifeAssured')
        : '-'
    },
    [t]
  )

  pipe(
    ChangeClientInfoService.getDetail(policyNum),
    ZIO.map((detail) => {
      const groupsViewsClone = [...groupsViews]
      const clients = detail.customerData.map((dc) => ({
        value: dc.customerId ?? '',
        label: `${dc.surName ?? ''} ${dc.name} - ${getRoles(dc.role)}`
      }))

      const groupsFuture = ChangeNationalIDCardService.replaceArrayObject<GroupsViewType<typeGroupName, typeNameField>>(
        {
          array: groupsViewsClone,
          typeKey: 'groupName',
          typeValue: 'info_change_client_group',
          data: {
            fields: ChangeNationalIDCardService.replaceArrayObject<FieldsType<typeNameField>>({
              array: groupsViewsClone.find((group) => group.groupName === 'info_change_client_group')?.fields ?? [],
              typeKey: 'formName',
              typeValue: 'selectClient',
              data: {
                options: clients
              }
            })
          }
        }
      ) as GroupsViewType<typeGroupName, typeNameField>[]

      setLstCustomer(detail.customerData)
      setGroupsViews([...groupsFuture])
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  )
  /**
   * Functions
   */
  const onOpenModalUploadCitizen = () => {
    const filesPDFClone = [...filesPdf]
    if (filesPDFClone.length >= 1) return showToast(t('message:MS040014'), 'error')

    const { setIsShowModalUpload } = modalUploadCitizenRef?.current ?? {}

    return setIsShowModalUpload?.(true)
  }

  const clearDataFormModal = () => {
    const { setStepModal, setError } = modalUploadCitizenRef?.current ?? {}
    const { setGroupCitizenUploads, defaultGroupCitizenUploads } = uploadImageCitizenRef?.current ?? {}
    const { clearErrors, reset, defaultValuesFormCitizen } = formCitizenRef?.current ?? {}

    setStepModal?.(1)
    setGroupCitizenUploads?.([...(defaultGroupCitizenUploads ?? [])])
    setError?.('')
    reset?.({ ...defaultValuesFormCitizen })
    clearErrors?.(['id', 'name', 'dob', 'doe', 'sex'])
  }

  const mappingBodySubmisson = (validOCR: string) => {
    const { selectClient, idCitizenNumber: identifyNumberUserInput, issuedBy, issuedDate } = getValues()
    const { poName, mobilePhone, email, idCCCD } = policyInfo ?? {}

    const getCustommerBySelectClient = () => [...lstCustomer]?.find((item) => item?.customerId === selectClient?.value)

    return {
      name: selectClient?.label?.split(new RegExp('-'))[0]?.trim() ?? '',
      clientId: selectClient?.value ?? '',
      dob: getCustommerBySelectClient()?.dob ?? '',
      sex: getCustommerBySelectClient()?.gender ?? '',
      firstName: getCustommerBySelectClient()?.name ?? '',
      surName: getCustommerBySelectClient()?.surName ?? '',
      nationality: 'VN',
      attributesExt: {
        clientNum: selectClient?.value ?? '',
        clientType: '',
        idType: 'ID',
        issuedBy: issuedBy?.label ?? '',
        idNumber: identifyNumberUserInput ?? '',
        issuedDate: moment(issuedDate ?? '').format('YYYY-MM-DD') || '',
        validOCR,

        PO_NAME: poName ?? '',
        PO_ID_NUM: idCCCD ?? '',
        PO_MOBILE: mobilePhone ?? '',
        PO_EMAIL: email ?? ''
      },
      createdDate: moment().format() ?? '',
      externalIds: {
        NATIONAL_ID: identifyNumberUserInput ?? ''
      }
    }
  }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: [...filesPdf?.[0]?.fileUploads],
      transactionType: TransactionType.CHANGE_NATIONAL_ID_CARD,
      docTypeCode: 'DPS10',
      category: TaskType.PolicyService,
      policyNumber: policyNum ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  const showAlert = () => {
    return pipe(
      ZIO.effect(() => {}),
      ZIO.flatMap(() => {
        return Alert.modalM({
          title: t('warning'),
          content: translate_change_nationalID('ERROR.ALERT_CONTENT'),
          onOK: () => {
            setIsAvailableShowAlert(true)
            showGlobalLoading(false)
            setTabIndex(1)
            setEformData((prev) => ({
              ...prev,
              transactionName:
                RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_NATIONAL_ID_CARD) || '',
              collerationId: policyNum || '',
              transaction: TransactionType.CHANGE_NATIONAL_ID_CARD
            }))
          },
          onClose: () => {
            setIsAvailableShowAlert(false)
            showGlobalLoading(false)
          }
        })
      }),
      ZIO.unsafeRun({})
    )
  }

  const callAPICompareOCR = () => {
    const { selectClient, idCitizenNumber: identifyNumberUserInput } = getValues()
    const { id: identifyNumberOCR, name: fullNameOCR, dob: dobOCR, sex: sexOCR, doe: idExprieDateOCR } = valuesForm

    const dataCompareOCR: ChangeNationalIdCardRequestDTO = {
      clientName: selectClient?.label?.split(new RegExp('-'))[0].trim() ?? '',
      clientNumber: selectClient?.value ?? '',
      identifyNumberUserInput: identifyNumberUserInput ?? '',
      identifyNumberOCR,
      fullNameOCR,
      idExprieDateOCR: moment(idExprieDateOCR, 'DD/MM/YYYY', true).isValid() ? idExprieDateOCR : null,
      dob: moment(dobOCR, 'DD/MM/YYYY').format('YYYYMMDD'),
      gender:
        sexOCR === 'N/A' ? 'N/A' : ChangeNationalIDCardService.removeAccents(sexOCR?.toUpperCase()) === 'NU' ? 'F' : 'M'
    }

    return pipe(
      ChangeNationalIDCardService.compareOCR(dataCompareOCR),
      ZIO.foldM(
        (_) =>
          ZIO.effect(() => {
            showGlobalLoading(false)
            showToast(translate_change_nationalID('common:SomeThingWentWrong'), 'error')
            return
          }),
        (success) => {
          showGlobalLoading(false)
          return ZIO.succeed(success ?? {})
        }
      ),
      ZIO.unsafeRun({})
    )
  }

  const callAPIScanIdentifyCard = (
    frontSideImage: File,
    callBack: ({
      data,
      errors,
      values
    }: {
      data: ScanIdentifyCardResponseType['data']
      errors: errorsFormCitizenType[]
      values: valuesFormCitizenType[]
    }) => void
  ) => {
    return pipe(
      ChangeNationalIDCardService.scanIdentifyCard(frontSideImage),
      ZIO.foldM(
        (_) =>
          ZIO.effect(() => {
            const { setStateButton, setError } = modalUploadCitizenRef?.current ?? {}
            setStateButton?.({
              loading: false,
              disable: false
            })
            return setError?.(translate_change_nationalID('common:SomeThingWentWrong'))
          }),
        (success) => {
          const { id, name, dob, doe, sex, id_prob, name_prob, dob_prob, doe_prob, sex_prob } = success ?? {}
          const errors: errorsFormCitizenType[] = [
            { key: 'id', percent: id_prob ?? '' },
            { key: 'name', percent: name_prob ?? '' },
            { key: 'dob', percent: dob_prob ?? '' },
            { key: 'doe', percent: doe_prob ?? '' },
            { key: 'sex', percent: sex_prob ?? '' }
          ]

          const values: valuesFormCitizenType[] = [
            { key: 'id', value: id ?? '' },
            { key: 'name', value: name ?? '' },
            { key: 'dob', value: dob ?? '' },
            { key: 'doe', value: doe ?? '' },
            { key: 'sex', value: sex ?? '' }
          ]

          callBack({ data: [success], errors, values })
          return ZIO.succeed(success)
        }
      ),
      ZIO.unsafeRun({})
    )
  }

  const callAPIGetFilePDF = () => {
    if (isEmpty(policyNum)) return console.error('Missing policy number')

    const getMetaFilesByAttachedFiles = () => {
      const currentDateFormat = moment().format('DD/MM/YYYY')
      return {
        type: TransactionType.CHANGE_NATIONAL_ID_CARD,
        doctype: 'Identify document',
        class: 'POLICYINFO',
        docid: '10205011',
        maindoc: 'IDENTIFICATION',
        subdoc: 'PERSONAL ID/BIRTH CERTIFICATE/ PASSPORT/VISA',
        batchno: `LandingPage_${currentDateFormat}`,
        polnum: policyNum
      }
    }

    const { groupCitizenUploads } = uploadImageCitizenRef?.current ?? {}

    if (isNil(groupCitizenUploads)) return

    const filesContent = groupCitizenUploads?.map((group) => {
      return {
        file: group.fileUpload?.file as File | Blob,
        metaData: getMetaFilesByAttachedFiles()
      }
    })

    return pipe(
      StorageBlob.uploadToSubmit('PS', policyNum, '')(filesContent),
      ZIO.tap((documents) => {
        const { getValues } = formCitizenRef?.current ?? {}
        const { setStateButton, setIsShowModalUpload } = modalUploadCitizenRef?.current ?? {}
        const { groupCitizenUploads } = uploadImageCitizenRef?.current ?? {}

        setStateButton?.({
          disable: false,
          loading: false
        })

        setValuesSubmission({
          filesPdf: [
            {
              id: Date.now(),
              name: 'CCCD.pdf',
              createDate: `${moment().format('DD/MM/YYYY hh:mm')}`,
              images: [...documents],
              fileUploads: (groupCitizenUploads?.map((elm) => elm.fileUpload) as FileUploadData[]) ?? []
            }
          ],
          valuesForm: getValues?.() ?? { name: '', dob: '', doe: '', id: '', sex: '' }
        })

        setTimeout(() => {
          clearDataFormModal()
          setIsShowModalUpload?.(false)
        }, 0)

        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  /**
   * Views
   */
  const renderTypeField = ({
    field,
    controller
  }: {
    field: FieldsType<typeNameField>
    controller: {
      field: ControllerRenderProps<FormData, typeNameField>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<FormData>
    }
  }) => {
    const { type, placeholder, required, options } = field
    const {
      field: { onChange, onBlur, value },
      fieldState: { error }
    } = controller

    const label = t(`CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.FIELDS.${field.formName}`)

    const fieldTypeMap = new Map<typeField | 'DEFAULT', Function>([
      [
        TypeFieldEnum['TEXTBOX'],
        () => (
          <Input
            title={label}
            disabled={isConfirmed}
            placeholder={placeholder}
            required={required}
            value={(value as string) || ''}
            onBlur={onBlur}
            onChange={onChange}
            errorMessage={!isEmpty(error) ? error?.message : ''}
          />
        )
      ],
      [
        TypeFieldEnum['DATE_PICKER'],
        () => (
          <DatePicker
            label={label}
            onBlur={onBlur}
            value={value as Date}
            disabled={isConfirmed}
            required={required}
            onChange={onChange}
            errorMessage={!isEmpty(error) ? error?.message : ''}
            maxDate={new Date()}
            invalidDateMessage=""
            maxDateMessage=""
            minDateMessage=""
          />
        )
      ],
      [
        TypeFieldEnum['DROPDOWN_LIST'],
        () => (
          <SelectSearch
            label={label}
            options={options}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value as any}
            disabled={isConfirmed}
            required={required}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            errorMessage={!isEmpty(error) ? error?.message : ''}
          />
        )
      ],
      ['DEFAULT', () => <></>]
    ])

    return (fieldTypeMap.get(type) ?? fieldTypeMap.get('DEFAULT'))?.()
  }

  const renderFields = (fields: FieldsType<typeNameField>[]) => {
    return fields.map((_field, index) => {
      const { formName, rules } = _field
      return (
        <Col_3 key={index}>
          <Controller
            name={formName}
            control={control}
            render={(controller) => <>{renderTypeField({ field: _field, controller })}</>}
            rules={rules}
          />
        </Col_3>
      )
    })
  }

  const renderGroupsViews = () => {
    return groupsViews?.map(({ fields, isVisible, isBorder, groupName }, index) => {
      return (
        <GROUP key={`${groupName}_${index}`}>
          <TextCustom fontSize="15px" fontWeight="800" textColor="black">
            {t(`CHANGE_NATIONALID_CARD_PS:GROUPS_VIEWS.LABEL_GROUP.${groupName}`)}
          </TextCustom>

          {isVisible ? (
            <Row
              style={[
                { marginTop: 12 },
                isBorder
                  ? {
                      borderRadius: 6,
                      paddingHorizontal: 12,
                      paddingVertical: 30,
                      borderStyle: 'solid',
                      borderColor: COLORS.HOWKES_BLUE,
                      borderWidth: 1
                    }
                  : {}
              ]}
            >
              {renderFields(fields)}
            </Row>
          ) : null}
        </GROUP>
      )
    })
  }

  const renderFilesPdf = () => {
    const handleDeletePdf = () => {
      return setValuesSubmission((prev) => ({
        ...prev,
        filesPdf: [],
        valuesForm: prev.valuesForm
      }))
    }

    return filesPdf.map(({ id, name, createDate }) => {
      return (
        <BoxPDF
          key={id}
          style={{
            marginRight: 24,
            marginTop: 24,
            borderBottomColor: COLORS.HOWKES_BLUE,
            borderBottomWidth: 2,
            borderStyle: 'dashed'
          }}
        >
          <BoxPDFLeft>
            <View>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png"
                alt="img_pdf"
                width={56}
                height={60}
              />
            </View>

            <View style={{ display: 'flex', flexDirection: 'column', marginLeft: 12 }}>
              <TextCustom textColor="black" fontSize="15px" fontWeight="800" style={{ marginBottom: 10 }}>
                {name}
              </TextCustom>
              <TextCustom fontWeight="400" fontSize="15px" fontStyle="italic" textColor={COLORS.ROLLING_STONE}>
                {createDate}
              </TextCustom>
            </View>
          </BoxPDFLeft>

          <TouchableOpacity onPress={handleDeletePdf} disabled={isConfirmed}>
            <assets.RemoveIcon />
          </TouchableOpacity>
        </BoxPDF>
      )
    })
  }

  const renderUploadArea = () => {
    return (
      <GROUP>
        <TextCustom fontSize="15px" fontWeight="800">
          {translate_change_nationalID('UPLOAD_AREA.TITLE')}
        </TextCustom>

        <TextCustom fontSize="15px" fontWeight="400" textColor={COLORS.ROLLING_STONE} style={{ marginTop: 12 }}>
          {translate_change_nationalID('UPLOAD_AREA.SUB_TITLE')}
        </TextCustom>

        <BoxUpload onPress={onOpenModalUploadCitizen} disabled={isConfirmed}>
          <assets.UploadCloud />
          <TextCustom fontSize="15px" fontWeight="700" textColor={COLORS.ALIZARIN} style={{ marginLeft: 8 }}>
            {translate_change_nationalID('UPLOAD_AREA.ACTION')}
          </TextCustom>
        </BoxUpload>
      </GROUP>
    )
  }

  const renderPDFArea = () => {
    if (isEmpty(filesPdf)) return

    return (
      <PDFArea>
        <TextCustom fontSize="15px" fontWeight="700" textColor={COLORS.ROLLING_STONE}>
          Chứng từ CCCD
        </TextCustom>
        <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{renderFilesPdf()}</View>
      </PDFArea>
    )
  }

  const renderModalUploadCitizen = () => {
    const handleSaveModalCitizen = () => {
      const { stepModal, setStepModal, setStateButton, setError } = modalUploadCitizenRef?.current ?? {}
      const { validateUploadFile, groupCitizenUploads } = uploadImageCitizenRef?.current ?? {}
      const { setValues, setErrors } = formCitizenRef?.current ?? {}

      const isStep1 = stepModal === 1

      if (isStep1) {
        return validateUploadFile?.(({ status, message }: CallBackValidateUploadFileType) => {
          if (status === STATUS['ERROR']) return setError?.(message ?? '')

          const frontSideImage = groupCitizenUploads?.[0]?.fileUpload?.file as File

          setStateButton?.({
            loading: true,
            disable: true
          })

          setError?.(message ?? '')

          return callAPIScanIdentifyCard(frontSideImage, ({ errors, values }) => {
            setStepModal?.(2)
            setStateButton?.({
              loading: false,
              disable: false
            })
            setTimeout(() => {
              setValues?.(values)
              setErrors?.(errors)
            }, 0)
            return
          })
        })
      }

      setStateButton?.({
        loading: true,
        disable: true
      })

      return callAPIGetFilePDF()
    }

    const handleCancelModalCitizen = () => {
      const { stepModal, setIsShowModalUpload } = modalUploadCitizenRef?.current ?? {}
      const isStep1 = stepModal === 1
      clearDataFormModal()
      isStep1 && setIsShowModalUpload?.(false)
    }

    return (
      <ModalUploadCitizen
        ref={modalUploadCitizenRef as RefObject<ModalUploadCitizenRef>}
        onSave={handleSaveModalCitizen}
        onCancel={handleCancelModalCitizen}
      >
        {(step) => {
          return (
            <>
              <GroupCitizen style={{ marginTop: 30 }}>
                <UploadImageCitizen ref={uploadImageCitizenRef as Ref<UploadCitizenRef>} stepModal={step} />
              </GroupCitizen>

              {<FormCitizen ref={formCitizenRef as Ref<FormCitizenRef>} stepModal={step} />}
            </>
          )
        }}
      </ModalUploadCitizen>
    )
  }

  const validateSuccess = (clientID: string, validOCR: string) => {
    return {
      url: () => `wf-api/customer/national-id-card/${clientID || ''}`,
      body: mappingBodySubmisson(validOCR),
      transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_NATIONAL_ID_CARD) || '',
      collerationId: policyNum || '',
      transaction: TransactionType.CHANGE_NATIONAL_ID_CARD,
      uploadedFilesInfo: getUploadedFilesInfo()
    }
  }

  initSubmission({
    validate: async () => {
      const isValidForm = await trigger()
      if (!isValidForm) return false
      if (isEmpty(filesPdf)) {
        showToast(t('message:MS150004'), 'error')
        return false
      }

      const data = await callAPICompareOCR()
      const validOCR = data?.oErrorResult?.errorDescription ?? ''

      if (validOCR === '1' || isAvailableShowAlert) {
        const { selectClient, idCitizenNumber, issuedBy, issuedDate } = watch()
        console.log({ selectClient, idCitizenNumber, issuedBy, issuedDate })

        const isValueInputEmpty = !selectClient?.value || !idCitizenNumber || !issuedBy?.value || !issuedDate
        if (isValueInputEmpty) return showToast(t('message:MS030197'), 'error')
        return validateSuccess(selectClient?.value ?? '', validOCR)
      }
      return await showAlert()
    },
    clear() {
      reset({ ...defaultValues })
      setValuesSubmission({
        filesPdf: [],
        valuesForm: { id: '', name: '', sex: '', dob: '', doe: '' }
      })
    }
  })

  return (
    <Container>
      {renderGroupsViews()}

      {renderUploadArea()}

      {renderPDFArea()}

      {renderModalUploadCitizen()}
    </Container>
  )
}

export default ChangeNationalIDCardScreen
