export namespace ChangeContactInfo {
  export interface ClientPolicy {
    policyNum: string
    name?: string
    address: string
  }
  export interface Detail {
    customerId: string
    policyNum: string
    street: string
    nationality: string
    province: string
    district: string
    ward: string
    clientPolicyList: Array<ClientPolicy>
    mobilePhoneCode: string
    mobilePhoneNumber: string
    phoneRelationship: string
    officePhoneCode: string
    officePhoneNumber: string
    smsIndicator: string
    email: string
    emailIndicator: string
    emailRelationship: string
    poName: string
    secuityNo: string
    dob: string
    gender: string
  }

  export interface DuplicateValue {
    clientId: string
    relationshipType: string
  }

  export interface DataSubmit {
    policyNo: string
    owners: {
      clientId: string
    }
    dispatchAddress?: {
      line1: string
      zipcode: string
      countryCode: string
      city: string
      district: string
      subDistrict: string
    }
    priorPolicies?: Array<{ policyNo: string }>
    contactDetails?: {
      EMAIL?: {
        value?: string
      }
      PHONE?: {
        value?: string
        countryCode?: string
      }
      OFFICE_PHONE?: {
        value?: string
        countryCode?: string
      }
      SMS_IND?: {
        value?: string
      }
      EMAIL_IND?: {
        value?: string
      }
    }
    attributesExt: {
      EMAILS_DUP: Array<{
        clientId: string
        relationshipType: string
      }>
      PHONES_DUP: Array<{
        clientId: string
        relationshipType: string
      }>,
      PO_OWNER_ID: string
      mainPhoneNumber: boolean
    }
  }

  export interface AccessDataCheck {
    contractStatus: string
    paidToDateAdvance: Date | null
  }

  export interface DuplicateInfo {
    clientId: string
    clientName: string
    role: string
    secuityNo: string
    dob: string
    gender: string
  }
}
