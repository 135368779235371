import * as React from 'react'
import { PolicyServiceProps } from '../../policy-service-props'
import {
  PartialWithdrawalService,
  ErrorHandling,
  PartialWithdrawal as PartialWithdrawalData,
  BeneficiaryDesignationService,
  AppContext
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ILP } from './ilp'
import { ULP } from './ulp'
import { ZIO } from '@mxt/zio'

interface Props extends PolicyServiceProps<PartialWithdrawalData.SubmitData> {
  policyNumber: string
}

export const PartialWithdrawal = (props: Props) => {
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const detail = pipe(
    ZIO.zipPar(
      PartialWithdrawalService.getDetail(props.policyNumber),
      BeneficiaryDesignationService.getSeaBankMessgeList(props.policyNumber ?? '', props.clientNumber ?? '')
    ),
    ZIO.map(([PWDetail, warningMessage]) => {
      if (!!warningMessage) {
        showToast(warningMessage, 'warning', undefined, 30000)
      }
      return {
        ...PWDetail,
        warningMessage
      }
    }),
    ErrorHandling.runDidUpdate([props.policyNumber])
  )
  // console.log('ahihi-detail: ', detail)
  return detail ? detail.ilp ? <ILP {...props} detail={detail} /> : <ULP {...props} detail={detail} /> : null
}
