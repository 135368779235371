import { Locale, TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-elements'

export const CommonRequest = ({ detail }: { detail?: TaskDetail.VeriCommonRequest }) => {
  const { t, i18n } = useTranslation()
  const getRequestName = () => {
    const requestItem = detail?.commonRequestList.find((x) => x.requestCode === detail.request)
    return requestItem ? (i18n.language === Locale.en ? requestItem.requestNameEn : requestItem.requestNameVn) : ''
  }

  return (
    <View style={styles.container}>
      <Text style={{ fontWeight: '700', color: 'grey' }}>{t('common:Request')}</Text>
      <Text>{getRequestName()}</Text>
      <View style={{ height: 20 }} />
      <Text>{t('message:MS050272')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    marginTop: 20
  }
})
