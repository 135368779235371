import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { HomeScreen } from './HomeScreen'
import { LandingPageStackParamList } from './LandingPageStackParamList'
import { PolicyListScreen } from './PolicyListScreen'
import { SpecialBonusScreen } from './SpecialBonusScreen'
import { SuccessScreen } from './SuccessScreen'
const Stack = createStackNavigator<LandingPageStackParamList>()

export const LandingPageStack = () => {
  return (
    <Stack.Navigator initialRouteName="HomeScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="PolicyListScreen" component={PolicyListScreen} />
      <Stack.Screen name="SpecialBonusScreen" component={SpecialBonusScreen} />
      <Stack.Screen name="SuccessScreen" component={SuccessScreen} />
    </Stack.Navigator>
  )
}
