/* eslint-disable no-extra-boolean-cast */
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { AuthService, POApi, SourceType } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import { PulseOpsFormat } from '@pulseops/common'
import { GenerateUUID } from '../common/SEAuuid'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'
export namespace SEABillingChangeService {
  export interface Column {
    id?: string
    name: string
    width?: number
    horizontal?: string
  }

  export interface Summary {
    fromDate: Date
    toDate: Date
    title: string
    status: string
  }
  export interface Table {
    ref: string
    rowRef: number
    colRef: number
  }
  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }
  export type RequestAuthPaper = {
    authFlag: boolean
    attachments: {
      url: string
      name: string
    }
  }
  export interface BodyUploadGuide {
    departmentCode: string
    group: string
    mainDoc: string
    subDoc: string
  }
  export interface FileContentSubmit {
    name: string
    filename?: string
    url: string
  }
  export const ResponseGuide = t.type({
    exchangeId: Maybe(t.string),
    body: Maybe(
      t.type({
        departmentCode: Maybe(t.string),
        group: Maybe(t.string),
        mainDoc: Maybe(t.string),
        subDoc: Maybe(t.string)
      })
    ),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.number),
        message: Maybe(t.string)
      })
    )
  })
  export const QueryData = t.type({
    source: Maybe(t.string),
    exchangeId: Maybe(t.string),
    createdBy: Maybe(t.string),
    action: Maybe(t.string),
    body: Maybe(
      t.type({
        templateFileName: Maybe(t.string),
        transactionType: Maybe(t.string)
      })
    ),
    createdDate: Maybe(t.string)
  })
  export const ExportData = t.type({
    body: Nullable(t.string)
  })

  export type ExportData = t.TypeOf<typeof ExportData>
  export type QueryData = t.TypeOf<typeof QueryData>

  export type ResponseGuide = t.Type<typeof ResponseGuide>
  export const submitGuide = (
    documents: FileContentSubmit[],
    userInfo: string,
    authenRequestForm: any,
    createdDate: string
  ) =>
    pipe(
      POApi.post(`banca-sea/billing-change/submit-import`)(ResponseGuide)({
        source: SourceType.BANCA_SEA,
        documents: documents,
        exchangeId: GenerateUUID.uuid(),
        createdBy: userInfo,
        authenRequestForm: authenRequestForm,
        createdDate: createdDate,
        authenOption: 'ATTACH_REQUEST_FORM', /// hardecode in DOC https://collaborate.pruconnect.net/pages/resumedraft.action?draftId=248152705&draftShareId=15828ebc-e64b-4405-bd8e-d8e6aa23a4a0&
        clientId: '12522298',
        officeCode: 'VCO'
      }),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (success) => ZIO.succeed(success)
      )
    )
  export const exportTemplateExcel = (query: QueryData) =>
    pipe(
      POApi.post(`banca-sea/billing-change/export-template-config-file`)(ExportData)(query),
      ZIO.map((res) => res)
    )

  export const exportTemplateExcelWithdraw = (query: QueryData) =>
    pipe(
      POApi.post(`banca-sea/general-config/export-template-config-file`)(ExportData)(query),
      ZIO.map((res) => res)
    )

  export const finalSentence = (mySentence: string) => {
    return mySentence?.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  }

  export const getReportData = (query: SEABillingChangeModal.ExportData) =>
    POApi.post(`banca-sea/billing-change/query-billing-change-import`)(SEABillingChangeModal.ReportsData)(query)

  export const submitCaseDetail = (
    id: string,
    body: {
      body: {
        id: string
        policyNumber: string
        currentBillingFrequency: string
        currentInstallmentPremium: string
        newDateEffect: string
        newBillingFrequency: string
        newInstallmentPremium: string
        requirePayInAmount: string
        lifeAssuredFulName: string
      }
    }
  ) =>
    pipe(
      POApi.post(`banca-sea/billing-change/update-billing-change-import/${id}`)(t.unknown)(body),
      ZIO.map((data) => data)
    )
  export const uploadCaseDetail = (body: {
    authenOption: string
    authenRequestForm: {
      authFlag: boolean
      signatureDocuments: any
    }
    body: {
      id: string
      policyNumber: string
      currentBillingFrequency: string
      currentInstallmentPremium: string
      newDateEffect: string
      newBillingFrequency: string
      newInstallmentPremium: string
      requirePayInAmount: string
      lifeAssuredFulName: string
    }
    clientId: string
    createdBy: string
    createdDate: string
    documents: any
    isCCE: boolean
    officeCode: string
    primaryPolicyNo: string
    source: string
    exchangeId: string
  }) =>
    pipe(
      POApi.post(`banca-sea/billing-change/manual/submit`)(t.unknown)(body),
      ZIO.map((data) => data)
    )

  export const getDetailCase = (id: string) =>
    POApi.get(`banca-sea/billing-change/get-billing-change-import/${id}`)(SEABillingChangeModal.DetailData)

  export const getCheckDuplicateFile = (id: string) => {
    return pipe(
      POApi.get(`banca-sea/billing-change/get-file-name-import/${id}`)(
        t.type({
          data: Maybe(t.string)
        })
      ),
      ZIO.map((res) => res.data)
    )
  }
  export const authFormUserCheckFlag = (processInstanceId: string) =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((info) =>
        POApi.getConfig({
          params: {
            processInstanceId,
            user: info.email
          }
        })(`pulseops/api/v1/sign-authentication/check-exist-flag-view`)(
          t.type({
            isFlag: t.boolean
          })
        )
      ),
      ZIO.map((x) => x.isFlag)
    )
  export const getRequestFormSign = (policyNum: string) =>
    pipe(
      POApi.getConfig({ params: { policyNum } })(`pulseops/api/v1/sign-authentication/get-sign-authentication`)(
        t.type({
          data: t.array(
            t.type({
              groupType: t.string,
              policyNumber: t.string,
              signFiles: t.array(
                t.type({
                  metaData: t.type({
                    batchno: Maybe(t.string),
                    docid: Maybe(t.string),
                    doctype: Maybe(t.string),
                    maindoc: Maybe(t.string),
                    subdoc: Maybe(t.string)
                  }),
                  url: t.string
                })
              ),
              transactionType: t.string
            })
          )
        })
      )
    )
  const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  export const exportTemplate = (
    tableInfo: Table,
    summary: Summary,
    columns: Column[],
    rows: any[][],
    extension: string = Extension.EXCEL,
    fullFileName?: string,
    fullSheetName?: string
  ) => {
    const fileName = !!fullFileName ? fullFileName : 'Template Export'
    const sheetName = !!fullSheetName ? fullSheetName : 'Template Export'
    const horizontal = 'center'
    const vertical = 'middle'
    const wrapText = true
    const bold = true

    const isExcel = extension === Extension.EXCEL

    const { colRef, rowRef, ref } = tableInfo
    const { status, fromDate, toDate, title } = summary
    const rowEnd = rows.length + rowRef

    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName)

    ws.views = [{ showGridLines: false }]
    columns.forEach((column, cIndex) => {
      const index = colRef + cIndex
      const width = column.width
      const horizontal = column.horizontal as any
      ws.getColumn(index).alignment = { horizontal, vertical, wrapText }
      ws.getColumn(index).width = width
      ws.getCell(1, index).value = '' // init for export csv
      for (let rIndex = rowRef; rIndex <= rowEnd; rIndex++) {
        ws.getCell(rIndex, index).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }
    })

    const writeExcel = () => {
      ws.mergeCells('B2:L2')
      ws.getCell('B2').value = title
      ws.getCell('B2').style = { font: { bold, size: 13 } }
      ws.getCell('B2').alignment = { vertical, horizontal }
      ws.getCell('B4').alignment = { horizontal: 'left' }
    }

    const writeCSV = () => {
      ws.getCell('B2').value = title
      ws.getCell('B4').value = `Reporting period: ${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
      ws.getCell('B5').value = `Reporting date: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`
      ws.getCell('B6').value = `Category: ${status}`
    }
    isExcel ? writeExcel() : writeCSV()
    ws.addTable({
      name: 'Template Export',
      ref,
      headerRow: true,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true
      },
      columns,
      rows
    })

    return pipe(
      ZIO.fromPromise(() => (isExcel ? wb.xlsx : wb.csv).writeBuffer()),
      ZIO.map((data) => new Blob([data], { type })),
      ZIO.tap((blob) => {
        FileSaver.saveAs(blob, `${fileName}.${extension}`)
        return ZIO.unit
      })
    )
  }
}
