import { Maybe, Nullable } from '@mxt/zio/codec'
import { SelectOption } from '@pulseops/common'
import * as t from 'io-ts'

const BOAddressC = t.type({ address: t.string, addressType: t.string, country: t.string })
const companyInfoC = t.type({
  // _id: t.string,
  companyName: t.string,
  contactPerson: t.string,
  contactNo: t.string,
  contactEmail: t.string,
  contactIdNo: t.string,
  businessNature: t.string,
  businessType: t.string,
  position: t.string,
  registrationDate: Maybe(t.string),
  registrationPlace: t.string,
  registrationCountry: t.string,
  addresses: t.array(BOAddressC),
  createdBy: t.string,
  updatedBy: t.string,
  status: t.string,
  createdAt: t.string,
  updatedAt: t.string
})

const companyPeopleC = t.type({
  fullName: t.string,
  dob: t.string,
  gender: t.string,
  occupationClass: Maybe(t.string),
  idNo: t.string,
  companyRole: t.string,
  nationality: t.string,
  addresses: t.array(BOAddressC),
  // warnings: [],
  otherNationality: t.string
})

// const ageDataC = t.type({
//   "0": Maybe(t.number),
//   "1": Maybe(t.number),
//   "2": Maybe(t.number),
//   "3": Maybe(t.number),
//   "4": Maybe(t.number),
//   "5": Maybe(t.number),
//   "6": Maybe(t.number),
//   "7": Maybe(t.number),
//   "8": Maybe(t.number),
//   "9": Maybe(t.number),
//   "10": Maybe(t.number),
//   "11": Maybe(t.number),
//   "12": Maybe(t.number),
//   "13": Maybe(t.number),
//   "14": Maybe(t.number),
//   "15": Maybe(t.number),
//   "16": Maybe(t.number),
//   "17": Maybe(t.number),
//   "18": Maybe(t.number),
//   "19": Maybe(t.number),
//   "20": Maybe(t.number),
//   "21": Maybe(t.number),
//   "22": Maybe(t.number),
//   "23": Maybe(t.number),
//   "24": Maybe(t.number),
//   "25": Maybe(t.number),
//   "26": Maybe(t.number),
//   "27": Maybe(t.number),
//   "28": Maybe(t.number),
//   "29": Maybe(t.number),
//   "30": Maybe(t.number),
//   "31": Maybe(t.number),
//   "32": Maybe(t.number),
//   "33": Maybe(t.number),
//   "34": Maybe(t.number),
//   "35": Maybe(t.number),
//   "36": Maybe(t.number),
//   "37": Maybe(t.number),
//   "38": Maybe(t.number),
//   "39": Maybe(t.number),
//   "40": Maybe(t.number),
//   "41": Maybe(t.number),
//   "42": Maybe(t.number),
//   "43": Maybe(t.number),
//   "44": Maybe(t.number),
//   "45": Maybe(t.number),
//   "46": Maybe(t.number),
//   "47": Maybe(t.number),
//   "48": Maybe(t.number),
//   "49": Maybe(t.number),
//   "50": Maybe(t.number),
//   "51": Maybe(t.number),
//   "52": Maybe(t.number),
//   "53": Maybe(t.number),
//   "54": Maybe(t.number),
//   "55": Maybe(t.number),
//   "56": Maybe(t.number),
//   "57": Maybe(t.number),
//   "58": Maybe(t.number),
//   "59": Maybe(t.number),
//   "60": Maybe(t.number),
//   "61": Maybe(t.number),
//   "62": Maybe(t.number),
//   "63": Maybe(t.number),
//   "64": Maybe(t.number),
//   "65": Maybe(t.number),
//   "66": Maybe(t.number),
//   "67": Maybe(t.number),
//   "68": Maybe(t.number),
//   "69": Maybe(t.number),
//   "0-17": Maybe(t.number),
//   "18-34": Maybe(t.number),
//   "35-49": Maybe(t.number),
//   "50-59": Maybe(t.number),
//   "60-64": Maybe(t.number),
//   "65-69": Maybe(t.number)
// })
// const ageDataShortC = t.type({
//   "0-17": Maybe(t.number),
//   "18-34": Maybe(t.number),
//   "35-49": Maybe(t.number),
//   "50-59": Maybe(t.number),
//   "60-64": Maybe(t.number),
//   "65-69": Maybe(t.number)
// })

const OptionC = t.type({ code: t.string, value: t.string })
const OccupationClassC = t.type({
  // _id: t.string,
  class: t.string,
  code: t.string,
  name: t.string,
  displayName: t.string,
  locales: t.array(OptionC)
})

const benefitInfoRiderC = t.type({
  _id: t.string,
  createdAt: t.string,
  updatedAt: t.string,
  assuredType: t.string,
  currency: t.string,
  status: t.string,
  displayName: t.string,
  name: t.string,
  locales: t.array(OptionC),
  riderType: t.string,
  benefitAttributes: t.type({
    // months: t.null,
    planName: Maybe(t.string),
    inPatient: Maybe(t.string),
    outPatient: Maybe(t.string),
    maternity: Maybe(t.string)
  }),
  geographicalCover: t.string,
  riderId: t.string,
  occupationClassCode: t.string,
  occupationClass: OccupationClassC,
  calculatedPremium: t.number
})

const benefitInfoC = t.type({
  classId: t.string,
  classPremium: t.number,
  basicPlan: Maybe(
    t.type({
      // _id: t.string,
      createdAt: t.string,
      updatedAt: t.string,
      assuredType: t.string,
      currency: t.string,
      status: t.string,
      displayName: t.string,
      name: t.string,
      locales: t.array(OptionC),
      // premiumRate: t.array(t.type({
      //   employee: t.type({
      //     m: ageDataC,
      //     f: ageDataC
      //   }),
      //   dependent: t.type({
      //     m: ageDataC,
      //     f: ageDataC
      //   })
      // })),
      benefitType: t.string,
      benefitAttributes: t.type({ months: t.number }),
      geographicalCover: t.string,
      benefitId: t.string,
      sumOfAssured: t.number,
      occupationClassCode: t.string,
      occupationClass: OccupationClassC,
      calculatedPremium: t.number
    })
  ),
  riders: Maybe(t.array(benefitInfoRiderC)),
  name: t.string,
  // distribution: t.type({
  //   employee: t.type({
  //     "m": ageDataShortC,
  //     "f": ageDataShortC
  //   }),
  //   dependent: t.type({
  //     "m": ageDataShortC,
  //     "f": ageDataShortC
  //   })
  // }),
  allowDependents: t.boolean,
  allowEmployees: t.boolean
})
const BOWarningC = t.type({
  type: t.string,
  message: t.string,
  remark: t.string
})

export const BOEmployeeDependentsC = t.type({
  firstName: t.string,
  surname: t.string,
  nationalId: t.string,
  nationality: t.string,
  occupationClassCode: t.string,
  dob: t.string,
  gender: t.string,
  phoneNo: t.string,
  addresses: t.array(BOAddressC),
  emailAddress: t.string,
  benefitClassId: t.string,
  employeeNumber: t.string,
  relationship: t.string,
  avgSalary: t.number
  // warnings: t.array(BOWarningC)
})

export const BOEmployeeC = t.type({
  _id: t.string,
  firstName: t.string,
  surname: t.string,
  nationalId: t.string,
  nationality: t.string,
  occupationClassCode: t.string,
  dob: t.string,
  gender: t.string,
  phoneNo: t.string,
  addresses: t.array(BOAddressC),
  emailAddress: t.string,
  benefitClassId: t.string,
  employeeNumber: t.string,
  avgSalary: t.number,
  warnings: t.array(BOWarningC),
  dependents: t.array(BOEmployeeDependentsC),
  quotationId: t.string,
  createdAt: t.string,
  updatedAt: t.string
})

export type BOEmployee = t.TypeOf<typeof BOEmployeeC>

export const BOModelC = t.type({
  // paymentFrequency: t.string,
  status: t.string,
  // halfYearlyPremium: t.number,
  // totalPremium: t.number,
  // "version": 5,
  // _id: t.string,
  quotationNo: t.string,
  createdBy: t.string,
  // updatedBy: t.string,
  // name: t.string,
  company: companyInfoC,
  benefits: t.array(benefitInfoC),
  // totalNoOfEmployees: t.number,
  // lastCalculationDate: t.string,
  agentCode: t.string,
  createdAt: t.string,
  updatedAt: t.string,
  // seqId: t.number,
  // "bankBranchCode": "022",
  // "bankName": "HS Bank",
  // "bankStaffCode": "Staff-0123",
  // "companyAddress": "",
  companyBrNo: Maybe(t.string),
  companyPeople: t.array(companyPeopleC),
  // "companyTaxCode": "8888",
  effectiveDate: t.string,
  // "nameOfBankStaff": "Staff",
  // "referralDate": "2021-09-01T02:55:55.153Z",
  // "referredCode": "Ref-999",
  // "takeoverEffectiveStartDate": "2000-09-01T02:58:00.000Z",
  // "takeoverGroupPolicyNo": "10000009",
  // "takeoverInsurerName": "insurer name",
  employees: Nullable(t.array(BOEmployeeC))
  // "documents": [
  //   {
  //     "GUID": "cb06ebc9-512f-4b75-a98e-95011d738924",
  //     "type": "Business Registration",
  //     "property": "",
  //     "fileName": "import-file.pdf",
  //     "filePath": "612eeb83ffe6490028904f77/br/cb06ebc9-512f-4b75-a98e-95011d738924.pdf",
  //     "status": "active",
  //     "lastUploadTime": "2021-09-01T03:06:44.560Z"
  //   },
  //   {
  //     "GUID": "d67288a7-8b52-4450-b60b-0abb27ad72e9",
  //     "type": "Financial Document",
  //     "property": "",
  //     "fileName": "import-file.pdf",
  //     "filePath": "612eeb83ffe6490028904f77/fi/d67288a7-8b52-4450-b60b-0abb27ad72e9.pdf",
  //     "status": "active",
  //     "lastUploadTime": "2021-09-01T03:06:54.528Z"
  //   }
  // ],
  // "formBundle": {
  //   "status": "signed",
  //   "_id": "612f31dfffe649002890691b",
  //   "createdBy": "88888885",
  //   "quotationId": "612eeb83ffe6490028904f77",
  //   "version": 5,
  //   "applicationFormFileName": "612eeb83ffe6490028904f77_applicatation_form_202109010755099513.pdf",
  //   "applicationFormFilePath": "612eeb83ffe6490028904f77/application-form/612eeb83ffe6490028904f77_applicatation_form_202109010755099513.pdf",
  //   "quotationFormFileName": "612eeb83ffe6490028904f77_quotation_form_2021090107551010443.pdf",
  //   "quotationFormFilePath": "612eeb83ffe6490028904f77/quotation-form/612eeb83ffe6490028904f77_quotation_form_2021090107551010443.pdf",
  //   "createdAt": "2021-09-01T07:55:11.230Z",
  //   "updatedAt": "2021-09-01T07:59:56.472Z",
  //   "lastUploadTime": "2021-09-01T07:59:56.472Z",
  //   "signedApplicationFormFileGUID": "f919887e-549f-4fa3-946f-184c84a8315a",
  //   "signedApplicationFormFileName": "f919887e-549f-4fa3-946f-184c84a8315a.pdf",
  //   "signedApplicationFormFilePath": "612eeb83ffe6490028904f77/application-form/f919887e-549f-4fa3-946f-184c84a8315a.pdf",
  //   "signedQuotationFormFileGUID": "e040c131-7785-4844-a9b4-cb0e3d7d6602",
  //   "signedQuotationFormFileName": "e040c131-7785-4844-a9b4-cb0e3d7d6602.pdf",
  //   "signedQuotationFormFilePath": "612eeb83ffe6490028904f77/quotation-form/e040c131-7785-4844-a9b4-cb0e3d7d6602.pdf"
  // }
})

export const BOGetAllC = t.type({
  data: t.array(BOModelC),
  total: t.number
})

export type BOModel = t.TypeOf<typeof BOModelC>

export interface BOCompanyBenInfo {
  fullName: string
  dob: string
  gender: string
  nationalId: string
  nationalIdIssueDate: string
  nationalIdIssuePlace: string
  nationality: string
  otherNationality: string
  visa: string
  addressPerm: string
  addressCurr: string
  addressOversea: string
  position: string
  mobile: string
  capitalContribute: string
}
export interface BOBenefitsRiders {
  classId: string
  name: string
  region: string
  benefit: string
  program: string
  hospitalized: string
  unHospitalized: string
  pregnancy: string
}

export interface Employee {
  policyNum: string
  fullName: string
  employeeCode: string
  occupation: string
  gender: string
  mobile: string
  email: string
  nationID: string
  nationIDCreatedDate: string
  nationIDCreatedLocation: string
  dob: string
  maritalStatus: string
  nationality: string
  otherNationality: string
  visa: string
  addressPerm: string
  addressCurr: string
  addressOversea: string
  benefitGroup: string
  bankAccont: string
  bankName: string
  bankBranch: string
  AML: string
  overFCL?: string
}

export interface Dependent {
  fullName: string
  agentCode: string
  employeeName: string
  employeeCode: string
  mobile: string
  email: string
  nationID: string
  nationIDCreatedDate: string
  nationIDCreatedLocation: string
  dob: string
  maritalStatus: string
  nationality: string
  otherNationality: string
  visa: string
  addressPerm: string
  addressCurr: string
  addressOversea: string
  benefitGroup: string
  AML: string
  relationship: string
}
export interface BOModelMapping {
  policyNum: string
  proposalNum: string
  createdDate: string
  effectiveDate: string
  duePremiumDate: string
  status: string
  company: {
    companyName: string
    companyEmail: string
    headquaterAddress: string
    headquaterCountry: string
    contactAddress: string
    contactCountry: string
    mobile: string
    email: string
    companyBrNo: string
    registrationDate: string
    registrationPlace: string
    businessNature: string
    legalRepresentative: {
      repName: string
      repTitle: string
      nationID: string
      nationIDCreatedDate: string
      nationIDCreatedLocation: string
      repDOB: string
      repGender: string
      repNation: string
      repOtherNation: string
      repVISA: string
      repPhone: string
      repEmail: string
      repAddress: string
      repAddressNation: string
      repAddressCurr: string
      repAddressCurrNation: string
      repAddress1: string
      repAddress1Nation: string
    }
    USrelated: string
    RegulatedStockSxchange: string
    contactPerson: {
      benRepName: string
      benRepTitle: string
      benRepNationID: string
      benRepPhone: string
      benRepEmail: string
    }
    beneficalOwner: BOCompanyBenInfo[]
  }
  benefits: {
    groupBasic: {
      classId: string
      name: string
      region: string
      amount: string
    }[]
    riders: BOBenefitsRiders[]
  }
  employees: Employee[]
  employeesOverFCL: Employee[]
  dependents: Dependent[]
  agent: {
    agentCode: string
    agentname: string
    agentEmail: string
    agentMobile: string
  }
}
export interface UWFCLData {
  firstName: string
  gender: string
  surname: string
  nationalId: string
  nationality: string
  occupationClassCode: string
  dob: string
  phoneNo: string
  emailAddress: string
  benefitClassId: string
  employeeNumber: string
  quotationId: string
  overFCL: string
  uwDecision: SelectOption | null
  comment: string | undefined
  edited: boolean
}

export const BOUWModelC = t.type({
  data: t.array(
    t.type({
      createdBy: Maybe(t.string),
      createdDate: Maybe(t.string),
      finalUwDecision: Maybe(t.string),
      quotationNo: Maybe(t.string)
    })
  )
})
export type BOUWModel = t.TypeOf<typeof BOUWModelC>

export const BOUWModelEmployeeC = t.type({
  data: t.array(
    t.type({
      benefitClassId: Maybe(t.string),
      comment: Maybe(t.string),
      // createdAt: Maybe(t.string),
      createdBy: Maybe(t.string),
      createdDate: Maybe(t.string),
      dob: Maybe(t.string),
      emailAddress: Maybe(t.string),
      employeeNumber: Maybe(t.string),
      firstName: Maybe(t.string),
      gender: Maybe(t.string),
      nationalId: Maybe(t.string),
      nationality: Maybe(t.string),
      occupationClassCode: Maybe(t.string),
      overFLCAmount: t.number,
      phoneNo: Maybe(t.string),
      quotationId: Maybe(t.string),
      quotationNo: Maybe(t.string),
      surname: Maybe(t.string),
      uwDecision: Maybe(t.string),
      id: Maybe(t.string)
    })
  )
})
export type BOUWModelEmployee = t.TypeOf<typeof BOUWModelEmployeeC>

export const BOVerifyModelC = t.type({
  data: t.array(
    t.type({
      checklistName: Maybe(t.string),
      comment: Maybe(t.string),
      completeDate: Maybe(t.string),
      createdBy: t.string,
      createdDate: Maybe(t.string),
      decision: Maybe(t.boolean),
      requestDate: Maybe(t.string),
      status: Maybe(t.string)
    })
  )
})
export type BOVerifyModel = t.TypeOf<typeof BOVerifyModelC>

export const BOPremiumDecisionModelC = t.type({
  data: t.array(
    t.type({
      checklistName: Maybe(t.string),
      comment: Maybe(t.string),
      completeDate: Maybe(t.string),
      createdBy: t.string,
      createdDate: Maybe(t.string),
      decision: Maybe(t.boolean),
      requestDate: Maybe(t.string),
      status: Maybe(t.string)
    })
  )
})
export type BOPremiumDecisionModel = t.TypeOf<typeof BOPremiumDecisionModelC>
