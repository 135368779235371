import { SelectOption } from "@pulseops/common";

export namespace OBCallingRecordForm {

  export const defaultFormValues = {
    callReceiver: undefined,
    callingMethod: { label: 'None', value: 'CM01' },
    callbackTime: null,
    doNotContact: undefined,
    callingResult: undefined,
    quickNote: '',
    callScript: null,
    hint: undefined,
    sendingType: undefined,
    guidelineDocument: undefined,
    phoneNumber: '',
    time: null,
    length: ''
  }

  export interface OBCallingRecord {
    callReceiver: SelectOption //callingReceiver
    callingMethod: SelectOption
    callbackTime: Date | null//callbackTime
    doNotContact: SelectOption //requestDeniedContact
    callingResult: SelectOption | undefined //callingResult
    quickNote: string //quickNote
    callScript: SelectOption | null //callingScenario
    hint: SelectOption //suggestion
    sendingType: SelectOption // chua co
    guidelineDocument: SelectOption // chua co
    phoneNumber: string // manualPhoneCallNumber
    time: Date | null // manualCallTime
    length: string // manualDuration
  }

}