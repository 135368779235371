import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, ScrollView, Text, Modal, Pressable, ActivityIndicator, SafeAreaView, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import { ErrorHandling, useMobile, PulseOpsFormat, AppContext } from '@pulseops/common'
import { BankTransferView, PayoutMethodRef } from './bank-transfer'
import { SC as SCore, assets } from './core'
import OtpInput from 'react-otp-input'
import { Services, PayoutPopup } from './core/services'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { StackScreenProps } from '@react-navigation/stack'
import { LandingPageStackParamList } from './LandingPageStackParamList'
import { useNavigation } from '@react-navigation/native'
import { Header as NewHeader } from './Header'

type Props = StackScreenProps<LandingPageStackParamList, 'SpecialBonusScreen'>

const convertMinute = (seconds: number): string => {
  const format = (val: number) => `0${Math.floor(val)}`.slice(-2)
  //const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  return [minutes, seconds % 60].map(format).join(':')
}

const TIMEOUT = 120
const RESEND_TIME = 3
const TIME_LOCK = '24h'

export const SpecialBonusScreen = (props: Props) => {
  const { t, i18n } = useTranslation(['landingPage', 'common', 'message'])
  const { reset } = useNavigation()
  const { isWide } = useMobile()
  const [loading, bindLoader] = useLoading(false)
  const [visible, setIsVisible] = React.useState<boolean>(false)
  const [otp, setOtp] = React.useState<string>('')
  const bankTransferRef = React.useRef<PayoutMethodRef | null>(null)
  const [sumary, setSumary] = React.useState<PayoutPopup.PayoutData[]>([])
  const [time, setTime] = React.useState<number>(120)
  const [errorOtp, setErrorOtp] = React.useState<string | null>(null)
  const [__, setDisableOtp] = React.useState<boolean>(false)
  const [submitting, bindSubmitting] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [otpTime, setOtpTime] = React.useState<number>(0)

  const { idNumber, dob, phoneNumber, policyNumber } =
    props.route.params ||
    {
      // policyNumber: '72368347',
      // phoneNumber: '0945738995',
      // dob: '19570815',
      // idNumber: '61755561'
    }

  React.useEffect(() => {
    if (!idNumber || !dob || !phoneNumber || !policyNumber) {
      setTimeout(() => props.navigation.navigate('HomeScreen'), 0)
    }
  }, [])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const { specialBonus, ownerInfo, riderList } = pipe(
    ZIO.zipPar(
      Services.getSpecicalBonusAmount({
        idNumber: idNumber,
        dob: dob,
        phoneNumber: phoneNumber,
        policyNo: policyNumber
      }),
      Services.getOwnerInfo(policyNumber),
      Services.getRiderTable
    ),
    ZIO.map(([bonusDetail, ownerInfo, riderList]) => {
      return {
        specialBonus: bonusDetail.specialBonusAmount,
        ownerInfo,
        riderList
      }
    }),
    ZIO.catchAll(() =>
      ZIO.effect(() => {
        showToast(t('common:SomeThingWentWrong'), 'error', {
          horizontal: 'center',
          vertical: 'bottom'
        })
      })
    ),
    bindLoader,
    ErrorHandling.runDidMount()
  ) || {
    specialBonus: 0,
    ownerInfo: { phoneNumber: '', nationalId: '', ownerName: '', ownerId: '', email: '', basicProductCode: '' },
    riderList: []
  }

  const onSubmit = (payout: PayoutPopup.PayoutData) => {
    setSumary([payout])
    const totalPayout = payout.amount
    if (totalPayout === 0) {
      showToast(t('message:MS050034'), 'error', {
        horizontal: 'center',
        vertical: 'bottom'
      })
    } else {
      sendOtp()
    }
  }

  const sendOtp = () => {
    if (otpTime > RESEND_TIME) {
      setIsVisible(false)
      setOtp('')
      showToast(t('message:MS050254', { t: TIME_LOCK }), 'error')
      return
    }
    setIsVisible(true)
    setTime(TIMEOUT)
    setOtpTime(otpTime + 1)
    const sendDate = moment().format('yyMMDDhhmmss').substr(2, 12)
    const transactionCode = `PS-${policyNumber}-${sendDate}`
    setDisableOtp(true)
    return pipe(
      Services.otpRequest('Bao tuc tich luy dac biet', transactionCode, phoneNumber, policyNumber),
      ZIO.tap(() => {
        setDisableOtp(false)
        return ZIO.unit
      }),
      ZIO.tapError(() => {
        setDisableOtp(false)
        return ZIO.unit
      }),
      ZIO.catchAll((e) => {
        setDisableOtp(false)
        // showToast(_.get(e, 'source.response.data.header.responseStatus.errors.0.code'), 'error')
        const code = _.get(e, 'source.response.data.header.responseStatus.errors.0.code')
        const time =
          (_.get(e, 'source.response.data.header.responseStatus.errors.0.message') ?? '').replace('t=', '') + 'p'
        setErrorOtp(t(`message:${code}`, { t: time }))
        return ZIO.unit
      }),
      ErrorHandling.run()
    )
  }

  React.useEffect(() => {
    let timer: number | false

    if (time === 0) {
      // sendOtp()
      // setTime(120)
    }

    if (visible) {
      timer = time > 0 && setInterval(() => setTime(time - 1), 1000)
    } else {
      setTime(120)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [time, visible])

  React.useEffect(() => {
    if (otp.length === 6) {
      //console.log('submit data aaaa')
      submitData()
    }
  }, [otp])

  const submitData = () => {
    return pipe(
      Services.submitSpecialBonus(
        policyNumber,
        mapData(),
        otp,
        ownerInfo.ownerId,
        ownerInfo.phoneNumber,
        ownerInfo.ownerName
      ),
      ZIO.tap((data) => {
        setIsVisible(false)
        showToast(t('message:MS040011'), 'success')
        //navigate('SuccessScreen')
        reset({
          index: 1,
          routes: [
            {
              name: 'HomeScreen'
            },
            {
              name: 'SuccessScreen'
            }
          ]
        })
        return ZIO.unit
      }),
      ZIO.mapError((err) => {
        const errors = _.get(err, 'source.response.data.responseStatus.errors')
        if (errors && Array.isArray(errors)) {
          const code = errors[0].code
          const time = (errors[0].message.replace('t=', '')?.replace('.0', '') ?? '') + 'h'

          const getErrorMess =
            code === 'MS050252'
              ? t('message:MS050252', { t: time })
              : code === 'MS050254'
              ? t('message:MS050254', { t: time })
              : code === 'MS050253'
              ? t('message:MS050253')
              : code === 'MS050255'
              ? t('message:MS050255')
              : 'Unknown error'
          setErrorOtp(getErrorMess)
        } else {
          setErrorOtp('Unknow error')
        }
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        return ZIO.unit
      }),
      bindSubmitting,
      ErrorHandling.run()
    )
  }

  const mapData = (): Record<string, unknown> => {
    const cashOutOptions = sumary.map((s) => ({
      type: 'BANKTRANSFER',
      policy: {
        policyNo: policyNumber
      },
      paymentOption: {
        currency: 'VND',
        amount: specialBonus ?? 0
      },
      bankAccount: {
        bankName: s.bankName,
        branchCode: s.bankBranchCode,
        accountNo: s.bankAccountNum,
        accountName: s.bankAccountName
      }
    }))

    const productRider = riderList.find((r) => r.code === ownerInfo.basicProductCode)
    const productName = productRider ? (i18n.language === 'vi' ? productRider.nameVi : productRider.name) : ''
    const branchName = sumary.length > 0 ? sumary[0].bankBranchName : ''

    return {
      policy: {
        policyNo: policyNumber
      },
      attributesExt: {
        PO_NAME: ownerInfo.ownerName ?? '',
        PO_ID_NUM: ownerInfo.nationalId ?? '',
        PO_MOBILE: ownerInfo.phoneNumber ?? '',
        PO_EMAIL: ownerInfo.email,
        BASIC_PRODUCT_NAME: productName,
        BANK_BRANCH_NAME: branchName,
        TOTAL_PAYOUT_AMOUNT: specialBonus ?? 0,
        SPECIAL_BONUS: specialBonus ?? 0
      },
      cashOutOptions
    }
  }

  const resendHandle = () => {
    if (time === 0) {
      sendOtp()
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, height: '100%' }}>
      <NewHeader isWide={isWide} t={t} />
      <SC.Container style={{ width: '100%' }}>
        {/* <Header policyNumber={policyNumber} policyOwner={`${ownerInfo.ownerName}`} ownerId={`${ownerInfo.ownerId}`} /> */}
        <SC.ScrollContainer>
          <View style={isWide ? styles.wrapContainer : styles.wrapContainerMobile}>
            <InfoCard
              t={t}
              title={t('special_bonus_info')}
              policyNumber={policyNumber}
              policyOwner={`${ownerInfo.ownerName}`}
              ownerId={`${ownerInfo.ownerId}`}
              specialBonus={specialBonus}
              isWide={isWide}
            />
            <View
              style={{
                marginLeft: isWide ? 45 : 20,
                marginRight: isWide ? 0 : 20,
                maxWidth: isWide ? 550 : '100%',
                marginTop: isWide ? 0 : 30
              }}
            >
              <BankTransferView
                ref={bankTransferRef}
                primaryPolicy={policyNumber}
                maxAmount={specialBonus ?? 0}
                onPress={() => bankTransferRef.current?.submit(onSubmit)}
              />
            </View>
          </View>
        </SC.ScrollContainer>
      </SC.Container>
      <Modal visible={visible} transparent={true} animationType="fade">
        <SCore.Modal.Container>
          <SCore.Modal.View>
            <SCore.Modal.Title>{t('otpTitle')}</SCore.Modal.Title>
            <SCore.Modal.Text>Nhập mã OTP được gửi tới {ownerInfo.phoneNumber}</SCore.Modal.Text>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              separator={<View style={{ width: 15 }} />}
              isDisabled={submitting}
              inputStyle={{
                width: 44,
                height: 44,
                fontSize: 15,
                borderRadius: 8,
                background: '#E5EAEF',
                borderWidth: 0,
                color: '#70777E'
              }}
              containerStyle={{
                alignSelf: 'center'
              }}
              placeholder="------"
            />
            {errorOtp && <SC.HelperText>{errorOtp}</SC.HelperText>}
            <Pressable onPress={resendHandle} disabled={time !== 0}>
              <SC.SendText disabled={time !== 0 || otpTime > RESEND_TIME}>
                Gửi lại {time !== 0 ? `sau ${convertMinute(time)}` : null}
              </SC.SendText>
            </Pressable>
            {submitting && <ActivityIndicator color={'primary'} />}
          </SCore.Modal.View>
        </SCore.Modal.Container>
      </Modal>
    </SafeAreaView>
  )
}

const InfoCard = (props: any) => {
  const { t, isWide } = props
  return (
    <View style={{ maxWidth: isWide ? 561 : '100%', margin: isWide ? 'none' : 20 }}>
      <SC.SessionText isWide={isWide}>{props.title ?? ''}</SC.SessionText>
      <SC.CardBorder isWide={isWide}>
        <SC.ItemInfoContainer>
          <SC.Icon>
            <assets.PolicyNumber />
          </SC.Icon>
          <View>
            <SC.TitleText isWide={isWide}>{t('policy_number')}</SC.TitleText>
            <SC.NormalText isWide={isWide}>{props.policyNumber ?? ''}</SC.NormalText>
          </View>
        </SC.ItemInfoContainer>
        <SC.ItemInfoContainer>
          <SC.Icon>
            <assets.PolicyOwner />
          </SC.Icon>
          <View>
            <SC.TitleText isWide={isWide}>{t('policy_owner')}</SC.TitleText>
            <SC.NormalText isWide={isWide}>{props.ownerId ?? ''}</SC.NormalText>
            <SC.NormalText isWide={isWide}>{props.policyOwner ?? ''}</SC.NormalText>
          </View>
        </SC.ItemInfoContainer>
        <SC.ItemInfoContainer marginBottom="0">
          <SC.Icon>
            <assets.SpecialBonusMoney />
          </SC.Icon>
          <View>
            <SC.TitleText isWide={isWide}>{t('special_bonus')}</SC.TitleText>
            <View>
              <SC.HighLightText isWide={isWide}>{`${
                PulseOpsFormat.thousandSepartor(props.specialBonus ?? 0) ?? '-'
              } VND`}</SC.HighLightText>
            </View>
          </View>
        </SC.ItemInfoContainer>
      </SC.CardBorder>
    </View>
  )
}

const SC = {
  Container: styled(View)`
    flex: 1;
    background-color: #f9f9f9;
    align-self: center;
  `,
  ScrollContainer: styled(ScrollView)`
    flex: 1;
    background: #f7f7f7;
  `,
  Card: styled(View)`
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
  `,
  HeaderItem: styled(View)`
    margin-bottom: 4px;
    flex-direction: row;
  `,
  NormalText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: ${(p) => (p.isWide ? '20' : '18')}px;
  `,
  HighLightText: styled(Text)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '24' : '20')}px;
    color: #ed1b2e;
  `,
  CardBorder: styled(View)<{ isWide?: boolean }>`
    border: 1px solid #d3dce6;
    background: #fff;
    padding: ${(p) => (p.isWide ? '30px 40px' : '20px')};
    margin-top: ${(p) => (p.isWide ? '30' : '0')}px;
    border-radius: 8px;
    box-shadow: 0px 26px 30px rgba(0, 0, 0, 0.05);
  `,
  SessionText: styled(Text)<{ isWide?: boolean }>`
    margin-bottom: 16px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: ${(p) => (p.isWide ? '32' : '24')}px;
  `,
  TitleText: styled(Text)<{ isWide?: boolean }>`
    color: #000000;
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: ${(p) => (p.isWide ? '18' : '16')}px;
    margin-bottom: 5px;
  `,
  ItemInfoContainer: styled(View)<{ marginBottom?: string }>`
    margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom : '40')}px;
    display: flex;
    flex-direction: row;
  `,
  SendText: styled(Text)<{ disabled?: boolean }>`
    text-align: center;
    color: #ed1b2e;
    opacity: ${(p) => (p.disabled ? 0.6 : 1)};
    font-size: 15;
    font-weight: 600;
    margin-top: 40px;
  `,
  HelperText: styled(Text)`
    text-align: flex-start;
    color: #e53935;
    font-size: 13px;
    margin-top: 2px;
  `,
  Icon: styled(View)`
    margin-right: 31px;
  `
}

const styles = StyleSheet.create({
  wrapContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 94
  },
  wrapContainerMobile: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column'
  }
})
