import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

const useIdleTimeout = ({ onIdle, idleTime = 1 }: {
  onIdle: () => void
  idleTime: number
}) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false)
  const handleIdle = () => {
    setIdle(true)
    onIdle()
  }
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500
  })
  return {
    isIdle,
    setIdle,
    idleTimer
  }
}
export default useIdleTimeout
