import { RadioButton, Select, TextInput } from '@pulseops/common'
import React, { useState } from 'react'
import { View, Text, StyleSheet, ScrollView, TextProps } from 'react-native'
import { Divider } from './common'

interface RequestChangeTabProps {
  title: string
}

interface ImportantTextProps extends TextProps {
  isImportant?: boolean
}

const ImportantText: React.FC<ImportantTextProps> = (props) => {
  return (
    <Text {...props}>
      {props.children}
      <Text style={{ color: 'red' }}> *</Text>
    </Text>
  )
}

export const RequestChangeTab: React.FC<RequestChangeTabProps> = (props) => {
  const [infoSelect, toggleInfoSelect] = useState<boolean>(true)

  const infoItems: { name: string; description: string }[] = [
    {
      name: 'Nhóm',
      description: 'Phục vụ hợp đồng'
    },
    {
      name: 'Loại yêu cầu',
      description: 'Điều chỉnh hợp đồng'
    },
    {
      name: 'Chi tiết yêu cầu',
      description: 'Cập nhật thông tin liên hệ'
    }
  ]

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator>
        <Text style={styles.sessionTitle}>THÔNG TIN YÊU CẦU</Text>

        <Divider />

        <View style={styles.infoContainer}>
          {infoItems.map(({ name, description }) => {
            return (
              <View>
                <Text style={styles.nameStyle}>{name}</Text>
                <Text style={styles.desctiptionStyle}>{description}</Text>
              </View>
            )
          })}
        </View>

        <Divider />

        <RadioButton
          selected={infoSelect}
          onChange={() => toggleInfoSelect(!infoSelect)}
          label={'THAY ĐỔI ĐỊA CHỈ LIÊN HỆ'}
          labelStyle={styles.sessionTitle}
        />
        <Divider />
        {/* Info address change */}
        <View style={styles.contentContainer}>
          {/* First Row */}
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 2 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Địa chỉ
              </ImportantText>
              <View style={{ borderBottomWidth: 1, borderColor: '#D3DCE6', paddingVertical: 7 }}>
                <TextInput placeholder={'Enter address'} />
              </View>
            </View>
            <Divider width={20} />
            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Quốc gia
              </ImportantText>
              <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
            </View>
          </View>

          <Divider height={20} />
          {/* Second Row */}
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Tỉnh/Thành phố
              </ImportantText>
              <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
            </View>
            <Divider width={20} />
            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Quận/Huyện
              </ImportantText>
              <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
            </View>
            <Divider width={20} />
            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Phường xã
              </ImportantText>
              <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
            </View>
          </View>
          <Divider />
          {/* Third Row */}
          <Text style={styles.nameStyle}>Cập nhật địa chỉ cho các hợp đồng(nếu có)</Text>
          <Divider />
          <View>
            <RadioButton label="Tất cả hợp đồng" selected={true} />
            <Divider />
            <RadioButton label="7000000 123 Nguyễn Thị Minh Khai, Phường Đa Ka, Q1, TP.HCM" selected={true} />
          </View>
        </View>

        <Divider />

        {/* Phone change */}
        <RadioButton
          selected={infoSelect}
          onChange={() => toggleInfoSelect(!infoSelect)}
          label={'THAY ĐỔI SỐ ĐIỆN THOẠI LIÊN HỆ'}
          labelStyle={styles.sessionTitle}
        />
        <Divider />
        <View style={styles.contentContainer}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Số điện thoại di động
              </ImportantText>
              <View style={{ flexDirection: 'row' }}>
                <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
                <TextInput placeholder="Nhập số điện thoại" />
              </View>
            </View>

            <Divider width={20} />

            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Số điện thoại cơ quan
              </ImportantText>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
                <TextInput placeholder="Nhập số điện thoại" />
              </View>
            </View>

            <Divider width={20} />

            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Nhập thông tin qua SMS
              </ImportantText>
              <Select options={[{ label: 'Thông tin hợp đồng', value: 'Viet nam' }]} />
            </View>
          </View>
        </View>

        {/* Email change */}
        <RadioButton
          selected={infoSelect}
          onChange={() => toggleInfoSelect(!infoSelect)}
          label={'THAY ĐỔI EMAIL'}
          labelStyle={styles.sessionTitle}
        />
        <View style={styles.contentContainer}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Số điện thoại di động
              </ImportantText>
              <View style={{ flex: 1 }}>
                <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
                <TextInput placeholder="Nhập số điện thoại" />
              </View>
            </View>

            <Divider width={20} />

            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Số điện thoại cơ quan
              </ImportantText>
              <View style={{ flex: 1 }}>
                <Select options={[{ label: 'Vietnam', value: 'Viet nam' }]} />
                <TextInput placeholder="Nhập số điện thoại" />
              </View>
            </View>

            <Divider width={20} />

            <View style={{ flex: 1 }}>
              <ImportantText style={styles.nameStyle} isImportant>
                Nhập thông tin qua SMS
              </ImportantText>
              <Select options={[{ label: 'Thông tin hợp đồng', value: 'Viet nam' }]} />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'transparent'
  },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 20,
    paddingHorizontal: 16,
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6'
  },
  sessionTitle: {
    fontWeight: 'bold',
    fontSize: 15,
    color: '#70777E'
    //color: 'red'
  },
  nameStyle: {
    fontWeight: '700',
    fontSize: 15,
    color: '#70777E'
  },
  desctiptionStyle: {
    fontSize: 16
  },
  contentContainer: {
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16
  }
})
