import React from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination
} from '@material-ui/core'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

export interface DisplayedColumn {
  title: string
  name: string
  minWidth: number
  renderItem?: (itemValue: string) => JSX.Element
}

type DataSource = {
  [key: string]: string | number
}

type InternalHistoryTableProps = {
  displayedColumns: DisplayedColumn[]
  dataSources: DataSource[]
  paginationData: {
    onPageChange: (pageNum: number, pageSize: number) => void
    onRowsPerPageChange: (pageNum: number, pageSize: number) => void
    setTotalItem: (totalItem: number) => void
    totalItem: number
    setPageSize: (pageSize: number) => void
    pageSize: number
    setPageNum: (pageNum: number) => void
    pageNum: number
  }
}

export const InternalHistoryTable: React.FC<InternalHistoryTableProps> = (props) => {
  const { displayedColumns, dataSources, paginationData } = props
  const { t } = useTranslation()
  // const [totalItem, setTotalItem] = React.useState<number>(0)
  // const [pageSize, setPageSize] = React.useState<number>(10)
  // const [pageNum, setPageNum] = React.useState<number>(0)

  // React.useEffect(() => {
  //   if (dataSources && paginationData) {
  //     console.log('paginationData :', paginationData)
  //     setPageSize(paginationData.pageSize)
  //     setPageNum(paginationData.pageNum)
  //     setTotalItem(paginationData.totalItem)
  //   }
  // }, [dataSources])

  return (
    <View>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#e2e7ea' }}>
              {displayedColumns &&
                displayedColumns.length > 0 &&
                displayedColumns.map((columItem) => (
                  <TableCell align="left" style={{ minWidth: columItem.minWidth, fontWeight: 'bold', fontSize: 15 }}>
                    {t(columItem.title)}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSources &&
              dataSources.length > 0 &&
              dataSources.map((item) => {
                return (
                  <TableRow>
                    {displayedColumns &&
                      displayedColumns.length > 0 &&
                      displayedColumns.map((colum) => {
                        const value = item[colum.name] as string
                        return <TableCell>{colum.renderItem ? colum.renderItem(value) : value}</TableCell>
                      })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        count={paginationData.totalItem}
        rowsPerPage={paginationData.pageSize}
        page={paginationData.pageNum}
        onPageChange={(e, page) => {
          paginationData.setPageNum(page)
          paginationData.onPageChange(page, paginationData.pageSize)
        }}
        onRowsPerPageChange={(e) => {
          const pageS = Number(e.target.value)
          paginationData.setPageSize(pageS)
          if (paginationData.totalItem <= paginationData.pageNum * pageS) {
            paginationData.setPageNum(0)
            paginationData.onRowsPerPageChange(0, pageS)
          } else {
            paginationData.onRowsPerPageChange(paginationData.pageNum, pageS)
          }
        }}
        labelRowsPerPage={t('common:PaginationSize')}
        labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
        component={View}
      ></TablePagination>
    </View>
  )
}
