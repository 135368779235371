import { POApi } from '@pulseops/common'
import * as t from 'io-ts'
import { Nullable } from '@mxt/zio/codec'
import { DateFromISOString } from 'io-ts-types'
import { AmlResultC } from '@pulseops/business/core'

export namespace PerformanceReportService {
  export const ExportFile = t.type({
    benefitClass: t.string,
    certificateNum: Nullable(t.number),
    dateOfBirth: t.string,
    dateOfIssue: t.string,
    effectiveDate: t.string,
    email: t.string,
    employeeId: t.string,
    employeeName: t.string,
    employeeNo: t.string,
    gender: t.string,
    maritalStatus: t.string,
    nationality: t.string,
    numberContractIndividualAfter: Nullable(t.number),
    numberContractIndividualBefore: Nullable(t.number),
    occupation: t.string,
    otherNationality: Nullable(t.string),
    overseasAddress: Nullable(t.string),
    overseasAddressCountry: Nullable(t.string),
    permanentAddress: t.string,
    permanentAddressCountry: t.string,
    placeOfIssue: t.string,
    policyHolder: t.string,
    residentialAddress: t.string,
    residentialAddressCountry: t.string,
    status: t.string,
    totalOfApe: Nullable(t.number),
    visa: Nullable(t.string)
  })

  export type ExportFile = t.TypeOf<typeof ExportFile>

  export const ContractInfo = t.type({
    addEmployee: t.number,
    agcat: Nullable(t.string),
    agentName1: Nullable(t.string),
    agentNumber1: Nullable(t.string),
    annualizedPremiumEquivalent: Nullable(t.number),
    appDate: Nullable(t.string),
    bankCode: Nullable(t.string),
    bsmnme: Nullable(t.string),
    caseCount: Nullable(t.number),
    contractTransferAgentNumber: Nullable(t.number),
    dayOfReceivedDate: Nullable(t.string),
    establishedDate: Nullable(t.string),
    groupPolicyEffectiveDate: Nullable(t.string),
    initialPremium: Nullable(t.number),
    masterContractNo: Nullable(t.string),
    modeOfPayment: Nullable(t.string),
    monthOfReceivedDate: Nullable(t.string),
    office: Nullable(t.number),
    paidPremiumWithSplit: Nullable(t.number),
    paidPremiumWithoutSplit: Nullable(t.number),
    policyAdmin: t.type({
      fullName: t.string,
      position: t.string,
      idNumber: t.string,
      phoneNumber: t.string,
      email: t.string,
      historyAmlResult: t.array(AmlResultC),
      uniqueIdentifierAml: Nullable(t.string)
    }),
    policyHolder: t.type({
      policyHolder: t.string,
      businessAddress: t.type({
        address: t.string,
        country: t.string,
        city: Nullable(t.string),
        district: Nullable(t.string),
        ward: Nullable(t.string),
        phoneNumber: t.string,
        email: t.string
      }),
      contactAddress: t.type({
        address: t.string,
        country: t.string,
        city: Nullable(t.string),
        district: Nullable(t.string),
        ward: Nullable(t.string),
        phoneNumber: t.string,
        email: t.string
      }),
      businessSector: t.string,
      businessLicense: t.string,
      dateOfRegistration: DateFromISOString,
      placeOfRegistration: t.string,
      capital: t.number,
      taxCode: t.string,
      entityType: t.string,
      historyAmlResult: t.array(AmlResultC),
      uniqueIdentifierAml: Nullable(t.string)
    }),
    policyTerm: Nullable(t.number),
    proposalDate: Nullable(t.string),
    provcity: Nullable(t.string),
    referredAgentCode: Nullable(t.string),
    referredBy: Nullable(t.string),
    refferedBankCode: Nullable(t.string),
    refferedSoldUnit: Nullable(t.string),
    regnen: Nullable(t.string),
    removalEmployee: Nullable(t.number),
    requestUpdateDate: Nullable(t.string),
    rsmnme: Nullable(t.string),
    saleUnit: Nullable(t.string),
    sazoneen: Nullable(t.string),
    soldUnit: Nullable(t.string),
    soldUnitCode: Nullable(t.string),
    soldUnitName: Nullable(t.string),
    splitCommission: Nullable(t.number),
    staffCode: Nullable(t.string),
    sumBasicSa: Nullable(t.number),
    sumStandardPremium: Nullable(t.string),
    suzoneen: Nullable(t.string),
    taxCode: Nullable(t.string),
    termDate: Nullable(t.string),
    totalAnnualPremium: Nullable(t.number),
    totalEmployees: Nullable(t.number),
    totalEmployeesInsured: Nullable(t.number),
    transactionCode: Nullable(t.string),
    updateDate: Nullable(t.string),
    yearOfReceivedDate: Nullable(t.string),
    zoneen: Nullable(t.string)
  })

  export type ContractInfo = t.TypeOf<typeof ContractInfo>

  export const exportFile = (params: { month: string | number; year: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/employee/export')(t.array(ExportFile))

  export const contractInfoExport = (params: { month: string | number; year: number }) =>
    POApi.getConfig({ params })('business-pulse/api/v1/contract-info/export')(t.array(ContractInfo))
}
