import * as React from 'react'
import { View, Text, TextInput } from 'react-native'
import { ControlProps } from '../FormProps'

type Props = ControlProps<string[]> & {
  label: string
  placeholder?: string
  disabled?: boolean
  errorMessage?: string
}

export const InputTag = (props: Props) => {
  const { label, value, onChange, onBlur, errorMessage, placeholder } = props

  return (
    <View style={{ flexDirection: 'row', marginBottom: 5, alignItems: 'center' }}>
      <Text style={{ marginLeft: 5 }}>{label}</Text>
      {value &&
        value.map((x, i) => (
          <View
            style={{
              paddingHorizontal: 10,
              paddingVertical: 5,
              backgroundColor: '#F4F4F4',
              borderRadius: 25,
              marginHorizontal: 2.5
            }}
            key={`label-${i}`}
          >
            <Text style={{ color: '#70777E' }}>{x}</Text>
          </View>
        ))}
      <TextInput
        style={{ padding: 5 }}
        onChangeText={(val) => {
          const udp = val.split(';')
          onChange && onChange(udp.length > 1 ? udp.slice(0, udp.length - 1) : [])
        }}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {errorMessage ? <Text>{errorMessage}</Text> : null}
    </View>
  )
}
