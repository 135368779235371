import { Paper } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { ZStream } from '@mxt/zio/stream'
import {
  assets,
  DetailService,
  HistoryItem,
  Table,
  TableFooterPagination,
  TableStatus,
  Text,
  TextLink,
  View
} from '@pulseops/business/core'
import { didUpdate, ErrorHandling, Format, subscribe } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, useWindowDimensions } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'
import { EditHistoryModalService } from './edit-history-modal-service'
import { RootStackParamList } from './root-stack-param-list'

type Props = StackScreenProps<RootStackParamList, 'BusinessEditHistoryModal'>

export const EditHistoryModal = ({ route, navigation }: Props) => {
  const { t } = useTranslation('business')
  const { height } = useWindowDimensions()

  const { masterContractNo, policyYear } = route.params

  const service = DetailService.contract({
    masterContractNo,
    policyYear: parseInt(policyYear)
  })
  const getHistory = service.getHistory(route.params)

  const [size, setSize] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [loading, bindLoading] = useLoading(false)

  pipe(
    didUpdate([size, page]),
    ZStream.chainEffect(([size, page]) => pipe(getHistory({ size, page }), bindLoading)),
    subscribe()
  )

  const rows: HistoryItem[] | null = pipe(
    didUpdate([size, page]),
    ZStream.switchMap(([size, page]) => service.state.history.size(size).watchPage(page)),
    subscribe([])
  )

  const total = pipe(
    didUpdate([size]),
    ZStream.switchMap(([size]) => service.state.history.size(size).watchTotal),
    subscribe(0)
  )

  React.useEffect(() => {
    if (!navigation.canGoBack()) {
      navigation.navigate('MainStack')
    }
  }, [])

  const onClose = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('MainStack')
    }
  }

  const onView = (item: HistoryItem) => {
    pipe(
      EditHistoryModalService.viewing.set(item),
      ZIO.flatMap(() =>
        ZIO.effect(() => {
          onClose()
        })
      ),
      ErrorHandling.run()
    )
  }

  return (
    <SafeAreaView>
      <SC.Header>
        <SC.HeaderText>{t('edit_history')}</SC.HeaderText>
        <SC.CloseIcon onPress={onClose}>
          <assets.IconClose />
        </SC.CloseIcon>
      </SC.Header>
      <ScrollView>
        <SC.Container
          style={{
            maxHeight: height - 90
          }}
        >
          <Table.Container
            component={Paper}
            style={{
              maxWidth: 900
            }}
          >
            <Table.Main>
              <Table.Head>
                <Table.Row>
                  <Table.CellHead>Version</Table.CellHead>
                  <Table.CellHead>{t('edit_by')}</Table.CellHead>
                  <Table.CellHead>{t('edit_date')}</Table.CellHead>
                  <Table.CellHead />
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <TableStatus colSpan={5} loading={loading} rows={rows} />
                {rows?.map((row) => (
                  <Table.Row key={row.version.toString()}>
                    <Table.Cell>{row.version}</Table.Cell>
                    <Table.Cell>{row.createdBy}</Table.Cell>
                    <Table.Cell>{Format.date(row.createdDate)}</Table.Cell>
                    <Table.Cell>
                      <Pressable
                        onPress={() => {
                          onView(row)
                        }}
                      >
                        <TextLink>{t('view_version')}</TextLink>
                      </Pressable>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <TableFooterPagination
                total={total}
                size={size}
                page={page}
                onPageChange={setPage}
                onSizeChange={setSize}
                colspan={5}
              />
            </Table.Main>
          </Table.Container>
        </SC.Container>
      </ScrollView>
    </SafeAreaView>
  )
}

const SC = {
  Header: styled(View)`
    align-items: center;
    justify-content: center;
    background: white;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 30px;
    padding-right: 30px;
  `,
  HeaderText: styled(Text)`
    font-size: 18px;
    font-weight: 700;
  `,
  CloseIcon: styled(Pressable)`
    position: absolute;
    right: 30px;
  `,
  Container: styled(View)`
    padding: 1rem;
    align-items: center;
  `
}
