import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Table,
  TablePagination
} from '@material-ui/core'
import { ErrorHandling, InquiryComplaintData, InquiryComplaintService, ModalComponent } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'

export interface EditingHistoryModalRef {
  getNewHistoryList: () => Promise<void>
}

type EditingHistoryModalProps = {
  title: string
  open: boolean
  column: string[]
  processInstanceId: string
  currentHistoryID: string | undefined
  // dataSource?: InquiryComplaintService.PayloadHistoryData
  onClose: () => void
  onHistoryDetailView: (historyID: string, isViewHistory: boolean, version: number) => void
  setCurrentHistoryID: (historyID: string) => void
}

export const _EditingHistoryModal = (props: EditingHistoryModalProps, ref: React.Ref<EditingHistoryModalRef>) => {
  const { t } = useTranslation()
  // const { width, height } = useWindowDimensions()
  const [pageSize, setPageSize] = React.useState<number>(5)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  // const [isDataLoading, setDataLoading] = React.useState<boolean>(!props.dataSource || props.dataSource.data.length <= 0)
  const [isLoading, bindLoader] = useLoading(false)
  const [payloadHistoryData, setPayloadHistoryData] = React.useState<InquiryComplaintService.PayloadHistoryData>()
  const onHistoryDetailView = (historyID: string, verionNumber: number) => {
    props.onHistoryDetailView(historyID, true, verionNumber)
  }

  const getEditedDate = (createdDate: string) => {
    return moment(createdDate).format('DD/MM/YYYY HH:mm')
  }

  React.useImperativeHandle(
    ref,
    () => ({
      getNewHistoryList: () => {
        return getHandlingPage(0, 5)
      }
    }),
    []
  )

  React.useEffect(() => {
    if (!!props.processInstanceId) {
      pipe(
        ZIO.effect(() => {
          const inputData: InquiryComplaintService.PayloadViewInput = {
            start: 0,
            size: 5,
            processInstanceId: props.processInstanceId
          }
          return inputData
        }),
        ZIO.flatMap((inputData) => InquiryComplaintService.getEditingHistoryList(inputData)),
        ZIO.foldM(
          (error) =>
            ZIO.effect(() => {
              setPayloadHistoryData(undefined)
            }),
          (success) =>
            ZIO.effect(() => {
              // console.log('setPayloadHistoryData:', success)

              const historyData = {
                data: success.data,
                total: success.total,
                start: success.start,
                sort: success.sort,
                order: success.order,
                size: success.size
              }

              setPayloadHistoryData(historyData)
              setPageSize(success.size)
              setPageNum(success.start)
              setTotalItem(success.total)
              return success
            })
        ),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [props.processInstanceId])

  const getHandlingPage = (pageIndex: number, currentPageSize: number) => {
    const inputData: InquiryComplaintService.PayloadViewInput = {
      start: pageIndex,
      size: currentPageSize,
      processInstanceId: props.processInstanceId
    }
    return !!props.processInstanceId
      ? pipe(
          InquiryComplaintService.getEditingHistoryList(inputData),
          ZIO.foldM(
            (error) =>
              ZIO.effect(() => {
                setPayloadHistoryData(undefined)
              }),
            (data) =>
              ZIO.effect(() => {
                const historyData = {
                  data: data.data,
                  total: data.total,
                  start: data.start,
                  sort: data.sort,
                  order: data.order,
                  size: data.size
                }
                setPayloadHistoryData(historyData)
                // props.setDataSource(historyData)
                setPageSize(data.size)
                setPageNum(data.start)
                setTotalItem(data.total)
                // return data
              })
          ),
          bindLoader,
          ZIO.unsafeRun({})
        )
      : Promise.resolve()
  }

  React.useEffect(() => {
    if (
      payloadHistoryData &&
      payloadHistoryData.data &&
      payloadHistoryData.data.length > 0 &&
      payloadHistoryData.start === 0
    ) {
      const currentHistoryID = payloadHistoryData.data[0].id ?? ''
      props.setCurrentHistoryID(currentHistoryID)
      console.log('currentHistoryID: ' + currentHistoryID)
    }
  }, [payloadHistoryData])

  return (
    <ModalComponent title={props.title} onClose={props.onClose} modalWidth={700} visible={props.open} actions={[]}>
      <View style={[historyStyles.container]}>
        {isLoading ? (
          <ActivityIndicator size={'large'} color={'red'} />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead style={{ backgroundColor: '#e2e7ea' }}>
                  <TableRow>
                    {props.column && props.column.map((columnItem) => <TableCell>{columnItem}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payloadHistoryData &&
                    payloadHistoryData.data.length > 0 &&
                    payloadHistoryData.data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{totalItem - (index + pageNum * pageSize)}</TableCell>
                        <TableCell>{item?.createdBy}</TableCell>
                        <TableCell>{getEditedDate(item?.createdDate ?? '')}</TableCell>
                        <TableCell>
                          <TouchableOpacity
                            onPress={() =>
                              onHistoryDetailView(item?.id ?? '', totalItem - (index + pageNum * pageSize))
                            }
                          >
                            <Text style={historyStyles.viewText}>{t('Adjudication:View')}</Text>
                          </TouchableOpacity>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component={View}
              count={totalItem}
              rowsPerPage={pageSize}
              page={pageNum}
              onPageChange={(e, page) => {
                setPageNum(page)
                getHandlingPage(page, pageSize)
              }}
              onRowsPerPageChange={(e) => {
                const pageSiz = Number(e.target.value)
                setPageSize(pageSiz)
                if (totalItem <= pageSiz * pageNum) {
                  setPageNum(0)
                  getHandlingPage(0, pageSiz)
                } else {
                  getHandlingPage(pageNum, pageSiz)
                }
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            />
          </>
        )}
      </View>
    </ModalComponent>
  )
}

export const EditingHistoryModal = React.forwardRef(_EditingHistoryModal)
const historyStyles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    height: '100%'
    // width: '100%'
  },
  viewText: {
    textDecorationLine: 'underline',
    color: '#1EA5FC'
  }
})
