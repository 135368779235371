import { FieldPath, FieldPathValue, FormState, RegisterOptions, useController, UseFormReturn } from 'react-hook-form'

export namespace Form {
  export const getError =
    <S>({ touchedFields, errors }: FormState<S>) =>
    (field: keyof S): string | undefined =>
      (touchedFields as any)[field] && (errors as any)[field]?.message

  export const register =
    <TFieldValues>(form: UseFormReturn<TFieldValues>) =>
    <TFieldName extends FieldPath<TFieldValues>>(
      fieldName: TFieldName,
      options?: RegisterOptions<TFieldValues, TFieldName>
    ): ControlProps<FieldPathValue<TFieldValues, TFieldName>> => {
      const { control, watch, formState } = form
      const value = watch(fieldName)

      const { field } = useController({
        name: fieldName,
        control,
        rules: options
      })

      const { touchedFields, errors } = formState

      const errorMessage =
        (touchedFields as any)[fieldName as any as keyof TFieldValues] &&
        ((errors as any)[fieldName as any as keyof TFieldValues] as any)?.message
      return {
        value,
        onChange: (value) => {
          field.onChange(value)
        },
        onBlur: () => {
          field.onBlur()
        },
        errorMessage
      }
    }
}

export type ControlProps<A> = {
  value?: A
  onChange?: (value: A) => void
  onBlur?: () => void
  onKeyEnter?: () => void
  errorMessage?: string
}
