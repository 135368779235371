import * as React from 'react'
import { View, Text, TextInput, StyleSheet, TextStyle, StyleProp, ViewStyle, TouchableOpacity } from 'react-native'
import { ControlProps } from '../FormProps'
import { formatNumberWithComma } from '../../shared'
import { formatCurrencyWithFraction, formatStringToDecimalNumber, getNumberFromString } from '../../service'

const numberRegExp = new RegExp('^[0-9]*$')

const formatInput = (inputType: 'text' | 'number' | 'money' = 'text', value: string, defaultValue = '') => {
  let output = defaultValue
  if (value.length === 0) {
    return output
  }

  if (inputType === 'number') {
    const isNumber = value.match(numberRegExp) !== null
    output = isNumber ? value : defaultValue
  } else if (inputType === 'money') {
    value = [...value].filter((item) => !isNaN(Number(item))).join('')
    const isNumber = value.match(numberRegExp) !== null
    output = isNumber && Number(value) > 0 ? value : defaultValue
  } else {
    output = value
  }
  return output
}

type Props = ControlProps<string> & {
  placeholder?: string
  title?: string
  labelStyle?: TextStyle
  disabled?: boolean
  required?: boolean
  errorMessage?: string
  maxLength?: number
  numberOfLines?: number
  multiline?: boolean
  textAlign?: 'left' | 'right' | 'center' | 'auto' | 'justify'
  suffix?: string | JSX.Element
  inputType?: 'text' | 'number' | 'money'
  containerStyle?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<ViewStyle & TextStyle>
  isAllowComma?: boolean
  isFractionFormat?: boolean,
  icon?: JSX.Element | undefined,
  onIconPressed?: () => void,
  checkAuto: boolean
}

export const CircelInput = (props: Props) => {
  const {
    title,
    value,
    onChange,
    onBlur,
    onKeyEnter,
    required,
    errorMessage,
    disabled,
    labelStyle,
    maxLength,
    textAlign,
    numberOfLines,
    multiline,
    suffix,
    inputType = 'text',
    containerStyle,
    inputStyle,
    placeholder,
    isAllowComma,
    isFractionFormat,
    icon,
    onIconPressed,
    checkAuto
  } = props

  const [text, setText] = React.useState<string>(() => {
    return formatInput(inputType, value || '')
  })

  React.useEffect(() => {
    if (!value) {
      setText('')
    }
  }, [value])

  return (
    <View style={containerStyle}>
      {title && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={[{ fontWeight: 'bold', color: '#70777E', fontSize: 15, marginBottom: 2 }, labelStyle]}>
            {props.title}
          </Text>
          {required && <Text style={{ color: '#ed1b2c', marginLeft: 5, fontSize: 15, fontWeight: 'bold' }}>*</Text>}
        </View>
      )}
      <View style={suffix ? { position: 'relative' } : {}}>
        <TextInput
          style={
            disabled
              ? [
                  styles.common,
                  icon ? styles.circelInputIconCss : styles.circelInputCss,
                  inputStyle,
                  { textAlign: textAlign, paddingRight: suffix ? 35 : 0 }
                ]
              : [
                  styles.common,
                  icon ? styles.circelInputIconCss : styles.circelInputCss,
                  inputStyle,
                  { paddingRight: suffix ? 35 : 0 },
                  multiline ? styles.outLine : styles.dashline
                ]
          }
          value={
            inputType === 'money'
              ? isFractionFormat === true
                ? formatCurrencyWithFraction(value ?? '')
                : formatNumberWithComma(text, '')
              : value
          }
          onChangeText={(value) => {
            if (inputType === 'money') {
              const output =
                value.length === 0
                  ? ''
                  : !isFractionFormat
                  ? formatInput(inputType, value, text)
                  : getNumberFromString(formatStringToDecimalNumber(value))
              setText(output)
              onChange && onChange(output)
            } else {
              if (
                isAllowComma &&
                inputType === 'number' &&
                value.length > 0 &&
                value.indexOf(',') <= 0 &&
                !value.match(/^[0-9]+$/)
              ) {
                return
              } else if (!isAllowComma && inputType === 'number' && value.length > 0 && !value.match(/^[0-9]+$/)) {
                return
              }
              onChange && onChange(value)
            }
          }}
          onBlur={onBlur}
          onKeyPress={(event) => {
            if (onKeyEnter && event.nativeEvent.key == 'Enter') {
              onKeyEnter()
            }
          }}
          editable={!disabled}
          maxLength={maxLength}
          numberOfLines={numberOfLines}
          multiline={multiline}
          placeholder={placeholder}
          placeholderTextColor={'#70777E'}
          autoFocus={checkAuto}
        />
        <View style={suffix ? { position: 'absolute', right: 1, top: 7 } : {}}>
          <Text>{suffix}</Text>
        </View>
        {icon && (
          <TouchableOpacity style={styles.iconStyle} onPress={onIconPressed}>
            {icon}
          </TouchableOpacity>
        )}
      </View>
      {errorMessage ? <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errorMessage}</Text> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  common: {
    minHeight: 33,
    fontSize: 15
    // lineHeight: 20
  },
  outLine: {
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8
  },
  dashline: {},

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  },
  circelInputCss: {
    height: 50,
    backgroundColor: '#FFFFFF',
    border: '2px solid #D3DCE6',
    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 60,
    paddingLeft: 20
  },
  circelInputIconCss: {
    height: 44,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D3DCE6',
    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 60,
    paddingLeft: 20
  },
  iconStyle:{
    position: 'absolute',
    right: 10,
    top: 11,
    
  }
})
