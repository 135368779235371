import * as React from 'react'
import { Column, Label, Row, Error } from '@pulseops/submission/common'
import { Text, TouchableOpacity } from 'react-native'
import {
  Panel,
  Title,
  ImgUploadMutiple,
  Divider,
  PayoutPopup,
  ExcessPremiumService,
  ErrorHandling,
  formatNumberWithComma,
  ExcessPremiumSubmission,
  form2,
  TransactionType,
  AppContext,
  StorageBlob,
  GeneralService,
  BuildConfig,
  TaskType,
  BeneficiaryDesignationService
} from '@pulseops/common'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { PayoutMethod, mapCashOutOption, PayoutMethodRef } from '../../payout-method'
import { pipe } from 'fp-ts/function'
import { ExcessPremiumForm } from './excess-premium.form'
import { RequestAuthenticateData } from '../../request-authen'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilePresent } from '../../common'
import { ZIO } from '@mxt/zio'
// import { useLoading } from '@mxt/zio-react'

interface Props extends PolicyServiceProps<ExcessPremiumSubmission.SubmitData> {
  policyNumber: string
}

export const ExcessPremium = ({ policyNumber, isConfirmed, initSubmission, officeCode, clientNumber }: Props) => {
  const { t } = useTranslation()
  // const [isLoading, bindLoader] = useLoading(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const form = form2.useForm(ExcessPremiumForm.codec, {
    defaultValues: {
      payout: [],
      receiptFlag: false
    }
  })

  const { control, watch, setValue, getValues } = form.base

  const { payout, receiptFlag } = watch()

  const { detail, warningMessage } = pipe(
    ZIO.zipPar(
      ExcessPremiumService.getDetail(policyNumber),
      BeneficiaryDesignationService.getSeaBankMessgeList(policyNumber ?? '', clientNumber ?? '')
    ),
    ZIO.map(([excessDetail, warningMessage]) => {
      return {
        detail: excessDetail,
        warningMessage
      }
    }),
    ErrorHandling.runDidUpdate([policyNumber])
  ) || {
    detail: null,
    warningMessage: ''
  }

  React.useEffect(() => {
    if (detail) {
      setValue('receiptFlag', detail.receiptFlag)
      if (detail.receiptFlag) {
        setValue('documents', [])
      }
    }
  }, [detail])

  // React.useEffect(() => {
  //   showGlobalLoading(isLoading)
  // }, [isLoading])

  const getPayoutMethod = () => {
    let methods: PayoutPopup.PayoutMethods[] = [
      PayoutPopup.PayoutMethods.PAYPREMIUM,
      PayoutPopup.PayoutMethods.PAYLOAN,
      PayoutPopup.PayoutMethods.OTHER
    ]
    if (detail && !detail.receiptFlag) {
      methods.push(
        PayoutPopup.PayoutMethods.CASHLESS,
        PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
        PayoutPopup.PayoutMethods.PAID_AT_BANK,
        PayoutPopup.PayoutMethods.BANKTRANSFER,
        // PayoutPopup.PayoutMethods.MOMO
      )
    }

    return methods
  }

  // const getMetaDataFromAttachmentFiles = () => {
  //   let files: StorageBlob.FileContent[] = []
  //   if (receiptFlag) {
  //     return pipe(
  //       GeneralService.getMetaData(TransactionType.EXCESS_PREMIUM || '', 'DPS18', officeCode),
  //       ZIO.map((metaDataRes) => {
  //         const metaDataRaw: StorageBlob.MetaDataUpload = {
  //           type: metaDataRes.data.transactionType,
  //           doctype: metaDataRes.data.doctypeEn,
  //           class: metaDataRes.data.classFilenet,
  //           docid: metaDataRes.data.docID,
  //           maindoc: metaDataRes.data.mainDoc,
  //           subdoc: metaDataRes.data.subDoc,
  //           polnum: policyNumber,
  //           batchno: metaDataRes.data.batchNo
  //         }
  //         const documents = getValues('documents') as ExcessPremiumForm.File[]
  //         files = documents.map((x) => {
  //           return {
  //             file: x.file,
  //             metaData: metaDataRaw
  //           }
  //         })
  //         return files
  //       })
  //     )
  //   } else {
  //     return pipe(files, ZIO.succeed)
  //   }
  // }

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const documents = getValues('documents') as ExcessPremiumForm.File[]
    if (receiptFlag) {
      uploadedFileList.push({
        uploadFiles: documents,
        transactionType: TransactionType.EXCESS_PREMIUM,
        docTypeCode: 'DPS18',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    uploadedFileList.push({
      uploadFiles: GeneralService.getPayoutDocuments(getValues('payout')),
      transactionType: TransactionType.EXCESS_PREMIUM,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isFormValid = await form.base.trigger()

      if (!isFormValid || !detail?.excessPremiumAmount || totalAmount > detail.excessPremiumAmount) {
        return false
      }

      if (totalAmount === 0) {
        showToast(t('message:MS050241'), 'error')
        return false
      }

      let filesUploaded: StorageBlob.FileContentSubmit[] = []
      if (!isContinue) {
        // showGlobalLoading(true)
        // await pipe(
        //   getMetaDataFromAttachmentFiles(),
        //   ZIO.flatMap((metaDataFiles) => StorageBlob.uploadToSubmit('PS', policyNumber)(metaDataFiles)),
        //   ZIO.tapError((_) => {
        //     showGlobalLoading(false)
        //     showToast(t('message:MS050001'), 'error')
        //     return ZIO.unit
        //   }),
        //   ZIO.map((files) => {
        //     showGlobalLoading(false)
        //     filesUploaded = files
        //     return files
        //   }),
        //   ZIO.runPromise({})
        // )
      }

      return {
        url: (policyNum: string) => `wf-api/policy/${policyNum}/excess-premium`,
        body: {
          policy: {
            attributesExt: {
              EXCESS_PREMIUM_AMOUNT: `${detail.excessPremiumAmount}`,
              TOTAL_PAYOUT_AMOUNT: `${totalAmount}`
            }
          },
          cashOutOptions: mapCashOutOption(payout)
        },
        collerationId: policyNumber,
        documents: filesUploaded,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.EXCESS_PREMIUM),
        transaction: TransactionType.EXCESS_PREMIUM,
        uploadedFilesInfo: getUploadedFilesInfo()
      }
    },
    clear: () => {
      payoutRef.current?.clearData()
      setValue('payout', [])
      setValue('documents', [])
    }
  })

  const totalAmount = React.useMemo(() => payout.reduce((sum, item) => sum + item.amount, 0), [payout])

  return (
    <Column marginTop={30}>
      <Title title={t('submission:EXCESS_PREMIUM_INFO')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        {detail && (
          <>
            <Column>
              <Label title={t('submission:ExcessPremium')} fontWeight="bold" />
              <Label
                title={detail.excessPremiumAmount ? `${formatNumberWithComma(detail.excessPremiumAmount)} VND` : '-'}
                color={'#000000'}
              />
            </Column>
            {detail.receiptFlag && (
              <Column marginTop={24}>
                <Label title={t('utilities:FileAttachment')} fontWeight="bold" required={true} />
                <Divider height={8} />
                {!isConfirmed && (
                  <Text style={{ fontSize: 15, fontWeight: '400', color: '#70777E' }}>
                    {t('submission:template_1')}
                    <TouchableOpacity
                      activeOpacity={0.7}
                      onPress={() => {
                        window.open(
                          `${BuildConfig.contextPath}/static/PYC_DE_NGHI_HUY_GIAO_DICH_THE_HOAN_TRA_PHI-JULY2021.pdf`,
                          '_blank'
                        )
                      }}
                    >
                      <Text style={{ color: '#1300EB', textDecorationLine: 'underline' }}>
                        {t('submission:template_2')}
                      </Text>
                    </TouchableOpacity>
                    {t('submission:template_3')}
                  </Text>
                )}
                <Controller
                  control={control}
                  name={'documents'}
                  render={({ field, fieldState: { error } }) =>
                    !isConfirmed ? (
                      <Column>
                        <ImgUploadMutiple {...field} value={field.value as any[]} />
                        {error?.message && <Error message={error.message} />}
                      </Column>
                    ) : (
                      <Row flexWrap={'wrap'} flexGrow={1}>
                        {(field.value as any[]).map((item) => (
                          <Column marginEnd={20}>
                            <FilePresent file={item} />
                          </Column>
                        ))}
                      </Row>
                    )
                  }
                />
              </Column>
            )}
          </>
        )}
      </Panel>

      {detail && (
        <Controller
          control={control}
          name="payout"
          render={({ field }) => (
            <PayoutMethod
              {...field}
              transactionType={TransactionType.EXCESS_PREMIUM}
              ref={payoutRef}
              editable={!isConfirmed}
              policyNum={policyNumber}
              maxAmount={detail?.excessPremiumAmount}
              errorMessage={totalAmount > detail.excessPremiumAmount ? t('message:MS050237') : ''}
              methods={getPayoutMethod()}
              officeCode={officeCode}
              isSeaBankPolicy={!!warningMessage}
            />
          )}
        />
      )}
    </Column>
  )
}
