import { formatNumberWithComma, FrequencyMapping, Panel, SourceType, TaskDetail } from '@pulseops/common'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { InfoViewList, Title } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

export const BillingChange = ({ detail }: { detail?: TaskDetail.BillingChange }) => {
  const { t } = useTranslation(['requestInfo', 'frequency'])
  let data: Output[] = []

  if (
    detail?.source === SourceType.PULSE4OPS ||
    detail?.source === SourceType.PULSE ||
    detail?.source === SourceType.PRUONLINE ||
    detail?.source === SourceType.ZALO
  ) {
    const currentFrequency: string | TFunctionResult = FrequencyMapping.mapFrequency.get(detail.currentFrequency) || '-'
    const newFrequency: string | TFunctionResult = FrequencyMapping.mapFrequency.get(detail.newFrequency) || '-'
    data = [
      {
        label: t('CurrentBillingFrequency'),
        value: t(currentFrequency)
      },
      {
        label: t('CurentInstallmentPremium'),
        value: detail.currentRenewalPremium ? formatNumberWithComma(detail.currentRenewalPremium) : '0',
        suffix: 'VND'
      },
      {
        label: t('SuspenseAmount'),
        value: detail.suspendAmount ? formatNumberWithComma(detail.suspendAmount) : '0',
        suffix: 'VND'
      },
      {
        label: t('NewBillingFrequency'),
        value: t(newFrequency),
        isHighlight: true
      },
      {
        label: t('NewInstallmentPremium'),
        value: detail.newRenewalPremium ? formatNumberWithComma(detail.newRenewalPremium) : '0',
        suffix: 'VND'
      },
      {
        label: t('AdvancePremium'),
        value: detail.advancePremium ? formatNumberWithComma(detail.advancePremium) : '0',
        suffix: 'VND'
      },
      {
        label: t('EffectiveDateOfNewFrequency'),
        value: detail.effectiveDate ? detail.effectiveDate : ''
      },
      {
        label: t('RequirePayinAmount'),
        value:
          detail.requirePayinAmount && detail.requirePayinAmount > 0
            ? formatNumberWithComma(detail.requirePayinAmount)
            : '0',
        suffix: 'VND',
        isHighlight: true
      }
    ]
  }

  return (
    <View>
      <Title title={t('Billing')} />
      <Panel title="" isExand={false}>
        <InfoViewList dataSource={data} />
      </Panel>
    </View>
  )
}
