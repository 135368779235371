import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import React from 'react'
import { View, Text, TextStyle, StyleSheet, SafeAreaView, ScrollView, TouchableOpacity, Pressable } from 'react-native'
import { OBAdvantageSearch, OBBasicSearch, OBCurrentActivity, TabSearchOB } from './ob-search-form'
import { AppContext, DatePicker, ErrorHandling, Permission, PulseOpsFormat, RadioButton, RBAC, SubTaskType, TableHeaderSort } from '@pulseops/common'
import { TFunction, useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { SearchFields } from './OBSearchField'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { pipe } from 'fp-ts/function'
import { OBTableServiceInquiry } from './OBTableServiceInquiry'
import { mapCallProgram, OBCallOutPopupService, OBCallProgramTypeCode, OBFormat, OBLastActivity, OBStatus, OBStatusLabel, OBTaskService, OutboundParamList, ParamInquiry, TaskServiceInquiryData } from '@pulseops/outbound'
import { ZIO } from '@mxt/zio'
import { StackScreenProps } from '@react-navigation/stack'
import { useLoading } from '@mxt/zio-react'
import { TaskConst } from '../ob-task-management'
import * as O from 'fp-ts/lib/Option'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'

export const OBServiceInquiryScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const [tab, setTab] = React.useState<TabSearchOB | null>(null)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const { t, i18n } = useTranslation()
  const [messageError, setMessageError] = React.useState<string | null>(null)
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [loading, bindLoading] = useLoading(false)
  const scrollViewRef = React.useRef<ScrollView | null>(null)
  // const [submittedData, setSubmittedData] = React.useState<ParamInquiry | null>(null)
  const [rows, setRows] = React.useState<TaskServiceInquiryData[]>([])
  const [sortItem, setSortItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })
  const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: '#E5EAEF'
    },
    tableCell: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    },
    cellNoWrap: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()

  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      setTab(getDefaultTab(permissions))
      return permissions
    }),
    ErrorHandling.runDidUpdate([isFocused])
  ) || []

  const { callingResultList, managerList, staffList } = pipe(
    ZIO.zipPar(
      OBCallOutPopupService.getCallingResultList(),
      OBTaskService.getManagerNameArr(),
      OBTaskService.getStaffList()
    ),
    ZIO.map(([callingResultList, managerList, staffList]) => {
      return {
        callingResultList,
        managerList,
        staffList
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    callingResultList: [],
    managerList: [],
    staffList: []
  }

  const currentDate = new Date()
  const dayBefore = new Date()
  dayBefore.setDate(dayBefore.getDate() - 30)

  const defaultValues = {
    policyNumber: '',
    idNumber: '',
    clientNumber: '',
    phoneNumber: '',
    fromDate: dayBefore,
    toDate: currentDate
  }
  const advanceDefaultValues = {
    agency: undefined,
    leader: undefined,
    lastAssignee: undefined,
    callProgram: undefined,
    status: undefined,
    currentActivity: undefined,
    fromDate: null,
    toDate: null,
    firstIssueFromDate: null,
    firstIssueToDate: null,
    lastIssueFromDate: null,
    lastIssueToDate: null,
    createdFromDate: dayBefore,
    createdToDate: currentDate,
    submitFromDate: null,
    submitToDate: null,
    paidToDateFromDate: null,
    paidToDateToDate: null
  }

  const basicSearchForm = useForm<OBBasicSearch>({
    defaultValues: defaultValues,
    mode: 'onChange',
  })

  const advancedSearchForm = useForm<OBAdvantageSearch>({
    defaultValues: advanceDefaultValues,
    mode: 'onChange',
  })

  const advancedSearchFieldList: (t: TFunction<"translation">) => SearchFields.FieldSearch[] = (t) => [
    {
      formNameDateFrom: 'firstIssueFromDate',
      formNameDateTo: 'firstIssueToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBTaskManagement:FirstIssueDate'),
      rules: {
        validate: (_: any) => {
          const firstIssueFromDate = advancedSearchForm.watch('firstIssueFromDate')
          const firstIssueToDate = advancedSearchForm.watch('firstIssueToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(firstIssueToDate as Date, firstIssueFromDate as Date)
          if (!firstIssueFromDate && !firstIssueToDate) {
            return true
          } else if ((firstIssueFromDate && !firstIssueToDate) || (!firstIssueFromDate && firstIssueToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBTaskManagement:FirstIssueDate') })}`
          }
          else if (moment(moment(firstIssueFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(firstIssueToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else if (moment(firstIssueFromDate).valueOf() > moment().valueOf() || moment(firstIssueToDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'lastIssueFromDate',
      formNameDateTo: 'lastIssueToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBTaskManagement:LastIssueDate'),
      rules: {
        validate: (_: any) => {
          const lastIssueFromDate = advancedSearchForm.watch('lastIssueFromDate')
          const lastIssueToDate = advancedSearchForm.watch('lastIssueToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(lastIssueToDate as Date, lastIssueFromDate as Date)
          if (!lastIssueFromDate && !lastIssueToDate) {
            return true
          } else if ((lastIssueFromDate && !lastIssueToDate) || (!lastIssueFromDate && lastIssueToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBTaskManagement:LastIssueDate') })}`
          }
          else if (moment(moment(lastIssueFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(lastIssueToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else if (moment(lastIssueFromDate).valueOf() > moment().valueOf() || moment(lastIssueToDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'createdFromDate',
      formNameDateTo: 'createdToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBServiceInquiry:CreatedDate'),
      required: true,
      rules: {
        validate: (_: any) => {
          const createdFromDate = advancedSearchForm.watch('createdFromDate')
          const createdToDate = advancedSearchForm.watch('createdToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(createdToDate as Date, createdFromDate as Date)
          if (moment(createdFromDate).isValid() && moment(createdToDate).isValid()) {
            return true
          } else if ((!createdFromDate && !createdToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBServiceInquiry:CreatedDate') })}`
          } else if ((createdFromDate && !createdToDate) || (!createdFromDate && createdToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBServiceInquiry:CreatedDate') })}`
          }
          else if (moment(moment(createdFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(createdToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          else if (moment(createdFromDate).valueOf() > moment().valueOf() || moment(createdToDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'submitFromDate',
      formNameDateTo: 'submitToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBServiceInquiry:SubmitDate'),
      rules: {
        validate: (_: any) => {
          const submitFromDate = advancedSearchForm.watch('submitFromDate')
          const submitToDate = advancedSearchForm.watch('submitToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(submitToDate as Date, submitFromDate as Date)
          if (!submitFromDate && !submitToDate) {
            return true
          } else if ((submitFromDate && !submitToDate) || (!submitFromDate && submitToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBServiceInquiry:SubmitDate') })}`
          }
          else if (moment(moment(submitFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(submitToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          } else if (moment(submitToDate).valueOf() > moment().valueOf() || moment(submitFromDate).valueOf() > moment().valueOf()) {
            return `${t('message:OB0020')}`
          }
          else {
            return true
          }
        }
      }
    },
    {
      formNameDateFrom: 'paidToDateFromDate',
      formNameDateTo: 'paidToDateToDate',
      type: 'DATE_FROM_TO',
      title: t('Outbound:OBServiceInquiry:PaidToDateAdvance'),
      rules: {
        validate: (_: any) => {
          const paidToDateFromDate = advancedSearchForm.watch('paidToDateFromDate')
          const paidToDateToDate = advancedSearchForm.watch('paidToDateToDate')
          const monthDistance = OBFormat.diffTwoDateAsMonth(paidToDateToDate as Date, paidToDateFromDate as Date)
          if (!paidToDateFromDate && !paidToDateToDate) {
            return true
          } else if ((paidToDateFromDate && !paidToDateToDate) || (!paidToDateFromDate && paidToDateToDate)) {
            return `${t('message:MS020001', { field: t('Outbound:OBServiceInquiry:PaidToDateAdvance') })}`
          }
          else if (moment(moment(paidToDateFromDate).format('YYYY/MM/DD')).valueOf() > moment(moment(paidToDateToDate).format('YYYY/MM/DD')).valueOf()) {
            return `${t('message:OB0031')}`
          }
          else if (monthDistance >= 3) {
            return `${t('message:OB0041')}`
          }
          // else if (moment(paidToDateFromDate).valueOf() > moment().valueOf() || moment(paidToDateToDate).valueOf() > moment().valueOf()) {
          //   return `${t('message:OB0020')}`
          // }
          else {
            return true
          }
        }
      }
    },
    {
      formName: 'lastCallingResult',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:LastCallingResult'),
      option: getOptions(callingResultList)
    },
    {
      formName: 'agency',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:Agency'),
      option: [
        ...TaskConst.agencyList,
        { label: 'ALL', value: '' }
      ]
    },
    {
      formName: 'leader',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:Leader'),
      option: managerList.map((item) => ({ value: item, label: item }))
    },
    {
      formName: 'lastAssignee',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:LatestAssignee'),
      option: staffList.map((item) => ({ value: item, label: item }))
    },
    {
      formName: 'callProgram',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:CallType'),
      option: [
        ...TaskConst.callProgramList,
        { label: 'ALL', value: '' },
      ]
    },
    {
      formName: 'status',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:Status'),
      option: Object.keys(OBStatus).map((item, index) => ({ value: item, label: Object.values(OBStatus)[index] }))
    },
    {
      formName: 'currentActivity',
      type: 'SEARCHSELECT',
      title: t('Outbound:OBServiceInquiry:CurrentActivity'),
      option: OBCurrentActivity
    }
  ]

  const firstIssueFroDa = advancedSearchForm.watch('firstIssueFromDate')
  const firstIssueToDa = advancedSearchForm.watch('firstIssueToDate')

  const lastIssueFroDa = advancedSearchForm.watch('lastIssueFromDate')
  const lastIssueToDa = advancedSearchForm.watch('lastIssueToDate')
  const submitFroDa = advancedSearchForm.watch('submitFromDate')
  const submitToDa = advancedSearchForm.watch('submitToDate')
  const paidToDateFroDa = advancedSearchForm.watch('paidToDateFromDate')
  const paidToDateToDa = advancedSearchForm.watch('paidToDateToDate')

  React.useEffect(() => {
    if ((!firstIssueFroDa && !firstIssueToDa) || (moment(firstIssueFroDa).isValid() && moment(firstIssueToDa).isValid())) {
      advancedSearchForm.clearErrors('firstIssueFromDate')
      advancedSearchForm.clearErrors('firstIssueToDate')
    }
  }, [firstIssueFroDa, firstIssueToDa])

  React.useEffect(() => {
    if ((!lastIssueFroDa && !lastIssueToDa) || (moment(lastIssueFroDa).isValid() && moment(lastIssueToDa).isValid())) {
      advancedSearchForm.clearErrors('lastIssueFromDate')
      advancedSearchForm.clearErrors('lastIssueToDate')
    }
  }, [lastIssueFroDa, lastIssueToDa])

  React.useEffect(() => {
    if ((!submitFroDa && !submitToDa) || (moment(submitFroDa).isValid() && moment(submitToDa).isValid())) {
      advancedSearchForm.clearErrors('submitFromDate')
      advancedSearchForm.clearErrors('submitToDate')
    }
  }, [submitFroDa, submitToDa])

  React.useEffect(() => {
    if ((!paidToDateFroDa && !paidToDateToDa) || (moment(paidToDateFroDa).isValid() && moment(paidToDateToDa).isValid())) {
      advancedSearchForm.clearErrors('paidToDateFromDate')
      advancedSearchForm.clearErrors('paidToDateToDate')
    }
  }, [paidToDateFroDa, paidToDateToDa])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:ServiceInquiry'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    setPageSize(10)
    setPageNum(0)
    setRows([])
    setTotalItem(0)
    setMessageError(null)
    setSortItem({ field: '', order: 'asc' })
    // setSubmittedData(null)
    basicSearchForm.reset(defaultValues)
    advancedSearchForm.reset(advanceDefaultValues)
  }, [tab])

  const getOptions = (dataList: any[]) => {
    return dataList.map((item) => ({ label: i18n.language === 'en' ? item.nameEn : item.nameVi, value: item.code }))
  }

  const getLastCallingResultName = (resultCode: string) => pipe(
    callingResultList.find((x) => x.code === resultCode),
    O.fromNullable,
    O.fold(
      () => '',
      (item) => i18n.language === 'en' ? item.nameEn : item.nameVi
    )
  )

  function getDefaultTab(permissions: string[]): TabSearchOB | null {
    if (permissions.includes(Permission['ViewBasicSearchOBServiceInquiry'])) {
      return TabSearchOB.BasicSearch
    } else if (permissions.includes(Permission['ViewAdvancedSearchOBServiceInquiry'])) {
      return TabSearchOB.AdvancedSearch
    } else {
      return null
    }
  }

  const validation = (formName: string) =>
    formName === 'fromDate' ?
      ({
        validate: (value: Date | null | undefined) => {
          if ((value && moment(value).isAfter(currentDate)))
            return t('message:OB0020')
          // if((value && !isNaN(value.getTime()) && toDate && !isNaN(toDate.getTime()) && moment(value).isAfter(toDate)))
          //   return t('message:MS030044')
          return true
        }
      }) :
      ({
        validate: (value: Date | null | undefined) => {
          if ((value && moment(value).isAfter(currentDate)))
            return t('message:OB0020')
          return true
        }
      })

  const validateSearch = () => {
    const { toDate, fromDate, policyNumber, phoneNumber, clientNumber, idNumber } = basicSearchForm.getValues()
    if (!policyNumber && !phoneNumber && !clientNumber && !idNumber) {
      setMessageError(t('message:MS990025'))
      return false
    }
    if (moment(toDate).diff(fromDate, 'months', true) > 12) {
      setMessageError(t('message:OB0023', { k: 12 }))
      return false
    }
    if ((fromDate && !isNaN(fromDate.getTime()) && toDate && !isNaN(toDate.getTime()) && moment(fromDate).isAfter(toDate))) {
      setMessageError(t('message:MS030044'))
      return false
    }
    setMessageError(null)
    return true
  }

  const onSearchEvent = async () => {
    switch (tab) {
      case TabSearchOB.BasicSearch: {
        const isValidForm = await basicSearchForm.trigger()
        if (isValidForm && validateSearch()) {
          getSearchDataForServiceInquiry(0, 10)
        }
        break
      }
      case TabSearchOB.AdvancedSearch: {
        const isValidAdvanceFormData = await advancedSearchForm.trigger()
        if (isValidAdvanceFormData) {
          getSearchDataForServiceInquiry(0, 10)
        }
        break
      }
      default: {
        break
      }
    }

  }

  const getServiceInquiry = (dataQuery: ParamInquiry, isAdvanceSearch?: boolean) => {
    showGlobalLoading(true)
    pipe(
      OBTaskService.getCaseInquiry(dataQuery),
      ZIO.map((res) => {
        setRows(res.data ?? [])
        setTotalItem(res.total)
        setMessageError(res.data && res.data.length === 0 ? t('message:OB0032') : null)
        isAdvanceSearch && scrollViewRef?.current?.scrollTo({ x: 0, y: 350 })
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.mapError((err) => {
        setRows([])
        setTotalItem(0)
        showGlobalLoading(false)
        return err
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const getSearchDataForServiceInquiry = (pageNum: number, pageSize: number) => {
    let dataQuery: ParamInquiry = {} as ParamInquiry
    let isAdvanceSearch: boolean = false
    switch (tab) {
      case TabSearchOB.BasicSearch: {
        const dataParams = basicSearchForm.getValues()
        isAdvanceSearch = false
        const createdBefore = pipe(
          dataParams.toDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_endOfDate(item, true)
          )
        )
        const createdAfter = pipe(
          dataParams.fromDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_StartOfDate(item, true)
          )
        )
        dataQuery = {
          policyFirstIssuedDateBefore: undefined,
          policyFirstIssuedDateAfter: undefined,
          policyLastIssuedDateBefore: undefined,
          policyLastIssuedDateAfter: undefined,
          caseSubmittedBefore: undefined,
          caseSubmittedAfter: undefined,
          paidToDateBefore: undefined,
          paidToDateAfter: undefined,
          callOutCode: undefined,
          latestAssignee: undefined,
          leaderOfAssignee: undefined,
          currentActivity: undefined,
          caseStatus: undefined,
          saleChannel: undefined,
          transactionTypeIn: undefined,
          policyNumber: dataParams.policyNumber,
          idNumber: dataParams.idNumber,
          clientNumber: dataParams.clientNumber,
          phoneNumber: dataParams.phoneNumber,
          createdAfter: createdAfter,
          createdBefore: createdBefore,
          size: pageSize,
          start: pageSize * pageNum,
          order: 'startTime',
          category: 'OB',
          sort: "DESC"
        }
        break
      }
      case TabSearchOB.AdvancedSearch: {
        const advancedFormData = advancedSearchForm.getValues()
        isAdvanceSearch = true
        const policyFirstIssuedDateBefore = pipe(
          advancedFormData.firstIssueToDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_endOfDate(item, true).toISOString()
          )
        )
        const policyFirstIssuedDateAfter = pipe(
          advancedFormData.firstIssueFromDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_StartOfDate(item, true).toISOString()
          )
        )
        const policyLastIssuedDateBefore = pipe(
          advancedFormData.lastIssueToDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_endOfDate(item, true).toISOString()
          )
        )
        const policyLastIssuedDateAfter = pipe(
          advancedFormData.lastIssueFromDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_StartOfDate(item, true).toISOString()
          )
        )
        const caseSubmittedBefore = pipe(
          advancedFormData.submitToDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_endOfDate(item, true).toISOString()
          )
        )
        const caseSubmittedAfter = pipe(
          advancedFormData.submitFromDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_StartOfDate(item, true).toISOString()
          )
        )
        const paidToDateBefore = pipe(
          advancedFormData.paidToDateToDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_endOfDate(item, true).toISOString()
          )
        )
        const paidToDateAfter = pipe(
          advancedFormData.paidToDateFromDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_StartOfDate(item, true).toISOString()
          )
        )
        const createdBefore = pipe(
          advancedFormData.createdToDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_endOfDate(item, true)
          )
        )
        const createdAfter = pipe(
          advancedFormData.createdFromDate,
          O.fromNullable,
          O.fold(
            () => undefined,
            (item) => PulseOpsFormat.serviceInquiry_StartOfDate(item, true)
          )
        )
        const callOutCode = pipe(advancedFormData.lastCallingResult, O.fromNullable,
          O.fold(
            () => undefined,
            (item) => item.value
          )
        )
        const latestAssignee = pipe(advancedFormData.lastAssignee, O.fromNullable,
          O.fold(
            () => undefined,
            (item) => item.value
          )
        )
        const leaderOfAssignee = pipe(advancedFormData.leader, O.fromNullable,
          O.fold(
            () => undefined,
            (item) => item.value
          )
        )
        const caseStatus = pipe(advancedFormData.status, O.fromNullable,
          O.fold(
            () => undefined,
            (item) => item.value
          )
        )
        const currentActivity = pipe(advancedFormData.currentActivity, O.fromNullable,
          O.fold(
            () => undefined,
            (item) => item.value
          )
        )
        const transactionTypeIn =
          pipe(advancedFormData.callProgram, O.fromNullable,
            O.fold(
              () => undefined,
              (item) => !!item.value ? [item.value] : undefined
            )
          )
        const saleChannel = pipe(advancedFormData.agency, O.fromNullable,
          O.fold(
            () => undefined,
            (item) => item.value
          )
        )
        dataQuery = {
          policyFirstIssuedDateBefore: policyFirstIssuedDateBefore,
          policyFirstIssuedDateAfter: policyFirstIssuedDateAfter,
          policyLastIssuedDateBefore: policyLastIssuedDateBefore,
          policyLastIssuedDateAfter: policyLastIssuedDateAfter,
          caseSubmittedBefore: caseSubmittedBefore,
          caseSubmittedAfter: caseSubmittedAfter,
          paidToDateBefore: paidToDateBefore,
          paidToDateAfter: paidToDateAfter,
          callOutCode: callOutCode,
          latestAssignee: latestAssignee,
          leaderOfAssignee: leaderOfAssignee,
          currentActivity: currentActivity,
          caseStatus: caseStatus,
          saleChannel: saleChannel,
          transactionTypeIn: transactionTypeIn,
          policyNumber: undefined,
          idNumber: undefined,
          clientNumber: undefined,
          phoneNumber: undefined,
          createdAfter: createdAfter,
          createdBefore: createdBefore,
          size: pageSize,
          start: pageSize * pageNum,
          order: 'startTime',
          category: 'OB',
          sort: "DESC"
        }
        break
      }
    }
    setPageSize(pageSize)
    setPageNum(pageNum)
    // setSubmittedData(dataQuery)
    getServiceInquiry(dataQuery, isAdvanceSearch)

  }

  const checkPressableCaseID = React.useCallback(
    (row: any) => {
      return (
        row.businessKey && roles.includes(Permission['ViewDetailcaseOBServiceInquiry'])
      )
    },
    [roles]
  )

  const getLabelVipRank = (vipcode: string) => {
    const vipFind = TaskConst.VIPList.find((i) => i.code === vipcode)
    return i18n.language === 'en' ? vipFind?.descriptionEN : vipFind?.descriptionVN
  }

  const getLabelCallProgram = (callProgram: string) => {
    const findCallProgram = mapCallProgram.get(callProgram as OBCallProgramTypeCode)
    return findCallProgram || ''
  }

  const getBasketType = (item: TaskServiceInquiryData) => {
    const basketType = item.activity?.includes('Quality Control') || item.status === OBStatus.COMPLETED ? 'QC' :
      item.activity?.includes('Re QC') ? SubTaskType.REQC : ''
    return basketType
  }

  const getLeaderName = (item: TaskServiceInquiryData) => {
    const leaderName = item.activity?.includes('Verification') || item.activity?.includes('Pending') ? (item.outBoundLeaderOfStaff || '-') : '-'
    return leaderName
  }

  const BasicFields = () => {
    if (tab === TabSearchOB.BasicSearch)
      return (
        <View style={styles.row}>
          {SearchFields.basicSearchFieldList(t).map((i) => i.type !== 'DATE_FROM_TO' ? (
            <View style={styles.col}>
              <Controller
                name={i.formName as SearchFields.TypeNameFieldBasic}
                control={basicSearchForm.control}
                rules={i.formName === 'fromDate' || i.formName === 'toDate' ? Object.assign({ ...i.rules }, validation(i.formName)) : i.rules}
                render={(controller) => SearchFields.getFieldSearch({ field: i, controller })}
              />
            </View>
          ) : <></>)}
        </View>
      )
    return <></>
  }

  const AdvancedFields = () => {
    if (tab === TabSearchOB.AdvancedSearch) {
      return <>
        {advancedSearchFieldList(t).map((i) => i.type === 'DATE_FROM_TO' && (
          <View style={styles.row}>
            <View style={styles.col2}>
              <Text style={[styles.label, { fontSize: 15 }]}>
                {i.title}
                {i.required && (<Text style={{ color: '#ed1b2c', fontWeight: 'bold', marginStart: 5 }}>{'*'}</Text>)}
              </Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Controller
                  name={i.formNameDateFrom as SearchFields.TypeNameFieldAdvantage}
                  control={advancedSearchForm.control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker onChange={field.onChange} onBlur={field.onBlur} value={field.value as Date | undefined ?? null} />
                  )}
                />
                <Text style={{ color: '#000000', fontSize: 15, marginHorizontal: 10 }}>~</Text>
                <Controller
                  name={i.formNameDateTo as SearchFields.TypeNameFieldAdvantage}
                  control={advancedSearchForm.control}
                  rules={i.rules}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker onChange={field.onChange} onBlur={field.onBlur} value={field.value as Date | undefined ?? null} errorMessage={error?.message} />
                  )}
                />
              </View>
            </View>
          </View>
        ))}
        <View style={styles.row}>
          {advancedSearchFieldList(t).map((i) => i.type !== 'DATE_FROM_TO' && (
            <View style={styles.col}>
              <Controller
                name={i.formName as SearchFields.TypeNameFieldAdvantage}
                control={advancedSearchForm.control}
                rules={i.formName === 'fromDate' || i.formName === 'toDate' ? Object.assign({ ...i.rules }, validation(i.formName)) : i.rules}
                render={(controller) => SearchFields.getFieldSearch({ field: i, controller })}
              />
            </View>
          ))}
        </View>
      </>
    }
    return <></>
  }

  const SearchContainer = () => {
    return tab === null ? (
      <></>
    ) : (
      <View style={{ flex: 1 }}>
        <View style={styles.searchContainer}>
          <View style={[styles.row, styles.titleContainer]}>
            {roles.includes(Permission['ViewBasicSearchOBServiceInquiry']) && (
              <View
                style={tab === TabSearchOB.BasicSearch ? { backgroundColor: '#FAFAFA', padding: 16 } : { padding: 16 }}
              >
                <RadioButton
                  key="BasicSearch"
                  label={t('ServiceInquiry:BasicSearch')}
                  selected={tab === TabSearchOB.BasicSearch}
                  onChange={() => setTab(TabSearchOB.BasicSearch)}
                />
              </View>
            )}
            {roles.includes(Permission['ViewAdvancedSearchOBServiceInquiry']) && (
              <View
                style={tab === TabSearchOB.AdvancedSearch ? { backgroundColor: '#FAFAFA', padding: 16 } : { padding: 16 }}
              >
                <RadioButton
                  key="AdvancedSearch"
                  label={t('ServiceInquiry:AdvancedSearch')}
                  selected={tab === TabSearchOB.AdvancedSearch}
                  onChange={() => setTab(TabSearchOB.AdvancedSearch)}
                />
              </View>
            )}
          </View>
          {BasicFields()}
          {AdvancedFields()}
          <View style={[styles.row, { alignItems: 'center', marginLeft: 10 }]}>
            <TouchableOpacity
              style={{ width: 118, height: 40 }}
              onPress={() => onSearchEvent()}
            >
              <View style={styles.btn}>
                <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={[styles.row, { marginTop: 10, marginLeft: 10 }]}>
            <Text style={{ color: '#ED1B2E' }}>
              {messageError}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  const TableServiceInquiry = () => {
    return (
      rows.length > 0 ? (
        <View style={styles.section}>
          <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
            <Table style={{ userSelect: 'text' }} stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableHeaderSort
                  data={rows}
                  setData={setRows}
                  sortItem={sortItem}
                  setSortItem={setSortItem}
                  column={OBTableServiceInquiry.columnCSAdvanceServiceInquiry(t, tab as TabSearchOB)}
                />
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <TableRow key={`row-${i}`}>
                      <TableCell className={classes.cellNoWrap}>
                        {checkPressableCaseID(row) ? (
                          <Pressable
                            onPress={() =>
                              props.navigation.navigate('OutboundStack', {
                                screen: 'OBCallOutDetailScreen',
                                params: {
                                  caseID: row.businessKey,
                                  basket: getBasketType(row),
                                  policyNumber: row.policyNumber,
                                  isInquiry: '1',
                                  callProgram: row.transactionType ? getLabelCallProgram(row.transactionType) : '-',
                                  policyOwner: row.poName || '',
                                  clientNumber: row.poClientNumber || '',
                                  processInstanceId: row.processInstanceId || '',
                                  transactionType: row.transactionType || ''
                                }
                              })
                            }
                          >
                            <Text style={[{ color: '#ed1b2e' }, styles.cellNoWrap]}>{row.policyNumber}</Text>
                          </Pressable>
                        ) : (
                          <Text style={styles.cellNoWrap}>{row.policyNumber || '-'}</Text>
                        )}
                      </TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.transactionType ? getLabelCallProgram(row.transactionType) : '-'}</TableCell>
                      {tab === TabSearchOB.AdvancedSearch && <>
                        <TableCell className={classes.cellNoWrap}>{row.policyFirstIssuedDate ? PulseOpsFormat.dateStringtoFormat(row.policyFirstIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
                        <TableCell className={classes.cellNoWrap}>{row.policyLastIssuedDate ? PulseOpsFormat.dateStringtoFormat(row.policyLastIssuedDate, 'DD/MM/YYYY') : ''}</TableCell>
                        <TableCell className={classes.cellNoWrap}>{row.paidToDate ? PulseOpsFormat.dateStringtoFormat(row.paidToDate, 'DD/MM/YYYY') : ''}</TableCell>
                        <TableCell className={classes.cellNoWrap}>{row.caseSubmittedDate ? PulseOpsFormat.dateStringtoFormat(row.caseSubmittedDate, 'DD/MM/YYYY - HH:mm:ss') : ''}</TableCell>
                        <TableCell className={classes.cellNoWrap}>{getLastCallingResultName(row.callOutCode || '')}</TableCell>
                      </>
                      }
                      <TableCell className={classes.cellNoWrap}>{row.poClientNumber || '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.poName || '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.vip ? getLabelVipRank(row.vip) : '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.outboundAgency || '-'}</TableCell>
                      {tab === TabSearchOB.AdvancedSearch && <TableCell className={classes.cellNoWrap}>{row.agentCode || ''}</TableCell>}
                      <TableCell className={classes.cellNoWrap}>{row.outBoundCallBackAppointmentDate ? PulseOpsFormat.dateStringtoFormat(row.outBoundCallBackAppointmentDate, 'DD/MM/YYYY - HH:mm') : '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.createdDate ? OBFormat.formatDateToDateString(new Date(row.createdDate)) : '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.agingDays ?? '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.outBoundExecutionTime ?? '0'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.outBoundLatestHandleStaff || '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{getLeaderName(row)}</TableCell>
                      <TableCell
                        style={row.status ? { color: OBStatusLabel(row.status as OBStatus).color } : {}}
                        className={classes.cellNoWrap}
                      >
                        {row.status ? OBStatusLabel(row.status as OBStatus).label : '-'}
                      </TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.activity || '-'}</TableCell>
                      <TableCell className={classes.cellNoWrap}>{row.businessKey}</TableCell>
                    </TableRow>
                  )
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            page={pageNum}
            rowsPerPage={pageSize}
            count={totalItem}
            onPageChange={(e, page) => {
              getSearchDataForServiceInquiry(page, pageSize)
            }}
            onRowsPerPageChange={(e) => {
              const pageS = Number(e.target.value)
              if (totalItem <= (pageNum * pageS)) {
                getSearchDataForServiceInquiry(0, pageS)
              } else {
                getSearchDataForServiceInquiry(pageNum, pageS)
              }
            }}
            labelRowsPerPage={t('common:PaginationSize')}
            labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
            component={View}
          />
        </View>
      ) : (
        <View style={{ marginHorizontal: 30 }}>
        </View>
      )
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView ref={scrollViewRef}>
        {SearchContainer()}
        {loading && <LinearProgress color="secondary" />}
        {TableServiceInquiry()}
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  section: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 10
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    // marginVertical: 30,
    marginBottom: 30,
    paddingBottom: 16
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    flexWrap: 'wrap'
  },

  col: {
    width: '100%',
    maxWidth: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  col2: {
    width: '100%',
    maxWidth: '60%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 2
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  },

  btnDisabled: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    opacity: 0.5
  }
} as const)
