import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import {
  Checkbox,
  ComponentOfEachBenefit,
  ErrorHandling,
  formatNumberWithComma,
  GeneralService,
  Input,
  TaskDetail,
  useMobile,
  HeaderTable,
  ClaimCaseDuplicatedList,
  SelectOption,
  AppContext
} from '@pulseops/common'
import { ClaimType } from '@pulseops/submission'
import { pipe } from 'fp-ts/lib/function'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native'
import { InfoViewList } from '../../../common'
import { Panel } from '../claim-common'
import { BenefitClaim, ClaimInfoForm, PolicyRiderOrigin } from '../claim-type/ClaimInfoForm'
import * as D from 'date-fns'
const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#D3DCE6'
  },
  tableBody: {
    backgroundColor: '#FAFAFA'
  },
  cellWidth200: {
    minWidth: 300
  },
  cellWidth100: {
    maxWidth: 100
  }
})

export type SubPAR = {
  prodCode: string
  prodStatus: string
  sumAssured: string
  claimAmount: string
  relatedCase: string
  dupplicatedCase: string
  eligible: boolean
}

export type PAR = {
  prodCode: string
  prodStatus: string
  sumAssured: string
  claimAmount: string
  relatedCase: string
  dupplicatedCase: string
  eligible: boolean
  renderSubRow: SubPAR[]
}

interface Props {
  innerProps: {
    detail: TaskDetail.VerificationClaim
    // parSources: PAR[]
    // onEligableCheck: (isParents: boolean, isSub: boolean, index: number) => void
    form: UseFormReturn<ClaimInfoForm>
    isPressSave: boolean
  }
  disabled?: boolean
  isEditable?: boolean
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
  duplicateList?: ClaimCaseDuplicatedList
  setDuplicates: (data: ClaimCaseDuplicatedList) => void
  setEligibleRequest: (data: any) => void
}

interface DuplicatedProps {
  duplicateList: ClaimCaseDuplicatedList
}

const DuplicatedCase = ({ duplicateList }: DuplicatedProps) => {
  const { t } = useTranslation()

  const mappingColorStatus = (status: string): string => {
    switch (status) {
      case 'Completed':
        return '#4CAF50'
      case 'In progress':
        return '#1EA5FC'
      case 'Pending':
        return '#FF6F00'
      case 'End':
        return '#ED1B2E'
      case 'Rejected':
        return '#ED1B2E'
      default:
        return 'black'
    }
  }

  // return _.isEmpty(duplicateList) || _.isNil(duplicateList) ? (
  //   <View></View>
  // ) : (
  return (
    <View>
      <Text style={[style.titleSectionText, { textTransform: 'uppercase' }]}>{t('claim:DuplicatedCase')}</Text>
      <HeaderTable
        columns={[
          {
            key: 0,
            name: 'businessKey',
            title: t('claim:CaseInquiry'),
            flex: 3,
            styles: style.tableValue
          },
          {
            key: 1,
            name: 'claimNo',
            title: t('claim:ClaimNumber'),
            flex: 1,
            styles: style.tableValue
          },
          {
            key: 2,
            name: 'claimStatus',
            title: t('claim:ClaimStatus'),
            flex: 1,
            styles: style.tableValue,
            render: (field) => {
              const status =
                duplicateList[field.index]?.claimStatus === 'In process'
                  ? 'In progress'
                  : duplicateList[field.index]?.claimStatus
              return (
                <View>
                  <Text style={{ color: mappingColorStatus(status || ''), fontWeight: 'bold' }}>{status}</Text>
                </View>
              )
            }
          },
          {
            key: 3,
            name: 'claimActivity',
            title: t('claim:CurrentActivity'),
            flex: 1,
            styles: style.tableValue
          },
          {
            key: 4,
            name: 'policy',
            title: t('claim:PolicyNumber'),
            flex: 1,
            styles: style.tableValue
          }
        ]}
        scrollable={true}
        contentHeight={300}
        dataSource={duplicateList ?? []}
      />
    </View>
  )
  // )
}

export const PolicyAndRider: React.FC<Props> = ({
  innerProps,
  disabled,
  isEditable = true,
  setIsCanChangeTab,
  duplicateList,
  setDuplicates,
  setEligibleRequest
}) => {
  const { detail, form, isPressSave } = innerProps
  const { isWide } = useMobile()
  const classes = useStyles()
  const { t, i18n } = useTranslation(['claim', 'message', 'Adjudication'])
  const { attributes } = detail.detail.payload.body
  const [lstHistoryEligible, setLstHistoryEligible] = React.useState<string[]>([])
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const getValue = (value: string, mappingData: any, key: string, isICU?: boolean) => {
    let rs = ''
    mappingData.some(function (el: any) {
      return (
        el.productComponent &&
        el.productComponent.some(function (u: any) {
          if (u.productSubCode === value && !isICU) {
            rs = u[key]
            return true
          } else if (u.productSubCode === value && isICU) {
            rs = el.productComponent.find((item: any) => item.productSubCode === value && item.isICU)[key]
            return true
          }
          return false
        })
      )
    })
    return rs
  }

  let haveTPDorCIorICD: string[] = [],
    isRelatedToAccident: boolean = false

  if (form.getValues('claimType.value') === ClaimType.CRITICAL_ILLNESS) {
    haveTPDorCIorICD =
      ((attributes.CRITICAL_ILLNESS?.ciName as Array<SelectOption>) || []).map((item) => item.value) || []
    isRelatedToAccident = form.watch('criticalIllness.isRelatedToAccident')
  } else if (form.watch('claimType.value') === ClaimType.DISABILITY) {
    haveTPDorCIorICD = ((attributes.DISABILITY?.tpdName as Array<SelectOption>) || []).map((item) => item.value) || []
    isRelatedToAccident = form.watch('disability.isRelatedToAccident')
  } else if (form.watch('claimType.value') === ClaimType.MEDICAL) {
    const { mainDiagnosisType, additionalDiagnosisType } = form.watch('hospital')
    const mainDiagnosis = mainDiagnosisType ? [mainDiagnosisType.value] : []
    const additionalDiagnosis = additionalDiagnosisType ? additionalDiagnosisType?.map((item) => item.value) : []
    haveTPDorCIorICD = [...mainDiagnosis, ...additionalDiagnosis]
    isRelatedToAccident = form.watch('hospital.isRelatedToAccident')
  } else if (form.watch('claimType.value') === ClaimType.DEATH) {
    isRelatedToAccident = form.watch('death.isRelatedToAccident')
  }

  let isICU: string = 'No'
  if (
    detail?.detail?.payload?.body?.attributes?.PATIENT_IN?.icuBenefit ||
    (detail?.detail?.payload?.verification?.subBenefit &&
      Array.isArray(detail?.detail?.payload?.verification?.subBenefit) &&
      detail?.detail?.payload?.verification?.subBenefit?.length > 0 &&
      !!detail?.detail?.payload?.verification?.subBenefit[0]?.receiptItems?.find(
        (item) => item.receiptItemCode?.value === 'RCI02'
      ))
  ) {
    isICU = 'Yes'
  }

  const durationICU: string | undefined =
    detail?.detail?.payload?.verification?.subBenefit
      ?.find((_) => _.value === 'SBC01')
      ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'

  const durationHospitalization: number | undefined = detail?.PATIENT_IN
    ? D.differenceInDays(
        moment(detail?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
        moment(detail?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
      )
    : 0

  const listComponent =
    (detail?.detail?.processInstanceId &&
      detail?.policyRider.policyNo &&
      pipe(
        ZIO.zipPar(
          GeneralService.getComponent(),
          pipe(
            GeneralService.getPolicyRiderInfo(
              detail?.detail?.processInstanceId || '',
              detail?.policyRider.policyNo || '',
              form.getValues('claimType.value'),
              form.getValues('subClaimType.value'),
              isRelatedToAccident ? 'Yes' : 'No',
              haveTPDorCIorICD,
              isICU,
              durationICU,
              durationHospitalization.toString(),
              detail?.PATIENT_IN?.admissionDate ?? '',
              detail.PATIENT_IN?.admissionDate || '',
              detail.detail.businessKey || '',
              '',
              detail.claimNumber || '',
              detail.clientIdNumber || '',
              detail.PATIENT_IN?.dischargeDate || '',
              detail.claimType === ClaimType.MEDICAL
                ? detail.PATIENT_IN?.admissionDate ?? ''
                : detail.death?.claimEventDate ??
                    detail.criticalIllness?.claimEventDate ??
                    detail.tdpInfo?.claimEventDate ??
                    '',
              moment(detail?.policyRider.effectiveDate, 'YYYY-MM-DD').add(1, 'years').format('DD/MM/YYYY')
            )
          )
        ),
        ZIO.map(([components, policyRiderInfo]) => {
          if (policyRiderInfo.eligibleResponse?.status?.code === 204) {
            showToast(t('message:ValidatePolicyRider'), 'error', undefined, 5000)
          }
          setEligibleRequest({
            haveAccidentalBenefit: isRelatedToAccident ? 'Yes' : 'No',
            haveTPDorCIorICD,
            haveICUBenefit: isICU,
            durationICU,
            durationHospitalization
          })
          const claimCaseDuplicatedList = policyRiderInfo?.claimCaseDuplicateHistory?.claimCaseDuplicatedList
          if (claimCaseDuplicatedList) setDuplicates(claimCaseDuplicatedList || [])
          const benefitClaim = policyRiderInfo?.eligibleResponse?.data || []
          const payload = detail.policyRider.productOptions
          const [filterData] = payload.map((item) => {
            return {
              productCode: item?.productMainCode,
              planName: item?.planName?.toLowerCase(),
              componentCode: item.productComponent.map((m) => m.productSubCode)
            }
          })
          const mappingData = components.filter(
            (item) =>
              _.includes(filterData.componentCode, item.componentCode) &&
              item.productCode === filterData.productCode &&
              item.planName.toLowerCase() === filterData.planName
          )

          const customData = mappingData.map((value) => {
            const benefitClaimCode = benefitClaim
              ? benefitClaim.filter(
                  (data) =>
                    data.benefitClaimCode === value.benefitClaimCode && data.componentCode === value.componentCode
                )
              : []

            return {
              ...value,
              subStatus: getValue(value.componentCode, detail.policyRider.productOptions, 'subStatus'),
              subTotalSumAssured: getValue(
                value.componentCode,
                detail.policyRider.productOptions,
                'subTotalSumAssured',
                value.benefitClaimEN.includes('ICU')
              ),
              benefitClaimCode
            }
          })

          // const output: ComponentOfEachBenefit = []
          // if (benefitClaim) {
          //   setLstHistoryEligible(benefitClaim.filter((x) => x.eligible).map((item) => item.id))
          //   customData.forEach(function (item) {
          //     const existing = output.filter(function (v, i) {
          //       return v.componentCode == item.componentCode
          //     })
          //     if (existing.length) {
          //       const existingIndex = output.indexOf(existing[0])
          //       output[existingIndex].benefitClaimCode = _.cloneDeep(
          //         output[existingIndex].benefitClaimCode.concat(item.benefitClaimCode)
          //       )
          //     } else {
          //       output.push(_.cloneDeep(item))
          //     }
          //   })
          // }
          return customData
        }),
        ErrorHandling.runDidUpdate([
          JSON.stringify(detail),
          isRelatedToAccident,
          isICU,
          JSON.stringify(haveTPDorCIorICD)
        ])
      )) ||
    []

  const policyStatus = (): string => {
    let result = '-'
    if (detail?.policyRider.status === 'INFORCE') {
      result = t('Adjudication:Inforce')
    }
    return result
  }
  const premiumStatus = (): string => {
    let result = '-'
    if (detail?.policyRider.premiumStatus === 'SINGLEPAY') {
      result = 'Singlepay'
    }
    return result
  }

  const productName = () => {
    return detail?.policyRider?.productOptions.map((item) => item.productMainCode + ' ' + item.productMainName)
  }

  const getClaimNumber = (): string => {
    return detail?.claimNumber
  }

  const dataSource = [
    {
      id: 1,
      label: t('PolicyNumber'),
      value: detail?.policyRider.policyNo || '-'
    },
    {
      id: 2,
      label: t('EffectiveDate'),
      value: moment(detail?.policyRider.effectiveDate, 'YYYY-MM-DD').format('DD/MM/YYYY') || '-'
    },
    {
      id: 3,
      label: t('PolicyStatus'),
      value: t(`claim:${detail?.policyRider.status}`),
      isHighlightWarning: detail?.policyRider.status !== 'INFORCE',
      isBold: true
    },
    {
      id: 4,
      label: t('ProductName'),
      value: productName()
    },
    {
      id: 5,
      label: t('ClaimNumber'),
      value: getClaimNumber()
    },
    {
      id: 6,
      label: t('PremiumStatus'),
      value: t(`claim:${detail?.policyRider.premiumStatus}`)
    }
  ]

  const { control, watch } = form
  const { fields } = useFieldArray<ClaimInfoForm>({
    control,
    name: 'policyRiders'
  })

  React.useEffect(() => {
    form.setValue('policyRiders', listComponent)
    // const components = detail?.detail?.payload?.policyRider?.components
    // const riders = components && _.isArray(components) ? components : []
    // if (riders.length > 0) {
    //   if (isPressSave) {
    //     form.setValue('policyRiders', listComponent)
    //   } else {
    //     console.log(riders)
    //     form.setValue('policyRiders', riders)
    //   }
    // } else {
    //   listComponent ? form.setValue('policyRiders', listComponent) : ''
    // }
  }, [detail, isRelatedToAccident, isICU, JSON.stringify(haveTPDorCIorICD), JSON.stringify(listComponent)])

  return (
    <ScrollView>
      <Text style={[style.titleSectionText, { textTransform: 'uppercase' }]}>{t('PolicyRider')}</Text>
      <Panel title={''} isHiddenTitle={true} styling={style.sectionInfo}>
        <InfoViewList dataSource={dataSource} />
        {isWide && Platform.OS === 'web' && (
          <View>
            <Text style={style.labelStyle}>{t('ProductDetail')}</Text>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.cellWidth200} style={{ fontWeight: 'bold' }}>
                      {t('Product')}
                    </TableCell>
                    <TableCell className={classes.cellWidth100} style={{ fontWeight: 'bold' }}>
                      {t('Status')}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: 45 }}>
                      {t('SumAssured')}
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{t('BenefitClaimAmount')}</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>{t('Eligible')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {fields.map((data: PolicyRiderOrigin, index) => {
                    return (
                      <React.Fragment key={data.id}>
                        <TableRow>
                          <TableCell>
                            <Controller
                              name={`policyRiders.${index}.componentCode` as 'policyRiders.0.componentCode'}
                              control={control}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                  disabled
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  inputStyle={{
                                    fontWeight: 'bold'
                                  }}
                                  value={`${
                                    i18n.language === 'en'
                                      ? watch(`policyRiders.${index}.componentEN`)
                                      : watch(`policyRiders.${index}.componentVN`)
                                  }`}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={`policyRiders.${index}.subStatus` as 'policyRiders.0.subStatus'}
                              control={control}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={t(`claim:${value as string}`)}
                                  inputStyle={value !== 'INFORCE' ? style.highLightWarning : null}
                                  disabled
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <View style={{ paddingEnd: 30 }}>
                              <Controller
                                name={`policyRiders.${index}.subTotalSumAssured` as 'policyRiders.0.subTotalSumAssured'}
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                  <Input
                                    textAlign={'right'}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value ? formatNumberWithComma(value) : ''}
                                    disabled
                                  />
                                )}
                              />
                            </View>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                        {form.watch(`policyRiders.${index}.benefitClaimCode`)?.map((item: BenefitClaim, key) => {
                          return (
                            <TableRow key={item.id + index + key + data.id}>
                              <TableCell component="th" scope="row">
                                <Controller
                                  name={
                                    `policyRiders.${index}.benefitClaimCode.${key}.benefitClaimCode` as 'policyRiders.0.benefitClaimCode.0.benefitClaimCode'
                                  }
                                  control={control}
                                  render={({ field: { onChange, onBlur, value } }) => (
                                    <Input
                                      disabled
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      value={`${watch(
                                        `policyRiders.${index}.benefitClaimCode.${key}.benefitClaimCode`
                                      )} ${
                                        i18n.language === 'en'
                                          ? watch(`policyRiders.${index}.benefitClaimCode.${key}.benefitClaimEN`)
                                          : watch(`policyRiders.${index}.benefitClaimCode.${key}.benefitClaimVN`)
                                      }`}
                                    />
                                  )}
                                />
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                {!!watch(`policyRiders.${index}.benefitClaimCode.${key}.eligible`) && (
                                  <Controller
                                    name={
                                      `policyRiders.${index}.benefitClaimCode.${key}.claimAmount` as 'policyRiders.0.benefitClaimCode.0.claimAmount'
                                    }
                                    control={control}
                                    rules={{
                                      required: {
                                        value: !!watch(`policyRiders.${index}.benefitClaimCode.${key}.eligible`),
                                        message: `${t('message:MS020001', { field: t('ClaimAmount') })}`
                                      },
                                      maxLength: {
                                        value: 15,
                                        message: `${t('message:MS070016', { field: 12 })}`
                                      }
                                    }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                      <Input
                                        onBlur={onBlur}
                                        onChange={(val: string) => {
                                          onChange(val)
                                          setIsCanChangeTab(false)
                                        }}
                                        value={
                                          watch(`policyRiders.${index}.benefitClaimCode.${key}.eligible`)
                                            ? (value as string)
                                            : ''
                                        }
                                        inputType="money"
                                        errorMessage={error?.message}
                                        disabled={
                                          true
                                          // !watch(`policyRiders.${index}.benefitClaimCode.${key}.eligible`) || disabled
                                        }
                                        maxLength={15}
                                      />
                                    )}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Controller
                                  key={item.id + index + key + data.id}
                                  name={
                                    `policyRiders.${index}.benefitClaimCode.${key}.eligible` as 'policyRiders.0.benefitClaimCode.0.eligible'
                                  }
                                  control={control}
                                  defaultValue={false}
                                  render={({ field: { onChange, onBlur, value } }) => (
                                    <Checkbox
                                      title=""
                                      onBlur={onBlur}
                                      value={!!value}
                                      onChange={(e) => {
                                        onChange(!value)
                                        setIsCanChangeTab(false)
                                      }}
                                      disabled={disabled ? disabled : !lstHistoryEligible.includes(item.id)}
                                    />
                                  )}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </React.Fragment>
                    )
                  })}
                  {fields.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                        <Text>{t('common:Nodata')}</Text>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </View>
        )}
      </Panel>
      <View style={style.section}>
        <DuplicatedCase duplicateList={duplicateList || []} />
      </View>
    </ScrollView>
  )
}

const style = StyleSheet.create({
  section: {
    marginBottom: 30,
    marginTop: 30
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  currencyText: {
    fontStyle: 'italic'
  },

  titleSectionText: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginBottom: 10
  },
  inputStyle: {
    fontWeight: 'bold'
  },
  labelStyle: {
    color: '#70777E',
    marginBottom: 10,
    fontWeight: 'bold'
  },
  tableValue: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '300'
  },
  tableValueCenter: {
    color: '#000000',
    fontSize: 16,
    fontWeight: '300',
    alignSelf: 'center'
  },
  highLightWarning: { color: '#FF6F00', fontWeight: 'bold' }
})
