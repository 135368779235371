import { Pressable, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import { FundSwitchingDetail, IBService, InvestmentDataInfo, InvestmentFund } from '../../../../../../ib-service'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { IBGeneralTable } from '../../../../../../common'
import { isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { ModalComponent, formatNumberWithComma } from '@pulseops/common'

interface Props {
  investmentInfo?: InvestmentDataInfo
  policyNumber: string
  setInvestmentInfo: (data: InvestmentDataInfo) => void
  listFundT25?: InvestmentFund[]
}

const ModalFundSwitchingDetail = (props: {
  visible: boolean
  onClose: (visible: boolean) => void
  listFundT25?: InvestmentFund[]
  data: { policyNumber: string; transNo: string }
}) => {
  const { width } = useWindowDimensions()
  const { visible, onClose, data, listFundT25 } = props
  const [loading, bindLoading] = useLoading(false)
  const [fundSwitchingDetail, setFundSwitchingDetail] = React.useState<FundSwitchingDetail[]>([])

  const { t, i18n } = useTranslation('Inbound')
  const getFundSwitchingDetail = (data: { policyNumber: string; transNo: string }) =>
    pipe(
      IBService.getFundSwitchingDetail(data.policyNumber, data.transNo),
      ZIO.map((res) => {
        setFundSwitchingDetail(res.data)
      }),
      bindLoading,
      ZIO.unsafeRun([])
    )

  React.useEffect(() => {
    if (data) getFundSwitchingDetail(data)
    return () => {
      setFundSwitchingDetail([])
    }
  }, [data])

  const dataTable = [
    {
      label: t('SwitchingFund'),
      field: 'switchingFundCode'
    },
    {
      label: t('PercentSwitchingAmount'),
      field: 'percent'
    },
    {
      label: t('SwitchingAmount'),
      field: 'switchingAmount'
    },
    {
      label: t('RealUnitsFund'),
      field: 'realUnits'
    },
    {
      label: t('EffectivePrice'),
      field: 'effectivePrice',
      format: 'money'
    }
  ]

  return (
    <ModalComponent
      title={`${t('FundSwitchDetail')}: ${data.transNo}`}
      titleStyle={{ color: '#000', fontSize: 20 }}
      visible={visible}
      modalWidth={width * 0.6}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <IBGeneralTable
          dataTable={dataTable}
          data={fundSwitchingDetail.map((x) => ({
            ...x,
            switchingFundCode: `${x.switchingFundCode} ${
              i18n.language === 'vi'
                ? listFundT25?.find((fund) => fund.fundCode === x.switchingFundCode)?.inBoundNameVN
                : listFundT25?.find((fund) => fund.fundCode === x.switchingFundCode)?.fundNameEN
            }`,
            percent: x.targetPercent ?? x.sourcePercentAmount,
            switchingAmount: formatNumberWithComma((x.switchingAmount as number).toFixed(0))
          }))}
          loading={loading}
          autoPaging
        />
      </View>
    </ModalComponent>
  )
}

export const FundSwitchHistory = (props: Props) => {
  const { investmentInfo, policyNumber, setInvestmentInfo, listFundT25 } = props
  const { t, i18n } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading()
  const [openModalDetail, setOpenModalDetail] = React.useState<boolean>(false)
  const [dataOpenModal, setDataOpenModal] = React.useState<{
    policyNumber: string
    transNo: string
  }>()
  React.useEffect(() => {
    if (isNil(investmentInfo?.fundSwitchHistory)) {
      const accountingCodes = [
        { sacsCode: 'LE', sacsType: 'SW' },
        { sacsCode: 'LE', sacsType: 'SF' }
      ]
      pipe(
        IBService.ACMVQuery(policyNumber, 'FUND_SWITCHING_HISTORY', 'T676', accountingCodes, true),
        ZIO.map((res) => {
          setInvestmentInfo({
            ...(investmentInfo as InvestmentDataInfo),
            fundSwitchHistory: res.data
          })
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    } else {
      pipe(ZIO.succeed(investmentInfo), bindLoading, ZIO.unsafeRun({}))
    }
  }, [])

  const dataTable = [
    {
      label: t('TransNo'),
      field: 'transNo',
      render: (value: string) => {
        return (
          <Pressable
            onPress={() => {
              setDataOpenModal({
                policyNumber: policyNumber,
                transNo: value
              })
              setOpenModalDetail(true)
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc'
              }}
            >
              {value}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('TransDateFSH'),
      field: 'transDate',
      format: 'date'
    },
    {
      label: t('AmountFSH'),
      field: 'amountFSH',
      format: 'money'
    },
    {
      label: t('FeeFSH'),
      field: 'feeFSH',
      format: 'money'
    }
  ]

  return (
    <View>
      <IBGeneralTable
        data={
          orderBy(
            investmentInfo?.fundSwitchHistory?.map((item) => ({
              ...item,
              transDate: moment(item.transDate, 'DD/MM/YYYY').format('YYYYMMDD'),
              amountFSH: item.additionalProperties?.fundSwitchingMoney?.amount,
              feeFSH: item.additionalProperties?.fundSwitchingMoney?.fee
            })),
            ['transDate', 'transNo'],
            ['desc', 'desc']
          ) || []
        }
        dataTable={dataTable}
        loading={loading}
        autoPaging
      />
      {dataOpenModal ? (
        <ModalFundSwitchingDetail
          visible={openModalDetail}
          onClose={setOpenModalDetail}
          data={dataOpenModal}
          listFundT25={listFundT25}
        />
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({})
