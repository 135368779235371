import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { Input, SelectSearch, TaskDetail, ErrorHandling, SelectOption } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { GeneralForm } from './da-genneral-form'
import { assets } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { DaGeneralInfoService } from './da-genneral-info-service'

export interface Bank {
  name: string
  code: string
}

export interface BankBranch {
  name: string
  code: string
}

export interface OwnerInfo {
  ownerName: string
  nationalId: string
}
type Props = { detail: TaskDetail.AgentOnboardNew; form: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'> }
export const BankAccount = (props: Props) => {
  const { t } = useTranslation('RequestInfo')
  const [banks, setBanks] = React.useState<Bank[]>([])
  const [bankBranchs, setBankBranchs] = React.useState<BankBranch[]>([])
  const { control, setValue, getValues, watch } = props.form
  const [bankBranchItem, setBankBranchItem] = useState('')
  const bankBrancName = props.detail.bankBranch
  const bankCode = watch('BankName.value')

  React.useEffect(() => {
    pipe(
      DaGeneralInfoService.getBanks(),
      ZIO.map((bankArr) => {
        setBanks(bankArr.length ? bankArr : [])
        return bankArr
      }),
      ZIO.map((bankArr) => {
        const bankItem =
          bankArr
            .map((item): SelectOption => ({ label: item.name, value: item.code }))
            .find((item) => item.value === getValues('BankName.value') || item.value === props.detail.bankCode) ?? null
        setValue('BankName', bankItem)
        return bankItem
      }),
      ZIO.flatMap((bankItem) => {
        return !!bankItem
          ? pipe(
              DaGeneralInfoService.bankBranch(bankItem.value || 'AGRI'),
              ZIO.map((branchs) => {
                setBankBranchs(branchs.length ? branchs : [])
                return branchs
              })
            )
          : ZIO.succeed([])
      }),
      ZIO.map((brankList) => {
        if (brankList && brankList.length > 0) {
          const bankBranchItem =
            brankList
              .map((item): SelectOption => ({ label: item.name, value: item.code }))
              .find((item) => item.value === getValues('Bankbranchname.value') || item.value == bankBrancName) ?? null
          // đợi prudaily trả mình Bankbranchcode thì sửa thành value
          !!bankBranchItem && setBankBranchItem(bankBranchItem?.value)
          setValue('Bankbranchname', bankBranchItem)
        }
      }),
      ErrorHandling.run()
    )
  }, [props.detail.bankCode])

  const bankOptions = React.useMemo(
    () => banks.map((item): SelectOption => ({ label: item.name, value: item.code })),
    [banks]
  )

  const bankBranchOptions = React.useMemo(
    () => bankBranchs.map((item): SelectOption => ({ label: item.name, value: item.code })),
    [bankBranchs]
  )

  const getBankBranch = (code: string) =>
    pipe(
      DaGeneralInfoService.bankBranch(code),
      ZIO.map((branchs) => {
        setBankBranchs(branchs.length ? branchs : [])
        return branchs
      }),
      ErrorHandling.run()
    )

  React.useEffect(() => {
    // setBankBranchCode(bankCode)

    let code = ''
    if (!!bankCode) {
      code = bankCode
    }
    if (!!bankBranchItem) {
      code += '-' + bankBranchItem
    }
    setValue('Bankcode', code)
  }, [bankCode, bankBranchItem])

  return (
    <View style={styles.row}>
      <View style={styles.col}>
        <Controller
          name="BankName"
          control={control}
          // rules={{
          //   required: {
          //     value: true,
          //     message: `${t('message:MS020009', { field: t('BankName') })}`
          //   }
          // }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectSearch
              required={true}
              popupIcon={<assets.ArrowDownDropdownIcon />}
              label={t('BankName')}
              options={bankOptions}
              value={value}
              onBlur={onBlur}
              onChange={(option) => {
                setBankBranchs([])
                props.form.setValue('Bankbranchname', null)
                if (option) {
                  getBankBranch(option.value)
                }
                onChange(option)
              }}
              placeholder={t('common:Select')}
              errorMessage={error?.message}
            />
          )}
        />
      </View>
      <View style={styles.cols}>
        <Controller
          control={control}
          name="Bankbranchname"
          render={({ field, fieldState: { error } }) => (
            <View style={{ flex: 1 }}>
              <SelectSearch
                {...field}
                label={t('Bankbranchname')}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                options={bankBranchOptions}
                placeholder={t('common:Select')}
                errorMessage={error?.message}
                value={field.value}
                onBlur={field.onBlur}
                required={true}
                onChange={(value) => {
                  field.onChange(value)
                  setBankBranchItem(value?.value ?? '')
                }}
              />
            </View>
          )}
        />
      </View>
      <View style={styles.col}>
        <Controller
          name="Bankcode"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Input
              title={t('Bankcode')}
              disabled={true}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              // errorMessage={error?.message}
              maxLength={24}
              // required={true}
            />
          )}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    marginBottom: 16
  },
  containers: {
    width: '100%',
    padding: 30,
    borderRadius: 8,
    marginBottom: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6'
  },
  col: {
    width: '33.33333333333%'
  },
  cols: {
    width: '33.33333333333%',
    paddingHorizontal: 30
  },
  row: {
    flexDirection: 'row'
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' }
})
