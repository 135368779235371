import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

const path = (path: string) => `pulseops/api/v1/outbound/assignment/${path}`

export const ManualGetCaseS = t.type({
  taskId: Maybe(t.string),
  processInstanceId: Maybe(t.string),
  status: t.union([t.literal('SUCCEED'), t.literal('FAILED'), t.undefined]),
  completedTime: Maybe(t.string),
  errorCode: Maybe(t.string),
  errorMessage: Maybe(t.string),
  uuid: Maybe(t.string),
  logs: t.array(
    t.type({
      taskId: Maybe(t.string),
      processInstanceId: Maybe(t.string),
      status: t.union([t.literal('SUCCEED'), t.literal('FAILED'), t.undefined]),
      completedTime: Maybe(t.string)
    })
  )
})

export type ManualGetCase = t.TypeOf<typeof ManualGetCaseS>

export const GetCaseAdHoc = t.array(
  t.type({
    code: Maybe(t.string),
    name: Maybe(t.string)
  })
)

export type GetCaseAdHoc = t.TypeOf<typeof GetCaseAdHoc>

export namespace OBGetCasesService {
  export const getAuthorizedTransactions = () => {
    return pipe(
      POApi.get(`${path('authorized-transactions')}`)(t.array(t.string)),
      ZIO.map((res) => res)
    )
  }

  export const manualGetCase = (payload: { transactionType: string; isGetMaxCase: boolean, adHocCode?: string }) => {
    return pipe(
      POApi.post(`${path('manual-get-case')}`)(ManualGetCaseS)({ ...payload }),
      ZIO.map((res) => res)
    )
  }

  export const getAdHocCode = (isAdhocCS?: boolean) => {
    return pipe(
      POApi.get(`pulseops/api/v1/outbound/transaction/sub-transactions/${isAdhocCS ? 'ADHOC_CS': 'ADHOC_CCE'}`)(GetCaseAdHoc),
      ZIO.map((res) => res)
    )
  }
}
