import { FontAwesome5 } from '@expo/vector-icons'
import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle, ActivityIndicator } from 'react-native'

interface Props {
  title?: string | JSX.Element
  titleOutline?: string
  allowToggle?: boolean
  isExand?: boolean
  children?: React.ReactNode
  containerStyle?: ViewStyle
  isLoading?: boolean
  isNoPadding?: boolean
  isTransactionToggleExpand?: boolean
  activeIcon?: JSX.Element
  nonActiveIcon?: JSX.Element
  isShowIconTitle?: boolean
  isActiveIcon?: boolean
  onPress?: () => void
  errorMessageTitle?: string
}

export const CepPanel: React.FC<Props> = ({
  title,
  isExand = true,
  isLoading = false,
  children,
  titleOutline,
  allowToggle,
  containerStyle,
  isNoPadding = false,
  isTransactionToggleExpand = false,
  isShowIconTitle = false,
  isActiveIcon = false,
  activeIcon,
  nonActiveIcon,
  onPress,
  errorMessageTitle
}) => {
  const [toggle, setToggle] = useState(true)

  const onToggle = () => {
    allowToggle !== false && setToggle(!toggle)
  }

  return (
    <View>
      {!!titleOutline && <Text style={[styles.sectionInfoHeaderTitle, styles.titleOutline]}>{titleOutline}</Text>}
      <View style={[styles.sectionInfo, containerStyle, isNoPadding && styles.noPadding]}>
        <View
          style={[
            styles.sectionInfoHeader,
            !title && styles.sectionInfoHeaderInnerRight,
            isNoPadding && styles.touchableBtn,
            !toggle && styles.bottomPadding
          ]}
        >
          {!!title && !isTransactionToggleExpand && (
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={[styles.sectionInfoHeaderTitle, isShowIconTitle ? { paddingRight: 10 } : {}]}>{title}</Text>
              {errorMessageTitle !== null ? (
                <Text style={[styles.errorMsgTitle, { paddingLeft: 10 }]}>{errorMessageTitle}</Text>
              ) : null}
              {isShowIconTitle && (
                <TouchableOpacity onPress={onPress}>{isActiveIcon ? activeIcon : nonActiveIcon}</TouchableOpacity>
              )}
            </View>
          )}
          {isExand && !isTransactionToggleExpand && (
            <TouchableOpacity onPress={onToggle}>
              <FontAwesome5 name={toggle ? 'minus-circle' : 'plus-circle'} color="#1B365D" size={25} />
            </TouchableOpacity>
          )}
          {isTransactionToggleExpand && (
            <TouchableOpacity onPress={onToggle} style={styles.transactionContent}>
              {!!title && <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>}
              <FontAwesome5 name={toggle ? 'minus-circle' : 'plus-circle'} color="#1B365D" size={25} />
            </TouchableOpacity>
          )}
        </View>
        {toggle && (
          <View style={styles.sectionInfoContent}>
            {isLoading ? <ActivityIndicator size="large" color="#ED1B2E" /> : children}
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  sectionInfoHeaderInnerLeft: {
    justifyContent: 'flex-start'
  },
  sectionInfoHeaderInnerRight: {
    justifyContent: 'flex-end'
  },
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfoContent: {
    marginTop: 16
  },
  noPadding: {
    padding: 0,
    backgroundColor: '#FAFAFA'
  },
  transactionContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  touchableBtn: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  bottomPadding: {
    paddingBottom: 20
  },
  errorMsgTitle: {
    fontSize: 12,
    lineHeight: 22,
    fontWeight: '400',
    color: '#ED1B2E',
    fontStyle: 'italic'
  }
})
