import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'

export interface ReceiptData {
  receiptItemCode: string
  dayNumber: string
  duration: string
  surgeryCode: string
  expenseAmt: string
  thirdPartyAmt: string
  siAmt: string
  claimAmt: string
  excludedAmt: string
  payableAmt: string
}

export interface PolicyRiderData {
  riderId: string
  productCode: string
  productStatus: string
  sumAssured: string
  relatedCase: string
  duplicatedCase: string
  eligible: boolean
  claimAmount: string
}

export interface PaymentInfo {
  paymentMethod: string
  isPayeeNotPO?: boolean | null | undefined
  relationshipWithPO: string | null | undefined
  dob: Date | null | undefined | string
  gender: string | null | undefined
  occupation: string | null | undefined
  residenceCountry: string | null | undefined
  nationality: string | null | undefined
  nationality2: string | null | undefined
  address: string | null | undefined
  foreignAddress: string | null | undefined
  bankTransfer?:
    | {
        accountName: string
        idNumber: string
        bank: string
        bankName: string | null | undefined
        branch: string | null | undefined
        branchName: string | null | undefined
        accountNumber: string
      }
    | null
    | undefined
  payAtBank?:
    | {
        payeeName: string
        idNumber: string
        issuedDate: string
        issuedBy: string
        bankName: string
        bank: string | null | undefined
        branch: string | null | undefined
        branchName: string | null | undefined
      }
    | null
    | undefined
  payAtCounter?:
    | {
        payeeName: string | null | undefined
        idNumber: string | null | undefined
        gaOffice: string
      }
    | null
    | undefined
}
export interface PaymentModeData {
  paymentModeCode: string
  payeeIsNotValid: boolean
  payment: PaymentInfo
}

export interface ReceiptSubmitData {
  processInstanceId: string
  user: string
  data: ReceiptData[]
}

export interface PolicyRiderSubmitData {
  processInstanceId: string
  user: string
  data: PolicyRiderData[]
}

export interface PaymentMethodSubmitData {
  processInstanceId: string
  user: string
  data: PaymentModeData[]
}

export namespace ClaimUpdateService {
  export const saveReceiptInfo =
    <C extends t.Mixed>(resp: C) =>
    (body: ReceiptSubmitData) => {
      return POApi.postError()('pulseops/api/v1/claim/receipt-info/save-log')(resp)(body)
    }

  export const savePolicyRider =
    <C extends t.Mixed>(resp: C) =>
    (body: PolicyRiderSubmitData) => {
      return POApi.postError()('pulseops/api/v1/claim/rider-info/save-log')(resp)(body)
    }

  export const savePaymentMethod =
    <C extends t.Mixed>(resp: C) =>
    (body: PaymentMethodSubmitData) => {
      return POApi.postError()('pulseops/api/v1/claim/payment-mode-info/save-log')(resp)(body)
    }

  export const getPolicyRiderLogs = (processId: string) =>
    pipe(
      POApi.getConfig({ params: { processInstanceId: processId } })(
        `pulseops/api/v1/claim/rider-info/get-log-histories?`
      )(
        t.type({
          data: Maybe(
            t.array(
              t.type({
                riderId: t.string,
                productCode: t.string,
                productStatus: t.string,
                sumAssured: t.string,
                relatedCase: t.string,
                duplicatedCase: t.string,
                eligible: t.boolean,
                claimAmount: t.string
              })
            )
          )
        })
      ),
      ZIO.map((res) => res.data)
    )

  export const getReceiptInfoLogs = (processId: string) =>
    pipe(
      POApi.getConfig({ params: { processInstanceId: processId } })(
        `pulseops/api/v1/claim/receipt-info/get-log-histories?`
      )(
        t.type({
          data: t.array(
            t.type({
              receiptItemCode: t.string,
              dayNumber: t.string,
              duration: t.string,
              surgeryCode: t.string,
              expenseAmt: t.string,
              thirdPartyAmt: t.string,
              siAmt: t.string,
              claimAmt: t.string,
              excludedAmt: t.string,
              payableAmt: t.string,
              createdBy: t.string
            })
          )
        })
      ),
      ZIO.map((res) => res.data)
    )
  export const getPaymenMethodLogs = (processId: string, paymentCode: string) =>
    pipe(
      POApi.getConfig({ params: { processInstanceId: processId, paymentModeCode: paymentCode } })(
        `pulseops/api/v1/claim/payment-mode-info/get-payment-mode-log?`
      )(
        t.type({
          data: Maybe(
            t.type({
              id: t.string,
              paymentModeCode: t.string,
              payeeIsNotValid: t.string,
              payment: t.type({
                paymentMethod: t.string,
                isPayeeNotPO: Maybe(t.boolean),
                relationshipWithPO: Maybe(t.string),
                dob: Maybe(t.string),
                gender: Maybe(t.string),
                occupation: Maybe(t.string),
                residenceCountry: Maybe(t.string),
                nationality: Maybe(t.string),
                nationality2: Maybe(t.string),
                address: Maybe(t.string),
                foreignAddress: Maybe(t.string),
                bankTransfer: Maybe(
                  t.type({
                    accountName: t.string,
                    idNumber: t.string,
                    bank: t.string,
                    bankName: t.string || null,
                    branch: t.string || null,
                    branchName: t.string || null,
                    accountNumber: t.string || t.number
                  })
                ),
                payAtBank: Maybe(
                  t.type({
                    payeeName: t.string,
                    idNumber: t.string,
                    issuedDate: t.string,
                    issuedBy: t.string,
                    bankName: t.string,
                    bank: t.string || null,
                    branch: t.string || null,
                    branchName: t.string || null
                  })
                ),
                payAtCounter: Maybe(
                  t.type({
                    payeeName: t.string || null,
                    idNumber: t.string || null,
                    gaOffice: t.string
                  })
                )
              })
            })
          )
        })
      ),
      ZIO.map((res) => res.data)
    )
}
