import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const GetCommentListResponse = t.array(
  t.type({
    author: t.string,
    message: t.string,
    time: DateFromISOString
  })
)
export type GetCommentListResponse = t.TypeOf<typeof GetCommentListResponse>
