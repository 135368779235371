import moment from 'moment'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface VeriLoanStatements {
  tag: TransactionType.LOAN_STATEMENTS
  clientName: string
  office: string
  fromDate: string
  toDate: string
}

export const LoanStatements = (detail: api.LoanStatements & api.TaskDetailBase, poName: string): VeriLoanStatements => {
  // console.log(`detail:${JSON.stringify(detail)}`)
  const payloadInfo = detail.payload.body
  const customedFromDate = payloadInfo.fromDate ? moment(payloadInfo.fromDate).format('DD/MM/YYYY') : ''
  const customedToDate = payloadInfo.toDate ? moment(payloadInfo.toDate).format('DD/MM/YYYY') : ''
  return {
    tag: TransactionType.LOAN_STATEMENTS,
    clientName: poName,
    office: '',
    fromDate: customedFromDate,
    toDate: customedToDate
  }
}
