import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import i18next from 'i18next'
import { pipe } from 'fp-ts/lib/function'
import { NonEmptyString, withMessage } from 'io-ts-types'

export type PolicyNumberBrand = {
  readonly PolicyNumber: unique symbol
}

export const validatePolicyNumber = (val: string) => /^[0-9]*$/.test(val) && (val.length === 8 || val.length === 9)

export const PolicyNumber = pipe(
  NonEmptyString,
  form2.refine(
    (val): val is t.Branded<NonEmptyString, PolicyNumberBrand> => validatePolicyNumber(val),
    () => i18next.t('message:MS090001'),
    'PolicyNumber'
  )
)
export namespace SEABillingChangeForm {
  const Base = t.type({
    policyNo: pipe(
      form2.string.requiredM(() =>
        i18next.t('message:MS020001', {
          field: i18next.t('BillingChange:Policy')
        })
      ),
      form2.string.minLength(8, () => i18next.t('BillingChange:CheckLengthPolicy'))
    ),
    newBillingFrequency: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('BillingChange:NewBillingFrequency') })
    ),
    currentBillingFrequency: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('BillingChange:NewBillingFrequency') })
    ),
    newInstallmentPremium: form2.string.optional,
    curentInstallmentPremium: form2.string.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('BillingChange:CurentInstallmentPremium')
      })
    ),
    effectiveDateOfNewFrequency: form2.date.requiredM(() =>
      i18next.t('message:MS020001', {
        field: i18next.t('BillingChange:EffectiveDateOfNewFrequency')
      })
    ),
    checkDialog: t.boolean,
    implementUser: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('BillingChange:ImplementUser') })
    ),
    importedFile: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('BillingChange:ImportedFileName') })
    )
  })
  export const codec = Base
  export type Validated = t.TypeOf<typeof codec>
  export type Raw = t.OutputOf<typeof codec>
}
