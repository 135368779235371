import { CashOut } from './CashOutOption'
export namespace BonusSurrenderPayment {
  export interface Detail {
    bonusValue: number
    principalValue: number
    interestValue: number
    bonusReserveValue: number
    bonusReserveRemainValue: number
  }
  export interface AccessDataCheck {
    contractStatus: string
    balanceAmount: number
  }
  export interface SubmitData {
    policy: {
      owners: {
        clientId: string
      }
      attributesExt: {
        bonusValueAmountToSurrender: number
      }
    }
    cashOutOptions: Array<CashOut.Option>
  }

  export interface SubmiDataV2 {
    policy: {
      policyNo: string
    }
    attributesExt: {
      bonusValue: number
      principalAplOpl: number
      interestAplOpl: number
      bonusValueAmountToSurrender: number
      bonusReserveValue: number
      bonusReserveRemainValue: number
      TOTAL_PAYOUT_AMOUNT: number
      BONUS_AMOUNT: number
    }
    cashOutOptions: CashOut.Option[]
  }

  export interface MaturityBenefit {
    suspendAmount: number
    paidMaturity: number
    notPaidPremium: number
    deductOfClaims: number
  }
}
