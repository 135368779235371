import React from 'react'
import { Controller, UseFormReturn, UseFieldArrayReturn, FieldArrayWithId } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { formatNumberWithComma } from '../../shared'
import { LAPanel } from './LAPanel'
import { RiderAlterationFormData } from '../../service/model'
import { InputTable } from '../table'
import { SelectSearch } from '../select-search'
import { assets } from '../../assets'
import { RiderAlterationService } from '../../service'
import { RiderDeleteModal } from './RiderDeleteModal'

export type RiderProps = {
  riderAlterationForm: UseFormReturn<RiderAlterationFormData.RiderAlteration>
  LAFieldList: UseFieldArrayReturn<RiderAlterationFormData.RiderAlteration, 'LARiderAlterationList', 'id'>
  isConfirmed: boolean
  isVerificationScreen?: boolean
  // openAddRiderModal: (index: number) => void
}

export const isClassNumberProduct = (isClassNumber: boolean, classNumberList: string[] | undefined) => {
  const isValidClassList =
    !!classNumberList && classNumberList.length > 0 ? classNumberList.some((item) => Number(item) > 2) : false
  return !!isClassNumber && isValidClassList
}

export const LifeAssuredTable = ({
  riderAlterationForm,
  LAFieldList,
  isConfirmed,
  isVerificationScreen
}: RiderProps) => {
  const { t } = useTranslation()
  const convertStringToDateString = (strDate: string) => {
    return moment(strDate).format('DD/MM/YYYY')
  }

  const convertNumberToCurrencyFormat = (value: number) => {
    return formatNumberWithComma(value)
  }

  const isInvalidAlteredSumAssured = (
    val: string,
    sumAssured: number,
    isWaiverProduct: boolean,
    isClassNumber: boolean
  ) => {
    return !!val && (Number(val) === 0 || Number(val) < sumAssured) && !isWaiverProduct && !isClassNumber
  }

  // const isClassNumberProduct = (isClassNumber: boolean, classNumberList: string[] | undefined) => {
  //   const isValidClassList =
  //     !!classNumberList && classNumberList.length > 0 ? classNumberList.some((item) => Number(item) > 2) : false
  //   return isClassNumber && isValidClassList
  // }

  return (
    <>
      {LAFieldList.fields.map((fieldItem, index) => (
        <>
          <LAPanel
            key={index}
            containerStyle={riderStyles.panelContent}
            title={fieldItem.LAName}
            isNoPadding={true}
            isTransactionToggleExpand={true}
          >
            <View style={riderStyles.currencyContent}>
              <Text style={riderStyles.currentText}>{t('requestInfo:Currency')}: VND</Text>
            </View>
            <View
              style={{
                flex: 1,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#D3DCE6',
                borderRadius: 8,
                marginTop: 10
              }}
            >
              {/* table header */}
              <View>
                {/* table row */}
                <View
                  style={{
                    flexDirection: 'row',
                    paddingLeft: 30,
                    backgroundColor: '#F4F4F4',
                    paddingVertical: 15,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                  }}
                >
                  <View style={{ flex: 1, padding: 10 }}>
                    <Text style={riderStyles.tableHeaderText}>{t('requestInfo:RiderName')}</Text>
                  </View>
                  <View style={{ flex: 1, padding: 10 }}>
                    <Text style={riderStyles.tableHeaderText}>{t('CancelRider:RiskCommencementDate')}</Text>
                  </View>
                  <View style={{ flex: 1, padding: 10 }}>
                    <Text style={riderStyles.tableHeaderText}>{t('requestInfo:RiskCessationDate')}</Text>
                  </View>
                  <View style={{ flex: 1, padding: 10 }}>
                    <Text style={riderStyles.tableHeaderText}>{t('requestInfo:SumAssured')}</Text>
                  </View>
                  <View style={{ flex: 1, padding: 10 }}>
                    <Text style={riderStyles.tableHeaderText}>{t('RiderAlteration:AlteredSumAssured')}</Text>
                  </View>
                  <View style={{ flex: 1, padding: 10 }}>
                    <Text style={riderStyles.tableHeaderText}>{t('CancelRider:InstallmentPremium')}</Text>
                  </View>
                </View>
              </View>
              {/* table body */}
              <View>
                {fieldItem.currentRiders.map((riderInfo, subIndex) => {
                  const isHightLight = isConfirmed && !!isVerificationScreen
                  return (
                    <View
                      key={subIndex}
                      style={{
                        flexDirection: 'row',
                        borderTopWidth: 1,
                        borderTopColor: 'rgb(238, 238, 238)',
                        paddingLeft: 30
                      }}
                    >
                      <View style={{ flex: 1, padding: 10, paddingTop: 19 }}>
                        <Text>{riderInfo.riderName}</Text>
                      </View>
                      <View style={{ flex: 1, padding: 10, paddingTop: 19 }}>
                        <Text>{convertStringToDateString(riderInfo.riskCommDate)}</Text>
                      </View>
                      <View style={{ flex: 1, padding: 10, paddingTop: 19 }}>
                        <Text>{convertStringToDateString(riderInfo.riskCessDate)}</Text>
                      </View>
                      <View style={{ flex: 1, padding: 10, paddingTop: 19 }}>
                        <Text>{convertNumberToCurrencyFormat(riderInfo.sumAssured)}</Text>
                      </View>
                      <View style={{ flex: 1, padding: 10 }}>
                        {isClassNumberProduct(
                          riderAlterationForm.watch(
                            `LARiderAlterationList.${index}.currentRiders.${subIndex}.isClassNumber`
                          ),
                          riderAlterationForm.watch(
                            `LARiderAlterationList.${index}.currentRiders.${subIndex}.classNumberList`
                          )
                        ) ? (
                          <Controller
                            control={riderAlterationForm.control}
                            name={`LARiderAlterationList.${index}.currentRiders.${subIndex}.alteredSumAssured`}
                            rules={{
                              // required: {
                              //   value: !riderInfo.isWaiverProduct,
                              //   message: `${t('message:MS020034')}`
                              // }
                              validate: (val) => {
                                return isInvalidAlteredSumAssured(
                                  val,
                                  riderInfo.sumAssured,
                                  riderInfo.isWaiverProduct,
                                  riderInfo.isClassNumber
                                )
                                  ? `${t('message:MS020034')}`
                                  : true
                              }
                            }}
                            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                              <SelectSearch
                                options={riderAlterationForm
                                  .watch(`LARiderAlterationList.${index}.currentRiders.${subIndex}.classNumberList`)
                                  .map((item) => ({
                                    label: item,
                                    value: item
                                  }))
                                  .filter((ixt) => Number(ixt.value) > 2 && Number(ixt.value) !== riderInfo.sumAssured)}
                                value={{
                                  label: value,
                                  value: value
                                }}
                                onChange={(val) => {
                                  onChange(val?.value)
                                }}
                                onBlur={onBlur}
                                disabled={isConfirmed}
                                placeholder={t('common:Select')}
                                isTextFieldHightLight={isHightLight}
                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                errorMessage={
                                  !value ||
                                  isInvalidAlteredSumAssured(
                                    value,
                                    riderInfo.sumAssured,
                                    riderInfo.isWaiverProduct,
                                    riderInfo.isClassNumber
                                  )
                                    ? error?.message
                                    : ''
                                }
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            control={riderAlterationForm.control}
                            name={`LARiderAlterationList.${index}.currentRiders.${subIndex}.alteredSumAssured`}
                            rules={{
                              // required: {
                              //   value: !riderInfo.isWaiverProduct,
                              //   message: `${t('message:MS020034')}`
                              // },
                              validate: (val) => {
                                return parseInt(val) < 100000000 || parseInt(val) === riderInfo.sumAssured
                                  ? `${t('message:MS020034')}`
                                  : true
                              }
                            }}
                            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                              return (
                                <InputTable
                                  value={String(value)}
                                  onChange={(val) => {
                                    onChange(val)
                                    riderAlterationForm.trigger(
                                      `LARiderAlterationList.${index}.currentRiders.${subIndex}.alteredSumAssured`
                                    )
                                  }}
                                  onBlur={onBlur}
                                  inputType={'money'}
                                  inputStyle={isHightLight && Number(value ?? 0) > 0 && riderStyles.hightLightText}
                                  disabled={isConfirmed || riderInfo.isWaiverProduct}
                                  errorMessage={
                                    !value ||
                                    Number(value) === riderInfo.sumAssured ||
                                    isInvalidAlteredSumAssured(
                                      value,
                                      100000000,
                                      riderInfo.isWaiverProduct,
                                      riderInfo.isClassNumber
                                    ) ||
                                    isInvalidAlteredSumAssured(
                                      value,
                                      riderInfo.sumAssured,
                                      riderInfo.isWaiverProduct,
                                      riderInfo.isClassNumber
                                    )
                                      ? error?.message
                                      : ''
                                  }
                                />
                              )
                            }}
                          />
                        )}
                      </View>
                      <View style={{ flex: 1, padding: 10, paddingTop: 19 }}>
                        <Text>{convertNumberToCurrencyFormat(riderInfo.installmentPrem)}</Text>
                      </View>
                    </View>
                  )
                })}
              </View>
            </View>
          </LAPanel>
        </>
      ))}
    </>
  )
}

type RiderTableProps = {
  // newRiderList: SelectOption[]
  // waiverProductList: string []
  riderAlterationForm: UseFormReturn<RiderAlterationFormData.RiderAlteration>
  NewRiderLAList: UseFieldArrayReturn<RiderAlterationFormData.RiderAlteration, 'newRiderLAList', 'id'>
  disabled: boolean
  addNewRider?: (
    index: number,
    fieldItem: FieldArrayWithId<RiderAlterationFormData.RiderAlteration, 'newRiderLAList', 'id'>
  ) => void
  removeRider?: (
    index: number,
    subIndex: number,
    fieldItem: FieldArrayWithId<RiderAlterationFormData.RiderAlteration, 'newRiderLAList', 'id'>
  ) => void
  onChangeRider?: (index: number, subIndex: number, riderCode: string) => void
  removeNewLA?: (index: number) => void
  viewLADetail?: (index: number) => void
  isVerificationScreen?: boolean
}
export const AlterRiderTable = ({
  // newRiderList,
  // waiverProductList,
  riderAlterationForm,
  disabled,
  NewRiderLAList,
  addNewRider,
  removeRider,
  removeNewLA,
  viewLADetail,
  onChangeRider,
  isVerificationScreen
}: RiderTableProps) => {
  const { t, i18n } = useTranslation()
  const [isDelete, setIsDelete] = React.useState<{ status: boolean; index: number }>({ status: false, index: -1 })
  const isInvalidAlteredSumAssured = (val: string, sumAssured: number, isWaiverProduct: boolean) => {
    return !!val && (Number(val) === 0 || Number(val) < sumAssured) && !isWaiverProduct
  }

  const isInvalidNewSumAssured = (val: string, isWaiverProduct: boolean) => {
    return !val && Number(val) === 0 && !isWaiverProduct
  }

  const getAlterRiderOptions = (riderArr: RiderAlterationService.RiderNameInfo[] | undefined) => {
    const riderList = !!riderArr
      ? riderArr.map((item) => ({
          label: item.code + ' - ' + (i18n.language === 'en' ? item.name : item.nameVi),
          value: item.code
        }))
      : []
    return riderList
  }

  const getClassNumberOptions = (classNumberArr: string[]) => {
    return classNumberArr
      .map((item) => ({
        label: item,
        value: item
      }))
      .filter((ixt) => Number(ixt.value) > 2)
  }

  return (
    <View>
      {NewRiderLAList.fields.map((fieldItem, index) => (
        <LAPanel
          key={index}
          containerStyle={riderStyles.panelContent}
          title={fieldItem.LAName}
          isNoPadding={true}
          isTransactionToggleExpand={true}
          isNewLA={fieldItem.isNewLA}
          removeItem={removeNewLA}
          viewDetailEvent={viewLADetail}
          LAIndex={index}
          isViewDetail={disabled}
          // isDelete={isDelete}
          setIsDelete={(status: boolean, index: number) => setIsDelete({ status: status, index: index })}
        >
          <View style={riderStyles.currencyContent}>
            <Text style={riderStyles.currentText}>
              {t('requestInfo:Currency')}: {t('RequestInfo:Years')}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#D3DCE6',
              borderRadius: 8,
              marginTop: 10
            }}
          >
            {/* table header */}
            <View>
              {/* table row */}
              <View
                style={{
                  flexDirection: 'row',
                  paddingLeft: 30,
                  backgroundColor: '#F4F4F4',
                  paddingVertical: 15,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8
                }}
              >
                <View style={{ flex: 30, padding: 10 }}>
                  <Text style={riderStyles.tableHeaderText}>{t('requestInfo:RiderName')}</Text>
                </View>
                <View style={{ flex: 30, padding: 10 }}>
                  <Text style={riderStyles.tableHeaderText}>{t('requestInfo:SumAssured')}</Text>
                </View>
                <View style={{ flex: 30, padding: 10 }}>
                  <Text style={riderStyles.tableHeaderText}>{t('RiderAlteration:PolicyPeriod')}</Text>
                </View>
                <View style={{ flex: 10, padding: 10 }}></View>
              </View>
            </View>
            {/* table body */}
            <View>
              {fieldItem.newRiderList.map((riderInfo, subIndex) => {
                const isHightLight = disabled && !!isVerificationScreen
                return (
                  <View
                    key={subIndex}
                    style={{
                      flexDirection: 'row',
                      borderTopWidth: 1,
                      borderTopColor: 'rgb(238, 238, 238)',
                      paddingLeft: 30
                    }}
                  >
                    <View style={{ flex: 30, padding: 10 }}>
                      <Controller
                        control={riderAlterationForm.control}
                        name={`newRiderLAList.${index}.newRiderList.${subIndex}.riderName`}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <SelectSearch
                            options={getAlterRiderOptions(
                              riderAlterationForm.watch(`newRiderLAList.${index}.alteredRiderList`)
                            )}
                            value={value}
                            onChange={(val) => {
                              onChange(val)
                              onChangeRider && onChangeRider(index, subIndex, val?.value ?? '')
                            }}
                            onBlur={onBlur}
                            disabled={disabled}
                            isTextFieldHightLight={isHightLight}
                            placeholder={t('common:Select')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            errorMessage={error?.message}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 30, padding: 10 }}>
                      {isClassNumberProduct(
                        riderAlterationForm.watch(`newRiderLAList.${index}.newRiderList.${subIndex}.isClassNumber`),
                        riderAlterationForm.watch(`newRiderLAList.${index}.newRiderList.${subIndex}.classNumberList`)
                      ) ? (
                        <Controller
                          control={riderAlterationForm.control}
                          name={`newRiderLAList.${index}.newRiderList.${subIndex}.sumAssured`}
                          rules={{
                            // required: {
                            //   value: !riderInfo.isWaiverProduct,
                            //   message: `${t('message:MS020034')}`
                            // }
                            validate: (val) => {
                              return isInvalidNewSumAssured(
                                val,
                                riderAlterationForm.watch(
                                  `newRiderLAList.${index}.newRiderList.${subIndex}.isWaiverProduct`
                                )
                              )
                                ? `${t('message:MS020034')}`
                                : true
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                            <SelectSearch
                              options={getClassNumberOptions(
                                riderAlterationForm.watch(
                                  `newRiderLAList.${index}.newRiderList.${subIndex}.classNumberList`
                                )
                              )}
                              value={{
                                label: value,
                                value: value
                              }}
                              onChange={(val) => {
                                onChange(val?.value)
                              }}
                              onBlur={onBlur}
                              disabled={disabled}
                              placeholder={t('common:Select')}
                              isTextFieldHightLight={isHightLight}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                              errorMessage={
                                !value ||
                                isInvalidNewSumAssured(
                                  value,
                                  riderAlterationForm.watch(
                                    `newRiderLAList.${index}.newRiderList.${subIndex}.isWaiverProduct`
                                  )
                                )
                                  ? error?.message
                                  : ''
                              }
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          control={riderAlterationForm.control}
                          name={`newRiderLAList.${index}.newRiderList.${subIndex}.sumAssured`}
                          rules={{
                            required: {
                              value: !riderAlterationForm.watch(
                                `newRiderLAList.${index}.newRiderList.${subIndex}.isWaiverProduct`
                              ),
                              message: `${t('message:MS020001', { field: t('requestInfo:SumAssured') })}`
                            },
                            validate: (val) => {
                              return isInvalidAlteredSumAssured(val, 100000000, riderInfo.isWaiverProduct)
                                ? `${t('message:MS020034')}`
                                : true
                            }
                          }}
                          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                            // const isHightLight = disabled && !!isVerificationScreen && Number(value ?? 0) > 0
                            return (
                              <InputTable
                                value={String(value)}
                                onChange={(val) => {
                                  onChange(val)
                                  riderAlterationForm.trigger(
                                    `newRiderLAList.${index}.newRiderList.${subIndex}.sumAssured`
                                  )
                                }}
                                onBlur={onBlur}
                                inputType={'money'}
                                inputStyle={isHightLight && Number(value ?? 0) > 0 && riderStyles.hightLightText}
                                disabled={
                                  disabled ||
                                  riderAlterationForm.watch(
                                    `newRiderLAList.${index}.newRiderList.${subIndex}.isWaiverProduct`
                                  )
                                }
                                errorMessage={
                                  !value || isInvalidAlteredSumAssured(value, 100000000, riderInfo.isWaiverProduct)
                                    ? error?.message
                                    : ''
                                }
                              />
                            )
                          }}
                        />
                      )}
                    </View>
                    <View style={{ flex: 30, padding: 10 }}>
                      <Controller
                        control={riderAlterationForm.control}
                        name={`newRiderLAList.${index}.newRiderList.${subIndex}.policyPeriod`}
                        rules={{
                          required: {
                            value: true,
                            message: `${t('message:MS020001', { field: t('RiderAlteration:PolicyPeriod') })}`
                          }
                        }}
                        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                          <InputTable
                            value={String(value)}
                            onChange={onChange}
                            onBlur={onBlur}
                            inputType={'money'}
                            inputStyle={isHightLight && riderStyles.hightLightText}
                            disabled={disabled}
                            errorMessage={!!value ? '' : error?.message}
                          />
                        )}
                      />
                    </View>
                    <View style={{ flex: 10, padding: 10 }}>
                      {!disabled && (
                        <TouchableOpacity onPress={() => removeRider && removeRider(index, subIndex, fieldItem)}>
                          <assets.DeleteIcon />
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                )
              })}
            </View>
          </View>
          {!disabled && (
            <View style={riderStyles.btnContent}>
              <TouchableOpacity onPress={() => addNewRider && addNewRider(index, fieldItem)}>
                <View style={riderStyles.btnAdd}>
                  <Text style={riderStyles.btn_text}>{t('claim:AddNew')}</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
          <RiderDeleteModal
            visible={isDelete.status}
            onPressClose={() => setIsDelete((val) => ({ index: val.index, status: false }))}
            onPressSubmit={() => {
              !!removeNewLA && removeNewLA(isDelete.index)
              setIsDelete((val) => ({ index: val.index, status: false }))
            }}
          />
        </LAPanel>
      ))}
    </View>
  )
}

const riderStyles = StyleSheet.create({
  container: {
    marginBottom: 40,
    flex: 1
  },
  titleText: {
    marginTop: 30
  },
  currencyContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  currentText: {
    fontStyle: 'italic'
  },
  tableHeaderText: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 13,
    color: '#70777E'
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  },
  btnContent: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  btnNewLA: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  btnAdd: {
    minHeight: 40,
    width: '100%',
    borderRadius: 100,
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderColor: '#ED1B2E',
    borderWidth: 1
    // backgroundColor: '#ED1B2E'
  },
  btn_text: {
    fontSize: 15,
    lineHeight: 20,
    color: '#ED1B2E',
    fontWeight: 'bold'
  },
  secondBtn: {
    marginLeft: 27
  },
  underlineLink: {
    textDecorationLine: 'underline'
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  panelContent: {
    backgroundColor: '#fff',
    marginTop: 20,
    borderWidth: 0,
    borderColor: '#fff'
  },
  secondLine: {
    marginTop: 20
  },
  redLine: {
    color: '#ed1b2c',
    fontSize: 15,
    fontWeight: 'bold'
  },
  hightLightText: {
    color: '#ED1B2E'
  }
})
