import { Moment } from 'moment'

export namespace PulseUtils {
  export const getYesterday = (): Date => {
    const today = new Date()
    return new Date(new Date(today.setDate(today.getDate() - 1)))
  }

  export const isIOS = (): boolean => {
    const nav = navigator.userAgent.toLowerCase()
    return !!nav.match(/(ipad|iphone|ipod)/i)
  }

  export const isAndroid = (): boolean => {
    const nav = navigator.userAgent.toLowerCase()
    return !!nav.match(/(android)/i)
  }

  export const getAbsoluteMonths = (momentDate: Moment) => {
    const months = Number(momentDate.format('MM'))
    const years = Number(momentDate.format('YYYY'))
    return months + years * 12
  }

  export const getDiffMonths = (start: Moment, end: Moment) => {
    return getAbsoluteMonths(end) - getAbsoluteMonths(start)
  }
}
