import React, { PropsWithChildren } from 'react'
import { StyleSheet, StyleProp, Text, View, ViewStyle, TextStyle } from 'react-native'

type OBSectionContentProps = {
  style?: StyleProp<ViewStyle>
}

type OBSectionFieldTitleProps = OBSectionContentProps & {
  textStyle?: StyleProp<TextStyle>
  text?: string
}


export const OBSectionContent = (props: PropsWithChildren<OBSectionContentProps>) => {
  return (
    <View style={[sectionStyles.container, props.style]}>
      {props.children}
    </View>
  )
}

export const OBSectionRow = (props: PropsWithChildren<OBSectionContentProps>) => {
  return (
    <View style={[sectionStyles.sectionRow, props.style]}>
      {props.children}
    </View>
  )
}

export const OBSectionCol = (props: PropsWithChildren<OBSectionContentProps>) => {
  return (
    <View style={[sectionStyles.sectionCol4, props.style]}>
      {props.children}
    </View>
  )
}

export const OBFieldTitle = (props: OBSectionFieldTitleProps) => {
  return (
    <View style={[sectionStyles.sectionTextContent, props.style]}>
      <Text style={[sectionStyles.sectionText, props.textStyle]}>{props.text}</Text>
    </View>
  )
}

export const OBFieldDescription = (props: OBSectionFieldTitleProps) => {
  return (
    <View style={[props.style]}>
      <Text style={[sectionStyles.sectionFieldDescription, props.textStyle]}>{props.text}</Text>
    </View>
  )
}

const sectionStyles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 8,
    borderColor: '#D3DCE6',
    borderWidth: 1,
    backgroundColor: '#fff'
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -15
  },
  sectionCol4: {
    width: '100%',
    maxWidth: '33.333333%',
    paddingHorizontal: 15
  },
  sectionTextContent: {
    marginTop: 5,
    marginBottom: 4
  },
  sectionText: {
    color: '#70777E',
    // font-family: Noto Sans;
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '700'
  },
  sectionFieldDescription: {
    color: '#000',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '400',
    marginTop: 4,
    marginBottom: 5
    // lineHeight: 22.5
  }
})