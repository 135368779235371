export namespace BankTransfer {
  export interface Bank {
    code: string
    name: string
  }
  export interface Branch {
    code: string
    name: string
  }
}
