import { StackScreenProps } from '@react-navigation/stack'
import { ReportsStackParamList } from '../ReportsStackParamList'
import { View, StyleSheet, ScrollView, SafeAreaView, TouchableOpacity, Text } from 'react-native'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AppContext, DatePicker, ErrorHandling, Input, Permission, PulseOpsFormat, RBAC, Select, TransactionLabel, TransactionType, TransactionTypeSelectOptions } from '@pulseops/common'
import { CommunicationTrackingForm } from './CommunicationTrackingForm'
import { CommunicationTrackingReportService, CommunicationTrackingDataType } from '../Service/CommunicationTrackingService'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core'
import moment from 'moment'
import { ReportService } from '../Service/ReportExcelService'
type props = StackScreenProps<ReportsStackParamList, 'CommunicationTrackingReportScreen'>

export const CommunicationTrackingReportScreen = ({ navigation }: props) => {
  const { t } = useTranslation()
  const currentDate = new Date()
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading } = useContext(AppContext.AppContextInstance)
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [dataReport, setDataReport] = React.useState<CommunicationTrackingDataType[]>([])
  const [submittedData, setSubmittedData] = React.useState<CommunicationTrackingReportService.PayloadReport>({})
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const [loading, bindLoader] = useLoading(false)
  const statusOption = [
    {
      label: t('Reports:EmptyData'),
      value: "EMPTY"
    },
    {
      label: t('Reports:DataExists'),
      value: "EXISTS"
    },
    {
      label: t('Reports:FailBO'),
      value: "FAIL_BO"
    },
    {
      label: t('Reports:NoLetter'),
      value: "NO_LETTER"
    },
    {
      label: t('Reports:All'),
      value: 'All'
    }
  ]

  const defaultValue = {
    fromDate: currentDate,
    toDate: currentDate,
    policyNumber: '',
    status: statusOption[2],
    transactionType: null,
    userComplete: ''
  }
  
  const columnCommunicationTracking = [
    {
      id: 'businessKey',
      name: `Reports:CommunicationTrackingTable.CaseId`,
      widthPx: 45
    },
    {
      id: 'policyNumber',
      name: `Reports:CommunicationTrackingTable.PolicyNumber`,
      widthPx: 15
    },
    {
      id: 'transactionType',
      name: `Reports:CommunicationTrackingTable.TransactionType`,
      widthPx: 30
    },
    {
      id: 'temporaryFile',
      name: `Reports:CommunicationTrackingTable.TempFile`,
      widthPx: 30
    },
    {
      id: 'status',
      name: `Reports:CommunicationTrackingTable.Status`,
      widthPx: 15
    },
    {
      id: 'createdDate',
      name: `Reports:CommunicationTrackingTable.CompletedDate`,
      widthPx: 15
    },
    {
      id: 'userComplete',
      name: `Reports:CommunicationTrackingTable.UserComplete`,
      widthPx: 45
    }
  ]

  const comTrackForm = useForm<CommunicationTrackingForm.CommunicationTrackingSearch>({
    defaultValues: defaultValue
  })

  const titleHeaders = [
    t('Reports:CommunicationTrackingTable.CaseId'),
    t('Reports:CommunicationTrackingTable.PolicyNumber'),
    t('Reports:CommunicationTrackingTable.TransactionType'),
    t('Reports:CommunicationTrackingTable.TempFile'),
    t('Reports:CommunicationTrackingTable.Status'),
    t('Reports:CommunicationTrackingTable.CompletedDate'),
    t('Reports:CommunicationTrackingTable.UserComplete')
  ]

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:CommunicationTracking'),
          navigate: null
        }
      ])
      comTrackForm.reset(defaultValue)
      setDataReport([])
      setTotalItem(0)
      setPageSize(10)
      setPageNum(0)
      setMessageResponse(null)
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const search = comTrackForm.handleSubmit((data) => {
    setPageNum(0)
    setMessageResponse(null)
    const getSubmitData = (): CommunicationTrackingReportService.PayloadReport => {
      return {
        fromDate: data.fromDate ? PulseOpsFormat.datetoFormat(data.fromDate, 'DD/MM/YYYY') : undefined,
        toDate: data.toDate ? PulseOpsFormat.datetoFormat(data.toDate, 'DD/MM/YYYY') : undefined,
        transactionType: data.transactionType ? data.transactionType?.value : '',
        policyNumber: data.policyNumber,
        status: data.status && data.status?.value !== 'All' ? data.status?.value : undefined,
        userComplete: data.userComplete
      }
    }

    const submitData: CommunicationTrackingReportService.PayloadReport = getSubmitData()
    setSubmittedData(submitData)
    getDataReport(submitData, pageSize, pageNum)
  })

  const searchToExport = comTrackForm.handleSubmit((data) => {
    const getSubmitData = (): CommunicationTrackingReportService.PayloadReport => {
      return {
        fromDate: data.fromDate ? PulseOpsFormat.datetoFormat(data.fromDate, 'DD/MM/YYYY') : undefined,
        toDate: data.toDate ? PulseOpsFormat.datetoFormat(data.toDate, 'DD/MM/YYYY') : undefined,
        transactionType: data.transactionType ? data.transactionType?.value : '',
        policyNumber: data.policyNumber,
        status: data.status && data.status?.value !== 'All' ? data.status?.value : undefined,
        userComplete: data.userComplete
      }
    }

    const submitData: CommunicationTrackingReportService.PayloadReport = getSubmitData()
    setSubmittedData(submitData)
    exportFile(submitData)
  })

  const clearFieldSearch = () => {
    comTrackForm.reset({
      fromDate: null,
      toDate: null,
      policyNumber: '',
      status: null,
      transactionType: null,
      userComplete: ''
    })
  }

  const getDataReport = (
    submitData: CommunicationTrackingReportService.PayloadReport,
    size: number,
    start: number
  ) => {
    pipe(
      CommunicationTrackingReportService.viewReportCommunicationTracking(submitData, size, start),
      ZIO.mapError((e) => {
        setTotalItem(0)
        setDataReport([])
        setMessageResponse(t('message:MS030029'))
        return ZIO.fail(e)
      }),
      ZIO.tap((res) => {
        setTotalItem(res.total)
        setDataReport(res.data)
        setMessageResponse(res.data.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const exportFile = (
    submitData: CommunicationTrackingReportService.PayloadReport
  ) => {
    pipe(
      CommunicationTrackingReportService.viewReportCommunicationTracking(submitData, 1000000000, 0),
      ZIO.mapError((e) => {
        return ZIO.fail(e)
      }),
      ZIO.flatMap((res) => {
       const today = PulseOpsFormat.datetoFormat(currentDate, 'DD/MM/YYYY')
       const fileName =  'Communication_tracking_report_' + today
       const fromDate = comTrackForm.getValues('fromDate') ?? undefined
       const toDate = comTrackForm.getValues('toDate') ?? undefined
       const title=  'Communication Tracking report'
       const columns = columnCommunicationTracking.map(({ id, name, widthPx }) => ({
        id,
        name: t(name),
        width: widthPx,
        horizontal: 'left'
       }))
       const array = res.data && 
        res.data?.map((res, index) => ({ ...res, id: `${index + 1}` }))
        .map(({
          businessKey,
          policyNumber,
          transactionType,
          temporaryFile,
          status,
          createdDate,
          userComplete
        }) => [
          businessKey ?? '-',
          policyNumber ?? '-',
          transactionType ? t(TransactionLabel(transactionType as TransactionType)) : '-',
          temporaryFile ?? '-',
          status ? getStatusLabel(status) : '-',
          createdDate ?? '-',
          userComplete ?? '-'
        ])
        return ReportService.exportLetterReport(array, columns, fileName, 'Communication_tracking_report', fromDate, toDate, title)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getStatusLabel = (value: string) => {
    return statusOption.find((status) => status.value === value)?.label ?? '-'
  }
  
  const renderTableHeader = () => {
    return titleHeaders.map((title, index) => {
      return (
        <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }} key={index}>
          {title}
        </TableCell>
      )
    })
  }

  const renderTableBody = () => {
    return dataReport?.map((row, index) => {
      return (
        <TableRow key={`${row.policyNumber}-${index}`}>
          <TableCell align="left">{row.businessKey ?? '-'}</TableCell>
          <TableCell align="left">{row.policyNumber ?? '-'}</TableCell>
          <TableCell align="left">{row.transactionType ? t(TransactionLabel(row.transactionType as TransactionType)) : '-'}</TableCell>
          <TableCell align="left">{row.temporaryFile ?? '-'}</TableCell>
          <TableCell align="left">{row.status ? getStatusLabel(row.status) : '-'}</TableCell>
          <TableCell align="left">{row.createdDate ?? '-'}</TableCell>
          <TableCell align="left">{row.userComplete ?? '-'}</TableCell>
        </TableRow>
      )
    })
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={styles.searchContainer}>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="fromDate"
                control={comTrackForm.control}
                rules={{
                  validate: (dateFrom) => {
                    const dateTo = comTrackForm.watch('toDate')
                    if (dateFrom && dateTo && moment(dateTo).isBefore(dateFrom)) return `${t('message:MS030044')}`
                    return true
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:FromDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxDate={currentDate}
                    maxDateMessage={t('message:MS990032')}
                    alwaysShow={true}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="toDate"
                control={comTrackForm.control}
                rules={{
                  validate: (dateTo) => {
                    const dateFrom = comTrackForm.watch('fromDate')
                    if (dateTo && dateFrom && moment(dateTo).isBefore(dateFrom)) return `${t('message:MS030044')}`
                    return true
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:ToDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxDate={currentDate}
                    alwaysShow={true}
                    maxDateMessage={t('message:MS990032')}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="status"
                control={comTrackForm.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    label={t('Reports:Status')}
                    options={statusOption}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <Controller
                name="policyNumber"
                control={comTrackForm.control}
                rules={{
                  validate: (value) => {
                    if (value.length > 0 && value.length < 8) return `${t('message:MS990065')}`
                    return true
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    title={t('ServiceInquiry:PolicyNumber')}
                    value={value ?? undefined}
                    maxLength={8}
                    inputType="number"
                    onChange={onChange}
                    errorMessage={error ? error.message : ''}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                name="transactionType"
                control={comTrackForm.control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    label={t('ServiceInquiry:TransactionType')}
                    options={TransactionTypeSelectOptions.filter(
                      (x) => x.category === 'PS'
                    ).map((item) => ({
                      label: t(item.label),
                      value: item.value
                    }))}
                    placeholder={t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </View>
            <View style={styles.col}>
              <Controller
                  name="userComplete"
                  control={comTrackForm.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('Reports:UserComplete')}
                      value={value ?? undefined}
                      inputType="text"
                      onChange={onChange}
                    />
                  )}
                />
            </View>
          </View>
          <View style={[styles.rowButton, { alignItems: 'center' }]}>
            {permissions.includes(Permission['SearchCommunicationTracking']) && 
              <TouchableOpacity style={[styles.buttonStyle, { marginRight: 10 }]} onPress={() => search()}>
                <View style={styles.btn}>
                  <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
                </View>
              </TouchableOpacity>
            }
            {permissions.includes(Permission['ExportCommunicationTracking']) && 
              <TouchableOpacity style={[styles.buttonStyle, { marginRight: 10 }]} onPress={() => searchToExport()}>
                <View style={styles.btn}>
                  <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Export')}</Text>
                </View>
              </TouchableOpacity>
            }
            {permissions.includes(Permission['SearchCommunicationTracking']) && 
              <TouchableOpacity style={styles.buttonStyle} onPress={() => clearFieldSearch()}>
                <View style={styles.btnCancel}>
                  <Text style={{ textAlign: 'center', color: '#ff0000' }}>{t('common:Cancel')}</Text>
                </View>
              </TouchableOpacity>
            }
          </View>
        </View>
        {dataReport.length === 0 ?
          <View style={[styles.row, { marginLeft: 10, marginTop: 10 }]}>
            <Text style={{ color: '#ED1B2E' }}>{messageResponse ?? ''}</Text>
          </View>
        :
          <View style={styles.viewTable}>
            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#e2e7ea' }}>{renderTableHeader()}</TableRow>
                </TableHead>

                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                getDataReport(submittedData, pageSize, page)
              }}
              onRowsPerPageChange={(e) => {
                setPageSize(Number(e.target.value))
                setPageNum(0)
                getDataReport(submittedData, Number(e.target.value), 0)
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          </View>
        }
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  textButtonRed: {
    color: '#ff0000',
    fontWeight: 'bold',
    paddingTop: 5
    // display: "flex",
  },

  buttonStyle: {
    width: 118,
    height: 40,
    marginLeft: 15
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 15,
    marginTop: 30,
    paddingBottom: 10,
    paddingTop: 16,
    flexDirection: 'column'
  },

  viewTable: {
    marginHorizontal: 15,
    marginTop: 30,
    marginBottom: 15
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingVertical: 8,
  },

  rowButton: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
    paddingBottom: 10
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
  },

  btnCancel: {
    width: 118,
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 100,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ED1B2E',
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
} as const)