import * as t from 'io-ts'
import { option } from 'io-ts-types'
import { refCache, RefSessionStorage } from '@pulseops/common'
import * as O from 'fp-ts/Option'
import { Lens } from 'monocle-ts'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

export namespace TaskDetailState {
  export const codec = t.type(
    {
      formValid: t.boolean,
      errorMessage: option(t.string),
      triggerValidate: option(t.boolean),
      hiddenButtonsForIACHistory: t.boolean
    },
    'TaskDetailState'
  )

  export type TaskDetailStateType = t.TypeOf<typeof TaskDetailState.codec>

  const defaultValue: TaskDetailStateType = {
    formValid: true,
    errorMessage: O.none,
    triggerValidate: O.none,
    hiddenButtonsForIACHistory: false
  }

  const ref = RefSessionStorage.make(codec, defaultValue)

  export const lens = {
    formValid: Lens.fromProp<TaskDetailState>()('formValid'),
    errorMessage: Lens.fromProp<TaskDetailState>()('errorMessage'),
    triggerValidate: Lens.fromProp<TaskDetailState>()('triggerValidate'),
    isHiddenButtonsForIACHistory: Lens.fromProp<TaskDetailState>()('hiddenButtonsForIACHistory')
  }

  export const cache = refCache(ref)

  export const formValid = {
    set: (value: boolean) => pipe(value, lens.formValid.set, ref.update),
    get: pipe(lens.formValid.get, ref.select)
  }

  export const errorMessage = {
    set: (value?: string) => pipe(value ? O.some(value) : O.none, lens.errorMessage.set, ref.update),
    getOption: pipe(lens.errorMessage.get, ref.select)
  }

  export const triggerValidateForm = {
    set: (value: string) => pipe(O.some(value), lens.errorMessage.set, ref.update),
    getOption: pipe(lens.triggerValidate.get, ref.select),
    watch: ref.watch(lens.triggerValidate.get)
  }

  export const isHiddenButtonsForIACHistory = {
    set: (value: boolean) => pipe(value, lens.isHiddenButtonsForIACHistory.set, ref.update),
    get: pipe(lens.isHiddenButtonsForIACHistory.get, ref.select),
    watch: ref.watch(lens.isHiddenButtonsForIACHistory.get)
  }

  export const action = {
    setFormState: (formValidValue: boolean, message?: string, isHiddenIACBActionButtons?: boolean) =>
      ZIO.zip(
        formValid.set(formValidValue),
        errorMessage.set(message),
        isHiddenButtonsForIACHistory.set(!!isHiddenIACBActionButtons ? true : false)
      ),
    reset: () => ref.set(defaultValue)
  }
}

export type TaskDetailState = t.TypeOf<typeof TaskDetailState.codec>
