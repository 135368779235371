import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'

export namespace LoginInfoForm {
  export const codec = t.type({
    userName: withMessage(NonEmptyString, () =>
      i18next.t('error_required_field', {
        ns: 'login',
        fieldName: i18next.t('EmailOrUsername', { ns: 'login' })
      })
    ),
    password: withMessage(NonEmptyString, () =>
      i18next.t('error_required_field', {
        ns: 'login',
        fieldName: i18next.t('Password', { ns: 'login' })
      })
    )
  })
}
