import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  Checkbox,
  ErrorHandling,
  GeneralService,
  GoAbroadModel,
  Panel,
  RadioButton,
  SourceType,
  TaskDetail
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, StyleSheet, Text } from 'react-native'
import { InfoViewList, Title } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element | undefined
  suffix?: string
  isHighlight?: boolean
}

enum Duration {
  From3To6Months = 'From3To6Months',
  From6To1Year = 'From6To1Year',
  From1To5Years = 'From1To5Years',
  From5To10Years = 'From5To10Years',
  From10To20Years = 'From10To20Years',
  From20Years = 'From20Years'
}

type AbroadList = {
  clients: Output[]
}
type Location = {
  key: string
  value: string
}[]

export const GoAbroad = ({ detail }: { detail?: TaskDetail.GoAbroad }) => {
  const { t } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const mapDuration = new Map<Duration, string>([
    [Duration.From3To6Months, t('requestInfo:From3To6months')],
    [Duration.From6To1Year, t('requestInfo:From6To1Year')],
    [Duration.From1To5Years, t('requestInfo:From1To5Years')],
    [Duration.From5To10Years, t('requestInfo:From5To10Years')],
    [Duration.From10To20Years, t('requestInfo:From10To20Years')],
    [Duration.From20Years, t('requestInfo:From20Years')]
  ])

  let goAbroadClients: GoAbroadModel.AbroadDetail[] = detail?.goAbroadClients ? detail.goAbroadClients : []
  let premiumPayer: GoAbroadModel.PremiumPayer | null = detail?.premiumPayer ? detail.premiumPayer : null
  const [nationals, setNational] = React.useState<Location>([])
  const [province, setProvince] = React.useState<Location>([])
  const [purpose, setPurpose] = React.useState<Location>([])
  const [districts, setDistrict] = React.useState<Location>([])
  const [wards, setWard] = React.useState<Location>([])
  let durationMap = mapDuration
  let premiumPayerData: Output[] = []

  pipe(
    ZIO.zipPar(GeneralService.getCountries, GeneralService.getProvinces, GeneralService.getGoAbroadPurposes),
    ZIO.tap(([countries, provinces, purposes]) => {
      setNational(countries.map((c) => ({ key: c.code, value: c.name })))
      setProvince(provinces.map((c) => ({ key: c.code, value: c.name })))
      setPurpose(purposes.map((c) => ({ key: c.code, value: c.name })))
      return ZIO.unit
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([detail])
  )

  if (premiumPayer?.addressCity) {
    pipe(
      GeneralService.getDistricts(premiumPayer?.addressCity),
      ZIO.tap((districts) => {
        setDistrict(districts.map((c) => ({ key: c.code, value: c.name })))
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.runDidUpdate([detail])
    )
  }

  if (premiumPayer?.addressDistrict) {
    pipe(
      GeneralService.getWards({ provinceCode: premiumPayer.addressCity, districtCode: premiumPayer?.addressDistrict }),
      ZIO.tap((wards) => {
        setWard(wards.map((c) => ({ key: c.code, value: c.name })))
        return ZIO.unit
      }),
      bindLoader,
      ErrorHandling.runDidUpdate([detail])
    )
  }

  const getPurpose = (code: string) => {
    return purpose.find((n) => n.key === code)?.value || '-'
  }

  const getDate = (date: string) => {
    return moment(date, 'DD/MM/YYYY', true).isValid() ? date : moment(date).format('DD/MM/yyyy')
  }

  const getNational = (code: string) => {
    return nationals.find((n) => n.key === code)?.value || '-'
  }

  const getProvince = (code: string) => {
    return province.find((n) => n.key === code)?.value || '-'
  }

  const getDistrict = (code: string) => {
    return districts.find((n) => n.key === code)?.value || '-'
  }

  const getWard = (code: string) => {
    return wards.find((n) => n.key === code)?.value || '-'
  }

  const getFatca = (code: string | boolean): boolean => {
    if (typeof code === 'string') return code === 'true'
    return code
  }

  const detectFatcaCode = (fatcaCode: string | boolean, isRadioYes: boolean) => {
    return detail?.source === SourceType.PRUONLINE ? false : isRadioYes ? getFatca(fatcaCode) : !getFatca(fatcaCode)
  }

  const getAbroadData = (info: GoAbroadModel.AbroadDetail): Output[] => {
    return [
      { label: `${t('requestInfo:SelectClient')}`, value: info.clientName },
      { label: `${t('requestInfo:DestinationCountry')}`, value: getNational(info.destinationCountry) },
      { label: `${t('requestInfo:Purpose')}`, value: getPurpose(info.purpose) },
      {
        label: `${t('requestInfo:DepartureDate')}`,
        value: getDate(info.departureDate) || '-'
      },
      {
        label: `${t('contact:Duration')}`,
        value: info.duration
          ? durationMap.get(info.duration as Duration) ?? `${info.duration} ${t('frequency:Monthly').toLowerCase()}`
          : '-'
      },
      {
        label: `${t('requestInfo:CurrentlyHaveToFileATaxReturnInTheUS')}`,
        value: (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <RadioButton disable={true} label={t('common:Yes')} selected={detectFatcaCode(info.fatca, true)} />
            <RadioButton disable={true} label={t('common:No')} selected={detectFatcaCode(info.fatca, false)} />
          </View>
        )
      },
      { label: `${t('requestInfo:HouseNumberBuildingNameStreet')}`, value: info.aboardAddress || '-' },
      { label: '', value: '' },
      { label: `${t('contact:Country')}`, value: getNational(info.addressCountry || '-') },
      {
        label: `${t('contact:CityProvince')}`,
        value: info.addressCity.length > 0 || info.addressCity ? info.addressCity : '-'
      },
      { label: `${t('contact:District')}`, value: info.addressDistrict ? info.addressDistrict : '-' },
      { label: `${t('contact:WardSubDistrict')}`, value: info.addressWard || '-' },
      {
        label: `${t('contact:MobilePhone')}`,
        value:
          info.clientPhoneCode && info.clientPhoneNumber ? `${info.clientPhoneCode}  ${info.clientPhoneNumber}` : '-'
      }
    ]
  }

  const getAbroadList = (): AbroadList[] => {
    return goAbroadClients.map((clients: GoAbroadModel.AbroadDetail) => ({
      clients: getAbroadData(clients)
    }))
  }

  premiumPayerData = [
    { label: t('requestInfo:Payer'), value: premiumPayer?.clientName || '-' },
    { label: '', value: '' },
    { label: '', value: '' },
    { label: t('requestInfo:HouseNumberBuildingNameStreet'), value: premiumPayer?.aboardAddress || '-' },
    { label: '', value: '' },
    {
      label: t('contact:Country'),
      value: premiumPayer?.addressCountry
        ? getNational(premiumPayer?.addressCountry === 'VIETNAM' ? 'VN' : premiumPayer?.addressCountry)
        : '-'
    },
    {
      label: t('contact:CityProvince'),
      value: premiumPayer?.addressCity ? getProvince(premiumPayer.addressCity) : '-'
    },
    {
      label: t('contact:District'),
      value: premiumPayer?.addressDistrict ? getDistrict(premiumPayer.addressDistrict) : '-'
    },
    { label: t('contact:WardSubDistrict'), value: premiumPayer?.addressWard ? getWard(premiumPayer.addressWard) : '-' },
    {
      label: t('contact:MobilePhone'),
      value:
        premiumPayer?.clientPhoneCode && premiumPayer?.clientPhoneNumber
          ? `${premiumPayer?.clientPhoneCode}  ${premiumPayer?.clientPhoneNumber}`
          : '-'
    }
  ]

  return (
    <View style={{ flex: 1 }}>
      {!loading && (
        <ScrollView>
          <Title title={t('requestInfo:GoAbroadDetail')} />
          {getAbroadList().map((item, index) => (
            <Panel title=" " key={index}>
              <InfoViewList dataSource={item?.clients} />
            </Panel>
          ))}
          {premiumPayer !== null && (
            <View>
              <View style={[style.row, { alignItems: 'center' }]}>
                <Checkbox disabled title={''} value={true} />
                <Text style={style.sectionInfoHeaderTitle}>{t('requestInfo:PremiumPayerRepresentativeContact')}</Text>
              </View>
              <Panel title=" " isExand={false}>
                <InfoViewList dataSource={premiumPayerData} />
              </Panel>
            </View>
          )}
        </ScrollView>
      )}
    </View>
  )
}
const style = StyleSheet.create({
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
