export type GeneralInfo = GeneralInfo.Main

export namespace GeneralInfo {
  export type Main = {
    // policy
    policyOwner: CodeName
    lifeAssuredList: CodeName[]
    aplDate: Date
    contract: {
      type: string
      status: string
    }
    premiumStatus: string
    sumAssured: number
    installmentPremium: number
    billingFrequency: string
    firstIssueDate: Date
    policyIssueDate: Date
    riskCommencementDate: Date
    paidToDateAdvanceBasic: Date
    paidToDateBasic: Date
    riskCessDate: Date
    paidToDateAdvanceRider: Date
    paidToDateRider: Date

    // benefit
    surrenderValue: number
    prucashBenefit: number
    maturityBenefit: number
    specialBonus: number
    suspenseAmount: number
    prucashDetail: PrucashDetail[]

    // investment
    estimateValue: number
    fundDetail: FundDetail[]

    agent: CodeName
    sharedAgent: CodeName | null
    unitManager: CodeName
    saleUnit: CodeName
    saleBranch: CodeName
    gaCode: CodeName
    region: CodeName
    zone: CodeName
    office: CodeName

    contactInfo: ContactInfo
  }
  export type CodeName = {
    code: string
    name: string
  }
  export type PrucashDetail = {
    startDate: Date
    principal: number
    interest: number
    total: number
    receivedDate: Date
    receivedAmount: number | null
  }
  export type FundDetail = {
    fund: string
    unitBalance: number
    unitPrice: number
    unitValue: number
    pricingDate: Date
  }
  export type ServicingAgent = {
    person: CodeName
    mobilePhone: PhoneNumber
    homePhone: PhoneNumber
  }
  export type PhoneNumber = {
    zone: string
    value: string
  }
  export type ContactInfo = {
    street: string
    country: string
    location: string
    city: string
    district: string
    ward: string
    email: string
    mobilePhone: PhoneNumber
    homePhone: PhoneNumber
  }
}
