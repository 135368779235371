import { Tabs, Tab, makeStyles } from '@material-ui/core'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import _ from 'lodash'
const useStyles = makeStyles({
  root: {
    '& .MuiTabs-fixed': {
      // borderRadius: 16,
      // boxShadow: 'none',
      // backgroundColor: 'blue',
      // shadowColor: '#0F0',
      borderBottom: '1px solid #C4C4C4'
    },
    '& .MuiTab-root': {
      // set style for child component
      lineHeight: 1,
      minHeight: 35,
      minWidth: 65,
      textTransform: 'none',
      // margin: '0px 10px',
      marginRight: 10,
      padding: '6px 1px'
    },
    '&.MuiTabs-root': {
      // set style for root component
      minHeight: 35
    }
  }
})

export interface CategoryTabInfo {
  label: string
  key: string
  count?: number
  permission?: string
}

export type IBCategoryTabProps = {
  dataSource: Array<CategoryTabInfo>
  permission?: string[]
  onChangeTab: (val: number) => void
  activeTabIndex: number
}

export const IBCategoryTab = (props: IBCategoryTabProps) => {
  const { activeTabIndex, dataSource, onChangeTab, permission } = props
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    onChangeTab(value)
  }
  const getTabLabel = (isActive: boolean, label: string, count: number | undefined) => {
    return (
      <View style={tabStyles.labelTab}>
        <Text style={[isActive && tabStyles.activeText, { fontSize: 15 }]}>{label}</Text>
        {_.isNumber(count) ? (
          <View style={[tabStyles.counterContainer, { paddingHorizontal: count > 99 ? 4 : 0 }]}>
            <Text style={tabStyles.count}>{count}</Text>
          </View>
        ) : (
          <></>
        )}
      </View>
    )
  }
  return (
    <Tabs
      value={activeTabIndex}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      aria-label="simple tabs example"
      classes={{
        root: classes.root
      }}
      TabIndicatorProps={{
        style: {
          transition: 'none'
        }
      }}
    >
      {dataSource &&
        dataSource.map((dataItem, index) => {
          if (dataItem.permission && !permission?.includes(dataItem.permission)) {
            return null
          }
          return (
            <Tab
              key={`dataItem_${index}`}
              label={getTabLabel(activeTabIndex === index, dataItem.label, dataItem.count)}
              disableRipple
            />
          )
        })}
    </Tabs>
  )
}
const tabStyles = StyleSheet.create({
  activeText: {
    color: '#ED1B2E'
  },
  counterContainer: {
    minWidth: 24,
    height: 24,
    borderRadius: 24,
    borderColor: '#ED1B2E',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5
  },

  count: {
    color: '#ED1B2E',
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 20,
    width: '100%'
  },
  labelTab: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})
