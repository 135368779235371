export namespace Occupation {
  export interface OccupationOptionDB {
    id: string
    code: string
    name: string
    nameEn: string
  }
  export interface OccupationOption {
    value: string
    label: string
  }

  export const MockOccupationDB: OccupationOptionDB[] = [
    {
      id: 'Occupation:3021',
      code: '3021',
      name: 'Bảo trì tòa nhà - nhân viên lâu chùi cửa kính làm việc ở độ cao từ 6 mét trở lên',
      nameEn: 'building supervisor'
    },
    {
      id: 'Occupation:3026',
      code: '3026',
      name: 'Bảo vệ - có trang bị vũ khí, thám tử tư, vệ sĩ',
      nameEn: 'security guard- armed, inspector, bodyguard'
    },
    {
      id: 'Occupation:3030',
      code: '3030',
      name: 'Bảo vệ - ngân hàng có trang bị vũ khí',
      nameEn: 'security guard- armed for bank '
    },
    {
      id: 'Occupation:3039',
      code: '3039',
      name: 'Bến cảng sông, biển - công nhân cảng',
      nameEn: 'stevedore, docker, dockworker'
    },
    {
      id: 'Occupation:3046',
      code: '3046',
      name: 'Bia, nước giải khát - công nhân vận hành bảo trì nồi lò hơi',
      nameEn: 'boiler operator'
    },
    {
      id: 'Occupation:3049',
      code: '3049',
      name: 'Bốc vác - nhân viên kiểm soát vận chuyển (đứng trên container điều khiển xếp đỡ)',
      nameEn: 'loader operator'
    },
    {
      id: 'Occupation:3077',
      code: '3077',
      name: 'Chế biến cao su -công nhân bảo trì nồi hơi/ lò hơi',
      nameEn: 'boiler maintenance worker'
    },
    {
      id: 'Occupation:3080',
      code: '3080',
      name: 'Chủ trại và nhân viên nuôi cá sấu',
      nameEn: 'crocodile farm owner, crocodile caretaker'
    },
    {
      id: 'Occupation:3083',
      code: '3083',
      name: 'Cơ khí - công nhân, thợ hàn, cắt tại công trường từ 6m trở lên',
      nameEn: 'welder, cutter working at construction site (height >=6 meters)'
    },
    {
      id: 'Occupation:3086',
      code: '3086',
      name: 'Công nghiệp đá - công nhân khai thác mỏ đá',
      nameEn: 'stone miner'
    },
    {
      id: 'Occupation:3098',
      code: '3098',
      name: 'Cứu hỏa - lính cứu hỏa',
      nameEn: 'firefighter'
    },
    {
      id: 'Occupation:3110',
      code: '3110',
      name: 'Điện - công nhân kéo cáp, nối cáp, kiểm tra đường dây cáp',
      nameEn: 'cable installer'
    },
    {
      id: 'Occupation:3111',
      code: '3111',
      name: 'Điện - công nhân lắp dựng trụ tháp',
      nameEn: 'pillar installer'
    },
    {
      id: 'Occupation:3125',
      code: '3125',
      name: 'Điện ảnh - diễn viên đòng thế vai nguy hiểm',
      nameEn: 'stuntman, stuntwoman'
    },
    {
      id: 'Occupation:3126',
      code: '3126',
      name: 'Điện ảnh - diễn viên xiếc với thú',
      nameEn: 'animal trainer'
    },
    {
      id: 'Occupation:3127',
      code: '3127',
      name: 'Điện ảnh - diễn viên xiếc, múa lửa, nhào lộn trên không',
      nameEn: 'circus performer, fire performer, aerial performer'
    },
    {
      id: 'Occupation:3133',
      code: '3133',
      name: 'Đóng và sửa chữa tàu -  công nhân điều khiển cẩu trục',
      nameEn: 'crane controller'
    },
    {
      id: 'Occupation:3135',
      code: '3135',
      name: 'Đóng và sửa chữa tàu - công nhân móc cẩu, hướng dẫn cẩu',
      nameEn: 'crane controlling assistant'
    },
    {
      id: 'Occupation:3136',
      code: '3136',
      name: 'Đóng và sửa chữa tàu - đóng tàu thợ sửa chữa tàu',
      nameEn: 'ship maker, ship repair worker'
    },
    {
      id: 'Occupation:3159',
      code: '3159',
      name: 'Giày da, may mặc - công nhân vận hành, bảo trì nồi lò hơi',
      nameEn: 'boiler operator, boiler maintenance worker'
    },
    {
      id: 'Occupation:3169',
      code: '3169',
      name: 'Gỗ - công nhân khai thác gỗ',
      nameEn: 'Lumberjack'
    },
    {
      id: 'Occupation:3173',
      code: '3173',
      name: 'Hầm mỏ - công nhân đào hầm',
      nameEn: 'tunnel digging worker'
    },
    {
      id: 'Occupation:3174',
      code: '3174',
      name: 'Hầm mỏ - công nhân làm việc trên mặt đất',
      nameEn: 'worker in surface mine'
    },
    {
      id: 'Occupation:3175',
      code: '3175',
      name: 'Hầm mỏ - công nhân làm việc trong hầm lò',
      nameEn: 'underground mine worker'
    },
    {
      id: 'Occupation:3183',
      code: '3183',
      name: 'Khí đốt - công nhân sản xuất có tiếp xúc chất cháy nổ',
      nameEn: 'production worker exposed to inflammable substances'
    },
    {
      id: 'Occupation:3184',
      code: '3184',
      name: 'Khí đốt - công nhân vận hành khai thác khí đốt',
      nameEn: 'gas exploration operator'
    },
    {
      id: 'Occupation:3186',
      code: '3186',
      name: 'Khí đốt - quản lý kho khí đốt',
      nameEn: 'storage tank manager'
    },
    {
      id: 'Occupation:3187',
      code: '3187',
      name: 'Khoáng sản - công nhân khai thác khoáng sản trong hầm lò',
      nameEn: 'mineral miner in pit'
    },
    {
      id: 'Occupation:3188',
      code: '3188',
      name: 'Khoáng sản - công nhân khai thác than trên mặt đất',
      nameEn: 'coal surface miner'
    },
    {
      id: 'Occupation:3189',
      code: '3189',
      name: 'Khoáng sản - công nhân khai thác than trong hầm lò',
      nameEn: 'coal underground miner'
    },
    {
      id: 'Occupation:3204',
      code: '3204',
      name: 'Lái sà lan khai thác cát',
      nameEn: 'sand barge boatman'
    },
    {
      id: 'Occupation:3218',
      code: '3218',
      name: 'Lực lượng vũ trang - bộ binh chiến đấu',
      nameEn: 'infantryman'
    },
    {
      id: 'Occupation:3232',
      code: '3232',
      name: 'Lực lượng vũ trang - đặc công',
      nameEn: 'commando'
    },
    {
      id: 'Occupation:3235',
      code: '3235',
      name: 'Lực lượng vũ trang - hải quân trên biển binh chủng người nhái lặn',
      nameEn: 'frogman'
    },
    {
      id: 'Occupation:3239',
      code: '3239',
      name: 'Lực lượng vũ trang - kỹ thuật đạn dược',
      nameEn: 'ammunition technician'
    },
    {
      id: 'Occupation:3240',
      code: '3240',
      name: 'Lực lượng vũ trang - lái xe chở vũ khí',
      nameEn: 'truck filled with weapons driver'
    },
    {
      id: 'Occupation:3241',
      code: '3241',
      name: 'Lực lượng vũ trang - phi công, hoa tiêu của các loại máy bay khác',
      nameEn: 'other aircraft pilot, navigator'
    },
    {
      id: 'Occupation:3242',
      code: '3242',
      name: 'Lực lượng vũ trang - phi công, hoa tiêu của máy bay chiến đấu',
      nameEn: 'fighter aircraft pilot, navgiator'
    },
    {
      id: 'Occupation:3245',
      code: '3245',
      name: 'Luyện kim - công nhân vận hành, bảo trì nồi hơi/lò hơi ',
      nameEn: 'boiler maintenance worker'
    },
    {
      id: 'Occupation:3263',
      code: '3263',
      name: 'Nhân viên giao nhận hàng hóa có giá trị (tiền, vàng bạc, đá quý, nữ trang..)',
      nameEn: 'valuable item deliverer'
    },
    {
      id: 'Occupation:3267',
      code: '3267',
      name: 'Nhân viên hạt kiểm lâm tại lâm trường, hạt kiểm lâm',
      nameEn: 'forest ranger'
    },
    {
      id: 'Occupation:3290',
      code: '3290',
      name: 'Nhân viên thu tiền và tài xế lái xe bọc sắt chở tiền',
      nameEn: 'bill collector, armored vehicle driver'
    },
    {
      id: 'Occupation:3295',
      code: '3295',
      name: 'Nhựa - công nhân vân hành, bảo trì nồi lò hơi',
      nameEn: 'boiler operator, maintenance worker'
    },
    {
      id: 'Occupation:3308',
      code: '3308',
      name: 'Nước - thợ đào giếng',
      nameEn: 'well digging worker'
    },
    {
      id: 'Occupation:3328',
      code: '3328',
      name: 'Quảng cáo, bảng hiệu đèn neon - thợ ráp bảng hiệu quảng cáo từ 6m trở lên',
      nameEn: 'advertising panel installer working with height over 6 meters'
    },
    {
      id: 'Occupation:3330',
      code: '3330',
      name: 'Sân bay, hàng không dân dụng - lái máy bay trực thăng dân dụng',
      nameEn: 'passenger helicopter pilot'
    },
    {
      id: 'Occupation:3332',
      code: '3332',
      name: 'Sân bay, hàng không dân dụng - nhân viên chụp không ảnh',
      nameEn: 'air traffic controller (sky photography)'
    },
    {
      id: 'Occupation:3341',
      code: '3341',
      name: 'Sản xuất đường tinh luyện - công nhân vận hành, bảo trì nồi hơi/lò hơi ',
      nameEn: 'boiler operator, maintenance worker'
    },
    {
      id: 'Occupation:3354',
      code: '3354',
      name: 'Sản xuất khí đá - công nhân sản xuất và nạp bình khí đá',
      nameEn: 'acetylene cylinder production worker'
    },
    {
      id: 'Occupation:3359',
      code: '3359',
      name: 'Sản xuất nồi hơi/lò hơi - công nhân sản xuất và bảo trì nồi hơi, lò hơi',
      nameEn: 'boiler production worker, maintenance worker'
    },
    {
      id: 'Occupation:3366',
      code: '3366',
      name: 'Sản xuất và lắp ráp ôtô, xe gắn máy - nhân viên chạy thử xe (thử tốc độ, va đập)',
      nameEn: 'test driver (speed, shock test)'
    },
    {
      id: 'Occupation:3368',
      code: '3368',
      name: 'Sản xuất vật liệu cháy nổ - công nhân không tiếp xúc vật liệu cháy nổ',
      nameEn: 'worker (not exposed to explosive)'
    },
    {
      id: 'Occupation:3369',
      code: '3369',
      name: 'Sản xuất vật liệu cháy nổ - công nhân tiếp xúc vật liệu cháy nổ',
      nameEn: 'worker exposed to explosive'
    },
    {
      id: 'Occupation:3371',
      code: '3371',
      name: 'Sản xuất vật liệu cháy nổ - nhân viên bảo trì máy sản xuất vật liệu',
      nameEn: 'production machine maitenance worker'
    },
    {
      id: 'Occupation:3375',
      code: '3375',
      name: 'Sản xuất vỏ ruột xe - công nhân vận hành máy, bảo trì nồi/ lò hơi',
      nameEn: 'boiler maintenance worker'
    },
    {
      id: 'Occupation:3386',
      code: '3386',
      name: 'Tài xế xe bồn chở xăng, vận chuyển vật liệu cháy nổ',
      nameEn: 'fuel, inflammable substance delivery truck driver'
    },
    {
      id: 'Occupation:3387',
      code: '3387',
      name: 'Tài xế xe đầu kéo, container, xe nâng',
      nameEn: 'tractor-trailer, container truck, forklift truck driver'
    },
    {
      id: 'Occupation:3388',
      code: '3388',
      name: 'Tài xế xe lái cẩu trục, thợ lắp dựng cẩu',
      nameEn: 'crane operator, crane installer'
    },
    {
      id: 'Occupation:3394',
      code: '3394',
      name: 'Thợ lắp dựng ăng ten làm việc độ cáo trên 6 m',
      nameEn: 'antenna installer working in height over than 6 meters'
    },
    {
      id: 'Occupation:3398',
      code: '3398',
      name: 'Thợ sơn nhà cao tầng trên 6 mét',
      nameEn: 'painter working with height >= 6 meters'
    },
    {
      id: 'Occupation:3422',
      code: '3422',
      name: 'Vân động viên bắn súng',
      nameEn: 'shooter'
    },
    {
      id: 'Occupation:3431',
      code: '3431',
      name: 'Vận động viên đua xe',
      nameEn: 'racer'
    },
    {
      id: 'Occupation:3432',
      code: '3432',
      name: 'Vận động viên lặn',
      nameEn: 'diver (sports)'
    },
    {
      id: 'Occupation:3433',
      code: '3433',
      name: 'Vận động viên leo núi',
      nameEn: 'mountain climber'
    },
    {
      id: 'Occupation:3435',
      code: '3435',
      name: 'Vận động viên nhảy dù',
      nameEn: 'parachuter'
    },
    {
      id: 'Occupation:3437',
      code: '3437',
      name: 'Vận động viên quyền anh, đấm bốc',
      nameEn: 'boxer'
    },
    {
      id: 'Occupation:3439',
      code: '3439',
      name: 'Vận động viên võ thuật, đấu kiếm',
      nameEn: 'martial artist, swordman/fencer'
    },
    {
      id: 'Occupation:3440',
      code: '3440',
      name: 'Vận tải đường biển - sà lan chuyên dùng chở dầu',
      nameEn: 'oil barge'
    },
    {
      id: 'Occupation:3441',
      code: '3441',
      name: 'Vận tải đường biển - thợ máy, thủy thủ sà lan chở hàng',
      nameEn: 'cargo barge mechanic, sailor'
    },
    {
      id: 'Occupation:3442',
      code: '3442',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu  khai thác và chế biến',
      nameEn: 'fishing vessel mechanic, sailor'
    },
    {
      id: 'Occupation:3443',
      code: '3443',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu chở hàng khác, tàu đặt cáp và ống dẫn',
      nameEn: 'cargo ship, cable ship mechanic, sailor'
    },
    {
      id: 'Occupation:3444',
      code: '3444',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu chở hàng ven biển việt nam',
      nameEn: 'cargo ship, cable ship mechanic, sailor'
    },
    {
      id: 'Occupation:3445',
      code: '3445',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu chở khách loại nhỏ (có nhiều nhất 12 giường ngủ), phà chở khách',
      nameEn: 'ferry, small passenger ship mechanic, sailor'
    },
    {
      id: 'Occupation:3446',
      code: '3446',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu dự báo thời tiết',
      nameEn: 'weather ship mechanic, sailor'
    },
    {
      id: 'Occupation:3447',
      code: '3447',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu kéo',
      nameEn: 'tugboat mechanic, sailor'
    },
    {
      id: 'Occupation:3448',
      code: '3448',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu lai dắt, tàu hoa tiêu dẫn đường vào cảng',
      nameEn: 'tugboat mechanic, sailor'
    },
    {
      id: 'Occupation:3449',
      code: '3449',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu nạo vét, tàu báo hiệu',
      nameEn: 'dredger, signal ship mechanic, sailor'
    },
    {
      id: 'Occupation:3450',
      code: '3450',
      name: 'Vận tải đường biển - thợ máy, thủy thủ tàu tiếp tế',
      nameEn: 'platform supply vessel mechnician, sailor'
    },
    {
      id: 'Occupation:3455',
      code: '3455',
      name: 'Vận tải trên sông - nhân viên, thủy thủ tàu kéo, phà',
      nameEn: 'tugboat, ferry employee, sailor'
    },
    {
      id: 'Occupation:3458',
      code: '3458',
      name: 'Vệ sinh công cộng - mé nhánh cây',
      nameEn: 'cut trees'
    },
    {
      id: 'Occupation:3472',
      code: '3472',
      name: 'Xây dựng - thầu xây dựng có tham gia xây dựng  có độ cao từ 6 mét trở lên',
      nameEn: 'building contractor (join construction acitvity with height >= 6 meters)'
    },
    {
      id: 'Occupation:3474',
      code: '3474',
      name: 'Xây dựng - thợ cắt đá',
      nameEn: 'stone cutter'
    },
    {
      id: 'Occupation:3475',
      code: '3475',
      name: 'Xây dựng - thợ hồ xây dựng làm trên giàn giáo có độ cao từ  6 mét trở lên',
      nameEn: 'bricklayer, construction worker in height >=6 meters'
    },
    {
      id: 'Occupation:3476',
      code: '3476',
      name: 'Xây dựng - thợ khoan bê tông cốt thép, thợ cắt đá',
      nameEn: 'composite steel-concrete driller, stone cutter'
    },
    {
      id: 'Occupation:3480',
      code: '3480',
      name: 'Xây dựng - xây dựng  đường hầm sử dụng chất nổ',
      nameEn: 'tunnel construction'
    },
    {
      id: 'Occupation:3481',
      code: '3481',
      name: 'Xây dựng - công nhân thi công đường hầm',
      nameEn: 'tunnel constructing worker'
    },
    {
      id: 'Occupation:3483',
      code: '3483',
      name: 'Xây dựng, cầu đường - công nhân xây bồn dự trữ',
      nameEn: 'tank builder'
    },
    {
      id: 'Occupation:3494',
      code: '3494',
      name: 'Y tế - điều dưỡng hoặc nhân viên phòng xạ trị',
      nameEn: 'Nurse, Employee working in Radiotherapy facility'
    },
    {
      id: 'Occupation:3495',
      code: '3495',
      name: 'Y tế - điều dưỡng phòng xạ trị',
      nameEn: 'Nurse working in Radiotherapy facility'
    },
    {
      id: 'Occupation:3504',
      code: '3504',
      name: 'Thợ lặn',
      nameEn: 'diver'
    },
    {
      id: 'Occupation:3516',
      code: '3516',
      name: 'Bảo trì tòa nhà - nhân viên sửa chữa và lắp đặt thang máy, thang cuốn',
      nameEn: 'elevator escalator worker'
    },
    {
      id: 'Occupation:3545',
      code: '3545',
      name: 'Công nhân khai thác, cưa xẻ gỗ rừng',
      nameEn: 'logger'
    },
    {
      id: 'Occupation:3551',
      code: '3551',
      name: 'Dầu khí - công nhân giàn khoan',
      nameEn: 'oil platform worker'
    },
    {
      id: 'Occupation:3570',
      code: '3570',
      name: 'Gỗ - công nhân bốc xếp gỗ',
      nameEn: 'log loading worker'
    },
    {
      id: 'Occupation:3571',
      code: '3571',
      name: 'Gỗ - công nhận vận chuyển gỗ',
      nameEn: 'wood transport driver'
    },
    {
      id: 'Occupation:3573',
      code: '3573',
      name: 'Gỗ - tài xế ủi rừng kéo gỗ',
      nameEn: 'skidder driver'
    },
    {
      id: 'Occupation:3577',
      code: '3577',
      name: 'Khoáng sản - công nhân làm trong mỏ than',
      nameEn: 'coal miner'
    },
    {
      id: 'Occupation:3582',
      code: '3582',
      name: 'Lực lượng vũ trang - phi công, hoa tiêu của máy bay trực thăng',
      nameEn: 'helicopter pilot, navigator'
    },
    {
      id: 'Occupation:3615',
      code: '3615',
      name: 'Vận tải trên sông - nhân viên, thủy thủ sà lan chở hàng',
      nameEn: 'cargo barge employee, sailor'
    },
    {
      id: 'Occupation:3484',
      code: '3484',
      name: 'Xây dựng, cầu đường - công nhân xây cầu',
      nameEn: 'bridge builder'
    },
    {
      id: 'Occupation:3485',
      code: '3485',
      name: 'Xây dựng, cầu đường - công nhân xây đập',
      nameEn: 'dam builder'
    },
    {
      id: 'Occupation:3486',
      code: '3486',
      name: 'Xây dựng, cầu đường - công nhân xây đê',
      nameEn: 'dyke builder'
    }
  ]

  const isVi = window && window.localStorage && window.localStorage.getItem('locale') === 'vi'
  export const MockOccupation = MockOccupationDB.map((x) =>
    isVi
      ? {
          label: x.name,
          value: x.code
        }
      : {
          label: x.nameEn,
          value: x.code
        }
  )

  export const mapOcc = new Map<string | undefined, string>()
  MockOccupation.forEach((o) => mapOcc.set(o.value, o.label))

  export const MockSalary: { label: string; value: string }[] = [
    { label: 'CCI.Salary.00', value: '00' },
    { label: 'CCI.Salary.01', value: '01' },
    { label: 'CCI.Salary.02', value: '02' },
    { label: 'CCI.Salary.03', value: '03' },
    { label: 'CCI.Salary.04', value: '04' },
    { label: 'CCI.Salary.05', value: '05' }
  ]

  export const mapSalary = new Map<string | undefined, string>()
  MockSalary.forEach((o) => mapSalary.set(o.value, o.label))

  export const getSalaryTitle = (code: string) => mapSalary.get(code) || code
}
