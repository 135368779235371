import { Panel, TaskDetail, TransactionType } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList, Title } from '../common'

interface Props {
  detail: TaskDetail.VeriPolicyCertificateData
}

export const PolicyCertificate = ({ detail }: Props) => {
  const { t } = useTranslation(['requestInfo'])

  const convertTransactionTypeToName = (type: TransactionType) => {
    switch (type) {
      case TransactionType.POLICY_CERTIFICATE:
        return t('PolicyCertificate')
      case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE:
        return t('ConfirmationLetterOfPolicyAccountAndSurrenderValue')
      default:
        return ''
    }
  }
  return (
    <>
      <Title title={t('LetterRequest')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('DocumentType'),
              value: convertTransactionTypeToName(detail.tag)
            },
            {
              label: t('Reason'),
              value: detail.reason ?? '-'
            }
          ]}
        />
      </Panel>
    </>
  )
}
