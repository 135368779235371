import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { Task, Throwable, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { QueryTask } from './model'

export namespace InternalFeedbackService {
  const Canbe = <C extends t.Mixed>(codec: C) => t.union([codec, t.undefined, t.null])
  export const SuspendTypeArray = t.array(
    t.type({
      code: t.string,
      typeEn: t.string,
      typeVn: t.string
    })
  )
  export const SuspendDepartmentInfo = Maybe(
    t.type({
      suspendCode: t.string,
      // normalEmailTo: t.string,
      // "normalEmailCc": t.string,
      // "urgentEmailTo": null,
      // "urgentEmailCc": null,
      // "criticalEmailTo": null,
      // "criticalEmailCc": null,
      slaHourNormal: t.string,
      slaHourUrgent: t.string,
      slaHourCritical: t.string,
      department: t.string,
      departmentShortName: t.string
    })
  )

  export const T31Department = t.type({
    departmentCode: t.string,
    departmentEN: t.string,
    departmentVN: t.string,
    departmentShortName: t.string,
    departmentWF: t.string
  })

  export const MailGroupInfo = Maybe(
    t.type({
      mailGroup: Canbe(t.string),
      email: Canbe(t.string),
      department: Canbe(t.string)
    })
  )

  export const InsertedInternalSuspendData = t.type({
    id: Maybe(t.string),
    status: t.string,
    suspendCode: t.string,
    user: t.string,
    slaHour: t.string,
    effectiveDate: t.string,
    dueDate: t.string,
    department: t.string,
    level: t.string,
    content: t.string,
    emailTo: t.string,
    emailCc: t.string,
    timeZone: Canbe(t.string)
  })

  export const ResponseInternalSuspendData = t.type({
    id: t.string,
    suspendGroupCode: t.string,
    suspendGroupEN: t.string,
    suspendGroupVN: t.string,
    suspendCode: t.string,
    suspendType: t.string,
    suspendTypeVN: t.string,
    slaHour: t.string,
    effectiveDate: t.string,
    dueDate: Maybe(t.string),
    status: t.string,
    department: t.string,
    level: t.string,
    content: t.string,
    emailTo: Canbe(t.string),
    emailCc: Canbe(t.string),
    createdBy: t.string,
    createdDate: t.string,
    modifiedBy: Maybe(t.string),
    modifiedDate: Maybe(t.string),
    caseId: Maybe(t.string)
  })

  export const SubmitedInternalFeedback = t.type({
    data: t.type({
      parentId: t.string,
      feedbackDepartment: t.string,
      feedbackDetail: t.string,
      feedbackUser: t.string
    }),
    processInstanceId: t.string
  })

  export const ResponsedInternalData = t.type({
    data: Maybe(
      t.type({
        id: Maybe(t.string),
        parentId: Maybe(t.string),
        feedbackDepartment: Maybe(t.string),
        feedbackDetail: Maybe(t.string),
        feedbackUser: Maybe(t.string)
      })
    ),
    responseError: t.type({
      code: t.string,
      status: t.string,
      message: t.string
    })
  })

  export const InternalFeedbackDetail = Maybe(
    t.type({
      id: t.string,
      parentId: t.string,
      feedbackDepartment: t.string,
      feedbackDate: t.string,
      feedbackDetail: t.string,
      feedbackUser: t.string
    })
  )

  export const WorkingTimeInfo = t.type({
    date: t.string,
    startTime: t.string,
    endTime: t.string
  })

  export const InternalHistoryResponse = t.type({
    data: t.array(ResponseInternalSuspendData),
    total: t.number,
    page: t.number,
    size: t.number
  })

  export const InsertedInternalSuspendDataList = Maybe(t.array(InsertedInternalSuspendData))

  export const ResponseInternalSuspendDataList = Maybe(t.array(ResponseInternalSuspendData))
  export const T31DepartmentList = t.array(T31Department)

  export const SavedInternalSuspendData = t.type({
    data: InsertedInternalSuspendDataList,
    processInstanceId: t.string
  })

  export const SuspendDepartmentInfoList = Maybe(t.array(SuspendDepartmentInfo))
  export const MailGroupList = Maybe(t.array(MailGroupInfo))

  export type SuspendTypeArray = t.TypeOf<typeof SuspendTypeArray>
  export type SuspendDepartmentInfo = t.TypeOf<typeof SuspendDepartmentInfo>
  export type ResponseInternalSuspendData = t.TypeOf<typeof ResponseInternalSuspendData>
  export type MailGroupInfo = t.TypeOf<typeof MailGroupInfo>
  export type InsertedInternalSuspendData = t.TypeOf<typeof InsertedInternalSuspendData>
  export type T31Department = t.TypeOf<typeof T31Department>
  export type MailGroupList = t.TypeOf<typeof MailGroupList>
  export type T31DepartmentList = t.TypeOf<typeof T31DepartmentList>
  export type SavedInternalSuspendData = t.TypeOf<typeof SavedInternalSuspendData>
  export type InsertedInternalSuspendDataList = t.TypeOf<typeof InsertedInternalSuspendDataList>
  export type ResponseInternalSuspendDataList = t.TypeOf<typeof ResponseInternalSuspendDataList>
  export type WorkingTimeInfo = t.TypeOf<typeof WorkingTimeInfo>
  // feedback model data
  export type SubmitedInternalFeedback = t.TypeOf<typeof SubmitedInternalFeedback>
  export type InternalFeedbackDetail = t.TypeOf<typeof InternalFeedbackDetail>
  export type InternalHistoryResponse = t.TypeOf<typeof InternalHistoryResponse>

  export const getSuspendTypeList = (processInstanceId: string, suspendGroupCode: string): Task<SuspendTypeArray> =>
    pipe(
      POApi.get(
        `pulseops/api/v1/t6s1/get-list-suspend-type?processInstanceId=${processInstanceId}&suspendGroupCode=${suspendGroupCode}`
      )(
        t.type({
          data: SuspendTypeArray
        })
      ),
      ZIO.map((response) => {
        return response.data
      })
    )

  export const getDeparmentInfo = (suspendCode: string): Task<SuspendDepartmentInfo> =>
    pipe(
      POApi.get(`pulseops/api/v1/t6s2/get-detail-by-suspend?suspendCode=${suspendCode}`)(
        t.type({
          data: SuspendDepartmentInfoList
        })
      ),
      ZIO.map((result) => {
        const department = !!result && !!result.data && result.data.length > 0 ? result.data[0] : null
        return department
      })
    )

  export const loadToEmailList = (mailGroup: string): Task<MailGroupList> =>
    pipe(
      POApi.get(`pulseops/api/v1/t27/get-detail-by-group?mailGroup=${mailGroup}`)(
        t.type({
          data: MailGroupList
        })
      ),
      ZIO.map((responseData) => {
        const toEmailList = responseData.data && responseData.data.length > 0 ? responseData.data : []
        return toEmailList
      })
    )

  export const getDepartmentList = () =>
    pipe(
      POApi.get(`pulseops/api/v1/t31/get-all`)(
        t.type({
          data: T31DepartmentList
        })
      ),
      ZIO.map((responseData) => {
        return responseData.data
      }),
      ZIO.cached()
    )

  export const getInternalSuspendHistoryList = (processInstanceId: string) => {
    return pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/get-history-list?processInstanceId=${processInstanceId}`)(
        t.type({
          data: ResponseInternalSuspendDataList
        })
      ),
      ZIO.map((responseData) => {
        return responseData.data
      })
    )
  }

  export const saveNewInternalSuspend = (newSuspendList: SavedInternalSuspendData) => {
    return pipe(
      POApi.put(`pulseops/api/v1/internal-feedback/save-suspend`)(
        t.type({
          data: ResponseInternalSuspendDataList,
          responseError: t.type({
            code: t.string,
            status: t.string,
            message: t.string
          })
        })
      )({
        data: newSuspendList.data,
        processInstanceId: newSuspendList.processInstanceId
      }),
      ZIO.map((response) => {
        return response
      })
    )
  }

  export const updatedInternalSuspendHistory = (newSuspendList: SavedInternalSuspendData) =>
    pipe(
      POApi.put(`pulseops/api/v1/internal-feedback/update-list-suspend`)(
        t.type({
          data: ResponseInternalSuspendDataList,
          responseError: t.type({
            code: t.string,
            status: t.string,
            message: t.string
          })
        })
      )({
        data: newSuspendList.data,
        processInstanceId: newSuspendList.processInstanceId
      }),
      ZIO.map((resultData) => {
        return resultData
      })
    )

  // internal feedback functions
  export const submitInternalFeedback = (submitedData: SubmitedInternalFeedback) =>
    pipe(
      POApi.put(`pulseops/api/v1/internal-feedback/save-feedback`)(ResponsedInternalData)({
        data: submitedData.data,
        processInstanceId: submitedData.processInstanceId
      }),
      ZIO.map((responseData) => {
        return responseData
      })
    )

  export const getInternalSuspendDetail = (casid: string): Task<ResponseInternalSuspendData> =>
    pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/get-detail-suspend/${casid}`)(ResponseInternalSuspendData),
      ZIO.map((responseData) => {
        return responseData
      })
    )

  export const getInternalFeedbackDetail = (internalSuspendID: string): Task<InternalFeedbackDetail> =>
    pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/get-detail-feedback/${internalSuspendID}`)(
        t.type({
          data: InternalFeedbackDetail
        })
      ),
      ZIO.map((outputData) => {
        return outputData.data
      })
    )

  export const checkSubmitedFeedback = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/check-feedback?processInstanceId=${processInstanceId}`)(
        t.type({
          data: t.type({
            isFeedback: t.boolean
          })
        })
      ),
      ZIO.map((resultData) => resultData.data)
    )

  export const getInternalSuspendExpiredDate = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/internal-feedback/get-expired-date?processInstanceId=${processInstanceId}`)(
        t.type({
          expiredDate: t.string
        })
      ),
      ZIO.foldM(
        (error) => {
          return ZIO.fail(Throwable(error))
        },
        (success) => {
          return ZIO.succeed(success.expiredDate)
        }
      )
    )

  export const getWorkingTimeList = (effectiveDate: string): Task<WorkingTimeInfo[]> =>
    pipe(
      POApi.get(`pulseops/api/v1/t34/get-all-by-date?date=${effectiveDate}`)(
        t.type({
          data: t.array(WorkingTimeInfo)
        })
      ),
      ZIO.map((responseData) => {
        return responseData.data
      })
    )

  export const getCaseStatusByCaseID = (caseID: string): Task<string> =>
    pipe(
      POApi.post(`pulseops/api/v1/internal-feedback/get-case-status`)(t.type({
        data: Maybe(
          t.array(t.type({
            status: t.string,
            processInstanceId: Maybe(t.string)
          }))
        )
      }))({
        businessKey: caseID
      }),
      ZIO.map((response) => {
        const status = !!response.data && response.data.length > 0 ? (response.data[0].status ?? '') : ''
        return status
      })
    )

  export const updatePlanSuspendDate = (processInstanceId: string, unsuspendDate: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/internal-feedback/unsuspend-date?processInstanceId=${processInstanceId}`)(t.unknown)({
          unsuspendDate: unsuspendDate
      })
    )
    
  export const getInternalHistoryList = (processInstanceId: string, start: number, size: number, departmentCode?: string) =>
    pipe(
      POApi.get(
        `pulseops/api/v1/internal-feedback/get-histories?processInstanceId=${processInstanceId}&department=${departmentCode}&start=${start}&size=${size}`
      )(InternalHistoryResponse)
    )
}
