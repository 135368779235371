import React, { useEffect } from 'react'
import { View, StyleSheet, Text, TextStyle, useWindowDimensions } from 'react-native'
import { Stepper, Step, StepLabel, makeStyles, StepConnector, withStyles, Typography } from '@material-ui/core'
import { AppContext, assets, ModalComponent, RoundedButton, useMobile } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ClaimService } from '@pulseops/submission'
import { ZIO } from '@mxt/zio'
import { StackScreenProps } from '@react-navigation/stack'
import { TaskDetailStackParamList } from '../../TaskDetailStackParamList'
import moment from 'moment'
import { useIsFocused } from '@react-navigation/native'
import i18next from 'i18next'
import { useLoading } from '@mxt/zio-react'
import { isEmpty, lowerCase } from 'lodash'

type Props = StackScreenProps<TaskDetailStackParamList, 'TrackingStatusClaimScreen'>

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundColor: '#ED1B2E'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#ED1B2E'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    marginTop: 60
  }
})(StepConnector)

export const TrackingStatusClaimScreen = ({ navigation, route }: Props) => {
  const classes = useStyles()

  const [activeStep, setActiveStep] = React.useState(0)
  const [dataSource, setDataSource] = React.useState<ClaimService.StageList | null>(null)
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoading] = useLoading(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [data, setData] = React.useState<Array<{
    code: string
    lable: string
    data: Array<{
      suspendTypeCode: string
      suspendTypeVN: string
      suspendType: string
      suspendTypeMessageCode: string
      createdDate?: string
      modifiedDate?: string
    }>
  }> | null>(null)
  const [suspendDetail, setSuspendDetail] = React.useState<Array<{
    suspendGroupDoc: string
    suspendTypes: Array<{
      suspendTypeCode: string
      suspendTypeVN: string
      suspendType: string
      suspendTypeMessageCode: string
      createdDate: string
    }>
  }> | null>(null)
  const [unsuspendDetail, setUnsuspendDetail] = React.useState<Array<{
    suspendGroupDoc: string
    unsuspendTypes: Array<{
      suspendTypeCode: string
      suspendTypeVN: string
      suspendType: string
      suspendTypeMessageCode: string
      modifiedDate: string
    }>
  }> | null>(null)
  const [dataUnSuspend, setDataUnsuspend] = React.useState<Array<{
    code: string
    lable: string
    data: Array<{
      suspendTypeCode: string
      suspendTypeVN: string
      suspendType: string
      suspendTypeMessageCode: string
      modifiedDate: string
    }>
  }> | null>(null)
  const [dateSuspend, setDateSuspend] = React.useState<string>('')
  const [unSuspendDate, setUnSuspendDate] = React.useState<string>('')
  const [statusAdju, setStatusAdju] = React.useState<{
    Pay3: boolean
    Sys4: boolean
    NullDate: boolean
    stage05: boolean
  }>({
    Pay3: false,
    Sys4: false,
    NullDate: false,
    stage05: false
  })

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.replace('HomeScreen')
          }
        },
        { title: i18next.t('claim:ClaimJourneyTrackingStatus'), navigate: null }
      ])
    }
  }, [])

  const step = [
    i18next.t('claim:Submission'),
    i18next.t('claim:Confirmation'),
    i18next.t('claim:Adjudication'),
    i18next.t('claim:FinalDecision'),
    i18next.t('claim:Settlement')
  ]

  const getData = () =>
    pipe(
      ZIO.zip(
        ClaimService.getStage(route.params.id, route.params.sourceOfBusiness),
        ClaimService.getStatusPV(route.params.claimNumber)
      ),
      ZIO.flatMap(([res, dataPV]) => {
        let index = res?.findIndex((item) => item.status === null)
        console.log(dataPV, 'dataPV')
        const STAGE_03 = res?.find((item) => item.stage === 'STAGE_03')
        const STAGE_05 = res?.find((item) => item.stage === 'STAGE_05')
        const checkPay3 = STAGE_03?.suspendGroupCodes?.includes('PAY3')
        const checkSys4 = STAGE_03?.suspendGroupCodes?.includes('SYS4')
        const checkNullDate = isEmpty(STAGE_03?.date) && STAGE_03?.status === 'Completed' ? true : false

        if (STAGE_03?.status === 'Pending') {
          index = checkPay3 ? Number(index ?? 0) + 1 : index
        } else if (STAGE_03?.status === 'Completed') {
          index = checkNullDate ? 2 : index
        }
        const stage05 = STAGE_05?.status && ['DS02', 'DS04'].includes(STAGE_05?.claimDecision)
        if (stage05) {
          index = 4
        }
        setStatusAdju((prevState) => ({
          ...prevState,
          Pay3: !!checkPay3,
          Sys4: !!checkSys4,
          NullDate: checkNullDate,
          stage05: stage05
        }))
        setActiveStep(index ?? 0)
        const dataStage = [...res]

        const STAGE_06 = res?.find((item) => item.stage === 'STAGE_06')
        if (STAGE_06.status) {
          let latestStage = {
            ...STAGE_06,
            status: dataPV.logStatus !== null ? dataPV.logStatus : STAGE_06?.stauts,
            date: dataPV?.logStatus?.toLowerCase() === 'paid' ? dataPV?.paidDate : dataPV?.returnDate
          }
          dataStage[dataStage.length - 1] = latestStage
        }
        setDataSource(dataStage ?? [])

        return ZIO.unit
      })
    )

  useEffect(() => {
    pipe(getData(), bindLoading, ZIO.unsafeRun({}))
  }, [isFocused])

  const ColorlibStepIcon = ({ icon }: any) => {
    const stepIcon: { [key: number]: any } = {
      1: <assets.TrackingSubmission />,
      2: <assets.TrackingNote />,
      3: <assets.TrackingProcess />,
      4: <assets.TrackingComplete />,
      5: <assets.TrackingMoney />
    }

    return (
      <View
        style={{
          backgroundColor: (activeStep <= 0 ? true : icon <= activeStep) ? '#ED1B2E' : '#B0B0B0',
          zIndex: 1,
          width: 50,
          height: 50,
          flex: 1,
          borderRadius: 25,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {stepIcon[icon]}
      </View>
    )
  }

  const mappingStatusProcessStage = (index: number) => {
    let content: string | any = ''

    switch (index) {
      case 0:
        if (dataSource !== null)
          content =
            dataSource?.[index]?.status === 'Completed'
              ? `${i18next.t('claim:Completed')} ${
                  dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
                }`
              : ''
        break
      case 1:
        if (dataSource !== null)
          if (statusAdju.NullDate) {
            content = `${i18next.t('claim:Cancelled')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (dataSource?.[index]?.status === 'Completed' && !statusAdju.NullDate) {
            content =
              dataSource?.[index]?.status === 'Completed'
                ? `${i18next.t('claim:Completed')} ${
                    dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
                  }`
                : ''
          } else if (dataSource?.[index]?.status === 'In process' && !statusAdju.NullDate) {
            content = `${i18next.t('claim:Inprogress')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          }
        break
      case 2:
        if (dataSource !== null)
          if (statusAdju.Pay3 && dataSource?.[index]?.status === 'Pending') {
            content = content = `${i18next.t('claim:Completed')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (
            dataSource?.[index]?.status === 'Pending' &&
            !isEmpty(dataSource?.[index]?.suspendGroupCodes) &&
            !statusAdju.Pay3
          ) {
            content = (
              <>
                {statusAdju.Sys4 && (
                  <View style={{ marginBottom: 5 }}>{i18next.t('claim:OverDeadlineForSupplementaryDocuments')}</View>
                )}
                <RoundedButton
                  text={`${i18next.t('claim:WaitingInformation')}`}
                  activeOpacity={0.7}
                  style={[styles.btnRounded, { width: '100%', maxWidth: 400, marginBottom: 20 }]}
                  textStyle={styles.textStyle}
                  showBorder={true}
                  textColorEnable="#ED1B2E"
                  textColorDisable="gray"
                  borderColorEnable="#ED1B2E"
                  borderColorDisable="gray"
                  onPress={() => openModal(dataSource?.[index])}
                />
              </>
            )
          } else if (dataSource?.[index]?.status === 'In process') {
            content = `${i18next.t('claim:Inprogress')}`
          } else if (dataSource?.[index]?.status === 'End' || dataSource?.[index]?.status === 'Rejected') {
            content = `${i18next.t('claim:Cancelled')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          }
        if (dataSource?.[index]?.status === 'Completed' && !statusAdju.NullDate) {
          content = `${i18next.t('claim:Completed')} ${
            dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
          }`
        }
        break
      case 3:
        if (dataSource !== null)
          if (statusAdju.Pay3) {
            if (['DS01', 'DS03', 'DS05'].includes(dataSource?.[index]?.claimDecision ?? '')) {
              content = `${i18next.t('claim:Accepted')} ${
                dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
              }`
            } else if (['DS02', 'DS04'].includes(dataSource?.[index]?.claimDecision ?? '')) {
              content = `${i18next.t('claim:Declined2')} ${
                dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
              }`
            } else {
              content = `${i18next.t('claim:AcceptedAddLackOfPayee')} ${
                dataSource?.[index]?.date ? renderTime(dataSource?.[index - 1]?.date ?? '') : ''
              }`
            }
          } else if (dataSource?.[index]?.status === 'Pending') {
            content = `${i18next.t('claim:AcceptedAddLackOfPayee')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (dataSource?.[index]?.status === 'In process' && !statusAdju.NullDate) {
            content = `${i18next.t('claim:Inprogress')} `
          } else if (dataSource?.[index]?.status === 'End' && !statusAdju.NullDate) {
            content = `${i18next.t('claim:Cancelled')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (dataSource?.[index]?.status === 'Completed' && !statusAdju.NullDate) {
            if (['DS01', 'DS03', 'DS05'].includes(dataSource?.[index]?.claimDecision ?? '')) {
              content = `${i18next.t('claim:Accepted')} ${
                dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
              }`
            } else if (['DS02', 'DS04'].includes(dataSource?.[index]?.claimDecision ?? '')) {
              content = `${i18next.t('claim:Declined')} ${
                dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
              }`
            } else {
              content = `${i18next.t('claim:Completed')} ${
                dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
              }`
            }
          } else if (dataSource?.[index]?.status === 'Rejected' && !statusAdju.NullDate) {
            content = `${i18next.t('claim:TemporarySuspended')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (statusAdju.NullDate) {
            content = ''
          }
        break
      case 4:
        // @ts-ignore
        const latestStatus = dataSource?.[index]?.status?.toLowerCase()

        if (dataSource !== null && !statusAdju.stage05)
          if (dataSource?.[index]?.status === 'Pending') {
            content = `${i18next.t('claim:AcceptedAddLackOfPayee')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (dataSource?.[index]?.status === 'In process') {
            content = `${i18next.t('claim:Inprogress')} `
          } else if (dataSource?.[index]?.status === 'Completed') {
            content = `${i18next.t('claim:Completed')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (latestStatus === 'paid') {
            content = `${i18next.t('claim:Completed')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (latestStatus === 'return') {
            content = `${i18next.t('claim:ReturnPV')} ${
              dataSource?.[index]?.date ? renderTime(dataSource?.[index]?.date ?? '') : ''
            }`
          } else if (dataSource?.[index]?.status !== null) {
            content = `${i18next.t('claim:Inprogress')} `
          }
        break
      default:
        break
    }

    return content
  }

  const renderTime = (date: string) => {
    return `(${moment(date).format('DD/MM/yyyy')})`
  }

  const SuspendGroup: {
    [key: string]: string
  } = {
    DOC4: `${i18next.t('claim:AwaitingSupplementary')}`,
    DOC5: `${i18next.t('claim:SupportingInGetting')}`,
    PAY3: `${i18next.t('claim:AcceptedAddLackOfPayee')}`,
    SYS4: `${i18next.t('claim:OverDeadlineForSupplementaryDocuments')}`
  }

  const openModal = (dataSource: {
    stage: string | null
    date: string | null
    assignee: string | null
    status: string | null
    suspendGroupCodes: string[] | null
    claimDecision: string
    suspendDetails: Array<{
      suspendGroupDoc: string
      suspendTypes: Array<{
        suspendTypeCode: string
        suspendTypeVN: string
        suspendType: string
        suspendTypeMessageCode: string
        createdDate: string
        modifiedDate?: string
      }>
    }>
    unsuspendDetails: Array<{
      suspendGroupDoc: string
      unsuspendTypes: Array<{
        suspendTypeCode: string
        suspendTypeVN: string
        suspendType: string
        suspendTypeMessageCode: string
        modifiedDate: string
        createdDate: string
      }>
    }>
  }) => {
    const data = dataSource?.suspendDetails?.map((item) => ({
      code: item.suspendGroupDoc,
      lable: `${SuspendGroup[item.suspendGroupDoc] as string}`,
      data: item.suspendTypes
    }))

    dataSource?.unsuspendDetails?.map((item) => {
      data.find((da, index) => {
        if (da.code === item.suspendGroupDoc && item.suspendGroupDoc !== 'SYS4') {
          data[index].data = [...data[index].data, ...item.unsuspendTypes]
        }
      })
    })
    setIsOpen(true)
    setDateSuspend(dataSource.date ?? '')
    setData([...data] ?? [])
  }

  return (
    <View style={{ backgroundColor: '#FFFFFF', height: '100vh' }}>
      <View style={styles.container}>
        <Text style={styles.titleText}>{i18next.t('claim:ClaimJourneyTrackingStatus')}</Text>
        <View>
          <View
            style={[styles.sectionRow, { justifyContent: 'space-evenly', paddingTop: 50, backgroundColor: '#FFFFFF' }]}
          >
            <View style={styles.sectionCenter}>
              <Text style={styles.textHightLight}>{i18next.t('ServiceInquiry:table:ClaimNumber')}: </Text>{' '}
              <Text style={styles.textValue}>{route.params.claimNumber}</Text>
            </View>
            <View style={styles.sectionCenter}>
              <Text style={styles.textHightLight}>{i18next.t('ServiceInquiry:table:PolicyNumber')}: </Text>{' '}
              <Text style={styles.textValue}>{route.params.policyNumber}</Text>
            </View>
            <View style={styles.sectionCenter}>
              <Text style={styles.textHightLight}>{i18next.t('ServiceInquiry:table:LAName')}: </Text>{' '}
              <Text style={styles.textValue}>{route.params.LAName}</Text>
            </View>
          </View>
        </View>
        <View>
          <Stepper
            style={{ paddingTop: 50 }}
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {step.map((lable, index) => {
              const active = activeStep <= 0 ? true : index < activeStep
              return (
                <Step key={index} completed={active} active={active}>
                  <Typography
                    align="center"
                    style={{
                      fontWeight: 'bold',
                      flex: 1,
                      justifyContent: 'center',
                      height: 60,
                      color: active ? '#000000E5' : '#B0B0B0'
                    }}
                  >
                    {lable}
                  </Typography>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{mappingStatusProcessStage(index)}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </View>
      </View>
      <ModalComponent
        title={`${i18next.t('claim:WaitingInformation')}`}
        visible={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        actions={[
          {
            text: i18next.t('common:Cancel'),
            type: 'outline',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 39, marginEnd: 15 },
            action: () => setIsOpen(false)
          }
        ]}
        modalWidth={800}
      >
        <View
          style={{
            marginHorizontal: 20,
            marginTop: 16
          }}
        >
          {
            <>
              <View style={[styles.sectionRow, { marginBottom: 10 }]}>
                <View style={styles.col_6}>
                  <View style={styles.textTitle}>
                    {i18next.t('claim:WaitingInformation').toUpperCase() +
                      ' ' +
                      (dateSuspend ? renderTime(dateSuspend) : '')}
                  </View>
                </View>
                <View style={styles.col_4}>
                  <View style={styles.textTitle}>
                    {i18next.t('claim:ReceivedInformation').toUpperCase() +
                      ' ' +
                      (unSuspendDate ? renderTime(unSuspendDate) : '')}
                  </View>
                </View>
              </View>
              {(data ?? []).map((item) => {
                if (item.code !== 'SYS4')
                  return (
                    <View>
                      <Text style={{ color: '#000000E5', fontWeight: '700', paddingTop: 10 }}>
                        {item.lable?.toUpperCase()}
                      </Text>
                      {item.code !== 'DOC5' ? (
                        item.data?.map((type) => {
                          if (type.suspendTypeCode !== 'SCL26')
                            return (
                              <View style={styles.sectionRow}>
                                <View style={[styles.col_6, styles.boxItem]}>
                                  {`- `}
                                  {i18next.language === 'en' ? type.suspendType : type.suspendTypeVN}{' '}
                                  {type.createdDate ? renderTime(type.createdDate) : ''}
                                </View>
                                <View style={[styles.col_4, styles.boxItem]}>
                                  {type.modifiedDate ? renderTime(type.modifiedDate) : ''}
                                </View>
                              </View>
                            )
                        })
                      ) : (
                        <View style={styles.boxItem}>
                          {`- `}
                          {item.lable} {renderTime(dateSuspend)}
                        </View>
                      )}
                    </View>
                  )
              })}
            </>
          }
        </View>
      </ModalComponent>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    marginLeft: 30,
    marginRight: 30,
    alignItem: 'center',
    backgroundColor: '#FAFAFA'
  },
  titleText: {
    color: '#ed1b2e',
    fontSize: 32,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    paddingTop: 100
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 30,
    paddingBottom: 16
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,

  wrap: {
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15
  },

  btnDisabled: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    opacity: 0.5
  },
  fontWBold: {
    fontWeight: 'bold'
  },
  btnRounded: {
    height: 46,
    borderRadius: 20
  },
  textStyle: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  textTitle: {
    color: '#000000E5',
    fontWeight: 'bold',
    marginBottom: 20,
    fontSize: 13,
    fontFamily: 'NotoSans_700Bold'
  },
  sectionRow: {
    flex: 1,
    // flexWrap: 'wrap',
    flexDirection: 'row',
    minHeight: 'auto'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  },
  col_2: {
    width: '100%',
    maxWidth: '50%'
  },
  sectionCenter: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  textHightLight: {
    color: '#ED1B2E',
    fontSize: 18,
    fontWeight: 700
  },
  textValue: {
    color: '#000000E5',
    fontSize: 18
  },
  boxItem: {
    paddingTop: 5,
    fontFamily: 'NotoSans_400Regular',
    fontSize: 14
  },
  col_6: {
    width: '100%',
    maxWidth: '59%'
  },
  col_4: {
    width: '100%',
    maxWidth: '39%'
  }
} as const)
