import { Maybe } from '@mxt/zio/codec'
import i18next from 'i18next'
import * as t from 'io-ts'
import { date as DateC } from 'io-ts-types'
import { FileUploadData } from '../../component'
import { form2 } from '../../form2'
export namespace PayoutPopup {
  export enum CashlessMethod {
    BANK = 'Bank transfer', // (Chuyển khoản qua ngân hàng)
    PAB = 'Paid at bank', //(Nhận tiền mặt bằng CMND tại ngân hàng)
    PAC = 'Receive cash at counter', //(Nhận tiền mặt tại quầy)
    MOMO = 'eWallet Momo' //(Ví điện tử Momo)
  }

  export enum PayoutMethods {
    PAYPREMIUM = 'PAYPREMIUM',
    PAYLOAN = 'PAYLOAN',
    OTHER = 'OTHER',
    CASHLESS = 'CASHLESS',
    BANKTRANSFER = 'BANKTRANSFER',
    PAID_AT_BANK = 'PAID_AT_BANK',
    REPAY_APL = 'REPAY_APL',
    REPAY_OPL = 'REPAY_OPL',
    REPAY_OPL_APL = 'REPAY_OPL_APL',
    MOMO = 'EWALLET_MOMO',
    CASH_AT_COUNTER = 'CASH_AT_COUNTER'
  }

  export interface PayoutMethodTranslator {
    typeEnum: string
    nameEn: string
    nameVi: string
  }

  export const translateMethod = (
    method: PayoutPopup.PayoutMethods,
    data: {
      typeEnum: string
      nameEn: string
      nameVi: string
    }[]
  ): string => {
    const target = data.find((x) => x.typeEnum == method)
    // const isVi = window && window.localStorage && window.localStorage.getItem('locale') === 'vi'
    const isVi = i18next.language === 'vi'
    return target ? (isVi ? target.nameVi : target.nameEn) : '-'
    // switch (method) {
    //   case PayoutPopup.PayoutMethods.PAYPREMIUM:
    //     return $localize`:@@PayPremium:Pay premium`
    //   case PayoutPopup.PayoutMethods.PAYLOAN:
    //     return $localize`:@@RepayLoan:Repay loan`
    //   case PayoutPopup.PayoutMethods.OTHER:
    //     return $localize`:@@OtherPremium:Other premium`
    //   case PayoutPopup.PayoutMethods.BANKTRANSFER:
    //     return $localize`:@@BankTransfer:Bank transfer`
    //   case PayoutPopup.PayoutMethods.PAID_AT_BANK:
    //     return $localize`:@@PaidAtBank:Paid at bank`
    //   case PayoutPopup.PayoutMethods.REPAY_APL:
    //     return $localize`:@@RepayAPL:Repay APL`
    //   case PayoutPopup.PayoutMethods.REPAY_OPL:
    //     return $localize`:@@RepayOPL:Repay OPL`
    //     // return $localize`:@@ReceiveCashAtCounter:Receive cash at counter`
    //   case PayoutPopup.PayoutMethods.REPAY_OPL_APL:
    //     return $localize`:@@RepayOplApl:Repay APL & OPL`
    //     case PayoutPopup.PayoutMethods.MOMO:
    //   return $localize`:@@EWalletMomo:eWallet Momo`
    //   default:
    //     return ''
    // }
  }

  export interface PayoutData {
    method: PayoutMethods
    amount: number

    policyNum?: string
    poName?: string
    totalPremium?: number

    officeCode?: string
    bankCode?: string
    bankName?: string
    bankAccountNum?: string
    bankAccountName?: string
    bankBranchCode?: string
    bankBranchName?: string
    nationalId?: string
    // issueDate?: string
    // issueBy?: string
    officeBankCode: string | null | undefined
    officeType: string | null | undefined
    payee:
      | {
          isNewClient: boolean | null | undefined
          paymentMethod: string
          isPayeeNotPO: boolean
          dob: string | null | undefined
          gender: string | null | undefined
          phone: string | null | undefined
          email: string | null | undefined
          payeeName: string | null | undefined
          issuedDate: string | null | undefined
          issuedBy: string | null | undefined
          issueCode: string | null | undefined
          salutation: string | null | undefined
          married: string | null | undefined
          occupation: string | null | undefined
          relationshipWithPO: string | null | undefined
          nationality: string | null | undefined
          address: string | null | undefined
          nationality2: string | null | undefined
          foreignAddress: string | null | undefined
          foreignCountry: string | null | undefined
          residenceCountry: string | null | undefined
          name: string | null | undefined
          idNumber: string
          dod: string | null | undefined
          surName: string | null | undefined
          giveName: string | null | undefined
          city: string | null | undefined
          district: string | null | undefined
          ward: string | null | undefined
          street: string | null | undefined
          relativeDocument: FileUploadData[] | null | undefined
        }
      | null
      | undefined
  }

  export const UploadedFileInfo = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: DateC,
    file: form2.file.normal
  })

  export const SummaryCodec = t.type({
    method: t.union([
      t.literal(PayoutMethods.BANKTRANSFER),
      t.literal(PayoutMethods.CASHLESS),
      t.literal(PayoutMethods.MOMO),
      t.literal(PayoutMethods.OTHER),
      t.literal(PayoutMethods.PAID_AT_BANK),
      t.literal(PayoutMethods.PAYLOAN),
      t.literal(PayoutMethods.PAYPREMIUM),
      t.literal(PayoutMethods.REPAY_APL),
      t.literal(PayoutMethods.REPAY_OPL),
      t.literal(PayoutMethods.REPAY_OPL_APL),
      t.literal(PayoutMethods.CASH_AT_COUNTER)
    ]),
    methodView: t.string,
    detail: t.string,
    amount: t.number,
    policyNum: Maybe(t.string),
    poName: Maybe(t.string),
    totalPremium: Maybe(t.number),

    // issueDate: Maybe(t.string),
    // issueBy: Maybe(t.string),
    bankCode: Maybe(t.string),
    bankName: Maybe(t.string),
    bankAccountNum: Maybe(t.string),
    bankAccountName: Maybe(t.string),
    bankBranchCode: Maybe(t.string),
    bankBranchName: Maybe(t.string),
    nationalId: Maybe(t.string),
    officeCode: Maybe(t.string),
    officeBankCode: Maybe(t.string),
    officeType: Maybe(t.string),
    payee: Maybe(
      t.type({
        paymentMethod: Maybe(t.string),
        isNewClient: Maybe(t.boolean),
        isPayeeNotPO: Maybe(t.boolean),
        dob: Maybe(t.string),
        gender: Maybe(t.string),
        phone: Maybe(t.string),
        email: Maybe(t.string),
        payeeName: Maybe(t.string),
        issuedDate: Maybe(t.string),
        issuedBy: Maybe(t.string),
        issueCode: Maybe(t.string),
        salutation: Maybe(t.string),
        married: Maybe(t.string),
        occupation: Maybe(t.string),
        relationshipWithPO: Maybe(t.string),
        nationality: Maybe(t.string),
        address: Maybe(t.string),
        nationality2: Maybe(t.string),
        foreignAddress: Maybe(t.string),
        foreignCountry: Maybe(t.string),
        residenceCountry: Maybe(t.string),
        name: Maybe(t.string),
        idNumber: Maybe(t.string),
        dod: Maybe(t.string),
        surName: Maybe(t.string),
        giveName: Maybe(t.string),
        city: Maybe(t.string),
        district: Maybe(t.string),
        ward: Maybe(t.string),
        street: Maybe(t.string),
        relativeDocument: Maybe(t.array(UploadedFileInfo))
      })
    )
  })

  export type Summary = t.TypeOf<typeof SummaryCodec>

  export type PayoutSummaryArrayBrands = {
    readonly EmptyArray: unique symbol
    readonly TotalAmountLessThanZero: unique symbol
    readonly TotalAmountLessThanMinValue: unique symbol
    readonly TotalAmountNotEqualValue: unique symbol
  }

  export type UploadedFileInfo = t.TypeOf<typeof UploadedFileInfo>
  export type PayoutSummaryArray = t.Branded<Summary[], PayoutSummaryArrayBrands>

  // export interface Summary {
  //   method: PayoutMethods
  //   methodView: string
  //   detail: string
  //   amount: number

  //   policyNum?: string
  //   poName?: string
  //   totalPremium?: number

  //   bankCode?: string
  //   bankName?: string
  //   bankAccountNum?: string
  //   bankAccountName?: string
  //   bankBranchCode?: string
  //   bankBranchName?: string
  //   nationalId?: string
  // }

  /**PAY PREMIUM */
  export interface PayPremiumData {
    policyNum: string
    paidToDate: string
    installmentPremium: number
    poName: string
    totalPremium: number

    billingFrequency?: string
    relationship?: string
    isMaturityAdvValid?: boolean
  }

  export interface PayPremiumOtherData {
    policyNum: string
    poName: string
    paidToDate: string
    installmentPremium: number
    totalPremium: number
    billingFrequency: string
    isMaturityAdvValid?: boolean
    isPayPremiumValid?: boolean
  }

  /**REPAY LOAN */
  export interface ReloanPayData {
    policyNum: string
    opl: number
    apl: number
    poName: string
    totalPremium: number
  }

  export interface RepayLoanOtherData {
    policyNum: string
    poName: string
    opl: number
    apl: number
    totalPremium: number
    isRepayLoanvalid?: boolean
    isNotFound: boolean
  }

  /**OTHER PAY */

  export interface OtherPayPremiumData {
    policyNum: string
    contractStatus: string
    poName: string
    totalPremium: number
    minTopUp?: number
    maxTopUp?: number
  }

  export interface OtherPayPremiumSearchData {
    policyNum: string
    poName: string
    contractStatus: string
    totalPremium: number
    isMaturityAdvValid?: boolean
    isOtherPayPremiumValid?: boolean
    minTopUp?: number
    maxTopUp?: number
  }

  export interface TopUpPolicy {
    policyNum: string
    minTopUp: number
    maxTopUp: number
    ogMaxTopup: number
    totalPremium: number
  }
}
