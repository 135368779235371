import {
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  CategoryToLabel,
  DatePicker,
  ErrorHandling,
  form2,
  HistoricTask,
  Input,
  InquiryComplaintService,
  Permission,
  PulseOpsFormat,
  RBAC,
  SelectOption,
  TaskOp,
  TaskService,
  TransactionLabel,
  TransactionStatusLabel
} from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/function'
import * as _ from 'lodash'
import { default as React } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, ScrollView, StyleSheet, Text, TextStyle, TouchableOpacity, View } from 'react-native'
import { SearchForm } from './search-form'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})

export const ErrorHandlerReportScreen = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const classes = useStyles()

  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [loading, bindLoading] = useLoading(false)

  const { t, i18n } = useTranslation()

  const [rows, setRows] = React.useState<TaskOp[]>([])
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)

  const [subServiceTypeList, setSubServiceTypeList] = React.useState<SelectOption[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<(SelectOption & { typeCode: string })[]>(
    []
  )

  const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})

  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const getServiceInquiry = (submittedData: HistoricTask.Filter, pageSize: number, pageNum: number) => {
    pipe(
      TaskService.getHistoricTasks({
        pagination: {
          pageIndex: pageNum,
          pageSize: pageSize
        },
        order: 'desc',
        filter: submittedData
      }),
      ZIO.tap((res) => {
        setTotalItem(res.total)
        setRows(res.items)
        setMessageResponse(res.items.length === 0 ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const defaultValues: SearchForm.Raw = {
    policyNumber: '',
    clientNumber: '',
    fromDate: new Date(),
    toDate: new Date()
  }
  const { base, handleSubmit } = form2.useForm(SearchForm.codec, {
    defaultValues: defaultValues
  })

  const search = handleSubmit((data) => {
    setPageNum(0)
    setMessageResponse(null)
    const getSubmitData = (): HistoricTask.Filter => {
      return {
        policyNum: data.policyNumber || undefined,
        clientNum: data.clientNumber || undefined,
        fromDate: PulseOpsFormat.startOfDate(data.fromDate, true),
        toDate: PulseOpsFormat.endOfDate(data.toDate, true),
        businessKeyEmpty: true
      }
    }
    const submitData: HistoricTask.Filter = getSubmitData()
    setSubmittedData(submitData)
    getServiceInquiry(submitData, pageSize, 0)
  })

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:ErrorHandlerReport'),
          navigate: null
        }
      ])

      // load Sub Service Type
      pipe(
        InquiryComplaintService.getTypes(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubServiceTypeList(
              typeList.slice().map((x) => ({
                label: i18n.language === 'en' ? x.name : x.nameVi,
                value: x.code
              }))
            )
          } else {
            setSubServiceTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
      // load sub transaction type
      pipe(
        InquiryComplaintService.getSubType(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubTransactionTypeList(
              typeList.slice().map((x) => ({
                label: i18n.language === 'en' ? x.name : x.nameVi,
                value: x.code,
                typeCode: x.typeCode
              }))
            )
          } else {
            setSubTransactionTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
    }
    setPageSize(10)
    setPageNum(0)
    setTotalItem(0)
    setRows([])
    setMessageResponse(null)
    // setClaimTypes([])
    // setSubServiceTypeList([])
    // setSubTransactionTypeList([])
    // setOfficeCodes([])
    // setOfficeCodeGA(null)
    // setNoteDetail()
    setSubmittedData({})
    base.reset(defaultValues)
  }, [isFocused])

  const convertCategoryToLabel = (category: string): string => {
    const converted = CategoryToLabel(category)
    if (converted) {
      return t(`Reports:${converted}`)
    } else {
      return ''
    }
  }

  return roles.includes(Permission['ViewErrorHandlerReport']) ||
    roles.includes(Permission['ViewErrorHandlerReportPC']) ? (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            {/* Policy Number, Client Number */}
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="policyNumber"
                  control={base.control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      title={t('ServiceInquiry:PolicyNumber')}
                      value={value ?? undefined}
                      maxLength={8}
                      inputType="number"
                      onChange={onChange}
                      errorMessage={base.formState.errors.policyNumber?.message}
                    />
                  )}
                />
              </View>
              <View style={styles.col}>
                <Controller
                  name="clientNumber"
                  control={base.control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      title={t('ServiceInquiry:ClientNumber')}
                      value={value ?? undefined}
                      maxLength={8}
                      inputType="number"
                      onChange={onChange}
                      errorMessage={base.formState.errors.clientNumber?.message}
                    />
                  )}
                />
              </View>
            </View>
            {/* From Date, To Date */}
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="fromDate"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <DatePicker
                      required
                      label={t('ServiceInquiry:FromDate')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
              <View style={styles.col}>
                <Controller
                  name="toDate"
                  control={base.control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <DatePicker
                      required
                      label={t('ServiceInquiry:ToDate')}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
            <View style={[styles.row, { alignItems: 'center' }]}>
              <TouchableOpacity style={{ width: 118, height: 40 }} onPress={search}>
                <View style={styles.btn}>
                  <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
                </View>
              </TouchableOpacity>
            </View>
            <View style={[styles.row, { marginTop: 6 }]}>
              <Text style={{ color: '#ED1B2E' }}>{_.get(base.formState.errors, '.message')}</Text>
            </View>
          </View>
          {loading && <LinearProgress color="secondary" />}

          {rows.length > 0 ? (
            <View style={[styles.section, { marginTop: 10 }]}>
              <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
                <Table style={{ userSelect: 'text' }} stickyHeader>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Source')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:PolicyNumber')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POClient')}</TableCell>
                      {/* <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POID')}</TableCell> */}
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POName')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POPhoneNumber')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Category')}</TableCell>
                      {/* <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Service')}</TableCell> */}
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Transaction')}</TableCell>
                      {/* <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Group')}</TableCell> */}
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubService')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubTransaction')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:UserSubmission')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastAssignee')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedBy')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedDate')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Status')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CurrentActivity')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, i) => (
                      <TableRow key={`TableRow-${i}`}>
                        {/* CaseID */}
                        <TableCell>
                          <Text style={styles.cellNoWrap}>{row.caseId || '-'}</Text>
                        </TableCell>
                        {/* Source */}
                        <TableCell>{row.source}</TableCell>
                        {/* PolicyNumber */}
                        <TableCell>{row.policyNumber || '-'}</TableCell>
                        {/* POClient */}
                        <TableCell>{row.clientNumber || '-'}</TableCell>
                        {/* POID */}
                        {/* <TableCell>{'-'}</TableCell> */}
                        {/* POName */}
                        <TableCell>{row.poName || '-'}</TableCell>
                        {/* POPhoneNumber */}
                        <TableCell>{row.poPhoneNumber || '-'}</TableCell>
                        {/* Category */}
                        <TableCell className={classes.cellNoWrap}>
                          {convertCategoryToLabel(row.category) || '-'}
                        </TableCell>
                        {/* Service */}
                        {/* <TableCell>{'-'}</TableCell> */}
                        {/* Transaction */}
                        <TableCell className={classes.cellNoWrap}>{t(TransactionLabel(row.transactionType))}</TableCell>
                        {/* Group */}
                        {/* <TableCell>{'-'}</TableCell> */}
                        {/* SubService */}
                        <TableCell>
                          {subServiceTypeList.find((x) => x.value === row.subServiceType)?.label || '-'}
                        </TableCell>
                        {/* SubTransaction */}
                        <TableCell>
                          {subTransactionTypeList.find((x) => x.value === row.subTransactionType)?.label || '-'}
                        </TableCell>
                        {/* UserSubmission */}
                        <TableCell>{row.createdBy ? row.createdBy.split('@')[0] : '-'}</TableCell>
                        {/* CreatedDate */}
                        <TableCell>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                        {/* LastAssignee */}
                        <TableCell>
                          <Text>{row.assigneeName || '-'}</Text>
                          {row.assignee && row.assignee !== '-' && (
                            <View>
                              <Text>({row.assignee.split('@')[0]})</Text>
                            </View>
                          )}
                        </TableCell>
                        {/* Aging */}
                        <TableCell>{row.aging}</TableCell>
                        {/* LastUpdatedBy */}
                        <TableCell>
                          {row.lastUpdateByAdj
                            ? row.lastUpdateByAdj.split('@')[0]
                            : row.lastUpdateByVer
                            ? row.lastUpdateByVer.split('@')[0]
                            : '-'}
                        </TableCell>
                        {/* LastUpdatedDate */}
                        <TableCell>
                          {row.lastUpdateDateAdj
                            ? PulseOpsFormat.datetoFormat(row.lastUpdateDateAdj, 'DD/MM/YYYY HH:mm:ss')
                            : row.lastUpdateDateVer
                            ? PulseOpsFormat.datetoFormat(row.lastUpdateDateVer, 'DD/MM/YYYY HH:mm:ss')
                            : '-'}
                        </TableCell>
                        {/* Status */}
                        <TableCell
                          style={{ color: TransactionStatusLabel(row.status).color }}
                          className={classes.cellNoWrap}
                        >
                          {TransactionStatusLabel(row.status).label}
                        </TableCell>
                        {/* CurrentActivity */}
                        <TableCell>{row.activity || '-'}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNum}
                rowsPerPage={pageSize}
                count={totalItem}
                onPageChange={(e, page) => {
                  setPageNum(page)
                  getServiceInquiry(submittedData, pageSize, page)
                }}
                onRowsPerPageChange={(e) => {
                  setPageSize(Number(e.target.value))
                  setPageNum(0)
                  getServiceInquiry(submittedData, Number(e.target.value), 0)
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                component={View}
              />
            </View>
          ) : (
            <View style={{ marginHorizontal: 30 }}>
              <Text style={{ color: '#ED1B2E' }}>{messageResponse}</Text>
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  ) : (
    <></>
  )
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 30,
    paddingVertical: 16
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle
} as const)
