import * as React from 'react'
import {
  FieldList,
  Panel,
  Title,
  Divider,
  EServiceModel,
  EServiceService,
  ErrorHandling,
  form2,
  AppContext,
  TransactionType
} from '@pulseops/common'
import { Checkbox, Column, Label } from '@pulseops/submission/common'
import { PolicyServiceProps } from '../policy-service-props'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/function'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
import { eServiceRegistrationForm } from './eservice-registration.form'
import { ZIO } from '@mxt/zio'
import { Controller } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'

interface Props extends PolicyServiceProps<EServiceModel.EServiceSubmitData> {
  policyNumber: string
}

export const EServiceRegistration = ({ policyNumber, isConfirmed, initSubmission }: Props) => {
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const { t } = useTranslation()

  const [isLoading, bindLoading] = useLoading()

  const eServiceForm = form2.useForm(eServiceRegistrationForm.codec)

  const { control, setValue, watch } = eServiceForm.base

  const formValue = watch()

  pipe(
    EServiceService.getOwnerInfo(policyNumber),
    ZIO.tap((res) => {
      setValue('idNumber', res.nationalId ?? '')
      setValue('telCode', res.clientPhoneCode ?? '')
      setValue('mobileNumber', res.clientPhoneNumber ?? '')
      setValue('fullName', res.ownerName ?? '')
      setValue('dob', moment(res.birthDate).format('DD/MM/yyyy') ?? '')
      setValue('email', res.email ?? '')
      setValue('gender', res.gender ?? '')
      setValue('poClientNum', res.nationalId || '')
      setValue('otpConsent', false)

      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  )

  initSubmission({
    validate: async () => {
      if (!formValue.otpConsent) {
        showToast(t('message:MS020001', { field: `"${t('requestInfo:I_WeAgreedThat')}"` }), 'error')
        return false
      }

      return {
        url: (policyNum: string) => `wf-api/policy/${policyNum}/e-service-registration/submission-for-staff`,
        body: {
          ...formValue,
          otpConsent: formValue.otpConsent ? '1' : '0'
        },
        collerationId: policyNumber,
        transaction: TransactionType.E_SERVICE_REGISTRATION,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.E_SERVICE_REGISTRATION)
      }
    },
    clear: () => {
      setValue('otpConsent', false)
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('submission:RequestDetail')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }} isLoading={isLoading}>
        <FieldList
          dataSource={[
            {
              label: t('submission:NationalID'),
              value: formValue.idNumber
            },
            {
              label: t('requestInfo:MobilePhoneNumber'),
              value: `+${formValue.telCode}  ${formValue.mobileNumber}`
            },
            {
              label: '',
              value: ' '
            },
            {
              label: t('requestInfo:Fullname'),
              value: formValue.fullName
            },
            {
              label: t('submission:DateOfBirth'),
              value: formValue.dob
            },
            {
              label: t('submission:Gender'),
              value: formValue.gender ? `${formValue.gender === 'M' ? t('common:Male') : t('common:Female')}` : '-'
            }
          ]}
        />
      </Panel>
      <Column marginStart={30}>
        <Label title={t('requestInfo:I_WeAgreedThat')} fontSize={15} color={'#000000'} fontWeight="700" />
        <Divider height={10} />
        <Controller
          control={control}
          name="otpConsent"
          render={({ field }) => (
            <Checkbox {...field} title={t('requestInfo:ReceiveOTP')} fontWeight={'700'} enable={!isConfirmed} />
          )}
        />
      </Column>
    </Column>
  )
}
