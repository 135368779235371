import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  formatNumberWithComma,
  formatTextFromLasToVN,
  FrequencyMapping,
  GeneralService,
  Panel,
  RadioButton,
  SourceType,
  TaskDetail
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { TFunctionResult } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import { InfoViewList, Title } from '../common'

type Output = {
  label: string
  value: string | number | JSX.Element | TFunctionResult
  suffix?: string
  isHighlight?: boolean
}

type StatementOfInsurability = {
  laName: string
  newHeathState: boolean
  newOccupationState: boolean
  curOccupation: string
  newOccupation: string
  newActivityState: boolean
  newActivity: string
  newCompanyState: boolean
  newCompany: string
}

export const Reinstatement = ({ detail }: { detail?: TaskDetail.Reinstatement }) => {
  const { t } = useTranslation(['requestInfo', 'frequency'])

  const [dataReinstatement, setDataReinstatement] = React.useState<Output[]>([])
  const [dataStatementOfInsurability, setDataStatementOfInsurability] = React.useState<StatementOfInsurability[]>([])

  const [loading, bindLoader] = useLoading(false)
  // const isTransCheckedNull = [SourceType.ZALO, SourceType.PRUONLINE].includes(detail?.source as SourceType)

  React.useEffect(() => {
    pipe(
      GeneralService.getOccupations,
      ZIO.tap((occupations) => {
        const billingFrequency: string | TFunctionResult =
          (detail?.paymentFrequency && FrequencyMapping.mapFrequency.get(detail.paymentFrequency)) || '-'

        setDataReinstatement([
          { label: t('RiskCommencementDate'), value: detail?.riskCommencementDate || '-' },
          { label: t('LapsedDate'), value: detail?.lapsedDate },
          {
            label: t('BillingFrequency'),
            value: t(billingFrequency)
          },
          {
            label: t('InstallmentPremium'),
            value: detail?.installmentPremium && formatNumberWithComma(detail.installmentPremium),
            suffix: 'VND'
          },
          {
            label: t('AutomaticPolicyLoan'),
            value: detail?.apl && formatNumberWithComma(detail.apl),
            suffix: 'VND'
          },
          {
            label: t('OriginalLoan'),
            value: detail?.opl && formatNumberWithComma(detail.opl),
            suffix: 'VND'
          },
          {
            label: t('ReinstatementFee'),
            value: detail?.reinstatementFee ? formatNumberWithComma(detail.reinstatementFee) : '0',
            suffix: 'VND'
          },
          {
            label: t('TotalReinstatementAmount'),
            value: detail?.totalReinsAmount ? formatNumberWithComma(detail.totalReinsAmount) : '0',
            suffix: 'VND',
            isHighlight: true
          }
        ])
        const dataStatementOfInsurability = detail?.lifeAssured.map((l) => ({
          ...l,
          curOccupation: occupations.find((o) => o.code === l.curOccupation)?.name ?? l.curOccupation ?? '-',
          newOccupation: occupations.find((o) => o.code === l.newOccupation)?.name ?? l.newOccupation
        }))

        const newDataStatementOfInsurability = dataStatementOfInsurability?.map((item) => ({
          laName: item.laName,
          newHeathState: item.newHeathState,
          newOccupationState: notEmpty(item?.newOccupation || ''),
          curOccupation: item.curOccupation,
          newOccupation: item.newOccupation || '',
          newActivityState: item.newActivityState,
          newActivity: item.newActivity || '',
          newCompanyState: notEmpty(item?.newCompany || ''),
          newCompany: item.newCompany || ''
        }))

        setDataStatementOfInsurability(newDataStatementOfInsurability || [])

        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [detail])

  const formatText = (text: string): string => {
    return formatTextFromLasToVN(text)
  }

  const notEmpty = (value: string): boolean => {
    return !!(value && value !== '' && value !== '-')
  }

  const renderLoading = () => {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="red" />
      </View>
    )
  }

  return loading ? (
    renderLoading()
  ) : (
    <View>
      <Title title={t('ReinstatementInfomation')} />
      <Panel title="" isExand={false}>
        <InfoViewList dataSource={dataReinstatement} />
      </Panel>
      {dataStatementOfInsurability.length > 0 && (
        <>
          <Title title={t('StatementOfInsurability')} />
          {dataStatementOfInsurability.map((item: StatementOfInsurability, index: number) => (
            <Panel title={item.laName} key={index}>
              <View style={styles.col}>
                <Text style={styles.label}>{t('AnyChangesInStateOfHealth')}</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <RadioButton label={t('Change')} selected={item.newHeathState} />
                  <RadioButton label={t('NotChange')} selected={!item.newHeathState} />
                </View>
              </View>
              <View style={styles.col}>
                <Text style={styles.label}>{t('AnyChangesInOccupation')}</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <RadioButton label={t('Change')} selected={item.newOccupationState} />
                  <RadioButton label={t('NotChange')} selected={!item.newOccupationState} />
                </View>
              </View>
              <View style={styles.col}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                  <View style={{ marginRight: 30 }}>
                    <Text style={styles.label}>{t('CurrentOccupation')}</Text>
                    <Text style={styles.value}>{item.curOccupation}</Text>
                  </View>
                  <View>
                    <Text style={styles.label}>{t('NewOccupation')}</Text>
                    <Text style={styles.value}>{item.newOccupation}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.col}>
                <Text style={styles.label}>{t('AnyChangeInActivities')}</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <RadioButton label={t('Change')} selected={item.newActivityState} />
                  <RadioButton label={t('NotChange')} selected={!item.newActivityState} />
                </View>
              </View>
              {item.newActivityState ? (
                <View style={styles.col}>
                  <Text style={styles.label}>{t('NewActivity')}</Text>
                  <Text style={styles.value}>{item.newActivity}</Text>
                </View>
              ) : (
                ''
              )}

              <View style={styles.col}>
                <Text style={styles.label}>{t('AnyChangesInInsurancePolicy/Requesting')}</Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <RadioButton label={t('Change')} selected={item.newCompanyState} />
                  <RadioButton
                    label={t('NotChange')}
                    selected={!item.newCompanyState}
                  />
                </View>
              </View>
              <View style={styles.col}>
                <Text style={styles.label}>{t('CompanyName')}</Text>
                <Text style={styles.value}>{item.newCompany}</Text>
              </View>
            </Panel>
          ))}
        </>
      )}

      <Title title={t('ReinstatementAgent')} />
      <Panel title="" isExand={false}>
        <View style={styles.col}>
          <Text style={styles.label}>{t('ReinstatementAgent')}</Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={(styles.value, { marginRight: 30 })}>{detail?.agentNumber || '-'}</Text>
            <Text style={styles.value}>{formatText(detail?.servicingAgent || '-')}</Text>
          </View>
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },

  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },

  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  col: {
    width: '100%',
    marginBottom: 15
  }
})
