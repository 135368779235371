import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { InboundParamList } from '..'
import { ScrollView, View, StyleSheet, Text } from 'react-native'
import { IBButton, IBGeneralField, IBStyles, TypeInputComponent } from '../common'
import { IACGroup, IBService, SaveDraft } from '../ib-service'
import { IBCommunicationInfo, IBConservation, IBGAOperation, IBMoneyMisappropriation, IBSalesPractice } from '../ib-client/ib-client-calling-result'
import { AppContext, AuthService, CancelRiderService, ErrorHandling, FileUploadData, InquiryComplaintData, InquiryComplaintService, InternalFeedbackService, ModalComponent, Permission, PulseOpsFormat, RBAC, SelectOption, SourceType, SubmissionService, TopUpService, TransactionType, form2 } from '@pulseops/common'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { ComplainInquiryForm } from '../../../../submission/src/complain-inquiry'
import _ from 'lodash'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { UploadedFilesInfo } from '../../../../submission/src/ps-submission'
import { InquiryComplaintConst } from '@pulseops/task'
import { bonusOptions, defaultValuesSubmitDraft, resultOptions } from './IBDraftTransSearchForm'
import { useIsFocused, useNavigation } from '@react-navigation/native'

type DataAttributesExt = {
	complainMisMoneyDetails?: {
		list: InquiryComplaintData.COMPLAINTMISMONEYDETAILS[],
		totalComplaintAmount: number
	} | null,
	complainSaleDetails?: InquiryComplaintData.COMPLAINTSALEDETAILS | null,
	complaintGADetails?: InquiryComplaintData.COMPLAINTGADETAILS | null,
	conservationInfo?: InquiryComplaintData.CONSERVATIONINFO | null
}

type Props = StackScreenProps<InboundParamList, 'IBSubmitTransaction'>

export const IBSubmitTransaction = (props: Props) => {
	const { t, i18n } = useTranslation('Inbound')
	const [loading, bindLoader] = useLoading(false)
	const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
	const isFocused = useIsFocused()
	const navigation = useNavigation()
	const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

	const [groupList, setGroupList] = React.useState<IACGroup[]>([])
	const [subServiceTypeList, setSubServiceTypeList] = React.useState<InquiryComplaintData.IACType[]>([])
	const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<InquiryComplaintData.IACSubtypes[]>([])
	const [requestorRoleList, setRequestorRoleList] = React.useState<SelectOption[]>(
		InquiryComplaintConst.requestorRoleList.map((item) => ({
			label: t(`roles:${item.label}`) as string,
			value: item.value
		})) || []
	)
	const [relationshipList, setRelationshipList] = React.useState<InquiryComplaintConst.Group[]>([])
	const [isDisabledFullname, setDisabledFullname] = React.useState<boolean>(false)
	const [isDisabledRPO, setDisabledRPO] = React.useState<boolean>(false)
	const [isDisabledFCR, setDisabledFCR] = React.useState<boolean>(false)
	const [isDisabledHot, setDisabledHot] = React.useState<boolean>(false)
	const [categoryCode, setCategoryCode] = React.useState<string>('')
	const [userInfo, setUserInfo] = React.useState<{ email: string; name: string }>({
		email: '',
		name: ''
	})
	const [isReset, setReset] = React.useState<boolean>(false)
	const [groupCode, setGroupCode] = React.useState<string>('')
	const [agentCodeError, setAgentCodeError] = React.useState<string>('')
	const [isShowConservation, setShowConservation] = React.useState<boolean>(false)
	const [isLoadOptionDone, setIsLoadOptionDone] = React.useState<boolean>(false)
	const [dataAttributesExt, setDataAttributesExt] = React.useState<DataAttributesExt | null>(null)
	const [receiptTypeList, setReceiptTypeList] = React.useState<InquiryComplaintData.IACReceiptType[]>([])
	const [financialList, setFinancialList] = React.useState<InquiryComplaintData.IACReceiptType[]>([])
	const [fullSurrenderReasonList, setFullSurrenderReasonList] = React.useState<InquiryComplaintData.FullSurrenderReason[]>([])
	const [resolutionList, setResolutionList] = React.useState<InquiryComplaintData.Resolution[]>([])
	const [generalAgencyList, setGeneralAgencyList] = React.useState<InquiryComplaintData.IACSources[]>([])
	const [isShowCancelPopup, setShowCancelPopup] = React.useState<boolean>(false)

	const listMethodShowCallID = ['MT02', 'MT03', 'MT12']

	const accountLogIn: string = ''
	const params = props.route?.params
	const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []

	const [detailData, setDetailData] = React.useState<{
		detail: InquiryComplaintData.DetailData | null
		mainProductCode?: string | null
		riders?: InquiryComplaintData.IACSources[] | null
	} | null>(null)

	const listHoursCallmeBack = ['1', '2', '3', '4', '5', '6', '7', '8', '10', '14', '18', '22', '30', '46']
	const workingTimeList = pipe(
    InternalFeedbackService.getWorkingTimeList(moment().format('YYYY/MM/DD')),
    ZIO.map((workingList) => {
      return workingList ?? []
    }),
    ErrorHandling.runDidMount([])
  )

	const converOptions = (arr: any[]): SelectOption[] => {
		return (
			arr.map((item) => ({
				label: i18n.language === 'en' ? item.name : item.nameVi,
				value: item.code
			})) || []
		)
	}

	const findData = (listData: { label: string, value: string }[], codeNeedFind: string) => {
		return listData.find((item) => item.value === codeNeedFind) ?? null
	}

	React.useEffect(() => {
		showGlobalLoading(loading)
	}, [loading])

	React.useEffect(() => {
		base.reset()
		setIsLoadOptionDone(false)
		if (params?.policyNo) {
			showGlobalLoading(true)
			handleDetailData(params?.policyNo)
		}
	}, [params?.id])

	React.useEffect(() => {
		if (isLoadOptionDone) {
			showGlobalLoading(true)
			setDataToForm()
		}
	}, [isLoadOptionDone])

	React.useEffect(() => {
		if (isFocused) {
			changeBreadcrumb([
				{
					title: '',
					navigate: () => {
						navigation.navigate('HomeScreen')
					}
				},
				{
					title: t('menu:DraftTransaction'),
					navigate: () => {
						navigation.navigate('IBDraftTransaction')
					}
				},
				{
					title: params ? (getCategory(params?.transactionType) + (params?.policyNo ? params?.policyNo + '-' : '') + params.createdDate) : '',
					navigate: null
				}
			])
		}
	}, [isFocused])
	const { base: callingResultForm } = form2.useForm(ComplainInquiryForm.codec, {
		defaultValues: defaultValuesSubmitDraft
	})

	const base = callingResultForm

	const getCategory = (categoryName: string | null | undefined) => {
		if (categoryName === 'INQUIRY') {
			return 'CS' + '-'
		} else if (categoryName === 'COMPLAINT') {
			return 'CH' + '-'
		} else {
			return ''
		}
	}

	const getCategoryCode = (value: string) => {
		setCategoryCode(value)
	}

	const getGroupCode = (value: string) => {
		setGroupCode(value)
		setAgentCodeError('')
	}

	pipe(
		AuthService.userInfo,
		ZIO.map((user) => {
			setUserInfo(user)
		}),
		ErrorHandling.runDidUpdate([detailData?.detail])
	)

	const setDataToForm = () => {
		const laList = detailData?.detail?.laList && detailData?.detail.laList?.map((item) => ({
			label: item.clientName,
			value: item.clientNumberOfLA
		})) || []
		const benList = detailData?.detail?.benList && detailData?.detail.benList?.map((item) => ({
			label: item.clientName,
			value: item.clientNumberOfBen
		})) || []
		base.setValue('generalInfo.group', params?.groupCode ? findData(converOptions(groupList), params?.groupCode) : null)
		base.setValue('generalInfo.type', params?.typeCode ? findData(converOptions(subServiceTypeList || []), params?.typeCode) : null)
		base.setValue('generalInfo.subType', params?.subTypeCode ? findData(converOptions(subTransactionTypeList || []), params?.subTypeCode) : null)
		base.setValue('generalInfo.requestorRole', params?.requesterRoleCode ? findData(requestorRoleList, params?.requesterRoleCode) : null as any)
		if (params?.requesterRoleCode === '1' || params?.requesterRoleCode === '4') {
			base.setValue('generalInfo.requestorFullName', params?.requesterFullName)
		} else {
			if (params?.requesterRoleCode === '2') {
				const findName = laList.find((i) => i.value === params?.requesterFullName) ?? null
				base.setValue('generalInfo.requestorFullName1', findName)
			}
			if (params?.requesterRoleCode === '3') {
				const findName = benList.find((i) => i.value === params?.requesterFullName) ?? null
				base.setValue('generalInfo.requestorFullName1', findName)
			}
		}

		if (params?.requesterRoleCode === '4') {
			detailData?.detail?.CBrelationshipList && setRelationshipList(
				detailData?.detail?.CBrelationshipList.map((relationshipItem) => {
					return {
						code: relationshipItem.code,
						nameVi: relationshipItem.name,
						name: relationshipItem.nameEn,
						parentCode: ''
					}
				})
			)
			setDisabledFullname(true)
			const findRole = detailData?.detail?.CBrelationshipList.find((i) => i.code === params?.relationshipWithPO)
			findRole && base.setValue('generalInfo.relationshipWithPolicyOwner', { value: findRole.code, label: i18n.language === 'en' ? findRole.nameEn : findRole.name })
		}
		else {
			setDisabledRPO(true)
		}

		const callBackHour = params?.callBackLaterHour ? { value: params?.callBackLaterHour.toString(), label: params?.callBackLaterHour.toString() } : null
		const callbackAppointmentTime = params?.callbackAppointmentTime ? PulseOpsFormat.datetoFormat(new Date(params?.callbackAppointmentTime), 'DD/MM/YYYY HH:mm:ss' ) : ''

		base.setValue('generalInfo.requestorNationalID', params?.requesterNationalId ?? '')
		base.setValue('generalInfo.method', params?.method ? findData(converOptions(detailData?.detail?.methodList || []), params?.method) : null)
		base.setValue('generalInfo.source', params?.source ? findData(converOptions(detailData?.detail?.sourceList || []), params?.source) : null)
		base.setValue('generalInfo.firstContactResolution', params?.hadFirstContactResolution ?? false)
		base.setValue('generalInfo.hot', params?.isUrgent ?? false)
		base.setValue('generalInfo.callBackLaterHour', callBackHour)
		base.setValue('generalInfo.callbackAppointmentTime', callbackAppointmentTime)

		base.setValue('communicationInfo.customerComments', params?.customerComment ?? false)
		base.setValue('communicationInfo.responsesToCustomer', params?.responseToCustomer ?? false)

		if (params?.method && listMethodShowCallID.includes(params.method) && params?.source === 'SO14') {
			base.setValue('generalInfo.isRequireCallId', true)
		}
		else {
			base.setValue('generalInfo.isRequireCallId', false)
		}
		base.setValue('generalInfo.callId', params?.callId ?? '')

		dataAttributesExt?.complainMisMoneyDetails && base.setValue('moneyMissappropriation.list', dataAttributesExt?.complainMisMoneyDetails?.list.map((i) => ({
			receipt: i.hasReciept as any,
			receiptType: findReceiptType(i.recieptType),
			complaintAmount: PulseOpsFormat.currency(i.complaintAmount),
			receiptNumber: i.recieptNum,
			benefit_PremiumMisappropriatedDate: moment(i.misPremiumDate).toDate() ?? new Date()
		})) ?? [])

		dataAttributesExt?.complainMisMoneyDetails &&
			base.setValue('moneyMissappropriation.totalComplaintAmount', PulseOpsFormat.currency(dataAttributesExt?.complainMisMoneyDetails?.totalComplaintAmount) ?? '')

		dataAttributesExt?.complainSaleDetails && base.setValue('salesPractice', {
			agentCode: dataAttributesExt?.complainSaleDetails.agentCode,
			agentName: dataAttributesExt?.complainSaleDetails.agentName,
			POSignedInProposal: dataAttributesExt?.complainSaleDetails.isPoSignedInProposal ? 'yes' : 'no',
			customerReceivedPolicyKit: dataAttributesExt?.complainSaleDetails.isCustomerReceiveKit ? 'yes' : 'no',
			customerReceivedDate: moment(dataAttributesExt?.complainSaleDetails.customerReceiveDate).toDate() ?? new Date(),
			financialNeedAnalysis: findFinance(dataAttributesExt?.complainSaleDetails.financeNeedCode)
		})

		dataAttributesExt?.conservationInfo && base.setValue('conservationInfomation', {
			conservationAgentCode: dataAttributesExt?.conservationInfo.conservationAgentCode,
			conservationAgentName: dataAttributesExt?.conservationInfo.conservationAgentName,
			conservationBonus: converOptions(bonusOptions).find((i) => i.value === dataAttributesExt?.conservationInfo?.conservationBonus) ?? null,
			conservationGAStaff: dataAttributesExt?.conservationInfo.conservationGAStaff ? true : false,
			conservationNote: dataAttributesExt?.conservationInfo.conservationNote,
			conservationResult: converOptions(resultOptions).find((i) => i.value === dataAttributesExt?.conservationInfo?.conservationResult) ?? null,
			conservationSolution: findResolution(dataAttributesExt?.conservationInfo.conservationSolution),
			reasonFullSurrender: findReason(dataAttributesExt?.conservationInfo.reasonFullSurrender),
			attachmentFiles: base.watch('conservationInfomation.attachmentFiles')
		})

		dataAttributesExt?.complaintGADetails && base.setValue('ga', {
			generalAgency: findAgency(dataAttributesExt?.complaintGADetails?.generalAgency) ?? null,
			personToBeComplained: dataAttributesExt?.complaintGADetails?.personComplaint
		})

		params?.groupCode && getGroupCode(params?.groupCode)
		params?.subTypeCode && onShowHideConservation(null, params?.subTypeCode)

		showGlobalLoading(false)
	}

	const findReceiptType = (receiptType: string) => {
		const findData = receiptTypeList?.find((x) => x.code === receiptType)
		return findData ? { value: findData.code, label: i18n.language === 'en' ? findData.nameEn : findData.name } : null
	}

	const findFinance = (finance: string) => {
		const findData = financialList?.find((x) => x.code === finance)
		return findData ? { value: findData.code, label: i18n.language === 'en' ? findData.nameEn : findData.name } : null
	}

	const findReason = (reason: string) => {
		const findData = fullSurrenderReasonList?.find((x) => x.reasonCode === reason)
		return findData ? { value: findData.reasonCode, label: i18n.language === 'en' ? findData.reasonEN : findData.reasonVN } : null
	}

	const findResolution = (resolution: string) => {
		const findData = resolutionList?.find((x) => x.resolutionCode === resolution)
		return findData ? { value: findData.resolutionCode, label: i18n.language === 'en' ? findData.resolutionEN : findData.resolutionVN } : null
	}

	const findAgency = (agency: string) => {
		const findData = generalAgencyList?.find((x) => x.code === agency)
		return findData ? { value: findData.code, label: i18n.language === 'en' ? findData.name : findData.nameVi } : null
	}

	const arrangeAndFilterGroupListByCagetory = (groupList: InquiryComplaintData.IACGroup[]) => {
		const inquiryList = groupList.filter((x) => x.categoryName === 'Inquiry')
		const complaintList = groupList.filter((x) => x.categoryName === 'Complaint')
		return [...inquiryList, ...complaintList]
	}

	const setAppointmentTime = (callbackHour: string) => {
    const currentDate = new Date
    let nextDay = ''
    let estimatedTime: moment.Moment = moment()
    let originSLAMinutes = Number(callbackHour) * 60
    let originEffectiveTime = moment(currentDate)
    for (let i = 0; i < workingTimeList.length && i + 1 < workingTimeList.length; i++) {
      // calculate in working time
      if (!!workingTimeList[i].startTime && !!workingTimeList[i].endTime) {
        const startDate = workingTimeList[i].date + ' ' + workingTimeList[i].startTime
        const endDate = workingTimeList[i].date + ' ' + workingTimeList[i].endTime
        // if startDate > originEffectiveTime, We set value variable originEffectiveTime to equal with startdate
        if (moment(startDate).valueOf() >= originEffectiveTime.valueOf()) {
          const newEffectiveValue = moment(startDate).valueOf() - originEffectiveTime.valueOf()
          originEffectiveTime = moment(originEffectiveTime.valueOf() + newEffectiveValue)
        }
        if (moment(endDate).valueOf() > originEffectiveTime.valueOf()) {
          const SLAHour = Math.floor(originSLAMinutes / 60)
          const SLAMinutes = Math.round((originSLAMinutes / 60 - SLAHour) * 60)
          estimatedTime = moment(originEffectiveTime).add(SLAHour, 'hours').add(SLAMinutes, 'minutes')
          // if estimatedTime less than endDate, we get valid estimatedTime and  break loop
          if (moment(endDate).valueOf() >= estimatedTime.valueOf()) {
            break
          }
          // else we get distanceMinutes and set originEffectiveTime = nextDay
          const distanceMinutes = moment(endDate).diff(originEffectiveTime, 'minutes')
          nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
          originEffectiveTime = moment(nextDay)
          originSLAMinutes = originSLAMinutes - distanceMinutes
        } else {
          // if originEffectiveTime > endDate, we will set originEffectiveTime = next working day
          if (workingTimeList[i + 1].startTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].startTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          } else if (workingTimeList[i + 1].endTime) {
            nextDay = workingTimeList[i + 1].date + ' ' + workingTimeList[i + 1].endTime
            originEffectiveTime =
              moment(nextDay).valueOf() > originEffectiveTime.valueOf() ? moment(nextDay) : originEffectiveTime
          }
        }
      }
    }
    const appointmentTime = estimatedTime.format('DD/MM/YYYY HH:mm:ss')
    base.setValue('generalInfo.callbackAppointmentTime', appointmentTime)
  }

	const handleDetailData = (policyNumber: string) => {
		pipe(
			ZIO.zipPar(
				InquiryComplaintService.getDetail(policyNumber),
				TopUpService.GetPolicyExtraInfo([policyNumber], ''),
				CancelRiderService.getRiderInfoList(),
				params?.id ? IBService.searchDraftTransactionById(params.id) : ZIO.succeed(undefined),
			),
			ZIO.map(([detail, policyExtraInfo, riders, data]) => {
				if (detail?.groupList) {
					setGroupList(arrangeAndFilterGroupListByCagetory(detail.groupList))
				}
				if (detail?.benList && detail.benList.length <= 0) {
					setRequestorRoleList(requestorRoleList.filter((x) => x.value !== '3'))
				}
				setDetailData({
					detail,
					mainProductCode: policyExtraInfo.policyExtraInfoDetail.find(
						(x) => x.lifeNo === '01' && x.coverageNo === '01' && x.riderNo === '00'
					)?.productCode,
					riders
				})
				if (detail.sourceList) {
					const so14Item = detail.sourceList.find((x) => x.code === 'SO14')
					base.setValue(
						'generalInfo.source',
						so14Item
							? {
								value: so14Item.code,
								label: i18n.language === 'en' ? so14Item.name : so14Item.nameVi
							}
							: null
					)
				}
				if (params?.requesterRoleCode === '2' && params?.requesterFullName) {
					getRelationshipListByLA(params?.requesterFullName, detail?.BORelationshipList, detail?.CBrelationshipList)
				}
				if (params?.requesterRoleCode === '3' && params?.requesterFullName) {
					getRelationshipListByBE(params?.requesterFullName, detail?.benList, detail?.BERelationshipList)
				}

				const complaintItem = detail?.groupList && detail?.groupList.find((x) => x.code === params?.groupCode)
				if (complaintItem?.categoryName === 'Inquiry') {
					getCategoryCode('CS')
				} else if (complaintItem?.categoryName === 'Complaint') {
					getCategoryCode('CH')
				}

				if (data) {
					data.payload?.documentUrls && data.payload?.documentUrls.map((item) => { getFileByUrl(item.url, item.type ?? '', !!data.attributesExt?.conservationInfo) })
					data.attributesExt &&
						setDataAttributesExt({
							...(data.attributesExt.complainMisMoneyDetails && { complainMisMoneyDetails: data.attributesExt.complainMisMoneyDetails }),
							...(data.attributesExt.complainSaleDetails && { complainSaleDetails: data.attributesExt.complainSaleDetails }),
							...(data.attributesExt.complaintGADetails && { complaintGADetails: data.attributesExt.complaintGADetails }),
							...(data.attributesExt.conservationInfo && { conservationInfo: data.attributesExt.conservationInfo })
						})
				}

				getAllListOption(!!data?.attributesExt ? data?.attributesExt : undefined)
				showGlobalLoading(false)
			}),
			ZIO.unsafeRun({})
		)
	}

	const getFileByUrl = (url: string, name: string, docConservationInfo: boolean) => {
		const container = url.slice(url.lastIndexOf('/') + 1)
		const apiUrl = url.slice(0, url.lastIndexOf('/'))
		return pipe(
			ZIO.zipPar(AuthService.token, AuthService.getLoginType),
			ZIO.flatMap(([token, loginType]) => {
				return ZIO.fromPromise(() =>
					fetch(apiUrl, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
							'X-Authen-Vendor': loginType,
							container: container,
							blob: name
						}
					})
				)
			}),
			ZIO.flatMap((res) =>
				ZIO.zipPar(
					ZIO.succeed(res),
					ZIO.fromPromise(() => res.blob())
				)
			),
			ZIO.map(([res, blob]) => {
				let reader = new FileReader();
				const updateDate = params?.updatedDate ? PulseOpsFormat.timeStrToDate(params?.updatedDate) ?? new Date() :
					params?.createdDate ? PulseOpsFormat.timeStrToDate(params?.createdDate) ?? new Date() : new Date()
				reader.readAsDataURL(blob)
				reader.onloadend = function () {
					const base64String = reader.result
					const blobFile = base64String ? new Blob([base64String]) : null
					let fileUpload = new File(blobFile ? [blobFile] : [], name.split('/')[1] ?? '')
					
					let file: FileUploadData = {
						fileName: name.split('/')[1] ?? '',
						fileExtension: blob.type.split('/')[1] ?? blob.type,
						size: blob.size,
						base64: base64String ? base64String.toString() : '',
						uploadedDate: updateDate,
						file: fileUpload
					}
					let fileList = docConservationInfo ? base.watch('conservationInfomation.attachmentFiles') : base.watch('communicationInfo.attachmentFiles')
					base64String && fileList.push(file)
					if (fileList.length > 0 && base64String) {
						if (docConservationInfo) {
							base.setValue('conservationInfomation.attachmentFiles', fileList)
						}
						else {
							base.setValue('communicationInfo.attachmentFiles', fileList)
						}
					}
				}
			}),
			ErrorHandling.run()
		)
	}

	const getAllListOption = (attributesExtData?: DataAttributesExt) => {
		pipe(
			ZIO.zipPar(
				params?.groupCode ? InquiryComplaintService.getTypes(params?.groupCode) : ZIO.succeed([]),
				params?.typeCode ? InquiryComplaintService.getSubType(params?.typeCode) : ZIO.succeed([]),
				attributesExtData?.complainMisMoneyDetails ? InquiryComplaintService.getT22Data('receipt-type') : ZIO.succeed([]),
				attributesExtData?.complainSaleDetails?.financeNeedCode ? InquiryComplaintService.getT22Data('fna') : ZIO.succeed([]),
				attributesExtData?.conservationInfo?.reasonFullSurrender ? InquiryComplaintService.getFullSurrenderReasonList() : ZIO.succeed([]),
				attributesExtData?.conservationInfo?.conservationSolution ? InquiryComplaintService.getResolutionList() : ZIO.succeed([]),
				attributesExtData?.complaintGADetails?.generalAgency ? InquiryComplaintService.getGeneralAgency() : ZIO.succeed([])
			),
			ZIO.map(([groupList, typeList, receiptTypes, financials, reasons, resolutions, agency]) => {
				if (groupList && groupList.length > 0) {
					setSubServiceTypeList(groupList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
				} else {
					setSubServiceTypeList([])
				}

				if (typeList && typeList.length > 0) {
					setSubTransactionTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
				} else {
					setSubTransactionTypeList([])
				}

				if (receiptTypes && receiptTypes.length > 0) {
					setReceiptTypeList(receiptTypes)
				}

				if (financials && financials.length > 0) {
					setFinancialList(financials)
				}

				if (reasons && reasons.length > 0) {
					setFullSurrenderReasonList(reasons)
				}

				if (resolutions && resolutions.length > 0) {
					setResolutionList(resolutions)
				}

				if (agency && agency.length > 0) {
					setGeneralAgencyList(agency)
				}

				setIsLoadOptionDone(true)
			}),
			ZIO.catchAll((error) => {
				return ZIO.fail(null)
			}),
			bindLoader,
			ZIO.unsafeRun({})
		)
	}

	const compareItemsByName = (item1: any, item2: any) => {
		return i18n.language === 'en' ? item1.name.localeCompare(item2.name) : item1.nameVi.localeCompare(item2.nameVi)
	}

	const getSubServiceTypeList = (groupCode: string) => {
		if (groupCode) {
			pipe(
				InquiryComplaintService.getTypes(groupCode),
				ZIO.map((groupList) => {
					if (groupList && groupList.length > 0) {
						setSubServiceTypeList(groupList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
					} else {
						setSubServiceTypeList([])
					}
				}),
				ZIO.catchAll((error) => {
					return ZIO.fail(null)
				}),
				bindLoader,
				ZIO.unsafeRun({})
			)
		}
	}

	const onShowConservation = (value: boolean) => {
		setShowConservation(value)
		setAgentCodeError('')
	}

	const onShowHideConservation = (e: SelectOption | null, subTypeCode?: string) => {
		if (e?.value === 'CS2701' || subTypeCode === 'CS2701') {
			onShowConservation(true)
			base.setValue('generalInfo.hot', false)
			setDisabledHot(true)
			base.setValue('generalInfo.firstContactResolution', true)
			setDisabledFCR(true)
		} else {
			onShowConservation(false)
			base.setValue('generalInfo.hot', false)
			setDisabledHot(false)
			base.setValue('generalInfo.firstContactResolution', false)
			// setDisabledFCR(false)
		}
	}

	const onResetValue = (value: string) => {
		base.clearErrors()
		base.setValue('communicationInfo.customerComments', '')
		base.setValue('communicationInfo.responsesToCustomer', '')
		base.setValue('communicationInfo.attachmentFiles', [])

		base.setValue('conservationInfomation.attachmentFiles', [])
		base.setValue('conservationInfomation.conservationAgentCode', '')
		base.setValue('conservationInfomation.conservationAgentName', '')
		base.setValue('conservationInfomation.conservationBonus', null)
		base.setValue('conservationInfomation.conservationGAStaff', false)
		base.setValue('conservationInfomation.conservationNote', '')
		base.setValue('conservationInfomation.conservationResult', null)
		base.setValue('conservationInfomation.conservationSolution', null)
		base.setValue('conservationInfomation.reasonFullSurrender', null)
		if (value !== 'CHG4') {
			base.setValue('ga.generalAgency', null)
			base.setValue('ga.personToBeComplained', '')
		}
		if (value !== 'CHG3') {
			base.setValue('salesPractice.agentCode', '')
			base.setValue('salesPractice.agentName', '')
			base.setValue('salesPractice.POSignedInProposal', 'yes')
			base.setValue('salesPractice.customerReceivedDate', new Date())
			base.setValue('salesPractice.customerReceivedPolicyKit', 'yes')
			base.setValue('salesPractice.financialNeedAnalysis', null)
		}
		if (value !== 'CHG2') {
			base.setValue('moneyMissappropriation.list', [
				{
					receipt: 'no',
					receiptType: null,
					receiptNumber: '',
					complaintAmount: '',
					benefit_PremiumMisappropriatedDate: new Date()
				}
			])
			base.setValue('moneyMissappropriation.totalComplaintAmount', '0')
		}
	}

	const onGroupChange = (e: SelectOption | null) => {
		setSubServiceTypeList([])
		setSubTransactionTypeList([])
		getGroupCode(e?.value || '')
		getSubServiceTypeList(e?.value || '')
		base.setValue('generalInfo.type', null)
		base.setValue('generalInfo.subType', null)
		onResetValue(e?.value || '')
		onShowHideConservation(null)
		const complaintItem = groupList.find((x) => x.code === e?.value)
		if (
			complaintItem?.categoryName === 'Complaint' ||
			(complaintItem?.categoryName === 'Inquiry' && accountLogIn === 'Customer')
		) {
			base.setValue('generalInfo.firstContactResolution', false)
			setDisabledFCR(true)
		} else {
			setDisabledFCR(false)
			base.setValue('generalInfo.firstContactResolution', false)
		}
		if (complaintItem?.categoryName === 'Inquiry') {
			getCategoryCode('CS')
		} else {
			getCategoryCode('CH')
		}
	}

	const onTypeChange = (e: SelectOption | null) => {
		base.clearErrors('generalInfo.subType')
		base.setValue('generalInfo.subType', null)
		if (
			base.watch('generalInfo.group')?.value === 'CHG1' ||
			base.watch('generalInfo.group')?.value === 'CHG2' ||
			base.watch('generalInfo.group')?.value === 'CHG3' ||
			base.watch('generalInfo.group')?.value === 'CHG4'
		) {
			setDisabledFCR(true)
			base.setValue('generalInfo.firstContactResolution', false)
		} else {
			setDisabledFCR(false)
		}
		setSubTransactionTypeList([])
		onShowHideConservation(null)
		onResetValue(e?.value || '')
		if (!!e) {
			getSubTransactionTypeList(e?.value)
		}
	}

	const getSubTransactionTypeList = (typeCode: string) => {
		pipe(
			InquiryComplaintService.getSubType(typeCode),
			ZIO.map((typeList) => {
				if (typeList && typeList.length > 0) {
					setSubTransactionTypeList(typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2)))
				} else {
					setSubTransactionTypeList([])
				}
			}),
			ZIO.catchAll((error) => {
				return ZIO.fail(null)
			}),
			bindLoader,
			ZIO.unsafeRun({})
		)
	}

	const onSubTypeChange = (e: SelectOption | null) => {
		onShowHideConservation(e)
		onResetValue(e?.value || '')
	}

	const onChangeRequestRole = (e: any): void => {
		base.clearErrors([
			'generalInfo.relationshipWithPolicyOwner',
			'generalInfo.requestorFullName1',
			'generalInfo.requestorFullName'
		])
		if (!!e) {
			switch (e.value) {
				case '1':
					base.setValue(
						'generalInfo.requestorFullName',
						params?.poName ? params?.poName : detailData?.detail?.poName || ''
					)
					base.setValue('generalInfo.relationshipWithPolicyOwner', null)
					base.clearErrors('generalInfo.relationshipWithPolicyOwner')
					setDisabledFullname(true)
					setDisabledRPO(true)
					setRelationshipList([])
					break
				case '2':
					base.setValue('generalInfo.requestorFullName1', null)
					base.setValue('generalInfo.relationshipWithPolicyOwner', null)
					base.clearErrors('generalInfo.relationshipWithPolicyOwner')
					setDisabledFullname(false)
					setDisabledRPO(true)
					setRelationshipList([])
					break
				case '3':
					base.setValue('generalInfo.requestorFullName1', null)
					base.setValue('generalInfo.relationshipWithPolicyOwner', null)
					base.clearErrors('generalInfo.relationshipWithPolicyOwner')
					setDisabledFullname(false)
					setDisabledRPO(true)
					setRelationshipList([])
					break
				case '4':
					base.setValue('generalInfo.requestorFullName', '')
					base.setValue('generalInfo.relationshipWithPolicyOwner', null)
					setDisabledFullname(false)
					setDisabledRPO(false)
					if (detailData?.detail?.CBrelationshipList) {
						setRelationshipList(
							detailData?.detail?.CBrelationshipList.map((relationshipItem) => {
								return {
									code: relationshipItem.code,
									nameVi: relationshipItem.name,
									name: relationshipItem.nameEn,
									parentCode: ''
								}
							})
						)
					} else {
						setRelationshipList([])
					}
					break
			}
		} else {
			base.setValue('generalInfo.requestorFullName', '')
			base.setValue('generalInfo.requestorFullName1', null)
			base.setValue('generalInfo.relationshipWithPolicyOwner', null)
			setDisabledFullname(false)
			setDisabledRPO(false)
			setRelationshipList([])
		}
	}

	const getRelationshipListByLA = (clientNumberOfLA: string, BORelationshipList?: InquiryComplaintData.BORelationshipList[], CBrelationshipList?: InquiryComplaintData.CBRelationshipList[]) => {
		setRelationshipList([])
		if (BORelationshipList && BORelationshipList.length > 0) {
			const relationships: InquiryComplaintConst.Group[] = []
			let clientNumInfo = BORelationshipList.find((x) => x.relationship === clientNumberOfLA)
			const relationshipFilterItem = CBrelationshipList && CBrelationshipList.find(
				(p) => p.code === clientNumInfo?.clientNum
			)
			if (relationshipFilterItem) {
				relationships.push({
					code: relationshipFilterItem?.code ?? '',
					nameVi: relationshipFilterItem?.name ?? '',
					name: relationshipFilterItem?.nameEn ?? '',
					parentCode: ''
				})
				base.setValue('generalInfo.relationshipWithPolicyOwner', {
					value: relationshipFilterItem?.code ?? '',
					label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
				})
				setRelationshipList(relationships)
			}
		}
	}


	const getRelationshipListByBE = (clientNumberOfBen: string, benList?: InquiryComplaintData.ICbenInfo[], BERelationshipList?: InquiryComplaintData.BERelationshipData[]) => {
		setRelationshipList([])
		if (benList && benList.length > 0 && BERelationshipList && BERelationshipList.length > 0) {
			const relationships: InquiryComplaintConst.Group[] = []
			const benItem = benList.find((x) => x.clientNumberOfBen === clientNumberOfBen)
			const relationshipFilterItem = BERelationshipList.find(
				(p) => p.code === benItem?.relationShipCode
			)
			if (relationshipFilterItem) {
				relationships.push({
					code: relationshipFilterItem?.code ?? '',
					nameVi: relationshipFilterItem?.name ?? '',
					name: relationshipFilterItem?.nameEn ?? '',
					parentCode: ''
				})
				base.setValue('generalInfo.relationshipWithPolicyOwner', {
					value: relationshipFilterItem?.code ?? '',
					label: i18n.language === 'en' ? relationshipFilterItem?.nameEn ?? '' : relationshipFilterItem?.name ?? ''
				})
			}
			setRelationshipList(relationships)
		}
	}
	const onChangeRequestorFullName = (e: SelectOption | null) => {
		base.clearErrors(['generalInfo.relationshipWithPolicyOwner'])
		if (!!e) {
			const requestInfoRole = base.getValues('generalInfo.requestorRole')?.value
			base.setValue('generalInfo.relationshipWithPolicyOwner', null)
			if (requestInfoRole) {
				if (requestInfoRole === '2') {
					e?.value && getRelationshipListByLA(e?.value, detailData?.detail?.BORelationshipList, detailData?.detail?.CBrelationshipList)
				}
				if (requestInfoRole === '3') {
					e?.value && getRelationshipListByBE(e?.value, detailData?.detail?.benList, detailData?.detail?.BERelationshipList)
				}
			}
		} else {
			setRelationshipList([])
			base.setValue('generalInfo.relationshipWithPolicyOwner', null)
		}
	}

	const onChangeMethodOrSource = () => {
		const method = base.watch('generalInfo.method')?.value ?? ''
		const source = base.watch('generalInfo.source')?.value ?? ''
		if (listMethodShowCallID.includes(method) && source === 'SO14') {
			base.setValue('generalInfo.isRequireCallId', true)
		}
		else {
			base.setValue('generalInfo.isRequireCallId', false)
			base.clearErrors('generalInfo.callId')
		}
	}

	const onReset = () => {
		base.reset({
			generalInfo: {
				group: null,
				type: null,
				subType: null,
				requestorRole: null,
				requestorFullName: '',
				requestorFullName1: null,
				relationshipWithPolicyOwner: null,
				requestorNationalID: '',
				method: null,
				source: null,
				firstContactResolution: false,
				hot: false,
				callId: '',
				isRequireCallId: false
			},
			ga: {
				generalAgency: null,
				personToBeComplained: ''
			},
			salesPractice: {
				agentCode: '',
				agentName: '',
				POSignedInProposal: 'yes',
				customerReceivedPolicyKit: 'yes',
				customerReceivedDate: new Date(),
				financialNeedAnalysis: null
			},
			communicationInfo: {
				customerComments: '',
				responsesToCustomer: '',
				attachmentFiles: []
			},
			moneyMissappropriation: {
				list: [
					{
						receipt: 'no',
						receiptType: null,
						complaintAmount: '',
						receiptNumber: '',
						benefit_PremiumMisappropriatedDate: new Date()
					}
				],
				totalComplaintAmount: '0'
			},
			conservationInfomation: {
				conservationAgentCode: '',
				conservationAgentName: '',
				conservationBonus: null,
				conservationGAStaff: false,
				conservationNote: '',
				conservationResult: null,
				conservationSolution: null,
				reasonFullSurrender: null,
				attachmentFiles: []
			},
			isSubmited: false
		})
		setReset((prevState) => !prevState)
		setGroupCode('')
		setAgentCodeError('')
		setShowConservation(false)
	}

	const onSubmit = () => {
		if (isShowConservation) {
			if (
				base.watch('conservationInfomation.conservationResult')?.value === 'FAIL' &&
				!base.watch('conservationInfomation.conservationGAStaff')
			) {
				base
					.trigger([
						'generalInfo',
						'conservationInfomation.conservationGAStaff',
						'conservationInfomation.conservationAgentCode',
						'conservationInfomation.conservationAgentName',
						'conservationInfomation.conservationResult',
						'conservationInfomation.conservationSolution',
						'conservationInfomation.reasonFullSurrender',
						'conservationInfomation.attachmentFiles'
					])
					.then((isValid) => {
						if (isValid) {
							handleSubmited()
						}
					})
			} else {
				base.trigger(['generalInfo', 'conservationInfomation']).then((isValid) => {
					if (isValid && !agentCodeError) {
						handleSubmited()
					}
				})
			}
		} else {
			if (groupCode === 'CHG2') {
				base.trigger(['generalInfo', 'moneyMissappropriation', 'communicationInfo']).then((isValid) => {
					if (isValid) {
						handleSubmited()
					}
				})
			} else if (groupCode === 'CHG3') {
				base.trigger(['generalInfo', 'salesPractice', 'communicationInfo']).then((isValid) => {
					if (isValid && !agentCodeError) {
						handleSubmited()
					}
				})
				base.trigger('salesPractice')
			} else if (groupCode === 'CHG4') {
				base.trigger(['generalInfo', 'ga', 'communicationInfo.customerComments', 'communicationInfo.responsesToCustomer']).then((isValid) => {
					if (isValid) {
						handleSubmited()
					}
				})
			} else {
				base.trigger(['generalInfo', 'communicationInfo.customerComments', 'communicationInfo.responsesToCustomer']).then(async (isValid) => {
					if (isValid) {
						handleSubmited()
					}
				})
			}
		}
	}

	const onSave = () => {
		if(isShowConservation) {
			base.trigger('generalInfo').then((isValid) => {
				if (isValid && !agentCodeError) {
					handleSaveDraft()
				}
			})
		}
		else {
			base.trigger(['generalInfo', 'communicationInfo.customerComments']).then((isValid) => {
				if (isValid && !agentCodeError) {
					handleSaveDraft()
				}
			})
		}
	}

	const onCancel = () => {
		const data = mappingData().saveDraftData
		data.status = 'DELETED'
		if (data) {
			showGlobalLoading(true)
			pipe(
				ZIO.effect(() => getAttachmentFiles()),
				ZIO.flatMap((attachmentFilesInfo) => {
					return pipe(
						IBService.saveDraft(data as SaveDraft, attachmentFilesInfo),
						ZIO.map((response) => {
							showToast(t('message:IB0014'), 'success')
							onReset()
							showGlobalLoading(false)
							setShowCancelPopup(false)
							props.navigation.navigate('IBDraftTransaction')
							return ZIO.unit
						})
					)
				}),
				ZIO.catchAll((error: any) => {
					showGlobalLoading(false)
					setShowCancelPopup(false)
					showToast(t('message:IB0013'), 'error')
					return ZIO.fail(null)
				}),
				ZIO.unsafeRun({})
			)
		}
	}

	const mappingData = () => {
		const { generalInfo, communicationInfo, conservationInfomation, ga, moneyMissappropriation, salesPractice } =
			base.getValues()

		let COMPLAINTMISMONEYDETAILS: InquiryComplaintData.COMPLAINTMISMONEYDETAILS[] = []
		let COMPLAINTSALEDETAILS: InquiryComplaintData.COMPLAINTSALEDETAILS[] = []
		let COMPLAINTGADETAILS: InquiryComplaintData.COMPLAINTGADETAILS[] = []
		let CONSERVATIONINFO: InquiryComplaintData.CONSERVATIONINFO | null = null
		const hot = generalInfo.hot ? true : false
		const fcr = generalInfo.firstContactResolution ? true : false
		const totalComplaintAmount =
			groupCode === 'CHG2'
				? moneyMissappropriation.totalComplaintAmount
					? PulseOpsFormat.thousandSepartorReverse(moneyMissappropriation.totalComplaintAmount)
					: 0
				: 0
		const groupItem =
			detailData?.detail?.groupList && detailData?.detail.groupList.find((x) => x.code === generalInfo.group?.value)
		const categoryName =
			groupItem?.categoryName === 'Complaint' ? 'COMPLAINT' : groupItem?.categoryName === 'Inquiry' ? 'INQUIRY' : ''
		let touchPoint =
			detailData?.detail?.sourceList &&
			detailData?.detail.sourceList.find((x) => x.code === generalInfo.source?.value)?.touchPoint
		touchPoint = !!touchPoint ? touchPoint : ''

		if (groupCode === 'CHG2') {
			COMPLAINTMISMONEYDETAILS = moneyMissappropriation.list.map((controlItem) => {
				const misPremiumDate = moment(controlItem.benefit_PremiumMisappropriatedDate).format('YYYYMMDD')
				const Moneyitem: InquiryComplaintData.COMPLAINTMISMONEYDETAILS = {
					hasReciept: controlItem.receipt,
					recieptType: _.get(controlItem, 'receiptType.value') || '',
					recieptNum: _.get(controlItem, 'receiptNumber') || '',
					complaintAmount: controlItem.complaintAmount
						? PulseOpsFormat.thousandSepartorReverse(controlItem.complaintAmount)
						: 0,
					misPremiumDate: misPremiumDate
				}
				return Moneyitem
			})
		}

		if (groupCode === 'CHG3') {
			const isPoSignedInProposal = salesPractice.POSignedInProposal === 'yes' ? true : false
			const isCustomerReceiveKit = salesPractice.customerReceivedPolicyKit === 'yes' ? true : false
			const customerReceiveDate = moment(salesPractice.customerReceivedDate).format('YYYYMMDD')
			const saleItem: InquiryComplaintData.COMPLAINTSALEDETAILS = {
				agentCode: salesPractice.agentCode || '',
				agentName: salesPractice.agentName || '',
				financeNeedCode: salesPractice.financialNeedAnalysis?.value || '',
				isPoSignedInProposal: isPoSignedInProposal,
				isCustomerReceiveKit: isCustomerReceiveKit,
				customerReceiveDate: customerReceiveDate
			}
			COMPLAINTSALEDETAILS.push(saleItem)
		}

		if (groupCode === 'CHG4') {
			const gaItem: InquiryComplaintData.COMPLAINTGADETAILS = {
				generalAgency: ga.generalAgency?.value || '',
				personComplaint: ga.personToBeComplained
			}
			COMPLAINTGADETAILS.push(gaItem)
		}

		if (isShowConservation) {
			let mainProductName: string = ''

			if (detailData?.detail && detailData?.riders && detailData?.mainProductCode) {
				const mainRider = detailData?.riders.find((r) => r.code === detailData?.mainProductCode)
				mainProductName = mainRider ? (i18n.language === 'en' ? mainRider.name : mainRider.nameVi) : ''
			}
			CONSERVATIONINFO = {
				product: mainProductName || '',
				servicingAgentCode: detailData?.detail?.agentCode || '',
				servicingAgentName: detailData?.detail?.agentName || '',
				conservationGAStaff: conservationInfomation.conservationGAStaff ? userInfo.name : '',
				conservationAgentCode: _.get(conservationInfomation, 'conservationAgentCode') || '',
				conservationAgentName: _.get(conservationInfomation, 'conservationAgentName') || '',
				reasonFullSurrender: conservationInfomation.reasonFullSurrender?.value || '',
				conservationResult: conservationInfomation.conservationResult?.value || '',
				conservationSolution: conservationInfomation.conservationSolution?.value || '',
				conservationBonus: _.get(conservationInfomation, 'conservationBonus.value') || '',
				conservationNote: conservationInfomation.conservationNote
			}
		}

		let requestorFullName = ''

		if (generalInfo.requestorRole?.value) {
			if (generalInfo.requestorRole.value === '1' || generalInfo.requestorRole.value === '4') {
				requestorFullName = _.get(generalInfo, 'requestorFullName')
			} else {
				requestorFullName = _.get(generalInfo, 'requestorFullName1.value') || ''
			}
		}

		const conditionAddCbt = (base.watch('generalInfo.group')?.value.startsWith('CS') && !base.watch('generalInfo.firstContactResolution'))

		const submitData: InquiryComplaintData.SubmitData = {
			CATEGORY_CODE: categoryName,
			GROUP_CODE: generalInfo.group?.value || '',
			SUB_SVC_TYPE_CODE: generalInfo.type?.value || '',
			SUB_TRANS_TYPE_CODE: generalInfo.subType?.value || '',
			REQUEST_WITH_ROLE: generalInfo.requestorRole?.value || '',
			REQUESTER_FULL_NAME: requestorFullName,
			REQUESTER_NATIONAL_ID: generalInfo.requestorNationalID,
			RLA_WITH_PO: _.get(generalInfo, 'relationshipWithPolicyOwner.value') || '',
			METHOD: generalInfo.method?.value || '',
			SOURCE: generalInfo.source?.value || '',
			TOUCH_POINT: touchPoint,
			HOT: hot,
			FCR: fcr,
			CUSTOMER_CMT: communicationInfo.customerComments || '',
			RES_TO_CUSTOMER: communicationInfo.responsesToCustomer || '',
			TOTAL_MIS_MONEY_AMT: totalComplaintAmount,
			COMPLAINT_MIS_MONEY_DETAILS: COMPLAINTMISMONEYDETAILS,
			COMPLAINT_SALE_DETAILS: COMPLAINTSALEDETAILS,
			COMPLAINT_GA_DETAILS: COMPLAINTGADETAILS,
			CONSERVATION_INFO: CONSERVATIONINFO ?? {},
			CALL_ID: base.watch('generalInfo.source')?.value === 'SO14' ? generalInfo?.callId : '',
			CALL_BACK_LATER_HOUR: conditionAddCbt && generalInfo.callBackLaterHour ? Number(generalInfo.callBackLaterHour?.value) : undefined,
      CALLBACK_APPOINTMENT_TIME: conditionAddCbt && generalInfo.callbackAppointmentTime ? moment(generalInfo.callbackAppointmentTime, 'DD/MM/YYYY HH:mm:ss').toISOString() : undefined
		}

		const saveDraftData: SaveDraft = {
			id: params?.id ?? '',
			policyNo: params?.policyNo ?? '',
			clientNo: params?.clientNo ?? '',
			payload: {
				groupCode: generalInfo.group?.value || '',
				typeCode: generalInfo.type?.value || '',
				subTypeCode: generalInfo.subType?.value || '',
				requesterRoleCode: generalInfo.requestorRole?.value || '',
				requesterFullName: requestorFullName,
				relationshipWithPO: _.get(generalInfo, 'relationshipWithPolicyOwner.value') || '',
				requesterNationalId: generalInfo.requestorNationalID,
				method: generalInfo.method?.value || '',
				source: generalInfo.source?.value || '',
				callId: base.watch('generalInfo.source')?.value === 'SO14' ? generalInfo?.callId : '',
				hadFirstContactResolution: fcr,
				customerComment: communicationInfo.customerComments || '',
				responseToCustomer: communicationInfo.responsesToCustomer || '',
				documentUrls: [],
				isUrgent: hot,
				poName: params?.poName ?? '',
				callBackLaterHour: conditionAddCbt && generalInfo.callBackLaterHour ? Number(generalInfo.callBackLaterHour?.value) : undefined,
        callbackAppointmentTime: conditionAddCbt && generalInfo.callbackAppointmentTime ? moment(generalInfo.callbackAppointmentTime, 'DD/MM/YYYY HH:mm:ss').toISOString() : undefined
			},
			transactionType: categoryName,
			attributesExt: {
				complainMisMoneyDetails: {
					list: COMPLAINTMISMONEYDETAILS,
					totalComplaintAmount: totalComplaintAmount
				},
				complainSaleDetails: COMPLAINTSALEDETAILS[0] ?? undefined,
				complaintGADetails: COMPLAINTGADETAILS[0] ?? undefined,
				conservationInfo: CONSERVATIONINFO ?? undefined
			},
			updateVersion: params?.updateVersion ?? undefined
		}

		return ({
			saveDraftData,
			submitData
		})
	}

	const getAttachmentFiles = () => {
		const { generalInfo, communicationInfo, conservationInfomation } = base.getValues()
		// let files: StorageBlob.FileContent[] = []
		const groupItem =
			detailData?.detail?.groupList && detailData?.detail.groupList.find((x) => x.code === generalInfo.group?.value)
		const categoryName =
			groupItem?.categoryName === 'Complaint'
				? TransactionType.COMPLAINT
				: groupItem?.categoryName === 'Inquiry'
					? TransactionType.INQUIRY
					: ''
		let docTypeCode = categoryName === TransactionType.COMPLAINT ? 'DCH02' : 'DCH05'

		let attachmentFilesControl = communicationInfo.attachmentFiles
		if (isShowConservation) {
			attachmentFilesControl = conservationInfomation.attachmentFiles
			docTypeCode = 'DCS01'
		}

		let uploadedFileItem: UploadedFilesInfo = {
			uploadFiles: attachmentFilesControl as ComplainInquiryForm.File[],
			transactionType: categoryName as TransactionType,
			docTypeCode: docTypeCode,
			category: categoryCode,
			policyNumber: params?.policyNo ?? '',
			officeCode: 'CCE'
		}
		return uploadedFileItem
	}

	const handleSubmited = () => {
		const inquiryData = mappingData().submitData
		const saveData = mappingData().saveDraftData
		saveData.status = 'DELETED'
		if (!base.watch('isSubmited')) {
			base.setValue('isSubmited', true)
			showGlobalLoading(true)
			pipe(
				ZIO.effect(() => getAttachmentFiles()),
				ZIO.flatMap((attachmentFilesInfo) => {
					return pipe(
						IBService.saveDraft(saveData as SaveDraft, attachmentFilesInfo),
						ZIO.flatMap((res) => {
							return pipe(
								InquiryComplaintService.submitData(
									!!params?.policyNo ? params?.policyNo : '',
									inquiryData as InquiryComplaintData.SubmitData,
									[],
									'CCE',
									attachmentFilesInfo,
									SourceType.INBOUND
								)
							)
						})
					)
				}),	
				ZIO.map((response) => {
					showToast(t('message:MS040011'), 'success')
					base.setValue('isSubmited', false)
					onReset()
					showGlobalLoading(false)
					props.navigation.navigate('IBDraftTransaction')
					return ZIO.unit
				}),				
				ZIO.catchAll((error: any) => {
					const errorCode = error?.source?.response?.data?.code
					if(errorCode === 'ENTITY_LOCK') {
						showToast(t('message:IB0013'), 'error')
					}
					else {
						showToast(t('message:MS050001'), 'error')
					}
					base.setValue('isSubmited', false)
					showGlobalLoading(false)
					return ZIO.fail(null)
				}),
				ZIO.unsafeRun({})
			)
		}
	}

	const handleSaveDraft = () => {
		const data = mappingData().saveDraftData
		if (!base.watch('isSubmited')) {
			base.setValue('isSubmited', true)
			showGlobalLoading(true)
			pipe(
				ZIO.effect(() => getAttachmentFiles()),
				ZIO.flatMap((attachmentFilesInfo) => {
					return pipe(
						IBService.saveDraft(data as SaveDraft, attachmentFilesInfo),
						ZIO.map((response) => {
							showToast(t('message:MS100003'), 'success')
							base.setValue('isSubmited', false)
							onReset()
							showGlobalLoading(false)
							props.navigation.navigate('IBDraftTransaction')
							return ZIO.unit
						})
					)
				}),
				ZIO.catchAll((error: any) => {
					const errorCode = error?.source?.response?.data?.code
					if(errorCode === 'ENTITY_LOCK') {
						showToast(t('message:IB0013'), 'error')
					}
					else {
						showToast(t('message:MS050001'), 'error')
					}
					base.setValue('isSubmited', false)
					showGlobalLoading(false)
					return ZIO.fail(null)
				}),
				ZIO.unsafeRun({})
			)
		}
	}

	return (
		<ScrollView style={{ paddingVertical: 20 }}>
			<View style={[IBStyles.container, { marginHorizontal: 25, marginBottom: 20 }]}>
				<View style={IBStyles.row}>
					<View style={IBStyles.sectionCol6}>
						<Text style={{ fontSize: 15, fontWeight: '400' }}>{t('common:PolicyNumber')}</Text>
						<Text style={{ color: 'red', fontWeight: '600', fontSize: 15 }}>{params?.policyNo ?? '-'}</Text>
					</View>
					<View style={IBStyles.sectionCol6}>
						<Text style={{ fontSize: 15, fontWeight: '400' }}>{t('submission:PolicyOn')}</Text>
						<Text style={{ color: 'red', fontWeight: '600', fontSize: 15 }}>{params?.poName ?? '-'}</Text>
					</View>
				</View>
			</View>
			<View style={[IBStyles.container, { marginHorizontal: 25, marginBottom: 10 }]}>
				<IBGeneralField
					FieldForm={base}
					col={3}
					typeInput={[
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.group',
							title: t('requestInfo:Group'),
							option: converOptions(groupList),
							required: true,
							handleData: (value) => {
								onGroupChange(value)
							}
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.type',
							title: t('requestInfo:Type'),
							option: converOptions(subServiceTypeList || []),
							required: true,
							handleData: (value) => {
								onTypeChange(value)
							}
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.subType',
							title: t('requestInfo:SubType'),
							option: converOptions(subTransactionTypeList || []),
							required: true,
							handleData: (value) => {
								onSubTypeChange(value)
							}
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.requestorRole',
							title: t('requestInfo:RequestorRole'),
							option: requestorRoleList,
							required: true,
							handleData: (value) => {
								onChangeRequestRole(value)
							}
						},
						{
							type: TypeInputComponent.INPUT,
							formName: 'generalInfo.requestorFullName',
							title: t('requestInfo:RequestorFullname'),
							inputType: 'input',
							disabled: isDisabledFullname,
							hidden: ['2', '3'].includes(base.watch('generalInfo.requestorRole')?.value || '')
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.requestorFullName1',
							title: t('requestInfo:RequestorFullname'),
							option:
								(detailData?.detail?.laList &&
									detailData?.detail.laList
										// .filter((la) => la.clientNumberOfLA !== detail?.clientId)
										.map((item) => ({
											label: item.clientName,
											value: item.clientNumberOfLA
										}))) ||
								[],
							required: true,
							disabled: isDisabledFullname,
							hidden: !(base.watch('generalInfo.requestorRole')?.value === '2'),
							handleData: (value) => {
								onChangeRequestorFullName(value)
							}
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.requestorFullName1',
							title: t('requestInfo:RequestorFullname'),
							option:
								(detailData?.detail?.benList &&
									detailData?.detail.benList.map((item) => ({
										label: item.clientName,
										value: item.clientNumberOfBen
									}))) ||
								[],
							required: true,
							disabled: isDisabledFullname,
							hidden: !(base.watch('generalInfo.requestorRole')?.value === '3'),
							handleData: (value) => {
								onChangeRequestorFullName(value)
							}
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.relationshipWithPolicyOwner',
							title: t('requestInfo:RelationshipWithPolicyOwner'),
							option: converOptions(relationshipList),
							required: !isDisabledRPO,
							disabled: isDisabledRPO
						},
						{
							type: TypeInputComponent.INPUT,
							formName: 'generalInfo.requestorNationalID',
							title: t('requestInfo:RequestorNationalID'),
							inputType: 'input'
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.method',
							title: t('requestInfo:Method'),
							option: converOptions(detailData?.detail?.methodList || []),
							handleData: () => onChangeMethodOrSource(),
							required: true
						},
						{
							type: TypeInputComponent.SELECT,
							formName: 'generalInfo.source',
							title: t('requestInfo:Source'),
							option: converOptions(detailData?.detail?.sourceList || []),
							handleData: () => onChangeMethodOrSource(),
							required: true
						},
						{
							type: TypeInputComponent.INPUT,
							formName: 'generalInfo.callId',
							title: t('requestInfo:CallId'),
							inputType: 'number',
							required: base.watch('generalInfo.isRequireCallId'),
							hidden: base.watch('generalInfo.source')?.value !== 'SO14',
							maxLength: 20
						},
						{
							type: TypeInputComponent.CHECKBOX,
							formName: 'generalInfo.hot',
							title: t('requestInfo:Hot'),
							disabled: isDisabledHot
						},
						{
							type: TypeInputComponent.CHECKBOX,
							formName: 'generalInfo.firstContactResolution',
							title: t('requestInfo:FirstContactResolution'),
							disabled: isDisabledFCR
						},
						{
              type: TypeInputComponent.SELECT,
              formName: 'generalInfo.callBackLaterHour',
              title: t('requestInfo:CallBackLaterHour'),
              option: listHoursCallmeBack.map((hour) => ({ value: hour, label: hour })),
              handleData: (hour) => {hour && setAppointmentTime(hour.value)},
							onClear: (value) => { base.setValue('generalInfo.callbackAppointmentTime', '')},
              hidden: !(base.watch('generalInfo.group')?.value.startsWith('CS') && !base.watch('generalInfo.firstContactResolution'))
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'generalInfo.callbackAppointmentTime',
              title: t('requestInfo:CallbackAppointmentTime'),
              inputType: 'text',
              disabled: true,
              hidden: !(base.watch('generalInfo.group')?.value.startsWith('CS') && !base.watch('generalInfo.firstContactResolution'))
            }
					]}
				/>
				{groupCode === 'CHG2' && <IBMoneyMisappropriation form={base} detail={detailData?.detail} />}
				{groupCode === 'CHG3' && (
					<IBSalesPractice
						form={base}
						detail={detailData?.detail}
						setAgentCodeErr={setAgentCodeError}
						agentCodeError={agentCodeError}
					/>
				)}
				{groupCode === 'CHG4' && <IBGAOperation form={base} detail={detailData?.detail} />}
				{isShowConservation && (
					<IBConservation
						form={base}
						detail={detailData?.detail}
						mainProductCode={detailData?.mainProductCode}
						riders={detailData?.riders}
						isReset={isReset}
						userInfo={userInfo}
						setAgentCodeErr={setAgentCodeError}
						agentCodeError={agentCodeError}
					/>
				)}
				{!isShowConservation && <IBCommunicationInfo form={base} detail={detailData?.detail} notShowTitle />}
			</View>
			<View style={styles.btnBar}>
				{permissions.includes(Permission['CancelDraftTransactionDetail']) &&
					<View style={styles.btn}>
						<IBButton onPress={() => setShowCancelPopup(true)} title={t('common:Cancel')} backgroundFill={false} width={180} />
					</View>
				}
				<View style={styles.btn}>
					<IBButton onPress={() => onReset()} title={t('submission:ClearAll')} backgroundFill={false} width={180} />
				</View>
				{permissions.includes(Permission['SaveDraftTransactionDetail']) &&
					<View style={styles.btn}>
						<IBButton onPress={() => onSave()} title={t('common:Save')} backgroundFill width={180} />
					</View>
				}
				{permissions.includes(Permission['SubmitDraftTransactionDetail']) &&
					<View style={[styles.btn, { marginRight: 0 }]}>
						<IBButton onPress={onSubmit} title={t('submission:Submit')} backgroundFill width={180} />
					</View>
				}
			</View>
			<ModalComponent
				children={<Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16 }}>{t('Inbound:CancelConfirmation')}</Text>}
				visible={isShowCancelPopup}
				onClose={() => setShowCancelPopup(false)}
				title={''}
				actions={[
					{
						text: t('common:Yes'),
						type: 'filled',
						disabled: false,
						loading: false,
						onlyWide: false,
						style: { height: 39, marginEnd: 15 },
						action: () => onCancel()
					},
					{
						text: t('common:No'),
						type: 'outline',
						disabled: false,
						loading: false,
						onlyWide: false,
						style: { height: 39 },
						action: () => setShowCancelPopup(false)
					}
				]}>
			</ModalComponent>
		</ScrollView >
	)
}

const styles = StyleSheet.create({
	btnBar: {
		borderTopWidth: 1,
		borderColor: '#EBEBF0',
		height: 70,
		width: '100%',
		flexDirection: 'row',
		paddingHorizontal: 25,
		// justifyContent: 'space-between'
	},
	btn: {
		marginRight: 10
	}
})