import React, { useEffect } from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native'
import { DaAddLackService, InfoHeader, AddLackConstant } from '../common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import * as A from 'fp-ts/lib/Array'

import {
  AppContext,
  Panel,
  useMobile,
  SelectOption,
  ImgUploadMutiple,
  RoundedButton,
  GeneralService,
  TabList,
  StorageBlob
} from '@pulseops/common'
import styled from 'styled-components/native'
import { useTranslation } from 'react-i18next'
import { UploadForm } from './addLack-document-form'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import _ from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { StackScreenProps } from '@react-navigation/stack'
import { UtilitiesStackParamList } from '../../utilites-stack-params-list'
import { useLoading } from '@mxt/zio-react'
import { Error } from '@pulseops/submission/common'

type Props = StackScreenProps<UtilitiesStackParamList, 'AddLackScreen'>
export const AddLackScreen = (props: Props) => {
  const { navigate } = useNavigation()
  const { t, i18n } = useTranslation()
  const [loading, bindLoader] = useLoading(false)
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [list, setList] = React.useState<UploadForm.AddLackItem[]>([])
  const { isMobile } = useMobile()
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { agentCode, caseId, eRef, idNumber } = props?.route?.params
  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:DistributionServices'),
          navigate: () => {
            navigate('DSScreen')
          }
        },
        { title: t('DistributionService:AddLackDoc'), navigate: null }
      ])
    }
  }, [isFocused])

  const {
    formState: { errors },
    control,
    watch,
    reset,
    handleSubmit
  } = useForm<UploadForm.AddLackDataForm>({
    defaultValues: { DocumentList: [] }
  })

  const { fields, append } = useFieldArray({
    control: control,
    name: 'DocumentList'
  })

  const uploadContent = watch('DocumentList')

  const clearForm = () => {
    const resetForm: UploadForm.AddLackItem[] = list.map((item) => {
      return { ...item, fileList: [] }
    })
    reset({ DocumentList: resetForm })
  }

  const addFields = (item?: UploadForm.AddLackItem | null) => {
    if (!!item) {
      append(item)
    }
  }

  const onSubmit = handleSubmit((validated) => {
    showGlobalLoading(true)
    return pipe(
      uploadContent,
      A.map((uploadItem) => {
        return GeneralService.getAzureStorageFilesAgent(
          uploadItem.fileList,
          uploadItem.transactionType,
          uploadItem.docTypeCode,
          uploadItem.category ?? '',
          agentCode ?? '',
          ''
        )
      }),
      ZIO.sequence,
      ZIO.map(uploadList =>{
        let uploadFiles: StorageBlob.FileContentSubmit[] = []
        uploadList.map(arrItem =>{
          uploadFiles.push(...arrItem)
        })
        return uploadFiles
      }),
      ZIO.flatMap((fileList) => {
        return pipe(
          DaAddLackService.AddLackDoc(caseId ?? '', fileList ?? []),
          ZIO.tap((res) => {
            showGlobalLoading(false)
            showToast('Documents has sent successfully', 'success')
            navigate('DSScreen')
            return ZIO.unit
          })
        )
      }),
      ZIO.mapError((err) => {
        showToast('Action was unsuccessful. Please try again', 'error')
        showGlobalLoading(false)
        return err
      }),
      ZIO.unsafeRun({})
    )
  })

  const menus: SelectOption[] = [
    { label: 'eREF', value: eRef ?? '-' },
    { label: 'Case ID', value: caseId ?? '-' },
    { label: 'ID number', value: idNumber ?? '-' },
    { label: 'Agent code', value: agentCode ?? '-' }
  ]

  const tabMenu = [{ id: 1, title: 'Add lack document' }]

  const getAgent = (agentCode: string) => {
    return pipe(
      DaAddLackService.getAddLackData(agentCode ?? ''),
      ZIO.tap((res) => {
        if (res && res?.detailList && res?.detailList?.length > 0) {
          const newList = res?.detailList && res?.detailList.filter((item) => item.status === 'lackdoc')
          newList.forEach((item) => {
            const checkItem = AddLackConstant.AddLackT17List.filter(
              (x, index) => x.docId === item.docID && x.transactionType === res.transactionType
            )
            if (checkItem) {
              const newOb: UploadForm.AddLackItem = {
                category: checkItem[0].category,
                docTypeCode: checkItem[0].docTypeCode,
                docId: item?.docID ?? '',
                transactionType: res.transactionType ?? '',
                documentNameEn: item?.documentsEn ?? '',
                documentNameVn: item.documentsVn ?? '',
                fileList: []
              }
              addFields(newOb)
            }
          })
          setList(uploadContent)
        } else {
          showToast(t('message:MS030029'), 'info')
        }
        return ZIO.unit
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    if (agentCode) {
      getAgent(agentCode)
    }
  }, [])

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        backgroundColor: useMobile().isMobile ? '#E5E5E5' : '#F9F9F9'
      }}
    >
      <View style={{ flex: 1 }}>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <View style={{ marginHorizontal: 16, marginBottom: isMobile ? 16 : 0 }}>
              <InfoHeader menus={menus} type="special" />
            </View>
            <View style={{ marginLeft: 16 }}>
              <TabList menus={tabMenu} tabIndex={0} onChangeTab={() => setTabIndex(0)} />
            </View>
            {tabIndex === 0 && (
              <>
                <SC.TextSession style={{ backgroundColor: '#fff' }}>
                  {'Supplementary infomation'.toUpperCase()}
                </SC.TextSession>
                {fields.map((fieldItem, index) => (
                  <SC.Container key={index} style={{ paddingHorizontal: 16, backgroundColor: '#fff' }}>
                    <View style={{ width: '100%', height: '100%' }}>
                      <Panel containerStyle={{ backgroundColor: 'white', padding: 10 }} isExand={false}>
                        <SC.PanelBody>
                          <View style={[isMobile ? styles.mobileRow : styles.row, { width: '100%' }]}>
                            <View style={styles.colFull}>
                              <Text style={styles.field_title}>
                                {i18n.language === 'en'
                                  ? uploadContent[index]?.documentNameEn ? uploadContent[index]?.documentNameEn : ''
                                  : uploadContent[index]?.documentNameVn ? uploadContent[index].documentNameVn : ''} 
                                <Text style={styles.requiredText}>*</Text>
                              </Text>
                            </View>
                          </View>
                          <SC.Divider />
                          <SC.TextHeader>
                            {t('IFQ:FileAttachment')} <SC.TabText>*</SC.TabText>
                          </SC.TextHeader>
                          <Controller
                            control={control}
                            name={`DocumentList.${index}.fileList`}
                            rules={{
                              required: {
                                value: true,
                                message: `${t(
                                  'message:MS050029'
                                  // ,{ field: t(`${i18n.language === 'en' ? uploadContent[index].documentNameEn : uploadContent[index].documentNameVn}`)}
                                )}`
                              }
                            }}
                            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                              return (
                                <>
                                  <ImgUploadMutiple
                                    value={value as any[]}
                                    onChange={onChange}
                                    errorMessage={error?.message || ''}
                                  />
                                  {error?.message && (
                                    <Error message={!!value && value.length > 0 ? '' : error?.message} />
                                  )}
                                </>
                              )
                            }}
                          />
                          <SC.Divider />
                        </SC.PanelBody>
                      </Panel>
                    </View>
                  </SC.Container>
                ))}
              </>
            )}
          </View>
        </ScrollView>
      </View>
      <SC.FooterContainer>
        <RoundedButton
          text={t('utilities:ClearAll')}
          activeOpacity={0.7}
          showBorder={true}
          textColorEnable="#ED1B2E"
          borderColorEnable="#ED1B2E"
          disabled={uploadContent && uploadContent?.length <= 1}
          onPress={clearForm}
          style={{
            minWidth: 100,
            height: 39,
            paddingHorizontal: 13
          }}
          textStyle={{ fontSize: 15 }}
        />
        <SC.Divider />

        <RoundedButton
          activeOpacity={0.7}
          text={t('utilities:Submit')}
          filled
          loadingColor={'#FFFFFF'}
          textColorDisable={'#FFFFFF'}
          textColorEnable={'#FFFFFF'}
          bgColorEnable={'#ED1B2E'}
          onPress={onSubmit}
          style={{
            minWidth: 100,
            height: 39,
            paddingHorizontal: 13
          }}
          textStyle={{ fontSize: 15 }}
        />
      </SC.FooterContainer>
    </View>
  )
}

const IconButton: React.FC<{ icon: JSX.Element; title: string; onPress: () => void }> = ({ title, onPress, icon }) => {
  return (
    <SC.ButtonContainer onPress={onPress}>
      {icon}
      <SC.Divider />
      <Text style={{ color: 'black', fontSize: 15, fontWeight: '600' }}>{title}</Text>
    </SC.ButtonContainer>
  )
}

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginHorizontal: -16
  },
  mobileRow: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 12
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  requiredText: {
    color: 'red',
    marginLeft: 5,
    fontSize: 14
  }
})

const SC = {
  Container: styled(View)`
    align-items: flex-start;
    align-self: center;
    width: 100%;
    background-color: '#fff';
  `,

  Divider: styled(View)`
    width: 10px;
    height: 10px;
  `,
  PanelBody: styled(View)`
    padding: 0px 10px 0px 10px;
    align-items: flex-start;
    overflow: hidden;
  `,
  ButtonContainer: styled(TouchableOpacity)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  Padding: styled(View).attrs<{ paddingVertical: number; paddingHorizontal: number }>((p) => ({
    style: {
      padding: p.paddingVertical
    }
  }))<{ paddingVertical: number; paddingHorizontal: number }>``,

  FooterContainer: styled(View)`
    padding: 20px 0px 20px 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
  `,
  TextHeader: styled(Text)`
    color: #70777e;
    font-weight: bold;
    font-size: 15;
  `,
  TabText: styled(Text)`
    color: red;
    font-weight: bold;
    font-size: medium;
    padding: 5px 0px 5px 0px;
  `,
  TextSession: styled(Text)`
    color: black;
    font-weight: bold;
    font-size: 15px;
    padding: 30px 16px 20px;
  `,
  ErrorText: styled(Text)`
    color: red;
    font-size: 12px;
    padding: 5px 0px 5px 0px;
  `
}
