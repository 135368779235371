import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { CategoryTabInfo, ClientScreenContext, IBCategoryTab } from '../../../../../common'
import { useTranslation } from 'react-i18next'
import { IBPolicyPermission } from '@pulseops/common'
import { IBPolicyInfomationDocs } from './IBPolicyInfomationDocs'
import { IBClaimInfomationDocs } from './IBClaimInfomationDocs'
import { IBEInvoiceDocs } from './IBEInvoiceDocs'
import { IBClaimAdvanceDocs } from './IBClaimAdvanceDocs'
import { DocumentsTabsData } from '../../../../../ib-service'

interface Props {
  policyNumber?: string
  data: DocumentsTabsData
  setData: (data: DocumentsTabsData) => void
}

export const IBDocumentInfoTab = (props: Props) => {
  const { policyNumber, data, setData } = props
  const { permissions } = React.useContext(ClientScreenContext)
  const { t, i18n } = useTranslation('Inbound')
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const [listTabHasPermission, setListTabHasPermission] = React.useState<CategoryTabInfo[]>([])

  //Chưa có permission cho các tabs
  const categoryTabList: Array<CategoryTabInfo> = [
    {
      label: t('PolicyInformation'),
      key: '1',
      permission: IBPolicyPermission.ViewIBPolicyInfoPolicyDetail
    },
    {
      label: t('ClaimInfomation'),
      key: '2',
      permission: IBPolicyPermission.ViewIBPolicyInfoPolicyDetail
    },
    {
      label: t('ClaimAdvance'),
      key: '3',
      permission: IBPolicyPermission.ViewIBPolicyInfoPolicyDetail
    },
    {
      label: t('EInvoice'),
      key: '4',
      permission: IBPolicyPermission.ViewIBPolicyInfoPolicyDetail
    }
  ]
  React.useEffect(() => {
    const list: Array<CategoryTabInfo> = []
    categoryTabList.map((i) => {
      if (i.permission && permissions.includes(i.permission)) {
        list.push(i)
      }
    })
    setListTabHasPermission(list)
  }, [permissions, i18n.language])

  const getTabKey = (tabIndex: number) => {
    const tabShow = listTabHasPermission[tabIndex]
    return tabShow?.key ?? ''
  }

  return (
    <View>
      <View style={{ marginTop: 5 }}>
        <IBCategoryTab
          dataSource={listTabHasPermission}
          onChangeTab={setCategoryTabIndex}
          activeTabIndex={categoryTabIndex}
          permission={permissions}
        />
      </View>
      <View style={{ marginTop: 15 }}>
        {getTabKey(categoryTabIndex) === '1' && (
          <IBPolicyInfomationDocs data={data} setData={setData} policyNumber={policyNumber as string} />
        )}
        {getTabKey(categoryTabIndex) === '2' && (
          <IBClaimInfomationDocs data={data} setData={setData} policyNumber={policyNumber as string} />
        )}
        {getTabKey(categoryTabIndex) === '3' && (
          <IBClaimAdvanceDocs data={data} setData={setData} policyNumber={policyNumber as string} />
        )}
        {getTabKey(categoryTabIndex) === '4' && (
          <IBEInvoiceDocs data={data} setData={setData} policyNumber={policyNumber as string} />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({})
