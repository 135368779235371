import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const ClientC = t.type({
  classCode: Maybe(t.string),
  clientId: Maybe(t.string),
  email: Maybe(t.string),
  mobilePhone: Maybe(t.string),
  countryCode: Maybe(t.string),
  location: Maybe(t.string),
  otpConsentStatus: Maybe(t.string),
  customerSmsInd: Maybe(t.string),
  customerEmailInd: Maybe(t.string),
  agentSmsInd: Maybe(t.string),
  updateNewPruInfo: Maybe(t.string),
  name: Maybe(t.string),
  firstName: t.string,
  surName: t.string,
  dob: Maybe(t.string),
  dod: Maybe(t.string),
  sex: Maybe(t.string),
  nationality: Maybe(t.string),
  occupation: Maybe(t.string),
  externalIds: Maybe(
    t.type({
      SOE: Maybe(t.string),
      SOE_VALUE: Maybe(t.string)
    })
  ),
  addressDetails: Maybe(
    t.type({
      PRIMARY: t.type({
        line1: Maybe(t.string), // street
        line2: Maybe(t.string), // ward
        line3: Maybe(t.string), // district
        line4: Maybe(t.string) // city
      })
    })
  ),
  attributesExt: t.type({
    MOBILE_CODE: t.string,
    FATCA_TAX_DECLARE: t.string,
    COMPANY_CITY: Maybe(t.string),
    COMPANY_DISTRICT: Maybe(t.string),
    COMPANY_WARD: Maybe(t.string),
    FOREIGN_CITY: Maybe(t.string),
    FOREIGN_COUNTRY: Maybe(t.string),
    FOREIGN_DISTRICT: Maybe(t.string),
    FOREIGN_STREET: Maybe(t.string),
    FOREIGN_WARD: Maybe(t.string),
    NATIONALITY_2: Maybe(t.string)
  })
})
export type ClientQuery = t.TypeOf<typeof ClientC>

export const Customer = t.type(
  {
    body: t.type({
      email: t.string,
      mobilePhone: t.string,
      countryCode: t.string,
      location: t.string,
      otpConsentStatus: t.string,
      customerSmsInd: t.string,
      customerEmailInd: t.string,
      agentSmsInd: t.string,
      updateNewPruInfo: t.string,
      name: t.string,
      clientId: t.string,
      externalIds: t.type({
        SOE: Maybe(t.string),
        SOE_VALUE: Maybe(t.string)
      }),
      addressDetails: t.type({
        PRIMARY: Maybe(
          t.type({
            line1: t.string,
            line2: t.string,
            line3: t.string,
            line4: t.string
          })
        )
      }),
      classCode: t.string,
      firstName: t.string,
      surName: t.string,
      dob: t.string,
      dateOfDeath: t.string,
      sex: t.string,
      maritalStatus: t.string,
      salutation: t.string,
      nationality: t.string,
      occupation: t.string,
      attributesExt: t.type({
        MOBILE_CODE: t.string,
        COMPANY_PHONE_NUMBER: t.string,
        COMPANY_CODE: t.string,
        HOME_PHONE_NUMBER: t.string,
        HOME_CODE: t.string,
        BIRTH_PLACE: t.string,
        BIRTH_COUNTRY: t.string,
        CLIENT_TYPE: t.string,
        ID_DATE: t.string,
        CATEGORY: t.string,
        NATIONALITY_2: t.string,
        FORMAT_NAME: t.string,
        EMPLOYEE_ID: t.string,
        FATCA_TAX_NUMBER: t.string,
        FATCA_TAX_DECLARE: t.string,
        FATCA_SIGNED_DATE: t.string,
        FATCA_EXPIRY_DATE: t.string,
        FAX: t.string,
        FOREIGN_COUNTRY: t.string,
        FOREIGN_STREET: t.string,
        FOREIGN_WARD: t.string,
        FOREIGN_DISTRICT: t.string,
        FOREIGN_CITY: t.string,
        COMPANY_NAME: t.string,
        COMPANY_STREET: t.string,
        COMPANY_WARD: t.string,
        COMPANY_DISTRICT: t.string,
        COMPANY_CITY: t.string,
        OCCUPATION_GROUP: t.string,
        OCCUPATION_DESC: t.string
      }),
      dod: t.string
    })
  },
  'Customer'
)
export type Customer = t.TypeOf<typeof Customer>
