import { TextField } from '@material-ui/core'
import { ModalComponent, sharedStyle, TextInput } from '@pulseops/common'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
import React from 'react'
import { Text, StyleSheet, View } from 'react-native'
type LetterPreviewPopup = {
  title: string
  isVisible: boolean
  description: string
  onClose: () => void
}
export const LetterPreviewPopup = (props: LetterPreviewPopup) => {
  const { title, isVisible, description, onClose } = props

  return (
    <ModalComponent title={title} centerTitle={true} visible={isVisible} modalWidth={700} onClose={onClose} actions={[]}>
      <View style={style.letterContainer}>
        <TextField
          value={description}
          fullWidth
          multiline
          InputProps={{
            readOnly: true,
            disableUnderline: true // disable underline
          }}
        />
      </View>
    </ModalComponent>
  )
}

const style = StyleSheet.create({
  letterContainer: {
    marginHorizontal: 20,
    marginTop: 20
  }
})