import { formatNumberWithComma, FrequencyMapping, Panel, RadioButton, TaskDetail, Title } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { FieldText, SectionCol, SectionContent, SectionRow } from '@pulseops/submission/common'
import moment from 'moment'

export interface VERedatingProps {
  detailData: TaskDetail.VERIRedating
}

export const VERedatingScreen: React.FC<VERedatingProps> = ({ detailData }) => {
  const { t } = useTranslation()

  const formatStringToDateString = (value: string) => {
    return value ? moment(value).format('DD/MM/YYYY') : ''
  }

  const formatCurrency = (value: number | string | undefined) => {
    return !!value ? formatNumberWithComma(value) : '0'
  }

  const mappingFrequency = (value: string) => {
    const frequencyItem = FrequencyMapping.FrequencyOption.find((x) => x.value === value)
    return !!frequencyItem ? t(frequencyItem.label) : ''
  }

  return (
    <SafeAreaView>
      <Title wrapperStyle={redatingStyles.noMarginBottom} title={t('REQUESTDETAIL')}></Title>
      <SectionContent sectionStyles={redatingStyles.sectionContent}>
        <SectionRow sectionStyles={redatingStyles.sectionRow}>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:RiskCommencementDate')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatStringToDateString(detailData.submissionData.rcd ?? '')}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText
              fieldStyles={redatingStyles.field_title}
              text={t('Redating:EstimatedNewRiskCommencementDate')}
            ></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatStringToDateString(detailData.submissionData.newRcd ?? '')}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('Redating:BillingFrequency')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={mappingFrequency(detailData.submissionData.frequency)}
            />
          </SectionCol>
        </SectionRow>

        <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondLine]}>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:InstallmentPremium')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatCurrency(detailData.submissionData.regularPremium ?? 0)}
              isCurrencyUnit={true}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:LapsedDate')}></FieldText>
            <FieldText
              fieldStyles={redatingStyles.field_description}
              text={formatStringToDateString(detailData.submissionData.lapsedDate ?? '')}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText
              fieldStyles={redatingStyles.field_title}
              text={t('Redating:EstimatedTotalReinstatementAmount')}
            ></FieldText>
            <FieldText
              fieldStyles={[redatingStyles.field_description, redatingStyles.redLine]}
              text={formatCurrency(detailData.submissionData.totalReinsAmount ?? 0)}
              isCurrencyUnit={true}
            />
          </SectionCol>
        </SectionRow>
      </SectionContent>

      <Title wrapperStyle={redatingStyles.secondLine} title={t('submission:StatementOfInsurability')}></Title>
      {detailData &&
        detailData.submissionData.lifeAssuredList &&
        detailData.submissionData.lifeAssuredList.length > 0 &&
        detailData.submissionData.lifeAssuredList.map((lifeItem, index) => {
          return (
            <Panel title={lifeItem.clientName} key={index} isTransactionToggleExpand={true}>
              <SectionRow sectionStyles={redatingStyles.sectionRow}>
                <SectionCol sectionStyles={redatingStyles.col_12}>
                  <OptionView
                    title={t('submission:AnyChangesInStateOfHealth')}
                    selected={lifeItem.healthStateChange}
                    disable={true}
                  ></OptionView>
                </SectionCol>
              </SectionRow>
              <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                <SectionCol sectionStyles={redatingStyles.col_12}>
                  <OptionView
                    title={t('submission:AnyChangesInOccupation')}
                    selected={lifeItem.occupationChange}
                    disable={true}
                  ></OptionView>
                </SectionCol>
              </SectionRow>
              {lifeItem.occupationChange && (
                <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                  <SectionCol sectionStyles={redatingStyles.col_4}>
                    <FieldText
                      fieldStyles={redatingStyles.field_title}
                      text={t('submission:CurrentOccupation')}
                    ></FieldText>
                    <FieldText fieldStyles={redatingStyles.field_description} text={lifeItem.curOccupation} />
                  </SectionCol>
                  <SectionCol sectionStyles={redatingStyles.col_4}>
                    <FieldText
                      fieldStyles={redatingStyles.field_title}
                      text={t('submission:NewOccupation')}
                    ></FieldText>
                    <FieldText
                      fieldStyles={[redatingStyles.field_description, redatingStyles.italicText]}
                      text={lifeItem.newOccupation}
                    />
                  </SectionCol>
                </SectionRow>
              )}
              <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                <SectionCol sectionStyles={redatingStyles.col_12}>
                  <OptionView
                    title={t('submission:AnyChangeInActivities')}
                    selected={lifeItem.activityChange}
                    disable={true}
                  ></OptionView>
                </SectionCol>
              </SectionRow>
              {lifeItem.activityChange && (
                <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                  <SectionCol sectionStyles={redatingStyles.col_4}>
                    <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:NewActivity')}></FieldText>
                    <FieldText
                      fieldStyles={[redatingStyles.field_description, redatingStyles.italicText]}
                      text={lifeItem.newActivity}
                    />
                  </SectionCol>
                </SectionRow>
              )}
              <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                <SectionCol sectionStyles={redatingStyles.col_12}>
                  <OptionView
                    title={t('submission:AnyChangesInInsurancePolicy/Requesting')}
                    selected={lifeItem.companyChange}
                    disable={true}
                  ></OptionView>
                </SectionCol>
              </SectionRow>
              {lifeItem.companyChange && (
                <SectionRow sectionStyles={[redatingStyles.sectionRow, redatingStyles.secondSmallLine]}>
                  <SectionCol sectionStyles={redatingStyles.col_4}>
                    <FieldText fieldStyles={redatingStyles.field_title} text={t('submission:CompanyName')}></FieldText>
                    <FieldText
                      fieldStyles={[redatingStyles.field_description, redatingStyles.italicText]}
                      text={lifeItem.newCompany}
                    />
                  </SectionCol>
                </SectionRow>
              )}
            </Panel>
          )
        })}
      <Title wrapperStyle={redatingStyles.secondLine} title={t('Redating:REINSTATEMENTAGENT')}></Title>
      <SectionContent sectionStyles={[redatingStyles.sectionContent, redatingStyles.noMarginTop]}>
        <SectionRow sectionStyles={redatingStyles.sectionRow}>
          <SectionCol sectionStyles={redatingStyles.col_12}>
            <FieldText fieldStyles={[redatingStyles.field_title]} text={t('Redating:Agent')}></FieldText>
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_2}>
            <FieldText
              fieldStyles={[redatingStyles.field_description, redatingStyles.italicText]}
              text={detailData.submissionData.agentCode}
            />
          </SectionCol>
          <SectionCol sectionStyles={redatingStyles.col_4}>
            <FieldText
              fieldStyles={[redatingStyles.field_description, redatingStyles.italicText]}
              text={detailData.submissionData.agentName}
            />
          </SectionCol>
        </SectionRow>
      </SectionContent>
    </SafeAreaView>
  )
}

export const OptionView: React.FC<{
  selected: boolean
  title: string
  onChange?: (value: boolean) => void
  disable?: boolean
}> = ({ selected, title, onChange, disable = false }) => {
  const { t } = useTranslation()
  return (
    <View style={{ flex: 1 }}>
      <FieldText fieldStyles={redatingStyles.field_title} text={title}></FieldText>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <RadioButton
          label={t('submission:Change')}
          selected={selected}
          onChange={() => onChange?.call(null, true)}
          disable={disable}
        />
        <RadioButton
          label={t('submission:NoChange')}
          selected={!selected}
          onChange={() => onChange?.call(null, false)}
          disable={disable}
        />
      </View>
    </View>
  )
}

const redatingStyles = StyleSheet.create({
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_2: {
    width: '100%',
    maxWidth: '16.6666666667%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  secondLine: {
    marginTop: 20
  },
  redLine: {
    color: '#ed1c2e',
    fontWeight: '700'
  },
  secondSmallLine: {
    marginTop: 10
  },
  noMarginBottom: {
    marginBottom: 0
  },
  noMarginTop: {
    marginTop: 0
  },
  italicText: {
    fontStyle: 'italic'
  }
})
