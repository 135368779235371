import { pipe } from 'fp-ts/function'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'

export namespace JournalService {
  export const CurrentBalanceData = t.type({
    amount: t.number
  })

  export type CurrentBalanceData = t.TypeOf<typeof CurrentBalanceData>

  export const FundBalanceData = t.type({
    fundCode: t.string,
    unitBalance: t.number,
    estimateValue: t.number
  })

  export type FundBalanceData = t.TypeOf<typeof FundBalanceData>

  export const getFundBalanceData = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/fund`)(
        t.type({
          body: t.array(FundBalanceData)
        })
      ),
      ZIO.map((c) => c.body)
    )

  export const getCurrentBalanceData = (policyNum: string, sacCode: string, sacType: string, releaseNumber?: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/account-balance/`, { params: { sacCode, sacType, releaseNumber } })(
        t.type({
          body: t.array(CurrentBalanceData)
        })
      ),
      ZIO.map((c) => (c.body[0] ? c.body[0].amount : 0))
    )
}
