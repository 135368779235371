import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Default = t.type({
  transactionType: t.literal(TransactionType.CHANGE_CONTACT_INFO_ADDRESS),
  payload: t.type({
    body: t.type({
      line1: t.string,
      countryCode: Maybe(t.string),
      country: Maybe(t.string)
    })
  }),
  source: t.union([
    t.literal(SourceType.BANCA),
    t.literal(SourceType.EKIOSK),
    t.literal(SourceType.PRUGREAT),
    t.literal(SourceType.ZALO)
  ])
})

const Pulse4Ops_BancaSea = t.type({
  transactionType: t.union([
    t.literal(TransactionType.CHANGE_CONTACT_INFO_ADDRESS),
    t.literal(TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL)
  ]),
  payload: t.type({
    body: t.type({
      policyNo: t.string,
      dispatchAddress: t.type({
        city: t.string,
        countryCode: Maybe(t.string),
        district: t.string,
        line1: t.string,
        subDistrict: t.string
      }),
      priorPolicies: Maybe(
        t.array(
          t.type({
            policyNo: t.string
          })
        )
      )
    })
  }),
  source: t.union([t.literal(SourceType.PULSE4OPS), t.literal(SourceType.BANCA_SEA), t.literal(SourceType.PRUONLINE), t.literal(SourceType.OUTBOUND), t.literal(SourceType.INBOUND)])
})

const Pulse = t.type({
  transactionType: t.union([
    t.literal(TransactionType.CHANGE_CONTACT_INFO_ADDRESS),
    t.literal(TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL)
  ]),
  payload: t.type({
    body: t.type({
      line1: Maybe(t.string),
      line2: Maybe(t.string),
      line3: Maybe(t.string),
      countryCode: Maybe(t.string),
      country: Maybe(t.string),
      state: Maybe(t.string)
    }),
    primaryPolicyNo: t.string
  }),
  source: t.literal(SourceType.PULSE)
})

export const ChangePrimaryAddressInfo = t.union([Default, Pulse4Ops_BancaSea, Pulse])
export type ChangePrimaryAddressInfo = t.TypeOf<typeof ChangePrimaryAddressInfo>
