import { View, Text } from '@pulseops/business/core'
import styled from 'styled-components/native'
import { SelectSearch } from './components/select-search';

export const SC = {
  Container: styled(View)<{ hasBackground?: boolean; maxWidth: string; isWide?: boolean; width: number }>`
    flex: 1;
    max-width: ${(p) => p.maxWidth}px;
    width: 100%;
    margin-top: ${(p) => (p.isWide ? (p.width > 1367 ? '330': '200') : '181')}px;
    margin-right: ${(p) => (p.isWide ? '96px' : 'auto')};
    margin-left: auto;
    background-color: ${(p) => (p.hasBackground ? '#fafafa' : 'transparent')};
    position: 'relative';
  `,
  ContainerPolicyList: styled(View)<{ hasBackground?: boolean; maxWidth: string; isWide?: boolean }>`
    flex: 1;
    max-width: ${(p) => p.maxWidth}px;
    width: 100%;
    margin-top: ${(p) => (p.isWide ? '94' : '20')}px;
    margin-right: auto;
    margin-left: auto;
    background-color: ${(p) => (p.hasBackground ? '#fafafa' : 'transparent')};
  `,
  Header: styled(View)`
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
  `,
  Logo: styled(View)<{ isWide?: boolean }>`
    margin-left: ${(p) => (p.isWide ? '90' : '20')}px;
    margin-top: 14px;
    margin-bottom: 15px;
  `,
  Support: styled(View)<{ isWide?: boolean }>`
    display: ${(p) => (p.isWide ? 'flex' : 'block')};
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 13px;
    margin-right: ${(p) => (p.isWide ? '96' : '20')}px;
  `,
  TollFreeCallCenter: styled(View)<{ isWide?: boolean }>`
    font-size: 15px;
    margin-right: ${(p) => (p.isWide ? '14' : '0')}px;
    font-family: 'NotoSans_400Regular';
    color: #4f4f4f;
  `,
  HotLine: styled(View)`
    margin-left: 9px;
    font-size: 18px;
    color: #ed1b2e;
    font-family: 'Noto Sans';
    font-weight: 700;
  `,
  MessageTitle: styled(View)<{ isWide?: boolean }>`
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    color: #000000;
    white-space: pre-line;
    width: ${(p) => (p.isWide ? 'auto' : '100%')};
  `,
  Footer: styled(View)<{ isWide?: boolean }>`
    max-width: 900px;
    width: 100%;
    margin-top: ${(p) => (p.isWide ? '40' : '20')}px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
  `,
  FooterButton: styled(View)<{ isWide?: boolean }>`
    max-width: ${(p) => (p.isWide ? '466px' : '100%')};
    width: 100%;
  `,
  TextError: styled(Text)`
    margin: 5px 10px;
    color: #ed1b2e;
  `,
  ListItems: styled(View)<{ isWide?: boolean }>`
    margin-top: ${(p) => (p.isWide ? '40' : '30')}px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${(p) => (p.isWide ? 'space-between' : 'center')};
  `,
  Item: styled(View)<{ isWide?: boolean }>`
    width: ${(p) => (p.isWide ? '380px' : '100%')};
    margin-bottom: ${(p) => (p.isWide ? '40' : '30')}px;
  `,
  Form: {
    Group: styled(View)<{ marginBottom?: string }>`
      margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '1em')};
    `,
    Row: styled(View)<{ isMobile?: boolean; marginBottom?: string }>`
      flex-direction: row;
      margin-left: ${(p) => (p.isMobile ? '20' : '30')}px;
      margin-right: ${(p) => (p.isMobile ? '20' : '30')}px;
      margin-bottom: ${(p) => (p.marginBottom ? `${p.marginBottom}px` : '1em')};
    `
  },
  PageTitle: styled(Text)<{ isWide?: boolean }>`
    font-weight: bold;
    margin-left: ${(p) => (p.isWide ? '0' : '20')}px;
    margin-bottom: 16px;
    font-size: ${(p) => (p.isWide ? '32' : '24')}px;
    font-weight: 700;
    font-family: 'Open Sans';
  `,
  PageTitleText: styled(Text)<{ isWide?: boolean; hasError?: boolean }>`
    margin-bottom: 16px;
    font-size: ${(p) => (p.isWide ? '22' : '18')}px;
    color: ${(p) => (p.hasError ? '#ed1b2e' : 'inherit')};
  `,
  Modal: {
    Container: styled(View)`
      flex: 1;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
    `,
    View: styled(View)`
      width: 400px;
      align-self: center;
      background-color: #fff;
      border-radius: 16px;
      padding: 32px;
    `,
    Title: styled(Text)`
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 32px;
    `,
    Text: styled(Text)`
      margin-bottom: 20px;
    `
  },
  NormalText: styled(Text)`
    font-size: 24px;
    font-family: 'Noto Sans';
    font-weight: 400;
    color: #000000;
  `,
  Universal: styled(View)<{ isWide?: boolean }>`
    padding-top: 18px;
    display: flex;
    margin-left: ${(p) => (p.isWide ? '50' : '20')}px;
    margin-right: ${(p) => (p.isWide ? '50' : '20')}px;
    border-top-width: 1px;
    border-top-color: #e5eaef;
  `,
  LinkText: styled(Text)`
    text-decoration: underline;
    font-size: 14px;
    font-family: 'NotoSans_400Regular';
    color: #ed1b2e;
    text-decoration-color: #ed1b2e;
    margin-bottom: 16px;
  `,
  LinkingRow: styled(View)``,
  FooterText: styled(Text)<{ isWide?: boolean }>`
    margin-top: 8px;
    margin-left: ${(p) => (p.isWide ? '60px' : '20px')};
    text-align: left;
    margin-bottom: 14px;
    color: #ffffff;
    background: #000000;
  `,
  SelectSearch: styled(SelectSearch).attrs(() => ({
    labelStyle: {
      fontWeight: '700',
      fontSize: 14,
      color: '#000000',
      fontFamily: 'Noto Sans'
    },
    textInputStyle: {
      fontWeight: 400,
      fontSize: 16,
      color: '#000000',
      fontFamily: 'Noto Sans'
    }
  }))``,
  SelectContainer: styled(View)`
    width: 100%;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
  `,
}
