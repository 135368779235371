import { SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View, ActivityIndicator } from 'react-native'
import React from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { ClaimStackParamList } from '@pulseops/submission'
import {
  PolicyInfoHeader,
  TabList,
  ErrorHandling,
  useMobile,
  AppContext,
  AuthService,
  Toast,
  PaymentType
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { RequestInfoTab } from '../request-info'
import { pipe } from 'fp-ts/lib/function'
import { ClaimService } from '../ClaimService'
import { ZIO } from '@mxt/zio'
import { RequestInfoForm } from '../request-info/RequestInfoForm'
import { useForm } from 'react-hook-form'
import { useLoading } from '@mxt/zio-react'
import { PaymentMethod } from '../payment-method'
import * as PaymentForm from '../payment-method/PaymentMethodForm'
import { ClaimPayoutSubmissionC } from '../common/SubmissionClaimPayout'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import moment from 'moment'

const generateUUID = (length: number = 32) => {
  let uuidValue = '',
    k,
    randomValue
  for (k = 0; k < length; k++) {
    randomValue = (Math.random() * 16) | 0

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += '-'
    }
    uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
  }
  return uuidValue
}
type Props = StackScreenProps<ClaimStackParamList, 'PayoutScreen'>

export const PayoutScreen = (props: Props) => {
  const { t } = useTranslation('claim')
  const [tabIndex, setTabIndex] = React.useState<number>(1)
  const [loading, bindLoader] = useLoading(false)
  const [isValid, setValid] = React.useState(false)
  const paymentForm = useForm<PaymentForm.PaymentMethodForm>({ mode: 'all' })
  const { isMobile, isWide } = useMobile()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { showGlobalLoading, changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  const [submitSuccess, setSubmitSuccess] = React.useState<boolean>(false)
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const { t: m } = useTranslation('message')
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:ClaimServices'),
          navigate: () =>
            props.navigation.navigate('ClaimListScreen', {
              policyNum: props.route.params.policyNum,
              policyOwner: props.route.params.policyOwner,
              officeCode: props.route.params.officeCode,
              officeName: props.route.params.officeName
            })
        },
        { title: t('ClaimPayout'), navigate: null }
      ])
    }
  }, [isFocused])

  const bankBranch = paymentForm.watch('bankBranch.value') || paymentForm.getValues('bankBranch.value')
  const bankValue = paymentForm.watch('bank.value')
  const [skipCheckNapas, setSkipCheckNapas] = React.useState(false)

  const menus = [
    {
      label: t('PolicyNumber'),
      value: props?.route?.params?.policyNum || '-'
    },
    {
      label: t('PolicyOwner'),
      value: props?.route?.params?.policyOwner || '-'
    },
    {
      label: t('Office'),
      value: props?.route?.params?.officeName || '-'
    }
  ]

  const tabs = [
    {
      id: 1,
      title: t('RequestInfo')
    },
    {
      id: 2,
      title: t('PayoutInfo')
    }
  ]

  const requestInfoForm = useForm<RequestInfoForm>({ criteriaMode: 'firstError', mode: 'all' })
  const uuid = generateUUID()
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const { data, requestFormDefaultValues } = pipe(
    ClaimService.getDataAccessPayout(props.route.params.policyNum, uuid),
    ZIO.map((data) => {
      const requestFormDefaultValues = {
        isNotPolicyOwner: false,
        ownerName: data?.ownerName || '-',
        ownerPhoneCode: data?.ownerPhoneCode || null,
        ownerPhoneNumber: data?.claimantPhoneNumber || '-',
        ownerId: data?.ownerId || '-',
        ownerEmail: data?.ownerEmail || '-',
        ownerContractName: data?.contractName,
        lifeAssured: data?.laList[0] || null,
        claimantName: data?.ownerName || '-',
        claimantPhoneCode: data?.ownerPhoneCode || null,
        claimantPhoneNumber: data?.ownerPhoneNumber || '-',
        claimantAddress: data?.ownerAddress || '-',
        claimantEmail: data?.ownerEmail || '-',
        dob: data?.dob
      }
      requestInfoForm.reset(requestFormDefaultValues)
      return {
        data,
        requestFormDefaultValues
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  ) || {
    data: null,
    requestFormDefaultValues: null
  }

  const onChangeTab = (i: number) => {
    if (i < tabIndex) setTabIndex(i)
  }
  const onNextPaymentTab = requestInfoForm.handleSubmit((data) => {
    setTabIndex(2)
  })

  const onSubmit = paymentForm.handleSubmit((submitData) => {
    const {
      claimType,
      claimEventDate,
      isRelatedToAccident,
      subClaimType,
      payableAmount,
      claimDecision,
      effectiveDate,
      policyStatus,
      claimNoPayout,
      businessKey,
      productCode,
      planName,
      surgeryBenefit,
      refCaseId,
      claimantEmail
    } = requestInfoForm.getValues()

    const data: ClaimPayoutSubmissionC = {
      claimInfo: {
        claimNo: claimNoPayout?.label ?? '',
        claimType: claimType?.value ?? '',
        subClaimType: subClaimType?.value ?? '',
        relatedOfAccident: isRelatedToAccident === 'Y' ? true : false,
        claimEventDate: claimEventDate ? moment(claimEventDate).format('YYYY-MM-DD') : '',
        productCode: productCode || '',
        planName: planName || '',
        surgeryBenefit: surgeryBenefit,
        endClaimDate: '',
        parentBusinessKey: businessKey || '',
        lastVerUpdatedBy: '',
        lastVerUpdatedDate: '',
        lastAdjUpdatedBy: '',
        lastAdjUpdatedDate: '',
        refCaseId: refCaseId || '',
        emailPO: claimantEmail || ''
      },
      adjudicationInfo: {
        payableAmount: payableAmount.replace(/,/g, ''),
        claimDecision: claimDecision,
        effectiveDate: effectiveDate ? moment(effectiveDate).format('YYYY-MM-DD') : '',
        policyStatus: policyStatus
      },
      paymentInfo: {
        cashOutOptions: [
          {
            type: submitData.paymentMode?.value ?? '',
            policy: {
              policyNo: props?.route.params.policyNum,
              totalPremium: 0,
              status: policyStatus
            },
            paymentOption: {
              currency: 'VND',
              amount: submitData.paymentAmount ? Number(submitData.paymentAmount?.replace(/,/g, '')) : 0
            },
            bankAccount: {
              bankName: submitData.bank?.label ?? '',
              bankCode: submitData.bank?.value ?? '',
              branchCode: submitData.bankBranch?.value ?? '',
              accountNo: submitData.bankAccountNumber ?? '',
              accountName: submitData.bankAccountName ?? ''
            },
            attributesExt: {
              PAYEE_INFO: {
                paymentMethod: submitData.paymentMode?.value ?? '',
                isNewClient: !submitData.payeeIsPolicyOwner ? true : false,
                isPayeeNotPO: submitData.payeeIsNotPolicyOwner,
                changePayeeInfo: submitData.changePayeeInfo as boolean,
                payeeName: submitData.payeeName ?? '',
                idNumber: submitData.bankIdNumber,
                dob: moment(submitData.dob).format('DD/MM/YYYY'),
                gender: submitData.gender?.value ?? '',
                occupation: submitData.occupation?.value ?? '',
                relationshipWithPolicyOwner: submitData.relationshipWithPO?.value ?? '',
                nationality: submitData.nationality?.value ?? '',
                nationality2: submitData.nationality2?.value ?? '',
                residenceCountry: submitData.residenceCountry?.value ?? '',
                address: submitData.address,
                foreignAddress: submitData.foreignAddress
              }
            }
          }
        ]
        // refNumber: `${moment().format('YYMMDDHHmmss')}${generateUUID(4)}`
      }
    }
    showGlobalLoading(true)
    pipe(
      ClaimService.submitPayout(
        props.route.params.policyNum,
        data,
        uuid,
        props.route.params.officeCode,
        true,
        paymentForm.getValues('payeeId')
      ),
      ZIO.map((_) => {
        setSubmitSuccess(true)
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
    // return pipe(
    //   !skipCheckNapas ? ZIO.succeed({
    //     body: null,
    //     status: null,
    //     poll: null,
    //     responseStatus: null
    //   }) :
    //   ClaimService.checkBankNapas({
    //     accountName: submitData.bankAccountName,
    //     accountNo: submitData.bankAccountNumber,
    //     bankName: submitData.bank?.value ?? '',
    //     branchCode: submitData.bankBranch?.value ?? ''
    //   }),
    //   ZIO.flatMap((res) => {
    //     return pipe(
    //       ClaimService.submitPayout(
    //         props.route.params.policyNum,
    //         data,
    //         uuid,
    //         props.route.params.officeCode,
    //         true,
    //         paymentForm.getValues('payeeId')
    //       ),
    //       ZIO.map((_) => {
    //         setSubmitSuccess(true)
    //         return ZIO.unit
    //       }),
    //     )
    //   }),
    //   ZIO.catchAll((error) => { console.log(error, 'error')
    //     let message = ''
    //     if (error?.source?.response?.data?.status?.code === 115021) {
    //       message = m('MS070029', { name: submitData.bankAccountName })
    //     } else if(error?.source?.response?.data?.status?.code === 115020) {
    //       message = m('MS070030')
    //     } else {
    //       message = m('MS030197')
    //     }
    //     return ZIO.effect(() => {
    //       showToast(message || m('MS030029'), 'error', {
    //         horizontal: 'right',
    //         vertical: 'top'
    //       })
    //     })
    //   }),
    //   ZIO.unsafeRun({})
    // )
  })
  const handleSuccessClose = () => {
    props.navigation.navigate('ClaimListScreen', props.route.params)
  }
  const renderFooter = (tab: number) => {
    return (
      <View style={[styles.footer, isWide ? { marginRight: 19 } : {}]}>
        {tab === 1 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              // paymentResetCallbackRef.current()
            }}
          >
            <Text style={styles.textButton}>{t('ClearAll')}</Text>
          </TouchableOpacity>
        )}

        {tab === 1 && (
          <TouchableOpacity style={styles.button} onPress={onNextPaymentTab}>
            <Text style={styles.textButton}>{t('Continue')}</Text>
          </TouchableOpacity>
        )}

        {tab === 2 && (
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              // docResetCallbackRef.current()
            }}
          >
            <Text style={styles.textButton}>{t('ClearAll')}</Text>
          </TouchableOpacity>
        )}

        {tab === 2 && (
          <TouchableOpacity style={styles.button} onPress={onSubmit} disabled={isLoading}>
            <Text style={styles.textButton}>
              {t('SendRequest')} {isLoading && <ActivityIndicator color="red" />}
            </Text>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  // React.useEffect(() => {
  //   bankBranch !== null && tabIndex === 2 && paymentForm.watch('paymentMode')?.value === PaymentType.BANKTRANSFER && pipe(
  //     ClaimService.checkBenID(bankBranch),
  //     ZIO.map((res) => {
  //       let isCheck = res.napasFlag === "Y" ? true : false
  //       setSkipCheckNapas(isCheck)
  //       !isCheck && paymentForm.setError('bank', {
  //         message: m('MSBENID')
  //       })
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  // }, [bankBranch, tabIndex, paymentForm.watch('paymentMode')?.value])

  // React.useEffect(() => {
  //   setSkipCheckNapas(false)
  //   paymentForm.setError('bank', {
  //     message: ''
  //   })
  // }, [bankValue])

  return (
    <SafeAreaView style={{ flex: 1, width: '100%' }}>
      <ScrollView>
        <View style={{ width: '100%' }}>
          <PolicyInfoHeader menus={menus} />
        </View>
        <View style={{ marginHorizontal: 15 }}>
          <TabList menus={tabs} isSubTab={false} useOriginalId={true} tabIndex={tabIndex} onChangeTab={onChangeTab} />
        </View>
        <View style={{ backgroundColor: '#FFFFFF', height: '100%', overflow: 'hidden' }}>
          {tabIndex === 1 && data !== null && (
            <RequestInfoTab
              isValid={isValid}
              form={requestInfoForm}
              paymentForm={paymentForm}
              claimDataAccess={data}
              onNext={() => {}}
              onReset={() => requestInfoForm.reset({ ...requestFormDefaultValues })}
              transaction={'PAYOUT'}
            />
          )}
          {tabIndex === 2 && data !== null && (
            <PaymentMethod
              policyNum={props.route.params.policyNum}
              officeCode={props.route.params.officeCode}
              dob={requestInfoForm.getValues('dob') || ''}
              form={paymentForm}
              isLoading={loading}
              triggerOnReset={() => {}}
              triggerOnNext={() => {}}
              claimType={requestInfoForm.getValues('claimType')?.value || ''}
              transaction={'PAYOUT'}
            />
          )}
        </View>
      </ScrollView>
      {renderFooter(tabIndex)}
      <Toast
        visible={submitSuccess === true}
        message={t('message:MS070017', { claimNumber: requestInfoForm.getValues().claimNo })}
        type={'success'}
        onClose={handleSuccessClose}
      />
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  container: {
    height: 68,
    marginTop: 15,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  icon: {
    height: 22,
    width: 17
  },
  label: {
    marginLeft: 10,
    fontSize: 17
  },
  // Tab ++
  tabLabel: {
    paddingTop: 8,
    paddingBottom: 8
  },
  tabLabelActive: {
    color: 'red',
    fontWeight: 'bold'
  },
  tab: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E7',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  tabActive: {
    minWidth: 260,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14
  },
  // Tab --
  policyInfoContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  policyInfoSectionMobile: {
    flexDirection: 'row',
    width: '100%',
    paddingLeft: 10
  },
  policyInfoSectionWide: {
    flexDirection: 'row',
    flex: 1 / 3,
    width: 'auto',
    paddingLeft: 33
  },
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },

  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnRed: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
