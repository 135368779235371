import { Frequency } from './Frequency'

export namespace BillingChangeReversal {
  export interface DetailData {
    automaticPolicyLoanInterestDate: Date | null
    curBillingFrequency: string
    curInstalmentAmount: string
    oldBillingFrequency: Array<string>
    oldInstalmentAmount: string
    policyNo: string
    automaticPolicyLoanInterest: number
    requirePayInAmount: number
    oldBillingFreqFormField: Frequency | null
  }

  export interface DataCheckAccess {
    curFreq: string
    contractStatus: string
    exceed120days: boolean
    hasLeastOldTransaction: boolean
  }

  export interface SubmitData {
    automaticPolicyLoanInterestDate: string[]
    automaticPolicyLoanInterest: string
    curBillingFrequency: string
    curInstalmentAmount: string
    oldBillingFrequency: Frequency[]
    oldInstalmentAmount: string[]
    policyNo: string
    payInAmount: string
  }
}
