import { enumC } from '@mxt/zio/codec'
export namespace ProductEnum {
  export enum Status {
    IF = 'IF',
    LA = 'LA',
    CF = 'CF',
    SU = 'SU',
    MA = 'MA',
    PU = 'PU',
    DH = 'DH',
    PS = 'PS'
  }
  export enum PremiumStatus {
    HA = 'HA',
    PP = 'PP',
    SP = 'SP'
  }
  export const StatusC = enumC(Status)
}
