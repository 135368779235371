import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

const SignatureDocuments = t.type({
  name: t.string,
  url: t.string
})

const AuthenRequestForm = t.type({
  signatureDocuments: t.array(SignatureDocuments),
  authFlag: t.boolean
})

const Body = t.type({
  benefits: t.string,
  oldBenefit: t.string,
  currentSumAssured: t.number,
  newSumAssured: t.number,
  riskSumAssured: t.number,
  minimumSumAssured: t.number
})

const Payload = t.type({
  source: t.string,
  createdBy: t.string,
  body: Body,
  authenRequestForm: Maybe(AuthenRequestForm),
  documents: t.array(SignatureDocuments),
  // authenOption: t.string,
  primaryPolicyNo: t.string,
  clientId: t.string,
  // officeCode: t.string,
  createdDate: t.string,
  isCCE: t.boolean
})

export const ProductOptionSwitching = t.type({
  transactionType: t.literal(TransactionType.PRODUCT_OPTION_SWITCHING),
  payload: Payload
})

export type ProductOptionSwitching = t.TypeOf<typeof ProductOptionSwitching>
