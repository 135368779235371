import { SelectOption } from '@pulseops/common'

export namespace UnderwrittingForm {
  export interface UnderwrittingFormInfo {
    UWDecisionList: UnderwrittingDecision[]
  }
  export interface UnderwrittingDecision {
    role: string
    clientNumber: string
    clientName: string
    transactionType: string
    UWDecision: SelectOption
    oldUWDecision: string
    suspendContent: string
    historyNotes: HistoryNote[]
    isSubmitedEdit?: boolean
    isNewLA?: boolean
  }

  // export interface HistoryNotes {
  //   eventDate: string
  //   policyNo: string
  //   notes: string
  //   clientNumber: string
  // }

  export interface UnderwrittingNotesModalForm {
    listNotes: HistoryNote[]
  }

  export interface HistoryNote {
    eventDate: Date
    policyNo: string
    notes: string
    clientNumber: string
    isOldNote: boolean
  }
}
