/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { DaDoccumentData } from './da-duccument-data'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'

export namespace DaDocumentService {
  export const DetaiList = t.type({
    priority: Nullable(t.number),
    docID: Nullable(t.string),
    className: Nullable(t.string),
    maindoc: Nullable(t.string),
    subdoc: Nullable(t.string),
    required: Nullable(t.string),
    documentsEn: Nullable(t.string),
    documentsVn: Nullable(t.string),
    note: Nullable(t.string),
    xcondition: Nullable(t.string),
    suspendCode: Nullable(t.string),
    lastedDate: Nullable(t.string),
    status: Nullable(t.string),
    isClickSuspense: Nullable(t.boolean)
  })
  export type DetaiList = t.TypeOf<typeof DetaiList>

  export const DaDoccumentTypeC = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createdDate: Maybe(t.string),
    source: Maybe(t.string),
    channelId: Maybe(t.string),
    data: t.array(DetaiList),
    eref: Maybe(t.string)
  })
  export type DaDoccumentTypeC = t.TypeOf<typeof DaDoccumentTypeC>

  export const DaDoccumentRqData = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    source: Maybe(t.string),
    detailList: Maybe(t.array(DetaiList)),
    eref: Maybe(t.string)
  })
  export type DaDoccumentRqData = t.TypeOf<typeof DaDoccumentRqData>
  export const DaTMSDoccumentRqData = t.type({
    birthdayStr: Maybe(t.string),
    identifyPassport: Maybe(t.string),
    firstName: Maybe(t.string),
    lastName: Maybe(t.string),
    gender: Maybe(t.string),
    marriageStatus: Maybe(t.string),
    occupation: Maybe(t.string),
    education: Maybe(t.string),
    issueDateStr: Maybe(t.string),
    issuePlace: Maybe(t.string),
    mobile: Maybe(t.string),
    email: Maybe(t.string),
    permanentAddress: Maybe(t.string),
    permanentProvince: Maybe(t.string),
    permanentDistrict: Maybe(t.string),
    permanentWard: Maybe(t.string),
    contactAddress: Maybe(t.string),
    contactProvince: Maybe(t.string),
    contactDistrict: Maybe(t.string),
    contactWard: Maybe(t.string),
    unitCode: Maybe(t.string)
  })
  export type DaTSMDoccumentRqData = t.TypeOf<typeof DaTMSDoccumentRqData>
  export const DaDoccumentFile = t.type({
    data: t.array(
      t.type({
        docId: Maybe(t.string),
        createdDate: Maybe(t.string),
        url: Maybe(t.string),
        metaData: t.type({
          DateCreated: Maybe(t.string),
          Id: Maybe(t.string),
          batchno: Maybe(t.string),
          docid: Maybe(t.string),
          maindoc: Maybe(t.string),
          subdoc: Maybe(t.string)
        })
      })
    )
  })
  export type DaDoccumentFile = t.TypeOf<typeof DaDoccumentFile>

  export const getChannelDocument = (data: DaDoccumentData.DaDoccumentDataDataRule): Task<DaDoccumentTypeC> => {
    return pipe(
      POApi.post('distribution-agents-service/table/get-t73-ob-channel-document')(DaDoccumentTypeC)(data),
      ZIO.map((res) => res)
    )
  }

  export const saveDocumentLog = (data: DaDoccumentRqData): Task<DaDoccumentRqData> => {
    return pipe(
      POApi.post('distribution-agents-service/agent-document/save-agent-document-log')(DaDoccumentRqData)(data),
      ZIO.map((res) => res)
    )
  }

  export const getDocumentLog = (businessKey: string): Task<DaDoccumentRqData> => {
    return pipe(
      POApi.getConfig({ params: { businessKey } })('distribution-agents-service/agent-document/get-agent-document-log')(
        DaDoccumentRqData
      ),
      ZIO.map((res) => res)
    )
  }

  export const getDocumentFile = (idNo: string, docId: string, className: string): Task<DaDoccumentFile> => {
    return pipe(
      POApi.getConfig({ params: { idNo, docId, className } })(
        'distribution-agents-service/agent-document/get-agent-document-file'
      )(DaDoccumentFile),
      ZIO.map((res) => res)
    )
  }

  export const saveTMSDocumentLog = (data: DaTSMDoccumentRqData): Task<DaTSMDoccumentRqData> => {
    return pipe(
      POApi.post('distribution-agents-service/distribution-service/update-trainee-info')(DaTMSDoccumentRqData)(data),
      ZIO.map((res) => res)
    )
  }
}
