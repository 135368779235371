import { addCommasToCurrencyString } from '../service'

export const formatNumberWithComma = (value: number | null | string, defaultValue: string = '0'): string => {
  return value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : defaultValue
}

export const formatStringWithDot = (valueVal: string) => {
  const value = valueVal ? valueVal.replace(/[^0-9]/g, '') : ''
  const primativeNo = value.length >= 3 ? value.substring(0, value.length - 2) : ''
  const fractionNo = value.length >= 3 ? value.substring(value.length - 2, value.length) : ''
  const stringWithComma = value.length >= 3 ? primativeNo + '.' + fractionNo : value
  const moneyFormat = addCommasToCurrencyString(stringWithComma)
  return moneyFormat
}

export const formatNumberWithDot = (value: number | null | string): string => {
  return value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') : '-'
}

export const removeAccents = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}
export const getSurname = (name: string) =>
  name.length
    ? name
        .split(' ')
        .slice(0, name.split(' ').length - 1)
        .join(' ')
    : name
export const getGivenName = (name: string) => (name.length ? name.split(' ')[name.split(' ').length - 1] : name)
export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return !!value && value !== null && value !== undefined
}
export const parseDate = (dateStr: string): Date =>
  new Date(`${dateStr.slice(4, 6)}/${dateStr.slice(6, 8)}/${dateStr.slice(0, 4)}`)

export const formatTextFromLasToVN = (str: string): string => {
  if (!str) return ''
  str = str.replace(/y\~/g, 'ỹ')
  str = str.replace(/Y\~/g, 'Ỹ')
  str = str.replace(/y\?/g, 'ỷ')
  str = str.replace(/Y\?/g, 'Ỷ')
  str = str.replace(/y\./g, 'ỵ')
  str = str.replace(/Y\./g, 'Ỵ')
  str = str.replace(/y`/g, 'ỳ')
  str = str.replace(/Y`/g, 'Ỳ')
  str = str.replace(/u\+\./g, 'ự')
  str = str.replace(/U\+\./g, 'Ự')
  str = str.replace(/u\+\~/g, 'ữ')
  str = str.replace(/U\+\~/g, 'Ữ')
  str = str.replace(/u\+\?/g, 'ử')
  str = str.replace(/U\+\?/g, 'Ử')
  str = str.replace(/u\+`/g, 'ừ')
  str = str.replace(/U\+`/g, 'Ừ')
  str = str.replace(/u\+'/g, 'ứ')
  str = str.replace(/U\+'/g, 'Ứ')
  str = str.replace(/u\?/g, 'ủ')
  str = str.replace(/U\?/g, 'Ủ')
  str = str.replace(/u\./g, 'ụ')
  str = str.replace(/U\./g, 'Ụ')
  str = str.replace(/o\+\./g, 'ợ')
  str = str.replace(/O\+\./g, 'Ợ')
  str = str.replace(/o\+\~/g, 'ỡ')
  str = str.replace(/O\+\~/g, 'Ỡ')
  str = str.replace(/o\+\?/g, 'ở')
  str = str.replace(/O\+\?/g, 'Ở')
  str = str.replace(/o\+`/g, 'ờ')
  str = str.replace(/O\+`/g, 'Ờ')
  str = str.replace(/o\+'/g, 'ớ')
  str = str.replace(/O\+'/g, 'Ớ')
  str = str.replace(/o\^\./g, 'ộ')
  str = str.replace(/O\^\./g, 'Ộ')
  str = str.replace(/o\^\~/g, 'ỗ')
  str = str.replace(/O\^\~/g, 'Ỗ')
  str = str.replace(/o\^\?/g, 'ổ')
  str = str.replace(/O\^\?/g, 'Ổ')
  str = str.replace(/o\^`/g, 'ồ')
  str = str.replace(/O\^`/g, 'Ồ')
  str = str.replace(/o\^'/g, 'ố')
  str = str.replace(/O\^'/g, 'Ố')
  str = str.replace(/o\?/g, 'ỏ')
  str = str.replace(/O\?/g, 'Ỏ')
  str = str.replace(/o\./g, 'ọ')
  str = str.replace(/O\./g, 'Ọ')
  str = str.replace(/i\./g, 'ị')
  str = str.replace(/I\./g, 'Ị')
  str = str.replace(/i\?/g, 'ỉ')
  str = str.replace(/I\?/g, 'Ỉ')
  str = str.replace(/e\^\./g, 'ệ')
  str = str.replace(/E\^\./g, 'Ệ')
  str = str.replace(/e\^\~/g, 'ễ')
  str = str.replace(/E\^\~/g, 'Ễ')
  str = str.replace(/e\^\?/g, 'ể')
  str = str.replace(/E\^\?/g, 'Ể')
  str = str.replace(/e\^`/g, 'ề')
  str = str.replace(/E\^`/g, 'Ề')
  str = str.replace(/e\^'/g, 'ế')
  str = str.replace(/E\^'/g, 'Ế')
  str = str.replace(/e\~/g, 'ẽ')
  str = str.replace(/E\~/g, 'Ẽ')
  str = str.replace(/e\?/g, 'ẻ')
  str = str.replace(/E\?/g, 'Ẻ')
  str = str.replace(/e\./g, 'ẹ')
  str = str.replace(/E\./g, 'Ẹ')
  str = str.replace(/a\(\./g, 'ặ')
  str = str.replace(/A\(\./g, 'Ặ')
  str = str.replace(/a\(\~/g, 'ẵ')
  str = str.replace(/A\(\~/g, 'Ẵ')
  str = str.replace(/a\(\?/g, 'ẳ')
  str = str.replace(/A\(\?/g, 'Ẳ')
  str = str.replace(/a\(`/g, 'ằ')
  str = str.replace(/A\(`/g, 'Ằ')
  str = str.replace(/a\('/g, 'ắ')
  str = str.replace(/A\('/g, 'Ắ')
  str = str.replace(/a\^\./g, 'ậ')
  str = str.replace(/A\^\./g, 'Ậ')
  str = str.replace(/a\^\~/g, 'ẫ')
  str = str.replace(/A\^\~/g, 'Ẫ')
  str = str.replace(/a\^\?/g, 'ẩ')
  str = str.replace(/A\^\?/g, 'Ẩ')
  str = str.replace(/a\^`/g, 'ầ')
  str = str.replace(/A\^`/g, 'Ầ')
  str = str.replace(/a\^'/g, 'ấ')
  str = str.replace(/A\^'/g, 'Ấ')
  str = str.replace(/a\?/g, 'ả')
  str = str.replace(/A\?/g, 'Ả')
  str = str.replace(/a\./g, 'ạ')
  str = str.replace(/A\./g, 'Ạ')
  str = str.replace(/u\+/g, 'ư')
  str = str.replace(/U\+/g, 'Ư')
  str = str.replace(/o\+/g, 'ơ')
  str = str.replace(/O\+/g, 'Ơ')
  str = str.replace(/u\~/g, 'ũ')
  str = str.replace(/U\~/g, 'Ũ')
  str = str.replace(/i\~/g, 'ĩ')
  str = str.replace(/I\~/g, 'Ĩ')
  str = str.replace(/dd/g, 'đ')
  str = str.replace(/a\(/g, 'ă')
  str = str.replace(/A\(/g, 'Ă')
  str = str.replace(/y'/g, 'ý')
  str = str.replace(/u'/g, 'ú')
  str = str.replace(/u`/g, 'ù')
  str = str.replace(/o\~/g, 'õ')
  str = str.replace(/o\^/g, 'ô')
  str = str.replace(/o'/g, 'ó')
  str = str.replace(/o`/g, 'ò')
  str = str.replace(/i'/g, 'í')
  str = str.replace(/i`/g, 'ì')
  str = str.replace(/e\^/g, 'ê')
  str = str.replace(/e'/g, 'é')
  str = str.replace(/e`/g, 'è')
  str = str.replace(/a\~/g, 'ã')
  str = str.replace(/a\^/g, 'â')
  str = str.replace(/a'/g, 'á')
  str = str.replace(/a`/g, 'à')
  str = str.replace(/Y'/g, 'Ý')
  str = str.replace(/U'/g, 'Ú')
  str = str.replace(/U`/g, 'Ù')
  str = str.replace(/O\~/g, 'Õ')
  str = str.replace(/O\^/g, 'Ô')
  str = str.replace(/O'/g, 'Ó')
  str = str.replace(/O`/g, 'Ò')
  str = str.replace(/DD/g, 'Đ')
  str = str.replace(/I'/g, 'Í')
  str = str.replace(/I`/g, 'Ì')
  str = str.replace(/E\^/g, 'Ê')
  str = str.replace(/E'/g, 'É')
  str = str.replace(/E`/g, 'È')
  str = str.replace(/A\~/g, 'Ã')
  str = str.replace(/A\^/g, 'Â')
  str = str.replace(/A'/g, 'Á')
  str = str.replace(/A`/g, 'À')
  return str
}
