import { View, Text, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import { useMobile } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { LandingPageCCCDStackParamList } from './LandingPageCCCDStackParamList'
import { BorderFilledInput, SC } from './core'
import { Header } from './Header'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

type Props = StackScreenProps<LandingPageCCCDStackParamList, 'IdentifycardScreen'>

export const Identifycard = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation('landingPage')
  const { t: m } = useTranslation('message')
  const { params } = props.route
  const handleSave = () => {
    props.navigation.navigate('ChangeNationalIdCardScreen', {
      idNumber: params.idNumber,
      phoneNumber: params.phoneNumber,
      policyNumber: params.policyNumber,
      dob: '',
      files: props.route.params.files,
      identifyNumberOCR: params.id || '',
      idExpreiDateOCR: params.doe || '',
      fullNameOCR: params.name || '',
      dobOCR: params.dob || '',
      sexOCR: params.sex || ''
    })
  }
  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#FFFFFF' }}>
      <Header isWide={isWide} t={t} />
      <ScrollView>
        <SC.ContainerPolicyList maxWidth="566" isWide={isWide}>
          <View style={styles.form}>
            <SC.Form.Group marginBottom="0">
              <SC.Form.Row marginBottom="18" isMobile={isMobile} alignSelf="center">
                <SC.MessageTitle isWide={isWide}>{t('uploadCCCD')}</SC.MessageTitle>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <BorderFilledInput
                  controlStyle={{ width: '100%' }}
                  label={`${t('IdPicture')}`}
                  value={params.id || ''}
                  errorMessage={t('WarningIdentifycard', { percent: params.id_prob })}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <BorderFilledInput
                  controlStyle={{ width: '100%' }}
                  label={`${t('NamePicture')}`}
                  value={params.name || ''}
                  errorMessage={t('WarningIdentifycard', { percent: params.name_prob })}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <BorderFilledInput
                  controlStyle={{ width: '100%' }}
                  label={`${t('DobPicture')}`}
                  value={params.dob || ''}
                  errorMessage={t('WarningIdentifycard', { percent: params.dob_prob })}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <BorderFilledInput
                  controlStyle={{ width: '100%' }}
                  label={`${t('ExpireDatePicture')}`}
                  value={params.doe || ''}
                  errorMessage={t('WarningIdentifycard', { percent: params.doe_prob })}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <BorderFilledInput
                  controlStyle={{ width: '100%' }}
                  label={`${t('Gender')}`}
                  value={params.sex || ''}
                  errorMessage={t('WarningIdentifycard', { percent: params.sex_prob })}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <TouchableOpacity style={styles.btnCancel} onPress={() => props.navigation.goBack()}>
                  <Text style={{ color: '#EE1A2D', fontFamily: 'Noto Sans' }}>{t('cancel')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnSave} onPress={handleSave}>
                  <Text style={{ color: 'white', fontFamily: 'Noto Sans' }}>{t('save')}</Text>
                </TouchableOpacity>
              </SC.Form.Row>
            </SC.Form.Group>
          </View>
        </SC.ContainerPolicyList>
      </ScrollView>
      <View style={styles.footer}>
        <SC.FooterText isWide={isWide}>{t('copy_right')}</SC.FooterText>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#000000'
  },
  btnCancel: {
    padding: 10,
    width: '46%',
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#EE1A2D',
    backgroundColor: 'white',
    marginRight: '4%'
  },
  btnSave: {
    padding: 10,
    width: '46%',
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#EE1A2D',
    backgroundColor: '#EE1A2D'
  }
})
