import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView, Platform } from 'react-native'
import { ControlProps } from '../FormProps'
import { TiffFieFormat, assets, replaceSpecialCharactersInFileName, useMobile } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { PulseOpsFormat } from '../../Formatter'
import * as Linking from 'expo-linking'
import { FontAwesome5 } from '@expo/vector-icons'

export interface FileUploadData {
  fileName: string
  fileExtension: string
  size: number
  base64: string
  uploadedDate: Date
  file: File
}

type ImageMultiProps = ControlProps<FileUploadData[]> & {
  transactionType?: string
  maxNumFile?: number
  timeFormat?: string
  disabled?: boolean
  haveViewFile?: boolean
  clearFile?: boolean
  setTriggerClear?: (val: boolean) => void
}

export const ImgUploadMutiple = (props: ImageMultiProps) => {
  const { value, onChange, disabled,  } = props
  const { t } = useTranslation()

  const inputRef = React.createRef<HTMLInputElement>()
  //const upload = () => inputRef.current?.click()

  const MB = 1048576
  const maxSize = 10 * MB
  const maxTotal = 50 * MB
  // const maxFile = props.maxNumFile ? props.maxNumFile : 5
  const maxFile = props.maxNumFile
  const validExtensions = ['PNG', 'JPG', 'JPEG', 'TIF', 'TIFF', 'PDF']

  const [errorMess, setErrorMess] = React.useState<string>('')
  const [currSize, setCurrSize] = React.useState<number>(0)
  const { isMobile } = useMobile()
  const checkUpload = (files: FileUploadData[]) => {
    const file = files[0]

    if (!validExtensions.includes(file.fileExtension.toLocaleUpperCase())) {
      setErrorMess(t('message:MS040003', { files: validExtensions.join('/') }))
    } else if (file.size >= maxSize) {
      setErrorMess(t('message:MS040004'))
    } else if (currSize + file.size >= maxTotal) {
      setErrorMess(t('message:MS040005'))
    }
    // else if (value && value.length >= maxFile) {
    else if (maxFile && value && value.length >= maxFile) {
      setErrorMess(t('message:MS050279', { size: maxFile }))
    } else {
      onChange && onChange(value ? [...value, file] : [file])
      setCurrSize(currSize + file.size)
      setErrorMess('')
    }
  }

  const deleteItem = (i: number) => {
    setErrorMess('')
    const temp = value || []
    temp.splice(i, 1)
    onChange && onChange(temp)
  }

  const handleUpload = (files: FileList) => {
    const fileList: FileUploadData[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      const reader = new FileReader()
      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''
      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: newFile.name.split('.')[0],
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && checkUpload(fileList)
      }
    }
  }

  const ProxyView = isMobile ? View : ScrollView
  const composeContainerWidth = isMobile ? '100%' : props.haveViewFile ? 380 : 368.5
  const composeProxyView = '100%'
  // const composeHeightPicture = isMobile ? undefined : '100%'

  React.useEffect(() => {
    if(props.clearFile) {
      setErrorMess('')
      props.setTriggerClear && props.setTriggerClear(false)
    }
  }, [props.clearFile])

  return (
    <View>
      <View style={{ flexDirection: isMobile ? 'column' : 'row', marginTop: 10 }}>
        {!disabled && (
          <View style={{ width: composeContainerWidth }}>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                style={[styles.uploadContainer, { marginRight: 10 }]}
                // onPress={() => {
                //   upload()
                // }}
              >
                <assets.UploadCloud />
                <Text style={{ fontSize: 12 }}>{t('common:DropFileHere')}</Text>
                <Text style={{ fontSize: 12 }}>{t('common:or')}</Text>
                <Text style={{ fontSize: 12, color: '#ED1B1C' }}>{t('common:SelectFromComputer')}</Text>
                <input
                  ref={inputRef}
                  //hidden
                  style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    fontSize: 20,
                    cursor: 'pointer',
                    opacity: 0,
                    borderWidth: 1
                  }}
                  type="file"
                  multiple={true}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if(event.target.files?.length === 0 && !event.target.files) {
                      setErrorMess('')
                    }
                    if (event.target.files) {
                      handleUpload(event.target.files)
                      event.target.files = null
                      event.target.value = ''
                    }
                  }}
                  onDrop={(event: React.DragEvent<HTMLInputElement>) => {
                    event.preventDefault()
                    if (event.dataTransfer.files) {
                      handleUpload(event.dataTransfer.files)
                    }
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity style={[styles.uploadContainer]} disabled>
                <assets.UploadCamera />
                <Text style={{ fontSize: 12 }}>{t('common:TakeAPicture')}</Text>
              </TouchableOpacity>
            </View>
            <Text style={{ fontSize: 15, opacity: 0.6, fontStyle: 'italic' }}>
              {t('common:ImageFormat', { fileExtension: validExtensions.join(', ') })}
            </Text>
          </View>
        )}
        <ProxyView
          style={{ width: composeProxyView, paddingVertical: isMobile ? 16 : 0, maxHeight: isMobile ? undefined : 250 }}
        >
          <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
            {value &&
              value.map((file, i) => (
                <View style={[styles.resultItem, { ...props.haveViewFile && { width: 290 }}]} key={`resultItem-${i}`}>
                  <Image source={{ uri: file.base64 }} style={{ height: 70, width: 70 }}></Image>
                  <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                    <Text style={{ fontSize: 15 }}>
                      <Text> {file.fileName.length < 10 ? file.fileName : `${file.fileName.substr(0, 5)}...`} </Text>.
                      {file.fileExtension}
                    </Text>
                    <Text style={{ fontSize: 15, fontStyle: 'italic', marginTop: 20 }}>
                      {PulseOpsFormat.datetoFormat(file.uploadedDate, props.timeFormat || 'DD/MM/YYYY')}
                    </Text>
                  </View>
                  <View style={{ position: 'absolute', right: 0, paddingTop: 20, flexDirection: 'row' }}>
                    <View
                    //hitSlop={{right: 20, left: 20, top: 20, bottom: 20,}}
                    >
                      {Platform.OS === 'web' ? (
                        <View style={{ padding: 5 }}>
                          <a href={file.base64} download={`${file.fileName}.${file.fileExtension}`}>
                            <assets.Download />
                          </a>
                        </View>
                      ) : (
                        <TouchableOpacity
                          //style={{ position: 'absolute', right: 0, paddingTop: 20 }}
                          hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                          onPress={() => {
                            Linking.openURL(file.base64)
                          }}
                          style={{ padding: 5 }}
                        >
                          <assets.DeleteIcon />
                        </TouchableOpacity>
                      )}
                    </View>

                    {props.haveViewFile && 
                      <TouchableOpacity
                        //style={{ position: 'absolute', right: 0, paddingTop: 20 }}
                        hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                        onPress={async () => {
                          const base64Response = await fetch(file.base64)
                          const blob = await base64Response.blob();
                          const blobUrl = window.URL.createObjectURL(blob)
                          const extension = file.fileExtension?.toLocaleLowerCase()
                          if(extension === 'tif' || extension === 'tiff'){
                            TiffFieFormat.viewTiffFileByNewTab(blobUrl)
                          }
                          else{
                            window.open(blobUrl, '_blank')
                          }
                        }}
                        style={{ paddingHorizontal: 2, paddingTop: 10 }}
                      >
                        <FontAwesome5 style={{ fontSize: 15 }} name="eye" color="#ED1B2E"/>
                      </TouchableOpacity>
                    }

                    {!disabled && (
                      <TouchableOpacity
                        //style={{ position: 'absolute', right: 0, paddingTop: 20 }}
                        hitSlop={{ right: 20, left: 20, top: 20, bottom: 20 }}
                        onPress={() => deleteItem(i)}
                        style={{ padding: 5 }}
                      >
                        <assets.DeleteIcon />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              ))}
          </View>
        </ProxyView>
      </View>
      <Text style={{ color: '#ED1B2C', fontSize: 11.25 }}>{errorMess}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  common: {
    height: 31,
    fontSize: 15,
    borderBottomWidth: 0
  },
  dashline: {
    borderBottomColor: '#D3DCE6',
    borderBottomWidth: 1
  },
  uploadContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    // font-size: 12px;
    // border: 1px dashed #D3DCE6,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    height: 156,
    width: '48%'
  },
  resultItem: {
    height: 70,
    width: 275,
    flexDirection: 'row',
    borderBottomColor: '#92B0B3',
    borderBottomWidth: 2,
    borderStyle: 'dashed',
    marginBottom: 10,
    marginHorizontal: 10
  }
})
