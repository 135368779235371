/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  FieldList,
  FileUploadData,
  form2,
  ImgUploadMutiple,
  Input,
  Panel,
  useMobile,
  assets,
  TransactionType,
  SelectSearch,
  getLanguage,
  AppContext,
  RadioButton,
  SubmissionService
} from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { SC, UploadedFilesInfo } from '../common'
import { PolicyServicePayload } from '../AgentWrapScreen'
import { AgentTransferForm } from './agent-transfer-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { AgentConst } from '../common'

export interface AgentRef {
  clearForm: () => void
  submit: () => void
}

interface Props {
  isContinue: (val: boolean) => void
  isTab: boolean
  onCheckValid: (value: number) => void
  dataForm: (data: PolicyServicePayload) => void
  agentNum: string
  setErrMessage: (value: string) => void
  officeCode: string
}

export const AgentTransferScreen = forwardRef<AgentRef, Props>(
  ({ isContinue, isTab, onCheckValid, dataForm, agentNum, officeCode, setErrMessage }, ref: React.Ref<AgentRef>) => {
    const { isMobile } = useMobile()
    const { t } = useTranslation()
    const [isLoading, bindLoader] = useLoading(false)
    const { showToast } = React.useContext(AppContext.AppContextInstance)
    const [dataGet, setDataGet] = useState<SubmissionService.transferDetail>()
    const dataTransferAgent = dataGet?.transferAgent

    const form = form2.useForm(AgentTransferForm.codec, {
      defaultValues: {
        AgentCode: '',
        PolicyCode: '',
        dataPayload: {},
        checkedSignature: false,
        transferFiles: []
      }
    })

    const {
      handleSubmit,
      base: {
        control,
        reset,
        getValues,
        watch,
        setValue,
        formState: { errors }
      }
    } = form

    const agentN = watch('AgentCode')
    const policyN = watch('PolicyCode')

    const agentInfoList: FieldList.FieldType[] = [
      {
        label: t('submission:Status'),
        value: dataGet?.statcode ?? '-'
      },
      {
        label: t('submission:IssueDate'),
        value: dataGet?.issueDate ?? '-'
      },
      {
        label: t('submission:PolicyYear'),
        value: dataGet?.policyYear ?? '-'
      },
      {
        label: t('submission:AgentNumberTran', { field: '1' }),
        value: dataGet?.serviceAgents[0]?.agentCode ?? '-'
      },
      {
        label: t('submission:AgentNameTran', { field: '1' }),
        value: dataGet?.serviceAgents[0]?.agentName ?? '-'
      },
      {
        label: t('submission:TerminationDateTran', { field: '1' }),
        value: dataGet?.serviceAgents[0]?.terminateDate ?? '-'
      },
      {
        label: t('submission:BDMName', { field: '1' }),
        value: dataGet?.serviceAgents[0]?.regionDescription ?? '-',
        colLength: '100%'
      },
      {
        label: t('submission:AgentNumberTran', { field: '2' }),
        value: dataGet?.serviceAgents[1]?.agentCode ?? '-'
      },
      {
        label: t('submission:AgentNameTran', { field: '2' }),
        value: dataGet?.serviceAgents[1]?.agentName ?? '-'
      },
      {
        label: t('submission:TerminationDateTran', { field: '2' }),
        value: dataGet?.serviceAgents[1]?.terminateDate ?? '-'
      },
      {
        label: t('submission:BDMName', { field: '2' }),
        value: dataGet?.serviceAgents[1]?.regionDescription ?? '-'
      },
      {
        label: t('submission:Salezone'),
        value: dataGet?.serviceAgents[0]?.salezone ?? '-'
      }
    ]

    const radio = 'radio'

    const itemQualified: FieldList.FieldType = !isTab
      ? {
          label: t('submission:QualifiedProduct'),
          fieldType: radio,
          value: dataTransferAgent?.qualifiedProduct ?? 'N',
          radioOptions: [
            { label: t('common:Yes'), id: 'Y' },
            { label: t('common:No'), id: 'N' }
          ]
        }
      : { label: t('submission:QualifiedProduct'), value: dataTransferAgent?.qualifiedProduct ?? 'N' }

    const newAgentList: FieldList.FieldType[] = [
      { label: t('submission:NewAgentNumber'), render: ({}) => AgentNumberView() },
      { label: t('submission:NewAgentName'), value: dataTransferAgent?.agentName ?? '-' },
      { label: t('submission:AppointedDate'), value: dataTransferAgent?.appointedDate ?? '-' },
      { label: t('submission:TerminationDateTran'), value: dataTransferAgent?.terminateDate ?? '-' },
      { label: t('submission:BDMName'), value: dataTransferAgent?.regionDescription ?? '-' },
      { label: t('submission:WorkTime'), value: dataTransferAgent?.workTime ?? '-' },
      { label: t('submission:Salezone'), value: dataTransferAgent?.salezone ?? '-' },
      { label: t('submission:RateOfContract'), value: dataTransferAgent?.rateInforce ?? '-' },
      itemQualified,
      {
        label: t('submission:ReasonFor'),
        render: ({}) => ReasonView()
      },
      { label: t('submission:CheckedSignature'), render: ({}) => CheckedSignatureView() }
    ]

    const AgentNumberView = () => {
      return (
        <Controller
          control={control}
          name={'AgentCode'}
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
            return (
              <Input
                // title={t('common:Policy')}
                value={value}
                onChange={onChange}
                maxLength={8}
                onBlur={onBlur}
                required={true}
                disabled={isTab}
                errorMessage={error?.message}
              />
            )
          }}
        />
      )
    }

    const CheckedSignatureView = () => {
      return (
        <Controller
          name="checkedSignature"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <SC.Padding>
                <SC.Divider />
                <SC.Row>
                  {!isTab ? (
                    <>
                      <RadioButton
                        label={t('common:Yes')}
                        selected={value}
                        onChange={() => {
                          onChange.call(null, true)
                          isContinue(false)
                        }}
                      />
                      <RadioButton
                        label={t('common:No')}
                        selected={!value}
                        onChange={() => {
                          onChange.call(null, false)
                          isContinue(true)
                        }}
                      />
                    </>
                  ) : (
                    <Text>{t('common:Yes')}</Text>
                  )}
                </SC.Row>
              </SC.Padding>
            )
          }}
        />
      )
    }

    const getAttachmentFiles = () => {
      let fileList: UploadedFilesInfo[] = []
      const fileUpload = form.base.getValues()
      const files = fileUpload.transferFiles as FileUploadData[]
      const documentFiles: UploadedFilesInfo = {
        uploadFiles: files,
        transactionType: TransactionType.AGENT_TRANSFER_POLICY,
        docTypeCode: 'DSA01',
        category: 'DS',
        agentNumber: fileUpload.AgentCode,
        officeCode: officeCode ?? ''
      }
      fileList.push(documentFiles)
      return fileList
    }

    const onReset = () => {
      reset({
        AgentCode: '',
        PolicyCode: '',
        dataPayload: {},
        checkedSignature: false,
        transferFiles: []
      })
    }

    const onSubmit = handleSubmit((values) => {
      const newOb = {
        transferPolicy: {
          ...getValues().dataPayload?.transferPolicy,
          transferReason: getValues('reasonForChange.value')
        }
      }
      dataForm({
        url: (agentNum: string) => `distribution-agents-service/distribution-service/registerFromPulseOp`,
        body: newOb,
        collerationId: agentNum,
        method: 'POST',
        transactionName: TransactionType.AGENT_TRANSFER_POLICY,
        uploadedFilesInfo: getAttachmentFiles()
      })
      onCheckValid(1)
    })

    useImperativeHandle(ref, () => ({
      clearForm: onReset,
      submit: onSubmit
    }))

    useEffect(() => {
      if (!!agentNum) {
        setValue('PolicyCode', agentNum)
      }
      isContinue(true)
    }, [])

    React.useMemo(() => {
      if (policyN && policyN.length > 7) {
        return pipe(
          agentN && agentN.length > 7
            ? SubmissionService.getDetailTransfer(policyN, agentN)
            : SubmissionService.getDetailTransfer(policyN),
          ZIO.tap((res) => {
            if (res) {
              setDataGet(res ?? {})
              setValue('dataPayload.transferPolicy', res || {})
            } else {
              setDataGet(undefined)
              showToast('Policy is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Policy is not found', 'info')
            setDataGet(undefined)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        return
      }
    }, [policyN])

    React.useMemo(() => {
      if (agentN && agentN.length > 7) {
        return pipe(
          SubmissionService.getDetailTransfer(policyN, agentN),
          ZIO.tap((res) => {
            if (res) {
              setDataGet(res ?? {})
              setValue('dataPayload.transferPolicy', res || {})
            } else {
              setDataGet(undefined)
              showToast('Agent is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Agent is not found', 'info')
            setDataGet(undefined)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        return
      }
    }, [agentN])

    const ReasonView = () => {
      return (
        <Controller
          name="reasonForChange"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <SelectSearch
                required={true}
                options={AgentConst.TransferList.map((item) => ({
                  label: getLanguage() === 'en' ? item.nameEn : item.nameVn,
                  value: item.value
                }))}
                placeholder={t('common:Select')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? { value: '', label: '' }}
                errorMessage={error?.message}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                disabled={isTab}
              />
            )
          }}
        />
      )
    }

    return (
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.container}>
          <View style={{ flex: 1 }}>
            <Panel title={t('submission:PolicyInformation')} isChangeIcon={true}>
              <View style={[isMobile ? styles.rowMobile : styles.row]}>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <Controller
                    control={control}
                    name={'PolicyCode'}
                    render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                      return (
                        <Input
                          title={t('common:Policy')}
                          value={value}
                          onChange={onChange}
                          maxLength={8}
                          onBlur={onBlur}
                          required={true}
                          disabled={true}
                          errorMessage={error?.message}
                        />
                      )
                    }}
                  />
                </View>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <View>
                    <Text style={[isMobile ? styles.labelMobile : styles.label]}>{t('submission:PolicyOn')}</Text>
                    <Text style={[styles.value]}>{dataGet?.cownName}</Text>
                  </View>
                </View>
                <View style={isMobile ? styles.colFull : styles.col}>
                  <View>
                    <Text style={[isMobile ? styles.labelMobile : styles.label]}>{t('submission:Product')}</Text>
                    <Text style={[styles.value]}>{dataGet?.cnttype ?? '-'}</Text>
                  </View>
                </View>
              </View>
              <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
                <FieldList dataSource={agentInfoList} />
              </View>
            </Panel>
          </View>
          <Panel title={t('submission:TransferInfo').toUpperCase()} isChangeIcon={true}>
            <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
              <FieldList dataSource={newAgentList} />
            </View>
            <View style={styles.colFull}>
              <View style={{ flexDirection: 'row' }}>
                <Text style={[styles.label]}>{t('CFI:CFIFileAttachment')}</Text>
                <Text style={[styles.requiredSub]}>*</Text>
              </View>
              <Controller
                control={control}
                name="transferFiles"
                render={({ field, fieldState: { error } }) => (
                  <Column>
                    <ImgUploadMutiple
                      {...field}
                      disabled={isTab}
                      onChange={field.onChange}
                      value={field.value as any}
                      errorMessage={error?.message || ''}
                    />
                    {error?.message && <Error message={error?.message} />}
                  </Column>
                )}
              />
            </View>
          </Panel>
        </View>
      </SafeAreaView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 'auto',
    justifyContent: 'center',
    display: 'flex'
  },
  row: {
    flexDirection: 'row',
    marginBottom: 32
  },
  rowMobile: {
    flexDirection: 'column',
    marginBottom: 0
  },
  col: {
    width: '33.33333%',
    marginTop: 32,
    paddingRight: 16
  },
  colFull: {
    width: '100%',
    paddingRight: 16,
    marginBottom: 16
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  requiredSub: {
    color: '#eD1B2E',
    marginLeft: 5,
    fontSize: 15,
    fontWeight: 'bold'
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },
  rowFooter: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  labelMobile: {
    color: '#70777E',
    fontSize: 15,
    lineHeight: 22
  }
})
