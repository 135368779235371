import React from 'react'
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native'
import { OBSharedStyles, OBTitle } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import {
  ErrorHandling,
  assets,
  Input,
  SelectSearch,
  SelectOption,
  GeneralService,
  BuildConfig,
  TransactionType,
  ChangeContactInfoService,
  ProductEnum,
  AppContext,
  Permission
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { OBGeneralService, OBPolicyData, OBPolicyOwnerData, OBTaskService } from '../../ob-service'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { CallOutDetailContext } from '../ob-common'
import moment from 'moment'
import { calloutPopupContext } from './OBCallOutPopup'
import { OBChangeContactInfoPopup } from './OBChangeContactInfo/OBChangeContactInfoPopup'

type OBContactListProps = {
  onCloseCallOutPopup?: () => void
}

export const OBContactList = (props: OBContactListProps) => {
  const [isLoading, bindLoader] = useLoading(false)
  const { t } = useTranslation()
  const {
    policyNumber,
    permissions,
    clientNumber,
    processInstanceId,
    onAvayaCallClickEvent,
    isOpenedAvayaCallBlock,
    isSavedAfterCallingAvaya,
    phoneType,
    setPhoneType,
    setIsUpdatedChangeContactInfo
  } = React.useContext(CallOutDetailContext)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { setPhoneNumber } = React.useContext(calloutPopupContext)
  const [otherPhoneNumber, setOtherPhoneNumber] = React.useState<string>('')
  const [isOpenCallOtherBlock, setIsOpenCallOtherBlock] = React.useState(false)
  const [dataPolicy, setDataPolicy] = React.useState<OBPolicyData | null>(null)
  const [mobiphoneCode, setMobiphoneCode] = React.useState<SelectOption | null>({ value: '84', label: '+84' })
  const [isOpenChangeContactPopup, setIsOpenChangeContactPopup] = React.useState(false)
  const isDisabledEdit = !permissions.includes(Permission['EditCallOutOBTaskDetail'])

  const dataPolicyOwner = pipe(
    OBGeneralService.getPolicyOwner(policyNumber),
    ZIO.map((data) => {
      return data
    }),
    bindLoader,
    ErrorHandling.runDidUpdate([policyNumber])
  )

  // const phoneCodeOptions: SelectOption[] = pipe(
  //   GeneralService.getMobileCodes,
  //   ZIO.map((codes) => {
  //     return codes.map((code) => ({
  //       label: `+${code.code}`,
  //       value: code.code
  //     }))
  //   }),
  //   ErrorHandling.runDidMount({})
  // )

  React.useEffect(() => {
    pipe(
      OBGeneralService.getPolicyInfo(policyNumber),
      ZIO.map((data) => {
        setDataPolicy(data)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [policyNumber])

  const diffTwoDateAsMonth = (d1: Date, d2: Date) =>
    moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'months')

  const onUpdateContanctInfoEvent = () => {
    setIsUpdatedChangeContactInfo && setIsUpdatedChangeContactInfo(true)
    pipe(
      ZIO.zipPar(
        ChangeContactInfoService.getDataAccess(policyNumber),
        OBTaskService.saveIsClickChangeMobileInfo(processInstanceId)
      ),
      ZIO.map(([accessDataCheck]) => {
        if (
          !accessDataCheck.contractStatus ||
          !accessDataCheck.paidToDateAdvance ||
          (accessDataCheck.contractStatus !== ProductEnum.Status.IF &&
            accessDataCheck.contractStatus !== ProductEnum.Status.LA) ||
          (accessDataCheck.contractStatus === ProductEnum.Status.LA &&
            diffTwoDateAsMonth(new Date(), accessDataCheck.paidToDateAdvance) > 24)
        ) {
          showToast(t('message:OB0033'), 'error')
        } else {
          setIsOpenChangeContactPopup(true)
        }
        return accessDataCheck
      }),
      bindLoader,
      ErrorHandling.run()
    )
  }

  const isEnableUpdateButton = () => {
    if (otherPhoneNumber.length === 10 && dataPolicy?.consent.isOTP) {
      return true
    } else {
      return false
    }
  }

  const onMobilePhoneClickEvent = (phoneType: string, phoneNumber: string) => {
    setPhoneType(phoneType)
    setPhoneNumber(phoneNumber)
    onAvayaCallClickEvent && onAvayaCallClickEvent(phoneNumber, phoneType)
  }

  const onCloseChangeContactPopup = () => {
    setIsOpenChangeContactPopup(false)
  }

  return (
    <>
      <View style={contactList.container}>
        <View style={{ flex: 50 }}>
          <View style={contactList.headerContent}>
            <OBTitle textStyle={contactList.titleText} text={t('Outbound:OBCallOutPopup:CONTACTLIST')}></OBTitle>
            <TouchableOpacity onPress={() => props.onCloseCallOutPopup && props.onCloseCallOutPopup()}>
              <View>
                <assets.OBPlus />
              </View>
            </TouchableOpacity>
          </View>
          <View style={contactList.contactContainer}>
            <TouchableOpacity
              disabled={isDisabledEdit || isOpenedAvayaCallBlock || !isSavedAfterCallingAvaya}
              onPress={() => {
                if (!dataPolicyOwner || !dataPolicyOwner?.mobilePhoneLatest) {
                  showToast(t('message:OB0005'), 'error')
                } else {
                  onMobilePhoneClickEvent('M', dataPolicyOwner?.mobilePhoneLatest || '')
                }
              }}
            >
              <View style={contactList.contactItem}>
                <View style={contactList.contactContent}>
                  <Text style={[contactList.contactTitle, phoneType === 'M' && isOpenedAvayaCallBlock && contactList.highLightText]}>{t('Outbound:OBCallOutPopup:MobilePhone')}</Text>
                  <Input disabled inputStyle={[contactList.contactDescription, phoneType === 'M' && isOpenedAvayaCallBlock && contactList.highLightText]} value={dataPolicyOwner?.mobilePhoneLatest ?? ''} />
                </View>
                <View>
                  {!isOpenedAvayaCallBlock && isSavedAfterCallingAvaya && <assets.OBPhoneCall />}
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={isDisabledEdit || isOpenedAvayaCallBlock || !isSavedAfterCallingAvaya}
              onPress={() => {
                if (!dataPolicyOwner || !dataPolicyOwner?.homePhone) {
                  showToast(t('message:OB0005'), 'error')
                } else {
                  onMobilePhoneClickEvent('H', dataPolicyOwner?.homePhone || '')
                }
              }}
            >
              <View style={contactList.contactItem}>
                <View style={contactList.contactContent}>
                  <Text style={[contactList.contactTitle, phoneType === 'H' && isOpenedAvayaCallBlock && contactList.highLightText]}>{t('Outbound:OBCallOutPopup:HomePhone')}</Text>
                  <Input disabled inputStyle={[contactList.contactDescription, phoneType === 'H' && isOpenedAvayaCallBlock && contactList.highLightText]} value={dataPolicyOwner?.homePhone ?? ''} />
                </View>
                <View>
                  {!isOpenedAvayaCallBlock && isSavedAfterCallingAvaya && <assets.OBPhoneCall />}
                </View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={isDisabledEdit || isOpenedAvayaCallBlock || !isSavedAfterCallingAvaya}
              onPress={() => {
                if (!dataPolicyOwner || !dataPolicyOwner?.companyPhone) {
                  showToast(t('message:OB0005'), 'error')
                } else {
                  onMobilePhoneClickEvent('C', dataPolicyOwner?.companyPhone || '')
                }
              }}
            >
              <View style={contactList.contactItem}>
                <View style={contactList.contactContent}>
                  <Text style={[contactList.contactTitle, phoneType === 'C' && isOpenedAvayaCallBlock && contactList.highLightText]}>{t('Outbound:OBCallOutPopup:CompanyPhone')}</Text>
                  <Input disabled inputStyle={[contactList.contactDescription, phoneType === 'C' && isOpenedAvayaCallBlock && contactList.highLightText]} value={dataPolicyOwner?.companyPhone ?? ''} />
                </View>
                <View>
                  {!isOpenedAvayaCallBlock && isSavedAfterCallingAvaya && <assets.OBPhoneCall />}
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>

        {dataPolicy?.consent.isOTP && (
          <View style={[contactList.footerContainer, isOpenedAvayaCallBlock && contactList.footerMargin]}>
            {isOpenCallOtherBlock ? (
              <View style={contactList.callOtherContainer}>
                {/* callotherheader */}
                <View style={contactList.callOtherHeader}>
                  <TouchableOpacity onPress={() => setIsOpenCallOtherBlock(false)}>
                    <assets.OBCallOtherClose />
                  </TouchableOpacity>
                </View>
                {/* callotherbody */}
                <View style={contactList.callOtherbody}>
                  <View>
                    <Text style={[contactList.callOtherTitle, phoneType === 'O' && isOpenedAvayaCallBlock && contactList.highLightText]}>{t('Outbound:OBCallOutPopup:CallOther')}</Text>
                  </View>

                  <View style={contactList.callOtherBodyContent}>
                    <View style={{ width: '20%' }}>
                      <Text style={{ paddingTop: 10 }}>{mobiphoneCode?.label}</Text>
                    </View>
                    <View style={{ width: '70%', paddingLeft: 6 }}>
                      <Input
                        disabled={isDisabledEdit}
                        placeholder={t('ServiceInquiry:PhoneNumber')}
                        value={otherPhoneNumber}
                        onChange={setOtherPhoneNumber}
                        inputType="number"
                        inputStyle={phoneType === 'O' && isOpenedAvayaCallBlock && contactList.highLightText}
                        maxLength={10}
                      />
                    </View>
                    <TouchableOpacity
                      disabled={!isEnableUpdateButton() || isDisabledEdit || isOpenedAvayaCallBlock || !isSavedAfterCallingAvaya}
                      onPress={() => onMobilePhoneClickEvent('O', otherPhoneNumber)}
                    >
                      <View style={{ width: '10%' }}>
                        {!isOpenedAvayaCallBlock && isSavedAfterCallingAvaya && <assets.OBPhoneCall />}
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>
                {/* callotherend */}
                <View style={contactList.callotherEnd}>
                  <TouchableOpacity
                    disabled={!isEnableUpdateButton() || isDisabledEdit}
                    onPress={() => onUpdateContanctInfoEvent()}
                  >
                    <View
                      style={[
                        OBSharedStyles.actionContent,
                        !isEnableUpdateButton() && OBSharedStyles.actionNonBackgroundDisable
                      ]}
                    >
                      <Text
                        style={[
                          OBSharedStyles.actionContentText,
                          !isEnableUpdateButton() && OBSharedStyles.actionNonBackgroundDisableText
                        ]}
                      >
                        {t('Outbound:OBCallOutPopup:UpdateContact')}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <TouchableOpacity onPress={() => setIsOpenCallOtherBlock(true)}>
                <assets.OBPopupCallOther />
              </TouchableOpacity>
            )}
          </View>
        )}

        <OBChangeContactInfoPopup
          visible={isOpenChangeContactPopup}
          onPopupCloseEvent={onCloseChangeContactPopup}
          policyNumber={policyNumber}
          clientNumber={clientNumber}
          // setIsUpdatedChangeContactInfo={setIsUpdatedChangeContactInfo}
        ></OBChangeContactInfoPopup>

      </View>
    </>
  )
}
const contactList = StyleSheet.create({
  container: {
    flex: 1,
    // width: '100%',
    paddingHorizontal: 8,
    paddingVertical: 16
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleText: {
    color: '#27272A'
  },
  contactContainer: {
    marginTop: 16
  },
  contactItem: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#EBEBF0'
  },
  contactContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  contactTitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 21,
    color: '#27272A'
  },
  contactDescription: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    color: '#808089'
  },
  footerContainer: {
    flex: 50,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  callOtherContainer: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    width: '100%',
    borderWidth: 1,
    borderColor: '#DDD',
    borderRadius: 16
  },
  callOtherTitle: {
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '700'
  },
  callOtherHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  callOtherbody: {
    width: '100%'
  },
  callOtherBodyContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  callotherEnd: {
    marginTop: 16
  },
  footerMargin: {
    marginBottom: 64
  },
  highLightText: {
    color: '#ED1B2E'
  }
})
