import { FileUploadData } from '@pulseops/common'
import { RedatingFormData } from '../redating'

export namespace FullSurrenderReversalForm {
  export interface FullSurrenderReversalData {
    reinstatementReason: string
    LAList: RedatingFormData.LifeAssuredInfo[]
    healthDeclaration: FileUploadData[]
  }
}
