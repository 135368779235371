import { Maybe, Nullable } from '@mxt/zio/codec'
import { form2, SelectOption } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'
import { SubmissionAgentService } from '../common'

export namespace AgentMovementForm {
  export const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  export type File = t.TypeOf<typeof File>

  export type FileListBrand = {
    readonly FileList: unique symbol
  }

  export type FileList = t.Branded<File[], FileListBrand>

  export const withFileRequired = () =>
    pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l.length > 0,
        (l) => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
        'FileList'
      )
    )

  type AgentCodeBrand = {
    readonly AgentCode: unique symbol
  }

  type UnitNameBrand = {
    readonly UnitNameNew: unique symbol
  }

  type BranchNameBrand = {
    readonly BranchNameNew: unique symbol
  }

  type AgentCode = t.Branded<NonEmptyString, AgentCodeBrand>
  type UnitNameNew = t.Branded<NonEmptyString, UnitNameBrand>
  type BranchNameNew = t.Branded<NonEmptyString, BranchNameBrand>

  export const AgentCode = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('requestInfo:AgentCode') })),
    form2.refine(
      (l): l is AgentCode => /\b\d{8}\b/.test(l),
      (l) => i18next.t('message:MS100005'),
      'AgentCode'
    ),
    form2.refine(
      (l): l is AgentCode => l.startsWith('6'),
      (l) => i18next.t('message:MS050013', { field: i18next.t('utilities:AgentCode') }),
      'AgentCode'
    )
  )

  export const UnitNameNew = pipe(
    t.string,
    form2.refine(
      (l): l is UnitNameNew => !(l.startsWith('DUMMY UNIT') || l.startsWith('MSDL')),
      (l) => i18next.t('submission:validValueMessage'),
      'UnitNameNew'
    )
  )

  export const BranchNameNew = pipe(
    t.string,
    form2.refine(
      (l): l is BranchNameNew => !(l.startsWith('DUMMY UNIT') || l.startsWith('MSDL')),
      (l) => i18next.t('submission:validValueMessage'),
      'BranchNameNew'
    )
  )

  const officeItem = t.type({
    officeNew: t.string,
    officeOld: Maybe(t.string)
  })

  type officeItem = t.TypeOf<typeof officeItem>

  type OfficeBrand = {
    readonly OfficeNew: unique symbol
  }

  type OfficeNew = t.Branded<officeItem, OfficeBrand>

  const validateOffice = () =>
    pipe(
      officeItem,
      form2.refine(
        (l): l is OfficeNew => l.officeNew?.toUpperCase() !== l.officeOld,
        (l) => i18next.t('submission:ChooseOtherOffice'),
        'OfficeNew'
      )
    )

  const UnitItem = t.type({
    unitNew: t.string,
    unitOld: Maybe(t.string)
  })

  type UnitItem = t.TypeOf<typeof UnitItem>

  type UnitBrand = {
    readonly UnitNew: unique symbol
  }

  type UnitNew = t.Branded<UnitItem, UnitBrand>

  const validateUnit = () =>
    pipe(
      UnitItem,
      form2.refine(
        (l): l is UnitNew => l.unitNew?.toUpperCase() !== l.unitOld,
        (l) => i18next.t('submission:ChooseOtherOffice'),
        'UnitNew'
      )
    )

  const BranchItem = t.type({
    branchNew: t.string,
    branchOld: Maybe(t.string)
  })

  type BranchItem = t.TypeOf<typeof BranchItem>

  type BranchBrand = {
    readonly BranchNew: unique symbol
  }

  type BranchNew = t.Branded<BranchItem, BranchBrand>

  const validateBranch = () =>
    pipe(
      BranchItem,
      form2.refine(
        (l): l is BranchNew => l.branchNew?.toUpperCase() !== l.branchOld,
        (l) => i18next.t('submission:ChooseOtherOffice'),
        'BranchNew'
      )
    )
  export const codec = t.type({
    AgentCode: AgentCode,
    OfficeCodeNew: validateOffice(),
    UnitCodeNew: validateUnit(),
    UnitNameNew: UnitNameNew,
    BranchCodeNew: validateBranch(),
    BranchNameNew: BranchNameNew,
    dataPayload: SubmissionAgentService.AgentDetail,
    officeReason: form2.selectOption.optional,
    leaderReason: form2.selectOption.optional,
    officeFiles: t.array(File),
    leaderFiles: t.array(File),
    agentType: Maybe(t.string)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
