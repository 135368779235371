import { pipe } from 'fp-ts/lib/function'
import { ZIO, Task } from '@mxt/zio'
import { ReinstatementService } from './ReinstatementService'
import { GeneralService } from './GeneralService'
import { SURedating } from './model'
// import { SubmissionService } from './SubmissionService'
import { POApi } from '../POApi'
import * as t from 'io-ts'
import { SubmissionService } from './SubmissionService'
import { Maybe } from '@mxt/zio/codec'

export namespace RedatingService {
  export const getDetail = (policyNum: string): Task<SURedating.DetailData> => {
    return pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        getRedatingInfo(policyNum),
        ReinstatementService.getReinData(policyNum),
        GeneralService.getOccupations
      ),
      ZIO.map(([policyInfo, redatingData, reinData, occupationList]) => {
        const totalPremium = Number(redatingData.body.totalPremium ?? 0)
        const riskCommenceDate = redatingData.body.riskCommenceDate ?? ''
        const billingFrequency = redatingData.body.billFreq ?? ''
        const agentCode = policyInfo.body.servingAgents?.agent?.code ?? ''
        const newRCD = redatingData.body.newRCD ?? ''
        const lapsedDate = redatingData.body.lapsedDate ?? ''
        return {
          totalPremium: totalPremium,
          riskCommenceDate: riskCommenceDate,
          billingFrequency: billingFrequency,
          newRCD: newRCD,
          lapsedDate: lapsedDate,
          reinData,
          occupationList,
          agentCode: agentCode
        }
      })
    )
  }

  export const getRedatingInfo = (policyNum: string) =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}/redating`)(
        t.type({
          body: SURedating.redatingInfo
        })
      ),
      ZIO.map((redatingData) => {
        return redatingData
      })
    )

  export const accessData = (policyNum: string): Task<SURedating.AcessData> =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNum), clientPolicyVerify(policyNum)),
      ZIO.flatMap(([policyInfo, clientVerifyError]) => {
        const primaryId = policyInfo.body.owners.id ?? ''
        return pipe(
          SubmissionService.getCustomer(primaryId),
          ZIO.map((customerInfo) => ({
            PODateOfDead: customerInfo.body.dod,
            coverageCode: policyInfo.body.basicCode ?? '',
            contractStatus: policyInfo.body.status ?? '',
            riskCommenceDate: policyInfo.body.riskCommenceDate ?? '',
            paidToDateAdvance: policyInfo.body.paidToDateAdvance ?? '',
            lapsedDate: policyInfo.body.lapsedDate ?? '',
            errorCode: clientVerifyError
          }))
        )
      })
    )
  export const clientPolicyVerify = (policyNum: string) =>
    pipe(
      POApi.post(`wf-api/other/client-policy-verify`)(
        t.type({
          body: t.type({
            policyRecOuts: t.array(
              t.type({
                errorCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        body: {
          function: 'CHECK_REDATING',
          clientRecs: [{ clientNumber: '', securityNo: '' }],
          policyRecs: [{ policyNum }]
        }
      }),
      ZIO.map((verify) => {
        const errorCode =
          verify.body.policyRecOuts && verify.body.policyRecOuts.length > 0
            ? verify.body.policyRecOuts[0].errorCode ?? ''
            : ''
        return errorCode
      })
    )
}
