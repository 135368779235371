import React from 'react'
import { View } from 'react-native'
import { PolicyServiceProps } from '../policy-service-props'
import { SC } from '../../common'
import {
  useMobile,
  ScheduledPaymentStatementService,
  AppContext,
  ErrorHandling,
  TransactionType,
  PulseOpsFormat
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import { RequestAuthenticateData } from '../../request-authen'
import moment from 'moment'
type Props = PolicyServiceProps<{}> & {}

export const SchedulePaymentState: React.FC<Props> = ({ isConfirmed, policyNumber, initSubmission }) => {
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const [fromDate, setFromDate] = React.useState<string>('')
  const [toDate, setToDate] = React.useState<string>('')

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionScreen' })
        },
        { title: t('TransactionType:SCHEDULED_PAYMENT_STATEMENT'), navigate: null }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const { firstReleaseCashDate } = pipe(
    ScheduledPaymentStatementService.getDetail(policyNumber!),
    ZIO.map((firstReleaseCashDate) => ({ firstReleaseCashDate })),
    ZIO.tapError((_) => ZIO.unit),
    bindLoader,
    ErrorHandling.runDidMount({})
  )

  initSubmission({
    validate: async () => {
      if (!firstReleaseCashDate) {
        showToast(t('message:MS050281'), 'error')
        return false
      }
      return {
        url: (policyNumber) => `wf-api/policy/${policyNumber}/scheduled-payment`,
        body: mapData(),
        collerationId: policyNumber!,
        transaction: TransactionType.SCHEDULED_PAYMENT_STATEMENT,
        transactionName:
          RequestAuthenticateData.TransactionLabelShort(TransactionType.SCHEDULED_PAYMENT_STATEMENT) || ''
      }
    },
    clear: () => {}
  })

  const customedFromDate = React.useMemo(() => {
    setFromDate(firstReleaseCashDate ?? '')
    return PulseOpsFormat.datetoFormat(moment(firstReleaseCashDate).toDate(), 'DD/MM/yyyy')
  }, [firstReleaseCashDate])
  const customedToDate = React.useMemo(() => {
    const currentDate = new Date()
    setToDate(PulseOpsFormat.datetoFormat(currentDate, 'yyyyMMDD'))
    return PulseOpsFormat.datetoFormat(currentDate, 'DD/MM/yyyy')
  }, [])

  const mapData = (): ScheduledPaymentStatementService.SubmitData => {
    return {
      fromDate: fromDate,
      toDate: toDate
    }
  }

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:LetterRequest').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <SC.RowWrap>
          <RView>
            <SC.BoldText>{t('common:FromDate')}</SC.BoldText>
            <SC.NormalText>{firstReleaseCashDate ? customedFromDate : '-'}</SC.NormalText>
          </RView>
          <RView>
            <SC.BoldText>{t('common:ToDate')}</SC.BoldText>
            <SC.NormalText>{customedToDate}</SC.NormalText>
          </RView>
        </SC.RowWrap>
      </SC.PanelContainer>
    </SC.Container>
  )
}

const RView: React.FC<{ mobileWidth?: string | number; width?: string | number; padding?: number }> = ({
  mobileWidth = '100%',
  width = '33%',
  padding = 5,
  children
}) => {
  const { isMobile } = useMobile()
  const composeWidth = isMobile ? mobileWidth : width
  const composePadding = isMobile ? padding : 5

  return <View style={{ width: composeWidth, padding: composePadding }}>{children}</View>
}
