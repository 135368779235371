/* eslint-disable prefer-const */
import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React, { useCallback, useContext } from 'react'
import {
  Input,
  ModalComponent,
  Alert,
  AuthService,
  PulseOpsFormat,
  FileUploadData,
  ErrorHandling,
  AppContext,
  AppConfig,
  IBPolicyPermission,
  assets
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { IBButton, IBGeneralField, IBGeneralTable, IBGridDataView, TypeInputComponent } from '../../common'
import { useForm } from 'react-hook-form'
import { ClickToSendEmailForm, FileUpload } from '../../ib-client/ib-click-to-send-email'
import { isEmpty, isNil, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { EmailManagementContext } from '../IBEmailManagementContext'
import { useIsFocused } from '@react-navigation/native'
import { ClickToSendCaseDetailData, IBService } from '../../ib-service'
import { useLoading } from '@mxt/zio-react'
import { TaskCategory, TaskDecision } from '../common'
import moment from 'moment'
import { Backdrop, Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core'

interface Props {
  visible: boolean
  onClose: (value: boolean) => void
  objectModal?: { policyNumber: string; processInstanceId: string; taskId: string }
}

const IBModalCancel = (
  props: Props & {
    onAction: (taskDecistion: TaskDecision, comment: string) => void
  }
) => {
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, objectModal, onAction } = props
  const { width, height } = useWindowDimensions()
  const [reasonText, setReasonText] = React.useState<string>('')

  const onCancel = () => {
    onAction(TaskDecision.CANCEL, reasonText)
    onClose(!visible)
  }

  return (
    <ModalComponent
      title={t('CancelConfirmation').toUpperCase()}
      titleStyle={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700', textAlign: 'center' }}
      visible={visible}
      modalWidth={width * 0.4}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 10, marginTop: 0 }}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <Input
          required={true}
          title={t('Reason')}
          value={reasonText}
          maxLength={1000}
          inputType={'text'}
          onChange={(value) => {
            setReasonText(value)
          }}
          multiline={true}
          numberOfLines={3}
          placeholder={t('common:Input')}
        />
        <View style={[styles.btnBar, { marginTop: 15, justifyContent: 'flex-end' }]}>
          <IBButton
            onPress={() => {
              onClose(!visible)
            }}
            title={t('Exit')}
            backgroundFill={false}
            widthDiv={'20%'}
            containerStyle={{ marginRight: 15 }}
          />
          <IBButton
            onPress={onCancel}
            title={t('CancelEmail')}
            backgroundFill
            widthDiv={'35%'}
            disabled={isEmpty(reasonText)}
          />
        </View>
      </View>
    </ModalComponent>
  )
}

const IBModalApprove = (
  props: Props & {
    onAction: (taskDecistion: TaskDecision) => void
  }
) => {
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, onAction } = props
  const { width, height } = useWindowDimensions()

  const onApprove = () => {
    onAction(TaskDecision.ACCEPT)
    onClose(!visible)
  }

  return (
    <ModalComponent
      title={t('ApproveConfirmation').toUpperCase()}
      titleStyle={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700', textAlign: 'center' }}
      visible={visible}
      modalWidth={width * 0.2}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 10, marginTop: 0 }}
    >
      <View style={{ paddingHorizontal: 20, alignItems: 'center' }}>
        {/* <Text style={{ fontSize: 16 }}>{t('ApproveConfirmation')}</Text> */}
        <View style={[styles.btnBar, { marginTop: 15, borderWidth: 0 }]}>
          <IBButton
            onPress={() => {
              onClose(!visible)
            }}
            title={t('NoApprove')}
            backgroundFill={false}
            widthDiv={'40%'}
          />
          <IBButton onPress={onApprove} title={t('Yes')} backgroundFill widthDiv={'40%'} />
        </View>
      </View>
    </ModalComponent>
  )
}

const IBModalReturnToSender = (
  props: Props & {
    onAction: (taskDecistion: TaskDecision, comment: string) => void
  }
) => {
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, onAction } = props
  const { width, height } = useWindowDimensions()
  const [commentText, setCommentText] = React.useState<string>('')

  const onReturn = () => {
    onAction(TaskDecision.RETURN, commentText)
    onClose(!visible)
  }

  return (
    <ModalComponent
      title={t('ReturnToSenderConfirmation').toUpperCase()}
      titleStyle={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700', textAlign: 'center' }}
      visible={visible}
      modalWidth={width * 0.4}
      onClose={() => onClose(!visible)}
      actions={[]}
      showCloseIcon={true}
      buttonGroupStyle={{ marginBottom: 10, marginTop: 0 }}
    >
      <View style={{ paddingHorizontal: 20 }}>
        <Input
          required={true}
          title={t('CommentEmail')}
          value={commentText}
          maxLength={1000}
          inputType={'text'}
          onChange={(value) => {
            setCommentText(value)
          }}
          multiline={true}
          numberOfLines={3}
          placeholder={t('common:Input')}
        />
        <View style={[styles.btnBar, { marginTop: 15, justifyContent: 'flex-end' }]}>
          <IBButton
            onPress={() => {
              onClose(!visible)
            }}
            title={t('Exit')}
            backgroundFill={false}
            widthDiv={'20%'}
            containerStyle={{ marginRight: 15 }}
          />
          <IBButton
            onPress={onReturn}
            title={t('Return')}
            backgroundFill
            disabled={isEmpty(commentText)}
            widthDiv={'40%'}
          />
        </View>
      </View>
    </ModalComponent>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: '16px 10px 24px',
      width: '70%',
      height: '90%',
      overflow: 'auto',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column'
    }
  })
)

export const IBModalListingOfPendingApproval = (
  props: Props & {
    flagSuccess: boolean
    setFlagSuccess: (flag: boolean) => void
  }
) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation('Inbound')
  const { visible, onClose, objectModal, flagSuccess, setFlagSuccess } = props
  const [openModalCancel, setOpenModalCancel] = React.useState<boolean>(false)
  const [openModalApprove, setOpenModalApprove] = React.useState<boolean>(false)
  const [openModalReturn, setOpenModalReturn] = React.useState<boolean>(false)
  const [listImgDefaultTemplate, setListImgDefaultTemplate] = React.useState<{ name: string; blob: string }[]>([])
  const [listImgInsertContent, setListImgInsertContent] = React.useState<{ file: FileUpload; blobUrl: string }[]>([])
  const [imgInsertCur, setImgInsertCur] = React.useState<{ file: FileUpload; blobUrl: string }>()
  const { masterDataList, permissions } = useContext(EmailManagementContext)
  const isFocused = useIsFocused()
  const [originalData, setOriginalData] = React.useState<ClickToSendCaseDetailData>()
  const [loading, bindLoading] = useLoading(false)
  const [loadingLoadFile, bindLoadingLoadFile] = useLoading(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const form = useForm<ClickToSendEmailForm>({
    mode: 'onChange',
    defaultValues: {
      attachmentFiles: []
    }
  })

  const clearStateOnClose = () => {
    setImgInsertCur(undefined)
    setListImgInsertContent([])
    setListImgDefaultTemplate([])
    setOriginalData(undefined)
    form.reset()
    onClose(!visible)
  }

  const getFileByUrl = (
    url: string,
    name: string,
    params: { updatedDate: string; createdDate: string },
    filename: string
  ) => {
    const container = url.slice(url.lastIndexOf('/') + 1)
    const apiUrl = url.slice(0, url.lastIndexOf('/'))
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        let reader = new FileReader()
        const updateDate = params?.updatedDate
          ? moment(params?.updatedDate).toDate() ?? new Date()
          : params?.createdDate
          ? moment(params?.createdDate).toDate() ?? new Date()
          : new Date()
        reader.readAsDataURL(blob)
        reader.onloadend = function () {
          const base64String = reader.result
          const blobFile = base64String ? new Blob([base64String]) : null
          let fileUpload = new File(blobFile ? [blobFile] : [], filename ?? '')
          const lastDotIndex = filename.lastIndexOf('.')
          const fileExtention = filename.substring(lastDotIndex + 1, filename.length)
          let file: FileUploadData = {
            fileName: filename.substring(0, lastDotIndex) ?? '',
            fileExtension: fileExtention === 'msg' ? 'msg' : blob.type.split('/')[1] ?? blob.type,
            size: blob.size,
            base64: base64String ? base64String.toString() : '',
            uploadedDate: updateDate,
            file: fileUpload
          }
          let fileList = form.watch('attachmentFiles')
          base64String && fileList.push(file)
          if (fileList.length > 0 && base64String) {
            form.setValue('attachmentFiles', fileList)
          }
        }
        return ZIO.unit
      })
    )
  }

  React.useEffect(() => {
    if (objectModal)
      pipe(
        IBService.getClickToSendCaseDetail(objectModal?.processInstanceId || ''),
        ZIO.map((res) => {
          setOriginalData(res)
          loadOriginData(res)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
  }, [isFocused, objectModal])

  const getImgByUrl = (name: string, content: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType, AppConfig.get),
      ZIO.flatMap(([token, loginType, cf]) => {
        const currVersion = cf.version.split('.').join('-') || '1-0-0'
        const url = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
        const container = url.slice(url.lastIndexOf('/') + 1)
        const apiUrl = url.slice(0, url.lastIndexOf('/'))
        return ZIO.fromPromise(() =>
          fetch(apiUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType,
              container: container,
              blob: name
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const splitName = name.split('/')
        return { name: splitName[splitName.length - 1].split('.')[0], blob: window.URL.createObjectURL(blob) }
      })
    )
  }

  const loadOriginData = (res: ClickToSendCaseDetailData) => {
    pipe(
      ZIO.sequence(
        (res.payload?.attachDocuments || []).map((item) =>
          getFileByUrl(
            item.url as string,
            item.type as string,
            {
              updatedDate: res.lastUpdatedDate || '',
              createdDate: res.startTime || ''
            },
            item.filename
          )
        )
      ),
      bindLoadingLoadFile,
      ZIO.unsafeRun({})
    )

    const group = masterDataList.a14s0s.find((x) => x.code === res.payload?.mailGroupCode)
    const type = masterDataList.a14s1s.find((x) => x.code === res.payload?.requestTypeCode)
    form.setValue('policyNumber', { label: res.policyNumber || '', value: res.policyNumber || '' })
    form.setValue('policyOwner', res.policyOwnerName || '')
    form.setValue('group', {
      value: group?.code || '',
      label: i18n.language === 'vi' ? group?.nameVi || '-' : group?.nameEn || '-'
    })
    form.setValue('type', {
      value: type?.code || '',
      label: i18n.language === 'vi' ? type?.nameVi || '-' : type?.nameEn || '-'
    })
    form.setValue('note', res.payload?.note || '')
    form.setValue('sendTo', res.payload?.emailTo?.reduce((pre, cur) => (pre += `${cur};`), '') || '')
    form.setValue('BCC', res.payload?.emailBCC?.reduce((pre, cur) => (pre += `${cur};`), '') || '')
    form.setValue('subject', res.payload?.subject || '')

    if (res.payload?.content) {
      if (!isEmpty(res.payload?.content.match(/alt="(.*?)"/g)) && !isNil(res.payload?.content.match(/alt="(.*?)"/g))) {
        const arrayImg = res.payload?.content.match(/alt="(.*?)"/g)?.map((match) => match.match(/alt="(.*?)"/)[1])
        pipe(
          ZIO.sequence((arrayImg || []).map((url) => getImgByUrl(url, res.payload?.content || ''))),
          ZIO.map((imgs) => {
            setListImgDefaultTemplate(imgs)
            const insertImgToContent = imgs.reduce(
              (pre, cur) => pre?.replace(`{${cur.name}}`, cur.blob),
              res.payload?.content
            )
            form.setValue('content', insertImgToContent || '')
            form.setValue('contentOriginal', insertImgToContent || '')
          }),
          ZIO.unsafeRun({})
        )
      } else {
        form.setValue('contentOriginal', res.payload?.content)
        form.setValue('content', res.payload?.content)
      }
    }
  }

  const onBehaviorButtonCheckValid = (buttonType: TaskDecision) =>
    form.handleSubmit((value) => {
      switch (buttonType) {
        case TaskDecision.ACCEPT:
          setOpenModalApprove(true)
          break
        case TaskDecision.RETURN:
          setOpenModalReturn(true)
          break
        case TaskDecision.CANCEL:
          setOpenModalCancel(true)
          break
        default:
          break
      }
    })

  const onAction = (taskDecistion: TaskDecision, comment?: string) => {
    const valueForm = form.watch()
    const listOriginalAttachFiles = originalData?.payload?.attachDocuments?.map((docs) => docs.filename)
    const listAttachFilesNew = valueForm.attachmentFiles.filter((x) => !listOriginalAttachFiles?.includes(x.file.name))
    const filterImgInsertContent = listImgInsertContent.filter((x) => valueForm.content.includes(x.blobUrl))
    pipe(
      ZIO.sequence(
        (filterImgInsertContent
          ? [...filterImgInsertContent.map((x) => x.file), ...listAttachFilesNew]
          : listAttachFilesNew
        ).map(({ file }) => {
          return IBService.importFileClickToSend(file, originalData?.policyNumber as string)
        })
      ),
      ZIO.flatMap((docs) => {
        return pipe(
          AppConfig.get,
          ZIO.flatMap((cf) => {
            const currVersion = cf.version.split('.').join('-') || '1-0-0'
            const apiUrl = `${cf.apiUrl}/azurestorage/${currVersion}/contactstrategy`
            let documentUrls = docs.map((file) => ({ ...file, url: apiUrl }))
            let content = valueForm.content
            content = listImgDefaultTemplate.reduce((pre, cur) => pre.replace(`${cur.blob}`, `{${cur.name}}`), content)
            const getDocTypes = docs.map((x) => x.type)
            const imgInserts = filterImgInsertContent.map((item) => {
              const getDoc = getDocTypes.find((x) => item.file.file.name === x.split('/')[2].split('_')[1])
              documentUrls = documentUrls.filter((x) => x.type !== getDoc)
              return {
                fileName: (getDoc as string).split('/')[2] || '',
                blobUrl: item.blobUrl,
                docsUrl: getDoc
              }
            })
            content = imgInserts.reduce(
              (pre, cur) =>
                pre
                  .replace(`{${cur.fileName.split('_')[1]}}`, cur.docsUrl || '')
                  .replace(`${cur.blobUrl}`, `{${cur.fileName.split('.')[0]}}`),
              content
            )

            const filterOriginalData = originalData?.payload?.attachDocuments?.filter((x) =>
              valueForm.attachmentFiles.find((file) => file.file.name === x.filename)
            )

            return IBService.postCompleteTaskClickToSend({
              processInstanceId: originalData?.processInstanceId || '',
              taskId: objectModal?.taskId || '',
              decision: taskDecistion,
              taskCategory: TaskCategory.REVIEW_EMAIL,
              categoryCode: 'IB',
              comment: comment || '',
              payload: {
                ...originalData?.payload,
                subject: valueForm.subject,
                content: content,
                attachDocuments: [...(filterOriginalData || []), ...documentUrls]
              }
            })
          })
        )
      }),
      ZIO.map((res) => {
        if ([TaskDecision.ACCEPT, TaskDecision.RETURN].includes(taskDecistion)) {
          showToast(t('message:IB0018'), 'success')
        } else {
          showToast(t('message:IB0014'), 'success')
        }
        setFlagSuccess(true)
        clearStateOnClose()
        return ZIO.unit
      }),
      ZIO.catchAll((error) => {
        console.log(error)
        showToast(t('message:IB0013'), 'error')
        return ZIO.succeed(null)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  React.useEffect(() => {
    if (imgInsertCur) setListImgInsertContent([...listImgInsertContent, imgInsertCur])
  }, [imgInsertCur])

  return (
    <Modal
      open={visible}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      children={
        <>
          <Fade in={visible}>
            <div className={classes.paper}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: 10,
                  paddingHorizontal: 15
                }}
              >
                <Text style={{ color: '#4F4F4F', fontSize: 16, fontWeight: '700' }}>
                  {t('EmailDetail').toUpperCase()}
                </Text>
                <Pressable onPress={() => clearStateOnClose()}>
                  <assets.CloseTaskModalIcon />
                </Pressable>
              </View>
              <ScrollView>
                <View style={styles.boxContainer}>
                  <IBGeneralField
                    FieldForm={form}
                    col={2}
                    typeInput={[
                      {
                        type: TypeInputComponent.SELECT,
                        formName: 'policyNumber',
                        title: t('common:PolicyNumber'),
                        option: [],
                        rules: {
                          validate: () => {
                            return isNil(form.watch('policyNumber'))
                              ? `${t('message:MS020009', { field: t('common:PolicyNumber') })}`
                              : true
                          }
                        },
                        disabled: true,
                        required: true
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'policyOwner',
                        title: t('OwnerName'),
                        inputType: 'input',
                        disabled: true,
                        inputStyle: { color: 'rgba(0, 0, 0, 0.38)' }
                      },
                      {
                        type: TypeInputComponent.SELECT,
                        formName: 'group',
                        title: t('Group'),
                        option:
                          masterDataList.a14s0s.map((item) => ({
                            value: item.code || '',
                            label: i18n.language === 'vi' ? item.nameVi || '-' : item.nameEn || '-'
                          })) || [],
                        required: true,
                        disabled: true,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('group')) ? `${t('message:MS020009', { field: t('Group') })}` : true
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.SELECT,
                        formName: 'type',
                        title: t('Type'),
                        option: form.watch().group
                          ? masterDataList.a14s1s
                              .filter((x) => x.groupCode === (form.watch().group.value || ''))
                              .map((item) => ({
                                value: item.code || '',
                                label: i18n.language === 'vi' ? item.nameVi || '-' : item.nameEn || '-'
                              }))
                          : [],
                        rules: {
                          validate: () => {
                            return isNil(form.watch('type')) ? `${t('message:MS020009', { field: t('Type') })}` : true
                          }
                        },
                        required: true,
                        disabled: true
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'note',
                        title: t('Note'),
                        inputType: 'input',
                        alwayShowUnderline: true,
                        expandRow: 2,
                        multiline: true,
                        disabled: true,
                        numberOfLines: 3,
                        maxLength: 1000,
                        inputStyle: { color: 'rgba(0, 0, 0, 0.38)' }
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'sendTo',
                        title: t('To'),
                        inputType: 'input',
                        required: true,
                        disabled: true,
                        alwayShowUnderline: true,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('sendTo')) || isEmpty(form.watch('sendTo'))
                              ? `${t('message:MS020001', { field: t('To') })}`
                              : true
                          }
                        },
                        inputStyle: { color: 'rgba(0, 0, 0, 0.38)' }
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'BCC',
                        title: t('Bcc'),
                        disabled: true,
                        alwayShowUnderline: true,
                        inputType: 'input',
                        inputStyle: { color: 'rgba(0, 0, 0, 0.38)' }
                      },
                      {
                        type: TypeInputComponent.INPUT,
                        formName: 'subject',
                        title: t('SubjectEmail'),
                        inputType: 'input',
                        required: true,
                        maxLength: 200,
                        expandRow: 2,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('subject')) || isEmpty(form.watch('subject'))
                              ? `${t('message:MS020001', { field: t('SubjectEmail') })}`
                              : true
                          }
                        }
                      },
                      {
                        type: TypeInputComponent.CKEDITOR,
                        formName: 'content',
                        config: {
                          fontSize_sizes:
                            '8/8pt;9/9pt;10/10pt;11/11pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt',
                          resize_enabled: true,
                          language: i18n.language,
                          extraAllowedContent: 'dl dt dd mark',
                          allowedContent: true,
                          extraPlugins: 'image3,colorbutton,font',
                          removePlugins: 'image,elementspath,sourcearea,sourcedialog,codemirror',
                          versionCheck: false
                        },
                        expandRow: 2,
                        title: t('Content'),
                        required: true,
                        rules: {
                          validate: () => {
                            return isNil(form.watch('content')) || isEmpty(form.watch('content'))
                              ? `${t('message:MS020001', { field: t('Content') })}`
                              : true
                          }
                        },
                        handleUploadFiles: (file: FileUpload, blobUrl: string) => {
                          setImgInsertCur({ file, blobUrl })
                        }
                      },
                      {
                        type: TypeInputComponent.IMPORT,
                        formName: 'attachmentFiles',
                        title: t('requestInfo:Attachment'),
                        width: '100%',
                        maxSizeFile: 20,
                        maxTotalFile: 20,
                        messageMaxTotalSize: 'message:IB0016',
                        messageMaxSize: 'message:IB0015',
                        // showFullFileName: true,
                        messageFormat: 'Inbound:ImageFormat20MB',
                        // formatFileNameVi: true,
                        messageValidExtensions: 'message:IB0017'
                      }
                    ]}
                  />
                </View>
                <View style={styles.btnBar}>
                  <IBButton
                    onPress={() => {
                      if (originalData) {
                        form.setValue('attachmentFiles', [])
                        loadOriginData(originalData)
                      }
                    }}
                    title={t('ReturnToOrigin')}
                    backgroundFill={false}
                    widthDiv={'30%'}
                    disabled={
                      !permissions.includes(IBPolicyPermission.IBReturnOriginalEmailApprovalEmailDetail) ||
                      loadingLoadFile
                    }
                  />
                  <IBButton
                    onPress={onBehaviorButtonCheckValid(TaskDecision.RETURN)}
                    title={t('ReturnToSender')}
                    backgroundFill={false}
                    widthDiv={'30%'}
                    disabled={
                      !permissions.includes(IBPolicyPermission.IBReturnToSenderApprovalEmailDetail) || loadingLoadFile
                    }
                  />
                  <IBButton
                    onPress={onBehaviorButtonCheckValid(TaskDecision.CANCEL)}
                    title={t('Cancel')}
                    backgroundFill={false}
                    widthDiv={'15%'}
                    disabled={!permissions.includes(IBPolicyPermission.IBCancelApprovalEmailDetail) || loadingLoadFile}
                  />
                  <IBButton
                    onPress={onBehaviorButtonCheckValid(TaskDecision.ACCEPT)}
                    title={t('Approve')}
                    backgroundFill
                    widthDiv={'15%'}
                    disabled={!permissions.includes(IBPolicyPermission.IBApproveApprovalEmailDetail) || loadingLoadFile}
                  />
                </View>
                {loading ? (
                  <View style={styles.loadingBlur}>
                    <ActivityIndicator size="large" color="red" />
                  </View>
                ) : (
                  <></>
                )}
              </ScrollView>
              <IBModalCancel visible={openModalCancel} onClose={setOpenModalCancel} onAction={onAction} />
              <IBModalApprove visible={openModalApprove} onClose={setOpenModalApprove} onAction={onAction} />
              <IBModalReturnToSender visible={openModalReturn} onClose={setOpenModalReturn} onAction={onAction} />
            </div>
          </Fade>
        </>
      }
    ></Modal>
  )
}

const styles = StyleSheet.create({
  boxContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    padding: 20,
    flex: 1,
    flexDirection: 'column'
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    justifyContent: 'space-evenly'
  },
  loadingBlur: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  }
})
