import { useMobile, assets, RBAC, ErrorHandling, HomePagePermissions, AuthState, AppContext } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View, ImageBackground, ScrollView, useWindowDimensions } from 'react-native'
import { UserStackParamList } from './UserStackParamList'
// import { FontAwesome5 } from '@expo/vector-icons'
import { useTranslation } from 'react-i18next'
import { UserDrawerParamList } from './drawer/UserDrawerParamList'
import { Container, makeStyles } from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'

const useStyles = makeStyles({
  root: {
    '&.MuiContainer-root': {
      width: 286,
      display: 'flex',
      paddingBottom: 24,
      paddingTop: 24,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '&:hover': {
      borderRadius: 20,
      backgroundColor: '#FFF',
      boxShadow: '0px 12px 36px 0px rgba(237, 27, 46, 0.18)'
    }
  }
})
type Props = StackScreenProps<UserStackParamList, 'HomeScreen'>

export const HomeScreen = (props: Props) => {
  const { t } = useTranslation('home')
  const trans = useTranslation()
  const { height } = useWindowDimensions()
  const { isMobile, isWide } = useMobile()
  const classes = useStyles()
  const authData = pipe(AuthState.auth.get)
  const [loading, bindLoading] = useLoading()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidUpdate([authData])) || []

  // const logout = () => {
  //   pipe(AuthService.logout, ZIO.unsafeRun({}))
  // }
  // console.log(permissions)

  const navigateTo = (screen: keyof UserDrawerParamList) => {
    props.navigation.navigate('UserDrawer', { screen })
  }

  const navigateBO = () => {
    props.navigation.navigate('UserDrawer', {
      screen: 'BusinessStack'
    })
  }
  const navigateDI = () => {
    props.navigation.navigate('UserDrawer', {
      screen: 'DIStack'
    })
  }
  const navigate360 = () => {
    props.navigation.navigate('UserDrawer', {
      screen: 'InquiryStack'
    })
  }

  const actionsList = [
    {
      title: t('TaskManagement'),
      iconMobile: <assets.NewTaskManagerIcon />,
      iconWeb: <assets.NewTaskManagerIcon />,
      permission: HomePagePermissions.ViewTaskManagementHomepage,
      navigate: () => navigateTo('TaskDashboardScreen')
    },
    {
      title: t('Submission'),
      iconMobile: <assets.NewSubmissionIcon />,
      iconWeb: <assets.NewSubmissionIcon />,
      permission: HomePagePermissions.ViewSubmissionHomepage,
      navigate: () =>
        props.navigation.navigate('UserDrawer', {
          screen: 'StaffSubmissionStack',
          params: {
            screen: 'StaffSubmissionScreen'
          }
        })
    },
    {
      title: t('DigitalInquiry'),
      iconMobile: <assets.NewPolicyDetailIcon />,
      iconWeb: <assets.NewPolicyDetailIcon />,
      // permission: HomePagePermissions.ViewDigitalInquiryHomepage,
      permission: HomePagePermissions.ViewDigitalHomepage,
      navigate: () => navigateDI()
    },
    {
      title: trans.t('menu:BackOffice'),
      iconMobile: <assets.NewBackOfficeIcon />,
      iconWeb: <assets.NewBackOfficeIcon />,
      permission: HomePagePermissions.ViewBusinessBackOfficeHomepage,
      navigate: () => navigateBO()
    },
    {
      title: t('menu:ReleasePolicy'),
      iconMobile: <assets.NewReleasePolicyIcon />,
      iconWeb: <assets.NewReleasePolicyIcon />,
      permission: HomePagePermissions.ViewAMLReleasePolicyHomepage,
      navigate: () =>
        props.navigation.navigate('UserDrawer', {
          screen: 'ReleasePolicyScreen'
        })
    },
    {
      title: trans.t('menu:360Inquiry'),
      iconMobile: <assets.New360InquiryIcon />,
      iconWeb: <assets.New360InquiryIcon />,
      permission: HomePagePermissions.View360InquiryHomepage,
      navigate: () => navigate360()
    },
    {
      title: t('submission:AgentSubmissionForm'),
      iconMobile: <assets.NewAgentSubmissionIcon />,
      iconWeb: <assets.NewAgentSubmissionIcon />,
      permission: HomePagePermissions.ViewAgentServicingHomepage,
      navigate: () =>
        props.navigation.navigate('UserDrawer', {
          screen: 'UtilitiesStack',
          params: {
            screen: 'AgentSearchScreen'
          }
        })
    },
    {
      title: t('Outbound'),
      iconMobile: <assets.NewOutboundIcon />,
      iconWeb: <assets.NewOutboundIcon />,
      permission: HomePagePermissions.OutboundHomepage,
      navigate: () =>
        props.navigation.navigate('UserDrawer', {
          screen: 'OBTaskManagementScreen'
        })
    },
    {
      title: t('Inbound'),
      iconMobile: <assets.NewInboundIcon />,
      iconWeb: <assets.NewInboundIcon />,
      permission: HomePagePermissions.ViewInboundHomepage,
      navigate: () => {
        props.navigation.push('UserDrawer', {
          screen: 'InboundStack'
        })
      }
    }
  ]

  return (
    // <ImageBackground
    //   source={isMobile ? assets.HomeBackground : assets.HomeBackgroundWide}
    //   resizeMode="cover"
    //   style={styles.image}
    // >

    <View style={[styles.image, { height: height }]}>
      <ScrollView showsVerticalScrollIndicator={true}>
        <View style={styles.container}>
          <View style={isMobile ? styles.logo : styles.logoWide}>
            {/* {isWide ? <assets.HomepageLogoWide /> : <assets.HomepageLogo />} */}
            <View style={styles.logoContainer}>{isWide ? <assets.NewHomepageLogo /> : <assets.SmallHomepageLogo />}</View>
            <Text style={isWide ? styles.logoText : styles.mobileLogoText}>
              {t('login:TransformingCustomerFulfillment')}
            </Text>
          </View>
          <View style={[styles.blockContainer, isWide ? styles.btnWrapped : {}]}>
            {/* {actionsList.map((x, i) => {
              const hasPermission = permissions && permissions.includes(x.permission)
              if (!hasPermission) return null
              return (
                <TouchableOpacity
                  style={isWide ? [styles.btn, styles.btnWide] : styles.btn}
                  onPress={x.navigate}
                  key={`pulseAction-${i}`}
                >
                  <Text style={isMobile ? styles.btnText : styles.btnTextWide}>{x.title}</Text>
                  {isMobile ? x.iconMobile : <View style={styles.btnIconWide}>{x.iconWeb}</View>}
                  {isMobile ? (
                    <View style={styles.iconWrapped}>
                      <FontAwesome5 name="angle-right" size={10} color="#FFFFFF" />
                    </View>
                  ) : null}
                </TouchableOpacity>
              )
            })} */}
            {actionsList && actionsList.map((x, i) => {
              const hasPermission = permissions && permissions.includes(x.permission)
              if (!hasPermission) return null
              return (
                <TouchableOpacity onPress={x.navigate} key={`pulseAction-${i}`}>
                  <Container classes={{
                    root: classes.root
                  }}>
                    <View style={styles.blockHeader}>
                      {x.iconWeb}
                    </View>
                    <View style={styles.blockBody}>
                      <Text style={styles.blockTitle}>{x.title}</Text>
                    </View>
                  </Container>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
      </ScrollView>
    </View>

    // </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 24,
    paddingHorizontal: 16
  },
  image: {
    display: 'flex',
    // justifyContent: 'center',
    backgroundColor: '#FFFFFF'
  },
  logo: {
    marginHorizontal: 'auto',
    marginBottom: 32
  },
  logoWide: {
    // marginBottom: 100
  },
  btnWrapped: {
    // width: '100%',
    // maxWidth: 1199,
    // flexDirection: 'row',
    // justifyContent: 'center',
    // marginTop: 40
    maxWidth: 1144,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  btn: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 31,
    paddingHorizontal: 26,
    borderRadius: 16,
    backgroundColor: '#FFFFFF',
    marginBottom: 10,
    shadowOffset: {
      width: 0,
      height: 20
    },
    shadowOpacity: 0.05,
    shadowRadius: 16
  },
  btnWide: {
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: 0,
    marginHorizontal: 15
  },
  btnIconWide: {
    width: 110,
    height: 110,
    padding: 25,
    backgroundColor: '#E5EAEF',
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 35
  },
  btnText: {
    fontSize: 18,
    lineHeight: 21.55,
    marginRight: 61
  },
  btnTextWide: {
    fontSize: 24,
    lineHeight: 32.69,
    fontWeight: '500',
    textAlign: 'center',
    color: '#ED1B2E',
    textTransform: 'uppercase',
    marginRight: 0
  },
  iconWrapped: {
    position: 'absolute',
    right: -10,
    width: 24,
    height: 24,
    backgroundColor: '#ED1B2E',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto'
  },
  logoText: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 26,
    lineHeight: 35,
    color: '#000000',
    marginTop: 35
  },
  mobileLogoText: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 15,
    lineHeight: 21,
    color: '#000000',
    marginTop: 30
  },
  blockContainer: {
    marginTop: 20
  },
  blockLine: {
    marginTop: 10
  },
  blockContent: {
    width: 286,
    paddingVertical: 24,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  blockHeader: {
    // display: 'flex',
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 64,
    backgroundColor: '#ED1B2E'
  },
  blockBody: {
    marginTop: 20,
    justifyContent: 'center',
    // alignItems: 'center',
  },
  blockTitle: {
    color: '#1C1D1F',
    textAlign: 'center',
    // font-family: Noto Sans
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '700'
  }
})
