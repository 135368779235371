export namespace ExportTask {
  export type ExportAVICAD = {
    fromDate: string
    toDate: string
    username: string
  }
  export type SearchAVICAD = {
    fromDate: string
    toDate: string
    userImport: string
    maxRecordPerPage: number
    pageToGet: number
    propertySort: string
    directionSort: string
  }
}
