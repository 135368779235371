import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as t from 'io-ts'
import { ClientC, GetClientsByPolicyResponse, GetOwnerInfoResponse } from './models'

export namespace ChangeDOBAndGenderService {
  export const replaceTwoArray = <A extends object>(array1: Array<A>, array2: Array<A>, typeKey: keyof A) => {
    return array1.map((elm: A) => array2.find((obj: A) => elm[typeKey] === obj[typeKey]) ?? elm)
  }

  export const getClients = (clientIds: Array<{ clientId: string }>) => {
    return pipe(
      POApi.post(`wf-api/customer/customers`)(
        t.type({
          body: t.array(GetOwnerInfoResponse)
        })
      )({
        body: {
          clients: clientIds
        }
      }),
      ZIO.map((clients) => clients)
    )
  }

  export const getClientsByPolicy = (policyNumber: string) => {
    return pipe(
      POApi.get(`wf-api/bo/${policyNumber}/clients`)(GetClientsByPolicyResponse),
      ZIO.map((body) => {
        return body.body ?? {}
      })
    )
  }

  export const getOwnerInfo = (policyNumber: string) => {
    return pipe(
      POApi.get(`wf-api/policy/${policyNumber}/owner`)(
        t.type({
          body: ClientC
        })
      ),
      ZIO.map((client) => {
        return client.body
      })
    )
  }
}
