import { ZIO } from '@mxt/zio'
import { Nullable } from '@mxt/zio/codec'
import { POApi, SelectOption } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export namespace ClaimService {
  export type ClaimInput = {
    pvaClaimId?: string
    insClaimId?: string
    from?: string
    to?: string
    claimStatCd?: string
    cliName?: string
    cliNum?: string
    polNum?: string
  }

  export const searchClaim = (data: ClaimInput) =>
    pipe(
      POApi.get(`business-pulse/api/v1/TPA/claim_search`, {
        params: data
      })(
        t.type({
          claimDataList: Nullable(
            t.array(
              t.type({
                pvaClaimId: t.string,
                insClaimId: t.string,
                pvaReceivedDt: t.string,
                claimApprAmnt: t.string,
                claimStatCd: t.string,
                cliNum: t.string,
                cliName: Nullable(t.string),
                caseType: t.string,
                polNum: t.string
              })
            )
          )
        })
      ),
      ZIO.map((_) => (_.claimDataList ? _.claimDataList : [])),
      ZIO.catchAll(() => ZIO.succeed([]))
    )

  export const exportClaim = (data: ClaimInput) =>
    pipe(
      POApi.get(`business-pulse/api/v1/TPA/claim_export`, {
        params: data
      })(
        t.type({
          claimList: t.array(t.unknown)
        })
      ),
      ZIO.map((_) => _.claimList)
    )

  export const getClaimDetail = ({
    pvaClaimId,
    polNum,
    cliNum
  }: {
    pvaClaimId: string
    polNum: string
    cliNum: string
  }) =>
    pipe(
      POApi.get(`business-pulse/api/v1/TPA/claim_detail`, {
        params: { pvaClaimId, polNum, cliNum }
      })(
        t.type({
          claimList: t.array(
            t.type({
              pvaClaimId: t.string,
              cliNum: t.string,
              cliName: Nullable(t.string),
              polNum: t.string,
              caseType: t.string,
              claimType: t.string,
              eventDt: t.string,
              dischargeDt: t.string,
              pvaReceivedDt: t.string,
              insClaimId: t.string,
              claimStatCd: t.string,
              insReceivedDt: t.string,
              insLastRequestDt: t.string,
              insLastDocRcvDt: t.string,
              insSubDt: t.string,
              claimProvAmnt: t.string,
              claimApprAmnt: t.string,
              claimActuAmnt: t.string,
              claimDeduAmnt: t.string,
              insPayDt: t.string,
              regFrom: t.string,
              deductReason: t.string,
              description: t.string,
              investigate: t.string,
              assessment: t.string,
              diagnosis: t.type({
                diag: t.type({
                  diagCd: t.string,
                  diagName: t.string
                })
              }),
              payees: t.type({
                payee: t.type({
                  payType: t.string,
                  cliNum: t.string,
                  cliName: t.string,
                  gender: t.string,
                  idNum: t.string,
                  birthDt: t.string,
                  bankCd: t.string,
                  bankName: t.string,
                  bankAccNum: t.string,
                  bankAccName: t.string,
                  idIssueDt: Nullable(t.string),
                  idIssuePlace: Nullable(t.string)
                })
              }),
              transferDate: DateFromISOString
            })
          )
        })
      ),
      ZIO.map((_) => _.claimList)
    )

  export const applicationStatusMap: Record<string, string> = {
    Investigation: 'Xác minh',
    Docrcvd: 'Đầy đủ chứng từ',
    Paid: 'Đã thanh toán',
    Declined: 'Từ chối toàn bộ',
    Approved: 'Duyệt bồi thường',
    'In progress': 'Đang xử lý',
    Suspended: 'Tạm hoãn',
    Pending: 'Chờ bổ sung',
    Confirmed: 'HS BLVP chưa nhận HS gốc từ CSYT',
    Cancelled: 'HS hủy'
  }

  export const applicationStatusOptions: SelectOption[] = [
    { label: 'Tất cả', value: 'ALL' },
    { label: 'Xác minh', value: 'Investigation' },
    { label: 'Đầy đủ chứng từ', value: 'Docrcvd' },
    { label: 'Đã thanh toán', value: 'Paid' },
    { label: 'Từ chối toàn bộ', value: 'Declined' },
    { label: 'Duyệt bồi thường', value: 'Approved' },
    { label: 'Đang xử lý', value: 'In progress' },
    { label: 'Tạm hoãn', value: 'Suspended' },
    { label: 'Chờ bổ sung', value: 'Pending' },
    { label: 'HS BLVP chưa nhận HS gốc từ CSYT', value: 'Confirmed' },
    { label: 'HS hủy', value: 'Cancelled' }
  ]

  export const caseTypeMap: Record<string, string> = {
    Reimbursement: 'Hồ sơ hoàn trả',
    'Direct Billing': 'Hồ sơ BLVP'
  }

  export const claimTypeMap: Record<string, string> = {
    Inpatient: 'Nội trú',
    Outpatient: 'Ngoại trú',
    Dental: 'Nha khoa',
    Maternity: 'Thai sản'
  }
}
