import { form2, ChangeContactInfo } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { NonEmptyString, withMessage } from 'io-ts-types'

export namespace ChangeContactInfoForm {
  export const emailValidate = (value: string) =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )

  type NotSpecialStringBrand = {
    readonly NotSpecialString: unique symbol
  }

  type NotSpecialString = t.Branded<NonEmptyString, NotSpecialStringBrand>

  type EmtyStringBrand = {
    readonly EmptyString: unique symbol
  }

  type EmptyString = t.Branded<string, EmtyStringBrand>

  const EmptyString = pipe(
    t.string,
    form2.refine(
      (l): l is EmptyString => l === '',
      (l) => '',
      'EmptyString'
    )
  )
  const NotSpecialString = pipe(
    withMessage(NonEmptyString, () =>
      i18next.t('message:MS020001', { field: i18next.t('contact:Address').toLowerCase() })
    ),
    form2.refine(
      (l): l is NotSpecialString => !/[!@#$%^&*?":{}|()<>\\]/.test(l),
      (l) => i18next.t('message:MS050223'),
      'NotSpecialString'
    )
  )

  const RelationShip = t.type({
    clientId: form2.string.optional,
    clientName: form2.string.optional,
    relationship: form2.selectOption.optional,
    role: form2.string.optional,
    secuityNo: form2.string.optional,
    dob: form2.string.optional,
    gender: form2.string.optional,
    poName: form2.string.optional
    //sameClient: t.boolean
  })

  export const isSameClient = (
    relationship: ChangeContactInfoForm.RelationShip,
    detail: ChangeContactInfo.Detail
  ): boolean => {
    return (
      relationship.clientName === detail.poName &&
      relationship.secuityNo === detail.secuityNo &&
      relationship.dob === detail.dob &&
      relationship.gender === detail.gender
    )
  }

  export type RelationShip = t.TypeOf<typeof RelationShip>

  type PhoneRelationShipBrand = {
    readonly PhoneRelationShip: unique symbol
  }

  type PhoneRelationShip = t.Branded<RelationShip, PhoneRelationShipBrand>

  const PhoneRelationShip = pipe(
    RelationShip,
    form2.refine(
      (l): l is PhoneRelationShip => {
        if (l.role === 'AG') {
          return false
        }
        return true
      },
      (l) => `Client co phone trung voi tu van vien ${l.clientName}`,
      'PhoneRelationShip'
    ),
    form2.refine(
      (l): l is PhoneRelationShip => {
        if (l.role !== 'AG') {
          return l.relationship !== null
        }
        return true
      },
      (l) => i18next.t('message:MS050228'),
      'PhoneRelationShip'
    )
  )

  type EmailRelationShipBrand = {
    readonly EmailRelationShip: unique symbol
  }

  type EmailRelationShip = t.Branded<RelationShip, EmailRelationShipBrand>

  const EmailRelationShip = pipe(
    RelationShip,
    form2.refine(
      (l): l is EmailRelationShip => {
        if (l.role === 'AG') {
          return false
        }
        return true
      },
      (l) => `Client co email trung voi tu van vien ${l.clientName}`,
      'EmailRelationShip'
    ),
    form2.refine(
      (l): l is EmailRelationShip => {
        if (l.role !== 'AG') {
          return l.relationship !== null
        }
        return true
      },
      (l) => i18next.t('message:MS050228'),
      'EmailRelationShip'
    )
  )

  const SelectOption = t.type({
    label: NonEmptyString,
    value: NonEmptyString
  })

  type SelectOption = t.TypeOf<typeof SelectOption>

  const AddressForm = t.type({
    street: NotSpecialString,
    country: withMessage(SelectOption, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Country').toLowerCase() })
    ),
    province: withMessage(SelectOption, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:CityProvince').toLowerCase() })
    ),
    district: withMessage(SelectOption, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:District').toLowerCase() })
    ),
    ward: withMessage(SelectOption, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:Ward').toLowerCase() })
    ),
    applyPolicies: form2.optional(
      t.array(
        t.type({
          selected: t.boolean,
          policyNum: form2.string.required,
          address: form2.string.optional
        })
      )
    )
    //appyPolicies: form2.optional(t.array(RelationShipForm))
  })

  export type AddressForm = t.TypeOf<typeof AddressForm>

  type NumberStringBrand = {
    readonly NumberString: unique symbol
  }

  type NumberString = t.Branded<NonEmptyString, NumberStringBrand>

  const PhoneNumber = pipe(
    withMessage(NonEmptyString, () => i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })),
    form2.refine(
      (l): l is NumberString => /\b\d{10}\b/.test(l),
      (l) => i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') }),
      'NumberString'
    )
  )
  const PhoneForm = t.intersection([
    t.type({
      mobileCode: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS050013', { field: i18next.t('submission:MobilePhone') })
      ),
      mobileNum: PhoneNumber,
      receiveInfoVia: form2.selectOption.optional,
      relationships: form2.optional(t.array(PhoneRelationShip))
    }),
    t.union([
      t.type({
        officeMobileCode: form2.selectOption.optional,
        officePhoneNum: EmptyString
      }),
      t.type({
        officeMobileCode: form2.selectOption.required,
        officePhoneNum: pipe(
          withMessage(NonEmptyString, () =>
            i18next.t('message:MS020001', { field: i18next.t('submission:MobilePhone') })
          ),
          form2.refine(
            (l): l is NumberString => /\b\d{10}\b/.test(l),
            (l) => i18next.t('message:MS050013', { field: i18next.t('submission:SecondaryPhone') }),
            'NumberString'
          )
        )
      })
    ])
  ])

  // const PhoneForm =  t.type({
  //   mobileCode: withMessage( form2.selectOption.required, () => i18next.t('message:MS050013', {field: i18next.t('submission:MobilePhone')})) ,
  //   mobileNum: PhoneNumber,
  //   officeMobileCode: form2.selectOption.required,
  //   officePhoneNum: withMessage(form2.optional(PhoneNumber), () =>  i18next.t('message:MS050013', {field: i18next.t('submission:SecondaryPhone').toLowerCase()}) ),
  //   receiveInfoVia: form2.selectOption.required,
  //   relationships: form2.optional(t.array(PhoneRelationShip))
  // })

  export type PhoneForm = t.TypeOf<typeof PhoneForm>

  type EmailBrand = {
    readonly Email: unique symbol
  }

  type Email = t.Branded<NonEmptyString, EmailBrand>

  const EmailForm = t.type({
    email: pipe(
      withMessage(NonEmptyString, () =>
        i18next.t('message:MS020001', { field: i18next.t('submission:Email').toLowerCase() })
      ),
      form2.refine(
        (l): l is Email => emailValidate(l),
        (l) => i18next.t('message:MS050013', { field: i18next.t('submission:Email') }),
        'Email'
      )
    ),
    receiveInfoVia: form2.selectOption.optional,
    // relationShip: form2.optional(t.array(t.type({
    //     clientId: form2.string.optional ,
    //     clientName: form2.string.optional,
    //     role: withMessage(form2.selectOption.required, () => 'Truong nay la bat buoc')
    // })))
    //relationShip: form2.optional(t.array(RelationShipForm))
    relationships: form2.optional(t.array(EmailRelationShip))
  })

  export type EmailForm = t.TypeOf<typeof EmailForm>

  const ChangeInfoForm = t.intersection([
    t.union([
      t.type({ chooseAddress: t.literal(false) }),
      t.type({ chooseAddress: t.literal(true), address: AddressForm })
    ]),
    t.union([t.type({ choosePhone: t.literal(false) }), t.type({ choosePhone: t.literal(true), phone: PhoneForm })]),
    t.union([t.type({ chooseEmail: t.literal(false) }), t.type({ chooseEmail: t.literal(true), email: EmailForm })])
  ])

  export const codec = ChangeInfoForm

  export type ChangeInfoForm = t.TypeOf<typeof ChangeInfoForm>

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
