import * as React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import * as core from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { ControlProps } from '@pulseops/common'
import i18next from 'i18next'
import { assets } from '../assets'
import { FormControl } from './styled'

type DatePickerProps = ControlProps<Date | null> & {
  label?: string
  alwaysShow?: boolean
  required?: boolean
  disabled?: boolean
  minDate?: ParsableDate
  maxDate?: ParsableDate
  maxDateMessage?: string
  minDateMessage?: string
  placeholder?: string
}

export const BorderFilledDatePicker = (props: DatePickerProps) => {
  const [isError, setIsError] = React.useState(false)

  const onError = (error: any, value: any) => {
    setIsError(!!error)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SC.FormControl error={!!props.errorMessage}>
        <SC.KeyboardDatePicker
          inputVariant="filled"
          clearable
          error={!!props.errorMessage || isError}
          label={props.label}
          disabled={props.disabled}
          value={props.value || null}
          keyboardIcon={<assets.IconCalendar />}
          onChange={(date) => {
            if (props.onChange) {
              props.onChange(date)
            }
          }}
          onError={onError}
          onBlur={props.onBlur}
          format="dd/MM/yyyy"
          minDate={props.minDate}
          maxDate={props.maxDate}
          maxDateMessage={props.maxDateMessage}
          minDateMessage={props.minDateMessage}
          invalidDateMessage={i18next.t('form:error_invalid')}
        />
        {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
      </SC.FormControl>
    </MuiPickersUtilsProvider>
  )
}

const SC = {
  KeyboardDatePicker: withStyles({
    root: {
      '& .MuiFilledInput-root': {
        background: 'white',
        borderRadius: 8,
        height: 70,
        padding: '31px 16px 11px 24px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        },
        '&.Mui-error': {
          border: '1px solid #F80016'
        }
      },
      '& .MuiFilledInput-input': {
        fontSize: 18,
        padding: 0
      },
      '& .MuiFilledInput-underline': {
        '&:before': {
          border: 'none'
        },
        '&:after': {
          border: 'none'
        }
      },
      '& .MuiInputLabel-filled': {
        color: '#000000',
        fontWeight: 300,
        paddingLeft: 15,
        marginTop: 9,
        '&.MuiInputLabel-shrink': {
          fontWeight: 700,
          marginTop: 0,
          transform: 'translate(12px, 16px) scale(0.75)'
        }
      },
      '& .MuiIconButton-root': {
        paddingTop: 0,
        fontSize: 21,
        color: '#1B365D'
      },
      '& .MuiFormHelperText-contained': {
        marginLeft: 0,
        marginRight: 0,
        color: '#F80016'
      }
    }
  })(KeyboardDatePicker),
  FormControl: withStyles({
    root: {
      margin: 0,
      width: '100%',
      minWidth: '120px'
    }
  })(FormControl)
}
