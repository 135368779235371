import * as React from 'react'
import { Input } from './input'
import { InputProps } from 'react-native-elements'

export const InputCurrency = (props: InputProps) => {
  const value = (props.value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Input
      {...props}
      value={value}
      onChangeText={(val) => {
        const targetValue = (val || '').replace(/[^0-9]/g, '')
        const numberValue = targetValue ? (/^\d+$/.test(targetValue) ? Number(targetValue).toString() : '') : ''
        props.onChangeText && props.onChangeText(numberValue)
      }}
    />
  )
}
