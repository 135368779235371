import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  /*TablePagination*/ TableRow
} from '@material-ui/core'
import {
  Checkbox,
  FieldList,
  Input,
  Panel,
  PulseOpsFormat,
  // SelectSearch,
  TaskDetail,
  InputTable as InputTable2,
  GeneralService,
  ErrorHandling,
  formatNumberWithComma
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { InputTable } from '../../common'
import moment from 'moment'
import * as React from 'react'
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { CalculationForm, FinalDecisionForm } from './AdjudicationForm'
import { BenefitClaimAmountFomulaDetailPopup } from './BenefitClaimAmountFomulaDetail.popup'
import { RemainingBenefitPopup } from './RemainingBenefit.popup'
import _ from 'lodash'
import * as D from 'date-fns'

interface Props {
  props: {
    faceAmount: string
    payload: TaskDetail.VerificationClaim
    claimNumber: string
    decision: {
      value: string
      label: string
    }[]
  }
  form: UseFormReturn<CalculationForm>
  finalForm: UseFormReturn<FinalDecisionForm>
  disabled?: boolean
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
}

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#F4F4F4',
    borderColor: '#D3DCE6'
  },
  tableBody: {
    backgroundColor: '#FAFAFA'
  },
  tableStyle: {
    width: 'max-content',
    overflowX: 'auto'
  },
  tableHeaderText: {
    fontWeight: 'bold',
    color: '#70777E'
  }
})

export const Calculation: React.FC<Props> = ({ props, form, finalForm, disabled, setIsCanChangeTab }) => {
  const { t, i18n } = useTranslation(['Adjudication', 'claim', 'requestInfo'])
  // const { payload, claimNumber, decision } = props
  const { payload, claimNumber } = props
  const [amountFomulaToggle, setAmountFomulaToggle] = React.useState<boolean>(false)
  const [remainingBenefitToggle, setRemainingBenefitToggle] = React.useState<boolean>(false)
  const [detailCalculation, setDetailCalculation] = React.useState<{
    benefitName: string
    benefitClaimAmount: number
    sumAssured: string
    duration: number
    benefitClaimType: string
    benefitClaimFormula: string
    declineCode: string
    declineEN: string
    declineVN: string
  }>({
    benefitName: '',
    benefitClaimAmount: 0,
    sumAssured: '',
    duration: 0,
    benefitClaimType: '',
    benefitClaimFormula: '',
    declineCode: '',
    declineEN: '',
    declineVN: ''
  })

  const [detailRemainingBenefit, setDetailRemainingBenefit] = React.useState<{
    benefitName: string
    maxNumberOf: number
    acceptDateInHistory: number
    remainingDate: number
    acceptDateOfThisClaim: number
    benefitClaimType: string
  }>({
    benefitName: '',
    maxNumberOf: 0,
    acceptDateInHistory: 0,
    remainingDate: 0,
    acceptDateOfThisClaim: 0,
    benefitClaimType: ''
  })
  const classes = useStyles()
  const policyStatus = (): string => {
    let result = '-'
    if (payload?.policyRider.status === 'INFORCE') {
      result = t('Adjudication:Inforce')
    }
    return result
  }
  const { control, watch, setValue } = form

  const calculateDetail = useFieldArray<CalculationForm>({
    control,
    name: 'calculateDetail'
  })

  const productName = (): string => {
    let result = '-'
    const rider = payload?.policyRider?.productOptions.map((item) => item.productMainCode + ' ' + item.productMainName)
    if (rider.length > 0) {
      result = rider[0]
    }
    return result
  }

  const calculationInfo = [
    {
      label: t('claim:PolicyNumber'),
      value: payload?.policyRider?.policyNo || '-'
    },
    {
      label: t('claim:EffectiveDate'),
      value: payload?.policyRider?.effectiveDate
        ? moment(payload?.policyRider?.effectiveDate).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('claim:PolicyStatus'),
      value: t(`claim:${payload?.policyRider.status}`),
      isHighlightWarning: payload?.policyRider.status !== 'INFORCE',
      bold: true
    },
    {
      label: t('Adjudication:ProductName'),
      value: productName()
    },
    {
      label: t('claim:ClaimNumber1'),
      value: claimNumber || '-'
    }
  ]

  const onChangeDeductedExpenses = (value: string) => {
    const totalPayableAmount = PulseOpsFormat.thousandSepartorReverse(watch('summaryDetail.totalPayableAmount'))
    const deductedExpenses = PulseOpsFormat.thousandSepartorReverse(value)
    let totalNetPayableAmount: string = '0'
    if (totalPayableAmount)
      if (totalPayableAmount >= deductedExpenses) {
        totalNetPayableAmount = PulseOpsFormat.thousandSepartor(totalPayableAmount - deductedExpenses)
      } else {
        totalNetPayableAmount = '0'
      }
    setValue('summaryDetail.totalNetPayableAmount', totalNetPayableAmount)
  }

  return (
    <SafeAreaView>
      <Panel
        title={`${t('Adjudication:PolicyInformation')} - ${payload?.policyRider?.policyNo || '-'}`}
        isExand={true}
        // allowToggle={!disabled}
      >
        <FieldList dataSource={calculationInfo} />
        {/* CALCULATION SUMMARY */}
        <View style={{ marginTop: 30 }}>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end' }}>
            <Text style={[style.sectionTitle, { paddingBottom: 10 }]}>{t('CalculationSummary')}</Text>
            <Text style={[style.currency, { paddingBottom: 10 }]}>{t('requestInfo:Currency')} : VND</Text>
          </View>
          <View>
            <Text style={[{ paddingBottom: 10, color: '#70777E', fontSize: 15, fontWeight: '700' }]}>
              {t('Adjudication:CalculationDetail')}
            </Text>
          </View>
          <TableContainer component={Paper}>
            <Table className={classes.tableStyle}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableHeaderText} style={{ width: 300 }}>
                    {t('Adjudication:BenefitName')}
                  </TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('Adjudication:AdjudicatorDecision')}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('claim:PayableAmount')}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOP')}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOPFrom')}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOPTo')}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('Adjudication:WOPAmount')}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{t('Adjudication:NumberOfWaiver')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {calculateDetail.fields.map((data, index) => (
                  <React.Fragment key={data.id}>
                    <TableRow>
                      <TableCell>
                        <Controller
                          name={`calculateDetail.${index}.componentCode` as 'calculateDetail.0.componentCode'}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              disabled
                              onBlur={onBlur}
                              onChange={onChange}
                              inputStyle={{
                                fontWeight: 'bold'
                              }}
                              value={`${
                                i18n.language === 'en'
                                  ? watch(`calculateDetail.${index}.componentEN`)
                                  : watch(`calculateDetail.${index}.componentVN`)
                              }`}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Controller
                          name={`calculateDetail.${index}.WOP` as 'calculateDetail.0.WOP'}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Checkbox title={''} value={value} onBlur={onBlur} onChange={onChange} disabled />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        {watch(`calculateDetail.${index}.WOPFrom`)
                          ? moment(watch(`calculateDetail.${index}.WOPFrom`)).format('DD/MM/YYYY')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {watch(`calculateDetail.${index}.WOPTo`)
                          ? moment(watch(`calculateDetail.${index}.WOPTo`)).format('DD/MM/YYYY')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`calculateDetail.${index}.WOPAmount` as 'calculateDetail.0.WOPAmount'}
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <InputTable
                              value={value + ''}
                              onChange={(e: string) => {
                                const newVal = e.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                onChange(newVal)
                                setIsCanChangeTab(false)
                              }}
                              errorMessage={error?.message}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <Controller
                          name={`calculateDetail.${index}.numberOfWaiver` as 'calculateDetail.0.numberOfWaiver'}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: `${t('message:MS020001', { field: t('Adjudication:NumberOfWaiver') })}`
                            }
                          }}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <InputTable2
                              value={value}
                              onChange={(e) => {
                                onChange(e)
                                setIsCanChangeTab(false)
                              }}
                              onBlur={onBlur}
                              maxLength={3}
                              errorMessage={error?.message}
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                    {form.watch(`calculateDetail.${index}.benefitClaimCode`)?.map((item, key) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell component="th" scope="row">
                            <Controller
                              name={
                                `calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimCode` as 'calculateDetail.0.benefitClaimCode.0.benefitClaimCode'
                              }
                              control={control}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Input
                                  disabled
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  value={`${watch(
                                    `calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimCode`
                                  )} ${
                                    i18n.language === 'en'
                                      ? watch(`calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimEN`)
                                      : watch(`calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimVN`)
                                  }`}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            {/* <Controller
                              name={
                                `calculateDetail.${index}.benefitClaimCode.${key}.adjudicatorDecision` as 'calculateDetail.0.benefitClaimCode.0.adjudicatorDecision'
                              }
                              control={control}
                              render={({ field: { value } }) => (
                                <SelectSearch
                                  popupIcon={<></>}
                                  disableUnderline={true}
                                  disabled
                                  value={value}
                                  options={decision || []}
                                  key={value?.value}
                                />
                              )}
                            /> */}
                            <Text style={{ fontSize: 15 }}>
                              {watch(`calculateDetail.${index}.benefitClaimCode.${key}.adjudicatorDecision`)?.label}
                            </Text>
                          </TableCell>
                          <TableCell>
                            <Controller
                              name={
                                `calculateDetail.${index}.benefitClaimCode.${key}.paymentAmount` as 'calculateDetail.0.benefitClaimCode.0.paymentAmount'
                              }
                              control={control}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                  disabled
                                  value={formatNumberWithComma(value || '0')}
                                  onChange={onChange}
                                  errorMessage={error?.message}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <TouchableOpacity
                              onPress={() => {
                                // const durationInsentiveCase: string | undefined = isICU
                                //   ? payload?.detail?.payload?.verification?.subBenefit
                                //       ?.find((_) => _.label === 'Hospitalization')
                                //       ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'
                                //   : D.differenceInDays(
                                //       moment(payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
                                //       moment(payload?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
                                //     ).toString()
                                const durationICU: string | undefined =
                                  payload?.detail?.payload?.verification?.subBenefit
                                    ?.find((_) => _.value === 'SBC01')
                                    ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'

                                const durationHospitalization: string | undefined = payload?.PATIENT_IN
                                  ? D.differenceInDays(
                                      moment(payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
                                      moment(payload?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
                                    ).toString()
                                  : '0'
                                const components = payload.detail.payload.policyRider?.components as Array<any>
                                pipe(
                                  GeneralService.getCalculationRule(
                                    payload?.detail?.processInstanceId || '',
                                    payload.detail.id,
                                    payload.detail.source,
                                    payload?.detail?.createdBy || '',
                                    payload.policyRider?.policyNo,
                                    payload.policyRider?.productCode,
                                    components[0].planName || '',
                                    watch(`calculateDetail.${index}.componentCode`),
                                    item.benefitClaimCode,
                                    durationICU,
                                    durationHospitalization,
                                    item.sumAssure || '',
                                    item.claimAmount || ''
                                  ),
                                  ZIO.map((data) => {
                                    setDetailCalculation({
                                      benefitName: `${watch(
                                        `calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimCode`
                                      )} ${
                                        i18n.language === 'en'
                                          ? watch(`calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimEN`)
                                          : watch(`calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimVN`)
                                      }`,
                                      benefitClaimAmount: data?.benefitClaimAmount || 0,
                                      sumAssured: item.sumAssure || '',
                                      duration:
                                        (data?.benefitClaimType === 'Other'
                                          ? data?.percentage || 0
                                          : data?.currentDuration) || 0,
                                      benefitClaimType: data?.benefitClaimType || '',
                                      benefitClaimFormula: data?.benefitClaimFormula || '',
                                      declineCode: data?.declineCode || '',
                                      declineVN: data?.declineVN || '',
                                      declineEN: data?.declineEN || ''
                                    })
                                  }),
                                  ErrorHandling.run()
                                )
                                setAmountFomulaToggle(true)
                              }}
                            >
                              <Text style={{ fontSize: 15, fontWeight: 'bold' }}>{t('Adjudication:ViewDetail')}</Text>
                            </TouchableOpacity>
                          </TableCell>
                          <TableCell>
                            <TouchableOpacity
                              onPress={() => {
                                // const durationInsentiveCase: string | undefined = isICU
                                //   ? payload?.detail?.payload?.verification?.subBenefit
                                //       ?.find((_) => _.label === 'Hospitalization')
                                //       ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'
                                //   : D.differenceInDays(
                                //       moment(payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
                                //       moment(payload?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
                                //     ).toString()
                                const durationICU: string | undefined =
                                  payload?.detail?.payload?.verification?.subBenefit
                                    ?.find((_) => _.value === 'SBC01')
                                    ?.receiptItems.find((_) => _.receiptItemCode.value === 'RCI02')?.duration || '0'

                                const durationHospitalization: string | undefined = payload?.PATIENT_IN
                                  ? D.differenceInDays(
                                      moment(payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
                                      moment(payload?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate()
                                    ).toString()
                                  : '0'

                                const components = payload.detail.payload.policyRider?.components as Array<any>
                                pipe(
                                  GeneralService.getRemainingBenefit(
                                    payload?.detail?.processInstanceId || '',
                                    payload.detail.id,
                                    payload.detail.source,
                                    payload?.detail?.createdBy || '',
                                    payload.policyRider?.policyNo,
                                    payload.policyRider?.productCode,
                                    components[0]?.planName || '',
                                    watch(`calculateDetail.${index}.componentCode`),
                                    item.benefitClaimCode,
                                    durationICU,
                                    durationHospitalization,
                                    item.sumAssure || '',
                                    parseInt(item.claimAmount || '0').toString() || '',
                                    item.adjudicatorDecision?.value || ''
                                  ),
                                  ZIO.map((data) => {
                                    setDetailRemainingBenefit({
                                      benefitName: `${watch(
                                        `calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimCode`
                                      )} ${
                                        i18n.language === 'en'
                                          ? watch(`calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimEN`)
                                          : watch(`calculateDetail.${index}.benefitClaimCode.${key}.benefitClaimVN`)
                                      }`,
                                      maxNumberOf: data.claimNumberOfDate || 0,
                                      acceptDateInHistory: data.acceptedDateInHistory || 0,
                                      remainingDate: data.remainingDate || 0,
                                      acceptDateOfThisClaim: data.acceptedDateOfClaim || 0,
                                      benefitClaimType: data?.benefitClaimType || ''
                                    })
                                    setRemainingBenefitToggle(true)
                                  }),
                                  ErrorHandling.run()
                                )
                              }}
                            >
                              <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                                {t('Adjudication:ViewRemainingBenefit')}
                              </Text>
                            </TouchableOpacity>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      )
                    })}
                  </React.Fragment>
                ))}
                {calculateDetail.fields.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <View>
                        <Text style={{ textAlign: 'center' }}>{t('common:Nodata')}</Text>
                      </View>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <View style={[style.row, { marginTop: 20 }]}>
            <View style={[style.col, { paddingEnd: 30 }]}>
              <Controller
                name={'summaryDetail.totalPayableAmount'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    value={watch('summaryDetail.totalPayableAmount')}
                    title={t('Adjudication:TotalPayableAmount')}
                    maxLength={15}
                    disabled={true}
                    onChange={(val: string) => {
                      const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      onChange(newVal)
                    }}
                  />
                )}
              />
            </View>
            <View style={[style.col, { paddingEnd: 30 }]}>
              <Controller
                name={'summaryDetail.deductedExpenses'}
                control={control}
                rules={{
                  required: `${t('message:MS020001', { field: t('Adjudication:DeductedExpenses') })}`,
                  maxLength: {
                    value: 15,
                    message: t('message:MS070016', { field: 12 })
                  },
                  validate: (value) => {
                    const checker: string | true =
                      PulseOpsFormat.thousandSepartorReverse(value) >= 0 ? true : (t('message:MS100004') as string)
                    return checker
                  }
                }}
                defaultValue={'0'}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Input
                    value={value ? value : watch('summaryDetail.deductedExpenses')}
                    onChange={(val: string) => {
                      const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      onChange(newVal)
                      onChangeDeductedExpenses(val)
                      setIsCanChangeTab(false)
                    }}
                    onBlur={onBlur}
                    title={t('Adjudication:DeductedExpenses')}
                    errorMessage={error?.message}
                    disabled={disabled}
                    maxLength={15}
                  />
                )}
              />
            </View>
            <View style={[style.col, { paddingEnd: 30 }]}>
              <Controller
                name={'summaryDetail.totalNetPayableAmount'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    value={watch('summaryDetail.totalNetPayableAmount')}
                    title={t('Adjudication:TotalNetPayableAmount')}
                    maxLength={15}
                    disabled={true}
                    onChange={(val: string) => {
                      const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      onChange(newVal)
                    }}
                  />
                )}
              />
            </View>
          </View>
        </View>
      </Panel>
      <BenefitClaimAmountFomulaDetailPopup
        visible={amountFomulaToggle}
        onClose={() => {
          setAmountFomulaToggle(false)
        }}
        onConfirm={() => {
          console.log('confirm')
        }}
        detail={detailCalculation}
      />
      <RemainingBenefitPopup
        visible={remainingBenefitToggle}
        onClose={() => {
          setRemainingBenefitToggle(false)
        }}
        onConfirm={() => {
          console.log('confirm')
        }}
        detail={detailRemainingBenefit}
      />
    </SafeAreaView>
  )
}

const style = StyleSheet.create({
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800',
    paddingTop: 14,
    textTransform: 'uppercase'
  },
  sectionInfo: {
    width: '100%',
    padding: 20,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderTopStartRadius: 8,
    borderTopEndRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    flex: 1 / 3,
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 18
  },
  currency: {
    fontSize: 15,
    lineHeight: 24,
    fontStyle: 'italic',
    fontWeight: '300',
    textAlign: 'right',
    color: '#70777E',
    flex: 1,
    marginEnd: 30
  },
  textInput: {
    borderColor: '#BABABA',
    backgroundColor: '#FFF',
    borderRadius: 8,
    borderWidth: 1,
    padding: 10
  },
  textDisabled: {
    borderColor: 'transparent'
  }
})
