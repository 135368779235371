export interface Client {
  address01?: string
  address02?: string
  address03?: string
  address04?: string
  countryCode?: string
  dob?: string
  dod?: string
  email?: string
  gender?: string
  giveName?: string
  idDate?: string
  location?: string
  married?: string
  mobileNumber?: string
  nationality?: string
  nationality2?: string
  occupationCode?: string
  salutation?: string
  securityNo?: string
  sureName?: string
  fullName?: string
  foreignStreet?: string
  foreignCountry?: string
}
export interface OwnerInfo {
  ownerName: string
  nationalId: string
}

export const specialCharacters = ['*', '&', '$', '#', '\\', '<', '>', '|']
