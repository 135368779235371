import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const OccupationDetail = t.type({
  id: t.string,
  code: t.string,
  classOccupation: t.string,
  nameEn: t.string,
  nameVn: t.string,
  isEnableCompanyInfo: t.string,
  positionCode: t.string,
  positionName: t.string
})

export const OccupationList = t.type({
  id: t.string,
  code: t.string,
  classOccupation: t.string,
  nameEn: t.string,
  nameVn: t.string,
  isEnableCompanyInfo: t.string,
  positionCode: t.string,
  positionName: t.string
})

export const OccupationDetailResponse = t.type({
  body: Maybe(OccupationDetail),
  responseStatus: Maybe(
    t.type({
      code: Maybe(t.number),
      message: Maybe(t.string)
    })
  )
})

export const OccupationsResponse = t.type({
  body: t.array(OccupationList)
})

export type OccupationDetailType = t.TypeOf<typeof OccupationDetail>
export type OccupationDetailResponseType = t.TypeOf<typeof OccupationDetailResponse>
export type OccupationsResponseType = t.TypeOf<typeof OccupationsResponse>
