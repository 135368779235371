import { makeStyles, Paper } from '@material-ui/core'
import { useLoading } from '@mxt/zio-react'
import {
  BorderDatePicker,
  BorderInput,
  BorderSelect,
  DocId,
  EditFooterService,
  Form,
  Input,
  NbuwApi,
  NbuwService,
  SectionHeader,
  Table,
  DetailService,
  ContractDetail,
  SendEmail,
  ProposalStatusOptions,
  FormService
} from '@pulseops/business/core'
import { ErrorHandling, form2, RBAC, Permission } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as _ from 'lodash'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../contract-detail-context'
import { VerificationForm } from './verification-form'
import { VerificationHistory } from './verification-history'
import { SC } from '../sc'

export const Verification = () => {
  const { t } = useTranslation('business')
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const { contractId } = React.useContext(ContractDetailContext)
  const service = NbuwService.forContract(contractId)
  const detailService = DetailService.contract(contractId)

  const classes = useStyles()

  const detail: ContractDetail | null = pipe(detailService.getInfo, ErrorHandling.runDidMount())

  const isEditDecision = roles.includes(Permission['EditDecisionGroupDetailVer'])
  const isEditOthers = roles.includes(Permission['EditChecklistVerifyInfoGroupDetailVer'])
  const [registerOnSave] = EditFooterService.useFooter({ hideCancel: true, hideSave: !isEditDecision && !isEditOthers })

  const [, bindLoading] = useLoading(false)
  const latest = pipe(service.getLatestVerificationDecision, bindLoading, ErrorHandling.runDidMount())

  const {
    base: {
      control,
      formState: { errors, isDirty, isSubmitted }
    },
    handleSubmit
  } = form2.useForm(VerificationForm.codec, {
    defaultValues: {
      decisions: new Array(VerificationForm.mandatoryDocuments.length).fill({}),
      optionalDecisions: new Array(VerificationForm.optionalDocuments.length).fill({})
    }
  })

  pipe(
    handleSubmit((validated) => {
      const body: NbuwApi.AddVerificationDecisionBody = {
        checkList: []
      }
      validated.decisions.forEach((doc, idx) => {
        body.checkList.push({
          masterContractNo: contractId.masterContractNo,
          policyYear: contractId.policyYear,
          checklistName: VerificationForm.mandatoryDocuments[idx],
          decision: doc.decision === FormService.decisionYes,
          comment: doc.comment,
          status: doc.status,
          requestDate: doc.requestDate,
          completeDate: doc.completeDate
        })
      })

      validated.optionalDecisions.forEach((doc, idx) => {
        if (doc.decision) {
          body.checkList.push({
            masterContractNo: contractId.masterContractNo,
            policyYear: contractId.policyYear,
            checklistName: VerificationForm.optionalDocuments[idx],
            decision: doc.decision === FormService.decisionYes,
            comment: doc.comment,
            status: doc.status,
            requestDate: doc.requestDate,
            completeDate: doc.completeDate
          })
        }
      })
      return pipe(service.addVerificationDecision(body), ErrorHandling.run())
    }),
    registerOnSave
  )

  const getRowError = (arrName: string) => (i: number) => (isDirty || isSubmitted) && _.get(errors, `${arrName}.${i}`)

  const getMandatoryRowError = getRowError('decisions')

  const getOptionalRowError = getRowError('optionalDecisions')

  if (!latest) {
    return null
  }

  const getDefaultValue = (docId: DocId) => {
    const doc = latest[docId]
    if (doc) {
      return {
        decision: doc.decision ? FormService.decisionYes : FormService.decisionNo,
        comment: doc.comment,
        status: doc.status,
        requestDate: doc.requestDate,
        completeDate: doc.completeDate
      }
    } else {
      return {
        decision: '',
        comment: '',
        status: '',
        requestDate: null,
        completeDate: null
      }
    }
  }

  return (
    <SC.Container>
      <SC.BorderContainer>
        <Form.Group>
          <SectionHeader>{t('contract_info')}</SectionHeader>
          <Form.Row>
            <Form.Col>
              <Input label={t('proposal_no')} value={contractId.masterContractNo} readonly />
            </Form.Col>
            <Form.Col>
              <Input label={t('policy_no')} value={contractId.masterContractNo} readonly />
            </Form.Col>
            <Form.Col>
              <Input
                label={t('proposal_status')}
                value={ProposalStatusOptions.find((_) => _.value === detail?.status)?.label || t('new_HSYCH')}
                readonly
              />
            </Form.Col>
          </Form.Row>
          <Form.Row>
            <SC.SubtitleContainer>
              <SC.Subtitle>{t('send_request')}</SC.Subtitle>
              <SendEmail email={detail?.policyHolder.contactAddress.email} />
            </SC.SubtitleContainer>
          </Form.Row>
        </Form.Group>
      </SC.BorderContainer>

      <SC.BorderContainer>
        <SectionHeader>
          {t('verification_decision')}
          <SC.Error>[{t('compulsory_comment')}]</SC.Error>
        </SectionHeader>
        <Table.Container component={Paper}>
          <Table.Main>
            <Table.Head>
              <Table.Row>
                <Table.CellHead>{t('paper_requirement')}</Table.CellHead>
                <SC.TableCellHead>{t('submitted')}</SC.TableCellHead>
                <SC.TableCellHead>{t('comment')}</SC.TableCellHead>
                <SC.TableCellHead>{t('status')}</SC.TableCellHead>
                <SC.TableCellHead>{t('request_date')}</SC.TableCellHead>
                <SC.TableCellHead>{t('completed_date')}</SC.TableCellHead>
              </Table.Row>
            </Table.Head>
            {/* decisions */}
            <Table.Body>
              {VerificationForm.mandatoryDocuments.map((docId, i) => (
                <React.Fragment key={i}>
                  <Table.Row className={getMandatoryRowError(i) ? classes.invalidRow : ''}>
                    <SC.TableCellNoBorderBottom style={{ paddingLeft: 16 }}>
                      <SC.RowLabel>
                        {getMandatoryRowError(i) ? <SC.RedLabel>{t(docId)}</SC.RedLabel> : t(docId)}
                        <SC.StarDot> *</SC.StarDot>
                      </SC.RowLabel>
                      {getMandatoryRowError(i)?.decision ? (
                        <SC.Error>
                          <br />
                          {t('required_message')}
                        </SC.Error>
                      ) : (
                        ''
                      )}
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`decisions.${i}.decision`}
                        defaultValue={getDefaultValue(docId).decision}
                        render={({ field }) => (
                          <BorderSelect
                            value={field.value}
                            options={FormService.decisionOptions}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditDecision}
                            errorMessage={getMandatoryRowError(i)?.decision?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`decisions.${i}.comment`}
                        defaultValue={getDefaultValue(docId).comment}
                        render={({ field }) => (
                          <BorderInput
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            maxLength={255}
                            disabled={!isEditOthers}
                            errorMessage={getMandatoryRowError(i)?.comment?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`decisions.${i}.status`}
                        defaultValue={getDefaultValue(docId).status}
                        render={({ field }) => (
                          <BorderSelect
                            value={field.value}
                            options={FormService.statusOptions}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getMandatoryRowError(i)?.status?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`decisions.${i}.requestDate`}
                        defaultValue={getDefaultValue(docId).requestDate}
                        render={({ field }) => (
                          <BorderDatePicker
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getMandatoryRowError(i)?.requestDate?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`decisions.${i}.completeDate`}
                        defaultValue={getDefaultValue(docId).completeDate}
                        render={({ field }) => (
                          <BorderDatePicker
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getMandatoryRowError(i)?.completeDate?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                  </Table.Row>
                  <Table.Row>
                    <SC.TableCellNoBorderTop style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                      {getMandatoryRowError(i)?.type === 'MS000022' && (
                        <SC.Error>{getMandatoryRowError(i)?.message}</SC.Error>
                      )}
                    </SC.TableCellNoBorderTop>
                  </Table.Row>
                </React.Fragment>
              ))}
              {VerificationForm.optionalDocuments.map((docId, i) => (
                <React.Fragment key={i}>
                  <Table.Row className={getOptionalRowError(i) ? classes.invalidRow : ''}>
                    <SC.TableCellNoBorderBottom style={{ paddingLeft: 16 }}>
                      <SC.RowLabel>{t(docId)}</SC.RowLabel>
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`optionalDecisions.${i}.decision`}
                        defaultValue={getDefaultValue(docId).decision}
                        render={({ field }) => (
                          <BorderSelect
                            value={field.value}
                            options={FormService.decisionOptions}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditDecision}
                            errorMessage={getOptionalRowError(i)?.decision?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`optionalDecisions.${i}.comment`}
                        defaultValue={getDefaultValue(docId).comment}
                        render={({ field }) => (
                          <BorderInput
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getOptionalRowError(i)?.comment?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`optionalDecisions.${i}.status`}
                        defaultValue={getDefaultValue(docId).status}
                        render={({ field }) => (
                          <BorderSelect
                            value={field.value}
                            options={FormService.statusOptions}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getOptionalRowError(i)?.status?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`optionalDecisions.${i}.requestDate`}
                        defaultValue={getDefaultValue(docId).requestDate}
                        render={({ field }) => (
                          <BorderDatePicker
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getOptionalRowError(i)?.requestDate?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                    <SC.TableCellNoBorderBottom>
                      <Controller
                        control={control}
                        name={`optionalDecisions.${i}.completeDate`}
                        defaultValue={getDefaultValue(docId).completeDate}
                        render={({ field }) => (
                          <BorderDatePicker
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            disabled={!isEditOthers}
                            errorMessage={getOptionalRowError(i)?.completeDate?.message}
                          />
                        )}
                      />
                    </SC.TableCellNoBorderBottom>
                  </Table.Row>
                  <Table.Row>
                    <SC.TableCellNoBorderTop style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'right' }}>
                      {getOptionalRowError(i)?.type === 'MS000022' && (
                        <SC.Error>{getOptionalRowError(i)?.message}</SC.Error>
                      )}
                    </SC.TableCellNoBorderTop>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          </Table.Main>
        </Table.Container>
      </SC.BorderContainer>
      <VerificationHistory />
    </SC.Container>
  )
}

const useStyles = makeStyles((theme) => ({
  invalidRow: {
    background: 'rgba(255, 0, 0, 0.1)'
  }
}))
