export namespace RiderAlterationConst {
  export const taxDeclarationList: Array<{ id: string; label: string }> = [
    { id: 'Y', label: 'common:Yes' },
    { id: 'N', label: 'common:No' }
  ]

  export const DocumentTypeList: Array<{ code: string; nameEn: string; nameVi: string }> = [
    {
      code: '00',
      nameEn: 'National ID',
      nameVi: 'CMND'
    },
    {
      code: '01',
      nameEn: 'National Citizen ID',
      nameVi: 'CCCD'
    },
    {
      code: '02',
      nameEn: 'Birth certificate',
      nameVi: 'Giấy khai sinh'
    },
    {
      code: '03',
      nameEn: 'Passport',
      nameVi: 'Hộ chiếu'
    }
  ]

  export const AverageIncomeList: Array<{ code: string; descriptionEn: string; descriptionVi: string }> = [
    {
      code: '00',
      descriptionEn: 'Not declared',
      descriptionVi: 'Không khai báo'
    },
    {
      code: '01',
      descriptionEn: 'Below 2.5 mil',
      descriptionVi: 'Dưới 2,5 triệu'
    },

    {
      code: '02',
      descriptionEn: 'From 2.5 mil to below 4.5 mil',
      descriptionVi: 'Từ 2.5 triệu đến dưới 4.5 triệu'
    },
    {
      code: '03',
      descriptionEn: 'From 4.5 mil to below 7.5 mil',
      descriptionVi: 'Từ 4.5 triệu đến dưới 7.5 triệu'
    },
    {
      code: '04',
      descriptionEn: 'From 7.5 mil to below 15 mil',
      descriptionVi: 'Từ 7.5 triệu đến dưới 15 triệu'
    },
    {
      code: '05',
      descriptionEn: 'From 15 mil and above',
      descriptionVi: 'Từ 15 triệu trở lên'
    }
  ]
}
