import {
  AnonymousCredential,
  BaseRequestPolicy,
  BlobServiceClient,
  newPipeline,
  RequestPolicy,
  RequestPolicyOptions,
  WebResource
} from '@azure/storage-blob'
import { Throwable, ZIO } from '@mxt/zio'
import { Ref } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { AuthService } from '../auth'
import { AppConfig } from '../config'
import { POApi } from '../POApi'
import { StorageBlob } from './StorageBlob'
import { LDApi } from '@pulseops/common'
export namespace StorageBlobApi {
  const SasTokenRes = t.type({
    account: t.string,
    containerName: t.string,
    sas: t.string,
    folderName: t.string
  })
  type SasTokenRes = t.TypeOf<typeof SasTokenRes>

  type Config = SasTokenRes & {
    blobServiceClient?: BlobServiceClient
    getUrl(blobName: string): string
    url: string
    authorization: string
    authenVendor: string
  }

  // const diffMinute = 5

  export const getRemoteConfig = (category: string, corelationId: string) =>
    POApi.post(`pulseops/api/v1/azure/sas-token`)(SasTokenRes)({ category, corelationId })

  // const getTime = (endTime?: string) => D.subMinutes(endTime ? new Date(endTime) : new Date(), diffMinute)

  const configRef = Ref.make<O.Option<Config>>(O.none)
  // const endTimeRef = Ref.make<Date>(getTime())

  function setMetaDataOnHeader(webRequest: WebResource, metaData?: StorageBlob.MetaDataUpload) {
    const functionType = metaData?.functionType ?? ''
    switch (functionType) {
      case 'uploadDocument':
        {
          if (!!metaData?.polnum || !!metaData?.propnum) {
            webRequest.headers.set('x-ms-meta-type', metaData.type)
            webRequest.headers.set('x-ms-meta-doctype', metaData.doctype)
            webRequest.headers.set('x-ms-meta-class', metaData.class)
            webRequest.headers.set('x-ms-meta-docid', metaData.docid)
            webRequest.headers.set('x-ms-meta-maindoc', metaData.maindoc)
            webRequest.headers.set('x-ms-meta-subdoc', metaData.subdoc)
            webRequest.headers.set('x-ms-meta-polnum', metaData.polnum)
            webRequest.headers.set('x-ms-meta-batchno', metaData.batchno)
            webRequest.headers.set('x-ms-meta-propnum', metaData.propnum ?? '')
          } else {
            webRequest.headers.set('x-ms-meta-type', metaData?.type ?? '')
            webRequest.headers.set('x-ms-meta-doctype', metaData?.doctype ?? '')
            webRequest.headers.set('x-ms-meta-class', metaData?.class ?? '')
            webRequest.headers.set('x-ms-meta-docid', metaData?.docid ?? '')
            webRequest.headers.set('x-ms-meta-maindoc', metaData?.maindoc ?? '')
            webRequest.headers.set('x-ms-meta-subdoc', metaData?.subdoc ?? '')
            webRequest.headers.set('x-ms-meta-agentnum', metaData?.agentnum ?? '')
            webRequest.headers.set('x-ms-meta-batchno', metaData?.batchno ?? '')
          }
          if (metaData?.class === 'POLICYINFOTMP') {
            webRequest.headers.set('x-ms-meta-trigger', metaData.trigger ?? '')
            webRequest.headers.set('x-ms-meta-doctypebpm', metaData.doctypebpm ?? '')
          }
        }
        break
      default: {
        webRequest.headers.set('x-ms-meta-type', metaData?.type ?? '')
        webRequest.headers.set('x-ms-meta-doctype', metaData?.doctype ?? '')
        webRequest.headers.set('x-ms-meta-class', metaData?.class ?? '')
        webRequest.headers.set('x-ms-meta-docid', metaData?.docid ?? '')
        webRequest.headers.set('x-ms-meta-maindoc', metaData?.maindoc ?? '')
        webRequest.headers.set('x-ms-meta-subdoc', metaData?.subdoc ?? '')
        webRequest.headers.set('x-ms-meta-polnum', metaData?.polnum ?? '')
        webRequest.headers.set('x-ms-meta-batchno', metaData?.batchno ?? '')
      }
    }
    // return originmetaData
  }
  class RequestIDPolicyFactory {
    prefix: string
    Container: string
    Token: string
    Blob: string
    authorization: string
    authenVendor: string
    transactionMetaData?: StorageBlob.MetaDataUpload
    fileType?: string
    // Constructor to accept parameters
    constructor(
      prefix: string,
      Container: string,
      Token: string,
      Blob: string,
      authorization: string,
      authenVendor: string,
      transactionMetaData?: StorageBlob.MetaDataUpload,
      fileType?: string
    ) {
      this.prefix = prefix
      this.Container = Container
      this.Token = Token
      this.Blob = Blob
      this.authorization = authorization
      this.authenVendor = authenVendor
      this.transactionMetaData = transactionMetaData
      this.fileType = fileType
    }
    // create() method needs to create a new RequestIDPolicy object
    create(nextPolicy: RequestPolicy, options: RequestPolicyOptions) {
      return new RequestIDPolicy(
        nextPolicy,
        options,
        this.prefix,
        this.Container,
        this.Token,
        this.Blob,
        this.authorization,
        this.authenVendor,
        this.transactionMetaData,
        this.fileType
      )
    }
  }

  class RequestIDPolicy extends BaseRequestPolicy {
    prefix: string
    Container: string
    Token: string
    Blob: string
    authorization: string
    authenVendor: string
    transactionMetaData?: StorageBlob.MetaDataUpload
    fileType?: string
    constructor(
      nextPolicy: RequestPolicy,
      options: RequestPolicyOptions,
      prefix: string,
      Container: string,
      Token: string,
      Blob: string,
      authorization: string,
      authenVendor: string,
      transactionMetaData?: StorageBlob.MetaDataUpload,
      fileType?: string
    ) {
      super(nextPolicy, options)
      this.prefix = prefix
      this.Container = Container
      this.Token = Token
      this.Blob = Blob
      this.authorization = authorization
      this.authenVendor = authenVendor
      this.transactionMetaData = transactionMetaData
      this.fileType = fileType
    }

    // Customize HTTP requests and responses by overriding sendRequest
    // Parameter request is WebResource type
    async sendRequest(request: WebResource) {
      if (this.prefix === 'Metadata') {
        // Customize client request ID header
        request.headers.set('Comp', 'metadata')
      } else if (this.prefix === 'Properties') {
        // Customize client request ID header
        request.headers.set('Comp', 'properties')
      }
      // Customize client request ID header
      request.headers.set('Container', this.Container)
      request.headers.set('Token', this.Token)
      request.headers.set('Blob', this.Blob)
      request.headers.set('authorization', `Bearer ${this.authorization}`)
      request.headers.set(`x-authen-vendor`, this.authenVendor)
      request.headers.set('content-type', this.fileType ?? '')
      //setMetaData to header when upload file to azures
      setMetaDataOnHeader(request, this.transactionMetaData)
      request.headers.set('x-ms-blob-content-type', this.fileType ?? '')
      // response is HttpOperationResponse type
      const response = await this._nextPolicy.sendRequest(request)
      // Modify response here if needed
      return response
    }
  }
  const initConfig = (category: string, corelationId: string, transactionCustomUrl?: string) =>
    pipe(
      ZIO.zipPar(getRemoteConfig(category, corelationId), AppConfig.get, AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([cf, configInfo, tokenInfo, loginType]) => {
        const { account, containerName, folderName, sas } = cf
        // const sas = `?${cf.sas}`
        const configUrl = configInfo.apiUrl
        const currVersion = configInfo.version.split('.').join('-') || '1-0-0'
        const url = `${configUrl}/azurestorage/${currVersion}`
        let getUrl = (blobName: string) => `${configUrl}/azurestorage/${currVersion}/${containerName}`
        let containerNameCustom = containerName
        let folderNameCustom = folderName

        switch (transactionCustomUrl) {
          case 'WRITEOFF':
            getUrl = (blobName: string) => `${configUrl}/azurestorage/${currVersion}/premiumcollection`
            containerNameCustom = 'premiumcollection'
            folderNameCustom = `PCWRITEOFF/${folderName}`
            break
          case 'AML':
            getUrl = (blobName: string) => `${configUrl}/azurestorage/${currVersion}/premiumcollection`
            containerNameCustom = 'premiumcollection'
            folderNameCustom = `PC-AML/${folderName}`
            break
          case 'CLICKTOSEND':
            getUrl = (blobName: string) => `${configUrl}/azurestorage/${currVersion}/pulseforops`
            containerNameCustom = 'pulseforops'
            folderNameCustom = `CLICKTOSEND/${corelationId}`
            break
          default:
            break
        }

        const authorization = tokenInfo
        const authenVendor = loginType
        const configData = {
          account,
          containerName: containerNameCustom,
          sas: sas,
          // blobServiceClient,
          folderName: folderNameCustom,
          getUrl,
          url,
          authorization,
          authenVendor
        }
        return pipe(
          configRef.set(O.some(configData)),
          // ZIO.flatMap(() => setEndTime(sas)),
          ZIO.asVoid
        )
      }),
      ZIO.mapError(() => Throwable('StorageBlobApi: Request config error'))
    )

  const initConfigLP = (category: string, corelationId: string, accessToken: string) =>
    pipe(
      AppConfig.get,
      ZIO.flatMap((configInfo) => {
        // const sas = `?${cf.sas}`
        const containerName = atob(configInfo.containerName)
        const configUrl = configInfo.apiUrl
        const currVersion = configInfo.version.split('.').join('-') || '1-0-0'
        const url = `${configUrl}/azurestorage/${currVersion}`
        const getUrl = (blobName: string) => `${configUrl}/azurestorage/${currVersion}/${containerName}`
        const authorization = accessToken
        const authenVendor = 'GUEST'
        const configData = {
          account: '',
          containerName: containerName,
          sas: '',
          // blobServiceClient,
          folderName: category + corelationId,
          getUrl,
          url,
          authorization,
          authenVendor
        }
        return pipe(
          configRef.set(O.some(configData)),
          // ZIO.flatMap(() => setEndTime(sas)),
          ZIO.asVoid
        )
      }),
      ZIO.mapError((e) => {
        console.log(e)
        return Throwable('StorageBlobApi: Request config error')
      })
    )

  // const checkToInit = pipe(
  //   endTimeRef.get,
  //   ZIO.flatMap((endTime) => (isRefresh(endTime) ? initConfig : ZIO.unit))
  // )

  // const isRefresh = (endTime: Date) => D.isBefore(endTime, new Date())

  // const setEndTime = (sas: string) => {
  //   const endTime = sas.substr(sas.indexOf('&se=') + 4, 20)
  //   return endTimeRef.set(getTime(endTime))
  // }

  export const getBlobServiceClient = (
    containerName: string,
    sasToken: string,
    url: string,
    blobName: string,
    authorization: string,
    authenVendor: string,
    transactionMetaData?: StorageBlob.MetaDataUpload,
    fileType?: string
  ) => {
    const pipeline = newPipeline(new AnonymousCredential())
    pipeline.factories.unshift(
      new RequestIDPolicyFactory(
        'Default',
        containerName,
        sasToken,
        blobName,
        authorization,
        authenVendor,
        transactionMetaData,
        fileType
      )
    )
    const blobServiceClient = new BlobServiceClient(url, pipeline)
    return blobServiceClient
  }

  export const getRefConfig = pipe(
    configRef.get,
    ZIO.absolveOption(() => Throwable('StorageBlobApi: missing azure config'))
  )

  export const getConfig = (category: string, corelationId: string, transactionCustomUrl?: string) =>
    pipe(
      // checkToInit,
      initConfig(category, corelationId, transactionCustomUrl),
      ZIO.flatMap(() => getRefConfig)
    )
  export const getConfigLP = (category: string, corelationId: string, accessToken: string) =>
    pipe(
      // checkToInit,
      initConfigLP(category, corelationId, accessToken),
      ZIO.flatMap(() => getRefConfig)
    )
}
