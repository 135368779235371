import { validate } from '@material-ui/pickers'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  Frequency,
  FrequencyMapping,
  ChangeContractService,
  Input,
  PulseOpsFormat,
  Select,
  SubmissionService
} from '@pulseops/common'
import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { MajorCombinedForm } from '../major-combined-form'
interface Props {
  policyNum: string
  isConfirmed: boolean
  validateBeforeContinue: ({ validateChangeContract }: { validateChangeContract: () => Promise<boolean> }) => void
  form: UseFormReturn<MajorCombinedForm.MajorCombined, object>
}

export const ChangeContractComponent: React.FC<Props> = ({ isConfirmed, policyNum, validateBeforeContinue, form }) => {
  const [loading, bindLoader] = useLoading(false)
  const { i18n, t } = useTranslation()
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const [currentSumAssured, setCurrentSumAssured] = React.useState<number>(0)
  const [currentPolicyYear, setCurrentPolicyYear] = React.useState<number>(0)
  const [currentPremiumYear, setCurrentPremiumYear] = React.useState<number>(0)
  React.useEffect(() => {
    if (isFocused) {
      form.setValue('changeContract', {
        baseProductCode: '',
        riskCommDate: '',
        age: 0,
        sumAssured: '',
        policyYear: '',
        riskCessationDate: '',
        premiumYear: '',
        installmentPremium: ''
      })
      // getData()
    }
    getData()
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const getData = () =>
    pipe(
      SubmissionService.getPolicy(policyNum),
      ZIO.flatMap((policy) => {
        form.setValue('changeContract.baseProductCode', policy.body.basicCode ?? '')
        return pipe(
          ChangeContractService.getDetail(
            policyNum,
            policy.body.mainLifeAssuredNumber ?? '',
            policy.body.basicCode ?? ''
          ),
          ZIO.map((detail) => {
            console.log(detail, 'detail')
            form.setValue('changeContract.riskCommDate', detail.riskCommDate ?? '')
            form.setValue('changeContract.age', detail.age ?? '')
            form.setValue('changeContract.riskCessationDate', detail.riskCessDate ?? '')
            setCurrentPolicyYear(detail.currentPolicyYear)
            setCurrentPremiumYear(detail.currentPremiumYear)
            setCurrentSumAssured(detail.currentSumAssured)
          })
        )
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )

  validateBeforeContinue({
    validateChangeContract: async () => {
      const detailData = form.getValues('changeContract')
      if (!detailData) return false
      else return true
    }
  })

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.sectionContent}>
        <View style={styles.sectionRow}>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('MajorCombined:BasicProduct')}</Text>
            <Text style={styles.field_description}>{form.watch('changeContract.baseProductCode')}</Text>
          </View>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:RiskCommencementDate')}</Text>
            <Text style={styles.field_description}>
              {form.watch('changeContract.riskCommDate')
                ? PulseOpsFormat.dateStringtoFormat(form.watch('changeContract.riskCommDate'), 'DD/MM/YYYY')
                : ''}
            </Text>
          </View>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('MajorCombined:Age')}</Text>
            <Text style={styles.field_description}>{form.watch('changeContract.age')}</Text>
          </View>
        </View>

        <View style={[styles.sectionRow, styles.secondLine]}>
          <View style={styles.col_4}>
            <Controller
              name="changeContract.sumAssured"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('MajorCombined:NewSumAssured') })}`
                },
                validate: (value) => (value && Number(value) === currentSumAssured ? `${t('message:MS020031')}` : true)
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('MajorCombined:NewSumAssured')}
                  disabled={isConfirmed}
                  value={value}
                  onChange={onChange}
                  inputType={'money'}
                  required
                  onBlur={onBlur}
                  maxLength={100}
                  errorMessage={error?.message ?? ''}
                />
              )}
            />
          </View>
          <View style={styles.col_4}>
            <Controller
              name="changeContract.policyYear"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('MajorCombined:NewPolicyYear') })}`
                },
                validate: (value) => (value && Number(value) === currentPolicyYear ? `${t('message:MS020032')}` : true)
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('MajorCombined:NewPolicyYear')}
                  disabled={isConfirmed}
                  value={value}
                  onChange={onChange}
                  inputType={'number'}
                  required
                  onBlur={onBlur}
                  maxLength={100}
                  errorMessage={error?.message ?? ''}
                />
              )}
            />
          </View>
          <View style={styles.col_4}>
            <Controller
              name="changeContract.premiumYear"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('MajorCombined:NewPremiumYear') })}`
                },
                validate: (value) => (value && Number(value) === currentPremiumYear ? `${t('message:MS020033')}` : true)
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('MajorCombined:NewPremiumYear')}
                  disabled={isConfirmed}
                  value={value}
                  onChange={onChange}
                  inputType={'number'}
                  required
                  onBlur={onBlur}
                  maxLength={100}
                  errorMessage={error?.message ?? ''}
                />
              )}
            />
          </View>
        </View>

        <View style={[styles.sectionRow, styles.secondLine]}>
          <View style={styles.col_4}>
            <Text style={styles.field_title}>{t('requestInfo:RiskCessationDate')}</Text>
            <Text style={styles.field_description}>
              {form.watch('changeContract.riskCessationDate')
                ? PulseOpsFormat.dateStringtoFormat(form.watch('changeContract.riskCessationDate'), 'DD/MM/YYYY')
                : ''}
            </Text>
          </View>
          <View style={styles.col_4}>
            <Controller
              name="changeContract.installmentPremium"
              control={form.control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  title={t('MajorCombined:InstallmentPremium')}
                  disabled={isConfirmed}
                  value={value}
                  onChange={onChange}
                  inputType={'money'}
                  // required
                  onBlur={onBlur}
                  maxLength={100}
                />
              )}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  headerField: {
    fontSize: 15,
    fontWeight: '700'
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    paddingBottom: 35
  },
  sectionRow: {
    flex: 1,
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 30
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  red_line: {
    fontWeight: 'bold',
    color: '#ed1c2e'
  }
})
