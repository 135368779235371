import { Throwable, ZIO } from '@mxt/zio'
import { Nullable } from '@mxt/zio/codec'
import { Ref, ZStream } from '@mxt/zio/stream'
import * as A from 'fp-ts/Array'
import { flow, pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { Lens } from 'monocle-ts'

export namespace PaginationState {
  export const itemC = <C extends t.Mixed>(ItemC: C, getIndex: (item: t.TypeOf<C>) => string) => {
    type Item = t.TypeOf<C>

    const PageRecordC = t.record(t.string, Nullable(t.array(t.string)))
    type PageRecord = t.TypeOf<typeof PageRecordC>

    const PageSizeC = t.type({
      total: t.number,
      pageRecord: PageRecordC
    })
    type PageSize = t.TypeOf<typeof PageSizeC>

    const PageSizeRecordC = t.record(t.string, Nullable(PageSizeC))
    type PageSizeRecord = t.TypeOf<typeof PageSizeRecordC>

    const ItemRecordC = t.record(t.string, Nullable(ItemC))
    type ItemRecord = t.TypeOf<typeof ItemRecordC>

    const PaginationStateC = t.type({
      itemRecord: ItemRecordC,
      pageSizeRecord: PageSizeRecordC
    })
    type PaginationState = t.TypeOf<typeof PaginationStateC>

    const initialState: PaginationState = {
      itemRecord: {},
      pageSizeRecord: {}
    }

    const getService = <S>(ref: Ref<Throwable, S>, lens: Lens<S, PaginationState>) => {
      const clear = pipe(lens.set(initialState), ref.update, ZIO.asVoid)

      const pageSizeLens = lens.compose(Lens.fromProp<PaginationState>()('pageSizeRecord'))
      const itemRecordLens = lens.compose(Lens.fromProp<PaginationState>()('itemRecord'))

      const item = (id: string) => {
        const itemLens = itemRecordLens.compose(Lens.fromProp<ItemRecord>()(id))

        return {
          get: ref.select(itemLens.get),
          getOption: ref.select(flow(itemLens.get, O.fromNullable)),
          watch: ref.watch(itemLens.get)
        }
      }

      const updateItems = (items: Item[]) =>
        pipe(
          itemRecordLens.modify((record) =>
            pipe(
              items,
              A.reduce(record, (b, a) => ({
                ...b,
                [getIndex(a)]: a
              }))
            )
          ),
          ref.update,
          ZIO.map(() => pipe(items, A.map(getIndex)))
        )

      const updateItem = (item: Item) => updateItems([item])

      const size = (size: number, query = '') => {
        const sizeLens = pageSizeLens.compose(
          Lens.fromNullableProp<PageSizeRecord>()(query + '_' + size.toString(), {
            total: 0,
            pageRecord: {}
          })
        )

        const totalLens = sizeLens.compose(Lens.fromProp<PageSize>()('total'))

        const pageRecord = sizeLens.compose(Lens.fromProp<PageSize>()('pageRecord'))

        const pageLens = (page: number) => pageRecord.compose(Lens.fromProp<PageRecord>()(page.toString()))

        const watchPage = (page: number) =>
          pipe(
            ZStream.combineLatest(pipe(pageLens(page).get, ref.watch), pipe(itemRecordLens.get, ref.watch)),
            ZStream.map(([itemIds, itemRecord]): Item[] | null => itemIds && itemIds.map((id) => itemRecord[id]))
          )

        const watchTotal = pipe(totalLens.get, ref.watch)

        const updateTotal = (value: number) => pipe(totalLens.set(value), ref.update)

        const updatePage = ({ total, data }: { total: number; data: Item[] }) =>
          pipe(
            ZIO.zip(updateTotal(total), updateItems(data)),
            ZIO.map(() => data.map(getIndex))
          )
        return {
          clear,
          updatePage,
          pageLens,
          watchPage,
          watchTotal
        }
      }

      return {
        clear,
        item,
        updateItems,
        updateItem,
        size
      }
    }

    return {
      codec: PaginationStateC,
      initialState,
      getService
    }
  }
}
