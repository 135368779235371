export namespace TopUpConst {
  export enum FundCode {
    UBL1 = 'UBL1',
    UEQ1 = 'UEQ1',
    UFI1 = 'UFI1',
    UGR1 = 'UGR1',
    UPS1 = 'UPS1',
    USB1 = 'USB1',
    UBL2 = 'UBL2',
    UEQ2 = 'UEQ2',
    UFI2 = 'UFI2',
    UGR2 = 'UGR2',
    UPS2 = 'UPS2',
    USB2 = 'USB2',
    USE1 = 'USE1',
    USE2 = 'USE2'
  }

  export enum TopUpOption {
    ByPercentage = 'ByPercentage',
    ByAmount = 'ByAmount'
  }

  export const mapFund = new Map<string, string>([
    // [FundCode.UBL1, $localize`:@@Fund.UBL1:UBL1`],
    // [FundCode.UEQ1, $localize`:@@Fund.UEQ1:UEQ1`],
    // [FundCode.UFI1, $localize`:@@Fund.UFI1:UFI1`],
    // [FundCode.UGR1, $localize`:@@Fund.UGR1:UGR1`],
    // [FundCode.UPS1, $localize`:@@Fund.UPS1:UPS1`],
    // [FundCode.USB1, $localize`:@@Fund.USB1:USB1`],
    // [FundCode.UBL2, $localize`:@@Fund.UBL2:UBL2`],
    // [FundCode.UEQ2, $localize`:@@Fund.UEQ2:UEQ2`],
    // [FundCode.UFI2, $localize`:@@Fund.UFI2:UFI2`],
    // [FundCode.UGR2, $localize`:@@Fund.UGR2:UGR2`],
    // [FundCode.UPS2, $localize`:@@Fund.UPS2:UPS2`],
    // [FundCode.USB2, $localize`:@@Fund.USB2:USB2`]
    [FundCode.UBL1, `UBL1`],
    [FundCode.UEQ1, `UEQ1`],
    [FundCode.UFI1, `UFI1`],
    [FundCode.UGR1, `UGR1`],
    [FundCode.UPS1, `UPS1`],
    [FundCode.USB1, `USB1`],
    [FundCode.UBL2, `UBL2`],
    [FundCode.UEQ2, `UEQ2`],
    [FundCode.UFI2, `UFI2`],
    [FundCode.UGR2, `UGR2`],
    [FundCode.UPS2, `UPS2`],
    [FundCode.USB2, `USB2`]
  ])

  export const FundList: Array<{
    fundCode: FundCode
    desVi: string
    desEn: string
  }> = [
    {
      fundCode: FundCode.UBL1,
      desEn: 'PRUlink Balanced',
      desVi: 'Quỹ PRUlink Cân Bằng'
    },
    {
      fundCode: FundCode.UEQ1,
      desEn: 'PRUlink VN Equity',
      desVi: 'Quỹ PRUlink Cổ Phiếu Việt Nam'
    },
    {
      fundCode: FundCode.UFI1,
      desEn: 'PRUlink VN FixedInc',
      desVi: 'Quỹ PRUlink Trái Phiếu Việt Nam'
    },
    {
      fundCode: FundCode.UGR1,
      desEn: 'PRUlink Growth',
      desVi: 'Quỹ PRUlink Tăng Trưởng'
    },
    {
      fundCode: FundCode.UPS1,
      desEn: 'PRUlink Preserver',
      desVi: 'Quỹ PRUlink Bảo Toàn'
    },
    {
      fundCode: FundCode.USB1,
      desEn: 'PRUlink Stable',
      desVi: 'Quỹ PRUlink Bền Vững'
    },
    {
      fundCode: FundCode.USE1,
      desEn: 'Prulink Vietnam Sustainable Development Equity Fund',
      desVi: 'Quỹ PRUlink Tương lai xanh'
    },
    {
      fundCode: FundCode.UBL2,
      desEn: 'PRUlink Balanced top-up',
      desVi: 'Quỹ PRUlink Cân Bằng'
    },
    {
      fundCode: FundCode.UEQ2,
      desEn: 'PRUlink VN Equity top-up',
      desVi: 'Quỹ PRUlink Cổ Phiếu Việt Nam'
    },
    {
      fundCode: FundCode.UFI2,
      desEn: 'PRUlink VN FixedInc top-up',
      desVi: 'Quỹ PRUlink Trái Phiếu Việt Nam'
    },
    {
      fundCode: FundCode.UGR2,
      desEn: 'PRUlink Growth top-up',
      desVi: 'Quỹ PRUlink Tăng Trưởng'
    },
    {
      fundCode: FundCode.UPS2,
      desEn: 'PRUlink Preserver top-up',
      desVi: 'Quỹ PRUlink Bảo Toàn'
    },
    {
      fundCode: FundCode.USB2,
      desEn: 'PRUlink Stable top-up',
      desVi: 'Quỹ PRUlink Bền Vững'
    },
    {
      fundCode: FundCode.USE2,
      desEn: 'Prulink Vietnam Sustainable Development Equity Fund Topup',
      desVi: 'Quỹ PRUlink Tương lai xanh'
    }
  ]

  export const FT25List: Array<{
    fundCode: string
    desVi: string
    desEn: string
  }> = [
    {
      fundCode: 'VFD1',
      desEn: 'Universal Life Fund 1 (VND)',
      desVi: 'Tài khoản hợp đồng'
    },
    {
      fundCode: 'VFD2',
      desEn: 'ULife Flex Premium Fund(VND)',
      desVi: 'Tài khoản hợp đồng'
    },
    {
      fundCode: 'VFD3',
      desEn: 'New UL RP - TP fund',
      desVi: 'Tài khoản cơ bản'
    },
    {
      fundCode: 'VFD4',
      desEn: 'New UL RP - top up fund',
      desVi: 'Tài khoản tích lũy thêm'
    },
    {
      fundCode: 'VFD5',
      desEn: 'New UL SP - Target prem fund',
      desVi: 'Tài khoản cơ bản'
    },
    {
      fundCode: 'VFD6',
      desEn: 'New UL SP - top up fund',
      desVi: 'Tài khoản tích lũy thêm'
    },
    {
      fundCode: 'VFE5',
      desEn: 'New UL RP2016 - EPA',
      desVi: 'Tài khoản tích lũy thêm'
    },
    {
      fundCode: 'VFE6',
      desEn: 'New UL with UDR EPA',
      desVi: 'Tài khoản tích lũy thêm'
    },
    {
      fundCode: 'VFT5',
      desEn: 'New UL RP2016 - TPA',
      desVi: 'Tài khoản cơ bản'
    },
    {
      fundCode: 'VFT6',
      desEn: 'New UL with UDR TPA',
      desVi: 'Tài khoản cơ bản'
    },
    {
      fundCode: 'IFD7',
      desEn: 'Individual pension',
      desVi: 'Tài khoản hưu trí'
    }
  ]
}
