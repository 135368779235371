import { Task } from '@mxt/zio'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { BeneficiaryDesignationData, ExcessPremiumSubmission, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export namespace ExcessPremiumService {
  export const getDetail = (policyNum: string): Task<ExcessPremiumSubmission.Detail> =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/policy/account-balance`)(
          t.type({
            body: t.type({
              accountGroups: t.array(
                t.type({
                  amount: t.number,
                  sacsCode: t.string,
                  sacsType: t.string
                })
              )
            })
          })
        )({
          body: {
            policyNum,
            function: 'TOTAL',
            accountGroups: [
              {
                sacsCode: 'LP',
                sacsType: 'S'
              },
              {
                sacsCode: 'LF',
                sacsType: 'WF'
              },
              {
                sacsCode: 'LP',
                sacsType: 'U'
              },
              {
                sacsCode: 'LX',
                sacsType: 'TF'
              },
              {
                sacsCode: 'LP',
                sacsType: 'PX'
              },
              {
                sacsCode: 'LP',
                sacsType: 'PS'
              },
              {
                sacsCode: 'LX',
                sacsType: 'EX'
              },
              {
                sacsCode: 'LI',
                sacsType: 'MO'
              }
            ]
          }
        }),
        SubmissionService.getFileNetAccess(policyNum)
      ),
      ZIO.map(([balances, receiptFlag]): ExcessPremiumSubmission.Detail => {
        const excessPremiumAmount = balances.body.accountGroups.reduce((total, e) => total + e.amount, 0)
        return {
          excessPremiumAmount,
          receiptFlag: receiptFlag
        }
      })
    )
  export const submit =
    (
      policyNum: string,
      data: ExcessPremiumSubmission.SubmitData,
      documents: BeneficiaryDesignationData.BeneficiaryDocumentFile[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/excess-premium`,
        { body: data },
        policyNum,
        requestauth,
        documents
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/excess-premium`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )
  export const getDataCheckAccess = (policyNum: string): Task<ExcessPremiumSubmission.AccessDataCheck> =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNum), getDetail(policyNum)),
      ZIO.map(
        ([policy, excess]): ExcessPremiumSubmission.AccessDataCheck => ({
          contractStatus: policy.body.status ?? '',
          excessPremium: excess.excessPremiumAmount
        })
      )
    )
}
