import { enumC } from '@mxt/zio/codec'

export enum TransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  END = 'END',
  REJECTED = 'REJECTED',
  CALLING = 'Calling'
}
export const TransactionStatusC = enumC(TransactionStatus)

const mapTransactionStatus = new Map<TransactionStatus, TransactionStatusLabel>([
  [
    TransactionStatus.IN_PROGRESS,
    { label: 'In progress', color: '#1EA5FC', backgroundColor: 'rgba(86, 204, 242, 0.3)' }
  ],
  [TransactionStatus.PENDING, { label: 'Pending', color: '#FF6F00', backgroundColor: 'rgba(242, 201, 76, 0.2)' }],
  [TransactionStatus.COMPLETED, { label: 'Completed', color: '#4CAF50', backgroundColor: 'rgba(111, 207, 151, 0.2)' }],
  [TransactionStatus.END, { label: 'End', color: '#ED1B2E', backgroundColor: '#FFFFFF' }],
  [TransactionStatus.REJECTED, { label: 'Rejected', color: '#ED1B2E', backgroundColor: '#FFFFFF' }],
  [TransactionStatus.CALLING, { label: 'Calling', color: '#ED1B2E', backgroundColor: '#FFFFFF' }]
])

export type TransactionStatusLabel = {
  label: string
  color: string
  backgroundColor: string
}

export const TransactionStatusLabel = (status: TransactionStatus): TransactionStatusLabel =>
  mapTransactionStatus.get(status) ?? {
    label: '-',
    color: 'black',
    backgroundColor: 'white'
  }
