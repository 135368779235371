import { View, Text, StyleSheet, TextStyle, ViewStyle, StyleProp } from 'react-native'
import React from 'react'
import { useForm, Controller, UseFormReturn } from 'react-hook-form'
import {
  SelectOption,
  Input,
  DatePicker,
  Select,
  ImgUploadMutiple,
  SelectSearch,
  assets,
  Checkbox,
  RadioButtonGroup,
  ImgUploadMutiplePC,
  replaceSpecialCharactersInFileName
} from '@pulseops/common'
import { Error, FieldText } from '@pulseops/submission/common'
import CKEditor from 'ckeditor4-react'
import { TypeInputComponent, InputType } from './index'
import { isNull, isNil } from 'lodash'
import './style.css'
import { FileUpload } from '../ib-client/ib-click-to-send-email'
interface FieldInput {
  formName: string
  type: TypeInputComponent.INPUT
  title: string
  inputType: string
  maxLength?: number
  rules?: Object
  multiline?: boolean
  numberOfLines?: number
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  handleData?: (value: string | null) => void
  suffix?: string
  placeHolder?: string
  alwayShowUnderline?: boolean
  errorMessage?: string
  width?: string | number
  inputStyle?: StyleProp<ViewStyle & TextStyle>
}

interface FieldCombined {
  formName: string
  type: TypeInputComponent.COMBINED
  title: string
  inputType: string
  maxLength?: number
  rules?: Object
  multiline?: boolean
  numberOfLines?: number
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  handleData?: (value: string | null) => void
  suffix?: string
  placeHolder?: string
  alwayShowUnderline?: boolean
  errorMessage?: string
  formName2: string
  widthField1?: string | number
  widthField2?: string | number
}

interface FieldSelect {
  formName: string
  type: TypeInputComponent.SELECT
  title: string
  option: SelectOption[]
  rules?: Object
  required?: boolean
  handleData?: (value: SelectOption | null) => void
  hidden?: boolean
  disabled?: boolean
  placeholder?: string
  multiple?: boolean
  onClear?: (value: SelectOption | null) => void
  labelStyle?: TextStyle
}

interface FieldDate {
  formName: string
  type: TypeInputComponent.DATE
  required?: boolean
  title: string
  maxDate?: Date
  maxDateMessage?: string
  minDate?: Date
  minDateMessage?: string
  rules?: Object
  placeHolder?: string
  disabled?: boolean
}

interface FieldImport {
  formName: string
  type: TypeInputComponent.IMPORT
  rules?: Object
  title: string
  required?: boolean
  width?: string | number
  maxSizeFile?: number
  maxTotalFile?: number
  messageFormat?: string
  validExtensions?: Array<string>
  messageMaxSize?: string
  takeAPicture?: boolean
  showFullFileName?: boolean
  isFileDownload?: boolean
  disabledDelete?: boolean
  formatFileNameVi?: boolean
  maxNumFile?: number
  messageMaxTotalSize?: string
  messageValidExtensions?: string
}
interface FieldCheckBox {
  formName: string
  type: TypeInputComponent.CHECKBOX
  title: string
  rules?: Object
  disabled?: boolean
  handleData?: (value: boolean | null) => void
  textStyle?: TextStyle
  checkBoxStyle?: StyleProp<ViewStyle>
}

interface FieldRadioGroup {
  formName: string
  type: TypeInputComponent.RADIOGROUP
  title: string
  disabled?: boolean
  option: { id: string; label: string }[]
  rules?: Object
  handleData?: (value: string | null) => void
  required?: boolean
}

interface FieldCKEditor {
  formName: string
  type: TypeInputComponent.CKEDITOR
  handleData?: (value: string | null) => void
  config?: Object
  rules?: Object
  hidden?: boolean
  title: string
  required?: boolean
  handleUploadFiles?: (file: FileUpload, blobUrl: string) => void
}

interface Props {
  typeInput: ((
    | FieldInput
    | FieldSelect
    | FieldDate
    | FieldImport
    | FieldCheckBox
    | FieldRadioGroup
    | FieldCombined
    | FieldCKEditor
  ) & {
    expandRow?: number
  })[]
  FieldForm: any
  col: number
}

export const IBGeneralField = (props: Props) => {
  const { typeInput, FieldForm, col } = props
  return (
    <View style={styles.container}>
      {typeInput.map((type) => {
        switch (type.type) {
          case TypeInputComponent.INPUT: {
            if (type.hidden) {
              return <></>
            }
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width:
                            type?.width ??
                            `${type?.expandRow ? Math.floor(95 / (col / type?.expandRow)) : Math.floor(95 / col)}%`
                        }
                      ]}
                    >
                      <Input
                        required={type.required}
                        title={type.title}
                        value={field.value as string | undefined}
                        maxLength={type.maxLength}
                        inputType={type.inputType as InputType}
                        onChange={(value) => {
                          field.onChange(value)
                          if (type.handleData && value) {
                            type.handleData(value)
                          }
                        }}
                        errorMessage={fieldState.error?.message || type?.errorMessage}
                        multiline={type.multiline}
                        numberOfLines={type.numberOfLines}
                        disabled={type.disabled}
                        suffix={type.suffix}
                        placeholder={type.placeHolder}
                        alwayShowUnderline={type.alwayShowUnderline}
                        inputStyle={type.inputStyle}
                      />
                    </View>
                  )
                }}
              />
            )
          }
          case TypeInputComponent.DATE:
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width: `${type?.expandRow ? Math.floor(95 / (col / type?.expandRow)) : Math.floor(95 / col)}%`
                        }
                      ]}
                    >
                      <DatePicker
                        required={type.required}
                        label={type.title}
                        onChange={field.onChange}
                        value={field.value as Date | undefined | null}
                        maxDate={type.maxDate}
                        maxDateMessage={type.maxDateMessage}
                        minDate={type.minDate}
                        minDateMessage={type.minDateMessage}
                        errorMessage={fieldState.error?.message}
                        alwaysShow={!type.required}
                        placeHolder={type.placeHolder}
                        disabled={type.disabled}
                        labelStyle={{ marginBottom: 2 }}
                      />
                    </View>
                  )
                }}
              />
            )
          case TypeInputComponent.SELECT: {
            if (type.hidden) {
              return <></>
            }
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width: `${type?.expandRow ? Math.floor(95 / (col / type?.expandRow)) : Math.floor(95 / col)}%`
                        }
                      ]}
                    >
                      <SelectSearch
                        required={type.required}
                        label={type.title}
                        labelStyle={type.labelStyle}
                        options={type.option as []}
                        onChange={(value) => {
                          field.onChange(value)
                          if (type.handleData && value) {
                            type.handleData(value)
                          }
                          if (isNull(value) && type.onClear) {
                            type.onClear(value)
                          }
                        }}
                        value={field.value}
                        errorMessage={fieldState.error?.message}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        disabled={type.disabled}
                        placeholder={type.placeholder}
                        multiple={type.multiple as boolean}
                      />
                    </View>
                  )
                }}
              />
            )
          }

          case TypeInputComponent.IMPORT:
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width: type.width
                            ? type.width
                            : `${type?.expandRow ? Math.floor(95 / (col / type?.expandRow)) : Math.floor(95 / col)}%`
                        }
                      ]}
                    >
                      <FieldText text={type.title} isRequired={type.required}></FieldText>
                      <ImgUploadMutiplePC
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        timeFormat={'DD/MM/YYYY HH:mm'}
                        maxSizeFile={type.maxSizeFile}
                        maxTotalFile={type.maxTotalFile}
                        messageFormat={type.messageFormat}
                        messageMaxSize={type.messageMaxSize}
                        maxNumFile={type.maxNumFile}
                        takeAPicture={type.takeAPicture}
                        showFullFileName={type.showFullFileName}
                        isFileDownload={type.isFileDownload}
                        disabledDelete={type.disabledDelete}
                        validExtensions={type.validExtensions}
                        formatFileNameVi={type.formatFileNameVi}
                        messageMaxTotalSize={type.messageMaxTotalSize}
                        messageValidExtensions={type.messageValidExtensions}
                      />
                      {fieldState.error?.message && <Error message={fieldState.error.message} />}
                    </View>
                  )
                }}
              />
            )
          case TypeInputComponent.CHECKBOX:
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width: `${type?.expandRow ? Math.floor(94 / (col / type?.expandRow)) : Math.floor(94 / col)}%`
                        }
                      ]}
                    >
                      <Checkbox
                        title={type.title}
                        value={field.value}
                        onBlur={field.onBlur}
                        onChange={(value) => {
                          field.onChange(value)
                          if (value && type.handleData) {
                            type.handleData(value)
                          }
                        }}
                        disabled={type.disabled}
                        textStyle={type.textStyle}
                        checkBoxStyle={type.checkBoxStyle}
                      />
                    </View>
                  )
                }}
              />
            )
          case TypeInputComponent.RADIOGROUP:
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width: `${type?.expandRow ? Math.floor(95 / (col / type?.expandRow)) : Math.floor(95 / col)}%`
                        }
                      ]}
                    >
                      <RadioButtonGroup
                        colLength={'100%'}
                        title={type.title}
                        required={type.required}
                        value={field.value}
                        options={type.option as []}
                        onChange={(e) => {
                          field.onChange(e)
                          if (e && type.handleData) {
                            type.handleData(e)
                          }
                        }}
                        onBlur={field.onBlur}
                        errorMessage={fieldState.error?.message}
                      />
                    </View>
                  )
                }}
              />
            )
          case TypeInputComponent.COMBINED: {
            if (type.hidden) {
              return <></>
            }
            return (
              <View style={[styles.col]}>
                <View style={[styles.row, styles.mB5]}>
                  <Text style={[styles.mR5, styles.label]}>{type.title}</Text>
                  {type.required && <Text style={{ color: '#ed1b2e' }}>*</Text>}
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: type.widthField1 }}>
                    <Controller
                      key={type.formName}
                      name={type.formName}
                      control={FieldForm.control}
                      rules={type.rules}
                      render={(controller) => {
                        const { field, fieldState } = controller
                        return (
                          <View style={[styles.inputStyle]}>
                            <Input
                              required={type.required}
                              value={field.value as string | undefined}
                              maxLength={type.maxLength}
                              inputType={type.inputType as InputType}
                              onChange={(value) => {
                                field.onChange(value)
                                if (type.handleData && value) {
                                  type.handleData(value)
                                }
                              }}
                              errorMessage={fieldState.error?.message || type?.errorMessage}
                              multiline={type.multiline}
                              numberOfLines={type.numberOfLines}
                              disabled={type.disabled}
                              suffix={type.suffix}
                              placeholder={type.placeHolder}
                              alwayShowUnderline={type.alwayShowUnderline}
                            />
                          </View>
                        )
                      }}
                    />
                  </View>
                  <View style={{ width: type.widthField2 }}>
                    <Controller
                      key={type.formName2}
                      name={type.formName2}
                      control={FieldForm.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          onChange={(e) => {
                            onChange(e)
                          }}
                          onBlur={onBlur}
                          value={value}
                          errorMessage={error?.message}
                          disabled
                          maxLength={120}
                        />
                      )}
                    />
                  </View>
                </View>
              </View>
            )
          }
          case TypeInputComponent.CKEDITOR: {
            if (type.hidden) {
              return <></>
            }
            return (
              <Controller
                key={type.formName}
                name={type.formName}
                control={FieldForm.control}
                rules={type.rules}
                render={(controller) => {
                  const { field, fieldState } = controller
                  return (
                    <View
                      style={[
                        styles.inputStyle,
                        {
                          width: `${type?.expandRow ? Math.floor(95 / (col / type?.expandRow)) : Math.floor(95 / col)}%`
                        }
                      ]}
                    >
                      <FieldText text={type.title} isRequired={type.required}></FieldText>
                      <CKEditor
                        onChange={(event) => {
                          field.onChange(event.editor.getData())
                          if (type.handleData && event.editor.getData()) {
                            type.handleData(event.editor.getData())
                          }
                        }}
                        config={type.config}
                        data={field.value}
                        onBeforeLoad={(ckeditor) => {
                          if (!ckeditor.plugins.get('image3')) {
                            ckeditor.plugins.add('image3', {
                              icons: 'base64image.png',
                              init: (editor: any) => {
                                editor.addCommand('insertImage3', {
                                  exec: (editor: any) => {
                                    const fileInput = document.getElementById('file')
                                    fileInput?.click()
                                    const handleFiles = (e: any) => {
                                      const files = e.target.files[0]
                                      if (files) {
                                        if (type.handleUploadFiles) {
                                          const reader = new FileReader()
                                          const oldExtension = files.name
                                            ? files.name.slice(files.name.lastIndexOf('.'))
                                            : ''
                                          const fileName = files.name
                                            ? files.name.slice(0, files.name.lastIndexOf('.'))
                                            : ''
                                          const filterNewName = replaceSpecialCharactersInFileName(fileName).replace(
                                            /_/g,
                                            ''
                                          )
                                          const fullFilterName = filterNewName + oldExtension
                                          const newFile = new File([files], fullFilterName, { type: files.type })
                                          const fileExt = newFile.name.split('.').pop() ?? ''
                                          reader.readAsDataURL(newFile)
                                          const blob = new Blob([files], { type: files.type })
                                          const urlImg = window.URL.createObjectURL(blob)
                                          type.handleUploadFiles(
                                            {
                                              fileName: newFile.name.split('.')[0],
                                              fileExtension: fileExt,
                                              size: newFile.size,
                                              base64: reader.result as string,
                                              uploadedDate: new Date(),
                                              file: newFile
                                            },
                                            urlImg
                                          )
                                          editor.insertHtml(`<img alt="{${newFile.name}}" src=${urlImg}></img`)
                                        }
                                        fileInput.value = ''
                                      }
                                    }
                                    fileInput?.addEventListener('change', handleFiles)
                                  }
                                })
                                editor.ui.addButton('image3', {
                                  label: 'Insert image3',
                                  command: 'insertImage3',
                                  toolbar: 'insert',
                                  className: 'my_plugin_btn'
                                })
                              }
                            })
                          }
                        }}
                      />
                      {fieldState.error?.message && <Error message={fieldState.error.message} />}
                      <input type="file" id="file" style={{ opacity: 0 }} accept="image/*"></input>
                    </View>
                  )
                }}
              />
            )
          }
          default:
            return <></>
        }
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  inputStyle: {
    marginBottom: 20,
    marginRight: 15
  },
  label: {
    fontSize: 15,
    lineHeight: 20,
    color: '#70777e',
    fontWeight: 'bold'
  },
  mR5: {
    marginRight: 5
  },
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '32.5%'
  },
  mB5: {
    marginBottom: 5
  }
})
