import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.RIDER_REINSTATEMENT)
    }),
    codec
  ])

  const RiderInfo = t.type({
    coverageCode: t.string,
    riskCommDate: t.string,
    lapsedDate: t.string,
    sumAssured: t.number,
    riderPremium: t.number,
  })

const payloadPulse4Ops = t.type({
  body: t.type({
    reinsFee: t.number,
    lifeAssuredList: t.array(
      t.type({
        clientNo: t.string,
        agentCode: t.string,
        healthStateChange: t.boolean,
        occupationChange: t.boolean,
        curOccupation: t.string,
        newOccupation: t.string,
        activityChange: t.boolean,
        newActivity: t.string,
        companyChange: t.boolean,
        newCompany: t.string,
        riderList: t.array(RiderInfo)
      })
    )
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const PulseOps = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: payloadPulse4Ops
  })
)

export const RiderReinstatement = t.union([Pulse4Ops, PulseOps])
export type RiderReinstatement = t.TypeOf<typeof RiderReinstatement>

