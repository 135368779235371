import { TaskSource } from './TaskSource'

interface SelectOption {
  label: string
  value: string
}

export const SourceList: SelectOption[] = [
  { label: 'Banca', value: 'BANCA' },
  { label: 'Banca-VIB', value: 'BANCA_VIB' },
  { label: 'Banca-SEA', value: 'BANCA_SEA' },
  { label: 'eKiosk', value: 'EKIOSK' },
  { label: 'PRUonline', value: 'PRUONLINE' },
  { label: 'Pulse Service', value: 'PULSE' },
  { label: 'PulseOps (eForm)', value: 'PULSE4OPS' },
  { label: 'Zalo', value: 'ZALO' },
  { label: 'Online payment', value: 'ONLINEPAYMENT' },
  { label: 'Landing Page', value: 'LANDING_PAGE' },
  { label: 'Ebanking Seabank', value: 'EBANKING_SEABANK' },
  { label: 'Momo', value: 'MOMO' },
  { label: 'My VIB', value: 'MY_VIB' },
  { label: 'Payoo', value: 'PAYOO' },
  { label: 'Pos Seabank', value: 'POS_SEABANK' },
  { label: 'Agribank VBA', value: 'AGRIBANK_VBA' },
  { label: 'Viettel', value: 'VIETTEL' },
  { label: 'VNPost', value: 'VNPOST' },
  { label: 'MSB Bank', value: 'MSB_BANK' },
  { label: 'Offline', value: 'OFFLINE' },
  { label: 'Sacombank', value: 'SACOMBANK' },
  { label: 'Credit Life Portal', value: 'CREDITLIFEPORTAL' }
]

export const sourceDAOptions: SelectOption[] = [
  { label: 'PruDaily', value: TaskSource.PRUDAILY },
  { label: 'PruForce', value: TaskSource.PRUFORCE }
]

export const sourceIDCOptions: SelectOption[] = [
  { label: 'PruDaily', value: TaskSource.PRUDAILY },
]
