import randomBytes from 'randombytes'
import { pipe } from 'fp-ts/function'
import { Metadata } from '@azure/storage-blob'
import * as O from 'fp-ts/Option'
import { Task, Throwable, ZIO } from '@mxt/zio'
import { StorageBlobApi } from './StorageBlobApi'
import { HttpClient } from '@mxt/zio-http-client'

export namespace StorageBlob {
  // export interface MetaDataRaw extends Metadata {
  export interface MetaDataRaw {
    type: string
    doctype: string
    class: string
    docid: string
    maindoc: string
    subdoc: string
  }

  export interface MetaDataUpload extends MetaDataRaw {
    polnum: string
    batchno: string
    propnum?: string
    trigger?: string
    //doctypeBPM?: string
    agentnum?: string
    functionType?: string
    doctypebpm?: string
  }

  export interface FileContent {
    file: File | Blob
    metaData?: MetaDataUpload
  }

  export interface Document {
    name: string
    url: string
  }

  export interface FileContentSubmit {
    name: string
    url: string
    id?: string
    fileName?: string
  }

  export interface CashOutSubmitFile {
    storageFiles: FileContentSubmit[]
    docTypeCode: string
  }

  export type Documents = Document[]

  function getOriginMetaData(metaData?: MetaDataUpload) {
    let originmetaData: Metadata = {}
    const functionType = metaData?.functionType ?? ''
    let prefix = 'x-ms-meta-'
    prefix.concat('type')
    switch (functionType) {
      case 'uploadDocument':
        {
          if (!!metaData?.polnum || !!metaData?.propnum) {
            originmetaData = {
              'x-ms-meta-type': metaData.type,
              'x-ms-meta-doctype': metaData.doctype,
              'x-ms-meta-class': metaData.class,
              'x-ms-meta-docid': metaData.docid,
              'x-ms-meta-maindoc': metaData.maindoc,
              'x-ms-meta-subdoc': metaData.subdoc,
              'x-ms-meta-polnum': metaData.polnum,
              'x-ms-meta-batchno': metaData.batchno,
              'x-ms-meta-propnum': metaData.propnum ?? ''
            }
          } else {
            originmetaData = {
              'x-ms-meta-type': metaData?.type ?? '',
              'x-ms-meta-doctype': metaData?.doctype ?? '',
              'x-ms-meta-class': metaData?.class ?? '',
              'x-ms-meta-docid': metaData?.docid ?? '',
              'x-ms-meta-maindoc': metaData?.maindoc ?? '',
              'x-ms-meta-subdoc': metaData?.subdoc ?? '',
              'x-ms-meta-agentnum': metaData?.agentnum ?? '',
              'x-ms-meta-batchno': metaData?.batchno ?? ''
            }
          }
          if (metaData?.class === 'POLICYINFOTMP') {
            originmetaData = {
              ...originmetaData,
              'x-ms-meta-trigger': metaData.trigger ?? '',
              'x-ms-meta-doctypebpm': metaData.doctypebpm ?? ''
            }
          }
        }
        break
      default: {
        originmetaData = {
          'x-ms-meta-type': metaData?.type ?? '',
          'x-ms-meta-doctype': metaData?.doctype ?? '',
          'x-ms-meta-class': metaData?.class ?? '',
          'x-ms-meta-docid': metaData?.docid ?? '',
          'x-ms-meta-maindoc': metaData?.maindoc ?? '',
          'x-ms-meta-subdoc': metaData?.subdoc ?? '',
          'x-ms-meta-polnum': metaData?.polnum ?? '',
          'x-ms-meta-batchno': metaData?.batchno ?? ''
        }
      }
    }
    return originmetaData
  }

  const getConfig = StorageBlobApi.getConfig
  const getConfigLP = StorageBlobApi.getConfigLP
  const getRefConfig = StorageBlobApi.getRefConfig

  const newId = pipe(
    ZIO.effectTotal(() => randomBytes(11).toString('base64').slice(0, 11)),
    ZIO.map((str) => str.replace(/\+/g, '-').replace(/\//g, '_'))
  )

  const containerClient = (fileName: string, transactionMetaData?: MetaDataUpload, fileType?: string) =>
    pipe(
      getRefConfig,
      ZIO.map(({ blobServiceClient, containerName, sas, url, authorization, authenVendor }) => {
        const blobClientItem = StorageBlobApi.getBlobServiceClient(
          containerName,
          sas,
          url,
          fileName,
          authorization,
          authenVendor,
          transactionMetaData,
          fileType
        )
        return blobClientItem.getContainerClient('')
      })
    )

  export const upload = (
    file: File | Blob,
    folderName: string,
    containerName: string,
    sasToken: string,
    url: string,
    authorization: string,
    authenVendor: string,
    metaData?: Metadata,
    transactionMetaData?: MetaDataUpload
  ) =>
    pipe(
      newId,
      ZIO.map((id) => `${id}_${file instanceof File ? file.name : `${file.size}.${file.type.split('/')[1]}`}`),
      ZIO.tap((blobName) => {
        const fullFileName = folderName + '/' + blobName
        return pipe(
          containerClient(fullFileName, transactionMetaData, file.type),
          ZIO.flatMap((_containerClient) => {
            const blockBlobClient = _containerClient.getBlockBlobClient('')
            return pipe(
              ZIO.fromPromise(() => blockBlobClient.uploadData(file))
              // ZIO.flatMap(() =>
              //   ZIO.fromPromise(() => {
              //     return Promise.all([
              //       // blockBlobClient.setMetadata(metaData),
              //       // blockBlobClient.setHTTPHeaders({ blobContentType: file.type }),
              //       setMetadataForStorageBlob(
              //         containerName,
              //         sasToken,
              //         url,
              //         fullFileName,
              //         authorization,
              //         authenVendor,
              //         metaData
              //       ),
              //       setPropertyForStorageBlob(
              //         containerName,
              //         sasToken,
              //         url,
              //         fullFileName,
              //         authorization,
              //         authenVendor,
              //         file
              //       )
              //     ])
              //   })
              // )
            )
          })
        )
      })
    )

  export const setMetadataForStorageBlob = (
    containerName: string,
    sasToken: string,
    url: string,
    blobName: string,
    authorization: string,
    authenVendor: string,
    metadata?: Metadata
  ) =>
    pipe(
      ZIO.effect(() => {
        return {
          containerName,
          sasToken,
          url,
          blobName,
          authorization,
          authenVendor
        }
      }),
      ZIO.flatMap((configInfo) => {
        return HttpClient.putAxios({
          headers: {
            Container: `${configInfo.containerName}`,
            Token: `${configInfo.sasToken}`,
            Blob: `${configInfo.blobName}`,
            Comp: 'metadata',
            'X-Authen-Vendor': `${configInfo.authenVendor}`,
            authorization: `Bearer ${configInfo.authorization}`,
            ...metadata
          }
        })(url + '/')()
      }),
      ZIO.unsafeRun({})
    )

  export const setPropertyForStorageBlob = (
    containerName: string,
    sasToken: string,
    url: string,
    blobName: string,
    authorization: string,
    authenVendor: string,
    file: File | Blob
  ) =>
    pipe(
      ZIO.effect(() => {
        return {
          containerName,
          sasToken,
          url,
          blobName,
          authorization,
          authenVendor
        }
      }),
      ZIO.flatMap((configInfo) =>
        HttpClient.putAxios({
          headers: {
            Container: `${configInfo.containerName}`,
            Token: `${configInfo.sasToken}`,
            Blob: `${configInfo.blobName}`,
            Comp: 'properties',
            'x-ms-blob-content-type': file.type,
            'X-Authen-Vendor': `${configInfo.authenVendor}`,
            authorization: `Bearer ${configInfo.authorization}`
          }
        })(url + '/')()
      ),
      ZIO.unsafeRun({})
    )

  export const uploads = (
    files: FileContent[],
    folderName: string,
    containerName: string,
    sasToken: string,
    url: string,
    authorization: string,
    authenVendor: string
  ) =>
    pipe(
      ZIO.sequence(
        files.map(({ file, metaData }) => {
          return upload(
            file,
            folderName,
            containerName,
            sasToken,
            url,
            authorization,
            authenVendor,
            getOriginMetaData(metaData),
            metaData
          )
        })
      )
    )

  export const uploadToSubmit =
    (category: string, corelationId: string, accessToken?: string, transactionCustomUrl?: string) =>
    (files: FileContent[]): Task<FileContentSubmit[]> => {
      return pipe(
        files.length > 0,
        O.fromNullable,
        O.fold(
          () => ZIO.succeed([] as FileContentSubmit[]),
          () =>
            pipe(
              accessToken
                ? getConfigLP(category, corelationId, accessToken)
                : getConfig(category, corelationId, transactionCustomUrl),
              ZIO.flatMap(({ folderName, getUrl, containerName, sas, url, authorization, authenVendor }) => {
                return pipe(
                  uploads(files, folderName, containerName, sas, url, authorization, authenVendor),
                  ZIO.map((_fileNames) =>
                    _fileNames.map((name) => ({ name: folderName + '/' + name, url: getUrl(name) }))
                  ),
                  ZIO.mapError((error) => {
                    return Throwable('StorageBlob: Upload fail')
                  })
                  // ZIO.mapError((_) => Throwable(`StorageBlob Upload fail: ${JSON.stringify(_, null, 2)}`))
                )
              })
            )
        )
      )
    }

  export const uploadSEA = (
    file: File | Blob,
    folderName: string,
    containerName: string,
    sasToken: string,
    url: string,
    authorization: string,
    authenVendor: string,
    metaData?: Metadata,
    transactionMetaData?: MetaDataUpload
  ) =>
    pipe(
      newId,
      ZIO.map((id) => `${id}_${file instanceof File ? file.name : `${file.size}.${file.type.split('/')[1]}`}`),
      ZIO.tap((blobName) => {
        const fullFileName = folderName + '/' + blobName.replace('-', '')
        return pipe(
          containerClient(fullFileName, transactionMetaData, file.type),
          ZIO.flatMap((_containerClient) => {
            const blockBlobClient = _containerClient.getBlockBlobClient('')
            return pipe(ZIO.fromPromise(() => blockBlobClient.uploadData(file)))
          })
        )
      })
    )

  export const uploadsSEA = (
    files: FileContent[],
    folderName: string,
    containerName: string,
    sasToken: string,
    url: string,
    authorization: string,
    authenVendor: string
  ) =>
    pipe(
      ZIO.sequence(
        files.map(({ file, metaData }) => {
          return uploadSEA(
            file,
            folderName,
            containerName,
            sasToken,
            url,
            authorization,
            authenVendor,
            getOriginMetaData(metaData),
            metaData
          )
        })
      )
    )

  export const uploadToSubmitSEA =
    (category: string, corelationId: string, accessToken?: string, transactionCustomUrl?: string) =>
    (files: FileContent[]): Task<FileContentSubmit[]> => {
      return pipe(
        files.length > 0,
        O.fromNullable,
        O.fold(
          () =>
            ZIO.effect(() => {
              return [] as FileContentSubmit[]
            }),
          () =>
            pipe(
              accessToken
                ? getConfigLP(category, corelationId, accessToken)
                : getConfig(category, corelationId, transactionCustomUrl),
              ZIO.flatMap(({ folderName, getUrl, containerName, sas, url, authorization, authenVendor }) => {
                return pipe(
                  uploadsSEA(files, folderName, containerName, sas, url, authorization, authenVendor),
                  ZIO.map((_fileNames) =>
                    _fileNames.map((name) => {
                      const fileItem = files.find((item) => name.includes((item.file as File).name))
                      const fileName = (fileItem?.file as File).name.toString()
                      return {
                        name: folderName + '/' + name.replace('-', ''),
                        url: getUrl(name),
                        filename: fileName
                      }
                    })
                  ),
                  ZIO.mapError((error) => {
                    return Throwable('StorageBlob: Upload fail')
                  })
                )
              })
            )
        )
      )
    }
}
