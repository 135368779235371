import React from 'react'
import { Pressable, ScrollView, StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'

export type MessageInfo = {
  messageText: string | string[]
  textStyle?: StyleProp<TextStyle>
  visible?: boolean
  onClickTextEvent?: () => void
  icon?: JSX.Element
  messageTitle?: string
}

type OBHeaderMessageProps = {
  messageList: Array<MessageInfo>
}
export const OBCallOutHeaderMessage = (props: OBHeaderMessageProps) => {
  return (
    <ScrollView showsHorizontalScrollIndicator={false}>
      <View style={[headerStyles.messageContainer, { height: props.messageList.some((x) => x.visible) ? 80 : 0 }]}>
        {props.messageList &&
          props.messageList.map((messageItem, index) =>
            messageItem.onClickTextEvent && messageItem.visible ? (
              <Pressable onPress={() => messageItem.onClickTextEvent && messageItem.onClickTextEvent()}>
                <View style={headerStyles.messageContent}>
                  <View style={headerStyles.iconContainer}>{messageItem.icon}</View>
                  <Text key={index} style={[headerStyles.messsageContainerText, messageItem.textStyle]}>
                    {messageItem.messageText}
                  </Text>
                </View>
              </Pressable>
            ) : messageItem.visible ? (
              <View style={[headerStyles.messageContent, headerStyles.secondLine]}>
                {messageItem.icon && <View style={headerStyles.iconContainer}>{messageItem.icon}</View>}
                <Text style={headerStyles.messageTitle}>{`${messageItem.messageTitle}:`}</Text>
                {typeof messageItem.messageText === 'string' ? (
                  <Text key={index} style={[headerStyles.messsageContainerText]}>
                    {messageItem.messageText}
                  </Text>
                ) : (
                  <View style={[headerStyles.messageTextArray, { width: '90%' }]}>
                    {messageItem.messageText.map((text) => (
                      <Text key={index} style={[headerStyles.messsageContainerText]}>
                        {text}
                      </Text>
                    ))}
                  </View>
                )}
              </View>
            ) : (
              <View></View>
            )
          )}
      </View>
    </ScrollView>
  )
}
const headerStyles = StyleSheet.create({
  messageContainer: {
    marginHorizontal: 24,
    marginVertical: 16
  },
  messsageContainerText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 21,
    marginLeft: 10
  },
  messageTitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 21,
    marginLeft: 10
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'row'
  },
  secondLine: {
    marginTop: 10
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#F05A67'
  },
  messageTextArray: {
    flexDirection: 'column'
  }
})
