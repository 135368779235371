import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { RequestRelatedCashlessCollectionStackParamList } from './RequestRelatedCashlessCollectionStackParamList'
import { RequestRelatedCashlessCollection } from './RequestRelatedCashlessCollection'
import { WriteOffSubmissionCommon } from './WriteOffSubmissionCommon'
const Stack = createStackNavigator<RequestRelatedCashlessCollectionStackParamList>()

export const RequestRelatedcashlessCollectionStack = () => {
  return (
    <Stack.Navigator initialRouteName="RequestRelatedCashlessCollectionScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="RequestRelatedCashlessCollectionScreen" component={RequestRelatedCashlessCollection} />
      <Stack.Screen name="WriteOffSubmissionCommonScreen" component={WriteOffSubmissionCommon} />
    </Stack.Navigator>
  )
}
