import {
  View,
  Text,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Image,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import React from 'react'
import {
  ErrorHandling,
  useMobile,
  FileUploadData,
  replaceSpecialCharactersInFileName,
  AppContext
} from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { LandingPageCCCDStackParamList } from './LandingPageCCCDStackParamList'
import { SC, Services } from './core'
import { Header } from './Header'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import _ from 'lodash'
import { ZIO } from '@mxt/zio'

type Props = StackScreenProps<LandingPageCCCDStackParamList, 'ChangeNationalIdCardScreen'>

export const UploadCCCD = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation('landingPage')
  const inputRef = React.createRef<HTMLInputElement>()
  const [spinning, setSpinning] = React.useState<boolean>(false)
  const [cccd, setCCCD] = React.useState<{ attachment_before?: FileUploadData; attachment_after?: FileUploadData }>()
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const checkUpload = (files: FileUploadData[]) => {
    setCCCD({ ...cccd, [files[0].fileName]: files[0] })
  }

  const handleUpload = (files: FileList, name: string) => {
    const fileList: FileUploadData[] = []
    const fileLength = files.length
    for (let i = 0; i < files.length; i++) {
      const fileItem = files[i]
      const reader = new FileReader()
      const oldExtension = fileItem.name ? fileItem.name.slice(fileItem.name.lastIndexOf('.')) : ''
      const fileName = fileItem.name ? fileItem.name.slice(0, fileItem.name.lastIndexOf('.')) : ''
      //   filter file name and replace special characters
      const filterNewName = replaceSpecialCharactersInFileName(fileName)
      const fullFilterName = filterNewName + oldExtension
      const newFile = new File([fileItem], fullFilterName, { type: fileItem.type })
      const fileExt = newFile.name.split('.').pop() ?? ''
      reader.readAsDataURL(newFile)
      reader.onloadend = () => {
        fileList.push({
          fileName: name,
          fileExtension: fileExt,
          size: newFile.size,
          base64: reader.result as string,
          uploadedDate: new Date(),
          file: newFile
        })
        i === fileLength - 1 && fileList.length > 0 && checkUpload(fileList)
      }
    }
  }

  const handleSave = () => {
    if (cccd?.attachment_before && cccd?.attachment_after) {
      setSpinning(true)
      pipe(
        Services.identifycard(cccd?.attachment_before?.file),
        ZIO.map((data) => {
          setSpinning(false)
          if (data[0] && data[0].id) {
            props.navigation.navigate('IdentifycardScreen', {
              ...props.route.params,
              ...data[0],
              files: [cccd.attachment_after, cccd.attachment_before] as FileUploadData[]
            })
          } else {
            showToast(t('WarningPleaseCheckPicture'), 'error', {
              horizontal: 'center',
              vertical: 'top'
            })
          }
        }),
        ZIO.catchAll((error) => {
          setSpinning(false)
          showToast(t('WarningPleaseCheckPicture'), 'error', {
            horizontal: 'center',
            vertical: 'top'
          })
          return ZIO.unit
        }),
        ErrorHandling.run()
      )
    } else {
      showToast(t('WarningPleaseFill'), 'error', {
        horizontal: 'center',
        vertical: 'top'
      })
    }
  }
  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#FFFFFF' }}>
      <Header isWide={isWide} t={t} />
      <ScrollView>
        <SC.ContainerPolicyList maxWidth="566" isWide={isWide}>
          <View style={styles.form}>
            <SC.Form.Group marginBottom="0">
              <SC.Form.Row marginBottom="10" isMobile={isMobile}>
                <SC.NoteText>{t('WarningUploadCCCD')}</SC.NoteText>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <SC.BorderCCCD>
                  <Image
                    style={{
                      width: cccd?.attachment_before ? '100%' : 70,
                      height: cccd?.attachment_before ? '100%' : 45,
                      marginBottom: cccd?.attachment_before ? 0 : 10
                    }}
                    source={
                      cccd?.attachment_before
                        ? { uri: cccd?.attachment_before?.base64 }
                        : require('./core/assets/cccd_before.png')
                    }
                  />
                  {cccd?.attachment_before ? <></> : <SC.NoteText>Mặt trước CCCD</SC.NoteText>}
                  {cccd?.attachment_before ? (
                    <></>
                  ) : (
                    <Text style={{ color: 'red', fontFamily: 'Noto Sans' }}>Tải ảnh lên</Text>
                  )}
                  <input
                    ref={inputRef}
                    //hidden
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      fontSize: 20,
                      cursor: 'pointer',
                      opacity: 0,
                      borderWidth: 1
                    }}
                    type="file"
                    multiple={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files) {
                        handleUpload(event.target.files, 'attachment_before')
                        event.target.files = null
                        event.target.value = ''
                      }
                    }}
                  />
                </SC.BorderCCCD>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <SC.BorderCCCD>
                  <Image
                    style={{
                      width: cccd?.attachment_after ? '100%' : 70,
                      height: cccd?.attachment_after ? '100%' : 45,
                      marginBottom: cccd?.attachment_after ? 0 : 10
                    }}
                    source={
                      cccd?.attachment_after
                        ? { uri: cccd?.attachment_after?.base64 }
                        : require('./core/assets/cccd_after.png')
                    }
                  />
                  {cccd?.attachment_after ? <></> : <SC.NoteText>Mặt sau CCCD</SC.NoteText>}
                  {cccd?.attachment_after ? (
                    <></>
                  ) : (
                    <Text style={{ color: 'red', fontFamily: 'Noto Sans' }}>Tải ảnh lên</Text>
                  )}
                  <input
                    ref={inputRef}
                    //hidden
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      fontSize: 20,
                      cursor: 'pointer',
                      opacity: 0,
                      borderWidth: 1
                    }}
                    type="file"
                    multiple={true}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files) {
                        handleUpload(event.target.files, 'attachment_after')
                        event.target.files = null
                        event.target.value = ''
                      }
                    }}
                  />
                </SC.BorderCCCD>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <TouchableOpacity style={styles.btnCancel} onPress={() => props.navigation.goBack()}>
                  <Text style={{ color: '#EE1A2D', fontFamily: 'Noto Sans' }}>{t('cancel')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnSave} onPress={handleSave} disabled={spinning}>
                  <Text style={{ color: 'white', fontFamily: 'Noto Sans' }}>
                    {spinning ? <ActivityIndicator size="small" animating={spinning} color="#FFFFFF" /> : t('save')}
                  </Text>
                </TouchableOpacity>
              </SC.Form.Row>
            </SC.Form.Group>
          </View>
        </SC.ContainerPolicyList>
      </ScrollView>
      <View style={styles.footer}>
        <SC.FooterText isWide={isWide}>{t('copy_right')}</SC.FooterText>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#000000'
  },
  btnCancel: {
    padding: 10,
    width: '46%',
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#EE1A2D',
    backgroundColor: 'white',
    marginRight: '4%'
  },
  btnSave: {
    padding: 10,
    width: '46%',
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#EE1A2D',
    backgroundColor: '#EE1A2D'
  }
})
