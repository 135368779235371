import * as React from 'react'
import { Modal, ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import {
  assets,
  useMobile,
  RoundedButton,
  Form,
  SelectSearch,
  CanView,
  TextInput,
  Input,
  SelectOption
} from '@pulseops/common'
import { FieldError, useForm } from 'react-hook-form'
import { AdminForm } from './AdminForm'
import { useTranslation } from 'react-i18next'
import { TypeTab } from './da-rule-item'

interface Props {
  selectedTab: TypeTab
  groupName: string
  categoryName: string
  visible: boolean
  onPressClose: () => void
  onPressSubmit: (data: AdminForm) => void
  onPressclean?: () => void
}

const OptionsChannel: SelectOption[] = [
  { label: 'mot', value: 'mot' },
  { label: 'hai', value: 'hai' },
  { label: 'ba', value: '3' }
]

export const AdminFormModal = ({
  selectedTab,
  groupName,
  categoryName,
  visible,
  onPressClose,
  onPressSubmit,
  onPressclean
}: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  const form = useForm<AdminForm>({
    defaultValues: {
      locationCode: '',
      officeCode: '',
      leaderOfficeCode: '',
      note: '',
      leaderCode: '',
      regionCode: '',
      regionType: '',
      docId: '',
      class: '',
      mainDoc: '',
      subDoc: '',
      channelsMapping: [],
      required: '',
      documentEn: '',
      documentVn: '',
      xCondition: '',
      suspendCode: ''
    }
  })

  const {
    reset,
    handleSubmit,
    formState: { errors }
  } = form

  const register = Form.register(form)
  const onClose = () => {
    reset()
    onPressClose && onPressClose()
  }
  const onSubmit = handleSubmit((data: AdminForm) => {
    onPressSubmit(data)
    onClose()
  })
  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          justifyContent: isWide ? 'center' : 'flex-end',
          alignItems: 'center'
        }}
      >
        {/* Backdrop handle touch outsidem modal event*/}
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onPressClose}
            style={{
              width: width,
              height: height,
              position: 'absolute'
            }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              height: height * 0.85,
              width: isWide ? 720 : '100%',
              borderBottomStartRadius: isWide ? 8 : 0,
              borderBottomEndRadius: isWide ? 8 : 0,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('claim:AddNew')}</Text>
              <View style={styles.breadcumbCotnainer}>
                <Text style={styles.breadcumbItem}>{groupName}</Text>
                <View style={styles.arrowIcon}>
                  <assets.ArrowRightTaskFilterModalIcon />
                </View>
                <Text style={styles.breadcumbItem}>{categoryName}</Text>
              </View>
            </View>
            <TouchableOpacity onPress={onPressClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          {/* content */}
          <View style={{ marginTop: 10, flex: 1, paddingHorizontal: 20 }}>
            <ScrollView style={{ paddingBottom: isWide ? 0 : 16 }}>
              {selectedTab === TypeTab.channel ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('docId', { required: `${t('message:MS020001', { field: 'DOCID' })}` })}
                      title={'DOCID'}
                      placeholder={''}
                      errorMessage={errors?.docId?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('class', { required: `${t('message:MS020001', { field: 'Class' })}` })}
                      title={'Class'}
                      placeholder={''}
                      errorMessage={errors?.class?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('mainDoc', { required: `${t('message:MS020001', { field: 'Main Doc' })}` })}
                      title={'Main Doc'}
                      placeholder={''}
                      errorMessage={errors?.mainDoc?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('subDoc', { required: `${t('message:MS020001', { field: 'Sub Doc' })}` })}
                      title={'Sub Doc'}
                      placeholder={''}
                      errorMessage={errors?.subDoc?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <SelectSearch
                      {...register('channelsMapping', {
                        required: `${t('message:MS020001', { field: 'Channels Mapping' })}`
                      })}
                      options={OptionsChannel.map((item) => ({ value: item.value, label: item.label }))}
                      label={'Channels Mapping'}
                      multiple
                      errorMessage={(errors?.channelsMapping as FieldError | undefined)?.message}
                      // searchOptions={ClaimService.searchDiagnostic(translator)}
                      // key={channelsMapping && channelsMapping.value}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('required', { required: `${t('message:MS020001', { field: 'Required' })}` })}
                      title={'Required'}
                      placeholder={''}
                      errorMessage={errors?.required?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('documentEn', { required: `${t('message:MS020001', { field: 'Document - EN' })}` })}
                      title={'Document - EN'}
                      placeholder={''}
                      errorMessage={errors?.documentEn?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('documentVn', { required: `${t('message:MS020001', { field: 'Document - VN' })}` })}
                      title={'Document - VN'}
                      placeholder={''}
                      errorMessage={errors?.documentVn?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('xCondition', { required: `${t('message:MS020001', { field: 'X Condition' })}` })}
                      title={'X Condition'}
                      placeholder={''}
                      errorMessage={errors?.required?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('suspendCode', { required: `${t('message:MS020001', { field: 'Suspend Code' })}` })}
                      title={'Suspend Code'}
                      placeholder={''}
                      errorMessage={errors?.suspendCode?.message}
                    />
                  </View>
                </>
              ) : (
                <>
                  {selectedTab === TypeTab.rule4 && (
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginTop: isWide ? 20 : 16
                      }}
                    >
                      <Input
                        {...register('leaderCode', {
                          required: `${t('message:MS020001', { field: 'Unit code' })}`,
                          validate: (value) => {
                            if (value !== undefined && value?.length !== 3) {
                              return `${t('RequestInfo:InvalidField', { field: 'Unit code' })}`
                            }
                            return true
                          }
                        })}
                        maxLength={3}
                        title={'Unit code'}
                        placeholder={''}
                        errorMessage={errors?.leaderCode?.message}
                      />
                    </View>
                  )}
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('locationCode', {
                        required: `${t('message:MS020001', { field: 'Location code' })}`,
                        validate: (value) => {
                          if (value !== undefined && value?.length !== 4) {
                            return `${t('RequestInfo:InvalidField', { field: 'Location code' })}`
                          }
                          return true
                        }
                      })}
                      title={'Location code'}
                      maxLength={4}
                      placeholder={''}
                      errorMessage={errors?.locationCode?.message}
                    />
                  </View>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: isWide ? 20 : 16
                    }}
                  >
                    <Input
                      {...register('officeCode', {
                        required: `${t('message:MS020001', { field: 'Office code' })}`,
                        validate: (value) => {
                          if (value !== undefined && value?.length !== 3) {
                            return `${t('RequestInfo:InvalidField', { field: 'Office code' })}`
                          }
                          return true
                        }
                      })}
                      title={'Office code'}
                      maxLength={3}
                      placeholder={''}
                      errorMessage={errors?.officeCode?.message}
                    />
                  </View>
                  {selectedTab === TypeTab.rule8 && (
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginTop: isWide ? 20 : 16
                      }}
                    >
                      <Input
                        {...register('leaderOfficeCode', {
                          required: `${t('message:MS020001', { field: 'Leader office code' })}`,
                          validate: (value) => {
                            if (value !== undefined && value?.length !== 3) {
                              return `${t('RequestInfo:InvalidField', { field: 'Leader office code' })}`
                            }
                            return true
                          }
                        })}
                        title={'Leader office code'}
                        placeholder={''}
                        errorMessage={errors?.leaderOfficeCode?.message}
                        maxLength={3}
                      />
                    </View>
                  )}
                  {selectedTab === TypeTab.region && (
                    <>
                      <View
                        style={{
                          marginHorizontal: 20,
                          marginTop: isWide ? 20 : 16
                        }}
                      >
                        <Input
                          {...register('regionCode', {
                            required: `${t('message:MS020001', { field: 'Region Code' })}`,
                            validate: (value) => {
                              if (value !== undefined && value?.length !== 3) {
                                return `${t('RequestInfo:InvalidField', { field: 'Region Code' })}`
                              }
                              return true
                            }
                          })}
                          title={'Region Code'}
                          maxLength={3}
                          placeholder={''}
                          errorMessage={errors?.regionCode?.message}
                        />
                      </View>
                      <View
                        style={{
                          marginHorizontal: 20,
                          marginTop: isWide ? 20 : 16
                        }}
                      >
                        <Input
                          {...register('regionType', {
                            required: `${t('message:MS020001', { field: 'Region Types' })}`,
                            validate: (value) => {
                              if (value !== undefined && value?.length !== 1) {
                                return `${t('RequestInfo:InvalidField', { field: 'Region type' })}`
                              }
                              return true
                            }
                          })}
                          title={'Region Types'}
                          maxLength={1}
                          placeholder={''}
                          errorMessage={errors?.regionType?.message}
                        />
                      </View>
                    </>
                  )}
                </>
              )}
              <View
                style={{
                  marginHorizontal: 20,
                  marginTop: isWide ? 20 : 16
                }}
              >
                <Input
                  {...register('note')}
                  title={'Note'}
                  inputStyle={[styles.input, { marginTop: 10, height: isWide ? 100 : 80 }]}
                  maxLength={256}
                  multiline={true}
                  placeholder={''}
                  errorMessage={errors?.regionType?.message}
                />
              </View>
            </ScrollView>
          </View>

          <View
            style={[
              styles.btnGroup,
              { marginBottom: 20, marginTop: isWide ? 30 : 28, marginHorizontal: isWide ? 0 : 16 }
            ]}
          >
            <CanView condition={isWide}>
              <RoundedButton
                showBorder
                activeOpacity={0.7}
                text={t('common:Cancel')}
                textStyle={styles.btnTitle}
                style={{ height: 39, marginEnd: 15, width: 108 }}
                textColorDisable={'#ED1B2E'}
                textColorEnable={'#ED1B2E'}
                borderColorDisable={'#ED1B2E'}
                borderColorEnable={'#ED1B2E'}
                onPress={onClose}
              />
            </CanView>
            <RoundedButton
              filled
              activeOpacity={0.7}
              text={t('claim:AddNew')}
              textStyle={styles.btnTitle}
              style={{ height: 39, width: isWide ? 108 : '100%' }}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorDisable={'#B0B0B0'}
              bgColorEnable={'#ED1B2E'}
              onPress={() => {
                onSubmit()
              }}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#4F4F4F',
    fontWeight: '600',
    fontSize: 20,
    marginEnd: 16
  },

  breadcumbCotnainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },

  breadcumbItem: {
    fontSize: 14,
    color: '#828282'
  },
  input: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },

  arrowIcon: {
    marginHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 25
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 'auto'
  },

  row: {
    alignItems: 'flex-end'
  },

  startItem: {
    width: 250
  },

  label: {
    color: '#70777E',
    fontWeight: 'bold',
    marginBottom: 2
  }
})
