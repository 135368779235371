export namespace OBActionHistoryConst {
  export interface ChangeContactInfo {
    nameVi: string
    nameEn: string
    code: boolean
  }
  type callIndicatorStatusInfo = {
    code: string
    nameVi: string
    nameEn: string
  }
  export const callIndicatorStatusList: Array<callIndicatorStatusInfo> = [
    {
      code: 'SUCCESSFUL',
      nameVi: 'Thành công',
      nameEn: 'Successful'
    },
    {
      code: 'UNSUCCESSFUL',
      nameVi: 'Chưa thành công',
      nameEn: 'Unsuccessful'
    },
    {
      code: 'CANCEL',
      nameVi: 'Hủy',
      nameEn: 'Cancel'
    }
  ]
  export const changeContactInfoList: ChangeContactInfo [] = [
    { nameVi: 'Yes', nameEn: 'Yes', code: true },
    { nameVi: 'No', nameEn: 'No', code: false },
  ]
}