import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { InquiryComplaintData, TransactionTypeC } from '@pulseops/common'
import { InquiryComplaintState } from '@pulseops/task'
import { category } from 'fp-ts'
export interface ClientSearch {
  contractNumber: string | null
  nationalId: string
  phoneNumber: string
  clientNumber: string
  email: string
  fullName: string
  dob: string
  pageIndex: number
  itemsPerPage: number
  orders: string[]
  sort: string
}

export interface PolicysSearch {
  pageIndex: number
  itemsPerPage: number
  orders: string[]
  sort: string
  clientNumber: string
  policyNo?: string
  proposalNumber?: string
  role: string
  lstPolicyStatusFilter: string[]
}

export interface DraftTransactionSearch {
  pageIndex: number
  itemsPerPage: number
  orders: string[]
  sort: string
  policyNo: string
  clientNo: string
  fromDate?: Date
  toDate?: Date
  transactionType?: string
  createdBy?: string
  id?: string
  status?: string
}

export interface SaveDraft {
  id?: string
  policyNo: string
  clientNo: string
  payload: {
    groupCode: string
    typeCode: string
    subTypeCode: string
    requesterRoleCode: string
    requesterFullName: string
    relationshipWithPO: string
    requesterNationalId: string
    method: string
    source: string
    callId: string
    hadFirstContactResolution: boolean
    customerComment: string
    responseToCustomer: string
    documentUrls: {
      type: string
      url: string
    }[]
    isUrgent: boolean
    poName: string
    callBackLaterHour?: number
    callbackAppointmentTime?: string
  }
  transactionType: string
  attributesExt?: {
    complainMisMoneyDetails?: {
      list: InquiryComplaintData.COMPLAINTMISMONEYDETAILS[]
      totalComplaintAmount: number
    }
    complainSaleDetails?: InquiryComplaintData.COMPLAINTSALEDETAILS
    complaintGADetails?: InquiryComplaintData.COMPLAINTGADETAILS
    conservationInfo?: InquiryComplaintData.CONSERVATIONINFO
  }
  updateVersion?: number
  status?: string
}

export interface CallbackAppointmentSearch {
  pageIndex: number
  itemsPerPage: number
  policyNumber: string
  clientNumber: string
  latestAssignee: string
  createdBefore: Date
  createdAfter: Date
}

export const ClientInfo = t.array(
  t.type({
    clientNumber: Maybe(t.string),
    fullName: Maybe(t.string),
    dob: Maybe(t.string),
    phoneNumber: Maybe(t.string),
    email: Maybe(t.string),
    nationalId: Maybe(t.string),
    address: Maybe(
      t.type({
        country: Maybe(t.string),
        city: Maybe(t.string),
        district: Maybe(t.string),
        ward: Maybe(t.string),
        address: Maybe(t.string)
      })
    ),
    addressDetail: Maybe(t.string)
  })
)

export type ClientInfo = t.TypeOf<typeof ClientInfo>

export const ClientSearchResponse = t.type({
  records: ClientInfo,
  paging: t.type({
    pageIndex: t.number,
    itemsPerPage: t.number,
    total: t.number,
    pages: t.number
  })
})

export type ClientSearchResponse = t.TypeOf<typeof ClientSearchResponse>

export interface PolicyDetail {
  policyNumber?: string
  policyOwnerName?: string
  mainProductName?: string
  policyStatus?: string
  premiumStatus?: string
  mainLAName?: string
  poNumber?: string
  mainProductCode?: string
  mainProductNameVi?: string
  mainProductNameEn?: string
}

export interface SearchClaimInquiry {
  clientNumber?: string
  claimNo?: string
  claimType?: string
  processStatus?: string
  pageIndex: number
  itemsPerPage: number
  orders: string[]
  sort: string
}

const MainProduct = t.type({
  code: Maybe(t.string),
  nameVn: Maybe(t.string),
  nameEn: Maybe(t.string)
})

const PolicyOwnerName = t.type({
  surName: Maybe(t.string),
  middleName: Maybe(t.string),
  firstName: Maybe(t.string),
  name: Maybe(t.string)
})
const ServicingAgent = t.type({
  agentCode: Maybe(t.string),
  agentName: Maybe(PolicyOwnerName),
  fromDate: Maybe(t.string),
  toDate: Maybe(t.string)
})

export interface PolicySearch {
  pageIndex: number
  itemsPerPage: number
  orders: string[]
  sort: string
  clientNumber: string
  policyNo: string
  role: string
  policyStatuses: string[]
}

export const PolicySearchItem = t.type({
  policyNumber: Maybe(t.string),
  policyStatus: Maybe(t.string),
  premiumStatus: Maybe(t.string),
  clientNumber: Maybe(t.string),
  proposalNo: Maybe(t.string),
  idNumber: Maybe(t.string),
  roles: Maybe(t.array(t.string)),
  policyOwnerName: Maybe(PolicyOwnerName),
  mainLifeAssuredName: Maybe(PolicyOwnerName),
  officeCode: Maybe(t.string),
  mainProduct: Maybe(MainProduct),
  paidToDateBasic: Maybe(t.string),
  aplAmount: Maybe(t.number),
  servicingAgent: Maybe(ServicingAgent),
  poClientNumber: Maybe(t.string),
  mainLifeAssuredClientNumber: Maybe(t.string),
  isPO: Maybe(t.boolean),
  isLA: Maybe(t.boolean),
  isBEN: Maybe(t.boolean),
  mainLAName: Maybe(t.string),
  poName: Maybe(t.string),
  mainProductNameVi: Maybe(t.string),
  mainProductNameEn: Maybe(t.string),
  mainProductCode: Maybe(t.string),
  premCessDate: Maybe(t.string),
  installmentPremium: Maybe(t.number),
  nextPaidPremium: Maybe(t.number)
})

export type PolicySearchItem = t.TypeOf<typeof PolicySearchItem>

export const PolicySearchResponse = t.type({
  records: t.array(PolicySearchItem),
  paging: t.type({
    total: t.number
  })
})

export type PolicySearchResponse = t.TypeOf<typeof PolicySearchResponse>

export const PolicyInfoC = t.type({
  data: t.type({
    policy: t.type({
      policyStatus: Maybe(t.string),
      premiumStatus: Maybe(t.string),
      sumAssured: Maybe(t.number),
      billingFrequency: Maybe(t.string),
      policyOwnerCode: Maybe(t.string),
      policyOwnerName: Maybe(
        t.type({
          surName: t.string,
          middleName: t.string,
          firstName: t.string,
          name: t.string
        })
      ),
      mainLifeAssuredCode: Maybe(t.string),
      mainLifeAssuredName: Maybe(
        t.type({
          surName: t.string,
          middleName: t.string,
          firstName: t.string,
          name: t.string
        })
      ),
      productCode: Maybe(t.string),
      productNameVN: Maybe(t.string),
      productNameEN: Maybe(t.string),
      installmentPremium: Maybe(t.number),
      riskCommencementDate: Maybe(t.string),
      aplDate: Maybe(t.string),
      policyIssueDate: Maybe(t.string),
      paidToDateBasic: Maybe(t.string),
      premiumCessationDate: Maybe(t.string),
      riskCessationDate: Maybe(t.string),
      customerReceiveDate: Maybe(t.string),
      customerReceiveMethod: Maybe(t.string),
      firstIssueDate: Maybe(t.string),
      uwDecisionDate: Maybe(t.string),
      proposalDate: Maybe(t.string),
      polCessationDate: Maybe(t.string),
      destOffice: Maybe(t.string),
      destOfficeName: Maybe(t.string),
      paidToDateRider: Maybe(t.string),
      premiumRiderAmount: Maybe(t.number),
      writeOffAmount: Maybe(t.number),
      waitingApplyPremiumAmount: Maybe(t.number),
      discountAmount: Maybe(t.number),
      premiumPaidInAdvanceAmount: Maybe(t.number),
      suspendPremiumAmount: Maybe(t.number),
      suspendAmount: Maybe(t.number),
      paidUpNewSumAssured: Maybe(t.number),
      dispatchAddress: Maybe(t.string)
    }),
    beneficiaries: Maybe(
      t.array(
        t.type({
          beneficiaryCode: Maybe(t.string),
          beneficiaryPercentage: Maybe(t.number),
          beneficiaryRelationship: Maybe(t.string),
          beneficiaryName: Maybe(
            t.type({
              surName: t.string,
              middleName: t.string,
              firstName: t.string,
              name: t.string
            })
          )
        })
      )
    ),
    transactionHistories: Maybe(
      t.array(
        t.type({
          id: Maybe(t.string),
          name: Maybe(t.string),
          businessKey: Maybe(t.string),
          source: Maybe(t.string),
          category: Maybe(t.string),
          transactionType: TransactionTypeC,
          policyNumber: Maybe(t.string),
          clientNumber: Maybe(t.string),
          poName: Maybe(t.string),
          lastUpdateDate: Maybe(t.string),
          subTransactionType: Maybe(t.string),
          agingDate: Maybe(t.number),
          assignee: Maybe(t.string),
          assigneeName: Maybe(t.string),
          activity: Maybe(t.string),
          createdDate: Maybe(t.string),
          request: Maybe(t.string),
          status: Maybe(t.string),
          createdBy: Maybe(t.string),
          lastUpdateByVer: Maybe(t.string),
          lastUpdateByAdj: Maybe(t.string),
          poClientNumber: Maybe(t.string),
          poPhoneNumber: Maybe(t.string),
          serviceType: Maybe(t.string),
          group: Maybe(t.string),
          subServiceType: Maybe(t.string),
          comment: Maybe(t.string),
          closeInfo: Maybe(t.string),
          note: Maybe(t.string),
          action: Maybe(t.string),
          endTime: Maybe(t.string),
          createTime: Maybe(t.string),
          lastUpdateDateAdj: Maybe(t.string),
          lastUpdateDateVer: Maybe(t.string),
          lifeAssuredName: Maybe(t.string),
          startTime: Maybe(t.string),
          completed: Maybe(t.boolean),
          suspended: Maybe(t.boolean)
        })
      )
    )
  })
})

export type PolicyInfoC = t.TypeOf<typeof PolicyInfoC>

export interface PolicyInfo {
  policyStatus: string
  premiumStatus: string
  sumAssured: number
  billingFrequency: string
  policyOwnerCode: string
  policyOwnerName: string
  mainLifeAssuredCode: string
  mainLifeAssuredName: string
  productCode: string
  productName: string
  installmentPremium: number
  riskCommencementDate: string
  aplDate: string
  policyIssueDate: string
  paidToDateBasic: string
  premiumCessationDate: string
  riskCessationDate: string
  customerReceiveDate: string
  customerReceiveMethod: string
  firstIssueDate: string
  premiumBasic: number
  uwDecisionDate: string
  proposalDate: string
  polCessationDate: string
  destOffice: string
  paidToDateRider: string
  premiumRiderAmount: number
  writeOffAmount: number
  waitingApplyPremiumAmount: number
  discountAmount: number
  premiumPaidInAdvanceAmount: number
  suspendPremiumAmount: number
  suspendAmount: number
  paidUpNewSumAssured: number
  dispatchAddress: string
  destOfficeName: string
}

export interface Beneficiary {
  stt: string
  clientId: string
  percentage: string
  relationshipType: string
  clientName: string
}

export interface PolicyInfoData {
  policyInfo: PolicyInfo | null
  beneficiaries: Beneficiary[]
}

export const ProductTypeC = t.type({
  body: t.type({
    productType: t.string,
    productCode: t.string,
    basicCode: t.string,
    status: t.string,
    salaryIncome: t.number,
    lastIssueDate: t.string,
    paidToDateAdvance: t.string,
    anniDate: t.string,
    paidToDateBasic: t.string,
    paidToDateRider: t.string,
    billToDate: t.string,
    billFreq: t.string,
    mainLifeAssuredNumber: t.string,
    clientDespatchAddress: t.string,
    riskCommenceDate: t.string,
    surrenderDate: t.string,
    lapsedDate: t.string,
    policyNo: t.string,
    totalPremium: t.number,
    contractDate: t.string,
    firstIssueDate: t.string,
    premiumStatus: t.string
  })
})

export type ProductTypeC = t.TypeOf<typeof ProductTypeC>

export const PolicyValueC = t.type({
  contractNumber: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  contractStatus: Maybe(t.string),
  productType: Maybe(t.string),
  coverageId: Maybe(t.string)
})
export type PolicyValueC = t.TypeOf<typeof PolicyValueC>

const PrucashBenefits = t.array(
  t.type({
    pruCashNo: Maybe(t.string),
    cashReleaseDate: Maybe(t.string),
    principalAmount: Maybe(t.number),
    interestAmount: Maybe(t.number),
    totalPerPeriod: Maybe(t.number),
    receiveDate: Maybe(t.string),
    receiveAmount: Maybe(t.number),
    currentBalanceAmount: Maybe(t.number)
  })
)

export type PrucashBenefits = t.TypeOf<typeof PrucashBenefits>

export const PrucashBenefitsResponseC = t.type({
  data: PrucashBenefits
})

export type PrucashBenefitsResponseC = t.TypeOf<typeof PrucashBenefitsResponseC>

export const PolicyValueInfoData = t.type({
  productInfo: Maybe(
    t.type({
      productType: Maybe(t.string),
      productCode: Maybe(t.string),
      basicCode: Maybe(t.string),
      status: Maybe(t.string)
    })
  ),
  validProduct: Maybe(t.boolean)
})

export type PolicyValueInfoData = t.TypeOf<typeof PolicyValueInfoData>

export const PolicyValueResponseC = t.type({
  data: PolicyValueInfoData
})

export type PolicyValueResponseC = t.TypeOf<typeof PolicyValueResponseC>

export const PremiumFrequencyDetailData = t.type({
  billingChangeReversal: t.type({
    currentBillingFrequency: Maybe(t.string),
    currentInstallmentPremium: Maybe(t.number),
    automaticPolicyLoanDate: Maybe(t.string),
    oldBillingFrequency: Maybe(t.string),
    oldInstallmentPremium: Maybe(t.number),
    automaticPolicyLoanInterest: Maybe(t.number),
    requirePayingAmount: Maybe(t.number),
    paymentLastDay: Maybe(t.string),
    aplDate: Maybe(t.string)
  }),
  billingChange: t.type({
    currentBillingFrequency: Maybe(t.string),
    currentInstallmentPremium: Maybe(t.number),
    suspenseAmount: Maybe(t.number),
    advancePremium: Maybe(t.number),
    effectiveDateOfNewFrequency: Maybe(t.string)
  }),
  billingChangeHistories: t.array(
    t.type({
      tranDate: Maybe(t.string),
      code: Maybe(t.string),
      description: Maybe(t.string),
      billingChangeHistories: Maybe(
        t.array(
          t.type({
            oldBillingFrequency: Maybe(t.string),
            newBillingFrequency: Maybe(t.string),
            effectiveDate: Maybe(t.string),
            validFlag: Maybe(t.string)
          })
        )
      )
    })
  )
})

export type PremiumFrequencyDetailData = t.TypeOf<typeof PremiumFrequencyDetailData>

export const PremiumFrequencyDetailResponseC = t.type({
  data: PremiumFrequencyDetailData
})

export type PremiumFrequencyDetailResponseC = t.TypeOf<typeof PremiumFrequencyDetailResponseC>

export const APLValueData = t.array(
  t.type({
    currency: Maybe(t.string),
    accuredInterest: Maybe(t.number),
    currentBalance: Maybe(t.number),
    policyLoanTotal: Maybe(t.number),
    pendingInterest: Maybe(t.number),
    principalAmount: Maybe(t.number),
    loanNumber: Maybe(t.number),
    loanStartDate: Maybe(t.string),
    loanType: Maybe(t.string),
    dutyStamp: Maybe(t.number),
    receivedDate: Maybe(t.string),
    receivedAmount: Maybe(t.number),
    premiumDate: Maybe(t.string)
  })
)

export type APLValueData = t.TypeOf<typeof APLValueData>

export const APLValueResponseC = t.type({
  data: APLValueData
})

export type APLValueResponseC = t.TypeOf<typeof APLValueResponseC>

export const OPLValueData = t.array(
  t.type({
    currency: Maybe(t.string),
    accuredInterest: Maybe(t.number),
    currentBalance: Maybe(t.number),
    policyLoanTotal: Maybe(t.number),
    pendingInterest: Maybe(t.number),
    principalAmount: Maybe(t.number),
    loanNumber: Maybe(t.number),
    loanStartDate: Maybe(t.string),
    loanType: Maybe(t.string),
    dutyStamp: Maybe(t.number),
    receivedDate: Maybe(t.string),
    receivedAmount: Maybe(t.number)
  })
)

export type OPLValueData = t.TypeOf<typeof OPLValueData>

export const OPLValueResponseC = t.type({
  data: OPLValueData
})

export type OPLValueResponseC = t.TypeOf<typeof OPLValueResponseC>

export const BonusHistoryItem = t.type({
  effectiveDate: Maybe(t.string),
  transactionNo: Maybe(t.string),
  transactionDate: Maybe(t.string),
  transCode: Maybe(t.string),
  accountingAmount: Maybe(t.number),
  originAmount: Maybe(t.number)
})
export type BonusHistoryItem = t.TypeOf<typeof BonusHistoryItem>

export const BonusValueData = t.type({
  bonusHistories: Maybe(t.array(BonusHistoryItem)),
  bonusSurrender: t.type({
    bonusValue: Maybe(t.number),
    currentBalance: Maybe(t.number),
    interest: Maybe(t.number),
    bonusReserveRemainValue: Maybe(t.number)
  })
})

export const BonusValueResponseC = t.type({
  data: BonusValueData
})

export type BonusValueData = t.TypeOf<typeof BonusValueData>

export type BonusValueResponseC = t.TypeOf<typeof BonusValueResponseC>

export const SurrenderValueData = t.type({
  cashDeposits: Maybe(t.number),
  suspenseAmount: Maybe(t.number),
  policyLoan: Maybe(t.number),
  policyDebt: Maybe(t.number),
  surrenderCharge: Maybe(t.number),
  total: Maybe(t.number),
  surrenderChargePercent: Maybe(t.number),
  netSurrenderValueAmount: Maybe(t.number)
})

export type SurrenderValueData = t.TypeOf<typeof SurrenderValueData>

export const SurrenderValueResponseC = t.type({
  data: SurrenderValueData
})
export type SurrenderValueResponseC = t.TypeOf<typeof SurrenderValueResponseC>

export const ContractValueData = t.type({
  contractSurrenderValue: Maybe(t.number),
  loanAmountAvailable: Maybe(t.number),
  automaticPremiumLoan: Maybe(t.number),
  originalPolicyLoan: Maybe(t.number),
  loanExceedSurrenderValue: Maybe(t.number),
  bonusValue: Maybe(t.number),
  specialBonus: Maybe(t.number),
  bonusSurrender: Maybe(t.number),
  suspenseAmount: Maybe(t.number),
  maturityBenefit: Maybe(t.number),
  totalCashBenefit: Maybe(t.number),
  maxWithDraw: Maybe(t.number),
  pruKidBenefit: Maybe(t.number),
  prevMaturityAdvance: Maybe(t.number),
  totalMaturityAdvance: Maybe(t.number)
})

export type ContractValueData = t.TypeOf<typeof ContractValueData>

export const ContractValueResponseC = t.type({
  data: ContractValueData
})
export type ContractValueResponseC = t.TypeOf<typeof ContractValueResponseC>

export const extraInfoC = t.type({
  data: t.type({
    function: t.string,
    policies: t.array(t.string)
  })
})

export type extraInfoC = t.TypeOf<typeof extraInfoC>

export const ExtraInfoResponse = t.type({
  data: t.type({
    policyExtraInfoDetails: t.array(
      t.type({
        productCode: t.string,
        lifeNo: t.string,
        coverageNo: t.string,
        riderNo: t.string,
        benefitOption: t.string,
        mortClass: t.string,
        sumAssured: t.number,
        installmentPremium: t.number,
        totalPaidBasicYearly: t.number,
        riskCommDate: t.string,
        premCessDate: t.string,
        riskCessDate: t.string,
        dealingDate: t.string,
        rerateDate: t.string,
        debtAmount: t.number,
        targetPremium: t.number,
        minTopUp: t.number,
        maxTopUp: t.number,
        policyNum: t.string,
        riderStatus: t.string,
        riderPremiumStatus: t.string
      })
    ),
    policyExtraInfoFunds: t.array(
      t.type({
        fundCode: t.string,
        unitBalance: t.number,
        price: t.number,
        priceDate: t.string,
        policyNum: t.string,
        estimateValue: t.number,
        fundNameEN: Maybe(t.string),
        fundNameVN: Maybe(t.string),
        fundTypeCode: Maybe(t.string),
        fundTypeName: Maybe(t.string)
      })
    )
  })
})

export type ExtraInfoResponse = t.TypeOf<typeof ExtraInfoResponse>

export const PremiumHisReq = t.type({
  data: t.type({
    policyNo: t.string,
    clientNo: t.string
  })
})

export type PremiumHisReq = t.TypeOf<typeof PremiumHisReq>

export const PremiumHistoryInfo = t.type({
  total: Maybe(t.number),
  start: Maybe(t.number),
  size: Maybe(t.number),
  data: Maybe(t.type({}))
})
export type PremiumHistoryInfo = t.TypeOf<typeof PremiumHistoryInfo>

export interface IPremiumHistory {
  transactionId: string | undefined | null
  paymentType: string | undefined | null
  bankCode: string | undefined | null
  transDate: string | undefined | null
  amount: number | string | undefined | null
  rcpDate: string | undefined | null
  orNo: string | undefined | null
  agtCol: string | undefined | null
  user: string | undefined | null
  channel: string | undefined | null
  sacsCode: string | undefined | null
  sacsType: string | undefined | null
  gtAmount: number | string | undefined | null
  content: string | undefined | null
  transactionCode: string | undefined | null
  paymentDesc: string | undefined | null
  isFromACMV: boolean | undefined | null
  glCode: string | undefined | null
  premiumStatus: string | undefined | null
  code: string | undefined | null
  transNo: string | undefined | null
  acct: string | undefined | null
}

export interface PremiumInfoData {
  transactionId: string
  data: IPremiumHistory[]
}

export interface IPremiumDetail {
  transactionId: string | null | undefined
  sacsCode: string | null | undefined
  sacsType: string | null | undefined
  gtAmount: string | number | null | undefined
  content: string | null | undefined
}

export interface IProductDetail {
  body: {
    productType: string
    productCode: string
    basicCode: string
    status: string
    salaryIncome: number
    lastIssueDate: string
    paidToDateAdvance: string
    anniDate: string
    paidToDateBasic: string
    paidToDateRider: string
    billToDate: string
    billFreq: string
    mainLifeAssuredNumber: string
    clientDespatchAddress: string
    riskCommenceDate: string
    surrenderDate: string
    lapsedDate: string
    policyNo: string
    totalPremium: number
    contractDate: string
    firstIssueDate: string
    premiumStatus: string
  }
}

export const BillingFreqRes = t.type({
  body: Maybe(
    t.type({
      nextInstallmentPremium: t.string,
      minimumContribution: t.string
    })
  ),
  error: Maybe(
    t.type({
      code: Maybe(t.number),
      message: Maybe(t.string),
      errors: Maybe(
        t.array(
          t.type({
            domain: Maybe(t.string),
            reason: Maybe(t.string),
            message: Maybe(t.string),
            errorCode: Maybe(t.string)
          })
        )
      )
    })
  )
})

export const dataBillingFreq = t.type({
  body: t.type({
    contractNumber: t.string,
    billingFrequency: t.string
  })
})

export type dataBillingFreq = t.TypeOf<typeof dataBillingFreq>

export enum LoanType {
  EO = 'EO',
  EI = 'EI',
  AO = 'AO',
  AI = 'AI',
  LO = 'LO',
  LI = 'LI'
}
export const PruCashBenefitReq = t.type({
  data: t.type({
    policyNo: t.string,
    loanNo: t.string,
    loanType: t.string
  })
})
export type PruCashBenefitReq = t.TypeOf<typeof PruCashBenefitReq>

export const PruCashBenefitData = t.type({
  loanNo: Maybe(t.string),
  loanType: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  transNo: Maybe(t.string),
  glNo: Maybe(t.string),
  transDate: Maybe(t.string),
  transCode: Maybe(t.string),
  accountingAmount: Maybe(t.number)
})
export type PruCashBenefitData = t.TypeOf<typeof PruCashBenefitData>

export const PruCashBenefitDataInfo = t.type({
  data: Maybe(t.array(PruCashBenefitData)),
  error: Maybe(
    t.array(
      t.type({
        domain: Maybe(t.string),
        reason: Maybe(t.string),
        message: Maybe(t.string),
        errorCode: Maybe(t.string)
      })
    )
  )
})

export const BonusReverseDataC = t.type({
  data: t.type({
    contractNumber: Maybe(t.string),
    effectiveDate: Maybe(t.string),
    contractStatus: Maybe(t.string),
    productType: Maybe(t.string),
    coverageId: Maybe(t.string),
    amountToSurrender: Maybe(t.number),
    bonusValue: Maybe(t.number),
    bonusReserveRemainValue: Maybe(t.number)
  })
})

export type BonusReverseDataC = t.TypeOf<typeof BonusReverseDataC>

export const BonusReverseInfoC = t.type({
  data: t.type({
    bonusReserveValue: Maybe(t.number),
    bonusValue: Maybe(t.number),
    bonusReserveRemainValue: Maybe(t.number),
    cashValue: Maybe(t.number)
  })
})

export type BonusReverseInfoC = t.TypeOf<typeof BonusReverseInfoC>

export interface IACGroup {
  id: string
  categoryName: string
  code: string
  name: string
  nameVi: string
}

export const CustomerProfileData = t.type({
  fullName: Maybe(t.string),
  vip: Maybe(t.string),
  sex: Maybe(t.string),
  clientNumber: Maybe(t.string),
  nationalId: Maybe(t.string),
  dob: Maybe(t.string),
  msgDOB: Maybe(t.string),
  currentAge: Maybe(t.number),
  mobilePhoneNumber: Maybe(t.string),
  mobilePhoneIndicator: Maybe(t.string),
  otpConsent: Maybe(t.string),
  homePhoneNumber: Maybe(t.string),
  officePhoneNumber: Maybe(t.string),
  email: Maybe(t.string),
  emailIndicator: Maybe(t.string),
  zalo: Maybe(
    t.type({
      channel: Maybe(t.string),
      contactValue: Maybe(t.string),
      indicator: Maybe(t.string),
      zaloId: Maybe(t.string),
      zaloName: Maybe(t.string)
    })
  ),
  mobileDuplicateObjs: Maybe(
    t.array(
      t.type({
        clientNumber: Maybe(t.string),
        mobilePhoneNumber: Maybe(t.string),
        fullName: Maybe(t.string)
      })
    )
  ),
  address: Maybe(
    t.type({
      country: Maybe(t.string),
      city: Maybe(t.string),
      district: Maybe(t.string),
      ward: Maybe(t.string),
      address: Maybe(t.string)
    })
  ),
  pruOnlineList: Maybe(
    t.array(
      t.type({
        account: Maybe(t.string),
        signature: Maybe(t.string)
      })
    )
  ),
  maritalStatus: Maybe(t.string),
  isLockedPolicyAdjustment: Maybe(t.boolean),
  customerType: Maybe(t.string),
  agentDuplicate: Maybe(
    t.array(
      t.type({
        agentCode: Maybe(t.string),
        agentName: Maybe(t.string)
      })
    )
  ),
  nationality: Maybe(t.string),
  nationalName: Maybe(t.string),
  occupationCode: Maybe(t.string),
  occupationName: Maybe(t.string),
  myHR: Maybe(t.string),
  latestPaymenType: Maybe(t.string),
  bankCode: Maybe(t.string),
  taxInfo: Maybe(t.string),
  foreignCountry: Maybe(
    t.type({
      country: Maybe(t.string),
      city: Maybe(t.string),
      district: Maybe(t.string),
      ward: Maybe(t.string),
      street: Maybe(t.string)
    })
  ),
  workPlace: Maybe(
    t.type({
      city: Maybe(t.string),
      district: Maybe(t.string),
      ward: Maybe(t.string),
      street: Maybe(t.string),
      address: Maybe(t.string)
    })
  ),
  fao: Maybe(t.string),
  registeredAddress: Maybe(t.string),
  agents: Maybe(
    t.array(
      t.type({
        clientId: Maybe(t.string),
        code: Maybe(t.string),
        officeCode: Maybe(t.string),
        officeName: Maybe(t.string),
        terminationDate: Maybe(t.string),
        terReasonCode: Maybe(t.string),
        terReasonName: Maybe(t.string)
      })
    )
  ),
  faoAddress: Maybe(
    t.type({
      city: Maybe(t.string),
      district: Maybe(t.string),
      ward: Maybe(t.string),
      street: Maybe(t.string),
      country: Maybe(t.string)
    })
  ),
  familyRelationships: Maybe(
    t.array(
      t.type({
        policyNumber: Maybe(t.string),
        clientId: Maybe(t.string),
        clientFullName: Maybe(t.string),
        relationshipDesc: Maybe(t.string)
      })
    )
  ),
  masterClientId: Maybe(t.string),
  aliasClientIds: Maybe(t.array(t.string)),
  paymentType: Maybe(t.string),
  clientType: Maybe(t.string),
  dateOfDeath: Maybe(t.string),
  callIndicator: Maybe(t.string)
})

export type CustomerProfileData = t.TypeOf<typeof CustomerProfileData>

export const CustomerProfileResponse = t.type({
  data: CustomerProfileData
})
export type CustomerProfileResponse = t.TypeOf<typeof CustomerProfileResponse>

export const PolicySummaryData = t.type({
  clientId: t.string,
  policySummary: Maybe(
    t.array(
      t.type({
        totalPolicy: t.number,
        statusGroup: t.string
      })
    )
  ),
  requestTransactionSummary: Maybe(
    t.array(
      t.type({
        transactionQuantity: Maybe(t.number),
        statusGroup: t.string
      })
    )
  ),
  queryTransactionSummary: Maybe(
    t.array(
      t.type({
        transactionQuantity: Maybe(t.number),
        statusGroup: t.string
      })
    )
  ),
  complaintTransactionSummary: Maybe(
    t.array(
      t.type({
        transactionQuantity: Maybe(t.number),
        statusGroup: t.string
      })
    )
  ),
  claimSummary: Maybe(
    t.array(
      t.type({
        totalClaim: Maybe(t.number),
        statusGroup: t.string
      })
    )
  )
})

export type PolicySummaryData = t.TypeOf<typeof PolicySummaryData>

export const PolicySummaryResponse = t.type({
  data: PolicySummaryData
})

export type PolicySummaryResponse = t.TypeOf<typeof PolicySummaryResponse>

export const DuplicatePhoneData = t.type({
  clientNumber: Maybe(t.string),
  clientName: Maybe(
    t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  agentCode: Maybe(t.string),
  agentName: Maybe(
    t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })
  )
})
export type DuplicatePhoneData = t.TypeOf<typeof DuplicatePhoneData>
export const DuplicatePhoneResponse = t.type({
  data: t.array(DuplicatePhoneData)
})
export type DuplicatePhoneResponse = t.TypeOf<typeof DuplicatePhoneResponse>

export const PolicyInquiryCountData = t.type({
  code: Maybe(t.string),
  value: Maybe(t.number),
  childs: Maybe(
    t.array(
      t.type({
        code: Maybe(t.string),
        value: Maybe(t.number)
      })
    )
  )
})

export type PolicyInquiryCountData = t.TypeOf<typeof PolicyInquiryCountData>

export const PolicyInquiryCountResponse = t.type({
  data: t.array(PolicyInquiryCountData)
})
export type PolicyInquiryCountResponse = t.TypeOf<typeof PolicyInquiryCountResponse>

export const ServicingAgentInfo = Maybe(
  t.type({
    code: Maybe(t.string),
    name: Maybe(
      t.type({
        surName: Maybe(t.string),
        middleName: Maybe(t.string),
        firstName: Maybe(t.string),
        name: Maybe(t.string)
      })
    ),
    mobilePhone: Maybe(t.string),
    gender: Maybe(t.string),
    dob: Maybe(t.string),
    clientId: Maybe(t.string),
    id: Maybe(t.string),
    terminateDate: Maybe(t.string),
    terminationReason: Maybe(t.string),
    officeCode: Maybe(t.string),
    gaCode: Maybe(t.string),
    region: Maybe(t.string),
    unitManager: Maybe(
      t.type({
        agentCode: Maybe(t.string),
        unitCode: Maybe(t.string),
        name: Maybe(
          t.type({
            surName: Maybe(t.string),
            middleName: Maybe(t.string),
            firstName: Maybe(t.string),
            name: Maybe(t.string)
          })
        ),
        mobilePhone: Maybe(t.string)
      })
    ),
    branchManager: Maybe(
      t.type({
        agentCode: Maybe(t.string),
        branchCode: Maybe(t.string),
        name: Maybe(
          t.type({
            surName: Maybe(t.string),
            middleName: Maybe(t.string),
            firstName: Maybe(t.string),
            name: Maybe(t.string)
          })
        ),
        mobilePhone: Maybe(t.string)
      })
    ),
    regionManager: Maybe(t.string),
    adminManager: Maybe(t.string),
    headTeam: Maybe(t.string),
    subZoneHead: Maybe(t.string),
    nationalId: Maybe(t.string),
    officeName: Maybe(t.string),
    gaName: Maybe(t.string)
  })
)
export type ServicingAgentInfo = t.TypeOf<typeof ServicingAgentInfo>

export const AgentInfoResponse = t.type({
  data: t.type({
    servicingAgent: ServicingAgentInfo,
    sharingServicingAgent: ServicingAgentInfo,
    agentHistory: Maybe(
      t.array(
        t.type({
          code: Maybe(t.string),
          name: Maybe(
            t.type({
              surName: Maybe(t.string),
              middleName: Maybe(t.string),
              firstName: Maybe(t.string),
              name: Maybe(t.string)
            })
          ),
          fromDate: Maybe(t.string),
          toDate: Maybe(t.string)
        })
      )
    )
  })
})

export type AgentInfoResponse = t.TypeOf<typeof AgentInfoResponse>

export const NBRequestInfos = t.type({
  requestRole: Maybe(t.string),
  roleName: Maybe(t.string),
  remarks: Maybe(t.string),
  requestStatus: Maybe(t.string),
  createdDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  lastUpdatedDate: Maybe(t.string),
  lastUpdatedBy: Maybe(t.string)
})

export type NBRequestInfos = t.TypeOf<typeof NBRequestInfos>

export const ReinsuranceRequestDetails = t.type({
  issueRole: Maybe(t.string),
  clientNumber: Maybe(t.string),
  clientName: t.type({
    surName: Maybe(t.string),
    middleName: Maybe(t.string),
    firstName: Maybe(t.string),
    name: Maybe(t.string)
  }),
  issueDescription: Maybe(t.string),
  medicalDescription: Maybe(t.string),
  reinDescription: Maybe(t.string),
  lifeNo: Maybe(t.string),
  uwDecisionBasic: Maybe(t.string),
  letterSummaryContent: Maybe(t.string),
  uwDecision: Maybe(t.string),
  noteForCustomer: Maybe(t.string),
  issueRequestDate: Maybe(t.string),
  issueStatus: Maybe(t.string)
})

export type ReinsuranceRequestDetails = t.TypeOf<typeof ReinsuranceRequestDetails>

export const NoteNBUWResponse = t.type({
  eventDate: Maybe(t.string),
  policyNumber: Maybe(t.string),
  diseaseCode: Maybe(t.string),
  uwNote: Maybe(t.string),
  createdDate: Maybe(t.string),
  lasUserId: Maybe(t.string)
})

export type NoteNBUWResponse = t.TypeOf<typeof NoteNBUWResponse>

export const UWRequestDetail = t.type({
  clientName: Maybe(t.string),
  clientId: Maybe(t.string),
  requestRole: Maybe(t.string),
  status: Maybe(t.string),
  gender: Maybe(t.string),
  dob: Maybe(t.string),
  uwDecision: Maybe(t.string),
  lifeNo: Maybe(t.string),
  meRequestDetails: Maybe(
    t.array(
      t.type({
        provinceCode: Maybe(t.string),
        provinceName: Maybe(t.string),
        meServiceLevel: Maybe(t.string),
        panelDoctor: Maybe(t.string),
        suspendRemark: Maybe(t.string),
        noteForPanelDoctor: Maybe(t.string),
        noteForCustomer: Maybe(t.string)
      })
    )
  ),
  issueRequestDetails: Maybe(
    t.array(
      t.type({
        requestedDate: Maybe(t.string),
        status: Maybe(t.string),
        description: Maybe(t.string)
      })
    )
  ),
  reinRequestDetails: Maybe(
    t.array(
      t.type({
        requestedDate: Maybe(t.string),
        status: Maybe(t.string),
        description: Maybe(t.string)
      })
    )
  ),
  conditionAcceptContents: Maybe(t.array(t.string))
  // uwNotes: Maybe(t.array(NoteNBUWResponse))
})

export type UWRequestDetail = t.TypeOf<typeof UWRequestDetail>

export const NBUWInfoResponse = t.type({
  data: Maybe(
    t.type({
      policyNumber: Maybe(t.string),
      proposalNumber: Maybe(t.string),
      policyStatus: Maybe(t.string),
      premiumStatus: Maybe(t.string),
      proposalFinalUWDecision: Maybe(t.string),
      reason: Maybe(t.string),
      remarkForCallCenter: Maybe(t.string),
      nbRequests: Maybe(t.array(NBRequestInfos)),
      uwRequests: Maybe(t.array(UWRequestDetail)),
      uwDecisionHistories: Maybe(
        t.array(
          t.type({
            createDate: Maybe(t.string),
            uwDecision: Maybe(t.string),
            lasUserId: Maybe(t.string)
          })
        )
      ),
      transactionNotes: Maybe(
        t.array(
          t.type({
            policyNo: Maybe(t.string),
            fupCode: Maybe(t.string),
            requestDate: Maybe(t.string),
            receiveDate: Maybe(t.string),
            receive2Date: Maybe(t.string),
            remark: Maybe(t.string)
          })
        )
      )
    })
  )
})

export type NBUWInfoResponse = t.TypeOf<typeof NBUWInfoResponse>

export const FAOInfo = t.type({
  title: Maybe(t.string),
  fullName: Maybe(t.string),
  dob: Maybe(t.string),
  address: Maybe(t.string),
  gender: Maybe(t.string),
  nationality: Maybe(t.string),
  idNumber: Maybe(t.string)
})
export type FAOInfo = t.TypeOf<typeof FAOInfo>

export const FAOInfoResponse = t.type({
  data: t.array(FAOInfo)
})

export type FAOInfoResponse = t.TypeOf<typeof FAOInfoResponse>

const AllocatedPremium = t.type({
  mainProductAndRider1: Maybe(t.number),
  rider2: Maybe(t.number)
})

const PolicyExtraInfoDetails = t.type({
  productCode: Maybe(t.string),
  lifeNo: Maybe(t.string),
  coverageNo: Maybe(t.string),
  riderNo: Maybe(t.string),
  benefitOption: Maybe(t.string),
  mortClass: Maybe(t.string),
  sumAssured: Maybe(t.number),
  installmentPremium: Maybe(t.number),
  totalPaidBasicYearly: Maybe(t.number),
  riskCommDate: Maybe(t.string),
  premCessDate: Maybe(t.string),
  riskCessDate: Maybe(t.string),
  dealingDate: Maybe(t.string),
  rerateDate: Maybe(t.string),
  debtAmount: Maybe(t.number),
  targetPremium: Maybe(t.number),
  minTopUp: Maybe(t.number),
  maxTopUp: Maybe(t.number),
  policyNum: Maybe(t.string),
  riderStatus: Maybe(t.string),
  riderPremiumStatus: t.string
})

const PolicyExtraInfoFunds = t.type({
  fundCode: Maybe(t.string),
  unitBalance: Maybe(t.number),
  price: Maybe(t.number),
  priceDate: Maybe(t.string),
  policyNum: Maybe(t.string),
  estimateValue: Maybe(t.number),
  fundNameEN: Maybe(t.string),
  fundNameVN: Maybe(t.string),
  fundTypeCode: Maybe(t.string),
  fundTypeName: Maybe(t.string)
})

const PolicyExtraInfo = t.type({
  policyExtraInfoDetails: Maybe(t.array(PolicyExtraInfoDetails)),
  policyExtraInfoFunds: Maybe(t.array(PolicyExtraInfoFunds))
})

export const InvestmentSummary = t.type({
  fundValue: Maybe(t.number),
  totalPartialWithdrawAmt: Maybe(t.number),
  maxWithDrawableAmt: Maybe(t.number),
  allocatedPremium: Maybe(AllocatedPremium),
  allocatedAccValue: Maybe(t.number),
  administrationCharge: Maybe(t.number),
  costOfInsurance: Maybe(t.number),
  totalAllocationCharges: Maybe(t.number),
  totalExcessPremium: Maybe(t.number),
  policyExtraInfo: Maybe(PolicyExtraInfo),
  fundAllocatingRates: Maybe(
    t.array(
      t.type({
        fundId: Maybe(t.string),
        fundName: Maybe(t.string),
        percentage: Maybe(t.string)
      })
    )
  )
})

export type InvestmentSummary = t.TypeOf<typeof InvestmentSummary>

export const InvestmentSummaryResponse = t.type({
  data: InvestmentSummary
})
export type InvestmentSummaryResponse = t.TypeOf<typeof InvestmentSummaryResponse>

export const InvestmentFund = t.type({
  id: Maybe(t.string),
  fundCode: Maybe(t.string),
  fundNameEN: Maybe(t.string),
  fundNameVN: Maybe(t.string),
  fundTypeName: Maybe(t.string),
  fundTypeCode: Maybe(t.string),
  inBoundNameVN: Maybe(t.string)
})
export type InvestmentFund = t.TypeOf<typeof InvestmentFund>

export const InvestmentFundResponse = t.type({
  data: t.array(InvestmentFund)
})
export type InvestmentFundResponse = t.TypeOf<typeof InvestmentFundResponse>

export const EstimatedValueInfo = t.type({
  estimatedValueTotal: t.string,
  totalExcessPremium: t.string,
  totalAllocatedPremiumToBasicProductAndGroup1Riders: t.string,
  totalAllocationCharges: t.string,
  targetPremiumEstimatedValue: t.string,
  totalWithdrawnAmount: t.string,
  totalAllocatedPremiumToGroup2Riders: t.string,
  totalCostOfInsurance: t.string,
  topUpPremiumEstimatedValue: t.string,
  maximumWithdrawableAmount: t.string,
  totalAllocatedPremiumToAccountValue: t.string,
  totalAdministrationCharge: t.string,
  fundValueTotal: t.string
})

export type EstimatedValueInfo = t.TypeOf<typeof EstimatedValueInfo>

const IlpFunds = t.type({
  fundCode: Maybe(t.string),
  minRemain: Maybe(t.number),
  fundValue: Maybe(t.number),
  withdrawAmount: Maybe(t.string),
  isRequired: Maybe(t.boolean)
})

const UlpFunds = t.type({
  actualValue: Maybe(t.number),
  estimatedValue: Maybe(t.number),
  fundCode: Maybe(t.string),
  isEPA: Maybe(t.boolean)
})

export const PartialWithdrawInfo = t.type({
  estimatedValue: Maybe(t.number),
  minimumPartialWithdrawal: Maybe(t.number),
  maximumPartialWithdrawal: Maybe(t.number),
  withDrawFee: Maybe(t.number),
  newSumAssured: Maybe(t.number),
  sumAssured: Maybe(t.number),
  ilpFunds: Maybe(t.array(IlpFunds)),
  ulpFunds: Maybe(t.array(UlpFunds))
})
export type PartialWithdrawInfo = t.TypeOf<typeof PartialWithdrawInfo>

export const PartialWithdrawResponse = t.type({
  data: Maybe(PartialWithdrawInfo),
  code: Maybe(t.string),
  message: Maybe(t.string),
  error: Maybe(
    t.type({
      code: Maybe(t.string),
      message: Maybe(t.string),
      errors: Maybe(
        t.array(
          t.type({
            domain: Maybe(t.string),
            reason: Maybe(t.string),
            message: Maybe(t.string),
            errorCode: Maybe(t.string)
          })
        )
      )
    })
  )
})
export type PartialWithdrawResponse = t.TypeOf<typeof PartialWithdrawResponse>

export const PartialWithdrawInfoRequest = t.type({
  policyNo: t.string,
  productType: t.string,
  details: t.array(
    t.type({
      coverage: t.string,
      fundCode: t.string,
      percent: t.number,
      amount: t.number
    })
  )
})
export type PartialWithdrawInfoRequest = t.TypeOf<typeof PartialWithdrawInfoRequest>

export const DetailFundValueData = t.type({
  realUnits: Maybe(t.number),
  cutOffDate: Maybe(t.string),
  moniesDate: Maybe(t.string),
  fundAmount: Maybe(t.number),
  code: Maybe(t.string)
})

export type DetailFundValueData = t.TypeOf<typeof DetailFundValueData>

export const DetailFundValueResponse = t.type({
  data: t.array(DetailFundValueData)
})

export type DetailFundValueResponse = t.TypeOf<typeof DetailFundValueResponse>

export const UnitLinkFundPriceData = t.type({
  fundCode: Maybe(t.string),
  fundName: Maybe(t.string),
  unitType: Maybe(t.string),
  effDate: Maybe(t.string),
  barePrice: Maybe(t.number),
  bidPrice: Maybe(t.number),
  unitPrice: Maybe(t.number),
  lastUpdatedTimestamp: Maybe(t.string)
})

export type UnitLinkFundPriceData = t.TypeOf<typeof UnitLinkFundPriceData>

export const UnitLinkFundPriceResponse = t.type({
  data: t.array(UnitLinkFundPriceData)
})

export type UnitLinkFundPriceResponse = t.TypeOf<typeof UnitLinkFundPriceResponse>

export const ACMVQueryData = t.type({
  transNo: Maybe(t.string),
  transDate: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  accountingAmount: Maybe(t.number),
  originAmount: Maybe(t.number),
  glAmount: Maybe(t.number),
  moneySign: Maybe(t.string),
  glCode: Maybe(t.string),
  sacsCode: Maybe(t.string),
  sacsType: Maybe(t.string),
  transCode: Maybe(t.string),
  accNo: Maybe(t.string),
  validFlag: Maybe(t.string),
  additionalProperties: Maybe(
    t.type({
      productCode: Maybe(t.string),
      partialWithDrawMoney: Maybe(
        t.type({
          fee: Maybe(t.number),
          purChaseAmount: Maybe(t.number),
          withDrawAmount: Maybe(t.number)
        })
      ),
      fundSwitchingMoney: Maybe(
        t.type({
          amount: Maybe(t.number),
          fee: Maybe(t.number)
        })
      )
    })
  )
})

export type ACMVQueryData = t.TypeOf<typeof ACMVQueryData>

export const ACMVQueryResponse = t.type({
  data: Maybe(t.array(ACMVQueryData))
})

export type ACMVQueryResponse = t.TypeOf<typeof ACMVQueryResponse>

export const ReinData = t.type({
  attributesExt: t.type({
    OPL: Maybe(t.number),
    APL: Maybe(t.number),
    TRANS_CODE: Maybe(t.string),
    LAPSED_DATE: Maybe(t.string),
    FREQUENCY: Maybe(t.string),
    INSTALLMENT_PREM: Maybe(t.number),
    REINS_FEE: Maybe(t.number),
    TOTAL_REINS_AMOUNT: Maybe(t.number),
    PAID_TO_DATE: Maybe(t.string),
    PREMIUM_FREQUENCY: Maybe(t.string),
    INSTALLMENT_PREMIUM: Maybe(t.number),
    PROJECT_PTDATE: Maybe(t.string),
    TOTAL_OUTS_PREMIUM_CNT_CURR: Maybe(t.number),
    BILL_CURR_SUSPENS: Maybe(t.number),
    TOTAL_BILL_CURR_SUSPENS_LEFT: Maybe(t.number),
    TOTAL_BILL_CURR_SUSPENS_RIGHT: Maybe(t.number),
    NEXT_PREMIUM_LEFT: Maybe(t.number),
    NEXT_PREMIUM_RIGHT: Maybe(t.number),
    REINSTATEMENT_FEE: Maybe(t.number),
    BALANCE_APL: Maybe(t.number),
    BALANCE_OPL: Maybe(t.number),
    INTEREST_APL: Maybe(t.number),
    INTEREST_OPL: Maybe(t.number),
    RISK_COMM_DATE: Maybe(t.string),
    AGENT_CODE: Maybe(t.string),
    AGENT_NAME: Maybe(t.string),
    DEBT: Maybe(t.number),
    EST_COI_ADMIN_CHARGE: Maybe(t.number),
    ESTIMATE_FV: Maybe(t.number),
    IN_FORCE_UNTIL: Maybe(t.string),
    OUT_COI_FEE: Maybe(t.number),
    OUTS_MONTHLY_CHARGE: Maybe(t.number),
    RERATE_FROM_DATE: Maybe(t.string)
  })
})

export type ReinData = t.TypeOf<typeof ReinData>

export const ReinResponse = t.type({
  data: ReinData,
  errors: Maybe(
    t.array(
      t.type({
        errorCode: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    )
  )
})
export type ReinResponse = t.TypeOf<typeof ReinResponse>

export const MainListProduct = t.type({
  productCode: Maybe(t.string),
  productNameVN: Maybe(t.string),
  productNameEN: Maybe(t.string),
  coverageCode: Maybe(t.string),
  coverageName: Maybe(t.string),
  coverageNameVN: Maybe(t.string)
})

export type MainListProduct = t.TypeOf<typeof MainListProduct>

export const MainProductResponse = t.type({
  data: Maybe(t.array(MainListProduct))
})
export type MainProductResponse = t.TypeOf<typeof MainProductResponse>

export const TopUpHistoryData = t.type({
  transNo: Maybe(t.string),
  topUpDate: Maybe(t.string),
  transCode: Maybe(t.string),
  fundCode: Maybe(t.string),
  topUpPremium: Maybe(t.number),
  accountAmount: Maybe(t.number),
  originalAmount: Maybe(t.number),
  topUpInvestment: Maybe(t.number),
  moneySign: Maybe(t.string),
  dataSource: Maybe(t.string)
})

export type TopUpHistoryData = t.TypeOf<typeof TopUpHistoryData>

export const TopUpHistoryResponse = t.type({
  data: Maybe(t.array(TopUpHistoryData))
})

export type TopUpHistoryResponse = t.TypeOf<typeof TopUpHistoryResponse>

export const TopUpHistoryDetail = t.type({
  transNo: Maybe(t.string),
  percentAmount: Maybe(t.number),
  topUpInvestment: Maybe(t.number),
  realUnits: Maybe(t.number),
  effectivePrice: Maybe(t.number),
  nonInvest: Maybe(t.number),
  fundCode: Maybe(t.string)
})

export type TopUpHistoryDetail = t.TypeOf<typeof TopUpHistoryDetail>

export const TopUpHistoryDetailResponse = t.type({
  data: t.array(TopUpHistoryDetail)
})

export type TopUpHistoryDetailResponse = t.TypeOf<typeof TopUpHistoryDetailResponse>

export const FundSwitchingDetail = t.type({
  switchingFundCode: Maybe(t.string),
  sourceFund: Maybe(t.string),
  targetFund: Maybe(t.string),
  sourcePercentAmount: Maybe(t.number),
  switchingAmount: Maybe(t.number),
  realUnits: Maybe(t.number),
  effectivePrice: Maybe(t.number),
  targetPercent: Maybe(t.number)
})
export type FundSwitchingDetail = t.TypeOf<typeof FundSwitchingDetail>

export const FundSwitchingDetailResponse = t.type({
  data: t.array(FundSwitchingDetail)
})

export type FundSwitchingDetailResponse = t.TypeOf<typeof FundSwitchingDetailResponse>

export const PremiumRedirectionHistoryData = t.type({
  transDate: Maybe(t.string),
  transNo: Maybe(t.string),
  code: Maybe(t.string)
})
export type PremiumRedirectionHistoryData = t.TypeOf<typeof PremiumRedirectionHistoryData>

export const PremiumRedirectionHistoryResponse = t.type({
  records: t.array(PremiumRedirectionHistoryData),
  paging: t.type({
    pageIndex: Maybe(t.number),
    itemsPerPage: Maybe(t.number),
    total: Maybe(t.number),
    pages: Maybe(t.number)
  })
})

export type PremiumRedirectionHistoryResponse = t.TypeOf<typeof PremiumRedirectionHistoryResponse>

export const FundValueUnitData = t.type({
  fundCode: Maybe(t.string),
  fundName: Maybe(t.string),
  unitType: Maybe(t.string),
  effDate: Maybe(t.string),
  barePrice: Maybe(t.number),
  bidPrice: Maybe(t.number),
  unitPrice: Maybe(t.number),
  lastUpdatedTimestamp: Maybe(t.string)
})

export type FundValueUnitData = t.TypeOf<typeof FundValueUnitData>

export const FundValueUnitResponse = t.type({
  data: t.array(FundValueUnitData)
})

export type FundValueUnitResponse = t.TypeOf<typeof FundValueUnitResponse>

export const PartialWithdrawHistoryData = t.type({
  fundCode: Maybe(t.string),
  policyNo: Maybe(t.string),
  fundAmount: Maybe(t.number),
  transCode: Maybe(t.string),
  transDate: Maybe(t.string),
  moniesDate: Maybe(t.string),
  priceDate: Maybe(t.string),
  numberOfUnits: Maybe(t.number),
  tranNo: Maybe(t.string),
  effectivePrice: Maybe(t.number),
  contractAmount: Maybe(t.number),
  sacsCode: Maybe(t.string),
  sacsType: Maybe(t.string)
})

export type PartialWithdrawHistoryData = t.TypeOf<typeof PartialWithdrawHistoryData>

export const PartialWithdrawHistoryResponse = t.type({
  data: t.array(PartialWithdrawHistoryData)
})
export type PartialWithdrawHistoryResponse = t.TypeOf<typeof PartialWithdrawHistoryResponse>

export const AccountValueInfoData = t.type({
  fundCode: Maybe(t.string),
  amount: Maybe(t.number)
})

export type AccountValueInfoData = t.TypeOf<typeof AccountValueInfoData>

export const AccountValueInfoResponse = t.type({
  data: t.array(AccountValueInfoData)
})

export type AccountValueInfoResponse = t.TypeOf<typeof AccountValueInfoResponse>

export const AccountValueDetailData = t.type({
  fundCode: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  transCode: Maybe(t.string),
  fundAmount: Maybe(t.number)
})
export type AccountValueDetailData = t.TypeOf<typeof AccountValueDetailData>

export const AccountValueDetailResponse = t.type({
  records: t.array(AccountValueDetailData)
})
export type AccountValueDetailResponse = t.TypeOf<typeof AccountValueDetailResponse>

export const InvestmentDataInfo = t.type({
  investmentSummary: Maybe(InvestmentSummary),
  estimatedValueInfo: Maybe(EstimatedValueInfo),
  partialWithdrawInfo: Maybe(PartialWithdrawInfo),
  partialWithdrawHistory: Maybe(t.array(ACMVQueryData)),
  fundValueInfo: Maybe(ExtraInfoResponse),
  loyaltyBonus: Maybe(t.array(ACMVQueryData)),
  allocatedPremiumInfo: Maybe(t.array(ACMVQueryData)),
  allocationChargeInfo: Maybe(t.array(ACMVQueryData)),
  costOfInsuranceInfo: Maybe(
    t.type({
      ACMVData: Maybe(t.array(ACMVQueryData)),
      listProduct: Maybe(
        t.array(
          t.type({
            code: Maybe(t.string),
            nameVn: Maybe(t.string),
            nameEn: Maybe(t.string)
          })
        )
      )
    })
  ),
  administrationChargeInfo: Maybe(t.array(ACMVQueryData)),
  topUpHistory: Maybe(t.array(TopUpHistoryData)),
  fundSwitchHistory: Maybe(t.array(ACMVQueryData)),
  premiumRedirectionHistory: Maybe(t.array(PremiumRedirectionHistoryData)),
  investmentInterest: Maybe(t.array(ACMVQueryData)),
  accountValue: Maybe(t.array(AccountValueInfoData)),
  extraInfo: Maybe(ExtraInfoResponse)
})
export type InvestmentDataInfo = t.TypeOf<typeof InvestmentDataInfo>

export const JointLife = t.type({
  name: Maybe(
    t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  idNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  dob: Maybe(t.string),
  occupationCode: Maybe(t.string),
  occupationName: Maybe(t.string),
  ageOfParticipation: Maybe(t.number),
  gender: Maybe(t.string),
  currentAge: Maybe(t.number),
  uwDecisionBasic: Maybe(t.string),
  underWritings: Maybe(
    t.array(
      t.type({
        conditionAcceptContent: Maybe(t.string),
        letterComponentId: Maybe(t.number)
      })
    )
  )
})

export type JointLife = t.TypeOf<typeof JointLife>

export const ClientInsuranceInfo = t.type({
  role: Maybe(t.string),
  name: Maybe(
    t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  idNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  dob: Maybe(t.string),
  occupationCode: Maybe(t.string),
  occupationName: Maybe(t.string),
  ageOfParticipation: Maybe(t.number),
  currentAge: Maybe(t.number),
  gender: Maybe(t.string),
  uwDecisionBasic: Maybe(t.string),
  relationship: Maybe(t.string),
  beneficiaryPercentage: Maybe(t.number),
  effectiveDate: Maybe(t.string),
  status: Maybe(t.string),
  beneficiaryCode: Maybe(t.string),
  underWritings: Maybe(
    t.array(
      t.type({
        conditionAcceptContent: Maybe(t.string),
        letterComponentId: Maybe(t.number)
      })
    )
  ),
  lifeNo: Maybe(t.string),
  productCode: Maybe(t.string),
  husband: Maybe(JointLife),
  joinLifeFlag: Maybe(t.string),
  usedTobe: Maybe(t.string)
})

export type ClientInsuranceInfo = t.TypeOf<typeof ClientInsuranceInfo>

export const ClaimPaymentInfo = t.type({
  sacsCode: t.string,
  sacsType: t.string,
  amount: t.number
})

export type ClaimPaymentInfo = t.TypeOf<typeof ClaimPaymentInfo>

export const ProductInfo = t.type({
  componentName: Maybe(t.string),
  productCode: Maybe(t.string),
  coverageStatus: Maybe(t.string),
  premiumStatus: Maybe(t.string),
  coverage: Maybe(t.string),
  rider: Maybe(t.string),
  benefitOption: Maybe(t.string),
  planCode: Maybe(t.string),
  rerateDate: Maybe(t.string),
  sumAssured: Maybe(t.number),
  installmentPremium: Maybe(t.number),
  paidToDate: Maybe(t.string),
  premCessDate: Maybe(t.string),
  covRiskCessDate: Maybe(t.string),
  riskCessDate: Maybe(t.string),
  mortalityClass: Maybe(t.string),
  loadInstallmentPremium: Maybe(t.number),
  policyCessDate: Maybe(t.string)
})

export type ProductInfo = t.TypeOf<typeof ProductInfo>

export const LifeAssuredInfo = t.type({
  lifeAssuredCode: Maybe(t.string),
  lifeAssuredName: Maybe(
    t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  life: Maybe(t.string),
  products: Maybe(t.array(ProductInfo))
})

export type LifeAssuredInfo = t.TypeOf<typeof LifeAssuredInfo>

export const ProductResponse = t.type({
  data: t.type({
    policyNumber: t.string,
    lifeAssureds: Maybe(t.array(LifeAssuredInfo))
  })
})

export type ProductResponse = t.TypeOf<typeof ProductResponse>

export const RelatedDocumentInfo = t.type({
  url: t.string,
  metaData: t.type({
    docid: t.string,
    maindoc: Maybe(t.string),
    subdoc: Maybe(t.string),
    batchno: Maybe(t.string)
  })
})
export type RelatedDocumentInfo = t.TypeOf<typeof RelatedDocumentInfo>

export const ClaimPaymentDetailInfo = t.type({
  transNo: Maybe(t.string),
  transDate: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  accountingAmount: Maybe(t.number),
  glAmount: Maybe(t.number),
  moneySign: Maybe(t.string),
  glCode: Maybe(t.string),
  sacsCode: Maybe(t.string),
  sacsType: Maybe(t.string),
  transCode: Maybe(t.string),
  accNo: Maybe(t.string),
  validFlag: Maybe(t.string)
})
export type ClaimPaymentDetailInfo = t.TypeOf<typeof ClaimPaymentDetailInfo>

export const ClientBenInfo = t.type({
  stt: t.number,
  name: t.string,
  idNumber: t.string,
  clientNumber: t.string,
  dob: t.string,
  gender: t.string,
  relationship: t.string,
  beneficiaryPercentage: t.number,
  effectiveDate: t.string,
  status: t.string,
  beneficiaryCode: t.string,
  usedTobe: t.string
})

export type ClientBenInfo = t.TypeOf<typeof ClientBenInfo>

export const ClientSpecialBenInfo = t.type({
  name: t.string,
  idNumber: t.string,
  clientNumber: t.string,
  dob: t.string,
  gender: t.string,
  status: t.string,
  usedTobe: t.string
})

export type ClientSpecialBenInfo = t.TypeOf<typeof ClientSpecialBenInfo>
export const LoanRepayInquiryData = t.type({
  action: Maybe(t.string),
  status: Maybe(t.string),
  interestAPL: Maybe(t.number),
  interestOPL: Maybe(t.number),
  balanceAPL: Maybe(t.number),
  balanceOPL: Maybe(t.number),
  validFlag: Maybe(t.string),
  maxInterest: Maybe(t.number),
  maxPrinOS: Maybe(t.number)
})
export type LoanRepayInquiryData = t.TypeOf<typeof LoanRepayInquiryData>

export const LoanRepayInquiryResponse = t.type({
  body: LoanRepayInquiryData
})
export type LoanRepayInquiryResponse = t.TypeOf<typeof LoanRepayInquiryResponse>

export const PaidUpInquiryData = t.type({
  existingSumAssured: Maybe(t.number),
  newSumAssured: Maybe(t.number)
})
export type PaidUpInquiryData = t.TypeOf<typeof PaidUpInquiryData>

export const PaidUpInquiryResponse = t.type({
  data: Maybe(PaidUpInquiryData)
})
export type PaidUpInquiryResponse = t.TypeOf<typeof PaidUpInquiryResponse>

export const DraftTransactionItem = t.type({
  createdDate: Maybe(t.string),
  updatedDate: Maybe(t.string),
  updatedBy: Maybe(t.string),
  createdBy: Maybe(t.string),
  transactionType: Maybe(t.string),
  policyNo: Maybe(t.string),
  clientNo: Maybe(t.string),
  id: Maybe(t.string),
  payload: Maybe(
    t.type({
      groupCode: Maybe(t.string),
      typeCode: Maybe(t.string),
      subTypeCode: Maybe(t.string),
      requesterRoleCode: Maybe(t.string),
      requesterFullName: Maybe(t.string),
      relationshipWithPO: Maybe(t.string),
      requesterNationalId: Maybe(t.string),
      method: Maybe(t.string),
      source: Maybe(t.string),
      callId: Maybe(t.string),
      hadFirstContactResolution: Maybe(t.boolean),
      customerComment: Maybe(t.string),
      responseToCustomer: Maybe(t.string),
      isUrgent: Maybe(t.boolean),
      documentUrls: t.array(
        t.type({
          type: Maybe(t.string),
          url: t.string
        })
      ),
      poName: Maybe(t.string),
      callBackLaterHour: Maybe(t.number),
      callbackAppointmentTime: Maybe(t.string)
    })
  ),
  attributesExt: Maybe(
    t.type({
      complainMisMoneyDetails: Maybe(
        t.type({
          list: t.array(InquiryComplaintState.COMPLAINTMISMONEYDETAILS),
          totalComplaintAmount: t.number
        })
      ),
      complainSaleDetails: Maybe(InquiryComplaintState.COMPLAINTSALEDETAILS),
      complaintGADetails: Maybe(InquiryComplaintState.COMPLAINTGADETAILS),
      conservationInfo: Maybe(InquiryComplaintState.CONSERVATIONINFO)
    })
  ),
  updateVersion: Maybe(t.number)
})

export type DraftTransactionItem = t.TypeOf<typeof DraftTransactionItem>

export const DraftTransactionParams = t.type({
  createdDate: Maybe(t.string),
  updatedDate: Maybe(t.string),
  updatedBy: Maybe(t.string),
  createdBy: Maybe(t.string),
  transactionType: Maybe(t.string),
  policyNo: Maybe(t.string),
  clientNo: Maybe(t.string),
  poName: Maybe(t.string),
  id: Maybe(t.string),
  groupCode: Maybe(t.string),
  typeCode: Maybe(t.string),
  subTypeCode: Maybe(t.string),
  requesterRoleCode: Maybe(t.string),
  requesterFullName: Maybe(t.string),
  relationshipWithPO: Maybe(t.string),
  requesterNationalId: Maybe(t.string),
  method: Maybe(t.string),
  source: Maybe(t.string),
  callId: Maybe(t.string),
  hadFirstContactResolution: Maybe(t.boolean),
  customerComment: Maybe(t.string),
  responseToCustomer: Maybe(t.string),
  isUrgent: Maybe(t.boolean),
  updateVersion: Maybe(t.number),
  callBackLaterHour: Maybe(t.number),
  callbackAppointmentTime: Maybe(t.string)
})

export type DraftTransactionParams = t.TypeOf<typeof DraftTransactionParams>

export const DraftTransactionResponse = t.type({
  records: t.array(DraftTransactionItem),
  paging: Maybe(
    t.type({
      pageIndex: t.number,
      itemsPerPage: t.number,
      total: t.number,
      pages: t.number
    })
  )
})
export type DraftTransactionResponse = t.TypeOf<typeof PaidUpInquiryResponse>

export const SaveDraftResponse = t.type({
  data: DraftTransactionItem,
  code: Maybe(t.string),
  message: Maybe(t.string),
  messageVn: Maybe(t.string)
})
export type SaveDraftResponse = t.TypeOf<typeof SaveDraftResponse>

export const ChangePOHistoryData = t.type({
  effectiveDate: Maybe(t.string),
  ownerHistory: Maybe(
    t.array(
      t.type({
        clientNumber: Maybe(t.string),
        clientRole: Maybe(t.string),
        clientName: t.type({
          surName: Maybe(t.string),
          middleName: Maybe(t.string),
          firstName: Maybe(t.string),
          name: Maybe(t.string)
        })
      })
    )
  )
})
export type ChangePOHistoryData = t.TypeOf<typeof ChangePOHistoryData>

export const ChangePOHistoryResponse = t.type({
  data: ChangePOHistoryData
})
export type ChangePOHistoryResponse = t.TypeOf<typeof ChangePOHistoryResponse>
export const OfficeCode = t.type({
  code: t.string,
  nameEn: t.string,
  nameVi: t.string,
  type: t.string,
  status: t.string,
  bankCode: Maybe(t.string),
  gaCode: Maybe(t.string)
})
export type OfficeCode = t.TypeOf<typeof OfficeCode>

export const PolicyNoteWarningData = t.type({
  code: Maybe(t.string),
  fromDate: Maybe(t.string),
  toDate: Maybe(t.string),
  shortDesc: Maybe(t.string),
  longDesc: Maybe(t.array(t.string)),
  lasCreatedUserId: Maybe(t.string),
  lasUpdatedUserId: Maybe(t.string),
  solutions: Maybe(t.array(t.string)),
  status: Maybe(t.string)
})
export type PolicyNoteWarningData = t.TypeOf<typeof PolicyNoteWarningData>

export const PolicyNoteWarningResponse = t.type({
  data: t.array(PolicyNoteWarningData)
})
export type PolicyNoteWarningResponse = t.TypeOf<typeof PolicyNoteWarningResponse>

export const CheckNoteInformationData = t.type({
  hasEmailActuaryForPolAcc: t.boolean,
  hasAmlMessage: t.boolean,
  isUpdatedCustomerInfo: t.boolean,
  isUpdatedPO: t.boolean,
  isHasWarning: t.boolean,
  isHasPolicyNote: t.boolean,
  isHCRWillNotBeRenewed: t.boolean
})
export type CheckNoteInformationData = t.TypeOf<typeof CheckNoteInformationData>

export const CheckNoteInformationResponse = t.type({
  data: CheckNoteInformationData
})
export type CheckNoteInformationResponse = t.TypeOf<typeof CheckNoteInformationResponse>

export const PolicyNoteDetailData = t.type({
  notedContent: Maybe(t.string),
  notedDate: Maybe(t.string),
  notedTime: Maybe(t.string),
  departmentCode: Maybe(t.string),
  lasUserId: Maybe(t.string)
})
export type PolicyNoteDetailData = t.TypeOf<typeof PolicyNoteDetailData>

export const PolicyNoteDetailResponse = t.type({
  data: t.array(PolicyNoteDetailData)
})
export type PolicyNoteDetailResponse = t.TypeOf<typeof PolicyNoteDetailResponse>

export const PaymentListData = t.type({
  reqnNumber: Maybe(t.string),
  type: Maybe(t.string),
  amount: Maybe(t.number),
  verifyDate: Maybe(t.string),
  bankCode: Maybe(t.string),
  authorisedBy: Maybe(t.string),
  verifyUser: Maybe(t.string),
  status: Maybe(t.string),
  authDate: Maybe(t.string),
  desc: Maybe(t.string),
  nationalId: Maybe(t.string),
  idForPayment: Maybe(t.string),
  accountName: Maybe(t.string),
  bankAccount: Maybe(t.string),
  bankKey: Maybe(t.string),
  bankName: Maybe(t.string),
  issDateAndAuthId: Maybe(t.string),
  paymentType: Maybe(t.string),
  returnedDate: Maybe(t.string),
  reason: Maybe(t.string)
})
export type PaymentListData = t.TypeOf<typeof PaymentListData>

export const PaymentListResponse = t.type({
  data: t.array(PaymentListData)
})
export type PaymentListResponse = t.TypeOf<typeof PaymentListData>

export const PaymentInfoDetail = t.type({
  accountingAmount: Maybe(t.number),
  originAmount: Maybe(t.number),
  amountSign: Maybe(t.string),
  seqNo: Maybe(t.string),
  code: Maybe(t.string),
  type: Maybe(t.string),
  tranKey: Maybe(t.string),
  description: Maybe(t.string)
})
export type PaymentInfoDetail = t.TypeOf<typeof PaymentInfoDetail>

export const PaymentInfoDetailResponse = t.type({
  data: t.array(PaymentInfoDetail)
})
export type PaymentInfoDetailResponse = t.TypeOf<typeof PaymentInfoDetailResponse>

export const MasterProductsData = t.type({
  code: Maybe(t.string),
  nameVn: Maybe(t.string),
  nameEn: Maybe(t.string)
})
export type MasterProductsData = t.TypeOf<typeof MasterProductsData>

export const MasterProductsResponse = t.type({
  data: t.array(MasterProductsData)
})

export type MasterProductsResponse = t.TypeOf<typeof MasterProductsResponse>
export const PrurewardAgentData = t.type({
  agentCode: Maybe(t.string),
  agentName: Maybe(
    t.type({
      surName: Maybe(t.string),
      middleName: Maybe(t.string),
      firstName: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  agentPhone: Maybe(t.string),
  agentStatus: Maybe(t.string)
})

export type PrurewardAgentData = t.TypeOf<typeof PrurewardAgentData>

export const PrurewardAgentResponse = t.type({
  data: PrurewardAgentData
})
export type PrurewardAgentResponse = t.TypeOf<typeof PrurewardAgentResponse>

export const PrurewardPointHistoryData = t.type({
  issuer: Maybe(t.string),
  status: Maybe(t.string),
  pointType: Maybe(t.string),
  pointValue: Maybe(t.number),
  pointComment: Maybe(t.string),
  pointRemark: Maybe(t.string),
  pointTransferCreatedAt: Maybe(t.string),
  pointTransferExpiredAt: Maybe(t.string)
})
export type PrurewardPointHistoryData = t.TypeOf<typeof PrurewardPointHistoryData>

export const PrurewardPointHistoryResponse = t.type({
  records: t.array(PrurewardPointHistoryData),
  paging: t.type({
    pageIndex: t.number,
    itemsPerPage: t.number,
    total: t.number,
    pages: t.number
  })
})
export type PrurewardPointHistoryResponse = t.TypeOf<typeof PrurewardPointHistoryResponse>

export const PrurewardTierHistoryData = t.type({
  clientName: t.type({
    surName: Maybe(t.string),
    middleName: Maybe(t.string),
    firstName: Maybe(t.string),
    name: Maybe(t.string)
  }),
  tierHistory: t.array(
    t.type({
      activePoint: Maybe(t.number),
      oldAPE: Maybe(t.number),
      newAPE: Maybe(t.number),
      oldTier: Maybe(t.string),
      newTier: Maybe(t.string),
      createdDate: Maybe(t.string)
    })
  )
})
export type PrurewardTierHistoryData = t.TypeOf<typeof PrurewardTierHistoryData>

export const PrurewardTierHistoryResponse = t.type({
  data: PrurewardTierHistoryData
})
export type PrurewardTierHistoryResponse = t.TypeOf<typeof PrurewardTierHistoryResponse>

export const PrurewardRedeemCodeHistoryData = t.type({
  code: Maybe(t.string),
  providerName: Maybe(t.string),
  dealName: Maybe(t.string),
  codeUsingStatus: Maybe(t.string),
  redeemCodeDate: Maybe(t.string),
  expiredDate: Maybe(t.string),
  channel: Maybe(t.string)
})
export type PrurewardRedeemCodeHistoryData = t.TypeOf<typeof PrurewardRedeemCodeHistoryData>

export const PrurewardRedeemCodeHistoryResponse = t.type({
  records: t.array(PrurewardRedeemCodeHistoryData),
  paging: t.type({
    pageIndex: t.number,
    itemsPerPage: t.number,
    total: t.number,
    pages: t.number
  })
})
export type PrurewardRedeemCodeHistoryResponse = t.TypeOf<typeof PrurewardRedeemCodeHistoryResponse>

export const PrurewardGiftRedemptionHistoryData = t.type({
  clientId: Maybe(t.string),
  transactionId: Maybe(t.string),
  promotionId: Maybe(t.string),
  promotionName: Maybe(t.string),
  providerName: Maybe(t.string),
  promotionCreateDate: Maybe(t.string),
  promotionReceivedDate: Maybe(t.string),
  deliveryStatus: Maybe(t.string),
  deliveryDate: Maybe(t.string),
  deliveryNote: Maybe(t.string)
})
export type PrurewardGiftRedemptionHistoryData = t.TypeOf<typeof PrurewardGiftRedemptionHistoryData>

export const PrurewardGiftRedemptionHistoryResponse = t.type({
  records: t.array(PrurewardGiftRedemptionHistoryData),
  paging: t.type({
    pageIndex: t.number,
    itemsPerPage: t.number,
    total: t.number,
    pages: t.number
  })
})
export type PrurewardGiftRedemptionHistoryResponse = t.TypeOf<typeof PrurewardGiftRedemptionHistoryResponse>

export const PrurewardCustomerNoteData = t.type({
  noteId: Maybe(t.number),
  noteCreatedBy: Maybe(t.string),
  noteCreatedDate: Maybe(t.string),
  noteContent: Maybe(t.string)
})

export type PrurewardCustomerNoteData = t.TypeOf<typeof PrurewardCustomerNoteData>

export const PrurewardCustomerNoteResponse = t.type({
  data: t.array(PrurewardCustomerNoteData)
})

export type PrurewardCustomerNoteResponse = t.TypeOf<typeof PrurewardCustomerNoteResponse>

export const PrurewardCustomerInfoData = t.type({
  clientId: Maybe(t.string),
  clientName: Maybe(t.string),
  vip: Maybe(t.string),
  point: Maybe(t.number),
  expiredPoint: Maybe(t.number),
  usedPoint: Maybe(t.number),
  ape: Maybe(t.number),
  enrolledDate: Maybe(t.string),
  expiredPointInNextMonth: Maybe(t.number),
  expiredPointIn03Month: Maybe(t.number),
  expiredPointIn06Month: Maybe(t.number)
})
export type PrurewardCustomerInfoData = t.TypeOf<typeof PrurewardCustomerInfoData>

export const PrurewardCustomerInfoResponse = t.type({
  data: PrurewardCustomerInfoData
})
export type PrurewardCustomerInfoResponse = t.TypeOf<typeof PrurewardCustomerInfoResponse>

export const PrurewardsSummaryData = t.type({
  clientId: Maybe(t.string),
  point: Maybe(t.number),
  expiredPointInNextMonth: Maybe(t.number),
  codeRedeemSevenDay: Maybe(t.number),
  giftRedeemThirtyDay: Maybe(t.number),
  healthCheckInvitation: Maybe(t.number),
  upDownTier: Maybe(t.string),
  ape: Maybe(t.number)
})

export type PrurewardsSummaryData = t.TypeOf<typeof PrurewardsSummaryData>

export const PrurewardsSummaryResponse = t.type({
  data: PrurewardsSummaryData
})
export type PrurewardsSummaryResponse = t.TypeOf<typeof PrurewardsSummaryResponse>
export const LasTransactionHistoryData = t.type({
  transDate: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  transNo: Maybe(t.string),
  code: Maybe(t.string),
  description: Maybe(t.string),
  descriptionTypeCode: Maybe(t.string),
  validFlag: Maybe(t.string)
})

export type LasTransactionHistoryData = t.TypeOf<typeof LasTransactionHistoryData>

export const LasTransactionHistoryResponse = t.type({
  records: t.array(LasTransactionHistoryData),
  paging: t.type({
    pageIndex: Maybe(t.number),
    itemsPerPage: Maybe(t.number),
    total: Maybe(t.number),
    pages: Maybe(t.number)
  })
})

export type LasTransactionHistoryResponse = t.TypeOf<typeof LasTransactionHistoryResponse>

export const PremiumPaymentHistoryData = t.type({
  proposalNo: Maybe(t.string),
  policyNo: Maybe(t.string),
  transactionDate: Maybe(t.string),
  statusCode: Maybe(t.string),
  paidAmount: Maybe(t.number),
  voucherCode: Maybe(t.string),
  voucherAmount: Maybe(t.number),
  status: Maybe(t.string),
  paymentType: Maybe(t.string),
  refNo: Maybe(t.string),
  policyOwner: Maybe(t.string),
  paymentReason: Maybe(t.string),
  payerName: Maybe(t.string),
  idNumber: Maybe(t.string),
  dob: Maybe(t.string),
  phone: Maybe(t.string),
  email: Maybe(t.string),
  loginId: Maybe(t.string),
  reasonCode: Maybe(t.string),
  transactionId: Maybe(t.string),
  pulseResult: Maybe(t.string)
})

export type PremiumPaymentHistoryData = t.TypeOf<typeof PremiumPaymentHistoryData>

export const PremiumPaymentHistoryResponse = t.type({
  records: t.array(PremiumPaymentHistoryData),
  paging: t.type({
    pageIndex: Maybe(t.number),
    itemsPerPage: Maybe(t.number),
    total: Maybe(t.number)
  })
})
export type PremiumPaymentHistoryResponse = t.TypeOf<typeof PremiumPaymentHistoryResponse>

export const MainProductsData = t.type({
  code: Maybe(t.string),
  nameVn: Maybe(t.string),
  nameEn: Maybe(t.string)
})
export type MainProductsData = t.TypeOf<typeof MainProductsData>

export const MainProductsResponse = t.type({
  data: t.array(MainProductsData)
})
export type MainProductsResponse = t.TypeOf<typeof MainProductsResponse>

export const SearchClaimData = t.type({
  claimNumber: Maybe(t.string),
  processStatus: Maybe(t.string),
  claimTypes: Maybe(t.array(t.string)),
  accidentEventDate: Maybe(t.string),
  criticalIllnessEventDate: Maybe(t.string),
  lifeEventDate: Maybe(t.string),
  disabilityEventDate: Maybe(t.string),
  medicalEventDate: Maybe(t.string),
  registeredDate: Maybe(t.string),
  approvalDate: Maybe(t.string),
  caseCategory: Maybe(t.string),
  faceAmount: Maybe(t.number),
  cancelReason: Maybe(t.string)
})
export type SearchClaimData = t.TypeOf<typeof SearchClaimData>

export const SearchClaimResponse = t.type({
  records: Maybe(t.array(SearchClaimData)),
  paging: Maybe(
    t.type({
      pageIndex: Maybe(t.number),
      itemsPerPage: Maybe(t.number),
      total: Maybe(t.number),
      pages: Maybe(t.number)
    })
  )
})
export type SearchClaimResponse = t.TypeOf<typeof SearchClaimResponse>

export const ClaimListData = t.type({
  claimType: Maybe(t.string),
  confirmedDate: Maybe(t.string),
  occurenceDate: Maybe(t.string),
  cancelReason: Maybe(t.string),
  remark: Maybe(t.string),
  cancelReasonCode: Maybe(t.string)
})
export type ClaimListData = t.TypeOf<typeof ClaimListData>

export const ClaimCaseDetail = t.type({
  claimNumber: Maybe(t.string),
  category: Maybe(t.string),
  processStatus: Maybe(t.string),
  receivedDate: Maybe(t.string),
  registeredDate: Maybe(t.string),
  approvalDate: Maybe(t.string),
  faceAmount: Maybe(t.number),
  cancelReason: Maybe(t.string),
  gaRank: Maybe(t.string),
  confirmedDate: Maybe(t.string),
  docCompletedDate: Maybe(t.string),
  evaluationDate: Maybe(t.string),
  registeredUser: Maybe(t.string),
  confirmedUser: Maybe(t.string),
  evaluationUser: Maybe(t.string),
  approvalUser: Maybe(t.string),
  claimEvents: Maybe(t.array(ClaimListData)),
  medicalClaimAmount: Maybe(t.number)
})
export type ClaimCaseDetail = t.TypeOf<typeof ClaimCaseDetail>

export const MedicalInfoData = t.type({
  claimNumber: Maybe(t.string),
  hospital: Maybe(
    t.type({
      code: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  diagnosises: Maybe(
    t.array(
      t.type({
        code: Maybe(t.string),
        description: Maybe(t.string)
      })
    )
  ),
  surgeryInfos: Maybe(
    t.array(
      t.type({
        surgeryCode: Maybe(t.string),
        surgeryName: Maybe(t.string),
        surgeryDate: Maybe(t.string)
      })
    )
  ),
  remark: Maybe(t.string),
  totalDays: Maybe(t.number),
  treatingDoctor: Maybe(t.string),
  medicalClaimAmount: Maybe(t.number),
  hospitalStartDate: Maybe(t.string),
  hospitalEndDate: Maybe(t.string)
})
export type MedicalInfoData = t.TypeOf<typeof MedicalInfoData>

export const PendingNoticeData = t.type({
  seqNo: Maybe(t.string),
  rank: Maybe(t.string),
  subject: Maybe(t.string),
  requestedBy: Maybe(t.string),
  communicator: Maybe(t.string),
  submitTime: Maybe(t.string),
  closedTime: Maybe(t.string),
  status: Maybe(t.string),
  pendingNoticeId: Maybe(t.number),
  claimNumber: Maybe(t.string),
  policyNumber: Maybe(t.string),
  communicationType: Maybe(t.string),
  dueDate: Maybe(t.string),
  cancelReason: Maybe(t.string),
  pendingCode: Maybe(t.string),
  requiredDocumentDescription: Maybe(t.string),
  supportedDocumentDescription: Maybe(t.string),
  delegatedDocumentDescription: Maybe(t.string),
  solutionCode: Maybe(t.string),
  feedback: Maybe(t.string)
})
export type PendingNoticeData = t.TypeOf<typeof PendingNoticeData>

export const ProductData = t.type({
  coverageNo: Maybe(t.string),
  productCode: Maybe(t.string),
  eventDate: Maybe(t.string),
  rejectReason: Maybe(t.string),
  rescission: Maybe(t.string),
  rescissionDate: Maybe(t.string),
  ifWaivePremiumIndicator: Maybe(t.string),
  waivePremiumStartDate: Maybe(t.string),
  waivePremiumEndDate: Maybe(t.string),
  waivePremiumAmount: Maybe(t.number),
  evaluationStatus: Maybe(t.string),
  relatedCase: Maybe(t.string),
  duplicatedCase: Maybe(t.string)
})
export type ProductData = t.TypeOf<typeof ProductData>

export const PolicyRiderData = t.type({
  policyNumber: Maybe(t.string),
  clientName: Maybe(t.string),
  clientNumber: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  paidToDate: Maybe(t.string),
  policyStatus: Maybe(t.string),
  eventPolicyStatus: Maybe(t.string),
  evaluationStatus: Maybe(t.string),
  installmentPremium: Maybe(t.number),
  sumAssured: Maybe(t.number),
  products: Maybe(t.array(ProductData))
})
export type PolicyRiderData = t.TypeOf<typeof PolicyRiderData>

export const BenefitItemData = t.array(
  t.type({
    benefitCode: Maybe(t.string),
    benefitName: Maybe(t.string),
    policyNumber: Maybe(t.string),
    coverageNo: Maybe(t.string),
    productCode: Maybe(t.string),
    caculatedAmount: Maybe(t.number),
    payableAmount: Maybe(t.number)
  })
)
export type BenefitItemData = t.TypeOf<typeof BenefitItemData>

export const ReceiptsDataList = t.type({
  receiptNumber: Maybe(t.string),
  category: Maybe(t.string),
  type: Maybe(t.string),
  hospital: Maybe(
    t.type({
      code: Maybe(t.string),
      name: Maybe(t.string)
    })
  ),
  startDate: Maybe(t.string),
  endDate: Maybe(t.string),
  receiptExpense: Maybe(t.number),
  receiptPayable: Maybe(t.number),
  procedureCode: Maybe(t.string),
  detailInfos: Maybe(
    t.array(
      t.type({
        duration: Maybe(t.number),
        occurence: Maybe(t.number),
        diagnosis: Maybe(
          t.type({
            code: Maybe(t.string),
            description: Maybe(t.string)
          })
        ),
        procedureCode: Maybe(t.string),
        expenseAmount: Maybe(t.number),
        thirdPartyAmount: Maybe(t.number),
        siAmount: Maybe(t.number),
        claimAmount: Maybe(t.number),
        excludedAmount: Maybe(t.number),
        payableAmount: Maybe(t.number)
      })
    )
  )
})
export type ReceiptsDataList = t.TypeOf<typeof ReceiptsDataList>

export const DocumentsClaimData = t.array(
  t.type({
    code: Maybe(t.string),
    name: Maybe(t.string),
    numberOfCopies: Maybe(t.number),
    firstReceivedDate: Maybe(t.string),
    lastReceivedDate: Maybe(t.string),
    remark: Maybe(t.string),
    image: Maybe(t.string)
  })
)
export type DocumentsClaimData = t.TypeOf<typeof DocumentsClaimData>

export const PayModeData = t.type({
  bankCode: Maybe(t.string),
  bankName: Maybe(t.string),
  recipientName: Maybe(t.string),
  branch: Maybe(t.string),
  bankAccount: Maybe(t.string),
  bankAddress: Maybe(t.string),
  paymentMode: Maybe(t.string),
  idNo: Maybe(t.string),
  issuingDate: Maybe(t.string),
  authoriseOfId: Maybe(t.string),
  plcnum: Maybe(t.string),
  mobilePhone: Maybe(t.string),
  bank: Maybe(t.string)
})
export type PayModeData = t.TypeOf<typeof PayModeData>

export const PaymentAllocationData = t.array(
  t.type({
    payeeName: Maybe(t.string),
    paymentPercentage: Maybe(t.number),
    paymentAmount: Maybe(t.number),
    paymentMode: Maybe(t.string), // C: Cash; K: Cheque; B: Bank Account Transfer; M: Mail
    bankCode: Maybe(t.string),
    account: Maybe(t.string),
    scheduleOption: Maybe(t.string), // L: Lumpsum; I: Instalment
    firstInstallmentDate: Maybe(t.string),
    installmentFrequency: Maybe(t.string),
    totalInstallmentNumber: Maybe(t.string),
    installmentAmount: Maybe(t.number),
    postAddress: Maybe(t.string)
  })
)
export type PaymentAllocationData = t.TypeOf<typeof PaymentAllocationData>

export const CallbackInfo = t.type({
  businessKey: Maybe(t.string),
  processInstanceId: Maybe(t.string),
  policyNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  createdBy: Maybe(t.string),
  poName: Maybe(t.string),
  lastUpdateByVer: Maybe(t.string),
  subTransactionTypeCode: Maybe(t.string),
  groupCode: Maybe(t.string),
  endTime: Maybe(t.string),
  assignee: Maybe(t.string),
  assigneeName: Maybe(t.string),
  status: Maybe(t.string),
  activity: Maybe(t.string),
  createdDate: Maybe(t.string),
  lastUpdateDateVer: Maybe(t.string),
  subServiceTypeCode: Maybe(t.string),
  callbackAppointmentTime: Maybe(t.string),
  poClientNumber: Maybe(t.string),
  cancelPaymentStatus: Maybe(t.string),
  category: Maybe(t.string)
})
export type CallbackInfo = t.TypeOf<typeof CallbackInfo>

export const CallbackAppointmentResponse = t.type({
  records: t.array(CallbackInfo),
  paging: t.type({
    pageIndex: t.number,
    itemsPerPage: t.number,
    total: t.number
  })
})

export type CallbackAppointmentResponse = t.TypeOf<typeof CallbackAppointmentResponse>
export const DocsData = t.type({
  url: Maybe(t.string),
  metaData: t.type({
    batchno: Maybe(t.string),
    subdoc: Maybe(t.string),
    datelastmodified: Maybe(t.string),
    docid: Maybe(t.string),
    maindoc: Maybe(t.string),
    documenttitle: Maybe(t.string),
    datecreated: Maybe(t.string),
    receiptnum: Maybe(t.string),
    taxnum: Maybe(t.string),
    receiptdate: Maybe(t.string),
    receiptamount: Maybe(t.string),
    tr_rb_num: Maybe(t.string)
  })
})
export type DocsData = t.TypeOf<typeof DocsData>

export const DocsResponse = t.type({
  data: t.array(DocsData)
})

export type DocsResponse = t.TypeOf<typeof DocsResponse>

export const DocsClaimAdvanceData = t.type({
  id: Maybe(t.number),
  claimCaseId: Maybe(t.string),
  claimNumber: Maybe(t.string),
  policyId: Maybe(t.string),
  policyNumber: Maybe(t.string),
  riderId: Maybe(t.string),
  riderNumber: Maybe(t.string),
  letterCode: Maybe(t.string),
  letterLogId: Maybe(t.number),
  lastPrintLtrFileLogId: Maybe(t.number),
  approveDate: Maybe(t.string),
  closedDate: Maybe(t.string),
  closedBy: Maybe(t.string),
  paymentAmount: Maybe(t.number),
  batchPrintDate: Maybe(t.string),
  finalPrintDate: Maybe(t.string),
  createdDate: Maybe(t.string),
  createdBy: Maybe(t.string),
  createdByName: Maybe(t.string),
  lastModifiedDate: Maybe(t.string),
  lastModifiedBy: Maybe(t.string),
  lastModifiedByName: Maybe(t.string),
  authorityLimit: Maybe(t.number),
  approver: Maybe(t.string),
  idEncrypt: Maybe(t.string),
  lastPrintLtrFileLogIdEncrypt: Maybe(t.string),
  isVerify: Maybe(t.string),
  isRevert: Maybe(t.string),
  processPriority: Maybe(t.string)
})

export type DocsClaimAdvanceData = t.TypeOf<typeof DocsClaimAdvanceData>

export const DocsClaimAdvanceResponse = t.type({
  data: t.array(DocsClaimAdvanceData)
})

export type DocsClaimAdvanceResponse = t.TypeOf<typeof DocsClaimAdvanceResponse>

export const DocumentsTabsData = t.type({
  ClaimAdvanceDocs: Maybe(t.array(DocsClaimAdvanceData)),
  ClaimInfomationDocs: Maybe(t.array(DocsData)),
  EInvoiceDocs: Maybe(t.array(DocsData)),
  PolicyInfomationDocs: Maybe(t.array(DocsData))
})
export type DocumentsTabsData = t.TypeOf<typeof DocumentsTabsData>

export const ClaimLetterData = t.type({
  letterFile: Maybe(t.string),
  fileType: Maybe(t.string),
  fileName: Maybe(t.string)
})
export type ClaimLetterData = t.TypeOf<typeof ClaimLetterData>

export const ClaimLetterResponse = t.type({
  data: ClaimLetterData
})
export type ClaimLetterResponse = t.TypeOf<typeof ClaimLetterResponse>

export const ExtensionNumberReponse = t.type({
  staffEmail: t.string,
  blackList: Maybe(t.string),
  extensionCode: t.string,
  teamCode: Maybe(t.string)
})
export type ExtensionNumberReponse = t.TypeOf<typeof ExtensionNumberReponse>

export const MakeCallResponse = t.type({
  data: Maybe(
    t.type({
      callId: Maybe(t.string),
      context: Maybe(t.string),
      faultCode: Maybe(t.string)
    })
  ),
  code: Maybe(t.string),
  message: Maybe(t.string)
})
export type MakeCallResponse = t.TypeOf<typeof MakeCallResponse>
export const A15CharacterData = t.type({
  id: Maybe(t.string),
  code: Maybe(t.string),
  nameVi: Maybe(t.string),
  nameEn: Maybe(t.string)
})
export type A15CharacterData = t.TypeOf<typeof A15CharacterData>

export const A15CharacterResponse = t.type({
  data: t.array(A15CharacterData)
})
export type A15CharacterResponse = t.TypeOf<typeof A15CharacterResponse>

export const CustomerBehaviorData = t.type({
  businessKey: Maybe(t.string),
  policyNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  behavior: Maybe(t.string),
  characterCode: Maybe(t.string),
  source: Maybe(t.string),
  createdBy: Maybe(t.string),
  createdDate: Maybe(t.string),
  characterNameVi: Maybe(t.string),
  characterNameEn: Maybe(t.string)
})
export type CustomerBehaviorData = t.TypeOf<typeof CustomerBehaviorData>

export const LastestCustomerBehaviorResponse = t.type({
  data: CustomerBehaviorData
})
export type LastestCustomerBehaviorResponse = t.TypeOf<typeof LastestCustomerBehaviorResponse>

export const LastestHistoryCustomerBehaviorResponse = t.type({
  records: t.array(CustomerBehaviorData),
  paging: t.type({
    pageIndex: Maybe(t.number),
    itemsPerPage: Maybe(t.number),
    total: Maybe(t.number)
  })
})
export type LastestHistoryCustomerBehaviorResponse = t.TypeOf<typeof LastestHistoryCustomerBehaviorResponse>

export const A14S0Data = t.type({ code: Maybe(t.string), nameVi: Maybe(t.string), nameEn: Maybe(t.string) })

export type A14S0Data = t.TypeOf<typeof A14S0Data>

export const A14S0Response = t.type({ data: Maybe(t.array(A14S0Data)) })
export type A14S0Response = t.TypeOf<typeof A14S0Response>

export const A14S1Data = t.type({
  code: Maybe(t.string),
  nameVi: Maybe(t.string),
  nameEn: Maybe(t.string),
  groupCode: Maybe(t.string),
  templateCodeVi: Maybe(t.string)
})

export type A14S1Data = t.TypeOf<typeof A14S1Data>

export const A14S1Response = t.type({ data: Maybe(t.array(A14S1Data)) })
export type A14S1Response = t.TypeOf<typeof A14S1Response>

export const A14S4Data = t.type({
  code: Maybe(t.string),
  subject: Maybe(t.string),
  htmlTemplate: Maybe(t.string)
})

export type A14S4Data = t.TypeOf<typeof A14S4Data>

export const A14S4Response = t.type({ data: t.array(A14S4Data) })
export type A14S4Response = t.TypeOf<typeof A14S4Response>

export const A14S5Data = t.type({
  email: t.string
})

export type A14S5Data = t.TypeOf<typeof A14S5Data>

export const A14S5Response = t.type({ data: Maybe(t.array(A14S5Data)) })
export type A14S5Response = t.TypeOf<typeof A14S5Response>

export const ClickToSendTaskInstanceData = t.type({
  processInstanceId: Maybe(t.string),
  taskId: Maybe(t.string),
  taskCategory: Maybe(t.string),
  assignee: Maybe(t.string),
  policyNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  createdTime: Maybe(t.string),
  processCreatedTime: Maybe(t.string),
  lastUpdatedDate: Maybe(t.string),
  emailTo: Maybe(t.array(t.string)),
  emailBCC: Maybe(t.array(t.string)),
  policyOwnerName: Maybe(t.string),
  status: Maybe(t.string),
  cceGroupCode: Maybe(t.string),
  mailGroupCode: Maybe(t.string),
  createdBy: Maybe(t.string),
  lastUpdatedBy: Maybe(t.string),
  businessKey: Maybe(t.string),
  requestTypeCode: Maybe(t.string),
  categoryCode: Maybe(t.string)
})

export type ClickToSendTaskInstanceData = t.TypeOf<typeof ClickToSendTaskInstanceData>

export const ClickToSendTaskInstanceResponse = t.type({
  data: t.array(ClickToSendTaskInstanceData),
  total: Maybe(t.number),
  start: Maybe(t.number),
  sort: Maybe(t.string),
  order: Maybe(t.string),
  size: Maybe(t.number)
})

export type ClickToSendTaskInstanceResponse = t.TypeOf<typeof ClickToSendTaskInstanceResponse>

const Payload = t.type({
  requestTypeCode: Maybe(t.string),
  subject: Maybe(t.string),
  policyNumber: Maybe(t.string),
  emailTo: Maybe(t.array(t.string)),
  source: Maybe(t.string),
  clientNumber: Maybe(t.string),
  categoryCode: Maybe(t.string),
  content: Maybe(t.string),
  cceGroupCode: Maybe(t.string),
  exchangeId: Maybe(t.string),
  createdBy: Maybe(t.string),
  policyOwnerName: Maybe(t.string),
  payload: Maybe(t.string),
  customerEmail: Maybe(t.string),
  mailGroupCode: Maybe(t.string),
  emailBCC: Maybe(t.array(t.string)),
  note: Maybe(t.string),
  attachDocuments: Maybe(t.array(t.type({ url: t.string, type: t.string, filename: t.string })))
})

export const ClickToSendCaseDetailData = t.type({
  processInstanceId: Maybe(t.string),
  policyNumber: Maybe(t.string),
  clientNumber: Maybe(t.string),
  startTime: Maybe(t.string),
  endTime: Maybe(t.string),
  lastUpdatedDate: Maybe(t.string),
  emailTo: Maybe(t.array(t.string)),
  emailBCC: Maybe(t.array(t.string)),
  policyOwnerName: Maybe(t.string),
  status: Maybe(t.string),
  cceGroupCode: Maybe(t.string),
  mailGroupCode: Maybe(t.string),
  createdBy: Maybe(t.string),
  lastUpdatedBy: Maybe(t.string),
  businessKey: Maybe(t.string),
  requestTypeCode: Maybe(t.string),
  categoryCode: Maybe(t.string),
  reviewEmailDecision: Maybe(t.string),
  reCheckEmailDecision: Maybe(t.string),
  sentEmailTime: Maybe(t.string),
  payload: Maybe(Payload)
})

export type ClickToSendCaseDetailData = t.TypeOf<typeof ClickToSendCaseDetailData>

export const ClickToSendMailHistoryResponse = t.type({
  data: t.array(ClickToSendCaseDetailData),
  total: Maybe(t.number),
  start: Maybe(t.number),
  sort: Maybe(t.string),
  order: Maybe(t.string),
  size: Maybe(t.number)
})

export type ClickToSendMailHistoryResponse = t.TypeOf<typeof ClickToSendMailHistoryResponse>

export const A14S19Response = t.type({
  teamCode: Maybe(t.string),
  managerEmail: Maybe(t.string),
  teamName: Maybe(t.string)
})
export type A14S19Response = t.TypeOf<typeof A14S19Response>

export const PromotionInfo = t.type({
  policyNumber: t.string,
  havePromotion: t.boolean,
  promotions: Maybe(
    t.array(
      t.type({
        code: t.string,
        name: t.string,
        type: t.string,
        description: t.string,
        fromDate: t.string,
        toDate: t.string,
        percent: Maybe(t.string),
        amount: Maybe(t.string),
        maxAmount: Maybe(t.string),
        reinAmount:Maybe(t.string)
      })
    )
  )
})
export type PromotionInfo = t.TypeOf<typeof PromotionInfo>

export const GuideDocumentInfo = t.type({
  categoryCode: t.string,
  transactionType: t.string,
  processDecision: t.string,
  pushCode: t.string,
  pushCodeName: t.string
})
export type GuideDocumentInfo = t.TypeOf<typeof GuideDocumentInfo>
