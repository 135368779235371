export enum Permission {
  // Home Page
  'ViewTaskManagementHomepage' = 'View-TaskManagement-Homepage',
  'ViewSubmissionHomepage' = 'View-Submission-Homepage',

  // 'ViewDigitalInquiryHomepage' = 'View-DigitalInquiry-Homepage',
  'ViewDigitalHomepage' = 'View-Digital-Homepage',
  'ViewBusinessBackOfficeHomepage' = 'View-BusinessBackOffice-Homepage',
  'ViewAMLReleasePolicyHomepage' = 'View-AMLReleasePolicy-Homepage',
  // Menu
  'ViewReportMenu' = 'View-Report-Menu',
  'ViewSettingMenu' = 'View-Setting-Menu',
  'ViewBusinessBackOfficeMenu' = 'View-BusinessBackOffice-Menu',
  'ViewSubmissionFormMenu' = 'View-SubmissionForm-Menu',
  'ViewSubmissionFormPayoutCL' = 'View-SubmissionForm-Payout-ClaimService',
  'ViewCaseInquiryMenu' = 'View-CaseInquiry-Menu',
  'ViewServiceInquiryMenu' = 'View-ServiceInquiry-Menu',
  'ViewErrorHandlerReportMenu' = 'View-ErrorHandlerReport-Menu',
  'CashoutMenu' = 'Cashout-Menu',
  'AccountingMenu' = 'Accounting-Menu',
  // 'ViewPolicyInquiryMenu' = 'View-DigitalInquiry-Menu',
  'ViewDigitalMenu' = 'View-Digital-Menu',
  'ViewVoucherInquiryMenu' = 'View-VoucherInquiry-Menu',
  'ViewTaskListMenu' = 'View-TaskList-Menu',
  'ViewDashboardMenu' = 'View-Dashboard-Menu',
  'ViewContactCenterMenu' = 'View-ContactCenter-Menu',
  'ViewUtilitiesMenu' = 'View-Utilities-Menu',
  'ViewAMLReleasePolicyMenu' = 'View-AMLReleasePolicy-Menu',
  'ViewPCDataImportMenu' = 'View-PC-DataImport-Menu',
  'ViewPCRequestList' = 'View-PC-RequestList-Menu',
  'ViewPCRequestDetail' = 'View-RequestDetail',
  'EditPCRequestDetail' = 'Edit-RequestDetail',
  'CompletePCRequestDetail' = 'Complete-RequestDetail',
  'ViewPCRequestMenu' = 'View-PC-Request-Menu',
  'ViewPVADoNotCall' = 'View-PVADoNotCall',
  'EditPVADoNotCall' = 'Edit-PVADoNotCall',

  'LinkTRRBPDMenu' = 'Link-TRRB-PD-Menu',
  'LinkPRUClaimsMenu' = 'Link-PRUClaims-Menu',
  'LinkPRUGreatMenu' = 'Link-PRUGreat-Menu',
  'LinkPanelDoctorMenu' = 'Link-PanelDoctor-Menu',
  'LinkeClaimsMenu' = 'Link-eClaims-Menu',
  'LinkeKioskMenu' = 'Link-eKiosk-Menu',
  // Team Task
  'ViewOBTeamTask' = 'View-OB-TeamTask',

  'ViewVerificationPSTeamTask' = 'View-Verification-PS-TeamTask',
  'ViewSuspendPSTeamTask' = 'View-Suspend-PS-TeamTask',
  'ViewQCPSTeamTask' = 'View-QC-PS-TeamTask',
  'ViewErrorHandlerPS' = 'View-ErrorHandler-PS-TeamTask',

  'ViewUnderwritingUWTeamTask' = 'View-Underwriting-UW-TeamTask',
  'ViewSuspendUWTeamTask' = 'View-Suspend-UW-TeamTask',
  'ViewQCUWTeamTask' = 'View-QC-UW-TeamTask',
  'ViewQCUWPSTeamTask' = 'View-QC-UW-PS-TeamTask',

  'ViewQCCSTeamTask' = 'View-QC-CS-TeamTask',
  'ViewSuspendCSTeamTask' = 'View-Suspend-CS-TeamTask',
  'ViewVerificationCSTeamTask' = 'View-Verification-CS-TeamTask',

  'ViewQCCHTeamTask' = 'View-QC-CH-TeamTask',
  'ViewVerificationCHTeamTask' = 'View-Verification-CH-TeamTask',
  'ViewSuspendCHTeamTask' = 'View-Suspend-CH-TeamTask',

  'ViewQCDSTeamTask' = 'View-QC-DS-TeamTask',
  'ViewVerificationDSTeamTask' = 'View-Verification-DS-TeamTask',
  'ViewSuspendDSTeamTask' = 'View-Suspend-DS-TeamTask',

  'ViewQCCLTeamTask' = 'View-QC-CL-TeamTask',
  'ViewAdjudicationSuspendCLTeamTask' = 'View-AdjudicationSuspend-CL-TeamTask',
  'ViewAdminCLTeamTask' = 'View-Admin-CL-TeamTask',
  'ViewAdjudicationCLTeamTask' = 'View-Adjudication-CL-TeamTask',
  'ViewPayoutCLTeamTask' = 'View-PayoutVerification-CL-TeamTask',
  'ViewPayoutCLTaskDetail' = 'View-PayoutVerification-TaskDetail',
  'EditPayoutCLTaskDetail' = 'Edit-PayoutVerification-TaskDetail',

  'ViewErrorHandlerTeamTask' = 'View-ErrorHandler-TeamTask',
  'ViewCommentTeamTask' = 'View-Comment-TeamTask',

  'ViewVerificationIDCTeamTask' = 'View-Verification-IDC-TeamTask',
  'ViewQCIDCTeamTask' = 'View-QC-IDC-TeamTask',
  'ViewSuspendIDCTeamTask' = 'View-Suspend-IDC-TeamTask',

  // My Task
  'ViewSuspendPSMyTask' = 'View-Suspend-PS-MyTask',
  'ViewQCPSMyTask' = 'View-QC-PS-MyTask',
  'ViewVerificationPSMyTask' = 'View-Verification-PS-MyTask',
  'ViewQCUWPSMyTask' = 'View-QC-UW-PS-MyTask',

  // 'ViewSuspendDSMyTask' = 'View-Suspend-DS-MyTask',
  'ViewQCDSMyTask' = 'View-QC-DS-MyTask',
  'ViewVerificationDSMyTask' = 'View-Verification-DS-MyTask',

  'ViewSuspendUWMyTask' = 'View-Suspend-UW-MyTask',
  'ViewQCUWMyTask' = 'View-QC-UW-MyTask',
  'ViewUnderwritingUWMyTask' = 'View-Underwriting-UW-MyTask',

  'ViewSuspendCSMyTask' = 'View-Suspend-CS-MyTask',
  'ViewQCCSMyTask' = 'View-QC-CS-MyTask',
  'ViewVerificationCSMyTask' = 'View-Verification-CS-MyTask',

  'ViewSuspendCHMyTask' = 'View-Suspend-CH-MyTask',
  'ViewQCCHMyTask' = 'View-QC-CH-MyTask',
  'ViewVerificationCHMyTask' = 'View-Verification-CH-MyTask',

  'ViewQCCLMyTask' = 'View-QC-CL-MyTask',
  'ViewAdjudicationCLMyTask' = 'View-Adjudication-CL-MyTask',
  'ViewAdjudicationSuspendCLMyTask' = 'View-AdjudicationSuspend-CL-MyTask',
  'ViewAdminCLMyTask' = 'View-Admin-CL-MyTask',
  'ViewPayoutCLMyTask' = 'View-PayoutVerification-CL-MyTask',

  'ViewErrorHandlerMyTask' = 'View-ErrorHandler-MyTask',
  'ViewCommentMyTask' = 'View-Comment-MyTask',

  'ViewVerificationIDCMyTask' = 'View-Verification-IDC-MyTask',
  'ViewSuspendIDCMyTask' = 'View-Suspend-IDC-MyTask',
  'ViewQCIDCMyTask' = 'View-QC-IDC-MyTask',

  // Task List
  'ExportPSTaskList' = 'Export-PS-TaskList',
  'RetryPSTaskList' = 'Retry-PS-TaskList',
  'UnsuspendPSTaskList' = 'Unsuspend-PS-TaskList',
  'TransferPSTaskList' = 'Transfer-PS-TaskList',
  'AssignToMePSTaskList' = 'AssignToMe-PS-TaskList',

  'ExportDSTaskList' = 'Export-DS-TaskList',
  'RetryDSTaskList' = 'Retry-DS-TaskList',
  'UnsuspendDSTaskList' = 'Unsuspend-DS-TaskList',
  'TransferDSTaskList' = 'Transfer-DS-TaskList',
  'AssignToMeDSTaskList' = 'AssignToMe-DS-TaskList',

  'ExportCHTaskList' = 'Export-CH-TaskList',
  'RetryCHTaskList' = 'Retry-CH-TaskList',
  'UnsuspendCHTaskList' = 'Unsuspend-CH-TaskList',
  'TransferCHTaskList' = 'Transfer-CH-TaskList',
  'AssignToMeCHTaskList' = 'AssignToMe-CH-TaskList',

  'ExportCSTaskList' = 'Export-CS-TaskList',
  'RetryCSTaskList' = 'Retry-CS-TaskList',
  'UnsuspendCSTaskList' = 'Unsuspend-CS-TaskList',
  'TransferCSTaskList' = 'Transfer-CS-TaskList',
  'AssignToMeCSTaskList' = 'AssignToMe-CS-TaskList',

  'ExportUWTaskList' = 'Export-UW-TaskList',
  'RetryUWTaskList' = 'Retry-UW-TaskList',
  'UnsuspendUWTaskList' = 'Unsuspend-UW-TaskList',
  'TransferUWTaskList' = 'Transfer-UW-TaskList',
  'AssignToMeUWTaskList' = 'AssignToMe-UW-TaskList',

  'ExportCLTaskList' = 'Export-CL-TaskList',
  'RetryCLTaskList' = 'Retry-CL-TaskList',
  'UnsuspendCLTaskList' = 'Unsuspend-CL-TaskList',
  'TransferCLTaskList' = 'Transfer-CL-TaskList',
  'AssignToMeCLTaskList' = 'AssignToMe-CL-TaskList',

  'ViewDetailTaskList' = 'ViewDetail-TaskList',
  'AddCommentTaskList' = 'Add-Comment-TaskList',

  'AssignToMeIDCTaskList' = 'AssignToMe-IDC-TaskList',
  'TranferIDCTaskList' = 'Tranfer-IDC-TaskList',
  'EndIDCTaskList' = 'End-IDC-TaskList',

  // Cashout
  'CashoutListCashout' = 'Cashout-List-Cashout',
  'PrintPVCashout' = 'Print-PV-Cashout',
  'SearchCashoutList' = 'Search-Cashout-List',
  'ViewcasedetailCashoutList' = 'View-case-detail-Cashout-List',
  'CashoutdetailCashoutList' = 'Cashout-detail-Cashout-List',
  'EditableCashoutdetail' = 'Editable-Cashout-detail',
  'SearchPrintPV' = 'Search-Print-PV',
  'ViewcasedetailPrintPV' = 'View-case-detail-Print-PV',
  'PrintPrintPV' = 'Print-Print-PV',
  'NotePrintPV' = 'Note-Print-PV',
  'CancelPaymentCashoutList' = 'Cancel-Payment-Cashout-List',

  // Accounting
  'CreatenoteAccounting' = 'Create-note-Accounting',
  'UpdatenoteAccounting' = 'Update-note-Accounting',
  'ViewCreatenote' = 'View-Create-note',
  'EditableCreatenote' = 'Editable-Create-note',
  'ViewUpdatenote' = 'View-Update-note',
  'EditableUpdatenote' = 'Editable-Update-note',

  // Task detail
  'ViewRequestInfoPSTaskDetail' = 'View-RequestInfo-PS-TaskDetail',
  'ViewRequestInfoCHTaskDetail' = 'View-RequestInfo-CH-TaskDetail',
  'ViewRequestInfoCSTaskDetail' = 'View-RequestInfo-CS-TaskDetail',
  'ViewRequestInfoIDCTaskDetail' = 'View-RequestInfo-IDC-TaskDetail',

  'ViewQCDSTaskDetail' = 'View-QC-DS-TaskDetail',
  'ViewQCPSTaskDetail' = 'View-QC-PS-TaskDetail',
  'ViewQCUWTaskDetail' = 'View-QC-UW-TaskDetail',
  'ViewQCCHTaskDetail' = 'View-QC-CH-TaskDetail',
  'ViewQCCSTaskDetail' = 'View-QC-CS-TaskDetail',
  'ViewQCCLTaskDetail' = 'View-QC-CL-TaskDetail',
  'ViewQCPCTaskDetail' = 'View-QC-PC-TaskDetail',
  'ViewQCIDCTaskDetail' = 'View-QC-IDC-TaskDetail',

  'ViewQCUWPSTaskDetail' = 'View-QC-UW-PS-TaskDetail',

  'ViewSupplementaryInfoTaskDetail' = 'View-SupplementaryInfo-TaskDetail',
  'ViewSupplementaryInfoIDCTaskDetail' = 'View-SupplementaryInfo-IDC-TaskDetail',
  'ViewRelatedInfoTaskDetail' = 'View-RelatedInfo-TaskDetail',
  'ViewRelatedInfoIDCTaskDetail' = 'View-RelatedInfo-IDC-TaskDetail',
  'ViewErrorlogIDCTaskDetail' = 'View-Errorlog-IDC-TaskDetail',
  'ViewQCAdminCL' = 'View-QC-admin-CL-TaskDetail',

  'ViewClaimInfoTaskDetail' = 'View-ClaimInfo-TaskDetail',
  'ViewInternalSuspendTaskDetail' = 'View-InternalSuspend-TaskDetail',
  'EditInternalSuspendTaskDetail' = 'Edit-InternalSuspend-TaskDetail',

  // Task detail - GeneralInfo
  'ViewGeneralInfoSpecialNotesTaskDetail' = 'View-GeneralInfo-SpecialNotes-TaskDetail',
  'ViewGeneralInfoProductTaskDetail' = 'View-GeneralInfo-Product-TaskDetail',
  'ViewGeneralInfoPolicyValueTaskDetail' = 'View-GeneralInfo-PolicyValue-TaskDetail',
  'ViewGeneralInfoPolicyTaskDetail' = 'View-GeneralInfo-Policy-TaskDetail',
  'ViewGeneralInfoInvestmentTaskDetail' = 'View-GeneralInfo-Investment-TaskDetail',
  'ViewGeneralInfoCustomerTaskDetail' = 'View-GeneralInfo-Customer-TaskDetail',
  'ViewGeneralInfoCoreTransTaskDetail' = 'View-GeneralInfo-CoreTrans-TaskDetail',
  'ViewGeneralInfoAgentTaskDetail' = 'View-GeneralInfo-Agent-TaskDetail',

  'ViewAdminPaymentModeTaskDetail' = 'View-Admin-PaymentMode-TaskDetail',
  'ViewAdminPolicyRiderTaskDetail' = 'View-Admin-PolicyRider-TaskDetail',
  'ViewAdminClaimTypeTaskDetail' = 'View-Admin-ClaimType-TaskDetail',

  'ViewAdjudicationAllocationTaskDetail' = 'View-Adjudication-Allocation-TaskDetail',
  'ViewAdjudicationCalculationTaskDetail' = 'View-Adjudication-Calculation-TaskDetail',
  'ViewAdjudicationFinalDecisionTaskDetail' = 'View-Adjudication-FinalDecision-TaskDetail',
  'ViewAdjudicationFinalLetterTaskDetail' = 'View-Adjudication-FinalLetter-TaskDetail',
  'EditAdjudicationFinalLetterTaskDetail' = 'Edit-Adjudication-FinalLetter-TaskDetail',
  'ViewAdjudicationPaymentModeTaskDetail' = 'View-Adjudication-PaymentMode-TaskDetail',
  'ViewAdjudicationPolicyRiderTaskDetail' = 'View-Adjudication-PolicyRider-TaskDetail',
  'ViewAdjudicationClaimTypeTaskDetail' = 'View-Adjudication-ClaimType-TaskDetail',

  'ViewRelatedInfoCFMLetterTaskDetail' = 'View-RelatedInfo-CFMLetter-TaskDetail',
  'ViewRelatedInfoOnGoingRequestTaskDetail' = 'View-RelatedInfo-OnGoingRequest-TaskDetail',
  'ViewRelatedInfoDocumentTaskDetail' = 'View-RelatedInfo-Document-TaskDetail',
  'ViewRelatedInfoHistoryTaskDetail' = 'View-RelatedInfo-History-TaskDetail',
  'ViewRelatedInfoCommentTaskDetail' = 'View-RelatedInfo-Comment-TaskDetail',
  'EditArchiveToFilenetRelatedInfoDocument' = 'Edit-ArchiveToFilenet-RelatedInfo-Document',

  'ViewGeneralInfoPolicyIDCTaskDetail' = 'View-GeneralInfo-Policy-IDC-TaskDetail',
  'ViewGeneralInfoCustomerIDCTaskDetail' = 'View-GeneralInfo-Customer-IDC-TaskDetail',
  'ViewGeneralInfoProductIDCTaskDetail'= 'View-GeneralInfo-Product-IDC-TaskDetail',
  'ViewGeneralInfoPolicyValueIDCTaskDetail' = 'View-GeneralInfo-PolicyValue-IDC-TaskDetail',
  'ViewGeneralInfoInvestmentIDCTaskDetail' = 'View-GeneralInfo-Investment-IDC-TaskDetail',
  'ViewGeneralInfoAgentIDCTaskDetail' = 'View-GeneralInfo-Agent-IDC-TaskDetail',

  // Policy Detail
  'SearchCoreTransactionHistoryPolicyDetail' = 'Search-CoreTransactionHistory-PolicyDetail',
  'ViewCoreTransactionHistoryPolicyDetail' = 'View-CoreTransactionHistory-PolicyDetail',
  'ViewAgentInfoPolicyDetail' = 'View-AgentInfo-PolicyDetail',
  'ViewInvestmentInfoPolicyDetail' = 'View-InvestmentInfo-PolicyDetail',
  'ViewPolicyValueInfoPolicyDetail' = 'View-PolicyValueInfo-PolicyDetail',
  'ViewProductInfoPolicyDetail' = 'View-ProductInfo-PolicyDetail',
  'ViewCustomerInfoPolicyDetail' = 'View-CustomerInfo-PolicyDetail',
  'ViewSpecialNotesPolicyDetail' = 'View-SpecialNotes-PolicyDetail',
  'ViewPolicyInfoPolicyDetail' = 'View-PolicyInfo-PolicyDetail',

  // Role user Casual labor
  'CasualLabor' = 'Casual-labor',

  // ===================
  // Report
  'ExportSubmissionReport' = 'Export-SubmissionReport',
  'ViewSubmissionReport' = 'View-SubmissionReport',
  'SubmissionReportReports' = 'SubmissionReport-Reports',
  'PaymentReportPayoutReports' = 'Payment-Report-Payout-Reports',
  'ReportManagement' = 'Reports-Management-Reports',
  'ViewErrorHandlerReports' = 'View-ErrorHandler-Reports',
  'SearchErrorHandlerReports' = 'Search-ErrorHandler-Reports',
  'ClaimPayoutReport' = 'Claim-Payout-Report-Reports',
  'ExportBookingReport' = 'Export-Booking-Report-Claim-Payout-Report',
  'ExportPVReport' = 'Export-PV-Report-Claim-Payout-Report',
  'CommunicationTrackingReports' = 'Communication-Tracking-Reports',
  'SearchCommunicationTracking' = 'Search-Communication-Tracking',
  'ExportCommunicationTracking' = 'Export-Communication-Tracking',
  'CheckingPayoutMethodReports' = 'Checking-Payout-Method-Reports',
  'SearchCheckingPayoutMethod' = 'Search-Checking-Payout-Method',
  'ExportCheckingPayoutMethod' = 'Export-Checking-Payout-Method',

  'ViewFunctionListPruRewardsSubmission' = 'View-FunctionList-PruRewards-Submission',
  'ViewFunctionListOthersSubmission' = 'View-FunctionList-Others-Submission',
  'ViewFunctionListPCSubmission' = 'View-FunctionList-PC-Submission',
  'ViewFunctionListClaimsSubmission' = 'View-FunctionList-Claims-Submission',
  'ViewFunctionListCHCSSubmission' = 'View-FunctionList-CHCS-Submission',
  'ViewFunctionListPSSubmission' = 'View-FunctionList-PS-Submission',
  'ViewFuncListAlterJournalFunctionListPS' = 'View-FuncList-AlterJournal-FunctionList-PS',
  'ViewFuncListPayoutJournalFunctionListPS' = 'View-FuncList-PayoutJournal-FunctionList-PS',
  'ViewFunctionListPSjournal' = 'View-FunctionList-PS-journal-Submission',
  'ViewPolicyDetailSubmission' = 'View-PolicyDetail-Submission',
  'ViewRequestCashlessPCRequest' = 'View-RequestCashless-PC-Request',
  'SearchSubmission' = 'Search-Submission',
  'EditLanguageSetting' = 'Edit-Language-Setting',
  'EditAssignmentOnOffSetting' = 'Edit-AssignmentByONOFF-Setting',
  'ViewDetailFunctionFunctionListPruRewards' = 'ViewDetailFunction-FunctionList-PruRewards',
  'SearchFunctionFunctionListPruRewards' = 'SearchFunction-FunctionList-PruRewards',
  'ViewDetailFunctionFunctionListOthers' = 'ViewDetailFunction-FunctionList-Others',
  'SearchFunctionFunctionListOthers' = 'SearchFunction-FunctionList-Others',
  'ViewDetailFunctionFunctionListPC' = 'ViewDetailFunction-FunctionList-PC',
  'SearchFunctionFunctionListPC' = 'SearchFunction-FunctionList-PC',
  'ViewDetailFunctionFunctionListClaims' = 'ViewDetailFunction-FunctionList-Claims',
  'SearchFunctionFunctionListClaims' = 'SearchFunction-FunctionList-Claims',
  'ViewDetailFunctionFunctionListPS' = 'ViewDetailFunction-FunctionList-PS',
  'SearchFunctionFunctionListPS' = 'SearchFunction-FunctionList-PS',
  'EmailAnnualPolicyStatementPolicyDetail' = 'Email-AnnualPolicyStatement-PolicyDetail',
  'DownloadAnnualPolicyStatementPolicyDetail' = 'Download-AnnualPolicyStatement-PolicyDetail',
  'ViewAnnualPolicyStatementPolicyDetail' = 'View-AnnualPolicyStatement-PolicyDetail',
  'View_IFQuotation_PD' = 'View-IFQuotation-PolicyDetail',
  'Edit_IFQuotation_PD' = 'Edit-IFQuotation-PolicyDetail',
  'ViewNoteCaseInquiry' = 'ViewNote-CaseInquiry',
  'ViewStaffInbox' = 'View-Staff-Inbox',
  'CashOutCaseInquiry' = 'CashOut-CaseInquiry',
  'PrintPVCaseInquiry' = 'PrintPV-CaseInquiry',
  'ViewDetailCaseInquiry' = 'ViewDetail-CaseInquiry',
  'SearchCaseInquiry' = 'Search-CaseInquiry',
  'EditUnderwritingUWTaskDetail' = 'Edit-Underwriting-UW-TaskDetail',
  'EndCHTaskDetail' = 'End-CH-TaskDetail',
  'EndCSTaskDetail' = 'End-CS-TaskDetail',
  'EndCLTaskDetail' = 'End-CL-TaskDetail',
  'EndDSTaskDetail' = 'End-DS-TaskDetail',
  'RejectCHTaskDetail' = 'Reject-CH-TaskDetail',
  'RejectCSTaskDetail' = 'Reject-CS-TaskDetail',
  'RejectCLTaskDetail' = 'Reject-CL-TaskDetail',
  'SuspendCHTaskDetail' = 'Suspend-CH-TaskDetail',
  'SuspendCSTaskDetail' = 'Suspend-CS-TaskDetail',
  'SuspendCLTaskDetail' = 'Suspend-CL-TaskDetail',
  'SuspendPCTaskDetail' = 'Suspend-PC-TaskDetail',
  'DeclineCHTaskDetail' = 'Decline-CH-TaskDetail',
  'DeclineCSTaskDetail' = 'Decline-CS-TaskDetail',
  'TransferCHTaskDetail' = 'Transfer-CH-TaskDetail',
  'TransferCSTaskDetail' = 'Transfer-CS-TaskDetail',
  'TransferCLTaskDetail' = 'Transfer-CL-TaskDetail',
  'AcceptCHTaskDetail' = 'Accept-CH-TaskDetail',
  'AcceptCSTaskDetail' = 'Accept-CS-TaskDetail',
  'CompleteCHTaskDetail' = 'Complete-CH-TaskDetail',
  'CompleteCSTaskDetail' = 'Complete-CS-TaskDetail',
  'CompleteCLTaskDetail' = 'Complete-CL-TaskDetail',
  'ViewSuspendCHTaskDetail' = 'View-Suspend-CH-TaskDetail',
  'ViewSuspendCSTaskDetail' = 'View-Suspend-CS-TaskDetail',
  'ViewSuspendUWTaskDetail' = 'View-Suspend-UW-TaskDetail',
  'ViewSuspendPSTaskDetail' = 'View-Suspend-PS-TaskDetail',
  'EditQCCHTaskDetail' = 'Edit-QC-CH-TaskDetail',
  'EditQCCSTaskDetail' = 'Edit-QC-CS-TaskDetail',
  'EditQCUWTaskDetail' = 'Edit-QC-UW-TaskDetail',
  'EditQCPSTaskDetail' = 'Edit-QC-PS-TaskDetail',
  'EditQCCLTaskDetail' = 'Edit-QC-CL-TaskDetail',
  'EditQCAdminCLTaskDetail' = 'Edit-QC-Admin-CL-TaskDetail',

  'EditQCUWPSTaskDetail' = 'Edit-QC-UW-PS-TaskDetail',

  'EditRequestInfoCHTaskDetail' = 'Edit-RequestInfo-CH-TaskDetail',
  'EditRequestInfoCSTaskDetail' = 'Edit-RequestInfo-CS-TaskDetail',

  'EndUWTaskDetail' = 'End-UW-TaskDetail',
  'RejectUWTaskDetail' = 'Reject-UW-TaskDetail',
  'SuspendUWTaskDetail' = 'Suspend-UW-TaskDetail',
  'DeclineUWTaskDetail' = 'Decline-UW-TaskDetail',
  'TransferUWTaskDetail' = 'Transfer-UW-TaskDetail',
  'AcceptUWTaskDetail' = 'Accept-UW-TaskDetail',
  'CompleteUWTaskDetail' = 'Complete-UW-TaskDetail',
  'ViewUnderwritingUWTaskDetail' = 'View-Underwriting-UW-TaskDetail',

  'ViewWorkflowCommentTaskDetail' = 'View-WorkflowComment-TaskDetail',

  'ViewDocumentFileTaskDetail' = 'ViewDocumentFile-TaskDetail',
  'TransferPSTaskDetail' = 'Transfer-PS-TaskDetail',
  'SuspendPSTaskDetail' = 'Suspend-PS-TaskDetail',
  'SearchCoreTransTaskDetail' = 'Search-CoreTrans-TaskDetail',
  'RejectPSTaskDetail' = 'Reject-PS-TaskDetail',
  'EndPSTaskDetail' = 'End-PS-TaskDetail',
  'EditSupplementaryInfoTaskDetail' = 'Edit-SupplementaryInfo-TaskDetail',
  'EditSupplementaryInfoIDCTaskDetail' = 'Edit-SupplementaryInfo-IDC-TaskDetail',
  'EditRequestInfoPSTaskDetail' = 'Edit-RequestInfo-PS-TaskDetail',

  'CompleteDSTaskDetail' = 'Complete-DS-TaskDetail',
  'CompletePSTaskDetail' = 'Complete-PS-TaskDetail',
  'CheckSignatureTaskDetail' = 'CheckSignature-TaskDetail',
  'AddCommentTaskDetail' = 'AddComment-TaskDetail',
  'DeclinePSTaskDetail' = 'Decline-PS-TaskDetail',
  'AcceptPSTaskDetail' = 'Accept-PS-TaskDetail',
  //IDC permission
  'CompleteRequestInfoIDCTaskDetail' = 'Complete-RequestInfo-IDC-TaskDetail',
  'TranferRequestInfoIDCTaskDetail' = 'Tranfer-RequestInfo-IDC-TaskDetail',
  'RejectRequestInfoIDCTaskDetail' = 'Reject-RequestInfo-IDC-TaskDetail',
  'EndRequestInfoIDCTaskDetail' = 'End-RequestInfo-IDC-TaskDetail',
  'SuspendIDCTaskDetail' = 'Suspend-IDC-TaskDetail',
  'AcceptIDCTaskDetail' = 'Accept-IDC-TaskDetail',
  'DeclineIDCTaskDetail' = 'Decline-IDC-TaskDetail',

  'CSCStaff' = 'CSC-Staff',
  'BusinessAdmin' = 'BusinessAdmin',

  // B@P
  'ViewGroupListGroupList' = 'View-GroupList-GroupList',
  'SearchGroupListGroupList' = 'Search-GroupList-GroupList',
  'ImportContractInfoGroupList' = 'Import-ContractInfo-GroupList',
  'ViewGeneralInfoGroupDetail' = 'View-GeneralInfo-GroupDetail',
  'ViewVerGroupDetail' = 'View-Ver-GroupDetail',
  'ViewUnderwritingGroupDetail' = 'View-Underwriting-GroupDetail',
  'ViewPremiumGroupDetail' = 'View-Premium-GroupDetail',
  'ViewIssuanceGroupDetail' = 'View-Issuance-GroupDetail',
  'ViewReportGroupDetail' = 'View-Report-GroupDetail',
  'ViewPSGroupDetail' = 'View-PS-GroupDetail',
  'ViewContractInfoGroupDetailGeneralInfo' = 'View-ContractInfo-GroupDetail-GeneralInfo',
  'EditContractInfoGroupDetailGeneralInfo' = 'Edit-ContractInfo-GroupDetail-GeneralInfo',
  'ImportShareholderGroupDetailGeneralInfo' = 'Import-Shareholder-GroupDetail-GeneralInfo',
  'ViewShareholderGroupDetailGeneralInfo' = 'View-Shareholder-GroupDetail-GeneralInfo',
  'EditShareholderGroupDetailGeneralInfo' = 'Edit-Shareholder-GroupDetail-GeneralInfo',
  'ImportBenefitGroupDetailGeneralInfo' = 'Import-Benefit-GroupDetail-GeneralInfo',
  'ViewBenefitGroupDetailGeneralInfo' = 'View-Benefit-GroupDetail-GeneralInfo',
  'EditBenefitGroupDetailGeneralInfo' = 'Edit-Benefit-GroupDetail-GeneralInfo',
  'ImportEmployeeGroupDetailGeneralInfo' = 'Import-Employee-GroupDetail-GeneralInfo',
  'ViewEmployeeGroupDetailGeneralInfo' = 'View-Employee-GroupDetail-GeneralInfo',
  'EditEmployeeGroupDetailGeneralInfo' = 'Edit-Employee-GroupDetail-GeneralInfo',
  'ViewChecklistVerifyInfoGroupDetailVer' = 'View-ChecklistVerifyInfo-GroupDetail-Ver',
  'EditChecklistVerifyInfoGroupDetailVer' = 'Edit-ChecklistVerifyInfo-GroupDetail-Ver',
  'ViewDecisionGroupDetailVer' = 'View-Decision-GroupDetail-Ver',
  'EditDecisionGroupDetailVer' = 'Edit-Decision-GroupDetail-Ver',
  'ViewUWInfoGroupDetailUnderwriting' = 'View-UWInfo-GroupDetail-Underwriting',
  'EditUWInfoGroupDetailUnderwriting' = 'Edit-UWInfo-GroupDetail-Underwriting',
  'ViewUWDecisionGroupDetailUnderwriting' = 'View-UWDecision-GroupDetail-Underwriting',
  'EditUWDecisionGroupDetailUnderwriting' = 'Edit-UWDecision-GroupDetail-Underwriting',
  'ViewPremiumInfoGroupDetailPremium' = 'View-PremiumInfo-GroupDetail-Premium',
  'EditPremiumInfoGroupDetailPremium' = 'Edit-PremiumInfo-GroupDetail-Premium',
  'ViewIssuanceInfoGroupDetailIssuance' = 'View-IssuanceInfo-GroupDetail-Issuance',
  'UploadHandbookGroupDetailIssuance' = 'Upload-Handbook-GroupDetail-Issuance',
  'ViewHandbookGroupDetailIssuance' = 'View-Handbook-GroupDetail-Issuance',
  'EditIssuanceDecisionGroupDetailIssuance' = 'Edit-IssuanceDecision-GroupDetail-Issuance',
  'ExtractCardInfoGroupDetailIssuance' = 'Extract-CardInfo-GroupDetail-Issuance',
  'ViewReportGroupDetailReport' = 'View-Report-GroupDetail-Report',
  'ExtractReportGroupDetailReport' = 'Extract-Report-GroupDetail-Report',
  'ViewRequestGroupDetailPS' = 'View-Request-GroupDetail-PS',
  'EditRequestVerifyGroupDetailPS' = 'Edit-RequestVerify-GroupDetail-PS',
  'EditRequestDecisionGroupDetailPS' = 'Edit-RequestDecision-GroupDetail-PS',
  'SearchGroupDetailClaim' = 'Search-GroupDetail-Claim',
  'ExtractGroupDetailClaim' = 'Extract-GroupDetail-Claim',
  'ViewClaimDetailsGroupDetailClaim' = 'View-ClaimDetails-GroupDetail-Claim',
  'BusinessPulseReports' = 'BusinessPulse-Reports',

  'AFList_View' = 'View-AFDetail-AFList',
  'AFList_Search' = 'Search-AF-AFList',
  'AFDetail_TGeneral' = 'View-General-AFDetail',
  'AFDetail_TUW' = 'View-UW-AFDetail',
  'AFDetail_TVerify' = 'View-Verify-AFDetail',
  'AFDetail_TPremiumCheck' = 'View-PremiumCheck-AFDetail',
  'AFDetail_GTabPolicy' = 'View-Policy-AFDetail-General',
  'AFDetail_GTabProduct' = 'View-Product-AFDetail-General',
  'AFDetail_GTabEmployee' = 'View-Employee-AFDetail-General',
  'AFDetail_GTabDependent' = 'View-Dependent-AFDetail-General',

  'AFDetail_UW_Edit' = 'Edit-UWInfo-AFDetail-UW',
  'AFDetail_UW_Send_Email' = 'Send-Email-AFDetail-UW',

  'AFDetail_Verify_Edit_PS' = 'Edit-PolicyStatus-AFDetail-Verify',
  'AFDetail_Verify_Edit_VI' = 'Edit-VerifyInfo-AFDetail-Verify',
  'AFDetail_Verify_Send_Email' = 'Send-Email-AFDetail-Verify',
  'AFDetail_PremiumCheck_Edit' = 'Edit-PremiumCheckInfo-AFDetail-PremiumCheck',
  'AFDetail_PremiumCheck_Send_Email' = 'Send-Email-AFDetail-PremiumCheck',

  // 'AFDetail-VerifyView' = 'View-VerifyInfo',
  // 'AFDetail-VerifyUpdatePS' = 'Update-PolicyStatus',
  // 'AFDetail-VerifyUpdateDecision' = 'Update-VerifyDecision',
  // 'AFDetail-VerifyUpdateComment' = 'Update-Comment',
  // 'AFDetail-VerifySendEmail' = 'Send-Email',
  // 'AFDetail-VerifyDecision' = 'Save-VerifyDecision',
  // 'AFDetail-PremiumCheckView' = 'View-PremiumCheckInfo',
  // 'AFDetail-PremiumCheckUpdatePremCheck' = 'Update-PremiumCheck',
  // 'AFDetail-PremiumCheckUpdateCommemt' = 'Update-Comment',
  // 'AFDetail-PremiumCheckSendEmail' = 'Send-Email',
  // 'AFDetail-PremiumCheckSave' = 'Save-PremiumDecision',

  'DI-View-PolicyInfo' = 'View-PolicyInfo-DigitalInquiry-Detail',
  'DI-View-CustomerInfo1' = 'View-CustomerInfo1-DigitalInquiry-Detail',
  'DI-View-CustomerInfo2' = 'View-CustomerInfo2-DigitalInquiry-Detail',
  'DI-View-CustomerInfo3' = 'View-CustomerInfo3-DigitalInquiry-Detail',
  'DI-View-CustomerInfo4' = 'View-CustomerInfo4-DigitalInquiry-Detail',
  'DI-View-CustomerInfo5' = 'View-CustomerInfo5-DigitalInquiry-Detail',
  'DI-Search-All' = 'Search-All-DigitalInquiry',
  'DI-Search-Policy' = 'Search-Policy-DigitalInquiry',
  'DI-Export1' = 'Export1-DigitalInquiry',
  'DI-Export2' = 'Export2-DigitalInquiry',
  'DI-Export3' = 'Export3-DigitalInquiry',
  'DI-Export4' = 'Export4-DigitalInquiry',
  'DI-Export5' = 'Export5-DigitalInquiry',

  'DI-View-Voucher' = 'View-Voucher-VoucherInquiry',
  'DI-Voucher-Export' = 'Export-Voucher-VoucherInquiry',

  'ViewIHeatInquiryUtilities' = 'View-iHeat-Inquiry-Utilities',
  'ViewUploadDocumentUtilities' = 'View-UploadDocument-Utilities',
  'ReportUploadDocumentUtilities' = 'Report-UploadDocument-Utilities',
  'ViewReportUploadDocument' = 'View-Report-UploadDocument',
  'ExportReportUploadDocument' = 'Export-Report-UploadDocument',
  'ViewManualGuidelineUtilities' = 'View-Manual-Guideline-Utilities',
  'UploadManualGuidelineUtilities' = 'Upload-Manual-Guideline-Utilities',
  'ViewMGDetailViewManualGuideline' = 'View-MG-Detail-View-Manual-Guideline',
  'UploadMGDetailUploadManualGuideline' = 'Upload-MG-Detail-Upload-Manual-Guideline',
  'ViewImagingUploadUtilities' = 'View-ImagingUpload-Utilities',

  // Service Inquiry
  'ViewBasicSearchServiceInquiry' = 'View-BasicSearch-ServiceInquiry',
  'ViewAdvancedSearchServiceInquiry' = 'View-AdvancedSearch-ServiceInquiry',
  'ViewClaimSearchServiceInquiry' = 'View-ClaimSearch-ServiceInquiry',
  'ViewDigitalSearchServiceInquiry' = 'View-DigitalClaim-ServiceInquiry',
  'ViewAdminSearchServiceInquiry' = 'View-AdminClaim-ServiceInquiry',
  'ViewTraditionalSearchServiceInquiry' = 'View-TraditionalClaim-ServiceInquiry',
  'ViewDetailServiceInquiry' = 'ViewDetail-ServiceInquiry',
  'ViewNoteServiceInquiry' = 'ViewNote-ServiceInquiry',
  'PrintPVServiceInquiry' = 'PrintPV-ServiceInquiry',
  'CashOutServiceInquiry' = 'CashOut-ServiceInquiry',
  'ExportServiceInquiry' = 'Export-ServiceInquiry',
  'ViewDistributionServicesServiceInquiry' = 'View-DistributionServices-ServiceInquiry',
  'IDC-Query' = 'IDC-Query',
  'View-IndependenceCheck' = 'IndependenceCheck-ServiceInquiry',
  'ViewIndependenceCheckServiceInquiry' = 'View-IndependenceCheck-ServiceInquiry',

  // Error Handler Report
  'ViewErrorHandlerReport' = 'View-ErrorHandlerReport',
  'ViewErrorHandlerReportPC' = 'ErrorHandler-Reports',
  //PC-web
  'ViewGeneralInfoPolicyPC' = 'View-GeneralInfo-Policy-PC-TaskDetail',
  'ViewGeneralInfoCustomerPC' = 'View-GeneralInfo-Customer-PC-TaskDetail',
  'ViewGeneralInfoProductPC' = 'View-GeneralInfo-Product-PC-TaskDetail',
  'ViewGeneralInfoPolicyValuePC' = 'View-GeneralInfo-PolicyValue-PC-TaskDetail',
  'ViewGeneralInfoInvestmentPC' = 'View-GeneralInfo-Investment-PC-TaskDetail',
  'ViewGeneralInfoAgentPC' = 'View-GeneralInfo-Agent-PC-TaskDetail',
  'ViewRequestInfoPCTaskDetail' = 'View-RequestInfo-PC-TaskDetail',
  'TranferRequestInfoPC' = 'Tranfer-RequestInfo-PC-TaskDetail',
  'CompleteRequestInfoPC' = 'Complete-RequestInfo-PC-TaskDetail',
  'RejectRequestInfoPC' = 'Reject-RequestInfo-PC-TaskDetail',
  'EndRequestInfoPC' = 'End-RequestInfo-PC-TaskDetail',
  'ViewSupplementaryInfoPC' = 'View-SupplementaryInfo-PC-TaskDetail',
  'EditSupplementaryInfoPC' = 'Edit-SupplementaryInfo-PC-TaskDetail',
  'UpdateSupplementaryInfoPC' = 'Update-SupplementaryInfo-PC-TaskDetail',
  'ViewRelatedInfoPC' = 'View-RelatedInfo-PC-TaskDetail',
  'ViewErrorlogPC' = 'View-Errorlog-PC-TaskDetail',
  'ViewSuspendWriteOffInfoPC' = 'View-Suspend-WriteOffInfo-PC-TaskDetail',

  'ViewVerificationPCTeamTask' = 'View-Verification-PC-TeamTask',
  'ViewQCPCTeamTask' = 'View-QC-PC-TeamTask',
  'ViewSuspendPCTeamTask' = 'View-Suspend-PC-TeamTask',
  'ViewWriteOffPCTeamTask' = 'View-WriteOff-PC-TeamTask',

  'ViewPremiumCollectionSearch' = 'View-PremiumCollectionSearch-ServiceInquiry',
  'AssignToMePCTaskList' = 'AssignToMe-PC-TaskList',
  'TranferPCTaskList' = 'Transfer-PC-TaskList',
  'EndPCTaskList' = 'End-PC-TaskList',
  'SuspendWriteOffPCTaskList' = 'Suspend-WriteOff-PC-TaskList',
  'UnsuspendPCTaskList' = 'Unsuspend-PC-TaskList',
  'UnsuspendWriteOffPCTaskList' = 'Unsuspend-WriteOff-PC-TaskList',
  'RetryPCTaskList' = 'Retry-PC-TaskList',
  'ExportPCTaskList' = 'Export-PC-TaskList',
  'AcceptPCTaskDetail' = 'Accept-PC-TaskDetail',
  'DeclinePCTaskDetail' = 'Decline-PC-TaskDetail',
  'PremiumCollectionReport' = 'PremiumCollectionReport-Reports',

  'ViewVerificationPCMyTask' = 'View-Verification-PC-MyTask',
  'ViewSuspendPCMyTask' = 'View-Suspend-PC-MyTask',
  'ViewQCPCMyTask' = 'View-QC-PC-MyTask',

  'ViewSubmitFilePCDataImport' = 'View-SubmitFile-PC-DataImport',
  'ViewUploadFileToStoragePCDataImport' = 'View-UploadFileToStorage-PC-DataImport',
  'ViewFailListPCDataImport' = 'View-FailList-PC-DataImport',
  'ViewAmlRequestCustomerServicing' = 'View-AMLRequest-Redflag-CustomerServicing',
  // file report
  // 'ReportManagementReports' = 'Reports-Management-Reports',
  'RawDataReportManagement' = 'RawData-Report-Management',
  'DataConservationReportManagement' = 'DataConservation-Report-Management',
  'IncentiveReportReportManagement' = 'IncentiveReport-Report-Management',
  'AssignmentONOFFReportReportManagement' = 'AssignmentONOFFReport-Report-Management',

  // CEP
  'View360Inquiry' = 'View-360Inquiry-Menu',
  'Search360Inquiry' = 'Search-360Inquiry',
  'ViewPolicyDetail360Inquiry' = 'View-360PolicyDetail-360Inquiry',
  'ViewPolicyInfoGAPolicyDetail' = 'View-360PolicyInfo-GA-360PolicyDetail',
  'ViewPolicyInfoStaffPolicyDetail' = 'View-360PolicyInfo-Staff-360PolicyDetail',
  'ViewCustomerInfoGAPolicyDetail' = 'View-360CustomerInfo-GA-360PolicyDetail',
  'ViewCustomerInfoStaffPolicyDetail' = 'View-360CustomerInfo-Staff-360PolicyDetail',
  'ViewProductInfoGAPolicyDetail' = 'View-360ProductInfo-GA-360PolicyDetail',
  'ViewProductInfoStaffPolicyDetail' = 'View-360ProductInfo-Staff-360PolicyDetail',
  'ViewPolicyValueInfoGAPolicyDetail' = 'View-360PolicyValue-GA-360PolicyDetail',
  'ViewPolicyValueInfoStaffPolicyDetail' = 'View-360PolicyValue-Staff-360PolicyDetail',
  'ViewInvestmentInfoGAPolicyDetail' = 'View-360Investment-GA-360PolicyDetail',
  'ViewInvestmentInfoStaffPolicyDetail' = 'View-360Investment-Staff-360PolicyDetail',
  'ViewAgentInfoGAPolicyDetail' = 'View-360AgentInfo-GA-360PolicyDetail',
  'ViewAgentInfoStaffPolicyDetail' = 'View-360AgentInfo-Staff-360PolicyDetail',
  'ViewIFQuotationPolicyDetail' = 'View-360IFQuotation-360PolicyDetail',
  'View360InquirySubmission' = 'View-360Inquiry-Submission',
  'View360InquiryHomepage' = 'View-360Inquiry-Homepage',
  // CEP-V2
  'ViewPruRewardsInfoPolicyDetail' = 'View-360PruRewards-360PolicyDetail',
  'ViewPruOnlineInfoGAPolicyDetail' = 'View-360PruOnline-GA-360PolicyDetail',
  'ViewPruOnlineInfoStaffPolicyDetail' = 'View-360PruOnline-Staff-360PolicyDetail',
  'ViewPremiumInfoGAPolicyDetail' = 'View-360Premium-GA-360PolicyDetail',
  'ViewPremiumInfoStaffPolicyDetail' = 'View-360Premium-Staff-360PolicyDetail',
  'ViewPaymentInfoGAPolicyDetail' = 'View-360Payment-GA-360PolicyDetail',
  'ViewPaymentInfoStaffPolicyDetail' = 'View-360Payment-Staff-360PolicyDetail',
  'ViewNBUWInfoGAPolicyDetail' = 'View-360NBUW-GA-360PolicyDetail',
  'ViewNBUWInfoStaffPolicyDetail' = 'View-360NBUW-Staff-360PolicyDetail',
  'View360PruRewardsGACSCCS' = 'View-360PruRewards-GACSCCS-360PolicyDetail',
  'ViewUnitLinkFundPrice' = 'View-UnitLinkFundPrice-Utilities',
  'InquiryStaff' = '360Inquiry-Staff',
  'InquiryGA' = '360Inquiry-GA',
  'InquiryCSCSC' = '360Inquiry-CSCSC',

  ///DS - WEB
  'ViewRequestInfoDSTaskDetail' = 'View-RequestInfo-DS-TaskDetail',
  'EditRequestInfoDS' = 'Edit-RequestInfo-DS-TaskDetail',
  'EditQCDS' = 'Edit-QC-DS-TaskDetail',
  'ViewSuspendDS' = 'View-Suspend-DS-TaskDetail',
  'ViewSupplementaryInfoDS' = 'View-SupplementaryInfo-DS-TaskDetail',
  'EditSupplementaryInfoDS' = 'Edit-SupplementaryInfo-DS-TaskDetail',
  'UpdateSupplementaryInfoDS' = 'Update-SupplementaryInfo-DS-TaskDetail',
  'ViewRelatedInfoDS' = 'View-RelatedInfo-DS-TaskDetail',
  'ViewErrorlogDS' = 'View-Errorlog-DS-TaskDetail',
  'TransferDSTaskDetail' = 'Transfer-DS-TaskDetail',
  'DeclineDSTaskDetail' = 'Decline-DS-TaskDetail',
  'SuspendDSTaskDetail' = 'Suspend-DS-TaskDetail',
  'RejectDSTaskDetail' = 'Reject-DS-TaskDetail',
  'AcceptDSTaskDetail' = 'Accept-DS-TaskDetail',
  'ViewAddLackDoc' = 'View-Addlackdoc-DistributionServices',
  'EditAddLackDoc' = 'Edit-Addlackdoc-DistributionServices',
  'ViewDetailFunctionListsDS' = 'ViewDetailFunction-FunctionLists-DS',
  'SearchFunctionListsDS' = 'SearchFunction-FunctionLists-DS',
  'ViewAgentServicingHomepage' = 'View-AgentServicing-Homepage',
  'ViewAgentServicingMenu' = 'View-AgentServicing-Menu',
  'ViewAgentTransfer' = 'View-AgentTransfer-AgentServicing',
  'EditAgentTransfer' = 'Edit-AgentTransfer-AgentServicing',
  'ViewAgentTermination' = 'View-AgentTermination-AgentServicing',
  'EditAgentTermination' = 'Edit-AgentTermination-AgentServicing',
  'ViewAgentMovement' = 'View-AgentMovement-AgentServicing',
  'EditAgentMovement' = 'Edit-AgentMovement-AgentServicing',

  'ViewDistributionServices' = 'View-DS-DistributionServices',
  'ImportAvicadDistributionServices' = 'ImportAvicad-DistributionServices',
  'ViewExportAvicadDistributionServices' = 'View-ExportAvicad-DistributionServices',
  'EditExportAvicadDistributionServices' = 'Edit-ExportAvicad-DistributionServices',
  'ViewSalesStructureDistributionServices' = 'View-SalesStructure-DistributionServices',
  'ExportSalesStructureDistributionServices' = 'Export-SalesStructure-DistributionServices',
  'ViewCandidateInforDistributionServices' = 'View-CandidateInfor-DistributionServices',
  'ExportCandidateInforDistributionServices' = 'Export-CandidateInfor-DistributionServices',

  // DS - Admin
  'ViewDSAdmin' = 'View-AdminFunction-DistributionServices',
  'EditDSAdmin' = 'Edit-AdminFunction-DistributionServices',

  // Seab Billing Change
  'ViewGroupPolicyMenu' = 'View-GroupPolicy-Menu',
  'ViewImportFileForPartialWithdrawMenu' = 'View-ImportFileForPartialWithdraw-Menu',
  'ViewImportFileForBillingChangeMenu' = 'View-ImportFileForBillingChange-Menu',
  'SaveFailDetailBillingChange' = 'Save-FailDetailBillingChange',
  'ViewFailDetailBillingChange' = 'View-FailDetailBillingChange',
  'EditFailDetailBillingChange' = 'Edit-FailDetailBillingChange',
  'UploadDataToSystemFailDetailBillingChange' = 'UploadDataToSystem-FailDetailBillingChange',
  'ViewImportFileImportFileBillingChange' = 'View-ImportFile-ImportFileBillingChange',
  'ImportFileImportFileBillingChange' = 'ImportFile-ImportFileBillingChange',
  'ViewHistoryImportFileBillingChange' = 'View-HistoryImportFileBillingChange',
  'EditFailHistoryImportFileBillingChange' = 'Edit-Fail-HistoryImportFileBillingChange',
  'ExportDataHistoryImportFileBillingChange' = 'Export-Data-HistoryImportFileBillingChange',
  'SearchHistoryImportFileBillingChange' = 'Search-HistoryImportFileBillingChange',
  'ViewHistoryImportBillingChange' = 'View-HistoryImportBillingChange',
  'ExportDataHistoryImportBillingChange' = 'Export-Data-HistoryImportBillingChange',

  // Auto Partial Withdraw
  'ViewFailDetailAutoDebit' = 'View-FailDetailAutoDebit',
  'ViewImportFileImportFileAutoDebit' = 'View-ImportFile-ImportFileAutoDebit',
  'ImportFileImportFileAutoDebit' = 'ImportFile-ImportFileAutoDebit',
  'ViewHistoryImportFileDetailAutoDebit' = 'View-HistoryImportFileDetailAutoDebit',
  'ViewFailHistoryImportFileDetailAutoDebit' = 'View-Fail-HistoryImportFileDetailAutoDebit',
  'ExportHistoryImportFileDetailAutoDebit' = 'Export-HistoryImportFileDetailAutoDebit',
  'SearchHistoryImportFileDetailAutoDebit' = 'Search-HistoryImportFileDetailAutoDebit',
  'ViewHistoryImportFileAutoDebit' = 'View-HistoryImportFileAutoDebit',
  'ExportHistoryImportFileAutoDebit' = 'Export-HistoryImportFileAutoDebit',
  'ViewHistoryRequestFormAutoDebit' = 'View-HistoryRequestFormAutoDebit',
  'ExportPDFHistoryRequestFormAutoDebit' = 'Export-PDF-HistoryRequestFormAutoDebit',

  /// ReportAutoDebitMonthlyRegister && AutoDebitTopupAccountMonthly
  'ViewReportAutoDebitMonthlyRegister' = 'View-Report-AutoDebitMonthlyRegister',
  'SearchReportAutoDebitMonthlyRegister' = 'Search-Report-AutoDebitMonthlyRegister',
  'ExportReportAutoDebitMonthlyRegister' = 'Export-Report-AutoDebitMonthlyRegister',
  'ViewReportAutoDebitTopupAccountMonthly' = 'View-Report-AutoDebitTopupAccountMonthly',
  'SearchReportAutoDebitTopupAccountMonthly' = 'Search-ReportAutoDebitTopupAccountMonthly',
  'ExportReportAutoDebitTopupAccountMonthly' = 'Export-ReportAutoDebitTopupAccountMonthly',

  //Outbound
  'OutboundHomepage' = 'Outbound-Homepage',
  'ViewTaskListOBMenu' = 'View-TaskList-OB-Menu',
  'ViewOutboundMenu' = 'View-Outbound-Menu',
  'ViewOBServiceInquiryMenu' = 'View-OB-ServiceInquiry-Menu',
  'ViewReportOBMenu' = 'View-Report-OB-Menu',

  'ViewVerificationOBTeamTask' = 'View-Verification-OB-TeamTask',
  'ViewQCOBTeamTask' = 'View-QC-OB-TeamTask',
  'ViewQAOBTeamTask' = 'View-QA-OB-TeamTask',
  'ViewErrorHandlerOBTeamTask' = 'View-ErrorHandler-OB-TeamTask',
  'ViewSuspendOBTeamTask' = 'View-Suspend-OB-TeamTask',
  'ViewReQCOBTeamTask' = 'View-Re-QC-OB-TeamTask',

  'ViewVerificationOBMyTask' = 'View-Verification-OB-MyTask',
  'ViewQCOBMyTask' = 'View-QC-OB-MyTask',
  'ViewQAOBMyTask' = 'View-QA-OB-MyTask',
  'ViewReQCOBMyTask' = 'View-Re-QC-OB-MyTask',

  'ViewDetailOBTaskList' = 'ViewDetail-OB-TaskList',
  'AssignToMeOBTaskList' = 'AssignToMe-OB-TaskList',
  'TransferOBTaskList' = 'Transfer-OB-TaskList',
  'EndOBTaskList' = 'End-OB-TaskList',

  'ViewGeneralInfoPolicyOBTaskDetail' = 'View-GeneralInfo-Policy-OB-TaskDetail',
  'ViewGeneralInfoPolicyOwnerOBTaskDetail' = 'View-GeneralInfo-PolicyOwner-OB-TaskDetail',
  'ViewGeneralInfoLifeAssuredOBTaskDetail' = 'View-GeneralInfo-LifeAssured-OB-TaskDetail',
  'ViewGeneralInfoAgentOBTaskDetail' = 'View-GeneralInfo-Agent-OB-TaskDetail',
  'ViewGeneralInfoSpecialOBTaskDetail' = 'View-GeneralInfo-Special-OB-TaskDetail',
  'ViewCallOutOBTaskDetail' = 'View-CallOut-OB-TaskDetail',
  'ViewCallingResultDetailsOBTaskDetail' = 'View-CallingResultDetails-OB-TaskDetail',
  'ViewActionHistoryOBTaskDetail' = 'View-ActionHistory-OB-TaskDetail',
  'ViewRelatedInfoCommentOBTaskDetail' = 'View-RelatedInfo-Comment-OB-TaskDetail',
  'ViewRelatedInfoHistoryOBTaskDetail' = 'View-RelatedInfo-History-OB-TaskDetail',
  'ViewRelatedInfoDocumentOBTaskDetail' = 'View-RelatedInfo-Document-OB-TaskDetail',
  'ViewRelatedInfoGoingRequestOBTaskDetail' = 'View-RelatedInfo-GoingRequest-OB-TaskDetail',
  'ViewInternalSuspendOBTaskDetail' = 'View-InternalSuspend-OB-TaskDetail',
  'ViewCustomerAnswerOBTaskDetail' = 'View-CustomerAnswer-OB-TaskDetail',
  'ViewPromotionInfoOBTaskDetail' = 'View-PromotionInfo-OB-TaskDetail',

  'EditCallOutOBTaskDetail' = 'Edit-CallOut-OB-TaskDetail',
  'EditCallingResultDetailsOBTaskDetail' = 'Edit-CallingResultDetails-OB-TaskDetail',
  'EditInternalSuspendOBTaskDetail' = 'Edit-InternalSuspend-OB-TaskDetail',
  'EditOBQCTaskDetail' = 'Edit-OB-QC-TaskDetail',
  'EditOBQATaskDetail' = 'Edit-OB-QA-TaskDetail',
  'SubmitOBTaskDetail' = 'Submit-OB-TaskDetail',
  'TransferOBTaskDetail' = 'Transfer-OB-TaskDetail',
  'SuspendOBTaskDetail' = 'Suspend-OB-TaskDetail',
  'UnSuspendOBTaskDetail' = 'UnSuspend-OB-TaskDetail',
  'SaveOBTaskDetail' = 'Save-OB-TaskDetail',
  'CompleteOBTaskDetail' = 'Complete-OB-TaskDetail',
  'AcceptOBTaskDetail' = 'Accept-OB-TaskDetail',
  'RejectOBTaskDetail' = 'Reject-OB-TaskDetail',
  'ResendSMSOBTaskDetail' = 'ResendSMS-OB-TaskDetail',

  'ViewBasicSearchOBServiceInquiry' = 'View-BasicSearch-OB-ServiceInquiry',
  'ViewAdvancedSearchOBServiceInquiry' = 'View-AdvancedSearch-OB-ServiceInquiry',
  'ExportOBServiceInquiry' = 'Export-OB-ServiceInquiry',
  'ViewDetailcaseOBServiceInquiry' = 'ViewDetailcase-OB-ServiceInquiry',

  'EditAssignmentByONOFFOBSetting' = 'Edit-AssignmentByONOFF-OB-Setting',
  'EditConfigOBSetting' = 'Edit-ConfigOB-Setting',
  'EditAssignmentByONOFFLeaderOBSetting' = 'Edit-AssignmentByONOFF-Leader-OB-Setting',
  'RecordingOBTaskDetail' = 'Recording-OB-TaskDetail',

  'ViewImportOBMenu' = 'View-Import-OB-Menu',
  'ViewImportFileAdhoc' = 'View-ImportFileAdhoc',
  'ImportImportFileAdhoc' = 'Import-ImportFileAdhoc',
  'ViewFileImportHistoryAdhoc' = 'View-FileImportHistoryAdhoc',
  'SearchFileImportHistoryAdhoc' = 'Search-FileImportHistoryAdhoc',
  'ExportFileImportHistoryAdhoc' = 'Export-FileImportHistoryAdhoc',
  'DownloadFileDetailFileImportHistoryAdhoc' = 'Download-FileDetail-FileImportHistoryAdhoc',
  'ViewDetailedFileImportHistoryAdhoc' = 'View-DetailedFileImportHistoryAdhoc',
  'SearchDetailedFileImportHistoryAdhoc' = 'Search-DetailedFileImportHistoryAdhoc',
  'GetcaseOBTaskList' = 'Getcase-OB-TaskList',
  'EditReQCOBTaskDetail' = 'Edit-Re-QC-OB-TaskDetail',
  'ViewImportFilePromotion' = 'View-ImportFilePromotion',
  'ImportImportFilePromotion' = 'Import-ImportFilePromotion',
  'ViewExportPromotionData' = 'View-ExportPromotionData',
  'ExportExportPromotionData' = 'Export-ExportPromotionData',
  'AdminToolOBSetting' = 'AdminTool-OB-Setting',
  'AdminToolIBSetting' = 'AdminTool-IB-Setting',
  'T4S7MaxCaseAssignmentAdminTool' = 'T4S7-MaxCaseAssignment-AdminTool',
  'T4S14ManagerOfTeamAdminTool' = 'T4S14-ManagerOfTeam-AdminTool',
  'T4S15OutboundStaffListAdminTool' = 'T4S15-Outbound-StaffList-AdminTool',
  'T4S17SetupTransferTransactionAdminTool' = 'T4S17-SetupTransferTransaction-AdminTool',
  'T4S18InboundStaffListAdminTool' = 'T4S18-Inbound-StaffList-AdminTool',
  'A2S3AnswerListAdminTool'='A2S3-AnswerList-AdminTool',
  'A2S1A2S2QuesListAnsOfQuesListAdminTool' = 'A2S1-A2S2-QuesList-AnsOfQuesList-AdminTool',
  
  'ViewT4S7MaxCaseAssignment' = 'View-T4S7-MaxCaseAssignment',
  'UpdateT4S7MaxCaseAssignment' = 'Update-T4S7-MaxCaseAssignment',

  'ViewT4S14ManagerOfTeam' = 'View-T4S14-ManagerOfTeam',
  'UpdateT4S14ManagerOfTeam' = 'Update-T4S14-ManagerOfTeam',

  'ViewT4S15OutboundStaffList' = 'View-T4S15-Outbound-StaffList',
  'UpdateT4S15OutboundStaffList' = 'Update-T4S15-Outbound-StaffList',
  'CreateNewT4S15OutboundStaffList' = 'CreateNew-T4S15-Outbound-StaffList',

  'ViewT4S17SetupTransferTransaction' = 'View-T4S17-SetupTransferTransaction',
  'UpdateT4S17SetupTransferTransaction' = 'Update-T4S17-SetupTransferTransaction',
  'CreateNewT4S17SetupTransferTransaction' = 'CreateNew-T4S17-SetupTransferTransaction',

  'ViewT4S18InboundStaffList' = 'View-T4S18-Inbound-StaffList',
  'UpdateT4S18InboundStaffList' = 'Update-T4S18-Inbound-StaffList',
  'CreateNewT4S18InboundStaffList' = 'CreateNew-T4S18-Inbound-StaffList',

  // IB
  'ViewInboundHomepage' = 'Inbound-Homepage',
  'ViewContactCenterInquiryIBMenu' = 'View-ContactCenterInquiry-IB-Menu',
  'ViewIBPolicyListTab' = 'View-IB-PolicyList',
  'ViewIBMasterTab' = 'View-IB-Master',
  'ViewIBTransactionTab' = 'View-IB-Transaction',
  'ViewIBPruRewardsTab' = 'View-IB-PruRewards',
  'ViewIBPromotionInfoPolicyDetail' = 'View-PromotionInfo-IB-PolicyDetail',
  'ViewIBPolicyInfoPolicyDetail' = 'View-PolicyInfo-IB-PolicyDetail',
  'ViewIBInvestmentInfoPolicyDetail' = 'View-InvestmentInfo-IB-PolicyDetail',
  'ViewIBAgentInfoPolicyDetail' = 'View-AgentInfo-IB-PolicyDetail',
  'ViewIBInsuranceBenefitInfoPolicyDetail' = 'View-InsuranceBenefitInfo-IB-PolicyDetail',
  'ViewIBNBUWInfoPolicyDetail' = 'View-NBUWInfo-IB-PolicyDetail',
  'ViewIBPaymentInfoPolicyDetail' = 'View-PaymentInfo-IB-PolicyDetail',
  'ViewIBComplainInquiryPopup' = 'View-IB-ComplainInquiryPopup',
  'EditIBComplainInquiryPopup' = 'Edit-IB-ComplainInquiryPopup',
  'SearchIBContactCenterInquiry' = 'Search-IB-ContactCenterInquiry',
  'ViewDraftTransactionMenu' = 'View-DraftTransaction-Menu',
  'SearchTransactionDraftTransaction' = 'SearchTransaction-DraftTransaction',
  'ViewDraftTransactionDetail' = 'View-DraftTransactionDetail',
  'SaveDraftTransactionDetail' = 'Save-DraftTransactionDetail',
  'SubmitDraftTransactionDetail' = 'Submit-DraftTransactionDetail',
  'CancelDraftTransactionDetail' = 'Cancel-DraftTransactionDetail',
  'IBViewInfobipReportMenu' = 'View-InfobipReport-Menu',
  'ViewIBChangeContactInfo' = 'View-ChangeContactInfoPopup',
  'IBViewCallRecordingMenu' = 'View-CallRecording-Menu',
  'ViewCallbackAppointmentMenu' = 'View-CallbackAppointment-Menu',
  'ViewIBDocumentPolicyDetail' = 'View-Document-IB-PolicyDetail',
  'ViewIBCustomerBehaviorPopup' = 'View-CustomerBehaviorPopup',
  'EditIBBehaviorCustomerBehaviorPopup' = 'Edit-Behavior-CustomerBehaviorPopup',
  'EditIBCharacterCustomerBehaviorPopup' = 'Edit-Character-CustomerBehaviorPopup',
  'UpdateIBCustomerBehaviorPopup' = 'Update-CustomerBehaviorPopup',
  'EmailManagementMenu' = 'EmailManagement-Menu',
  'IBViewEmailPopup' = 'View-EmailPopup',
  'IBSubmitEmailPopup' = 'Submit-EmailPopup',
  'IBClearAllEmailPopup' = 'ClearAll-EmailPopup',
  'IBReturnOriginalEmailPopup' = 'ReturnOriginalEmail-EmailPopup',
  'IBViewReturnedEmailList' = 'View-Returned-EmailList',
  'IBViewPendingApprovalEmailList' = 'View-PendingApproval-EmailList',
  'IBViewHistoryEmailList' = 'View-History-EmailList',
  'IBViewReturnedEmailDetail' = 'View-ReturnedEmailDetail',
  'IBReturnOriginalEmailReturnedEmailDetail' = 'ReturnOriginalEmail-ReturnedEmailDetail',
  'IBCancelReturnedEmailDetail' = 'Cancel-ReturnedEmailDetail',
  'IBSubmitReturnedEmailDetail' = 'Submit-ReturnedEmailDetail',
  'IBViewApprovalEmailDetail' = 'View-ApprovalEmailDetail',
  'IBApproveApprovalEmailDetail' = 'Approve-ApprovalEmailDetail',
  'IBReturnToSenderApprovalEmailDetail' = 'ReturnToSender-ApprovalEmailDetail',
  'IBCancelApprovalEmailDetail' = 'Cancel-ApprovalEmailDetail',
  'IBReturnOriginalEmailApprovalEmailDetail' = 'ReturnOriginalEmail-ApprovalEmailDetail',
  'IBViewZNSPopup' = 'View-ZNSPopup',
  'IBSendZNSPopup' = 'Send-ZNSPopup'
}

export const HomePagePermissions = {
  ViewSubmissionHomepage: Permission.ViewSubmissionHomepage,
  // ViewDigitalInquiryHomepage: Permission.ViewDigitalInquiryHomepage,
  ViewDigitalHomepage: Permission.ViewDigitalHomepage,
  ViewTaskManagementHomepage: Permission.ViewTaskManagementHomepage,
  ViewBusinessBackOfficeHomepage: Permission.ViewBusinessBackOfficeHomepage,
  ViewAMLReleasePolicyHomepage: Permission.ViewAMLReleasePolicyHomepage,
  View360InquiryHomepage: Permission.View360InquiryHomepage,
  ViewAgentServicingHomepage: Permission.ViewAgentServicingHomepage,
  OutboundHomepage: Permission.OutboundHomepage,
  ViewInboundHomepage: Permission.ViewInboundHomepage
}

export const MenuPermissions = {
  ViewReportMenu: Permission.ViewReportMenu,
  ViewSettingMenu: Permission.ViewSettingMenu,
  ViewBusinessBackOfficeMenu: Permission.ViewBusinessBackOfficeMenu,
  ViewSubmissionFormMenu: Permission.ViewSubmissionFormMenu,
  ViewCaseInquiryMenu: Permission.ViewCaseInquiryMenu,
  ViewServiceInquiryMenu: Permission.ViewServiceInquiryMenu,
  ViewErrorHandlerReportMenu: Permission.ViewErrorHandlerReportMenu,
  ViewAMLReleasePolicyMenu: Permission.ViewAMLReleasePolicyMenu,
  // ViewPolicyInquiryMenu: Permission.ViewPolicyInquiryMenu,
  ViewDigitalMenu: Permission.ViewDigitalMenu,
  ViewVoucherInquiryMenu: Permission.ViewVoucherInquiryMenu,
  ViewTaskListMenu: Permission.ViewTaskListMenu,
  ViewDashboardMenu: Permission.ViewDashboardMenu,
  ViewContactCenterMenu: Permission.ViewContactCenterMenu,
  ViewUtilitiesMenu: Permission.ViewUtilitiesMenu,
  LinkTRRBPDMenu: Permission.LinkTRRBPDMenu,
  LinkPRUClaimsMenu: Permission.LinkPRUClaimsMenu,
  LinkPRUGreatMenu: Permission.LinkPRUGreatMenu,
  LinkPanelDoctorMenu: Permission.LinkPanelDoctorMenu,
  LinkeClaimsMenu: Permission.LinkeClaimsMenu,
  LinkeKioskMenu: Permission.LinkeKioskMenu,
  CashoutMenu: Permission.CashoutMenu,
  View360Inquiry: Permission.View360Inquiry,
  ViewPVADoNotCall: Permission.ViewPVADoNotCall,
  ViewDistributionServices: Permission.ViewDistributionServices,
  ViewAgentSubmission: Permission.ViewAgentServicingMenu,
  ViewPCDataImport: Permission.ViewPCDataImportMenu,
  ViewRequestListPCMenu: Permission.ViewPCRequestList,
  ViewRequestPCMenu: Permission.ViewPCRequestMenu,
  AccountingMenu: Permission.AccountingMenu,
  ViewGroupPolicyMenu: Permission.ViewGroupPolicyMenu,
  ViewTaskListOBMenu: Permission.ViewTaskListOBMenu,
  ViewOBServiceInquiryMenu: Permission.ViewOBServiceInquiryMenu,
  ViewReportOBMenu: Permission.ViewReportOBMenu,
  ViewContactCenterInquiryIBMenu: Permission.ViewContactCenterInquiryIBMenu,
  ViewImportOBMenu: Permission.ViewImportOBMenu,
  ViewDraftTransactionMenu: Permission.ViewDraftTransactionMenu,
  IBViewInfobipReportMenu: Permission.IBViewInfobipReportMenu,
  IBViewCallRecordingMenu: Permission.IBViewCallRecordingMenu,
  ViewCallbackAppointmentMenu: Permission.ViewCallbackAppointmentMenu,
  EmailManagementMenu: Permission.EmailManagementMenu
}

export const TeamTaskPermissions = {
  PS: {
    ViewVerificationPSTeamTask: Permission.ViewVerificationPSTeamTask,
    ViewSuspendPSTeamTask: Permission.ViewSuspendPSTeamTask,
    ViewQCPSTeamTask: Permission.ViewQCPSTeamTask,
    ViewErrorHandlerPS: Permission.ViewErrorHandlerPS
  },
  DS: {
    ViewQCDSTeamTask: Permission.ViewQCDSTeamTask,
    ViewVerificationDSTeamTask: Permission.ViewVerificationDSTeamTask,
    ViewSuspendDSTeamTask: Permission.ViewSuspendDSTeamTask
  },
  // DS: {
  //   ViewVerificationPSTeamTask: Permission.ViewVerificationPSTeamTask,
  //   ViewSuspendPSTeamTask: Permission.ViewSuspendPSTeamTask,
  //   ViewQCPSTeamTask: Permission.ViewQCPSTeamTask
  // },

  UW: {
    ViewUnderwritingUWTeamTask: Permission.ViewUnderwritingUWTeamTask,
    ViewSuspendUWTeamTask: Permission.ViewSuspendUWTeamTask,
    ViewQCUWTeamTask: Permission.ViewQCUWTeamTask,
    ViewQCUWPSTeamTask: Permission.ViewQCUWPSTeamTask
  },

  CS: {
    ViewQCCSTeamTask: Permission.ViewQCCSTeamTask,
    ViewSuspendCSTeamTask: Permission.ViewSuspendCSTeamTask,
    ViewVerificationCSTeamTask: Permission.ViewVerificationCSTeamTask
  },

  CH: {
    ViewQCCHTeamTask: Permission.ViewQCCHTeamTask,
    ViewVerificationCHTeamTask: Permission.ViewVerificationCHTeamTask,
    ViewSuspendCHTeamTask: Permission.ViewSuspendCHTeamTask
  },

  CL: {
    ViewQCCLTeamTask: Permission.ViewQCCLTeamTask,
    ViewAdjudicationSuspendCLTeamTask: Permission.ViewAdjudicationSuspendCLTeamTask,
    ViewAdminCLTeamTask: Permission.ViewAdminCLTeamTask,
    ViewAdjudicationCLTeamTask: Permission.ViewAdjudicationCLTeamTask,
    ViewPayoutCLTeamTask: Permission.ViewPayoutCLTeamTask
  },

  EH: {},

  ViewErrorHandlerTeamTask: Permission.ViewErrorHandlerTeamTask,

  PC: {
    ViewVerificationPCTeamTask: Permission.ViewVerificationPCTeamTask,
    ViewQCPCTeamTask: Permission.ViewQCPCTeamTask,
    ViewSuspendPCTeamTask: Permission.ViewSuspendPCTeamTask,
    ViewWriteOffPCTeamTask: Permission.ViewWriteOffPCTeamTask
  },
  IDC: {
    ViewVerificationIDCTeamTask: Permission.ViewVerificationIDCTeamTask,
    ViewQCIDCTeamTask: Permission.ViewQCIDCTeamTask,
    ViewSuspendIDCTeamTask: Permission.ViewSuspendIDCTeamTask
  }
}

export const MyTaskPermissions = {
  PS: {
    ViewSuspendPSMyTask: Permission.ViewSuspendPSMyTask,
    ViewQCPSMyTask: Permission.ViewQCPSMyTask,
    ViewVerificationPSMyTask: Permission.ViewVerificationPSMyTask
  },
  // DS: {
  //   ViewSuspendMyTask: Permission.ViewSuspendPSMyTask,
  //   ViewQCPSMyTask: Permission.ViewQCPSMyTask,
  //   ViewVerificationPSMyTask: Permission.ViewVerificationPSMyTask
  // },
  DS: {
    // ViewSuspendDSMyTask: Permission.ViewSuspendDSMyTask,
    ViewQCDSMyTask: Permission.ViewQCDSMyTask,
    ViewVerificationDSMyTask: Permission.ViewVerificationDSMyTask
  },

  UW: {
    ViewSuspendUWMyTask: Permission.ViewSuspendUWMyTask,
    ViewQCUWMyTask: Permission.ViewQCUWMyTask,
    ViewUnderwritingUWMyTask: Permission.ViewUnderwritingUWMyTask,
    ViewQCUWPSMyTask: Permission.ViewQCUWPSMyTask
  },

  CS: {
    ViewSuspendCSMyTask: Permission.ViewSuspendCSMyTask,
    ViewQCCSMyTask: Permission.ViewQCCSMyTask,
    ViewVerificationCSMyTask: Permission.ViewVerificationCSMyTask
  },

  CH: {
    ViewSuspendCHMyTask: Permission.ViewSuspendCHMyTask,
    ViewQCCHMyTask: Permission.ViewQCCHMyTask,
    ViewVerificationCHMyTask: Permission.ViewVerificationCHMyTask
  },

  CL: {
    ViewQCCLMyTask: Permission.ViewQCCLMyTask,
    ViewAdjudicationCLMyTask: Permission.ViewAdjudicationCLMyTask,
    ViewAdjudicationSuspendCLMyTask: Permission.ViewAdjudicationSuspendCLMyTask,
    ViewAdminCLMyTask: Permission.ViewAdminCLMyTask,
    ViewPayoutCLMyTask: Permission.ViewPayoutCLMyTask
  },

  ViewErrorHandlerMyTask: Permission.ViewErrorHandlerMyTask,

  PC: {
    ViewVerificationPCMyTask: Permission.ViewVerificationPCMyTask,
    ViewSuspendPCMyTask: Permission.ViewSuspendPCMyTask,
    ViewQCPCMyTask: Permission.ViewQCPCMyTask
  },
  IDC: {
    ViewVerificationIDCMyTask: Permission.ViewVerificationIDCMyTask,
    ViewSuspendIDCMyTask: Permission.ViewSuspendIDCMyTask,
    ViewQCIDCMyTask: Permission.ViewQCIDCMyTask
  }
}

export const TaskListPermissions = {
  PS: {
    ExportPSTaskList: Permission.ExportPSTaskList,
    RetryPSTaskList: Permission.RetryPSTaskList,
    UnsuspendPSTaskList: Permission.UnsuspendPSTaskList,
    TransferPSTaskList: Permission.TransferPSTaskList,
    AssignToMePSTaskList: Permission.AssignToMePSTaskList
  },
  DS: {
    ExportDSTaskList: Permission.ExportDSTaskList,
    RetryDSTaskList: Permission.RetryDSTaskList,
    UnsuspendDSTaskList: Permission.UnsuspendDSTaskList,
    TransferDSTaskList: Permission.TransferDSTaskList,
    AssignToMeDSTaskList: Permission.AssignToMeDSTaskList
  },

  CH: {
    ExportCHTaskList: Permission.ExportCHTaskList,
    RetryCHTaskList: Permission.RetryCHTaskList,
    UnsuspendCHTaskList: Permission.UnsuspendCHTaskList,
    TransferCHTaskList: Permission.TransferCHTaskList,
    AssignToMeCHTaskList: Permission.AssignToMeCHTaskList
  },

  CS: {
    ExportCSTaskList: Permission.ExportCSTaskList,
    RetryCSTaskList: Permission.RetryCSTaskList,
    UnsuspendCSTaskList: Permission.UnsuspendCSTaskList,
    TransferCSTaskList: Permission.TransferCSTaskList,
    AssignToMeCSTaskList: Permission.AssignToMeCSTaskList
  },

  UW: {
    ExportUWTaskList: Permission.ExportUWTaskList,
    RetryUWTaskList: Permission.RetryUWTaskList,
    UnsuspendUWTaskList: Permission.UnsuspendUWTaskList,
    TransferUWTaskList: Permission.TransferUWTaskList,
    AssignToMeUWTaskList: Permission.AssignToMeUWTaskList
  },

  CL: {
    ExportCLTaskList: Permission.ExportCLTaskList,
    RetryCLTaskList: Permission.RetryCLTaskList,
    UnsuspendCLTaskList: Permission.UnsuspendCLTaskList,
    TransferCLTaskList: Permission.TransferCLTaskList,
    AssignToMeCLTaskList: Permission.AssignToMeCLTaskList
  },

  PC: {
    ExportPCTaskList: Permission.ExportPCTaskList,
    RetryPCTaskList: Permission.RetryPCTaskList,
    AssignToMePCTaskList: Permission.AssignToMePCTaskList,
    UnsuspendPCTaskList: Permission.UnsuspendPCTaskList,
    TransferPCTaskList: Permission.TranferPCTaskList,
    EndPCTaskList: Permission.EndPCTaskList,
    SuspendWriteOffPCTaskList: Permission.SuspendWriteOffPCTaskList,
    UnsuspendWriteOffPCTaskList: Permission.UnsuspendWriteOffPCTaskList
  },

  IDC: {
    AssignToMeIDCTaskList: Permission.AssignToMeIDCTaskList,
    TranferIDCTaskList: Permission.TranferIDCTaskList,
    EndIDCTaskList: Permission.EndIDCTaskList
  },

  ViewDetailTaskList: Permission.ViewDetailTaskList
}

export const OBMyTaskPermissions = {
  ViewQAOBMyTask: Permission.ViewQAOBMyTask,
  ViewQCOBMyTask: Permission.ViewQCOBMyTask,
  ViewVerificationOBMyTask: Permission.ViewVerificationOBMyTask,
  GetcaseOBTaskList: Permission.GetcaseOBTaskList,
  ViewReQCOBMyTask: Permission.ViewReQCOBMyTask
}

export const OBTeamTaskPermissions = {
  ViewSuspendOBTeamTask: Permission.ViewSuspendOBTeamTask,
  ViewQAOBTeamTask: Permission.ViewQAOBTeamTask,
  ViewQCOBTeamTask: Permission.ViewQCOBTeamTask,
  ViewVerificationOBTeamTask: Permission.ViewVerificationOBTeamTask,
  ViewErrorHandlerOBTeamTask: Permission.ViewErrorHandlerOBTeamTask,
  ViewReQCOBTeamTask: Permission.ViewReQCOBTeamTask
}

export const OBTaskListPermissions = {
  ViewDetailOBTaskList: Permission.ViewDetailOBTaskList,
  AssignToMeOBTaskList: Permission.AssignToMeOBTaskList,
  TransferOBTaskList: Permission.TransferOBTaskList,
  EndOBTaskList: Permission.EndOBTaskList
}

export const BackOfficePermission = {
  List: {
    View: Permission.AFList_View,
    Search: Permission.AFList_Search
  },
  Detail: {
    Tab: {
      General: Permission.AFDetail_TGeneral,
      UW: Permission.AFDetail_TUW,
      Verify: Permission.AFDetail_TVerify,
      PremiumCheck: Permission.AFDetail_TPremiumCheck,
      GeneralTab: {
        Policy: Permission.AFDetail_GTabPolicy,
        Product: Permission.AFDetail_GTabProduct,
        Employee: Permission.AFDetail_GTabEmployee,
        Dependent: Permission.AFDetail_GTabDependent
      }
    },
    UW: {
      Edit: Permission.AFDetail_UW_Edit,
      Email: Permission.AFDetail_UW_Send_Email
    },
    Verification: {
      EditPS: Permission.AFDetail_Verify_Edit_PS,
      EditVI: Permission.AFDetail_Verify_Edit_VI,
      Email: Permission.AFDetail_Verify_Send_Email
    },
    PremiumCheck: {
      Edit: Permission.AFDetail_PremiumCheck_Edit,
      Email: Permission.AFDetail_PremiumCheck_Send_Email
    }
  }
}

export const TASK_DETAIL_ROLES = {
  VIEW_REQUESTINFO_PS: Permission.ViewRequestInfoPSTaskDetail,
  VIEW_REQUESTINFO_CS: Permission.ViewRequestInfoCSTaskDetail,
  VIEW_REQUESTINFO_CH: Permission.ViewRequestInfoCHTaskDetail,
  VIEW_REQUESTINFO_PC: Permission.ViewRequestInfoPCTaskDetail,
  VIEW_REQUESTINFO_DS: Permission.ViewRequestInfoDSTaskDetail,
  VIEW_REQUESTINFO_IDC: Permission.ViewRequestInfoIDCTaskDetail,

  VIEW_SUPPLEMENTARY_INFO: Permission.ViewSupplementaryInfoTaskDetail,
  VIEW_SUPPLEMENTARY_INFO_IDC: Permission.ViewSupplementaryInfoIDCTaskDetail,
  VIEW_SUPPLEMENTARY_INFO_PC: Permission.ViewSupplementaryInfoPC,
  VIEW_QC_ADMIN_CL: Permission.ViewQCAdminCL,
  VIEW_RELATED_INFO: Permission.ViewRelatedInfoTaskDetail,
  VIEW_RELATED_INFO_PC: Permission.ViewRelatedInfoPC,
  VIEW_RELATED_INFO_DS: Permission.ViewRelatedInfoDS,
  VIEW_RELATED_INFO_IDC: Permission.ViewRelatedInfoIDCTaskDetail,

  VIEW_CLAIM_INFO: Permission.ViewClaimInfoTaskDetail,

  EDIT_REQUESTINFO_PS: Permission.EditRequestInfoPSTaskDetail,
  EDIT_REQUESTINFO_CS: Permission.EditRequestInfoCSTaskDetail,
  EDIT_REQUESTINFO_CH: Permission.EditRequestInfoCHTaskDetail,

  ViewGeneralInfoSpecialNotesTaskDetail: Permission.ViewGeneralInfoSpecialNotesTaskDetail,
  ViewGeneralInfoProductTaskDetail: Permission.ViewGeneralInfoProductTaskDetail,
  ViewGeneralInfoPolicyValueTaskDetail: Permission.ViewGeneralInfoPolicyValueTaskDetail,
  ViewGeneralInfoPolicyTaskDetail: Permission.ViewGeneralInfoPolicyTaskDetail,
  ViewGeneralInfoInvestmentTaskDetail: Permission.ViewGeneralInfoInvestmentTaskDetail,
  ViewGeneralInfoCustomerTaskDetail: Permission.ViewGeneralInfoCustomerTaskDetail,
  ViewGeneralInfoCoreTransTaskDetail: Permission.ViewGeneralInfoCoreTransTaskDetail,
  ViewGeneralInfoAgentTaskDetail: Permission.ViewGeneralInfoAgentTaskDetail,

  ViewGeneralInfoPolicyIDCTaskDetail: Permission.ViewGeneralInfoPolicyIDCTaskDetail,
  ViewGeneralInfoCustomerIDCTaskDetail: Permission.ViewGeneralInfoCustomerIDCTaskDetail,
  ViewGeneralInfoProductIDCTaskDetail: Permission.ViewGeneralInfoProductIDCTaskDetail,
  ViewGeneralInfoPolicyValueIDCTaskDetail: Permission.ViewGeneralInfoPolicyValueIDCTaskDetail,
  ViewGeneralInfoInvestmentIDCTaskDetail: Permission.ViewGeneralInfoInvestmentIDCTaskDetail,
  ViewGeneralInfoAgentIDCTaskDetail: Permission.ViewGeneralInfoAgentIDCTaskDetail,

  ViewAdminPaymentModeTaskDetail: Permission.ViewAdminPaymentModeTaskDetail,
  ViewAdminPolicyRiderTaskDetail: Permission.ViewAdminPolicyRiderTaskDetail,
  ViewAdminClaimTypeTaskDetail: Permission.ViewAdminClaimTypeTaskDetail,

  ViewAdjudicationAllocationTaskDetail: Permission.ViewAdjudicationAllocationTaskDetail,
  ViewAdjudicationCalculationTaskDetail: Permission.ViewAdjudicationCalculationTaskDetail,
  ViewAdjudicationFinalDecisionTaskDetail: Permission.ViewAdjudicationFinalDecisionTaskDetail,
  ViewAdjudicationFinalLetterTaskDetail: Permission.ViewAdjudicationFinalLetterTaskDetail,
  ViewAdjudicationPaymentModeTaskDetail: Permission.ViewAdjudicationPaymentModeTaskDetail,
  ViewAdjudicationPolicyRiderTaskDetail: Permission.ViewAdjudicationPolicyRiderTaskDetail,
  ViewAdjudicationClaimTypeTaskDetail: Permission.ViewAdjudicationClaimTypeTaskDetail,
  EditAdjudicationFinalLetterTaskDetail: Permission.EditAdjudicationFinalLetterTaskDetail,
  ViewPayoutCLTaskDetail: Permission.ViewPayoutCLTaskDetail,

  ViewRelatedInfoCFMLetterTaskDetail: Permission.ViewRelatedInfoCFMLetterTaskDetail,
  ViewRelatedInfoOnGoingRequestTaskDetail: Permission.ViewRelatedInfoOnGoingRequestTaskDetail,
  ViewRelatedInfoDocumentTaskDetail: Permission.ViewRelatedInfoDocumentTaskDetail,
  ViewRelatedInfoHistoryTaskDetail: Permission.ViewRelatedInfoHistoryTaskDetail,
  ViewRelatedInfoCommentTaskDetail: Permission.ViewRelatedInfoCommentTaskDetail,
  EditArchiveToFilenetRelatedInfoDocument: Permission.EditArchiveToFilenetRelatedInfoDocument,

  VIEW_QC_PS: Permission.ViewQCPSTaskDetail,
  VIEW_QC_UW: Permission.ViewQCUWTaskDetail,
  VIEW_QC_CS: Permission.ViewQCCSTaskDetail,
  VIEW_QC_CH: Permission.ViewQCCHTaskDetail,
  VIEW_QC_CL: Permission.ViewQCCLTaskDetail,
  VIEW_QC_PC: Permission.ViewQCPCTaskDetail,
  VIEW_QC_DS: Permission.ViewQCDSTaskDetail,
  VIEW_QC_IDC: Permission.ViewQCIDCTaskDetail,

  VIEW_QC_UW_PS: Permission.ViewQCUWPSTaskDetail,

  VIEW_SUSPEND_WRITEOFF_PC: Permission.ViewSuspendWriteOffInfoPC,

  COMPLETE_DS: Permission.CompleteDSTaskDetail,
  COMPLETE_PS: Permission.CompletePSTaskDetail,
  COMPLETE_CH: Permission.CompleteCHTaskDetail,
  COMPLETE_CS: Permission.CompleteCSTaskDetail,
  COMPLETE_CL: Permission.CompleteCLTaskDetail,
  COMPLETE_PC: Permission.CompleteRequestInfoPC,
  COMPLETE_IDC: Permission.CompleteRequestInfoIDCTaskDetail,

  ACCEPT_PS: Permission.AcceptPSTaskDetail,
  ACCEPT_CH: Permission.AcceptCHTaskDetail,
  ACCEPT_CS: Permission.AcceptCSTaskDetail,
  ACCEPT_PC: Permission.AcceptPCTaskDetail,
  ACCEPT_DS: Permission.AcceptDSTaskDetail,
  ACCEPT_IDC: Permission.AcceptIDCTaskDetail,

  TRANSFER_PS: Permission.TransferPSTaskDetail,
  TRANSFER_CH: Permission.TransferCHTaskDetail,
  TRANSFER_CS: Permission.TransferCSTaskDetail,
  TRANSFER_CL: Permission.TransferCLTaskDetail,
  TRANSFER_PC: Permission.TranferRequestInfoPC,
  TRANSFER_DS: Permission.TransferDSTaskDetail,
  TRANSFER_IDC: Permission.TranferRequestInfoIDCTaskDetail,

  DECLINE_PS: Permission.DeclinePSTaskDetail,
  DECLINE_CH: Permission.DeclineCHTaskDetail,
  DECLINE_CS: Permission.DeclineCSTaskDetail,
  DECLINE_PC: Permission.DeclinePCTaskDetail,
  DECLINE_DS: Permission.DeclineDSTaskDetail,
  DECLINE_IDC: Permission.DeclineIDCTaskDetail,

  SUSPEND_PS: Permission.SuspendPSTaskDetail,
  SUSPEND_CH: Permission.SuspendCHTaskDetail,
  SUSPEND_CS: Permission.SuspendCSTaskDetail,
  SUSPEND_CL: Permission.SuspendCLTaskDetail,
  SUSPEND_PC: Permission.SuspendPCTaskDetail,
  SUSPEND_DS: Permission.SuspendDSTaskDetail,
  SUSPEND_IDC: Permission.SuspendIDCTaskDetail,

  REJECT_PS: Permission.RejectPSTaskDetail,
  REJECT_CH: Permission.RejectCHTaskDetail,
  REJECT_CS: Permission.RejectCSTaskDetail,
  REJECT_CL: Permission.RejectCLTaskDetail,
  REJECT_PC: Permission.RejectRequestInfoPC,
  REJECT_DS: Permission.RejectDSTaskDetail,
  REJECT_IDC: Permission.RejectRequestInfoIDCTaskDetail,

  END_PS: Permission.EndPSTaskDetail,
  END_CH: Permission.EndCHTaskDetail,
  END_CS: Permission.EndCSTaskDetail,
  END_CL: Permission.EndCLTaskDetail,
  END_PC: Permission.EndRequestInfoPC,
  END_DS: Permission.EndDSTaskDetail,
  END_IDC: Permission.EndRequestInfoIDCTaskDetail,
  // End-DS-TaskDetail

  VIEW_UNDEWRITING_UW: Permission.ViewUnderwritingUWTaskDetail,
  EDIT_UNDERWRITING_UW: Permission.EditUnderwritingUWTaskDetail,

  EDIT_SUPPLEMENTARY_INFO: Permission.EditSupplementaryInfoTaskDetail,

  VIEW_WORK_FLOW_COMMENT: Permission.ViewWorkflowCommentTaskDetail,
  VIEW_WORK_FLOW_COMMENT_PC: Permission.ViewErrorlogPC,
  VIEW_ERROR_LOG_IDC: Permission.ViewErrorlogIDCTaskDetail,

  ADD_COMMENT: Permission.AddCommentTaskDetail,
  VIEW_DOCUMENT_FILE: Permission.ViewDocumentFileTaskDetail,

  ViewGeneralInfoPolicyPC: Permission.ViewGeneralInfoPolicyPC,
  ViewGeneralInfoCustomerPC: Permission.ViewGeneralInfoCustomerPC,
  ViewGeneralInfoProductPC: Permission.ViewGeneralInfoProductPC,
  ViewGeneralInfoPolicyValuePC: Permission.ViewGeneralInfoPolicyValuePC,
  ViewGeneralInfoInvestmentPC: Permission.ViewGeneralInfoInvestmentPC,
  ViewGeneralInfoAgentPC: Permission.ViewGeneralInfoAgentPC,
  ViewRequestInfoPCTaskDetail: Permission.ViewRequestInfoPCTaskDetail,
  TranferRequestInfoPC: Permission.TranferRequestInfoPC,
  CompleteRequestInfoPC: Permission.CompleteRequestInfoPC,
  RejectRequestInfoPC: Permission.RejectRequestInfoPC,
  EndRequestInfoPC: Permission.EndRequestInfoPC,
  ViewSupplementaryInfoPC: Permission.ViewSupplementaryInfoPC,
  EditSupplementaryInfoPC: Permission.EditSupplementaryInfoPC,
  UpdateSupplementaryInfoPC: Permission.UpdateSupplementaryInfoPC,
  ViewRelatedInfoPC: Permission.ViewRelatedInfoPC,
  ViewErrorlogPC: Permission.ViewErrorlogPC,
  AssignmentToMePC: Permission.AssignToMePCTaskList,
  TranferPC: Permission.TranferPCTaskList,
  EndPC: Permission.EndPCTaskList,
  UnsuspendPC: Permission.UnsuspendPCTaskList,
  RetryPC: Permission.RetryPCTaskList,
  ExportPC: Permission.ExportPCTaskList,
  AcceptPC: Permission.AcceptPCTaskDetail,
  DeclinePC: Permission.DeclinePCTaskDetail,
  PremiumCollectionReport: Permission.PremiumCollectionReport,

  VIEW_INTERNAL_SUSPEND: Permission.ViewInternalSuspendTaskDetail,
  EDIT_INTERNAL_SUSPEND: Permission.EditInternalSuspendTaskDetail,

  EditRequestInfoDS: Permission.EditRequestInfoDS,
  EditQCDS: Permission.EditQCDS,
  ViewSuspendDS: Permission.ViewSuspendDS,

  ViewSupplementaryInfoDS: Permission.ViewSupplementaryInfoDS,
  EditSupplementaryInfoDS: Permission.EditSupplementaryInfoDS,
  UpdateSupplementaryInfoDS: Permission.UpdateSupplementaryInfoDS,

  ViewErrorlogDS: Permission.ViewErrorlogDS,
  EDIT_QC_CL: Permission.EditQCCLTaskDetail,
  EDIT_QC_ADMIN_CL: Permission.EditQCAdminCLTaskDetail,

  ViewPCRequestDetail: Permission.ViewPCRequestDetail,
  EditPCRequestDetail: Permission.EditPCRequestDetail,
  CompletePCRequestDetail: Permission.CompletePCRequestDetail
}

export const E_UTILITIES = {
  VIEW_IHEAT_INQUIRY_UTILITIES: Permission.ViewIHeatInquiryUtilities,
  VIEW_UPLOADDOCUMENT_UTILITIES: Permission.ViewUploadDocumentUtilities,
  REPORT_UPLOADDOCUMENT_UTILITIES: Permission.ReportUploadDocumentUtilities,
  VIEW_MANUAL_GUIDELINE_UTILITIES: Permission.ViewManualGuidelineUtilities,
  UPLOAD_MANUAL_GUIDELINE_UTILITIES: Permission.UploadManualGuidelineUtilities,
  VIEW_IMAGING_UPLOAD_UTILITIES: Permission.ViewImagingUploadUtilities
}

export const SubmissionPermissions = {
  SearchCoreTransactionHistoryPolicyDetail: Permission.SearchCoreTransactionHistoryPolicyDetail,
  ViewCoreTransactionHistoryPolicyDetail: Permission.ViewCoreTransactionHistoryPolicyDetail,
  ViewAgentInfoPolicyDetail: Permission.ViewAgentInfoPolicyDetail,
  ViewInvestmentInfoPolicyDetail: Permission.ViewInvestmentInfoPolicyDetail,
  ViewPolicyValueInfoPolicyDetail: Permission.ViewPolicyValueInfoPolicyDetail,
  ViewProductInfoPolicyDetail: Permission.ViewProductInfoPolicyDetail,
  ViewCustomerInfoPolicyDetail: Permission.ViewCustomerInfoPolicyDetail,
  ViewSpecialNotesPolicyDetail: Permission.ViewSpecialNotesPolicyDetail,
  ViewPolicyInfoPolicyDetail: Permission.ViewPolicyInfoPolicyDetail
}

export const UserRoles = {
  CasualLabor: Permission.CasualLabor
}
export const ReportPermission = {
  ExportSubmissionReport: Permission.ExportSubmissionReport,
  ViewSubmissionReport: Permission.ViewSubmissionReport,
  SubmissionReportReports: Permission.SubmissionReportReports,
  PaymentReportPayoutReports: Permission.PaymentReportPayoutReports,
  ViewErrorHandlerReports: Permission.ViewErrorHandlerReports,
  SearchErrorHandlerReports: Permission.SearchErrorHandlerReports,
  ClaimPayoutReports: Permission.ClaimPayoutReport,
  CommunicationTrackingReport: Permission.CommunicationTrackingReports,
  CheckingPayoutMethodReports: Permission.CheckingPayoutMethodReports
}

export const UploadDocumentReportPermission = {
  ViewReportUploadDocument: Permission.ViewReportUploadDocument,
  ExportReportUploadDocument: Permission.ExportReportUploadDocument
}

export const CepPermission = {
  View360Inquiry: Permission.View360Inquiry,
  Search360Inquiry: Permission.Search360Inquiry,
  ViewPolicyDetail360Inquiry: Permission.ViewPolicyDetail360Inquiry,
  ViewPolicyInfoGAPolicyDetail: Permission.ViewPolicyInfoGAPolicyDetail,
  ViewPolicyInfoStaffPolicyDetail: Permission.ViewPolicyInfoStaffPolicyDetail,
  ViewCustomerInfoGAPolicyDetail: Permission.ViewCustomerInfoGAPolicyDetail,
  ViewCustomerInfoStaffPolicyDetail: Permission.ViewCustomerInfoStaffPolicyDetail,
  ViewProductInfoGAPolicyDetail: Permission.ViewProductInfoGAPolicyDetail,
  ViewProductInfoStaffPolicyDetail: Permission.ViewProductInfoStaffPolicyDetail,
  ViewPolicyValueInfoGAPolicyDetail: Permission.ViewPolicyValueInfoGAPolicyDetail,
  ViewPolicyValueInfoStaffPolicyDetail: Permission.ViewPolicyValueInfoStaffPolicyDetail,
  ViewInvestmentInfoGAPolicyDetail: Permission.ViewInvestmentInfoGAPolicyDetail,
  ViewInvestmentInfoStaffPolicyDetail: Permission.ViewInvestmentInfoStaffPolicyDetail,
  ViewAgentInfoGAPolicyDetail: Permission.ViewAgentInfoGAPolicyDetail,
  ViewAgentInfoStaffPolicyDetail: Permission.ViewAgentInfoStaffPolicyDetail,
  ViewIFQuotationPolicyDetail: Permission.ViewIFQuotationPolicyDetail,
  // CEP-V2
  ViewPruRewardsInfoPolicyDetail: Permission.ViewPruRewardsInfoPolicyDetail,
  ViewPruOnlineInfoGAPolicyDetail: Permission.ViewPruOnlineInfoGAPolicyDetail,
  ViewPruOnlineInfoStaffPolicyDetail: Permission.ViewPruOnlineInfoStaffPolicyDetail,
  ViewPremiumInfoGAPolicyDetail: Permission.ViewPremiumInfoGAPolicyDetail,
  ViewPremiumInfoStaffPolicyDetail: Permission.ViewPremiumInfoStaffPolicyDetail,
  ViewPaymentInfoGAPolicyDetail: Permission.ViewPaymentInfoGAPolicyDetail,
  ViewPaymentInfoStaffPolicyDetail: Permission.ViewPaymentInfoStaffPolicyDetail,
  ViewNBUWInfoGAPolicyDetail: Permission.ViewNBUWInfoGAPolicyDetail,
  ViewNBUWInfoStaffPolicyDetail: Permission.ViewNBUWInfoStaffPolicyDetail,
  View360PruRewardsGACSCCS: Permission.View360PruRewardsGACSCCS,
  ViewUnitLinkFundPrice: Permission.ViewUnitLinkFundPrice
}
export const DistributionServices = {
  ImportAvicadDistributionServices: Permission.ImportAvicadDistributionServices,
  ViewExportAvicadDistributionServices: Permission.ViewExportAvicadDistributionServices,
  ExportAvicadDistributionServices: Permission.EditExportAvicadDistributionServices,
  ViewSalesStructureDistributionServices: Permission.ViewSalesStructureDistributionServices,
  ExportSalesStructureDistributionServices: Permission.ExportSalesStructureDistributionServices,
  ViewCandidateInforDistributionServices: Permission.ViewCandidateInforDistributionServices,
  ExportCandidateInforDistributionServices: Permission.ExportCandidateInforDistributionServices,
  ViewDSAdminDistributionServices: Permission.ViewDSAdmin,
  ViewAddLackDoc: Permission.ViewAddLackDoc
}

export const PCDataImportPermission = {
  ViewSubmitFilePCDataImport: Permission.ViewSubmitFilePCDataImport,
  ViewUploadFileToStoragePCDataImport: Permission.ViewUploadFileToStoragePCDataImport,
  ViewFailListPCDataImport: Permission.ViewFailListPCDataImport
}
export const GroupPolicyPermission = {
  ViewImportFileForPartialWithdrawMenu: Permission.ViewImportFileForPartialWithdrawMenu,
  ViewImportFileForBillingChangeMenu: Permission.ViewImportFileForBillingChangeMenu,
  SaveFailDetailBillingChange: Permission.SaveFailDetailBillingChange,
  ViewFailDetailBillingChange: Permission.ViewFailDetailBillingChange,
  EditFailDetailBillingChange: Permission.EditFailDetailBillingChange,
  UploadDataToSystemFailDetailBillingChange: Permission.UploadDataToSystemFailDetailBillingChange,
  ViewImportFileImportFileBillingChange: Permission.ViewImportFileImportFileBillingChange,
  ImportFileImportFileBillingChange: Permission.ImportFileImportFileBillingChange,
  ViewHistoryImportFileBillingChange: Permission.ViewHistoryImportFileBillingChange,
  EditFailHistoryImportFileBillingChange: Permission.EditFailHistoryImportFileBillingChange,
  ExportDataHistoryImportFileBillingChange: Permission.ExportDataHistoryImportFileBillingChange,
  SearchHistoryImportFileBillingChange: Permission.SearchHistoryImportFileBillingChange,
  ViewHistoryImportBillingChange: Permission.ViewHistoryImportBillingChange,
  ExportDataHistoryImportBillingChange: Permission.ExportDataHistoryImportBillingChange,
  // Auto Partial Withdraw
  ViewFailDetailAutoDebit: Permission.ViewFailDetailAutoDebit,
  ViewImportFileImportFileAutoDebit: Permission.ViewImportFileImportFileAutoDebit,
  ImportFileImportFileAutoDebit: Permission.ImportFileImportFileAutoDebit,
  ViewHistoryImportFileDetailAutoDebit: Permission.ViewHistoryImportFileDetailAutoDebit,
  ViewFailHistoryImportFileDetailAutoDebit: Permission.ViewFailHistoryImportFileDetailAutoDebit,
  ExportHistoryImportFileDetailAutoDebit: Permission.ExportHistoryImportFileDetailAutoDebit,
  SearchHistoryImportFileDetailAutoDebit: Permission.SearchHistoryImportFileDetailAutoDebit,
  ViewHistoryImportFileAutoDebit: Permission.ViewHistoryImportFileAutoDebit,
  ExportHistoryImportFileAutoDebit: Permission.ExportHistoryImportFileAutoDebit,
  ViewHistoryRequestFormAutoDebit: Permission.ViewHistoryRequestFormAutoDebit,
  ExportPDFHistoryRequestFormAutoDebit: Permission.ExportPDFHistoryRequestFormAutoDebit,
  /// ReportAutoDebitMonthlyRegister && AutoDebitTopupAccountMonthly
  ViewReportAutoDebitMonthlyRegister: Permission.ViewReportAutoDebitMonthlyRegister,
  SearchReportAutoDebitMonthlyRegister: Permission.SearchReportAutoDebitMonthlyRegister,
  ExportReportAutoDebitMonthlyRegister: Permission.ExportReportAutoDebitMonthlyRegister,
  ViewReportAutoDebitTopupAccountMonthly: Permission.ViewReportAutoDebitTopupAccountMonthly,
  SearchReportAutoDebitTopupAccountMonthly: Permission.SearchReportAutoDebitTopupAccountMonthly,
  ExportReportAutoDebitTopupAccountMonthly: Permission.ExportReportAutoDebitTopupAccountMonthly
}

export const IBPolicyPermission = {
  ViewIBPolicyListTab: Permission.ViewIBPolicyListTab,
  ViewIBMasterTab: Permission.ViewIBMasterTab,
  ViewIBTransactionTab: Permission.ViewIBTransactionTab,
  ViewIBPruRewardsTab: Permission.ViewIBPruRewardsTab,
  ViewIBPromotionInfoPolicyDetail: Permission.ViewIBPromotionInfoPolicyDetail,
  ViewIBPolicyInfoPolicyDetail: Permission.ViewIBPolicyInfoPolicyDetail,
  ViewIBInvestmentInfoPolicyDetail: Permission.ViewIBInvestmentInfoPolicyDetail,
  ViewIBAgentInfoPolicyDetail: Permission.ViewIBAgentInfoPolicyDetail,
  ViewIBInsuranceBenefitInfoPolicyDetail: Permission.ViewIBInsuranceBenefitInfoPolicyDetail,
  ViewIBNBUWInfoPolicyDetail: Permission.ViewIBNBUWInfoPolicyDetail,
  ViewIBPaymentInfoPolicyDetail: Permission.ViewIBPaymentInfoPolicyDetail,
  ViewIBComplainInquiryPopup: Permission.ViewIBComplainInquiryPopup,
  EditIBComplainInquiryPopup: Permission.EditIBComplainInquiryPopup,
  SearchIBContactCenterInquiry: Permission.SearchIBContactCenterInquiry,
  ViewIBChangeContactInfoPopup: Permission.ViewIBChangeContactInfo,
  ViewIBDocumentPolicyDetail: Permission.ViewIBDocumentPolicyDetail,
  ViewIBCustomerBehaviorPopup: Permission.ViewIBCustomerBehaviorPopup,
  EditIBBehaviorCustomerBehaviorPopup: Permission.EditIBBehaviorCustomerBehaviorPopup,
  EditIBCharacterCustomerBehaviorPopup: Permission.EditIBCharacterCustomerBehaviorPopup,
  UpdateIBCustomerBehaviorPopup: Permission.UpdateIBCustomerBehaviorPopup,
  IBViewEmailPopup: Permission.IBViewEmailPopup,
  IBSubmitEmailPopup: Permission.IBSubmitEmailPopup,
  IBClearAllEmailPopup: Permission.IBClearAllEmailPopup,
  IBReturnOriginalEmailPopup: Permission.IBReturnOriginalEmailPopup,
  IBViewReturnedEmailList: Permission.IBViewReturnedEmailList,
  IBViewPendingApprovalEmailList: Permission.IBViewPendingApprovalEmailList,
  IBViewHistoryEmailList: Permission.IBViewHistoryEmailList,
  IBViewReturnedEmailDetail: Permission.IBViewReturnedEmailDetail,
  IBReturnOriginalEmailReturnedEmailDetail: Permission.IBReturnOriginalEmailReturnedEmailDetail,
  IBCancelReturnedEmailDetail: Permission.IBCancelReturnedEmailDetail,
  IBSubmitReturnedEmailDetail: Permission.IBSubmitReturnedEmailDetail,
  IBViewApprovalEmailDetail: Permission.IBViewApprovalEmailDetail,
  IBApproveApprovalEmailDetail: Permission.IBApproveApprovalEmailDetail,
  IBReturnToSenderApprovalEmailDetail: Permission.IBReturnToSenderApprovalEmailDetail,
  IBCancelApprovalEmailDetail: Permission.IBCancelApprovalEmailDetail,
  IBReturnOriginalEmailApprovalEmailDetail: Permission.IBReturnOriginalEmailApprovalEmailDetail,
  IBSendZNSPopup: Permission.IBSendZNSPopup,
  IBViewZNSPopup: Permission.IBViewZNSPopup
}

export const OBImportFilePermission = {
  ViewImportFileAdhoc: Permission.ViewImportFileAdhoc,
  ImportImportFileAdhoc: Permission.ImportImportFileAdhoc,
  ViewFileImportHistoryAdhoc: Permission.ViewFileImportHistoryAdhoc,
  SearchFileImportHistoryAdhoc: Permission.SearchFileImportHistoryAdhoc,
  ExportFileImportHistoryAdhoc: Permission.ExportFileImportHistoryAdhoc,
  DownloadFileDetailFileImportHistoryAdhoc: Permission.DownloadFileDetailFileImportHistoryAdhoc,
  ViewDetailedFileImportHistoryAdhoc: Permission.ViewDetailedFileImportHistoryAdhoc,
  SearchDetailedFileImportHistoryAdhoc: Permission.SearchDetailedFileImportHistoryAdhoc,
  ViewImportFilePromotion: Permission.ViewImportFilePromotion,
  ImportImportFilePromotion: Permission.ImportImportFilePromotion,
  ViewExportPromotionData: Permission.ViewExportPromotionData,
  ExportExportPromotionData: Permission.ExportExportPromotionData
}
