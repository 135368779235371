import {
  AppContext,
  assets,
  form2,
  ImgUploadMutiple,
  Panel,
  Select,
  useMobile,
  DatePicker,
  Input,
  BeneficiaryDesignationService,
  ErrorHandling,
  GeneralService,
  TransactionType,
  ModalComponent,
  // StorageBlob,
  BeneficiaryDesignationData,
  SelectSearch,
  TaskType,
  SelectOption
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import React from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'
import { BenificiaryDesignationForm } from './beneficiary-designation-form'
import { SC } from '../../common/styles'
import { pipe } from 'fp-ts/lib/function'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { Throwable, ZIO } from '@mxt/zio'
import moment from 'moment'
import { BenIDCardOption, Gender, BenIDCard } from './ben-const'
import _ from 'lodash'
import { ConfirmView, FilePresent } from '../../common'
import { RequestAuthenticateData } from '../../request-authen'
import { useLoading } from '@mxt/zio-react'
type Props = PolicyServiceProps<BeneficiaryDesignationData.BeneficiaryToSubmit[]> & {
  officeCode: string
}

export const BeneficiaryDesignation: React.FC<Props> = ({
  policyNumber,
  initSubmission,
  isConfirmed,
  officeCode,
  clientNumber
}) => {
  const { t, i18n } = useTranslation()
  const { changeBreadcrumb, showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const [modalVisible, setModalVisible] = React.useState<boolean>(false)
  const [districtList, setDistrictList] = React.useState<SelectOption[]>([])
  const [wardList, setWardList] = React.useState<SelectOption[]>([])
  const [loading, bindLoader] = useLoading(false)
  const {
    base: {
      control,
      formState: { errors, isValid },
      getValues,
      reset,
      watch,
      trigger,
      setValue
    }
  } = form2.useForm(BenificiaryDesignationForm.codec)

  const beneficiaries = watch('beneficiaries')

  const { fields, remove, append } = useFieldArray<BenificiaryDesignationForm.Raw>({
    control,
    name: 'beneficiaries'
  })

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const getUploadedFilesInfo = () => {
    let uploadedFileList: UploadedFilesInfo[] = []
    uploadedFileList.push({
      uploadFiles: getValues('fileAttachments') as BenificiaryDesignationForm.FileMeta[],
      transactionType: TransactionType.BENEFICIARY_DESIGNATION,
      docTypeCode: 'DPS10',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      await trigger()
      if (isValid) {
        const totalPercentage = getValues('beneficiaries')?.reduce((pre, cur) => pre + Number(cur.benefitShare), 0) ?? 0
        if (totalPercentage !== 100) {
          showToast(t('message:MS050017'), 'error')
          return false
        }

        // const attachFiles = getValues('fileAttachments') || []

        // const fileSubmit =
        //   !isContinue && attachFiles.length > 0
        //     ? await pipe(
        //         GeneralService.getMetaData(TransactionType.BENEFICIARY_DESIGNATION, 'DPS10'),
        //         ZIO.flatMap(({ data }) => {
        //           const batchno = `${moment().format('DD/MM/YYYY')}-${data.type}-${officeCode}`
        //           const metaDataRaw: StorageBlob.MetaDataUpload = {
        //             type: data.transactionType,
        //             doctype: data.doctypeEn,
        //             class: data.classFilenet,
        //             docid: data.docID,
        //             maindoc: data.mainDoc,
        //             subdoc: data.subDoc,
        //             polnum: policyNumber!!,
        //             batchno
        //           }

        //           const files: StorageBlob.FileContent[] = attachFiles.map((file) => {
        //             return {
        //               file: file.file!!,
        //               metaData: metaDataRaw
        //             }
        //           })
        //           return pipe(StorageBlob.uploadToSubmit('PS', policyNumber!!)(files))
        //         }),
        //         ZIO.tapError((_) => {
        //           showToast(t('message:MS050001'), 'error')
        //           return ZIO.succeed(false)
        //         }),
        //         bindLoader,
        //         ErrorHandling.run({})
        //       )
        //     : []

        // if (!fileSubmit) return false

        return {
          url: (_) => `wf-api/policy/${policyNumber!}/beneficiary`,
          body: mapData(),
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.BENEFICIARY_DESIGNATION),
          collerationId: policyNumber ?? '',
          transaction: TransactionType.BENEFICIARY_DESIGNATION,
          // documents: fileSubmit,
          method: 'PUT',
          uploadedFilesInfo: getUploadedFilesInfo()
        }
      }
      return false
    },
    clear: () => {
      initBen()
    }
  })

  const mapData = (): BeneficiaryDesignationData.BeneficiaryToSubmit[] => {
    const bens = getValues('beneficiaries') || []
    return bens.map((b, index) => ({
      percentage: Number(b.benefitShare) ?? 0,
      relationshipType: b.relationship?.value ?? '',
      customer: {
        firstName: b.givenName ?? '',
        surName: b.surname ?? '',
        dob: b.dob ? moment(b.dob).format('DD/MM/YYYY') : '',
        sex: b.gender?.value ?? '',
        nationality: b.nationality?.value ?? '',
        clientId: !b.enable ? b.clientID || '' : '',
        occupation: b.occupation?.value ?? '',
        externalIds: {
          SOE: b.idType?.value ?? '',
          SOE_VALUE: b.idNumber ?? '',
          ISSUED_BY: b.issueBy?.value ?? '',
          BC_PROVINCE_CODE: b.idType?.value === BenIDCard.BIRTH_CERTIFICATE ? b.issueBy?.value ?? '' : ''
        },
        contactDetails: {
          EMAIL: {
            channel: '',
            value: b.email ?? ''
          },
          PHONE: {
            countryCode: b.mobileCode?.value ?? '',
            channel: '',
            value: b.mobileNumber ?? ''
          }
        },
        addressDetails: {
          ADDRESS: {
            line1: b.street ?? '',
            city: b.city?.label ?? '',
            district: b.district?.label ?? '',
            subDistrict: b.ward?.label ?? '',
            countryCode: b.country?.value ?? '',
            location: b.ward?.value ?? ''
          },
          FOREIGN_ADDRESS: {
            line1: b.foreignAddress ?? '',
            countryCode: b.countryOfForeignAddress?.value ?? ''
          }
        },
        attributesExt: {
          NATIONALITY_2: b.nationality2?.value ?? '',
          nationality2Name: b.nationality2?.label ?? '',
          countryName: b.country?.label ?? '',
          districtName: b.district?.label ?? '',
          wardName: b.ward?.label ?? ''
        }
      }
    }))
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:BENEFICIARY_DESIGNATION'), navigate: null }
      ])
    }
  }, [isFocused])

  const {
    detail,
    countriesOptions,
    provinceOptions,
    mobileCodeOptions,
    relationshipMaleOptions,
    relationshipFemaleOptions,
    careerOptions
  } = pipe(
    ZIO.zipPar(
      BeneficiaryDesignationService.getDetail(policyNumber!),
      GeneralService.getCountries,
      GeneralService.getProvinces,
      GeneralService.getMobileCodes,
      GeneralService.getRelationship(),
      GeneralService.getOccupations,
      BeneficiaryDesignationService.getSeaBankMessgeList(policyNumber ?? '', clientNumber ?? '')
    ),
    ZIO.map(([detail, countries, provinces, mobileCodes, relationships, occupations, warningMessage]) => {
      //console.log('detail________BeneficiaryDesignationService', detail)

      const countriesOptions = countries.map((c) => ({
        value: c.code,
        label: c.name
      }))

      const provinceOptions = provinces.map((p) => ({
        value: p.code,
        label: p.name
      }))

      const mobileCodeOptions = mobileCodes.map((m) => ({
        value: m.code,
        label: '+' + m.code
      }))

      const relationshipMaleOptions = relationships
        .filter((r) => r.beneficiaryDesignation === 'Y' && r.male === 'Y')
        .map((r) => ({
          value: r.code,
          label: i18n.language === 'vi' ? r.nameVn : r.nameEn
        }))

      const relationshipFemaleOptions = relationships
        .filter((r) => r.beneficiaryDesignation === 'Y' && r.female === 'Y')
        .map((r) => ({
          value: r.code,
          label: i18n.language === 'vi' ? r.nameVn : r.nameEn
        }))

      const careerOptions = occupations.map((c) => ({
        value: c.code,
        label: c.name
      }))

      if (!!warningMessage) {
        showToast(warningMessage, 'warning', undefined, 30000)
      }
      return {
        detail,
        countriesOptions,
        provinceOptions,
        mobileCodeOptions,
        relationshipMaleOptions,
        relationshipFemaleOptions,
        careerOptions
      }
    }),
    ErrorHandling.runDidUpdate([i18n.language, policyNumber])
  ) || {
      countriesOptions: [],
      provinceOptions: [],
      mobileCodeOptions: [],
      relationshipMaleOptions: [],
      relationshipFemaleOptions: [],
      careerOptions: []
    }

  const idCardOptions = React.useMemo(() => {
    return BenIDCardOption((value) => t(`submission:${value}`))
  }, [i18n.language])

  const genderOptions = React.useMemo(() => {
    return Gender.map((g) => ({
      label: i18n.language === 'vi' ? g.nameVi : g.nameEn,
      value: g.value
    }))
  }, [i18n.language])

  const initBen = () => {
    //console.log('adasdasdasd a as ', detail)
    if (detail) {
      const defaultCountry = countriesOptions.find((c) => c.value === 'VN')
      reset({ beneficiaries: [], fileAttachments: [] })
      if (detail.length > 0) {

        detail.map((d, index) => {
          const phoneCode = mobileCodeOptions.find((m) => m.value === d.telCode)
          const idType = idCardOptions.find((i) => i.value === d.idCardType)
          const issueBy = provinceOptions.find((p) => p.value === d.issueAt)
          const gender = genderOptions.find((g) => g.value === d.gender)
          const relationship =
            d.gender === 'M'
              ? relationshipMaleOptions.find((r) => r.value === d.relationWithOwner)
              : relationshipFemaleOptions.find((r) => r.value === d.relationWithOwner)
          const nationality = countriesOptions.find((c) => c.value === d.nationality)
          const nationality2 = countriesOptions.find((_) => _.value === d.nationality2) || null
          getDistrictList(index, d.city, d.district)
          getWardList(index, d.city, d.district, d.ward)
          append({
            idType: idType || null,
            dob: !!d.dob ? moment(d.dob).toDate() : null,
            email: !!d.email ? d.email : null,
            givenName: !!d.firstName ? d.firstName : null,
            surname: !!d.givenName ? d.givenName : null,
            idNumber: !!d.idNumber ? d.idNumber : null,
            issueBy: issueBy || null,
            relationship: relationship || null,
            mobileNumber: !!d.mobileNumber ? d.mobileNumber : null,
            mobileCode: phoneCode ?? { value: '84', label: '+84' },
            gender: gender || null,
            benefitShare: !!d.percentage ? d.percentage + '' : '0',
            nationality: nationality || null,
            clientID: d.clientID || null,
            enable: false,
            requireIssueBy: false,
            country: defaultCountry || null,
            occupation: careerOptions.find((_) => _.value === d.occupation) || null,
            city: provinceOptions.find((_) => _.value === d.city) || null,
            district: districtList.find((_) => _.value === d.district) || null,
            ward: wardList.find((_) => _.value === d.ward) || null,
            street: d.address,
            foreignAddress: d.foreignAddress,
            nationality2: nationality2
          })
          setValueForForeignAddressRequired(index, nationality, nationality2)
        })
      } else {
        append({
          idType: undefined,
          dob: undefined,
          email: undefined,
          givenName: undefined,
          surname: undefined,
          idNumber: undefined,
          issueBy: undefined,
          relationship: undefined,
          mobileNumber: undefined,
          mobileCode: { value: '84', label: '+84' },
          gender: { label: i18n.language === 'vi' ? 'Nam' : 'Male', value: 'M' },
          benefitShare: undefined,
          nationality: undefined,
          clientID: undefined,
          enable: true,
          requireIssueBy: false,
          country: defaultCountry || null
        })
      }
    }
  }

  React.useEffect(() => {
    initBen()
    return () => {
      initBen()
    }
  }, [detail])

  const idTypeOps = (dob: Date) => {
    if (moment().subtract(16, 'years').isAfter(moment(dob))) {
      return idCardOptions.filter((i) => i.value !== BenIDCard.BIRTH_CERTIFICATE)
    }
    return idCardOptions
  }

  const getDistrictList = (index: number, provinceCode: string, oldDistrictCode?: string) => {
    setDistrictList([])
    setValue(`beneficiaries.${index}.district`, null)
    pipe(
      !!provinceCode ? GeneralService.getDistricts(provinceCode) : ZIO.succeed([]),
      ZIO.map((districtList) => {
        const filterDistrict = districtList.map((item) => ({ label: item.name, value: item.code }))
        setDistrictList(filterDistrict)
        !!oldDistrictCode && setValue(`beneficiaries.${index}.district`, filterDistrict.find(x => x.value === oldDistrictCode) || null)
        return filterDistrict
      }),
      ZIO.mapError((_) => {
        return Throwable('call get districts api with error')
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getWardList = (index: number, provinceCode?: string, districtCode?: string, oldWardCode?: string) => {
    setWardList([])
    setValue(`beneficiaries.${index}.ward`, null)
    pipe(
      !!provinceCode && !!districtCode ? GeneralService.getWards({ provinceCode, districtCode }) : ZIO.succeed([]),
      ZIO.map((wardList) => {
        const mapWards = wardList.map((_) => ({
          value: _.code,
          label: _.name
        }))
        setWardList(mapWards)
        !!oldWardCode && setValue(`beneficiaries.${index}.ward`, mapWards.find(x => x.value === oldWardCode) || null)
        return mapWards
      }),
      ZIO.mapError((_) => {
        return Throwable('call get wards api with error')
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const setValueForForeignAddressRequired = (index: number, nationality: SelectOption | null | undefined, nationality2: SelectOption | null | undefined) => {
    setValue(`beneficiaries.${index}.foreignAddressRequired`, (!!nationality?.value && nationality?.value !== 'VN') || (!!nationality2?.value && nationality2?.value !== 'VN'))
  }

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:ListOfBeneficiary').toUpperCase()}</SC.SessionText>
      </SC.Padding>
      {fields.map((value, index) => {
        const enable = getValues(`beneficiaries.${index}.enable`) ?? true
        const titleName = `${getValues(`beneficiaries.${index}.surname`) ?? ''} ${getValues(`beneficiaries.${index}.givenName`) ?? ''
          }`
        const disableInput = !enable || isConfirmed
        return (
          <SC.Container key={`____lists_${index}`}>
            <Panel
              containerStyle={{ backgroundColor: '#fafafa' }}
              title={
                <SC.Row>
                  <SC.CenterSelf>
                    <SC.SessionText>
                      {!enable || isConfirmed ? titleName : t('submission:BeneficiaryInformation')}
                    </SC.SessionText>
                  </SC.CenterSelf>
                  <SC.Divider width={8} />
                  <SC.CenterSelf>
                    <View style={{ borderRadius: 4, backgroundColor: '#397be6', paddingHorizontal: 5 }}>
                      {(!enable || isConfirmed) && getValues(`beneficiaries.${index}.benefitShare`) ? (
                        <SC.BoldText color={'white'}>{`${getValues(
                          `beneficiaries.${index}.benefitShare`
                        )} %`}</SC.BoldText>
                      ) : null}
                    </View>
                  </SC.CenterSelf>
                </SC.Row>
              }
            >
              <SC.RowWrap>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.idType`}
                    render={({ field: { value, onChange } }) => {
                      const dob = getValues(`beneficiaries.${index}.dob`)
                      return (
                        <ConfirmView
                          title={t('submission:idType')}
                          value={value?.label ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <Select
                            options={dob ? idTypeOps(dob) : idCardOptions}
                            label={t('submission:idType')}
                            onChange={(v) => {
                              onChange(v)
                              if (v?.value === BenIDCard.BIRTH_CERTIFICATE) {
                                setValue(`beneficiaries.${index}.requireIssueBy`, true)
                              } else {
                                setValue(`beneficiaries.${index}.requireIssueBy`, false)
                              }
                              trigger(`beneficiaries.${index}.issueBy`)
                            }}
                            value={value}
                            required={enable}
                            errorMessage={_.get(errors, `beneficiaries.${index}.idType.message`)}
                            placeholder={t('common:Select')}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.idNumber`}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:idNumber')}
                          value={value ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <Input
                            title={t('submission:idNumber')}
                            onChange={onChange}
                            value={value || undefined}
                            required={enable}
                            onBlur={onBlur}
                            errorMessage={_.get(errors, `beneficiaries.${index}.idNumber.message`)}
                            placeholder={t('submission:idNumberPlaceHolder')}
                            maxLength={24}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.issueBy`}
                    render={({ field: { value, onChange } }) => {
                      const requireIssue = getValues(`beneficiaries.${index}.requireIssueBy`) ?? false
                      return (
                        <ConfirmView
                          title={t('submission:IssueBy')}
                          value={value?.label ?? '-'}
                          isConfirmed={disableInput}
                          required={requireIssue}
                        >
                          <SelectSearch
                            options={provinceOptions}
                            label={t('submission:IssueBy')}
                            onChange={onChange}
                            value={value}
                            errorMessage={_.get(errors, `beneficiaries.${index}.issueBy.message`)}
                            placeholder={t('common:Select')}
                            popupIcon={<assets.ArrowDownDropdownIcon />}
                            required={requireIssue}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>

              <SC.RowWrap>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.surname`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:Surname')}
                          value={value ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <Input
                            title={t('submission:Surname')}
                            onChange={onChange}
                            value={value || undefined}
                            required={enable}
                            maxLength={60}
                            errorMessage={_.get(errors, `beneficiaries.${index}.surname.message`)}
                            placeholder={t('submission:SurNamePlaceHolder')}
                            containerStyle={{ paddingTop: 6 }}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.givenName`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:GivenName')}
                          value={value ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <Input
                            title={t('submission:GivenName')}
                            onChange={onChange}
                            value={value || undefined}
                            required={enable}
                            maxLength={60}
                            errorMessage={_.get(errors, `beneficiaries.${index}.givenName.message`)}
                            placeholder={t('submission:GivenNamePlaceHolder')}
                            containerStyle={{ paddingTop: 6 }}
                          />
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.dob`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:DateOfBirth')}
                          value={moment(value ?? new Date()).format('DD/MM/yyyy') ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <SC.Padding top={6}>
                            <DatePicker
                              label={t('submission:DateOfBirth')}
                              onChange={onChange}
                              value={value}
                              required={enable}
                              errorMessage={_.get(errors, `beneficiaries.${index}.dob.message`)}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>

              <SC.RowWrap>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.gender`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:Gender')}
                          value={value?.label ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <SC.Padding top={8}>
                            <Select
                              options={genderOptions}
                              label={t('submission:Gender')}
                              onChange={onChange}
                              value={value}
                              required={enable}
                              errorMessage={_.get(errors, `beneficiaries.${index}.gender.message`)}
                              placeholder={t('common:Select')}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.relationship`}
                    render={({ field: { value, onChange } }) => {
                      const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                      const gender = watch(`beneficiaries.${index}.gender`)
                      return (
                        <ConfirmView
                          title={t('submission:Relationship')}
                          value={value?.label ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <SC.Padding top={8}>
                            <SelectSearch
                              options={
                                !gender
                                  ? []
                                  : gender.value === 'M'
                                    ? relationshipMaleOptions
                                    : relationshipFemaleOptions
                              }
                              label={t('submission:Relationship')}
                              onChange={onChange}
                              value={wrapValue}
                              required={enable}
                              errorMessage={_.get(errors, `beneficiaries.${index}.relationship.message`)}
                              placeholder={t('common:Select')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.nationality`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:Nationality')}
                          value={value?.label ?? ''}
                          isConfirmed={disableInput}
                          required={enable}
                        >
                          <SC.Padding top={8}>
                            <SelectSearch
                              options={countriesOptions}
                              label={t('submission:Nationality')}
                              onChange={(e) => {
                                onChange(e)
                                setValueForForeignAddressRequired(index, e, watch(`beneficiaries.${index}.nationality2`))
                              }}
                              value={value}
                              required
                              errorMessage={_.get(errors, `beneficiaries.${index}.nationality.message`)}
                              placeholder={t('common:Select')}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>

              <SC.RowWrap>
                <RView>
                  <ConfirmView
                    title={t('submission:PhoneNumber')}
                    value={
                      getValues(`beneficiaries.${index}.mobileNumber`)
                        ? `${getValues(`beneficiaries.${index}.mobileCode.label`) ?? ''}  ${getValues(
                          `beneficiaries.${index}.mobileNumber`
                        )}`
                        : '-'
                    }
                    isConfirmed={disableInput}
                    required={false}
                  >
                    <SC.Padding vertical={3}>
                      <View style={{ marginBottom: 2 }}>
                        <SC.TitleText>{t('submission:PhoneNumber')}</SC.TitleText>
                      </View>

                      <SC.Row>
                        <View style={{ width: 120 }}>
                          <Controller
                            control={control}
                            name={`beneficiaries.${index}.mobileCode`}
                            render={({ field: { value, onChange } }) => {
                              const wrapValue = { label: value?.label ?? '', value: value?.value ?? '' }
                              return (
                                <Select
                                  value={wrapValue}
                                  options={mobileCodeOptions}
                                  onChange={onChange}
                                  placeholder={'+84'}
                                />
                              )
                            }}
                          />
                        </View>
                        <View style={{ flex: 1, paddingLeft: 15 }}>
                          <Controller
                            control={control}
                            name={`beneficiaries.${index}.mobileNumber`}
                            render={({ field: { value, onChange, onBlur } }) => {
                              return (
                                <Input
                                  onChange={onChange}
                                  value={value || undefined}
                                  placeholder={t('submission:MobileNumberPlaceHolder')}
                                  onBlur={onBlur}
                                  maxLength={10}
                                />
                              )
                            }}
                          />
                        </View>
                      </SC.Row>
                    </SC.Padding>
                  </ConfirmView>
                  {_.get(errors, `beneficiaries.${index}.mobileNumber.message`) && (
                    <SC.ErrorText>{_.get(errors, `beneficiaries.${index}.mobileNumber.message`)}</SC.ErrorText>
                  )}
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.email`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView
                          title={t('submission:Email')}
                          value={value ?? '-'}
                          isConfirmed={disableInput}
                          required={false}
                        >
                          <SC.Padding vertical={3}>
                            <Input
                              // options={[]}
                              title={t('submission:Email')}
                              onChange={onChange}
                              value={value || undefined}
                              maxLength={50}
                              errorMessage={_.get(errors, `beneficiaries.${index}.email.message`)}
                              placeholder={t('submission:EmailPlaceHolder')}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.benefitShare`}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <ConfirmView title={t('submission:BenefitShare')} value={value ?? ''} isConfirmed={isConfirmed}>
                          <SC.Padding vertical={3}>
                            <Input
                              title={t('submission:BenefitShare')}
                              onChange={onChange}
                              value={value || undefined}
                              required
                              maxLength={3}
                              errorMessage={_.get(errors, `beneficiaries.${index}.benefitShare.message`)}
                              placeholder={t('submission:BenefitSharePlaceHolder')}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>
              <SC.RowWrap>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.occupation`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('submission:Occupation')} value={value?.label ?? ''} isConfirmed={isConfirmed} required={!isConfirmed}>
                          <SC.Padding vertical={3}>
                            <SelectSearch
                              required={!isConfirmed}
                              label={t('submission:Occupation')}
                              options={careerOptions}
                              placeholder={t('common:Select')}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              errorMessage={error?.message}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.country`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('submission:Country')} value={value?.label ?? ''} isConfirmed={true} required={!isConfirmed}>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.city`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('requestInfo:City')} value={value?.label ?? ''} isConfirmed={isConfirmed} required={!isConfirmed}>
                          <SC.Padding vertical={3}>
                            <SelectSearch
                              required={!isConfirmed}
                              label={t('requestInfo:City')}
                              options={provinceOptions}
                              placeholder={t('common:Select')}
                              onChange={(val) => {
                                onChange(val)
                                getDistrictList(index, val?.value ?? '')
                                getWardList(index)
                              }}
                              onBlur={onBlur}
                              value={value}
                              maxLength={100}
                              errorMessage={error?.message}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>
              <SC.RowWrap>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.district`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('requestInfo:District')} value={value?.label ?? ''} isConfirmed={isConfirmed} required={!isConfirmed}>
                          <SC.Padding vertical={3}>
                            <SelectSearch
                              required={!isConfirmed}
                              label={t('requestInfo:District')}
                              options={districtList}
                              placeholder={t('common:Select')}
                              onChange={(val) => {
                                onChange(val)
                                getWardList(index, watch(`beneficiaries.${index}.city`)?.value ?? '', val?.value ?? '')
                              }}
                              onBlur={onBlur}
                              value={value}
                              maxLength={100}
                              errorMessage={error?.message}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.ward`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('requestInfo:Ward')} value={value?.label ?? ''} isConfirmed={isConfirmed} required={!isConfirmed}>
                          <SC.Padding vertical={3}>
                            <SelectSearch
                              required={!isConfirmed}
                              label={t('requestInfo:Ward')}
                              options={wardList}
                              placeholder={t('common:Select')}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              maxLength={100}
                              errorMessage={error?.message}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>
              <SC.RowWrap>
                <RView width={'66.66%'}>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.street`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('submission:Street')} value={value ?? ''} isConfirmed={isConfirmed} required={!isConfirmed}>
                          <SC.Padding vertical={3}>
                            <Input
                              required={!isConfirmed}
                              title={t('submission:Street')}
                              maxLength={50}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value ?? ''}
                              errorMessage={error?.message}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.nationality2`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('submission:Nationality2')} value={value?.label ?? ''} isConfirmed={isConfirmed} required={enable}>
                          <SC.Padding vertical={3}>
                            <SelectSearch
                              label={t('submission:Nationality2')}
                              options={countriesOptions}
                              placeholder={t('common:Select')}
                              onChange={(e) => {
                                onChange(e)
                                setValueForForeignAddressRequired(index, watch(`beneficiaries.${index}.nationality`), e)
                              }}
                              onBlur={onBlur}
                              value={value}
                              maxLength={100}
                              errorMessage={error?.message}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>
              <SC.RowWrap>
                <RView width={'66.66%'}>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.foreignAddress`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('submission:ForeignAddress')} value={value ?? ''} isConfirmed={isConfirmed} required={enable}>
                          <SC.Padding vertical={3}>
                            <Input
                              required={watch(`beneficiaries.${index}.foreignAddressRequired`)}
                              title={t('submission:ForeignAddress')}
                              value={value ?? ''}
                              maxLength={500}
                              onChange={onChange}
                              onBlur={onBlur}
                              errorMessage={error?.message}
                            />
                          </SC.Padding>
                        </ConfirmView>

                      )
                    }}
                  />
                </RView>
                <RView>
                  <Controller
                    control={control}
                    name={`beneficiaries.${index}.countryOfForeignAddress`}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                      return (
                        <ConfirmView title={t('submission:CountryOfForeignAddress')} value={value?.label ?? ''} isConfirmed={isConfirmed} required={enable}>
                          <SC.Padding vertical={3}>
                            <SelectSearch
                              required={watch(`beneficiaries.${index}.foreignAddressRequired`)}
                              label={t('submission:CountryOfForeignAddress')}
                              options={countriesOptions}
                              placeholder={t('common:Select')}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              maxLength={100}
                              errorMessage={error?.message}
                              popupIcon={<assets.ArrowDownDropdownIcon />}
                            />
                          </SC.Padding>
                        </ConfirmView>
                      )
                    }}
                  />
                </RView>
              </SC.RowWrap>
              {!isConfirmed && (
                <SC.FlexStartSelf>
                  <SC.Padding vertical={10}>
                    <IconButton
                      icon={<assets.DeleteBin />}
                      onPress={() => {
                        //remove(index)
                        setModalVisible(true)
                      }}
                      title={t('submission:RemoveBeneficiary')}
                    />
                  </SC.Padding>
                </SC.FlexStartSelf>
              )}
            </Panel>
            <ModalComponent
              title={t('submission:RemoveBeneficiary')}
              visible={modalVisible}
              onClose={() => setModalVisible(!modalVisible)}
              actions={[
                {
                  text: t('common:Ok'),
                  type: 'outline',
                  disabled: false,
                  loading: false,
                  onlyWide: false,
                  style: { height: 39 },
                  action: () => {
                    remove(index)
                    setModalVisible(!modalVisible)
                  }
                }
              ]}
            >
              <SC.Padding horizontal={20}>
                <SC.NormalText>{t('submission:DeleteThisBeneficiary')}</SC.NormalText>
              </SC.Padding>
            </ModalComponent>
          </SC.Container>
        )
      })}

      {!isConfirmed && (
        <SC.FlexStartSelf>
          <IconButton
            icon={<assets.PlusCircleIcon />}
            onPress={() => {
              if (beneficiaries.length < 15) {
                append({
                  //idType: {label: '', value: ''},
                  enable: true,
                  requireIssueBy: false,
                  gender: { label: i18n.language === 'vi' ? 'Nam' : 'Male', value: 'M' },
                  mobileCode: { value: '84', label: '+84' },
                  country: countriesOptions.find((c) => c.value === 'VN') || null
                })
              } else {
                showToast('Max 15 sections', 'info')
              }
            }}
            title={t('submission:AddNewBeneficiary')}
          />
        </SC.FlexStartSelf>
      )}

      <SC.Padding vertical={10}>
        <SC.Padding vertical={10}>
          <SC.SessionText>{t('utilities:FileAttachment').toUpperCase()}</SC.SessionText>
        </SC.Padding>
        <SC.Padding top={10}>
          <SC.TitleText>{t('submission:FileAttachment')}</SC.TitleText>
        </SC.Padding>
        <Controller
          control={control}
          name={'fileAttachments'}
          render={({ field: { value, onChange } }) => {
            const wrapValue = value?.map((v) => ({ ...v, uploadedDate: v.uploadedDate!, file: v.file! })) || []
            if (isConfirmed) {
              return (
                <SC.Container>
                  <SC.RowWrap>
                    {wrapValue.map((file, index) => {
                      return (
                        <RView>
                          <FilePresent file={file} key={`____file_${index}`} />
                        </RView>
                      )
                    })}
                  </SC.RowWrap>
                </SC.Container>
              )
            }

            return <ImgUploadMutiple maxNumFile={50} value={wrapValue} onChange={onChange} />
          }}
        />
      </SC.Padding>
    </SC.Container>
  )
}

const IconButton: React.FC<{ icon: JSX.Element; title: string; onPress: () => void }> = ({ title, onPress, icon }) => {
  return (
    <SC.ButtonContainer onPress={onPress}>
      {icon}
      <SC.Divider />
      <Text style={{ color: 'black', fontSize: 15, fontWeight: '600' }}>{title}</Text>
    </SC.ButtonContainer>
  )
}

const RView: React.FC<{ mobileWidth?: string | number; width?: string | number; padding?: number }> = ({
  mobileWidth = '100%',
  width = '33%',
  padding = 5,
  children
}) => {
  const { isMobile } = useMobile()
  const composeWidth = isMobile ? mobileWidth : width
  const composePadding = isMobile ? padding : 5

  return <View style={{ width: composeWidth, padding: composePadding }}>{children}</View>
}
