import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { ZIO } from '@mxt/zio'
import { LoanService } from './LoanService'
import { CashOut, RequestAuthFormDataSubmit, TransactionType } from './model'
import { POApi } from '../POApi'
import { SubmissionService } from './SubmissionService'

export namespace BonusSurrenderService {
  interface LoanEnquiryRequest {
    contractNumber: string
    bonusReserveValue: number
  }

  export interface SubmitData {
    policy: {
      policyNo: string
    }
    attributesExt: {
      TOTAL_PAYOUT_AMOUNT: number
      BONUS_AMOUNT: number
    }
    cashOutOptions: Array<CashOut.Option>
  }

  export const accountBalance = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/policy/account-balance`)(
          t.type({
            body: Maybe(
              t.type({
                accountGroups: Maybe(
                  t.array(
                    t.type({
                      amount: Maybe(t.number),
                      sacsCode: Maybe(t.string),
                      sacsType: Maybe(t.string)
                    })
                  )
                )
              })
            )
          })
        )({
          body: {
            policyNum,
            function: 'TOTAL',
            accountGroups: [
              {
                sacsCode: 'LE',
                sacsType: 'RV'
              },
              {
                sacsCode: 'LX',
                sacsType: 'DM'
              }
            ]
          }
        }),
        POApi.post(`wf-api/other/client-policy-verify`)(
          t.type({
            body: t.type({
              policyRecOuts: t.array(
                t.type({
                  errorCode: Maybe(t.string)
                })
              )
            })
          })
        )({
          body: {
            function: TransactionType.BONUS_SURRENDER,
            clientRecs: [{ clientNumber: '', securityNo: '' }],
            policyRecs: [{ policyNum }]
          }
        })
      ),
      ZIO.map(
        ([
          { body },
          {
            body: { policyRecOuts }
          }
        ]) => {
          const amountLERV =
            body?.accountGroups
              ?.filter(({ sacsCode, sacsType }) => sacsCode === 'LE' && sacsType === 'RV')
              .reduce((acc, { amount }) => acc + (amount ?? 0), 0) ?? 0
          const amountLXDM =
            body?.accountGroups
              ?.filter(({ sacsCode, sacsType }) => sacsCode === 'LX' && sacsType === 'DM')
              .reduce((acc, { amount }) => acc + (amount ?? 0), 0) ?? 0
          // const validPolicy = amountLERV > 0 && amountLXDM <= 0 && !policyRecOuts[0].errorCode
          const validPolicy = amountLERV > 0 && amountLXDM <= 0 ?
            (policyRecOuts && policyRecOuts.length > 0 ? !policyRecOuts[0].errorCode : true)
            : false
          return { amountLERV: Math.abs(amountLERV), validPolicy }
        }
      )
    )

  export const getAccountBalance = (policyNum: string) =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/policy/account-balance`)(
          t.type({
            body: Maybe(
              t.type({
                accountGroups: Maybe(
                  t.array(
                    t.type({
                      amount: Maybe(t.number),
                      sacsCode: Maybe(t.string),
                      sacsType: Maybe(t.string)
                    })
                  )
                )
              })
            )
          })
        )({
          body: {
            policyNum,
            function: 'TOTAL',
            accountGroups: [
              {
                sacsCode: 'LE',
                sacsType: 'RV'
              }
            ]
          }
        }),
        POApi.post(`wf-api/other/client-policy-verify`)(
          t.type({
            body: t.type({
              policyRecOuts: t.array(
                t.type({
                  errorCode: Maybe(t.string)
                })
              )
            })
          })
        )({
          body: {
            function: TransactionType.BONUS_SURRENDER,
            clientRecs: [{ clientNumber: '', securityNo: '' }],
            policyRecs: [{ policyNum }]
          }
        })
      ),
      ZIO.map(
        ([
          { body },
          {
            body: { policyRecOuts }
          }
        ]) => {
          const amountLERV =
            body?.accountGroups
              ?.filter(({ sacsCode, sacsType }) => sacsCode === 'LE' && sacsType === 'RV')
              .reduce((acc, { amount }) => acc + (amount ?? 0), 0) ?? 0
          const existsLXDM = body?.accountGroups?.some(
            ({ sacsCode, sacsType }) => sacsCode === 'LX' && sacsType === 'DM'
          )
          const validPolicy = amountLERV > 0 && !existsLXDM && !policyRecOuts[0].errorCode
          return { amountLERV: Math.abs(amountLERV), validPolicy }
        }
      )
    )

  export const getPolicyLoanEnquiry = (policyNum: string) =>
    pipe(
      LoanService.getPolicyLoanEnquiry(policyNum),
      ZIO.map((policyLoan) => {
        const principalAplOpl = Number(policyLoan.balanceAPL) + Number(policyLoan.balanceOPL)
        const interestAplOpl = Number(policyLoan.interestAPL) + Number(policyLoan.interestOPL)
        return {
          principalAplOpl: principalAplOpl,
          interestAplOpl: interestAplOpl
        }
      })
    )

  export const bonusSurrenderPolicyLoanEnquiry = (body: LoanEnquiryRequest, isFirst = false) =>
    pipe(
      SubmissionService.getPolicy(body.contractNumber),
      ZIO.flatMap((policy) =>
        pipe(
          SubmissionService.getClientsByPolicy(body.contractNumber),
          ZIO.map((clientByPolicy) => ({
            clientByPolicy,
            policy
          }))
        )
      ),
      ZIO.flatMap(({ policy, clientByPolicy }) =>
        POApi.post(`wf-api/bo/bonus-surrender-policy-loan-enquiry`)(
          t.type({
            body: t.type({
              bonusValue: t.number,
              bonusReserveValue: t.number,
              cashValue: t.number
            })
          })
        )({
          body: {
            bonusReserveValue: body.bonusReserveValue,
            contractNumber: isFirst ? `${body.contractNumber}CH` : body.contractNumber,
            inCrtable: policy.body.basicCode,
            inLifeNo: clientByPolicy.laList[0].life
          }
        })
      )
    )

  export const submit = (body: SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
    SubmissionService.submit(t.unknown)(
      `wf-api/policy/${body.policy.policyNo}/surrender-bonus`,
      { body },
      body.policy.policyNo,
      requestauth,
      []
    )
}
