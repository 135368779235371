import { mapTransactionType, SelectOption, SubTaskCount, TaskSource, TransactionType } from '@pulseops/common'
import { TaskFilterForm } from './TaskFilterForm'
export interface QueryTaskOptions {
  subTask?: SubTaskCount
  pageIndex: number
  pageSize?: number
  searchPolicyNum?: string
  filter?: TaskFilterForm.Validated
  isTeam?: boolean
  category?: string
  tabIndex?: number
}

export const sourceOptions: SelectOption[] = [
  { label: 'Pulse', value: TaskSource.PULSE },
  { label: 'Banca', value: TaskSource.BANCA },
  { label: 'Banca Sea', value: TaskSource.BANCA_SEA },
  { label: 'Banca Seabank', value: TaskSource.BANCA_SEABANK },
  { label: 'PRUonline', value: TaskSource.PRUONLINE },
  { label: 'Zalo', value: TaskSource.ZALO },
  { label: 'Ekiosk', value: TaskSource.EKIOSK },
  { label: 'Pulse4Ops', value: TaskSource.PULSE4OPS },
  { label: 'Landing page', value: TaskSource.LANDING_PAGE },
  { label: 'Onlinepayment', value: TaskSource.ONLINEPAYMENT },
  { label: 'Credit Life Portal', value: TaskSource.CREDITLIFEPORTAL },
  { label: 'Outbound', value: TaskSource.OUTBOUND },
  { label: 'Inbound', value: TaskSource.INBOUND },
  {label: 'Prudaily', value: TaskSource.PRUDAILY}
]

export const sourceDAOptions: SelectOption[] = [
  { label: 'PruDaily', value: TaskSource.PRUDAILY },
  { label: 'PruForce', value: TaskSource.PRUFORCE }
]

export const sourceIDCOptions: SelectOption[] = [
  { label: 'Prudaily', value: TaskSource.PRUDAILY }
]

const mapSourceOptions = new Map<string, string>([
  [TaskSource.PULSE, 'Pulse'],
  [TaskSource.BANCA, 'Banca'],
  [TaskSource.BANCA_SEA, 'Banca Sea'],
  [TaskSource.PRUONLINE, 'Pruonline'],
  [TaskSource.ZALO, 'Zalo'],
  [TaskSource.EKIOSK, 'Ekiosk'],
  [TaskSource.PULSE4OPS, 'Pulse4Ops'],
  [TaskSource.LANDING_PAGE, 'Landing page'],
  [TaskSource.ONLINEPAYMENT, 'Onlinepayment'],
  [TaskSource.CREDITLIFEPORTAL, 'Credit Life Portal'],
  [TaskSource.OUTBOUND, 'Outbound'],
  [TaskSource.INBOUND, 'Inbound']
])

export const getSourceLabel = (source: string) => mapSourceOptions.get(source) || source

const TransactionLabel = (type: TransactionType | null): string => (type ? mapTransactionType.get(type) || '-' : '-')

const transactions = (type: TransactionType) => ({
  label: TransactionLabel(type),
  value: type
})
export const transactionTypeDAOption = [
  transactions(TransactionType.AGENT_ONBOARD_NEW),
  transactions(TransactionType.AGENT_ONBOARD_REINS),
  transactions(TransactionType.AGENT_TERMINATION_AG),
  transactions(TransactionType.AGENT_TRANSFER_POLICY),
  transactions(TransactionType.AGENT_OFFICE_MOVING),
  transactions(TransactionType.AGENT_UNIT_TRANSFER)
]
export const transactionTypeOption = [
  transactions(TransactionType.BENEFICIARY_DESIGNATION),
  transactions(TransactionType.CHANGE_CONTACT_INFO_EMAIL),
  transactions(TransactionType.CHANGE_CONTACT_INFO_PHONE),
  transactions(TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL),
  transactions(TransactionType.CHANGE_CLIENT_INFO),
  transactions(TransactionType.CHANGE_CONTACT_INFO_ADDRESS),
  transactions(TransactionType.CHANGE_CONTACT_INFO),
  transactions(TransactionType.CHANGE_SUM_ASSURED),
  transactions(TransactionType.CHANGE_PREMIUM),
  transactions(TransactionType.LOAN),
  transactions(TransactionType.LOAN_STATEMENTS),
  transactions(TransactionType.PRUCASH_PAYMENT),
  transactions(TransactionType.MATURITY),
  transactions(TransactionType.MATURITY_ADVANCE),
  transactions(TransactionType.BILLING_CHANGE),
  transactions(TransactionType.BILLING_CHANGE_CORPORATE),
  transactions(TransactionType.BILLING_FREQUENCY_REVERSAL),
  transactions(TransactionType.REINSTATEMENT),
  transactions(TransactionType.FULL_SURRENDER_REVERSAL),
  transactions(TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT),
  transactions(TransactionType.TOP_UP),
  transactions(TransactionType.EXCESS_PREMIUM),
  transactions(TransactionType.CANCEL_FROM_INCEPTION),
  transactions(TransactionType.CANCEL_RIDER),
  transactions(TransactionType.PARTIAL_WITHDRAWAL),
  transactions(TransactionType.PARTIAL_SURRENDER),
  transactions(TransactionType.FULL_SURRENDER),
  transactions(TransactionType.POLICY_CERTIFICATE),
  transactions(TransactionType.COMPLAINT_AND_INQUIRY),
  transactions(TransactionType.COMPLAINT),
  transactions(TransactionType.INQUIRY),
  transactions(TransactionType.PRODUCT_OPTION_SWITCHING),

  transactions(TransactionType.ZALO_OTHERS),
  transactions(TransactionType.ZALO_CLAIM_DECISION),
  transactions(TransactionType.ZALO_PREMIUM_COLLECTION),
  transactions(TransactionType.ZALO_RECOMMENDATION),
  transactions(TransactionType.ZALO_REQUEST_PROCESS),
  transactions(TransactionType.E_SERVICE_REGISTRATION),
  transactions(TransactionType.GO_ABROAD),
  transactions(TransactionType.SPECIAL_BONUS),
  transactions(TransactionType.SPECIAL_BONUS_CUSTOMER),
  transactions(TransactionType.PREMIUM_REDIRECTION),
  transactions(TransactionType.SIGNATURE_REGISTRATION),
  transactions(TransactionType.EXCELLENT_STUDENT_AWARD),
  transactions(TransactionType.BONUS_SURRENDER),
  transactions(TransactionType.FUND_SWITCHING),
  transactions(TransactionType.STOP_APL),
  transactions(TransactionType.ASSIGNMENT),
  transactions(TransactionType.FATCA_DECLARATION),
  transactions(TransactionType.INHERITANCE),
  transactions(TransactionType.PAID_UP),
  transactions(TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE),
  transactions(TransactionType.PRUKID369),
  transactions(TransactionType.HEALTH_DECLARATION),
  transactions(TransactionType.SCHEDULED_PAYMENT_STATEMENT),

  transactions(TransactionType.COMMON_REQUEST_FORM),
  transactions(TransactionType.RIDER_ALTERATION),
  transactions(TransactionType.MAJOR_CHANGE_IN_FREE_LOOK),
  transactions(TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK),
  transactions(TransactionType.REDATING),
  transactions(TransactionType.PRODUCT_OPTION_SWITCHING),
  transactions(TransactionType.RIDER_REINSTATEMENT),
  transactions(TransactionType.MAJOR_COMBINED_CHANGE),

  transactions(TransactionType.OTHER_MAJOR_ALTERATION),
  transactions(TransactionType.OTHER_MINOR_ALTERATION),
  transactions(TransactionType.OTHER_PAYOUT),

  transactions(TransactionType.CASHLESS_O1),
  transactions(TransactionType.CASHLESS_O2),
  transactions(TransactionType.CASHLESS_O3),
  transactions(TransactionType.CASHLESS_DK),
  transactions(TransactionType.CASHLESS_C9),
  transactions(TransactionType.CASHLESS_A7),
  transactions(TransactionType.CASHLESS_S1),
  transactions(TransactionType.CASHLESS_P3),
  transactions(TransactionType.CASHLESS_S2),
  transactions(TransactionType.CASHLESS_B3),
  transactions(TransactionType.CASHLESS_S3),
  transactions(TransactionType.CASHLESS_C3),
  transactions(TransactionType.CASHLESS_U3),
  transactions(TransactionType.CASHLESS_V3),
  transactions(TransactionType.CASHLESS_I3),
  transactions(TransactionType.CASHLESS_C7),
  transactions(TransactionType.CASHLESS_X1),
  transactions(TransactionType.CASHLESS_T3),
  transactions(TransactionType.CASHLESS_P2),
  transactions(TransactionType.CASHLESS_V2),
  transactions(TransactionType.CASHLESS_M2),
  transactions(TransactionType.CASHLESS_A9),
  transactions(TransactionType.CASHLESS_S6),
  transactions(TransactionType.CASHLESS_L3),
  transactions(TransactionType.CASHLESS_D7),
  transactions(TransactionType.CASHLESS_M3),
  transactions(TransactionType.CASHLESS_V4),
  transactions(TransactionType.CASHLESS_A0),
  transactions(TransactionType.CASHLESS_I4),
  transactions(TransactionType.CASHLESS_P9),
  transactions(TransactionType.CASHLESS_I2),
  transactions(TransactionType.JOURNAL_ALTERATION),
  transactions(TransactionType.JOURNAL_PAYOUT),
  transactions(TransactionType.CHANGE_NATIONAL_ID_CARD),
  transactions(TransactionType.CASHLESS_M9),
  transactions(TransactionType.CASHLESS_D6),
  transactions(TransactionType.CASHLESS_D8),
  transactions(TransactionType.CASHLESS_D9),
  transactions(TransactionType.CASHLESS_V5),
  transactions(TransactionType.CHANGE_OCCUPATION_PERSONAL),
  transactions(TransactionType.CHANGE_DOB_GENDER),
  transactions(TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL),
  transactions(TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST),
  transactions(TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL),
  transactions(TransactionType.VOICE_RECORDING)
]

export const transactionTypeIDCOption = [
  transactions(TransactionType.VOICE_RECORDING)
]