import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Default = t.type({
  transactionType: t.literal(TransactionType.CHANGE_CONTACT_INFO_PHONE),
  payload: t.type({
    body: t.type({
      value: t.string
    })
  }),
  source: t.union([
    t.literal(SourceType.BANCA),
    t.literal(SourceType.EKIOSK),
    t.literal(SourceType.PRUGREAT),
    // t.literal(SourceType.PRUONLINE),
    t.literal(SourceType.ZALO)
  ])
})

const Pulse4Ops_BancaSea = t.type({
  transactionType: t.literal(TransactionType.CHANGE_CONTACT_INFO_PHONE),
  payload: t.type({
    body: t.type({
      contactDetails: t.type({
        PHONE: t.type({
          countryCode: t.string,
          value: t.string
        }),
        OFFICE_PHONE: t.type({
          countryCode: t.string,
          value: t.string
        }),
        SMS_IND: Maybe(
          t.type({
            value: Maybe(t.string)
          })
        )
      }),
      attributesExt: Maybe(
        t.type({
          PHONES_DUP: Maybe(
            t.array(
              t.type({
                clientId: Maybe(t.string),
                relationshipType: Maybe(t.string)
              })
            )
          ),
          mainPhoneNumber: Maybe(t.boolean)
        })
      ),
    }),
  }),
  otpTransaction: Maybe(t.type({
    otp: Maybe(t.string),
    receiverPhone: Maybe(t.string)
  })),
  source: t.union([t.literal(SourceType.PULSE4OPS), t.literal(SourceType.BANCA_SEA), t.literal(SourceType.PRUONLINE), t.literal(SourceType.OUTBOUND), t.literal(SourceType.INBOUND)])
})

const Pulse = t.type({
  transactionType: t.literal(TransactionType.CHANGE_CONTACT_INFO_PHONE),
  payload: t.type({
    body: t.type({
      countryCode: Maybe(t.string),
      value: Maybe(t.string)
    })
  }),
  source: t.literal(SourceType.PULSE)
})

export const ChangePhoneInfo = t.union([Pulse4Ops_BancaSea, Pulse, Default])
export type ChangePhoneInfo = t.TypeOf<typeof ChangePhoneInfo>
