import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import { withMinStringLength, withMaxStringLength, withStringPattern } from '@pulseops/submission/common'
import { withMessage } from 'io-ts-types'
import i18next from 'i18next'

export namespace PolicyOfOrtherClientForm {
  const POCform = t.type({
    policyNumber: pipe(
      withMessage(form2.string.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('common:PolicyNumber') })
      ),
      withStringPattern('^[0-9]*$', 'message:MS070018'),
      withMinStringLength(8),
      withMaxStringLength(8)
    ),
    poName: form2.string.required,
    installmentPremium: form2.string.required,
    totalPremium: form2.string.required,
    billingFrequency: form2.string.required,
    finalPolicyNum: form2.string.required,

    amount: withMessage(form2.string.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('submission:AmountToBePaid') })
    ),
    relationship: withMessage(form2.selectOption.required, () =>
      i18next.t('message:MS020001', { field: i18next.t('requestInfo:RelationshipWithMainifeAssured') })
    )
  })

  export const codec = t.type({
    list: t.array(POCform)
  })

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
