import { Animated, TouchableOpacity, View, StyleSheet, Text } from 'react-native'
//import styled from 'styled-components/native'
import { Autocomplete } from '@material-ui/lab'
import { assets, useMobile } from '@pulseops/common'
import { TextField } from '@material-ui/core'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export type PanelItem = {
  label: string
  value: string
}

export type PanelProp = {
  itemsSearch: Array<PanelItem>

  itemsGroup: Array<PanelGroupProps>
}

export type PanelGroupProps = {
  id: string
  name: string
  icon: () => JSX.Element
  children: Array<PanelItem>
}

const MAX_TRANS_Y = 200
const MIN_TRANS_Y = 0
const DURATION = 400

export const SearchPanel: React.FC<{ data: PanelItem[]; selected: (e: string) => void }> = ({ data, selected }) => {
  const [isHide, setHide] = useState<boolean>(false)
  const transY = useRef(new Animated.Value(200)).current
  const [isFocused, setFocused] = useState<boolean>(false)
  const { t } = useTranslation()
  const ref = React.useRef()
  // const [selected, setSelected] = useState<PanelItem>()

  const opacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  })

  const imageOpacity = transY.interpolate({
    inputRange: [0, 200],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  })

  const toggleView = () => {
    if (isHide) {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MAX_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(false))
    } else {
      transY.stopAnimation()
      Animated.timing(transY, {
        toValue: MIN_TRANS_Y,
        duration: DURATION,
        useNativeDriver: false
      }).start(() => setHide(true))
    }
  }

  return (
    <View style={styles.container}>
      <Animated.View
        style={{
          alignSelf: 'center',
          height: transY,
          // width: transY
          opacity: imageOpacity
        }}
      >
        <assets.PsImage />
      </Animated.View>

      <View style={{ width: '70%', flexDirection: 'row' }}>
        <Autocomplete
          ref={ref}
          id="combo-box-demo"
          options={data}
          style={{ flex: 1 }}
          freeSolo
          disableClearable
          renderInput={(params) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: 'white',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: isFocused ? 'red' : 'transparent',
                  alignSelf: 'center',
                  paddingVertical: 5
                }}
              >
                <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>
                  <assets.Search20Icon />
                </View>
                <TextField
                  {...params}
                  placeholder={'Enter code'}
                  style={{ fontStyle: 'italic' }}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    // endAdornment: (
                    // <React.Fragment>
                    //     {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                    //     {params.InputProps.endAdornment}
                    // </React.Fragment>
                    // ),
                    disableUnderline: true
                  }}
                  // InputLabelProps={{ shrink: true }}
                  fullWidth
                  onBlur={() => setFocused(false)}
                  onFocus={() => setFocused(true)}
                />
                <View style={{ alignSelf: 'center', paddingHorizontal: 10 }}>
                  <assets.MicroPhone />
                </View>
              </View>
            )
          }}
          getOptionLabel={(option) => option.value}
          onChange={(_event, value) => {
            if (!(typeof value === 'string')) {
              // navigationPage(value)
              // value.navigator()
              selected(value?.value)
              console.log('valueee', value)
            }
          }}
        />

        <View style={{ width: 20 }} />

        <TouchableOpacity
          onPress={toggleView}
          style={{
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 8,
            width: 45
            // height: 45
          }}
          activeOpacity={0.4}
        >
          {!isHide ? <assets.OpenMenu /> : <assets.CloseMenu />}
        </TouchableOpacity>
      </View>

      {/* <Animated.View
        style={{
          transform: [{ translateY: transY }],
          opacity: opacity,
          marginTop: 40,
          width: '100%',
          alignItems: 'center'
        }}
      >
        {data.itemsGroup.map((group, index) => {
          return <PanelView group={group} isHide={isHide} />
        })}
      </Animated.View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 20
  },
  pannel: {
    width: '90%',
    flexDirection: 'row',
    backgroundColor: 'white',
    shadowColor: 'white',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 30,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 20
  },
  pannelBody: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    minWidth: 500
  },
  pannelTitle: {
    fontWeight: 'bold',
    fontSize: 18,
    justifyContent: 'center',
    padding: 40
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: 'red',
    margin: 10
  },
  xBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#E5EAEF',
    alignItems: 'center',
    justifyContent: 'center'
  }
})
