import { ZIO, Task } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import { POApi } from '../POApi'
import { SpecialBonusData } from './model'
import { SubmissionService } from './SubmissionService'
import * as t from 'io-ts'
// import {
//   SpecialBonusData,
//   SubmissionService
// } from './index'

export namespace SpecialBonusService {
  //export const specialBonusRef = Ref.make<O.Option<SpecialBonusData.DetailData>>(O.none)

  export const getDetail = (policyNum: string): Task<SpecialBonusData.DetailData> => {
    const accountGroups1: SpecialBonusData.SBAccountGroups[] = [
      { sacsCode: 'LP', sacsType: 'SB' },
      { sacsCode: 'LI', sacsType: 'MO' },
      { sacsCode: 'LX', sacsType: 'TF' }
    ]

    const accountGroups2: SpecialBonusData.SBAccountGroups[] = [{ sacsCode: 'LP', sacsType: 'PS' }]

    return pipe(
      ZIO.zipPar(
        SubmissionService.getAccountBalance(policyNum, 'TOTAL', accountGroups1),
        SubmissionService.getAccountBalance(policyNum, 'B504', accountGroups2)
      ),
      ZIO.map(([accountBalance1, accountBalance2]) => {
        const balance1 = accountBalance1.body.accountGroups?.reduce(function (total, item) {
          return total + item.amount
        }, 0)
        const balance2 = accountBalance2.body.accountGroups?.reduce(function (total, item) {
          return total + item.amount
        }, 0)
        const specialBonus = Number(balance1 ?? 0) + Number(balance2 ?? 0)
        return {
          specialBonus: specialBonus
        }
      })
    )
  }

  export const getAccountBalance = (policyNum: string, transCode: string) =>
    pipe(
      POApi.post(`wf-api/policy/account-balance`)(
        t.type({
          body: Maybe(
            t.type({
              accountGroups: Maybe(
                t.array(
                  t.type({
                    amount: Maybe(t.number),
                    sacsCode: Maybe(t.string),
                    sacsType: Maybe(t.string)
                  })
                )
              )
            })
          )
        })
      )({
        body: {
          policyNum,
          function: transCode,
          accountGroups: [
            {
              sacsCode: 'LX',
              sacsType: 'TF'
            }
          ]
        }
      }),
      ZIO.map((response) => {
        return (
          response.body?.accountGroups
            ?.filter((item) => item.sacsCode === 'LX' && item.sacsType === 'TF')
            ?.reduce((sum, curr) => sum + (curr.amount || 0), 0) || 0
        )
      })
    )

  // export const submit = (policyNum: string, submitData: SpecialBonusData.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>  SubmissionService.submit(t.unknown)(
  //   `wf-api/policy/${submitData.policy.policyNo}/special-bonus`,
  //   {body: submitData}, policyNum, requestauth, [])
}
