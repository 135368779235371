import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions, ScrollView } from 'react-native'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { UploadDocumentsService, ErrorHandling, Divider, UploadDocumentData, TransactionType } from '@pulseops/common'
import { useLoading } from '@mxt/zio-react'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type Props = {
  taskId: string
  isUploadImaging?: boolean
}
export const UploadDocumentDetail: React.FC<Props> = ({ taskId, isUploadImaging }) => {
  const [loading, bindLoading] = useLoading(false)
  const { height } = useWindowDimensions()
  const composeHeigth = Math.max(height - 200, 400)
  // pipe(
  //   UploadDocumentsService.getHistoricUploadDocumentDetail(taskId),
  //   ZIO.map((value) => {
  //     console.log(value)
  //   })
  // )

  const requests = pipe(
    UploadDocumentsService.getHistoricUploadDocumentDetail(taskId), 
    ZIO.map((requests) => {
      return requests
    }),
    ZIO.tapError((_) => ZIO.unit),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || null

  const departmentsImaging = 
    isUploadImaging ?
      pipe(
        UploadDocumentsService.getDepartmentImaging(TransactionType.IMAGING_UPLOAD), 
        ZIO.map((requests) => {
        return requests
      }),
        ZIO.tapError((_) => ZIO.unit),
        bindLoading,
        ErrorHandling.runDidMount()
      ) || null : []

  const departments = 
    !isUploadImaging ?
      pipe(
        UploadDocumentsService.getDetail(), 
        ZIO.map((requests) => {
        return requests
      }),
        ZIO.tapError((_) => ZIO.unit),
        bindLoading,
        ErrorHandling.runDidMount()
      ) || null : { DepartmentList: [] }

  if (loading && !requests && (isUploadImaging ? !departmentsImaging : !departments)) {
    return (
      <View style={{ alignSelf: 'center' }}>
        <CircularProgress color={'primary'} />
      </View>
    )
  }
  return (
    <View style={[styles.container, { maxHeight: composeHeigth }]}>
      <ScrollView>
        {requests?.map((request) => {
          return <DetailView detail={request} departments={departments!} departmentsImaging={departmentsImaging!} isUploadImaging={isUploadImaging} />
        })}
      </ScrollView>
    </View>
  )
}

const DetailView: React.FC<{
  detail: UploadDocumentsService.DetailUploadDocument
  departments: UploadDocumentData.DetailData
  departmentsImaging: UploadDocumentData.DepartmentT17[]
  isUploadImaging?: boolean
}> = ({ detail, departments, departmentsImaging, isUploadImaging }) => {
  const { t, i18n } = useTranslation()
  //const [ loading, bindLoading ] = useLoading()
  const departName = departments.DepartmentList.find((v) => v.departmentShortName === detail.department)
  const showDeparmentName = departName
    ? i18n.language === 'vi'
      ? departName.departmentVN
      : departName.departmentEN
    : ''
  
  const showDeparmentImaging = departmentsImaging.find((v) => v.departmentShortName === detail.department)?.department ?? ''
  
  const documentType = 
    isUploadImaging ? 
      pipe(
        UploadDocumentsService.getDocumentTypeImaging(TransactionType.IMAGING_UPLOAD, detail.department),
        ZIO.nullable,
        ZIO.map((doc) => {
          if (doc) {
            const findDoc = doc.find((i) => i.docTypeCode === detail.documentType)
            return (i18n.language === 'vi' ? findDoc?.docTypeVN ?? '' : findDoc?.docTypeEN ?? '')
          }
          return ''
        }),
        ErrorHandling.runDidUpdate([i18n.language])
      ) :
      pipe(
        UploadDocumentsService.getDocumentTypeName(detail.department, detail.documentType),
        ZIO.nullable,
        ZIO.map((doc) => {
          if (doc) {
            return i18n.language === 'vi' ? doc.doctypeVn : doc.doctypeEn
          }
          return ''
        }),
        ErrorHandling.runDidUpdate([i18n.language])
      )

  return (
    <View style={styles.pannel}>
      <View style={styles.row}>
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Text style={styles.title}>{t('utilities:department')}</Text>
          <Text>{isUploadImaging ? showDeparmentImaging : showDeparmentName}</Text>
        </View>
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Text style={styles.title}>{t('utilities:documentType')}</Text>
          <Text>{documentType || ''}</Text>
        </View>
      </View>
      {isUploadImaging && 
        <View style={[styles.row, { paddingTop: 20 }]}>
          <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <Text style={styles.title}>{t('utilities:BatchName')}</Text>
            <Text>{detail.batchName ?? ''}</Text>
          </View>
        </View>
      }
      <Divider height={20} />
      <Text style={[styles.title, { paddingHorizontal: 10, paddingVertical: 5 }]}>{t('utilities:FileAttachment')}</Text>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {detail.requestDocuments.map((document) => {
          return (
            <View style={styles.fileContainer}>
              <Text>{document.name}</Text>
            </View>
          )
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center'
  },
  pannel: {
    borderWidth: 1,
    borderColor: '#d3dce6',
    borderRadius: 8,
    backgroundColor: '#fafafa',
    padding: 16,
    margin: 16
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#70777E'
  },
  row: {
    flexDirection: 'row'
  },
  fileContainer: {
    width: '45%',
    marginHorizontal: 10,
    borderBottomWidth: 1,
    paddingVertical: 10,
    borderStyle: 'dashed',
    borderBottomColor: '#D3DCE6'
  }
})
