import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { MaturityAdvanceGroup, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export namespace MaturityAdvanceService {
  export const getDetail = (policyNum: string): Task<MaturityAdvanceGroup.Detail> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        pipe(
          POApi.post(`wf-api/bo/maturity-advance-enquiry`)(
            t.type({
              body: t.type({
                billedToDate: t.string,
                effectiveDate: t.string,
                exchangeId: t.string,
                paidToDate: t.string,
                percentage: t.number,
                policyDebt: t.number,
                policyLoan: t.number,
                policyNo: t.string,
                preMaturityAdv: t.number,
                rcDate: t.string,
                requiredMaturityAdv: t.number,
                reversionBonus: t.number,
                sumAssured: t.number,
                terminalBonus: t.number,
                totalMaturityAmount: t.number,
                unpaidAmount: t.number,
                unpaidClaimAmount: t.number
              })
            })
          )({
            body: {
              contractNumber: policyNum
            }
          }),
          ZIO.catchAll(() => ZIO.succeed(null))
        )
      ),
      ZIO.map(([policy, advanceEnquiry]): MaturityAdvanceGroup.Detail => {
        return {
          estimatedTotalMaturity: advanceEnquiry
            ? advanceEnquiry.body.sumAssured +
              advanceEnquiry.body.reversionBonus +
              advanceEnquiry.body.terminalBonus -
              advanceEnquiry.body.policyLoan -
              advanceEnquiry.body.policyDebt -
              advanceEnquiry.body.unpaidAmount -
              advanceEnquiry.body.unpaidClaimAmount
            : 0,
          preMaturityAdvance: advanceEnquiry ? advanceEnquiry.body.preMaturityAdv : 0,
          totalMaturityAmount: advanceEnquiry ? advanceEnquiry.body.totalMaturityAmount : 0
        }
      })
    )
  export const getDataAccess = (policyNum: string): Task<MaturityAdvanceGroup.AccessDataCheck> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        POApi.post(`wf-api/other/client-policy-verify`)(
          t.type({
            body: t.type({
              clientRecOuts: Maybe(t.array(t.type({}))),
              policyRecOuts: Maybe(
                t.array(
                  t.type({
                    isClaim: t.string,
                    isWarning: t.string,
                    policyNum: t.string
                  })
                )
              )
            })
          })
        )({
          body: {
            function: 'MATUR ADV',
            clientRecs: [{ clientNumber: '', securityNo: '' }],
            policyRecs: [{ policyNum }]
          }
        })
      ),
      ZIO.map(([policy, verify]): MaturityAdvanceGroup.AccessDataCheck => {
        return {
          contractStatus: policy.body.status ?? '',
          maturityAdvValid: !!verify.body?.policyRecOuts && !!verify.body?.policyRecOuts[0]?.policyNum
        }
      })
    )

  export const getVerficationAccess = (policyNum: string): Task<boolean> =>
    pipe(
      POApi.post(`wf-api/other/client-policy-verify`)(
        t.type({
          body: t.type({
            policyRecOuts: t.array(
              t.type({
                errorCode: Maybe(t.string)
              })
            )
          })
        })
      )({
        body: {
          function: 'CHECK_LOAN',
          clientRecs: [{ clientNumber: '', securityNo: '' }],
          policyRecs: [{ policyNum }]
        }
      }),
      ZIO.map((verify) => !!verify.body.policyRecOuts[0].errorCode)
    )

  export const submit =
    (policyNum: string, data: MaturityAdvanceGroup.DataSubmit) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/maturity-advance`,
        { body: data },
        policyNum,
        requestauth,
        []
      )
  // pipe(
  //   POApi.post(`wf-api/policy/${policyNum}/maturity-advance`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )
}
