import { form2, PulseOpsFormat } from '@pulseops/common'
import i18next from 'i18next'
import * as t from 'io-ts'
import moment from 'moment'
import * as D from 'date-fns'

export const compareOnlyDate = (date1: Date, date2: Date) => moment(date1).isSameOrBefore(date2, 'day')
export const diffYear = (date1: Date, date2: Date) => moment(date1).diff(date2, 'years')
export const maxCurrentDate = (message: string) => (value: Date | null) => {
  return !!value && !compareOnlyDate(value, new Date()) ? message : true
}

export type StringMaxLengthBrand = {
  readonly StringMaxLength: unique symbol
}
export type StringMaxLength = t.Branded<string, StringMaxLengthBrand>

export const withMaxStringLength = (maxLen: number, message = 'message:MaxLength') =>
  form2.refine(
    (l): l is StringMaxLength => l.length <= maxLen,
    (l) => i18next.t(message, { field: maxLen }),
    'StringMaxLength'
  )

export type StringMinLengthBrand = {
  readonly StringMinLength: unique symbol
}
export type StringMinLength = t.Branded<string, StringMinLengthBrand>

export const withMinStringLength = (minLen: number, message = 'message:MinLength') =>
  form2.refine(
    (l): l is StringMinLength => l.length >= minLen,
    (l) => i18next.t(message, { field: minLen }),
    'StringMinLength'
  )

export type StringPatternBrand = {
  readonly StringPattern: unique symbol
}
export type StringPattern = t.Branded<string, StringPatternBrand>

export const withStringPattern = (pattern: string, message = 'message:StringPattern') =>
  form2.refine(
    (l): l is StringPattern => new RegExp(pattern).test(l),
    (l) => i18next.t(message),
    'StringPattern'
  )

export type GreaterBrand = {
  readonly Greater: unique symbol
}
export type Greater = t.Branded<string, GreaterBrand>

export const withGreater = (num: number) =>
  form2.refine(
    (l): l is Greater => PulseOpsFormat.thousandSepartorReverse(l) > num,
    (l) => i18next.t('message:MS100004', { num: num }),
    'Greater'
  )

export type NumberGreaterBrand = {
  readonly NumberGreater: unique symbol
}
export type NumberGreater = t.Branded<string, NumberGreaterBrand>

export const withNumberGreater = (min: number) =>
  form2.refine(
    (l): l is NumberGreater => l > min,
    (l) => i18next.t('message:MS100004', { num: min }),
    'Greater'
  )

export type MaxDateBrand = {
  readonly MaxDate: unique symbol
}

export const withMaxDate = (max: Date) =>
  form2.refine(
    (d): d is t.Branded<Date, MaxDateBrand> => D.isBefore(d, max),
    () => i18next.t('error_max_date', { maxDate: D.format(max, 'dd-MM-yyyy'), ns: 'form' }),
    'MaxDate'
  )

export type MinDateBrand = {
  readonly MaxDate: unique symbol
}

export const withMinDate = (min: Date) =>
  form2.refine(
    (d): d is t.Branded<Date, MinDateBrand> => D.isAfter(d, min),
    () => i18next.t('error_min_date', { maxDate: D.format(min, 'dd-MM-yyyy'), ns: 'form' }),
    'MinDate'
  )
