import {
  assets,
  AuthService,
  AuthState,
  BuildConfig,
  ErrorHandling,
  MenuPermissions,
  RBAC,
  RoundedButton
} from '@pulseops/common'
import { DrawerContentComponentProps, DrawerContentOptions, DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { ImageBackground, StyleSheet, Text, View, TouchableOpacity, ScrollView } from 'react-native'
import { UserDrawerParamList } from './UserDrawerParamList'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import { useTranslation } from 'react-i18next'
import { assets as businessAssets } from '@pulseops/business/core'
import { AdmintoolList } from '../setting/SettingStackParamList'

// const getLogoFromName = (name: string, defaultValue: string = '') => {
//   let logo = defaultValue
//   if (name) {
//     const token = name.split(' ')
//     const surname = token.shift()
//     const firstname = token.pop()
//     if (surname && surname[0]) {
//       logo = surname[0]
//     }

//     if (firstname && firstname[0]) {
//       logo += firstname[0]
//     }
//   }

//   return logo.toLocaleUpperCase()
// }

type Props = DrawerContentComponentProps<DrawerContentOptions>

export const DrawerContent = ({ state, navigation }: Props) => {
  const { t } = useTranslation('menu')

  const props = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()

  // const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const authData = pipe(AuthState.auth.get)
  const permissions: string[] = pipe(RBAC.permissionWithNoCache, ErrorHandling.runDidUpdate([authData])) || []
  // const { name, logo } = pipe(
  //   AuthService.userInfo,
  //   ZIO.map((info) => ({
  //     ...info,
  //     logo: getLogoFromName(info.name)
  //   })),
  //   ErrorHandling.runDidMount({})
  // )

  const Menu = [
    {
      group: t('TasksManagement'),
      items: [
        {
          title: t('TasksManagement'),
          icon: <assets.MenuItemTaskManagementIcon />,
          permission: MenuPermissions.ViewTaskListMenu,
          onPress: () => {
            navigation.navigate('TaskDashboardScreen')
          }
        },
        {
          title: t('ServiceInquiry'),
          icon: <assets.MenuItemCaseInquiryIcon />,
          permission: MenuPermissions.ViewServiceInquiryMenu,
          onPress: () => {
            navigation.navigate('ServiceInquiryScreen')
          }
        },
        {
          title: t('ErrorHandlerReport'),
          icon: <assets.MenuItemErrorHandlerReportIcon />,
          permission: MenuPermissions.ViewErrorHandlerReportMenu,
          onPress: () => {
            navigation.navigate('ErrorHandlerReportScreen')
          }
        },
        {
          title: t('Reports'),
          icon: <assets.MenuItemReportIcon />,
          permission: MenuPermissions.ViewReportMenu,
          onPress: () => {
            navigation.navigate('ReportsStack', { screen: 'ReportsScreen' })
          }
        },
        // {
        //   title: t('Notification'),
        //   icon: <assets.IconMenuNotification />,
        //   permission: MenuPermissions.ViewReportMenu,
        //   onPress: () => {
        //     navigation.navigate('NotificationScreen')
        //   }
        // },
        {
          title: t('BackOffice'),
          icon: <businessAssets.IconBusiness />,
          permission: MenuPermissions.ViewBusinessBackOfficeMenu,
          onPress: () => {
            props.navigate('BusinessStack', {
              screen: 'HomeScreen'
            })
          }
        },
        {
          title: t('Cashout'),
          icon: <assets.CashOutIcon />,
          permission: MenuPermissions.CashoutMenu,
          onPress: () => {
            props.navigate('CashoutStack', {
              screen: 'CashOutHistoryList'
            })
          }
        },
        {
          title: t('ReleasePolicy'),
          icon: <assets.MenuItemTaskManagementIcon />,
          permission: MenuPermissions.ViewAMLReleasePolicyMenu,
          onPress: () => {
            navigation.navigate('ReleasePolicyScreen')
          }
        },
        {
          title: t('PCDataImport'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewPCDataImport,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'PCDataImportScreen' }]
            })
          }
        },
        {
          title: t('PCRequest'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewRequestPCMenu,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'PCRequestStack' }]
            })
            // navigation.navigate('PCRequestStack')
          }
        },
        {
          title: t('PCRequestList'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewRequestListPCMenu,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'PCRequestListStackScreen' }]
            })
            // navigation.navigate('PCRequestListStackScreen')
          }
        },
        {
          title: t('GroupPolicy'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewGroupPolicyMenu,
          onPress: () => {
            props.navigate('PolicyGroupListStack', {
              screen: 'SEAPolicyGroupScreen'
            })
          }
        },
        {
          title: t('Accounting'),
          icon: <assets.CashOutIcon />,
          permission: MenuPermissions.AccountingMenu,
          onPress: () => {
            props.navigate('AccountingStack', {
              screen: 'AccountingList'
            })
          }
        }
      ]
    },
    {
      group: t('Inquiry'),
      items: [
        {
          title: t('360Inquiry'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.View360Inquiry,
          onPress: () => {
            navigation.navigate('InquiryStack', { screen: 'CEPSearchScreen' })
          }
        }
      ]
    },
    {
      group: t('Submission'),
      items: [
        {
          title: t('SubmissionForm'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewSubmissionFormMenu,
          onPress: () => {
            // navigation.reset({ index: 0, routes: [{ name: 'StaffSubmissionStack' }] })
            navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
          }
        },
        {
          title: t('ContactCenter'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewContactCenterMenu,
          onPress: () => {
            navigation.navigate('StaffSubmissionStack', { screen: 'ContactCenterScreen' })
          }
        },
        {
          title: t('submission:AgentSubmissionForm'),
          icon: <assets.MenuItemSubmissionFormIcon />,
          permission: MenuPermissions.ViewAgentSubmission,
          onPress: () => {
            navigation.navigate('UserDrawer', {
              screen: 'UtilitiesStack',
              params: {
                screen: 'AgentSearchScreen'
              }
            })
          }
        }
      ]
    },
    {
      group: t('Utilities'),
      items: [
        {
          title: t('Utilities'),
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.ViewUtilitiesMenu,
          onPress: () => {
            navigation.navigate('UtilitiesStack', { screen: 'Utilities' })
          }
        },
        {
          title: 'TRRB, PD',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.LinkTRRBPDMenu,
          onPress: () => {
            window.open('https://ganet.prudential.com.vn/csbo/')
          }
        },
        {
          title: 'Pruclaims',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.LinkPRUClaimsMenu,
          onPress: () => {
            window.open('https://pruclaims.prudential.com.vn/claims-api/investigation/index.html#/login')
          }
        },
        {
          title: 'PRUGreat',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.LinkPRUGreatMenu,
          onPress: () => {
            window.open('https://prugreat.prudential.com.vn/prugreat/#/login')
          }
        },
        {
          title: 'Panel Doctor',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.LinkPanelDoctorMenu,
          onPress: () => {
            window.open('https://partnerportal.prudential.com.vn/panel-doctor/#/')
          }
        },
        {
          title: 'eClaim',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.LinkeClaimsMenu,
          onPress: () => {
            window.open('https://eclaim.prudential.com.vn/eclaim/#/zalo-eclaim-request')
          }
        },
        {
          title: 'Ekiosk',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.LinkeKioskMenu,
          onPress: () => {
            window.open('https://service.prudential.com.vn/ekiosk-ps/#/home')
          }
        },
        {
          title: 'PVA - Do Not Call',
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.ViewPVADoNotCall,
          onPress: () => {
            navigation.navigate('UtilitiesStack', { screen: 'DoNotCallScreen' })
          }
        },
        {
          title: t('DistributionServices'),
          icon: <assets.MenuItemUtilitiesIcon />,
          permission: MenuPermissions.ViewDistributionServices,
          onPress: () => {
            navigation.navigate('UtilitiesStack', { screen: 'DistributionServicesScreen' })
          }
        }
      ]
    },
    {
      group: t('Digital'),
      items: [
        {
          title: t('PolicyInquiry'),
          icon: <assets.MenuItemPolicyInquiryIcon />,
          // permission: MenuPermissions.ViewPolicyInquiryMenu,
          permission: MenuPermissions.ViewDigitalMenu,
          onPress: () => {
            props.navigate('DIStack', {
              screen: 'PolicyInquiryScreen'
            })
          }
        },
        {
          title: t('VoucherInquiry'),
          icon: <assets.MenuItemVoucherInquiryIcon />,
          permission: MenuPermissions.ViewVoucherInquiryMenu,
          onPress: () => {
            props.navigate('DIStack', {
              screen: 'VoucherInquiryScreen'
            })
          }
        }
      ]
    },
    {
      group: t('Outbound'),
      items: [
        {
          title: t('TasksManagement'),
          icon: <assets.MenuItemTaskManagementIcon />,
          permission: MenuPermissions.ViewTaskListOBMenu,
          onPress: () => {
            navigation.navigate('OBTaskManagementScreen')
          }
        },
        {
          title: t('ServiceInquiry'),
          icon: <assets.MenuItemCaseInquiryIcon />,
          permission: MenuPermissions.ViewOBServiceInquiryMenu,
          onPress: () => {
            // navigation.navigate('OutboundStack', { screen: 'OBServiceInquiryScreen' })
            navigation.navigate('OBServiceInquiryScreen')
          }
        },
        {
          title: t('Import'),
          icon: <assets.MenuItemReportIcon />,
          permission: MenuPermissions.ViewImportOBMenu,
          onPress: () => {
            navigation.navigate('OBImportFileScreen')
          }
        }
      ]
    },
    {
      group: t('Inbound'),
      items: [
        {
          title: t('SystemInbound'),
          icon: <assets.IBMenuSearchAPerson />,
          permission: MenuPermissions.ViewContactCenterInquiryIBMenu,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'InboundStack' }]
            })
          }
        },
        {
          title: t('DraftTransaction'),
          icon: <assets.IBDraftMenu />,
          permission: MenuPermissions.ViewDraftTransactionMenu,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'InboundStack', params: { screen: 'IBDraftTransaction' } }]
            })
          }
        },
        {
          title: t('InfobipReport'),
          icon: <assets.IBInfoBipIcon />,
          permission: MenuPermissions.IBViewInfobipReportMenu,
          onPress: () => {
            window.open(`https://portal.infobip.com/login/?callback=https%3A%2F%2Fportal.infobip.com%2Fanalyze%2Flogs`)
          }
        },
        {
          title: t('CallRecording'),
          icon: <assets.IBInfoBipIcon />,
          permission: MenuPermissions.IBViewCallRecordingMenu,
          onPress: () => {
            window.open(`http://vvnlfwpniceapp1/NiceApplications/Desktop/XbapApplications/NiceDesktop.xbap`)
          }
        },
        {
          title: t('CallbackAppointment'),
          icon: <assets.IBCallbackAppointIcon />,
          permission: MenuPermissions.ViewCallbackAppointmentMenu,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'InboundStack', params: { screen: 'IBCallbackAppointment' } }]
            })
          }
        },
        {
          title: t('EmailManagement'),
          icon: <assets.IBInfoBipIcon />,
          permission: MenuPermissions.EmailManagementMenu,
          onPress: () => {
            navigation.reset({
              routes: [{ name: 'InboundStack', params: { screen: 'IBEmailManagement' } }]
            })
          }
        }
      ]
    },
    {
      group: t('System'),
      items: [
        {
          title: t('Settings'),
          icon: <assets.MenuItemSettingIcon />,
          permission: MenuPermissions.ViewSettingMenu,
          onPress: () => {
            props.navigate('SettingStack', { screen: 'SettingScreen', params: {} })
          }
        }
      ]
    }
  ]

  const handleLocalStorageAfterLogout = () => {
    localStorage.removeItem('isCasualLabor')
    return ZIO.unit
  }

  return (
    <View style={styles.container}>
      <ImageBackground source={assets.DrawerBackground} resizeMethod="resize" resizeMode="cover" style={styles.topBox}>
        {/* <TouchableOpacity activeOpacity={1} style={styles.userInfoBox}>
          <View style={styles.avatar}>
            <Text style={styles.logo}>{logo}</Text>
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.infoBox}>
              <Text style={styles.username}>{name}</Text>
              <assets.MenuItemArrowRightIcon />
            </View>
            <Text style={styles.staffId}>NVBH01_02</Text>
          </View>
        </TouchableOpacity> */}
      </ImageBackground>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.bottomBox}>
        {Menu.map(({ group, items }, i) => (
          <View key={i}>
            <Text style={styles.menuGroupTitle}>{group}</Text>
            {items.map(({ icon, title, permission, onPress }, i) => {
              //   const isFocused = index === state.index
              const isFocused = false
              const hasPermission = permissions.includes(permission)
              if (!hasPermission) return null
              return (
                <TouchableOpacity key={i} activeOpacity={0.7} onPress={onPress} style={styles.menuItem}>
                  <View style={styles.menuIcon}>{icon}</View>
                  <Text style={[styles.menuTitle, { color: isFocused ? '#ED1B2E' : '#000000' }]}>{title}</Text>
                  {/* {!isFocused && <assets.MenuItemArrowRightIcon />} */}
                </TouchableOpacity>
              )
            })}
          </View>
        ))}
        <View style={styles.logoutBox}>
          <RoundedButton
            text={t('LogOut')}
            activeOpacity={0.7}
            style={styles.btnLogout}
            textStyle={styles.btnLogoutText}
            showBorder={true}
            textColorEnable="#ED1B2E"
            borderColorEnable="#ED1B2E"
            onPress={() => {
              handleLocalStorageAfterLogout()
              pipe(AuthService.logout, ZIO.unsafeRun({}))
            }}
          />
          <Text style={styles.version}>{`${t('Version')} 1.0`}</Text>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },

  topBox: {
    width: '100%',
    height: 209,
    justifyContent: 'flex-end'
  },

  userInfoBox: {
    marginStart: 25,
    marginEnd: 16,
    marginBottom: 48,
    flexDirection: 'row'
  },

  avatar: {
    width: 58,
    height: 58,
    borderRadius: 58,
    marginEnd: 13,
    backgroundColor: '#EB5757',
    justifyContent: 'center',
    alignItems: 'center'
  },

  logo: {
    fontSize: 24,
    color: '#FFFFFF',
    fontWeight: 'bold'
  },

  infoBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10
  },

  username: {
    color: '#000000',
    fontSize: 15,
    fontWeight: 'bold',
    flex: 1
  },

  staffId: {
    color: '#000000',
    fontSize: 13,
    fontWeight: 'normal',
    marginTop: 6
  },

  bottomBox: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },

  menuGroupTitle: {
    color: '#000000',
    fontSize: 18,
    fontWeight: '600',
    paddingHorizontal: 15,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE'
  },

  menuItem: {
    flexDirection: 'row',
    paddingStart: 24,
    paddingEnd: 15,
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#EEEEEE'
  },

  menuIcon: {
    width: 24,
    height: 24,
    marginEnd: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },

  menuTitle: {
    flex: 1,
    fontSize: 15,
    fontWeight: 'normal'
  },

  logoutBox: {
    paddingHorizontal: 15,
    marginTop: 64,
    marginBottom: 47,
    alignItems: 'center'
  },

  btnLogout: {
    width: '100%',
    height: 48,
    borderRadius: 48
  },

  btnLogoutText: {
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: 28
  },

  version: {
    color: '#000000',
    fontSize: 15,
    marginTop: 13
  }
})
