import {
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import {
  InputTable,
  SelectTable,
  assets,
  DatePickerTable,
  BackOfficePermission,
  AuthService,
  sharedStyle,
  SelectOption,
  Checkbox,
  PulseOpsFormat,
  RequestTableDataSource
} from '@pulseops/common'
import React from 'react'
import { View, useWindowDimensions, Text, ScrollView, SafeAreaView } from 'react-native'
import { EmailPopup, infoGen, LoadingScreen, styles } from '../../common'
import { useMobile, ErrorHandling } from '@pulseops/common'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { pipe } from 'fp-ts/lib/function'
import { BOService } from '../../services'
import { ZIO, Throwable } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#EEEEEE'
  }
})

export const BOInfoCheckComponent = () => {
  const { isMobile } = useMobile()
  const classes = useStyles()
  const { width, height } = useWindowDimensions()

  const [loading, bindLoader] = useLoading(false)

  const detail = pipe(
    BOService.getDetail,
    ZIO.absolveOption(() => Throwable('DetailMissing')),
    ZIO.tap((res) => updateData(res.policyNum, 0, 10)),
    ErrorHandling.runDidMount()
  )

  const { isEditPS, isEditVI, isSendEmail } = pipe(
    BOService.getPermission,
    ZIO.absolveOption(() => Throwable('RolesMissing')),
    ZIO.map((x) => {
      return {
        isEditPS: x.includes(BackOfficePermission.Detail.Verification.EditPS),
        isEditVI: x.includes(BackOfficePermission.Detail.Verification.EditVI),
        isSendEmail: x.includes(BackOfficePermission.Detail.Verification.Email)
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    isEditPS: false,
    isEditVI: false,
    isSendEmail: false
  }

  const [rows, setRows] = React.useState<
    {
      docType: string
      decision: string
      note: string
      status: string
      requestDate: string
      completeDate: string
      time: string
      userId: string
    }[]
  >([])
  // {
  // docType: 'Quotation',
  // decision: '-',
  // note: '-',
  // status: '-',
  // requestDate: '-',
  // completeDate: '-',
  // time: '-',
  // userId: '-'
  // }

  const updateData = (policyNum: string | undefined, pageNum: number, pageSize: number, filter?: string) => {
    return pipe(
      BOService.getVerifyList(policyNum || '-', pageNum, pageSize),
      ZIO.tap((x) => {
        setRows(
          x.data.reduce(
            (ans, y) => [
              ...ans,
              {
                docType: y.checklistName || '-',
                decision: !!y.decision ? 'Yes' : 'No',
                note: y.comment || '',
                status: y.status || '-',
                requestDate: y.requestDate ? PulseOpsFormat.datetoFormat(new Date(y.requestDate), 'DD/MM/YYYY') : '-',
                completeDate: y.completeDate
                  ? PulseOpsFormat.datetoFormat(new Date(y.completeDate), 'DD/MM/YYYY')
                  : '-',
                time: y.createdDate ? PulseOpsFormat.datetoFormat(new Date(y.createdDate), 'DD/MM/YYYY') : '-',
                userId: y.createdBy || '-'
              }
            ],
            new Array<{
              docType: string
              decision: string
              note: string
              status: string
              requestDate: string
              completeDate: string
              time: string
              userId: string
            }>()
          )
        )
        return ZIO.unit
      }),
      bindLoader
    )
  }

  const [rowInputData, setInputData] = React.useState<
    {
      title: string
      docType: string
      decision: SelectOption | null
      note: string
      status: SelectOption | null
      requestDate: Date | null
      completeDate: Date | null
      edited: boolean
    }[]
  >([
    {
      title: 'Bảng minh họa',
      docType: 'Quotation',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'HSYCBH',
      docType: 'Application form',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'Danh sách nhân viên',
      docType: 'Member list',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'Giấy phép kinh doanh',
      docType: 'Business license',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'CMND đại diện pháp lý',
      docType: 'ID of Legal representative',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'CMND chủ sở hữu hưởng lợi',
      docType: 'ID of benefit owner',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'Giấy bổ nhiệm / Hợp đồng Tổng giám đốc',
      docType: 'Labor contract of Director',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'Giấy bổ nhiệm / Hợp đồng Kế toán trưởng',
      docType: 'Labor contract of Chief Accountant',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'Giấy ủy quyền (BMBH khác đại diện)',
      docType: 'Authorization - PO not Legal rep',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    },
    {
      title: 'Giấy tờ FATCA',
      docType: 'FATCA docs',
      decision: null,
      note: '',
      status: null,
      requestDate: null,
      completeDate: null,
      edited: false
    }
  ])

  const yesNoOptions = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' }
  ]
  // const statusQuotationOptions = [
  //   {  label: 'Active', value: 'Active'},
  //   {  label: 'Inactive', value: 'Inactive'},
  //   {  label: 'Completed', value: 'Completed'},
  // ]

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Completed', value: 'Completed' }
  ]

  const [openPopup, setOpenPopup] = React.useState(false)

  const handleClickOpen = () => {
    setOpenPopup(true)
  }

  const handleClose = (value: any) => {
    setOpenPopup(false)
  }

  const [loadingSave, bindLoaderSave] = useLoading(false)
  const infoMapper = infoGen(isMobile)

  const onSave = () => {
    const checkList = rowInputData
      .map((x) => ({
        checklistName: x.docType,
        decision: x.decision?.value === 'Y' ? true : false || null,
        note: x.note,
        status: x.status?.value || 'xxx',
        requestDate: x.requestDate?.toISOString() || 'xxx',
        completeDate: x.completeDate?.toISOString() || 'xxx'
      }))
      .filter(
        (x) => !(x.decision === null && x.status === 'xxx' && x.requestDate === 'xxx' && x.completeDate === 'xxx')
      )
    const checker = checkList.some(
      (x) => x.decision !== null && x.status !== 'xxx' && x.requestDate !== 'xxx' && x.completeDate !== 'xxx'
    )
    checkList.length > 0 && checker
      ? pipe(
          AuthService.userInfo,
          ZIO.flatMap((user) => BOService.addVerifyList(detail?.policyNum || '', user.email, checkList)),
          bindLoaderSave,
          ZIO.tap((res) => (detail ? updateData(detail.policyNum, 0, 10) : ZIO.unit)),
          ErrorHandling.run()
        )
      : (() => {})()
  }

  const renderFooter = () => {
    return (
      <View style={[sharedStyle.footer, { width: '100%', justifyContent: 'center' }]}>
        <TouchableOpacity
          style={[
            sharedStyle.button,
            isEditPS || isEditVI ? sharedStyle.btnRed : sharedStyle.btnDisabled,
            isMobile ? { marginRight: 20 } : {}
          ]}
          disabled={!isEditPS && !isEditVI}
          onPress={() => {
            onSave()
          }}
        >
          <Text style={isEditPS || isEditVI ? sharedStyle.textButtonRed : sharedStyle.btnTextDisabled}>
            Save {loadingSave && <CircularProgress color="inherit" size={14} />}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[sharedStyle.button, { borderColor: '#ED1B2E' }, isMobile ? { marginRight: 20 } : {}]}
          onPress={() => {}}
        >
          <Text style={sharedStyle.textButton}>Cancel</Text>
        </TouchableOpacity>
      </View>
    )
  }

  const rowsSetup = [
    { title: 'Giấy tờ yêu cầu', key: 'docType' },
    { title: 'Quyết định', key: 'decision' },
    { title: 'Ghi chú', key: 'note' },
    { title: 'Trạng thái', key: 'status' },
    { title: 'Ngày yêu cầu', key: 'requestDate' },
    { title: 'Ngày hoàn tất', key: 'completeDate' },
    { title: 'Thời gian', key: 'time' },
    { title: 'UserID', key: 'userId' }
  ]

  const generalInfo = [
    [
      { id: 'requestNum', title: 'Số HSYCBH', value: detail?.policyNum || '-' },
      { id: 'policyNum', title: 'Số hợp đồng', value: detail?.proposalNum || '-' },
      { id: 'policyStatus', title: 'Trạng thái HSYCBH', value: detail?.status || '-' }
    ]
  ]

  return (
    <SafeAreaView>
      <LoadingScreen loading={loading}></LoadingScreen>
      <SafeAreaView
        style={{
          marginTop: 35,
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: '#FFF',
          height: height - 295,
          width: width >= 1440 ? 1440 : '100%'
        }}
      >
        <ScrollView style={{ marginBottom: 70 }}>
          <View style={styles.sectionContainer}>
            <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Thông tin chung</Text>
            {infoMapper(generalInfo, 'generalKey')}
            {/* <View style={{flexDirection: isMobile ? 'column': 'row'}}>
            <View style={isMobile ? styles.mobileField : styles.tabletField}>
              <Input
                title={'Số HSYCBH'}
                value={detail?.policyNum || '-'}
                disabled={true}
              />
            </View>
            <View style={isMobile ? styles.mobileField : styles.tabletField}>
              <Input
                title={'Số hợp đồng'}
                value={detail?.proposalNum || '-'}
                disabled={true}
              />
            </View>
            <View style={isMobile ? styles.mobileField : styles.tabletField}>
              <Input
                title={'Trạng thái HSYCBH'}
                value={detail?.status || '-'}
                disabled={true}
              />
              <Select
                label={'Trạng thái HSYCBH'}
                value={{
                  value: detail?.status || '-',
                  label: detail?.status || '-'
                }}
                disabled={!isEditPS}
                options={statusQuotationOptions}
              />
            </View> 
          </View>*/}
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', color: '#70777E', marginTop: 10 }}>Gửi email</Text>
              <TouchableOpacity onPress={() => handleClickOpen()} disabled={!isSendEmail}>
                <assets.SendEmail />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.sectionContainer}>
            <Text style={{ fontWeight: '900', marginBottom: 10 }}> {'\u2022'} Quyết định kiểm tra thông tin</Text>
            <View style={{ marginTop: 10 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>Giấy tờ yêu cầu</TableCell>
                      <TableCell>Quyết định</TableCell>
                      <TableCell>Ghi chú</TableCell>
                      <TableCell>Trạng thái</TableCell>
                      <TableCell>Ngày yêu cầu</TableCell>
                      <TableCell>Ngày hoàn tất</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowInputData.map((row, i) => (
                      <TableRow key={`row-${i}`}>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>
                          <SelectTable
                            id="decision"
                            value={row.decision}
                            onChange={(val) => {
                              const temp = [...rowInputData]
                              temp[i].decision = val
                              temp[i].edited = true
                              setInputData(temp)
                            }}
                            errorMessage={row.edited && row.decision === null ? 'Required' : ''}
                            options={yesNoOptions}
                            disabled={!isEditVI}
                          />
                        </TableCell>
                        <TableCell>
                          <InputTable
                            placeholder="Input text ..."
                            value={row.note}
                            onChange={(val) => {
                              const temp = [...rowInputData]
                              temp[i].note = val
                              temp[i].edited = true
                              setInputData(temp)
                            }}
                            disabled={!isEditVI}
                          />
                        </TableCell>
                        <TableCell>
                          <SelectTable
                            id="status"
                            value={row.status}
                            onChange={(val) => {
                              const temp = [...rowInputData]
                              temp[i].status = val
                              temp[i].edited = true
                              setInputData(temp)
                            }}
                            errorMessage={row.edited && row.status === null ? 'Required' : ''}
                            options={statusOptions}
                            disabled={!isEditVI}
                          />
                        </TableCell>
                        <TableCell>
                          <DatePickerTable
                            value={row.requestDate}
                            onChange={(val) => {
                              const temp = [...rowInputData]
                              temp[i].requestDate = val
                              temp[i].edited = true
                              setInputData(temp)
                            }}
                            errorMessage={row.edited && row.requestDate === null ? 'Required' : ''}
                            disabled={!isEditVI}
                          ></DatePickerTable>
                        </TableCell>
                        <TableCell>
                          <DatePickerTable
                            value={row.completeDate}
                            onChange={(val) => {
                              const temp = [...rowInputData]
                              temp[i].completeDate = val
                              temp[i].edited = true
                              setInputData(temp)
                            }}
                            errorMessage={row.edited && row.completeDate === null ? 'Required' : ''}
                            disabled={!isEditVI}
                          ></DatePickerTable>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
            <Checkbox title="AML (HIIIT / No HIIT)" value={false} onChange={() => {}} disabled></Checkbox>
          </View>
          <View style={styles.sectionContainer}>
            <Text style={{ fontWeight: '900', marginBottom: 10 }}>
              {' '}
              {'\u2022'} Lịch sử quyết định kiểm tra thông tin
            </Text>
            <RequestTableDataSource
              rows={rows}
              setup={rowsSetup}
              keyTitle={'InfoCheckKey'}
              actionPageNum={(pageNum, pageSize) => {
                pipe(updateData(detail?.policyNum, pageNum, pageSize), ErrorHandling.run())
              }}
              actionPageSize={(pageNum, pageSize) => {
                pipe(updateData(detail?.policyNum, pageNum, pageSize), ErrorHandling.run())
              }}
            ></RequestTableDataSource>
          </View>
          <EmailPopup open={openPopup} onClose={handleClose}></EmailPopup>
        </ScrollView>
        {renderFooter()}
      </SafeAreaView>
    </SafeAreaView>
  )
}
