import { View, Text, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'
import React from 'react'
import { useMobile, StorageBlob, GeneralService, TransactionType, AppContext } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { LandingPageCCCDStackParamList } from './LandingPageCCCDStackParamList'
import { BorderFilledInput, SC, Services, Button } from './core'
import { Header } from './Header'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

type Props = StackScreenProps<LandingPageCCCDStackParamList, 'OTPRequestScreen'>
const TIMEOUT = 300
const convertMinute = (seconds: number): string => {
  const format = (val: number) => `0${Math.floor(val)}`.slice(-2)
  //const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60

  return [minutes, seconds % 60].map(format).join(':')
}
export const OTPRequestScreen = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t: m } = useTranslation('message')
  const { t } = useTranslation('landingPage')
  const [OTP, setOTP] = React.useState<string>('')
  const { params } = props.route
  const [time, setTime] = React.useState<number>(TIMEOUT)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const accessToken = localStorage.getItem('LANDING_PAGE_TOKEN')
  const [spinning, setSpinning] = React.useState<boolean>(false)
  const getMetaFilesByAttachedFiles = () => {
    const currentDateFormat = moment().format('DD/MM/YYYY')
    return {
      type: TransactionType.CHANGE_NATIONAL_ID_CARD,
      doctype: 'Identify document',
      class: 'POLICYINFO',
      docid: '10205011',
      maindoc: 'IDENTIFICATION',
      subdoc: 'PERSONAL ID/BIRTH CERTIFICATE/ PASSPORT/VISA',
      batchno: `LandingPage_${currentDateFormat}`,
      polnum: params.policyNumber
    }
  }
  const handleSubmission = () => {
    const filesContent = params.files.map((docItem) => ({
      file: docItem.file,
      metaData: getMetaFilesByAttachedFiles()
    }))
    if (OTP.length < 6) {
      showToast(m('MS030029'), 'error', {
        horizontal: 'right',
        vertical: 'top'
      })
    } else {
      setSpinning(true)
      const otpTransaction = {
        otp: OTP,
        transactionName: 'Dieu chinh can cuoc cong dan',
        payload: '',
        receiverPhone: params.phoneNumber,
        transactionCode: params.transactionCode
      }
      return pipe(
        StorageBlob.uploadToSubmit('PS', params.policyNumber, accessToken || '')(filesContent),
        ZIO.map((documents) => {
          return pipe(
            Services.submissionChangeNationalIdCard(
              params.policyNumber,
              params.client,
              params.clientId,
              params.client.name,
              params.attributesExt,
              otpTransaction,
              documents,
              params.ownerName
            ),
            ZIO.tap(() =>
              ZIO.effect(() => {
                setSpinning(false)
                showToast(m('MS990017'), 'success', {
                  horizontal: 'center',
                  vertical: 'top'
                })
                props.navigation.navigate('SuccessScreen')
              })
            ),
            ZIO.catchAll((error) => {
              setSpinning(false)
              const path = `source.response.data.responseStatus.errors.0.code`
              const code = _.get(error, path)
              return ZIO.effect(() => {
                showToast(code ? m(code) : m('MS030029'), 'error', {
                  horizontal: 'center',
                  vertical: 'top'
                })
              })
            }),
            ZIO.unsafeRun({})
          )
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const resendOTP = () => {
    const sendDate = moment().format('yyMMDDhhmmss').substr(2, 12)
    const transactionCode = `PS-${params.policyNumber}-${sendDate}`
    pipe(
      Services.otpRequest('Dieu chinh can cuoc cong dan', transactionCode, params.phoneNumber, params.policyNumber),
      ZIO.unsafeRun({})
    )
    setTime(TIMEOUT)
  }
  React.useEffect(() => {
    let timer: number | false

    timer = time > 0 && setInterval(() => setTime(time - 1), 1000)
    if (time === 0 && timer) {
      clearInterval(timer)
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [time])

  return (
    <SafeAreaView style={{ flex: 1, height: '100%', backgroundColor: '#FFFFFF' }}>
      <Header isWide={isWide} t={t} />
      <ScrollView>
        <SC.ContainerPolicyList maxWidth="566" isWide={isWide}>
          <View style={styles.form}>
            <SC.Form.Group marginBottom="0">
              <SC.Form.Row marginBottom="18" isMobile={isMobile} alignSelf="center">
                <SC.MessageTitle isWide={isWide}>{t('OTPInput')}</SC.MessageTitle>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <BorderFilledInput
                  controlStyle={{ width: '100%' }}
                  label={`${t('OTPInput')} *`}
                  value={OTP}
                  onChange={(val) => {
                    setOTP(val || '')
                  }}
                  maxLength={6}
                />
              </SC.Form.Row>
              <SC.Form.Row marginBottom="18" isMobile={isMobile} alignSelf="center">
                <SC.NoteText>{time !== 0 ? `Cập nhật lại sau ${convertMinute(time)}` : null}</SC.NoteText>
              </SC.Form.Row>
              <SC.Form.Row marginBottom="22" isMobile={isMobile}>
                <TouchableOpacity style={styles.btnCancel} onPress={resendOTP}>
                  <Text style={{ color: '#EE1A2D', fontFamily: 'Noto Sans' }}>{t('RequestOTP')}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.btnSave}
                  onPress={handleSubmission}
                  disabled={OTP.length === 0 || time === 0 || spinning}
                >
                  <Text style={{ color: 'white', fontFamily: 'Noto Sans' }}>
                    {spinning ? <ActivityIndicator size="small" animating={spinning} color="#FFFFFF" /> : t('SendOTP')}
                  </Text>
                </TouchableOpacity>
              </SC.Form.Row>
            </SC.Form.Group>
          </View>
        </SC.ContainerPolicyList>
      </ScrollView>
      <View style={styles.footer}>
        <SC.FooterText isWide={isWide}>{t('copy_right')}</SC.FooterText>
      </View>
    </SafeAreaView>
  )
}
const styles = StyleSheet.create({
  form: {
    backgroundColor: '#F7F7F7',
    paddingTop: 24,
    borderRadius: 8,
    boxShadow: '-6px 20px 30px rgba(0, 0, 0, 0.12)'
  },
  footer: {
    backgroundColor: '#000000'
  },
  btnCancel: {
    padding: 10,
    width: '46%',
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#EE1A2D',
    backgroundColor: 'white',
    marginRight: '4%'
  },
  btnSave: {
    padding: 10,
    width: '46%',
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#EE1A2D',
    backgroundColor: '#EE1A2D'
  }
})
