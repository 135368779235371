import React from 'react'
import { StyleSheet, View, Text, ViewStyle } from 'react-native'

export type CallHeaderInfo = {
  title: string
  value: string
}

type IBChangeContactHeaderProps = {
  headerData: Array<CallHeaderInfo>
  headerStyles?: ViewStyle
}
export const IBChangeContactHeader = (props: IBChangeContactHeaderProps) => {
  const { headerData, headerStyles } = props
  return (
    <View>
      <View style={[callDetailStyles.headerContainer, headerStyles]}>
        {headerData &&
          headerData.map((dataItem, index) => {
            return (
              <View style={callDetailStyles.headerContent} key={'headerData_' + index}>
                <Text style={callDetailStyles.headerTitle}>{dataItem.title}</Text>
                <Text style={callDetailStyles.headerText}>{dataItem.value}</Text>
              </View>
            )
          })}
      </View>
    </View>
  )
}

const callDetailStyles = StyleSheet.create({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    // paddingVertical: 8,
    borderRadius: 8,
    marginHorizontal: 0,
    marginVertical: 20,
    padding: 20,
    justifyContent: 'space-between',
    backgroundColor: 'rgb(229, 234, 239)'
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerTitle: {
    color: '#000',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 22
  },
  headerText: {
    color: '#ED1B2E',
    // fontFamily: 'Noto Sans',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 22
  }
})
