import { StyleSheet, Text, View, ScrollView, TouchableOpacity, FlatList, useWindowDimensions } from 'react-native'
import { PulseOpsFormat, useMobile } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentOption, FileUploadData, generalStyles } from '../claim'

interface Props {
  selectedDocumentType: DocumentOption[]
}

export const DocumentCashOutConfirm: React.FC<Props> = (props: Props) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation('claim')
  const { width } = useWindowDimensions()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const flatListRef = React.useRef<FlatList>(null)
  const setIndexAndScroll = (index: number): void => {
    setTabIndex(index)
    flatListRef.current?.scrollToOffset({ offset: index * width, animated: true })
  }
  return (
    <View style={{ flexDirection: 'column', paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
      <View style={{ width: '100%', marginTop: 20, flexDirection: 'row' }}>
        <Text style={styles.label}>{t('CashOut:DocType')}</Text>
      </View>

      <View style={[styles.sectionContainer]}>
        <ScrollView horizontal={true}>
          {props.selectedDocumentType.map((doc, index) => (
            <TouchableOpacity
              key={`doc.value-${doc.value}-${index}`}
              style={[
                tabIndex === index
                  ? {
                      borderRadius: 15,
                      backgroundColor: '#E5EAEF'
                    }
                  : {},
                {
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 23,
                  paddingRight: 23,
                  flexDirection: 'row'
                }
              ]}
              onPress={() => setIndexAndScroll(index)}
            >
              <Text style={tabIndex === index ? { fontWeight: 'bold' } : { color: '#70777E' }}>{doc.label}</Text>
              {doc.required && <Text style={{ color: 'red' }}> *</Text>}
            </TouchableOpacity>
          ))}
        </ScrollView>

        <View style={{ flexDirection: 'row', width: '100%' }}>
          {props.selectedDocumentType.map((document, index) => (
            <View
              style={{
                width: '100%',
                marginTop: 30,
                flexDirection: isMobile ? 'column' : 'row',
                display: index === tabIndex ? 'flex' : 'none'
              }}
              key={`selected-doc-${index}`}
            >
              {isMobile && (
                <View
                  style={{
                    borderBottomColor: '#C4C4C4',
                    borderBottomWidth: 1,
                    marginBottom: 30
                  }}
                />
              )}
              <ScrollView style={{ height: 170 }}>
                <View
                  style={{
                    flex: 1,
                    flexWrap: 'wrap',
                    marginTop: 8,
                    flexDirection: isMobile ? 'column' : 'row'
                  }}
                >
                  {document.files?.map((file: FileUploadData, attachmentIdx) => (
                    <View
                      key={`File: ${file.fileName}-${attachmentIdx}`}
                      style={{
                        width: isWide ? '50%' : '100%',
                        paddingLeft: isWide ? 15 : 0,
                        paddingBottom: 10,
                        flexDirection: 'row'
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <img src={file.base64} width={38} height={41} />
                      </View>
                      <View style={{ flexDirection: 'column', flex: 3 }}>
                        <Text>{file.fileName}</Text>
                        <Text>{PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD-MM-YYYY hh:mm')}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </ScrollView>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}

const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    label: {
      color: '#70777E',
      fontWeight: 'bold',
      fontSize: 15,
      marginBottom: 2
    },
    addBtn: {
      marginLeft: 26,
      marginRight: 40,
      marginTop: 10,
      justifyContent: 'center',
      backgroundColor: '#ED1B2E',
      borderRadius: 100,
      borderWidth: 1,
      borderColor: '#ED1B2E',
      minWidth: 34,
      height: 40,
      paddingLeft: 29,
      paddingRight: 29
    }
  })
}
