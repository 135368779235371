import { POApi, jsFileDownload } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { ZIO, Throwable, Task } from '@mxt/zio'
import moment from 'moment'

export namespace PolicyDataXMLService {
  export const checkExtractData = (data: {
    masterContractNo?: string
    certificateNum?: string
    releaseDate?: string
  }) =>
    pipe(
      POApi.get(`business-pulse/api/v1/TPA/check`, {
        params: data
      })(
        t.union([
          t.type({
            result: t.literal('SUCCESS')
          }),
          t.type({
            result: t.literal('ERROR'),
            errorCodes: t.array(
              t.type({
                code: t.string
              })
            )
          })
        ])
      ),
      ZIO.flatMap((res): Task<{ result: 'SUCCESS' } | { result: 'ERROR'; errorCode: string }> => {
        if (res.result === 'ERROR') {
          const code = res.errorCodes[0]?.code
          if (code) {
            return ZIO.succeed({
              result: 'ERROR',
              errorCode: code
            })
          } else {
            return ZIO.fail(Throwable('NOT FOUND ERROR CODE', res))
          }
        }
        return ZIO.succeed(res)
      })
    )

  export const extractData = (data: { masterContractNo?: string; certificateNum?: string; releaseDate?: string }) =>
    pipe(
      POApi.get(`business-pulse/api/v1/TPA/extract`, {
        params: data,
        responseType: 'blob'
      })(t.unknown),
      ZIO.map((data) => {
        jsFileDownload(data as Blob, `Prudential_PolicyDataGroup_${moment(new Date()).format('DDMMyyyy_HHmm')}.xml`)
      })
    )
}
