import React from 'react'
import { StyleProp, View, ViewStyle, Text } from 'react-native'
import { useMobile } from '@pulseops/common'

export const ViewStatus: React.FC<{
  text?: string | null
  style?: StyleProp<ViewStyle>
}> = ({ text, style }) => {
  const setColor = (text: string) => {
    switch (text) {
      case 'In progress':
        return '#1EA5FC'
      case 'Pending':
        return '#FF6F00'
      case 'Completed':
        return '#4CAF50'
      case 'End':
        return '#ED1B2E'
      case 'Rejected':
        return '#ED1B2E'
      case 'Submitted':
        return 'blue'
      default:
        return '#fff'
    }
  }

  return (
    <View>
      <Text style={{ color: setColor(text ?? ''), fontSize: 15 }}>{text ?? '-'}</Text>
    </View>
  )
}
