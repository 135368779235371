import {
  AppContext,
  assets,
  CepPermission,
  ErrorHandling,
  E_UTILITIES,
  PanelProp,
  RBAC,
  SearchPanel,
  useMobile
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ScrollView } from 'react-native'

//import styled from 'styled-components/native'

export const UtilitiesScreen: React.FC<{}> = () => {
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const { changeBreadcrumb } = useContext(AppContext.AppContextInstance)
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const { navigate } = useNavigation()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { isMobile } = useMobile()
  const checkUserRoles = (transactionId: string) => {
    switch (transactionId) {
      case 'UploadDocuments':
        return permissions.includes(E_UTILITIES.VIEW_UPLOADDOCUMENT_UTILITIES)
      case 'iHeatData':
        return permissions.includes(E_UTILITIES.VIEW_IHEAT_INQUIRY_UTILITIES)
      case 'UploadDocumentsReport':
        return permissions.includes(E_UTILITIES.REPORT_UPLOADDOCUMENT_UTILITIES)
      case 'UnitLinkFundPriceHistory':
        return permissions.includes(CepPermission.ViewUnitLinkFundPrice)
      case 'uploadDocument&Guide':
        return permissions.includes(E_UTILITIES.UPLOAD_MANUAL_GUIDELINE_UTILITIES)
      case 'viewDocument&Guide':
        return permissions.includes(E_UTILITIES.VIEW_MANUAL_GUIDELINE_UTILITIES)
      case 'imagingUpload':
        return permissions.includes(E_UTILITIES.VIEW_IMAGING_UPLOAD_UTILITIES)
      default:
        return false
    }
  }

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('utilities:Utilities'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const navigator = (transactionId: string, screenName: string) => {
    if (checkUserRoles(transactionId)) {
      navigate('UtilitiesStack', { screen: screenName || '' })
    } else {
      showToast('You not have permission to this link', 'error')
    }
  }

  const data: PanelProp = {
    itemsSearch: [
      {
        id: 'UploadDocuments',
        name: t('utilities:UploadDocs'),
        navigator: () => {
          navigator('UploadDocuments', 'UploadDocumentScreen')
        }
      },
      {
        id: 'iHeatData',
        name: t('utilities:iHeatInquiry'),
        navigator: () => {
          navigator('iHeatData', 'InquiryIheatScreen')
        }
      },
      {
        id: 'UploadDocumentsReport',
        name: t('utilities:UploadDocumentsReport'),
        navigator: () => {
          navigator('UploadDocumentsReport', 'UploadDocumentReportScreen')
        }
      },
      {
        id: 'UnitLinkFundPriceHistory',
        name: t('utilities:UnitLinkFundPriceHistory'),
        navigator: () => {
          navigator('UnitLinkFundPriceHistory', 'UnitFundFundPriceScreen')
        }
      },
      {
        id: 'uploadDocument&Guide',
        name: t('utilities:UploadManualGuideline'),
        navigator: () => {
          navigator('uploadDocument&Guide', 'UpdateDocumentGuideScreen')
        }
      },
      {
        id: 'viewDocument&Guide',
        name: t('utilities:ViewManualGuideLine'),
        navigator: () => {
          navigator('viewDocument&Guide', 'ViewDocumentGuideScreen')
        }
      },
      {
        id: 'imagingUpload',
        name: t('utilities:ImagingUpload'),
        navigator: () => {
          navigator('imagingUpload', 'ImagingUploadScreen')
        }
      }
    ],
    itemsGroup: [
      {
        id: 'UploadDocuments',
        name: t('utilities:UploadDocs'),
        icon: () => <assets.UploadDocument />,
        children: [
          {
            id: 'UploadDocuments',
            name: t('utilities:UploadDocs'),
            navigator: () => {
              navigator('UploadDocuments', 'UploadDocumentScreen')
            }
          },
          {
            id: 'UploadDocumentsReport',
            name: t('utilities:UploadDocumentsReport'),
            navigator: () => {
              navigator('UploadDocumentsReport', 'UploadDocumentReportScreen')
            }
          },
          {
            id: 'imagingUpload',
            name: t('utilities:ImagingUpload'),
            navigator: () => {
              navigator('imagingUpload', 'ImagingUploadScreen')
            }
          }
        ]
      },
      {
        id: 'iHeatInquiry',
        name: t('utilities:IHeatData'),
        icon: () => <assets.UploadDocument />,
        children: [
          {
            id: 'iHeatData',
            name: t('utilities:IHeatData'),
            navigator: () => {
              navigator('iHeatData', 'InquiryIheatScreen')
            }
          }
        ]
      },
      {
        id: 'UnitLinkFundPriceHistory',
        name: t('utilities:UnitLinkFundPriceHistory'),
        icon: () => <assets.UploadDocument />,
        children: [
          {
            id: 'UnitLinkFundPriceHistory',
            name: t('utilities:UnitLinkFundPriceHistory'),
            navigator: () => {
              navigator('UnitLinkFundPriceHistory', 'UnitFundFundPriceScreen')
            }
          }
        ]
      },
      {
        id: 'supportingDocs',
        name: t('utilities:SupportingDocuments'),
        icon: () => <assets.UploadDocument />,
        children: [
          {
            id: 'uploadDocument&Guide',
            name: t('utilities:UploadManualGuideline'),
            navigator: () => {
              navigator('uploadDocument&Guide', 'UpdateDocumentGuideScreen')
            }
          },
          {
            id: 'viewDocument&Guide',
            name: t('utilities:ViewManualGuideLine'),
            navigator: () => {
              navigator('viewDocument&Guide', 'ViewDocumentGuideScreen')
            }
          }
        ]
      }
    ]
  }
  return (
    <ScrollView>
      <View style={{ width: isMobile ? '100%' : '70%', alignSelf: 'center' }}>
        <SearchPanel data={data} />
      </View>
    </ScrollView>
  )
}
