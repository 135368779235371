import { FontAwesome5 } from '@expo/vector-icons'
import {
  FieldList,
  Permission,
  TabList,
  TASK_DETAIL_ROLES,
  useMobile,
  Tab,
  SubmissionPermissions
} from '@pulseops/common'
// import {
//   AgentInfoData,
//   AgentScreen,
//   IFQuotationData,
//   IFQuotationScreen,
//   InvestmentInfoData,
//   InvestmentScreen,
//   PolicyValueInfoData,
//   PolicyValueScreen
// } from '@pulseops/task'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { TaskDetailStackParamList } from '../..'
import { CustomerDigitalScreen, CustomerInfoClaim } from './CustomerScreen'
import { PolicyInfoDigitalScreen } from './PolicyScreen'
import { ProductDigitalScreen, ProductInfoDigitalData } from './ProductScreen'

export const GeneralInfoDigitalScreen = (props: {
  navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen' | 'PolicyDetail'>
  policyNum: string
  roles: string[]
  isGeneral?: boolean
  isEditable?: boolean
}) => {
  const { isEditable = true } = props

  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation()

  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const [policyInfo, setPolicyInfo] = useState<FieldList.DataSources>([])
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoClaim | null>(null)
  const [productInfo, setProductInfo] = useState<ProductInfoDigitalData | null>(null)
  // const [pvInfo, setPVInfo] = useState<PolicyValueInfoData | null>(null)
  // const [investInfo, setInvestInfo] = useState<InvestmentInfoData | null>(null)
  // const [agentInfo, setAgentInfo] = useState<AgentInfoData | null>(null)
  // const [IFQuotationInfo, setIFQuotationInfo] = useState<IFQuotationData | null>(null)
  const generalInfos = [
    {
      id: 0,
      title: t('Tab:PolicyInfo'),
      permission: TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail,
      policyDetailPermission: SubmissionPermissions.ViewPolicyInfoPolicyDetail
    },
    {
      id: 1,
      title: t('Tab:CustomerInfo'),
      permission: TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail,
      policyDetailPermission: SubmissionPermissions.ViewCustomerInfoPolicyDetail
    },
    {
      id: 2,
      title: t('Tab:ProductInfo'),
      permission: TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail,
      policyDetailPermission: SubmissionPermissions.ViewProductInfoPolicyDetail
    }
    // {
    //   id: 3,
    //   title: t('Tab:PolicyValueInfo'),
    //   permission: TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail,
    //   policyDetailPermission: SubmissionPermissions.ViewPolicyValueInfoPolicyDetail
    // },
    // {
    //   id: 4,
    //   title: t('Tab:InvestmentInfo'),
    //   permission: TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail,
    //   policyDetailPermission: SubmissionPermissions.ViewInvestmentInfoPolicyDetail
    // },
    // {
    //   id: 5,
    //   title: t('Tab:AgentInfo'),
    //   permission: TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail,
    //   policyDetailPermission: SubmissionPermissions.ViewAgentInfoPolicyDetail
    // }
  ]

  const getTabs = () => {
    if (props.roles.length > 0) {
      let visibleTabs: Tab[] = generalInfos.filter((item) =>
        props.roles.includes(props.isGeneral ? item.permission : item.policyDetailPermission)
      )

      // if (props.isGeneral && props.roles.includes(Permission.View_IFQuotation_PD)) {
      //   visibleTabs.push({
      //     id: 6,
      //     title: t('Tab:IFQuotation')
      //   })
      // }

      if (visibleTabs.length > 0 && visibleTabs.find((item) => item.id === subTabIndex) === undefined) {
        setSubTabIndex(visibleTabs[0].id)
      }

      return visibleTabs
    }

    return []
  }

  const GeneralInfoItem = ({
    tabId,
    title,
    onNavigation
  }: {
    tabId: number
    title: string
    onNavigation: (tabId: number) => void
  }) => {
    return (
      <TouchableOpacity onPress={() => onNavigation(tabId)}>
        <View style={styles.generalInfoItem}>
          <Text style={styles.generalInfoItemText}>{title}</Text>
          <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
        </View>
      </TouchableOpacity>
    )
  }

  const onChangePage = (tabId: number) => {
    switch (tabId) {
      case 0:
        props.navigation.navigate('PolicyScreen')
        break
      case 1:
        props.navigation.navigate('CustomerScreen')
        break
      case 2:
        props.navigation.navigate('ProductScreen')
        break
      default:
        break
    }
  }

  const onChangeSubTab = (tabIdx: number) => {
    setSubTabIndex(tabIdx)
  }

  const accept = (roles: string) => {
    return (
      (roles === 'PLI' && !props.isGeneral && props.roles.includes(Permission.ViewPolicyInfoPolicyDetail)) ||
      (props.isGeneral && props.roles.includes(Permission.ViewGeneralInfoPolicyTaskDetail)) ||
      (roles === 'CI' && !props.isGeneral && props.roles.includes(Permission.ViewCustomerInfoPolicyDetail)) ||
      (props.isGeneral && props.roles.includes(Permission.ViewGeneralInfoCustomerTaskDetail)) ||
      (roles === 'PDI' && !props.isGeneral && props.roles.includes(Permission.ViewProductInfoPolicyDetail)) ||
      (props.isGeneral && props.roles.includes(Permission.ViewGeneralInfoProductTaskDetail)) ||
      (roles === 'PVI' && !props.isGeneral && props.roles.includes(Permission.ViewPolicyValueInfoPolicyDetail)) ||
      (props.isGeneral && props.roles.includes(Permission.ViewGeneralInfoPolicyValueTaskDetail)) ||
      (roles === 'II' && !props.isGeneral && props.roles.includes(Permission.ViewInvestmentInfoPolicyDetail)) ||
      (props.isGeneral && props.roles.includes(Permission.ViewGeneralInfoInvestmentTaskDetail)) ||
      (roles === 'AI' && !props.isGeneral && props.roles.includes(Permission.ViewAgentInfoPolicyDetail)) ||
      (!props.isGeneral && props.roles.includes(Permission.ViewGeneralInfoAgentTaskDetail))
      //  || (roles === 'IFQ' && !props.isGeneral && props.roles.includes(Permission.View_IFQuotation_PD))
    )
  }
  return (
    <View style={styles.tabContainer}>
      {isMobile ? (
        <FlatList
          data={getTabs()}
          renderItem={({ item }) => <GeneralInfoItem tabId={item.id} onNavigation={onChangePage} title={item.title} />}
          keyExtractor={(item) => item.id.toString()}
        />
      ) : (
        <TabList
          gutterBottom
          isSubTab={true}
          useOriginalId={true}
          menus={getTabs()}
          tabIndex={subTabIndex}
          onChangeTab={onChangeSubTab}
        />
      )}
      <View>
        {accept('PLI') && subTabIndex === 0 && isWide && props.policyNum.length > 0 && (
          <PolicyInfoDigitalScreen
            isEditable={isEditable}
            id={props.policyNum}
            data={policyInfo}
            setData={setPolicyInfo}
          />
        )}
        {accept('CI') && subTabIndex === 1 && isWide && props.policyNum.length > 0 && (
          <CustomerDigitalScreen
            isEditable={isEditable}
            id={props.policyNum}
            data={customerInfo}
            setData={setCustomerInfo}
          />
        )}
        {accept('PDI') && subTabIndex === 2 && isWide && props.policyNum.length > 0 && (
          <ProductDigitalScreen
            isEditable={isEditable}
            id={props.policyNum}
            data={productInfo}
            setData={setProductInfo}
          />
        )}

        {/* new */}
        {/* {accept('PVI') && subTabIndex === 3 && isWide && props.policyNum && (
          <PolicyValueScreen id={props.policyNum} data={pvInfo} setData={setPVInfo} />
        )}
        {accept('II') && subTabIndex === 4 && isWide && props.policyNum && (
          <InvestmentScreen id={props.policyNum} data={investInfo} setData={setInvestInfo} />
        )}
        {accept('AI') && subTabIndex === 5 && isWide && props.policyNum && (
          <AgentScreen id={props.policyNum} data={agentInfo} setData={setAgentInfo} />
        )} */}
        {/* {accept('IFQ') && isNotIFQuotation && subTabIndex === 6 && isWide && props.policyNum && (
          <IFQuotationScreen
            id={props.policyNum}
            data={IFQuotationInfo}
            setData={setIFQuotationInfo}
            editPermissionFlag={props.roles.includes(Permission.Edit_IFQuotation_PD)}
          />
        )} */}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginHorizontal: 'auto',
    justifyContent: 'center'
    // maxWidth: '1199px'
  },
  // Tab ++
  tabContainer: {
    marginHorizontal: 15,
    marginBottom: 15,
    flex: 1
  },
  // Tab --
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },
  // General Info Item
  generalInfoItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 22,
    marginBottom: 12
  },
  generalInfoItemText: {
    fontSize: 16,
    lineHeight: 28
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 16
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  sectionInfoContentRow: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sectionInfoContentCol: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  sectionInfoContentLabel: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  sectionInfoContentValue: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
