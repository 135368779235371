import { Panel, TaskDetail } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { InfoViewList, Title } from '../common'

interface Props {
  detail: TaskDetail.StopApl
}

export const StopAPL = ({ detail }: Props) => {
  const { t } = useTranslation(['requestInfo'])

  return (
    <>
      <Title title={t('STOP_APL_INFORMATION')} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <InfoViewList
          dataSource={[
            {
              label: t('APLDate'),
              value: detail.aplDate
            },
            {
              label: t('Reason'),
              value: detail.reason
            }
          ]}
        />
      </Panel>
    </>
  )
}
