
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { POApi } from '../POApi'
import { Maybe } from '@mxt/zio/codec'
import * as O from 'fp-ts/lib/Option'

export namespace IDCDetailService {

  export const CallingResultDetailInfo = t.type({
    obBusinessKey: Maybe(t.string),
    idcInfo: Maybe(t.type({
      p4oCaseId: t.string,
      idcProcessInstanceId: t.string,
      idcType: t.string,
      proposalNumber: Maybe(t.string),
      smsStatus: Maybe(t.string),
      voiceStatus: Maybe(t.string),
      parentProcessInstanceId: Maybe(t.string),
    })),
    callingResultInfo: Maybe(t.type({
      processInstanceId: Maybe(t.string),
      note: Maybe(t.string),
      createdDate: Maybe(t.string),
      createdBy: Maybe(t.string),
      updatedDate: Maybe(t.string),
      updatedBy: Maybe(t.string),
      details: Maybe(t.array(t.type({
        questionCode: Maybe(t.string),
        answerCode: Maybe(t.string),
        answerNote: Maybe(t.string),
      })))
    }))
  })

  export const A2s1QuestionInfo = t.type({
    category: t.string,
    transactionTypeWF: t.string,
    questionCode: t.string,
    questionDescEn: t.string,
    questionDescVN: t.string,
    questionOrder: t.number
  })

  export const A2s2QuestionAnswerMapping = t.type({
    questionCode: t.string,
    answerCode: t.string,
  })

  export const A2s3AnswerList = t.type({
    answerCode: t.string,
    answerEN: t.string,
    answerVN: t.string,
  })

  export const CallOutInfo = t.type({
    processInstanceId: Maybe(t.string),
    policyNumber: Maybe(t.string),
    assignee: Maybe(t.string),
    teamLeader: Maybe(t.string),
    transactionType: Maybe(t.string),
    calledDate: Maybe(t.string),
    savedDate: Maybe(t.string),
    createdBy: Maybe(t.string),
    createdDate: Maybe(t.string),
    updatedBy: Maybe(t.string),
    updatedDate: Maybe(t.string),
    callingInfoDTO: t.type({
      callId: Maybe(t.string),
      callReceiver: Maybe(t.string),
      callingMethod: Maybe(t.string),
      callbackTime: Maybe(t.string),
      doNotContacts: Maybe(t.array(t.string)),
      callingResult: Maybe(t.string),
      quickNote: Maybe(t.string),
      callScript: Maybe(t.string),
      hint: Maybe(t.string),
      phoneNumber: Maybe(t.string),
      callingDuration: Maybe(t.string),
      connectionStatus: Maybe(t.string),
      // callingTime: t.string
    }),
    sendingFileInfoDTO: t.type({
      sendingType: Maybe(t.string),
      guidelineDocument: Maybe(t.string),
      communicationHistory: Maybe(t.string)
    })
  })

  export const ActionHistoryInfo = t.type({
    obBusinessKey: t.string,
    idcInfo: t.type({
      p4oCaseId: t.string,
      idcProcessInstanceId: t.string,
      idcType: t.string,
      proposalNumber: Maybe(t.string),
      smsStatus: t.string,
      voiceStatus: t.string,
      parentProcessInstanceId: Maybe(t.string),
    }),
    callOutInfo: t.array(CallOutInfo)
  })

  export const CallResultInfo = t.type({
    code: t.string,
    nameVi: t.string,
    nameEn: t.string,
  })

  export type CallingResultDetailInfo = t.OutputOf<typeof CallingResultDetailInfo>
  export type A2s1QuestionInfo = t.OutputOf<typeof A2s1QuestionInfo>
  export type A2s2QuestionAnswerMapping = t.OutputOf<typeof A2s2QuestionAnswerMapping>
  export type A2s3AnswerList = t.OutputOf<typeof A2s3AnswerList>

  export const getCustomerAnswersList = (caseID: string) => pipe(
    POApi.get(`independence-query/api/v1/idc/customer/answer-results/${caseID}`)(t.type({
      body: t.array(t.type({
        processInstanceId: Maybe(t.string),
        answerResults: Maybe(t.array(t.type({
          questionCode: t.string,
          passCheck: t.string,
          answerUser: t.string,
          questionContent: t.string,
          commentUser: t.string,
          decisionResult: t.string,
        }))),
        createdDate: Maybe(t.string),
        completeTimeQuestion: Maybe(t.string),
        timeRetry: Maybe(t.number),
        smsSenderDate: Maybe(t.string),
        businessKey: Maybe(t.string),
        idcType: Maybe(t.string)
      }))
    })),
    ZIO.map((customerList) => {
      return customerList.body
    })
  )

  export const getCallingResultDetail = (processInstanceId: string) => {
    return pipe(
      POApi.get(`independence-query/api/v1/outbound/calling-result/idc/${processInstanceId}`)(t.type({
        data: t.array(CallingResultDetailInfo),
        code: Maybe(t.string),
        message: Maybe(t.string)
      })),
      ZIO.map((response) => {
        return response.data
      })
    )
  }

  export const getQuestionAnswerList = (transactiontype: string) => pipe(
    POApi.get(`independence-query/api/v1/outbound/calling-result/questionnaire-config/${transactiontype}`)(t.type({
      data: t.type({
        a2s1QuestionList: t.array(A2s1QuestionInfo),
        a2s2QuestionAnswerMapping: t.array(A2s2QuestionAnswerMapping),
        a2s3AnswerList: t.array(A2s3AnswerList)
      }),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((response) => {
      return response.data
    })
  )

  export const getActionHistoryList = (processInstanceId: string) => pipe(
    POApi.get(`independence-query/api/v1/outbound/calling-result/history-call?idcParentProcessInstanceId=${processInstanceId}`)(t.type({
      data: Maybe(t.array(ActionHistoryInfo)),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((response) => {
      return response.data
    })
  )

  export const getOBCaseStatusByIDCprocessInstanceId = (processInstanceId: string) => pipe(
    POApi.get(`pulseops/api/v1/idc/outbound/${processInstanceId}`)(t.type({
      data: Maybe(t.array(t.type({
        status: t.string,
        idcProcessInstanceId: t.string
      }))),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((responseData) => {
      const statusCode = pipe(
        responseData.data,
        O.fromNullable,
        O.fold(
          () => '',
          (caseStatusList) => pipe(
            caseStatusList.find((x) => x.status === 'PENDING'),
            O.fromNullable,
            O.fold(
              () => '',
              (statusCode) => statusCode.status
            )
          )
        )
      )
      return statusCode
    })
  )

  export const getReceiverAndCallResultList = () => pipe(
    POApi.get(`independence-query/api/v1/outbound/calling-result/history-call/config`)(t.type({
      data: t.type({
        callResultList: t.array(CallResultInfo),
        callReceiverList: t.array(CallResultInfo)
      }),
      code: Maybe(t.string),
      message: Maybe(t.string)
    })),
    ZIO.map((response) => {
      return response.data
    })
  )
}