import { ZIO } from '@mxt/zio'
import {
  AppContext,
  ClaimTypeInfo,
  ClaimUpdateService,
  CriticalIllnessType,
  ErrorHandling,
  GeneralService,
  HospitalType,
  ICDType,
  PulseOpsFormat,
  PulseOpsService,
  SelectOption,
  SubClaimType,
  SubClaimTypeBenefit,
  Tab,
  TabList,
  TaskDetail,
  TaskDetailService,
  TASK_DETAIL_ROLES,
  useMobile,
  VerClaimType,
  ClaimCaseDuplicatedList
} from '@pulseops/common'
import { requestRefreshAllInDashboard } from '@pulseops/task/common'
import { StackNavigationProp } from '@react-navigation/stack'
import { pipe } from 'fp-ts/lib/function'
import i18next from 'i18next'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { CompleteClaim, SaveClaimPaymentMode } from '../../actions/index'
import { TaskDetailStackParamList } from '../../TaskDetailStackParamList'
import { BenefitDetailForm } from '../adjudication-info'
import { SelectOptions } from '../supplementary-info'
import { ClaimTypeTab, SubClaimTypeEnum } from './claim-type'
import { ClaimInfoForm } from './claim-type/ClaimInfoForm'
import { PaymentMethodTab } from './payment-mode'
import { PolicyAndRider } from './policy-rider'
import { checkRolesForButton, onEndAction, EndPopup } from '../../actions'
import { PolicyRiderOrigin } from './claim-type/ClaimInfoForm'
interface Props {
  isClaim?: boolean
  detail: TaskDetail.Type
  claimData: {
    accidents: VerClaimType[] | null
    claimTypes?: SubClaimType[] | null
    subClaimTypes?: SubClaimTypeBenefit[] | null
    tpdTypes?: VerClaimType[] | null
    ciTypes?: CriticalIllnessType[] | null
    icdTypes: ICDType[] | null
    hospitals: HospitalType[] | null
  }
  clients: {
    firstName: string
    surName: string
    dob: string
    sex: string
    nationality: string
    occupation: {
      desc: string
    }
    addressDetails: {
      Current: {
        line1: string
        country: string
      }
    }
  }
  subtask: string
  disabled?: boolean
  roles: string[]
  isEditable?: boolean
  route: {
    id: string
    basketName: string
  }
  navigation: StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
  isInquiry?: boolean
  isQC?: boolean
}

enum ClaimType {
  CRITICAL_ILLNESS = 'CT0100',
  DEATH = 'CT0200',
  MEDICAL = 'CT0300',
  DISABILITY = 'CT0400'
}

export const gender = [
  {
    value: 'M',
    label: i18next.t('common:Male')
  },
  {
    value: 'F',
    label: i18next.t('common:Female')
  }
]
export interface HistoryPayment {
  paymentModeCode: string
  payeeIsNotValid: string
  payment?: {
    bankTransfer?:
      | {
          accountName: string
          idNumber: string
          bank: string
          bankName: string | null | undefined
          branch: string | null | undefined
          branchName: string | null | undefined
          accountNumber: string | number
        }
      | null
      | undefined
    payAtBank?:
      | {
          payeeName: string
          idNumber: string
          issuedDate: string
          issuedBy: string
          bankName: string
          bank: string | null | undefined
          branch: string | null | undefined
          branchName: string | null | undefined
        }
      | null
      | undefined
    payAtCounter?:
      | {
          payeeName: string | null | undefined
          idNumber: string | null | undefined
          gaOffice: string
        }
      | null
      | undefined
  }
}
interface ClaimScreenContextState {
  // historyReceiptInfoData: ReceiptData[] | null
  // historyPolicyRiderData: PolicyRiderData[] | null
  historyPaymentMethodData: HistoryPayment | null | undefined
}
export const LogHistoryData = React.createContext<ClaimScreenContextState>({
  // historyReceiptInfoData: [],
  // historyPolicyRiderData: [],
  historyPaymentMethodData: null
})

export type EligibleParams = {
  processInstanceId: string
  policyNo: string
  claimType: string
  subClaimType: string
  isRelatedToAccident: string
  haveTPDorCIorICD: string[]
  isICU: string
}

export const ClaimScreen: React.FC<Props> = ({
  detail,
  claimData,
  clients,
  isClaim,
  subtask,
  disabled,
  roles,
  isEditable = true,
  route,
  navigation,
  isInquiry
}) => {
  const { isWide } = useMobile()
  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const [isSaveLoading, setIsSaveLoading] = React.useState(false)
  const [isCompleteLoading, setIsCompleteLoading] = React.useState(false)
  const [paymentMethodData, setPaymentMethodData] = React.useState<HistoryPayment | null | undefined>(null)
  const { t, i18n } = useTranslation(['claim', 'message'])
  const { showToast, getFooterClaim, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const detailData: TaskDetail.VerificationClaim = React.useMemo(
    () => detail?.payload as TaskDetail.VerificationClaim,
    [detail]
  )
  const listClaim: ClaimTypeInfo[] = pipe(GeneralService.getClaims, ErrorHandling.runDidMount([])) || []
  const [isSaveBefore, setSaveBefore] = React.useState<boolean>(false)
  const [detailValue, setDetail] = React.useState<any>()
  const [isCanChangeTab, setIsCanChangeTab] = React.useState<boolean>(true)
  const [isPressSave, setIsPressSave] = React.useState<boolean>(false)
  const [duplicates, setDuplicates] = React.useState<ClaimCaseDuplicatedList>([])
  const [isEndPopupOpen, setIsEndPopupOpen] = React.useState<boolean>(false)
  const [isEligibleRequest, setEligibleRequest] = React.useState<{
    haveAccidentalBenefit: string
    haveTPDorCIorICD: string[]
    haveICUBenefit: string
    durationICU: string
    durationHospitalization: string
  }>()

  // SET DEFAULT VALUE FOR PAYEMNT
  const { PAYMENT } = detailData
  let validDate = moment(PAYMENT?.dob, 'DD/MM/YYYY', true).isValid()

  React.useEffect(() => {
    showGlobalLoading(true)
    if (subTabIndex === 0) {
      // pipe(
      //   isInquiry
      //     ? PulseOpsService.getHistoryDetail(route.id)
      //     : PulseOpsService.getTaskDetail(route.id, route.basketName),
      //   ZIO.catchAll((e) => {
      //     showGlobalLoading(false)
      //     return ZIO.fail(null)
      //   }),
      //   ZIO.tap((value) => {
      //     setDetail(value)
      //     showGlobalLoading(false)
      //     return ZIO.unit
      //   }),
      //   ZIO.unsafeRun({})
      // )
      pipe(
        ZIO.zipPar(
          ClaimUpdateService.getPaymenMethodLogs(detail.processId, PAYMENT.paymentMethod),
          isInquiry
            ? PulseOpsService.getHistoryDetail(route.id)
            : PulseOpsService.getTaskDetail(route.id, route.basketName)
        ),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.tap(([paymentMethods, dataDetail]) => {
          if (paymentMethods) {
            const payload = detail.payload as TaskDetail.VerificationClaim
            payload.PAYMENT = paymentMethods?.payment ?? PAYMENT
            form.setValue('isNotPolicyOwner', paymentMethods?.payment?.isPayeeNotPO ? true : false)
          }
          setPaymentMethodData(paymentMethods)
          setDetail(dataDetail)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
    if (subTabIndex === 1) {
      pipe(
        isInquiry
          ? PulseOpsService.getHistoryDetail(route.id)
          : PulseOpsService.getTaskDetail(route.id, route.basketName),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.map((value) => {
          setDetail(value)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
    if (subTabIndex === 2) {
      switch (paymentMethodData?.paymentModeCode) {
        case 'BANKTRANSFER':
          form.setValue('bankTransferForm', {
            accountName: PAYMENT?.bankTransfer?.accountName || '-',
            idNumber: PAYMENT?.bankTransfer?.idNumber || '-',
            accountNumber: PAYMENT?.bankTransfer?.accountNumber.toString() || '-',
            bank: null,
            branch: null,
            address: PAYMENT?.address || '-',
            dateOfBirth: PAYMENT?.dob
              ? validDate
                ? moment(PAYMENT?.dob, 'DD/MM/YYYY').toDate()
                : moment(PAYMENT?.dob, 'YYYY/MM/DD').toDate()
              : null,
            foreignAddress: PAYMENT?.foreignAddress || '-',
            gender: gender.find((cond) => cond.value === PAYMENT?.gender) || null,
            nationality: nationality.find((res) => res.value === PAYMENT.nationality) || null,
            nationality2: nationality.find((res) => res.value === PAYMENT.nationality2) || null,
            occupation: occupation.find((res) => res.value === PAYMENT?.occupation) || null,
            relationshipWithPO: relationships.find((res) => res.value === PAYMENT.relationshipWithPO) || null,
            residenceCountry: nationality.find((res) => res.value === PAYMENT.residenceCountry) || null
          })
          break
        default:
          break
      }
      pipe(
        isInquiry
          ? PulseOpsService.getHistoryDetail(route.id)
          : PulseOpsService.getTaskDetail(route.id, route.basketName),
        ZIO.catchAll((e) => {
          showGlobalLoading(false)
          return ZIO.fail(null)
        }),
        ZIO.map((value) => {
          setDetail(value)
          showGlobalLoading(false)
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [subTabIndex, detail.processId])

  const relationships: SelectOption[] =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          // label: i18n.language === 'vi' ? item.nameVn : item.nameEn
          label: i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const nationality: SelectOption[] =
    pipe(
      GeneralService.getCountries,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const occupation: SelectOption[] =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const defaultPaymentForm: Partial<ClaimInfoForm> = {
    isNotPolicyOwner: PAYMENT.isPayeeNotPO ? true : false,
    bankTransferForm: {
      accountName: PAYMENT?.bankTransfer?.accountName || '-',
      idNumber: PAYMENT?.bankTransfer?.idNumber || '-',
      accountNumber: PAYMENT?.bankTransfer?.accountNumber.toString() || '-',
      bank: null,
      branch: null,
      address: PAYMENT?.address || '-',
      dateOfBirth: PAYMENT?.dob
        ? validDate
          ? moment(PAYMENT?.dob, 'DD/MM/YYYY').toDate()
          : moment(PAYMENT?.dob, 'YYYY/MM/DD').toDate()
        : null,
      foreignAddress: PAYMENT?.foreignAddress || '-',
      gender: gender.find((cond) => cond.value === PAYMENT?.gender) || null,
      nationality: nationality.find((res) => res.value === PAYMENT.nationality) || null,
      nationality2: nationality.find((res) => res.value === PAYMENT.nationality2) || null,
      occupation: occupation.find((res) => res.value === PAYMENT?.occupation) || null,
      relationshipWithPO: relationships.find((res) => res.value === PAYMENT.relationshipWithPO) || null,
      residenceCountry: nationality.find((res) => res.value === PAYMENT.residenceCountry) || null
    },
    paidAtBank: {
      payeeName: PAYMENT?.payAtBank?.payeeName || '-',
      idNumber: PAYMENT?.payAtBank?.idNumber || '-',
      issuedDate: moment(PAYMENT?.payAtBank?.issuedDate, 'DD/MM/YYYY').toDate(),
      issuedBy: PAYMENT?.payAtBank?.issuedBy || '-',
      bankName: null,
      branch: null,
      address: PAYMENT?.address || '-',
      dateOfBirth: PAYMENT?.dob
        ? validDate
          ? moment(PAYMENT?.dob, 'DD/MM/YYYY').toDate()
          : moment(PAYMENT?.dob, 'YYYY/MM/DD').toDate()
        : null,
      foreignAddress: PAYMENT?.foreignAddress || '-',
      gender: gender.find((cond) => cond.value === PAYMENT?.gender) || null,
      nationality: nationality.find((res) => res.value === PAYMENT.nationality) || null,
      nationality2: nationality.find((res) => res.value === PAYMENT.nationality2) || null,
      occupation: occupation.find((res) => res.value === PAYMENT?.occupation) || null,
      relationshipWithPO: relationships.find((res) => res.value === PAYMENT.relationshipWithPO) || null,
      residenceCountry: nationality.find((res) => res.value === PAYMENT.residenceCountry) || null
    },
    payAtTheCounter: {
      gaOffice: null,
      payeeName: PAYMENT?.payAtCounter?.payeeName || '-',
      idNumber: PAYMENT?.payAtCounter?.idNumber || '-',
      address: PAYMENT?.address || '-',
      dateOfBirth: PAYMENT?.dob
        ? validDate
          ? moment(PAYMENT?.dob, 'DD/MM/YYYY').toDate()
          : moment(PAYMENT?.dob, 'YYYY/MM/DD').toDate()
        : null,
      foreignAddress: PAYMENT?.foreignAddress || '-',
      gender: gender.find((cond) => cond.value === PAYMENT?.gender) || null,
      nationality: nationality.find((res) => res.value === PAYMENT.nationality) || null,
      nationality2: nationality.find((res) => res.value === PAYMENT.nationality2) || null,
      occupation: occupation.find((res) => res.value === PAYMENT?.occupation) || null,
      relationshipWithPO: relationships.find((res) => res.value === PAYMENT.relationshipWithPO) || null,
      residenceCountry: nationality.find((res) => res.value === PAYMENT.residenceCountry) || null
    }
  }

  const form = useForm<ClaimInfoForm>({ defaultValues: defaultPaymentForm })

  const { handleSubmit } = form

  const onChangeSubTab = (tabIdx: number) => {
    if (isCanChangeTab) {
      setSubTabIndex(tabIdx)
      setIsPressSave(false)
    } else {
      let fieldName = 'ClaimType'
      if (subTabIndex === 0) {
        fieldName = 'ClaimType'
      } else if (subTabIndex === 1) {
        fieldName = 'PolicyRider'
      } else if (subTabIndex === 2) {
        fieldName = 'PaymentMethod'
      }
      showToast(t('message:MS070020', { field: t(`${fieldName}`) }), 'error')
    }
  }

  const claimInfos = [
    {
      id: 0,
      title: t('ClaimTypeTitle'),
      permission: TASK_DETAIL_ROLES.ViewAdminClaimTypeTaskDetail
    },
    {
      id: 1,
      title: t('PolicyRider'),
      permission: TASK_DETAIL_ROLES.ViewAdminPolicyRiderTaskDetail
    },
    {
      id: 2,
      title: t('PaymentMethod'),
      permission: TASK_DETAIL_ROLES.ViewAdminPaymentModeTaskDetail
    }
  ]

  const getTabs = (): Tab[] => {
    if (roles.length > 0) {
      return claimInfos.filter((item) => roles.includes(item.permission))
    }
    return []
  }

  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }

  React.useEffect(() => {
    const claimType: SelectOption | null =
      claimData.claimTypes
        ?.map((option: SubClaimType) => ({
          value: option.claimTypeCode,
          label: `${
            i18n.language === 'en'
              ? option.claimType
              : listClaim?.find((item) => item?.claimCode === option?.claimTypeCode)?.claimTypeVN || ''
          }`
        }))
        .find((elm) => elm.value === detailValue?.payload?.claimType) || null
    form.setValue('claimType', claimType)

    let subClaimType: SelectOption | null = null
    if (claimType) {
      const subClaimTypeList = claimData?.subClaimTypes?.map((option) => ({
        value: option.subClaimTypeCode,
        label: option.subClaimType
      }))
      switch (claimType?.value) {
        case ClaimType.CRITICAL_ILLNESS: {
          subClaimType = subClaimTypeList?.find((elm) => elm.value === SubClaimTypeEnum.CRITICAL_ILLNESS) || null
          break
        }
        case ClaimType.DEATH: {
          subClaimType = subClaimTypeList?.find((elm) => elm.value === SubClaimTypeEnum.DEATH) || null
          break
        }
        case ClaimType.DISABILITY: {
          subClaimType =
            subClaimTypeList?.find((elm) => elm.value === SubClaimTypeEnum.TOTAL_PERMANENT_DISABILITY) || null
          break
        }
        case ClaimType.MEDICAL: {
          subClaimType =
            subClaimTypeList?.find(
              (elm) => elm.value === (SubClaimTypeEnum.HOSPITAL_CASH || SubClaimTypeEnum.REIMBURSEMENT)
            ) || null
          break
        }

        default:
          break
      }
    }
    form.setValue('subClaimType', subClaimType)
    form.setValue('directBilling', detailValue?.payload?.directBilling ?? false)

    const lifeAssured = detailValue?.payload?.detail?.payload?.body?.lifeAssured
    const lifeAssuredName: SelectOption | null = lifeAssured
      ? {
          label: lifeAssured?.name,
          value: lifeAssured?.name
        }
      : null
    form.setValue('lifeAssuredName', lifeAssuredName)

    // Disability
    form.setValue('disability.isRelatedToAccident', detailValue?.payload?.tdpInfo?.isRelatedToAccident || false)

    form.setValue(
      'disability.claimEventDate',
      detailValue?.payload?.tdpInfo?.claimEventDate
        ? moment(detailValue?.payload?.tdpInfo?.claimEventDate, moment.ISO_8601).isValid()
          ? new Date(Date.parse(detailValue?.payload?.tdpInfo?.claimEventDate))
          : moment(detailValue?.payload?.tdpInfo?.claimEventDate, 'DD/MM/YYYY').toDate()
        : null
    )

    form.setValue(
      'disability.occurrenceDate',
      detailValue?.payload?.tdpInfo?.occurrenceDate
        ? moment(detailValue?.payload?.tdpInfo?.occurrenceDate, 'DD/MM/YYYYY').toDate()
        : null
    )

    form.setValue(
      'disability.accidentName',
      claimData.accidents
        ?.map((option: VerClaimType) => ({
          value: option.code,
          label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
        }))
        .find((elm) => elm.value === detailValue?.payload?.tdpInfo?.accidentName?.value) || null
    )

    let icd: any
    let icdProcessed: SelectOptions = []

    if (detailValue?.payload?.tdpInfo?.cause && typeof detailValue?.payload?.tdpInfo?.cause === 'string') {
      icdProcessed =
        claimData.icdTypes
          ?.filter(
            (elm) =>
              elm.id === detailValue?.payload.tdpInfo?.cause || elm.icdcode === detailValue?.payload.tdpInfo?.cause
          )
          ?.map((option: ICDType) => ({
            value: option.icdcode,
            label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
          })) || []
    } else if (detailValue?.payload?.tdpInfo?.cause && typeof detailValue?.payload?.tdpInfo?.cause === 'object') {
      icd = detailValue?.payload?.tdpInfo?.cause
        ? detailValue?.payload?.tdpInfo?.cause?.map((option: SelectOption) => {
            const filterValue = claimData?.icdTypes?.filter(
              (item) => item.id === option.value || item.icdcode === option.value
            )
            if (filterValue) {
              return filterValue.map((option: ICDType) => ({
                value: option.icdcode,
                label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
              }))
            }
            return []
          })
        : []
      icdProcessed = icd && icd.length > 0 ? icd.flat() : []
    }

    form.setValue('disability.cause', icdProcessed)

    let tpdName: any
    let tpdNameProcessed: SelectOptions = []
    if (detailValue?.payload?.tdpInfo?.tpdName && typeof detailValue?.payload?.tdpInfo?.tpdName === 'string') {
      tpdNameProcessed =
        claimData.tpdTypes
          ?.map((option: VerClaimType) => ({
            value: option.code,
            label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
          }))
          .filter((elm) => elm.value === detailValue?.payload.tdpInfo?.tpdName) || []
    } else if (detailValue?.payload?.tdpInfo?.tpdName && typeof detailValue?.payload?.tdpInfo?.tpdName === 'object') {
      tpdName = detailValue?.payload?.tdpInfo?.tpdName
        ? detailValue?.payload?.tdpInfo?.tpdName?.map((option: SelectOption) => {
            const filterValue = claimData?.tpdTypes?.filter((item) => item.code === option.value)

            if (filterValue) {
              return filterValue.map((option: VerClaimType) => ({
                value: option.code,
                label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
              }))
            }
            return []
          })
        : []
      tpdNameProcessed = tpdName && tpdName.length > 0 ? tpdName.flat() : []
    }

    form.setValue('disability.tpdName', tpdNameProcessed)
    form.setValue('disability.remark', detailValue?.payload?.tdpInfo?.remark || '')

    // HOSPITAL
    form.setValue('hospital.isRelatedToAccident', detailValue?.payload?.relatedToAccident)

    const occurrenceDate =
      detailValue?.payload?.PATIENT_DENTAL?.occurrenceDate ||
      detailValue?.payload?.PATIENT_IN?.occurrenceDate ||
      detailValue?.payload?.PATIENT_OUT?.occurrenceDate ||
      detailValue?.payload?.PATIENT_MATERNITY?.occurrenceDate

    form.setValue('hospital.occurrenceDate', occurrenceDate ? moment(occurrenceDate, 'DD/MM/YYYYY').toDate() : null)

    const accidentName =
      claimData.accidents
        ?.map((option: VerClaimType) => ({
          value: option.code,
          label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
        }))
        .find(
          (elm) =>
            elm.value === detailValue?.payload?.PATIENT_DENTAL?.accidentName?.value ||
            detailValue?.payload?.PATIENT_IN?.accidentName?.value ||
            detailValue?.payload?.PATIENT_OUT?.accidentName?.value ||
            detailValue?.payload?.PATIENT_MATERNITY?.accidentName?.value
        ) || null

    form.setValue('hospital.accidentName', accidentName)

    form.setValue(
      'hospital.hospitalType',
      claimData.hospitals
        ?.map((option: HospitalType) => ({
          value: option.code,
          label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVn}`
        }))
        .find(
          (elm) =>
            elm.value ===
            (detailValue?.payload?.PATIENT_DENTAL?.hospital ||
              detailValue?.payload?.PATIENT_IN?.hospital ||
              detailValue?.payload?.PATIENT_OUT?.hospital ||
              detailValue?.payload?.PATIENT_MATERNITY?.hospital)
        ) || null
    )
    const diagnosisCode =
      detailValue?.payload?.PATIENT_DENTAL?.diagnosis ||
      detailValue?.payload?.PATIENT_IN?.diagnosis ||
      detailValue?.payload?.PATIENT_OUT?.diagnosis ||
      detailValue?.payload?.PATIENT_MATERNITY?.diagnosis
    form.setValue(
      'hospital.mainDiagnosisType',
      claimData.icdTypes
        ?.filter((elm) => elm.id === diagnosisCode || elm.icdcode === diagnosisCode)
        ?.map((option: ICDType) => ({
          value: option.icdcode,
          label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
        }))[0] || null
    )

    const additionalDiagnosisType = detailValue?.payload?.PATIENT_IN?.additionalDiagnosis
      ? detailValue?.payload?.PATIENT_IN?.additionalDiagnosis?.map((option: SelectOption) => {
          const filterValue = claimData?.icdTypes?.filter(
            (item) => item.id === option.value || item.icdcode === option.value
          )
          if (filterValue) {
            return filterValue.map((option: ICDType) => ({
              value: option.icdcode,
              label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
            }))
          }
          return []
        })
      : []
    const additionalProcessed: SelectOptions =
      additionalDiagnosisType && additionalDiagnosisType.length > 0 ? additionalDiagnosisType.flat() : []

    form.setValue('hospital.additionalDiagnosisType', additionalProcessed)
    form.setValue(
      'hospital.admissionDate',
      moment(
        detailValue?.payload?.PATIENT_IN?.admissionDate ||
          detailValue?.payload?.PATIENT_OUT?.admissionDate ||
          detailValue?.payload?.PATIENT_MATERNITY?.admissionDate ||
          detailValue?.payload?.PATIENT_DENTAL?.admissionDate,
        'DD/MM/YYYY'
      ).toDate()
    )

    form.setValue(
      'hospital.dischargeDate',
      detailValue?.payload?.PATIENT_IN?.dischargeDate
        ? moment(detailValue?.payload?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate()
        : null
    )

    // DEATH
    form.setValue('death.isRelatedToAccident', detailValue?.payload?.death?.isRelatedToAccident || false)

    form.setValue(
      'death.claimEventDate',
      detailValue?.payload?.death?.claimEventDate
        ? moment(detailValue?.payload?.death?.claimEventDate, moment.ISO_8601).isValid()
          ? new Date(Date.parse(detailValue?.payload?.death?.claimEventDate))
          : moment(detailValue?.payload?.death?.claimEventDate, 'DD/MM/YYYY').toDate()
        : null
    )

    form.setValue(
      'death.occurrenceDate',
      detailValue?.payload?.death?.occurrenceDate
        ? moment(detailValue?.payload?.death?.occurrenceDate, 'DD/MM/YYYYY').toDate()
        : null
    )

    form.setValue(
      'death.accidentName',
      claimData.accidents
        ?.map((option: VerClaimType) => ({
          value: option.code,
          label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
        }))
        .find((elm) => elm.value === detailValue?.payload?.death?.accidentName?.value) || null
    )

    let icdDeath: any
    let icdDeathProcessed: SelectOptions = []

    if (detailValue?.payload?.death?.cause && typeof detailValue?.payload?.death?.cause === 'string') {
      icdDeathProcessed =
        claimData.icdTypes
          ?.filter(
            (elm) => elm.id === detailValue?.payload.death?.cause || elm.icdcode === detailValue?.payload.death?.cause
          )
          ?.map((option: ICDType) => ({
            value: option.icdcode,
            label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
          })) || []
    } else if (detailValue?.payload?.death?.cause && typeof detailValue?.payload?.death?.cause === 'object') {
      icdDeath = detailValue?.payload?.death?.cause
        ? detailValue?.payload?.death?.cause?.map((option: SelectOption) => {
            const filterValue = claimData?.icdTypes?.filter(
              (item) => item.id === option.value || item.icdcode === option.value
            )
            if (filterValue) {
              return filterValue.map((option: ICDType) => ({
                value: option.icdcode,
                label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
              }))
            }
            return []
          })
        : []
      icdDeathProcessed = icdDeath && icdDeath.length > 0 ? icdDeath.flat() : []
    }

    form.setValue('death.cause', icdDeathProcessed)

    form.setValue('death.remark', detailValue?.payload?.death?.remark || '')

    // Critical Illness
    form.setValue(
      'criticalIllness.claimEventDate',
      detailValue?.payload?.criticalIllness?.claimEventDate
        ? moment(detailValue?.payload?.criticalIllness?.claimEventDate, moment.ISO_8601).isValid()
          ? new Date(Date.parse(detailValue?.payload?.criticalIllness?.claimEventDate))
          : moment(detailValue?.payload?.criticalIllness?.claimEventDate, 'DD/MM/YYYY').toDate()
        : null
    )

    form.setValue(
      'criticalIllness.occurrenceDate',
      detailValue?.payload?.criticalIllness?.occurrenceDate
        ? moment(detailValue?.payload?.criticalIllness?.occurrenceDate, 'DD/MM/YYYYY').toDate()
        : null
    )
    form.setValue(
      'criticalIllness.deathDate',
      detailValue?.payload?.criticalIllness?.deathDate
        ? moment(detailValue?.payload?.criticalIllness?.deathDate, 'DD/MM/YYYYY').toDate()
        : null
    )

    form.setValue(
      'criticalIllness.accidentName',
      claimData.accidents
        ?.map((option: VerClaimType) => ({
          value: option.code,
          label: `${option.code} - ${i18n.language === 'en' ? option.nameEn : option.nameVi}`
        }))
        .find((elm) => elm.value === detailValue?.payload?.criticalIllness?.accidentName?.value) || null
    )

    form.setValue(
      'criticalIllness.isRelatedToAccident',
      detailValue?.payload?.criticalIllness?.isRelatedToAccident || false
    )

    let diagnosis: any
    let diagnosisProcessed: SelectOptions = []

    if (
      detailValue?.payload?.criticalIllness?.diagnosis &&
      typeof detailValue?.payload?.criticalIllness?.diagnosis === 'string'
    ) {
      diagnosisProcessed =
        claimData.icdTypes
          ?.filter(
            (elm) =>
              elm.id === detailValue?.payload.criticalIllness?.diagnosis ||
              elm.icdcode === detailValue?.payload.criticalIllness?.diagnosis
          )
          ?.map((option: ICDType) => ({
            value: option.icdcode,
            label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
          })) || []
    } else if (
      detailValue?.payload?.criticalIllness?.diagnosis &&
      typeof detailValue?.payload?.criticalIllness?.diagnosis === 'object'
    ) {
      diagnosis = detailValue?.payload?.criticalIllness?.diagnosis
        ? detailValue?.payload?.criticalIllness?.diagnosis?.map((option: SelectOption) => {
            const filterValue = claimData?.icdTypes?.filter(
              (item) => item.id === option.value || item.icdcode === option.value
            )
            if (filterValue) {
              return filterValue.map((option: ICDType) => ({
                value: option.icdcode,
                label: `${option.icdcode} - ${i18n.language === 'en' ? option.diseaseNameEn : option.diseaseNameVn}`
              }))
            }
            return []
          })
        : []
      diagnosisProcessed = diagnosis && diagnosis.length > 0 ? diagnosis.flat() : []
    }

    form.setValue('criticalIllness.diagnosis', diagnosisProcessed)

    let ciName: any
    let ciNameProcessed: SelectOptions = []
    if (
      detailValue?.payload?.criticalIllness?.ciName &&
      typeof detailValue?.payload?.criticalIllness?.ciName === 'string'
    ) {
      ciNameProcessed =
        claimData.ciTypes
          ?.filter(
            (elm) =>
              elm.id === detailValue?.payload.criticalIllness?.ciName ||
              elm.cilCode === detailValue?.payload.criticalIllness?.ciName
          )
          ?.map((option: CriticalIllnessType) => ({
            value: option.cilCode,
            label: `${option.cilCode} - ${i18n.language === 'en' ? option.nameEn : option.nameVn}`
          })) || []
    } else if (
      detailValue?.payload?.criticalIllness?.ciName &&
      typeof detailValue?.payload?.criticalIllness?.ciName === 'object'
    ) {
      ciName = detailValue?.payload?.criticalIllness?.ciName
        ? detailValue?.payload?.criticalIllness?.ciName?.map((option: SelectOption) => {
            const filterValue = claimData?.ciTypes?.filter((item) => item.cilCode === option.value)
            if (filterValue) {
              return filterValue.map((option: CriticalIllnessType) => ({
                value: option.cilCode,
                label: `${option.cilCode} - ${i18n.language === 'en' ? option.nameEn : option.nameVn}`
              }))
            }
            return []
          })
        : []
      ciNameProcessed = ciName && ciName.length > 0 ? ciName.flat() : []
    }

    form.setValue('criticalIllness.ciName', ciNameProcessed)
    form.setValue('criticalIllness.remark', detailValue?.payload?.criticalIllness?.remark || '')
  }, [claimData, detailValue, subTabIndex])

  React.useEffect(() => {
    const isSupsend = subtask.split('-')[1] === 'SUSPENDED'

    getFooterClaim(
      isSupsend
        ? []
        : [
            { name: t('Complete'), action: onComplete, isLoading: isCompleteLoading, isVisible: isClaim },
            {
              name: t('claim:End'),
              action: detail ? onEndAction(showToast, t)()(setIsEndPopupOpen, () => {}) : () => {},
              isVisible: checkRolesForButton('CL', roles, false)('End')
            },
            { name: t('Save'), action: onSubmit, isLoading: isSaveLoading }
          ]
    )
  }, [detail, isSaveLoading, isCompleteLoading, subTabIndex, detailValue, isSaveBefore])

  const calculateFaceAmount = (): number => {
    const detailPayload = detailValue ? detailValue.payload.detail.payload : detailData.detail.payload
    const components = detailPayload?.policyRider?.components as BenefitDetailForm[]
    let riders = []
    if (form.getValues('policyRiders')) {
      riders = form.getValues('policyRiders')
    } else {
      riders = components && _.isArray(components) ? _.cloneDeep(components) : []
    }
    let totalFaceAmount = 0
    if (riders.length > 0) {
      riders.forEach((rider) => {
        rider.benefitClaimCode
          .filter((item) => item.eligible)
          .forEach((benefitClaim) => {
            totalFaceAmount += benefitClaim.claimAmount ? parseInt(benefitClaim.claimAmount.replace(/\,/g, ''), 10) : 0
          })
      })
    }
    return totalFaceAmount
  }

  const onComplete = () => {
    const claimDirty = !!form.formState.dirtyFields.subBenefit
    const totalFaceAmount = calculateFaceAmount()
    const claimType = form.watch('claimType')?.value || ''
    const subClaimType = form.watch('subClaimType')?.value || ''

    if (!isSaveBefore) {
      showToast(`${t('claim:SaveBeforeComplete')}`, 'error')
      return
    }
    if (subTabIndex === 0 && detailData.claimType === ClaimType.MEDICAL) {
      if (form.getValues().subBenefit.length > 0) {
        if (claimDirty) {
          showToast(t('message:MS070020', { field: t('ClaimType') }), 'error')
          return
        } else {
          showToast(`${t('claim:SaveBeforeComplete')}`, 'error')
          return
        }
      } else {
        showToast(`${t('message:MS020009', { field: t('claim:SubBenefit') })}`, 'error')
        return
      }
    } else if (subTabIndex === 1) {
      const inValidData = form
        .getValues()
        .policyRiders.filter((policyRider) =>
          policyRider.benefitClaimCode.find(
            (item) =>
              item.eligible && (item.claimAmount === '' || item.claimAmount === '0' || item.claimAmount === undefined)
          )
        )
      inValidData &&
        inValidData.length > 0 &&
        showToast(`${t('message:MS020001', { field: t('claim:ClaimAmount') })}`, 'error')
    }

    const validData = (
      (detailValue.payload.detail.payload.policyRider?.components as Array<PolicyRiderOrigin>) || []
    ).filter((policyRider) => policyRider.benefitClaimCode.find((item) => !!item.eligible))

    if (validData.length === 0) {
      showToast(t('message:MS070009'), 'error')
    } else {
      CompleteClaim(showToast, setIsCompleteLoading, t)(detail, totalFaceAmount, claimType, subClaimType)(() => {
        requestRefreshAllInDashboard()
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          navigation.navigate('UserDrawer', {
            screen: 'TaskDashboardScreen'
          })
        }
      })
    }
  }
  // Form propgress
  const onSubmit = handleSubmit(() => {
    const detailSubmit = detailValue ? detailValue.payload.detail.payload : detailData.detail.payload
    const processId = detailValue ? detailValue.processId : detail.processId

    let faceAmountValue = calculateFaceAmount()
    const claimType = form.watch('claimType')?.value || detailValue?.payload?.claimType
    const subClaimType = form.watch('subClaimType')?.value || undefined
    const directBilling = form.watch('directBilling') ?? false

    const {
      criticalIllness: {
        isRelatedToAccident,
        claimEventDate,
        diagnosis,
        occurrenceDate,
        accidentName,
        deathDate,
        ciName,
        remark
      },
      disability: {
        isRelatedToAccident: isRelatedToAccidentDisability,
        claimEventDate: claimEventDateDisability,
        occurrenceDate: occurrenceDateDisability,
        accidentName: accidentNameDisability,
        cause: causeDisability,
        tpdName: tpdNameDisability,
        remark: remarkDisability
      },
      death: {
        isRelatedToAccident: isRelatedToAccidentDeath,
        claimEventDate: claimEventDateDeath,
        occurrenceDate: occurrenceDateDeath,
        accidentName: accidentNameDeath,
        cause: causeDeath,
        remark: remarkDeath
      },
      hospital: {
        isRelatedToAccident: isRelatedToAccidentHospital,
        occurrenceDate: occurrenceDateHospital,
        accidentName: accidentNameHospital,
        mainDiagnosisType,
        additionalDiagnosisType,
        hospitalType,
        admissionDate,
        dischargeDate
      }
    } = form.getValues()
    switch (claimType) {
      case ClaimType.CRITICAL_ILLNESS:
        detailSubmit.body.attributes.CRITICAL_ILLNESS = {
          isRelatedToAccident: isRelatedToAccident,
          claimEventDate: PulseOpsFormat.date(claimEventDate) || null,
          diagnosis: diagnosis,
          occurrenceDate: PulseOpsFormat.date(occurrenceDate) || null,
          accidentName: accidentName || null,
          deathDate: PulseOpsFormat.date(deathDate) || null,
          ciName: ciName || [],
          remark: remark
        }
        break
      case ClaimType.DISABILITY:
        detailSubmit.body.attributes.DISABILITY = {
          isRelatedToAccident: isRelatedToAccidentDisability,
          claimEventDate: PulseOpsFormat.date(claimEventDateDisability) || null,
          occurrenceDate: PulseOpsFormat.date(occurrenceDateDisability) || null,
          accidentName: accidentNameDisability || null,
          cause: causeDisability || [],
          tpdName: tpdNameDisability || [],
          remark: remarkDisability
        }
        break
      case ClaimType.DEATH:
        detailSubmit.body.attributes.DEATH = {
          isRelatedToAccident: isRelatedToAccidentDeath,
          claimEventDate: PulseOpsFormat.date(claimEventDateDeath) || null,
          occurrenceDate: PulseOpsFormat.date(occurrenceDateDeath) || null,
          accidentName: accidentNameDeath || null,
          cause: causeDeath || [],
          tpdName: [],
          remark: remarkDeath
        }
        break
      case ClaimType.MEDICAL:
        if (detailSubmit.body.attributes.PATIENT_IN) {
          detailSubmit.body.attributes.PATIENT_IN = {
            ...detailSubmit.body.attributes.PATIENT_IN,
            isRelatedToAccident: isRelatedToAccidentHospital,
            occurrenceDate: PulseOpsFormat.date(occurrenceDateHospital) || null,
            accidentName: accidentNameHospital || null,
            hospital: hospitalType?.value || '',
            hospitalLabel: claimData?.hospitals?.find((item) => item.code === hospitalType?.value)?.nameVn ?? '',
            diagnosis: mainDiagnosisType?.value || '',
            additionalDiagnosis: additionalDiagnosisType,
            admissionDate: PulseOpsFormat.date(admissionDate) || null,
            dischargeDate: PulseOpsFormat.date(dischargeDate) || ''
          }
        } else if (detailSubmit.body.attributes.PATIENT_OUT) {
          detailSubmit.body.attributes.PATIENT_OUT = {
            ...detailSubmit.body.attributes.PATIENT_OUT,
            isRelatedToAccident: isRelatedToAccidentHospital,
            occurrenceDate: PulseOpsFormat.date(occurrenceDateHospital) || null,
            accidentName: accidentNameHospital || null,
            hospital: hospitalType?.value || '',
            hospitalLabel: claimData?.hospitals?.find((item) => item.code === hospitalType?.value)?.nameVn ?? '',
            diagnosis: mainDiagnosisType?.value || '',
            admissionDate: PulseOpsFormat.date(admissionDate) || ''
          }
        } else if (detailSubmit.body.attributes.PATIENT_DENTAL) {
          detailSubmit.body.attributes.PATIENT_DENTAL = {
            ...detailSubmit.body.attributes.PATIENT_DENTAL,
            isRelatedToAccident: isRelatedToAccidentHospital,
            occurrenceDate: PulseOpsFormat.date(occurrenceDateHospital) || null,
            accidentName: accidentNameHospital || null,
            hospital: hospitalType?.value || '',
            hospitalLabel: claimData?.hospitals?.find((item) => item.code === hospitalType?.value)?.nameVn ?? '',
            diagnosis: mainDiagnosisType?.value || '',
            admissionDate: PulseOpsFormat.date(admissionDate) || ''
          }
        } else if (detailSubmit.body.attributes.PATIENT_MATERNITY) {
          detailSubmit.body.attributes.PATIENT_MATERNITY = {
            ...detailSubmit.body.attributes.PATIENT_MATERNITY,
            isRelatedToAccident: isRelatedToAccidentHospital,
            occurrenceDate: PulseOpsFormat.date(occurrenceDateHospital) || null,
            accidentName: accidentNameHospital || null,
            hospital: hospitalType?.value || '',
            hospitalLabel: claimData?.hospitals?.find((item) => item.code === hospitalType?.value)?.nameVn ?? '',
            diagnosis: mainDiagnosisType?.value || '',
            admissionDate: PulseOpsFormat.date(admissionDate) || ''
          }
        }
        break
      default:
        break
    }
    if (processId) {
      if (subTabIndex === 0) {
        showGlobalLoading(true)
        pipe(
          TaskDetailService.saveLog(
            detail.id,
            {
              ...detailSubmit,
              body: {
                ...detailSubmit.body,
                claimType: claimType,
                subClaimType: subClaimType,
                totalFaceAmount: faceAmountValue,
                directBilling: directBilling
              },
              verification: {
                subBenefit: _.cloneDeep(form.getValues().subBenefit)
              },
              policyRider: {
                components: []
              }
            },
            detail.processId,
            []
          ),
          ZIO.tap((_) => {
            pipe(GeneralService.triggerActionClaim(detailValue.payload.detail.businessKey), ZIO.unsafeRun({}))
            setDetail({})
            setIsCanChangeTab(true)
            setSaveBefore(true)
            showToast(t('message:MS990017'), 'success')
            showGlobalLoading(false)
            setSubTabIndex(1)
            setIsPressSave(true)
            return ZIO.unit
          }),
          ZIO.catchAll((e) =>
            ZIO.effectTotal(() => {
              setSaveBefore(false)
              setIsCanChangeTab(false)
              showGlobalLoading(false)
            })
          ),
          ZIO.unsafeRun({})
        )
      } else if (subTabIndex === 1) {
        const validData = form
          .getValues()
          .policyRiders.filter((policyRider) => policyRider.benefitClaimCode.find((item) => !!item.eligible))
        const validAmount = form
          .getValues()
          .policyRiders.some(
            (policyRider) =>
              policyRider.benefitClaimCode.find((item) => !!item.eligible) &&
              policyRider.benefitClaimCode.some((item) => parseInt(item.claimAmount || '0') > 0)
          )

        if (validData.length === 0) {
          showToast(`${t('message:MS070009')}`, 'error')
        } else if (!validAmount) {
          showToast(`${t('message:MS070025')}`, 'error')
        } else {
          showGlobalLoading(true)
          pipe(
            GeneralService.saveDublicateClaim({
              admissionDate: detailValue.payload.PATIENT_IN?.admissionDate || '',
              businessKey: detailValue.payload.detail.businessKey || '',
              claimActivity: '',
              claimNo: detailValue.payload.detail.claimNumber,
              claimStatus: '',
              claimType: form.getValues('claimType.value'),
              clientNumber: detailValue.clientNumber || '',
              dischargeDate: detailValue.payload.PATIENT_IN?.dischargeDate || '',
              policy: detailValue.primaryPolicyNo || '',
              processInstanceId: detailValue.payload.detail?.processInstanceId,
              components: form.getValues().policyRiders.map((item) => ({
                benefitClaimCode: item.benefitClaimCode.map((x) => ({
                  benefitClaimCode: x.benefitClaimCode,
                  benefitClaimEN: x.benefitClaimEN,
                  eligible: x.eligible || false,
                  id: x.id
                })),
                componentCode: item.componentCode,
                componentEN: item.componentEN,
                id: item.id,
                planName: item.planName,
                productCode: item.productCode,
                productEN: item.productEN
              }))
            }),
            ZIO.unsafeRun({})
          )
          pipe(
            GeneralService.updateEligible(
              detailValue.payload.detail?.processInstanceId,
              detailValue.primaryPolicyNo,
              form.getValues('claimType.value'),
              form.getValues('subClaimType.value'),
              isEligibleRequest?.haveAccidentalBenefit || '',
              isEligibleRequest?.haveTPDorCIorICD || [],
              isEligibleRequest?.haveICUBenefit || '',
              isEligibleRequest?.durationICU || '',
              isEligibleRequest?.durationHospitalization || '',
              detailValue.payload.detail?.PATIENT_IN?.admissionDate,
              detailValue.payload.detail?.businessKey,
              detailValue.id,
              detailValue.payload.detail?.payload.source,
              '',
              _.flatten(
                form.getValues().policyRiders.map((item) =>
                  item.benefitClaimCode.map((x) => ({
                    benefitClaimCode: x.benefitClaimCode,
                    benefitClaimEN: x.benefitClaimEN,
                    benefitClaimWF: x.benefitClaimWF,
                    benefitClaimVN: x.benefitClaimVN,
                    eligible: x.eligible || false,
                    id: x.id,
                    claimAmount: x.claimAmount || '0',
                    sumAssure: item.subTotalSumAssured || '0',
                    componentCode: item.componentCode
                  }))
                )
              )
            ),
            ZIO.unsafeRun({})
          )
          pipe(
            TaskDetailService.saveLog(
              detail.id,
              {
                ...detailSubmit,
                body: {
                  ...detailSubmit.body,
                  claimType: claimType,
                  subClaimType: subClaimType,
                  totalFaceAmount: faceAmountValue,
                  directBilling: directBilling
                },
                policyRider: {
                  components: _.cloneDeep(form.getValues().policyRiders)
                }
              },
              detail.processId,
              []
            ),
            ZIO.tap((_) => {
              pipe(GeneralService.triggerActionClaim(detailValue.payload.detail.businessKey), ZIO.unsafeRun({}))
              setSaveBefore(true)
              setIsCanChangeTab(true)
              showToast(t('message:MS990017'), 'success')
              showGlobalLoading(false)
              setIsPressSave(false)
              setSubTabIndex(2)
              return ZIO.unit
            }),
            ZIO.catchAll((e) =>
              ZIO.effectTotal(() => {
                setSaveBefore(false)
                setIsCanChangeTab(false)
                showGlobalLoading(false)
              })
            ),
            ZIO.unsafeRun({})
          )
        }
      } else {
        SaveClaimPaymentMode(showToast, setIsSaveLoading, t)(
          detail,
          form.getValues().payeeIsNotValid,
          setIsCanChangeTab
        )
        setPaymentMethodData({
          paymentModeCode: detailData.PAYMENT.paymentMethod,
          payeeIsNotValid: String(form.getValues().payeeIsNotValid)
        })

        // const componentRiders = _.cloneDeep(form.getValues().policyRiders)
        //   ? detailData.detail.payload.policyRider?.components
        //   : []
        showGlobalLoading(true)
        pipe(
          TaskDetailService.saveLog(
            detail.id,
            {
              ...detailSubmit,
              body: {
                ...detailSubmit.body,
                claimType: claimType,
                subClaimType: subClaimType,
                totalFaceAmount: faceAmountValue,
                directBilling: directBilling
              }
              // policyRider: {
              //   components: detailData.detail.payload.policyRider?.components ?? []
              // }
            },
            detail.processId,
            []
          ),
          ZIO.tap((_) => {
            pipe(GeneralService.triggerActionClaim(detailValue.payload.detail.businessKey), ZIO.unsafeRun({}))
            setSaveBefore(true)
            setIsCanChangeTab(true)
            showToast(t('message:MS990017'), 'success')
            showGlobalLoading(false)
            setIsPressSave(false)
            return ZIO.unit
          }),
          ZIO.catchAll((e) =>
            ZIO.effectTotal(() => {
              setSaveBefore(false)
              setIsCanChangeTab(false)
              showGlobalLoading(false)
            })
          ),
          ZIO.unsafeRun({})
        )
      }
    }
  })

  const tabs = getTabs()

  const onHandleActionSuccess = () => {
    requestRefreshAllInDashboard()

    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('UserDrawer', {
        screen: 'TaskDashboardScreen'
      })
    }
  }

  return (
    <LogHistoryData.Provider
      value={{
        historyPaymentMethodData: paymentMethodData
      }}
    >
      <View style={styles.wrapper}>
        <TabList
          gutterBottom
          isSubTab={true}
          useOriginalId={true}
          menus={tabs}
          tabIndex={subTabIndex}
          onChangeTab={onChangeSubTab}
        />
        <View>
          {hasTab(0, tabs) && subTabIndex === 0 && isWide && detail !== null && (
            <ClaimTypeTab
              innerProps={{
                ...claimData,
                claimNumber: detail?.claimNumber || '-',
                form: form,
                detail: detailValue?.payload || (detail?.payload as TaskDetail.VerificationClaim)
              }}
              disabled={disabled || !isEditable}
              setIsCanChangeTab={setIsCanChangeTab}
            />
          )}
          {hasTab(1, tabs) && subTabIndex === 1 && isWide && detail !== null && (
            <PolicyAndRider
              innerProps={{
                detail: detailValue?.payload || (detail?.payload as TaskDetail.VerificationClaim),
                form,
                isPressSave
              }}
              disabled={disabled || !isEditable}
              setIsCanChangeTab={setIsCanChangeTab}
              duplicateList={duplicates}
              setDuplicates={setDuplicates}
              setEligibleRequest={setEligibleRequest}
            />
          )}
          {hasTab(2, tabs) && subTabIndex === 2 && isWide && detail !== null && (
            <PaymentMethodTab
              innerProps={{ detail: detail?.payload as TaskDetail.VerificationClaim, clients, form }}
              disabled={disabled || !isEditable}
              setIsCanChangeTab={setIsCanChangeTab}
            />
          )}
          {detail && (
            <EndPopup
              open={isEndPopupOpen}
              onClose={() => setIsEndPopupOpen(false)}
              onSuccess={onHandleActionSuccess}
              selectedValue={{
                taskId: detail.id,
                updateToCore: detail.updateToCore,
                category: detail.category,
                caseId: detail.caseId
              }}
            />
          )}
        </View>
      </View>
    </LogHistoryData.Provider>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flex: 1
  },
  loadingWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    bottom: -60,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 60,
    width: '100%',
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center',
    marginRight: 0
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  },
  btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
  btnDisabled: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#FFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
