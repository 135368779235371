import { COLORS } from './../constants'
import styled from 'styled-components/native'
import { View } from 'react-native'

export const SC = {
  Container: styled(View)`
    width: 100%;
    height: 100%;
    background-color: ${COLORS.WHITE};
  `,

  Header: styled(View)`
    width: 100%;
    margin-top: 8px;
    padding: 20px;
  `,

  Body: styled(View)`
    background: ${COLORS.SNOW};
    border: 1px solid ${COLORS.HOWKES_BLUE};
    border-radius: 8px;
    padding: 30px;
    width: 98%;
    margin: 0 auto;
  `,

  Row: styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  `,

  Col_3: styled(View)`
    width: 100%;
    max-width: 33.3333333333%;
    padding: 0 15px;
  `,

  GROUP: styled(View)`
    padding: 24px 0;
  `,

  SEARCH_AREA: styled(View)`
    padding: 20px;
    background-color: ${COLORS.SNOW};
    border: 1px solid ${COLORS.HOWKES_BLUE};
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 20px;
  `
}
