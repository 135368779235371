import { Stream, ZStream } from '@mxt/zio/stream'
import { pipe } from 'fp-ts/function'
import * as zr from '@mxt/zio-react'

export function subscribe<S0>(initialState: S0): <E, A>(stream: Stream<E, A>) => A extends S0 ? A : S0 | A
export function subscribe(): <E, A>(stream: Stream<E, A>) => A | null
export function subscribe<S0>(initialState: S0 | null = null) {
  return <E, A>(stream: Stream<E, A>) =>
    pipe(
      stream,
      ZStream.catchAll((err) => {
        console.error('subscribe error', err)
        return ZStream.succeed(initialState as any)
      }),
      zr.subscribe(initialState)
    )
}
