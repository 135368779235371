import { Throwable, ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { AuthService, mapBenGender, POApi, StorageBlob } from '@pulseops/common'
import { tr } from 'date-fns/locale'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export namespace SubmissionAgentService {
  export const TerminationDetail = Maybe(
    t.type({
      agentCode: t.string,
      agentSurName: Maybe(t.string),
      agentName: Maybe(t.string),
      agentGivName: Maybe(t.string),
      appointedDate: Maybe(t.string),
      terminateDate: Nullable(t.string),
      agentType: Maybe(t.string),
      clientNum: Maybe(t.string),
      title: Maybe(t.string),
      unitCode: Maybe(t.string),
      unitDescription: Maybe(t.string),
      branchCode: Maybe(t.string),
      branchDescription: Maybe(t.string),
      officeCode: Maybe(t.string),
      officeDescription: Maybe(t.string),
      regionCode: Maybe(t.string),
      regionDescription: Maybe(t.string),
      subzoneDescription: Maybe(t.string),
      zoneCode: Maybe(t.string),
      zoneDescription: Maybe(t.string),
      debt: Maybe(t.string),
      terminationReason: Maybe(t.string),
      officeReason: Maybe(t.string),
      leaderReason: Maybe(t.string)
    })
  )

  export const OfficeInfo = Maybe(
    t.type({
      code: Maybe(t.string),
      name: Maybe(t.string),
      address: Maybe(t.string),
      gaCode: Maybe(t.string),
      officeMovementReason: Maybe(t.string)
    })
  )

  export const UnitInfo = Maybe(
    t.type({
      code: Maybe(t.string),
      desc: Maybe(t.string),
      unitMovementReason: Maybe(t.string)
    })
  )

  export const BranchInfo = Maybe(
    t.type({
      code: Maybe(t.string),
      desc: Maybe(t.string)
    })
  )

  export const OtpInfo = t.type({
    otp: t.string,
    payload: Nullable(t.string),
    receiverPhone: t.string,
    transactionCode: t.string,
    transactionName: t.string
  })


  export const AgentRequest = t.type({
    exchangeId: t.string,
    body: t.type({
      transactionType: t.string,
      optionFlag: t.boolean,
      agent: TerminationDetail
    }),
    status: Maybe(t.string),
    responseStatus: t.type({
      suspendCode: Nullable(t.string),
      suspendReason: Nullable(t.string),
      clientError: Nullable(t.string),
      code: Maybe(t.number),
      message: Maybe(t.string),
      errors: Maybe(
        t.array(
          t.type({
            code: t.string,
            message: Maybe(t.string),
            type: Nullable(t.string)
          })
        )
      )
    })
  })

  export const TransferDetail = Maybe(
    t.type({
      policyNum: Maybe(t.string),
      cownNum: Maybe(t.string),
      cownSurName: Maybe(t.string),
      cownGivName: Maybe(t.string),
      cownName: Maybe(t.string),
      cnttype: Maybe(t.string),
      statcode: Maybe(t.string),
      issueDate: Maybe(t.string),
      ptDate: Maybe(t.string),
      occDate: Maybe(t.string),
      policyYear: Maybe(t.number),
      serviceAgents: t.array(
        t.type({
          agentCode: Maybe(t.string),
          clientNum: Maybe(t.string),
          agentSurName: Maybe(t.string),
          agentGivName: Maybe(t.string),
          agentName: Maybe(t.string),
          appointedDate: Maybe(t.string),
          terminateDate: Nullable(t.string),
          agentType: Nullable(t.string),
          title: Nullable(t.string),
          unitCode: Nullable(t.string),
          unitDescription: Nullable(t.string),
          branchCode: Nullable(t.string),
          branchDescription: Nullable(t.string),
          officeCode: Nullable(t.string),
          officeDescription: Nullable(t.string),
          regionCode: Maybe(t.string),
          regionDescription: Maybe(t.string),
          subzoneCode: Nullable(t.string),
          subzoneDescription: Nullable(t.string),
          zoneCode: Nullable(t.string),
          zoneDescription: Nullable(t.string),
          debt: Nullable(t.string),
          workTime: Maybe(t.string),
          salezone: Maybe(t.string),
          rateInforce: Maybe(t.string),
          qualifiedProduct: Nullable(t.string)
        })
      ),
      transferAgent: Maybe(
        t.type({
          agentCode: Maybe(t.string),
          clientNum: Maybe(t.string),
          agentSurName: Maybe(t.string),
          agentGivName: Maybe(t.string),
          agentName: Maybe(t.string),
          appointedDate: Maybe(t.string),
          terminateDate: Maybe(t.string),
          agentType: Nullable(t.string),
          title: Nullable(t.string),
          unitCode: Nullable(t.string),
          unitDescription: Nullable(t.string),
          branchCode: Nullable(t.string),
          branchDescription: Nullable(t.string),
          officeCode: Nullable(t.string),
          officeDescription: Nullable(t.string),
          regionCode: Maybe(t.string),
          regionDescription: Maybe(t.string),
          subzoneCode: Nullable(t.string),
          subzoneDescription: Nullable(t.string),
          zoneCode: Nullable(t.string),
          zoneDescription: Nullable(t.string),
          debt: Nullable(t.string),
          workTime: Maybe(t.number),
          salezone: Maybe(t.string),
          rateInforce: Maybe(t.number),
          qualifiedProduct: Nullable(t.string)
        })
      ),
      transferReason: Maybe(t.string)
    })
  )

  export const AgentDetail = Maybe(
    t.type({
      agent: TerminationDetail,
      newOffice: OfficeInfo,
      newUnitLeader: UnitInfo,
      newBranch: BranchInfo,
      transferPolicy: TransferDetail
    })
  )

  export type TerminationDetail = t.TypeOf<typeof TerminationDetail>
  export type AgentRequest = t.TypeOf<typeof AgentRequest>
  export type OfficeInfo = t.TypeOf<typeof OfficeInfo>
  export type UnitInfo = t.TypeOf<typeof UnitInfo>
  export type BranchInfo = t.TypeOf<typeof BranchInfo>
  export type AgentDetail = t.TypeOf<typeof AgentDetail>
  export type OtpInfo = t.TypeOf<typeof OtpInfo>
  export type TransferDetail = t.TypeOf<typeof TransferDetail>

  export const agentGetDetail = (agentNum: string) =>
    pipe(
      POApi.getConfig({ params: { agentNum } })('distribution-agents-service/agent/get-agent-info')(TerminationDetail),
      ZIO.map((res) => res)
    )

  export const getOfficeInfo = (officeCode: string) =>
    pipe(
      POApi.getConfig({ params: { officeCode } })('distribution-agents-service/table/get-office-info')(OfficeInfo),
      ZIO.map((res) => res)
    )

  export const getLeaderInfo = (unitCode: string) =>
    pipe(
      POApi.getConfig({ params: { unitCode } })('distribution-agents-service/table/get-unit-desc')(UnitInfo),
      ZIO.map((res) => res)
    )

  export const getBranchInfo = (branchCode: string) =>
    pipe(
      POApi.getConfig({ params: { branchCode } })('distribution-agents-service/table/get-branch-desc')(BranchInfo),
      ZIO.map((res) => res)
    )

  export const addAgentSubmission = (
    agentBody: SubmissionAgentService.AgentDetail,
    transactionType: string,
    documents: StorageBlob.FileContentSubmit[],
    otpTransaction: SubmissionAgentService.OtpInfo,
    isCCE: boolean = false,
    primaryPolicyNo?: string
  ) => {
    return pipe(
      AuthService.userInfo,
      ZIO.flatMap((currentUser) =>
        POApi.post(`distribution-agents-service/distribution-service/registerFromPulseOp`)(AgentRequest)({
          authenOption: 'OTP',
          isCCE: isCCE,
          source: 'PULSE4OPS',
          body: {
            transactionType: transactionType,
            optionFlag: true,
            agent: agentBody?.agent,
            newOffice: agentBody?.newOffice,
            newBranch: agentBody?.newBranch,
            newUnitLeader: agentBody?.newUnitLeader,
            transferPolicy: agentBody?.transferPolicy 
          },
          otpTransaction:otpTransaction,
          primaryPolicyNo: primaryPolicyNo ?? '',
          documents: documents,
          createdBy: currentUser.email,
          createdDate: new Date().toISOString()
        })
      ),
      ZIO.map((res) => res)
    )
  }
}
