import * as React from 'react'
import { Table, TableProps } from '@pulseops/common'
import { StyleSheet } from 'react-native'

export const HeaderTable = ({
  columns,
  dataSource,
  containerStyle,
  headerContainerStyle,
  rowStyle,
  headerTitleStyle
}: TableProps) => (
  <Table
    containerStyle={[styles.tableContainer, containerStyle]}
    headerContainerStyle={[styles.headerContainer, headerContainerStyle]}
    rowStyle={[styles.rowContainer, rowStyle]}
    headerTitleStyle={[styles.headerTitle, headerTitleStyle]}
    columns={columns}
    dataSource={dataSource}
  />
)

const styles = StyleSheet.create({
  tableContainer: {
    marginTop: 16,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: '#D3DCE6'
  },

  headerContainer: {
    backgroundColor: '#e2e7ea',
    padding: 24
  },

  rowContainer: {
    paddingHorizontal: 24,
    borderTopWidth: 1,
    borderColor: '#D3DCE6'
  },

  headerTitle: {
    color: '#000',
    fontSize: 15,
    fontWeight: '700'
  },

  tableValue: {
    color: '#212529',
    fontWeight: '400'
  }
})
