import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { ClientScreenContext } from '../../common'
import { assets, IBPolicyPermission, SelectOption } from '@pulseops/common'
import { IBPopupSendZNS } from './IBPopupSendZNS'
import { useForm } from 'react-hook-form'
import { IBPopupSendZNSForm } from './IBPopupSendZNSForm'
import { GuideDocumentInfo, IBService } from '../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

export const IBSendZNS = ({
  setFlagModal,
  flagOpenModal
}: {
  setFlagModal: (open: boolean) => void
  flagOpenModal: boolean | undefined
}) => {
  const { permissions, masterClientName } = React.useContext(ClientScreenContext)
  const [open, setOpen] = React.useState<boolean>(false)
  const [policyList, setPolicyList] = React.useState<SelectOption[]>([])
  const [guidelineZNSArr, setGuidelineZNSArr] = React.useState<GuideDocumentInfo[]>([])
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const clientNumber = urlParams.get('clientNumber')

  React.useEffect(() => {
    pipe(
      IBService.getPolicyList(clientNumber || ''),
      ZIO.map((data) => {
        setPolicyList(
          data.map((item) => ({
            value: item.policyNumber,
            label: item.policyNumber
          }))
        )
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }, [])

  const form = useForm<IBPopupSendZNSForm>({
    mode: 'onChange',
    defaultValues: {
      policyOwner: masterClientName || ''
    }
  })

  React.useEffect(() => {
    setFlagModal(open)
  }, [open])

  React.useEffect(() => {
    if (!flagOpenModal) {
      setOpen(false)
    }
  }, [flagOpenModal])

  if (policyList.length === 0 || !permissions.includes(IBPolicyPermission.IBViewZNSPopup)) return <></>
  return (
    <>
      {!permissions.includes(IBPolicyPermission.IBViewEmailPopup) ? (
        <></>
      ) : (
        <View>
          <View style={[styles.fabModal, { display: !open ? 'none' : 'flex' }]}>
            <IBPopupSendZNS
              showPopup={open}
              setShowPopup={setOpen}
              form={form}
              policyList={policyList}
              setGuidelineZNSArr={setGuidelineZNSArr}
              guidelineZNSArr={guidelineZNSArr}
            />
          </View>
          <Pressable
            style={[
              styles.CircleFab,
              {
                backgroundColor: open ? '#FFFFFF' : '#ED1B2E',
                opacity: flagOpenModal ? 0 : 1,
                alignItems: 'center'
              }
            ]}
            disabled={flagOpenModal}
            onPress={() => {
              setOpen(!open)
            }}
          >
            {open ? (
              <assets.IBMultiTasksXIcon />
            ) : (
              <Text style={{ color: 'white', fontSize: 15, fontWeight: '700' }}>ZNS</Text>
            )}
          </Pressable>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: 0
  },
  fabModal: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: -270
  },
  CircleFab: {
    borderColor: '#ED1B2E',
    paddingVertical: 17,
    paddingHorizontal: 10,
    borderRadius: 40,
    borderWidth: 1
  }
})
