export namespace UserMock {
  export const List: Array<{
    value: string
    label: string
  }> = [
    {
      value: 'System',
      label: 'System'
    },
    {
      value: 'Automation Bot',
      label: 'Automation Bot'
    },
    {
      value: 'admin',
      label: 'Admin'
    },
    {
      value: 'Tnt.hieu@prudential.com.vn',
      label: 'Trần Nguyên Trung Hiếu'
    },
    {
      value: 'Ngo.thi.loc@prudential.com.vn',
      label: 'Ngô Thị Lộc'
    },
    {
      value: 'Ly.Hoang.Long@prudential.com.vn',
      label: 'Lý Hoàng Long'
    },
    {
      value: 'Truong.Minh.Tung@prudential.com.vn',
      label: 'Trương Minh Tùng'
    },
    {
      value: 'Nguyen.Thi.Kim.Ngan.IT@prudential.com.vn',
      label: 'Nguyễn Thị Kim Ngân'
    },
    {
      value: 'Truong.Thi.Kim.Oanh@prudential.com.vn',
      label: 'Trương Thị Kim Oanh'
    },
    {
      value: 'nguyen.h.huong@prudential.com.vn',
      label: 'Nguyễn Hoàng Hưởng'
    },
    {
      value: 'dtl.huong@prudential.com.vn',
      label: 'Đỗ Thị Lan Hương'
    },
    {
      value: 'Ho.Quang.Dung@prudential.com.vn',
      label: 'Hồ Quang Dũng'
    }
  ]

  export const getLabel = (str: string | null | undefined): string =>
    str ? List.find((x) => x.value === str)?.label || '-' : '-'
}
