import React from 'react'
import { ActivityIndicator, View } from 'react-native'

type OBPageLoading = {
  isShowLoading: boolean
}
export const OBPageLoading = (props: OBPageLoading) => {
  return props.isShowLoading ? (
    <>
      <View style={{ position: 'absolute', opacity: 0.8, backgroundColor: 'rgba(0, 0, 0, 0.13)', width: '100%', height: '100%', top: 0, left: 0, zIndex: 10000, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator color={'red'} size={25} />
      </View>
    </>
  ) : <></>
}