import { useTranslation } from 'react-i18next'
import { formatNumberWithComma, Input, Title, TopUp } from '@pulseops/common'
import { StyleSheet, View } from 'react-native'
import { Controller, UseFormReturn } from 'react-hook-form'
import { TopUpFormData } from '../top-up.form'
import React from 'react'

export interface TopUpULPProps {
  formULP: UseFormReturn<TopUpFormData.RequestULPForm>
  topUpDetail: TopUp.Detail
  isDisable: boolean
}

export const TopUpULPScreen: React.FC<TopUpULPProps> = ({ formULP, topUpDetail, isDisable }) => {
  const { t } = useTranslation()
  const { control, setError, watch, setValue } = formULP

  React.useMemo(() => {
    setValue('suspenseAmount', topUpDetail.suspendAmount)
  }, [topUpDetail])

  const onChangeTopUpAmountEvent = (amount: number) => {
    // console.log(`topUpDetail: ${JSON.stringify(topUpDetail)}`)
    if (topUpDetail.coverageCode === 'VLR1' && amount > topUpDetail.totalPremium) {
      setError('topUpAmount', {
        message: t('message:MS050141')
      })
    } else if (topUpDetail.coverageCode === 'VLS1' && amount > 0.5 * topUpDetail.ULPsuspendAmount) {
      setError('topUpAmount', {
        message: t('message:MS050141')
      })
    } else if (amount < topUpDetail.minTopUp) {
      setError('topUpAmount', {
        message: `${t('message:MS050266', {
          min: formatNumberWithComma(topUpDetail.minTopUp),
          max: formatNumberWithComma(topUpDetail.maxTopUp)
        })}`
      })
    } else {
      setError('topUpAmount', {
        message: ''
      })
    }
    const requirePayin = amount - watch('suspenseAmount') > 0 ? amount - watch('suspenseAmount') : 0
    setValue('requirePayinAmount', requirePayin)
  }

  return (
    <View>
      <Title title={t('requestInfo:TOP_UP_INFORMATION')}></Title>
      <View style={topUpULPStyles.sectionContent}>
        <View style={topUpULPStyles.sectionRow}>
          <View style={topUpULPStyles.col_4}>
            <Controller
              control={control}
              name="topUpAmount"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('requestInfo:TopUpAmount') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  onChange={(val) => {
                    const amountVal = val.replace(/[^0-9]/g, '')
                    onChange(amountVal)
                    onChangeTopUpAmountEvent(Number(amountVal))
                  }}
                  onBlur={onBlur}
                  value={formatNumberWithComma(value)}
                  title={t('requestInfo:TopUpAmount')}
                  alwayShowUnderline={true}
                  inputType={'number'}
                  required={true}
                  disabled={isDisable}
                  isAllowComma={true}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={topUpULPStyles.col_4}>
            <Controller
              control={control}
              name="suspenseAmount"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('requestInfo:SuspenseAmount') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={true}
                  value={formatNumberWithComma(value)}
                  alwayShowUnderline={true}
                  title={t('requestInfo:SuspenseAmount')}
                  inputType={'number'}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={topUpULPStyles.col_4}>
            <Controller
              control={control}
              name="requirePayinAmount"
              rules={{
                required: {
                  value: true,
                  message: `${t('message:MS020001', { field: t('requestInfo:RequirePayinAmount') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={true}
                  value={formatNumberWithComma(value)}
                  alwayShowUnderline={true}
                  title={t('requestInfo:RequirePayinAmount')}
                  inputType={'number'}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
      </View>
    </View>
  )
}
const topUpULPStyles = StyleSheet.create({
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  secondLine: {
    marginTop: 20
  }
})
