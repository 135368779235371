import { POApi } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { Task, ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'

export namespace UnitFundFundPriceService {
  export interface QueryData {
    start: number
    size: number
    data: {
      fundCode: string
      fundName: string
      fromDate: string | undefined
      toDate: string | undefined
    }
  }

  export const UnitLinkItemC = t.type({
    fundCode: Maybe(t.string),
    fundName: Maybe(t.string),
    unitType: Maybe(t.string),
    effDate: Maybe(t.string),
    barePrice: Maybe(t.number),
    bidPrice: Maybe(t.number),
    unitPrice: Maybe(t.number),
    lastUpdatedTimestamp: Maybe(t.string)
  })

  export type UnitLinkItemC = t.TypeOf<typeof UnitLinkItemC>

  export const Error = Maybe(
    t.type({
      code: Maybe(t.number),
      message: Maybe(t.string),
      errors: Maybe(
        t.array(
          t.type({
            domain: Maybe(t.string),
            reason: Maybe(t.string),
            message: Maybe(t.string),
            errorCode: Maybe(t.string)
          })
        )
      )
    })
  )

  export const UnitLinkListDataC = t.type({
    total: Maybe(t.number),
    start: Maybe(t.number),
    size: Maybe(t.number),
    data: Maybe(t.array(UnitLinkItemC)),
    error: Error
  })

  export type UnitLinkListDataC = t.TypeOf<typeof UnitLinkListDataC>

  export const getDataUnitLinkFundPrice = (query: QueryData): Task<UnitLinkListDataC> =>
    pipe(
      POApi.post(`cep-rest/fund/unit-link-fund-price`)(UnitLinkListDataC)(query),
      ZIO.map((res) => res)
    )
}
