import {
  AppContext,
  assets,
  PanelGroupProps,
  PanelItem,
  PanelProp,
  SearchPanel,
  Permission,
  RBAC,
  ErrorHandling
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ClaimStackParamList } from './ClaimStackParamList'
import { ClaimInfoHeader } from './common/claim-header'
import { pipe } from 'fp-ts/lib/function'

type Props = StackScreenProps<ClaimStackParamList, 'ClaimListScreen'>

export const ClaimListScreen = (props: Props) => {
  const { t } = useTranslation()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const navigationParams = {
    policyNum: props.route.params.policyNum,
    policyOwner: props.route.params.policyOwner,
    officeCode: props.route.params.officeCode,
    officeName: props.route.params.officeName
  }
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigation.navigate('HomeScreen')
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:ClaimServices'),
          navigate: () => null
        }
      ])
    }
  }, [isFocused])

  const itemsGroup: PanelGroupProps[] = [
    {
      id: 'ClaimService',
      name: t('claim:group:ClaimService'),
      icon: () => <assets.ClaimService />,
      children: roles.includes(Permission.ViewSubmissionFormPayoutCL)
        ? [
            {
              id: 'NewClaimRegister',
              name: t('claim:NewClaimRegister'),
              navigator: () => props.navigation.navigate('ClaimScreen', navigationParams)
            },
            {
              id: 'PayoutScreen',
              name: t('claim:Payout'),
              navigator: () => props.navigation.navigate('PayoutScreen', navigationParams)
            }
          ]
        : [
            {
              id: 'NewClaimRegister',
              name: t('claim:NewClaimRegister'),
              navigator: () => props.navigation.navigate('ClaimScreen', navigationParams)
            }
          ]
    },
    {
      id: 'ClaimSupplementary',
      name: t('claim:group:ClaimSupplementary'),
      icon: () => <assets.ClaimSupplementary />,
      children: [
        {
          id: 'AddLackOfPayeeInfo',
          name: t('claim:AddLackOfPayeeInfo'),
          navigator: () => props.navigation.navigate('AddLackOfPayeeScreen', navigationParams)
        },
        {
          id: 'AddLackOfDocuments',
          name: t('claim:AddLackOfDocuments'),
          navigator: () => props.navigation.navigate('AddLackOfDocumentsScreen', navigationParams)
        }
      ]
    }
  ]

  const data: PanelProp | null = {
    itemsGroup: itemsGroup,
    itemsSearch: ([] as PanelItem[]).concat(...itemsGroup.map((group) => group.children))
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <ClaimInfoHeader
          menus={{
            policyNum: props.route.params.policyNum,
            policyOwner: props.route.params.policyOwner,
            officeName: props.route.params.officeName
          }}
        />
        {data && <SearchPanel data={data} />}
      </ScrollView>
    </SafeAreaView>
  )
}
