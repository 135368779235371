import * as t from 'io-ts'
import { form2 } from '@pulseops/common'

export namespace DocumentForm {
  export const codec = t.type({
    documentList: t.array(
      t.type({
        priority: form2.number.optional,
        docID: form2.string.optional,
        className: form2.string.optional,
        maindoc: form2.string.optional,
        subdoc: form2.string.optional,
        required: form2.string.optional,
        documentsEn: form2.string.optional,
        documentsVn: form2.string.optional,
        note: form2.string.optional,
        xcondition: form2.string.optional,
        suspendCode: form2.string.optional,
        lastedDate: form2.string.optional,
        status: form2.selectOption.optional,
        isClickSuspense: t.boolean,
        isSupend: t.boolean,
      })
    )
  })

  export type Raw = t.OutputOf<typeof codec>
  export type Validated = t.TypeOf<typeof codec>
}
