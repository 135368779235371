/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable prefer-const */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-case-declarations */
/* eslint-disable no-fallthrough */
import React from 'react'
import { View } from 'react-native'
import {
  Tab,
  AppContext,
  TaskDetail,
  Toast,
  TabList,
  TransactionType,
  StorageBlobApi,
  AuthService,
  downloadURI,
  ErrorHandling
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { Color } from '@material-ui/lab'
import { DAPolicyInformationScreen } from './da-policy-information'
import { DANewAgentInformationScreen } from './da-newagent-information'
import { DAAgentInformationScreen } from './da-agent-information'
import { DARecallItemsScreen } from './da-recall-items'
import { DANewOfficeScreen } from './da-new-office'
import { DAUnitLeaderInformationScreen } from './da-unit-leader-information'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'

type Props = {
  detail: TaskDetail.AgentVerificationNew
}

export function DsAgentVerificationDetail(props: Props) {
  const { t } = useTranslation()
  const [subTabIndex, setSubTabIndex] = React.useState<number>(0)
  const { getFooter, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [openPopup, setOpen] = React.useState<boolean>(false)
  const [image, setImage] = React.useState<string[]>([])
  const [loading, bindLoader] = useLoading(false)
  const imgExtensions = ['.png', '.jpg', '.jpeg']
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }
  React.useEffect(() => {
    if (props.detail.transactionType === TransactionType.AGENT_TERMINATION_AG) {
      return setSubTabIndex(0)
    } else if (props.detail.transactionType === TransactionType.AGENT_UNIT_TRANSFER) {
      return setSubTabIndex(4)
    } else if (props.detail.transactionType === TransactionType.AGENT_OFFICE_MOVING) {
      return setSubTabIndex(6)
    } else if (props.detail.transactionType === TransactionType.AGENT_TRANSFER_POLICY) {
      return setSubTabIndex(2)
    }
  }, [props.detail.transactionType])
  const requestTabsTermination = React.useMemo(() => {
    return [
      {
        id: 0,
        title: t('RequestInfo:AgentInformation'),
        permission: ''
      },
      {
        id: 1,
        title: t('RequestInfo:RecalledItems'),
        permission: ''
      }
    ]
  }, [])
  const requestTabsTransfer = React.useMemo(() => {
    return [
      {
        id: 2,
        title: t('RequestInfo:PolicyInformation'),
        permission: ''
      },
      {
        id: 3,
        title: t('RequestInfo:NewAgentInformation'),
        permission: ''
      }
    ]
  }, [])
  const requestTabsMovementUnit = React.useMemo(() => {
    return [
      {
        id: 4,
        title: t('RequestInfo:AgentInformation'),
        permission: ''
      },
      {
        id: 5,
        title: t('RequestInfo:NewUnitLeader'),
        permission: ''
      }
    ]
  }, [])
  const requestTabsMovementOffice = React.useMemo(() => {
    return [
      {
        id: 6,
        title: t('RequestInfo:AgentInformation'),
        permission: ''
      },
      {
        id: 7,
        title: t('RequestInfo:SubTab'),
        permission: ''
      }
    ]
  }, [])
  const getListTabs = (): Tab[] => {
    if (props.detail.transactionType === TransactionType.AGENT_TERMINATION_AG) {
      return requestTabsTermination
    } else if (props.detail.transactionType === TransactionType.AGENT_TRANSFER_POLICY) {
      return requestTabsTransfer
    } else if (props.detail.transactionType === TransactionType.AGENT_UNIT_TRANSFER) {
      return requestTabsMovementUnit
    } else if (props.detail.transactionType === TransactionType.AGENT_OFFICE_MOVING) {
      return requestTabsMovementOffice
    }
    return []
  }
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])
  const tabs = getListTabs()

  const viewPendingList = (index: number) => {
    const doccument = props.detail.documents?.find((item, idx) => index === idx)
    if (!!doccument) {
      pipe(
        ZIO.zipPar(
          StorageBlobApi.getRemoteConfig(props?.detail?.category ?? '', props?.detail?.agentCode ?? ''),
          AuthService.token,
          AuthService.getLoginType
        ),
        ZIO.flatMap(([storageConfig, token, loginType]) => {
          return pipe(
            ZIO.succeed(doccument),
            ZIO.flatMap((pendingItem) => {
              const nameUrl = pendingItem.name?.split('/').splice(0, 1).join('/')
              const nameUrlImage = pendingItem.name?.split('/').splice(-1, 1).join('/')
              const urlImage =
                pendingItem?.url?.split('/').splice(0, 5).join('/') +
                '?container=pulseforops' +
                '&blob=' +
                nameUrl +
                '/' +
                nameUrlImage
              return pipe(
                ZIO.fromPromise(() => {
                  const apiUrl = urlImage?.split('?')
                  const storageUrl = apiUrl[0]
                  const paramInfo = apiUrl[1]
                  const paramList = paramInfo.split('&')
                  const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
                  const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
                  return fetch(storageUrl, {
                    method: 'GET',
                    headers: {
                      'Cache-Control': 'no-cache',
                      Pragma: 'no-cache',
                      Container: containerName,
                      Token: storageConfig.sas,
                      Blob: blobName,
                      Authorization: `Bearer ${token}`,
                      'X-Authen-Vendor': loginType
                    }
                  })
                }),
                ZIO.flatMap((res) =>
                  ZIO.zipPar(
                    ZIO.succeed(res),
                    ZIO.fromPromise(() => res.blob())
                  )
                ),
                ZIO.map(([response, blob]) => ({
                  response,
                  blob,
                  pendingItem
                }))
              )
            }),
            ZIO.map((responseData) => {
              const isImageFile = imgExtensions.includes(
                (
                  responseData?.pendingItem?.name?.slice(responseData?.pendingItem?.name?.lastIndexOf('.')) ?? ''
                ).toLocaleLowerCase()
              )
              if (isImageFile) {
                const imageFile = window.URL.createObjectURL(responseData.blob)
                setImage([imageFile])
                setOpen(true)
              } else {
                const type = responseData.response.headers.get('content-type') || ''
                downloadURI(window.URL.createObjectURL(responseData.blob), `file.${type.split('/')[1]}`)
              }
            })
          )
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }
  return (
    <View style={{ flex: 1 }}>
      <TabList
        gutterBottom
        isSubTab={true}
        useOriginalId={true}
        menus={tabs}
        tabIndex={subTabIndex}
        onChangeTab={(index) => {
          getFooter(null)
          setSubTabIndex(index)
        }}
      />
      <View>
        {subTabIndex === 0 && hasTab(0, requestTabsTermination) && <DAAgentInformationScreen detail={props.detail} />}
        {subTabIndex === 1 && hasTab(1, requestTabsTermination) && (
          <DARecallItemsScreen
            detail={props.detail}
            imageList={image}
            openPopup={openPopup}
            viewPendingList={viewPendingList}
            setOpen={setOpen}
          />
        )}
        {subTabIndex === 2 && hasTab(2, requestTabsTransfer) && <DAPolicyInformationScreen detail={props.detail} />}
        {subTabIndex === 3 && hasTab(3, requestTabsTransfer) && (
          <DANewAgentInformationScreen
            detail={props.detail}
            imageList={image}
            openPopup={openPopup}
            viewPendingList={viewPendingList}
            setOpen={setOpen}
          />
        )}
        {subTabIndex === 4 && hasTab(4, requestTabsMovementUnit) && <DAAgentInformationScreen detail={props.detail} />}
        {subTabIndex === 5 && hasTab(5, requestTabsMovementUnit) && (
          <DAUnitLeaderInformationScreen
            detail={props.detail}
            imageList={image}
            openPopup={openPopup}
            viewPendingList={viewPendingList}
            setOpen={setOpen}
          />
        )}
        {subTabIndex === 6 && hasTab(6, requestTabsMovementOffice) && (
          <DAAgentInformationScreen detail={props.detail} />
        )}
        {subTabIndex === 7 && hasTab(7, requestTabsMovementOffice) && (
          <DANewOfficeScreen
            detail={props.detail}
            imageList={image}
            openPopup={openPopup}
            viewPendingList={viewPendingList}
            setOpen={setOpen}
          />
        )}
      </View>
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </View>
  )
}
