import * as React from 'react'
import {
  FieldList,
  Panel,
  Title,
  ErrorHandling,
  form2,
  TransactionType,
  PayoutPopup,
  LoanModel,
  LoanService,
  formatNumberWithComma,
  GeneralService,
  TaskType
} from '@pulseops/common'
import { Column } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { useLoading } from '@mxt/zio-react'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../payout-method'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { LoanPaymentForm } from './loan-payment.form'
import { Controller } from 'react-hook-form'
import { RequestAuthenticateData } from '../../request-authen'
import { useTranslation } from 'react-i18next'

interface Props extends PolicyServiceProps<LoanModel.SubmitData> {
  policyNumber: string
}

export const LoanPayment = ({ policyNumber, initSubmission, isConfirmed, officeCode }: Props) => {
  const { t } = useTranslation()

  const [isLoadingBenefit, bindLoadingBenefit] = useLoading(false)
  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const benefit = pipe(
    LoanService.getDetail(policyNumber),
    bindLoadingBenefit,
    ErrorHandling.runDidUpdate([policyNumber])
  )

  const loanForm = form2.useForm(LoanPaymentForm.codec, {
    defaultValues: {
      payout: []
    }
  })

  const { control, watch, setValue, trigger } = loanForm.base

  const { payout } = watch()

  const totalAmount = React.useMemo(() => payout.reduce((sum, item) => sum + item.amount, 0), [payout])

  const getUploadedFilesInfo = (formData: LoanPaymentForm.Raw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const uploadedItem: UploadedFilesInfo = {
      uploadFiles: GeneralService.getPayoutDocuments(formData.payout),
      transactionType: TransactionType.LOAN,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    }
    uploadedFileList.push(uploadedItem)
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      // let uploadedFiles: StorageBlob.FileContentSubmit[] = []
      if (benefit && benefit.loanAmountAvailable && totalAmount > benefit.loanAmountAvailable) {
        return false
      }

      const isFormValid = await trigger()
      if (!isFormValid) {
        return false
      }

      const value = loanForm.base.getValues()
      return {
        url: (policyNumber: string) => `wf-api/policy/${policyNumber}/loan`,
        body: {
          attributesExt: {
            TOTAL_LOAN: totalAmount
          },
          cashOutOptions: mapCashOutOption(value.payout)
        },
        collerationId: policyNumber,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.LOAN),
        transaction: TransactionType.LOAN,
        // documents: uploadedFiles,
        uploadedFilesInfo: getUploadedFilesInfo(value)
      }
    },
    clear: () => {
      setValue('payout', [])
      payoutRef.current?.clearData()
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('submission:LOAN_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }} isLoading={isLoadingBenefit}>
        <FieldList
          dataSource={[
            {
              label: t('submission:SurrenderValue'),
              value: formatNumberWithComma(benefit?.contractSurrenderValue || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:Principal_APL_OPL'),
              value: formatNumberWithComma(benefit?.principalAplOpl || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:Interest_APL_OPL'),
              value: formatNumberWithComma(benefit?.interestAplOpl || ''),
              suffix: 'VND'
            },
            {
              label: t('submission:LoanAmountAvailable'),
              value: formatNumberWithComma(benefit?.loanAmountAvailable || ''),
              suffix: 'VND',
              isHighlight: true
            }
          ]}
        />
      </Panel>

      {benefit && (
        <Controller
          control={control}
          name="payout"
          render={({ field, fieldState: { error } }) => (
            <PayoutMethod
              {...field}
              ref={payoutRef}
              transactionType={TransactionType.LOAN}
              editable={!isConfirmed}
              policyNum={policyNumber}
              maxAmount={benefit.loanAmountAvailable || 0}
              errorMessage={
                error?.message ||
                (benefit.loanAmountAvailable && totalAmount > benefit.loanAmountAvailable ? t('message:MS050237') : '')
              }
              methods={[
                PayoutPopup.PayoutMethods.PAYPREMIUM,
                PayoutPopup.PayoutMethods.PAYLOAN,
                PayoutPopup.PayoutMethods.CASHLESS,
                PayoutPopup.PayoutMethods.OTHER,
                PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
                PayoutPopup.PayoutMethods.PAID_AT_BANK,
                PayoutPopup.PayoutMethods.BANKTRANSFER,
                // PayoutPopup.PayoutMethods.MOMO
              ]}
              officeCode={officeCode}
            />
          )}
        />
      )}
    </Column>
  )
}
