import { ZIO } from '@mxt/zio'
import {
  DatePicker,
  ErrorHandling,
  Form,
  GeneralService,
  Input,
  Select,
  SelectOption,
  TaskDetail,
  SourceType
} from '@pulseops/common'
import { Bank, Branch } from '@pulseops/submission'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { ClaimInfoForm } from '../claim-type/ClaimInfoForm'
interface Props {
  props: {
    detail: TaskDetail.VerificationClaim
    form: UseFormReturn<ClaimInfoForm>
    bank: Bank[] | null
    bankBranchs: Branch[] | null
  }
}
export const BankTransfer: React.FC<Props> = ({ props }) => {
  const { form, bank, bankBranchs, detail } = props
  const { PAYMENT } = detail
  const register = Form.register(form)
  const { watch } = form
  const { bankTransferForm, isNotPolicyOwner } = watch()
  const { t, i18n } = useTranslation('claim')

  React.useEffect(() => {
    if (!isNotPolicyOwner) {
      form.setValue('bankTransferForm.accountName', bankTransferForm.accountName)
      form.setValue('bankTransferForm.idNumber', bankTransferForm.idNumber)
    }
  }, [isNotPolicyOwner])
  const relationships =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) => {
        const relaList: SelectOption[] =
          r.map((item) => ({
            value: item.code,
            // label: `${item.code} - ${i18n.language === 'vi' ? item.nameVn : item.nameEn}`
            label: `${item.code} - ${i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN}`
          })) || []
        const rela = relaList.find((cond) => cond.value === PAYMENT?.relationshipWithPO) || null
        form.setValue('bankTransferForm.relationshipWithPO', rela)
        return relaList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const countries =
    pipe(
      GeneralService.getCountries,
      ZIO.map((c) => {
        const countryList: SelectOption[] =
          c.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        const nationality = countryList.find((cond) => cond.value === PAYMENT?.nationality) || null
        const nationality2 = countryList.find((cond) => cond.value === PAYMENT?.nationality2) || null
        const residenceCountry = countryList.find((cond) => cond.value === PAYMENT?.residenceCountry) || null
        form.setValue('bankTransferForm.nationality', nationality)
        form.setValue('bankTransferForm.nationality2', nationality2)
        form.setValue('bankTransferForm.residenceCountry', residenceCountry)
        return countryList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  const occupations =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((oc) => {
        const ocupationList: SelectOption[] =
          oc.map((item) => ({
            value: item.code,
            label: item.name
          })) || []
        const occupation = ocupationList.find((cond) => cond.value === PAYMENT?.occupation) || null

        form.setValue('bankTransferForm.occupation', occupation)
        return ocupationList
      }),
      ErrorHandling.runDidUpdate([i18n.language, detail])
    ) || []

  React.useEffect(() => {
    const genderGen = (value: string) =>
      value === 'F' ? `${t('common:Female')}` : value === 'M' ? `${t('common:Male')}` : value

    const genderObj = PAYMENT?.gender
      ? {
          value: PAYMENT?.gender || '-',
          label: PAYMENT?.gender ? genderGen(PAYMENT.gender) : '-'
        }
      : null
    form.setValue('bankTransferForm.gender', genderObj)
  }, [i18n.language, detail])

  return (
    <SafeAreaView>
      <View style={styles.row}>
        <View style={styles.col}>
          <Input {...register('bankTransferForm.accountName')} disabled title={t('AccountName')} />
        </View>
        <View style={styles.col}>
          <Input {...register('bankTransferForm.idNumber')} disabled title={t('IDNumber')} />
        </View>
      </View>

      <View style={styles.row}>
        {detail?.detail?.source === SourceType.PULSE && (
          <>
            <View style={[styles.col]}>
              <Input {...register('bankTransferForm.bank.label')} disabled title={t('Bank')} />
            </View>
            {PAYMENT.bankTransfer?.branch && (
              <View style={[styles.col]}>
                <Input {...register('bankTransferForm.branch.label')} disabled title={t('Branch')} />
              </View>
            )}
          </>
        )}
        {detail?.detail?.source !== SourceType.PULSE && (
          <>
            <View style={[styles.col]}>
              <Select
                {...register('bankTransferForm.bank')}
                label={t('Bank')}
                options={bank?.map((v) => ({ value: v.code, label: v.name })) || []}
                disabled
                showUnderline={false}
              />
            </View>
            {PAYMENT.bankTransfer?.branch && (
              <View style={[styles.col]}>
                <Select
                  {...register('bankTransferForm.branch')}
                  label={t('Branch')}
                  options={bankBranchs?.map((v) => ({ value: v.code, label: v.name })) || []}
                  disabled
                  showUnderline={false}
                />
              </View>
            )}
          </>
        )}
        <View style={styles.col}>
          <Input {...register('bankTransferForm.accountNumber')} disabled title={t('AccountNumber')} />
        </View>
        {!isNotPolicyOwner && detail?.detail?.source !== SourceType.PULSE && (
          <View style={[styles.col, { paddingEnd: 100 }]}>
            <Text style={styles.label}>{t('DateOfBirth')}</Text>
            <DatePicker {...register('bankTransferForm.dateOfBirth')} disabled label={''} />
          </View>
        )}
      </View>
      {isNotPolicyOwner && (
        <>
          <View style={styles.row}>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Select
                {...register('bankTransferForm.relationshipWithPO')}
                disabled
                options={relationships}
                label={t('RelationWithPolicyOwner')}
                showUnderline={false}
              />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('DateOfBirth')}</Text>
              <DatePicker {...register('bankTransferForm.dateOfBirth')} disabled label={''} />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Gender')}</Text>
              <Select
                {...register('bankTransferForm.gender')}
                disabled
                options={[
                  {
                    value: 'F',
                    label: t('common:Female')
                  },
                  {
                    value: 'M',
                    label: t('common:Male')
                  }
                ]}
                showUnderline={false}
              />
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col}>
              <Text style={styles.label}>{t('ResidenceCountry')}</Text>
              <Select
                {...register('bankTransferForm.residenceCountry')}
                disabled
                options={countries}
                showUnderline={false}
              />
            </View>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Occupation')}</Text>
              <Select
                {...register('bankTransferForm.occupation')}
                options={occupations}
                disabled
                showUnderline={false}
              />
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.col, { paddingEnd: 100 }]}>
              <Text style={styles.label}>{t('Nationality')}</Text>
              <Select
                {...register('bankTransferForm.nationality')}
                disabled
                options={countries}
                showUnderline={false}
              />
            </View>
            <View style={[styles.col]}>
              <Text style={styles.label}>{t('Address')}</Text>
              <Input {...register('bankTransferForm.address')} disabled title={''} multiline numberOfLines={4} />
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.col]}>
              <Text style={styles.label}>{t('Nationality2')}</Text>
              <Select
                {...register('bankTransferForm.nationality2')}
                disabled
                options={countries}
                showUnderline={false}
              />
            </View>
            <View style={styles.col}>
              <Text style={styles.label}>{t('ForeignAddress')}</Text>
              <Input {...register('bankTransferForm.foreignAddress')} disabled title={''} multiline numberOfLines={4} />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  colFull: {
    width: '100%',
    marginBottom: 32
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  }
})
