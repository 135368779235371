import { form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import * as t from 'io-ts'

export namespace ClaimForm {
  const BaseC = t.type({
    pvaNo: form2.string.optional,
    insNo: form2.string.optional,
    fromDate: form2.date.optional,
    toDate: form2.date.optional,
    applicationStatus: form2.string.optional,
    policyNo: form2.string.optional,
    noInsured: form2.string.optional,
    nameInsured: form2.string.optional
  })
  type Base = t.TypeOf<typeof BaseC>
  type Form = t.Branded<Base, { readonly Form: unique symbol }>

  export const codec = pipe(
    BaseC,
    form2.refine(
      (data): data is Form =>
        !!data.applicationStatus ||
        !!data.fromDate ||
        !!data.insNo ||
        !!data.nameInsured ||
        !!data.noInsured ||
        !!data.policyNo ||
        !!data.pvaNo ||
        !!data.toDate,
      () => i18next.t('business:MS000063'),
      'Form'
    )
  )

  const SearchingResultInfoC = t.type({
    pvaNo: t.string,
    insNo: t.string,
    applicationDate: t.string,
    payoutAmount: t.string,
    applicationStatus: t.string,
    ndbhId: t.string,
    ndbhName: t.string,
    applicationType: t.string,
    polNum: t.string
  })
  export type SearchingResultInfo = t.TypeOf<typeof SearchingResultInfoC>
}
