import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { ClientName } from './CepCommonModels'

export const AgentInfoC = t.type({
  data: t.type({
    policyAgent: Maybe(
      t.type({
        policyNumber: Maybe(t.string),
        servicingAgentCode: Maybe(t.string),
        servicingAgentName: Maybe(ClientName),
        servicingAgentMobilePhone: Maybe(t.string),
        sharingAgentCode: Maybe(t.string),
        sharingAgentName: Maybe(ClientName),
        sharingAgentMobilePhone: Maybe(t.string),
        office: Maybe(t.string),
        gaCode: Maybe(t.string),
        gaName: Maybe(t.string),
        BDM: Maybe(t.string),
        BDD: Maybe(t.string),
        unitManager: Maybe(t.string),
        unitManagerName: Maybe(t.string),
        unitManagerMobilePhone: Maybe(t.string),
        branchManager: Maybe(t.string),
        branchManagerName: Maybe(t.string),
        branchManagerMobilePhone: Maybe(t.string),
        officeName: Maybe(t.string),
        gad: Maybe(t.string)
      })
    ),
    servicingHistory: Nullable(
      t.array(
        Maybe(
          t.type({
            agentCode: Maybe(t.string),
            agentName: Maybe(ClientName),
            fromDate: Maybe(t.string),
            toDate: Maybe(t.string)
          })
        )
      )
    )
  })
})

export type AgentInfoC = t.TypeOf<typeof AgentInfoC>

export const AgentServicesHistory = t.type({
  traceId: Maybe(t.string),
  system: Maybe(t.string),
  timestamp: Maybe(t.string),
  total: t.number,
  start: t.number,
  size: t.number,
  data: t.array(
    Maybe(
      t.type({
        agentCode: Maybe(t.string),
        agentName: Maybe(ClientName),
        fromDate: Maybe(t.number),
        toDate: Maybe(t.number)
      })
    )
  ),
  error: Maybe(
    t.type({
      code: Maybe(t.number),
      message: Maybe(t.string),
      errors: Maybe(
        t.array(
          t.type({
            domain: Maybe(t.string),
            reason: Maybe(t.string),
            message: Maybe(t.string)
          })
        )
      )
    })
  )
})

export type AgentServicesHistory = t.TypeOf<typeof AgentServicesHistory>
