import { Task, ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { POApi } from '../POApi'
import { CashOutPrucashType, PrucashPayment, RequestAuthFormDataSubmit } from './model'
import { SubmissionService } from './SubmissionService'

export const PrucashBenefitC = t.type({
  liabilities: Maybe(
    t.array(
      t.type({
        currency: Maybe(t.string),
        loan: Maybe(
          t.type({
            outstandingAmount: Maybe(t.number)
          })
        ),
        policy: Maybe(
          t.type({
            policyNo: Maybe(t.string),
            status: Maybe(t.string),
            totalPremium: Maybe(t.number)
          })
        ),
        type: t.union([
          t.literal(CashOutPrucashType.LOAN),
          t.literal(CashOutPrucashType.PAYPREMIUM),
          t.literal(CashOutPrucashType.TOPUP)
        ])
      })
    )
  ),
  attributesExt: Maybe(
    t.type({
      LOAN_EXCEED_80_SURRENDER_VALUE: Maybe(t.number),
      NOT_PAID_PREMIUM: Maybe(t.number),
      TOTAL_CASH_BENEFIT: Maybe(t.number),
      NET_TOTAL_CASH_BENEFIT: Maybe(t.number),
      TOTAL_MATURITY_AMOUNT: Maybe(t.number)
    })
  ),
  totalCashBenefit: Maybe(t.number)
})

export type PrucashBenefit = t.TypeOf<typeof PrucashBenefitC>

export namespace PruCashPaymentService {
  export const getDetail = (policyNum: string): Task<PrucashPayment.Detail> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getPolicy(policyNum),
        SubmissionService.getPolicyHeader(policyNum),
        getBenefit(policyNum)
      ),
      ZIO.map(([policy, client, cashout]): PrucashPayment.Detail => {
        const totalCashBenefit = cashout.body?.attributesExt?.TOTAL_CASH_BENEFIT ?? 0 //A
        const notPaidPremium = cashout.body?.attributesExt?.NOT_PAID_PREMIUM ?? 0 // B
        const loanExceedSurrenderValue = cashout.body?.attributesExt?.LOAN_EXCEED_80_SURRENDER_VALUE ?? 0 // C, from cashout --> waiting NhatAnh

        const netTotalCashBenefit = totalCashBenefit - (notPaidPremium + loanExceedSurrenderValue) //totalCashBenefit  - (notPaidPremium + loanExceedSurrenderValue) // add condition
        const coverageCode = policy.body.basicCode ?? '' // missing --> waiting BE --> policy info
        return {
          policyNum,
          clientName: client.ownerName,
          clientId: client.clientId,
          totalCashBenefit,
          notPaidPremium,
          loanExceedSurrenderValue,
          netTotalCashBenefit,
          coverageCode
        }
      })
    )

  export const getBenefit = (
    policyNum: string
  ): Task<{
    body: PrucashBenefit
  }> =>
    POApi.get(`wf-api/policy/${policyNum}/cashout?type=prucash`)(
      t.type({
        body: PrucashBenefitC
      })
    )

  export const submit =
    (policyNum: string, data: PrucashPayment.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/prucash`,
        { body: data },
        policyNum,
        requestauth,
        []
      )
  // pipe(
  //   PulseOpsApi.postAuthError()(`wf-api/policy/${policyNum}/prucash`)(t.unknown)({
  //     body: data,
  //     otpTransaction: requestauth,
  //     source: SourceType.PULSE4OPS
  //   }),
  //   ZIO.asVoid
  // )

  export const getDataAccessCheck = (policyNum: string): Task<PrucashPayment.AccessData> =>
    pipe(
      ZIO.zipPar(SubmissionService.getPolicy(policyNum), getDetail(policyNum)),
      ZIO.map(
        ([policy, detail]): PrucashPayment.AccessData => ({
          coverageCode: policy.body.basicCode ?? '', // missing --> waiting BE --> hard code
          contractStatus: policy.body.status ?? '',
          totalCashBenefit: detail.totalCashBenefit
        })
      )
    )
}
