import { ZIO } from '@mxt/zio'
import { ErrorHandling, GeneralService, SelectOption, SelectSearch, useMobile, assets } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ClaimInfoForm } from '../ClaimInfoForm'
import { generalStyles } from './styles'
import { differenceWith, isEqual } from 'lodash'
interface Props {
  form: UseFormReturn<ClaimInfoForm>
  cb: (data: SelectOption) => void
  setIsCanChangeTab: (isCanChangeTab: boolean) => void
}

export const SubBenefit: React.FC<Props> = ({ form, cb, setIsCanChangeTab }) => {
  const { isMobile } = useMobile()
  const { t, i18n } = useTranslation('claim')
  const { control } = form
  const { append } = useFieldArray<ClaimInfoForm>({
    control,
    name: 'subBenefit'
  })
  const [data, setData] = React.useState<SelectOption | null>(null)
  const [benefits, setBenefits] = React.useState<SelectOption[]>([])

  pipe(
    GeneralService.getAllSubBenefit(),
    ZIO.map((subBenefitList) => {
      setBenefits(
        subBenefitList
          ?.filter((subBenefit) => subBenefit.benefit === 'BNF01')
          .map((item) => ({
            value: item.subBenefitCode,
            label: i18n.language === 'en' ? item.subBenefitEN : item.subBenefitVN
          })) || []
      )
      return ZIO.unit
    }),
    ErrorHandling.runDidUpdate([i18n.language])
  )

  const addSubBenefit = React.useCallback(() => {
    let index = benefits?.findIndex((item) => item.value === data?.value)
    if (index !== undefined && index >= 0 && benefits) {
      const benefit = {
        value: benefits[index].value,
        label: benefits[index].label
      } as never
      append(benefit)
      data && cb(data)
      setData(null)
    }
    setIsCanChangeTab(false)
  }, [data])

  const onChangeSubBenefit = (data: SelectOption | null) => {
    setData(data)
    setIsCanChangeTab(false)
  }

  const sub2 =
    form?.watch('subBenefit')?.map((item: { value: any; label: any }) => ({
      value: item.value,
      label: item.label
    })) || []

  return (
    <>
      <View style={{ flexDirection: isMobile ? 'column' : 'row', marginTop: 10, alignItems: 'center' }}>
        <View style={isMobile ? styles.mobileField : styles.tabletField}>
          <SelectSearch
            popupIcon={<assets.ArrowDownDropdownIcon />}
            onChange={(data) => onChangeSubBenefit(data)}
            label={`${t('SubBenefit')}`}
            placeholder={t('common:Select')}
            options={differenceWith(benefits, sub2, isEqual) || []}
            value={data}
          />
        </View>
        <View>
          <TouchableOpacity
            disabled={!data?.value}
            style={data?.value ? styles.button : styles.btnDisabled}
            onPress={() => addSubBenefit()}
          >
            <Text style={data?.value ? styles.textButton : styles.btnTextDisabled}>{t('AddNew')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  )
}
const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    mobileField: {
      width: '100%',
      paddingBottom: 30
    },
    tabletField: {
      width: '33%',
      paddingBottom: 30
    },
    textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
    button: {
      marginLeft: 20,
      marginEnd: 20,
      justifyContent: 'center',
      backgroundColor: '#FFF',
      borderRadius: 100,
      borderWidth: 1,
      borderColor: '#ED1B2E',
      minWidth: 100,
      height: 30,
      paddingHorizontal: 16
    },
    btnTextDisabled: { textAlign: 'center', color: '#B0B0B0', fontWeight: 'bold' },
    btnDisabled: {
      marginLeft: 20,
      marginEnd: 30,
      justifyContent: 'center',
      backgroundColor: '#FFF',
      borderRadius: 100,
      borderWidth: 1,
      borderColor: '#B0B0B0',
      minWidth: 100,
      height: 30,
      paddingHorizontal: 16
    }
  })
}
