import { SelectOption } from '@pulseops/common'

export enum Status {
  Input = 0,
  Uploading = 1,
  Success = 2,
  Error = 3
}

export interface FileUploadData {
  fileName: string
  fileExtension: string
  size: number
  base64: string
  uploadedDate: Date
  file: File
}

export type DocumentOption = {
  label: string
  value: string
  labelVn: string
} & {
  required: boolean
  docTypeCode: string
  files: FileUploadData[]
  originalValue?: string
}
