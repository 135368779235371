import {
  Checkbox,
  Form,
  Input,
  Select,
  useMobile,
  GeneralService,
  ErrorHandling,
  DatePicker,
  RadioButton,
  PulseOpsApi,
  TransactionType,
  AppContext,
  SelectOption,
  PaymentType,
  SourceType
} from '@pulseops/common'
import React from 'react'
import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { ClaimDataAccess, ClaimType, generalStyles } from '../common'
import { ClaimCI } from './critical-illness/ClaimCI'
import { Death } from './death/Death'
import { pipe } from 'fp-ts/function'
import { MedicalClaim } from './medical-claim/MedicalClaim'
import { RequestInfoForm } from './RequestInfoForm'
import { Disability } from './total-pernament-disability/Disability'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import _ from 'lodash'
import * as PaymentForm from '../payment-method/PaymentMethodForm'
import { CanView } from '@pulseops/task/common'

interface Props {
  form: UseFormReturn<RequestInfoForm>
  paymentForm: UseFormReturn<PaymentForm.PaymentMethodForm>
  claimDataAccess: ClaimDataAccess
  onNext: () => void
  onReset: () => void
  isValid: boolean
  transaction?: string
}
type PolicyRiderOrigin = {
  planName: string
  productCode: string
}
export const RequestInfoTab = (props: Props) => {
  const { t, i18n } = useTranslation('claim')
  const { isMobile } = useMobile()
  const { claimDataAccess, form, isValid, transaction, paymentForm } = props
  const { mobileCodes, laList, ownerAddress, ownerEmail, claimTypes, listClaimNo } = claimDataAccess
  const { watch, control } = form
  const {
    ownerName,
    ownerPhoneCode,
    ownerPhoneNumber,
    isNotPolicyOwner,
    claimType,
    isRelatedToAccident,
    subClaimType
  } = watch()
  const register = Form.register(form)
  const registerClaimantPhoneCode = register('claimantPhoneCode')
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const gender = [
    {
      value: 'M',
      label: t('common:Male')
    },
    {
      value: 'F',
      label: t('common:Female')
    }
  ]

  const decision = pipe(GeneralService.getDecision('s1'), ErrorHandling.runDidMount())

  const relationships: SelectOption[] =
    pipe(
      GeneralService.getRelationship(),
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          // label: i18n.language === 'vi' ? item.nameVn : item.nameEn
          label: i18n.language === 'vi' ? item.relationshipVN : item.relationshipEN
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const nationality: SelectOption[] =
    pipe(
      GeneralService.getCountries,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const occupation: SelectOption[] =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  const listClaim = pipe(GeneralService.getClaims, ErrorHandling.runDidMount())

  const radioGroups = [
    {
      code: 'Y',
      label: t('common:Yes')
    },
    {
      code: 'N',
      label: t('common:No')
    }
  ]

  React.useEffect(() => {
    if (!isNotPolicyOwner) {
      form.setValue('claimantName', ownerName)
      form.setValue('claimantPhoneCode', ownerPhoneCode)
      form.setValue('claimantPhoneNumber', ownerPhoneNumber)
      form.setValue('claimantAddress', ownerAddress)
      form.setValue('claimantEmail', ownerEmail)
      form.clearErrors(['claimantName', 'claimantPhoneNumber', 'claimantAddress', 'claimantEmail'])
    } else {
      form.setValue('claimantName', '')
      form.setValue('claimantPhoneNumber', '')
      form.setValue('claimantAddress', '')
      form.setValue('claimantEmail', '')
    }
    // trigger().then()
  }, [isNotPolicyOwner /* , claimantName */])

  const getClaimComp = () => {
    switch (claimType?.value) {
      case ClaimType.CRITICAL_ILLNESS:
        return <ClaimCI form={form} />
      case ClaimType.MEDICAL:
        return <MedicalClaim form={form} claimDataAccess={claimDataAccess as ClaimDataAccess} />
      case ClaimType.DEATH:
        return <Death form={form} />
      case ClaimType.DISABILITY:
        return <Disability form={form} />
      default:
        return <></>
    }
  }
  const watchPO = form.watch('isNotPolicyOwner')
  const errors = form.formState.errors

  const getRelatedToAccident = (claimType: string, attributes: any) => {
    const { CRITICAL_ILLNESS, DEATH, DISABILITY, PAYMENT, PATIENT_IN, PATIENT_OUT, PATIENT_DENTAL, PATIENT_MATERNITY } =
      attributes
    switch (claimType) {
      case ClaimType.CRITICAL_ILLNESS:
        return CRITICAL_ILLNESS?.isRelatedToAccident || false
      case ClaimType.DEATH:
        return DEATH?.isRelatedToAccident || false
      case ClaimType.DISABILITY:
        return DISABILITY?.isRelatedToAccident || false
      case ClaimType.MEDICAL:
        return (
          PATIENT_IN?.isRelatedToAccident ||
          PATIENT_OUT?.isRelatedToAccident ||
          PATIENT_DENTAL?.isRelatedToAccident ||
          PATIENT_MATERNITY?.isRelatedToAccident ||
          false
        )
      default:
        return false
    }
  }
  const onSearchClaimNo = (claimNoPayout: SelectOption) => {
    if (claimNoPayout) {
      showGlobalLoading(true)
      const id = claimNoPayout?.value
      pipe(
        PulseOpsApi.getHistoricDetail(id),
        ZIO.map((data) => {
          if (data.transactionType === TransactionType.NEW_CLAIM_REGISTER && data.source === SourceType.PULSE4OPS) {
            const payload = data.payload
            const subClaimType = claimTypes.find((item) =>
              item.subClaimType.includes(payload.body.subClaimType as string)
            )
            const attributes = payload.body.attributes
            const bankTransfer = attributes.PAYMENT.bankTransfer
            const requestInfoPayout = {
              claimType: {
                label:
                  localStorage.getItem('locale') === 'en-US'
                    ? listClaim?.find((item) => item?.claimCode === payload.body.claimType)?.claimType || ''
                    : listClaim?.find((item) => item?.claimCode === payload.body.claimType)?.claimTypeVN || '',
                value: payload.body.claimType
              },
              subClaimType: {
                label: `${payload.body.subClaimType} - ${subClaimType?.claimType}`,
                value: payload.body.subClaimType || ''
              },
              isRelatedToAccident: getRelatedToAccident(payload.body.claimType, attributes) ? 'Y' : 'N',
              claimEventDate: attributes?.PATIENT_IN
                ? null
                : moment(
                    attributes.CRITICAL_ILLNESS?.claimEventDate ||
                      attributes.DEATH?.claimEventDate ||
                      attributes?.DISABILITY?.claimEventDate ||
                      attributes?.PATIENT_DENTAL?.admissionDate ||
                      attributes?.PATIENT_MATERNITY?.admissionDate ||
                      attributes?.PATIENT_OUT?.admissionDate ||
                      attributes?.PATIENT_IN?.admissionDate,
                    'DD/MM/YYYY'
                  ).toDate(),
              admissionDate: moment(attributes?.PATIENT_IN?.admissionDate, 'DD/MM/YYYY').toDate(),
              dischargeDate: moment(attributes?.PATIENT_IN?.dischargeDate, 'DD/MM/YYYY').toDate(),
              payableAmount: payload.adjudication?.calculation?.summaryDetail.totalNetPayableAmount || '',
              businessKey: data.businessKey,
              claimDecision: payload.adjudication?.claimDecision || '',
              effectiveDate: moment(payload.policyRider?.effectiveDate).toDate(),
              policyStatus: claimDataAccess.status || '',
              dob: moment(attributes.PAYMENT.dob, 'DD/MM/YYYY').format('YYYY-MM-DD') || '',
              claimNoPayout,
              productCode: (payload.policyRider?.components as Array<PolicyRiderOrigin>)[0].productCode,
              planName: (payload.policyRider?.components as Array<PolicyRiderOrigin>)[0].planName,
              surgeryBenefit: (attributes?.PATIENT_IN?.surgeryBenefit as boolean) ?? false
            }

            const paymentInfoPayout = {
              payeeIsPolicyOwner: !attributes.PAYMENT.isPayeeNotPO || false,
              payeeIsNotPolicyOwner: attributes.PAYMENT.isPayeeNotPO || false,
              changePayeeInfo: false,
              bank: { value: bankTransfer?.bank || '', label: bankTransfer?.bankName || '' },
              bankBranch: {
                value: bankTransfer?.branch || '',
                label: bankTransfer?.branchName || ''
              },
              bankAccountNumber: bankTransfer?.accountNumber.toString() || '',
              paymentAmount: payload.adjudication?.calculation?.summaryDetail.totalNetPayableAmount || '',
              dob: moment(attributes.PAYMENT.dob, 'DD/MM/YYYY').toDate() || '',
              bankAccountName: bankTransfer?.accountName,
              address: attributes.PAYMENT?.address || '-',
              foreignAddress: attributes.PAYMENT.foreignAddress || '-',
              gender: gender.find((cond) => cond.value === attributes.PAYMENT?.gender) || null,
              nationality: nationality.find((res) => res.value === attributes.PAYMENT.nationality) || null,
              nationality2: nationality.find((res) => res.value === attributes.PAYMENT.nationality2) || null,
              occupation: occupation.find((res) => res.value === attributes.PAYMENT?.occupation) || null,
              relationshipWithPO:
                relationships.find((res) => res.value === attributes.PAYMENT.relationshipWithPO) || null,
              residenceCountry: nationality.find((res) => res.value === attributes.PAYMENT.residenceCountry) || null,
              bankIdNumber: bankTransfer?.idNumber || '-'
            }
            paymentForm.reset(paymentInfoPayout)
            form.reset(requestInfoPayout)
            showGlobalLoading(false)
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }
  return (
    <View style={styles.tabContainer}>
      <Text style={styles.sectionTitle}>{t('Tab:GeneralInfo')}</Text>
      <View style={styles.sectionContainer}>
        <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input {...register('ownerName')} disabled={true} title={t('PolicyOwner')} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input {...register('ownerId')} disabled={true} title={t('PolicyOwnerID')} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input {...register('ownerContractName')} disabled={true} title={t('common:ContractName')} />
          </View>
        </View>
        <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Input {...register('ownerEmail')} disabled={true} title={t('PolicyOwnerEmail')} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <Select {...register('lifeAssured')} options={laList} label={t('SelectLifeAssured')} />
          </View>
          <View style={isMobile ? styles.mobileField : styles.tabletField}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', color: '#70777E', fontSize: 15 }}>{t('PolicyOwnerPhone')}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ marginTop: 7 }}>
                <Text style={styles.textContent}>{ownerPhoneNumber}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {transaction === 'PAYOUT' ? (
        <>
          {/* CLAIM INFO */}
          <Text style={styles.sectionTitle}>{t('ClaimInfo')}</Text>
          <View style={styles.sectionContainer}>
            <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Select
                  {...register('claimNoPayout', {
                    required: `${t('message:MS020001', { field: t('ClaimNumber') })}`
                  })}
                  options={listClaimNo}
                  label={t('ClaimNumber')}
                  required
                  onChange={(val) => {
                    onSearchClaimNo(val as SelectOption)
                  }}
                  errorMessage={(errors.claimNoPayout as FieldError | undefined)?.message || ''}
                />
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Input {...register('refCaseId')} title={t('ReferenceCaseId')} />
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Select
                  {...register('claimType')}
                  options={claimTypes.map((c) => ({
                    value: c.claimTypeCode,
                    label:
                      localStorage.getItem('locale') === 'en-US'
                        ? c.claimType
                        : listClaim?.find((item) => item?.claimCode === c?.claimTypeCode)?.claimTypeVN || ''
                  }))}
                  label={t('ClaimType')}
                  disabled
                  showUnderline={false}
                  placeholder={'-'}
                />
              </View>
            </View>
            <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Select
                  {...register('subClaimType')}
                  options={[{ value: subClaimType?.value || '', label: subClaimType?.label || '' }] || []}
                  label={t('SubClaimType')}
                  disabled
                  showUnderline={false}
                  placeholder={'-'}
                />
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Text style={{ fontWeight: 'bold', color: '#70777E', paddingLeft: 4, paddingBottom: 3, fontSize: 15 }}>
                  {t('RelatedToAccident')}
                </Text>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                  {radioGroups.map((r, i) => (
                    <RadioButton
                      {...register('isRelatedToAccident')}
                      key={i}
                      label={r.label}
                      value={r.code}
                      selected={isRelatedToAccident === r.code}
                      disable
                    />
                  ))}
                </View>
              </View>
              <CanView condition={claimType?.value !== ClaimType.MEDICAL}>
                <View style={isMobile ? styles.mobileField : styles.tabletField}>
                  <DatePicker
                    {...register('claimEventDate')}
                    alwaysShow
                    label={t('ClaimEventDate')}
                    errorMessage={errors.claimEventDate?.message || ''}
                    disabled
                  />
                </View>
              </CanView>
              <CanView condition={claimType?.value === ClaimType.MEDICAL}>
                <View style={isMobile ? styles.mobileField : styles.tabletField}>
                  <DatePicker
                    {...register('admissionDate')}
                    alwaysShow
                    label={t('AdmissionDate')}
                    errorMessage={errors.admissionDate?.message || ''}
                    disabled
                  />
                </View>
                <View style={isMobile ? styles.mobileField : styles.tabletField}>
                  <DatePicker
                    {...register('dischargeDate')}
                    alwaysShow
                    label={t('DischargeDate')}
                    errorMessage={errors.dischargeDate?.message || ''}
                    disabled
                  />
                </View>
              </CanView>
            </View>
          </View>
          {/* ADJUDICATION INFO */}
          <Text style={styles.sectionTitle}>{t('AdjudicationInfo')}</Text>
          <View style={styles.sectionContainer}>
            <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Input {...register('payableAmount')} disabled={true} title={t('PayableAmount')} />
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Input
                  {...register('claimDecision')}
                  disabled={true}
                  title={t('ClaimDecision')}
                  value={
                    i18n.language === 'vi'
                      ? decision?.find((d) => d.code === form.getValues().claimDecision)?.nameVi
                      : decision?.find((d) => d.code === form.getValues().claimDecision)?.nameEn
                  }
                />
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <DatePicker
                  {...register('effectiveDate')}
                  alwaysShow
                  label={t('EffectiveDate')}
                  errorMessage={errors.claimEventDate?.message || ''}
                  disabled
                />
              </View>
            </View>
            <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Input {...register('policyStatus')} disabled={true} title={t('PolicyStatus')} />
              </View>
            </View>
          </View>
        </>
      ) : (
        <>
          <Text style={styles.sectionTitle}>{t('ClaimantInfo')}</Text>
          <View style={styles.sectionContainer}>
            <View>
              <Controller
                name={'isNotPolicyOwner'}
                control={control}
                rules={{
                  required: {
                    value: watch('claimType')?.value === ClaimType.DEATH,
                    message: `${t('message:MS990046', { field: t('ClaimantIsNotPolicyOwner') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Checkbox
                    title={t('ClaimantIsNotPolicyOwner')}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
            <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Input
                  {...register('claimantName', {
                    required: `${t('message:MS020001', { field: t('ClaimantName') })}`,
                    maxLength: { value: 120, message: 'Max 120' },
                    pattern: {
                      value: /^[^<>'\"/;`%@*!(){}\[\],.:#+-=$^&\\|?~_-]*$/,
                      message: `${t('message:MS050013', { field: t('ClaimantName') })}`
                    }
                  })}
                  errorMessage={errors.claimantName?.message || ''}
                  maxLength={120}
                  title={t('ClaimantName')}
                  required={isNotPolicyOwner}
                  disabled={!watchPO}
                />
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold', color: '#70777E' }}>{t('ClaimantPhone')}</Text>
                  {isNotPolicyOwner && <Text style={{ color: 'red', marginLeft: 5 }}>*</Text>}
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ width: isNotPolicyOwner ? 85 : 'auto', marginRight: 10 }}>
                    {isNotPolicyOwner ? (
                      <Select {...registerClaimantPhoneCode} options={mobileCodes} disabled={isNotPolicyOwner} />
                    ) : (
                      <Text style={[styles.textContent, { marginTop: 10 }]}>{''}</Text>
                    )}
                  </View>
                  <View style={{ marginTop: 3 }}>
                    <Input
                      {...register('claimantPhoneNumber', {
                        required: isNotPolicyOwner && `${t('message:MS020001', { field: t('ClaimantPhone') })}`,
                        pattern: {
                          value: /^((?!501|504)[0-9]*)$/,
                          message: `${t('message:MS050013', { field: t('ClaimantPhone') })}`
                        }
                      })}
                      maxLength={10}
                      errorMessage={form.formState.errors.claimantPhoneNumber?.message}
                      disabled={!isNotPolicyOwner}
                      required={isNotPolicyOwner}
                    />
                  </View>
                </View>
              </View>
              <View style={isMobile ? styles.mobileField : styles.tabletField}>
                <Input
                  {...register('claimantEmail', {
                    pattern: {
                      value:
                        /^[a-zA-Z0-9]+([\w\.\'\!\#\$\%\&\*\+\-\/\=\?\^\`\{\|\}\~])*([a-zA-Z0-9])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/,
                      message: `${t('message:MS050013', { field: t('ClaimantEmail') })}`
                    }
                  })}
                  title={t('ClaimantEmail')}
                  disabled={!isNotPolicyOwner}
                  required={false}
                />
              </View>
            </View>
            <View style={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap', width: '100%' }}>
              <View style={isMobile ? styles.mobileField : styles.twoThirdField}>
                <Input
                  {...register('claimantAddress', {
                    max: 250,
                    pattern: {
                      // value: /^[^*&$#\\<>|~`!@%^()';.:\[\]"+=?]*$/g,
                      value: /^[^*&$#\\<>|]*$/g,
                      message: t('message:MS050223')
                    }
                  })}
                  maxLength={250}
                  title={t('ClaimantAddress')}
                  disabled={!isNotPolicyOwner}
                  required={false}
                  errorMessage={form.formState.errors.claimantAddress?.message}
                />
              </View>
            </View>
          </View>
          {/* CLAIM TYPE */}
          <Text style={styles.sectionTitle}>{t('ClaimType')}</Text>
          <View style={[styles.sectionContainer, { padding: 0 }]}>
            <View style={{ flexDirection: 'column', flexWrap: 'wrap', marginLeft: 18, marginRight: 18, marginTop: 18 }}>
              <View style={{ width: isMobile ? '100%' : '33%', marginBottom: 37 }}>
                <Select
                  {...register('claimType', { required: `${t('message:MS020001', { field: t('ClaimType') })}` })}
                  options={claimTypes.map((c) => ({
                    value: c.claimTypeCode,
                    label:
                      localStorage.getItem('locale') === 'en-US'
                        ? c.claimType
                        : listClaim?.find((item) => item?.claimCode === c?.claimTypeCode)?.claimTypeVN || ''
                  }))}
                  errorMessage={(isValid && (errors.claimType as FieldError | undefined)?.message) || ''}
                  label={t('PleaseLetUsKnowWhatHappened')}
                  required={true}
                  placeholder={t('common:Select')}
                />
              </View>
            </View>
            {claimType && (
              <View
                style={
                  claimType.value === ClaimType.MEDICAL
                    ? { backgroundColor: '#FFFFFF' }
                    : { backgroundColor: '#FAFAFA', borderTopWidth: 1, borderTopColor: '#D3DCE6' }
                }
              >
                {getClaimComp()}
              </View>
            )}
          </View>
        </>
      )}
    </View>
  )
}

const styles = {
  ...generalStyles,
  ...StyleSheet.create({
    tabContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: 60
    },
    textLabel: {
      fontSize: 15,
      fontWeight: 'bold',
      color: '#70777E'
    },
    textContent: {
      fontSize: 15
    },
    claimContainer: {
      marginTop: 14,
      // padding: 18,
      backgroundColor: '#FFFFFF',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#D3DCE6',
      minHeight: 20
    }
  })
}
