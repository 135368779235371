import * as React from 'react'
import { HeaderTable, SelectSearch, assets, Columns, getLanguage, RowSelectedProps } from '@pulseops/common'
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'

export interface DuplicateInfoItem {
  clientNumber: string
  clientName: string
  relationship: {
    vi: string
    en: string
  }
}

interface Props {
  data: DuplicateInfoItem[]
}

const PopupIcon = () => (
  <View style={{ paddingVertical: 7 }}>
    <assets.ArrowDownDropdownIcon />
  </View>
)

export const DuplicateInfoTable = ({ data }: Props) => {
  const { t } = useTranslation()

  const renderRelationship = ({ index }: RowSelectedProps) => {
    const relationship = data[index]?.relationship
    const value = {
      label: (getLanguage() === 'en' ? relationship?.en : relationship.vi) || '',
      value: ''
    }

    return (
      <SelectSearch
        readOnly
        disableUnderline
        hideLabel
        value={value}
        options={[value]}
        inputStyle={styles.inputStyle}
        popupIcon={<PopupIcon />}
      />
    )
  }

  const columns: Columns[] = [
    {
      key: '0',
      name: 'clientNumber',
      title: t('submission:ClientNumber')
    },
    {
      key: '1',
      name: 'clientName',
      title: t('submission:ClientName')
    },
    {
      key: '2',
      name: 'relationship',
      title: t('submission:RelationshipWithPO'),
      render: renderRelationship
    }
  ]

  return <HeaderTable scrollable={true} contentHeight={300} columns={columns} dataSource={data as any[]} />
}

const styles = StyleSheet.create({
  inputStyle: {
    maxWidth: 300,
    borderWidth: 1,
    borderColor: '#E5EAEF',
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 7
  }
})
