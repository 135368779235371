import { OBInternalFeedbackStackParamList } from './OBInternalFeedbackStackParamList'
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { OBInternalFeedbackScreen } from './OBInternalFeedbackScreen'

const Stack = createStackNavigator<OBInternalFeedbackStackParamList>()

export const OBInternalFeedbackStack = () => {
  return (
    <Stack.Navigator initialRouteName="OBInternalFeedbackScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="OBInternalFeedbackScreen" component={OBInternalFeedbackScreen}></Stack.Screen>
    </Stack.Navigator>
  )
}
