import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'

export interface FatcaDeclaration {
  tag: TransactionType.FATCA_DECLARATION
  source: SourceType
  clientName: string
  clientNumber: string
}

export const FatcaDeclaration = (
  detail: api.FatcaDeclaration,
  clientName: string,
  clientNumber: string
): FatcaDeclaration => {
  return {
    tag: TransactionType.FATCA_DECLARATION,
    source: detail.source as SourceType,
    clientName,
    clientNumber
  }
}
