// import { CircularProgress } from '@material-ui/core'
import { Color } from '@material-ui/lab'
import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  AuthService,
  CloudData,
  ErrorHandling,
  InquiryComplaintData,
  InquiryComplaintService,
  LocalData,
  PulseOpsFormat,
  RoundedButton,
  sharedStyle,
  StorageBlob,
  TaskDetailService,
  TASK_DETAIL_ROLES,
  Toast,
  TaskType,
  subscribe,
  GlobalLoadingModal
} from '@pulseops/common'
// import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import {
  VeriAdditionalDocuments,
  VeriInquiryComplaintData,
  VeriPayloadData
} from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { CaseInfo } from './CaseInfo'
import { CommunicationInfo } from './CommunicationInfo'
import { Conservation } from './Conservation'
import { Diary } from './Diary'
import { GAOperation } from './GAOperation'
import { DiaryForm, InquiryComplaintForm, VericomplaintList } from './InquiryComplaintForm'
import { MoneyMisappropriation } from './MoneyMisappropriation'
import { Resolution } from './Resolution'
import { SalesPractice } from './SalesPractice'
import _ from 'lodash'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import { AdditionalDocument } from './AdditionalDocument'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { EditingHistoryModal, EditingHistoryModalRef } from './EditingHistoryModal'
import { TaskDetailState } from '../../state'
import { InquiryComplaintState } from './InquiryComplaintState'
import { useIsFocused } from '@react-navigation/native'

interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  subTaskCode: string
  isHistory: boolean | null
  isInquiry: boolean | null
  isSuspend: boolean
  isQC: boolean | null
  onSubmitInquiry: (isSubmit: boolean) => void
  roles: string[]
  isNotCheckValidator: boolean
  onCheckValidator: (isValidator: boolean) => void
  setUpdatedIACPayloadData: (data: VeriPayloadData) => void
  updatedIACPayloadData?: VeriPayloadData
  isChangeTab?: boolean
  setIsHiddenButtonsForIACHistory?: (val: boolean) => void
}

export type UpdatedPayloadData = {
  updatedPayloadInfo: VeriPayloadData
  isViewHistory: boolean
  verionNumber: number
}

export const InquiryComplaint: React.FC<Props> = ({
  detail,
  form,
  isHistory,
  isInquiry,
  isSuspend,
  subTaskCode,
  isQC,
  roles,
  onSubmitInquiry,
  isNotCheckValidator,
  onCheckValidator,
  setUpdatedIACPayloadData,
  updatedIACPayloadData,
  isChangeTab,
  setIsHiddenButtonsForIACHistory
}) => {
  const diaryForm = useForm<DiaryForm>({ criteriaMode: 'firstError', mode: 'all' })

  const [diariesSubmit, setDiariesSubmit] = React.useState<InquiryComplaintService.VeriIACDiary[]>([])

  const [isResetResolution, setIsResetResolution] = React.useState<boolean>(false)
  const [isResetDiary, setIsResetDiary] = React.useState<boolean>(false)
  const [isShowConservationInfo, setIsShowConservationInfo] = React.useState<boolean>(false)

  const [isEditingHistoryModal, setIsEditingHistoryModal] = React.useState<boolean>(false)
  const [currentHistoryID, setCurrentHistoryID] = React.useState<string>()
  const [userInfo, setUserInfo] = React.useState<{ email: string; name: string }>({
    email: '',
    name: ''
  })
  const editingHistoryModalRef = React.useRef<EditingHistoryModalRef | null>(null)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [isIACLoading, bindLoader] = useLoading(false)

  // const [updatedPayloadData, setUpdatedPayloadData] = React.useState<UpdatedPayloadData>({
  //   updatedPayloadInfo: isChangeTab && updatedIACPayloadData ? updatedIACPayloadData : detail.veriPayloadData,
  //   isViewHistory: false,
  //   verionNumber: -1
  // })
  const isFocused = useIsFocused()
  const [updatedPayloadData, setUpdatedPayloadData] = React.useState<UpdatedPayloadData>()

  const listMethodShowCallID = ['MT02', 'MT03', 'MT12']

  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const complaintList = VericomplaintList

  const { setValue, clearErrors, watch, reset, getValues } = form
  const { veriPayloadData } = detail
  const { t } = useTranslation()
  const displayedColumn = [t('menu:Version'), t('requestInfo:EditedBy'), t('requestInfo:EditedDate'), '']

  const showLoading = (isLoading: boolean): void => {
    // setLoading(isLoading)
  }

  React.useEffect(() => {
    // console.log('focus and unfocus on InquiryComplaint Screen')
    if (isFocused && isChangeTab && !!updatedIACPayloadData) {
      // console.log('updatedPayloadData,isChangeTab')
      setUpdatedPayloadData({
        updatedPayloadInfo: updatedIACPayloadData,
        isViewHistory: false,
        verionNumber: -1
      })
    } else if (isFocused && detail.veriPayloadData) {
      // console.log('detail.veriPayloadData')
      setUpdatedPayloadData({
        updatedPayloadInfo: detail.veriPayloadData,
        isViewHistory: false,
        verionNumber: -1
      })
    }
  }, [isFocused, isChangeTab, detail.veriPayloadData])

  React.useEffect(()=> {
    return () => {
      // console.log('reset setUpdatedPayloadData')
      setUpdatedPayloadData(undefined)
    }
  },[isFocused])

  console.log('updatedIACPayloadData', JSON.stringify(updatedIACPayloadData))

  React.useEffect(() => {
    const flagConservation =
      !!detail.submissionData.CONSERVATION_INFO &&
      detail.submissionData.CONSERVATION_INFO !== {} &&
      isHistory === true &&
      detail.submissionData.SUB_TRANS_TYPE_CODE === 'CS2701'
    // console.log('flagConservation: ' + flagConservation)
    setIsShowConservationInfo(flagConservation)
    onSubmitInquiry(false)
  }, [detail])

  pipe(
    AuthService.userInfo,
    ZIO.map((user) => {
      setUserInfo(user)
    }),
    // bindLoader,
    ErrorHandling.runDidUpdate([detail.processInstanceId])
  )

  const onGetDiaryList = (diaries: InquiryComplaintService.VeriIACDiary[]) => {
    setDiariesSubmit(diaries)
  }


  React.useEffect(() => {
    updatedPayloadData && setIsHiddenButtonsForIACHistory && setIsHiddenButtonsForIACHistory(updatedPayloadData?.isViewHistory)
  }, [updatedPayloadData?.isViewHistory])

  const mappingResolutionData = (data: InquiryComplaintForm) => {
    const { resolution } = data

    const veriResolutionData: InquiryComplaintService.VeriResolutionData = {
      id: '',
      agentCode: resolution?.agentCode || '',
      agentName: resolution?.agentName || '',
      amount: resolution.amount ? PulseOpsFormat.thousandSepartorReverse(resolution.amount).toString() : '0',
      attachmentIds: [''],
      changeType: resolution.changetype_Subtype === 'yes' ? true : false,
      complaintAgainstId: resolution.complaintAgainst?.value || '',
      correctiveStatementId: resolution.correctiveSettlement?.value || '',
      createdBy: userInfo.name,
      createdDate: '',
      createdLetter: true,
      isSerious: resolution.significant || false,
      processInstanceId: detail.processInstanceId,
      reasonDealingId: resolution.reasonForDealing?.value || '',
      reasonId: resolution.reason?.value || '',
      resolutionActionId: resolution.resolution?.value || '',
      responseMethodId: resolution.responseMethod?.value || '',
      reasonExceedingId: resolution.reasonForExceedingTAT?.value || '',
      detail: resolution.detail,
      newGroup: resolution.finalGroup?.value ?? '',
      newType: resolution.finalType?.value ?? '',
      newSubType: resolution.finalSubType?.value ?? ''
    }
    return veriResolutionData
  }

  const mappingNewSubmissionData = (data: InquiryComplaintForm) => {
    let requestorFullName = ''
    let totalVerifiedAmount = 0
    let COMPLAINTMISMONEYDETAILS: InquiryComplaintData.COMPLAINTMISMONEYDETAILS[] = []
    let COMPLAINTSALEDETAILS: InquiryComplaintData.COMPLAINTSALEDETAILS[] = []
    let COMPLAINTGADETAILS: InquiryComplaintData.COMPLAINTGADETAILS[] = []
    let CONSERVATIONINFO: InquiryComplaintData.CONSERVATIONINFO | null = null
    // const data = form.getValues()
    const { caseInfo, communicationInfo, moneyMissappropriation, salesPractice, GA, conservation } = data
    const hot = caseInfo.hot ? true : false
    // const groupCode = detail.submissionData.GROUP_CODE
    const groupCode = data.caseInfo.group?.value
    const touchPoint = pipe(
      detail.sourceList.find((x) => x.code === data.caseInfo.source?.value),
      O.fromNullable,
      O.map((item) => item.touchPoint),
      O.getOrElse(() => '')
    )
    const totalComplaintAmount = groupCode === 'CHG2' ? Number(moneyMissappropriation.totalComplaintAmount) : 0
    if (caseInfo.requestorRole?.value) {
      if (caseInfo.requestorRole.value === '1' || caseInfo.requestorRole.value === '4') {
        requestorFullName = _.get(caseInfo, 'requestorFullName')
      } else {
        requestorFullName = _.get(caseInfo, 'requestorFullName1.value') || ''
      }
    }
    if (groupCode === 'CHG2') {
      totalVerifiedAmount = Number(moneyMissappropriation.totalVerifiedAmount)
      COMPLAINTMISMONEYDETAILS = moneyMissappropriation.list.map((controlItem) => {
        const misPremiumDate = moment(controlItem.benefit_PremiumMisappropriatedDate).format('YYYYMMDD')
        const hasReciept = controlItem.receipt.toString()
        const verifiedAmount = Number(controlItem.verifiedAmount)
        console.log('hasReciept' + hasReciept)
        const Moneyitem: InquiryComplaintData.COMPLAINTMISMONEYDETAILS = {
          hasReciept: hasReciept,
          recieptType: _.get(controlItem, 'receiptType.value') || '',
          recieptNum: _.get(controlItem, 'receiptNumber') || '',
          complaintAmount: controlItem.complaintAmount
            ? PulseOpsFormat.thousandSepartorReverse(controlItem.complaintAmount)
            : 0,
          misPremiumDate: misPremiumDate,
          evidenceStatus: controlItem.evidenceStatus?.value ?? '',
          verifiedAmount: verifiedAmount
        }
        return Moneyitem
      })
    }
    if (groupCode === 'CHG3') {
      const isPoSignedInProposal = salesPractice.POSignedInProposal === 'yes' ? true : false
      const isCustomerReceiveKit = salesPractice.customerReceivedPolicyKit === 'yes' ? true : false
      const evidence = salesPractice.evidence === 'yes' ? true : false
      const customerReceiveDate = salesPractice.customerReceivedDate
        ? moment(salesPractice.customerReceivedDate).format('YYYYMMDD')
        : ''
      const salePractice = veriPayloadData.body.attributesExt.COMPLAINT_SALE_DETAILS
      const agentCode = salePractice && salePractice.length > 0 ? salePractice[0].agentCode : ''
      const agentName = salePractice && salePractice.length > 0 ? salePractice[0].agentName : ''
      const saleItem: InquiryComplaintData.COMPLAINTSALEDETAILS = {
        agentCode: agentCode,
        agentName: agentName,
        financeNeedCode: salesPractice.financialNeedAnalysis?.value || '',
        isPoSignedInProposal: isPoSignedInProposal,
        isCustomerReceiveKit: isCustomerReceiveKit,
        customerReceiveDate: customerReceiveDate,
        evidence: evidence
      }
      COMPLAINTSALEDETAILS.push(saleItem)
    }
    if (groupCode === 'CHG4') {
      const verifiedAmount = !!GA.verifiedAmount ? PulseOpsFormat.thousandSepartorReverse(GA.verifiedAmount) : 0
      const gaItem: InquiryComplaintData.COMPLAINTGADETAILS = {
        generalAgency: GA.generalAgency?.value || '',
        personComplaint: GA.personToBeComplained,
        role: GA.role?.value ?? '',
        verifiedAmount: verifiedAmount,
        evidenceStatus: GA.evidenceStatus?.value ?? ''
      }
      COMPLAINTGADETAILS.push(gaItem)
    }

    if (isShowConservationInfo) {
      const veriCONSERVATION = veriPayloadData.body.attributesExt
        .CONSERVATION_INFO as InquiryComplaintData.CONSERVATIONINFO

      CONSERVATIONINFO = {
        product: veriCONSERVATION.product || '',
        servicingAgentCode: veriCONSERVATION.servicingAgentCode || '',
        servicingAgentName: veriCONSERVATION.servicingAgentName || '',
        conservationGAStaff: conservation.conservationGAStaff ? userInfo.name : '',
        conservationAgentCode: _.get(conservation, 'conservationAgentCode') || '',
        conservationAgentName: _.get(conservation, 'conservationAgentName') || '',
        reasonFullSurrender: conservation.reasonFullSurrender?.value || '',
        conservationResult: conservation.conservationResult?.value || '',
        conservationSolution: conservation.conservationSolution?.value || '',
        conservationBonus: _.get(conservation, 'conservationBonus.value') || '',
        conservationNote: conservation.conservationNote
      }
    }

    const submitData: InquiryComplaintData.SubmitData = {
      CATEGORY_CODE: detail.submissionData.CATEGORY_CODE,
      GROUP_CODE: groupCode ?? '',
      SUB_SVC_TYPE_CODE: caseInfo.type?.value || '',
      SUB_TRANS_TYPE_CODE: caseInfo.subType?.value || '',
      REQUEST_WITH_ROLE: caseInfo.requestorRole?.value || '',
      REQUESTER_FULL_NAME: requestorFullName,
      REQUESTER_NATIONAL_ID: caseInfo.requestorNationalID,
      RLA_WITH_PO: caseInfo.relationshipWithPolicyOwner?.value || '',
      METHOD: caseInfo.method?.value || '',
      SOURCE: caseInfo.source?.value || '',
      TOUCH_POINT: touchPoint,
      HOT: hot,
      FCR: detail.submissionData.FCR,
      CUSTOMER_CMT: communicationInfo.customerComments || '',
      RES_TO_CUSTOMER: communicationInfo.responsesToCustomer || '',
      TOTAL_MIS_MONEY_AMT: totalComplaintAmount,
      TOTAL_VERIFIED_AMOUNT: totalVerifiedAmount,
      COMPLAINT_MIS_MONEY_DETAILS: COMPLAINTMISMONEYDETAILS,
      COMPLAINT_SALE_DETAILS: COMPLAINTSALEDETAILS,
      COMPLAINT_GA_DETAILS: COMPLAINTGADETAILS,
      CONSERVATION_INFO: CONSERVATIONINFO ?? {},
      CLOSED_INFO: communicationInfo.ClosedInfo,
      CALL_ID: watch('caseInfo.source.value') === 'SO14' ? (detail.submissionData.CALL_ID ?? '') : ''
    }
    return submitData
  }

  const getIACPayloadData = (formData: InquiryComplaintForm, newAdditionalList: VeriAdditionalDocuments[]) => {
    const newSubmissionData = mappingNewSubmissionData(formData)
    const payloadData: VeriPayloadData = {
      source: veriPayloadData.source,
      createdBy: veriPayloadData.createdBy,
      documents: veriPayloadData.documents,
      primaryPolicyNo: veriPayloadData.primaryPolicyNo,
      clientId: veriPayloadData.clientId,
      officeCode: veriPayloadData.officeCode,
      createdDate: veriPayloadData.createdDate,
      body: {
        attributesExt: newSubmissionData,
        auditDetail: {
          createTime: veriPayloadData.body.auditDetail.createTime
        }
      },
      additionalDocuments: newAdditionalList
    }
    return payloadData
  }

  const updateAdditionalDocumentForm = (veriAdditionalList: VeriAdditionalDocuments[]) => {
    watch('additionalDocument').forEach((additionalItem, index) => {
      if (additionalItem.transactionType?.value === veriAdditionalList[index].transactionType) {
        setValue(
          `additionalDocument.${index}.isAzuredUpdated`,
          veriAdditionalList[index].attachmentList && veriAdditionalList[index].attachmentList.length > 0 ? true : false
        )
        setValue(`additionalDocument.${index}.attachment`, veriAdditionalList[index].attachmentList)
      }
    })
  }

  const getNewAdditionalDocumentList = (formData: InquiryComplaintForm) => {
    const additionalList = formData.additionalDocument
    return pipe(
      ZIO.succeed(additionalList),
      ZIO.map((dataList) => {
        const filteredList = dataList
          .filter((p) => !!p.transactionType?.value)
          .map((dataItem) => {
            const files =
              dataItem.attachment && dataItem.attachment.length > 0
                ? dataItem.attachment
                  .filter((x) => x.tag === 'Local')
                  .map((attachmentItem) => {
                    const localItem = attachmentItem as LocalData
                    return {
                      file: localItem.file,
                      metaData: dataItem.metaData
                    }
                  })
                : []
            return {
              attachmentList: files,
              transactionType: dataItem.transactionType?.value ?? ''
            }
          })
        return filteredList
      }),
      ZIO.flatMap((metaDataFiles) => {
        const transactionFileList = pipe(
          metaDataFiles,
          A.map((metaItem) => {
            return pipe(
              metaItem.attachmentList && metaItem.attachmentList.length > 0
                ? StorageBlob.uploadToSubmit(subTaskCode, veriPayloadData.primaryPolicyNo)(metaItem.attachmentList)
                : ZIO.succeed([]),
              ZIO.map((azuredFiles) => {
                return {
                  transactionType: metaItem.transactionType,
                  azuredFiles
                }
              })
            )
          }),
          ZIO.sequence
        )
        return transactionFileList
      })
    )
  }

  const checkDeletedItem = (cloudItem: CloudData, formData: InquiryComplaintForm) => {
    const additionalList = formData.additionalDocument
    let isNoExistedFileName = true
    additionalList.forEach((item) => {
      if (item.attachment.filter((p) => p.tag === 'Cloud').some((x) => x.fileName === cloudItem.fileName)) {
        isNoExistedFileName = false
      }
    })
    return isNoExistedFileName
  }

  const getDeletedAdditionalDocumentList = (formData: InquiryComplaintForm) => {
    let removedDocuments: InquiryComplaintData.AdditionalDocumentList[] = []
    // const payloadDocList = detail.veriPayloadData.additionalDocuments ?? []
    const payloadDocList =
      !!watch('payloadData') && watch('payloadData.additionalDocuments')
        ? watch('payloadData.additionalDocuments') ?? []
        : detail.veriPayloadData.additionalDocuments ?? []
    payloadDocList.forEach((docItem) => {
      docItem.attachmentList.forEach((attachmentItem) => {
        // if attachmentItem does not exist in additionalDocument form group (user delete it) and
        // save this attachmentItem in removed Documents
        if (checkDeletedItem(attachmentItem, formData)) {
          const apiUrl = attachmentItem.url.split('?')
          const storageUrl = apiUrl[0]
          const paramInfo = apiUrl[1]
          const paramList = paramInfo.split('&')
          const containerName = paramList[0] ? paramList[0].split('=')[1] : ''
          const blobName = paramList[1] ? paramList[1].split('=')[1] : ''
          const azuredUrl = storageUrl + containerName
          removedDocuments.push({
            name: blobName,
            url: azuredUrl,
            id: ''
          })
        }
      })
    })
    return removedDocuments
  }

  const saveIACPayloadData = (formData: InquiryComplaintForm) => {
    const additionalDocumentData = formData.additionalDocument
    return pipe(
      ZIO.effect(() => {
        const removeList = getDeletedAdditionalDocumentList(formData)
        return removeList
      }),
      ZIO.flatMap((deletedAdditionalList) => {
        const isNoExistedNewList = pipe(
          additionalDocumentData,
          O.fromNullable,
          O.map((item) =>
            item.length <= 0 ||
              (additionalDocumentData.length <= 1 && !additionalDocumentData[0].transactionType?.value)
              ? true
              : false
          ),
          O.getOrElse(() => true)
        )
        return ZIO.zipPar(
          isNoExistedNewList ? ZIO.succeed([]) : getNewAdditionalDocumentList(formData),
          ZIO.succeed(deletedAdditionalList)
        )
      }),
      ZIO.flatMap(([newAdditionalData, removedDocument]) => {
        let addDocuments: InquiryComplaintData.AdditionalDocumentList[] = []
        const additionalList = formData.additionalDocument
        let veriAdditionalDocumentList = newAdditionalData
          .filter((p) => !!p.transactionType)
          .map((item) => {
            //get new uploaded files
            const filteredList = item.azuredFiles.map((azuredItem) => ({
              name: azuredItem.name,
              url: azuredItem.url,
              id: azuredItem.id ?? ''
            }))
            addDocuments = [...addDocuments, ...filteredList]
            const cloudFileInfoList = item.azuredFiles.map((subitem) => {
              const fileInfo = additionalList
                .find((x) => x.transactionType?.value === item.transactionType)
                ?.attachment.find((p) => subitem.name.includes(p.fileName))
              const cloudFileInfo = subitem.name.indexOf('/') > 0 ? subitem.name.split('/') : []
              const fileName = cloudFileInfo.length > 1 ? cloudFileInfo[1] : ''
              const container = subitem.url.slice(subitem.url.lastIndexOf('/') + 1)
              const apiUrl = subitem.url.slice(0, subitem.url.lastIndexOf('/'))
              const azuredUrl = apiUrl + '/?' + 'container=' + container + '&blob=' + subitem.name
              const cloudFile: CloudData = {
                tag: 'Cloud',
                fileName: fileName,
                fileExtension: fileInfo?.fileExtension ?? '',
                size: fileInfo?.size ?? 0,
                base64: '',
                uploadedDate: fileInfo?.uploadedDate as Date,
                url: azuredUrl,
                blobInfo: subitem.name,
                storageConfig: {
                  sas: ''
                }
              }
              return cloudFile
            })
            return {
              transactionType: item.transactionType,
              attachmentList: cloudFileInfoList
            }
          })
        additionalList &&
          additionalList.length > 0 &&
          additionalList
            .filter((p) => !!p.transactionType?.value)
            .forEach((addItem, index) => {
              const previousCloudData = addItem.attachment.filter((x) => x.tag === 'Cloud') as CloudData[]
              if (addItem.transactionType?.value === veriAdditionalDocumentList[index].transactionType) {
                veriAdditionalDocumentList[index].attachmentList = [
                  ...veriAdditionalDocumentList[index].attachmentList,
                  ...previousCloudData
                ]
              } else {
                previousCloudData &&
                  previousCloudData.length > 0 &&
                  veriAdditionalDocumentList.splice(index, 0, {
                    transactionType: addItem.transactionType?.value ?? '',
                    attachmentList: previousCloudData
                  })
              }
            })
        const payloadData = getIACPayloadData(formData, veriAdditionalDocumentList)
        //reset AdditionalDocumentForm with cloudData Array
        veriAdditionalDocumentList &&
          veriAdditionalDocumentList.length > 0 &&
          updateAdditionalDocumentForm(veriAdditionalDocumentList)
        return TaskDetailService.saveIACPayloadData(
          detail.taskId,
          payloadData,
          detail.processInstanceId,
          addDocuments,
          removedDocument,
          []
        )
      }),
      ZIO.map((payloadResponse) => {
        !!payloadResponse && setValue('payloadData', payloadResponse)
        return payloadResponse
      })
    )
  }

  const saveDiaryList = (diaryList: InquiryComplaintService.VeriIACDiary[]) =>
    pipe(
      diaryList,
      A.map((diaryItem) => InquiryComplaintService.addNewDiary(diaryItem)),
      ZIO.sequence
    )

  const openEditingHistoryModal = () => {
    setIsEditingHistoryModal(true)
  }

  const closeEditingHistoryModal = () => {
    setIsEditingHistoryModal(false)
  }

  const validateIACFormForSave = async (IACFormdata: InquiryComplaintForm) => {
    const isValidCaseBlock = await form.trigger('caseInfo')
    const isValidMoneyMiss =
      IACFormdata.caseInfo.group?.value === 'CHG2' ? await form.trigger('moneyMissappropriation') : true
    const isValidSalesPractice =
      IACFormdata.caseInfo.group?.value === 'CHG3' ? await form.trigger('salesPractice') : true
    const isValidGa = IACFormdata.caseInfo.group?.value === 'CHG4' ? await form.trigger('GA') : true
    return isValidCaseBlock && isValidMoneyMiss && isValidSalesPractice && isValidGa
  }

  const onSubmit = async () => {
    const IACFormdata = form.getValues()
    if ((await validateIACFormForSave(IACFormdata)) && !watch('isSavePayloadData')) {
      if (isNotCheckValidator) {
        clearErrors()
      }
      setValue('isSavePayloadData', true)
      onSubmitInquiry(true)
      pipe(
        ZIO.effect(() => {
          return {
            diariesSubmit,
            isSaveResolution: complaintList.includes(detail.submissionData.GROUP_CODE),
            submitResolutionData: mappingResolutionData(IACFormdata)
          }
        }),
        ZIO.flatMap((InfoData) =>
          ZIO.zipPar(
            InfoData.diariesSubmit.length > 0 ? saveDiaryList(InfoData.diariesSubmit) : ZIO.succeed([]),
            InfoData.isSaveResolution
              ? InquiryComplaintService.addResolution(InfoData.submitResolutionData)
              : ZIO.succeed({})
          )
        ),
        ZIO.flatMap(([diaryResponse, resolutionRes]) => {
          setDiariesSubmit([])
          diaryForm.reset()
          return saveIACPayloadData(IACFormdata)
        }),
        ZIO.flatMap((savedPayload) => {
          // setUpdatedPayloadData({ updatedPayloadInfo: savedPayload, isViewHistory: false, verionNumber: -1 })
          const responseData = savedPayload as InquiryComplaintState.VeriPayloadData
          setUpdatedIACPayloadData(responseData)
          return pipe(
            ZIO.fromPromise(() =>
              editingHistoryModalRef && editingHistoryModalRef.current
                ? editingHistoryModalRef?.current?.getNewHistoryList()
                : Promise.resolve()
            ),
            ZIO.map((_) => savedPayload)
          )
        }),
        ZIO.foldM(
          (error) => {
            setValue('isSavePayloadData', false)
            showToast(t('message:MS050001'), 'error')

            return ZIO.fail(error)
          },
          (success) => {
            setValue('isSavePayloadData', false)
            showToast(t('message:MS100003'), 'success')
            return ZIO.succeed(success)
          }
        ),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onReset = () => {
    setValue('resolution.changetype_Subtype', 'no')
    setIsResetDiary((prevState) => !prevState)
    if (subTaskCode === 'CH' && detail.processInstanceId) {
      setIsResetResolution((prevState) => !prevState)
    }
  }

  const checkValidRolesForIACEdit = (): boolean => {
    if (subTaskCode === 'PS' && roles && roles.includes(TASK_DETAIL_ROLES.EDIT_REQUESTINFO_PS)) {
      return true
    } else if (subTaskCode === 'CS' && roles && roles.includes(TASK_DETAIL_ROLES.EDIT_REQUESTINFO_CS)) {
      return true
    } else if (subTaskCode === 'CH' && roles && roles.includes(TASK_DETAIL_ROLES.EDIT_REQUESTINFO_CH)) {
      return true
    }
    return false
  }

  const validateShowSaveButton = (): boolean => {
    return !isSuspend && !isQC && !isHistory && !isInquiry && checkValidRolesForIACEdit()
  }

  const validateReadOnly = (): boolean => {
    return (
      isSuspend || isQC || isHistory || isInquiry || !checkValidRolesForIACEdit() || (updatedPayloadData ? updatedPayloadData.isViewHistory : false)
    )
  }

  const onHistoryDetailView = (historyID: string, isViewHistory: boolean, version: number) => {
    //get history payload view and set state
    // showGlobalLoading(true)
    closeEditingHistoryModal()
    return pipe(
      InquiryComplaintService.getPayloadDataByHistoryID(historyID),
      ZIO.map((payloadInfo) => {
        setUpdatedPayloadData({ updatedPayloadInfo: payloadInfo, isViewHistory: isViewHistory, verionNumber: version })
        return payloadInfo
      }),
      ZIO.foldM(
        (error) => {
          // showGlobalLoading(false)
          return ZIO.fail(error)
        },
        (success) => {
          // showGlobalLoading(false)
          return ZIO.succeed(success)
        }
      ),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  // console.log('updatedPayloadData', JSON.stringify(updatedPayloadData))

  const onBackToCurrentVersion = () => {
    onHistoryDetailView(currentHistoryID ?? '', false, -1)
  }

  return (
    <View style={{ flex: 1 }}>
      {subTaskCode === TaskType.Complaint && (
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          {updatedPayloadData?.isViewHistory ? (
            <TouchableOpacity
              style={[sharedStyle.button, { marginBottom: 16, borderColor: '#ED1B2E', paddingHorizontal: 15 }]}
              onPress={onBackToCurrentVersion}
            >
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <assets.Undo />
                <Text style={[sharedStyle.textButton, { marginLeft: 10 }]}>{t('requestInfo:BackToLatestVersion')}</Text>
              </View>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[sharedStyle.button, { marginBottom: 16, borderColor: '#ED1B2E', paddingHorizontal: 15 }]}
              onPress={openEditingHistoryModal}
            >
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <assets.Clock />
                <Text style={[sharedStyle.textButton, { marginLeft: 10 }]}>{t('requestInfo:EditingHistory')}</Text>
              </View>
            </TouchableOpacity>
          )}
        </View>
      )}

      {updatedPayloadData && <CaseInfo
        detail={detail}
        form={form}
        validateReadOnly={validateReadOnly}
        subTaskCode={subTaskCode}
        updatedPayloadData={updatedPayloadData?.updatedPayloadInfo}
      />}
      {/* {isShowConservationInfo && <Conservation detailData={detail} dataForm={form} />} */}
      {isShowConservationInfo ? (
        <View>
          <Conservation detailData={detail} dataForm={form} />
          <Diary
            detail={detail}
            form={diaryForm}
            onGetDiaryList={onGetDiaryList}
            diariesSubmit={diariesSubmit}
            isReset={isResetDiary}
            validateReadOnly={validateReadOnly}
            isSuspend={isSuspend}
            isHistory={isHistory}
            // updatedPayloadData={updatedPayloadData}
          />
        </View>
      ) : (
        <View>
          {watch('caseInfo.group') && watch('caseInfo.group.value') === 'CHG2' && updatedPayloadData && (
            <MoneyMisappropriation
              detail={detail}
              form={form}
              validateReadOnly={validateReadOnly}
              updatedPayloadData={updatedPayloadData.updatedPayloadInfo}
            />
          )}
          {watch('caseInfo.group') && watch('caseInfo.group.value') === 'CHG3' && updatedPayloadData && (
            <SalesPractice
              detail={detail}
              form={form}
              validateReadOnly={validateReadOnly}
              updatedPayloadData={updatedPayloadData.updatedPayloadInfo}
            />
          )}
          {watch('caseInfo.group') && watch('caseInfo.group.value') === 'CHG4' && updatedPayloadData && (
            <GAOperation
              detail={detail}
              form={form}
              validateReadOnly={validateReadOnly}
              updatedPayloadData={updatedPayloadData.updatedPayloadInfo}
            />
          )}

          {updatedPayloadData && <CommunicationInfo
            form={form}
            validateReadOnly={validateReadOnly}
            updatedPayloadData={updatedPayloadData.updatedPayloadInfo}
          />}
          {updatedPayloadData && !updatedPayloadData.isViewHistory && (
            <>
              <Diary
                detail={detail}
                form={diaryForm}
                onGetDiaryList={onGetDiaryList}
                diariesSubmit={diariesSubmit}
                isReset={isResetDiary}
                validateReadOnly={validateReadOnly}
                isSuspend={isSuspend}
                isHistory={isHistory}
                // updatedPayloadData={updatedPayloadData}
              />
              <Resolution
                detail={detail}
                form={form}
                isHistory={isHistory}
                isSuspend={isSuspend}
                subTaskCode={subTaskCode}
                showLoading={showLoading}
                isReset={isResetResolution}
                validateReadOnly={validateReadOnly}
                onSubmitInquiry={onSubmitInquiry}
                // updatedPayloadData={updatedPayloadData}
              />
            </>
          )}

          {updatedPayloadData && (validateShowSaveButton() || updatedPayloadData.isViewHistory) && (
            <AdditionalDocument
              detail={detail}
              form={form}
              category={subTaskCode}
              disabled={updatedPayloadData.isViewHistory}
              updatedPayloadData={updatedPayloadData.updatedPayloadInfo}
            />
          )}
          {updatedPayloadData && validateShowSaveButton() && !updatedPayloadData.isViewHistory && (
            <View style={styles.row}>
              <RoundedButton
                showBorder={true}
                filled={false}
                activeOpacity={0.7}
                text={t('common:Cancel')}
                textStyle={styles.btnTitle}
                style={{ height: 39, marginEnd: 15, paddingHorizontal: 20 }}
                textColorDisable="#B0B0B0"
                textColorEnable="#ED1B2E"
                bgColorDisable={'#B0B0B0'}
                bgColorEnable={'#ED1B2E'}
                borderColorDisable={'#B0B0B0'}
                borderColorEnable={'#ED1B2E'}
                onPress={onReset}
              />
              <RoundedButton
                // isLoading={loader}
                showBorder={false}
                filled={true}
                activeOpacity={0.7}
                text={t('common:Save')}
                textStyle={styles.btnTitle}
                style={{ height: 39, paddingHorizontal: 20 }}
                textColorDisable="#FFFFFF"
                textColorEnable="#FFFFFF"
                bgColorDisable={'#B0B0B0'}
                bgColorEnable={'#ED1B2E'}
                borderColorDisable={'#B0B0B0'}
                borderColorEnable={'#ED1B2E'}
                onPress={onSubmit}
              />
            </View>
          )}
        </View>
      )}
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
      <EditingHistoryModal
        ref={editingHistoryModalRef}
        title={t('requestInfo:EditingHistory')}
        column={displayedColumn}
        // dataSource={payloadHistoryData}
        open={isEditingHistoryModal}
        onClose={closeEditingHistoryModal}
        onHistoryDetailView={onHistoryDetailView}
        processInstanceId={detail.processInstanceId}
        setCurrentHistoryID={setCurrentHistoryID}
        currentHistoryID={currentHistoryID}
      ></EditingHistoryModal>
      <GlobalLoadingModal visible={isIACLoading} />
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
