import { POApi, TransactionType } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { ZIO } from '@mxt/zio'
import { Maybe } from '@mxt/zio/codec'

export const ClaimPayoutReportItem = t.type({
    fromDate: Maybe(t.string),
    toDate: Maybe(t.string),
    filename: Maybe(t.array(t.string)),
    createdDate: Maybe(t.string),
    createdBy: Maybe(t.string),
    folderAzure: Maybe(t.string),
    status: Maybe(t.string),
    author: Maybe(t.string),
})

export const ClaimPayoutReportData = t.type({
   data: Maybe(t.array(ClaimPayoutReportItem))
})

export type ClaimPayoutReportItem = t.TypeOf<typeof ClaimPayoutReportItem>
export type ClaimPayoutReportData = t.TypeOf<typeof ClaimPayoutReportData>


export namespace ClaimPayoutReportService {
    export const exportData = (data: {
        fromDate: string
        toDate: string
        author: string | undefined
        reportType: string 
        role: string
      }) => {
        return pipe(
          POApi.post('pulseops/api/v1/claim-report/get-report')(ClaimPayoutReportData)(data),
          ZIO.catchAll(() => ZIO.succeed(null)),
          ZIO.map((res) => {
            const { data = [] } = res ?? {}
    
            return {
              data
            }
          })
        )
      }
}