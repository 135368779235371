import { enumC } from '@mxt/zio/codec'

export enum Frequency {
  Annual = '01',
  HalfYearly = '02',
  Quarterly = '04',
  Monthly = '12'
}
export const FrequencyC = enumC(Frequency)

export const mapFrequency = new Map<Frequency, string>([
  [Frequency.Annual, 'Annual'],
  [Frequency.HalfYearly, 'Half Yearly'],
  [Frequency.Quarterly, 'Quarterly'],
  [Frequency.Monthly, 'Monthly']
])

export namespace FrequencyMapping {
  export const FrequencyInfo = {
    Annual: `frequency:Annual`,
    HalfYearly: `frequency:HalfYearly`,
    Quarterly: `frequency:Quarterly`,
    Monthly: `frequency:Monthly`
  }

  export const mapFrequency = new Map<Frequency | string, string>([
    [Frequency.Annual, FrequencyInfo.Annual],
    ['Annual', FrequencyInfo.Annual],
    [Frequency.HalfYearly, FrequencyInfo.HalfYearly],
    ['Half Yearly', FrequencyInfo.HalfYearly],
    [Frequency.Quarterly, FrequencyInfo.Quarterly],
    ['Quarterly', FrequencyInfo.Quarterly],
    [Frequency.Monthly, FrequencyInfo.Monthly],
    ['Monthly', FrequencyInfo.Monthly]
  ])

  export const get = (translator: (val: string) => string) => (key?: string) =>
    key ? translator(mapFrequency.get(key) ?? '') : '-'

  export const FrequencyOption: Array<{ value: Frequency; label: string }> = [
    {
      value: Frequency.Annual,
      label: mapFrequency.get(Frequency.Annual) ?? ''
    },
    {
      value: Frequency.HalfYearly,
      label: mapFrequency.get(Frequency.HalfYearly) ?? ''
    },
    {
      value: Frequency.Monthly,
      label: mapFrequency.get(Frequency.Monthly) ?? ''
    },
    {
      value: Frequency.Quarterly,
      label: mapFrequency.get(Frequency.Quarterly) ?? ''
    }
  ]
}
