import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { CategoryTabInfo, IBCategoryTab } from '../common'
import { useTranslation } from 'react-i18next'
import { IBListingOfReturned } from './ib-listing-of-returned'
import { IBListingOfPendingApproval } from './ib-listing-of-pending-approval'
import { isEmpty } from 'lodash'
import { AppContext, ErrorHandling, IBPolicyPermission, RBAC, SelectOption } from '@pulseops/common'
import { A14S0Data, A14S19Response, A14S1Data, A14S5Data, ExtensionNumberReponse, IBService } from '../ib-service'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import { EmailManagementContext } from './IBEmailManagementContext'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { IBListingOfHistory } from './ib-listing-of-history'

export const IBEmailManagement = () => {
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const { t, i18n } = useTranslation('Inbound')
  const [masterDataList, setMasterDataList] = React.useState<{
    policyLists: (SelectOption & { policyOwner: string })[]
    a14s0s: A14S0Data[]
    a14s1s: A14S1Data[]
    a14s5s: A14S5Data[]
    a14s18: ExtensionNumberReponse[]
    a14s19: A14S19Response[]
  }>({ policyLists: [], a14s0s: [], a14s1s: [], a14s5s: [], a14s18: [], a14s19: [] })
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const navigation = useNavigation()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([])) || []
  const [listTabHasPermission, setListTabHasPermission] = React.useState<CategoryTabInfo[]>([])

  const categoryTabList: Array<CategoryTabInfo> = [
    {
      label: t('ListingOfReturned'),
      key: '0',
      permission: IBPolicyPermission.IBViewReturnedEmailList
    },
    {
      label: t('ListingOfPendingApproval'),
      key: '1',
      permission: IBPolicyPermission.IBViewPendingApprovalEmailList
    },
    {
      label: t('History'),
      key: '2',
      permission: IBPolicyPermission.IBViewHistoryEmailList
    }
  ]

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:EmailManagement'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    pipe(
      ZIO.zipPar(
        IBService.getA14S0(),
        IBService.getA14S1(),
        IBService.getA14S5(),
        IBService.getA14S19(),
        IBService.getListUserCreatedByEmail()
      ),
      ZIO.map(([A14S0, A14S1, A14S5, T14S19, A14S18]) => {
        setMasterDataList({
          policyLists: [],
          a14s0s: A14S0 as A14S0Data[],
          a14s1s: A14S1 as A14S1Data[],
          a14s5s: A14S5 as A14S5Data[],
          a14s18: A14S18.data,
          a14s19: T14S19.data
        })
      }),
      ZIO.unsafeRun({})
    )
  }, [])

  React.useEffect(() => {
    const list: Array<CategoryTabInfo> = []
    categoryTabList.map((i) => {
      if (i.permission && permissions.includes(i.permission)) {
        list.push(i)
      }
    })
    setListTabHasPermission(list)
  }, [permissions, i18n.language])

  const getTabKey = (tabIndex: number) => {
    const tabShow = listTabHasPermission[tabIndex]
    return tabShow?.key ?? ''
  }
  return (
    <EmailManagementContext.Provider
      value={{
        masterDataList: masterDataList,
        permissions: permissions
      }}
    >
      <View style={styles.container}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          {permissions ? (
            <>
              <IBCategoryTab
                dataSource={listTabHasPermission}
                onChangeTab={setCategoryTabIndex}
                activeTabIndex={categoryTabIndex}
                permission={permissions}
              />
              {getTabKey(categoryTabIndex) === '0' && <IBListingOfReturned />}
              {getTabKey(categoryTabIndex) === '1' && <IBListingOfPendingApproval />}
              {getTabKey(categoryTabIndex) === '2' && <IBListingOfHistory />}
            </>
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="red" />
            </View>
          )}
        </ScrollView>
      </View>
    </EmailManagementContext.Provider>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    height: '100%',
    width: '100%',
    padding: 30
  }
})
