export namespace PayoutInfoConst {
  export const salutation = [
    {
      value: 'BÀ',
      label: 'submission:Mrs'
    },
    {
      value: 'CHÁU',
      label: 'submission:Child'
    },
    {
      value: 'CÔ',
      label: 'submission:Ms'
    },
    {
      value: 'ÔNG',
      label: 'submission:Mr'
    },
    {
      value: 'QUÝ',
      label: 'submission:Quy'
    }
  ]

  export const married = [
    {
      value: 'M',
      label: 'submission:Married'
    },
    {
      value: 'S',
      label: 'submission:Single'
    },
    {
      value: 'W',
      label: 'submission:Widowed'
    },
    {
      value: 'U',
      label: 'submission:Unknown'
    },
    {
      value: 'D',
      label: 'submission:Divorced'
    },
    {
      value: 'Quý',
      label: 'submission:Divorced'
    }
  ]
  export const genders = [
    {
      value: 'F',
      label: 'common:Female'
    },
    {
      value: 'M',
      label: 'common:Male'
    }
  ]
}
