import { Task } from '@mxt/zio'
import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import { PruKid369Model, RequestAuthFormDataSubmit } from './model'
import * as t from 'io-ts'
import { SubmissionService } from './SubmissionService'
import { POApi } from '../POApi'

export namespace PruKid369Service {
  export const getDetail = (policyNo: string): Task<PruKid369Model.Detail> =>
    pipe(
      SubmissionService.getAccountBalance(policyNo, 'TOTAL', [
        {
          sacsCode: 'LX',
          sacsType: 'EX'
        }
      ]),
      ZIO.map((x) => {
        return {
          prukidAmount: x.body.accountGroups?.reduce((sum, x) => x.amount + sum, 0) || 0
        }
      })
    )

  export const getDetailVer = (policyNo: string): Task<PruKid369Model.DetailVerify> =>
    pipe(
      ZIO.zipPar(
        SubmissionService.getAccountBalance(policyNo, 'TOTAL', [
          {
            sacsCode: 'LX',
            sacsType: 'EX'
          }
        ]),
        SubmissionService.getLoanEnquiry(policyNo)
      ),
      ZIO.map(([prukid, loanEnquiry]) => {
        return {
          prukidAmount: prukid.body.accountGroups?.reduce((sum, x) => x.amount + sum, 0) || 0,
          principalAmount: loanEnquiry.subfileOccurs.reduce((sum, x) => sum + x.currentBalance, 0),
          interestAmount: loanEnquiry.subfileOccurs.reduce((sum, x) => sum + x.accuredInterest + x.pendingInterest, 0)
        }
      })
    )

  export const getAccess = (policyNum: string): Task<PruKid369Model.AccessData> =>
    pipe(
      ZIO.zipPar(
        POApi.post(`wf-api/bo/policy-extra-info`)(
          t.type({
            body: t.type({
              policyExtraInfoDetails: t.array(
                t.type({
                  productCode: t.string
                })
              )
            })
          })
        )({
          body: {
            policies: [policyNum]
          }
        }),
        POApi.post(`wf-api/policy/account-balance`)(
          t.type({
            body: t.type({
              accountGroups: t.array(
                t.type({
                  amount: t.number,
                  sacsCode: t.string,
                  sacsType: t.string
                })
              )
            })
          })
        )({
          body: {
            policyNum,
            function: 'TOTAL',
            accountGroups: [
              {
                sacsCode: 'LX',
                sacsType: 'EX'
              }
            ]
          }
        })
      ),
      ZIO.map(([code, balance]) => {
        const checkerCode = code.body.policyExtraInfoDetails.some((x) =>
          ['KID3', 'KID6', 'KID9'].includes(x.productCode)
        )
        const checkerBalance =
          balance.body.accountGroups.reduce(
            (sum, x) => (x.sacsCode === 'LX' && x.sacsType === 'EX' ? sum + x.amount : sum),
            0
          ) > 0
        return {
          checkerCode,
          checkerBalance
        }
      })
    )

  export const submit =
    (policyNum: string, data: PruKid369Model.SubmitData) => (requestauth: RequestAuthFormDataSubmit) =>
      SubmissionService.submit(t.unknown)(
        `wf-api/policy/${policyNum}/pru-kid`,
        { body: data },
        policyNum,
        requestauth,
        []
      )
}
