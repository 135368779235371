import React, { useState, useContext, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { OBTabView, OBTabViewInfo } from '../ob-common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { OBUploadFile } from './OBUploadFile'
import { OBFileUploadedHistory } from './OBFileUploadedHistory'
import { OBFileUploadedDetailHistory } from './OBFileUploadedDetailHistory'
import { AppContext, AuthState, ErrorHandling, OBImportFilePermission, RBAC, SelectOption } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { OBImportFileService } from '../ob-service'
import { OBImportFileContext } from './OBImportFileContext'
import { OBImportPromotionFiles } from './OBImportPromotionFiles'
import { OBExportPromotionData } from './OBExportPromotionData'

const obImportFileTranslate = 'Outbound:OBImportFile.Tabs'

export const OBImportFileScreen = () => {
  const { t, i18n } = useTranslation()
  const isFocused = useIsFocused()
  const navigation = useNavigation()

  const [permissions, setPermissions] = useState<string[]>([])
  const [isActiveTabIndex, setIsActiveTabIndex] = useState<number>(0)
  const [tabList, setTabList] = useState<OBTabViewInfo[]>([])
  const [isUploadImportfile, setIsUploadImportfile] = useState<boolean>(false)

  const { changeBreadcrumb } = useContext(AppContext.AppContextInstance)
  const authData = pipe(AuthState.auth.get)

  /**
   * Fetch
   */

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: 'Export CERT history',
          navigate: null
        }
      ])
    }
    return () => {
      setIsUploadImportfile(false)
    }
  }, [isFocused])

  pipe(
    RBAC.permissions,
    ZIO.tap((permissions) => {
      const listTabPermission: OBTabViewInfo[] = [
        ...(permissions.includes(OBImportFilePermission.ViewImportFileAdhoc)
          ? [
              {
                tabName: t(`${obImportFileTranslate}.ImportFile`),
                tabKey: 0
              }
            ]
          : []),

        ...(permissions.includes(OBImportFilePermission.ViewFileImportHistoryAdhoc)
          ? [
              {
                tabName: t(`${obImportFileTranslate}.FileImportHistory`),
                tabKey: 1
              }
            ]
          : []),

        ...(permissions.includes(OBImportFilePermission.ViewDetailedFileImportHistoryAdhoc)
          ? [
              {
                tabName: t(`${obImportFileTranslate}.DetailedFileImportHistory`),
                tabKey: 2
              }
            ]
          : []),
        //wait to set permission
        ...(permissions.includes(OBImportFilePermission.ViewImportFilePromotion)
          ? [
              {
                tabName: t(`${obImportFileTranslate}.ImportPromotionFile`),
                tabKey: 3
              }
            ]
          : []),
        ...(permissions.includes(OBImportFilePermission.ViewExportPromotionData)
          ? [
              {
                tabName: t(`${obImportFileTranslate}.ExportPromotionData`),
                tabKey: 4
              }
            ]
          : [])
      ]

      setTabList(listTabPermission)
      setPermissions([...permissions])
      return ZIO.unit
    }),
    ErrorHandling.runDidUpdate([authData, i18n.language])
  )

  const { AdhocNameOption, AdhocImportTemplates } = pipe(
    OBImportFileService.getListTemplateImportFile(),
    ZIO.map((res) => {
      const adhocName: SelectOption[] = res.map((elm) => ({
        value: elm.adhocCode ?? '',
        label: elm.adhocName ?? ''
      }))

      // setAdhocNameOption(adhocName)
      return {
        AdhocNameOption: adhocName,
        AdhocImportTemplates: res
      }
    }),
    ErrorHandling.runDidUpdate([isUploadImportfile, i18n.language])
  ) || {
    AdhocNameOption: [],
    AdhocImportTemplates: []
  }

  /**
   * Functions
   */

  const getTabByActionIndex = () => {
    switch (isActiveTabIndex) {
      case 0:
        return <OBUploadFile permissions={permissions} />
      case 1:
        return <OBFileUploadedHistory permissions={permissions} />
      case 2:
        return <OBFileUploadedDetailHistory permissions={permissions} />
      case 3:
        return <OBImportPromotionFiles permissions={permissions} />
      case 4:
        return <OBExportPromotionData permissions={permissions} />
      default:
        return <></>
    }
  }

  /**
   * Views
   */

  return (
    <OBImportFileContext.Provider
      value={{
        AdhocNameList: AdhocNameOption,
        AdhocImportTemplates: AdhocImportTemplates,
        setIsUploadImportfile: setIsUploadImportfile
      }}
    >
      <View style={importStyles.container}>
        <OBTabView isActiveTabIndex={isActiveTabIndex} setIsActiveTabIndex={setIsActiveTabIndex} data={tabList} />
        {getTabByActionIndex()}
      </View>
    </OBImportFileContext.Provider>
  )
}
const importStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 16
  }
})
