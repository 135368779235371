import { TransactionType } from '../TransactionType'
import * as ApiResponses from '../task-detail-api'

export interface ProductOptionSwitching {
  tag: TransactionType.PRODUCT_OPTION_SWITCHING
  converageCode: string
  newBenefitOption: string
  benefitOption: string
  oldBenefit: string
  currentSumAssured: number
  curRenewalPremium: number
  newSumAssured: number
}

export const ProductOptionSwitching = (
  detail: ApiResponses.ProductOptionSwitching,
  value: {
    converageCode: string
    benefitOption: string
    currentSumAssured: number
    curRenewalPremium: number
  }
): ProductOptionSwitching => {
  const payloadData = detail.payload.body
  return {
    tag: TransactionType.PRODUCT_OPTION_SWITCHING,
    newBenefitOption: detail.payload.body.benefits,
    ...value,
    oldBenefit: payloadData.oldBenefit,
    newSumAssured: payloadData.newSumAssured
  }
}
