/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  FieldList,
  FileUploadData,
  form2,
  ImgUploadMutiple,
  Input,
  Panel,
  useMobile,
  assets,
  SelectSearch,
  TransactionType,
  AppContext
} from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { AgentMovementForm } from './agent-movement-form'
import { AgentConst, InfoAgentProps, SC, SubmissionAgentService, UploadedFilesInfo } from '../common'
import { PolicyServicePayload } from '../AgentWrapScreen'
import { Link } from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import i18next from 'i18next'
import * as _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
export interface AgentRef {
  clearForm: () => void
  submit: () => void
}
interface Props {
  isContinue: (val: boolean) => void
  isTab: boolean
  onCheckValid: (value: number) => void
  dataForm: (data: PolicyServicePayload) => void
  agentNum: string
  setErrMessage: (value: string) => void
  officeCode: string
  setTransaction: (val: string) => void
}

export const AgentMovementScreen = React.forwardRef<AgentRef, Props>(
  (
    { isContinue, isTab = false, onCheckValid, agentNum, dataForm, officeCode, setErrMessage, setTransaction },
    ref: React.Ref<AgentRef>
  ) => {
    const [dataGet, setDataGet] = useState<SubmissionAgentService.TerminationDetail>()
    const [hideOffice, setHideOffice] = useState<boolean>(false)
    const [hideUnit, setHideUnit] = useState<boolean>(false)
    const [officeDetail, setOfficeDetail] = useState<SubmissionAgentService.OfficeInfo>()
    const { isMobile } = useMobile()
    const { t } = useTranslation()
    const { showToast } = React.useContext(AppContext.AppContextInstance)
    const [isLoading, bindLoader] = useLoading(false)

    const form = form2.useForm(AgentMovementForm.codec, {
      defaultValues: {
        AgentCode: '',
        OfficeCodeNew: { officeNew: '', officeOld: '' },
        UnitCodeNew: { unitNew: '', unitOld: '' },
        UnitNameNew: '-',
        BranchCodeNew: { branchNew: '', branchOld: '' },
        BranchNameNew: '-',
        officeReason: null,
        leaderReason: null,
        dataPayload: {
          agent: {},
          newBranch: {},
          newUnitLeader: {},
          newOffice: {}
        },
        officeFiles: [],
        leaderFiles: [],
        agentType: ''
      }
    })

    const {
      handleSubmit,
      base: {
        control,
        reset,
        formState: { errors },
        watch,
        getValues,
        setValue,
        setError,
        clearErrors
      }
    } = form

    const agentN = watch('AgentCode')
    const officeCodeN = watch('OfficeCodeNew.officeNew')
    const unitCodeN = watch('UnitCodeNew.unitNew')
    const branchCodeN = watch('BranchCodeNew.branchNew')

    const checkTerminateDate = (terDate: string) => {
      if (terDate && terDate !== '') {
        return true
      } else {
        return false
      }
    }

    const agentInfoList: InfoAgentProps[] = [
      {
        label: t('submission:TerminationDate'),
        value: dataGet?.terminateDate ?? '-',
        isHighlight: checkTerminateDate(dataGet?.terminateDate ?? '')
      },
      {
        label: t('submission:AgentType'),
        value: dataGet?.agentType ?? '-'
      },
      {
        label: t('submission:Title'),
        value: dataGet?.title ?? '-'
      },
      {
        label: t('submission:UnitDes'),
        value: dataGet?.unitDescription ?? '-'
      },
      {
        label: t('submission:BranchDes'),
        value: dataGet?.branchDescription ?? '-'
      },
      {
        label: t('submission:OfficeDes'),
        value: dataGet?.officeDescription ?? '-'
      },
      {
        label: t('submission:RegionBDM'),
        value: dataGet?.regionDescription ?? '-'
      },
      {
        label: t('submission:SubzoneBDD'),
        value: dataGet?.subzoneDescription ?? '-'
      },
      {
        label: t('submission:ZoneCodeTH'),
        value: dataGet?.zoneDescription ?? '-'
      }
    ]

    const newOfficeList: FieldList.FieldType[] = [
      { label: t('submission:OfficeCodeN'), render: ({}) => OfficeField(), required: true },
      { label: t('submission:OfficeNameN'), value: officeDetail?.name ?? '-' },
      { label: t('submission:GACodeN'), value: officeDetail?.gaCode ?? '-' },
      { label: t('submission:OfficeAddressN'), value: officeDetail?.address ?? '-' },
      { label: t('submission:OfficeMovementReason'), render: ({}) => officeReasonField(), required: true }
    ]

    const newLeaderList: FieldList.FieldType[] = [
      { label: t('submission:UnitCodeN'), render: ({}) => LeaderField(), required: true },
      { label: t('submission:DesOfUnit'), render: ({}) => UnitNameField() },
      { label: t('submission:BranchCodeN'), render: ({}) => BranchField() },
      { label: t('submission:DesOfBranch'), render: ({}) => BranchNameField() },
      { label: t('submission:UnitMovementReason'), render: ({}) => unitReasonField(), required: true }
    ]

    const officeReasonField = () => {
      return (
        <Controller
          name={'officeReason'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <SelectSearch
                required={isTab}
                options={AgentConst.OfficeReasonList.map((item) => ({
                  value: item.value,
                  label: i18next.language === 'en' ? item.nameEn : item.nameVn
                }))}
                placeholder={t('common:Select')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? { value: '', label: '' }}
                errorMessage={error?.message}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                disabled={hideOffice}
              />
            )
          }}
        />
      )
    }
    const unitReasonField = (index?: number) => {
      return (
        <Controller
          name={'leaderReason'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <SelectSearch
                required={isTab}
                options={AgentConst.LeaderReasonList.map((item) => ({
                  value: item.value,
                  label: i18next.language === 'en' ? item.nameEn : item.nameVn
                }))}
                placeholder={t('common:Select')}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? { value: '', label: '' }}
                errorMessage={error?.message}
                popupIcon={<assets.ArrowDownDropdownIcon />}
                disabled={hideUnit}
              />
            )
          }}
        />
      )
    }

    const getAttachmentFiles = () => {
      let fileList: UploadedFilesInfo[] = []
      const fileUpload = form.base.getValues()
      const obTemplate = {
        transactionType: TransactionType.AGENT_OFFICE_MOVING,
        docTypeCode: 'DSA04',
        category: 'DS',
        agentNumber: fileUpload.AgentCode,
        officeCode: officeCode ?? ''
      }
      const files1 = fileUpload.officeFiles as FileUploadData[]
      const files2 = fileUpload.leaderFiles as FileUploadData[]
      const documentFiles1: UploadedFilesInfo = {
        ...obTemplate,
        uploadFiles: files1
      }
      const documentFiles2: UploadedFilesInfo = {
        ...obTemplate,
        uploadFiles: files2
      }
      fileList.push(documentFiles1)
      fileList.push(documentFiles2)
      return fileList
    }

    const onReset = () => {
      reset({
        AgentCode: agentNum,
        OfficeCodeNew: { officeNew: '', officeOld: '' },
        UnitCodeNew: { unitNew: '', unitOld: dataGet?.unitCode },
        UnitNameNew: '-',
        BranchCodeNew: { branchNew: '', branchOld: dataGet?.branchCode },
        BranchNameNew: '-',
        officeReason: null,
        leaderReason: null,
        dataPayload: {
          agent: dataGet,
          newBranch: {},
          newOffice: {},
          newUnitLeader: {}
        },
        officeFiles: [],
        leaderFiles: [],
        agentType: ''
      })
    }

    const onSubmit = handleSubmit((values) => {
      const checkValid = validToSubmit()
      if (checkValid) {
        const newOb = {
          agent: getValues().dataPayload?.agent,
          newBranch: getValues().dataPayload?.newBranch || null,
          newOffice: { ...getValues('dataPayload.newOffice'), officeMovementReason: getValues('officeReason.value') },
          newUnitLeader: {
            ...getValues('dataPayload.newUnitLeader'),
            unitMovementReason: getValues('leaderReason.value')
          }
        }
        dataForm({
          url: (agentNum: string) => `distribution-agents-service/distribution-service/registerFromPulseOp`,
          body: newOb,
          collerationId: agentNum,
          method: 'POST',
          transactionName:
            officeCodeN && officeCodeN.length > 0
              ? TransactionType.AGENT_OFFICE_MOVING
              : TransactionType.AGENT_UNIT_TRANSFER,
          transaction: TransactionType.AGENT_TERMINATION_AG,
          uploadedFilesInfo: getAttachmentFiles()
        })
        onCheckValid(1)
      }
    })

    const validToSubmit = () => {
      const isValidOffice = validateOfficeCode()
      const isValidUnit = validateUnitCode()
      if (!officeCodeN) {
        if (!getValues('BranchCodeNew.branchNew') && getValues('UnitCodeNew.unitNew')) {
          return isValidUnit && ValidLeaderReasonAndFiles()
        } else if (getValues('BranchCodeNew.branchNew') && !getValues('UnitCodeNew.unitNew')) {
          return validateBranchCode() && ValidLeaderReasonAndFiles()
        } else {
          return false
        }
      } else {
        return isValidOffice && ValidOfficeReasonAndFiles()
      }
    }

    useImperativeHandle(ref, () => ({
      clearForm: onReset,
      submit: onSubmit
    }))

    const validateOfficeCode = () => {
      const officeValue = getValues('OfficeCodeNew.officeNew')
      if (officeValue.toUpperCase() === dataGet?.officeCode) {
        setError('OfficeCodeNew', { message: t('submission:ChooseOtherOffice') })
        return false
      } else if (officeValue.length > 0 && officeValue.length < 3) {
        setError('OfficeCodeNew', { message: t('submission:ValidMessage') })
        return false
      }
      return true
    }

    const validateUnitCode = () => {
      const unitValue = getValues('UnitCodeNew.unitNew')
      if (unitValue.toUpperCase() === dataGet?.unitCode) {
        setError('UnitCodeNew', { message: t('submission:ChooseOtherUnit') })
        return false
      } else if (unitValue.length > 0 && unitValue.length < 3) {
        setError('UnitCodeNew', { message: t('submission:ValidMessage') })
        return false
      } else {
        clearErrors('UnitCodeNew')
        return true
      }
    }

    const validateBranchCode = () => {
      const branchValue = getValues('BranchCodeNew.branchNew')
      const type = getValues('agentType')
      if (dataGet && !['PUM', 'UM', 'BM'].includes(type ?? '')) {
        setError('BranchCodeNew', { message: t('submission:BranchCheckAgentType') })
        return false
      } else {
        clearErrors('BranchCodeNew')
        if (branchCodeN.toUpperCase() === dataGet?.branchCode) {
          setError('BranchCodeNew', { message: t('submission:ChooseOtherBranch') })
          return false
        } else if (branchValue.length > 0 && branchValue.length < 3) {
          setError('BranchCodeNew', { message: t('submission:ValidMessage') })
          return false
        }
        return true
      }
    }

    const ValidLeaderReasonAndFiles = () => {
      const valueReason = getValues('leaderReason.label')
      const valueFiles = getValues('leaderFiles')
      if (!valueReason && valueFiles.length === 0) {
        setError('leaderReason', { message: t('submission:UnitReason') })
        setError('leaderFiles', { message: t('message:MS020001', { field: t('submission:FileAttachment') }) })
        return false
      } else if (valueReason && valueFiles.length === 0) {
        clearErrors('leaderReason')
        setError('leaderFiles', { message: t('message:MS020001', { field: t('submission:FileAttachment') }) })
        return false
      } else if (!valueReason && valueFiles.length > 0) {
        clearErrors('leaderFiles')
        setError('leaderReason', { message: t('submission:UnitReason') })
        return false
      } else {
        clearErrors(['leaderReason', 'leaderFiles'])
        return true
      }
    }

    const ValidOfficeReasonAndFiles = () => {
      const valueReason = getValues('officeReason.label')
      const valueFiles = getValues('officeFiles')
      if (!valueReason && valueFiles.length === 0) {
        setError('officeReason', { message: t('submission:OfficeReason') })
        setError('officeFiles', { message: t('message:MS020001', { field: t('submission:FileAttachment') }) })
        return false
      } else if (valueReason && valueFiles.length === 0) {
        clearErrors('officeReason')
        setError('officeFiles', { message: t('message:MS020001', { field: t('submission:FileAttachment') }) })
        return false
      } else if (!valueReason && valueFiles.length > 0) {
        clearErrors('officeFiles')
        setError('officeReason', { message: t('submission:OfficeReason') })
        return false
      } else {
        clearErrors(['officeReason', 'officeFiles'])
        return true
      }
    }

    React.useMemo(() => {
      if (agentN && agentN.length > 7) {
        return pipe(
          SubmissionAgentService.agentGetDetail(agentN),
          ZIO.tap((res) => {
            if (res) {
              form.base.setValue('OfficeCodeNew.officeOld', res.officeCode)
              form.base.setValue('UnitCodeNew.unitOld', res?.unitCode ?? '')
              form.base.setValue('BranchCodeNew.branchOld', res?.branchCode ?? '')
              setDataGet(res ?? {})
              setValue('agentType', res?.agentType ?? '')
              setValue('dataPayload.agent', res || {})
              if (res.terminateDate) {
                setErrMessage(t('submission:TerminateDateErr'))
              } else {
                setErrMessage('')
              }
            } else {
              setDataGet(undefined)
              showToast('Agent is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Agent is not found', 'info')
            setDataGet(undefined)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        setDataGet(undefined)
        return
      }
    }, [agentN])

    React.useMemo(() => {
      if (officeCodeN && officeCodeN.length > 2 && officeCodeN.toUpperCase() !== dataGet?.officeCode) {
        return pipe(
          SubmissionAgentService.getOfficeInfo(officeCodeN.toUpperCase()),
          ZIO.tap((res) => {
            if (res) {
              setOfficeDetail(res ?? null)
              setValue('dataPayload.newOffice', res || null)
              clearErrors(['OfficeCodeNew', 'BranchNameNew', 'UnitNameNew'])
            } else {
              setOfficeDetail(null)
              showToast('Office is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Office is not found', 'info')
            setOfficeDetail(null)
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        setOfficeDetail(null)
        validateOfficeCode()
      }
    }, [officeCodeN])

    React.useMemo(() => {
      if (unitCodeN && unitCodeN.length > 2 && unitCodeN.toUpperCase() !== dataGet?.unitCode) {
        return pipe(
          SubmissionAgentService.getLeaderInfo(unitCodeN.toUpperCase()),
          ZIO.tap((res) => {
            if (res) {
              setValue('dataPayload.newUnitLeader', res ?? null)
              setValue('UnitNameNew', res.desc ?? '-')
              setValue('BranchNameNew', '-')
              clearErrors(['UnitCodeNew', 'UnitNameNew'])
            } else {
              showToast('Unit is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Unit is not found', 'info')
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        setValue('UnitNameNew', '')
        validateUnitCode()
      }
    }, [unitCodeN])

    React.useMemo(() => {
      if (branchCodeN && branchCodeN.length > 2 && branchCodeN.toUpperCase() !== dataGet?.branchCode) {
        return pipe(
          SubmissionAgentService.getBranchInfo(branchCodeN.toUpperCase()),
          ZIO.tap((res) => {
            if (res) {
              setValue('dataPayload.newBranch', res ?? null)
              setValue('BranchNameNew', res.desc ?? '-')
              setValue('UnitNameNew', '-')
              clearErrors(['BranchCodeNew', 'BranchNameNew'])
            } else {
              showToast('Branch is not found', 'info')
            }
            return ZIO.unit
          }),
          ZIO.tapError((_) => {
            showToast('Branch is not found', 'info')
            return ZIO.unit
          }),
          ZIO.unsafeRun({})
        )
      } else {
        setValue('BranchNameNew', '')
        validateBranchCode()
      }
    }, [branchCodeN])

    useEffect(() => {
      if (!!agentNum) {
        form.base.setValue('AgentCode', agentNum)
      }
    }, [])

    const reasonOffice = getValues('officeReason.label')
    const fileOffice = getValues('officeFiles')
    const reasonLeader = getValues('leaderReason.label')
    const fileLeader = getValues('leaderFiles')

    useEffect(() => {
      if (officeCodeN || reasonOffice || fileOffice.length > 0) {
        isContinue(false)
        setHideUnit(true)
        setHideOffice(false)
        setTransaction(`${TransactionType.AGENT_OFFICE_MOVING}-DSA04`)
      } else if (unitCodeN || branchCodeN || reasonLeader || fileLeader.length > 0) {
        isContinue(false)
        setHideOffice(true)
        setHideUnit(false)
        setTransaction(`${TransactionType.AGENT_UNIT_TRANSFER}-DSA03`)
      } else {
        isContinue(true)
        setHideUnit(false)
        setHideOffice(false)
      }
    }, [officeCodeN, unitCodeN, branchCodeN, reasonLeader, reasonOffice, fileLeader, fileOffice])

    const isDisableUnitField = () => {
      return !!branchCodeN && branchCodeN.length === 3
    }
    const isDisableBranchField = () => {
      return !!unitCodeN && unitCodeN.length === 3
    }

    const OfficeField = (index?: number) => {
      return (
        <Controller
          name={'OfficeCodeNew.officeNew'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <Input
                required={isTab}
                onChange={(e) => {
                  onChange(e)
                }}
                onBlur={onBlur}
                value={value ?? ''}
                maxLength={3}
                disabled={isTab || hideOffice}
                errorMessage={_.get(errors, 'OfficeCodeNew.message')}
              />
            )
          }}
        />
      )
    }

    const LeaderField = (index?: number) => {
      return (
        <Controller
          name={'UnitCodeNew.unitNew'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <Input
                required={isTab}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                maxLength={3}
                disabled={isTab || isDisableUnitField() || hideUnit}
                errorMessage={_.get(errors, 'UnitCodeNew.message')}
              />
            )
          }}
        />
      )
    }

    const UnitNameField = () => {
      return (
        <Controller
          name={'UnitNameNew'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <Input
                required={isTab}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? '-'}
                maxLength={3}
                disabled={true}
                errorMessage={_.get(errors, 'UnitNameNew.message')}
              />
            )
          }}
        />
      )
    }

    const BranchNameField = () => {
      return (
        <Controller
          name={'BranchNameNew'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <Input
                required={isTab}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? '-'}
                maxLength={3}
                disabled={true}
                errorMessage={_.get(errors, 'BranchNameNew.message')}
              />
            )
          }}
        />
      )
    }

    const BranchField = (index?: number) => {
      return (
        <Controller
          name={'BranchCodeNew.branchNew'}
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
            return (
              <Input
                required={isTab}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                maxLength={3}
                disabled={isTab || isDisableBranchField() || hideUnit}
                errorMessage={_.get(errors, 'BranchCodeNew.message')}
              />
            )
          }}
        />
      )
    }

    const downloadView = (type: string) => {
      return (
        <SC.BoldText>
          <Link
            href={`${type === 'office' ? assets.AgentOfficeTemplate : assets.AgentLeaderTemplate}`}
            target="_parent"
            download
          >
            <assets.Download />
          </Link>
        </SC.BoldText>
      )
    }

    return (
      <SafeAreaView style={{ flex: 1 }}>
        <View style={styles.container}>
          <Panel title={t('submission:AgentInformation')} isChangeIcon={true}>
            <View style={[isMobile ? styles.rowMobile : styles.row]}>
              <View style={isMobile ? styles.colFull : styles.col}>
                <Controller
                  control={control}
                  name={'AgentCode'}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <Input
                        title={t('submission:AgentNumber')}
                        value={value}
                        onChange={onChange}
                        maxLength={8}
                        onBlur={onBlur}
                        required={true}
                        disabled={isTab}
                        errorMessage={error?.message}
                      />
                    )
                  }}
                />
              </View>
              <View style={isMobile ? styles.colFull : styles.col}>
                <View>
                  <Text style={[isMobile ? styles.labelMobile : styles.label]}>{t('submission:AgentName')}</Text>
                  <Text style={[styles.value]}>{dataGet?.agentName}</Text>
                </View>
              </View>
              <View style={isMobile ? styles.colFull : styles.col}>
                <View>
                  <Text style={[isMobile ? styles.labelMobile : styles.label]}>{t('submission:AppointedDate')}</Text>
                  <Text style={[styles.value]}>{dataGet?.appointedDate}</Text>
                </View>
              </View>
            </View>
            <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
              <FieldList dataSource={agentInfoList} />
            </View>
          </Panel>
          {/* new office infomation */}
          {!hideOffice && (
            <Panel title={t('submission:OfficeInfoN')} isChangeIcon={true}>
              <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
                <FieldList dataSource={newOfficeList} />
                <View style={[isMobile ? styles.rowMobile : styles.row]}>
                  <View style={isMobile ? styles.colFull : styles.col}>
                    <Input
                      title={t('ReleasePolicy:DownloadFile')}
                      value={'MovingOfficeRequestForm.pdf'}
                      disabled={true}
                      alwayShowUnderline={false}
                      suffix={downloadView('office')}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.colFull}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={[styles.label]}>{t('CFI:CFIFileAttachment')}</Text>
                  <Text style={[styles.requiredSub]}>*</Text>
                </View>
                <Controller
                  control={control}
                  name="officeFiles"
                  rules={{ required: { value: true, message: 'Field is required' } }}
                  render={({ field, fieldState: { error } }) => (
                    <Column>
                      <ImgUploadMutiple
                        {...field}
                        disabled={isTab}
                        onChange={field.onChange}
                        value={field.value as FileUploadData[]}
                        errorMessage={error?.message}
                      />
                      {error?.message && (
                        <Error message={!!field.value && field.value.length > 0 ? '' : error?.message} />
                      )}
                    </Column>
                  )}
                />
              </View>
            </Panel>
          )}

          {/* new leader infomation */}
          {!hideUnit && (
            <Panel title={t('submission:UnitLeaderInfoN')} isChangeIcon={true}>
              <View style={{ marginHorizontal: isMobile ? -16 : 0 }}>
                <FieldList dataSource={newLeaderList} />
                <View style={[isMobile ? styles.rowMobile : styles.row]}>
                  <View style={isMobile ? styles.colFull : styles.col}>
                    <Input
                      title={t('submission:DownloadTemplate')}
                      value={'TransferUnitRequestForm.pdf'}
                      disabled={true}
                      alwayShowUnderline={false}
                      suffix={downloadView('')}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.colFull}>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={[styles.label]}>{t('CFI:CFIFileAttachment')}</Text>
                  <Text style={[styles.requiredSub]}>*</Text>
                </View>
                <Controller
                  control={control}
                  name="leaderFiles"
                  rules={{ required: { value: true, message: 'Field is required' } }}
                  render={({ field, fieldState: { error } }) => (
                    <Column>
                      <ImgUploadMutiple
                        {...field}
                        disabled={isTab}
                        onChange={field.onChange}
                        value={field.value as FileUploadData[]}
                        errorMessage={error?.message}
                      />
                      {error?.message && (
                        <Error message={!!field.value && field.value.length > 0 ? '' : error?.message} />
                      )}
                    </Column>
                  )}
                />
              </View>
            </Panel>
          )}
        </View>
      </SafeAreaView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 'auto',
    justifyContent: 'center',
    display: 'flex'
  },
  row: {
    flexDirection: 'row',
    marginBottom: 32
  },
  rowMobile: {
    flexDirection: 'column',
    marginBottom: 0
  },
  col: {
    width: '33.33333%',
    marginTop: 32,
    paddingRight: 16
  },
  colFull: {
    width: '100%',
    paddingRight: 16,
    marginBottom: 16
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  highLight: { color: '#eD1B2E' },
  requiredSub: {
    color: '#eD1B2E',
    marginLeft: 5,
    fontSize: 15,
    fontWeight: 'bold'
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  },
  rowFooter: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 30
  },
  labelMobile: {
    color: '#70777E',
    fontSize: 15,
    lineHeight: 22
  },
  titleb: {
    fontSize: 15,
    fontWeight: 'bold',
    color: 'grey'
  }
})
