import { ModalComponent, PulseOpsFormat, assets } from '@pulseops/common'
import { IBGeneralTable, IBStyles } from '../../../../../common'
import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text, Pressable, TouchableOpacity } from 'react-native'
import { IBDataDetailModal } from './IBDataDetailModal'
import { useLoading } from '@mxt/zio-react'
import { pipe } from 'fp-ts/lib/function'
import { IBService, NBUWInfoResponse, UWRequestDetail } from '../../../../../ib-service'
import { ZIO } from '@mxt/zio'
import _, { isNull } from 'lodash'
import moment from 'moment'

type IssueAndReinRequestResponse = {
  requestedDate: string | null | undefined,
  status: string | null | undefined,
  description: string | null | undefined
}

type MeRequestResponse = {
  provinceCode: string | null | undefined,
  provinceName: string | null | undefined,
  meServiceLevel: string | null | undefined,
  panelDoctor: string | null | undefined,
  suspendRemark: string | null | undefined,
  noteForPanelDoctor: string | null | undefined,
  noteForCustomer: string | null | undefined
}

type CodeNimData = {
  requestDate: string | null | undefined,
  receiveDate: string | null | undefined,
  receive2Date: string | null | undefined,
  remark: string | null | undefined
}

type NBUWNoteList = {
  eventDate: string | null | undefined,
  policyNumber: string | null | undefined,
  diseaseCode: string | null | undefined,
  uwNote: string,
  createdDate: string | null | undefined,
  lasUserId: string | null | undefined
}

export const IBNBUWTab = (
  props: {
    policyNumber: string,
    nbuwInfo: NBUWInfoResponse | null,
    setNbuwInfo: (data: NBUWInfoResponse) => void
  }) => {
  const { t } = useTranslation('Inbound')
  const [loading, bindLoading] = useLoading(false)

  const [issueRequest, setOpenIssueRequest] = React.useState<{ isOpen: boolean, indexOpen?: number }>({ isOpen: false })
  const [reinRequest, setOpenReinRequest] = React.useState<{ isOpen: boolean, indexOpen?: number }>({ isOpen: false })
  const [meRequest, setOpenMeRequest] = React.useState<{ isOpen: boolean, indexOpen?: number }>({ isOpen: false })
  const [isOpenUWDeHistory, setOpenUWDeHistory] = React.useState<boolean>(false)
  const [conditionalAccept, setOpenConditionalAccept] = React.useState<{ isOpen: boolean, indexOpen?: number }>({ isOpen: false })
  const [isOpenCodeNIM, setOpenCodeNIM] = React.useState<boolean>(false)
  const [noteDetail, setOpenNoteDetail] = React.useState<{ isOpen: boolean, contentNoteDetail: string }>({ isOpen: false, contentNoteDetail: '' })

  const [noteDataList, setNoteDataList] = React.useState<{ noteData: NBUWNoteList[], id: string, toggle: boolean }[]>([])
  const listRoleOpenMUI = ['PO', 'LA']
  const listRoleHideInfo = ['PO', 'AG']
  const listUWDecisionEnableReason = ['Decline', 'NTU', 'Postpone', 'Withdraw']

  React.useEffect(() => {
    if (!props.nbuwInfo && props.policyNumber) {
      pipe(
        IBService.getNBUWInfo(props.policyNumber),
        ZIO.map((res) => {
          res.data?.uwRequests?.sort((pre, cur) => {
            if(pre.requestRole && cur.requestRole) {
              if(pre.requestRole === 'PO' && cur.requestRole !== 'PO') return -1
              else if(pre.requestRole === 'AG' && cur.requestRole !== 'PO') return -1
              else if(pre.requestRole === 'LA' && cur.requestRole === 'LA' && Number(pre.lifeNo) < Number(cur.lifeNo)) return -1
            }
            return 1
          })
          res && props.setNbuwInfo(res)
          res.data?.uwRequests && setNoteDataList(res.data.uwRequests.map((i, index) => ({
            id: `id${index}`,
            noteData: [],
            toggle: false
          })))
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
    else {
      props.nbuwInfo && props.nbuwInfo.data?.uwRequests && setNoteDataList(props.nbuwInfo.data.uwRequests.map((i, index) => ({
        id: `id${index}`,
        noteData: [],
        toggle: false
      })))
    }
  }, [props.policyNumber])

  const checkCodeNIM = () => {
    let value = (props.nbuwInfo?.data?.transactionNotes && props.nbuwInfo?.data?.transactionNotes.length > 0) ? true : false
    props.nbuwInfo?.data?.transactionNotes?.map((i) => {
      if(!(i.fupCode === 'NIM' && isNull(i?.receiveDate) && isNull(i?.receive2Date))){
        value = false
      }
    })
    return value
  }
  const dataCommonField = [
    {
      title: t('ProposalNo'),
      value: props.nbuwInfo?.data?.proposalNumber ?? '-'
    },
    {
      title: t('CodeNIM'),
      value: checkCodeNIM() ? t('common:Yes') : t('common:No'),
      onClickIcon: props?.nbuwInfo?.data?.transactionNotes ? (
        <Pressable
          onPress={() => setOpenCodeNIM(true)}
        >
          <assets.IBChevronRightCircle />
        </Pressable>
      ) : <></>
    },
    {
      title: t('ProposalUWDecision'),
      value: props.nbuwInfo?.data?.proposalFinalUWDecision ?? '-',
      onClickIcon: (
        <Pressable
          onPress={() => setOpenUWDeHistory(true)}
        >
          <assets.IBChevronRightCircle />
        </Pressable>
      )
    },
    {
      title: t('Reason'),
      value: props.nbuwInfo?.data?.reason ?? '-',
      hidden: props.nbuwInfo?.data?.proposalFinalUWDecision ? !listUWDecisionEnableReason.includes(props.nbuwInfo?.data?.proposalFinalUWDecision) : true
    },
    {
      title: t('RemarkContact'),
      value: props.nbuwInfo?.data?.remarkForCallCenter ?? '-'
    }
  ]

  const dataUserUWInfoView = (data: UWRequestDetail | undefined) => [
    {
      title: t('Role'),
      value: (data?.requestRole === 'LA' && data?.lifeNo) ? (`LA${data?.lifeNo}`) : (data?.requestRole ?? '-')
    },
    !listRoleHideInfo.includes(data?.requestRole ?? '') && {
      title: t('Status'),
      value: data?.status ?? '-'
    },
    {
      title: t('ClientNo'),
      value: data?.clientId ?? '-'
    },
    {
      title: t('Gender'),
      value: data?.gender ?? '-'
    },
    data?.requestRole !== 'AG' && {
      title: t('AgeUser'),
      value: data?.dob ? PulseOpsFormat.getAgeFromDate(data?.dob, 'YYYY/MM/DD') : '-'
    },
    !listRoleHideInfo.includes(data?.requestRole ?? '') && {
      title: t('UWDecision'),
      value: data?.uwDecision ?? '-'
    }
  ] || []

  const dataUserUWInfoLink = (data: UWRequestDetail, index: number) => [
    !listRoleHideInfo.includes(data.requestRole ?? '') && data.meRequestDetails && data.meRequestDetails?.length > 0 && {
      title: t('MERequest'),
      value: '-',
      gridColumn: 2,
      onClick: () => setOpenMeRequest({ isOpen: true, indexOpen: index })
    },
    data.issueRequestDetails && data.issueRequestDetails?.find((i) => i.status === 'Active') && {
      title: t('ISRequest'),
      value: '-',
      gridColumn: 4,
      onClick: () => setOpenIssueRequest({ isOpen: true, indexOpen: index })
    },
    !listRoleHideInfo.includes(data.requestRole ?? '') && data.reinRequestDetails && data.reinRequestDetails?.find((i) => i.status === 'Active') && {
      title: t('ReinRequest'),
      value: '-',
      gridColumn: 4,
      onClick: () => setOpenReinRequest({ isOpen: true, indexOpen: index })
    },
    data.requestRole === 'LA' &&
    (props.nbuwInfo?.data?.proposalFinalUWDecision === 'Conditional Accept' || props.nbuwInfo?.data?.proposalFinalUWDecision === 'NTU') &&
    (!isNull(data.uwDecision) && data.uwDecision !== 'Accept') &&
    {
      title: t('ConditionalAccept'),
      value: '-',
      gridColumn: 2,
      onClick: () => setOpenConditionalAccept({ isOpen: true, indexOpen: index })
    }
  ]

  const columnUWNote = [
    { 
      label: t('EventDate'),
      field: 'eventDate',
      format: 'date'
    },
    { 
      label: t('PolicyNo'),
      field: 'policyNumber'
    },
    { 
      label: t('DiseaseCode'), 
      field: 'diseaseCode' 
    },
    {
      label: t('Notes'),
      field: 'uwNote',
      render: (value: string, index: number) => {
        return(
          <View>
            <Text style={{ fontSize: 15 }}>{value ? value.split('\n')[0] : ''}</Text>
            {value && value.split('\n')[1] && 
              <Pressable
                onPress={() => setOpenNoteDetail({
                  isOpen: true,
                  contentNoteDetail: value ?? ''
                })}
                style={{ flexDirection: 'row'}}
              >
                <Text
                  style={{ fontWeight: '600', fontSize: 15, textDecorationLine: 'underline', flex: 1, color: '#09A4D8' }}
                  numberOfLines={1}
                >
                  {t('ViewMore')}
                </Text>
              </Pressable>
            }
          </View> 
        )
      }
    },
    { 
      label: t('CreateDate'), 
      field: 'createdDate'
    },
    { 
      label: t('UserID'), 
      field: 'lasUserId'
    }
  ]

  const columnNBRequest = [
    { label: t('Role'), field: 'requestRole' },
    { label: t('RoleName'), field: 'roleName' },
    { label: t('RequestStatus'), field: 'requestStatus' },
    { label: t('Remarks'), field: 'remarks', minWidthCell: { minWidth: 180, lengthSet: 20 }},
    { label: t('CreateDate'), field: 'createdDate'},
    { label: t('CreateBy'), field: 'createdBy' },
    { label: t('LastUpdatedDate'), field: 'lastUpdatedDate' },
    { label: t('LastUpdatedBy'), field: 'lastUpdatedBy' }
  ]

  const columnUWDesicionHistory = [
    { label: t('DateTime'), field: 'createDate' },
    { label: t('UWDecisions'), field: 'uwDecision' },
    { label: t('UserId'), field: 'lasUserId' },
  ]

  const columnTableIssueRequest = [
    { label: t('ISRequestDate'), field: 'requestedDate' },
    { label: t('ISStatus'), field: 'status' },
    { label: t('ISDescription'),
      field: 'description',
      render: (value: string, index: number) => {
        const listData = value && value.split('/n')
        return (
          <View>
            {
              listData && listData.map((i) => 
                <Text style={{ fontSize: 15 }}>{i}</Text>
              )
            }
          </View>
        )
      }
    }
  ]

  const columnTableReinRequest = [
    { label: t('ReinRequestDate'), field: 'requestedDate' },
    { label: t('ReinStatus'), field: 'status' },
    { label: t('ReinDescription'),
      field: 'description',
      render: (value: string, index: number) => {
        const listData = value && value.split('/n')
        return (
          <View>
            {
              listData && listData.map((i) => 
                <Text style={{ fontSize: 15 }}>{i}</Text>
              )
            }
          </View>
        )
      }
    }
  ]

  const columnTableMeRequest = [
    { label: t('Province'), field: 'provinceName' },
    { label: t('PanelDoctor'), field: 'panelDoctor' },
    { label: t('MEServiceLevel'), field: 'meServiceLevel' },
    { label: t('SuspendRemark'), field: 'suspendRemark' },
    { label: t('NotesPanelDoctor'), field: 'noteForPanelDoctor' },
    { label: t('NotesCustomer'), field: 'noteForCustomer' }
  ]

  const columnTableCodeNIM = [
    { label: t('RequestedDate'), field: 'requestDate' },
    { label: t('ReceivedDate1'), field: 'receiveDate' },
    { label: t('ReceivedDate2'), field: 'receive2Date' },
    { label: t('Remark'), field: 'remark' },
  ]

  const setToggle = (toggleIndex: number, toggle: boolean, clientNumber: string) => {
    let temp = noteDataList
    if (toggleIndex !== -1 && temp[toggleIndex].noteData.length === 0) {
      temp[toggleIndex].toggle = !toggle
      !toggle && getNoteDetail(toggleIndex, toggle, clientNumber)
      toggle && setNoteDataList([...temp])
    }
    else {
      temp[toggleIndex].toggle = !toggle
      setNoteDataList([...temp])
    }
  }

  const getNoteDetail = (index: number, toggle: boolean, clientNumber: string) => {
    // const findIndexItem = noteDataList.findIndex((i) => i.id === index)
    let temp = noteDataList
    if (index !== -1 && noteDataList[index]?.noteData.length === 0) {
      pipe(
        IBService.getNoteNBUWInfo(clientNumber),
        ZIO.map((res) => {
          if (index !== -1 && res.data) {
            temp[index].noteData = res.data.map((i) => ({
              ...i,
              uwNote: i.uwNote ?? ''
            }))
          }
          temp[index].toggle = !toggle
          setNoteDataList([...temp])
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
    else {
      temp[index].toggle = !toggle
      setNoteDataList([...temp])
    }
  }

  const NoteDetail = (clientNumber: string, toggleIndex: number, toggle: boolean) => {
    const dataNote = noteDataList[toggleIndex]?.noteData.map((i) => ({
      ...i,
      eventDate: moment(i.eventDate, 'DD/MM/YYYY').format('YYYYMMDD')
    }))?.sort((p1,p2) => (p1.eventDate && p2.eventDate && moment(p1.eventDate).startOf('days').diff(moment(p2.eventDate).startOf('days'), 'days') > 0) ? 1 : -1 )
    const title = t('UWNote')
    const children = (
      <IBGeneralTable
        dataTable={columnUWNote}
        data={toggleIndex !== -1 ? dataNote : []}
        loading={loading}
      />
    )
    return (
      <View>
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => setToggle(toggleIndex, toggle, clientNumber)}
            style={{ marginRight: 20 }}
            disabled={_.isUndefined(children) || _.isUndefined(toggle)}
          >
            {toggle ? <assets.IBCollapseIcon /> : <assets.IBExpandIcon />}
          </TouchableOpacity>
          <Text style={styles.titleText}>{title}</Text>
        </View>
        {toggle ? (
          <View style={{ marginTop: 15 }}>
            <Suspense fallback={<>Loading...</>}>{children}</Suspense>
          </View>
        ) : (
          <></>
        )}
        {ModalNoteNbuw()}
      </View>
    )
  }

  const CommonInfo = () => {
    const dataCodeNim = props?.nbuwInfo?.data?.transactionNotes
    return (
      <View style={IBStyles.container}>
        <View style={IBStyles.row}>
          {dataCommonField.map((i) => (
            !i.hidden && 
              <View style={[IBStyles.sectionCol4, { paddingBottom: 24, paddingRight: 8 }]}>
                <View style={{ flexDirection: 'row', marginTop: 5, marginBottom: 4 }}>
                  <Text style={{ fontWeight: '700', color: '#58647A', fontSize: 15 }}>{i.title}</Text>
                  <View style={{ marginLeft: 5 }}>{i.onClickIcon}</View>
                </View>
                <Text style={{ fontSize: 15, marginTop: 4 }}>{i.value}</Text>
              </View>
          ))}
        </View>
        {dataCodeNim && ModalCodeNIM(dataCodeNim.map((i) => ({
          requestDate: i?.requestDate,
          receiveDate: i?.receiveDate,
          receive2Date: i?.receive2Date,
          remark: i?.remark
        })))}
        {ModalUWDesicionHistory()}
      </View>
    )
  }

  const UserInfo = (dataUwRequest: UWRequestDetail, index: number) => {
    return (
      <>
        <View style={IBStyles.row}>
          {dataUserUWInfoView(dataUwRequest).map((i) => (
            i && <View style={[IBStyles.sectionCol2, { paddingBottom: 24 }]}>
              <Text style={{ fontWeight: '700', color: '#58647A', marginTop: 5, marginBottom: 4, fontSize: 15 }}>{i.title}</Text>
              <Text style={{ fontSize: 15, marginTop: 4 }}>{i.value}</Text>
            </View>
          ))}
        </View>
        <View style={IBStyles.row}>
          {dataUserUWInfoLink(dataUwRequest, index).map((i) => (
            i && <View style={[i.gridColumn === 2 ? IBStyles.sectionCol2 : IBStyles.sectionCol4, { paddingBottom: 24 }]}>
              <Pressable onPress={i.onClick} style={{width: 'fit-content'}}>
                <Text style={{ fontWeight: '500', color: '#09A4D8', fontSize: 15, textDecorationLine: 'underline' }}>{i.title}</Text>
              </Pressable>
            </View>
          ))}
        </View>
      </>
    )
  }

  const UWRequest = () => {
    return (
      <View>
        <Text style={[IBStyles.header, { paddingBottom: 10 }]}>{t('UWRequest')}</Text>
        {props.nbuwInfo?.data?.uwRequests && props.nbuwInfo?.data?.uwRequests.map((uwInfo, index) => {
          const toggleIndex = noteDataList.findIndex((i) => i.id === `id${index}`)
          const toggle = toggleIndex !== -1 ? noteDataList[toggleIndex]?.toggle : false
          return(
            <>
              <View style={IBStyles.container}>
                <View style={IBStyles.row}>
                  <Text style={[IBStyles.header, { paddingBottom: 10 }]}>{uwInfo.clientName}</Text>
                  {uwInfo.requestRole && listRoleOpenMUI.includes(uwInfo.requestRole) &&
                    <View style={{ marginLeft: 5 }}>
                      <Pressable 
                        onPress={() => {
                          const pathname = window.location.pathname
                          const splitStr = pathname.split('/')
                          const url = pathname.replace(`/${splitStr[splitStr.length - 1]}`, '')
                          window.open(`${url}/ib-dashboard?clientNumber=${uwInfo.clientId}`)
                        }}
                      >
                        <assets.IBChevronRightCircle />
                      </Pressable>
                    </View>
                  }
                </View>
                {UserInfo(uwInfo, index)}
                {uwInfo.requestRole !== 'AG' && NoteDetail(uwInfo.clientId ?? '', toggleIndex, toggle)}
              </View>
              {uwInfo.issueRequestDetails && ModalIssueRequest(uwInfo.issueRequestDetails, index)}
              {uwInfo.reinRequestDetails && ModalReinRequest(uwInfo.reinRequestDetails, index)}
              {uwInfo.meRequestDetails && ModalMeRequest(uwInfo.meRequestDetails, index)}
              {uwInfo.conditionAcceptContents && ModalConditionalAccept(uwInfo.conditionAcceptContents, index)}
            </>
          )}
        )}
      </View>
    )
  }

  const NBRequest = () => {
    return (
      <View>
        <Text style={[IBStyles.header, { paddingBottom: 10 }]}>{t('NBRequest')}</Text>
        <View style={IBStyles.container}>
          <IBGeneralTable
            dataTable={columnNBRequest}
            data={props.nbuwInfo?.data?.nbRequests ?? []}
            loading={loading}
            autoPaging={true}
            wrap
          />
        </View>
      </View>
    )
  }

  const ModalConditionalAccept = (content: string[] | null | undefined, index: number) => {
    return (
      <ModalComponent
        title={t('ConditionalAcceptDetail')}
        modalWidth={790}
        onClose={() => setOpenConditionalAccept({ isOpen: false })}
        visible={conditionalAccept.isOpen && index === conditionalAccept.indexOpen}
        actions={[]}
        children={
          <View style={[IBStyles.row, { paddingHorizontal: 20, alignItems: 'center' }]}>
            {content && content.length > 0 ?
              content.map((i) =>
                <Text style={{fontSize: 15}}>{i}</Text>
              ) :
              <Text style={{ textAlign: 'center', fontSize: 15 }}>{t('common:Nodata')}</Text>
            }
          </View>
        }
      />
    )
  }

  const ModalCodeNIM = (data: CodeNimData[]) => {
    return (
      <ModalComponent
        title={t('CodeNimDetail')}
        modalWidth={790}
        onClose={() => setOpenCodeNIM(false)}
        visible={isOpenCodeNIM}
        actions={[]}
        children={
          <View style={[IBStyles.row, { paddingHorizontal: 20, alignItems: 'center' }]}>
            <IBGeneralTable
              dataTable={columnTableCodeNIM}
              data={data}
              loading={loading}
              maxWidth={750}
              minWidth={750}
              wrap
            />
          </View>
        }
      />
    )
  }

  const ModalUWDesicionHistory = () => {
    return (
      <IBDataDetailModal
        title={t('UWDecisionHistory')}
        collumnDatable={columnUWDesicionHistory}
        dataShow={props.nbuwInfo?.data?.uwDecisionHistories ?? []}
        onClose={() => setOpenUWDeHistory(false)}
        open={isOpenUWDeHistory}
      />
    )
  }

  const ModalIssueRequest = (data: IssueAndReinRequestResponse[], index: number) => {
    const dataShow = data.filter((i, index) => { 
      return index === data.findIndex((x) => _.isEqual(x, i)) 
    })
    return (
      <IBDataDetailModal
        title={t('ISRequestDetail')}
        onClose={() => setOpenIssueRequest({ isOpen: false })}
        open={issueRequest.isOpen && index === issueRequest.indexOpen}
        collumnDatable={columnTableIssueRequest}
        dataShow={dataShow ?? []}
        wrap
      />
    )
  }

  const ModalReinRequest = (data: IssueAndReinRequestResponse[], index: number) => {
    const dataShow = data.filter((i, index) => { 
      return index === data.findIndex((x) => _.isEqual(x, i)) 
    })    
    return (
      <IBDataDetailModal
        title={t('ReinRequestDetail')}
        onClose={() => setOpenReinRequest({ isOpen: false })}
        open={reinRequest.isOpen && index === reinRequest.indexOpen}
        collumnDatable={columnTableReinRequest}     
        dataShow={dataShow ?? []}
      />
    )
  }

  const ModalMeRequest = (data: MeRequestResponse[], index: number) => {
    const dataShow = data.filter((i, index) => { 
      return index === data.findIndex((x) => _.isEqual(x, i)) 
    })
    return (
      <IBDataDetailModal
        title={t('MERequestDetail')}
        onClose={() => setOpenMeRequest({ isOpen: false })}
        open={meRequest.isOpen && index === meRequest.indexOpen}
        collumnDatable={columnTableMeRequest}
        dataShow={dataShow ?? []}
        wrap
      />
    )
  }

  const ModalNoteNbuw = () => {
    return (
      <ModalComponent
        title={t('DetailNote')}
        modalWidth={790}
        onClose={() => setOpenNoteDetail({
          isOpen: false,
          contentNoteDetail: ''
        })}
        visible={noteDetail.isOpen && !!noteDetail.contentNoteDetail}
        actions={[]}
        children={
          <View style={[IBStyles.row, { paddingHorizontal: 20 }]}>
            <Text style={{ fontSize: 15 }}>{noteDetail.contentNoteDetail ?? '-'}</Text>
          </View>
        }
      />
    )
  }

  return (
    <View style={styles.container}>
      {CommonInfo()}
      {UWRequest()}
      {NBRequest()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20
  },
  polInfoDataBoxContainer: {
    backgroundColor: '#FFF',
    borderRadius: 10,
    padding: 15,
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    flex: 1
  },
  polInfoDataBoxTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5
  },
  rowHeader: {
    flexDirection: 'row',
    flex: 1,
    gap: 20,
    marginTop: 10
  },
  titleText: {
    fontSize: 15,
    fontWeight: '700',
    color: '#1C1D1F'
  }
})  