import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { Maybe } from '@mxt/zio/codec'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.BILLING_FREQUENCY_REVERSAL)
    }),
    codec
  ])

const payload = t.type({
  body: t.type({
    curBillingFrequency: Maybe(t.string),
    oldBillingFreq: Maybe(t.string),
    oldBillingFrequency: Maybe(t.array(t.string)),
    automaticPolicyLoanInterest: Maybe(t.union([t.string, t.number])),
    payInAmount: Maybe(t.string)
  })
})

const payloadPulse4Ops = t.type({
  body: t.type({
    automaticPolicyLoanInterest: Maybe(t.string),
    automaticPolicyLoanInterestDate: Maybe(t.array(t.string)),
    curBillingFrequency: Maybe(t.string),
    curInstalmentAmount: Maybe(t.string),
    oldBillingFrequency: Maybe(t.array(t.string)),
    oldInstalmentAmount: Maybe(t.array(t.string)),
    payInAmount: Maybe(t.string),
    policyNo: Maybe(t.string)
  })
})

const payloadPruonline = payloadPulse4Ops
const payloadZalo = payloadPulse4Ops

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadZalo
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPruonline
  })
)

export const BillingChangeReversal = t.union([Ekiosk, Pulse, Pulse4Ops, Zalo, Banca, Ekiosk, PruGreat, PruOnline])
export type BillingChangeReversal = t.TypeOf<typeof BillingChangeReversal>
