import { Pressable, StyleSheet, Text, View } from 'react-native'
import React, { useContext } from 'react'
import { useLoading } from '@mxt/zio-react'
import { useTranslation } from 'react-i18next'
import { IBButton, IBGeneralField, IBGeneralTable, TypeInputComponent } from '../../common'
import { useForm } from 'react-hook-form'
import { isEmpty, isNil, isNull, orderBy, uniq } from 'lodash'
import { IBPolicyPermission, SelectOption, assets } from '@pulseops/common'
import moment from 'moment'
import { IBModalListingOfPendingApproval } from './IBModalListingOfPendingApproval'
import { pipe } from 'fp-ts/lib/function'
import { ClickToSendTaskInstanceData, IBService } from '../../ib-service'
import { ZIO } from '@mxt/zio'
import { useIsFocused } from '@react-navigation/native'
import { EmailManagementContext } from '../IBEmailManagementContext'
import { Status, TaskCategory } from '../common'
export interface IBSearchForm {
  contractNumber: string
  clientNumber: string
  email: string
  fromDate: Date | null
  toDate: Date | null
  groupCCE: SelectOption | null
  createdBy: SelectOption | null
}

interface TableProps {
  rows: ClickToSendTaskInstanceData[]
  setObjectModal: (data: { policyNumber: string; processInstanceId: string; taskId: string }) => void
  loading: boolean
  setPageSize: (num: number) => void
  setPageNum: (num: number) => void
  pageSize: number
  pageNum: number
  totalItem: number
}

const TablePendingApprovalComponent = React.memo((props: TableProps) => {
  const { masterDataList, permissions } = useContext(EmailManagementContext)
  const { t, i18n } = useTranslation('Inbound')
  const { rows, setObjectModal, loading, setPageSize, setPageNum, pageSize, pageNum, totalItem } = props

  const dataTable = [
    {
      label: t('Inbound:table:PolicyNumber'),
      field: 'policyNumber',
      render: (value: string, index: number, item: ClickToSendTaskInstanceData) => {
        return (
          <Pressable
            onPress={() => {
              setObjectModal({
                policyNumber: item?.policyNumber || '-',
                processInstanceId: item?.processInstanceId as string,
                taskId: item?.taskId as string
              })
            }}
            disabled={!permissions.includes(IBPolicyPermission.IBViewApprovalEmailDetail)}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 15,
                color: '#1ea5fc',
                textDecorationLine: 'underline'
              }}
            >
              {value || '-'}
            </Text>
          </Pressable>
        )
      }
    },
    {
      label: t('ClientNumber'),
      field: 'clientNumber'
    },
    {
      label: t('PolicyOwner'),
      field: 'policyOwnerName'
    },
    {
      label: t('Email'),
      field: 'customerEmail',
      disabled: true
    },
    {
      label: t('Group'),
      field: 'mailGroup'
    },
    {
      label: t('Type'),
      field: 'requestType'
    },
    {
      label: t('StatusEmail'),
      field: 'status'
    },
    {
      label: t('GroupCCE'),
      field: 'cceGroupCode'
    },
    {
      label: t('CreatedDate'),
      field: 'processStartTime',
      format: 'dateTime'
    },
    {
      label: t('LastUpdatedDateEmail'),
      field: 'lastUpdatedDate',
      format: 'dateTime'
    },
    {
      label: t('LastUpdatedByEmail'),
      field: 'lastUpdatedBy'
    },
    {
      label: t('Inbound:table:CaseID'),
      field: 'businessKey',
      disabled: true
    }
  ]
  return (
    <IBGeneralTable
      data={rows.map((item) => ({
        ...item,
        customerEmail: item.emailTo?.reduce((pre, cur) => (pre += cur + ';'), ''),
        processStartTime: moment(item.processCreatedTime).format('YYYYMMDDHHmmss'),
        lastUpdatedDate: item.lastUpdatedDate
          ? moment(item.lastUpdatedDate).format('YYYYMMDDHHmmss')
          : item.lastUpdatedDate,
        mailGroup:
          i18n.language === 'vi'
            ? masterDataList.a14s0s.find((x) => x.code === item.mailGroupCode)?.nameVi
            : masterDataList.a14s0s.find((x) => x.code === item.mailGroupCode)?.nameEn,
        requestType:
          i18n.language === 'vi'
            ? masterDataList.a14s1s.find((x) => x.code === item.requestTypeCode)?.nameVi
            : masterDataList.a14s1s.find((x) => x.code === item.requestTypeCode)?.nameEn,
        status:
          i18n.language === 'vi'
            ? Status.find((x) => x.code === item.status)?.nameVi
            : Status.find((x) => x.code === item.status)?.nameEn,
        cceGroupCode: masterDataList.a14s19.find((x) => x.teamCode === item.cceGroupCode)?.teamName
      }))}
      dataTable={dataTable}
      loading={loading}
      paging={{
        page: pageNum,
        pageSize: pageSize,
        taskCount: totalItem,
        setPage: setPageNum,
        setPageSize: setPageSize
      }}
    />
  )
})

export const IBListingOfPendingApproval = () => {
  const { t, i18n } = useTranslation('Inbound')
  const [formError, setFormError] = React.useState<string>('')
  const [openModal, setOpenModal] = React.useState<boolean>(false)
  const [objectModal, setObjectModal] = React.useState<{
    policyNumber: string
    processInstanceId: string
    taskId: string
  }>()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const [rows, setRows] = React.useState<ClickToSendTaskInstanceData[]>([])
  const isFocused = useIsFocused()
  const { masterDataList } = useContext(EmailManagementContext)
  const [flagActionSuccess, setFlagActionSuccess] = React.useState<boolean>(false)
  const [loading, bindLoading] = useLoading(false)

  const defaultValues: IBSearchForm = {
    contractNumber: '',
    clientNumber: '',
    email: '',
    fromDate: null,
    toDate: null,
    groupCCE: null,
    createdBy: null
  }

  const SearchForm = useForm<IBSearchForm>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })

  React.useEffect(() => {
    if (!isNil(objectModal)) {
      setOpenModal(true)
    }
  }, [objectModal])

  React.useEffect(() => {
    if (!openModal) {
      setObjectModal(undefined)
    }
  }, [openModal])

  React.useEffect(() => {
    if (isEmpty(rows)) {
      onSearch()
    }
  }, [isFocused])

  const validateFormSearch = (data: IBSearchForm): boolean => {
    if ((data.toDate && isNil(data.fromDate)) || (isNil(data.toDate) && data.fromDate)) {
      setFormError(t('message:MS020009', { field: isNil(data.fromDate) ? t('FromDate') : t('ToDate') }))
      return false
    } else if (moment(data.fromDate).startOf('day').diff(moment(data.toDate)) > 0) {
      setFormError(t('message:MS030044'))
      return false
    }
    return true
  }

  const onSearch = SearchForm.handleSubmit((value) => {
    if (validateFormSearch(value)) {
      setFormError('')
      setFlagActionSuccess(false)
      // const emailTo = value.email.charAt(value.email?.length - 1) === ';' ? value.email.slice(0, -1) : value.email
      pipe(
        IBService.getListClickToSendTaskInstance({
          start: pageNum,
          size: pageSize,
          sort: 'DESC',
          order: 'processCreatedTime',
          taskCategory: TaskCategory.REVIEW_EMAIL,
          policyNumber: value.contractNumber,
          clientNumber: value.clientNumber,
          fromDate: moment(value.fromDate, 'DD/MM/YYYY').startOf('days').toISOString(),
          toDate: moment(value.toDate, 'DD/MM/YYYY').endOf('days').toISOString(),
          emailTo: value.email,
          categoryCode: 'IB',
          cceGroupCode: value.groupCCE?.value,
          createdBy: value.createdBy?.value
        }),
        ZIO.map((res) => {
          if (!isEmpty(res.data)) {
            setRows(res.data)
            setFormError('')
            setTotalItem(res.total || 0)
          } else {
            setRows([])
            setFormError(t('message:MS030029'))
          }
          return ZIO.unit
        }),
        ZIO.catchAll((e) => {
          setFormError(t('message:MS030029'))
          setRows([])
          return ZIO.succeed([])
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  })

  React.useEffect(() => {
    if (!isEmpty(rows)) {
      onSearch()
    }
  }, [pageNum, pageSize])

  React.useEffect(() => {
    if (flagActionSuccess) {
      onSearch()
    }
  }, [flagActionSuccess])

  return (
    <View>
      <View style={styles.searchContainer}>
        <IBGeneralField
          FieldForm={SearchForm}
          col={3}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'contractNumber',
              title: t('Inbound:table:PolicyNumber'),
              inputType: 'number',
              maxLength: 9,
              rules: {
                minLength: {
                  value: 8,
                  message: t('message:MS160014')
                }
              },
              placeHolder: t('common:Input'),
              disabled: loading,
              alwayShowUnderline: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'clientNumber',
              title: t('ClientNumber'),
              inputType: 'number',
              maxLength: 8,
              rules: {
                minLength: {
                  value: 8,
                  message: t('message:IB0003')
                }
              },
              placeHolder: t('common:Input'),
              disabled: loading,
              alwayShowUnderline: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'email',
              title: t('Email'),
              inputType: 'input',
              maxLength: 255,
              rules: {
                validate: () => {
                  return SearchForm.watch('email').length > 0 &&
                    !SearchForm.watch('email')
                      .toLowerCase()
                      .match(/\S+@\S+\.\S+/)
                    ? `${t('message:IB0010')}`
                    : true
                }
              },
              placeHolder: t('common:Input'),
              disabled: loading,
              alwayShowUnderline: true
            },
            {
              type: TypeInputComponent.DATE,
              formName: 'fromDate',
              title: t('FromDate'),
              rules: {
                validate: () => {
                  const currentDate = new Date()
                  return !isNull(SearchForm.watch('fromDate')) &&
                    (SearchForm.watch('fromDate')?.getTime() as number) > currentDate.getTime()
                    ? `${t('message:MS990032')}`
                    : true
                }
              },
              disabled: loading,
              maxDate: SearchForm.watch('toDate') ? (SearchForm.watch('toDate') as Date) : new Date(),
              maxDateMessage: t('message:MS990032')
            },
            {
              type: TypeInputComponent.DATE,
              formName: 'toDate',
              title: t('ToDate'),
              rules: {
                validate: () => {
                  const currentDate = new Date()
                  return !isNull(SearchForm.watch('toDate')) &&
                    (SearchForm.watch('toDate')?.getTime() as number) > currentDate.getTime()
                    ? `${t('message:MS990032')}`
                    : true
                }
              },
              disabled: loading,
              maxDate: new Date(),
              maxDateMessage: t('message:MS990032')
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'groupCCE',
              title: t('GroupCCE'),
              option:
                orderBy(
                  uniq(masterDataList.a14s19.map((item) => ({ code: item.teamCode, name: item.teamName }))).map(
                    (x) => ({
                      label: x.name || '',
                      value: x.code || ''
                    })
                  ),
                  'label',
                  'asc'
                ) || []
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'createdBy',
              title: t('CreatedByEmail'),
              option:
                masterDataList.a14s18.map((item) => ({
                  label: item.staffEmail || '',
                  value: item.staffEmail || ''
                })) || []
            }
          ]}
        />
        <View style={styles.buttonContainer}>
          <IBButton
            onPress={onSearch}
            title={t('Search')}
            suffixIcon={<assets.IBSearchIcon />}
            backgroundFill
            disabled={loading}
          />
        </View>
        <View style={{ marginLeft: 15, marginBottom: 15 }}>
          {formError ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{formError}</Text> : null}
        </View>
      </View>
      <View style={styles.tableContainer}>
        <TablePendingApprovalComponent
          rows={rows}
          setObjectModal={setObjectModal}
          loading={loading}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
          totalItem={totalItem}
          pageNum={pageNum}
          pageSize={pageSize}
        />
      </View>
      <IBModalListingOfPendingApproval
        visible={openModal}
        onClose={setOpenModal}
        objectModal={objectModal}
        flagSuccess={flagActionSuccess}
        setFlagSuccess={setFlagActionSuccess}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    alignItems: 'center',
    width: '100%'
  },
  tableContainer: {
    paddingVertical: 20,
    paddingHorizontal: 30
  }
})
