import { ClientPolicy } from '../../Client'
import * as t from 'io-ts'
import { InternalPayments, PaymentData } from '../../task-detail-api/PaymentData'
import { PaymentType } from '../../PaymentType'
import { CoreResponseDetail } from '../../task-detail-api/TaskDetailBase'
import { LasStatus } from '../../LasStatus'
import { CashoutOptionType } from '../../task-detail-api/CashoutOptions'
import moment from 'moment'
export const payoutOptionC = t.union([
  PaymentData.BankTransfer,
  PaymentData.EWalletMomo,
  PaymentData.PayPremium,
  PaymentData.Premium,
  PaymentData.PayLoan,
  PaymentData.TopUp,
  PaymentData.Reinstatement,
  PaymentData.NoType,
  PaymentData.RepayOpl,
  PaymentData.RepayApl,
  PaymentData.Other,
  PaymentData.Reins
])

export type payoutOption = t.TypeOf<typeof payoutOptionC>
export enum PayoutMethods {
  PAYPREMIUM = 'PAYPREMIUM',
  PAYLOAN = 'PAYLOAN',
  OTHER = 'OTHER',
  CASHLESS = 'CASHLESS',
  BANKTRANSFER = 'BANKTRANSFER',
  PAID_AT_BANK = 'PAID_AT_BANK',
  REPAY_OPL = 'REPAY_OPL',
  REPAY_APL = 'REPAY_APL',
  REPAY_OPL_APL = 'REPAY_OPL_APL',
  MOMO = 'EWALLET_MOMO',
  PREMIUM = 'PREMIUM',
  REINSTATEMENT = 'REINSTATEMENT',
  TOPUP = 'TOP_UP',
  CASH_AT_COUNTER = 'CASH_AT_COUNTER'
}

export const PayoutMethodsMap = new Map([
  [PayoutMethods.PAYPREMIUM, 'Pay premium'],
  [PayoutMethods.PREMIUM, 'Pay premium'],
  [PayoutMethods.PAYLOAN, 'Repay loan'],
  [PayoutMethods.OTHER, 'Other premium (Reinstate, Top-up, Alteration)'],
  [PayoutMethods.CASHLESS, 'Cashless'],
  [PayoutMethods.BANKTRANSFER, 'Bank transfer'],
  [PayoutMethods.PAID_AT_BANK, 'Paid at bank'],
  [PayoutMethods.REPAY_OPL, 'Repay OPL'],
  [PayoutMethods.REPAY_APL, 'Repay APL'],
  [PayoutMethods.REPAY_OPL_APL, 'Repay APL & OP'],
  [PayoutMethods.MOMO, 'eWallet Momo'],
  [PayoutMethods.TOPUP, 'Top up'],
  [PayoutMethods.REINSTATEMENT, 'Reinstatement'],
  [PayoutMethods.CASH_AT_COUNTER, 'Cash at counter']
])

export const mapPayoutMethods = (method: string) => PayoutMethodsMap.get(method as PayoutMethods) ?? '-'
export const checker = (val: PayoutMethods) => (checkee: string) => {
  return val === checkee
}

export const getDetail = (id: string | null | undefined, policyClients: Array<ClientPolicy>): string =>
  id ? `${id} - ${policyClients.find((e) => e.policy === id)?.name}` : ''

export const getPayOutDetail = (
  v: {
    type: PaymentType
    bankAccount: {
      accountName: string
      accountNo: string
      bankName: string
      bankCode: string
      branchCode: string
      branchName?: string
    }
    officeCode?: string
    issuedDate?: string
    issuedBy?: string
    phoneNumber?: string
    policy?: {
      policyNo: string
    }
  },
  clientPolicy: Array<ClientPolicy>
) => {
  const bankName = v.bankAccount.bankName ? v.bankAccount.bankName + ' - ' : ''
  const accountName = v.bankAccount.accountName ? v.bankAccount.accountName + ' - ' : ''
  const branchName = v.bankAccount.branchName ? v.bankAccount.branchName + ' - ' : ''
  const issuedDate = v.issuedDate ? moment(v.issuedDate).format('DD/MM/YYYY') : ''

  return v.type === PaymentType.PAID_AT_BANK
    ? `${bankName} ${branchName} ${accountName} ${v.bankAccount.accountNo || ''} - ${issuedDate} - ${v.issuedBy ?? ''}`
    : v.type === PaymentType.BANKTRANSFER
      ? `${bankName} ${branchName} ${accountName} ${v.bankAccount.accountNo || ''}`
      : v.type === PaymentType.CASH_AT_COUNTER
        ? `${v.officeCode + ' -' || ''} ${accountName} ${v.bankAccount.accountNo || ''}`
        : v.type === PaymentType.MOMO
          ? // ? `${v.bankAccount?.branchCode}`
          `${accountName} ${v.bankAccount.accountNo} - ${v.phoneNumber ?? ''}`
          : v.type && InternalPayments.includes(v.type)
            ? getDetail(v.policy?.policyNo, clientPolicy)
            : getDetail(v.policy?.policyNo, clientPolicy)
}

export const getCoreDetail = (
  x: CoreResponseDetail | null,
  p: CashoutOptionType
): {
  status: LasStatus
  errorMes: string
}[] =>
  x === null
    ? p.map((_) => ({
      status: '-' as LasStatus,
      errorMes: '-'
    }))
    : (() => {
      const defaultData = {
        // status: LasStatus.FAILED,
        status: '-' as LasStatus,
        errorMes: '-'
      }
      const final = x.filter((y) => Object.values(PayoutMethods).includes(y.methodName as PayoutMethods))
      return final.length > 0
        ? p.map((x) => {
          const result = final.find((y) => x.type === y.methodName && x.policy?.policyNo === y.policyNo)
          return result
            ? {
              status: result.status as LasStatus,
              errorMes: !!result.historyActions && result.historyActions.length > 0 ?
                (result.historyActions[result.historyActions.length - 1].output?.oErrorResult?.errorDescription || '-') : '-'
            }
            : defaultData
        })
        : p.map((_) => defaultData)
    })()
