import * as React from 'react'

import { withStyles } from '@material-ui/core'
import * as core from '@material-ui/core'
import { assets } from '../assets'

type InputProps = {
  placeholder?: string
  onClick: (value: string) => void
}

export const SearchInput = (props: InputProps) => {
  const [value, setValue] = React.useState('')
  return (
    <SC.TextField
      placeholder={props.placeholder}
      variant="outlined"
      value={value}
      onChange={(event) => setValue(event.target.value as string)}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          props.onClick(value)
          ev.preventDefault()
        }
      }}
      InputProps={{
        endAdornment: (
          <core.InputAdornment position="end">
            <SC.IconButton onClick={() => props.onClick(value)}>
              <assets.IconSearch2 />
            </SC.IconButton>
          </core.InputAdornment>
        )
      }}
    />
  )
}

const SC = {
  TextField: withStyles({
    root: {
      minWidth: '50%',
      '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 20,
        padding: '15px 4px 15px 15px',
        height: '40px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        }
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 12,
        padding: 0
      }
    }
  })(core.TextField),
  IconButton: withStyles((theme) => ({
    root: {
      backgroundColor: `${theme.palette.primary.main}!important`,
      padding: '8px'
    }
  }))(core.IconButton)
}
