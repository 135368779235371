import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
export const QCComplaintC = t.type({
  errors: t.array(
    t.type({
      desc_en: t.string,
      desc_vi: t.string,
      score: t.number,
      error_id: t.string
    })
  ),
  lastUser: Maybe(t.string)
})

export type QCComplaint = t.TypeOf<typeof QCComplaintC>
