import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { View } from '@pulseops/business/core'
import { formatNumberWithComma, JournalModel, PayoutService, RadioButton, T0Data, TaskDetail, Title } from '@pulseops/common'
import { SectionContent } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
// import { actionList } from 'libs/submission/src/ps-submission/transactionScreen/screens/transfer-journal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView, Text, StyleSheet } from 'react-native'

export interface JournalScreenProps {
  detail: TaskDetail.TransferJournal
}

export enum ActionOption {
  ACTION_A_CNB = 'CASH_NOT_BANKED',
  ACTION_A_UJ = 'UNIT_JOURNAL',
  ACTION_A_CJ = 'CREATE_JOURNAL',
  ACTION_J_APR = 'ADVANCE_PREMIUM_RECEIPT',
  ACTION_J_ET = 'EA_TRANSFER',
  ACTION_B_RJ = 'REMOVE_JOURNAL',
  ACTION_B_BJ = 'BONUS_JOURNAL',
  ACTION_C_JPA = 'JOURNAL_POLICY_ACCOUNT',
  ACTION_L_LRR = 'LOAN_REPAYMENT_RECEIPT'
}

export const actionList = [
  {
    label: 'Action A - Cash Not Banked',
    value: ActionOption.ACTION_A_CNB
  },
  {
    label: 'Action J - Advance Premium Receipt',
    value: ActionOption.ACTION_J_APR
  },
  {
    label: 'Action A - Unit Journal',
    value: ActionOption.ACTION_A_UJ
  },
  {
    label: 'Action C - Journal Policy Account',
    value: ActionOption.ACTION_C_JPA
  },
  {
    label: 'Action A - Create Journal',
    value: ActionOption.ACTION_A_CJ
  },
  {
    label: 'Action J - EA Transfer (For Death Claim)',
    value: ActionOption.ACTION_J_ET
  },
  {
    label: 'Action B - Remove Journal',
    value: ActionOption.ACTION_B_RJ
  },
  {
    label: 'Action B - Bonus Journal',
    value: ActionOption.ACTION_B_BJ
  },
  {
    label: 'Action L - Loan Repayment Receipt',
    value: ActionOption.ACTION_L_LRR
  }
]

export const JournalScreen: React.FC<JournalScreenProps> = ({ detail }) => {
  const { t, i18n } = useTranslation()
  const [policyOwner, setPolicyOwner] = React.useState<string>('')
  const filterAction = (actionData: string) => {
    const action = actionList.filter((item) => item.value === actionData)
    return action ? action[0].label : '-'
  }

  const filterCategory = (type: string) => {
    return type === 'PS01' ? t('Joursnal:Payout') : type === 'PS02' ? t('Joursnal:Alteration') : '-'
  }

  const checkPolicyOwner = () => {
    if(detail.submissionData.action === ActionOption.ACTION_A_CJ)
    pipe(
      PayoutService.getOwnerInfo(detail.submissionData.policyNo),
      ZIO.map((data) => {
        setPolicyOwner(data.ownerName)
      }),
      ZIO.unsafeRun({})
    )
  }

  const getTransactionTypeLabel = (transactionType: string) => {
    const transactionItem = T0Data.getValue(transactionType)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : '-'
  }

  const changeToFormat = (num: number) => {
    return num === 0 ? 0 : formatNumberWithComma(num)
  }

  const showOptionalRequest = () => {
    const optionRequest = detail.submissionData.optionalRequest
    switch (optionRequest.type) {
      case JournalModel.JournalType.PAYABLE_ACCOUNT: {
        const payableAccount = optionRequest as JournalModel.PayableAccount
        return (
          <View>
            <RadioButton
              disable
              label={t('Journal:Payable')}
              selected
              style={{ paddingBottom: 20 }}
              labelStyle={{ fontWeight: '600' }}
            />
            <Title wrapperStyle={style.noMarginBottom} title={t('Journal:PolicyTransfer')}></Title>
            <SectionContent sectionStyles={style.sectionContent}>
              <View style={style.row}>
                {payableAccount.policyTransfer.releaseNumber && (
                  <View style={style.col_4_first}>
                    <Text style={style.label}>{t('Journal:ReleaseNumber')}</Text>
                    <Text>{payableAccount.policyTransfer.releaseNumber}</Text>
                  </View>
                )}
                {payableAccount.policyTransfer.type && (
                  <View style={style.col_4}>
                    <Text style={style.label}>{t('Journal:JournalType')}</Text>
                    <Text>{payableAccount.policyTransfer.type}</Text>
                  </View>
                )}
              </View>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow style={styleView.backGround}>
                      <TableCell style={{ width: '23%' }}>{t('Journal:SacCode')}</TableCell>
                      <TableCell style={{ width: '23%' }}>{t('Journal:SacType')}</TableCell>
                      <TableCell style={{ width: '27%' }}>{t('Journal:CurrentBalance')}</TableCell>
                      <TableCell style={{ width: '27%' }}>{t('Journal:TransferAmount')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payableAccount.policyTransfer.policyDetails.map((fieldItem, index) => {
                      return (
                        <TableRow key={`journalsTransfer_${index}`}>
                          <TableCell>
                            <Text>{fieldItem.code}</Text>
                          </TableCell>
                          <TableCell>
                            <Text>{fieldItem.type}</Text>
                          </TableCell>
                          <TableCell>
                            <Text>{changeToFormat(fieldItem.balance)}</Text>
                          </TableCell>
                          <TableCell style={{ flexDirection: 'row' }}>
                            <Text style={style.labelConfirm}>{changeToFormat(fieldItem.amount)}</Text>
                            <Text style={style.labelVND}>{'VND'}</Text>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  <TableRow>
                    <TableCell style={styleView.backGround}></TableCell>
                    <TableCell align="center" style={{ backgroundColor: '#E8E8E8' }}>
                      <Text style={{ fontWeight: 'bold' }}>{t('Journal:Total')}</Text>
                    </TableCell>
                    <TableCell style={styleView.backGround}></TableCell>
                    <TableCell>
                      <Text style={{ fontSize: 14 }}>{changeToFormat(payableAccount.policyTransfer.total)}</Text>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SectionContent>

            <Title
              wrapperStyle={[style.noMarginBottom, { marginTop: 15 }]}
              title={t('Journal:ReceivingPolicy')}
            ></Title>
            <SectionContent sectionStyles={style.sectionContent}>
              <View style={style.row}>
                <View style={[style.col_4_first, { paddingBottom: 30 }]}>
                  <Text style={style.label}>{t('Journal:Policy')}</Text>
                  <Text>{payableAccount.receivingPolicy.policy}</Text>
                </View>
                {policyOwner && (
                  <View style={style.col_4}>
                    <Text style={style.label}>{t('common:PolicyOwner')}</Text>
                    <Text>{policyOwner}</Text>
                  </View>
                )} 
              </View>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#E8E8E8' }}>
                      <TableCell style={{ width: '23%' }}>{t('Journal:SacCode')}</TableCell>
                      <TableCell style={{ width: '50%' }}>{t('SAC type')}</TableCell>
                      <TableCell style={{ width: '27%' }}>{t('Journal:Amount')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payableAccount.receivingPolicy.policyDetails.map((fieldItem, index) => {
                      return (
                        <TableRow key={`journalsReceive_${index}`}>
                          <TableCell>
                            <Text>{fieldItem.code}</Text>
                          </TableCell>
                          <TableCell>
                            <Text>{fieldItem.type}</Text>
                          </TableCell>
                          <TableCell style={{ flexDirection: 'row' }}>
                            <Text style={style.labelConfirm}>{changeToFormat(fieldItem.amount)}</Text>
                            <Text style={style.labelVND}>{'VND'}</Text>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  <TableRow style={{ marginTop: 20 }}>
                    <TableCell style={styleView.backGround}></TableCell>
                    <TableCell align="center" style={{ backgroundColor: '#E8E8E8', paddingRight: '28%' }}>
                      <Text style={{ fontWeight: 'bold' }}>{t('Journal:TotalReceive')}</Text>
                    </TableCell>
                    <TableCell>
                      <Text style={{ fontSize: 14 }}>{changeToFormat(payableAccount.receivingPolicy.total)}</Text>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SectionContent>
          </View>
        )
      }
      case JournalModel.JournalType.POLICY_ACCOUNT_JOURNAL: {
        const policyAccount = optionRequest as JournalModel.UnitJournal
        return (
          <View>
            <RadioButton
              disable
              label={t('Journal:PolicyAccountJournal')}
              selected
              style={{ paddingBottom: 20 }}
              labelStyle={{ fontWeight: '600' }}
            />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={styleView.backGround}>
                    <TableCell style={{ width: '23%' }}>{t('Journal:Fund')}</TableCell>
                    <TableCell style={{ width: '23%' }}>{t('Journal:UnitType')}</TableCell>
                    <TableCell style={{ width: '27%' }}>{t('Journal:PolicyAccount')}</TableCell>
                    <TableCell style={{ width: '27%' }}>{t('Journal:Adjust')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policyAccount.policyTransfer.policyDetails.map((fieldItem, index) => {
                    return (
                      <TableRow key={`journalPolicyAccount_${index}`}>
                        <TableCell>
                          <Text>{fieldItem.code}</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{fieldItem.type}</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{changeToFormat(fieldItem.balance)}</Text>
                        </TableCell>
                        <TableCell style={{ flexDirection: 'row' }}>
                          <Text style={style.labelConfirm}>{changeToFormat(fieldItem.amount)}</Text>
                          <Text style={style.labelVND}>{'VND'}</Text>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </View>
        )
      }
      case JournalModel.JournalType.UNIT_JOURNAL: {
        const unitJournal = optionRequest as JournalModel.UnitJournal
        return (
          <View>
            <RadioButton
              disable
              label={t('Journal:UnitJournal')}
              selected
              style={{ paddingBottom: 20 }}
              labelStyle={{ fontWeight: '600' }}
            />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={styleView.backGround}>
                    <TableCell style={{ width: '23%' }}>{t('Journal:Fund')}</TableCell>
                    <TableCell style={{ width: '23%' }}>{t('Journal:Type')}</TableCell>
                    <TableCell style={{ width: '27%' }}>{t('Journal:UnitBalance')}</TableCell>
                    <TableCell style={{ width: '27%' }}>{t('Journal:Adjust')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitJournal.policyTransfer.policyDetails.map((fieldItem, index) => {
                    return (
                      <TableRow key={`unitJournal_${index}`}>
                        <TableCell>
                          <Text>{fieldItem.code}</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{fieldItem.type}</Text>
                        </TableCell>
                        <TableCell>
                          <Text>{fieldItem.balance}</Text>
                        </TableCell>
                        <TableCell>
                          <Text style={style.labelConfirm}>{fieldItem.amount}</Text>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </View>
        )
      }
      case JournalModel.JournalType.BONUS_JOURNAL: {
        const bonusJournal = optionRequest as JournalModel.BonusJournal
        return (
          <View>
            <RadioButton
              disable
              label={t('Journal:BonusJournal')}
              selected
              style={{ paddingBottom: 20 }}
              labelStyle={{ fontWeight: '600' }}
            />
            <View style={[style.row, { marginLeft: 35, marginRight: 20 }]}>
              <View style={style.col_4_first}>
                <Text style={style.label}>{t('Journal:EffectiveDate')}</Text>
                <Text>{moment(bonusJournal.policyTransfer.effectiveDate).format('DD/MM/YYYY') || '-'}</Text>
              </View>
              <View style={style.col_4}>
                <Text style={style.label}>{t('Journal:AccruedBonus')}</Text>
                <Text>{changeToFormat(bonusJournal.policyTransfer.bonus)}</Text>
              </View>
              <View style={style.col_4}>
                <Text style={style.label}>{t('Journal:ARBAmount')}</Text>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={style.labelConfirmBJ}>{changeToFormat(bonusJournal.policyTransfer.amount)}</Text>
                  <Text>{'VND'}</Text>
                </View>
              </View>
            </View>
          </View>
        )
      }
      case JournalModel.JournalType.REMOVE_JOURNAL: {
        const removeJournal = optionRequest as JournalModel.RemoveJournal
        return (
          <View>
            <RadioButton
              disable
              label={t('Journal:RemoveJournal')}
              selected
              style={{ paddingBottom: 20 }}
              labelStyle={{ fontWeight: '600' }}
            />
            <View style={[style.row, { marginLeft: 35, marginRight: 20 }]}>
              <View style={style.col_4_first}>
                <Text style={style.label}>{t('Journal:JournalNumber')}</Text>
                <Text>{removeJournal.policyTransfer.journalNumber}</Text>
              </View>
            </View>
          </View>
        )
      }
      default:
        return <View>{'-'}</View>
    }
  }

  React.useEffect(() => {
    checkPolicyOwner()
  }, [])

  return (
    <SafeAreaView style={style.container}>
      <Title wrapperStyle={style.noMarginBottom} title={t('Tab:RelatedInfo')}></Title>
      <SectionContent sectionStyles={style.sectionContent}>
        <View style={style.row}>
          <View style={style.col_4_first}>
            <Text style={style.field_title}>{t('ServiceInquiry:Category')}</Text>
            <Text style={[style.field_description]}>{filterCategory(detail.submissionData.category)}</Text>
          </View>
          <View style={style.col_4}>
            <Text style={style.field_title}>{t('Journal:Action')}</Text>
            <Text style={[style.field_description]}>{filterAction(detail.submissionData.action)}</Text>
          </View>
          <View style={style.col_4}>
            <Text style={style.field_title}>{t('common:CaseId')}</Text>
            <Text style={[style.field_description]}>{detail.submissionData.caseID || '-'}</Text>
          </View>
        </View>
        <View style={[style.row, { marginBottom: 15, marginTop: 30 }]}>
          <View style={style.col_4_first}>
            <Text style={style.field_title}>{t('Journal:Transaction')}</Text>
            <Text style={[style.field_description]}>
              {getTransactionTypeLabel(detail.submissionData.transaction || '-')}
            </Text>
          </View>
        </View>
      </SectionContent>
      <View style={[style.row, { marginTop: 30 }]}>
        <View style={{ flexDirection: 'column', width: '100%' }}>{showOptionalRequest()}</View>
      </View>
      <View style={{ marginBottom: 16, marginLeft: 8 }}>
        <Text style={style.label}>{t('Journal:Remark')}</Text>
        <Text style={[style.field_description]}>{detail.submissionData.remark || '-'}</Text>
      </View>
    </SafeAreaView>
  )
}
const style = StyleSheet.create({
  container: {
    marginTop: 15
  },
  noMarginBottom: {
    marginBottom: 0
  },
  sectionContent: {
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 15
  },
  sectionRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -15,
    marginLeft: -15
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  col_4_first: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15
  },
  col_12: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 15,
    paddingLeft: 15
  },
  field_title: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  field_description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },
  redLine: {
    color: '#ed1c2e',
    fontWeight: '700'
  },
  underlineLink: {
    textDecorationLine: 'underline'
  },
  button: {
    width: 100,
    marginLeft: 10,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    height: 35,
    paddingHorizontal: 20
  },
  label: {
    fontSize: 15,
    lineHeight: 22,
    color: '#70777E',
    fontWeight: 'bold',
    paddingBottom: 10
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  labelVND: {
    paddingVertical: 10,
    paddingLeft: 30
  },
  labelConfirm: {
    width: '60%',
    paddingVertical: 10
  },
  labelConfirmBJ: {
    width: '50%'
  }
})

const styleView = {
  tableCellSelect: {
    width: '100%',
    borderRadius: '6px',
    paddingRight: 20
  },
  tableCellInput: {
    width: '100%'
  },
  backGround: {
    backgroundColor: '#E8E8E8'
  }
}
