import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export const ProvinceType = t.type({
  bcCode: t.string,
  code: t.string,
  desc: Maybe(t.string || null),
  id: Maybe(t.string || null),
  name: t.string,
  nameEn: Maybe(t.string || null)
})

export type ProvinceType = t.TypeOf<typeof ProvinceType>
