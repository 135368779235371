import { FontAwesome5 } from '@expo/vector-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle, ActivityIndicator, StyleProp } from 'react-native'
// import { RiderDeleteModal } from './RiderDeleteModal'

interface Props {
  title?: string | JSX.Element
  titleOutline?: string
  allowToggle?: boolean
  isExand?: boolean
  children?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
  isLoading?: boolean
  isNoPadding?: boolean
  isTransactionToggleExpand?: boolean
  isNewLA?: boolean
  LAIndex?: number
  isViewDetail?: boolean
  removeItem?: (index: number) => void
  viewDetailEvent?: (index: number) => void
  isDelete?: boolean
  setIsDelete?: (status: boolean, index: number) => void
}

export const LAPanel: React.FC<Props> = ({
  title,
  isExand = true,
  isLoading = false,
  children,
  titleOutline,
  allowToggle,
  containerStyle,
  isNoPadding = false,
  isTransactionToggleExpand = false,
  isNewLA = false,
  LAIndex,
  isViewDetail = false,
  removeItem,
  viewDetailEvent,
  isDelete = false,
  setIsDelete
}) => {
  const [toggle, setToggle] = useState(true)
  const { t } = useTranslation()
  const onToggle = () => {
    allowToggle !== false && setToggle(!toggle)
  }

  return (
    <View>
      {!!titleOutline && <Text style={[styles.sectionInfoHeaderTitle, styles.titleOutline]}>{titleOutline}</Text>}
      <View style={[styles.sectionInfo, containerStyle, isNoPadding && styles.noPadding]}>
        <View
          style={[
            styles.sectionInfoHeader,
            !title && styles.sectionInfoHeaderInnerRight,
            isNoPadding && styles.touchableBtn,
            !toggle && styles.bottomPadding
          ]}
        >
          {!!title &&
            !isTransactionToggleExpand &&
            (!isNewLA ? (
              <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
            ) : (
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={[styles.sectionInfoHeaderTitle, styles.marginText]}>{title}</Text>
                <Text style={[styles.newText, styles.btnActions]}>{`(${t('RiderAlteration:New')})`}</Text>
                <TouchableOpacity onPress={() => !!viewDetailEvent && viewDetailEvent(LAIndex ?? -1)}>
                  <Text style={[styles.detailText, styles.btnActions]}>{t('RiderAlteration:Details')}</Text>
                </TouchableOpacity>
                {!isViewDetail && (
                  <TouchableOpacity onPress={() => setIsDelete && setIsDelete(true, LAIndex ?? -1)}>
                    <Text style={styles.detailText}>{t('common:Delete')}</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          {isExand && !isTransactionToggleExpand && (
            <TouchableOpacity onPress={onToggle}>
              <FontAwesome5 name={toggle ? 'minus-circle' : 'plus-circle'} color="#1B365D" size={25} />
            </TouchableOpacity>
          )}
          {isTransactionToggleExpand && (
            <TouchableOpacity onPress={onToggle} style={styles.transactionContent}>
              {!!title &&
                (!isNewLA ? (
                  <Text style={styles.sectionInfoHeaderTitle}>{title}</Text>
                ) : (
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={[styles.sectionInfoHeaderTitle, styles.marginText]}>{title}</Text>
                    <Text style={[styles.newText, styles.btnActions]}>{`(${t('RiderAlteration:New')})`}</Text>
                    <TouchableOpacity onPress={() => !!viewDetailEvent && viewDetailEvent(LAIndex ?? -1)}>
                      <Text style={[styles.detailText, styles.btnActions]}>{t('RiderAlteration:Details')}</Text>
                    </TouchableOpacity>
                    {!isViewDetail && (
                      <TouchableOpacity onPress={() => setIsDelete && setIsDelete(true, LAIndex ?? -1)}>
                        <Text style={styles.detailText}>{t('common:Delete')}</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                ))}
              <FontAwesome5 name={toggle ? 'minus-circle' : 'plus-circle'} color="#1B365D" size={25} />
            </TouchableOpacity>
          )}
        </View>
        {toggle && (
          <View style={styles.sectionInfoContent}>
            {isLoading ? <ActivityIndicator size="large" color="#ED1B2E" /> : children}
          </View>
        )}
      </View>
      {/* <RiderDeleteModal
        visible={isDelete}
        onPressClose={() => setIsDelete && setIsDelete(false)}
        onPressSubmit={() => {
          !!removeItem && removeItem(LAIndex ?? 0)
          setIsDelete && setIsDelete(false)
        }}
      /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  titleOutline: {
    marginBottom: 10
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  sectionInfoHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  sectionInfoHeaderInnerLeft: {
    justifyContent: 'flex-start'
  },
  sectionInfoHeaderInnerRight: {
    justifyContent: 'flex-end'
  },
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  sectionInfoContent: {
    marginTop: 16
  },
  noPadding: {
    padding: 0,
    backgroundColor: '#FAFAFA'
  },
  transactionContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  touchableBtn: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  bottomPadding: {
    paddingBottom: 20
  },
  marginText: {
    marginRight: 8
  },
  btnActions: {
    marginRight: 16
  },
  newText: {
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: 15,
    lineHeight: 22,
    color: '#ED1B2E'
  },
  detailText: {
    fontWeight: '400',
    fontSize: 15,
    lineHeight: 22,
    color: '#1EA5FC',
    textDecorationLine: 'underline'
  }
})
