import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  ImgUploadMutiple,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  SelectSearch,
  Title,
  useMobile
} from '@pulseops/common'
import { Column, Error } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { ComplainInquiryForm } from './complain-inquiry-form'
interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail: InquiryComplaintData.DetailData | null
  mainProductCode: string | undefined
  riders: InquiryComplaintData.IACSources[] | null
  isReset: boolean
  userInfo: {
    email: string
    name: string
  }
  setAgentCodeErr: (value: string) => void
  agentCodeError: string
}

type Option = {
  label: string
  value: string
}

export const Conservation: React.FC<Props> = ({
  detail,
  form,
  riders,
  mainProductCode,
  isReset,
  userInfo,
  setAgentCodeErr,
  agentCodeError
}) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()
  const [loader, bindLoader] = useLoading()

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const [fullSurrenderReasonList, setFullSurrenderReasonList] = useState<InquiryComplaintData.FullSurrenderReason[]>([])
  const [resolutionList, setResolutionList] = useState<InquiryComplaintData.Resolution[]>([])

  const [isDisableBonus, setDisableBonus] = useState<boolean>(false)
  const [isDisableSolution, setDisableSolution] = useState<boolean>(false)

  const { control, watch, setValue, clearErrors } = form

  let mainProductName: string = ''

  if (detail && riders && mainProductCode) {
    const mainRider = riders.find((r) => r.code === mainProductCode)
    mainProductName = mainRider ? (i18n.language === 'en' ? mainRider.name : mainRider.nameVi) : ''
  }

  const resultOptions = [
    {
      code: 'SUCCESS',
      name: 'Success',
      nameVi: 'Thành công'
    },
    {
      code: 'FAIL',
      name: 'Fail',
      nameVi: 'Không thành công'
    }
  ]

  const bonusOptions = [
    {
      code: 'BO_01',
      name: '+/- 2%',
      nameVi: 'Cộng/trừ 2%'
    },
    {
      code: 'BO_02',
      name: 'Not +/- 2%',
      nameVi: 'Không cộng/trừ 2%'
    }
  ]

  useEffect(() => {
    setDisableBonus(false)
    setDisableSolution(false)
  }, [isReset])

  useEffect(() => {
    showGlobalLoading(loader)
  }, [loader])

  const converOptions = (arr: any[]): Option[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  //   FETCH API

  pipe(
    InquiryComplaintService.getFullSurrenderReasonList(),
    ZIO.map((list) => {
      setFullSurrenderReasonList(list)
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  )
  pipe(
    InquiryComplaintService.getResolutionList(),
    ZIO.map((list) => {
      setResolutionList(list)
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  )

  const onChangeResult = (e: Option | null) => {
    setValue('conservationInfomation.conservationSolution', null)
    setValue('conservationInfomation.conservationBonus', null)
    if (e?.value === 'FAIL') {
      setValue('conservationInfomation.conservationSolution', {
        value: 'FULL_SURRENDER',
        label: i18n.language === 'en' ? 'Full surrender' : 'Hủy hợp đồng'
      })
      setDisableBonus(true)
      setDisableSolution(true)
    } else {
      setDisableSolution(false)
      if (form.getValues('conservationInfomation.conservationGAStaff') === true) {
        setDisableBonus(true)
      } else {
        setDisableBonus(false)
      }
    }
  }

  const onChangeGaStaff = (e: boolean) => {
    setValue('conservationInfomation.conservationBonus', null)
    setValue('conservationInfomation.conservationAgentCode', '')
    setValue('conservationInfomation.conservationAgentName', '')
    setAgentCodeErr('')
    clearErrors('conservationInfomation')
    if (e) {
      setDisableBonus(true)
    } else {
      if (form.getValues('conservationInfomation.conservationResult')?.value === 'FAIL') {
        setDisableBonus(true)
      } else {
        setDisableBonus(false)
      }
    }
  }

  const onChangeAgentCode = (e: string) => {
    if (e && e.length === 8) {
      pipe(
        InquiryComplaintService.getAgentInfo(e),
        ZIO.map((res) => {
          if (!!res.agentName && res.agentName !== ' ') {
            setAgentCodeErr('')
            setValue('conservationInfomation.conservationAgentName', res.agentName)
            clearErrors('conservationInfomation.conservationAgentCode')
          } else {
            setValue('conservationInfomation.conservationAgentName', '')
            setAgentCodeErr(t('message:MS100005'))
          }
        }),
        ErrorHandling.run()
      )
    } else {
      setAgentCodeErr('')
      setValue('conservationInfomation.conservationAgentName', '')
    }
  }

  return (
    <View>
      <Title title={t('ConservationInfomation')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('Product')}</Text>
            </View>
            <Text>{mainProductName}</Text>
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('ServicingAgent')}</Text>
            </View>
            <Text>{`${detail?.agentCode} ${detail?.agentName}`}</Text>
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="conservationInfomation.conservationGAStaff"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={watch('conservationInfomation.conservationGAStaff') ? userInfo.name : t('ConservationGAStaff')}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChangeGaStaff(e)
                    onChange(e)
                  }}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('ConservationAgent')}</Text>
              {!watch('conservationInfomation.conservationGAStaff') && <Text style={styles.required}>*</Text>}
            </View>
            <View style={[styles.row]}>
              <View style={{ marginRight: 20, width: '30%', minWidth: 95 }}>
                <Controller
                  name="conservationInfomation.conservationAgentCode"
                  control={control}
                  render={({ field: { onBlur, value }, fieldState: { error } }) => (
                    <View>
                      <Input
                        disabled={watch('conservationInfomation.conservationGAStaff')}
                        onChange={(e) => {
                          const newVal = e.replace(/\D/g, '')
                          form.setValue('conservationInfomation.conservationAgentCode', newVal)
                          onChangeAgentCode(e)
                        }}
                        onBlur={onBlur}
                        value={value ?? ''}
                        errorMessage={error?.message || agentCodeError}
                        maxLength={8}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ width: '70%' }}>
                <Controller
                  name="conservationInfomation.conservationAgentName"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      onChange={(e) => {
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      disabled
                      multiline
                      numberOfLines={2}
                      maxLength={120}
                    />
                  )}
                />
              </View>
            </View>
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="conservationInfomation.reasonFullSurrender"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('ReasonForFullSurrender')}
                  required
                  options={fullSurrenderReasonList.map((item) => ({
                    value: item.reasonCode,
                    label: i18n.language === 'en' ? item.reasonEN : item.reasonVN
                  }))}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="conservationInfomation.conservationResult"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('Result')}
                  required
                  options={converOptions(resultOptions)}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChangeResult(e)
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="conservationInfomation.conservationSolution"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('Solution')}
                  required
                  options={
                    watch('conservationInfomation.conservationResult')?.value !== 'FAIL'
                      ? resolutionList.map((item) => ({
                          value: item.resolutionCode,
                          label: i18n.language === 'en' ? item.resolutionEN : item.resolutionVN
                        }))
                      : converOptions([{ code: 'FULL_SURRENDER', name: 'Full surrender', nameVi: 'Hủy hợp đồng' }])
                  }
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  disabled={isDisableSolution}
                  disableUnderline={isDisableSolution}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="conservationInfomation.conservationBonus"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('Bonus')}
                  required={!isDisableBonus}
                  disabled={isDisableBonus}
                  options={converOptions(bonusOptions)}
                  placeholder={t('common:Select')}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  onBlur={onBlur}
                  value={value}
                  key={value?.value}
                  errorMessage={error?.message}
                  disableUnderline={isDisableBonus}
                />
              )}
            />
          </View>
        </View>
        <View style={styles.colFull}>
          <Controller
            name="conservationInfomation.conservationNote"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('Note')}
                onChange={onChange}
                onBlur={onBlur}
                value={value || undefined}
                errorMessage={error?.message}
                maxLength={1000}
                multiline
                numberOfLines={5}
              />
            )}
          />
        </View>
        <View style={styles.colFull}>
          <View style={[styles.row, styles.mB5]}>
            <Text style={[styles.mR5, styles.label]}>{t('requestInfo:Attachment')}</Text>
            <Text style={styles.required}>*</Text>
          </View>
          <Controller
            control={control}
            defaultValue={[]}
            name="conservationInfomation.attachmentFiles"
            render={({ field, fieldState: { error } }) => (
              <Column>
                <ImgUploadMutiple {...field} value={field.value as any[]} errorMessage={error?.message || ''} />
                {error?.message && <Error message={error.message} />}​​​
              </Column>
            )}
          />
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  required: {
    color: '#ed1b2e'
  },
  label: {
    fontSize: 15,
    lineHeight: 20,
    color: '#70777e',
    fontWeight: 'bold'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
