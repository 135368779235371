import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import {
  AppContext,
  Columns,
  Container,
  ErrorHandling,
  FieldList,
  Panel,
  PulseOpsFormat,
  useTranslator,
  Input,
  mapFrequency,
  Frequency,
  Select,
  FrequencyMapping,
  ModalComponent,
  CanView
} from '@pulseops/common'
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native'
import { CepTableField, CepFieldList } from '../cep-components'
import _, { isEmpty } from 'lodash'
import i18next from 'i18next'
import { useWindowDimensions, View, Text, ActivityIndicator, StyleSheet } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InquiryStackParamList } from '../../InquiryStackParamList'
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { CepFormConst } from '../CEPFormConst'
import { CepPanel } from '../cep-components'
import {
  codesBillingChangeHistory,
  EInvesProduct,
  ETradProduct,
  IBonusSurrender,
  IProductDetail,
  IProductInfo,
  FormDataBillingChange,
  PruCashBenefitReq,
  getPruCashBenInfo,
  LoanType,
  PruCashBenefitData,
  getBonusHistory,
  BonusHistoryItem,
  getBillingFrequency,
  getCoreTransaction,
  getInvestmentInfo,
  getPolicyBonusReverse,
  getPolicyValueInfo,
  getProductType,
  PolicyValueInfoData,
  ProductTypeC,
  getBillingChangeHistory
} from '@pulseops/inquiry'

export const CepPolicyValueScreen = (props: {
  id: string
  data: PolicyValueInfoData | null
  setData: (val: PolicyValueInfoData | null) => void
  productTypeInfo: ProductTypeC | null
  setProductTypeInfo: (val: IProductDetail) => void
  bonusHistoryInfo: Array<BonusHistoryItem> | null
  setBonusHistoryInfo: (val: Array<BonusHistoryItem> | null) => void
}) => {
  const { t } = useTranslator('TaskManagement')
  const cep = useTranslator('CustomerEngagement').t
  const reqt = useTranslator('requestInfo').t
  const [loading, bindLoading] = useLoading()
  const [bonusReserveRemainValue, setBonusReserveRemainValue] = React.useState<number | undefined>(undefined)
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const [bonusReserveValue, setBonusReserveValue] = React.useState<number>(0)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const navigation = useNavigation<StackNavigationProp<InquiryStackParamList, 'InquiryScreen'>>()
  const route = useRoute<RouteProp<InquiryStackParamList, 'CEPPolicyDetailScreen'>>()
  const { width } = useWindowDimensions()
  const [bindError, setError] = React.useState<string>('')
  const [disabledNewBillFre, setDisabledNewBillFre] = React.useState<boolean>(true)
  const [newInstallment, setNewInstallment] = React.useState<number | undefined>(undefined)
  const [repayinAmount, setRepayinAmount] = React.useState<number>(0)
  const [errorBillingChange, setErrorBilingChange] = React.useState<string | undefined>(undefined)
  const [loadingModal, setLoadingModal] = React.useState<boolean>(false)
  const [loanDetail, setLoanDetail] = React.useState<Array<PruCashBenefitData>>([])
  const form = useForm<FormDataBillingChange>({
    mode: 'all'
  })
  const [loanType, setLoanType] = React.useState<LoanType>(LoanType.EI)

  const titleLoanType = {
    EO: cep('PrincipalAmount'),
    EI: cep('InterestAmount'),
    AO: cep('Table.CurrentBalanceAPL'),
    AI: cep('Table.AccuredInterestAPL'),
    LO: cep('Table.CurrentBalanceOPL'),
    LI: cep('Table.AccuredInterestOPL')
  }

  const titleModalPopup = {
    EO: cep('PrucashBenefitDetail'),
    EI: cep('PrucashBenefitDetail'),
    AO: cep('APLValueDetail'),
    AI: cep('APLValueDetail'),
    LO: cep('OPLValueDetail'),
    LI: cep('OPLValueDetail')
  }

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => navigate('HomeScreen')
        },
        {
          title: route?.params?.isSubmission === 'true' ? t('Submission:EForm') : t('menu:360Inquiry'),
          navigate: () =>
            route?.params?.isSubmission === 'true'
              ? navigation.replace('UserDrawer', {
                  screen: 'StaffSubmissionStack'
                })
              : navigation.navigate('CEPSearchScreen')
        },
        {
          title: t('Tab:PolicyValueInfo'),
          navigate: null
        }
      ])
    }
  }, [isFocused])
  const getData = (): ZIO<unknown, Throwable, PolicyValueInfoData> =>
    pipe(
      props.productTypeInfo !== null ? ZIO.succeed(props.productTypeInfo) : getProductType(props.id),
      ZIO.flatMap((productInfo) => {
        return pipe(
          ZIO.zip(
            getPolicyValueInfo({
              data: {
                contractNumber: props.id,
                effectiveDate: undefined,
                contractStatus: productInfo?.data?.status,
                productType: productInfo?.data?.productType,
                coverageId: productInfo?.data?.basicCode
              }
            }),
            ZIO.succeed(productInfo),
            getInvestmentInfo({ data: { function: '', policies: [`${props.id}`] } })
          ),
          ZIO.map(([_policyValue, product, checker]) => {
            const {
              contractValue,
              billingChangeInfo,
              billingChange,
              bonusSurrender,
              fullSurrender,
              aplValue,
              oplValue,
              prucashBenefits
            } = _policyValue.data

            props.setProductTypeInfo?.(product)
            setBonusReserveRemainValue(bonusSurrender?.bonusReserveRemainValue ?? undefined)

            const aplData = aplValue.sort(function (a, b) {
              const timeB = b?.loanStartDate?.split('/').reverse().join('/') ?? ''
              const timeA = a?.loanStartDate?.split('/').reverse().join('/') ?? ''

              const newB = new Date(timeB)

              const newA = new Date(timeA)

              return newB.valueOf() - newA.valueOf()
            })

            const oplData = oplValue.sort(function (a, b) {
              const timeB = b?.loanStartDate?.split('/').reverse().join('/') ?? ''
              const timeA = a?.loanStartDate?.split('/').reverse().join('/') ?? ''

              const newB = new Date(timeB)

              const newA = new Date(timeA)

              return newB.valueOf() - newA.valueOf()
            })

            return {
              contractValue: contractValue,
              billingChange: billingChange,
              billingChangeInfo: billingChangeInfo,
              bonusSurrender: bonusSurrender,
              fullSurrender: fullSurrender,
              aplValue: aplData,
              oplValue: oplData,
              productInfo: product?.data,
              validProduct: !checker.data.policyExtraInfoDetails.some(
                (x) =>
                  ['UHS2', 'HSR4', 'VHS1'].includes(x.productCode) && x.riderStatus === 'IF' && x.sumAssured === 400000
              ),
              prucashBenefits: prucashBenefits
            }
          })
        )
      })
    )

  const {
    contractValue,
    billingChangeInfo,
    billingChange,
    bonusSurrender,
    fullSurrender,
    aplValue,
    oplValue,
    productInfo,
    validProduct,
    prucashBenefits
  } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      setBonusReserveRemainValue(bonusSurrender?.bonusReserveRemainValue ?? undefined)
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    contractValue: null,
    billingChange: null,
    bonusSurrender: null,
    fullSurrender: null,
    aplValue: [],
    oplValue: [],
    productInfo: null,
    validProduct: false,
    prucashBenefits: []
  }

  pipe(
    getBonusHistory(props.id),
    ZIO.map((res) => {
      const bonusData =
        res && res?.data && res?.data.length > 0
          ? res?.data.sort(function (a, b) {
              const timeB = b?.transactionDate?.split('/').reverse().join('/') ?? ''
              const timeA = a?.transactionDate?.split('/').reverse().join('/') ?? ''

              const newB = new Date(timeB)

              const newA = new Date(timeA)

              return newB.valueOf() - newA.valueOf()
            })
          : []
      props.setBonusHistoryInfo?.(bonusData ?? [])
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (isEmpty(props.productTypeInfo)) return
    validateNewBillingFre()
  }, [props.productTypeInfo])

  React.useEffect(() => {
    if (isEmpty(form.watch('newBillingChange')?.value)) return
    getNextInstallmentPremium()
  }, [form.watch('newBillingChange')])

  const MapFrequencies = FrequencyMapping.FrequencyOption.filter(
    (freq) => freq.value !== props.productTypeInfo?.data.billFreq
  )

  const getNextInstallmentPremium = () =>
    pipe(
      getBillingFrequency({
        data: {
          contractNumber: props.id,
          billingFrequency: form.watch('newBillingChange')?.value ?? ''
        }
      }),
      ZIO.foldM(
        (error) => {
          return ZIO.fail(error)
        },
        (res) => {
          if (!validProduct && form.watch('newBillingChange')?.value === Frequency.Monthly) {
            setError(i18next.t('message:MS050217'))
            return ZIO.succeed(res)
          }
          if (!validProduct && form.watch('newBillingChange')?.value === Frequency.Quarterly) {
            setError(i18next.t('message:MS020027'))
            return ZIO.succeed(res)
          }

          if (isEmpty(res.data?.nextInstallmentPremium)) {
            const errorMess = _.get(res.error, `errors.${0}.errorCode`)

            if (!isEmpty(errorMess)) {
              // to do
              const currFrequency = mapFrequency.get(props.productTypeInfo?.data.billFreq as Frequency)
              const codeAvailable = MapFrequencies.reduce((acc, curr) => {
                return curr.value === props.productTypeInfo?.data.billFreq
                  ? acc
                  : acc.length
                  ? i18next.t(acc) + ' hoặc ' + i18next.t(curr.label)
                  : curr.label
              }, '')

              setError(i18next.t('message:MS050215', { currFrequency, codeAvailable }))
              form.setValue('newBillingChange', {
                label: '',
                value: ''
              })
            }
          }

          if (Number(res.data?.minimumContribution) > Number(res.data?.nextInstallmentPremium)) {
            setNewInstallment(0)
            setError(i18next.t('message:MS050220'))
          } else {
            setNewInstallment(res?.data?.nextInstallmentPremium ?? 0)
            if (
              Number(res.data?.nextInstallmentPremium ?? 0) >
              Number(billingChange?.suspenseAmount ?? 0) + Number(billingChange?.advancePremium ?? 0)
            ) {
              const total =
                Number(res.data?.nextInstallmentPremium ?? 0) -
                Number(billingChange?.suspenseAmount ?? 0) +
                Number(billingChange?.advancePremium ?? 0)
              setRepayinAmount(total)
            } else {
              setRepayinAmount(0)
            }

            setError('')
          }

          return ZIO.succeed(res)
        }
      ),
      ZIO.unsafeRun({})
    )

  const validateNewBillingFre = async () => {
    // const PTDAdvance = new Date(props?.productTypeInfo?.data.paidToDateAdvance ?? '')

    // const PTDAdvance120 = new Date(props?.productTypeInfo?.data.paidToDateAdvance ?? '')
    // const PTDAdvance60 = new Date(props?.productTypeInfo?.data.paidToDateAdvance ?? '')

    // PTDAdvance120.setDate(PTDAdvance120.getDate() + 120)
    // PTDAdvance60.setDate(PTDAdvance60.getDate() + 60)

    // const RCD = new Date(props?.productTypeInfo?.data.riskCommenceDate ?? '')
    // const PTDBasic = new Date(props?.productTypeInfo?.data.paidToDateBasic ?? '')
    // const today = new Date()
    // const isCheckProdCode = ['UR2', 'UR4', 'VR1'].includes(props?.productTypeInfo?.data.productCode ?? '')
    // const InvesmentProduct = [EInvesProduct.ULP, EInvesProduct.ILP]

    if (props?.productTypeInfo?.data.billFreq === '00') {
      setError(i18next.t('message:MS050269'))
      return false
    } else if (props?.productTypeInfo?.data.status !== 'IF' && props?.productTypeInfo?.data.status !== 'LA') {
      setError(i18next.t('message:MS050045'))
      return false
    } else if (props?.productTypeInfo?.data.premiumStatus === 'FP') {
      setError(i18next.t('message:MS050045'))
      return false
    }
    // else if (props?.productTypeInfo.data.productType === ETradProduct.TRAD || isCheckProdCode) {
    //   if (today.valueOf() > PTDAdvance120.valueOf()) {
    //     setError(i18next.t('message:MS050046'))
    //     return false
    //   } else if (PTDAdvance.getMonth() !== RCD.getMonth() || PTDAdvance.getDate() !== RCD.getDate()) {
    //     setError(i18next.t('message:MS050045'))
    //     return false
    //   }
    // } else if (
    //   InvesmentProduct.includes(props?.productTypeInfo.data.productType as EInvesProduct) ||
    //   !isCheckProdCode
    // ) {
    //   if (today.valueOf() > PTDAdvance60.valueOf()) {
    //     setError(i18next.t('message:MS050046'))
    //     return false
    //   } else if (PTDBasic.getMonth() !== RCD.getMonth() || PTDBasic.getDate() !== RCD.getDate()) {
    //     setError(i18next.t('message:MS050045'))
    //     return false
    //   }
    // }
    setError('')
    setDisabledNewBillFre(false)
    return true
  }

  const contractValueData = [
    {
      id: 1,
      label: t('AutomaticPremiumLoanAPL'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.automaticPremiumLoan) + ' VND'
    },
    {
      id: 2,
      label: t('OriginalPolicyLoanOPL'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.originalPolicyLoan) + ' VND'
    },
    {
      id: 3,
      label: t('SurrenderValue'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.contractSurrenderValue) + ' VND'
    },
    {
      id: 4,
      label: t('LoanAvailable'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.loanAmountAvailable) + ' VND'
    },
    {
      id: 5,
      label: t('LoanAmount80SV'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.loanExceedSurrenderValue) + ' VND'
    },
    {
      id: 6,
      label: t('BonusValue'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.bonusValue) + ' VND'
    },
    {
      id: 7,
      label: t('SpecialBonus'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.specialBonus) + ' VND'
    },
    {
      id: 8,
      label: t('BonusSurrender'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.bonusSurrender) + ' VND'
    },
    {
      id: 9,
      label: t('SuspendAmount'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.suspenseAmount) + ' VND'
    },
    {
      id: 10,
      label: t('PrucashBenefit'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.totalCashBenefit) + ' VND'
    },
    {
      id: 11,
      label: t('Prukid369'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.pruKidBenefit) + ' VND'
    },
    {
      id: 12,
      label: t('MaturityBenefit'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.maturityBenefit) + ' VND'
    },
    {
      id: 13,
      label: t('MaxPartialWithdrawal'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.maxWithDraw) + ' VND'
    },
    {
      id: 14,
      label: cep('PrevMaturityAdvance'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.prevMaturityAdvance) + ' VND'
    },
    {
      id: 15,
      label: cep('TotalMaturityAdvance'),
      value: PulseOpsFormat.thousandSepartor(contractValue?.totalMaturityAdvance) + ' VND'
    }
  ]

  const prucashbenefitColumns = [
    {
      title: cep('PruCashNo'),
      name: 'pruCashNo'
    },
    {
      title: t('CashReleaseDate'),
      name: 'cashReleaseDate'
    },
    {
      title: t('PrincipalAmount'),
      name: 'principalAmount',
      color: () => '#1EA5FC',
      onPress: (index: number) => {
        setLoanType(LoanType.EO)
        setIsOpen(true)
        setLoadingModal(true)
        const prms = {
          data: {
            policyNo: props.id,
            loanNo: prucashBenefits?.[index].pruCashNo
              ? Number(prucashBenefits?.[index]?.pruCashNo ?? 0) < 10
                ? `0${prucashBenefits?.[index].pruCashNo}`
                : `${prucashBenefits?.[index].pruCashNo}`
              : '',
            loanType: LoanType.EO
          }
        }
        getPruCashBenDetail(prms)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      title: t('InterestAmount'),
      name: 'interestAmount',
      color: () => '#1EA5FC',
      onPress: (index: number) => {
        setLoanType(LoanType.EI)
        setIsOpen(true)
        setLoadingModal(true)
        const prms = {
          data: {
            policyNo: props.id,
            loanNo: prucashBenefits?.[index].pruCashNo
              ? Number(prucashBenefits?.[index]?.pruCashNo ?? 0) < 10
                ? `0${prucashBenefits?.[index].pruCashNo}`
                : `${prucashBenefits?.[index].pruCashNo}`
              : '',
            loanType: LoanType.EI
          }
        }
        getPruCashBenDetail(prms)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      title: t('TotalAmount'),
      name: 'totalPerPeriod'
    },
    {
      title: t('ReceivedDate'),
      name: 'receiveDate'
    },
    {
      title: t('ReceivedAmount'),
      name: 'receiveAmount'
    }
    // {
    //   title: cep('PaymentNo'),
    //   name: 'paymentno'
    // }
  ]

  const getPruCashBenDetail = (data: PruCashBenefitReq) =>
    pipe(
      getPruCashBenInfo(data),
      ZIO.map((res) => {
        const loanData = res
          ? res.sort(function (a, b) {
              const timeB = b?.transDate?.split('/').reverse().join('/') ?? ''
              const timeA = a?.transDate?.split('/').reverse().join('/') ?? ''

              const newB = new Date(timeB)

              const newA = new Date(timeA)

              if (newB.getTime() === newB.getTime()) {
                return b?.transNo && a?.transNo ? +b?.transNo - +a?.transNo : newB.valueOf() - newA.valueOf()
              }
              return newB.valueOf() - newA.valueOf()
            })
          : []
        setLoadingModal(false)
        setLoanDetail(loanData)
      }),
      ZIO.unsafeRun([])
    )

  const AValueDetailColumns = [
    {
      key: 'ldaov',
      title: cep('LoanNo'),
      name: 'no'
    },
    {
      key: 'ldaov1',
      title: cep('TransactionDate'),
      name: 'loanStartDate'
    },
    {
      key: 'paaov',
      title: cep('PrincipalAmount'),
      name: 'principalAmount'
    },
    {
      key: 'cbaov',
      title: cep('CurrentBalance'),
      color: () => '#1EA5FC',
      name: 'currentBalance',
      onPress: (index: number) => {
        setLoanType(LoanType.AO)
        setLoadingModal(true)
        setIsOpen(true)
        const prms = {
          data: {
            policyNo: props.id,
            loanNo: aplValue?.[index].loanNumber
              ? Number(aplValue?.[index]?.loanNumber ?? 0) < 10
                ? `0${aplValue?.[index].loanNumber}`
                : `${aplValue?.[index].loanNumber}`
              : '',
            loanType: LoanType.AO
          }
        }
        getPruCashBenDetail(prms)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: 'aiaov',
      title: cep('AssuredInterest'),
      color: () => '#1EA5FC',
      name: 'accuredInterest',
      onPress: (index: number) => {
        setIsOpen(true)
        setLoadingModal(true)
        setLoanType(LoanType.AI)
        const prms = {
          data: {
            policyNo: props.id,
            loanNo: aplValue?.[index].loanNumber
              ? Number(aplValue?.[index]?.loanNumber ?? 0) < 10
                ? `0${aplValue?.[index].loanNumber}`
                : `${aplValue?.[index].loanNumber}`
              : '',
            loanType: LoanType.AI
          }
        }
        getPruCashBenDetail(prms)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: 'piaoe',
      title: cep('PendingInterest'),
      name: 'pendingInterest',
      styles: { textAlign: 'right' }
    },
    {
      key: 'ttaov',
      title: cep('Total'),
      name: 'policyLoanTotal',
      styles: { textAlign: 'right' }
    }
    // {
    //   key: 'pmd',
    //   title: cep('PremiumDate'),
    //   name: 'premiumDate',
    //   styles: { textAlign: 'right' }
    // }
    // {
    //   key: 'dtaov',
    //   title: cep('DutyStamp'),
    //   name: 'dutyStamp',
    //   styles: { textAlign: 'right' }
    // }
  ]
  const OValueDetailColumns = [
    {
      key: 'ldov',
      title: cep('LoanNo'),
      name: 'no'
    },
    {
      key: 'ldov',
      title: cep('TransactionDate'),
      name: 'loanStartDate'
    },
    {
      key: 'paov',
      title: cep('PrincipalAmount'),
      name: 'principalAmount'
    },
    {
      key: 'cbov',
      title: cep('CurrentBalance'),
      color: () => '#1EA5FC',
      name: 'currentBalance',
      onPress: (index: number) => {
        setLoanType(LoanType.LO)
        setIsOpen(true)
        setLoadingModal(true)
        const prms = {
          data: {
            policyNo: props.id,
            loanNo: oplValue?.[index].loanNumber
              ? Number(oplValue?.[index]?.loanNumber ?? 0) < 10
                ? `0${oplValue?.[index].loanNumber}`
                : `${oplValue?.[index].loanNumber}`
              : '',
            loanType: LoanType.LO
          }
        }
        getPruCashBenDetail(prms)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: 'aiov',
      title: cep('AssuredInterest'),
      color: () => '#1EA5FC',
      name: 'accuredInterest',
      onPress: (index: number) => {
        setIsOpen(true)
        setLoadingModal(true)
        setLoanType(LoanType.LI)
        const prms = {
          data: {
            policyNo: props.id,
            loanNo: oplValue?.[index].loanNumber
              ? Number(oplValue?.[index]?.loanNumber ?? 0) < 10
                ? `0${oplValue?.[index].loanNumber}`
                : `${oplValue?.[index].loanNumber}`
              : '',
            loanType: LoanType.LI
          }
        }
        getPruCashBenDetail(prms)
      },
      styleCss: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
      }
    },
    {
      key: 'pioe',
      title: cep('PendingInterest'),
      name: 'pendingInterest',
      styles: { textAlign: 'right' }
    },
    {
      key: 'ttov',
      title: cep('Total'),
      name: 'policyLoanTotal',
      styles: { textAlign: 'right' }
    }
    // {
    //   key: 'payno',
    //   title: cep('PaymentNo'),
    //   name: 'paymentNo',
    //   styles: { textAlign: 'right' }
    // }
    // {
    //   key: 'metpay',
    //   title: cep('PaymentMethod'),
    //   name: 'paymentMethod',
    //   styles: { textAlign: 'right' }
    // }
    // {
    //   key: 'dtov',
    //   title: cep('DutyStamp'),
    //   name: 'dutyStamp',
    //   styles: { textAlign: 'right' }
    // }
  ]

  const dataTotalApl = [
    {
      title: cep('CurrentBalance'),
      value: aplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(aplValue, (o) => {
              return o.currentBalance ? o.currentBalance : 0
            })
          )
        : '0'
    },
    {
      title: cep('AssuredInterest'),
      value: aplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(aplValue, (o) => {
              return o.accuredInterest ? o.accuredInterest : 0
            })
          )
        : '0'
    },
    {
      title: cep('PendingInterest'),
      value: aplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(aplValue, (o) => {
              return o.pendingInterest ? o.pendingInterest : 0
            })
          )
        : '0'
    },
    {
      title: cep('Total'),
      value: aplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(aplValue, (o) => {
              return o.policyLoanTotal ? o.policyLoanTotal : 0
            })
          )
        : '0'
    }
    // {
    //   title: cep('Total'),
    //   value: aplValue
    //     ? PulseOpsFormat.thousandSepartor(
    //         _.sumBy(aplValue, (o) => {
    //           return o.dutyStamp ? o.dutyStamp : 0
    //         })
    //       )
    //     : '0'
    // }
  ]
  const dataTotalOpl = [
    {
      title: cep('CurrentBalance'),
      value: oplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(oplValue, (o) => {
              return o.currentBalance ? o.currentBalance : 0
            })
          )
        : '0'
    },
    {
      title: cep('AssuredInterest'),
      value: oplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(oplValue, (o) => {
              return o.accuredInterest ? o.accuredInterest : 0
            })
          )
        : '0'
    },
    {
      title: cep('PendingInterest'),
      value: oplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(oplValue, (o) => {
              return o.pendingInterest ? o.pendingInterest : 0
            })
          )
        : '0'
    },
    {
      title: cep('Total'),
      value: oplValue
        ? PulseOpsFormat.thousandSepartor(
            _.sumBy(oplValue, (o) => {
              return o.policyLoanTotal ? o.policyLoanTotal : 0
            })
          )
        : '0'
    }
    // {
    //   title: cep('Total'),
    //   value: oplValue
    //     ? PulseOpsFormat.thousandSepartor(
    //         _.sumBy(oplValue, (o) => {
    //           return o.dutyStamp ? o.dutyStamp : 0
    //         })
    //       )
    //     : '0'
    // }
  ]

  const OptionBillingFrequency = React.useMemo(() => {
    return FrequencyMapping.FrequencyOption.filter((x) => x.value !== props?.productTypeInfo?.data.billFreq).map(
      (item) => ({
        label: t(item.label),
        value: item.value
      })
    )
  }, [props?.productTypeInfo?.data.billFreq])

  const billChange = [
    {
      id: 1,
      label: cep('CurrentBillingFrequency'),
      value: FrequencyMapping.get(t)(billingChange?.currentBillingFrequency as Frequency) ?? '-'
    },
    {
      id: 2,
      label: cep('CurrentInstallmentPremium'),
      value: PulseOpsFormat.thousandSepartor(billingChange?.currentInstallmentPremium),
      suffix: 'VND'
    },
    {
      id: 3,
      label: cep('EffectiveDateOfNewFrequency'),
      value: billingChange?.effectiveDateOfNewFrequency ?? '-'
    },
    {
      id: 4,
      label: cep('NewBillingFrequency'),
      render: () => {
        return (
          <Controller
            control={form.control}
            name="newBillingChange"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <View style={{ marginRight: 20 }}>
                <Select
                  value={value}
                  onChange={onChange}
                  options={OptionBillingFrequency}
                  disabled={disabledNewBillFre}
                  errorMessage={bindError}
                  labelStyle={disabledNewBillFre ? { opacity: 0.5 } : {}}
                  showUnderline={disabledNewBillFre ? false : true}
                  placeholder={disabledNewBillFre ? '--' : ''}
                />
              </View>
            )}
          />
        )
      }
    },
    {
      id: 5,
      label: cep('NewInstallmentPremium'),
      value: PulseOpsFormat.thousandSepartor(newInstallment ?? 0),
      suffix: 'VND'
    },
    {
      id: 6,
      label: cep('RequirePayingAmount'),
      value: PulseOpsFormat.thousandSepartor(repayinAmount ?? 0),
      suffix: 'VND',
      color: true
    }
  ]

  const billChangeReversal = [
    {
      id: 1,
      label: cep('CurrentBillingFrequency'),
      value: FrequencyMapping.get(t)(billingChangeInfo?.currentBillingFrequency as Frequency) ?? '-'
    },
    {
      id: 2,
      label: cep('CurrentInstallmentPremium'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.currentInstallmentPremium),
      suffix: 'VND'
    },
    {
      id: 3,
      label: cep('DateOfTransactionAutomaticPolicyLoan'),
      value: billingChangeInfo?.automaticPolicyLoanDate ?? '-'
    },
    {
      id: 4,
      label: cep('OldBillingFrequency'),
      value: FrequencyMapping.get(t)(billingChangeInfo?.oldBillingFrequency as Frequency) ?? '-'
    },
    {
      id: 5,
      label: cep('OldInstallmentPremium'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.oldInstallmentPremium),
      suffix: 'VND'
    },
    {
      id: 6,
      label: cep('AutomaticPolicyLoanInterest'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.automaticPolicyLoanInterest),
      suffix: 'VND'
    },
    {
      id: 7,
      label: cep('Requirepayinamount'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.requirePayingAmount),
      suffix: 'VND',
      color: true
    },
    {
      id: 8,
      label: cep('PaymentLastDay'),
      value: billingChangeInfo?.paymentLastDay ?? '-'
    }
  ]

  const billChangeReversalNotPayIn = [
    {
      id: 1,
      label: cep('CurrentBillingFrequency'),
      value: FrequencyMapping.get(t)(billingChangeInfo?.currentBillingFrequency as Frequency) ?? '-'
    },
    {
      id: 2,
      label: cep('CurrentInstallmentPremium'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.currentInstallmentPremium),
      suffix: 'VND'
    },
    {
      id: 3,
      label: cep('DateOfTransactionAutomaticPolicyLoan'),
      value: billingChangeInfo?.automaticPolicyLoanDate ?? '-'
    },
    {
      id: 4,
      label: cep('OldBillingFrequency'),
      value: FrequencyMapping.get(t)(billingChangeInfo?.oldBillingFrequency as Frequency) ?? '-'
    },
    {
      id: 5,
      label: cep('OldInstallmentPremium'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.oldInstallmentPremium),
      suffix: 'VND'
    },
    {
      id: 6,
      label: cep('AutomaticPolicyLoanInterest'),
      value: PulseOpsFormat.thousandSepartor(billingChangeInfo?.automaticPolicyLoanInterest),
      suffix: 'VND'
    },
    {
      id: 7,
      label: cep('PaymentLastDay'),
      value: billingChangeInfo?.paymentLastDay ?? '-'
    }
  ]

  React.useEffect(() => {
    if (!isEmpty(billingChangeInfo)) {
      const aplDateVal = billingChangeInfo?.aplDate ? billingChangeInfo?.aplDate : null
      if (aplDateVal !== null) {
        const today = new Date()
        const date = aplDateVal.split('/').reverse().join('/')
        const aplDate = new Date(date).setDate(new Date(date).getDate() + 120)
        const PLDCompare = new Date(
          // aplDate.getFullYear(),
          // aplDate.getMonth(),
          // aplDate.getDate(),
          // 23,
          // 59,
          // 59
          aplDate
        )

        const todayCompare = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)

        if (todayCompare.valueOf() > PLDCompare.valueOf()) {
          //show message
          setErrorBilingChange(i18next.t('message:MS020029'))
        }
      }
    }
  }, [billingChangeInfo])

  const billChangeHistoryColumn: Columns[] = [
    {
      key: 1,
      title: cep('DateLoan'),
      name: 'tranDate'
    },
    {
      key: 2,
      title: cep('OldBillingFrequency'),
      name: 'oldbillfrenquency'
    },
    {
      key: 3,
      title: cep('NewBillingFrequency'),
      name: 'newbillfrenquency'
    },
    {
      key: 4,
      title: cep('Code'),
      name: 'code'
    },
    {
      key: 5,
      title: cep('Desciption'),
      name: 'description'
    }
  ]

  const bonusSuerrenderInfo = [
    {
      id: 1,
      label: cep('BonusValue'),
      value: PulseOpsFormat.thousandSepartor(bonusSurrender?.bonusValue) + ' VND'
    },
    {
      id: 2,
      label: cep('CurrentBalanceAPL'),
      value: PulseOpsFormat.thousandSepartor(bonusSurrender?.currentBalance) + ' VND'
    },
    {
      id: 3,
      label: cep('InterestAPL'),
      value: PulseOpsFormat.thousandSepartor(bonusSurrender?.interest) + ' VND'
    },
    {
      id: 4,
      label: cep('BonusValueAmountToSurrender'),
      suffix: 'VND',
      required: true,
      render: () => {
        return (
          <Input
            value={''}
            inputType="money"
            containerStyle={{ marginEnd: 30 }}
            inputStyle={{ color: '#ED1B2E', fontSize: 18, fontWeight: 'bold' }}
            maxLength={12}
            onChange={(e) => debounceBonusValueAmountSurrenderFn(e, productInfo, bonusSurrender)}
          />
        )
      }
    },
    {
      id: 5,
      label: cep('BonusReserveValue'),
      value: PulseOpsFormat.thousandSepartor(bonusReserveValue),
      suffix: 'VND',
      color: true
    },
    {
      id: 6,
      label: cep('BonusReserveRemainValue'),
      value: PulseOpsFormat.thousandSepartor(bonusReserveRemainValue ?? bonusSurrender?.bonusReserveRemainValue),
      suffix: 'VND'
    }
  ]

  const fullSurrenderData = [
    {
      id: 1,
      label: cep('CashDeposit'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.cashDeposits) + ' VND'
    },
    {
      id: 2,
      label: cep('SuspenseAmount'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.suspenseAmount) + ' VND'
    },
    {
      id: 3,
      label: cep('PolicyLoan'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.policyLoan) + ' VND'
    },
    {
      id: 4,
      label: cep('PolicyDebt'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.policyDebt) + ' VND'
    },
    {
      id: 5,
      label: cep('SurrenderChargePercent'),
      value: fullSurrender?.surrenderChargePercent ?? '-'
    },
    {
      id: 6,
      label: cep('SurrenderCharge'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.surrenderCharge) + ' VND'
    },
    {
      id: 7,
      label: cep('TotalSurrenderValueAmount'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.total) + ' VND'
    },
    {
      id: 8,
      label: cep('NetSurrenderValueAmount'),
      value: PulseOpsFormat.thousandSepartor(fullSurrender?.netSurrenderValueAmount) + ' VND'
    }
  ]

  const AnnualColumns = [
    // {
    //   title: cep('PaymentNo'),
    //   name: 'no',
    //   color: () => '#1EA5FC',
    //   // eslint-disable-next-line @typescript-eslint/no-empty-function
    //   onPress: () => {},
    //   styleCss: {
    //     textDecorationLine: 'underline',
    //     fontWeight: 'bold'
    //   }
    // },
    {
      title: cep('EffectiveDateBonusHistory'),
      name: 'effectiveDate'
    },
    {
      title: cep('TransactionNoBonusHistory'),
      name: 'transactionNo'
    },
    {
      title: cep('TransactionDateBonusHistory'),
      name: 'transactionDate'
    },

    {
      title: cep('CodeBonusHistory'),
      name: 'transCode'
    },
    {
      title: cep('AccountingAmount'),
      name: 'originAmount'
    }
  ]

  const calcBonusReverse = (
    amount: string,
    productData: IProductInfo | null | undefined,
    bonusSurrenderData: IBonusSurrender | null
  ) => {
    const bValue = bonusSurrenderData?.bonusValue ? bonusSurrenderData?.bonusValue : 0

    if (isEmpty(amount)) {
      setBonusReserveRemainValue(bonusSurrenderData?.bonusValue ?? 0)
      setBonusReserveValue(0)
      return
    }

    if (!Number.isNaN(bValue) && Number(bValue) === 0) {
      showToast(
        i18next.t('message:MS050264', {
          bonusAmount: PulseOpsFormat.thousandSepartor(bValue) + ' VND'
        }),
        'error'
      )
      return
    }

    if (Number(amount) > Number(bValue)) {
      showToast(
        i18next.t('message:MS050264', {
          bonusAmount: PulseOpsFormat.thousandSepartor(bValue) + ' VND'
        }),
        'error'
      )
      return
    }

    pipe(
      getPolicyBonusReverse({
        data: {
          contractNumber: props.id,
          effectiveDate: undefined,
          contractStatus: productData?.status,
          productType: productData?.productType,
          coverageId: productData?.basicCode,
          amountToSurrender: +amount,
          bonusValue: bValue,
          bonusReserveRemainValue: bonusSurrenderData?.bonusReserveRemainValue
            ? bonusSurrenderData?.bonusReserveRemainValue
            : 0
        }
      }),
      ZIO.map((bonus) => {
        setBonusReserveRemainValue(bonus.data.bonusReserveRemainValue)
        setBonusReserveValue(bonus.data.bonusReserveValue)
      }),
      ZIO.unsafeRun({})
    )
  }

  const debounceBonusValueAmountSurrenderFn = React.useCallback(_.debounce(calcBonusReverse, 500), [])

  const columnsPruCashDetail = [
    {
      key: '1',
      title: cep('Type'),
      name: 'businessKey'
    },
    {
      key: '2',
      title: cep('EffectiveDate'),
      name: 'source'
    },
    {
      key: '3',
      title: cep('TransNo'),
      name: 'policyNumber'
    },
    {
      key: '4',
      title: cep('G/LCode'),
      name: 'clientNumber'
    },
    {
      key: '4',
      title: cep('Transdate'),
      name: 'poClientNumber'
    },
    {
      key: '5',
      title: cep('Code'),
      name: 'poName'
    },
    {
      key: '6',
      title: cep('AccountingAmount'),
      name: 'poName'
    }
  ]

  const { billingChangeHisData } = pipe(
    getBillingChangeHistory(props.id),
    ZIO.map((res) => {
      let list = []

      for (let item of res?.data) {
        const sortedData = item.billingChangeHistories
          .sort(function (a: { effectiveDate: string }, b: { effectiveDate: string }) {
            const timeB = b?.effectiveDate?.split('/').reverse().join('/') ?? ''
            const timeA = a?.effectiveDate?.split('/').reverse().join('/') ?? ''

            const newB = new Date(timeB)
            const newA = new Date(timeA)

            return newB.valueOf() - newA.valueOf()
          })
          .slice(0, 1)

        let x = {
          tranDate: item.tranDate,
          code: item?.code,
          description: item?.description,
          oldbillfrenquency: FrequencyMapping.get(t)(sortedData?.[0]?.oldBillingFrequency as Frequency) ?? '-',
          newbillfrenquency: FrequencyMapping.get(t)(sortedData?.[0]?.newBillingFrequency as Frequency) ?? '-'
        }

        list.push(x)
      }

      return {
        billingChangeHisData: list
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    billingChangeHisData: []
  }

  return (
    <Container loading={loading} display={true}>
      <Panel title={t('ContractValue')}>
        <FieldList dataSource={contractValueData ? contractValueData : []} />
      </Panel>
      <Panel title={t('PrucashBenefitDetail')}>
        <CepTableField
          columns={prucashbenefitColumns}
          dataSource={
            prucashBenefits
              ? prucashBenefits.map((item) => ({
                  pruCashNo: item.pruCashNo ?? '-',
                  cashReleaseDate: item.cashReleaseDate ?? '-',
                  principalAmount: PulseOpsFormat.thousandSepartor(item.principalAmount) ?? 0,
                  interestAmount: PulseOpsFormat.thousandSepartor(item.interestAmount) ?? 0,
                  totalPerPeriod: PulseOpsFormat.thousandSepartor(item.totalPerPeriod) ?? 0,
                  receiveDate: item.receiveDate ?? '-',
                  receiveAmount: PulseOpsFormat.thousandSepartor(item.receiveAmount) ?? 0
                }))
              : []
          }
        />
      </Panel>
      <Panel title={cep('APLValueDetail')}>
        <CepTableField
          columns={AValueDetailColumns}
          dataSource={
            aplValue
              ? aplValue.map((x, i) => ({
                  no: x.loanNumber,
                  loanStartDate: x.loanStartDate,
                  principalAmount: PulseOpsFormat.thousandSepartor(x.principalAmount),
                  currentBalance: PulseOpsFormat.thousandSepartor(x.currentBalance),
                  accuredInterest: PulseOpsFormat.thousandSepartor(x.accuredInterest),
                  pendingInterest: PulseOpsFormat.thousandSepartor(x.pendingInterest),
                  policyLoanTotal: PulseOpsFormat.thousandSepartor(x.policyLoanTotal),
                  premiumDate: x.premiumDate ?? '-'
                  // dutyStamp: PulseOpsFormat.thousandSepartor(x.dutyStamp)
                }))
              : []
          }
          totalCell={true}
          dataTotalCell={dataTotalApl}
        />
      </Panel>
      <Panel title={cep('OPLValueDetail')}>
        <CepTableField
          columns={OValueDetailColumns}
          dataSource={
            oplValue
              ? oplValue.map((x, i) => ({
                  no: x.loanNumber,
                  loanStartDate: x.loanStartDate,
                  principalAmount: PulseOpsFormat.thousandSepartor(x.principalAmount),
                  currentBalance: PulseOpsFormat.thousandSepartor(x.currentBalance),
                  accuredInterest: PulseOpsFormat.thousandSepartor(x.accuredInterest),
                  pendingInterest: PulseOpsFormat.thousandSepartor(x.pendingInterest),
                  policyLoanTotal: PulseOpsFormat.thousandSepartor(x.policyLoanTotal),
                  paymentNo: '-'
                  // dutyStamp: PulseOpsFormat.thousandSepartor(x.dutyStamp)
                }))
              : []
          }
          totalCell={true}
          dataTotalCell={dataTotalOpl}
        />
      </Panel>
      <Panel title={cep('BillingChangeHistory')}>
        <CepTableField columns={billChangeHistoryColumn} dataSource={billingChangeHisData ?? []} />
      </Panel>
      <Panel title={cep('BillingChange')}>
        <CepFieldList dataSource={billChange} />
      </Panel>
      <CepPanel
        title={cep('BillingChangeReversal')}
        errorMessageTitle={errorBillingChange ? `( ${errorBillingChange} )` : ''}
      >
        <CepFieldList dataSource={errorBillingChange === undefined ? billChangeReversal : billChangeReversalNotPayIn} />
      </CepPanel>
      <Panel title={cep('BonusSuerrenderInfo')}>
        <CepFieldList dataSource={bonusSuerrenderInfo} />
      </Panel>
      <Panel title={cep('BonusHistory')}>
        <CepTableField
          columns={AnnualColumns}
          dataSource={
            props?.bonusHistoryInfo
              ? props?.bonusHistoryInfo.map((item) => ({
                  effectiveDate: item.effectiveDate ?? '-',
                  transactionNo: item.transactionNo ?? '-',
                  transactionDate: item.transactionDate ?? '-',
                  transCode: item.transCode ?? '-',
                  originAmount: PulseOpsFormat.thousandSepartor(item.originAmount) ?? 0
                }))
              : []
          }
        />
      </Panel>
      <Panel title={cep('FullSurrender')}>
        <FieldList dataSource={fullSurrenderData} />
      </Panel>
      <ModalComponent
        title={titleModalPopup[loanType].toUpperCase()}
        visible={isOpen}
        onClose={() => {
          setIsOpen(false)
          setLoadingModal(false)
        }}
        actions={[]}
        modalWidth={width >= 1082 ? 1082 : 1024}
        titleStyle={{ color: '#000000' }}
        // contentStyle={{overflow: 'scroll'}}
      >
        <View style={styles.borderContainer}>
          <View style={{ marginHorizontal: 22, marginVertical: 22, overflow: 'hidden' }}>
            <Text style={{ color: '#4F4F4F', fontWeight: '700', marginBottom: 18 }}>
              {titleLoanType[loanType].toUpperCase()}
            </Text>
            <TableContainer component={Paper} style={{ maxHeight: 450 }}>
              {loadingModal ? (
                <CanView condition={loadingModal}>
                  <View style={styles.loadingContainer}>
                    <ActivityIndicator size="large" color="red" />
                  </View>
                </CanView>
              ) : (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      {columnsPruCashDetail.map((column, index) => (
                        <TableCell
                          key={column.key ? column.key : `table-header-${index}-${column.name}`}
                          align="left"
                          style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }}
                        >
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loanDetail.length > 0 ? (
                      loanDetail.map((data, i) => (
                        <TableRow key={i}>
                          <TableCell> {data.loanType ?? '-'} </TableCell>
                          <TableCell> {data.effectiveDate ?? '-'} </TableCell>
                          <TableCell> {data.transNo ?? '-'} </TableCell>
                          <TableCell> {data.glNo ?? '-'} </TableCell>
                          <TableCell> {data.transDate ?? '-'} </TableCell>
                          <TableCell> {data.transCode ?? '-'} </TableCell>
                          <TableCell> {PulseOpsFormat.thousandSepartor(data.accountingAmount) ?? '-'} </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                          {t('common:Nodata')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </View>
        </View>
      </ModalComponent>
    </Container>
  )
}
const styles = StyleSheet.create({
  sectionRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  col_45: {
    width: '100%',
    maxWidth: '48%'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  borderContainer: {
    marginHorizontal: 20,
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 8
  }
})
