export namespace RiderReinstatementModel {  
  export interface LifeAssured {
    clientNo: string,
    healthStateChange: boolean,
    occupationChange: boolean,
    curOccupation: string,
    newOccupation: string,
    activityChange: boolean,
    newActivity: string,
    companyChange: boolean,
    newCompany: string,
    agentCode: string,
    riderList: RiderInfo[]
  }

  export interface AccessData {
    coverageCode: string,
    contractStatus: string,
    notExistAnyRider: boolean,
    checkDate: boolean
  }

  export interface RiderInfo {
    coverageCode: string,
    riskCommDate: string,
    lapsedDate: string,
    sumAssured: number,
    riderPremium: number,
  }
 
  export interface RiderReinstatementSubmitData {
    reinsFee: number,
    lifeAssuredList: LifeAssured[]
  }
}