import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { HomeScreen } from './HomeScreen'
import { LandingPageCCCDStackParamList } from './LandingPageCCCDStackParamList'
import { PolicyListScreen } from './PolicyListScreen'
import { SuccessScreen } from './SuccessScreen'
import { ChangeNationalIDCard } from './ChangeNationalIDCard'
import { UploadCCCD } from './UploadCCCD'
import { Identifycard } from './identifycard'
import { OTPRequestScreen } from './OTPRequestScreen'
const Stack = createStackNavigator<LandingPageCCCDStackParamList>()

export const LandingPageCCCDStack = () => {
  return (
    <Stack.Navigator initialRouteName="HomeScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="PolicyListScreen" component={PolicyListScreen} />
      <Stack.Screen name="ChangeNationalIdCardScreen" component={ChangeNationalIDCard} />
      <Stack.Screen name="UploadCCCD" component={UploadCCCD} />
      <Stack.Screen name="IdentifycardScreen" component={Identifycard} />
      <Stack.Screen name="OTPRequestScreen" component={OTPRequestScreen} />
      <Stack.Screen name="SuccessScreen" component={SuccessScreen} />
    </Stack.Navigator>
  )
}
