import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    clientId: Maybe(t.string),
    firstName: Maybe(t.string),
    surName: Maybe(t.string),
    nationality: Maybe(t.string),
    nationalityName: Maybe(t.string),
    addressDetails: Maybe(
      t.type({
        // ADDRESS: Maybe(
        //   t.type({
        //     line1: Maybe(t.string),
        //     city: Maybe(t.string),
        //     district: Maybe(t.string),
        //     subDistrict: Maybe(t.string),
        //     countryCode: Maybe(t.string),
        //     location: Maybe(t.string)
        //   })
        // ),
        FOREIGN_ADDRESS: Maybe(
          t.type({
            line1: Maybe(t.string),
            countryCode: Maybe(t.string),
            country: Maybe(t.string)
          })
        )
      })
    ),
    attributesExt: Maybe(
      t.type({
        clientNum: Maybe(t.string),
        idType: Maybe(t.string),
        idNumber: Maybe(t.string),
        issuedBy: Maybe(t.string),
        issuedDate: Maybe(t.string),
        usTaxDeclarationFrom: Maybe(t.union([t.boolean, t.string])),
        isCheckChangeFullNameGroup: Maybe(t.boolean),
        isCheckChangeCardGroup: Maybe(t.boolean),
        isCheckChangeNationalityGroup: Maybe(t.boolean),

        // AML check
        NATIONALITY_2: Maybe(t.string),
        nationality2Name: Maybe(t.string),
        taxResidencyCountry: Maybe(t.string),
        taxResidencyCountryName: Maybe(t.string)
      })
    )
  })
})

const Pulse4Ops = Base(
  t.type({
    source: t.literal(SourceType.PULSE4OPS),
    payload: payloadPulse4Ops
  })
)

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadPulse4Ops
  })
)

const Pruonline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPulse4Ops
  })
)

export const ChangeOtherInfomationPersonal = t.union([Pulse4Ops, Zalo, Pruonline])
export type ChangeOtherInfomationPersonal = t.TypeOf<typeof ChangeOtherInfomationPersonal>
