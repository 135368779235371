import React, { ChangeEvent } from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { Select as MaterialSelect, MenuItem, InputBase } from '@material-ui/core'
import { assets } from '../../assets'
import { ControlProps } from '../FormProps'

export interface SelectTableOption {
  label: string
  value: string
}

export type SelectTableProps = ControlProps<SelectTableOption | null> & {
  id: string
  options: SelectTableOption[]
  style?: {
    backgroundColor?: string
  }
  disabled?: boolean
  iconSelect?: React.ReactNode
}

export const SelectTable = (props: SelectTableProps) => {
  const { id, options, errorMessage, iconSelect = <assets.ArrowSelectTable /> } = props
  const placeholder = ''

  const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (props.onChange) {
      const value = event.target.value as string
      const option = options.find((option) => option.value === value) || null
      props.onChange(option)
    }
  }
  return (
    <View>
      <View
        style={{
          borderColor: '#D3DCE6',
          borderWidth: 1,
          borderRadius: 8,
          paddingHorizontal: 5,
          backgroundColor: props.style?.backgroundColor || 'transparent'
        }}
      >
        <MaterialSelect
          value={props.value?.value ?? undefined}
          labelId={`table-select-${id}`}
          displayEmpty
          IconComponent={() => (
            <View style={styles.dropdownIcon}>
              <Text>{iconSelect}</Text>
            </View>
          )}
          input={<InputBase />}
          onChange={onChange}
          renderValue={(value) => {
            const option = options.find((option) => option.value === value)
            const label = option?.label
            if (!label) {
              return <Text style={styles.placeholder}>{placeholder}</Text>
            }
            return <Text style={styles.value}>{label}</Text>
          }}
          disabled={props.disabled === true}
          onBlur={props.onBlur}
        >
          {placeholder ? (
            <MenuItem value={''} disabled>
              <Text>{placeholder}</Text>
            </MenuItem>
          ) : null}

          {(options || []).map((item, _index) => (
            <MenuItem value={item.value} key={`${id}-${_index}`}>
              <Text>{item.label}</Text>
            </MenuItem>
          ))}
        </MaterialSelect>
      </View>
      {!!errorMessage && <Text style={{ color: '#ED2B1C' }}>{errorMessage}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 2
  },

  dropdownIcon: {
    width: 16,
    height: 16
  },

  placeholder: {
    color: '#B0B0B0',
    fontSize: 15,
    fontWeight: 'normal'
  },

  value: {
    color: '#000000',
    fontSize: 15,
    fontWeight: 'normal'
  },

  underline: {
    height: 1,
    backgroundColor: '#D3DCE6'
  }
})
