import * as React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import * as core from '@material-ui/core'
import { withStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { ControlProps } from '@pulseops/common'
import i18next from 'i18next'
import { assets } from '../assets'
import { FormControl } from './styled'

type DatePickerProps = ControlProps<Date | null> & {
  label?: string
  alwaysShow?: boolean
  required?: boolean
  disabled?: boolean
  minDate?: ParsableDate
  maxDate?: ParsableDate
  maxDateMessage?: string
  minDateMessage?: string
  placeholder?: string
}

export const BorderDatePicker = (props: DatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SC.FormControl error={!!props.errorMessage}>
        <SC.KeyboardDatePicker
          inputVariant="outlined"
          clearable
          label={props.label}
          disabled={props.disabled}
          value={props.value || null}
          keyboardIcon={props.errorMessage ? <assets.IconCalendarGray /> : <assets.IconCalendar />}
          onChange={(date) => {
            if (props.onChange) {
              props.onChange(date)
            }
          }}
          onBlur={props.onBlur}
          placeholder={props.placeholder || 'dd/mm/yyyy'}
          format="dd/MM/yyyy"
          minDate={props.minDate}
          maxDate={props.maxDate}
          maxDateMessage={props.maxDateMessage}
          minDateMessage={props.minDateMessage}
          invalidDateMessage={i18next.t('form:error_invalid')}
        />
        {props.errorMessage ? <core.FormHelperText>{props.errorMessage}</core.FormHelperText> : null}
      </SC.FormControl>
    </MuiPickersUtilsProvider>
  )
}

const SC = {
  KeyboardDatePicker: withStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 8,
        height: 40,
        padding: '5px 10px',
        '& fieldset': {
          borderColor: '#D3DCE6'
        }
      },
      '& .MuiOutlinedInput-input': {
        fontSize: 13,
        padding: 0
      },
      '& .MuiIconButton-root': {
        padding: 0
      },
      '& .MuiFormHelperText-contained': {
        marginLeft: 0,
        marginRight: 0
      }
    }
  })(KeyboardDatePicker),
  FormControl: withStyles({
    root: {
      margin: 0,
      width: '100%',
      minWidth: '120px'
    }
  })(FormControl)
}
