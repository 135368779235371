import { Task, Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { AuthService } from '../auth'
import { PulseOpsFormat } from '../Formatter'
import { POApi } from '../POApi'
import {
  GeneralInfo,
  HistoryC,
  ORG,
  ORGCodex,
  SuspendTask,
  TaskDetailApi,
  WFcommentC,
  WFResponse,
  History,
  QCComplaint,
  QCComplaintC,
  RelatedDoc,
  RelatedDocCodex,
  PolicyDigitalC,
  GeneralInfoDigitalPolicyRes,
  GeneralInfoDigitalProductRes,
  InquiryComplaintData
} from './model'
import { VeriPayloadData } from './model/task-detail/InquiryComplaint'
import { WfApi } from './WfApi'

export namespace TaskDetailService {
  const baseUrl = {
    pulseops: 'pulseops/api/v1'
  }
  export const getTask = (id: string): Task<TaskDetailApi.Type> =>
    POApi.get(`${baseUrl.pulseops}/tasks/${id}`)(TaskDetailApi.codec)

  export const getGeneralInfo = (policyNum: string): Task<GeneralInfo> =>
    pipe(
      ZIO.zipPar(WfApi.getPolicy(policyNum), WfApi.getCustomer(policyNum)),
      ZIO.map(([policy, client]) => WfApi.createGeneralInfo({ policy, client }))
    )

  export const GetSuspends = (data: SuspendTask.InputData) =>
    pipe(
      POApi.post(`pulseops/api/v1/query/process-instances`)(t.unknown)({
        suspended: data.suspended,
        category: data.category,
        size: data.pagination.pageSize,
        start: data.pagination.pageIndex
      }),
      ZIO.map((res) => res),
      ZIO.asVoid
    )

  export const GetOGR = (
    policyNum: string,
    pagination: {
      pageSize: number
      pageNum: number
    },
    sort: {
      order: string
      field: string
    }
  ): Task<ORG> =>
    POApi.post(`pulseops/api/v1/query/on-going-request`)(ORGCodex)({
      policyNum,
      order: sort.order,
      sort: sort.field,
      size: pagination.pageSize,
      start: pagination.pageNum * pagination.pageSize
    })

  export const GetWFComment = (
    processInstanceId: string,
    pagination: {
      pageSize: number
      pageNum: number
    },
    sort: {
      order: string
      field: string
    }
  ): Task<WFResponse> =>
    pipe(
      POApi.getConfig({ params: { processInstanceId, size: pagination.pageSize, start: pagination.pageNum } })(
        `pulseops/api/v1/update-to-core/history`
      )(WFcommentC),
      ZIO.map((x) => ({
        ...x,
        content: x.data.map((y) => ({ ...y, createDate: PulseOpsFormat.dateStrWF(y.createDate, 'DD/MM/YYYY') }))
      }))
    )

  // postAuth()(`pulseops/api/v1/query/on-going-request`)(ORGCodex)({
  //       correlationId,
  //       order: sort.order,
  //       sort: sort.field,
  //       size: pagination.pageSize,
  //       start: pagination.pageNum * pagination.pageSize
  //     })
  export const GetRelatedHistory = (processInstanceId: string): Task<History[]> =>
    pipe(
      POApi.get(`pulseops/api/v1/query/activity-log?processInstanceId=${processInstanceId}`)(HistoryC),
      ZIO.map((data) =>
        data.map(({ user, workFlowStage, createdDate, fields }) => {
          const fieldsData = fields
            ? fields.map((x) => ({
              ...x,
              oldValue: x.oldValue || '',
              newValue: x.newValue || ''
            }))
            : []
          return {
            fields: fieldsData,
            field: fieldsData.map(({ name }) => name).join('\n'),
            newValue: fieldsData.map(({ newValue }) => newValue).join('\n'),
            originalValue: fieldsData.map(({ oldValue }) => oldValue || '').join('\n'),
            name: user || '',
            email: user,
            createdDate: PulseOpsFormat.toCurrentTimeZone(
              createdDate + ':00 GMT+00:00',
              'DD/MM/YYYY HH:mm:ss Z',
              'DD/MM/YYYY HH:mm'
            ),
            workFlowStage: workFlowStage || ''
          }
        })
      )
    )

  export const GetQCReason = (processInstanceId: string): Task<QCComplaint> =>
    pipe(
      POApi.get(`pulseops/api/v1/qcscore/${processInstanceId}`)(QCComplaintC),
      ZIO.map((data) => {
        console.log(data.lastUser ? data : { ...data, lastUser: 'System' })
        console.log(!!data.lastUser)
        return data.lastUser ? data : { ...data, lastUser: 'System' }
      })
    )

  export const GetRelatedDoc = (processInstanceId: string): Task<RelatedDoc> =>
    pipe(POApi.get(`pulseops/api/v1/query/document/${processInstanceId}`)(RelatedDocCodex))

  export const getRelatedDocPCWriteOff = (azureBlobNames?: string[], policyNumber?: string): Task<RelatedDoc> =>
    pipe(
      POApi.post(`pulseops/api/v1/query/write-off/document`)(RelatedDocCodex)({ azureBlobNames, policyNumber }),
      ZIO.map((_) => _)
    )

  export const getOnGoingRequestStatus = (correlationId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/query/on-going-request-status/${correlationId}`)(
        t.array(t.union([t.literal('PENDING'), t.literal('IN_PROCESS')]))
      )
    )

  export const getDigitalPolicyFull = (policyNum: string): ZIO<unknown, Throwable, GeneralInfoDigitalPolicyRes> =>
    pipe(
      POApi.get(`pvadigital/digital/policy/${policyNum}`)(
        t.type({
          body: PolicyDigitalC
        })
      ),
      ZIO.map((x) => {
        const Customer = x.body.customerRoles[0].customer
        const basicProduct = x.body.productOptions[0]
        const Client = x.body.clients[0]
        return {
          clients: {
            firstName: Client.firstName,
            surName: Client.surName,
            dob: Client.dob,
            sex: Client.sex,
            nationality: Client.nationality,
            nationalID: Client.externalIds.NATIONAL_ID,
            occupation: {
              desc: Client?.occupation?.desc || ''
            },
            addressDetails: {
              Current: {
                line1: Client.addressDetails.Current.line1,
                line2: Client.addressDetails.Current.line2,
                line3: Client.addressDetails.Current.line3,
                line4: Client.addressDetails.Current.line4,
                country: Client.addressDetails.Current.country
              }
            }
          },
          policy: {
            policyOwner: `${policyNum} - ${x.body.clients[0]?.surName ?? ''} ${x.body.clients[0].firstName}`,
            policyOwnerName: `${x.body.clients[0].surName} ${x.body.clients[0].firstName}`,
            mainLA: `${x.body?.lifeAssured && x.body?.lifeAssured[0]?.surName ? x.body?.lifeAssured[0]?.surName : ''} ${x.body?.lifeAssured && x.body.lifeAssured[0].firstName
              }`,
            contractName: `${basicProduct.product.code} - ${basicProduct.product.fullName}`,
            policyStatus: basicProduct.status,
            sumAssured: `${PulseOpsFormat.thousandSepartor(basicProduct.totalSumAssured)} ${x.body?.paymentOption?.currency || ''
              }`,
            installmentPremium: `${PulseOpsFormat.thousandSepartor(x.body?.paymentOption?.amount)} ${x.body?.paymentOption?.currency || ''
              }`,
            frequency: x.body?.paymentOption?.frequency || '',
            firstIssuedDate: PulseOpsFormat.dateWF(x.body.firstIssueDate, 'YYYY-MM-DD', 'DD/MM/YYYY'),
            premiumCessDate: PulseOpsFormat.dateWF(x.body.endDate, 'YYYY-MM-DD', 'DD/MM/YYYY'),
            riskCommenceDate: PulseOpsFormat.dateWF(x.body.inceptionDate, 'YYYY-MM-DD', 'DD/MM/YYYY'),
            riskCessDate: PulseOpsFormat.dateWF(x.body.endDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
          },
          customer: {
            street: Customer.addressDetails.Current.line4,
            ward: Customer.addressDetails.Current?.line2 || '-',
            district: Customer.addressDetails.Current?.line3 || Customer.addressDetails.Current?.line2 || '-',
            city: Customer.addressDetails.Current?.line4 || Customer.addressDetails.Current?.line1 || '-',
            country: Customer.addressDetails.Current.country,
            email: Customer.contactDetails.email.value,
            mobilePhone: Customer.contactDetails.phone.value || '-',
            po_la_info: []
          }
        }
      })
    )
  export const getDigitalProduct = (policyNum: string): ZIO<unknown, Throwable, GeneralInfoDigitalProductRes> =>
    pipe(
      POApi.get(`pvadigital/digital/policy/${policyNum}`)(
        t.type({
          body: PolicyDigitalC
        })
      ),
      ZIO.map((x) => {
        const basicProduct = x.body.productOptions[0]
        return {
          owner: {
            name: `${policyNum} - ${x.body.clients[0].surName} ${x.body.clients[0].firstName}`,
            life: '-',
            extInfos: [
              {
                componentName: `${basicProduct.product.fullName}`,
                status: basicProduct.status || '-',
                sumAssured: PulseOpsFormat.thousandSepartor(basicProduct.totalSumAssured) || '-',
                installmentPremium: PulseOpsFormat.thousandSepartor(basicProduct.totalPremium) || '-',
                paidToDate: '-',
                premiumCessationDate: PulseOpsFormat.dateWF(basicProduct.riskCessDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
              }
            ]
          },
          laClient: [
            {
              name: `${policyNum} - ${x.body.clients[0].surName} ${x.body.clients[0].firstName}`,
              life: '-',
              extInfos: basicProduct.productComponentOptions.map((y) => {
                return {
                  componentName: `${y.component.name}`,
                  status: y.status || '-',
                  sumAssured: PulseOpsFormat.thousandSepartor(Number(y.options.totalSumAssured)),
                  installmentPremium: PulseOpsFormat.thousandSepartor(Number(y.options.totalPremium)),
                  paidToDate: '-',
                  premiumCessationDate: PulseOpsFormat.dateWF(y.riskCessDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
                }
              })
            }
          ]
        }
      })
    )

  export const saveLog = (
    taskId: string,
    payload: any,
    processInstanceId: string,
    logChange: {
      nameCode: string
      newValue: string
      oldValue: string
    }[]
  ): Task<void> =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((user) =>
        POApi.post(`pulseops/api/v1/payload/save-payload-info/${taskId}`)(t.unknown)({
          logChange,
          payload,
          processInstanceId,
          user: user.email
        })
      ),
      ZIO.asVoid
    )

  export const saveIACPayloadData = (
    taskId: string,
    payload: any,
    processInstanceId: string,
    addDocuments: InquiryComplaintData.AdditionalDocumentList[],
    removeDocuments: InquiryComplaintData.AdditionalDocumentList[],
    logChange: {
      nameCode: string
      newValue: string
      oldValue: string
    }[]
  ): Task<VeriPayloadData> =>
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((user) =>
        POApi.post(`pulseops/api/v1/payload/save-payload-info/${taskId}`)(
          t.type({
            payload: t.unknown
          })
        )({
          logChange,
          payload,
          processInstanceId,
          user: user.email,
          addDocuments,
          removeDocuments
        })
      ),
      ZIO.map((responseData) => {
        const payloadData = responseData.payload as VeriPayloadData
        return payloadData
      })
    )

  export const archiveToFileNet = (processInstanceId: string, author: string, azureBlobNames: string[],
  ) => pipe(
    POApi.post(`pulseops/api/v1/error-handler/re-trigger`)(t.type({
      status: t.string,
      errorMessage: t.string
    }))({
      processInstanceId,
      author,
      azureBlobNames,
      stage: "archive-document-event"
    }),
    ZIO.foldM(
      (error) => ZIO.fail(error),
      (success) => ZIO.effect(() => {
        return {
          ...success,
          azureBlobNames
        }
      })
    )
  )
}
