import * as React from 'react'
import { StyleSheet, View, Modal, ActivityIndicator } from 'react-native'

interface Props {
  visible: boolean
}

export const GlobalLoadingModal = ({ visible }: Props) => {
  return (
    <Modal visible={visible} transparent={true} animationType={'fade'}>
      <View style={styles.modal}>
        <ActivityIndicator size={'large'} color="red" />
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)'
  }
})
