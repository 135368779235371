import { enumC } from '@mxt/zio/codec'
import { PayoutPopup } from './Payout'
import moment from 'moment'
import { PaymentData } from './task-detail-api'
import _ from 'lodash'

export enum PaymentType {
  PAYPREMIUM = 'PAYPREMIUM',
  PAYLOAN = 'PAYLOAN',
  LOAN = 'LOAN',
  TOPUP = 'TOP_UP',
  REINSTATEMENT = 'REINSTATEMENT',
  BANKTRANSFER = 'BANKTRANSFER',
  EWALLET = 'EWALLET',
  EWALLETTRANSFER = 'EWALLETTRANSFER',
  PAID_AT_BANK = 'PAID_AT_BANK',
  REPAY_APL = 'REPAY_APL',
  REPAY_OPL = 'REPAY_OPL',
  CASH_AT_BANK = 'CASH_AT_BANK',
  PREMIUM = 'PREMIUM',
  OTHER = 'OTHER',
  CASH_AT_COUNTER = 'CASH_AT_COUNTER',
  MOMO = 'EWALLET_MOMO'
}
export const PaymentTypeC = enumC(PaymentType)

export const mapPaymentTitleArr = new Map<PayoutPopup.PayoutMethods, string>([
  [PayoutPopup.PayoutMethods.BANKTRANSFER, 'submission:BankTranfer'],
  [PayoutPopup.PayoutMethods.PAID_AT_BANK, 'submission:PaidAtBank'],
  [PayoutPopup.PayoutMethods.MOMO, 'submission:EWalletMomo'],
  [PayoutPopup.PayoutMethods.CASH_AT_COUNTER, 'submission:CashAtCounter']
])

export const getPayeeNotPOData = (payeeDetail: PaymentData.CashOutList, branchName: string) => {
  const payeeInfo = payeeDetail ? payeeDetail[0] : undefined
  const paymentMethod = payeeInfo?.type?.toString() as PayoutPopup.PayoutMethods
  const amount = payeeInfo?.paymentOption.amount ?? 0
  const payeeMethod =
    PaymentType.BANKTRANSFER === payeeInfo?.type ||
    PaymentType.PAID_AT_BANK === payeeInfo?.type ||
    PaymentType.CASH_AT_COUNTER === payeeInfo?.type
  const bankMethod = PaymentType.BANKTRANSFER === payeeInfo?.type || PaymentType.PAID_AT_BANK === payeeInfo?.type
  const payeeName = payeeMethod ? payeeInfo?.attributesExt?.PAYEE_INFO?.payeeName : ''
  // const bankBranchCode = payeeInfo?.bankAccount?.branchCode ?? ''
  const payeeNotPO = (payeeMethod && payeeInfo?.attributesExt?.PAYEE_INFO?.isPayeeNotPO) ?? false
  const dob = payeeMethod ? payeeInfo?.attributesExt?.PAYEE_INFO?.dob : ''

  const bankName = bankMethod ? payeeInfo.bankAccount?.bankName : ''
  const accountName = payeeInfo?.type === PaymentType.BANKTRANSFER ? payeeInfo.bankAccount?.accountName : ''
  const accountNo = payeeInfo?.type === PaymentType.BANKTRANSFER ? payeeInfo.bankAccount?.accountNo : ''
  const bankBranchName = bankMethod ? branchName : ''
  const bankBranchCode = bankMethod ? payeeInfo.bankAccount?.branchCode : ''
  const payeeInfoData: PayoutPopup.Summary = {
    method: paymentMethod,
    methodView: '',
    detail: '',
    amount: amount,
    policyNum: payeeInfo?.policy?.policyNo ?? '',
    poName: payeeName,
    officeCode:
      payeeInfo?.type === PaymentType.CASH_AT_COUNTER
        ? _.get(payeeInfo?.attributesExt?.PVA_CASH_OUT_OPTION_INFO, 'officeCode')
        : '',
    totalPremium: 0,
    bankCode: payeeMethod ? _.get(payeeInfo?.bankAccount, 'bankCode') : '',
    bankName: bankName,
    bankAccountNum: accountNo,
    bankAccountName: accountName,
    bankBranchCode: bankBranchCode,
    bankBranchName: bankBranchName,
    nationalId: '',
    officeBankCode: undefined,
    officeType:
      payeeInfo?.type === PaymentType.CASH_AT_COUNTER
        ? _.get(payeeInfo?.attributesExt?.PVA_CASH_OUT_OPTION_INFO, 'officeType')
        : '',

    payee: payeeNotPO
      ? {
          paymentMethod: paymentMethod,
          isPayeeNotPO: true,
          dob: moment(dob).format('YYYYMMDD') ?? '',
          gender: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'gender') : '',
          phone: payeeMethod ? payeeInfo?.attributesExt?.PAYEE_INFO?.phone || '-' : '-',
          email: payeeMethod ? payeeInfo?.attributesExt?.PAYEE_INFO?.email || '-' : '-',
          salutation: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'salutation') : '',
          married: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'married') : '',
          occupation: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'occupation') : '',
          relationshipWithPO: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'relationshipWithPO') : '',
          nationality: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'nationality') : '',
          address: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'address') : '',
          nationality2: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'nationality2') : '',
          foreignAddress: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'foreignAddress') : '',
          foreignCountry: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'foreignCountry') : '',
          isNewClient: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'isNewClient') : false,
          residenceCountry: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'residenceCountry') : '',
          payeeName: payeeName,
          issuedDate:
            payeeInfo?.type === PaymentType.PAID_AT_BANK
              ? moment(payeeInfo?.attributesExt?.PAYEE_INFO?.issuedDate).format('YYYYMMDD')
              : undefined,
          issuedBy:
            payeeInfo?.type === PaymentType.PAID_AT_BANK ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'issuedBy') : '',
          issueCode:
            payeeInfo?.type === PaymentType.PAID_AT_BANK
              ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'issueCode')
              : '',
          name: payeeName,
          idNumber: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'idNumber') : '',
          dod: '',
          surName: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'surname') ?? '' : '',
          giveName: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'givenName') ?? '' : '',
          city: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'city') : '',
          district: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'district') : '',
          ward: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'ward') : '',
          street: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'street') ?? '' : '',
          relativeDocument: []
        }
      : {
          paymentMethod: paymentMethod,
          isPayeeNotPO: false,
          isNewClient: false,
          payeeName: payeeName,
          idNumber: payeeMethod ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'idNumber') : '',
          issuedDate:
            payeeInfo?.type === PaymentType.PAID_AT_BANK
              ? moment(payeeInfo?.attributesExt?.PAYEE_INFO?.issuedDate).format('YYYYMMDD')
              : undefined,
          issuedBy:
            payeeInfo?.type === PaymentType.PAID_AT_BANK ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'issuedBy') : '',
          issueCode:
            payeeInfo?.type === PaymentType.PAID_AT_BANK
              ? _.get(payeeInfo?.attributesExt?.PAYEE_INFO, 'issueCode')
              : '',
          dob: undefined,
          gender: undefined,
          phone: undefined,
          email: undefined,
          salutation: undefined,
          married: undefined,
          occupation: undefined,
          relationshipWithPO: undefined,
          nationality: undefined,
          address: undefined,
          nationality2: undefined,
          foreignAddress: undefined,
          foreignCountry: undefined,
          residenceCountry: undefined,
          name: undefined,
          dod: undefined,
          surName: undefined,
          giveName: undefined,
          city: undefined,
          district: undefined,
          ward: undefined,
          street: undefined,
          relativeDocument: []
        }
  }
  return payeeInfoData
}
