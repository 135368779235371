import {
  assets,
  UwEmployeeDecision,
  Form,
  Input,
  SectionHeader,
  Button,
  FormService,
  NbuwService
} from '@pulseops/business/core'
import { ErrorHandling, Format } from '@pulseops/common'
import { StackScreenProps } from '@react-navigation/stack'
import { pipe } from 'fp-ts/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ZIO } from '@mxt/zio'
import { RootStackParamList } from '../../root-stack-param-list'
import { SC } from './sc-modal'

type Props = StackScreenProps<RootStackParamList, 'BusinessViewEmployeeModal'>

export const BusinessViewEmployeeModal = ({ route, navigation }: Props) => {
  const { t } = useTranslation('business')

  const [viewData, setViewData] = React.useState({
    gender: ''
  })

  const { employeeKey, masterContractNo, policyYear } = route.params
  const { getGenderName } = FormService

  const service = NbuwService.forContract({
    masterContractNo,
    policyYear: parseInt(policyYear)
  })

  const detail: UwEmployeeDecision | null = pipe(
    service.state.employeeDecision.item(employeeKey).get,
    ZIO.tap((employee) => {
      setViewData({
        gender: getGenderName(employee?.gender)
      })
      return ZIO.unit
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (!navigation.canGoBack()) {
      navigation.navigate('MainStack')
    }
  }, [])

  const onClose = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('MainStack')
    }
  }

  return (
    <SafeAreaView>
      <SC.Header>
        <SC.HeaderText>{t('employee_information')}</SC.HeaderText>
        <SC.CloseIcon onPress={onClose}>
          <assets.IconClose />
        </SC.CloseIcon>
      </SC.Header>
      <SC.Container>
        <SC.BorderContainer
          style={{
            maxHeight: 'calc(100vh - 144px)',
            overflow: 'scroll'
          }}
        >
          <SectionHeader> {t('detail_info')}</SectionHeader>
          <Form.Group>
            <Form.Row>
              <Form.Col>
                <Input label={t('name')} value={detail?.employeeName || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('id_card_no')} value={detail?.employeeId || '-'} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('dob')} value={detail?.dateOfBirth ? Format.date(detail?.dateOfBirth) : '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('gender')} value={viewData.gender} readonly />
              </Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input label={t('phone_number')} value={detail?.employeePhoneNumber || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('email')} value={detail?.email || '-'} readonly />
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Input label={t('benefit_class')} value={detail?.benefitClass || '-'} readonly />
              </Form.Col>
              <Form.Col></Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Input label={t('main_product')} value={detail?.basicPlan || '-'} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('basic_sa')} value={Format.currencyForBusiness(detail?.basicSa)} readonly />
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Input label={t('add_rider_sa')} value={Format.currencyForBusiness(detail?.addRiderSa)} readonly />
              </Form.Col>
              <Form.Col>
                <Input label={t('hc_plan')} value={detail?.healthCarePlan || '-'} readonly />
              </Form.Col>
            </Form.Row>

            <Form.Row>
              <Form.Col>
                <Input
                  label={t('over_fcl_amount')}
                  value={Format.currencyForBusiness(detail?.overFclAmount)}
                  readonly
                />
              </Form.Col>
              <Form.Col></Form.Col>
            </Form.Row>
            <Form.Row>
              <Form.Col>
                <Input
                  label={t('datetime')}
                  value={detail?.createdDate ? Format.datetime(detail.createdDate) : '-'}
                  readonly
                />
              </Form.Col>
              <Form.Col>
                <Input label={t('change_by')} value={detail?.createdBy || '-'} readonly />
              </Form.Col>
            </Form.Row>
          </Form.Group>
        </SC.BorderContainer>
        <SC.ButtonContainer>
          <Button title="OK" onPress={onClose} />
        </SC.ButtonContainer>
      </SC.Container>
    </SafeAreaView>
  )
}
