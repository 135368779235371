import React from 'react'
import { assets, SC } from './core'
import { View } from 'react-native'

export const Header = (props: any) => {
  const { isWide, t } = props
  return (
    <SC.Header>
      <SC.Logo isWide={isWide}>
        <assets.Logo />
      </SC.Logo>
      <SC.Support isWide={isWide}>
        <SC.TollFreeCallCenter isWide={isWide}>{t('toll_free_call_center')}</SC.TollFreeCallCenter>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <assets.Calling />
          <SC.HotLine>{t('hot_line')}</SC.HotLine>
        </View>
      </SC.Support>
    </SC.Header>
  )
}
