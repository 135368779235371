import { ChangeContactInfo } from '../ChangeContactInfo'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface DuplicateValue {
  clientId: string
  clientName: string
  relationship: string
}

export interface Inheritance {
  tag: TransactionType.INHERITANCE
  clientName: string
  detail: api.Inheritance
  selectedClient: string
  attributesExt?: {
    EMAILS_DUP: Array<DuplicateValue>
    PHONES_DUP: Array<DuplicateValue>
  }
  policyList: ChangeContactInfo.ClientPolicy[]
}

export const Inheritance = (
  detail: api.Inheritance,
  poName: string,
  selectedClient: string,
  clientData: Array<{ clientId: string; clientName: string }>,
  policyList: ChangeContactInfo.ClientPolicy[]
): Inheritance => ({
  tag: TransactionType.INHERITANCE,
  clientName: poName ?? '',
  selectedClient,
  detail,
  attributesExt: {
    PHONES_DUP:
      detail.payload.body.attributesExt?.PHONES_DUP?.filter((e) => !!e.clientId && !!e.relationshipType).map((e) => ({
        clientId: e.clientId ?? '',
        clientName:
          clientData && clientData.length > 0
            ? clientData.find((client) => client.clientId === e.clientId)?.clientName ?? ''
            : '',
        relationship: e.relationshipType
      })) ?? [],
    EMAILS_DUP:
      detail.payload.body.attributesExt?.EMAILS_DUP?.filter((e) => !!e.clientId && !!e.relationshipType).map((e) => ({
        clientId: e.clientId ?? '',
        clientName:
          clientData && clientData.length > 0
            ? clientData.find((client) => client.clientId === e.clientId)?.clientName ?? ''
            : '',
        relationship: e.relationshipType
      })) ?? []
  },
  policyList: policyList.filter(
    (pL) => !!detail.payload.body.appliedPolicys.find((p) => p.appliedPolicy === pL.policyNum)
  )
})
