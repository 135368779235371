import { Table, TableRow, TableCell, TableContainer, TableHead, TableBody, makeStyles, Paper } from '@material-ui/core'
import React from 'react'
import { OBMuiTableStyles } from '../../ob-common'

// const useStyles = makeStyles({
//   root: {
//     "& .MuiTableHead-root": {
//       backgroundColor: '#F4F4F4'
//     },
//     "& .MuiTableCell-head": {
//       color: '#58647A',
//       fontSize: 14,
//       fontStyle: 'normal',
//       fontWeight: 700,
//     },
//     "& .MuiTableCell-body": {
//       color: '#1C1D1F',
//       fontSize: 14,
//       fontStyle: 'normal',
//       fontWeight: 400,
//     }
//   }
// })
export type OBOldAgentInfo = {
  code: string | null | undefined,
  fullName: string | null | undefined,
  mobileCode: string | null | undefined,
  mobilePhone: string | null | undefined,
  fromDate: string | null | undefined,
  toDate: string | null | undefined
}
type OBAgentTableProps = {
  displayedColums: Array<string>
  dataSource: Array<OBOldAgentInfo>
}
export const OBAgentTable = (props: OBAgentTableProps) => {
  const classes = OBMuiTableStyles()
  const space = ' '
  const { dataSource, displayedColums } = props
  return (
    <TableContainer component={Paper}>
      <Table classes={{ root: classes.root }}>
        <TableHead>
          <TableRow >
            {displayedColums && displayedColums.map((colItem, index) => {
              return (
                <TableCell key={`$'old-agent-column-${colItem}` + index} style={{ minWidth: 120 }}>{colItem}</TableCell>
              )
            })}

          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource && props.dataSource.map((dataItem, index) => {
            return (
              <TableRow key={'old-agent' + `${dataItem.fullName}` + index}>
                <TableCell>{(dataItem?.code ? (dataItem.code + space) : '')  + dataItem.fullName || '-'}</TableCell>
                <TableCell>{dataItem.mobilePhone || '-'}</TableCell>
                <TableCell>{dataItem.fromDate || '-'}</TableCell>
                <TableCell>{dataItem.toDate || '-'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}