import { ZIO } from '@mxt/zio'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
export namespace CertApi {
  export const SearchElement = t.type({
    agentCode: Maybe(t.string),
    agentName: Maybe(t.string),
    agtsts: Maybe(t.string),
    birthday: Maybe(t.number),
    blacklist: t.boolean,
    blkCode:Maybe(t.string),
    gender: Maybe(t.string),
    id: Maybe(t.string),
    idCard: Maybe(t.string),
    idnum: Maybe(t.string),
    importDate: Maybe(t.number),
    insurer: Maybe(t.string),
    isCompany: t.boolean,
    isOtherCompany: t.boolean,
    lifecer: Maybe(t.string),
    misdat: Maybe(t.string),
    organizationName: Maybe(t.string),
    prefix: Maybe(t.string),
    recentCont: Maybe(t.number),
    recentTer: Maybe(t.number),
    result: Maybe(t.string),
    taxCode:Maybe(t.string),
    userImport: Maybe(t.string),
    validFlag: Maybe(t.number)
  })

  export type SearchElement = t.TypeOf<typeof SearchElement>

  export const uploadCert = (file: File, username: string) => {
    const formData = new FormData()
    formData.append('file', file)
    return pipe(
      POApi.post('distribution-agents-service/certificate/upload', {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          username: username
        }
      })(t.unknown)(formData),
      ZIO.map((res) => res)
    )
  }

  export const exportCert = (fromDate: string, toDate: string, username: string) => {
    return pipe(
      POApi.post('distribution-agents-service/Cert/export', {
        params: {
          fromDate: fromDate,
          todate: toDate,
          username: username
        }
      })(
        t.type({
          body: Nullable(t.string)
        })
      )({}),
      ZIO.map((res) => res)
    )
  }

  export const searchCert = (data: any) => {
    return pipe(
      POApi.post('distribution-agents-service/Cert/search')(
        t.type({
          body: t.type({
            currentPage: t.number,
            elements: Maybe(t.array(SearchElement)),
            pageSize: t.number,
            totalElement: t.number,
            totalPage: t.number
          })
        })
      )(data),
      ZIO.map((res) => res)
    )
  }
}
