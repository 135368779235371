/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-dupe-else-if */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions
} from 'react-native'
import {
  AppContext,
  DatePicker,
  RBAC,
  ErrorHandling,
  Input,
  assets,
  GroupPolicyPermission,
  MenuPermissions
} from '@pulseops/common'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { Throwable, ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'
import { addMonths, isAfter, startOfDay } from 'date-fns/fp'
import { Controller, UseFormReturn, useForm } from 'react-hook-form'
import moment from 'moment'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { SEABillingChangeModal } from '../common/SEABillingChangeModal'
import { SEAWithdrawChangeService } from './SEAWithdrawChangeService'
import { SEAWithdrawChangeForm } from './SEAWithdrawChangeForm'

export interface WithdrawItem {
  id: string
  exchangeId: string
  excelFileImportName: string
  fileExcelSize: number
  totalInputRecords: number
  filePdfName: string
  filePdfSize: number
  importBy: string
  createdDate: string
  waiting: number
  success: number
  fail: number
  duplicate: number
}
export interface WithdrawImportHistoryFormData {
  fromDate: Date | null
  toDate: Date | null
  implementUser: string
  importedFile: string
}
type Props = {
  setSubTabIndex: (val: number) => void
  form: Omit<UseFormReturn<SEAWithdrawChangeForm.Raw>, 'handleSubmit'>
}

export const SEAWithdrawImportHistoryScreen = (props: Props) => {
  let isView = false
  const navigation = useNavigation()
  const { t, i18n } = useTranslation('BillingChange')
  const billingChanceImportHistoryForm = useForm<WithdrawImportHistoryFormData>({
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date()
    }
  })
  const [loading, bindLoading] = useLoading()
  const { control, setValue, watch } = billingChanceImportHistoryForm
  const [maxDate] = React.useState<Date>(new Date())
  const result = moment(watch('fromDate')).subtract(3, 'months')
  const [dataSource, setDataSource] = React.useState<WithdrawItem[]>([])
  const isFocused = useIsFocused()
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageNum, setPageNum] = React.useState<number>(0)
  const [totalItem, setTotalItem] = React.useState<number>(0)
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { height } = useWindowDimensions()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  React.useEffect(() => {
    props.form.setValue('checkReset', false)
  }, [dataSource])
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('ImportFileForPartialWithdraw'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  const getQuery = (input: {
    order: string | undefined
    sort: string | undefined
    start: number
    page: number
    size: number | undefined
    fromCreatedDate: Date
    toCreatedDate: Date
    implementUser: string | undefined
    importedFile: string | undefined
  }) => {
    const { fromCreatedDate, toCreatedDate, implementUser, importedFile, size, start, page, order, sort } = input
    return {
      order,
      sort,
      start,
      page,
      size,
      fromCreatedDate: moment(fromCreatedDate).startOf('day').toDate(),
      toCreatedDate: moment(toCreatedDate).endOf('day').toDate(),
      implementUser,
      importedFile
    }
  }
  const dateStrWF = (date: string | null | undefined, format: string): string =>
    !!date && date !== '0' && date !== '99999999' && date != '9999-99-99' && date !== '-'
      ? moment(date,'DD/MM/YYYY HH:mm:ss').add(7, 'hours').format(format)
      : '-'
  const mappingDataSource = (data: SEABillingChangeModal.QueryAutoDebitlogData, start = 0) => {
    const filteredDataSource = data.map((item, index) => ({
      id: item.id || '-',
      exchangeId: item.exchangeId || '-',
      excelFileImportName: item.excelFileImportName || '-',
      fileExcelSize: item.fileExcelSize || 0,
      totalInputRecords: item.totalInputRecords || 0,
      filePdfName: item.filePdfName || '-',
      filePdfSize: item.filePdfSize || 0,
      importBy: item.importBy || '-',
      createdDate: dateStrWF(item.createdDate, 'DD/MM/YYYY HH:mm') || '-',
      waiting: item.waiting || 0,
      success: item.success || 0,
      fail: item.fail || 0,
      duplicate: item.duplicate || 0
    }))
    if (isView) {
      setDataSource(filteredDataSource)
    }
    return filteredDataSource
  }

  const handleReportData = async (
    handle: (report: SEABillingChangeModal.QueryAutoDebitlogsData) => ZIO<any, Throwable, any>
  ) => {
    const size = isView ? pageSize : 0
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const implementUser = watch('implementUser') || null
    const importedFile = watch('importedFile') || null
    if (await validateDataFields(customedFromDate, customedToDate)) {
      const query = getQuery({
        order: 'desc',
        sort: 'createdDate',
        start: 0,
        page: 0,
        size,
        fromCreatedDate: customedFromDate,
        toCreatedDate: customedToDate,
        implementUser: implementUser ?? '',
        importedFile: importedFile ?? ''
      })
      showGlobalLoading(true)
      pipe(
        SEAWithdrawChangeService.geDataQueryAutoDebitLog(query),
        ZIO.flatMap((report) =>
          pipe(
            O.fromNullable(report.data),
            O.filter((data) => data.length > 0),
            O.fold(
              () => {
                setDataSource([])
                showToast(t('message:MS030029'), 'error')
                showGlobalLoading(false)
                return ZIO.unit
              },
              (data) => {
                if (isView) {
                  mappingDataSource(data)
                }
                showGlobalLoading(false)
                return handle(report)
              }
            )
          )
        ),
        ZIO.catchAll(() => {
          setDataSource([])
          showToast(t('message:MS030029'), 'error')
          showGlobalLoading(false)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const handlePaging = (pageIndex: number, currentpageSize: number) => {
    const start = currentpageSize * pageIndex
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const implementUser = watch('implementUser')
    const importedFile = watch('importedFile')
    const query = getQuery({
      order: 'desc',
      sort: 'createdDate',
      start,
      page: pageIndex,
      size: currentpageSize,
      fromCreatedDate: customedFromDate,
      toCreatedDate: customedToDate,
      implementUser: implementUser ?? '',
      importedFile: importedFile ?? ''
    })
    showGlobalLoading(true)
    isView = true
    pipe(
      SEAWithdrawChangeService.geDataQueryAutoDebitLog(query),
      ZIO.flatMap((report) =>
        pipe(
          O.fromNullable(report.data),
          O.filter((data) => data.length > 0),
          O.fold(
            () => {
              showToast(t('message:MS030029'), 'error')
              setDataSource([])
              showGlobalLoading(false)
              return ZIO.unit
            },
            (data) => {
              mappingDataSource(data, pageIndex)
              showGlobalLoading(false)
              return ZIO.unit
            }
          )
        )
      ),
      ZIO.catchAll(() => {
        showToast(t('message:MS030029'), 'error')
        setDataSource([])
        showGlobalLoading(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const setDefaultValues = () => {
    const currentDate = new Date()
    if (!watch('fromDate') && !watch('toDate')) {
      setValue('fromDate', currentDate)
      setValue('toDate', currentDate)
    }
  }

  const validateDataFields = async (fromDateItem: Date, toDateItem: Date) => {
    const customedFromDate = moment(fromDateItem).format('YYYY/MM/DD')
    const customedToDate = moment(toDateItem).format('YYYY/MM/DD')
    const isValidForm = await billingChanceImportHistoryForm.trigger()
    if (!isValidForm) {
      return false
    } else if (!moment(fromDateItem).isValid()) {
      return false
    } else if (!moment(customedToDate).isValid()) {
      return false
    } else if (fromDateItem && !toDateItem) {
      return false
    } else if (!fromDateItem && toDateItem) {
      return false
    } else if (moment(customedFromDate) > moment(customedToDate)) {
      showToast(t('message:MS030044'), 'error')
      return false
    } else if (
      moment(customedFromDate).valueOf() > moment().valueOf() ||
      moment(customedToDate).valueOf() > moment().valueOf()
    ) {
      return false
    } else if (
      pipe(fromDateItem, startOfDay, addMonths(3), (fromDateAdd3Months) =>
        isAfter(fromDateAdd3Months)(startOfDay(toDateItem))
      )
    ) {
      showToast(t('message:MS080004', { k: 3 }), 'error')
      return false
    }
    return true
  }

  const onViewReportClick = () => {
    if (watch('fromDate') === null) {
      return showToast(t('message:MS020009', { field: t('common:FromDate') }), 'error')
    } else if (watch('toDate') === null) {
      return showToast(t('message:MS020009', { field: t('common:ToDate') }), 'error')
    } else if (watch('toDate') === null && watch('fromDate') === null) {
      return showToast(t('message:MS030044'), 'error')
    }
    isView = true
    setDefaultValues()
    handleReportData((report) => {
      setPageNum(0)
      setTotalItem(report.total)
      return ZIO.unit
    })
  }
  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: '#D3DCE6',
            margin: 15,
            padding: 25
          }}
        >
          <View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <Controller
                  name="fromDate"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={[{ marginHorizontal: 15, marginBottom: 15, width: '70%' }, { marginTop: 6 }]}>
                      <DatePicker
                        keyboardIconRed={true}
                        label={t('common:FromDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={value ? '' : error?.message}
                        // minDate={result}
                        maxDate={maxDate}
                        alwaysShow={true}
                      />
                    </View>
                  )}
                />
              </View>

              <View style={{ flex: 1, alignItems: 'flex-start' }}>
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <View style={[{ marginHorizontal: 15, marginBottom: 15, width: '70%' }, { marginTop: 6 }]}>
                      <DatePicker
                        keyboardIconRed={true}
                        label={t('common:ToDate')}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        errorMessage={value ? '' : error?.message}
                        maxDate={maxDate}
                        alwaysShow={true}
                      />
                    </View>
                  )}
                />
              </View>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <Controller
                  name="implementUser"
                  control={control}
                  render={({ field }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 15, width: '70%' }}>
                      <Input {...field} value={field.value ?? ''} title={t('ImplementUser')} />
                    </View>
                  )}
                />
              </View>
              <View style={{ flex: 1, alignItems: 'flex-start' }}>
                <Controller
                  name="importedFile"
                  control={control}
                  render={({ field }) => (
                    <View style={{ marginHorizontal: 15, marginBottom: 15, marginTop: 15, width: '70%' }}>
                      <Input {...field} value={field.value ?? ''} title={t('ImportedFileName')} />
                    </View>
                  )}
                />
              </View>
            </View>
          </View>
          <View
            style={[
              SUREStyle.sectionRow,
              SUREStyle.secondLine,
              { justifyContent: 'center', alignItems: 'center', alignSelf: 'center', padding: 20 }
            ]}
          >
            <View
              style={[
                SUREStyle.col_4,
                { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }
              ]}
            >
              {permissions &&
                (permissions.includes(GroupPolicyPermission.ViewHistoryImportFileDetailAutoDebit) ||
                  permissions.includes(GroupPolicyPermission.SearchHistoryImportFileDetailAutoDebit)) && (
                  <TouchableOpacity style={{}} onPress={onViewReportClick}>
                    <View style={[SUREStyle.primaryBtn, { width: i18n.language === 'vi' ? 150 : 120, height: 40 }]}>
                      <assets.SearchTaskIcon />
                      <View style={{ width: 10 }} />
                      <Text style={{ textAlign: 'center', color: '#fff', fontWeight: '600' }}>{t('Search')}</Text>
                    </View>
                  </TouchableOpacity>
                )}
            </View>
          </View>
        </View>
        {dataSource && dataSource.length > 0 ? (
          <View style={{ padding: 20 }}>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableCellHeader} style={{ width: 100 }}>
                      {t('No')}
                    </TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('FileName')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('ImportDate')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('ImportRowSumMary')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('ImportRowSuccess')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('ImportRowProcessing')}</TableCell>
                    <TableCell className={classes.tableCellHeader}>{t('UseImplementation')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataSource.map((item, index) => {
                    return (
                      <TableRow key={1}>
                        <TableCell className={classes.tableCell}>{(index + 1).toString() || '-'}</TableCell>
                        <TableCell className={classes.tableCell}>
                          <Pressable
                            onPress={() => {
                              props.form.reset({
                                fromDate: watch('fromDate'),
                                toDate: watch('toDate'),
                                importedFileName: item.excelFileImportName,
                                checkReset: true
                              })
                              props.setSubTabIndex(2)
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: '600',
                                fontSize: 15,
                                color: '#1ea5fc',
                                textDecorationLine: 'none'
                              }}
                            >
                              {item.excelFileImportName || '-'}
                            </Text>
                          </Pressable>
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.createdDate}</TableCell>
                        <TableCell className={classes.tableCell}>{item.totalInputRecords}</TableCell>
                        <TableCell className={classes.tableCell}>{item.success}</TableCell>
                        <TableCell className={classes.tableCell}>{item.waiting}</TableCell>
                        <TableCell className={classes.tableCell}>{item.importBy || '-'}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              page={pageNum}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              rowsPerPage={pageSize}
              count={totalItem}
              onPageChange={(e, page) => {
                setPageNum(page)
                handlePaging(page, pageSize)
              }}
              onRowsPerPageChange={(e) => {
                const rowsPerPage = Number(e.target.value)
                setPageSize(rowsPerPage)
                if (pageNum * rowsPerPage >= totalItem) {
                  setPageNum(0)
                  handlePaging(0, rowsPerPage)
                } else {
                  handlePaging(pageNum, rowsPerPage)
                }
              }}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            ></TablePagination>
          </View>
        ) : (
          !loading && (
            <View style={{ marginHorizontal: 30 }}>
              <Text style={{ color: '#ED1B2E' }}>{t('message:MS030029')}</Text>
            </View>
          )
        )}
      </ScrollView>
    </SafeAreaView>
  )
}

const SUREStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 30
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    flexDirection: 'row',
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    justifyContent: 'center'
  },
  cellWidth200: {
    minWidth: '200px'
  }
})
