import { CashOut } from './CashOutOption'
export namespace MaturityPayment {
  export interface Detail {
    policyNum: string
    clientId: string
    maturityBenefit: number
    prucashBenefit: number
    suspendAmount: number
    totalCashBenefit: number
    paidMaturity: number
    principalAplOpl: number
    interestAplOpl: number
    notPaidPremium: number
    deductOfClaims: number
  }
  export interface AccessDataCheck {
    contractStatus: string
    balanceAmount: number
  }
  export interface SubmitData {
    policy: {
      owners: {
        clientId: string
        dateOfDeath: string
      }
      attributesExt: {
        inheritanceFlag: 'N' | 'Y'
      }
    }
    cashOutOptions: Array<CashOut.Option>
  }

  export interface MaturityBenefit {
    suspendAmount: number
    paidMaturity: number
    notPaidPremium: number
    deductOfClaims: number
  }
}
