import styled from 'styled-components/native'
import { View } from './styled'

export const UtilRow = styled(View)`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  align-items: center;
`
