import { CallHeaderInfo, OBCallOutHeader, OBStatus, OBTabView, OBTabViewInfo } from '@pulseops/outbound'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet, ScrollView, Text } from 'react-native'
import { IDCCustomerAnswer } from './independence-tabs/IDCCustomerAnswer'
import { IDCCallingResultDetail } from './independence-tabs/IDCCallingResultDetail'
import { IDCActionHistory } from './independence-tabs/IDCActionHistory'
import { StackScreenProps } from '@react-navigation/stack'
import { IDCDetailParamList } from './IDCDetailParamList'
import { useIsFocused } from '@react-navigation/native'
import { AppContext, ErrorHandling, IDCDetailService } from '@pulseops/common'
import { IDCDetailContext } from './IDCDetailContext'
import { IDCErrorLog, IDCRelatedInfo } from './independence-tabs'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'


export const IDCDetailScreen = (props: StackScreenProps<IDCDetailParamList, 'IDCDetailScreen'>) => {
  const { t } = useTranslation()
  const [isActiveTabIndex, setIsActiveTabIndex] = React.useState<number>(0)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [OBSelectedCaseID, setOBSelectedCaseID] = React.useState('')
  const caseID = props.route.params.caseID || ''
  const idcType = props.route.params.idcType || ''
  const transactionType = props.route.params.transactionType || ''
  const processInstanceID = props.route.params.processInstanceID || ''
  const getPolicyHeaderInfo = () => {
    return props.route.params.clientID && props.route.params.clientID !== "null" ?
      `${props.route.params.clientID} - ${props.route.params.policyOwnerName}` :
      props.route.params.policyOwnerName && props.route.params.policyOwnerName !== "null" ? props.route.params.policyOwnerName : ''
  }

  const headerData: CallHeaderInfo[] = [
    {
      title: t('Outbound:OBTaskManagement:Channel'),
      value: props.route.params.channel || ''
    },
    {
      title: t('ServiceInquiry:proposalNumber'),
      value: props.route.params.proposalNumber
    },
    {
      title: t('TaskManagement:policy'),
      value: props.route.params.policyNumber || ''
    },
    {
      title: t('common:PolicyOwner'),
      value: getPolicyHeaderInfo()
    }
  ]

  const tabList: OBTabViewInfo[] = [
    {
      tabName: t('Outbound:OBCustomerAnwser:CustomerAnwser'),
      tabKey: 0
    },
    {
      tabName: t('Outbound:OBCalloutDetail:CallingResult'),
      tabKey: 1
    },
    {
      tabName: t('Outbound:OBCalloutDetail:ActionHistory'),
      tabKey: 2
    },
    {
      tabName: t('Tab:RelatedInfo'),
      tabKey: 3
    },
    {
      tabName: t('Tab:ErrLog'),
      tabKey: 4
    }
  ]

  const OBCaseStatus = pipe(
    IDCDetailService.getOBCaseStatusByIDCprocessInstanceId(processInstanceID),
    ZIO.map((responseData) => {
      return responseData
    }),
    ErrorHandling.runDidUpdate([processInstanceID])
  ) || ''

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.replace('HomeScreen')
          }
        },
        {
          title: t('menu:ServiceInquiry'),
          navigate: () => props.navigation.navigate('UserDrawer', { screen: 'ServiceInquiryScreen' })
        },
        { title: caseID ? caseID : '-', navigate: null }
      ])
    }
    return () => {
      setOBSelectedCaseID('')
    }
  }, [isFocused])

  const getStatusName = (statusCode: string) => {
    const statusName = Object.keys(OBStatus).map((item, index) => ({ value: item, label: Object.values(OBStatus)[index] })).find((x) => x.value === statusCode)
    return statusName?.label || ''
  }

  return (
    <IDCDetailContext.Provider value={{
      caseID: caseID,
      idcType: idcType,
      transactionType: transactionType,
      processInstanceID: processInstanceID,
      OBSelectedCaseID: OBSelectedCaseID,
      setIsActiveTabIndex: setIsActiveTabIndex,
      setOBSelectedCaseID: setOBSelectedCaseID
    }}>
      <View style={detailStyles.wrapper}>
        <OBCallOutHeader headerData={headerData} OBCallOutHeaderMessage={() => <></>}></OBCallOutHeader>
        {OBCaseStatus && (
          <View style={detailStyles.statusContent}>
            <Text style={detailStyles.statusTitle}>{`${t('IDCDetail:Status')}:`} </Text>
            <Text style={detailStyles.statusText}>{getStatusName(OBCaseStatus)}</Text>
          </View>
        )}
        <ScrollView style={detailStyles.container}>
          <OBTabView
            isActiveTabIndex={isActiveTabIndex}
            setIsActiveTabIndex={setIsActiveTabIndex}
            data={tabList}
          ></OBTabView>
          {isActiveTabIndex === 0 && <IDCCustomerAnswer></IDCCustomerAnswer>}
          {isActiveTabIndex === 1 && <IDCCallingResultDetail></IDCCallingResultDetail>}
          {isActiveTabIndex === 2 && <IDCActionHistory></IDCActionHistory>}

          {isActiveTabIndex === 3 && <IDCRelatedInfo></IDCRelatedInfo>}
          {isActiveTabIndex === 4 && <IDCErrorLog></IDCErrorLog>}
        </ScrollView>
      </View>
    </IDCDetailContext.Provider>

  )
}
const detailStyles = StyleSheet.create({
  wrapper: {
    flex: 1
  },
  container: {
    display: 'flex',
    marginTop: 30
  },
  statusContent: {
    marginHorizontal: 24,
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row'
  },
  statusTitle: {
    color: '#70777E',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '700'
  },
  statusText: {
    color: '#FF6F00',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '700'
  }
})