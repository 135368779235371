import React from 'react'
import { ScrollView, SafeAreaView, ActivityIndicator, View, StyleSheet } from 'react-native'
// import { ScrollViewSafeAreaView } from 'react-native-safe-area-context'
// import { SafeAreaView } from 'react-native-safe-area-context'

interface Props {
  loading?: boolean
  display?: boolean
  children: React.ReactNode
  isSignCheck?: boolean
}

export const Container: React.FC<Props> = ({ loading, display, children, isSignCheck }) => {
  return (
    <SafeAreaView style={[{ flex: 1, minHeight: 300 }, isSignCheck && styles.signCheckContent]}>
      {loading ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" animating={true} hidesWhenStopped={true} color="red" />
        </View>
      ) : (
        <ScrollView>{display && children}</ScrollView>
      )}
    </SafeAreaView>
  )
}

Container.defaultProps = {
  display: true
}

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  signCheckContent: {
    minHeight: 100
  }
})
