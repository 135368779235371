import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.CHANGE_PREMIUM)
    }),
    codec
  ])

const Pulse4Ops = Base(
  t.type({
    payload: t.type({
      body: t.type({
        currentInstallmentPremium: t.number,
        currentSumAssured: t.number,
        newInstallmentPremium: t.number,
        requirePayInAmount: t.number
      })
    })
  })
)

export const ChangePremium = Pulse4Ops
export type ChangePremium = t.TypeOf<typeof ChangePremium>
