import { Pressable, ScrollView, StyleSheet, Text, View, useWindowDimensions, ActivityIndicator } from 'react-native'
import React from 'react'
import { IBButton } from '../../common'
import {
  AppContext,
  ChangeContactInfo,
  ChangeContactInfoService,
  Checkbox,
  DuplicateTable,
  ErrorHandling,
  GeneralService,
  Input,
  OBSC,
  ProductEnum,
  RequestAuthFormDataSubmit,
  Select,
  SelectOption,
  SelectSearch,
  SourceType,
  StorageBlob,
  SubmissionService,
  TabList,
  TransactionType,
  assets,
  form2,
  sharedStyle
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { isEmpty, orderBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { IBService } from '../../ib-service'
import { ZIO, Task } from '@mxt/zio'
import { CallHeaderInfo, IBChangeContactHeader } from './IBChangeContactHeader'
import { ChangeContactOfficeInfo, EmailIndicator, SMSIndicator } from './IBChangeContactInfoConst'
import { ChangeContactInfoForm } from './IBChangeContactInforForm'
import { useLoading } from '@mxt/zio-react'
import { RequestAuthen, RequestAuthenticateData, RequestInfoPanel, UploadedFilesInfo } from '@pulseops/submission'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ConfirmView } from './IBConfirmedView'
import { SC } from './share-styles/share-styles'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import * as tc from 'io-ts'
import _ from 'lodash'
import moment from 'moment'

interface Props {
  showPopup: boolean
  setShowPopup: (open: boolean) => void
}
export const IBModalChangeContactInfo = ({ showPopup, setShowPopup }: Props) => {
  const { height, width } = useWindowDimensions()
  const { t, i18n } = useTranslation('Inbound')
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [isMaximize, setMaximize] = React.useState<boolean>(false)
  const [policyNumber, setPolicyNumber] = React.useState<{ label: string; value: string }>()
  const [isCountinue, setCountinue] = React.useState<boolean>(false)
  const [policyLists, setPolicyLists] = React.useState<SelectOption[]>([])
  const clientNumber = urlParams.get('clientNumber')
  const [POName, setPOName] = React.useState('')
  const [POOwnerID, setPOOwnerID] = React.useState('')
  const [currentTab, setCurrentTab] = React.useState<number>(0)
  const [bindLoading, bindLoader] = useLoading(false)
  const [detail, setDetail] = React.useState<ChangeContactInfo.Detail>()
  const [countryOptions, setCountryOptions] = React.useState<SelectOption[]>([])
  const [provinceOptions, setProvinceOptions] = React.useState<SelectOption[]>([])
  const [districOptions, setDistrictOptions] = React.useState<SelectOption[]>([])
  const [wardOptions, setWardOptions] = React.useState<SelectOption[]>([])
  const [isShowLoadingIcon, setIsShowLoadingIcon] = React.useState(false)
  const [relationOptions, setRelationOptions] = React.useState<SelectOption[]>([])
  const isConfirmed = currentTab === 1
  const isCCE = true
  const officeCodes = pipe(GeneralService.getOfficeCodes('CCE'), ErrorHandling.runDidMount([]))

  const requestAuthenForm = useForm<RequestAuthenticateData.RequestAuthFormValue>({
    defaultValues: {
      authOption: RequestAuthenticateData.RequestAuthOption.OTP,
      otp: {
        otpOption: RequestAuthenticateData.OTPOption.PHONE,
        otpInput: undefined,
        otpConsent: false
      },
      paper: {
        data: [],
        consent: false
      }
    }
  })

  const [eformData, setEformData] = React.useState<RequestAuthenticateData.EformRequestAuthenticateData>(
    RequestAuthenticateData.DefaultRequestAuthenticateData
  )
  const {
    base: {
      control,
      reset,
      getValues,
      trigger,
      formState: { errors, isValid },
      setValue,
      watch,
      clearErrors
    }
  } = form2.useForm(ChangeContactInfoForm.codec, {
    defaultValues: {
      chooseAddress: false,
      chooseEmail: false,
      choosePhone: false
    }
  })

  const menus = [
    {
      id: 1,
      title: t('submission:Request')
    },
    {
      id: 2,
      title: t('submission:Confirm')
    }
  ]

  const requestInfo = [
    { label: t('submission:category'), value: t('submission:PolicyServices') },
    { label: t('submission:serviceType'), value: t('TransactionGroup:Alteration') },
    { label: t('submission:transactionType'), value: t(`TransactionType:CHANGE_CONTACT_INFO`) }
  ]

  const headers: CallHeaderInfo[] = [
    {
      title: t('common:PolicyNumber'),
      value: policyNumber?.value ?? '-'
    },
    {
      title: t('common:PolicyOwner'),
      value: `${POOwnerID} - ${POName}`
    },
    {
      title: t('common:Office'),
      value: `${officeCodes.find((item) => item.code === 'CCE')?.code} - ${
        i18n.language === 'vi'
          ? officeCodes.find((item) => item.code === 'CCE')?.nameVi
          : officeCodes.find((item) => item.code === 'CCE')?.nameEn
      }`
    }
  ]

  const applyPolicyAddress = useWatch({
    control,
    name: 'address.applyPolicies'
  })

  const phoneRelationShip = useWatch({
    control,
    name: 'phone.relationships'
  })

  const emailRelationShip = useWatch({
    control,
    name: 'email.relationships'
  })

  const predicateAgent = (relationship: ChangeContactInfoForm.RelationShip) => relationship.role === 'AG'
  const anyAgentPhone = React.useMemo(() => phoneRelationShip?.find(predicateAgent), [phoneRelationShip])
  const anyAgentEmail = React.useMemo(() => emailRelationShip?.find(predicateAgent), [emailRelationShip])

  const phoneCodeOptions: SelectOption[] = pipe(
    GeneralService.getMobileCodes,
    ZIO.map((codes) => {
      return codes.map((code) => ({
        label: `+${code.code}`,
        value: code.code
      }))
    }),
    ErrorHandling.runDidMount({})
  )

  const EmailOptions = React.useMemo(() => {
    return EmailIndicator.map((email) => {
      return {
        value: email.value,
        label: i18n.language === 'vi' ? email.labelVI : email.labelEN
      }
    })
  }, [i18n.language])

  const applyAllPolicy = (value: boolean) => {
    const newApply = applyPolicyAddress && applyPolicyAddress.map((a) => ({ ...a, selected: value }))
    setValue('address.applyPolicies', newApply)
  }

  const isSameClient = (relationship: ChangeContactInfo.DuplicateInfo): boolean => {
    return (
      relationship.clientName === detail?.poName &&
      relationship.dob === detail?.dob &&
      relationship.gender === detail?.gender &&
      relationship.secuityNo === detail?.secuityNo
    )
  }

  React.useEffect(() => {
    if (isCountinue) {
      setCurrentTab(0)
      reset({
        chooseAddress: false,
        chooseEmail: false,
        choosePhone: false
      })
      clearErrors()
    }
    if (isCountinue && policyNumber) {
      pipe(
        ZIO.zipPar(
          ChangeContactInfoService.getDetail(policyNumber.value!),
          SubmissionService.getNewOwnerInfo(policyNumber.value)
        ),
        ZIO.map(([contactData, customerInfo]) => {
          setIsShowLoadingIcon(false)
          setDetail(contactData)
          setEformData({
            ...eformData,
            policyNum: policyNumber.value,
            phoneData: {
              phoneCode: customerInfo.attributesExt.MOBILE_CODE,
              phoneNum: customerInfo.mobilePhone ?? ''
            },
            otpRegistered: customerInfo.otpConsentStatus === '1',
            isCCE: true
          })
          setPOName(customerInfo.name)
          setPOOwnerID(customerInfo.clientId)
          return contactData
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }, [isCountinue])

  pipe(
    isCountinue ? GeneralService.getRelationship() : ZIO.succeed([]),
    ZIO.tap((relations) => {
      //console.log(relations)
      const relationOptions = relations
        .filter((r) => r.changeContactInfo === 'Y')
        .map((relation) => {
          return {
            label: i18n.language === 'vi' ? relation.nameVn : relation.nameEn,
            value: relation.code
          }
        })
      setRelationOptions(relationOptions)
      return ZIO.unit
    }),
    ErrorHandling.runDidUpdate([isCountinue])
  )

  React.useEffect(() => {
    if (isEmpty(policyLists) && showPopup) {
      pipe(
        IBService.getPolicyList(clientNumber || ''),
        ZIO.map((policyArr) => {
          if (!isEmpty(policyArr)) {
            setPolicyLists(
              policyArr.map((item) => ({ value: item.policyNumber, label: item.policyNumber, policyOwner: '' }))
            )
          }
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [showPopup])

  const getDuplicatePhone = (phoneNumber: string) => {
    pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast(t('common:noData'), 'error'),
        (detail) => {
          setIsShowLoadingIcon(true)
          return pipe(
            ChangeContactInfoService.getDuplicatePhoneInfo(phoneNumber, detail.customerId),
            ZIO.tap((info) => {
              const phones = info.map((e) => {
                return {
                  clientId: e.clientId,
                  clientName: e.clientName,
                  poName: detail.poName,
                  role: e.role,
                  //role: 'AG',
                  relationship: null,
                  dob: e.dob,
                  secuityNo: e.secuityNo,
                  gender: e.gender
                  //sameClient: isSameClient(e)
                }
              })
              setValue(
                'phone.relationships',
                phones.filter((p) => !isSameClient(p))
              )
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run()
          )
        }
      )
    )
  }

  const pathAddress = () => {
    if (
      provinceOptions.length > 0 &&
      districOptions.length > 0 &&
      wardOptions.length > 0 &&
      getValues('address.country') &&
      getValues('address.street') &&
      getValues('address.province') &&
      getValues('address.district') &&
      getValues('address.ward')
    ) {
      return
    }
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast('Error', 'error'),
        (detail) => {
          setIsShowLoadingIcon(true)
          return pipe(
            ZIO.zipPar(ZIO.succeed(detail), GeneralService.getCountries, GeneralService.getProvinces),
            ZIO.flatMap(([detail, countries, provinces]) => {
              // setDetail(detail)
              const countriesOptions = countries.map((country) => {
                return {
                  label: country.name,
                  value: country.code
                }
              })

              const provinceOptions = provinces.map((province) => {
                return {
                  label: province.name,
                  value: province.code
                }
              })

              setCountryOptions(countriesOptions)
              setProvinceOptions(provinceOptions)

              const chooseCountry = countriesOptions.find((value) => value.value === detail.nationality)
              const chooseProvince = provinceOptions.find(
                (value) => value.value === getValues('address.province.value') || value.value === detail.province
              )

              return pipe(
                GeneralService.getDistricts(getValues('address.province.value') || detail.province),
                ZIO.flatMap((districts) => {
                  const districtOptions = districts.map((district) => {
                    return {
                      label: district.name,
                      value: district.code
                    }
                  })

                  const chooseDistrict = districtOptions.find(
                    (value) => value.value === getValues('address.district.value') || value.value === detail.district
                  )
                  setDistrictOptions(districtOptions)

                  return pipe(
                    GeneralService.getWards({
                      provinceCode: getValues('address.province.value') || detail.province,
                      districtCode: getValues('address.district.value') || detail.district
                    }),
                    ZIO.map((wards) => {
                      const wardOptions = wards.map((ward) => {
                        return {
                          label: ward.name,
                          value: ward.code
                        }
                      })
                      const chooseWards = wardOptions.find(
                        (value) => value.value === getValues('address.ward.value') || value.value === detail.ward
                      )

                      setWardOptions(wardOptions)

                      setValue('address.street', getValues('address.street') || detail.street)
                      setValue(
                        'address.country',
                        getValues('address.country') || chooseCountry || { label: '', value: '' }
                      )
                      setValue(
                        'address.province',
                        getValues('address.province') || chooseProvince || { label: '', value: '' }
                      )
                      setValue(
                        'address.district',
                        getValues('address.district') || chooseDistrict || { label: '', value: '' }
                      )
                      setValue('address.ward', getValues('address.ward') || chooseWards || { label: '', value: '' })
                      //console.log('dasdasdas asdasd asd', detail.clientPolicyList)
                      const clientPolicy =
                        getValues('address.applyPolicies') ||
                        detail.clientPolicyList
                          .filter((c) => !!c.policyNum && !!c.address)
                          .map((policy) => {
                            return { selected: false, policyNum: policy.policyNum, address: policy.address }
                          })
                      setValue('address.applyPolicies', clientPolicy)
                      //setValue('address.applyPolicies', [{selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}, {selected: false, policyNum: '7000001', address: '2B nguyen thi huynh'}])
                      return ZIO.unit
                    })
                  )
                })
              )
            }),
            ZIO.tap((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ErrorHandling.run({})
          )
        }
      )
    )
  }

  const getDuplicateEmail = (email: string) => {
    pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => showToast('Khong co du lieu', 'error'),
        (detail) => {
          setIsShowLoadingIcon(true)
          return pipe(
            ChangeContactInfoService.getDuplicateEmailInfo(email, detail.customerId),
            ZIO.tap((info) => {
              const email = info.map((e) => {
                // const validationFlag = (e?.role !== 'AG' || e?.role === 'AG') && e?.clientName !== detail.poName
                return {
                  clientId: e.clientId,
                  clientName: e.clientName,
                  poName: detail.poName,
                  role: e.role,
                  relationship: null,
                  dob: e.dob,
                  secuityNo: e.secuityNo,
                  gender: e.gender
                  //sameClient: isSameClient(e)
                }
              })
              //console.log('duplication email', email)
              setValue(
                'email.relationships',
                email.filter((e) => !isSameClient(e))
              )
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            ZIO.tapError((_) => {
              setIsShowLoadingIcon(false)
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run()
          )
        }
      )
    )
  }

  const pathEmail = () => {
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => console.log('Error'),
        (detail) => {
          setValue('email.email', detail.email)
          const mapEmail = EmailIndicator.find((email) => email.value === detail.emailIndicator)
          if (mapEmail) {
            setValue('email.receiveInfoVia', {
              value: mapEmail.value,
              label: i18n.language === 'vi' ? mapEmail.labelVI : mapEmail.labelEN
            })
          } else {
            const receiveDefaultValue = EmailOptions.find((x) => x.value === '3') ?? { value: '', label: '' }
            setValue('email.receiveInfoVia', receiveDefaultValue)
          }

          getDuplicateEmail(detail.email)
        }
      )
    )
  }

  const getDistricts = (provinceCode: string) =>
    pipe(
      GeneralService.getDistricts(provinceCode),
      ZIO.map((districts) => {
        const districtOptions = districts.map((district) => {
          return {
            value: district.code,
            label: i18n.language === 'vi' ? district.name : district.name
          }
        })
        setDistrictOptions(districtOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const getWards = (provinceCode: string, districtCode: string) =>
    pipe(
      GeneralService.getWards({ districtCode, provinceCode }),
      ZIO.map((wards) => {
        const wardOptions = wards.map((ward) => {
          return {
            value: ward.code,
            label: i18n.language === 'vi' ? ward.name : ward.name
          }
        })
        setWardOptions(wardOptions)
        return ZIO.unit
      }),
      ErrorHandling.run()
    )

  const SMSOptions = React.useMemo(() => {
    return SMSIndicator.map((sms) => {
      return {
        value: sms.value,
        label: i18n.language === 'vi' ? sms.labelVI : sms.labelEN
      }
    })
  }, [i18n.language])

  const pathPhone = () => {
    return pipe(
      detail,
      O.fromNullable,
      O.fold(
        () => console.log('Error'),
        (detail) => {
          setValue('phone.mobileCode', { value: detail.mobilePhoneCode, label: `+${detail.mobilePhoneCode}` })
          setValue('phone.mobileNum', detail.mobilePhoneNumber)
          setValue('phone.officeMobileCode', { value: detail.officePhoneCode, label: `+${detail.officePhoneCode}` })
          setValue('phone.officePhoneNum', detail.officePhoneNumber)
          const mapRelationShip = EmailIndicator.find((phone) => phone.value === detail.smsIndicator)
          if (mapRelationShip) {
            setValue('phone.receiveInfoVia', {
              value: mapRelationShip.value,
              label: i18n.language === 'vi' ? mapRelationShip.labelVI : mapRelationShip.labelEN
            })
          } else {
            const receiveDefaultValue = SMSOptions.find((x) => x.value === '3') ?? { value: '', label: '' }
            setValue('phone.receiveInfoVia', receiveDefaultValue)
          }
          getDuplicatePhone(detail.mobilePhoneNumber || '')
        }
      )
    )
  }

  const handlerEmail = React.useCallback(
    _.debounce((email) => {
      if (ChangeContactInfoForm.emailValidate(email)) {
        getDuplicateEmail(email)
      } else {
        setValue('email.relationships', [])
      }
    }, 300),
    [detail]
  )

  const updateSendDate = (sendDate: string) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      sendDate: sendDate
    }
    setEformData(eFormItem)
  }

  const updatedocReviewed = (docReview: boolean) => {
    let eFormItem = eformData
    eFormItem = {
      ...eFormItem,
      docReviewed: docReview
    }
    setEformData(eFormItem)
  }

  const getTransactionShortNameForCCE = (
    isPhone: boolean,
    isEmail: boolean,
    isAddress: boolean,
    isPhoneAndEmail: boolean,
    currentForm: ChangeContactInfoForm.Raw
  ) => {
    let smsShortMessage = ''
    const phone = _.get(currentForm, 'phone') as ChangeContactInfoForm.PhoneForm
    const email = _.get(currentForm, 'email') as ChangeContactInfoForm.EmailForm
    const isPhoneAndAddress = currentForm.chooseAddress && !currentForm.chooseEmail && currentForm.choosePhone
    const isEmailAndAddress = currentForm.chooseAddress && currentForm.chooseEmail && !currentForm.choosePhone
    const isALL = currentForm.chooseAddress && currentForm.chooseEmail && currentForm.choosePhone
    if (isPhone || isPhoneAndEmail || isPhoneAndAddress || isALL) {
      smsShortMessage =
        RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE) + ' ' + phone.mobileNum
    } else if (isEmail || isEmailAndAddress) {
      smsShortMessage = 'Cap nhat dia chi thu dien tu ' + email?.email ?? ''
    } else if (isAddress) {
      smsShortMessage = RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_ADDRESS)
    }
    return smsShortMessage
  }

  const getTransactionName = (currentForm: ChangeContactInfoForm.Raw) => {
    const isAll = currentForm.chooseAddress && (currentForm.chooseEmail || currentForm.choosePhone)
    const isAddress = currentForm.chooseAddress && !currentForm.chooseEmail && !currentForm.choosePhone
    const isPhone = !currentForm.chooseAddress && !currentForm.chooseEmail && currentForm.choosePhone
    const isEmail = !currentForm.chooseAddress && currentForm.chooseEmail && !currentForm.choosePhone
    const isPhoneAndEmail = !currentForm.chooseAddress && currentForm.chooseEmail && currentForm.choosePhone
    return isCCE
      ? getTransactionShortNameForCCE(isPhone, isEmail, isAddress, isPhoneAndEmail, currentForm)
      : isAll
      ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO)
      : isPhoneAndEmail
      ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL)
      : isAddress
      ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_ADDRESS)
      : isEmail
      ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_EMAIL)
      : isPhone
      ? RequestAuthenticateData.TransactionLabelShort(TransactionType.CHANGE_CONTACT_INFO_PHONE)
      : '-'
  }

  const onClickContinuedButtonEvent = async () => {
    const isValidData = await trigger()
    const isChosenOption = getValues('chooseAddress') || getValues('choosePhone') || getValues('chooseEmail')
    if (isValidData && isChosenOption) {
      const changeContactFormValue = getValues()
      const transactionName = getTransactionName(changeContactFormValue)
      setEformData({
        ...eformData,
        transactionName: transactionName,
        collerationId: policyNumber?.value || '',
        transaction: TransactionType.CHANGE_CONTACT_INFO
      })
      setCurrentTab(1)
    }
  }

  const onBack = () => {
    setCountinue(false)
  }

  const diffTwoDateAsMonth = (d1: Date, d2: Date) =>
    moment(d1).startOf('days').diff(moment(d2).startOf('days'), 'months')

  const onCountinue = () => {
    if (policyNumber) {
      setIsShowLoadingIcon(true)
      pipe(
        ChangeContactInfoService.getDataAccess(policyNumber.value),
        ZIO.map((accessDataCheck) => {
          if (
            !accessDataCheck.contractStatus ||
            !accessDataCheck.paidToDateAdvance ||
            (accessDataCheck.contractStatus !== ProductEnum.Status.IF &&
              accessDataCheck.contractStatus !== ProductEnum.Status.LA) ||
            (accessDataCheck.contractStatus === ProductEnum.Status.LA &&
              diffTwoDateAsMonth(new Date(), accessDataCheck.paidToDateAdvance) > 24)
          ) {
            showToast(t('message:MS050034'), 'error')
            setIsShowLoadingIcon(false)
          } else {
            setCountinue(true)
          }
          return accessDataCheck
        }),
        bindLoader,
        ErrorHandling.run()
      )
    }
  }

  const mapDataSubmit = (currentForm: ChangeContactInfoForm.Raw) => {
    const address = _.get(currentForm, 'address') as ChangeContactInfoForm.AddressForm
    const phone = _.get(currentForm, 'phone') as ChangeContactInfoForm.PhoneForm
    const email = _.get(currentForm, 'email') as ChangeContactInfoForm.EmailForm
    const chooseAddress = currentForm.chooseAddress
    const choosePhone = currentForm.choosePhone
    const chooseEmail = currentForm.chooseEmail

    let contactDetails = {}

    if (chooseEmail) {
      contactDetails = {
        ...contactDetails,
        EMAIL: {
          value: email?.email?.toString() || ''
        },

        EMAIL_IND: {
          value: email.receiveInfoVia?.value
        }
      }
    }

    if (choosePhone) {
      contactDetails = {
        ...contactDetails,
        PHONE: {
          value: phone.mobileNum,
          countryCode: phone.mobileCode.value
        },
        OFFICE_PHONE: {
          value: _.get(phone, 'officePhoneNum') || '',
          countryCode: _.get(phone, 'officeMobileCode.value') || ''
        },
        SMS_IND: {
          value: phone.receiveInfoVia?.value
        }
      }
    }

    const data: ChangeContactInfo.DataSubmit = {
      policyNo: (policyNumber?.value as string)!,
      owners: {
        clientId: detail?.customerId || ''
      },
      priorPolicies:
        chooseAddress && address.applyPolicies
          ? [
              { policyNo: (policyNumber?.value as string)! },
              ...address.applyPolicies
                .filter((e) => e.selected && !!e.policyNum)
                .map((policy) => ({ policyNo: policy.policyNum.toString() }))
            ]
          : [{ policyNo: (policyNumber?.value as string)! }],
      attributesExt: {
        PHONES_DUP:
          choosePhone && phone.relationships
            ? phone.relationships
                ?.filter((v) => v.relationship !== null)
                .map((e) => ({ clientId: e.clientId || '', relationshipType: e.relationship?.value || '' }))
            : [],
        EMAILS_DUP:
          chooseEmail && email.relationships
            ? email.relationships
                ?.filter((v) => v.relationship !== null)
                .map((e) => ({ clientId: e.clientId || '', relationshipType: e.relationship?.value || '' }))
            : [],
        PO_OWNER_ID: detail?.customerId || ''
      }
    }

    const requestdata = {
      ...data,
      dispatchAddress: chooseAddress
        ? {
            line1: address.street,
            zipcode: address.ward.value,
            countryCode: address.country.value,
            city: address.province.value,
            district: address.district.value,
            subDistrict: address.ward.value
          }
        : undefined,
      contactDetails,
      isCCE: false
    }
    return requestdata
  }

  const transactionTypeByPassAuthPaper = [
    TransactionType.FATCA_DECLARATION,
    TransactionType.SIGNATURE_REGISTRATION,
    TransactionType.HEALTH_DECLARATION,
    TransactionType.COMMON_REQUEST_FORM
  ]

  const isByPassAuthPaper = (): boolean => {
    return eformData.transaction !== null && transactionTypeByPassAuthPaper.includes(eformData.transaction)
  }

  const showOTPErrorMsg = (
    verify: RequestAuthenticateData.RequestAuthFormValue,
    isValidAuthDoc: boolean,
    isValidAuthFlag: boolean
  ) => {
    setIsShowLoadingIcon(false)
    showToast(
      verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
        ? t('OTP:inputValid')
        : !isValidAuthDoc
        ? t('message:MS050262')
        : !isValidAuthFlag
        ? t('message:MS050261')
        : 'Unknown Error',
      'error'
    )
    return false
  }

  const onSubmitDataWithAuthen = (submitService: (_: RequestAuthFormDataSubmit) => Task<unknown>) => {
    return (verify: RequestAuthenticateData.RequestAuthFormValue) => {
      const isValidOTP: boolean =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP &&
        verify.otp.otpInput.toString().length === 6
      const isValidAuthFlag =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER && eformData.docReviewed
      const isValidAuthDoc =
        verify.authOption === RequestAuthenticateData.RequestAuthOption.PAPER &&
        (verify.paper.data.length > 0 || isByPassAuthPaper())
      setIsShowLoadingIcon(true)

      return isValidOTP || (isValidAuthFlag && isValidAuthDoc)
        ? pipe(
            verify.authOption === RequestAuthenticateData.RequestAuthOption.OTP
              ? submitService({
                  type: RequestAuthenticateData.RequestAuthOption.OTP,
                  data: {
                    otp: verify.otp.otpInput,
                    transactionName: eformData.transactionName,
                    transactionCode: `${eformData.transactionType}-${eformData.collerationId}-${eformData.sendDate}`,
                    receiverPhone: eformData.phoneData.phoneNum,
                    payload: ''
                  }
                })
              : pipe(
                  !!eformData.metaData
                    ? StorageBlob.uploadToSubmit(
                        'PS',
                        eformData.collerationId
                      )(
                        verify.paper.data.map((x) => ({
                          file: x.file,
                          metaData: eformData.metaData
                        }))
                      )
                    : ZIO.succeed([]),
                  ZIO.flatMap((x) =>
                    submitService({
                      type: RequestAuthenticateData.RequestAuthOption.PAPER,
                      data: {
                        authFlag: eformData.docReviewed,
                        attachments: x
                      }
                    })
                  )
                ),
            ZIO.tap((e) => {
              showToast(t('message:RequestSendSuccessfully'), 'success')
              setIsShowLoadingIcon(false)
              requestAuthenForm.reset({
                authOption: RequestAuthenticateData.RequestAuthOption.OTP,
                otp: {
                  otpOption: RequestAuthenticateData.OTPOption.PHONE,
                  otpInput: undefined,
                  otpConsent: false
                },
                paper: {
                  data: [],
                  consent: false
                }
              })
              setCountinue(false)
              return ZIO.unit
            }),
            ZIO.mapError((err) => {
              setIsShowLoadingIcon(false)
              let getErrorMess = ''
              if (err.source && err.source.message.includes(' - ')) {
                const code = err.source.message.split(' - ')[0]
                const time = err.source.message.split(' - ')[1].replace('t=', '')
                getErrorMess =
                  code === 'MS050252'
                    ? t('message:MS050252', { t: time })
                    : code === 'MS050254'
                    ? t('message:MS050254', { t: time })
                    : code === 'MS050253'
                    ? t('message:MS050253')
                    : code === 'MS050255'
                    ? t('message:MS050255')
                    : 'Unknown error'
              } else {
                getErrorMess = t('message:MS050001')
              }
              showToast(getErrorMess, 'error')
              return getErrorMess
            }),
            ZIO.unsafeRun({})
          )
        : showOTPErrorMsg(verify, isValidAuthDoc, isValidAuthFlag)
    }
  }

  const onSubmit =
    (
      url: string,
      body: unknown,
      documents: StorageBlob.FileContentSubmit[],
      method?: 'POST' | 'PUT',
      uploadedFilesInfo?: UploadedFilesInfo[]
    ) =>
    (requestauth: RequestAuthFormDataSubmit) => {
      const requestAuthInfo = requestauth
      setIsShowLoadingIcon(true)
      return pipe(
        !!uploadedFilesInfo && uploadedFilesInfo.length > 0
          ? pipe(
              uploadedFilesInfo,
              A.map((uploadedItem) => {
                return GeneralService.getAzureStorageFiles(
                  uploadedItem?.uploadFiles,
                  uploadedItem.transactionType,
                  uploadedItem.docTypeCode,
                  uploadedItem.category,
                  uploadedItem.policyNumber,
                  uploadedItem.officeCode
                )
              }),
              ZIO.sequence,
              ZIO.map((uploadedList) => {
                let uploadFiles: StorageBlob.FileContentSubmit[] = []
                uploadedList.map((arrayItem) => {
                  uploadFiles = [...arrayItem, ...uploadFiles]
                })
                return uploadFiles
              })
            )
          : ZIO.succeed([]),
        ZIO.flatMap((azuredDocList) =>
          SubmissionService.submit(tc.unknown, method || 'POST')(
            url,
            body,
            policyNumber?.value || '',
            requestAuthInfo,
            [...documents, ...azuredDocList],
            detail?.customerId || '',
            isCCE,
            ChangeContactOfficeInfo.officeCode,
            undefined,
            undefined,
            undefined,
            SourceType.INBOUND
          )
        ),
        ZIO.foldM(
          (err) => {
            setIsShowLoadingIcon(false)
            return ZIO.fail(err)
          },
          (_) => {
            setIsShowLoadingIcon(false)
            return ZIO.unit
          }
        )
      )
    }

  const onClickSubmitButtonEvent = async () => {
    const isValidData = await trigger()
    if (isValidData) {
      const changeContactFormValue = getValues()
      const submitedData = mapDataSubmit(changeContactFormValue)
      const requestAuthenItem = requestAuthenForm.getValues()
      onSubmitDataWithAuthen(
        onSubmit(`wf-api/customer/${detail?.customerId}/contact/address`, { body: submitedData }, [], 'POST', [])
      )(requestAuthenItem)
    }
  }

  const validateRequestAuthenForm = () => {
    if (requestAuthenForm.watch('authOption') === RequestAuthenticateData.RequestAuthOption.PAPER) {
      return requestAuthenForm.watch('paper.consent')
    } else {
      return !!requestAuthenForm.watch('otp.otpInput') && requestAuthenForm.watch('otp.otpConsent')
    }
  }

  const onHandleClearAll = () => {
    reset()
  }

  return (
    <View
      style={[
        styles.modalCallResult,
        { height: isCountinue ? height * 0.75 : 200, width: isMaximize ? width * 0.8 : width * 0.55 }
      ]}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingTop: 20,
            marginBottom: 10
          }}
        >
          <Text style={{ fontWeight: '700', fontSize: 16 }}>
            {t('TransactionType:CHANGE_CONTACT_INFO').toUpperCase()}
          </Text>
          {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}> */}
          <Pressable onPress={() => setMaximize(!isMaximize)} style={{ marginRight: 15 }}>
            {isMaximize ? <assets.IBArrowMinimize /> : <assets.IBArrowMaximize />}
          </Pressable>
          {/* <Pressable onPress={() => setShowPopup(false)}>
              <assets.IBXMark />
            </Pressable>
          </View> */}
        </View>
        <View style={styles.formStyle}>
          {isCountinue ? (
            <View style={styles.formContainer}>
              <View style={{ paddingHorizontal: 20 }}>
                <IBChangeContactHeader headerData={headers} />
              </View>
              <TabList
                menus={menus}
                isSubTab={false}
                tabIndex={currentTab}
                onChangeTab={setCurrentTab}
                predicateDisable={(index) => index === 1}
              />
              <View
                style={{
                  padding: 15,
                  backgroundColor: '#fff',
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10
                }}
              >
                <Text style={sharedStyle.sectionTitle}>{t('submission:requestType')}</Text>
                <View
                  style={{
                    backgroundColor: '#FAFAFA',
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: '#d3dce6',
                    padding: 20,
                    marginTop: 15
                  }}
                >
                  <RequestInfoPanel dataSource={requestInfo}></RequestInfoPanel>
                </View>
                {/* Change Address */}
                <Controller
                  control={control}
                  name={'chooseAddress'}
                  defaultValue={getValues('chooseAddress')}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <View>
                        <Checkbox
                          title={t('submission:ChangeAdress').toUpperCase()}
                          value={value}
                          onChange={(val) => {
                            onChange(val)
                            if (val) pathAddress()
                          }}
                          onBlur={onBlur}
                          textStyle={{ fontWeight: 'bold' }}
                          disabled={isConfirmed}
                        />
                        {value && (
                          <OBSC.PanelContainer backgroundColor={'#fff'}>
                            <OBSC.Row>
                              <View style={{ flex: 1, minWidth: 250 }}>
                                <Controller
                                  control={control}
                                  name={'address.country'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:Country')}
                                        value={value?.label ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <SelectSearch
                                          label={t('submission:Country')}
                                          options={countryOptions || []}
                                          popupIcon={<assets.ArrowDownDropdownIcon />}
                                          value={value}
                                          placeholder={t('common:Select')}
                                          onChange={onChange}
                                          errorMessage={_.get(errors, 'address.country')?.message}
                                          disabled
                                          required
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                              <OBSC.Divider />
                              <View style={{ flex: 1 }}>
                                <Controller
                                  control={control}
                                  name={'address.province'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:CityProvince')}
                                        value={value?.label ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <SelectSearch
                                          label={t('submission:CityProvince')}
                                          value={value}
                                          options={provinceOptions || []}
                                          popupIcon={<assets.ArrowDownDropdownIcon />}
                                          placeholder={t('common:Select')}
                                          onChange={(value) => {
                                            if (value) {
                                              onChange(value)
                                              setValue('address.district', { label: '', value: '' })
                                              setValue('address.ward', { label: '', value: '' })
                                              getDistricts(value.value)
                                            }
                                          }}
                                          errorMessage={_.get(errors, 'address.province')?.message}
                                          required
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                              <OBSC.Divider />
                              <View style={{ flex: 1 }}>
                                <Controller
                                  control={control}
                                  name={'address.district'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:District')}
                                        value={value?.label ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <SelectSearch
                                          label={t('submission:District')}
                                          value={value}
                                          options={districOptions || []}
                                          popupIcon={<assets.ArrowDownDropdownIcon />}
                                          placeholder={t('common:Select')}
                                          onChange={(value) => {
                                            if (value) {
                                              onChange(value)
                                              setValue('address.ward', { label: '', value: '' })
                                              getWards(watch('address.province.value'), value.value)
                                            }
                                          }}
                                          errorMessage={_.get(errors, 'address.district')?.message}
                                          required
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                            </OBSC.Row>
                            <OBSC.Divider height={15} />
                            <OBSC.Row>
                              <View style={{ flex: 1, minWidth: 250 }}>
                                <Controller
                                  control={control}
                                  name={'address.ward'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:Ward')}
                                        value={value?.label ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <SelectSearch
                                          label={t('submission:Ward')}
                                          value={value}
                                          placeholder={t('common:Select')}
                                          options={wardOptions || []}
                                          popupIcon={<assets.ArrowDownDropdownIcon />}
                                          onChange={onChange}
                                          errorMessage={_.get(errors, 'address.ward')?.message}
                                          required
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                              <OBSC.Divider />
                              <View style={{ flex: 2, minWidth: 250 }}>
                                <Controller
                                  control={control}
                                  name={'address.street'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:Address')}
                                        value={value ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <Input
                                          title={t('submission:Address')}
                                          required
                                          onChange={(text) => {
                                            onChange(text)
                                          }}
                                          onBlur={() => {
                                            onBlur()
                                            onChange(value?.trim())
                                          }}
                                          value={value}
                                          maxLength={50}
                                          errorMessage={_.get(errors, 'address.street')?.message}
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                            </OBSC.Row>
                            {applyPolicyAddress && applyPolicyAddress.length > 0 && (
                              <OBSC.Padding top={20}>
                                <OBSC.TitleText>{t('submission:AppliedTo')}</OBSC.TitleText>
                              </OBSC.Padding>
                            )}
                            {applyPolicyAddress && applyPolicyAddress.length > 0 && (
                              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Checkbox
                                  value={!applyPolicyAddress.some((a) => !a.selected)}
                                  onChange={(val) => {
                                    applyAllPolicy(val)
                                  }}
                                  disabled={isConfirmed}
                                />
                                <Text style={{ color: 'rgb(67, 72, 77)' }}>{t('submission:AllPolicies')}</Text>
                              </View>
                            )}
                            {applyPolicyAddress &&
                              applyPolicyAddress.map((value, index) => {
                                return (
                                  <Controller
                                    key={`list_address${value?.policyNum}${value?.selected}`}
                                    control={control}
                                    name={`address.applyPolicies.${index}`}
                                    render={({ field: { value, onChange, onBlur } }) => {
                                      return (
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                          <Checkbox
                                            value={value.selected}
                                            onChange={(checked) => onChange({ ...value, selected: checked })}
                                            disabled={isConfirmed}
                                          />
                                          <Text style={{ color: 'rgb(67, 72, 77)' }}>{`${value.policyNum || ''} ${
                                            value.address || ''
                                          }`}</Text>
                                        </View>
                                      )
                                    }}
                                  />
                                )
                              })}
                          </OBSC.PanelContainer>
                        )}
                      </View>
                    )
                  }}
                />

                {/* Change Phone number */}
                <Controller
                  control={control}
                  name={'choosePhone'}
                  defaultValue={getValues('choosePhone')}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <View>
                        <Checkbox
                          title={t('submission:ChangePhone').toUpperCase()}
                          value={value}
                          onChange={(val) => {
                            if (val) pathPhone()
                            onChange(val)
                          }}
                          onBlur={onBlur}
                          textStyle={{ fontWeight: 'bold' }}
                          disabled={isConfirmed}
                        />
                        {value && (
                          <SC.Padding top={5} bottom={5} horizontal={0}>
                            <SC.ErrorText>{t('message:MS050224')}</SC.ErrorText>
                          </SC.Padding>
                        )}
                        {value && (
                          <OBSC.PanelContainer backgroundColor={'#fff'}>
                            <OBSC.Row>
                              <View style={{ flex: 1, paddingTop: 4 }}>
                                <ConfirmView
                                  title={t('submission:MobilePhone')}
                                  value={`${getValues('phone.mobileCode.label') ?? ''} ${
                                    getValues('phone.mobileNum') ?? ''
                                  }`}
                                  isConfirmed={isConfirmed}
                                >
                                  <View>
                                    <OBSC.TitleText>
                                      {t('submission:MobilePhone')} <OBSC.RequiredMark>*</OBSC.RequiredMark>
                                    </OBSC.TitleText>
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                      <Controller
                                        control={control}
                                        name={'phone.mobileCode'}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                          return (
                                            <View style={{ width: 110 }}>
                                              <SelectSearch
                                                options={phoneCodeOptions}
                                                onChange={onChange}
                                                value={value}
                                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                                hideLabel
                                              />
                                            </View>
                                          )
                                        }}
                                      />
                                      <OBSC.Divider />
                                      <Controller
                                        control={control}
                                        name={'phone.mobileNum'}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                          return (
                                            <View style={{ flex: 1 }}>
                                              <Input
                                                onChange={(value) => {
                                                  onChange(value)
                                                  if (/\b\d{10}\b/.test(value)) {
                                                    getDuplicatePhone(value)
                                                  } else {
                                                    setValue('phone.relationships', [])
                                                  }
                                                }}
                                                value={value || undefined}
                                                maxLength={10}
                                              />
                                            </View>
                                          )
                                        }}
                                      />
                                    </View>
                                    {(_.get(errors, 'phone.mobileCode')?.message ||
                                      _.get(errors, 'phone.mobileNum')?.message) && (
                                      <View>
                                        <OBSC.ErrorText>
                                          {_.get(errors, 'phone.mobileCode')?.message ||
                                            _.get(errors, 'phone.mobileNum')?.message}
                                        </OBSC.ErrorText>
                                      </View>
                                    )}
                                  </View>
                                </ConfirmView>
                              </View>
                              <OBSC.Divider />
                              <View style={{ flex: 1, paddingTop: 4 }}>
                                <ConfirmView
                                  title={t('submission:SecondaryPhone')}
                                  value={
                                    !!getValues('phone.officePhoneNum')
                                      ? `${getValues('phone.officeMobileCode.label') ?? ''} ${
                                          getValues('phone.officePhoneNum') ?? ''
                                        }`
                                      : ''
                                  }
                                  isConfirmed={isConfirmed}
                                  required={false}
                                >
                                  <View>
                                    <OBSC.TitleText>{t('submission:SecondaryPhone')}</OBSC.TitleText>
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                                      <Controller
                                        control={control}
                                        name={'phone.officeMobileCode'}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                          return (
                                            <View style={{ width: 110 }}>
                                              <SelectSearch
                                                options={phoneCodeOptions}
                                                onChange={onChange}
                                                value={value}
                                                popupIcon={<assets.ArrowDownDropdownIcon />}
                                                hideLabel
                                              />
                                            </View>
                                          )
                                        }}
                                      />
                                      <OBSC.Divider />
                                      <Controller
                                        control={control}
                                        name={'phone.officePhoneNum'}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                          return (
                                            <View style={{ flex: 1 }}>
                                              <Input
                                                onChange={(value) => {
                                                  onChange(value)
                                                  trigger()
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                maxLength={10}
                                              />
                                            </View>
                                          )
                                        }}
                                      />
                                    </View>
                                    {(_.get(errors, 'phone.officeMobileCode')?.message ||
                                      _.get(errors, 'phone.officePhoneNum')?.message) && (
                                      <View>
                                        <OBSC.ErrorText>
                                          {_.get(errors, 'phone.officeMobileCode')?.message ||
                                            _.get(errors, 'phone.officePhoneNum')?.message}
                                        </OBSC.ErrorText>
                                      </View>
                                    )}
                                  </View>
                                </ConfirmView>
                              </View>
                              <OBSC.Divider />
                              <View style={{ flex: 1 }}>
                                <Controller
                                  control={control}
                                  name={'phone.receiveInfoVia'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:ReceiveInfoViaSms')}
                                        value={value?.label ?? ''}
                                        required={false}
                                        isConfirmed={isConfirmed}
                                      >
                                        <Select
                                          options={SMSOptions}
                                          // popupIcon={<View />}
                                          onChange={onChange}
                                          value={value}
                                          label={t('submission:ReceiveInfoViaSms')}
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                            </OBSC.Row>

                            {anyAgentPhone ? (
                              <OBSC.Padding vertical={15}>
                                <OBSC.ErrorText>
                                  {t('message:MS050225', { agent: _.get(anyAgentPhone, 'clientName') })}
                                </OBSC.ErrorText>
                              </OBSC.Padding>
                            ) : (
                              phoneRelationShip &&
                              phoneRelationShip.length > 0 && (
                                <OBSC.Container>
                                  {!isConfirmed && (
                                    <OBSC.Padding vertical={10}>
                                      <OBSC.ErrorText>{t('submission:PhoneDuplicatedWithOther')}</OBSC.ErrorText>
                                    </OBSC.Padding>
                                  )}
                                  {_.get(errors, 'phone.relationships') && (
                                    <OBSC.Padding vertical={5}>
                                      <OBSC.ErrorText>{t('message:MS050228')}</OBSC.ErrorText>
                                    </OBSC.Padding>
                                  )}
                                  <Controller
                                    control={control}
                                    name={'phone.relationships'}
                                    render={({ field: { value, onChange } }) => {
                                      return (
                                        <DuplicateTable
                                          key={`phone_table_${isConfirmed}`}
                                          value={value ?? []}
                                          onChange={onChange}
                                          disable={isConfirmed}
                                          relationshipOptions={relationOptions ?? []}
                                        />
                                      )
                                    }}
                                  />
                                </OBSC.Container>
                              )
                            )}
                          </OBSC.PanelContainer>
                        )}
                      </View>
                    )
                  }}
                />

                {/* Change Email */}
                <Controller
                  control={control}
                  name={'chooseEmail'}
                  defaultValue={getValues('chooseEmail')}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <View>
                        <Checkbox
                          title={t('submission:ChangeEmail').toUpperCase()}
                          value={value}
                          onChange={(val) => {
                            if (val) pathEmail()
                            onChange(val)
                          }}
                          onBlur={onBlur}
                          textStyle={{ fontWeight: 'bold' }}
                          // checkedColor={'red'}
                          disabled={isConfirmed}
                        />
                        {value && (
                          <OBSC.Padding top={5} bottom={5} horizontal={0}>
                            <OBSC.ErrorText>{t('message:MS050226')}</OBSC.ErrorText>
                          </OBSC.Padding>
                        )}
                        {value && (
                          <OBSC.PanelContainer backgroundColor={'#fff'}>
                            <OBSC.Row>
                              <View style={{ width: '33%', paddingTop: 5 }}>
                                <Controller
                                  control={control}
                                  name={'email.email'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:Email')}
                                        value={value ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <Input
                                          value={value}
                                          onChange={(value) => {
                                            onChange(value)
                                            handlerEmail(value)
                                          }}
                                          onBlur={onBlur}
                                          required
                                          title={t('submission:Email')}
                                          errorMessage={_.get(errors, 'email.email')?.message}
                                          //onSubmit={() => getDuplicateEmail(value)}
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                              <OBSC.Divider />
                              <View style={{ width: '30%', paddingTop: 5 }}>
                                <Controller
                                  control={control}
                                  name={'email.receiveInfoVia'}
                                  render={({ field: { value, onChange, onBlur } }) => {
                                    return (
                                      <ConfirmView
                                        title={t('submission:ReceiveInfoViaEmail')}
                                        value={value?.label ?? ''}
                                        isConfirmed={isConfirmed}
                                      >
                                        <Select
                                          options={EmailOptions}
                                          onChange={onChange}
                                          value={value}
                                          label={t('submission:ReceiveInfoViaEmail')}
                                        />
                                      </ConfirmView>
                                    )
                                  }}
                                />
                              </View>
                            </OBSC.Row>

                            <OBSC.Divider />

                            {anyAgentEmail ? (
                              <OBSC.Padding vertical={10}>
                                <OBSC.ErrorText>
                                  {t('message:MS050227', { agent: _.get(anyAgentEmail, 'clientName') })}
                                </OBSC.ErrorText>
                              </OBSC.Padding>
                            ) : (
                              emailRelationShip &&
                              emailRelationShip.length > 0 && (
                                <OBSC.Container>
                                  {!isConfirmed && (
                                    <OBSC.Padding vertical={10}>
                                      <OBSC.ErrorText>{t('submission:EmailDuplicatedWithOther')}</OBSC.ErrorText>
                                    </OBSC.Padding>
                                  )}
                                  {_.get(errors, 'email.relationships') && (
                                    <OBSC.Padding vertical={5}>
                                      <OBSC.ErrorText>{t('message:MS050228')}</OBSC.ErrorText>
                                    </OBSC.Padding>
                                  )}
                                  <Controller
                                    control={control}
                                    name={'email.relationships'}
                                    render={({ field: { value, onChange } }) => {
                                      return (
                                        <DuplicateTable
                                          key={`email_table_${isConfirmed}`}
                                          value={value ?? []}
                                          onChange={onChange}
                                          disable={isConfirmed}
                                          relationshipOptions={relationOptions ?? []}
                                        />
                                      )
                                    }}
                                  />
                                </OBSC.Container>
                              )
                            )}
                          </OBSC.PanelContainer>
                        )}
                      </View>
                    )
                  }}
                />

                {currentTab === 1 && (
                  <>
                    <RequestAuthen
                      requestAuthenData={eformData}
                      authenForm={requestAuthenForm}
                      updateSendDate={updateSendDate}
                      updatedocReviewed={updatedocReviewed}
                      updateRequestAuthenData={setEformData}
                      isByPassAuthPaper={false}
                      officeCode={''}
                      transactionType={TransactionType.CHANGE_CONTACT_INFO}
                      // isHiddenRequestForm={true}
                    />
                  </>
                )}
              </View>
            </View>
          ) : (
            <View style={styles.formSearchContainer}>
              <View style={{ width: `100%` }}>
                <SelectSearch
                  label={t('Inbound:table:PolicyNumber')}
                  options={orderBy(policyLists, 'value', 'desc')}
                  onChange={(value) => {
                    if (value) setPolicyNumber(value)
                  }}
                  value={policyNumber}
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                />
              </View>
            </View>
          )}
        </View>
      </ScrollView>
      <View>
        {isCountinue ? (
          <View style={styles.btnBar}>
            <IBButton onPress={() => onBack()} title={t('common:Back')} backgroundFill={false} width={200} />
            {currentTab === 0 ? (
              <>
                <IBButton
                  onPress={() => onHandleClearAll()}
                  title={t('submission:ClearAll')}
                  backgroundFill={false}
                  width={200}
                />
                <IBButton
                  onPress={() => onClickContinuedButtonEvent()}
                  title={t('submission:Continue')}
                  backgroundFill={false}
                  width={200}
                />
              </>
            ) : (
              <>
                <IBButton
                  onPress={() => onClickSubmitButtonEvent()}
                  title={t('submission:SendRequest')}
                  backgroundFill
                  width={200}
                  disabled={!validateRequestAuthenForm()}
                />
              </>
            )}
          </View>
        ) : (
          <View style={{ borderTopWidth: 1, borderColor: '#EBEBF0', paddingTop: 15, marginBottom: 15 }}>
            <IBButton onPress={() => onCountinue()} title={t('submission:Continue')} backgroundFill width={'90%'} />
          </View>
        )}
      </View>
      {isShowLoadingIcon ? (
        <View style={styles.fabLoading}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  modalCallResult: {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 40,
    elevation: 40,
    shadowOpacity: 1
  },
  formStyle: {
    flex: 1,
    padding: 20,
    height: '100%'
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 50,
    justifyContent: 'space-between',
    marginBottom: 15
  },
  formContainer: {
    borderColor: '#D3DCE6',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: '#F4F4F4',
    marginBottom: 15
  },
  formSearchContainer: {
    marginBottom: 15,
    height: 10
  },
  fabLoading: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  }
})
