import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'

export const RelatedDocResponseC = t.type({
  url: t.string,
  metaData: t.type({
    doctype: Maybe(t.string),
    docid: Maybe(t.string),
    maindoc: Maybe(t.string),
    subdoc: Maybe(t.string),
    batchno: Maybe(t.string)
  })
})

export const AzureDocResponseC = t.type({
  url: t.string,
  metaData: t.type({
    Docid: Maybe(t.string),
    Type: Maybe(t.string),
    Doctype: Maybe(t.string),
    Batchno: Maybe(t.string),
    Subdoc: Maybe(t.string),
    Class: Maybe(t.string),
    Polnum: Maybe(t.string),
    Maindoc: Maybe(t.string),
    Doctypebpm: Maybe(t.string),

    docid: Maybe(t.string),
    type: Maybe(t.string),
    doctype: Maybe(t.string),
    batchno: Maybe(t.string),
    subdoc: Maybe(t.string),
    class: Maybe(t.string),
    polnum: Maybe(t.string),
    maindoc: Maybe(t.string),
    doctypebpm: Maybe(t.string)
  })
})

export type RelatedDocResponse = t.TypeOf<typeof RelatedDocResponseC>

export const RelatedDocCodex = t.type({
  archivedDocuments: Maybe(t.array(RelatedDocResponseC)),
  azureDocuments: Maybe(t.array(AzureDocResponseC))
})

export type RelatedDoc = t.TypeOf<typeof RelatedDocCodex>
