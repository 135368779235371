import { Permission, AppContext, CanView, assets, ErrorHandling, RBAC } from '@pulseops/common'
import React from 'react'
import { StyleSheet, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { TabView } from './common/TabView'
import { OfficeRule8Tab } from './da-OfficeRule8Tab/OfficeRule8Tab'
import { GADRule4Tab } from './da-GADRule4Tab/GADRule4Tab'
import { LocationRule3Tab } from './da-LocationRule3Tab/LocationRule3Tab'
import { LocationRegionTab } from './da-LocationRegionTab/LocationRegionTab'
import { BIG_SCREEN_SIZE } from '../task-dashboard/common'
import { pipe } from 'fp-ts/function'
import { StackScreenProps } from '@react-navigation/stack'
import { UtilitiesStackParamList } from '@pulseops/utilites'
import { useLoading } from '@mxt/zio-react'
// import { OBChannelDocumnentTab } from './da-OBChannelDocumentTab/OBChannelDocumentTab'

type props = StackScreenProps<UtilitiesStackParamList, 'DsAdminScreen'>

export const DsAdminScreen = ({ navigation }: props) => {
  const { width } = useWindowDimensions()
  const { t } = useTranslation('DsAdmin')
  const isFocused = useIsFocused()
  const { changeBreadcrumb, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const [tabActiveIndex, setTabActiveIndex] = React.useState(0)
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const [loading] = useLoading(false)
  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('Utilities')
          }
        },
        {
          title: t('menu:DistributionServices'),
          navigate: () => {
            navigation.navigate('DistributionServicesScreen')
          }
        },
        {
          title: t('menu:DsAdmin'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const tabRule8 = React.useMemo(() => {
    return permissions.includes(Permission.ViewDSAdmin)
  }, [permissions])

  const tabRule4 = React.useMemo(() => {
    return permissions.includes(Permission.ViewDSAdmin)
  }, [permissions])

  const tabRule3 = React.useMemo(() => {
    return permissions.includes(Permission.ViewDSAdmin)
  }, [permissions])

  const tabRegion = React.useMemo(() => {
    return permissions.includes(Permission.ViewDSAdmin)
  }, [permissions])

  // const tabChannelDoc = React.useMemo(() => {
  //   return (
  //     permissions.includes(Permission.ViewDSAdmin)
  //   )
  // }, [permissions])

  const getTabScenes = () => {
    const tabs = []
    if (tabRule8) {
      tabs.push({
        title: 'Office Rule 8',
        Component: <OfficeRule8Tab active={tabActiveIndex === tabs?.length} permissions={permissions} />
      })
    }
    if (tabRule4) {
      tabs.push({
        title: 'GAD Rule 4',
        Component: <GADRule4Tab active={tabActiveIndex === tabs?.length} permissions={permissions} />
      })
    }
    if (tabRule3) {
      tabs.push({
        title: 'Location Rule 3',
        Component: <LocationRule3Tab active={tabActiveIndex === tabs?.length} permissions={permissions} />
      })
    }
    if (tabRegion) {
      tabs.push({
        title: 'Location Region',
        Component: <LocationRegionTab active={tabActiveIndex === tabs?.length} permissions={permissions} />
      })
    }
    // if (tabChannelDoc) {
    //   tabs.push({
    //     title: 'OB channels document mapping',
    //     Component: <OBChannelDocumnentTab active={tabActiveIndex === tabs?.length} permissions={permissions} />
    //   })
    // }
    return tabs
  }

  return (
    <View style={styles.container}>
      <CanView condition={width < BIG_SCREEN_SIZE}>
        <View style={styles.FABContainer}>
          <TouchableOpacity activeOpacity={0.7} style={styles.timeFAB}>
            <assets.TimeCircleIcon />
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.7} style={styles.calendarFAB}>
            <assets.CalenderIcon />
          </TouchableOpacity>
        </View>
      </CanView>
      <View style={styles.bottomBox}>
        <TabView
          initTabIndex={0}
          containerStyle={styles.kanbanBoard}
          onTabChanged={setTabActiveIndex}
          scenes={getTabScenes()}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F9F9F9',
    paddingTop: 16
  },

  bottomBox: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },

  kanbanBoard: {
    flex: 1
  },

  FABContainer: {
    position: 'absolute',
    bottom: 38,
    end: 34,
    zIndex: 4
  },

  timeFAB: {
    width: 55,
    height: 55,
    borderRadius: 55,
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    borderWidth: 1,
    borderColor: '#E5EAEF',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3
  },

  calendarFAB: {
    width: 55,
    height: 55,
    borderRadius: 55,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ED1B2E',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3
  }
})
