import * as React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'

interface IProps {
  isHavingToolbar?: boolean
  title?: string
  disable?: boolean
  value?: string
  onChange?: (val: string) => void
}

export const ParagraphInput: React.FC<IProps> = ({ value, onChange, isHavingToolbar = true, title, disable }) => {
  return (
    <View style={{ marginBottom: 32 }}>
      <Text style={styles.label}>{title}</Text>
      <View style={styles.toolbar}>
        <TextInput
          value={value}
          onChangeText={onChange}
          editable={disable !== true}
          style={{ alignItems: 'flex-start', padding: 5, height: 190, borderWidth: 1, borderColor: '#D3DCE6' }}
          numberOfLines={5}
          multiline
        />
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  toolbar: {
    width: '100%',
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#FAFAFA'
  }
})
