import React, { useRef, RefObject } from 'react'
import { SC, TextCustom } from './styles'
import FormArea, { FormAreaRef } from './FormArea'
import { FlatList, ScrollView } from 'react-native'
import { COLORS } from '../constants'
import { RoundedButton, UploadManualService, StorageBlob, AppContext, ErrorHandling, RBAC, Permission } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { FormGroupI, FormValueI } from './models'
import { isEmpty, isNil, isNull } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useIsFocused, useNavigation } from '@react-navigation/native'

const { Container, Header, Body, Footer } = SC

type ButtonType = 'CLEAR' | 'SUBMIT'

interface ButtonI {
  type: ButtonType
  text: string
  textColorEnable: string
  bgColorEnable: string
  borderColorEnable: string
}

const buttons = (t: any): ButtonI[] => [
  {
    type: 'CLEAR',
    text: t('utilities:ClearAll'),
    textColorEnable: COLORS.ALIZARIN,
    bgColorEnable: COLORS.WHITE,
    borderColorEnable: COLORS.ALIZARIN
  },
  {
    type: 'SUBMIT',
    text: t('utilities:Submit'),
    textColorEnable: COLORS.WHITE,
    bgColorEnable: COLORS.ALIZARIN,
    borderColorEnable: COLORS.ALIZARIN
  }
]

export const UpdateDocumentGuideScreen = () => {
  const { t } = useTranslation()
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const formRef = useRef<FormAreaRef>()
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  pipe(
    UploadManualService.getAllt17s2(),
    ZIO.map((res) => {
      const { setFormGroups, setGetAllsData } = formRef?.current ?? {}
      const departments = [...new Map(res.map((item) => [item['department'], 
      {
        label: item.department,
        value: item.departmentCode
      }])).values()]
      setGetAllsData?.([...res])
      setFormGroups?.((prev: FormGroupI[]) => {
        return prev.fill(
          {
            ...prev[0],
            options: departments.map((elm) => (elm))
          },
          prev.indexOf(prev[0]),
          prev.indexOf(prev[0]) + 1
        )
      })
    }),
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Utilities'),
          navigate: () => {
            navigate('UtilitiesStack', { screen: 'Utilities' })
          }
        },
        { title: t('utilities:UploadManualGuideline'), navigate: null }
      ])
    }
    formRef?.current?.clearForm?.()
    formRef?.current?.setFiles?.([])
  }, [isFocused])

  const mappingData = (data: FormValueI) => {
    const { department, group, mainDoc, subDoc } = data
    const { getAllsData } = formRef?.current ?? {}
    return (
      getAllsData?.filter(
        (item: UploadManualService.DepartmentT17s2Type) =>
          item.departmentCode === department?.value &&
          item.mainDoc === mainDoc?.value &&
          item.group === group?.value &&
          item.subDoc === subDoc?.value
      ) ?? []
    )
  }

  const getCategory = (value: string) => {
    let str = ''
    const result = value
      .split(' ')
      .map((item) => str + item.slice(0, 1))
      .join('')
    return result
  }

  const onSubmit = (data: FormValueI) => {
    const metaData: UploadManualService.DepartmentT17s2Type = mappingData(data)[0]
    const { files, setFiles, clearForm } = formRef?.current ?? {}
    const bodyInfo: UploadManualService.BodyUploadGuide = {
      departmentCode: data.department?.value ?? '',
      group: data.group?.value ?? '',
      mainDoc: data.mainDoc?.value ?? '',
      subDoc: data.subDoc?.value ?? ''
    }
    if (isEmpty(files)) return
    showGlobalLoading(true)
    return pipe(
      ZIO.succeed(files),
      ZIO.map((files) => {
        const metaDataRaw: StorageBlob.MetaDataUpload = {
          type: metaData.department ?? '',
          doctype: metaData.docTypeEN ?? '',
          class: metaData.className ?? '',
          docid: metaData.docId ?? '',
          maindoc: metaData.mainDoc ?? '',
          subdoc: metaData.subDoc ?? '',
          polnum: '11111111',
          batchno: moment().format('DD/MM/YYYY')
        }

        return !isNil(files)
          ? files.map((item: StorageBlob.FileContent) => ({
              file: item.file,
              metaData: metaDataRaw
            }))
          : []
      }),
      ZIO.flatMap((fileList) => StorageBlob.uploadToSubmit(getCategory(metaData.department), '11111111')(fileList)),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        showToast(t('message:MS050001'), 'error')
        return ZIO.unit
      }),
      ZIO.flatMap((res) => (res && res.length > 0 ? UploadManualService.SubmitGuide(res, bodyInfo) : ZIO.succeed({}))),
      ZIO.tap(() => {
        showGlobalLoading(false)
        setFiles?.([])
        clearForm?.()
        showToast(t('message:MS040012'), 'success')
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const renderFormArea = <FormArea ref={formRef as RefObject<FormAreaRef>} onSubmit={onSubmit} />

  const renderButtons = (): JSX.Element => {
    const handlePress = (type: ButtonType) => {
      const { submit, clearForm, setFiles, files } = formRef?.current ?? {}

      const pressHandler = {
        CLEAR: () => {
          setFiles?.([])
          clearForm?.()
          return
        },
        SUBMIT: () => {
          isNull(files) && setFiles?.([])
          return submit?.()
        }
      }

      return pressHandler?.[type]?.()
    }

    return (
      <FlatList
        data={buttons(t)}
        contentContainerStyle={{
          alignSelf: 'center',
          flexDirection: 'row'
        }}
        renderItem={({ item, index }) => (
          <RoundedButton
            activeOpacity={0.7}
            text={item.text}
            filled
            showBorder={true}
            textColorEnable={item.textColorEnable}
            bgColorEnable={item.bgColorEnable}
            borderColorEnable={item.borderColorEnable}
            style={{
              minWidth: 100,
              height: 39,
              paddingHorizontal: 13,
              ...(index === 0 && { marginRight: '12px' })
            }}
            textStyle={{ fontSize: 15 }}
            onPress={() => handlePress(item.type)}
          />
        )}
      />
    )
  }

  return (
    <Container>
      {permissions.includes(Permission['UploadMGDetailUploadManualGuideline']) && 
        <ScrollView>
          <Header>
            <TextCustom fontSize="15px" fontWeight="bold">
              {t('utilities:ManualGuideline').toUpperCase()}
            </TextCustom>
          </Header>
          <Body>{renderFormArea}</Body>
          <Footer>{renderButtons()}</Footer>
        </ScrollView>
      }
    </Container>
  )
}
