import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'
import { useMobile } from '@pulseops/common'

export namespace TableField {
  export type Columns = {
    key?: string | number
    title: string | JSX.Element
    name: string
    align?: 'left' | 'center' | 'right'
    color?: (val: string) => string
  }

  type keyType = string | number | JSX.Element

  export type DataSource<T = keyType> = {
    [key: string]: T
  }

  export type DataSources<T = keyType> = DataSource<T>[]

  export interface TableBody {
    columns: TableField.Columns[]
    dataSource: TableField.DataSources
    name?: string
    styleBody?: StyleProp<TextStyle>
  }
}

interface TableProps {
  columns: TableField.Columns[]
  dataSource: TableField.DataSources | any[]
  name?: string
  title?: string
  display?: boolean
  currency?: string
  styleHeader?: StyleProp<TextStyle>
  styleBody?: StyleProp<TextStyle>
}

function alignStyle(align: TableField.Columns['align']) {
  switch (align) {
    case 'right':
      return styles.alignRight
    case 'center':
      return styles.alignCenter
    default:
      return {}
  }
}

const TableHeader = ({
  columns,
  styleHeader
}: {
  columns: TableField.Columns[]
  styleHeader: StyleProp<TextStyle>
}) => {
  return (
    <View style={[styles.tableRow, { borderTopWidth: 0 }, styleHeader]}>
      {columns.map((column, index) => (
        <View
          key={column.key ? column.key : `table-header-${index}-${column.name}`}
          style={[styles.tableCol, { flex: 1 / columns.length }]}
        >
          <Text style={[styles.tableColHeaderText, alignStyle(column.align)]}>{column.title}</Text>
        </View>
      ))}
    </View>
  )
}

const TableCol = ({ cols, columns }: { cols: TableField.DataSource; columns: TableField.Columns[] }) => {
  return (
    <View style={styles.tableRow}>
      {columns.map((col, i) => {
        const value = cols[col.name] as string
        return (
          <View key={i} style={[styles.tableCol, { flex: 1 / columns.length }]}>
            <Text
              style={[styles.tableColText, alignStyle(col.align), { color: col.color ? col.color(value) : '#000' }]}
            >
              {value}
            </Text>
          </View>
        )
      })}
    </View>
  )
}

const TableBody = ({ columns, dataSource, name, styleBody }: TableField.TableBody) => {
  const { t } = useTranslation()
  let xhtml: JSX.Element[] = [
    <View style={styles.tableRow} key={0}>
      <View style={[styles.tableCol, { flex: 1 }]}>
        <Text style={[styles.tableColText, { textAlign: 'center' }]}>{t('common:Nodata')}</Text>
      </View>
    </View>
  ]
  if (dataSource.length > 0) {
    xhtml = dataSource.map((data, i) => <TableCol key={i} cols={data} columns={columns} />)
  }
  return <View style={styleBody}>{xhtml}</View>
}

const TableMobile = ({ columns, dataSource }: TableProps) => {
  return (
    <View>
      {(dataSource as any[]).map((row: any, index: number) => (
        <View style={[mobileStyles.item, index % 2 ? {} : mobileStyles.gray]}>
          {columns.map(({ key, title, name }) => (
            <View>
              <Text key={key} style={mobileStyles.label}>
                {title}
              </Text>
              <Text key={key} style={mobileStyles.value}>
                {row[name]}
              </Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

export const TableField = (props: TableProps) => {
  const { columns, dataSource, currency, title, name, display, styleHeader, styleBody } = props
  const { t } = useTranslation('requestInfo')
  const { isMobile } = useMobile()
  // console.log(name)

  if (!display) {
    return null
  }

  return isMobile ? (
    <TableMobile {...props} />
  ) : (
    <View style={styles.table}>
      <View style={styles.header}>
        {title && <Text style={styles.title}>{title}</Text>}
        {currency && (
          <Text style={styles.currencty}>
            {t('Currency')}: {currency}
          </Text>
        )}
      </View>
      <TableHeader columns={columns} styleHeader={styleHeader} />
      <TableBody columns={columns} dataSource={dataSource} name={name} styleBody={styleBody} />
    </View>
  )
}

TableField.defaultProps = {
  currency: 'VND',
  display: true
}

const styles = StyleSheet.create({
  table: {
    width: '100%'
  },
  tableRow: {
    flexDirection: 'row',
    width: '100%',
    borderTopColor: '#EEEEEE',
    borderTopWidth: 2,
    paddingVertical: 10
  },
  tableCol: {
    justifyContent: 'center',
    alignContent: 'center'
  },
  tableColHeaderText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700'
  },
  tableColText: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '300',
    width: '92%'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignCenter: {
    textAlign: 'center'
  },
  currencty: {
    fontSize: 15,
    lineHeight: 24,
    fontStyle: 'italic',
    fontWeight: '300',
    textAlign: 'right',
    color: '#70777E',
    flex: 1
  },
  header: {
    flexDirection: 'row'
  },
  title: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800'
  }
})

const mobileStyles = StyleSheet.create({
  label: {
    color: '#70777E'
  },
  value: {
    lineHeight: 22,
    fontSize: 15,
    marginBottom: 18
  },
  gray: {
    backgroundColor: '#FAFAFA'
  },
  item: {
    paddingTop: 18,
    paddingHorizontal: 16
  }
})
