import { Customer } from '../Client'
import { SourceType } from '../SourceType'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import moment from 'moment'

export interface ChangeNationalIDCard {
  tag: TransactionType.CHANGE_NATIONAL_ID_CARD
  source: SourceType
  clientName: string
  data: {
    idNumber: string
    issuedBy: string
    issuedDate: string
    createDate: string
  }
}

interface Props {
  detail: api.ChangeNationalIDCard
  customer: Customer
}

export const ChangeNationalIDCard = ({ detail, customer }: Props): ChangeNationalIDCard => {
  const { transactionType, source, payload } = detail

  const { attributesExt, createdDate } = payload.body ?? {}
  const { idNumber, issuedBy, issuedDate } = attributesExt ?? {}

  const {
    body: { name }
  } = customer

  switch (source) {
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.PULSE4OPS: {
      return {
        tag: transactionType,
        source: source,
        clientName: name ?? '-',
        data: {
          idNumber: idNumber ?? '-',
          issuedBy: issuedBy ?? '-',
          issuedDate: moment(issuedDate ?? '', 'DD/MM/YYYY', true).isValid()
            ? issuedDate ?? '-'
            : moment(issuedDate ?? '').format('DD/MM/YYYY') ?? '-',
          createDate: createdDate ? moment(createdDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')
        }
      }
    }
  }
}
