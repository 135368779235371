import * as React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { AccountingStackParamList } from './AccountingStackParamList'
import { AccountingListScreen } from './AccountingList'
import { NoteListScreen } from './note-list-screen'
import { NoteDetailScreen } from './note-detail-screen'

const Stack = createStackNavigator<AccountingStackParamList>()

export const AccountingStack = () => {
  return (
    <Stack.Navigator initialRouteName="AccountingList" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="AccountingList" component={AccountingListScreen} />
      <Stack.Screen name="AccountingCreateNoteScreen" component={NoteListScreen} />
      <Stack.Screen name="AccountingUpdateNoteScreen" component={NoteDetailScreen} />
    </Stack.Navigator>
  )
}
