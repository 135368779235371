/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as React from 'react'
import { Modal, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { CanView, useMobile, assets, ErrorHandling, TaskDetail, AppContext } from '@pulseops/common'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { DaPolicyInformationServices } from '../da-policy-information'
import { useLoading } from '@mxt/zio-react'
interface Props {
  visible: boolean
  onPressClose: () => void
  onPressSubmit: () => void
  detail: TaskDetail.AgentVerificationNew
}
const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 700,
    fontSize: 15,
    width: 'calc(100%/3)',
    paddingRight: 30,
    paddingLeft: 50,
    color: '#70777E'
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontSize: 15,
    // width: 'calc(100%/5)',
    color: '#70777E'
  },
  tableHeaderCellbody: {
    paddingRight: 30,
    paddingLeft: 50
  },
  tableHeaderRow: {
    backgroundColor: 'white'
  },
  tableDelete: {
    fontSize: 15,
    color: '#70777E'
  },
  tableActive: {
    fontSize: 15,
    color: '#74c075'
  },
  tableWaiting: {
    fontSize: 15,
    color: '#FF6F00'
  },
  tableError: {
    fontSize: 15,
    color: 'red'
  }
})

export const DaInforPlicyModal = ({ visible, onPressClose, onPressSubmit, detail }: Props) => {
  const { isMobile, isWide } = useMobile()
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation('RequestInfo')
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [loading, bindLoading] = useLoading(false)
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const { listData } = pipe(
    DaPolicyInformationServices.getDetailTransfer(
      detail.transferPolicy.policyNum,
      String(detail.transferAgent?.agentCode)
    ),
    ZIO.map((data) => {
      return { listData: data?.transferHistory }
    }),
    bindLoading,
    ErrorHandling.runDidMount({ listData: [] })
  )
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])
  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0.25)',
          alignItems: 'center',
          justifyContent: isWide ? 'center' : 'center'
        }}
      >
        <CanView condition={isMobile}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onPressClose}
            style={{ position: 'absolute', height: height, width: width }}
          />
        </CanView>

        <View
          style={[
            styles.container,
            {
              width: isWide ? '50%' : '60%',
              borderBottomStartRadius: 8,
              borderBottomEndRadius: 8,
              borderRadius: 8
            }
          ]}
        >
          {/* Header */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
              <Text style={styles.headerTitle}>{t('TransferHistory')}</Text>
            </View>
            <TouchableOpacity onPress={onPressClose} style={styles.btnClose}>
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>

          {/* content */}
          {listData?.length === 0 ? (
            <View style={{ alignItems: 'center', justifyContent: 'center', minHeight: 164, marginTop: 50 }}>
              <Text
                style={{
                  color: '#70777E',
                  fontSize: 16,
                  fontWeight: '500'
                }}
              >
                {t('Norecord')}
              </Text>
            </View>
          ) : (
            <View style={{ marginTop: 20, marginBottom: 20 }}>
              <View
                style={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: '#D3DCE6',
                  marginHorizontal: 20,
                  marginVertical: 10
                }}
              >
                <TableContainer
                  component={Paper}
                  style={{
                    borderRadius: 8,
                    borderWidth: 1,
                    maxHeight: 350
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow style={{ backgroundColor: '#FAFAFA' }}>
                        <TableCell className={classes.tableHeaderCell}>{t('TranDate')}</TableCell>
                        <TableCell className={classes.tableHeaderCell}>{t('FromAG')}</TableCell>
                        <TableCell className={classes.tableHeaderCell}>{t('ToAgent')}</TableCell>
                        <TableCell className={classes.tableHeaderCell}>{t('ReasonDescription')}</TableCell>
                        <TableCell className={classes.tableHeaderCell}>{t('Orphan')}</TableCell>
                        <TableCell className={classes.tableHeaderCell}>{t('UserID')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listData?.map((item, index) => {
                        return (
                          <TableRow className={classes.tableHeaderRow} key={`___index_table${index}`}>
                            <TableCell>{item.transactionDate}</TableCell>
                            <TableCell>{item.fromAgent}</TableCell>
                            <TableCell>{item.toAgent}</TableCell>
                            <TableCell>{item.reasonDesc}</TableCell>
                            <TableCell>{item.orphan}</TableCell>
                            <TableCell>{item.userId}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </View>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Number(listData?.length)}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 8
  },

  headerTitle: {
    color: '#4F4F4F',
    fontWeight: '600',
    fontSize: 20,
    marginEnd: 16
  },

  input: {
    borderWidth: 1,
    borderColor: '#D3DCE6',
    borderRadius: 8,
    padding: 16,
    fontSize: 15,
    lineHeight: 22,
    color: '#000000'
  },

  btnGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 4
  },

  btnClose: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
