import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
export interface VeriPolicyCertificateData {
  tag: TransactionType.POLICY_CERTIFICATE | TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE
  clientName: string
  office: string
  reason: string
}

export const PolicyCertificate = (
  detail: api.PolicyCertificate & api.TaskDetailBase,
  poName: string
): VeriPolicyCertificateData => {
  const payloadInfo = detail.payload.body
  const transactionType = detail.transactionType
  return {
    tag: transactionType,
    clientName: poName,
    office: payloadInfo.office ?? '',
    reason: payloadInfo.reason ?? ''
  }
}
