import { DocId, FormService } from '@pulseops/business/core'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import * as t from 'io-ts'
import { form2 } from '@pulseops/common'

export namespace VerificationForm {
  type DecisionBrand = {
    readonly MS000022: unique symbol
  }
  type Decision = t.Branded<t.TypeOf<typeof BaseDecisionC>, DecisionBrand>

  const BaseDecisionC = t.intersection([
    t.type({
      decision: form2.string.required,
      comment: form2.string.optional,
      status: form2.string.optional
    }),
    t.union([
      t.type({
        decision: form2.literal(FormService.decisionYes).required,
        requestDate: form2.date.required,
        completeDate: form2.date.optional
      }),
      t.type({
        decision: form2.literal(FormService.decisionNo).required,
        requestDate: form2.date.optional,
        completeDate: form2.date.optional
      })
    ])
  ])

  const DecisionC = pipe(
    BaseDecisionC,
    form2.refine(
      (v): v is Decision => {
        if (v.requestDate && v.completeDate) {
          return v.requestDate.getTime() < v.completeDate.getTime()
        }
        return true
      },
      () => i18next.t('business:MS000022'),
      'MS000022'
    )
  )

  type OptionalDecision = t.Branded<t.TypeOf<typeof BaseOptionalDecisionC>, DecisionBrand>

  const BaseOptionalDecisionC = t.intersection([
    t.type({
      comment: form2.string.optional,
      status: form2.string.optional
    }),
    t.union([
      t.type({
        decision: t.literal('')
      }),
      t.type({
        decision: form2.literal(FormService.decisionYes).required,
        requestDate: form2.date.required,
        completeDate: form2.date.optional
      }),
      t.type({
        decision: form2.literal(FormService.decisionNo).required,
        requestDate: form2.date.optional,
        completeDate: form2.date.optional
      })
    ])
  ])

  const OptionalDecisionC = pipe(
    BaseOptionalDecisionC,
    form2.refine(
      (v): v is OptionalDecision => {
        if (
          (v.decision === FormService.decisionYes || v.decision === FormService.decisionNo) &&
          v.requestDate &&
          v.completeDate
        ) {
          return v.requestDate.getTime() < v.completeDate.getTime()
        }
        return true
      },
      () => i18next.t('business:MS000022'),
      'MS000022'
    )
  )
  export const codec = t.type({
    decisions: t.array(DecisionC),
    optionalDecisions: t.array(OptionalDecisionC)
  })

  export const mandatoryDocuments = [
    DocId.Quotation,
    DocId.Proposal,
    DocId.EmployeeList,
    DocId.BusinessLicense,
    DocId.LegalRepId,
    DocId.BeneficialOwnerId,
    DocId.ChiefAccountantContract
  ]

  export const optionalDocuments = [DocId.DirectorContract, DocId.AlternatePolicyOwner, DocId.FATCA, DocId.AML]
}
