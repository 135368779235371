import React, { useState } from 'react'
import { TouchableOpacity, View, Text, ScrollView, FlatList } from 'react-native'
import {
  Select,
  SelectOption,
  ErrorHandling,
  Panel,
  ImgUploadMutiple,
  assets,
  form2,
  AppContext,
  UploadDocumentsService,
  FileUploadData,
  RoundedButton,
  SubmissionService,
  UploadDocumentData,
  StorageBlob,
  SelectSearch,
  useMobile,
  TransactionType,
  Permission,
  RBAC,
  CashLessService,
} from '@pulseops/common'
import styled from 'styled-components/native'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { UploadForm } from './imaging-upload-form'
import { Controller, useFieldArray } from 'react-hook-form'
// import { useLoading } from '@mxt/zio-react'
import { useIsFocused, useNavigation } from '@react-navigation/core'
import { useTranslation } from 'react-i18next'
import { UploadDocumentConst } from './imaging-upload-const'
import * as A from 'fp-ts/lib/Array'
import { Input } from './input'
import { FieldText } from '@pulseops/submission/common'

const getClassByDocumentRule = (type: string, countNB_UW: number, totalDocNBR_UWR: number) => {
  switch (type) {
    case 'APP':
      return 'POLICYINFOTMP'
    case 'BAK':
      return 'AGENTINFO'
    case 'BOM':
      return 'POLICYINFO'
    case 'CLM':
      return 'EXTCLAIMINFO'
    case 'CCD':
      return 'POLICYINFO'
    case 'INF':
      return 'AGENTINFO'
    case 'OBO':
      return 'AGENTINFO'
    case 'PHO':
      return 'AGENTINFO'
    case 'PSR':
      return 'POLICYINFO'
    case 'TER':
      return 'AGENTINFO'
    case 'CLD': 
      return 'CLAIMINFO'
    case 'UWD':
      return 'ThirdPartyInfo'
    case 'NBR':
    case 'UWR':
      return totalDocNBR_UWR <= 1 ? 'POLICYINFOTMP' : countNB_UW <= 1 ? 'POLICYINFOTMP' : 'POLICYINFO'
    default:
      return ''
  }
}

const generateUUID = () => {
  var uuidValue = '',
    k,
    randomValue
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += '-'
    }
    uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
  }
  return uuidValue
}

export const ImagingUploadScreen = () => {
  const scrollViewRef = React.useRef<ScrollView>(null)
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()
  const { changeBreadcrumb, showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  // const [loading, bindLoader] = useLoading()
  const [triggerClear, setTriggerClear] = useState<boolean>(false)
  const { isMobile } = useMobile()
  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue,
      reset,
      trigger
    },
    handleSubmit
  } = form2.useForm(UploadForm.codec)

  const roles: string[] | null = pipe(RBAC.permissions, ErrorHandling.runDidMount())

  React.useEffect(() => {
    append({
      department: undefined,
      documentType: undefined,
      files: []
    })
  }, [])

  const headerForm = watch('upload.header')

  const { fields, append, remove, update } = useFieldArray<UploadForm.Raw>({
    control,
    name: 'upload.content'
  })

  const uploadContent = watch('upload.content')
  const policyNumber = watch('upload.header.policyNumber')
  const proposal = watch('upload.header.proposalNumber')

  React.useEffect(() => {
    if (!/\b\d{8}\b/.test(policyNumber)) {
      setValue('upload.header.policyOwner', '')
      setValue('upload.header.poClientId', '')
      setValue('upload.header.poIdNumber', '')
    }
  }, [policyNumber])

  const getPoNameByPolicyNumber = (policy: string) => {
    showGlobalLoading(true)
    return pipe(
      SubmissionService.getOwnerInfo(policy),
      ZIO.map((ownerInfo) => {
        if (!!ownerInfo && !!ownerInfo.body) {
          const poCMND = ownerInfo.body.externalIds.SOE_VALUE || ''
          setValue('upload.header.policyOwner', ownerInfo.body.name)
          setValue('upload.header.poClientId', ownerInfo.body.clientId)
          setValue('upload.header.poIdNumber', poCMND)
        } else {
          showToast(t('message:MS030029'), 'error')
        }
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      // bindLoader,
      ErrorHandling.run()
    )
  }

  const getPoNameByPolicyNumberDigital = (policy: string) => {
    showGlobalLoading(true)
    return pipe(
      SubmissionService.getPolicyInquiry(policy),
      ZIO.map((ownerInfo) => {
        if (!!ownerInfo && ownerInfo.customerRoles) {
          const dataPO = ownerInfo.customerRoles.find((po) => po.role === 'Owner')
          if(dataPO) {
            setValue('upload.header.policyOwner', dataPO.customer.surName?.trim() + " " + dataPO.customer.firstName?.trim())
            setValue('upload.header.poClientId', dataPO.customer.id)
            setValue('upload.header.poIdNumber', dataPO.customer.externalIds.NATIONAL_ID)
          }
          else {
            showToast(t('message:MS030029'), 'error')
          }
        } else {
          showToast(t('message:MS030029'), 'error')
        }
        showGlobalLoading(false)
        return ZIO.unit
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        return ZIO.unit
      }),
      // bindLoader,
      ErrorHandling.run()
    )
  }

  const validateProposalNumber = (proposal: string) => {
    if(watch('upload.header.type')?.value === 'TP02') {
      showGlobalLoading(true)
      return pipe(
        CashLessService.getProposalInfo(proposal),
        ZIO.map((info) => {
          if (!!info) {
            showGlobalLoading(false)
            return true
          } else {
            showGlobalLoading(false)
            showToast(t('message:MS070033'), 'error')
            return false
          }
        }),
        // bindLoader,
        ErrorHandling.run()
      )
    }
    else {
      return true
    }
  }

  React.useEffect(() => {
    if (isFocused) {
      clearForm()
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Utilities'),
          navigate: () => {
            navigate('UtilitiesStack', { screen: 'Utilities' })
          }
        },
        { title: t('utilities:ImagingUpload'), navigate: null }
      ])
    }
  }, [isFocused])

  const departmentNames = pipe(
    UploadDocumentsService.getDepartmentImaging('IMAGING_UPLOAD'),
    ZIO.map((departmentData) => {
      return departmentData.map((department) => {
        return {
          value: department.departmentShortName,
          label: department.department
        }
      })
    }),
    ErrorHandling.runDidUpdate([])
  )

  const types = React.useMemo<SelectOption[]>(
    () =>
      UploadDocumentConst.TypeList.map((type) => {
        return {
          value: type.code,
          label: i18n.language === 'vi' ? type.nameVi : type.name
        }
      }),
    [i18n.language]
  )

  const onSubmit = handleSubmit(async (validated) => {
    const validateProposal = await validateProposalNumber(proposal)
    if(!validateProposal) return
    const getDepartmentTypeList = () => {
      const departmentTypeList = validated.upload.content.map((department) => {
        return {
          department: department.department.value,
          documentType: department.documentType.value,
          officeCode: 'IMG',
          attachmentFiles: department.files
        }
      })
      return departmentTypeList
    }

    const getPolicyNoByType = () => {
      const type = validated.upload.header.type.value
      switch (type) {
        case 'TP01':
          return _.get(validated.upload.header, 'policyNumber') ?? ''
        case 'TP02':
          return '99999999'
        default:
          return ''
      }
    }

    const getProposalNoByType = () => {
      const type = validated.upload.header.type.value
      switch (type) {
        case 'TP02':
          return _.get(validated.upload.header, 'proposalNumber') ?? ''
        default:
          return ''
      }
    }

    const getPolicyNoForMetadata = () => {
      const type = validated.upload.header.type.value
      switch (type) {
        case 'TP01':
          return _.get(validated.upload.header, 'policyNumber') ?? ''
        case 'TP02':
          return _.get(validated.upload.header, 'proposalNumber') ?? ''
        default:
          return ''
      }
    }

    const getClientNumByType = () => {
      const type = validated.upload.header.type.value
      switch (type) {
        case 'TP01':
          return _.get(validated.upload.header, 'poClientId') ?? ''
        case 'TP02':
          return _.get(validated.upload.header, 'proposalNumber') ?? ''
        default:
          return ''
      }
    }

    const getAttachmentFileWithMetadata = () => {
      const departmentTypeList = getDepartmentTypeList()
      return pipe(
        ZIO.zipPar(
          pipe(departmentTypeList, ZIO.succeed),
          UploadDocumentsService.getMetaDataInfoByDepartment(departmentTypeList, '')
        ),
        ZIO.map(([departmentList, metaDataList]) => {
          let attachmentFiles: StorageBlob.FileContent[] = []
          const NBR = 'NBR',
            UWR = 'UWR'
          let countNB_UW = 0
          const totalDocNBR_UWR = metaDataList.reduce(
            (count, item) => (item.departType === 'NBR' || item.departType === 'UWR' ? count + 1 : count),
            0
          )

          const categoryList = departmentList.map((departItem) => {
            countNB_UW =
              NBR.startsWith(departItem.department) || UWR.startsWith(departItem.department)
                ? countNB_UW + 1
                : countNB_UW
            const metaItem = metaDataList.find(
              (x) => x.department === departItem.department && x.documentType === departItem.documentType
            )
            const metaType = metaItem?.departType ?? ''
            const classInfo = getClassByDocumentRule(metaType, countNB_UW, totalDocNBR_UWR)
            const metaDataRaw: StorageBlob.MetaDataUpload = {
              type: metaItem?.type ?? '',
              doctype: metaItem?.doctype ?? '',
              class: classInfo,
              docid: metaItem?.docid ?? '',
              maindoc: metaItem?.maindoc ?? '',
              subdoc: metaItem?.subdoc ?? '',
              polnum: getPolicyNoByType(),
              batchno: metaItem?.batchno ? (metaItem?.batchno.split('-')[0] + '-HCM' + '-' + validated.upload.header.batchName) : '',
              propnum: getProposalNoByType(),
              trigger: metaItem?.trigger ?? '',
              doctypebpm: metaItem?.doctypeBPM ?? '',
              functionType: 'uploadDocument'
            }
            attachmentFiles = departItem.attachmentFiles.map((attachmentItem) => {
              return {
                file: attachmentItem.file,
                metaData: metaDataRaw
              }
            })

            return {
              attachmentFiles,
              category: departItem.department,
              documentType: departItem.documentType,
              metaType: metaType
            }
          })
          return categoryList
        })
      )
    }

    const mappingSubmissionData = (blobFiles: UploadDocumentData.ResponseFileInfo[]) => {
      const requestDetails = validated.upload.content.map((departmentContrl, index) => {
        let UUIDList: string[] = []
        const blobItem = blobFiles[index]
        UUIDList = blobItem.blobFiles.map((x) => _.get(x, 'id') ?? '')
        return {
          policyNo: _.get(validated.upload.header, 'policyNumber'),
          poClientId: _.get(validated.upload.header, 'poClientId'),
          poIdNumber: _.get(validated.upload.header, 'poIdNumber'),
          proposalNo: _.get(validated.upload.header, 'proposalNumber'),
          poName: _.get(validated.upload.header, 'policyOwner'),
          agentCode: _.get(validated.upload.header, 'agentCode'),
          agentName: _.get(validated.upload.header, 'agentName'),
          department: departmentContrl.department.value,
          documentType: departmentContrl.documentType.value,
          requestDocuments: UUIDList,
          type2: blobItem.metaType
        }
      })
      const submitData = {
        batchName: validated.upload.header.batchName,
        type: validated.upload.header.type.value,
        requestDetails: requestDetails
      }
      return submitData
    }

    showGlobalLoading(true)
    return pipe(
      getAttachmentFileWithMetadata(),
      ZIO.flatMap((categoryList) => {
        // console.log('categoryList', categoryList)
        return pipe(
          categoryList,
          A.map((attachFiles) =>
            pipe(
              StorageBlob.uploadToSubmit(attachFiles.category, getPolicyNoForMetadata())(attachFiles.attachmentFiles),
              ZIO.map((azureBlobFiles) => ({
                blobFiles: azureBlobFiles,
                category: attachFiles.category,
                documentType: attachFiles.documentType,
                metaType: attachFiles.metaType
              }))
            )
          ),
          ZIO.sequence
        )
      }),
      ZIO.tapError((_) => {
        showGlobalLoading(false)
        showToast(t('message:MS050001'), 'error')
        return ZIO.unit
      }),
      ZIO.flatMap((responseFiles) => {
        let submitFiles: StorageBlob.FileContentSubmit[] = []

        const uploadOfficeCode = 'IMG'
        const policyNo = getPolicyNoForMetadata()
        const clientNum = getClientNumByType()

        responseFiles.forEach((responseItem) => {
          responseItem.blobFiles.forEach((item) => {
            _.set(item, 'id', generateUUID())
          })
          submitFiles = [...submitFiles, ...responseItem.blobFiles]
        })
        const submitData = mappingSubmissionData(responseFiles)
        return pipe(
          UploadDocumentsService.submitDataImagingUpload(submitData, policyNo, clientNum, submitFiles, uploadOfficeCode),
          ZIO.map((response) => {
            showGlobalLoading(false)
            showToast(t('message:MS040012'), 'success')
            // navigate('UtilitiesStack', { screen: 'Utilities' })
            clearForm()
            clearDataAfterSubmited()
            return response
          }),
          ZIO.tapError((_) => {
            showGlobalLoading(false)
            showToast(t('message:MS050001'), 'error')
            return ZIO.unit
          })
        )
      }),
      //bindLoader,
      ErrorHandling.run()
    )
  })

  const clearForm = () => {
    setTriggerClear(true)
    reset({
      upload: {
        content: [{ documentType: { label: '', value: '' }, department: { label: '', value: '' }, files: [] }],
        header: {
          type: types[0] ?? { label: '', value: '' }
        }
      }
    })
  }

  const clearDataAfterSubmited = () => {
    let fieldList = fields.length
    setValue('upload.header.type', types[0] ?? { label: '', value: '' })
    setValue('upload.header.policyNumber', '')
    setValue('upload.header.proposalNumber', '')
    setValue('upload.header.poClientId', '')
    setValue('upload.header.policyOwner', '')
    setValue('upload.header.batchName', '')
    // reset field array
    while (fieldList > 1) {
      remove(fieldList - 1)
      fieldList--
    }
    update(0, {
      department: undefined,
      documentType: undefined,
      files: []
    })
  }

  if(!!roles && roles.includes(Permission.ViewImagingUploadUtilities)) {
    return (
      <ScrollView ref={scrollViewRef} style={{ backgroundColor: 'white' }}>
        <SC.Container>
          <SC.RowContainer>
            <SC.Line />
            <SC.Tab>
              <SC.TabText>{t('utilities:Request')}</SC.TabText>
            </SC.Tab>
          </SC.RowContainer>
  
          <SC.TextSession>{t('Tab:GeneralInfo').toUpperCase()}</SC.TextSession>
          <SC.Header>
            <SC.RowContainer>
              <SC.InputContainer isMobile={isMobile}>
                <FieldText text={t('common:Office')}></FieldText>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22
                  }}
                >
                  {'HCM Imaging'}
                </Text>
              </SC.InputContainer>
              <SC.Divider />
              <SC.InputContainer isMobile={isMobile}>
                <Controller
                  control={control}
                  name="upload.header.batchName"
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <Input
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        title={t('utilities:BatchName')}
                        required={true}
                        maxLength={50}
                        errorMessage={errors.upload?.header?.batchName?.message}
                      />
                    )
                  }}
                />
              </SC.InputContainer>
            </SC.RowContainer>
            <SC.Divider />
            <SC.RowContainer>
              <SC.SelectContainer isMobile={isMobile}>
                <Controller
                  control={control}
                  name={'upload.header.type'}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <Select
                        onChange={(value) => {
                          setValue('upload.header.poClientId', '')
                          setValue('upload.header.poIdNumber', '')
                          setValue('upload.header.policyNumber', '')
                          setValue('upload.header.policyOwner', '')
                          setValue('upload.header.proposalNumber', '')
                          onChange(value)
                        }}
                        label={t('utilities:type')}
                        value={value}
                        required={true}
                        showUnderline={true}
                        placeholder={t('common:Select')}
                        numberOfLine={1}
                        options={types}
                        errorMessage={_.get(errors, 'upload.header.type')?.message}
                      />
                    )
                  }}
                />
              </SC.SelectContainer>
              <SC.Divider />
              {headerForm && headerForm.type?.value === 'TP01' && (
                <SC.InputContainer isMobile={isMobile} error={!!errors.upload?.header?.policyNumber?.message}>
                  <Controller
                    control={control}
                    name="upload.header.policyNumber"
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Input
                          value={value || ''}
                          inputType='number'
                          onChange={onChange}
                          onBlur={() => {
                            onBlur()
                            if (/\b\d{8,9}\b/.test(value)) {
                              setValue('upload.header.policyOwner', '')
                              setValue('upload.header.poClientId', '')
                              setValue('upload.header.poIdNumber', '')
                              if(value.length === 8) {
                                getPoNameByPolicyNumber(value)
                              }
                              if(value.length === 9) {
                                getPoNameByPolicyNumberDigital(value)
                              }
                            }
                          }}
                          required={true}
                          title={t('utilities:PolicyNumber')}
                          maxLength={9}
                          errorMessage={errors.upload?.header?.policyNumber?.message}
                          onSubmit={async () => {
                            await trigger('upload.header.policyNumber')
                            if (!!!errors.upload?.header?.policyNumber?.message) {
                              getPoNameByPolicyNumber(policyNumber)
                            }
                          }}
                        />
                      )
                    }}
                  />
                </SC.InputContainer>
              )}
              {headerForm && headerForm.type?.value === 'TP02' && (
                <SC.InputContainer isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="upload.header.proposalNumber"
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Input
                          value={value || ''}
                          inputType='number'
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('utilities:ProposalNumber')}
                          required={true}
                          maxLength={9}
                          errorMessage={errors.upload?.header?.proposalNumber?.message || t('message:MS050293')}
                        />
                      )
                    }}
                  />
                </SC.InputContainer>
              )}
              <SC.Divider />
              {headerForm && headerForm.type?.value === 'TP01' && (
                <SC.InputContainer isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="upload.header.policyOwner"
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Input
                          value={value || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          required={true}
                          title={t('utilities:PolicyOwner')}
                          disabled={true}
                        />
                      )
                    }}
                  />
                </SC.InputContainer>
              )}
              {headerForm && headerForm.type?.value === 'TP02' && (
                <SC.InputContainer isMobile={isMobile}>
                  <Controller
                    control={control}
                    name="upload.header.policyOwner"
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Input
                          value={value || ''}
                          onChange={onChange}
                          onBlur={onBlur}
                          title={t('utilities:PolicyOwner')}
                          required={true}
                          maxLength={300}
                          errorMessage={errors.upload?.header?.policyOwner?.message}
                        />
                      )
                    }}
                  />
                </SC.InputContainer>
              )}
            </SC.RowContainer>
          </SC.Header>
  
          <SC.TextSession>{t('requestInfo:RequestDetail').toUpperCase()}</SC.TextSession>
          {
            <FlatList
              data={fields}
              keyExtractor={(item, index) => `listfile__${index}`}
              style={{ width: '100%' }}
              renderItem={({ item, index }) => {
                return (
                  <Controller
                    control={control}
                    name={`upload.content.${index}`}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <View style={{ width: '100%' }}>
                          <RequestDetail
                            onDelete={() => remove(index)}
                            departmentOptions={departmentNames && departmentNames.length > 0 ? departmentNames : []}
                            onChangeDepartment={(select) => {
                              onChange({ ...value, department: select, documentType: null })
                            }}
                            onChangeDocType={(select) => {
                              onChange({ ...value, documentType: select })
                            }}
                            onFilesChange={(files) => {
                              onChange({ ...value, files })
                            }}
                            department={value?.department || undefined}
                            docType={value?.documentType || undefined}
                            files={
                              value.files &&
                              value.files
                                .filter((fileMeta) => fileMeta.file !== null)
                                .map((file) => ({
                                  fileName: file.fileName,
                                  fileExtension: file.fileExtension,
                                  size: file.size,
                                  base64: file.base64,
                                  uploadedDate: file.uploadedDate!,
                                  file: file.file!
                                }))
                            }
                            errorDepartment={_.get(errors, `upload.content.${index}.department`)?.message}
                            errorDepartmentType={_.get(errors, `upload.content.${index}.documentType`)?.message}
                            errorFiles={_.get(errors, `upload.content.${index}.files`)?.message}
                            clearFile={triggerClear}
                            setTriggerClear={setTriggerClear}
                          />
                        </View>
                      )
                    }}
                  />
                )
              }}
            />
          }
          {uploadContent && uploadContent.length < 10 &&
            <IconButton
              icon={<assets.PlusCircleIcon />}
              onPress={() => {
                if (uploadContent.length < 10) {
                  append({
                    documentType: undefined,
                    department: undefined,
                    files: []
                  })
                }
              }}
              title={t('common:Add')}
            />
          }
          <SC.FooterContainer>
            <RoundedButton
              text={t('utilities:ClearAll')}
              activeOpacity={0.7}
              showBorder={true}
              textColorEnable="#ED1B2E"
              borderColorEnable="#ED1B2E"
              onPress={clearForm}
              style={{
                minWidth: 100,
                height: 39,
                paddingHorizontal: 13
              }}
              textStyle={{ fontSize: 15 }}
            />
            <SC.Divider />
  
            <RoundedButton
              activeOpacity={0.7}
              text={t('utilities:Submit')}
              filled
              loadingColor={'#FFFFFF'}
              textColorDisable={'#FFFFFF'}
              textColorEnable={'#FFFFFF'}
              bgColorEnable={'#ED1B2E'}
              onPress={() => {
                setTriggerClear(true)
                onSubmit()
              }}
              style={{
                minWidth: 100,
                height: 39,
                paddingHorizontal: 13
              }}
              textStyle={{ fontSize: 15 }}
            />
          </SC.FooterContainer>
        </SC.Container>
      </ScrollView>
    )
  }
  return (
    <ScrollView>
    </ScrollView>
  )
}

const RequestDetail: React.FC<{
  departmentOptions: SelectOption[]
  onChangeDepartment?: (select: SelectOption) => void
  onChangeDocType?: (select: SelectOption) => void
  onFilesChange?: (files: FileUploadData[]) => void
  department?: SelectOption
  docType?: SelectOption
  files?: FileUploadData[]
  errorDepartment?: string
  errorDepartmentType?: string
  errorFiles?: string
  onDelete: () => void
  clearFile?: boolean
  setTriggerClear?: (val: boolean) => void
}> = ({
  departmentOptions,
  onChangeDepartment,
  onChangeDocType,
  onFilesChange,
  department,
  docType,
  files,
  errorDepartment,
  errorDepartmentType,
  errorFiles,
  onDelete,
  clearFile,
  setTriggerClear
}) => {
  const [docTypes, setDocTypes] = React.useState<SelectOption[]>([])
  // const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  // const [ loading, bindLoading ] = useLoading()
  const { t, i18n } = useTranslation()
  const { isMobile } = useMobile()
  const getDoctype = (shortName: string) =>
    pipe(
      UploadDocumentsService.getDocumentTypeImaging(TransactionType.IMAGING_UPLOAD, shortName),
      ZIO.map((docTypes) => {
        if (docTypes) {
          const docTypeOptions = docTypes.map((docType) => {
            return {
              label: docType.docID + ' - ' + (i18n.language === 'en' ? docType.docTypeEN ?? '' : docType.docTypeVN ?? ''),
              value: docType.docTypeCode ?? ''
            }
          })
          setDocTypes(docTypeOptions)
        }
        return ZIO.unit
      }),
      //bindLoading,
      ErrorHandling.run({})
    )

  const handleDepartmentChange = (select: SelectOption | null) => {
    if (select) {
      setDocTypes([])
      getDoctype(select.value)
      // onChangeDocType?.call(null, { label: '', value: ''})
      onChangeDepartment?.call(null, select)
    }
  }

  const handleDocTypeChange = (select: SelectOption | null) => {
    if (select) onChangeDocType?.call(null, select)
  }
  return (
    <SC.RequestContainer>
      <Panel containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <SC.PanelBody>
          <SC.RowContainer>
            <SC.SelectContainer width={isMobile ? '100%' : '40%'}>
              <SelectSearch
                label={t('utilities:department')}
                options={departmentOptions}
                value={department}
                onChange={handleDepartmentChange}
                required
                placeholder={t('common:Select')}
                // numberOfLine={1}
                errorMessage={errorDepartment}
                popupIcon={<assets.ArrowDownDropdownIcon />}
              />
            </SC.SelectContainer>
            <SC.Divider />
            <SC.SelectContainer width={isMobile ? '100%' : '40%'}>
              <SelectSearch
                label={t('utilities:DocIDDocType')}
                options={docTypes || []}
                onChange={handleDocTypeChange}
                value={docType || { label: '', value: '' }}
                required
                placeholder={t('common:Select')}
                //numberOfLine={1}
                errorMessage={errorDepartmentType}
                popupIcon={<assets.ArrowDownDropdownIcon />}
              />
            </SC.SelectContainer>
          </SC.RowContainer>
          <SC.Divider />
          <SC.TextHeader>
            {t('IFQ:FileAttachment')} <SC.TabText>*</SC.TabText>
          </SC.TextHeader>
          <ImgUploadMutiple
            value={files}
            onChange={(files) => onFilesChange?.call(null, files)}
            timeFormat={'DD/MM/YYYY HH:mm'}
            maxNumFile={100}
            haveViewFile
            clearFile={clearFile}
            setTriggerClear={setTriggerClear}
          />
          {errorFiles && <SC.ErrorText>{errorFiles}</SC.ErrorText>}
          <SC.Divider />
          <IconButton onPress={onDelete} title={t('claim:Delete')} icon={<assets.DeleteBin />} />
        </SC.PanelBody>
      </Panel>
    </SC.RequestContainer>
  )
}

const IconButton: React.FC<{ icon: JSX.Element; title: string; onPress: () => void }> = ({ title, onPress, icon }) => {
  return (
    <SC.ButtonContainer onPress={onPress}>
      {icon}
      <SC.Divider />
      <Text style={{ color: 'black', fontSize: 15, fontWeight: '600' }}>{title}</Text>
    </SC.ButtonContainer>
  )
}

const SC = {
  Container: styled(View)`
    align-items: flex-start;
    align-self: center;
    width: 70%;
    height: 100%;
  `,
  Header: styled(View)`
    width: 100%;
    margin-top: 8px;
    align-self: center;
    align-items: flex-start;
    padding: 20px;
    border-width: 1px;
    border-radius: 8px;
    background-color: #fafafa;
    border-color: #d3dce6;
  `,
  SelectContainer: styled(View)<{ isMobile?: boolean; width?: string }>`
    width: ${(p) => p.width || (p.isMobile ? '100%' : '32%')};
    /* min-width: max-content; */
    //max-width: 300px;
  `,
  InputContainer: styled(View)<{ error?: boolean; isMobile?: boolean; width?: string }>`
    width: ${(p) => p.width || (p.isMobile ? '100%' : '32%')};
    padding: 5px 0px 0px 0px;
    //max-width: 300px;
  `,
  RowContainer: styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content: space-between;
    width: 100%;
  `,
  Divider: styled(View)`
    width: 10px;
    height: 10px;
  `,
  RequestContainer: styled(View)`
    padding-top: 8px;
    width: 100%;
  `,
  PanelBody: styled(View)`
    padding: 0px 10px 0px 10px;
    align-items: flex-start;
    overflow: hidden;
  `,
  ButtonContainer: styled(TouchableOpacity)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  Padding: styled(View).attrs<{ paddingVertical: number; paddingHorizontal: number }>((p) => ({
    style: {
      padding: p.paddingVertical
    }
  }))<{ paddingVertical: number; paddingHorizontal: number }>``,

  Horizonlayout: styled(View)`
    flex-direction: row;
    align-self: center;
    justify-content: center;
  `,
  FooterContainer: styled(View)`
    padding: 20px 0px 20px 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
  `,
  TextHeader: styled(Text)`
    color: #70777e;
    font-weight: bold;
    font-size: 15;
  `,
  Line: styled(View)`
    position: absolute;
    bottom: 0px;
    height: 2px;
    border-width: 1;
    border-color: #d3dce6;
    width: 100%;
  `,
  Tab: styled(View)`
    min-width: 50px;
    justify-content: center;
    align-items: flex-start;
    border-bottom-color: red;
    border-bottom-width: 2px;
  `,
  TabText: styled(Text)`
    color: red;
    font-weight: bold;
    font-size: medium;
    padding: 5px 0px 5px 0px;
  `,
  TextSession: styled(Text)`
    color: black;
    font-weight: bold;
    font-size: 15px;
    padding-top: 16px;
  `,
  ErrorText: styled(Text)`
    color: red;
    font-size: 12px;
    padding: 5px 0px 5px 0px;
  `
}
