import { Maybe } from '@mxt/zio/codec'
import * as t from 'io-ts'
export namespace IHeatData {
  export const codec = t.type({
    callId: t.string,
    policyNo: t.string,
    clientNum: Maybe(t.string),
    policyOwner: Maybe(t.string),
    callCategory: Maybe(t.string),
    callType: Maybe(t.string),
    subType: Maybe(t.string),
    callSource: Maybe(t.string),
    callSourceRef: Maybe(t.string),
    csCenter: Maybe(t.string),
    receivedFrom: Maybe(t.string),
    location: Maybe(t.string),
    callDesc: Maybe(t.string),
    closeDesc: Maybe(t.string),
    subject: Maybe(t.string),
    recvdDate: Maybe(t.string),
    closedDate: Maybe(t.string),
    closedBy: Maybe(t.string),
    closureType: Maybe(t.string),
    complaintsAgainst: Maybe(t.string),
    personComplaint: Maybe(t.string),
    fullName: Maybe(t.string),
    receipt: Maybe(t.string),
    correctiveAction: Maybe(t.string),
    complaintAmount: Maybe(t.string),
    verifyAmount: Maybe(t.string),
    deductedAmountUM: Maybe(t.string),
    costSerRecovery: Maybe(t.string),
    recoveredAmount: Maybe(t.string),
    deductedAmount: Maybe(t.string),
    refNo: Maybe(t.string),
    response: Maybe(t.string),
    reimbursement: Maybe(t.string),
    corrective: Maybe(t.string),
    achangePYear: Maybe(t.string)
  })
}
export type IHeatData = t.TypeOf<typeof IHeatData.codec>
