import { Input, Permission, assets } from '@pulseops/common'
import { OBSharedStyles } from '@pulseops/outbound'
import { SectionCol, SectionRow } from '@pulseops/submission/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

type OBAvayaCallDetailPopupProps = {
  isVisible: boolean
  onPopupCloseEvent?: () => void
  connectionStatus?: string
  callDuration?: string
  avayaRecordLink?: string
  callId?: string
  roles?: string[]
  errorMessage?: string
}

export const OBAvayaCallDetailPopup = (props: OBAvayaCallDetailPopupProps) => {
  const { t } = useTranslation()
  const [isShowedAudioReplayer, setIsShowedAudioReplayer] = React.useState(false)
  const isRecordView = props.roles?.includes(Permission["RecordingOBTaskDetail"])
  React.useEffect(() => {
    setIsShowedAudioReplayer(false)
  }, [props.isVisible])

  const formatSecondsWithHHMMSS = (secondVal: number) => {
    return [parseInt(((secondVal / 60) / 60).toString()), parseInt(((secondVal / 60) % 60).toString()), parseInt((secondVal % 60).toFixed())].join(':').replace(/\b(\d)\b/g, '0$1')
  }

  return (
    <Modal visible={props.isVisible} transparent={true} animationType={'fade'}>
      <View style={callDetailStyles.globalContainer}>
        <View style={callDetailStyles.modalContainer}>
          <View style={callDetailStyles.modalHeader}>
            <View>
              <Text style={callDetailStyles.modalHeaderText}>{t('Outbound:OBActionHistory:CallingDetails')} {`[${props.callId}]`}</Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                props.onPopupCloseEvent && props.onPopupCloseEvent()
              }}
            >
              <assets.CloseTaskModalIcon />
            </TouchableOpacity>
          </View>
          <View style={callDetailStyles.modalBody}>
            <SectionRow >
              <SectionCol sectionStyles={OBSharedStyles.sectionCol6}>
                <Input
                  title={t('Outbound:OBActionHistory:ConnectionStatus')}
                  disabled={true}
                  labelStyle={{ fontSize: 13 }}
                  value={props.connectionStatus || ''}
                  alwayShowUnderline={true}
                />
              </SectionCol>
              <SectionCol sectionStyles={OBSharedStyles.sectionCol6}>
                <Input
                  title={t('Outbound:OBActionHistory:CallDuration')}
                  disabled={true}
                  labelStyle={{ fontSize: 13 }}
                  value={props.callDuration ? formatSecondsWithHHMMSS(Number(props.callDuration)) : ""}
                  alwayShowUnderline={true}
                />
              </SectionCol>
            </SectionRow>
            {!props.errorMessage ? (
              <View style={callDetailStyles.audioContainer}>
                <Text style={callDetailStyles.modalTextTitle}>{t('Outbound:OBActionHistory:ContentChecking')}</Text>
                {isRecordView && (
                  <View>
                    {isShowedAudioReplayer ? (
                      <audio controls controlsList="nodownload">
                        {/* <source src="https://VVNLFWPNICENPP1.PRU.intranet.asia:8088/player/playH264/PlayFile?fileId=9505092f-dc77-4898-8e69-a74fca976a4a" type="audio/mp4">
                        </source> */}
                        <source src={props.avayaRecordLink} type="audio/mp4">
                        </source>
                      </audio>
                    ) : (
                      <TouchableOpacity onPress={() => props.avayaRecordLink && setIsShowedAudioReplayer(true)}>
                        <assets.OBRelistenIcon />
                      </TouchableOpacity>
                    )}
                  </View>
                )}
              </View>
            ) : (
              <View style={callDetailStyles.audioContainer}>
                <Text style={callDetailStyles.errorMessageText}>{props.errorMessage}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  )
}
const callDetailStyles = StyleSheet.create({
  globalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    width: 700,
    minHeight: 300,
    paddingHorizontal: 24,
    paddingVertical: 24,
    backgroundColor: '#fff',
    // backgroundColor: 'rgb(231, 231, 231)',
    borderRadius: 8,
    boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.08)'
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalHeaderText: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#4F4F4F'
  },
  modalBody: {
    marginTop: 24
  },
  modalTextTitle: {
    color: '#70777E',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '700',
    marginBottom: 10
  },
  audioContainer: {
    marginTop: 20
  },
  errorMessageText: {
    color: '#ED1B2C', 
    fontSize: 13 
  }
})