import { Throwable, ZIO } from '@mxt/zio'
import {
  Benefit,
  BorderContainer,
  DetailService,
  EditFooterService,
  Form,
  FormService,
  Input,
  InputCurrency,
  ProductCode,
  Select
} from '@pulseops/business/core'
import { Checkbox, ErrorHandling, form2 } from '@pulseops/common'
import { pipe } from 'fp-ts/function'
import * as _ from 'lodash'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractDetailContext } from '../../contract-detail-context'
import { ProductInfoForm } from './product-info-form'
import products = FormService.products

export const ProductInfoEdit = ({ onClose }: { onClose: () => void }) => {
  const [registerOnSave, registerOnClose] = EditFooterService.useFooter()

  registerOnClose(onClose)

  const { t } = useTranslation('business')

  const { contractId } = React.useContext(ContractDetailContext)

  const service = DetailService.contract(contractId)

  const benefits: Benefit[] | null = pipe(
    service.state.benefit.get,
    ZIO.tap((benefits) => {
      benefits?.forEach((benefit, i) => {
        setValue(`benefits.${i}.clazz`, benefit.clazz)
        setValue(`benefits.${i}.ddProductCodeB`, benefit.ddProductCodeB)
        setValue(`benefits.${i}.geographicCoverageB`, benefit.geographicCoverageB)
        setValue(`benefits.${i}.sumAssuredB`, benefit.sumAssuredB.toString())
        setValue(`benefits.${i}.grad`, !!benefit.ddProductCodeR)
        setValue(`benefits.${i}.geographicCoverageR`, benefit.geographicCoverageR)
        setValue(`benefits.${i}.sumAssuredR`, benefit.sumAssuredR?.toString() || null)
        setValue(`benefits.${i}.grhc`, !!benefit.productCodeHCR)
        setValue(`benefits.${i}.healthcarePlan`, benefit.healthcarePlan)
      })
      return ZIO.unit
    }),
    ErrorHandling.runDidMount()
  )

  const {
    base: {
      control,
      formState: { errors },
      watch,
      setValue
    },
    handleSubmit
  } = form2.useForm(ProductInfoForm.codec)

  pipe(
    handleSubmit((validated) =>
      pipe(
        benefits,
        ZIO.fromPredicate(
          (a): a is Benefit[] => a != null,
          () => Throwable('benefit not found')
        ),
        ZIO.flatMap((benefits) =>
          pipe(
            benefits.map((benefit, index): Benefit => {
              const item = validated.benefits[index]
              const grad = item.grad
                ? {
                    ddProductCodeR: 'GRAD',
                    ddProductNameR: products.GRAD,
                    geographicCoverageR: item.geographicCoverageR,
                    sumAssuredR: item.sumAssuredR
                  }
                : {
                    ddProductCodeR: '',
                    ddProductNameR: '',
                    geographicCoverageR: '',
                    sumAssuredR: 0
                  }
              const grhc = item.grhc
                ? {
                    productCodeHCR: 'GRHC',
                    productNameHCR: products.GRHC,
                    healthcarePlan: item.healthcarePlan
                  }
                : {
                    productCodeHCR: '',
                    productNameHCR: '',
                    healthcarePlan: ''
                  }

              return {
                ...benefit,
                clazz: item.clazz,
                ddProductCodeB: item.ddProductCodeB,
                ddProductNameB: FormService.products[item.ddProductCodeB as ProductCode],
                geographicCoverageB: item.geographicCoverageB,
                sumAssuredB: item.sumAssuredB,
                ...grad,
                ...grhc
              }
            }),
            service.updateBenefit
          )
        ),
        ErrorHandling.run()
      )
    ),
    registerOnSave
  )

  if (!benefits?.length) {
    return null
  }

  return (
    <>
      {benefits.map((benefit, i) => {
        const errorMessage = (name: string) => _.get(errors as unknown, `benefits[${i}].${name}.message`)
        return (
          <BorderContainer key={i.toString()}>
            <Form.Group>
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name={`benefits.${i}.clazz`}
                    render={({ field }) => (
                      <Input
                        label={t('benefit_class')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errorMessage('clazz')}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col />
                <Form.Col />
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name={`benefits.${i}.ddProductCodeB`}
                    render={({ field }) => (
                      <Select
                        options={FormService.productOptions}
                        label={t('group_product')}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errorMessage('ddProductCodeB')}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col>
                  <Controller
                    control={control}
                    name={`benefits.${i}.geographicCoverageB`}
                    render={({ field }) => (
                      <Input
                        label={t('geo_coverage')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errorMessage('geographicCoverageB')}
                      />
                    )}
                  />
                </Form.Col>
                <Form.Col>
                  <Controller
                    control={control}
                    name={`benefits.${i}.sumAssuredB`}
                    render={({ field }) => (
                      <InputCurrency
                        label={t('sum_assured')}
                        value={field.value || ''}
                        onChangeText={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errorMessage('sumAssuredB')}
                      />
                    )}
                  />
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name={`benefits.${i}.grad`}
                    render={({ field }) => (
                      <Checkbox
                        title={t('grad')}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errorMessage('grad')}
                      />
                    )}
                  />
                </Form.Col>
              </Form.Row>
              {watch(`benefits.${i}.grad`) ? (
                <Form.Row>
                  <Form.Col>
                    <Controller
                      control={control}
                      name={`benefits.${i}.geographicCoverageR`}
                      render={({ field }) => (
                        <Input
                          label={t('geo_coverage')}
                          value={field.value || ''}
                          onChangeText={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={errorMessage('geographicCoverageR')}
                        />
                      )}
                    />
                  </Form.Col>
                  <Form.Col>
                    <Controller
                      control={control}
                      name={`benefits.${i}.sumAssuredR`}
                      render={({ field }) => (
                        <InputCurrency
                          label={t('sum_assured')}
                          value={field.value || ''}
                          onChangeText={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={errorMessage('sumAssuredR')}
                        />
                      )}
                    />
                  </Form.Col>
                  <Form.Col />
                </Form.Row>
              ) : null}
              <Form.Row>
                <Form.Col>
                  <Controller
                    control={control}
                    name={`benefits.${i}.grhc`}
                    render={({ field }) => (
                      <Checkbox
                        title={t('grhc')}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        errorMessage={errorMessage('grhc')}
                      />
                    )}
                  />
                </Form.Col>
              </Form.Row>
              {watch(`benefits.${i}.grhc`) ? (
                <Form.Row>
                  <Form.Col>
                    <Controller
                      control={control}
                      name={`benefits.${i}.healthcarePlan`}
                      render={({ field }) => (
                        <Select
                          options={FormService.hcPlanOptions}
                          label={t('health_care_plan')}
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          errorMessage={errorMessage('healthcarePlan')}
                        />
                      )}
                    />
                  </Form.Col>
                  <Form.Col />
                  <Form.Col />
                </Form.Row>
              ) : null}
            </Form.Group>
          </BorderContainer>
        )
      })}
    </>
  )
}
