import * as React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { Text } from './styled'
import { useTranslation } from 'react-i18next'

export const TableNoResult = ({ colSpan }: { colSpan: number }) => {
  const { t } = useTranslation('business')
  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        style={{
          textAlign: 'center'
        }}
      >
        <Text>{t('no_result')}</Text>
      </TableCell>
    </TableRow>
  )
}
