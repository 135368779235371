import { SourceType } from './../SourceType'
import { Customer } from '../Client'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { mapCountry, markupString } from './Util'
import { formatTextFromLasToVN } from '../../format'
import { Relationship } from '../mock/Relationship'
import { Address } from '../mock'

export interface PolicyInfo {
  policyNumber: string
  address: string
}

export interface DuplicateValue {
  clientId: string
  clientName: string
  relationship: string
  veriRelationship?: {
    code: string
    desEn: string
    desVi: string
  }
}

export interface ChangeContactInfo {
  tag: TransactionType.CHANGE_CONTACT_INFO | TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL
  source: SourceType
  clientName: string
  clientCode: string
  street: string
  country: string
  city: string
  district: string
  ward: string
  location: string
  countryCode: string
  mobilePhone: string
  email: string
  policyNo: string
  policies: PolicyInfo[]
  mobilePhoneCode?: string
  mobilePhoneNumber?: string
  officePhoneCode?: string
  officePhoneNumber?: string
  smsIndicator?: string
  emailAddress?: string
  emailIndicator?: string
  attributesExt?: {
    EMAILS_DUP: Array<DuplicateValue>
    PHONES_DUP: Array<DuplicateValue>,
    mainPhoneNumber: boolean
  },
  otpTransaction?: {
    otp: string,
    receiverPhone: string
  }
}

export interface generalAddressInfo {
  country?: string
  city?: string
  district?: string
  ward?: string
}

export const ChangeContactInfo = (
  detail: api.ChangeContactInfo,
  client: Customer,
  policies: PolicyInfo[],
  clientData: Array<{ clientId: string; clientName: string }>,
  generalAddress: generalAddressInfo
): ChangeContactInfo => {
  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
    case SourceType.PULSE:
      const data = detail.payload.body
      const address = data.dispatchAddress
      return {
        tag: TransactionType.CHANGE_CONTACT_INFO,
        source: detail.source,
        clientName: client.body.name,
        clientCode: client.body.clientId,
        street:
          markupString(address?.line1) || markupString(data.street) || client.body.addressDetails.PRIMARY?.line1 || '-',
        countryCode: markupString(data.countryCode) || '+84',
        country: mapCountry(client.body.nationality),
        city: markupString(address?.city) || data.city || client.body.addressDetails.PRIMARY?.line4 || '-',
        district:
          markupString(address?.district) ||
          markupString(data.district) ||
          client.body.addressDetails.PRIMARY?.line3 ||
          '-',
        ward: markupString(data.ward) || client.body.addressDetails.PRIMARY?.line2 || '-',
        location: policies.length ? policies[0].address : '',
        mobilePhone: data.mobilePhone
          ? markupString(`${data.countryCode ?? ''} ${data.mobilePhone}`)
          : client.body.mobilePhone,
        email: markupString(data.email) || '-',
        policyNo: data.policyNo || '-',
        policies: policies.map((p) => ({
          policyNumber: p.policyNumber,
          address: formatTextFromLasToVN(p.address)
        }))
      }
    case SourceType.PRUONLINE:
    case SourceType.ZALO:
    case SourceType.EKIOSK:
    case SourceType.BANCA_SEA:
    case SourceType.OUTBOUND:
    case SourceType.INBOUND:
    case SourceType.PULSE4OPS:
      const pulseOpsBody = detail.payload.body
      const street =
        markupString(pulseOpsBody.dispatchAddress?.line1) || client.body.addressDetails.PRIMARY?.line1 || '-'

      const contactDetails = detail.payload.body.contactDetails
      return {
        tag: detail.transactionType,
        source: detail.source,
        clientName: client.body.name,
        clientCode: client.body.clientId,

        street: markupString(pulseOpsBody.dispatchAddress?.line1) || '',
        countryCode: markupString(pulseOpsBody.dispatchAddress?.countryCode) || '+84',
        country:
          pulseOpsBody.dispatchAddress && pulseOpsBody.dispatchAddress.countryCode
            ? Address.NationalOption.find((e) => e.value === pulseOpsBody.dispatchAddress?.countryCode ?? false)
                ?.label ?? ''
            : '',

        city: (pulseOpsBody.dispatchAddress && pulseOpsBody.dispatchAddress.city) || '',
        district: (pulseOpsBody.dispatchAddress && pulseOpsBody.dispatchAddress.district) || '',
        ward: (pulseOpsBody.dispatchAddress && pulseOpsBody.dispatchAddress.subDistrict) || '',
        location: pulseOpsBody.dispatchAddress?.zipcode ?? '',
        mobilePhone: pulseOpsBody.contactDetails?.PHONE?.value
          ? markupString(pulseOpsBody.contactDetails?.PHONE?.value)
          : '-',
        email: markupString(pulseOpsBody.contactDetails?.EMAIL?.value) || '-',
        policyNo: '-',
        policies:
          pulseOpsBody.priorPolicies?.map((e) => ({
            address: `${street}, ${generalAddress.ward}, ${generalAddress.district}, ${generalAddress.city}`,
            policyNumber: e.policyNo ?? ''
          })) ?? [],
        mobilePhoneCode: contactDetails?.PHONE?.countryCode ?? '',
        mobilePhoneNumber: contactDetails?.PHONE?.value ?? '',
        officePhoneCode: contactDetails?.COMPANY_CODE ?? contactDetails?.OFFICE_PHONE?.countryCode,
        officePhoneNumber: contactDetails?.OFFICE_PHONE_NUMBER ?? contactDetails?.OFFICE_PHONE?.value,
        smsIndicator: contactDetails?.SMS_IND?.value ?? '',
        emailAddress: contactDetails?.EMAIL?.value ?? '',
        emailIndicator: contactDetails?.EMAIL_IND?.value ?? '',
        attributesExt: {
          PHONES_DUP:
            detail.payload.body.attributesExt?.PHONES_DUP?.filter((e) => !!e.clientId && !!e.relationshipType).map(
              (e) => ({
                clientId: e.clientId ?? '',
                clientName:
                  clientData && clientData.length > 0
                    ? clientData.find((client) => client.clientId === e.clientId)?.clientName ?? ''
                    : '',
                relationship: e.relationshipType
                  ? Relationship.IndicatorRelations.find((relation) => relation.code === e.relationshipType)?.desEn ??
                    ''
                  : '',
                veriRelationship: e.relationshipType
                  ? Relationship.IndicatorRelations.find((relation) => relation.code === e.relationshipType) ?? {
                      code: e.relationshipType,
                      desEn: '',
                      desVi: ''
                    }
                  : { code: '', desEn: '', desVi: '' }
              })
            ) ?? [],
          EMAILS_DUP:
            detail.payload.body.attributesExt?.EMAILS_DUP?.filter((e) => !!e.clientId && !!e.relationshipType).map(
              (e) => ({
                clientId: e.clientId ?? '',
                clientName:
                  clientData && clientData.length > 0
                    ? clientData.find((client) => client.clientId === e.clientId)?.clientName ?? ''
                    : '',
                relationship: e.relationshipType
                  ? Relationship.IndicatorRelations.find((relation) => relation.code === e.relationshipType)?.desEn ??
                    ''
                  : '',
                veriRelationship: e.relationshipType
                  ? Relationship.IndicatorRelations.find((relation) => relation.code === e.relationshipType) ?? {
                      code: e.relationshipType,
                      desEn: '',
                      desVi: ''
                    }
                  : { code: '', desEn: '', desVi: '' }
              })
            ) ?? [],
          mainPhoneNumber: detail.payload.body.attributesExt?.mainPhoneNumber as boolean
        },
        otpTransaction: {
          otp: detail.payload.otpTransaction?.otp ?? '',
          receiverPhone: detail.payload.otpTransaction?.receiverPhone ?? '',
        }
      }
  }
}
