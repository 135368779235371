import React from 'react'
import { StyleSheet, View } from 'react-native'
import { OBCategoryTab, CategoryTabInfo } from '../../ob-task-management'
import { useTranslation } from 'react-i18next'
import { CallOutDetailContext, OBInternalSuspendHistoryScreen, OBInternalSuspendInfoScreen, OBInternalSuspendService, OBSuspendCodeData } from '@pulseops/outbound'
import { AppContext, AuthService, ErrorHandling, InternalFeedbackService, Permission } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import moment from 'moment'
import { useLoading } from '@mxt/zio-react'
import { InternalUserInfo } from '@pulseops/task'

export const OBInternalSuspend = (props: { disabled: boolean, processInstanceId: string, businessKey: string }) => {
  const { t, i18n } = useTranslation()
  const [categoryTabIndex, setCategoryTabIndex] = React.useState<number>(0)
  const [tabIndex, setTabIndex] = React.useState<CategoryTabInfo | null>(null)
  const { permissions } = React.useContext(CallOutDetailContext)
  const processInstanceId = props.processInstanceId
  const businessKey = props.businessKey

  const [categoryTabList, setCategoryTabList] = React.useState<CategoryTabInfo[]>([])
  const [suspendTypeList, setsuspendTypeList] = React.useState<OBSuspendCodeData[]>([])
  const [workingTimeList, setWorkingTimeList] = React.useState<InternalFeedbackService.WorkingTimeInfo[]>([])
  const [isLoading, bindLoader] = useLoading(false)
  const {showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const currentDate = moment().format('YYYY/MM/DD')
  const [userInfo, setUserInfo] = React.useState<InternalUserInfo>({
    email: '',
    name: '',
    officeCode: '',
    isGaLogin: false
  })

  // const listDataEmailRules = 
  //   pipe(OBInternalSuspendService.getInternalRulesListData(),
  //   ZIO.map((res) => res.data ?? []),
  //   bindLoader, 
  //   ErrorHandling.runDidMount()) ?? []

  React.useEffect(() => {
    pipe(
      ZIO.zipPar(
        OBInternalSuspendService.getSuspendTypeList(processInstanceId),
        InternalFeedbackService.getWorkingTimeList(currentDate),
        AuthService.userInfo
      ),
      ZIO.map(([responseData, workingList, userInfoData]) => {
        !!responseData && responseData.length > 0 && setsuspendTypeList(responseData)
        !!workingList && workingList.length > 0 && setWorkingTimeList(workingList)
        userInfoData && setUserInfo(userInfoData)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }, [processInstanceId])
  
  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])
  
  const checkPermissionTabView = () => {
    const listTabPermission: CategoryTabInfo[] = []
    listTabPermission.push({
      label: t('Tab:InternalSuspendInformation'),
      key: '1'
    })
    listTabPermission.push({
      label: t('Tab:InternalSuspendHistory'),
      key: '2'
    })
    listTabPermission.length > 0 && setCategoryTabIndex(0)
    listTabPermission.length > 0 && setTabIndex(listTabPermission[0])
    setCategoryTabList([...listTabPermission])
  }

  const getViewByTabIndex = (tab: CategoryTabInfo) => {
    // console.log(tab, 'tab')
    switch (tab.key) {
      case '1':
        return (
          <OBInternalSuspendInfoScreen
            disabled={props.disabled || !permissions.includes(Permission['EditInternalSuspendOBTaskDetail'])}
            processInstanceId={processInstanceId}
            caseId={businessKey}
            userInfo={userInfo} 
            suspendTypeList={suspendTypeList} 
            workingTimeList={workingTimeList}
            listDataEmailRules={[]}
          ></OBInternalSuspendInfoScreen>)
      case '2':
        return ( 
          <OBInternalSuspendHistoryScreen
            disabled={props.disabled || !permissions.includes(Permission['EditInternalSuspendOBTaskDetail'])}
            processInstanceId={processInstanceId}
            userInfo={userInfo}
            businessKey={businessKey}
            listDataEmailRules={[]}
            suspendTypeList={suspendTypeList}
          ></OBInternalSuspendHistoryScreen>
        )
      default:
        return <></>
    }
  }

  React.useEffect(() => {
    checkPermissionTabView()
  }, [permissions, i18n.language])

  return (
    <View style={internalStyles.container}>
      <OBCategoryTab
        dataSource={categoryTabList}
        onChangeTab={(index) => {
          setCategoryTabIndex(index), setTabIndex(categoryTabList[index])
        }}
        activeTabIndex={categoryTabIndex}
      ></OBCategoryTab>
      {tabIndex && getViewByTabIndex(tabIndex)}
    </View>
  )
}
const internalStyles = StyleSheet.create({
  container: {
    display: 'flex',
    // minHeight: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: 24,
    paddingTop: 16
  }
})
