//import { BankTransfer } from "./bank-transfer-view";
import * as t from 'io-ts'
import { withMessage } from 'io-ts-types'
import { form2 } from '@pulseops/common'
import { withMaxStringLength, withMinStringLength } from '../validators'
import i18next from 'i18next'
import { pipe } from 'fp-ts/lib/function'

export namespace BankTransfer {
  const BankTransferForm = t.intersection([
    t.type({
      bank: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('landingPage:bank') })
      ),
      branch: withMessage(form2.selectOption.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('landingPage:branch') })
      ),
      accountNumber: pipe(
        withMessage(form2.string.required, () =>
          i18next.t('message:MS020001', { field: i18next.t('landingPage:AccountNumber') })
        ),
        withMaxStringLength(20)
      ),
      // amount: pipe(
      //   withMessage(form2.number.required, () =>
      //     i18next.t('message:MS020001', { field: i18next.t('requestInfo:Amount') })
      //   ),
      //   withNumberGreater(0)
      // )
      amount: pipe(
        withMessage(t.number, () => i18next.t('message:MS020001', { field: i18next.t('landingPage:Amount') }))
        //withNumberGreater(0)
      )
    }),
    t.union([
      t.type({
        payeeNotPO: t.literal(false),
        accountName: pipe(form2.string.required),
        idNumber: pipe(form2.string.required)
      }),
      t.type({
        payeeNotPO: t.literal(true),
        accountName: pipe(form2.string.required, withMaxStringLength(120)),
        idNumber: pipe(form2.string.required, withMinStringLength(12), withMaxStringLength(24))
      })
    ])
  ])
  export const codec = BankTransferForm

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
