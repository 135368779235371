import React from 'react'
import { TextInput, Text, View, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { ControlProps } from '@pulseops/common'

type InputProps = ControlProps<string> & {
  containerStyle?: StyleProp<ViewStyle>
  suffixIcon?: () => JSX.Element
  prefixIcon?: () => JSX.Element
  label?: string
  errorString?: string
  disabled?: boolean
  disabledSearch?: boolean
  required?: boolean
  errorMessage?: string
  numberOnly?: boolean
  onIconPressed?: () => void
  placeholder?: string
  maxLength?: number
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    containerStyle,
    suffixIcon,
    prefixIcon,
    label,
    errorMessage,
    placeholder,
    maxLength,
    onChange,
    onBlur,
    value,
    numberOnly,
    onIconPressed
  } = props
  return (
    <View style={[containerStyle]}>
      {label ?? <Text>{label}</Text>}
      <View
        style={[styles.container, { borderColor: errorMessage ? 'red' : undefined, borderWidth: errorMessage ? 1 : 0 }]}
      >
        {prefixIcon && <View style={styles.paddingView}>{prefixIcon()}</View>}
        <TextInput
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          onChangeText={(t) => onChange && onChange(numberOnly ? t.replace(/[^0-9]/g, '') : t)}
          onBlur={onBlur}
          onSubmitEditing={onIconPressed}
          style={{ flex: 1 }}
        />
        {suffixIcon && <View style={styles.paddingView}>{suffixIcon()}</View>}
      </View>
      {errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    shadowColor: 'grey',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 6,
    borderRadius: 8,
    height: 46,
    flexDirection: 'row',
    alignSelf: 'stretch'
  },
  paddingView: {
    width: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorText: {
    color: 'red'
  }
})
