import * as React from 'react'
import {
  ModalComponent,
  HeaderTable,
  Title,
  PayoutPopup,
  FieldList,
  assets,
  form2,
  Input,
  Divider,
  PayoutService,
  ModalComponentRef,
  InputTable,
  ModalAction,
  RowSelectedProps,
  Columns,
  DataSource,
  getLanguage
} from '@pulseops/common'
import {
  useWindowDimensions,
  View,
  ScrollView,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  StyleSheet
} from 'react-native'
import { OtherPayPremiumForm } from './other-pay-premium.form'
import { PolicyOfOrtherClientForm } from './policy-of-orther-client.form'
import { Controller, useFieldArray } from 'react-hook-form'
import { Label, Error } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import { getPolicyStatus } from './constants'
import { useTranslation } from 'react-i18next'

interface IconButtonProps {
  title: string
  style?: StyleProp<ViewStyle>
  icon: () => JSX.Element
  onPress: () => void
}

const IconButton = ({ title, icon, style, onPress }: IconButtonProps) => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        {
          height: 32,
          alignItems: 'center',
          flexDirection: 'row',
          alignSelf: 'baseline'
        },
        style
      ]}
      onPress={onPress}
    >
      {icon()}
      <Divider width={12} />
      <Label title={title} fontWeight="600" color="#000000" />
    </TouchableOpacity>
  )
}

interface Props {
  primaryPolicy: string
  details: PayoutPopup.OtherPayPremiumData[]
  visible: boolean
  onClose: () => void
  onConfirm: (payouts: PayoutPopup.PayoutData[]) => void
}

export const OtherPayPremiumPopup = ({ visible, details, primaryPolicy, onClose, onConfirm }: Props) => {
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()

  const modalRef = React.useRef<ModalComponentRef | null>(null)

  const [rowsSelected, setRowsSelected] = React.useState<Map<number, PayoutPopup.OtherPayPremiumData>>(new Map())
  const [loadingMap, setLoadingMap] = React.useState<Map<number, boolean>>(new Map())

  const otherPayPremiumForm = form2.useForm(OtherPayPremiumForm.codec, { defaultValues: { list: [] } })

  const otherPayPremiumFormList = useFieldArray<OtherPayPremiumForm.Raw>({
    name: 'list',
    control: otherPayPremiumForm.base.control
  })

  const POCForm = form2.useForm(PolicyOfOrtherClientForm.codec, { defaultValues: { list: [] } })

  const POCFormList = useFieldArray<PolicyOfOrtherClientForm.Raw>({
    control: POCForm.base.control,
    name: 'list'
  })

  const showSearchLoadingAtIndex = (index: number, isShow: boolean) => {
    const map = new Map(loadingMap)
    map.set(index, isShow)
    setLoadingMap(map)
  }

  const searchPolicy = (policyNum: string, formIndex: number) => {
    if (policyNum === primaryPolicy) {
      modalRef?.current?.showToast(t('message:MS050110'), 'error')
      POCFormList.update(formIndex, { policyNumber: policyNum })
      return
    }

    showSearchLoadingAtIndex(formIndex, true)
    return pipe(
      PayoutService.searchPolicyOther(policyNum),
      ZIO.catchAll((error) => {
        showSearchLoadingAtIndex(formIndex, false)
        modalRef?.current?.showToast(error.source.message, 'error')
        return ZIO.fail(null)
      }),
      ZIO.tap((response) => {
        showSearchLoadingAtIndex(formIndex, false)
        const isNotFound =
          response.contractStatus === '' && response.poName.trim() === '' && response.totalPremium === 0
        if (isNotFound) {
          modalRef?.current?.showToast(t('message:MS030029'), 'error')
          POCFormList.update(formIndex, { policyNumber: policyNum })
        } else {
          if (response && response.isOtherPayPremiumValid) {
            POCFormList.update(formIndex, {
              policyNumber: policyNum,
              poName: response.poName,
              contractStatus: response.contractStatus,
              totalPremium: `${response.totalPremium}`
            })
          } else {
            modalRef?.current?.showToast(t('message:MS050259'), 'error')
            POCFormList.update(formIndex, { policyNumber: policyNum })
          }
        }
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  }

  const resetForm = () => {
    rowsSelected.clear()
    otherPayPremiumFormList.remove()
    POCFormList.remove()
  }

  const onPressClose = () => {
    resetForm()
    onClose()
  }

  const onPressConfirm = async () => {
    const isOtherPayPremiumValid = await otherPayPremiumForm.base.trigger()
    const isPOCFormValid = await POCForm.base.trigger()
    let payoutData: PayoutPopup.PayoutData[] = []
    if (isOtherPayPremiumValid) {
      const repayLoanData: PayoutPopup.PayoutData[] = otherPayPremiumForm.base
        .getValues('list')
        .filter((e) => Number(e.amount) > 0)
        .map((e): PayoutPopup.PayoutData => {
          const row = rowsSelected.get(Number(e.formId))
          return {
            method: PayoutPopup.PayoutMethods.OTHER,
            amount: Number(e.amount),
            policyNum: row?.policyNum,
            poName: row?.poName,
            totalPremium: row?.totalPremium,
            payee: undefined,
            officeBankCode: undefined,
            officeType: undefined
          }
        })
      payoutData = repayLoanData
    }
    if (isPOCFormValid) {
      const otherPolicyArr: PayoutPopup.PayoutData[] = POCForm.base
        .getValues('list')
        .filter((e) => Number(e.amount) > 0)
        .map(
          (e): PayoutPopup.PayoutData => ({
            method: PayoutPopup.PayoutMethods.OTHER,
            amount: Number(e.amount),
            policyNum: e.policyNumber,
            poName: e.poName || '-',
            totalPremium: Number(e.totalPremium),
            payee: undefined,
            officeBankCode: undefined,
            officeType: undefined
          })
        )
      payoutData = [...payoutData, ...otherPolicyArr]
    }
    onConfirm(payoutData)
    resetForm()
  }

  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: onPressClose,
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnCancel
    },
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: onPressConfirm,
      disabled: (() => {
        const lA = otherPayPremiumFormList.fields.length
        const lB = POCFormList.fields.length

        const isAValid = otherPayPremiumForm.base.formState.isValid
        const isBValid = POCForm.base.formState.isValid

        const isDisable = (lA === 0 && lB === 0) || isAValid === false || isBValid === false
        return isDisable
      })(),
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]

  const renderAmountInputController = ({ index, isSelected }: RowSelectedProps) => {
    if (isSelected) {
      const formIndex = otherPayPremiumFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
      if (formIndex > -1) {
        return (
          <Controller
            control={otherPayPremiumForm.base.control}
            name={`list.${formIndex}.amount`}
            render={({ field }) => (
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                <View style={{ width: '100%', maxWidth: 200 }}>
                  <InputTable
                    {...field}
                    value={field.value || ''}
                    inputType={'money'}
                    textAlign="right"
                    inputStyle={{ maxWidth: 200 }}
                  />
                </View>
              </View>
            )}
          />
        )
      }
    }
    return <View />
  }

  const repayLoanTableColumns: Columns[] = [
    {
      key: '0',
      name: 'policyNum',
      title: t('submission:PolicyNumber'),
      styles: {
        textAlign: getLanguage() === 'en' ? 'center' : 'right'
      }
    },
    {
      key: '1',
      name: 'contractStatus',
      title: t('submission:ContractStatus'),
      flex: 1,
      styles: {
        textAlign: 'center'
      }
    },
    {
      key: '2',
      name: 'amount',
      require: true,
      title: t('submission:Amount'),
      render: renderAmountInputController,
      styles: {
        textAlign: getLanguage() === 'en' ? 'right' : 'center',
        flexWrap: 'nowrap'
      }
    }
  ]

  const onTableRowSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.set(index, details[index])
    setRowsSelected(rows)
    otherPayPremiumFormList.append({
      formId: `${index}`,
      amount: ''
    })
  }

  const onTableRowUnSelected = (index: number, _data: DataSource) => {
    const rows = new Map(rowsSelected)
    rows.delete(index)
    setRowsSelected(rows)
    const targetIndex = otherPayPremiumFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`)
    otherPayPremiumFormList.remove(targetIndex)
  }

  const onSelectedAllTableRow = () => {
    const rows = details.reduce((result, item, index) => {
      result.set(index, item)
      const isFormExists =
        otherPayPremiumFormList.fields.findIndex((item) => item.formId && item.formId === `${index}`) > -1
      if (!isFormExists) {
        otherPayPremiumFormList.append({
          formId: `${index}`,
          amount: ''
        })
      }
      return result
    }, new Map<number, PayoutPopup.OtherPayPremiumData>())
    setRowsSelected(rows)
  }

  const onUnSelectedAllTableRow = () => {
    setRowsSelected(new Map())
    otherPayPremiumFormList.remove()
  }

  const renderSearchIcon = (isEnable: boolean, isLoading: boolean) => {
    return isLoading ? (
      <ActivityIndicator color="red" size={24} />
    ) : isEnable ? (
      <assets.Search20Icon />
    ) : (
      <assets.SearchDisableIcon />
    )
  }

  const renderPOCPolicySearchInputController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.policyNumber`}
        render={({ field, fieldState: { error, invalid } }) => {
          const isValid = field.value && !invalid
          const isSearching = loadingMap.get(index) || false
          return (
            <View style={{ marginBottom: 22 }}>
              <View style={styles.searchPolicyContainer}>
                <Input
                  {...field}
                  title={t('submission:PolicyNumber')}
                  required={true}
                  maxLength={8}
                  value={field.value || ''}
                  disabled={isSearching}
                  alwayShowUnderline={true}
                  onKeyEnter={() => field.value && searchPolicy(field.value, index)}
                />
                <TouchableOpacity
                  disabled={!isValid || isSearching}
                  onPress={() => field.value && searchPolicy(field.value, index)}
                  style={styles.btnSearch}
                >
                  {renderSearchIcon(isValid, isSearching)}
                </TouchableOpacity>
              </View>
              {error?.message && <Error message={error.message} />}
            </View>
          )
        }}
      />
    )
  }

  const renderPOCAmountInputController = (index: number) => {
    return (
      <Controller
        control={POCForm.base.control}
        name={`list.${index}.amount`}
        render={({ field }) => (
          <Input {...field} value={field.value || ''} inputType={'money'} containerStyle={{ marginEnd: 60 }} />
        )}
      />
    )
  }

  const renderPOCFieldList = (index: number) => {
    const data = POCForm.base.getValues('list')[index]
    return (
      <FieldList
        dataSource={[
          {
            label: t('submission:POname'),
            value: data?.poName?.trim() || '-'
          },
          {
            label: t('submission:ContractStatus'),
            value: data?.contractStatus ? getPolicyStatus(data.contractStatus) : '-'
          },
          {
            label: t('submission:Amount'),
            required: true,
            value: '',
            render: ({}) => renderPOCAmountInputController(index)
          }
        ]}
      />
    )
  }

  const renderPOCForm = (item: Record<'id', string>, index: number) => {
    return (
      <View key={item.id} style={styles.ortherPolicyContainer}>
        {renderPOCPolicySearchInputController(index)}
        {renderPOCFieldList(index)}
        <IconButton
          title={t('common:Delete')}
          icon={() => <assets.DeleteBin />}
          onPress={() => POCFormList.remove(index)}
        />
      </View>
    )
  }

  return (
    <ModalComponent
      ref={modalRef}
      title={t('submission:OtherPremium')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      modalHeight={Math.min(height * 0.9, 768)}
      visible={visible}
      onClose={onPressClose}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        <Title title={t('submission:OtherPremium')} wrapperStyle={styles.bigLabel} />
        <HeaderTable
          selectable={true}
          rowsSelected={rowsSelected}
          unit={'VND'}
          headerContainerStyle={{ backgroundColor: '#FAFAFA' }}
          rowStyle={{ backgroundColor: '#FAFAFA' }}
          selectBoxContainerStyle={{ marginEnd: 100, justifyContent: 'center', alignItems: 'center' }}
          columns={repayLoanTableColumns}
          dataSource={details.map((item) => ({
            policyNum: item.policyNum,
            contractStatus: getPolicyStatus(item.contractStatus)
          }))}
          onRowSelected={onTableRowSelected}
          onRowUnSelected={onTableRowUnSelected}
          onAllRowSelected={onSelectedAllTableRow}
          onAllRowUnSelected={onUnSelectedAllTableRow}
        />
        <Title title={t('submission:POLICY_OF_OTHER_CLIENT')} wrapperStyle={styles.bigLabel} />
        {POCFormList.fields.map(renderPOCForm)}
        <IconButton
          style={styles.btnAdd}
          title={t('common:Add')}
          icon={() => <assets.PlusCircleIcon />}
          onPress={() => POCFormList.append({ policyNumber: '' }, { shouldFocus: true })}
        />
      </ScrollView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    textAlign: 'center'
  },

  modalContent: {
    backgroundColor: '#EEEEEE'
  },

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39
  },

  container: {
    paddingHorizontal: 30
  },

  bigLabel: {
    textTransform: 'uppercase',
    marginTop: 20,
    marginBottom: 10
  },

  ortherPolicyContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    overflow: 'hidden',
    marginVertical: 5,
    paddingHorizontal: 30,
    paddingVertical: 24
  },

  searchPolicyContainer: {
    width: 275,
    flexDirection: 'row',
    alignItems: 'flex-end'
  },

  btnSearch: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },

  btnAdd: {
    marginStart: 20,
    marginTop: 16
  }
})
