import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { Error } from './CepCommonModels'

export const PolicyValueC = t.type({
  data: t.type({
    contractNumber: Maybe(t.string),
    effectiveDate: Maybe(t.string),
    contractStatus: Maybe(t.string),
    productType: Maybe(t.string),
    coverageId: Maybe(t.string)
  })
})
export type PolicyValueC = t.TypeOf<typeof PolicyValueC>

const PrucashBenefits = t.type({
  pruCashNo: Maybe(t.string),
  cashReleaseDate: Maybe(t.string),
  principalAmount: Maybe(t.number),
  interestAmount: Maybe(t.number),
  totalPerPeriod: Maybe(t.number),
  receiveDate: Maybe(t.string),
  receiveAmount: Maybe(t.number)
})

export type PrucashBenefits = t.TypeOf<typeof PrucashBenefits>

export const PolicyValueInfoData = t.type({
  contractValue: t.type({
    contractSurrenderValue: Maybe(t.number),
    loanAmountAvailable: Maybe(t.number),
    automaticPremiumLoan: Maybe(t.number),
    originalPolicyLoan: Maybe(t.number),
    loanExceedSurrenderValue: Maybe(t.number),
    bonusValue: Maybe(t.number),
    specialBonus: Maybe(t.number),
    bonusSurrender: Maybe(t.number),
    suspenseAmount: Maybe(t.number),
    maturityBenefit: Maybe(t.number),
    totalCashBenefit: Maybe(t.number),
    maxWithDraw: Maybe(t.number),
    pruKidBenefit: Maybe(t.number),
    prevMaturityAdvance: Maybe(t.number),
    totalMaturityAdvance: Maybe(t.number)
  }),
  billingChangeInfo: t.type({
    currentBillingFrequency: Maybe(t.string),
    currentInstallmentPremium: Maybe(t.number),
    automaticPolicyLoanDate: Maybe(t.string),
    oldBillingFrequency: Maybe(t.string),
    oldInstallmentPremium: Maybe(t.number),
    automaticPolicyLoanInterest: Maybe(t.number),
    requirePayingAmount: Maybe(t.number),
    paymentLastDay: Maybe(t.string),
    aplDate: Maybe(t.string)
  }),
  billingChange: t.type({
    currentBillingFrequency: Maybe(t.string),
    currentInstallmentPremium: Maybe(t.number),
    suspenseAmount: Maybe(t.number),
    advancePremium: Maybe(t.number),
    effectiveDateOfNewFrequency: Maybe(t.string)
  }),
  bonusSurrender: t.type({
    bonusValue: Maybe(t.number),
    currentBalance: Maybe(t.number),
    interest: Maybe(t.number),
    bonusReserveRemainValue: Maybe(t.number)
  }),
  fullSurrender: t.type({
    cashDeposits: Maybe(t.number),
    suspenseAmount: Maybe(t.number),
    policyLoan: Maybe(t.number),
    policyDebt: Maybe(t.number),
    surrenderCharge: Maybe(t.number),
    total: Maybe(t.number),
    surrenderChargePercent: Maybe(t.number),
    netSurrenderValueAmount: Maybe(t.number)
  }),
  aplValue: t.array(
    t.type({
      currency: Maybe(t.string),
      accuredInterest: Maybe(t.number),
      currentBalance: Maybe(t.number),
      policyLoanTotal: Maybe(t.number),
      pendingInterest: Maybe(t.number),
      principalAmount: Maybe(t.number),
      loanNumber: Maybe(t.number),
      loanStartDate: Maybe(t.string),
      loanType: Maybe(t.string),
      dutyStamp: Maybe(t.number),
      receivedDate: Maybe(t.string),
      receivedAmount: Maybe(t.number),
      premiumDate: Maybe(t.string)
    })
  ),
  oplValue: t.array(
    t.type({
      currency: Maybe(t.string),
      accuredInterest: Maybe(t.number),
      currentBalance: Maybe(t.number),
      policyLoanTotal: Maybe(t.number),
      pendingInterest: Maybe(t.number),
      principalAmount: Maybe(t.number),
      loanNumber: Maybe(t.number),
      loanStartDate: Maybe(t.string),
      loanType: Maybe(t.string),
      dutyStamp: Maybe(t.number),
      receivedDate: Maybe(t.string),
      receivedAmount: Maybe(t.number)
    })
  ),
  productInfo: Maybe(
    t.type({
      productType: Maybe(t.string),
      productCode: Maybe(t.string),
      basicCode: Maybe(t.string),
      status: Maybe(t.string)
    })
  ),
  validProduct: Maybe(t.boolean),
  prucashBenefits: Maybe(t.array(PrucashBenefits))
})

export type PolicyValueInfoData = t.TypeOf<typeof PolicyValueInfoData>

export const PolicyValueResponseC = t.type({
  data: PolicyValueInfoData
})

export type PolicyValueResponseC = t.TypeOf<typeof PolicyValueResponseC>

export const BonusReverseInfoC = t.type({
  data: t.type({
    bonusReserveValue: t.number,
    bonusValue: t.number,
    bonusReserveRemainValue: t.number
  })
})

export type BonusReverseInfoC = t.TypeOf<typeof BonusReverseInfoC>

export const BonusReverseDataC = t.type({
  data: t.type({
    contractNumber: Maybe(t.string),
    effectiveDate: Maybe(t.string),
    contractStatus: Maybe(t.string),
    productType: Maybe(t.string),
    coverageId: Maybe(t.string),
    amountToSurrender: Maybe(t.number),
    bonusValue: Maybe(t.number),
    bonusReserveRemainValue: Maybe(t.number)
  })
})

export type BonusReverseDataC = t.TypeOf<typeof BonusReverseDataC>

export const dataBillingFreq = t.type({
  data: t.type({
    contractNumber: t.string,
    billingFrequency: t.string
  })
})

export type dataBillingFreq = t.TypeOf<typeof dataBillingFreq>

export const BillingFreqRes = t.type({
  data: Maybe(
    t.type({
      nextInstallmentPremium: t.number,
      minimumContribution: t.number
    })
  ),
  error: Error
})

export type BillingFreqRes = t.TypeOf<typeof BillingFreqRes>

export const CoreTransactionHistoryC = t.type({
  start: t.number,
  size: t.number,
  data: t.type({
    policyNo: t.string,
    codes: t.array(t.string)
  })
})

export type CoreTransactionHistoryC = t.TypeOf<typeof CoreTransactionHistoryC>

export const ItemCoreTransaction = t.type({
  tranDate: Maybe(t.string),
  code: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  description: Maybe(t.string),
  oldBillingFrequency: Maybe(t.string),
  newBillingFrequency: Maybe(t.string),
  validFlag: Maybe(t.string),
})

export const CoreTransactionData = t.type({
  data: t.array(ItemCoreTransaction)
})

export type CoreTransactionData = t.TypeOf<typeof CoreTransactionData>

export const PruCashBenefitReq = t.type({
  data: t.type({
    policyNo: t.string,
    loanNo: t.string,
    loanType: t.string
  })
})
export type PruCashBenefitReq = t.TypeOf<typeof PruCashBenefitReq>

export const PruCashBenefitData = t.type({
  loanNo: Maybe(t.string),
  loanType: Maybe(t.string),
  effectiveDate: Maybe(t.string),
  transNo: Maybe(t.string),
  glNo: Maybe(t.string),
  transDate: Maybe(t.string),
  transCode: Maybe(t.string),
  accountingAmount: Maybe(t.number)
})
export type PruCashBenefitData = t.TypeOf<typeof PruCashBenefitData>

export const PruCashBenefitDataInfo = t.type({
  data: Maybe(t.array(PruCashBenefitData)),
  error: Error
})

export const BonusHistoryItem = t.type({
  effectiveDate: Maybe(t.string),
  transactionNo: Maybe(t.string),
  transactionDate: Maybe(t.string),
  transCode: Maybe(t.string),
  accountingAmount: Maybe(t.number),
  originAmount: Maybe(t.number),
})
export type BonusHistoryItem = t.TypeOf<typeof BonusHistoryItem>

export const BonusHistoryDataInfo = t.type({
  data: Maybe(t.array(BonusHistoryItem)),
  error: Error
})

export type BonusHistoryDataInfo = t.TypeOf<typeof BonusHistoryDataInfo>

export const BillingChangeHistoryData = t.type({
  tranDate: Maybe(t.string),
  code: Maybe(t.string),
  description: Maybe(t.string),
  billingChangeHistories: t.array(
    t.type({
      oldBillingFrequency: Maybe(t.string),
      newBillingFrequency: Maybe(t.string),
      effectiveDate: Maybe(t.string),
      validFlag: Maybe(t.string),
    })
  )
})

export type BillingChangeHistoryData = t.TypeOf<typeof BillingChangeHistoryData>

export const BillingChangeHistory = t.type({
  data: Maybe(t.array(BillingChangeHistoryData))
})

export type BillingChangeHistory = t.TypeOf<typeof BillingChangeHistory>
