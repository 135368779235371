import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions, ScrollView } from 'react-native'
import { Select, Input, PulseOpsFormat } from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Status, StatusChangeForm } from './cashout-history-list'

type Props = {
  form: UseFormReturn<StatusChangeForm, object>
}

export const UploadStatus: React.FC<Props> = ({ form }) => {
  const { height } = useWindowDimensions()
  const composeHeigth = Math.max(height - 200, 400)
  const { t, i18n } = useTranslation()
  const statusFirstLoad = form.getValues('status.value')
  const [statusNote, setStatusNote] = React.useState<string>(statusFirstLoad ?? Status.VALID)

  const optionStatusNote = [
    {
      value: Status.INCOMPLETE,
      label: t('CashOut:NotCompleteDoc')
    },
    {
      value: Status.NOTUPDATE,
      label: t('CashOut:NotUpdateDoc')
    },
    {
      value: Status.VALID,
      label: t('CashOut:ValidDoc')
    },
    {
      value: Status.INVALID,
      label: t('CashOut:InvalidDoc')
    }
  ]

  return (
    <View style={[styles.container, { maxHeight: composeHeigth }]}>
      <ScrollView>
        <View style={styles.pannel}>
          <View style={[styles.row, { paddingBottom: 15, paddingLeft: 10 }]}>
            <Text style={styles.textUpdate}>{t('CashOut:LastUpdated')}</Text>
            <Text style={styles.textUpdate}>{': '}</Text>
            <Text style={styles.textUpdate}>
              {form.watch('updatedDate')
                ? PulseOpsFormat.dateStringtoFormat(form.watch('updatedDate'), 'DD/MM/YYYY HH:mm')
                : '-'}
            </Text>
          </View>

          <View style={styles.row}>
            <View style={{ flex: 1, paddingHorizontal: 10 }}>
              <Controller
                name="status"
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: `${t('message:MS020009', { field: t('CashOut:DocumentStatus') })}`
                  }
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <Select
                    required
                    label={t('CashOut:DocumentStatus')}
                    options={optionStatusNote}
                    placeholder={t('common:Select')}
                    onChange={(status) => {
                      onChange(status)
                      setStatusNote(status?.value ?? '')
                      if (status?.value !== Status.VALID && value && value.value === Status.VALID)
                        form.setValue('notes', '')
                    }}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </View>
          </View>

          {statusNote !== Status.VALID && (
            <View style={styles.row}>
              <View style={{ flex: 1, paddingHorizontal: 10, paddingTop: 20 }}>
                <Controller
                  name="notes"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020001', { field: t('common:Note') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      required
                      title={t('common:Note')}
                      value={value ?? ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center'
  },
  pannel: {
    borderWidth: 1,
    borderColor: '#d3dce6',
    borderRadius: 8,
    backgroundColor: '#fafafa',
    padding: 16,
    margin: 16
  },
  textUpdate: {
    fontStyle: 'italic',
    color: '#70777E'
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#70777E'
  },
  row: {
    flexDirection: 'row'
  },
  fileContainer: {
    width: '45%',
    marginHorizontal: 10,
    borderBottomWidth: 1,
    paddingVertical: 10,
    borderStyle: 'dashed',
    borderBottomColor: '#D3DCE6'
  }
})
