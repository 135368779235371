import * as t from 'io-ts'
import { PaymentData } from './PaymentData'

export const CashoutOptions = t.array(
  t.union([
    PaymentData.BankTransfer,
    PaymentData.EWalletMomo,
    PaymentData.PayPremium,
    PaymentData.Premium,
    PaymentData.PayLoan,
    PaymentData.TopUp,
    PaymentData.Reinstatement,
    PaymentData.NoType,
    PaymentData.RepayOpl,
    PaymentData.RepayApl,
    PaymentData.Other,
    PaymentData.Reins,
    PaymentData.CashAtCounter,
    PaymentData.PaidAtBank
  ])
)

export type CashoutOptionType = t.TypeOf<typeof CashoutOptions>
