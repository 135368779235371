import { View, Text, StyleSheet, useWindowDimensions, ScrollView, ActivityIndicator } from 'react-native'
import React from 'react'
import {
  ModalComponent,
  ModalAction,
  FailListInfo,
  form2,
  SelectSearch,
  Input,
  CashLessService,
  ErrorHandling,
  Banks,
  DatePicker,
  AuthService,
  Toast,
  addCommasToCurrencyString
} from '@pulseops/common'
import { Color } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { FailListDetailForm } from './fail-list-form'
import { Controller } from 'react-hook-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import moment from 'moment'
interface Props {
  visible: boolean
  onClose: (visible: boolean) => void
  objectId: string
}

const ModalWarning = (
  props: Props & {
    onConfirm: () => void
  }
) => {
  const { visible, onClose, onConfirm } = props
  const { width, height } = useWindowDimensions()
  const { t } = useTranslation()
  const modalActions: ModalAction[] = [
    {
      text: t('common:Cancel'),
      type: 'outline',
      action: () => onClose(!visible),
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    },
    {
      text: t('common:OK'),
      type: 'outline',
      action: () => onConfirm(),
      disabled: false,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]
  return (
    <ModalComponent
      title={''}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 500)}
      modalHeight={Math.min(height * 0.4, 160)}
      visible={visible}
      onClose={() => onClose(!visible)}
      actions={modalActions}
      showCloseIcon={false}
    >
      <View style={{ paddingTop: 20 }}>
        <Text style={styles.textWarning}>{t('message:MS990036')}</Text>
      </View>
    </ModalComponent>
  )
}

export const FailListDetailScreen = (props: Props) => {
  const { width, height } = useWindowDimensions()
  const { visible, onClose, objectId } = props
  const { t } = useTranslation()
  const [enableEdit, setEnableEdit] = React.useState<boolean>(false)
  const [enableSave, setEnableSave] = React.useState<boolean>(true)
  const [enableUploadFile, setEnableUploadFile] = React.useState<boolean>(true)
  const [disableFields, setDisabledFields] = React.useState<boolean>(true)
  const detail: FailListInfo | null = pipe(CashLessService.loadCaseDetail(objectId), ErrorHandling.runDidMount())
  const { base, handleSubmit } = form2.useForm(FailListDetailForm.codec)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const showToastInternal = (message: string, type: Color | undefined = undefined) => {
    setToast({ open: true, message, type })
  }
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())
  React.useEffect(() => {
    if (detail)
      base.reset({
        bankCode: { label: detail?.bankCode || '', value: detail?.bankCode || '' },
        policyNumber: detail?.primaryPolicyNo || '',
        amount: detail?.amount?.toString() || '0',
        refNo: detail?.refNo || '',
        receivedDate: moment(detail?.receivedDate).toDate(),
        creditDate: moment(detail?.creditDate).toDate(),
        reason: detail?.paymentReason || ''
      })
  }, [detail])

  const handleSave = handleSubmit((data) => {
    setEnableSave(true)
    pipe(
      CashLessService.updateCaseDetail({
        objectId: detail?.objectId || '',
        receivedDate: moment(data.receivedDate).format('YYYY-MM-DD'),
        creditDate: moment(data.creditDate).format('YYYY-MM-DD'),
        amount: data.amount || '',
        refNo: data.refNo || '',
        paymentReason: data.reason || '',
        primaryPolicyNo: data.policyNumber || '',
        bankCode: data.bankCode?.value || '',
        status: detail?.status || '',
        sourceData: detail?.sourceData || '',
        createDate: detail?.createdDate || '',
        updatedDate: moment().toISOString(),
        createBy: detail?.createdBy || '',
        updatedBy: userInfo?.email || ''
      }),
      ZIO.map((data) => {
        setEnableEdit(false)
        setDisabledFields(true)
        setEnableUploadFile(false)
      }),
      ZIO.catchAll((e) => {
        setEnableSave(false)
        if (Number(data.amount) === 0) {
          showToastInternal(t('message:MS100004', { num: 0 }), 'error')
        } else {
          showToastInternal(t('message:MS990073'), 'error')
        }
        return ZIO.unit
      }),
      ErrorHandling.run({})
    )
  })

  const uploadFile = (data: FailListDetailForm.Raw) => {
    pipe(
      CashLessService.submitCaseDetail([
        {
          objectId: detail?.objectId || '',
          receivedDate: moment(data.receivedDate).format('YYYY-MM-DD'),
          creditDate: moment(data.creditDate).format('YYYY-MM-DD'),
          amount: data.amount || '',
          refNo: data.refNo || '',
          paymentReason: data.reason || '',
          primaryPolicyNo: data.policyNumber || '',
          bankCode: data.bankCode?.value || '',
          updatedBy: userInfo?.email || ''
        }
      ]),
      ZIO.map((data) => {
        showToastInternal(t('message:MS990072'), 'success')
        setEnableUploadFile(true)
        setEnableSave(true)
        setEnableEdit(true)
        setDisabledFields(true)
        setVisibleWarning(false)
      }),
      ZIO.unsafeRun({})
    )
  }

  const handleUploadFile = handleSubmit((data) => {
    pipe(
      ZIO.succeed(data),
      ZIO.flatMap((res) => {
        if (detail?.status === 'duplicate') {
          setVisibleWarning(true)
          return ZIO.unit
        } else {
          uploadFile(res)
          return ZIO.unit
        }
      }),
      ErrorHandling.run()
    )
  })
  const [isVisibleWarning, setVisibleWarning] = React.useState<boolean>(false)
  const modalActions: ModalAction[] = [
    {
      text: t('TaskManagement:Edit'),
      type: 'outline',
      action: () => {
        setEnableEdit(true)
        setEnableSave(false)
        setDisabledFields(false)
        setEnableUploadFile(true)
      },
      disabled: enableEdit,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    },
    {
      text: t('TaskManagement:Save'),
      type: 'outline',
      action: handleSave,
      disabled: enableSave,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    },
    {
      text: t('TaskManagement:UploadDataToSys'),
      type: 'outline',
      action: handleUploadFile,
      disabled: enableUploadFile,
      loading: false,
      onlyWide: false,
      style: styles.btnConfirm
    }
  ]

  return (
    <ModalComponent
      title={t('TaskManagement:FailDetail')}
      titleStyle={styles.modalTitle}
      contentStyle={styles.modalContent}
      modalWidth={Math.min(width * 0.9, 1024)}
      visible={visible}
      onClose={() => onClose(!visible)}
      actions={modalActions}
    >
      <ScrollView style={styles.container}>
        {!_.isUndefined(base.watch().policyNumber) ? (
          <>
            <View style={styles.row}>
              <Controller
                control={base.control}
                name="bankCode"
                render={({ field, fieldState: { error } }) => (
                  <View style={styles.inputStyle}>
                    <SelectSearch
                      {...field}
                      label={t('TaskManagement:BankCode')}
                      options={Banks.RawBanks.filter((x) => x.bankCode).map((item) => ({
                        value: item.bankCode,
                        label: item.bankCode
                      }))}
                      placeholder={t('common:Select')}
                      disabled={disableFields}
                      disableUnderline={disableFields}
                      required
                      errorMessage={error?.message}
                    />
                  </View>
                )}
              />
              <Controller
                control={base.control}
                name="policyNumber"
                render={({ field, fieldState: { error } }) => {
                  const firstLoading = _.isNaN(Number(field.value)) && !_.isEmpty(field.value)
                  return (
                    <View style={styles.inputStyle}>
                      <Input
                        {...field}
                        value={field.value ?? ''}
                        title={t('TaskManagement:policy')}
                        maxLength={9}
                        onChange={(val) => {
                          if (firstLoading && field.value) {
                            field.value?.length > val.length && field.onChange(val)
                          } else {
                            field.onChange(val)
                          }
                        }}
                        disabled={disableFields}
                        alwayShowUnderline={!disableFields}
                        errorMessage={error?.message}
                        inputType={firstLoading ? 'text' : 'number'}
                        required
                      />
                    </View>
                  )
                }}
              />
              <Controller
                control={base.control}
                name="amount"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <View style={styles.inputStyle}>
                      <Input
                        {...field}
                        value={
                          Number(field.value?.replace(',', ''))
                            ? addCommasToCurrencyString(field.value?.replace(',', '') || '0')
                            : field.value === '0'
                            ? '0'
                            : ''
                        }
                        onChange={(val) => {
                          let value = [...val].filter((item) => item !== ',').join('')
                          if (value.length <= 12) {
                            field.onChange(value)
                          }
                        }}
                        title={t('TaskManagement:Amount')}
                        disabled={disableFields}
                        alwayShowUnderline={!disableFields}
                        errorMessage={error?.message}
                        required
                      />
                    </View>
                  )
                }}
              />
            </View>
            <View style={styles.row}>
              <Controller
                control={base.control}
                name="refNo"
                render={({ field, fieldState: { error } }) => (
                  <View style={styles.inputStyle}>
                    <Input
                      {...field}
                      value={field.value ?? ''}
                      title={t('TaskManagement:RefNo')}
                      disabled={disableFields}
                      alwayShowUnderline={!disableFields}
                      errorMessage={error?.message}
                    />
                  </View>
                )}
              />
              <Controller
                control={base.control}
                name="receivedDate"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <View style={styles.inputStyle}>
                    <DatePicker
                      label={t('TaskManagement:ReceivedDate')}
                      onChange={onChange}
                      value={value}
                      maxDate={base.watch().creditDate}
                      labelStyle={{ marginBottom: 0 }}
                      disabled={disableFields}
                      hideUnderline={disableFields}
                      errorMessage={error?.message}
                      required
                    />
                  </View>
                )}
              />
              <Controller
                control={base.control}
                name="creditDate"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <View style={styles.inputStyle}>
                    <DatePicker
                      label={t('TaskManagement:CreditDate')}
                      onChange={onChange}
                      value={value}
                      maxDate={new Date()}
                      minDate={base.watch().receivedDate}
                      labelStyle={{ marginBottom: 0 }}
                      disabled={disableFields}
                      hideUnderline={disableFields}
                      errorMessage={error?.message}
                      required
                    />
                  </View>
                )}
              />
            </View>
            <View style={styles.row}>
              <Controller
                control={base.control}
                name="reason"
                render={({ field, fieldState: { error } }) => (
                  <View style={[styles.inputStyle, { width: '100%' }]}>
                    <Input
                      {...field}
                      value={field.value ?? ''}
                      title={t('Cashless:reason')}
                      disabled={disableFields}
                      alwayShowUnderline={!disableFields}
                      required
                      errorMessage={error?.message}
                    />
                  </View>
                )}
              />
            </View>
          </>
        ) : (
          <ActivityIndicator size="large" color="#ED1B2C" />
        )}
      </ScrollView>
      <ModalWarning
        visible={isVisibleWarning}
        onClose={(visible) => {
          setVisibleWarning(visible)
        }}
        onConfirm={() => {
          uploadFile(base.watch())
        }}
        objectId={objectId}
      />
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </ModalComponent>
  )
}
const styles = StyleSheet.create({
  modalTitle: {},

  modalContent: {},

  btnCancel: {
    height: 39,
    marginEnd: 15
  },

  btnConfirm: {
    height: 39,
    marginHorizontal: 5
  },

  container: {
    padding: 30
  },
  searchMessage: {
    color: '#ED1B2C',
    fontSize: 11.25
  },
  row: {
    flex: 1,
    flexDirection: 'row'
  },
  inputStyle: {
    marginHorizontal: 15,
    marginBottom: 15,
    width: '30%'
  },
  textWarning: {
    paddingHorizontal: 35,
    fontSize: 18
  }
})
