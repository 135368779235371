// React Lib
import React, { useState } from 'react'
import { ScrollView, View, TouchableOpacity } from 'react-native'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  // TableFooter,
  Icon
} from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
// import Icon from 'react-native-vector-icons/Feather'
import { useForm, Controller, ControllerRenderProps, ControllerFieldState, UseFormStateReturn } from 'react-hook-form'

// Common
import { SC } from './styles'
import { TextCustom } from '../UpdateDocumentGuideScreen/styles'
import {
  assets,
  DatePicker,
  SelectSearch,
  Input,
  ViewManualService,
  downloadURI,
  SelectOption,
  RoundedButton,
  UploadManualService,
  ErrorHandling,
  PulseOpsFormat,
  AppContext,
  RBAC,
  Permission
} from '@pulseops/common'
import {
  COLORS,
  GroupsViewType,
  TypeAction,
  TypeActionEnum,
  TypeFieldEnum,
  actions,
  groupsViewsConst,
  titleHeaders,
  typeField,
  typeNameField
} from '../constants'
import { useTranslation } from 'react-i18next'
import { ViewDocumentGuideForm } from './ViewDocumentGuideForm'
import { isEmpty, uniqBy } from 'lodash'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons'
import { FormGroupEType } from '../UpdateDocumentGuideScreen/models'

const { Container, Header, Row, Col_3, SEARCH_AREA } = SC

const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: `${COLORS.MYSTIC}`,
    border: `1px solid ${COLORS.HOWKES_BLUE}`,
    borderRadius: '8px 8px 0px 0px'
  },
  columnStyle: {
    width: '200px',
    verticalAlign: 'top'
  }
})

export const ViewDocumentGuideScreen = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  // const { isMobile } = useMobile()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const { navigate } = useNavigation()
  const [{ pageSize, pageNum, total }, setPagination] = useState({
    pageSize: 10,
    pageNum: 0,
    total: 10
  })
  const [dataSource, setDataSource] = React.useState<ViewManualService.GuidelineTable[]>([])
  const { control, reset, watch, setValue } = useForm({
    defaultValues: ViewDocumentGuideForm.GuideFormDefault
  })
  const [search, setSearch] = useState<boolean>(false)
  const [departmentData, setDepartmentData] = useState<SelectOption[]>([])
  const [groupData, setGroupData] = useState<SelectOption[]>([])
  const [submittedData, setSubmittedData] = React.useState<ViewDocumentGuideForm.GuideModal>(ViewDocumentGuideForm.GuideFormDefault)
  const [loading, bindLoading] = useLoading(false)
  const [getAllsData, setGetAllsData] = useState<UploadManualService.DepartmentT17s2Type[]>([])
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Utilities'),
          navigate: () => {
            navigate('UtilitiesStack', { screen: 'Utilities' })
          }
        },
        { title: t('utilities:ViewManualGuideLine'), navigate: null }
      ])
    }
    setDataSource([])
    setSearch(false)
  }, [isFocused])

  pipe(
    UploadManualService.getAllt17s2(),
    ZIO.map((res) => {
      const departments = [...new Map(res.map((item) => [item['department'], 
      {
        label: item.department,
        value: item.departmentCode
      }
      ])).values()]
      const groups = [...new Map(res.map((item) => [item['group'], 
      {
        label: item.group,
        value: item.group
      }])).values()]
      setGetAllsData([...res])
      setDepartmentData(departments)
      setGroupData(groups)
    }),
    ErrorHandling.runDidMount()
  )

  const searchData = (
    submitData: ViewDocumentGuideForm.GuideModal,
    pageSize: number,
    pageNum: number,
  ) => {
    pipe(
      ViewManualService.GetDataSearch(
        {
          order: 'ASC',
          sort: 'string',
          size: pageSize,
          start: pageNum,
          departmentCode: submitData.departmentCode?.value ?? '',
          group: submitData.group?.value ?? '',
          mainDoc: submitData.mainDoc?.value ?? '',
          subDoc: submitData.subDoc?.value ?? '',
          fromDate: submitData.fromDate ? PulseOpsFormat.datetoFormat(submitData.fromDate, 'DD/MM/YYYY') : '',
          toDate: submitData.toDate ? PulseOpsFormat.datetoFormat(submitData.toDate, 'DD/MM/YYYY') : '' 
        }
      ),
      ZIO.map((res) => {
        setPagination({
          total: res.total ?? 0,
          pageNum: pageNum,
          pageSize: pageSize
        })
        setSearch(true)
        setDataSource(res.data)
        // setMessageResponse(res.items.length === 0 && loadFromSearch ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const renderTitleTable = () => {
    return titleHeaders(t).map((title) => {
      return (
        <TableCell align="left" style={{ fontWeight: 'bold', fontSize: 15 }}>
          {title}
        </TableCell>
      )
    })
  }

  const filterLabelDepartment = (value: string) => {
    const filtering = (item: SelectOption) => item.value === value
    const filterByName = departmentData.find(filtering)
    return filterByName && filterByName.label
  }

  const downLoadFile = (row: ViewManualService.GuidelineTable ) => {
    const businessKey = row.businessKey && row.businessKey.indexOf('GI-') >= 0 ? row.businessKey.split('GI-')[1] : undefined
    if(businessKey) {
      pipe(
        ViewManualService.GetDataDownload(businessKey),
        ZIO.flatMap((responseData) => {
          return ZIO.zipPar(
            ZIO.succeed(responseData),
            ZIO.fromPromise(() => responseData.blob())
          )
        }),
        ZIO.map(([res, blob]) => {
          const fileString = window.URL.createObjectURL(blob)
          // window.open(fileString, '_blank')
          const type = res.headers.get('content-type') || ''
          downloadURI(fileString, `file.${type.split('/')[1]}`)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const viewFile = (row: ViewManualService.GuidelineTable ) => {
    const businessKey = row.businessKey && row.businessKey.indexOf('GI-') >= 0 ? row.businessKey.split('GI-')[1] : undefined
    if(businessKey) {
      pipe(
        ViewManualService.GetDataDownload(businessKey),
        ZIO.flatMap((responseData) => {
          return ZIO.zipPar(
            ZIO.succeed(responseData),
            ZIO.fromPromise(() => responseData.blob())
          )
        }),
        ZIO.map(([res, blob]) => {
          const fileString = window.URL.createObjectURL(blob)
          // window.open(fileString, '_blank')
          window.open(fileString, '_blank')
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }


  const renderActionsTable = (row: ViewManualService.GuidelineTable) => {
    return (
      <Row style={{ marginRight: 10 }}>
        <TouchableOpacity onPress={() => viewFile(row)}>
          <View style={{ flexDirection: 'row', width: 100, marginRight: 5 }}>
            <Icon style={{ marginRight: 10, fontSize: 17, height: 'auto', marginTop: 5 }}>
              <FontAwesome5 name="eye" color="#ED1B2E" />
            </Icon>
            <TextCustom fontWeight="600" fontSize="16px">
              {t('utilities:View')}
            </TextCustom>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => downLoadFile(row)}>
          <View style={{ flexDirection: 'row', paddingLeft: 5, width: 100 }}>
            <Icon style={{ marginRight: 10 }}>
              <assets.Download />
            </Icon>
            <TextCustom fontWeight="600" fontSize="16px">
              {t('utilities:Download')}
            </TextCustom>
          </View>
        </TouchableOpacity>
      </Row>
    )
  }

  const handleChangePage = (page: number) => {
    searchData(submittedData, pageSize, page)
  }

  const handleChangePerPage = (perPage: number) => {
    searchData(submittedData, perPage, pageNum)
  }

  const renderTable = () => {
    if(search) {
      return (
        <View>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>{renderTitleTable()}</TableRow>
              </TableHead>
              <TableBody>
                {dataSource.length === 0 && search &&
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      {t('common:noData')}
                    </TableCell>
                  </TableRow>
                }
                {dataSource?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{item?.departmentCode ? filterLabelDepartment(item?.departmentCode) : ''}</TableCell>
                    <TableCell align="left">{item?.group}</TableCell>
                    <TableCell align="left">{item?.mainDoc}</TableCell>
                    <TableCell align="left">{item?.subDoc}</TableCell>
                    <TableCell align="left">{item?.createdDate}</TableCell>
                    <TableCell align="left">{renderActionsTable(item)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {dataSource.length > 0 &&
            <TablePagination
              page={pageNum}
              rowsPerPage={pageSize}
              count={total}
              onPageChange={(_, page) => handleChangePage(page)}
              onRowsPerPageChange={(e) => handleChangePerPage(+e.target.value)}
              labelRowsPerPage={t('common:PaginationSize')}
              labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
              component={View}
            />
          }
        </View>
      )
    }
    return(<View></View>)
  }

  const getUniqueArrayObject = (arr: UploadManualService.DepartmentT17s2Type[], byType: FormGroupEType) => {
    const uniArray = uniqBy(arr, byType)?.map((elm) => elm[byType])
    return uniArray.map((item) => ({
      label: item,
      value: item
    })) as SelectOption[]
  }

  const getGroupData = () => {
    const department = watch('departmentCode')
    return department ? groupData : []
  }

  const getTitleData = () => {
    const department = watch('departmentCode')
    const group = watch('group')
    if(department && group)
    {
      const filterData = getAllsData.filter((item) => item.departmentCode === department.value && item.group === group.value)
      const filterTitle = getUniqueArrayObject(filterData, 'mainDoc')
      return filterTitle
    }
    return []
  }

  const getDecriptionData = () => {
    const department = watch('departmentCode')
    const group = watch('group')
    const title = watch('mainDoc')
    if(department && group && title)
    {
      const filterData = getAllsData.filter((item) => item.departmentCode === department.value && item.group === group.value && item.mainDoc === title.value)
      const filterDecription = getUniqueArrayObject(filterData, 'subDoc')
      return filterDecription
    }
    return []
  }

  const isChange = (value: any, valueCompare: string): value is SelectOption => (value as SelectOption).value !== valueCompare

  const resetField = (type: typeNameField) => {
    if(type === 'departmentCode') {
      setValue('group', null)
      setValue('mainDoc', null)
      setValue('subDoc', null)
    }
    else if(type === 'group') {
      setValue('mainDoc', null)
      setValue('subDoc', null)
    }
    else if(type === 'mainDoc') {
      setValue('subDoc', null)
    }
  }

  /**
   * VIEWS
   */
  const renderTypeField = ({
    field,
    controller
  }: {
    field: GroupsViewType<typeNameField>
    controller: {
      field: ControllerRenderProps<typeof ViewDocumentGuideForm.GuideFormDefault, typeNameField>
      fieldState: ControllerFieldState
      formState: UseFormStateReturn<typeof ViewDocumentGuideForm.GuideFormDefault>
    }
  }) => {
    const { type, placeholder, required, options, formName } = field
    const label = field.label
    const {
      field: { onChange, onBlur, value },
      fieldState: { error }
    } = controller
    const optionsData = {
      departmentCode: departmentData,
      group: getGroupData(),
      mainDoc: getTitleData(),
      subDoc: getDecriptionData(),
      fromDate: [],
      toDate: []
    }

    const fieldTypeMap = new Map<typeField | 'DEFAULT', () => void>([
      // [
      //   TypeFieldEnum['TEXTBOX'],
      //   () => (
      //     <Input
      //       title={label}
      //       value={value as string}
      //       required={required}
      //       onChange={onChange}
      //       onBlur={onBlur}
      //       errorMessage={!isEmpty(error) ? error?.message : ''}
      //     />
      //   )
      // ],
      [
        TypeFieldEnum['DATE_PICKER'],
        () => (
          <DatePicker
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value as Date}
            maxDate={new Date()}
            alwaysShow={true}
            required={required}
            maxDateMessage=""
            minDateMessage=""
            errorMessage={!isEmpty(error) ? error?.message : ''}
          />
        )
      ],
      [
        TypeFieldEnum['DROPDOWN_LIST'],
        () => (
          <SelectSearch
            label={label}
            options={optionsData[formName]}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e)
              if(e === null || (e && value && isChange(value, e.value)))
                resetField(formName)
            }}
            onBlur={onBlur}
            value={value as SelectOption}
            required={required}
            popupIcon={<assets.ArrowDownDropdownIcon />}
            maxLength={100}
            errorMessage={!isEmpty(error) ? error?.message : ''}
          />
        )
      ],

      ['DEFAULT', () => <></>]
    ])

    return (fieldTypeMap.get(type) ?? fieldTypeMap.get('DEFAULT'))?.()
  }

  const renderFields = () => {
    return groupsViewsConst(t).map((_field, index) => {
      const { formName } = _field
      return (
        <Col_3 key={index} style={{ marginBottom: 32 }}>
          <Controller
            name={formName}
            control={control}
            render={(controller) => <>{renderTypeField({ field: _field, controller })}</>}
          />
        </Col_3>
      )
    })
  }

  const renderActions = () => {
    const handlePress = (type: TypeAction) => {
      const pressHandler: {
        [key in TypeAction]: () => void
      } = {
        [TypeActionEnum['SEARCH']]: () => {
          const dataSearch = control._getWatch()
          setSubmittedData(dataSearch)
          return searchData(dataSearch, pageSize, pageNum)
        },
        [TypeActionEnum['CLEAR']]: () => {
          return reset({ ...ViewDocumentGuideForm.GuideFormDefault })
        }
      }

      return pressHandler?.[type]?.()
    }

    return actions(t).map((button, index) => {
      const { text, bgColorEnable, borderColorEnable, textColorEnable, type } = button
      return (
        <RoundedButton
          activeOpacity={0.7}
          text={text}
          filled
          showBorder={true}
          textColorEnable={textColorEnable}
          bgColorEnable={bgColorEnable}
          borderColorEnable={borderColorEnable}
          style={{
            minWidth: 100,
            height: 39,
            paddingHorizontal: 13,
            ...(index === 0 && { marginRight: '12px' })
          }}
          textStyle={{ fontSize: 15 }}
          onPress={() => handlePress(type as TypeAction)}
        />
      )
    })
  }

  return (
    <Container>
      {permissions.includes(Permission['ViewMGDetailViewManualGuideline']) && 
        <ScrollView>
          <Header>
            <TextCustom fontSize="15px" fontWeight="bold">
              {t('utilities:ManualGuidelineList')}
            </TextCustom>
          </Header>
          <View style={{ paddingHorizontal: 15 }}>
            <SEARCH_AREA>
              <Row>{renderFields()}</Row>
              <Row>{renderActions()}</Row>
            </SEARCH_AREA>
            <View>{renderTable()}</View>
          </View>
        </ScrollView>
      }
    </Container>
  )
}
