import React, { useState, useEffect, useContext } from 'react'
import { ReportsStackParamList } from './ReportsStackParamList'
import { StackScreenProps } from '@react-navigation/stack'
import { useIsFocused } from '@react-navigation/native'
import {
  AppContext,
  DatePicker,
  // ErrorHandling,
  // RBAC,
  SelectOption,
  SelectSearch,
  assets,
  // ReportPermission,
  GeneralService,
  AuthService,
  PulseOpsFormat,
  T0Data,
  Format,
  ErrorHandling,
  RBAC,
  Permission,
  StorageBlobApi,
  downloadURI
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, StyleSheet, TouchableOpacity, Text, Pressable } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { SubmissionReportConst } from './SubmissionReportConst'
import { ZIO } from '@mxt/zio'

import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  LinearProgress
} from '@material-ui/core'
import moment from 'moment'
import { isAfter, startOfDay, addDays } from 'date-fns/fp'
import { PaymentReportItemType } from './Service/PaymentReportService'
import { isEmpty, isNil } from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { ReportService } from './Service/ReportExcelService'
import _ from 'lodash'
import { ClaimPayoutReportItem, ClaimPayoutReportService } from './Service/ClaimPayoutReportService'
export interface PayoutReportFormData {
  fromDate: Date | null
  toDate: Date | null
  template: SelectOption
  offices: SelectOption[]
  category: SelectOption
  source: SelectOption[]
  reportType: SelectOption
  year: SelectOption
}

declare interface Pagination {
  pageNum: number
  pageSize: number
  total: number
}

declare type actionType = 'VIEW' | 'EXPORT'

const Action = {
  VIEW: 'VIEW',
  EXPORT: 'EXPORT'
}

type props = StackScreenProps<ReportsStackParamList, 'PaymentReportScreen'>

declare type DocumentStatusType = 'VALID_DOC' | 'INVALID_DOC' | 'INCOMPLETE_DOC' | 'NOT_UPDATE_DOC'

// export const Status = {
//   VALID: 'VALID_DOC',
//   INVALID: 'INVALID_DOC',
//   INCOMPLETE: 'INCOMPLETE_DOC',
//   NOTUPDATE: 'NOT_UPDATE_DOC'
// }

export const ClaimPayoutReportScreen = ({ navigation }: props) => {
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()
  const [loading, bindLoading] = useLoading(false)
  const ht = useTranslation('menu').t
  
  const { changeBreadcrumb, showToast, showGlobalLoading } = useContext(AppContext.AppContextInstance)
  const [maxDate] = React.useState<Date>(new Date())
  const [officeCodes, setOfficeCodes] = useState<SelectOption[]>([])
  const [officeGA, setOfficeGA] = useState<SelectOption | null>(null)
  const [dataSource, setDataSource] = useState<ClaimPayoutReportItem[]>([])

  
  const actions = [
    {
      type: Action.EXPORT,
      text: t('View'),
      isHavePermission: true
    }
  ]

  const paymentReportForm = useForm<PayoutReportFormData>({
    defaultValues: {
      // fromDate: undefined,
      // toDate: undefined,
      // template: {
      //   value: SubmissionReportConst.Extension.CSV,
      //   label: 'CSV'
      // },
      reportType: {
        value: SubmissionReportConst.ExportBookingReport[0].value,
        label: t(`Reports:${SubmissionReportConst.ExportBookingReport[0].label}`)
      },
      year: {
        value: new Date().getFullYear().toString(),
        label: new Date().getFullYear().toString()
      }
    }
  })

  const { control, setValue, watch } = paymentReportForm
  
  const userInfo = pipe(AuthService.userInfo, ErrorHandling.runDidMount())

  
  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: ht('Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:ClaimPayoutReport'),
          navigate: null
        }
      ])
    }
  }, [isFocused])

  /**
   * Functions
   */

  const validateDataFields = async (fromDateItem: Date, toDateItem: Date) => {
    const customedFromDate = moment(fromDateItem).format('YYYY/MM/DD')
    const customedToDate = moment(toDateItem).format('YYYY/MM/DD')

    const isValidForm = await paymentReportForm.trigger()

    if (!isValidForm) {
      return false
    } else if (!moment(fromDateItem).isValid()) {
      return false
    } else if (!moment(customedToDate).isValid()) {
      return false
    } else if (fromDateItem && !toDateItem) {
      return false
    } else if (!fromDateItem && toDateItem) {
      return false
    } else if (moment(customedFromDate) > moment(customedToDate)) {
      showToast(t('message:MS030044'), 'error')
      return false
    } else if (
      moment(customedFromDate).valueOf() > moment().valueOf() ||
      moment(customedToDate).valueOf() > moment().valueOf()
    ) {
      return false
    } else if (
      pipe(fromDateItem, startOfDay, addDays(35), (fromDateAdd10Days) =>
        isAfter(fromDateAdd10Days)(startOfDay(toDateItem))
      )
    ) {
      showToast(t('message:MS080004', { k: 35 }).replace('months', 'days').replace('tháng', 'ngày'), 'error')
      return false
    }
    return true
  }

  const getValuesForm = (): {
    customedFromDate: Date
    customedToDate: Date
    customedTemplate: SubmissionReportConst.Extension.EXCEL | SubmissionReportConst.Extension.CSV
    customedReportType: SelectOption
  } => {
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const customedTemplate = watch('template')?.value as
      | SubmissionReportConst.Extension.EXCEL
      | SubmissionReportConst.Extension.CSV

    const customedReportType = watch('reportType');

    return {
      customedFromDate,
      customedToDate,
      customedTemplate,
      customedReportType
    }
  }


  const setDefaultValuesForm = () => {
    const { customedFromDate, customedToDate } = getValuesForm() ?? {}
    const currentDate = moment(new Date()).toDate()
    const pastOneDay = !customedToDate
      ? moment().subtract(1, 'days').toDate()
      : moment(customedToDate).subtract(1, 'days').toDate()

    !customedFromDate && setValue('fromDate', pastOneDay)
    !customedToDate && setValue('toDate', currentDate)
  }

  
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  const handleClickViewReport = async () => {
    const { customedFromDate, customedToDate, customedReportType } = getValuesForm() ?? {}

    // setDefaultValuesForm()
    if (await validateDataFields(customedFromDate, customedToDate)) {
      const reportData = {
        // fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
        // toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
        author: userInfo?.email, 
        reportType: customedReportType.value,
        role: userInfo?.isGaLogin ? 'GA' : '',
        year:  watch('year.value')
      }
      pipe(
        ClaimPayoutReportService.exportData(reportData),
        ZIO.map((res) => {
          setDataSource(res.data ?? [])
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  
  const handleClickActions = (typeAction: actionType) => {
    const actionsHandler = {
      VIEW: () => {},

      EXPORT: () => { handleClickViewReport()}
    }

    return actionsHandler?.[typeAction]?.()
  }

  const handleExportFile = (url: string) => {
    pipe(
      ZIO.zipPar(
        StorageBlobApi.getRemoteConfig('CL' ?? '', ''),
        AuthService.token,
        AuthService.getLoginType
      ),
      ZIO.flatMap(([storageConfig, token, loginType]) => {
        const urlImage = url ?? ''
        return pipe(
          ZIO.fromPromise(() => {
            const apiUrl = urlImage.split('?')
            const storageUrl = apiUrl[0]
            const paramInfo = apiUrl[1]
            const paramList = paramInfo.split('&')
            const containerName = !!paramList[0] ? paramList[0].split('=')[1] : ''
            const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
            return fetch(storageUrl, {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Container: containerName,
                Token: storageConfig.sas,
                Blob: blobName,
                Authorization: `Bearer ${token}`,
                'X-Authen-Vendor': loginType
              }
            })
          }),
          ZIO.flatMap((res) =>
            ZIO.zipPar(
              ZIO.succeed(res),
              ZIO.fromPromise(() => res.blob())
            )
          ),
          ZIO.map(([response, blob]) => ({
            response,
            blob
          }))
        )
      }),
      ZIO.map((responseData) => {
        const fileName = url.split('/');
        // const type = responseData.response.headers.get('content-type') || ''
        downloadURI(window.URL.createObjectURL(responseData.blob), `${fileName?.[fileName?.length - 1]}`)
        
      }),
      ZIO.unsafeRun({})
    )
  }

  const renderActions = () => {
    return (
      <View style={[PRStyle.col_4, { flex: 1, flexDirection: 'row', alignItems: 'center' }]}>
        {actions.map((action, index) => {
          const { text, isHavePermission, type } = action
          const isButtonView = type === Action.VIEW

          return (
            <React.Fragment key={index}>
              {isHavePermission && (
                <TouchableOpacity
                  style={{ width: 118, height: 40, ...(isButtonView && { marginRight: 30 }) }}
                  onPress={() => handleClickActions(type as actionType)}
                >
                  <View style={PRStyle.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{text}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </React.Fragment>
          )
        })}
      </View>
    )
  }

  const getReportTypeOption = () => {
    if(roles.includes(Permission['ExportBookingReport']) && roles.includes(Permission['ExportPVReport'])) {
      return SubmissionReportConst.ReportPayoutClaim.map((item) => ({
        value: item.value,
        label: t(`Reports:${item.label}`),
      }))
    } else if(roles.includes(Permission['ExportBookingReport'])) {
      return SubmissionReportConst.ExportBookingReport.map((item) => ({
        value: item.value,
        label: t(`Reports:${item.label}`),
      }))
    } else {
      return SubmissionReportConst.ExportPVReport.map((item) => ({
        value: item.value,
        label: t(`Reports:${item.label}`),
      }))
    }
  }

  const getReportYearOption = () => {
    const currentYear = new Date().getFullYear();
    let list: SelectOption[] = [];

    for(let i = 0; i < 5; i ++){
      let year = (currentYear - i).toString();
      list.push(
        {
          value: year,
          label: year
        }
      )
    }

    return list;
  }
  const renderTable = () => {
    if (isEmpty(dataSource)) return
    return (
      <View style={{ marginTop: 20, marginBottom: 20 }}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow style={{ backgroundColor: '#e2e7ea' }}>{renderTableHeader()}</TableRow>
            </TableHead>

            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
      </View>
    )
  }


  const renderTableBody = () => {
    return dataSource?.map((row, index) => {
      const urlImage = row?.filename?.[0] ?? ''
      const apiUrl = urlImage?.split('?')
      const paramInfo = apiUrl[1]
      const paramList = paramInfo.split('&')
      const blobName = !!paramList[1] ? paramList[1].split('=')[1] : ''
      return (
        <TableRow key={`${index}`}>
          <TableCell align="left">
            <Pressable
              onPress={() =>{handleExportFile(row?.filename?.[0] ?? '')}}
            >
              <Text
                numberOfLines={1}
                style={{
                  fontWeight: '400',
                  fontSize: 15,
                  color: '#1ea5fc',
                  textDecorationLine: 'underline'
                }}
              >
                { blobName || '-'}
              </Text>
            </Pressable>
          </TableCell>
          <TableCell align="left">{row.createdDate}</TableCell>
          <TableCell align="left">{row.fromDate}</TableCell>
          <TableCell align="left">{row.toDate}</TableCell>
          
        </TableRow>
      )
    })
  }
  const titleHeaders = [
    t('Reports:Payment.TableHeader.File'),
    t('Reports:Payment.TableHeader.CreateDateClaim'),
    t('common:FromDate'),
    t('common:ToDate'),
  ]

  const renderTableHeader = () => {
    return titleHeaders.map((title, index) => {
      return (
        <TableCell align="left" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }} key={index}>
          {title}
        </TableCell>
      )
    })
  }

  return (
    <View style={PRStyle.pageContent}>
      <ScrollView style={[PRStyle.container]}>
        <View style={PRStyle.sectionContent}>
          <View style={PRStyle.sectionRow}>
            {/* <View style={PRStyle.col_4}>
              <Controller
                name="fromDate"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:FromDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                  />
                )}
              />
            </View>
            <View style={PRStyle.col_4}>
              <Controller
                name="toDate"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:ToDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                  />
                )}
              />
            </View> */}
            <View style={PRStyle.col_4}>
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('RequestInfo:Years')}
                    options={getReportYearOption()}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
            <View style={PRStyle.col_4}>
              <Controller
                name="reportType"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:ReportType')}
                    options={getReportTypeOption()}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
          </View>

          {/* <View style={[PRStyle.sectionRow, PRStyle.secondLine]}>
            <View style={PRStyle.col_4}>
              <Controller
                name="template"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Template')}
                    options={[{
                      value: SubmissionReportConst.TemplateList[1].value,
                      label: SubmissionReportConst.TemplateList[1].label
                    }]}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>

            
          </View> */}
          <View style={[PRStyle.sectionRow, PRStyle.secondLine]}>{renderActions()}</View>
        </View>
        {loading && <LinearProgress color="secondary" />}
        {renderTable()}
      </ScrollView>
    </View>
  )
}

const PRStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 30
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  totalRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginLeft: 5
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  cellWidth200: {
    minWidth: '200px'
  }
})
