import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Customer } from '../Client'
import { MoreData } from '../MoreData'

export interface ZaloClaimDecision {
  tag: TransactionType.ZALO_CLAIM_DECISION
  clientName: string

  note: string
}

export const ZaloClaimDecision = (
  detail: api.ZaloClaimDecision,
  client: Customer,
  sql: MoreData.PoNameFromSql
): ZaloClaimDecision => {
  return {
    tag: TransactionType.ZALO_CLAIM_DECISION,
    clientName: sql.body?.poName || client.body.name,

    note: detail.payload.body.message || ''
  }
}
