import { SubPremiumRedirectionData } from '../PremiumRedirection'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface VeriPremiumRedirectionData {
  tag: TransactionType.PREMIUM_REDIRECTION
  clientName: string
  fundList: SubPremiumRedirectionData.SUBFundInfo[]
}

export const PremiumRedirection = (
  detail: api.PremiumRedirection & api.TaskDetailBase,
  poName: string
): VeriPremiumRedirectionData => {
  const payLoadData = detail.payload.body
  const payLoadFundList = payLoadData.fundList.map((item) => {
    return {
      fundCode: item.fundCode,
      fundName: '',
      currentPercentage: Number(item.currentPercentage),
      redirectPercentage: Number(item.redirectPercentage)
    }
  })
  return {
    tag: TransactionType.PREMIUM_REDIRECTION,
    clientName: poName,
    fundList: payLoadFundList
  }
}
