import { POApi } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { OccupationDetailResponse, OccupationsResponse } from './models'

export namespace ChangeOccupationService {
  export const bytesToMegabyte = (bytes: number) => {
    return +(bytes / 1048576).toFixed(2)
  }

  export const removeAccents = (str: string) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  }

  export const replaceTwoArray = <A extends object>(array1: Array<A>, array2: Array<A>, typeKey: keyof A) => {
    return array1.map((elm: A) => array2.find((obj: A) => elm[typeKey] === obj[typeKey]) ?? elm)
  }

  export const getOccupationDetailByCode = (code: string) => {
    return pipe(
      POApi.get(`wf-api/table/t80s1/get-by-code?code=${code}`)(OccupationDetailResponse),
      ZIO.map((res) => {
        return res.body
      })
    )
  }

  export const getOccupations = pipe(
    POApi.get(`wf-api/table/t80s1/get-all`)(OccupationsResponse),
    ZIO.map((res) => {
      return res.body
    })
  )
}
