import { PayoutPopup, form2, capFisrtLetterEachWord } from '@pulseops/common'
import * as t from 'io-ts'
import { DateOfDeathForm } from '../../date-of-death'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'

export namespace MaturityPaymentForm {
  const MaturityPayment = t.intersection([
    t.type({
      isPoPassAway: t.boolean,
      payout: pipe(
        t.array(PayoutPopup.SummaryCodec),
        form2.refine(
          (arr): arr is PayoutPopup.PayoutSummaryArray => arr && arr.length > 0,
          () => i18next.t('message:MS020001', { field: capFisrtLetterEachWord(i18next.t('submission:PAYOUT_INFO')) }),
          'EmptyArray'
        ),
        form2.refine(
          (arr): arr is PayoutPopup.PayoutSummaryArray =>
            arr && arr.length > 0 && arr.reduce((sum, item) => sum + item.amount, 0) > 0,
          () => i18next.t('message:MS050241'),
          'TotalAmountLessThanZero'
        )
      )
    }),
    t.union([
      t.intersection([t.type({ isPoPassAway: t.literal(true) }), DateOfDeathForm.codec]),
      t.type({ isPoPassAway: t.literal(false) })
    ])
  ])

  export type MaturityPayment = t.TypeOf<typeof MaturityPayment>

  export const codec = MaturityPayment

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
