import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddNewLAModal,
  Checkbox,
  InquiryComplaintData,
  RiderAlterationFormData,
  RiderAlterationService,
  SelectOption,
  Title,
  LifeAssuredTable,
  AlterRiderTable,
  formatNumberWithComma
} from '@pulseops/common'
import { VERIRiderAlteration } from '../../../../common/src/service/model/task-detail/RiderAlteration'
import { StyleSheet, Text, View } from 'react-native'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { SectionContent } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import * as A from 'fp-ts/lib/Array'

type DataSource = {
  riderName: string
  riskCommDate: string
  riskCessDate: string
  sumAssured: string
  installmentPremium: string
}

type RiderAlterationProps = {
  detailData: VERIRiderAlteration
  policyNum: string
}

export const RiderAlteration = (data: RiderAlterationProps) => {
  const { t, i18n } = useTranslation('requestInfo')
  const [newRiderList, setNewRiderList] = React.useState<SelectOption[]>([])
  // const [averageIncomeList, setAverageIncomeList] = React.useState<SelectOption[]>([])
  const [isShowPopup, setIsShowPopup] = React.useState<boolean>(false)
  // const lifeAssureds: VeriLARiderInfo[] = data?.detailData.lifeAssureds || []
  const [newLAInfoIndex, setNewLAInfoIndex] = React.useState<number>(-1)
  const riderNameList: InquiryComplaintData.IACSources[] = data?.detailData.riderNameList || []

  const riderAlterationForm = useForm<RiderAlterationFormData.RiderAlteration>({
    defaultValues: {
      LARiderAlterationList: [],
      isAddRider: false,
      isRiderAlteration: false,
      newRiderLAList: []
    }
  })

  const LAFieldList = useFieldArray({
    control: riderAlterationForm.control,
    name: 'LARiderAlterationList'
  })

  const NewRiderLAList = useFieldArray({
    control: riderAlterationForm.control,
    name: 'newRiderLAList'
  })

  React.useEffect(() => {
    if (data.detailData) {
      const lifeAssuredAdditions = data.detailData.lifeAssuredAdditions
      pipe(
        lifeAssuredAdditions,
        A.map((lifeAssuredItem) =>
          RiderAlterationService.getRiderList(data.policyNum, lifeAssuredItem.lifeAssured.lifeNo ?? '')
        ),
        ZIO.sequence,
        ZIO.map((riderNameArr) => {
          const lifeAssuredArr = lifeAssuredAdditions.map((item) => {
            const alteredRiderList = riderNameArr.find((x) => x.lifeNo === item.lifeAssured.lifeNo)?.riderArr ?? []
            return {
              lifeAssured: {
                ...item.lifeAssured,
                alteredRiderList: alteredRiderList
              }
            }
          })
          return lifeAssuredArr
        }),
        ZIO.map((lifeAssuredList) => {
          let riderFormArray: Array<RiderAlterationFormData.CurrentRiderForm> = []
          const LARiderItemList = data.detailData.lifeAssureds
          riderAlterationForm.setValue('isRiderAlteration', data.detailData.isRiderAlteration)
          riderAlterationForm.setValue('isAddRider', data.detailData.isAddLA)
          LARiderItemList.map((LAItem) => {
            LAItem.coreRiders.map((rider) => {
              const productName = getRiderName(rider.productCode)
              const isWaiverProduct = data.detailData.waiverList.some((waiveritem) => waiveritem === rider.productCode)
              riderFormArray.push({
                riderCode: rider.productCode,
                riderName: productName,
                isWaiverProduct: isWaiverProduct,
                isClassNumber: false,
                classNumberList: [],
                // SelectRowForRider: false,
                riskCommDate: rider.riskCommDate,
                riskCessDate: rider.riskCessDate,
                sumAssured: rider.sumAssured,
                alteredSumAssured: rider.alteredSumAssured,
                installmentPrem: rider.installmentPremium,
                newInstallmentPremium: 0,
                lifeNo: rider.lifeNo,
                coverageNo: rider.coverageNo,
                riderNo: rider.riderNo,
                riderStatus: rider.riderStatus
              })
            })

            LAFieldList.append({
              // selectAllForLA: false,
              LAName: LAItem.clientName,
              lifeAssured: LAItem.lifeAssured,
              currentRiders: riderFormArray
            })

            riderFormArray = []
          })
          lifeAssuredList.map((lifeItem, index) => {
            // addNewRiderLAItem(index)
            const riderList = lifeItem.lifeAssured.riderListAdditional.map((riderItem) => {
              const riderInfo = getAlterRiderOptions(lifeItem.lifeAssured.alteredRiderList).find(
                (item) => item.value === riderItem.coverageCode
              ) ?? {
                value: '',
                label: ''
              }
              return {
                riderName: riderInfo,
                sumAssured: riderItem.sumAssured.toString(),
                policyPeriod: riderItem.policyPeriod.toString(),
                isWaiverProduct: false,
                isClassNumber: false,
                classNumberList: []
              }
            })
            NewRiderLAList.insert(index, {
              LAName: lifeItem.lifeAssured.fullName,
              lifeAssured: '',
              isNewLA: lifeItem.lifeAssured.isNewLA ? true : false,
              newRiderList: riderList,
              detailData: lifeItem.lifeAssured,
              alteredRiderList: lifeItem.lifeAssured.alteredRiderList
            })
            riderAlterationForm.setValue(`newRiderLAList.${index}.LAName`, lifeItem.lifeAssured.fullName)
            riderAlterationForm.setValue(`newRiderLAList.${index}.lifeAssured`, '')
            riderAlterationForm.setValue(`newRiderLAList.${index}.isNewLA`, lifeItem.lifeAssured.isNewLA ? true : false)
            riderAlterationForm.setValue(`newRiderLAList.${index}.newRiderList`, riderList)
            riderAlterationForm.setValue(`newRiderLAList.${index}.detailData`, lifeItem.lifeAssured)
            riderAlterationForm.setValue(
              `newRiderLAList.${index}.alteredRiderList`,
              lifeItem.lifeAssured.alteredRiderList
            )
          })
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [data.detailData])

  const getRiderName = (riderCode: string) => {
    const item = riderNameList.find((x) => x.code === riderCode)
    return !!item ? riderCode + ' - ' + (i18n.language === 'en' ? item.name : item.nameVi) : ''
  }

  const getAlterRiderOptions = (riderArr: RiderAlterationService.RiderNameInfo[]) => {
    const riderList = riderArr.map((item) => ({
      label: item.code + ' - ' + (i18n.language === 'en' ? item.name : item.nameVi),
      value: item.code
    }))
    return riderList
  }

  const closeAddLAModal = () => {
    setIsShowPopup(false)
  }

  const viewLADetail = (index: number) => {
    setNewLAInfoIndex(index)
    setIsShowPopup(true)
  }
  const openViewDetailPopUp = () => {
    const veriDetailData = riderAlterationForm.watch(`newRiderLAList.${newLAInfoIndex}.detailData`)
    console.log('viewItem', veriDetailData)
    return isShowPopup ? (
      <AddNewLAModal
        title={t('RiderAlteration:NewLAInformation')}
        isVisible={isShowPopup}
        isConfirmed={true}
        onClose={closeAddLAModal}
        isDetailView={true}
        detailIndex={newLAInfoIndex}
        veriDetailData={veriDetailData}
        riderAlterationForm={riderAlterationForm}
        // NewRiderLAList={NewRiderLAList}
        onSuccess={() => {}}
      />
    ) : (
      <></>
    )
  }
  const formatCurrency = (value: number | string | undefined) => {
    return !!value ? formatNumberWithComma(value) : '0'
  }
  return (
    <View>
      <Title title={t('RIDERALTERATIONINFORMATION')} />
      <Controller
        control={riderAlterationForm.control}
        name={'isRiderAlteration'}
        render={({ field: { value, onChange, onBlur } }) => (
          <Checkbox
            title={t('RiderAlteration:RiderAlteration')}
            onChange={(checked) => {
              onChange(checked)
              // changeEventForLA(checked, index, fieldItem)
            }}
            onBlur={onBlur}
            value={value}
            disabled={true}
            checkBoxStyle={styles.tableCheckBox}
          />
        )}
      />
      {riderAlterationForm.watch('isRiderAlteration') && (
        <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
          <LifeAssuredTable
            riderAlterationForm={riderAlterationForm}
            LAFieldList={LAFieldList}
            isConfirmed={true}
            isVerificationScreen={true}
          ></LifeAssuredTable>
        </SectionContent>
      )}
      <View style={styles.secondLine}>
        <Controller
          control={riderAlterationForm.control}
          name={'isAddRider'}
          render={({ field: { value, onChange, onBlur } }) => (
            <Checkbox
              title={t('RiderAlteration:AddRider')}
              onChange={(checked) => {
                onChange(checked)
                // changeEventForLA(checked, index, fieldItem)
              }}
              onBlur={onBlur}
              value={value}
              disabled={true}
              checkBoxStyle={styles.tableCheckBox}
            />
          )}
        />
      </View>
      {riderAlterationForm.watch('isAddRider') && (
        <SectionContent sectionStyles={{ backgroundColor: '#fff' }}>
          <AlterRiderTable
            // newRiderList={newRiderList}
            riderAlterationForm={riderAlterationForm}
            NewRiderLAList={NewRiderLAList}
            disabled={true}
            // addNewRider={addNewRiderForExistedLA}
            // removeRider={removeRiderForExistedLA}
            // removeNewLA={removeNewLA}
            viewLADetail={viewLADetail}
            isVerificationScreen={true}
          ></AlterRiderTable>
        </SectionContent>
      )}
      {openViewDetailPopUp()}
      <View style={styles.secondLine}>
        <Text style={styles.text}>{t('submission:RequirePayInAmount')}</Text>
        <Text style={styles.isHightLight}>{formatCurrency(data?.detailData.requiredPayInAmount)} VND</Text>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    marginBottom: 10
  },
  isHightLight: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#eD1B2E'
  },
  tableCheckBox: {
    padding: 0,
    marginTop: 0,
    paddingLeft: 10
  },
  secondLine: {
    marginTop: 20
  }
})
