import * as React from 'react'
import {
  FieldList,
  Panel,
  Title,
  Divider,
  MaturityPaymentService,
  ErrorHandling,
  formatNumberWithComma,
  form2,
  MaturityPayment as MaturityPaymentModel,
  TransactionType,
  PayoutPopup,
  StorageBlob,
  GeneralService,
  AppContext,
  TaskType
} from '@pulseops/common'
import { Checkbox, Column } from '@pulseops/submission/common'
import { pipe } from 'fp-ts/function'
import { DateOfDeath, DateOfDeathForm } from '../../date-of-death'
import { mapCashOutOption, PayoutMethod, PayoutMethodRef } from '../../payout-method'
import { PolicyServiceProps, UploadedFilesInfo } from '../policy-service-props'
import { MaturityPaymentForm } from './maturity-payment.form'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import { RequestAuthenticateData } from '../../request-authen'
import { useTranslation } from 'react-i18next'
// import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'

interface Props extends PolicyServiceProps<MaturityPaymentModel.SubmitData> {
  policyNumber: string
}

export const MaturityPayment = ({ policyNumber, initSubmission, isConfirmed, officeCode }: Props) => {
  const { t } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const payoutRef = React.useRef<PayoutMethodRef | null>(null)

  const payoutMethodList: PayoutPopup.PayoutMethods[] = [
    PayoutPopup.PayoutMethods.PAYPREMIUM,
    PayoutPopup.PayoutMethods.PAYLOAN,
    PayoutPopup.PayoutMethods.CASHLESS,
    PayoutPopup.PayoutMethods.OTHER,
    PayoutPopup.PayoutMethods.CASH_AT_COUNTER,
    PayoutPopup.PayoutMethods.PAID_AT_BANK,
    PayoutPopup.PayoutMethods.BANKTRANSFER
  ]
  const maturityForm = form2.useForm(MaturityPaymentForm.codec, {
    defaultValues: {
      isPoPassAway: false,
      payout: []
    }
  })
  const { control, watch, setValue, trigger } = maturityForm.base
  const { isPoPassAway, payout } = watch()
  const benefit = pipe(MaturityPaymentService.getDetail(policyNumber), ErrorHandling.runDidUpdate([policyNumber]))
  const totalAmount = React.useMemo(() => payout.reduce((sum, item) => sum + item.amount, 0), [payout])

  React.useEffect(() => {
    return () => {
      maturityForm.base.reset({
        isPoPassAway: false,
        payout: []
      })
      payoutRef.current?.clearData()
    }
  }, [isFocused])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  // const getMetaDataFromAttachmentFiles = () => {
  //   let files: StorageBlob.FileContent[] = []
  //   return pipe(
  //     GeneralService.getMetaData(TransactionType.MATURITY, 'DPS11', officeCode),
  //     ZIO.map((metaDataRes) => {
  //       const metaDataRaw: StorageBlob.MetaDataUpload = {
  //         type: metaDataRes.data.transactionType,
  //         doctype: metaDataRes.data.doctypeEn,
  //         class: metaDataRes.data.classFilenet,
  //         docid: metaDataRes.data.docID,
  //         maindoc: metaDataRes.data.mainDoc,
  //         subdoc: metaDataRes.data.subDoc,
  //         polnum: policyNumber,
  //         batchno: metaDataRes.data.batchNo
  //       }
  //       const documents = maturityForm.base.getValues('files') as DateOfDeathForm.FileList
  //       files = documents.map((x) => {
  //         return {
  //           file: x.file,
  //           metaData: metaDataRaw
  //         }
  //       })
  //       return files
  //     })
  //   )
  // }

  // const getMetaForPayoutDocuments = (maturityForm: MaturityPaymentForm.Raw) => {
  //   let files: StorageBlob.FileContent[] = []
  //   return pipe(
  //     ZIO.effect(() => getPayoutDocuments(maturityForm)),
  //     ZIO.flatMap((payoutDocuments) =>
  //       !!payoutDocuments && payoutDocuments.length > 0
  //         ? pipe(
  //             GeneralService.getMetaData(TransactionType.MATURITY, 'DPS01', officeCode),
  //             ZIO.map((metaDataRes) => {
  //               const metaDataRaw: StorageBlob.MetaDataUpload = {
  //                 type: metaDataRes.data.transactionType,
  //                 doctype: metaDataRes.data.doctypeEn,
  //                 class: metaDataRes.data.classFilenet,
  //                 docid: metaDataRes.data.docID,
  //                 maindoc: metaDataRes.data.mainDoc,
  //                 subdoc: metaDataRes.data.subDoc,
  //                 polnum: policyNumber,
  //                 batchno: metaDataRes.data.batchNo
  //               }
  //               files = payoutDocuments.map((x) => {
  //                 return {
  //                   file: x.file,
  //                   metaData: metaDataRaw
  //                 }
  //               })
  //               return files
  //             })
  //           )
  //         : ZIO.succeed(files)
  //     )
  //   )
  // }

  const getUploadedFilesInfo = (formData: MaturityPaymentForm.Raw) => {
    let uploadedFileList: UploadedFilesInfo[] = []
    const documents = maturityForm.base.getValues('files') as DateOfDeathForm.FileList
    if (isPoPassAway && documents && documents.length > 0) {
      uploadedFileList.push({
        uploadFiles: documents,
        transactionType: TransactionType.MATURITY,
        docTypeCode: 'DPS11',
        category: TaskType.PolicyService,
        policyNumber: policyNumber ?? '',
        officeCode: officeCode ?? ''
      })
    }
    uploadedFileList.push({
      uploadFiles: GeneralService.getPayoutDocuments(formData.payout),
      transactionType: TransactionType.MATURITY,
      docTypeCode: 'DPS01',
      category: TaskType.PolicyService,
      policyNumber: policyNumber ?? '',
      officeCode: officeCode ?? ''
    })
    return uploadedFileList
  }

  initSubmission({
    validate: async (isContinue) => {
      const isFormValid = await trigger()

      if (benefit && totalAmount !== benefit.totalCashBenefit) {
        return false
      }

      if (!isFormValid) {
        return false
      }

      const value = maturityForm.base.getValues()
      // const files = maturityForm.base.getValues('files')
      let uploadedFiles: StorageBlob.FileContentSubmit[] = []

      return {
        url: (policyNumber: string) => `wf-api/policy/${policyNumber}/maturity`,
        body: {
          policy: {
            owners: {
              clientId: benefit?.clientId || '',
              dateOfDeath: value.isPoPassAway ? moment(value.deathDate).format('DD/MM/yyyy') : ''
            },
            attributesExt: {
              inheritanceFlag: value.isPoPassAway ? 'Y' : 'N'
            }
          },
          cashOutOptions: mapCashOutOption(value.payout)
        },
        collerationId: policyNumber,
        transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.MATURITY),
        transaction: TransactionType.MATURITY,
        documents: uploadedFiles,
        uploadedFilesInfo: getUploadedFilesInfo(value)
      }
    },
    clear: () => {
      setValue('isPoPassAway', false)
      setValue('deathDate', null)
      setValue('files', [])
      setValue('payout', [])
      payoutRef.current?.clearData()
    }
  })

  return (
    <Column flex={1}>
      <Title title={t('submission:MATURITY_INFO')} wrapperStyle={{ marginTop: 30 }} />
      <Panel isExand={false} containerStyle={{ backgroundColor: '#FAFAFA' }}>
        <FieldList
          dataSource={[
            {
              label: t('requestInfo:TotalMaturityBenefit'),
              value: formatNumberWithComma(benefit?.maturityBenefit || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:TotalPrucashBenefit'),
              value: formatNumberWithComma(benefit?.prucashBenefit || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:SuspenseAmount'),
              value: formatNumberWithComma(benefit?.suspendAmount || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:PaidMaturityAdvance'),
              value: formatNumberWithComma(benefit?.paidMaturity || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:PrincipalAPL-OPL'),
              value: formatNumberWithComma(benefit?.principalAplOpl || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:InterestAPL-OPL'),
              value: formatNumberWithComma(benefit?.interestAplOpl || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:NotPaidPremium'),
              value: formatNumberWithComma(benefit?.notPaidPremium || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:DeductOfClaims'),
              value: formatNumberWithComma(benefit?.deductOfClaims || ''),
              suffix: 'VND'
            },
            {
              label: t('requestInfo:NetTotalMaturityBenefits'),
              value: formatNumberWithComma(benefit?.totalCashBenefit || ''),
              suffix: 'VND',
              isHighlight: true
            }
          ]}
        />
      </Panel>
      {(!isConfirmed || (isConfirmed && isPoPassAway)) && (
        <>
          <Divider height={30} />
          <Checkbox
            enable={!isConfirmed}
            title={t('submission:PODeath')}
            fontWeight="bold"
            value={isPoPassAway}
            onChange={(val) => {
              if (val) {
                setValue('deathDate', null)
                setValue('files', [])
              }
              setValue('isPoPassAway', val)
            }}
          />
        </>
      )}
      {isPoPassAway && (
        <DateOfDeath
          editable={!isConfirmed}
          deathDateControl={{ control: control, name: 'deathDate' }}
          fileControl={{ control: control, name: 'files' }}
        />
      )}

      {benefit && (
        <Controller
          control={control}
          name="payout"
          render={({ field, fieldState: { error } }) => (
            <PayoutMethod
              {...field}
              ref={payoutRef}
              editable={!isConfirmed}
              policyNum={policyNumber}
              transactionType={TransactionType.MATURITY}
              inheritanceFlag={isPoPassAway}
              maxAmount={benefit.totalCashBenefit}
              errorMessage={error?.message || (totalAmount !== benefit.totalCashBenefit ? t('message:MS050170') : '')}
              methods={payoutMethodList}
              officeCode={officeCode}
            />
          )}
        />
      )}
    </Column>
  )
}
