import { Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { ClientScreenContext, IBButtonMultiTasks } from '../../common'
import { IBPolicyPermission, SelectOption, assets } from '@pulseops/common'
import { IBPopupClickToSend } from './IBPopupClickToSend'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { ClickToSendEmailForm } from './IBClickToSendEmailForm'
import { A14S0Data, A14S1Data, A14S5Data } from '../../ib-service'

export const IBClickToSendEmail = ({
  setFlagModal,
  flagOpenModal
}: {
  setFlagModal: (open: boolean) => void
  flagOpenModal: boolean | undefined
}) => {
  const { permissions } = React.useContext(ClientScreenContext)
  const [open, setOpen] = React.useState<boolean>(false)
  const [masterDataList, setMasterDataList] = React.useState<{
    policyLists: (SelectOption & { policyOwner: string })[]
    a14s0s: A14S0Data[]
    a14s1s: A14S1Data[]
    a14s5s: A14S5Data[]
  }>({ policyLists: [], a14s0s: [], a14s1s: [], a14s5s: [] })

  const form = useForm<ClickToSendEmailForm>({
    mode: 'onChange'
  })

  React.useEffect(() => {
    setFlagModal(open)
  }, [open])

  React.useEffect(() => {
    if (!flagOpenModal) {
      setOpen(false)
    }
  }, [flagOpenModal])
  return (
    <>
      {!permissions.includes(IBPolicyPermission.IBViewEmailPopup) ? (
        <></>
      ) : (
        <View>
          <View style={[styles.fabModal, { display: !open ? 'none' : 'flex' }]}>
            <IBPopupClickToSend
              showPopup={open}
              setShowPopup={setOpen}
              form={form}
              masterDataList={masterDataList}
              setMasterDataList={setMasterDataList}
            />
          </View>
          <Pressable
            style={[
              styles.CircleFab,
              {
                backgroundColor: open ? '#FFFFFF' : '#ED1B2E',
                opacity: flagOpenModal ? 0 : 1
                // bottom: open ? -78 : 0
              }
            ]}
            disabled={flagOpenModal}
            onPress={() => {
              setOpen(!open)
            }}
          >
            {open ? <assets.IBMultiTasksXIcon /> : <assets.IBClickToSendEmailIcon />}
          </Pressable>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: 0
  },
  fabModal: {
    margin: 0,
    position: 'absolute',
    right: 30,
    bottom: -75
  },
  CircleFab: {
    borderColor: '#ED1B2E',
    padding: 15,
    borderRadius: 40,
    borderWidth: 1
  }
})
