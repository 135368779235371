import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.REINSTATEMENT)
    }),
    codec
  ])

const infoCodec = t.type({
  lapseDate: Maybe(t.string),
  reinsAmount: Maybe(t.string),
  poClientNum: Maybe(t.string)
})

const LifeAssured = t.type({
  clientId: t.string,
  name: t.string,
  attributesExt: Maybe(
    t.type({
      IS_CHANGE_HEALTH_STATE: Maybe(t.boolean),
      IS_OCCUPATION_CHANGE: Maybe(t.boolean),
      IS_ACTIVITY_CHANGE: Maybe(t.boolean),
      IS_EXIST_REQUEST_COMPANY: Maybe(t.boolean),
      CUR_OCCUPATION: Maybe(t.string),
      NEW_OCCUPATION: Maybe(t.string),
      NEW_ACTIVITY: Maybe(t.string),
      COMPANY_NAME: Maybe(t.string)
    })
  )
})

const payloadPulse4Ops = t.type({
  body: t.type({
    policyNo: t.string,
    owners: t.type({
      clientId: t.string
    }),
    lifeAssured: t.array(LifeAssured),
    attributesExt: t.type({
      TRANS_CODE: t.string,
      AGENT_CODE: t.string,
      AGENT_NAME: t.string,
      // "AUTHEN_OPTION": string, //1,2,3 ~ OTP, SIGN ON APP, ATTACT REQEUST FORM
      // "OFFICE_CODE": string,
      RISK_COMM_DATE: Maybe(t.string), // YYYYMMDD -  ngay hieu luc hien tai
      LAPSED_DATE: Maybe(t.string), // ngay mat hieu luc
      FREQUENCY: Maybe(t.string), // dinh ky
      INSTALLMENT_PREM: Maybe(t.number), // phi bao hiem dinh ky
      APL: Maybe(t.number), // tam ung nop phi tu dong
      OPL: Maybe(t.number), // tam ung gia tri hoan lai
      REINS_FEE: Maybe(t.number),
      TOTAL_REINS_AMOUNT: Maybe(t.number)
    })
  })
})

const payloadPruonline = t.type({
  body: t.type({
    policyNo: t.string,
    owners: t.type({
      clientId: t.string
    }),
    lifeAssured: t.array(LifeAssured),
    attributesExt: t.type({
      TRANS_CODE: t.string,
      AGENT_CODE: Maybe(t.string),
      AGENT_NAME: Maybe(t.string),
      // "AUTHEN_OPTION": string, //1,2,3 ~ OTP, SIGN ON APP, ATTACT REQEUST FORM
      // "OFFICE_CODE": string,
      RISK_COMM_DATE: Maybe(t.string), // YYYYMMDD -  ngay hieu luc hien tai
      LAPSED_DATE: Maybe(t.string), // ngay mat hieu luc
      FREQUENCY: Maybe(t.string), // dinh ky
      INSTALLMENT_PREM: Maybe(t.number), // phi bao hiem dinh ky
      APL: Maybe(t.number), // tam ung nop phi tu dong
      OPL: Maybe(t.number), // tam ung gia tri hoan lai
      REINS_FEE: Maybe(t.number),
      TOTAL_REINS_AMOUNT: Maybe(t.number)
    })
  })
})

const payloadZalo = t.type({
  body: t.type({
    policyNo: t.string,
    owners: t.type({
      clientId: t.string
    }),
    lifeAssured: t.array(LifeAssured),
    attributesExt: t.type({
      TRANS_CODE: t.string,
      AGENT_CODE: Maybe(t.string),
      AGENT_NAME: Maybe(t.string),
      RISK_COMM_DATE: Maybe(t.string), // YYYYMMDD -  ngay hieu luc hien tai
      LAPSED_DATE: Maybe(t.string), // ngay mat hieu luc
      FREQUENCY: Maybe(t.string), // dinh ky
      INSTALLMENT_PREM: Maybe(t.union([t.string, t.number])), // phi bao hiem dinh ky
      APL: Maybe(t.union([t.string, t.number])), // tam ung nop phi tu dong
      OPL: Maybe(t.union([t.string, t.number])), // tam ung gia tri hoan lai
      REINS_FEE: Maybe(t.number),
      TOTAL_REINS_AMOUNT: Maybe(t.number)
    })
  })
})

const Zalo = Base(
  t.type({
    source: t.literal(SourceType.ZALO),
    payload: payloadZalo
  })
)

const Ekiosk = Base(
  t.type({
    source: t.literal(SourceType.EKIOSK),
    payload: t.type({
      body: t.intersection([
        t.type({
          healthChangeNote: Maybe(t.string)
        }),
        infoCodec
      ])
    })
  })
)

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: t.type({
      body: infoCodec
    })
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload: t.type({
      body: infoCodec
    })
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload: t.type({
      body: infoCodec
    })
  })
)

const Pulse4Ops = Base(
  t.type({
    source: t.union([t.literal(SourceType.PULSE4OPS), t.literal(SourceType.BANCA_SEA)]),
    payload: payloadPulse4Ops
  })
)

const PruOnline = Base(
  t.type({
    source: t.literal(SourceType.PRUONLINE),
    payload: payloadPruonline
  })
)

export const Reinstatement = t.union([PruOnline, Pulse, Zalo, Banca, Ekiosk, PruGreat, Pulse4Ops])
export type Reinstatement = t.TypeOf<typeof Reinstatement>
