/* eslint-disable no-extra-boolean-cast */
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
import { ZIO } from '@mxt/zio'
import { SeaAutoDebitRegisterReportModel } from './SEAAutoDebitRegisterReportModel'
import * as ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import { PulseOpsFormat } from '@pulseops/common'
export const uuid = () => {
  let uuidValue = '',
    k,
    randomValue
  for (k = 0; k < 32; k++) {
    randomValue = (Math.random() * 16) | 0

    if (k === 8 || k === 12 || k === 16 || k === 20) {
      uuidValue += '-'
    }
    uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
  }
  return uuidValue
}
export namespace SEAAutoDebitRegisterReportService {
  export interface Column {
    id?: string
    name: string
    width?: number
    horizontal?: string
  }

  export interface Summary {
    fromDate: Date
    toDate: Date
    title: string
    status: string
  }
  export interface Table {
    ref: string
    rowRef: number
    colRef: number
  }
  export enum Extension {
    EXCEL = 'xlsx',
    CSV = 'csv'
  }
  export type RequestAuthPaper = {
    authFlag: boolean
    attachments: {
      url: string
      name: string
    }
  }
  export interface BodyUploadGuide {
    departmentCode: string
    group: string
    mainDoc: string
    subDoc: string
  }
  export interface FileContentSubmit {
    name: string
    filename?: string
    url: string
  }
  export const ResponseGuide = t.type({
    exchangeId: Maybe(t.string),
    body: Maybe(
      t.type({
        departmentCode: Maybe(t.string),
        group: Maybe(t.string),
        mainDoc: Maybe(t.string),
        subDoc: Maybe(t.string)
      })
    ),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.number),
        message: Maybe(t.string)
      })
    )
  })
  export const QueryData = t.type({
    source: Maybe(t.string),
    exchangeId: Maybe(t.string),
    createdBy: Maybe(t.string),
    action: Maybe(t.string),
    body: Maybe(
      t.type({
        templateFileName: Maybe(t.string),
        transactionType: Maybe(t.string)
      })
    ),
    createdDate: Maybe(t.string)
  })
  export const ExportData = t.type({
    body: Nullable(t.string)
  })

  export type ExportData = t.TypeOf<typeof ExportData>
  export type QueryData = t.TypeOf<typeof QueryData>

  export type ResponseGuide = t.Type<typeof ResponseGuide>

  export const exportTemplateExcel = (query: QueryData) =>
    pipe(
      POApi.post(`banca-sea/billing-change/export-template-config-file`)(ExportData)(query),
      ZIO.map((res) => res)
    )

  export const finalSentence = (mySentence: string) => {
    return mySentence?.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  }

  export const getReportData = (query: SeaAutoDebitRegisterReportModel.ExportData) =>
    POApi.post(`banca-sea/report/partial-withdraw/register`)(SeaAutoDebitRegisterReportModel.ReportsDebitData)(query)

  const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  export const exportTemplate = (
    tableInfo: Table,
    summary: Summary,
    columns: Column[],
    rows: any[][],
    extension: string = Extension.EXCEL,
    fullFileName?: string,
    fullSheetName?: string
  ) => {
    const fileName = !!fullFileName ? fullFileName : 'Auto Debit Topup Account Monthly Register Report'
    const sheetName = !!fullSheetName ? fullSheetName : 'Auto Debit Topup Account Monthly Register Report'
    const horizontal = 'center'
    const vertical = 'middle'
    const name = 'Calibri'
    const wrapText = true
    const bold = true
    const size = 11
    const font = { bold, name, size }
    const isExcel = extension === Extension.EXCEL

    const { colRef, rowRef, ref } = tableInfo
    const { status, fromDate, toDate, title } = summary
    const rowEnd = rows.length + rowRef

    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName)

    ws.views = [{ showGridLines: false }]
    columns.forEach((column, cIndex) => {
      const index = colRef + cIndex
      const width = column.width
      const horizontal = column.horizontal as any
      ws.getColumn(index).alignment = { horizontal, vertical, wrapText }
      ws.getColumn(index).width = width
      ws.getCell(1, index).value = '' // init for export csv
      for (let rIndex = rowRef; rIndex <= rowEnd; rIndex++) {
        ws.getCell(rIndex, index).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }
    })

    const writeExcel = () => {
      ws.mergeCells('B2:L2')
      ws.getCell('B2').value = title
      ws.getCell('B2').style = { font: { bold, size: 20 } }
      ws.getCell('B2').alignment = { vertical, horizontal }

      ws.getCell('B4').alignment = { horizontal: 'left' }
      ws.getCell('B4').value = {
        richText: [
          { font, text: 'Reporting period: ' },
          {
            font: { name, size },
            text: `${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
          }
        ]
      }

      ws.getCell('B5').alignment = { horizontal: 'left' }
      ws.getCell('B5').value = {
        richText: [
          { font, text: 'Reporting date: ' },
          {
            font: { name, size },
            text: PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')
          }
        ]
      }
    }

    const writeCSV = () => {
      ws.getCell('B2').value = title
      ws.getCell('B4').value = `Reporting period: ${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
      ws.getCell('B5').value = `Reporting date: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`
      ws.getCell('B6').value = `Category: ${status}`
    }
    isExcel ? writeExcel() : writeCSV()
    ws.addTable({
      name: 'Template Export',
      ref,
      headerRow: true,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true
      },
      columns,
      rows
    })

    return pipe(
      ZIO.fromPromise(() => (isExcel ? wb.xlsx : wb.csv).writeBuffer()),
      ZIO.map((data) => new Blob([data], { type })),
      ZIO.tap((blob) => {
        FileSaver.saveAs(blob, `${fileName}.${extension}`)
        return ZIO.unit
      })
    )
  }
  export const exportTemplateVI = (
    tableInfo: Table,
    summary: Summary,
    columns: Column[],
    rows: any[][],
    extension: string = Extension.EXCEL,
    fullFileName?: string,
    fullSheetName?: string
  ) => {
    const fileName = !!fullFileName ? fullFileName : 'Auto Debit Topup Account Monthly Register Report'
    const sheetName = !!fullSheetName ? fullSheetName : 'Auto Debit Topup Account Monthly Register Report'
    const horizontal = 'center'
    const vertical = 'middle'
    const name = 'Calibri'
    const wrapText = true
    const bold = true
    const size = 11
    const font = { bold, name, size }
    const isExcel = extension === Extension.EXCEL

    const { colRef, rowRef, ref } = tableInfo
    const { status, fromDate, toDate, title } = summary
    const rowEnd = rows.length + rowRef

    const wb = new ExcelJS.Workbook()
    const ws = wb.addWorksheet(sheetName)

    ws.views = [{ showGridLines: false }]
    columns.forEach((column, cIndex) => {
      const index = colRef + cIndex
      const width = column.width
      const horizontal = column.horizontal as any
      ws.getColumn(index).alignment = { horizontal, vertical, wrapText }
      ws.getColumn(index).width = width
      ws.getCell(1, index).value = '' // init for export csv
      for (let rIndex = rowRef; rIndex <= rowEnd; rIndex++) {
        ws.getCell(rIndex, index).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }
    })

    const writeExcel = () => {
      ws.mergeCells('B2:L2')
      ws.getCell('B2').value = title
      ws.getCell('B2').style = { font: { bold, size: 20 } }
      ws.getCell('B2').alignment = { vertical, horizontal }

      ws.getCell('B4').alignment = { horizontal: 'left' }
      ws.getCell('B4').value = {
        richText: [
          { font, text: 'Kỳ báo cáo: ' },
          {
            font: { name, size },
            text: `${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
          }
        ]
      }

      ws.getCell('B5').alignment = { horizontal: 'left' }
      ws.getCell('B5').value = {
        richText: [
          { font, text: 'Ngày báo cáo: ' },
          {
            font: { name, size },
            text: PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')
          }
        ]
      }
    }

    const writeCSV = () => {
      ws.getCell('B2').value = title
      ws.getCell('B4').value = `Kỳ báo cáo: ${PulseOpsFormat.date(fromDate)} - ${PulseOpsFormat.date(toDate)}`
      ws.getCell('B5').value = `Ngày báo cáo: ${PulseOpsFormat.datetoFormat(new Date(), 'DD/MM/yyyy HH:mm')}`
    }
    isExcel ? writeExcel() : writeCSV()
    ws.addTable({
      name: 'Template Export',
      ref,
      headerRow: true,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true
      },
      columns,
      rows
    })

    return pipe(
      ZIO.fromPromise(() => (isExcel ? wb.xlsx : wb.csv).writeBuffer()),
      ZIO.map((data) => new Blob([data], { type })),
      ZIO.tap((blob) => {
        FileSaver.saveAs(blob, `${fileName}.${extension}`)
        return ZIO.unit
      })
    )
  }
}
