import { assets } from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'

export interface Tab {
  id: number
  title: string
}

export interface TabWithPermission<P> extends Tab {
  id: number
  title: string
  permission: P
  subTabPermissions: string[]
}

interface Props {
  gutterBottom?: boolean
  menus: Tab[]
  tabIndex: number
  useOriginalId?: boolean
  onChangeTab: (idx: number) => void
  isSubTab?: boolean
  isOTPStyle?: boolean
  predicateDisable?: (index: number) => boolean
  isScrollIcon?: boolean
}

const TabItem = ({
  tabItem,
  tabIndex,
  onChangeTab,
  isSubTab = false,
  useOriginalId = false,
  disable = false,
  isOTPStyle = false
}: {
  tabItem: { id: number; title: string }
  tabIndex: number
  onChangeTab: (tabIndex: number) => void
  isSubTab?: boolean
  useOriginalId?: boolean
  disable?: boolean
  isOTPStyle?: boolean
}) => {
  const { t } = useTranslation()

  const id = tabItem.id - (useOriginalId ? 0 : 1)

  if (isSubTab === true) {
    return (
      <TouchableOpacity
        style={[tabIndex === id ? styles.subTabActive : styles.subTabItem]}
        onPress={() => onChangeTab(id)}
        disabled={disable}
      >
        <Text style={[styles.tabLabel, tabIndex === id && styles.subTabLabelActive]}>{tabItem.title}</Text>
      </TouchableOpacity>
    )
  } else {
    return (
      <TouchableOpacity
        style={[tabIndex === id ? styles.tabActive : styles.tabItem, isOTPStyle && styles.OTPTab]}
        onPress={() => onChangeTab(id)}
        disabled={disable}
      >
        <Text style={[styles.tabLabel, tabIndex === id && styles.tabLabelActive]}>{t(tabItem.title)}</Text>
      </TouchableOpacity>
    )
  }
}

export const TabList: React.FC<Props> = (props: Props) => {
  const { useOriginalId = false } = props
  const scrollViewRef = React.useRef<ScrollView>(null)
  const [positionX, setpositionX] = React.useState<number>(0)
  const [scrollWidth, setScrollWidth] = React.useState<number>(0)

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    setpositionX(event.nativeEvent.contentOffset.x)
  }

  const handleScrollLayout = (event: LayoutChangeEvent) => {
    setScrollWidth(event.nativeEvent.layout.width)
  }
  return (
    <View style={[props.isSubTab && styles.container, styles.tabWrapper]}>
      {props.isScrollIcon && !props.isSubTab && scrollWidth > 780 && scrollWidth < 1777 && (
        <View style={{ width: '3%', paddingTop: 3 }}>
          <TouchableOpacity
            onPress={() => {
              scrollViewRef.current?.scrollTo({ x: Math.min(positionX - 260, 0) })
            }}
          >
            <assets.LeftScrollIcon />
          </TouchableOpacity>
        </View>
      )}
      <ScrollView
        ref={scrollViewRef}
        onLayout={handleScrollLayout}
        onScroll={handleScroll}
        horizontal={true}
        style={{ width: '94%' }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.taContainer}>
          {props.menus.map((tabItem, index) => (
            <TabItem
              key={tabItem.id}
              isSubTab={props.isSubTab}
              useOriginalId={useOriginalId}
              tabItem={tabItem}
              tabIndex={props.tabIndex}
              onChangeTab={props.onChangeTab}
              disable={props.predicateDisable?.call(null, index)}
              isOTPStyle={props.isOTPStyle}
            />
          ))}
        </View>
      </ScrollView>
      {props.isScrollIcon && !props.isSubTab && scrollWidth > 780 && scrollWidth < 1777 && (
        <View style={{ width: '3%', paddingTop: 3, paddingLeft: 10 }}>
          <TouchableOpacity
            onPress={() => {
              // scrollViewRef.current?.scrollTo({ x: Math.min(positionX - 260, 0) })
              scrollViewRef.current?.scrollTo({ x: Math.max(positionX + 260, 0) })
            }}
          >
            <assets.RightScrollIcon />
          </TouchableOpacity>
        </View>
      )}
      {props.gutterBottom && props.isSubTab && <View style={[styles.gutterButton]} />}
    </View>
  )
}

const styles = StyleSheet.create({
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  container: { paddingTop: 20, marginBottom: 30 },
  taContainer: {
    flexDirection: 'row'
  },
  tabItem: {
    backgroundColor: '#D3DCE6',
    opacity: 0.5,
    borderTopStartRadius: 14,
    borderTopEndRadius: 14,
    width: 260
  },
  OTPTab: {
    borderLeftWidth: 1,
    borderColor: 'rgb(211, 220, 230)',
    borderTopWidth: 1
  },
  subTabItem: {
    marginRight: 50
  },

  // Tab ++
  tabLabel: {
    paddingVertical: 8,
    fontSize: 15,
    textAlign: 'center'
  },
  subTabLabelActive: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  subTabActive: {
    borderBottomColor: 'red',
    borderBottomWidth: 2,
    marginRight: 50
  },
  gutterButton: {
    position: 'absolute',
    top: 56,
    zIndex: -1,
    borderBottomWidth: 2,
    borderBottomColor: '#D3DCE6',
    marginRight: 10,
    width: '100%'
  },
  w100: {
    width: '100%'
  },
  tabActive: {
    width: 260,
    backgroundColor: '#fff',
    borderTopStartRadius: 14,
    borderTopEndRadius: 14
  },
  tabLabelActive: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'red'
  },
  //scroll icons
  rightScrollIcon: {
    width: '3%',
    paddingTop: 3
  }
})
