export enum TaskCategory {
  REVIEW_EMAIL = 'REVIEW-EMAIL',
  RECHECK_EMAIL = 'RECHECK-EMAIL'
}

export enum TaskDecision {
  COMPLETE = 'complete',
  ACCEPT = 'accept',
  RETURN = 'return',
  CANCEL = 'cancel'
}

export const Status = [
  { code: 'RETURNED', nameEn: 'Returned', nameVi: 'Được trả về' },
  { code: 'PENDING_APPROVE', nameEn: 'Pending approval', nameVi: 'Chờ duyệt' },
  { code: 'CANCELED', nameEn: 'Canceled', nameVi: 'Đã hủy' },
  { code: 'OVER_SLA', nameEn: 'Over SLA', nameVi: 'Quá hạn' },
  { code: 'SENDING', nameEn: 'Sending', nameVi: 'Đang gửi' },
  { code: 'SUCCEED', nameEn: 'Success', nameVi: 'Thành công' },
  { code: 'FAILED', nameEn: 'Fail', nameVi: 'Thất bại' }
]
