import React from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

interface Props {
  gutterBottom?: boolean
  menus: {
    id: number
    title: string
  }[]
  tabIndex: number
  onChangeTab: (idx: number) => void
}

const TabItem = ({
  tabItem,
  tabIndex,
  onChangeTab
}: {
  tabItem: { id: number; title: string }
  tabIndex: number
  onChangeTab: (tabIndex: number) => void
}) => {
  return (
    <TouchableOpacity
      style={[tabIndex === tabItem.id - 1 ? styles.tabActive : styles.tabItem]}
      onPress={() => onChangeTab(tabItem.id - 1)}
    >
      <Text style={[styles.tabLabel, tabIndex === tabItem.id - 1 && styles.tabLabelActive]}>{tabItem.title}</Text>
    </TouchableOpacity>
  )
}

export const TabList: React.FC<Props> = ({ gutterBottom, menus, tabIndex, onChangeTab }) => {
  return (
    <View style={styles.container}>
      <ScrollView horizontal={true} style={styles.w100} showsVerticalScrollIndicator={false}>
        <View style={styles.taContainer}>
          {menus.map((tabItem) => (
            <TabItem key={tabItem.id} tabItem={tabItem} tabIndex={tabIndex} onChangeTab={onChangeTab} />
          ))}
        </View>
      </ScrollView>
      {gutterBottom && <View style={[styles.gutterButton]} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: { marginBottom: 30 },
  taContainer: {
    flexDirection: 'row'
  },
  tabItem: {
    marginRight: 10
  },
  // Tab ++
  tabLabel: {
    paddingVertical: 8,
    fontSize: 15
  },
  tabLabelActive: {
    color: 'red',
    fontWeight: 'bold'
  },
  tabActive: {
    borderBottomColor: 'red',
    borderBottomWidth: 2,
    marginRight: 10
  },
  gutterButton: {
    position: 'absolute',
    top: 34,
    zIndex: -1,
    borderBottomWidth: 2,
    borderBottomColor: '#D3DCE6',
    marginRight: 10,
    width: '100%'
  },
  w100: {
    width: '100%'
  }
})
