import { CashOut } from './CashOutOption'
export namespace ExcellentStudentAwardServiceModel {
  export interface Detail {
    benefit: number
    principalAmount: number
    investmentAmount: number
    overMaturity: number
    reqPayinAmount: number
  }

  export interface SubmitData {
    policy: {
      policyNo: string
    }
    attributesExt: {
      TOTAL_PAYOUT_AMOUNT: number
      EXCELLENT_AWARD_AMOUNT: number
      PRINCIPAL_AMOUNT: number
      INTEREST_AMOUNT: number
      NET_TOTAL_CASH_BENEFIT: number
      LOAN_EXCEED_80_SURRENDER_VALUE: number
    }
    cashOutOptions: CashOut.Option[]
  }
}
