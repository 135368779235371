import { CashOut } from './CashOutOption'

export namespace ExcessPremiumSubmission {
  export interface Detail {
    excessPremiumAmount: number
    // hasCashless: boolean
    receiptFlag: boolean
  }
  export interface AccessDataCheck {
    contractStatus: string
    excessPremium: number
  }
  export interface SubmitData {
    policy: {
      attributesExt: {
        EXCESS_PREMIUM_AMOUNT: string
        TOTAL_PAYOUT_AMOUNT: string
      }
    }
    cashOutOptions: Array<CashOut.Option>
  }
}
