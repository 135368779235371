import { StyleSheet } from 'react-native'

export const generalStyles = StyleSheet.create({
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800',
    paddingTop: 14,
    textTransform: 'uppercase'
  },
  sectionContainer: {
    marginTop: 14,
    padding: 18,
    // backgroundColor: "#FAFAFA",
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    minHeight: 20,
    overflow: 'hidden'
  },
  mobileField: {
    width: '100%',
    paddingBottom: 30
  },
  tabletField: {
    width: '33%',
    paddingBottom: 30,
    paddingRight: 30
  },
  btnWhite: {
    marginRight: 40,
    marginTop: 10,
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 40,
    paddingLeft: 29,
    paddingRight: 29
  },
  btnRed: {
    marginRight: 40,
    marginTop: 10,
    justifyContent: 'center',
    backgroundColor: '#ED1B2E',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 40,
    paddingLeft: 29,
    paddingRight: 29
  },
  btnDisabled: {
    marginRight: 40,
    marginTop: 10,
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#B0B0B0',
    minWidth: 121,
    height: 40,
    paddingLeft: 29,
    paddingRight: 29
  },
  submitTextRed: {
    color: '#ED1B2E',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  submitTextWhite: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  btnTextDisabled: {
    color: '#B0B0B0',
    textAlign: 'center',
    fontWeight: 'bold'
  }
})
