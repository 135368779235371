import * as React from 'react'
import styled from 'styled-components/native'
import { assets } from '../assets'
import { Text } from './styled'

export const SectionHeader: React.FC = (props) => {
  return (
    <SC.SectionHeader>
      <assets.IconDot
        style={{
          marginRight: 6
        }}
      />
      {props.children}
    </SC.SectionHeader>
  )
}

const SC = {
  SectionHeader: styled(Text)`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 12px;
  `
}
