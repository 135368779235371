import React from 'react'
import { StyleProp, ViewStyle, View, useWindowDimensions } from 'react-native'
import { TableCell, TableBody, Table, TableContainer, TableHead, Paper, TableRow, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import { IHeatDataDetail, useMobile } from '@pulseops/common'
import { useTranslation } from 'react-i18next'

type keyType = string | number | JSX.Element

export type DataSource<T = keyType> = {
  [key: string]: T
}

export type Columns = {
  title: string
  name: string
  builder?: (data: IHeatDataDetail) => JSX.Element
  cellStyle?: StyleProp<ViewStyle>
  headerCellStyle?: StyleProp<ViewStyle>
}

const useStyles = makeStyles({
  tableHeader: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    backgroundColor: '#F4F4F4'
  },
  headerCell: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    color: '#70777E'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  }
})

export const TableIHeatDetail: React.FC<{ columns: Columns[]; dataSource: IHeatDataDetail[] }> = ({
  columns,
  dataSource
}) => {
  const { t } = useTranslation()
  const { height } = useWindowDimensions()
  const { isMobile } = useMobile()
  const tableHeight = height - (isMobile ? 150 : 250)
  const classes = useStyles()
  return (
    <TableContainer component={Paper} style={{ maxHeight: tableHeight }}>
      <Table>
        <TableHead className={classes.tableHeader}>
          {columns.map((col, index) => {
            return <TableCell className={classes.headerCell}>{col.title}</TableCell>
          })}
        </TableHead>

        <TableBody>
          {dataSource.map((row, index) => {
            return (
              <TableRow key={`row_table_${index}`}>
                {columns.map((col, index) => {
                  const name = _.get(row, col.name) || ''
                  return (
                    <TableCell key={`cell_table_${index}`} className={classes.tableCell}>
                      {col.builder?.call(null, row) || name}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {dataSource.length === 0 && <View style={{ padding: 20 }}>{t('common:noRecord')}</View>}
    </TableContainer>
  )
}
