import * as t from 'io-ts'

export const GetBanksResponse = t.array(
  t.type(
    {
      code: t.string,
      name: t.string
    },
    'GetBanksResponse'
  )
)
export type GetBanksResponse = t.TypeOf<typeof GetBanksResponse>
