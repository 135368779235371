import Logo from './logo.svg'
import IconCalendar from './icon-calendar.svg'
import IconCalendarGray from './icon-calendar-gray.svg'
import IconArrowDown from './icon-calendar-gray.svg'
import IconArrowDownGray from './icon-arrow-down-gray.svg'
import Calling from './calling.svg'
import PolicyNumber from './policy-number.svg'
import PolicyOwner from './policy-owner.svg'
import SpecialBonusMoney from './special-bonus-money.svg'
export const assets = {
  HomeBackground: require('./home_bg.png'),
  HomeBackgroundWide: require('./home_bg_wide.png'),
  Logo,
  IconCalendar,
  IconCalendarGray,
  IconArrowDown,
  IconArrowDownGray,
  Calling,
  PolicyNumber,
  PolicyOwner,
  SpecialBonusMoney
}
