import { form2 } from '@pulseops/common'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/function'
import i18next from 'i18next'
import { withMessage } from 'io-ts-types'
import { withMaxDate } from '@pulseops/submission/common'

export namespace DateOfDeathForm {
  const File = t.type({
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: t.string,
    uploadedDate: form2.date.required,
    file: form2.file.required
  })

  type File = t.TypeOf<typeof File>

  type FileListBrand = {
    readonly FileList: any // unique symbol
  }
  export type FileList = t.Branded<File[], FileListBrand>

  const PoPassedAway = t.type({
    deathDate: pipe(
      withMessage(form2.date.required, () =>
        i18next.t('message:MS020001', { field: i18next.t('requestInfo:PODeathDate') })
      ),
      withMaxDate(new Date(), 'message:MS050028')
    ),

    files: pipe(
      t.array(File),
      form2.refine(
        (l): l is FileList => l && l.length > 0,
        () => i18next.t('message:MS020001', { field: i18next.t('submission:FileAttachment') }),
        'FileList'
      )
    )
  })

  export type PoPassedAway = t.TypeOf<typeof PoPassedAway>

  export const codec = PoPassedAway

  export type Validated = t.TypeOf<typeof codec>

  export type Raw = t.OutputOf<typeof codec>
}
