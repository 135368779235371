import { GroupProduct, Input, SectionHeader } from '@pulseops/business/core'
import { Format } from '@pulseops/common'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SC } from './sc'

export const ProductInfoView = ({ product }: { product: GroupProduct | null }) => {
  const { t } = useTranslation('business')

  if (!product) {
    return <SC.NoResult>{t('no_result')}</SC.NoResult>
  }

  return (
    <>
      <SC.ProductContainer>
        <SC.ProductTitle>
          {t('group_product')} - {product.basic.code} - {product.basic.name}
        </SC.ProductTitle>
        <SC.BenefitList>
          {product.basic.benefits.map((b, i, arr) => (
            <React.Fragment key={i.toString()}>
              <SC.BenefitItem>
                <SectionHeader>{b.name}</SectionHeader>
                <SC.BenefitContent>
                  <Input label={t('geo_coverage')} value={b.geoCoverage} readonly={true} />
                  <Input label={t('sum_assured')} value={Format.currencyForBusiness(b.sumAssured)} readonly={true} />
                </SC.BenefitContent>
              </SC.BenefitItem>
              {i % 2 === 1 ? <SC.ItemBreak /> : null}
            </React.Fragment>
          ))}
        </SC.BenefitList>
      </SC.ProductContainer>
      {product.add && (
        <SC.ProductContainer>
          <SC.ProductTitle>
            {t('group_product_rider')} - {product.add.code} - {product.add.name}
          </SC.ProductTitle>
          <SC.BenefitList>
            {product.add.benefits.map((b, i, arr) => (
              <React.Fragment key={i.toString()}>
                <SC.BenefitItem>
                  <SectionHeader>{b.name}</SectionHeader>
                  <SC.BenefitContent>
                    <Input label={t('geo_coverage')} value={b.geoCoverage} readonly={true} />
                    <Input label={t('sum_assured')} value={Format.currencyForBusiness(b.sumAssured)} readonly={true} />
                  </SC.BenefitContent>
                </SC.BenefitItem>
                {i % 2 === 1 ? <SC.ItemBreak /> : null}
              </React.Fragment>
            ))}
          </SC.BenefitList>
        </SC.ProductContainer>
      )}
      {product.healthCare && (
        <SC.ProductContainer>
          <SC.ProductTitle>
            {t('group_product_rider')} - {product.healthCare.code} - {product.healthCare.name}
          </SC.ProductTitle>
          <SC.BenefitList>
            {product.healthCare.benefits.map((b, i, arr) => (
              <React.Fragment key={i.toString()}>
                <SC.BenefitItem>
                  <SectionHeader>{b.name}</SectionHeader>
                  <SC.BenefitContent>
                    <Input label={t('health_care_plan')} value={b.hcPlan} readonly={true} />
                  </SC.BenefitContent>
                </SC.BenefitItem>
                {i % 2 === 1 ? <SC.ItemBreak /> : null}
              </React.Fragment>
            ))}
          </SC.BenefitList>
        </SC.ProductContainer>
      )}
    </>
  )
}
