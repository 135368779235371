import { ZIO } from '@mxt/zio'
import {
  assets,
  DatePicker,
  ErrorHandling,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  RadioButtonGroup,
  SelectSearch,
  Title,
  useMobile
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import React, { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-elements'
import { ComplainInquiryForm } from './complain-inquiry-form'

interface Props {
  form: Omit<UseFormReturn<ComplainInquiryForm.Raw>, 'handleSubmit'>
  detail: InquiryComplaintData.DetailData | null
  setAgentCodeErr: (value: string) => void
  agentCodeError: string
}

export const SalesPractice: React.FC<Props> = ({ form, setAgentCodeErr, agentCodeError }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()

  const [financialNeedAnalysisList, setFinancialNeedAnalysisList] = useState<InquiryComplaintData.IACReceiptType[]>([])

  pipe(
    InquiryComplaintService.getT22Data('fna'),
    ZIO.map((list) => {
      setFinancialNeedAnalysisList(list)
    }),
    ErrorHandling.runDidMount()
  )

  const { control, setValue, clearErrors } = form

  // Fecth API

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const onChangeAgentCode = (e: string) => {
    if (e && e.length === 8) {
      pipe(
        InquiryComplaintService.getAgentInfo(e),
        ZIO.map((res) => {
          if (!!res.agentName && res.agentName !== ' ') {
            setAgentCodeErr('')
            setValue('salesPractice.agentName', res.agentName)
            clearErrors('salesPractice.agentCode')
          } else {
            setValue('salesPractice.agentName', '')
            setAgentCodeErr(t('message:MS100005'))
          }
        }),
        ErrorHandling.run()
      )
    } else {
      setAgentCodeErr('')
      setValue('salesPractice.agentName', '')
    }
  }

  return (
    <View>
      <Title title={t('ComplaintedSalesPracticeDetails')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('Agent')}</Text>
              <Text style={styles.required}>*</Text>
            </View>
            <View style={[styles.row]}>
              <View style={{ marginRight: 20, width: '30%', minWidth: 95 }}>
                <Controller
                  name="salesPractice.agentCode"
                  control={control}
                  render={({ field: { onBlur, value }, fieldState: { error } }) => (
                    <View>
                      <Input
                        onChange={(e) => {
                          const newVal = e.replace(/\D/g, '')
                          form.setValue('salesPractice.agentCode', newVal)
                          onChangeAgentCode(e)
                        }}
                        onBlur={() => {
                          onBlur()
                          form.trigger('salesPractice.agentCode')
                        }}
                        value={value ?? ''}
                        errorMessage={error?.message || agentCodeError}
                        maxLength={8}
                      />
                    </View>
                  )}
                />
              </View>
              <View style={{ width: '70%' }}>
                <Controller
                  name="salesPractice.agentName"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      onChange={(e) => {
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      disabled
                      multiline
                      numberOfLines={2}
                    />
                  )}
                />
              </View>
            </View>
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="salesPractice.financialNeedAnalysis"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('FinancialNeedAnalysis')}
                  required
                  options={financialNeedAnalysisList.map((item) => ({
                    value: item.code,
                    label: i18n.language === 'en' ? item.nameEn : item.name
                  }))}
                  placeholder={showOptionLanguage()}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'salesPractice.POSignedInProposal'}
              control={control}
              defaultValue="yes"
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  title={t('POSignedInProposal')}
                  colLength={'100%'}
                  required
                  value={value}
                  options={[
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'salesPractice.customerReceivedPolicyKit'}
              control={control}
              defaultValue="yes"
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  title={t('CustomerReceivedPolicyKit/e-kit')}
                  colLength={'100%'}
                  required
                  value={value}
                  options={[
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ]}
                  onChange={onChange}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'salesPractice.customerReceivedDate'}
              control={control}
              defaultValue={new Date()}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <DatePicker
                  label={t('CustomerReceivedDate')}
                  required
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />
          </View>
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 15
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
