import React from 'react'
import { View } from 'react-native'
import { SC } from './styles'
import { PolicyServiceProps } from '../policy-service-props'
import {
  FatcaDeclaration,
  ImgUploadMutiple,
  form2,
  PulseOpsFormat,
  GeneralService,
  TransactionType,
  ErrorHandling,
  StorageBlob,
  AppContext,
  FatcaDeclarationService,
  TaskType
} from '@pulseops/common'
import { FatcaDeclarationForm } from './common'
import { UseFormReturn, Controller } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import { RequestAuthenticateData } from '../../request-authen'
import { useIsFocused, useNavigation } from '@react-navigation/native'
// import moment from 'moment'
type Props = PolicyServiceProps<FatcaDeclaration.SubmitData> & {
  //policyNum: string
  officeCode?: string
}

export const FatcaDeclarationScreen: React.FC<Props> = ({ policyNumber, initSubmission, isConfirmed, officeCode }) => {
  const { base: form } = form2.useForm(FatcaDeclarationForm.codec, {
    defaultValues: {
      listFile: []
    }
  })
  const { showGlobalLoading, changeBreadcrumb, showToast } = React.useContext(AppContext.AppContextInstance)
  const [loading, bindLoader] = useLoading(false)
  const isFocused = useIsFocused()
  const navigation = useNavigation()
  const { t } = useTranslation()
  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('Submission:EForm'),
          navigate: () => navigation.navigate('StaffSubmissionStack', { screen: 'StaffSubmissionScreen' })
        },
        {
          title: t('common:PolicyServices'),
          navigate: () => navigation.navigate('PSSubmissionStack', { screen: 'PSSubmissionListScreen' })
        },
        { title: t('TransactionType:FATCA_DECLARATION'), navigate: null }
      ])
    }
  }, [isFocused])

  const detail = pipe(FatcaDeclarationService.getDetail(policyNumber!), bindLoader, ErrorHandling.runDidMount({}))

  const mapData = (
    files: {
      name: string
      url: string
    }[] = []
  ): FatcaDeclaration.SubmitData => {
    return {
      customer: {
        clientId: detail.clientId
      },
      attributesExt: {
        PRIMARY_POLICY: policyNumber!,
        PO_CLIENT_NUMBER: detail.clientId,
        GA_OFFICE_CODE: detail.gaOffice,
        DOCUMENTS: files
      }
    }
  }

  initSubmission({
    validate: async (isContinue) => {
      await form.trigger()
      //console.log(form.formState.errors)
      if (form.formState.isValid) {
        let fileMeta: StorageBlob.FileContentSubmit[] = []
        if (!isContinue) {
          fileMeta = await pipe(
            // GeneralService.getMetaData(TransactionType.FATCA_DECLARATION, 'DPS06'),
            // ZIO.flatMap(({ data }) => {
            //   const batchno = `${moment().format('DD/MM/YYYY')}-${data.type}-${officeCode}`
            //   const metaDataRaw: StorageBlob.MetaDataUpload = {
            //     type: data.transactionType,
            //     doctype: data.doctypeEn,
            //     class: data.classFilenet,
            //     docid: data.docID,
            //     maindoc: data.mainDoc,
            //     subdoc: data.subDoc,
            //     polnum: policyNumber!!,
            //     batchno
            //   }

            //   const files = form.getValues().listFile.map((file) => {
            //     return {
            //       file: file.file!!,
            //       metaData: metaDataRaw
            //     }
            //   })
            //   return pipe(StorageBlob.uploadToSubmit('PS', policyNumber!!)(files))
            // }),
            GeneralService.getAzureStorageFiles(
              form.getValues('listFile') as FatcaDeclarationForm.FileMeta[],
              TransactionType.FATCA_DECLARATION,
              'DPS06',
              TaskType.PolicyService,
              policyNumber ?? '',
              officeCode ?? ''
            ),
            ZIO.tapError((_) => {
              showToast(t('message:MS050001'), 'error')
              return ZIO.unit
            }),
            bindLoader,
            ErrorHandling.run({})
          )
        }

        const data = mapData(fileMeta)
        return {
          url: () => `wf-api/customer/${detail.clientId}/taxInfo`,
          body: data,
          transactionName: RequestAuthenticateData.TransactionLabelShort(TransactionType.FATCA_DECLARATION),
          collerationId: policyNumber ?? '',
          transaction: TransactionType.FATCA_DECLARATION,
          documents: fileMeta
        }
      }
      return false
    },
    clear: () => {
      form.reset({ listFile: [] })
    }
  })

  if (isConfirmed) {
    return <ConfirmTab form={form.getValues()} />
  }
  return <RequestTab form={form} />
}

const RequestTab: React.FC<{
  form: Omit<UseFormReturn<FatcaDeclarationForm.Raw>, 'handleSubmit'>
}> = ({ form }) => {
  const {
    control,
    formState: { errors }
  } = form

  const { t } = useTranslation()

  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:FatcaRequestDetail')}</SC.SessionText>
      </SC.Padding>
      <Controller
        control={control}
        name={'listFile'}
        render={({ field: { value, onChange } }) => {
          const mapValue = value.map((file) => ({ ...file, uploadedDate: file.uploadedDate!!, file: file.file!! }))
          return (
            <SC.PanelContainer>
              <SC.TitleText>
                {t('utilities:FileAttachment')} <SC.ErrorText>*</SC.ErrorText>
              </SC.TitleText>
              <ImgUploadMutiple value={mapValue} onChange={onChange} errorMessage={_.get(errors, 'listFile.message')} />
              {errors.listFile && <SC.ErrorText>{_.get(errors, 'listFile.message')}</SC.ErrorText>}
            </SC.PanelContainer>
          )
        }}
      />
    </SC.Container>
  )
}

const ConfirmTab: React.FC<{ form: FatcaDeclarationForm.Raw }> = ({ form }) => {
  const { t } = useTranslation()
  return (
    <SC.Container>
      <SC.Padding vertical={10}>
        <SC.SessionText>{t('submission:FatcaRequestDetail')}</SC.SessionText>
      </SC.Padding>
      <SC.PanelContainer>
        <SC.TitleText>{t('utilities:FileAttachment')}</SC.TitleText>
        <SC.Row>
          {form.listFile.map((file, index) => {
            return (
              <SC.Padding key={`list_file_thumb_${index}`} vertical={5} right={10}>
                <View style={{ width: 200, marginRight: 20 }}>
                  <SC.BottomDashContainer>
                    <SC.Padding vertical={10} right={10}>
                      <SC.Row>
                        <SC.ImageThumb source={{ uri: file.base64 }} />
                        <SC.Divider />
                        <SC.Colum>
                          <SC.NormalText>{file.fileName}</SC.NormalText>
                          <SC.ItalicText>
                            {PulseOpsFormat.datetoFormat(file.uploadedDate, 'DD/MM/YYYY HH:mm')}
                          </SC.ItalicText>
                        </SC.Colum>
                        <SC.Divider />
                      </SC.Row>
                    </SC.Padding>
                  </SC.BottomDashContainer>
                </View>
              </SC.Padding>
            )
          })}
        </SC.Row>
      </SC.PanelContainer>
    </SC.Container>
  )
}
