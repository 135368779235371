import { GeneralData } from '../../GeneralService'
import { SubmissionService } from '../../SubmissionService'
import { SUFullSurrenderReversalData } from '../FullSurrenderReversal'
import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'

export interface VeriLifeAssureds {
  attributesExt: {
    CLIENT_NO: string
    H_ANSWER: string
    O_ANSWER: string
    CURRENT_OCCUPATION: string
    NEW_OCCUPATION: string
    A_ANSWER: string
    NEW_ACTIVITY: string
    C_ANSWER: string
    COMPANY_NAME: string
    AGENT_CODE: string
    // new field
    clientName: string
    oldOccupationName: string
    newOccupationName: string
  }
}

export interface VeriFSRSubmissionData {
  surrenderValue: string
  attributesExt: SUFullSurrenderReversalData.FullSurrenderReversalInfo
  lifeAssureds: VeriLifeAssureds[]
}

export interface VeriFullSurrenderReversalData {
  tag: TransactionType.FULL_SURRENDER_REVERSAL
  clientName: string
  // submissionData: SUFullSurrenderReversalData.SubmitData
  occupationList: SUFullSurrenderReversalData.FUSUREOccupation[]
  submissionData: VeriFSRSubmissionData
}

export const FullSurrenderReversal = (
  detail: api.FullSurrenderReversalData & api.TaskDetailBase,
  occupationList: GeneralData[],
  clientList: SubmissionService.ClientQuery[],
  poName: string
): VeriFullSurrenderReversalData => {
  const payloadData = detail.payload.body
  const customedjobList = occupationList.map((occupation) => ({
    id: occupation.id ?? '',
    name: occupation.name,
    code: occupation.code
  }))
  const VeriLifeAssuredsList = payloadData.lifeAssureds.map((lifeAssuredItem) => {
    let occupationOldName = '',
      occupationNewName = ''
    const clientNo = lifeAssuredItem.attributesExt.CLIENT_NO
    const currentOccupationID = lifeAssuredItem.attributesExt.CURRENT_OCCUPATION
    const newOccupationID = lifeAssuredItem.attributesExt.NEW_OCCUPATION
    const clientName = clientList.find((item) => item.clientId === clientNo)?.name
    if (lifeAssuredItem.attributesExt.O_ANSWER === 'Y') {
      occupationOldName = occupationList.find((item) => item.code === currentOccupationID)?.name ?? ''
      occupationNewName = occupationList.find((item) => item.code === newOccupationID)?.name ?? ''
    }

    return {
      attributesExt: {
        CLIENT_NO: clientNo,
        H_ANSWER: lifeAssuredItem.attributesExt.H_ANSWER,
        O_ANSWER: lifeAssuredItem.attributesExt.O_ANSWER,
        CURRENT_OCCUPATION: currentOccupationID,
        NEW_OCCUPATION: newOccupationID,
        A_ANSWER: lifeAssuredItem.attributesExt.A_ANSWER,
        NEW_ACTIVITY: lifeAssuredItem.attributesExt.NEW_ACTIVITY,
        C_ANSWER: lifeAssuredItem.attributesExt.C_ANSWER,
        COMPANY_NAME: lifeAssuredItem.attributesExt.COMPANY_NAME,
        AGENT_CODE: lifeAssuredItem.attributesExt.AGENT_CODE,
        clientName: clientName ?? '',
        oldOccupationName: occupationOldName ?? '',
        newOccupationName: occupationNewName ?? ''
      }
    }
  })
  return {
    tag: TransactionType.FULL_SURRENDER_REVERSAL,
    clientName: poName,
    submissionData: {
      surrenderValue: String(payloadData.surrenderValue),
      attributesExt: {
        PAID_CASH_BENEFITS: payloadData.attributesExt.PAID_CASH_BENEFITS,
        NOT_PAID_PREMIUM: payloadData.attributesExt.NOT_PAID_PREMIUM,
        POLICY_LOANS: payloadData.attributesExt.POLICY_LOANS ?? '',
        REINSTATEMENT_FEE: payloadData.attributesExt.REINSTATEMENT_FEE,
        REASON: payloadData.attributesExt.REASON ?? ''
      },
      lifeAssureds: VeriLifeAssuredsList
    },
    occupationList: customedjobList
  }
}
